import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import React, { useEffect, useState } from "react";
import {
  BGColor02,
  blackShade05,
  blueColor04,
  borderTextbox,
  descriptionColor,
  greyColor6,
  greyColor8,
  menuGradient,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ModalCard from "../../../components/ModalCard";

function DiscountInputModal({
  label,
  placeholder,
  name,
  value,
  onChange,
  icon,
  setMoreProducts,
  maxLength,
  inputType,
  // isError,
  error,
  helperText,
  autoFocus,
  inputRef,
  onKeyUp,
  onBlur,
  disabled,
  isDashboardInput,
  clickDisabled,
}) {
  const [modal, setModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [arr, setArr] = useState([
    {
      discountPercentage: "",
      distanceStart: "",
      distanceEnd: "",
      discountType: "freeshipmentpromotion",
    },
  ]);

  useEffect(() => {
    setArr(
      value?.length > 0
        ? value
        : [
            {
              discountPercentage: "",
              distanceStart: "",
              distanceEnd: "",
              discountType: "freeshipmentpromotion",
            },
          ]
    );
    return () => {};
  }, [value, modal]);

  const handleAddNewRow = (textInput, index) => {
    setModalError("");
    const fromDistance = Number(textInput?.distanceStart);
    const toDistance = Number(textInput?.distanceEnd);
    const discountPer = Number(textInput?.discountPercentage);

    if (
      textInput?.distanceStart === "" ||
      textInput?.distanceEnd === "" ||
      textInput?.discountPercentage === ""
    ) {
      setModalError("Please fill all the input fields");
      return;
    }

    if (fromDistance && toDistance > 0 && toDistance <= fromDistance) {
      setModalError(
        "From Distance should not be greater than or equal to To Distance"
      );
      return;
    }

    console.log("Discount_Percentage_List:", arr);

    if (arr.length > 0) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (index !== i) {
          const currentObj = arr[i];

          const currentFromDistance = Number(currentObj.distanceStart);
          const currentToDistance = Number(currentObj.distanceEnd);

          //   console.log(
          //     `NewITEM_[${index}][${i}]:`,
          //     `${fromDistance} >= ${currentFromDistance} && ${fromDistance} <= ${currentToDistance} ||
          //         ${toDistance} >= ${currentFromDistance} && ${toDistance} <= ${currentToDistance}`
          //   );
          //   console.log(
          //     `NewITEM_[${index}][${i}]:`,
          //     `${fromDistance >= currentFromDistance} && ${
          //       fromDistance <= currentToDistance
          //     } ||
          //         ${toDistance >= currentFromDistance} && ${
          //       toDistance <= currentToDistance
          //     }`
          //   );

          //   console.log(
          //     "----------------------------------------------------------"
          //   );

          if (
            (fromDistance >= currentFromDistance &&
              fromDistance <= currentToDistance) ||
            (toDistance >= currentFromDistance &&
              toDistance <= currentToDistance)
          ) {
            console.log("Range overlap found");
            setModalError("Range overlap found");
            return;
          }

          if (
            (currentFromDistance >= fromDistance &&
              currentFromDistance <= toDistance) ||
            (currentToDistance >= fromDistance &&
              currentToDistance <= toDistance)
          ) {
            console.log("Range overlap found");
            setModalError("Range overlap found");
            return;
          }
        }
      }
    }

    console.log("No range overlap found, return true");

    if (fromDistance >= 0 && toDistance >= 0 && discountPer >= 0) {
      setArr([
        ...arr,
        {
          discountPercentage: "",
          // distanceStart: "",
          distanceStart: (toDistance + 0.01)?.toFixed(2),
          distanceEnd: "",
          discountType: "freeshipmentpromotion",
        },
      ]);
      setModalError("");
    } else setModalError("Enter valid input");
  };

  const handleApply = () => {
    setModalError("");
    let errorMsg = "";

    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];

      errorMsg = "";

      const fromDistance = Number(item?.distanceStart);
      const toDistance = Number(item?.distanceEnd);
      const discountPer = Number(item?.discountPercentage);

      if (
        item?.distanceStart === "" ||
        item?.distanceEnd === "" ||
        item?.discountPercentage === ""
      ) {
        errorMsg = "Please fill all the input fields";
        setModalError(errorMsg);
        break;
      }

      if (fromDistance && toDistance > 0 && toDistance <= fromDistance) {
        errorMsg =
          "From Distance should not be greater than or equal to To Distance";
        setModalError(errorMsg);
        break;
      }

      for (let j = 0; j < arr.length; j++) {
        if (i !== j) {
          const newItem = arr[j];

          const currentFromDistance = Number(newItem?.distanceStart);
          const currentToDistance = Number(newItem?.distanceEnd);

          if (
            (fromDistance >= currentFromDistance &&
              fromDistance <= currentToDistance) ||
            (toDistance >= currentFromDistance &&
              toDistance <= currentToDistance)
          ) {
            console.log("Range overlap found");
            errorMsg = "Range overlap found";
            break;
          }
        }
      }

      if (errorMsg) {
        setModalError(errorMsg);
        break;
      }
    }

    if (!errorMsg) {
      setMoreProducts(arr);
      setModal(false);
    }
  };

  const handleOpenModal = () => {
    if (clickDisabled) return;
    setModal(true);
  };

  const onDistanceChange = (value, inputData, index) => {
    if (modalError) setModalError("Enter valid input");

    const distance = value?.replace(/[^0-9.]/g, "");
    const formattedDistance = distance?.replace(/^(\d+\.\d{0,2})\d*$/, "$1");

    let data = [...arr];
    data[index] = {
      ...data[index],
      distanceEnd: formattedDistance,
    };

    const nextIndex = index + 1;

    if (nextIndex < arr?.length) {
      console.log("BLUR_HITT", Number(inputData?.distanceEnd));
      if (
        inputData?.discountPercentage &&
        inputData?.distanceStart &&
        inputData?.distanceEnd
      ) {
        const newDistanceEnd = Number(formattedDistance) + 0.01;

        data[nextIndex] = {
          ...data[nextIndex],
          distanceStart: newDistanceEnd.toFixed(2),
        };
      }
    }

    setArr(data);
    setModalError("");
  };

  return (
    <Box mb="15px !important">
      <Box display="flex" alignItems="center" gap="15px">
        <TextField
          variant="standard"
          required={true}
          fullWidth
          name={name}
          disabled={disabled}
          value={value
            ?.map((item) =>
              item?.distanceStart &&
              item?.distanceEnd &&
              item?.discountPercentage
                ? `${item?.distanceStart} KM-${item?.distanceEnd} KM ${item?.discountPercentage}%`
                : ""
            )
            ?.join(", ")}
          onBlur={onBlur}
          autoFocus={autoFocus}
          onChange={onChange}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          onClick={handleOpenModal}
          inputRef={inputRef}
          sx={{
            fontSize: 14.5,
            borderTop: "none",
            borderBottom: borderTextbox,
            borderLeft: "none",
            borderRight: "none",
            borderRadius: "0px",
            backgroundColor: whiteColor,
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                {icon}
              </InputAdornment>
            ),
            style: {
              padding: isDashboardInput ? "7px 12px" : "4px 8px",
              color: grey,
              fontSize: 13,
              fontWeight: 500,
            },
            disableUnderline: true,
          }}
          inputProps={{ maxLength: maxLength }}
          type={inputType}
        />

        <IconButton onClick={handleOpenModal} sx={styles.addBtn}>
          <AddRoundedIcon
            sx={{ fontSize: { md: "28px", xs: "20px" }, color: whiteColor }}
          />
        </IconButton>
      </Box>

      {error ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={12}
          sx={{ mt: "4px !important", textAlign: "start !important" }}
          style={{ color: "red" }}
        >
          {error}
        </Typography>
      ) : null}

      {helperText ? (
        <Typography
          variant="body1"
          component="p"
          fontSize={11}
          color={descriptionColor}
        >
          {helperText}
        </Typography>
      ) : null}

      <ModalCard
        open={modal}
        handleClose={() => {
          setArr([]);
          setModal(false);
        }}
        width="50%"
      >
        <Box sx={styles.card}>
          <Typography
            variant="h3"
            fontSize={18}
            fontWeight={600}
            lineHeight="34px"
            color={blackShade05}
            textAlign="center"
            p="15px 50px"
          >
            Additional Discount
          </Typography>

          <Box sx={styles.cardBody}>
            <Box mt={1}>
              <Grid
                container
                display={"flex"}
                alignItems="center"
                justifyContent={"flex-start"}
                columnSpacing={2}
              >
                <Grid item md={3.5}>
                  <Typography
                    variant="body1"
                    fontSize={13}
                    color={greyColor6}
                    fontWeight={500}
                    mb="8px !important"
                    sx={{ display: "inline" }}
                  >
                    From Distance (in KM)
                  </Typography>
                </Grid>

                <Grid item md={3.5}>
                  <Typography
                    variant="body1"
                    fontSize={13}
                    color={greyColor6}
                    fontWeight={500}
                    mb="8px !important"
                    sx={{ display: "inline" }}
                  >
                    To Distance (in KM)
                  </Typography>
                </Grid>

                <Grid item md={3.5}>
                  <Typography
                    variant="body1"
                    fontSize={13}
                    color={greyColor6}
                    fontWeight={500}
                    mb="8px !important"
                    sx={{ display: "inline" }}
                  >
                    Percentage OFF
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {arr.map((textInput, index) => (
              <Grid
                container
                display={"flex"}
                alignItems="center"
                justifyContent={"flex-start"}
                columnSpacing={{ xs: 0, md: 2 }}
              >
                <Grid item xs={3.5} md={3.5}>
                  <TextField
                    variant="standard"
                    fullWidth
                    value={textInput?.distanceStart}
                    onChange={({ target }) => {
                      if (modalError) setModalError("Enter valid input");
                      let data = [...arr];
                      // const distance = target?.value?.replace(/[^0-9]/g, "");

                      const distance = target?.value?.replace(/[^0-9.]/g, "");
                      const formattedDistance = distance?.replace(
                        /^(\d+\.\d{0,2})\d*$/,
                        "$1"
                      );

                      data[index] = {
                        ...data[index],
                        distanceStart: formattedDistance,
                      };
                      setArr(data);
                      setModalError("");
                    }}
                    disabled={index !== 0}
                    sx={{
                      mt: 1,
                      fontSize: 14.5,
                      borderTop: isDashboardInput ? borderTextbox : "none",
                      borderBottom: borderTextbox,
                      borderLeft: isDashboardInput ? borderTextbox : "none",
                      borderRight: isDashboardInput ? borderTextbox : "none",
                      borderRadius: isDashboardInput ? "6px" : "0px",
                      backgroundColor: whiteColor,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ cursor: "pointer" }}
                        >
                          {icon}
                        </InputAdornment>
                      ),
                      style: {
                        padding: isDashboardInput ? "7px 12px" : "4px 8px",
                        color: grey,
                        fontSize: 14.5,
                        fontWeight: 500,
                      },
                      disableUnderline: true,
                    }}
                    inputProps={{ maxLength: maxLength }}
                    type={inputType}
                  />
                </Grid>

                <Grid item xs={3.5} md={3.5}>
                  <TextField
                    variant="standard"
                    fullWidth
                    value={textInput?.distanceEnd || ""}
                    onChange={({ target }) => {
                      onDistanceChange(target?.value, textInput, index);
                    }}
                    sx={{
                      mt: 1,
                      fontSize: 14.5,
                      borderTop: isDashboardInput ? borderTextbox : "none",
                      borderBottom: borderTextbox,
                      borderLeft: isDashboardInput ? borderTextbox : "none",
                      borderRight: isDashboardInput ? borderTextbox : "none",
                      borderRadius: isDashboardInput ? "6px" : "0px",
                      backgroundColor: whiteColor,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ cursor: "pointer" }}
                        >
                          {icon}
                        </InputAdornment>
                      ),
                      style: {
                        padding: isDashboardInput ? "7px 12px" : "4px 8px",
                        color: grey,
                        fontSize: 14.5,
                        fontWeight: 500,
                      },
                      disableUnderline: true,
                    }}
                    inputProps={{ maxLength: maxLength }}
                    type={inputType}
                  />
                </Grid>

                <Grid item xs={3.2} md={3.2}>
                  <TextField
                    variant="standard"
                    fullWidth
                    value={textInput?.discountPercentage || ""}
                    onChange={({ target }) => {
                      if (modalError) setModalError("Enter valid input");

                      // const percentage = target?.value?.replace(/[^0-9]/g, "");

                      const percentage = target?.value?.replace(/[^0-9.]/g, "");
                      const formattedPercentage = percentage?.replace(
                        /^(\d+\.\d{0,2})\d*$/,
                        "$1"
                      );

                      let data = [...arr];
                      data[index] = {
                        ...data[index],
                        discountPercentage: formattedPercentage,
                      };
                      setArr(data);
                      setModalError("");
                    }}
                    sx={{
                      mt: 1,
                      fontSize: 14.5,
                      borderTop: isDashboardInput ? borderTextbox : "none",
                      borderBottom: borderTextbox,
                      borderLeft: isDashboardInput ? borderTextbox : "none",
                      borderRight: isDashboardInput ? borderTextbox : "none",
                      borderRadius: isDashboardInput ? "6px" : "0px",
                      backgroundColor: whiteColor,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ cursor: "pointer" }}
                        >
                          {icon}
                        </InputAdornment>
                      ),
                      style: {
                        padding: isDashboardInput ? "7px 12px" : "4px 8px",
                        color: grey,
                        fontSize: 14.5,
                        fontWeight: 500,
                      },
                      disableUnderline: true,
                    }}
                    inputProps={{ maxLength: maxLength }}
                    type={inputType}
                  />
                </Grid>

                <Grid item xs={0.7} md={0.7}>
                  <IconButton
                    onClick={() => {
                      if (index === 0) return;
                      let data = [...arr];
                      data.splice(index, 1);
                      setArr(data);
                    }}
                    sx={{ opacity: index === 0 ? 0.3 : 1 }}
                  >
                    <DeleteRoundedIcon fontSize="large" />
                  </IconButton>
                </Grid>

                {arr?.length < 10 ? (
                  index == arr?.length - 1 ? (
                    <Grid item xs={1.5} md={0.5}>
                      <IconButton
                        onClick={() => handleAddNewRow(textInput, index)}
                      >
                        <AddRoundedIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                  ) : null
                ) : null}
              </Grid>
            ))}

            {modalError ? (
              <Typography
                variant="subtitle1"
                component="p"
                fontSize={12}
                sx={{ mt: "4px !important", textAlign: "start !important" }}
                style={{ color: "red" }}
              >
                {modalError}
              </Typography>
            ) : null}

            <Typography
              variant="subtitle1"
              component="p"
              fontSize={13}
              sx={{ mt: "12px !important", textAlign: "start !important" }}
              style={{ color: greyColor6 }}
            >
              You can add upto 10 rows
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p="15px"
          >
            <Button
              sx={[styles.actionBtn, styles.cancelBtn]}
              onClick={() => {
                setArr([""]);
                setModal(false);
              }}
            >
              CANCEL
            </Button>

            <Button
              sx={[styles.actionBtn, styles.proceedBtn]}
              onClick={handleApply}
            >
              APPLY
            </Button>
          </Box>
        </Box>
      </ModalCard>
    </Box>
  );
}

export default DiscountInputModal;

const styles = {
  card: {
    backgroundColor: BGColor02,
    borderRadius: "15px",
  },

  cardBody: {
    maxHeight: "80vh",
    backgroundColor: whiteColor,
    p: "12px",
    overflow: "auto",
  },

  actionBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    color: greyColor8,
    border: `1px solid ${greyColor8}`,
    p: "4px 35px",
  },

  cancelBtn: {
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },

  proceedBtn: {
    color: whiteColor,
    background: menuGradient,
    p: "5px 35px",
    border: "none",

    "&:hover": {
      color: greyColor8,
      background: whiteColor,
      border: `1px solid ${greyColor8}`,
    },
  },

  btn: (bgColor, textcolor) => ({
    background: bgColor,
    padding: { xs: "3px 24px", md: "4px 45px" },
    fontSize: { xs: "12px", md: "14px" },
    color: textcolor,
    borderRadius: "4px",
    fontWeight: 500,
    border: "1px solid " + bgColor,
  }),

  addBtn: {
    width: { md: "40px", xs: "30px" },
    height: { md: "40px", xs: "30px" },
    borderRadius: "3px",
    backgroundColor: { md: blueColor04, xs: mobilePrimary },
    "&:hover": {
      backgroundColor: { md: blueColor04, xs: mobilePrimary },
    },
  },
};
