import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  borderTextbox,
  greyShade04,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import DashboardFormHeader from "./DashboardFormHeader";
import DashboardStepper from "../DashboardStepper";
import RadiosBtnWithLabel from "../../../components/Input/RadiosBtnWithLabel";
import { useState } from "react";
import CheckBoxWithLabel from "../../../components/Input/CheckBoxWithLabel";
import FormInputBox from "../../../components/Input/FormInputBox";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import { STORE_STEP_LIST } from "../../../utils/appData";

function DiscountDetailsForm({ gotoNext, goBack }) {
  const [discountType, setDiscountType] = useState("Select Discount Type");

  const DISCOUNT_TYPES = [
    { label: "Select Discount Type" },
    { label: "Brand" },
    { label: "Retailers" },
    { label: "Wholesalers/Distributors" },
  ];

  return (
    <Box sx={styles.card}>
      <DashboardFormHeader />
      <DashboardStepper
        stepList={STORE_STEP_LIST}
        currentPage="discount-details"
      />

      <Box m="20px 0 15px 0">
        <Typography component="h5" sx={styles.heading}>
          Discount Details
        </Typography>

        <Box sx={{ borderBottom: borderTextbox, pb: 1, mt: "20px" }}>
          <Grid container rowSpacing={2} columnSpacing={3}>
            <Grid item xs={12} md={6}>
              <FormInputBox
                label="Free shipment orders above"
                placeholder="Enter Free shipment orders above"
                isDashboardInput
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderBottom: borderTextbox, pb: 1, mt: "20px" }}>
          <Typography
            component="h6"
            sx={{
              ...styles.heading,
              fontSize: { xs: "14.5px", md: "16.5px" },
              m: "15px 0 6px",
            }}
          >
            Additional Discount based on distance
          </Typography>

          <Typography
            variant="body1"
            fontSize={13}
            fontWeight={400}
            color={greyShade04}
            mb="15px !important"
          >
            (Distance will be calculated from the pickup address)
          </Typography>

          <Grid container rowSpacing={{ xs: 0.5, md: 2 }} columnSpacing={3}>
            <Grid item xs={12} md={4}>
              <FormInputBox
                label="From Date"
                placeholder="Enter From Date"
                isDashboardInput
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInputBox
                label="To Date"
                placeholder="Enter To Date"
                isDashboardInput
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInputBox
                label="Discount Percentage"
                placeholder="Enter Discount Percentage"
                isDashboardInput
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ pb: 1, mt: "20px" }}>
          <Typography
            component="h6"
            sx={{
              ...styles.heading,
              fontSize: { xs: "14.5px", md: "16.5px" },
              m: "15px 0 15px",
            }}
          >
            Store Pickup Discount
          </Typography>

          <Grid container rowSpacing={2} columnSpacing={3}>
            <Grid item xs={12} md={4}>
              <SelectWithLabel
                label="Discount Type"
                value={discountType}
                onValueChange={() => {}}
                menuList={DISCOUNT_TYPES}
                isDashboardInput
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInputBox
                label="Discount Amount"
                placeholder="Enter Discount Amount"
                isDashboardInput
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInputBox
                label="Discount Percentage"
                placeholder="Enter Discount Percentage"
                isDashboardInput
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box mt="15px">
        <ActionButtons
          onBackClick={goBack}
          onSaveClick={() => {}}
          onNextClick={gotoNext}
          backBtnText="Previous"
          showBackBtn
        />
      </Box>
    </Box>
  );
}

export default DiscountDetailsForm;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    p: "20px",
    my: "25px",
  },

  heading: {
    fontSize: { xs: 15, md: 18 },
    color: blackShade05,
    fontWeight: 600,
    lineHeight: "20px",
  },
};
