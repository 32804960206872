import { Box, Button, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blueColor02,
  blueColor04,
  greyColor24,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
function MobileCashBackEmptyCard() {
  return (
    <Box sx={styles.card}>
      <Box sx={styles.iconContainer}>
        <Box
          component="img"
          src={
            "https://storage.googleapis.com/bodefaults/businessdashboardweb/cashback-empty.svg"
          }
          width="40px"
        />
      </Box>

      <Typography
        color={blackShade05}
        fontSize={13}
        fontWeight={500}
        lineHeight={1.6}
        sx={{ whiteSpace: "pre-line" }}
      >
        {`To encourage repeat customer purchase \nenable `}
        <Typography
          fontSize={13}
          fontWeight={500}
          sx={{
            color: { xs: mobilePrimary, md: blueColor04 },
            display: "inline",
          }}
        >
          Loyalty Points Program
        </Typography>
      </Typography>
    </Box>
  );
}

export default MobileCashBackEmptyCard;

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#EAE8FF",
    marginBottom: "12px",
    borderRadius: "10px",
    padding: "10px 0 10px 0",
    textAlign: "center",
  },
  iconContainer: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    background: whiteColor,
    mb: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  actionBtn: {
    fontSize: "13px",
    fontWeight: "600",
    color: greyColor24,
    backgroundColor: whiteColor,
    padding: "6px 22px",
    mt: "15px",
    border: `1px solid ${greyColor24}`,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor24,
    },
  },
};
