import { Box, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  greyColor28,
} from "../../../../configs/styles/muiThemes";
import DisplayBoxData from "./DisplayBoxData";
import { TIME_SCHEDULE } from "../../../../utils/data";
import TimeScheduleStep from "../../../../components/Stepper/TimeScheduleStep";
import { useSelector } from "react-redux";
import { getFirstStringBeforeComma } from "../../../../utils/helper";

function DisplayB2BViewContent({
  boxData,
  handleResend,
  success,
  error,
  loading,
  orderStatus,
}) {
  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  const getBoxTitle = (data) => {
    switch (data?.toLowerCase()?.slice(0, 5)) {
      case "small":
        return "Small Box";
      case "mediu":
        return "Medium Box";
      case "stand":
        return "Custom Box";

      default:
        return "Custom Box";
    }
  };

  return (
    <Box
      sx={{
        borderRadius: "12px",
        border: `1px solid ${greyColor28}`,
        mt: 2.2,
        p: "12px",
      }}
    >
      <Typography
        fontSize={15}
        fontWeight={600}
        color={blackShade05}
        mb={1}
        lineHeight={"20px"}
      >
        Shipment Info
      </Typography>

      <Box mb={2}>
        <TimeScheduleStep
          label={"Shipping label"}
          subLabel={`Email From : orders@costbo.com`}
          subLabel1={`Email To: ${getFirstStringBeforeComma(
            activeBusinessInfo?.businessMailingAddress
          )}`}
          msg="ATTACH TO DELIVERY PACKAGE"
          btn={true}
          hideLine={true}
          isShipmentLabel
          hideNode
          successMsg="Shipping label sent successfully"
          handleResend={handleResend}
          success={success}
          error={error}
          loading={loading}
          disableButton={orderStatus === "orderdelivered"}
        />
      </Box>

      {boxData?.map((box, index) => (
        <DisplayBoxData
          title={getBoxTitle(box?.boxType)}
          length={box?.length}
          width={box?.width}
          height={box?.height}
          count={box?.count}
          key={index}
        />
      ))}
      {/* 
      <DisplayBoxData title="Small Box" />
      <DisplayBoxData title="Medium Box" />
      <DisplayBoxData title="Custom Box" /> */}
    </Box>
  );
}

export default DisplayB2BViewContent;
