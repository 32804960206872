import React, { useEffect, useRef, useState } from "react";
import {
  addNewProductApi,
  deleteProductPhotoApi,
  getReviewProductDetailsAPI,
  getUnitOfMeasurementApi,
  getViewProductDetailsAPI,
  uploadPendingProductApi,
  uploadProductApi,
  uploadProductPhotoApi,
} from "../../service/api_calls/catalog/CatalogAPI";
import {
  getOndcFullSpecificationAPI,
  getOndcSpecificationAPI,
  getProductCodeAPI,
  getProductSubCategoryOneAPI,
} from "../../service/api_calls/AddProduct/AddProductAPI";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { stickyHeader } from "../../utils/styles";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import HowToLinkCard from "../../components/Cards/HowToLinkCard";
import {
  blackColor,
  blueColor04,
  mobilePrimary,
  primaryColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { ONDC_DEFAULT_NEW_DATA } from "../../utils/data/defaultFormData";
import { DEFAULT_COUNTRY, PRODUCT_CODE_DEFAULT_LIST } from "../../utils/data";
import moment from "moment";
import {
  countMatch,
  extractNameCodePairs,
  extractNames,
  getOptionsForDependentCode,
  getSelectedCountryByCode,
  getValueByCode,
} from "../../utils/helper";
import ModalCard from "../../components/ModalCard";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import ProductDetailsTab from "../AddProductNew/Tabs/ProductDetailsTab";
import ProductImageSpecificationTab from "../AddProductNew/Tabs/ProductImageSpecificationTab";
import ProductONDCTab from "../AddProductNew/Tabs/ProductONDCTab";
import ProductInfoPreview from "../AddProductNew/DataPreview/ProductInfoPreview";
import {
  getTimeToShipUnitValue,
  getTimeToShipValue,
  prepareAddNewProductPhotoData,
  prepareAddNewProductPostData,
  prepareAddProductPhotoData,
  prepareProductPreviewData,
  prepareUpdateNewProductPostData,
} from "../../utils/postDataHelpers/CatalogHelper";
import {
  getNameByCode,
  getValueByPath,
  parseDbObjectInsideArray,
} from "../../utils/validationHelper";
import {
  getProductSubCategoryOne,
  getProductSubCategoryTwo,
} from "../../reducers/AddProduct/AddProductSlice";
import { convertImagesToBase64 } from "../../utils/imageCropUtils/imgUrlToBase64";
import { clearCatalogData } from "../../reducers/businessPreview/businessPreviewSlice";

const EditProductContent = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [tab, setTab] = useState("product-details");

  const [showAlert, setShowAlert] = useState(false);
  const [addProductLoader, setAddProductLoader] = useState(false);

  const [productDetails, setProductDetails] = useState(null);
  const [productDetailsLoader, setProductDetailsLoader] = useState(false);

  const [ondcProductSubCatList, setOndcProductSubCatList] = useState([]);

  const { countryList, countryListLoading, gstRates, allDeliveryMethod } =
    useSelector((state) => state.catalog);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [categoryInfo, setCategoryInfo] = useState({
    ondcEnabled: "yes",
    businessCategory: [],
    productCategory: [],
    productSubCategory: [],
    ondcCategory: [],
    ondcSubCategory: [],
    selectedBusinessCat: "",
    selectedProductCat: "",
    selectedProductSubCat: "",
    selectedOndcCat: "",
    selectedOndcSubCat: "",

    errors: null,
  });

  const [productInfo, setProductInfo] = useState({
    productName: "",
    brand: "",
    description: "",
    country: DEFAULT_COUNTRY,
    skuId: "",

    errors: null,
  });

  const [taxDetails, setTaxDetails] = useState({
    gstPercentage: "",
    productCode: { key: "EAN", value: "" },
  });

  const [productImages, setProductImages] = useState({
    productImgOne: "",
    productImgTwo: "",
    productImgThree: "",
    productImgFour: "",
  });

  const [ondcData, setOndcData] = useState(ONDC_DEFAULT_NEW_DATA);
  const [productImgUpdate, setProductImgUpdate] = useState([]);
  const [deleteImgList, setDeleteImgList] = useState([]);

  const [priceInfo, setPriceInfo] = useState({
    currency: "₹",
    originalPrice: "",
    OfferPrice: "",
    taxDetails: "false",
    gstHsnCode: "",

    errors: null,
  });

  const [availabilityInfo, setAvailabilityInfo] = useState({
    availability: "Yes",
    availabelQty: "",
    alertQty: "",
    minQty: "",
    maxQty: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: "",

    errors: null,
  });

  const [measurementInfo, setMeasurementInfo] = useState({
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
    netProductWeight: "",
    deliveryMethod: [],

    errors: null,
  });

  const [productSpec, setProductSpec] = useState({
    productSpecs: [{ code: "", value: [""] }],
    refLinks: [{ code: "", link: "" }],
  });

  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  const [ondcSpecificationData, setOndcSpecificationData] = useState([]);

  const [productPreviewData, setProductPreviewData] = useState(null);

  const [productCodeList, setProductCodeList] = useState(null);
  const [unitOfMeasurementData, setUnitOfMeasurementData] = useState([]);

  const [errorPageOne, setErrorPageOne] = useState(false);
  const [errorPageTwo, setErrorPageTwo] = useState(false);
  const [errorPageThree, setErrorPageThree] = useState(false);

  // Fetching product details
  const getProductDetails = async () => {
    setProductDetailsLoader(true);
    const productId = location?.state?.productId;
    const isRejectedProduct = location?.state?.isRejectedProduct || false;

    const businessId = sessionStorage.getItem("selectedBusinessId");

    if (isRejectedProduct) {
      const data = await getReviewProductDetailsAPI({ businessId, productId });
      if (data?.details) {
        const productMainCat = data?.details?.productMainCategory?.filter(
          (item) => item?.status == "active"
        );

        if (productMainCat?.length > 0 && productMainCat?.[0]?.ondcCode) {
          const ondcSubCatData = await getProductSubCategoryOneAPI(
            productMainCat?.[0]?.ondcCode
          );
          setOndcProductSubCatList(ondcSubCatData?.details || []);
        }

        setProductDetails(data?.details);
        setProductDetailsLoader(false);
      }
    } else {
      const data = await getViewProductDetailsAPI(productId);
      if (data?.product) {
        const productMainCat = data?.product?.productMainCategory?.filter(
          (item) => item?.status == "active"
        );

        if (productMainCat?.length > 0 && productMainCat?.[0]?.ondcCode) {
          const ondcSubCatData = await getProductSubCategoryOneAPI(
            productMainCat?.[0]?.ondcCode
          );

          console.log("ondcSubCatData___:", ondcSubCatData);
          setOndcProductSubCatList(ondcSubCatData?.details || []);
        }

        setProductDetails(data?.product);
        setProductDetailsLoader(false);
      }
    }
  };

  const autoFillOndcSpecification = async (productDetails) => {
    console.log("productDetailsProductDetails", productDetails);
    const specificationPayload = {
      pscCode1: `${productDetails?.productMainCategory?.[0]?.ondcCode}${productDetails?.productSubCategory?.[0]?.ondcSubCode}`,
      status: "active",
    };
    const fullSpecificationPayload = {
      domainCode: productDetails?.productMainCategory?.[0]?.ondcCode,
      status: "active",
    };
    const specification = await getOndcSpecificationAPI(specificationPayload);
    const fullSpecification = await getOndcFullSpecificationAPI(
      fullSpecificationPayload
    );

    const mapByCode = fullSpecification?.details?.reduce((acc, obj) => {
      acc[obj.code] = obj;
      return acc;
    }, {});

    const array = specification?.details?.[0]?.attributes?.map((obj1) => {
      // Check if there is a matching object in the second array based on code
      if (mapByCode[obj1?.value]) {
        console.log(
          "specificationInputValue",
          productDetails,
          mapByCode[obj1?.value]?.dbObjectName,
          getValueByPath(productDetails, mapByCode[obj1?.value]?.dbObjectName)
        );
        // Merge properties from the second array object to the first array object
        console.log("map", mapByCode[obj1?.value]?.type);
        if (mapByCode[obj1?.value]?.dbObjectName === "list") {
          console.log("hit585");
          if (mapByCode[obj1?.value]?.type === "codeValue") {
            const colorList = extractNameCodePairs(
              mapByCode[obj1?.value]?.values
            );
            const colorCode = getValueByCode(
              productDetails?.ondc?.attributeList,
              mapByCode[obj1?.value]?.code
            );
            const colourName = getNameByCode(colorList, colorCode);
            return {
              ...obj1,
              required: obj1?.code,
              ...mapByCode[obj1?.value],
              inputValue: colourName || "",
              options: extractNames(mapByCode[obj1?.value]?.values),
              nameCodes: extractNameCodePairs(mapByCode[obj1?.value]?.values),
            };
          }
          if (mapByCode[obj1?.value]?.display === "N") {
            return {
              ...obj1,
              required: "o",
              ...mapByCode[obj1?.value],
              inputValue: mapByCode[obj1?.value]?.defaultValue,
            };
          }

          if (mapByCode[obj1?.value]?.type === "dependentCode") {
            return {
              ...obj1,
              required: obj1?.code,
              ...mapByCode[obj1?.value],
              inputValue:
                getValueByCode(
                  productDetails?.ondc?.attributeList,
                  mapByCode[obj1?.value]?.code
                ) || "",
              options: getOptionsForDependentCode(
                mapByCode[obj1?.value]?.dependentValues,
                specification?.details?.[0]?.pscCode1
              ),
            };
          }
          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            inputValue:
              getValueByCode(
                productDetails?.ondc?.attributeList,
                mapByCode[obj1?.value]?.code
              ) || "",
            // mapByCode[obj1?.value]?.type === "default"
            //   ? mapByCode[obj1?.value]?.values?.[0]
            //   : "",
          };
        }
        if (mapByCode[obj1?.value]?.display === "N") {
          return {
            ...obj1,
            required: "o",
            ...mapByCode[obj1?.value],
            inputValue: mapByCode[obj1?.value]?.defaultValue,
          };
        }
        if (mapByCode[obj1?.value]?.type === "codeValue") {
          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            inputValue: "",
            options: extractNames(mapByCode[obj1?.value]?.values),
            nameCodes: extractNameCodePairs(mapByCode[obj1?.value]?.values),
          };
        }
        if (mapByCode[obj1?.value]?.type === "radioButton") {
          const options = mapByCode[obj1?.value]?.radioOptions;

          const radioOptions = options?.map((option) => {
            return {
              ...option,
              value: getValueByPath(productDetails, option?.key),
            };
          });

          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            radioOptions,
          };
        }
        return {
          ...obj1,
          required: obj1?.code,
          ...mapByCode[obj1?.value],
          inputValue:
            getValueByPath(
              productDetails,
              mapByCode[obj1?.value]?.dbObjectName
            ) || "",
          // mapByCode[obj1?.value]?.type === "default"
          //   ? mapByCode[obj1?.value]?.values?.[0]
          //   : "",
        };
      } else {
        // If no match found, simply return the first array object
        return obj1;
      }
    });

    console.log(
      "codeMapDataSpecification",
      mapByCode,
      specification?.details?.[0]?.attributes,
      fullSpecification,
      array
    );

    setOndcSpecificationData(array);
  };

  // Get Unit of measurement
  const getUnitsData = async () => {
    const response = await getUnitOfMeasurementApi();
    setUnitOfMeasurementData(() => response?.details || []);
    console.log("getUnitsData:", response);
  };

  // Converting all photo urls to base64 string
  const convertURLtoBase64String = async (photos) => {
    const selector = [
      "productImgOne",
      "productImgTwo",
      "productImgThree",
      "productImgFour",
    ];

    const base64StringList = await convertImagesToBase64(photos);
    console.log("base64StringList:", base64StringList);
    console.log("PRODUCT_IMAGES___:", photos);

    const photoListBase64 = [];

    // Removing inactive photos
    const filteredList = photos?.filter(
      (photo) => photo?.status !== "inactive"
    );

    filteredList.forEach((item, index) => {
      const data = {
        dataURL: base64StringList[index],
        fileDetails: {
          name: item?.name,
          type: "image/jpeg",
        },
        index: index + 1,
        selector: selector[index],
        docType: item?.docType,
        docURL: item?.docURL,
        // id and key for drag & drop
        id: index + 1,
        key: selector[index],
      };

      photoListBase64.push(data);
    });

    setProductImgUpdate(photoListBase64);
    console.log("photoListWithBase64:", photoListBase64);
  };

  const autoFillCategotyInfo = async () => {
    if (activeBusinessInfo && productDetails) {
      const businessCat = productDetails?.productMainCategory?.[0] || null;
      const productCat = productDetails?.productSubCategory?.[0] || null;
      const prodSubCat = productDetails?.productSubCategory2?.[0] || null;

      const ondcMainCat = productDetails?.productMainCategory?.[0] || null;
      const ondcSubCat = productDetails?.productSubCategory?.[0] || null;

      dispatch(getProductSubCategoryOne(businessCat?.code));
      dispatch(
        getProductSubCategoryTwo({
          pcCode: productCat?.pcCode,
          pscCode1: productCat?.pscCode1,
        })
      );

      let ondcSubCatArray = [];
      if (productDetails?.ondcEnabled?.toLowerCase() == "yes") {
        const response = await getProductSubCategoryOneAPI(
          ondcMainCat?.ondcCode
        );
        ondcSubCatArray = response?.details;
        const productCodeResponse = await getProductCodeAPI({
          domainCode: ondcMainCat?.ondcCode,
          status: "active",
        });

        if (productCodeResponse?.details?.length > 0) {
          setProductCodeList(productCodeResponse?.details);
        } else {
          setProductCodeList(PRODUCT_CODE_DEFAULT_LIST);
        }
      }

      const catData = {
        ...categoryInfo,
        ondcEnabled: productDetails?.ondcEnabled || "no",

        businessCategory: activeBusinessInfo?.businessCategories || [],
        productCategory: productDetails?.productSubCategory || [],
        productSubCategory: productDetails?.productSubCategory2 || [],

        ondcCategory: activeBusinessInfo?.businessONDCCategories || [],
        ondcSubCategory: ondcSubCatArray || [],

        selectedBusinessCat: {
          code: businessCat?.code || "",
          name: businessCat?.value || "",
          status: "active",
        },
        selectedProductCat: {
          code: productCat?.code || "",
          value: productCat?.value || "",
          pcCode: productCat?.pcCode || "",
          status: "active",
          pscCode1: productCat?.pscCode1 || "",
        },
        selectedProductSubCat: {
          code: prodSubCat?.code || "",
          value: prodSubCat?.value || "",
          pcCode: prodSubCat?.pcCode || "",
          psCode: prodSubCat?.psCode || "",
          status: "active",
          pscCode2: prodSubCat?.pscCode2 || "",
        },
        selectedOndcCat: {
          code: ondcMainCat?.ondcCode || "",
          name: ondcMainCat?.ondcValue || "",
          status: "active",
        },
        selectedOndcSubCat: {
          pcCode: "",
          scCode1: ondcSubCat?.ondcSubCode || "",
          pscCode1: "",
          productSubCategory1: ondcSubCat?.ondcSubValue || "",
        },
      };

      console.log("CAT_DATA___ProdInfo:", productDetails);
      console.log("CAT_DATA___:", catData);
      setCategoryInfo(catData);
    }
  };

  const validateProductDetailsFormOne = () => {
    let categoryErr = null;
    let productInfoErr = null;
    let taxInfoErr = null;

    console.log("validateProductDetailsFormOne__categoryInfo:", categoryInfo);
    console.log("validateProductDetailsFormOne__productInfo:", productInfo);
    console.log("validateProductDetailsFormOne__taxDetails:", taxDetails);

    // ONDC categories
    if (categoryInfo?.ondcEnabled === "yes") {
      if (!categoryInfo?.selectedOndcCat) {
        categoryErr = {
          ...categoryErr,
          selectedOndcCat: "Select ONDC category",
        };
      }
      if (!categoryInfo?.selectedOndcSubCat) {
        categoryErr = {
          ...categoryErr,
          selectedOndcSubCat: "Select ONDC sub category",
        };
      }
    }

    // Webstore Category
    if (!categoryInfo?.selectedBusinessCat) {
      categoryErr = {
        ...categoryErr,
        selectedBusinessCat: "Select business category",
      };
    }
    if (!categoryInfo?.selectedProductCat) {
      categoryErr = {
        ...categoryErr,
        selectedProductCat: "Select product category",
      };
    }

    // Product Info
    if (!productInfo?.productName) {
      productInfoErr = { ...productInfoErr, productName: "Enter product name" };
    }
    if (!productInfo?.description) {
      productInfoErr = { ...productInfoErr, description: "Enter description" };
    }
    if (!productInfo?.country) {
      productInfoErr = { ...productInfoErr, country: "Select origin" };
    }

    // Tax Info

    if (!taxDetails?.gstPercentage) {
      taxInfoErr = { ...taxInfoErr, gstPercentage: "Select GST percentage" };
    }

    console.log(
      "validateProductDetailsFormOne__ERRORS:",
      productInfoErr,
      categoryErr
    );

    if (categoryErr || productInfoErr || taxInfoErr) {
      setErrorPageOne(true);
      if (categoryErr) {
        setCategoryInfo((prevState) => ({
          ...prevState,
          errors: categoryErr,
        }));
      }

      if (productInfoErr) {
        setProductInfo((prevState) => ({
          ...prevState,
          errors: productInfoErr,
        }));
      }

      if (taxInfoErr) {
        setTaxDetails((prevState) => ({
          ...prevState,
          errors: taxInfoErr,
        }));
      }
    } else {
      setErrorPageOne(false);
      setCategoryInfo((prevState) => ({
        ...prevState,
        errors: null,
      }));

      setProductInfo((prevState) => ({
        ...prevState,
        errors: null,
      }));
      // setTab("product-ondc");
      setTab("product-images");
      window.scrollTo(0, 0);
    }
  };

  const validateProductDetailsFormTwo = () => {
    document.querySelector("body").focus();

    let productImgErr = null;
    let priceInfoErr = null;
    let availabilityErr = null;
    let measurementErr = null;
    let productSpecErr = null;

    console.log("validateProductDetailsFormTwo__Info", {
      productImages,
      productImgUpdate,
      priceInfo,
      availabilityInfo,
      measurementInfo,
      productSpec,
    });

    if (productImgUpdate?.length === 0) {
      productImgErr = {
        ...productImgErr,
        productImg: "Upload atleast one product photo",
      };
    }

    if (location.state?.isCopyProduct) {
      // Price Info
      if (!priceInfo?.originalPrice) {
        priceInfoErr = {
          ...priceInfoErr,
          originalPrice: "Please fill out this field",
        };
      }

      if (priceInfo?.originalPrice && Number(priceInfo?.originalPrice) <= 0) {
        priceInfoErr = {
          ...priceInfoErr,
          originalPrice: "Original price should be greater than 0",
        };
      }

      if (!priceInfo?.OfferPrice) {
        priceInfoErr = { ...priceInfoErr, OfferPrice: true };
      }

      if (Number(priceInfo?.OfferPrice) > Number(priceInfo?.originalPrice)) {
        priceInfoErr = { ...priceInfoErr, priceError: true };
      }

      // Availability Info
      if (!availabilityInfo?.availabelQty) {
        availabilityErr = {
          ...availabilityErr,
          availabelQty: "Enter available quantity",
        };
      }
      if (!availabilityInfo?.alertQty) {
        availabilityErr = {
          ...availabilityErr,
          alertQty: "Enter alert quantity",
        };
      }
      if (!availabilityInfo?.minQty) {
        availabilityErr = {
          ...availabilityErr,
          minQty: "Enter minimum quantity",
        };
      }
      if (!availabilityInfo?.maxQty) {
        availabilityErr = {
          ...availabilityErr,
          maxQty: "Enter maximum quantity",
        };
      }
      if (!availabilityInfo?.startDate) {
        availabilityErr = {
          ...availabilityErr,
          startDate: "Enter startDate quantity",
        };
      }
      if (!availabilityInfo?.endDate) {
        availabilityErr = {
          ...availabilityErr,
          endDate: "Enter endDate quantity",
        };
      }

      if (
        Number(availabilityInfo?.availabelQty) <=
          Number(availabilityInfo?.alertQty) ||
        Number(availabilityInfo?.availabelQty) <
          Number(availabilityInfo?.maxQty)
      ) {
        availabilityErr = {
          ...availabilityErr,
          availabilityError:
            "Available Quantity should be greater than Alert Quantity & Maximum Quantity Per Order",
        };
      }

      if (Number(availabilityInfo?.maxQty) < Number(availabilityInfo?.minQty)) {
        availabilityErr = {
          ...availabilityErr,
          maxQtyError:
            "Maximum Quantity Per Order should be greater than Minimum Quantity Per Order",
        };
      }

      if (
        Number(availabilityInfo?.alertQty) < Number(availabilityInfo?.minQty)
      ) {
        availabilityErr = {
          ...availabilityErr,
          alertQtyError: "Alert quantity must be more than Min order quantity",
        };
      }

      if (
        moment(availabilityInfo?.startDate)?.isAfter(
          moment(availabilityInfo?.endDate)
        )
      ) {
        availabilityErr = {
          ...availabilityErr,
          startDate: "Start date should be less than End date",
        };
      }
    }

    // Measurement Info
    if (!measurementInfo?.weight || measurementInfo?.weight == 0) {
      measurementErr = { ...measurementErr, weight: "Enter product weight" };
    }

    if (
      !measurementInfo?.netProductWeight ||
      measurementInfo?.netProductWeight == "0"
    ) {
      measurementErr = {
        ...measurementErr,
        netProductWeight: "Enter net product weight",
      };
    }

    if (
      measurementInfo?.netProductWeight > "0" &&
      Number(measurementInfo?.netProductWeight) <
        Number(measurementInfo?.weight)
    ) {
      measurementErr = {
        ...measurementErr,
        netProductWeight:
          "Net product weight should be greater than product weight",
      };
    }

    // Product Specs
    const isSpecError = productSpec?.productSpecs?.some(
      (item) =>
        (item?.code === "" && item?.value?.[0] !== "") ||
        (item?.code !== "" && item?.value?.[0] === "")
    );

    // console.log("validateProductDetailsFormTwo__isSpecError__:", isSpecError);
    if (isSpecError) {
      productSpecErr = {
        ...productSpecErr,
        productSpecs: "Enter the label and value",
      };
    }

    const isRefLinkError = productSpec?.refLinks?.some(
      (item) =>
        (item?.code === "" && item?.link !== "") ||
        (item?.code !== "" && item?.link === "")
    );

    // console.log("validateProductDetailsFormTwo__isRefLinkError__:", isRefLinkError);
    if (isRefLinkError) {
      productSpecErr = {
        ...productSpecErr,
        refLinks: "Enter the label and value",
      };
    }

    if (
      productImgErr ||
      priceInfoErr ||
      availabilityErr ||
      measurementErr ||
      productSpecErr
    ) {
      setErrorPageTwo(true);
      if (productImgErr) {
        setProductImages((prevState) => ({
          ...prevState,
          errors: productImgErr,
        }));
      }

      if (priceInfoErr) {
        setPriceInfo((prevState) => ({
          ...prevState,
          errors: priceInfoErr,
        }));
      }

      if (availabilityErr) {
        setAvailabilityInfo((prevState) => ({
          ...prevState,
          errors: availabilityErr,
        }));
      }

      if (measurementErr) {
        setMeasurementInfo((prevState) => ({
          ...prevState,
          errors: measurementErr,
        }));
      }

      if (productSpecErr) {
        setProductSpec((prevState) => ({
          ...prevState,
          errors: productSpecErr,
        }));
      }
    } else {
      setErrorPageTwo(false);
      setPriceInfo((prevState) => ({
        ...prevState,
        errors: null,
      }));

      setAvailabilityInfo((prevState) => ({
        ...prevState,
        errors: null,
      }));

      setMeasurementInfo((prevState) => ({
        ...prevState,
        errors: null,
      }));

      setProductSpec((prevState) => ({
        ...prevState,
        errors: null,
      }));

      setProductImages((prevState) => ({
        ...prevState,
        errors: null,
      }));

      if (categoryInfo?.ondcEnabled === "yes") {
        setTab("product-ondc");
        window.scrollTo(0, 0);
      } else {
        const previewData = prepareProductPreviewData({
          categoryInfo,
          productInfo,
          taxDetails,
          productImgUpdate,
          priceInfo,
          availabilityInfo,
          measurementInfo,
          productSpec,
          ondcData,
          ondcSpecificationData,
        });

        console.log("previewData__;", previewData);
        setProductPreviewData(previewData);
        setTab("preview");
        window.scrollTo(0, 0);
      }
    }
  };

  // ONDC

  const validateOndc = (isSubmit) => {
    console.log("ondcSpecificationData", ondcSpecificationData);
    console.log("ondcData", ondcData);

    // handleSetValue("errors", null, ondcData, setOndcData);

    let errors = null;
    let specificationError = false;

    if (!ondcData?.timeToShip || ondcData?.timeToShip == "0") {
      errors = { ...errors, timeToShip: "Enter time to ship" };
    }

    if (
      !ondcData?.returnTerms?.returnPeriod ||
      ondcData?.returnTerms?.returnPeriod == "0"
    ) {
      errors = { ...errors, returnPeriod: "Enter return period" };
    }

    if (ondcData?.cancelTerms?.cancellable?.toLowerCase() === "yes") {
      if (!ondcData?.cancelTerms?.cancellationFeePercentage) {
        errors = {
          ...errors,
          cancellationFeePercentage: "Enter cancellation fee percentage",
        };
      }
      if (
        ondcData?.cancelTerms?.cancellationFeePercentage > 0 &&
        ondcData?.cancelTerms?.cancellationFeePercentage > 100
      ) {
        errors = {
          ...errors,
          cancellationFeePercentage:
            "Cancellation fee percentage should be less than or equal to 100",
        };
      }
    }

    if (!ondcData?.productCode?.key) {
      errors = { ...errors, productCode1: "Select product code" };
    }
    if (
      !productCodeList?.some(
        (product) => product.prodictCode === ondcData?.productCode?.key
      )
    ) {
      errors = { ...errors, productCode1: "Select product code" };
    }

    if (!ondcData?.productCode?.value) {
      errors = { ...errors, productCode: "Enter product code" };
    }

    if (ondcData?.productCode?.key && ondcData?.productCode?.value) {
      const productCodeLookup = productCodeList?.find(
        (obj) => obj?.prodictCode === ondcData?.productCode?.key
      );

      const regex = new RegExp(
        productCodeLookup?.validation?.[0]
          ?.replace(/d/g, "\\d")
          ?.replace(/\./g, "\\.")
      );

      if (!regex.test(ondcData?.productCode?.value)) {
        errors = {
          ...errors,
          productCode: `Enter valid ${countMatch(
            productCodeLookup?.validation?.[0]
              ?.replace(/d/g, "\\d")
              ?.replace(/\./g, "\\.")
          )} digit product code`,
        };
      }
      console.log(
        "productCodeLookup",
        productCodeLookup,
        regex,
        ondcData?.productCode?.value
      );
    }

    if (!ondcData?.unitOfMeasure?.unit) {
      errors = { ...errors, unitOfMeasure1: "Select Unit of measure" };
    }

    if (
      !unitOfMeasurementData?.some(
        (unit) => unit.value === ondcData?.unitOfMeasure?.unit
      )
    ) {
      errors = { ...errors, unitOfMeasure1: "Select Unit of measure" };
    }

    if (!ondcData?.unitOfMeasure?.value) {
      errors = { ...errors, unitOfMeasure: "Enter Unit of measure" };
    }

    // Validations
    const updatedArray = ondcSpecificationData?.map((item) => {
      if (item?.type === "default") {
        return item;
      }
      if (
        !item?.inputValue &&
        item?.required?.toLowerCase() === "m" &&
        item?.type !== "radioButton"
      ) {
        specificationError = true;
        return { ...item, error: "Please enter, it's a mandatory value" };
      }
      if (
        item?.required?.toLowerCase() === "m" &&
        item?.type == "radioButton" &&
        item?.radioOptions?.every((obj) => obj.value === "")
      ) {
        specificationError = true;
        return { ...item, error: "Please select, it's a mandatory value" };
      }

      if (!item?.inputValue || item?.validation?.length === 0) {
        return { ...item, error: "" };
      }

      // const validationArray = JSON.parse(item?.validation);

      // console.log("validationArray", validationArray);

      // if (validationArray?.length === 0) {
      //   return { ...item, error: "" };
      // }

      for (let validation of item?.validation) {
        if (validation.substring(0, 5) !== "regex") {
          if (validation === item?.inputValue) {
            return { ...item, error: "" };
          }
        } else {
          const regexString = validation.match(/regex\((.+)\)/)[1];
          const regexPattern = regexString
            .replace(/d/g, "\\d")
            .replace(/\./g, "\\.");
          const regex = new RegExp(regexPattern);
          console.log(
            "regex inputValue",
            validation,
            regexPattern,
            item?.inputValue
          );
          if (regex.test(item?.inputValue)) {
            console.log("validation success");
            return { ...item, error: "" };
          }
        }
      }

      specificationError = true;
      return { ...item, error: "Please enter valid format" };
    });

    const dbSpecificationObj = parseDbObjectInsideArray(updatedArray);
    console.log("Validated Array", updatedArray);
    console.log("Db SpecificationObj", dbSpecificationObj);

    setOndcSpecificationData(updatedArray);

    if (errors) {
      handleSetValue("errors", errors, ondcData, setOndcData);
    } else {
      handleSetValue("errors", null, ondcData, setOndcData);
    }

    if (errors || specificationError) {
      setErrorPageThree(true);
      console.log("ONDC NOT VALIDATED");
    } else {
      setErrorPageThree(false);
      if (isSubmit) {
        if (location.state?.isCopyProduct) {
          addCopyProductDetails(dbSpecificationObj);
        } else {
          updateProductDetails(dbSpecificationObj);
        }
      } else {
        const previewData = prepareProductPreviewData({
          categoryInfo,
          productInfo,
          taxDetails,
          productImgUpdate,
          priceInfo,
          availabilityInfo,
          measurementInfo,
          productSpec,
          ondcData,
          ondcSpecificationData,
        });

        console.log("previewData__;", previewData);
        setProductPreviewData(previewData);
        setTab("preview");
        window.scrollTo(0, 0);
        // updateProductDetails(dbSpecificationObj);
        console.log("ONDC VALIDATED");
      }
    }
  };

  const updateProductDetails = async (dbSpecificationObj = null) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const productPhotosAPI = productDetails?.productPhoto;

    const deletePhotoList = [];
    const uploadPhotoList = [];

    let index = 0;

    if (productImgUpdate?.length > 0) {
      for (const item of productImgUpdate) {
        if (productPhotosAPI?.length > index) {
          if (item?.docType !== productPhotosAPI[index]?.docType) {
            console.log("Change_Photo:", item, productPhotosAPI[index]);
            deletePhotoList.push(productPhotosAPI[index]?.docType);
            uploadPhotoList.push(item);
          } else {
            console.log("No_Change_Photo:", item, productPhotosAPI[index]);
          }
        } else {
          uploadPhotoList.push(item);
        }
        index = index + 1;
      }
    }

    console.log("UpdateIMGList:", productImgUpdate);

    console.log("deletePhotoList:", deletePhotoList);
    console.log("deleteImgList:", deleteImgList);
    console.log("uploadPhotoList:", uploadPhotoList);

    setAddProductLoader(true);

    const combinedDeleteImg = [...deletePhotoList, ...deleteImgList];

    // Remove duplicates using a Set
    const uniqueImgs = [...new Set(combinedDeleteImg)];

    console.log("uniqueImgs___:", uniqueImgs);

    // Deleting Photos
    let deletedImages = 0;
    for (const imageId of uniqueImgs) {
      const deleteRes = await deleteProductPhoto(imageId);
      if (deleteRes?.success) deletedImages = deletedImages + 1;
    }

    if (deletedImages > 0) {
      console.log(`${deletedImages} Photos deleted`);
    }

    // Uploading Photos
    let uploadedImages = 0;
    for (const item of uploadPhotoList) {
      const uploadRes = await addProductPhoto(
        item?.dataURL,
        item?.fileDetails,
        item?.index
      );
      if (uploadRes?.success) uploadedImages = uploadedImages + 1;
    }

    if (uploadedImages > 0) {
      console.log(`${uploadedImages} Photos Uploaded`);
    }

    const postData = prepareUpdateNewProductPostData({
      businessId,
      businessInfo: activeBusinessInfo,
      productDetails,
      productInfo,
      categoryInfo,
      priceInfo,
      taxDetails,
      productSpec,
      measurementInfo,
      availabilityInfo,
      subscriptionInfo: null,
      ondcInfo: ondcData,
      ondcUIControl: null,
      ondcDbObj: dbSpecificationObj,
      ondcSpecificationData,
    });

    console.log("UpdatePostData:", postData);

    // Updating product details
    if (location.state?.isPendingProduct) {
      const response = await uploadPendingProductApi(postData);

      if (response?.success) {
        dispatch(clearCatalogData());
        setAddProductLoader(false);
        setShowAlert(true);
      } else {
        setAddProductLoader(false);
        setShowAlert(false);
      }
    } else {
      const response = await uploadProductApi(postData);

      if (response?.success) {
        dispatch(clearCatalogData());
        setAddProductLoader(false);
        setShowAlert(true);
      } else {
        setAddProductLoader(false);
        setShowAlert(false);
      }
    }

    // Updating product details
    // const response = await uploadProductApi(postData);

    // if (response?.success) {
    //   dispatch(clearCatalogData());
    //   setAddProductLoader(false);
    //   setShowAlert(true);
    // } else {
    //   setAddProductLoader(false);
    //   setShowAlert(false);
    // }
  };

  // Update Product Details
  const addCopyProductDetails = async (dbSpecificationObj = null) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    console.log("FORM_DATA:", {
      categoryInfo,
      productInfo,
      productImages,
      productImgUpdate,
      priceInfo,
      availabilityInfo,
      measurementInfo,
      productSpec,
    });

    const productPhoto = prepareAddNewProductPhotoData(productImgUpdate);
    console.log("UpdateIMGList:", productPhoto);

    const postData = prepareAddNewProductPostData({
      businessId,
      businessInfo: activeBusinessInfo,
      productPhoto,
      productInfo,
      categoryInfo,
      priceInfo,
      taxDetails,
      productSpec,
      measurementInfo,
      availabilityInfo,
      subscriptionInfo: null,
      ondcInfo: ondcData,
      ondcUIControl: null,
      ondcDbObj: dbSpecificationObj,
      ondcSpecificationData,
    });

    console.log("AddPostData:", postData);

    // Add new product details
    setAddProductLoader(true);
    const response = await addNewProductApi(postData);

    if (response?.success) {
      dispatch(clearCatalogData());
      setShowAlert(true);
      setAddProductLoader(false);
      return;
    }

    setAddProductLoader(false);
  };

  const addProductPhoto = async (dataURL, fileDetails, imageIndex) => {
    if (!dataURL || !imageIndex) return;

    const businessId = sessionStorage.getItem("selectedBusinessId");
    const filename = fileDetails?.name;
    const mimeType = fileDetails?.type;

    const params = {
      imageIndex,
      productId: location?.state?.productId,
      businessId,
      filename,
      mimeType,
      dataURL,
    };

    const postData = prepareAddProductPhotoData(params);
    console.log("photo_upload_postData:", postData);

    const response = await uploadProductPhotoApi(postData);
    console.log("response:", response);
  };

  const deleteProductPhoto = async (imageId) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const productImgData = productDetails?.productPhoto?.filter(
      (item, index) => imageId === item?.docType
    );

    if (productImgData?.length > 0) {
      // Delete Product photo
      const photoItem = productImgData?.[0];
      const postData = {
        imageId: photoItem?.docType,
        productID: productDetails?.id,
        businessID: businessId,
      };

      await deleteProductPhotoApi(postData);
    }

    console.log("delete_IMG_productData:", productDetails?.productPhoto);
    console.log("delete_imageId:", imageId);
    console.log("delete_IMG_DATA:", productImgData);
  };

  const handleSetValue = (name, value, state, setState) => {
    if (name !== "errors") {
      setState({
        ...state,
        [name]: value,
        errors: { ...state?.errors, [name]: "" },
      });
      return;
    }
    setState({
      ...state,
      [name]: value,
    });
  };

  // Get BreadCrumbs List
  const getBreadCrumblinks = (productName) => {
    const businessName = sessionStorage.getItem("costboWEBURL");
    return [
      {
        label: "Product Catalog",
        to: `/${businessName}/catalog`,
      },
      {
        label: location.state?.isCopyProduct ? "Copy Product" : "Edit Product",
        to: `#`,
      },
      {
        label: productName,
        to: `#`,
      },
    ];
  };

  const goback = (data) => {
    setTab(data);
    window.scrollTo(0, 0);
  };

  const handleCancel = () => {
    const webURL = sessionStorage.getItem("costboWEBURL");
    navigate(`/${webURL}/catalog`);
  };

  useEffect(() => {
    if (productDetails && countryList?.length > 0) {
      if (productDetails?.productPhoto?.length > 0) {
        if (!location.state?.isCopyProduct) {
          convertURLtoBase64String(productDetails?.productPhoto);
        }
      }

      const productOndcInfo = productDetails?.ondc;

      if (location.state?.isCopyProduct) {
        setProductInfo({
          copyProductName: productDetails?.productName || "",

          productName: "",
          brand: productDetails?.brand || "",
          description: productDetails?.productDescription || "",
          country: getSelectedCountryByCode(
            productDetails?.origin,
            countryList
          ),
          skuId: productDetails?.skuId || "",
        });

        setProductImages({
          productImgOne: "",
          productImgTwo: "",
          productImgThree: "",
          productImgFour: "",
        });
      } else {
        // Removing inactive photos
        const filteredPhotoList = productDetails?.productPhoto?.filter(
          (photo) => photo?.status !== "inactive"
        );

        setProductInfo({
          productName: productDetails?.productName || "",
          brand: productDetails?.brand || "",
          description: productDetails?.productDescription || "",
          country: getSelectedCountryByCode(
            productDetails?.origin,
            countryList
          ),
          skuId: productDetails?.skuId || "",
        });

        setProductImages({
          productImgOne: filteredPhotoList?.[0]?.docURL || "",
          productImgTwo: filteredPhotoList?.[1]?.docURL || "",
          productImgThree: filteredPhotoList?.[2]?.docURL || "",
          productImgFour: filteredPhotoList?.[3]?.docURL || "",
        });
      }

      autoFillCategotyInfo();

      setTaxDetails({
        gstPercentage: productDetails?.gstInfo?.gstPercentage || "",
        productCode: {
          key: productOndcInfo?.productCode?.key || "EAN",
          value: productOndcInfo?.productCode?.value || "",
        },
      });

      setPriceInfo({
        currency: productDetails?.currencySymbol || "₹",
        originalPrice: productDetails?.productOriginalPrice || "",
        OfferPrice: productDetails?.productSalePrice || "",
        taxDetails: productDetails?.taxDetails?.[0]?.value || false,
        gstHsnCode: productDetails?.gstInfo?.code || "",
      });

      setAvailabilityInfo({
        availability: productDetails?.availability?.[0] || "",
        availabelQty: productDetails?.availableQuantity || 0,
        alertQty: productDetails?.alertQuantity || 0,
        minQty: productDetails?.minOrderQuantity || 1,
        maxQty: productDetails?.maxOrderQuantity || 0,
        startDate: productDetails?.commerceStartDate || "",
        endDate: productDetails?.commerceEndDate || "",
      });

      setMeasurementInfo({
        weight: productDetails?.netProductWeight || "",
        length: productDetails?.measurementInfo?.length || 0,
        width: productDetails?.measurementInfo?.width || 0,
        height: productDetails?.measurementInfo?.height || 0,
        deliveryMethod: productDetails?.deliveryType || [],
        netProductWeight: productDetails?.measurementInfo?.weight || 0,
      });

      let specs = [];

      if (
        productDetails?.label?.length === 1 &&
        productDetails?.label?.[0]?.value?.length === 0
      ) {
        specs = [{ code: "", value: [""] }];
      } else {
        specs =
          productDetails?.label?.length > 0
            ? productDetails?.label
            : [{ code: "", value: [""] }];
      }

      setProductSpec({
        productSpecs: specs,
        // refLinks: [{ code: "", link: "" }],
        refLinks:
          productDetails?.refLink?.length > 0
            ? productDetails?.refLink
            : [{ code: "", link: "" }],
      });

      //  ONDC AutoFill

      const ondc = productDetails?.ondc;

      if (productDetails?.ondc) {
        console.log("Ondc HiT", ondc);
        const unitSymbol =
          ondc?.timeToShip?.charAt(ondc?.timeToShip?.length - 1) || "";

        setOndcData({
          ...ONDC_DEFAULT_NEW_DATA,
          timeToShipUnit: getTimeToShipUnitValue(unitSymbol),
          available_on_cod: ondc?.available_on_cod,
          fragile: ondc?.fragile == true,
          timeToShip: getTimeToShipValue(unitSymbol, ondc?.timeToShip),

          cancelTerms: {
            cancellable: ondc?.cancelTerms?.cancellable || "none",
            cancellationFeeAmount: null,
            cancellationFeePercentage:
              ondc?.cancelTerms?.cancellationFeePercentage || "",
            refundEligible: ondc?.cancelTerms?.refundEligible || "none",
          },

          returnTerms: {
            returnable: ondc?.returnTerms?.returnable || "none",
            returnPeriod: ondc?.returnTerms?.returnPeriod
              ? ondc?.returnTerms?.returnPeriod?.slice(1, -1)
              : "",
            seller_pickup_return:
              ondc?.returnTerms?.seller_pickup_return || "none",
          },

          productCode: {
            key: productOndcInfo?.productCode?.key,
            value: productOndcInfo?.productCode?.value || "",
          },

          unitOfMeasure: productDetails?.productDisplayUnitOfMeasure,
        });

        autoFillOndcSpecification(productDetails);
      }
    }
    return () => {};
  }, [productDetails, countryList]);

  useEffect(() => {
    getProductDetails();
    if (unitOfMeasurementData?.length === 0) {
      getUnitsData();
    }
    return () => {};
  }, []);

  return (
    <Box pb={2}>
      {tab !== "preview" && (
        <Box
          sx={{
            ...stickyHeader,
            display: { md: "none", xs: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            pb: { xs: 1, md: 2 },
          }}
        >
          <Typography
            component="h6"
            fontSize={{ xs: 15, md: 17 }}
            color={blackColor}
            fontWeight={{ xs: 600, md: 600 }}
            lineHeight="20px"
            display="inline"
          >
            <span style={{ color: matches ? blueColor04 : mobilePrimary }}>
              {location.state?.isCopyProduct
                ? `Product details copied from - `
                : `Edit Product - `}
            </span>{" "}
            {location.state?.isCopyProduct
              ? productDetails?.productName
              : productInfo?.productName}
          </Typography>
        </Box>
      )}

      {tab === "product-details" && categoryInfo?.selectedOndcCat ? (
        <ProductDetailsTab
          onNextClick={() => validateProductDetailsFormOne()}
          categoryData={categoryInfo}
          setCategoryData={setCategoryInfo}
          productInfo={productInfo}
          setProductInfo={setProductInfo}
          taxDetails={taxDetails}
          setTaxDetails={setTaxDetails}
          onValueChange={handleSetValue}
          setOndcSpecificationData={setOndcSpecificationData}
          setProductCodeList={setProductCodeList}
          titleFrom={
            location.state?.isCopyProduct
              ? `Product details copied from - `
              : `Edit Product - `
          }
          title={
            location.state?.isCopyProduct
              ? productDetails?.productName
              : productInfo?.productName
          }
          links={getBreadCrumblinks(
            location.state?.isCopyProduct
              ? productDetails?.productName
              : productInfo?.productName
          )}
          handleCancel={handleCancel}
          pageError={errorPageOne}
        />
      ) : (
        tab === "product-details" && <ThreeDotLoader />
      )}

      {tab === "product-images" && (
        <ProductImageSpecificationTab
          productInfo={productInfo}
          categoryData={categoryInfo}
          productImages={productImages}
          setProductImages={setProductImages}
          productImgUpdate={productImgUpdate}
          setProductImgUpdate={setProductImgUpdate}
          priceInfo={priceInfo}
          setPriceInfo={setPriceInfo}
          availabilityInfo={availabilityInfo}
          setAvailabilityInfo={setAvailabilityInfo}
          measurementInfo={measurementInfo}
          setMeasurementInfo={setMeasurementInfo}
          productSpec={productSpec}
          setProductSpec={setProductSpec}
          onValueChange={handleSetValue}
          onNextClick={() => validateProductDetailsFormTwo()}
          onBackClick={() => goback("product-details")}
          isOndcEnabled={categoryInfo?.ondcEnabled === "yes"}
          deleteImgList={deleteImgList}
          setDeleteImgList={setDeleteImgList}
          titleFrom={
            location.state?.isCopyProduct
              ? `Product details copied from - `
              : `Edit Product - `
          }
          title={
            location.state?.isCopyProduct
              ? productDetails?.productName
              : productInfo?.productName
          }
          links={getBreadCrumblinks(
            location.state?.isCopyProduct
              ? productDetails?.productName
              : productInfo?.productName
          )}
          handleCancel={handleCancel}
          pageError={errorPageTwo}
          isEdit={!location.state?.isCopyProduct}
        />
      )}
      {tab === "product-ondc" && (
        <ProductONDCTab
          productInfo={productInfo}
          categoryData={categoryInfo}
          ondcData={ondcData}
          setOndcData={setOndcData}
          onValueChange={handleSetValue}
          ondcSpecificationData={ondcSpecificationData}
          setOndcSpecificationData={setOndcSpecificationData}
          onNextClick={() => validateOndc()}
          onBackClick={() => goback("product-images")}
          productCodeList={productCodeList}
          unitOfMeasurementData={unitOfMeasurementData}
          titleFrom={
            location.state?.isCopyProduct
              ? `Product details copied from - `
              : `Edit Product - `
          }
          title={
            location.state?.isCopyProduct
              ? productDetails?.productName
              : productInfo?.productName
          }
          links={getBreadCrumblinks(
            location.state?.isCopyProduct
              ? productDetails?.productName
              : productInfo?.productName
          )}
          handleCancel={handleCancel}
          pageError={errorPageThree}
        />
      )}

      {tab === "preview" && (
        <ProductInfoPreview
          data={productPreviewData}
          productInfo={productInfo}
          categoryData={categoryInfo}
          onGoBack={() =>
            goback(
              categoryInfo?.ondcEnabled === "yes"
                ? "product-ondc"
                : "product-images"
            )
          }
          onSubmit={() => {
            if (categoryInfo?.ondcEnabled === "yes") {
              validateOndc(categoryInfo?.ondcEnabled === "yes");
            } else {
              if (location.state?.isCopyProduct) {
                addCopyProductDetails();
              } else {
                updateProductDetails();
              }
            }
          }}
          links={getBreadCrumblinks(
            location.state?.isCopyProduct
              ? productDetails?.productName
              : productInfo?.productName
          )}
          titleFrom={
            location.state?.isCopyProduct
              ? `Product details copied from`
              : `Edit Product`
          }
          title={
            location.state?.isCopyProduct
              ? productDetails?.productName
              : productInfo?.productName
          }
          handleCancel={handleCancel}
          loader={addProductLoader}
        />
      )}

      <ModalCard
        open={showAlert}
        handleClose={() => {
          // setShowAlert(false);
        }}
        width="48%"
      >
        <AlertMessagePopup
          isLoading={addProductLoader}
          text={`Product added successfully. Our team will review and approve within 24 hours.  New products will be available for customer ordering after approval.\nFor immediate approval, please contact via whatsapp - ‘8088422615’`}
          onClick={() => {
            const webURL = sessionStorage.getItem("costboWEBURL");
            const copyOrPending =
              location.state?.isCopyProduct || location.state?.isPendingProduct;
            navigate(
              `/${webURL}/${
                copyOrPending ? "catalogPending" : "catalogPendingExisting"
              }`
            );
          }}
        />
      </ModalCard>
    </Box>
  );
};

export default EditProductContent;

const AlertMessagePopup = ({ text, onClick, isLoading }) => {
  return (
    <Box sx={styles.alertCard}>
      {isLoading ? (
        <ThreeDotLoader />
      ) : (
        <>
          <Typography
            variant="body2"
            fontSize={14}
            fontWeight={500}
            textAlign="center"
            sx={{ whiteSpace: "pre-line", lineHeight: "26px", my: 1.2 }}
          >
            {text}
          </Typography>
          <Button sx={styles.alertBtn} variant="outlined" onClick={onClick}>
            GO TO CATALOG
          </Button>
        </>
      )}
    </Box>
  );
};

const styles = {
  alertCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    padding: "20px 20px 15px 20px",
    borderRadius: "8px",
  },

  alertBtn: {
    fontSize: "13px",
    fontWeight: "500",
    color: whiteColor,
    backgroundColor: primaryColor,
    padding: "6px 24px",
    my: "10px",
    "&:hover": {
      color: primaryColor,
      backgroundColor: whiteColor,
    },
  },
};
