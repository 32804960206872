import { Box, Button, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  blueColor04,
  whiteColor,
} from "../../../../configs/styles/muiThemes";

const DeleteAlert = ({ alertMsg, onCancel, onDelete }) => {
  return (
    <Box sx={{backgroundColor: whiteColor, p:2, borderRadius:'10px'}}>
      <Typography fontSize={16} fontWeight={600} color={blackColor}>
        Alert
      </Typography>

      <Typography fontSize={14} my={2.5} color={blackColor}>
        {alertMsg}
      </Typography>

      <Box display="flex" justifyContent={"flex-end"} mt={1.5} gap={2}>
        <Button sx={styles.cancelBtn} onClick={onCancel}>
          No
        </Button>

        <Button sx={styles.fillBtn} onClick={onDelete}>
          Yes
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteAlert;

const styles = {
  cancelBtn: {
    padding: "4px 14px",
    fontSize: "12px",
    fontWeight: 600,
    color: blueColor04,
    backgroundColor: whiteColor,
    border: "1.5px solid " + blueColor04,
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },

  fillBtn: {
    backgroundColor: blueColor04,
    padding: "4px 14px",
    fontSize: "12px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + blueColor04,
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
    },
  },
};
