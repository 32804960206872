import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackColor,
  blueColor04,
  borderTextbox,
  logoImgborder1,
  whiteColor,
} from "../../../../configs/styles/muiThemes";
import ModalCard from "../../../../components/ModalCard";
import FormInputBox from "../../../../components/Input/FormInputBox";
import { getImageIdFromDriveLink } from "../../../../reducers/catalogBulkUpload/catalogBulkValidationHelper";
import { convertImagesToBase64 } from "../../../../utils/imageCropUtils/imgUrlToBase64";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";

const ProductImgPreviewEdit = ({
  photoLink,
  openModal,
  setOpenModal,
  onValueChange,
  photoIndex,
}) => {
  const [text, setText] = useState(photoLink || "");
  const [previewImg, setPreviewImg] = useState("");
  const [isError, setIsError] = useState(false);

  const getSourceType = (imageUrl) => {
    if (imageUrl.includes("drive.google.com")) {
      return "googleDrive";
    } else if (imageUrl.includes("www.dropbox.com")) {
      return "dropbox";
    } else if (imageUrl.includes("storage.googleapis.com")) {
      return "googleStorage";
    } else if (/\.(jpeg|jpg|gif|png|bmp|svg)$/.test(imageUrl)) {
      return "directLink";
    } else {
      return "unknown";
    }
  };

  const ImageDisplay = (imageUrl, sourceType) => {
    if (sourceType === "googleDrive") {
      return `https://drive.google.com/uc?id=${imageUrl}`;
    } else if (sourceType === "dropbox") {
      return `https://www.dropbox.com/s/${imageUrl}?raw=1`;
    } else if (sourceType === "googleStorage") {
      return `https://storage.googleapis.com/your-bucket-name/${imageUrl}`;
    } else {
      return "";
    }
  };

  const getDisplayImageLink = async (imgLink) => {
    const sourceType = getSourceType(imgLink);

    if (sourceType === "unknown" || sourceType === "directLink") {
      setPreviewImg(imgLink);
    }

    if (sourceType === "googleDrive") {
      //   const imageId = getImageIdFromDriveLink(imgLink);
      //   if (imageId) {
      //     const displayImgLink = ImageDisplay(imageId, "googleDrive");

      //     console.log("HIY_googleDrive_displayImgLink", displayImgLink);
      //     setPreviewImg(displayImgLink);
      //     return;
      //   }

      setPreviewImg(imgLink);
      setIsError(true);
      return;
    }

    if (sourceType === "dropbox") {
      //   const imageId = getImageIdFromDriveLink(imgLink);
      //   if (imageId) {
      //     const displayImgLink = ImageDisplay(imageId, "dropbox");
      //     setPreviewImg(displayImgLink);
      //     return;
      //   }

      setPreviewImg(imgLink);
      setIsError(true);
      return;
    }
  };

  const handleOnChange = (data) => {
    if (/\.(jpeg|jpg|png|svg)$/.test(data)) {
      setPreviewImg(data);
    } else {
      setPreviewImg("");
    }
    setText(data);
  };

  useEffect(() => {
    // getDisplayImageLink(photoLink || "");
    if (/\.(jpeg|jpg|png|svg)$/.test(photoLink)) {
      setPreviewImg(photoLink);
    } else {
      setPreviewImg("");
    }
    setText(photoLink || "");
    return () => {};
  }, [photoLink]);

  return (
    <ModalCard
      open={openModal}
      handleClose={() => {
        setOpenModal(false);
      }}
      width="60%"
    >
      <Box sx={{ p: 3, backgroundColor: whiteColor, borderRadius: "10px" }}>
        <Typography
          variant="body1"
          fontSize={15}
          fontWeight={500}
          color={blackColor}
          mb={2}
        >
          Product Photo {photoIndex}
        </Typography>
        <Box>
          {previewImg ? (
            <Box
              component="img"
              sx={styles.previewImg}
              src={previewImg}
              // alt="product image"
            />
          ) : (
            <Box sx={styles.noImg}>
              <AddPhotoAlternateOutlinedIcon
                sx={{ fontSize: "55px", color: "rgba(0,0,0,0.7)" }}
              />
            </Box>
          )}

          <FormInputBox
            label="Product Photo Url"
            value={text}
            onChange={({ target }) => {
              handleOnChange(target.value);
            }}
          />

          <Stack alignItems="center">
            <Button
              variant="contained"
              disableElevation
              sx={styles.applyBtn}
              onClick={() => {
                onValueChange(text);
                setOpenModal(false);
              }}
            >
              DONE
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalCard>
  );
};

export default ProductImgPreviewEdit;

const styles = {
  applyBtn: {
    fontSize: 13,
    fontWeight: 500,
    backgroundColor: blueColor04,
    border: `1.5px solid ${blueColor04}`,
    color: whiteColor,
    p: "4px 30px",
    mt: 2,
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
    },
  },

  previewImg: {
    width: "200px",
    height: "200px",
    backgroundColor: "rgba(0,0,0,0.1)",
    border: logoImgborder1,
    borderRadius: "8px",
    mb: 2,
    objectFit: "contain",
  },

  noImg: {
    width: "200px",
    height: "200px",
    backgroundColor: "rgba(0,0,0,0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: logoImgborder1,
    borderRadius: "8px",
    mb: 2,
  },
};
