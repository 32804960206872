import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BGColor02 } from "../../configs/styles/muiThemes";
import { DISABLE_ALL_MENU } from "../../utils/data";
import DashboardMenu from "../../pages/Dashboard/DashboardMenu";
import DashboardHeader from "../../pages/Dashboard/DashboardHeader";
import { useLocation } from "react-router-dom";

const DashboardLayout = ({
  children,
  activeMenu,
  activeSubMenu,
  disableAll,
  disableAllEmpty,
  isLoading,
  hideBusinessData,
  handleOpenVideoGuide,
  handleAddBusiness,
  isAddBusinessDisabled,
  hideMobileMenu,
}) => {
  const location = useLocation();
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [userFrom, setUserFrom] = useState("");

  const getUserFromValue = () => {
    const queryParams = new URLSearchParams(location.search);
    const userFromValue = queryParams.get("userFrom");
    const sessionUserFrom = sessionStorage.getItem("userFrom");

    console.log("LOCATION____UERFROM___:", {userFromValue, sessionUserFrom});

    if (userFromValue === "app") {
      setUserFrom(userFromValue);
      sessionStorage.setItem("userFrom", userFromValue);
    } else if (sessionUserFrom === "app") {
      setUserFrom(sessionUserFrom);
    } else {
      setUserFrom("");
      sessionStorage.setItem("userFrom", "");
    }
  };

  useEffect(() => {
    getUserFromValue();

    return () => {};
  }, [location]);

  return (
    <Box position="relative">
      <Box sx={styles.stickyTop}>
        <DashboardHeader
          activeMenu={activeMenu}
          activeSubMenu={activeSubMenu}
          disableMenu={
            isLoading
              ? DISABLE_ALL_MENU
              : disableAll
              ? DISABLE_ALL_MENU?.filter((item) => item != "store")
              : []
          }
          handleOpenVideoGuide={handleOpenVideoGuide}
          handleAddBusiness={handleAddBusiness}
          isAddBusinessDisabled={isAddBusinessDisabled}
          isUserFromApp={userFrom === "app"}
          hideBusinessData={hideBusinessData}
          hideMobileMenu={hideMobileMenu}
        />
      </Box>

      <Box display={{ md: "flex", xs: "block" }} alignItems="flex-start">
        <Box sx={styles.menuStickyLeft}>
          <DashboardMenu
            activeMenu={activeMenu}
            activeSubMenu={activeSubMenu}
            disableMenu={
              isLoading
                ? DISABLE_ALL_MENU
                : disableAll
                ? DISABLE_ALL_MENU?.filter((item) => item != "store")
                : []
            }
            hideBusinessData={hideBusinessData}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            ...styles.content,
            mt: {
              md: "45px",
              xs:
                activeMenu != "store" && activeMenu != "howToVideoGuides"
                  ? "90px"
                  : "45px",
            },
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;

const styles = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    backgroundColor: BGColor02,
  },

  content: {
    p: { xs: "0px", md: "0px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 201,
    pb: 1.5,
  },

  menuStickyLeft: {
    display: { md: "block", xs: "none" },
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "calc(100vh - 45px)",
    // zIndex: 100,
    backgroundColor: BGColor02,
  },
};
