import { FormControlLabel, Radio, Typography } from "@mui/material";
import React from "react";
import { greyColor6 } from "../../configs/styles/muiThemes";

function RadiosBtnWithLabel({
  label,
  size,
  textSmall,
  onValuesChanged,
  checked,
  labelStyles,
  disabled,
}) {
  return (
    <FormControlLabel
      control={
        <Radio
          color="primary"
          checked={checked}
          size={size || "large"}
          onChange={() => {
            onValuesChanged();
          }}
          disabled={disabled}
          sx={{color:'#cdd1da', height:{xs:'30px', md:'auto'}}}
        />
      }
      label={
        <Typography
          variant="body1"
          color={greyColor6}
          fontSize={{
            md: textSmall ? "12px" : "14px",
            xs: textSmall ? "11px" : "12px",
          }}
          fontWeight={500}
          sx={labelStyles || {}}
        >
          {label}
        </Typography>
      }
    />
  );
}

export default RadiosBtnWithLabel;
