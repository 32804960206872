import { createTheme } from "@mui/material";

// export const primaryColor = "#01a4b5";
export const primaryColor = "#ff7b2c";
export const primaryColor2 = "#01a4b5";
export const secondaryColor = "#1732a4";
export const backgroundColor = "#f7f7f7";
export const descriptionColor = "#828282";
export const bodyColor = "#696969";
export const backgroundColor2 = "#ffe3d2";
export const errorTextColor = "#e50000";
export const authInfoColor = "#a2a5a7";
export const tableHeaderColor = "#e8eaed";
export const shareBgColor = "#edf1f3";
export const shareBgColor1 = "#25D366";

export const mobilePrimary = "#e83658";

export const headingTextColor = "#1c2437";
export const infoTextColor = "#585858";
export const registerBgColor = "#f9f9f9";
export const requiredStarColor = "#ff485f";
export const sendOTPBtnBgColor = "#a9acb3";

export const subTextColor = "#8b92a0";
export const titleColor = "#161616";
export const whiteColor = "#fff";
export const whiteColor01 = "#ebeff5";
export const blackColor = "#000";
export const labelColor = "#121212";
export const borderLineSearchColor = "#f0f0f0";
export const borderTextBoxColor = "#e8e8e8";
export const productBorderColor = "#d5d5d5";
export const cartBorderColor = "#d9d9d9";
export const bgGetCostboColor = "#fff5ee";
export const headingColor = "#252525";
export const loginPrimary = "#FF9F43";
export const greyShade10 = "#ababab";
export const greyShade7 = "#828282";
export const greenColor = "#34c518";
export const greenColor2 = "#00b293";
export const greenColor3 = "#299956";
export const greenColor4 = "#24b794";
export const greenColor5 = "#29C76F";
export const greenColor7 = "#27bd72";
export const greenColor6 = "#30c676";

export const newPrimaryColor = "#0295a4";

export const BGColor = "#f2f2f2";
export const BGColor02 = "#f5f6f8";
export const BGColor03 = "#f2f4f7";
export const BGColor04 = "#f1f0f5";
export const BGColor05 = "#f8f9fb";
export const orangeBgColor = "#fff9ea";

export const infoCardBG = "#d7f0f9";
export const saveBtnBG = "#ffe3d9";
export const saveBtnColor = "#fe820e";
export const nextBtnBG = "#c9c9c9";
export const backBtnColor = "#9d9d9d";
export const locationBtnBG = "#e2eef3";

export const blackShade01 = "#050505";
export const blackShade02 = "#737373";
export const blackShade03 = "#716d6d";
export const blackShade04 = "#242424";
export const blackShade05 = "#051e3b";
export const blackShade06 = "#3c3c3c";
export const blackShade07 = "#2b2d2f";
export const blackShade08 = "#575757";
export const blackShade58 = "#0e151b";
export const blackShade59 = "#053c51";
export const blackShade159 = "#022633";
export const blackShade09 = "#1d1d21";
export const blackShade10 = "#141f39";
export const blackShade18 = "#3d4551";
export const blackShade19 = "#0e1f42";
export const blackShade21 = "#767F91";
export const blackShade22 = "#12254b";
export const blackShade42 = "#232323";
export const blackShade43 = "#05122c";

export const blackShade23 = "#212735";
export const orangeShade11 = "#dcb34b";
export const orangeShade12 = "#fff6ec";
export const orangeShade13 = "#ffe1c0";
export const orangeShade14 = "#f2961d";
export const orangeShade15 = "#ff7e00";
export const orangeShade18 = "#ff7e00";

export const blackShade20 = "#051e3b";

export const eCommerceblackcolor1 = "#051e3b";

export const deleteBtbColor = "#959ca9";

export const whatsappBGColor = "#42be50";
export const facebookBGColor = "#4267B2";
export const instagramBGColor = "#cc0001";
export const linkedInBGColor = "#0072b1";
export const twitterBGColor = "#1DA1F2";
export const telegramBGColor = "#0088cc";

export const greyShade01 = "#959595";
export const greyShade02 = "#898989";
export const greyShade03 = "#9c9c9c";
export const greyShade04 = "#989898";
export const greyShade05 = "#666666";
export const greyShade06 = "#808080";
export const greyShade25 = "#8f8f8f";
export const greyShade08 = "#8e8e8e";
export const greyShade09 = "#e8eef7";
export const greyShade07 = "#4d4d4d";
export const greyShade80 = "#757575";
export const greyShade81 = "#a2aebe";
export const greyShade82 = "#9ca6b8";
export const greyShade83 = "#f0f2fa";
export const greyColor02 = "#e1e7ef";
export const greyColor03 = "#58647a";
export const blackColor01 = "#051e3b";
export const greyColor051 = "#7b8598";
export const greyColor052 = "#e8eaee";
export const greyColor053 = "#4a5364";
export const greyColor054 = "#eceef2";
export const greyColor056 = "#89909d";
export const greyColor057 = "#b4bac6";
export const greyColor058 = "#6a707c";
export const greyColor059 = "#7c8393";

export const orangeShade06 = "#f2a17a";
export const orangeShade07 = "#ffefe8";

export const purpleShade01 = "#6D4085";
export const purpleShade02 = "rgb(109 64 133 / 14%)";

export const brownShade01 = "#C2465D";
export const brownShade02 = "rgb(194 70 93 / 20%)";

export const BGcolor01 = "#f5f6f8";
export const border25 = "1px solid #e5e9f0";
export const border26 = "1px solid #e7eaef";

export const colorYellow = "#ffbc54";
export const colorGreen = "#29c770";
export const colorRed = "#ff3f40";
export const colorOrangeLight = "#fca5492e";

export const iconBG1 = "rgba(249, 110, 111, 0.12)";
export const iconBG2 = "rgba(40, 199, 111, 0.12)";

export const heroOverlayBG = "#1d2733";
export const heroSectionText = "#ff8a22";

export const planTextColor = "#9d9d9d";
export const pricingText1 = "#212B36";
export const pricingSubText1 = "#505760";

export const regiterInfoTestColor = "#212B38";
export const regiterSuccessCardBorder = "#e5e9f0";
export const regiterSuccessNameColor = "#212B37";
export const regiterSuccessIconColor = "#24d489";

export const pinkCardBG = "#fee1e1";
export const copyBtnColor = "#28C76F";
export const deleteBtnColor = "#f44336";
export const editBtnColor = "#01a4b5";
export const oldPriceColor = "#a4a4a4";
export const checkProductBtnColor = "#ff6d62";

export const progressCardBG = "#d6eaed";
export const orangeTextColor = "#ff6e3b";
export const progressBGColor = "#efefef";
export const navbarBGColor = "#0293a1";
export const addressBtnColor = "#f1f1f1";

export const bannerBgColor = "#f4f6fa";
export const bannerDescriptionColor = "#878787";
export const closedColor = "#e30d0d";
export const iconContainerBgColor = "#2598af";
export const iconContentColor = "#5d5d5d";
export const infoTextColor2 = "#6d6d6d";

export const greyColor1 = "#999999";
export const greyColor2 = "#e4e4e4";
export const greyColor3 = "#4f4f4f";
export const greyColor4 = "#939393";
export const greyColor5 = "#414b5d";
export const greyColor6 = "#7e8a9e";
export const greyColor7 = "#7b8598";
export const greyColor8 = "#023143";
export const greyColor9 = "#626d82";
export const greyColor10 = "#eef1f5";
export const greyColor11 = "#e2e5ec";
export const greyColor12 = "#e4e9ef";
export const greyColor13 = "#212529";
export const greyColor14 = "#6a6e76";
export const greyColor15 = "#3a3a3e";
export const greyColor16 = "#58647a";
export const greyColor17 = "#c7cdd8";
export const greyColor18 = "#dde1e9";
export const greyColor19 = "#dedfe0";
export const greyColor20 = "#474e58";
export const greyColor21 = "#cdd1da";
export const greyColor22 = "#47494e";
export const greyColor23 = "#1b1d20";
export const greyColor24 = "#051e3b";
export const greyColor25 = "#eaebee";
export const greyColor26 = "#788595";
export const greyColor27 = "#434a57";
export const greyColor28 = "#e1e7ef";
export const greyColor29 = "#303744";
export const greyColor30 = "#282828";
export const greyColor31 = "#6b6b6b";
export const greyColor32 = "#e7e7e7";
export const greyColor33 = "#8d9198";
export const greyColor34 = "#9ca3af";
export const greyColor35 = "#101517";
export const greyColor36 = "#727a88";
export const greyColor84 = "#eaecf1";
export const greyColor37 = "#abacad";
export const greyColor38 = "#c9d1e6";
export const greyColor39 = "#f4f5f9";
export const greyColor40 = "#808BA0";
export const greyColor41 = "#F0F3F7";
export const greyColor42 = "#e8eaf2";
export const greyColor45 = "#545454";
export const greyColor46 = "#e9ecf1";
export const greyColor47 = "#212735";
export const greyColor48 = "#808BA0";
export const greyColor49 = "#3d4262";
export const greyColor51 = "#19346a";
export const greyColor80 = "#8993a1";
export const greyColor50 = "#232323";

export const greyColorM01 = "#B4B8C5";
export const yellowColorM01 = "#FFA74B";
export const redColorM01 = "#E9436A";
export const greenColorM01 = "#00E096";


export const blueColorM01 = "#396AFF";
export const blueColorM02 = "#0095FF";


export const skyblueColorBG01 = "#CBEFFB";

export const blueColorBG01 = "#E7EDFF";
export const yellowColorBG01 = "#FFF5EA";
export const yellowColorBG02 = "#FFF2DE";
export const redColorBG01 = "#FFEEF2";


export const blueColor1 = "#053c51";
export const blueColor02 = "#1999b9";
export const blueColor03 = "#242d3e";
export const blueColor04 = "#3f53d8";
export const blueColor05 = "#159abc";
export const blueColor100 = "#05264E";

export const border27 = "1px solid #e2e5ec";
export const iconBGcolor01 = "#11cda0";
export const redShade02 = "#ff0000";
export const voiletShade01 = "#c94b85";
export const voiletColor01 = "#603d85";
export const voiletShade02 = "#f7efff";

export const lightBlueColor01 = "#50bca2";
export const lightBlueColor02 = "#f1f5ff";
export const lightBlueColor03 = "#e0eafa";
export const lightBlueColor04 = "#446CFF";
export const lightBlueColor05 = "#FFEFEF";
export const lightBlueColor06 = "#CBEFFB";

export const lightPinkColor01 = "#FFEEF2";

export const activeBGColor = "#d7f4e7";
export const activeTextColor = "#01b763";

export const pendingBGColor = "#ffe8cc";
export const pendingTextColor = "#ff8a00";

export const draftBGColor = "#b8c0cf";
export const draftTextColor = "#ffffff";

export const rejectedBGColor = "#ffd9d5";
export const rejectedTextColor = "#ee301d";

export const inactiveBGColor = "#ffd8ea";
export const inactiveTextColor = "#c94b85";
export const perpleColor1 = "#894bc9";

export const navBarColor = "#01a4b5";

export const greyShade13 = "#8b8b8b";
export const bgColor = "#f2f2f2";
export const copyrightTextColor = "#12092c";
export const blackShade25 = "#1f1f1f";
export const blackShade26 = "#2b2b2b";
export const blackShade27 = "#373737";
export const bgColor2 = "#FAFBFE";
export const bgColor3 = "#f9f9fb";
export const bgColor4 = "f5f6f8";
export const featureBgColor = "#daf0f4";

export const greyShade51 = "#989898";
export const greyShade52 = "#515151";
export const greyShade53 = "#2f2f2f";
export const greyShade54 = "#717171";
export const greyShade55 = "#eee9e9";
export const greyShade65 = "#7e8a9e";
export const greyShade68 = "#bac3c6";
export const greyShade168 = "#444c5b";
export const blueNeoColor = "#00bbcf";
export const blueShade1 = "#0aa4b5";
export const blueShade10 = "#D9E9FF";
export const greenShade10 = "#c1fdef";
export const greenShade11 = "#CFFFE2";
export const greenShade12 = "#e4ffd7";
export const perpleShade10 = "#F6D4FF";
export const redShade10 = "#FFE0D9";
export const orangeShade10 = "#FFEFCF";
export const greyShade85 = "#DDDDDD";
export const bgColor15 = "#8593ac";
export const bgColor25 = "#f8f6f1";
export const yellowColorStar = "#ffa500";
export const whatsappColor = "#4ac959";

export const greenStatusColor = "#169e6d";
export const orangeStatusColor = "#fca549";
export const redStatusColor = "#f43c3c";

export const redColor = "#d83f3f";
export const redColor05 = "#e62113";
export const redColor06 = "#cc6969";
export const redColor07 = "#E53834";

export const lightRedColor = "#fff5f5";
export const lightRedColor02 = "#feecec";

export const tealColor = "#17b3c4";
export const alertInfoCardBG = "#faeee0";
export const searchInputIconColor = "#b6b6b6";

export const stepperBGColor = "#d6dae3";
export const outOfStockColor = "#e53935";
export const categoryLabelColor = "#949494";

export const tableAvatarBGColor = "#ecedee";
export const affiliateCardBorderBottom = "#dce2ed";

export const authInputIconColor = "#a3aaba ";

export const displaySwitchRedColor = "#ff3845";
export const displaySwitchGreenColor = "#34d17e ";

export const yellowColor = "#fdca49";

export const redColor01 = "#f21112";

export const lightOrange01 = "#fff2e4";
export const lightPurple01 = "#E7EDFF";
export const lightSkyBlue01 = "#EBF7FF";

export const lightOrange02 = "#fff3dc";
export const lightOrange03 = "#FFF2E4";


export const lightGreen01 = "#dcfff7";
export const lightGreen02 = "#e2f8f0";
export const lightGreen03 = "#c6ebde";
export const lightGreen04 = "#ebfff5";
export const lightGreen14 = "#e1f8ed";
export const lightGreen15 = "#effff7";
export const greenColor02 = "#50bca2";
export const greenColor03 = "#02bd7e";
export const orangeColor02 = "#fca549";

export const shadeGrey01 = "#e6eaf0";

export const yellowShade01 = "#ffb700";
export const yellowShade02 = "#fff5dc";

// border
export const borderSearchBar = "1px solid" + borderLineSearchColor;
export const borderTextbox = "1px solid" + borderTextBoxColor;
export const errorborderTextbox = "1px solid #ff0000";
export const borderProduct = "1px solid" + productBorderColor;
export const borderCart = "1px solid" + cartBorderColor;
export const borderBackground = "1px solid" + backgroundColor;
export const logoImgborder = "1px solid #dfe4ed";
export const logoImgborder1 = "1px solid #e5e9f0";
export const fileUploadCardBorder = "1px solid #e8ebf1";
export const gstInactiveBorder = "1px solid #dee0e2";
export const gstActiveBorder = "2px solid #053c51";
export const orderCardBorder = "1px solid #edf0f4";
export const orderCardBorder2 = "1px dashed #d8d8d8";
export const orderCardBorder3 = "1px solid #F0F3F7";
export const orderCardHeadingBorder2 = "2px solid" + whiteColor;
export const catalogCardHeadingBorder = "1px solid" + whiteColor;
export const cardBoder = "1px solid #e7e8eb";
export const cardBoderlightBlue = "1px solid #B5ECFF";
export const cardBoderlightPink = "1px solid #FFE4EB";
export const cardBoderlightOrange = "1px solid #FFE9D1";

export const authInputBorder = "1px solid #d6dbe6";

export const borderOTPTextbox = "1px solid #737373";
export const btnGradientBgColor =
  "linear-gradient(to right, #074e68 0%, #053c51 100%)";

// export const menuGradient = 'linear-gradient(-154deg, #0195a3, #04656e)'
// export const menuGradient =
//   "linear-gradient(to right, #023143 40%, #074e68 100%)";
export const menuGradient =
  "linear-gradient(to right, #242d3e 40%, #242d3e 100%)";
export const sideContentGradient =
  "linear-gradient(to right, #053c51 40%, #074e68 100%)";

export const orangeShade01 = "#fca549";
export const orangeBgShade01 = "#fff3e6";
export const cardBoderlightBlue2 = "1px solid #D7E1FF";

export const redShade03 = "#ff2626";
export const redBgShade01 = "#ffeeee";

export const greenShade04 = "#42ad89";
export const greenBgShade01 = "#f5fbf9";
export const greenBgShade02 = "#24b794";
export const greenShade01 = "#28C76F";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    allVariants: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
    },
    h1: {
      fontWeight: 600,
      fontSize: "23px",
    },

    h2: {
      fontSize: "22px",
      fontWeight: 600,
    },

    h3: {
      fontSize: "18px",
      fontWeight: 600,
    },

    h4: {
      fontSize: "17px",
      fontWeight: 600,
    },

    h5: {
      fontSize: "16px",
      fontWeight: 600,
    },
    h6: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "26px",
    },
    subtitle1: {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: "normal",
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "normal",
    },
    body1: {
      fontSize: "13px",
      lineHeight: "23px",
    },
    body2: {
      fontSize: "12px",
      lineHeight: "normal",
    },
    caption: {
      fontSize: "16px",
    },
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: orangeTextColor,
    },
  },
});

export default theme;
