import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import {
  affiliateCardBorderBottom,
  bgColor15,
  blackShade05,
  blueColor02,
  blueColor04,
  eCommerceblackcolor1,
  greenColor2,
  greyColor6,
  greyColor8,
  logoImgborder1,
  whiteColor,
} from "../../configs/styles/muiThemes";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ProfileCharAvatar from "../../components/ProfileCharAvatar";

function CustomerPurchaceInfo({
  profileImage,
  customeName,
  customeOnBoardedDate,
  lastPurchasedDate,
  lastPurcahedAmount,
  numberOfPurchase,
  totalPurchase,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box sx={styles.infoBox}>
      <Box
        display="flex"
        // alignItems={{ xs: "flex-start", md: "center" }}
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" gap="60px" flex={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={2.9}>
              <Box
                display="flex"
                gap="10px"
                borderRight={{
                  md: `1px dashed ${affiliateCardBorderBottom}`,
                  xs: "none",
                }}
                alignContent={"center"}
              >
                {profileImage ? (
                  <Avatar
                    alt="remy sharp"
                    src={profileImage}
                    sx={{
                      width: {xs:"43px", md:"47px"},
                      height: {xs:"43px", md:"47px"},
                      display: { xs: "flex", md: "flex" },
                    }}
                  />
                ) : (
                  <ProfileCharAvatar
                    username={customeName || "Guest"}
                    border
                    size={matches ? "47px" : "43px"}
                  />
                )}
                <Box>
                  <Typography
                    fontSize={{ xs: 13.5, md: 14.5 }}
                    fontWeight={600}
                    color={blackShade05}
                  >
                    {customeName || "Guest"}
                  </Typography>
                  <Typography fontSize={12} fontWeight={400} color={bgColor15} mt={-0.5}>
                    Customer since {customeOnBoardedDate}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={2.2}>
              <AffiliateDiascription
                label="Latest Order Date"
                labelValue={lastPurchasedDate}
              />
            </Grid>
            <Grid item xs={6} md={2.3}>
              <AffiliateDiascription
                label="Latest Order Value"
                labelValue={
                  lastPurcahedAmount
                    ? `₹ ${
                        Number(lastPurcahedAmount)
                          ?.toFixed(2)
                          .replaceAll(
                            /\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g,
                            ","
                          ) || 0
                      }`
                    : 0
                }
              />
            </Grid>
            <Grid item xs={6} md={2.3}>
              <AffiliateDiascription
                label="No. of Orders"
                labelValue={numberOfPurchase || 0}
              />
            </Grid>
            <Grid item xs={6} md={2.3}>
              <AffiliateDiascription
                label="Total Order Value"
                labelValue={
                  totalPurchase
                    ? `₹ ${
                        Number(totalPurchase)
                          ?.toFixed(2)
                          .replaceAll(
                            /\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g,
                            ","
                          ) || 0
                      }`
                    : 0
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default CustomerPurchaceInfo;

const AffiliateDiascription = ({ label, labelValue, icon }) => {
  return (
    <Box
      flex={1}
      borderRight={{
        md: `1px dashed ${affiliateCardBorderBottom}`,
        xs: "none",
      }}
      pl={{ md: 1, xs: "none" }}
    >
      <Typography variant="h5" sx={styles.valueTitle}>
        {label}
      </Typography>
      <Typography sx={styles.value}>
        {labelValue}
        {icon}
      </Typography>
    </Box>
  );
};

const styles = {
  infoBox: {
    width: "100%",
    backgroundColor: whiteColor,
    baxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    padding: "15px 15px 10px 15px",
    border: logoImgborder1,
    borderRadius: "10px",
    mb: 2,
  },
  valueTitle: {
    color: greyColor6,
    fontSize: "13px",
    fontWeight: "400",
  },

  value: {
    color: blackShade05,

    fontSize: "13px",
    fontWeight: "600",
  },
  iconCircle: {
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: blueColor04,
    borderRadius: "50%",
    padding: "7px",
  },
};
