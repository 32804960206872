import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import { Box, Typography } from "@mui/material";
import {
  CheckRounded,
  LocalMallRounded,
  LocalShippingRounded,
} from "@mui/icons-material";
import {
  stepperBGColor,
  blackShade05,
  whiteColor,
  lightBlueColor01,
  greyColor7,
} from "../configs/styles/muiThemes";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    // top: 0,
    // right: 50,
    // marginTop: "-20px",
    // marginLeft: "0px",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.lineVertical}`]: {
      //   border: "1px dashed #a2aebe",
      // backgroundColor: lightBlueColor01,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.lineVertical}`]: {
      // backgroundColor: blackShade05,
      //   border: "1px dashed #a2aebe",
    },
  },
  [`& .${stepConnectorClasses.lineVertical}`]: {
    // width: "500%",
    height: "10vh",

    borderLeft: "3px dashed #a2aebe",
    // backgroundColor: stepperBGColor,
    // borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: whiteColor,
  zIndex: 1,
  color: stepperBGColor,
  width: 40,
  height: 40,
  border: `3px solid ${stepperBGColor}`,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    // border: `1px solid ${blackShade05}`,
    // backgroundColor: whiteColor,
    // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    // color: whiteColor,
  }),
  ...(ownerState.completed && {
    backgroundColor: lightBlueColor01,
    color: whiteColor,
    border: "",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, index } = props;


  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={{ width: "30px", height: "30px", gap: "30px" }}
    >
      
      {completed ? (
        <CheckRoundedIcon sx={{ fontSize: "18px", color: whiteColor }} />
      ) : (
        <FiberManualRecordIcon
          sx={{ fontSize: "18px", color: stepperBGColor }}
        />
      )}
    </ColorlibStepIconRoot>
  );
}

function VerticalDotLineStepper({ steps, activeStep, width, height }) {
  return (
    <Stack sx={{ maxWidth: width || 400, maxHeight: height || 500 }}>
      <Stepper
        // alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        orientation="vertical"
      >
        {steps.map((item, index) => (
          <Step key={item.label} onClick={() => {}}>
            <StepLabel
              StepIconComponent={(props) =>
                ColorlibStepIcon({ ...props, route: item.route, index })
              }
            >
              <Typography
                variant="h5"
                component="p"
                fontSize={12.5}
                fontWeight={500}
                lineHeight="18px !important"
                // color={activeStep >= index ? blackShade05 : stepperBGColor}
                color={blackShade05}
                // mt={1}
                mr={10}
              >
                {item.label}
              </Typography>
              <Typography
                variant="h4"
                fontSize={11}
                fontWeight={400}
                color={greyColor7}
                mt={1}
                mr={10}
              >
                {item.sublabel}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}

export default VerticalDotLineStepper;
