import { getApiInstance } from "../../AppService";
import {
  PROCESS_RETURN_ORDER_STATUS,
  RETURN_ORDERS_BY_STATUS,
  RETURN_RESONS_OMS_CODES,
} from "../../constant";

export const getReturnOrdersByStatusApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });

    const response = await instance.get(RETURN_ORDERS_BY_STATUS, {
      params: {
        pageNo: data?.pageNo,
        returnStatus: data?.status,
      },
    });
    console.log("getReturnOrdersByStatusApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getReturnActionListApi = async () => {
  try {
    const instance = getApiInstance();

    const response = await instance.get(RETURN_RESONS_OMS_CODES, {
      params: {
        codeUseBy: "SNP",
        purpose: "returnRejection",
      },
    });
    console.log("getReturnActionListApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const processReturnStatusApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
        cartId: data?.cartId,
      },
    });

    const response = await instance.post(
      PROCESS_RETURN_ORDER_STATUS,
      data?.postData
    );

    console.log("processReturnStatusApi", response.data);

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
