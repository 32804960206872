import { Box, Typography } from "@mui/material";
import React from "react";
import {
  blackShade43,
  greyColor40,
  greyShade83,
  lightBlueColor04,
  lightBlueColor05,
  logoImgborder1,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import CallIcon from "@mui/icons-material/Call";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PersonIcon from "@mui/icons-material/Person";

const OwnerContactInfoCard = ({ activeBusinessInfo }) => {
  return (
    <Box sx={styles.card}>
      <Box display="flex" alignItems="center" gap={1.5}>
        <Box sx={styles.iconCircle}>
          <AdminPanelSettingsOutlinedIcon sx={styles.icon} />
        </Box>

        <Box>
          <Typography
            fontSize={{ xs: 10, md: 11.2 }}
            fontWeight={500}
            color={greyColor40}
          >
            OWNER
          </Typography>

          <Box>
            <ContactInfo
              icon={<PersonIcon sx={{ fontSize: "15px" }} />}
              text={activeBusinessInfo?.owner?.name || ""}
            />

            <ContactInfo
              icon={<CallIcon sx={{ fontSize: "15px" }} />}
              text={
                activeBusinessInfo?.owner?.phone
                  ? activeBusinessInfo?.owner?.phone?.replace("+91", "+91 ")
                  : ""
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OwnerContactInfoCard;

const ContactInfo = ({ icon, text }) => {
  return (
    <Box display="flex" alignItems="center" gap={0.7}>
      {icon}
      <Typography
        fontSize={{ xs: 11.5, md: 12 }}
        fontWeight={500}
        color={blackShade43}
        lineHeight={{ xs: "19px", md: "22px" }}
      >
        {text}
      </Typography>
    </Box>
  );
};

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "13px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "5px 10px", md: "10px 15px" },
    width: "100%",
  },

  iconCircle: {
    width: { md: "40px", xs: "34px" },
    height: { md: "40px", xs: "34px" },
    borderRadius: "50%",
    backgroundColor: { xs: lightBlueColor05, md: greyShade83 },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  icon: {
    fontSize: { md: "20px", xs: "17px" },
    color: { xs: mobilePrimary, md: lightBlueColor04 },
  },
};
