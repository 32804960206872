import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  blackShade01,
  border27,
  greyShade01,
  redShade02,
  whiteColor,
} from "../../configs/styles/muiThemes";

export const TextBox = ({
  label,
  placeholder,
  required,
  disabled,
  value,
  onChange,
  startIcon,
  endIcon,
  maxLength,
  inputType,
  error,
  helperText,
}) => {
  return (
    <Box>
      {label ? (
        <>
          <Typography
            variant="h3"
            fontSize={{ xs: 12, md: 13 }}
            color={greyShade01}
            fontWeight={400}
            sx={{
              display: "inline",
              opacity: disabled ? 0.7 : 1,
            }}
          >
            {label}{" "}
          </Typography>
          {required ? (
            <Typography
              variant="body1"
              sx={{ display: "inline", color: redShade02 }}
            >
              *
            </Typography>
          ) : null}
        </>
      ) : null}

      <TextField
        variant="standard"
        required={true}
        fullWidth
        disabled={disabled}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={inputType}
        sx={{
          fontSize: 13,
          fontWeight: 500,
          color: blackShade01,
          opacity: disabled ? 0.5 : 1,
          borderBottom: border27,
          borderRadius: "0px",
          backgroundColor: whiteColor,
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: blackColor,
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                color: `${blackShade01} !important`,
                fontSize: 13.5,
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: 13, fontWeight: 500, color: blackShade01 }}
              >
                {startIcon}
              </Typography>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" sx={{ cursor: "pointer" }}>
              {endIcon}
            </InputAdornment>
          ),
          style: {
            padding: "4px 8px 4px 0px",
            fontSize: 13.5,
            fontWeight: 500,
          },
          disableUnderline: true,
        }}
        inputProps={{ maxLength: maxLength }}
      />

      {helperText ? (
        <Typography
          variant="body1"
          component="p"
          fontSize={12}
          color={greyShade01}
          lineHeight="20px"
          mt={0.5}
        >
          {helperText}
        </Typography>
      ) : null}
      {error ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={12}
          sx={{ mt: "4px !important", textAlign: "start !important" }}
          style={{ color: "red" }}
        >
          {error}
        </Typography>
      ) : null}
    </Box>
  );
};
