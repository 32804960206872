import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { storeLeads } from '../../api/Leads/leadsApi'


const initialState = {
  leadResponse:null,
  loading: false,
}

//Fetching all coupons

export const saveUserDetails = createAsyncThunk(
  'saveUserDetails',
  async (param, thunkAPI) => {
    const res = await storeLeads(param)
    return res
  }
)

export const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {},
  extraReducers: {
    [saveUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [saveUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.leadResponse = payload;
    },
    [saveUserDetails.rejected]: (state) => {
      state.loading = false;
    },
  },
})

export const leadsReducer = leadsSlice.reducer
