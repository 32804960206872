import { Box, Switch, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  blackColor,
  blackShade07,
  blackShade18,
  blackShade21,
  blueColor02,
  blueColor04,
  borderTextbox,
  greyColor24,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import DashboardStepper from "../../Dashboard/DashboardStepper";
import { ECOMMERCE_STEP_LIST } from "../../../utils/appData";
import RadiosBtnWithLabel from "../../../components/Input/RadiosBtnWithLabel";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import TimeRange from "../../../components/Input/TimeRange";
import { useState } from "react";
import moment from "moment";
import {
  ECOMINPUTSUPPORTLABEL,
  emailAddress,
  whatsAppNumber,
} from "../../../configs/Constants";
import { useDispatch, useSelector } from "react-redux";
import { getActiveBusinessInfo } from "../../../reducers/businessPreview/businessPreviewSlice";
import { stickyHeader } from "../../../utils/styles";

function AddBusinessTimings({
  data,
  setData,
  onValueChange,
  currentPage,
  gotoNext,
  goBack,
  handleCancel,
  isEdit,
}) {
  const dispatch = useDispatch();

  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
    }
  }, []);

  const BUSINESS_TIME_LIST = [
    { label: "Open 24 Hrs", value: "open-24-Hrs" },
    { label: "All Days", value: "all-days" },
    { label: "Select specific days", value: "select-specific-days" },
  ];

  const handleTimeChange = (key, value, index) => {
    const newBusinessHours = [...data?.businessHours];
    if (key === "isClosed" && value === false) {
      newBusinessHours[index] = {
        ...newBusinessHours[index],
        [key]: value,
        from: "",
        to: "",
      };
    } else {
      newBusinessHours[index] = {
        ...newBusinessHours[index],
        [key]: value,
      };
    }

    setData((prevData) => ({
      ...prevData,
      businessHours: newBusinessHours,
    }));
  };

  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Support+Request.+`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };

  return (
    <Box>
      {/* <Box sx={stickyHeader} pb={1.5}>
        <Typography
          component="h6"
          fontSize={{ xs: 15, md: 17 }}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          mb={"4px !important"}
        >
          {isEdit && (
            <span style={{ color: blueColor04, marginRight: "5px" }}>Edit</span>
          )}{" "}
          E-Commerce Details
        </Typography>

        <Typography
          variant="h6"
          fontSize={13}
          fontWeight={500}
          color={blackShade21}
          display="inline"
          mb="15px"
        >
          {ECOMINPUTSUPPORTLABEL}{" "}
          <Typography
            // variant="body1"
            fontSize={13}
            fontWeight={500}
            color={blueColor04}
            display="inline"
            // onclick={handleChatClick}
            component="a"
            href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Support+Request.+&fs=1&to=${emailAddress}&tf=cm`}
            target="_blank"
          >
            Email
          </Typography>
          {"  "}
          or{" "}
          <Typography
            // variant="body1"
            fontSize={13}
            fontWeight={500}
            color={blueColor04}
            sx={{ cursor: "pointer" }}
            display="inline"
            onClick={handleChatClick}
          >
            Whatsapp
          </Typography>
        </Typography>
      </Box> */}
      <Box sx={styles.card}>
        {/* <DashboardStepper
          stepList={ECOMMERCE_STEP_LIST}
          currentPage={currentPage}
          isAddProduct
        /> */}

        <Box m={{ md: "15px 0 15px 0", xs: "15px 0 10px 0" }}>
          <Typography
            component="h6"
            fontSize={{ xs: 14, md: 14 }}
            color={blackColor}
            fontWeight={600}
            lineHeight="20px"
          >
            BusinessTiming
          </Typography>

          <Typography
            fontSize={{ xs: 13, md: 13 }}
            color={blackShade18}
            fontWeight={500}
            mt={1}
          >
            Business open hours are significant if you are planning to use
            Hyperlocal Express delivery (Bengaluru only) OR In Store pickup
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-start", md: "center" },
              // justifyContent: "space-evenly",
              justifyContent: "flex-start",
              mt: { xs: "6px", md: "15px" },
              pb: "8px",
              borderBottom: borderTextbox,
              gap:5
            }}
          >
            {BUSINESS_TIME_LIST.map((item, index) => {
              return (
                <RadiosBtnWithLabel
                  key={"business_time" + index}
                  label={item.label}
                  labelStyles={styles.labelText}
                  checked={data?.businessTime === item.value}
                  onValuesChanged={() => {
                    // onValueChange("businessTime", item.value);
                    setData((prevData) => ({
                      ...prevData,
                      businessTime: item.value,
                      allDays: { from: "", to: "" },
                      businessHours: [
                        {
                          dayOfWeek: 1,
                          day: "Monday",
                          isClosed: false,
                          from: "",
                          to: "",
                        },
                        {
                          dayOfWeek: 2,
                          day: "Tuesday",
                          isClosed: false,
                          from: "",
                          to: "",
                        },
                        {
                          dayOfWeek: 3,
                          day: "Wednesday",
                          isClosed: false,
                          from: "",
                          to: "",
                        },
                        {
                          dayOfWeek: 4,
                          day: "Thursday",
                          isClosed: false,
                          from: "",
                          to: "",
                        },
                        {
                          dayOfWeek: 5,
                          day: "Friday",
                          isClosed: false,
                          from: "",
                          to: "",
                        },
                        {
                          dayOfWeek: 6,
                          day: "Saturday",
                          isClosed: false,
                          from: "",
                          to: "",
                        },
                        {
                          dayOfWeek: 7,
                          day: "Sunday",
                          isClosed: false,
                          from: "",
                          to: "",
                        },
                      ],
                    }));
                  }}
                />
              );
            })}
          </Box>
        </Box>

        {data?.businessTime === "all-days" && (
          <Box
            sx={{
              mt: { xs: "6px", md: "25px" },
              mb: 2,
              pb: { xs: "8px", md: "18px" },
              // borderBottom: borderTextbox,
            }}
          >
            <BusinessTiming
              day="All Days"
              isOpen={true}
              hideClosed
              fromTime={data?.allDays?.from}
              toTime={data?.allDays?.to}
              handleFromChange={(time) => {
                setData({ ...data, allDays: { ...data?.allDays, from: time } });
              }}
              handleToChange={(time) => {
                setData({ ...data, allDays: { ...data?.allDays, to: time } });
              }}
            />

            <Typography variant="body2" sx={styles.errorText} m="0px 0 12px">
              {data?.errors?.allDays?.from || data?.errors?.allDays?.to}
            </Typography>
          </Box>
        )}

        {data?.businessTime === "select-specific-days" && (
          <>
            <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <Typography
                component="h5"
                fontSize={{ xs: 14, md: 14 }}
                color={blackColor}
                fontWeight={600}
                lineHeight="30px"
              >
                Select Specific Days
              </Typography>
            </Box>

            {data?.businessHours?.map((item, index) => {
              const format = "HH:mm";
              const fromMoment = moment(item?.from, format);
              const toMoment = moment(item?.to, format);

              const isAfter = fromMoment.isAfter(toMoment);
              const isSame = fromMoment.isSame(toMoment);

              return (
                <>
                  <BusinessTiming
                    key={`day_${index}`}
                    day={item?.day}
                    isOpen={item?.isClosed}
                    fromTime={item?.from}
                    toTime={item?.to}
                    handleOpenChange={(isChecked) =>
                      handleTimeChange("isClosed", isChecked, index)
                    }
                    handleFromChange={(time) =>
                      handleTimeChange("from", time, index)
                    }
                    handleToChange={(time) =>
                      handleTimeChange("to", time, index)
                    }
                  />

                  {(isAfter || isSame) && item?.isClosed && (
                    <Typography
                      variant="body2"
                      sx={styles.errorText}
                      m="0px 0 12px"
                    >
                      {isAfter &&
                        "From time should not be greater than To time"}
                      {isSame && "From time and To time should not be same"}
                    </Typography>
                  )}

                  {/* {data?.errors?.businessHours?.errorList?.[index]?.error && (
                  <Typography
                    variant="body2"
                    sx={styles.errorText}
                    m="0px 0 12px"
                  >
                    {data?.errors?.businessHours?.errorList?.[index]?.error}
                  </Typography>
                )} */}
                </>
              );
            })}
          </>
        )}

        <Typography variant="body2" sx={styles.errorText} m="0px 0 12px">
          {data?.errors?.businessHours?.error}
        </Typography>

        <Box mt="20px">
          <ActionButtons
            onBackClick={goBack}
            onSaveClick={() => {}}
            onNextClick={gotoNext}
            onCancelClick={handleCancel}
            // showBackBtn
            hideSaveBtn
            // backBtnText="Previous"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default AddBusinessTimings;

const BusinessTiming = ({
  day,
  isOpen,
  handleOpenChange,
  fromTime,
  handleFromChange,
  toTime,
  handleToChange,
  hideClosed,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: { xs: "flex-start", md: "center" },
        gap: { xs: "6px", md: "80px" },
        mb: { xs: "12px", md: "12px" },
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", md: "auto" },
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "space-between" },
        }}
      >
        <Typography
          width="90px"
          component="h5"
          fontSize={{ xs: 14, md: 14 }}
          color={blackColor}
          fontWeight={500}
          lineHeight="30px"
        >
          {day}
        </Typography>

        {!hideClosed && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Switch
              checked={isOpen}
              color="warning"
              onChange={({ target }) => {
                handleOpenChange(target?.checked);
              }}
            />

            <Typography
              width="50px"
              fontSize={{ xs: 13, md: 13.5 }}
              color={blackShade07}
              fontWeight={500}
              lineHeight="20px"
            >
              {isOpen ? "Open" : "Closed"}
            </Typography>
          </Box>
        )}
      </Box>

      <TimeRange
        from={fromTime}
        handleFromChange={handleFromChange}
        to={toTime}
        handleToChange={handleToChange}
        disabled={!isOpen}
      />
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    p: "5px 20px 20px 20px",
    m: { xs: "0 0 25px", md: "0px 0 25px" },
  },

  errorText: {
    fontSize: 12,
    fontWeight: 500,
    color: "red",
  },
};
