import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDeliveredOrderInfo,
  getDeliveredOrderData,
} from "../../../reducers/orders/ordersSlice";
import ListEmptyCard from "../../../components/ListEmptyCard";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import moment from "moment";
import { getOrderDeliveryTypeText, getOrderType } from "../../../utils/helper";
import OrderDeliveredCard from "../Cards/OrderDeliveredCard";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchOrdersById from "../SearchOrdersById";
import HorizontalStepper from "../../../components/HorizontalStepper";
import {
  blackShade23,
  greyColor42,
  orderCardBorder,
  orderCardHeadingBorder2,
} from "../../../configs/styles/muiThemes";
import { stickyHeader } from "../../../utils/styles";
import ActionInfoHeader from "../Cards/ActionInfoHeader";
function DeliveredOrdersContent({
  handleTrackOrder,
  handleViewOrder,
  steps,
  tabValue,
  getOndcBapNameBySubscriberId,
  downloadShipingLabel,
}) {
  const PAGE_SIZE = 30;

  const dispatch = useDispatch();
  const [hasMoreData, setHasMoreData] = useState(true);

  const [isSearchActive, setIsSearchActive] = useState(false);

  const { deliveredOrders, deliveredPageNo, deliveredOrdersLoader } =
    useSelector((state) => state.businessOrders);

  const getOrdersData = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      getDeliveredOrderData({
        businessId,
        pageSize: PAGE_SIZE,
        pageNo: deliveredPageNo,
      })
    );
  };

  const fetchNextData = () => {
    // console.log("Next Hit");
    getOrdersData();
  };

  // handle Searching
  const onSearchStart = () => {
    setIsSearchActive(true);
    dispatch(clearDeliveredOrderInfo());
  };

  const onSearchClear = () => {
    setIsSearchActive(false);
    getOrdersData();
  };

  useEffect(() => {
    getOrdersData();
    return () => {
      dispatch(clearDeliveredOrderInfo());
    };
  }, []);

  useEffect(() => {
    if ((deliveredPageNo - 1) * PAGE_SIZE > deliveredOrders?.length) {
      setHasMoreData(false);
    }

    // console.log({
    //   hasMoreData: deliveredPageNo - 1 * PAGE_SIZE > deliveredOrders?.length,
    //   deliveredPageNo,
    //   lhs: (deliveredPageNo - 1) * PAGE_SIZE,
    //   length: deliveredOrders?.length,
    // });

    return () => {};
  }, [deliveredPageNo]);

  return (
    <Box>
      <SearchOrdersById
        handleTrackOrder={handleTrackOrder}
        handleViewOrder={handleViewOrder}
        onSearchStart={onSearchStart}
        onSearchClear={onSearchClear}
        steps={steps}
        activeStep={tabValue}
        tabValue={tabValue}
        disableSearch={deliveredOrders?.length == 0}
        getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
        downloadShipingLabel={downloadShipingLabel}
      />
      {/* <Box display={{ xs: "block", md: "none" }} mb={3} ml={-1.4} mt={-1}>
        <HorizontalStepper steps={steps} activeStep={tabValue} />
      </Box> */}

      {!deliveredOrdersLoader &&
      deliveredPageNo === 1 &&
      deliveredOrders?.length === 0 &&
      !isSearchActive ? (
        <ListEmptyCard
          icon={
            <ProductionQuantityLimitsOutlinedIcon sx={{ fontSize: "24px" }} />
          }
          text="No Delivered Orders Found"
          hideBtn
        />
      ) : (
        deliveredOrdersLoader && deliveredPageNo === 1 && <ThreeDotLoader />
      )}
      {deliveredOrders?.length > 0 && (
        <Box
          sx={{
            ...stickyHeader,
            pb: { md: 0, xs: 0 },
            pt: 0,
            top: 248,
            display: { md: "block", xs: "none" },
          }}
        >
          <Box sx={styles.card}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3.3}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  ORDER DETAIL
                </Typography>
              </Grid>
              <Grid item xs={6} md={2.8}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  CUSTOMER INFO & PRICE & QTY
                </Typography>
              </Grid>

              <Grid item xs={6} md={2.3}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  DELIVERY TYPE & STATUS
                </Typography>
              </Grid>

              <Grid item xs={12} md={3.6}>
                <Typography
                  variant="h5"
                  fontSize={12.5}
                  fontWeight={500}
                  color={blackShade23}
                >
                  ACTION
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}

      {deliveredOrders?.length > 0 && (
        <>
          <InfiniteScroll
            dataLength={deliveredOrders?.length || 0}
            next={fetchNextData}
            hasMore={hasMoreData}
            loader={
              <Box>
                <ThreeDotLoader />
              </Box>
            }
          >
            {deliveredOrders?.map((item, index) => (
              <OrderDeliveredCard
                index={index}
                key={`order_${item?.orderId}`}
                id={item?.orderId}
                ondcOrderId={item?.ondcOrderId || ""}
                date={moment(item?.orderCreatedOn)?.format("DD MMM YYYY")}
                username={item?.customerAddressName}
                addressName={item?.customerAddressName || ""}
                city={item?.customerCity}
                addressCity={item?.customerAddressCity || ""}
                orderCost={item?.orderTotalCost}
                cartCount={item?.totalQuantity}
                orderStatus="Completed"
                deliveryType={getOrderDeliveryTypeText(
                  item?.deliveryType?.type
                )}
                orderType={getOrderType(item)}
                carrierId={item?.shipmentInfo?.carrierTrackId || ""}
                statusUpdatedDate={
                  item?.orderDeliveredOn
                    ? moment(item?.orderDeliveredOn).fromNow()
                    : ""
                }
                handleTrackOrder={() => handleTrackOrder(item)}
                handleViewOrder={() => handleViewOrder(item)}
                getOndcBapNameBySubscriberId={() =>
                  getOndcBapNameBySubscriberId(item?.ondcBapId)
                }
                downloadShipingLabel={downloadShipingLabel}
                orderData={item}
              />
            ))}
          </InfiniteScroll>
        </>
      )}
    </Box>
  );
}

export default DeliveredOrdersContent;
const styles = {
  card: {
    display: { md: "flex", xs: "none" },
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "16px", md: "20px" },
    backgroundColor: greyColor42,
    borderTop: orderCardBorder,
    borderRadius: "7px 7px 0px 0px",
    boxShadow: "0 2px 4px rgb(179 188 198 / 5%)",
    padding: "7px 15px",
    position: "sticky",
    top: 248,
    zIndex: 10000,
    // marginBottom: "15px",
  },
};
