import { Box, FormControl, MenuItem, Select } from '@mui/material';
import React from 'react'
import ErrorToolTipIcon from "./ErrorToolTipIcon";
import { borderTextbox, whiteColor } from '../../configs/styles/muiThemes';

const TableCellDropdownBox = ({
    value,
    onValueChange,
    menuList,
    valueSelector,
    returnFullItem,
    disabled,
    rounded,
    errorMsg,
    width
  }) => {
  return (
    <Box position="relative">
    {errorMsg && (
      <Box sx={{ position: "absolute", right: -26, top: -22 }}>
        <ErrorToolTipIcon errorMsg={errorMsg} />
      </Box>
    )}

    <Box
      sx={{
        borderBottom: borderTextbox,
        height: { xs: "38px", md: "auto" },
        backgroundColor: whiteColor,
      }}
    >
      <FormControl sx={{...styles.formControlStyle, width: width || "200px",}}>
        <Select
          value={value}
          displayEmpty
          fullWidth
          sx={{
            height: "28px",
            pb: "3px",
          }}
          disabled={disabled}
        >
          {menuList.map((item, index) => {
            return (
              <MenuItem
                value={valueSelector ? item[valueSelector] : item.label}
                key={"Menu_Item_" + index}
                onClick={() => {
                  if (!returnFullItem) {
                    onValueChange(
                      valueSelector ? item[valueSelector] : item.label
                    );
                  } else {
                    onValueChange(item);
                  }
                }}
                sx={{ fontWeight: 500 }}
              >
                {valueSelector ? item[valueSelector] : item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  </Box>
  )
}

export default TableCellDropdownBox


const styles = {
    formControlStyle: {
      height: "26px",
      "& .css-hdw1oc": {
        display: "none",
      },
      "& .MuiOutlinedInput-notchedOutline, .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
        {
          border: "none",
        },
    },
  };