import { Box, Typography } from "@mui/material";
import React from "react";
import {
  blueColor02,
  blueColor04,
  greyColor24,
  mobilePrimary,
  whiteColor,
} from "../../configs/styles/muiThemes";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";

function EmptyCoupon() {
  return (
    <Box
      style={styles.card}
      gap={1}
      justifyContent="center"
      alignItems="center"
    >
      <Box sx={styles.iconContainer}>
        <SellOutlinedIcon sx={{ fontSize: "24px" }} />
      </Box>
      <Typography
        sx={{
          color: greyColor24,
          fontSize: "16px",
          fontWeight: 500,
          textAlign: "center",
          mb: "10px",
          display: "inline",
        }}
      >
        Increase your sales by 5X, with{" "}
        <Typography
          variant="h6"
          color={{xs: mobilePrimary, md:blueColor04}}
          fontSize="16px"
          fontWeight="500"
          display="inline"
        >
          Limited Time Offers
        </Typography>
      </Typography>
      <Typography
        sx={{
          color: greyColor24,
          fontSize: "14px",
          fontWeight: 400,
          textAlign: "center",
        }}
      >
        Get started by creating new coupons!
      </Typography>
    </Box>
  );
}

export default EmptyCoupon;

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: whiteColor,
    borderRadius: "10px",
    padding: "30px 15px",
    textAlign: "center",
    border: "1px solid #e5e9f0",
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
  },
  iconContainer: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    background: "rgb(5 60 81 / 7%)",
    padding: "12px",
    mb: "10px",
  },
};
