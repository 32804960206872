import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  blackShade01,
  border27,
  bgColor15,
} from "../../../../configs/styles/muiThemes";

function ProductDetailsCard({
  imageUrl,
  productName,
  orderQty,
  issueQty,
  price,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Box>
      <Grid container spacing={{ xs: 1.5, md: "unset" }}>
        <Grid item xs={12} md={5}>
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={{ xs: "8px", md: "15px" }}
          >
            <Box
              component="img"
              src={imageUrl}
              alt={productName}
              sx={styles.logoImg}
            />
            <Typography
              variant="h4"
              fontSize={15}
              fontWeight={600}
              color={blackShade01}
              lineHeight="25px"
            >
              {productName}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4} md={3}>
          <Typography sx={styles.greyText} mb={0.6}>
            {matches ? "Order Quantity" : "Order QTY"}
          </Typography>
          <Typography sx={styles.blackText}>{orderQty}</Typography>
        </Grid>
        <Grid item xs={4} md={2}>
          <Typography sx={styles.greyText} mb={0.6}>
            {matches ? "Issue Quantity" : "Issue QTY"}
          </Typography>
          <Typography sx={styles.blackText}>{issueQty}</Typography>
        </Grid>
        <Grid item xs={4} md={2}>
          <Typography sx={styles.greyText} mb={0.6}>
            Price
          </Typography>
          <Typography sx={styles.blackText}>₹ {price}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProductDetailsCard;

const styles = {
  logoImg: {
    width: { xs: "52px", md: "48px" },
    height: { xs: "52px", md: "48px" },
    border: border27,
    objectFit: "contain",
    borderRadius: "8px",
  },
  greyText: {
    color: bgColor15,
    fontSize: { xs: 11, md: 12.5 },
    fontWeight: 400,
    lineHeight: 1.5,
  },
  blackText: {
    color: blackShade01,
    fontSize: { xs: 12.5, md: 13 },
    fontWeight: 500,
    lineHeight: 1.5,
  },
};
