import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMyBusinessAPI } from "../../service/api_calls/BusinessPreview/BusinessPreviewAPI";
import { getAllBusinessProfileIDApi } from "../../service/api_calls/preferredDiscounts/PreferredDiscountsApi";
import { getMatchingObjects } from "../../utils/helper";
import { getBankDetailsApi, verifyBusinessRoleApi } from "../../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";

const initialState = {
  managersInfo: [],
  businessRole: null,
  businessRoleLoader: false,
  managerRole: null,
  managerRoleLoader: false,
  managersInfoLoader: false,

  bankInfo:null,
  bankInfoLoader:false,
};

// get Managers
export const getManagerDetails = createAsyncThunk(
  "getManagerDetails",
  async (param, thunkAPI) => {
    const res = await getMyBusinessAPI(param);
    console.log("MyBusinessData", res);
    const profileId = res?.viewBusiness?.delegates?.map(
      (item) => item?.profileId
    );
    // console.log(profileId);

    profileId?.push(res?.viewBusiness?.owner?.profileId);

    const uniqueprofileIds = [];

    for (const item of profileId) {
      if (!uniqueprofileIds.includes(item)) {
        uniqueprofileIds.push(item);
      }
    }
    console.log("uniqueprofileIds:", uniqueprofileIds);

    let profileParams = "?";
    uniqueprofileIds.forEach((element, index) => {
      profileParams =
        profileParams +
        (index === uniqueprofileIds.length - 1
          ? `params=${element}`
          : `params=${element}&`);
    });
    // console.log("profileParams:", profileParams);

    const profileInfo = await getAllBusinessProfileIDApi({
      ...param,
      profileParams,
    });

    const ownerInfo = profileInfo?.accountList?.find(
      (obj) => obj.profileId === res?.viewBusiness?.owner?.profileId
    );
    const managers = getMatchingObjects(
      res?.viewBusiness?.delegates,
      profileInfo?.accountList
    );
    console.log("ProfileInfo:", profileInfo);
    return { managers, ownerInfo };
  }
);

export const verifyBusinessRole = createAsyncThunk(
  "verifyBusinessRole",
  async (param, thunkAPI) => {
    const res = await verifyBusinessRoleApi(param);
    return res;
  }
);

export const verifyManagerRole = createAsyncThunk(
  "verifyManagerRole",
  async (param, thunkAPI) => {
    const res = await verifyBusinessRoleApi(param);
    return res;
  }
);

export const BankDetailsData = createAsyncThunk(
  "BankDetailsData",
  async (param, thunkAPI) => {
    const res = await getBankDetailsApi(param);
    return res;
  }
);

export const eCommerceControl = createSlice({
  name: "eCommerceControl",
  initialState,
  reducers: {
    clearManagersInfo: (state, action) => {
      state.managersInfo = [];
    },
  },
  extraReducers: {
    //fetch active business info
    [getManagerDetails.pending]: (state) => {
      state.managersInfoLoader = true;
    },
    [getManagerDetails.fulfilled]: (state, { payload }) => {
      state.managersInfo = payload || null;
      state.managersInfoLoader = false;
    },
    [getManagerDetails.rejected]: (state, { payload }) => {
      state.managersInfoLoader = false;
    },

    // Business Role
    [verifyBusinessRole.pending]: (state) => {
      state.businessRoleLoader = true;
    },
    [verifyBusinessRole.fulfilled]: (state, { payload }) => {
      state.businessRole = payload || null;
      state.businessRoleLoader = false;
    },
    [verifyBusinessRole.rejected]: (state, { payload }) => {
      state.businessRoleLoader = false;
    },

    // Manager Role
    [verifyManagerRole.pending]: (state) => {
      state.managerRoleLoader = true;
    },
    [verifyManagerRole.fulfilled]: (state, { payload }) => {
      state.managerRole = payload || null;
      state.managerRoleLoader = false;
    },
    [verifyManagerRole.rejected]: (state, { payload }) => {
      state.managerRoleLoader = false;
    },

    // Bank Details
    [BankDetailsData.pending]: (state) => {
      state.bankInfoLoader = true;
    },
    [BankDetailsData.fulfilled]: (state, { payload }) => {
      state.bankInfo = payload?.details || null;
      state.bankInfoLoader = false;
    },
    [BankDetailsData.rejected]: (state, { payload }) => {
      state.bankInfoLoader = false;
    },
  },
});

export const { clearManagersInfo } = eCommerceControl.actions;

export const eCommerceControlReducer = eCommerceControl.reducer;
