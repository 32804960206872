import { Box, Typography, Grid } from "@mui/material";
import React, { useEffect } from "react";
import {
  blackShade159,
  greyColor6,
} from "../../../configs/styles/muiThemes";
import { useState } from "react";
import BusinessTypeCheckBox from "../Components/BusinessTypeCheckBox";
import FormInputBoxWithBorder from "../../../components/Input/FormInputBoxWithBorder";
import NavigationButtons from "../Components/NavigationButtons";

function StoreInfoForm({
  handleNext,
  handleBack,
  handleSave,
  businessLookupTypes,
  storeInfoDetails,
  onValueChange,
}) {
  const [businessType, setBusinessType] = useState([]);

  useEffect(() => {
    let arr = [];

    storeInfoDetails?.type?.forEach((element) => {
      arr?.push(element?.name);
    });

    setBusinessType(arr);
  }, []);

  const handleChange = (value) => {
    const newList = [...businessType];

    // console.log("Value:", value);
    // console.log("businessType:", businessType);

    const index = newList.indexOf(value);
    if (index === -1) {
      newList.push(value);
    } else {
      newList.splice(index, 1);
    }

    setBusinessType(newList);

    const filteredArray = businessLookupTypes?.filter((type) =>
      newList.includes(type.name)
    );

    onValueChange("type", filteredArray);
  };

  return (
    <Box sx={styles.card}>
      {/* <Typography
        variant="h5"
        fontSize={{ xs: 17, md: 18 }}
        color={blackColor}
        fontWeight={500}
        lineHeight="30px"
        mb="10px !important"
      >
        Business Detail
      </Typography> */}

      <FormInputBoxWithBorder
        label="Brand Name/Business Name"
        value={storeInfoDetails?.name}
        onChange={({ target }) => {
          const value = target.value?.replace(/[^a-zA-Z0-9 -]/g, "");
          onValueChange("name", value);
        }}
        required
        maxLength={50}
        error={storeInfoDetails?.errors?.name}
      />
      {/* 
      <Typography fontSize={11.5} fontWeight={500} color={greyShade65} mb={2}>
        Ex: Bhuira Jams <CheckCircleRoundedIcon sx={{ color: greenColor }} />,
        {"   "}
        Bhuira Jame Private Ltd{" "}
        <CancelRoundedIcon sx={{ color: requiredStarColor }} />
      </Typography> */}

      <Box mb='4px'>
        <Typography
          variant="body2"
          fontSize={13}
          color={greyColor6}
          fontWeight={400}
          sx={{
            display: "inline",
          }}
        >
          Business Type {" "}
        </Typography>
        <Typography variant="body1" sx={{ display: "inline", color: "#f00" }}>
          *
        </Typography>
      </Box>

      <Box sx={styles.businessTypeContainer}>
        <Grid container spacing={1}>
          {businessLookupTypes?.map((item, index) => {
            return (
              <Grid item xs={12} md={6} key={`business_type_${index}`}>
                <BusinessTypeCheckBox
                  isChecked={businessType?.includes(item?.name)}
                  onClick={() => handleChange(item?.name)}
                  businessType={item?.name}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Typography
        variant="subtitle1"
        component="p"
        fontSize={12}
        color="red"
        mt={1}
      >
        {storeInfoDetails?.errors?.type}
      </Typography>

      {/* <MultiSelectWithLabel
        label="Business Type"
        value={businessType}
        onValueChange={handleChange}
        menuList={businessLookupTypes}
        required
        error={storeInfoDetails?.errors?.type}
      /> */}

      <Box mt={0.5}>
        {/* <ActionButtons
          onBackClick={handleBack}
          onSaveClick={handleSave}
          onNextClick={handleNext}
          hideSaveBtn
        /> */}
        <NavigationButtons hideBackBtn onNextClick={handleNext} />
      </Box>
    </Box>
  );
}

export default StoreInfoForm;

const styles = {
  card: {
    // width: { xs: "100%", md: "100%" },
    // mt: { md: "35px", xs: 1.5 },
  },

  businessTypeContainer: {
    maxHeight: "250px",
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
      // height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blackShade159,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },
};
