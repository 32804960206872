import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import PlaceIcon from "@mui/icons-material/Place";
import {
  blackShade01,
  border27,
  bgColor15,
  greyShade03,
  deleteBtnColor,
  blueColor04,
  copyBtnColor,
  blackShade19,
} from "../../../../configs/styles/muiThemes";
// import {
//   addDurationToDate,
//   getColorChip,
//   getIssueTypeText,
//   getStatusColor,
// } from "../../../../utils/helper";
import moment from "moment";
import {
  addDurationToDate,
  formatDuration,
  getColorChip,
  getIssueTypeText,
  getONDCOrderType,
  getStatusColor,
} from "../../../../utils/helper";

function IssueDetailsCard({
  imageUrl,
  businessName,
  location,
  issueId,
  issuedDate,
  issueType,
  status,
  customerName,
  phoneNumber,
  orderId,
  orderStatus,
  responseTime,
  responseTimeStatus,
  resolutionTime,
  resolutionTimeStatus,
  msgResolution,
  refundAmount,
  responseStatusSla,
  resolutionStatusSla,
  grievanceData,
  getOndcBapNameBySubscriberId,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
 const orderType = getONDCOrderType(grievanceData);
  return (
    <Box mb={2}>
      <Grid
        container
        display="flex"
        // alignItems="center"
        justifyContent="start"
        sx={{ borderBottom: border27, pb: 1 }}
        spacing={{ xs: 1, md: "unset" }}
      >
        <Grid item xs={6} md={2.5}>
          <Typography sx={styles.greyText} mb={0.6}>
            Customer Details
          </Typography>
          <Typography
            sx={{
              ...styles.blackText,
              textOverflow: "ellipsis",
              display: "-webkit-box",
              overflow: "hidden",
              "-webkit-line-clamp": "1",
              "-webkit-box-orient": "vertical",
            }}
          >
            {customerName}
          </Typography>
          <Typography sx={styles.blackText}>+91 {phoneNumber}</Typography>
        </Grid>
        <Grid item xs={6} md={3} textAlign={{ xs: "end", md: "unset" }}>
          <Typography sx={styles.greyText} mb={0.6}>
            Order Detail
          </Typography>
          <Typography
            sx={{
              ...styles.blackText,
              // textDecoration: "underline",
              // cursor: "pointer",
            }}
          >
            # {orderId}
          </Typography>
          <Typography sx={styles.blackText}>{orderStatus}</Typography>
          <Box flex={1} display={"flex"} gap={2}>
            <Typography sx={{ ...styles.title, display: "inline-block" }}>
              {" "}
              <Typography
                sx={{
                  ...styles.title,
                  fontSize: 12,
                  color: orderType?.color,
                  display: "inline-block",
                }}
              >
                {console.log("grievanceData", grievanceData)}
                {orderType?.label}
                {grievanceData?.ondcInfo?.bapId}
                {orderType?.orderType === "ondcOrder" ? (
                  <span style={{ color: blackShade19 }}>
                    {" "}
                    -{" "}
                    {getOndcBapNameBySubscriberId(
                      grievanceData?.context?.bap_id
                    )}
                  </span>
                ) : null}
              </Typography>
            </Typography>
          </Box>
          <Typography
            sx={{
              ...styles.headingText,
              ...styles.title,
              fontSize: 12,
              color: orderType?.color,
              display: "inline-block",
            }}
            mb={0.5}
          >
            # {grievanceData?.message?.issue?.order_details?.id}
          </Typography>
        </Grid>
        {!matches && (
          <Grid item xs={12} sx={{ borderBottom: border27, pb: 1 }} />
        )}
        <Grid item xs={6} md={2.5}>
          <Typography sx={styles.greyText} mb={0.6}>
            Issue Id
          </Typography>
          <Typography sx={styles.blackText}># {issueId}</Typography>
          <Typography sx={styles.greyText}>
            {moment(issuedDate).format("DD MMM, hh:mm A")}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2} textAlign={{ xs: "end", md: "unset" }}>
          <Typography sx={styles.greyText} mb={0.6}>
            Issue Type
          </Typography>
          <Typography sx={styles.blackText}>
            {getIssueTypeText(issueType?.toUpperCase())}
          </Typography>
        </Grid>
        {matches && (
          <Grid item xs={6} md={2}>
            <Typography sx={styles.greyText} mb={0.6}>
              Status
            </Typography>
            <Typography
              sx={{
                ...styles.blackText,
                color: getStatusColor(status?.toLowerCase()),
              }}
            >
              {status}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container mt={{ md: 2, xs: 1 }}>
        <Grid item xs={6} md={2.5}>
          <Typography
            sx={{ ...styles.greyText, fontSize: { xs: 10.5, md: 13 } }}
            mb={0.6}
          >
            Expected Response Time
          </Typography>
          {responseTime ? (
            <>
              {" "}
              <Typography
                sx={{ ...styles.blackText, fontSize: { xs: 12, md: 13 } }}
                mb={0.5}
              >
                {addDurationToDate(issuedDate, responseTime)}(
                {formatDuration(responseTime)})
              </Typography>
              {status === "OPEN" ? (
                <Box
                  sx={styles.statusChip(
                    getColorChip(responseTimeStatus?.toLowerCase())
                  )}
                >
                  {responseTimeStatus}
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: { xs: 12.5, md: 12 },
                    fontWeight: 500,
                    lineHeight: 1.5,
                    color: getStatusColor(responseStatusSla?.toLowerCase()),
                  }}
                >
                  {responseStatusSla}
                </Typography>
              )}
            </>
          ) : (
            <Typography>--/--/--</Typography>
          )}
        </Grid>
        <Grid item xs={6} md={3} textAlign={{ xs: "end", md: "unset" }}>
          <Typography
            sx={{ ...styles.greyText, fontSize: { xs: 11, md: 13 } }}
            mb={0.6}
          >
            Expected Resolution time
          </Typography>
          {resolutionTime ? (
            <>
              <Typography
                sx={{ ...styles.blackText, fontSize: { xs: 11.8, md: 13 } }}
                mb={0.5}
              >
                {addDurationToDate(issuedDate, resolutionTime)}(
                {formatDuration(resolutionTime)}) 
              </Typography>
              {status === "PROCESSING" ? (
                <Box
                  sx={styles.statusChip(
                    getColorChip(resolutionTimeStatus?.toLowerCase())
                  )}
                >
                  {resolutionTimeStatus}
                </Box>
              ) : status === "OPEN" ? (
                <Box
                  sx={styles.statusChip(
                    getColorChip(resolutionTimeStatus?.toLowerCase())
                  )}
                >
                  {resolutionTimeStatus}
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: { xs: 12.5, md: 12 },
                    fontWeight: 500,
                    lineHeight: 1.5,
                    color: getStatusColor(resolutionStatusSla?.toLowerCase()),
                  }}
                >
                  {resolutionStatusSla}
                </Typography>
              )}
            </>
          ) : (
            <Typography>--/--/--</Typography>
          )}
        </Grid>
        {(status === "RESOLVED" || status === "CLOSED") && (
          <>
            {!matches && (
              <Grid item xs={12} sx={{ borderBottom: border27, pb: 1 }} />
            )}
            <Grid item xs={7} md={4.5} mt={{ xs: 1, md: 0 }}>
              <Box width={"90%"}>
                <Typography sx={styles.greyText} mb={0.6}>
                  Resolution
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: 12.5, md: 13 },
                    fontWeight: 500,
                    lineHeight: 1.5,
                    color: copyBtnColor,
                  }}
                >
                  {msgResolution}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={5}
              md={2.}
              textAlign={{ xs: "end", md: "unset" }}
              mt={{ xs: 1, md: 0 }}
            >
              <Typography sx={styles.greyText} mb={0.6}>
                Refund amount
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: 12.5, md: 13 },
                  fontWeight: 500,
                  lineHeight: 1.5,
                  color: copyBtnColor,
                }}
              >
                ₹ {refundAmount}
              </Typography>
            </Grid>
          </>
        )}
        {!matches && (
          <>
            <Grid item xs={12} sx={{ borderBottom: border27, pb: 1 }} />
            <Grid item xs={6} md={2.25} mt={{ xs: 1, md: 0 }}>
              <Typography sx={styles.greyText} mb={0.6}>
                Status
              </Typography>
              <Typography
                sx={{
                  ...styles.blackText,
                  color: getStatusColor(status?.toLowerCase()),
                }}
              >
                {status}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default IssueDetailsCard;

const styles = {
  logoImg: {
    width: { xs: "52px", md: "55px" },
    height: { xs: "52px", md: "55px" },
    border: border27,
    objectFit: "contain",
    borderRadius: "8px",
  },
  locationInfo: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    fontSize: { xs: 12, md: 12.5 },
    fontWeight: 500,
    color: greyShade03,
    lineHeight: "25px",
  },
  greyText: {
    color: bgColor15,
    fontSize: { xs: 11, md: 12.5 },
    fontWeight: 400,
    lineHeight: 1.5,
  },
  blackText: {
    color: blackShade01,
    fontSize: { xs: 12.5, md: 13 },
    fontWeight: { md: 600, xs: 500 },
    lineHeight: 1.5,
  },

  statusChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    p: "5px 13px",
    borderRadius: "50px",
    width: "max-content",
  }),
};
