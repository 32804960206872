import moment from "moment";
import { getApiInstance } from "../../AppService";
import { CURRENT_DATE, FROM_DATE, GET_AFFILIATE_PARTNERS, GET_AFFILIATE_PARTNERS_DATA, GET_AFFILIATE_TOTAL_ORDERS, GET_TOP_AFFILIATES } from "../../constant";



export const getAllAffiliateTotalOrdersApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(GET_AFFILIATE_TOTAL_ORDERS, {
      params: {
        pageNo: data.pageNo,
        from: FROM_DATE,
        to: CURRENT_DATE,
        pageSize: data.pageSize,
      },
    });
    console.log("getAllAffiliateTotalOrdersApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAllAffiliatePartnersApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data.businessId,
      },
    });
    const response = await instance.get(GET_AFFILIATE_PARTNERS, {
      params: {
        pageNo: data.pageNo,
        from: FROM_DATE,
        to: CURRENT_DATE,
        pageSize: data.pageSize,
      },
    });
    console.log("getAllAffiliatePartnersApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};export const getAllAffiliatePartnersDataApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data.businessId,
      },
    });
    const response = await instance.get(GET_AFFILIATE_PARTNERS_DATA, {
      params: {
        from: FROM_DATE,
        to: CURRENT_DATE,
      },
    });
    console.log("getAllAffiliatePartnersDataApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAllTopAffiliatesApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data,
      },
    });
    const response = await instance.get(GET_TOP_AFFILIATES, {
      params: {
        from: FROM_DATE,
        to: CURRENT_DATE,
      },
    });
    console.log("getAllTopAffiliatesApi", response);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};


