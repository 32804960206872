import React from "react";
import {
  blackShade05,
  greyColor6,
  logoImgborder1,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { Box, Grid, Typography } from "@mui/material";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import { addThousandsSeparator } from "../../utils/helper";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";

function CRMDashboardContent({ data }) {
  return (
    <Box
      width={{ xs: "100%", md: "60%" }}
      display="flex"
      justifyContent="space-between"
    >
      <Box sx={{ ...styles.infoBox }}>
        <StatsInfoCard
          icon={<PeopleOutlineOutlinedIcon sx={{ fontSize: "24px" }} />}
          label="TOTAL CUSTOMERS"
          value={data?.totalBuyers || 0}
        />
        <StatsInfoCard
          icon="https://storage.googleapis.com/bodefaults/businessdashboardweb/total-order.svg"
          // iconBG={iconBG1}
          label="TOTAL ORDERS"
          value={data?.totalOrders || 0}
          img
        />

        <StatsInfoCard
          icon="https://storage.googleapis.com/bodefaults/businessdashboardweb/total-sales.svg"
          label="TOTAL SALES"
          value={`₹ ${
            data?.totalAmount ? addThousandsSeparator(data?.totalAmount) : 0
          }`}
          img
        />
      </Box>
    </Box>
  );
}

export default CRMDashboardContent;

const StatsInfoCard = ({ icon, iconBG, label, value, img }) => {
  return (
    <Box display="flex" gap="10px" alignItems="center">
      <Box sx={{ ...styles.iconBG, backgroundColor: "rgb(5 60 81 / 7%)" }}>
        {img ? (
          <Box
            component="img"
            src={icon}
            width={{ xs: "21px", md: "21px" }}
            height={{ xs: "21px", md: "21px" }}
            sx={{
              objectFit: "contain",
            }}
          />
        ) : (
          icon
        )}
      </Box>

      <Box>
        <Typography
          fontSize={{ md: 12, xs: 12 }}
          fontWeight={500}
          color={greyColor6}
        >
          {label}
        </Typography>

        <Typography
          fontSize={{ md: 16, xs: 13 }}
          fontWeight={600}
          color={blackShade05}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  infoBox: {
    width: { md: "100%", xs: "100vw" },
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: whiteColor,
    baxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    padding: "10px",
    border: logoImgborder1,
    borderRadius: "10px",
    gap: "10px",
  },
  iconCircle: {
    width: "45px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(5 60 81 / 7%)",
    borderRadius: "50%",
  },
  iconBG: {
    width: "45px",
    height: "45px",
    display: { xs: "none", md: "flex" },
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
};
