import React, { useEffect, useState } from "react";
import ModalCard from "../../../components/ModalCard";
import {
  BGColor02,
  blackShade19,
  blueColor04,
  greyColor40,
  greyColor9,
  logoImgborder,
  redColor05,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Radio,
  Stack,
  Typography,
} from "@mui/material";

const MultiProductPushPopup = ({
  openModal,
  setOpenModal,
  branchInfoList,
  setSubBranchDataList,
  mainBranchProducts,
  selectedRowCount,
  onSubmit,
  branchPushLoader,
}) => {
  const onSelectBranch = (value, index) => {
    const dataArr = [...branchInfoList];

    const updatedList = dataArr?.map((branchInfo, branchIndex) => {
      return {
        ...branchInfo,
        isSelected: branchIndex === index,
      };
    });

    console.log("onSelectBranch___:", updatedList);

    setSubBranchDataList(updatedList);
  };

  return (
    <>
      <ModalCard
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        width="30%"
      >
        <Box sx={{ backgroundColor: whiteColor, borderRadius: 1.8 }}>
          <Box
            sx={{
              backgroundColor: BGColor02,
              p: 1.5,
              borderRadius: "14.4px 14.4px 0 0",
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              fontSize={16}
              fontWeight={600}
              color={blackShade19}
              mb={0.3}
            >
              Catalog Push to Branch
              <Typography
                variant="body1"
                fontSize={13}
                fontWeight={500}
                color={greyColor9}
              >
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                    color: blueColor04,
                  }}
                >
                  {selectedRowCount} of {mainBranchProducts?.length}
                </span>{" "}
                rows selected
              </Typography>
            </Typography>
          </Box>

          <Box sx={styles.modelBodyBox}>
            {branchInfoList?.map((item, index) => {
              return (
                <SubBranchCard
                  key={item?.id}
                  isSelected={item?.isSelected || false}
                  logo={item?.logoURL}
                  businessName={item?.businessName}
                  onClick={() => {
                    onSelectBranch(item?.isSelected || false, index);
                  }}
                />
              );
            })}
          </Box>

          <Box
            sx={{
              backgroundColor: BGColor02,
              p: { xs: "10px 10px 10px 10px", md: "10px 16px 16px 16px" },
              borderRadius: "0 0 14.4px 14.4px",
            }}
          >
            {/* <Typography
              fontSize={{ xs: 11.5, md: 12 }}
              fontWeight={400}
              color={greyColor40}
              lineHeight="20px"
              mb={1}
            >
              10 products will be pushed to selected branch.
            </Typography> */}

            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={4}
            >
              <Box />
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  sx={styles.backBtn}
                  disableElevation
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  CANCEL
                </Button>

                <Button
                  variant="contained"
                  disableElevation
                  sx={styles.applyBtn}
                  onClick={() => {
                    onSubmit();
                  }}
                  disabled={
                    branchPushLoader ||
                    branchInfoList?.every((item) => !item.isSelected)
                  }
                  startIcon={
                    branchPushLoader && (
                      <CircularProgress size={16} sx={{ color: "inherit" }} />
                    )
                  }
                >
                  PUSH TO BRANCH
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>
      </ModalCard>
    </>
  );
};

export default MultiProductPushPopup;

const SubBranchCard = ({ isSelected, logo, businessName, onClick }) => {
  return (
    <Box sx={styles.subBranchCard}>
      <Radio
        size="large"
        checked={isSelected}
        onChange={() => {
          onClick();
        }}
      />

      <Box sx={styles.logoImg} component="img" src={logo} />

      <Typography fontSize={13.5} fontWeight={600} color={blackShade19} ml={2}>
        {businessName}
      </Typography>
    </Box>
  );
};

const styles = {
  backBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: whiteColor,
    border: `1px solid ${redColor05}`,
    color: redColor05,
    p: "5px 20px",
    "&:hover": {
      color: redColor05,
      backgroundColor: whiteColor,
    },
  },

  applyBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: blueColor04,
    border: `1px solid ${blueColor04}`,
    color: whiteColor,
    p: "5px 20px",
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },

  modelBodyBox: {
    height: "60vh",
    overflowY: "scroll",
    p: "0px 12px 0 12px",
    mb: 1,
  },

  subBranchCard: {
    display: "flex",
    alignItems: "center",
    py: 2,
    borderBottom: logoImgborder,
  },

  logoImg: {
    width: "45px",
    height: "45px",
    border: logoImgborder,
    borderRadius: "5px",
    objectFit: "contain",
  },
};
