import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackShade05,
  blackShade159,
  deleteBtnColor,
  greenColor3,
  greyColor11,
  greyColor28,
  greyColor29,
  greyColor30,
  greyColor6,
  heroSectionText,
  lightBlueColor01,
  logoImgborder1,
  mobilePrimary,
  orangeStatusColor,
  outOfStockColor,
  tableHeaderColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import {
  addRewardPointApi,
  approveCart,
  getCartInfoApi,
  getRewardPointsApi,
  updateRewardPointApi,
} from "../../../service/api_calls/orders/OrdersApi";
import moment from "moment";
import {
  addThousandsSeparator,
  getOrderDeliveryTypeText,
  getOrderType,
} from "../../../utils/helper";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import PickupDetailsPopupContent from "./ApprovePopups/PickupDetailsPopupContent";
import ModalCard from "../../../components/ModalCard";
import ConfirmationAlert from "./Components/ConfirmationAlert";

function ApprovePopupContent({
  orderData,
  handleCancel,
  handleApproveSuccess,
  handleApproveOrder,
  commerceInfo,
  getOndcBapNameBySubscriberId,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [cartInfo, setCartInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(false);

  const [approveLoading, setApproveLoading] = useState(false);

  const orderType = getOrderType(orderData);

  const getCartData = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    setLoading(true);

    if (orderData?.cartId) {
      const response = await getCartInfoApi({
        cartId: orderData?.cartId,
        businessId,
      });

      if (response?.cart) {
        setCartInfo(response?.cart || null);
        setLoading(false);
        return;
      }
    }

    setLoading(false);
  };

  const getBGColorByStatus = (status) => {
    switch (status) {
      case "ordercreated":
        return orangeStatusColor;
      case "orderapproved":
        return blackShade05;
      case "ordershipped":
        return lightBlueColor01;
      case "orderdelivered":
        return lightBlueColor01;
      case "bordercanceled":
        return outOfStockColor;

      default:
        return "";
    }
  };

  const getOrderStatusText = (status) => {
    switch (status) {
      case "ordercreated":
        return "Pending";
      case "orderapproved":
        return "Ready to Ship";
      case "ordershipped":
        return "Shipped";
      case "orderdelivered":
        return "Completed";
      case "bordercanceled":
        return "Canceled";

      default:
        return "";
    }
  };

  const getBoxType = (type, weight) => {
    if (type?.toLowerCase() === "business") {
      return "";
    }
    const deliveryObj = commerceInfo?.info?.deliveryPartners?.find(
      (obj) => obj.deliveryType === type
    );

    if (deliveryObj)
      for (const item of deliveryObj?.packageRange) {
        if (weight >= item.from && weight < item.to) {
          return item.value;
        }
      }
  };

  const approveConfirmation = () => {
    if (cartInfo?.deliveryType?.type === "delhiveryb2b") {
      handleApproveOrder("businessOrderApprovedBtoB");
      return;
    }
    setConfirmationPopup(true);
  };

  const onOrderApprove = async () => {
    setApproveLoading(true);

    const businessId = sessionStorage.getItem("selectedBusinessId");

    if (
      cartInfo?.deliveryType?.type?.toLowerCase() === "delhivery" ||
      cartInfo?.deliveryType?.type?.toLowerCase() === "telyport" ||
      cartInfo?.deliveryType?.type?.toLowerCase() === "business" ||
      !cartInfo?.deliveryType
    ) {
      const approveData = {
        notesBusiness: "Thanks for your order",
        deliveryDateRange: !cartInfo?.deliveryType
          ? "-"
          : cartInfo?.deliveryDateRange,
        shippingCost: cartInfo?.shippingCost,
        totalQuantity: cartInfo?.totalQuantity,
        orderTotalProductCost: cartInfo?.orderTotalProductCost,
        orderTotalCost: cartInfo?.orderTotalCost,
        productList: cartInfo?.productList,
        claimedPoint: cartInfo?.claimedPoint,
        btobInfo: !cartInfo?.deliveryType
          ? null
          : {
              dimensions: [
                {
                  length: cartInfo?.packageInfo?.length,
                  width: cartInfo?.packageInfo?.width,
                  height: cartInfo?.packageInfo?.height,
                  count: 1,
                  boxType: getBoxType(
                    cartInfo?.deliveryType?.type,
                    cartInfo?.packageInfo?.weight
                  ),
                  unit: "cm",
                },
              ],
            },
      };

      const data = { cartId: orderData?.cartId, businessId, data: approveData };

      console.log("APPROVE_DATA", data);
      console.log("CART_DATA", cartInfo);

      // handleApproveOrder(
      //   cartInfo?.deliveryType?.type?.toLowerCase() === "business"
      //     ? "businessOrderApproved"
      //     : "OrderApproved"
      // );

      const approveResponse = await approveCart(data);

      if (approveResponse) {
        console.log("HIT 1");
        if (cartInfo?.claimType === "cashback" || cartInfo?.claimType === "") {
          console.log("HIT 2");
          const rewardResponse = await getRewardPointsApi({
            businessId,
            profileId: cartInfo?.profileId,
          });

          if (rewardResponse?.details?.[0]?.id) {
            const awardedPoints =
              ((Number(cartInfo?.orderTotalProductCost) -
                Number(cartInfo?.claimedPoint)) *
                Number(commerceInfo?.info?.rewardPointPercent)) /
              100;
            const updateData = {
              businessId: businessId,
              rewardPointId: rewardResponse?.details?.[0]?.id,
              data: {
                cartId: orderData?.cartId,
                businessId: businessId,
                awardedReason: "business wallet",
                referralSenderPhone: "",
                referralSenderProfileId: "",
                referralSenderName: "",
                status: "active",
                profileId: cartInfo?.profileId,
                phoneNo: cartInfo?.customerAddress?.phoneNo,
                expiryDays: commerceInfo?.info?.rewardExpirationDays,
                awardedPoints: awardedPoints,
                awardedDate: moment().valueOf(),
              },
            };

            const updateResponse = updateRewardPointApi(updateData);

            if (updateResponse) {
              setApproveLoading(false);
              setConfirmationPopup(false);

              if (!cartInfo?.deliveryType) {
                handleApproveSuccess();
                return;
              }

              handleApproveOrder(
                cartInfo?.deliveryType?.type?.toLowerCase() === "business"
                  ? "businessOrderApproved"
                  : "OrderApproved"
              );
            }

            console.log("REWARD_DATA", rewardResponse);
            console.log("UPDATA_PAYLOAD_DATA", updateData);
          } else {
            const awardedPoints =
              ((Number(cartInfo?.orderTotalProductCost) -
                Number(cartInfo?.claimedPoint)) *
                Number(commerceInfo?.info?.rewardPointPercent)) /
              100;

            const addData = {
              businessId: businessId,
              data: {
                cartId: orderData?.cartId,
                businessId: businessId,
                awardedReason: "purchase",
                referralSenderPhone: " ",
                referralSenderProfileId: " ",
                referralSenderName: " ",
                status: "active",
                profileId: cartInfo?.profileId,
                phoneNo: cartInfo?.customerAddress?.phoneNo,
                expiryDays: commerceInfo?.info?.rewardExpirationDays,
                awardedPoints: awardedPoints,
                awardedDate: moment().valueOf(),
              },
            };

            const addResponse = addRewardPointApi(addData);

            if (addResponse) {
              setApproveLoading(false);
              setConfirmationPopup(false);

              if (!cartInfo?.deliveryType) {
                handleApproveSuccess();
                return;
              }

              handleApproveOrder(
                cartInfo?.deliveryType?.type?.toLowerCase() === "business"
                  ? "businessOrderApproved"
                  : "OrderApproved"
              );
            }

            console.log("ADD_PAYLOAD_DATA", addData);
          }
        } else {
          console.log("HIT 3");
          setApproveLoading(false);
          setConfirmationPopup(false);

          if (!cartInfo?.deliveryType) {
            handleApproveSuccess();
            return;
          }

          handleApproveOrder(
            cartInfo?.deliveryType?.type?.toLowerCase() === "business"
              ? "businessOrderApproved"
              : "OrderApproved"
          );
        }
      }
    }
  };

  const getOrderTotalCostForPopup = (cartInfo) => {
    // {
    //   !orderData?.cartType && orderData?.ondcCart
    //     ? addThousandsSeparator(
    //         Number(cartInfo?.orderTotalProductCost) +
    //           Number(cartInfo?.shippingCost || 0) -
    //           Number(cartInfo?.claimedPoint || 0) +
    //           Number(cartInfo?.convenienceFee || 0) +
    //           Number(cartInfo?.packingInfo?.cost || 0)
    //       )
    //     : addThousandsSeparator(
    //         Number(cartInfo?.orderTotalProductCost) +
    //           Number(cartInfo?.shippingCost || 0) -
    //           Number(cartInfo?.claimedPoint || 0)
    //       );
    // }

    // {addThousandsSeparator(
    //   Number(cartInfo?.orderTotalProductCost) +
    //     Number(cartInfo?.codCost?.codCharges) +
    //     Number(cartInfo?.shippingCost || 0) -
    //     Number(cartInfo?.claimedPoint || 0)
    // )}

    if (!orderData?.cartType && orderData?.ondcCart) {
      return addThousandsSeparator(
        Number(cartInfo?.orderTotalProductCost) +
          Number(cartInfo?.shippingCost || 0) -
          Number(cartInfo?.claimedPoint || 0) +
          Number(cartInfo?.convenienceFee || 0) +
          Number(cartInfo?.packingInfo?.cost || 0)
      );
    } else if (cartInfo?.codCost) {
      return addThousandsSeparator(
        Number(cartInfo?.orderTotalProductCost) +
          Number(cartInfo?.codCost?.codCharges) +
          Number(cartInfo?.shippingCost || 0) -
          Number(cartInfo?.claimedPoint || 0)
      );
    } else {
      return addThousandsSeparator(
        Number(cartInfo?.orderTotalProductCost) +
          Number(cartInfo?.shippingCost || 0) -
          Number(cartInfo?.claimedPoint || 0)
      );
    }
  };

  useEffect(() => {
    getCartData();
    return () => {};
  }, []);

  return (
    <Box overflow="hidden">
      {loading ? (
        <Box
          sx={{
            minHeight: "100px",
            backgroundColor: whiteColor,
            borderRadius: "12px",
            pt: "20px",
          }}
        >
          <ThreeDotLoader />
        </Box>
      ) : (
        <Box sx={styles.card}>
          <ModalCard
            open={confirmationPopup}
            handleClose={() => {
              setConfirmationPopup(false);
            }}
            width="33%"
          >
            <ConfirmationAlert
              title="Order Approval"
              msg="Are you sure you want to approve this order ?"
              // total={`₹
              // ${JSON.stringify(cartInfo?.orderTotalProductCost)} +
              // ${JSON.stringify(cartInfo?.convenienceFee)} +
              // ${JSON.stringify(cartInfo?.packingInfo?.cost)} +
              // ${JSON.stringify(cartInfo?.shippingCost)} -
              // ${JSON.stringify(cartInfo?.claimedPoint)} -->>
              //   ${addThousandsSeparator(
              //     Number(cartInfo?.orderTotalProductCost) +
              //       Number(cartInfo?.convenienceFee) +
              //       Number(cartInfo?.packingInfo?.cost) +
              //       Number(cartInfo?.shippingCost || 0) -
              //       Number(cartInfo?.claimedPoint || 0)
              //   )}

              //   `}

              total={`₹ ${getOrderTotalCostForPopup(cartInfo)}`}
              quantity={cartInfo?.totalQuantity || ""}
              handleCancel={() => {
                setConfirmationPopup(false);
              }}
              handleOk={onOrderApprove}
              okLoading={approveLoading}
            />
          </ModalCard>
          <Box display="flex" justifyContent="space-between">
            <Typography
              variant="h6"
              fontSize={{ md: "17px" }}
              fontWeight={600}
              color={blackShade05}
            >
              Order Detail
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              gap={1}
              mr={{ md: 10, xs: 1.5 }}
            >
              <Button
                sx={styles.approveIconBtn}
                variant="contained"
                disableElevation
                disabled={approveLoading}
                onClick={approveConfirmation}
              >
                {orderData?.deliveryType?.type == "delhiveryb2b"
                  ? "ADD SHIPMENT INFO"
                  : "APPROVE"}
              </Button>
              {/* <Button
                sx={styles.cancelIconBtn}
                variant="contained"
                disableElevation
                onClick={handleCancel}
              >
                CANCEL
              </Button> */}
            </Box>
          </Box>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} md={12}>
              <Box sx={styles.orderInfoBox}>
                <Box>
                  <Box mb={{ md: 0.8, xs: 0.5 }}>
                    <Typography variant="h6" sx={styles.orderIdText}>
                      Order ID:{" "}
                      <Typography
                        variant="h6"
                        sx={{ ...styles.orderIdText, fontWeight: 600 }}
                      >
                        {cartInfo?.orderId}
                      </Typography>
                    </Typography>
                  </Box>

                  {orderType?.orderType == "ondcOrder" && (
                    <>
                      <Box mb={{ md: 0.8, xs: 0.5 }}>
                        <Typography variant="h6" sx={styles.orderIdText}>
                          ONDC Order ID:{" "}
                          <Typography
                            variant="h6"
                            sx={{ ...styles.orderIdText, fontWeight: 600 }}
                          >
                            {orderData?.ondcOrderId || ""}
                          </Typography>
                        </Typography>
                      </Box>

                      <Box mb={{ md: 0.8, xs: 0.5 }}>
                        <Typography variant="h6" sx={styles.orderIdText}>
                          ONDC Buyer App:{" "}
                          <Typography
                            variant="h6"
                            sx={{ ...styles.orderIdText, fontWeight: 600 }}
                          >
                            {getOndcBapNameBySubscriberId(orderData?.ondcBapId)}
                          </Typography>
                        </Typography>
                      </Box>
                    </>
                  )}

                  <Typography variant="h6" sx={styles.orderIdText}>
                    Order Date:{" "}
                    <Typography
                      variant="h6"
                      sx={{ ...styles.orderIdText, fontWeight: 600 }}
                    >
                      {moment(cartInfo?.orderCreatedOn).format("MMM Do, YYYY")}
                    </Typography>
                  </Typography>
                </Box>

                <Box
                  sx={styles.statusTag(
                    getBGColorByStatus(cartInfo?.orderStatus)
                  )}
                >
                  {getOrderStatusText(cartInfo?.orderStatus)}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box sx={styles.summaryContainer}>
                <Grid container spacing={{ md: 6, xs: 2 }}>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ ...styles.summaryCard, border: "none" }}>
                      <Typography
                        variant="h3"
                        fontSize={14}
                        fontWeight={600}
                        color={blackShade05}
                        pb="12px"
                        pt="4px"
                        sx={{
                          borderBottom: `1px solid ${greyColor28}`,
                          textAlign: { md: "left", xs: "left" },
                        }}
                      >
                        SUMMARY
                      </Typography>

                      <Box mt={1}>
                        <SummaryDataRow
                          label="Subtotal:"
                          value={`₹${addThousandsSeparator(
                            cartInfo?.orderTotalProductCost
                          )}`}
                        />

                        <SummaryDataRow
                          label="Total Quantity:"
                          value={cartInfo?.totalQuantity}
                        />

                        <SummaryDataRow
                          label="Taxes:"
                          value="Included"
                          valueColor={greenColor3}
                        />

                        <SummaryDataRow
                          label="Shipping & Handling:"
                          value={`₹${addThousandsSeparator(
                            cartInfo?.shippingCost
                          )}`}
                        />

                        <SummaryDataRow
                          label="Discount:"
                          value={`₹${addThousandsSeparator(
                            cartInfo?.claimedPoint
                          )}`}
                        />
                        {!orderData?.cartType && orderData?.ondcCart ? (
                          <>
                            <SummaryDataRow
                              label="Convenience Fee:"
                              value={`₹${addThousandsSeparator(
                                cartInfo?.convenienceFee
                              )}`}
                            />

                            <SummaryDataRow
                              label="Packing Cost:"
                              value={`₹${addThousandsSeparator(
                                cartInfo?.packingInfo?.cost
                              )}`}
                            />
                          </>
                        ) : null}

                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            borderTop: `1px solid ${greyColor28}`,
                            borderBottom: {
                              md: "none",
                              xs: `1px solid ${greyColor28}`,
                            },
                            mb: "4px",
                            py: { md: "12px", xs: "6px" },
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontSize={14}
                            fontWeight={600}
                            color={blackShade05}
                          >
                            Total
                          </Typography>

                          <Typography
                            variant="body2"
                            fontSize={14}
                            fontWeight={600}
                            color={{ md: heroSectionText, xs: mobilePrimary }}
                          >
                            ₹
                            {!orderData?.cartType && orderData?.ondcCart
                              ? addThousandsSeparator(
                                  Number(cartInfo?.orderTotalProductCost) +
                                    Number(cartInfo?.shippingCost || 0) -
                                    Number(cartInfo?.claimedPoint || 0) +
                                    Number(cartInfo?.convenienceFee || 0) +
                                    Number(cartInfo?.packingInfo?.cost || 0)
                                )
                              : addThousandsSeparator(
                                  Number(cartInfo?.orderTotalProductCost) +
                                    Number(cartInfo?.shippingCost || 0) -
                                    Number(cartInfo?.claimedPoint || 0)
                                )}
                          </Typography>
                        </Box>
                        {cartInfo?.codCost && (
                          <>
                            <SummaryDataRow
                              label="COD Cost:"
                              value={`₹${addThousandsSeparator(
                                cartInfo?.codCost?.codCharges
                              )}`}
                            />
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              sx={{
                                borderTop: `1px solid ${greyColor28}`,
                                mb: "4px",
                                pt: "12px",
                              }}
                            >
                              <Typography
                                variant="body1"
                                fontSize={14}
                                fontWeight={600}
                                color={blackShade05}
                              >
                                Total
                              </Typography>

                              <Typography
                                variant="body2"
                                fontSize={14}
                                fontWeight={600}
                                color={{
                                  md: heroSectionText,
                                  xs: mobilePrimary,
                                }}
                              >
                                ₹
                                {addThousandsSeparator(
                                  Number(cartInfo?.orderTotalProductCost) +
                                    Number(cartInfo?.codCost?.codCharges) +
                                    Number(cartInfo?.shippingCost || 0) -
                                    Number(cartInfo?.claimedPoint || 0)
                                )}
                              </Typography>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ ...styles.summaryCard, border: "none" }}>
                      <Typography
                        variant="h3"
                        fontSize={14}
                        fontWeight={600}
                        color={blackShade05}
                        pb="12px"
                        pt="4px"
                        sx={{
                          borderBottom: `1px solid ${greyColor28}`,
                          textAlign: { md: "left", xs: "left" },
                        }}
                      >
                        Delivery Address
                      </Typography>

                      <Box mt={1}>
                        <Typography
                          variant="h6"
                          fontSize={14}
                          fontWeight={600}
                          color={blackShade05}
                          lineHeight="25px"
                          sx={{ mt: "4px" }}
                        >
                          {cartInfo?.customerAddress?.name}{" "}
                        </Typography>

                        {cartInfo?.customerAddress?.addressLine1 && (
                          <Typography
                            variant="body1"
                            fontSize={12}
                            fontWeight={500}
                            color={greyColor6}
                            sx={{ m: { xs: "0", md: "6px 0 10px" } }}
                          >
                            {!orderData?.cartType && orderData?.ondcCart
                              ? `${cartInfo?.customerAddress?.houseNo},`
                              : ""}{" "}
                            {cartInfo?.customerAddress?.addressLine1},{" "}
                            {cartInfo?.customerAddress?.landMark},{" "}
                            {cartInfo?.customerAddress?.city} -{" "}
                            {cartInfo?.customerAddress?.zip},{" "}
                            {cartInfo?.customerAddress?.state},{" "}
                            {cartInfo?.customerAddress?.country}
                          </Typography>
                        )}

                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={500}
                          color={greyColor6}
                        >
                          Ph: {cartInfo?.customerAddress?.phoneNo}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box sx={{ ...styles.summaryCard, border: "none" }}>
                      <Typography
                        variant="h3"
                        fontSize={14}
                        fontWeight={600}
                        color={blackShade05}
                        pb="12px"
                        pt="4px"
                        sx={{
                          borderBottom: `1px solid ${greyColor28}`,
                          textAlign: { md: "left", xs: "left" },
                        }}
                      >
                        Delivery Method
                      </Typography>

                      <Box m="12px 0 -10px">
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={500}
                          color={greyColor6}
                          sx={{ m: "6px 0 10px", textTransform: "capitalize" }}
                        >
                          {!cartInfo?.deliveryType
                            ? "Store Pickup"
                            : getOrderDeliveryTypeText(
                                cartInfo?.deliveryType?.type
                              )}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {matches ? null : (
              <>
                <Grid item xs={12} md="none">
                  <Typography
                    variant="h3"
                    fontSize={15}
                    fontWeight={600}
                    color={blackShade05}
                    sx={{
                      textAlign: { md: "none", xs: "left" },
                    }}
                  >
                    Product Overview
                  </Typography>
                </Grid>
                <Grid item xs={12} md="none">
                  {/* <Box sx={styles.summaryCard}> */}
                  {cartInfo?.productList?.map((item, index) => {
                    const savings =
                      Number(item?.productOriginalPrice) *
                        Number(item?.currentQuantity) -
                      Number(item?.productSalePrice) *
                        Number(item?.currentQuantity);

                    return (
                      <Box sx={{ ...styles.summaryCard, p: "12px", mb: 1 }}>
                        {/* <Box sx={styles.productRow} key={item?.productId}> */}
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Box
                              display="flex"
                              alignItems="center"
                              gap="12px"
                              // ml={2}
                            >
                              <Box
                                component="img"
                                src={item?.productPicURL}
                                alt={item?.productName}
                                sx={styles.productImg}
                              />

                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  fontSize: "14px",
                                  fontWeight: 500,
                                }}
                              >
                                {item?.productName}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: "12px",
                                  mb: 1,
                                }}
                              >
                                UNIT PRICE
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹{addThousandsSeparator(item?.productSalePrice)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: "12px",
                                  mb: 1,
                                }}
                              >
                                QUANTITY
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                {item?.currentQuantity}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: "12px",
                                  mb: 1,
                                }}
                              >
                                PRICE
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹
                                {addThousandsSeparator(item?.productTotalPrice)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: "12px",
                                  mb: 1,
                                }}
                              >
                                SAVINGS
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹{addThousandsSeparator(savings)}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </Grid>
              </>
            )}
            <Grid item xs={12} md={12}>
              {matches ? (
                <Box sx={styles.table}>
                  <Box sx={styles.tableHeader}>
                    <Grid container>
                      <Grid item xs={4.5}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          PRODUCT
                        </Typography>
                      </Grid>

                      <Grid item xs={1.5}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          UNIT PRICE
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          QUANTITY
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          PRICE
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          SAVINGS
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  {cartInfo?.productList?.map((item, index) => {
                    const savings =
                      Number(item?.productOriginalPrice) *
                        Number(item?.currentQuantity) -
                      Number(item?.productSalePrice) *
                        Number(item?.currentQuantity);

                    return (
                      <Box sx={styles.productRow} key={item?.productId}>
                        <Grid container spacing={2}>
                          <Grid item xs={4.5}>
                            <Box
                              display="flex"
                              alignItems="center"
                              gap="10px"
                              ml={2}
                            >
                              <Box
                                component="img"
                                src={item?.productPicURL}
                                alt={item?.productName}
                                sx={styles.productImg}
                              />

                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                {item?.productName}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={1.5}>
                            <Box sx={styles.centerText}>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹{addThousandsSeparator(item?.productSalePrice)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={2}>
                            <Box sx={styles.centerText}>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                {item?.currentQuantity}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={2}>
                            <Box sx={styles.centerText}>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹
                                {addThousandsSeparator(item?.productTotalPrice)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={2}>
                            <Box sx={styles.centerText}>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹{addThousandsSeparator(savings)}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default ApprovePopupContent;

const SummaryDataRow = ({ label, value, valueColor }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={{ md: "10px", xs: "4px" }}
    >
      <Typography
        variant="body1"
        fontSize={13}
        fontWeight={500}
        color={blackShade05}
      >
        {label}
      </Typography>

      <Typography
        variant="body2"
        fontSize={13.5}
        fontWeight={600}
        color={valueColor || greyColor30}
      >
        {value}
      </Typography>
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "12px",
    padding: "16px",

    maxHeight: { md: "95vh", xs: "70vh" },
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
      // height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blackShade159,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },

  approveIconBtn: {
    padding: { md: "5px 24px", xs: "3px 12px" },
    fontSize: "12px",
    fontWeight: 600,
    color: lightBlueColor01,
    backgroundColor: whiteColor,
    border: "1px solid " + lightBlueColor01,
    "&:hover": {
      color: whiteColor,
      backgroundColor: lightBlueColor01,
    },
  },
  cancelIconBtn: {
    padding: { md: "5px 24px", xs: "3px 12px" },
    fontSize: "12px",
    fontWeight: 600,
    color: deleteBtnColor,
    backgroundColor: whiteColor,
    border: "1px solid " + deleteBtnColor,
    "&:hover": {
      color: whiteColor,
      backgroundColor: deleteBtnColor,
    },
  },

  orderIdText: {
    fontSize: { md: 14, xs: 13 },
    fontWeight: 500,
    lineHeight: "27px",
    color: blackShade05,
    display: "inline",
  },

  orderInfoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "12px",
    border: `1px solid ${greyColor28}`,
    p: "12px 0 12px 12px",
  },

  statusTag: (bgColor) => ({
    backgroundColor: bgColor || lightBlueColor01,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "20px",
    color: whiteColor,
    p: "0 15px 0 20px",
    position: "relative",

    "&::before": {
      content: '" "',
      width: "15px",
      height: "28px",
      backgroundColor: whiteColor,
      rotate: "40deg",
      position: "absolute",
      left: -3,
      top: -8,
    },
  }),

  table: {
    border: `1px solid ${greyColor11}`,
    borderRadius: "7px",
  },

  tableHeader: {
    backgroundColor: tableHeaderColor,
    p: "15px 10px",
    borderRadius: "7px 7px 0 0",
  },

  tableHeaderText: {
    fontSize: 13,
    fontWeight: 500,
    color: greyColor29,
  },

  productRow: {
    p: { xs: "0 0", md: "18px 0" },
    borderBottom: "1px solid #f2f2f2",
  },

  productImg: {
    width: { xs: "50px", md: "60px" },
    height: { xs: "50px", md: "60px" },
    borderRadius: "8px",
    border: logoImgborder1,
  },

  productInfoText: {
    fontSize: 14,
    fontWeight: 500,
    color: greyColor29,
  },

  centerText: {
    height: "100%",
    display: "flex",
    alignItems: { xs: "flex-start", md: "center" },
    justifyContent: "flex-start",
  },

  summaryCard: {
    // padding: "12px",
    // borderRadius: "10px",
    border: { xs: `1px solid ${greyColor28}`, md: "none" },
    borderRadius: "6px",
    mt: { md: "none", xs: "none" },
    mb: { md: "none", xs: 0 },
  },

  summaryContainer: {
    border: `1px solid ${greyColor28}`,
    p: "12px",
    borderRadius: "12px",
  },
};
