import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  bgColor2,
  bgColor4,
  blackShade10,
  greyColor31,
  greyColor32,
} from "../configs/styles/muiThemes";

function FooterBig() {
  const navigate = useNavigate();
  const openLink = (link, target = "_blank") => {
    window.open(link, target);
  };

  return (
    <Box
      sx={{
        // backgroundColor: bgColor2,
        backgroundColor: bgColor4,
        pt: { xs: "20px", md: "50px" },
        borderTop: `1px solid ${greyColor32}`,
      }}
    >
      <Container sx={{ maxWidth: "1300px !important" }}>
        <Box>
          <Grid container>
            <Grid item xs={12} md={3}>
              <Box
                onClick={() => {
                  openLink("https://costbo.com", "_self");
                }}
              >
                <Box
                  component="img"
                  src="https://storage.googleapis.com/bodefaults/businessdashboardweb/logo.svg"
                  width={{ xs: "120px", md: "150px" }}
                  height="auto"
                  mb="25px"
                />
              </Box>

              <Typography
                variant="h5"
                fontSize={15}
                fontWeight={600}
                color={blackShade10}
                lineHeight="28px"
                mb="10px"
              >
                Download App
              </Typography>

              <Box display="flex" gap="15px" mb="20px">
                <SocialIconLink
                  icon="https://uploads-ssl.webflow.com/6495db14a37e7ef50b7e747d/64acde2d2ca618712be9bd50_logo-google-playstore-svgrepo-com.svg"
                  onClick={() => {
                    openLink(
                      "https://play.google.com/store/apps/details?id=com.costbo&pli=1"
                    );
                  }}
                />
                <SocialIconLink
                  icon="https://uploads-ssl.webflow.com/6495db14a37e7ef50b7e747d/64acde30b879772c57f91b0e_app-store-svgrepo-com.svg"
                  onClick={() => {
                    openLink("https://apps.apple.com/app/id1468091785");
                  }}
                />
              </Box>

              <Typography
                variant="h5"
                fontSize={15}
                fontWeight={600}
                color={blackShade10}
                lineHeight="28px"
                mb="10px"
              >
                Follow Us On
              </Typography>

              <Box display="flex" gap="15px" mb="20px">
                <SocialIconLink
                  icon="https://uploads-ssl.webflow.com/6495db14a37e7ef50b7e747d/6495db14a37e7ef50b7e75a1_Social%20Icon%20One.svg"
                  onClick={() => {
                    openLink("https://www.facebook.com/Costboconnect");
                  }}
                />
                <SocialIconLink
                  icon="https://uploads-ssl.webflow.com/6495db14a37e7ef50b7e747d/6495db14a37e7ef50b7e75b6_Social%20Icon%20Two.svg"
                  onClick={() => {
                    openLink("https://twitter.com/costBoconnect");
                  }}
                />
                <SocialIconLink
                  icon="https://uploads-ssl.webflow.com/6495db14a37e7ef50b7e747d/6495db14a37e7ef50b7e75a4_Social%20Icon%20Three.svg"
                  onClick={() => {
                    openLink("https://www.instagram.com/costbo.connect/");
                  }}
                />
                <SocialIconLink
                  icon="https://uploads-ssl.webflow.com/6495db14a37e7ef50b7e747d/64b9e86b42f7724f06f5e2c0_Group%20628665.webp"
                  onClick={() => {
                    openLink(
                      "https://www.linkedin.com/company/costbo?originalSubdomain=in"
                    );
                  }}
                />
                <SocialIconLink
                  icon="https://uploads-ssl.webflow.com/6495db14a37e7ef50b7e747d/64b9e83da6e0ed8004417bab_Group%20628663.webp"
                  onClick={() => {
                    openLink("https://www.youtube.com/c/costBo/videos");
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant="h3" sx={styles.heading}>
                Quick Links
              </Typography>

              <Box>
                <LinkText
                  text="Home"
                  onClick={() => {
                    openLink("https://www.costbo.com/", "_self");
                  }}
                />
                <LinkText
                  text="Solutions"
                  onClick={() => {
                    openLink("https://www.costbo.com/#Solutions", "_self");
                  }}
                />
                <LinkText
                  text="Discover & Buy"
                  onClick={() => {
                    openLink("https://buy.costbo.com/");
                  }}
                />
                <LinkText
                  text="Pricing"
                  onClick={() => {
                    openLink("https://www.costbo.com/pricing", "_self");
                  }}
                />
                <LinkText
                  text="Contact Us"
                  onClick={() => {
                    openLink("https://www.costbo.com/request-demo");
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant="h3" sx={styles.heading}>
                Information
              </Typography>

              <Box>
                <LinkText
                  text="Terms & Conditions"
                  onClick={() => {
                    openLink(
                      "https://www.costbo.com/terms-conditions",
                      "_self"
                    );
                  }}
                />
                <LinkText
                  text="Privacy Policy"
                  onClick={() => {
                    openLink("https://www.costbo.com/privacy-policy", "_self");
                  }}
                />
                <LinkText
                  text="Returns Policy"
                  onClick={() => {
                    openLink("https://www.costbo.com/return-policy", "_self");
                  }}
                />
                <LinkText
                  text="Cancellation Policy"
                  onClick={() => {
                    openLink(
                      "https://www.costbo.com/cancellation-policy",
                      "_self"
                    );
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant="h3" sx={styles.heading}>
                Support
              </Typography>

              <Box>
                <LinkText
                  text="Support"
                  onClick={() => {
                    openLink(
                      "https://mail.google.com/mail/u/0/?fs=1&to=ecommerce@costbo.com&tf=cm"
                    );
                  }}
                />
                <LinkText
                  text="Whatsapp"
                  onClick={() => {
                    openLink(
                      "https://api.whatsapp.com/send?phone=918088422615"
                    );
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={styles.copyrightBox}>
          <Typography variant="body2" sx={styles.copyrightText}>
            2023 CostBo Services Private Ltd, All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default FooterBig;

const SocialIconLink = ({ icon, onClick }) => {
  return (
    <Box onClick={onClick}>
      <Box component="img" src={icon} sx={styles.socialIcon} />
    </Box>
  );
};

const LinkText = ({ text, onClick }) => {
  return (
    <Box onClick={onClick} mb="18px">
      <Typography variant="h3" sx={styles.linkText}>
        {text}
      </Typography>
    </Box>
  );
};

const styles = {
  socialIcon: {
    width: { xs: "30px", md: "30px" },
    height: "auto",
    cursor: "pointer",
  },

  heading: {
    fontSize: { xs: 18, md: 20 },
    fontWeight: 600,
    color: blackShade10,
    lineHeight: "28px",
    mb: "25px",
  },

  linkText: {
    fontSize: 14,
    fontWeight: 400,
    color: greyColor31,
    lineHeight: "14px",
    mb: "10px",
    cursor: "pointer",
  },

  copyrightBox: {
    borderTop: "1px solid #cfcfcf",
    padding: "22px 0",
    mt: "50px",
  },

  copyrightText: {
    fontSize: 15,
    fontWeight: 400,
    color: blackShade10,
    textAlign: "center",
  },
};
