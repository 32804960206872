import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import DashboardMenu from "./Dashboard/DashboardMenu";
import DashboardHeader from "./Dashboard/DashboardHeader";
import {
  BGColor02,
  blackShade05,
  blueColor02,
  greyColor6,
} from "../configs/styles/muiThemes";
import BusinessInfoCard from "../components/Cards/BusinessInfoCard";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import AffiliateMarketingDashboardContent from "./AffiliateMarketingDashboard/AffiliateMarketingDashboardContent";
import { getBusinessDashboardData } from "../reducers/businessDashboard/BusinessDashboardSlice";
import Loader from "../components/Loader";
import { getAllAffiliatePanelData } from "../reducers/AffiliatePanels/AffiliateSlice";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import ThreeDotLoader from "../components/ThreeDotLoader";

function AffiliateMarketingDashboard() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );
    
  const { dashboardData, dashboardDataLoader } = useSelector(
    (state) => state.businessDashboard
  );
  

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }

      if (!dashboardData) {
        dispatch(getBusinessDashboardData({ businessId }));
      }
    }
  }, []);
  return (
    <Box sx={styles.container}>

      <DashboardLayout activeMenu="affiliateMarketing">
        {/* <Box sx={styles.content}> */}
          {dashboardDataLoader ? (
            <ThreeDotLoader />
          ) : (
            <AffiliateMarketingDashboardContent
              affiliateSaleInfo={dashboardData?.affiliateSaleInfo}
              uniqueAffiliateCount={dashboardData?.uniqueAffiliateCount}
              affiliateShareCount={dashboardData?.affiliateShareCount}
            />
          )}
        {/* </Box> */}
      </DashboardLayout>
    </Box>
  );
}

export default AffiliateMarketingDashboard;

const styles = {
  container: {
    minHeight: "100vh",
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },
};
