import moment from "moment";
import { checkValidMonthYear, validateDate } from "../../utils/validationUtils";
import { formatTimeToShip } from "../../utils/postDataHelpers/CatalogHelper";
import { parseDbObjectInsideArray } from "../../utils/validationHelper";
import { getSelectedCountryCodeByName } from "../../utils/helper";

export const validateReviewProductData = (
  productDetails,
  unitOfMeasurementData
) => {
  let allProducts = [...productDetails];

  let isError = false;

  productDetails.forEach((product, index) => {
    if (!product.isSelected) return;

    let errors = null;

    if (!product?.productDescription) {
      errors = {
        ...errors,
        productDescription: "Enter product description",
      };
    } else {
      delete errors?.productDescription;
    }

    if (!product?.brand) {
      errors = {
        ...errors,
        brand: "Enter brand name",
      };
    } else {
      delete errors?.brand;
    }

    if (!product?.origin) {
      errors = {
        ...errors,
        origin: "Select origin",
      };
    } else {
      delete errors?.origin;
    }

    if (
      !product?.productPhotoUrl?.[0] ||
      product?.productPhotoUrl?.[0] === ""
    ) {
      errors = {
        ...errors,
        productPhotoUrl: "Add product image",
      };
    } else {
      delete errors?.productPhotoUrl;
    }

    if (
      !product?.productCodeKey ||
      product?.productCodeKey === "Select Product Code"
    ) {
      errors = {
        ...errors,
        productCodeKey: "Select Product Code",
      };
    } else {
      delete errors?.productCodeKey;
    }

    if (!product?.productCodeValue) {
      errors = {
        ...errors,
        productCodeValue: "Enter value",
      };
    } else {
      delete errors?.productCodeValue;
    }

    if (product?.productCodeKey && product?.productCodeValue) {
      if (
        product?.productCodeKey === "GTIN" &&
        product?.productCodeValue?.length !== 12
      ) {
        errors = {
          ...errors,
          productCodeValue: "GTIN should be 12 digits",
        };
      } else if (
        product?.productCodeKey === "HSN" &&
        product?.productCodeValue?.length !== 8
      ) {
        errors = {
          ...errors,
          productCodeValue: "HSN should be 8 digits",
        };
      } else if (
        product?.productCodeKey !== "GTIN" &&
        product?.productCodeKey !== "HSN" &&
        product?.productCodeValue?.length !== 13
      ) {
        errors = {
          ...errors,
          productCodeValue: "Value should be 13 digits",
        };
      } else {
        delete errors?.productCodeValue;
      }
    }

    // if (!product?.gstInfo?.code) {
    //   errors = {
    //     ...errors,
    //     gstInfoCode: "Enter GST/HSN code",
    //   };
    // } else {
    //   delete errors?.gstInfoCode;
    // }

    if (!product?.gstInfo?.gstPercentage) {
      errors = {
        ...errors,
        gstPercentage: "Select GST percentage",
      };
    } else {
      delete errors?.gstPercentage;
    }

    if (
      !product?.unitOfmeasurement ||
      product?.unitOfmeasurement === "Select Unit Of Measurement"
    ) {
      errors = {
        ...errors,
        unitOfmeasurement: "Select Unit Of Measurement",
      };
    } else {
      delete errors?.unitOfmeasurement;
    }

    const isSelectedUnitValid = unitOfMeasurementData?.find(
      (item) => item?.value == product?.unitOfmeasurement?.toLowerCase()
    );

    if (!isSelectedUnitValid) {
      errors = {
        ...errors,
        unitOfmeasurement: "Select Unit Of Measurement",
      };
    } else {
      delete errors?.unitOfmeasurement;
    }

    if (!product?.unitOfmeasurementValue) {
      errors = {
        ...errors,
        unitOfmeasurementValue: "Enter unit value",
      };
    } else {
      delete errors?.unitOfmeasurementValue;
    }

    if (!Number.isInteger(product?.unitOfmeasurementValue)) {
      errors = {
        ...errors,
        unitOfmeasurementValue: "Enter integer value",
      };
    } else {
      delete errors?.unitOfmeasurementValue;
    }

    if (!product?.productOriginalPrice) {
      errors = {
        ...errors,
        productOriginalPrice: "Enter original price",
      };
    } else {
      delete errors?.productOriginalPrice;
    }

    if (!product?.productSalePrice) {
      errors = {
        ...errors,
        productSalePrice: "Enter offer price",
      };
    } else {
      delete errors?.productSalePrice;
    }

    if (product?.productSalePrice && product?.productOriginalPrice) {
      if (
        Number(product?.productSalePrice) >
        Number(product?.productOriginalPrice)
      ) {
        errors = {
          ...errors,
          productSalePrice:
            "Offer Price must be less than or equal to Original Price",
        };
      }
    }

    if (!product?.availableQuantity) {
      errors = {
        ...errors,
        availableQuantity: "Enter qty on hand",
      };
    } else {
      delete errors?.availableQuantity;
    }

    if (!product?.alertQuantity) {
      errors = {
        ...errors,
        alertQuantity: "Enter alert qty",
      };
    } else {
      delete errors?.alertQuantity;
    }

    if (!product?.minOrderQuantity) {
      errors = {
        ...errors,
        minOrderQuantity: "Enter minimum qty",
      };
    } else {
      delete errors?.minOrderQuantity;
    }

    if (!product?.maxOrderQuantity) {
      errors = {
        ...errors,
        maxOrderQuantity: "Enter maximum qty",
      };
    } else {
      delete errors?.maxOrderQuantity;
    }

    if (
      product?.availableQuantity &&
      product?.alertQuantity &&
      product?.maxOrderQuantity
    ) {
      if (
        Number(product?.availableQuantity) <= Number(product?.alertQuantity) ||
        Number(product?.availableQuantity) < Number(product?.maxOrderQuantity)
      ) {
        errors = {
          ...errors,
          availableQuantity:
            "Qty On Hand should be greater than Alert Qty & Max Qty",
        };
      } else {
        delete errors?.availableQuantity;
      }
    }

    if (product?.maxOrderQuantity && product?.minOrderQuantity) {
      if (
        Number(product?.maxOrderQuantity) < Number(product?.minOrderQuantity)
      ) {
        errors = {
          ...errors,
          maxOrderQuantity:
            "Max Qty per order should be greater than Min Qty per order",
        };
      } else {
        delete errors?.maxOrderQuantity;
      }
    }

    // if(product?.alertQuantity && product?.minOrderQuantity){
    //   if (Number(product?.alertQuantity) < Number(product?.minOrderQuantity)) {
    //     errors = {
    //       ...errors,
    //       alertQuantity: "Alert Qty must be more than Min Qty per order",
    //     };
    //   }else{
    //     delete errors?.alertQuantity
    //   }
    // }

    if (!product?.measurementInfo?.weight) {
      errors = {
        ...errors,
        weight: "Enter product weight",
      };
    } else {
      delete errors?.weight;
    }

    if (!product?.netProductWeight || product?.netProductWeight === "") {
      errors = {
        ...errors,
        netProductWeight: "Enter net product weight",
      };
    } else {
      delete errors?.netProductWeight;
    }

    if (
      product?.netProductWeight !== "" &&
      product?.netProductWeight < product?.measurementInfo?.weight
    ) {
      errors = {
        ...errors,
        netProductWeight:
          "Net Product Weight should be more than Product Weight",
      };
    } else {
      delete errors?.netProductWeight;
    }

    if (errors) {
      allProducts[index] = {
        ...allProducts[index],
        errors: errors,
      };
      isError = true;
    } else {
      allProducts[index] = {
        ...allProducts[index],
        errors: null,
      };
    }
  });

  console.log("validateReviewProducts_Verified:", allProducts);
  console.log("validateReviewProducts_isError:", isError);

  return {
    allProducts,
    isError,
  };
};

export const validateOndcProductData = (productDetails, isRemoveONDCSpecs) => {
  let allProducts = [...productDetails];

  let isError = false;

  productDetails.forEach((product, index) => {
    if (!product.isSelected) return;

    let errors = null;

    if (product?.ondcEnabled?.toUpperCase() === "YES") {
      if (!product?.ondc?.shipmentTime || product?.ondc?.shipmentTime == "0") {
        errors = { ...errors, shipmentTime: "Enter time to ship" };
      } else {
        delete errors?.shipmentTime;
      }

      // CancelTerms
      if (product?.ondc?.cancelTerms?.cancellable?.toUpperCase() === "YES") {
        // if (product?.ondc?.cancelTerms?.cancellationFeePercentage === "") {
        //   if (!product?.ondc?.cancelTerms?.cancellationFeeAmount) {
        //     errors = {
        //       ...errors,
        //       cancellationFeeAmount: "Please enter either 'cancellation Fee Percentage' or 'cancellationFeeAmount'",
        //     };
        //   } else {
        //     delete errors?.cancellationFeeAmount;
        //   }
        // }

        // if (product?.ondc?.cancelTerms?.cancellationFeeAmount === "") {
        //   if (
        //     !product?.ondc?.cancelTerms?.cancellationFeePercentage ||
        //     product?.ondc?.cancelTerms?.cancellationFeePercentage === ""
        //   ) {
        //     errors = {
        //       ...errors,
        //       cancellationFeePercentage: "Please enter either 'cancellation Fee Percentage' or 'cancellationFeeAmount'",
        //     };
        //   } else {
        //     delete errors?.cancellationFeePercentage;
        //   }
        // }

        // if (
        //   product?.ondc?.cancelTerms?.cancellationFeeAmount &&
        //   product?.ondc?.cancelTerms?.cancellationFeePercentage
        // ) {
        //   errors = {
        //     ...errors,
        //     cancellationFeeAmount:
        //       "Please enter only one input - either 'cancellation Fee Percentage' or 'cancellationFeeAmount', not both",
        //   };
        // } else {
        //   delete errors?.cancellationFeeAmount;
        // }

        if (
          !product?.ondc?.cancelTerms?.cancellationFeePercentage ||
          product?.ondc?.cancelTerms?.cancellationFeePercentage === ""
        ) {
          errors = {
            ...errors,
            cancellationFeePercentage: "Enter cancellation fee percentage",
          };
        } else {
          delete errors?.cancellationFeePercentage;
        }

        if (product?.ondc?.cancelTerms?.cancellationFeePercentage > 0) {
          if (product?.ondc?.cancelTerms?.cancellationFeePercentage > 100) {
            errors = {
              ...errors,
              cancellationFeePercentage:
                "Cancellation fee percentage should be less than or equal to 100",
            };
          } else {
            delete errors?.cancellationFeePercentage;
          }
        }
      }

      // return terms
      //   if (product?.ondc?.returnTerms?.returnable?.toUpperCase() === "YES") {
      if (
        !product?.ondc?.returnPeriodDays ||
        product?.ondc?.returnPeriodDays == "0"
      ) {
        errors = { ...errors, returnPeriod: "Enter return period" };
      } else {
        delete errors?.returnPeriod;
      }
      //   }

      if (!isRemoveONDCSpecs) {
        // food related items
        if (product?.ondc?.foodRelatedItems?.toUpperCase() === "YES") {
          if (!product?.ondc?.shelfLife || product?.ondc?.shelfLife == "0") {
            errors = { ...errors, shelfLife: "Enter shelfLife" };
          } else {
            delete errors?.shelfLife;
          }

          if (
            !product?.ondc?.foodType ||
            product?.ondc?.foodType == "Select Value"
          ) {
            errors = { ...errors, foodType: "Select value" };
          } else {
            delete errors?.foodType;
          }

          if (product?.ondc?.fssaiNo?.length !== 14) {
            errors = { ...errors, fssaiNo: "Enter 14 digit fssai number" };
          } else {
            delete errors?.fssaiNo;
          }

          // if (!product?.ondc?.fssaiValidTill) {
          //   errors = {
          //     ...errors,
          //     fssaiValidTill: "Enter fssai valid till date",
          //   };
          // } else {
          //   delete errors?.fssaiValidTill;
          // }

          // if (product?.ondc?.fssaiValidTill) {
          //   const isFssaiDateValid = validateDate(product?.ondc?.fssaiValidTill);
          //   if (!isFssaiDateValid) {
          //     errors = {
          //       ...errors,
          //       fssaiValidTill: "Enter fssai valid till date",
          //     };
          //   } else {
          //     delete errors?.fssaiValidTill;
          //   }
        }

        // packaged food
        if (product?.ondc?.isPackaged?.toUpperCase() === "YES") {
          if (
            !product?.ondc?.statutoryReqsPackagedCommodities
              ?.manufacturer_or_packer_name
          ) {
            errors = {
              ...errors,
              manufacturer_or_packer_name: "Enter manufacturer or packer name",
            };
          } else {
            delete errors?.manufacturer_or_packer_name;
          }

          if (
            !product?.ondc?.statutoryReqsPackagedCommodities?.mfg_license_no
          ) {
            errors = {
              ...errors,
              mfg_license_no: "Enter manufacturer license number",
            };
          } else {
            delete errors?.mfg_license_no;
          }

          if (
            !product?.ondc?.statutoryReqsPackagedCommodities
              ?.manufacturer_or_packer_address
          ) {
            errors = {
              ...errors,
              manufacturer_or_packer_address: "Enter address",
            };
          } else {
            delete errors?.manufacturer_or_packer_address;
          }

          if (
            !product?.ondc?.statutoryReqsPackagedCommodities
              ?.common_or_generic_name_of_commodity
          ) {
            errors = {
              ...errors,
              common_or_generic_name_of_commodity:
                "Enter product common/generic name",
            };
          } else {
            delete errors?.common_or_generic_name_of_commodity;
          }

          if (
            !product?.ondc?.statutoryReqsPackagedCommodities
              ?.month_year_of_manufacture_packing_import
          ) {
            errors = {
              ...errors,
              month_year_of_manufacture_packing_import: "Enter import date",
            };
          } else {
            delete errors?.month_year_of_manufacture_packing_import;
          }

          const packageImportDate =
            product?.ondc?.statutoryReqsPackagedCommodities
              ?.month_year_of_manufacture_packing_import;

          if (packageImportDate) {
            const isDateValid = checkValidMonthYear(packageImportDate);
            if (!isDateValid) {
              errors = {
                ...errors,
                month_year_of_manufacture_packing_import:
                  "Enter valid import date (MM/YYYY)",
              };
            } else {
              delete errors?.month_year_of_manufacture_packing_import;
            }
          }

          if (!product?.ondc?.statutoryReqsPackagedCommodities?.expiry_date) {
            errors = { ...errors, expiry_date: "Enter expiry date" };
          } else {
            delete errors?.expiry_date;
          }

          const packageExpiryDate =
            product?.ondc?.statutoryReqsPackagedCommodities?.expiry_date;

          if (packageExpiryDate) {
            const isDateValid = checkValidMonthYear(packageExpiryDate);
            if (!isDateValid) {
              errors = {
                ...errors,
                expiry_date: "Enter valid expiry date (MM/YYYY)",
              };
            } else {
              delete errors?.expiry_date;
            }
          }

          if (
            !product?.ondc?.statutoryReqsPackagedCommodities
              ?.net_quantity_or_measure_of_commodity_in_pkg
          ) {
            errors = {
              ...errors,
              net_quantity_or_measure_of_commodity_in_pkg: "Enter net quantity",
            };
          } else {
            delete errors?.net_quantity_or_measure_of_commodity_in_pkg;
          }

          if (
            !product?.ondc?.statutoryReqsPackagedCommodities
              ?.multiple_products_name_number_or_qty
          ) {
            errors = {
              ...errors,
              multiple_products_name_number_or_qty: "Enter multiple products",
            };
          } else {
            delete errors?.multiple_products_name_number_or_qty;
          }
        }

        // pre packaged food
        if (product?.ondc?.isPrePackaged?.toUpperCase() === "YES") {
          if (
            product?.ondc?.statutoryReqsPrepackagedFood
              ?.brand_owner_FSSAI_license_no?.length !== 14
          ) {
            errors = {
              ...errors,
              brand_owner_FSSAI_license_no: "Enter 14 digit fssai number",
            };
          } else {
            delete errors?.brand_owner_FSSAI_license_no;
          }

          if (
            product?.ondc?.statutoryReqsPrepackagedFood?.other_FSSAI_license_no
              ?.length !== 14
          ) {
            errors = {
              ...errors,
              other_FSSAI_license_no: "Enter 14 digit fssai number",
            };
          } else {
            delete errors?.other_FSSAI_license_no;
          }

          if (
            product?.ondc?.statutoryReqsPrepackagedFood
              ?.importer_FSSAI_license_no?.length !== 14
          ) {
            errors = {
              ...errors,
              importer_FSSAI_license_no: "Enter 14 digit fssai number",
            };
          } else {
            delete errors?.importer_FSSAI_license_no;
          }

          if (!product?.ondc?.statutoryReqsPrepackagedFood?.ingredients_info) {
            errors = {
              ...errors,
              ingredients_info: "Enter ingredients info",
            };
          } else {
            delete errors?.ingredients_info;
          }

          if (!product?.ondc?.statutoryReqsPrepackagedFood?.nutritional_info) {
            errors = {
              ...errors,
              nutritional_info: "Enter nutritional info",
            };
          } else {
            delete errors?.nutritional_info;
          }

          if (!product?.ondc?.statutoryReqsPrepackagedFood?.additives_info) {
            errors = { ...errors, additives_info: "Enter additives info" };
          } else {
            delete errors?.additives_info;
          }
        }
      }
    }

    if (errors) {
      allProducts[index] = {
        ...allProducts[index],
        ondcErrors: errors,
      };
      isError = true;
    } else {
      allProducts[index] = {
        ...allProducts[index],
        ondcErrors: null,
      };
    }
  });

  console.log("validateOndcParameters_Verified:", allProducts);
  console.log("validateOndcParameters_isError:", isError);

  return {
    allProducts,
    isError,
  };
};

// Validate ONDC Spec Info

export const validateOndcProductSpecs = (productDetails) => {
  // console.log("validateOndcProductSpecs__productDetails:", productDetails);

  const productList = [...productDetails];
  const updatedProductList = [];

  let isError = false;

  productList.forEach((productInfo) => {
    if (productInfo?.isSelected) {
      const productSpecs = [...productInfo?.specList];

      let specificationError = false;

      const updatedSpecsArray = productSpecs?.map((item) => {
        if (item?.type === "default") {
          return item;
        }
        if (
          !item?.inputValue &&
          item?.required?.toLowerCase() === "m" &&
          item?.type !== "radioButton"
        ) {
          specificationError = true;
          return { ...item, error: "Please enter, it's a mandatory value" };
        }
        if (
          item?.required?.toLowerCase() === "m" &&
          item?.type == "radioButton" &&
          item?.radioOptions?.every((obj) => obj.value === "")
        ) {
          specificationError = true;
          return { ...item, error: "Please select, it's a mandatory value" };
        }

        if (!item?.inputValue || item?.validation?.length === 0) {
          return { ...item, error: "" };
        }

        // const validationArray = JSON.parse(item?.validation);

        // console.log("validationArray", validationArray);

        // if (validationArray?.length === 0) {
        //   return { ...item, error: "" };
        // }

        for (let validation of item?.validation) {
          if (validation.substring(0, 5) !== "regex") {
            if (validation === item?.inputValue) {
              return { ...item, error: "" };
            }
          } else {
            const regexString = validation.match(/regex\((.+)\)/)[1];
            const regexPattern = regexString
              .replace(/d/g, "\\d")
              .replace(/\./g, "\\.");
            const regex = new RegExp(regexPattern);
            console.log(
              "regex inputValue",
              validation,
              regexPattern,
              item?.inputValue
            );
            if (regex.test(item?.inputValue)) {
              console.log("validation success");
              return { ...item, error: "" };
            }
          }
        }

        specificationError = true;
        return { ...item, error: "Please enter valid format" };
      });

      updatedProductList.push({
        ...productInfo,
        specList: updatedSpecsArray,
        specificationError,
      });

      if (specificationError && !isError) {
        isError = true;
      }
    } else {
      // Clearing errors
      const productSpecs = [...productInfo?.specList];
      const updatedSpecsArray = productSpecs?.map((item) => ({
        ...item,
        error: "",
      }));

      updatedProductList.push({
        ...productInfo,
        specList: updatedSpecsArray,
        specificationError: false,
      });
    }
  });

  console.log("validateOndcProductSpecs__updatedProductList:", {
    updatedProductList,
    isError,
  });
  return { updatedProductList, isError };
};

// export const prepareOndcSpecsPostData = (productDetails, countryList) => {
//   console.log("SpecListData__postData__productDetails:", productDetails);

//   const productList = [...productDetails];
//   const updatedSpecPostData = [];

//   productList.forEach((productInfo) => {
//     if (productInfo?.isSelected) {
//       const productSpecs = [...productInfo?.specList];
//       const dbSpecificationObj = parseDbObjectInsideArray(productSpecs);

//       const ondcInfo = productInfo?.ondc;
//       const cancelTermsInfo = ondcInfo?.cancelTerms;
//       const returnTermsInfo = ondcInfo?.returnTerms;

//       const shipTimeUnit = ondcInfo?.timeToShipUnit?.label || "Minutes";
//       const timeToShipUnit =
//         shipTimeUnit === "Minutes" ? "M" : shipTimeUnit === "Hours" ? "H" : "D";

//       const data = {
//         id: productInfo?.id,

//         measurementInfo: {
//           length: productInfo?.measurementInfo?.length,
//           height: productInfo?.measurementInfo?.height,
//           width: productInfo?.measurementInfo?.width,
//           weight: Number(productInfo?.netProductWeight) || 0,
//         },
//         netProductWeight: productInfo?.measurementInfo?.weight,

//         origin: getSelectedCountryCodeByName(productInfo?.origin, countryList),
//         ondcEnabled: productInfo?.ondcEnabled?.toLowerCase() || "no",
//         ondc: {
//           ...dbSpecificationObj?.ondc,
//           productCode: {
//             key: productInfo?.productCodeKey || "OTHERS",
//             value: productInfo?.productCodeValue || "",
//           },
//           fragile: ondcInfo?.fragile || false,
//           available_on_cod: ondcInfo?.available_on_cod || false,

//           timeToShip: formatTimeToShip(timeToShipUnit, ondcInfo?.shipmentTime),

//           cancelTerms: {
//             cancellable: cancelTermsInfo?.cancellable?.toLowerCase() || "no",
//             // cancellationFeeAmount: cancelTermsInfo?.cancellationFeeAmount || "",
//             cancellationFeeAmount: null,
//             cancellationFeePercentage: Number(
//               cancelTermsInfo?.cancellationFeePercentage
//             ),
//             refundEligible: cancelTermsInfo?.refundEligible || "no",
//           },

//           returnTerms: {
//             returnable: returnTermsInfo?.returnable?.toLowerCase() || "no",
//             returnPeriod: `P${ondcInfo?.returnPeriodDays || 0}D`,
//             seller_pickup_return:
//               returnTermsInfo?.seller_pickup_return?.toLowerCase() || "no",
//           },
//         },
//       };

//       updatedSpecPostData.push(data);
//     }
//   });

//   console.log(
//     "SpecListData__postData__updatedSpecPostData:",
//     updatedSpecPostData
//   );

//   return updatedSpecPostData;
// };

export const prepareOndcSpecsPostData = (
  productDetails,
  countryList,
  isSaveExit
) => {
  console.log("SpecListData__postData__productDetails:", productDetails);

  const productList = [...productDetails];
  const updatedSpecPostData = [];

  productList.forEach((productInfo) => {
    if (productInfo?.isSelected) {
      const productSpecs = [...productInfo?.specList];
      const dbSpecificationObj = parseDbObjectInsideArray(productSpecs);

      const ondcInfo = productInfo?.ondc;
      const cancelTermsInfo = ondcInfo?.cancelTerms;
      const returnTermsInfo = ondcInfo?.returnTerms;

      const shipTimeUnit = ondcInfo?.timeToShipUnit?.label || "Minutes";
      const timeToShipUnit =
        shipTimeUnit === "Minutes" ? "M" : shipTimeUnit === "Hours" ? "H" : "D";

      const nonOndcInfo = {
        measurementInfo: {
          length: productInfo?.measurementInfo?.length,
          height: productInfo?.measurementInfo?.height,
          width: productInfo?.measurementInfo?.width,
          weight: Number(productInfo?.netProductWeight) || 0,
        },
        netProductWeight: productInfo?.measurementInfo?.weight,

        origin: getSelectedCountryCodeByName(productInfo?.origin, countryList),
      };

      const data = {
        id: productInfo?.id,

        ondcEnabled: productInfo?.ondcEnabled?.toLowerCase() || "no",
        ondc: {
          ...dbSpecificationObj?.ondc,
          productCode: {
            key: productInfo?.productCodeKey || "OTHERS",
            value: productInfo?.productCodeValue || "",
          },
          fragile: ondcInfo?.fragile || false,
          available_on_cod: ondcInfo?.available_on_cod || false,

          timeToShip: formatTimeToShip(timeToShipUnit, ondcInfo?.shipmentTime),

          cancelTerms: {
            cancellable: cancelTermsInfo?.cancellable?.toLowerCase() || "no",
            // cancellationFeeAmount: cancelTermsInfo?.cancellationFeeAmount || "",
            cancellationFeeAmount: null,
            cancellationFeePercentage: Number(
              cancelTermsInfo?.cancellationFeePercentage
            ),
            refundEligible: cancelTermsInfo?.refundEligible || "no",
          },

          returnTerms: {
            returnable: returnTermsInfo?.returnable?.toLowerCase() || "no",
            returnPeriod: `P${ondcInfo?.returnPeriodDays || 0}D`,
            seller_pickup_return:
              returnTermsInfo?.seller_pickup_return?.toLowerCase() || "no",
          },
        },
      };

      if (isSaveExit) {
        updatedSpecPostData.push(data);
      } else {
        updatedSpecPostData.push({ ...nonOndcInfo, ...data });
      }
    }
  });

  console.log(
    "SpecListData__postData__updatedSpecPostData:",
    updatedSpecPostData
  );

  return updatedSpecPostData;
};

export const getTimeToShipment = (time) => {
  const timeString = time || "";
  let timeData = 0;
  if (timeString.includes("Minutes") || timeString.includes("M")) {
    if (timeString.includes("Minutes")) {
      timeData = timeString.replace("Minutes", "").slice(2);
      return { timeData, timeUnit: "Minutes" };
    }

    if (timeString.includes("M")) {
      timeData = timeString.replace("M", "").slice(2);
      return { timeData, timeUnit: "Minutes" };
    }
  }

  if (timeString.includes("Hours") || timeString.includes("H")) {
    if (timeString.includes("Hours")) {
      timeData = timeString.replace("Hours", "").slice(2);
      return { timeData, timeUnit: "Hours" };
    }

    if (timeString.includes("H")) {
      timeData = timeString.replace("H", "").slice(2);
      return { timeData, timeUnit: "Hours" };
    }
  }

  if (timeString.includes("Days") || timeString.includes("D")) {
    if (timeString.includes("Days")) {
      timeData = timeString.replace("Days", "").slice(2);
      return { timeData, timeUnit: "Days" };
    }

    if (timeString.includes("D")) {
      if (timeString.includes("PT")) {
        timeData = timeString.replace("D", "").slice(2);
      } else {
        timeData = timeString.replace("D", "").slice(1);
      }

      return { timeData, timeUnit: "Days" };
    }
  }

  return { timeData: 0, timeUnit: "Minutes" };
};

export const prepareProductONDCInfo = (productInfo) => {
  const ondcInfo = productInfo?.ondc;
  const cancelTermsInfo = ondcInfo?.cancelTerms;
  const returnTermsInfo = ondcInfo?.returnTerms;
  const packagedComInfo = ondcInfo?.statutoryReqsPackagedCommodities;
  const prePackagedComInfo = ondcInfo?.statutoryReqsPrepackagedFood;

  const shipTimeUnit = ondcInfo?.timeToShipUnit?.label || "Minutes";
  const timeToShipUnit =
    shipTimeUnit === "Minutes" ? "M" : shipTimeUnit === "Hours" ? "H" : "D";

  const ondcData = {
    attributeList: ondcInfo?.attributeList || [],
    available_on_cod: ondcInfo?.available_on_cod || false,
    discount: 0,
    fragile: ondcInfo?.fragile || false,
    shelfLife: ondcInfo?.shelfLife || 0,
    // timeToShip: `PT${ondcInfo?.shipmentTime || 0}${timeToShipUnit}`,
    timeToShip: formatTimeToShip(timeToShipUnit, ondcInfo?.shipmentTime),
    cancelTerms: {
      cancellable: cancelTermsInfo?.cancellable?.toLowerCase() || "no",
      // cancellationFeeAmount: cancelTermsInfo?.cancellationFeeAmount || "",
      cancellationFeeAmount: null,
      cancellationFeePercentage: Number(
        cancelTermsInfo?.cancellationFeePercentage
      ),
      refundEligible: cancelTermsInfo?.refundEligible || "no",
    },
    veg_nonveg: {
      veg: ondcInfo?.foodType === "Veg" ? "yes" : "no",
      nonVeg: ondcInfo?.foodType === "Non Veg" ? "yes" : "no",
      egg: ondcInfo?.foodType === "Egg" ? "yes" : "no",
    },
    fssai: ondcInfo?.fssaiNo
      ? [
          {
            number: ondcInfo?.fssaiNo || "",
            // validTill: ondcInfo?.fssaiValidTill || "",
            validTill: null,
          },
        ]
      : [],
    // fssai: [],
    productCode: {
      key: productInfo?.productCodeKey || "OTHERS",
      value: productInfo?.productCodeValue || "",
    },
    returnTerms: {
      returnable: returnTermsInfo?.returnable?.toLowerCase() || "no",
      returnPeriod: `P${ondcInfo?.returnPeriodDays || 0}D`,
      seller_pickup_return:
        returnTermsInfo?.seller_pickup_return?.toLowerCase() || "no",
    },
    statutoryReqsPackagedCommodities: {
      manufacturer_or_packer_name:
        packagedComInfo?.manufacturer_or_packer_name || "",
      manufacturer_or_packer_address:
        packagedComInfo?.manufacturer_or_packer_address || "",
      common_or_generic_name_of_commodity:
        packagedComInfo?.common_or_generic_name_of_commodity || "",
      mfg_license_no: packagedComInfo?.mfg_license_no || "",
      expiry_date: packagedComInfo?.expiry_date || "N/A",
      month_year_of_manufacture_packing_import:
        packagedComInfo?.month_year_of_manufacture_packing_import || "N/A",
      net_quantity_or_measure_of_commodity_in_pkg:
        packagedComInfo?.net_quantity_or_measure_of_commodity_in_pkg || "",
      multiple_products_name_number_or_qty:
        packagedComInfo?.multiple_products_name_number_or_qty || "",
    },
    statutoryReqsPrepackagedFood: {
      ingredients_info: prePackagedComInfo?.ingredients_info || "",
      nutritional_info: prePackagedComInfo?.nutritional_info || "",
      additives_info: prePackagedComInfo?.additives_info || "",
      brand_owner_FSSAI_license_no:
        prePackagedComInfo?.brand_owner_FSSAI_license_no || "",
      other_FSSAI_license_no: prePackagedComInfo?.other_FSSAI_license_no || "",
      importer_FSSAI_license_no:
        prePackagedComInfo?.importer_FSSAI_license_no || "",
    },
  };

  return { ...ondcData };
};

export const prepareProductPhotoData = (photos) => {
  const postData = [];

  photos.forEach((photoUrl, index) => {
    if (photoUrl) {
      postData.push(photoUrl);
    }
  });

  return postData;
};

// Returns image id from the drive link
export const getImageIdFromDriveLink = (driveLink) => {
  try {
    const startIndex = driveLink.indexOf("/d/") + 3; // Add 3 to skip '/d/'
    const endIndex = driveLink.indexOf("/view");
    if (startIndex !== -1 && endIndex !== -1) {
      return driveLink.substring(startIndex, endIndex);
    } else {
      throw new Error("Invalid Google Drive link format");
    }
  } catch (error) {
    console.error(error.message);
    return null;
  }
};
