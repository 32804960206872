import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { whiteColor } from "../../configs/styles/muiThemes";

function ImageMagnifierMobile({ imgUrl, width, height }) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [insideImage, setInsideImage] = useState(false);

  const imgRef = useRef(null);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const handleTouchHover = (e) => {
    const touch = e.touches[0];
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();

    const touchX = touch.clientX - left;
    const touchY = touch.clientY - top;

    if (touchX < 0 || touchX > width || touchY < 0 || touchY > height) {
      setInsideImage(false);
      setShowMagnifier(false);
      return;
    }

    const x = (touchX / width) * 100;
    const y = (touchY / height) * 100;
    setPosition({ x, y });
    setCursorPosition({ x: touchX, y: touchY });
    setInsideImage(true);
    setShowMagnifier(true);
  };

  useEffect(() => {
    if (imgRef.current) {
      const { naturalWidth, naturalHeight } = imgRef.current;
      const aspectRatio = naturalWidth / naturalHeight;

      const height = window.innerHeight * 0.4;
      const width = height * aspectRatio;

      setImageDimensions({ width, height });
      setShowMagnifier(false);
    }
  }, [imgUrl]);

  return (
    <Box
      onTouchStart={(e) => handleTouchHover(e)}
      onTouchMove={(e) => handleTouchHover(e)}
      onTouchEnd={() => setShowMagnifier(insideImage)}
      position="relative"
      width={`${Math.min(imageDimensions.width, width)}px`}
      height={`${imageDimensions.height}px`}
    >
      <Box
        component="img"
        src={imgUrl}
        ref={imgRef}
        backgroundColor={whiteColor}
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          objectFit: "contain",
        }}
      />

      {showMagnifier && (
        <Box
          sx={{
            position: "absolute",
            left: `${cursorPosition.x - 100}px`,
            top: `${cursorPosition.y - 50}px`,
            pointerEvents: "none",
            width: "200px",
            height: "200px",
            border: `2px solid ${whiteColor}`,
            backgroundImage: `url(${imgUrl})`,
            backgroundPosition: `${position.x}% ${position.y}%`,
            backgroundSize: "200%",
          }}
        />
      )}
    </Box>
  );
}

export default ImageMagnifierMobile;
