import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { bgColor, blackColor, greyShade13 } from "../../configs/styles/muiThemes";
// import Footer from "../components/Footer";
// import Header from "../components/Header";
import ParagraphWithHeader from "./ParagraphWithHeader";

function PrivacyPolicy() {
  return (
    <Box>
      {/* <Header /> */}
      <Box sx={{ backgroundColor: bgColor, py: 2 }}>
        <Container sx={{ maxWidth: 1350 }}>
          <Typography
            fontSize={{ md: 24, xs: 19 }}
            fontWeight={600}
            lineHeight={1.5}
            color={blackColor}
            pb={{ md: 1.5, xs: 0.7 }}
          >
            Privacy Policy
          </Typography>
          <Typography
            color={greyShade13}
            fontSize={{ md: 14, xs: 13 }}
            fontWeight={400}
            pb={{ md: 2, xs: 1 }}
            lineHeight="25px"
            textAlign="justify"
            width={"100%"}
          >
            This Privacy Policy also explains the Users’ rights regarding their
            Personal Information.
          </Typography>
          <ParagraphWithHeader
            title="Personal Information"
            paragraph={[
              "This information through which users can be directly or indirectly identified, such as name, e-mail address, information made available on social media, telephone numbers, passwords, financial information and products purchased, etc. (provided the Company can connect them to the Users’ email address or account).",
              "By using or accessing the Application the Users agree to the terms and conditions of this Privacy Policy. The Users also expressly consent to the Company’s use and disclosure of their Personal Information in any manner as described in this Privacy Policy.",
            ]}
          />
          <Box pb={1}>
            <Typography
              color={blackColor}
              variant="h5"
              fontSize={{ md: 18, xs: 15 }}
              fontWeight={600}
              component="h4"
              pb={1.6}
            >
              Collection of Personal Information
            </Typography>
            <Typography
              color={greyShade13}
              fontSize={{ md: 14, xs: 13 }}
              fontWeight={400}
              pb={2}
              lineHeight="25px"
              textAlign="justify"
            >
              The Company receives Personal Information from Users in the
              following cases:
            </Typography>
            <Box pb={2}>
              <Typography
                color={blackColor}
                fontSize={{ md: 14, xs: 13 }}
                fontWeight={600}
                pb={2}
                lineHeight="25px"
                textAlign="justify"
                display="inline"
              >
                1. Registering an Account:{" "}
                <Typography
                  color={greyShade13}
                  fontSize={{ md: 14, xs: 13 }}
                  fontWeight={400}
                  pb={2}
                  lineHeight="25px"
                  textAlign="justify"
                  display="inline"
                >
                  The Company stores the information that Users provide to the
                  Company when Users create an account on the Application
                  (including email address, name, password, date of birth,
                  address and additional information Users may voluntarily
                  provide to the Company, such as contact list, location, social
                  account details).
                </Typography>
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography
                color={blackColor}
                fontSize={{ md: 14, xs: 13 }}
                fontWeight={600}
                pb={2}
                lineHeight="25px"
                textAlign="justify"
                display="inline"
              >
                2. Transactions:{" "}
                <Typography
                  color={greyShade13}
                  fontSize={{ md: 14, xs: 13 }}
                  fontWeight={400}
                  pb={2}
                  lineHeight="25px"
                  textAlign="justify"
                  display="inline"
                >
                  The Company collect details of purchases on the Application
                  (date, price and subscription / products purchased) as well as
                  when Users return or exchange a product and any communication
                  in connection with this. The Company also collects User's
                  email address, name and phone number for verification or
                  confirmation of any orders or communications placed or done on
                  the Application.
                </Typography>
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography
                color={blackColor}
                fontSize={{ md: 14, xs: 13 }}
                fontWeight={600}
                pb={2}
                lineHeight="25px"
                textAlign="justify"
                display="inline"
              >
                3. Use of the Application:{" "}
                <Typography
                  color={greyShade13}
                  fontSize={{ md: 14, xs: 13 }}
                  fontWeight={400}
                  pb={2}
                  lineHeight="25px"
                  textAlign="justify"
                  display="inline"
                >
                  The Company stores details of any pictures/materials shared by
                  Users for publication on social media and details pertaining
                  to any competition or promotion sponsored by the Company.
                  Additionally, the Company collects and stores details
                  pertaining to User visits to the websites (such as pages
                  visited) and User interactions with any e-mails that the
                  Company sends to such Users. For more information, please see
                  the Company’s policy on cookies below.
                </Typography>
              </Typography>
            </Box>

            <Box pb={2}>
              <Typography
                color={blackColor}
                fontSize={{ md: 14, xs: 13 }}
                fontWeight={600}
                pb={2}
                lineHeight="25px"
                textAlign="justify"
                display="inline"
              >
                4. Cookies:{" "}
                <Typography
                  color={greyShade13}
                  fontSize={{ md: 14, xs: 13 }}
                  fontWeight={400}
                  pb={2}
                  lineHeight="25px"
                  textAlign="justify"
                  display="inline"
                >
                  The Company uses cookies and other technology like pixel tags
                  for purposes described below. Cookies are small files, which
                  are sent from the Application and then stored on your device.
                  Pixel tags, also called web beacons are clear image (i.e.
                  non-visible) files that may be placed on an Application which
                  request information from User devices (collectively,
                  “Cookies”)
                </Typography>
              </Typography>
            </Box>

            <Typography
              color={greyShade13}
              fontSize={{ md: 14, xs: 13 }}
              fontWeight={400}
              pb={2}
              lineHeight="25px"
              textAlign="justify"
            >
              Cookies may be set in User devices when Users access the
              Application, or may be set in when Users visit third party
              websites or applications.
            </Typography>
            <Typography
              color={greyShade13}
              fontSize={{ md: 14, xs: 13 }}
              fontWeight={400}
              pb={2}
              lineHeight="25px"
              textAlign="justify"
            >
              Users are free to suitably modify the Application settings if
              Users do not wish to accept cookies, but please note that changing
              such settings in the Application may affect User experience
              therein.
            </Typography>
          </Box>
          <Box pb={1}>
            <Typography
              color={blackColor}
              variant="h5"
              fontSize={{ md: 18, xs: 15 }}
              fontWeight={600}
              component="h4"
              pb={1.6}
            >
              Types of Cookies
            </Typography>
            <Typography
              color={greyShade13}
              fontSize={{ md: 14, xs: 13 }}
              fontWeight={400}
              pb={2}
              lineHeight="25px"
              textAlign="justify"
            >
              The Company use Cookies for different purposes, which can be
              categorized as follows:
            </Typography>
            <Box pb={2}>
              <Typography
                color={blackColor}
                fontSize={{ md: 14, xs: 13 }}
                fontWeight={600}
                pb={2}
                lineHeight="25px"
                textAlign="justify"
                display="inline"
              >
                1. Functional Cookies:{" "}
                <Typography
                  color={greyShade13}
                  fontSize={{ md: 14, xs: 13 }}
                  fontWeight={400}
                  pb={2}
                  lineHeight="25px"
                  textAlign="justify"
                  display="inline"
                >
                  These Cookies are required for the Application to function.
                  They include, for example, Cookies that enable Users to log in
                  or store products in a shopping cart. They may also enable us
                  to recognise Users when Users return to the Application and
                  store information about User preferences, so they allow the
                  Company to customise the Application according to individual
                  preferences (such as the language chosen by Users).
                </Typography>
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography
                color={blackColor}
                fontSize={{ md: 14, xs: 13 }}
                fontWeight={600}
                pb={2}
                lineHeight="25px"
                textAlign="justify"
                display="inline"
              >
                2. Analytical Cookies-react-text:{" "}
                <Typography
                  color={greyShade13}
                  fontSize={{ md: 14, xs: 13 }}
                  fontWeight={400}
                  pb={2}
                  lineHeight="25px"
                  textAlign="justify"
                  display="inline"
                >
                  These Cookies analyse how Users navigate around the
                  Application (pages visited, links used etc.) or use e-mails
                  the Company sends to the Users. They enable the Company to
                  better understand the interests of Users and to improve the
                  Application.
                </Typography>
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography
                color={blackColor}
                fontSize={{ md: 14, xs: 13 }}
                fontWeight={600}
                pb={2}
                lineHeight="25px"
                textAlign="justify"
                display="inline"
              >
                3. Third party advertising:{" "}
                <Typography
                  color={greyShade13}
                  fontSize={{ md: 14, xs: 13 }}
                  fontWeight={400}
                  pb={2}
                  lineHeight="25px"
                  textAlign="justify"
                  display="inline"
                >
                  The Company uses third-party service providers to display
                  advertisements (banners or links) on the Company’s behalf
                  across the internet. These advertising service providers may
                  collect information about visits to the Application and
                  interactions with the Company’s products, as well as User
                  visits to other websites or Applications. Such information
                  does not include User names, addresses, e-mail addresses but
                  may contain Application related information.
                </Typography>
              </Typography>
            </Box>
          </Box>
          <ParagraphWithHeader
            title="Use of Personal Information"
            paragraph={[
              "User Personal Information is used by the Company in the following manner:",
            ]}
          />

          <ParagraphWithHeader
            title="Transactions"
            paragraph={[
              "User Personal Information is required to process purchases or send order confirmations. Additionally, Personal Information is used for handling any returns and all communication pertaining to the orders;",
              "The Company uses Personal Information to administer accounts such as to enable Users to access orders;",
              "The Company uses Personal Information to show Users other products that may interest Users;",
              "When Users enter a sweepstake/contest, the Company processes User data to enable participation; and",
              "The Company uses Personal Information to ensure successful delivery of orders, any change in status of an order, etc.",
            ]}
          />
          <ParagraphWithHeader
            title="Analytics"
            paragraph={[
              "The Company uses and collects Personal Information to help learn more about the Users’ shopping preferences (to enable the personalization of the Applications or any offers according to User interest and preference), to help address problems with and improve the Application and products therein, to analyse trends and statistics, and to present content with the most clarity and usability.",
            ]}
          />
          <ParagraphWithHeader
            title="Direct marketing"
            paragraph={[
              "The Company will only contact Users for direct marketing purposes per emails or text message when Users have given the Company prior consent. Users may opt-out from receiving further marketing messages at any time by sending an e-mail to hello@costbo.com",
            ]}
          />
          <ParagraphWithHeader
            title="Securing the Company’s assets"
            paragraph={[
              "The Company may also use Personal Information to protect the security or integrity of the Application and the Company’s business.",
            ]}
          />
          <ParagraphWithHeader
            title="Legal Proceedings"
            paragraph={[
              "In the event the Company are required to respond to any legal proceedings, including any legal notices or court orders, User Personal Information may be disclosed pursuant to such legal proceedings.",
            ]}
          />
          <ParagraphWithHeader
            title="Disclosure of Personal Information"
            paragraph={[
              "The Company may involve other companies in providing services to Users. Third party service providers perform various different services, such as logistics, payments etc. In these instances, the Company shares User Personal Information with such third party service providers. Where the Company engages service providers that use Personal Information, the Company will ensure that Personal Information is processed for specific purposes and according to the contractual terms entered into between the Company and the Users.",
            ]}
          />
          <ParagraphWithHeader
            title="Retention Period"
            paragraph={[
              "The Company retains Personal Information for as long as necessary to carry out its contractual obligations, or for as long as the Company is required to retain such information by law. Post the completion of the aforementioned period, the Company shall delete User Personal Information and will not be available for public view.",
            ]}
          />
          <ParagraphWithHeader
            title="Security"
            paragraph={[
              "unauthorised access or unauthorised alteration, disclosure or destruction. The Company maintains reasonable security practices and procedures with respect to the Personal Information, and is in compliance with IS/ISO/IEC/27001 (Information Technology – Security Techniques Information Security Management Systems – Requirements). The Company is not responsible for any breach of security or for any actions of any third parties that receive your Personal Information. costBo is a secure Business Social platform. At costBo, We use the highest industry standard Encryption. Which assures, that your data is safe with costBo. We also keep your data protected & encrypted during transmission & at datacenter. costBo does not sell, share, rent, re-market the Users data to anyone.",
              "Promise to you is that costBo does not sell, share, rent, re-market the Users data to anyone.",
            ]}
          />
          <ParagraphWithHeader
            title="Links to Other Materials"
            paragraph={[
              "If Users are offered a link to connect to a third party website or application, it is for the convenience of the Users. Sites or Applications linked to and from the Application are not necessarily under the control of the Company, and the Company has no responsibility for the content or privacy practices of any such linked site, application or service. The Company recommends that the Users read the privacy policies of those third parties.",
            ]}
          />
          <ParagraphWithHeader
            title="Access to User Personal Information"
            paragraph={[
              "This Application gives some Users the ability to view and change the Personal Information provided within the Application. Other Users may access information the Company hold about Users, request a correction where data is incorrect or a deletion of the data, unless the Company has to keep the data for legal reasons. For any enquiries please contact us at hello@costbo.com",
            ]}
          />
          <ParagraphWithHeader
            title="Changes to this Privacy Policy"
            paragraph={[
              "This Privacy Policy was posted on 15, September, 2018. Any material changes the Company may make to the Privacy Policy in the future will be posted on this page and, where appropriate, notified to Users by e-mail. Your continued use of the Platform after such changes are made constitutes your consent to the changes. Users are encouraged to check the Privacy Policy regularly for the latest updates to the Company’s Privacy Policy. (Similar can be put for T&C as well….users are encouraged to read latest updates for T&C)",
            ]}
          />
          <ParagraphWithHeader
            title="Contact Us"
            paragraph={[
              "Information to the grievance officer: Anantha – hello@costbo.com.",
            ]}
          />
        </Container>
      </Box>
      {/* <Footer /> */}
    </Box>
  );
}

export default PrivacyPolicy;
