import { Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ErrorToolTipIcon from "./ErrorToolTipIcon";
import {
  blackColor,
  borderTextbox,
  whiteColor,
} from "../../configs/styles/muiThemes";

const TableCellDateInputBox = ({
  value,
  onValueChange,
  placeholder,
  rounded,
  errorMsg,
  disabled,
  minWidth,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleBlur = () => {
    onValueChange(inputValue);
  };

  useEffect(() => {
    setInputValue(value);

    return () => {};
  }, [value]);

  return (
    <Box position="relative" sx={{ minWidth: minWidth || "120px" }}>
      {errorMsg && (
        <Box sx={{ position: "absolute", right: -14, top: -12, zIndex: 1 }}>
          <ErrorToolTipIcon errorMsg={errorMsg} />
        </Box>
      )}

      <Box
        sx={{
          // borderBottom: borderTextbox,
          height: { xs: "auto", md: "auto" },
          borderRadius: rounded ? "6px" : "0px",
          backgroundColor: whiteColor,
        }}
      >
        <TextField
          variant="standard"
          type="date"
          InputProps={{
            disableUnderline: true,
          }}
          value={inputValue}
          onChange={({ target }) => setInputValue(target.value)}
          onBlur={handleBlur}
          sx={styles.inputBox(whiteColor)}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
};

export default TableCellDateInputBox;

const styles = {
  inputBox: (color) => ({
    borderBottom: borderTextbox,
    borderRadius: "4px",
    fontSize: "13px",
    color: "#777",
    background: color,
    padding: { md: "3px 12px", xs: "3px 10px" },
    width: "100%",
    "& .MuiInputBase-root.Mui-disabled": {
      color: blackColor + " !important",
    },
  }),
};
