import {
  Box,
  Button,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackColor,
  blackShade159,
  blackShade21,
  blackShade22,
  blueColor02,
  blueColor04,
  blueColor1,
  greyColor24,
  greyColor28,
  greyColor40,
  greyColor6,
  greyColor7,
  greyColor9,
  greyShade07,
  logoImgborder1,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import InputPreview from "../../../components/Input/InputPreview";
import StyledReadOnlySwitch from "../../../components/Switch/StyledReadOnlySwitch";
import OrderMobileTab from "../../../components/MobileTabs/OrderMobileTab";
import {
  ECOMMERCE_VIDEO_DATA,
  E_COMMERCE_STEP_LIST,
  INVENTORY_STEP_LIST,
} from "../../../utils/data";
import { useNavigate } from "react-router-dom";
import { scrollBox, stickyHeader } from "../../../utils/styles";
import HowToLinkCard from "../../../components/Cards/HowToLinkCard";
import VideoModal from "../../../components/VideoModal";

function WarehouseDetailContent({ commerceDetails, activeBusinessInfo }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [businessTimings, setBusinessTimings] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const navigate = useNavigate();

  const whatsAppNumber = "+918088422615";
  const emailAddress = "support@costbo.com";
  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        // `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Warehouse+Details+Update+Request.+`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };
  const prepareTimingData = (timing) => {
    const dayOfWeek = [1, 2, 3, 4, 5, 6, 7];

    const timingInfoArr = dayOfWeek.map((day) => {
      const dayTimingInfo = timing?.filter((item) => item?.dayOfWeek === day);

      if (dayTimingInfo.length > 0) {
        return {
          ...dayTimingInfo?.[0],
          isClosed: false,
        };
      } else {
        return {
          dayOfWeek: day,
          from: "",
          to: "",
          isClosed: true,
        };
      }
    });

    console.log("FORMATTED_TIMING:", timingInfoArr);
    setBusinessTimings(timingInfoArr);
  };

  //Get Day of Week by number
  const getDayOfWeekByNum = (day) => {
    switch (day) {
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      case 7:
        return "Sunday";

      default:
        return "";
    }
  };

  const navigateToTab = (newValue) => {
    if (newValue === 0) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/warehouseDetail`);
    }
    if (newValue === 1) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/deliveryMethods`);
    }
    if (newValue == 2) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/discountDetails`);
    }
    if (newValue == 3) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/otherDetails`);
    }
    if (newValue == 4) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/managers`);
    }
    if (newValue == 5) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/bankInfo`);
    }
  };

  const handleChange = (event, newValue) => {
    navigateToTab(newValue);
  };

  const handleEditDetails = () => {
    navigate(`/editCommerceInfo`);
  };

  useEffect(() => {
    if (activeBusinessInfo?.businessHours) {
      prepareTimingData(activeBusinessInfo?.businessHours || []);
    }

    return () => {};
  }, [activeBusinessInfo]);

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  return (
    <Box flex={1}>
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          handleClose={handleCloseVideoModal}
          data={ECOMMERCE_VIDEO_DATA}
        />
      )}

      <Box sx={stickyHeader} pb={1}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={{ xs: "row", md: "row" }}
          mb="10px"
        >
          <Typography
            variant="h2"
            fontSize={{ md: 17, xs: 17 }}
            fontWeight={600}
            color={greyColor24}
            lineHeight="28px"
          >
            E-Commerce Controls
          </Typography>
          <HowToLinkCard
            link="E-Commerce Controls"
            handleClick={() => {
              setOpenVideoModal(true);
              // navigate("/howToVideoGuides", {
              //   state: { status: "eCommerceControls" },
              // });
            }}
            justifyContent="flex-start"
          />
        </Box>

        <Box
          sx={{
            ...scrollBox,
            display: { xs: "block", md: "block" },
            borderBottom: 1,
            borderColor: "divider",
            mb: { xs: "10px", md: 1 },
          }}
        >
          <Tabs
            variant="scrollable"
            value={tabValue}
            onChange={(event, newValue) => handleChange(event, newValue)}
            aria-label="basic tabs example"
            textColor={blackColor}
            TabIndicatorProps={{
              style: {
                height: "3px",
                backgroundColor: matches ? blueColor04 : mobilePrimary,
              },
            }}
            sx={{
              minHeight: "35px !important",
              "& .MuiButtonBase-root": {
                minWidth: "auto !important",
              },
            }}
          >
            {E_COMMERCE_STEP_LIST?.map((item, index) => {
              return (
                <Tab
                  key={"filter_" + index}
                  label={item.label}
                  // label={item.label}
                  sx={{
                    fontSize: { xs: "13px", md: "13.5px" },
                    fontWeight: tabValue == index ? 500 : 500,
                    p: 0,
                    minHeight: "30px !important",
                    mr: { xs: "20px", md: "55px" },
                    color:
                      item.id === tabValue
                        ? matches
                          ? blueColor04
                          : mobilePrimary
                        : greyColor40,
                    textTransform: "capitalize",
                  }}
                />
              );
            })}
          </Tabs>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={{ md: 0, xs: 1 }}
              width={"100%"}
            >
              <Typography
                variant="h4"
                fontSize={{ md: 15, xs: 15 }}
                fontWeight={600}
                color={greyColor24}
                lineHeight="28px"
              >
                Warehouse Detail
              </Typography>
              {!matches ? (
                <Button
                  onClick={handleEditDetails}
                  sx={styles.editBtn}
                  flex={{ md: "none", xs: 1 }}
                >
                  EDIT
                </Button>
              ) : null}
            </Box>

          {matches ? (
            <Button onClick={handleEditDetails} sx={styles.editBtn}>
              EDIT
            </Button>
          ) : null}
        </Box>
      </Box>
      <Box sx={styles.card}>
        <Typography
          component="P"
          fontSize={14}
          color={greyColor24}
          fontWeight={500}
          lineHeight="20px"
          mb="14px !important"
        >
          Following address and contact number will be used for scheduling the
          pickups
        </Typography>

        <Box display="flex" flexDirection="column" gap="8px" mt={1}>
          <InputPreview
            label="Contact Number"
            value={
              activeBusinessInfo?.contactNos?.length > 0
                ? activeBusinessInfo?.contactNos?.[0]?.number?.replace(
                    "+91",
                    "+91 "
                  )
                : ""
            }
            valueSize={14}
            hideBorder
          />

          <InputPreview
            label="Warehouse Address"
            value={commerceDetails?.address}
            valueSize={14}
            hideBorder
          />
        </Box>
      </Box>
      <Box sx={styles.card} mt={2}>
        <Typography
          component="h5"
          fontSize={14}
          color={greyColor24}
          fontWeight={600}
          lineHeight="20px"
          mt="5px !important"
        >
          BusinessTiming
        </Typography>

        <Box mt={{ md: 1, xs: 1 }}>
          <Grid container spacing={{ md: 1, xs: 1 }}>
            {businessTimings?.map((item, index) => (
              <Grid item xs={6} md={3} key={`timing_${index}`}>
                <InputPreview
                  label={getDayOfWeekByNum(item?.dayOfWeek)}
                  value={
                    item?.isClosed ? `Closed` : `${item?.from} - ${item?.to}`
                  }
                  valueSize={13}
                  hideBorder
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default WarehouseDetailContent;

const styles = {
  card: {
    p: { xs: "10px 15px", md: "1px 15px " },
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: { xs: "8px", md: "13px" },
    border: logoImgborder1,
  },

  dotUi: {
    width: "6px",
    height: "6px",
    borderRadius: "3px",
    backgroundColor: greyColor24,
    mt: -0.2,
  },

  pricingValueBox: {
    width: "170px",
    border: `1px solid ${greyColor28}`,
    p: "9px",
  },

  pricingValueText: {
    fontSize: 14,
    fontWeight: 500,
    color: greyColor24,
    lineHeight: "18px",
  },

  editBtn: {
    backgroundColor: blackShade22,
    padding: "3px 14px",
    fontSize: "12.5px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + blackShade22,
    "&:hover": {
      color: blackShade22,
      backgroundColor: whiteColor,
    },
  },
};
