import {
  Box,
  Button,
  Fade,
  Popper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import {
  blackShade05,
  blueColor04,
  logoImgborder1,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";

const UpdateInventoryBtn = ({
  inventoryErr,
  anchorEl,
  updateInventory,
  updateLoader,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box mt={{xs:1.7, md:0}}>
      <Popper
        open={inventoryErr}
        anchorEl={anchorEl}
        placement="bottom"
        transition
        sx={{ zIndex: 999 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              sx={{
                backgroundColor: whiteColor,
                p: "3px 20px",
                m: "10px 20px",
                borderRadius: "8px",
                boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
                border: logoImgborder1,
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: blackShade05,
                  m: "10px 0",
                  whiteSpace: "pre-line",
                }}
              >
                Inventory not updated for any products.
              </Typography>
            </Box>
          </Fade>
        )}
      </Popper>

      <Button
        sx={styles.addIconBtn}
        variant="contained"
        disableElevation
        onClick={updateInventory}
        fullWidth={!matches}
        startIcon={matches ? <InventoryRoundedIcon /> : null}
        disabled={updateLoader}
      >
        Update Inventory
      </Button>
    </Box>
  );
};

export default UpdateInventoryBtn;

const styles = {
  addIconBtn: {
    padding: { md: "3px 16px", xs: "3px 14px" },
    fontSize: { md: "13px", xs: "12px" },
    fontWeight: 600,
    color: { xs: mobilePrimary, md: blueColor04 },
    backgroundColor: whiteColor,
    whiteSpace:'nowrap',
    border: {
      xs: `1.5px solid ${mobilePrimary}`,
      md: `1.5px solid ${blueColor04}`,
    },
    "&:hover": {
      color: whiteColor,
      backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },
};
