import { Box, Button, Divider, Typography, useMediaQuery } from "@mui/material";
import {
  borderOTPTextbox,
  errorTextColor,
  headingColor,
  loginPrimary,
  secondaryColor,
  greyColor1,
  whiteColor,
  blueColor03,
  blackShade58,
  greyColor24,
  authInputBorder,
  greyColor6,
  blueColor04,
  yellowColor,
  blackShade09,
} from "../../../configs/styles/muiThemes";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import firebase from "../../../firebase";
// import {
//   createGuestUser,
//   getOwnRewards,
//   upadateRewardProfile,
//   userAuthGuestUser,
// } from 'services/api_calls/auth/login_api'
import {
  inputError_OTPIncorrent,
  LATITUDE,
  LONGITUDE,
} from "../../../configs/Constants";

function OTPScreen({
  mobileNumber,
  onLoginSuccess,
  onChangeNumber,
  onCreateAccount,
  userType,
}) {
  const [otp, setOtp] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [inputErrorMsg, setInputErrorMsg] = useState(false);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  // const configureCaptcha = () => {
  //   window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  //     'sign-in-button',
  //     {
  //       size: 'invisible',
  //       callback: (response) => {
  //         // reCAPTCHA solved, allow signInWithPhoneNumber.
  //         onSignInSubmit()
  //         console.log('Recaptca varified')
  //       },
  //       defaultCountry: 'IN',
  //     }
  //   )
  // }

  const onSignInSubmit = (type) => {
    setErrorMsg(false);
    setInputErrorMsg(false);
    if (otp.length != 6) {
      setInputErrorMsg(true);
      setLoading(false);
      return;
    }
    setLoading(true);
    console.log(window);
    window.confirmationResult
      .confirm(otp)
      .then((result) => {
        const user = result.user;
        console.log(JSON.stringify(user));
        console.log(result);
        let x = firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(function (idToken) {
            // console.log('Otp verified')
            //   onRegisterClick(idToken, guest)
            if (type === "guest") {
              checkUser(mobileNumber, idToken);
            } else {
              onCreateAccount(idToken);
            }

            // return idToken
          })

          .catch(function (error) {
            setLoading(false);
          });

        // ...
      })
      .catch((error) => {
        setErrorMsg(true);
        setLoading(false);
      });
  };

  const handleChange = (otp) => {
    if (errorMsg) setErrorMsg(false);
    if (inputErrorMsg) setInputErrorMsg(false);
    setOtp(otp);
  };

  const checkUser = async (phoneNumber, idToken) => {
    const lat = localStorage.getItem("lat") || LATITUDE;
    const long = localStorage.getItem("long") || LONGITUDE;

    if (userType === "new-user") {
      console.log("New Guest User");
      const xmppID = Math.floor(100000 + Math.random() * 900000);
      const data = {
        login: "+91" + phoneNumber,
        password: "costBog4+91" + phoneNumber,
        firstName: "Guest",
        lastName: "",
        email: "guest-+91" + phoneNumber + "@costbo.com",
        phone: "+91" + phoneNumber,
        type: "0",
        imeiNumber: "",
        deviceModel: "WEB",
        osVersion: "Mac",
        deviceID: "mac-os-x-15-Chrome",
        clientVersion: "",
        latitude: lat,
        longitude: long,
        gender: "M/F",
        birthYear: "0",
        fcmKey: "",
        apnsKey: "",
        fcmUserToken: idToken,
        id: "",
        imageUrl: "",
        status: "",
        sr: true,
        country: "INDIA",
        xmppID: xmppID,
      };

      // createGuestUser(data).then((res) => {
      //   console.log('CREATE_GUEST_USER_RESPONSE', res)
      //   let formdata = new FormData()
      //   formdata.append('grant_type', 'password')
      //   formdata.append('username', '+91' + phoneNumber)
      //   formdata.append('password', idToken)
      //   userAuthGuestUser(formdata).then((res1) => {
      //     console.log('USER_AUTH_RESPONSE', res1)
      //     getOwnRewards('91' + phoneNumber).then((resp1) => {
      //       upadateRewardProfile({
      //         data: resp1?.data?.details,
      //         phone: '91' + phoneNumber,
      //       }).then(() => {
      //         setLoading(false)
      //         // onLoginSuccess()
      //       })
      //     })
      //     setLoading(false)
      //     sessionStorage.setItem('userType', 'guest')
      //     onLoginSuccess()
      //   })
      // })
    } else {
      console.log("Returned Guest User");
      // let formdata = new FormData()
      // formdata.append('grant_type', 'password')
      // formdata.append('username', '+91' + phoneNumber)
      // formdata.append('password', idToken)
      // userAuthGuestUser(formdata).then((res) => {
      //   console.log('USER_AUTH_RESPONSE', res)
      //   setLoading(false)
      //   sessionStorage.setItem('userType', 'guest')
      //   onLoginSuccess()
      // })
    }

    // return dispatch(checkExistingUserGuest(phoneNumber)).then((response) => {
    //   console.log(response)
    //   if (response.payload) {
    //     if (response.payload.status === 200) {
    //       if (response.payload.data.sr === true) {
    //         if (response.payload.data.na === false) {
    //           console.log('New Guest User')
    //           const xmppID = Math.floor(100000 + Math.random() * 900000)
    //           const data = {
    //             login: '+91' + phoneNumber,
    //             password: 'costBog4+91' + phoneNumber,
    //             firstName: 'Guest',
    //             lastName: '',
    //             email: 'guest-+91' + phoneNumber + '@costbo.com',
    //             phone: '+91' + phoneNumber,
    //             type: '0',
    //             imeiNumber: '',
    //             deviceModel: 'WEB',
    //             osVersion: 'Mac',
    //             deviceID: 'mac-os-x-15-Chrome',
    //             clientVersion: '',
    //             latitude: lat,
    //             longitude: long,
    //             gender: 'M/F',
    //             birthYear: '0',
    //             fcmKey: '',
    //             apnsKey: '',
    //             fcmUserToken: idToken,
    //             id: '',
    //             imageUrl: '',
    //             status: '',
    //             sr: true,
    //             country: 'INDIA',
    //             xmppID: xmppID,
    //           }

    //           createGuestUser(data).then((res) => {
    //             console.log('CREATE_GUEST_USER_RESPONSE', res)
    //             let formdata = new FormData()
    //             formdata.append('grant_type', 'password')
    //             formdata.append('username', '+91' + phoneNumber)
    //             formdata.append('password', idToken)
    //             userAuthGuestUser(formdata).then((res1) => {
    //               console.log('USER_AUTH_RESPONSE', res1)
    //               setLoading(false)
    //               onLoginSuccess()
    //             })
    //           })
    //           console.log('New Guest User', data)
    //         } else {
    //           console.log('Returned Guest User')
    //           let formdata = new FormData()
    //           formdata.append('grant_type', 'password')
    //           formdata.append('username', '+91' + phoneNumber)
    //           formdata.append('password', idToken)
    //           userAuthGuestUser(formdata).then((res) => {
    //             console.log('USER_AUTH_RESPONSE', res)
    //             setLoading(false)
    //             onLoginSuccess()
    //           })
    //         }
    //       }
    //     }
    //   }
    // })
  };
  return (
    <Box>
      <Typography
        variant="h4"
        color={blackShade58}
        fontSize={{ xs: 17, md: 22 }}
        fontWeight={600}
        lineHeight="30px"
        m={{ md: "0px 0 15px !important", xs: "0px 0 8px !important" }}
      >
        Authenticate with OTP
      </Typography>

      <Typography
        variant="body1"
        color={greyColor6}
        fontSize={{ xs: 13, md: 14 }}
        fontWeight={400}
        lineHeight="18px"
        m={{ md: "0px 0 8px !important", xs: "0px 0 3px !important" }}
      >
        OTP sent to
      </Typography>

      <Typography
        variant="h5"
        color={greyColor6}
        fontSize={{ xs: 13, md: 14 }}
        fontWeight={400}
        lineHeight="18px"
        m="0px 0 5px !important"
        display="inline"
      >
        +91 {mobileNumber}{" "}
        <Typography
          component="h6"
          color={blueColor04}
          fontSize={13}
          fontWeight={500}
          sx={{
            textDecoration: "underline",
            ml: 0.5,
            "&:hover": { textDecoration: "none" },
            cursor: "pointer",
          }}
          onClick={onChangeNumber}
          display="inline"
        >
          Change
        </Typography>
      </Typography>

      <Box sx={{ mt: { md: 2, xs: 1 }, mb: 0 }}>
        <OtpInput
          value={otp}
          onChange={handleChange}
          name="otp"
          required
          isInputNum={true}
          numInputs={6}
          shouldAutoFocus={true}
          inputStyle={{
            border: authInputBorder,
            // borderBottom: "1px solid rgb(115, 115, 115)",
            width: matches ? "44px" : "35px",
            height: matches ? "40px" : "35px",
            borderRadius: "6px",
            color: headingColor,
          }}
          containerStyle={{
            width: matches ? "75%" : "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        />
      </Box>

      {errorMsg && (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={13}
          color={errorTextColor}
        >
          {inputError_OTPIncorrent}
        </Typography>
      )}

      {inputErrorMsg && (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={13}
          color={errorTextColor}
        >
          Please Enter the 6 digits OTP
        </Typography>
      )}

      {/* <Box>
        <Typography
          variant="body1"
          component="p"
          color={greyColor1}
          fontSize={12}
          display="inline"
        >
          By continuing, you agree to our{" "}
          <Typography
            color={loginPrimary}
            display="inline"
            fontSize={12}
            fontWeight={500}
            component={Link}
            // target='_blank'
            to={`/terms-conditions`}
          >
            Terms of Use
          </Typography>{" "}
          &{" "}
          <Typography
            color={loginPrimary}
            display="inline"
            fontSize={12}
            fontWeight={500}
            component={Link}
            // target='_blank'
            to={`/privacy-policy`}
          >
            Privacy Policy
          </Typography>
        </Typography>
      </Box> */}

      <Box display="flex" justifyContent="flex-end">
        <Button
          sx={{
            ...styles.actionBtn(),
            border: loading ? "none" : `1px solid ${yellowColor}`,
          }}
          variant="contained"
          disableElevation
          //   onClick={handleSubmit}
          onClick={() => onSignInSubmit("create")}
          disabled={loading}
        >
          VERIFY
        </Button>
      </Box>
      {/* <Divider sx={{ mb: 1.5, mt: 2.5 }}>OR</Divider>
      <Typography
        // variant='h5'
        component='h6'
        color={loginPrimary}
        fontSize={{ xs: 15 }}
        fontWeight={500}
        textAlign='center'
        lineHeight={1.5}
        sx={{
          textDecoration: 'underline',
          '&:hover': { textDecoration: 'none' },
          cursor: 'pointer',
        }}
        onClick={() => onSignInSubmit('create')}
        pb={{ xs: 0, md: 2 }}
      >
        Create an account
      </Typography> */}
    </Box>
  );
}

export default OTPScreen;

const styles = {
  actionBtn: () => ({
    minWidth: { md: "115px", xs: "auto" },
    fontSize: { md: "14px", xs: "13px" },
    fontWeight: 600,
    color: blackShade09,
    background: yellowColor,
    p: { md: "5px 22px", xs: "4px 12px" },
    mt: "20px !important",
    borderRadius: "6px",

    "&:hover": {
      background: "none",
      backgroundColor: whiteColor,
      color: blackShade09,
      border: `1px solid ${blackShade09}`,
    },
    letterSpacing: "0.5px",
  }),
};
