import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import InfoCardHeader from "./InfoCardHeader";
import {
  blackShade05,
  blueColor02,
  blueColor04,
  logoImgborder1,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import LabelValueText from "./LabelValueText";

function SupplyChainFintechCard({ onClick }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box sx={styles.card}>
      <InfoCardHeader title="Supply Chain Fintech" onManageClick={onClick} />

      <Box
        display="flex"
        alignItems="center"
        borderBottom="1px dashed #e2e4ea"
        pb={{ xs: 1, md: 2 }}
      >
        <LabelValueText
          label="NUMBER OF ORDERS"
          value="0"
          hideBorder
          labelBottom
          valueSize={14}
        />
      </Box>

      <Box display="flex" alignItems="center" pb={2}>
        <LabelValueText
          label="TOTAL SALES"
          value="₹ 0"
          valueColor={matches ? blueColor04 : blackShade05}
          borderStyle="dashed"
          labelBottom
          valueSize={14}
        />

        <LabelValueText
          label="AVA ORDER VALUE"
          value="0"
          hideBorder
          labelBottom
          valueSize={14}
        />
      </Box>
    </Box>
  );
}

export default SupplyChainFintechCard;

const styles = {
  card: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "13px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 15px", md: "15px 25px" },
    mb: { xs: 2, md: 0 },
  },
};
