import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Button,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  bgColor15,
  blackShade05,
  blackShade159,
  blackShade42,
  greenColor3,
  greyColor11,
  greyColor28,
  greyColor29,
  greyColor30,
  greyColor42,
  greyColor6,
  heroSectionText,
  lightBlueColor01,
  logoImgborder1,
  mobilePrimary,
  orangeColor02,
  orangeStatusColor,
  outOfStockColor,
  tableHeaderColor,
  whiteColor,
  blueColor04,
  blackShade22,
  greyColor8,
  voiletShade01,
} from "../../../configs/styles/muiThemes";
import {
  getCartInfoApi,
  getShipingLabelLink,
  resendInvoiceApi,
  resendInvoiceApiB2B,
} from "../../../service/api_calls/orders/OrdersApi";
import moment from "moment";
import {
  addThousandsSeparator,
  getFirstStringBeforeComma,
  getOrderDeliveryTypeText,
  getOrderType,
  updateApproveList,
} from "../../../utils/helper";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import ResponsiveStepper from "../../../components/Stepper/ResponsiveStepper";
import TimeScheduleStep from "../../../components/Stepper/TimeScheduleStep";
import { TIME_SCHEDULE } from "../../../utils/data";
import DisplayTrackingInfoCard from "./ApprovePopups/DisplayTrackingInfoCard";
import DisplayThroughDeliveryAgent from "./ApprovePopups/DisplayThroughDeliveryAgent";
import DisplayB2BViewContent from "./ApprovePopups/DisplayB2BViewContent";
import DownloadIcon from "@mui/icons-material/Download";

function ViewOrderPopupContent({
  orderData,
  handleTrackOrder,
  showTracking,
  getOndcBapNameBySubscriberId,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [cartInfo, setCartInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState(null);
  const [success, setSuccess] = useState(false);
  const [successB2B, setSuccessB2B] = useState(false);
  const [errorB2B, setErrorB2B] = useState(false);
  const [loadingB2B, setLoadingB2B] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState(false);
  const [errorLabel, setErrorLabel] = useState(false);

  const orderType = getOrderType(orderData);

  const getCartData = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    setLoading(true);

    if (orderData?.cartId) {
      console.log("ORDER_DATA", orderData);
      const response = await getCartInfoApi({
        cartId: orderData?.cartId,
        businessId,
      });

      if (response?.cart) {
        setCartInfo(response?.cart || null);
        setLoading(false);
        setSteps(updateApproveList(response?.cart));
        setSuccess(false);
        setSuccessB2B(false);
        return;
      }
    }

    setLoading(false);
  };

  const getBGColorByStatus = (status) => {
    switch (status) {
      case "ordercreated":
        return orangeStatusColor;
      case "orderapproved":
        return blackShade05;
      case "ordershipped":
        return lightBlueColor01;
      case "orderdelivered":
        return lightBlueColor01;
      case "bordercanceled":
        return outOfStockColor;
      case "cordercanceled":
        return outOfStockColor;

      default:
        return "";
    }
  };
  const getText = (status) => {
    switch (status) {
      case "bordercanceled":
        return "Canceled by Business";
      case "cordercanceled":
        return "Canceled by Customer";

      default:
        return "";
    }
  };

  const getOrderStatusText = (status) => {
    switch (status) {
      case "ordercreated":
        return "Pending";
      case "orderapproved":
        return "Approved";
      case "ordershipped":
        return "Shipped";
      case "orderdelivered":
        return "Completed";
      case "bordercanceled":
        return "Canceled";
      case "cordercanceled":
        return "Canceled";

      default:
        return "";
    }
  };

  const handleResend = async () => {
    if (orderData?.cartId) {
      const response = await resendInvoiceApi({
        cartId: orderData?.cartId,
      });

      if (response) {
        setSuccess(true);
        return;
      }
    }
  };

  const handleResendB2B = async () => {
    setErrorB2B(false);
    setSuccessB2B(false);
    setLoadingB2B(false);
    if (orderData?.cartId) {
      if (
        cartInfo?.shipmentInfo?.carrierTrackId?.length === 36 ||
        !cartInfo?.shipmentInfo?.carrierTrackId
      ) {
        setErrorB2B(true);
        return;
      }
      setLoadingB2B(true);

      const businessId = sessionStorage.getItem("selectedBusinessId");
      const response = await resendInvoiceApiB2B({
        cartId: orderData?.cartId,
        carrierId: cartInfo?.shipmentInfo?.carrierTrackId,
        businessId,
      });

      if (response) {
        setSuccessB2B(true);
        setLoadingB2B(false);
        return;
      }
    }
  };

  const downloadShipingLabel = async () => {
    setErrorLabel(false);
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const data = { businessId, wbns: cartInfo?.shipmentInfo?.carrierTrackId };
    setLoadingLabel(true);
    const shipingLabelLink = await getShipingLabelLink(data);
    setLoadingLabel(false);
    console.log("downloadPdf", shipingLabelLink);
    if (!shipingLabelLink?.packages?.[0]?.pdf_download_link) {
      setErrorLabel(true);
      return;
    }
    downloadPdf(
      shipingLabelLink?.packages?.[0]?.pdf_download_link,
      "shippingLabel.pdf"
    );
  };

  const downloadPdf = (url, name) => {
    console.log(url, name);
    window.open(url, "_self");
    // const link = document.createElement("a");
    // link.href = { url }; // Replace with the path to your PDF file
    // link.download = name; // The name of the downloaded file
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    // fetch(url, { method: "HEAD" })
    //   .then((response) => {
    //     if (response.ok) {
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.download = name; // The name of the downloaded file
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //     } else {
    //       alert("File wasn't available on site");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching the PDF:", error);
    //     alert("An error occurred while trying to fetch the PDF");
    //   });
  };

  useEffect(() => {
    getCartData();
    return () => {};
  }, []);

  return (
    <Box overflow="hidden">
      {loading ? (
        <Box
          sx={{
            minHeight: "100px",
            backgroundColor: whiteColor,
            borderRadius: "12px",
            pt: "20px",
          }}
        >
          <ThreeDotLoader />
        </Box>
      ) : (
        <Box sx={styles.card}>
          <Typography
            variant="h5"
            fontSize={17}
            fontWeight={600}
            color={blackShade05}
          >
            Order Detail
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Box sx={styles.orderInfoBox}>
                <Box>
                  <Box mb={{ md: 0.5, xs: 0.5 }}>
                    <Typography variant="h6" sx={styles.orderIdText}>
                      Order ID:{" "}
                      <Typography
                        variant="h6"
                        sx={{ ...styles.orderIdText, fontWeight: 600 }}
                      >
                        {cartInfo?.orderId}
                      </Typography>
                    </Typography>
                  </Box>

                  {orderType?.orderType == "ondcOrder" && (
                    <>
                      <Box mb={{ md: 0.5, xs: 0.5 }}>
                        <Typography variant="h6" sx={styles.orderIdText}>
                          ONDC Order ID:{" "}
                          <Typography
                            variant="h6"
                            sx={{ ...styles.orderIdText, fontWeight: 600 }}
                          >
                            {orderData?.ondcOrderId || ""}
                          </Typography>
                        </Typography>
                      </Box>

                      <Box mb={{ md: 0.8, xs: 0.5 }}>
                        <Typography variant="h6" sx={styles.orderIdText}>
                          ONDC Buyer App:{" "}
                          <Typography
                            variant="h6"
                            sx={{ ...styles.orderIdText, fontWeight: 600 }}
                          >
                            {getOndcBapNameBySubscriberId(orderData?.ondcBapId)}
                          </Typography>
                        </Typography>
                      </Box>
                    </>
                  )}

                  <Typography variant="h6" sx={styles.orderIdText}>
                    Order Date:{" "}
                    <Typography
                      variant="h6"
                      sx={{ ...styles.orderIdText, fontWeight: 600 }}
                    >
                      {moment(cartInfo?.orderCreatedOn).format("MMM Do, YYYY")}
                    </Typography>
                  </Typography>
                  {cartInfo?.orderStatus === "bordercanceled" ||
                  cartInfo?.orderStatus === "cordercanceled" ? (
                    <>
                      <Typography
                        mt={1.5}
                        color={bgColor15}
                        fontSize={14}
                        lineHeight={1.5}
                        fontWeight={500}
                      >
                        {getText(cartInfo?.orderStatus)}
                      </Typography>
                      <Typography
                        color={orangeColor02}
                        fontSize={13.5}
                        lineHeight={1.5}
                        fontWeight={500}
                        mt={0.8}
                      >
                        {cartInfo?.cancelInfo
                          ? cartInfo?.cancelInfo?.cancelReason
                          : cartInfo?.orderStatus === "bordercanceled"
                          ? cartInfo?.notesBusiness
                          : cartInfo?.notesConsumer}
                      </Typography>
                    </>
                  ) : null}
                </Box>

                <Box
                  sx={styles.statusTag(
                    getBGColorByStatus(cartInfo?.orderStatus)
                  )}
                >
                  {getOrderStatusText(cartInfo?.orderStatus)}
                </Box>
                {cartInfo?.deliveryType?.type?.toLowerCase() === "delhivery" &&
                  cartInfo?.orderStatus !== "ordercreated" &&
                  cartInfo?.orderStatus !== "bordercanceled" &&
                  cartInfo?.orderStatus !== "cordercanceled" && (
                    <Stack gap={0.5} mt={1}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { md: "row", xs: "column" },
                          gap: 2,
                        }}
                      >
                        {cartInfo?.invoiceUrl && (
                          <a
                            href={cartInfo?.invoiceUrl}
                            download
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button
                              sx={styles.actionBtn(voiletShade01)}
                              onClick={
                                () => {}
                                // downloadPdf(cartInfo?.invoiceUrl, "Invoice.pdf")
                              }
                              startIcon={
                                <DownloadIcon sx={{ fontSize: "14px" }} />
                              }
                            >
                              InVoice
                            </Button>
                          </a>
                        )}
                        {cartInfo?.orderStatus !== "orderdelivered" && (
                          <Button
                            sx={styles.actionBtn(blueColor04)}
                            onClick={downloadShipingLabel}
                            startIcon={
                              <DownloadIcon sx={{ fontSize: "14px" }} />
                            }
                            disabled={loadingLabel}
                          >
                            SHIPPING LABEL
                          </Button>
                        )}
                      </Box>
                      {errorLabel && (
                        <Typography
                          variant="subtitle1"
                          component="p"
                          fontSize={11.5}
                          color="red"
                          textAlign="right"
                          mb={-1.2}
                        >
                          Shipping Label expired/ not available
                        </Typography>
                      )}
                    </Stack>
                  )}
              </Box>

              {showTracking ? (
                cartInfo?.deliveryType?.type?.toLowerCase() === "delhivery" ||
                cartInfo?.deliveryType?.type?.toLowerCase() === "telyport" ? (
                  <Box sx={{ ...styles.orderInfoBox, p: "12px" }}>
                    <Box>
                      <ResponsiveStepper
                        steps={steps}
                        handleTrackOrder={handleTrackOrder}
                      />
                      {cartInfo?.deliveryType?.type?.toLowerCase() !==
                        "telyport" && (
                        <Box
                          display="flex"
                          flexDirection={{ md: "row", xs: "column" }}
                          mt={3}
                          ml={{ md: "205px", xs: 0 }}
                        >
                          {TIME_SCHEDULE.map((item, index) => (
                            <TimeScheduleStep
                              label={item.label}
                              subLabel={item.sublabel}
                              msg={item.msg}
                              btn={item.btn}
                              hideLine={TIME_SCHEDULE.length - 1 === index}
                              handleResend={handleResend}
                              success={success}
                              successMsg={item.successMsg}
                              key={`${item.label}${index}`}
                              disableButton={
                                cartInfo?.orderStatus === "orderdelivered"
                              }
                              subLabel1={
                                TIME_SCHEDULE?.length - 1 === index &&
                                `Email To: ${getFirstStringBeforeComma(
                                  cartInfo?.businessAddress?.emails
                                )}`
                              }
                            />
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                ) : orderData?.deliveryType?.type?.toLowerCase() ===
                    "business" && orderData?.shipmentInfo != null ? (
                  <Box
                    sx={{
                      borderRadius: "12px",
                      border: `1px solid ${greyColor28}`,
                      mt: 2.2,
                      p: "12px",
                    }}
                  >
                    <Typography
                      fontSize={15}
                      fontWeight={600}
                      color={blackShade05}
                      mb="12px"
                      lineHeight={"20px"}
                    >
                      Shipment Info
                    </Typography>
                    {cartInfo?.shipmentInfo?.carrierTrackId ? (
                      <DisplayTrackingInfoCard
                        carrierTrackId={cartInfo?.shipmentInfo?.carrierTrackId}
                        carrierName={cartInfo?.shipmentInfo?.carrierName}
                        carrierURL={cartInfo?.shipmentInfo?.carrierURL}
                        notes={cartInfo?.shipmentInfo?.notes}
                        img={cartInfo?.shipmentInfo?.shipmentLabel}
                        mode={cartInfo?.shipmentInfo?.deliveryMode}
                      />
                    ) : (
                      <DisplayThroughDeliveryAgent
                        deliveryPersonPhone={
                          cartInfo?.shipmentInfo?.deliveryPersonPhone
                        }
                        deliveryPersonName={
                          cartInfo?.shipmentInfo?.deliveryPersonName
                        }
                        etaDeliveryDate={
                          cartInfo?.shipmentInfo?.etaDeliveryDate
                        }
                        etaDeliveryTime={
                          cartInfo?.shipmentInfo?.etaDeliveryTime
                        }
                        notes={cartInfo?.shipmentInfo?.notes}
                        mode={cartInfo?.shipmentInfo?.deliveryMode}
                      />
                    )}
                  </Box>
                ) : null
              ) : null}

              {showTracking &&
              cartInfo?.deliveryType?.type?.toLowerCase() === "delhiveryb2b" ? (
                <DisplayB2BViewContent
                  boxData={cartInfo?.btobInfo?.dimensions}
                  handleResend={handleResendB2B}
                  success={successB2B}
                  error={errorB2B}
                  loading={loadingB2B}
                  orderStatus={cartInfo?.orderStatus}
                />
              ) : null}

              {matches ? (
                <Box sx={styles.table}>
                  <Box sx={styles.tableHeader}>
                    <Grid container>
                      <Grid item xs={4.5}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          PRODUCT
                        </Typography>
                      </Grid>

                      <Grid item xs={1.5}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          UNIT PRICE
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          QUANTITY
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          PRICE
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          SAVINGS
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  {cartInfo?.productList?.map((item, index) => {
                    const savings =
                      Number(item?.productOriginalPrice) *
                        Number(item?.currentQuantity) -
                      Number(item?.productSalePrice) *
                        Number(item?.currentQuantity);

                    return (
                      <Box sx={styles.productRow} key={item?.productId}>
                        <Grid container spacing={2}>
                          <Grid item xs={4.5}>
                            <Box
                              display="flex"
                              alignItems="center"
                              gap="10px"
                              ml={2}
                            >
                              <Box
                                component="img"
                                src={item?.productPicURL}
                                alt={item?.productName}
                                sx={styles.productImg}
                              />

                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                {item?.productName}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={1.5}>
                            <Box sx={styles.centerText}>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹{addThousandsSeparator(item?.productSalePrice)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={2}>
                            <Box sx={styles.centerText}>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                {item?.currentQuantity}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={2}>
                            <Box sx={styles.centerText}>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                                ml={-2}
                              >
                                ₹
                                {addThousandsSeparator(item?.productTotalPrice)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={2}>
                            <Box sx={styles.centerText}>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                                ml={-2}
                              >
                                ₹{addThousandsSeparator(savings)}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </Box>
              ) : null}
            </Grid>

            <Grid item xs={12} md={3}>
              <Box sx={styles.summaryCard}>
                <Typography
                  variant="h3"
                  fontSize={15}
                  fontWeight={600}
                  color={blackShade05}
                  pb="12px"
                  pt="4px"
                  sx={{
                    borderBottom: `1px solid ${greyColor28}`,
                    textAlign: { md: "right", xs: "left" },
                  }}
                >
                  SUMMARY
                </Typography>

                <Box mt={1}>
                  <SummaryDataRow
                    label="Subtotal:"
                    value={`₹${addThousandsSeparator(
                      cartInfo?.orderTotalProductCost
                    )}`}
                  />

                  <SummaryDataRow
                    label="Total Quantity:"
                    value={cartInfo?.totalQuantity}
                  />

                  <SummaryDataRow
                    label="Taxes:"
                    value="Included"
                    valueColor={greenColor3}
                  />

                  <SummaryDataRow
                    label="Shipping & Handling:"
                    value={`₹${addThousandsSeparator(cartInfo?.shippingCost)}`}
                  />

                  <SummaryDataRow
                    label="Discount:"
                    value={`₹${addThousandsSeparator(cartInfo?.claimedPoint)}`}
                  />
                  {!orderData?.cartType && orderData?.ondcCart ? (
                    <>
                      <SummaryDataRow
                        label="Convenience Fee:"
                        value={`₹${addThousandsSeparator(
                          cartInfo?.convenienceFee
                        )}`}
                      />

                      <SummaryDataRow
                        label="Packing Cost:"
                        value={`₹${addThousandsSeparator(
                          cartInfo?.packingInfo?.cost
                        )}`}
                      />
                    </>
                  ) : null}

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      borderTop: `1px solid ${greyColor28}`,
                      mb: "4px",
                      pt: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontSize={14}
                      fontWeight={cartInfo?.codCost ? 500 : 600}
                      color={blackShade05}
                    >
                      Order Total
                    </Typography>

                    <Typography
                      variant="body2"
                      fontSize={14}
                      fontWeight={cartInfo?.codCost ? 500 : 600}
                      color={{ md: heroSectionText, xs: mobilePrimary }}
                    >
                      ₹
                      {!orderData?.cartType && orderData?.ondcCart
                        ? addThousandsSeparator(
                            Number(cartInfo?.orderTotalProductCost) +
                              Number(cartInfo?.shippingCost || 0) -
                              Number(cartInfo?.claimedPoint || 0) +
                              Number(cartInfo?.convenienceFee || 0) +
                              Number(cartInfo?.packingInfo?.cost || 0)
                          )
                        : addThousandsSeparator(
                            Number(cartInfo?.orderTotalProductCost) +
                              Number(cartInfo?.shippingCost || 0) -
                              Number(cartInfo?.claimedPoint || 0)
                          )}
                    </Typography>
                  </Box>
                  {cartInfo?.codCost && (
                    <>
                      <SummaryDataRow
                        label="COD Cost:"
                        value={`₹${addThousandsSeparator(
                          cartInfo?.codCost?.codCharges
                        )}`}
                      />
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          borderTop: `1px solid ${greyColor28}`,
                          mb: "4px",
                          pt: "12px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontSize={14}
                          fontWeight={600}
                          color={blackShade05}
                        >
                          Total
                        </Typography>

                        <Typography
                          variant="body2"
                          fontSize={14}
                          fontWeight={600}
                          color={{ md: heroSectionText, xs: mobilePrimary }}
                        >
                          ₹
                          {addThousandsSeparator(
                            Number(cartInfo?.orderTotalProductCost) +
                              Number(cartInfo?.codCost?.codCharges) +
                              Number(cartInfo?.shippingCost || 0) -
                              Number(cartInfo?.claimedPoint || 0)
                          )}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box sx={styles.summaryCard}>
                <Typography
                  variant="h3"
                  fontSize={15}
                  fontWeight={600}
                  color={blackShade05}
                  pb="12px"
                  pt="4px"
                  sx={{
                    borderBottom: `1px solid ${greyColor28}`,
                    textAlign: { md: "right", xs: "left" },
                  }}
                >
                  Delivery Address
                </Typography>

                <Box mt={1}>
                  <Typography
                    variant="h6"
                    fontSize={14}
                    fontWeight={600}
                    color={blackShade05}
                    lineHeight="25px"
                    sx={{ mt: "4px" }}
                  >
                    {cartInfo?.customerAddress?.name}{" "}
                    {/* {cartInfo?.customerAddress?.type
                      ? `(${cartInfo?.customerAddress?.type})`
                      : ""} */}
                  </Typography>

                  {cartInfo?.customerAddress?.addressLine1 && (
                    <Typography
                      variant="body1"
                      fontSize={12}
                      fontWeight={500}
                      color={greyColor6}
                      sx={{ m: "6px 0 10px" }}
                    >
                      {!orderData?.cartType && orderData?.ondcCart
                        ? `${cartInfo?.customerAddress?.houseNo},`
                        : ""}{" "}
                      {cartInfo?.customerAddress?.addressLine1},{" "}
                      {cartInfo?.customerAddress?.landMark},{" "}
                      {cartInfo?.customerAddress?.city} -{" "}
                      {cartInfo?.customerAddress?.zip},{" "}
                      {cartInfo?.customerAddress?.state},{" "}
                      {cartInfo?.customerAddress?.country}
                    </Typography>
                  )}

                  <Typography
                    variant="body1"
                    fontSize={12}
                    fontWeight={500}
                    color={greyColor6}
                  >
                    Ph: {cartInfo?.customerAddress?.phoneNo}
                  </Typography>
                </Box>
              </Box>

              <Box sx={styles.summaryCard}>
                <Typography
                  variant="h3"
                  fontSize={15}
                  fontWeight={600}
                  color={blackShade05}
                  pb="12px"
                  pt="4px"
                  sx={{
                    borderBottom: `1px solid ${greyColor28}`,
                    textAlign: { md: "right", xs: "left" },
                  }}
                >
                  Delivery Method
                </Typography>

                <Box m="12px 0 -10px">
                  <Typography
                    variant="body1"
                    fontSize={12}
                    fontWeight={500}
                    color={greyColor6}
                    sx={{ m: "6px 0 10px", textTransform: "capitalize" }}
                  >
                    {!cartInfo?.deliveryType
                      ? "Store Pickup"
                      : getOrderDeliveryTypeText(cartInfo?.deliveryType?.type)}
                    {/* {cartInfo?.deliveryDateRange !== "- -"
                      ? cartInfo?.deliveryDateRange
                      : !cartInfo?.customerAddress?.city
                      ? "Store Pickup"
                      : ""} */}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {matches ? null : (
              <>
                <Grid item xs={12} md="none" mt={-2}>
                  <Typography
                    variant="h3"
                    fontSize={15}
                    fontWeight={600}
                    color={blackShade05}
                    // pb="12px"
                    // pt="30px"
                    sx={{
                      // borderBottom: `1px solid ${greyColor28}`,
                      textAlign: { md: "none", xs: "left" },
                    }}
                  >
                    Product Overview
                  </Typography>
                </Grid>
                <Grid item xs={12} md="none">
                  {/* <Box sx={styles.summaryCard}> */}
                  {cartInfo?.productList?.map((item, index) => {
                    const savings =
                      Number(item?.productOriginalPrice) *
                        Number(item?.currentQuantity) -
                      Number(item?.productSalePrice) *
                        Number(item?.currentQuantity);

                    return (
                      <Box sx={styles.summaryCard}>
                        {/* <Box sx={styles.productRow} key={item?.productId}> */}
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Box
                              display="flex"
                              alignItems="center"
                              gap="12px"
                              // ml={2}
                            >
                              <Box
                                component="img"
                                src={item?.productPicURL}
                                alt={item?.productName}
                                sx={styles.productImg}
                              />

                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  fontSize: "13px",
                                  fontWeight: 500,
                                }}
                              >
                                {item?.productName}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: { md: "12px", xs: "11px" },
                                  mb: 1,
                                }}
                              >
                                UNIT PRICE
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹{addThousandsSeparator(item?.productSalePrice)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: { md: "12px", xs: "11px" },
                                  mb: 1,
                                }}
                              >
                                QUANTITY
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                {item?.currentQuantity}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: { md: "12px", xs: "11px" },
                                  mb: 1,
                                }}
                              >
                                PRICE
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹
                                {addThousandsSeparator(item?.productTotalPrice)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: { md: "12px", xs: "11px" },
                                  mb: 1,
                                }}
                              >
                                SAVINGS
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹{addThousandsSeparator(savings)}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                  {/* </Box> */}
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default ViewOrderPopupContent;

const SummaryDataRow = ({ label, value, valueColor }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb="10px"
    >
      <Typography
        variant="body1"
        fontSize={13}
        fontWeight={500}
        color={blackShade05}
      >
        {label}
      </Typography>

      <Typography
        variant="body2"
        fontSize={13.5}
        fontWeight={600}
        color={valueColor || greyColor30}
      >
        {value}
      </Typography>
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "12px",
    padding: "20px",

    maxHeight: { md: "95vh", xs: "70vh" },
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
      // height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blackShade159,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },

  orderIdText: {
    fontSize: { md: 13, xs: 13 },
    fontWeight: 500,
    lineHeight: "20px",
    color: blackShade05,
    display: "inline",
  },

  orderInfoBox: {
    display: "flex",
    flexDirection: { md: "row", xs: "column" },
    alignItems: { md: "flex-end", xs: "flex-start" },
    justifyContent: "space-between",
    borderRadius: "12px",
    border: `1px solid ${greyColor28}`,
    p: "13px 15px 13px 15px",
    mt: 2.2,
    position: "relative",
  },

  statusTag: (bgColor) => ({
    position: "absolute",
    right: 0,
    top: "10%",
    backgroundColor: bgColor || lightBlueColor01,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "20px",
    color: whiteColor,
    p: "0 15px 0 20px",
    // position: "relative",

    "&::before": {
      content: '" "',
      width: "15px",
      height: "28px",
      backgroundColor: whiteColor,
      rotate: "40deg",
      position: "absolute",
      left: -3,
      top: -8,
    },
  }),

  table: {
    border: `1px solid ${greyColor11}`,
    borderRadius: "7px",
    mt: 3,
  },

  tableHeader: {
    backgroundColor: greyColor42,
    p: "10px 10px",
    borderRadius: "7px 7px 0 0",
  },

  tableHeaderText: {
    fontSize: 13,
    fontWeight: 500,
    color: greyColor29,
  },

  productRow: {
    p: "10px 0",
    borderBottom: "1px solid #f2f2f2",
  },

  productImg: {
    width: { md: "45px", xs: 45 },
    height: { md: "45px", xs: 45 },
    borderRadius: "8px",
    border: logoImgborder1,
  },

  productInfoText: {
    fontSize: 14,
    fontWeight: 600,
    // alignItems:"center",
    color: blackShade42,
  },

  centerText: {
    height: "100%",
    display: "flex",
    alignItems: { md: "center", xs: "flex-start" },
    justifyContent: "flex-start",
  },

  summaryCard: {
    padding: "12px",
    borderRadius: "10px",
    border: `1px solid ${greyColor28}`,
    mt: { md: 2.2, xs: "none" },
    mb: { md: "none", xs: 2.2 },
  },

  actionBtn: (color) => ({
    padding: { md: "3px 6px", xs: "3px 15px" },
    fontSize: "12px",
    fontWeight: 500,
    color: color || greyColor8,
    backgroundColor: whiteColor,
    border: color ? "1px solid " + color : "1px solid" + greyColor8,
    "&:hover": {
      color: whiteColor,
      backgroundColor: color || greyColor8,
    },
    "&.Mui-disabled": {
      borderColor: "rgba(0,0,0,0.2)",
    },
  }),
};
