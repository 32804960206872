import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Suspense } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import BusinessOnboarding from "./pages/BusinessOnboarding";
import Dashboard from "./pages/Dashboard";
import TermsAndConditions from "./pages/Auth/TermsAndConditions";
import PrivacyPolicy from "./pages/Auth/PrivacyPolicy";
import Catalog from "./pages/Catalog";
import ProductList from "./pages/ProductList";
import ProductPreview from "./pages/ProductPreview";
import ChooseBusiness from "./pages/ChooseBusiness";
import BusinessDetailsPreview from "./pages/BusinessDetailsPreview";
import ScrollToTop from "./components/ScrollToTop";
import URLtoBase64Test from "./pages/URLtoBase64Test";
import BusinessDashboard from "./pages/BusinessDashboard";
import OutOfStock from "./pages/Inventory/OutOfStock";
import LowStock from "./pages/Inventory/LowStock";
import PriceUpdate from "./pages/Inventory/PriceUpdate";
import AddProduct from "./pages/AddProduct";
import BusinessDashboardStart from "./pages/BusinessDashboardStart";
import AddCommerceInfo from "./pages/AddCommerceInfo";
import CouponsDiscount from "./pages/CouponsDiscount";
import CashBack from "./pages/CashBack";
import PreferredDiscounts from "./pages/PreferredDiscounts";
import Orders from "./pages/Orders";
import PaycartOrders from "./pages/PaycartOrders";
import AllStock from "./pages/Inventory/AllStock";
import AffiliateMarketingDashboard from "./pages/AffiliateMarketingDashboard";
import LandingPage from "./pages/LandingPage";
import LogisticsManagement from "./pages/EComInfoPreview/LogisticsManagement";
import BankDetails from "./pages/EComInfoPreview/BankDetails";
import BusinessDiscounts from "./pages/EComInfoPreview/BusinessDiscounts";
import ManageDelegates from "./pages/EComInfoPreview/ManageDelegates";
import PausedInventory from "./pages/Inventory/PausedInventory";
import CRMMarketing from "./pages/CRMMarketing";
import WarehouseDetail from "./pages/EComInfoPreview/WarehouseDetail";
import OtherDetails from "./pages/EComInfoPreview/OtherDetails";
import FinTechProgram from "./pages/FinTechProgram";
import Subscriptions from "./pages/Subscriptions";
import Reports from "./pages/Reports";
import Support from "./pages/Support";
import AddONDCDetails from "./pages/AddONDCDetails/AddONDCDetails";
import AddONDCControlsDetails from "./pages/AddONDCControlInfo/AddONDCControlsDetails";
import EditONDCControlDetails from "./pages/AddONDCControlInfo/EditONDCControlDetails";
import IssueRaising from "./pages/IssueRaising";
import ViewIssueDetails from "./pages/IssueRaising/PopupCards/ViewIssueDetails";
import EditCommerceInfo from "./pages/EditCommerceInfo";
import Guides from "./pages/Guides";
import EmptyBusinessList from "./pages/EmptyBusinessList";
import WebsiteIntegration from "./pages/WebsiteIntegration";
import CatalogBulkUpload from "./pages/CatalogBulkUpload";
import ProductcatalogList from "./pages/ProductcatalogList";
import UserAuth from "./pages/UserAuth/UserAuth";
import ManageReturns from "./pages/ManageReturns";
import CustomerGrievance from "./pages/CustomerGrievance";
import CustomerGrievanceFullfilment from "./pages/CustomerGrievanceFullfilment";
import CatalogBulkUploadNew from "./pages/CatalogBulkUploadNew";
import AddProductNew from "./pages/AddProductNew";
import EditProductNew from "./pages/EditProductNew";
import ProductPreviewNew from "./pages/ProductPreviewNew";
import AddONDCDetailsNew from "./pages/AddONDCDetailsNew";
import ActiveProductList from "./pages/ActiveProductList";
import PriceUpdateTab from "./pages/Inventory/Tabs/PriceUpdateTab";
import AllStockTab from "./pages/Inventory/Tabs/AllStockTab";
import OutOfStockTab from "./pages/Inventory/Tabs/OutOfStockTab";
import LowStockTab from "./pages/Inventory/Tabs/LowStockTab";
import NotSellingTab from "./pages/Inventory/Tabs/NotSellingTab";
import BusinessMobileSideMenu from "./pages/BusinessMobileSideMenu";
import BranchCatalogPush from "./pages/BranchCatalogPush";
import SubBranchCalalog from "./pages/SubBranchCalalog";
import ProductCatalog from "./pages/ProductCatalog";
import OndcStoreLinks from "./pages/ShareLinks/OndcStore/OndcStoreLinks";
import DirectShareLink from "./pages/ShareLinks/DirectLink/DirectShareLink";

const routes = (
  <Suspense fallback={<p></p>}>
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/addBusiness" element={<BusinessOnboarding />} />
          <Route path="/addBusinessDetail" element={<Dashboard />} />
          <Route path="/:businessName/editProduct" element={<Catalog />} />
          <Route path="/:businessName/addProduct" element={<AddProduct />} />
          <Route path="/:businessName/copyProduct" element={<Catalog />} />

          {/* <Route path="/:businessName/catalog" element={<ProductList />} /> */}
          <Route
            path="/:businessName/catalog"
            element={<ProductCatalog status="active" />}
          />
          <Route
            path="/:businessName/branchCatalogPush"
            element={<BranchCatalogPush />}
          />
          <Route
            path="/:businessName/branchCatalog"
            element={<SubBranchCalalog />}
          />
          {/* <Route
            path="/:businessName/catalog"
            element={<ActiveProductList status="active" />}
          /> */}
          {/* <Route
            path="/:businessName/newCatalogPending"
            element={<ProductcatalogList />}
          />
          <Route
            path="/:businessName/newCatalogPendingExisting"
            element={<ProductcatalogList />}
          />
          <Route
            path="/:businessName/newCatalogRejected"
            element={<ProductcatalogList />}
          />
          <Route
            path="/:businessName/newCatalogRejectedExisting"
            element={<ProductcatalogList />}
          /> */}
          <Route
            path="/:businessName/ONDCParameters"
            element={<AddONDCDetails />}
          />
          <Route
            path="/:businessName/ONDCParametersNew"
            element={<AddONDCDetailsNew />}
          />

          <Route
            path="/:businessName/catalogPending"
            element={<ProductcatalogList status="pending" />}
          />
          <Route
            path="/:businessName/catalogPendingExisting"
            element={<ProductcatalogList status="pendingExisting" />}
          />
          <Route
            path="/:businessName/catalogRejected"
            element={<ProductcatalogList status="rejected" />}
          />
          <Route
            path="/:businessName/catalogRejectedExisting"
            element={<ProductcatalogList status="rejectedExisting" />}
          />

          <Route
            path="/:businessName/viewProduct"
            element={<ProductPreview />}
          />
          <Route
            path="/:businessName/viewProductNew"
            element={<ProductPreviewNew />}
          />
          <Route
            path="/addBusinessPreview"
            element={<BusinessDetailsPreview />}
          />
          <Route
            path="/addBusinessPending"
            element={<BusinessDetailsPreview />}
          />
          <Route
            path="/dashboardStartBusiness"
            element={<BusinessDashboardStart />}
          />
          <Route
            path="/:businessName/dashboard"
            element={<BusinessDashboard />}
          />
          <Route path="/businessListing" element={<ChooseBusiness />} />
          <Route path="/BusinessListingEmpty" element={<EmptyBusinessList />} />

          {/* <Route path="/:businessName/allStock" element={<AllStock />} /> */}
          {/* <Route path="/:businessName/lowStock" element={<LowStock />} /> */}
          {/* <Route path="/:businessName/priceUpdate" element={<PriceUpdate />} /> */}
          {/* <Route path="/:businessName/outOfStock" element={<OutOfStock />} /> */}

          <Route path="/:businessName/outOfStock" element={<OutOfStockTab />} />
          <Route path="/:businessName/allStock" element={<AllStockTab />} />
          <Route path="/:businessName/lowStock" element={<LowStockTab />} />
          <Route
            path="/:businessName/priceUpdate"
            element={<PriceUpdateTab />}
          />
          {/* <Route
            path="/:businessName/pausedInventory"
            element={<PausedInventory />}
          /> */}
          <Route
            path="/:businessName/pausedInventory"
            element={<NotSellingTab />}
          />

          {/* Add Ecommerce Info */}
          {/* <Route path="/addCommerceInfo" element={<AddCommerceInfo />} /> */}
          <Route path="/addCommerceInfo" element={<EditCommerceInfo type="Add" />} />
          <Route path="/editCommerceInfo" element={<EditCommerceInfo />} />

          {/* Add ONDC Controls Info */}
          <Route
            path="/:businessName/ONDCControls"
            element={<AddONDCControlsDetails />}
          />
          <Route
            path="/:businessName/editONDCControls"
            element={<EditONDCControlDetails />}
          />

          {/* Coupons and Discounts */}
          <Route
            path="/:businessName/couponsDiscount"
            element={<CouponsDiscount />}
          />
          {/* CashBack */}
          <Route path="/:businessName/cashBack" element={<CashBack />} />
          {/* Prefered Discounts */}
          <Route
            path="/:businessName/preferredDiscounts"
            element={<PreferredDiscounts />}
          />

          {/* Orders */}
          <Route path="/:businessName/orders" element={<Orders />} />
          <Route
            path="/:businessName/manageReturns"
            element={<ManageReturns />}
          />
          {/* IssueRaising */}
          <Route
            path="/:businessName/issueRaising"
            element={<IssueRaising />}
          />
          {/* Paycart Orders */}
          <Route
            path="/:businessName/easyCartOrders"
            element={<PaycartOrders />}
          />

          {/* Affiliate Marketing Dashboard */}
          <Route
            path="/:businessName/affiliateMarketing"
            element={<AffiliateMarketingDashboard />}
          />

          {/* Ecommerce Info Preview */}
          <Route
            path="/:businessName/warehouseDetail"
            element={<WarehouseDetail />}
          />

          <Route
            path="/:businessName/deliveryMethods"
            element={<LogisticsManagement />}
          />

          <Route path="/:businessName/bankInfo" element={<BankDetails />} />

          <Route
            path="/:businessName/discountDetails"
            element={<BusinessDiscounts />}
          />

          <Route
            path="/:businessName/otherDetails"
            element={<OtherDetails />}
          />

          <Route path="/:businessName/managers" element={<ManageDelegates />} />

          <Route path="/:businessName/crmAnalysis" element={<CRMMarketing />} />

          <Route
            path="/:businessName/fintechB2BCS"
            element={<FinTechProgram />}
          />
          <Route
            path="/:businessName/subscriptions"
            element={<Subscriptions />}
          />
          <Route path="/:businessName/reports" element={<Reports />} />
          <Route path="/:businessName/support" element={<Support />} />

          <Route
            path="/:businessName/issueRaisingDetail"
            element={<ViewIssueDetails />}
          />

          <Route path="/howToVideoGuides" element={<Guides />} />

          <Route
            path="/:businessName/websiteintegration"
            element={<WebsiteIntegration />}
          />

          <Route
            path="/:businessName/catalogBulkUpload"
            // element={<CatalogBulkUploadNew />}
            element={<CatalogBulkUpload />}
          />

          {/* <Route
            path="/:businessName/copyCatalogBulkUpload"
            element={<CatalogBulkUpload />}
          /> */}

          <Route path="/userAuth" element={<UserAuth />} />

          <Route
            path="/:businessName/igmIssuesItem"
            element={<CustomerGrievance status="open" />}
          />

          <Route
            path="/:businessName/igmIssuesFulfillment"
            element={
              <CustomerGrievanceFullfilment
                category="FULFILLMENT"
                status="open"
              />
            }
          />
          <Route
            path="/:businessName/addProductNew"
            element={<AddProductNew />}
          />
          <Route
            path="/:businessName/editProductNew"
            element={<EditProductNew />}
          />
          <Route
            path="/:businessName/copyProductNew"
            element={<EditProductNew />}
          />
          <Route
            path="/:businessName/mobileSideMenu"
            element={<BusinessMobileSideMenu />}
          />

          <Route
            path="/:businessName/ondcShareLink"
            element={<OndcStoreLinks />}
          />

          <Route
            path="/:businessName/directStoreLink"
            element={<DirectShareLink />}
          />

          {/* URL to Base64 Testing */}
          {/* <Route path="/urlToBase64Test" element={<URLtoBase64Test />} /> */}
        </Routes>
      </ScrollToTop>
    </Router>
  </Suspense>
);

function App() {
  return <div className="main-wrapper">{routes}</div>;
}

export default App;
