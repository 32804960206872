import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import FormInputBox from "../../../components/Input/FormInputBox";
import {
  blackColor,
  blueColor02,
  errorTextColor,
  greyColor5,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import DashboardStepper from "../../Dashboard/DashboardStepper";
import {
  CATALOG_ADD_PRODUCT_STEP_LIST,
  CATALOG_STEP_LIST,
} from "../../../utils/appData";
import DateInput from "../../../components/Input/DateInput";
import moment from "moment";
import ErrorHighlight from "../../../components/ErrorHighlight/ErrorHighlight";
import CatalogActionBtns from "../../../components/Buttons/CatalogActionBtns";

function ProductAvailabilityForm({
  data,
  onValueChange,
  currentPage,
  gotoNext,
  goBack,
  productName,
  isAddProduct,
  onCancelClick,
  isCopyProduct,
  handleTabChange,
  tabTitle,
  showPreviewBtn,
  previewErrorMsg,
  handlePreviewSubmit,
}) {
  const isAlertQtyValid = () => {
    if (data?.alertQty === "" || data?.availabelQty === "") {
      return "none";
    }

    const alertQty = Number(data?.alertQty);
    const availableQty = Number(data?.availabelQty);

    return alertQty < availableQty ? "valid" : "invalid";
  };

  const isMinOrderValid = () => {
    if (data?.minQty === "" || data?.maxQty === "") {
      return "none";
    }

    const minQty = Number(data?.minQty);
    const maxQty = Number(data?.maxQty);

    return minQty <= maxQty ? "valid" : "invalid";
  };

  const isAlertMinOrderValid = () => {
    if (data?.minQty === "" || data?.alertQty === "") {
      return "none";
    }

    const minQty = Number(data?.minQty);
    const alertQty = Number(data?.alertQty);

    return alertQty >= minQty ? "valid" : "invalid";
  };

  const isAvailableQtyValid = () => {
    if (
      data?.availabelQty === "" ||
      data?.alertQty === "" ||
      data?.maxQty === ""
    ) {
      return "none";
    }

    const maxQty = Number(data?.maxQty);
    const alertQty = Number(data?.alertQty);
    const availabelQty = Number(data?.availabelQty);

    return availabelQty >= maxQty && availabelQty >= alertQty
      ? "valid"
      : "invalid";
  };

  const isStartEndDateValid = () => {
    if (data?.startDate === "" || data?.endDate === "") {
      return "none";
    }

    const startDate = data?.startDate;
    const endDate = data?.endDate;

    return moment(startDate)?.isSameOrBefore(moment(endDate))
      ? "valid"
      : "invalid";
  };

  return (
    <Box sx={styles.card}>
      {/* <DashboardStepper
        stepList={
          isCopyProduct ? CATALOG_ADD_PRODUCT_STEP_LIST : CATALOG_STEP_LIST
        }
        currentPage={currentPage}
        isAddProduct
        handleTabChange={(destinationTab) =>
          handleTabChange(
            currentPage,
            isCopyProduct ? CATALOG_ADD_PRODUCT_STEP_LIST : CATALOG_STEP_LIST,
            destinationTab
          )
        }
      /> */}
      <Typography
        component="h6"
        fontSize={{ xs: 15, md: 15 }}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        mb={"10px !important"}
        mt={{ xs: "0px !important", md: "15px !important" }}
      >
        Product Availablity
      </Typography>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
          gap: { xs: "0px", md: "12px" },
          mb: "20px",
        }}
      >
        <Typography
          component="h5"
          fontSize={{ xs: 13, md: 14 }}
          color={blackShade05}
          fontWeight={500}
          mb="3px !important"
        >
          Is the Product Available for selling online?
        </Typography>

        <Box>
          <RadiosBtnWithLabel
            label="Yes"
            checked={data?.availability === "Yes"}
            onValuesChanged={() => {
              onValueChange("availability", "Yes");
            }}
          />

          <RadiosBtnWithLabel
            label="No"
            checked={data?.availability === "No"}
            onValuesChanged={() => {
              onValueChange("availability", "No");
            }}
          />
        </Box>
      </Box> */}
      <Grid container rowSpacing={2} columnSpacing={{ xs: 2, md: 3 }}>
        <Grid item xs={6} md={3}>
          <FormInputBox
            label="Available Quantity"
            placeholder=""
            required
            value={data?.availabelQty}
            onChange={({ target }) => {
              const value = target?.value?.replace(/[^0-9]/g, "");
              if (value.length <= 7) {
                onValueChange("availabelQty", value);
              }
            }}
            error={data?.errors?.availabelQty && "Enter Availabel Qty"}
            // error={
            //   data?.errors?.availabelQty
            //     ? "Enter Availabel Qty"
            //     : data?.errors?.availabilityError
            //     ? data?.errors?.availabilityError
            //     : ""
            // }
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <FormInputBox
            label="Alert Quantity"
            placeholder=""
            required
            value={data?.alertQty}
            onChange={({ target }) => {
              const value = target?.value?.replace(/[^0-9]/g, "");
              if (value.length <= 7) {
                onValueChange("alertQty", value);
              }
            }}
            error={data?.errors?.alertQty && "Enter alert quantity"}
            // error={
            //   data?.errors?.alertQty
            //     ? "Enter alert quantity"
            //     : data?.errors?.alertQtyError
            //     ? data?.errors?.alertQtyError
            //     : ""
            // }
          />
        </Grid>
      </Grid>
      <Box mt={{ xs: 2, md: 0 }}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 2, md: 3 }}>
          <Grid item xs={6} md={3}>
            <FormInputBox
              label="Min Quantity Per Order"
              placeholder=""
              required
              value={data?.minQty}
              onChange={({ target }) => {
                const value = target?.value?.replace(/[^0-9]/g, "");
                if (value.length <= 7) {
                  onValueChange("minQty", value);
                }
              }}
              error={data?.errors?.minQty && "Enter min quantity"}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <FormInputBox
              label="Max Quantity Per Order"
              placeholder=""
              required
              value={data?.maxQty}
              onChange={({ target }) => {
                const value = target?.value?.replace(/[^0-9]/g, "");
                if (value.length <= 7) {
                  onValueChange("maxQty", value);
                }
              }}
              error={data?.errors?.maxQty && "Enter max quantity"}
              // error={
              //   data?.errors?.maxQty
              //     ? "Enter max quantity"
              //     : data?.errors?.maxQtyError
              //     ? data?.errors?.maxQtyError
              //     : ""
              // }
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={{ xs: 2, md: 0 }}>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={6} md={3}>
            <Box mt={{ xs: 2, md: 0 }}>
              <DateInput
                label="Start Date"
                date={moment(data?.startDate).format("yyyy-MM-DD")}
                onDateChange={({ target }) => {
                  console.log(
                    target.value,
                    new Date(target.value).toISOString()
                  );
                  // onValueChange("startDate", moment(target.value).valueOf());
                  onValueChange(
                    "startDate",
                    new Date(target.value).toISOString()
                  );
                }}
                error={data?.errors?.startDate && "Select start date"}
              />
            </Box>
          </Grid>

          <Grid item xs={6} md={3}>
            <Box mt={{ xs: 2, md: 0 }}>
              <DateInput
                label="End Date"
                date={moment(data?.endDate).format("yyyy-MM-DD")}
                onDateChange={({ target }) => {
                  console.log(
                    target.value,
                    new Date(target.value).toISOString()
                  );
                  // onValueChange("endDate", moment(target.value).valueOf());
                  onValueChange(
                    "endDate",
                    new Date(target.value).toISOString()
                  );
                }}
                error={data?.errors?.endDate && "Select end date"}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3.8}>
            <ErrorHighlight
              status={isAlertQtyValid()}
              text="Alert quantity should be less than Available quantity"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <ErrorHighlight
              status={isMinOrderValid()}
              text="Min order quantity must be less than Max order quantity"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3.8}>
            <ErrorHighlight
              status={isAlertMinOrderValid()}
              text="Alert quantity must be more than Min order quantity"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ErrorHighlight
              status={isStartEndDateValid()}
              text="Start date should be less than End date"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <ErrorHighlight
              status={isAvailableQtyValid()}
              text="Available Quantity should be greater than Alert Quantity & Maximum Quantity Per Order"
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt="20px">
        <CatalogActionBtns
          onBackClick={goBack}
          onSaveClick={() => {}}
          onNextClick={gotoNext}
          onCancelClick={onCancelClick}
          showBackBtn
          hideSaveBtn
          backBtnText="Previous"
          // Preview Btn
          showPreviewBtn={showPreviewBtn}
          onPreviewSubmitClick={handlePreviewSubmit}
        />
      </Box>
      <Typography
        fontSize={12}
        fontWeight={500}
        mt={1}
        textAlign="right"
        color={errorTextColor}
      >
        {previewErrorMsg}
      </Typography>
    </Box>
  );
}

export default ProductAvailabilityForm;

const styles = {
  card: {
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: { xs: "8px", md: "13px" },
    p: { xs: "12px", md: "20px" },
    m: { xs: "15px 0 25px", md: "0px 0 25px" },
  },
};
