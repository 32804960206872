import moment from "moment";
import { getApiInstance } from "../../AppService";
import {
  CHECK_COSTBO_WEB_URL,
  CURRENT_DATE,
  FROM_DATE,
  GET_ALL_COUPONS,
  GET_ALL_CUSTOMERS,
  GET_ALL_PREFERRED_DISCOUNTS,
  GET_ALL_PROFILE_IDS,
  GET_BUSINESS_FOLLOWERS,
  GET_BUSINESS_REVIEW_SUMMARY,
  GET_CMS_PRODUCTS,
  GET_DASHBOARD_AFFILIATE_SALE_INFO,
  GET_DASHBOARD_AFFILIATE_SHARE_COUNT,
  GET_DASHBOARD_CASHBACK_COUNT,
  GET_DASHBOARD_CASHBACK_SUMMARY,
  GET_DASHBOARD_INVENTORY_SUMMARY,
  GET_DASHBOARD_ORDER_SUMMARY,
  GET_DASHBOARD_PAYCART_SUMMARY,
  GET_DASHBOARD_PREFERRED_DISCOUNT_COUNT,
  GET_DASHBOARD_PREFERRED_DISCOUNT_SUMMARY,
  GET_DASHBOARD_UNIQUE_AFFILIATE,
  VIEW_ALL_ORDERS_BY_BUSINESS,
  VIEW_BUSINESS_ACTIVITY,
} from "../../constant";
import { getProfileId } from "../../../utils/validationUtils";

export const viewAllOrderByBusinessApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(VIEW_ALL_ORDERS_BY_BUSINESS);
    console.log("viewAllOrderByBusinessApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Business Coupons
export const getAllBusinessCouponsApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(GET_ALL_COUPONS);
    console.log("getAllBusinessCouponsApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Business CRM customers
export const getAllBusinessCustomersApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(GET_ALL_CUSTOMERS, {
      params: {
        businessKey: data.businessId,
        pageNo: data.pageNo,
      },
    });
    console.log("getAllBusinessCustomersApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Business Views
export const getViewBusinessActivityApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(VIEW_BUSINESS_ACTIVITY, {
      params: {
        businessId: data?.businessId,
        activityName: "viewActiveBusiness",
      },
    });
    console.log("getViewBusinessActivityApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Business Followers
export const getBusinessFollowersApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(GET_BUSINESS_FOLLOWERS, {
      params: {
        businessId: data?.businessId,
      },
    });
    console.log("getBusinessFollowersApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Business Review Summary
export const getBusinessReviewSummaryApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(GET_BUSINESS_REVIEW_SUMMARY, {
      params: {
        params: data?.businessId,
      },
    });
    console.log("getBusinessReviewSummaryApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Business CMS Pending Prodicts
export const getCMSPendingProductsApi = async (data) => {
  try {
    const TEMP_TOKEN =
      "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiIrOTE5ODQ1NTcwODY0Iiwic2NvcGUiOlsid3JpdGUiLCJyZWFkIl0sInJhbmRvbU5vIjoiZTFjZTdkM2UyOWU2NGZlYThiZmIwNWM1ZDVjZTgyMmYiLCJleHAiOjE2ODg2MTk0MzgsImlhdCI6MTY4ODUzMzAzOCwiYXV0aG9yaXRpZXMiOlsiUk9MRV9DTVNBR0VOVCJdLCJqdGkiOiJhYWRkZDAyNy0wYzJjLTQ3OTgtYTZiNi1hNTZhNWQzN2U0Y2MiLCJjbGllbnRfaWQiOiJidXNpbmVzc19hcHAifQ.OJyyQq1HAEI9k4X26uG-pmLMrJ206fCmwyLcKN-K9MikuNSaicBwL7P9OmvzZBwr1gihEe5m-20V3t5pwekQiENzz_0ChTrssnjhH5Iqow6oO7LNAfXvRyvY65A4IOfoZ0GwhdQyT1FEcXlP7sIX-m5m7zVsGMGlYxw8-IXnlqY3tdD3WQliWvC0gKdR0_DcCHmxFGJxDs27ujxHD9cxLIgf390Pp03tQ1VulhLpuukDri7koS5Kf-BkKQGY9ilcFtmUMCfz12k5EAhMbn8XQwGYCVEYze_fTYsjL4eIuCO20-NjxXqZ3liz_Y80LNpy_ws_8xlMkZ64iTXjC2tmFg";

    const instance = getApiInstance({
      header: {
        Authorization: `Bearer ${TEMP_TOKEN}`,
      },
    });

    const response = await instance.get(GET_CMS_PRODUCTS + data?.businessId, {
      params: {
        status: data?.status,
        pageNo: data?.pageNo,
      },
    });
    console.log("getCMSPendingProductsApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Dashboard Order Summary
export const getDashboardOrderSummaryApi = async (businessId) => {
  try {
    // const TOKEN = getAccessToken()
    // const TOKEN = "bearereyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiIrOTE3Nzc3NzcxMDAxIiwic2NvcGUiOlsib3BlbmlkIl0sInJhbmRvbU5vIjoiMTE5M2RiNTk3NjU2NDU0M2I2ZjM2ZjIzODdiNGVjYzgiLCJleHAiOjE3MDMxMDI2NTksImlhdCI6MTY4NzMyMjY1OSwiYXV0aG9yaXRpZXMiOlsiQlVTSU5FU1NfUk9MRSJdLCJqdGkiOiI2Yjk1MjI1MC0yYWI5LTRjMzgtYWFhNC0wZmYyNjM4ODkxNTYiLCJjbGllbnRfaWQiOiJ3ZWJfYXBwIn0.Kaynf5iJKfdTiyX_Q9Jfs9ry4Uqp1iT99Sw6M0dAGUU5Tx2AfUiSS_C7Jqd2o2q-aQq_585TzdM7cjNYRJisLtR7QskD4Dh6A6SW6hJ03lWdhXr1wHKF_NUzzyd7qSzzDiaCJyz0kxQEkfyDIDHvHX2b5Ejmw_88EPhmgDqmquZfJhrhhyqo4Lmcmzbx_5vOEK1b3qlMhgz9NBwv7a2zBA5PHztVcIFF-DnVwiLWdPxQbuod85VJU7IDTG54sW20zmo0nb5ZKi4tGWH7C5JDDjMRG_5G8TYPiAkz86BKSx1TrOcezLuPqC0ccbtTuT52dyxrQRbwHqY8qtfUWWjS9A"

    const instance = getApiInstance({
      header: {
        businessId,
      },
    });

    const response = await instance.get(GET_DASHBOARD_ORDER_SUMMARY, {
      params: {
        from: FROM_DATE,
        to: moment().valueOf(),
      },
    });
    console.log("getDashboardOrderSummaryApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Dashboard Inventory Summary
export const getDashboardInventorySummaryApi = async (businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });

    const response = await instance.get(GET_DASHBOARD_INVENTORY_SUMMARY);
    console.log("getDashboardInventorySummaryApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Dashboard Affiliate Sale Info
export const getDashboardAffiliateSaleInfoApi = async (businessId) => {
  try {
    const instance = getApiInstance();

    const response = await instance.get(
      GET_DASHBOARD_AFFILIATE_SALE_INFO + businessId,
      {
        params: {
          fromDate: FROM_DATE,
          toDate: CURRENT_DATE,
        },
      }
    );

    console.log("getDashboardAffiliateSaleInfoApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Dashboard Unique Affiliate count
export const getDashboardUniqueAffiliateCountApi = async (businessId) => {
  try {
    const instance = getApiInstance();

    const response = await instance.get(
      GET_DASHBOARD_UNIQUE_AFFILIATE + businessId
    );

    console.log("getDashboardUniqueAffiliateCountApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Dashboard Affiliate Share Count
export const getDashboardAffiliateShareCountApi = async (businessId) => {
  try {
    const instance = getApiInstance();

    const response = await instance.get(
      GET_DASHBOARD_AFFILIATE_SHARE_COUNT + businessId,
      {
        params: {
          fromDate: FROM_DATE,
          toDate: CURRENT_DATE,
        },
      }
    );

    console.log("getDashboardAffiliateShareCountApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Paycart Summary
export const getDashboardPaycartSummaryApi = async (businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });

    const response = await instance.get(GET_DASHBOARD_PAYCART_SUMMARY, {
      params: {
        from: FROM_DATE,
        to: CURRENT_DATE,
      },
    });

    console.log("getDashboardPaycartSummaryApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Preferred Discount Summary
export const getDashboardPreferredDiscountSummaryApi = async (businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });

    const response = await instance.get(
      GET_DASHBOARD_PREFERRED_DISCOUNT_SUMMARY,
      {
        params: {
          from: FROM_DATE,
          to: moment().valueOf(),
        },
      }
    );

    console.log("getDashboardPreferredDiscountSummaryApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Preferred Discount Summary
export const getDashboardPreferredDiscountCountApi = async (businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });

    const response = await instance.get(
      GET_DASHBOARD_PREFERRED_DISCOUNT_COUNT,
      {
        params: {
          from: FROM_DATE,
          to: moment().valueOf(),
        },
      }
    );

    console.log("getDashboardPreferredDiscountCountApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Cashback Summary
export const getDashboardCashbackSummaryApi = async (businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });

    const response = await instance.get(GET_DASHBOARD_CASHBACK_SUMMARY, {
      params: {
        from: FROM_DATE,
        to: CURRENT_DATE,
      },
    });

    console.log("getDashboardCashbackSummaryApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Get Cashback Count
export const getDashboardCashbackCountApi = async (businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });

    const response = await instance.get(GET_DASHBOARD_CASHBACK_COUNT, {
      params: {
        from: FROM_DATE,
        to: CURRENT_DATE,
      },
    });

    console.log("getDashboardCashbackCountApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// checkCostboWebURL
export const checkCostboWebURL = async (costBoUrl) => {
  try {
    const instance = getApiInstance();

    const response = await instance.get(CHECK_COSTBO_WEB_URL, {
      params: {
        costboWebURL: costBoUrl,
      },
    });

    console.log("checkCostboWebURL", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
