import moment from "moment";
import { getProfileId, getUserDetails } from "./validationUtils";

export const addBusinessDetails = ({
  gstDetails,
  addressDetails,
  storeInfoDetails,
  businessDetails,
  ownerDetails,
}) => {
  const userDetails = getUserDetails();
  let xmpprandomNumber = Math.floor(100000 + Math.random() * 900000);

  const USER_LAT = localStorage.getItem("lat");
  const USER_LNG = localStorage.getItem("long");

  const logo = {
    name: storeInfoDetails?.name + ".jpeg",
    type: "logo",
    mimeType: "image/jpeg",
    extension: "jpeg",
    replicate: false,
    bytesString: businessDetails?.image,
  };

  let mainProducts = [];

  businessDetails?.mainProducts?.forEach((element, index) => {
    if (element !== "") {
      mainProducts.push({ code: "product" + index + 1, productName: element });
    }
  });

  let banners = [];

  businessDetails?.banners?.forEach((element, index) => {
    let randomNumber = Math.floor(100000 + Math.random() * 900000);
    let id = Number(index) + 1;
    if (element?.base64String) {
      banners.push({
        replicate: false,
        extension: "jpeg",
        name: `business_pic_${randomNumber}.jpeg`,
        mimeType: "image/jpeg",
        type: `bpic${id}-${randomNumber}`,
        bytesString: element?.base64String,
      });
    }
  });

  // Preparing Contact numbers
  const contactNoList = [
    ownerDetails?.phone,
    ownerDetails?.alternatePhone1,
    ownerDetails?.alternatePhone2,
  ];

  const contactNos = [];
  let numIndex = 1;

  if (ownerDetails) {
    for (const number of contactNoList) {
      console.log("Number:", number);
      if (number !== "" || number !== undefined) {
        contactNos.push({
          code: `Mobile${numIndex}`,
          number: "+91" + number,
        });

        numIndex += 1;
      }
    }
  }

  console.log("contactNos:", contactNos);

  // Preparing Email
  const emailList = [ownerDetails?.email, ownerDetails?.alternateEmail];
  const businessMailingAddress = emailList
    ?.filter((item) => item != "")
    ?.join(",");

  console.log("businessMailingAddress:", businessMailingAddress);

  const data = {
    name: storeInfoDetails?.name || "",
    xmppBusinessId: xmpprandomNumber,
    // xmppBusinessId: "th8bp",
    aliasName: gstDetails?.registeredBusinessName || "",
    businessWebURL: "",
    businessTaxDocuments: [],
    documentsURL: "",
    completePercentage: "",
    revenuePerYear: 0,
    status: "draft",
    description: businessDetails?.description || "",
    searchText: "",
    GSTNumber: gstDetails?.gstNumber || "",
    PANNumber: gstDetails?.panNumber || "",
    FSSAINumber: "",
    // businessMailingAddress: userDetails?.account?.email || "",
    businessMailingAddress: businessMailingAddress,
    rewardCurrency: businessDetails?.fssaiNumber || "", //FSSAI
    backgroundColor: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    hideChat: false,
    publicChatID: "",
    address: `${addressDetails?.address?.trim()} ${addressDetails?.city}-${
      addressDetails?.pinCode
    }, ${addressDetails?.state}, India`,
    distributionArea: "",
    noOfEmployees: "",
    subscriptionCode: "",
    // subscriptionCode: "indipa1",
    remoteAdd: "",
    costboWebUrl: "",
    mainBranch: "",
    businessHours: [
      {
        dayOfWeek: 1,
        from: "12:00 AM",
        to: "11:59 PM",
      },
      {
        dayOfWeek: 2,
        from: "12:00 AM",
        to: "11:59 PM",
      },
      {
        dayOfWeek: 3,
        from: "12:00 AM",
        to: "11:59 PM",
      },
      {
        dayOfWeek: 4,
        from: "12:00 AM",
        to: "11:59 PM",
      },
      {
        dayOfWeek: 5,
        from: "12:00 AM",
        to: "11:59 PM",
      },
      {
        dayOfWeek: 6,
        from: "12:00 AM",
        to: "11:59 PM",
      },
      {
        dayOfWeek: 7,
        from: "12:00 AM",
        to: "11:59 PM",
      },
    ],
    businessDocumentsURL: businessDetails ? banners : [],
    createdOn: "",
    updatedOn: "",
    createdBy: "",
    updatedBy: "",
    contactNos: contactNos,
    latitude: addressDetails?.lat || USER_LAT,
    longitude: addressDetails.lng || USER_LNG,
    logo: businessDetails ? logo : {},
    businessTypes: storeInfoDetails?.type || [],
    businessCategories: businessDetails?.category || [],
    mainProducts: businessDetails ? mainProducts : [],
    targetBusinessTypes: [
      {
        code: "BT6",
        name: "Consumers",
        status: "active",
      },
    ],
    deliveryMethods: [
      {
        code: "DM001",
        name: "Delivery/Fulfillment by Business",
        status: "active",
      },
    ],
    establishedOn: "",
    businessArea: [
      {
        code: "Country",
        value: "India",
      },
      {
        code: "State",
        value: addressDetails?.state || "",
      },
      {
        code: "City",
        value: addressDetails?.city || "",
      },
      {
        code: "pincode",
        value: addressDetails?.pinCode || "",
      },
      {
        code: "subAdminArea",
        value: "",
      },
      {
        code: "featurename",
        value: "",
      },
      {
        code: "locality",
        value: addressDetails?.landmark || addressDetails?.locality,
      },
      {
        code: "sublocality",
        value: addressDetails?.sublocality || addressDetails?.locality,
      },
      {
        code: "premises",
        value: "",
      },
      {
        code: "subThroughFare",
        value: "",
      },
      {
        code: "ThroughFare",
        value: "",
      },
      {
        code: "countryCode",
        value: "",
      },
      {
        code: "addressline one",
        value: addressDetails?.address || "",
      },
      {
        code: "addressline two",
        value: "",
      },
    ],
    owner: {
      profileId: getProfileId() || "",
      name: ownerDetails?.name || userDetails?.account?.name || "",
      phone: userDetails?.account?.phonenumber || "",
      email: userDetails?.account?.email || "",
      country: "India",
      status: "active",
      role: "Owner",
      picURL: userDetails?.account?.photoURL || "",
      type: 1,
      privileges: [],
    },
    locationMarker: {},
  };

  return data;
};

export const editBusinessDetails = ({
  gstDetails,
  addressDetails,
  storeInfoDetails,
  businessDetails,
  businessData,
  maintainDraft,
  ownerDetails,
}) => {
  const USER_LAT = localStorage.getItem("lat");
  const USER_LNG = localStorage.getItem("long");

  let mainProducts = [];
  businessDetails?.mainProducts?.forEach((element, index) => {
    if (element !== "") {
      mainProducts.push({ code: "product" + index + 1, productName: element });
    }
  });

  // Preparing Contact numbers
  const contactNoList = [
    ownerDetails?.phone,
    ownerDetails?.alternatePhone1,
    ownerDetails?.alternatePhone2,
  ];

  const contactNos = [];
  let numIndex = 1;

  for (const number of contactNoList) {
    if (number != "") {
      contactNos.push({
        code: `Mobile${numIndex}`,
        number: "+91" + number,
      });

      numIndex += 1;
    }
  }

  console.log("contactNos:", contactNos);

  // Preparing Email
  const emailList = [ownerDetails?.email, ownerDetails?.alternateEmail];
  const businessMailingAddress = emailList
    ?.filter((item) => item != "")
    ?.join(",");

  console.log("businessMailingAddress:", businessMailingAddress);

  const data = {
    id: businessData?.id,
    name: storeInfoDetails?.name,
    aliasName: gstDetails?.registeredBusinessName,
    businessWebURL: businessData?.businessWebURL,
    completePercentage: businessData?.completePercentage,
    revenuePerYear: businessData?.revenuePerYear,
    status: "draft",
    pannumber: gstDetails?.panNumber,
    gstnumber: gstDetails?.gstNumber,
    logoURL: businessData?.logoURL,
    description: businessDetails?.description,
    hideChat: businessData?.hideChat,
    publicChatID: businessData?.publicChatID,
    address: `${addressDetails?.address?.trim()} ${addressDetails?.city}-${
      addressDetails?.pinCode
    }, ${addressDetails?.state}, India`,
    latitude: addressDetails?.lat || USER_LAT,
    longitude: addressDetails.lng || USER_LNG,
    distributionArea: businessData?.distributionArea,
    establishedOn: businessData?.establishedOn,
    noOfEmployees: businessData?.noOfEmployees,
    subscriptionCode: businessData?.subscriptionCode,
    costboWebUrl: businessData?.costboWebURL,
    locationMarker: businessData?.locationMarker,
    mainBranch: businessData?.mainBranch,
    branchOrLocation: businessData?.branchOrLocation,
    costboNotes: businessData?.costboNotes,
    mainProducts: mainProducts,
    owner: { ...businessData?.owner, name: ownerDetails?.name },
    delegates: businessData?.delegates,
    businessTypes: storeInfoDetails?.type,
    targetBusinessTypes: businessData?.targetBusinessTypes,
    businessCategories: businessDetails?.category,
    // contactNos: businessData?.contactNos,
    contactNos: contactNos,
    searchText: businessData?.searchText,
    locationType: businessData?.locationType,
    mainBranchId: businessData?.mainBranchId,
    // businessMailingAddress: businessData?.businessMailingAddress,
    businessMailingAddress: businessMailingAddress,
    rewardCurrency: businessDetails?.fssaiNumber,
    backgroundColor: businessData?.backgroundColor,
    metaTitle: businessData?.metaTitle,
    metaDescription: businessData?.metaDescription,
    metaKeywords: businessData?.metaKeywords,
    hyperLocal: businessData?.hyperLocal,
    minRewardPercentage: businessData?.minRewardPercentage,
    maxRewardPercentage: businessData?.maxRewardPercentage,
    maxRewardAmount: businessData?.maxRewardAmount,
    minPurchaseAmount: businessData?.minPurchaseAmount,
    minRewardAmount: businessData?.minRewardAmount,
    maxPurchaseAmount: businessData?.maxPurchaseAmount,
    taxDocumentsURL: businessData?.taxDocumentsURL,
    documentsURL: businessData?.documentsURL,
    paymentMethods: businessData?.paymentMethods,
    deliveryMethods: businessData?.deliveryMethods,
    preferedCurrencies: businessData?.preferedCurrencies,
    shippingMethods: businessData?.shippingMethods,
    businessHours: businessData?.businessHours,
    businessArea: [
      {
        code: "Country",
        value: "India",
      },
      {
        code: "State",
        value: addressDetails?.state || "",
      },
      {
        code: "City",
        value: addressDetails?.city || "",
      },
      {
        code: "pincode",
        value: addressDetails?.pinCode || "",
      },
      {
        code: "subAdminArea",
        value: "",
      },
      {
        code: "featurename",
        value: "",
      },
      {
        code: "locality",
        value: addressDetails?.landmark || addressDetails?.locality,
      },
      {
        code: "sublocality",
        value: addressDetails?.sublocality || addressDetails?.locality,
      },
      {
        code: "premises",
        value: "",
      },
      {
        code: "subThroughFare",
        value: "",
      },
      {
        code: "ThroughFare",
        value: "",
      },
      {
        code: "countryCode",
        value: "",
      },
      {
        code: "addressline one",
        value: addressDetails?.address || "",
      },
      {
        code: "addressline two",
        value: "",
      },
    ],
    rewardEmpDetails: businessData?.rewardEmpDetails,
    updatedBy: null,
    maintainDraft,
  };
  return data;
};

export const prepareUpdateBusinessTimingData = ({
  businessTiming,
  businessData,
  deliveryMethods,
}) => {
  // console.log(
  //   "prepareUpdateBusinessTimingData_businessTiming:",
  //   businessTiming
  // );
  // console.log("prepareUpdateBusinessTimingData_businessData:", businessData);

  let businessHours = [
    {
      dayOfWeek: 1,
      from: "12:00 AM",
      to: "11:59 PM",
    },
    {
      dayOfWeek: 2,
      from: "12:00 AM",
      to: "11:59 PM",
    },
    {
      dayOfWeek: 3,
      from: "12:00 AM",
      to: "11:59 PM",
    },
    {
      dayOfWeek: 4,
      from: "12:00 AM",
      to: "11:59 PM",
    },
    {
      dayOfWeek: 5,
      from: "12:00 AM",
      to: "11:59 PM",
    },
    {
      dayOfWeek: 6,
      from: "12:00 AM",
      to: "11:59 PM",
    },
    {
      dayOfWeek: 7,
      from: "12:00 AM",
      to: "11:59 PM",
    },
  ];

  if (businessTiming?.businessTime === "all-days") {
    const updatedTiming = [];

    businessHours.forEach((item) => {
      updatedTiming.push({
        ...item,
        from: moment(businessTiming?.allDays?.from, "HH:mm").format("hh:mm A"),
        to: moment(businessTiming?.allDays?.to, "HH:mm").format("hh:mm A"),
      });
    });

    businessHours = updatedTiming;
  }

  if (businessTiming?.businessTime === "select-specific-days") {
    const updatedTiming = [];

    businessTiming?.businessHours.forEach((item) => {
      if (item?.isClosed) {
        updatedTiming.push({
          dayOfWeek: item?.dayOfWeek,
          from: moment(item?.from, "HH:mm").format("hh:mm A"),
          to: moment(item?.to, "HH:mm").format("hh:mm A"),
        });
      }
    });

    businessHours = updatedTiming;
  }

  // let postData = {
  //   id: businessData?.id,
  //   name: businessData?.name,
  //   aliasName: businessData?.aliasName,
  //   businessWebURL: businessData?.businessWebURL,
  //   completePercentage: businessData?.completePercentage,
  //   revenuePerYear: businessData?.revenuePerYear,
  //   status: businessData?.status,
  //   pannumber: businessData?.pannumber,
  //   gstnumber: businessData?.gstnumber,
  //   logoURL: businessData?.logoURL,
  //   description: businessData?.description,
  //   hideChat: businessData?.hideChat,
  //   publicChatID: businessData?.publicChatID,
  //   address: businessData?.address,
  //   latitude: businessData?.locationMarker?.latitude || "",
  //   longitude: businessData?.locationMarker?.longitude || "",
  //   distributionArea: businessData?.distributionArea,
  //   establishedOn: businessData?.establishedOn,
  //   noOfEmployees: businessData?.noOfEmployees,
  //   subscriptionCode: businessData?.subscriptionCode,
  //   costboWebUrl: businessData?.costboWebURL,
  //   locationMarker: businessData?.locationMarker,
  //   mainBranch: businessData?.mainBranch,
  //   branchOrLocation: businessData?.branchOrLocation,
  //   costboNotes: null,
  //   mainProducts: businessData?.mainProducts,
  //   owner: businessData?.owner,
  //   delegates: businessData?.delegates,
  //   businessTypes: businessData?.businessTypes,
  //   targetBusinessTypes: businessData?.targetBusinessTypes,
  //   businessCategories: businessData?.businessCategories,
  //   contactNos: businessData?.contactNos,
  //   searchText: businessData?.searchText,
  //   locationType: businessData?.locationType,
  //   mainBranchId: businessData?.mainBranchId,
  //   businessMailingAddress: businessData?.businessMailingAddress,
  //   rewardCurrency: businessData?.rewardCurrency,
  //   backgroundColor: businessData?.backgroundColor,
  //   metaTitle: businessData?.metaTitle,
  //   metaDescription: businessData?.metaDescription,
  //   metaKeywords: businessData?.metaKeywords,
  //   hyperLocal: businessData?.hyperLocal,
  //   minRewardPercentage: businessData?.minRewardPercentage,
  //   maxRewardPercentage: businessData?.maxRewardPercentage,
  //   maxRewardAmount: businessData?.maxRewardAmount,
  //   minPurchaseAmount: businessData?.minPurchaseAmount,
  //   minRewardAmount: businessData?.minRewardAmount,
  //   maxPurchaseAmount: businessData?.maxPurchaseAmount,
  //   taxDocumentsURL: businessData?.taxDocumentsURL,
  //   documentsURL: businessData?.documentsURL,
  //   paymentMethods: businessData?.paymentMethods,
  //   deliveryMethods: businessData?.deliveryMethods,
  //   preferedCurrencies: businessData?.preferedCurrencies,
  //   shippingMethods: businessData?.shippingMethods,
  //   businessHours,
  //   businessArea: businessData?.businessArea,
  //   rewardEmpDetails: businessData?.rewardEmpDetails,
  //   updatedBy: businessData?.updatedBy,
  //   maintainDraft: businessData?.maintainDraft,
  // };
  let postData = {
    businessId: businessData?.id,
    businessHours,
    deliveryMethods: businessData?.deliveryMethods,
  };

  const selectedMethods = deliveryMethods?.deliveryMethods;

  //Updating Store Pickup details of its seleted
  if (selectedMethods?.includes("Store Pickup")) {
    console.log("USER SELECTED STORE PICKUP");

    const deliveryMethods = [
      {
        code: "DM001",
        name: "Delivery/Fulfillment by Business",
        status: "active",
      },
      {
        code: "DM002",
        name: "Store Pickup",
        status: "active",
      },
    ];

    postData = { ...postData, deliveryMethods };
  } else {
    console.log("USER NOT SELECTED STORE PICKUP");

    const deliveryMethods = [
      {
        code: "DM001",
        name: "Delivery/Fulfillment by Business",
        status: "active",
      },
    ];

    postData = { ...postData, deliveryMethods };
  }

  // console.log("prepareUpdateBusinessTimingData_postData:", postData);

  return postData;
};
