import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import FormInputBox from "../../../components/Input/FormInputBox";
import {
  blackColor,
  blackShade21,
  blueColor02,
  blueColor04,
  greyColor24,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import DashboardStepper from "../../Dashboard/DashboardStepper";
import { ECOMMERCE_STEP_LIST } from "../../../utils/appData";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import TextAreaComponent from "../../../components/Input/TextAreaComponent";
import {
  ECOMINPUTSUPPORTLABEL,
  emailAddress,
  whatsAppNumber,
} from "../../../configs/Constants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getActiveBusinessInfo } from "../../../reducers/businessPreview/businessPreviewSlice";
import { stickyHeader } from "../../../utils/styles";

function AddOtherDetails({
  data,
  setData,
  onValueChange,
  currentPage,
  gotoNext,
  goBack,
  handleCancel,
}) {
  const dispatch = useDispatch();

  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
    }
  }, []);

  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Support+Request.+`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };
  return (
    <Box>
      {/* <Box sx={stickyHeader} pb={1.5}>
        <Typography
          component="h6"
          fontSize={{ xs: 15, md: 17 }}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          mb={"4px !important"}
        >
          E-Commerce Details
        </Typography>

        <Typography
          variant="h6"
          fontSize={13}
          fontWeight={500}
          color={blackShade21}
          display="inline"
          mb="15px"
        >
          {ECOMINPUTSUPPORTLABEL}{" "}
          <Typography
            // variant="body1"
            fontSize={13}
            fontWeight={500}
            color={blueColor04}
            display="inline"
            // onclick={handleChatClick}
            component="a"
            href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Support+Request.+&fs=1&to=${emailAddress}&tf=cm`}
            target="_blank"
          >
            Email
          </Typography>
          {"  "}
          or{" "}
          <Typography
            // variant="body1"
            fontSize={13}
            fontWeight={500}
            color={blueColor04}
            sx={{ cursor: "pointer" }}
            display="inline"
            onClick={handleChatClick}
          >
            Whatsapp
          </Typography>
        </Typography>
      </Box> */}

      <Box sx={styles.card}>
        {/* <DashboardStepper
          stepList={ECOMMERCE_STEP_LIST}
          currentPage={currentPage}
          isAddProduct
        /> */}

        <Box m="10px 0 10px 0">
          <Typography
            component="h6"
            fontSize={{ xs: 15, md: 15 }}
            color={blackColor}
            fontWeight={600}
            lineHeight="20px"
            mb="6px"
          >
            Returns & Other Info
          </Typography>

          <Grid container rowSpacing={1} columnSpacing={3}>
            <Grid item xs={12} md={2.5}>
              <FormInputBox
                label="Minimum Order Amount"
                placeholder="Enter Min Order Amount"
                startIcon="₹"
                value={data?.minOrderAmount}
                onChange={({ target }) => {
                  const value = target?.value?.replace(/[^0-9]/g, "");

                  setData((prevData) => ({
                    ...prevData,
                    minOrderAmount: value,
                  }));
                }}
                error={data?.errors?.minOrderAmount}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextAreaComponent
                required
                label="Return Policy"
                placeholder="Limit to 150 characters"
                value={data?.returnPolicy}
                onChange={({ target }) => {
                  onValueChange("returnPolicy", target.value);
                }}
                error={data?.errors?.returnPolicy}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextAreaComponent
                label="Special Notes"
                placeholder="Limit to 150 characters"
                value={data?.notes}
                onChange={({ target }) => {
                  onValueChange("notes", target.value);
                }}
                error={data?.errors?.notes}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt="20px">
          <ActionButtons
            onBackClick={goBack}
            onSaveClick={() => {}}
            onNextClick={gotoNext}
            onCancelClick={handleCancel}
            showBackBtn
            hideSaveBtn
            backBtnText="Previous"
            nextBtnText={"Preview"}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default AddOtherDetails;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    p: "5px 20px 15px 20px",
    m: { xs: "0 0 25px", md: "0 0 25px" },
  },
};
