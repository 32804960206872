import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Snackbar,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  BGColor02,
  blackColor,
  blackShade05,
  blackShade19,
  blackShade21,
  blackShade22,
  blueColor03,
  blueColor04,
  borderTextBoxColor,
  greyColor40,
  greyColor41,
  greyColor42,
  greyColor47,
  greyColor5,
  greyColor8,
  greyColor9,
  heroSectionText,
  lightRedColor,
  logoImgborder,
  mobilePrimary,
  oldPriceColor,
  redColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { scrollBox } from "../../utils/styles";
import {
  CATALOG_BRANCH_PUSH_HEADER,
  CATALOG_PUSH_TABS,
} from "../../utils/data";
import {
  dynamicSort,
  getColorChip,
  getOrderStatusText,
} from "../../utils/helper";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import { textOneLines } from "../../utils/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  pushMainBranchProductsToSubBranch,
  updateMainProductList,
} from "../../reducers/multiBranchCatalog/multiBranchCatalogSlice";
import { calculatePercentageOff } from "../../utils/validationHelper";
import MuiAlert from "@mui/material/Alert";
import HeaderInfoRow from "./Card/HeaderInfoRow";
import ProductInfoRowCard from "./Card/ProductInfoRowCard";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductFilterCard from "../SubBranchCalalog/Components/ProductFilterCard";
import SearchInfoCard from "../SubBranchCalalog/Components/SearchInfoCard";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import {
  getONDCProductsAPI,
  searchBusinessProductsAPI,
} from "../../service/api_calls/BusinessPreview/BusinessPreviewAPI";
import {
  clearSearchProducts,
  getONDCCategories,
  getProductCategories,
} from "../../reducers/businessPreview/businessPreviewSlice";
import SearchBoxInput from "../../components/Input/SearchBoxInput";
import moment from "moment";
import MobileTab from "../../components/MobileTabs/MobileTab";
import MultiProductPushPopup from "./Popup/MultiProductPushPopup";
import { getSubBranchBusinessDetails } from "../../reducers/multiBranchCatalog/multiBranchCatalogHelper";
import SingleProductPushPopup from "./Popup/SingleProductPushPopup";
import MobileCategoryFilter from "./Popup/MobileCategoryFilter";

import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SubBranchCard from "./Card/SubBranchCard";
import PushMultipleProductPopup from "./Popup/PushMultipleProductPopup";
import {
  getBranchInfoAPI,
  getProdcutsSyncStatusAPI,
} from "../../service/api_calls/multiBranchCatalog/multiBranchCatalogAPIs";
import EmptyCard from "../../components/Alerts/EmptyCard";

const BranchCatalogPushContent = ({
  activeBusinessInfo,
  mainBranchProducts,
  productLoader,
  branchPushLoader,
  inventorySummary,
  fetchMainBranchProducts,
  hasMoreData,
  setHasMoreData,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const dispatch = useDispatch();

  const [selectedBranchInfo, setSelectedBranchInfo] = useState(null);

  const [openMultiProductPopup, setOpenMultiProductPopup] = useState(false);
  const [openSingleProductPopup, setOpenSingleProductPopup] = useState({
    show: false,
    productInfo: null,
  });

  const [openMobileFilterModel, setOpenMobileFilterModel] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [openSnack, setOpenSnack] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [isClearSearch, setIsClearSearch] = useState(false);
  const [filterType, setFilterType] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);

  const [ondcCategoryList, setOndcCategoryList] = useState([]);
  const [ondcSubCatList, setOndcSubCatList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedSubCat, setSelectedSubCat] = useState("All");

  const [selectedOndcCategory, setSelectedOndcCategory] = useState("All");
  const [selectedOndcSubCat, setSelectedOndcSubCat] = useState("All");

  const [showONDCFilter, setShowONDCFilter] = useState(false);
  const [showWebstoreFilter, setShowWebstoreFilter] = useState(false);

  const [isClearFilter, setIsClearFilter] = useState(null);
  const [filterError, setFilterError] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);

  const ondcFilterRef = useRef(null);
  const webstoreFilterRef = useRef(null);

  const [subBranchDataList, setSubBranchDataList] = useState([]);

  const { productCategory, ondcCategory } = useSelector(
    (state) => state.businessPreview
  );

  // Handle Tab Value Change
  const handleTabChange = (event, newValue) => {
    selectAllProductSelect(true);
    setSelectedBranchInfo(null);
    setTabValue(newValue);
    onCloseMultiProductModel();
  };

  // Select All Product Rows
  const selectAllProductSelect = (isUnselectAll) => {
    const productList = [...mainBranchProducts];
    const updatedList = [];

    const isAllSelected = mainBranchProducts?.every((item) => item?.isSelected);

    productList.forEach((item) => {
      updatedList.push({
        ...item,
        isSelected: isUnselectAll ? false : !isAllSelected,
      });
    });

    dispatch(updateMainProductList({ updatedList }));
  };

  // Select Product Row
  const onProductSelect = (index) => {
    const productList = [...mainBranchProducts];

    productList[index] = {
      ...productList[index],
      isSelected: !productList[index]?.isSelected,
    };

    dispatch(updateMainProductList({ updatedList: productList }));
  };

  // Handle Multibranch Push Click
  const handleMultiBranchPushClick = () => {
    setOpenMultiProductPopup(true);
  };

  // Push Products to all Branches
  const onPushToAllBranch = () => {
    const branchInfoList = activeBusinessInfo?.branchInfo || [];

    const coordinates = activeBusinessInfo?.loc || null;
    const latitude = coordinates?.y || "";
    const longitude = coordinates?.x || "";

    const mainBranchBusinessId = activeBusinessInfo?.id;

    const selectedProducts = selectedRowCount;

    dispatch(
      pushMainBranchProductsToSubBranch({
        mainBranchBusinessId,
        mainBranchProducts,
        branchInfoList,
        latitude,
        longitude,
      })
    ).then((res) => {
      console.log("res_____", res);
      setSuccessMsg(
        `${selectedProducts} Products pushed to ${branchInfoList?.length} Branches`
      );
      setOpenSnack(true);
    });
  };

  // Push Products to Single Branches
  const onPushToSingleBranch = () => {
    // const branchInfoList = activeBusinessInfo?.branchInfo || [];

    let selectedSubBranch = subBranchDataList?.find((item) => item?.isSelected);
    // selectedSubBranch = {
    //   ...selectedSubBranch,
    //   branchId: selectedSubBranch?.id,
    // };

    const coordinates = activeBusinessInfo?.loc || null;
    const latitude = coordinates?.y || "";
    const longitude = coordinates?.x || "";

    const mainBranchBusinessId = activeBusinessInfo?.id;
    const selectedProducts = selectedRowCount;

    dispatch(
      pushMainBranchProductsToSubBranch({
        mainBranchBusinessId,
        mainBranchProducts,
        selectedSubBranch,
        latitude,
        longitude,
      })
    ).then((res) => {
      console.log("res_____", res);
      setSuccessMsg(
        `${selectedProducts} Products pushed to ${selectedSubBranch?.businessName} Branch`
      );
      setOpenSnack(true);
      onCloseMultiProductModel();
    });
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setSuccessMsg("");
  };

  const clearFilter = () => {
    setSelectedCategory("All");
    setSelectedSubCat("All");
    setSubCatList([]);
    setSelectedOndcCategory("All");
    setSelectedOndcSubCat("All");
    setOndcSubCatList([]);

    setIsClearFilter(null);
  };

  //search for products
  const handleSearch = async () => {
    if (!searchQuery) return;
    clearFilter();
    const businessId = sessionStorage.getItem("selectedBusinessId");
    setSelectedCategory("All");
    setSelectedSubCat("All");

    const searchRes = await searchBusinessProductsAPI({
      query: searchQuery,
      businessId: businessId,
    });

    const searchProducts = searchRes?.details;

    if (searchProducts?.length > 0) {
      const formattedProducts = searchProducts?.map((item) => ({
        addedDate: null,
        lastUpdateDate: null,
        ondcEnabled: item?.ondcEnabled,

        origin: "",
        originalPrice: item?.productOriginalPrice,
        productId: item?.id,
        productName: item?.productName,
        productPhoto: item?.productPhoto,
        salePrice: item?.productSalePrice,
      }));

      console.log("SEARCH_RES___:", formattedProducts);
      setFilterType("");
      setIsClearSearch(true);
      setHasMoreData(false);
      dispatch(updateMainProductList({ updatedList: [...formattedProducts] }));
    }
  };

  // Clear Search
  const clearSearch = (isUpdateSyncStatus) => {
    dispatch(clearSearchProducts());
    fetchMainBranchProducts(true, null, selectedBranchInfo?.branchId || "");

    setSearchQuery("");
    setIsClearSearch(false);
    setIsClearFilter(false);
    setFilterType("");
    onOndcFilterClose();
  };

  // Prepare list data for filter dropdown
  const formatCategory = (category, setValue, valueSelector) => {
    const sortedArray = category?.slice()?.sort(dynamicSort(valueSelector));
    const catList = sortedArray?.map((item) => {
      return { ...item, label: item?.[valueSelector] };
    });

    setValue([{ label: "All" }, ...catList]);
  };

  // Handle Web Store Category Change
  const handleWebstoreCategoryChange = (data) => {
    setSelectedCategory(data);
    setSelectedSubCat("All");

    if (data === "All") {
      setSubCatList([]);
      return;
    }

    setFilterError("");

    const CategoryData = categoryList?.filter((item) => {
      return item?.value === data;
    });

    formatCategory(CategoryData?.[0]?.subCat, setSubCatList, "value");
  };

  // Handle Web Store Sub-Category Change
  const handleWebstoreSubCategoryChange = (data) => {
    setSelectedSubCat(data);
  };

  // Handle ONDC Category Change
  const handleOndcCategoryChange = (data) => {
    setSelectedOndcCategory(data);
    setSelectedOndcSubCat("All");

    if (data === "All") {
      setOndcSubCatList([]);
      return;
    }

    setFilterError("");

    const categoryData = ondcCategoryList?.filter((item) => {
      return item?.ondcValue === data;
    });

    formatCategory(
      categoryData?.[0]?.productSubCategory,
      setOndcSubCatList,
      "ondcSubValue"
    );
  };

  // Handle ONDC Sub-Category Change
  const handleOndcSubCategoryChange = (data) => {
    setSelectedOndcSubCat(data);
  };

  // Apply ONDC Filter
  const handleOndcFilter = async () => {
    setFilterError("");
    if (ondcCategoryList?.length === 1) {
      setFilterError("ONDC category not assigned for any of the products");

      return;
    }
    if (selectedOndcCategory === "All") {
      setFilterError("Select the Main Category");
      return;
    }

    setFilterLoader(true);
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const categoryData = ondcCategoryList?.filter((item) => {
      return item?.ondcValue === selectedOndcCategory;
    });

    const subCategoryData = ondcSubCatList?.filter((item) => {
      return item?.ondcSubValue === selectedOndcSubCat;
    });

    const ondcProductList = await getONDCProductsAPI({
      businessId,
      params: {
        ondcCode: categoryData?.[0]?.ondcCode,
        ondcSubCode: subCategoryData?.[0]?.ondcSubCode,
        pageSize: 500,
        pageNo: 1,
      },
    });

    const formattedProducts = ondcProductList?.map((item) => ({
      addedDate: null,
      lastUpdateDate: item?.productUpdatedOn || null,
      ondcEnabled: item?.ondcEnabled,

      origin: "",
      originalPrice: item?.productOriginalPrice,
      productId: item?.id,
      productName: item?.productName,
      productPhoto: item?.productPhoto,
      salePrice: item?.productSalePrice,
    }));

    if (tabValue === 0) {
      prepareProductListWithSyncStatus(formattedProducts);
    } else {
      dispatch(updateMainProductList({ updatedList: [...formattedProducts] }));
    }

    setFilterLoader(false);
    setFilterType("ondc");
    setHasMoreData(false);

    console.log("handleOndcFilter____", formattedProducts);

    console.log(
      "handleOndcFilter____Info",
      `${selectedOndcCategory} - ${selectedOndcSubCat}` +
        `ONDC Category Filter results for “${selectedOndcCategory} - ${selectedOndcSubCat}” -
    ${ondcProductList?.length} products`
    );
    // setProductsList(updatedSearchList);

    setShowONDCFilter(false);
    setOpenMobileFilterModel(false);
    setIsClearFilter({
      show: true,
      category: `${selectedOndcCategory} - ${selectedOndcSubCat}`,
      message: `ONDC Category Filter results for “${selectedOndcCategory} - ${selectedOndcSubCat}” -
    ${ondcProductList?.length} products`,
    });
  };

  // Apply Web Store Filter
  const handleWebstoreFilter = () => {
    setFilterError("");
    if (selectedCategory === "All") {
      setFilterError("Select the Main Category");
      return;
    }

    const categoryData = categoryList?.filter((item) => {
      return item?.value === selectedCategory;
    });

    if (selectedSubCat === "All") {
      const currentProductList = [...mainBranchProducts];

      const filteredProducts = currentProductList?.filter((item) => {
        return (
          item?.productSubCategory?.[0]?.pcCode === categoryData?.[0]?.code
        );
      });

      console.log("handleWebstoreFilter___", filteredProducts);

      dispatch(updateMainProductList({ updatedList: [...filteredProducts] }));
      // setProductsList(filteredProducts);

      setShowWebstoreFilter(false);
      setIsClearFilter({
        show: true,
        category: `${selectedCategory} - ${selectedSubCat}`,
        message: `Webstore Category Filter results for “${selectedCategory} - ${selectedSubCat}” -
      ${filteredProducts?.length} products`,
      });
      return;
    }

    const subCategoryData = subCatList?.filter((item) => {
      return item?.value === selectedSubCat;
    });

    const currentProductList = [...mainBranchProducts];

    const filteredProducts = currentProductList?.filter((item) => {
      return item?.productSubCategory?.[0]?.code === subCategoryData?.[0]?.code;
    });

    dispatch(updateMainProductList({ updatedList: [...filteredProducts] }));
    setFilterType("web");
    setShowWebstoreFilter(false);
    setIsClearFilter({
      show: true,
      category: `${selectedCategory} - ${selectedSubCat}`,
      message: `Webstore Category Filter results for “${selectedCategory} - ${selectedSubCat}” -
    ${filteredProducts?.length} products`,
    });
  };

  const onWebstoreFilterClose = () => {
    setFilterError("");
    setShowWebstoreFilter(false);
    setSelectedCategory("All");
    setSelectedSubCat("All");
    setSubCatList([]);
  };

  const clearAllWebstoreFilterData = () => {
    setSelectedCategory("All");
    setSelectedSubCat("All");
    setSubCatList([]);
  };

  const onOndcFilterClose = () => {
    setFilterError("");
    setShowONDCFilter(false);
    setSelectedOndcCategory("All");
    setSelectedOndcSubCat("All");
    setOndcSubCatList([]);
  };

  const clearAllOndcFilterData = () => {
    setSelectedOndcCategory("All");
    setSelectedOndcSubCat("All");
    setOndcSubCatList([]);
  };

  const closeMobileFilterPopup = () => {
    clearAllOndcFilterData();
    setOpenMobileFilterModel(false);
  };

  // Get sub-branch business info
  const getSubBranchBusinessInfo = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    const branchListData = await getBranchInfoAPI(businessId);

    // const res = await getSubBranchBusinessDetails(
    //   activeBusinessInfo?.branchInfo
    // );

    if (branchListData) {
      setSubBranchDataList(branchListData?.details?.branchInfo || []);
    }
  };

  // Handle Sub Branch Select
  const onSubBranchSelect = (info, index) => {
    const dataArr = [...subBranchDataList];

    const updatedList = dataArr?.map((branchInfo, branchIndex) => {
      return {
        ...branchInfo,
        isSelected: branchIndex === index,
      };
    });

    console.log("onSelectBranch___:", updatedList);

    setSelectedBranchInfo(info);
    setSubBranchDataList(updatedList);
  };

  // Handle close multi product push model
  const onCloseMultiProductModel = () => {
    setOpenMultiProductPopup(false);

    const mainProductArr = [...mainBranchProducts];
    const branchInfoArr = [...subBranchDataList];

    const updatedmainProductList = mainProductArr?.map((item) => {
      return {
        ...item,
        isSelected: false,
      };
    });

    dispatch(updateMainProductList({ updatedList: updatedmainProductList }));

    const updatedBranchInfoList = branchInfoArr?.map((item) => {
      return {
        ...item,
        isSelected: false,
      };
    });

    clearSearch();
    closeMobileFilterPopup();
    clearAllOndcFilterData();
    onOndcFilterClose();
    setSelectedBranchInfo(null);
    setSubBranchDataList(updatedBranchInfoList);
  };

  const prepareProductListWithSyncStatus = async (productArr, openPopup) => {
    const masterBranchId = sessionStorage.getItem("selectedBusinessId");
    const subBranchId = selectedBranchInfo?.branchId || "";

    console.log("selectedBranchInfo___:", selectedBranchInfo);

    const res = await getProdcutsSyncStatusAPI({
      masterBranchId,
      subBranchId,
    });

    const productSyncStatus = res?.details || [];
    console.log("selectedBranchInfo___productSyncStatus:", productSyncStatus);

    const updatedList = productArr?.map((item) => {
      const isSynced = productSyncStatus?.find(
        (data) =>
          data?.masterProductId === item?.productId &&
          data?.childProductDetils?.childProductId
      );

      return {
        ...item,
        isSynced: !!isSynced,
        childProductId: isSynced?.childProductDetils?.childProductId || "",
        productLastImportDate:
          isSynced?.childProductDetils?.productImportDate || null,
      };
    });

    console.log("djcdjcbdjbcdkjbnckjbn_____updatedList", updatedList);

    console.log("selectedBranchInfo___updatedList:", updatedList);
    dispatch(updateMainProductList({ updatedList: [...updatedList] }));

    if (openPopup) {
      setOpenMultiProductPopup(true);
    }
  };

  const getEmptyMessage = () => {
    if (filterType === "search") {
      return "No matching active products found. Please refine your search";
    } else if (filterType === "ondc" || filterType === "web") {
      return "No matching active products found";
    }

    return "No active products found, Please add a new product or complete the approval process for pending products.";
  };

  useEffect(() => {
    if (productCategory?.details) {
      console.log("productCategory", productCategory?.details);
      formatCategory(productCategory?.details, setCategoryList, "value");
    }
  }, [productCategory]);

  useEffect(() => {
    if (ondcCategory) {
      const ondcFilteredCategory = ondcCategory?.filter(
        (item) => item?.ondcCode
      );
      formatCategory(ondcFilteredCategory, setOndcCategoryList, "ondcValue");
      console.log("ondcCategory", ondcCategory, ondcFilteredCategory);
    }
  }, [ondcCategory]);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!productCategory) {
        dispatch(getProductCategories(businessId));
      }
      if (!ondcCategory) {
        dispatch(getONDCCategories(businessId));
      }

      getSubBranchBusinessInfo();
    }

    return () => {};
  }, []);

  useEffect(() => {
    let count = 0;

    mainBranchProducts?.forEach((item) => {
      if (item?.isSelected) {
        count = count + 1;
      }
    });
    setSelectedRowCount(count);

    return () => {};
  }, [mainBranchProducts]);

  return (
    <Box mt={{ xs: 0.8, md: 0 }}>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          position: "sticky",
          top: { xs: 100, md: 45 },
          width: "100%",
          zIndex: 100,
          backgroundColor: BGColor02,
          mt: { xs: -2, md: 0 },
        }}
      >
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          py={{ xs: "0 0 6px", md: "6px 0 6px" }}
          // bgcolor='red'
        >
          <Box pt={1.8} pb={{ xs: 1, md: 1.5 }}>
            <Typography
              component="h4"
              fontSize={{ xs: 15, md: 17 }}
              color={blackShade05}
              fontWeight={600}
              lineHeight="20px"
            >
              Catalog Push to Branches
            </Typography>

            <Typography
              component="h4"
              fontSize={{ xs: 13, md: 13 }}
              color={blackShade21}
              fontWeight={500}
              lineHeight={"24px"}
              pt={0.4}
            >
              Catalog details can be pushed to sub branches
            </Typography>
          </Box>
        </Box>

        {matches ? (
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => handleTabChange(event, newValue)}
            textColor={blackColor}
            TabIndicatorProps={{
              style: {
                height: "3px",
                backgroundColor: blueColor04,
              },
            }}
            sx={styles.tabStyles}
          >
            {CATALOG_PUSH_TABS?.map((item, index) => {
              return (
                <Tab
                  key={"filter_" + index}
                  label={
                    item.label?.toLowerCase() +
                    `${
                      index == 1
                        ? !inventorySummary
                          ? " - XX"
                          : ` - ${inventorySummary?.totalProduct} >> Multiple Branches`
                        : ""
                    }`
                  }
                  sx={{
                    fontSize: { xs: "11px", md: "13.5px" },
                    fontWeight: tabValue == index ? 600 : 500,
                    p: 0,
                    minHeight: "30px !important",
                    mr: { xs: "10px", md: "55px" },
                    color: item.id === tabValue ? blueColor04 : greyColor40,
                    textTransform: "capitalize",
                  }}
                />
              );
            })}
          </Tabs>
        ) : (
          <Box sx={{ ...styles.mobileTabs, ...scrollBox }}>
            {CATALOG_PUSH_TABS?.map((item, index) => {
              return (
                <MobileTab
                  key={"catalog_push_" + index}
                  title={item.label?.toUpperCase()}
                  count={
                    !inventorySummary ? "XX" : inventorySummary?.totalProduct
                  }
                  isActive={tabValue == index}
                  onClick={() => handleTabChange("", item.id)}
                />
              );
            })}
          </Box>
        )}

        {/* {selectedBranchInfo && !matches && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              mt: 2,
            }}
          >
            <Button
              sx={styles.updateIconBtn}
              variant="contained"
              disableElevation
              // onClick={() => handleMultiBranchPushClick()}
              onClick={() =>
                prepareProductListWithSyncStatus(mainBranchProducts, true)
              }
              disabled={branchPushLoader}
            >
              PUSH TO BRANCH
            </Button>
          </Box>
        )} */}

        {tabValue === 1 && (
          <>
            {mainBranchProducts?.length > 0 || filterType ? (
              matches ? (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={{ md: "center" }}
                  flexDirection={{ md: "row", xs: "column" }}
                  gap={2}
                  px={1.5}
                  mb={2}
                >
                  <Box>
                    <Box width={{ xs: "auto", md: "300px" }}>
                      {/* <SearchBoxInput
                        label=""
                        placeholder="Search Product"
                        value={searchQuery}
                        onChange={(text) => {
                          setSearchQuery(text);
                        }}
                        onSearchClick={handleSearch}
                        isDashboardInput
                      /> */}
                    </Box>

                    {(isClearSearch || isClearFilter) && (
                      <Box mt={{ xs: 2.5, md: 1.3 }}>
                        <SearchInfoCard
                          searchQuery={searchQuery}
                          clearSearch={() => {
                            clearSearch();
                          }}
                          filterType={filterType}
                          isClearFilter={isClearFilter}
                        />
                      </Box>
                    )}
                  </Box>

                  <Box
                    display={"flex"}
                    gap={1}
                    position={{ xs: "relative", md: "static" }}
                  >
                    <Box
                      position={{ md: "relative", xs: "static" }}
                      ref={ondcFilterRef}
                    >
                      <Button
                        sx={styles.filterIconBtn}
                        variant="contained"
                        disableElevation
                        onClick={() => {
                          setShowWebstoreFilter(false);
                          setShowONDCFilter((prev) => !prev);
                        }}
                        endIcon={
                          <FilterListRoundedIcon sx={{ fontSize: "17px" }} />
                        }
                      >
                        {matches ? "ONDC Category Filters" : "ONDC Category"}
                      </Button>

                      {showONDCFilter && (
                        <ProductFilterCard
                          title="ONDC Category Filters"
                          categoryList={ondcCategoryList}
                          subCategoryList={ondcSubCatList}
                          selectedCategory={selectedOndcCategory}
                          selectedSubCategory={selectedOndcSubCat}
                          handleCategoryChange={handleOndcCategoryChange}
                          handleSubCategoryChange={handleOndcSubCategoryChange}
                          onApply={handleOndcFilter}
                          onCancel={onOndcFilterClose}
                          onAllClear={clearAllOndcFilterData}
                          loader={filterLoader}
                          error={filterError}
                        />
                      )}
                    </Box>
                    {/* <Box
              position={{ md: "relative", xs: "static" }}
              ref={webstoreFilterRef}
            >
              <Button
                sx={styles.filterIconBtn}
                variant="contained"
                disableElevation
                onClick={() => {
                  setShowONDCFilter(false);
                  setShowWebstoreFilter((prev) => !prev);
                }}
                endIcon={<FilterListRoundedIcon sx={{ fontSize: "17px" }} />}
              >
                {matches ? "Webstore Category Filters" : "Webstore Category"}
              </Button>

              {showWebstoreFilter && (
                <ProductFilterCard
                  title="Webstore Category Filters"
                  categoryList={categoryList}
                  subCategoryList={subCatList}
                  selectedCategory={selectedCategory}
                  selectedSubCategory={selectedSubCat}
                  handleCategoryChange={handleWebstoreCategoryChange}
                  handleSubCategoryChange={handleWebstoreSubCategoryChange}
                  onApply={handleWebstoreFilter}
                  onCancel={onWebstoreFilterClose}
                  onAllClear={clearAllWebstoreFilterData}
                  error={filterError}
                />
              )}
            </Box> */}
                  </Box>
                </Box>
              ) : (
                <Box py={2}>
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="flex-end"
                    gap={1.5}
                  >
                    <IconButton
                      sx={styles.iconBtn}
                      onClick={() => setOpenMobileFilterModel(true)}
                    >
                      <FilterListIcon sx={{ fontSize: "18px" }} />
                    </IconButton>

                    {/* <IconButton
                      sx={styles.iconBtn}
                      onClick={() => setShowSearchBar(!showSearchBar)}
                    >
                      {showSearchBar ? (
                        <SearchOffIcon sx={{ fontSize: "18px" }} />
                      ) : (
                        <SearchIcon sx={{ fontSize: "18px" }} />
                      )}
                    </IconButton> */}
                  </Box>

                  {showSearchBar && (
                    <Box width={{ xs: "auto", md: "300px" }} mt={1}>
                      <SearchBoxInput
                        label=""
                        placeholder="Search Product"
                        value={searchQuery}
                        onChange={(text) => {
                          setSearchQuery(text);
                        }}
                        onSearchClick={handleSearch}
                        isDashboardInput
                      />
                    </Box>
                  )}

                  {(isClearSearch || isClearFilter) && (
                    <Box mt={{ xs: 2.5, md: 1.3 }}>
                      <SearchInfoCard
                        searchQuery={searchQuery}
                        clearSearch={() => {
                          clearSearch();
                        }}
                        filterType={filterType}
                        isClearFilter={isClearFilter}
                      />
                    </Box>
                  )}
                </Box>
              )
            ) : null}

            {matches && (
              <HeaderInfoRow
                tabValue={tabValue}
                productList={mainBranchProducts}
                selectAllProductSelect={selectAllProductSelect}
                loader={branchPushLoader}
              />
            )}
          </>
        )}
      </Box>

      {!productLoader && mainBranchProducts?.length == 0 && tabValue === 1 && (
        <EmptyCard
          icon="https://storage.googleapis.com/bodefaults/businessdashboardweb/product-reject.svg"
          message={getEmptyMessage()}
        />
      )}

      {tabValue === 0 && (
        <Box mt={2}>
          <Grid container spacing={2}>
            {subBranchDataList?.map((item, index) => (
              <Grid item xs={12} md={4} key={`sub_branch_${index}`}>
                <SubBranchCard
                  isSelected={item?.isSelected || false}
                  logo={item?.logo}
                  businessName={item?.businessName}
                  status={item?.status}
                  onClick={() => {
                    onSubBranchSelect(item, index);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {tabValue === 1 && (
        <Box>
          <InfiniteScroll
            dataLength={mainBranchProducts?.length || 0}
            next={() => fetchMainBranchProducts()}
            hasMore={hasMoreData}
            loader={
              productLoader ? (
                <Box>
                  <ThreeDotLoader />
                </Box>
              ) : null
            }
          >
            {mainBranchProducts?.map((productInfo, index) => {
              return (
                <ProductInfoRowCard
                  key={`${productInfo?.productId}_${index}`}
                  index={index}
                  isSelected={productInfo?.isSelected || false}
                  onProductSelect={() => {
                    onProductSelect(index);
                  }}
                  loader={branchPushLoader}
                  productImg={productInfo?.productPhoto?.[0]?.docURL}
                  productName={productInfo?.productName}
                  originalPrice={productInfo?.originalPrice}
                  salePrice={productInfo?.salePrice}
                  ondcEnabled={productInfo?.ondcEnabled}
                  lastUpdateDate={
                    productInfo?.lastUpdateDate
                      ? moment(productInfo?.lastUpdateDate)?.format(
                          "MMM Do YYYY, hh:mm A"
                        )
                      : "-"
                  }
                  tabValue={tabValue}
                  onPushProduct={() => {
                    setOpenSingleProductPopup({
                      show: true,
                      productInfo: productInfo,
                    });
                  }}
                  isAllSelected={mainBranchProducts?.every(
                    (item) => item?.isSelected
                  )}
                  selectAllProducts={selectAllProductSelect}
                />
              );
            })}
          </InfiniteScroll>
        </Box>
      )}

      <Box my={3}>
        {selectedBranchInfo && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Button
              sx={styles.updateIconBtn}
              variant="contained"
              disableElevation
              // onClick={() => handleMultiBranchPushClick()}
              onClick={() =>
                prepareProductListWithSyncStatus(mainBranchProducts, true)
              }
              disabled={branchPushLoader}
            >
              PUSH TO BRANCH
            </Button>
          </Box>
        )}
      </Box>

      <PushMultipleProductPopup
        openModal={openMultiProductPopup}
        setOpenModal={setOpenMultiProductPopup}
        selectedBranchInfo={selectedBranchInfo}
        mainBranchProducts={mainBranchProducts}
        selectedRowCount={selectedRowCount}
        branchPushLoader={branchPushLoader}
        onProductSelect={onProductSelect}
        selectAllProducts={selectAllProductSelect}
        // fetchMainBranchProducts={fetchMainBranchProducts}
        fetchMainBranchProducts={(selectedBranchId) => {
          fetchMainBranchProducts(false, null, selectedBranchId);
        }}
        hasMoreData={hasMoreData}
        productLoader={productLoader}
        getEmptyMessage={getEmptyMessage}
        onCloseMultiProductModel={onCloseMultiProductModel}
        onSubmit={() => onPushToSingleBranch()}
        // Filter Props
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearch={handleSearch}
        isClearSearch={isClearSearch}
        isClearFilter={isClearFilter}
        filterType={filterType}
        clearSearch={clearSearch}
        ondcFilterRef={ondcFilterRef}
        setShowWebstoreFilter={setShowWebstoreFilter}
        setShowONDCFilter={setShowONDCFilter}
        showONDCFilter={showONDCFilter}
        ondcCategoryList={ondcCategoryList}
        ondcSubCatList={ondcSubCatList}
        selectedOndcCategory={selectedOndcCategory}
        selectedOndcSubCat={selectedOndcSubCat}
        handleOndcCategoryChange={handleOndcCategoryChange}
        handleOndcSubCategoryChange={handleOndcSubCategoryChange}
        handleOndcFilter={handleOndcFilter}
        onOndcFilterClose={onOndcFilterClose}
        clearAllOndcFilterData={clearAllOndcFilterData}
        filterLoader={filterLoader}
        filterError={filterError}
        // Mobile Filter props
        setOpenMobileFilterModel={setOpenMobileFilterModel}
      />

      {openSingleProductPopup?.show && (
        <SingleProductPushPopup
          openModal={openSingleProductPopup?.show}
          setOpenModal={setOpenSingleProductPopup}
          selectedProductInfo={openSingleProductPopup?.productInfo}
          branchInfoList={subBranchDataList || []}
          setSubBranchDataList={setSubBranchDataList}
          mainBranchProducts={mainBranchProducts}
          selectedRowCount={selectedRowCount}
          branchPushLoader={branchPushLoader}
          activeBusinessInfo={activeBusinessInfo}
        />
      )}

      <MobileCategoryFilter
        openModal={openMobileFilterModel}
        setOpenModal={setOpenMobileFilterModel}
        // ONDC Category
        selectedOndcCategory={selectedOndcCategory}
        handleOndcCategoryChange={handleOndcCategoryChange}
        selectedOndcSubCat={selectedOndcSubCat}
        handleOndcSubCategoryChange={handleOndcSubCategoryChange}
        ondcCategoryList={ondcCategoryList}
        ondcSubCatList={ondcSubCatList}
        onOndcApplyFilter={handleOndcFilter}
        onCancel={closeMobileFilterPopup}
        onAllClear={clearAllOndcFilterData}
        loader={filterLoader}
        filterError={filterError}
      />
    </Box>
  );
};

export default BranchCatalogPushContent;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
  headerSubText: {
    fontSize: 11.5,
    fontWeight: 400,
    color: greyColor5,
    marginLeft: "5px",
  },

  tableWrapper: {
    width: "calc(100vw - 270px)",
    borderRadius: "6px",
    border: `1px solid ${borderTextBoxColor}`,
    bgColor: whiteColor,
    mt: 3,
  },

  tableContainer: {
    borderRadius: "8px",
    overflow: "scroll",
    maxHeight: "calc(100vh - 160px)",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      // background: '#d0d3da',
      background: "rgba(0,0,0,0.1)",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.05)",
    },
  },

  tableRowStyles: {
    position: "sticky !important",
    top: 0,
    backgroundColor: greyColor42,
    zIndex: 20,
    borderRadius: "8px 8px 0 0",
  },

  tableCell: {
    color: greyColor47,
    fontSize: "12.5px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    padding: "8px 15px",
    backgroundColor: greyColor42,
    borderRight: `1px solid ${whiteColor}`,
  },

  tableBodyCell: (isError) => ({
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: isError ? lightRedColor : whiteColor,
    padding: "8px 15px !important",
    // borderRight: `1px solid ${borderTextBoxColor}`,
    boxShadow: isError ? `0px -1px 0 0 ${redColor} inset` : "none",
  }),

  checkbox: {
    p: 0,
    transform: "scale(1.3)",
    "&.Mui-checked": {
      color: greyColor8,
    },
  },

  imgBox: {
    borderRadius: "4px",
    border: "1px solid #e5e9f0",
    p: "5px",
  },

  imgContainer: {
    backgroundColor: whiteColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "35px !important",
    height: "35px",
    objectFit: "contain",
  },

  ondcStatusTag: {
    width: "55px",
    display: { xs: "inline", md: "flex" },
    alignItems: "center",
    justifyContent: "center",
    fontSize: { xs: 13, md: 12 },
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "50px",
    p: { xs: "0 8px", md: "1px 13px" },
  },

  statusChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    p: "5px 13px",
    borderRadius: "50px",
    width: "max-content",
  }),

  statusBoxChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    p: "2px 4px",
    borderRadius: "4px",
    width: "max-content",
  }),

  originalPriceText: {
    color: oldPriceColor,
    fontSize: 12,
    lineHeight: "15px",
    fontWeight: 400,
    mt: { xs: "0px !important", md: "7px !important" },
    textDecoration: "line-through",
    display: "inline",
  },

  salePriceText: {
    display: "inline",
    color: blueColor04,
    fontSize: 13,
    lineHeight: "15px",
    fontWeight: 600,
    m: "6px 0 0 0px",
    mr: 1,
  },

  actionBtn: {
    fontSize: 12,
    fontWeight: 600,
    padding: "3px 15px",
    textTransform: "unset",
    border: `1px solid ${blueColor03}`,
    color: blueColor04,
    borderColor: blueColor04,
    mt: 1.2,
    "&:hover": {
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      borderColor: "rgba(0,0,0,0.2)",
    },
  },

  updateIconBtn: {
    padding: { md: "3px 10px", xs: "3px 14px" },
    fontSize: { md: "12.5px", xs: "11px" },
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    border: {
      xs: `1.5px solid ${mobilePrimary}`,
      md: `1.5px solid ${blueColor04}`,
    },
    whiteSpace: "nowrap",
    "&:hover": {
      border: {
        xs: `1.5px solid ${mobilePrimary}`,
        md: `1.5px solid ${blueColor04}`,
      },
      color: { xs: mobilePrimary, md: blueColor04 },
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },

  filterIconBtn: {
    padding: "4px 12px",
    fontSize: "11px",
    fontWeight: 600,
    color: greyColor9,
    backgroundColor: whiteColor,
    whiteSpace: "nowrap",
    border: "1px solid " + greyColor9,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor9,
    },
  },

  tabStyles: {
    mb: 1.8,
    minHeight: "35px !important",
    borderBottom: logoImgborder,
    "& .MuiButtonBase-root": {
      minWidth: "auto !important",
    },
  },

  mobileTabs: {
    width: { xs: "90vw", md: "calc(100% - 100px)" },
    display: "flex",
    alignItems: "center",
    // borderBottom: logoImgborder,
  },

  iconBtn: {
    width: "40px",
    height: "32px",
    bgcolor: whiteColor,
    borderRadius: "5px",
    border: `1px solid ${greyColor9}`,
  },
};
