import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackShade05,
  blackShade159,
  blueColor02,
  blueColor04,
  borderLineSearchColor,
  greenColor02,
  greenColor2,
  greenColor3,
  greyColor11,
  greyColor28,
  greyColor29,
  greyColor30,
  greyColor42,
  greyColor6,
  greyColor8,
  headingColor,
  heroSectionText,
  lightBlueColor01,
  lightGreen01,
  lightOrange01,
  logoImgborder1,
  mobilePrimary,
  orangeColor02,
  orangeStatusColor,
  outOfStockColor,
  tableHeaderColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import {
  getCartInfoApi,
  resendInvoiceApi,
  resendInvoiceApiB2B,
} from "../../../service/api_calls/orders/OrdersApi";
import moment from "moment";
import {
  addThousandsSeparator,
  updateApproveList,
} from "../../../utils/helper";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import ResponsiveStepper from "../../../components/Stepper/ResponsiveStepper";
import TimeScheduleStep from "../../../components/Stepper/TimeScheduleStep";
import { TIME_SCHEDULE } from "../../../utils/data";

import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PaycartStatusStepper from "../PaycartStatusStepper";
import { getPayCartApi } from "../../../service/api_calls/paycartOrders/PaycartOrdersAPI";
import ModalCard from "../../../components/ModalCard";
import OrderTrackingPopupContent from "../../Orders/Popups/OrderTrackingPopupContent";

function ViewPayCartOrderPopup({ orderData, handleTrackOrder, showTracking }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);

  const [paycartInfo, setPaycartInfo] = useState(null);

  const [openTrackOrder, setOpenTrackOrder] = useState(false);

  const getCartData = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    setLoading(true);

    if (orderData?.cartId) {
      const paycartData = await getPayCartApi({
        cartId: orderData?.cartId,
        businessId,
      });

      if (paycartData?.success) {
        setPaycartInfo(paycartData?.cart || null);
      }
    }

    setLoading(false);
  };

  const STEPS = [
    {
      label: "Payment Requested",
    },
    {
      label: "Created",
    },
    {
      label: "Approved",
    },
    {
      label: "Shipped",
    },
    {
      label: "Delivered",
    },
  ];

  const getOrderStatusText = (status) => {
    switch (status) {
      case "paycartcreated":
        return "Yet to be Paid";

      case "ordercreated":
        return "Order Created";

      case "orderapproved":
        return "Ready to Ship";

      case "ordershipped":
        return "Shipped";

      case "orderdelivered":
        return "Delivered";

      case "bordercanceled":
        return "Canceled";

      case "cordercanceled":
        return "Canceled";

      default:
        return "Yet to be Paid";
    }
  };

  const getOrderStatusStep = (status) => {
    switch (status) {
      case "paycartcreated":
        return 0;
      case "ordercreated":
        return 1;
      case "orderapproved":
        return 2;
      case "ordershipped":
        return 3;
      case "orderdelivered":
        return 4;

      default:
        return 0;
    }
  };

  const formatOrderDate = (date) => {
    return date ? moment(date).format("MMM DD, YYYY") : "";
  };

  const getDateByOrderStatus = (status) => {
    switch (status) {
      case 0:
        return formatOrderDate(paycartInfo?.orderRequestedOn);
      case 1:
        return formatOrderDate(paycartInfo?.orderCreatedOn);
      case 2:
        return formatOrderDate(paycartInfo?.orderApprovedOn);
      case 3:
        return formatOrderDate(paycartInfo?.orderShippedOn);
      case 4:
        return formatOrderDate(paycartInfo?.orderDeliveredOn);

      default:
        return "";
    }
  };

  useEffect(() => {
    getCartData();
    return () => {};
  }, []);

  return (
    <Box overflow="hidden">
      <ModalCard
        open={openTrackOrder}
        handleClose={() => {
          setOpenTrackOrder(false);
        }}
        // width="30%"
      >
        <OrderTrackingPopupContent orderData={orderData || null} />
      </ModalCard>
      {loading ? (
        <Box
          sx={{
            minHeight: "100px",
            backgroundColor: whiteColor,
            borderRadius: "12px",
            pt: "20px",
          }}
        >
          <ThreeDotLoader />
        </Box>
      ) : (
        <Box sx={styles.card}>
          <Typography
            variant="h5"
            fontSize={17}
            fontWeight={600}
            color={blackShade05}
          >
            Easy Cart Order
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Box
                sx={{
                  ...styles.orderInfoBox,
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  pb={1.5}
                  borderBottom={"1px solid " + greyColor28}
                  display="flex"
                  alignItems={{ md: "center", xs: "flex-start" }}
                  justifyContent="space-between"
                  flexDirection={{ md: "row", xs: "column" }}
                  width="100%"
                  mb={1}
                >
                  <Box>
                    <Box mb={{ md: 0, xs: 0.5 }}>
                      <Typography variant="h6" sx={styles.orderIdText}>
                        Order ID:{" "}
                        <Typography
                          variant="h6"
                          sx={{
                            ...styles.orderIdText,
                            fontWeight: 600,
                            color: { md: blueColor04, xs: mobilePrimary },
                          }}
                        >
                          {paycartInfo?.orderId}
                        </Typography>
                      </Typography>
                    </Box>

                    <Typography variant="h6" sx={styles.orderIdText}>
                      Date:{" "}
                      <Typography
                        variant="h6"
                        sx={{
                          ...styles.orderIdText,
                          fontWeight: 600,
                          color: { md: blueColor04, xs: mobilePrimary },
                        }}
                      >
                        {moment(
                          paycartInfo?.orderCreatedOn
                            ? paycartInfo?.orderCreatedOn
                            : paycartInfo?.createdOn
                        ).format("MMM Do, YYYY")}
                      </Typography>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    alignItems={{ xs: "flex-start", md: "center" }}
                    gap={{ md: 5, xs: 1 }}
                  >
                    <Box
                      display="flex"
                      flexDirection={{ md: "column", xs: "row" }}
                      alignItems={{ xs: "center", md: "center" }}
                      gap={{ md: 0, xs: 1 }}
                      mt={{ xs: 0.5, md: 0 }}
                    >
                      <Typography component="h6" sx={styles.orderIdText}>
                        Order Status
                      </Typography>

                      <Box
                        sx={{
                          backgroundColor:
                            paycartInfo?.orderStatus === "paycartcreated" ||
                            paycartInfo?.orderStatus === "bordercanceled" ||
                            paycartInfo?.orderStatus === "cordercanceled"
                              ? lightOrange01
                              : lightGreen01,
                          p: "4px 12px",
                          borderRadius: "20px",
                          mt: "4px",
                        }}
                      >
                        <Typography
                          component="p"
                          sx={{
                            fontSize: 12,
                            fontWeight: 500,
                            lineHeight: "18px",
                            color:
                              paycartInfo?.orderStatus === "paycartcreated" ||
                              paycartInfo?.orderStatus === "bordercanceled" ||
                              paycartInfo?.orderStatus === "cordercanceled"
                                ? orangeColor02
                                : greenColor02,
                          }}
                        >
                          {getOrderStatusText(paycartInfo?.orderStatus)}
                        </Typography>
                      </Box>
                    </Box>

                    {(paycartInfo?.orderStatus === "ordershipped" ||
                      paycartInfo?.orderStatus === "orderdelivered" ||
                      paycartInfo?.orderStatus === "orderapproved") && (
                      <Button
                        disableElevation
                        sx={{
                          fontSize: 12,
                          fontWeight: 600,
                          padding: "4px 20px",
                          color: whiteColor,
                          backgroundColor: {
                            md: blueColor04,
                            xs: mobilePrimary,
                          },
                          border: {
                            md: `1px solid ${blueColor04}`,
                            xs: `1px solid ${mobilePrimary}`,
                          },
                          mr: 3,

                          "&:hover": {
                            color: { md: blueColor04, xs: mobilePrimary },
                            backgroundColor: whiteColor,
                          },
                        }}
                        onClick={() => {
                          setOpenTrackOrder(true);
                        }}
                      >
                        TRACK
                      </Button>
                    )}
                  </Box>
                </Box>

                <Box
                  pb={2}
                  borderBottom={"1px solid " + greyColor28}
                  display="flex"
                  alignItems={{ md: "center", xs: "flex-start" }}
                  justifyContent="space-between"
                  flexDirection={{ xs: "column", md: "row" }}
                  width="100%"
                  gap={2}
                  mb={1}
                >
                  <InfoLinkWithCopy
                    icon={
                      <CurrencyRupeeIcon
                        sx={{ fontSize: "16px", color: whiteColor }}
                      />
                    }
                    label="Payment Link:"
                    link={paycartInfo?.paymentLink}
                  />
                  <InfoLinkWithCopy
                    icon={
                      <ShoppingCartOutlinedIcon
                        sx={{ fontSize: "16px", color: whiteColor }}
                      />
                    }
                    label="Order Details:"
                    link={`https://shop.costbo.com/${sessionStorage.getItem(
                      "costboWEBURL"
                    )}/easycart/${orderData?.cartId}`}
                  />
                </Box>

                <Box width="100%">
                  <Typography
                    variant="h5"
                    fontSize={{ xs: 13, md: 15 }}
                    color={headingColor}
                    mb="15px !important"
                  >
                    Order Status
                  </Typography>

                  <PaycartStatusStepper
                    steps={STEPS}
                    activeStep={getOrderStatusStep(paycartInfo?.orderStatus)}
                    getDateByOrderStatus={getDateByOrderStatus}
                    width="auto"
                  />
                </Box>
              </Box>

              {matches ? (
                <Box sx={styles.table}>
                  <Box sx={styles.tableHeader}>
                    <Grid container>
                      <Grid item xs={4.5}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          PRODUCT
                        </Typography>
                      </Grid>

                      <Grid item xs={1.5}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          UNIT PRICE
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          QUANTITY
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          PRICE
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          SAVINGS
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  {paycartInfo?.productList?.map((item, index) => {
                    const savings =
                      Number(item?.productOriginalPrice) *
                        Number(item?.currentQuantity) -
                      Number(item?.productSalePrice) *
                        Number(item?.currentQuantity);

                    return (
                      <Box sx={styles.productRow} key={item?.productId}>
                        <Grid container spacing={2}>
                          <Grid item xs={4.5}>
                            <Box
                              display="flex"
                              alignItems="center"
                              gap="10px"
                              ml={2}
                            >
                              <Box
                                component="img"
                                src={item?.productPicURL}
                                alt={item?.productName}
                                sx={styles.productImg}
                              />

                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                {item?.productName}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={1.5}>
                            <Box sx={styles.centerText}>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹{addThousandsSeparator(item?.productSalePrice)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={2}>
                            <Box sx={styles.centerText}>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                {item?.currentQuantity}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={2}>
                            <Box sx={styles.centerText}>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹
                                {addThousandsSeparator(item?.productTotalPrice)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={2}>
                            <Box sx={styles.centerText}>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹{addThousandsSeparator(savings)}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </Box>
              ) : null}
            </Grid>

            <Grid item xs={12} md={3}>
              <Box sx={styles.summaryCard}>
                <Typography
                  variant="h3"
                  fontSize={14}
                  fontWeight={600}
                  color={blackShade05}
                  pb="12px"
                  pt="4px"
                  sx={{
                    borderBottom: `1px solid ${greyColor28}`,
                    textAlign: { md: "right", xs: "left" },
                  }}
                >
                  SUMMARY
                </Typography>

                <Box mt={1}>
                  <SummaryDataRow
                    label="Subtotal:"
                    value={`₹${addThousandsSeparator(
                      paycartInfo?.orderTotalProductCost
                    )}`}
                  />

                  <SummaryDataRow
                    label="Total Quantity:"
                    value={paycartInfo?.totalQuantity}
                  />

                  <SummaryDataRow
                    label="Taxes:"
                    value="Included"
                    valueColor={greenColor3}
                  />

                  <SummaryDataRow
                    label="Shipping & Handling:"
                    value={`₹${addThousandsSeparator(
                      paycartInfo?.shippingCost
                    )}`}
                  />

                  <SummaryDataRow
                    label="Discount:"
                    value={`₹${addThousandsSeparator(
                      paycartInfo?.claimedPoint
                    )}`}
                  />

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      borderTop: `1px solid ${greyColor28}`,
                      mb: "4px",
                      pt: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontSize={14}
                      fontWeight={paycartInfo?.codCost ? 500 : 600}
                      color={blackShade05}
                    >
                      Order Total
                    </Typography>

                    <Typography
                      variant="body2"
                      fontSize={14}
                      fontWeight={paycartInfo?.codCost ? 500 : 600}
                      color={{ md: heroSectionText, xs: mobilePrimary }}
                    >
                      ₹
                      {addThousandsSeparator(
                        Number(paycartInfo?.orderTotalProductCost) +
                          Number(paycartInfo?.shippingCost || 0) -
                          Number(paycartInfo?.claimedPoint || 0)
                      )}
                    </Typography>
                  </Box>
                  {paycartInfo?.codCost && (
                    <>
                      <SummaryDataRow
                        label="COD Cost:"
                        value={`₹${addThousandsSeparator(
                          paycartInfo?.codCost?.codCharges
                        )}`}
                      />
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          borderTop: `1px solid ${greyColor28}`,
                          mb: "4px",
                          pt: "12px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontSize={14}
                          fontWeight={600}
                          color={blackShade05}
                        >
                          Total
                        </Typography>

                        <Typography
                          variant="body2"
                          fontSize={14}
                          fontWeight={600}
                          color={heroSectionText}
                        >
                          ₹
                          {addThousandsSeparator(
                            Number(paycartInfo?.orderTotalProductCost) +
                              Number(paycartInfo?.codCost?.codCharges) +
                              Number(paycartInfo?.shippingCost || 0) -
                              Number(paycartInfo?.claimedPoint || 0)
                          )}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box sx={styles.summaryCard}>
                <Typography
                  variant="h3"
                  fontSize={14}
                  fontWeight={600}
                  color={blackShade05}
                  pb="12px"
                  pt="4px"
                  sx={{
                    borderBottom: `1px solid ${greyColor28}`,
                    textAlign: { md: "right", xs: "left" },
                  }}
                >
                  Customer Address
                </Typography>

                <Box mt={1}>
                  <Typography
                    variant="h6"
                    fontSize={13}
                    fontWeight={600}
                    color={blackShade05}
                    lineHeight="25px"
                    sx={{ mt: "4px" }}
                  >
                    {paycartInfo?.customerAddress?.name}{" "}
                    {/* {cartInfo?.customerAddress?.type
                      ? `(${cartInfo?.customerAddress?.type})`
                      : ""} */}
                  </Typography>

                  {paycartInfo?.customerAddress?.addressLine1 && (
                    <Typography
                      variant="body1"
                      fontSize={12}
                      fontWeight={500}
                      color={greyColor6}
                      sx={{ m: "6px 0 10px" }}
                    >
                      {paycartInfo?.customerAddress?.addressLine1},{" "}
                      {paycartInfo?.customerAddress?.landMark},{" "}
                      {paycartInfo?.customerAddress?.city} -{" "}
                      {paycartInfo?.customerAddress?.zip},{" "}
                      {paycartInfo?.customerAddress?.state},{" "}
                      {paycartInfo?.customerAddress?.country}
                    </Typography>
                  )}

                  <Typography
                    variant="body1"
                    fontSize={12}
                    fontWeight={500}
                    color={greyColor6}
                  >
                    Ph: +91{" "}
                    {paycartInfo?.customerAddress?.alternatePhoneNo?.replace(
                      "+91",
                      ""
                    ) ||
                      paycartInfo?.customerAddress?.phoneNo?.replace("+91", "")}
                  </Typography>
                </Box>
              </Box>

              <Box sx={styles.summaryCard}>
                <Typography
                  variant="h3"
                  fontSize={14}
                  fontWeight={600}
                  color={blackShade05}
                  pb="12px"
                  pt="4px"
                  sx={{
                    borderBottom: `1px solid ${greyColor28}`,
                    textAlign: { md: "right", xs: "left" },
                  }}
                >
                  Delivery Method
                </Typography>

                <Box m="12px 0 -10px">
                  <Typography
                    variant="body1"
                    fontSize={12}
                    fontWeight={500}
                    color={greyColor6}
                    sx={{ m: "6px 0 10px", textTransform: "capitalize" }}
                  >
                    {!paycartInfo?.deliveryType
                      ? "Store Pickup"
                      : paycartInfo?.deliveryType?.type}
                    {/* {cartInfo?.deliveryDateRange !== "- -"
                      ? cartInfo?.deliveryDateRange
                      : !cartInfo?.customerAddress?.city
                      ? "Store Pickup"
                      : ""} */}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {matches ? null : (
              <>
                <Grid item xs={12} md="none">
                  <Typography
                    variant="h3"
                    fontSize={15}
                    fontWeight={600}
                    color={blackShade05}
                    // pb="12px"
                    // pt="30px"
                    sx={{
                      // borderBottom: `1px solid ${greyColor28}`,
                      textAlign: { md: "none", xs: "left" },
                    }}
                  >
                    Product Overview
                  </Typography>
                </Grid>
                <Grid item xs={12} md="none">
                  {/* <Box sx={styles.summaryCard}> */}
                  {paycartInfo?.productList?.map((item, index) => {
                    const savings =
                      Number(item?.productOriginalPrice) *
                        Number(item?.currentQuantity) -
                      Number(item?.productSalePrice) *
                        Number(item?.currentQuantity);

                    return (
                      <Box sx={styles.summaryCard}>
                        {/* <Box sx={styles.productRow} key={item?.productId}> */}
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Box
                              display="flex"
                              alignItems="center"
                              gap="12px"
                              // ml={2}
                            >
                              <Box
                                component="img"
                                src={item?.productPicURL}
                                alt={item?.productName}
                                sx={styles.productImg}
                              />

                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  fontSize: "14px",
                                  fontWeight: 500,
                                }}
                              >
                                {item?.productName}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: "12px",
                                  mb: 1,
                                }}
                              >
                                UNIT PRICE
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹{addThousandsSeparator(item?.productSalePrice)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: "12px",
                                  mb: 1,
                                }}
                              >
                                QUANTITY
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                {item?.currentQuantity}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: "12px",
                                  mb: 1,
                                }}
                              >
                                PRICE
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹
                                {addThousandsSeparator(item?.productTotalPrice)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: "12px",
                                  mb: 1,
                                }}
                              >
                                SAVINGS
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹{addThousandsSeparator(savings)}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                  {/* </Box> */}
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default ViewPayCartOrderPopup;

const SummaryDataRow = ({ label, value, valueColor }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb="10px"
    >
      <Typography
        variant="body1"
        fontSize={13}
        fontWeight={500}
        color={blackShade05}
      >
        {label}
      </Typography>

      <Typography
        variant="body2"
        fontSize={13.5}
        fontWeight={600}
        color={valueColor || greyColor30}
      >
        {value}
      </Typography>
    </Box>
  );
};

const InfoLinkWithCopy = ({ icon, label, link }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <Box display="flex" alignItems="center" gap="10px">
      <Box sx={styles.iconCircle}>{icon}</Box>

      <Box flex={1}>
        <Typography
          variant="h5"
          fontSize={{ xs: 12, md: 12 }}
          fontWeight={600}
          color={blackShade05}
          lineHeight="24px"
        >
          {label}
        </Typography>

        <Box display="flex" alignItems="flex-end" gap="7px">
          <Typography
            variant="body2"
            fontSize={12}
            fontWeight={500}
            color={{ md: blueColor04, xs: mobilePrimary }}
            sx={{ overflowWrap: "anywhere" }}
          >
            {link}
          </Typography>

          <IconButton
            sx={{ width: "18px", height: "18px" }}
            onClick={handleCopy}
          >
            {isCopied ? (
              <CheckCircleOutlinedIcon
                sx={{ fontSize: 16, color: greenColor2 }}
              />
            ) : (
              <ContentCopyOutlinedIcon
                sx={{
                  fontSize: 16,
                  color: { md: blueColor04, xs: mobilePrimary },
                }}
              />
            )}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "12px",
    padding: { md: "20px", xs: "8px" },

    maxHeight: { md: "95vh", xs: "70vh" },
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
      // height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blackShade159,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },

  orderIdText: {
    fontSize: { md: 14, xs: 13 },
    fontWeight: 500,
    lineHeight: "27px",
    color: blackShade05,
    display: "inline",
  },

  orderInfoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "12px",
    border: `1px solid ${greyColor28}`,
    p: { md: "13px 15px 13px 15px", xs: "6px" },
    mt: 2.2,
  },

  statusTag: (bgColor) => ({
    backgroundColor: bgColor || lightBlueColor01,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "20px",
    color: whiteColor,
    p: "0 15px 0 20px",
    position: "relative",

    "&::before": {
      content: '" "',
      width: "15px",
      height: "28px",
      backgroundColor: whiteColor,
      rotate: "40deg",
      position: "absolute",
      left: -3,
      top: -8,
    },
  }),

  table: {
    border: `1px solid ${greyColor11}`,
    borderRadius: "7px",
    mt: 3,
  },

  tableHeader: {
    backgroundColor: greyColor42,
    p: "6px 10px",
    borderRadius: "7px 7px 0 0",
  },

  tableHeaderText: {
    fontSize: 13,
    fontWeight: 500,
    color: greyColor29,
  },

  productRow: {
    p: "10px 0",
    borderBottom: "1px solid #f2f2f2",
  },

  productImg: {
    width: { md: "45px", xs: 45 },
    height: { md: "45px", xs: 45 },
    borderRadius: "8px",
    border: logoImgborder1,
  },

  productInfoText: {
    fontSize: 14,
    fontWeight: 500,
    // alignItems:"center",
    color: greyColor29,
  },

  centerText: {
    height: "100%",
    display: "flex",
    alignItems: { md: "center", xs: "flex-start" },
    justifyContent: "flex-start",
  },

  summaryCard: {
    padding: "12px",
    borderRadius: "10px",
    border: `1px solid ${greyColor28}`,
    mt: { md: 2.2, xs: "none" },
    mb: { md: "none", xs: 2.2 },
  },

  iconCircle: {
    width: "30px",
    height: "30px",
    display: { xs: "none", md: "flex" },
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: blueColor04,
    borderRadius: "15px",
  },
};
