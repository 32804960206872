import { Box, TableCell } from "@mui/material";
import React from "react";
import TabelCellInput from "../Popups/TabelCellInput";
import {
  blackShade05,
  borderTextBoxColor,
  lightRedColor,
  redColor,
  whiteColor,
} from "../../../../configs/styles/muiThemes";
import TableDropdown from "../TableDropdown";
import OndcCustomMultiSelect from "./OndcCustomMultiSelect";
import OndcRadioGroup from "./OndcRadioGroup";
import { toTitleCase } from "../../../../utils/validationUtils";

const OndcSpecInput = ({
  specItemInfo,
  value,
  onChange,
  regexType,
  disabled,
  errorMsg,
}) => {
  const handleValueChange = (inputValue, selector = "inputValue") => {
    const formattedInput =
      typeof inputValue === "string" ? inputValue?.trim() : inputValue;
    onChange(formattedInput, selector);
  };

  const getSelectedRadioValue = (radioOptionList) => {
    const selectedOption = radioOptionList?.filter(
      (item) => item?.value === item?.options?.selected
    );

    if (selectedOption?.length > 0) {
      return selectedOption?.[0]?.label;
    }

    return "";
  };

  if (specItemInfo?.type === "variants" || specItemInfo?.type === "data") {
    return (
      <TableCell sx={{ ...styles.tableBodyCell(errorMsg) }}>
        {/* {JSON.stringify(specItemInfo.inputValue)} */}
        <TableDropdown
          value={toTitleCase(specItemInfo.inputValue)}
          onValueChange={(selectedValue) => {
            handleValueChange(selectedValue);
          }}
          menuList={
            specItemInfo?.values?.length > 0
              ? specItemInfo?.values?.map((item) => ({
                  label: toTitleCase(item),
                }))
              : []
          }
          // disabled={disabled}
          errorMsg={specItemInfo?.error}
        />
      </TableCell>
    );
  }

  if (specItemInfo?.type === "codeValue") {
    return (
      <TableCell sx={{ ...styles.tableBodyCell(errorMsg) }}>
        {/* {JSON.stringify(specItemInfo.inputValue)} */}
        <TableDropdown
          value={specItemInfo.inputValue}
          onValueChange={(selectedValue) => {
            handleValueChange(selectedValue);
          }}
          menuList={
            specItemInfo?.options?.length > 0
              ? specItemInfo?.options?.map((item) => ({
                  label: toTitleCase(item),
                }))
              : []
          }
          // disabled={disabled}
          errorMsg={specItemInfo?.error}
        />
      </TableCell>
    );
  }

  if (specItemInfo?.type === "default") {
    return (
      <TableCell sx={{ ...styles.tableBodyCell(errorMsg) }}>
        {/* {JSON.stringify(specItemInfo.inputValue)} */}
        <TabelCellInput
          value={
            specItemInfo?.values?.[0]?.charAt(0)?.toUpperCase() +
            specItemInfo?.values?.[0]?.slice(1)
          }
          onValueChange={(value) => {
            // handleValueChange(value);
          }}
          regexType={regexType}
          disabled
          errorMsg={specItemInfo?.error}
        />
      </TableCell>
    );
  }

  if (specItemInfo?.type === "sample data") {
    return (
      <TableCell sx={{ ...styles.tableBodyCell(errorMsg) }}>
        {/* {JSON.stringify(specItemInfo.inputValue)} */}
        <OndcCustomMultiSelect
          // required={specItemInfo?.required?.toLowerCase() === "m"}
          label={specItemInfo?.displayName}
          // hintText={specItemInfo?.hintText}
          menuList={specItemInfo?.values || []}
          value={specItemInfo?.inputValue}
          onValueChange={(value) => handleValueChange(value)}
          errorMsg={specItemInfo?.error}
        />
      </TableCell>
    );
  }

  if (specItemInfo?.type === "radioButton") {
    return (
      <TableCell sx={{ ...styles.tableBodyCell(errorMsg) }}>
        <OndcRadioGroup
          // required={specItemInfo?.required?.toLowerCase() === "m"}
          // label={specItemInfo?.displayName}
          // hintText={specItemInfo?.hintText}
          value={getSelectedRadioValue(specItemInfo?.radioOptions)}
          errorMsg={specItemInfo?.error}
          radioOptions={specItemInfo?.radioOptions || []}
          onValueChange={(obj) => {
            const updatedRadioOptions = specItemInfo?.radioOptions?.map(
              (option) => {
                if (option?.key === obj?.key) {
                  return {
                    ...option,
                    value: option?.options?.selected,
                  };
                } else {
                  return {
                    ...option,
                    value: option?.options?.notSelected,
                  };
                }
              }
            );
            console.log("SpecListData___RADIO_GROUP", updatedRadioOptions);
            handleValueChange(updatedRadioOptions, "radioOptions");
          }}
          returnFullItem
        />
      </TableCell>
    );
  }

  if (specItemInfo?.type === "dependentCode") {
    return (
      <TableCell sx={{ ...styles.tableBodyCell(errorMsg) }}>
        {/* {JSON.stringify(specItemInfo.inputValue)} */}
        <TableDropdown
          value={specItemInfo.inputValue}
          onValueChange={(selectedValue) => {
            handleValueChange(selectedValue);
          }}
          menuList={
            specItemInfo?.options?.length > 0
              ? specItemInfo?.options?.map((item) => ({
                  label: toTitleCase(item),
                }))
              : []
          }
          // disabled={disabled}
          errorMsg={specItemInfo?.error}
        />
      </TableCell>
    );
  }

  return (
    <>
      <TableCell sx={{ ...styles.tableBodyCell(errorMsg) }}>
        {/* {JSON.stringify(specItemInfo.code)} */}
        <TabelCellInput
          minWidth="180px"
          value={specItemInfo.inputValue}
          onValueChange={(value) => {
            handleValueChange(value);
          }}
          regexType={regexType}
          disabled={disabled}
          errorMsg={specItemInfo?.error}
        />
      </TableCell>
    </>
  );
};

export default OndcSpecInput;

const styles = {
  tableStickyLeftCell: {
    minWidth: "70px !important",
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: whiteColor,
    padding: "10px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,
    position: "sticky",
    left: 0,
    zIndex: 10,
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  tableBodyCell: (isError) => ({
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: isError ? lightRedColor : whiteColor,
    padding: "8px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,

    boxShadow: isError ? `0px -1px 0 0 ${redColor} inset` : "none",
  }),

  tableStickyLeftCell: {
    minWidth: "70px !important",
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: whiteColor,
    padding: "10px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,
    position: "sticky",
    left: 0,
    zIndex: 10,
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  tableStickyRightCell: {
    position: "sticky",
    right: 0,
    boxShadow: `1px 0 0 0 ${borderTextBoxColor} inset`,
  },
};

// [
//   {
//       "id": "31a8362232c54eee919cd1c50582a74f",
//       "skuId": "ALU03",
//       "specList": [
//           {
//               "code": "brand",
//               "value": "brand",
//               "inputValue": ""
//           },
//           {
//               "code": "brand2",
//               "value": "brand2",
//               "inputValue": ""
//           },
//           {
//               "code": "brand3",
//               "value": "brand3",
//               "inputValue": ""
//           },
//           {
//               "code": "brand4",
//               "value": "brand4",
//               "inputValue": ""
//           },
//           {
//               "code": "brand5",
//               "value": "brand5",
//               "inputValue": ""
//           },
//       ]
//   },
//   {
//       "id": "31a8362232c54eee91ddd1c50582a74f",
//       "skuId": "ALU05",
//       "specList": [
//           {
//               "code": "brand",
//               "value": "brand",
//               "inputValue": ""
//           },
//           {
//               "code": "brand2",
//               "value": "brand2",
//               "inputValue": ""
//           },
//           {
//               "code": "brand3",
//               "value": "brand3",
//               "inputValue": ""
//           },
//           {
//               "code": "brand4",
//               "value": "brand4",
//               "inputValue": ""
//           },
//           {
//               "code": "brand5",
//               "value": "brand5",
//               "inputValue": ""
//           },
//       ]
//   },
//   {
//       "id": "31a8362232c54eee919cdfff0582a74f",
//       "skuId": "ALU07",
//       "specList": [
//           {
//               "code": "brand",
//               "value": "brand",
//               "inputValue": ""
//           },
//           {
//               "code": "brand2",
//               "value": "brand2",
//               "inputValue": ""
//           },
//           {
//               "code": "brand3",
//               "value": "brand3",
//               "inputValue": ""
//           },
//           {
//               "code": "brand4",
//               "value": "brand4",
//               "inputValue": ""
//           },
//           {
//               "code": "brand5",
//               "value": "brand5",
//               "inputValue": ""
//           },
//       ]
//   },
// ]
