import { getApiInstance } from "../../service/AppService";

const businessTypeList = [
  {
    code: "BT004",
    name: "Business to Business",
    status: "active",
  },
  {
    code: "BT010",
    name: "Farmers, Growers & Packers",
    status: "active",
  },

  {
    code: "BT011",
    name: "Health Care",
    status: "active",
  },
  {
    code: "BT013",
    name: "Manufacturers/Industries",
    status: "active",
  },
  {
    code: "BT015",
    name: "Retailers",
    status: "active",
  },
  {
    code: "BT020",
    name: "Wholesalers/Distributors",
    status: "active",
  },
  {
    code: "BT023",
    name: "Food Industry",
    status: "active",
  },
];

const businessCategoryList = [
  {
    code: "PC001",
    name: "Agriculture & Farming",
    status: "active",
  },
  {
    code: "PC002",
    name: "Animals, Pets & Supplies",
    status: "active",
  },

  {
    code: "PC003",
    name: "Clothing & Accessories",
    status: "active",
  },
  {
    code: "PC004",
    name: "Arts, Crafts & Collectibles",
    status: "active",
  },
  {
    code: "PC005",
    name: "Automobiles & Accessories",
    status: "active",
  },
  {
    code: "PC006",
    name: "Baby & Toddler Products",
    status: "active",
  },
  {
    code: "PC007",
    name: "Beauty & Personal Care",
    status: "active",
  },
  {
    code: "PC008",
    name: "Books",
    status: "active",
  },
  {
    code: "PC009",
    name: "Computers & Networking",
    status: "active",
  },

  {
    code: "PC0010",
    name: "Construction",
    status: "active",
  },
  {
    code: "PC0011",
    name: "Education",
    status: "active",
  },
  {
    code: "PC0012",
    name: "Electronics",
    status: "active",
  },
  {
    code: "PC0013",
    name: "Electrical & Hardware",
    status: "active",
  },
  {
    code: "PC0015",
    name: "Food & Beverages",
    status: "active",
  },
];

export const storeLeads = async (data) => {
  const instance = getApiInstance();

  let businessCatList = [];

  data?.businessCategory?.forEach((business) => {
    businessCategoryList.forEach((item) => {
      if (business == item.code) {
        businessCatList.push(item);
      }
    });
  });

  const businessType = businessTypeList.filter((item) => {
    return item.code == data.businessType;
  });

  // console.log("BUSINESS_TYPE", businessType);
  // console.log("BUSINESS_CAT", businessCatList);

  const postData = {
    phone: data?.phone,
    contactName: data?.contactName,
    businessName: data?.businessName,
    email: data?.email,
    businessTypes: businessType,
    businessCategories: businessCatList,
    callbackHours: [
      {
        dayOfWeek: 1,
        from: "",
        to: "",
      },
    ],
    notes: data?.timeToCall,
  };

  // console.log('LEAD_POST_DATA', postData);

  try {
    const response = await instance.post(
      `https://web.costbo.com/business-query-side/api/v1/lead/business-lead`,
      postData,
      {
        headers: {
            campaignKey: 'lead-campaign-mender-4321',
        },
      },
    )
    // console.log('LEAD_DATA', response)
    return response
  } catch (error) {
    console.log(error)
  }
};

export const eRegister = async (data) => {
  // console.log(data);
  const instance = getApiInstance();
  try {
    const response = await instance.post(
      `https://web.costbo.com/costbouaa/api/eRegister`,
      data,
      {
        headers: {
          profileId: sessionStorage.getItem("profileId"),
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
