import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllDeliveryMethodsApi, getCountryNamesApi, getGstRatesApi } from "../../service/api_calls/catalog/CatalogAPI";

const initialState = {
    countryList: [],
    gstRates:[],
    allDeliveryMethod: [],

    countryListLoading: false,
    gstRatesLoading: false,
    allDeliveryMethodLoading: false,
};

// Fetch all country names
export const getCountryNames = createAsyncThunk(
  "getCountryNames",
  async (param, thunkAPI) => {
    const res = await getCountryNamesApi()
    return res;
  }
);

// Fetch GST rates
export const getGstRates = createAsyncThunk(
  "getGstRates",
  async (param, thunkAPI) => {
    const res = await getGstRatesApi()
    return res;
  }
);

// Fetch all delivery methods
export const getAllDeliveryMethods = createAsyncThunk(
  "getAllDeliveryMethods",
  async (param, thunkAPI) => {
    const res = await getAllDeliveryMethodsApi()
    return res;
  }
);

export const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {},
  extraReducers: {
    [getCountryNames.pending]: (state) => {
      state.countryListLoading = true;
    },
    [getCountryNames.fulfilled]: (state, { payload }) => {
      state.countryListLoading = false;
      state.countryList = payload?.details;
    },
    [getCountryNames.rejected]: (state, { payload }) => {
      state.countryListLoading = false;
    },

    // Get GST Rates
    [getGstRates.pending]: (state) => {
      state.gstRatesLoading = true;
    },
    [getGstRates.fulfilled]: (state, { payload }) => {
      state.gstRatesLoading = false;
      state.gstRates = payload?.details;
    },
    [getGstRates.rejected]: (state, { payload }) => {
      state.gstRatesLoading = false;
    },

    // Get all delivery methods
    [getAllDeliveryMethods.pending]: (state) => {
      state.allDeliveryMethodLoading = true;
    },
    [getAllDeliveryMethods.fulfilled]: (state, { payload }) => {
      state.allDeliveryMethodLoading = false;
      state.allDeliveryMethod = payload?.allDeliveryMethods;
    },
    [getAllDeliveryMethods.rejected]: (state, { payload }) => {
      state.allDeliveryMethodLoading = false;
    },
  },
});

export const catalogReducer = catalogSlice.reducer;
