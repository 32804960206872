import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BGColor02,
  blackShade19,
  blackShade21,
  greyColor49,
  lightRedColor02,
  logoImgborder1,
  outOfStockColor,
  whiteColor,
} from "../configs/styles/muiThemes";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import { getStatsCountInfoData } from "../reducers/businessDashboard/BusinessDashboardSlice";
import CatalogBulkUploadContent from "./CatalogBulkUploadNewCopy/CatalogBulkUploadContent";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import HowToLinkCard from "../components/Cards/HowToLinkCard";
import { clearBulkProductInfo } from "../reducers/catalogBulkUploadCopy/catalogBulkUploadSlice";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { getCommerceDashboardInfoAPI } from "../service/api_calls/BusinessPreview/BusinessPreviewAPI";

function CatalogBulkUpload() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [commerceInfo, setCommerceInfo] = useState(null);

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  const { statsCountInfo } = useSelector((state) => state.businessDashboard);

  // Get CommerceInfo
  const getCommerceInfoDetails = async (businessId) => {
    const businessCommerceInfo = await getCommerceDashboardInfoAPI(businessId);
    setCommerceInfo(businessCommerceInfo || null);
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      getCommerceInfoDetails(businessId);

      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }

      // Getting Product and Sales Count
      if (!statsCountInfo) {
        dispatch(getStatsCountInfoData(businessId));
      }
    }
    return () => {
      dispatch(clearBulkProductInfo());
    };
  }, []);

  return (
    <Box backgroundColor={BGColor02}>
      <DashboardLayout  activeSubMenu='bulkUploadNew' activeMenu="catalog" >
        <Box sx={{ minHeight: 'calc(100vh - 92px)', py: 2 }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={{ xs: 2, md: 0 }}
            mb={2}
          >
            <Box>
              <Typography
                variant="h4"
                color={blackShade19}
                fontSize={{ xs: "15px", md: "17px" }}
                fontWeight="600"
                m={{xs:"10px 0 8px", md:'0 0 8px'}}
              >
                Bulk Catalog Upload
              </Typography>

              <Typography
                variant="h6"
                color={blackShade21}
                fontSize="13px"
                fontWeight={{ xs: 500, md: 500 }}
                lineHeight={{ xs: "20px", md: "27px" }}
              >
                Have 100s of catalog to upload, we got you covered. Please
                follow the below steps
              </Typography>
            </Box>

            {/*  <HowToLinkCard /> */}
          </Box>

          {commerceInfo?.status === "new" && (
            <Box
              sx={{
                bgcolor: lightRedColor02,
                p: "8px 15px",
                borderRadius: "10px",
                mb: 2,
              }}
            >
              <Typography
                fontSize={{ xs: 12, md: 13 }}
                fontWeight={500}
                color={outOfStockColor}
                lineHeight="21px"
              >
                E-Commerce Controls are not configured yet for this business.
                Update E-Commerce details and try Bulk upload for products
                again.{" "}
                <Link
                  to={`/addCommerceInfo`}
                  style={{
                    color: greyColor49,
                    textDecoration: "underline",
                  }}
                >
                  Click Here
                </Link>
              </Typography>
            </Box>
          )}

          {matches && activeBusinessInfo && (
            <CatalogBulkUploadContent
              activeBusinessInfo={activeBusinessInfo}
              isDisabled={commerceInfo?.status === "new"}
            />
          )}

          {!matches && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: whiteColor,
                border: logoImgborder1,
                borderRadius: "10px",
                p: "12px",
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  width: "45px",
                  height: "45px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "25px",
                  backgroundColor: "#E83251",
                  mb: 2,
                }}
              >
                <FileUploadOutlinedIcon
                  sx={{ fontSize: "24px", color: whiteColor }}
                />
              </Box>

              <Typography sx={styles.infoText}>
                Bulk Catalog Upload feature is currently available only on our
                browser web version.
              </Typography>

              <Typography sx={styles.infoText} my={1.5}>
                For the best experience and to use this feature, please switch
                to a desktop or laptop computer and access our site through a
                standard web browser.
              </Typography>

              <Typography sx={styles.infoText}>
                We appreciate your understanding
              </Typography>

              <Typography
                sx={{
                  ...styles.infoText,
                  color: "#E83251",
                  textDecoration: "underline",
                }}
              >
                https://business.costbo.com/
              </Typography>
            </Box>
          )}
        </Box>
      </DashboardLayout>
    </Box>
  );
}

export default CatalogBulkUpload;

const styles = {
  infoText: {
    fontSize: 13,
    fontWeight: "500",
    color: blackShade19,
  },
};
