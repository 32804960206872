export const STORAGE_BASE_URL =
  "https://storage.googleapis.com/bodefaults/businessweb/business-home-page-v2/";

export const dealsEmptyMessage =
  "No Active Deals/Coupons. Please check back later!";
export const reviewEmptyMessage = "No customer reviews yet";
export const catalogEmptyMessage =
  "We are still building our store, check back later for exciting product range";
export const inputError_NumberPasswordNotMatch =
  "Mobile number and Password do not match. Please try again";
export const inputError_NotRegistered =
  "Not a registered Mobile number. Please register in CostBo";
export const forgotPassword_Title = "Forgot your password?";

export const enterPasswordMsg = "Enter Password";
export const enterPhoneNumberMsg = "Enter Phone Number";

export const resetPassword_Title = "Reset Password";
export const resetPassword_SubTitle = "Password reset key has been sent to";
export const resetPassword_EnterKey = "Reset Key From Your Email (5 digits)";
export const resetPassword_EnterPassword = "(Min 8 chars Max 25 chars)";
export const inputError_EnterValidKey = "Please enter a valid Reset key";
export const inputError_PasswordRequirementNotMet =
  "Password requirements not met";

export const inputError_EmailNotMatch =
  "Email and Re-enter email does not match";
export const inputError_EmailUsedAlready = "Email address already in use";

export const inputLabel_OneUpperChar = "One Upper case character";
export const inputLabel_OneSpecialChar = "One Special character";
export const inputLabel_MinChar = "8 characters minimum";
export const inputLabel_OneNum = "One number";

export const register_Title = "Register Details";
export const register_EnterName = "Enter Full Name";
export const register_EnterEmail = "Enter Email";
export const register_ReEnterEmail = "Re-Enter Email";
export const register_EnterNewPassword = "Enter New Password";

export const navBar_numberRegisteredMsg =
  "Your phone number is already registered. Please login";
export const inputError_AlreadyRegistered =
  "You have already registered CostBo account. Please sign in";
export const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
      },
    },
  ],
};

export const allOrdersEmptyMessage = "It’s time to place your first order!";
export const OrderEmptyDescription = "Explore our amazing range of products";

export const openOrdersEmptyMessage = "No open orders!";

export const completedOrdersEmptyMessage = "No completed orders!";

export const cancelledOrdersEmptyMessage = "No cancelled orders!";

export const otherOrdersEmptyMessage = "No other orders!";

export const emptyOrderBtnText = "Continue Shopping";

export const cardEmptyMsg = "Your shopping cart is empty";
export const cardEmptyDescriptionMsg = "Let’s get it rolling";
export const cardEmptyBtnText = "ADD ITEM TO CART";

export const cardEmptyGuestMsg = "Missing cart items ?";
export const cardEmptyGuestDescriptionMsg =
  "Login to see previously added items";
export const cardEmptyGuestBtnText = "LOGIN/SIGNUP";

export const searchEmptyMsg = "No products matching ";

//Cart Page Messages

export const productPriceChangeMsg =
  "Product price has been updated. Please accept or remove from cart. Updated price Rs ";
export const productNotAvailabelMsg =
  "Product is currently not available. Please remove from the cart";
export const OnlineOrderSuspendedMsg =
  "Online ordering has been temporarily suspended. Please check back later";

export const reviewLoginMsg = "Please login to write/update your review";
export const topBarMsg = "Buy online directly from us! Enjoy great savings!";
export const businessClosedMsg = "This business/store is temporarily closed.";

export const onlineOrderClosedMsg =
  "Online ordering is temporarily paused. Please check back later";
export const commingSoonMsg = `We are currently adding exciting range of products and discount to serve you better. Please check back later.`;

export const businessActiveMsg =
  "List of your active businesses in CostBo. Please click on the business logo/name to further manage the business details .";
export const businessPendingMsg =
  "Our agents will review and approve your business details as soon as possible. For further enquiries please contact +91 8088422611";
export const businessDraftMsg =
  "Following businesses are in draft status. Please click on the business logo/name to complete the details and submit for approval. Once submitted our agents will review and approve the business within 48 hours.";
export const businessRejectedMsg =
  "Following businesses are rejected by our agents. Please click on the business logo/name to review the rejection reason. Update the required details and submit for approval. Once submitted our agents will review and approve the business within 48 hours.";

export const activeBusinessEmptyMsg =
  "No active businesses in CostBo, Please add a new business or complete the approval process for pending business.";
export const pendingBusinessEmptyMsg = "No business pending approval";
export const draftBusinessEmptyMsg = "No business in draft status.";
export const rejectedBusinessEmptyMsg = "No business in rejected status.";

export const activeProductEmptyMsg =
  "No active products found, Please add a new product or complete the approval process for pending products.";
export const pendingProductEmptyMsg = "No products pending approval";
export const draftProductEmptyMsg = "No products in draft status.";
export const rejectedProductEmptyMsg = "No products in rejected status.";

export const inputError_OTPIncorrent = "Incorrect OTP ReEnter";

export const LATITUDE = "12.9634";
export const LONGITUDE = "77.5855";
// export const LATITUDE = "13";
// export const LONGITUDE = "70";

export const GOOGLE_MAPS_API_KEY = "AIzaSyAzzu692ouH2AXFzesPIVLfHeO8RmhN44Q";
// export const GOOGLE_MAPS_API_KEY = "AIzaSyB7aGKnPAPaKmQfDFE2u18ybVDZdrIqrrc";

// Inventory
export const OutOfStockMsg1 =
  "The below products are ‘Out of Stock’ and are not orderable by the customer.";
export const OutOfStockMsg2 =
  "For stock update - ‘Available Quantity’ should be at least 3 times more than ‘Maximum Order Quantity (per order)’.";
export const OutOfStockMsg3 =
  "You can update stock for multiple products and click on ‘UPDATEINVENTORY’ button to save the details.";

export const whatsAppNumber = "+918088422615";
export const emailAddress = "support@costbo.com";

export const ECOMINPUTSUPPORTLABEL =
  "Any queries about filling the data below please contact via";
