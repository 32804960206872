import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackColor,
  heroSectionText,
  mobilePrimary,
  whiteColor,
} from "../../configs/styles/muiThemes";
import SearchBoxInput from "../../components/Input/SearchBoxInput";
import VideoCard from "./VideoCard";
import moment from "moment";
import { GUIDE_DATA } from "../../utils/data";
import { useLocation } from "react-router-dom";
import { stickyHeader } from "../../utils/styles";

function GuideContent() {
  const [active, setActive] = useState("all");
  const [activeGuides, setActiveGuides] = useState(GUIDE_DATA);

  const location = useLocation();

  const categories = [
    { label: "All", value: "all", count: "5" },
    {
      label: "Getting started/Add Business",
      value: "gettingStarted",
      count: "1",
    },
    { label: "E-Commerce Controls", value: "eCommerceControls", count: "1" },
    { label: "Catalog Management", value: "catalog", count: "1" },
    { label: "Inventory Management", value: "inventory", count: "1" },
    { label: "Order Management", value: "order", count: "1" },
  ];

  const onCategoryChange = (value) => {
    setActive(value);
    if (value === "all") {
      setActiveGuides(GUIDE_DATA);
      return;
    }
    const filteredList = GUIDE_DATA?.filter(
      (data) => data?.category?.value === value
    );

    setActiveGuides(filteredList);
  };

  useEffect(() => {
    if (location?.state?.status) {
      onCategoryChange(location?.state?.status);
    }
  }, []);

  return (
    <Box>
      <Box sx={{ ...stickyHeader, top: { md: "45px", xs: "58px" }, pb: 1.5 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            component="h6"
            fontSize={{ xs: 14.5, md: 17 }}
            color={blackColor}
            fontWeight={600}
            lineHeight="22px"
            m={{xs:'-2px 0 3px', md:0}}
          >
            How-to Video Guides
          </Typography>

          {/* <SearchBoxInput label="" placeholder="Search Guides" /> */}
        </Box>

        <Box display={"flex"} gap={2} flexWrap={"wrap"} mt={1.5}>
          {categories?.map((category, index) => (
            <CategoryChip
              label={category?.label}
              active={active === category.value}
              count={category.count}
              key={index}
              onCategoryChange={() => onCategoryChange(category?.value)}
            />
          ))}
        </Box>
      </Box>

      <Grid container spacing={3} mt={-1}>
        {activeGuides?.map((data, index) => (
          <Grid item md={4} xs={12} key={index}>
            <VideoCard
              url={data?.url}
              title={data?.title}
              category={data?.category?.label}
              date={data?.date}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default GuideContent;

const CategoryChip = ({ label, count, active, onCategoryChange }) => {
  return (
    <Box sx={styles.categoryChipCard(active)} onClick={onCategoryChange}>
      <Box sx={styles.badgeDot}>{count}</Box>
      {label}
    </Box>
  );
};

const styles = {
  categoryChipCard: (active) => ({
    p: "4px 15px",
    fontSize: 11.5,
    fontWeight: 500,
    color: active ? { xs: "#e83658", md: whiteColor } : blackColor,
    backgroundColor: active
      ? { xs: "#fdedf0", md: heroSectionText }
      : whiteColor,
    borderRadius: "60px",
    border: active
      ? { xs: "1px solid #e83658", md: "1px solid #e7eaef" }
      : "1px solid #e7eaef",
    position: "relative",
    cursor: "pointer",
    "&:hover": {
      color: { xs: "#e83658", md: whiteColor },
      backgroundColor: { xs: "#fdedf0", md: heroSectionText },
    },
  }),
  badgeDot: {
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    fontSize: "10px",
    fontWeight: 600,
    color: { xs: whiteColor },
    backgroundColor: { md: "#fdca4a", xs: mobilePrimary },
    position: "absolute",
    top: -4,
    right: -6,
  },
};
