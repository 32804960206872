import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  blackShade05,
  greyColor35,
  greyColor6,
} from "../../../configs/styles/muiThemes";
import FormInputBox from "../../../components/Input/FormInputBox";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import { FULL_CANCEL_STAGE, PARTIAL_CANCEL_STAGE } from "../../../utils/data";
import RadioOptionWithLabel from "../../../components/Radio/RadioOptionWithLabel";
import DateInput from "../../../components/Input/DateInput";
import CheckBoxWithLabel from "../../../components/Input/CheckBoxWithLabel";
import RadiosBtnWithLabel from "../../../components/Input/RadiosBtnWithLabel";
import StoreTimeMultiInput from "./StoreTimeMultiInput";

const TimingHolidayForm = ({
  data,
  setData,
  handleOndcInfoChange,
}) => {
  const handleStoreDayChange = (index, value) => {
    const daysList = data?.ondc?.storeDays;

    daysList[index] = {
      ...daysList[index],
      isOpen: !value,
    };

    setData((prevState) => ({
      ...prevState,
      ondc: {
        ...prevState.ondc,
        storeDays: daysList,
      },
      errors: { ...prevState?.errors, storeDays: "" },
    }));
  };

  const handleOpen24Hr = () => {
    handleOndcInfoChange("isOpen24Hr", "yes");
  };

  return (
    <Box>
      <Typography
        component="h6"
        sx={{ fontSize: 15, fontWeight: 600, color: blackShade05, mt: 2 }}
      >
        Business Timing
      </Typography>

      <Box m="5px 0 10px">
        <Grid container rowSpacing={{xs:0, md:2}} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <RadiosBtnWithLabel
              label="Open 24 Hrs"
              checked={data?.ondc?.isOpen24Hr === "yes"}
              onValuesChanged={() => {
                handleOpen24Hr();
              }}
              labelStyles={styles.radioLabel}
            />
          </Grid>

          <Grid item xs={12} md={3} ml={{md:-8,xs:0}}>
            <RadiosBtnWithLabel
              label="Select specific days"
              checked={data?.ondc?.isOpen24Hr === "no"}
              onValuesChanged={() => {
                handleOndcInfoChange("isOpen24Hr", "no");
              }}
              labelStyles={styles.radioLabel}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ opacity: 0.7 }} />

      {data?.ondc?.isOpen24Hr === "no" && (
        <>
          <Box mt={1.5}>
            <Typography
              component="p"
              fontSize={13}
              color={greyColor6}
              display="inline"
              mb={1}
            >
              Select specific days{" "}
              <Typography
                variant="body1"
                sx={{ display: "inline", color: "#f00" }}
              >
                *
              </Typography>
            </Typography>

            <Grid container rowSpacing={{xs:0, md:2}} columnSpacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container rowSpacing={{xs:0, md:1}} columnSpacing={3}>
                  {data?.ondc?.storeDays?.map((item, index) => (
                    <Grid item xs={12} md={3}>
                      <CheckBoxWithLabel
                        checked={item?.isOpen}
                        label={item?.label}
                        checkBoxStyle={{
                          height: {xs:'30px', md:'auto'},
                          "&.Mui-checked": {
                            color: greyColor35,
                          },
                        }}
                        labelStyle={{
                          fontSize: 13,
                          color: greyColor35,
                        }}
                        onValuesChanged={() =>
                          handleStoreDayChange(index, item?.isOpen)
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Typography
              component="p"
              fontSize={12}
              fontWeight={500}
              sx={{ mt: "4px !important", color: "red" }}
            >
              {data?.errors?.storeDays}
            </Typography>
          </Box>

          <Box mt={1}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography
                  component="h6"
                  sx={{ ...styles.sectionHeader, mb: 1 }}
                >
                  Store Timing
                </Typography>

                <StoreTimeMultiInput
                  inputList={data?.ondc?.storeTimeList}
                  isError={data?.errors?.storeStartTime}
                  setIsError={(errorMsg)=>{
                    setData((prevState)=>({
                      ...prevState,
                      errors:{...prevState?.errors, storeStartTime: errorMsg}
                    }))
                  }}
                  setInputList={(updatedData) => {
                    handleOndcInfoChange("storeTimeList", updatedData);
                  }}
                />

                {/* <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={12} md={4}>
                    <FormInputBox
                      label="Start Time"
                      placeholder=""
                      required
                      value={data?.ondc?.storeStartTime}
                      onChange={({ target }) => {
                        handleOndcInfoChange("storeStartTime", target.value);
                      }}
                      inputType="time"
                      error={data?.errors?.storeStartTime}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormInputBox
                      label="End Time"
                      placeholder=""
                      required
                      value={data?.ondc?.storeEndTime}
                      onChange={({ target }) => {
                        handleOndcInfoChange("storeEndTime", target.value);
                      }}
                      inputType="time"
                      error={data?.errors?.storeEndTime}
                    />
                  </Grid>
                </Grid> */}

                {/* <Typography
                  component="p"
                  fontSize={12}
                  fontWeight={500}
                  sx={{ mt: "-6px !important", color: "red" }}
                >
                  {data?.errors?.storeStartTime}
                </Typography> */}
              </Grid>

              {/* <Grid item xs={12} md={6}>
            <Typography component="h6" sx={{ ...styles.sectionHeader, mb: 1 }}>
              Break Timing
            </Typography>

            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} md={4}>
                <FormInputBox
                  label="Start Time"
                  placeholder=""
                  required
                  value={data?.ondc?.breakStartTime}
                  onChange={({ target }) => {
                    console.log("Start_Time", target.value);
                    handleOndcInfoChange("breakStartTime", target.value);
                  }}
                  inputType="time"
                  error={data?.errors?.breakStartTime}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormInputBox
                  label="End Time"
                  placeholder=""
                  required
                  value={data?.ondc?.breakEndTime}
                  onChange={({ target }) => {
                    handleOndcInfoChange("breakEndTime", target.value);
                  }}
                  inputType="time"
                  error={data?.errors?.breakEndTime}
                />
              </Grid>
            </Grid>

            <Typography
              component="p"
              fontSize={12}
              fontWeight={500}
              sx={{ mt: "-6px !important", color: "red" }}
            >
              {data?.errors?.breakTime}
            </Typography>
          </Grid> */}
            </Grid>
          </Box>
        </>
      )}

      <Box mt={2}>
        <Typography component="h6" sx={{ ...styles.sectionHeader, mb: 0.8 }}>
          Holiday Schedule
        </Typography>

        <Typography
          component="h6"
          sx={{
            fontSize: 13,
            fontWeight: 400,
            color: greyColor6,
            mb: 1.5,
          }}
        >
          Enter any planned holidays in next 3 months, when the store won't be
          operational
        </Typography>

        <Grid container rowSpacing={2.2} columnSpacing={3}>
          <Grid item xs={6} md={2}>
            <DateInput
              label="Start Date"
              date={data?.ondc?.holidayStartDate}
              onDateChange={({ target }) => {
                handleOndcInfoChange("holidayStartDate", target.value);
              }}
              error={data?.errors?.holidayStartDate}
            />
          </Grid>
          <Grid item xs={6} md={2.2}>
            <DateInput
              label="End Date"
              date={data?.ondc?.holidayEndDate}
              onDateChange={({ target }) => {
                handleOndcInfoChange("holidayEndDate", target.value);
              }}
              error={data?.errors?.holidayEndDate}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TimingHolidayForm;

const styles = {
  sectionHeader: {
    fontSize: { xs: 14, md: 14 },
    color: blackColor,
    fontWeight: 600,
    lineHeight: "20px",
    mb: 2,
  },

  radioLabel: {
    color: blackShade05,
    fontSize: 13,
    fontWeight: 600,
  },
};
