import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDeliveredOrderInfo,
  clearIntransitOrderInfo,
  getIntransitOrderData,
  handleRefreshTabData,
  refreshIntransitOrderData,
  refreshPendingOrderData,
} from "../../../reducers/orders/ordersSlice";
import ListEmptyCard from "../../../components/ListEmptyCard";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import moment from "moment";
import { getOrderDeliveryTypeText, getOrderType } from "../../../utils/helper";
import InfiniteScroll from "react-infinite-scroll-component";
import OrderIntransitCard from "../Cards/OrderIntransitCard";
import SearchOrdersById from "../SearchOrdersById";
import HorizontalStepper from "../../../components/HorizontalStepper";
import OrderCancelPopup from "../CancelOrderPopup/OrderCancelPopup";

import {
  blackShade23,
  greyColor42,
  orderCardBorder,
  orderCardHeadingBorder2,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { stickyHeader } from "../../../utils/styles";
import {
  deliveryStatusShippmentApi,
  orderDeliveredShippmentApi,
} from "../../../service/api_calls/orders/OrdersApi";
import ActionInfoHeader from "../Cards/ActionInfoHeader";
function IntransitOrdersContent({
  handleTrackOrder,
  handleViewOrder,
  steps,
  tabValue,
  handleResendB2B,
  handleResend,
  error,
  success,
  commerceInfo,
  getOndcBapNameBySubscriberId,
  downloadShipingLabel,
}) {
  const PAGE_SIZE = 30;

  const dispatch = useDispatch();
  const [hasMoreData, setHasMoreData] = useState(true);

  const [isSearchActive, setIsSearchActive] = useState(false);

  const [openCancelOrder, setOpenCancelOrder] = useState({
    show: false,
    data: null,
  });

  const [openApproveOrder, setOpenApproveOrder] = useState({
    show: false,
    data: null,
  });

  const { intransitOrders, intransitPageNo, intransitOrdersLoader } =
    useSelector((state) => state.businessOrders);

  const getOrdersData = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      getIntransitOrderData({
        businessId,
        pageSize: PAGE_SIZE,
        pageNo: intransitPageNo,
      })
    );
  };

  const fetchNextData = () => {
    // console.log("Next Hit");
    getOrdersData();
  };

  // Open Cancel Order Popup
  const handleCancelOrder = (data) => {
    console.log("CANCEL_ORDER__:", data);
    setOpenCancelOrder({ show: true, data });
    // dispatch(handleRefreshTabData());
  };

  // handle Searching
  const onSearchStart = () => {
    setIsSearchActive(true);
    dispatch(clearIntransitOrderInfo());
  };

  const onSearchClear = () => {
    setIsSearchActive(false);
    getOrdersData();
  };

  // Open Approve Order Popup
  const handleApproveOrder = (data) => {
    setOpenApproveOrder({ show: true, data });
  };

  const handleApproveSuccess = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    setOpenApproveOrder({ show: false, data: null });
    dispatch(handleRefreshTabData());
    dispatch(
      refreshPendingOrderData({
        businessId,
        pageSize: PAGE_SIZE,
        pageNo: 1,
      })
    );
  };
  const handleSucessIntransit = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    setOpenApproveOrder({ show: false, data: null });
    dispatch(handleRefreshTabData());
    dispatch(
      refreshIntransitOrderData({
        businessId,
        pageSize: PAGE_SIZE,
        pageNo: 1,
      })
    );
  };

  const handleShipmentInfoDeliveryStatus = async (orderData) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const updateData = {
      businessId: businessId,
      cartId: orderData?.cartId,
      data: {
        status: "OUTFORDELIVERY",
      },
    };
    const deliveryStatus = await deliveryStatusShippmentApi(updateData);
    if (deliveryStatus) {
      handleSucessIntransit();
    }
  };

  const handleShipmentInfoOrderDelivered = async (orderData) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const updateData = {
      businessId: businessId,
      cartId: orderData?.cartId,
      data: {
        businessNotes: " Order Delivered to Customer",
      },
    };

    const deliveryStatus = await orderDeliveredShippmentApi(updateData);
    if (deliveryStatus) {
      handleSucessIntransit();
    }
  };

  useEffect(() => {
    getOrdersData();
    return () => {
      dispatch(clearIntransitOrderInfo());
    };
  }, []);

  useEffect(() => {
    if ((intransitPageNo - 1) * PAGE_SIZE > intransitOrders?.length) {
      setHasMoreData(false);
    }

    return () => {};
  }, [intransitPageNo]);

  return (
    <Box>
      <SearchOrdersById
        handleTrackOrder={handleTrackOrder}
        handleViewOrder={handleViewOrder}
        handleCancelOrder={handleCancelOrder}
        onSearchStart={onSearchStart}
        onSearchClear={onSearchClear}
        steps={steps}
        activeStep={tabValue}
        tabValue={tabValue}
        handleResendB2B={handleResendB2B}
        handleResend={handleResend}
        error={error}
        success={success}
        disableSearch={intransitOrders?.length == 0}
        getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
        downloadShipingLabel={downloadShipingLabel}
      />
      {/* {tabValue >= 2 && tabValue <= 3 && (
        <Box display={{ xs: "block", md: "none" }} mb={3}>
          <HorizontalStepper steps={steps} activeStep={tabValue} />
        </Box>
      )} */}

      {!intransitOrdersLoader &&
      intransitPageNo === 1 &&
      intransitOrders?.length === 0 &&
      !isSearchActive ? (
        <ListEmptyCard
          icon={
            <ProductionQuantityLimitsOutlinedIcon sx={{ fontSize: "24px" }} />
          }
          text="No In Transit Orders Found"
          hideBtn
        />
      ) : (
        intransitOrdersLoader && intransitPageNo === 1 && <ThreeDotLoader />
      )}
      {intransitOrders?.length > 0 && (
        <Box
          sx={{
            ...stickyHeader,
            pb: { md: 0, xs: 0 },
            pt: 0,
            top: 250,
            display: { md: "block", xs: "none" },
          }}
        >
          <Box sx={styles.card}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3.2}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  ORDER DETAIL
                </Typography>
              </Grid>
              <Grid item xs={6} md={2.5}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  CUSTOMER INFO & PRICE & QTY
                </Typography>
              </Grid>

              <Grid item xs={6} md={2.1}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  DELIVERY TYPE & STATUS
                </Typography>
              </Grid>

              <Grid item xs={12} md={4.2}>
                <Stack flexDirection="row" alignItems="center" gap={0.5}>
                  <Typography
                    variant="h5"
                    fontSize={12.5}
                    fontWeight={500}
                    color={blackShade23}
                  >
                    ACTION
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      {intransitOrders?.length > 0 && (
        <>
          <InfiniteScroll
            dataLength={intransitOrders?.length || 0}
            next={fetchNextData}
            hasMore={hasMoreData}
            loader={
              <Box>
                <ThreeDotLoader />
              </Box>
            }
          >
            {intransitOrders?.map((item, index) => (
              <OrderIntransitCard
                index={index}
                key={`order_${item?.orderId}`}
                id={item?.orderId}
                ondcOrderId={item?.ondcOrderId || ""}
                date={moment(item?.orderCreatedOn)?.format("DD MMM YYYY")}
                username={item?.customerAddressName}
                addressName={item?.customerAddressName || ""}
                city={item?.customerCity}
                addressCity={item?.customerAddressCity || ""}
                orderCost={item?.orderTotalCost}
                cartCount={item?.totalQuantity}
                orderType={getOrderType(item)}
                orderStatus="Shipped"
                deliveryType={getOrderDeliveryTypeText(
                  item?.deliveryType?.type
                )}
                carrierId={item?.shipmentInfo?.carrierTrackId || ""}
                statusUpdatedDate={
                  item?.orderShippedOn
                    ? moment(item?.orderShippedOn).fromNow()
                    : ""
                }
                handleTrackOrder={() => {
                  handleTrackOrder(item);
                }}
                handleViewOrder={() => handleViewOrder(item)}
                handleCancelOrder={() => handleCancelOrder(item)}
                handleResendB2B={handleResendB2B}
                handleResend={handleResend}
                error={error}
                success={success}
                orderData={item}
                handleShipmentInfoDeliveryStatus={
                  handleShipmentInfoDeliveryStatus
                }
                handleShipmentInfoOrderDelivered={
                  handleShipmentInfoOrderDelivered
                }
                getOndcBapNameBySubscriberId={() =>
                  getOndcBapNameBySubscriberId(item?.ondcBapId)
                }
                downloadShipingLabel={downloadShipingLabel}
              />
            ))}
          </InfiniteScroll>
        </>
      )}

      {openCancelOrder?.show && (
        <OrderCancelPopup
          open={openCancelOrder?.show}
          handleClose={() => {
            setOpenCancelOrder({ show: false, data: null });
            handleApproveSuccess();
          }}
          handleCloseModal={() => {
            setOpenCancelOrder({ show: false, data: null });
          }}
          data={openCancelOrder?.data || null}
          commerceInfo={commerceInfo}
          orderShipStatus="transit"
          getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
        />
      )}
    </Box>
  );
}

export default IntransitOrdersContent;
const styles = {
  card: {
    display: { md: "flex", xs: "none" },
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "16px", md: "20px" },
    backgroundColor: greyColor42,
    borderTop: orderCardBorder,
    borderRadius: "7px 7px 0px 0px",
    boxShadow: "0 2px 4px rgb(179 188 198 / 5%)",
    // padding: "10px 10px 0px 10px",
    padding: "7px 15px",
    position: "sticky",
    top: 280,
    zIndex: 10000,
    // marginBottom: "15px",
  },
};
