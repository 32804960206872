import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade23,
  greyColor42,
  orderCardBorder,
  orderCardHeadingBorder2,
} from "../../configs/styles/muiThemes";

function GridTableHeader({ list, mb }) {
  return (
    <Box sx={{ ...styles.card, mb: mb || 0 }}>
      <Grid container spacing={2}>
        {list?.map((item, index) => (
          <Grid item xs={item?.size} md={item?.size} key={item?.label}>
            <Typography
              variant="h5"
              fontSize={13}
              fontWeight={500}
              color={blackShade23}
            >
              {item?.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default GridTableHeader;

const styles = {
  card: {
    display: { md: "flex", xs: "none" },
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "16px", md: "20px" },
    backgroundColor: greyColor42,
    borderTop: orderCardBorder,
    borderRadius: "7px",
    boxShadow: "0 2px 4px rgb(179 188 198 / 5%)",
    padding: "7px 15px",
    // marginBottom: "15px",
  },
};
