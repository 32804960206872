import React, { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import {
  BGColor02,
  blackShade05,
  blackShade22,
  blueColor04,
  greyColor6,
  greyColor8,
  heroSectionText,
  logoImgborder1,
  menuGradient,
  whiteColor,
  yellowColor,
} from "../../../configs/styles/muiThemes";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import ModalCard from "../../../components/ModalCard";
import FormInputBox from "../../../components/Input/FormInputBox";
import { validateUserEmail } from "../../../utils/validationHelper";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router-dom";

const EmailVerifyCard = ({
  isEmailVerified,
  onResend,
  onUpdateEmail,
  resendLoader,
}) => {
  const navigate = useNavigate();

  const [openPopup, setOpenPopup] = useState(false);

  const [newEmail, setNewEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleSubmit = async () => {
    const isEmailValid = validateUserEmail(newEmail);

    if (!isEmailValid) {
      setEmailError("Enter valid email");
      return;
    }

    setEmailError("");
    const isSuccess = await onUpdateEmail(newEmail);

    if (isSuccess) {
      setNewEmail("");
      setOpenPopup(false);
    } else {
      setEmailError("Email already registered in CostBo, try a new email");
    }
  };

  return (
    <Box sx={styles.card}>
      <Typography
        variant="h6"
        fontSize={15.5}
        fontWeight={600}
        color={blackShade05}
        lineHeight="28px"
      >
        Email verification not complete
      </Typography>

      <Box m="5px 0 12px 0 !important">
        <Typography
          variant="body1"
          fontSize={14}
          fontWeight={400}
          lineHeight="20px"
          color={greyColor6}
          sx={{ whiteSpace: "pre-line", display: "inline" }}
        >
          A verification email has been sent to{" "}
          <Typography
            fontSize={14}
            fontWeight={500}
            color={blueColor04}
            display="inline"
          >
            {isEmailVerified?.email || "-"}
          </Typography>
          {isEmailVerified?.activated
            ? ""
            : `. Please complete verification to access business details.`}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" gap="15px" mt={1}>
          <Button
            onClick={onResend}
            disableElevation
            variant="contained"
            sx={{ ...styles.btn }}
            startIcon={
              resendLoader === "resend" ? (
                <CircularProgress
                  size={22}
                  sx={{
                    color: greyColor6,
                  }}
                />
              ) : (
                <ForwardToInboxIcon />
              )
            }
            disabled={resendLoader === "resend"}
          >
            Resend Verification
          </Button>

          <Button
            onClick={() => {
              setOpenPopup(true);
            }}
            disableElevation
            variant="contained"
            sx={{ ...styles.updateBtn, ...styles.outlineBtn }}
            startIcon={
              resendLoader === "update" ? (
                <CircularProgress
                  size={22}
                  sx={{
                    color: greyColor6,
                  }}
                />
              ) : (
                <MarkEmailUnreadOutlinedIcon />
              )
            }
            disabled={resendLoader === "update"}
          >
            Update E-mail & Resend Verification
          </Button>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Button
            onClick={() => {
              navigate(0);
            }}
            disableElevation
            variant="contained"
            sx={{ ...styles.btn }}
            startIcon={<RefreshIcon />}
          >
            Refresh
          </Button>

          <Typography
            variant="body1"
            fontSize={11}
            fontWeight={400}
            color={greyColor6}
            mt={0.5}
          >
            Click on refesh button after validating the email
          </Typography>
        </Box>
      </Box>

      <ModalCard
        open={openPopup}
        handleClose={() => {
          setOpenPopup(false);
          setNewEmail("");
          setEmailError("");
        }}
        width={{ xs: "20%", md: "28%" }}
      >
        <Box sx={styles.modalCard}>
          <Typography
            variant="h3"
            fontSize={16}
            fontWeight={600}
            lineHeight="25px"
            color={blackShade05}
            textAlign="center"
            p="15px 50px"
            mb="15px"
          >
            Update Email
          </Typography>

          <Box
            sx={{
              backgroundColor: whiteColor,
              p: "15px",
            }}
          >
            <FormInputBox
              label="Enter Email"
              placeholder=""
              value={newEmail}
              onChange={({ target }) => {
                setNewEmail(target.value);
              }}
              required
              error={emailError}
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p="15px"
          >
            <Button
              sx={[styles.actionBtn, styles.cancelBtn]}
              onClick={() => {
                setOpenPopup(false);
                setNewEmail("");
                setEmailError("");
              }}
            >
              CANCEL
            </Button>

            <Button
              sx={[styles.actionBtn, styles.proceedBtn]}
              onClick={handleSubmit}
              disabled={resendLoader === "update"}
              startIcon={
                resendLoader === "update" ? (
                  <CircularProgress
                    size={16}
                    sx={{
                      color: greyColor6,
                    }}
                  />
                ) : null
              }
            >
              Submit
            </Button>
          </Box>
        </Box>
      </ModalCard>
    </Box>
  );
};

export default EmailVerifyCard;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "10px",
    border: logoImgborder1,
    padding: "15px 15px 18px 15px",
    my: "15px",
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
  },
  btn: {
    fontSize: 13,
    fontWeight: 600,
    color: whiteColor,
    border: `1px solid ${blueColor04}`,
    backgroundColor: blueColor04,
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
    },

    "&.Mui-disabled": {
      border: "none",
    },
  },
  updateBtn: {
    fontSize: 13,
    fontWeight: 600,
    color: whiteColor,
    border: `1px solid ${yellowColor}`,
    backgroundColor: yellowColor,
    "&:hover": {
      color: yellowColor,
      backgroundColor: whiteColor,
    },

    "&.Mui-disabled": {
      border: "none",
    },
  },

  outlineBtn: {
    fontSize: 13,
    fontWeight: 600,
    color: blackShade05,
    backgroundColor: yellowColor,

    "&:hover": {
      color: yellowColor,
      backgroundColor: whiteColor,
    },
  },

  modalCard: {
    backgroundColor: BGColor02,
    borderRadius: "15px",
  },

  actionBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    color: blackShade22,
    border: `1px solid ${blackShade22}`,
    p: "4px 35px",
  },

  cancelBtn: {
    "&:hover": {
      color: whiteColor,
      backgroundColor: blackShade22,
    },
  },

  proceedBtn: {
    color: whiteColor,
    background: menuGradient,
    p: "5px 35px",
    border: "none",

    "&:hover": {
      color: blackShade22,
      background: whiteColor,
      border: `1px solid ${blackShade22}`,
    },

    "&.Mui-disabled": {
      color: whiteColor,
      background: `${blackShade22} !important`,
      borderColor: blackShade22,
    },
  },
};
