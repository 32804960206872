import { getApiInstance } from "../../AppService";
import {
  ADD_NEW_BRANCH,
  GET_MAIN_BRANCH,
  UPDATE_BUSINESS_WRT_BRANCH,
} from "../../constant";

export const addNewBranchApi = async (data, businessId) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(ADD_NEW_BRANCH, data, {
      headers: { businessId },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const updateBusinessWRTBranchApi = async (data, businessId) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(UPDATE_BUSINESS_WRT_BRANCH, data, {
      headers: { businessId },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const getMainBranchInfoApi = async (businessId) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_MAIN_BRANCH, {
      params: { branchBusinessId: businessId },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
