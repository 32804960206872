import {
  Box,
  Button,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  bgColor,
  blackColor,
  blackShade159,
  blackShade21,
  blackShade22,
  blueColor02,
  blueColor04,
  blueColor1,
  greyColor11,
  greyColor24,
  greyColor28,
  greyColor40,
  greyColor6,
  greyColor7,
  greyColor9,
  greyShade07,
  logoImgborder1,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import InputPreview from "../../../components/Input/InputPreview";
import StyledReadOnlySwitch from "../../../components/Switch/StyledReadOnlySwitch";
import { addThousandsSeparator } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import {
  ECOMMERCE_VIDEO_DATA,
  E_COMMERCE_STEP_LIST,
} from "../../../utils/data";
import { scrollBox, stickyHeader } from "../../../utils/styles";
import HowToLinkCard from "../../../components/Cards/HowToLinkCard";
import VideoModal from "../../../components/VideoModal";

function LogisticManagementContent({ commerceDetails, activeBusinessInfo }) {
  const [costBoDelivery, setCostBoDelivery] = useState(null);
  const [costBoHyperLocal, setCostBoHyperLocal] = useState(null);
  const [fullfillmentInfo, setFullfillmentInfo] = useState(null);
  const [b2bDelivery, setB2bDelivery] = useState(null);
  const [storePickupInfo, setStorePickupInfo] = useState(null);
  const [codInfo, setCodInfo] = useState(null);

  const [isStorePickUpEnabled, setIsStorePickUpEnabled] = useState(false);

  const [tabValue, setTabValue] = useState(1);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const navigate = useNavigate();
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const whatsAppNumber = "+918088422615";
  const emailAddress = "support@costbo.com";
  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Delivery+Methods+Update+Request.+`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };

  const filterDeliveryInfoDetials = (deliveryTypeList) => {
    const costBoDeliveryData = deliveryTypeList?.filter(
      (item) => item?.type === "delhivery"
    );
    const costBoHyperLocalData = deliveryTypeList?.filter(
      (item) => item?.type === "telyport"
    );
    const fullfillmentData = deliveryTypeList?.filter(
      (item) => item?.type === "business"
    );
    const b2bDeliveryData = deliveryTypeList?.filter(
      (item) => item?.type === "delhiveryb2b"
    );

    const codData = commerceDetails?.codInfo?.filter(
      (item) => item?.type === "delhivery"
    );

    if (costBoDeliveryData?.length > 0) {
      setCostBoDelivery(costBoDeliveryData?.[0]);
    }

    if (costBoHyperLocalData?.length > 0) {
      setCostBoHyperLocal(costBoHyperLocalData?.[0]);
    }

    if (fullfillmentData?.length > 0) {
      setFullfillmentInfo(fullfillmentData?.[0]);
    }

    if (b2bDeliveryData?.length > 0) {
      setB2bDelivery(b2bDeliveryData?.[0]);
    }

    if (codData?.length > 0) {
      setCodInfo(codData?.[0]);
    }

    if (
      commerceDetails?.pickUpInfoDetails &&
      commerceDetails?.pickUpInfoDetails?.length > 0
    ) {
      setStorePickupInfo(commerceDetails?.pickUpInfoDetails?.[0]);
    }
  };
  const navigateToTab = (newValue) => {
    if (newValue === 0) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/warehouseDetail`);
    }
    if (newValue === 1) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/deliveryMethods`);
    }
    if (newValue == 2) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/discountDetails`);
    }
    if (newValue == 3) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/otherDetails`);
    }
    if (newValue == 4) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/managers`);
    }
    if (newValue == 5) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/bankInfo`);
    }
  };

  const handleChange = (event, newValue) => {
    navigateToTab(newValue);
  };

  const handleEditDetails = () => {
    navigate(`/editCommerceInfo`);
  };

  useEffect(() => {
    if (commerceDetails) {
      filterDeliveryInfoDetials(commerceDetails?.deliveryTypeInfo || []);
    }

    return () => {};
  }, [commerceDetails]);

  useEffect(() => {
    if (activeBusinessInfo) {
      const isStorePickup = activeBusinessInfo?.deliveryMethods?.some(
        (item) => item?.code === "DM002"
      );

      setIsStorePickUpEnabled(isStorePickup);
    }

    return () => {};
  }, [activeBusinessInfo]);

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  return (
    <Box flex={1}>
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          handleClose={handleCloseVideoModal}
          data={ECOMMERCE_VIDEO_DATA}
        />
      )}
      <Box sx={stickyHeader} pb={1.5}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={{ xs: "row", md: "row" }}
          mb="10px"
        >
          <Typography
            variant="h2"
            fontSize={{ md: 17, xs: 17 }}
            fontWeight={600}
            color={greyColor24}
            lineHeight="28px"
          >
            E-Commerce Controls
          </Typography>
          <HowToLinkCard
            link="E-Commerce Controls"
            handleClick={() => {
              setOpenVideoModal(true);
            }}
            justifyContent="flex-start"
          />
        </Box>
        <Box
          sx={{
            ...scrollBox,
            display: { xs: "block", md: "block" },
            borderBottom: 1,
            borderColor: "divider",
            mb: { xs: "10px", md: 1 },
          }}
        >
          <Tabs
            variant="scrollable"
            value={tabValue}
            onChange={(event, newValue) => handleChange(event, newValue)}
            aria-label="basic tabs example"
            textColor={blackColor}
            TabIndicatorProps={{
              style: {
                height: "3px",
                backgroundColor: matches ? blueColor04 : mobilePrimary,
              },
            }}
            sx={{
              minHeight: "35px !important",
              "& .MuiButtonBase-root": {
                minWidth: "auto !important",
              },
            }}
          >
            {E_COMMERCE_STEP_LIST?.map((item, index) => {
              return (
                <Tab
                  key={"filter_" + index}
                  label={item.label}
                  // label={item.label}
                  sx={{
                    fontSize: { xs: "13px", md: "13.5px" },
                    fontWeight: tabValue == index ? 500 : 500,
                    p: 0,
                    minHeight: "30px !important",
                    mr: { xs: "20px", md: "55px" },
                    color:
                      item.id === tabValue
                        ? matches
                          ? blueColor04
                          : mobilePrimary
                        : greyColor40,
                    textTransform: "capitalize",
                  }}
                />
              );
            })}
          </Tabs>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <Typography
              variant="h4"
              fontSize={15}
              fontWeight={600}
              color={greyColor24}
              lineHeight="28px"
            >
              Delivery Methods
            </Typography>
            {!matches ? (
              <Button onClick={handleEditDetails} sx={styles.editBtn}>
                EDIT
              </Button>
            ) : null}
          </Box>

          {/* <Typography
              variant="h6"
              fontSize={13}
              fontWeight={500}
              color={blackShade21}
              lineHeight="20px"
              // display="inline"
              // mb="15px"
            >
              To modify Delivery options, please contact COSTBO support via{" "}
              <Typography
                // variant="body1"
                fontSize={13}
                fontWeight={500}
                color={{ xs: mobilePrimary, md: blueColor04 }}
                display="inline"
                lineHeight="20px"
                // onclick={handleChatClick}
                component="a"
                href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Delivery+Methods+Update+Request+&fs=1&to=${emailAddress}&tf=cm`}
                target="_blank"
              >
                Email
              </Typography>
              {"  "}
              or{" "}
              <Typography
                // variant="body1"
                fontSize={13}
                fontWeight={500}
                lineHeight="20px"
                color={{ xs: mobilePrimary, md: blueColor04 }}
                display="inline"
                // component="a"
                onClick={handleChatClick}
                // href={`https://mail.google.com/mail/u/0/?fs=1&to=${emailAddress}&tf=cm`}
                // target="_blank"
              >
                Whatsapp
              </Typography>
            </Typography> */}

          {matches ? (
            <Button onClick={handleEditDetails} sx={styles.editBtn}>
              EDIT
            </Button>
          ) : null}
        </Box>
      </Box>
      <Box sx={styles.card}>
        <Box>
          <TitleText
            title="Automated Pan India Delivery"
            subtitle="(Fully automated with tracking)"
          />
          <Box ml={1}>
            <StyledReadOnlySwitch isEnabled={costBoDelivery} />

            <Box m={{ md: "10px 0 0 8px", xs: "10px 0 0" }}>
              {costBoDelivery ? (
                <Grid container spacing={2}>
                  <Grid item xs={6} md={3}>
                    <InputPreview
                      label="From Distance (in KM)"
                      value={`${costBoDelivery?.from} KM`}
                      valueSize={14}
                      hideBorder
                    />
                  </Grid>

                  <Grid item xs={6} md={3}>
                    <InputPreview
                      label="To Distance (in KM)"
                      value={`${costBoDelivery?.to} KM`}
                      valueSize={14}
                      hideBorder
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Box>
          </Box>
        </Box>

        <Box mt={{ md: 1, xs: 2 }} mb={3} ml={{ md: "10px", xs: 0 }}>
          <Box ml={1}>
            <Typography
              component="h5"
              fontSize={{ md: 14, xs: 14 }}
              color={greyColor24}
              fontWeight={600}
              lineHeight="20px"
              display={{ md: "inline", xs: "block" }}
            >
              Cash on Delivery (COD){" "}
              <Typography
                component="span"
                fontSize={{ md: 12, xs: 12 }}
                color={greyColor6}
                display={{ md: "inline", xs: "block" }}
                fontWeight={400}
              >
                (Applicable only for Automated Pan India Delivery)
              </Typography>
            </Typography>
            <Box ml={-1}>
              <StyledReadOnlySwitch isEnabled={codInfo} />
            </Box>
            <Box m="10px 0 8px 0px">
              {codInfo ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <InputPreview
                      label="COD Order Amount Max Limit"
                      value={`₹ ${addThousandsSeparator(codInfo?.limit)}`}
                      valueSize={14}
                      hideBorder
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <InputPreview
                      label="COD fee charged to"
                      value={`${codInfo?.chargeTo}`}
                      valueSize={14}
                      hideBorder
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Box>

            {codInfo && (
              <Typography
                component="p"
                fontSize={12.5}
                color={greyColor7}
                fontWeight={400}
                lineHeight="18px"
              >
                Additional Fee for COD - Amount ₹ 45 or Percentage 3% (of total
                order amount), whichever higher will be charged
              </Typography>
            )}
          </Box>
        </Box>

        <Divider sx={styles.dividerStyle} />

        <Box mt={2} mb={2}>
          <TitleText
            title="Automated Hyperlocal - Bengaluru Only"
            subtitle="(Fully automated with tracking)"
          />
          <Box ml={1}>
            <StyledReadOnlySwitch isEnabled={costBoHyperLocal} />
          </Box>
          <Box m={{ md: "10px 0 15px 16px", xs: "10px 0 0" }}>
            {costBoHyperLocal ? (
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <InputPreview
                    label="From Distance (in KM)"
                    value={`${costBoHyperLocal?.from} KM`}
                    valueSize={14}
                    hideBorder
                  />
                </Grid>

                <Grid item xs={6} md={3}>
                  <InputPreview
                    label="To Distance (in KM)"
                    value={`${costBoHyperLocal?.to} KM`}
                    valueSize={14}
                    hideBorder
                  />
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Box>

        <Divider sx={styles.dividerStyle} />

        <Box mt={2} mb={2}>
          <TitleText title="Fulfillment by Business" />
          <Box ml={1}>
            <StyledReadOnlySwitch isEnabled={fullfillmentInfo} />
          </Box>
          <Box m={{ md: "10px 0 15px 16px", xs: "10px 0 20px" }}>
            {fullfillmentInfo ? (
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <InputPreview
                    label="From Distance (in KM)"
                    value={`${fullfillmentInfo?.from} KM`}
                    valueSize={14}
                    hideBorder
                  />
                </Grid>

                <Grid item xs={6} md={3}>
                  <InputPreview
                    label="To Distance (in KM)"
                    value={`${fullfillmentInfo?.to} KM`}
                    valueSize={14}
                    hideBorder
                  />
                </Grid>

                <Grid item xs={12} md={6.5}>
                  <Typography
                    variant="h6"
                    fontSize={13.5}
                    fontWeight={600}
                    color={greyColor24}
                    lineHeight="18px"
                  >
                    Pricing
                  </Typography>
                  <Box display="flex" alignItems="center" gap="12px" mt={1.5}>
                    <Typography
                      variant="h3"
                      fontSize={13}
                      color={greyColor6}
                      fontWeight={400}
                      width={"250px"}
                    >
                      Order Value
                    </Typography>
                    <Typography
                      variant="h3"
                      fontSize={13}
                      color={greyColor6}
                      fontWeight={400}
                      width={"200px"}
                    >
                      Shipping Cost
                    </Typography>
                  </Box>
                  <Box mb={2}>
                    {commerceDetails?.shipmentCost?.map((item, index) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        gap="12px"
                        mt={1.5}
                        key={`pricing_${index}`}
                      >
                        <Box sx={styles.pricingValueBox}>
                          <Typography
                            variant="body1"
                            sx={styles.pricingValueText}
                          >
                            ₹ {addThousandsSeparator(item?.txnAmount)} to ₹{" "}
                            {addThousandsSeparator(item?.txnAmount2)}
                          </Typography>
                        </Box>

                        <Box sx={styles.pricingValueBox}>
                          <Typography
                            variant="body1"
                            sx={styles.pricingValueText}
                          >
                            ₹ {item?.byFor}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>

                  {/* <InputPreview
                    label="Pricing"
                    value={`${commerceDetails?.shipmentCost
                      ?.map((item) =>
                        item?.txnAmount !== "" &&
                        item?.txnAmount2 !== "" &&
                        item?.byFor !== ""
                          ? `${item?.txnAmount} - ${item?.txnAmount2} ₹${item?.byFor}`
                          : ""
                      )
                      ?.join(", ")}`}
                    valueSize={14}
                    hideBorder
                  /> */}
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Box>

        <Divider sx={styles.dividerStyle} />

        <Box mt={2}>
          <TitleText title="B2B Delivery" subtitle="(Heavy Shipment)" />

          <Typography
            component="h6"
            fontSize={12.5}
            color={{ md: blueColor04, xs: mobilePrimary }}
            fontWeight={600}
            lineHeight="18px"
            m={{ md: "4px 0 0 16px !important", xs: "4px 0 0 !important" }}
          >
            from 25Kg to 5000Kg
          </Typography>
          <Box ml={1}>
            <StyledReadOnlySwitch isEnabled={b2bDelivery} />
          </Box>
          <Box m={{ md: "10px 0 15px 16px", xs: "10px 0 20px " }}>
            {b2bDelivery ? (
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <InputPreview
                    label="From Distance (in KM)"
                    value={`${b2bDelivery?.from} KM`}
                    valueSize={14}
                    hideBorder
                  />
                </Grid>

                <Grid item xs={6} md={3}>
                  <InputPreview
                    label="To Distance (in KM)"
                    value={`${b2bDelivery?.to} KM`}
                    valueSize={14}
                    hideBorder
                  />
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Box>

        <Divider sx={styles.dividerStyle} />

        <Box mt={2}>
          <TitleText title="Store Pickup" />
          <Box ml={1}>
            <StyledReadOnlySwitch isEnabled={isStorePickUpEnabled} />
          </Box>
          <Box m={{ md: "10px 0 15px 16px", xs: "10px 0 6px " }}>
            {isStorePickUpEnabled ? (
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <InputPreview
                    label="Discount Type"
                    value={`${storePickupInfo?.discountBy}`}
                    valueSize={14}
                    hideBorder
                  />
                </Grid>

                <Grid item xs={6} md={3}>
                  <InputPreview
                    label="Discount Amount"
                    value={`₹ ${addThousandsSeparator(
                      storePickupInfo?.discountAmount
                    )}`}
                    valueSize={14}
                    hideBorder
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputPreview
                    label="Discount Percentage"
                    value={`${storePickupInfo?.discountPercentage}%`}
                    valueSize={14}
                    hideBorder
                  />
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default LogisticManagementContent;

const TitleText = ({ title, subtitle }) => {
  return (
    <Box display="flex" alignItems="center" gap="8px">
      <Box sx={styles.dotUi} />

      <Typography
        component="h6"
        fontSize={{ md: 14, xs: 14 }}
        color={greyColor24}
        fontWeight={600}
        lineHeight="18px"
        display={{ md: "inline", xs: "block" }}
      >
        {title}{" "}
        <Typography
          component="span"
          fontSize={{ md: 12, xs: 11.5 }}
          color={greyColor6}
          fontWeight={400}
          display={{ md: "inline", xs: "block" }}
          ml={{ md: "5px !important", xs: 0 }}
        >
          {subtitle}
        </Typography>
      </Typography>
    </Box>
  );
};

const styles = {
  card: {
    p: { xs: "5px 15px", md: "15px" },
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: { xs: "8px", md: "13px" },
    mt: { xs: "5px", md: "0px" },
    border: logoImgborder1,
  },

  dotUi: {
    width: { md: "6px", xs: "5px" },
    height: { md: "6px", xs: "5px" },
    borderRadius: "50%",
    backgroundColor: greyColor24,
    mt: -0.2,
  },

  pricingValueBox: {
    width: "250px",
    border: `1px solid ${greyColor28}`,
    p: "9px",
    borderRadius: "5px",
  },

  pricingValueText: {
    fontSize: { md: 14, xs: 13 },
    fontWeight: 500,
    color: greyColor24,
    lineHeight: "18px",
  },

  dividerStyle: {
    border: {
      xs: "2px solid " + greyColor11,
      md: "1px solid " + greyColor11,
    },
  },

  editBtn: {
    backgroundColor: blackShade22,
    padding: "3px 14px",
    fontSize: "12.5px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + blackShade22,
    "&:hover": {
      color: blackShade22,
      backgroundColor: whiteColor,
    },
  },
};
