import React from "react";
import { Box, Stack, Typography, Button, useMediaQuery } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import {
  blackShade05,
  blackShade19,
  blueColor04,
  border25,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";

function ProductFilterCard({
  title,
  categoryList,
  subCategoryList,
  selectedCategory,
  selectedSubCategory,
  handleCategoryChange,
  handleSubCategoryChange,
  onApply,
  onCancel,
  loader,
  onAllClear,
  error,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box sx={styles.card} position="absolute" top="100%" right={0}>
      <Box
        sx={{
          p: "6px 15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          //   borderBottom: border25,
        }}
      >
        <Typography
          fontSize={{ xs: 13, md: 13.5 }}
          color={blackShade05}
          fontWeight={600}
          lineHeight="20px"
        >
          {title}
        </Typography>
        <Box display="flex" alignItems="center" gap={3}>
          <Typography
            fontSize={{ xs: 12, md: 12 }}
            color={blackShade05}
            fontWeight={600}
            lineHeight="20px"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={onAllClear}
          >
            Clear All
          </Typography>
          <CloseRoundedIcon
            sx={{ fontSize: "15px", fontWeight: 700, cursor: "pointer" }}
            onClick={onCancel}
          />
        </Box>
      </Box>
      <Stack p={"0px 15px 15px"} gap={1}>
        <SelectWithLabel
          label="Category"
          value={selectedCategory}
          onValueChange={handleCategoryChange}
          menuList={categoryList || []}
          rounded
          borderFull
        />
        <SelectWithLabel
          label="Sub Category"
          value={selectedSubCategory}
          onValueChange={handleSubCategoryChange}
          menuList={subCategoryList || []}
          disabled={subCategoryList?.length === 0}
          rounded
          borderFull
        />

        {error ? (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={12}
            fontWeight={500}
            color="red"
          >
            {error}
          </Typography>
        ) : null}
      </Stack>
      <Box
        display="flex"
        justifyContent={"flex-end"}
        gap={2}
        sx={{ padding: { md: "6px 12px", xs: "0 12px 6px" } }}
      >
        <Button
          sx={styles.actionBtn(
            whiteColor,
            matches ? blueColor04 : blackShade19
          )}
          onClick={onCancel}
          disabled={loader}
        >
          Cancel
        </Button>
        <Button
          sx={styles.actionBtn(
            matches ? blueColor04 : mobilePrimary,
            whiteColor
          )}
          onClick={onApply}
          disabled={loader}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
}

export default ProductFilterCard;

const styles = {
  card: {
    width: { md: "420px", xs: "93vw" },
    backgroundColor: whiteColor,
    borderRadius: "8px",
    border: border25,
    boxShadow: "0 1px 3px rgb(49 49 49 / 15%)",
    my: 2,
    // overflow: "hidden",
    zIndex: 999,
  },
  actionBtn: (bgColor, textcolor) => ({
    padding: { md: "3px 12px", xs: "6px 12px" },
    fontSize: "11px",
    fontWeight: 600,
    color: textcolor,
    backgroundColor: bgColor,
    border: "1px solid " + textcolor,
    "&:hover": {
      color: bgColor,
      backgroundColor: textcolor,
      border: "1px solid " + bgColor,
    },
  }),
};
