import { clearBusinessDashboardData } from "../reducers/businessDashboard/BusinessDashboardSlice";
import { getMyBusinessSummary, getUserEditEligibility } from "../reducers/businessOnboard/businessOnboardSlice";
import { clearBusinessPreviewData, clearCommerceInfo } from "../reducers/businessPreview/businessPreviewSlice";
import { clearCashbackInfo } from "../reducers/cashback/CashbackSlice";
import { clearCouponInfo } from "../reducers/coupons/couponSlice";
import { clearCRMInfo } from "../reducers/crmcustomerdata/customerSlice";
import { clearManagersInfo } from "../reducers/ecommerceControl/EcommerceControlSlice";
import { clearAllInventoryInfo } from "../reducers/inventory/inventorySlice";
import { clearMaterBranchInfo, clearMultiBranchInfo } from "../reducers/multiBranchCatalog/multiBranchCatalogSlice";
import { clearOrderInfo } from "../reducers/orders/ordersSlice";
import { clearPreferredDiscountsInfo } from "../reducers/preferredDiscount/PreferredDiscountSlice";

export const clearReduxState = (dispatch)=>{
    sessionStorage.removeItem("selectedBusinessId");
    // Clear Data
    dispatch(clearBusinessPreviewData());
    dispatch(clearCouponInfo());
    dispatch(clearOrderInfo());
    dispatch(clearCRMInfo());
    dispatch(clearPreferredDiscountsInfo());
    dispatch(clearCashbackInfo());
    dispatch(clearManagersInfo());
    dispatch(clearAllInventoryInfo());

    dispatch(getMyBusinessSummary());
    dispatch(getUserEditEligibility());

    dispatch(clearMultiBranchInfo());
    dispatch(clearMaterBranchInfo());
    

    dispatch(clearBusinessDashboardData());
    dispatch(clearCommerceInfo());
}