import {
  Box,
  Button,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackColor,
  blackShade01,
  blackShade05,
  blackShade22,
  blackShade23,
  blueColor04,
  blueColor1,
  borderTextBoxColor,
  borderTextbox,
  greenColor5,
  greyColor1,
  greyColor24,
  greyColor40,
  greyColor42,
  greyColor9,
  greyShade09,
  mobilePrimary,
  orderCardHeadingBorder2,
  redColor07,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import {
  ECOMMERCE_VIDEO_DATA,
  E_COMMERCE_STEP_LIST,
} from "../../../utils/data";
import { useNavigate } from "react-router-dom";
import { scrollBox } from "../../../utils/styles";
import HowToLinkCard from "../../../components/Cards/HowToLinkCard";
import VideoModal from "../../../components/VideoModal";
import InputPreview from "../../../components/Input/InputPreview";
import { useDispatch, useSelector } from "react-redux";
import { BankDetailsData } from "../../../reducers/ecommerceControl/EcommerceControlSlice";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import { Link } from "react-router-dom";
import EmptyCard from "../../../components/Alerts/EmptyCard";

function BankDetailsContent() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const dispatch = useDispatch();
  const { bankInfo, bankInfoLoader } = useSelector(
    (state) => state.eCommerceControl
  );

  const [beforeData, setBeforeData] = useState([]);
  const [afterData, setAfterData] = useState([]);
  const [nowData, setNowData] = useState([]);
  const emailAddress = "support@costbo.com";

  const BANK_Table_Headers = [
    {
      title: "ACCOUNT NAME ",
      image: "",
    },
    { title: "ACCOUNT NUMBER ", image: "" },
    {
      title: "ACCOUNT TYPE",
      // subtitle: `${(<VisibilityIcon sx={{ fontSize: 20 }} />)} EXPECTED - ${(
      //   <VisibilityIcon sx={{ fontSize: 20 }} />
      // )} ACTUAL`,
      image: "",
    },
    { title: "BANK NAME", image: "" },
    { title: "BANK BRANCH NAME", image: "" },
    { title: "IFSC CODE", image: "" },
    { title: "ACTIVE DATE", image: "" },
    { title: "INACTIVE DATE", image: "" },
  ];
  const BANK_Table_Headers_Next = [
    {
      title: "ACCOUNT NAME ",
      image: "",
    },
    { title: "ACCOUNT NUMBER ", image: "" },
    {
      title: "ACCOUNT TYPE",
      // subtitle: `${(<VisibilityIcon sx={{ fontSize: 20 }} />)} EXPECTED - ${(
      //   <VisibilityIcon sx={{ fontSize: 20 }} />
      // )} ACTUAL`,
      image: "",
    },
    { title: "BANK NAME", image: "" },
    { title: "BANK BRANCH NAME", image: "" },
    { title: "IFSC CODE", image: "" },
    { title: "ACTIVE DATE", image: "" },
  ];

  const [tabValue, setTabValue] = useState(5);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const navigate = useNavigate();
  const navigateToTab = (newValue) => {
    if (newValue === 0) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/warehouseDetail`);
    }
    if (newValue === 1) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/deliveryMethods`);
    }
    if (newValue == 2) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/discountDetails`);
    }
    if (newValue == 3) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/otherDetails`);
    }
    if (newValue == 4) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/managers`);
    }
    if (newValue == 5) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/bankInfo`);
    }
  };

  const handleChange = (event, newValue) => {
    navigateToTab(newValue);
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };
  const handleEditDetails = () => {
    navigate(`/editCommerceInfo`, {
      state: { status: "bank-detail" },
    });
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    dispatch(BankDetailsData({ businessId }));
  }, []);
  useEffect(() => {
    let filteredData = [];
    filteredData = bankInfo?.map((item) => ({
      ...item,
      addedDate: moment(item?.addedDate),
      expiredDate: item.expiredDate ? moment(item?.expiredDate) : "",
    }));

    const currentDate = moment();
    const currentDateInIST = moment();

    const after = filteredData?.filter((item) =>
      moment(item?.addedDate).isAfter(currentDate)
    );
    console.log("after", after);
    setAfterData(after);

    const beforeExpired = filteredData?.filter((item) =>
      item?.expired ? moment(item?.expired).isAfter(after?.[0]?.addedDate) : ""
    );
    console.log("beforeExpired", beforeExpired);

    const now = filteredData?.filter((item) =>
      moment(item?.addedDate).isSame(currentDate, "day")
    );

    const lesserDates = [];

    // Iterate over the data array and compare dates
    // for (let i = 0; i < filteredData.length - 1; i += 2) {
    //   const date1 = filteredData[i].expiredDate || 0; // Handle missing or null dates
    //   const date2 = filteredData[i + 1].addedDate || 0; // Handle missing or null dates
    //   const lesserDates = date1 < date2 ? filteredData[i] : filteredData[i + 1];
    // }
    //console.log("lesserDates", lesserDates);
    // let lesserObject = [];
    // if (filteredData?.length > 0) {
    //   const testdata = filteredData?.filter((item) => item?.expiredDate !== "");
    //   if (testdata) {
    //     lesserObject = testdata?.reduce((prev, curr) => {
    //       const prevDate = prev.expiredDate || moment();
    //       const currDate = curr.addedDate || 0;
    //       return prevDate < currDate ? prev : curr;
    //     });
    //   } else
    //     lesserObject = filteredData?.reduce((prev, curr) => {
    //       const prevDate = prev.expiredDate || moment();
    //       const currDate = curr.addedDate || 0;
    //       return prevDate < currDate ? prev : curr;
    //     });
    // }
    // const currentDateInIST = moment().tz("Asia/Kolkata");

    const lesserObject = filteredData?.filter((item) => {
      const addedDate = item?.addedDate;
      const expiredDate = item?.expiredDate;
      return currentDateInIST.isBetween(
        addedDate,
        expiredDate || currentDateInIST,
        null,
        "[]"
      );
    });
    setNowData(lesserObject);
    console.log("lesserObject", lesserObject);

    const before = filteredData?.filter((item) =>
      moment(item?.expiredDate).isBefore(currentDate)
    );
    // console.log("before", before);

    // const before = beforeOne?.filter((item) => nowData?.id === item?.id);
    // console.log("before", before);

    setBeforeData(before);
    console.log("before_after_now", before, after, now);
  }, [bankInfo]);

  return (
    <Box pt={{ md: 2, xs: 1.2 }}>
      {/* {JSON.stringify(bankInfo)} */}
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          handleClose={handleCloseVideoModal}
          data={ECOMMERCE_VIDEO_DATA}
        />
      )}
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={{ xs: "row", md: "row" }}
        mb="10px"
      >
        <Typography
          variant="h2"
          fontSize={{ md: 17, xs: 17 }}
          fontWeight={600}
          color={greyColor24}
          lineHeight="28px"
        >
          E-Commerce Controls
        </Typography>
        <HowToLinkCard
          link="E-Commerce Controls"
          handleClick={() => {
            setOpenVideoModal(true);
          }}
          justifyContent="flex-start"
        />
      </Box>
      <Box
        sx={{
          ...scrollBox,
          display: { xs: "block", md: "block" },
          borderBottom: 1,
          borderColor: "divider",
          mb: { xs: "10px", md: 1 },
        }}
      >
        <Tabs
          variant="scrollable"
          value={tabValue}
          onChange={(event, newValue) => handleChange(event, newValue)}
          aria-label="basic tabs example"
          textColor={blackColor}
          TabIndicatorProps={{
            style: {
              height: "3px",
              backgroundColor: matches ? blueColor04 : mobilePrimary,
            },
          }}
          sx={{
            minHeight: "35px !important",
            "& .MuiButtonBase-root": {
              minWidth: "auto !important",
            },
          }}
        >
          {E_COMMERCE_STEP_LIST?.map((item, index) => {
            return (
              <Tab
                key={"filter_" + index}
                label={item.label}
                // label={item.label}
                sx={{
                  fontSize: { xs: "13px", md: "13.5px" },
                  fontWeight: tabValue == index ? 500 : 500,
                  p: 0,
                  minHeight: "30px !important",
                  mr: { xs: "20px", md: "55px" },
                  color:
                    item.id === tabValue
                      ? matches
                        ? blueColor04
                        : mobilePrimary
                      : greyColor40,
                  textTransform: "capitalize",
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection={"column"}>
          <Typography
            variant="h4"
            fontSize={15}
            fontWeight={600}
            color={greyColor24}
            lineHeight="20px"
          >
            Bank Information 
          </Typography>
          {matches && (
            <Typography
              variant="h6"
              color={blackShade05}
              fontSize="14px"
              fontWeight="400"
            >
              For any updates to the Settlement Bank account info in the middle
              of the calendar month, please contact{" "}
              <Typography
                // variant="body1"
                fontSize={{ md: "13px", xs: "13px" }}
                fontWeight={500}
                color={{ xs: mobilePrimary, md: blueColor04 }}
                display="inline"
                // onclick={handleChatClick}
                component="a"
                Loyalty
                href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=+ +&fs=1&to=${emailAddress}&tf=cm`}
                target="_blank"
                // lineHeight={0.8}
              >
                support@costbo.com
              </Typography>
            </Typography>
          )}
        </Box>

        {bankInfo?.length !== 0 && nowData?.[0]?.expiredDate === "" && (
          <Button onClick={handleEditDetails} sx={styles.editBtn()}>
            EDIT
          </Button>
        )}

        {!bankInfoLoader && bankInfo?.length !== 0 && nowData?.[0]?.expiredDate !== "" && (
          <LightTooltip
            title={
              <span style={{ fontSize: "11px" }}>
                Please wait until the current bank details expired
              </span>
            }
            placement="top"
          >
            <Button
              variant="contained"
              sx={styles.editBtn(nowData?.[0]?.expiredDate !== "")}
              disabled
            >
              EDIT
            </Button>
          </LightTooltip>
        )}
      </Box>
      {!matches && (
        <Typography
          variant="h6"
          color={blackShade05}
          fontSize="14px"
          fontWeight="400"
          lineHeight={"22px"}
          mt={1}
        >
          For any updates to the Settlement Bank account info in the middle of
          the calendar month, please contact{" "}
          <Typography
            // variant="body1"
            fontSize={{ md: "13px", xs: "13px" }}
            fontWeight={500}
            color={{ xs: mobilePrimary, md: blueColor04 }}
            display="inline"
            // onclick={handleChatClick}
            component="a"
            Loyalty
            href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=+ +&fs=1&to=${emailAddress}&tf=cm`}
            target="_blank"
            // lineHeight={0.8}
          >
            support@costbo.com
          </Typography>
        </Typography>
      )}
      {bankInfo?.length > 0 && (
        <Box sx={styles.card}>
          <Grid container spacing={1} pt={{ md: 1, xs: 1 }} px={1} py={2}>
            {nowData?.length > 0 ? (
              <>
                <Grid md={12} xs={12}>
                  <Typography
                    variant="h4"
                    fontSize={14}
                    fontWeight={600}
                    color={greyColor24}
                    lineHeight="20px"
                    mb={1.5}
                  >
                    Settlement Account - Active
                  </Typography>
                </Grid>

                <Grid md={3} xs={12}>
                  <InputPreview
                    label="Account Name"
                    value={nowData?.[0]?.accountName}
                    valueStyles={{
                      p: "10px 0 0px 0",
                      mt: { md: -0.5, xs: 0.5 },
                      mb: { md: 2, xs: 1 },
                    }}
                    hideBorder
                  />
                </Grid>
                <Grid md={3} xs={7}>
                  <InputPreview
                    label="Account Number"
                    value={nowData?.[0]?.accountNo}
                    valueStyles={{
                      p: "10px 0 0px 0",
                      mt: { md: -0.5, xs: 0.5 },
                      mb: { md: 2, xs: 1 },
                    }}
                    hideBorder
                  />
                </Grid>
                <Grid md={4} xs={5}>
                  <InputPreview
                    label="Account Type"
                    value={nowData?.[0]?.accountType}
                    valueStyles={{
                      p: "10px 0 0px 0",
                      mt: { md: -0.5, xs: 0.5 },
                      mb: { md: 2, xs: 1 },
                    }}
                    hideBorder
                  />
                </Grid>
                <Grid md={3} xs={12}>
                  <InputPreview
                    label="Bank Name"
                    value={nowData?.[0]?.bankName}
                    valueStyles={{
                      p: "10px 0 0px 0",
                      mt: { md: -0.5, xs: 0.5 },
                      mb: { md: 2, xs: 1 },
                    }}
                    hideBorder
                  />
                </Grid>
                <Grid md={3} xs={7}>
                  <InputPreview
                    label="Bank Branch Name"
                    value={nowData?.[0]?.bankAddress}
                    valueStyles={{
                      p: "10px 0 0px 0",
                      mt: { md: -0.5, xs: 0.5 },
                      mb: { md: 2, xs: 1 },
                    }}
                    hideBorder
                  />
                </Grid>
                <Grid md={4} xs={5}>
                  <InputPreview
                    label="IFSC Code"
                    value={nowData?.[0]?.ifscCode}
                    valueStyles={{
                      p: "10px 0 0px 0",
                      mt: { md: -0.5, xs: 0.5 },
                      mb: { md: 2, xs: 1 },
                    }}
                    hideBorder
                  />
                </Grid>
                <Grid md={3} xs={7}>
                  <InputPreview
                    label="Active Date"
                    value={moment(nowData?.[0]?.addedDate).format(
                      " DD MMM YYYY"
                    )}
                    valueStyles={{
                      p: "10px 0 0px 0",
                      mt: { md: -0.5, xs: 0.5 },
                      mb: { md: -2, xs: -2 },
                    }}
                    hideBorder
                  />
                </Grid>
                {nowData?.[0]?.expiredDate !== "" && (
                  <>
                    <Grid md={3} xs={5}>
                      <InputPreview
                        label="Inactive Date"
                        value={
                          nowData?.[0]?.expiredDate === " "
                            ? "-/-/-"
                            : moment(nowData?.[0]?.expiredDate).format(
                                " DD MMM YYYY"
                              )
                        }
                        valueStyles={{ p: "10px 0 0px 0", mt: -0.5, mb: -2 }}
                        hideBorder
                      />
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: "15px",
                }}
              >
                <Typography
                  width={{ xs: "auto", md: "800px" }}
                  component="h6"
                  fontSize={{ xs: 14, md: 15 }}
                  color={blackColor}
                  fontWeight={500}
                  lineHeight="27px"
                  mt="12px !important"
                  textAlign="center"
                >
                  No Active Settlement Account
                </Typography>
              </Box>
            )}
          </Grid>
        </Box>
      )}
      {bankInfo?.length === 0 && (
        <Box sx={styles.card}>
          <Box mt="16px">
            <Box
              display="flex"
              flexDirection="column"
              gap="10px"
              alignItems="center"
              my={4}
            >
              <Box sx={styles.iconContainer}>
                <AccountBalanceOutlinedIcon sx={{ fontSize: "24px" }} />
              </Box>

              <Typography variant="body2" sx={styles.noDataText}>
                No Bank Detail Available
              </Typography>
              <Button
                sx={styles.addIconBtn}
                variant="contained"
                disableElevation
                startIcon={<AddIcon />}
                onClick={() =>
                  navigate(`/editCommerceInfo`, {
                    state: { status: "bank-detail" },
                  })
                }
              >
                ADD BANK DETAILS
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {afterData?.length > 0 && (
        <>
          <Typography
            variant="h4"
            fontSize={15}
            fontWeight={600}
            color={greyColor24}
            lineHeight="28px"
            mb={1.5}
            my={1}
          >
            Settlement Account - Future
          </Typography>
          <TableContainer
            component={Box}
            sx={{
              // borderRadius: "10px",
              overflow: "scroll",
              maxHeight: "420px",
              borderRadius: "8px 8px 8px 8px ",
              width: { md: "88%", xs: "100%" },
              borderLeft: `4px solid ${greenColor5}`,
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: greyColor42,
                    zIndex: 100,
                    borderRadius: "8px 8px 0 0 ",
                  }}
                >
                  {BANK_Table_Headers_Next?.map((item, index) => {
                    return (
                      <TableCell
                        sx={{
                          ...styles.tableCell,
                          borderRight: {
                            xs: "none",
                            md: orderCardHeadingBorder2,
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            // alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            sx={{
                              color: blackShade23,
                              background: greyColor42,
                              fontSize: "11.5px",
                              fontWeight: "500",
                            }}
                          >
                            {item?.title}
                          </Typography>
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: whiteColor }}>
                {afterData?.map((item, index) => {
                  return (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        border: borderTextbox,
                      }}
                    >
                      <TableCell sx={styles.tableBodyCell()}>
                        <Typography sx={styles.headingText} mb={0.5}>
                          {item?.accountName}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        <Typography sx={styles.headingText} mb={0.5}>
                          {item?.accountNo}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        <Typography sx={styles.headingText} mb={0.5}>
                          {item?.accountType}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        <Typography sx={styles.headingText} mb={0.5}>
                          {item?.bankName}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        <Typography sx={styles.headingText} mb={0.5}>
                          {item?.bankAddress}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        <Typography sx={styles.headingText} mb={0.5}>
                          {item?.ifscCode}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        <Typography sx={styles.headingText} mb={0.5}>
                          {moment(item?.addedDate).format(" DD MMM YYYY")}
                        </Typography>
                      </TableCell>
                      {/* <TableCell sx={styles.tableBodyCell()}>
                    <Typography sx={styles.headingText} mb={0.5}>
                      {moment(bankInfo?.expiredDate).format(" DD MMM YYYY")}
                    </Typography>
                  </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {beforeData?.length > 0 && (
        <>
          <Typography
            variant="h4"
            fontSize={15}
            fontWeight={600}
            color={greyColor24}
            lineHeight="28px"
            mb={1.5}
            my={1}
          >
            Settlement Account - Historical
          </Typography>
          <TableContainer
            component={Box}
            sx={{
              // borderRadius: "10px",
              overflow: "scroll",
              maxHeight: "420px",
              borderRadius: "8px 8px 8px 8px ",
              borderLeft: `4px solid ${redColor07}`,
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: greyColor42,
                    zIndex: 100,
                    borderRadius: "8px 8px 0 0 ",
                  }}
                >
                  {BANK_Table_Headers?.map((item, index) => {
                    return (
                      <TableCell
                        sx={{
                          ...styles.tableCell,
                          borderRight: {
                            xs: "none",
                            md: orderCardHeadingBorder2,
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            // alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            sx={{
                              color: blackShade23,
                              background: greyColor42,
                              fontSize: "11.5px",
                              fontWeight: "500",
                            }}
                          >
                            {item?.title}
                          </Typography>
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: whiteColor }}>
                {beforeData?.map((item, index) => {
                  return (
                    <TableRow
                      sx={{
                        // "&:last-child td, &:last-child th": { border: 0 },
                        border: borderTextbox,
                      }}
                    >
                      <TableCell sx={styles.tableBodyCell()}>
                        <Typography sx={styles.headingText} mb={0.5}>
                          {item?.accountName}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        <Typography sx={styles.headingText} mb={0.5}>
                          {item?.accountNo}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        <Typography sx={styles.headingText} mb={0.5}>
                          {item?.accountType}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        <Typography sx={styles.headingText} mb={0.5}>
                          {item?.bankName}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        <Typography sx={styles.headingText} mb={0.5}>
                          {item?.bankAddress}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        <Typography sx={styles.headingText} mb={0.5}>
                          {item?.ifscCode}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        <Typography sx={styles.headingText} mb={0.5}>
                          {moment(item?.addedDate).format(" DD MMM YYYY")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={styles.tableBodyCell()}
                        align={item?.expiredDate ? "left" : "center"}
                      >
                        <Typography sx={styles.headingText} mb={0.5}>
                          {item?.expiredDate
                            ? moment(item?.expiredDate).format(" DD MMM YYYY")
                            : "-"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
}

export default BankDetailsContent;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[2],
    fontSize: 11,
    border: `1px solid rgba(0,0,0,0.2)`,
  },
}));

const styles = {
  card: {
    p: { xs: "10px 15px", md: "10px 20px 5px" },
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: { xs: "8px", md: "13px" },
    mt: { xs: "5px", md: "10px" },
  },

  iconContainer: {
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "25px",
    backgroundColor: greyShade09,
  },
  tableCell: {
    color: blackShade23,
    background: greyColor42,
    fontSize: "11.5px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    padding: "5px 20px",
  },
  tableBodyCell: (color) => ({
    fontSize: 13,
    fontWeight: 600,
    whiteSpace: "nowrap",
    color: color || blackShade01,
    padding: "5px 20px !important",
    borderRight: {
      xs: "none",
      md: "1px solid" + borderTextBoxColor,
    },
  }),
  headingText: {
    color: blackShade01,
    fontSize: { xs: 12.5, md: 12 },
    fontWeight: 600,
    lineHeight: 1.5,
  },

  noDataText: {
    fontSize: 14,
    fontWeight: 500,
    color: greyColor24,
    lineHeight: "24px",
  },
  addIconBtn: {
    backgroundColor: "transparent",
    padding: { xs: "2px 10px", md: "3px 22px" },
    alignItems: { md: "center", xs: "center" },
    fontSize: 13,
    fontWeight: 600,
    color: { xs: mobilePrimary, md: blueColor04 },
    backgroundColor: whiteColor,
    border: {
      xs: "1px solid " + mobilePrimary,
      md: "1px solid " + blueColor04,
    },
    "&:hover": {
      color: whiteColor,
      backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    },
  },
  editBtn: (disabled) => ({
    backgroundColor: blackShade22,
    padding: "3px 14px",
    fontSize: "12.5px",
    fontWeight: 600,
    color: whiteColor,
    border: disabled ? `1px solid ${greyColor1}` : `1px solid ${blackShade22}`,

    "&:hover": {
      color: blackShade22,
      backgroundColor: whiteColor,
    },
    opacity: disabled ? 0.5 : 1,
  }),
};
