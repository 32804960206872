import { Box, Grid, Typography, useMediaQuery, Button } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChooseBusinessCard from "../components/Cards/ChooseBusinessCard";
import {
  BGColor02,
  activeTextColor,
  blackColor,
  blackShade05,
  blueColor04,
  draftBGColor,
  greyColor7,
  greyColor8,
  greyShade65,
  inactiveTextColor,
  mobilePrimary,
  pendingTextColor,
  rejectedTextColor,
  whiteColor,
} from "../configs/styles/muiThemes";
import {
  getMyBusinessSummary,
  getUserEditEligibility,
} from "../reducers/businessOnboard/businessOnboardSlice";
import AddIcon from "@mui/icons-material/Add";
import EmptyCard from "../components/Alerts/EmptyCard";
import {
  clearBusinessPreviewData,
  clearCommerceInfo,
} from "../reducers/businessPreview/businessPreviewSlice";
import { clearBusinessDashboardData } from "../reducers/businessDashboard/BusinessDashboardSlice";
import {
  getCommerceDashboardInfoAPI,
  getEmailActivationAPI,
  getProductsByBusinessAPI,
  resendEmailActivationAPI,
} from "../service/api_calls/BusinessPreview/BusinessPreviewAPI";
import { ONBOARDING_VIDEO_DATA } from "../utils/data";
import { clearCouponInfo } from "../reducers/coupons/couponSlice";
import { clearOrderInfo } from "../reducers/orders/ordersSlice";
import BusinessOnboarding from "./BusinessOnboarding";
import { clearCRMInfo } from "../reducers/crmcustomerdata/customerSlice";
import { clearPreferredDiscountsInfo } from "../reducers/preferredDiscount/PreferredDiscountSlice";
import { clearCashbackInfo } from "../reducers/cashback/CashbackSlice";
import ModalCard from "../components/ModalCard";
import ThreeDotLoader from "../components/ThreeDotLoader";
import { clearManagersInfo } from "../reducers/ecommerceControl/EcommerceControlSlice";
import EmailVerifyCard from "./BusinessDashboardStart/Cards/EmailVerifyCard";
import HowToLinkCard from "../components/Cards/HowToLinkCard";
import VideoModal from "../components/VideoModal";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import { stickyHeader } from "../utils/styles";
import { clearAllInventoryInfo } from "../reducers/inventory/inventorySlice";
import MultiBranchBusinessSection from "./ChooseBusiness/MultiBranchBusinessSection";
import { clearMultiBranchInfo } from "../reducers/multiBranchCatalog/multiBranchCatalogSlice";
import { clearReduxState } from "../service/reduxStateResetHelper";

function ChooseBusiness() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [singleLocationBusinessList, setSingleLocationBusinessList] = useState(
    []
  );
  const [mainBranchBusinessList, setMainBranchBusinessList] = useState([]);
  const [subBranchBusinessList, setSubBranchBusinessList] = useState([]);

  const [businessList, setBusinessList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");

  const [isEmailVerified, setIsEmailVerified] = useState(null);
  const [emailLoader, setEmailLoader] = useState("");

  const [showEmailAlert, setShowEmailAlert] = useState({
    show: false,
    data: null,
  });

  const [openAddBusinessModal, setOpenAddBusinessModal] = useState(false);
  const [showInactiveAlert, setShowInactiveAlert] = useState({
    show: false,
    data: null,
  });

  const [alert, setAlert] = useState(false);

  const [openVideoModal, setOpenVideoModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [statusCount, setStatusCount] = useState(null);

  const { myBusinessSummary, myBusinessSummaryLoader } = useSelector(
    (state) => state.businessOnboard
  );

  // Just for testing can be removed
  const reduxState = useSelector((state) => state);

  const handleNavigation = (route, businessId, routeData) => {
    dispatch(clearBusinessDashboardData());
    dispatch(clearCommerceInfo());
    sessionStorage.setItem("selectedBusinessId", businessId);
    navigate(route, routeData || {});
  };

  const onBusinessSelected = async (data) => {
    console.log(data);

    if (data?.status === "inactive") {
      // if (matches) {
      // } else {
      //   setAlert(true);
      // }
      setShowInactiveAlert({ show: true, data });
      return;
    }

    if (data?.status === "pending") {
      if (matches) {
        handleNavigation(`/addBusinessPending`, data?.businessid, {
          state: { businessId: data?.businessid, isPendingPreview: true },
        });
      } else {
        setAlert(true);
      }

      return;
    }

    if (data?.status === "draft" || data?.status === "rejected") {
      handleNavigation(`/addBusinessDetail`, data?.businessid, {
        state: { businessId: data?.businessid },
      });
      return;
    }

    const businessProduct = await getProductsByBusinessAPI(data?.businessid);
    const businessCommerceInfo = await getCommerceDashboardInfoAPI(
      data?.businessid
    );

    console.log("businessProduct", businessProduct);
    console.log("businessCommerceInfo", businessCommerceInfo);

    // Validating whether the business is new
    if (
      businessProduct?.totalProduct === 0 ||
      businessCommerceInfo?.status === "new"
    ) {
      sessionStorage.setItem("costboWEBURL", data?.costboWEBURL);
      handleNavigation(`/dashboardStartBusiness`, data?.businessid, {
        state: { businessProduct, businessCommerceInfo },
        // state: { businessProduct, businessCommerceInfo: { status: "new" } },
        // state: { businessProduct, businessCommerceInfo: { status: "submitted" } },
        // state: { businessProduct, businessCommerceInfo: { status: "active" } },
      });
      // if (matches) {
      //   sessionStorage.setItem("costboWEBURL", data?.costboWEBURL);
      //   handleNavigation(`/dashboardStartBusiness`, data?.businessid, {
      //     state: { businessProduct, businessCommerceInfo },
      //     // state: { businessProduct, businessCommerceInfo: { status: "new" } },
      //     // state: { businessProduct, businessCommerceInfo: { status: "submitted" } },
      //     // state: { businessProduct, businessCommerceInfo: { status: "active" } },
      //   });
      // } else {
      //   setAlert(true);
      // }

      return;
    }

    // Checking the business status
    if (data?.status === "active") {
      sessionStorage.setItem("costboWEBURL", data?.costboWEBURL);
      handleNavigation(`/${data?.costboWEBURL}/dashboard`, data?.businessid);
      // if (matches) {
      //   sessionStorage.setItem("costboWEBURL", data?.costboWEBURL);
      //   handleNavigation(`/${data?.costboWEBURL}/dashboard`, data?.businessid);
      // } else {
      //   setAlert(true);
      // }

      return;
    }
  };

  // Filter Products by Category
  const handleCategoryFilter = (data) => {
    console.log("pendingList___data", data);
    setSelectedCategory(data);
    // setSelectedSubCat("All");

    if (data === "All") {
      setBusinessList(singleLocationBusinessList);
      return;
    }

    if (data === "Active") {
      const activeList = singleLocationBusinessList?.filter(
        (item) => item?.status === "active"
      );
      setBusinessList(activeList);
      return;
    }
    if (data === "Pending") {
      const pendingList = singleLocationBusinessList?.filter(
        (item) => item?.status === "pending"
      );
      console.log("pendingList___", pendingList);
      setBusinessList(pendingList);
      return;
    }
    if (data === "Draft") {
      const draftList = singleLocationBusinessList?.filter(
        (item) => item?.status === "draft"
      );
      setBusinessList(draftList);
      return;
    }
    if (data === "Rejected") {
      const rejectedList = singleLocationBusinessList?.filter(
        (item) => item?.status === "rejected"
      );
      setBusinessList(rejectedList);
      return;
    }

    if (data === "Inactive") {
      const rejectedList = singleLocationBusinessList?.filter(
        (item) => item?.status === "inactive"
      );
      setBusinessList(rejectedList);
      return;
    }
  };

  const categoryList = [
    { label: "All", tileColor: blackShade05 },
    { label: "Active", tileColor: activeTextColor },
    { label: "Pending", tileColor: pendingTextColor },
    { label: "Draft", tileColor: inactiveTextColor },
    { label: "Rejected", tileColor: rejectedTextColor },
    { label: "Inactive", tileColor: draftBGColor },
  ];

  const getBusinessStatusCount = (dataList) => {
    const activeList = dataList?.filter((item) => item?.status === "active");
    const pendingList = dataList?.filter((item) => item?.status === "pending");
    const draftList = dataList?.filter((item) => item?.status === "draft");
    const rejectedList = dataList?.filter(
      (item) => item?.status === "rejected"
    );
    const inactiveList = dataList?.filter(
      (item) => item?.status === "inactive"
    );

    setStatusCount({
      all: dataList?.length,
      active: activeList?.length,
      pending: pendingList?.length,
      draft: draftList?.length,
      rejected: rejectedList?.length,
      inactive: inactiveList?.length,
    });
  };

  const getBusinessCoutByStatus = (status) => {
    switch (status) {
      case "All":
        return statusCount?.all;
      case "Active":
        return statusCount?.active;
      case "Pending":
        return statusCount?.pending;
      case "Draft":
        return statusCount?.draft;
      case "Rejected":
        return statusCount?.rejected;
      case "Inactive":
        return statusCount?.inactive;

      default:
        return "";
    }
  };

  // Check If User Email is Verified
  const checkEmailActive = async (businessList) => {
    const isActiveOrPending = businessList?.some(
      (item) => item?.status === "pending" || item?.status === "active"
    );

    if (isActiveOrPending) {
      const res = await getEmailActivationAPI();

      if (res) {
        setIsEmailVerified(res);
      }
    }
  };

  // Re-send Email verification
  const resendVerificationMail = async (newEmail) => {
    setEmailLoader(newEmail ? "update" : "resend");
    const response = await resendEmailActivationAPI(
      newEmail || isEmailVerified?.email
    );

    // console.log("RESEND__EMAIL___:", response);

    setEmailLoader("");
    if (response?.success) {
      setShowEmailAlert({
        show: true,
        data: newEmail ? { email: newEmail } : isEmailVerified,
      });

      return true;
    } else {
      return false;
    }
  };

  const prepareBusinessListingData = (data) => {
    const allBusinessList = [...data];

    const singleLocationBusiness = [];
    const mainBranchBusiness = [];
    const subBranchBusiness = [];

    const otherBusiness = [];

    allBusinessList?.forEach((info) => {
      if (!info?.mainBranch && info?.mainBranchId === "") {
        singleLocationBusiness.push(info);
      } else if (info?.mainBranch && info?.mainBranchId === "") {
        mainBranchBusiness.push(info);
      } else if (!info?.mainBranch && info?.mainBranchId) {
        subBranchBusiness.push(info);
      } else {
        otherBusiness.push(info);
      }
    });

    const filteredSubBranchBusiness = [];
    const remainingList = [];

    // Filtering the subBranchBusiness list to store only sub-branches
    //  that is associated with mainBranchBusiness
    subBranchBusiness?.forEach((subBranch) => {
      const isInMainBranch = mainBranchBusiness?.some(
        (info) => info?.businessid === subBranch?.mainBranchId
      );

      if (isInMainBranch) {
        filteredSubBranchBusiness?.push(subBranch);
      } else {
        remainingList?.push(subBranch);
      }
    });

    console.log(
      "CHOOSE_BUSINESS___singleLocationBusiness",
      singleLocationBusiness
    );
    console.log("CHOOSE_BUSINESS___mainBranchBusiness", mainBranchBusiness);
    console.log("CHOOSE_BUSINESS___subBranchBusiness", subBranchBusiness);
    console.log("CHOOSE_BUSINESS___otherBusiness", otherBusiness);
    console.log(
      "CHOOSE_BUSINESS___filteredSubBranchBusiness",
      filteredSubBranchBusiness
    );
    console.log("CHOOSE_BUSINESS___remainingList", remainingList);

    const finalBusinessList = [
      ...singleLocationBusiness,
      ...otherBusiness,
      ...remainingList,
    ];

    setSingleLocationBusinessList(finalBusinessList);
    setMainBranchBusinessList(mainBranchBusiness);
    setSubBranchBusinessList(filteredSubBranchBusiness);

    setBusinessList(finalBusinessList);
    getBusinessStatusCount(finalBusinessList);
    checkEmailActive(allBusinessList);
  };

  useEffect(() => {
    console.log(myBusinessSummary);
    if (myBusinessSummary) {
      // filterBusiness();
      prepareBusinessListingData(myBusinessSummary?.details);
    }
  }, [myBusinessSummary]);

  useEffect(() => {
    // sessionStorage.removeItem("selectedBusinessId");
    // // Clear Data
    // dispatch(clearBusinessPreviewData());
    // dispatch(clearCouponInfo());
    // dispatch(clearOrderInfo());
    // dispatch(clearCRMInfo());
    // dispatch(clearPreferredDiscountsInfo());
    // dispatch(clearCashbackInfo());
    // dispatch(clearManagersInfo());
    // dispatch(clearAllInventoryInfo());

    // dispatch(getMyBusinessSummary());
    // dispatch(getUserEditEligibility());

    // dispatch(clearMultiBranchInfo());

    clearReduxState(dispatch);
  }, []);

  const handleCloseModal = () => {
    setOpenAddBusinessModal(false);
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  useEffect(() => {
    console.log(reduxState);

    return () => {};
  }, [reduxState]);

  return (
    <Box backgroundColor={BGColor02}>
      <DashboardLayout
        activeMenu="store"
        disableAll
        handleOpenVideoGuide={() => {
          setOpenVideoModal(true);
        }}
        handleAddBusiness={() => {
          setOpenAddBusinessModal(true);
        }}
        isAddBusinessDisabled={!isEmailVerified?.activated}
        hideBusinessData
        hideMobileMenu
      >
        <Box sx={styles.card}>
          <Box sx={{ ...stickyHeader, top: "45px" }} pb={{ md: 1.5, xs: 1.5 }}>
            {matches && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "row" },
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box mt={{ xs: 1, md: 0 }}>
                  <Typography
                    component="h4"
                    fontSize={{ xs: 17, md: 17 }}
                    color={blackShade05}
                    fontWeight={600}
                    lineHeight="28px"
                    mb={{ xs: "0px !important", md: "4px !important" }}
                  >
                    Business Listing
                  </Typography>

                  {/* <Typography
                component="body1"
                fontSize={{ xs: 12, md: 13 }}
                color={greyColor7}
                fontWeight={400}
                lineHeight={{ xs: "15px", md: "20px" }}
                mb={{ xs: "10px !important", md: "10px !important" }}
              >
                Select the business you want to manage
              </Typography> */}
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems={{ xs: "flex-start", md: "flex-end" }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={{ md: 3, xs: 0 }}
                  >
                    <HowToLinkCard
                      link="Add New Business"
                      handleClick={() => {
                        setOpenVideoModal(true);
                        // navigate("/howToVideoGuides", {
                        //   state: { status: "gettingStarted" },
                        // });
                      }}
                      txt="to"
                    />

                    <Box>
                      {matches && (
                        <Button
                          sx={styles.addIconBtn}
                          variant="contained"
                          disableElevation
                          onClick={() => {
                            // navigate("/addBusiness");
                            setOpenAddBusinessModal(true);
                          }}
                          startIcon={<AddIcon />}
                          disabled={!isEmailVerified?.activated}
                        >
                          Add Business
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            <Typography
              component="h4"
              fontSize={{ xs: 14.5, md: 14.5 }}
              color={blackShade05}
              fontWeight={600}
              lineHeight={1.5}
              mt={1}
            >
              Single Branch/Warehouse
            </Typography>
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              columnGap="24px"
              rowGap="15px"
              m={{ md: "10px 0 10px 0", xs: "65px 0 0px 0" }}
            >
              {categoryList?.map((item, index) => {
                return (
                  <BusinessLegend
                    key={`legend_${index}`}
                    label={item?.label}
                    count={getBusinessCoutByStatus(item?.label)}
                    color={item?.tileColor}
                    onClick={() => handleCategoryFilter(item?.label)}
                    selectedCategory={selectedCategory}
                    disabled={!isEmailVerified?.activated}
                  />
                );
              })}
            </Box>

            {/* <Box display={"flex"} justifyContent="center">
              {!matches && (
                <Button
                  sx={styles.addIconBtn}
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    // navigate("/addBusiness");
                    setOpenAddBusinessModal(true);
                  }}
                  startIcon={<AddIcon />}
                  disabled={!isEmailVerified?.activated}
                  mt={1}
                >
                  Add Business
                </Button>
              )}
            </Box> */}
          </Box>
          {isEmailVerified && !isEmailVerified?.activated && (
            <EmailVerifyCard
              isEmailVerified={isEmailVerified}
              onResend={() => resendVerificationMail("")}
              onUpdateEmail={resendVerificationMail}
              resendLoader={emailLoader}
            />
          )}

          <Box mt={{ md: "0", xs: "0px" }}>
            {!myBusinessSummary && myBusinessSummaryLoader && (
              <ThreeDotLoader />
            )}

            {myBusinessSummary &&
              !myBusinessSummaryLoader &&
              businessList?.length === 0 && (
                <EmptyCard
                  icon="https://storage.googleapis.com/bodefaults/businessdashboardweb/online-shopping.svg"
                  message={`No business in ${selectedCategory} status`}
                />
              )}

            <Grid container spacing={{ md: 2, xs: 1 }}>
              {businessList?.length > 0 &&
                businessList?.map((item, index) => {
                  return (
                    <Grid
                      item
                      md={3}
                      xs={12}
                      key={"business_" + item?.name + index}
                    >
                      <ChooseBusinessCard
                        businessName={item?.name}
                        image={item?.url}
                        onClick={() => onBusinessSelected(item)}
                        status={item?.status}
                        disabled={!isEmailVerified?.activated}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Box>

          {mainBranchBusinessList?.length > 0 && (
            <>
              <Typography
                component="h4"
                fontSize={{ xs: 14.5, md: 14.5 }}
                color={blackShade05}
                fontWeight={600}
                lineHeight={1.5}
                m={{ md: "30px 0 15px 0", xs: "45px 0 0px 0" }}
              >
                Multi Branch/Warehouse
              </Typography>

              {/* <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                columnGap="24px"
                rowGap="15px"
                m={{ md: "10px 0 25px 0", xs: "65px 0 0px 0" }}
              >
                {categoryList?.map((item, index) => {
                  return (
                    <BusinessLegend
                      key={`legend_${index}`}
                      label={item?.label}
                      count={"X"}
                      color={item?.tileColor}
                      onClick={() => handleCategoryFilter(item?.label)}
                      selectedCategory={selectedCategory}
                      disabled={!isEmailVerified?.activated}
                    />
                  );
                })}
              </Box> */}

              {mainBranchBusinessList?.map((businessInfo, index) => (
                <MultiBranchBusinessSection
                  key={`business_` + index}
                  businessInfo={businessInfo}
                  allSubBranchList={subBranchBusinessList}
                  onBusinessSelected={onBusinessSelected}
                />
              ))}
            </>
          )}
        </Box>
      </DashboardLayout>

      {openAddBusinessModal && (
        <BusinessOnboarding
          open={openAddBusinessModal}
          handleClose={handleCloseModal}
        />
      )}

      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          handleClose={handleCloseVideoModal}
          data={ONBOARDING_VIDEO_DATA}
        />
      )}

      <ModalCard
        open={showInactiveAlert?.show}
        handleClose={() => {
          setShowInactiveAlert({ show: false, data: null });
        }}
        width="27%"
      >
        <Box
          sx={{ backgroundColor: whiteColor, px: 3, py: 2.5, borderRadius: 2 }}
        >
          <Typography fontSize={16} fontWeight={600} color={blackColor}>
            Alert
          </Typography>

          <Typography fontSize={14} mt={2.5} sx={{ whiteSpace: "pre-line" }}>
            {`${showInactiveAlert?.data?.name} has been inactivated and is no longer selling in
            COSTBO. Please contact support@costbo.com to activate or any other queries.`}
          </Typography>

          <Box display="flex" justifyContent={"flex-end"} mt={1.5} gap={2}>
            <Button
              sx={styles.alertOkBtn}
              onClick={() => {
                setShowInactiveAlert({ show: false, data: null });
              }}
            >
              OK
            </Button>
          </Box>
        </Box>
      </ModalCard>

      <ModalCard
        open={showEmailAlert?.show}
        handleClose={() => {
          setShowEmailAlert({ show: false, data: null });
        }}
        width="33%"
      >
        <Box
          sx={{ backgroundColor: whiteColor, px: 3, py: 2.5, borderRadius: 2 }}
        >
          <Typography fontSize={16} fontWeight={600} color={blackColor}>
            Activation Link Sent
          </Typography>

          <Typography fontSize={14} mt={2.5} sx={{ whiteSpace: "pre-line" }}>
            {`Verification email has been sent to ${showEmailAlert?.data?.email}. \nPlease complete the verification to proceed.`}
          </Typography>

          <Box display="flex" justifyContent={"flex-end"} mt={1.5} gap={2}>
            <Button
              sx={styles.alertOkBtn}
              onClick={() => {
                setShowEmailAlert({ show: false, data: null });
              }}
            >
              OK
            </Button>
          </Box>
        </Box>
      </ModalCard>

      <ModalCard
        open={alert}
        handleClose={() => {
          setAlert(false);
        }}
        width="27%"
      >
        <AlertMessagePopup
          // text={
          //   "Currently mobile version is not available, please try in larger devices"
          // }
          onClick={() => {
            setAlert(false);
          }}
        />
      </ModalCard>
    </Box>
  );
}

export default ChooseBusiness;

const BusinessLegend = ({
  label,
  color,
  count,
  onClick,
  selectedCategory,
  disabled,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="8px"
      onClick={() => {
        if (disabled) return;
        onClick();
      }}
      sx={{
        position: "relative",
        opacity: disabled ? 0.6 : 1,
        "&::before": {
          content: "' '",
          width: "25px",
          height: "2px",
          backgroundColor:
            selectedCategory === label ? blackShade05 : "transparent",
          borderRadius: "6px",
          position: "absolute",
          bottom: -5,
          right: 0,
          opacity: disabled ? 0.6 : 1,
        },
      }}
    >
      <Box
        sx={{ ...styles.legendDot, backgroundColor: color || draftBGColor }}
      />

      <Typography
        component="h6"
        fontSize={{ xs: 12, md: 13 }}
        color={greyShade65}
        fontWeight={400}
        lineHeight={{ xs: "18px", md: "20px" }}
      >
        {label} - {count}
      </Typography>
    </Box>
  );
};

const AlertMessagePopup = ({ text, onClick }) => {
  return (
    <Box sx={styles.alertCard}>
      <Typography
        variant="body2"
        fontSize={14}
        fontWeight={500}
        textAlign="center"
        sx={{ whiteSpace: "pre-line", lineHeight: { md: "28px", xs: "23px" } }}
      >
        Business Dashboard detail functionality is currently not available in
        mobile devices. Please access the same link from a desktop/laptop.
      </Typography>
      <Typography
        variant="body2"
        fontSize={14}
        fontWeight={500}
        textAlign="center"
        sx={{ whiteSpace: "pre-line", lineHeight: { md: "28px", xs: "23px" } }}
      >
        These features will be made available in mobile devices soon.
      </Typography>
      <Box
        display="flex"
        justifyContent={"flex-end"}
        mt={1.5}
        gap={2}
        sx={{ width: "100%" }}
      >
        <Button sx={styles.alertOkBtn} onClick={onClick}>
          OK
        </Button>
      </Box>
      {/* <Button
        sx={styles.alertBtn}
        variant="contained"
        disableElevation
        onClick={onClick}
      >
        GO TO BUSINESS LISTING
      </Button> */}
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "25px" },
    p: { xs: "0px", md: "15px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    // backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  card: {
    // backgroundColor: whiteColor,
    borderRadius: "13px",
    // boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    // p: "20px",
    mb: "25px",
    cursor: "pointer",
  },

  addBtn: {
    height: "162px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #f1f1f1",
    borderRadius: "5px",
    boxShadow: "0px 1px 3px rgb(0 0 0 / 6%)",
    backgroundColor: BGColor02,
    cursor: "pointer",
  },

  loaderBox: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  addIconBtn: {
    padding: "5px 16px",
    width: { md: "auto", xs: "90%" },
    fontSize: "12.5px",
    fontWeight: 600,
    color: blueColor04,
    backgroundColor: whiteColor,
    border: "1px solid " + blueColor04,
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },

    "&.Mui-disabled": {
      border: "none",
    },
  },

  alertOkBtn: {
    padding: { md: "6px 22px", xs: "4px 10px" },
    fontSize: "13px",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    border: "1.5px solid " + blueColor04,
    "&:hover": {
      color: { xs: mobilePrimary, md: blueColor04 },
      backgroundColor: whiteColor,
      border: {
        xs: "1.5px solid " + mobilePrimary,
        md: "1.5px solid " + blueColor04,
      },
    },
  },

  legendDot: {
    width: "8px",
    height: "8px",
    borderRadius: "4px",
  },
  alertCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    padding: "25px 12px 12px 12px",
    borderRadius: "8px",
  },
};
