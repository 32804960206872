import { getApiInstance } from "../../AppService";
import {
  ONDC_FULL_SPECIFICATION,
  ONDC_SPECIFICATION,
  PRODUCT_CODE,
  PRODUCT_DETAILS,
  PRODUCT_SUB_CATEGORY_ONE,
  PRODUCT_SUB_CATEGORY_TWO,
} from "../../constant";

// Fetch Active product details
export const getActiveProductInfoAPI = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(PRODUCT_DETAILS + data?.productId);
    console.log("getActiveProductInfoAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Fetch product sub category list 1 details
export const getProductSubCategoryOneAPI = async (pcCodes) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(PRODUCT_SUB_CATEGORY_ONE, {
      params: {
        pcCodes,
      },
    });
    console.log("getProductSubCategoryOneAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Fetch product sub category list 2 details
export const getProductSubCategoryTwoAPI = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(
      PRODUCT_SUB_CATEGORY_TWO + data?.pcCode + "/" + data?.pscCode1
    );
    console.log("getProductSubCategoryTwoAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOndcSpecificationAPI = async (params) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(ONDC_SPECIFICATION, {
      params,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOndcFullSpecificationAPI = async (params) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(ONDC_FULL_SPECIFICATION, {
      params,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getProductCodeAPI = async (params) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(PRODUCT_CODE, {
      params,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
