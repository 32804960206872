import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade19,
  border25,
  greyColor7,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import InputPreview from "../../../components/Input/InputPreview";
import {
  getVolumetricWeightInKg,
  mmToCm,
  mmToInches,
} from "../../../utils/helper";

const MeasurementInfoPreview = ({
  weight,
  netProductWeight,
  length,
  width,
  height,
  productSpecLabel,
  refLinks,
}) => {
  return (
    <Box sx={styles.card}>
      <Box pb={1.2} mb={0}>
        <Typography sx={styles.title}>Product Measurement</Typography>
      </Box>

      <Grid container rowSpacing={{ xs: 2, md: 0 }} columnSpacing={3}>
        <Grid item xs={8} md={3}>
          <InputPreview
            label="Product Weight (in gms)"
            value={weight}
            hideBorder
          />
        </Grid>

        <Grid item xs={4} md={2}>
          <InputPreview
            label="In kgs"
            value={(Number(weight) / 1000).toFixed(2)}
            hideBorder
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputPreview
            label="Shipping Weight"
            value={`${netProductWeight} gms`}
            hideBorder
          />
        </Grid>

        <Grid item xs={4} md={2.5}>
          <InputPreview
            label="In kgs"
            value={(Number(netProductWeight) / 1000).toFixed(2)}
            hideBorder
          />
        </Grid>
      </Grid>

      <Box mt={1.5}>
        <Grid container rowSpacing={{ xs: 2, md: 0 }} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <InputPreview
              label="Product Length (in mm)"
              value={length}
              hideBorder
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <InputPreview label="In cm" value={mmToCm(length)} hideBorder />
          </Grid>

          <Grid item xs={6} md={3}>
            <InputPreview
              label="In inches"
              value={mmToInches(length)}
              hideBorder
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={1.5}>
        <Grid container rowSpacing={{ xs: 2, md: 0 }} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <InputPreview
              label="Product Width (in mm)"
              value={width}
              hideBorder
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <InputPreview label="In cm" value={mmToCm(width)} hideBorder />
          </Grid>

          <Grid item xs={6} md={3}>
            <InputPreview
              label="In inches"
              value={mmToInches(width)}
              hideBorder
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={1.5}>
        <Grid container rowSpacing={{ xs: 2, md: 0 }} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <InputPreview
              label="Product Height (in mm)"
              value={height}
              hideBorder
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <InputPreview label="In cm" value={mmToCm(height)} hideBorder />
          </Grid>

          <Grid item xs={6} md={3}>
            <InputPreview
              label="In inches"
              value={mmToInches(height)}
              hideBorder
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={1.5}>
        <Grid container rowSpacing={{ xs: 2, md: 0 }} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <InputPreview
              label="Volumetirc weight (in kg)"
              value={getVolumetricWeightInKg(
                mmToCm(length),
                mmToCm(width),
                mmToCm(height)
              )}
              hideBorder
            />
          </Grid>
        </Grid>
      </Box>

      <Box py={1.2} mb={0}>
        <Typography sx={styles.title}>Product Specification</Typography>
      </Box>

      {productSpecLabel?.length === 0 ||
      (productSpecLabel?.length === 1 && !productSpecLabel?.[0]?.code) ? (
        <Typography
          component="h6"
          fontSize={13.5}
          color={greyColor7}
          fontWeight={500}
          lineHeight="20px"
          m="10px 0 15px !important"
        >
          N/A
        </Typography>
      ) : null}

      {productSpecLabel?.map((item, index) => {
        if (!item?.code) return;

        return (
          <Box key={`product_spec_${index}`} mb={{ xs: 2, md: 2 }}>
            <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
              <Grid item xs={12} md={3.5}>
                <InputPreview label="Label" value={item?.code} hideBorder />
              </Grid>

              <Grid item xs={12} md={8.5}>
                <InputPreview
                  label="Value"
                  value={item?.value?.join(", ")}
                  hideBorder
                />
              </Grid>
            </Grid>
          </Box>
        );
      })}

      <Box py={1.2} mb={0}>
        <Typography sx={styles.title}>Reference Link</Typography>
      </Box>

      {refLinks?.length === 1 &&
      !refLinks?.[0]?.code &&
      !refLinks?.[0]?.link ? (
        <Typography
          component="h6"
          fontSize={13.5}
          color={greyColor7}
          fontWeight={500}
          lineHeight="20px"
          m="10px 0 15px !important"
        >
          N/A
        </Typography>
      ) : (
        <>
          {refLinks?.map((item, index) => {
            return (
              <Box key={`ref_link_${index}`} mb={{ xs: 2, md: 2 }}>
                <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
                  <Grid item xs={12} md={3.5}>
                    <InputPreview
                      label="Link Name"
                      value={item?.code}
                      hideBorder
                    />
                  </Grid>

                  <Grid item xs={12} md={8.5}>
                    <InputPreview label="Link" value={item?.link} hideBorder />
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
};

export default MeasurementInfoPreview;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    border: border25,
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: "15px 15px",
    mb: 2,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    color: blackShade19,
  },
};
