import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  blackShade05,
  greyColor6,
} from "../../../configs/styles/muiThemes";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import { FULL_CANCEL_STAGE, PARTIAL_CANCEL_STAGE } from "../../../utils/data";
import RadioOptionWithLabel from "../../../components/Radio/RadioOptionWithLabel";

const ApprovalSettingsForm = ({
  data,
  setData,
  ondcCategories,
  handleOndcInfoChange,
  editOndc,
}) => {
  const handleAutoApproveInput = (inputValue) => {
    setData((prevState) => ({
      ...prevState,
      ondc: {
        ...prevState.ondc,
        autoApproval: {
          ...prevState?.ondc?.autoApproval,
          autoApprovalEnabled: inputValue,
        },
      },
      errors: { ...prevState?.errors, autoApprovalEnabled: "" },
    }));
  };

  return (
    <Box>
      <Typography
        component="h6"
        sx={{ fontSize: 15, fontWeight: 600, color: blackShade05, mt: 2 }}
      >
        Approval Settings
      </Typography>

      <Box mt={1.5}>
        <Typography component="h6" sx={styles.sectionHeader}>
          Cancel Terms
        </Typography>

        <Grid container rowSpacing={{xs:1, md:2}} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <SelectWithLabel
              label="Partial Cancel Stage"
              value="Order Created"
              required
              onValueChange={() => {}}
              returnFullItem
              menuList={PARTIAL_CANCEL_STAGE}
              errorMsg=""
              disabled={editOndc}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <SelectWithLabel
              label="Full Cancel Stage"
              value={data?.ondc?.cancelTerms?.ondcFullCancellationStage?.label}
              onValueChange={(status) => {
                setData((prevState) => ({
                  ...prevState,
                  ondc: {
                    ...prevState.ondc,
                    cancelTerms: {
                      ...prevState?.ondc?.cancelTerms,
                      ondcFullCancellationStage: status,
                    },
                  },

                  errors: {
                    ...prevState?.errors,
                    ondcFullCancellationStage: "",
                  },
                }));
              }}
              required
              returnFullItem
              menuList={FULL_CANCEL_STAGE}
              errorMsg={data?.errors?.ondcFullCancellationStage}
              disabled={editOndc}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={2}>
        <Typography component="h6" sx={styles.sectionHeader}>
          Auto Approval
        </Typography>

        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <RadioOptionWithLabel
              label="Auto Approval"
              required
              value={
                data?.ondc?.autoApproval?.autoApprovalEnabled === ""
                  ? ""
                  : data?.ondc?.autoApproval?.autoApprovalEnabled === true
                  ? "Yes"
                  : "No"
              }
              onYesClick={() => {
                handleAutoApproveInput(true);
              }}
              onNoClick={() => {
                handleAutoApproveInput(false);
              }}
              error={data?.errors?.autoApprovalEnabled}
              disabled={editOndc}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ApprovalSettingsForm;

const styles = {
  sectionHeader: {
    fontSize: { xs: 14, md: 14 },
    color: blackColor,
    fontWeight: 600,
    lineHeight: "20px",
    mb: {xs:0.7, md:2},
  },

  radioLabel: {
    color: greyColor6,
    fontSize: 13,
    fontWeight: 400,
  },
};
