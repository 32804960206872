import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  borderTextbox,
  greyColor6,
  primaryColor,
  whiteColor,
} from "../../configs/styles/muiThemes";

function DateInput({ label, date, onDateChange, required, error }) {
  return (
    <>
      <Box>
        <Box >
        <Typography
          variant="h3"
          fontSize={{xs:12, md:13}}
          color={greyColor6}
          fontWeight={400}
          display='inline'
        >
          {label}
        </Typography>{" "}
        {required ? (
          <Typography variant="body1" sx={{ display: "inline", color: "#f00" }}>
            *
          </Typography>
        ) : null}
        </Box>

        <TextField
          variant="standard"
          type="date"
          InputProps={{
            disableUnderline: true,
          }}
          value={date}
          onChange={onDateChange}
          sx={styles.inputBox(whiteColor)}
        />
      </Box>

      {error ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={12}
          sx={{ mt: "4px !important", textAlign: "start !important" }}
          style={{ color: "red" }}
        >
          {error}
        </Typography>
      ) : null}
    </>
  );
}

export default DateInput;

const styles = {
  actionBtn: {
    background: primaryColor,
    padding: { md: "5px 20px", xs: "5px" },
    fontSize: "13px",
    color: whiteColor,
    borderRadius: "5px",
    fontWeight: 600,
    border: "1px solid " + primaryColor,

    "&:hover": {
      backgroundColor: whiteColor,
      color: primaryColor,
      border: "1px solid " + primaryColor,
    },
  },
  inputBox: (color) => ({
    borderBottom: borderTextbox,
    borderRadius: "4px",
    fontSize: "13px",
    color: "#777",
    background: color,
    padding: { md: "3px 12px", xs: "3px 10px" },
    width: "100%",
    "& .MuiInputBase-root.Mui-disabled": {
      color: blackColor + " !important",
    },
  }),
};
