import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import React from "react";
import {
  blackShade05,
  descriptionColor,
  greyColor11,
  greyColor6,
} from "../../configs/styles/muiThemes";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { allowOnlyEnglish } from "../../utils/helper";

function SearchBoxInput({
  minWidth,
  label,
  placeholder,
  name,
  value,
  onChange,
  startIcon,
  icon,
  maxLength,
  inputType,
  isError,
  errorMsg,
  helperText,
  autoFocus,
  inputRef,
  onKeyUp,
  onBlur,
  disabled,
  isDashboardInput,
  onSearchClick,
}) {
  return (
    <form>
      <Box sx={{ width: minWidth || "auto" }}>
        {label && (
          <Typography
            variant="body2"
            component="label"
            fontSize={12.5}
            color={greyColor6}
            fontWeight={400}
            mb="8px !important"
            sx={{ display: "inline" }}
          >
            {label}
          </Typography>
        )}

        <Box sx={styles.inputBox(isDashboardInput)}>
          <TextField
            variant="standard"
            required={true}
            fullWidth
            name={name}
            disabled={disabled}
            value={value}
            onBlur={onBlur}
            autoFocus={autoFocus}
            onChange={({ target }) => {
              const finalValue = allowOnlyEnglish(target.value);
              onChange(finalValue);
            }}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            inputRef={inputRef}
            sx={{
              fontSize: 12,
              padding: { xs: "0px 8px", md: "4px 0px 4px 0px" },
              "& input::placeholder": {
                color: "rgba(0,0,0,0.7)",
                opacity: 1,
              },
            }}
            InputProps={{
              startAdornment: startIcon ? (
                <InputAdornment
                  position="start"
                  sx={{
                    color: `${blackShade05} !important`,
                    fontSize: 13.5,
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 13, fontWeight: 500, color: blackShade05 }}
                  >
                    {startIcon}
                  </Typography>
                </InputAdornment>
              ) : (
                <Box ml={0}/>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                  {icon}
                </InputAdornment>
              ),
              style: {
                color: grey,
                fontSize: 12,
                fontWeight: 500,
                padding: "0px",
              },
              disableUnderline: true,
            }}
            inputProps={{ maxLength: maxLength }}
            type={inputType}
            autoComplete="off"
          />

          <IconButton
            aria-label="search"
            onClick={(e) => {
              e.preventDefault();
              onSearchClick();
            }}
            type="submit"
          >
            <SearchRoundedIcon
              sx={{ fontSize: "18px", color: "rgba(0,0,0,0.7)" }}
            />
          </IconButton>
        </Box>

        {errorMsg ? (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={12}
            sx={{ mt: "4px !important", textAlign: "start !important" }}
            style={{ color: "red" }}
          >
            {errorMsg}
          </Typography>
        ) : null}

        {helperText ? (
          <Typography
            variant="body1"
            component="p"
            fontSize={11}
            color={descriptionColor}
          >
            {helperText}
          </Typography>
        ) : null}
      </Box>
    </form>
  );
}

export default SearchBoxInput;

const styles = {
  inputBox: (isDashboardInput) => ({
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${greyColor11}`,
  }),
};
