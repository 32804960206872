import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardMenu from "../Dashboard/DashboardMenu";
import DashboardHeader from "../Dashboard/DashboardHeader";
import {
  getActiveBusinessInfo,
  viewMyBusinessData,
} from "../../reducers/businessPreview/businessPreviewSlice";
import { BGColor02 } from "../../configs/styles/muiThemes";
import BusinessInfoCard from "../../components/Cards/BusinessInfoCard";
import moment from "moment";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import ManageDelegatesContent from "./PreviewContent/ManageDelegatesContent";
import { getManagerDetails } from "../../reducers/ecommerceControl/EcommerceControlSlice";
import DashboardLayout from "../../components/Layouts/DashboardLayout";

function ManageDelegates() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const dispatch = useDispatch();

  const {
    activeBusinessInfo,
    activeBusinessInfoLoader,
    myBusinessData,
    myBusinessDataLoader,
  } = useSelector((state) => state.businessPreview);

  const { managersInfo, managersInfoLoader } = useSelector(
    (state) => state.eCommerceControl
  );

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      dispatch(getManagerDetails(businessId));
    }
  }, []);

  return (
    <Box sx={styles.container}>
      <DashboardLayout activeMenu="eCommerceControls" activeSubMenu="managers">
        <Box
          display={{ md: "flex", xs: "block" }}
          pt={{ xs: 1, md: 0 }}
          justifyContent="center"
        >
          {!activeBusinessInfoLoader && (
            <ManageDelegatesContent
              managersInfoLoader={managersInfoLoader}
              managersInfo={managersInfo}
              activeBusinessInfo={activeBusinessInfo}
            />
          )}
        </Box>
      </DashboardLayout>
    </Box>
  );
}

export default ManageDelegates;

const styles = {
  container: {
    backgroundColor: BGColor02,
    pb: { md: "15px", xs: "20px" },
    minHeight: "80vh",
  },

  content: {
    p: { xs: "0 0 20px", md: "15px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },
};
