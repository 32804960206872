import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardMenu from "./Dashboard/DashboardMenu";
import DashboardHeader from "./Dashboard/DashboardHeader";
import { BGColor02 } from "../configs/styles/muiThemes";
import BusinessInfoCard from "../components/Cards/BusinessInfoCard";
import CouponDiscountContent from "./CouponsDiscount/CouponDiscountContent";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import {
  getAllCouponsCodeData,
  getAllCouponsCountData,
  getAllCouponsSummaryData,
} from "../reducers/coupons/couponSlice";
import ThreeDotLoader from "../components/ThreeDotLoader";
import { getCouponCodeUseageApi } from "../service/api_calls/cashback/CashbackApi";
import DashboardLayout from "../components/Layouts/DashboardLayout";

function CouponsDiscount() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [usageInfo, setUsageInfo] = useState([]);

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  const { couponSummary, couponCount, couponsCode, couponsCodeLoader } =
    useSelector((state) => state.businessCoupons);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getCouponUsageInfo = async (couponList) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const postList = couponList.map((item) => {
      return {
        couponCode: item?.resellerCode,
        claimID: item?.id,
      };
    });

    const usageData = await getCouponCodeUseageApi({
      businessId,
      couponList: postList,
    });

    setUsageInfo(usageData?.details || []);
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      dispatch(getAllCouponsCodeData({ businessId }));
      dispatch(getAllCouponsSummaryData({ businessId }));
      dispatch(getAllCouponsCountData({ businessId }));
    }
  }, []);

  useEffect(() => {
    if (couponsCode?.details && couponsCode?.details?.length > 0) {
      getCouponUsageInfo(couponsCode?.details);
    }

    return () => {};
  }, [couponsCode]);

  return (
    <Box sx={styles.container}>
      <DashboardLayout activeMenu="coupons" activeSubMenu="couponsPromo">
        {couponsCodeLoader ? (
          <ThreeDotLoader />
        ) : (
          <CouponDiscountContent
            activeBusinessInfo={activeBusinessInfo}
            businessCoupons={couponsCode}
            coupons={couponsCode?.details}
            couponSummary={couponSummary?.details}
            couponCount={couponCount?.details}
            usageInfo={usageInfo}
          />
        )}
      </DashboardLayout>
    </Box>
  );
}

export default CouponsDiscount;

const styles = {
  container: {
    minHeight: "calc(100vh - 110px)",
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "25px" },
    p: { xs: "0px", md: "0px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },
};
