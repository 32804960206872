import React, { useEffect, useState } from "react";
import ModalCard from "../../../components/ModalCard";
import {
  BGColor02,
  blackShade19,
  blueColor02,
  blueColor04,
  greyColor40,
  greyColor42,
  greyColor8,
  greyColor9,
  logoImgborder,
  mobilePrimary,
  orderCardBorder3,
  redColor05,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Radio,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  SINGLE_PRODUCT_PUSH_HEADER,
  getStatusBGColor,
  getStatusTextColor,
} from "../../../utils/data";
import { getColorChip } from "../../../utils/helper";
import {
  getMasterProductInfoAPI,
  pushProductsToSubBranch,
  updateProductInfoInSubBranch,
} from "../../../service/api_calls/multiBranchCatalog/multiBranchCatalogAPIs";
import moment from "moment";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import DateWithTooltip from "../Card/DateWithTooltip";

const SingleProductPushPopup = ({
  openModal,
  setOpenModal,
  selectedProductInfo,
  branchInfoList,
  setSubBranchDataList,
  branchPushLoader,
  activeBusinessInfo,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const businessId = sessionStorage.getItem("selectedBusinessId");

  const [isLoading, setIsLoading] = useState(false);
  const [syncLoader, setSyncLoader] = useState(false);

  const [multiSyncLoader, setMultiSyncLoader] = useState(false);

  const prepareBranchInfo = async () => {
    setIsLoading(true);
    const selectedProductId = selectedProductInfo?.productId;

    // To check if the product is synced or not
    const masterProductInfo = await getMasterProductInfoAPI({
      businessId,
      masterProductId: selectedProductId,
    });

    console.log("prepareBranchInfo__masterProductInfo:", masterProductInfo);

    const newArr = branchInfoList?.map((item) => {
      const masterProductList = masterProductInfo?.details || [];
      const isSynced = masterProductList?.find(
        (product) => product?.branchBusinessId === item?.branchId
      );

      return {
        ...item,
        isSelected: false,
        isSynced: isSynced ? true : false,
        productLastImportDate: isSynced?.productLastImportDate || null,
        branchProductId: isSynced?.branchProductId || null,
      };
    });

    console.log("prepareBranchInfo__newArr:", newArr);

    setSubBranchDataList(newArr);
    setIsLoading(false);
  };

  // Update Franchise Product
  const updateFranchiseProduct = async (branchData, isMultiPush) => {
    setSyncLoader(true);

    const postData = {
      mainBranchBusinessId: businessId,
      masterProductId: selectedProductInfo?.productId,
      childProductIds: [`${branchData?.branchProductId}`],
    };

    const res = await updateProductInfoInSubBranch(postData);

    if (res) {
      if (!isMultiPush) {
        prepareBranchInfo();
      }
      setSyncLoader(false);
    }
  };

  // copy Franchise Product
  const copyFranchiseProduct = async (branchData, isMultiPush) => {
    setSyncLoader(true);

    const coordinates = activeBusinessInfo?.loc || null;
    const latitude = coordinates?.y || "";
    const longitude = coordinates?.x || "";

    const postData = {
      mainBranchBusinessId: businessId,
      subBranchBusienssId: branchData?.branchId,
      mainBrachProductId: [`${selectedProductInfo?.productId}`],
      latitude,
      longitude,
    };

    const res = await pushProductsToSubBranch(postData);

    if (res) {
      if (!isMultiPush) {
        prepareBranchInfo();
      }
      setSyncLoader(false);
    }
  };

  const handleSyncClick = (branchData) => {
    if (branchData?.isSynced) {
      updateFranchiseProduct(branchData);
    } else {
      copyFranchiseProduct(branchData);
    }
  };

  // Handle Select Row
  const handleSubBranchSelect = (index, value) => {
    const subBranchArr = [...branchInfoList];

    subBranchArr[index] = {
      ...subBranchArr[index],
      isSelected: !value,
    };

    setSubBranchDataList(subBranchArr);
  };

  // Handle Select All sub branches
  const selectAllSubBranch = () => {
    const activeBusinessList = branchInfoList?.filter(
      (item) => item?.status?.toLowerCase() == "active"
    );

    const isAllSelected = activeBusinessList?.every((item) => item?.isSelected);

    const newList = branchInfoList?.map((item) => ({
      ...item,
      isSelected: item?.status === "active" ? !isAllSelected : false,
    }));

    setSubBranchDataList(newList);
  };

  // handle push to multiple sub-branches
  const pushProductToMultipleSubBranch = async () => {
    const syncedBranches = [];
    const notSyncedBranches = [];

    setMultiSyncLoader(true);

    branchInfoList.forEach((branchData) => {
      if (branchData?.isSelected) {
        if (branchData?.isSynced) {
          syncedBranches?.push(branchData);
        } else {
          notSyncedBranches?.push(branchData);
        }
      }
    });

    console.log(
      "pushProductToMultipleSubBranch___syncedBranches:",
      syncedBranches
    );
    console.log(
      "pushProductToMultipleSubBranch___notSyncedBranches:",
      notSyncedBranches
    );

    if (syncedBranches?.length > 0 || notSyncedBranches?.length > 0) {
      const dataList = [...syncedBranches, ...notSyncedBranches];

      for (let i = 0; i < dataList.length; i++) {
        const branchData = dataList[i];

        if (branchData?.isSynced) {
          await updateFranchiseProduct(branchData, true);
        } else {
          await copyFranchiseProduct(branchData, true);
        }
      }

      prepareBranchInfo();
      setMultiSyncLoader(false);
    } else {
      setMultiSyncLoader(false);
    }
  };

  useEffect(() => {
    prepareBranchInfo();

    return () => {};
  }, []);

  return (
    <>
      <ModalCard
        open={openModal}
        handleClose={() => {
          setOpenModal({ show: false, productInfo: null });
        }}
        width="68%"
      >
        <Box sx={{ backgroundColor: whiteColor, borderRadius: 1.8 }}>
          <Box
            sx={{
              backgroundColor: BGColor02,
              p: 1.5,
              borderRadius: "14.4px 14.4px 0 0",
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              fontSize={{ xs: 14.5, md: 16 }}
              fontWeight={600}
              color={blackShade19}
              mb={0.3}
            >
              Catalog Push to Branch
            </Typography>

            <Typography
              variant="body1"
              fontSize={12.5}
              fontWeight={500}
              color={greyColor9}
            >
              Sync Catalog details to the branches selected below
            </Typography>
          </Box>

          <Box sx={styles.modelBodyBox}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={{ xs: "flex-start", md: "space-between" }}
              mb={2}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <Box sx={styles.imgBox}>
                  <Box
                    sx={styles.logoImg}
                    component="img"
                    src={selectedProductInfo?.productPhoto?.[0]?.docURL}
                  />
                </Box>

                <Box>
                  <Typography
                    fontSize={{ xs: 12.5, md: 13.5 }}
                    fontWeight={600}
                    color={blackShade19}
                  >
                    {selectedProductInfo?.productName}
                  </Typography>

                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    color={greyColor40}
                  >
                    Master Product Last Updated Date :{" "}
                    <span style={{color: blueColor02}}>
                      {selectedProductInfo?.lastUpdateDate
                        ? moment(selectedProductInfo?.lastUpdateDate)?.format(
                            "Do MMM YYYY, hh:mm A"
                          )
                        : "N/A"}
                    </span>
                  </Typography>
                </Box>
              </Box>

              {/* {matches && (
                <Typography fontSize={13} fontWeight={400} color={greyColor40}>
                  Master Product Last Updated Date :{" "}
                  {selectedProductInfo?.lastUpdateDate
                    ? moment(selectedProductInfo?.lastUpdateDate)?.format(
                        "Do MMM YYYY, hh:mm A"
                      )
                    : "N/A"}
                </Typography>
              )} */}
            </Box>

            <Box sx={styles.tableContainer}>
              {matches && (
                <HeaderRow
                  subBranchList={branchInfoList}
                  selectAllSubBranch={() => {
                    selectAllSubBranch();
                  }}
                />
              )}

              {/* {JSON.stringify(branchInfoList[0])} */}

              <Box sx={styles.tableBody}>
                {!isLoading ? (
                  <>
                    {branchInfoList?.map((item, index) => {
                      return (
                        <SubBranchCard
                          key={`sub_branch__${index}`}
                          isSelected={item?.isSelected || false}
                          logo={item?.logo}
                          businessName={item?.businessName}
                          status={item?.status}
                          isSynced={item?.isSynced}
                          masterProductLastUpdateDate={
                            selectedProductInfo?.lastUpdateDate || null
                          }
                          lastSyncDate={item?.productLastImportDate || null}
                          loader={syncLoader || multiSyncLoader}
                          onClick={() => {
                            handleSyncClick(item);
                          }}
                          onSelectRow={() => {
                            handleSubBranchSelect(
                              index,
                              item?.isSelected || false
                            );
                          }}
                          // Mobile UI Props
                          index={index}
                          matches={matches}
                          isAllSelected={branchInfoList?.every(
                            (item) => item?.isSelected
                          )}
                          selectAllSubBranch={() => {
                            selectAllSubBranch();
                          }}
                        />
                      );
                    })}
                  </>
                ) : (
                  <ThreeDotLoader />
                )}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: BGColor02,
              p: { xs: "10px 10px 10px 10px", md: "10px 16px 16px 16px" },
              borderRadius: "0 0 14.4px 14.4px",
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={4}
            >
              <Button
                variant="contained"
                sx={styles.backBtn}
                disableElevation
                onClick={() => {
                  setOpenModal({ show: false, productInfo: null });
                }}
                disabled={syncLoader || multiSyncLoader}
              >
                CANCEL
              </Button>

              <Button
                variant="contained"
                disableElevation
                sx={styles.applyBtn}
                onClick={() => {
                  pushProductToMultipleSubBranch();
                }}
                disabled={
                  syncLoader ||
                  multiSyncLoader ||
                  branchInfoList?.every((item) => !item.isSelected)
                }
                startIcon={
                  multiSyncLoader && (
                    <CircularProgress size={16} sx={{ color: "inherit" }} />
                  )
                }
              >
                SYNC
              </Button>
            </Stack>
          </Box>
        </Box>
      </ModalCard>
    </>
  );
};

export default SingleProductPushPopup;

const HeaderRow = ({ subBranchList, selectAllSubBranch, loader, tabValue }) => {
  const getIsAllSelectedValue = () => {
    const activeBusinessList = subBranchList?.filter(
      (item) => item?.status?.toLowerCase() == "active"
    );
    return activeBusinessList?.every((item) => item?.isSelected);
  };

  return (
    <Box
      sx={{
        backgroundColor: greyColor42,
        zIndex: 20,
        borderRadius: "8px 8px 0 0",
      }}
    >
      {/* {JSON.stringify(subBranchList)} */}

      <Grid container>
        {SINGLE_PRODUCT_PUSH_HEADER?.map((item, index) => {
          return (
            <Grid
              key={item.title}
              item
              md={item.col}
              borderRight={`1px solid ${whiteColor}`}
            >
              <Box
                display={index === 0 ? "flex" : "inline-block"}
                alignItems="center"
                gap={2.5}
                padding="4px 15px"
              >
                {index === 0 && (
                  <Checkbox
                    checked={getIsAllSelectedValue()}
                    onChange={({ target }) => {
                      selectAllSubBranch();
                    }}
                    sx={styles.checkbox}
                    disabled={loader}
                  />
                )}
                <Box sx={{ fontSize: 11.5, fontWeight: 500 }}>
                  {item?.title}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const SubBranchCard = ({
  isSelected,
  logo,
  businessName,
  status,
  isSynced,
  masterProductLastUpdateDate,
  lastSyncDate,
  loader,
  onClick,
  onSelectRow,
  index,
  matches,
  isAllSelected,
  selectAllSubBranch,
}) => {
  const [isSyncRequired, setIsSyncRequired] = useState(false);

  const predictSyncRequired = () => {
    const updatedData = masterProductLastUpdateDate
      ? moment(masterProductLastUpdateDate)
      : null;
    const importData = lastSyncDate ? moment(lastSyncDate) : null;

    if (updatedData && importData) {
      if (updatedData.isAfter(importData)) {
        setIsSyncRequired(true);
      }
    }
  };

  useEffect(() => {
    predictSyncRequired();

    return () => {};
  }, [masterProductLastUpdateDate]);

  return (
    <Box
      sx={{
        ...styles.subBranchCard,
        pt: { xs: index == 0 && !matches ? 0 : 1, md: 1 },
      }}
    >
      {index === 0 && !matches && (
        <Box sx={styles.mobileSelectAllBox}>
          <Checkbox
            checked={isAllSelected}
            onChange={({ target }) => {
              selectAllSubBranch();
            }}
            sx={styles.checkbox}
            disabled={loader}
          />
          <Box sx={{ fontSize: 12, fontWeight: 500 }}>
            Select All Sub-Branches
          </Box>
        </Box>
      )}

      <Grid
        container
        sx={{ opacity: status?.toLowerCase() != "active" ? 0.7 : 1 }}
      >
        <Grid item xs={12} md={6.5}>
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={isSelected}
              onChange={() => {
                onSelectRow();
              }}
              sx={{ ...styles.checkbox, mx: 2 }}
              disabled={status?.toLowerCase() != "active"}
            />

            <Box sx={styles.imgBox}>
              <Box sx={styles.logoImg} component="img" src={logo} />
            </Box>

            <Stack ml={2} alignItems="flex-start">
              <Typography
                fontSize={{ xs: 13, md: 13.5 }}
                fontWeight={600}
                color={blackShade19}
              >
                {businessName}
              </Typography>

              {status?.toLowerCase() !== "active" && (
                <Box
                  sx={{
                    ...styles.statusTag,
                    backgroundColor: getStatusBGColor(status),
                  }}
                >
                  <Typography
                    fontSize={9}
                    fontWeight={500}
                    lineHeight="20px"
                    color={getStatusTextColor(status)}
                    sx={{ textTransform: "uppercase" }}
                  >
                    {status}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={6} md={2.1}>
          <Box m="5px 0 0 16px">
            <Typography sx={styles.labelStyles}>SYNC STATUS</Typography>

            <Box sx={styles.statusChip(getColorChip(isSynced ? "yes" : "no"))}>
              {isSynced ? "Synced" : "Not Synced"}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} md={3.4}>
          <Box m="5px 16px 0 16px" textAlign={{ xs: "right", md: "left" }}>
            {isSyncRequired ? (
              <DateWithTooltip
                date={
                  lastSyncDate
                    ? moment(lastSyncDate)?.format("Do MMM YYYY, hh:mm A")
                    : "-"
                }
                message="Product not synced since the last update. Select the product and use the 'Sync' button to sync the product to the sub-branch."
              />
            ) : (
              <>
                <Typography sx={{ ...styles.labelStyles, mb: 0 }}>
                  LAST SYNC DATE
                </Typography>

                <Typography
                  fontSize={11.3}
                  fontWeight={500}
                  color={greyColor40}
                >
                  {lastSyncDate
                    ? moment(lastSyncDate)?.format("Do MMM YYYY, hh:mm A")
                    : "-"}
                </Typography>
              </>
            )}
          </Box>
        </Grid>

        {/* <Grid item xs={12} md={2}>
          <Box display="flex" justifyContent="flex-start" m={"5px 15px 0 16px"}>
            <Button
              sx={styles.syncBtn}
              variant="contained"
              disableElevation
              onClick={() => onClick()}
              disabled={loader || status?.toLowerCase() != "active"}
              disableFocusRipple
              disableRipple
            >
              SYNC
            </Button>
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
};

const styles = {
  backBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: whiteColor,
    border: `1px solid ${redColor05}`,
    color: redColor05,
    p: "5px 20px",
    "&:hover": {
      color: redColor05,
      backgroundColor: whiteColor,
    },
  },

  applyBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    border: {
      xs: `1px solid ${mobilePrimary}`,
      md: `1px solid ${blueColor04}`,
    },
    color: whiteColor,
    p: "5px 20px",
    "&:hover": {
      color: { xs: mobilePrimary, md: blueColor04 },
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },

  modelBodyBox: {
    // height: "60vh",
    // overflowY: "scroll",
    p: "10px 12px 0 12px",
    mb: 1,
  },

  subBranchCard: {
    py: 1,
    mb: { xs: 2, md: 0 },
    borderRadius: { xs: "6px", md: 0 },
    borderBottom: orderCardBorder3,
    borderTop: { xs: orderCardBorder3, md: "none" },
    borderLeft: { xs: orderCardBorder3, md: "none" },
    borderRight: { xs: orderCardBorder3, md: "none" },
  },

  imgBox: {
    width: "45px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: logoImgborder,
    borderRadius: "5px",
  },

  logoImg: {
    width: "35px",
    height: "35px",
    objectFit: "contain",
  },

  checkbox: {
    p: 0,
    transform: "scale(1.3)",
    "&.Mui-checked": {
      color: greyColor8,
    },
  },

  tableContainer: {
    border: { xs: "none", md: orderCardBorder3 },
    borderRadius: "8px",
  },

  tableBody: {
    height: "60vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: { xs: "1.5px", md: "3px" },
      height: "3px",
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      // background: '#d0d3da',
      background: "rgba(0,0,0,0.1)",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.05)",
    },
  },

  statusChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    p: "5px 13px",
    borderRadius: "50px",
    width: "max-content",
  }),

  syncBtn: {
    padding: { md: "3px 6px", xs: "3px 14px" },
    fontSize: { md: "11px", xs: "11px" },
    fontWeight: 600,
    color: { xs: mobilePrimary, md: blueColor04 },
    backgroundColor: whiteColor,
    border: {
      xs: `1.5px solid ${mobilePrimary}`,
      md: `1.5px solid ${blueColor04}`,
    },
    whiteSpace: "nowrap",
    ml: { xs: "auto", md: 0 },
    "&:hover": {
      border: {
        xs: `1.5px solid ${mobilePrimary}`,
        md: `1.5px solid ${blueColor04}`,
      },
      color: { xs: mobilePrimary, md: blueColor04 },
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
      color: { xs: greyColor40, md: "auto" },
      backgroundColor: { xs: `rgba(0,0,0,0.1)`, md: "auto" },
    },
  },

  mobileSelectAllBox: {
    display: "flex",
    alignItems: "center",
    gap: 2.5,
    padding: "4px 15px",
    backgroundColor: greyColor42,
    borderRadius: "6px 6px 0 0",
    mb: 1,
  },

  labelStyles: {
    display: { xs: "block", md: "none" },
    fontSize: 11,
    fontWeight: 400,
    color: greyColor40,
    mb: 0.6,
  },

  statusTag: {
    borderRadius: "4px",
    padding: "0px 8px",
    mt: 0.3,
  },
};
