import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BGColor02 } from "../configs/styles/muiThemes";
import { removeSessionInfo } from "../utils/authHelpers";
import DashboardHeader from "./Dashboard/DashboardHeader";
import DashboardMenu from "./Dashboard/DashboardMenu";
import { useDispatch, useSelector } from "react-redux";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import BusinessDashboardStartContent from "./BusinessDashboardStart/BusinessDashboardStartContent";
import ModalCard from "../components/ModalCard";
import EcomAlertModalContent from "./BusinessDashboardStart/Modal/EcomAlertModalContent";
import DashboardLayout from "../components/Layouts/DashboardLayout";

function BusinessDashboardStart() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const contentRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showEcomAlert, setShowEcomAlert] = useState(false);

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  const handleAddEcomClick = () => {
    setShowEcomAlert(true);
  };

  const handleAlertClose = () => {
    setShowEcomAlert(false);
  };

  const handleEcomProceed = () => {
    navigate("/addCommerceInfo");
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      dispatch(getActiveBusinessInfo(businessId));
    }
  }, []);

  return (
    <Box sx={styles.container}>
      <DashboardLayout activeMenu="dashboard">
        <Box ref={contentRef} sx={styles.content}>
          {activeBusinessInfo && (
            <BusinessDashboardStartContent
              businessData={activeBusinessInfo}
              handleAddEcomClick={handleAddEcomClick}
            />
          )}
        </Box>
      </DashboardLayout>

      <ModalCard
        open={showEcomAlert}
        handleClose={handleAlertClose}
        width="32%"
      >
        <EcomAlertModalContent
          onCancel={handleAlertClose}
          onProceed={handleEcomProceed}
        />
      </ModalCard>
    </Box>
  );
}

export default BusinessDashboardStart;

const styles = {
  container: {
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "0px", md: "25px" },
    p: { xs: "0px", md: "25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },
};
