import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import { blackShade05 } from "../../../configs/styles/muiThemes";
import CancelIcon from "@mui/icons-material/Cancel";

const SearchInfoCard = ({ searchQuery, clearSearch, filterType, isClearFilter,data }) => {
  return filterType ? (
    <Box
      display="flex"
      alignItems="center"
      gap="6px"
      mt={{ xs: 0, md: "4px" }}
      flexWrap={"wrap"}
    >
      <Typography
        component="h4"
        fontSize={12}
        color={blackShade05}
        fontWeight={500}
        lineHeight="20px"
      >
        {isClearFilter?.message} - {data?.length} Products
      </Typography>

      <Chip
        label={isClearFilter?.category}
        sx={{ fontSize: "12px", height: "28px" }}
        onDelete={clearSearch}
      />
    </Box>
  ) : (
    <Box display="flex" alignItems="center" gap="10px" mt="4px">
      <Typography
        component="h4"
        fontSize={13}
        color={blackShade05}
        fontWeight={500}
        lineHeight="20px"
        m="0px !important"
      >
        Search results for “
        <Box
          component="span"
          sx={{
            fontSize: 13,
            color: blackShade05,
            fontWeight: 600,
          }}
        >
          {searchQuery}
        </Box>
        ”
      </Typography>

      <Chip
        label={searchQuery}
        sx={{ fontSize: "13px", height: "24px" }}
        onDelete={clearSearch}
        deleteIcon={
          <CancelIcon
            sx={{ width: "18px", height: "18px", fontSize: "10px" }}
          />
        }
      />
    </Box>
  );
};

export default SearchInfoCard;
