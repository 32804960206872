import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { blackShade05, blueColor02, blueColor04, greyColor6 } from "../../../configs/styles/muiThemes";

function InfoCardHeader({
  title,
  onManageClick,
  buttonText,
  hideBtn,
  showSecondaryBtn,
  onSecondaryBtnClick,
  btnText,
}) {
  return (
    <Box sx={styles.card}>
      <Typography
        component="body2"
        fontSize={{ xs: 14, md: 14 }}
        color={blackShade05}
        fontWeight={600}
      >
        {title}
      </Typography>

      <Box>
        {showSecondaryBtn && (
          <Button
            sx={{
              ...styles.btn,
              color: blueColor04,
              mr: 1,
            }}
            onClick={onSecondaryBtnClick}
          >
            {btnText}
          </Button>
        )}

        {!hideBtn && (
          <Button sx={styles.btn} onClick={onManageClick}>
            {buttonText || "Manage"}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default InfoCardHeader;

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  btn: {
    fontSize: 11.5,
    fontWeight: 400,
    color: greyColor6,
    textDecoration: "underline",
    textTransform: "unset",
    p: "0px",
  },
};
