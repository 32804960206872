import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  borderTextbox,
  greyColor6,
} from "../../configs/styles/muiThemes";

function SelectWithLabel({
  label,
  labelSize,
  required,
  value,
  onValueChange,
  errorMsg,
  menuList,
  valueSelector,
  returnFullItem,
  disabled,
  hideLabel,
  labelStyles,
  mtHide,
  borderFull,
  rounded,
}) {
  return (
    <Box sx={{ opacity: disabled ? 0.9 : 1 }}>
      <Box mb={rounded ? "2px !important" : "none"}>
        {!hideLabel ? (
          <Typography
            variant="body2"
            component="label"
            fontSize={{ xs: 12, md: labelSize || 13 }}
            color={greyColor6}
            fontWeight={400}
            mb="0px !important"
            sx={{ display: "inline", ...labelStyles }}
          >
            {label}{" "}
          </Typography>
        ) : (
          <Box mt={mtHide ? mtHide : 3} />
        )}

        {required ? (
          <Typography
            variant="body1"
            sx={[styles.label, { color: "#f00", display: "inline" }]}
          >
            *
          </Typography>
        ) : null}
      </Box>

      <Box
        sx={{
          borderTop: borderFull ? borderTextbox : "none",
          borderLeft: borderFull ? borderTextbox : "none",
          borderRight: borderFull ? borderTextbox : "none",
          borderBottom: borderTextbox,
          height: { xs: "32px", md: "auto" },
          borderRadius: rounded ? "6px" : "0px",
          pb: "3px",
          pl: borderFull ? "5px" : "0",
          //pb: "3px",
        }}
      >
        <FormControl sx={styles.formControlStyle}>
          <Select
            value={value}
            // onChange={(event) => {
            //   onValueChange(event.target.value);
            // }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            fullWidth
            sx={{
              height: { xs: "32px", md: "40px" },
              // pb: "2px",
              textTransform: "capitalize",
              ml:1,
            }}
            disabled={disabled}
          >
            {menuList.map((item, index) => {
              return (
                <MenuItem
                  value={valueSelector ? item[valueSelector] : item.label}
                  key={"Menu_Item_" + index}
                  onClick={() => {
                    if (!returnFullItem) {
                      onValueChange(
                        valueSelector ? item[valueSelector] : item.label
                      );
                    } else {
                      onValueChange(item);
                    }
                  }}
                  sx={{ fontWeight: 500, textTransform: "capitalize" }}
                >
                  {valueSelector ? item[valueSelector] : item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      {errorMsg ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={12}
          fontWeight={500}
          color="red"
          mt="6px"
        >
          {errorMsg}
        </Typography>
      ) : null}
    </Box>
  );
}

export default SelectWithLabel;

const styles = {
  label: {
    fontSize: { md: "14px", xs: "12px" },
    color: blackColor,
    display: "inline",
  },

  formControlStyle: {
    width: "100%",
    height: "28px",
    ml:-1,
    "& .css-hdw1oc": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline, .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
      {
        border: "none",
      },
  },
};
