import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import {
  bgColor15,
  blackShade05,
  blackShade19,
  border25,
  deleteBtnColor,
  greyColor8,
  lightBlueColor01,
  orangeStatusColor,
  orderCardBorder,
  orderCardBorder2,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { addThousandsSeparator } from "../../../utils/helper";
import { textOneLines } from "../../../utils/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";

function ReturnOrderCard({
  id,
  date,
  ondcID,
  orderCost,
  cartCount,

  refundTotalAmt,
  returnQty,
  requestedDate,

  handleViewOrder,
  handleActionClick,
  getOndcBapNameBySubscriberId,
  orderType,
  item,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box sx={styles.card}>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        <Grid item xs={6} md={2.2}>
          <Box
            display="flex"
            gap="8px"
            flex={1}
            borderRight={{ xs: "none", md: orderCardBorder2 }}
            // borderBottom={{ xs: orderCardBorder2, md: "none" }}
            // pb={{ xs: 1.2, md: 0 }}
          >
            <ReceiptLongOutlinedIcon
              sx={{
                fontSize: "18px",
                color: blackShade19,
                display: { xs: "none", md: "block" },
                mt: 0.2,
              }}
            />

            <Box
              flex={1}
              display="flex"
              flexDirection={{ xs: "column", md: "column" }}
              justifyContent={{ xs: "space-between", md: "flex-start" }}
            >
              <Typography sx={styles.labelText}>ORDER DETAIL</Typography>
              <Typography
                variant="h5"
                fontSize={12.5}
                fontWeight={{ xs: 500, md: 600 }}
                color={blackShade19}
                lineHeight="22px"
              >
                ID# {id}
              </Typography>

              <Typography
                variant="p"
                fontSize={11}
                fontWeight={{ xs: 400, md: 500 }}
                color={bgColor15}
                lineHeight={{ xs: "18px", md: "22px" }}
              >
                {date}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} md={2.2}>
          <Box
            display="flex"
            gap="8px"
            flex={1}
            borderRight={{ xs: "none", md: orderCardBorder2 }}
            // borderBottom={{ xs: orderCardBorder2, md: "none" }}
            // pb={{ xs: 1.2, md: 0 }}
          >
            <ReceiptLongOutlinedIcon
              sx={{
                fontSize: "18px",
                color: blackShade19,
                display: { xs: "none", md: "block" },
                mt: 0.2,
              }}
            />

            <Box
              flex={1}
              // display="flex"
              // flexDirection={{ xs: "column", md: "column" }}
              // justifyContent={{ xs: "space-between", md: "flex-start" }}
              textAlign={{ xs: "right", md: "left" }}
            >
              <Typography sx={styles.labelText}>ONDC ORDER ID</Typography>
              <Typography
                variant="h5"
                fontSize={12.5}
                fontWeight={{ xs: 500, md: 600 }}
                color={orderType?.color}
                lineHeight={{ xs: "20px", md: "22px" }}
              >
                ID# {ondcID?.slice(0, 17)}
              </Typography>
            </Box>
          </Box>
          <Box flex={1} display={"flex"} gap={2}>
            <Typography sx={{ ...styles.title, display: "inline-block" }}>
              {" "}
              <Typography
                sx={{
                  ...styles.title,
                  fontSize: 12,
                  color: orderType?.color,
                  display: "inline-block",
                  ml: 3,
                }}
              >
                {orderType?.label}
                {orderType?.orderType === "ondcOrder" ? (
                  <span style={{ color: blackShade19 }}>
                    {" "}
                    - {getOndcBapNameBySubscriberId(item?.ondcInfo?.bapId)}
                  </span>
                ) : null}
              </Typography>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6} md={2}>
          <InfoBox
            titleIcon={<CreditCardIcon sx={{ fontSize: "20px" }} />}
            title={`₹ ${addThousandsSeparator(orderCost)}`}
            subtitleIcon={
              <ShoppingCartIcon sx={{ fontSize: "16px", color: bgColor15 }} />
            }
            subtitle={cartCount}
            mobileLabel="PRICE & QTY"
          />
        </Grid>

        <Grid item xs={6} md={2}>
          <InfoBox
            titleIcon={<CreditCardIcon sx={{ fontSize: "20px" }} />}
            title={`₹ ${addThousandsSeparator(refundTotalAmt)}`}
            subtitleIcon={
              <ShoppingCartIcon sx={{ fontSize: "16px", color: bgColor15 }} />
            }
            subtitle={returnQty}
            mobileLabel="RETURN(PRICE & QTY)"
            mobileRightAlign
          />
        </Grid>

        <Grid item xs={6} md={1.8}>
          <Box sx={styles.infoTab}>
            <Typography
              variant="h5"
              fontSize={12}
              fontWeight={600}
              color={orangeStatusColor}
              lineHeight="20px"
            >
              Requested
            </Typography>

            <Typography
              variant="p"
              fontSize={12}
              fontWeight={500}
              color={bgColor15}
              lineHeight="18px !important"
            >
              {requestedDate}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={1.8}>
          <Box
            display="flex"
            // flexDirection={{xs:'row', md:'column'}}
            alignItems="center"
            justifyContent={{ xs: "flex-end", md: "flex-start" }}
            gap={{ xs: "15px", md: "10px" }}
            flex={0.8}
          >
            <IconButton
              sx={styles.addIconBtn}
              disableElevation
              onClick={handleViewOrder}
            >
              <VisibilityIcon sx={{ fontSize: 20 }} />
            </IconButton>

            <Button
              sx={styles.actionBtn}
              variant="contained"
              disableElevation
              onClick={handleActionClick}
            >
              ACTION
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ReturnOrderCard;

const InfoBox = ({
  titleIcon,
  title,
  subtitleIcon,
  subtitle,
  alignRight,
  mobileLabel,
  mobileRightAlign,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return matches ? (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "column" }}
      justifyContent={{ xs: "space-between", md: "flex-start" }}
      alignItems={{
        md: "flex-start",
        xs: alignRight ? "flex-end" : "flex-start",
      }}
      flex={1}
      borderRight={{ xs: "none", md: orderCardBorder2 }}
    >
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems="center"
        gap={{ xs: "0px", md: "10px" }}
      >
        {matches ? titleIcon : null}
        <Typography
          variant="h5"
          fontSize={12.5}
          fontWeight={600}
          color={blackShade05}
          lineHeight="20px"
          sx={{ ...textOneLines }}
        >
          {title}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" gap="10px">
        <Box display="flex" alignItems="center" gap="10px" mt="6px">
          {matches ? subtitleIcon : null}

          <Typography
            variant="p"
            fontSize={12.5}
            fontWeight={500}
            color={bgColor15}
            lineHeight="18px !important"
            ml={`${subtitleIcon ? 6 : 30}px !important`}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box
      textAlign={mobileRightAlign ? "right" : "left"}
      borderBottom={border25}
      pb={1}
    >
      <Typography sx={styles.labelText}>{mobileLabel}</Typography>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent={mobileRightAlign ? "flex-end" : "flex-start"}
        gap={0}
      >
        <Typography
          variant="h5"
          fontSize={12.5}
          fontWeight={500}
          color={blackShade05}
          lineHeight="20px"
        >
          {title}
        </Typography>
        <Box sx={styles.dotUi} />

        <Typography
          variant="h5"
          fontSize={12.5}
          fontWeight={500}
          color={blackShade05}
          lineHeight="20px"
        >
          {subtitle}
        </Typography>
      </Stack>
    </Box>
  );
};

const styles = {
  card: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "16px", md: "20px" },
    backgroundColor: whiteColor,
    border: orderCardBorder,
    borderRadius: { xs: "7px", md: "0px" },
    boxShadow: "0 2px 4px rgb(179 188 198 / 5%)",
    padding: "10px",
    mb: { md: 0, xs: 1 },
  },

  dotUi: {
    width: "6px",
    height: "6px",
    borderRadius: "3px",
    backgroundColor: orangeStatusColor,
  },

  addIconBtn: {
    padding: { md: "4px 10px", xs: "3px 15px" },
    fontSize: "12px",
    fontWeight: 600,
    color: bgColor15,
    backgroundColor: whiteColor,
    border: "1px solid " + bgColor15,
    borderRadius: "4px",
    "&:hover": {
      color: whiteColor,
      backgroundColor: bgColor15,
    },
  },

  actionBtn: {
    padding: { md: "4px 15px", xs: "3px 15px" },
    fontSize: "12px",
    fontWeight: 500,
    color: whiteColor,
    backgroundColor: lightBlueColor01,
    border: "1px solid " + lightBlueColor01,
    "&:hover": {
      color: lightBlueColor01,
      backgroundColor: whiteColor,
    },
  },

  cancelBtn: {
    padding: { md: "5px 24px", xs: "3px 15px" },
    fontSize: "12px",
    fontWeight: 600,
    color: deleteBtnColor,
    backgroundColor: whiteColor,
    border: "1px solid " + deleteBtnColor,
    "&:hover": {
      color: whiteColor,
      backgroundColor: deleteBtnColor,
    },
  },

  trackBtn: {
    color: whiteColor,
    backgroundColor: greyColor8,
    "&:hover": {
      color: greyColor8,
      backgroundColor: whiteColor,
    },
  },

  infoTab: {
    display: "flex",
    alignItems: { xs: "flex-start", md: "flex-start" },
    flexDirection: { xs: "column", md: "column" },
    justifyContent: { xs: "space-between", md: "flex-start" },
    pr: { xs: 0, md: 2 },
    borderRight: { xs: "none", md: orderCardBorder2 },
  },

  labelText: {
    display: { xs: "block", md: "none" },
    fontSize: 11,
    fontWeight: 400,
    color: bgColor15,
  },

  dotUi: {
    width: "4px",
    height: "4px",
    background: bgColor15,
    borderRadius: "30px",
    position: "relative",
    margin: " 0px 10px",
    bottom: "2px",
  },
};
