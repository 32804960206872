import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { useDispatch } from "react-redux";
import firebase from "../../../firebase";
import NumberInput from "../../../components/NumberInput";
import {
  authInputIconColor,
  blackShade09,
  blackShade58,
  blueColor03,
  greyColor6,
  whiteColor,
  yellowColor,
} from "../../../configs/styles/muiThemes";
import { validateMobileNumber } from "../../../utils/validationUtils";
import { checkExistingUser } from "../../../reducers/auth/authSlice";

function MobileNumberScreen({ onContinue, setUserType }) {
  const [mobileNumber, setMobileNumber] = useState(
    localStorage.getItem("phone") ? localStorage.getItem("phone") : ""
  );
  const [isError, setIsError] = useState(false);
  const [isError1, setIsError1] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    setLoader(true);
    const error = validateMobileNumber(mobileNumber, true);
    if (error?.length > 0) {
      setIsError(true);
      setLoader(false);
      return;
    }
    dispatch(checkExistingUser({ phone: mobileNumber })).then((response) => {
      console.log("sssknn", response);
      if (response.payload == undefined) {
        console.log("huhuuihhk");
        setIsError1(true);
        setLoader(false);
        return;
      }
      if (response.payload) {
        if (response.payload.status === 200) {
          if (
            response.payload.data.na === true &&
            response.payload.data.sr === false
          ) {
            setLoader(false);
            console.log("Existing User");
            onContinue({ page: "password", phone: mobileNumber });
          } else {
            setLoader(false);
            console.log("New User");
            if (response.payload.data.na === false) {
              setUserType("new-user");
            } else {
              setUserType("allready-guest-user");
            }
            getOtp();
          }
        }
      } else {
        setLoader(false);
      }
    });
  };

  const configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          getOtp();
          console.log("Recaptca varified");
        },
        defaultCountry: "IN",
      }
    );
  };

  const getOtp = () => {
    configureCaptcha();
    let phone = "+91" + mobileNumber.replace("-", "");
    phone = phone.replace(" ", "");
    console.log("abc", phone);
    const appVerifier = window.recaptchaVerifier;

    firebase
      .auth()
      .signInWithPhoneNumber(phone, appVerifier)
      .then((confirmationResult) => {
        console.log("OTP has been sent", confirmationResult);
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        console.log("OTP has been sent", confirmationResult);
        onContinue({ page: "otp", phone: mobileNumber });
        // ...
      })
      .catch((error) => {});
  };
  return (
    <Box>
      <Typography
        variant="h4"
        color={blackShade58}
        fontSize={{ xs: 17, md: 22 }}
        fontWeight={600}
        lineHeight="30px"
        m={{md:"0px 0 20px !important",xs:"0px 0 10px !important"}}
      >
        Login / Signup
      </Typography>

      {/* this div is used for firebase sign-in don't remove */}
      <div id="sign-in-button"></div>

      <Typography
        variant="body1"
        color={greyColor6}
        fontSize={13}
        fontWeight={400}
        lineHeight="18px"
        mb="4px !important"
      >
        Business Phone Number
      </Typography>

      <NumberInput
        value={mobileNumber}
        onChange={(e) => {
          if (isError) setIsError(false);
          const value = e.target.value
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*)\./g, "$1");
          //   values.phone = e.target.value
          setMobileNumber(value);
        }}
        //   onBlur={() => {
        //     handleInputChange(values.phone, 'phone')
        //   }}
        isError={isError}
        errorMsg="Please enter a valid mobile number"
        autoFocus={true}
        icon={
          <PhoneIphoneIcon
            sx={{ fontSize: "18px", color: authInputIconColor, mt: -1 }}
          />
        }
        showAutoComplete
      />

      {/* <Box sx={{ mt: 2 }}>
        <Typography
          variant="body1"
          component="p"
          color={greyColor1}
          fontSize={12}
          display="inline"
        >
          By continuing, you agree to our{" "}
          <Typography
            color={loginPrimary}
            display="inline"
            fontSize={12}
            fontWeight={500}
            component={Link}
            to={`/terms-conditions`}
          >
            Terms of Use
          </Typography>{" "}
          &{" "}
          <Typography
            color={loginPrimary}
            display="inline"
            fontSize={12}
            fontWeight={500}
            component={Link}
            // target='_blank'
            to={`/privacy-policy`}
          >
            Privacy Policy
          </Typography>
        </Typography>
      </Box> */}

      <Box display="flex" justifyContent="flex-end">
        <Button
          sx={{
            ...styles.actionBtn(),
            border: loader ? "none" : `1px solid ${yellowColor}`,
          }}
          variant="contained"
          disableElevation
          onClick={handleSubmit}
          disabled={loader}
        >
          NEXT
        </Button>
      </Box>
    </Box>
  );
}

export default MobileNumberScreen;

const styles = {
  actionBtn: (outline) => ({
    minWidth: { md: "115px", xs: "auto" },
    fontSize: { md: "14px", xs: "13px" },
    fontWeight: 600,
    color: outline ? yellowColor : blackShade09,
    background: yellowColor,
    p: { md: "5px 22px", xs: "4px 12px" },
    mt: "20px !important",
    borderRadius: "6px",

    "&:hover": {
      background: "none",
      backgroundColor: whiteColor,
      color: blackShade09,
      border: `1px solid ${blackShade09}`,
    },
    letterSpacing: "0.5px",
  }),
};
