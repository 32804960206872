import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import InputPreview from "../../components/Input/InputPreview";
import { blackColor, whiteColor } from "../../configs/styles/muiThemes";

function PriceDetailsPreview({
  currency,
  originalPrice,
  offerPrice,
  taxesIncluded,
  gstHsnCode,
  gstPercentage,
  ondcData,
  viewProduct,
}) {
  return (
    <Box sx={styles.section}>
      <Typography
        component="h5"
        fontSize={{ xs: 15, md: 18 }}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        m="5px 0 20px !important"
      >
        Price Details
      </Typography>

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        <Grid item xs={12} md={2.2}>
          <InputPreview label="Currency" value={currency} />
        </Grid>

        <Grid item xs={6} md={2.2}>
          <InputPreview label="Original Price" value={originalPrice} />
        </Grid>

        <Grid item xs={6} md={2.2}>
          <InputPreview label="Offer Price" value={offerPrice} />
        </Grid>
      </Grid>

      <Typography
        component="h6"
        fontSize={{ xs: 14, md: 15 }}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        m="20px 0 15px !important"
      >
        Tax and GST Information
      </Typography>

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        <Grid item xs={12} md={2.2}>
          <InputPreview label="Taxes Included" value={taxesIncluded} />
        </Grid>

        {/* <Grid item xs={6} md={2.2}>
          <InputPreview label="GST/HSN code" value={gstHsnCode} />
        </Grid> */}

        <Grid item xs={8} md={2.2}>
          <InputPreview
            label="GST Percentage"
            value={`${gstPercentage || "-"}%`}
          />
        </Grid>
        <Grid item xs={6} md={1.8}>
          <InputPreview
            label="Product Code"
            value={
              !viewProduct
                ? ondcData?.ondc?.productCode?.key
                : ondcData?.productCode?.key
            }
          />
        </Grid>
        <Grid item xs={6} md={2.2}>
          <InputPreview
            label=""
            value={
              !viewProduct
                ? ondcData?.ondc?.productCode?.value
                : ondcData?.productCode?.value
            }
            hideLabel
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PriceDetailsPreview;

const styles = {
  section: {
    p: "10px 20px 35px",
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: "13px",
    mt: { xs: "25px" },
  },
};
