import React, { useEffect, useState } from "react";
import AffiliatePartnerInfoCard from "../AffiiateCards/AffiliatePartnerInfoCard";
import {
  clearAffiliatePanelInfo,
  getAllAffiliatePartnerData,
  getAllTopAffiliatesData,
} from "../../../reducers/AffiliatePanels/AffiliateSlice";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import moment from "moment";
import EmptyCard from "../../../components/Cards/EmptyCard";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import InfiniteScroll from "react-infinite-scroll-component";
import ThreeDotLoader from "../../../components/ThreeDotLoader";

function AffiliatePartenersCard() {
  const page_size = 100;
  const [hasMoreData, setHasMoreData] = useState(true);

  const dispatch = useDispatch();

  const { affiliatePartner, affiliatePartnerPageNo, affiliatePartnerLoader } =
    useSelector((state) => state.affiliatePanelControl);

  const getAffiliatePartners = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    dispatch(
      getAllAffiliatePartnerData({
        businessId: businessId,
        pageNo: affiliatePartnerPageNo,
        pageSize: page_size,
      })
    );
  };
  const fetchNextData = () => {
    getAffiliatePartners();
  };
  useEffect(() => {
    getAffiliatePartners();
    return () => {
      // dispatch(clearAffiliatePanelInfo());
    };
  }, []);
  useEffect(() => {
    if (
      (affiliatePartnerPageNo-1) * page_size >
      affiliatePartner?.details?.length
    ) {
      setHasMoreData(false);
    }

    return () => {};
  }, [affiliatePartnerPageNo]);
    
  return (
    <Box>
      {/* json:{JSON.stringify(affiliatePartner)} */}
      <EmptyCard
        icon={
          <Box
            component="img"
            src="https://storage.googleapis.com/bodefaults/businessdashboardweb/coimg-soon.svg"
            alt="coming soon"
            width="40px"
            height="auto"
            display="block"
          />
        }
        msg="Performance details by individual affiliates - Coming Soon"
      />
      {/* {affiliatePartner?.details.length > 0 && (
        <InfiniteScroll
          dataLength={affiliatePartner?.details?.length || 0}
          next={fetchNextData}
          hasMore={hasMoreData}
          loader={
            <Box>
              <ThreeDotLoader />
            </Box>
          }
        >
          {affiliatePartner?.details.map((item, index) => {
            const joinedDate = moment(item?.createdOn).format("MMM Do YYYY");

            const userInfo = affiliatePartner?.profileInfo.filter(
              (profile) => item?.profileId === profile.profileId
            );
            console.log("userInfo_parteners", userInfo);

            let username = "";
            let usernumber = "";
            let profilePic = "";

            if (userInfo?.length > 0) {
              username = userInfo?.[0]?.name;
              usernumber = userInfo?.[0]?.phone;
              profilePic = userInfo?.[0]?.photoUrl;
            }
            const maskedNumber = usernumber.replace(/(\d{4})$/, "****");
            // .substring(0, usernumber.length - 4) + "****";

            return (
              <AffiliatePartnerInfoCard
                profileImage={profilePic}
                profileName={username}
                createdDate={joinedDate}
                phoneNo={maskedNumber}
                commissionPercentage={item?.commissionPercentage}
                cxDiscountPercentage={item?.discountPercentage}
                totalOrders="875"
                commission="280"
                noOfShares="450"
                totalSales=" 3278"
                paid="2350"
                pending="1356"
              />
            );
          })}
        </InfiniteScroll>
      )} */}
    </Box>
  );
}

export default AffiliatePartenersCard;
