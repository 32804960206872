import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import CouponLegend from "./CouponLegend";
import {
  facebookBGColor,
  linkedInBGColor,
  telegramBGColor,
  twitterBGColor,
  whatsappBGColor,
  blackShade05,
  greenStatusColor,
  greyColor24,
  greyColor8,
  orangeStatusColor,
  whiteColor,
  blackShade159,
  blueColor04,
  BGColor02,
  blackShade21,
  mobilePrimary,
} from "../../configs/styles/muiThemes";
import CouponInfoCard from "./CouponInfoCard";
import AddIcon from "@mui/icons-material/Add";
import CouponDiscountDetails from "./CouponDiscountDetails";
import ModalCard from "../../components/ModalCard";
import AddCouponModalContent from "./AddCouponModalContent";
import EmptyCard from "../../components/Cards/EmptyCard";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import EmptyCoupon from "./EmptyCoupon";
import moment from "moment";
import { getAllBusinessCouponsApi } from "../../service/api_calls/BusinessDashboard/BusinessDashboardApi";
import {
  addNewCouponApi,
  prepareAddNewCouponPostData,
} from "../../service/api_calls/coupons/couponsApi";
import { useDispatch } from "react-redux";
import {
  getAllCouponsCodeData,
  getAllCouponsCountData,
  getAllCouponsSummaryData,
} from "../../reducers/coupons/couponSlice";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import { stickyHeader } from "../../utils/styles";
function CouponDiscountContent({
  activeBusinessInfo,
  businessCoupons,
  coupons,
  couponSummary,
  couponCount,
  usageInfo,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();

  const [openAddModal, setOpenAddModal] = useState(false);

  const [newCoupon, setNewCoupon] = useState({
    couponCode: "",
    discountPercentage: "",
    startDate: "",
    endDate: "",
    errors: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState("all");
  const [count, setCount] = useState(null);
  const COUPON_DISCOUNT_STATUS = [
    {
      status: "All",
      color: blackShade159,
      value: "all",
    },
    {
      status: "Active",
      color: greenStatusColor,
      value: "active",
    },
    {
      status: "Expired",
      color: orangeStatusColor,
      value: "expired",
    },
  ];
  const onStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleValueChange = (name, value) => {
    setNewCoupon({
      ...newCoupon,
      [name]: value,
    });
  };

  const handleAddCoupon = () => {
    handleValueChange("startDate", moment().format("YYYY-MM-DD"));
    setOpenAddModal(true);
  };

  const handleCloseModal = () => {
    clearCouponData();
    setOpenAddModal(false);
  };

  const clearCouponData = () => {
    setNewCoupon({
      couponCode: "",
      discountPercentage: "",
      startDate: "",
      endDate: "",
      errors: null,
    });
  };
  const getAllCoupons = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    dispatch(getAllCouponsCodeData({ businessId }));
    dispatch(getAllCouponsSummaryData({ businessId }));
    dispatch(getAllCouponsCountData({ businessId }));
  };

  // Add New Coupon
  const handleAddCouponSubmit = () => {
    let isError = false;
    let errors = {};

    if (!newCoupon.couponCode) {
      errors = { ...errors, couponCode: "Enter coupon code" };
      isError = true;
    }

    if (newCoupon.couponCode && newCoupon.couponCode?.length < 8) {
      errors = { ...errors, couponCode: "Coupon should be Min 8 characters" };
      isError = true;
    }

    if (!newCoupon.discountPercentage) {
      errors = { ...errors, discountPercentage: "Enter discount percentage" };
      isError = true;
    }

    if (!newCoupon.startDate) {
      errors = { ...errors, startDate: "Enter start date" };
      isError = true;
    }
    if (!newCoupon.endDate) {
      errors = { ...errors, endDate: "Enter end date" };
      isError = true;
    }

    if (newCoupon?.startDate && newCoupon?.endDate) {
      const fromDate = moment(newCoupon?.startDate);
      const toDate = moment(newCoupon?.endDate);

      const currentDate = moment().subtract(1, "day");

      const isFromBeforeCurrentDate = fromDate?.isBefore(currentDate);
      const isFromAfterToDate = fromDate?.isAfter(toDate);

      if (isFromAfterToDate) {
        errors = {
          ...errors,
          startDate: "Start Date should be before End Date",
        };
        isError = true;
      }

      if (isFromBeforeCurrentDate) {
        errors = { ...errors, startDate: "Start Date cannot be in past" };
        isError = true;
      }

      if (!isFromBeforeCurrentDate && !isFromAfterToDate) {
        const diffCount = moment.duration(toDate.diff(fromDate)).asDays();

        if (diffCount > 90) {
          errors = {
            ...errors,
            startEndDiff: "Period between start and end date is 90 days",
          };
          isError = true;
        }
      }
    }

    if (isError) {
      console.log("NEW_COUPON", newCoupon);
      handleValueChange("errors", errors);
    } else {
      handleValueChange("errors", null);

      // Add Coupon
      addNewCoupon();
    }
  };

  const addNewCoupon = async () => {
    setIsLoading(true);

    const businessId = sessionStorage.getItem("selectedBusinessId");
    const couponsList = await getAllBusinessCouponsApi({ businessId });

    if (couponsList) {
      const isCouponAlreadyPresent = couponsList?.details?.some(
        (item) => item?.resellerCode === newCoupon?.couponCode
      );
      console.log("ADD_COUPONS_RES:", couponsList);

      if (isCouponAlreadyPresent) {
        handleValueChange("errors", {
          couponCode: "Coupon code already exists, please try another",
        });

        setIsLoading(false);
        return;
      } else {
        // Add Coupon API call
        const postData = prepareAddNewCouponPostData({
          ...newCoupon,
          businessId,
          businessName: activeBusinessInfo?.name || "",
        });

        console.log("ADD_COUPONS_POST_DATA:", postData);

        const res = await addNewCouponApi(postData);

        setIsLoading(false);
        clearCouponData();
        console.log("HITT_COUPON_REFRESH");
        getAllCoupons();
        setOpenAddModal(false);
      }
    }

    setIsLoading(false);
  };
  function countExpiredAndActive(objectsArray) {
    const currentDate = moment();
    let expiredCount = 0;
    let activeCount = 0;

    objectsArray?.forEach((obj) => {
      const expirationDate = moment(obj?.codeValidityEnd);
      if (expirationDate?.isBefore(currentDate)) {
        expiredCount++;
      } else {
        activeCount++;
      }
    });

    return { expiredCount, activeCount };
  }

  useEffect(() => {
    if(coupons?.length > 0){
    const count = countExpiredAndActive(coupons);
    console.log("count_coupon",count);
    setCount(count)
    }
    return () => {};
  }, [coupons]);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ ...stickyHeader, pb: { md: 0.4, xs: 1 }, pt: 2 }}
      >
        <Box
          display="flex"
          alignItems={{ md: "center" }}
          gap={{ xs: "4px", md: "20px" }}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Typography
            variant="h4"
            color={blackShade05}
            fontSize="17px"
            fontWeight="600"
          >
            Coupons/Promo Code
          </Typography>
          {matches ? null : (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"column"}
              gap={1}
            >
              <Box>
                <Typography
                  sx={{
                    color: blackShade21,
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  Increase your sales by 5X, with Limited time offers{" "}
                </Typography>
              </Box>
              <Box sx={{ display: { md: "block", xs: "none" } }}>
                <Button
                  sx={styles.addIconBtn}
                  variant="contained"
                  disableElevation
                  onClick={handleAddCoupon}
                  // postion="relative"
                  startIcon={<AddIcon />}
                >
                  {matches ? "New coupon" : "New"}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        {coupons?.length === 0 && (
          <Box sx={{ display: { md: "block", xs: "block" } }}>
            <Button
              sx={styles.addIconBtn}
              variant="contained"
              disableElevation
              onClick={handleAddCoupon}
              // postion="relative"
              startIcon={<AddIcon />}
            >
              {matches ? "New coupon" : "New"}
            </Button>
          </Box>
        )}
      </Box>
      {!couponSummary?.totalCoupons ? (
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Box
            display={"flex"}
            gap={1}
            sx={{
              position: "sticky",
              top: { md: 85, xs: 200 },
              backgroundColor: BGColor02,
              zIndex: 100,
              pb: 2,
            }}
          >
            <CouponLegend dotColor={blackShade159} label="All" />
            <CouponLegend dotColor={greenStatusColor} label="Active" />
            <CouponLegend dotColor={orangeStatusColor} label="Expired" />
          </Box>
          <EmptyCoupon />
        </Box>
      ) : (
        <>
          {matches ? (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{
                position: "sticky",
                top: 85,
                backgroundColor: BGColor02,
                zIndex: 100,
                pb: 2,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: blackShade21,
                    fontSize: { xs: "0px", md: "13px" },
                    fontWeight: 500,
                    // mt: { xs: "0px", md: "20px" },
                    display: "inline",
                  }}
                >
                  Increase your sales by 5X, with Limited time offers.<br></br>
                  <Box display={"flex"}>
                    <Typography
                      sx={{
                        display: "inline",
                        fontSize: { xs: "0px", md: "13px" },
                      }}
                    >
                      Share coupon details with customers via multiple social
                      media channels.
                    </Typography>
                    <Box display="flex">
                      <Box sx={styles.iconStyle(whatsappBGColor)}>
                        <WhatsAppIcon />
                      </Box>
                      <Box sx={styles.iconStyle(facebookBGColor)}>
                        <FacebookOutlinedIcon />
                      </Box>
                      <Box sx={styles.iconStyle(linkedInBGColor)}>
                        <LinkedInIcon />
                      </Box>
                      <Box sx={styles.iconStyle(twitterBGColor)}>
                        <TwitterIcon />
                      </Box>
                      <Box sx={styles.iconStyle(telegramBGColor)}>
                        <TelegramIcon />
                      </Box>
                    </Box>
                  </Box>
                </Typography>
              </Box>
              <Box>
                <Button
                  sx={styles.addIconBtn}
                  variant="contained"
                  disableElevation
                  onClick={handleAddCoupon}
                  // postion="relative"
                  startIcon={<AddIcon />}
                >
                  {matches ? "New coupon" : "New"}
                </Button>
              </Box>
            </Box>
          ) : null}

          {matches ? (
            <Box
              display="flex"
              gap={4}
              sx={{
                position: "sticky",
                top: 150,
                backgroundColor: BGColor02,
                zIndex: 100,
                pb: 1.5,
              }}
            >
              {COUPON_DISCOUNT_STATUS.map((item, index) => {
                return (
                  <CouponLegend
                    index={index}
                    key={`status+${index}`}
                    dotColor={item.color}
                    label={item?.status}
                    isActive={item?.value === selectedStatus}
                    onSelect={() => onStatusChange(item?.value)}
                  />
                );
              })}
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              gap={6.5}
              sx={{
                position: "sticky",
                top: 170,
                backgroundColor: BGColor02,
                zIndex: 100,
                pb: { md: 2, xs: 1.3 },
              }}
            >
              <Box flex={1} display="flex">
                {/* <CouponLegend dotColor={greenStatusColor} label="Active" />
                  <CouponLegend dotColor={orangeStatusColor} label="Expired" /> */}
                {COUPON_DISCOUNT_STATUS.map((item, index) => {
                  return (
                    <CouponLegend
                      index={index}
                      key={`status+${index}`}
                      dotColor={item.color}
                      label={item?.status}
                      isActive={item?.value === selectedStatus}
                      onSelect={() => onStatusChange(item?.value)}
                    />
                  );
                })}
              </Box>
              <Box flex={1} mt={{ md: 2, xs: 1 }} mb={2}>
                <Button
                  sx={styles.addIconBtn}
                  variant="contained"
                  disableElevation
                  onClick={handleAddCoupon}
                  // postion="relative"
                  startIcon={<AddIcon />}
                >
                  {matches ? "New coupon" : "New"}
                </Button>
              </Box>
            </Box>
          )}

          <CouponInfoCard
            totalCoupons={couponSummary?.totalCoupons}
            activeCoupons={couponSummary?.totalActiveCoupons}
            ordersPalcedWithCoupon={couponCount?.totalCouponOrders}
            totalUsedDiscount={couponCount?.totalCouponOrderValue}
            couponExpired={couponSummary?.totalExpiredCoupons}
          />
          {selectedStatus === "all" &&
            businessCoupons?.details &&
            businessCoupons?.details?.length === 0 && (
              <EmptyCard
                icon={<CardGiftcardOutlinedIcon sx={{ fontSize: "24px" }} />}
                msg="No Coupons Available"
              />
            )}
          {selectedStatus === "active" && count?.activeCount === 0 && (
            <EmptyCard
              icon={<CardGiftcardOutlinedIcon sx={{ fontSize: "24px" }} />}
              msg="No Active Coupons Available"
            />
          )}
          {selectedStatus === "expired" && count?.expiredCount === 0 && (
            <EmptyCard
              icon={<CardGiftcardOutlinedIcon sx={{ fontSize: "24px" }} />}
              msg="No Expired Coupons Available"
            />
          )}
          <CouponDiscountDetails
            coupons={coupons}
            usageInfo={usageInfo}
            activeBusinessInfo={activeBusinessInfo}
            statusValue={selectedStatus}
          />
        </>
      )}

      {/* <AddCoupon /> */}
      <ModalCard
        open={openAddModal}
        handleClose={handleCloseModal}
        width={{ xs: "20%", md: "28%" }}
      >
        <AddCouponModalContent
          data={newCoupon}
          handleValueChange={handleValueChange}
          onCancel={handleCloseModal}
          onSubmit={handleAddCouponSubmit}
          isLoading={isLoading}
        />
      </ModalCard>
    </Box>
  );
}

export default CouponDiscountContent;

const styles = {
  addIconBtn: {
    backgroundColor: "transparent",
    padding: { xs: "2px 0px", md: "4px 10px" },
    fontSize: { xs: "11px", md: "13px" },
    fontWeight: 600,
    color: {xs: mobilePrimary, md:blueColor04},
    backgroundColor: whiteColor,
    border: {xs:`1.5px solid ${mobilePrimary}`, md: `1.5px solid ${blueColor04}`},
    "&:hover": {
      color: whiteColor,
      backgroundColor: {xs: mobilePrimary, md:blueColor04},
    },
  },
  iconStyle: (bgColor) => ({
    width: { xs: "0px", md: "18px" },
    height: { xs: "0px", md: "18px" },
    backgroundColor: bgColor,
    borderRadius: "7px",
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: whiteColor,
    marginBottom: "4px",
    marginLeft: "5px",
    cursor: "pointer",
  }),
};
