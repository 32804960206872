import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Divider,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import FormInputBox from "../../../components/Input/FormInputBox";
import {
  bgColor,
  blackColor,
  blackShade05,
  blackShade21,
  blueColor02,
  blueColor04,
  greyColor21,
  greyColor24,
  greyColor6,
  greyColor7,
  mobilePrimary,
  primaryColor,
  progressBGColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import DashboardStepper from "../../Dashboard/DashboardStepper";
import { ECOMMERCE_STEP_LIST } from "../../../utils/appData";
import RadiosBtnWithLabel from "../../../components/Input/RadiosBtnWithLabel";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import CheckBoxWithLabel from "../../../components/Input/CheckBoxWithLabel";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import PricingInputModal from "../PopupModals/PricingInputModal";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getActiveBusinessInfo } from "../../../reducers/businessPreview/businessPreviewSlice";
import {
  ECOMINPUTSUPPORTLABEL,
  emailAddress,
  whatsAppNumber,
} from "../../../configs/Constants";
import ModalCard from "../../../components/ModalCard";
import { stickyHeader } from "../../../utils/styles";

function AddDeliveryMethods({
  isEdit,
  data,
  setData,
  setDiscountInfo,
  onValueChange,
  currentPage,
  gotoNext,
  goBack,
  handleCancel,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();

  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
    }
  }, []);

  const COD_FEE_CHARGES = [
    { label: "Select Cash on delivery fee charged to" },
    { label: "Business" },
    { label: "Customer" },
  ];

  const DISCOUNT_TYPE = [
    { label: "Select Discount Type" },
    { label: "No Discount" },
    { label: "Amount" },
    { label: "Percentage" },
  ];

  const onDeliveryMethodChange = (value) => {
    if (value === "Fulfillment by Business") {
      if (
        isEdit &&
        data?.deliveryMethods?.includes("Fulfillment by Business")
      ) {
        setShowAlert(true);
        return;
      }
    }

    const deliveryMethodList = data?.deliveryMethods;

    const index = deliveryMethodList.indexOf(value);
    if (index === -1) {
      deliveryMethodList.push(value);
    } else {
      deliveryMethodList.splice(index, 1);
    }

    console.log(
      "onDeliveryMethodChange_Value_deliveryMethods:",
      value,
      deliveryMethodList
    );

    if (value === "CostBo Delivery") {
      setData((prevData) => ({
        ...prevData,
        costBoDelivery: {
          from: "",
          to: "",
          type: "delhivery",
          unit: "km",
          deliveryTime: ["COSTBO SURFACE"],
        },
        cod: {
          type: "delhivery",
          limit: "",
          notes: `Maximum allowed Rs  otherwise pay by card/wallet`,
          codFlatCharge: 45.0,
          codPercent: 3.0,
          settlementDays: 7,
          chargeTo: "Select Cash on delivery fee charged to",
        },
        deliveryMethods: deliveryMethodList,
        errors: {},
      }));
    }

    if (value === "CostBo Hyperlocal") {
      setData((prevData) => ({
        ...prevData,

        costBoHyperlocal: {
          from: "",
          to: "",
          type: "telyport",
          unit: "km",
          deliveryTime: [],
        },

        deliveryMethods: deliveryMethodList,
        errors: {},
      }));
    }

    if (value === "Fulfillment by Business") {
      setData((prevData) => ({
        ...prevData,
        fullfillment: {
          from: "",
          to: "",
          type: "business",
          unit: "km",
          deliveryTime: [],
        },

        fullfillmentPricing: [],
        deliveryMethods: deliveryMethodList,
        errors: {},
      }));
    }

    if (value === "B2B Delivery") {
      setData((prevData) => ({
        ...prevData,

        b2bDelivery: {
          from: "",
          to: "",
          type: "delhiveryb2b",
          unit: "km",
          deliveryTime: ["COSTBO B2B"],
        },
        deliveryMethods: deliveryMethodList,
        errors: {},
      }));
    }

    if (value === "Store Pickup") {
      setData((prevData) => ({
        ...prevData,

        storePickup: {
          discountBy: "Select Discount Type",
          discountAmount: "",
          discountPercentage: "",
        },
        deliveryMethods: deliveryMethodList,
        errors: {},
      }));
    }

    // console.log("deliveryMethodList:", deliveryMethodList);
    // onValueChange("deliveryMethod", deliveryMethodList);
  };

  const updateValue = (value, infoObject, attribute) => {
    setData((prevData) => ({
      ...prevData,
      [infoObject]: {
        ...prevData?.[infoObject],
        [attribute]: value,
      },
    }));
  };

  const clearBusinessFullfillmentInfo = (value) => {
    const deliveryMethodList = data?.deliveryMethods;

    const index = deliveryMethodList.indexOf(value);
    if (index === -1) {
      deliveryMethodList.push(value);
    } else {
      deliveryMethodList.splice(index, 1);
    }

    console.log(
      "onDeliveryMethodChange_Value_deliveryMethods:",
      value,
      deliveryMethodList
    );

    setData((prevData) => ({
      ...prevData,
      fullfillment: {
        from: "",
        to: "",
        type: "business",
        unit: "km",
        deliveryTime: [],
      },

      fullfillmentPricing: [],
      deliveryMethods: deliveryMethodList,
      errors: {},
    }));

    // setDiscountInfo((prevState) => ({
    //   ...prevState,
    //   additionalDiscount: [],
    // }));

    setShowAlert(false);
  };

  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Support+Request.+`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };

  return (
    <Box>
      {/* <Box sx={stickyHeader} pb={1.5}>
        <Typography
          component="h6"
          fontSize={{ xs: 15, md: 17 }}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          mb={"4px !important"}
        >
          E-Commerce Details
        </Typography>

        <Typography
          variant="h6"
          fontSize={13}
          fontWeight={500}
          color={blackShade21}
          display="inline"
          mb="15px"
        >
          {ECOMINPUTSUPPORTLABEL}{" "}
          <Typography
            // variant="body1"
            fontSize={13}
            fontWeight={500}
            color={blueColor04}
            display="inline"
            // onclick={handleChatClick}
            component="a"
            href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Support+Request.+&fs=1&to=${emailAddress}&tf=cm`}
            target="_blank"
          >
            Email
          </Typography>
          {"  "}
          or{" "}
          <Typography
            // variant="body1"
            fontSize={13}
            fontWeight={500}
            color={blueColor04}
            display="inline"
            sx={{ cursor: "pointer" }}
            onClick={handleChatClick}
          >
            Whatsapp
          </Typography>
        </Typography>
      </Box> */}

      <Box m="0px 0 15px 0" sx={styles.card}>
        {/* <DashboardStepper
          stepList={ECOMMERCE_STEP_LIST}
          currentPage={currentPage}
          isAddProduct
        /> */}
        <Box
          m="15px 0 5px 0"
          sx={{
            p: {
              md: 0,
              xs: "10px 15px 0px 15px",
            },
          }}
        >
          <Typography
            component="h6"
            fontSize={{ xs: 14, md: 14 }}
            color={blackColor}
            fontWeight={600}
            lineHeight="20px"
            display={{ md: "inline", xs: "block" }}
          >
            Delivery Methods <span style={styles.required}>*</span>
          </Typography>

          <Typography
            component="span"
            fontSize={{ xs: 12, md: 13 }}
            color={greyColor7}
            fontWeight={400}
            mt={{ xs: 1, md: 0 }}
            display={{ md: "inline", xs: "block" }}
            ml={{ md: "6px !important", xs: "0" }}
          >
            (Automated Pan India Delivery / Fulfillment by Business either one is mandatory)
          </Typography>
          <Typography
            component="span"
            fontSize={{ xs: 12, md: 13 }}
            color={greyColor7}
            fontWeight={400}
            display={{ md: "block", xs: "block" }}
            mt={0.5}
          >
            (For All India shipment enter 'To Distance (in KM)' as 4000)
          </Typography>
        </Box>

        {/* <Typography
          component="span"
          fontSize={{ xs: 12, md: 13 }}
          color={greyColor7}
          fontWeight={400}
        >
          (For All India shipment enter 'To Distance (in KM)' as 4000)
        </Typography> */}

        <Box sx={styles.mobileBorder}>
          <CheckBoxWithLabel
            label="Automated Pan India Delivery"
            infoText="(Fully automated with tracking)"
            checked={data?.deliveryMethods?.includes("CostBo Delivery")}
            onValuesChanged={() => {
              onDeliveryMethodChange("CostBo Delivery");
            }}
            checkBoxStyle={styles.checkBoxStyle}
            labelStyle={styles.labelStyle}
          />

          <Grid
            container
            rowSpacing={{ xs: 0.5, md: 2 }}
            columnSpacing={{ md: 3, xs: 0 }}
            ml={{ md: 0.5, xs: 0 }}
          >
            <Grid item xs={5.5} md={2}>
              <FormInputBox
                label={matches ? "From Distance (in KM)" : "From Distance"}
                placeholder="Min 0"
                value={data?.costBoDelivery?.from}
                onChange={({ target }) => {
                  const value = target?.value?.replace(/[^0-9]/g, "");
                  updateValue(value, "costBoDelivery", "from");
                }}
                disabled={!data?.deliveryMethods?.includes("CostBo Delivery")}
                error={data?.errors?.costBoDelivery?.from}
              />
              <Typography
                variant="h3"
                fontSize={12}
                color={greyColor6}
                fontWeight={400}
                mb={2}
                sx={{
                  display: { md: "none", xs: "block" },
                  opacity: !data?.deliveryMethods?.includes("CostBo Delivery")
                    ? 0.7
                    : 1,
                }}
              >
                (in KM)
              </Typography>
            </Grid>

            <Grid item xs={5.5} md={2} ml={{ md: 0, xs: 2 }}>
              <FormInputBox
                label={matches ? "To Distance (in KM)" : "To Distance"}
                placeholder="Max 4000"
                value={data?.costBoDelivery?.to}
                onChange={({ target }) => {
                  const value = target?.value?.replace(/[^0-9]/g, "");
                  updateValue(value, "costBoDelivery", "to");
                }}
                disabled={!data?.deliveryMethods?.includes("CostBo Delivery")}
                error={data?.errors?.costBoDelivery?.to}
              />
              <Typography
                variant="h3"
                fontSize={12}
                color={greyColor6}
                fontWeight={400}
                mb={2}
                sx={{
                  display: { md: "none", xs: "block" },
                  opacity: !data?.deliveryMethods?.includes("CostBo Delivery")
                    ? 0.7
                    : 1,
                }}
              >
                (in KM)
              </Typography>
            </Grid>
          </Grid>

          <Box ml={{ md: 3.5, xs: 0 }}>
            <Typography
              component="h6"
              fontSize={{ xs: 13.5, md: 13.5 }}
              color={blackColor}
              fontWeight={600}
              lineHeight="20px"
              display="inline"
            >
              Cash on Delivery (COD) <span style={styles.required}>*</span>
            </Typography>

            {/* <Typography
              component="span"
              fontSize={{ xs: 13, md: 13 }}
              color={greyColor7}
              fontWeight={400}
              display="inline"
              ml="6px !important"
            >
              (Applicable only for Automated Pan India Delivery
            </Typography> */}

            <Box>
              <RadiosBtnWithLabel
                label="Yes"
                checked={data?.isCOD}
                onValuesChanged={() => {
                  onValueChange("isCOD", true);
                }}
                disabled={!data?.deliveryMethods?.includes("CostBo Delivery")}
              />

              <RadiosBtnWithLabel
                label="No"
                checked={!data?.isCOD}
                onValuesChanged={() => {
                  setData({
                    ...data,
                    isCOD: false,
                  });
                }}
                disabled={!data?.deliveryMethods?.includes("CostBo Delivery")}
              />
            </Box>

            <Box sx={{ pb: { xs: 2.5, md: 1 } }}>
              <Grid container rowSpacing={{ xs: 0.5, md: 2 }} columnSpacing={3}>
                <Grid item xs={11} md={2.8}>
                  <FormInputBox
                    label="COD Order Amount Max Limit"
                    placeholder=""
                    startIcon="₹"
                    value={data?.cod?.limit}
                    onChange={({ target }) => {
                      const value = target?.value?.replace(/[^0-9]/g, "");
                      updateValue(value, "cod", "limit");
                      updateValue(
                        `Maximum allowed Rs ${value} otherwise pay by card/wallet`,
                        "cod",
                        "notes"
                      );
                    }}
                    disabled={data?.isCOD === false || data?.isCOD === ""}
                    error={data?.errors?.costBoDelivery?.limit}
                  />
                </Grid>

                <Grid item xs={11} md={2}>
                  <SelectWithLabel
                    label="COD fee charged to"
                    value={data?.cod?.chargeTo}
                    onValueChange={(value) => {
                      updateValue(value, "cod", "chargeTo");
                    }}
                    menuList={COD_FEE_CHARGES}
                    disabled={data?.isCOD === false || data?.isCOD === ""}
                    errorMsg={data?.errors?.costBoDelivery?.chargeTo}
                  />
                </Grid>
              </Grid>

              <Typography
                component="p"
                fontSize={{ xs: 12, md: 12.5 }}
                color={greyColor7}
                fontWeight={400}
                lineHeight={"20px"}
              >
                Additional Fee for COD - Amount ₹ 45 or Percentage 3% (of total
                order amount), whichever higher will be charged
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* <Divider
          
        /> */}
        <Box sx={styles.mobileBorder}>
          <CheckBoxWithLabel
            label="Automated Hyperlocal - Bengaluru Only"
            infoText="(Fully automated with tracking)"
            checked={data?.deliveryMethods?.includes("CostBo Hyperlocal")}
            onValuesChanged={() => {
              onDeliveryMethodChange("CostBo Hyperlocal");
            }}
            checkBoxStyle={styles.checkBoxStyle}
            labelStyle={styles.labelStyle}
          />

          <Grid
            container
            rowSpacing={{ xs: 0.5, md: 2 }}
            columnSpacing={{ md: 3, xs: 0 }}
            ml={{ md: 0.5, xs: 0 }}
          >
            <Grid item xs={5.5} md={2}>
              <FormInputBox
                label={matches ? "From Distance (in KM)" : "From Distance"}
                placeholder="Min 0"
                value={data?.costBoHyperlocal?.from}
                onChange={({ target }) => {
                  const value = target?.value?.replace(/[^0-9]/g, "");
                  updateValue(value, "costBoHyperlocal", "from");
                }}
                disabled={!data?.deliveryMethods?.includes("CostBo Hyperlocal")}
                error={data?.errors?.costBoHyperlocal?.from}
              />
              <Typography
                variant="h3"
                fontSize={12}
                color={greyColor6}
                fontWeight={400}
                mb={2}
                sx={{
                  display: { md: "none", xs: "block" },
                  opacity: !data?.deliveryMethods?.includes("CostBo Hyperlocal")
                    ? 0.7
                    : 1,
                }}
              >
                (in KM)
              </Typography>
            </Grid>

            <Grid item xs={5.5} md={2} ml={{ md: 0, xs: 2 }}>
              <FormInputBox
                label={matches ? "To Distance (in KM)" : "To Distance"}
                placeholder="Max 40"
                value={data?.costBoHyperlocal?.to}
                onChange={({ target }) => {
                  const value = target?.value?.replace(/[^0-9]/g, "");
                  updateValue(value, "costBoHyperlocal", "to");
                }}
                disabled={!data?.deliveryMethods?.includes("CostBo Hyperlocal")}
                error={data?.errors?.costBoHyperlocal?.to}
              />
              <Typography
                variant="h3"
                fontSize={12}
                color={greyColor6}
                fontWeight={400}
                mb={2}
                sx={{
                  display: { md: "none", xs: "block" },
                  opacity: !data?.deliveryMethods?.includes("CostBo Hyperlocal")
                    ? 0.7
                    : 1,
                }}
              >
                (in KM)
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={styles.mobileBorder}>
          <CheckBoxWithLabel
            label="Fulfillment by Business"
            checked={data?.deliveryMethods?.includes("Fulfillment by Business")}
            onValuesChanged={() => {
              onDeliveryMethodChange("Fulfillment by Business");
            }}
            checkBoxStyle={styles.checkBoxStyle}
            labelStyle={styles.labelStyle}
          />

          <Grid
            container
            rowSpacing={{ xs: 0.5, md: 2 }}
            columnSpacing={{ md: 3, xs: 0 }}
            ml={{ md: 0.5, xs: 0 }}
          >
            <Grid item xs={5.5} md={2}>
              <FormInputBox
                label={matches ? "From Distance (in KM)" : "From Distance"}
                placeholder="Min 0"
                value={data?.fullfillment?.from}
                onChange={({ target }) => {
                  const value = target?.value?.replace(/[^0-9]/g, "");
                  updateValue(value, "fullfillment", "from");
                }}
                disabled={
                  !data?.deliveryMethods?.includes("Fulfillment by Business")
                }
                error={data?.errors?.fullfillment?.from}
              />
              <Typography
                variant="h3"
                fontSize={12}
                color={greyColor6}
                fontWeight={400}
                mb={2}
                sx={{
                  display: { md: "none", xs: "block" },
                  opacity: !data?.deliveryMethods?.includes(
                    "Fulfillment by Business"
                  )
                    ? 0.7
                    : 1,
                }}
              >
                (in KM)
              </Typography>
            </Grid>

            <Grid item xs={5.5} md={2} ml={{ md: 0, xs: 2 }}>
              <FormInputBox
                label={matches ? "To Distance (in KM)" : "To Distance"}
                placeholder="Max 4000"
                value={data?.fullfillment?.to}
                onChange={({ target }) => {
                  const value = target?.value?.replace(/[^0-9]/g, "");
                  updateValue(value, "fullfillment", "to");
                }}
                disabled={
                  !data?.deliveryMethods?.includes("Fulfillment by Business")
                }
                error={data?.errors?.fullfillment?.to}
              />
              <Typography
                variant="h3"
                fontSize={12}
                color={greyColor6}
                fontWeight={400}
                mb={2}
                sx={{
                  display: { md: "none", xs: "block" },
                  opacity: !data?.deliveryMethods?.includes(
                    "Fulfillment by Business"
                  )
                    ? 0.7
                    : 1,
                }}
              >
                (in KM)
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={7.5}
              sx={{
                opacity: !data?.deliveryMethods?.includes(
                  "Fulfillment by Business"
                )
                  ? 0.5
                  : 1,
              }}
            >
              <Typography
                variant="h3"
                fontSize={13}
                color={greyColor6}
                fontWeight={500}
                mb={"0px"}
              >
                Pricing
              </Typography>

              <PricingInputModal
                label="Pricing"
                placeholder="Ex: ₹0 - ₹100 Delivery Price ₹50, ₹100 - ₹200 Delivery Price ₹100"
                required
                disabled
                onValueChange={() => {}}
                value={data?.fullfillmentPricing}
                setMoreProducts={(data) => {
                  onValueChange("fullfillmentPricing", data);
                }}
                clickDisabled={
                  !data?.deliveryMethods?.includes("Fulfillment by Business") ||
                  !data?.fullfillment?.from ||
                  !data?.fullfillment?.to
                }
                minValue={data?.fullfillment?.from}
                maxValue={data?.fullfillment?.to}
              />

              <Typography variant="body2" sx={styles.errorText} m="0px 0 12px">
                {data?.errors?.fullfillment?.fullfillmentPricing}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={styles.mobileBorder}>
          <CheckBoxWithLabel
            label="B2B Delivery"
            infoText="(Heavy Shipment)"
            checked={data?.deliveryMethods?.includes("B2B Delivery")}
            onValuesChanged={() => {
              onDeliveryMethodChange("B2B Delivery");
            }}
            checkBoxStyle={styles.checkBoxStyle}
            labelStyle={styles.labelStyle}
          />

          <Typography
            component="h3"
            fontSize={{ xs: 12, md: 13 }}
            color={{ xs: mobilePrimary, md: blueColor04 }}
            fontWeight={{ md: 600, xs: 500 }}
            m={{ md: "-12px 0 12px 28px", xs: "0px 0 12px 28px" }}
          >
            from 25Kg to 5000Kg
          </Typography>

          <Grid
            container
            rowSpacing={{ xs: 0.5, md: 2 }}
            columnSpacing={{ md: 3, xs: 0 }}
            ml={{ md: 0.5, xs: 0 }}
          >
            <Grid item xs={5.5} md={2}>
              <FormInputBox
                label={matches ? "From Distance (in KM)" : "From Distance"}
                placeholder="Min 0"
                value={data?.b2bDelivery?.from}
                onChange={({ target }) => {
                  const value = target?.value?.replace(/[^0-9]/g, "");
                  updateValue(value, "b2bDelivery", "from");
                }}
                disabled={!data?.deliveryMethods?.includes("B2B Delivery")}
                error={data?.errors?.b2bDelivery?.from}
              />
              <Typography
                variant="h3"
                fontSize={12}
                color={greyColor6}
                fontWeight={400}
                mb={2}
                sx={{
                  display: { md: "none", xs: "block" },
                  opacity: !data?.deliveryMethods?.includes("B2B Delivery")
                    ? 0.7
                    : 1,
                }}
              >
                (in KM)
              </Typography>
            </Grid>

            <Grid item xs={5.5} md={2} ml={{ md: 0, xs: 2 }}>
              <FormInputBox
                label={matches ? "To Distance (in KM)" : "To Distance"}
                placeholder="Max 4000"
                value={data?.b2bDelivery?.to}
                onChange={({ target }) => {
                  const value = target?.value?.replace(/[^0-9]/g, "");
                  updateValue(value, "b2bDelivery", "to");
                }}
                disabled={!data?.deliveryMethods?.includes("B2B Delivery")}
                error={data?.errors?.b2bDelivery?.to}
              />
              <Typography
                variant="h3"
                fontSize={12}
                color={greyColor6}
                fontWeight={400}
                mb={2}
                sx={{
                  display: { md: "none", xs: "block" },
                  opacity: !data?.deliveryMethods?.includes("B2B Delivery")
                    ? 0.7
                    : 1,
                }}
              >
                (in KM)
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            p: {
              md: 0,
              xs: "5px 15px ",
            },
          }}
        >
          <CheckBoxWithLabel
            label="Store Pickup"
            checked={data?.deliveryMethods?.includes("Store Pickup")}
            onValuesChanged={() => {
              onDeliveryMethodChange("Store Pickup");
            }}
            checkBoxStyle={styles.checkBoxStyle}
            labelStyle={styles.labelStyle}
          />

          <Grid
            container
            rowSpacing={{ xs: 0.5, md: 2 }}
            columnSpacing={{ md: 3, xs: 0 }}
            ml={{ md: 0.5, xs: 0 }}
            mt={{md:0,xs:-1}}
          >
            <Grid item xs={12} md={2.5}>
              <SelectWithLabel
                label="Discount Type"
                value={data?.storePickup?.discountBy}
                onValueChange={(value) => {
                  updateValue("", "storePickup", "discountAmount");
                  updateValue("", "storePickup", "discountPercentage");
                  updateValue(value, "storePickup", "discountBy");
                }}
                menuList={DISCOUNT_TYPE}
                disabled={!data?.deliveryMethods?.includes("Store Pickup")}
                errorMsg={data?.errors?.storePickup?.discountBy}
              />
            </Grid>

            {data?.storePickup?.discountBy !== "No Discount" && (
              <>
                {data?.storePickup?.discountBy === "Amount" && (
                  <Grid item xs={5.5} md={2.5}>
                    <FormInputBox
                      label="Discount Amount"
                      placeholder=""
                      startIcon="₹"
                      value={data?.storePickup?.discountAmount}
                      onChange={({ target }) => {
                        const value = target?.value?.replace(/[^0-9]/g, "");
                        updateValue(value, "storePickup", "discountAmount");
                      }}
                      disabled={
                        !data?.deliveryMethods?.includes("Store Pickup") ||
                        data?.storePickup?.discountBy !== "Amount"
                      }
                      error={data?.errors?.storePickup?.discountAmount}
                    />
                  </Grid>
                )}

                {data?.storePickup?.discountBy === "Percentage" && (
                  <Grid item xs={5.5} md={2.5} ml={{ md: 0, xs: 2 }}>
                    <FormInputBox
                      label={matches ? "Discount Percentage" : "Discount %"}
                      placeholder=""
                      value={data?.storePickup?.discountPercentage}
                      onChange={({ target }) => {
                        const value = target?.value?.replace(/[^0-9]/g, "");
                        updateValue(value, "storePickup", "discountPercentage");
                      }}
                      disabled={
                        !data?.deliveryMethods?.includes("Store Pickup") ||
                        data?.storePickup?.discountBy !== "Percentage"
                      }
                      error={data?.errors?.storePickup?.discountPercentage}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Box>

        <Typography variant="body2" sx={styles.errorText} m="0px 0 12px">
          {data?.errors?.deliveryMethods &&
            "Automated Pan India Delivery / Fulfillment by Business either one is mandatory"}
        </Typography>

        <Box
          mt="20px"
          sx={{
            p: {
              md: 0,
              xs: "0px 15px 15px",
            },
          }}
        >
          <ActionButtons
            onBackClick={goBack}
            onSaveClick={() => {}}
            onNextClick={gotoNext}
            onCancelClick={handleCancel}
            showBackBtn
            hideSaveBtn
            backBtnText="Previous"
          />
        </Box>

        <ModalCard
          open={showAlert}
          handleClose={() => {
            setShowAlert(false);
          }}
          width="48%"
        >
          <AlertMessagePopup
            text={`All the additional discount based on Distance will be removed if the 'Fulfillment by Business' delivery method is disabled`}
            onClick={() => {
              clearBusinessFullfillmentInfo("Fulfillment by Business");
            }}
            onCancel={() => {
              setShowAlert(false);
            }}
          />
        </ModalCard>
      </Box>
    </Box>
  );
}

export default AddDeliveryMethods;

const AlertMessagePopup = ({ text, onCancel, onClick }) => {
  return (
    <Box sx={styles.alertCard}>
      <Typography
        variant="body2"
        fontSize={14}
        fontWeight={500}
        textAlign="center"
        sx={{ whiteSpace: "pre-line", lineHeight: "26px", my: 1.2 }}
      >
        {text}
      </Typography>
      <Box display="flex" alignItems="center" gap="20px">
        <Button sx={styles.cancelBtn} variant="outlined" onClick={onCancel}>
          CANCEL
        </Button>

        <Button sx={styles.alertBtn} variant="outlined" onClick={onClick}>
          CONTINUE
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    p: { md: "5px 20px 20px 20px", xs: "0" },
    // p: {md:"5px 20px 20px 20px",xs:"5px 15px 15px"},
  },

  required: {
    fontSize: 14,
    fontWeight: 400,
    color: "red",
  },

  errorText: {
    fontSize: 12,
    fontWeight: 500,
    color: "red",
  },

  checkBoxStyle: {
    color: greyColor21,
  },

  labelStyle: {
    fontSize: { md: 13.5, xs: 13.5 },
    fontWeight: 600,
    color: blackShade05,
  },

  mobileBorder: {
    p: {
      md: 0,
      xs: "10px 15px 5px",
    },
    borderBottom: { xs: "4px solid" + bgColor, md: "none" },
  },

  alertCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    padding: "20px 20px 15px 20px",
    borderRadius: "8px",
  },

  alertBtn: {
    fontSize: "13px",
    fontWeight: "500",
    color: whiteColor,
    backgroundColor: primaryColor,
    padding: "6px 24px",
    my: "10px",
    "&:hover": {
      color: primaryColor,
      backgroundColor: whiteColor,
    },
  },

  cancelBtn: {
    fontSize: "13px",
    fontWeight: "500",
    color: primaryColor,
    backgroundColor: whiteColor,
    border: `1px solid ${primaryColor}`,
    padding: "6px 24px",
    my: "10px",
    "&:hover": {
      color: primaryColor,
      backgroundColor: whiteColor,
    },
  },
};
