import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  blackShade19,
  blueColor04,
  border25,
  greyColor27,
  greyColor6,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ProductImageListDND from "../../Catalog/ProductImageListDND";
import ModalCard from "../../../components/ModalCard";
import ProductPicRecommendations from "../../Catalog/PopupModals/InfoPopups/ProductPicRecommendations";

function AddProductImage({
  productImages,
  setProductImages,
  productImgUpdate,
  setProductImgUpdate,
  onProductImageUpdate,
  onValueChange,
  onDeleteProductPhoto,
}) {
  const [showProductPicInfo, setShowProductPicInfo] = useState(false);

  // Show Product Image Recommandations Title
  const openProductPicInfo = () => {
    setShowProductPicInfo(true);
  };

  return (
    <Box sx={styles.card}>
      <Typography sx={styles.title}>Product Images</Typography>
      <Typography
        component="h6"
        fontSize={{ xs: 12, md: 12 }}
        color={greyColor6}
        fontWeight={400}
        lineHeight="20px"
        display="inline"
      >
        Upload Product Images (Upto Max 4){" "}
        <Typography variant="body1" sx={{ display: "inline", color: "#f00" }}>
          *
        </Typography>
      </Typography>

      <Box>
        <ProductImageListDND
          productImgDataList={productImgUpdate}
          setProductImgDataList={setProductImgUpdate}
          data={productImages}
          productPhotoKeys={[
            { id: 1, key: "productImgOne" },
            { id: 2, key: "productImgTwo" },
            { id: 3, key: "productImgThree" },
            { id: 4, key: "productImgFour" },
          ]}
          onProductImageUpdate={onProductImageUpdate}
          onValueChange={onValueChange}
          onDeleteProductPhoto={onDeleteProductPhoto}
        />

        {productImages?.errors?.productImg ? (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={12}
            sx={{
              textAlign: "start !important",
              color: "red",
              m: { xs: "40px 0 10px", md: "-10px 0 10px" },
            }}
          >
            {productImages?.errors?.productImg}
          </Typography>
        ) : null}
      </Box>

      <Box
        sx={{ mt: { xs: "10px", md: "-10px" } }}
        onClick={openProductPicInfo}
      >
        <Typography
          variant="body1"
          fontSize={{ xs: 12, md: 12.5 }}
          fontWeight={400}
          color={greyColor27}
          display="inline"
        >
          Refer to the picture recommendations and samples{" "}
          <Typography
            variant="body1"
            fontSize={{ xs: 13, md: 13.5 }}
            fontWeight={500}
            color={{xs: mobilePrimary,md:blueColor04}}
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            display="inline"
          >
            Here
          </Typography>
        </Typography>
      </Box>

      <ModalCard
        open={showProductPicInfo}
        handleClose={() => {
          setShowProductPicInfo(false);
        }}
        width="70%"
      >
        <ProductPicRecommendations />
      </ModalCard>
    </Box>
  );
}

export default AddProductImage;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    border: border25,
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: "15px 15px",
    mb: 2,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    mb: "10px",
    color: blackShade19,
  },
};
