import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import VerticalTimeline from "../VerticalTimeline";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  blackColor,
  blackShade05,
  blackShade159,
  blackShade22,
  blueColor03,
  greyColor50,
  greyColor6,
  primaryColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import {
  clearDeliveryStatusInfo,
  getDelhiveryB2BStatus,
  getDelhiveryStatus,
  getTelyportStatus,
} from "../../../reducers/orders/ordersSlice";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import { addThousandsSeparator, getOrderType } from "../../../utils/helper";
import { getCartInfoApi } from "../../../service/api_calls/orders/OrdersApi";
import VerticalB2BTimeline from "../VerticalB2BTimeline";

export default function OrderTrackingPopupContent({
  orderStatus,
  orderData,
  getOndcBapNameBySubscriberId,
}) {
  const dispatch = useDispatch();
  const [deliveryType, setDeliveryType] = useState("");
  const [cartInfo, setCartInfo] = useState(null);

  const orderType = getOrderType(orderData);

  const {
    dehiveryStatusData,
    dehiveryB2BStatusData,
    telyportStatusData,
    statusLoader,
  } = useSelector((state) => state.businessOrders);

  const fetchCartInfo = async (orderData) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    const response = await getCartInfoApi({
      cartId: orderData?.cartId,
      businessId,
    });

    if (response?.cart) {
      setCartInfo(response?.cart || null);
    }
  };

  useEffect(() => {
    if (orderData) {
      fetchCartInfo(orderData);
    }

    return () => {
      dispatch(clearDeliveryStatusInfo());
    };
  }, [orderData]);

  useEffect(() => {
    if (cartInfo) {
      console.log("cartInfo", cartInfo);
      const orderDeliveryType =
        cartInfo?.deliveryType?.type?.toLowerCase() || "";

      if (
        orderDeliveryType == "delhivery"
        // || orderDeliveryType == "delhiveryb2b"
      ) {
        if (cartInfo?.shipmentInfo?.carrierTrackId) {
          dispatch(getDelhiveryStatus(cartInfo?.shipmentInfo?.carrierTrackId));
        }
      }

      if (orderDeliveryType == "delhiveryb2b") {
        if (cartInfo?.shipmentInfo?.carrierTrackId) {
          dispatch(
            getDelhiveryB2BStatus(cartInfo?.shipmentInfo?.carrierTrackId)
          );
        }
      }

      if (orderDeliveryType.toLowerCase() == "telyport") {
        if (cartInfo?.shipmentInfo?.carrierTrackId) {
          dispatch(getTelyportStatus(cartInfo?.shipmentInfo?.carrierTrackId));
        }
      }

      setDeliveryType(cartInfo?.deliveryType?.type || "");
    }
  }, [cartInfo]);

  return (
    <Box sx={styles.card}>
      <Box sx={styles.cardHeader}>
        <Typography
          variant="h5"
          fontSize={15}
          fontWeight={500}
          color={whiteColor}
          mb="6px"
        >
          Order Id : #{orderData?.orderId}
        </Typography>

        {orderType?.orderType == "ondcOrder" && (
          <>
            <Typography
              variant="h5"
              fontSize={15}
              fontWeight={500}
              color={whiteColor}
              mb="4px"
            >
              ONDC Order Id : #{orderData?.ondcOrderId}
            </Typography>

            <Typography
              variant="h5"
              fontSize={15}
              fontWeight={500}
              color={whiteColor}
              mb="14px"
            >
              ONDC Buyer App : {getOndcBapNameBySubscriberId(orderData?.ondcBapId)}
            </Typography>
          </>
        )}

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography
              variant="h6"
              fontSize={13}
              fontWeight={500}
              color={whiteColor}
              lineHeight="22px"
            >
              Tracking Id :{" "}
              {deliveryType?.toLowerCase() == "delhivery" ||
              deliveryType?.toLowerCase() == "delhiveryb2b"
                ? dehiveryStatusData?.ShipmentData
                  ? dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.AWB
                  : cartInfo?.shipmentInfo?.carrierTrackId
                : null}
              {deliveryType == "business" || deliveryType == "Business"
                ? cartInfo?.shipmentInfo?.carrierTrackId || "Not Available"
                : null}
              {deliveryType?.toLowerCase() == "telyport"
                ? cartInfo?.shipmentInfo?.carrierTrackId || "Not Available"
                : null}
            </Typography>

            <Typography
              variant="body1"
              fontSize={12}
              fontWeight={400}
              color={whiteColor}
              lineHeight="20px"
            >
              {cartInfo?.totalQuantity} Items | Rs{" "}
              {cartInfo?.orderTotalCost
                ? addThousandsSeparator(cartInfo?.orderTotalCost)
                : 0}
            </Typography>
          </Box>

          <Box sx={styles.statusTag}>
            {dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status?.Status ||
            dehiveryB2BStatusData?.data?.status ||
            deliveryType == "business" ||
            deliveryType == "Business" ? (
              <Typography
                variant="body1"
                fontSize={12}
                fontWeight={500}
                color={blueColor03}
                lineHeight="20px"
              >
                {deliveryType?.toLowerCase() == "delhivery"
                  ? dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.Status
                  : null}

                {deliveryType?.toLowerCase() == "delhiveryb2b"
                  ? dehiveryB2BStatusData?.data?.status?.replace(/_/g, " ")
                  : null}

                {deliveryType == "business" || deliveryType == "Business"
                  ? orderStatus
                  : null}

                {deliveryType?.toLowerCase() == "telyport"
                  ? telyportStatusData?.data?.orderStatus
                  : null}
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{ my: 2, fontWeight: 400, borderTop: `1px dashed #ffffff4a` }}
        ></Box>
        <Box>
          <Box display="flex" alignItems="center" gap="10px" mb={1}>
            <RadioButtonUncheckedOutlinedIcon
              sx={{ color: whiteColor, fontSize: "20px" }}
            />
            <Typography
              variant="h6"
              color={whiteColor}
              fontSize={12}
              fontWeight={400}
            >
              {cartInfo?.businessAddress?.addressLine1}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" gap="10px">
            <FmdGoodOutlinedIcon sx={{ color: whiteColor, fontSize: "20px" }} />

            <Typography
              variant="h6"
              color={whiteColor}
              fontSize={12}
              fontWeight={400}
            >
              {cartInfo?.customerAddress?.addressLine1}
            </Typography>
          </Box>
        </Box>
      </Box>

      {statusLoader && (
        <Box height="100px">
          <ThreeDotLoader />
        </Box>
      )}

      {(cartInfo?.shipmentInfo == null || dehiveryStatusData?.Error) && (
        <Box
          sx={{ background: whiteColor, p: 3 }}
          justifyContent="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box sx={styles.iconContainer}>
            <LocalShippingOutlinedIcon sx={{ fontSize: "24px" }} />{" "}
          </Box>

          <Typography color={blackColor} fontWeight={500}>
            {dehiveryStatusData?.Error
              ? "Tracking information not available for this order"
              : "Tracking Information not available yet"}
          </Typography>
        </Box>
      )}

      {cartInfo?.shipmentInfo != null &&
      deliveryType == "delhivery" &&
      dehiveryStatusData?.ShipmentData ? (
        <Box
          sx={{
            background: whiteColor,
            height: "400px",
            overflowY: "auto",
            p: "15px",
          }}
        >
          <Typography
            variant="h4"
            fontSize={17}
            fontWeight={600}
            lineHeight="38px"
            color={blackShade05}
          >
            History
          </Typography>

          <VerticalTimeline
            data={dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Scans}
          />

          {dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status?.Status ==
          "Delivered" ? (
            <Box>
              <Divider />
              <Box my={1}>
                <Typography variant="body1" sx={styles.infoText}>
                  {
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.Status
                  }{" "}
                  on{" "}
                  {moment(
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.StatusDateTime
                  ).format("MMM DD, YYYY")}
                </Typography>

                <Typography variant="body1" sx={styles.infoText}>
                  {
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.Instructions
                  }
                </Typography>

                <Typography variant="body1" sx={styles.infoText}>
                  {
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.StatusLocation
                  }
                </Typography>
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : null}

      {/* deliveryType: {JSON.stringify(deliveryType)} */}
      {/* {JSON.stringify(dehiveryB2BStatusData?.data?.wbns)} */}
      {cartInfo?.shipmentInfo != null &&
      deliveryType?.toLowerCase() == "delhiveryb2b" &&
      dehiveryB2BStatusData?.data ? (
        <Box
          sx={{
            background: whiteColor,
            height: "200px",
            overflowY: "auto",
            p: "15px",
          }}
        >
          <Typography
            variant="h4"
            fontSize={17}
            fontWeight={600}
            lineHeight="38px"
            color={blackShade05}
          >
            Tracking details
          </Typography>

          <VerticalB2BTimeline data={dehiveryB2BStatusData?.data?.wbns} />

          {/* {dehiveryB2BStatusData?.data?.status == "DELIVERED" ? (
            <Box>
              <Divider />
              <Box my={1}>
                <Typography variant="body1" sx={styles.infoText}>
                  {
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.Status
                  }{" "}
                  on{" "}
                  {moment(
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.StatusDateTime
                  ).format("MMM DD, YYYY")}
                </Typography>

                <Typography variant="body1" sx={styles.infoText}>
                  {
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.Instructions
                  }
                </Typography>

                <Typography variant="body1" sx={styles.infoText}>
                  {
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.StatusLocation
                  }
                </Typography>
              </Box>
            </Box>
          ) : null} */}
        </Box>
      ) : null}

      {cartInfo?.shipmentInfo != null &&
      (deliveryType == "business" || deliveryType == "Business") ? (
        <Box
          sx={{
            background: whiteColor,
            p: 2,
            minHeight: "75px",
            overflowY: "auto",
          }}
        >
          {cartInfo?.shipmentInfo?.carrierName ? (
            <Box mb={1}>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={blueColor03}
                display="inline"
              >
                Carrier Name :{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={blueColor03}
                display="inline"
              >
                {cartInfo?.shipmentInfo?.carrierName}
              </Typography>
            </Box>
          ) : null}

          {cartInfo?.shipmentInfo?.carrierURL ? (
            <Box mb={1}>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={blueColor03}
                display="inline"
              >
                Carrier Tracking :{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={blueColor03}
                display="inline"
              >
                {cartInfo?.shipmentInfo?.carrierURL}
              </Typography>
            </Box>
          ) : null}

          {cartInfo?.shipmentInfo?.deliveryMode ? (
            <Box mb={1}>
              <Typography
                fontSize={12}
                fontWeight={600}
                color={greyColor6}
                display="inline"
              >
                Delivery Mode :{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={blueColor03}
                display="inline"
              >
                {cartInfo?.shipmentInfo?.deliveryMode}
              </Typography>
            </Box>
          ) : null}

          {cartInfo?.shipmentInfo?.deliveryPersonName ? (
            <Box mb={1}>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={blueColor03}
                display="inline"
              >
                Delivery Person Name :{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={blueColor03}
                display="inline"
              >
                {cartInfo?.shipmentInfo?.deliveryPersonName}
              </Typography>
            </Box>
          ) : null}

          {cartInfo?.shipmentInfo?.deliveryPersonPhone ? (
            <Box>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={blueColor03}
                display="inline"
              >
                Delivery Person Phone number :{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={blueColor03}
                display="inline"
              >
                {cartInfo?.shipmentInfo?.deliveryPersonPhone}
              </Typography>
            </Box>
          ) : null}
        </Box>
      ) : null}

      {cartInfo?.shipmentInfo != null &&
      deliveryType?.toLowerCase() == "telyport" ? (
        <Box
          sx={{
            background: whiteColor,
            p: 2,
            minHeight: "75px",
            overflowY: "auto",
          }}
        >
          {telyportStatusData?.data?.orderStatus ? (
            <>
              <Box mb={1}>
                <Typography
                  fontSize={14.5}
                  fontWeight={600}
                  color={blueColor03}
                  display="inline"
                >
                  Order Status :{" "}
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  color={blueColor03}
                  display="inline"
                >
                  {telyportStatusData?.data?.orderStatus}
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography
                  fontSize={14.5}
                  fontWeight={600}
                  color={blueColor03}
                  display="inline"
                >
                  Tracking Number :{" "}
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  color={blueColor03}
                  display="inline"
                >
                  {telyportStatusData?.data?.trackableID}
                </Typography>
              </Box>

              <Box
                mb={1}
                onClick={() => {
                  if (telyportStatusData?.data?.trackingLink) {
                    window.open(
                      telyportStatusData?.data?.trackingLink,
                      "_blank"
                    );
                  }
                }}
              >
                <Typography
                  fontSize={14.5}
                  fontWeight={600}
                  color={blueColor03}
                  display="inline"
                >
                  Tracking Link :{" "}
                </Typography>
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  color={primaryColor}
                  display="inline"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  {telyportStatusData?.data?.trackingLink}
                </Typography>
              </Box>

              <Box mb={1}>
                <Typography
                  fontSize={14.5}
                  fontWeight={600}
                  color={blueColor03}
                  display="inline"
                >
                  Agent Name :{" "}
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  color={blueColor03}
                  display="inline"
                >
                  {telyportStatusData?.data?.agentDetails?.agent_info?.name}
                </Typography>
              </Box>

              <Box mb={1}>
                <Typography
                  fontSize={14.5}
                  fontWeight={600}
                  color={blueColor03}
                  display="inline"
                >
                  Phone Number :{" "}
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  color={blueColor03}
                  display="inline"
                >
                  +91{" "}
                  {telyportStatusData?.data?.agentDetails?.agent_info?.phone}
                </Typography>
              </Box>
            </>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
}

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "12px",
    overflow: "hidden",
    maxHeight: { md: "95vh", xs: "70vh" },
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
      // height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blackShade159,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },

  cardHeader: {
    backgroundColor: { md: blackShade22, xs: greyColor50 },
    p: "15px",
  },

  statusTag: {
    backgroundColor: whiteColor,
    borderRadius: "5px",
    p: "0px 14px",
  },

  iconContainer: {
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    background: "rgb(5 60 81 / 7%)",
    padding: "12px",
    mb: "10px",
  },

  infoText: {
    fontSize: 12,
    fontWeight: 400,
    color: greyColor6,
    lineHeight: "20px",
  },
};
