import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveBusinessInfo } from "../../reducers/businessPreview/businessPreviewSlice";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import DashboardMenu from "../Dashboard/DashboardMenu";
import DashboardHeader from "../Dashboard/DashboardHeader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getReviewProductDetailsAPI,
  getUnitOfMeasurementApi,
  getViewProductDetailsAPI,
  uploadProductApi,
} from "../../service/api_calls/catalog/CatalogAPI";
import BusinessInfoCard from "../../components/Cards/BusinessInfoCard";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import {
  BGColor02,
  blackColor,
  primaryColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { ONDC_DEFAULT_DATA } from "../../utils/data/defaultFormData";
import ONDCInfoForm from "./Form/ONDCInfoForm";
import ONDCDetailsPreview from "../ProductPreview/ONDCDetailsPreview";
import ActionButtons from "../../components/Buttons/ActionButtons";
import {
  getTimeToShipUnitValue,
  getTimeToShipValue,
  getUpdateProductONDCPostAPIData,
} from "../../utils/postDataHelpers/CatalogHelper";
import ModalCard from "../../components/ModalCard";
import moment from "moment";
import { PRODUCT_CODE_UNIT_LIST, UNITS_OF_MEASURE } from "../../utils/data";
import { getProductSubCategoryOneAPI } from "../../service/api_calls/AddProduct/AddProductAPI";
import { isStringValidDateFormat } from "../../utils/helper";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import { stickyHeader } from "../../utils/styles";
import BreadcrumbSection from "../../components/Breadcrumb/BreadcrumbSection";

const AddONDCDetails = () => {
  const businessName = sessionStorage.getItem("costboWEBURL");

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  const [productDetails, setProductDetails] = useState(null);
  const [productDetailsLoader, setProductDetailsLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState("ondc");

  const [unitOfMeasurementData, setUnitOfMeasurementData] = useState([]);

  const [ondcInfo, setOndcInfo] = useState(ONDC_DEFAULT_DATA);
  const [ondcUIControl, setOndcUIControl] = useState({
    timeToShipUnit: {
      label: "Minutes",
      unit: "M",
    },
    isFoodRelatedItems: "none",
    isPackaged: "none",
    isPrePackaged: "none",
  });

  // ONDC subcat data
  const [ondcProductSubCatList, setOndcProductSubCatList] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [addProductLoader, setAddProductLoader] = useState(false);

  // Fetching product details
  const getProductDetails = async () => {
    setProductDetailsLoader(true);
    const productId = location?.state?.productId;
    const isRejectedProduct = location?.state?.isRejectedProduct || false;

    const businessId = sessionStorage.getItem("selectedBusinessId");

    const data = await getViewProductDetailsAPI(productId);
    if (data?.product) {
      const productMainCat = data?.product?.productMainCategory?.filter(
        (item) => item?.status == "active"
      );

      if (productMainCat?.length > 0) {
        const ondcSubCatData = await getProductSubCategoryOneAPI(
          productMainCat?.[0]?.ondcCode
        );
        setOndcProductSubCatList(ondcSubCatData?.details || []);
      }

      setProductDetails(data?.product);
      setProductDetailsLoader(false);
    }

    // if (isRejectedProduct) {
    //   const data = await getReviewProductDetailsAPI({ businessId, productId });
    //   if (data?.details) {
    //     setProductDetails(data?.details);
    //     setProductDetailsLoader(false);
    //   }
    // } else {
    //   const data = await getViewProductDetailsAPI(productId);
    //   if (data?.product) {
    //     setProductDetails(data?.product);
    //     setProductDetailsLoader(false);
    //   }
    // }
  };

  // To handle ONDC Info Next Click
  const handleOndcInfoSubmit = async () => {
    let errors = null;

    if (ondcInfo?.ondcEnabled === "yes") {
      if (
        ondcInfo?.ondcProductCategory?.name == "Select ONDC Product Category"
      ) {
        errors = {
          ...errors,
          ondcProductCategory: "Select ONDC product category",
        };
      }

      if (!ondcInfo?.ondcProductSubCategory?.productSubCategory1) {
        errors = {
          ...errors,
          ondcProductSubCategory: "Select ONDC product sub category",
        };
      }

      if (!ondcInfo?.ondc?.timeToShip || ondcInfo?.ondc?.timeToShip == "0") {
        errors = { ...errors, timeToShip: "Enter time to ship" };
      }

      if (ondcInfo?.ondc?.available_on_cod === "none") {
        errors = { ...errors, available_on_cod: "choose available on COD" };
      }

      // if (!ondcInfo?.ondc?.discount) {
      //   errors = { ...errors, discount: "Enter customer discount" };
      // }

      // const isUnitValid = UNITS_OF_MEASURE?.filter(
      //   (item) => item?.label === ondcInfo?.productDisplayUnitOfMeasure?.unit
      // );

      // if (isUnitValid?.length === 0) {
      //   errors = { ...errors, productDisplayUnitOfMeasure1: "Select unit" };
      // }

      const isUnitValid = unitOfMeasurementData?.filter(
        (item) => item?.value === ondcInfo?.productDisplayUnitOfMeasure?.unit
      );

      if (isUnitValid?.length === 0) {
        errors = { ...errors, productDisplayUnitOfMeasure1: "Select unit" };
      }

      if (!ondcInfo?.productDisplayUnitOfMeasure?.unit) {
        errors = { ...errors, productDisplayUnitOfMeasure1: "Select unit" };
      }

      if (
        !ondcInfo?.productDisplayUnitOfMeasure?.value ||
        ondcInfo?.productDisplayUnitOfMeasure?.value == "0"
      ) {
        errors = { ...errors, productDisplayUnitOfMeasure: "Enter value" };
      }

      if (!ondcInfo?.weight || ondcInfo?.weight == 0) {
        errors = { ...errors, weight: true };
      }

      if (!ondcInfo?.netProductWeight || ondcInfo?.netProductWeight == "0") {
        errors = { ...errors, netProductWeight: "Enter net product weight" };
      }

      if (
        ondcInfo?.netProductWeight > "0" &&
        Number(ondcInfo?.netProductWeight) < Number(ondcInfo?.weight)
      ) {
        errors = {
          ...errors,
          netProductWeight:
            "Net product weight should be greater than product weight",
        };
      }

      const isKeyValid = PRODUCT_CODE_UNIT_LIST?.filter(
        (item) => item?.label === ondcInfo?.ondc?.productCode?.key
      );

      if (isKeyValid?.length === 0) {
        errors = { ...errors, productCode1: "Select code" };
      }

      if (!ondcInfo?.ondc?.productCode?.value) {
        errors = { ...errors, productCode: "Enter product code" };
      }

      if (
        ondcInfo?.ondc?.productCode?.key === "EAN" &&
        ondcInfo?.ondc?.productCode?.value?.length != 13
      ) {
        errors = {
          ...errors,
          productCode: "Enter a valid 13 digit EAN code",
        };
      }
      if (
        ondcInfo?.ondc?.productCode?.key === "ISBN" &&
        ondcInfo?.ondc?.productCode?.value?.length != 13
      ) {
        errors = {
          ...errors,
          productCode: "Enter a valid 13 digit ISBN code",
        };
      }
      if (
        ondcInfo?.ondc?.productCode?.key === "GTIN" &&
        ondcInfo?.ondc?.productCode?.value?.length != 12
      ) {
        errors = {
          ...errors,
          productCode: "Enter a valid 12 digit GTIN code",
        };
      }
      if (
        ondcInfo?.ondc?.productCode?.key === "HSN" &&
        ondcInfo?.ondc?.productCode?.value?.length != 8
      ) {
        errors = {
          ...errors,
          productCode: "Enter a valid 8 digit HSN code",
        };
      }
      if (
        ondcInfo?.ondc?.productCode?.key === "OTHERS" &&
        ondcInfo?.ondc?.productCode?.value?.length != 13
      ) {
        errors = {
          ...errors,
          productCode: "Enter a valid 13 digit code",
        };
      }

      if (ondcInfo?.ondc?.fragile === "none") {
        errors = { ...errors, fragile: "Choose fragile" };
      }

      if (ondcInfo?.ondc?.cancelTerms?.cancellable === "none") {
        errors = { ...errors, cancellable: "Choose cancellable" };
      }

      if (ondcInfo?.ondc?.cancelTerms?.cancellable === "yes") {
        if (ondcInfo?.ondc?.cancelTerms?.refundEligible === "none") {
          errors = { ...errors, refundEligible: "Choose refund eligible" };
        }

        // if (!ondcInfo?.ondc?.cancelTerms?.cancellationFeeAmount) {
        //   errors = {
        //     ...errors,
        //     cancellationFeeAmount: "Enter cancellation fee amount",
        //   };
        // }

        if (!ondcInfo?.ondc?.cancelTerms?.cancellationFeePercentage) {
          errors = {
            ...errors,
            cancellationFeePercentage: "Enter cancellation fee percentage",
          };
        }
      }

      if (ondcInfo?.ondc?.returnTerms?.returnable === "none") {
        errors = { ...errors, returnable: "Choose returnable" };
      }

      if (
        !ondcInfo?.ondc?.returnTerms?.returnPeriod ||
        ondcInfo?.ondc?.returnTerms?.returnPeriod == "0"
      ) {
        errors = { ...errors, returnPeriod: "Enter return period" };
      }

      if (ondcInfo?.ondc?.returnTerms?.seller_pickup_return === "none") {
        errors = { ...errors, seller_pickup_return: "Choose seller pickup" };
      }

      if (ondcUIControl?.isFoodRelatedItems === "none") {
        errors = {
          ...errors,
          isFoodRelatedItems: "Choose Food & Related Items",
        };
      }

      if (ondcUIControl?.isFoodRelatedItems === "yes") {
        if (!ondcInfo?.ondc?.shelfLife || ondcInfo?.ondc?.shelfLife == "0") {
          errors = { ...errors, shelfLife: "Enter shelfLife" };
        }

        if (
          !ondcInfo?.ondc?.fssai?.[0]?.number ||
          ondcInfo?.ondc?.fssai?.[0]?.number?.length != 14 ||
          isNaN(ondcInfo?.ondc?.fssai?.[0]?.number)
        ) {
          errors = { ...errors, fssai: "Enter a valid 14 digit FSSAI number" };
        }

        // if (ondcInfo?.ondc?.fssai?.[0]?.validTill === "") {
        //   errors = { ...errors, validTill: "Enter validTill" };
        // }
      }

      if (ondcUIControl?.isPackaged === "none") {
        errors = { ...errors, isPackaged: "Choose Packaged Commodities" };
      }

      if (ondcUIControl?.isPackaged === "yes") {
        if (
          !ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.manufacturer_or_packer_name
        ) {
          errors = {
            ...errors,
            manufacturer_or_packer_name: "Enter Manufacturer/Packer Name",
          };
        }
        if (!ondcInfo?.ondc?.statutoryReqsPackagedCommodities?.mfg_license_no) {
          errors = { ...errors, mfg_license_no: "Enter License No" };
        }
        if (
          !ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.manufacturer_or_packer_address
        ) {
          errors = {
            ...errors,
            manufacturer_or_packer_address: "Enter Manufacturer/Packer Address",
          };
        }
        if (
          !ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.common_or_generic_name_of_commodity
        ) {
          errors = {
            ...errors,
            common_or_generic_name_of_commodity:
              "Enter Product Common/Generic Name",
          };
        }

        if (
          ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.month_year_of_manufacture_packing_import &&
          !isStringValidDateFormat(
            ondcInfo?.ondc?.statutoryReqsPackagedCommodities
              ?.month_year_of_manufacture_packing_import
          )
        ) {
          errors = {
            ...errors,
            month_year_of_manufacture_packing_import:
              "Please enter a valid Month, Year or N/A",
          };
        }

        if (
          ondcInfo?.ondc?.statutoryReqsPackagedCommodities?.expiry_date &&
          !isStringValidDateFormat(
            ondcInfo?.ondc?.statutoryReqsPackagedCommodities?.expiry_date
          )
        ) {
          errors = {
            ...errors,
            expiry_date: "Please enter a valid Month, Year or N/A",
          };
        }

        if (
          !ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.net_quantity_or_measure_of_commodity_in_pkg
        ) {
          errors = {
            ...errors,
            net_quantity_or_measure_of_commodity_in_pkg: "Enter Net Quantity",
          };
        }
        if (
          !ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.multiple_products_name_number_or_qty
        ) {
          errors = {
            ...errors,
            multiple_products_name_number_or_qty: "Enter Multiple Products",
          };
        }
      }

      if (ondcUIControl?.isPrePackaged === "none") {
        errors = { ...errors, isPrePackaged: "Choose Packaged Commodities" };
      }

      if (ondcUIControl?.isPrePackaged === "yes") {
        if (
          !ondcInfo?.ondc?.statutoryReqsPrepackagedFood
            ?.brand_owner_FSSAI_license_no ||
          ondcInfo?.ondc?.statutoryReqsPrepackagedFood
            ?.brand_owner_FSSAI_license_no?.length != 14 ||
          isNaN(
            ondcInfo?.ondc?.statutoryReqsPrepackagedFood
              ?.brand_owner_FSSAI_license_no
          )
        ) {
          errors = {
            ...errors,
            brand_owner_FSSAI_license_no: "Enter a valid 14 digit FSSAI number",
          };
        }
        if (
          !ondcInfo?.ondc?.statutoryReqsPrepackagedFood
            ?.other_FSSAI_license_no ||
          ondcInfo?.ondc?.statutoryReqsPrepackagedFood?.other_FSSAI_license_no
            ?.length != 14 ||
          isNaN(
            ondcInfo?.ondc?.statutoryReqsPrepackagedFood?.other_FSSAI_license_no
          )
        ) {
          errors = {
            ...errors,
            other_FSSAI_license_no: "Enter a valid 14 digit FSSAI number",
          };
        }
        if (
          !ondcInfo?.ondc?.statutoryReqsPrepackagedFood
            ?.importer_FSSAI_license_no ||
          ondcInfo?.ondc?.statutoryReqsPrepackagedFood
            ?.importer_FSSAI_license_no?.length != 14 ||
          isNaN(
            ondcInfo?.ondc?.statutoryReqsPrepackagedFood
              ?.importer_FSSAI_license_no
          )
        ) {
          errors = {
            ...errors,
            importer_FSSAI_license_no: "Enter a valid 14 digit FSSAI number",
          };
        }
        if (!ondcInfo?.ondc?.statutoryReqsPrepackagedFood?.ingredients_info) {
          errors = {
            ...errors,
            ingredients_info: "Enter Ingredients Info",
          };
        }
        if (!ondcInfo?.ondc?.statutoryReqsPrepackagedFood?.nutritional_info) {
          errors = {
            ...errors,
            nutritional_info: "Enter Nutritional Info ",
          };
        }
        if (!ondcInfo?.ondc?.statutoryReqsPrepackagedFood?.additives_info) {
          errors = {
            ...errors,
            additives_info: "Enter Additives Info",
          };
        }
      }

      console.log("ONDC_INFO__:", ondcInfo);

      if (errors) {
        setOndcInfo((prevState) => ({
          ...prevState,
          errors,
        }));
      } else {
        setOndcInfo((prevState) => ({
          ...prevState,
          errors: null,
        }));
        setCurrentPage("preview");
      }
    } else {
      // Update or Add product
      setOndcInfo((prevState) => ({
        ...prevState,
        errors: null,
      }));
      setCurrentPage("preview");
    }
  };

  const goBack = () => {
    if (currentPage === "preview") {
      setCurrentPage("ondc");
    }
  };

  const onCancelClick = () => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/catalog`);
  };

  // Update ONDC Data
  const updateDetails = async () => {
    console.log("ONDC_INFO___", ondcInfo);

    const businessId = sessionStorage.getItem("selectedBusinessId");

    const postData = getUpdateProductONDCPostAPIData({
      businessId,
      businessInfo: activeBusinessInfo,
      productDetails,
      ondcInfo,
      ondcUIControl,
    });

    console.log("UpdateONDCPostData:", postData);

    setAddProductLoader(true);

    // Updating product details
    const response = await uploadProductApi(postData);

    setTimeout(() => {
      if (response?.success) {
        setAddProductLoader(false);
        setShowAlert(true);
      } else {
        setAddProductLoader(false);
        setShowAlert(false);
      }
    }, 1500);
  };

  // Get Unit of measurement
  const getUnitsData = async () => {
    const response = await getUnitOfMeasurementApi();
    setUnitOfMeasurementData(() => response?.details || []);
    console.log("getUnitsData:", response);
  };

  useEffect(() => {
    if (productDetails) {
      const ondcData = productDetails?.ondc;

      if (productDetails?.ondc) {
        const unitSymbol =
          ondcData?.timeToShip?.charAt(ondcData?.timeToShip?.length - 1) || "";

        const isPackagedData = ondcData?.statutoryReqsPackagedCommodities;
        const isPrePackagedData = ondcData?.statutoryReqsPrepackagedFood;

        setOndcUIControl({
          timeToShipUnit: getTimeToShipUnitValue(unitSymbol),
          isFoodRelatedItems:
            ondcData?.shelfLife || ondcData?.fssai?.[0]?.number
              ? "yes"
              : "no",
          isPackaged:
            isPackagedData?.manufacturer_or_packer_name ||
            isPackagedData?.manufacturer_or_packer_address ||
            isPackagedData?.common_or_generic_name_of_commodity ||
            isPackagedData?.mfg_license_no ||
            isPackagedData?.net_quantity_or_measure_of_commodity_in_pkg
              ? "yes"
              : "no",
          isPrePackaged:
            isPrePackagedData?.ingredients_info ||
            isPrePackagedData?.nutritional_info ||
            isPrePackagedData?.additives_info ||
            isPrePackagedData?.brand_owner_FSSAI_license_no ||
            isPrePackagedData?.other_FSSAI_license_no ||
            isPrePackagedData?.importer_FSSAI_license_no
              ? "yes"
              : "no",
        });

        // const importMfnDate =
        // ondcData?.statutoryReqsPackagedCommodities
        //   ?.month_year_of_manufacture_packing_import;

        // const parsedDate = moment(importMfnDate, "MM/YYYY");

        // const mfnImportDateMilliseconds = parsedDate.valueOf();

        const activeMainCat =
          productDetails?.productMainCategory?.filter(
            (item) => item?.status == "active"
          ) || [];

        const activeSubCat =
          productDetails?.productSubCategory?.filter(
            (item) => item?.status == "active"
          ) || [];

        setOndcInfo({
          ...ONDC_DEFAULT_DATA,

          weight: productDetails?.netProductWeight || 0,
          productDisplayUnitOfMeasure: {
            unit:
              productDetails?.productDisplayUnitOfMeasure?.unit === "gms"
                ? "gram"
                : productDetails?.productDisplayUnitOfMeasure?.unit || "",
            value: productDetails?.productDisplayUnitOfMeasure?.value || "",
          },

          netProductWeight: productDetails?.measurementInfo?.weight || 0,
          ondcEnabled: productDetails?.ondcEnabled || "no",

          ondcProductCategory: {
            name:
              activeMainCat?.[0]?.ondcValue || "Select ONDC Product Category",
            code: activeMainCat?.[0]?.ondcCode || "",
            status: "",
          },
          ondcSubCatList: ondcProductSubCatList || [],
          ondcProductSubCategory: {
            productSubCategory1: activeSubCat?.[0]?.ondcSubValue,
            scCode1: activeSubCat?.[0]?.ondcSubCode,
          },

          ondc: {
            available_on_cod: ondcData?.available_on_cod,
            discount: ondcData?.discount || "",
            fragile: ondcData?.fragile == true ? "yes" : "no",
            shelfLife: ondcData?.shelfLife || "",
            // timeToShip: ondcData?.timeToShip
            //   ? ondcData?.timeToShip?.slice(2, -1)
            //   : "",
            timeToShip: getTimeToShipValue(unitSymbol, ondcData?.timeToShip),

            cancelTerms: {
              cancellable: ondcData?.cancelTerms?.cancellable || "none",
              // cancellationFeeAmount:
              //   ondcData?.cancelTerms?.cancellationFeeAmount || "",
              cancellationFeeAmount: null,
              cancellationFeePercentage:
                ondcData?.cancelTerms?.cancellationFeePercentage || "",
              refundEligible: ondcData?.cancelTerms?.refundEligible || "none",
            },

            veg_nonveg: {
              veg: ondcData?.veg_nonveg?.veg || "no",
              nonVeg: ondcData?.veg_nonveg?.nonVeg || "no",
              egg: ondcData?.veg_nonveg?.egg || "no",
            },

            fssai: [
              {
                number: ondcData?.fssai?.[0]?.number || "",
                // validTill: ondcData?.fssai?.[0]?.validTill || "",
                validTill: null,
              },
            ],

            productCode: {
              key: ondcData?.productCode?.key || "EAN",
              value: ondcData?.productCode?.value || "",
            },

            returnTerms: {
              returnable: ondcData?.returnTerms?.returnable || "none",
              returnPeriod: ondcData?.returnTerms?.returnPeriod
                ? ondcData?.returnTerms?.returnPeriod?.slice(1, -1)
                : "",
              seller_pickup_return:
                ondcData?.returnTerms?.seller_pickup_return || "none",
            },

            statutoryReqsPackagedCommodities: {
              manufacturer_or_packer_name:
                ondcData?.statutoryReqsPackagedCommodities
                  ?.manufacturer_or_packer_name || "",
              manufacturer_or_packer_address:
                ondcData?.statutoryReqsPackagedCommodities
                  ?.manufacturer_or_packer_address || "",
              common_or_generic_name_of_commodity:
                ondcData?.statutoryReqsPackagedCommodities
                  ?.common_or_generic_name_of_commodity || "",
              mfg_license_no:
                ondcData?.statutoryReqsPackagedCommodities?.mfg_license_no ||
                "",

              expiry_date:
                ondcData?.statutoryReqsPackagedCommodities?.expiry_date || "",

              month_year_of_manufacture_packing_import:
                ondcData?.statutoryReqsPackagedCommodities
                  ?.month_year_of_manufacture_packing_import || "",

              net_quantity_or_measure_of_commodity_in_pkg:
                ondcData?.statutoryReqsPackagedCommodities
                  ?.net_quantity_or_measure_of_commodity_in_pkg || "",
              multiple_products_name_number_or_qty:
                ondcData?.statutoryReqsPackagedCommodities
                  ?.multiple_products_name_number_or_qty || "",
            },

            statutoryReqsPrepackagedFood: {
              ingredients_info:
                ondcData?.statutoryReqsPrepackagedFood?.ingredients_info || "",
              nutritional_info:
                ondcData?.statutoryReqsPrepackagedFood?.nutritional_info || "",
              additives_info:
                ondcData?.statutoryReqsPrepackagedFood?.additives_info || "",
              brand_owner_FSSAI_license_no:
                ondcData?.statutoryReqsPrepackagedFood
                  ?.brand_owner_FSSAI_license_no || "",
              other_FSSAI_license_no:
                ondcData?.statutoryReqsPrepackagedFood
                  ?.other_FSSAI_license_no || "",
              importer_FSSAI_license_no:
                ondcData?.statutoryReqsPrepackagedFood
                  ?.importer_FSSAI_license_no || "",
            },
          },
        });
      }
    }
    return () => {};
  }, [productDetails]);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      getProductDetails();

      if (unitOfMeasurementData?.length === 0) {
        getUnitsData();
      }
    }
  }, []);

  return (
    <Box sx={styles.container}>
      <DashboardLayout activeMenu="catalog">
        {!productDetailsLoader && !activeBusinessInfoLoader ? (
          <Stack gap={0}>
            <Box sx={stickyHeader}>
              {matches && <BreadcrumbSection
                links={[
                  {
                    label: "Product Catalog",
                    to: `/${businessName}/catalog`,
                  },
                  { label: "Edit ONDC Parameters", to: `#` },
                  {
                    label: productDetails?.productName,
                    to: `#`,
                  },
                ]}
              />}

              {currentPage === "ondc" && (
                <Typography
                  component="p"
                  fontSize={{ xs: 15, md: 16 }}
                  color={blackColor}
                  fontWeight={600}
                  lineHeight="20px"
                  m={{xs:"0px 0 12px 0 !important" , md:"-5px 0 12px 0 !important"}}
                >
                  {productDetails?.productName}
                </Typography>
              )}
            </Box>

            {currentPage === "ondc" && (
              <ONDCInfoForm
                data={ondcInfo}
                setData={setOndcInfo}
                ondcUIControl={ondcUIControl}
                setOndcUIControl={setOndcUIControl}
                ondcProductCategory={
                  activeBusinessInfo?.businessONDCCategories || []
                }
                gotoNext={handleOndcInfoSubmit}
                onCancelClick={onCancelClick}
                productName={productDetails?.productName}
                unitOfMeasurementData={unitOfMeasurementData}
              />
            )}

            {currentPage === "preview" && (
              <>
                <ONDCDetailsPreview
                  data={{ ...ondcInfo, ondcUIControl }}
                  ondcPreview
                />
                <Box my={{ xs: "15px", md: "10px" }}>
                  <ActionButtons
                    onBackClick={goBack}
                    onSaveClick={() => {}}
                    onNextClick={updateDetails}
                    onCancelClick={onCancelClick}
                    hideSaveBtn
                    showBackBtn
                    nextBtnText="SUBMIT"
                    backBtnText="Previous"
                  />
                </Box>
              </>
            )}
          </Stack>
        ) : (
          <ThreeDotLoader />
        )}
      </DashboardLayout>

      <ModalCard
        open={showAlert || addProductLoader}
        handleClose={() => {
          // setShowAlert(false);
        }}
        width="48%"
      >
        <Box backgroundColor={whiteColor} borderRadius="8px">
          {addProductLoader ? (
            <Box sx={{ height: "100px", pt: 2 }}>
              <ThreeDotLoader />
            </Box>
          ) : showAlert ? (
            <AlertMessagePopup
              text={
                "Product ONDC parameters updated successfully. Our team will review and approve within 24 hours.  New changes will be reflected in the product after approval.\nFor immediate approval, please contact via whatsapp - ‘8088422615’"
              }
              onClick={() => {
                const webURL = sessionStorage.getItem("costboWEBURL");
                navigate(`/${webURL}/catalog`);
              }}
            />
          ) : null}
        </Box>
      </ModalCard>
    </Box>
  );
};

export default AddONDCDetails;

const AlertMessagePopup = ({ text, onClick }) => {
  return (
    <Box sx={styles.alertCard}>
      <Typography
        variant="body2"
        fontSize={14}
        fontWeight={500}
        textAlign="center"
        lineHeight="26px"
        m="16px 0 12px "
      >
        {text}
      </Typography>
      <Button sx={styles.alertBtn} variant="outlined" onClick={onClick}>
        GO TO CATALOG
      </Button>
    </Box>
  );
};

const styles = {
  container: {
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "25px" },
    p: { xs: "0px", md: "15px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  alertCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    padding: "20px 20px 15px 20px",
    borderRadius: "8px",
  },

  alertBtn: {
    fontSize: "13px",
    fontWeight: "500",
    color: whiteColor,
    backgroundColor: primaryColor,
    padding: "6px 24px",
    marginTop: "12px",
    "&:hover": {
      color: primaryColor,
      backgroundColor: whiteColor,
    },
  },
};
