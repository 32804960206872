import { getUserDetails } from "../../../utils/authHelpers";
import { getProfileId } from "../../../utils/validationUtils";
import { getApiInstance } from "../../AppService";
import {
  ACTIVE_BUSINESS_INFO,
  BUSINESS_PRODUCTS_BY_STATUS,
  BUYER_APP_LOOKUP,
  GET_COMMERCE_DASHBOARD_INFO,
  GET_COMMERCE_INFO,
  GET_EMAIL_ACTIVATION,
  MY_BUSINESS_DATA,
  ONDC_CATEGORY,
  ONDC_PRODUCT_LIST,
  PRODUCTS_BY_BUSINESS,
  PRODUCT_CATEGORY,
  RESEND_EMAIL_ACTIVATION,
  SEARCH_BUSINESS_PRODUCTS,
} from "../../constant";

export const getActiveBusinessAPI = async (businessId) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(ACTIVE_BUSINESS_INFO + businessId);
    console.log("getActiveBusinessAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getProductsByBusinessAPI = async (businessId) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(PRODUCTS_BY_BUSINESS + businessId, {
      params: {
        business: true,
        percentageOff: false,
        highToLow: false,
        lowToHigh: false,
        pageSize: 500,
        pageNo: 1,
      },
    });
    console.log("getActiveBusinessAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const getActiveProductsByBusinessAPI = async ({
  businessId,
  pageNo,
}) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(PRODUCTS_BY_BUSINESS + businessId, {
      params: {
        business: true,
        percentageOff: false,
        highToLow: false,
        lowToHigh: false,
        pageSize: 50,
        pageNo: pageNo,
      },
    });
    console.log("getActiveBusinessAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Get business products by status
export const getBusinessProductsByStatusAPI = async ({
  businessId,
  status,
}) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });
    const response = await instance.get(BUSINESS_PRODUCTS_BY_STATUS, {
      params: {
        status,
      },
    });
    console.log("getBusinessProductsByStatusAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const searchBusinessProductsAPI = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(SEARCH_BUSINESS_PRODUCTS, {
      params: {
        key: data?.query,
        businessId: data?.businessId,
        page: 0,
      },
    });
    console.log("getActiveBusinessAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getProductCategoriesAPI = async (businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });
    const response = await instance.get(PRODUCT_CATEGORY);

    console.log("getProductCategoriesAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getMyBusinessAPI = async (businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });
    const response = await instance.get(MY_BUSINESS_DATA);

    console.log("getMyBusinessAPI", response.data);
    sessionStorage.setItem("viewBusiness", JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCommerceInfoAPI = async (businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });
    const response = await instance.get(GET_COMMERCE_INFO);

    console.log("getCommerceInfoAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const getBuyerAppLookupAPI = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(BUYER_APP_LOOKUP);

    console.log("BUYER_APP_LOOKUP", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Commerce info to check business active status: (active, new, submitted)
export const getCommerceDashboardInfoAPI = async (businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });
    const response = await instance.get(GET_COMMERCE_DASHBOARD_INFO);

    console.log("getCommerceDashboardInfoAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Email Verify Data API
export const getEmailActivationAPI = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_EMAIL_ACTIVATION);

    console.log("getEmailActivationAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Re-send Verification Email
export const resendEmailActivationAPI = async (newEmail) => {
  const profileid = getProfileId();
  const userDetails = getUserDetails();

  const postData = {
    profileid,
    phone: userDetails?.account?.phonenumber,
    newEmail: newEmail || userDetails?.account?.email,
  };

  console.log("POST_DATA___:", postData);

  try {
    const instance = getApiInstance();
    const response = await instance.post(RESEND_EMAIL_ACTIVATION, postData);

    console.log("resendEmailActivationAPI", response.data);
    return response.data;
  } catch (error) {
    console.log("RESEND_ERROR", error.response?.data?.title);
    if (error.response?.data?.title === "Login already in use")
      return "Login already in use";
  }
};

export const getONDCCategoriesAPI = async (businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });
    const response = await instance.get(ONDC_CATEGORY);

    console.log("getONDCCategoriesAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const getONDCProductsAPI = async ({ businessId, params }) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });
    const response = await instance.get(ONDC_PRODUCT_LIST, { params });

    console.log("getONDCProductsAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
