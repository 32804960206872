import { Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ErrorToolTipIcon from "../ErrorToolTipIcon";
import {
  blackShade05,
  borderTextBoxColor,
  borderTextbox,
  whiteColor,
} from "../../../../configs/styles/muiThemes";
import { allowOnlyEnglish } from "../../../../utils/helper";

const TabelCellInput = ({
  value,
  onValueChange,
  placeholder,
  rounded,
  errorMsg,
  disabled,
  maxLength,
  inputType,
  minWidth,
  regexType,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleBlur = () => {
    onValueChange(inputValue);
  };

  useEffect(() => {
    setInputValue(value);
    return () => {};
  }, [value]);

  return (
    <Box position="relative" sx={{ minWidth: minWidth || "120px" }}>
      {errorMsg && (
        <Box sx={{ position: "absolute", right: -14, top: -12, zIndex: 1 }}>
          <ErrorToolTipIcon errorMsg={errorMsg} />
        </Box>
      )}

      <Box
        sx={{
          borderBottom: borderTextbox,
          height: { xs: "20px", md: "auto" },
          borderRadius: rounded ? "6px" : "0px",
          backgroundColor: whiteColor,
        }}
      >
        <TextField
          variant="standard"
          required={true}
          fullWidth
          disabled={disabled}
          value={inputValue}
          onBlur={handleBlur}
          // onKeyDown={(e) => {
          //   if (e.key === "Backspace") {
          //     return;
          //   }
          //   onKeyPress(e);
          // }}
          onChange={({ target }) => {
            if (regexType === "skuid") {
              if (target.value?.length <= 30) {
                const skuValue = target.value?.replace(/[^a-zA-Z0-9-_]/g, "");
                setInputValue(skuValue);
              }
              return;
            }

            if (regexType === "price") {
              const regex = /^\d{0,7}(\.\d{0,2})?$/;

              if (regex.test(target.value)) {
                setInputValue(target.value);
              }
              return;
            }
            if (regexType === "qty") {
              const newValue = target?.value?.replace(/[^0-9]/g, "");

              if (newValue.length <= 7) {
                setInputValue(newValue);
              }
              return;
            }

            if (regexType === "gsthsnCode") {
              const allowedAlphaNumRegex = /^[A-Za-z0-9]*$/;
              const newValue = target.value;

              if (newValue.length <= 15) {
                if (allowedAlphaNumRegex.test(newValue)) {
                  setInputValue(newValue);
                }
              }
              return;
            }

            if (regexType === "weight") {
              const newValue = target?.value
                ?.replace(/[^\d.]/g, "")
                ?.replace(/^0+(?=\d)/, "")
                ?.replace(/^(\d*\.\d{0,2}).*/, "$1");

              if (newValue === "" || parseFloat(newValue) <= 10000000) {
                setInputValue(newValue);
              }
              return;
            }

            if (regexType === "dimension") {
              const newValue = target?.value
                ?.replace(/[^\d.]/g, "")
                ?.replace(/^0+(?=\d)/, "")
                ?.replace(/^(\d*\.\d{0,2}).*/, "$1");

              if (newValue === "" || parseFloat(newValue) <= 10000) {
                setInputValue(newValue);
              }
              return;
            }

            if (regexType === "number") {
              const newValue = target?.value?.replace(/[^0-9]/g, "");
              setInputValue(newValue);
              return;
            }

            if (regexType === "date") {
              const newValue = target?.value?.replace(/[^0-9/]/g, "");
              setInputValue(newValue);
              return;
            }

            const finalValue = allowOnlyEnglish(target.value);
            setInputValue(finalValue);
          }}
          placeholder={placeholder}
          sx={{
            fontSize: 12,
            fontWeight: 500,
            color: blackShade05,
            borderTop: "none",
            borderBottom: `0.5px solid ${borderTextBoxColor}`,
            borderLeft: "none",
            borderRight: "none",
            borderRadius: "0px",
            backgroundColor: whiteColor,
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          }}
          InputProps={{
            style: {
              padding: "0px",
              fontSize: 12,
              fontWeight: 500,
            },
            disableUnderline: true,
          }}
          inputProps={{ maxLength: maxLength }}
          type={inputType}
        />
      </Box>
    </Box>
  );
};

export default TabelCellInput;
