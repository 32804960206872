import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { BGColor02, greyColor39 } from "../configs/styles/muiThemes";
import BusinessDashboardContent from "./BusinessDashboard/BusinessDashboardContent";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveBusinessInfo,
  getBuyerAppLookup,
  getCommerceInfo,
} from "../reducers/businessPreview/businessPreviewSlice";
import { getBusinessDashboardData } from "../reducers/businessDashboard/BusinessDashboardSlice";
import ThreeDotLoader from "../components/ThreeDotLoader";
import ModalCard from "../components/ModalCard";
import BusinessDetailPopupContent from "./BusinessDashboard/Popup/BusinessDetailPopupContent";
import DashboardLayout from "../components/Layouts/DashboardLayout";

function BusinessDashboard() {
  const dispatch = useDispatch();

  const [openBusinessInfoModal, setOpenBusinessInfoModal] = useState(false);

  const { activeBusinessInfo, commerceInfo } = useSelector(
    (state) => state.businessPreview
  );

  const { dashboardData, dashboardDataLoader } = useSelector(
    (state) => state.businessDashboard
  );

  const showBusinessDetails = () => {
    setOpenBusinessInfoModal(true);
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    dispatch(getBuyerAppLookup());
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      if (!dashboardData) {
        dispatch(getBusinessDashboardData({ businessId }));
      }
      if (!commerceInfo) {
        dispatch(getCommerceInfo(businessId));
      }
    }
  }, []);

  return (
    <Box sx={styles.container}>
      <DashboardLayout activeMenu="dashboard" isLoading={dashboardDataLoader}>
        <Box display={{ md: "flex", xs: "block" }} justifyContent="center">
          {!activeBusinessInfo && !commerceInfo && !dashboardData ? (
            // dashboardDataLoader ||
            // activeBusinessInfoLoader ||
            // branchDetailsLoader
            <Box
              height={{ md: "auto", xs: "30vh" }}
              backgroundColor={BGColor02}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <ThreeDotLoader />
            </Box>
          ) : (
            <>
              {/* {JSON.stringify(dashboardData)} */}
              <BusinessDashboardContent
                businessData={activeBusinessInfo}
                dashboardData={dashboardData}
                commerceInfo={commerceInfo}
                showBusinessDetails={showBusinessDetails}
              />
            </>
          )}
        </Box>
      </DashboardLayout>

      {/* Show Business Details */}
      <ModalCard
        open={openBusinessInfoModal}
        handleClose={() => {
          setOpenBusinessInfoModal(false);
        }}
        width="85%"
      >
        <BusinessDetailPopupContent businessData={activeBusinessInfo} />
      </ModalCard>
    </Box>
  );
}

export default BusinessDashboard;

const styles = {
  container: {
    backgroundColor: { xs: greyColor39, md: BGColor02 },
  },

  content: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "0px", md: "25px" },
    p: { xs: "0px", md: "10px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },
};
