import { Box, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  greyShade82,
  whiteColor,
} from "../../configs/styles/muiThemes";

function VideoCard({ category, title, date, url }) {
  return (
    <Box sx={styles.card}>
      <Box sx={styles.videoContent}>
        <iframe
          src={url}
          width={"100%"}
          height={"100%"}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          frameborder="0"
          allowFullScreen
          title="YouTube video player"
        />
      </Box>
      <Box sx={styles.infoContainer}>
        <Typography
          fontSize={12.5}
          fontWeight={400}
          mb={"10px"}
          color={greyShade82}
        >
          {category}
        </Typography>
        <Typography
          fontSize={14}
          fontWeight={600}
          mb={"10px"}
          color={blackColor}
          mt={-1}
        >
          {title}
        </Typography>
        <Typography
          fontSize={12.5}
          fontWeight={400}
          color={greyShade82}
          mt={-1}
        >
          {date}
        </Typography>
      </Box>
    </Box>
  );
}

export default VideoCard;

const styles = {
  card: {
    padding: 0,
    overflow: "hidden",
    borderRadius: "10px",
    border: "1px solid #e5e9f0",
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    background: whiteColor,
  },
  videoContent: {
    width: "100%",
    aspectRatio: " 16 / 9",
  },
  infoContainer: {
    p: "12px 15px",
  },
};
