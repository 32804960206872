import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ONDC_ECOM_DEFAULT_DATA } from "../../utils/data/defaultFormData";
import ONDCControlsForm from "./Form/ONDCControlsForm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveBusinessInfo,
  getBuyerAppLookup,
} from "../../reducers/businessPreview/businessPreviewSlice";
import DashboardMenu from "../Dashboard/DashboardMenu";
import DashboardHeader from "../Dashboard/DashboardHeader";
import BusinessInfoCard from "../../components/Cards/BusinessInfoCard";
import {
  BGColor02,
  backgroundColor,
  blackColor,
  blackShade05,
  blackShade159,
  blackShade21,
  blackShade22,
  blueColor02,
  blueColor04,
  displaySwitchGreenColor,
  displaySwitchRedColor,
  greyColor24,
  greyColor49,
  greyColor6,
  lightRedColor02,
  mobilePrimary,
  outOfStockColor,
  primaryColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import {
  getBusinessONDCCategoryApi,
  getOndcCityToPincodeApi,
  updateBusinessTimingAndDeliveryMethod,
  updateCommerceSubmitAPI,
  updateONDCBusinessOptionalFields,
  updateONDCControlsAPI,
} from "../../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";
import ONDCControlsPreview from "./ONDCControlsPreview";
import ActionButtons from "../../components/Buttons/ActionButtons";
import CategorySpecsForm from "./Form/CategorySpecsForm";
import StyledSwitch from "../../components/Switch/StyledSwitch";
import DashboardStepper from "../Dashboard/DashboardStepper";
import { ONDC_STEP_LIST } from "../../utils/appData";
import PriceControlsForm from "./Form/PriceControlsForm";
import ApprovalSettingsForm from "./Form/ApprovalSettingsForm";
import TimingHolidayForm from "./Form/TimingHolidayForm";
import moment from "moment";
import {
  getBuyerAppLookupAPI,
  getCommerceDashboardInfoAPI,
} from "../../service/api_calls/BusinessPreview/BusinessPreviewAPI";
import {
  getONDCOpenDays,
  prepareAdditionalBusinessCategoryData,
  prepareONDCBusinessOptionsData,
  prepareONDCControlsUpdateData,
} from "../../service/api_calls/BusinessDashboard/BusinessDashboardHelper";
import ModalCard from "../../components/ModalCard";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import {
  doIntervalsOverlap,
  getIsOpen24HrData,
  getStoreTimingList,
} from "../../utils/data/ONDCHelper";
import { scrollBox, stickyHeader } from "../../utils/styles";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import BuyerAppForm from "./Form/BuyerAppForm";

const AddONDCControlsDetails = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeBusinessInfo, activeBusinessInfoLoader, buyerAppData } =
    useSelector((state) => state.businessPreview);

  const [ondcCategories, setOndcCategories] = useState([]);
  const [commerceInfo, setCommerceInfo] = useState(null);
  const [commerceInfoLoader, setCommerceInfoLoader] = useState(false);
  const [buyerLinks, setBuyerLinks] = useState(false);

  const [currentPage, setCurrentPage] = useState("none");

  const [ondcInfo, setOndcInfo] = useState({
    ...ONDC_ECOM_DEFAULT_DATA,
    errors: {},
  });

  const whatsAppNumber = "+918088422615";
  const emailAddress = "support@costbo.com";

  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${activeBusinessInfo?.name}+ -E-Commerce Controls+-+Discount+Detail+Update+Request.+`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };

  const handleOndcInfoChange = (property, value) => {
    setOndcInfo((prevState) => {
      const ondcUpdate = {
        ...prevState.ondc,
        [property]: value,
      };

      return {
        ...prevState,
        ondc: ondcUpdate,
        errors: { ...prevState?.errors, [property]: "" },
      };
    });
  };

  const getCommerceInfoDetails = async (businessId) => {
    const businessCommerceInfo = await getCommerceDashboardInfoAPI(businessId);
    setCommerceInfo(businessCommerceInfo || null);
    setCommerceInfoLoader(false);
  };

  const getONDCCategory = async (businessData) => {
    const response = await getBusinessONDCCategoryApi();

    if (response?.details?.length > 0) {
      const categories = response?.details || [];
      const businessCat = businessData?.businessCategories || [];

      console.log("categories:", categories);
      console.log("businessCat:", businessCat);

      const newOndcCat = [];

      businessCat.map((item) => {
        categories.forEach((ondcCat) => {
          if (ondcCat?.businessCategoryCodes === item?.code) {
            newOndcCat.push(ondcCat);
          }
        });
      });

      console.log("newOndcCat:", newOndcCat);

      // setOndcCategories(newOndcCat);
      setOndcCategories(categories);
    }
  };

  const validateONDCControls = () => {
    if (ondcInfo?.ondcEnabled === "yes") {
      if (currentPage === "category") {
        let errors = null;

        if (ondcCategories?.length === 0) {
          errors = {
            ...errors,
            ondcDomain: "Business level category change needed",
          };
          setOndcInfo((prevState) => ({
            ...prevState,
            errors,
          }));
          return;
        }

        if (ondcInfo?.ondc?.ondcDomain?.length === 0) {
          errors = { ...errors, ondcDomain: "Select ONDC category" };
        }

        if (ondcInfo?.ondc?.ondcSupportRegion?.value === "") {
          errors = { ...errors, ondcSupportRegion: "Select shipment region" };
        }

        if (ondcInfo?.ondc?.ondcSupportRegion?.value === "locality") {
          if (ondcInfo?.ondc?.ondcSupportDistanceKm > 5) {
            errors = {
              ...errors,
              ondcSupportDistanceKm: "Shipment distance must be 1KM - 5KM",
            };
          }
        }

        if (!ondcInfo?.ondc?.ondcSupportDistanceKm) {
          errors = {
            ...errors,
            ondcSupportDistanceKm: "Enter shipment distance",
          };
        }

        if (!ondcInfo?.ondc?.ondcBPPFulfillments) {
          errors = { ...errors, ondcBPPFulfillments: "Select delivery method" };
        }

        if (ondcInfo?.ondc?.ondcCustomerDiscountEnabled === "") {
          errors = {
            ...errors,
            ondcCustomerDiscountEnabled: "Select customer discount",
          };
        }

        if (errors) {
          setOndcInfo((prevState) => ({
            ...prevState,
            errors,
          }));
          return;
        } else {
          setCurrentPage("price-controls");
        }
      }

      if (currentPage === "price-controls") {
        let errors = null;

        if (!ondcInfo?.ondc?.ondcMinOrder) {
          errors = { ...errors, ondcMinOrder: "Enter minimum order amount" };
        }

        if (ondcInfo?.ondc?.packagingCost === "") {
          errors = { ...errors, packagingCost: "Enter packaging cost" };
        }

        if (ondcInfo?.ondc?.convenienceFee === "") {
          errors = { ...errors, convenienceFee: "Enter convenience fee" };
        }

        const buyerFinderFee =
          ondcInfo?.ondc?.ondcBuyerFinderFeeUpperLimitPercentage;

        if (!buyerFinderFee) {
          errors = {
            ...errors,
            ondcBuyerFinderFeeUpperLimitPercentage: "Enter buyer finder fee",
          };
        }

        if (buyerFinderFee && (buyerFinderFee < 3 || buyerFinderFee > 10)) {
          errors = {
            ...errors,
            ondcBuyerFinderFeeUpperLimitPercentage:
              "buyer finder fee should be greater than 3 and less than 10",
          };
        }

        if (errors) {
          setOndcInfo((prevState) => ({
            ...prevState,
            errors,
          }));
          return;
        } else {
          setCurrentPage("approval-settings");
        }
      }

      if (currentPage === "approval-settings") {
        let errors = null;

        if (
          ondcInfo?.ondc?.cancelTerms?.ondcFullCancellationStage?.value === ""
        ) {
          errors = {
            ...errors,
            ondcFullCancellationStage: "Select full cancel stage",
          };
        }

        if (ondcInfo?.ondc?.autoApproval?.autoApprovalEnabled === "") {
          errors = { ...errors, autoApprovalEnabled: "Select auto approval" };
        }

        if (errors) {
          setOndcInfo((prevState) => ({
            ...prevState,
            errors,
          }));
          return;
        } else {
          setCurrentPage("timing-holiday");
        }
      }

      if (currentPage === "timing-holiday") {
        let errors = null;

        // if (!ondcInfo?.ondc?.storeStartTime) {
        //   errors = { ...errors, storeStartTime: "Select store start time" };
        // }

        // if (!ondcInfo?.ondc?.storeEndTime) {
        //   errors = { ...errors, storeEndTime: "Select store end time" };
        // }

        // if (ondcInfo?.ondc?.storeStartTime && ondcInfo?.ondc?.storeEndTime) {
        //   const format = "HH:mm";
        //   const fromMoment = moment(ondcInfo?.ondc?.storeStartTime, format);
        //   const toMoment = moment(ondcInfo?.ondc?.storeEndTime, format);

        //   const isAfter = fromMoment.isAfter(toMoment);
        //   const isSame = fromMoment.isSame(toMoment);

        //   if (isAfter) {
        //     errors = {
        //       ...errors,
        //       storeTime: "Start Time should not be greater End Time",
        //     };
        //   } else if (isSame) {
        //     errors = {
        //       ...errors,
        //       storeTime: "Start and End Time should not be same",
        //     };
        //   }
        // }

        // if (!ondcInfo?.ondc?.breakStartTime) {
        //   errors = { ...errors, breakStartTime: "Select break start time" };
        // }

        // if (!ondcInfo?.ondc?.breakEndTime) {
        //   errors = { ...errors, breakEndTime: "Select break end time" };
        // }

        // if (ondcInfo?.ondc?.breakStartTime && ondcInfo?.ondc?.breakEndTime) {
        //   const format = "HH:mm";

        //   const fromMoment = moment(ondcInfo?.ondc?.breakStartTime, format);
        //   const toMoment = moment(ondcInfo?.ondc?.breakEndTime, format);

        //   const isAfter = fromMoment.isAfter(toMoment);
        //   const isSame = fromMoment.isSame(toMoment);

        //   if (isAfter) {
        //     errors = {
        //       ...errors,
        //       breakTime: "Start Time should not be greater End Time",
        //     };
        //   } else if (isSame) {
        //     errors = {
        //       ...errors,
        //       breakTime: "Start and End Time should not be same",
        //     };
        //   }

        //   if (ondcInfo?.ondc?.storeStartTime && ondcInfo?.ondc?.storeEndTime) {
        //     const storeFromMoment = moment(
        //       ondcInfo?.ondc?.storeStartTime,
        //       format
        //     );
        //     const storeToMoment = moment(ondcInfo?.ondc?.storeEndTime, format);

        //     if (
        //       storeFromMoment.isSameOrBefore(fromMoment) &&
        //       storeToMoment.isSameOrAfter(toMoment)
        //     ) {
        //       // errors = {
        //       //   ...errors,
        //       //   breakTime: "",
        //       // };
        //     } else {
        //       errors = {
        //         ...errors,
        //         breakTime:
        //           "Break start and end time should be within the store start and end time",
        //       };
        //     }
        //   }
        // }

        if (ondcInfo?.ondc?.isOpen24Hr === "no") {
          const isDaysSelected = ondcInfo?.ondc?.storeDays?.some(
            (item) => item?.isOpen === true
          );

          if (!isDaysSelected) {
            errors = {
              ...errors,
              storeDays: "Select store days",
            };
          }

          if (ondcInfo?.ondc?.storeTimeList?.length === 0) {
            errors = {
              ...errors,
              storeStartTime: "Enter store start and end time",
            };
          }

          if (ondcInfo?.ondc?.storeTimeList?.length === 1) {
            const timing = ondcInfo?.ondc?.storeTimeList[0];

            if (timing?.storeStartTime == "" || timing?.storeEndTime == "") {
              errors = {
                ...errors,
                storeStartTime: "Enter store start and end time",
              };
            }

            if (timing?.storeStartTime && timing?.storeEndTime) {
              const format = "HH:mm";
              const fromMoment = moment(timing?.storeStartTime, format);
              const toMoment = moment(timing?.storeEndTime, format);

              console.log("fromMoment__:", fromMoment);
              console.log("toMoment__:", toMoment);

              const isAfter = fromMoment.isSameOrAfter(toMoment);
              const isSame = fromMoment.isSame(toMoment);

              if (isAfter) {
                errors = {
                  ...errors,
                  storeStartTime: "Start Time should not be greater End Time",
                };
              }

              if (isSame) {
                errors = {
                  ...errors,
                  storeStartTime: "Start and End Time should not be same",
                };
              }
            }
          }

          if (ondcInfo?.ondc?.storeTimeList?.length > 0) {
            const format = "HH:mm";

            // Duration check and invalid time check
            ondcInfo?.ondc?.storeTimeList?.forEach((item, index) => {
              if (item?.storeStartTime !== "" && item?.storeEndTime !== "") {
                if (index > 0) {
                  const startMoment = moment(item?.storeStartTime, format);
                  const endMoment = moment(item?.storeEndTime, format);

                  const prevTiming = ondcInfo?.ondc?.storeTimeList[index - 1];

                  const prevStartMoment = moment(
                    prevTiming?.storeStartTime,
                    format
                  );
                  const prevEndMoment = moment(
                    prevTiming?.storeEndTime,
                    format
                  );

                  // Calculate durations
                  const currentDuration = moment.duration(
                    endMoment.diff(startMoment)
                  );
                  const prevDuration = moment.duration(
                    prevEndMoment.diff(prevStartMoment)
                  );

                  // Compare durations
                  const areDurationsEqual =
                    currentDuration.valueOf() === prevDuration.valueOf();

                  console.log("areDurationsEqual:", areDurationsEqual);

                  if (areDurationsEqual) {
                    console.log("Durations are equal");
                  } else {
                    console.log("Durations are not equal");
                    errors = {
                      ...errors,
                      storeStartTime:
                        "Duration between start and end time should be same for all entries",
                    };
                  }
                }
              } else {
                // isInvalidTime = "Enter valid start and end time";
                errors = {
                  ...errors,
                  storeStartTime: "Enter start and end time",
                };
              }
            });

            // Check if all intervals in the array are unique and non-overlapping

            const timeArr = ondcInfo?.ondc?.storeTimeList || [];

            for (let i = 0; i < timeArr.length; i++) {
              for (let j = i + 1; j < timeArr.length; j++) {
                if (doIntervalsOverlap(timeArr[i], timeArr[j])) {
                  errors = {
                    ...errors,
                    storeStartTime: "Time intervals overlap",
                  };
                }
              }
            }
          }
        }

        if (
          ondcInfo?.ondc?.holidayStartDate ||
          ondcInfo?.ondc?.holidayEndDate
        ) {
          if (!ondcInfo?.ondc?.holidayStartDate) {
            errors = {
              ...errors,
              holidayStartDate: "Select holiday start date",
            };
          }

          if (!ondcInfo?.ondc?.holidayEndDate) {
            errors = { ...errors, holidayEndDate: "Select holiday end date" };
          }

          if (
            ondcInfo?.ondc?.holidayStartDate &&
            ondcInfo?.ondc?.holidayEndDate
          ) {
            const fromDate = moment(ondcInfo?.ondc?.holidayStartDate);
            const toDate = moment(ondcInfo?.ondc?.holidayEndDate);

            const currentDate = moment().subtract(1, "day");

            const isFromBeforeCurrentDate = fromDate?.isBefore(currentDate);
            const isFromAfterToDate = fromDate?.isAfter(toDate);

            if (isFromAfterToDate) {
              errors = {
                ...errors,
                holidayStartDate: "Start Date should be before End Date",
              };
            }

            if (isFromBeforeCurrentDate) {
              errors = {
                ...errors,
                holidayStartDate: "Start Date cannot be in past",
              };
            }

            if (!isFromBeforeCurrentDate && !isFromAfterToDate) {
              const diffCount = moment.duration(toDate.diff(fromDate)).asDays();

              if (diffCount > 90) {
                errors = {
                  ...errors,
                  holidayStartDate:
                    "Period between start and end date is 90 days",
                };
              }
            }
          }
        }

        console.log("ONDC___DATA:", ondcInfo);

        if (errors) {
          setOndcInfo((prevState) => ({
            ...prevState,
            errors,
          }));
          return;
        } else {
          setOndcInfo((prevState) => ({
            ...prevState,
            errors: null,
          }));
          setCurrentPage("buyer-app");
          window.scrollTo(0, 0);
        }
      }

      if (currentPage === "buyer-app") {
        let errors = null;
        const isBuyerLinkError = ondcInfo?.ondc?.buyerLinks?.some(
          (item) =>
            (item?.code === "" && item?.value !== "") ||
            (item?.code !== "" && item?.value === "")
        );
        if (isBuyerLinkError) {
          errors = {
            ...errors,
            buyerLinks: "Enter the label and value",
          };
        }
        // else {
        //   const uniqueCodes = [
        //     ...new Set(ondcInfo?.ondc?.buyerLinks?.map((obj) => obj.code)),
        //   ];

        //   if (uniqueCodes?.length < ondcInfo?.ondc?.buyerLinks)
        //     errors = {
        //       ...errors,
        //       buyerLinks: "This Buyer app name already selected",
        //     };
        // }
        if (errors) {
          setOndcInfo((prevState) => ({
            ...prevState,
            errors,
          }));
          return;
        } else {
          setOndcInfo((prevState) => ({
            ...prevState,
            errors: null,
          }));
          setCurrentPage("preview");
          // setCurrentPage("preview");
          window.scrollTo(0, 0);
        }
      }
    }
  };

  const goBack = () => {
    if (currentPage === "category") {
      setCurrentPage("category");
      return;
    }
    if (currentPage === "price-controls") {
      setCurrentPage("category");
      return;
    }
    if (currentPage === "approval-settings") {
      setCurrentPage("price-controls");
      return;
    }
    if (currentPage === "timing-holiday") {
      setCurrentPage("approval-settings");
      return;
    }
    if (currentPage === "buyer-app") {
      setCurrentPage("timing-holiday");
      return;
    }
    if (currentPage === "preview") {
      setCurrentPage("buyer-app");
      return;
    }
  };

  // Update API call
  const updateONDCControls = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    console.log("ONDC___DATA:", ondcInfo);

    setLoading(true);
    setShowAlert(true);

    const businessPincode = activeBusinessInfo?.businessArea?.filter(
      (item) => item?.code === "pincode"
    );

    const stdData = await getOndcCityToPincodeApi({
      pincode: businessPincode?.[0]?.value,
    });

    const postData = prepareONDCControlsUpdateData(
      {
        commerceInfo: commerceInfo?.info || null,
        ondcInfo,
        ondcCategories,
        stdData: stdData || null,
      },
      false
    );

    const businessOptionalFieldPostData = prepareONDCBusinessOptionsData({
      ondcInfo,
      ondcCategories,
      isEdit: false,
    });

    console.log("ONDC___postData:", postData);
    console.log(
      "ONDC___businessOptionalFieldPostData:",
      businessOptionalFieldPostData
    );

    const additionalCategoryPostData = prepareAdditionalBusinessCategoryData({
      ondcInfo,
      ondcCategories,
      activeBusinessInfo,
      isEdit: false,
    });

    console.log(
      "ONDC___additionalCategoryPostData:",
      additionalCategoryPostData
    );

    const responce = await updateONDCControlsAPI(postData, businessId);

    if (responce) {
      await updateONDCBusinessOptionalFields(
        businessOptionalFieldPostData,
        businessId
      );

      if (additionalCategoryPostData?.businessCategories?.length > 0) {
        await updateBusinessTimingAndDeliveryMethod(
          { ...additionalCategoryPostData, businessId },
          businessId
        );
      }

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  // Format ondc domain for preview
  const getONDCDomainValue = (domainCodeArr) => {
    const domainNames = [];

    domainCodeArr?.forEach((item) => {
      const category = ondcCategories?.filter(
        (categoryItem) => categoryItem?.domainCode === item
      );

      if (category?.length > 0) {
        domainNames.push(category?.[0]?.domainName);
      }
    });

    return domainNames?.join(", ");
  };

  const handleEditClick = () => {
    const webURL = sessionStorage.getItem("costboWEBURL");
    navigate(`/${webURL}/editONDCControls`);
  };

  const autoFillBuyerLinks = async () => {
    let buyerLinkList = [];

    if (commerceInfo?.info?.ondc?.buyerAppLinks?.length > 0) {
      const buyerResponse = await getBuyerAppLookupAPI();

      if (buyerResponse.details) {
        commerceInfo?.info?.ondc?.buyerAppLinks?.forEach((obj1) => {
          // Find the matching object from arr2 based on the 'code' property
          const matchedObj = buyerResponse.details.find(
            (obj2) => obj2.bapName === obj1.code
          );
          // If a matching object is found, merge its properties with obj1
          if (matchedObj) {
            buyerLinkList?.push({
              ...obj1,
              displayName: matchedObj?.displayName,
            });
          }
        });
      }
    } else {
      buyerLinkList?.push({ code: "", value: "" });
    }

    console.log("buyerLinks__", buyerLinkList, commerceInfo);
    setBuyerLinks(buyerLinkList);
  };

  useEffect(() => {
    setCommerceInfoLoader(true);
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      dispatch(getBuyerAppLookup(businessId));
      getCommerceInfoDetails(businessId);
    }
  }, []);

  useEffect(() => {
    if (activeBusinessInfo) {
      getONDCCategory(activeBusinessInfo);
    }
    return () => {};
  }, [activeBusinessInfo]);

  useEffect(() => {
    if (commerceInfo && commerceInfo?.info?.ondcEnabled !== "yes") {
      setCurrentPage("category");
    } else {
      setCurrentPage("display");
      // setCurrentPage("category");
    }

    autoFillBuyerLinks();

    return () => {};
  }, [commerceInfo]);

  return (
    <Box sx={styles.container}>
      <DashboardLayout activeMenu="ONDCControls">
        <Box sx={styles.content}>
          <Box
            sx={ondcInfo?.ondcEnabled === "yes" ? stickyHeader : { mt: 2 }}
            pb={{md:1,xs:0}}
          >
            <Box
              display="flex"
              flexDirection={{ xs: "row", md: "row" }}
              alignItems={{ xs: "flex-start", md: "flex-start" }}
              justifyContent="space-between"
            >
              <Box>
                <Typography
                  component="h6"
                  fontSize={{ xs: 15, md: 17 }}
                  color={blackColor}
                  fontWeight={600}
                  lineHeight="20px"
                >
                  ONDC Controls
                </Typography>
                <Typography
                  variant="h6"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade21}
                  lineHeight={"20px"}
                  // display="inline"
                  // mt={{ xs: 1.5, md: 1 }}
                >
                  Any queries about filling the data below please contact via{" "}
                  <Typography
                    // variant="body1"
                    fontSize={13}
                    fontWeight={500}
                    color={{ xs: mobilePrimary, md: blueColor04 }}
                    display="inline"
                    // onclick={handleChatClick}
                    component="a"
                    href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Discount+Detail+Update+Request.+&fs=1&to=${emailAddress}&tf=cm`}
                    target="_blank"
                  >
                    Email
                  </Typography>
                  {"  "}
                  or{" "}
                  <Typography
                    // variant="body1"
                    fontSize={13}
                    fontWeight={500}
                    color={{ xs: mobilePrimary, md: blueColor04 }}
                    display="inline"
                    onClick={handleChatClick}
                  >
                    Whatsapp
                  </Typography>
                </Typography>
              </Box>
              {currentPage === "display" && (
                <Button
                  onClick={handleEditClick}
                  sx={styles.editBtn}
                  disabled={activeBusinessInfoLoader || commerceInfoLoader}
                >
                  EDIT
                </Button>
              )}
            </Box>

            {!activeBusinessInfoLoader && !commerceInfoLoader && (
              <>
                {currentPage !== "preview" &&
                  currentPage !== "display" &&
                  currentPage !== "none" && (
                    <>
                      {commerceInfo?.status === "new" && (
                        <Box
                          sx={{
                            bgcolor: lightRedColor02,
                            p: "8px 15px",
                            borderRadius: "10px",
                            mb: 0.2,
                            mt: 2,
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 12, md: 13 }}
                            fontWeight={500}
                            color={outOfStockColor}
                            lineHeight="21px"
                          >
                            E-Commerce Controls are not configured yet for this
                            business. Update E-Commerce details before enabling
                            ONDC for this business.{" "}
                            <Link
                              to={`/addCommerceInfo`}
                              style={{
                                color: greyColor49,
                                textDecoration: "underline",
                              }}
                            >
                              Click Here
                            </Link>
                          </Typography>
                        </Box>
                      )}
                      <Box mt={1}>
                        <Typography
                          component="h6"
                          fontSize={{ xs: 12, md: 13 }}
                          color={greyColor6}
                          fontWeight={400}
                          lineHeight="20px"
                          display="inline"
                        >
                          ONDC Enabled{" "}
                          <Typography
                            variant="body1"
                            sx={{ display: "inline", color: "#f00" }}
                          >
                            *
                          </Typography>
                        </Typography>
                      </Box>

                      <StyledSwitch
                        label={ondcInfo?.ondcEnabled === "yes" ? "Yes" : "No"}
                        isChecked={ondcInfo?.ondcEnabled === "yes"}
                        onChange={() => {
                          // if (!commerceInfo?.info?.commerceEnable) return;
                          if (commerceInfo?.status === "new") return;
                          if (ondcInfo?.ondcEnabled === "yes") {
                            setOndcInfo((prevState) => ({
                              ...ONDC_ECOM_DEFAULT_DATA,
                              ondcEnabled: "no",
                              errors: null,
                            }));

                            return;
                          }

                          setOndcInfo((prevState) => ({
                            ...prevState,
                            ondcEnabled:
                              ondcInfo?.ondcEnabled === "yes" ? "no" : "yes",
                          }));
                        }}
                      />

                      {ondcInfo?.ondcEnabled === "yes" &&
                        currentPage !== "preview" && (
                          <Box mt={0}>
                            {matches && (
                              <DashboardStepper
                                stepList={ONDC_STEP_LIST}
                                currentPage={currentPage}
                                handleTabChange={() => {}}
                              />
                            )}

                            {!matches && (
                              <Box sx={{ ...styles.mobileTabs, ...scrollBox }}>
                                {ONDC_STEP_LIST?.map((item, index) => {
                                  return (
                                    <MobileTab
                                      title={item.label}
                                      isActive={currentPage == item?.value}
                                      icon={item?.icon}
                                      onClick={() => {
                                        // setCurrentPage(item?.value)
                                      }}
                                    />
                                  );
                                })}
                              </Box>
                            )}
                          </Box>
                        )}
                    </>
                  )}
              </>
            )}
          </Box>

          {!activeBusinessInfoLoader && !commerceInfoLoader ? (
            <Stack gap={1.5}>
              {currentPage !== "preview" &&
                currentPage !== "display" &&
                currentPage !== "none" && (
                  <>
                    {ondcInfo?.ondcEnabled === "yes" && (
                      <Box sx={styles.card}>
                        {currentPage === "category" && (
                          <CategorySpecsForm
                            data={ondcInfo}
                            setData={setOndcInfo}
                            ondcCategories={ondcCategories}
                            handleOndcInfoChange={handleOndcInfoChange}
                          />
                        )}

                        {currentPage === "price-controls" && (
                          <PriceControlsForm
                            data={ondcInfo}
                            setData={setOndcInfo}
                            handleOndcInfoChange={handleOndcInfoChange}
                          />
                        )}

                        {currentPage === "approval-settings" && (
                          <ApprovalSettingsForm
                            data={ondcInfo}
                            setData={setOndcInfo}
                            handleOndcInfoChange={handleOndcInfoChange}
                          />
                        )}

                        {currentPage === "timing-holiday" && (
                          <TimingHolidayForm
                            data={ondcInfo}
                            setData={setOndcInfo}
                            handleOndcInfoChange={handleOndcInfoChange}
                          />
                        )}

                        {currentPage === "buyer-app" && buyerAppData && (
                          <BuyerAppForm
                            buyerLinks={ondcInfo?.ondc?.buyerLinks}
                            menuList={buyerAppData}
                            handleOndcInfoChange={handleOndcInfoChange}
                            error={ondcInfo?.errors?.buyerLinks}
                          />
                        )}

                        <Box mt="20px">
                          <ActionButtons
                            onBackClick={goBack}
                            onSaveClick={() => {}}
                            onNextClick={validateONDCControls}
                            onCancelClick={() => {
                              setOndcInfo((prevState) => ({
                                ...ONDC_ECOM_DEFAULT_DATA,
                                ondcEnabled: "no",
                                errors: null,
                              }));
                            }}
                            showBackBtn
                            hideSaveBtn
                            // hideCancel
                            backBtnText="Previous"
                            nextBtnText={
                              currentPage === "buyer-app"
                                ? "PREVIEW & SUBMIT"
                                : "NEXT"
                            }
                          />
                        </Box>
                      </Box>
                    )}
                  </>
                )}

              {currentPage === "preview" && (
                <>
                  <ONDCControlsPreview
                    ondcEnabled={ondcInfo?.ondcEnabled}
                    ondcDomain={ondcInfo?.ondc?.ondcDomain?.join(", ")}
                    ondcSupportRegion={ondcInfo?.ondc?.ondcSupportRegion?.value}
                    ondcSupportDistanceKm={
                      ondcInfo?.ondc?.ondcSupportDistanceKm
                    }
                    ondcBPPFulfillments={
                      ondcInfo?.ondc?.ondcBPPFulfillments?.type
                    }
                    ondcCustomerDiscountEnabled={
                      ondcInfo?.ondc?.ondcCustomerDiscountEnabled === "yes"
                        ? "Yes"
                        : "No"
                    }
                    ondcMinOrder={ondcInfo?.ondc?.ondcMinOrder}
                    packagingCost={ondcInfo?.ondc?.packagingCost}
                    convenienceFee={ondcInfo?.ondc?.convenienceFee}
                    ondcBuyerFinderFeeUpperLimitPercentage={
                      ondcInfo?.ondc?.ondcBuyerFinderFeeUpperLimitPercentage
                    }
                    ondcPartialCancellationStage={
                      ondcInfo?.ondc?.cancelTerms?.ondcPartialCancellationStage
                    }
                    ondcFullCancellationStage={
                      ondcInfo?.ondc?.cancelTerms?.ondcFullCancellationStage
                        ?.value
                    }
                    autoApprovalEnabled={
                      ondcInfo?.ondc?.autoApproval?.autoApprovalEnabled
                        ? "Yes"
                        : "No"
                    }
                    // Holiday

                    isOpen24Hr={ondcInfo?.ondc?.isOpen24Hr === "yes"}
                    storeTimeList={ondcInfo?.ondc?.storeTimeList || []}
                    storeDays={
                      ondcInfo?.ondc?.isOpen24Hr === "yes"
                        ? ondcInfo?.ondc?.storeDays?.map((item) => ({
                            ...item,
                            isOpen: true,
                          }))
                        : ondcInfo?.ondc?.storeDays
                    }
                    storeStartTime={
                      ondcInfo?.ondc?.isOpen24Hr === "yes"
                        ? "00:00"
                        : ondcInfo?.ondc?.storeStartTime
                    }
                    storeEndTime={
                      ondcInfo?.ondc?.isOpen24Hr === "yes"
                        ? "23:59"
                        : ondcInfo?.ondc?.storeEndTime
                    }
                    breakStartTime={ondcInfo?.ondc?.breakStartTime}
                    breakEndTime={ondcInfo?.ondc?.breakEndTime}
                    holidayStartDate={ondcInfo?.ondc?.holidayStartDate}
                    holidayEndDate={ondcInfo?.ondc?.holidayEndDate}
                    buyerLinks={ondcInfo?.ondc?.buyerLinks}
                  />

                  <Box mt="20px">
                    <ActionButtons
                      onBackClick={goBack}
                      onSaveClick={() => {}}
                      onNextClick={updateONDCControls}
                      // onCancelClick={handleCancel}
                      showBackBtn
                      hideSaveBtn
                      hideCancel
                      backBtnText="Previous"
                      nextBtnText="SUBMIT"
                    />
                  </Box>
                </>
              )}

              {currentPage === "display" && (
                <>
                  <ONDCControlsPreview
                    ondcEnabled={ondcInfo?.ondcEnabled}
                    ondcDomain={getONDCDomainValue(
                      commerceInfo?.info?.ondc?.ondcDomain
                    )}
                    ondcSupportRegion={
                      commerceInfo?.info?.ondc?.ondcSupportRegion?.[0]
                    }
                    ondcSupportDistanceKm={
                      commerceInfo?.info?.ondc?.ondcSupportDistanceKm
                    }
                    ondcBPPFulfillments={
                      commerceInfo?.info?.ondc?.ondcBPPFulfillments?.[0]?.type
                    }
                    ondcCustomerDiscountEnabled={
                      commerceInfo?.info?.ondc?.ondcCustomerDiscountEnabled ===
                      "yes"
                        ? "Yes"
                        : "No"
                    }
                    ondcMinOrder={commerceInfo?.info?.ondc?.ondcMinOrder}
                    packagingCost={commerceInfo?.info?.ondc?.packagingCost}
                    convenienceFee={commerceInfo?.info?.ondc?.convenienceFee}
                    ondcBuyerFinderFeeUpperLimitPercentage={
                      commerceInfo?.info?.ondc
                        ?.ondcBuyerFinderFeeUpperLimitPercentage
                    }
                    ondcPartialCancellationStage={
                      commerceInfo?.info?.ondc?.cancelTerms
                        ?.ondcPartialCancellationStage
                    }
                    ondcFullCancellationStage={
                      commerceInfo?.info?.ondc?.cancelTerms
                        ?.ondcFullCancellationStage
                    }
                    autoApprovalEnabled={
                      commerceInfo?.info?.ondc?.autoApproval
                        ?.autoApprovalEnabled
                        ? "Yes"
                        : "No"
                    }
                    // Holidays

                    isOpen24Hr={
                      getIsOpen24HrData(
                        commerceInfo?.info?.ondc?.operatingHours,
                        commerceInfo?.info?.ondc?.openDays
                      ) === "yes"
                    }
                    storeTimeList={getStoreTimingList(
                      commerceInfo?.info?.ondc?.schedule?.times,
                      commerceInfo?.info?.ondc?.schedule?.frequency,
                      commerceInfo?.info?.ondc?.operatingHours,
                      commerceInfo?.info?.ondc?.openDays
                    )}
                    storeDays={getONDCOpenDays(
                      commerceInfo?.info?.ondc?.openDays
                    )}
                    storeStartTime={
                      commerceInfo?.info?.ondc?.operatingHours?.[0]
                    }
                    storeEndTime={commerceInfo?.info?.ondc?.operatingHours?.[1]}
                    breakStartTime={
                      commerceInfo?.info?.ondc?.schedule?.times?.[0]
                    }
                    breakEndTime={
                      commerceInfo?.info?.ondc?.schedule?.times?.[1]
                    }
                    holidayStartDate={
                      commerceInfo?.info?.ondc?.schedule?.holidays?.[0] || ""
                    }
                    holidayEndDate={
                      commerceInfo?.info?.ondc?.schedule?.holidays?.[
                        commerceInfo?.info?.ondc?.schedule?.holidays?.length - 1
                      ] || ""
                    }
                    buyerLinks={
                      buyerLinks?.length > 0
                        ? buyerLinks
                        : [{ code: "", value: "" }]
                    }
                  />

                  {/* <Box mt={{ xs: 2, md: 0 }} mb={2}>
                  <ActionButtons
                    onBackClick={() => {}}
                    onSaveClick={() => {}}
                    onNextClick={handleEditClick}
                    onCancelClick={() => {}}
                    // showBackBtn
                    hideSaveBtn
                    hideCancel
                    backBtnText="Previous"
                    nextBtnText="EDIT"
                  />
                </Box> */}
                </>
              )}

              {commerceInfo?.info?.ondcEnabled?.toLowerCase() !== "yes" &&
                ondcInfo?.ondcEnabled === "no" && (
                  <Stack alignItems="center">
                    <Box
                      component="img"
                      src="https://storage.googleapis.com/bodefaults/businessdashboardweb/ondc-banner.svg?test=jdb"
                      sx={styles.ondcBanner}
                    />

                    <Button
                      variant="contained"
                      disableElevation
                      sx={styles.learnMoreBtn}
                      onClick={() =>
                        window.open("https://www.costbo.com/ondc", "_blank")
                      }
                    >
                      Learn More
                    </Button>
                  </Stack>
                )}
            </Stack>
          ) : (
            <ThreeDotLoader />
          )}
        </Box>
      </DashboardLayout>

      <ModalCard
        open={showAlert}
        handleClose={() => {
          navigate(0);
        }}
        width="40%"
      >
        {loading ? (
          <Box
            sx={{
              bgcolor: whiteColor,
              minHeight: "80px",
              pt: 0.8,
              borderRadius: "6px",
            }}
          >
            <ThreeDotLoader />
          </Box>
        ) : (
          <AlertMessagePopup
            text={`ONDC controls have been submitted for approval. Please contact support@costbo.com for any queries`}
            onClick={() => {
              const webURL = sessionStorage.getItem("costboWEBURL");
              navigate(0);
            }}
          />
        )}
      </ModalCard>
    </Box>
  );
};

export default AddONDCControlsDetails;

const MobileTab = ({ title, icon, isActive, onClick }) => {
  return (
    <Box sx={styles.mobileTabItem(isActive)} onClick={onClick}>
      {icon}
      <Typography
        fontSize={12}
        color={isActive ? blueColor04 : blackShade05}
        fontWeight={isActive ? 600 : 500}
        sx={{ whiteSpace: "nowrap" }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const AlertMessagePopup = ({ text, onClick }) => {
  return (
    <Box sx={styles.alertCard}>
      <Typography
        variant="body2"
        fontSize={14}
        fontWeight={500}
        textAlign="center"
        sx={{ whiteSpace: "pre-line", lineHeight: "26px", my: 1.2 }}
      >
        {text}
      </Typography>
      <Button sx={styles.alertBtn} variant="outlined" onClick={onClick}>
        OK
      </Button>
    </Box>
  );
};

const styles = {
  container: {
    minHeight: "100vh",
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    // gap: { xs: "0px", md: "12px" },
    // pb: 1,
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  alertCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    padding: "20px 20px 15px 20px",
    borderRadius: "8px",
  },

  editBtn: {
    fontSize: "13px",
    fontWeight: "500",
    color: whiteColor,
    backgroundColor: blackShade22,
    padding: "3px 16px",
    border: `1px solid ${blackShade22}`,
    "&:hover": {
      color: blackShade22,
      backgroundColor: whiteColor,
    },

    "&.Mui-disabled": {
      color: whiteColor,
      border: "none",
    },
  },

  alertBtn: {
    fontSize: "13px",
    fontWeight: "500",
    color: whiteColor,
    backgroundColor: primaryColor,
    padding: "6px 24px",
    marginTop: "12px",
    "&:hover": {
      color: primaryColor,
      backgroundColor: whiteColor,
    },
  },

  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    p: "3px 15px 15px 15px",
    m: { xs: "0 0 25px", md: "0px 0 25px" },
  },

  mobileTabs: {
    width: { xs: "calc(100vw - 60px)", md: "calc(100% - 100px)" },
    display: "flex",
    alignItems: "center",
  },

  mobileTabItem: (isActive) => ({
    display: "flex",
    alignItems: "center",
    gap: "6px",
    py: 0.8,
    color: isActive ? blueColor04 : blackShade05,
    borderBottom: isActive ? `3px solid ${blueColor04}` : "none",
  }),

  ondcBanner: {
    width: { xs: "calc(100vw - 30px)", md: "75%" },
    backgroundColor: "rgba(0,0,0,0.05)",
    height: { xs: "auto", md: "618px" },
    objectFit: "contain",
    mt: 1,
    borderRadius: "10px",
  },

  learnMoreBtn: {
    fontSize: 14,
    fontWeight: 500,
    color: blueColor04,
    textDecoration: "underline",
    textTransform: "unset",
    bgcolor: "transparent",
    mt: 1.5,
    "&:hover": {
      color: blueColor04,
      bgcolor: "transparent",
      textDecoration: "none",
    },
  },
};
