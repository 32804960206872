import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBusinessOrdersByStatusApi } from "../../service/api_calls/orders/OrdersApi";
import { getReturnOrdersByStatusApi } from "../../service/api_calls/manageReturns/ManageReturnsApi";

const initialState = {
  newReturnOrders: [],
  newReturnPageNo: 1,
  newReturnOrdersLoader: false,

  completedReturnOrders: [],
  completedReturnPageNo: 1,
  completedReturnOrdersLoader: false,

  refreshTabData: 1,
};

// Get New Orders
export const getNewReturnOrderData = createAsyncThunk(
  "getNewReturnOrderData",
  async (param, thunkAPI) => {
    const res = await getReturnOrdersByStatusApi({
      ...param,
      status: "REQUESTED",
    });
    return res;
  }
);

// Refresh New Orders
export const refreshNewReturnOrderData = createAsyncThunk(
  "refreshNewReturnOrderData",
  async (param, thunkAPI) => {
    const res = await getReturnOrdersByStatusApi({
      ...param,
      pageNo: 1,
      status: "REQUESTED",
    });
    return res;
  }
);

// Get Completed Orders
export const getCompletedReturnOrderData = createAsyncThunk(
  "getCompletedReturnOrderData",
  async (param, thunkAPI) => {
    const res = await getReturnOrdersByStatusApi({
      ...param,
      status: "RESPONDED",
    });
    return res;
  }
);

// Refresh Completed Orders
export const refreshCompltedReturnOrderData = createAsyncThunk(
  "refreshCompltedReturnOrderData",
  async (param, thunkAPI) => {
    const res = await getReturnOrdersByStatusApi({
      ...param,
      pageNo: 1,
      status: "RESPONDED",
    });
    return res;
  }
);

export const manageReturnOrders = createSlice({
  name: "manageReturns",
  initialState,
  reducers: {
    clearReturnOrderInfo: (state, action) => {
      state.newReturnOrders = [];
      state.newReturnPageNo = 1;
      state.completedReturnOrders = [];
      state.completedReturnPageNo = 1;
    },

    clearNewReturnOrderInfo: (state) => {
      state.newReturnOrders = [];
      state.newReturnPageNo = 1;
    },

    clearCompletedReturnOrderInfo: (state) => {
      state.completedReturnOrders = [];
      state.completedReturnPageNo = 1;
    },
  },
  extraReducers: {
    //fetch Pending Business Orders
    [getNewReturnOrderData.pending]: (state) => {
      state.newReturnOrdersLoader = state.newReturnPageNo === 1;
    },
    [getNewReturnOrderData.fulfilled]: (state, { payload }) => {
      const orderData = payload?.details || [];
      if (orderData.length > 0) {
        state.newReturnOrders = [...state.newReturnOrders, ...orderData];
        state.newReturnPageNo = state.newReturnPageNo + 1;
      }
      state.newReturnOrdersLoader = false;
    },
    [getNewReturnOrderData.rejected]: (state) => {
      state.newReturnOrdersLoader = false;
    },

    //fetch Pending Business Orders
    [refreshNewReturnOrderData.pending]: (state) => {
      state.newReturnOrdersLoader = state.newReturnPageNo === 1;
    },
    [refreshNewReturnOrderData.fulfilled]: (state, { payload }) => {
      const orderData = payload?.details || [];
      state.newReturnOrders = orderData;
      state.newReturnPageNo = 2;
      state.newReturnOrdersLoader = false;
    },
    [refreshNewReturnOrderData.rejected]: (state) => {
      state.newReturnOrdersLoader = false;
    },

    //fetch Completed Business Orders
    [getCompletedReturnOrderData.pending]: (state) => {
      state.completedReturnOrdersLoader = state.completedReturnPageNo === 1;
    },
    [getCompletedReturnOrderData.fulfilled]: (state, { payload }) => {
      const orderData = payload?.details || [];
      if (orderData.length > 0) {
        state.completedReturnOrders = [
          ...state.completedReturnOrders,
          ...orderData,
        ];
        state.completedReturnPageNo = state.completedReturnPageNo + 1;
      }
      state.completedReturnOrdersLoader = false;
    },
    [getCompletedReturnOrderData.rejected]: (state) => {
      state.completedReturnOrdersLoader = false;
    },

    //fetch Pending Business Orders
    [refreshCompltedReturnOrderData.pending]: (state) => {
      state.completedReturnOrdersLoader = state.completedReturnPageNo === 1;
    },
    [refreshCompltedReturnOrderData.fulfilled]: (state, { payload }) => {
      const orderData = payload?.details || [];
      state.completedReturnOrders = orderData;
      state.completedReturnPageNo = 2;
      state.completedReturnOrdersLoader = false;
    },
    [refreshCompltedReturnOrderData.rejected]: (state) => {
      state.completedReturnOrdersLoader = false;
    },
  },
});

export const {
  clearReturnOrderInfo,
  clearNewReturnOrderInfo,
  clearCompletedReturnOrderInfo,
} = manageReturnOrders.actions;

export const manageReturnOrdersReducer = manageReturnOrders.reducer;
