import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  greyColor21,
  greyColor6,
  labelColor,
} from "../../configs/styles/muiThemes";

function CheckBoxWithLabel({
  label,
  infoText,
  textSmall,
  onValuesChanged,
  checked,
  checkBoxStyle,
  labelStyle,
  disabled,
}) {
  return (
    <FormControlLabel
      disabled={disabled}
      control={
        <Checkbox
          color="primary"
          checked={checked}
          size="large"
          onChange={() => {
            onValuesChanged();
          }}
          sx={{ whiteSpace: "nowrap", color: greyColor21, ...checkBoxStyle }}
        />
      }
      label={
        <Typography
          variant="body1"
          color={labelColor}
          fontSize={textSmall ? "12px" : "14px"}
          fontWeight={500}
          sx={{ display: { md: "inline", xs: "block" }, ...labelStyle }}
        >
          {label}{" "}
          <Typography
            sx={{
              display: { md: "inline", xs: "block" },
              color: greyColor6,
              fontSize: 12,
              fontWeight: { md: 500, xs: 400 },
            }}
          >
            {infoText || ""}
          </Typography>
        </Typography>
      }
    />
  );
}

export default CheckBoxWithLabel;
