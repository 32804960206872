import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  BGColor03,
  blackShade05,
  deleteBtbColor,
  greenStatusColor,
  greyColor052,
  greyColor6,
  logoImgborder1,
  orangeStatusColor,
  tableHeaderColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import moment from "moment";
import { CURRENT_DATE } from "../../service/constant";
import SocialShare from "../../components/Cards/SocialShare";
import ShareIcon from "@mui/icons-material/Share";
import ModalCard from "../../components/ModalCard";
import EmptyCard from "../../components/Cards/EmptyCard";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";

function CouponDiscountDetails({
  coupons,
  usageInfo,
  activeBusinessInfo,
  statusValue,
}) {
  return (
    <Grid container spacing={2} mt={0.1}>
      {coupons?.map((item, index) => {
        const endDate = item?.codeValidityEnd;
        const currentDate = CURRENT_DATE;

        const isCouponExpired = moment(endDate).isBefore(currentDate);

        const usageData = usageInfo?.filter(
          (info) => info?.couponcode === item?.resellerCode
        );
        const totalOrderNumber = usageData?.[0]?.totalCouponOrderNumbers || 0;
        const totalOrderValue =
          usageData?.[0]?.totalCouponOrderValue
            .toFixed(2)
            .replaceAll(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",") || 0;

        return statusValue === "all" ? (
          <Grid item xs={12} md={3}>
            <CuoponCardDetails
              borderleftcolor={
                isCouponExpired ? orangeStatusColor : greenStatusColor
              }
              offvalue={item?.discountPercentage}
              offerexpiry={moment(endDate).format("MMM DD YYYY")}
              couponCode={item?.resellerCode}
              appliedCount={totalOrderNumber}
              appliedAmount={totalOrderValue}
              businessname={activeBusinessInfo?.name}
            />
          </Grid>
        ) : statusValue === "active" && !isCouponExpired ? (
          <Grid item xs={12} md={3}>
            {
              <CuoponCardDetails
                borderleftcolor={
                  isCouponExpired ? orangeStatusColor : greenStatusColor
                }
                offvalue={item?.discountPercentage}
                offerexpiry={moment(endDate).format("MMM DD YYYY")}
                couponCode={item?.resellerCode}
                appliedCount={totalOrderNumber}
                appliedAmount={totalOrderValue}
                businessname={activeBusinessInfo?.name}
              />
            }
          </Grid>
        ) : (
          statusValue === "expired" &&
          isCouponExpired && (
            <Grid item xs={12} md={3}>
              <CuoponCardDetails
                borderleftcolor={
                  isCouponExpired ? orangeStatusColor : greenStatusColor
                }
                offvalue={item?.discountPercentage}
                offerexpiry={moment(endDate).format("MMM DD YYYY")}
                couponCode={item?.resellerCode}
                appliedCount={totalOrderNumber}
                appliedAmount={totalOrderValue}
                businessname={activeBusinessInfo?.name}
              />
            </Grid>
          )
        );
        // : (
        //   <Grid item xs={12} md={12}>
        //   { statusValue === "all" || statusValue === "active" || statusValue === "expired" &&<EmptyCard
        //       icon={<CardGiftcardOutlinedIcon sx={{ fontSize: "24px" }} />}
        //       msg={`No ${
        //         statusValue.charAt(0).toUpperCase() +
        //         statusValue.slice(1)
        //       } Coupons Available.`}
        //     />}
        //   </Grid>

        // );
      })}
    </Grid>
  );
}

export default CouponDiscountDetails;

const CuoponCardDetails = ({
  borderleftcolor,
  offvalue,
  offerexpiry,
  couponCode,
  appliedCount,
  appliedAmount,
  businessname,
}) => {
  const [isShowSocialShareAlter, setIsShowSocialShareAlter] = useState(false);

  // const url = window.location.href;
  const webUrl = sessionStorage.getItem("costboWEBURL");
  return (
    <Box
      sx={{
        ...styles.couponcard,
        borderLeftColor: { xs: "none", md: borderleftcolor },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        p="10px 12px"
        borderBottom="1px dashed #dadae1"
      >
        <Box>
          <Typography variant="h5" sx={styles.cashBackEarned}>
            {offvalue}% OFF
          </Typography>
          <Typography variant="p" sx={styles.offExpiry}>
            Exp : {offerexpiry}
          </Typography>
        </Box>

        <Box>
          <Typography sx={styles.couponCodeTitle}>Coupon code:</Typography>
          <Typography sx={{ ...styles.couponCode, color: borderleftcolor }}>
            {couponCode}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          backgroundColor: { xs: greyColor052, md: BGColor03 },
          justifyContent: "space-between",
          padding: {xs:"5px 12px", md:"3px 12px"},
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            ...styles.usedCoupon,
            textAlign: "center",
          }}
        >
          Used for {appliedCount} Orders - ₹ {appliedAmount}
        </Typography>

        {borderleftcolor === greenStatusColor ? (
          <IconButton
            sx={{
              color: blackShade05,
              fontSize: "18px",
              fontWeight: 500,
              position: "absolute",
              lineHeight: 26,
              top: "-4px",
              right: "10px",
            }}
            onClick={() => {
              setIsShowSocialShareAlter(true);
              setTimeout(() => {
                setIsShowSocialShareAlter(false);
              }, 2000);
            }}
          >
            <ShareIcon />
          </IconButton>
        ) : (
          ""
        )}

        {isShowSocialShareAlter ? (
          <Box position="absolute" bottom="-50px" zIndex={10}>
            <SocialShare
              // url={"https://costbo.com"}
              url={`Special offer from ${businessname}\n\nGET additional ${offvalue}% OFF on all products\nUse coupon code ${couponCode} on checkout\nhttps://shop.costbo.com/${webUrl}`}
              // quote={`Special offer from ${businessname}\n\nGET additional ${offvalue}% OFF on all products\nUse coupon code ${couponCode} on checkout\n${url}`}
              link={`https://shop.costbo.com/${webUrl}`}
              back={() => setIsShowSocialShareAlter(false)}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

const styles = {
  couponcard: {
    backgroundColor: whiteColor,
    baxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    // padding: "15px",
    border: `1px solid ${tableHeaderColor}`,
    borderRadius: "10px",
    borderLeft: { xs: `1px solid ${tableHeaderColor}`, md: "3px solid green" },
  },

  cashBackEarned: {
    color: blackShade05,
    textAlign: "left",
    fontSize: "15px",
    fontWeight: "700",
  },
  offExpiry: {
    color: greyColor6,
    textAlign: "left",
    fontSize: "12px",
    fontWeight: "500",
  },
  couponCodeTitle: {
    color: greyColor6,
    textAlign: "right",
    fontSize: "12px",
    fontWeight: "500",
  },
  couponCode: {
    color: greenStatusColor,
    textAlign: "right",
    fontSize: "13px",
    fontWeight: "700",
  },

  usedCouponBox: {
    // backgroundColor: "rgb(5 60 81 / 7%)",
  },
  usedCoupon: {
    color: blackShade05,
    fontSize: {xs:12, md:13},
    fontWeight: "500",
    // backgroundColor: "rgb(5 60 81 / 7%)",
    // padding: "0px 12px"
    // marginBottom: "3px",
  },
};
