import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  BGColor02,
  blackShade19,
  greyColor9,
  mobilePrimary,
  redColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ErrorIcon from "@mui/icons-material/Error";
import ModalCard from "../../../components/ModalCard";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";

const MobileCategoryFilter = ({
  openModal,
  onCancel,

  ondcCategoryList,
  ondcSubCatList,

  selectedOndcCategory,
  handleOndcCategoryChange,
  selectedOndcSubCat,
  handleOndcSubCategoryChange,

  filterError,
  onOndcApplyFilter,

  categoryList,
  subCategoryList,
  selectedCategory,
  selectedSubCategory,
  handleCategoryChange,
  handleSubCategoryChange,
  onWebStoreApply,
}) => {
  const [currentPage, setCurrentPage] = useState("ondc");

  return (
    <ModalCard
      open={openModal}
      handleClose={() => {
        onCancel();
      }}
      width="40%"
    >
      <Box sx={{ backgroundColor: whiteColor, borderRadius: 1.8 }}>
        <Box
          sx={{
            backgroundColor: BGColor02,
            p: 1.5,
            borderRadius: "14.4px 14.4px 0 0",
            textAlign: "center",
          }}
        >
          <Typography
            variant="body1"
            fontSize={16}
            fontWeight={600}
            color={blackShade19}
            mb={0.3}
          >
            Filter Products by Categories
          </Typography>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={1.3}
          mt={2}
        >
          <Button
            sx={styles.filterIconBtn(currentPage === "ondc")}
            variant="contained"
            disableElevation
            onClick={() => {
              setCurrentPage("ondc");
            }}
            endIcon={<FilterListRoundedIcon sx={{ fontSize: "17px" }} />}
          >
            ONDC Category
          </Button>

          <Button
            sx={styles.filterIconBtn(currentPage === "web")}
            variant="contained"
            disableElevation
            onClick={() => {
              setCurrentPage("web");
            }}
            endIcon={<FilterListRoundedIcon sx={{ fontSize: "17px" }} />}
          >
            Webstore Category
          </Button>
        </Box>

        <Box sx={{ p: 2, mb: 1 }}>
          <Typography
            variant="h6"
            fontSize={14}
            fontWeight={600}
            color={blackShade19}
            mb={0.4}
          >
            {currentPage === "ondc" ? "ONDC Categories" : "Webstore Category"}
          </Typography>

          {currentPage === "ondc" ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SelectWithLabel
                  label="ONDC Category"
                  value={selectedOndcCategory}
                  onValueChange={handleOndcCategoryChange}
                  menuList={ondcCategoryList || []}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <SelectWithLabel
                  label="ONDC Sub Category"
                  value={selectedOndcSubCat}
                  onValueChange={handleOndcSubCategoryChange}
                  menuList={
                    selectedOndcCategory === "All" ? [] : ondcSubCatList || []
                  }
                  disabled={ondcSubCatList?.length === 0}
                  required
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SelectWithLabel
                  label="Category"
                  value={selectedCategory}
                  onValueChange={handleCategoryChange}
                  menuList={categoryList || []}
                  rounded
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <SelectWithLabel
                  label="Sub Category"
                  value={selectedSubCategory}
                  onValueChange={handleSubCategoryChange}
                  menuList={subCategoryList || []}
                  disabled={subCategoryList?.length === 0}
                  rounded
                  required
                />
              </Grid>
            </Grid>
          )}

          {filterError ? (
            <Typography
              fontSize={12}
              fontWeight={500}
              color="red"
              mt={2}
              lineHeight="18px"
            >
              {filterError}
            </Typography>
          ) : null}
        </Box>

        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          sx={{
            backgroundColor: BGColor02,
            p: 2,
            borderRadius: "0 0 14.4px 14.4px",
          }}
        >
          <Button
            variant="contained"
            sx={styles.backBtn}
            disableElevation
            onClick={() => {
              onCancel();
            }}
          >
            CANCEL
          </Button>

          <Button
            variant="contained"
            disableElevation
            sx={styles.applyBtn}
            onClick={() => {
              if (currentPage === "ondc") {
                onOndcApplyFilter();
              } else {
                onWebStoreApply();
              }
            }}
          >
            SUBMIT
          </Button>
        </Stack>
      </Box>
    </ModalCard>
  );
};

export default MobileCategoryFilter;

const ErrorText = ({ errorMsg }) => {
  if (!errorMsg) return null;

  return (
    <Typography
      variant="body1"
      fontSize={11.5}
      fontWeight={500}
      color={redColor}
      mt={0.4}
    >
      <ErrorIcon sx={{ fontSize: 13, mr: 0.3, mt: -0.3 }} /> {errorMsg}
    </Typography>
  );
};

const styles = {
  backBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: whiteColor,
    border: `1px solid ${blackShade19}`,
    color: blackShade19,
    p: "5px 20px",
    "&:hover": {
      color: blackShade19,
      backgroundColor: whiteColor,
    },
  },

  applyBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: mobilePrimary,
    border: `1px solid ${mobilePrimary}`,
    color: whiteColor,
    p: "5px 20px",
    "&:hover": {
      color: mobilePrimary,
      backgroundColor: whiteColor,
    },
  },

  filterIconBtn: (isActive) => ({
    padding: "4px 12px",
    fontSize: "11px",
    fontWeight: 600,
    color: isActive ? whiteColor : greyColor9,
    backgroundColor: isActive ? greyColor9 : whiteColor,
    whiteSpace: "nowrap",
    border: "1px solid " + greyColor9,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor9,
    },
  }),
};
