import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BusinessInfoCard from "../components/Cards/BusinessInfoCard";
import StatsInfoCard from "../components/Cards/StatsInfoCard";
import {
  BGColor02,
  blackColor,
  blackShade05,
  blueColor1,
  greyColor10,
  greyColor5,
  greyColor6,
  greyColor8,
  greyColor9,
  greyShade168,
  greyShade65,
  iconBG1,
  iconBG2,
  tableHeaderColor,
  whiteColor,
} from "../configs/styles/muiThemes";
import MuiAlert from "@mui/material/Alert";

import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import DashboardHeader from "./Dashboard/DashboardHeader";
import DashboardMenu from "./Dashboard/DashboardMenu";
import { useNavigate } from "react-router-dom";
import WebsiteIntegrationContent from "./WebSiteIntegration/WebsiteIntegrationContent";
import { getStatsCountInfoData } from "../reducers/businessDashboard/BusinessDashboardSlice";
import { addThousandsSeparator } from "../utils/helper";
import {
  getDashboardInventorySummaryApi,
  getDashboardOrderSummaryApi,
} from "../service/api_calls/BusinessDashboard/BusinessDashboardApi";

function WebsiteIntegration() {
  const [productCountSalesInfo, setProductCountSalesInfo] = useState(null);
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { activeBusinessInfo, businessProducts, statsCountInfo } = useSelector(
    (state) => state.businessPreview
  );

  const getStatsInfo = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const orderCountInfo = await getDashboardOrderSummaryApi(businessId);
    const inventoryCountInfo = await getDashboardInventorySummaryApi(
      businessId
    );

    setProductCountSalesInfo({
      orderCountInfo: orderCountInfo || null,
      inventoryCountInfo: inventoryCountInfo || null,
    });
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      getStatsInfo();
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      if (!statsCountInfo) {
        dispatch(getStatsCountInfoData(businessId));
      }
    }
    return () => {};
  }, []);

  return (
    <Box sx={styles.container}>
      {matches ? (
        <Box sx={styles.menuStickyLeft}>
          <DashboardMenu activeMenu="websiteIntegration" />
        </Box>
      ) : null}
      <Box flex={1}>
        <Box sx={styles.stickyTop}>
          <DashboardHeader />
        </Box>
        {/* {JSON.stringify(productsList?.[0])} */}

        <Box sx={styles.content}>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent="space-between"
          ></Box>
          <WebsiteIntegrationContent activeBusinessInfo={activeBusinessInfo} />
          <Box></Box>
        </Box>
      </Box>
    </Box>
  );
}

export default WebsiteIntegration;

const styles = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    // gap: { xs: "0px", md: "15px" },
    p: { xs: "0px", md: "15px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  bodySticky: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "20px" },
    p: { xs: "0px", md: "0 " },
    mx: { xs: "15px", md: "0px" },
    mt: { xs: "15px", md: "15px" },
    position: "-webkit-sticky",
    position: "sticky",
    top: "70px",
    backgroundColor: BGColor02,
    zIndex: 5,
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  statsCard: {
    width: { xs: "100%", md: "auto" },
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    gap: { xs: "20px", md: "35px" },
    backgroundColor: whiteColor,
    padding: { xs: "10px 16px", md: "8px 16px" },
    borderRadius: { xs: "8px", md: "12px" },
    border: `1px solid ${greyColor10}`,
    m: { xs: "12px 0 12px 0", md: "0" },
  },

  addIconBtn: {
    backgroundColor: "transparent",
    padding: "6px 22px",
    fontSize: "13px",
    fontWeight: 600,
    color: greyColor8,
    backgroundColor: whiteColor,
    border: "1.5px solid " + greyColor8,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },
  fillBtn: {
    backgroundColor: greyColor8,
    padding: "6px 22px",
    fontSize: "13px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + greyColor8,
    "&:hover": {
      color: greyColor8,
      backgroundColor: whiteColor,
    },
  },

  addIconBtnSmall: {
    backgroundColor: "transparent",
    fontSize: "13px",
    fontWeight: 600,
    color: greyColor8,
    backgroundColor: whiteColor,
    borderRadius: "3px",
    border: "1.5px solid " + greyColor8,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
