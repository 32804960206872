import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade19,
  border25,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import InputPreview from "../../../components/Input/InputPreview";
import { findLabelBySelectedValue } from "../../../utils/helper";

const OndcInfoPreview = ({
  ondcEnabled,
  timeToShip,
  timeUnit,
  cod,
  fragile,
  returnable,
  returnPeriod,
  sellerPickup,
  cancellable,
  refundEligible,
  cancellationFee,
  dynamicSpecs,
  productCode,
  productCodeValue,
  unitOfMeasure,
  unitOfMeasureValue,
}) => {
  return (
    <Box sx={styles.card}>
      <Box pb={1.2} mb={0}>
        <Typography sx={styles.title}>ONDC Controls</Typography>
      </Box>

      {!ondcEnabled ? (
        <Typography fontWeight={500} fontSize={13}>
          Not Enabled
        </Typography>
      ) : (
        <>
          <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
            <Grid item xs={6} md={2}>
              <InputPreview
                label="Product Code"
                value={productCode}
                hideBorder
                valueStyles={{ textTransform: "capitalize" }}
              />
            </Grid>

            <Grid item xs={6} md={2}>
              <InputPreview
                label="Product Code Value"
                value={productCodeValue}
                hideBorder
                isReadMoreLess
                valueStyles={{ textTransform: "capitalize" }}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <InputPreview
                label="Unit of Measure (UOM)"
                value={unitOfMeasure}
                hideBorder
                valueStyles={{ textTransform: "capitalize" }}
              />
            </Grid>

            <Grid item xs={6} md={5}>
              <InputPreview
                label="UOM Value"
                value={unitOfMeasureValue}
                hideBorder
                isReadMoreLess
                valueStyles={{ textTransform: "capitalize" }}
              />
            </Grid>

            <Grid item xs={6} md={2}>
              <InputPreview
                label="Time to Ship"
                value={timeToShip}
                hideBorder
                valueStyles={{ textTransform: "capitalize" }}
              />
            </Grid>

            <Grid item xs={6} md={2}>
              <InputPreview
                label="Time"
                value={timeUnit}
                hideBorder
                valueStyles={{ textTransform: "capitalize" }}
              />
            </Grid>

            <Grid item xs={6} md={2}>
              <InputPreview
                label="Cash on Delivery"
                value={cod}
                hideBorder
                valueStyles={{ textTransform: "capitalize" }}
              />
            </Grid>

            <Grid item xs={6} md={5}>
              <InputPreview
                label="Fragile"
                value={fragile}
                hideBorder
                valueStyles={{ textTransform: "capitalize" }}
              />
            </Grid>

            <Grid item xs={6} md={2}>
              <InputPreview
                label="Returnable"
                value={returnable}
                hideBorder
                valueStyles={{ textTransform: "capitalize" }}
              />
            </Grid>

            <Grid item xs={6} md={2}>
              <InputPreview
                label="Return Period (In Date)"
                value={returnPeriod}
                hideBorder
                valueStyles={{ textTransform: "capitalize" }}
              />
            </Grid>

            <Grid item xs={12} md={7}>
              <InputPreview
                label="Seller Pickup"
                value={sellerPickup}
                hideBorder
                valueStyles={{ textTransform: "capitalize" }}
              />
            </Grid>

            <Grid item xs={6} md={2}>
              <InputPreview
                label="Cancellable"
                value={cancellable}
                hideBorder
                valueStyles={{ textTransform: "capitalize" }}
              />
            </Grid>

            <Grid item xs={6} md={2}>
              <InputPreview
                label="Refund Eligible"
                value={refundEligible}
                hideBorder
                valueStyles={{ textTransform: "capitalize" }}
              />
            </Grid>

            <Grid item xs={12} md={7}>
              <InputPreview
                label="Cancellation Fee %"
                value={cancellationFee}
                hideBorder
                valueStyles={{ textTransform: "capitalize" }}
              />
            </Grid>
          </Grid>

          <Box py={1.2} mb={0}>
            <Typography sx={styles.title}>Product Specifications</Typography>
          </Box>

          <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
            {dynamicSpecs?.map((item, index) => {
              if (item?.display === "N") return null;
              if (!item?.type) return null;
              if (item?.type === "default") {
                return (
                  <Grid item xs={12} md={4} key={`Dymanic_spec_${index}`}>
                    <InputPreview
                      label={item?.displayName}
                      value={item?.values?.[0]
                        ?.toString()
                        ?.replace(/_/g, " ")
                        ?.replace(/,/g, ", ")}
                      hideBorder
                      valueStyles={{ textTransform: "capitalize" }}
                    />
                  </Grid>
                );
              }
              if (item?.type === "radioButton") {
                return (
                  <Grid item xs={12} md={4} key={`Dymanic_spec_${index}`}>
                    <InputPreview
                      label={item?.displayName}
                      value={findLabelBySelectedValue(item?.radioOptions)}
                      hideBorder
                      valueStyles={{ textTransform: "capitalize" }}
                    />
                  </Grid>
                );
              }
              return (
                <Grid item xs={12} md={4} key={`Dymanic_spec_${index}`}>
                  <InputPreview
                    label={item?.displayName}
                    value={item?.inputValue
                      ?.toString()
                      ?.replace(/_/g, " ")
                      ?.replace(/,/g, ", ")}
                    hideBorder
                    valueStyles={{ textTransform: "capitalize" }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default OndcInfoPreview;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    border: border25,
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: "15px 15px",
    mb: 2,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    color: blackShade19,
  },
};
