import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllAffiliatePartnersApi, getAllAffiliatePartnersDataApi, getAllAffiliateTotalOrdersApi, getAllTopAffiliatesApi } from "../../service/api_calls/AffiliatePanels/AffiliateAPI";
import { getAllBusinessProfileIDApi } from "../../service/api_calls/preferredDiscounts/PreferredDiscountsApi";


const initialState = {
  affiliatePanel: {
    details: [],
    profileInfo: [],
  },
  affiliatePanelPageNo: 0,
  affiliatePanelLoader: false,
  affiliatePartner: {
    details: [],
    profileInfo: [],
  },
  affiliatePartnerPageNo: 0,
  affiliatePartnerLoader: false,
  topAffiiliates: null,
  topAffiliatesLoader: false,
};


export const getAllAffiliatePanelData = createAsyncThunk(
  "getAllAffiliatePanelData",
  async (param, thunkAPI) => {
    const res = await getAllAffiliateTotalOrdersApi(param);
    const arrayData = Object.values(res.details)
    console.log("affilate_Total_orders", arrayData);
    const profileId = arrayData?.map((item, index) => item?.profileId);
    console.log(profileId);

    const uniqueprofileIds = [];

    for (const item of profileId) {
      if (!uniqueprofileIds.includes(item)) {
        uniqueprofileIds.push(item);
      }
    }
    console.log("uniqueprofileIds:", uniqueprofileIds);

    let profileParams = "?";
    uniqueprofileIds.forEach((element, index) => {
      profileParams =
        profileParams +
        (index === uniqueprofileIds.length - 1
          ? `params=${element}`
          : `params=${element}&`);
    });
    console.log("profileParams:", profileParams);

    const profileInfo = await getAllBusinessProfileIDApi({
      ...param,
      profileParams,
    });
    console.log("ProfileInfo:", profileInfo);

    return {
      affiliatePanel: arrayData,
      profileInfo,
    };
  }
);

export const getAllAffiliatePartnerData = createAsyncThunk(
  "getAllAffiliatePartnerData",
  async (param, thunkAPI) => {
    const res = await getAllAffiliatePartnersApi(param);
    // const data = await getAllAffiliatePartnersDataApi(param);
    console.log("affilate_Partners", res);
    // console.log("affilate_Partners_data", data);
    const key1 = Object.keys(res.details);
    console.log(key1);
    const profileId = key1?.map((item, index) => item);
    console.log(profileId);

    const uniqueprofileIds = [];

    for (const item of profileId) {
      if (!uniqueprofileIds.includes(item)) {
        uniqueprofileIds.push(item);
      }
    }
    console.log("uniqueprofileIds:", uniqueprofileIds);

    let profileParams = "?";
    uniqueprofileIds.forEach((element, index) => {
      profileParams =
        profileParams +
        (index === uniqueprofileIds.length - 1
          ? `params=${element}`
          : `params=${element}&`);
    });
    console.log("profileParams:", profileParams);

    const profileInfo = await getAllBusinessProfileIDApi({
      ...param,
      profileParams,
    });
    console.log("ProfileInfo:", profileInfo);

    return {
      affiliatePartner: res,
      profileInfo,
    };
  }
);


export const getAllTopAffiliatesData = createAsyncThunk(
  "getAllTopAffiliatesData",
  async (param, thunkAPI) => {
    const res = await getAllTopAffiliatesApi(param);
    console.log("Top_Affiliates", res.data);
    const data = Object.values(res.data);
    console.log("filtered", data);
    const profileId = data.map((item, index) => item?.affiliateId);
    console.log(profileId);

    const uniqueprofileIds = [];

    for (const item of profileId) {
      if (!uniqueprofileIds.includes(item)) {
        uniqueprofileIds.push(item);
      }
    }
    console.log("uniqueprofileIds:", uniqueprofileIds);

    let profileParams = "?";
    uniqueprofileIds.forEach((element, index) => {
      profileParams =
        profileParams +
        (index === uniqueprofileIds.length - 1
          ? `params=${element}`
          : `params=${element}&`);
    });
    console.log("profileParams:", profileParams);

    const profileInfo = await getAllBusinessProfileIDApi({
      ...param,
      profileParams,
    });
    console.log("ProfileInfo:", profileInfo);

    return {
      details: data,
      profileInfo: profileInfo?.accountList,
    };
  }
);


export const affiliatePanel = createSlice({
  name: "AffiliatePanel",
  initialState,
  reducers: {
    clearAffiliatePanelInfo: (state, action) => {
      state.affiliatePanel = { details: [], profileInfo: [] };
      state.affiliatePanelPageNo = 0;
      state.affiliatePartner = { details: [], profileInfo: [] };
      state.affiliatePartnerPageNo = 0;
      state.topAffiiliates = { details: [], profileInfo: [] };
    },
  },
  extraReducers: {
    //Top Affilate
    [getAllTopAffiliatesData.pending]: (state) => {
      state.topAffiliatesLoader = true;
    },
    [getAllTopAffiliatesData.fulfilled]: (state, { payload }) => {
      // const topAffiiliateData = payload.topAffiliates || [];
      console.log("PAYLOAD", payload);
      // const profileInfo = payload?.profileInfo?.accountList || [];
      // console.log("top_afiiliate_Data", topAffiiliateData);
      // console.log("profileInfo", profileInfo);
      // // if (topAffiiliateData.length > 0){
      //   state.topAffiliates = {
      //     details: [...state.topAffiliates?.details, ...topAffiiliateData],
      //     profileInfo: [...state.topAffiliates.profileInfo, ...profileInfo],
      //   };
      // // } 
      state.topAffiiliates=payload || null;
      state.topAffiliatesLoader = false;
    },
    [getAllTopAffiliatesData.rejected]: (state, { payload }) => {
      state.topAffiliatesLoader = false;
    },

    // affilate orders
    [getAllAffiliatePanelData.pending]: (state) => {
      state.affiliatePanelLoader = true;
    },
    [getAllAffiliatePanelData.fulfilled]: (state, { payload }) => {
      const affiiliateData = payload?.affiliatePanel || [];
      console.log("payload_DATA", payload);
      const profileInfo = payload?.profileInfo?.accountList || [];
      console.log("afiiliate_Data", affiiliateData);
      console.log("profileInfo", profileInfo);
      if (affiiliateData.length > 0) {
        state.affiliatePanel = {
          details: [...state.affiliatePanel?.details, ...affiiliateData],
          profileInfo: [...state.affiliatePanel.profileInfo, ...profileInfo],
        };
        state.affiliatePanelPageNo = state.affiliatePanelPageNo + 1;
      }
      state.affiliatePanelLoader = false;
    },
    [getAllAffiliatePanelData.rejected]: (state, { payload }) => {
      state.affiliatePanelLoader = false;
    },

    // affilate parteners
    [getAllAffiliatePartnerData.pending]: (state) => {
      state.affiliatePartnerLoader = true;
    },
    [getAllAffiliatePartnerData.fulfilled]: (state, { payload }) => {
      const affiiliatePartnerData = payload?.affiliatePartner;
      const profileInfo = payload?.profileInfo?.accountList || [];
      const affiiliatePartnerDataDetais = affiiliatePartnerData.details ;
      console.log("affiiliatePartnerData", affiiliatePartnerData.details);

      const dataArray = [];

      for (const key in affiiliatePartnerDataDetais) {
        if (affiiliatePartnerDataDetais.hasOwnProperty(key)) {
          const obj = affiiliatePartnerDataDetais[key];
          obj.profileId = key;
          dataArray.push(obj);
        }
      }
      console.log(dataArray);

      if (affiiliatePartnerData.totalCount > 0) {
        state.affiliatePartner = {
          // ...affiiliatePartnerData,
          details: [...state.affiliatePartner?.details, ...dataArray],
          profileInfo: [...state.affiliatePartner.profileInfo, ...profileInfo],
        };
        state.affiliatePartnerPageNo = state.affiliatePartnerPageNo + 1;
      }
      state.affiliatePartnerLoader = false;
    },
    [getAllAffiliatePartnerData.rejected]: (state, { payload }) => {
      state.affiliatePartnerLoader = false;
    },
  },
});

export const { clearAffiliatePanelInfo } = affiliatePanel.actions;

export const affiliatePanelReducer = affiliatePanel.reducer;
