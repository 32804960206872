import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import InputPreview from "../../../components/Input/InputPreview";
import {
  blackColor,
  greyColor22,
  greyColor6,
  greyColor7,
  greyShade07,
  labelColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";

function DeliveryMethodsPreviewCard({
  costBoDelivery,
  costBoHyperlocal,
  isCOD,
  cod,
  fullfillment,
  fullfillmentPricing,
  b2bDelivery,
  storePickup,
}) {
  return (
    <Box sx={styles.section}>
      <Box m="5px 0 0 !important">
        <Typography
          component="h5"
          fontSize={15}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          display={{ md: "inline", xs: "block" }}
        >
          Delivery Methods
        </Typography>

        <Typography
          component="span"
          fontSize={12.5}
          color={greyShade07}
          fontWeight={400}
          display={{ md: "inline", xs: "block" }}
          ml={{ md: "6px !important", xs: 0 }}
          my={{ md: "0", xs: "5px" }}
        >
          (Automated Pan India Delivery / Fulfillment by Business either one is mandatory)
        </Typography>
      </Box>

      <Typography
        component="span"
        fontSize={12.5}
        color={greyShade07}
        fontWeight={400}
      >
        (For All India shipment enter 'To Distance (in KM)' as 4000)
      </Typography>

      <Typography
        variant="body1"
        color={labelColor}
        fontSize={14.5}
        fontWeight={600}
        sx={{ whiteSpace: { md: "nowrap", xs: "normal" } }}
        m="15px 0 12px !important"
      >
        Automated Pan India Delivery
        <span
          style={{
            color: greyColor6,
            fontSize: 12,
            fontWeight: 400,
            marginLeft: "6px",
          }}
        >
          (Fully automated with tracking)
        </span>
      </Typography>

      <Grid
        container
        rowSpacing={{ xs: 2, md: 2 }}
        columnSpacing={{ md: 3, xs: 1.5 }}
      >
        <Grid item xs={6} md={3}>
          <InputPreview
            label="From Distance (in KM)"
            value={costBoDelivery?.from}
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <InputPreview
            label="To Distance (in KM)"
            value={costBoDelivery?.to}
          />
        </Grid>
      </Grid>

      <Typography
        variant="body1"
        color={labelColor}
        fontSize={15}
        fontWeight={600}
        sx={{ whiteSpace: { md: "nowrap", xs: "normal" } }}
        m="18px 0 12px !important"
      >
        Cash on Delivery (COD)
        <span
          style={{
            color: greyColor6,
            fontSize: 12.5,
            fontWeight: 400,
            marginLeft: "6px",
          }}
        >
          (Applicable only for Automated Pan India Delivery)
        </span>
      </Typography>

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        <Grid item xs={12} md={3}>
          <InputPreview label="COD Order Amount Max Limit" value={cod?.limit} />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputPreview
            label="COD fee charged to"
            value={
              cod?.chargeTo === "Select Cash on delivery fee charged to"
                ? ""
                : cod?.chargeTo
            }
          />
        </Grid>
      </Grid>

      <Typography
        variant="body1"
        fontSize={12.5}
        color={greyColor7}
        fontWeight={400}
        mt="6px !important"
      >
        Additional Fee for COD - Amount ₹ 45 or Percentage 3% (of total order
        amount), whichever higher will be charged
      </Typography>

      <Typography
        variant="body1"
        color={labelColor}
        fontSize={14.5}
        fontWeight={600}
        sx={{ whiteSpace: { md: "nowrap", xs: "normal" } }}
        m="18px 0 12px !important"
      >
        Automated Hyperlocal
        <span
          style={{
            color: greyColor6,
            fontSize: 12,
            fontWeight: 400,
            marginLeft: "6px",
          }}
        >
          (Fully automated with tracking)
        </span>
      </Typography>

      <Grid
        container
        rowSpacing={{ xs: 2, md: 2 }}
        columnSpacing={{ md: 3, xs: 1.5 }}
      >
        <Grid item xs={6} md={3}>
          <InputPreview
            label="From Distance (in KM)"
            value={costBoHyperlocal?.from}
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <InputPreview
            label="To Distance (in KM)"
            value={costBoHyperlocal?.to}
          />
        </Grid>
      </Grid>

      <Typography
        variant="body1"
        color={labelColor}
        fontSize={14.5}
        fontWeight={600}
        sx={{ whiteSpace: { md: "nowrap", xs: "normal" } }}
        m="18px 0 12px !important"
      >
        Fulfillment by Business
      </Typography>

      <Grid
        container
        rowSpacing={{ xs: 2, md: 2 }}
        columnSpacing={{ md: 3, xs: 1.5 }}
      >
        <Grid item xs={6} md={3}>
          <InputPreview
            label="From Distance (in KM)"
            value={fullfillment?.from}
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <InputPreview label="To Distance (in KM)" value={fullfillment?.to} />
        </Grid>

        <Grid item xs={12} md={6.1}>
          <InputPreview
            label="Pricing"
            value={
              fullfillmentPricing?.length > 0
                ? fullfillmentPricing
                    ?.map(
                      (item) =>
                        `₹${item?.txnAmount} - ₹${item?.txnAmount2} Delivery Price ₹${item?.byFor}`
                    )
                    ?.join(", ")
                : ""
            }
          />
        </Grid>
      </Grid>

      <Typography
        variant="body1"
        color={labelColor}
        fontSize={14.5}
        fontWeight={600}
        sx={{ whiteSpace: { md: "nowrap", xs: "normal" } }}
        m="18px 0 0 0 !important"
      >
        B2B Delivery
        <span
          style={{
            color: greyColor6,
            fontSize: 12,
            fontWeight: 400,
            marginLeft: "6px",
          }}
        >
          (Heavy Shipment)
        </span>
      </Typography>

      <Typography
        variant="body1"
        color={greyColor22}
        fontSize={12}
        fontWeight={500}
        m="0 0 12px !important"
      >
        from 25Kg to 5000Kg
      </Typography>

      <Grid
        container
        rowSpacing={{ xs: 2, md: 2 }}
        columnSpacing={{ md: 3, xs: 1.5 }}
      >
        <Grid item xs={6} md={3}>
          <InputPreview
            label="From Distance (in KM)"
            value={b2bDelivery?.from}
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <InputPreview label="To Distance (in KM)" value={b2bDelivery?.to} />
        </Grid>
      </Grid>

      <Typography
        variant="body1"
        color={labelColor}
        fontSize={14.5}
        fontWeight={600}
        sx={{ whiteSpace: { md: "nowrap", xs: "normal" } }}
        m="18px 0 12px 0 !important"
      >
        Store Pickup
      </Typography>

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        <Grid item xs={12} md={3}>
          <InputPreview
            label="Discount Type"
            value={
              storePickup?.discountBy === "Select Discount Type"
                ? ""
                : storePickup?.discountBy
            }
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputPreview
            label="Discount Amount"
            value={
              storePickup?.discountAmount
                ? `₹ ${storePickup?.discountAmount}`
                : storePickup?.discountAmount
            }
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputPreview
            label="Discount Percentage"
            value={storePickup?.discountPercentage}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default DeliveryMethodsPreviewCard;

const styles = {
  section: {
    p: "10px 20px 20px",
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: "10px",
    mt: { xs: "10px", md: "20px" },
  },
};
