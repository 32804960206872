import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";

import { stickyHeader, scrollBox } from "../../../utils/styles";
import {
  COMPLETED_RETURN_ORDER_TABLE_COL,
  RETURN_ORDER_TABLE_COL,
} from "../../../utils/data";
import {
  blackShade23,
  orderCardBorder,
  orderCardHeadingBorder2,
  shadeGrey01,
} from "../../../configs/styles/muiThemes";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNewReturnOrderInfo,
  getCompletedReturnOrderData,
  getNewReturnOrderData,
} from "../../../reducers/manageReturns/manageReturnsSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import ReturnOrderCard from "../Cards/ReturnOrderCard";
import moment from "moment";
import CompletedReturnOrderCard from "../Cards/CompletedReturnOrderCard";
import MobileReturnsCard from "../Cards/MobileReturnsCard";
import { getOrderType } from "../../../utils/helper";

const CompletedReturnOrdersContent = ({
  onViewOrderClick,
  getAllReturnQty,
  getOndcBapNameBySubscriberId,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const PAGE_SIZE = 20;

  const dispatch = useDispatch();
  const {
    completedReturnOrders,
    completedReturnPageNo,
    completedReturnOrdersLoader,
  } = useSelector((state) => state.manageReturns);

  const [hasMoreData, setHasMoreData] = useState(true);

  const getOrdersData = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      getCompletedReturnOrderData({
        businessId,
        pageNo: completedReturnPageNo,
      })
    );
  };

  useEffect(() => {
    if (
      (completedReturnPageNo - 1) * PAGE_SIZE >
      completedReturnOrders?.length
    ) {
      setHasMoreData(false);
    }

    console.log(
      "(completedReturnPageNo - 1) * PAGE_SIZE:",
      (completedReturnPageNo - 1) * PAGE_SIZE
    );
    console.log(
      "completedReturnOrders?.length:",
      completedReturnOrders?.length
    );
    console.log(
      "setHasMoreData:",
      (completedReturnPageNo - 1) * PAGE_SIZE > completedReturnOrders?.length
    );

    return () => {};
  }, [completedReturnPageNo]);

  return (
    <Box>
      {!completedReturnOrdersLoader ? (
        <>
          <Box
            sx={{
              ...stickyHeader,
              pb: { md: 0, xs: 0 },
              top: 205,
              display: { md: "block", xs: "none" },
            }}
          >
            <Box sx={styles.infoHeader}>
              <Grid container spacing={2}>
                {COMPLETED_RETURN_ORDER_TABLE_COL.map((item, index) => (
                  <Grid item xs={6} md={item.col} key={item.id}>
                    <Typography
                      variant="h5"
                      sx={{
                        ...styles.colName,
                        borderRight:
                          RETURN_ORDER_TABLE_COL.length === index + 1
                            ? "none"
                            : orderCardHeadingBorder2,
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>

          {completedReturnOrders?.length > 0 && (
            <>
              <InfiniteScroll
                dataLength={completedReturnOrders?.length}
                next={getOrdersData}
                hasMore={hasMoreData}
                loader={
                  <Box>
                    <ThreeDotLoader />
                  </Box>
                }
              >
                {completedReturnOrders?.map((item) =>
                  matches ? (
                    <CompletedReturnOrderCard
                      getOndcBapNameBySubscriberId={
                        getOndcBapNameBySubscriberId
                      }
                      orderType={getOrderType(item)}
                      item={item}
                      key={`order_${item?.orderId}`}
                      id={item?.orderId}
                      date={moment(item?.orderCreatedOn)?.format("DD MMM YYYY")}
                      ondcID={item?.ondcInfo?.bapOrderId}
                      orderCost={item?.orderTotalCost}
                      cartCount={item?.totalQuantity}
                      refundTotalAmt={
                        Number(
                          item?.returnOrderInfo?.refundTotalAmount
                        )?.toFixed(2) || "-"
                      }
                      returnQty={getAllReturnQty(
                        item?.returnOrderInfo?.partialProducts || []
                      )}
                      requestedDate={
                        item?.returnOrderInfo?.createdOn
                          ? moment(item?.returnOrderInfo?.createdOn).format(
                              "DD MMM, hh:mm A"
                            )
                          : ""
                      }
                      completedDate={
                        item?.returnOrderInfo?.updatedOn
                          ? moment(item?.returnOrderInfo?.updatedOn).format(
                              "DD MMM, hh:mm A"
                            )
                          : "N/A"
                      }
                      handleViewOrder={() => onViewOrderClick(item)}
                    />
                  ) : (
                    <MobileReturnsCard
                      key={`order_${item?.orderId}`}
                      id={item?.orderId}
                      date={moment(item?.orderCreatedOn)?.format("DD MMM YYYY")}
                      ondcID={item?.ondcInfo?.bapOrderId}
                      orderCost={item?.orderTotalCost}
                      cartCount={item?.totalQuantity}
                      refundTotalAmt={
                        Number(
                          item?.returnOrderInfo?.refundTotalAmount
                        )?.toFixed(2) || "-"
                      }
                      returnQty={getAllReturnQty(
                        item?.returnOrderInfo?.partialProducts || []
                      )}
                      requestedDate={
                        item?.returnOrderInfo?.createdOn
                          ? moment(item?.returnOrderInfo?.createdOn).format(
                              "DD MMM, hh:mm A"
                            )
                          : ""
                      }
                      completedDate={
                        item?.returnOrderInfo?.updatedOn
                          ? moment(item?.returnOrderInfo?.updatedOn).format(
                              "DD MMM, hh:mm A"
                            )
                          : "N/A"
                      }
                      handleViewOrder={() => onViewOrderClick(item)}
                      isCompleted
                    />
                  )
                )}
              </InfiniteScroll>
            </>
          )}
        </>
      ) : (
        <ThreeDotLoader />
      )}
    </Box>
  );
};

export default CompletedReturnOrdersContent;

const styles = {
  infoHeader: {
    // display: {md:"flex",xs:"none"},
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "16px", md: "20px" },
    backgroundColor: shadeGrey01,
    borderTop: orderCardBorder,
    borderRadius: "7px 7px 0 0",
    boxShadow: "0 2px 4px rgb(179 188 198 / 5%)",
    p: "0 10px",
  },

  colName: {
    fontSize: 12.5,
    fontWeight: 500,
    color: blackShade23,
    ml: { md: 1, xs: 0 },
    borderRight: { xs: "none", md: orderCardHeadingBorder2 },
    padding: "8px 0",
  },
};
