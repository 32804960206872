import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import InfoCardHeader from "./InfoCardHeader";
import {
  blackShade05,
  blueColor02,
  blueColor04,
  logoImgborder1,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import LabelValueText from "./LabelValueText";
import { addThousandsSeparator } from "../../../utils/helper";

function AffiliatesProgramInfoCard({
  affiliateSaleInfo,
  uniqueAffiliateCount,
  affiliateShareCount,
  onClick,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  
  return (
    <Box sx={styles.card}>
      <InfoCardHeader title="Affiliates Program" onManageClick={onClick} />

      <Box
        display="flex"
        alignItems="center"
        borderBottom="1px dashed #e2e4ea"
        pb={1}
      >
        <LabelValueText
          label="NO OF AFFILIATES"
          value={addThousandsSeparator(uniqueAffiliateCount?.uniqueAffiliateCount, true) || 0}
          valueColor={matches ? blueColor04 : blackShade05}
          borderStyle="dashed"
          labelBottom
          valueSize={14}
        />

        <LabelValueText
          label="NO OF SHARES"
          value={addThousandsSeparator(affiliateShareCount?.shareCount, true) || 0}
          hideBorder
          labelBottom
          valueSize={14}
        />
      </Box>

      <Box display="flex" alignItems="center" pb={1}>
        <LabelValueText
          label="TOTAL ORDERS"
          value={addThousandsSeparator(affiliateSaleInfo?.totalOrders, true) || 0}
          valueColor={matches ? blueColor04 : blackShade05}
          borderStyle="dashed"
          labelBottom
          valueSize={14}
        />

        <LabelValueText
          label="TOTAL SALES"
          value={`₹ ${addThousandsSeparator(affiliateSaleInfo?.totalsales) || 0}`}
          hideBorder
          labelBottom
          valueSize={14}
        />
      </Box>
    </Box>
  );
}

export default AffiliatesProgramInfoCard;

const styles = {
  card: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: {xs:'10px', md:"13px"},
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 15px", md: "15px" },
  },
};
