import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
    blackColor,
  blackShade05,
  blueColor04,
  greyColor50,
  greyColor6,
  greyColorM01,
  lightOrange01,
  lightPurple01,
  logoImgborder1,
  redColorBG01,
  skyblueColorBG01,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import useDynamicColor from "../../../hooks/useDynamicColor";
import { addThousandsSeparator } from "../../../utils/helper";
import MobileInfoCardHeader from "./MobileInfoCardHeader";
import DiscountIcon from "@mui/icons-material/Discount";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import PersonalizeEmptyCard from "../EmptyCards/PersonalizeEmptyCard";
import MobilePersonalizeEmptyCard from "../EmptyCards/MobilePersonalizeEmptyCard";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
function MobilePrefferedDiscounts({
  preferredDiscountCount,
  preferredDiscountSummary,
  onClick,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mainColor = useDynamicColor();

  const totalDiscount =
    Number(preferredDiscountSummary?.totalPreferredDiscounts) || 0;
  const activePreferredDiscount =
    Number(preferredDiscountSummary?.totalActivePreferredDiscounts) || 0;
  const totalOrdersPlaced =
    Number(preferredDiscountCount?.totalPreferredOrderNumbers) || 0;
  const totalUtilizedPreferredDiscoutns =
    Number(preferredDiscountSummary?.totalUtilizedPreferredDiscoutns) || 0;
  const totalPreferredOrderValue =
    Number(preferredDiscountCount?.totalPreferredOrderValue) || 0;
  return (
    <Box
      flex={1}
      display="flex"
      flexDirection={{ xs: "row", md: "row" }}
      alignItems={{ xs: "center", md: "center" }}
      sx={{
        ...styles.orderDateCardMobile(lightPurple01, "#FFEAEF"),
        background: lightPurple01,
        p: "0px",
      }}
      flexWrap={"wrap"}
    >
      <Box
        sx={{
          ...styles.orderDateCardMobile(whiteColor, "#D7E1FF"),
          background: whiteColor,
          p: "10px 5px",
          borderRadius: "10px 10px 0px 0px",
          width: "100%",
        }}
        flexWrap={"wrap"}
      >
        <MobileInfoCardHeader
          title={"Personalized Discounts"}
          onManageClick={() => {
            onClick();
          }}
        />
      </Box>
      {totalDiscount === 0 ? (
        <Box width={"100%"}>
          <MobilePersonalizeEmptyCard onClick={onClick} />
        </Box>
      ) : (
        <Box padding={"15px"} width={"100%"}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box
                sx={{
                  ...styles.orderDateCardMobile(whiteColor, "#FFEAEF"),
                  background: whiteColor,
                  borderRadius: "10px",
                  width: "100%",
                }}
                flexWrap={"wrap"}
              >
                <MobileStatsCard
                  label="Total Discounts"
                  value={addThousandsSeparator(totalDiscount, true)}
                  icon={
                    <DiscountIcon
                      sx={styles.icon(matches ? whiteColor : blackColor)}
                    />
                  }
                  bgColor={matches ? blueColor04 : lightPurple01}
                  valueSize={14}
                  hideMobileBorder
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  ...styles.orderDateCardMobile(whiteColor, "#FFEAEF"),
                  background: whiteColor,
                  borderRadius: "10px",
                }}
                flexWrap={"wrap"}
              >
                <MobileStatsCard
                  label="Active"
                  value={activePreferredDiscount}
                  icon={
                    <CreditScoreIcon
                      sx={styles.icon(matches ? whiteColor : blackColor)}
                    />
                  }
                  bgColor={matches ? blueColor04 : lightPurple01}
                  valueSize={14}
                  hideMobileBorder
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  ...styles.orderDateCardMobile(whiteColor, "#FFEAEF"),
                  background: whiteColor,
                  borderRadius: "10px",
                }}
                flexWrap={"wrap"}
              >
                <MobileStatsCard
                  label="Orders Placed"
                  value={addThousandsSeparator(totalOrdersPlaced, true)}
                  icon={
                    <ReceiptLongIcon
                      sx={styles.icon(matches ? whiteColor : blackColor)}
                    />
                  }
                  bgColor={matches ? blueColor04 : lightPurple01}
                  valueSize={14}
                  hideMobileBorder
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  ...styles.orderDateCardMobile(whiteColor, "#FFEAEF"),
                  background: whiteColor,
                  borderRadius: "10px",
                }}
                flexWrap={"wrap"}
              >
                <MobileStatsCard
                  label="Discounts Used"
                  value={`₹ ${addThousandsSeparator(totalPreferredOrderValue)}`}
                  icon={
                    <MoneyOffIcon
                      sx={styles.icon(matches ? whiteColor : blackColor)}
                    />
                  }
                  bgColor={matches ? blueColor04 : lightPurple01}
                  valueSize={14}
                  hideMobileBorder
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default MobilePrefferedDiscounts;

const MobileStatsCard = ({
  icon,
  label,
  value,
  color,
  bgColor,
  labelColor,
  valueSize,
  hideMobile,
  flex,
  hideMobileBorder,
}) => {
  return (
    <Box
      sx={{
        ...styles.card(flex),
        borderBottom: hideMobileBorder ? "" : `1px solid #F5F7FC`,
        p:"4px"
      }}
    >
      <Box sx={styles.iconContainer(bgColor, hideMobile)}>{icon}</Box>
      <Stack>
        <Typography
          variant="body2"
          fontSize={{ xs: 10, md: 11.28 }}
          color={labelColor || greyColorM01}
          fontWeight={500}
          lineHeight="20px"
          mb={-0.5}
        >
          {label}
        </Typography>

        <Typography
          component="h6"
          fontSize={{ xs: valueSize || 15, md: 15 }}
          color={color || blackShade05}
          fontWeight={600}
        >
          {value}
        </Typography>
      </Stack>
    </Box>
  );
};

const styles = {
  orderCard: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px", md: "12px" },
  },
  orderDateCard: {
    backgroundColor: { xs: greyColor50, md: whiteColor },
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "7px 15px", md: "12px" },
  },
  orderDateCardMobile: (color, borderColor) => ({
    backgroundColor: { xs: color ? color : skyblueColorBG01, md: whiteColor },
    border: `1px solid ${borderColor}`,
    borderRadius: { xs: "10px", md: "12px" },
    // boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "4px 6px", md: "12px" },
  }),
  orderTotalCardMobile: {
    backgroundColor: { xs: "#FFEEF2", md: whiteColor },
    border: `1px solid #FFE4EB`,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 10px", md: "12px" },
  },
  card: (flex) => ({
    display: "flex",
    alignItems: "center",
    gap: 1,
    flex: flex || 1,
  }),
  iconContainer: (bgColor, hideMobile) => ({
    width: { md: "40px", xs: "36px" },
    height: { md: "40px", xs: "36px" },
    borderRadius: "10px",
    backgroundColor: bgColor,
    display: { md: "flex", xs: hideMobile ? "none" : "flex" },
    alignItems: "center",
    justifyContent: "center",
  }),
  icon: (color) => ({ fontSize: { md: 20, xs: 17 }, color: color }),
};
