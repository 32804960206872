import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import {
  bgColor15,
  blackShade05,
  blackShade22,
  blueColor03,
  blueColor04,
  checkProductBtnColor,
  deleteBtnColor,
  greyColor11,
  greyColor24,
  greyColor33,
  greyColor7,
  greyColor9,
  heroSectionText,
  iconContainerBgColor,
  logoImgborder1,
  mobilePrimary,
  oldPriceColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import DeleteIcon from "@mui/icons-material/Delete";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";

import { textTwoLines } from "../../utils/styles";
import moment from "moment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function ProductCardMinimal({
  title,
  image,
  newPrice,
  oldPrice,
  isDiscount,
  percentageOff,
  onEditClick,
  onDeleteClick,
  onOndcClick,
  onCheckClick,
  onClick,
  handleCopyProduct,
  hideActionBtns,
  rejectedNote,
  hideCopyBtn,
  productStatus,
  disableEdit,
  isPendingProduct,
  isRejectedProduct,
  updatedOn,
  catalogStatus,
  ondcEnabled,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const getActiveInfoText = (isInPending, isInRejected) => {
    if (!isInPending && !isInRejected) return "No recent changes";
    if (isInPending) return `Update requested \n${moment(updatedOn).fromNow()}`;
    if (isInRejected)
      return `Update request rejected \n${moment(updatedOn).fromNow()}`;

    return "";
  };

  return (
    <Box sx={styles.card}>
      <Grid container spacing={{ xs: 0, md: 3 }}>
        <Grid item xs={12} md={4}>
          <Box
            display="flex"
            alignItems={{ xs: "flex-start", md: "center" }}
            gap="15px"
          >
            <Box
              component="img"
              alt="Product Image"
              src={image}
              width="48px"
              height="48px"
              sx={{
                objectFit: "contain",
                border: logoImgborder1,
                borderRadius: "7px",
              }}
              onClick={onClick}
            />

            <Box>
              <Typography
                variant="body1"
                color={greyColor24}
                fontSize={13}
                lineHeight="17px"
                fontWeight={600}
                // my={{ xs: "0px !important", md: "10px !important" }}
                sx={textTwoLines}
                onClick={onClick}
              >
                {title}
              </Typography>

              <Box display="flex" alignItems="center" gap="12px" mt={0.5}>
                <Box>
                  <Typography
                    variant="body1"
                    color={oldPriceColor}
                    fontSize={13}
                    lineHeight="15px"
                    fontWeight={400}
                    mt={{ xs: "0px !important", md: "12px !important" }}
                    sx={{ textDecoration: "line-through", display: "inline" }}
                  >
                    {oldPrice === newPrice ? "" : `₹${oldPrice}`}
                  </Typography>

                  <Typography
                    variant="body1"
                    color={{ xs: greyColor24, md: blueColor04 }}
                    fontSize={14}
                    lineHeight="15px"
                    fontWeight={600}
                    m="6px 0 0 8px !important"
                    sx={{ display: "inline", ml: "6px" }}
                  >
                    ₹{newPrice}
                  </Typography>
                </Box>

                {isDiscount ? (
                  <Box sx={styles.offTag}>
                    <Typography
                      variant="body1"
                      color={heroSectionText}
                      fontSize="10px"
                      fontWeight={600}
                      textAlign="center"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {percentageOff}% OFF
                    </Typography>
                  </Box>
                ) : null}
              </Box>

              {/* {!matches &&
                (productStatus === "pending" ||
                  productStatus === "pendingExisting") && (
                  <>
                    <Typography
                      variant="body1"
                      color={greyColor24}
                      fontSize={{ xs: 12, md: 13 }}
                      lineHeight="17px"
                      fontWeight={500}
                      m="4px 0 4px !important"
                      sx={textTwoLines}
                    >
                      {moment(updatedOn).fromNow()}
                    </Typography>

                    <Typography
                      variant="body1"
                      color={bgColor15}
                      fontSize={12}
                      lineHeight="20px"
                      fontWeight={400}
                      display={{ xs: "inline", md: "block" }}
                    >
                      {`ONDC Enabled${matches ? "" : ":"}`}
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        ...styles.ondcStatusTag,
                        color: ondcEnabled === "yes" ? "#06d26c" : "#e53935",
                        backgroundColor: {
                          xs: "transparent",
                          md: ondcEnabled === "yes" ? "#d8ffec" : "#ffebeb",
                        },
                      }}
                    >
                      {ondcEnabled === "yes" ? "Yes" : "No"}
                    </Typography>
                  </>
                )} */}
            </Box>
          </Box>
        </Grid>

        {/* {productStatus === "active" && ( */}
        {(isPendingProduct || isRejectedProduct) && (
          <Grid item xs={12} md={2.7}>
            <Box height="100%" mt={{ xs: 1.5, md: 0 }}>
              <Box
                display="flex"
                flexDirection={{ xs: "row", md: "column" }}
                alignItems={{ xs: "center", md: "flex-start" }}
                justifyContent={{ xs: "space-between", md: "flex-start" }}
                mb={{ xs: 0.5, md: 0 }}
              >
                <Typography
                  variant="body1"
                  color={greyColor24}
                  fontSize={13}
                  lineHeight="17px"
                  fontWeight={500}
                  mt={{ xs: "0px !important", md: "10px !important" }}
                  sx={textTwoLines}
                >
                  {/* {
                    getActiveInfoText(
                      isPendingProduct,
                      isRejectedProduct
                    ).split("\n")?.[0]
                  } */}{" "}
                  Update Requested
                </Typography>

                <Typography
                  variant="body1"
                  color={greyColor7}
                  fontSize={12}
                  lineHeight="17px"
                  fontWeight={400}
                  m={{ xs: "0px !important", md: "4px 0 10px !important" }}
                  sx={textTwoLines}
                >
                  {/* {
                    getActiveInfoText(
                      isPendingProduct,
                      isRejectedProduct
                    ).split("\n")?.[1]
                  } */}
                  {moment(updatedOn).fromNow()}
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
        {/* )} */}

        {/* {matches &&
          (productStatus === "pending" ||
            productStatus === "pendingExisting") && (
            <>
              <Grid item xs={5} md={4.1}>
                <Box
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box sx={{ minWidth: { xs: "auto", md: "200px" } }}>
                    <Typography
                      variant="body1"
                      color={greyColor24}
                      fontSize={{ xs: 12, md: 13 }}
                      lineHeight="17px"
                      fontWeight={500}
                      my="10px !important"
                      sx={textTwoLines}
                    >
                      {moment(updatedOn).fromNow()}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </>
          )} */}

        {/* {(productStatus === "rejected" ||
          productStatus === "rejectedExisting") && (
          <>
            <Grid item xs={12} md={4.1}>
              <Box
                height="100%"
                display="flex"
                alignItems="center"
                mb={{ xs: 1, md: 0 }}
              >
                <Typography
                  variant="body1"
                  color={greyColor24}
                  fontSize={{ xs: 12, md: 13 }}
                  lineHeight="17px"
                  fontWeight={500}
                  my={{ xs: "0px", md: "10px !important" }}
                  sx={textTwoLines}
                >
                  {rejectedNote}
                </Typography>
              </Box>
            </Grid>
          </>
        )} */}

        <Grid item xs={7} md={productStatus === "active" ? 2.5 : 2.1}>
          <Typography
            variant="body1"
            color={bgColor15}
            fontSize={12}
            lineHeight="20px"
            fontWeight={400}
            my={{ xs: 0, md: 0.7 }}
            display="inline"
          >
            {`ONDC Enabled${matches ? "" : ":"}`}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              ...styles.ondcStatusTag,
              display: "inline",
              color: ondcEnabled === "yes" ? "#27bd72" : "#e53935",
              backgroundColor: {
                xs: "transparent",
                md: ondcEnabled === "yes" ? "#d8ffec" : "#ffebeb",
              },
            }}
          >
            {ondcEnabled === "yes" ? "Yes" : "No"}
          </Typography>
        </Grid>

        <Grid item xs={12} md={productStatus === "active" ? 2.8 : 1.8}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "15px",
              mt: { xs: 0.5, md: 0 },
              mb: { xs: 1, md: 0 },
            }}
          >
            {productStatus === "active" && (
              <>
                {!isPendingProduct && !isRejectedProduct && (
                  <Button
                    onClick={onEditClick}
                    sx={{
                      ...styles.actionBtn,
                      ...styles.editBtn,
                    }}
                    disabled={disableEdit}
                  >
                    EDIT
                  </Button>
                )}

                {isPendingProduct && (
                  <Button
                    onClick={onClick}
                    sx={{
                      ...styles.actionBtn,
                      color: { xs: mobilePrimary, md: blueColor04 },
                      borderColor: blueColor04,
                    }}
                  >
                    VIEW
                  </Button>
                )}

                {isRejectedProduct && (
                  <Button
                    onClick={onCheckClick}
                    sx={{ ...styles.actionBtn, ...styles.checkBtn }}
                  >
                    CHECK
                  </Button>
                )}
              </>
            )}

            {(productStatus === "pending" ||
              productStatus === "pendingExisting") && (
              <Button
                onClick={onEditClick}
                sx={{
                  ...styles.actionBtn,
                  ...styles.editBtn,
                }}
                disabled={disableEdit}
              >
                EDIT
              </Button>
            )}

            {
              productStatus !== "rejected" &&
                productStatus !== "rejectedExisting" && (
                  // (!matches ? (
                  <IconButton
                    onClick={handleCopyProduct}
                    sx={styles.copyIconBtn}
                  >
                    <ContentCopyIcon
                      sx={{
                        fontSize: "16px",
                        color: { xs: greyColor33, md: greyColor33 },
                      }}
                    />
                  </IconButton>
                )
              // ) : (
              // <Button
              //   onClick={handleCopyProduct}
              //   sx={{ ...styles.actionBtn, ...styles.copyBtn }}
              // >
              //   COPY
              // </Button>
              // ))
            }

            {productStatus === "active" && (
              <IconButton
                onClick={onOndcClick}
                sx={styles.ondcIconBtn(isPendingProduct)}
                disabled={isPendingProduct}
              >
                <HubOutlinedIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            )}

            {productStatus === "rejected" ||
            productStatus === "rejectedExisting" ? (
              <Button
                onClick={onEditClick}
                sx={{
                  ...styles.actionBtn,
                  ...styles.editBtn,
                }}
              >
                EDIT & RESUBMIT
              </Button>
            ) : null}

            {productStatus === "active" && (
              <IconButton onClick={onDeleteClick} sx={styles.deleteBtn}>
                <DeleteIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProductCardMinimal;

const styles = {
  card: {
    padding: "12px 15px",
    background: "#fff",
    border: `1px solid ${greyColor11}`,
    borderRadius: "10px",
    cursor: "pointer",
    mb: 2,
  },

  offTag: {
    height: "20px",
    display: "flex",
    alignItems: "center",
    backgroundColor: whiteColor,
    border: `1px solid ${heroSectionText}`,
    borderRadius: "4px",
    lineHeight: "10px",
    p: "1.5px 6px",
  },

  actionBtn: {
    // minWidth: "76px",
    fontSize: 12,
    fontWeight: 600,
    padding: "3px 15px",
    textTransform: "unset",
    border: {
      xs: `1px solid ${mobilePrimary}`,
      md: `1px solid ${blueColor03}`,
    },
    "&:hover": {
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      borderColor: "rgba(0,0,0,0.2)",
    },
  },

  copyBtn: {
    backgroundColor: blueColor03,
    color: whiteColor,
    "&:hover": {
      backgroundColor: whiteColor,
      color: blueColor03,
    },
  },

  checkBtn: {
    border: `1px solid ${checkProductBtnColor}`,
    backgroundColor: whiteColor,
    color: checkProductBtnColor,
    "&:hover": {
      backgroundColor: whiteColor,
      color: checkProductBtnColor,
    },
  },

  deleteBtn: {
    width: "28px",
    height: "28px",
    borderRadius: "5px",
    border: `1px solid ${deleteBtnColor}`,
    backgroundColor: whiteColor,
    color: deleteBtnColor,
    "&:hover": {
      backgroundColor: deleteBtnColor,
      color: whiteColor,
    },
  },

  editBtn: {
    color: { xs: blackShade22, md: blueColor04 },
    borderColor: { xs: blackShade22, md: blueColor04 },
  },

  copyIconBtn: {
    width: "28px",
    height: "28px",
    borderRadius: "5px",
    border: { xs: `1px solid ${greyColor33}`, md: `1px solid ${greyColor33}` },
    backgroundColor: whiteColor,
    color: greyColor33,
    "&:hover": {
      backgroundColor: greyColor33,
      color: whiteColor,
    },
  },

  ondcIconBtn: (disabled) => ({
    width: "28px",
    height: "28px",
    borderRadius: "5px",
    border: disabled
      ? `1px solid rgba(0, 0, 0, 0.26)`
      : `1px solid ${heroSectionText}`,
    backgroundColor: whiteColor,
    color: heroSectionText,
    "&:hover": {
      backgroundColor: heroSectionText,
      color: whiteColor,
    },
  }),

  infoText: {
    color: greyColor9,
    fontSize: 12.5,
    lineHeight: "15px",
    fontWeight: 500,
  },

  ondcStatusTag: {
    width: "55px",
    display: { xs: "inline", md: "flex" },
    alignItems: "center",
    justifyContent: "center",
    fontSize: { xs: 13, md: 12 },
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "50px",
    p: { xs: "0 8px", md: "1px 13px" },
  },
};
