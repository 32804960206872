import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import ErrorToolTipIcon from "./ErrorToolTipIcon";
import {
  blueColor04,
  lightBlueColor03,
  lightRedColor,
  redColor,
  whiteColor,
  yellowColor,
} from "../../../configs/styles/muiThemes";
import EditIcon from "@mui/icons-material/Edit";
import ProductImgPreviewEdit from "./Popups/ProductImgPreviewEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const ProductImageLink = ({ value, onValueChange, errorMsg, photoIndex }) => {
  const [showEditPopup, setShowEditPopup] = useState(false);

  const handleOpenEditPopup = () => {
    setShowEditPopup(true);
  };

  return (
    <Box position="relative">
      {errorMsg && (
        <Box sx={{ position: "absolute", right: -14, top: -12, zIndex: 1 }}>
          <ErrorToolTipIcon errorMsg={errorMsg} />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-between",
          gap: 2,
          //   borderBottom: borderTextbox,
          height: { xs: "38px", md: "auto" },
        }}
      >
        {value ? (
          <Typography
            fontSize={12}
            fontWeight={500}
            color={yellowColor}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={handleOpenEditPopup}
          >
            Link
          </Typography>
        ) : (
          <IconButton sx={styles.iconBtn} onClick={handleOpenEditPopup}>
            <AddIcon sx={{ fontSize: "18px", color: blueColor04 }} />
          </IconButton>
        )}

        {value && (
          <IconButton sx={styles.iconBtn} onClick={handleOpenEditPopup}>
            <EditIcon sx={{ fontSize: "16px", color: blueColor04 }} />
          </IconButton>
        )}

        {value && (
          <IconButton
            sx={{ ...styles.iconBtn, ...styles.deleteBtn }}
            onClick={() => onValueChange("")}
          >
            <DeleteIcon sx={{ fontSize: "16px", color: redColor }} />
          </IconButton>
        )}
      </Box>

      {showEditPopup && (
        <ProductImgPreviewEdit
          openModal={showEditPopup}
          setOpenModal={setShowEditPopup}
          photoLink={value}
          photoIndex={photoIndex}
          onValueChange={onValueChange}
        />
      )}
    </Box>
  );
};

export default ProductImageLink;

const styles = {
  iconBtn: {
    width: "22px",
    height: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    // backgroundColor: lightBlueColor03,
  },

  deleteBtn: {
    // backgroundColor: lightRedColor,
  },
};
