import { Box, Grid, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackColor,
  blackShade05,
  blueColor04,
  blueColor1,
  greyColor6,
  greyColor9,
  mobilePrimary,
  searchInputIconColor,
} from "../../configs/styles/muiThemes";
import AffiliateMarketingInfocard from "./AffiliateMarketingInfocard";
import AffiliatesSales from "./AffiliatesSales";
import TopAffiliates from "./TopAffiliates";
import NumberSearchInput from "../../components/Input/NumberSearchInput";
import SearchIcon from "@mui/icons-material/Search";
import AffiliatePartnerInfoCard from "./AffiiateCards/AffiliatePartnerInfoCard";
import AffiliateOrderCard from "./AffiiateCards/AffiliateOrderCard";
import moment from "moment";
import { addThousandsSeparator } from "../../utils/helper";
import AffiliateTotalOrderCard from "./AffiliateTab/AffiliateTotalOrderCard";
import AffiliatePartenersCard from "./AffiliateTab/AffiliatePartenersCard";
import { stickyHeader } from "../../utils/styles";
import { useDispatch, useSelector } from "react-redux";
import { getAllTopAffiliatesData } from "../../reducers/AffiliatePanels/AffiliateSlice";

function AffiliateMarketingDashboardContent({
  affiliateSaleInfo,
  uniqueAffiliateCount,
  affiliateShareCount,
  profileInfo,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [tabValue, setTabValue] = useState(0);

  const [number, setNumber] = useState("");

  const stepList = [
    { id: 0, label: "TOTAL ORDERS", count: affiliateSaleInfo?.totalOrders },

    {
      id: 1,
      label: "AFFILIATE PARTNERS",
      count: uniqueAffiliateCount?.uniqueAffiliateCount,
    },
  ];

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const dispatch = useDispatch();
  const { topAffiiliates, topAffiliatesLoader } = useSelector(
    (state) => state.affiliatePanelControl
  );

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    // if (topAffiiliates) {
    dispatch(getAllTopAffiliatesData(businessId));
    // }
    return () => {
      // dispatch(clearAffiliatePanelInfo());
    };
  }, []);

  return (
    <Box>
      <Box
      // sx={{ md: { ...stickyHeader, pb: 2 }, xs: { display: "block" } }}
      >
        <Box
          display="flex"
          // alignItems="center"
          justifyContent="space-between"
          mt={{ xs: 6, md: 1 }}
          flexDirection={"column"}
          sx={{ ...stickyHeader, pb: 1 }}
          mb={{ xs: 1, md: 1 }}
        >
          <Typography
            variant="h4"
            color={blackShade05}
            fontSize={{ xs: 15, md: 17 }}
            fontWeight="600"
          >
            Affiliate Panel
          </Typography>
          <AffiliateMarketingInfocard
            affiliatePartners={uniqueAffiliateCount?.uniqueAffiliateCount}
            noOfShares={affiliateShareCount?.shareCount}
            totalOrders={affiliateSaleInfo?.totalOrders}
            totalSales={affiliateSaleInfo?.totalsales}
          />
        </Box>

        <Grid container spacing={{ xs: 1, md: 3 }}>
          {/* <Grid item xs={12} md={12}>
            <AffiliateMarketingInfocard
              affiliatePartners={uniqueAffiliateCount?.uniqueAffiliateCount}
              noOfShares={affiliateShareCount?.shareCount}
              totalOrders={affiliateSaleInfo?.totalOrders}
              totalSales={affiliateSaleInfo?.totalsales}
            />
          </Grid> */}

          <Grid item xs={12} md={5}>
            <AffiliatesSales
              affiliateSaleInfo={affiliateSaleInfo}
              totalSales={affiliateSaleInfo?.totalsales}
              commission={affiliateSaleInfo?.commissions}
              commissionPaid={affiliateSaleInfo?.commissionPaid}
              commissionPending={affiliateSaleInfo?.commissionpending}
            />
          </Grid>

          <Grid
            item
            display="flex"
            alignItems="stretch"
            justifyContent={"center"}
            flexGrow={1}
            xs={12}
            md={7}
          >
            <TopAffiliates
              topAffiiliates={topAffiiliates}
              topAffiliatesLoader={topAffiliatesLoader}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            my: "10px",
            mt: { xs: 1, md: 3 },
          }}
        >
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => handleChange(event, newValue)}
            aria-label="basic tabs example"
            textColor={blackColor}
            TabIndicatorProps={{
              style: {
                height: "3px",
                backgroundColor: matches ? blueColor04 : mobilePrimary,
              },
            }}
            sx={{
              minHeight: "35px !important",
              "& .MuiButtonBase-root": {
                minWidth: "auto !important",
              },
            }}
          >
            {stepList?.map((item, index) => {
              return (
                <Tab
                  key={"filter_" + index}
                  label={item?.label + "-" + item?.count}
                  // label={item.label}
                  sx={{
                    fontSize: { xs: "12 px", md: "13px" },
                    fontWeight: tabValue == index ? 600 : 500,
                    p: 0,
                    minHeight: "30px !important",
                    mr: { xs: "30px", md: "55px" },
                    color: {
                      xs: item.id === tabValue ? mobilePrimary : greyColor9,
                      md: item.id === tabValue ? blueColor04 : greyColor9,
                    },
                  }}
                />
              );
            })}
          </Tabs>
        </Box>

        {/* <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          // m="15px 0 10px 0"
          gap={"10px"}
        > */}
        <Box display="flex" alignItems="center" gap="10px">
          <Typography
            variant="h4"
            color={blackShade05}
            fontSize={{ xs: "15px", md: "16px" }}
            fontWeight="600"
          >
            {tabValue === 0 ? "Total Orders" : "Affiliate Panel"}
          </Typography>

          <Typography
            variant="h4"
            color={greyColor6}
            fontSize="13px"
            fontWeight="400"
          >
            -{" "}
            {tabValue === 0
              ? affiliateSaleInfo?.totalOrders
              : uniqueAffiliateCount?.uniqueAffiliateCount}
          </Typography>
        </Box>
        {/* <Box sx={{ display: { xs: "none", md: "block" } }}>
          <NumberSearchInput
            placeholder="Affiliate Phone Number"
            value={number}
            onChange={({ target }) => {
              const value = target?.value?.replace(/[^0-9]/g, "");
              setNumber(value);
            }}
            icon={
              <SearchIcon
                sx={{ fontSize: "20px", color: searchInputIconColor }}
              />
            }
            maxLength={10}
          />
        </Box> */}
        {tabValue === 0 && <AffiliateTotalOrderCard />}

        {tabValue === 1 && <AffiliatePartenersCard />}
      </Box>
    </Box>
  );
}

export default AffiliateMarketingDashboardContent;
