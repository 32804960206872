import React, { useEffect, useState } from "react";
import ModalCard from "../../../components/ModalCard";
import {
  BGColor02,
  blackShade19,
  blueColor04,
  greyColor40,
  greyColor9,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import FormInputBox from "../../../components/Input/FormInputBox";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import UpdateInfoAlert from "./UpdateInfoAlert";

const QuickPriceInfoUpdate = ({
  openModal,
  setOpenModal,
  onApply,
  gstRates,
  allProducts,
  totalCount,
}) => {
  const [inputData, setInputData] = useState({
    percentageOff: "",
    gstPercentage: "",
    validityDays: "",
  });

  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [openAlertModel, setOpenAlertModel] = useState(false);

  const handleValueChange = (name, value) => {
    setInputData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const selectedRows = [...allProducts]?.reduce(
      (accumulator, currentValue) => {
        if (currentValue?.itemChange) {
          accumulator = accumulator + 1;
        }

        return accumulator;
      },
      0
    );

    setSelectedRowCount(selectedRows);

    return () => {};
  }, []);

  return (
    <>
      <ModalCard
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        width="30%"
      >
        <Box sx={{ backgroundColor: whiteColor, borderRadius: 1.8 }}>
          <Box
            sx={{
              backgroundColor: BGColor02,
              p: 1.5,
              borderRadius: "14.4px 14.4px 0 0",
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              fontSize={16}
              fontWeight={600}
              color={blackShade19}
              mb={0.3}
            >
              Bulk Update
              <Typography
                variant="body1"
                fontSize={13}
                fontWeight={500}
                color={greyColor9}
              >
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                    color: blueColor04,
                  }}
                >
                  {selectedRowCount} of {totalCount}
                </span>{" "}
                rows selected
              </Typography>
            </Typography>
          </Box>

          <Box sx={{ p: "15px 15px 0 15px", mb: 1 }}>
            <Grid container columnSpacing={3} rowSpacing={0}>
              <Grid item xs={6}>
                <FormInputBox
                  label="% OFF"
                  value={inputData?.percentageOff}
                  onChange={({ target }) => {
                    const newValue = target?.value?.replace(/[^0-9]/g, "");
                    if (newValue <= 100 || newValue === "")
                      handleValueChange("percentageOff", newValue);
                  }}
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs={6}>
                <SelectWithLabel
                  label="GST %"
                  value={inputData?.gstPercentage?.replace("%", "") || ""}
                  onValueChange={(value) => {
                    handleValueChange("gstPercentage", value);
                  }}
                  menuList={gstRates}
                  valueSelector="value"
                  isDashboardInput
                />
              </Grid>

              <Grid item xs={12}>
                <FormInputBox
                  label="Validity Days"
                  value={inputData?.validityDays}
                  onChange={({ target }) => {
                    const newValue = target?.value?.replace(/[^0-9]/g, "");
                    if (newValue <= 1000 || newValue === "")
                      handleValueChange("validityDays", newValue);
                  }}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              backgroundColor: BGColor02,
              p: { xs: "10px 10px 10px 10px", md: "10px 16px 16px 16px" },
              borderRadius: "0 0 14.4px 14.4px",
            }}
          >
            <Typography
              fontSize={{ xs: 11.5, md: 12 }}
              fontWeight={400}
              color={greyColor40}
              lineHeight="20px"
              mb={1}
            >
              The changes above will be applied to {selectedRowCount} selected
              rows.
            </Typography>

            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={4}
            >
              <Box />
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  sx={styles.backBtn}
                  disableElevation
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  CANCEL
                </Button>

                <Button
                  variant="contained"
                  disableElevation
                  sx={styles.applyBtn}
                  onClick={() => {
                    // onApply(inputData);
                    setOpenAlertModel(true);
                  }}
                  disabled={
                    !inputData?.percentageOff &&
                    !inputData?.gstPercentage &&
                    !inputData?.minQty &&
                    !inputData?.maxQty &&
                    !inputData?.validityDays
                  }
                >
                  APPLY
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>
      </ModalCard>

      <UpdateInfoAlert
        openModal={openAlertModel}
        setOpenModal={setOpenAlertModel}
        selectedRowCount={selectedRowCount}
        onClick={() => {
          onApply(inputData);
          setOpenAlertModel(false);
        }}
      />
    </>
  );
};

export default QuickPriceInfoUpdate;

const styles = {
  backBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: whiteColor,
    border: `1px solid ${blackShade19}`,
    color: blackShade19,
    p: "5px 20px",
    "&:hover": {
      color: blackShade19,
      backgroundColor: whiteColor,
    },
  },

  applyBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: blueColor04,
    border: `1px solid ${blueColor04}`,
    color: whiteColor,
    p: "5px 20px",
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },
};
