import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import React, { useState } from "react";
import {
  BGColor02,
  blackShade05,
  borderTextbox,
  deleteBtnColor,
  descriptionColor,
  greyColor8,
  greyColor9,
  greyShade65,
  menuGradient,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ModalCard from "../../../components/ModalCard";
import { useEffect } from "react";
import { allowOnlyEnglish } from "../../../utils/helper";

function LabelValueInputPopup({
  label,
  placeholder,
  name,
  value,
  onChange,
  icon,
  setLabelValue,
  maxLength,
  inputType,
  // isError,
  error,
  helperText,
  autoFocus,
  inputRef,
  onKeyUp,
  onBlur,
  disabled,
  isDashboardInput,
}) {
  const [modal, setModal] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [arr, setArr] = useState([""]);
  const [prevArr, setPrevArr] = useState([""]);
  useEffect(() => {
    setArr(value?.length > 0 ? value : [""]);
    return () => {};
  }, [value]);

  return (
    <Box mb="8px !important">
      {/* {!hideLabel ? (
          <>
            <Typography
              variant="h3"
              fontSize={13}
              color={greyColor6}
              fontWeight={500}
              mb={"8px !important"}
              sx={{ display: "inline" }}
            >
              {label}{" "}
            </Typography>
            {required ? (
              <Typography
                variant="body1"
                sx={{ display: "inline", color: "#f00" }}
              >
                *
              </Typography>
            ) : null}
          </>
        ) : null} */}

      <Box sx={{ position: "relative" }}>
        <TextField
          variant="standard"
          required={true}
          fullWidth
          name={name}
          disabled={disabled}
          value={value?.join(", ")}
          onBlur={onBlur}
          autoFocus={autoFocus}
          onChange={onChange}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          onClick={() => setModal(true)}
          inputRef={inputRef}
          sx={{
            fontSize: 13.5,
            fontWeight: 500,
            color: blackShade05,
            borderTop: "none",
            borderBottom: borderTextbox,
            borderLeft: "none",
            borderRight: "none",
            borderRadius: "0px",
            backgroundColor: whiteColor,
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                {icon}
              </InputAdornment>
            ),
            style: {
              padding: isDashboardInput ? "7px 12px" : "4px 8px",
              color: grey,
              fontSize: 13.5,
              fontWeight: 500,
            },
            disableUnderline: true,
          }}
          inputProps={{ maxLength: maxLength }}
          type={inputType}
        />

        <Box
          sx={{
            width: "100%",
            height: "35px",
            backgroundColor: "transparent",
            position: "absolute",
            top: 0,
          }}
          onClick={() => {
            setPrevArr(arr);
            setModal(true);
          }}
        />
      </Box>

      {error ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={12}
          sx={{ mt: "4px !important", textAlign: "start !important" }}
          style={{ color: "red" }}
        >
          {error}
        </Typography>
      ) : null}

      {helperText ? (
        <Typography
          variant="body1"
          component="p"
          fontSize={11}
          color={descriptionColor}
        >
          {helperText}
        </Typography>
      ) : null}

      <ModalCard
        open={modal}
        handleClose={() => {
          setArr(prevArr);
          setModal(false);
        }}
        width="42%"
      >
        <Box
          sx={{
            backgroundColor: BGColor02,
            borderRadius: "15px",
          }}
        >
          <Box p="15px 20px">
            <Typography
              variant="h3"
              fontSize={{ xs: 16, md: 18 }}
              fontWeight={600}
              lineHeight="34px"
              color={blackShade05}
              textAlign="center"
            >
              {label || "Add Value"}
            </Typography>

            {/* <Typography
              variant="body1"
              fontSize={13}
              fontWeight={400}
              lineHeight="20px"
              color={greyColor9}
              textAlign="center"
              mt="4px"
            >
              Add New Value
            </Typography> */}
          </Box>

          <Box
            sx={{
              backgroundColor: whiteColor,
              p: "15px 20px",
              maxHeight: "70vh",
              overflowY: "auto",
            }}
          >
            <Typography
              variant="body1"
              fontSize={14}
              fontWeight={600}
              lineHeight="20px"
              color={blackShade05}
              mb="-5px"
            >
              Add Value
            </Typography>

            {arr.map((textInput, index) => (
              <Grid
                container
                display={"flex"}
                alignItems="flex-start"
                justifyContent={"flex-start"}
                columnSpacing={2}
              >
                <Grid item xs={9} md={9.7}>
                  {/* <TextField
                    variant="standard"
                    fullWidth
                    value={textInput}
                    onChange={({ target }) => {
                      if (modalError) setModalError(false);
                      let data = [...arr];
                      data[index] = target.value;
                      setArr(data);
                    }}
                    sx={{
                      mt: 2,
                      fontSize: 14.5,
                      borderTop: isDashboardInput ? borderTextbox : "none",
                      borderBottom: borderTextbox,
                      borderLeft: isDashboardInput ? borderTextbox : "none",
                      borderRight: isDashboardInput ? borderTextbox : "none",
                      borderRadius: isDashboardInput ? "6px" : "0px",
                      backgroundColor: whiteColor,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ cursor: "pointer" }}
                        >
                          {icon}
                        </InputAdornment>
                      ),
                      style: {
                        padding: isDashboardInput ? "7px 12px" : "4px 8px",
                        color: grey,
                        fontSize: 14.5,
                        fontWeight: 500,
                      },
                      disableUnderline: true,
                    }}
                    inputProps={{ maxLength: maxLength }}
                    type={inputType}
                  /> */}

                  <TextareaAutosize
                    minRows={2}
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: blackShade05,
                      display: "block",
                      width: "100%",
                      borderTop: "none",
                      borderBottom: borderTextbox,
                      borderLeft: "none",
                      borderRight: "none",
                      borderRadius: "0px",
                      backgroundColor: whiteColor,
                      padding: "10px 0",
                      whiteSpace:'pre-line'
                    }}
                    value={textInput?.replace(/\\n|\n/g, ' ')}
                    onChange={({ target }) => {
                      if (modalError) setModalError(false);
                      const finalValue = allowOnlyEnglish(target.value)
                      let data = [...arr];
                      data[index] = finalValue;
                      setArr(data);
                    }}
                  />
                </Grid>

                {index == arr?.length - 1 ? (
                  <Grid item xs={1.5} md={1.1}>
                    <IconButton
                      onClick={() => {
                        if (index >= 9) return;

                        if (textInput) {
                          setArr([...arr, ""]);
                          setModalError(false);
                        } else setModalError(true);
                      }}
                      sx={{ ...styles.iconBtn, ...styles.addBtn }}
                    >
                      <AddRoundedIcon
                        sx={{ fontSize: { xs: "20px", md: "24px" } }}
                      />
                    </IconButton>
                  </Grid>
                ) : null}

                <Grid item xs={1.5} md={1.1}>
                  <IconButton
                    onClick={() => {
                      if (index === 0) return;
                      let data = [...arr];
                      data.splice(index, 1);
                      setArr(data);
                      setModalError(false);
                    }}
                    sx={{
                      ...styles.iconBtn,
                      ...styles.deleteBtn,
                      opacity: index === 0 ? 0.3 : 1,
                    }}
                  >
                    <DeleteRoundedIcon
                      sx={{ fontSize: { xs: "20px", md: "24px" } }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            ))}

            {modalError ? (
              <Typography
                variant="subtitle1"
                component="p"
                fontSize={12}
                sx={{ mt: "4px !important", textAlign: "start !important" }}
                style={{ color: "red" }}
              >
                Please enter value
              </Typography>
            ) : null}

            <Typography
              variant="body1"
              fontSize={13}
              fontWeight={400}
              lineHeight="20px"
              color={greyShade65}
              mt="15px"
            >
              You can add upto 10 rows
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p="15px"
          >
            <Button
              sx={[styles.actionBtn, styles.cancelBtn]}
              onClick={() => {
                setArr(prevArr);
                setModal(false);
              }}
            >
              CANCEL
            </Button>

            <Button
              sx={[styles.actionBtn, styles.proceedBtn]}
              onClick={() => {
                setLabelValue(arr);
                setModalError(false);
                setModal(false);
              }}
            >
              Apply
            </Button>
          </Box>

          {/* <Box display={"flex"} justifyContent="flex-end" mt={2} gap={1}>
            <Button
              sx={styles.btn(nextBtnBG, whiteColor)}
              onClick={() => {
                setArr(prevArr);
                setModal(false);
              }}
            >
              Cancel
            </Button>

            <Button
              sx={styles.btn(saveBtnBG, saveBtnColor)}
              onClick={() => {
                setLabelValue(arr);
                setModalError(false);
                setModal(false);
              }}
            >
              Apply
            </Button>
          </Box> */}
        </Box>
      </ModalCard>
    </Box>
  );
}

export default LabelValueInputPopup;

const styles = {
  actionBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    color: greyColor8,
    border: `1px solid ${greyColor8}`,
    p: "4px 30px",
  },

  cancelBtn: {
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },

  proceedBtn: {
    color: whiteColor,
    background: menuGradient,
    p: "5px 30px",
    border: "none",

    "&:hover": {
      color: greyColor8,
      background: whiteColor,
      border: `1px solid ${greyColor8}`,
    },
  },

  iconBtn: {
    width: { xs: "32px", md: "35px" },
    height: { xs: "32px", md: "35px" },
    borderRadius: "5px",
    border: `1px solid rgb(133 147 172)`,
  },

  addBtn: {
    backgroundColor: "rgb(133 147 172)",
    color: whiteColor,
    "&:hover": {
      backgroundColor: whiteColor,
      color: "rgb(133 147 172)",
    },
  },

  deleteBtn: {
    border: `1px solid ${deleteBtnColor}`,
    backgroundColor: whiteColor,
    color: deleteBtnColor,
    "&:hover": {
      backgroundColor: deleteBtnColor,
      color: whiteColor,
    },
  },
};
