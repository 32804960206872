import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  blackShade05,
  displaySwitchGreenColor,
  displaySwitchRedColor,
  greyColor41,
  greyColor6,
  greyColor7,
  lightGreen04,
  lightRedColor02,
  whiteColor,
} from "../../configs/styles/muiThemes";
import StyledReadOnlySwitch from "../../components/Switch/StyledReadOnlySwitch";
import InputPreview from "../../components/Input/InputPreview";
import moment from "moment";
import { toTitleCase } from "../../utils/validationUtils";

const ONDCControlsPreview = ({
  ondcEnabled,
  ondcDomain,

  ondcSupportRegion,
  ondcSupportDistanceKm,
  ondcBPPFulfillments,
  ondcCustomerDiscountEnabled,

  ondcMinOrder,
  packagingCost,
  convenienceFee,
  ondcBuyerFinderFeeUpperLimitPercentage,

  ondcPartialCancellationStage,
  ondcFullCancellationStage,

  autoApprovalEnabled,

  storeDays,
  storeStartTime,
  storeEndTime,
  breakStartTime,
  breakEndTime,
  holidayStartDate,
  holidayEndDate,

  isOpen24Hr,
  storeTimeList,

  buyerLinks,
}) => {
  const getOrderStatusText = (status) => {
    switch (status) {
      case "ordercreated":
        return "Order Created";
      case "orderapproved":
        return "Order Approved";
      case "ordershipped":
        return "Order Shipped";

      default:
        return "";
    }
  };

  return (
    <>
      <Box sx={{ ...styles.section, pt: 1, mt: { xs: 2, md: 1 } }}>
        <Typography
          component="h5"
          fontSize={{ xs: 14, md: 14 }}
          // borderBottom={"1px solid " + greyColor41}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          m={{ xs: "5px 0 !important", md: "10px 0 10px !important" }}
        >
          Category & Shipments 
        </Typography>

        <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
          <Grid item xs={12} md={3.5}>
            <Typography
              fontSize={13}
              fontWeight={400}
              color={greyColor6}
              mb={{ xs: 0, md: 1 }}
            >
              ONDC Enabled{" "}
            </Typography>
            {/* <StyledReadOnlySwitch isEnabled={ondcEnabled} /> */}
            <Typography
              component="body2"
              fontSize={13}
              color={
                ondcEnabled ? displaySwitchGreenColor : displaySwitchRedColor
              }
              fontWeight={500}
              lineHeight="20px"
              // display={{ xs: "block", md: "none" }}
              sx={{
                textDecoration: "underline",
                bgcolor: ondcEnabled ? lightGreen04 : lightRedColor02,
                p: "4px 15px",
                borderRadius: "6px",
              }}
            >
              {ondcEnabled ? "Yes" : "No"}
            </Typography>
          </Grid>
        </Grid>

        <Box my={2}>
          <Typography
            fontSize={13}
            fontWeight={400}
            color={greyColor6}
            mb={{ xs: 0, md: 1 }}
          >
            ONDC Category
          </Typography>

          <Grid container rowSpacing={{ xs: 0.5, md: 2 }} columnSpacing={3}>
            {ondcDomain?.split(",")?.map((item, index) => {
              return (
                <Grid item xs={12} md={3} key={`${item}_${index}`}>
                  <ListData value={item} />
                </Grid>
              );
            })}
          </Grid>
        </Box>

        <Typography
          component="h6"
          fontSize={{ xs: 14, md: 14 }}
          // borderBottom={"1px solid " + greyColor41}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          m={{ xs: "5px 0 10px !important", md: "5px 0 15px !important" }}
        >
          Shipments & Discounts
        </Typography>

        <Grid container rowSpacing={{ xs: 1, md: 2 }} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <InputPreview
              label="Shipment Region"
              value={toTitleCase(ondcSupportRegion || "")}
              hideBorder
              styles={styles.labelStyles}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <InputPreview
              label="Shipment Distance"
              value={ondcSupportDistanceKm}
              hideBorder
              styles={styles.labelStyles}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <InputPreview
              label="Delivery Methods"
              value={ondcBPPFulfillments}
              hideBorder
              styles={styles.labelStyles}
            />
          </Grid>

          {/* <Grid item xs={12} md={3}>
            <InputPreview
              label="Customer Discount"
              value={ondcCustomerDiscountEnabled}
              hideBorder
              styles={styles.labelStyles}
            />
          </Grid> */}
        </Grid>
      </Box>

      <Box sx={styles.section}>
        <Typography
          component="h6"
          fontSize={{ xs: 14, md: 14 }}
          // borderBottom={"1px solid " + greyColor41}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          m={{ xs: "10px 0 10px !important", md: "20px 0 10px !important" }}
        >
          Pricing Controls
        </Typography>

        <Grid container rowSpacing={{ xs: 1, md: 2 }} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <InputPreview
              label="Minimum Order Amount"
              value={`₹ ${ondcMinOrder}`}
              hideBorder
              styles={styles.labelStyles}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputPreview
              label="Packing Cost"
              value={`₹ ${packagingCost}`}
              hideBorder
              styles={styles.labelStyles}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputPreview
              label="Convenience Fee"
              value={`₹ ${convenienceFee}`}
              hideBorder
              styles={styles.labelStyles}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputPreview
              label="Buyer Finder Fee Limit"
              value={ondcBuyerFinderFeeUpperLimitPercentage}
              hideBorder
              styles={styles.labelStyles}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={styles.section}>
        <Typography
          component="h6"
          fontSize={{ xs: 14, md: 14 }}
          // borderBottom={"1px solid " + greyColor41}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          m={{ xs: "10px 0 10px !important", md: "20px 0 10px !important" }}
        >
          Approval Settings
        </Typography>

        <Typography
          component="h6"
          fontSize={{ xs: 13, md: 14 }}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          m={{ xs: "10px 0 10px !important", md: "10px 0 15px !important" }}
        >
          Cancel Terms
        </Typography>

        <Grid container rowSpacing={{ xs: 1, md: 2 }} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <InputPreview
              label="Partial Cancel Stage"
              value={getOrderStatusText(ondcPartialCancellationStage)}
              hideBorder
              styles={styles.labelStyles}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputPreview
              label="Full Cancel Stage"
              value={getOrderStatusText(ondcFullCancellationStage)}
              hideBorder
              styles={styles.labelStyles}
            />
          </Grid>
        </Grid>

        <Typography
          component="h6"
          fontSize={{ xs: 13, md: 14 }}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          m={{ xs: "10px 0 10px !important", md: "5px 0 15px !important" }}
        >
          Auto Approval
        </Typography>

        <Grid container rowSpacing={{ xs: 1, md: 2 }} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <InputPreview
              label="Auto Approval"
              value={autoApprovalEnabled}
              hideBorder
              styles={styles.labelStyles}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={styles.section}>
        <Typography
          component="h6"
          fontSize={{ xs: 14, md: 14 }}
          // borderBottom={"1px solid " + greyColor41}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          m={{ xs: "10px 0 10px !important", md: "20px 0 10px !important" }}
        >
          Timings & Holiday
        </Typography>

        <Typography
          component="h6"
          fontSize={{ xs: 13, md: 14 }}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          m={{ xs: "10px 0 10px !important", md: "5px 0 10px !important" }}
        >
          Store Days
        </Typography>

        <Grid container rowSpacing={{ xs: 1, md: 2 }} columnSpacing={3}>
          {storeDays?.map((item, index) => {
            return (
              <Grid item xs={6} md={3} key={item?.label + index}>
                <InputPreview
                  key={item?.label}
                  label={item?.label}
                  value={
                    item?.isOpen
                      ? isOpen24Hr
                        ? `${moment(storeStartTime, "HH:mm").format(
                            "hh:mm A"
                          )} - ${moment(storeEndTime, "HH:mm").format(
                            "hh:mm A"
                          )}`
                        : "Open"
                      : "Closed"
                  }
                  hideBorder
                  styles={styles.labelStyles}
                />
              </Grid>
            );
          })}
        </Grid>

        <Grid container rowSpacing={{ xs: 1, md: 2 }} columnSpacing={3}>
          <Grid item xs={12} md={6}>
            <Typography
              component="h6"
              fontSize={{ xs: 13, md: 14 }}
              color={blackColor}
              fontWeight={600}
              lineHeight="20px"
              mb={1}
              mt={{ xs: 1, md: 1 }}
            >
              Store Timing
            </Typography>

            <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
              {isOpen24Hr ? (
                <>
                  <Grid item xs={6} md={4}>
                    <InputPreview
                      label="Start Time"
                      value={moment(storeStartTime, "HH:mm").format("hh:mm A")}
                      hideBorder
                      styles={styles.labelStyles}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <InputPreview
                      label="End Time"
                      value={moment(storeEndTime, "HH:mm").format("hh:mm A")}
                      hideBorder
                      styles={styles.labelStyles}
                    />
                  </Grid>{" "}
                </>
              ) : (
                storeTimeList?.map((item, index) => (
                  <>
                    <Grid item xs={6} md={4} key={"timing_" + index}>
                      <InputPreview
                        label="Start Time"
                        value={moment(item?.storeStartTime, "HH:mm").format(
                          "hh:mm A"
                        )}
                        hideBorder
                        styles={styles.labelStyles}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <InputPreview
                        label="End Time"
                        value={moment(item?.storeEndTime, "HH:mm").format(
                          "hh:mm A"
                        )}
                        hideBorder
                        styles={styles.labelStyles}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                  </>
                ))
              )}
            </Grid>
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <Typography
              component="h6"
              fontSize={{ xs: 13, md: 14 }}
              color={blackColor}
              fontWeight={600}
              lineHeight="20px"
              mb={2}
            >
              Break Timing
            </Typography>

            <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
              <Grid item xs={12} md={4}>
                <InputPreview
                  label="Start Date"
                  value={moment(breakStartTime, "HH:mm").format("hh:mm A")}
                  hideBorder
                  styles={styles.labelStyles}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputPreview
                  label="End Date"
                  value={moment(breakEndTime, "HH:mm").format("hh:mm A")}
                  hideBorder
                  styles={styles.labelStyles}
                />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>

        <Typography
          component="h6"
          fontSize={{ xs: 13, md: 14 }}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          mb={0.7}
        >
          Holiday Schedule
        </Typography>

        <Typography
          component="p"
          fontSize={{ xs: 12, md: 12.5 }}
          color={greyColor6}
          fontWeight={400}
          lineHeight="20px"
          mb={2}
        >
          Enter any planned holidays in next 3 months, when the store won't be
          operational
        </Typography>

        <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
          <Grid item xs={6} md={3}>
            <InputPreview
              label="Start Date"
              value={
                holidayStartDate
                  ? moment(holidayStartDate)?.format("DD-MM-YYYY")
                  : ""
              }
              hideBorder
              styles={styles.labelStyles}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <InputPreview
              label="End Date"
              value={
                holidayEndDate
                  ? moment(holidayEndDate)?.format("DD-MM-YYYY")
                  : ""
              }
              hideBorder
              styles={styles.labelStyles}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={styles.section}>
        <Typography
          component="h6"
          fontSize={{ xs: 14, md: 14 }}
          // borderBottom={"1px solid " + greyColor41}
          pb={0.8}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          m={{ xs: "10px 0 15px !important", md: "20px 0 15px !important" }}
        >
          Buyer App
        </Typography>

        <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
          {buyerLinks?.length === 1 &&
          !buyerLinks?.[0]?.code &&
          !buyerLinks?.[0]?.value ? (
            <Grid item xs={12} md={5}>
              <Typography
                component="h6"
                fontSize={13.5}
                color={greyColor7}
                fontWeight={500}
                lineHeight="20px"
                m="-10px 0 15px !important"
              >
                N/A
              </Typography>
            </Grid>
          ) : (
            buyerLinks?.map((buyerLink, index) => (
              <Grid item xs={12} md={12} key={index}>
                <InputPreview
                  label={buyerLink?.displayName}
                  value={buyerLink?.value}
                  hideBorder
                  styles={{
                    ...styles.labelStyles,
                    textTransform: "capitalize",
                  }}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </>
  );
};

export default ONDCControlsPreview;

const ListData = ({ value }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <Box
        sx={{
          width: { md: "6px", xs: "5px" },
          height: { md: "6px", xs: "5px" },
          borderRadius: "50%",
          backgroundColor: blackShade05,
        }}
      />
      <Typography fontSize={13} fontWeight={500} color={blackShade05}>
        {value}
      </Typography>
    </Box>
  );
};

const styles = {
  section: {
    p: "0px 20px 10px",
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: { xs: "8px", md: "13px" },
    mt: { xs: "10px", md: "0px" },
  },

  labelStyles: {
    mb: { xs: 0.7, md: 0 },
  },
};
