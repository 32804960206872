import React from "react";
import {
  blackShade19,
  blueColor04,
  greyColor24,
  greyColor27,
  mobilePrimary,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { Box, Grid, Typography } from "@mui/material";
import DragAndDropFileInput from "../../components/Input/DragAndDropFileInput";

const UploadTemplate = ({ fileDetails, setFileDetails, setOpenModal }) => {
  const handleFileSelection = (selectedFile) => {
    setFileDetails((prevState) => ({
      ...prevState,
      selectedFile: selectedFile,
      errors: null,
    }));
  };

  const handleFileError = (errorInfo) => {
    setFileDetails((prevState) => ({
      ...prevState,
      errors: { selectedFile: errorInfo },
    }));
  };

  return (
    <Box>
      <Typography sx={styles.heading}>Upload Template</Typography>

      <Grid container spacing={2}>
        <Grid item md={6}>
          <Box m="10px 0 10px">
            <DragAndDropFileInput
              file={fileDetails.selectedFile}
              setFile={(fileData) => handleFileSelection(fileData)}
              error={fileDetails?.errors?.selectedFile}
              onError={(errorMsg) => handleFileError(errorMsg)}
            />
          </Box>
        </Grid>
      </Grid>

      <Box>
        <Typography
          variant="body2"
          fontSize={{ xs: 13, md: 13 }}
          fontWeight={400}
          color={greyColor27}
          display="inline"
          lineHeight="20px"
        >
          Recommendation and guidelines{" "}
          <Typography
            variant="body2"
            fontSize={12.5}
            fontWeight={500}
            color={{ xs: mobilePrimary, md: greyColor24 }}
            lineHeight="20px"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            display="inline"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Here
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default UploadTemplate;

const styles = {
  heading: {
    fontSize: "16px",
    fontWeight: "600",
    color: blackShade19,
    mb: 1,
  },

  btn: {
    fontSize: 13,
    fontWeight: 600,
    color: blueColor04,
    backgroundColor: whiteColor,
    border: `1px solid ${blueColor04}`,
    p: "8px 20px",
    mt: 1.5,

    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },
};
