import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BGColor02 } from "../configs/styles/muiThemes";

import { useDispatch, useSelector } from "react-redux";
import {
  getActiveBusinessInfo,
  getCommerceInfo,
} from "../reducers/businessPreview/businessPreviewSlice";
import OrdersContent from "./Orders/OrdersContent";
import { getDashboardOrderSummaryApi } from "../service/api_calls/BusinessDashboard/BusinessDashboardApi";
import { getStatsCountInfoData } from "../reducers/businessDashboard/BusinessDashboardSlice";
import DashboardLayout from "../components/Layouts/DashboardLayout";

function Orders() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [orderSummaryInfo, setOrderSummaryInfo] = useState(null);

  const { activeBusinessInfo, commerceInfo } = useSelector(
    (state) => state.businessPreview
  );

  const { businessOrders, businessOrdersLoader, refreshTabData } = useSelector(
    (state) => state.businessOrders
  );

  const { statsCountInfo } = useSelector((state) => state.businessDashboard);

  const dispatch = useDispatch();

  const getOrderSummaryData = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const res = await getDashboardOrderSummaryApi(businessId);

    if (res?.details) {
      console.log("setOrderSummaryInfo hit");
      setOrderSummaryInfo(res?.details);
    }
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }

      // dispatch(getAllBusinessOrderData({ businessId }));

      getOrderSummaryData();

      if (!commerceInfo) {
        dispatch(getCommerceInfo(businessId));
      }

      // Getting Product and Sales Count
      if (!statsCountInfo) {
        dispatch(getStatsCountInfoData(businessId));
      }
    }
  }, [refreshTabData]);

  return (
    activeBusinessInfo && (
      <Box sx={styles.container}>
        <DashboardLayout activeMenu="orders">
          <OrdersContent
            orderSummaryInfo={orderSummaryInfo}
            // orderData={businessOrders?.details || []}
            loader={businessOrdersLoader}
            statsCountInfo={statsCountInfo}
          />
        </DashboardLayout>
      </Box>
    )
  );
}

export default Orders;

const styles = {
  container: {
    backgroundColor: BGColor02,
  },
};
