export const prepareBusinessWhatsappMessage = (data) => {
  const businessId = data?.businessId || "";
  const code = data?.code || "";
  const ownerName = data?.ownerName || "";
  const brandName = data?.brandName || "";
  const phoneNumber = data?.phoneNumber || "";

  const postData = {
    businessId,
    code,
    data: [
      {
        code: "brandOwner",
        value: ownerName,
      },
      {
        code: "brandName",
        value: brandName,
      },
      {
        code: "phoneNumber",
        value: phoneNumber,
      },
      {
        code: "businessURL",
        value: "https://business.costbo.com",
      },
      {
        code: "androidURL",
        value: "https://play.google.com/store/apps/details?id=com.costbo",
      },
      {
        code: "iosURL",
        value: "https://apps.apple.com/us/app/costbo/id1468091785",
      },
    ],
    toNumbers: [`${phoneNumber}`],
  };

  return postData
};
