import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  blackShade19,
  blackShade21,
  blueColor04,
  border25,
  greyColor054,
  whiteColor,
} from "../../../configs/styles/muiThemes";

const EmptyCatalogData = ({ message, goBack }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: border25,
        borderRadius: "10px",
        p: "30px 15px",
        mt: 0.5,
      }}
    >
      <Box
        sx={{
          width: "50px",
          height: "50px",
          borderRadius: "25px",
          bgcolor: greyColor054,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <UploadFileIcon sx={{ fontSize: "26px" }} />
      </Box>

      <Typography
        variant="body1"
        fontSize={15}
        fontWeight={500}
        color={blackShade19}
        mt={1.5}
      >
        {message}
      </Typography>

      <Button
        variant="contained"
        sx={styles.backBtn}
        disableElevation
        onClick={goBack}
      >
        Go To Upload Template
      </Button>
    </Box>
  );
};

export default EmptyCatalogData;

const styles = {
  backBtn: {
    fontSize: 12,
    fontWeight: 600,
    color: blueColor04,
    backgroundColor: whiteColor,
    border: `1px solid ${blueColor04}`,
    padding: "5px 30px",
    mt: 2,
    borderRadius: "5px",
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },
};
