import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import {
  blackShade19,
  border25,
  displaySwitchGreenColor,
  displaySwitchRedColor,
  greyColor6,
  lightGreen04,
  lightGreen14,
  lightRedColor02,
  whiteColor,
} from "../../../configs/styles/muiThemes";

function CategoryPreview({ categoryData }) {
  return (
    <Box sx={styles.card}>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <Box>
            <Typography
              component="h6"
              fontSize={{ xs: 12, md: 12 }}
              color={greyColor6}
              fontWeight={400}
              lineHeight="20px"
              mb={1}
            >
              Product Name
            </Typography>
            <Typography
              component="h6"
              fontSize={{ xs: 12, md: 13 }}
              color={blackShade19}
              fontWeight={500}
              lineHeight="20px"
            >
              {categoryData?.productName}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={9} xs={12}>
          <Box>
            <Typography sx={styles.title}>ONDC</Typography>
            <Box
              display={"flex"}
              gap={{ md: "100px", xs: "10px" }}
              justifyContent={"flex-start"}
              flexDirection={{ md: "row", xs: "column" }}
              mt={0.5}
            >
              <InfoCard
                label="Enabled"
                value={categoryData?.ondcEnabled === "yes" ? "Yes" : "No"}
                chip
              />
              <InfoCard
                label="Category"
                value={categoryData?.selectedOndcCat?.name}
              />
              <InfoCard
                label="Sub Category"
                value={categoryData?.selectedOndcSubCat?.productSubCategory1}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CategoryPreview;

const InfoCard = ({ label, value, chip }) => {
  return (
    <Box>
      <Box>
        <Typography
          component="h6"
          fontSize={{ xs: 12, md: 12 }}
          color={greyColor6}
          fontWeight={400}
          lineHeight="20px"
        >
          {label}
        </Typography>
      </Box>
      <Box mt={0.5}>
        {chip ? (
          <Typography
            component="h6"
            sx={{
              bgcolor:
                value?.toLowerCase() == "yes" ? lightGreen14 : lightRedColor02,
              p: "2px 15px",
              borderRadius: "50px",
              cursor: "pointer",
              display: "inline-block",
            }}
            fontSize={{ xs: 12, md: 12 }}
            color={
              value?.toLowerCase() == "yes"
                ? displaySwitchGreenColor
                : displaySwitchRedColor
            }
            fontWeight={500}
            lineHeight="20px"
          >
            {value}
          </Typography>
        ) : (
          <Typography
            component="h6"
            fontSize={{ xs: 12, md: 13 }}
            color={blackShade19}
            fontWeight={500}
            lineHeight="20px"
          >
            {value}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    border: border25,
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: "10px 15px",
    mb: 2,
    mt: 1,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    color: blackShade19,
  },
};
