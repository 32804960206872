import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import {
  bgColor15,
  blackShade159,
  greyColor24,
  greyColor28,
  whiteColor,
} from "../../../../configs/styles/muiThemes";
import { PRODUCT_DEMO_PICS } from "../../../../utils/data";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

function ProductPackingInfo() {
  function createData(measurement, charges, info = "") {
    return { measurement, charges, info };
  }

  const rows = [
    createData("=20cm x 20cm x 10cm", "Charged weight = 1kg"),
    createData("=10cm x 15cm x 30cm", "Charged weight = 1kg"),
    createData("=8cm x 20cm x 30cm", "Charged weight = 1kg"),
    createData(
      "=20cm x 20cm x 20cm",
      `Charged weight = 1.5kg`,
      "(Will be charged more than product weight due to higher volumetric weight)"
    ),
  ];

  return (
    <Box sx={styles.modelContainer}>
      <Box sx={styles.content}>
        <Box m="16px 0 34px">
          <Typography
            variant="h5"
            fontSize={18}
            fontWeight={600}
            color={greyColor24}
            textAlign="center"
          >
            Recommendation for Packing & Shipping costs
          </Typography>
        </Box>

        <Grid container spacing={10}>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h5"
              fontSize={16}
              fontWeight={600}
              color={greyColor24}
              mb="6px !important"
            >
              1. Package/Box Sizes are very important
            </Typography>

            <Typography variant="body1" sx={styles.infoText}>
              (Volumetric weight = Lenght x width x height / 5000) Shipping
              charges are calculated based on the dead weight of the consignment
              including box weight or Volumetric weight of the box which ever is
              higher.
            </Typography>

            <Box mt="20px">
              <Typography
                variant="h5"
                fontSize={14}
                fontWeight={600}
                color={greyColor24}
                mb="6px !important"
              >
                For 1 kg Product weight:
              </Typography>

              <Typography variant="body1" sx={styles.infoText}>
                Recommended box sizes
              </Typography>

              <Box sx={styles.infoContainer}>
                {rows.map((row) => (
                  <Box sx={styles.infoContent} key={row.name}>
                    <Box flex={1}>
                      <Typography
                        component="th"
                        scope="row"
                        sx={{ ...styles.infoText }}
                      >
                        {row.measurement}
                      </Typography>{" "}
                    </Box>

                    <Box flex={1}>
                      <Typography sx={styles.infoText}>
                        {row.charges}{" "}
                        <Typography sx={{ ...styles.infoText, fontSize: 12 }}>
                          {row.info}
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src="https://storage.googleapis.com/bodefaults/businessdashboardweb/package1.jpg"
              sx={styles.packingImg}
            />

            <Box
              component="img"
              src="https://storage.googleapis.com/bodefaults/businessdashboardweb/package2.jpg"
              sx={styles.packingImg}
            />
          </Grid>
        </Grid>

        <Box>
          <Grid container spacing={10}>
            <Grid item xs={12} md={8}>
              <Box mt="20px">
                <Typography
                  variant="h5"
                  fontSize={16}
                  fontWeight={600}
                  color={greyColor24}
                  mb="16px !important"
                >
                  2. Packaging Method
                </Typography>

                <PackingDetail
                  title="Compact Pack -"
                  description="Products should not move around within the box"
                />

                <PackingDetail
                  title="Bottles -"
                  description="Properly wrap such products in 2 layers of bubble wrap (taped properly) for cushioning. We recommend Air Column Cushion bag."
                />

                <PackingDetail
                  title="Avoid Gaps -"
                  description="If there is empty space, choose a smaller box OR use adequate filler material inside the boxes to completely fill empty spaces to minimize movement"
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box
                component="img"
                src="https://storage.googleapis.com/bodefaults/businessdashboardweb/package3.jpg"
                sx={styles.packingImg}
              />

              <Box
                component="img"
                src="https://storage.googleapis.com/bodefaults/businessdashboardweb/package4.jpg"
                sx={styles.packingImg}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default ProductPackingInfo;

const PackingDetail = ({ title, description }) => {
  return (
    <Box mb={4}>
      <Box display="flex" alignItems="center" gap="10px">
        <TaskAltIcon sx={{ fontSize: "20px" }} />
        <Typography sx={styles.infoTitleStyle}>{title}</Typography>
      </Box>
      <Typography sx={styles.infoDescriptionStyle}>{description}</Typography>
    </Box>
  );
};

const styles = {
  modelContainer: {
    maxHeight: "95vh",
    backgroundColor: whiteColor,
    borderRadius: "16px",
    overflow: "hidden",
  },

  content: {
    maxHeight: "95vh",
    padding: { xs: "20px", md: "20px 50px 20px 20px" },
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
      // height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blackShade159,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },

  infoText: {
    fontSize: 13,
    fontWeight: 400,
    color: bgColor15,
    whiteSpace: "pre-line",
  },

  infoContainer: {
    border: `1px solid ${greyColor28}`,
    mt: 2,
  },

  infoContent: {
    display: "flex",
    padding: "12px",
    borderBottom: `1px solid ${greyColor28}`,
  },

  infoTitleStyle: {
    fontSize: 14,
    fontWeight: 500,
    color: greyColor24,
  },

  infoDescriptionStyle: {
    fontSize: 14,
    fontWeight: 400,
    color: bgColor15,
    mt: 0.5,
    ml: 3.8,
  },

  packingImg: {
    minWidth: "240px",
    border: `1px solid ${greyColor28}`,
    borderRadius: "15px",
    mb: "20px",
  },
};
