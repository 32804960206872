import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import OndcGroupCard from "./Components/OndcGroupCard";
import { blackShade19, blackShade21 } from "../../configs/styles/muiThemes";
import { useSelector } from "react-redux";

const ONDCSpecGroups = ({ productDetails, setSelectedProdGroup }) => {
  const { selectedRowCount, deleteProductLoader, productPublished } =
    useSelector((state) => state.catalogBulkUploadCopy);

  const [productGroupList, setproductGroupList] = useState([]);
  const [selectedListIndex, setSelectedListIndex] = useState(-1);

  // Group products by category and subcategory
  const groupProductsByCategoryNew = () => {
    const selectedProducts = productDetails?.filter(
      (product) => product.isSelected
    );

    const groupedProducts = [];

    selectedProducts.forEach((product) => {
      const category = product?.productMainCategory?.[0]?.ondcValue;
      const subcategory = product?.productSubCategory?.[0]?.ondcSubValue;

      // Check if an entry for the same top-level category and subcategory already exists
      const existingEntryIndex = groupedProducts.findIndex(
        (entry) =>
          entry.topLevel === category && entry.subCategory === subcategory
      );

      if (existingEntryIndex !== -1) {
        // Add the product to the existing entry
        groupedProducts[existingEntryIndex].products.push(product);
      } else {
        // Add a new entry for the top-level category and subcategory
        groupedProducts.push({
          topLevel: category,
          subCategory: subcategory,
          products: [product],
        });
      }
    });

    console.log("groupedProducts__Neww", groupedProducts);
    setproductGroupList(groupedProducts);
  };

  const updateSelectedList = (groupIndex) => {
    console.log("updateSelectedList__", groupIndex);
    setSelectedListIndex(groupIndex);
    setSelectedProdGroup(productGroupList[groupIndex])
  };

  //   const groupProductsByCategory = () => {
  //     const selectedProducts = productDetails.filter(
  //       (product) => product.isSelected
  //     );
  //     // Step 1: Organize products by category and subcategory
  //     const groupedProducts = {};

  //     selectedProducts.forEach((product) => {
  //       // const { category, subcategory } = product;

  //       const category = product?.productMainCategory?.[0]?.ondcValue;
  //       const subcategory = product?.productSubCategory?.[0]?.ondcSubValue;

  //       // Create category if it doesn't exist
  //       if (!groupedProducts[category]) {
  //         groupedProducts[category] = {};
  //       }

  //       // Create subcategory array if it doesn't exist under the category
  //       if (!groupedProducts[category][subcategory]) {
  //         groupedProducts[category][subcategory] = [];
  //       }

  //       // Push the product to the appropriate subcategory array
  //       groupedProducts[category][subcategory].push(product);
  //     });

  //     console.log("groupedProducts__", groupedProducts);
  //   };

  useEffect(() => {
    // groupProductsByCategory();
    groupProductsByCategoryNew();
    return () => {};
  }, []);

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography sx={styles.heading}>ONDC Specifications</Typography>
          <Typography sx={styles.subHeading}>
            {/* {selectedRowCount || 0} of {productDetails?.length} rows selected
            for updates and import. Go to Step 3 to choose additional rows for
            import. */}
            Choose one category for Product specifications update
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={2}>
        {productGroupList?.length > 0
          ? productGroupList.map((item, index) => (
              <Grid item xs={12} md={4} key={`group_card_${index}`}>
                <OndcGroupCard
                  category={item?.topLevel}
                  subCategory={item.subCategory}
                  count={item?.products?.length || 0}
                  isSelected={selectedListIndex === index}
                  onChangeValue={() => updateSelectedList(index)}
                />
              </Grid>
            ))
          : null}
      </Grid>
    </Box>
  );
};

export default ONDCSpecGroups;

const styles = {
  heading: {
    fontSize: "14px",
    fontWeight: "600",
    color: blackShade19,
    mb: 0.2,
  },
  subHeading: {
    fontSize: "13px",
    fontWeight: "400",
    color: blackShade21,
    mb: 2,
  },
};