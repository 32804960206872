import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BusinessInfoCard from "../../../components/Cards/BusinessInfoCard";
import StatsInfoCard from "../../../components/Cards/StatsInfoCard";
import {
  BGColor02,
  greyColor10,
  iconBG1,
  iconBG2,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import {
  getActiveBusinessInfo,
  getCommerceInfo,
} from "../../../reducers/businessPreview/businessPreviewSlice";
import DashboardMenu from "../../Dashboard/DashboardMenu";
import DashboardHeader from "../../Dashboard/DashboardHeader";
import IssueDetailCard from "./IssueDetailCard";

function ViewIssueDetails() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { activeBusinessInfo, commerceInfo } = useSelector(
    (state) => state.businessPreview
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }

      if (!commerceInfo) {
        dispatch(getCommerceInfo(businessId));
      }
    }
  }, []);

  return (
    <Box sx={styles.container}>
      {matches ? (
        <Box sx={styles.menuStickyLeft}>
          <DashboardMenu
            activeMenu="issue"
            // disableMenu={DISABLE_ALL_MENU?.filter((item) => item != "store")}
          />
        </Box>
      ) : null}

      <Box flex={1}>
        <Box sx={styles.stickyTop}>
          <DashboardHeader />
        </Box>

        <Box sx={styles.content}>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent="space-between"
          >
            <BusinessInfoCard
              logo={activeBusinessInfo?.logoURL}
              businessName={activeBusinessInfo?.name}
            />
          </Box>

          <Box>
            <IssueDetailCard />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ViewIssueDetails;

const styles = {
  container: {
    minHeight: { xs: "calc(100vh - 91px)", md: "100vh" },
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "25px" },
    p: { xs: "0px", md: "15px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  statsCard: {
    width: { xs: "100%", md: "auto" },
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    gap: { xs: "20px", md: "35px" },
    backgroundColor: whiteColor,
    padding: { xs: "10px 16px", md: "8px 16px" },
    borderRadius: { xs: "8px", md: "12px" },
    border: `1px solid ${greyColor10}`,
    m: { xs: "12px 0 12px 0", md: "0" },
  },
};
