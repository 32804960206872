import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { BGColor02 } from "../configs/styles/muiThemes";
import DashboardMenu from "./Dashboard/DashboardMenu";
import DashboardHeader from "./Dashboard/DashboardHeader";
import GuideContent from "./Guides/GuideContent";
import { DISABLE_ALL_MENU } from "../utils/data";
import { getProfileId } from "../utils/validationUtils";
import DashboardLayout from "../components/Layouts/DashboardLayout";

function Guides() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const businessId = sessionStorage.getItem("selectedBusinessId");
  return (
    <Box sx={styles.container}>
      {/* {matches ? (
        <Box sx={styles.menuStickyLeft}>
          <DashboardMenu
            activeMenu="howToVideoGuides"
            disableMenu={
              getProfileId()
                ? []
                : DISABLE_ALL_MENU?.filter((item) => item != "howToGuides")
            }
          />
        </Box>
      ) : null} */}

      {/* <Box flex={1}>
        <Box sx={styles.stickyTop}>
          <DashboardHeader />
        </Box>
        <Box sx={styles.content}>
          <GuideContent />
        </Box>
      </Box> */}
      <DashboardLayout
        activeMenu="howToVideoGuides"
        // disableMenu={
        //   getProfileId()
        //     ? []
        //     // : DISABLE_ALL_MENU?.filter((item) => item != "howToGuides")
        //     :
        // }
        disableAll={sessionStorage.getItem("selectedBusinessId") ? false : true}
        // hideBusinessData={
        //   sessionStorage.getItem("selectedBusinessId") ? false : true
        // }
        hideBusinessData={true}
      >
        <GuideContent />
      </DashboardLayout>
    </Box>
  );
}

export default Guides;

const styles = {
  container: {
    minHeight: { xs: "calc(100vh - 91px)", md: "100vh" },
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "25px" },
    p: { xs: "0px", md: "25px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },
};
