import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Chip,
  Tabs,
  Tab,
  Button,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SaveAltRoundedIcon from "@mui/icons-material/SaveAltRounded";
import EmptyCard from "../components/Alerts/EmptyCard";
import BusinessInfoCard from "../components/Cards/BusinessInfoCard";
import StatsInfoCard from "../components/Cards/StatsInfoCard";
import SearchBoxInput from "../components/Input/SearchBoxInput";
import SelectWithLabel from "../components/Input/SelectWithLabel";
import {
  BGColor02,
  blackColor,
  blackShade05,
  blackShade22,
  blueColor04,
  blueColor1,
  greyColor10,
  greyColor24,
  greyColor33,
  greyColor40,
  greyColor5,
  greyColor8,
  greyColor9,
  heroSectionText,
  iconBG1,
  iconBG2,
  logoImgborder1,
  whiteColor,
} from "../configs/styles/muiThemes";
import {
  clearCatalogData,
  clearSearchProducts,
  getActiveBusinessInfo,
  getBusinessPendingProducts,
  getBusinessRejectsProducts,
  getBusinessUpdatedPendingProducts,
  getCommerceInfo,
  getProductCategories,
  getProductsByBusiness,
  searchBusinessProducts,
} from "../reducers/businessPreview/businessPreviewSlice";
import DashboardHeader from "./Dashboard/DashboardHeader";
import DashboardMenu from "./Dashboard/DashboardMenu";
import AddIcon from "@mui/icons-material/Add";
import {
  activeProductEmptyMsg,
  pendingProductEmptyMsg,
  rejectedProductEmptyMsg,
} from "../configs/Constants";
import { getUserEditEligibility } from "../reducers/businessOnboard/businessOnboardSlice";
import { isUserEditEligible } from "../utils/validationHelper";
import ThreeDotLoader from "../components/ThreeDotLoader";
import ProductCardMinimal from "../components/Cards/ProductCardMinimal";
import DeleteProductModalContent from "./ProductList/DeleteProductModalContent";
import ModalCard from "../components/ModalCard";
import { updateProductStatusApi } from "../service/api_calls/catalog/CatalogAPI";
import {
  getDashboardInventorySummaryApi,
  getDashboardOrderSummaryApi,
} from "../service/api_calls/BusinessDashboard/BusinessDashboardApi";
import { addThousandsSeparator } from "../utils/helper";
import { scrollBox, stickyHeader } from "../utils/styles";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import HowToLinkCard from "../components/Cards/HowToLinkCard";
import VideoModal from "../components/VideoModal";
import { CATALOG_VIDEO_DATA } from "../utils/data";
import { getStatsCountInfoData } from "../reducers/businessDashboard/BusinessDashboardSlice";
import DashboardLayout from "../components/Layouts/DashboardLayout";

function ProductList() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const location = useLocation();
  const productStatus = location.state?.productStatus || "active";

  const [productCountSalesInfo, setProductCountSalesInfo] = useState(null);

  const [tabValue, setTabValue] = useState(0);
  const stepList = [
    { id: 0, label: "ACTIVE", mobileLabel: "ACTIVE" },
    { id: 1, label: "PENDING APPROVAL(NEW)", mobileLabel: "PENDING (NEW)" },
    {
      id: 2,
      label: "PENDING APPROVAL(EXISTING)",
      mobileLabel: "PENDING (EXISTING)",
    },
    { id: 3, label: "REJECTED(NEW)", mobileLabel: "REJECTED (NEW)" },
    { id: 4, label: "REJECTED(EXISTING)", mobileLabel: "REJECTED(EXISTING)" },
  ];

  const [productFilteredData, setProductFilteredData] = useState({
    active: [],
    pendingNew: [],
    pendingExisting: [],
    rejectedNew: [],
    rejectedExisting: [],

    searchPrev: [],
  });

  const [productsList, setProductsList] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [isClearSearch, setIsClearSearch] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedSubCat, setSelectedSubCat] = useState("All");

  const [isProductsEditable, setIsProductsEditable] = useState(false);

  const [isShowDeleteAlter, setIsShowDeleteAlter] = useState({
    show: false,
    data: null,
    loader: false,
    deleteComplete: false,
  });

  const [openVideoModal, setOpenVideoModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    activeBusinessInfo,
    businessProducts,
    searchProducts,
    productCategory,
    commerceInfo,
    pendingProducts,
    updatedPendingProducts,
    rejectedProducts,
    businessProductsLoader,
    searchProductsLoader,
    productsByStatusLoader,
  } = useSelector((state) => state.businessPreview);

  const { editEligibility } = useSelector((state) => state.businessOnboard);

  const { statsCountInfo } = useSelector((state) => state.businessDashboard);

  // Filter products based on status
  const filterProducts = (status) => {
    const filteredList = businessProducts?.productList?.filter(
      (item) => item?.status === status
    );

    return filteredList;
  };

  // handle Tab switch
  const handleChange = (event, newValue) => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");

    if (newValue === 0) {
      navigate(`/${businessUrl}/catalog`, {
        state: { productStatus: "active" },
      });
    } else if (newValue === 1) {
      navigate(`/${businessUrl}/catalogPending`, {
        state: { productStatus: "pending" },
      });
    } else if (newValue === 2) {
      navigate(`/${businessUrl}/catalogPendingExisting`, {
        state: { productStatus: "pendingExisting" },
      });
    } else if (newValue === 3) {
      navigate(`/${businessUrl}/catalogRejected`, {
        state: { productStatus: "rejected" },
      });
    } else if (newValue === 4) {
      navigate(`/${businessUrl}/catalogRejectedExisting`, {
        state: { productStatus: "rejectedExisting" },
      });
    } else {
    }

    setSelectedCategory("All");
    setSelectedSubCat("All");

    setTabValue(newValue);
  };

  const getProductsCount = (tabId) => {
    if (businessProductsLoader || productsByStatusLoader) return "XX";

    if (tabId === 0) {
      const activeList = filterProducts("active");
      return activeList?.length || 0;
    } else if (tabId === 1) {
      return pendingProducts?.details?.length || 0;
    } else if (tabId === 2) {
      return updatedPendingProducts?.details?.length || 0;
    } else if (tabId === 3) {
      return productFilteredData?.rejectedNew?.length || 0;
    } else if (tabId === 4) {
      return productFilteredData?.rejectedExisting?.length || 0;
    } else {
      return 0;
    }
  };

  //search for products
  const handleSearch = () => {
    if (!searchQuery) return;
    const businessId = sessionStorage.getItem("selectedBusinessId");
    setSelectedCategory("All");
    setSelectedSubCat("All");

    dispatch(
      searchBusinessProducts({ query: searchQuery, businessId: businessId })
    );
  };

  const clearSearch = () => {
    dispatch(clearSearchProducts());
    setProductsList(productFilteredData?.active || []);
    setSearchQuery("");
    setIsClearSearch(false);
  };

  // Prepare list data for filter dropdown
  const formatCategory = (category, setValue) => {
    const catList = category.map((item) => {
      return { ...item, label: item?.value };
    });

    setValue([{ label: "All" }, ...catList]);
  };

  const getCurrentProductList = () => {
    if (productStatus === "active") return productFilteredData?.active || [];

    if (productStatus === "pending")
      return productFilteredData?.pendingNew || [];
    if (productStatus === "rejected")
      return productFilteredData?.rejectedNew || [];

    return [];
  };

  // Filter Products by Category
  const handleCategoryFilter = (data) => {
    clearSearch();

    setSelectedCategory(data);
    setSelectedSubCat("All");

    if (data === "All") {
      setSubCatList([]);
      const currentProductList = getCurrentProductList();
      setProductsList(currentProductList);
      return;
    }

    const CategoryData = categoryList?.filter((item) => {
      return item?.value === data;
    });

    formatCategory(CategoryData?.[0]?.subCat, setSubCatList);

    const currentProductList = getCurrentProductList();

    const filteredProducts = currentProductList?.filter((item) => {
      return item?.productSubCategory?.[0]?.pcCode === CategoryData?.[0]?.code;
    });

    setProductsList(filteredProducts);
  };

  // Filter Products by Sub Category
  const handleSubCategoryFilter = (data) => {
    clearSearch();

    setSelectedSubCat(data);

    if (data === "All") {
      const CategoryData = categoryList?.filter((item) => {
        return item?.value === selectedCategory;
      });

      const currentProductList = getCurrentProductList();

      const filteredProducts = currentProductList?.filter((item) => {
        return (
          item?.productSubCategory?.[0]?.pcCode === CategoryData?.[0]?.code
        );
      });

      setProductsList(filteredProducts);
      return;
    }

    const subCategoryData = subCatList?.filter((item) => {
      return item?.value === data;
    });

    const currentProductList = getCurrentProductList();

    const filteredProducts = currentProductList?.filter((item) => {
      return item?.productSubCategory?.[0]?.code === subCategoryData?.[0]?.code;
    });

    setProductsList(filteredProducts);
  };

  const handleEditProduct = (productId) => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/editProduct`, {
      state: {
        productId,
        // isRejectedProduct:
        //   productStatus === "rejected" || productStatus === "pending",
        isRejectedProduct: productStatus !== "active",
      },
    });
  };

  const handlePreview = (productId) => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/viewProduct`, {
      state: { productId, isReviewProduct: productStatus !== "active" },
    });
  };

  // handle ondc click
  const handleOndcClick = (productId) => {
    // console.log("productID", productId);

    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/ONDCParameters`, {
      state: { productId, isReviewProduct: productStatus !== "active" },
    });
  };

  const handleAddProduct = () => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/addProductNew`);
    // navigate(`/${businessUrl}/addProduct`, {
    //   state: { productId: null },
    // });
  };

  const onCopyProduct = (event, productId) => {
    event.stopPropagation();

    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/copyProduct`, {
      state: {
        productId,
        isCopyProduct: true,
        // isRejectedProduct:
        //   productStatus === "rejected" || productStatus === "pending",
        isRejectedProduct: productStatus !== "active",
      },
    });
  };

  // handle delete click
  const onDeleteProduct = (data) => {
    setIsShowDeleteAlter({ ...isShowDeleteAlter, show: true, data });
  };

  // close delete product alter
  const closeDeleteAlert = () => {
    setIsShowDeleteAlter({
      ...isShowDeleteAlter,
      show: false,
      data: null,
      loader: false,
      deleteComplete: false,
    });
  };

  const onCheckClick = () => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/catalogRejected`, {
      state: { productStatus: "rejected" },
    });
  };

  // update product status to inactive (Delete)
  const updateProductStatus = async () => {
    setIsShowDeleteAlter({ ...isShowDeleteAlter, loader: true });

    const businessId = sessionStorage.getItem("selectedBusinessId");
    const productInfo = isShowDeleteAlter?.data || null;

    const deletePostData = {
      productId: productInfo?.id,
      businessId,
    };

    console.log("deletePostData:", deletePostData);

    const response = await updateProductStatusApi(deletePostData);
    console.log("delete_response:", response);

    setTimeout(() => {
      setIsShowDeleteAlter({
        ...isShowDeleteAlter,
        loader: false,
        deleteComplete: true,
      });
    }, 2000);
  };

  const updateProductList = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(getProductsByBusiness(businessId));
    dispatch(getBusinessPendingProducts({ businessId, status: "new" }));
    dispatch(getBusinessRejectsProducts({ businessId, status: "declined" }));

    closeDeleteAlert();
  };

  // Checking if current product list is empty
  const checkListEmpty = () => {
    if (searchProductsLoader) return true;

    if (productStatus === "active") {
      return (
        !businessProductsLoader &&
        !searchProductsLoader &&
        businessProducts?.productList?.length > 0
      );
    }
    if (productStatus === "pending") {
      return (
        !businessProductsLoader &&
        !searchProductsLoader &&
        pendingProducts?.details?.length > 0
      );
    }
    if (productStatus === "pendingExisting") {
      return (
        !businessProductsLoader &&
        !searchProductsLoader &&
        updatedPendingProducts?.details?.length > 0
      );
    }
    if (productStatus === "rejected") {
      return (
        !businessProductsLoader &&
        !searchProductsLoader &&
        productFilteredData?.rejectedNew?.length > 0
      );
    }
    if (productStatus === "rejectedExisting") {
      return (
        !businessProductsLoader &&
        !searchProductsLoader &&
        productFilteredData?.rejectedExisting?.length > 0
      );
    }
  };

  // Return Empty List message based on tab
  const getEmptyMessage = () => {
    if (isClearSearch && tabValue === 0)
      return "No matching active products found. Please refine your search";

    return tabValue === 0
      ? activeProductEmptyMsg
      : tabValue === 1
      ? pendingProductEmptyMsg
      : tabValue === 2
      ? pendingProductEmptyMsg
      : tabValue === 3
      ? rejectedProductEmptyMsg
      : rejectedProductEmptyMsg;
  };

  // Check if active product is also present in pending & Rejected products
  const checkActiveProductInPendingRejected = (
    activeList,
    pendingList,
    rejectList
  ) => {
    const updatedActiveList = activeList.map((product) => {
      const isPendingProduct = pendingList.filter(
        (item) => item.id === product.id
      );
      const isRejectedProduct = rejectList.filter(
        (item) => item.id === product.id
      );

      let updatedOn = "";

      if (isPendingProduct?.length > 0) {
        updatedOn = isPendingProduct?.[0]?.productUpdatedOn;
      }

      if (isRejectedProduct?.length > 0) {
        updatedOn = isRejectedProduct?.[0]?.productUpdatedOn;
      }

      return {
        ...product,
        disableEdit: isPendingProduct?.length > 0,
        isPendingProduct: isPendingProduct?.length > 0,
        isRejectedProduct: isRejectedProduct?.length > 0,
        updatedOn,
      };
    });

    return updatedActiveList;
  };

  const getRejectedProductsByStatus = (activeList, rejectList, status) => {
    if (status === "new") {
      const newRejectedProducts = rejectList?.filter((product) =>
        activeList.some((item) => item.id !== product.id)
      );

      console.log("REJECTED_NEW:", newRejectedProducts);
      return newRejectedProducts;
    }

    if (status === "existing") {
      const existingRejectedProducts = rejectList?.filter((product) =>
        activeList.some((item) => item.id === product.id)
      );
      console.log("REJECTED_EXISTING:", existingRejectedProducts);
      return existingRejectedProducts;
    }

    return [];
  };

  // Getting total product and sales count
  const getStatsInfo = async () => {
    // const businessId = sessionStorage.getItem("selectedBusinessId");
    // const orderCountInfo = await getDashboardOrderSummaryApi(businessId);
    // const inventoryCountInfo = await getDashboardInventorySummaryApi(
    //   businessId
    // );
    // setProductCountSalesInfo({
    //   orderCountInfo: orderCountInfo || null,
    //   inventoryCountInfo: inventoryCountInfo || null,
    // });
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  // Fetching all the Catalog Data
  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      // getStatsInfo();

      if (!editEligibility) {
        dispatch(getUserEditEligibility());
      }
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      if (!businessProducts) {
        dispatch(getProductsByBusiness(businessId));
      }
      if (!productCategory) {
        dispatch(getProductCategories(businessId));
      }
      if (!commerceInfo) {
        dispatch(getCommerceInfo(businessId));
      }
      if (!pendingProducts) {
        dispatch(getBusinessPendingProducts({ businessId, status: "new" }));
      }
      if (!updatedPendingProducts) {
        dispatch(
          getBusinessUpdatedPendingProducts({ businessId, status: "updated" })
        );
      }
      if (!rejectedProducts) {
        dispatch(
          getBusinessRejectsProducts({ businessId, status: "declined" })
        );
      }

      // Getting Product and Sales Count
      if (!statsCountInfo) {
        dispatch(getStatsCountInfoData(businessId));
      }
    }

    return () => {
      console.log("CLEAR_DATA_CATALOG");
      dispatch(clearCatalogData());
    };
  }, []);

  // Show search results when data received
  useEffect(() => {
    if (searchProducts?.details) {
      setIsClearSearch(true);

      const searchResults = searchProducts?.details;
      const pendingList = pendingProducts?.details || [];
      const updatedPendingList = updatedPendingProducts?.details || [];
      const rejectList = rejectedProducts?.details || [];

      const updatedSearchList = checkActiveProductInPendingRejected(
        searchResults,
        [...pendingList, ...updatedPendingList],
        rejectList
      );

      setProductsList(updatedSearchList);
    }
  }, [searchProducts]);

  // Display data based on selected tab
  useEffect(() => {
    let activeUpdatedList = [];

    if (productStatus === "active") {
      if (
        businessProducts?.productList &&
        pendingProducts &&
        updatedPendingProducts &&
        rejectedProducts
      ) {
        const activeList = filterProducts("active");
        const pendingList = pendingProducts?.details || [];
        const updatedPendingList = updatedPendingProducts?.details || [];
        const rejectList = rejectedProducts?.details || [];

        const updatedActiveList = checkActiveProductInPendingRejected(
          activeList,
          [...pendingList, ...updatedPendingList],
          rejectList
        );

        updatedActiveList?.sort((a, b) => {
          if (!a.updatedOn) return 1;
          if (!b.updatedOn) return -1;

          return parseInt(b.updatedOn) - parseInt(a.updatedOn);
        });

        console.log("updatedActiveList:", updatedActiveList);

        activeUpdatedList = updatedActiveList;
        setProductsList(updatedActiveList);
      }
    }

    if (productStatus === "pending") {
      const pendingList = pendingProducts?.details || [];

      setProductFilteredData({
        ...productFilteredData,
        pendingNew: pendingList,
      });

      const pendingNewSort = [...pendingList];

      pendingNewSort?.sort((a, b) => {
        if (!a.productUpdatedOn) return 1;
        if (!b.productUpdatedOn) return -1;

        return parseInt(b.productUpdatedOn) - parseInt(a.productUpdatedOn);
      });

      console.log("pendingNewList:", pendingNewSort);

      setProductsList(pendingNewSort);
    }

    if (productStatus === "pendingExisting") {
      let pendingList = updatedPendingProducts?.details || [];

      setProductFilteredData({
        ...productFilteredData,
        pendingExisting: pendingList,
      });

      const pendingExistingSort = [...pendingList];

      pendingExistingSort?.sort((a, b) => {
        if (!a.productUpdatedOn) return 1;
        if (!b.productUpdatedOn) return -1;

        return parseInt(b.productUpdatedOn) - parseInt(a.productUpdatedOn);
      });

      setProductsList(pendingExistingSort);
    }

    // Filtering Rejected Products by Status

    if (businessProducts?.productList && rejectedProducts) {
      const activeList = filterProducts("active");
      const rejectList = rejectedProducts?.details || [];

      const updatedNewRejectedList = getRejectedProductsByStatus(
        activeList,
        rejectList,
        "new"
      );

      const updatedExistingRejectedList = getRejectedProductsByStatus(
        activeList,
        rejectList,
        "existing"
      );

      setProductFilteredData({
        ...productFilteredData,
        active: activeUpdatedList,
        rejectedNew: updatedNewRejectedList,
        rejectedExisting: updatedExistingRejectedList,
      });

      if (productStatus === "rejected") {
        const rejectedNewSort = [...updatedNewRejectedList];

        rejectedNewSort?.sort((a, b) => {
          if (!a.productUpdatedOn) return 1;
          if (!b.productUpdatedOn) return -1;

          return parseInt(b.productUpdatedOn) - parseInt(a.productUpdatedOn);
        });

        setProductsList(rejectedNewSort);
      }

      if (productStatus === "rejectedExisting") {
        const rejectedExistingSort = [...updatedExistingRejectedList];

        rejectedExistingSort?.sort((a, b) => {
          if (!a.productUpdatedOn) return 1;
          if (!b.productUpdatedOn) return -1;

          return parseInt(b.productUpdatedOn) - parseInt(a.productUpdatedOn);
        });

        setProductsList(rejectedExistingSort);
      }
    }

    const catalogStatus = location.state?.productStatus || "active";

    setTabValue(
      catalogStatus === "active"
        ? 0
        : catalogStatus === "pending"
        ? 1
        : catalogStatus === "pendingExisting"
        ? 2
        : catalogStatus === "rejected"
        ? 3
        : 4
    );
  }, [
    businessProducts,
    updatedPendingProducts,
    pendingProducts,
    rejectedProducts,
    location,
  ]);

  useEffect(() => {
    if (productCategory?.details) {
      formatCategory(productCategory?.details, setCategoryList);
    }
  }, [productCategory]);

  // User Edit Eligibility Check
  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (editEligibility) {
      const businessList = editEligibility?.editEligibilityList;
      const isEditable = isUserEditEligible(businessId, businessList);

      setIsProductsEditable(isEditable);
    }
    return () => {};
  }, [editEligibility]);

  return (
    activeBusinessInfo &&
    statsCountInfo && (
      <Box sx={styles.container}>
        <DashboardLayout activeMenu="catalog">
          <Box sx={styles.content}>
            {isProductsEditable ? (
              <Box>
                <Box sx={{ ...stickyHeader, pt: 1 }} pb={1.5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: { xs: "15px", md: "20px" },
                      // mt: { xs: "8px", md: "none" },
                    }}
                  >
                    <Typography
                      component="h4"
                      fontSize={{ xs: 15, md: 17 }}
                      color={blackShade05}
                      fontWeight={600}
                      lineHeight="20px"
                    >
                      Product Catalog
                    </Typography>

                    <Box
                      display="flex"
                      gap={{ md: 2, xs: 1 }}
                      alignItems="center"
                    >
                      <HowToLinkCard
                        link="Catalog Management"
                        handleClick={() => {
                          setOpenVideoModal(true);
                          // navigate("/howToVideoGuides", {
                          //   state: { status: "catalog" },
                          // });
                        }}
                      />
                      {matches ? (
                        <Button
                          sx={styles.actionBtn}
                          variant="contained"
                          disableElevation
                          startIcon={<SaveAltRoundedIcon />}
                        >
                          Bulk upload
                        </Button>
                      ) : (
                        <IconButton sx={styles.bulkaddIconBtnSmall}>
                          <SaveAltRoundedIcon sx={{ fontSize: "18px" }} />
                        </IconButton>
                      )}
                      {matches ? (
                        <Button
                          sx={styles.addIconBtn}
                          variant="contained"
                          disableElevation
                          onClick={handleAddProduct}
                          startIcon={<AddIcon />}
                        >
                          Add Product
                        </Button>
                      ) : (
                        <IconButton
                          sx={styles.addIconBtnSmall}
                          onClick={handleAddProduct}
                        >
                          <AddIcon sx={{ fontSize: "18px" }} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>

                  {!businessProductsLoader &&
                    !productsByStatusLoader &&
                    matches && (
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          mb: { xs: "10px", md: "12px" },
                        }}
                      >
                        <Tabs
                          value={tabValue}
                          onChange={(event, newValue) =>
                            handleChange(event, newValue)
                          }
                          aria-label="basic tabs example"
                          textColor={blackColor}
                          TabIndicatorProps={{
                            style: {
                              height: "3px",
                              backgroundColor: blueColor04,
                            },
                          }}
                          sx={{
                            minHeight: "35px !important",
                            "& .MuiButtonBase-root": {
                              minWidth: "auto !important",
                            },

                            "& .MuiTabs-flexContainer": {
                              // justifyContent: "space-between",
                            },
                          }}
                        >
                          {stepList?.map((item, index) => {
                            return (
                              <Tab
                                key={"filter_" + index}
                                label={
                                  item.label?.toLowerCase() +
                                  ` - ${getProductsCount(item.id)}`
                                }
                                // label={item.label}
                                sx={{
                                  fontSize: { xs: "11px", md: "13px" },
                                  fontWeight: tabValue == index ? 600 : 500,
                                  p: 0,
                                  minHeight: "30px !important",
                                  mr: { xs: "10px", md: "55px" },
                                  color:
                                    item.id === tabValue
                                      ? blueColor04
                                      : greyColor40,
                                  textTransform: "capitalize",
                                }}
                              />
                            );
                          })}
                        </Tabs>
                      </Box>
                    )}
                  {!matches && (
                    <Box sx={{ ...styles.mobileTabs, ...scrollBox }}>
                      {stepList?.map((item, index) => {
                        return (
                          <MobileTab
                            title={
                              item.mobileLabel +
                              ` - ${getProductsCount(item.id)}`
                            }
                            isActive={tabValue == index}
                            onClick={() => handleChange("", index)}
                          />
                        );
                      })}
                    </Box>
                  )}
                </Box>
                {checkListEmpty() && (
                  <Box>
                    {productStatus !== "rejected" &&
                      productStatus !== "rejectedExisting" && (
                        <Box
                          display="flex"
                          flexDirection={{ xs: "column", md: "row" }}
                          alignItems="flex-start"
                          justifyContent="space-between"
                          mb={3.9}
                        >
                          <Box>
                            <Typography
                              fontSize={{ xs: 12, md: 12 }}
                              fontWeight={500}
                              color={greyColor40}
                              display="inline"
                            >
                              Tip:{" "}
                              <Typography
                                fontSize={{ xs: 12, md: 12 }}
                                fontWeight={400}
                                color={greyColor40}
                                display="inline"
                              >
                                Use{" "}
                                <Box sx={styles.copyBtnBox}>
                                  <ContentCopyIcon sx={{ fontSize: "16px" }} />
                                </Box>{" "}
                                option to add a new product quickly
                                {/* , Use{" "}
                            <Box
                              sx={{
                                ...styles.copyBtnBox,
                                color: heroSectionText,
                                borderColor: heroSectionText,
                              }}
                            >
                              <HubOutlinedIcon sx={{ fontSize: "16px" }} />
                            </Box>{" "}
                            option to edit ONDC parameters */}
                              </Typography>
                            </Typography>

                            <Box ml={3} mt={1}>
                              <Typography
                                fontSize={{ xs: 12, md: 12 }}
                                fontWeight={400}
                                color={greyColor40}
                                display="inline"
                              >
                                Use{" "}
                                <Box
                                  sx={{
                                    ...styles.copyBtnBox,
                                    color: heroSectionText,
                                    borderColor: heroSectionText,
                                  }}
                                >
                                  <HubOutlinedIcon sx={{ fontSize: "16px" }} />
                                </Box>{" "}
                                option to edit ONDC parameters
                              </Typography>
                            </Box>
                          </Box>

                          <Typography
                            fontSize={{ xs: 13, md: 13 }}
                            m={{ xs: "8px 0 8px 25px", md: "0px 0 8px 0px" }}
                            color={greyColor40}
                          >
                            For stock and pricing updates use Inventory update
                            section
                          </Typography>
                        </Box>
                      )}

                    {productStatus === "active" && (
                      <Grid container spacing={{ xs: 0, md: 2 }}>
                        <Grid item xs={12} md={7}>
                          <Box mt={-2}>
                            <Box width={{ xs: "auto", md: "300px" }}>
                              <SearchBoxInput
                                label="Search"
                                placeholder="Search Product"
                                value={searchQuery}
                                onChange={(text) => {
                                  setSearchQuery(text);
                                }}
                                onSearchClick={handleSearch}
                                isDashboardInput
                              />
                            </Box>

                            {isClearSearch ? (
                              <Box
                                display="flex"
                                alignItems="center"
                                gap="6px"
                                mt={1}
                              >
                                <Typography
                                  component="h4"
                                  fontSize={14}
                                  color={blackShade05}
                                  fontWeight={500}
                                  lineHeight="20px"
                                >
                                  Search results for “
                                  <Box
                                    component="span"
                                    sx={{
                                      fontSize: 14,
                                      color: blackShade05,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {searchQuery}
                                  </Box>
                                  ”
                                </Typography>

                                <Chip
                                  label={searchQuery}
                                  sx={{ fontSize: "12px", height: "28px" }}
                                  onDelete={clearSearch}
                                />
                              </Box>
                            ) : null}
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={5}>
                          <Box m={{ xs: "12px 16px 0 16px", md: "0px" }}>
                            <Grid container spacing={2}>
                              {/* <Grid item xs={12} md={4} p="0px !important">
                              <SearchBoxInput
                                label="Search"
                                placeholder="Search Product"
                                value={searchQuery}
                                onChange={(text) => {
                                  setSearchQuery(text);
                                }}
                                onSearchClick={handleSearch}
                                isDashboardInput
                              />
                            </Grid> */}

                              <Grid item xs={12} md={6} p="0px !important">
                                <Box m={{ xs: "16px 0", md: "0 16px" }}>
                                  <SelectWithLabel
                                    label="Product Category"
                                    value={selectedCategory}
                                    onValueChange={(value) => {
                                      handleCategoryFilter(value);
                                    }}
                                    menuList={categoryList}
                                    isDashboardInput
                                  />
                                </Box>
                              </Grid>

                              <Grid item xs={12} md={6} p="0px !important">
                                <SelectWithLabel
                                  label="Product Sub Category"
                                  value={selectedSubCat}
                                  onValueChange={(value) => {
                                    handleSubCategoryFilter(value);
                                  }}
                                  menuList={subCatList}
                                  isDashboardInput
                                  disabled={subCatList.length === 0}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                )}

                <Box mt={3}>
                  {businessProductsLoader || searchProductsLoader ? (
                    <ThreeDotLoader />
                  ) : productsList?.length > 0 ? (
                    productsList?.map((item) => {
                      return (
                        <ProductCardMinimal
                          key={item?.id}
                          title={item?.productName}
                          image={item?.productPhoto?.[0]?.docURL}
                          newPrice={item?.productSalePrice}
                          oldPrice={item?.productOriginalPrice}
                          isDiscount={item?.percentageOff !== 0}
                          percentageOff={item?.percentageOff}
                          onClick={() => {
                            handlePreview(item?.id);
                          }}
                          onEditClick={() => {
                            handleEditProduct(item?.id);
                          }}
                          onCopyClick={() => {}}
                          onDeleteClick={() => onDeleteProduct(item)}
                          onOndcClick={() => handleOndcClick(item?.id)}
                          handleCopyProduct={(e) => onCopyProduct(e, item?.id)}
                          onCheckClick={onCheckClick}
                          disableEdit={item?.disableEdit}
                          productStatus={productStatus}
                          rejectedNote={item?.notes || ""}
                          // Status Check in pending and rejected
                          isPendingProduct={item?.isPendingProduct}
                          isRejectedProduct={item?.isRejectedProduct}
                          updatedOn={
                            productStatus === "active"
                              ? item?.updatedOn
                              : item?.productUpdatedOn
                          }
                          // Pending
                          catalogStatus={item?.status}
                          // Rejected
                          rejectedProductInActive={
                            item?.rejectedProductInActive || false
                          }
                          // ONDC
                          ondcEnabled={item?.ondcEnabled}
                        />
                      );
                    })
                  ) : !businessProductsLoader &&
                    !searchProductsLoader &&
                    productsList?.length === 0 ? (
                    <EmptyCard
                      icon="https://storage.googleapis.com/bodefaults/businessdashboardweb/product-reject.svg"
                      message={getEmptyMessage()}
                    />
                  ) : null}
                  {/* </Grid> */}
                </Box>
              </Box>
            ) : (
              <Typography variant="body1" fontSize={15} fontWeight={500}>
                You don't have edit permission
              </Typography>
            )}

            {productsByStatusLoader ? <ThreeDotLoader /> : null}
          </Box>
        </DashboardLayout>

        <ModalCard
          open={isShowDeleteAlter.show}
          // open={true}
          handleClose={closeDeleteAlert}
          width="27%"
        >
          <DeleteProductModalContent
            productName={isShowDeleteAlter?.data?.productName}
            isLoading={isShowDeleteAlter?.loader}
            onDelete={updateProductStatus}
            onCancel={closeDeleteAlert}
            isDeleteComplete={isShowDeleteAlter?.deleteComplete}
            onDone={updateProductList}
          />
        </ModalCard>

        {openVideoModal && (
          <VideoModal
            open={openVideoModal}
            handleClose={handleCloseVideoModal}
            data={CATALOG_VIDEO_DATA}
          />
        )}
      </Box>
    )
  );
}

export default ProductList;

const MobileTab = ({ title, isActive, onClick }) => {
  return (
    <Box sx={styles.mobileTabItem(isActive)} onClick={onClick}>
      <Typography
        fontSize={12}
        color={isActive ? blueColor04 : greyColor40}
        fontWeight={isActive ? 600 : 500}
        sx={{ whiteSpace: "nowrap" }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const styles = {
  container: {
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "10px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  addBtn: {
    height: "299px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #f1f1f1",
    borderRadius: "5px",
    boxShadow: "0px 1px 3px rgb(0 0 0 / 6%)",
    backgroundColor: whiteColor,
    cursor: "pointer",
  },

  addIconBtn: {
    padding: "4px 14px",
    fontSize: "12.5px",
    fontWeight: 600,
    color: blueColor04,
    backgroundColor: whiteColor,
    border: "1.5px solid " + blueColor04,
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },
  actionBtn: {
    backgroundColor: blackShade22,
    padding: "4px 14px",
    fontSize: "12.5px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + blackShade22,
    "&:hover": {
      color: blackShade22,
      backgroundColor: whiteColor,
    },
  },

  addIconBtnSmall: {
    width: "38px",
    height: "34px",
    backgroundColor: "transparent",
    fontWeight: 600,
    color: blueColor04,
    backgroundColor: whiteColor,
    borderRadius: "3px",
    border: "1px solid " + blueColor04,
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },
  bulkaddIconBtnSmall: {
    width: "38px",
    height: "34px",
    backgroundColor: "transparent",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: blackShade22,
    borderRadius: "3px",
    border: "1px solid " + whiteColor,
    "&:hover": {
      color: blackShade22,
      backgroundColor: whiteColor,
    },
  },

  statsCard: {
    width: { xs: "100%", md: "auto" },
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    gap: { xs: "20px", md: "35px" },
    backgroundColor: whiteColor,
    padding: { xs: "10px 16px", md: "8px 16px" },
    borderRadius: { xs: "8px", md: "12px" },
    border: logoImgborder1,
    m: { xs: "12px 0 12px 0", md: "0" },
  },

  copyBtnBox: {
    width: "28px",
    height: "28px",
    borderRadius: "5px",
    border: `1px solid ${greyColor33}`,
    padding: "3px 4px",
    mx: "2px",
    backgroundColor: whiteColor,
    color: greyColor33,
    display: "inline",
  },

  mobileTabs: {
    width: { xs: "90vw", md: "calc(100% - 100px)" },
    display: "flex",
    alignItems: "center",
    mb: 1,
  },

  mobileTabItem: (isActive) => ({
    borderBottom: isActive ? `3px solid ${blueColor04}` : "none",
  }),
};
