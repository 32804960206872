import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BGColor02,
  alertInfoCardBG,
  blackShade05,
  blackShade159,
  blueColor02,
  blueColor04,
  greyColor24,
  primaryColor,
  whiteColor,
} from "../configs/styles/muiThemes";
import {
  publishBusinessData,
  sendWhatsAppNotification,
} from "../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";
import Banner from "./BusinessPreview/Banner";
import BusinessDetailsPreviewContent from "./BusinessPreview/BusinessDetailsPreviewContent";
import DashboardHeader from "./Dashboard/DashboardHeader";
import { getMyBusinessAPI } from "../service/api_calls/BusinessPreview/BusinessPreviewAPI";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import ThreeDotLoader from "../components/ThreeDotLoader";
import { useDispatch, useSelector } from "react-redux";
import { getMyBusinessSummary } from "../reducers/businessOnboard/businessOnboardSlice";
import ModalCard from "../components/ModalCard";
import { getUserDetails } from "../utils/validationUtils";
import DashboardLayout from "../components/Layouts/DashboardLayout";

function BusinessDetailsPreview() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [businessInfo, setBusinessInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const userDetails = getUserDetails();

  const [addBusinesLoader, setAddBusinesLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const whatsAppNumber = "+918088422615";
  const emailAddress = "support@costbo.com";
  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        // `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${businessInfo?.name}+Business Approval Status Check.+`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };

  const { myBusinessSummary } = useSelector((state) => state.businessOnboard);

  const getBusinessDetails = async () => {
    setLoading(true);
    // api should be changed to viewMyBusiness
    const businessData = await getMyBusinessAPI(location?.state?.businessId);

    if (businessData?.viewBusiness) {
      let businessDetail = businessData?.viewBusiness;

      const businessAddress = businessDetail?.businessArea?.reduce(
        (preValue, currentValue) => {
          return {
            ...preValue,
            [currentValue?.code?.toLowerCase()?.split(" ")?.join("_") ===
            "addressline_one"
              ? "address"
              : currentValue?.code?.toLowerCase()?.split(" ")?.join("_")]:
              currentValue?.value,
          };
        },
        {}
      );

      // console.log("businessAddress:", businessAddress);

      const ownerDetails = {
        phone:
          businessDetail?.contactNos?.[0]?.number?.replace("+91", "+91 ") || "",
        alternatePhone1:
          businessDetail?.contactNos?.[1]?.number?.replace("+91", "+91 ") || "",
        alternatePhone2:
          businessDetail?.contactNos?.[2]?.number?.replace("+91", "+91 ") || "",
        email: businessDetail?.businessMailingAddress?.split(",")?.[0] || "",
        alternateEmail:
          businessDetail?.businessMailingAddress?.split(",")?.[1] || "",
      };

      // console.log("ownerDetails_prepare:", ownerDetails);

      businessDetail = {
        ...businessDetail,
        image: businessDetail?.logoURL,
        banners: businessDetail?.documentsURL,
        mainProducts: businessDetail?.mainProducts?.map(
          (item) => item?.productName
        ),
        businessAddress,
        ownerDetails,
      };

      setBusinessInfo(businessDetail);
      setLoading(false);

      // console.log("getBusinessDetails:", businessDetail);
    }

    setLoading(false);
    console.log("location?.state?.businessId:", location?.state);
  };

  useEffect(() => {
    if (location?.state?.isPendingPreview) {
      getBusinessDetails();
    }
    if (!myBusinessSummary) {
      dispatch(getMyBusinessSummary());
    }
    return () => {};
  }, []);

  const onSubmit = async () => {
    setAddBusinesLoader(true);
    setShowAlert(true);
    await publishBusinessData(
      { id: location?.state?.id },
      location?.state?.id
    ).then((res) => {
      setAddBusinesLoader(false);
      sendWhatsAppNotificationData();
    });
  };

  const sendWhatsAppNotificationData = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    // let businessDetail = businessData?.viewBusiness;
    const data = {
      businessId: businessId || "",
      code: "B002",
      businessOwnerName: userDetails?.account?.name || "",
      businessName: location?.state?.gstDetails?.registeredBusinessName || "",
      businessOwnerPhoneNumber: location?.state?.ownerDetails?.phone
        ? "+91" + location?.state?.ownerDetails?.phone
        : "",
    };
    console.log("Whatsapp", data);
    const res = await sendWhatsAppNotification(data, businessId);
  };

  const onEdit = () => {
    navigate("/addBusinessDetail", {
      state: { businessId: location?.state?.id },
    });
  };

  const handleCancel = () => {
    if (myBusinessSummary?.details?.length === 0) {
      navigate("/");
    } else {
      navigate("/businessListing");
    }
  };

  return (
    <Box sx={styles.container}>
      {/* {JSON.stringify(businessInfo)} */}
      {/* {matches ? (
        <Box sx={styles.menuStickyLeft}>
          <DashboardMenu
            activeMenu="store"
            disableMenu={DISABLE_ALL_MENU?.filter((item) => item != "store")}
          />
        </Box>
      ) : null} */}
      <DashboardLayout activeMenu="store" disableAll>
        {loading ? (
          <ThreeDotLoader wrapContent />
        ) : (
          <Box sx={styles.content}>
            {location?.state?.isPendingPreview && (
              <Box sx={styles.alertInfoCard}>
                <Typography
                  variant="h6"
                  fontSize={14}
                  fontWeight={500}
                  color={blackShade05}
                  mb="6px !important"
                  textAlign="center"
                >
                  This business is Pending approval. Our CMS agents will review
                  and approve the business within 24 hrs of submission.
                </Typography>

                <Typography
                  variant="body2"
                  fontSize={14}
                  fontWeight={500}
                  color={blackShade05}
                  display="inline"
                  mb="15px"
                >
                  Please contact COSTBO support via{" "}
                  <Typography
                    // variant="body1"
                    fontSize={14}
                    fontWeight={500}
                    color={blueColor04}
                    display="inline"
                    // onclick={handleChatClick}
                    component="a"
                    href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=${businessInfo?.name}+Business Approval Status Check.+&fs=1&to=${emailAddress}&tf=cm`}
                    target="_blank"
                  >
                    Email
                  </Typography>
                  {"  "}
                  or{" "}
                  <Typography
                    // variant="body1"
                    fontSize={14}
                    fontWeight={500}
                    color={blueColor04}
                    display="inline"
                    // component="a"
                    onClick={handleChatClick}
                    // href={`https://mail.google.com/mail/u/0/?fs=1&to=${emailAddress}&tf=cm`}
                    // target="_blank"
                  >
                    Whatsapp
                  </Typography>
                </Typography>

                <Button
                  onClick={() => {
                    navigate("/businessListing");
                  }}
                  sx={{
                    fontSize: 13,
                    textDecoration: "underline",
                  }}
                >
                  Back to business listing
                </Button>
              </Box>
            )}

            <Banner
              businessDetails={location?.state?.businessDetails || businessInfo}
              storeInfoDetails={
                location?.state?.storeInfoDetails || businessInfo
              }
            />
            {console.log(location?.state)}
            <BusinessDetailsPreviewContent
              businessDetails={location?.state?.businessDetails || businessInfo}
              storeInfoDetails={location?.state?.storeInfoDetails}
              addressDetails={
                location?.state?.addressDetails || businessInfo?.businessAddress
              }
              gstDetails={location?.state?.gstDetails || businessInfo}
              ownerDetails={
                location?.state?.ownerDetails || businessInfo?.ownerDetails
              }
              isPendingPreview={location?.state?.isPendingPreview}
              onSubmit={onSubmit}
              onEdit={onEdit}
              handleCancel={handleCancel}
            />

            {location?.state?.isPendingPreview && (
              <Button
                sx={styles.backBtn}
                startIcon={<SkipPreviousIcon fontSize="36px" />}
                onClick={() => {
                  navigate("/businessListing");
                }}
              >
                BACK TO BUSINESS LISTING
              </Button>
            )}
          </Box>
        )}
      </DashboardLayout>
      <ModalCard
        open={showAlert}
        handleClose={() => {
          // setShowAlert(false);
        }}
        width="48%"
      >
        <Box backgroundColor={whiteColor} borderRadius="8px">
          {addBusinesLoader ? (
            <Box sx={{ height: "100px", pt: 2 }}>
              <ThreeDotLoader />
            </Box>
          ) : (
            <AlertMessagePopup
              text={
                "Business Details added successfully. Our CMS team will review and approve within 24 hours.\nFor immediate approval, please contact via whatsapp - ‘8088422615’"
              }
              onClick={() => {
                sendWhatsAppNotificationData();
                navigate("/businessListing");
              }}
            />
          )}
        </Box>
      </ModalCard>
    </Box>
  );
}

export default BusinessDetailsPreview;

const AlertMessagePopup = ({ text, onClick }) => {
  return (
    <Box sx={styles.alertCard}>
      <Typography
        variant="body2"
        fontSize={14}
        fontWeight={500}
        textAlign="center"
        sx={{ whiteSpace: "pre-line", lineHeight: "28px" }}
      >
        {text}
      </Typography>
      <Button
        sx={styles.alertBtn}
        variant="contained"
        disableElevation
        onClick={onClick}
      >
        GO TO BUSINESS LISTING
      </Button>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    backgroundColor: BGColor02,
  },

  content: {
    p: { xs: "0 0 20px", md: "0px 0px 25px 0px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  alertInfoCard: {
    backgroundColor: alertInfoCardBG,
    p: "15px",
    borderRadius: "8px",
    my: 2,
    textAlign: "center",
  },

  backBtn: {
    fontSize: 13.5,
    fontWeight: 500,
    backgroundColor: whiteColor,
    color: "rgb(8 82 109)",
    border: "1px solid rgb(8 82 109)",
    padding: "6px 15px",
    mt: 2,
    "&:hover": {
      backgroundColor: "rgb(8 82 109)",
      color: whiteColor,
    },
  },

  alertCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    padding: "20px 20px 15px 20px",
    borderRadius: "8px",
  },

  alertBtn: {
    fontSize: "13px",
    fontWeight: "500",
    color: whiteColor,
    backgroundColor: blackShade159,
    padding: "6px 24px",
    marginTop: "12px",
    "&:hover": {
      color: blackShade159,
      backgroundColor: whiteColor,
      border: `1px solid ${blackShade159}`,
    },
  },
};
