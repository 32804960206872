import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FormInputBox from "../../../components/Input/FormInputBox";
import {
  blackColor,
  blackShade05,
  blackShade22,
  blueColor02,
  blueColor04,
  colorGreen,
  greyColor24,
  greyColor6,
  greyShade04,
  greyShade25,
  mobilePrimary,
  redColor05,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import DashboardStepper from "../../Dashboard/DashboardStepper";
import { ECOMMERCE_STEP_LIST } from "../../../utils/appData";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import moment from "moment";
import InputPreview from "../../../components/Input/InputPreview";
import DateInput from "../../../components/Input/DateInput";
import { useNavigate } from "react-router-dom";

function AddBankDetails({
  data,
  addBankData,
  setData,
  onValueChange,
  currentPage,
  onSubmit,
  gotoNext,
  goBack,
  handleCancel,
  inactiveDate,
  onInactiveDate,
  UpdateInactiveDate,
  setBankInfoError,
  bankInfoError,
  loader,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const navigate = useNavigate();

  const accountType = [
    { type: "Current" },
    { type: "Checking" },
    { type: "Savings" },
    { type: "Other" },
  ];
  const [open, setOPen] = useState(false);
  const [beforeData, setBeforeData] = useState([]);
  const [afterData, setAfterData] = useState([]);
  const [nowData, setNowData] = useState([]);
  const emailAddress = "support@costbo.com";

  useEffect(() => {
    const filteredData = data?.map((item) => ({
      ...item,
      addedDate: moment(item?.addedDate),
      expiredDate: item.expiredDate ? moment(item?.expiredDate) : "",
    }));
    console.log("filteredData", filteredData);
    const currentDate = moment();
    const currentDateInIST = moment();

    const after = filteredData?.filter((item) =>
      moment(item?.addedDate).isAfter(currentDate)
    );
    console.log("after", after);
    setAfterData(after);

    const beforeExpired = filteredData?.filter((item) =>
      item?.expired ? moment(item?.expired).isAfter(after?.[0]?.addedDate) : ""
    );
    console.log("beforeExpired", beforeExpired);

    const now = filteredData?.filter((item) =>
      moment(item?.addedDate).isSame(currentDate, "day")
    );

    const lesserDates = [];

    // Iterate over the data array and compare dates
    // for (let i = 0; i < filteredData.length - 1; i += 2) {
    //   const date1 = filteredData[i].expiredDate || 0; // Handle missing or null dates
    //   const date2 = filteredData[i + 1].addedDate || 0; // Handle missing or null dates
    //   const lesserDates = date1 < date2 ? filteredData[i] : filteredData[i + 1];
    // }
    //console.log("lesserDates", lesserDates);
    // let lesserObject = [];
    // if (filteredData?.length > 0) {
    //   const testdata = filteredData?.filter((item) => item?.expiredDate !== "");
    //   if (testdata) {
    //     lesserObject = testdata?.reduce((prev, curr) => {
    //       const prevDate = prev.expiredDate || moment();
    //       const currDate = curr.addedDate || 0;
    //       return prevDate < currDate ? prev : curr;
    //     });
    //   } else
    //     lesserObject = filteredData?.reduce((prev, curr) => {
    //       const prevDate = prev.expiredDate || moment();
    //       const currDate = curr.addedDate || 0;
    //       return prevDate < currDate ? prev : curr;
    //     });
    // }
    // const currentDateInIST = moment().tz("Asia/Kolkata");

    const lesserObject = filteredData?.filter((item) => {
      const addedDate = item?.addedDate;
      const expiredDate = item?.expiredDate;
      return currentDateInIST.isBetween(
        addedDate,
        expiredDate || currentDateInIST,
        null,
        "[]"
      );
    });
    setNowData(lesserObject);
    if (lesserObject?.length > 0)
      onInactiveDate("expiredDate", lesserObject?.[0]?.expiredDate);

    console.log("lesserObject", lesserObject);

    const before = filteredData?.filter((item) =>
      moment(item?.expiredDate).isBefore(currentDate)
    );
    // console.log("before", before);

    // const before = beforeOne?.filter((item) => nowData?.id === item?.id);
    // console.log("before", before);

    setBeforeData(before);
    console.log("before_after_now", lesserObject);
  }, [data]);
  return (
    <Box>
      <Box m="15px 0 15px 0" display={"flex"} flexDirection={"column"}>
        <Typography
          component="h6"
          fontSize={{ xs: 15, md: 15 }}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          mb="6px"
        >
          Bank Information
        </Typography>
        
          <Typography
            variant="h6"
            color={blackShade05}
            fontSize="14px"
            fontWeight="400"
          >
            For any updates to the Settlement Bank account info in the middle of
            the calendar month, please contact{" "}
            <Typography
              // variant="body1"
              fontSize={{ md: "13px", xs: "13px" }}
              fontWeight={500}
              color={{ xs: mobilePrimary, md: blueColor04 }}
              display="inline"
              // onclick={handleChatClick}
              component="a"
              Loyalty
              href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=+ +&fs=1&to=${emailAddress}&tf=cm`}
              target="_blank"
              // lineHeight={0.8}
            >
              support@costbo.com
            </Typography>
          </Typography>
      </Box>
      <Box sx={styles.card}>
        {data?.length > 0 && (
          <Box m="15px 0 10px 0">
            {nowData?.length > 0 && (
              <>
                {nowData?.map((item, index) => {
                  return (
                    <Grid
                      container
                      spacing={1}
                      pt={{ md: 0, xs: 1 }}
                      px={1}
                      py={2}
                      mb={3}
                    >
                      <Grid md={12} xs={12}>
                        <Typography
                          variant="h4"
                          fontSize={14}
                          fontWeight={600}
                          color={greyColor24}
                          lineHeight="20px"
                          mb={1.5}
                        >
                          Settlement Account - Active
                        </Typography>
                      </Grid>
                      <Grid md={3} xs={12}>
                        <InputPreview
                          label="Account Name"
                          value={item?.accountName}
                          valueStyles={{
                            fontSize: 14,
                            p: "10px 0 0px 0",
                            mt: { md: 0, xs: 0.5 },
                            mb: { md: 1, xs: 1 },
                            color: "#546274",
                          }}
                          labelSize={13}
                          valueSize={13}
                          hideBorder
                        />
                      </Grid>
                      <Grid md={3} xs={12}>
                        <InputPreview
                          label="Account Number"
                          value={item?.accountNo}
                          valueStyles={{
                            fontSize: 14,
                            color: "#546274",
                            p: "10px 0 0px 0",
                            mt: { md: 0, xs: 0.5 },
                            mb: { md: 1, xs: 1 },
                          }}
                          labelSize={13}
                          valueSize={13}
                          hideBorder
                        />
                      </Grid>
                      <Grid md={4} xs={12}>
                        <InputPreview
                          label="Account Type"
                          labelSize={13}
                          value={item?.accountType}
                          valueStyles={{
                            fontSize: 14,
                            color: "#546274",
                            p: "10px 0 0px 0",
                            mt: { md: 0, xs: 0.5 },
                            mb: { md: 1, xs: 1 },
                          }}
                          valueSize={13}
                          hideBorder
                        />
                      </Grid>
                      <Grid md={3} xs={12}>
                        <InputPreview
                          label="Bank Name"
                          value={item?.bankName}
                          valueStyles={{
                            fontSize: 14,
                            color: "#546274",
                            p: "10px 0 0px 0",
                            mt: { md: 0, xs: 0.5 },
                            mb: { md: 1, xs: 1 },
                          }}
                          labelSize={13}
                          valueSize={13}
                          hideBorder
                        />
                      </Grid>
                      <Grid md={3} xs={12}>
                        <InputPreview
                          label="Bank Branch Name"
                          value={item?.bankAddress}
                          valueStyles={{
                            fontSize: 14,
                            color: "#546274",
                            p: "10px 0 0px 0",
                            mt: { md: 0, xs: 0.5 },
                            mb: { md: 1, xs: 1 },
                          }}
                          labelSize={13}
                          valueSize={13}
                          hideBorder
                        />
                      </Grid>
                      <Grid md={4} xs={12}>
                        <InputPreview
                          label="IFSC Code"
                          value={item?.ifscCode}
                          valueStyles={{
                            fontSize: 14,
                            color: "#546274",
                            p: "10px 0 0px 0",
                            mt: { md: 0, xs: 0.5 },
                            mb: { md: 1, xs: 1 },
                          }}
                          labelSize={13}
                          valueSize={13}
                          hideBorder
                        />
                      </Grid>
                      <Grid md={3} xs={12}>
                        <InputPreview
                          label="Active Date"
                          value={moment(item?.addedDate).format(" DD MMM YYYY")}
                          valueStyles={{
                            fontSize: 14,
                            color: "#546274",
                            p: "10px 0 0px 0",
                            mt: { md: 0, xs: 0.5 },
                            mb: { md: -2, xs: -2 },
                          }}
                          labelSize={13}
                          valueSize={13}
                          hideBorder
                        />
                      </Grid>

                      <Grid item xs={12} md={3} mt={{ md: -2, xs: 1 }} ml={-1}>
                        {/* <FormInputBox
                              label="Inactive Date"
                              placeholder=""
                              value={
                                item?.expiredDate
                                  ? 
                                  moment(item?.expiredDate).format("DD/MM/YYYY")
                                  : inactiveDate?.expiredDate
                              }
                              onChange={({ target }) => {
                                // const date =
                                //   // moment(target)
                                //   //   // .year(year)
                                //   //   // .month(month)
                                //   //   .endOf("month")
                                //   //   .format("DD/MM/YYYY");
                                //   moment(target?.value).isSame(
                                //     moment(target?.value).endOf("month"),
                                //     "day"
                                //   );
                                onInactiveDate("expiredDate", target?.value);
                              }}
                              inputType="date"
                              required
                              error={inactiveDate?.errors?.expiredDate}
                              helperText={"Inactive Date should be Month end"}
                              // helperColor={blueColor04}
                            /> */}
                        <Box>
                          <DateInput
                            label="Inactive Date"
                            date={
                              inactiveDate?.expiredDate
                                ? moment(inactiveDate?.expiredDate).format(
                                    "yyyy-MM-DD"
                                  )
                                : ""
                            }
                            onDateChange={({ target }) => {
                              onInactiveDate("expiredDate", target?.value);
                              const addDate = moment(target?.value)
                                .add(1, "day")
                                .format("YYYY-MM-DD");
                              console.log("newaddDate", addDate);
                              onValueChange("addedDate", addDate);
                            }}
                            error={inactiveDate?.errors?.expiredDate}
                          />

                          <Typography
                            variant="body1"
                            component="p"
                            fontSize={12}
                            color={greyColor6}
                            lineHeight="20px"
                            mt={0.5}
                          >
                            {matches
                              ? "Inactive Date should be end of month"
                              : "Inactive Date should be end of month"}
                          </Typography>
                        </Box>
                      </Grid>

                      {!item?.expiredDate && (
                        <Grid
                          item
                          xs={12}
                          md={4}
                          mt={2}
                          display={{ md: "block", xs: "flex" }}
                          justifyContent={"center"}
                        >
                          <Button
                            sx={styles.addBtn}
                            variant="contained"
                            disabled={loader}
                            disableElevation
                            onClick={() => {
                              const checkerror = UpdateInactiveDate(
                                item?.addedDate
                              );
                              console.log("ravi", checkerror);
                              if (checkerror) return;

                              const date = moment(
                                inactiveDate?.expiredDate
                              ).isSame(
                                moment(inactiveDate?.expiredDate).endOf(
                                  "month"
                                ),
                                "day"
                              );

                              if (
                                date &&
                                moment(item?.addedDate)
                                  .startOf("day")
                                  .isBefore(
                                    moment(inactiveDate?.expiredDate).endOf(
                                      "day"
                                    )
                                  )
                              ) {
                                setOPen(true);
                              }
                              const addDate = moment(inactiveDate?.expiredDate)
                                .add(1, "day")
                                .format("YYYY-MM-DD");
                              console.log("newaddDate", addDate);
                              onValueChange("addedDate", addDate);
                            }}
                          >
                            ADD NEW BANK ACCOUNT
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
              </>
            )}

            {afterData?.length > 0 && (
              <>
                {afterData?.map((item, index) => {
                  return (
                    <Grid
                      container
                      spacing={1}
                      pt={{ md: 0, xs: 1 }}
                      px={1}
                      py={2}
                      mb={3}
                    >
                      <Grid md={12} xs={12}>
                        <Typography
                          variant="h4"
                          fontSize={14}
                          fontWeight={600}
                          color={greyColor24}
                          lineHeight="20px"
                        >
                          Settlement Account - Future
                        </Typography>
                      </Grid>
                      <Grid md={3} xs={12}>
                        <InputPreview
                          label="Account Name"
                          value={item?.accountName}
                          valueStyles={{
                            fontSize: 14,
                            p: "10px 0 0px 0",
                            mt: { md: 0, xs: 0.5 },
                            mb: { md: 1, xs: 1 },
                            color: "#546274",
                          }}
                          labelSize={13}
                          valueSize={13}
                          hideBorder
                        />
                      </Grid>
                      <Grid md={3} xs={12}>
                        <InputPreview
                          label="Account Number"
                          value={item?.accountNo}
                          valueStyles={{
                            fontSize: 14,
                            color: "#546274",
                            p: "10px 0 0px 0",
                            mt: { md: 0, xs: 0.5 },
                            mb: { md: 1, xs: 1 },
                          }}
                          labelSize={13}
                          valueSize={13}
                          hideBorder
                        />
                      </Grid>
                      <Grid md={4} xs={12}>
                        <InputPreview
                          label="Account Type"
                          labelSize={13}
                          value={item?.accountType}
                          valueStyles={{
                            fontSize: 14,
                            color: "#546274",
                            p: "10px 0 0px 0",
                            mt: { md: 0, xs: 0.5 },
                            mb: { md: 1, xs: 1 },
                          }}
                          valueSize={13}
                          hideBorder
                        />
                      </Grid>
                      <Grid md={3} xs={12}>
                        <InputPreview
                          label="Bank Name"
                          value={item?.bankName}
                          valueStyles={{
                            fontSize: 14,
                            color: "#546274",
                            p: "10px 0 0px 0",
                            mt: { md: 0, xs: 0.5 },
                            mb: { md: 1, xs: 1 },
                          }}
                          labelSize={13}
                          valueSize={13}
                          hideBorder
                        />
                      </Grid>
                      <Grid md={3} xs={12}>
                        <InputPreview
                          label="Bank Branch Name"
                          value={item?.bankAddress}
                          valueStyles={{
                            fontSize: 14,
                            color: "#546274",
                            p: "10px 0 0px 0",
                            mt: { md: 0, xs: 0.5 },
                            mb: { md: 1, xs: 1 },
                          }}
                          labelSize={13}
                          valueSize={13}
                          hideBorder
                        />
                      </Grid>
                      <Grid md={4} xs={12}>
                        <InputPreview
                          label="IFSC Code"
                          value={item?.ifscCode}
                          valueStyles={{
                            fontSize: 14,
                            color: "#546274",
                            p: "10px 0 0px 0",
                            mt: { md: 0, xs: 0.5 },
                            mb: { md: 1, xs: 1 },
                          }}
                          labelSize={13}
                          valueSize={13}
                          hideBorder
                        />
                      </Grid>
                      <Grid md={3} xs={12}>
                        <InputPreview
                          label="Active Date"
                          value={moment(
                            inactiveDate.expiredDate
                              ? moment(inactiveDate?.expiredDate)
                                  .add(1, "day")
                                  .format("MM/DD/YYYY")
                              : item?.addedDate
                          ).format(" DD MMM YYYY")}
                          valueStyles={{
                            fontSize: 14,
                            color: "#546274",
                            p: "10px 0 0px 0",
                            mt: { md: 0, xs: 0.5 },
                            mb: { md: -2, xs: -2 },
                          }}
                          labelSize={13}
                          valueSize={13}
                          hideBorder
                        />
                      </Grid>
                      {item?.expiredDate === " " ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            mt={{ md: -2, xs: 0 }}
                            ml={-1}
                          >
                            <FormInputBox
                              label="Inactive Date1"
                              placeholder=""
                              value={
                                item?.exexpiredDate
                                  ? item?.expiredDate
                                  : inactiveDate?.expiredDate
                              }
                              onChange={({ target }) => {
                                // const date =
                                //   // moment(target)
                                //   //   // .year(year)
                                //   //   // .month(month)
                                //   //   .endOf("month")
                                //   //   .format("DD/MM/YYYY");
                                //   moment(target?.value).isSame(
                                //     moment(target?.value).endOf("month"),
                                //     "day"
                                //   );
                                onInactiveDate("expiredDate", target?.value);
                              }}
                              inputType="date"
                              required
                              error={inactiveDate?.errors?.expiredDate}
                              helperText={"Inactive Date should be Month end"}
                              // helperColor={blueColor04}
                            />
                          </Grid>

                          <Grid item xs={12} md={4} mt={2}>
                            <Button
                              sx={styles.submitBtn}
                              variant="contained"
                              disableElevation
                              onClick={() => {
                                UpdateInactiveDate(item?.addedDate);
                                const date = moment(
                                  inactiveDate?.expiredDate
                                ).isSame(
                                  moment(inactiveDate?.expiredDate).endOf(
                                    "month"
                                  ),
                                  "day"
                                );
                                if (
                                  date &&
                                  moment(item?.addedDate)
                                    .startOf("day")
                                    .isBefore(
                                      moment(inactiveDate?.expiredDate).endOf(
                                        "day"
                                      )
                                    )
                                ) {
                                  setOPen(true);
                                }
                                const addDate = moment(
                                  inactiveDate?.expiredDate
                                )
                                  .add(1, "day")
                                  .format("DD/MM/YYYY");
                                console.log("addDate", addDate);
                                onValueChange("addedDate", addDate);
                              }}
                            >
                              ADD NEW BANK ACCOUNT
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        item?.expiredDate && (
                          <Grid md={3} xs={6}>
                            <InputPreview
                              label="Inctive Date"
                              value={moment(item?.expiredDate).format(
                                "DD MMM YYYY"
                              )}
                              valueStyles={{
                                fontSize: 14,
                                color: "#546274",
                                p: "10px 0 0px 0",
                                mt: { md: 0, xs: 0.5 },
                                mb: { md: -2, xs: -2 },
                              }}
                              labelSize={13}
                              valueSize={13}
                              hideBorder
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  );
                })}
              </>
            )}
          </Box>
        )}
        {(data?.length <= 0 || open) && (
          <Box m="15px 0 10px 0">
            <Typography
              component="h6"
              fontSize={{ xs: 15, md: 15 }}
              color={blackColor}
              fontWeight={600}
              lineHeight="20px"
              mb="6px"
            >
              Add New Bank Account
            </Typography>

            {/* <Typography
              variant="body1"
              fontSize={13}
              fontWeight={400}
              color={greyShade04}
              mb="15px !important"
            >
              (Order settlements will be paid to this account)
            </Typography> */}

            <Grid container rowSpacing={{ xs: 1, md: 2 }} columnSpacing={3}>
              <Grid item xs={12} md={3}>
                <FormInputBox
                  label="Account Name"
                  value={addBankData?.accountName}
                  onChange={({ target }) => {
                    // const regex = /^[A-Za-z0-9-\s]*$/;
                    const regex = /^[A-Za-z0-9\W]*$/;
                    if (regex.test(target?.value) && target?.value !== " ") {
                      // onValueChange("accountName", target?.value);
                      setData((prevData) => ({
                        ...prevData,
                        accountName: target?.value,
                      }));
                    }
                  }}
                  maxLength={100}
                  required
                  error={addBankData?.errors?.accountName}
                />
              </Grid>

              <Grid item xs={6} md={3}>
                <FormInputBox
                  label="Account Number"
                  value={addBankData?.accountNo}
                  onChange={({ target }) => {
                    // const regex = /^[A-Za-z0-9-\s]*$/;
                    // if (
                    //   regex.test(target?.value) &&
                    //   !isNaN(target.value) &&
                    //   target.value.charAt(0) !== " "
                    //   // && target.value < 100
                    // )
                    const regex = /^[A-Za-z0-9]*$/;
                    if (regex.test(target?.value) && target?.value !== " ") {
                      onValueChange("accountNo", target?.value?.toUpperCase());
                    }
                  }}
                  error={
                    matches
                      ? addBankData?.errors?.accountNo
                      : addBankData?.errors?.accountNo
                      ? "Max 20 characters"
                      : ""
                  }
                  maxLength={20}
                  required
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <SelectWithLabel
                  label="Account Type"
                  required
                  value={addBankData?.accountType}
                  menuList={accountType || []}
                  onValueChange={(value) => {
                    onValueChange("accountType", value);
                  }}
                  errorMsg={addBankData?.errors?.accountType}
                  valueSelector={"type"}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <FormInputBox
                  label={"Bank Name"}
                  value={addBankData?.bankName}
                  onChange={({ target }) => {
                    // const regex = /^[A-Za-z\s]*$/;
                    const regex = /^[A-Za-z0-9\W]*$/;
                    if (regex.test(target?.value) && target?.value !== " ") {
                      onValueChange("bankName", target?.value);
                    }
                  }}
                  maxLength={100}
                  required
                  error={addBankData?.errors?.bankName}
                />
              </Grid>

              <Grid item xs={6} md={3}>
                <FormInputBox
                  label="Bank Branch Name"
                  value={addBankData?.bankAddress}
                  onChange={({ target }) => {
                    // const regex = /^[A-Za-z\s]*$/;
                    const regex = /^[A-Za-z0-9\W]*$/;
                    if (regex.test(target?.value) && target?.value !== " ") {
                      onValueChange("bankAddress", target?.value);
                      // setData((prevData) => ({
                      //   ...prevData,
                      //   bankName: target?.value,
                      // }));
                    }
                  }}
                  maxLength={100}
                  required
                  error={addBankData?.errors?.bankAddress}
                />
              </Grid>

              <Grid item xs={6} md={4}>
                <FormInputBox
                  label="IFSC code"
                  value={addBankData?.ifscCode}
                  onChange={({ target }) => {
                    // const regex = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/;
                    const regex = /^[A-Za-z0-9]*$/;
                    if (regex.test(target?.value) && target?.value !== " ") {
                      // if (regex.test(target?.value) && target?.value !== " ") {
                      onValueChange("ifscCode", target?.value?.toUpperCase());
                      // onValueChange("ifscCode", target?.value);
                      // setData((prevData) => ({
                      //   ...prevData,
                      //   bankName: target?.value,
                      // }));
                    }
                  }}
                  maxLength={11}
                  required
                  error={addBankData?.errors?.ifscCode}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                {data?.length === 0 && (
                  <FormInputBox
                    label="Active Date"
                    value={addBankData?.addedDate}
                    onChange={({ target }) => {
                      // const addDate= moment(inactiveDate?.expiredDate).add(1, "day").format("DD/MM/YYYY");
                      onValueChange("addedDate", target?.value);
                    }}
                    inputType="date"
                    required
                    error={addBankData?.errors?.addedDate}
                  />
                )}
                {data?.length !== 0 && (
                  <InputPreview
                    label="Active Date"
                    value={moment(addBankData?.addedDate).format("DD MMM YYYY")}
                    valueStyles={{
                      p: "10px 0 0px 0",
                      mt: { md: -0.5, xs: 0.5 },
                      mb: { md: 2, xs: 1 },
                    }}
                    hideBorder
                  />
                )}
                {/* <DateInput
                  label="Activated Date"
                  date={addBankData?.addedDate}
                  onDateChange={() => {
                    const addDate = moment(inactiveDate?.expiredDate)
                      .add(1, "day")
                      .format("DD/MM/YYYY");
                    onValueChange("addedDate", addDate);
                  }}
                  error={data?.errors?.holidayStartDate}
                /> */}
              </Grid>
              {/* <Grid item xs={12} md={3}>
              <FormInputBox
                label="InActive Date"
                placeholder=""
                value={data?.expiredDate}
                onChange={({ target }) => {
                  // handleValueChange("endDate", target?.value);
                }}
                inputType="date"
                // required
                error={data?.errors?.endDate}
              />
            </Grid> */}

              {/* <Grid item xs={12} md={4}>
              <Button
                sx={styles.verifyBtn}
                variant="contained"
                disableElevation
                onClick={() => {
                  gotoNext();
                }}
              >
                ADD NEW BANK ACCOUNT
              </Button>
            </Grid> */}
              <Grid item xs={0} md={8}></Grid>
            </Grid>
          </Box>
        )}

        <Box>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            flexDirection={"column"}
            gap={2}
          >
            {addBankData?.errors?.addedDate ||
            inactiveDate?.errors?.expiredDate ||
            bankInfoError ? (
              <Typography
                variant="subtitle1"
                component="p"
                fontSize={12}
                sx={{ mt: "4px !important", textAlign: "end !important" }}
                style={{ color: "red" }}
              >
                {inactiveDate?.errors?.expiredDate || bankInfoError}
              </Typography>
            ) : null}
            <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
              <Button
                sx={styles.deleteBtn}
                variant="contained"
                disabled={loader}
                disableElevation
                onClick={() => {
                  setOPen(false);
                  navigate(
                    `/${sessionStorage.getItem("costboWEBURL")}/bankInfo`
                  );
                }}
              >
                Cancel
              </Button>

              <Button
                sx={styles.submitBtn}
                variant="contained"
                disableElevation
                disabled={loader}
                startIcon={
                  loader && (
                    <CircularProgress
                      size={14}
                      sx={{ color: { xs: mobilePrimary, md: blackShade22 } }}
                    />
                  )
                }
                onClick={() => {
                  console.log("HIT1");

                  if (data?.length === 0) {
                    console.log("HIT2");

                    onSubmit(nowData);
                    return;
                  }
                  const adddate = nowData?.filter(
                    (item, index) => item?.addedDate
                  );

                  const errorCheck = UpdateInactiveDate(
                    adddate?.[0]?.addedDate
                  );
                  if (errorCheck) return;

                  if (open) {
                    console.log("HIT3");

                    console.log("kiran", data);

                    console.log("kiran", errorCheck);

                    onSubmit(nowData, errorCheck);
                  } else {
                    console.log("HIT4");
                    setBankInfoError("Add new bank account Details");
                  }
                }}
              >
                SUBMIT
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AddBankDetails;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    p: "5px 0px 20px 20px",
    m: { xs: "15px 0 25px", md: "0px 0 25px" },
  },

  deleteBtn: {
    backgroundColor: { xs: mobilePrimary, md: whiteColor },
    padding: { md: "2px 20px", xs: "3px 6px" },
    fontSize: "12px",
    fontWeight: 500,
    color: { md: blueColor04, xs: whiteColor },
    border: {
      xs: "1.5px solid " + mobilePrimary,
      md: "1.5px solid " + blueColor04,
    },
    "&:hover": {
      color: { xs: mobilePrimary, md: whiteColor },
      backgroundColor: { md: blueColor04, xs: whiteColor },
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },
  addBtn: {
    backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    padding: { md: "2px 20px", xs: "3px 6px" },
    fontSize: "12px",
    fontWeight: 500,
    color: { md: whiteColor, xs: whiteColor },
    border: {
      xs: "1.5px solid " + mobilePrimary,
      md: "1.5px solid " + blueColor04,
    },
    "&:hover": {
      color: { xs: mobilePrimary, md: blueColor04 },
      backgroundColor: { md: whiteColor, xs: whiteColor },
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },
  submitBtn: {
    backgroundColor: { xs: whiteColor, md: blackShade22 },
    padding: { md: "2px 20px", xs: "3px 6px" },
    fontSize: "12px",
    fontWeight: 500,
    color: { md: whiteColor, xs: mobilePrimary },
    border: {
      xs: "1.5px solid " + mobilePrimary,
      md: "1.5px solid " + blackShade22,
    },
    "&:hover": {
      color: { xs: mobilePrimary, md: blackShade22 },
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },
};
