import { Box, Grid, Typography, Button, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import OrdersInfo from "./InfoCards/OrdersInfo";
import CatalogInfoCard from "./InfoCards/CatalogInfoCard";
import InventoryInfoCard from "./InfoCards/InventoryInfoCard";
import CustomerTractionInfoCard from "./InfoCards/CustomerTractionInfoCard";
import AvaiableDiscountsInfoCard from "./InfoCards/AvaiableDiscountsInfoCard";
import RecentOrdersInfoCard from "./InfoCards/RecentOrdersInfoCard";
import AffiliatesProgramInfoCard from "./InfoCards/AffiliatesProgramInfoCard";
import SupplyChainFintechCard from "./InfoCards/SupplyChainFintechCard";
import MarketingInfoCard from "./InfoCards/MarketingInfoCard";
import { WhatsappShareButton } from "react-share";
import { useNavigate } from "react-router-dom";
import {
  BGColor02,
  bgColor15,
  blackShade05,
  blackShade09,
  blackShade19,
  border25,
  greenColor2,
  greyColor059,
  greyColor23,
  mobilePrimary,
  orangeStatusColor,
  whatsappColor,
  whiteColor,
  yellowColor,
} from "../../configs/styles/muiThemes";
import PaycartOrdersCard from "./InfoCards/PaycartOrdersCard";
import CashbackInfoCard from "./InfoCards/CashbackInfoCard";
import PreferredDiscountsCard from "./InfoCards/PreferredDiscountsCard";

import CopyTextWithIcon from "../../components/CopyTextWithIcon";
import HowToLinkCard from "../../components/Cards/HowToLinkCard";
import EasyCartEmptyCard from "./EmptyCards/EasyCartEmptyCard";
import { stickyHeader } from "../../utils/styles";
import DashboardBanners from "./InfoCards/DashboardBanners";

import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import useDynamicColor from "../../hooks/useDynamicColor";
import ModalCard from "../../components/ModalCard";

import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { getBuyerAppLookupAPI } from "../../service/api_calls/BusinessPreview/BusinessPreviewAPI";
import { useDispatch, useSelector } from "react-redux";
import WarehouseContactInfoCard from "./InfoCards/WarehouseContactInfoCard";
import DelegateContactInfoCard from "./InfoCards/DelegateContactInfoCard";
import OwnerContactInfoCard from "./InfoCards/OwnerContactInfoCard";
import MobileContactDetailsCard from "./MObileInfoCards/MobileContactDetailsCard";
import MobileInfoCardHeader from "./MObileInfoCards/MobileInfoCardHeader";
import MobileCashBackCard from "./MObileInfoCards/MobileCashBackCard";
import MobileCustomerTraction from "./MObileInfoCards/MobileCustomerTraction";
import MobilePrefferedDiscounts from "./MObileInfoCards/MobilePrefferedDiscounts";
import MobileAffiliateMarketingCard from "./MObileInfoCards/MobileAffiliateMarketingCard";
import MobileEasyCartOrders from "./MObileInfoCards/MobileEasyCartOrders";
import MobileSupplyChainTech from "./MObileInfoCards/MobileSupplyChainTech";
import MobileEasyCartEmptyCard from "./EmptyCards/MobileEasyCartEmptyCard";
import MobileTotalSalesOndcCard from "./MObileInfoCards/MobileTotalSalesOndcCard";
import MobileCouponsCard from "./MObileInfoCards/MobileCouponsCard";
import AddBranchPopup from "./Popup/AddBranchPopup";
import { DEFAULT_BRANCH_DATA } from "../../utils/data";
import {
  prepareAddNewBranchData,
  prepareUpdateBusinessData,
} from "../../utils/postDataHelpers/DashboardHelper";
import {
  addNewBranchApi,
  updateBusinessWRTBranchApi,
} from "../../service/api_calls/dashboard/dashboardApi";
import { getActiveBusinessInfo } from "../../reducers/businessPreview/businessPreviewSlice";
import MultiBranchSection from "./InfoCards/MultiBranchSection";
import { getProfileId } from "../../utils/validationUtils";
import { getBranchInfoAPI } from "../../service/api_calls/multiBranchCatalog/multiBranchCatalogAPIs";

function BusinessDashboardContent({
  businessData,
  dashboardData,
  commerceInfo,
  showBusinessDetails,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [buyerlinkModal, setBuyerlinkModal] = useState({
    show: false,
    data: "",
  });

  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();

  const mainColor = useDynamicColor();

  const [buyerLinks, setBuyerLinks] = useState(false);

  const [addBranch, setAddBranch] = useState(false);
  const [addBranchLoader, setAddBranchLoader] = useState(false);

  const [branchData, setBranchData] = useState(DEFAULT_BRANCH_DATA);

  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  const { masterBranchInfo, masterBranchInfoLoader } = useSelector(
    (state) => state.multiBranchCatalog
  );

  const profileId = getProfileId();
  const dispatch = useDispatch();

  const [branchDetails, setBranchDetails] = useState(null);
  const [branchDetailsLoader, setBranchDetailsLoader] = useState(false);

  const handleNavigation = (route) => {
    navigate(`/${businessData?.costboWebURL}/${route}`);
  };

  const handleValueChange = (state, setState, name, value) => {
    // let errors = { ...state?.errors };
    // if (value) {
    //   errors = { ...errors, [name]: "" };
    // }

    setState({
      ...state,
      [name]: value,
      // errors,
    });
  };

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const autoFillBuyerLinks = async () => {
    let buyerLinkList = [];

    if (commerceInfo?.info?.ondc?.buyerAppLinks?.length > 0) {
      const buyerResponse = await getBuyerAppLookupAPI();

      if (buyerResponse.details) {
        commerceInfo?.info?.ondc?.buyerAppLinks?.forEach((obj1) => {
          // Find the matching object from arr2 based on the 'code' property
          const matchedObj = buyerResponse.details.find(
            (obj2) => obj2.bapName === obj1.code
          );
          // If a matching object is found, merge its properties with obj1
          if (matchedObj) {
            buyerLinkList?.push({
              ...obj1,
              ...matchedObj,
            });
          }
        });
      }
    }

    console.log("buyerLinks__", buyerLinkList, commerceInfo);
    setBuyerLinks(buyerLinkList);
  };

  const onWhatsappClick = () => {
    let webLinks = "%0A%0AWeb links";
    let appLinks = "%0A%0AApp links";

    buyerLinks?.forEach((buyerLink) => {
      if (buyerLink?.type === "link") {
        webLinks =
          webLinks +
          `%0A${buyerLink?.displayName}%0A${buyerLink?.value?.replace(
            /&/g,
            "%26"
          )}`;
      }
      if (buyerLink?.type === "app") {
        appLinks =
          appLinks +
          `%0A${buyerLink?.displayName}%0A${buyerLink?.value?.replace(
            /&/g,
            "%26"
          )}`;
      }
    });

    const message = `Dear Customer,%0A%0AExciting news! We are thrilled to announce that "${
      activeBusinessInfo?.name
    }" is now live on the ONDC Network. Get ready to unlock fantastic discounts and elevate your savings to new heights!
    %0A%0ATo make the most of this opportunity, simply place your order through any of the following buyer apps of your preference
    ${appLinks != "%0A%0AApp links" ? appLinks : ""}
    ${webLinks != "%0A%0AWeb links" ? webLinks : ""}
     
     %0A%0ADon't miss out on this chance to enjoy premium quality products at unbeatable prices. Thank you for choosing us.
     %0A%0AWarm regards,%0A${activeBusinessInfo?.name}
    `;

    window.open(`https://web.whatsapp.com/send?text=${message}`, "_blank");
  };

  const cancelAddBranch = () => {
    setBranchData(DEFAULT_BRANCH_DATA);
    setAddBranch(false);
  };

  const addNewBranch = async () => {
    let errors = null;

    if (!branchData?.branchName) {
      errors = { ...errors, branchName: "Please enter branch name" };
    }

    if (!branchData?.gstNumber) {
      errors = { ...errors, gstNumber: "Please enter GST number" };
    }
    if (
      !/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
        branchData?.gstNumber
      )
    ) {
      errors = { ...errors, gstNumber: "Please enter GST number" };
    }

    if (!branchData?.registeredBusinessName) {
      errors = {
        ...errors,
        registeredBusinessName: "Please enter registered business name",
      };
    }

    if (!branchData?.panNumber) {
      errors = { ...errors, panNumber: "Please enter PAN number" };
    }

    if (!/[A-Z]{5}\d{4}[A-Z]{1}/.test(branchData?.panNumber)) {
      errors = { ...errors, panNumber: "Please enter PAN number" };
    }

    if (!branchData?.pinCode) {
      errors = { ...errors, pinCode: "Please enter pincode" };
    }

    if (!branchData?.address) {
      errors = { ...errors, address: "Please enter address" };
    }

    if (!branchData?.city) {
      errors = { ...errors, city: "Please enter city" };
    }

    if (!branchData?.state) {
      errors = { ...errors, state: "Please enter state" };
    }

    if (!branchData?.phoneNumber) {
      errors = { ...errors, phoneNumber: "Please enter valid phone number" };
    }

    if (branchData?.phoneNumber?.length < 10) {
      errors = { ...errors, phoneNumber: "Please enter valid phone number" };
    }

    if (!branchData?.email) {
      errors = { ...errors, email: "Please enter Email" };
    }

    if (errors) {
      handleValueChange(branchData, setBranchData, "errors", errors);
      return;
    }

    handleValueChange(branchData, setBranchData, "errors", null);

    console.log("ADD_NEW_BRANCH_DATA: ", branchData);

    setAddBranchLoader(true);

    const businessId = sessionStorage.getItem("selectedBusinessId");

    const postData = prepareAddNewBranchData(branchData, businessId);

    console.log("ADD_NEW_BRANCH_PAYLOAD_DATA: ", postData);

    const addBranchResponse = await addNewBranchApi(postData, businessId);

    if (addBranchResponse) {
      console.log("ADD_NEW_BRANCH_RESPONSE", addBranchResponse);
      const updateBusinessData = prepareUpdateBusinessData(
        branchData,
        addBranchResponse?.branchId,
        businessId
      );

      const updateBusinessResponse = await updateBusinessWRTBranchApi(
        updateBusinessData,
        businessId
      );
      if (updateBusinessResponse) {
        setAddBranchLoader(false);
        dispatch(getActiveBusinessInfo(businessId));
        setBranchData(DEFAULT_BRANCH_DATA);
        // Refresh Sub-branch info
        getBusinessDetails();
        setAddBranch(false);
      }
    }
  };

  const getBusinessDetails = async () => {
    setBranchDetailsLoader(true);
    const businessId = sessionStorage.getItem("selectedBusinessId");

    const branchListData = await getBranchInfoAPI(businessId);
    setBranchDetails(branchListData?.details?.branchInfo || []);
    setBranchDetailsLoader(false);
  };

  useEffect(() => {
    getBusinessDetails();

    return () => {};
  }, []);

  useEffect(() => {
    if (commerceInfo?.info) {
      autoFillBuyerLinks();
    }
  }, [commerceInfo]);

  return (
    <Box sx={{ flex: 1 }}>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        gap={1.5}
        sx={stickyHeader}
        pb={{ xs: 1, md: 0 }}
      >
        <Box width="100%">
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            gap={1}
            mb={{ xs: 0.4, md: 0 }}
          >
            <Typography
              component="h4"
              fontSize={{ xs: 16, md: 17 }}
              color={blackShade05}
              fontWeight={600}
              lineHeight="28px"
            >
              Dashboard - {businessData?.name}
            </Typography>

            {!matches && (
              <Box mr={-2} mt={0.2}>
                <ActionLinks
                  handleNavigation={handleNavigation}
                  navigate={navigate}
                />
              </Box>
            )}
          </Box>

          <CopyTextWithIcon
            text={`https://shop.costbo.com/${businessData?.costboWebURL}`}
            onLinkClick={() => {
              window.open(
                `https://shop.costbo.com/${businessData?.costboWebURL}`,
                "_blank"
              );
            }}
          />

          {matches && (
            <OndcBuyerAppLinks
              buyerLinks={buyerLinks}
              onWhatsappClick={onWhatsappClick}
              setBuyerlinkModal={setBuyerlinkModal}
            />
          )}
        </Box>

        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {businessData &&
            !businessData?.mainBranch &&
            businessData?.mainBranchId &&
            !masterBranchInfoLoader &&
            matches && (
              <MasterBranchInfoCard
                logoUrl={masterBranchInfo?.details?.logoURL}
                businessName={masterBranchInfo?.details?.masterBranchName}
                branchCount={masterBranchInfo?.details?.branchCount || 0}
              />
            )}

          {matches && (
            <Box mt={0.7}>
              <ActionLinks
                handleNavigation={handleNavigation}
                navigate={navigate}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box>
        {!matches && (
          <>
            <Box width="100%">
              <OndcBuyerAppLinks
                buyerLinks={buyerLinks}
                onWhatsappClick={onWhatsappClick}
                setBuyerlinkModal={setBuyerlinkModal}
              />
            </Box>

            {businessData &&
              !businessData?.mainBranch &&
              businessData?.mainBranchId &&
              !masterBranchInfoLoader &&
              !matches && (
                <MasterBranchInfoCard
                  logoUrl={masterBranchInfo?.details?.logoURL}
                  businessName={masterBranchInfo?.details?.masterBranchName}
                  branchCount={masterBranchInfo?.details?.branchCount || 0}
                />
              )}
          </>
        )}
      </Box>

      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {activeBusinessInfo?.branchInfo?.length == 0 &&
        activeBusinessInfo?.mainBranchId === "" &&
        profileId === activeBusinessInfo?.owner?.profileId ? (
          <Button
            sx={styles.addIconBtn}
            variant="contained"
            disableElevation
            onClick={() => setAddBranch(true)}
            startIcon={<AddIcon />}
          >
            Add Branch
          </Button>
        ) : null}
      </Box>

      {activeBusinessInfo &&
      activeBusinessInfo?.branchInfo?.length > 0 &&
      profileId === activeBusinessInfo?.owner?.profileId ? (
        <MultiBranchSection
          branchDetails={branchDetails}
          onAddNewBranch={() => setAddBranch(true)}
          businessData={activeBusinessInfo}
        />
      ) : null}

      <Box>
        <Grid
          container
          rowSpacing={{ xs: 1.5, md: 1.5 }}
          columnSpacing={{ xs: 2, md: 1.5 }}
        >
          {!activeBusinessInfo?.mainBranch && (
            // && activeBusinessInfo?.branchInfo?.length === 0
            <Grid item md={12} xs={12}>
              <DashboardBanners />
            </Grid>
          )}

          {!matches && (
            <Grid item md={12} xs={12}>
              <MobileTotalSalesOndcCard
                orderSummary={dashboardData?.orderSummary?.details}
                commerceInfo={commerceInfo}
              />
            </Grid>
          )}

          <Grid item md={12} xs={12}>
            <OrdersInfo
              orderSummary={dashboardData?.orderSummary?.details}
              recentOrders={
                dashboardData?.orderSummary?.details?.recentOrderSummary
              }
              onClick={() => {
                handleNavigation("orders");
              }}
            />
          </Grid>
          {matches && (
            <>
              <Grid item md={12} xs={12}>
                <SectionHeader title="Contact Detail" mb={0} />
              </Grid>

              <Grid item md={9} xs={12} sx={{ width: "100%" }}>
                <WarehouseContactInfoCard
                  activeBusinessInfo={activeBusinessInfo}
                  commerceInfo={commerceInfo?.info}
                  onManageClick={() => {
                    handleNavigation("managers");
                  }}
                />
              </Grid>

              {!matches && (
                <Grid item md={3} xs={12} sx={{ width: "100%" }}>
                  <OwnerContactInfoCard
                    activeBusinessInfo={activeBusinessInfo}
                  />
                </Grid>
              )}

              <Grid
                item
                md={3}
                xs={12}
                display="flex"
                alignItems="stretch"
                justifyContent={"center"}
                flexGrow={1}
                sx={{ width: "100%" }}
              >
                <DelegateContactInfoCard
                  activeBusinessInfo={activeBusinessInfo}
                />
              </Grid>
            </>
          )}
          {matches && (
            <Grid item md={12} xs={12}>
              <SectionHeader title="Catalog & Inventory" mb={0} />
            </Grid>
          )}
          <Grid
            item
            md={4.2}
            xs={12}
            display="flex"
            alignItems="stretch"
            justifyContent={"center"}
            flexGrow={1}
            sx={{ width: "100%" }}
          >
            <CatalogInfoCard
              productData={dashboardData?.inventorySummary}
              onClick={() => {
                handleNavigation("catalog");
              }}
            />
          </Grid>
          {!matches && (
            <Grid
              item
              md={3}
              xs={12}
              display="flex"
              alignItems="stretch"
              justifyContent={"center"}
              flexGrow={1}
              sx={{ width: "100%" }}
            >
              <MobileContactDetailsCard
                activeBusinessInfo={activeBusinessInfo}
                commerceInfo={commerceInfo?.info}
                onManageClick={() => {
                  handleNavigation("managers");
                }}
              />
            </Grid>
          )}
          <Grid
            item
            md={5}
            xs={12}
            display="flex"
            alignItems="stretch"
            justifyContent={"center"}
            flexGrow={1}
            sx={{ width: "100%" }}
          >
            <InventoryInfoCard
              productData={dashboardData?.inventorySummary}
              onClick={() => {
                handleNavigation("allStock");
              }}
              onPriceUpdateClick={() => {
                handleNavigation("priceUpdate");
              }}
            />
          </Grid>
          {matches && (
            <>
              <Grid
                item
                md={2.8}
                xs={12}
                display="flex"
                alignItems="stretch"
                justifyContent={"center"}
                flexGrow={1}
                sx={{ width: "100%" }}
              >
                <CustomerTractionInfoCard
                  view={dashboardData?.businessViews}
                  followers={dashboardData?.followers}
                  reviewSummary={dashboardData?.reviewSummary}
                  allOrders={dashboardData?.allOrders}
                  orderSummary={dashboardData?.orderSummary?.details}
                  onClick={() => {}}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <SectionHeader title="Order Details" />
              </Grid>
            </>
          )}
          {!matches && (
            <>
              <Grid item md={12} xs={12}>
                <MobileInfoCardHeader title="Customer Traction" hideBtn />
              </Grid>
              <Grid
                item
                md={2.8}
                xs={12}
                display="flex"
                alignItems="stretch"
                justifyContent={"center"}
                flexGrow={1}
                sx={{ width: "100%" }}
              >
                <MobileCustomerTraction
                  view={dashboardData?.businessViews}
                  followers={dashboardData?.followers}
                  reviewSummary={dashboardData?.reviewSummary}
                  allOrders={dashboardData?.allOrders}
                  orderSummary={dashboardData?.orderSummary?.details}
                  onClick={() => {}}
                />
              </Grid>
            </>
          )}
          <Grid item md={8} xs={12}>
            <RecentOrdersInfoCard
              recentOrders={
                dashboardData?.orderSummary?.details?.recentOrderSummary
              }
              onClick={() => {
                handleNavigation("orders");
              }}
            />
          </Grid>
          {!matches && (
            <Grid item md={4} xs={12}>
              <MobileInfoCardHeader
                title="Easy Cart Orders"
                onManageClick={() => {
                  handleNavigation("easyCartOrders");
                }}
              />
            </Grid>
          )}
          {matches && (
            <Grid item md={4} xs={12}>
              {Number(
                dashboardData?.paycartSummary?.details?.totalPaycartcreated ===
                  0
              ) ? (
                <EasyCartEmptyCard
                  onClick={() => {
                    handleNavigation("easyCartOrders");
                  }}
                />
              ) : (
                <PaycartOrdersCard
                  paycartData={dashboardData?.paycartSummary?.details}
                  onClick={() => {
                    handleNavigation("easyCartOrders");
                  }}
                />
              )}
            </Grid>
          )}
          {!matches && (
            <Grid item md={4} xs={12}>
              {Number(
                dashboardData?.paycartSummary?.details?.totalPaycartcreated
              ) === 0 ? (
                <MobileEasyCartEmptyCard
                  onClick={() => {
                    handleNavigation("easyCartOrders");
                  }}
                />
              ) : (
                <MobileEasyCartOrders
                  paycartData={dashboardData?.paycartSummary?.details}
                  onClick={() => {
                    handleNavigation("easyCartOrders");
                  }}
                />
              )}
            </Grid>
          )}
          {!matches && (
            <Grid
              item
              md={4}
              xs={12}
              display="flex"
              alignItems="stretch"
              justifyContent={"center"}
              flexGrow={1}
              sx={{ width: "100%" }}
            >
              <MobileCouponsCard
                discountData={dashboardData?.allCoupons}
                couponSummary={dashboardData?.couponSummary}
                couponCount={dashboardData?.couponCounts}
                onClick={() => {
                  handleNavigation("couponsDiscount");
                }}
              />
            </Grid>
          )}
          {!matches && (
            <>
              <Grid item md={12} xs={12}>
                <MobileInfoCardHeader
                  title="Loyalty Points"
                  onManageClick={() => {
                    handleNavigation("cashBack");
                  }}
                />
              </Grid>
              <Grid
                item
                md={3.7}
                xs={12}
                sx={{ width: "100%" }}
                display="flex"
                alignItems="stretch"
                justifyContent={"center"}
                flexGrow={1}
              >
                <MobileCashBackCard
                  businessData={businessData}
                  cashbackCount={dashboardData?.cashbackCount?.details}
                  cashbackSummary={dashboardData?.cashbackSummary?.details}
                  availableCashback={dashboardData?.availabelCashbackValue}
                  onClick={() => {
                    handleNavigation("cashBack");
                  }}
                />
              </Grid>
            </>
          )}
          {matches && (
            <>
              <Grid item md={12} xs={12}>
                <SectionHeader title="Coupons & Discount" mb={0} />
              </Grid>
              <Grid
                item
                md={3.7}
                xs={12}
                sx={{ width: "100%" }}
                display="flex"
                alignItems="stretch"
                justifyContent={"center"}
                flexGrow={1}
              >
                <AvaiableDiscountsInfoCard
                  discountData={dashboardData?.allCoupons}
                  couponSummary={dashboardData?.couponSummary}
                  couponCount={dashboardData?.couponCounts}
                  onClick={() => {
                    handleNavigation("couponsDiscount");
                  }}
                />
              </Grid>
            </>
          )}
          {matches && (
            <Grid
              item
              md={4.3}
              xs={12}
              display="flex"
              alignItems="stretch"
              justifyContent={"center"}
              flexGrow={1}
              sx={{ width: "100%" }}
            >
              <CashbackInfoCard
                businessData={businessData}
                cashbackCount={dashboardData?.cashbackCount?.details}
                cashbackSummary={dashboardData?.cashbackSummary?.details}
                availableCashback={dashboardData?.availabelCashbackValue}
                onClick={() => {
                  handleNavigation("cashBack");
                }}
              />
            </Grid>
          )}
          {matches && (
            <Grid
              item
              md={4}
              xs={12}
              display="flex"
              alignItems="stretch"
              justifyContent={"center"}
              flexGrow={1}
              sx={{ width: "100%" }}
            >
              <PreferredDiscountsCard
                preferredDiscountCount={
                  dashboardData?.preferredDiscountCount?.details
                }
                preferredDiscountSummary={
                  dashboardData?.preferredDiscountSummary?.details
                }
                onClick={() => {
                  handleNavigation("preferredDiscounts");
                }}
              />
            </Grid>
          )}

          {activeBusinessInfo?.mainBranch && matches && (
            // && activeBusinessInfo?.branchInfo?.length > 0
            <Grid item md={12} xs={12}>
              <DashboardBanners />
            </Grid>
          )}

          {!matches && (
            <>
              <Grid
                item
                md={3.7}
                xs={12}
                sx={{ width: "100%" }}
                display="flex"
                alignItems="stretch"
                justifyContent={"center"}
                flexGrow={1}
              >
                <MobilePrefferedDiscounts
                  preferredDiscountCount={
                    dashboardData?.preferredDiscountCount?.details
                  }
                  preferredDiscountSummary={
                    dashboardData?.preferredDiscountSummary?.details
                  }
                  onClick={() => {
                    handleNavigation("preferredDiscounts");
                  }}
                />
              </Grid>
            </>
          )}
          {matches && (
            <Grid item md={6} xs={12}>
              <SectionHeader title="Affiliates Program" styles={{ mb: 1 }} />
              {dashboardData?.uniqueAffiliateCount?.uniqueAffiliateCount > 0 ? (
                <AffiliatesProgramInfoCard
                  affiliateSaleInfo={dashboardData?.affiliateSaleInfo}
                  uniqueAffiliateCount={dashboardData?.uniqueAffiliateCount}
                  affiliateShareCount={dashboardData?.affiliateShareCount}
                  onClick={() => {
                    handleNavigation("affiliateMarketing");
                  }}
                />
              ) : (
                <MarketingInfoCard
                  slide={1}
                  features={[
                    "Increased customer reach",
                    "More brand Visibility",
                    "Success fee based model",
                  ]}
                />
              )}
            </Grid>
          )}
          {!matches && (
            <Grid item md={6} xs={12}>
              {dashboardData?.uniqueAffiliateCount?.uniqueAffiliateCount > 0 ? (
                <MobileAffiliateMarketingCard
                  affiliateSaleInfo={dashboardData?.affiliateSaleInfo}
                  uniqueAffiliateCount={dashboardData?.uniqueAffiliateCount}
                  affiliateShareCount={dashboardData?.affiliateShareCount}
                  onClick={() => {
                    handleNavigation("affiliateMarketing");
                  }}
                />
              ) : (
                <MarketingInfoCard
                  slide={1}
                  features={[
                    "Increased customer reach",
                    "More brand Visibility",
                    "Success fee based model",
                  ]}
                />
              )}
            </Grid>
          )}
          {matches && (
            <Grid item md={6} xs={12}>
              <SectionHeader title="Supply Chain Fintech" styles={{ mb: 1 }} />
              {commerceInfo?.info?.fintech ? (
                <SupplyChainFintechCard
                  onClick={() => {
                    handleNavigation("fintechB2BCS");
                  }}
                />
              ) : (
                <MarketingInfoCard
                  slide={2}
                  features={[
                    "Deep shipment integration",
                    `Payments upon delivery, \nGuaranteed`,
                    "Build a direct dealer network",
                  ]}
                />
              )}
            </Grid>
          )}
          {!matches && (
            <Grid item md={6} xs={12}>
              {commerceInfo?.info?.fintech ? (
                <MobileSupplyChainTech
                  onClick={() => {
                    handleNavigation("fintechB2BCS");
                  }}
                />
              ) : (
                <MarketingInfoCard
                  slide={2}
                  features={[
                    "Deep shipment integration",
                    `Payments upon delivery, \nGuaranteed`,
                    "Build a direct dealer network",
                  ]}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Box>

      <ModalCard
        open={buyerlinkModal?.show}
        handleClose={() => {
          setBuyerlinkModal(null);
        }}
        width="45%"
      >
        <Box sx={{ background: whiteColor, borderRadius: "10px" }}>
          <Box
            sx={{
              backgroundColor: BGColor02,
              p: "10px 15px",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Typography
              fontSize={17}
              fontWeight={600}
              color={blackShade05}
              textAlign={"center"}
              textTransform={"capitalize"}
            >
              {buyerlinkModal?.data?.displayName}
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"} p={2} gap={2}>
            <Typography
              color={greenColor2}
              fontSize={{ xs: 13, md: 13 }}
              fontWeight={600}
              sx={{
                width: "90%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
              borderBottom={"1px solid #e5e9f0"}
            >
              {buyerlinkModal?.data?.value}
            </Typography>

            {!isCopied ? (
              <Button
                onClick={() => {
                  handleCopy(buyerlinkModal?.data?.value);
                }}
                sx={styles.actionBtn}
              >
                <ContentCopyOutlinedIcon
                  sx={{ fontSize: { xs: 18, md: 18 } }}
                />
              </Button>
            ) : (
              <Button onClick={() => {}} sx={styles.copySuccessBtn}>
                <CheckRoundedIcon sx={{ fontSize: { xs: 18, md: 18 } }} />
              </Button>
            )}
          </Box>
        </Box>
      </ModalCard>

      <ModalCard open={addBranch} handleClose={cancelAddBranch} width="45%">
        <AddBranchPopup
          businessName={activeBusinessInfo?.name}
          data={branchData}
          setData={setBranchData}
          onValueChange={(name, value) => {
            handleValueChange(branchData, setBranchData, name, value);
          }}
          onCancel={cancelAddBranch}
          onSubmit={addNewBranch}
          loader={addBranchLoader}
        />
      </ModalCard>
    </Box>
  );
}

export default BusinessDashboardContent;

const SectionHeader = ({ title, styles }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="8px"
      sx={{ ...styles, mt: { xs: 0, md: 1 } }}
    >
      <Typography
        component="h3"
        fontSize={{ xs: 14.5, md: 14.5 }}
        color={greyColor23}
        fontWeight={600}
        lineHeight="25px"
      >
        {title}
      </Typography>

      {/* <InfoIcon sx={{ fontSize: "20px" }} /> */}
    </Box>
  );
};

const MasterBranchInfoCard = ({ logoUrl, businessName, branchCount }) => {
  return (
    <Box sx={styles.masterBranchCard}>
      <Box
        component="img"
        src={logoUrl}
        alt="Master Branch Logo"
        sx={{ width: "45px", height: "45px", borderRadius: "6px" }}
      />

      <Box>
        <Typography
          fontSize={14}
          fontWeight={600}
          color={blackShade19}
          lineHeight="24px"
          sx={{
            textDecoration: "underline",
            "&:hover": {
              textDecoration: "none",
            },
          }}
        >
          Head Quarters - {businessName}
        </Typography>

        <Typography
          fontSize={11}
          fontWeight={400}
          color={greyColor059}
          lineHeight="18px"
        >
          {branchCount} {branchCount === 1 ? "Branch" : "Branches"}
        </Typography>
      </Box>
    </Box>
  );
};

const OndcBuyerAppLinks = ({
  buyerLinks,
  onWhatsappClick,
  setBuyerlinkModal,
}) => {
  return (
    <Box>
      {buyerLinks?.length > 0 ? (
        <Box
          width={{ xs: "auto", md: "80%" }}
          display="flex"
          alignItems={{ md: "center", xs: "flex-start" }}
          flexWrap="wrap"
          columnGap={1.5}
          rowGap={1}
          pb={{ xs: 1, md: 0.5 }}
          flexDirection={{ md: "row", xs: "row" }}
          mt={1.7}
        >
          <Typography
            component="p"
            fontSize={{ xs: 14, md: 14.5 }}
            color={blackShade05}
            fontWeight={600}
            lineHeight="20px"
          >
            ONDC Buyer App Links -
          </Typography>
          {/* <Box display="flex" alignItems="center" gap={1.5} flexWrap='wrap'> */}
          {buyerLinks?.length > 0
            ? buyerLinks?.map((buyerLink, index) => (
                <Typography
                  key={index}
                  color={greenColor2}
                  fontSize={{ xs: 13, md: 13 }}
                  fontWeight={600}
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    textTransform: "capitalize",
                  }}
                  onClick={() =>
                    setBuyerlinkModal({ show: true, data: buyerLink })
                  }
                >
                  {buyerLink?.displayName}
                </Typography>
              ))
            : null}

          <Button
            onClick={() => {
              onWhatsappClick();
            }}
            sx={styles.whatsappBtn}
          >
            <Box
              component={"img"}
              src={
                "https://storage.googleapis.com/bodefaults/businessweb/whatsapp.svg"
              }
              width="17px"
              height="17px"
            />
          </Button>
          {/* </Box> */}
        </Box>
      ) : null}
    </Box>
  );
};

const ActionLinks = ({ handleNavigation, navigate }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={{ md: 5, xs: 1 }}
      sx={{ cursor: "pointer" }}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <SettingsRoundedIcon
          sx={{
            color: { md: orangeStatusColor, xs: mobilePrimary },
            fontSize: { md: "18px", xs: "20px" },
          }}
          onClick={() => {
            handleNavigation("warehouseDetail");
          }}
        />
        <Typography
          fontSize={14}
          fontWeight={500}
          color={orangeStatusColor}
          sx={{
            whiteSpace: "nowrap",
            textDecoration: "underline",
            display: { md: "block", xs: "none" },
          }}
          onClick={() => {
            handleNavigation("warehouseDetail");
          }}
        >
          E-Commerce Settings
        </Typography>
      </Box>
      <HowToLinkCard
        link="Video Guides"
        root
        handleClick={() => {
          navigate("/howToVideoGuides", {
            state: { status: "all" },
          });
        }}
      />
    </Box>
  );
};

const styles = {
  actionBtn: {
    width: "40px",
    height: "35px",
    minWidth: "0",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: whiteColor,
    color: bgColor15,
    border: `1px solid ${bgColor15}`,
    "&:hover": {
      background: bgColor15,
      color: whiteColor,
    },
  },
  whatsappBtn: {
    width: "25px",
    height: "25px",
    color: whiteColor,
    minWidth: "0",
    p: "0",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: whatsappColor,
    border: `1px solid ${whatsappColor}`,
    "&:hover": {
      background: whatsappColor,
    },
  },
  copySuccessBtn: {
    width: "40px",
    height: "35px",
    color: whiteColor,
    minWidth: "0",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: whatsappColor,
    border: `1px solid ${whatsappColor}`,
    "&:hover": {
      background: whatsappColor,
    },
  },
  addIconBtn: {
    padding: { xs: "3px 14px", md: "5px 16px" },
    // width: { md: "auto", xs: "auto" },
    fontSize: { xs: 11.5, md: "12.5px" },
    fontWeight: 600,
    backgroundColor: { xs: whiteColor, md: blackShade19 },
    color: { xs: mobilePrimary, md: whiteColor },
    border: {
      xs: "1px solid " + mobilePrimary,
      md: "1px solid " + blackShade19,
    },
    mt: { xs: 2.2, md: 0 },
    "&:hover": {
      backgroundColor: whiteColor,
      color: { xs: mobilePrimary, md: blackShade19 },
    },

    "&.Mui-disabled": {
      border: "none",
    },
  },

  masterBranchCard: {
    display: "flex",
    alignContent: "center",
    gap: 1.4,
    mb: 1,
    bgcolor: { xs: whiteColor, md: "transparent" },
    borderRadius: "8px",
    border: { xs: border25, md: "none" },
    p: { xs: "10px", md: 0 },
    cursor: "pointer",
  },
};
