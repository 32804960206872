import { Box, Button, IconButton } from "@mui/material";
import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  blackShade09,
  blackShade159,
  blueColor04,
  greyColor6,
  mobilePrimary,
  whiteColor,
  yellowColor,
} from "../../../configs/styles/muiThemes";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";

function NavigationButtons({ hideBackBtn, onNextClick, onBackClick }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {!hideBackBtn ? (
        <IconButton
          sx={styles.backBtn}
          variant="contained"
          disableElevation
          onClick={onBackClick}
        >
          <SkipPreviousIcon sx={{ fontSize: "20px", color: greyColor6 }} />
        </IconButton>
      ) : (
        <Box></Box>
      )}

      <Button
        sx={styles.btn}
        variant="contained"
        disableElevation
        onClick={onNextClick}
      >
        NEXT
      </Button>
    </Box>
  );
}

export default NavigationButtons;

const styles = {
  btn: {
    backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    padding: { xs: "4px 22px", md: "4px 24px" },
    fontSize: { xs: "12px", md: "14px" },
    color: whiteColor,
    borderRadius: "6px",
    fontWeight: 500,
    border: { xs: `1px solid ${mobilePrimary}`, md: `1px solid ${blueColor04}` },
    "&:hover": {
      backgroundColor: whiteColor,
      color: { xs: mobilePrimary, md: blueColor04 },
    },
  },

  backBtn: {
    background: whiteColor,
    padding: { xs: "4px 10px", md: "4px 12px" },
    fontSize: { xs: "12px", md: "14px" },
    color: blackShade159,
    border: `1px solid ${greyColor6}`,
    borderRadius: "6px",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: whiteColor,
      color: blackShade159,
    },
  },
};
