import moment from "moment";
import {
  calculateEndDate,
  calculateSalePrice,
} from "../../utils/validationHelper";
import { calculateDaysDifference } from "../../utils/validationUtils";

// Prepare Low and Out of Stock Data
export const prepareLowAndOOSProducts = (productList) => {
  if (productList?.length > 0) {
    return productList?.map((item) => {
      return {
        ...item,
        errors: [],
        itemChange: false,
        currentStock: item?.availableQuantity,
      };
    });
  }

  return [];
};

// Filter Price Update
export const filterPriceUpdateProducts = (productList) => {
  if (productList?.length > 0) {
    return productList
      ?.filter((item) => item?.availability?.[0] == "Yes")
      .map((item) => {
        const commerceStartDate = item?.commerceStartDate || "";
        const commerceEndDate = item?.commerceEndDate || "";

        const validityDays = calculateDaysDifference(
          commerceStartDate,
          commerceEndDate
        );

        return {
          ...item,
          errors: [],
          itemChange: false,
          currentStock: item?.availableQuantity,
          priceOff: Number(item?.percentageOff) || "0",
          validityDays: validityDays || "",
        };
      });
  }

  return [];
};

// Update Price Info Input Change
export const updatePriceInputDetails = (priceUpdateProducts, value, index) => {
  console.log("updatePriceInputDetails___:", {
    priceUpdateProducts,
    value,
    index,
  });

  const updatedProduct = { ...priceUpdateProducts[index] };

  if (value.type === "priceOff" && value?.value) {
    const originalPrice = Number(updatedProduct.productOriginalPrice);
    const priceOffPercentage = value.value;
    const calculatedSalePrice = calculateSalePrice(
      originalPrice,
      priceOffPercentage
    );

    updatedProduct[value.type] = value.value;
    updatedProduct.productSalePrice = calculatedSalePrice;
  } else if (value.type === "validityDays" && value?.value) {
    const startDate = updatedProduct.commerceStartDate;
    const daysCount = value.value;
    const calculatedEndDate = calculateEndDate(startDate, daysCount);

    updatedProduct[value.type] = value.value;
    updatedProduct.commerceEndDate = calculatedEndDate;
  } else if (value.type === "productOriginalPrice" && value?.value) {
    const originalPrice = Number(value?.value);
    const priceOffPercentage = Number(updatedProduct?.priceOff);

    const calculatedSalePrice = calculateSalePrice(
      originalPrice,
      priceOffPercentage
    );

    updatedProduct[value.type] = value.value;
    updatedProduct.productSalePrice = calculatedSalePrice;
  } else {
    updatedProduct[value.type] = value.value;
  }

  if (value.type !== "itemChange") {
    updatedProduct.itemChange = true;
  }

  const updatedProductsList = [...priceUpdateProducts];
  updatedProductsList[index] = updatedProduct;

  return [...updatedProductsList];
};

// Bulk Price Info Update
export const updateQuickPriceInputDetails = (
  priceUpdateProducts,
  quickUpdateData
) => {
  const productList = [...priceUpdateProducts];
  const newProductList = [];

  productList.forEach((productInfo) => {
    const updatedProduct = { ...productInfo };

    if (updatedProduct?.itemChange) {
      if (quickUpdateData?.percentageOff) {
        const originalPrice = Number(updatedProduct.productOriginalPrice);
        const priceOffPercentage = quickUpdateData?.percentageOff;
        const calculatedSalePrice = calculateSalePrice(
          originalPrice,
          priceOffPercentage
        );

        updatedProduct["priceOff"] = quickUpdateData?.percentageOff;
        updatedProduct.productSalePrice = calculatedSalePrice;
      }

      if (quickUpdateData?.validityDays) {
        const startDate = updatedProduct.commerceStartDate;
        const daysCount = quickUpdateData?.validityDays;
        const calculatedEndDate = calculateEndDate(startDate, daysCount);

        updatedProduct["validityDays"] = quickUpdateData?.validityDays;
        updatedProduct.commerceEndDate = calculatedEndDate;
      }

      if (quickUpdateData?.gstPercentage) {
        updatedProduct["gstInfo"] = {
          code: updatedProduct?.gstInfo?.code,
          gstPercentage: quickUpdateData?.gstPercentage,
        };
      }

      if (quickUpdateData?.availability) {
        updatedProduct["availability"] = quickUpdateData?.availability;
      }

      if (quickUpdateData?.minQty) {
        updatedProduct["minOrderQuantity"] = quickUpdateData?.minQty;
      }

      if (quickUpdateData?.maxQty) {
        updatedProduct["maxOrderQuantity"] = quickUpdateData?.maxQty;
      }

      updatedProduct.itemChange = true;

      newProductList.push(updatedProduct);
    } else {
      newProductList.push(updatedProduct);
    }
  });

  console.log("updateQuickPriceInputDetails______:", newProductList);

  return newProductList;
};

// Validate Price Update Post Data
export const validatePriceInfoUpdate = (priceUpdateProducts) => {
  const productList = [...priceUpdateProducts];
  const newProductList = [];

  let isError = false;

  productList.forEach((productInfo, index) => {
    if (productInfo?.itemChange) {
      let errors = null;
      console.log("ProductUpdate itteration", index, productInfo);

      if (
        !productInfo?.productSalePrice ||
        !productInfo?.productOriginalPrice ||
        !productInfo?.commerceStartDate ||
        !productInfo?.commerceEndDate
      ) {
        errors = { ...errors, globalErr: "All fields are mandatory" };
        isError = true;
      }

      if (!productInfo?.productOriginalPrice) {
        errors = { ...errors, productOriginalPrice: "Enter Original Price" };
        isError = true;
      }

      if (!productInfo?.productSalePrice) {
        errors = { ...errors, productSalePrice: "Enter Sale Price" };
        isError = true;
      }

      if (!productInfo?.commerceStartDate) {
        errors = { ...errors, commerceStartDate: "Enter Start Date" };
        isError = true;
      }

      if (!productInfo?.commerceEndDate) {
        errors = { ...errors, commerceEndDate: "Enter End Date" };
        isError = true;
      }

      if (
        Number(productInfo?.productSalePrice) >
        Number(productInfo?.productOriginalPrice)
      ) {
        errors = {
          ...errors,
          productSalePrice:
            "Sales price should not be more than Original price",
        };
        isError = true;
      }

      // if (Number(productInfo?.validityDays) > 1000) {
      //   errors = {
      //     ...errors,
      //     validityDays:
      //       "Duration between availability start and end date should not be more than 1000 days",
      //   };
      //   isError = true;
      // }

      if (
        moment(productInfo?.commerceStartDate).isAfter(
          productInfo?.commerceEndDate
        )
      ) {
        errors = {
          ...errors,
          commerceEndDate:
            "Availability End date should not be prior than Start date",
        };
        isError = true;
      }

      const errorList = errors
        ? Object.entries(errors).map(([key, value]) => `${value}`)
        : [];

      newProductList.push({
        ...productInfo,
        errors,
        errorList,
      });
    } else {
      newProductList.push({
        ...productInfo,
        errors: null,
        errorList: [],
      });
    }
  });

  return {
    productList: newProductList,
    isError,
  };
};

// ***** All Stock *****

// Update Price Info Input Change
export const updateAllStockInputDetails = (
  priceUpdateProducts,
  value,
  index
) => {
  const updatedProduct = { ...priceUpdateProducts[index] };

  if (value.type === "priceOff" && value?.value) {
    const originalPrice = Number(updatedProduct.productOriginalPrice);
    const priceOffPercentage = value.value;
    const calculatedSalePrice = calculateSalePrice(
      originalPrice,
      priceOffPercentage
    );

    updatedProduct[value.type] = value.value;
    updatedProduct.productSalePrice = calculatedSalePrice;
  } else if (value.type === "validityDays" && value?.value) {
    const startDate = updatedProduct.commerceStartDate;
    const daysCount = value.value;
    const calculatedEndDate = calculateEndDate(startDate, daysCount);

    updatedProduct[value.type] = value.value;
    updatedProduct.commerceEndDate = calculatedEndDate;
  } else if (value.type === "productOriginalPrice" && value?.value) {
    const originalPrice = Number(value?.value);
    const priceOffPercentage = Number(updatedProduct?.priceOff);

    const calculatedSalePrice = calculateSalePrice(
      originalPrice,
      priceOffPercentage
    );

    updatedProduct[value.type] = value.value;
    updatedProduct.productSalePrice = calculatedSalePrice;
  } else {
    updatedProduct[value.type] = value.value;
  }

  if (value.type !== "itemChange") {
    updatedProduct.itemChange = true;
  }

  const updatedProductsList = [...priceUpdateProducts];
  updatedProductsList[index] = updatedProduct;

  return [...updatedProductsList];
};

// Validate Price Update Post Data
export const validateAllStockInfoUpdate = (allStockUpdateProducts) => {
  const productList = [...allStockUpdateProducts];
  const newProductList = [];

  let isError = false;

  productList.forEach((productInfo, index) => {
    if (productInfo?.itemChange && productInfo?.availability?.[0] == "Yes") {
      let errors = null;
      console.log("ProductUpdate itteration", index, productInfo);

      if (
        !productInfo?.availableQuantity ||
        Number(productInfo?.availableQuantity) == 0
      ) {
        errors = {
          ...errors,
          availableQuantity: "Quantity columns cannot be null or ‘0’.",
        };
        isError = true;
      }
      if (
        Number(productInfo?.availableQuantity) <
        Number(productInfo?.maxOrderQuantity)
      ) {
        errors = {
          ...errors,
          availableQuantity:
            "Available quantity should be greater than Max quantity per order.",
        };
        isError = true;
      }

      if (
        Number(productInfo?.minOrderQuantity) >
        Number(productInfo?.maxOrderQuantity)
      ) {
        errors = {
          ...errors,
          minOrderQuantity:
            "Min quantity per order should be less than or equal to Max quantity per order.",
        };
        isError = true;
      }

      if (
        Number(productInfo?.availableQuantity) <
        Number(productInfo?.alertQuantity)
      ) {
        errors = {
          ...errors,
          availableQuantity:
            "Available quantity should be more than Alert Quantity.",
        };
        isError = true;
      }

      if (
        !productInfo?.productSalePrice ||
        !productInfo?.productOriginalPrice ||
        !productInfo?.commerceStartDate ||
        !productInfo?.commerceEndDate
      ) {
        errors = { ...errors, globalErr: "All fields are mandatory" };
        isError = true;
      }

      if (!productInfo?.productOriginalPrice) {
        errors = { ...errors, productOriginalPrice: "Enter Original Price" };
        isError = true;
      }

      if (!productInfo?.productSalePrice) {
        errors = { ...errors, productSalePrice: "Enter Sale Price" };
        isError = true;
      }

      if (!productInfo?.commerceStartDate) {
        errors = { ...errors, commerceStartDate: "Enter Start Date" };
        isError = true;
      }

      if (!productInfo?.commerceEndDate) {
        errors = { ...errors, commerceEndDate: "Enter End Date" };
        isError = true;
      }

      if (
        Number(productInfo?.productSalePrice) >
        Number(productInfo?.productOriginalPrice)
      ) {
        errors = {
          ...errors,
          productSalePrice:
            "Sales price should not be more than Original price",
        };
        isError = true;
      }

      // if (Number(productInfo?.validityDays) > 1000) {
      //   errors = {
      //     ...errors,
      //     validityDays:
      //       "Duration between availability start and end date should not be more than 1000 days",
      //   };
      //   isError = true;
      // }

      if (
        moment(productInfo?.commerceStartDate).isAfter(
          productInfo?.commerceEndDate
        )
      ) {
        errors = {
          ...errors,
          commerceEndDate:
            "Availability End date should not be prior than Start date",
        };
        isError = true;
      }

      const errorList = errors
        ? Object.entries(errors).map(([key, value]) => `${value}`)
        : [];

      newProductList.push({
        ...productInfo,
        errors,
        errorList,
      });
    } else {
      newProductList.push({
        ...productInfo,
        errors: null,
        errorList: [],
      });
    }
  });

  return {
    productList: newProductList,
    isError,
  };
};

export const validateOutOfStockUpdate = (outOfStockProducts) => {
  const productList = [...outOfStockProducts];
  const newProductList = [];

  console.log("validateOutOfStockUpdate__BEFORE:", productList);

  let isError = false;

  productList.forEach((productInfo, index) => {
    if (productInfo?.itemChange) {
      let errors = null;
      console.log("ProductUpdate itteration", index, productInfo);

      // if (
      //   !productInfo?.availableQuantity ||
      //   !productInfo?.maxOrderQuantity ||
      //   !productInfo?.minOrderQuantity ||
      //   !productInfo?.alertQuantity
      // ) {
      //   errors = { ...errors, globalErr: "All fields are mandatory" };
      //   isError = true;
      // }

      if (
        !productInfo?.availableQuantity ||
        Number(productInfo?.availableQuantity) === 0
      ) {
        errors = {
          ...errors,
          availableQuantity: "Enter Available Quantity",
        };
        isError = true;
      }

      if (
        !productInfo?.maxOrderQuantity ||
        Number(productInfo?.maxOrderQuantity) === 0
      ) {
        errors = {
          ...errors,
          maxOrderQuantity: "Enter Max Order Quantity",
        };
        isError = true;
      }

      if (
        !productInfo?.minOrderQuantity ||
        Number(productInfo?.minOrderQuantity) === 0
      ) {
        errors = { ...errors, minOrderQuantity: "Enter Min Order Quantity" };
        isError = true;
      }

      if (
        !productInfo?.alertQuantity ||
        Number(productInfo?.alertQuantity) === 0
      ) {
        errors = { ...errors, alertQuantity: "Enter Alert Quantity" };
        isError = true;
      }

      if (
        Number(productInfo?.availableQuantity) <
        Number(productInfo?.maxOrderQuantity)
      ) {
        errors = {
          ...errors,
          availableQuantity:
            "Available quantity should be greater than Max quantity per order.",
        };
        isError = true;
      }

      if (
        Number(productInfo?.minOrderQuantity) >
        Number(productInfo?.maxOrderQuantity)
      ) {
        errors = {
          ...errors,
          minOrderQuantity:
            "Min quantity per order should be less than or equal to Max quantity per order.",
        };
        isError = true;
      }

      if (
        Number(productInfo?.availableQuantity) <
        Number(productInfo?.alertQuantity)
      ) {
        errors = {
          ...errors,
          availableQuantity:
            "Available quantity should be more than Alert Quantity.",
        };
        isError = true;
      }

      if (
        Number(productInfo?.alertQuantity) <
        Number(productInfo?.minOrderQuantity)
      ) {
        errors = {
          ...errors,
          alertQuantity: "Alert quantity must be more than Min order quantity",
        };
        isError = true;
      }

      if (
        Number(productInfo?.availableQuantity) <
          Number(productInfo?.alertQuantity) ||
        Number(productInfo?.availableQuantity) <
          Number(productInfo?.maxOrderQuantity)
      ) {
        errors = {
          ...errors,
          availableQuantity:
            "Available Quantity should be greater than Alert Quantity & Maximum Quantity Per Order",
        };
        isError = true;
      }

      const errorList = errors
        ? Object.entries(errors).map(([key, value]) => `${value}`)
        : [];

      newProductList.push({
        ...productInfo,
        errors,
        errorList,
      });
    } else {
      newProductList.push({
        ...productInfo,
        errors: null,
        errorList: [],
      });
    }
  });

  return {
    productList: newProductList,
    isError,
  };
};

// ***** Not Selling ******

// Filter Not Selling Products
export const filterNotSellingProducts = (productList) => {
  if (productList?.length > 0) {
    return productList
      ?.filter((item) => item?.availability?.[0] == "No")
      .map((item) => {
        const commerceStartDate = item?.commerceStartDate || "";
        const commerceEndDate = item?.commerceEndDate || "";

        const validityDays = calculateDaysDifference(
          commerceStartDate,
          commerceEndDate
        );

        return {
          ...item,
          errors: [],
          itemChange: false,
          currentStock: item?.availableQuantity,
          priceOff: Number(item?.percentageOff) || "0",
          validityDays: validityDays || "",
        };
      });
  }

  return [];
};

export const getSelectedInventoryRowCount = (productList) => {
  const selectedRows = productList?.reduce((accumulator, currentValue) => {
    if (currentValue?.itemChange) {
      accumulator = accumulator + 1;
    }

    return accumulator;
  }, 0);

  return selectedRows;
};
