import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import {
  blackColor,
  blackShade19,
  border25,
  greyColor6,
  orangeShade11,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import StyledSwitch from "../../../components/Switch/StyledSwitch";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import { useSelector } from "react-redux";
import { dynamicSort } from "../../../utils/helper";

function AddOndcCatagory({
  data,
  setData,
  handleMainCatChange,
  handleProductCatChange,
  handleSubProductCatChange,
  handleOndcCategoryChange,
  handleOndcSubCategoryChange,
}) {
  const { productSubCatOne, productSubCatTwo } = useSelector(
    (state) => state.addProduct
  );

  const { commerceInfo } = useSelector((state) => state.businessPreview);
  return (
    <Box sx={styles.card}>
      <Typography sx={styles.title}>ONDC</Typography>
      {(!commerceInfo?.info?.ondcEnabled ||
        commerceInfo?.info?.ondcEnabled?.toLowerCase() === "no") && (
        <Typography
          variant="body2"
          fontSize={{ xs: 12, md: 11.5 }}
          fontWeight={400}
          color={orangeShade11}
          mt={{ xs: 1, md: -1 }}
          mb={1}
          lineHeight="20px"
        >
          Enable ONDC control at business level to make sure products are
          visible in ONDC search from buyer apps
        </Typography>
      )}

      <Grid container spacing={{xs:1,md:2}}>
        <Grid item md={3}>
          <Box>
            <Typography
              fontSize={{ xs: 12, md: 13 }}
              color={greyColor6}
              fontWeight={400}
              mb="0px !important"
              display="inline"
            >
              Enabled{" "}
              <Typography
                variant="body1"
                sx={{ display: "inline", color: "#f00" }}
              >
                *
              </Typography>
            </Typography>
            <StyledSwitch
              label={data?.ondcEnabled === "yes" ? "Yes" : "No"}
              isChecked={data?.ondcEnabled === "yes"}
              onChange={() => {
                setData((prevState) => ({
                  ...prevState,
                  ondcEnabled: prevState?.ondcEnabled === "yes" ? "no" : "yes",
                }));
              }}
            />
          </Box>
        </Grid>
        <Grid item md={3} xs={12}>
          <SelectWithLabel
            label="Category"
            required
            value={data?.selectedOndcCat?.name}
            disabled={data?.ondcEnabled !== "yes"}
            menuList={
              data?.ondcCategory?.slice()?.sort(dynamicSort("name")) || []
            }
            valueSelector="name"
            onValueChange={handleOndcCategoryChange}
            returnFullItem
            errorMsg={data?.errors?.selectedOndcCat}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SelectWithLabel
            label="Sub Category"
            required
            valueSelector="productSubCategory1"
            value={data?.selectedOndcSubCat?.productSubCategory1}
            menuList={
              data?.ondcSubCategory
                ?.slice()
                ?.sort(dynamicSort("productSubCategory1")) || []
            }
            disabled={data?.ondcEnabled !== "yes"}
            onValueChange={handleOndcSubCategoryChange}
            returnFullItem
            errorMsg={data?.errors?.selectedOndcSubCat}
          />
        </Grid>
      </Grid>
      <Typography sx={styles.title} mt={2}>
        Webstore Categories
      </Typography>
      {console.log("data", data)}
      <Grid container spacing={{xs:1,md:2}}>
        <Grid item md={3} xs={12}>
          <SelectWithLabel
            label="Business Category"
            required
            menuList={
              data?.businessCategory?.slice()?.sort(dynamicSort("name")) || []
            }
            valueSelector="name"
            value={data?.selectedBusinessCat?.name || ""}
            onValueChange={handleMainCatChange}
            returnFullItem
            errorMsg={data?.errors?.selectedBusinessCat}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SelectWithLabel
            label="Product Category"
            required
            menuList={productSubCatOne?.details?.slice()?.sort(dynamicSort("productSubCategory1")) || []}
            value={data?.selectedProductCat?.value || ""}
            valueSelector="productSubCategory1"
            onValueChange={handleProductCatChange}
            returnFullItem
            errorMsg={data?.errors?.selectedProductCat}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SelectWithLabel
            label="Product Sub-Category"
            menuList={productSubCatTwo?.details?.slice()?.sort(dynamicSort("productSubCategory2")) || []}
            value={data?.selectedProductSubCat?.value || ""}
            valueSelector="productSubCategory2"
            onValueChange={handleSubProductCatChange}
            returnFullItem
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddOndcCatagory;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    border: border25,
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: "15px 15px",
    mb: 2,
    mt: 1,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    mb: "10px",
    color: blackShade19,
  },
};
