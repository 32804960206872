import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { blackShade05, greyColor50, greyColor6, greyColorM01, logoImgborder1, redColorBG01, skyblueColorBG01, whiteColor } from "../../../configs/styles/muiThemes";
import { addThousandsSeparator } from "../../../utils/helper";
import { scrollBox } from "../../../utils/styles";

function MobileCustomerTraction({
  view,
  followers,
  reviewSummary,
  allOrders,
  orderSummary,
  onClick,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box display={"flex"} gap={1} sx={{ ...scrollBox,height:"120px"}} alignItems={"flex-start"}>
     
        <MobileStatsCard
          label="Ratings"
          value={reviewSummary?.[0]?.average || 0}
          label2="Reviews"
          value2={reviewSummary?.[0]?.totalRated || 0}
          icon={
            <Box sx={styles.iconCircle}>
              <Box
                component="img"
                alt="Product Image"
                src={
                  " https://storage.googleapis.com/bodefaults/businessdashboardweb/reviews.svg"
                }
                width="auto"
                height="120px"
              />
            </Box>
          }
          //   bgColor={matches ? blueColor04 : whiteColor}
          // color={matches ? blackShade05 : "#E74068"}
          // labelColor={matches ? greyColor6 : "#E74068"}
          valueSize={14}
          hideMobileBorder
        />
      
        <MobileStatsCard
          label="View"
          value={addThousandsSeparator(view?.totalView, true) || 0}
          label2="Followers"
          value2={addThousandsSeparator(followers?.totalFollower, true) || 0}
          icon={
            <Box sx={styles.iconCircle}>
              <Box
                component="img"
                alt="Product Image"
                src={
                  "https://storage.googleapis.com/bodefaults/businessdashboardweb/followers.svg"
                }
                width="auto"
                height="128px"
              />
            </Box>
          }
          //   bgColor={matches ? blueColor04 : whiteColor}
          // color={matches ? blackShade05 : "#E74068"}
          // labelColor={matches ? greyColor6 : "#E74068"}
          valueSize={14}
          hideMobileBorder
        />
      
      
        <MobileStatsCard
          label="Unique Buyers"
          value={addThousandsSeparator(orderSummary?.totalBuyers, true) || 0}
          label2=""
          value2={""}
          icon={
            <Box sx={styles.iconCircle}>
              <Box
                component="img"
                alt="Product Image"
                src={
                  "https://storage.googleapis.com/bodefaults/businessdashboardweb/unique.svg"
                }
                width="auto"
                height="128px"
              />
            </Box>
          }
          //   bgColor={matches ? blueColor04 : whiteColor}
          // color={matches ? blackShade05 : "#E74068"}
          // labelColor={matches ? greyColor6 : "#E74068"}
          valueSize={14}
          hideMobileBorder
        />
    </Box>
  );
}

export default MobileCustomerTraction;
const MobileStatsCard = ({
  icon,
  label,
  value,
  label2,
  value2,
  color,
  bgColor,
  labelColor,
  valueSize,
  hideMobile,
  flex,
  hideMobileBorder,
}) => {
  return (
    <Box
      sx={{
        ...styles.orderDateCardMobile(whiteColor, whiteColor),
        background: whiteColor,
        width: "100%",
      }}
    >
      <Box sx={styles.iconContainer(bgColor, hideMobile)}>{icon}</Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
       width={"100%"}
       mt={0.5}
      >
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
          <Typography
            variant="body2"
            fontSize={{ xs: 10, md: 11.28 }}
            color={labelColor || greyColorM01}
            fontWeight={500}
            lineHeight="20px"
            textAlign={"start"}
            whiteSpace={"wrap"}
          >
            {label}
          </Typography>

          <Typography
            component="h6"
            fontSize={{ xs:  15, md: 15 }}
            color={color || blackShade05}
            fontWeight={600}
          >
            {value}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            fontSize={{ xs: 10, md: 11.28 }}
            color={labelColor || greyColorM01}
            fontWeight={500}
            lineHeight="20px"
          >
            {label2}
          </Typography>

          <Typography
            component="h6"
            fontSize={{ xs: valueSize || 15, md: 15 }}
            color={color || blackShade05}
            fontWeight={600}
          >
            {value2}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: redColorBG01,
    border: `1px solid ${redColorBG01}`,
    borderRadius: { xs: "10px", md: "13px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: "10px 15px",
    width: "100%",
  },
  orderCard: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px", md: "12px" },
  },
  orderDateCard: {
    backgroundColor: { xs: greyColor50, md: whiteColor },
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "7px 15px", md: "12px" },
  },
  orderDateCardMobile: (color, borderColor) => ({
    minWidth:"120px",
    display:"flex",
    alignItems:"center",
    flexDirection:"column",
    backgroundColor: { xs: color ? color : skyblueColorBG01, md: whiteColor },
    border: `1px solid ${borderColor}`,
    borderRadius: { xs: "10px", md: "12px" },
    // boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "6px 6px", md: "12px" },
  }),
  orderTotalCardMobile: {
    backgroundColor: { xs: "#FFEEF2", md: whiteColor },
    border: `1px solid #FFE4EB`,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 10px", md: "12px" },
  },
  card: (flex) => ({
    display: "flex",
    justifyContent: "space-between",
    gap: 1,
    flex: flex || 1,
  }),
  iconContainer: (bgColor, hideMobile) => ({
    width: { md: "40px", xs: "52px" },
    height: { md: "40px", xs: "47px" },
    borderRadius: "50%",
    backgroundColor: bgColor,
    display: { md: "flex", xs: hideMobile ? "none" : "flex" },
    alignItems: "center",
    justifyContent: "center",
  }),
  icon: (color) => ({ fontSize: { md: 20, xs: 17 }, color: color }),
};