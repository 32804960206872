import moment from "moment";

// BASE URL
export const BASE_URL = "https://web.costbo.com/";

// Date Constants

export const FROM_DATE = moment("2020-01-01").valueOf();
export const CURRENT_DATE = moment().valueOf();

// end points
export const AUTH_TOKEN_URL = "costbouaa/oauth/token";

export const MY_BUSINESS_SUMMARY =
  "business-query-side/api/v1/business/getMyBusinessSummary";
// export const ACTIVE_BUSINESS_INFO = 'business-query-side/api/v1/business/getActiveBusiness/'

export const ACTIVE_BUSINESS_INFO =
  "business-query-side/api/v1/business/viewActiveBusiness/";
export const PRODUCTS_BY_BUSINESS =
  "product-query-side/api/v1/open/product/productsByBusiness/";
export const BUSINESS_PRODUCTS_BY_STATUS =
  "product-query-side/api/v2/business/product/all/by-status";
export const SEARCH_BUSINESS_PRODUCTS =
  "search-service/api/v1/costbo/productBusinessSearch/";
export const PRODUCT_CATEGORY =
  "product-query-side/api/v1/open/product/category";
export const PRODUCT_DETAILS =
  "product-query-side/api/v1/product/viewActiveProduct/";
export const PRODUCT_SUB_CATEGORY_ONE =
  "lookup-service/api/v1/lookup/getNewProductSubCategory1";
export const PRODUCT_SUB_CATEGORY_TWO =
  "lookup-service/api/v1/lookup/getNewProductSubCategory2/";

export const GET_ONDC_BUSINESS_CATEGORY =
  "lookup-service/api/v1/lookup/getONDCBusinessCategory";

export const GET_GEO_POINT =
  "cart-query-side/api/v2/cart/consumer/getGeoPoint?address=";
export const GET_BUSINESS_TYPE = "lookup-service/api/v1/lookup/getBusinessType";
export const GET_BUSINESS_CATEGORY =
  "lookup-service/api/v1/lookup/getAllBusinessCategory";
export const ADD_BUSINESS_DATA =
  "business-command-side/api/v1/business/addBusiness";
export const EDIT_BUSINESS_DATA =
  "business-command-side/api/v1/business/editBusinessText";
export const PUBLISH_BUSINESS_DATA =
  "business-command-side/api/v1/business/publishBusiness";
export const MY_BUSINESS_DATA =
  "business-query-side/api/v1/business/viewMyBusiness";
export const UPDATE_LOGO_DATA =
  "business-command-side/api/v1/business/updateBusinessLogo";
export const DELETE_BANNER_DATA =
  "business-command-side/api/v1/business/deleteDocuments?";
export const UPDATE_BANNER_DATA =
  "business-command-side/api/v1/business/updateBusinessDocuments";
export const GET_USER_EDIT_ELIGIBILITY =
  "business-query-side/api/v1/business/checkBusinessEditEligibility/";
export const UPDATE_COMMERCE_SUBMIT =
  "business-command-side/api/v1/business/updateCommerceSubmit";
export const UPDATE_BUSINESS_TIMINGS_DELIVERY_METHOD =
  "business-command-side/api/v1/business/updateBusinessOptionalField";
export const UPDATE_ONDC_CONTROLS =
  "business-command-side/api/v2/business/updateONDCCommerceSubmit";
export const GET_ONDC_CITY_TO_PINCODE =
  "lookup-service/api/v1/lookup/getOndcCityToPincode";
export const UPDATE_ONDC_BUSINESS_OPTIONAL_FIELDS =
  "business-command-side/api/v2/business/updateONDCBusinessOptionalFields";

// Catalog
export const VIEW_PRODUCT_INFO =
  "product-query-side/api/v1/product/viewProduct/";
export const REVIEW_PRODUCT_INFO =
  "product-query-side/api/v2/business/product/review-product/";
export const GET_COMMERCE_INFO =
  "business-query-side/api/v1/business/getCommerceInfo";
export const GET_COMMERCE_DASHBOARD_INFO =
  "business-query-side/api/v1/business/getCommerceDashboard";
export const GET_COUNTRY_NAMES = "lookup-service/api/v1/lookup/getCountryNames";
export const GET_GST_RATES = "lookup-service/api/v1/lookup/getGstRates";
export const GET_ALL_DELIVERY_METHODS =
  "lookup-service/api/v1/lookup/getAllDeliveryMethod?param=product";

export const INVENTORY_PRODUCTS_BY_STATUS =
  "product-query-side/api/v2/business/product/getProductByStatus";
export const INVENTORY_ONDC_FILTER =
  "product-query-side/api/v2/business/product/getproductStatusByONDCCategories";

export const UPDATE_INVENTORY =
  "product-command-side/api/v2/product/business/updateBulkProductInventory";
export const ADD_PRODUCT_PHOTO =
  "product-command-side/api/v1/business/product/add-photo";
export const UPDATE_PRODUCT =
  "product-command-side/api/v1/business/product/update";
export const DELETE_PRODUCT_Photo =
  "product-command-side/api/v1/business/product/remove-photo/";
export const ADD_NEW_PRODUCT = "product-command-side/api/v1/product/newProduct";
export const DELETE_PRODUCT =
  "product-command-side/api/v1/product/updateProductStatus/";
export const GET_UNIT_OF_MEASUREMENT =
  "lookup-service/api/v1/lookup/ondc/unitOfMeasure";
export const UPDATE_PENDING_PRODUCT =
  "product-command-side/api/v2/business/product/newProductAppend";

// Business Dashboard Apis
export const VIEW_ALL_ORDERS_BY_BUSINESS =
  "cart-query-side/api/v1/cart/viewAllOrderByBusiness?pageNo=1&filter=800";
export const GET_ALL_COUPONS = "reward-query-side/api/v1/reseller/getCoupons";
export const VIEW_BUSINESS_ACTIVITY =
  "activity-query-side/api/v1/activity/viewBusinessActivity";
export const GET_BUSINESS_FOLLOWERS =
  "businesssocial-query-side/api/v1/businessSocial/getBusinessFollowerCount";
export const GET_BUSINESS_REVIEW_SUMMARY =
  "review-query-side/api/v1/review/webGetReviewSummary";
export const GET_CMS_PRODUCTS =
  "product-query-side/api/cms/product/business-products/";
export const GET_DASHBOARD_ORDER_SUMMARY =
  "cart-query-side/api/v2/cart/business/order/summary";
export const GET_DASHBOARD_INVENTORY_SUMMARY =
  "product-query-side/api/v2/business/product/inventory/summary/new";
export const GET_DASHBOARD_AFFILIATE_SALE_INFO =
  "cart-query-side/api/v2/cart/business/resellerpayment/affiliate/sales/info/";
export const GET_DASHBOARD_UNIQUE_AFFILIATE =
  "reward-query-side/api/v2/business/reseller/account/unique/affiliate/";
export const GET_DASHBOARD_AFFILIATE_SHARE_COUNT =
  "businesssocial-query-side/api/v1/business-social/affiliate/share/count/";
export const GET_DASHBOARD_PAYCART_SUMMARY =
  "cart-query-side/api/v2/cart/business/paycart/summary";
export const GET_DASHBOARD_PREFERRED_DISCOUNT_SUMMARY =
  "reward-query-side/api/v2/business/reseller/account/preferred/discounts";
export const GET_DASHBOARD_PREFERRED_DISCOUNT_COUNT =
  "cart-query-side/api/v2/cart/business/preferred/cashback/counts";
export const GET_DASHBOARD_CASHBACK_SUMMARY =
  "business-query-side/api/v1/business/cashback/summary/by/business";
export const GET_DASHBOARD_CASHBACK_COUNT =
  "cart-query-side/api/v2/cart/business/cashback/counts";
export const CHECK_COSTBO_WEB_URL =
  "business-query-side/api/v1/business/checkCostboWebURL";

// Business Paycart Orders
export const GET_BUSINESS_PAYCART_ORDERS =
  "cart-query-side/api/v2/cart/business/viewPayCartByBusiness";

// Business Cashbacks
export const GET_COUPON_CODE_USEAGE =
  "cart-query-side/api/v2/cart/business/couponcodeusagesummary";

// Business Cashbacks
export const GET_BUSINESS_CASHBACKS =
  "reward-query-side/api/v2/reward/business/cashbackdetailbyuser";

// business crm cutomers
export const GET_ALL_CUSTOMERS =
  "business-contact-query-side/api/v1/business/contact/getContactsOfBusiness";

//business Preferred Discount
export const GET_ALL_PREFERRED_DISCOUNTS =
  "reward-query-side/api/v2/reward/business/instant-discounts";
export const SEARCH_PREFERRED_DISCOUNT =
  "reward-query-side/api/v2/reward/business/instant-discounts/search";

export const DELETE_PREFERRED_DISCOUNT =
  "reward-command-side/api/v2/business/reward/inactive";

// business profile info
export const GET_ALL_PROFILE_IDS =
  "account-query-side/api/v1/account/getTimeLineAccount";

//phonenumber or email getiing
export const GET_EMAIL_OR_PHONE_NUMBER =
  "account-query-side/api/v1/account/getEmailOrPhone";

export const ADD_PREFERRED_DISCOUNT_REWARD =
  "reward-command-side/api/v1/reward/addReward";

//Top Affiliates
export const GET_TOP_AFFILIATES =
  "cart-query-side/api/v2/cart/business/getTopAffiliatesByEarnings";
// affiliate total orders
export const GET_AFFILIATE_TOTAL_ORDERS =
  "cart-query-side/api/v2/cart/business/getOrderDetailsofAffiliate";
// affiliate partners
export const GET_AFFILIATE_PARTNERS =
  "reward-query-side/api/v2/business/reseller/account/getAffiliatesOfBusiness";
export const GET_AFFILIATE_PARTNERS_DATA =
  "cart-query-side/api/v2/cart/business/resellerpayment/transaction/details";

// coupon
export const GET_ALL_COUPONS_SUMMARY =
  "reward-query-side/api/v2/business/reseller/account/coupons/summary";

export const GET_ALL_COUPONS_COUNT =
  "cart-query-side/api/v2/cart/business/coupons/counts";

export const GET_CASH_BACK_VALUE_FOR_BUSINESS =
  "reward-query-side/api/v2/reward/business/totalAvailableCashbackValueByBusiness";

export const GET_ALL_COUPON_CODE =
  "reward-query-side/api/v2/business/reseller/account/GetResellerCodeByBusiness";

export const ADD_NEW_COUPON = "reward-query-side/api/v1/reseller/addReseller";

// Orders
export const GET_BUSINESS_ORDERS_BY_STATUS =
  "cart-query-side/api/v2/cart/business/viewAllOrderByBusinessOrderStatus";
export const GET_DELHIVERY_STATUS =
  "cart-query-side/api/v2/cart/consumer/getDelhiveryStatus";
export const GET_DELHIVERY_B2B_STATUS =
  "cart-query-side/api/v2/cart/consumer/btobDelhiveryTracking/";
export const GET_TELYPORT_STATUS = "cart-query-side/api/v1/cart/getTelyport";
export const SEARCH_ORDERS_BY_ID =
  "cart-query-side/api/v2/cart/business/viewCartByOrderId";
export const SEARCH_ONDC_ORDERS_BY_ID =
  "cart-query-side/api/v2/cart/business/getOndcOrder";
export const GET_BUSINESS_CART_INFO =
  "cart-query-side/api/v2/cart/business/getCart";
export const APPROVE_CART = "cart-command-side/api/v1/cart/orderApprove";
// export const APPROVE_CART = "cart-command-side/api/v2/cart/orderApprove";

export const DELHIVERY_STATUS_SHIPPMENTINFO =
  "cart-command-side/api/v2/cart/business/shipment/status";

export const ORDER_DELHIVERED_SHIPPMENTINFO =
  "cart-command-side/api/v2/cart/business/orderDelivered";

export const GET_EMAIL_ACTIVATION = "costbouaa/api/account/getEmailActivation";

export const RESEND_EMAIL_ACTIVATION = "costbouaa/api/account/updateEmail";

export const GET_REWARD_POINTS =
  "reward-query-side/api/v1/rewardpoint/getRewardPoints";

export const UPDATE_REWARD_POINT =
  "reward-command-side/api/v1/rewardpoint/updateRewardPoint";
export const ADD_REWARD_POINT =
  "reward-command-side/api/v1/rewardpoint/addRewardPoint";
export const RESEND_INVOICE = "cart-query-side/api/v1/cart/getPackingSlip";
export const RESEND_INVOICE_B2B =
  "cart-query-side/api/v2/cart/business/getBtoBPackingSlip/";
export const APPROVE_SHIPMENT = "cart-command-side/api/v1/cart/orderShipment";
// export const APPROVE_SHIPMENT = "cart-command-side/api/v2/cart/orderShipment";

// Cancel Orders
export const CANCEL_COMPLETE_ORDER =
  "cart-command-side/api/v2/cart/business/orderCancelByBusiness";
// export const PARTIAL_COMPLETE_ORDER = "cart-command-side/api/cms/cart/partialUpdateCart";
export const PARTIAL_COMPLETE_ORDER =
  "cart-command-side/api/v2/cart/business/partialUpdateCart.";

export const WHATSAPP_NONIFICATION =
  "notification-service/api/v1/notification/service/testSendWhatsapp";

export const GET_TELYPORT_INFO =
  "cart-query-side/api/v2/open/cart/consumer/getPayCartTelyportStatus";

export const GET_CANCELNATION_LIST =
  "lookup-service/api/v1/lookup/getOndcOMSCodes";
export const UPLOAD_BULK_PRODUCTS =
  "product-command-side/api/v2/product/business/productstage/newUploadBulkProductStage";
export const GET_BULK_PRODUCTS_DETAILS =
  "product-command-side/api/v2/product/business/productstage/getBulkProductStage";
export const DELETE_BULK_PRODUCT =
  "product-command-side/api/v2/product/business/productstage/deleteBulkProductStage";
export const UPDATE_BULK_PRODUCT =
  "product-command-side/api/v2/product/business/productstage/updateBulkProductStage";
export const IMPORT_BULK_PRODUCT =
  "product-command-side/api/v2/product/business/productstage/new/importBulkProduct";

// Return Orders
export const RETURN_ORDERS_BY_STATUS =
  "cart-query-side/api/v2/cart/business/order/return";
export const RETURN_RESONS_OMS_CODES =
  "lookup-service/api/v1/lookup/getOndcOMSCodes";
export const PROCESS_RETURN_ORDER_STATUS =
  "/cart-command-side/api/v2/cart/business/order/return/process-status";

export const ALL_ISSUES = "issue-management/api/v2/business/issue/all";
export const BUSINESS_INFO =
  "business-query-side/api/v1/business/getBusinessInfo";
export const GET_BUSINESS_CART_INFO_IGM = "cart-query-side/api/v1/cart/getCart";
export const SEARCH_CUSTOMER_GRIVANCE_ISSUEID =
  "/issue-management/api/v2/business/issue/";
export const SEARCH_CUSTOMER_GRIVANCE_ORDERID =
  "/issue-management/api/v2/business/issue/by-order/";
export const UPDATE_ISSUE_RESOLUTION =
  "/issue-management/api/v2/business/issue/updateIssueResolution";
export const UPDATE_ISSUE_STATUS_BY_BUSINESS =
  "/issue-management/api/v2/business/issue/updateIssueStatusByBusiness";
export const VIEW_MY_BUSINESS_URL =
  "business-query-side/api/v1/business/viewMyBusiness";

export const ADD_NEW_BUSINESS_OWNER =
  "costbouaa/api/v2/business/addBusinessRoleStage";
export const UPDATE_BUSINESS_OWNER_PRIVILEGE =
  "business-command-side/api/v2/business/updateBusinessOwnerAndOwnerPrivilege";
export const UPDATE_BUSINESS_DELEGATES =
  "business-command-side/api/v2/business/updateAdminDelegates";
export const REMOVE_BUSINESS_DELEGATES =
  "business-command-side/api/v2/business/updateAdminDelegates";
export const VERIFY_BUSINESS_ROLE =
  "costbouaa/api/v2/business/getBusinessRoleStage";
export const UPDATE_BUSINESS_ROLE =
  "costbouaa/api/v2/business/updateBusinessRoleStage";
export const CHECK_BUSINESS_ROLE =
  "business-command-side/api/v1/open/business/checkBusinessRoleStage";
export const SEND_WHATSPP_NOTIFICATION =
  "notification-service/api/v1/notification/service/sendBusinessWhatsappNotification";

export const ONDC_SPECIFICATION =
  "lookup-service/api/v1/lookup/getondcspecifications";
export const ONDC_FULL_SPECIFICATION =
  "lookup-service/api/v1/lookup/getondcMandatoryOptional";
export const PRODUCT_CODE = "lookup-service/api/v1/lookup/getProductInfo";

export const BUYER_APP_LOOKUP =
  "lookup-service/api/v1/lookup/ondcBapDisplayName?subscriberId=ALL";

export const ONDC_CATEGORY =
  "product-query-side/api/v2/business/product/getOndcCategoryGroupByBusinessId";
export const ONDC_PRODUCT_LIST =
  "product-query-side/api/v2/business/product/getProductsByOndcCategory";

export const ADD_NEW_BRANCH =
  "business-command-side/api/v2/business/addNewBranch";
export const UPDATE_BUSINESS_WRT_BRANCH =
  "business-command-side/api/v2/business/updateBusinessWithBranchDetails";
export const BANK_DETAILS =
  "/business-query-side/api/v2/business/bankInfo/getBankDetails";
export const ADD_BANK_DETAILS =
  "/business-query-side/api/v2/business/bankInfo/addBankInfo";
export const UPDATE_INACTIVE_DATE =
  "/business-query-side/api/v2/business/bankInfo/updateBankInfo";

export const GET_SHIPING_LABEL_LINK =
  "cart-query-side/api/v2/cart/business/getDelhiveryB2cShippingLabelLink";
export const GET_BUSINESS_PRODUCTS =
  "/product-query-side/api/v2/open/product/getBusinessProducts";
export const COPY_FRANCHISE_PRODUCTS =
  "/product-command-side/api/v2/business/product/copyFranchiseProduct";
export const GET_MAIN_BRANCH =
  "/business-query-side/api/v2/business/getMasterBranchInfo";
export const GET_MASTER_PRODUCT_INFO = "/product-query-side/api/v2/business/product/getMasterProductInfo";
export const UPDATE_FRANCHISE_PRODUCTS = "/product-command-side/api/v2/business/product/updateFranchiseProduct";
export const GET_BRANCH_INFO = "/business-query-side/api/v2/business/getBranchInfo";
export const GET_PRODUCT_SYNC_STATUS = "/product-query-side/api/v2/business/product/getProdcutsSyncStatus";
