import moment from "moment";
import { toTitleCase } from "./validationUtils";

export const isUserEditEligible = (businessID, eligibilityList) => {
  const list = eligibilityList?.length > 0 ? eligibilityList : [];

  const isPresent = list?.filter(
    (item, index) => item?.businessId === businessID
  );

  console.log("isUserEditEligible", isPresent);

  return isPresent?.length > 0;
};

export const validateUserEmail = (email) => {
  // Email validation regular expressionhi
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const parseDbObjectInsideArray = (inputArray) => {
  const result = {};
  let list = [];

  console.log("parseDbObjectInsideArray__inputArray", inputArray);

  inputArray?.forEach((item) => {
    if (item?.dbObjectName == "list") {
      if (!item?.inputValue) return;
      list?.push({
        code: item?.code,
        value:
          item?.type === "codeValue"
            ? getCodeByName(item?.nameCodes, item?.inputValue?.toLowerCase())
            : item?.code === "brand"
            ? toTitleCase(item?.inputValue)
            : item?.inputValue?.toLowerCase(),
      });
      return;
    }
    if (item?.type == "radioButton") {
      item?.radioOptions?.forEach((option) => {
        let currentRadioObj = result;
        let inputValue =
          option?.display !== "Y" ? option?.defaultValue : option?.value;
        parseDbObject(currentRadioObj, option.key, inputValue);
      });
      return;
    }

    // const keys = item.dbObjectName.split(".");
    let currentObj = result;

    parseDbObject(
      currentObj,
      item.dbObjectName,
      item?.type === "codeValue"
        ? getCodeByName(item?.nameCodes, item?.inputValue)
        : item.inputValue
      // : item?.value === "fssai_validity_date(mm/yyyy)"
      // ? 1710500769089
    );

    // keys.forEach((key, index) => {
    //   if (index === keys.length - 1) {
    //     currentObj[key] = item.inputValue;
    //   } else {
    //     if (key.includes("[") && key.includes("]")) {
    //       const arrayKey = key.split("[")[0];
    //       const arrayIndex = parseInt(key.split("[")[1].replace("]", ""), 10);
    //       currentObj[arrayKey] = currentObj[arrayKey] || [];
    //       if (!currentObj[arrayKey][arrayIndex]) {
    //         currentObj[arrayKey][arrayIndex] = {};
    //       }
    //       currentObj = currentObj[arrayKey][arrayIndex];
    //     } else {
    //       currentObj[key] = currentObj[key] || {};
    //       currentObj = currentObj[key];
    //     }
    //   }
    // });
  });

  if (list?.length > 0) {
    let currentListObj = result;
    currentListObj["ondc"] = { ...currentListObj["ondc"], attributeList: list };
  }

  return result;
};

const parseDbObject = (currentObj, dbObjectName, inputValue) => {
  const keys = dbObjectName?.split(".");

  keys?.forEach((key, index) => {
    if (index === keys.length - 1) {
      currentObj[key] = inputValue;
    } else {
      if (key?.includes("[") && key?.includes("]")) {
        const arrayKey = key?.split("[")[0];
        const arrayIndex = parseInt(key?.split("[")[1]?.replace("]", ""), 10);
        currentObj[arrayKey] = currentObj[arrayKey] || [];
        if (!currentObj[arrayKey][arrayIndex]) {
          currentObj[arrayKey][arrayIndex] = {};
        }
        currentObj = currentObj[arrayKey][arrayIndex];
      } else {
        currentObj[key] = currentObj[key] || {};
        currentObj = currentObj[key];
      }
    }
  });
};

export const getValueByPath = (obj, path) => {
  console.log("getValueByPath", obj, path);
  // Split the path by periods
  const keys = path?.split(".");

  console.log("keys", keys);

  // Initialize result to the input object
  let result = obj;

  // Traverse the object using keys
  for (const key of keys) {
    // Check if the key contains an array index
    const match = key?.match(/(.+?)\[(\d+)\]/);

    if (match) {
      // If it's an array index, extract the array name and index
      const arrayName = match[1];
      const index = parseInt(match[2]);
      // Access the array and update the result
      result = result?.[arrayName]?.[index];
    } else {
      // Otherwise, access the property directly
      result = result[key] || "";
    }

    // If the result is undefined, break the loop
    if (result === undefined) break;
  }

  return result;
};
export const getCodeByName = (list, name) => {
  for (let i = 0; i < list.length; i++) {
    if (list[i][0] === name) {
      return list[i][1];
    }
  }
  return null; // Return null if colorName is not found in the list
};

export const getNameByCode = (list, code) => {
  for (let i = 0; i < list.length; i++) {
    if (list[i][1] === code) {
      return list[i][0];
    }
  }
  return null; // Return null if colorCode is not found in the list
};

// Functon to calculate sale price using the original price and % Off
export const calculateSalePrice = (originalPrice, percentageOff) => {
  const percentageOffDecimal = percentageOff / 100;
  const discountAmount = originalPrice * percentageOffDecimal;
  let salePrice = originalPrice - discountAmount;

  // Check if there are decimal places
  if (salePrice % 1 !== 0) {
    salePrice = salePrice.toFixed(2);
  }

  return salePrice;
};

// Calculate commerce end date usind validity days and start date
export const calculateEndDate = (startDate, validityDays) => {
  const parsedStartDate = moment(startDate);
  const endDate = parsedStartDate.add(validityDays, "days");

  // Format the end date as "YYYY-MM-DD"
  return endDate.valueOf();
};

export const calculatePercentageOff = (originalPrice, salePrice) => {
  if (originalPrice <= 0) {
    return 0;
  }
  const percentageOff = ((originalPrice - salePrice) / originalPrice) * 100;
  return percentageOff.toFixed(2);
};
