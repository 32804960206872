import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FormInputBox from "../../../components/Input/FormInputBox";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import {
  blackColor,
  blackShade159,
  greyColor24,
  greyColor27,
  greyColor5,
  greyColor34,
  greyColor6,
  whiteColor,
  blueColor02,
  errorTextColor,
  greyColor40,
  blueColor04,
  mobilePrimary,
} from "../../../configs/styles/muiThemes";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import ProductImageListDND from "../ProductImageListDND";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductSubCategoryOne,
  getProductSubCategoryTwo,
} from "../../../reducers/AddProduct/AddProductSlice";
import ModalCard from "../../../components/ModalCard";
import ProductTitleInfo from "../PopupModals/InfoPopups/ProductTitleInfo";
import ProductPicRecommendations from "../PopupModals/InfoPopups/ProductPicRecommendations";
import TextAreaComponent from "../../../components/Input/TextAreaComponent";
import CatalogActionBtns from "../../../components/Buttons/CatalogActionBtns";
import DashboardStepper from "../../Dashboard/DashboardStepper";
import {
  CATALOG_ADD_PRODUCT_STEP_LIST,
  CATALOG_STEP_LIST,
} from "../../../utils/appData";

function AddProductForm({
  data,
  onValueChange,
  countryList,
  productId,
  gotoNext,
  goBack,
  onProductImageUpdate,
  onDeleteProductPhoto,
  productImgDataList,
  setProductImgDataList,
  isAddProduct,
  categoryData,
  setCategoryData,
  onCategoryValueChange,
  onCancelClick,
  isCopyProduct,
  tabTitle,
  productName,
  showPreviewBtn,
  previewErrorMsg,
  handlePreviewSubmit,
  currentPage,
  handleTabChange,
}) {
  const dispatch = useDispatch();

  const [showTitleValidationPopup, setShowTitleValidationPopup] =
    useState(false);
  const [showProductPicInfo, setShowProductPicInfo] = useState(false);

  const { productSubCatOne, productSubCatTwo } = useSelector(
    (state) => state.addProduct
  );

  // Prepare list data for dropdown
  const formatDropdownList = (list, setValue) => {
    const newList = list.map((item) => {
      return { ...item, label: item?.value };
    });

    setValue(newList);
  };

  useEffect(() => {
    if (categoryData?.businessCategory) {
      // prepare data for dropdown
      // formatDropdownList(categoryData?.businessCategory, setBusinessCatList);

      // set active category as selected
      const activeBusinessCat = categoryData?.productMainCat?.filter(
        (item) => item.status === "active"
      );

      const activeProductSubCat1 = categoryData?.productSubCatOne?.filter(
        (item) => item.status === "active"
      );

      const activeProductSubCat2 = categoryData?.productSubCatTwo?.filter(
        (item) => item.status === "active"
      );

      console.log("activeBusinessCat:", activeBusinessCat);

      setCategoryData({
        ...categoryData,
        selectedBusinessCat: activeBusinessCat?.[0] || "",
        selectedProductSubCat1: activeProductSubCat1?.[0] || "",
        selectedProductSubCat2: activeProductSubCat2?.[0] || "",
      });

      // get product sub category data
      if (activeBusinessCat?.length > 0) {
        dispatch(getProductSubCategoryOne(activeBusinessCat[0]?.code));
      }

      if (activeProductSubCat1?.length > 0) {
        dispatch(
          getProductSubCategoryTwo({
            pcCode: activeProductSubCat1[0]?.pcCode,
            pscCode1: activeProductSubCat1[0]?.pscCode1,
          })
        );
      }
    }
  }, []);

  const handleMainCatChange = (value) => {
    console.log(
      "categoryData?.businessCategory",
      categoryData?.businessCategory
    );
    console.log("selectedBusinessCat", value);

    if (!categoryData?.selectedBusinessCat?.code) {
      const selectedMainCat = {
        code: value?.code,
        value: value?.name,
        status: "active",
      };

      dispatch(getProductSubCategoryOne(value?.code));
      setCategoryData({
        ...categoryData,
        selectedBusinessCat: selectedMainCat,
        selectedProductSubCat1: "",
        selectedProductSubCat2: "",
      });
      return;
    }

    if (categoryData?.selectedBusinessCat?.code !== value?.code) {
      const selectedMainCat = {
        code: value?.code,
        value: value?.name,
        status: "active",
      };

      dispatch(getProductSubCategoryOne(value?.code));
      setCategoryData({
        ...categoryData,
        selectedBusinessCat: selectedMainCat,
        selectedProductSubCat1: "",
        selectedProductSubCat2: "",
      });
    }

    // onCategoryValueChange("selectedBusinessCat", value);
  };

  const handleSubCatOneChange = async (value) => {
    console.log("selectedProductSubCat1", value);
    console.log("selectedProductSubCat1", {
      code: value?.pscCode1,
      value: value?.productSubCategory1,
      pcCode: value?.pcCode,
      status: "active",
      pscCode1: value?.scCode1,
    });

    dispatch(
      getProductSubCategoryTwo({
        pcCode: value?.pcCode,
        pscCode1: value?.scCode1,
      })
    );

    // onCategoryValueChange("selectedProductSubCat1", {
    //   code: value?.pscCode1,
    //   value: value?.productSubCategory1,
    //   pcCode: value?.pcCode,
    //   status: "active",
    //   pscCode1: value?.scCode1,
    // });

    setCategoryData({
      ...categoryData,
      selectedProductSubCat1: {
        code: value?.pscCode1,
        value: value?.productSubCategory1,
        pcCode: value?.pcCode,
        status: "active",
        pscCode1: value?.scCode1,
      },
      selectedProductSubCat2: "",
    });
  };

  const handleSubCatTwoChange = (value) => {
    console.log("selectedProductSubCat2", value);
    console.log("selectedProductSubCat2", {
      code: value?.pscCode2,
      value: value?.productSubCategory2,
      pcCode: value?.pcCode,
      psCode: value?.pcCode + value?.scCode1,
      status: "active",
      pscCode2: "",
    });

    onCategoryValueChange("selectedProductSubCat2", {
      code: value?.pscCode2,
      value: value?.productSubCategory2,
      pcCode: value?.pcCode,
      psCode: value?.pcCode + value?.scCode1,
      status: "active",
      pscCode2: "",
    });
  };

  // Validate Product Title
  const validateProductTitle = () => {
    setShowTitleValidationPopup(true);
  };

  // Show Product Image Recommandations Title
  const openProductPicInfo = () => {
    console.log("HITIIIT");
    setShowProductPicInfo(true);
  };

  return (
    <Box sx={styles.card}>
      {/* {!isCopyProduct && !isAddProduct && (
        <DashboardStepper
          stepList={
            isCopyProduct ? CATALOG_ADD_PRODUCT_STEP_LIST : CATALOG_STEP_LIST
          }
          currentPage={currentPage}
          isAddProduct
          hideAvailability={!isCopyProduct}
          handleTabChange={(destinationTab) =>
            handleTabChange(
              currentPage,
              isCopyProduct ? CATALOG_ADD_PRODUCT_STEP_LIST : CATALOG_STEP_LIST,
              destinationTab
            )
          }
        />
      )} */}

      <Box mt={{ xs: 0, md: 1 }}>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12} md={8}>
            <FormInputBox
              label="Product Name"
              placeholder="Max 150 characters"
              required
              value={data?.productName}
              onChange={({ target }) => {
                onValueChange("productName", target.value);
              }}
              error={data?.errors?.productName && "Enter product name"}
              maxLength={150}
            />

            <Typography
              variant="body2"
              fontSize={{ xs: 12, md: 13 }}
              fontWeight={400}
              color={greyColor34}
              mt={{ xs: 1, md: -0.7 }}
              lineHeight="20px"
            >
              Please include product weight (if applicable) as part of the name.
              Ex: Cold pressed coconut Oil - 500g.
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormInputBox
              label="Brand"
              placeholder="Max 50 characters"
              // required
              value={data?.brand}
              onChange={({ target }) => {
                if (target.value?.length <= 50) {
                  onValueChange("brand", target.value);
                }
              }}
              // error={data?.errors?.brand && "Enter brand"}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <SelectWithLabel
              label="Country of Origin"
              required
              value={data?.country?.name || ""}
              onValueChange={(value) => {
                onValueChange("country", value);
                // onValueChange("country", "India");
              }}
              returnFullItem
              menuList={countryList}
              // menuList={[{ name: "India", code: "IN" }]}
              valueSelector="name"
              errorMsg={data?.errors?.country && "Select country"}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormInputBox
              label="SKU ID"
              // required
              placeholder="Max 30 characters"
              value={data?.skuId}
              onChange={({ target }) => {
                if (target.value?.length <= 30) {
                  const skuValue = target.value?.replace(/[^a-zA-Z0-9-_]/g, "");
                  onValueChange("skuId", skuValue);
                }
              }}
              // error={data?.errors?.skuId && "Enter skuId"}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextAreaComponent
              label="Product Description"
              minRows={2}
              required
              placeholder="Max 5000 characters"
              value={data?.description}
              onChange={({ target }) => {
                if (target.value?.length <= 5000) {
                  onValueChange("description", target.value);
                }
              }}
              error={data?.errors?.description && "Enter description"}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <SelectWithLabel
              label="Business Category"
              required
              value={
                isAddProduct
                  ? categoryData?.selectedBusinessCat?.name
                  : categoryData?.selectedBusinessCat?.value || ""
              }
              onValueChange={handleMainCatChange}
              returnFullItem
              // menuList={businessCatList || []}
              menuList={categoryData?.businessCategory || []}
              // valueSelector={isAddProduct ? "name" : "value"}
              valueSelector="name"
              errorMsg={
                categoryData?.errors?.selectedBusinessCat &&
                "Select Business Category"
              }
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <SelectWithLabel
              label="Product Category"
              required
              value={categoryData?.selectedProductSubCat1?.value || ""}
              onValueChange={handleSubCatOneChange}
              returnFullItem
              menuList={productSubCatOne?.details || []}
              valueSelector="productSubCategory1"
              errorMsg={
                categoryData?.errors?.selectedProductSubCat1 &&
                "Select Product Category"
              }
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <SelectWithLabel
              label="Product Sub-Category"
              value={categoryData?.selectedProductSubCat2?.value || ""}
              onValueChange={handleSubCatTwoChange}
              returnFullItem
              menuList={productSubCatTwo?.details || []}
              valueSelector="productSubCategory2"
              errorMsg={
                categoryData?.errors?.selectedProductSubCat2?.value &&
                "Select Product Sub-Category"
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Typography
        component="h6"
        fontSize={{ xs: 13, md: 13 }}
        color={greyColor6}
        fontWeight={500}
        lineHeight="20px"
        m="30px 0 0 !important"
      >
        Upload Product Image (Min 1)
      </Typography>

      <Box>
        <ProductImageListDND
          productImgDataList={productImgDataList}
          setProductImgDataList={setProductImgDataList}
          data={data}
          productPhotoKeys={[
            { id: 1, key: "productImgOne" },
            { id: 2, key: "productImgTwo" },
            { id: 3, key: "productImgThree" },
            { id: 4, key: "productImgFour" },
          ]}
          onProductImageUpdate={onProductImageUpdate}
          onValueChange={onValueChange}
          onDeleteProductPhoto={onDeleteProductPhoto}
        />

        {data?.errors?.productImg ? (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={12}
            sx={{
              textAlign: "start !important",
              color: "red",
              m: { xs: "40px 0 10px", md: "-10px 0 10px" },
            }}
          >
            Upload atleast one product photo
          </Typography>
        ) : null}
      </Box>

      <Box
        sx={{ mt: { xs: "10px", md: "-10px" } }}
        onClick={openProductPicInfo}
      >
        <Typography
          variant="body1"
          fontSize={{ xs: 13, md: 13.5 }}
          fontWeight={400}
          color={greyColor27}
          display="inline"
        >
          Refer to the picture recommendations and samples{" "}
          <Typography
            variant="body1"
            fontSize={{ xs: 13, md: 13.5 }}
            fontWeight={500}
            color={{xs: mobilePrimary, md:blueColor04}}
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            display="inline"
          >
            Here
          </Typography>
        </Typography>
      </Box>

      <Box mt="20px">
        <CatalogActionBtns
          onBackClick={goBack}
          onSaveClick={() => {}}
          hideSaveBtn
          onNextClick={gotoNext}
          onCancelClick={onCancelClick}
          // Preview Btn
          showPreviewBtn={showPreviewBtn}
          onPreviewSubmitClick={handlePreviewSubmit}
        />
      </Box>

      <Typography
        fontSize={12}
        fontWeight={500}
        mt={1}
        textAlign="right"
        color={errorTextColor}
      >
        {previewErrorMsg}
      </Typography>

      {/* Title Validation Popup */}
      <ModalCard
        open={showTitleValidationPopup}
        handleClose={() => {
          setShowTitleValidationPopup(false);
        }}
        width="28%"
      >
        <ProductTitleInfo />
      </ModalCard>

      {/* Product Image Recommandations Popup */}

      <ModalCard
        open={showProductPicInfo}
        handleClose={() => {
          setShowProductPicInfo(false);
        }}
        width="70%"
      >
        <ProductPicRecommendations />
      </ModalCard>
    </Box>
  );
}

export default AddProductForm;

const styles = {
  card: {
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: { xs: "8px", md: "13px" },
    p: { xs: "12px 12px", md: "5px 20px 20px" },
    m: { xs: "0px 0 25px", md: "0px 0 25px" },
  },

  validateBtn: {
    background: whiteColor,
    padding: { xs: "3px 24px", md: "3px 20px" },
    fontSize: 13,
    color: blackShade159,
    borderRadius: "3px",
    fontWeight: 600,
    border: "1px solid " + blackShade159,
    mt: { xs: 0, md: 3.5 },

    "&:hover": {
      border: `1px solid ${blackShade159}`,
      backgroundColor: whiteColor,
      color: blackShade159,
    },
  },
};
