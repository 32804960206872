import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  borderTextbox,
  primaryColor,
  whiteColor,
} from "../../configs/styles/muiThemes";

function TimeRange({ from, to, handleFromChange, handleToChange, disabled }) {
  return (
    <Box display="flex" gap={1} alignItems="center" sx={{opacity: disabled ? 0.5 : 1}}>
      {/* <Typography
        fontSize={14}
        fontWeight={600}
        color={blackColor}
        lineHeight={1.5}
      >
        From
      </Typography> */}
      
      <TextField
        variant="standard"
        type="time"
        InputProps={{
          disableUnderline: true,
        }}
        sx={styles.inputBox(whiteColor)}
        value={from}
        onChange={({ target }) => {
          // console.log(target.value);
          handleFromChange(target.value)
        }}
        disabled={disabled}
      />

      <Typography
        fontSize={14}
        fontWeight={500}
        color={blackColor}
        lineHeight={1.5}
        mx="10px"
      >
        To
      </Typography>

      <TextField
        variant="standard"
        type="time"
        InputProps={{
          disableUnderline: true,
        }}
        sx={styles.inputBox(whiteColor)}
        value={to}
        onChange={({ target }) => {
          // console.log(target.value);
          handleToChange(target.value)
        }}
        disabled={disabled}
      />
    </Box>
  );
}

export default TimeRange;
const styles = {
  actionBtn: {
    background: primaryColor,
    padding: { md: "5px 20px", xs: "5px" },
    fontSize: "13px",
    color: whiteColor,
    borderRadius: "5px",
    fontWeight: 600,
    border: "1px solid " + primaryColor,

    "&:hover": {
      backgroundColor: whiteColor,
      color: primaryColor,
      border: "1px solid " + primaryColor,
    },
  },
  inputBox: (color) => ({
    border: borderTextbox,
    borderRadius: "4px",
    fontSize: "13px",
    color: "#777",
    background: color,
    padding: { md: "3px 12px", xs: "3px 12px" },
    width: "100%",
    "& .MuiInputBase-root.Mui-disabled": {
      color: blackColor + " !important",
    },
  }),
};
