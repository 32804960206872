import React, { useState } from "react";
import {
  blackColor,
  blackShade19,
  blueColor04,
  greyColor24,
  greyColor27,
  greyColor42,
  greyShade07,
  mobilePrimary,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import SelectWithLabel from "../../components/Input/SelectWithLabel";
import { BULK_UPLOAD_EXCEL_TEMPLATES } from "../../utils/data";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const DownloadTemplate = ({ templateInfo, setTemplateInfo, setOpenModal }) => {
  const [isDownloaded, setIsDownloaded] = useState(false);

  const onDownloadClick = () => {
    if (isDownloaded) return;

    setIsDownloaded(true);

    setTimeout(() => {
      setIsDownloaded(false);
    }, 2000);
  };

  return (
    <Box>
      <Typography sx={styles.heading}>Download Template</Typography>

      <Grid container spacing={1}>
        <Grid item md={4}>
          <SelectWithLabel
            label="Select Template"
            value={templateInfo?.selectedTemplate?.label}
            onValueChange={(value) => {
              setTemplateInfo((prevState) => ({
                ...prevState,
                selectedTemplate: value,
              }));
            }}
            returnFullItem
            menuList={BULK_UPLOAD_EXCEL_TEMPLATES}
            borderFull
            rounded
            required
          />
        </Grid>

        <Grid item md={9}>
          <Typography
            fontSize={11.5}
            fontWeight={500}
            color={greyShade07}
            mb={2}
            sx={{ whiteSpace: "pre-line" }}
          >
            {`Supported Categories - Groceries, Beauty & Personal Care (BPC), Electronics, Appliances, Home & Kitchen \nUpcoming Categories - Food & Beverages, Fashion, Pharmacy, Health & Wellness, Auto Components`}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item md={6}>
          <Box
            sx={{
              backgroundColor: greyColor42,
              padding: "15px",
              borderRadius: "8px",
            }}
          >
            <Typography fontSize={13} fontWeight={500} color={blackColor}>
              Import file is in the correct format by comparing it with our
              sample file*
            </Typography>

            <a
              href={templateInfo?.selectedTemplate?.fileUrl}
              download
              target="_self"
              rel="noreferrer"
            >
              <Button
                variant="contained"
                disableElevation
                startIcon={
                  isDownloaded ? (
                    <CircularProgress size={18} color="inherit" />
                  ) : (
                    <FileDownloadOutlinedIcon sx={{ fontSize: "24px" }} />
                  )
                }
                sx={styles.btn}
                onClick={onDownloadClick}
              >
                {isDownloaded ? "DOWNLOADING" : "DOWNLOAD SAMPLE FILE"}
              </Button>
            </a>
          </Box>

          <Box mt={1}>
            <Typography
              variant="body2"
              fontSize={{ xs: 13, md: 13 }}
              fontWeight={400}
              color={greyColor27}
              display="inline"
              lineHeight="20px"
            >
              Recommendation and guidelines {" "}
              <Typography
                variant="body2"
                fontSize={12.5}
                fontWeight={500}
                color={{ xs: mobilePrimary, md: greyColor24 }}
                lineHeight="20px"
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                display="inline"
                onClick={() => {
                  setOpenModal(true)
                }}
              >
                Here
              </Typography>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DownloadTemplate;

const styles = {
  heading: {
    fontSize: "14px",
    fontWeight: "600",
    color: blackShade19,
    mb: 1,
  },

  btn: {
    fontSize: 13,
    fontWeight: 600,
    color: blueColor04,
    backgroundColor: whiteColor,
    border: `1px solid ${blueColor04}`,
    p: "6px 16px",
    mt: 1.5,

    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },
};
