import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import { getProfileDetails } from "../../reducers/auth/authSlice";
import { checkCostboWebURL } from "../../service/api_calls/BusinessDashboard/BusinessDashboardApi";
import { Box } from "@mui/material";
import ThreeDotLoader from "../../components/ThreeDotLoader";

const KEY = "01020304050607080910111213141516";

const UserAuth = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const decryptData = (data) => {
    console.log("before__plainText:", data);
    const d_data = data
      ?.replace(/p1L2u3S/g, "+")
      .replace(/s1L2a3S4h/g, "/")
      .replace(/e1Q2u3A4l/g, "=");

    const bytes = CryptoJS.AES.decrypt(d_data, KEY);
    const plainText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log("plainText:", plainText);
    return plainText;
  };

  const extractStringBetweenSlashes = (inputString) => {
    const startIndex = inputString.indexOf("/") + 1;
    const endIndex = inputString.indexOf("/", startIndex);

    if (startIndex !== -1 && endIndex !== -1) {
      return inputString.substring(startIndex, endIndex);
    } else {
      return null;
    }
  };

  const getBusinessIdByUrl = async (tokenData) => {
    const webUrl = extractStringBetweenSlashes(tokenData?.route);

    if (webUrl) {
      const res = await checkCostboWebURL(webUrl);
      console.log("checkCostboWebURL:", res);

      if (res?.validate) {
        const businessId = res?.businessId;

        sessionStorage.setItem("costboWEBURL", webUrl);
        sessionStorage.setItem("selectedBusinessId", businessId);

        dispatch(getProfileDetails()).then((response) => {
          // console.log('PROFILE_DATA', response)
          if (tokenData?.route) {
            navigate(tokenData?.route);
          } else {
            navigate(`/${webUrl}`);
          }
        });
      }
    }else{
      dispatch(getProfileDetails()).then((response) => {
        // console.log('PROFILE_DATA', response)
        if (tokenData?.route) {
          navigate(tokenData?.route);
        } else {
          navigate(`/`);
        }
      });
    }
  };

  const getProfileData = async (tokenData) => {
    console.log("Hittt", tokenData);
    if (tokenData?.profileId && tokenData?.token) {
      sessionStorage.setItem("token", tokenData?.token);
      sessionStorage.setItem("profileId", tokenData?.profileId);

      await getBusinessIdByUrl(tokenData);
    }
  };

  useEffect(() => {
    const authToken = new URLSearchParams(location.search).get("token");

    if (authToken) {
      const plainText = decryptData(authToken);
      console.log("plainText:", plainText);

      if (plainText) {
        getProfileData(plainText);
      }
    }
  }, [location.search]);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ThreeDotLoader />
    </Box>
  );
};

export default UserAuth;
