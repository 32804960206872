import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackShade43,
  greyColor40,
  greyColor6,
  greyShade83,
  lightBlueColor04,
  lightBlueColor05,
  logoImgborder1,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { textOneLines } from "../../../utils/styles";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PersonIcon from "@mui/icons-material/Person";

function WarehouseContactInfoCard({
  activeBusinessInfo,
  commerceInfo,
  onManageClick,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [emailString, setEmailString] = useState("");

  useEffect(() => {
    if (activeBusinessInfo?.businessMailingAddress) {
      let mailText = "";
      const emailList = activeBusinessInfo?.businessMailingAddress?.split(",");

      if (emailList?.length > 0) {
        emailList?.forEach((item, index) => {
          mailText =
            index === emailList?.length - 1
              ? mailText + item
              : mailText + `${item},  `;
        });

        setEmailString(mailText);
      }
    }

    return () => {};
  }, [activeBusinessInfo]);

  return (
    <>
      <Box sx={styles.card}>
        <Box
          display={{ xs: "none", md: "flex" }}
          alignItems="center"
          gap={1.5}
          flex={0.4}
        >
          <Box sx={styles.iconCircle}>
            <AdminPanelSettingsOutlinedIcon sx={styles.icon} />
          </Box>

          <Box>
            <Typography fontSize={11.2} fontWeight={500} color={greyColor40}>
              OWNER
            </Typography>

            <ContactInfo
              icon={<PersonIcon sx={{ fontSize: "15px" }} />}
              text={activeBusinessInfo?.owner?.name || ""}
            />

            <ContactInfo
              icon={<CallIcon sx={{ fontSize: "15px" }} />}
              text={
                activeBusinessInfo?.owner?.phone
                  ? activeBusinessInfo?.owner?.phone?.replace("+91", "+91 ")
                  : ""
              }
            />
          </Box>
        </Box>

        <Box display="flex" alignItems="center" gap={1.5} flex={1}>
          <Box sx={styles.iconCircle}>
            <ApartmentIcon sx={styles.icon} />
          </Box>

          <Box flex={1}>
            <Typography
              fontSize={{ xs: 10, md: 11.2 }}
              fontWeight={500}
              color={greyColor40}
            >
              WAREHOUSE CONTACT
            </Typography>

            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              gap={{ xs: 0.2, md: 1.5 }}
            >
              <ContactInfo
                icon={
                  <CallIcon sx={{ fontSize: { xs: "13px", md: "15px" } }} />
                }
                text={
                  activeBusinessInfo?.contactNos?.length > 0
                    ? activeBusinessInfo?.contactNos?.[0]?.number?.replace(
                        "+91",
                        "+91 "
                      )
                    : ""
                }
              />

              <Box flex={1} my={{ xs: 0.2, md: 0 }}>
                <ContactInfo
                  icon={
                    <FmdGoodIcon
                      sx={{ fontSize: { xs: "13px", md: "15px" } }}
                    />
                  }
                  text={commerceInfo?.address || "N/A"}
                  isTextOneLine
                />
              </Box>
            </Box>

            {matches ? (
              <ContactInfo
                icon={
                  <EmailIcon sx={{ fontSize: { xs: "13px", md: "15px" } }} />
                }
                text={emailString || "N/A"}
                isTextOneLine
              />
            ) : (
              activeBusinessInfo?.businessMailingAddress
                ?.split(",")
                ?.map((item, index) => (
                  <ContactInfo
                    key={`email_${index}`}
                    icon={
                      <EmailIcon
                        sx={{ fontSize: { xs: "13px", md: "15px" } }}
                      />
                    }
                    text={item}
                    isTextOneLine
                  />
                ))
            )}
          </Box>
        </Box>

        <Button sx={styles.btn} onClick={onManageClick}>
          Manage
        </Button>
      </Box>

      {/* <Box
        sx={{ ...styles.card, display: { xs: "flex", md: "none" }, mt: 1.5 }}
      >
        <Box display={{ xs: "flex", md: "none" }} alignItems="center" gap={1.5}>
          <Box sx={styles.iconCircle}>
            <AdminPanelSettingsOutlinedIcon sx={styles.icon} />
          </Box>

          <Box>
            <Typography
              fontSize={{ xs: 10, md: 11.2 }}
              fontWeight={500}
              color={greyColor40}
            >
              OWNER
            </Typography>

            <Box display="flex" alignItems="center" gap={1.5}>
              <ContactInfo
                icon={<PersonIcon sx={{ fontSize: "15px" }} />}
                text={activeBusinessInfo?.owner?.name || ""}
              />

              <ContactInfo
                icon={<CallIcon sx={{ fontSize: "15px" }} />}
                text={
                  activeBusinessInfo?.owner?.phone
                    ? activeBusinessInfo?.owner?.phone?.replace("+91", "+91 ")
                    : ""
                }
              />
            </Box>
          </Box>
        </Box>
      </Box> */}
    </>
  );
}

export default WarehouseContactInfoCard;

const ContactInfo = ({ icon, text, isTextOneLine }) => {
  return (
    <Box display="flex" alignItems="center" gap={0.7}>
      {icon}
      <Typography
        fontSize={{ xs: 11.5, md: 12 }}
        fontWeight={500}
        color={blackShade43}
        lineHeight={{ xs: "19px", md: "22px" }}
        sx={isTextOneLine ? { ...textOneLines } : null}
        title={isTextOneLine ? text : null}
      >
        {text}
      </Typography>
    </Box>
  );
};

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    gap: 2,
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "13px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "5px 10px", md: "10px 15px" },
    width: "100%",
    position:'relative'
  },

  iconCircle: {
    width: { md: "40px", xs: "34px" },
    height: { md: "40px", xs: "34px" },
    borderRadius: "50%",
    backgroundColor: { xs: lightBlueColor05, md: greyShade83 },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  icon: {
    fontSize: { md: "20px", xs: "17px" },
    color: { xs: mobilePrimary, md: lightBlueColor04 },
  },

  btn: {
    fontSize: 11.5,
    fontWeight: 400,
    color: greyColor6,
    textDecoration: "underline",
    textTransform: "unset",
    p: "0px",
    position:'absolute',
    top:7, right:15
  },
};
