import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import ThreeDotLoader from "../components/ThreeDotLoader";
import { getCountryNames, getGstRates } from "../reducers/catalog/CatalogSlice";
import { BGColor02 } from "../configs/styles/muiThemes";
import AddONDCDetailsNewContent from "./AddONDCDetailsNew/AddONDCDetailsNewContent";

function AddONDCDetailsNew() {
  const dispatch = useDispatch();

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      dispatch(getCountryNames());
      dispatch(getGstRates());
    }
  }, []);
  return (
    <Box sx={styles.container}>
      <DashboardLayout activeMenu="catalog">
        {activeBusinessInfoLoader ? (
          <ThreeDotLoader />
        ) : (
          <AddONDCDetailsNewContent />
        )}
      </DashboardLayout>
    </Box>
  );
}

export default AddONDCDetailsNew;

const styles = {
  container: {
    backgroundColor: BGColor02,
  },
};
