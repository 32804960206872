import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  BGColor02,
  blackShade05,
  greyColor6,
  logoImgborder1,
  whiteColor,
} from "../../configs/styles/muiThemes";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import RedeemIcon from "@mui/icons-material/Redeem";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { addThousandsSeparator } from "../../utils/helper";
import EmptyCashBack from "./EmptyCashBack";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import EmptyCard from "../../components/Cards/EmptyCard";
import CustomerCashBackInfo from "./CustomerCashBackInfo";
import { stickyHeader } from "../../utils/styles";

function CashBackDetails({
  cashbackStats,
  customerCashback,
  activeBusinessInfo,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box>
      {cashbackStats?.cashbackSummary?.rewardPointPercent === 0 && (
        <EmptyCashBack activeBusinessInfo={activeBusinessInfo} />
      )}
      {(cashbackStats?.cashbackSummary?.rewardPointPercent !== 0 ||
        cashbackStats?.cashbackCount?.totalCashbackOrderNumbers > 0) && (
        <>
          <Stack
            sx={{
              position: "sticky",
              top: { md: 125, xs: 190 },
              backgroundColor: BGColor02,
              zIndex: 100,
              pb: {md:2,xs:1},
            }}
            gap={{md:2,xs:1.5}}
          >
            <Typography
              variant="h4"
              color={blackShade05}
              fontSize={{ md: "15px", xs: "14px" }}
              fontWeight="600"
            >
              Cashback Settings
            </Typography>
            <Box gap={{md:2,xs:1}} sx={styles.infoBox}>
              <StatsInfoCard
                icon={
                  <SavingsOutlinedIcon
                    sx={{ fontSize: { xs: "0px", md: "24px" } }}
                  />
                }
                label="CASHBACK %"
                sublabel="(ON ALL ORDERS)"
                value={`${
                  cashbackStats?.cashbackSummary?.rewardPointPercent || 0
                }%`}
              />
              <StatsInfoCard
                icon={
                  <CalendarMonthIcon
                    sx={{ fontSize: { xs: "0px", md: "24px" } }}
                  />
                }
                label="CASHBACK VALIDITY"
                sublabel="  "
                value={`${
                  cashbackStats?.cashbackSummary?.rewardExpirationDays || 0
                } days`}
              />
              <StatsInfoCard
                icon={
                  <CurrencyExchangeIcon
                    sx={{ fontSize: { xs: "0px", md: "24px" } }}
                  />
                }
                label="MAX CASHBACK"
                sublabel="(USAGE FOR ORDER)"
                value={`₹ ${
                  addThousandsSeparator(
                    cashbackStats?.cashbackSummary?.rewardPointMaxPerTXn
                  ) || 0
                }`}
              />
            </Box>
            {matches && (
              <>
                <Typography
                  variant="h4"
                  color={blackShade05}
                  fontSize={{ md: "15px", xs: "14px" }}
                  fontWeight="600"
                >
                  Cashback
                </Typography>
                <Box gap={{md:2,xs:1}} sx={styles.infoBox}>
                  <StatsInfoCard
                    icon={
                      <RedeemIcon
                        sx={{ fontSize: { xs: "0px", md: "24px" } }}
                      />
                    }
                    label="TOTAL CASHBACK"
                    sublabel="AVAILABLE WITH CUSTOMERS"
                    value={`₹ ${
                      addThousandsSeparator(
                        customerCashback?.totalAvailableCashBackValueForBusiness
                      ) || 0
                    }`}
                  />
                  <StatsInfoCard
                    icon={
                      <CalendarMonthIcon
                        sx={{ fontSize: { xs: "0px", md: "24px" } }}
                      />
                    }
                    label="CASHBACK VALIDITY"
                    sublabel="  "
                    value={`${
                      cashbackStats?.cashbackSummary?.rewardExpirationDays || 0
                    } days`}
                  />
                  <StatsInfoCard
                    icon={
                      <RedeemIcon
                        sx={{ fontSize: { xs: "0px", md: "24px" } }}
                      />
                    }
                    label="TOTAL CASHBACK"
                    sublabel="USED BY CUSTOMERS"
                    value={`₹ ${
                      addThousandsSeparator(
                        cashbackStats?.cashbackCount?.totalCashbackApplied
                      ) || 0
                    }`}
                  />
                </Box>
              </>
            )}
          </Stack>
          {!matches && (
            <Stack gap={1} mb={2} mt={1}>
              <Typography
                variant="h4"
                color={blackShade05}
                fontSize={{ md: "15px", xs: "14px" }}
                fontWeight="600"
              >
                Cashback
              </Typography>
              <Box gap={1} sx={styles.infoBox}>
                <StatsInfoCard
                  icon={
                    <RedeemIcon sx={{ fontSize: { xs: "0px", md: "24px" } }} />
                  }
                  label="TOTAL CASHBACK"
                  sublabel="AVAILABLE WITH CUSTOMERS"
                  value={`₹ ${
                    addThousandsSeparator(
                      customerCashback?.totalAvailableCashBackValueForBusiness
                    ) || 0
                  }`}
                />
                <StatsInfoCard
                  icon={
                    <CalendarMonthIcon
                      sx={{ fontSize: { xs: "0px", md: "24px" } }}
                    />
                  }
                  label="CASHBACK VALIDITY"
                  sublabel="  "
                  value={`${
                    cashbackStats?.cashbackSummary?.rewardExpirationDays || 0
                  } days`}
                />
                <StatsInfoCard
                  icon={
                    <RedeemIcon sx={{ fontSize: { xs: "0px", md: "24px" } }} />
                  }
                  label="TOTAL CASHBACK"
                  sublabel="USED BY CUSTOMERS"
                  value={`₹ ${
                    addThousandsSeparator(
                      cashbackStats?.cashbackCount?.totalCashbackApplied
                    ) || 0
                  }`}
                />
              </Box>
            </Stack>
          )}
          <CustomerCashBackInfo />
        </>
      )}

      {/* {(cashbackStats?.cashbackSummary?.rewardPointPercent !== 0 ||
        cashbackStats?.cashbackCount?.totalCashbackOrderNumbers > 0) && (
        <Grid container spacing={1}>
          {cashbackStats?.cashbackSummary?.rewardPointPercent > 0 && (
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                // position: "sticky",
                //top: { md: 127, xs: 200 },
                backgroundColor: BGColor02,
                zIndex: 100,
                pb: 2,
              }}
            >
              <Typography
                variant="h4"
                color={blackShade05}
                fontSize={{ md: "15px", xs: "14px" }}
                fontWeight="600"
              >
                Cashback Settings
              </Typography>
            </Grid>
          )}
          {cashbackStats?.cashbackSummary?.rewardPointPercent > 0 && (
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                position: "sticky",
                top: { md: 160, xs: 235 },
                backgroundColor: BGColor02,
                zIndex: 100,
                pb: { xs: 1.3, md: 2 },
              }}
            >
              <Grid sx={styles.infoBox} container spacing={1}>
                <Grid item xs={12} md={4}>
                  <StatsInfoCard
                    icon={
                      <SavingsOutlinedIcon
                        sx={{ fontSize: { xs: "0px", md: "24px" } }}
                      />
                    }
                    label="CASHBACK %"
                    sublabel="(ON ALL ORDERS)"
                    value={`${
                      cashbackStats?.cashbackSummary?.rewardPointPercent || 0
                    }%`}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StatsInfoCard
                    icon={
                      <CalendarMonthIcon
                        sx={{ fontSize: { xs: "0px", md: "24px" } }}
                      />
                    }
                    label="CASHBACK VALIDITY"
                    sublabel="  "
                    value={`${
                      cashbackStats?.cashbackSummary?.rewardExpirationDays || 0
                    } days`}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StatsInfoCard
                    icon={
                      <CurrencyExchangeIcon
                        sx={{ fontSize: { xs: "0px", md: "24px" } }}
                      />
                    }
                    label="MAX CASHBACK"
                    sublabel="(USAGE FOR ORDER)"
                    value={`₹ ${
                      addThousandsSeparator(
                        cashbackStats?.cashbackSummary?.rewardPointMaxPerTXn
                      ) || 0
                    }`}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            md={12}
            sx={{
              position: "sticky",
              top: { md: 257, xs: "none" },
              backgroundColor: BGColor02,
              zIndex: 0,
              pb: 2,
            }}
          >
            <Typography
              variant="h4"
              color={blackShade05}
              fontSize={{ md: "15px", xs: "14px" }}
              fontWeight="600"
            >
              Cashback
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            sx={{
              position: "sticky",
              top: { md: 299, xs: "none" },
              backgroundColor: BGColor02,
              zIndex: 0,
              pb: 2,
            }}
          >
            <Grid sx={styles.infoBox} container spacing={1}>
              <Grid item xs={12} md={4}>
                <StatsInfoCard
                  icon={
                    <RedeemIcon sx={{ fontSize: { xs: "0px", md: "24px" } }} />
                  }
                  label="TOTAL CASHBACK"
                  sublabel="AVAILABLE WITH CUSTOMERS"
                  value={`₹ ${
                    addThousandsSeparator(
                      customerCashback?.totalAvailableCashBackValueForBusiness
                    ) || 0
                  }`}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <StatsInfoCard
                  icon={
                    <CreditScoreIcon
                      sx={{ fontSize: { xs: "0px", md: "24px" } }}
                    />
                  }
                  label="ORDER PLACED"
                  sublabel="USING CASHBACK"
                  value={
                    cashbackStats?.cashbackCount?.totalCashbackOrderNumbers || 0
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <StatsInfoCard
                  icon={
                    <RedeemIcon sx={{ fontSize: { xs: "0px", md: "24px" } }} />
                  }
                  label="TOTAL CASHBACK"
                  sublabel="USED BY CUSTOMERS"
                  value={`₹ ${
                    addThousandsSeparator(
                      cashbackStats?.cashbackCount?.totalCashbackApplied
                    ) || 0
                  }`}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12}>
            {console.log("JSX_RENDER_______INFINITE")}
            <CustomerCashBackInfo />
          </Grid>
        </Grid>
      )} */}
    </Box>
  );
}

export default CashBackDetails;
const StatsInfoCard = ({ icon, label, sublabel, value }) => {
  return (
    <Box flex={1} display="flex" gap={{ xs: "none", md: "10px" }} alignItems="center" >
      <Box sx={styles.iconCircle}>{icon}</Box>

      <Box
        display={"flex"}
        flexDirection={{ md: "column", xs: "row" }}
        justifyContent="space-between"
        width={{md:"auto",xs:"100%"}}
      >
        <Box flex={1}>
          <Typography
            fontSize={{ xs: "11px", md: "12" }}
            fontWeight={500}
            color={greyColor6}
            lineHeight="15px"
          >
            {label}
          </Typography>
          <Typography
            fontSize={{ xs: "10px", md: "10" }}
            fontWeight={500}
            color={greyColor6}
          >
            {sublabel}
          </Typography>
        </Box>
        <Box >
          <Typography
            fontSize={{ xs: "14px", md: "16" }}
            fontWeight={600}
            color={blackShade05}
            textAlign={{xs:"right",md:"left"}}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
    infoBox: {
    width:{md:"60%",xs:"100%"},
    display: "flex",
    flexDirection: {md:"row",xs:"column"},
    flexWrap:"wrap",
    justifyContent:"space-between",
    backgroundColor: whiteColor,
    baxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    padding: {md:"10px",xs:"10px"},
    border: logoImgborder1,
    borderRadius: "10px",
  },
  infoInnerBox: {
    display: "flex",
    marginTop: { xs: "10px", md: "0px" },
    justifyContent: "space-between",
  },

  iconCircle: {
    width: { xs: "none", md: "45px" },
    height: { xs: "none", md: "45px" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(5 60 81 / 7%)",
    borderRadius: "50%",
  },
};
