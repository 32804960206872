import { Box, Tab, Tabs, Typography } from '@mui/material'
import React, { useState } from 'react'
import { bgColor15, blackColor, blackShade05, blueColor1, greyColor9, whiteColor } from '../../configs/styles/muiThemes';
import NewIssueRaised from './Tabs/NewIssueRaised';
import ModalCard from '../../components/ModalCard';
import ViewIssueDetails from './PopupCards/ViewIssueDetails';
import { useNavigate } from 'react-router-dom';

function IssueRaisingContent() {
  const [open, setOpen] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const [tabTitle, setTabTitle] = useState("NEW");

  const [openViewIssue, setOpenViewIssue] = useState({
    show: false,
  });

  let navigate=useNavigate();

    const ISSUE_STATUS = [
      { id: 0, label: "NEW" },
      { id: 1, label: "INPROCESS" },
      { id: 2, label: "COMPLETED" },
    ];
    const getTabTitle = (tabsNum) => {
      switch (tabsNum) {
        case 0:
          return "NEW";
        case 1:
          return "INPROCESS";
        case 2:
         return "COMPLETED";
      }
    };
    const getIssueCoutByStatus = (tabNum) => {
      switch (tabNum) {
        case 0:
          return 15;
        case 1:
          return 17;
        case 2:
          return 12;
        default:
          return "";
      }
    };

    const handleChange = (event, newValue) => {
      setTabTitle(getTabTitle(newValue));
      setOpen(false);
      setTabValue(newValue);
    };

    const handleViewIssue = () => {
    //   openViewIssue({ show: true });
    navigate(
      `/${sessionStorage.getItem("costboWEBURL")}/issueRaisingDetail`
    );
    };
  return (
    <Box>
      <Typography
        component="h4"
        fontSize={17}
        color={blackShade05}
        fontWeight={600}
        lineHeight="20px"
      >
        Customer Grievance
      </Typography>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          mb: { xs: "10px", md: 1 },
          mt: 2,
        }}
      >
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => handleChange(event, newValue)}
          textColor={blackColor}
          TabIndicatorProps={{
            style: {
              height: "3px",
              backgroundColor: blueColor1,
            },
          }}
          sx={{
            minHeight: "35px !important",
            "& .MuiButtonBase-root": {
              minWidth: "auto !important",
            },
          }}
        >
          {ISSUE_STATUS?.map((item, index) => {
            return (
              <Tab
                key={"filter_" + index}
                label={item.label + ` - ${getIssueCoutByStatus(index)}`}
                // label={item.label}
                sx={{
                  fontSize: { xs: "11px", md: "13px" },
                  fontWeight: tabValue == index ? 600 : 500,
                  p: { xs: "10px 35px 10px 10px", md: 0 },
                  minHeight: "30px !important",
                  mr: { xs: "0px", md: "55px" },
                  color: {
                    md: item.id === tabValue ? blueColor1 : greyColor9,
                    xs: item.id === tabValue ? whiteColor : bgColor15,
                  },
                  backgroundColor: {
                    xs: item.id === tabValue ? bgColor15 : whiteColor,
                    md: "transparent",
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    display: "inline-block",
                    width: "35px",
                    height: "35px",
                    top: "0px",
                    right: "-20px",
                    background: {
                      xs: item.id === tabValue ? bgColor15 : whiteColor,
                      md: "transparent",
                    },
                    borderRight: {
                      xs: `2px solid ${
                        item.id === tabValue ? whiteColor : bgColor15
                      }`,
                      md: "none",
                    },
                    borderBottom: {
                      xs: `2px solid ${
                        item.id === tabValue ? whiteColor : bgColor15
                      }`,
                      md: "none",
                    },
                    borderTopRightRadius: "5px",
                    transform: "scale(0.707) rotate(-45deg)",
                    zIndex: 16,
                  },
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box>
        {tabValue === 0 && <NewIssueRaised handleViewIssue={handleViewIssue} />}
      </Box>
    </Box>
  );
}

export default IssueRaisingContent