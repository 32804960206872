import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import {
  affiliateCardBorderBottom,
  bgColor15,
  blackShade05,
  blueColor02,
  blueColor1,
  copyrightTextColor,
  greenColor2,
  greyColor6,
  greyColor8,
  logoImgborder1,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ProfileCharAvatar from "../../../components/ProfileCharAvatar";

function AffiliateOrderCard({
  partnerName,
  partnerNumber,
  partnerPic,
  orderValue,
  comminsionEarned,
  commissionPercentage,
  discountCommission,
  orderDate,
  orderId,
  orderStatus,
}) {
  return (
    <Box sx={styles.infoBox}>
      <Box
        display="flex"
        alignItems={{ xs: "flex-start", md: "center" }}
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        width="100%"
        pb={1}
        borderBottom={`1px dashed ${affiliateCardBorderBottom}`}
        gap={1}
      >
        <Box display="flex" gap="60px" flex={1}>
          <Box display="flex" gap="10px">
            <Box display={{ xs: "block", md: "block" }}>
              {partnerPic ? (
                <Avatar
                  alt="remy sharp"
                  src={partnerPic}
                  // src="https://storage.googleapis.com/bo3151920215/account/5b81b8aa07ba461c8aab9d0800d918e6/profilephoto.jpeg"
                  sx={{
                    width: "38px",
                    height: "38px",
                  }}
                />
              ) : (
                <ProfileCharAvatar
                  username={partnerName || "Guest"}
                  border
                  size="50px"
                />
              )}
            </Box>

            <Box>
              <Typography
                fontSize={{ xs: 13.5, md: 14.5 }}
                fontWeight={600}
                color={blackShade05}
              >
                {partnerName}
              </Typography>
              <Typography fontSize={12} fontWeight={400} color={bgColor15}>
                {partnerNumber}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          width={{ xs: "100%", md: "auto" }}
          display="flex"
          //alignItems={{ xs: "flex-start", md: "flex-end" }}
          flexDirection={{ xs: "row", md: "column" }}
          justifyContent={{ xs: "space-between", md: "flex-start" }}
        >
          <Typography
            variant="h6"
            fontSize={{ xs: "12.5", md: "13" }}
            fontWeight={600}
            color={blackShade05}
          >
            Order Id : {orderId}
          </Typography>

          <Typography
            variant="body1"
            fontSize={12}
            fontWeight={600}
            color={greenColor2}
            textAlign="right"
          >
            {orderStatus === "ordercreated"
              ? "New"
              : orderStatus === "orderapproved"
              ? "Approved"
              : orderStatus === "ordershipped"
              ? "Shipped"
              : orderStatus === "orderdelivered"
              ? "Delivered"
              : orderStatus === "bordercanceled"
              ? "Canceled"
              : ""}
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        p="15px"
        gap="20px"
      >
        <Grid container spacing={2}>
          <Grid item xs={6} md={2.4}>
            <AffiliateDiascription
              label="Order Value"
              labelValue={orderValue}
            />
          </Grid>

          <Grid item xs={6} md={2.4}>
            <AffiliateDiascription label="Order Date" labelValue={orderDate} />
          </Grid>
          <Grid item xs={6} md={2.4}>
            <AffiliateDiascription
              label="Commission"
              labelValue={comminsionEarned}
            />
          </Grid>
          <Grid item xs={6} md={2.4}>
            <AffiliateDiascription
              label="Commission %"
              labelValue={commissionPercentage + "%"}
            />
          </Grid>
          <Grid item xs={6} md={2.4}>
            <AffiliateDiascription
              label="Customer Discount"
              labelValue={discountCommission + "%"}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default AffiliateOrderCard;

const AffiliateDiascription = ({ label, labelValue }) => {
  return (
    <Box
      flex={1}
      borderRight={{
        md: `1px dashed ${affiliateCardBorderBottom}`,
        xs: "none",
      }}
    >
      <Typography variant="h5" sx={styles.valueTitle}>
        {label}
      </Typography>
      <Typography sx={styles.value}>{labelValue}</Typography>
    </Box>
  );
};

const styles = {
  infoBox: {
    backgroundColor: whiteColor,
    baxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    padding: "15px 15px 0px",
    border: logoImgborder1,
    borderRadius: "10px",
    marginTop: "22px",
  },
  valueTitle: {
    color: greyColor6,
    fontSize: "13px",
    fontWeight: "400",
  },

  value: {
    color: blackShade05,

    fontSize: "13px",
    fontWeight: "600",
  },
  updateBtn: {
    backgroundColor: "transparent",
    padding: "6px 22px",
    fontSize: "13px",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: greyColor8,
    border: "1.5px solid " + greyColor8,
    "&:hover": {
      color: greyColor8,
      backgroundColor: whiteColor,
    },
  },
};
