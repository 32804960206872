import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  greyColor24,
  whiteColor,
} from "../../configs/styles/muiThemes";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { stickyHeader } from "../../utils/styles";

export default function SupportContent({ activeBusinessInfo }) {
  const whatsAppNumber = "+918088422615";
  const emailAddress = "support@costbo.com";
  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        // `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${
          activeBusinessInfo ? activeBusinessInfo?.name : "none"
        }+Support+Request.+`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };
  const handleEmailClick = () => {
    window.open(
      `https://mail.google.com/mail/u/0/?su=${activeBusinessInfo?.name}+Support+Request.+&fs=1&to=${emailAddress}&tf=cm&body=&tf=cm`,
      "_blank"
    );
  };
  return (
    <Box mt={{ md: 1, xs: 1 }}>
      <Typography
        variant="h4"
        color={blackShade05}
        fontSize={{ xs: 15, md: "18px" }}
        fontWeight="600"
        pt={{xs:1.5, md:0}}
      >
        We are here to help!
      </Typography>
      
      <Typography
        variant="h6"
        color={blackShade05}
        fontSize={{xs:13.5, md:"14px"}}
        fontWeight="400"
        mt={{xs:1, md:2}}
      >
        For any queries regarding Onboarding OR Order Management services,
        please contact our specialists
      </Typography>

      <Typography
        variant="h6"
        color={blackShade05}
        fontSize={{xs:13.5, md:"14px"}}
        fontWeight="400"
        mt={1}
        marginBottom={2}
      >
        Please include a brief description about the request/issue, so we can
        serve you better.
      </Typography>
      <Grid container columnSpacing={6} spacing={2}>
        <Grid item xs={12} md={6}>
          <Box textAlign="center">
            <Box
              sx={styles.card}
              onClick={handleEmailClick}
              // component="a"
              // href={`https://mail.google.com/mail/u/0/?su=${activeBusinessInfo?.name}+Support+Request.+&fs=1&to=${emailAddress}&tf=cm&body=&tf=cm`}
              // target="_blank"
              // cursor="pointer"
            >
              <Box sx={styles.iconCircle}>
                <EmailOutlinedIcon
                  sx={{ fontSize: { xs: "24px", md: "35px" } }}
                />
              </Box>
              <Typography
                fontSize={{xs:15, md:16}}
                fontWeight={500}
                color={blackShade05}
                lineHeight="20px"
                textAlign="center"
                mt="6px"
                // component="a"
                // href={`https://mail.google.com/mail/u/0/?su=${activeBusinessInfo?.name}+ Support+Request.+&fs=1&to=${emailAddress}&tf=cm&body=&tf=cm`}
                // target="_blank"
              >
                support@costbo.com
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box textAlign="center">
            <Box sx={styles.card} onClick={handleChatClick}>
              <Box sx={styles.iconCircle}>
                <Box
                  component="img"
                  src="https://storage.googleapis.com/bodefaults/businessdashboardweb/whatsapp-support.svg"
                  alt="whatsapp-support"
                  width={{ md: "35px", xs: "24px" }}
                  height="auto"
                  display="block"
                />
              </Box>
              <Typography
                fontSize={{xs:15, md:16}}
                fontWeight={500}
                color={blackShade05}
                lineHeight="20px"
                textAlign="center"
                mt="6px"
              >
                {whatsAppNumber}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    p: "25px 25px",
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: { xs: "8px", md: "8px" },
  },
  iconCircle: {
    width: { xs: "45px", md: "60px" },
    height: { xs: "45px", md: "60px" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(5 60 81 / 7%)",
    borderRadius: "50%",
    marginBottom: 2,
  },
};
