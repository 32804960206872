import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  addressBtnColor,
  bgColor,
  blackColor,
  blackShade03,
  blackShade06,
  blackShade58,
  blueColor04,
  mobilePrimary,
  orangeBgColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { getGeoPointApi } from "../../../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";
import { useState } from "react";
import PickLocationBtn from "../../../components/Buttons/PickLocationBtn";
import { getAddressInfoFromLatLong } from "../../../utils/geoLocationHelper";
import PickAddressFromMapModal from "../../../components/PickAddress/PickAddressFromMapModal";
import FormInputBoxWithBorder from "../../../components/Input/FormInputBoxWithBorder";
import NavigationButtons from "../Components/NavigationButtons";
import { isStringNotPresentInArray } from "../../../utils/helper";

function AddressInfoForm({
  handleNext,
  handleBack,
  handleSave,
  addressDetails,
  onValueChange,
  setAddressDetails,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [openMap, setOpenMap] = useState({
    show: false,
    userLat: "",
    userLong: "",
  });

  const [cityDisabled, setCityDisabled] = useState(true);

  const handlePinChange = async (value) => {
    if (value?.length === 6) {
      const responce = await getGeoPointApi(value);

      const pinCodeErr = isStringNotPresentInArray(
        responce?.results?.[0]?.address_components,
        "postal_code"
      );
      console.log("pinCodeErrpinCodeErr", pinCodeErr);
      if (pinCodeErr) {
        console.log("Hit");
        setAddressDetails({
          ...addressDetails,
          pinCode: value,
          city: "",
          state: "",
          landmark: "",
          address: "",
          errors: {
            ...addressDetails?.errors,
            pinCode: "Invalid pincode",
          },
        });
        return;
      }

      const city = responce?.results?.[0]?.address_components?.[1]?.long_name;

      const state =
        responce?.results?.[0]?.address_components?.[
          responce?.results?.[0]?.address_components?.length - 2
        ]?.long_name;

      if (!city || !state) setCityDisabled(false);

      setAddressDetails({
        ...addressDetails,
        city: city || "",
        state: state || "",
        landmark: "",
        locality: city || "",
        sublocality: "",
        lat: responce?.results?.[0]?.geometry?.location?.lat || "",
        lng: responce?.results?.[0]?.geometry?.location?.lng || "",
        pinCode: value,
        errors: { ...addressDetails?.errors, city: "", state: "", pinCode: "" },
      });

      setOpenMap({
        show: true,
        userLat: responce?.results?.[0]?.geometry?.location?.lat || "",
        userLong: responce?.results?.[0]?.geometry?.location?.lng || "",
      });
    }
  };

  // Get user current location address
  const handleChooseCurrentLocation = () => {
    setOpenMap({
      show: true,
      userLat: localStorage.getItem("lat"),
      userLong: localStorage.getItem("long"),
    });
  };

  // autoFill user selected address from map
  const handlePickLocationConfirm = (userAddress) => {
    if (userAddress) {
      setAddressDetails({
        ...addressDetails,
        city: userAddress?.city || "",
        state: userAddress?.state || "",
        pinCode: userAddress?.postalCode,
        address: userAddress?.address || "",
        lat: userAddress?.lat || "",
        lng: userAddress?.lng || "",
        landmark: userAddress?.locality || "",
        locality: userAddress?.locality || "",
        sublocality: userAddress?.sublocality || "",
        errors: {
          ...addressDetails?.errors,
          city: "",
          state: "",
          pinCode: "",
          address: "",
        },
      });
    }

    setOpenMap({
      show: false,
      userLat: "",
      userLong: "",
    });
  };

  return (
    <Box sx={styles.card}>
      {/* <Typography variant="h5" sx={styles.headingText}>
        Business Address
      </Typography> */}

      {/* <FormInputBox label="Registered Business Address" />

      <Typography
        variant="h5"
        sx={{ ...styles.headingText, mb: "5px !important" }}
      >
        Current Address
      </Typography> */}

      {/* <Typography variant="h5" sx={styles.subText}>
        Please enter your preferred communication address. You may choose to
        keep the same address from the GSTIN or use a different one.
      </Typography> */}

      <PickAddressFromMapModal
        isOpen={openMap.show}
        setIsOpen={setOpenMap}
        onConfirm={handlePickLocationConfirm}
        userLat={openMap.userLat}
        userLong={openMap.userLong}
      />

      <Grid container rowSpacing={{md:0.5,xs:0}} columnSpacing={3}>
        <Grid item xs={12} md={6}>
          <FormInputBoxWithBorder
            label="PinCode"
            required
            maxLength={6}
            value={addressDetails?.pinCode}
            onChange={({ target }) => {
              const value = target.value.replace(/[a-zA-Z]|[^\w\s]/gi, "");
              onValueChange("pinCode", value);
              if (!cityDisabled) setCityDisabled(true);
              if (value.length >= 6) handlePinChange(value);
            }}
            error={addressDetails?.errors?.pinCode}
          />
        </Grid>
        <Grid item xs={12}>
          <PickLocationBtn
            onChooseLocation={handleChooseCurrentLocation}
            onPickNearLocation={() => {
              setOpenMap(true);
            }}
            bgColor={whiteColor}
            textColor={matches ? blueColor04 : mobilePrimary}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormInputBoxWithBorder
            label="City"
            value={addressDetails?.city}
            onChange={({ target }) => {
              onValueChange("city", target.value);
            }}
            required
            disabled={cityDisabled}
            inputOpaticy={1}
            error={addressDetails?.errors?.city}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormInputBoxWithBorder
            label="State"
            value={addressDetails?.state}
            onChange={({ target }) => {
              onValueChange("state", target.value);
            }}
            required
            disabled={cityDisabled}
            inputOpaticy={1}
            error={addressDetails?.errors?.state}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <FormInputBoxWithBorder
            label="Bldg No, Street, Area"
            value={addressDetails?.address}
            onChange={({ target }) => {
              onValueChange("address", target.value);
            }}
            error={addressDetails?.errors?.address}
            required
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <FormInputBoxWithBorder
            label="Landmark"
            value={addressDetails?.landmark}
            onChange={({ target }) => {
              onValueChange("landmark", target.value);
            }}
          />
        </Grid>
      </Grid>
      <Box sx={styles.infoBox}>
        <Typography
          textAlign={"center"}
          fontWeight={500}
          fontSize={{xs:12, md:14}}
          color={blackShade58}
          lineHeight={{xs:'20px', md:'22px'}}
        >
          Please make sure the above address is accurate as the deliveries will
          be picked up from this location
        </Typography>
      </Box>

      {/* <ActionButtons
        onBackClick={handleBack}
        // onSaveClick={handleSave}
        onNextClick={handleNext}
        showBackBtn
        // saveBtnText={"save & exit"}
        hideSaveBtn
        saveTooltip={saveMsg}
      /> */}

      <NavigationButtons onBackClick={handleBack} onNextClick={handleNext} />
    </Box>
  );
}

export default AddressInfoForm;

const styles = {
  card: {
    // width: { xs: "100%", md: "100%" },
    // mt: { md: "35px", xs: 1.5 },
  },

  headingText: {
    fontSize: { xs: 17, md: 18 },
    color: blackColor,
    fontWeight: 500,
    lineHeight: "30px",
    mb: "10px !important",
  },

  subText: {
    fontSize: { xs: 12, md: 12 },
    color: blackShade03,
    fontWeight: 400,
    lineHeight: { xs: "17px", md: "20px" },
    mb: "15px !important",
  },

  infoBox: {
    px: 3,
    py: 1,
    borderRadius: "7px",
    backgroundColor: orangeBgColor,
    mb: 2,
  },
};
