import { Avatar, Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackShade05,
  blueColor02,
  blueColor04,
  greyColor6,
  logoImgborder1,
  mobilePrimary,
  whiteColor,
} from "../../configs/styles/muiThemes";
import moment from "moment";
import { addThousandsSeparator } from "../../utils/helper";
import ProfileCharAvatar from "../../components/ProfileCharAvatar";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCashbackInfo,
  getAllBusinessCashbackData,
} from "../../reducers/cashback/CashbackSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import EmptyCard from "../../components/Cards/EmptyCard";
import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined";

function CustomerCashBackInfo() {
  const PAGE_SIZE = 40;

  const dispatch = useDispatch();

  const [hasMoreData, setHasMoreData] = useState(true);

  const { businessCashback, businessCashbackPageNo, businessCashbackLoader } =
    useSelector((state) => state.businessCashback);

  const getCashBackCustomers = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      getAllBusinessCashbackData({
        businessId,
        pageNo: businessCashbackPageNo,
        pageSize: PAGE_SIZE,
      })
    );
  };
  const fetchNextData = () => {
    getCashBackCustomers();
    console.log("FETCH_HITT");
  };

  useEffect(() => {
    console.log("USEEFFECT_HIT");
    getCashBackCustomers();

    return () => {
      dispatch(clearCashbackInfo());
    };
  }, []);

  useEffect(() => {
    if (
      (businessCashbackPageNo - 1) * PAGE_SIZE >
      businessCashback?.details?.length
    ) {
      setHasMoreData(false);
    }
    console.log("PAGE_NO_INC ", businessCashbackPageNo);

    return () => {};
  }, [businessCashbackPageNo]);

  return (
    <Box>
      {!businessCashbackLoader &&
      businessCashbackPageNo === 1 &&
      businessCashback?.details?.length === 0 ? (
        <EmptyCard
          msg="No Cashback available with customers"
          icon={<CreditCardOffOutlinedIcon sx={{ fontSize: "24px" }} />}
        />
      ) : (
        businessCashbackLoader &&
        businessCashbackPageNo === 0 && <ThreeDotLoader />
      )}

      {businessCashback?.details?.length > 0 && (
        <InfiniteScroll
          dataLength={businessCashback?.details?.length || 0}
          next={fetchNextData}
          hasMore={hasMoreData}
          loader={
            <Box>
              <ThreeDotLoader />
            </Box>
          }
        >
          <Grid container spacing={2} >
            {businessCashback?.details?.map((item) => {
              const profileImg = businessCashback?.profileInfo?.filter(
                (profile) => profile?.profileId === item?.profileId
              );

              const profileImage = profileImg?.[0]?.photoUrl || "";
              const username = profileImg?.[0]?.name || "";

              return (
                <Grid item xs={12} md={3} key={item?.id}>
                  <CustomerCashBackDetails
                    availableCashback={addThousandsSeparator(
                      item?.availablePointValue
                    )}
                    totalEarned={addThousandsSeparator(item?.totalPointValue)}
                    date={moment(item?.expiryDate).format("MMM Do YYYY")}
                    profileImage={profileImage}
                    username={username}
                  />
                </Grid>
              );
            })}
          </Grid>
        </InfiniteScroll>
      )}
    </Box>
  );
}

export default CustomerCashBackInfo;

const CustomerCashBackDetails = ({
  availableCashback,
  totalEarned,
  date,
  profileImage,
  username,
}) => {
  return (
    <Box sx={{ ...styles.customerCashBackCard }}>
      <Box
        display="flex"
        p="10px 10px"
        borderBottom="1px solid #e5e9f0"
        gap="15px"
        alignItems={"center"}
      >
        {profileImage !== "" ? (
          <Avatar
            alt="Remy Sharp"
            src={profileImage}
            sx={{ width: { md: 47, xs: 42 }, height: { md: 47, xs: 42 } }}
          />
        ) : (
          <ProfileCharAvatar
            size={{ md: 47, xs: 42 }}
            username={username || "Guest"}
          />
        )}

        <Box>
          <Typography sx={styles.customerName}>
            {username || "Guest"}
          </Typography>
          <Box display="flex" justifyContent="space-around">
            <Typography sx={{ ...styles.cashBackTitle, marginRight: "8px" }}>
              Available Cashback
            </Typography>
            <Typography sx={styles.availableCashBack}>
              ₹ {availableCashback}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" p="8px 12px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography variant="h5" sx={styles.cashBackTitle}>
            Total Earned
          </Typography>
          <Typography sx={styles.cashBackEarned}>₹ {totalEarned}</Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography variant="p" sx={styles.cashBackTitle}>
            Valid Till
          </Typography>
          <Typography sx={{ ...styles.cashBackEarned }}>{date}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  customerCashBackCard: {
    backgroundColor: whiteColor,
    baxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    border: logoImgborder1,
    borderRadius: "10px",
  },
  customerName: {
    color: blackShade05,
    textAlign: "left",
    fontSize: "15px",
    fontWeight: "600",
  },
  availableCashBack: {
    color: {xs:mobilePrimary, md:blueColor04},
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "600",
  },

  cashBackTitle: {
    color: greyColor6,
    textAlign: "right",
    fontSize: "12px",
    fontWeight: "500",
  },

  cashBackEarned: {
    color: blackShade05,
    textAlign: "left",
    fontSize: "12px",
    fontWeight: "600",
  },
};
