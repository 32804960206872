import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  IconButton,
  Tabs,
  Tab,
  Chip,
  Snackbar,
  Tooltip,
  Popper,
  Fade,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import MuiAlert from "@mui/material/Alert";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import AddIcon from "@mui/icons-material/Add";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import moment from "moment";
import {
  clearSearchProducts,
  getActiveBusinessInfo,
  getCommerceInfo,
  getProductCategories,
  getProductsByBusiness,
  searchBusinessProducts,
} from "../../reducers/businessPreview/businessPreviewSlice";
import { updateInventoryData } from "../../service/api_calls/inventory/InventoryApi";
import DashboardMenu from "../Dashboard/DashboardMenu";
import DashboardHeader from "../Dashboard/DashboardHeader";
import { removeSessionInfo } from "../../utils/authHelpers";
import BusinessInfoCard from "../../components/Cards/BusinessInfoCard";
import StatsInfoCard from "../../components/Cards/StatsInfoCard";
import {
  BGColor02,
  blackColor,
  blackShade05,
  blueColor04,
  blueColor1,
  greyColor10,
  greyColor40,
  greyColor5,
  greyColor6,
  greyColor8,
  greyColor9,
  greyShade168,
  greyShade65,
  iconBG1,
  iconBG2,
  logoImgborder,
  logoImgborder1,
  mobilePrimary,
  tableHeaderColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import EcommerceEnableCard from "../../components/Cards/EcommerceEnableCard";
import {
  INVENTORY_STEP_LIST,
  INVENTORY_VIDEO_DATA,
  OOS_COLUMN_HEADING,
  PU_COLUMN_HEADING,
} from "../../utils/data";
import SelectWithLabel from "../../components/Input/SelectWithLabel";
import SearchBoxInput from "../../components/Input/SearchBoxInput";
import ModalCard from "../../components/ModalCard";
import PriceUpdateRowCard from "./PriceUpdateRowCard";
import EmptyCard from "../../components/Cards/EmptyCard";
import { getGstRates } from "../../reducers/catalog/CatalogSlice";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import { getStatsCountInfoData } from "../../reducers/businessDashboard/BusinessDashboardSlice";
import { addThousandsSeparator } from "../../utils/helper";
import HowToLinkCard from "../../components/Cards/HowToLinkCard";
import VideoModal from "../../components/VideoModal";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import { stickyHeader, scrollBox } from "../../utils/styles";
import MobileTab from "../../components/MobileTabs/MobileTab";

function PriceUpdate() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState(2);
  const [productsList, setProductsList] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [isClearSearch, setIsClearSearch] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedSubCat, setSelectedSubCat] = useState("All");

  const [openSnack, setOpenSnack] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [updateLoader, setUpdateLoader] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, value: "" });
  const [infoModal, setInfoModal] = useState(false);

  const [inventoryErr, setInventoryErr] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [openVideoModal, setOpenVideoModal] = useState(false);

  const {
    activeBusinessInfo,
    businessProducts,
    searchProducts,
    productCategory,
    commerceInfo,
    businessProductsLoader,
    searchProductsLoader,
    productCategoryLoader,
  } = useSelector((state) => state.businessPreview);

  const { statsCountInfo, statsCountInfoLoader } = useSelector(
    (state) => state.businessDashboard
  );

  const { gstRates } = useSelector((state) => state.catalog);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      dispatch(getProductsByBusiness(businessId));
      dispatch(getProductCategories(businessId));
      if (!commerceInfo) {
        dispatch(getCommerceInfo(businessId));
      }
      dispatch(getGstRates());

      // Getting Product and Sales Count
      if (!statsCountInfo) {
        dispatch(getStatsCountInfoData(businessId));
      }
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (productCategory?.details) {
      formatCategory(productCategory?.details, setCategoryList);
    }
    return () => {};
  }, [productCategory]);

  useEffect(() => {
    if (businessProducts?.productList) {
      const ossProducts = getPriceUpdateProducts();
      setProductsList(ossProducts);
    }
    return () => {};
  }, [businessProducts]);

  useEffect(() => {
    if (searchProducts?.details) {
      setIsClearSearch(true);

      // Get only availability -> Yes products from search
      const filteredSearchData = searchProducts?.details
        // ?.filter((item) => item?.availability?.[0] == "Yes")
        ?.map((item) => {
          return {
            ...item,
            errors: [],
            itemChange: false,
            currentStock: item.availableQuantity,
          };
        });

      setProductsList(filteredSearchData);
    }
    return () => {
      dispatch(clearSearchProducts());
    };
  }, [searchProducts]);

  // Prepare list data for filter dropdown
  const formatCategory = (category, setValue) => {
    const catList = category.map((item) => {
      return { ...item, label: item?.value };
    });

    setValue([{ label: "All" }, ...catList]);
  };

  // Prepare list data for OutOfStock products
  const getPriceUpdateProducts = () => {
    const products = businessProducts?.productList
      ?.filter((item) => item?.availability?.[0] == "Yes")
      .map((item) => {
        return {
          ...item,
          errors: [],
          itemChange: false,
          currentStock: item.availableQuantity,
        };
      });
    return products;
  };

  const clearSearch = () => {
    dispatch(clearSearchProducts());
    setProductsList(getPriceUpdateProducts());
    setSearchQuery("");
    setIsClearSearch(false);
  };

  // navigate To Tab
  const navigateToTab = (newValue) => {
    if (newValue === 1) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/lowStock`);
    }
    if (newValue === 0) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/outOfStock`);
    }
    if (newValue == 3) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/allStock`);
    }
  };

  const handleChange = (event, newValue) => {
    const filteredProducts = productsList?.filter((item) => item?.itemChange);
    if (filteredProducts?.length > 0) {
      setErrorMsg(
        `${tabValue == 2 ? "Pricing" : "Inventory"} has been modified for ${
          filteredProducts?.length
        } products. Do you want to save the changes before proceeding ?`
      );
      setErrorModal({ show: true, value: newValue });
      return;
    }

    navigateToTab(newValue);

    setSelectedCategory("All");
    setSelectedSubCat("All");
  };

  const getProductsCount = (tabId) => {
    if (tabId === 0) {
      let filteredList = businessProducts?.productList?.filter(
        (item) =>
          item?.availableQuantity < item?.minOrderQuantity &&
          item?.availability?.[0] == "Yes"
      );
      return filteredList?.length || 0;
    } else if (tabId === 1) {
      let filteredList = businessProducts?.productList?.filter(
        (item) =>
          item?.availableQuantity <= item?.alertQuantity &&
          item?.availableQuantity >= item?.minOrderQuantity &&
          item?.availability?.[0] == "Yes"
      );
      return filteredList?.length || 0;
    } else if (tabId === 2 || tabId == 3) {
      let filteredList = businessProducts?.productList?.filter(
        (item) => item?.availability?.[0] == "Yes"
      );
      return filteredList?.length || 0;
    }
  };

  //search for products
  const handleSearch = () => {
    if (!searchQuery) return;
    const businessId = sessionStorage.getItem("selectedBusinessId");
    setSelectedCategory("All");
    setSelectedSubCat("All");

    dispatch(
      searchBusinessProducts({ query: searchQuery, businessId: businessId })
    );
  };

  const clearData = () => {
    setSearchQuery("");
    setIsClearSearch(false);
    setSelectedCategory("All");
    setSelectedSubCat("All");
  };

  // Update inventory
  const updateInventory = (event) => {
    setInventoryErr(false);
    setUpdateLoader(true);
    let error = false;

    const filteredList = productsList?.filter((item) => item?.itemChange);

    if (filteredList?.length == 0) {
      setInventoryErr((prev) => !prev);
      setAnchorEl(event.currentTarget);
      setUpdateLoader(false);

      setTimeout(() => {
        setInventoryErr(false);
      }, 2000);
      return;
    }

    filteredList.forEach((item, index) => {
      let errors = [];
      console.log("ProductUpdate itteration", index, item);
      if (
        !item?.productSalePrice ||
        !item?.productOriginalPrice ||
        !item?.commerceStartDate ||
        !item?.commerceEndDate
      ) {
        errors.push("All fields are mandatory.");
        error = true;
      }
      if (Number(item?.productSalePrice) > Number(item?.productOriginalPrice)) {
        console.log("Hit1", index);
        errors.push("Sales price should not be more than Original price.");
        error = true;
      }
      if (moment(item?.commerceStartDate).isAfter(item?.commerceEndDate)) {
        console.log(
          "Hit2",
          index,
          item?.commerceEndDate,
          item?.commerceStartDate
        );
        errors.push(
          "Availability End date should not be prior than Start date."
        );
        error = true;
      }

      if (errors?.length > 0) {
        item.errors = errors;
      } else {
        item.errors = [];
      }
    });

    let newArr = [];
    productsList?.forEach((item) => {
      let value = item;
      filteredList?.forEach((ele) => {
        if (item?.id == ele?.id) {
          value = ele;
        }
      });
      newArr.push(value);
    });
    setProductsList(newArr);

    if (error) {
      setUpdateLoader(false);
      return;
    }

    const businessId = sessionStorage.getItem("selectedBusinessId");
    let newArr1 = [];
    filteredList?.forEach((item) => {
      newArr1.push({
        productId: item?.id,
        skuId: "",
        businessId: businessId,
        minOrderQuantity: item?.minOrderQuantity,
        maxOrderQuantity: item?.maxOrderQuantity,
        availableQuantity: item?.availableQuantity,
        alertQuantity: item?.alertQuantity,
        availability: ["Yes"],
        hyperLocal: "Y",
        shipmentDistance: "5000",
        productSalePrice: item?.productSalePrice,
        productOriginalPrice: item?.productOriginalPrice,
        commerceStartDate: moment(item?.commerceStartDate)
          .startOf("day")
          .valueOf(),
        commerceEndDate: moment(item?.commerceEndDate).endOf("day").valueOf(),
        gstInfo: item?.gstInfo,
      });
    });

    updateInventoryData(newArr1, businessId)
      .then((res) => {
        console.log("Success");
        setSuccessMsg(
          `${tabValue == 2 ? "Pricing" : "Inventory"} has been updated for ${
            filteredList?.length
          } products`
        );
        setOpenSnack(true);
        dispatch(getProductsByBusiness(businessId));
        setUpdateLoader(false);
      })
      .finally(() => {
        setUpdateLoader(false);
        clearData();
      });
  };

  // Filter Products by Category
  const handleCategoryFilter = (data) => {
    clearSearch();

    setSelectedCategory(data);
    setSelectedSubCat("All");

    if (data === "All") {
      setSubCatList([]);
      const filteredList = getPriceUpdateProducts();
      setProductsList(filteredList);
      return;
    }

    const CategoryData = categoryList?.filter((item) => {
      return item?.value === data;
    });

    formatCategory(CategoryData?.[0]?.subCat, setSubCatList);

    const productArray = getPriceUpdateProducts();

    const filteredProducts = productArray?.filter((item) => {
      return item?.productSubCategory?.[0]?.pcCode === CategoryData?.[0]?.code;
    });

    let filteredList1 = [];
    filteredProducts?.forEach((element) => {
      filteredList1?.push({
        ...element,
        itemChange: false,
        currentStock: element.availableQuantity,
        errors: [],
      });
    });

    setProductsList(filteredList1);
  };

  // Filter Products by Sub Category
  const handleSubCategoryFilter = (data) => {
    clearSearch();

    setSelectedSubCat(data);
    if (data === "All") {
      const CategoryData = categoryList?.filter((item) => {
        return item?.value === selectedCategory;
      });

      const currentProductList = getPriceUpdateProducts();

      const filteredProducts = currentProductList?.filter((item) => {
        return (
          item?.productSubCategory?.[0]?.pcCode === CategoryData?.[0]?.code
        );
      });

      let filteredList1 = [];
      filteredProducts?.forEach((element) => {
        filteredList1?.push({
          ...element,
          itemChange: false,
          currentStock: element.availableQuantity,
          errors: [],
        });
      });
      setProductsList(filteredList1);
      return;
    }

    const subCategoryData = subCatList?.filter((item) => {
      return item?.value === data;
    });

    const currentProductList = getPriceUpdateProducts();
    const filteredProducts = currentProductList?.filter((item) => {
      return item?.productSubCategory?.[0]?.code === subCategoryData?.[0]?.code;
    });

    let filteredList1 = [];
    filteredProducts?.forEach((element) => {
      filteredList1?.push({
        ...element,
        itemChange: false,
        currentStock: element.availableQuantity,
        errors: [],
      });
    });
    setProductsList(filteredList1);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setSuccessMsg("");
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  return (
    <Box sx={styles.container}>
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          handleClose={handleCloseVideoModal}
          data={INVENTORY_VIDEO_DATA}
        />
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={4000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
      <DashboardLayout activeMenu="inventory" activeSubMenu="activeInventory">
        <Box sx={styles.content}>
          <Box sx={{ ...stickyHeader, pt: { xs: 1.5, md: 0.5 } }}>
            <Box
              sx={{
                display: "flex",
                alignItems: { md: "center", xs: "flex-start" },
                justifyContent: "space-between",
                flexDirection: { md: "row", xs: "row" },
              }}
            >
              <Typography
                component="h4"
                fontSize={{ md: 17, xs: 15 }}
                color={blackShade05}
                fontWeight={600}
                lineHeight="20px"
              >
                Active Inventory
              </Typography>
              <HowToLinkCard
                link="Inventory Management"
                handleClick={() => {
                  setOpenVideoModal(true);
                  // navigate("/howToVideoGuides", {
                  //   state: { status: "inventory" },
                  // });
                }}
                pb={{ xs: 0, md: 2 }}
              />
            </Box>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                mb: { xs: 0, md: 1 },
                mt: { xs: 1, md: 0 },
              }}
            >
              {matches ? (
                <Tabs
                  value={tabValue}
                  onChange={(event, newValue) => handleChange(event, newValue)}
                  aria-label="basic tabs example"
                  textColor={blackColor}
                  TabIndicatorProps={{
                    style: {
                      height: "3px",
                      backgroundColor: blueColor04,
                    },
                  }}
                  sx={{
                    minHeight: "35px !important",
                    "& .MuiButtonBase-root": {
                      minWidth: "auto !important",
                    },
                  }}
                >
                  {INVENTORY_STEP_LIST?.map((item, index) => {
                    return (
                      <Tab
                        key={"filter_" + index}
                        label={
                          item.label?.toLowerCase() +
                          ` - ${
                            businessProductsLoader || productCategoryLoader
                              ? "XX"
                              : getProductsCount(item.id)
                          }`
                        }
                        // label={item.label}
                        sx={{
                          fontSize: { xs: "11px", md: "13.5px" },
                          fontWeight: tabValue == index ? 500 : 500,
                          p: 0,
                          minHeight: "30px !important",
                          mr: { xs: "10px", md: "55px" },
                          color:
                            item.id === tabValue ? blueColor04 : greyColor40,
                          textTransform: "capitalize",
                        }}
                      />
                    );
                  })}
                </Tabs>
              ) : (
                <Box sx={{ ...styles.mobileTabs, ...scrollBox, pb: 0, pt: 0 }}>
                  {INVENTORY_STEP_LIST?.map((item, index) => {
                    return (
                      <MobileTab
                        key={"inventory_tab_" + index}
                        title={item.label?.toUpperCase()}
                        count={
                          businessProductsLoader || productCategoryLoader
                            ? "XX"
                            : getProductsCount(item.id)
                        }
                        isActive={tabValue == index}
                        onClick={() => handleChange("", item.id)}
                      />
                    );
                  })}
                </Box>
              )}
            </Box>

            {businessProductsLoader || productCategoryLoader ? (
              <ThreeDotLoader />
            ) : null}

            {!businessProductsLoader && (
              <>
                {getPriceUpdateProducts()?.length > 0 ? (
                  <>
                    <Box
                      display={"flex"}
                      justifyContent="space-between"
                      width={"100%"}
                      mt={2}
                    >
                      <Box>
                        <Typography
                          fontSize={13}
                          color={greyColor5}
                          display={{ xs: "none", md: "block" }}
                        >
                          You may update the pricing details and availability
                          dates for the products listed here{" "}
                        </Typography>

                        <Box display={"flex"} alignItems="center">
                          <Typography
                            fontSize={13}
                            fontWeight={500}
                            color={greyShade168}
                            sx={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => setInfoModal(true)}
                          >
                            How to update Stock?
                          </Typography>
                          <IconButton>
                            <InfoRoundedIcon
                              sx={{ fontSize: "18px", color: greyColor9 }}
                            />
                          </IconButton>
                        </Box>
                      </Box>

                      <Box>
                        {" "}
                        {matches ? (
                          <>
                            <Popper
                              open={inventoryErr}
                              anchorEl={anchorEl}
                              placement="bottom"
                              transition
                              sx={{ zIndex: 999 }}
                            >
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Box
                                    sx={{
                                      backgroundColor: whiteColor,
                                      p: "3px 20px",
                                      m: "10px 20px",
                                      borderRadius: "8px",
                                      boxShadow:
                                        "0 3px 4px rgb(229 233 240 / 22%)",
                                      border: logoImgborder1,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: blackShade05,
                                        m: "10px 0",
                                        whiteSpace: "pre-line",
                                      }}
                                    >
                                      Inventory not updated for any products.
                                    </Typography>
                                  </Box>
                                </Fade>
                              )}
                            </Popper>
                            <Button
                              sx={styles.addIconBtn}
                              variant="contained"
                              disableElevation
                              onClick={updateInventory}
                              startIcon={<InventoryRoundedIcon />}
                              disabled={updateLoader}
                            >
                              Update Inventory
                            </Button>
                          </>
                        ) : (
                          <Button
                            sx={styles.addIconBtn}
                            variant="contained"
                            disableElevation
                            onClick={updateInventory}
                            disabled={updateLoader}
                          >
                            Update Inventory
                          </Button>
                        )}{" "}
                      </Box>
                    </Box>

                    <Box m={{ md: "22px 0 18px", xs: "10px 0 10px" }}>
                      <Grid container>
                        <Grid item xs={12} md={7}>
                          <Typography
                            component="h4"
                            fontSize={15}
                            color={blackShade05}
                            fontWeight={600}
                            lineHeight={{ xs: "18px", md: "20px" }}
                            m="0px !important"
                            flex={0.8}
                          >
                            Price Update{" "}
                            <Box
                              component="span"
                              sx={{
                                fontSize: 13,
                                color: greyColor6,
                                fontWeight: 400,
                              }}
                            >
                              - {productsList?.length || 0}
                            </Box>
                          </Typography>

                          {isClearSearch && searchQuery ? (
                            <Box
                              display="flex"
                              alignItems="center"
                              gap="10px"
                              mt="4px"
                            >
                              <Typography
                                component="h4"
                                fontSize={15}
                                color={blackShade05}
                                fontWeight={500}
                                lineHeight="20px"
                                m="0px !important"
                              >
                                Search results for “
                                <Box
                                  component="span"
                                  sx={{
                                    fontSize: 15,
                                    color: blackShade05,
                                    fontWeight: 600,
                                  }}
                                >
                                  {searchQuery}
                                </Box>
                                ”
                              </Typography>

                              <Chip
                                label={searchQuery}
                                sx={{ fontSize: "12px", height: "28px" }}
                                onDelete={clearSearch}
                              />
                            </Box>
                          ) : null}
                        </Grid>

                        {matches && (
                          <Grid item xs={12} md={5}>
                            <Box m={{ xs: "12px 16px 0 16px", md: "0px" }}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6} p="0px !important">
                                  <Box m={{ xs: "16px 0", md: "0 16px" }}>
                                    <SelectWithLabel
                                      label="Product Category"
                                      value={selectedCategory}
                                      onValueChange={(value) => {
                                        handleCategoryFilter(value);
                                      }}
                                      menuList={categoryList}
                                      isDashboardInput
                                    />
                                  </Box>
                                </Grid>

                                <Grid item xs={12} md={6} p="0px !important">
                                  <SelectWithLabel
                                    label="Product Sub Category"
                                    value={selectedSubCat}
                                    onValueChange={(value) => {
                                      handleSubCategoryFilter(value);
                                    }}
                                    menuList={subCatList}
                                    isDashboardInput
                                    disabled={subCatList.length === 0}
                                  />
                                </Grid>
                                {/* <Grid item xs={12} md={4} p="0px !important">
                              <Box m={{ xs: "16px 0", md: "0 16px" }}>
                                <SearchBoxInput
                                  label="Search"
                                  placeholder="Search Product"
                                  value={searchQuery}
                                  onChange={(text) => {
                                    setSearchQuery(text);
                                  }}
                                  onSearchClick={handleSearch}
                                  isDashboardInput
                                />
                              </Box>
                            </Grid> */}
                              </Grid>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Box>

                    {matches && (
                      <Box sx={styles.bodySticky}>
                        <Grid
                          container
                          sx={{
                            // backgroundColor: tableHeaderColor,
                            backgroundColor: "#e8eaf2",
                            px: 2,
                            py: 1,
                            borderBottom: "1px solid #eaeaea",
                            display: { md: "flex", xs: "none" },
                            borderRadius: "12px 12px 0 0 ",
                          }}
                        >
                          {PU_COLUMN_HEADING.map((colHead, i) => (
                            <Grid
                              item
                              md={colHead.size}
                              key={i}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              {/* {colHead.label == "Product" ? <CheckBoxWithLabel /> : null} */}
                              <Typography
                                fontSize={13}
                                color={greyColor5}
                                fontWeight={500}
                                lineHeight={1.5}
                                textTransform="uppercase"
                              >
                                {colHead.label}
                              </Typography>
                              <Typography
                                fontSize={12}
                                color={greyShade65}
                                fontWeight={400}
                                lineHeight={1.5}
                                ml={1}
                                textTransform="uppercase"
                              >
                                {colHead.description}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    )}
                  </>
                ) : productsList?.length === 0 ? (
                  <Typography
                    component="h4"
                    fontSize={15}
                    color={blackShade05}
                    fontWeight={600}
                    lineHeight={{ xs: "18px", md: "20px" }}
                    m="10px 0 12px !important"
                    flex={0.8}
                  >
                    Price Update{" "}
                    <Box
                      component="span"
                      sx={{
                        fontSize: 13,
                        color: greyColor6,
                        fontWeight: 400,
                      }}
                    >
                      - 0
                    </Box>
                  </Typography>
                ) : null}
              </>
            )}
          </Box>

          {!matches && (
            <Box>
              {!businessProductsLoader && (
                <>
                  {getPriceUpdateProducts()?.length > 0 ? (
                    <>
                      <Box m="0px 0 18px">
                        <Grid container>
                          <Grid item xs={12} md={5}>
                            <Box m={{ xs: "0px 16px 0 16px", md: "0px" }}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6} p="0px !important">
                                  <Box m={{ xs: "16px 0", md: "0 16px" }}>
                                    <SelectWithLabel
                                      label="Product Category"
                                      value={selectedCategory}
                                      onValueChange={(value) => {
                                        handleCategoryFilter(value);
                                      }}
                                      menuList={categoryList}
                                      isDashboardInput
                                    />
                                  </Box>
                                </Grid>

                                <Grid item xs={12} md={6} p="0px !important">
                                  <SelectWithLabel
                                    label="Product Sub Category"
                                    value={selectedSubCat}
                                    onValueChange={(value) => {
                                      handleSubCategoryFilter(value);
                                    }}
                                    menuList={subCatList}
                                    isDashboardInput
                                    disabled={subCatList.length === 0}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  ) : null}
                </>
              )}
            </Box>
          )}

          {!businessProductsLoader && (
            <>
              {productsList?.length === 0 ? (
                <EmptyCard
                  msg={
                    selectedCategory !== "All" && selectedSubCat !== "All"
                      ? "No products matching this criteria are available for Price Update"
                      : "No active products are available for Price Update"
                  }
                  icon={<ContentPasteIcon sx={{ fontSize: "24px" }} />}
                />
              ) : (
                <Grid
                  container
                  spacing={{ md: 0, xs: 2 }}
                  sx={{
                    borderRadius: {
                      xs: " none",
                      md: "0 0 12px 12px",
                    },
                  }}
                >
                  {/* <Box
                    // my={1}
                    sx={{
                      overflow: "hidden",
                      borderRadius: "0 0 12px 12px",
                      border: "1px solid #eaeaea",
                    }}
                  > */}
                  {/* <Box> */}
                  {productsList?.map((data, i) => (
                    <Grid item md={12}>
                      <Box
                        // my={1}
                        sx={{
                          overflow: "hidden",
                          borderRadius: {
                            xs: "12px 12px",
                            md: "0 0 0 0 ",
                          },
                          border: "1px solid #eaeaea",
                        }}
                      >
                        <PriceUpdateRowCard
                          key={`Price_Update_${i}`}
                          img={data?.productPhoto?.[0]?.docURL || ""}
                          title={data?.productName || ""}
                          originalAmt={data?.productOriginalPrice || ""}
                          amt={data?.productSalePrice || ""}
                          alertQty={data?.alertQuantity || ""}
                          avlQty={data?.availableQuantity || ""}
                          minQty={data?.minOrderQuantity || ""}
                          maxQty={data?.maxOrderQuantity || ""}
                          currentStock={data?.currentStock}
                          startDate={moment(data?.commerceStartDate)?.format(
                            "YYYY-MM-DD"
                          )}
                          endDate={moment(data?.commerceEndDate)?.format(
                            "YYYY-MM-DD"
                          )}
                          last={productsList?.length - 1 === i}
                          errors={data?.errors}
                          isChecked={data?.itemChange}
                          onValueChange={(value) => {
                            let arr = [...productsList];
                            arr[i][value.type] = value.value;
                            if (value.type !== "itemChange")
                              arr[i].itemChange = true;
                            setProductsList(arr);
                          }}
                          gstRates={gstRates}
                          gst={data?.gstInfo?.gstPercentage}
                          gstCode={data?.gstInfo?.code}
                        />
                      </Box>
                    </Grid>
                  ))}
                  {/* </Box> */}
                  {/* </Box> */}
                </Grid>
              )}
            </>
          )}
        </Box>
      </DashboardLayout>

      <ModalCard
        open={errorModal.show}
        handleClose={() => {
          setErrorModal({ show: false, value: "" });
        }}
        width="27%"
      >
        <Box
          sx={{ backgroundColor: whiteColor, px: 3, py: 2.5, borderRadius: 2 }}
        >
          <Typography fontSize={16} fontWeight={600} color={blackColor}>
            Alert
          </Typography>

          <Typography fontSize={14} mt={2.5}>
            {errorMsg}
          </Typography>

          <Box display="flex" justifyContent={"flex-end"} mt={1.5} gap={2}>
            <Button
              sx={styles.addIconBtn}
              onClick={() => {
                updateInventory();
                setErrorModal({ show: false, value: "" });
              }}
            >
              Yes
            </Button>

            <Button
              sx={styles.fillBtn}
              onClick={() => {
                navigateToTab(errorModal?.value);
                // setTabValue(errorModal.value);
                // setProductsList([]);
                setErrorModal({ show: false, value: "" });
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </ModalCard>

      <ModalCard
        open={infoModal}
        handleClose={() => {
          setInfoModal(false);
        }}
        width="50%"
      >
        <Box
          sx={{ backgroundColor: whiteColor, px: 3, py: 2.5, borderRadius: 2 }}
        >
          <Box>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={greyShade168}
              mb={2}
            >
              How to update Stock?
            </Typography>
            <Box>
              <Typography
                fontSize={14}
                fontWeight={400}
                color={greyColor9}
                display="inline"
                lineHeight={1.5}
              >
                You can update pricing for multiple products and click on{" "}
                <Typography
                  fontSize={15}
                  fontWeight={600}
                  color={greyColor9}
                  display="inline"
                  lineHeight={1.5}
                >
                  ‘UPDATE INVENTORY’
                </Typography>{" "}
                button to save the details.{" "}
              </Typography>
            </Box>
          </Box>
        </Box>
      </ModalCard>
    </Box>
  );
}

export default PriceUpdate;

const styles = {
  container: {
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    pt:{xs:2, md:0}
  },

  bodySticky: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "20px" },
    p: { xs: "0px", md: "0 " },
    mx: { xs: "15px", md: "0px" },
    mt: { xs: "15px", md: "15px" },
    position: "-webkit-sticky",
    position: "sticky",
    top: "70px",
    backgroundColor: BGColor02,
    zIndex: 5,
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  statsCard: {
    width: { xs: "100%", md: "auto" },
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    gap: { xs: "20px", md: "35px" },
    backgroundColor: whiteColor,
    padding: { xs: "10px 16px", md: "8px 16px" },
    borderRadius: { xs: "8px", md: "12px" },
    border: `1px solid ${greyColor10}`,
    m: { xs: "12px 0 12px 0", md: "0" },
  },

  addIconBtn: {
    padding: { md: "3px 16px", xs: "3px 14px" },
    fontSize: { md: "13px", xs: "12px" },
    fontWeight: 600,
    color: {xs: mobilePrimary, md:blueColor04},
    backgroundColor: whiteColor,
    border: {xs:`1.5px solid ${mobilePrimary}`, md:`1.5px solid ${blueColor04}`},
    "&:hover": {
      color: whiteColor,
      backgroundColor: {xs: mobilePrimary, md:blueColor04},
    },
  },
  fillBtn: {
    backgroundColor: greyColor8,
    padding: "6px 22px",
    fontSize: "13px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + greyColor8,
    "&:hover": {
      color: greyColor8,
      backgroundColor: whiteColor,
    },
  },

  addIconBtnSmall: {
    backgroundColor: "transparent",
    fontSize: "13px",
    fontWeight: 600,
    color: greyColor8,
    backgroundColor: whiteColor,
    borderRadius: "3px",
    border: "1.5px solid " + greyColor8,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },

  mobileTabs: {
    width: { xs: "90vw", md: "calc(100% - 100px)" },
    display: "flex",
    alignItems: "center",
    // borderBottom: logoImgborder,
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
