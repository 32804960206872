import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import React, { useEffect, useState } from "react";
import {
  BGColor02,
  blackShade05,
  blueColor04,
  borderTextbox,
  descriptionColor,
  greyColor6,
  greyColor8,
  menuGradient,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ModalCard from "../../../components/ModalCard";

function PricingInputModal({
  label,
  placeholder,
  name,
  value,
  onChange,
  icon,
  setMoreProducts,
  maxLength,
  inputType,
  // isError,
  error,
  helperText,
  autoFocus,
  inputRef,
  onKeyUp,
  onBlur,
  disabled,
  isDashboardInput,
  clickDisabled,
  minValue,
  maxValue,
}) {
  const [modal, setModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [arr, setArr] = useState([
    {
      byFor: "",
      txnAmount: "",
      txnAmount2: "",
      location: "India",
    },
  ]);

  useEffect(() => {
    setArr(
      value?.length > 0
        ? value
        : [
            {
              byFor: "",
              txnAmount: "",
              txnAmount2: "",
              location: "India",
            },
          ]
    );
    return () => {};
  }, [value, modal]);

  const handleAddNewRow = (textInput, index) => {
    setModalError("");
    const fromAmt = Number(textInput?.txnAmount);
    const toAmt = Number(textInput?.txnAmount2);
    const deliveryPrice = Number(textInput?.byFor);

    if (
      textInput?.txnAmount === "" ||
      textInput?.txnAmount2 === "" ||
      textInput?.byFor === ""
    ) {
      setModalError("Please fill all the input fields");
      return;
    }

    // if (fromAmt < minValue || fromAmt > maxValue) {
    //   setModalError(
    //     `From Order Amount should not be greater than ${minValue} and less than ${maxValue}`
    //   );
    //   return;
    // }

    // if (toAmt < minValue || toAmt > maxValue) {
    //   setModalError(
    //     `To Order Amount should not be greater than ${minValue} and less than ${maxValue}`
    //   );
    //   return;
    // }

    if (toAmt <= fromAmt) {
      setModalError(
        "From Order Amount should not be greater than or equal to To Order Amount"
      );
      return;
    }

    console.log("Pricing_List:", arr);

    if (arr.length > 0) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (index !== i) {
          const currentObj = arr[i];

          const currentFromAmt = Number(currentObj.txnAmount);
          const currentToAmt = Number(currentObj.txnAmount2);

          if (
            (fromAmt >= currentFromAmt && fromAmt <= currentToAmt) ||
            (toAmt >= currentFromAmt && toAmt <= currentToAmt)
          ) {
            console.log("Range overlap found");
            setModalError("Range overlap found");
            return;
          }

          if (
            (currentFromAmt >= fromAmt && currentToAmt <= toAmt) ||
            (currentToAmt >= fromAmt && currentToAmt <= toAmt)
          ) {
            console.log("Range overlap found");
            setModalError("Range overlap found");
            return;
          }
        }
      }
    }

    console.log("No range overlap found, return true");

    if (fromAmt >= 0 && toAmt >= 0 && deliveryPrice >= 0) {
      setArr([
        ...arr,
        {
          byFor: "",
          // txnAmount: "",
          txnAmount: (toAmt + 0.01)?.toFixed(2),
          txnAmount2: "",
          location: "India",
        },
      ]);
      setModalError("");
    } else setModalError("Enter valid input");
  };

  const handleApply = () => {
    setModalError("");
    let errorMsg = "";

    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];

      errorMsg = "";

      const fromAmt = Number(item?.txnAmount);
      const toAmt = Number(item?.txnAmount2);
      const deliveryPrice = Number(item?.byFor);

      if (
        item?.txnAmount === "" ||
        item?.txnAmount2 === "" ||
        item?.byFor === ""
      ) {
        errorMsg = "Please fill all the input fields";
        setModalError(errorMsg);
        break;
      }

      // if (fromAmt < minValue || fromAmt > maxValue) {
      //   errorMsg = `From Order Amount should not be greater than ${minValue} and less than ${maxValue}`;
      //   setModalError(errorMsg);
      //   break;
      // }

      // if (toAmt < minValue || toAmt > maxValue) {
      //   errorMsg = `To Order Amount should not be greater than ${minValue} and less than ${maxValue}`;
      //   setModalError(errorMsg);
      //   break;
      // }

      if (fromAmt && toAmt > 0 && fromAmt >= toAmt) {
        errorMsg =
          "From Order Amount should not be greater than or equal to To Order Amount";
        setModalError(errorMsg);
        break;
      }

      for (let j = 0; j < arr.length; j++) {
        if (i !== j) {
          const newItem = arr[j];

          const currentFromAmt = Number(newItem.txnAmount);
          const currentToAmt = Number(newItem.txnAmount2);
          const currentDeliveryPrice = Number(newItem?.byFor);

          if (
            (fromAmt >= currentFromAmt && fromAmt <= currentToAmt) ||
            (toAmt >= currentFromAmt && toAmt <= currentToAmt)
          ) {
            console.log("Range overlap found");
            errorMsg = "Range overlap found";
            break;
          }
        }
      }

      if (errorMsg) {
        setModalError(errorMsg);
        break;
      }
    }

    if (!errorMsg) {
      setMoreProducts(arr);
      setModal(false);
    }
  };

  const handleOpenModal = () => {
    if (clickDisabled) return;
    setModal(true);
  };

  const onTxnAmountChange = (value, inputData, index) => {
    if (modalError) setModalError("Enter valid input");

    const amount = value?.replace(/[^0-9.]/g, "");
    const formattedAmount = amount?.replace(/^(\d+\.\d{0,2})\d*$/, "$1");

    let data = [...arr];
    data[index] = {
      ...data[index],
      txnAmount2: formattedAmount,
    };

    const nextIndex = index + 1;

    if (nextIndex < arr?.length) {
      console.log("BLUR_HITT", Number(inputData?.txnAmount2));
      if (inputData?.byFor && inputData?.txnAmount && inputData?.txnAmount2) {
        const newTxnAmt2 = Number(formattedAmount) + 0.01;
        data[nextIndex] = {
          ...data[nextIndex],
          txnAmount: newTxnAmt2.toFixed(2),
        };
      }
    }

    setArr(data);
    setModalError("");
  };

  return (
    <Box mb="15px !important">
      <Box display="flex" alignItems="center" gap="15px">
        <TextField
          variant="standard"
          required={true}
          fullWidth
          name={name}
          disabled={disabled}
          value={value
            ?.map((item) =>
              item?.txnAmount && item?.txnAmount2 && item?.byFor
                ? `₹${item?.txnAmount} - ₹${item?.txnAmount2} Delivery Price ₹${item?.byFor}`
                : ""
            )
            ?.join(", ")}
          onBlur={onBlur}
          autoFocus={autoFocus}
          onChange={onChange}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          onClick={handleOpenModal}
          inputRef={inputRef}
          sx={{
            fontSize: 14.5,
            borderTop: "none",
            borderBottom: borderTextbox,
            borderLeft: "none",
            borderRight: "none",
            borderRadius: "0px",
            backgroundColor: whiteColor,
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                {icon}
              </InputAdornment>
            ),
            style: {
              padding: isDashboardInput ? "7px 12px" : "4px 8px",
              color: grey,
              fontSize: 14.5,
              fontWeight: 500,
            },
            disableUnderline: true,
          }}
          inputProps={{ maxLength: maxLength }}
          type={inputType}
        />

        <IconButton onClick={handleOpenModal} sx={styles.addBtn}>
          <AddRoundedIcon
            sx={{ fontSize: { md: "28px", xs: "20px" }, color: whiteColor }}
          />
        </IconButton>
      </Box>

      {error ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={12}
          sx={{ mt: "4px !important", textAlign: "start !important" }}
          style={{ color: "red" }}
        >
          {error}
        </Typography>
      ) : null}

      {helperText ? (
        <Typography
          variant="body1"
          component="p"
          fontSize={11}
          color={descriptionColor}
        >
          {helperText}
        </Typography>
      ) : null}

      <ModalCard
        open={modal}
        handleClose={() => {
          setArr([]);
          setModal(false);
        }}
        width="50%"
      >
        <Box sx={styles.card}>
          <Typography
            variant="h3"
            fontSize={18}
            fontWeight={600}
            lineHeight="34px"
            color={blackShade05}
            textAlign="center"
            p="15px 50px"
          >
            Fulfillment by Business
          </Typography>

          <Box sx={styles.cardBody}>
            <Box mt={1}>
              <Grid
                container
                display={"flex"}
                alignItems="center"
                justifyContent={"flex-start"}
                columnSpacing={{ md: 2, xs: 1 }}
              >
                <Grid item md={3.5} xs={3.5}>
                  <Typography
                    variant="body1"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyColor6}
                    fontWeight={{ md: 500, xs: 400 }}
                    mb={{ md: "8px !important", xs: 0 }}
                    sx={{ display: "inline" }}
                  >
                    From Order Amount
                  </Typography>
                </Grid>

                <Grid item md={3.5} xs={3.5}>
                  <Typography
                    variant="body1"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyColor6}
                    fontWeight={{ md: 500, xs: 400 }}
                    mb={{ md: "8px !important", xs: 0 }}
                    sx={{ display: "inline" }}
                  >
                    To Order Amount
                  </Typography>
                </Grid>

                <Grid item md={3.5} xs={3.5}>
                  <Typography
                    variant="body1"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyColor6}
                    fontWeight={{ md: 500, xs: 400 }}
                    mb={{ md: "8px !important", xs: 0 }}
                    sx={{ display: "inline" }}
                  >
                    Delivery Price
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {arr.map((textInput, index) => (
              <Grid
                container
                display={"flex"}
                alignItems="center"
                justifyContent={"flex-start"}
                columnSpacing={{ xs: 1, md: 2 }}
              >
                <Grid item xs={3.5} md={3.5}>
                  <TextField
                    variant="standard"
                    fullWidth
                    disabled={index !== 0}
                    startIcon="₹"
                    value={textInput?.txnAmount}
                    onChange={({ target }) => {
                      if (modalError) setModalError("Enter valid input");
                      let data = [...arr];
                      // const amount = target?.value?.replace(/[^0-9.]/g, "");
                      const amount = target?.value?.replace(/[^0-9.]/g, "");
                      const formattedAmount = amount?.replace(
                        /^(\d+\.\d{0,2})\d*$/,
                        "$1"
                      );
                      data[index] = {
                        ...data[index],
                        txnAmount: formattedAmount,
                      };
                      setArr(data);
                      setModalError("");
                    }}
                    sx={{
                      mt: { md: 1, xs: 0 },
                      fontSize: { md: 14.5, xs: 13 },
                      borderTop: isDashboardInput ? borderTextbox : "none",
                      borderBottom: borderTextbox,
                      borderLeft: isDashboardInput ? borderTextbox : "none",
                      borderRight: isDashboardInput ? borderTextbox : "none",
                      borderRadius: isDashboardInput ? "6px" : "0px",
                      backgroundColor: whiteColor,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            color: `${blackShade05} !important`,
                            fontSize: 13.5,
                            fontWeight: 500,
                            cursor: "pointer",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: 13,
                              fontWeight: 500,
                              color: blackShade05,
                            }}
                          >
                            ₹
                          </Typography>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ cursor: "pointer" }}
                        >
                          {icon}
                        </InputAdornment>
                      ),
                      style: {
                        padding: isDashboardInput ? "7px 12px" : "4px 8px",
                        color: grey,
                        fontSize: 14.5,
                        fontWeight: 500,
                      },
                      disableUnderline: true,
                    }}
                    inputProps={{ maxLength: maxLength }}
                    type={inputType}
                  />
                </Grid>

                <Grid item xs={3.5} md={3.5}>
                  <TextField
                    variant="standard"
                    fullWidth
                    value={textInput?.txnAmount2 || ""}
                    // onChange={({ target }) => {

                    //   if (modalError) setModalError("Enter valid input");

                    //   // const amount = target?.value?.replace(/[^0-9]/g, "");
                    //   const amount = target?.value?.replace(/[^0-9.]/g, "");
                    //   const formattedAmount = amount?.replace(
                    //     /^(\d+\.\d{0,2})\d*$/,
                    //     "$1"
                    //   );
                    //   let data = [...arr];
                    //   data[index] = {
                    //     ...data[index],
                    //     txnAmount2: formattedAmount,
                    //   };
                    //   setArr(data);
                    //   setModalError("");
                    // }}

                    onChange={({ target }) => {
                      onTxnAmountChange(target?.value, textInput, index);
                    }}
                    sx={{
                      mt: { md: 1, xs: 0 },
                      fontSize: { md: 14.5, xs: 13 },
                      borderTop: isDashboardInput ? borderTextbox : "none",
                      borderBottom: borderTextbox,
                      borderLeft: isDashboardInput ? borderTextbox : "none",
                      borderRight: isDashboardInput ? borderTextbox : "none",
                      borderRadius: isDashboardInput ? "6px" : "0px",
                      backgroundColor: whiteColor,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            color: `${blackShade05} !important`,
                            fontSize: 13.5,
                            fontWeight: 500,
                            cursor: "pointer",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: 13,
                              fontWeight: 500,
                              color: blackShade05,
                            }}
                          >
                            ₹
                          </Typography>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ cursor: "pointer" }}
                        >
                          {icon}
                        </InputAdornment>
                      ),
                      style: {
                        padding: isDashboardInput ? "7px 12px" : "4px 8px",
                        color: grey,
                        fontSize: 14.5,
                        fontWeight: 500,
                      },
                      disableUnderline: true,
                    }}
                    inputProps={{ maxLength: maxLength }}
                    type={inputType}
                  />
                </Grid>

                <Grid item xs={3.2} md={3.2}>
                  <TextField
                    variant="standard"
                    fullWidth
                    value={textInput?.byFor || ""}
                    onChange={({ target }) => {
                      if (modalError) setModalError("Enter valid input");

                      // const amount = target?.value?.replace(/[^0-9]/g, "");
                      const amount = target?.value?.replace(/[^0-9.]/g, "");
                      const formattedAmount = amount?.replace(
                        /^(\d+\.\d{0,2})\d*$/,
                        "$1"
                      );
                      let data = [...arr];
                      data[index] = {
                        ...data[index],
                        byFor: formattedAmount,
                      };
                      setArr(data);
                      setModalError("");
                    }}
                    sx={{
                      mt: { md: 1, xs: 0 },
                      fontSize: { md: 14.5, xs: 13 },
                      borderTop: isDashboardInput ? borderTextbox : "none",
                      borderBottom: borderTextbox,
                      borderLeft: isDashboardInput ? borderTextbox : "none",
                      borderRight: isDashboardInput ? borderTextbox : "none",
                      borderRadius: isDashboardInput ? "6px" : "0px",
                      backgroundColor: whiteColor,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            color: `${blackShade05} !important`,
                            fontSize: 13.5,
                            fontWeight: 500,
                            cursor: "pointer",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: 13,
                              fontWeight: 500,
                              color: blackShade05,
                            }}
                          >
                            ₹
                          </Typography>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ cursor: "pointer" }}
                        >
                          {icon}
                        </InputAdornment>
                      ),
                      style: {
                        padding: isDashboardInput ? "7px 12px" : "4px 8px",
                        color: grey,
                        fontSize: 14.5,
                        fontWeight: 500,
                      },
                      disableUnderline: true,
                    }}
                    inputProps={{ maxLength: maxLength }}
                    type={inputType}
                  />
                </Grid>

                <Grid item xs={0.7} md={0.7}>
                  <IconButton
                    onClick={() => {
                      if (index === 0) return;
                      let data = [...arr];
                      data.splice(index, 1);
                      setArr(data);
                    }}
                    sx={{ opacity: index === 0 ? 0.3 : 1 }}
                  >
                    <DeleteRoundedIcon sx={{ fontSize: { md: 26, xs: 20 } }} />
                  </IconButton>
                </Grid>

                {arr?.length < 10 ? (
                  index == arr?.length - 1 ? (
                    <Grid item xs={0.4} md={0.5}>
                      <IconButton
                        onClick={() => handleAddNewRow(textInput, index)}
                      >
                        <AddRoundedIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                  ) : null
                ) : null}
              </Grid>
            ))}

            {modalError ? (
              <Typography
                variant="subtitle1"
                component="p"
                fontSize={12}
                sx={{ mt: "4px !important", textAlign: "start !important" }}
                style={{ color: "red" }}
              >
                {modalError}
              </Typography>
            ) : null}

            <Typography
              variant="subtitle1"
              component="p"
              fontSize={{ md: 13, xs: 11.5 }}
              sx={{ mt: "12px !important", textAlign: "start !important" }}
              style={{ color: greyColor6 }}
            >
              You can add upto 10 rows
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p="15px"
          >
            <Button
              sx={[styles.actionBtn, styles.cancelBtn]}
              onClick={() => {
                setArr([""]);
                setModal(false);
              }}
            >
              CANCEL
            </Button>

            <Button
              sx={[styles.actionBtn, styles.proceedBtn]}
              onClick={handleApply}
            >
              APPLY
            </Button>
          </Box>
        </Box>
      </ModalCard>
    </Box>
  );
}

export default PricingInputModal;

const styles = {
  card: {
    backgroundColor: BGColor02,
    borderRadius: "15px",
  },

  cardBody: {
    maxHeight: "80vh",
    backgroundColor: whiteColor,
    p: "12px",
    overflow: "auto",
  },

  actionBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    color: greyColor8,
    border: `1px solid ${greyColor8}`,
    p: "4px 35px",
  },

  cancelBtn: {
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },

  proceedBtn: {
    color: whiteColor,
    background: menuGradient,
    p: "5px 35px",
    border: "none",

    "&:hover": {
      color: greyColor8,
      background: whiteColor,
      border: `1px solid ${greyColor8}`,
    },
  },

  btn: (bgColor, textcolor) => ({
    background: bgColor,
    padding: { xs: "3px 24px", md: "4px 45px" },
    fontSize: { xs: "12px", md: "14px" },
    color: textcolor,
    borderRadius: "4px",
    fontWeight: 500,
    border: "1px solid " + bgColor,
  }),

  addBtn: {
    width: { md: "40px", xs: "30px" },
    height: { md: "40px", xs: "30px" },
    borderRadius: "3px",
    backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    "&:hover": {
      backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    },
  },
};
