import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blueColor04,
  greyColor8,
  whiteColor,
} from "../../../../configs/styles/muiThemes";
import FormInputBox from "../../../../components/Input/FormInputBox";
import FilePickerInput from "../../../../components/Input/FilePickerInput";
import FilePickerWithoutCrop from "../../../../components/Input/FilePickerWithoutCrop";

function TrackingInfoCard({
  trackingInfoData,
  onValueChange,
  handleShipmentInfo,
  loader,
}) {
  return (
    <Box>
      <Typography
        fontSize={14}
        fontWeight={600}
        color={blackShade05}
        mt="4px"
        mb="10px"
        lineHeight={"20px"}
      >
        Tracking Info
      </Typography>
      <Box width={"150px"} my={2}>
        <FilePickerWithoutCrop
          height={"115px"}
          placeholder={"Add Tracking Slip"}
          image={trackingInfoData?.img}
          setImage={(data) => {
            console.log(data);
            onValueChange("img", data);
          }}
          onDelete={(data) => onValueChange("img", data)}
        />
      </Box>

      <Grid container spacing={4}>
        <Grid item md={3} xs={12}>
          <FormInputBox
            label={"Shipment Tracking Number"}
            value={trackingInfoData?.carrierTrackId}
            onChange={({ target }) => {
              onValueChange("carrierTrackId", target.value);
            }}
            error={trackingInfoData?.errors?.carrierTrackId}
            required
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormInputBox
            label={"Courier Name"}
            value={trackingInfoData?.carrierName}
            onChange={({ target }) => {
              onValueChange("carrierName", target.value);
            }}
            error={trackingInfoData?.errors?.carrierName}
            required
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormInputBox
            label={"Courier URL"}
            value={trackingInfoData?.carrierURL}
            onChange={({ target }) => {
              onValueChange("carrierURL", target.value);
            }}
            error={trackingInfoData?.errors?.carrierURL}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormInputBox
            label={"Notes"}
            value={trackingInfoData?.notes}
            onChange={({ target }) => {
              onValueChange("notes", target.value);
            }}
            error={trackingInfoData?.errors?.notes}
          />
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        justifyContent={{ md: "flex-end", xs: "center" }}
        mt={3}
      >
        <Button
          sx={styles.actionBtn}
          onClick={handleShipmentInfo}
          disabled={loader}
        >
          Send Shipping Info
        </Button>
      </Box>
    </Box>
  );
}

export default TrackingInfoCard;

const styles = {
  actionBtn: {
    padding: "5px 24px",
    fontSize: "14px",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: blueColor04,
    border: "1px solid " + blueColor04,
    "&:hover": { color: blueColor04, backgroundColor: whiteColor },
  },
};
