import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackShade05,
  blueColor04,
  border25,
  lightBlueColor03,
  whiteColor,
} from "../../configs/styles/muiThemes";
import ChooseBusinessCard from "../../components/Cards/ChooseBusinessCard";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

function MultiBranchBusinessSection({
  businessInfo,
  allSubBranchList,
  onBusinessSelected,
}) {
  const [branchInfoList, setBranchInfoList] = useState([]);

  const prepareSubBranchList = () => {
    const branchInfo = businessInfo?.branchInfo;

    if (branchInfo?.length > 0) {
      const branchList = [];

      branchInfo?.forEach((branch) => {
        const branchData = allSubBranchList?.find(
          (item) => item?.businessid === branch?.branchId
        );

        if (branchData) {
          branchList.push(branchData);
        }
      });

      console.log("CHOOSE_BUSINESS___branchList", branchList);
      setBranchInfoList(branchList);
    }
  };

  useEffect(() => {
    prepareSubBranchList();
    return () => {};
  }, [allSubBranchList]);

  return (
    <Stack mb={1} sx={styles.card} gap={2}>
      <Stack gap={1}>
        <Typography
          component="h4"
          fontSize={{ xs: 14, md: 14 }}
          color={blackShade05}
          fontWeight={600}
          lineHeight={1.5}
        >
          Head Quarters
        </Typography>
        
        <Grid container spacing={{ md: 2, xs: 1 }}>
          <Grid item md={3} xs={12} key={"business_"}>
            <ChooseBusinessCard
              businessName={businessInfo?.name}
              type="Master Branch"
              // image={"https://storage.googleapis.com/bo3151920215/business/5ba77258a15d4fcfac353118ce96373e/logo.jpeg"}
              image={businessInfo?.url}
              onClick={() => {
                onBusinessSelected(businessInfo);
              }}
              status={"active"}
            />
          </Grid>
        </Grid>
      </Stack>

      {branchInfoList?.length > 0 && (
        <Stack gap={1}>
          <Typography
            component="h4"
            fontSize={{ xs: 14, md: 14 }}
            color={blackShade05}
            fontWeight={600}
            lineHeight={1.5}
          >
            Branches
          </Typography>
          <Grid container spacing={{ md: 2, xs: 1 }}>
            {/* <Grid item md={3} xs={12} key={"business_"}>
            <AddBtn />
          </Grid> */}

            {branchInfoList?.map((branch, index) => (
              <Grid item md={3} xs={12} key={"branch_" + index}>
                <ChooseBusinessCard
                  businessName={branch?.name}
                  // city={branch?.city}
                  type={branch?.city}
                  image={branch?.url}
                  onClick={() => {
                    onBusinessSelected(branch);
                  }}
                  status={branch?.status}
                />
              </Grid>
            ))}

            {/* <Grid item md={3} xs={12} key={"business_"}>
            <ChooseBusinessCard
              businessName={"Happilo - Chennai"}
              type="Branch"
              image={
                "https://storage.googleapis.com/bo3151920215/business/5ba77258a15d4fcfac353118ce96373e/logo.jpeg"
              }
              onClick={() => {}}
              status={"active"}
            />
          </Grid> */}
          </Grid>
        </Stack>
      )}
    </Stack>
  );
}

const AddBtn = ({ disabled, onAddNewBranch }) => {
  return (
    <Box
      onClick={!disabled && onAddNewBranch}
      sx={{
        opacity: disabled ? 0.5 : 1,
        // background: lightBlueColor03,
        border: "1px solid" + blueColor04,
        height: "100%",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        cursor: disabled ? "default" : "pointer",
      }}
    >
      <Box
        sx={{
          width: "35px",
          height: "35px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: blueColor04,
          border: "2px solid " + whiteColor,
          borderRadius: "50%",
        }}
      >
        <AddRoundedIcon sx={{ color: whiteColor, fontSize: 19 }} />
      </Box>
      <Typography
        variant="body1"
        color={blueColor04}
        fontSize={13}
        lineHeight="18px"
        fontWeight={600}
      >
        ADD A NEW BRANCH
      </Typography>
    </Box>
  );
};

export default MultiBranchBusinessSection;
const styles = {
  card: {
    backgroundColor: whiteColor,
    border: border25,
    p: "12px",
    borderRadius: "12px",
  },
};
