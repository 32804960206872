import { Box, Switch, TextField, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import ErrorToolTipIcon from "./ErrorToolTipIcon";
import {
  blackColor,
  borderTextbox,
  copyBtnColor,
  greenColor02,
  greyColor8,
  outOfStockColor,
  whiteColor,
} from "../../configs/styles/muiThemes";

const TableCellSwitchBtn = ({
  value,
  onValueChange,
  placeholder,
  rounded,
  errorMsg,
  disabled,
  minWidth,
}) => {
  return (
    <Box position="relative" sx={{ minWidth: minWidth || "80px" }}>
      {errorMsg && (
        <Box sx={{ position: "absolute", right: -14, top: -12, zIndex: 1 }}>
          <ErrorToolTipIcon errorMsg={errorMsg} />
        </Box>
      )}

      <Box
        sx={{
          backgroundColor: whiteColor,
          mt:1.2,
          ml:1
        }}
      >
        <StyledSwitch
          sx={{ ml: 1, color: "red" }}
          checked={value}
          onChange={() => {
            onValueChange(!value);
          }}
        />
      </Box>
    </Box>
  );
};

export default TableCellSwitchBtn;

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 35,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(8px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: copyBtnColor,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : outOfStockColor,
    boxSizing: "border-box",
  },
}));

const styles = {
  inputBox: (color) => ({
    borderBottom: borderTextbox,
    borderRadius: "4px",
    fontSize: "13px",
    color: "#777",
    background: color,
    padding: { md: "3px 12px", xs: "3px 10px" },
    width: "100%",
    "& .MuiInputBase-root.Mui-disabled": {
      color: blackColor + " !important",
    },
  }),
};
