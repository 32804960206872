import React from "react";
import {
  blackShade19,
  cardBoder,
  greyColor8,
  logoImgborder,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { Box, Radio, Typography } from "@mui/material";
import { getStatusBGColor, getStatusTextColor } from "../../../utils/data";

const SubBranchCard = ({ isSelected, logo, businessName, status, onClick }) => {
  return (
    <Box
      sx={{
        ...styles.subBranchCard,
        opacity: status?.toLowerCase() !== "active" ? 0.7 : 1,
      }}
    >
      <Radio
        size="large"
        checked={isSelected}
        sx={{
          "&.Mui-checked": {
            color: greyColor8,
          },
        }}
        onChange={() => {
          onClick();
        }}
        disabled={status?.toLowerCase() !== "active"}
      />

      <Box sx={styles.logoImg} component="img" src={logo} />

      <Typography fontSize={13.5} fontWeight={600} color={blackShade19} ml={2}>
        {businessName}
      </Typography>

      {status?.toLowerCase() !== "active" && (
        <Box
          sx={{
            ...styles.statusTag,
            backgroundColor: getStatusBGColor(status),
          }}
        >
          <Typography
            variant="body1"
            fontSize={10.5}
            fontWeight={500}
            lineHeight="20px"
            color={getStatusTextColor(status)}
            sx={{ textTransform: "uppercase" }}
          >
            {status}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SubBranchCard;

const styles = {
  subBranchCard: {
    display: "flex",
    alignItems: "center",
    p: "10px 8px",
    bgcolor: whiteColor,
    border: cardBoder,
    borderRadius: "8px",
    position: "relative",
  },

  logoImg: {
    width: "45px",
    height: "45px",
    border: logoImgborder,
    borderRadius: "5px",
    objectFit: "contain",
  },

  statusTag: {
    position: "absolute",
    right: 0,
    bottom: 0,
    borderRadius: "10px 0 0 0",
    padding: { md: "1.5px 14px", xs: "0px 15px" },
  },
};
