import { GOOGLE_MAPS_API_KEY, LATITUDE, LONGITUDE } from "../configs/Constants";

// Get User Current Lantitude and longitute
export const getUserLocation = () => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log("Position:", position);
        localStorage.setItem("lat", position.coords.latitude);
        localStorage.setItem("long", position.coords.longitude);
      },
      function () {
        console.log("HIt else:");
        localStorage.setItem("lat", LATITUDE);
        localStorage.setItem("long", LONGITUDE);
      }
    );
  } else {
    console.log("HIt if failed:");
    localStorage.setItem("lat", LATITUDE);
    localStorage.setItem("long", LONGITUDE);
  }
};

// Get Address From LATITUDE and LONGITUDE
export const fetchAddressFromLatLng = async (lat, lng) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`
    );
    const data = await response.json();
    if (data.status === "OK") {
      const addressComponents = data.results[0].address_components;
      const fullAddress = data.results[0].formatted_address;
      let postalCode = "";
      let state = "";
      let city = "";
      let locality = "";
      let sublocality = "";

      addressComponents.forEach((component) => {
        if (component.types.includes("postal_code")) {
          postalCode = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
        }
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
        if (component.types.includes("sublocality_level_1")) {
          locality = component.long_name;
        }
        if (component.types.includes("sublocality_level_2")) {
          sublocality = component.long_name;
        }
      });

      let address = fullAddress
        ?.replace(`${postalCode},`, "")
        ?.replace(`${city},`, "")
        ?.replace(`${state}`, "")
        .replace("India", "");

      // let address = "";
      // addressComponents.forEach((component) => {
      //   if (
      //     !component.types.includes("locality") &&
      //     !component.types.includes("administrative_area_level_1") &&
      //     !component.types.includes("country")
      //   ) {
      //     address += component.long_name + ", ";
      //   }
      // });
      // address = address.slice(0, -2);

      console.log("lat, lng:", lat, ", ", lng);
      console.log("Address:", address);
      console.log("fullAddress:", fullAddress);
      console.log("Postal Code:", postalCode);
      console.log("State:", state);
      console.log("City:", city);
      console.log("Locality:", locality);
      console.log("SubLocality:", sublocality);
      return {
        postalCode,
        state,
        city,
        address,
        fullAddress,
        lat,
        lng,
        locality,
        sublocality,
      };
    } else {
      console.error("Geocoding failed:", data.status);
      return null;
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

export const getAddressInfoFromLatLong = async () => {
  const lat = localStorage.getItem("lat");
  const long = localStorage.getItem("long");

  if ((lat, long)) {
    const addressInfo = await fetchAddressFromLatLng(lat, long);
    return addressInfo;
  }
};
