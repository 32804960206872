import { Box, Button, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  greyColor8,
  whiteColor,
} from "../../../configs/styles/muiThemes";

function DashboardEmptyCard({ icon, text, hideBtn, btnText, onClick, noPadding }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={{md:noPadding ? 0.3 : 3,xs:1}}
    >
      <Box sx={styles.iconCircle}>{icon}</Box>

      <Typography fontSize={14} fontWeight={500} color={blackShade05}>
        {text}
      </Typography>

      {!hideBtn && (
        <Button sx={styles.btnStyles} onClick={onClick}>
          {btnText}
        </Button>
      )}
    </Box>
  );
}

export default DashboardEmptyCard;

const styles = {
  iconCircle: {
    width: "50px",
    height: "50px",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: {md:"rgb(236 237 239)",xs:whiteColor},
    mb: {md:"10px",xs:0},
  },

  btnStyles: {
    padding: "4px 22px",
    fontSize: "13px",
    fontWeight: 600,
    color: greyColor8,
    backgroundColor: whiteColor,
    border: "1.5px solid " + greyColor8,
    marginTop: "15px",
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },
};
