import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blueColor02,
  greenStatusColor,
  greyColor6,
  logoImgborder1,
  orangeStatusColor,
  whiteColor,
  BGColor03,
  deleteBtbColor,
  inactiveTextColor,
  shareBgColor,
  blueColor04,
  logoImgborder,
} from "../../../configs/styles/muiThemes";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import moment from "moment";
import ProfileCharAvatar from "../../../components/ProfileCharAvatar";
import { useSelector } from "react-redux";
import { addThousandsSeparator } from "../../../utils/helper";

const PreferredDiscountCard = ({
  customerName,
  customerNumber,
  totalDiscount,
  startDate,
  endDate,
  profileImage,
  percentage,
  minpurchase,
  statusText,
  onDeleteClick,
}) => {
  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  const onShareClick = () => {
    const webURL = sessionStorage.getItem("costboWEBURL");
    const message = `Special discount just for you from ${
      activeBusinessInfo?.name
    }  \n\n GET ${percentage ? `${percentage}% OFF on all products` : ""}${
      totalDiscount ? `₹ ${totalDiscount} OFF ` : ""
    } on minimum purchase of ₹ ${addThousandsSeparator(
      minpurchase
    )} \n Use below link for instant discount! \n https://shop.costbo.com/${webURL}`;
    const msg = encodeURIComponent(message);
    window.open(
      `https://api.whatsapp.com/send/?phone=${customerNumber}&text=${msg}`,
      "_blank"
    );
  };

  return (
    <Box sx={{ ...styles.customerDiscountCard }}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        p="15px 10px"
        borderBottom="1px solid #dadae1"
        gap="15px"
        position="relative"
      >
        <Box>
          <Box
            sx={{
              // width: 52,
              // height: 52,
              // position: "relative",
              bottom:130,
              right:"42%",
               position:"absolute",
              display: "block",
              alignItems: "center",
              textlign: "center",
              // padding: "25px 10px 5px",
            }}
          >
            {profileImage ? (
              <Avatar
                alt="Remy Sharp"
                src={profileImage}
                sx={{
                  width: 52,
                  height: 52,
                  border: "2px solid #e2e5ec",
                  marginRight: 0,
                }}
              ></Avatar>
            ) : (
              <ProfileCharAvatar
                username={customerName || "Guest"}
                border
                size="50px"
              />
            )}
            <Box
              sx={{
                ...styles.onlineStatus,
                backgroundColor:
                  statusText === "active"
                    ? greenStatusColor
                    : statusText === "expired"
                    ? orangeStatusColor
                    : statusText === "claimed"
                    ? inactiveTextColor
                    : "",
              }}
            ></Box>
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Typography sx={styles.customerName}>
            {customerName || "Guest"}
          </Typography>
          <Typography sx={styles.discountTitle}>{customerNumber}</Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography sx={{ ...styles.discountTitle }}>Valid From</Typography>
            <Typography sx={styles.discountEarned}>{startDate}</Typography>
          </Box>

          <Box>
            <Typography sx={{ ...styles.discountTitle }}>Valid End</Typography>
            <Typography sx={styles.discountEarned}>{endDate}</Typography>
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        p="10px 10px 10px"
        border="10px 10px 0px"
      >
        {totalDiscount ? (
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" sx={styles.discountTitle}>
              Discount Value
            </Typography>
            <Typography sx={styles.discountEarned}>
              ₹ {totalDiscount}
            </Typography>
          </Box>
        ) : null}

        {percentage ? (
          <Box display="flex" justifyContent="space-between">
            <Typography variant="p" sx={styles.discountTitle}>
              Percentage
            </Typography>

            <Typography sx={{ ...styles.discountEarned }}>
              {percentage} %
            </Typography>
          </Box>
        ) : null}
        {/* {(percentage === 0) && totalDiscount ===0 ? (
          <Box display="flex" justifyContent="space-between">
            <Typography variant="p" sx={styles.discountTitle}>
              Percentage
            </Typography>

            <Typography sx={{ ...styles.discountEarned }}>
              {percentage} %
            </Typography>
          </Box>
        ) : null} */}

        <Box display="flex" justifyContent="space-between">
          <Typography variant="p" sx={styles.discountTitle}>
            Min Purchase Amount
          </Typography>

          <Typography sx={{ ...styles.discountEarned }}>
            ₹ {minpurchase}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          backgroundColor: BGColor03,
          justifyContent: "center",
          padding: "4px 0",
          position: "relative",
        }}
      >
        <Typography
          color={
            statusText === "active"
              ? greenStatusColor
              : statusText === "expired"
              ? orangeStatusColor
              : statusText === "claimed"
              ? inactiveTextColor
              : ""
          }
          fontSize={12.5}
          fontWeight={500}
          textAlign="center"
          textTransform="uppercase"
          marginRight="0px"
          marginBottom="3px"
        >
          {statusText}
        </Typography>
        {statusText === "active" ? (
          <IconButton
            sx={{
              color: deleteBtbColor,
              fontSize: "15px",
              padding: "4px",
              border: "1px solid #959ca9",
              borderRadius: "4px",
              position: "absolute",
              top: "4px",
              right: "12px",
            }}
            onClick={onDeleteClick}
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          ""
        )}
      </Box>
      {statusText === "active" ? (
        <Box sx={styles.shareContainer} onClick={onShareClick}>
          <ShareRoundedIcon sx={{ fontSize: "18px" }} />
        </Box>
      ) : null}
    </Box>
  );
};

export default PreferredDiscountCard;

const styles = {
  customerDiscountCard: {
    backgroundColor: whiteColor,
    baxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    border: logoImgborder,
    position: "relative",
    borderRadius: "10px",
  },
  customerName: {
    color: blackShade05,
    textAlign: "left",
    fontSize: "15px",
    fontWeight: "600",
  },
  availableCashBack: {
    color: blueColor04,
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "600",
  },

  discountTitle: {
    color: greyColor6,
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "500",
  },

  discountEarned: {
    color: blackShade05,
    textAlign: "left",
    fontSize: "12px",
    fontWeight: "600",
  },
  onlineStatus: {
    width: "13px",
    height: "13px",
    borderRadius: "50%",
    right: "9px",
    bottom: "3%",
    zIndex: 2,
    position: "absolute",
    border: "2px solid #fff",
  },
  shareContainer: {
    position: "absolute",
    width: "32px",
    height: "32px",
    top: "8px",
    right: "8px",
    backgroundColor: shareBgColor,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
};
