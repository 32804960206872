import { getBusinessData } from "../../service/api_calls/grievance/grievanceApi";

export const prepareCopyFranchiseProductPostData = (data) => {
  const {
    mainBranchBusinessId,
    subBranchBusienssId,
    notSyncedProducts,
    latitude,
    longitude,
  } = data;

  const mainBrachProductId = [];

  notSyncedProducts?.forEach((item) => {
    if (item?.isSelected) {
      mainBrachProductId.push(item?.productId);
    }
  });

  const postData = {
    mainBranchBusinessId,
    subBranchBusienssId,
    mainBrachProductId,
    latitude,
    longitude,
  };

  return postData;
};

export const prepareUpdateFranchiseProductPostData = (data) => {
  const {
    mainBranchBusinessId,
    subBranchBusienssId,
    syncedProducts,
    latitude,
    longitude,
  } = data;

  const postDataList = []
  const mainBrachProductId = [];

  syncedProducts?.forEach((item) => {
    if (item?.isSelected) {
      const postData = {
        mainBranchBusinessId,
        masterProductId: item?.productId,
        childProductIds: [`${item?.childProductId}`],
      };

      postDataList.push(postData);
    }
  });

  // const postData = {
  //   mainBranchBusinessId,
  //   masterProductId: selectedProductInfo?.productId,
  //   childProductIds: [`${branchData?.branchProductId}`],
  // };

  // const postData = {
  //   mainBranchBusinessId,
  //   subBranchBusienssId,
  //   mainBrachProductId,
  //   latitude,
  //   longitude,
  // };

  return postDataList;
};


export const getSubBranchBusinessDetails = async (branchInfo) => {
  let paramsData = "";

  branchInfo.forEach((element, index) => {
    if (index === branchInfo?.length - 1) {
      paramsData = paramsData + element?.branchId;
      return;
    }
    paramsData = paramsData + element?.branchId + "&params=";
  });

  const businessInfo = await getBusinessData(paramsData);
  return businessInfo?.businessInfo || []
};