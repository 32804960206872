import { getApiInstance } from "../../AppService";
import {
  ADD_NEW_PRODUCT,
  ADD_PRODUCT_PHOTO,
  DELETE_PRODUCT,
  DELETE_PRODUCT_Photo,
  GET_ALL_DELIVERY_METHODS,
  GET_COUNTRY_NAMES,
  GET_GST_RATES,
  GET_UNIT_OF_MEASUREMENT,
  REVIEW_PRODUCT_INFO,
  UPDATE_PENDING_PRODUCT,
  UPDATE_PRODUCT,
  VIEW_PRODUCT_INFO,
} from "../../constant";

// Fetch product details
export const getViewProductDetailsAPI = async (productId) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(VIEW_PRODUCT_INFO + productId);
    console.log("getViewProductDetailsAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Fetch product details  using review-product API
export const getReviewProductDetailsAPI = async ({ businessId, productId }) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });
    const response = await instance.get(REVIEW_PRODUCT_INFO + productId);
    console.log("getReviewProductDetailsAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Fetch country names
export const getCountryNamesApi = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_COUNTRY_NAMES);
    console.log("getCountryNamesApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Fetch country names
export const getGstRatesApi = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_GST_RATES);
    console.log("getGstRatesApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Fetch all delivery methods
export const getAllDeliveryMethodsApi = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_ALL_DELIVERY_METHODS);
    console.log("getAllDeliveryMethodsApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Upload Product Photo
export const uploadProductPhotoApi = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(ADD_PRODUCT_PHOTO, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Add New Product
export const addNewProductApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        Businessid: data?.businessId,
      },
    });
    const response = await instance.post(ADD_NEW_PRODUCT, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Upload Product
export const uploadProductApi = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(UPDATE_PRODUCT, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
// Upload Product
export const uploadPendingProductApi = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(UPDATE_PENDING_PRODUCT, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Delete Product photo
export const deleteProductPhotoApi = async (data) => {
  const imageId = data?.imageId;
  try {
    const instance = getApiInstance({
      header: {
        productID: data?.productID,
        businessID: data?.businessID,
        clientType: "business-dashboard-web",
      },
    });

    const response = await instance.delete(
      DELETE_PRODUCT_Photo + imageId,
      data
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Delete Product
export const updateProductStatusApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.post(DELETE_PRODUCT, {
      id: data?.productId,
      status: "inactive",
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// get Unit of Measurement
export const getUnitOfMeasurementApi = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_UNIT_OF_MEASUREMENT);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

