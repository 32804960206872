import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FormInputBox from "../Input/FormInputBox";
import {
  BGColor02,
  bgColor,
  blackShade05,
  blackShade19,
  blueColor04,
  greyColor40,
  whiteColor,
} from "../../configs/styles/muiThemes";
import AddIcon from "@mui/icons-material/Add";

function MultiSelectWithCustomInputCard({
  menuList,
  value,
  onValueChange,
  handleClose,
  title,
}) {
  const [inputValue, setInputValue] = useState("");
  const [selectedList, setSelectedList] = useState(
    value?.length > 0 ? value?.split(",") : []
  );

  const [showOptions, setShowOptions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState(
    menuList || []
  );

  const divRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    }

    // Add event listener to handle clicks on the document
    document.addEventListener("click", handleClickOutside);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
    const filteredSuggestions = menuList?.filter(
      (suggestion) =>
        suggestion?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) > -1
    );
    setFilteredSuggestions(filteredSuggestions);
  };

  return (
    <Box sx={{ background: whiteColor, borderRadius: "10px" }}>
      <Box
        sx={{
          backgroundColor: BGColor02,
          p: "10px 15px",
          borderRadius: "10px 10px 0 0",
        }}
      >
        <Typography
          fontSize={17}
          fontWeight={600}
          color={blackShade05}
          textAlign={"center"}
        >
          {title}
        </Typography>
      </Box>
      <Box p={"10px 15px"}>
        <Typography fontSize={13} fontWeight={600} color={blackShade05} mb={0}>
          Selected Values
        </Typography>
        {selectedList?.length > 0 ? (
          <Box display={"flex"} flexWrap={"wrap"} gap={1} mb={2} mt={1}>
            {selectedList?.map((item, index) => (
              <Chip
                label={
                  <Typography
                    textTransform={"capitalize"}
                    fontSize={11}
                    color={blackShade19}
                    fontWeight={500}
                  >
                    {item?.replace(/_/g, " ")}
                  </Typography>
                }
                variant="outlined"
                onDelete={() => {
                  setSelectedList((prevState) => {
                    const updatedItems = [...prevState];
                    updatedItems.splice(index, 1);

                    return updatedItems;
                  });
                }}
                key={item + index}
              />
            ))}
          </Box>
        ) : (
          <Typography
            fontSize={11.5}
            fontWeight={400}
            color={greyColor40}
            mb={1}
          >
            No values are selected
          </Typography>
        )}
        <Typography fontSize={13} fontWeight={600} color={blackShade05} mb={1}>
          Select value or Add custom value
        </Typography>
        <Box position="relative" ref={divRef}>
          <Box display="flex" alignItems="flex-start" gap={3}>
            <Box flex={1}>
              <FormInputBox
                placeholder="Enter custom value"
                value={inputValue}
                onChange={handleChange}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setSelectedList((prevState) => [...prevState, inputValue]);
                    setInputValue("");
                    setFilteredSuggestions(menuList);
                  }
                }}
                onFocus={() => setShowOptions(true)}
                autoComplete="off"
                //   onBlur={() => setShowOptions(false)}
              />
            </Box>

            <IconButton
              sx={styles.iconAddBtn}
              onClick={() => {
                setSelectedList((prevState) => [...prevState, inputValue]);
                setInputValue("");
                setFilteredSuggestions(menuList);
              }}
            >
              <AddIcon fontSize="large" />
            </IconButton>
          </Box>
          {showOptions && filteredSuggestions?.length > 0 && (
            <Box
              position="absolute"
              top="100%"
              sx={{
                boxShadow:
                  "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                py: 1,
                borderRadius: "6px",
                maxHeight: "200px",
                minWidth: "150px",
                overflowY: "auto",
                backgroundColor: whiteColor,
                zIndex: 10,
              }}
            >
              {filteredSuggestions?.map((item, index) => (
                <Box
                  key={index}
                  fontSize={13.5}
                  fontWeight={500}
                  sx={{
                    cursor: "pointer",
                    py: "3px",
                    px: 2,
                    textTransform: "capitalize",
                    backgroundColor: selectedList?.includes(item)
                      ? "rgba(255, 123, 44, 0.08)"
                      : "transparent",
                    "&:hover": {
                      backgroundColor: selectedList?.includes(item)
                        ? "rgba(255, 123, 44, 0.08)"
                        : bgColor,
                    },
                  }}
                  onClick={() => {
                    setSelectedList((prevState) => {
                      if (prevState?.includes(item)) {
                        let newArr = prevState.filter(
                          (selected) => selected !== item
                        );

                        return newArr;
                      } else {
                        return [...prevState, item];
                      }
                    });
                    setInputValue("");
                    setFilteredSuggestions(menuList);
                  }}
                >
                  {item?.replace(/_/g, " ")}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems="flex-end"
        gap={1}
        sx={{
          backgroundColor: BGColor02,
          p: "10px 15px",
          borderRadius: "0  0 10px 10px",
        }}
      >
        <Button sx={styles.cancelBtn} onClick={handleClose}>
          Cancel
        </Button>

        <Box
          display="flex"
          flexDirection="column"
          gap="4px"
          alignItems="flex-end"
        >
          <Typography fontSize={11} fontWeight={500}>
            {selectedList?.length}{" "}
            {selectedList?.length > 1 ? "values" : "value"} selected
          </Typography>
          <Button
            sx={styles.applyBtn}
            onClick={() => {
              onValueChange(selectedList?.join());
              handleClose();
            }}
            disabled={selectedList?.length === 0}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default MultiSelectWithCustomInputCard;

const styles = {
  cancelBtn: {
    padding: "4px 14px",
    fontSize: "12.5px",
    fontWeight: 600,
    color: blueColor04,
    backgroundColor: whiteColor,
    border: "1.5px solid " + blueColor04,
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },
  applyBtn: {
    padding: "4px 14px",
    fontSize: "12.5px",
    fontWeight: 600,
    backgroundColor: blueColor04,
    color: whiteColor,
    border: "1.5px solid " + blueColor04,
    "&:hover": {
      backgroundColor: whiteColor,
      color: blueColor04,
    },

    "&.Mui-disabled": {
      bgcolor: "rgba(0,0,0,0.2)",
      color: "rgba(0,0,0,0.3)",
      border: "none",
    },
  },

  iconAddBtn: {
    width: "35px",
    height: "35px",
    bgcolor: blueColor04,
    color: whiteColor,
    borderRadius: "3px",
    border: `1px solid ${blueColor04}`,
    "&:hover": {
      border: `1px solid ${blueColor04}`,
      color: blueColor04,
      bgcolor: whiteColor,
    },
  },
};
