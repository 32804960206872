import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getActiveProductInfoAPI, getProductSubCategoryOneAPI, getProductSubCategoryTwoAPI,  } from "../../service/api_calls/AddProduct/AddProductAPI";

const initialState = {
  productDetails: null,
  productSubCatOne: null,
  productSubCatTwo: null,

  productDetailsLoader: false,
  productSubCatOneLoader: false,
  productSubCatTwoLoader: false,
};

//get products details
export const getActiveProductInfo = createAsyncThunk(
  "getActiveProductInfo",
  async (param, thunkAPI) => {
    const res = await getActiveProductInfoAPI(param);
    return res;
  }
);

//get products sub category one data
export const getProductSubCategoryOne = createAsyncThunk(
  "getProductSubCategoryOne",
  async (param, thunkAPI) => {
    const res = await getProductSubCategoryOneAPI(param);
    return res;
  }
);

//get products sub category two data
export const getProductSubCategoryTwo = createAsyncThunk(
  "getProductSubCategoryTwo",
  async (param, thunkAPI) => {
    const res = await getProductSubCategoryTwoAPI(param);
    return res;
  }
);

export const addProduct = createSlice({
  name: "addProduct",
  initialState,
  reducers: {},
  extraReducers: {
    [getActiveProductInfo.pending]: (state) => {
      state.productDetails = null;
      state.productDetailsLoader = true;
    },
    [getActiveProductInfo.fulfilled]: (state, { payload }) => {
      state.productDetails = payload?.product;
      state.productDetailsLoader = false;
    },
    [getActiveProductInfo.rejected]: (state, { payload }) => {
      state.productDetails = null;
      state.productDetailsLoader = false;
    },

    //get products sub category one data
    [getProductSubCategoryOne.pending]: (state) => {
      state.productSubCatOne = null;
      state.productSubCatOneLoader = true;
    },
    [getProductSubCategoryOne.fulfilled]: (state, { payload }) => {
      state.productSubCatOne = payload;
      state.productSubCatOneLoader = false;
    },
    [getProductSubCategoryOne.rejected]: (state, { payload }) => {
      state.productSubCatOne = null;
      state.productSubCatOneLoader = false;
    },

    //get products sub category two data
    [getProductSubCategoryTwo.pending]: (state) => {
      state.productSubCatTwo = null;
      state.productSubCatTwoLoader = true;
    },
    [getProductSubCategoryTwo.fulfilled]: (state, { payload }) => {
      state.productSubCatTwo = payload;
      state.productSubCatTwoLoader = false;
    },
    [getProductSubCategoryTwo.rejected]: (state, { payload }) => {
      state.productSubCatTwo = null;
      state.productSubCatTwoLoader = false;
    },
  },
});

export const addProductReducer = addProduct.reducer;
