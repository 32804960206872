import { Avatar, Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from 'react'
import { BGColor02, blackShade05, blackShade159, deleteBtnColor, greyColor11, greyColor28, greyColor29, greyColor6, greyColor7, lightBlueColor01, logoImgborder1, orangeStatusColor, outOfStockColor, tableHeaderColor, whiteColor } from '../../../../configs/styles/muiThemes';
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HorizontalStepper from "../../../../components/HorizontalStepper";
import ModalCard from "../../../../components/ModalCard";
import OrderApprovedPopupContent from "./OrderApprovedPopupContent";
import HorizontalDotLineStepper from "../../../../components/HorizontalDotLineStepper";
import VerticalDotLineStepper from "../../../../components/VerticalDotLineStepper";

function OrderDetailsPopupContent({ handleApproveOrder, handleCancel }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const getBGColorByStatus = (status) => {
    switch (status) {
      case "ordercreated":
        return orangeStatusColor;
      case "orderapproved":
        return blackShade05;
      case "ordershipped":
        return lightBlueColor01;
      case "orderdelivered":
        return lightBlueColor01;
      case "bordercanceled":
        return outOfStockColor;

      default:
        return "";
    }
  };

  const getOrderStatusText = (status) => {
    switch (status) {
      case "ordercreated":
        return "Pending";
      case "orderapproved":
        return "Ready to Ship";
      case "ordershipped":
        return "Shipped";
      case "orderdelivered":
        return "Completed";
      case "bordercanceled":
        return "Canceled";

      default:
        return "";
    }
  };
  const STEPS = [
    {
      label: "Order Received",
      sublabel: "12-Apr-2023 at 12:15 AM",
    },
    {
      label: "Order Processing",
    },
    {
      label: "Order Approved",
    },
    {
      label: "Order Shipped",
    },
    {
      label: "Order Delivered",
    },
  ];

  return (
    <Box sx={styles.card} mt={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        gap={{ xs: "0px", md: "10px" }}
        alignItems="center"
      >
        <Typography
          variant="h5"
          fontSize={{ xs: "14", md: 17 }}
          fontWeight={600}
          color={blackShade05}
        >
          Order Detail
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          gap={1}
          mr={{ xs: 0, md: 10 }}
        >
          <Button
            sx={styles.approveIconBtn}
            variant="contained"
            disableElevation
            onClick={handleApproveOrder}
          >
            APPROVE
          </Button>
          <Button
            sx={styles.cancelIconBtn}
            variant="contained"
            disableElevation
            onClick={handleCancel}
          >
            CANCEL
          </Button>
        </Box>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box sx={styles.orderInfoBox}>
            <Box>
              <Box mb={0.8}>
                <Typography variant="h6" sx={styles.orderIdText}>
                  Order ID:{" "}
                  <Typography
                    variant="h6"
                    sx={{ ...styles.orderIdText, fontWeight: 600 }}
                  >
                    267676GHERT105467 {/* {cartInfo?.orderId} */}
                  </Typography>
                </Typography>
              </Box>

              <Typography variant="h6" sx={styles.orderIdText}>
                Order Date:{" "}
                <Typography
                  variant="h6"
                  sx={{ ...styles.orderIdText, fontWeight: 600 }}
                >
                  Apr 12, 2023
                  {/* {moment(cartInfo?.orderCreatedOn).format("MMM Do, YYYY")} */}
                </Typography>
              </Typography>
            </Box>

            <Box sx={styles.statusTag(getBGColorByStatus("ordercreated"))}>
              {getOrderStatusText("ordercreated")}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{ ...styles.orderInfoBox, padding: 3 }}
          >
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent="space-between"
              gap={{ xs: 2, md: 20 }}
              borderBottom={`1px solid #ebeff5`}
            >
              <Box display="flex" gap={3}>
                <Avatar
                  alt="Remy Sharp"
                  src="https://storage.googleapis.com/bo3151920215/business/c147c9d959f74365bbce4d2633e99e97/logo.jpeg"
                  variant="square"
                  sx={{
                    width: 52,
                    height: 52,
                    border: "2px solid #e2e5ec",
                  }}
                ></Avatar>
                <Box>
                  <Typography
                    variant="h5"
                    fontSize={14}
                    fontWeight={600}
                    color={blackShade05}
                  >
                    Tavastha Ayurveda
                  </Typography>
                  <Typography
                    variant="h4"
                    fontSize={13}
                    fontWeight={400}
                    color={greyColor7}
                  >
                    127 pallavi nagar bawadiyan kallan, bhopal, Bhopal, Madhya
                    Pradesh, 462026
                  </Typography>
                </Box>
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                gap={{ xs: "0px", md: "10px" }}
              >
                <InfoCard
                  icon={
                    <LocalShippingOutlinedIcon
                      sx={{ fontSize: { xs: "20px", md: "24px" } }}
                    />
                  }
                  label="Costbo Premiere"
                />
                <InfoCard
                  icon={
                    <EventAvailableOutlinedIcon
                      sx={{ fontSize: { xs: "20px", md: "24px" } }}
                    />
                  }
                  label="Delhivery (2-5 days)"
                />
              </Box>
            </Box>
            <Box
              // display="flex"
              // justifyContent="center"
              // padding={20}
              mt={{ xs: 1, md: 5 }}
              mr={{ xs: 0, md: 9 }}
            >
              {matches ? (
                <HorizontalDotLineStepper steps={STEPS} activeStep={"1"} />
              ) : (
                <VerticalDotLineStepper steps={STEPS} activeStep={"1"} />
              )}
            </Box>
          </Box>
          <Box></Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default OrderDetailsPopupContent
const InfoCard = ({ icon, label, value }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={{ xs: "5px", md: "2px" }}
    >
      <Box sx={styles.iconCircle}>{icon}</Box>
      <Typography fontSize={13} fontWeight={600} color={blackShade05}>
        {label}
      </Typography>
    </Box>
  );
};
const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "12px",
    padding: "20px",

    maxHeight: "95vh",
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
      // height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blackShade159,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },
  approveIconBtn: {
    padding: { md: "5px 24px", xs: "3px 12px" },
    fontSize: "12px",
    fontWeight: 600,
    color: lightBlueColor01,
    backgroundColor: whiteColor,
    border: "1px solid " + lightBlueColor01,
    "&:hover": {
      color: whiteColor,
      backgroundColor: lightBlueColor01,
    },
  },
  cancelIconBtn: {
    padding: { md: "5px 24px", xs: "3px 12px" },
    fontSize: "12px",
    fontWeight: 600,
    color: deleteBtnColor,
    backgroundColor: whiteColor,
    border: "1px solid " + deleteBtnColor,
    "&:hover": {
      color: whiteColor,
      backgroundColor: deleteBtnColor,
    },
  },
  orderIdText: {
    fontSize: { xs: 13, md: 15 },
    fontWeight: 500,
    lineHeight: "27px",
    color: blackShade05,
    display: "inline",
  },

  orderInfoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "12px",
    border: `1px solid ${greyColor28}`,
    p: "13px 0 13px 15px",
    mt: 2.2,
  },

  statusTag: (bgColor) => ({
    backgroundColor: bgColor || lightBlueColor01,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "20px",
    color: whiteColor,
    p: "0 15px 0 20px",
    position: "relative",

    "&::before": {
      content: '" "',
      width: "15px",
      height: "28px",
      backgroundColor: whiteColor,
      rotate: "40deg",
      position: "absolute",
      left: -3,
      top: -8,
    },
  }),

  table: {
    border: `1px solid ${greyColor11}`,
    borderRadius: "7px",
    mt: 3,
  },

  tableHeader: {
    backgroundColor: tableHeaderColor,
    p: "15px 10px",
    borderRadius: "7px 7px 0 0",
  },

  tableHeaderText: {
    fontSize: 13,
    fontWeight: 500,
    color: greyColor29,
  },

  productRow: {
    p: "18px 0",
    borderBottom: "1px solid #f2f2f2",
  },

  productImg: {
    width: "60px",
    height: "60px",
    borderRadius: "8px",
    border: logoImgborder1,
  },

  productInfoText: {
    fontSize: 14,
    fontWeight: 500,
    color: greyColor29,
  },

  centerText: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  summaryCard: {
    padding: "12px",
    borderRadius: "10px",
    border: `1px solid ${greyColor28}`,
    mt: 2.2,
  },
  iconCircle: {
    width: { xs: "45px", md: "45px" },
    height: { xs: "45px", md: "45px" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: BGColor02,
    borderRadius: "50%",
  },
};