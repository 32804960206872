import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import InputPreview from "../../../components/Input/InputPreview";
import {
  blackColor,
  greyColor6,
  labelColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";

function DiscountPreviewCard({ data }) {
  return (
    <Box sx={styles.section}>
      <Typography
        component="h5"
        fontSize={15}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        m="5px 0 15px !important"
      >
        Discount Details
      </Typography>

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        <Grid item xs={12} md={3}>
          <InputPreview
            label="Free shipment for Orders above"
            value={
              data?.immediateDiscount
                ? `₹ ${data?.immediateDiscount}`
                : data?.immediateDiscount
            }
          />
        </Grid>
      </Grid>

      <Typography
        variant="body1"
        color={labelColor}
        fontSize={14.5}
        fontWeight={600}
        sx={{ whiteSpace: { md: "nowrap", xs: "normal" } }}
        m="18px 0 18px !important"
      >
        Cashback on Orders
      </Typography>

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        <Grid item xs={12} md={3}>
          <InputPreview
            label="Cashback Percentage"
            infoText="(10% preferred)"
            value={data?.rewardPointPercent?.value}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputPreview
            label="Max Cashback usage per Order"
            value={
              data?.referralPointsMaxPerTxn
                ? `₹ ${data?.referralPointsMaxPerTxn}`
                : data?.referralPointsMaxPerTxn
            }
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputPreview
            label="Cashback Validity"
            value={data?.rewardExpirationDays?.value}
          />
        </Grid>
      </Grid>

      <Typography
        variant="body1"
        color={labelColor}
        fontSize={14.5}
        fontWeight={600}
        sx={{ whiteSpace: { md: "nowrap", xs: "normal" } }}
        m="18px 0 18px 0 !important"
      >
        Additional Discount based on Distance
        <span
          style={{
            color: greyColor6,
            fontSize: 12,
            fontWeight: 400,
            marginLeft: "6px",
          }}
        >
          (Distance between warehouse and customer address)
        </span>
      </Typography>

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        <Grid item xs={12} md={6} mt={-2}>
          <InputPreview
            label=""
            value={
              data?.additionalDiscount?.length > 0
                ? data?.additionalDiscount
                    ?.map(
                      (item) =>
                        `${item?.distanceStart} KM - ${item?.distanceEnd} KM ₹${item?.discountPercentage}`
                    )
                    ?.join(", ")
                : 0
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default DiscountPreviewCard;

const styles = {
  section: {
    p: "10px 20px 20px",
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: "10px",
    mt: { xs: "10px", md: "20px" },
  },
};
