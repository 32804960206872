import { Box, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  blueColor02,
  errorTextColor,
  greyColor5,
  orangeTextColor,
  saveBtnBG,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import DashboardStepper from "../../Dashboard/DashboardStepper";
import {
  CATALOG_ADD_PRODUCT_STEP_LIST,
  CATALOG_STEP_LIST,
} from "../../../utils/appData";
import LabelInputModal from "../PopupModals/LabelInputModal";
import RefLinksInputModal from "../PopupModals/RefLinksInputModal";
import CatalogActionBtns from "../../../components/Buttons/CatalogActionBtns";

function CategorySpecsForm({
  data,
  onValueChange,
  currentPage,
  gotoNext,
  goBack,
  productName,
  isCopyProduct,
  isAddProduct,
  onCancelClick,
  handleTabChange,
  tabTitle,
  showPreviewBtn,
  previewErrorMsg,
  handlePreviewSubmit,
}) {
  return (
    <Box sx={styles.card}>
      {/* <DashboardStepper
        stepList={
          isCopyProduct ? CATALOG_ADD_PRODUCT_STEP_LIST : CATALOG_STEP_LIST
        }
        currentPage={currentPage}
        isAddProduct
        hideAvailability={!isCopyProduct}
        handleTabChange={(destinationTab) =>
          handleTabChange(
            currentPage,
            isCopyProduct ? CATALOG_ADD_PRODUCT_STEP_LIST : CATALOG_STEP_LIST,
            destinationTab
          )
        }
      /> */}

      <Typography
        component="h6"
        fontSize={{ xs: 15, md: 14.5 }}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        mb={{ xs: "5px !important", md: "10px !important" }}
        mt={{ xs: "0px !important", md: "15px !important" }}
      >
        Product Specification
      </Typography>

      <LabelInputModal
        label="Product Specification"
        placeholder="Enter product specifications"
        required
        disabled
        onValueChange={() => {}}
        productSpcs={data?.productSpecs}
        setMoreProducts={(data) => {
          onValueChange("productSpecs", data);
        }}
        error={data?.errors?.productSpecs}
      />

      <Box mt={{ xs: 3, md: 0 }}>
        <Typography
          component="h6"
          fontSize={{ xs: 13.5, md: 14.5 }}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          mb={"10px !important"}
          mt={{ xs: "0px !important", md: "30px !important" }}
        >
          Reference Link
        </Typography>

        <RefLinksInputModal
          label="Reference Link"
          placeholder="Enter reference links"
          required
          disabled
          onValueChange={() => {}}
          refLinks={data?.refLinks}
          setMoreProducts={(data) => {
            onValueChange("refLinks", data);
          }}
          isDashboardInput
          error={data?.errors?.refLinks}
        />
      </Box>

      <Box mt="20px">
        <CatalogActionBtns
          onBackClick={goBack}
          onSaveClick={() => {}}
          onNextClick={gotoNext}
          showBackBtn
          hideSaveBtn
          backBtnText="Previous"
          // nextBtnText="PREVIEW & SUBMIT"
          nextBtnText="NEXT"
          onCancelClick={onCancelClick}
          // Preview Btn
          showPreviewBtn={showPreviewBtn}
          onPreviewSubmitClick={handlePreviewSubmit}
        />
      </Box>

      <Typography
        fontSize={12}
        fontWeight={500}
        mt={1}
        textAlign="right"
        color={errorTextColor}
      >
        {previewErrorMsg}
      </Typography>
    </Box>
  );
}

export default CategorySpecsForm;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: { xs: "8px", md: "13px" },
    p: { xs: "12px", md: "5px 20px 20px" },
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    m: { xs: "15px 0 25px", md: "0px 0 25px" },
  },

  btnContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    ml: { xs: "auto" },
  },

  addIconBtn: {
    backgroundColor: "rgb(133 147 172)",
    borderRadius: "6px",
    color: whiteColor,
    "&:hover": {
      backgroundColor: "rgb(133 147 172)",
      color: whiteColor,
    },
  },

  deleteIconBtn: {
    backgroundColor: saveBtnBG,
    borderRadius: "6px",
    color: orangeTextColor,
    "&:hover": {
      backgroundColor: saveBtnBG,
      color: orangeTextColor,
    },
  },
};
