import moment from "moment";
import { getApiInstance } from "../../AppService";
import { CURRENT_DATE, FROM_DATE, GET_BUSINESS_CASHBACKS, GET_CASH_BACK_VALUE_FOR_BUSINESS, GET_COUPON_CODE_USEAGE } from "../../constant";

export const getAllBusinessCashbackApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(GET_BUSINESS_CASHBACKS, {
      params: {
        from: FROM_DATE,
        to: CURRENT_DATE,
        pageNo: data.pageNo,
        pageSize: data.pageSize,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCouponCodeUseageApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.post(GET_COUPON_CODE_USEAGE, data?.couponList, {
      params: {
        from: FROM_DATE,
        to: CURRENT_DATE,
        pageNo: 1,
        pageSize: 100,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCashBackValueForBusinessApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
        date: CURRENT_DATE,
      },
    });
    const response = await instance.get(GET_CASH_BACK_VALUE_FOR_BUSINESS, {
      params: {
        date: CURRENT_DATE,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

