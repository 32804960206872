import { Box, Button, Typography } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  blackShade05,
  blueColor04,
  greyColor8,
  whiteColor,
} from "../../configs/styles/muiThemes";
import HowToLinkCard from "../../components/Cards/HowToLinkCard";
import VideoModal from "../../components/VideoModal";
import { ONBOARDING_VIDEO_DATA } from "../../utils/data";
import { useState } from "react";
import BusinessOnboarding from "../BusinessOnboarding";

function EmptyBusinessListCard() {
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openAddBusinessModal, setOpenAddBusinessModal] = useState(false);

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  const handleCloseModal = () => {
    setOpenAddBusinessModal(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          handleClose={handleCloseVideoModal}
          data={ONBOARDING_VIDEO_DATA}
        />
      )}

      {openAddBusinessModal && (
        <BusinessOnboarding
          open={openAddBusinessModal}
          handleClose={handleCloseModal}
        />
      )}
      {/* <Box sx={styles.imgContainer}> */}
        <Box
          component="img"
          alt=""
          src="https://storage.googleapis.com/bodefaults/businessdashboardweb/online-shopping.svg"
          sx={{ height: "150px", width: "auto" }}
        />
      {/* </Box> */}
      <Typography
        fontWeight={600}
        color={blackShade05}
        fontSize={{ xs: 15, md: 15 }}
        textAlign={"center"}
        mt={"15px"}
        lineHeight={1.5}
      >
        Smart future proof full E-Commerce suite to manage your entire online business
      </Typography>
      <Button
        sx={styles.addIconBtn}
        variant="contained"
        disableElevation
        onClick={() => {
          setOpenAddBusinessModal(true);
        }}
        startIcon={<AddIcon />}
      >
        Add Business
      </Button>
      <HowToLinkCard
        link="Video Guide"
        handleClick={() => {
          setOpenVideoModal(true);
        }}
        text="Get Started - "
      />
    </Box>
  );
}

export default EmptyBusinessListCard;

const styles = {
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // background: whiteColor,
    width: "200px",
    height: "75px",
    // border: "1px solid #e5e9f0",
    // borderRadius: "50%",
  },
  addIconBtn: {
    padding: "6px 22px",
    width: { md: "auto", xs: "90%" },
    fontSize: "13px",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: blueColor04,
    border: "1.5px solid " + whiteColor,
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
      border: "1.5px solid " + blueColor04,
    },
    my: { md: 3, xs: 1.5 },

    "&.Mui-disabled": {
      border: "none",
    },
  },
};
