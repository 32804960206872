import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  blackShade01,
  bgColor15,
  lightBlueColor01,
  whiteColor,
} from "../../../../configs/styles/muiThemes";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { formatDate, getColorChip } from "../../../../utils/helper";
import { STEPPER_DATA } from "../../../../utils/dummyData";

function ActionTimeLine({ steps }) {
  return (
    <Box mt={1}>
      {steps?.map((item, index) => (
        <Step
          key={index}
          active={index !== 0}
          description={item?.short_desc}
          date={item?.updated_at}
          by={item?.updated_by?.person?.name}
          status={item?.complainant_action || item?.respondent_action}
          hideLine={steps?.length - 1 === index}
        />
      ))}
    </Box>
  );
}

export default ActionTimeLine;

const Step = ({ active, status, description, date, by, hideLine }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box
      display="flex"
      justifyContent={{ md: "space-between", xs: "unset" }}
      flexDirection={{ md: "row", xs: "column" }}
    >
      <Box display="flex" alignItems="flex-start" gap={{ md: 1.5, xs: 1.5 }}>
        <Stack alignItems="center">
          <Box sx={active ? styles.activeNode : styles.inactiveNode}>
            {active ? (
              <CheckRoundedIcon sx={{ fontSize: "18px", color: whiteColor }} />
            ) : (
              <FiberManualRecordIcon
                sx={{ fontSize: "15px", color: bgColor15 }}
              />
            )}
          </Box>
          {!hideLine && (
            <Box
              sx={{
                border: "1px dashed #a2aebe",
                width: { md: "1px", xs: "1px" },
                height: { md: "50px", xs: "150px" },
              }}
            />
          )}
        </Stack>

        {matches && (
          <Box>
            <Typography sx={styles.blackText} mb={0.4}>
              {description}
            </Typography>
            <Typography sx={styles.greyText}>Updated by : {by}</Typography>
          </Box>
        )}
        {!matches && (
          <Box display={"flex"} gap={0.5} flexDirection={"column"}>
            <Stack alignItems="flex-start">
              <Box sx={styles.statusChip(getColorChip(status?.toLowerCase()))}>
                {status === "CLOSE" ? "CLOSED" : status}
              </Box>
              <Typography sx={styles.greyText} mt={0.6}>
                {formatDate(date)}
              </Typography>
            </Stack>
            <Box>
              <Typography sx={styles.blackText} mb={0.4}>
                {description}
              </Typography>
              <Typography sx={styles.greyText}>Updated by : {by}</Typography>
            </Box>
          </Box>
        )}
      </Box>
      {matches && (
        <Stack alignItems="flex-end">
          <Box sx={styles.statusChip(getColorChip(status?.toLowerCase()))}>
            {status === "CLOSE" ? "CLOSED" : status}
          </Box>
          <Typography sx={styles.greyText} mt={0.6}>
            {formatDate(date)}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

const styles = {
  activeNode: {
    width: "25px",
    height: "25px",
    background: lightBlueColor01,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inactiveNode: {
    width: "25px",
    height: "25px",
    background: whiteColor,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid" + bgColor15,
  },
  greyText: {
    color: bgColor15,
    fontSize: { xs: 12, md: 12.5 },
    fontWeight: 400,
    lineHeight: 1.5,
  },
  blackText: {
    color: blackShade01,
    fontSize: { xs: 12.5, md: 13 },
    fontWeight: 500,
    lineHeight: 1.5,
  },
  statusChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    p: "3px 20px",
    borderRadius: "50px",
    width: "max-content",
    border: "1px solid " + data.color,
  }),
};
