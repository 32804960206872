import { Box, Checkbox, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blackShade159,
  greyColor21,
} from "../../../../configs/styles/muiThemes";
import FormInputBox from "../../../../components/Input/FormInputBox";

function ManifestCard({ title, boxData, onValueChange }) {
  return (
    <Box mb={2}>
      <Box display={"flex"} alignItems="center" gap={0.5}>
        <Checkbox
          checked={boxData?.isChecked}
          size="large"
          sx={{
            color: greyColor21,
            "&.Mui-checked": {
              color: blackShade159,
            },
          }}
          onChange={() => {
            onValueChange("isChecked", !boxData?.isChecked);
          }}
        />
        <Typography
          fontSize={14}
          fontWeight={600}
          color={blackShade05}
          lineHeight={"20px"}
        >
          {title}
        </Typography>
      </Box>
      <Grid container sx={{ width: "93%", ml: 0.7 }} spacing={5}>
        <Grid item md={3}>
          <FormInputBox
            label={"Length (cm) "}
            value={boxData?.length}
            onChange={({ target }) => {
              const value = target.value
                .replace(/[^0-9.]/g, "")
                .replace(/(\..*)\./g, "$1");
              onValueChange("length", value);
            }}
            disabled={!boxData?.isChecked}
            error={boxData?.errors?.length}
            required
          />
        </Grid>
        <Grid item md={3}>
          <FormInputBox
            label={"Width (cm) "}
            value={boxData?.width}
            onChange={({ target }) => {
              const value = target.value
                .replace(/[^0-9.]/g, "")
                .replace(/(\..*)\./g, "$1");
              onValueChange("width", value);
            }}
            disabled={!boxData?.isChecked}
            error={boxData?.errors?.width}
            required
          />
        </Grid>
        <Grid item md={3}>
          <FormInputBox
            label={"Height (cm)"}
            value={boxData?.height}
            onChange={({ target }) => {
              const value = target.value
                .replace(/[^0-9.]/g, "")
                .replace(/(\..*)\./g, "$1");
              onValueChange("height", value);
            }}
            disabled={!boxData?.isChecked}
            error={boxData?.errors?.height}
            required
          />
        </Grid>
        <Grid item md={3}>
          <FormInputBox
            label={"No of Boxes (1 to 100)"}
            value={boxData?.noOfBoxes}
            onChange={({ target }) => {
              const value = target.value
                .replace(/[^0-9.]/g, "")
                .replace(/(\..*)\./g, "$1");
              onValueChange("noOfBoxes", value);
            }}
            disabled={!boxData?.isChecked}
            error={boxData?.errors?.noOfBoxes}
            required
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ManifestCard;
