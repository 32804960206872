import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BGColor02,
  blackShade05,
  blackShade21,
  blueColor02,
  blueColor04,
  mobilePrimary,
  searchInputIconColor,
} from "../../configs/styles/muiThemes";
import SearchIcon from "@mui/icons-material/Search";
import CashBackDetails from "./CashBackDetails";
import CustomerCashBackInfo from "./CustomerCashBackInfo";
import NumberSearchInput from "../../components/Input/NumberSearchInput";
import EmptyCard from "../../components/Cards/EmptyCard";
import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import EmptyCashBack from "./EmptyCashBack";
import { stickyHeader } from "../../utils/styles";

function CashBackContent({
  cashbackStats,
  customerCashback,
  loading,
  activeBusinessInfo,
}) {
  const [number, setNumber] = useState("");

  const rewardPercentage = cashbackStats?.cashbackSummary?.rewardPointPercent;
  const whatsAppNumber = "+918088422615";
  const emailAddress = "support@costbo.com";
  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${activeBusinessInfo?.name}+- Loyalty Points+-+Cashback Settings+Update+Request.+`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={"column"}
        sx={{ ...stickyHeader, pt: 2, pb: {md:1,xs:0.7 }}}
        gap={{xs:0.8, md:1.5}}
        
      >
        <Typography
          variant="h4"
          color={blackShade05}
          fontSize={{ md: "17px", xs: "15px" }}
          fontWeight="600"
        >
          Loyalty Points
        </Typography>
        <Typography
          variant="h6"
          color={blackShade21}
          fontSize={{ md: "13px", xs: "13px" }}
          fontWeight="500"
          display={{ md: "inline", xs: "inline" }}
          lineHeight='20px'
        >
          For any changes to Cashback Settings, please contact COSTBO support
          via{" "}
          <Typography
            // variant="body1"
            fontSize={{ md: "13px", xs: "13px" }}
            fontWeight={500}
            color={{xs:mobilePrimary, md:blueColor04}}
            display="inline"
            // onclick={handleChatClick}
            component="a"
            href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=${activeBusinessInfo?.name}+- Loyalty Points+-+Cashback Settings+Update+Request.+&fs=1&to=${emailAddress}&tf=cm`}
            target="_blank"
            // lineHeight={0.8}
          >
            Email
          </Typography>
          {"  "}
          or{" "}
          <Typography
            // variant="body1"
            fontSize={{ md: "13px", xs: "13px" }}
            fontWeight={500}
            color={{xs:mobilePrimary, md:blueColor04}}
            display="inline"
            onClick={handleChatClick}
            // lineHeight={0.8}
          >
            Whatsapp
          </Typography>
        </Typography>
      </Box>
      {rewardPercentage >= 0 ? (
        <>
            <CashBackDetails
              activeBusinessInfo={activeBusinessInfo}
              cashbackStats={cashbackStats}
              customerCashback={customerCashback}
            />
        </>
      ) : (
        <ThreeDotLoader />
      )}
    </Box>
  );
}

export default CashBackContent;
