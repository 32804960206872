import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { blackColor, blackShade159, greyColor6, greyShade55, logoImgborder } from "../../configs/styles/muiThemes";

const QuantityPickerInput = ({ value, onRemove, onAdd, disabledAdd, disabled }) => {
  return (
    <Box sx={styles.inputBox(disabled)}>
      <IconButton onClick={onRemove}>
        <RemoveIcon sx={{color: blackShade159}} />
      </IconButton>

      <Box sx={styles.qtyContentBox}>
        <Typography fontSize={13} fontWeight={500} color={blackColor}>
          {value}
        </Typography>
      </Box>

      <IconButton onClick={onAdd} disabled={disabledAdd}>
        <AddIcon sx={{color: blackShade159}} />
      </IconButton>
    </Box>
  );
};

export default QuantityPickerInput;

const styles = {
  inputBox: (disabled)=>({
    height:'30px',
    display: "flex",
    alignItems: "center",
    // border: logoImgborder,
    border:`1.5px solid ${greyColor6}`,
    borderRadius: "6px",
    opacity: disabled ? 0.5 : 1
  }),

  qtyContentBox:{
    minWidth: '30px',
    height:'30px',
    display:'flex', alignItems:'center', justifyContent:'center',
    borderRight: `1.5px solid ${greyColor6}`,
    borderLeft: `1.5px solid ${greyColor6}`,
  }
};


