import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import InfoCardHeader from "./InfoCardHeader";
import {
  blackShade05,
  blueColor02,
  blueColor04,
  logoImgborder1,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import LabelValueText from "./LabelValueText";
import { addThousandsSeparator } from "../../../utils/helper";

function PaycartOrdersCard({ paycartData, onClick }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const totalPaycartcreated = Number(paycartData?.totalPaycartcreated) || 0;
  const totalPaycartOrders = Number(paycartData?.totalPaycartOrders) || 0;
  const totalPaycartValue = Number(paycartData?.totalPaycartValue) || 0;

  let ordersNotPayed = totalPaycartcreated - totalPaycartOrders;

  return (
    <Box sx={styles.card}>
      <InfoCardHeader title="Easy Cart Orders" onManageClick={onClick} />

      <Box
        display="flex"
        alignItems="center"
        borderBottom="1px dashed #e2e4ea"
        pb={{ xs: 1, md: 2.5 }}
        mt={1}
      >
        <LabelValueText
          label="TOTAL EASY CART ORDERS"
          value={totalPaycartcreated}
          valueColor={matches ? blueColor04 : blackShade05}
          borderStyle="dashed"
          flex={{ xs: 1, md: 1.2 }}
          valueSize={14}
        />
        <LabelValueText
          label="ORDERS PAID"
          value={totalPaycartOrders}
          hideBorder
          valueSize={14}
        />
      </Box>

      <Box display="flex" alignItems="center" pt={{ xs: 0, md: 1.3 }} pb={0}>
        <LabelValueText
          label="TOTAL EASY CART VALUE"
          value={`₹ ${addThousandsSeparator(totalPaycartValue)}`}
          valueColor={matches ? blueColor04 : blackShade05}
          borderStyle="dashed"
          flex={{ xs: 1, md: 1.2 }}
          valueSize={14}
        />
        <LabelValueText
          label="ORDERS NOT PAID"
          value={ordersNotPayed || 0}
          hideBorder
          valueSize={14}
        />
      </Box>
    </Box>
  );
}

export default PaycartOrdersCard;

const styles = {
  card: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: {xs:'10px', md:"13px"},
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 15px", md: "10px 15px" },
  },
};
