import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { greyColor40, orangeShade11 } from "../../configs/styles/muiThemes";

function HorizontalRadioGroupWithLabel({
  label,
  disabled,
  required,
  hintText,
  radioOptions,
  onValuesChanged,
  error,
}) {
  return (
    <Grid
      container
      columnSpacing={2}
      rowSpacing={1}
      display={"flex"}
      alignItems={"start"}
    >
      <Grid item md={6} xs={12}>
        <Typography
          variant="h3"
          fontSize={{ xs: 12, md: 13 }}
          color={greyColor40}
          fontWeight={400}
          mb={"0px"}
          sx={{
            display: "inline",
            opacity: disabled ? 0.7 : 1,
          }}
        >
          {label}{" "}
        </Typography>
        {required ? (
          <Typography variant="body1" sx={{ display: "inline", color: "#f00" }}>
            *
          </Typography>
        ) : null}
        {hintText && (
          <Typography
            sx={{ display: "block" }}
            fontSize={11.5}
            color={orangeShade11}
            fontWeight={400}
            mt={-0.2}
            lineHeight={1.5}
          >
            {hintText}
          </Typography>
        )}
      </Grid>
      <Grid item md={6} xs={12}>
        <FormControl>
          <RadioGroup row name="radio-buttons-group">
            {radioOptions?.map((item, index) => {
              if (item?.display !== "Y") return;

              return (
                <FormControlLabel
                  key={item?.label + index}
                  control={
                    <Radio
                      checked={item?.value === item?.options?.selected}
                      onChange={() => {
                        onValuesChanged(item);
                      }}
                    />
                  }
                  label={item?.label}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
        {error ? (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={12}
            sx={{ mt: "4px !important", textAlign: "start !important" }}
            style={{ color: "red" }}
          >
            {/* Please enter, its a mandatory value */}
            {error}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default HorizontalRadioGroupWithLabel;
