import React from "react";
import { primaryColor, whiteColor } from "../../configs/styles/muiThemes";
import { Box, Button, Typography } from "@mui/material";

const AlertPopup = ({ text, onClick, btnTxt, color }) => {
  return (
    <Box sx={styles.alertCard}>
      <Typography
        variant="body2"
        fontSize={14}
        fontWeight={500}
        textAlign="center"
        sx={{ whiteSpace: "pre-line", lineHeight: "26px", my: 1.2 }}
      >
        {text}
      </Typography>
      <Button sx={styles.alertBtn(color)} variant="outlined" onClick={onClick}>
        {btnTxt || "GO TO BUSINESS LISTING"}
      </Button>
    </Box>
  );
};

export default AlertPopup;

const styles = {
  alertCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    padding: "20px 20px 15px 20px",
    borderRadius: "8px",
  },

  alertBtn: (color) => ({
    fontSize: "13px",
    fontWeight: "500",
    color: whiteColor,
    backgroundColor: color || primaryColor,
    border: "1px solid" + color || primaryColor,
    padding: "6px 24px",
    my: "10px",
    "&:hover": {
      color: color || primaryColor,
      border: "1px solid" + color || primaryColor,
      backgroundColor: whiteColor,
    },
  }),
};
