import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { blackShade05 } from "../../../../configs/styles/muiThemes";
import InputPreview from "../../../../components/Input/InputPreview";
import StyledReadOnlySwitch from "../../../../components/Switch/StyledReadOnlySwitch";

function DisplayTrackingInfoCard({
  carrierTrackId,
  carrierName,
  carrierURL,
  notes,
  img,
  mode,
}) {
  const [hide, setHide] = useState(false);
  return (
    <Box>
      <Typography
        fontSize={14}
        fontWeight={600}
        color={blackShade05}
        mt="4px"
        mb="10px"
        lineHeight={"20px"}
      >
        Tracking Info
      </Typography>
      {img && !hide ? (
        <Box
          sx={{ mb: 2, height: "115px", width: "auto", borderRadius: "12px" }}
          component={"img"}
          alt=""
          src={img}
          onError={() => setHide(true)}
        />
      ) : null}
      <Grid container mt={1} spacing={{ xs: 1, md: 0 }}>
        <Grid item md={3} xs={12}>
          <InputPreview
            label="Shipment Tracking Number"
            value={carrierTrackId}
            valueSize={14}
            hideBorder
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <InputPreview
            label="Courier Name"
            value={carrierName}
            valueSize={14}
            hideBorder
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <InputPreview
            label="Delivery Mode"
            value={mode}
            valueSize={14}
            hideBorder
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <InputPreview
            label="Courier URL"
            value={carrierURL}
            valueSize={14}
            hideBorder
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <InputPreview label="Notes" value={notes} valueSize={14} hideBorder />
        </Grid>
      </Grid>
    </Box>
  );
}

export default DisplayTrackingInfoCard;
