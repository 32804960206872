import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  IconButton,
  Snackbar,
  Popper,
  Fade,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Stack,
  CircularProgress,
  Grid,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import MuiAlert from "@mui/material/Alert";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import moment from "moment";
import {
  getActiveBusinessInfo,
  getCommerceInfo,
} from "../../../reducers/businessPreview/businessPreviewSlice";
import { updateInventoryData } from "../../../service/api_calls/inventory/InventoryApi";
import {
  BGColor02,
  blackColor,
  blackShade05,
  blackShade19,
  blackShade21,
  blackShade22,
  blueColor04,
  borderTextBoxColor,
  greenColor,
  greyColor40,
  greyColor42,
  greyColor47,
  greyColor5,
  greyColor6,
  greyColor8,
  greyColor9,
  greyShade10,
  greyShade168,
  lightRedColor,
  logoImgborder1,
  mobilePrimary,
  redColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import {
  INVENTORY_VIDEO_DATA,
  OOS_COLUMN_HEADING_NEW,
} from "../../../utils/data";
import ModalCard from "../../../components/ModalCard";
import EmptyCard from "../../../components/Cards/EmptyCard";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import HowToLinkCard from "../../../components/Cards/HowToLinkCard";
import VideoModal from "../../../components/VideoModal";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import { stickyHeader } from "../../../utils/styles";
import TabelCellInputBox from "../../../components/TableInputs/TabelCellInputBox";

import { textOneLines } from "../../../utils/styles";
import {
  getSelectedInventoryRowCount,
  validateOutOfStockUpdate,
} from "../../../reducers/inventory/inventoryHelper";

import ErrorToolTipIcon from "../../CatalogBulkUploadNewCopy/Components/ErrorToolTipIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  clearInventoryInfo,
  filterInventoryOndcProducts,
  getAllLowStockProducts,
  getCategoryDropdownListData,
  getInventorySummaryCounts,
  handleLowStockUpdateChange,
  refreshLowStockProducts,
  searchInventoryProducts,
  selectAllInventoryProducts,
  updateLowStockInfoList,
} from "../../../reducers/inventory/inventorySlice";
import InventoryTabs from "../Components/InventoryTabs";
import SearchInfoCard from "../Components/SearchInfoCard";
import SearchBoxInput from "../../../components/Input/SearchBoxInput";
import InfiniteScroll from "react-infinite-scroll-component";
import OutOfStockCard from "../Cards/OutOfStockCard";
import UpdateInventoryBtn from "../Components/UpdateInventoryBtn";
import MobileActionBtns from "../Components/MobileActionBtns";
import InventoryFilterCard from "../Cards/InventoryFilterCard";
import MobileCategoryFilter from "../Popups/MobileCategoryFilter";

function LowStockTab() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const PAGE_SIZE = 50;
  const [hasMoreData, setHasMoreData] = useState(true);

  const [searchQuery, setSearchQuery] = useState("");
  const [isClearSearch, setIsClearSearch] = useState(false);

  const [filterType, setFilterType] = useState("");
  const [isClearFilter, setIsClearFilter] = useState(null);

  const [categoryInfo, setCategoryInfo] = useState(null);

  const [selectedOndcCategory, setSelectedOndcCategory] = useState("All");
  const [selectedOndcSubCat, setSelectedOndcSubCat] = useState("All");

  const [tabValue, setTabValue] = useState(2);
  const [productsList, setProductsList] = useState(null);

  const [openSnack, setOpenSnack] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [updateLoader, setUpdateLoader] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, value: "" });
  const [infoModal, setInfoModal] = useState(false);

  const [inventoryErr, setInventoryErr] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openMobileFilterModel, setOpenMobileFilterModel] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const { activeBusinessInfo, commerceInfo, businessProductsLoader } =
    useSelector((state) => state.businessPreview);

  const {
    lowStockProducts,
    lowStockPageNo,
    lowStockProductsLoader,
    inventorySummary,
    searchLoader,
    ondcDropdownData,
  } = useSelector((state) => state.inventory);

  // Fetch Low Stock Products
  const fetchAllLowStockProducts = (isRefresh) => {
    setHasMoreData(true);
    const businessId = sessionStorage.getItem("selectedBusinessId");

    if (isRefresh) {
      dispatch(
        refreshLowStockProducts({
          businessId,
          status: "low_stock",
          // status: "all",
          pageSize: PAGE_SIZE,
          pageNo: 1,
        })
      );

      dispatch(getInventorySummaryCounts(businessId));
      setFilterType("");
      setIsClearFilter(null);

      setSearchQuery("");
      setIsClearSearch(false);

      setSelectedOndcCategory("All");
      setSelectedOndcSubCat("All");

      return;
    }

    if (filterType === "ondc" && categoryInfo) {
      dispatch(
        filterInventoryOndcProducts({
          businessId,
          pageSize: PAGE_SIZE,
          pageNo: lowStockPageNo,
          ondcMainCat: categoryInfo?.ondcMainCat || "",
          ondcSubCat: categoryInfo?.ondcSubCat || "",
          status: "low_stock",
          tabStatus: "low_stock",
        })
      ).then((res) => {
        if (
          res?.payload?.result?.length == 0 ||
          res?.payload?.result?.length < PAGE_SIZE
        ) {
          setHasMoreData(false);
        }
      });

      return;
    }

    dispatch(
      getAllLowStockProducts({
        businessId,
        status: "low_stock",
        // status: "all",
        pageSize: PAGE_SIZE,
        pageNo: lowStockPageNo,
      })
    ).then((res) => {
      if (
        res?.payload?.details?.length == 0 ||
        res?.payload?.details?.length < PAGE_SIZE
      ) {
        setHasMoreData(false);
      }
    });
  };

  // navigate To Tab
  const navigateToTab = (newValue) => {
    if (newValue === 1) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/outOfStock`);
    }
    if (newValue === 3) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/priceUpdate`);
    }
    if (newValue == 0) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/allStock`);
    }
  };

  // Handle Tab Value Change
  const handleChange = (event, newValue) => {
    const filteredProducts = lowStockProducts?.filter(
      (item) => item?.itemChange
    );
    if (filteredProducts?.length > 0) {
      setErrorMsg(
        `${tabValue == 2 ? "Pricing" : "Inventory"} has been modified for ${
          filteredProducts?.length
        } products. Do you want to save the changes before proceeding ?`
      );
      setErrorModal({ show: true, value: newValue });
      return;
    }

    navigateToTab(newValue);
  };

  // Update inventory
  const updateInventory = (event) => {
    setInventoryErr(false);
    setUpdateLoader(true);

    const filteredList = lowStockProducts?.filter((item) => item?.itemChange);

    if (filteredList?.length == 0) {
      setInventoryErr((prev) => !prev);
      setAnchorEl(event.currentTarget);
      setUpdateLoader(false);

      setTimeout(() => {
        setInventoryErr(false);
      }, 2000);
      return;
    }

    const valididatedData = validateOutOfStockUpdate(lowStockProducts);

    console.log("valididatedData__:", valididatedData);

    if (valididatedData.isError) {
      dispatch(
        updateLowStockInfoList({
          lowStockProducts: valididatedData?.productList,
        })
      );
      setUpdateLoader(false);
      return;
    } else {
      const businessId = sessionStorage.getItem("selectedBusinessId");
      let postDataList = [];
      filteredList?.forEach((item) => {
        postDataList.push({
          productId: item?.productId,
          skuId: "",
          businessId: businessId,
          minOrderQuantity: Number(item?.minOrderQuantity),
          maxOrderQuantity: Number(item?.maxOrderQuantity),
          availableQuantity: Number(item?.availableQuantity),
          alertQuantity: Number(item?.alertQuantity),
          availability: ["Yes"],
          hyperLocal: "Y",
          shipmentDistance: "5000",
          productSalePrice: Number(item?.productSalePrice),
          productOriginalPrice: Number(item?.productOriginalPrice),
          commerceStartDate: moment(item?.commerceStartDate).valueOf(),
          commerceEndDate: moment(item?.commerceEndDate).valueOf(),
          gstInfo: item?.gstInfo,
        });
      });

      console.log("postDataListpostDataList", postDataList);

      updateInventoryData(postDataList, businessId)
        .then(() => {
          console.log("Success");
          setSuccessMsg(
            `Inventory has been updated for ${filteredList?.length} products`
          );
          setOpenSnack(true);
          setUpdateLoader(false);
        })
        .finally(() => {
          setUpdateLoader(false);
          fetchAllLowStockProducts(true);
        });
    }
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setSuccessMsg("");
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  // handle Input Value Change
  const handleInputChange = (value, index) => {
    dispatch(
      handleLowStockUpdateChange({
        lowStockProducts,
        value,
        index,
      })
    );
  };

  // Handle Inventory Product Search
  const handleSearch = () => {
    if (!searchQuery) return;

    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      searchInventoryProducts({
        businessId,
        status: "low_stock",
        pageSize: PAGE_SIZE,
        pageNo: 1,
        tabStatus: "low_stock",
        searchQuery,
      })
    ).then((res) => {
      setFilterType("");
      setIsClearFilter(null);

      setSelectedOndcCategory("All");
      setSelectedOndcSubCat("All");

      setIsClearSearch(true);
    });
  };

  // Apply ONDC Category Filter
  const applyONDCFilter = (categoryData, subCategoryData) => {
    setHasMoreData(true);

    const mainCatCode = categoryData?.ondcCode || "";
    const subCatCode = subCategoryData?.ondcSubCode || "";

    setCategoryInfo((prevState) => ({
      ...prevState,
      ondcMainCat: mainCatCode,
      ondcSubCat: subCatCode,
    }));

    setFilterType("ondc");

    setSearchQuery("");
    setIsClearSearch(false);

    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      filterInventoryOndcProducts({
        businessId,
        pageSize: PAGE_SIZE,
        pageNo: 1,
        ondcMainCat: mainCatCode,
        ondcSubCat: subCatCode,
        status: "low_stock",
        tabStatus: "low_stock",
      })
    ).then((res) => {
      if (
        res?.payload?.result?.length == 0 ||
        res?.payload?.result?.length < PAGE_SIZE
      ) {
        setHasMoreData(false);
      }
    });
  };

  // Select All Rows
  const selectAllRows = () => {
    const selectType = lowStockProducts?.every((item) => item.itemChange);

    dispatch(
      selectAllInventoryProducts({
        tabStatus: "low_stock",
        productList: lowStockProducts,
        selectType: !selectType,
      })
    );
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }

      fetchAllLowStockProducts();

      // dispatch(getProductsByBusiness(businessId));

      // Fetching ONDC Dropdown Data
      if (ondcDropdownData?.length === 0) {
        dispatch(getCategoryDropdownListData(businessId));
      }

      if (!commerceInfo) {
        dispatch(getCommerceInfo(businessId));
      }
    }
    return () => {
      dispatch(clearInventoryInfo());
    };
  }, []);

  return (
    <Box sx={styles.container}>
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          handleClose={handleCloseVideoModal}
          data={INVENTORY_VIDEO_DATA}
        />
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={4000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>

      <DashboardLayout activeMenu="inventory" activeSubMenu="activeInventory">
        <Box sx={styles.content}>
          <Box sx={{ ...stickyHeader, pt: 1.5 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: { md: "center", xs: "flex-start" },
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Typography
                component="h4"
                fontSize={{ md: 17, xs: 15 }}
                color={blackShade05}
                fontWeight={600}
                lineHeight="20px"
              >
                Active Inventory
              </Typography>

              <HowToLinkCard
                link="Inventory Management"
                handleClick={() => {
                  setOpenVideoModal(true);
                }}
              />
            </Box>

            <InventoryTabs tabValue={tabValue} handleChange={handleChange} />

            {!businessProductsLoader &&
            lowStockPageNo != 1 &&
            inventorySummary ? (
              <>
                {Number(inventorySummary?.lowStock) > 0 && (
                  <Box
                    display={{ xs: "none", md: "flex" }}
                    justifyContent="space-between"
                    width={"100%"}
                  >
                    <Box>
                      <Typography
                        fontSize={13}
                        color={greyColor5}
                        display={{ md: "block", xs: "none" }}
                      >
                        The products listed here are ‘Low in Stock’ and will not
                        be orderable soon.{" "}
                      </Typography>

                      <Box display={"flex"} alignItems="center">
                        <Typography
                          fontSize={13}
                          fontWeight={500}
                          color={greyShade168}
                          sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => setInfoModal(true)}
                        >
                          How to update Stock?
                        </Typography>

                        <IconButton onClick={() => setInfoModal(true)}>
                          <InfoRoundedIcon
                            sx={{ fontSize: "18px", color: greyColor9 }}
                          />
                        </IconButton>
                      </Box>
                    </Box>

                    <UpdateInventoryBtn
                      inventoryErr={inventoryErr}
                      anchorEl={anchorEl}
                      updateInventory={updateInventory}
                      updateLoader={updateLoader}
                    />
                  </Box>
                )}

                <Box m={{ xs: "10px 0 10px", md: "6px 0 10px" }}>
                  <Grid container>
                    <Grid item xs={12} md={7}>
                      <Box
                        display="flex"
                        flexDirection={{ xs: "row", md: "column" }}
                        alignItems={{ xs: "center", md: "flex-start" }}
                        justifyContent="space-between"
                      >
                        <Box display="flex" alignItems="center" flex={1}>
                          <Typography
                            component="h4"
                            fontSize={15}
                            color={blackShade05}
                            fontWeight={600}
                            lineHeight={{ xs: "18px", md: "20px" }}
                          >
                            Low Stock
                          </Typography>

                          {!matches && (
                            <IconButton>
                              <InfoRoundedIcon
                                sx={{ fontSize: "18px", color: greyColor9 }}
                                onClick={() => setInfoModal(true)}
                              />
                            </IconButton>
                          )}
                        </Box>

                        {!matches && Number(inventorySummary?.lowStock) > 0 && (
                          <MobileActionBtns
                            onFilterClick={() => {
                              setOpenMobileFilterModel(true);
                            }}
                            isSearchShown={showSearchBar}
                            onSearchClick={() => {
                              setShowSearchBar(!showSearchBar);
                            }}
                          />
                        )}

                        {(isClearSearch || isClearFilter) && matches && (
                          <SearchInfoCard
                            searchQuery={searchQuery}
                            clearSearch={() => {
                              fetchAllLowStockProducts(true);
                              setIsClearSearch(false);
                              setSearchQuery("");
                            }}
                            filterType={filterType}
                            isClearFilter={isClearFilter}
                            data={lowStockProducts}
                          />
                        )}
                      </Box>
                    </Grid>

                    {Number(inventorySummary?.lowStock) > 0 && (
                      <>
                        <Grid item xs={12} md={5}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-end"
                          >
                            {(!matches && showSearchBar) || matches ? (
                              <SearchBoxInput
                                // label="Search"
                                placeholder="Search Product"
                                value={searchQuery}
                                onChange={(text) => {
                                  setSearchQuery(text);
                                }}
                                minWidth={
                                  matches ? "335px" : "calc(100vw - 30px)"
                                }
                                onSearchClick={handleSearch}
                                isDashboardInput
                              />
                            ) : null}

                            {matches && (
                              <Box
                                display="flex"
                                alignItems="flex-start"
                                mt={2}
                              >
                                <InventoryFilterCard
                                  applyONDCFilter={applyONDCFilter}
                                  setIsClearFilter={setIsClearFilter}
                                  setFilterType={setFilterType}
                                  // ONDC Category
                                  ondcDropdownData={ondcDropdownData}
                                  selectedOndcCategory={selectedOndcCategory}
                                  setSelectedOndcCategory={
                                    setSelectedOndcCategory
                                  }
                                  selectedOndcSubCat={selectedOndcSubCat}
                                  setSelectedOndcSubCat={setSelectedOndcSubCat}
                                  refreshProductList={fetchAllLowStockProducts}
                                />
                              </Box>
                            )}
                          </Box>
                        </Grid>

                        {!matches && Number(inventorySummary?.lowStock) > 0 && (
                          <>
                            <Grid item xs={6.5} md={12}></Grid>

                            <Grid item xs={5.5} md={12}>
                              <UpdateInventoryBtn
                                inventoryErr={inventoryErr}
                                anchorEl={anchorEl}
                                updateInventory={updateInventory}
                                updateLoader={updateLoader}
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>

                  {(isClearSearch || isClearFilter) && !matches && (
                    <Box mt={{ xs: 2.5, md: 1.3 }}>
                      <SearchInfoCard
                        searchQuery={searchQuery}
                        clearSearch={() => {
                          fetchAllLowStockProducts(true);
                          setIsClearSearch(false);
                          setSearchQuery("");
                        }}
                        filterType={filterType}
                        isClearFilter={isClearFilter}
                        data={lowStockProducts}
                      />
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <ThreeDotLoader />
            )}
          </Box>

          {!businessProductsLoader &&
            lowStockPageNo != 1 &&
            inventorySummary && (
              <>
                {lowStockProducts?.length == 0 ? (
                  <EmptyCard
                    msg={
                      isClearSearch
                        ? "No matching products found. Please refine your search"
                        : "You have an up to date inventory, No products are low in stock."
                    }
                    icon={<ContentPasteIcon sx={{ fontSize: "24px" }} />}
                    noMargin={!matches}
                  />
                ) : matches ? (
                  <Box sx={styles.tableWrapper}>
                    <TableContainer sx={styles.tableContainer} component={Box}>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow
                            sx={{
                              position: "sticky !important",
                              top: 0,
                              backgroundColor: greyColor42,
                              zIndex: 20,
                              borderRadius: "8px 8px 0 0",
                            }}
                          >
                            {OOS_COLUMN_HEADING_NEW?.map((item, index) => {
                              const selectedRows =
                                getSelectedInventoryRowCount(lowStockProducts);

                              return (
                                <TableCell
                                  key={item.title}
                                  sx={{
                                    ...styles.tableCell,
                                    ...styles.colSticky,
                                  }}
                                >
                                  <Box
                                    display={
                                      index === 0 ? "flex" : "inline-block"
                                    }
                                    alignItems="center"
                                    gap={1.2}
                                  >
                                    {index === 0 && (
                                      <Checkbox
                                        checked={lowStockProducts?.every(
                                          (item) => item.itemChange
                                        )}
                                        onChange={({ target }) => {
                                          selectAllRows();
                                        }}
                                        sx={styles.checkbox}
                                      />
                                    )}
                                    <Box sx={{ fontSize: 12 }}>
                                      {item?.title}{" "}
                                      {selectedRows > 0 &&
                                      item?.title === "PRODUCT NAME" ? (
                                        <span
                                          style={{
                                            fontSize: 11.5,
                                            fontWeight: 400,
                                            color: greyColor5,
                                            marginLeft: "5px",
                                          }}
                                        >
                                          {selectedRows} of{" "}
                                          {inventorySummary?.lowStock} rows
                                          selected
                                        </span>
                                      ) : null}
                                    </Box>
                                  </Box>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>

                        <TableBody sx={{ backgroundColor: whiteColor }}>
                          {lowStockProducts?.map((productInfo, index) => {
                            return (
                              <TableRow
                                key={`Out_Of_Stock_${index}`}
                                sx={{
                                  borderBottom: `1px solid ${borderTextBoxColor}`,
                                  verticalAlign: "top",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    ...styles.tableStickyLeftCell,
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    alignItems="flex-start"
                                    gap={1.2}
                                  >
                                    <Checkbox
                                      sx={{ ...styles.checkbox, mt: 1 }}
                                      checked={productInfo?.itemChange || false}
                                      onChange={() => {
                                        handleInputChange(
                                          {
                                            type: "itemChange",
                                            value: !productInfo?.itemChange,
                                          },
                                          index
                                        );
                                      }}
                                    />
                                    <Box
                                      display="flex"
                                      gap={1.5}
                                      alignItems={"flex-start"}
                                    >
                                      <Box
                                        width="35px"
                                        sx={{
                                          display: { md: "block", xs: "none" },
                                        }}
                                      >
                                        <Box
                                          component="img"
                                          src={
                                            productInfo?.productPhoto?.[0]
                                              ?.docURL
                                          }
                                          sx={styles.imgContainer}
                                        />
                                      </Box>
                                      <Box flex={1}>
                                        <Typography
                                          variant="body1"
                                          title={productInfo?.productName}
                                          sx={{
                                            ...styles.prodNameBox,
                                            ...textOneLines,
                                          }}
                                        >
                                          {productInfo?.productName}
                                        </Typography>

                                        <Box display="flex" gap={1} mt={0.4}>
                                          {productInfo?.productOriginalPrice !==
                                          productInfo?.productSalePrice ? (
                                            <Typography
                                              fontSize={11}
                                              color={blackShade21}
                                              sx={{
                                                textDecoration: "line-through",
                                              }}
                                            >
                                              ₹{" "}
                                              {
                                                productInfo?.productOriginalPrice
                                              }
                                            </Typography>
                                          ) : null}
                                          <Typography
                                            fontSize={12}
                                            fontWeight={600}
                                            color={blueColor04}
                                          >
                                            ₹ {productInfo?.productSalePrice}
                                          </Typography>
                                          <Typography
                                            fontSize={{ md: 12, xs: 12 }}
                                            color={greyShade10}
                                            fontWeight={400}
                                            ml={{ md: 3, xs: 1.5 }}
                                          >
                                            Current Stock:
                                          </Typography>
                                          <Typography
                                            fontSize={{ md: 12, xs: 12 }}
                                            color={blackColor}
                                            fontWeight={600}
                                          >
                                            {productInfo?.currentStock}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </TableCell>

                                <TableCell sx={styles.tableBodyCell(false)}>
                                  <TabelCellInputBox
                                    value={productInfo?.availableQuantity}
                                    onValueChange={(value) => {
                                      handleInputChange(
                                        {
                                          type: "availableQuantity",
                                          value,
                                        },
                                        index
                                      );
                                    }}
                                    regexType="qty"
                                    errorMsg={
                                      productInfo?.errors?.availableQuantity
                                    }
                                  />
                                </TableCell>
                                <TableCell sx={styles.tableBodyCell(false)}>
                                  <TabelCellInputBox
                                    value={productInfo?.alertQuantity}
                                    onValueChange={(value) => {
                                      handleInputChange(
                                        { type: "alertQuantity", value },
                                        index
                                      );
                                    }}
                                    regexType="qty"
                                    errorMsg={
                                      productInfo?.errors?.alertQuantity
                                    }
                                  />
                                </TableCell>
                                <TableCell sx={styles.tableBodyCell(false)}>
                                  <TabelCellInputBox
                                    value={productInfo?.minOrderQuantity}
                                    onValueChange={(value) => {
                                      handleInputChange(
                                        {
                                          type: "minOrderQuantity",
                                          value,
                                        },
                                        index
                                      );
                                    }}
                                    regexType="qty"
                                    errorMsg={
                                      productInfo?.errors?.minOrderQuantity
                                    }
                                  />
                                </TableCell>
                                <TableCell sx={styles.tableBodyCell(false)}>
                                  <TabelCellInputBox
                                    value={productInfo?.maxOrderQuantity}
                                    onValueChange={(value) => {
                                      handleInputChange(
                                        {
                                          type: "maxOrderQuantity",
                                          value,
                                        },
                                        index
                                      );
                                    }}
                                    regexType="qty"
                                    errorMsg={
                                      productInfo?.errors?.maxOrderQuantity
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    ...styles.tableStickyLeftCell,
                                    ...styles.tableStickyRightCell,
                                  }}
                                >
                                  <Box
                                    minWidth="50px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap={1.5}
                                  >
                                    <Stack
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      {productInfo?.errors &&
                                      Object.keys(productInfo?.errors).length >
                                        0 ? (
                                        <Box sx={styles.errorCountTag}>
                                          <Box
                                            sx={{
                                              position: "absolute",
                                              right: -6,
                                              top: -6,
                                            }}
                                          >
                                            <ErrorToolTipIcon errorMsg="Please review all columns for errors" />
                                          </Box>
                                          {
                                            Object.keys(productInfo?.errors)
                                              .length
                                          }
                                        </Box>
                                      ) : (
                                        <CheckCircleIcon
                                          sx={{
                                            fontSize: 16,
                                            color: greenColor,
                                          }}
                                        />
                                      )}
                                    </Stack>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            );
                          })}

                          {lowStockProducts?.length > 0 &&
                            hasMoreData &&
                            !isClearSearch && (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    ...styles.tableStickyLeftCell,
                                    bgcolor: BGColor02,
                                    borderRight: `none`,
                                    boxShadow: "none",
                                  }}
                                  colSpan={7}
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    p={1}
                                  >
                                    <Button
                                      sx={styles.loadMoreBtn}
                                      variant="contained"
                                      disableElevation
                                      onClick={() => fetchAllLowStockProducts()}
                                      startIcon={
                                        lowStockProductsLoader && (
                                          <CircularProgress
                                            size={16}
                                            color="inherit"
                                          />
                                        )
                                      }
                                    >
                                      {lowStockProductsLoader
                                        ? "Loading..."
                                        : "Load More"}
                                    </Button>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ) : (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      p="6px 16px"
                      bgcolor={greyColor42}
                      sx={{
                        position: "sticky",
                        top: { md: "45px", xs: "288px" },
                        zIndex: 100,
                      }}
                    >
                      <Checkbox
                        sx={{
                          ml: -1,
                          ...styles.checkbox,
                          transform: "scale(0.9)",
                        }}
                        checked={lowStockProducts?.every(
                          (item) => item.itemChange
                        )}
                        onChange={({ target }) => {
                         selectAllRows();
                        }}
                        size={"large"}
                      />

                      <Typography
                        fontSize={12.5}
                        fontWeight={500}
                        color={greyColor47}
                      >
                        Select All Inventory{" "}
                        {getSelectedInventoryRowCount(lowStockProducts) > 0 ? (
                          <span
                            style={{
                              fontSize: 11.5,
                              fontWeight: 400,
                              color: greyColor5,
                              marginLeft: "5px",
                            }}
                          >
                            {getSelectedInventoryRowCount(lowStockProducts)} of{" "}
                            {inventorySummary?.lowStock} rows selected
                          </span>
                        ) : null}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        overflow: "hidden",
                        borderRadius: "0 0 12px 12px",
                        border: "1px solid #eaeaea",
                      }}
                    >
                      <Box>
                        <InfiniteScroll
                          dataLength={lowStockProducts?.length || 0}
                          next={() => fetchAllLowStockProducts()}
                          hasMore={
                            hasMoreData && !isClearSearch
                            // Math.ceil(inventorySummary?.lowStock / PAGE_SIZE) >=
                            //   lowStockPageNo && !isClearSearch
                          }
                          loader={
                            lowStockProductsLoader && (
                              <Box>
                                <ThreeDotLoader />
                              </Box>
                            )
                          }
                        >
                          {lowStockProducts?.map((data, index) => {
                            const selectedRows =
                              getSelectedInventoryRowCount(lowStockProducts);

                            return (
                              <OutOfStockCard
                                index={index}
                                productList={lowStockProducts}
                                onSelectAll={() => {
                                  selectAllRows();
                                }}
                                selectedRows={selectedRows}
                                totalCount={inventorySummary?.lowStock}
                                key={`OOS_ITEM_${index}`}
                                img={data?.productPhoto?.[0]?.docURL}
                                title={data?.productName}
                                originalAmt={data?.productOriginalPrice}
                                amt={data?.productSalePrice}
                                alertQty={data?.alertQuantity}
                                avlQty={data?.availableQuantity}
                                minQty={data?.minOrderQuantity}
                                maxQty={data?.maxOrderQuantity}
                                currentStock={data?.currentStock}
                                errors={data?.errorList}
                                isChecked={data?.itemChange}
                                onValueChange={(value) => {
                                  handleInputChange(value, index);
                                }}
                              />
                            );
                          })}
                        </InfiniteScroll>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            )}
        </Box>
      </DashboardLayout>

      <ModalCard
        open={errorModal.show}
        handleClose={() => {
          setErrorModal({ show: false, value: "" });
        }}
        width="29%"
      >
        <Box
          sx={{ backgroundColor: whiteColor, px: 3, py: 2.5, borderRadius: 2 }}
        >
          <Typography fontSize={16} fontWeight={600} color={blackColor}>
            Alert
          </Typography>
          <Typography fontSize={14} mt={2.5}>
            {errorMsg}
          </Typography>
          <Box display="flex" justifyContent={"flex-end"} mt={1.5} gap={2}>
            <Button
              sx={styles.addIconBtn}
              onClick={() => {
                updateInventory();
                setErrorModal({ show: false, value: "" });
              }}
            >
              Yes
            </Button>
            <Button
              sx={styles.fillBtn}
              onClick={() => {
                navigateToTab(errorModal?.value);
                // setTabValue(errorModal.value);
                // setProductsList([]);
                setErrorModal({ show: false, value: "" });
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </ModalCard>
      <ModalCard
        open={infoModal}
        handleClose={() => {
          setInfoModal(false);
        }}
        width="50%"
      >
        <Box
          sx={{ backgroundColor: whiteColor, px: 3, py: 2.5, borderRadius: 2 }}
        >
          <Box>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={greyShade168}
              mb={2}
            >
              How to update Stock?
            </Typography>
            <Box>
              <Typography
                fontSize={14}
                fontWeight={400}
                color={greyColor9}
                display="inline"
                lineHeight={1.5}
              >
                Please update the available quantity
              </Typography>
            </Box>

            {/* <Box mt={1}>
                <Typography
                  fontSize={14}
                  fontWeight={400}
                  color={greyColor9}
                  display="inline"
                  lineHeight={1.5}
                >
                  For stock update -{" "}
                  <Typography
                    fontSize={15}
                    fontWeight={600}
                    color={greyColor9}
                    display="inline"
                    lineHeight={1.5}
                  >
                    ‘Available Quantity’
                  </Typography>{" "}
                  should be at least 3 times more than{" "}
                  <Typography
                    fontSize={15}
                    fontWeight={600}
                    color={greyColor9}
                    display="inline"
                    lineHeight={1.5}
                  >
                    ‘Maximum Order Quantity (per order)’.
                  </Typography>
                </Typography>
              </Box> */}

            <Box mt={1}>
              <Typography
                fontSize={14}
                fontWeight={400}
                color={greyColor9}
                display="inline"
                lineHeight={1.5}
              >
                You can update stock for multiple products and click on{" "}
                <Typography
                  fontSize={15}
                  fontWeight={600}
                  color={greyColor9}
                  display="inline"
                  lineHeight={1.5}
                >
                  ‘UPDATE INVENTORY’
                </Typography>{" "}
                button to save the details.
              </Typography>
            </Box>
          </Box>
        </Box>
      </ModalCard>

      <MobileCategoryFilter
        openModal={openMobileFilterModel}
        setOpenModal={setOpenMobileFilterModel}
        applyONDCFilter={applyONDCFilter}
        setIsClearFilter={setIsClearFilter}
        setFilterType={setFilterType}
        // ONDC Category
        ondcDropdownData={ondcDropdownData}
        selectedOndcCategory={selectedOndcCategory}
        setSelectedOndcCategory={setSelectedOndcCategory}
        selectedOndcSubCat={selectedOndcSubCat}
        setSelectedOndcSubCat={setSelectedOndcSubCat}
        refreshProductList={fetchAllLowStockProducts}
      />
    </Box>
  );
}

export default LowStockTab;

const styles = {
  container: {
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    pt: { xs: 2, md: 0 },
  },

  checkbox: {
    p: 0,
    transform: "scale(1.3)",
    "&.Mui-checked": {
      color: greyColor8,
    },
  },

  addIconBtn: {
    padding: { md: "3px 16px", xs: "3px 14px" },
    fontSize: { md: "13px", xs: "12px" },
    fontWeight: 600,
    color: { xs: mobilePrimary, md: blueColor04 },
    backgroundColor: whiteColor,
    border: {
      xs: `1.5px solid ${mobilePrimary}`,
      md: `1.5px solid ${blueColor04}`,
    },
    "&:hover": {
      color: whiteColor,
      backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    },
  },

  fillBtn: {
    backgroundColor: greyColor8,
    padding: "6px 22px",
    fontSize: "13px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + greyColor8,
    "&:hover": {
      color: greyColor8,
      backgroundColor: whiteColor,
    },
  },

  addIconBtnSmall: {
    backgroundColor: "transparent",
    fontSize: "13px",
    fontWeight: 600,
    color: greyColor8,
    backgroundColor: whiteColor,
    borderRadius: "3px",
    border: "1.5px solid " + greyColor8,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },

  tableWrapper: {
    width: "calc(100vw - 270px)",
    borderRadius: "6px",
    border: `1px solid ${borderTextBoxColor}`,
    // overflowX: "scroll",
    bgColor: whiteColor,
  },

  tableContainer: {
    borderRadius: "8px",
    overflow: "scroll",
    maxHeight: "calc(100vh - 293px)",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      // background: '#d0d3da',
      background: "rgba(0,0,0,0.1)",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.05)",
    },
  },

  tableCell: {
    color: greyColor47,
    fontSize: "12.5px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    padding: "8px 15px",
    backgroundColor: greyColor42,
    borderRight: `1px solid ${whiteColor}`,
  },

  colSticky: {
    // position: "-webkit-sticky",
    // position: isSticky ? "sticky" : "unset",
    // width: isSticky ? "100px" : "auto",
    // minWidth: isSticky ? "100px" : "auto",
    // maxWidth: isSticky ? "100px" : "auto",
    // left: "0px",
    // right: isRight ? "0px" : "unset",

    ":first-child": { position: "sticky", left: "0px" },
    // ":nth-last-child(2)": {
    //   position: "sticky",
    //   right: "78px",
    // },
    ":last-child": { position: "sticky", right: "-3px" },
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  statuSticky: (isStatus) => ({
    right: isStatus ? "100px" : "unset",
  }),

  colStickyRight: (isSticky) => ({
    position: "-webkit-sticky",
    position: isSticky ? "sticky" : "unset",
    width: isSticky ? "100px" : "auto",
    minWidth: isSticky ? "100px" : "auto",
    maxWidth: isSticky ? "100px" : "auto",
    right: "0px",
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  }),

  tableBodyCell: (isError) => ({
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: isError ? lightRedColor : whiteColor,
    padding: "8px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,

    boxShadow: isError ? `0px -1px 0 0 ${redColor} inset` : "none",
  }),

  tableStickyLeftCell: {
    minWidth: "70px !important",
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: whiteColor,
    padding: "10px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,
    position: "sticky",
    left: 0,
    zIndex: 10,
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  tableStickyRightCell: {
    position: "sticky",
    right: 0,
    boxShadow: `1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  imgContainer: {
    borderRadius: "6px",
    border: "1px solid #e5e9f0",
    backgroundColor: whiteColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "35px !important",
    height: "35px",
  },

  prodNameBox: {
    minWidth: "250px",
    maxWidth: "100%",
    whiteSpace: "normal",
    fontSize: 12,
    fontWeight: 600,
    color: blackShade19,
    lineHeight: "16px",
  },

  errorCountTag: {
    backgroundColor: lightRedColor,
    color: redColor,
    fontSize: 12,
    fontWeight: 600,
    p: "3px 18px",
    borderRadius: "10px",
  },

  loadMoreBtn: {
    padding: { md: "3px 14px", xs: "3px 14px" },
    fontSize: { md: "12px", xs: "12px" },
    fontWeight: 500,
    color: whiteColor,
    backgroundColor: { xs: mobilePrimary, md: blackShade22 },
    border: {
      xs: `1.5px solid ${mobilePrimary}`,
      md: `1.5px solid ${blackShade22}`,
    },
    "&:hover": {
      border: {
        xs: `1.5px solid ${mobilePrimary}`,
        md: `1.5px solid ${blackShade22}`,
      },
      color: { xs: mobilePrimary, md: blackShade22 },
      backgroundColor: whiteColor,
    },
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
