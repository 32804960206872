import { Box, Typography } from "@mui/material";
import React from "react";
import { blackColor, border25, whiteColor } from "../../configs/styles/muiThemes";

function EmptyCard({ icon, message, hideBorder }) {
  return (
    <Box
      sx={{
        width:"100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: "15px",
        bgcolor: whiteColor,
        borderRadius:'8px',
        border: hideBorder ? 'none' : border25,
        py:5,
        mt: 3
      }}
    >
      <Box
        component="img"
        src={icon}
        alt="list empty"
        sx={{ width: "70px", height: "auto", objectFit: "contain" }}
      />

      <Typography
        width={{ xs: "auto", md:'800px' }}
        component="h6"
        fontSize={{ xs: 14, md: 15 }}
        color={blackColor}
        fontWeight={500}
        lineHeight="27px"
        mt="12px !important"
        textAlign="center"
      >
        {message}
      </Typography>
    </Box>
  );
}

export default EmptyCard;
