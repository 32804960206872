import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blueColor02,
  blueColor04,
  greyColor20,
  greyColor8,
  menuGradient,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { BGColor02 } from "../../../configs/styles/muiThemes";

function CreatePaycartAlert({ businessName, onCancel, onProceed }) {
  return (
    <Box sx={styles.card}>
      <Typography
        variant="h3"
        fontSize={16}
        fontWeight={600}
        lineHeight="25px"
        color={blackShade05}
        textAlign="center"
        p="10px 50px"
      >
        Create a new Easy Cart
      </Typography>

      <Box
        sx={{
          backgroundColor: whiteColor,
          p: "10px",
        }}
      >
        <Typography
          variant="h6"
          fontSize={14}
          fontWeight={500}
          lineHeight="20px"
          color={blackShade05}
          textAlign="center"
          sx={{ whiteSpace: "pre-line" }}
          m="5px 0 2px 0"
        >
          You will be redirected to your webstore{` \n`}
          <Typography
            component="span"
            sx={{
              fontSize: 14,
              fontWeight: 600,
              color: { md: blueColor04, xs: mobilePrimary },
              lineHeight:"20px"
            }}
          >
            ‘{businessName}’
          </Typography>
          . Please follow the regular order creation process with items in the
          cart, discounts and logistics choice.
        </Typography>
        <Typography
          variant="h6"
          fontSize={14}
          fontWeight={500}
          lineHeight="20px"
          color={blackShade05}
          textAlign="center"
          m="10px 0 2px 0"
        >
          Click on{" "}
          <Typography
            component="span"
            sx={{
              fontSize: 14,
              fontWeight: 600,
              color: { md: blueColor04, xs: mobilePrimary },
              textTransform: "uppercase",
              lineHeight:"20px"
            }}
          >
            ‘Generate Easy Cart link’
          </Typography>{" "}
          at the final page. The payment link generated can be sent to the
          customer for order completion via whatsapp.
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p="15px"
      >
        <Button sx={[styles.actionBtn, styles.cancelBtn]} onClick={onCancel}>
          CANCEL
        </Button>

        <Button sx={[ styles.proceedBtn]} onClick={onProceed}>
          CONTINUE
        </Button>
      </Box>
    </Box>
  );
}

export default CreatePaycartAlert;

const styles = {
  card: {
    backgroundColor: BGColor02,
    borderRadius: "15px",
  },

  actionBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    backgroundColor: whiteColor,
    color: { md: blueColor04, xs: mobilePrimary },
    border: {
      md: `1px solid ${blueColor04}`,
      xs: `1px solid ${mobilePrimary}`,
    },
    p: "4px 20px",
  },

  cancelBtn: {
    "&:hover": {
      color: whiteColor,
      backgroundColor: { md: blueColor04, xs: mobilePrimary },
    },
  },

  proceedBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    color: whiteColor,
    background: { md: blueColor04, xs: mobilePrimary },
    p: "5px 20px",
    border: "none",

    "&:hover": {
      color: { md: blueColor04, xs: mobilePrimary },
      background: whiteColor,
      border: {
        md: `1px solid ${blueColor04}`,
        xs: `1px solid ${mobilePrimary}`,
      },
    },
  },
};
