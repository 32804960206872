import { Box, Typography } from "@mui/material";
import React from "react";
import CatalogActionBtns from "../../../components/Buttons/CatalogActionBtns";
import CategoryPreview from "../Forms/CategoryPreview";
import AddONDCControls from "../Forms/AddONDCControls";
import {
  blackColor,
  blueColor04,
  errorTextColor,
} from "../../../configs/styles/muiThemes";
import { stickyHeader } from "../../../utils/styles";
import BreadcrumbSection from "../../../components/Breadcrumb/BreadcrumbSection";

function ProductONDCTab({
  categoryData,
  productInfo,
  onNextClick,
  onBackClick,
  ondcData,
  setOndcData,
  onValueChange,
  ondcSpecificationData,
  setOndcSpecificationData,
  productCodeList,
  unitOfMeasurementData,
  title,
  titleFrom,
  links,
  handleCancel,
  pageError,
}) {
  const handleKeyValueChange = (property, value, rootProperty, errCode) => {
    setOndcData((prevState) => ({
      ...prevState,
      [rootProperty]: { ...prevState[rootProperty], [property]: value },
      errors: prevState?.errors
        ? { ...prevState?.errors, [errCode]: "" }
        : null,
    }));
  };
  return (
    <Box mt={{ md: 0, xs: 1 }}>
      <Box
        sx={{
          ...stickyHeader,
          display: { md: "block", xs: "none" },
          pb: 1,
        }}
      >
        <BreadcrumbSection links={links} />
        <Typography
          component="h6"
          fontSize={{ xs: 15, md: 17 }}
          color={blackColor}
          fontWeight={{ xs: 600, md: 600 }}
          lineHeight="20px"
          display="inline"
        >
          {titleFrom && <span style={{ color: blueColor04 }}>{titleFrom}</span>}
          {title}
        </Typography>
        <CategoryPreview
          categoryData={categoryData}
          productInfo={productInfo}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        <CategoryPreview
          categoryData={categoryData}
          productInfo={productInfo}
        />
      </Box>

      <AddONDCControls
        ondcSpecificationData={ondcSpecificationData}
        setOndcSpecificationData={setOndcSpecificationData}
        ondcData={ondcData}
        productCodeList={productCodeList}
        onValueChange={(name, value) =>
          onValueChange(name, value, ondcData, setOndcData)
        }
        handleKeyValueChange={handleKeyValueChange}
        unitOfMeasurementData={unitOfMeasurementData}
      />
      <Box>
        <CatalogActionBtns
          nextBtnText={"PREVIEW & SUBMIT"}
          showBackBtn
          hideSaveBtn
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          onCancelClick={handleCancel}
        />
        {pageError ? (
          <Typography
            fontSize={12}
            fontWeight={500}
            mt={1}
            textAlign="right"
            color={errorTextColor}
          >
            Please fix the errors above to proceed
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
}

export default ProductONDCTab;
