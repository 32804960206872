import { Box, IconButton } from "@mui/material";
import React from "react";
import { greyColor9, whiteColor } from "../../../configs/styles/muiThemes";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";

const MobileActionBtns = ({ onFilterClick, onSearchClick, isSearchShown }) => {
  return (
    <Box display="flex" alignItems="center" gap={1.5}>
      <IconButton sx={styles.btn} onClick={() => onFilterClick()}>
        <FilterListIcon sx={{ fontSize: "18px" }} />
      </IconButton>

      <IconButton sx={styles.btn} onClick={() => onSearchClick()}>
        {isSearchShown ? (
          <SearchOffIcon sx={{ fontSize: "18px" }} />
        ) : (
          <SearchIcon sx={{ fontSize: "18px" }} />
        )}
      </IconButton>
    </Box>
  );
};

export default MobileActionBtns;

const styles = {
  btn: {
    height: "28px",
    bgcolor: whiteColor,
    borderRadius: "5px",
    border: `1px solid ${greyColor9}`,
  },
};
