import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import InputPreview from "../../components/Input/InputPreview";
import { blackColor, whiteColor } from "../../configs/styles/muiThemes";
import {
  capitalizeString,
  getVolumetricWeightInKg,
  mmToCm,
  mmToInches,
} from "../../utils/helper";

function ProductMeasurementPreview({
  weight,
  length,
  width,
  height,
  deliveryMethod,
  ondcData,
  unitOfmeasure,
  netProductWeight,
  viewProduct,
}) {
  return (
    <Box sx={styles.section}>
      <Typography
        component="h5"
        fontSize={{ xs: 15, md: 18 }}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        m="5px 0 20px !important"
      >
        Product Measurement
      </Typography>
      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        <Grid item xs={6} md={3}>
          <InputPreview
            label="Unit of measure"
            value={
              !viewProduct
                ? ondcData?.productDisplayUnitOfMeasure?.unit === "gms"
                  ? "grams"
                  : capitalizeString(
                      ondcData?.productDisplayUnitOfMeasure?.unit
                    )
                : unitOfmeasure?.unit === "gms"
                ? "grams"
                : capitalizeString(unitOfmeasure?.unit)
            }
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <InputPreview
            label="Value"
            value={
              !viewProduct
                ? ondcData?.productDisplayUnitOfMeasure?.value
                : unitOfmeasure?.value
            }
          />
        </Grid>
      </Grid>

      <Box mt={2.5}>
        <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
          <Grid item xs={8} md={3}>
            <InputPreview label="Net Product Weight (in gms)" value={netProductWeight} />
          </Grid>

          <Grid item xs={4} md={3}>
            <InputPreview
              label="In kgs"
              value={(Number(netProductWeight) / 1000).toFixed(3)}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <InputPreview label="Shipping Weight" value={weight} />
          </Grid>
        </Grid>
      </Box>

      <Box mt={2.5}>
        <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <InputPreview label="Product Length (in mm)" value={length} />
          </Grid>

          <Grid item xs={6} md={3}>
            <InputPreview label="In cm" value={mmToCm(length)} />
          </Grid>

          <Grid item xs={6} md={3}>
            <InputPreview label="In inches" value={mmToInches(length)} />
          </Grid>
        </Grid>
      </Box>

      <Box mt={2.5}>
        <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <InputPreview label="Product Width (in mm)" value={width} />
          </Grid>

          <Grid item xs={6} md={3}>
            <InputPreview label="In cm" value={mmToCm(width)} />
          </Grid>

          <Grid item xs={6} md={3}>
            <InputPreview label="In inches" value={mmToInches(width)} />
          </Grid>
        </Grid>
      </Box>

      <Box mt={2.5}>
        <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <InputPreview label="Product Height (in mm)" value={height} />
          </Grid>

          <Grid item xs={6} md={3}>
            <InputPreview label="In cm" value={mmToCm(height)} />
          </Grid>

          <Grid item xs={6} md={3}>
            <InputPreview label="In inches" value={mmToInches(height)} />
          </Grid>
        </Grid>
      </Box>

      <Box mt={2.5}>
        <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <InputPreview
              label="Volumetirc weight (in kg)"
              value={getVolumetricWeightInKg(
                mmToCm(length),
                mmToCm(width),
                mmToCm(height)
              )}
            />
          </Grid>
        </Grid>
      </Box>

      {/* <Typography
        component="h6"
        fontSize={15}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        m="20px 0 15px !important"
      >
        Delivery Method
      </Typography>

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        <Grid item xs={12} md={12}>
          <InputPreview label="Delivery Method" value={deliveryMethod} />
        </Grid>
      </Grid> */}
    </Box>
  );
}

export default ProductMeasurementPreview;

const styles = {
  section: {
    p: "10px 20px 35px",
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: "13px",
    mt: { xs: "25px" },
  },
};
