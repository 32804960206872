import moment from "moment";
import { getProfileId } from "../validationUtils";

export const prepareAddNewBranchData = (data, businessId) => {
  const phoneNumber = "+91" + data?.phoneNumber;
  return {
    name: data?.branchName || "",
    masterBranchId: businessId || "",
    locationType: "branch",
    gstNumber: data?.gstNumber || "",
    panNumber: data?.panNumber || "",
    businessMailingAddress: data?.email || "",
    status: "pending",
    latitude: data?.lat || "",
    longitude: data?.lng || "",
    contactNos: [
      {
        code: "Mobile1",
        number: phoneNumber,
      },
    ],
    delegates: [],

    businessArea: [
      {
        code: "Country",
        value: "India",
      },
      {
        code: "State",
        value: data?.state || "",
      },
      {
        code: "City",
        value: data?.city || "",
      },
      {
        code: "pincode",
        value: data?.pinCode || "",
      },
      {
        code: "subAdminArea",
        value: "",
      },
      {
        code: "featurename",
        value: "",
      },
      {
        code: "locality",
        value: data?.landmark || data?.locality,
      },
      {
        code: "sublocality",
        value: data?.sublocality || data?.locality,
      },
      {
        code: "premises",
        value: "",
      },
      {
        code: "subThroughFare",
        value: "",
      },
      {
        code: "ThroughFare",
        value: "",
      },
      {
        code: "countryCode",
        value: "",
      },
      {
        code: "addressline one",
        value: data?.address || "",
      },
      {
        code: "addressline two",
        value: "",
      },
    ],
  };
};

export const prepareUpdateBusinessData = (data, branchId, businessId) => {
  const profileId = getProfileId();
  return {
    businessId,
    branchInfos: [
      {
        branchId,
        businessName: data?.branchName || "",
        city: data?.city || "",
        pincode: data?.pinCode || "",
        status: "Active",
        createdOn: moment().format("YYYY-MM-DDTHH:mm:ss[Z]") || "",
        updatedOn: moment().format("YYYY-MM-DDTHH:mm:ss[Z]") || "",
        addedBy: profileId,
        updatedBy: profileId,
        inactiveDate: null,
      },
    ],
  };
};
