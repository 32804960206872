import { Box, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FilePickerInput from "../../components/Input/FilePickerInput";
import { convertImagesToBase64 } from "../../utils/imageCropUtils/imgUrlToBase64";

function NewBusinessBannerListDND({
  myBusinessData,
  businessDetails,
  bannerImgDataList,
  setBannerImgDataList,
  deletedBannerIndexList,
  setDeletedBannerIndexList,
}) {
  const [dragDropList, setDragDropList] = useState([]);

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const onDragComplete = (result) => {
    if (!result.destination) return;
    if (result?.destination?.index === result?.source?.index) return;

    console.log(result);

    const bannerDNDArray = [...dragDropList];

    const sourceData = bannerImgDataList?.[result?.source?.index];
    const destinationDate = bannerImgDataList?.[result?.destination?.index];

    if (
      sourceData?.base64String !== "" &&
      destinationDate?.base64String !== ""
    ) {
      console.log("SAFE__");

      let removedBannerItem = bannerDNDArray.splice(result.source.index, 1)[0];
      bannerDNDArray.splice(result.destination.index, 0, removedBannerItem);

      console.log("bannerDNDArray:", bannerDNDArray);

      setBannerImgDataList(bannerDNDArray);
      setDragDropList(bannerDNDArray);
    }
  };

  const onBannerAdded = (dataURL, index) => {
    const bannerDNDArray = [...dragDropList];

    bannerDNDArray[index] = {
      ...bannerDNDArray[index],
      base64String: dataURL,
      update: true,
      deleted: false,
    };

    console.log("onBannerAdded:", bannerDNDArray);

    setBannerImgDataList(bannerDNDArray);
    setDragDropList(bannerDNDArray);
  };

  const clearBanner = (index) => {
    const newBannerCopy = [...bannerImgDataList];
    newBannerCopy[index] = {
      ...newBannerCopy[index],
      base64String: "",
      update: false,
      deleted: true,
    };

    console.log("clearBanner:", newBannerCopy);
    setBannerImgDataList(newBannerCopy);
    setDragDropList(newBannerCopy);
  };

  const onBannerDelete = (banner, index) => {
    if (banner?.docType && !banner?.update) {
      const isPresent = deletedBannerIndexList?.filter(
        (item) => item?.docType === banner?.docType
      );

      if (isPresent?.length > 0) return;

      console.log("deletedBannerIndexList", [
        ...deletedBannerIndexList,
        banner,
      ]);
      setDeletedBannerIndexList([...deletedBannerIndexList, banner]);
      clearBanner(index);
    } else {
      clearBanner(index);
    }
  };

  useEffect(() => {
    if (businessDetails) {
      setDragDropList(businessDetails?.banners);
    }
    return () => {};
  }, [businessDetails]);

  return (
    <Box mt="-18px">
      {/* <button
        onClick={() => {
          console.log("bannersDND_BTN:", businessDetails?.banners);
          console.log("bannersDelete_BTN:", deletedBannerIndexList);
        }}
      >
        click
      </button> */}

      <DragDropContext onDragEnd={onDragComplete}>
        <Droppable
          droppableId="drag-drop-list"
          direction={matches ? "horizontal" : "vertical"}
        >
          {(provided) => (
            <Box
              sx={{
                padding: "20px 0",
                // background: "black",
                display: "flex",
                alignItems: "center",
                flexDirection: { md: "row", xs: "column" },
                gap: { md: "25px", xs: "6px" },
                // width: { md: "auto", xs: "100%" },
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {dragDropList.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={JSON.stringify(item?.key)}
                  index={index}
                >
                  {(provided) => (
                    <div
                      className="dnd-item-card"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <FilePickerInput
                        label=""
                        ratio={4 / 3}
                        cropedimage={item?.base64String}
                        setCropedImage={(dataURL) => {
                          console.log("CropedImgDND:", bannerImgDataList, item);
                          onBannerAdded(dataURL, index);
                        }}
                        setLogoChange={() => {}}
                        onDelete={() => {
                          onBannerDelete(item, index);
                        }}
                        hideLabel
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}

export default NewBusinessBannerListDND;
