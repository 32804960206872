import { Box, Typography } from '@mui/material'
import React from 'react'
import {  blackShade05, blueColor02, blueColor04, greyColor24, greyColor8, logoImgborder1, whiteColor, yellowColor } from '../../configs/styles/muiThemes';

function WebsiteIntegrationContent({ activeBusinessInfo }) {
    const whatsAppNumber = "+918088422615";
    const emailAddress = "support@costbo.com";
    const handleChatClick = () => {
      if (whatsAppNumber) {
        window.open(
          `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${activeBusinessInfo?.name}+- request for D2C website integration-+`,
          "_blank"
        );
      } else {
        window.fcWidget.open();
        window.fcWidget.show();
      }
    };
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      gap={3}
      mt={3}
    >
      <Box width={{ md: "55%", xs: "100%" }} textAlign="center">
        <Typography
          sx={{
            color: greyColor24,
            fontSize: { md: "24px", xs: "17px" },
            fontWeight: 600,
            textAlign: "center",
            mb: "1px",
            display: "inline",
            lineHeight: 1.5,
          }}
        >
          Super charge your existing/new brand website in{" "}
          <Typography
            variant="h6"
            color={blueColor04}
            fontSize={{ md: "24px", xs: "17px" }}
            fontWeight="600"
            textAlign="center"
            display="inline"
            lineHeight={1.5}
          >
            JUST FEW HOURS
          </Typography>{" "}
          with seamless Order Management System (OMS) integration powered by
          COSTBO
        </Typography>
      </Box>
      <Box
        mt={{ md: 3, xs: 1 }}
        display="flex"
        // alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={3}
      >
        <Box
          display="flex"
          gap={3}
          width="100%"
          justifyContent="center"
          flexWrap="wrap"
        >
          <TabCards label="Lightning-fast loading catalogs" />
          <TabCards label="Express Checkout" />
          <TabCards label="Instant Payment Processing" />
          <TabCards label="In-build Shipping Integration" />
          <TabCards label="Discount & Loyalty points system" />
        </Box>
      </Box>
      <Box mt={8}>
        <Box sx={styles.videoBox}>
          <Box
            component="img"
            width={{ md: "auto", xs: "100%" }}
            height={{ md: "400px", xs: "auto" }}
            src="https://storage.googleapis.com/bodefaults/businessdashboardweb/web-video.gif"
            sx={{ borderRadius: "12px", zIndex: 1 }}
          ></Box>
        </Box>
      </Box>
      <Box textAlign="center" mt={6}>
        <Typography
          variant="h6"
          color={blackShade05}
          fontSize="15px"
          fontWeight="500"
          display="inline"
        >
          To get started contact COSTBO team via{" "}
          <Typography
            // variant="body1"
            fontSize={14}
            fontWeight={500}
            color={blueColor04}
            display="inline"
            // onclick={handleChatClick}
            component="a"
            href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=${activeBusinessInfo?.name}+- request for D2C website integration.+&fs=1&to=${emailAddress}&tf=cm`}
            target="_blank"
          >
            Email
          </Typography>
          {"  "}
          or{" "}
          <Typography
            // variant="body1"
            fontSize={14}
            fontWeight={500}
            color={blueColor04}
            display="inline"
            onClick={handleChatClick}
          >
            Whatsapp
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
}

export default WebsiteIntegrationContent

const TabCards =({label})=>{
    return (
      <Box
        sx={{
            width:{md:"28%",xs:"100%"},
          border: logoImgborder1,
         padding: "8px",
          display: "flex",
          flexDirection: "row",
          background: whiteColor,
          borderRadius: "25px",
          gap: "10px",
          alignItems:"center",
          justifyContent:"center",

    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
        }}
      >
        <Box sx={styles.iconCircle}></Box>
        <Typography
          sx={{ fontSize: "16px", fontWeight: 600, color: blackShade05 }}
        >
          {label}
        </Typography>
      </Box>
    );
}

const styles = {
  videoBox: {
    position: "relative",
    display: "flex",
    justifyContent: "center",

    "&::after": {
      content: '""',
      position: "absolute",
      width: { md: "225px", xs: "100px" },
      height: { md: "225px", xs: "100px" },
      backgroundColor: "rgb(47 85 212 / 18%)",
      borderRadius: "100%",
      zIndex: 0,
      top: {md:"-44px",xs:"-30px"},
      left: "0",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      width: { md: "225px", xs: "100px" },
      height: { md: "225px", xs: "100px" },
      backgroundColor: "rgb(253 202 73 / 30%)",
      borderRadius: "100%",
      zIndex: 0,
      bottom: {md:"-34px",xs:"-26px"},
      right: "0",
    },
  },

  iconCircle: {
    width: "8px",
    height: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: yellowColor,
    borderRadius: "50%",
    //padding:1,
    mt: 0.2,
  },
};