import { Box, Typography } from "@mui/material";
import { greyColor6 } from "../../configs/styles/muiThemes";
import RadiosBtnWithLabel from "../Input/RadiosBtnWithLabel";

const RadioOptionWithLabel = ({
  label,
  required,
  value,
  onYesClick,
  onNoClick,
  error,
  disabled,
  disableYes
}) => {
  return (
    <Box>
      <Typography
        component="label"
        fontSize={{ xs: 12.5, md: 13 }}
        color={greyColor6}
        fontWeight={400}
        display="inline"
      >
        {label}{" "}
        {required && (
          <Typography variant="body1" sx={{ display: "inline", color: "#f00" }}>
            *
          </Typography>
        )}
      </Typography>

      <Box>
        <RadiosBtnWithLabel
          label="Yes"
          checked={value === "Yes"}
          onValuesChanged={onYesClick}
          labelStyles={{
            color: greyColor6,
            fontSize: 13,
          }}
          disabled={disabled || disableYes}
        />

        <RadiosBtnWithLabel
          label="No"
          checked={value === "No"}
          onValuesChanged={onNoClick}
          labelStyles={{
            color: greyColor6,
            fontSize: 13,
          }}
          disabled={disabled}
        />
      </Box>

      {error ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={12}
          sx={{ mt: "4px !important", textAlign: "start !important" }}
          style={{ color: "red" }}
        >
          {error}
        </Typography>
      ) : null}
    </Box>
  );
};

export default RadioOptionWithLabel;
