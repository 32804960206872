import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllBusinessPaycartOrdersApi } from "../../service/api_calls/paycartOrders/PaycartOrdersAPI";

const initialState = {
  businessPaycartOrders: [],
  pageNo: 1,

  businessPaycartOrdersLoader: false,
};

// Get All Business Paycart Order Data
export const getAllBusinessPaycartOrderData = createAsyncThunk(
  "getAllBusinessPaycartOrderData",
  async (param, thunkAPI) => {
    const res = await getAllBusinessPaycartOrdersApi(param);
    return res;
  }
);

export const businessPaycartOrders = createSlice({
  name: "businessPaycartOrders",
  initialState,
  reducers: {
    clearPaycartOrderInfo: (state, action) => {
      state.businessPaycartOrders = [];
      state.pageNo = 1;
      state.businessPaycartOrdersLoader = false;
    },
  },
  extraReducers: {
    //fetch business paycart orders
    [getAllBusinessPaycartOrderData.pending]: (state) => {
      state.businessPaycartOrdersLoader = true;
    },
    [getAllBusinessPaycartOrderData.fulfilled]: (state, { payload }) => {
      // state.businessPaycartOrders = payload;
      console.log("paycart-orders", payload);
      const paycartData = payload?.details || [];
      if (paycartData.length > 0) {
        state.businessPaycartOrders = [
          ...state.businessPaycartOrders,
          ...paycartData,
        ];
        state.pageNo = state.pageNo + 1;
      }
      state.businessPaycartOrdersLoader = false;
    },
    [getAllBusinessPaycartOrderData.rejected]: (state, { payload }) => {
      state.businessPaycartOrdersLoader = false;
    },
  },
});

export const { clearPaycartOrderInfo } = businessPaycartOrders.actions;

export const businessPaycartOrdersReducer = businessPaycartOrders.reducer;
