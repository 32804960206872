import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import {
  blackColor,
  blackShade159,
  blackShade22,
  blueColor04,
  blueColor1,
  greyColor24,
  greyColor40,
  greyColor9,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import InputPreview from "../../../components/Input/InputPreview";
import { useNavigate } from "react-router-dom";
import {
  ECOMMERCE_VIDEO_DATA,
  E_COMMERCE_STEP_LIST,
} from "../../../utils/data";
import { scrollBox, stickyHeader } from "../../../utils/styles";
import HowToLinkCard from "../../../components/Cards/HowToLinkCard";
import VideoModal from "../../../components/VideoModal";

function OtherDetailsContent({ commerceDetails }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [tabValue, setTabValue] = useState(3);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const navigate = useNavigate();
  const navigateToTab = (newValue) => {
    if (newValue === 0) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/warehouseDetail`);
    }
    if (newValue === 1) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/deliveryMethods`);
    }
    if (newValue == 2) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/discountDetails`);
    }
    if (newValue == 3) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/otherDetails`);
    }
    if (newValue == 4) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/managers`);
    }
    if (newValue == 5) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/bankInfo`);
    } 
  };

  const handleChange = (event, newValue) => {
    navigateToTab(newValue);
  };

  const handleEditDetails = () => {
    navigate(`/editCommerceInfo`);
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  return (
    <Box flex={1}>
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          handleClose={handleCloseVideoModal}
          data={ECOMMERCE_VIDEO_DATA}
        />
      )}
      <Box sx={stickyHeader} pb={1.5}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={{ xs: "row", md: "row" }}
          mb="10px"
        >
          <Typography
            variant="h2"
            fontSize={{ md: 17, xs: 17 }}
            fontWeight={600}
            color={greyColor24}
            lineHeight="28px"
          >
            E-Commerce Controls
          </Typography>
          <HowToLinkCard
            link="E-Commerce Controls"
            handleClick={() => {
              setOpenVideoModal(true);
              // navigate("/howToVideoGuides", {
              //   state: { status: "eCommerceControls" },
              // });
            }}
            justifyContent="flex-start"
          />
        </Box>
        <Box
          sx={{
            ...scrollBox,
            display: "block",
            borderBottom: 1,
            borderColor: "divider",
            mb: { xs: "10px", md: 1 },
          }}
        >
          <Tabs
            variant="scrollable"
            value={tabValue}
            onChange={(event, newValue) => handleChange(event, newValue)}
            aria-label="basic tabs example"
            textColor={blackColor}
            TabIndicatorProps={{
              style: {
                height: "3px",
                backgroundColor: matches ? blueColor04 : mobilePrimary,
              },
            }}
            sx={{
              minHeight: "35px !important",
              "& .MuiButtonBase-root": {
                minWidth: "auto !important",
              },
            }}
          >
            {E_COMMERCE_STEP_LIST?.map((item, index) => {
              return (
                <Tab
                  key={"filter_" + index}
                  label={item.label}
                  // label={item.label}
                  sx={{
                    fontSize: { xs: "13px", md: "13.5px" },
                    fontWeight: tabValue == index ? 500 : 500,
                    p: 0,
                    minHeight: "30px !important",
                    mr: { xs: "20px", md: "55px" },
                    color:
                      item.id === tabValue
                        ? matches
                          ? blueColor04
                          : mobilePrimary
                        : greyColor40,
                    textTransform: "capitalize",
                  }}
                />
              );
            })}
          </Tabs>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h4"
            fontSize={15}
            fontWeight={600}
            color={greyColor24}
            lineHeight="28px"
            mb="4px"
          >
            Returns & Other Info
          </Typography>

          <Button onClick={handleEditDetails} sx={styles.editBtn}>
            EDIT
          </Button>
        </Box>
      </Box>
      <Box sx={styles.card}>
        {/* <Typography
          component="h5"
          fontSize={15}
          color={greyColor24}
          fontWeight={600}
          lineHeight="20px"
          mt="5px !important"
        >
          Bank Detail
        </Typography> */}

        <Box mt="16px">
          <Grid container>
            <Grid item xs={12} md={4}>
              <InputPreview
                label="Minimum Order Amount"
                value={`₹ ${
                  commerceDetails?.orderInfoDetails?.[0]?.minOrderAmount || "0"
                }`}
                valueStyles={{ p: "5px 0 0px 0" }}
                hideBorder
              />
            </Grid>
          </Grid>

          <Grid container mt={1}>
            <Grid item xs={12} md={12}>
              <InputPreview
                label="Return Policy"
                value={commerceDetails?.returnPolicy}
                valueStyles={{ p: "5px 0 0px 0" }}
                hideBorder
              />
            </Grid>
          </Grid>

          <Grid container mt={1}>
            <Grid item xs={12} md={12}>
              <InputPreview
                label="Special Notes"
                value={commerceDetails?.notes}
                valueStyles={{ p: "5px 0 0px 0" }}
                hideBorder
              />
            </Grid>
          </Grid>

          {/* <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            alignItems="center"
            my={4}
          >
            <Box sx={styles.iconContainer}>
              <AccountBalanceOutlinedIcon sx={{ fontSize: "24px" }} />
            </Box>

            <Typography variant="body2" sx={styles.noDataText}>
              Coming Soon
            </Typography>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}

export default OtherDetailsContent;

const styles = {
  card: {
    p: { xs: "10px 15px", md: "10px 20px 20px" },
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: { xs: "8px", md: "13px" },
    mt: { xs: "5px", md: "0px" },
  },

  iconContainer: {
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "25px",
    backgroundColor: "#e5e8ee",
  },

  noDataText: {
    fontSize: 14,
    fontWeight: 500,
    color: greyColor24,
    lineHeight: "24px",
  },

  editBtn: {
    backgroundColor: blackShade22,
    padding: "2px 14px",
    fontSize: "12.5px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + blackShade22,
    "&:hover": {
      color: blackShade22,
      backgroundColor: whiteColor,
    },
  },
};
