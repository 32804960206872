import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Chip,
  Tabs,
  Tab,
  Button,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import EmptyCard from "../components/Alerts/EmptyCard";
import SearchBoxInput from "../components/Input/SearchBoxInput";
import SelectWithLabel from "../components/Input/SelectWithLabel";
import {
  BGColor02,
  blackColor,
  blackShade05,
  blackShade19,
  blackShade21,
  blackShade22,
  blueColor04,
  border25,
  greyColor33,
  greyColor39,
  greyColor40,
  greyColor42,
  greyColor49,
  greyColor9,
  heroSectionText,
  lightRedColor02,
  logoImgborder,
  logoImgborder1,
  mobilePrimary,
  outOfStockColor,
  whiteColor,
} from "../configs/styles/muiThemes";
import {
  clearActiveBusinessProducts,
  clearCatalogData,
  clearSearchProducts,
  getActiveBusinessInfo,
  getActiveProductsByBusiness,
  getBusinessPendingProducts,
  getBusinessRejectsProducts,
  getBusinessUpdatedPendingProducts,
  getCommerceInfo,
  getONDCCategories,
  getProductCategories,
  getProductsByBusiness,
  searchBusinessProducts,
} from "../reducers/businessPreview/businessPreviewSlice";
import AddIcon from "@mui/icons-material/Add";
import {
  activeProductEmptyMsg,
  pendingProductEmptyMsg,
  rejectedProductEmptyMsg,
} from "../configs/Constants";
import { getUserEditEligibility } from "../reducers/businessOnboard/businessOnboardSlice";
import { isUserEditEligible } from "../utils/validationHelper";
import ThreeDotLoader from "../components/ThreeDotLoader";
import DeleteProductModalContent from "./ProductList/DeleteProductModalContent";
import ModalCard from "../components/ModalCard";
import { updateProductStatusApi } from "../service/api_calls/catalog/CatalogAPI";
import { scrollBox, stickyHeader } from "../utils/styles";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import HowToLinkCard from "../components/Cards/HowToLinkCard";
import VideoModal from "../components/VideoModal";
import {
  ACTIVE_PRODUCT_STEPLIST,
  CATALOG_VIDEO_DATA,
  NEW_PRODUCT_STEPLIST,
} from "../utils/data";
import { getStatsCountInfoData } from "../reducers/businessDashboard/BusinessDashboardSlice";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import ProductNewlist from "./newCatalog/ProductNewlist";

import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { getONDCProductsAPI } from "../service/api_calls/BusinessPreview/BusinessPreviewAPI";
import FilterCard from "./newCatalog/FilterCard";
import { dynamicSort } from "../utils/helper";
import PillTabs from "../components/PillTabs";
import SubBranchCalalog from "./SubBranchCalalog";

function ProductcatalogList({ status }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const location = useLocation();
  // const productStatus = location.state?.productStatus || "active";
  const productStatus = status;

  const [productCountSalesInfo, setProductCountSalesInfo] = useState(null);

  const [ondcProductCount, setOndcProductCount] = useState(0);

  const [tabValue, setTabValue] = useState(0);
  const stepList = [
    { id: 0, label: "ACTIVE PRODUCTS", mobileLabel: "ACTIVE" },
    { id: 1, label: "NEW PRODUCTS", mobileLabel: "PENDING (NEW)" },
    // {
    //   id: 2,
    //   label: "PENDING APPROVAL(EXISTING)",
    //   mobileLabel: "PENDING (EXISTING)",
    // },
    // { id: 3, label: "REJECTED(NEW)", mobileLabel: "REJECTED (NEW)" },
    // { id: 4, label: "REJECTED(EXISTING)", mobileLabel: "REJECTED(EXISTING)" },
  ];

  const [productFilteredData, setProductFilteredData] = useState({
    active: [],
    pendingNew: [],
    pendingExisting: [],
    rejectedNew: [],
    rejectedExisting: [],

    searchPrev: [],
  });

  const [productsList, setProductsList] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [isClearSearch, setIsClearSearch] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);

  const [ondcCategoryList, setOndcCategoryList] = useState([]);
  const [ondcSubCatList, setOndcSubCatList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedSubCat, setSelectedSubCat] = useState("All");

  const [selectedOndcCategory, setSelectedOndcCategory] = useState("All");
  const [selectedOndcSubCat, setSelectedOndcSubCat] = useState("All");

  const [isProductsEditable, setIsProductsEditable] = useState(false);

  const [isShowDeleteAlter, setIsShowDeleteAlter] = useState({
    show: false,
    data: null,
    loader: false,
    deleteComplete: false,
  });

  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);

  const [showONDCFilter, setShowONDCFilter] = useState(false);
  const [showWebstoreFilter, setShowWebstoreFilter] = useState(false);

  const [isClearFilter, setIsClearFilter] = useState(null);
  const [filterError, setFilterError] = useState("");

  const [activeTab, setActiveTab] = useState("active");

  const [listLoader, setListLoader] = useState(true);

  const ondcFilterRef = useRef(null);
  const webstoreFilterRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    activeBusinessInfo,
    businessProducts,
    searchProducts,
    productCategory,
    commerceInfo,
    pendingProducts,
    updatedPendingProducts,
    rejectedProducts,
    businessProductsLoader,
    searchProductsLoader,
    productsByStatusLoader,
    activeBusinessProductsLoader,
    activeBusinessProducts,
    totalProductCount,
    ondcCategory,
  } = useSelector((state) => state.businessPreview);

  const { editEligibility } = useSelector((state) => state.businessOnboard);

  const { statsCountInfo } = useSelector((state) => state.businessDashboard);

  // Filter products based on status
  const filterProducts = (status) => {
    const filteredList = businessProducts?.productList?.filter(
      (item) => item?.status === status
    );

    return filteredList;
  };

  // handle Tab switch
  const handleChange = (event, newValue) => {
    // dispatch(clearCatalogData());
    dispatch(clearActiveBusinessProducts());
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    clearFilter();
    clearSearch();

    if (newValue === 0) {
      navigate(`/${businessUrl}/catalog`);
    } else if (newValue === 1) {
      navigate(`/${businessUrl}/catalogPending`);
    } else if (newValue === 2) {
      navigate(`/${businessUrl}/catalogPendingExisting`);
    } else if (newValue === 3) {
      navigate(`/${businessUrl}/catalogRejected`);
    } else if (newValue === 4) {
      navigate(`/${businessUrl}/catalogRejectedExisting`);
    } else {
    }

    setSelectedCategory("All");
    setSelectedSubCat("All");

    setTabValue(newValue);
  };

  const ontabClick = (data) => {
    // dispatch(clearCatalogData());
    dispatch(clearActiveBusinessProducts());
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    clearFilter();
    clearSearch();

    if (data?.value === "active") {
      navigate(`/${businessUrl}/catalog`);
    } else if (data?.value === "pendingApprovalNew") {
      navigate(`/${businessUrl}/catalogPending`);
    } else if (data?.value === "pendingApproval") {
      navigate(`/${businessUrl}/catalogPendingExisting`);
    } else if (data?.value === "rejectedNew") {
      navigate(`/${businessUrl}/catalogRejected`);
    } else if (data?.value === "rejected") {
      navigate(`/${businessUrl}/catalogRejectedExisting`);
    } else {
    }

    setSelectedCategory("All");
    setSelectedSubCat("All");

    setActiveTab(data?.value);
  };

  const getProductsCount = (tabId) => {
    if (businessProductsLoader || productsByStatusLoader) return "XX";

    if (tabId === 0) {
      return totalProductCount || 0;
    } else if (tabId === 1) {
      return pendingProducts?.details?.length || 0;
    } else if (tabId === 2) {
      return updatedPendingProducts?.details?.length || 0;
    } else if (tabId === 3) {
      return productFilteredData?.rejectedNew?.length || 0;
    } else if (tabId === 4) {
      return productFilteredData?.rejectedExisting?.length || 0;
    } else {
      return 0;
    }
  };

  const getProductsCountByValue = (value) => {
    if (businessProductsLoader || productsByStatusLoader) return "XX";

    if (value === "active") {
      return totalProductCount || 0;
    } else if (value === "pendingApprovalNew") {
      return pendingProducts?.details?.length || 0;
    } else if (value === "pendingApproval") {
      return updatedPendingProducts?.details?.length || 0;
    } else if (value === "rejectedNew") {
      return productFilteredData?.rejectedNew?.length || 0;
    } else if (value === "rejected") {
      return productFilteredData?.rejectedExisting?.length || 0;
    } else {
      return 0;
    }
  };

  //search for products
  const handleSearch = () => {
    if (!searchQuery) return;
    clearFilter();
    const businessId = sessionStorage.getItem("selectedBusinessId");
    setSelectedCategory("All");
    setSelectedSubCat("All");

    dispatch(
      searchBusinessProducts({ query: searchQuery, businessId: businessId })
    );
  };

  const clearSearch = () => {
    dispatch(clearSearchProducts());
    setProductsList(productFilteredData?.active || []);

    setSearchQuery("");
    setIsClearSearch(false);
  };

  // Prepare list data for filter dropdown
  const formatCategory = (category, setValue, valueSelector) => {
    const sortedArray = category?.slice()?.sort(dynamicSort(valueSelector));
    const catList = sortedArray?.map((item) => {
      return { ...item, label: item?.[valueSelector] };
    });

    setValue([{ label: "All" }, ...catList]);
  };

  const getCurrentProductList = () => {
    if (productStatus === "active") return productFilteredData?.active || [];

    if (productStatus === "pending")
      return productFilteredData?.pendingNew || [];
    if (productStatus === "rejected")
      return productFilteredData?.rejectedNew || [];

    return [];
  };

  // Filter Products by Category
  // const handleCategoryFilter = (data) => {
  //   clearSearch();

  //   setSelectedCategory(data);
  //   setSelectedSubCat("All");

  //   setSelectedOndcCategory("All");
  //   setSelectedOndcSubCat("All");
  //   setOndcSubCatList([]);

  //   if (data === "All") {
  //     setSubCatList([]);
  //     const currentProductList = getCurrentProductList();
  //     setProductsList(currentProductList);
  //     return;
  //   }

  //   const CategoryData = categoryList?.filter((item) => {
  //     return item?.value === data;
  //   });

  //   formatCategory(CategoryData?.[0]?.subCat, setSubCatList, "value");

  //   const currentProductList = getCurrentProductList();

  //   const filteredProducts = currentProductList?.filter((item) => {
  //     return item?.productSubCategory?.[0]?.pcCode === CategoryData?.[0]?.code;
  //   });

  //   setProductsList(filteredProducts);
  // };

  // Filter Products by Sub Category
  // const handleSubCategoryFilter = (data) => {
  //   clearSearch();

  //   setSelectedSubCat(data);

  //   if (data === "All") {
  //     const CategoryData = categoryList?.filter((item) => {
  //       return item?.value === selectedCategory;
  //     });

  //     const currentProductList = getCurrentProductList();

  //     const filteredProducts = currentProductList?.filter((item) => {
  //       return (
  //         item?.productSubCategory?.[0]?.pcCode === CategoryData?.[0]?.code
  //       );
  //     });

  //     setProductsList(filteredProducts);
  //     return;
  //   }

  //   const subCategoryData = subCatList?.filter((item) => {
  //     return item?.value === data;
  //   });

  //   const currentProductList = getCurrentProductList();

  //   const filteredProducts = currentProductList?.filter((item) => {
  //     return item?.productSubCategory?.[0]?.code === subCategoryData?.[0]?.code;
  //   });

  //   setProductsList(filteredProducts);
  // };

  // Filter Products by Ondc Category
  // const handleOndcCategoryFilter = async (data) => {
  //   clearSearch();

  //   setSelectedOndcCategory(data);
  //   setSelectedOndcSubCat("All");

  //   setSelectedCategory("All");
  //   setSelectedSubCat("All");
  //   setSubCatList([]);

  //   if (data === "All") {
  //     setOndcSubCatList([]);
  //     const currentProductList = getCurrentProductList();
  //     setProductsList(currentProductList);
  //     return;
  //   }
  //   setFilterLoader(true);

  //   const categoryData = ondcCategoryList?.filter((item) => {
  //     return item?.ondcValue === data;
  //   });

  //   formatCategory(
  //     categoryData?.[0]?.productSubCategory,
  //     setOndcSubCatList,
  //     "ondcSubValue"
  //   );
  //   const businessId = sessionStorage.getItem("selectedBusinessId");
  //   const ondcProductList = await getONDCProductsAPI({
  //     businessId,
  //     params: {
  //       ondcCode: categoryData?.[0]?.ondcCode,
  //       pageSize: 500,
  //       pageNo: 1,
  //     },
  //   });

  //   console.log("ondcProductList", ondcProductList);

  //   setFilterLoader(false);
  //   setProductsList(ondcProductList);
  // };

  // Filter Products by Ondc Sub Category
  // const handleOndcSubCategoryFilter = async (data) => {
  //   clearSearch();

  //   setSelectedOndcSubCat(data);
  //   setFilterLoader(true);

  //   const businessId = sessionStorage.getItem("selectedBusinessId");
  //   const categoryData = ondcCategoryList?.filter((item) => {
  //     return item?.ondcValue === selectedOndcCategory;
  //   });

  //   console.log("categoryData", categoryData, data);

  //   if (data === "All") {
  //     const ondcProductList = await getONDCProductsAPI({
  //       businessId,
  //       params: {
  //         ondcCode: categoryData?.[0]?.ondcCode,
  //         pageSize: 500,
  //         pageNo: 1,
  //       },
  //     });
  //     setProductsList(ondcProductList);
  //     setFilterLoader(false);
  //     return;
  //   }

  //   const subCategoryData = ondcSubCatList?.filter((item) => {
  //     return item?.ondcSubValue === data;
  //   });

  //   console.log("subCategoryData", subCategoryData, data);

  //   const ondcProductList = await getONDCProductsAPI({
  //     businessId,
  //     params: {
  //       ondcCode: categoryData?.[0]?.ondcCode,
  //       ondcSubCode: subCategoryData?.[0]?.ondcSubCode,
  //       pageSize: 500,
  //       pageNo: 1,
  //     },
  //   });

  //   setFilterLoader(false);
  //   setProductsList(ondcProductList);
  // };

  // Category Change
  const handleWebstoreCategoryChange = (data) => {
    setSelectedCategory(data);
    setSelectedSubCat("All");

    if (data === "All") {
      setSubCatList([]);
      return;
    }

    setFilterError("");

    const CategoryData = categoryList?.filter((item) => {
      return item?.value === data;
    });

    formatCategory(CategoryData?.[0]?.subCat, setSubCatList, "value");
  };

  const handleWebstoreSubCategoryChange = (data) => {
    setSelectedSubCat(data);
  };

  const handleOndcCategoryChange = (data) => {
    setSelectedOndcCategory(data);
    setSelectedOndcSubCat("All");

    if (data === "All") {
      setOndcSubCatList([]);
      return;
    }

    setFilterError("");

    const categoryData = ondcCategoryList?.filter((item) => {
      return item?.ondcValue === data;
    });

    formatCategory(
      categoryData?.[0]?.productSubCategory,
      setOndcSubCatList,
      "ondcSubValue"
    );
  };

  const handleOndcSubCategoryChange = (data) => {
    setSelectedOndcSubCat(data);
  };

  // Filter Applied
  const handleOndcFilter = async () => {
    setFilterError("");
    if (ondcCategoryList?.length === 1) {
      setFilterError("ONDC category not assigned for any of the products");

      return;
    }
    if (selectedOndcCategory === "All") {
      setFilterError("Select the Main Category");

      return;
    }

    setFilterLoader(true);
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const categoryData = ondcCategoryList?.filter((item) => {
      return item?.ondcValue === selectedOndcCategory;
    });

    const subCategoryData = ondcSubCatList?.filter((item) => {
      return item?.ondcSubValue === selectedOndcSubCat;
    });

    const ondcProductList = await getONDCProductsAPI({
      businessId,
      params: {
        ondcCode: categoryData?.[0]?.ondcCode,
        ondcSubCode: subCategoryData?.[0]?.ondcSubCode,
        pageSize: 500,
        pageNo: 1,
      },
    });
    const pendingList = pendingProducts?.details || [];
    const updatedPendingList = updatedPendingProducts?.details || [];
    const rejectList = rejectedProducts?.details || [];

    const updatedSearchList = checkActiveProductInPendingRejected(
      ondcProductList,
      [...pendingList, ...updatedPendingList],
      rejectList
    );

    setFilterLoader(false);
    setProductsList(updatedSearchList);

    setShowONDCFilter(false);
    setIsClearFilter({
      show: true,
      category: `${selectedOndcCategory} - ${selectedOndcSubCat}`,
      message: `ONDC Category Filter results for “${selectedOndcCategory} - ${selectedOndcSubCat}” -
    ${ondcProductList?.length} products`,
    });
  };

  const handleWebstoreFilter = () => {
    setFilterError("");
    if (selectedCategory === "All") {
      setFilterError("Select the Main Category");
      // const currentProductList = getCurrentProductList();
      // setProductsList(currentProductList);
      // setShowWebstoreFilter(false);
      // setIsClearFilter({
      //   show: true,
      //   category: "All",
      //   message: `Webstore Category Filter results for “All” -
      // ${currentProductList?.length} products`,
      // });
      return;
    }

    const categoryData = categoryList?.filter((item) => {
      return item?.value === selectedCategory;
    });

    if (selectedSubCat === "All") {
      const currentProductList = getCurrentProductList();

      const filteredProducts = currentProductList?.filter((item) => {
        return (
          item?.productSubCategory?.[0]?.pcCode === categoryData?.[0]?.code
        );
      });

      setProductsList(filteredProducts);

      setShowWebstoreFilter(false);
      setIsClearFilter({
        show: true,
        category: `${selectedCategory} - ${selectedSubCat}`,
        message: `Webstore Category Filter results for “${selectedCategory} - ${selectedSubCat}” -
      ${filteredProducts?.length} products`,
      });
      return;
    }

    const subCategoryData = subCatList?.filter((item) => {
      return item?.value === selectedSubCat;
    });

    const currentProductList = getCurrentProductList();

    const filteredProducts = currentProductList?.filter((item) => {
      return item?.productSubCategory?.[0]?.code === subCategoryData?.[0]?.code;
    });

    setProductsList(filteredProducts);
    setShowWebstoreFilter(false);
    setIsClearFilter({
      show: true,
      category: `${selectedCategory} - ${selectedSubCat}`,
      message: `Webstore Category Filter results for “${selectedCategory} - ${selectedSubCat}” -
    ${filteredProducts?.length} products`,
    });
  };

  const onWebstoreFilterClose = () => {
    setFilterError("");
    setShowWebstoreFilter(false);
    setSelectedCategory("All");
    setSelectedSubCat("All");
    setSubCatList([]);
  };

  const onOndcFilterClose = () => {
    setFilterError("");
    setShowONDCFilter(false);
    setSelectedOndcCategory("All");
    setSelectedOndcSubCat("All");
    setOndcSubCatList([]);
  };

  const clearFilter = () => {
    setSelectedCategory("All");
    setSelectedSubCat("All");
    setSubCatList([]);
    setSelectedOndcCategory("All");
    setSelectedOndcSubCat("All");
    setOndcSubCatList([]);
    const currentProductList = getCurrentProductList();
    setProductsList(currentProductList);

    setIsClearFilter(null);
  };

  const clearAllOndcFilterData = () => {
    setSelectedOndcCategory("All");
    setSelectedOndcSubCat("All");
    setOndcSubCatList([]);
  };
  const clearAllWebstoreFilterData = () => {
    setSelectedCategory("All");
    setSelectedSubCat("All");
    setSubCatList([]);
  };

  const closeFilter = () => {
    // handleCategoryFilter("All");
    clearSearch();
    setSelectedOndcCategory("All");
    setSelectedOndcSubCat("All");
    setOndcSubCatList([]);
    setShowFilter(false);
  };

  const handleEditProduct = (productId) => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/editProductNew`, {
      state: {
        productId,
        isRejectedProduct: productStatus !== "active",
        isPendingProduct: productStatus === "pending",
      },
    });
  };

  const handlePreview = (productId) => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/viewProductNew`, {
      state: {
        productId,
        isReviewProduct: productStatus !== "active",
        urlLink: activeTab,
      },
    });
  };

  // handle ondc click
  const handleOndcClick = (productId) => {
    // console.log("productID", productId);

    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/ONDCParametersNew`, {
      state: { productId, isReviewProduct: productStatus !== "active" },
    });
  };

  const handleAddProduct = () => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/addProductNew`);

    // navigate(`/${businessUrl}/addProduct`, {
    //   state: { productId: null },
    // });
  };

  const onCopyProduct = (event, productId) => {
    event.stopPropagation();

    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/copyProductNew`, {
      state: {
        productId,
        isCopyProduct: true,
        // isRejectedProduct:
        //   productStatus === "rejected" || productStatus === "pending",
        isRejectedProduct: productStatus !== "active",
      },
    });
  };

  // handle delete click
  const onDeleteProduct = (data) => {
    setIsShowDeleteAlter({ ...isShowDeleteAlter, show: true, data });
  };

  // close delete product alter
  const closeDeleteAlert = () => {
    setIsShowDeleteAlter({
      ...isShowDeleteAlter,
      show: false,
      data: null,
      loader: false,
      deleteComplete: false,
    });
  };

  const onCheckClick = () => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/catalogRejected`, {
      state: { productStatus: "rejected" },
    });
  };

  // update product status to inactive (Delete)
  const updateProductStatus = async () => {
    setIsShowDeleteAlter({ ...isShowDeleteAlter, loader: true });

    const businessId = sessionStorage.getItem("selectedBusinessId");
    const productInfo = isShowDeleteAlter?.data || null;

    const deletePostData = {
      productId: productInfo?.id,
      businessId,
    };

    console.log("deletePostData:", deletePostData);

    const response = await updateProductStatusApi(deletePostData);
    console.log("delete_response:", response);

    setTimeout(() => {
      dispatch(getActiveProductsByBusiness({ businessId, pageNo: 1 }));
      setIsShowDeleteAlter({
        ...isShowDeleteAlter,
        loader: false,
        deleteComplete: true,
      });
    }, 2000);
  };

  const updateProductList = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(getProductsByBusiness(businessId));
    dispatch(getBusinessPendingProducts({ businessId, status: "new" }));
    dispatch(getBusinessRejectsProducts({ businessId, status: "declined" }));

    closeDeleteAlert();
  };

  // Checking if current product list is empty
  const checkListEmpty = () => {
    if (searchProductsLoader) return true;

    if (productStatus === "active") {
      return (
        !businessProductsLoader &&
        !searchProductsLoader &&
        businessProducts?.productList?.length > 0
      );
    }
    if (productStatus === "pending") {
      return (
        !businessProductsLoader &&
        !searchProductsLoader &&
        pendingProducts?.details?.length > 0
      );
    }
    if (productStatus === "pendingExisting") {
      return (
        !businessProductsLoader &&
        !searchProductsLoader &&
        updatedPendingProducts?.details?.length > 0
      );
    }
    if (productStatus === "rejected") {
      return (
        !businessProductsLoader &&
        !searchProductsLoader &&
        productFilteredData?.rejectedNew?.length > 0
      );
    }
    if (productStatus === "rejectedExisting") {
      return (
        !businessProductsLoader &&
        !searchProductsLoader &&
        productFilteredData?.rejectedExisting?.length > 0
      );
    }
  };

  // Return Empty List message based on tab
  const getEmptyMessage = () => {
    if (isClearSearch && activeTab === "active")
      return "No matching active products found. Please refine your search";

    return activeTab === "active"
      ? activeProductEmptyMsg
      : activeTab === "pendingApprovalNew"
      ? pendingProductEmptyMsg
      : activeTab === "pendingApproval"
      ? pendingProductEmptyMsg
      : activeTab === "rejected"
      ? rejectedProductEmptyMsg
      : rejectedProductEmptyMsg;
  };

  // Check if active product is also present in pending & Rejected products
  const checkActiveProductInPendingRejected = (
    activeList,
    pendingList,
    rejectList
  ) => {
    const updatedActiveList = activeList.map((product) => {
      const isPendingProduct = pendingList.filter(
        (item) => item.id === product.id
      );
      const isRejectedProduct = rejectList.filter(
        (item) => item.id === product.id
      );

      let updatedOn = "";

      if (isPendingProduct?.length > 0) {
        updatedOn = isPendingProduct?.[0]?.productUpdatedOn;
      }

      if (isRejectedProduct?.length > 0) {
        updatedOn = isRejectedProduct?.[0]?.productUpdatedOn;
      }

      return {
        ...product,
        disableEdit: isPendingProduct?.length > 0,
        isPendingProduct: isPendingProduct?.length > 0,
        isRejectedProduct: isRejectedProduct?.length > 0,

        tempisPendingProduct: isPendingProduct,
        tempisRejectedProduct: isRejectedProduct,
        updatedOn,
      };
    });

    return updatedActiveList;
  };

  const getRejectedProductsByStatus = (activeList, rejectList, status) => {
    console.log(
      "REJECTED_ACTIVE_LIST:",
      activeList.map((item) => item.id)
    );

    if (status === "new") {
      const newRejectedProducts = rejectList?.filter((product) => {
        const isProdInActive = activeList.some(
          (item) => item.id === product.id
        );

        return !isProdInActive;
      });

      console.log(
        "REJECTED_NEW:",
        newRejectedProducts.map((item) => item.id)
      );

      return newRejectedProducts;
    }

    if (status === "existing") {
      const existingRejectedProducts = rejectList?.filter((product) =>
        activeList.some((item) => item.id === product.id)
      );

      console.log(
        "REJECTED_EXISTING:",
        existingRejectedProducts.map((item) => item.id)
      );
      return existingRejectedProducts;
    }

    return [];
  };

  // Getting total product and sales count
  const getStatsInfo = async () => {
    // const businessId = sessionStorage.getItem("selectedBusinessId");
    // const orderCountInfo = await getDashboardOrderSummaryApi(businessId);
    // const inventoryCountInfo = await getDashboardInventorySummaryApi(
    //   businessId
    // );
    // setProductCountSalesInfo({
    //   orderCountInfo: orderCountInfo || null,
    //   inventoryCountInfo: inventoryCountInfo || null,
    // });
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  const getSteps = () => {
    if (tabValue === 0) {
      return ACTIVE_PRODUCT_STEPLIST;
    }
    if (tabValue === 1) {
      return NEW_PRODUCT_STEPLIST;
    }
  };

  // Fetching all the Catalog Data
  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      // getStatsInfo();
      if (activeBusinessProducts?.length == 0) {
        dispatch(getActiveProductsByBusiness({ businessId, pageNo: 1 }));
      }

      if (!editEligibility) {
        dispatch(getUserEditEligibility());
      }
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      if (!businessProducts) {
        dispatch(getProductsByBusiness(businessId));
      }
      if (!productCategory) {
        dispatch(getProductCategories(businessId));
      }
      if (!ondcCategory) {
        dispatch(getONDCCategories(businessId));
      }
      if (!commerceInfo) {
        dispatch(getCommerceInfo(businessId));
      }
      if (!pendingProducts) {
        dispatch(getBusinessPendingProducts({ businessId, status: "new" }));
      }
      if (!updatedPendingProducts) {
        dispatch(
          getBusinessUpdatedPendingProducts({ businessId, status: "updated" })
        );
      }
      if (!rejectedProducts) {
        dispatch(
          getBusinessRejectsProducts({ businessId, status: "declined" })
        );
      }

      // // Getting Product and Sales Count
      // if (!statsCountInfo) {
      //   dispatch(getStatsCountInfoData(businessId));
      // }
    }

    return () => {
      console.log("CLEAR_DATA_CATALOG");
      // dispatch(clearCatalogData());
    };
  }, []);

  // Show search results when data received
  useEffect(() => {
    if (searchProducts?.details) {
      setIsClearSearch(true);

      const searchResults = searchProducts?.details;
      const pendingList = pendingProducts?.details || [];
      const updatedPendingList = updatedPendingProducts?.details || [];
      const rejectList = rejectedProducts?.details || [];

      const updatedSearchList = checkActiveProductInPendingRejected(
        searchResults,
        [...pendingList, ...updatedPendingList],
        rejectList
      );

      setProductsList(updatedSearchList);
    }
  }, [searchProducts]);

  // Display data based on selected tab
  useEffect(() => {
    console.log("HIt456");
    let activeUpdatedList = [];

    if (ondcProductCount === 0) {
      if (businessProducts?.productList) {
        const ondcProducts = businessProducts?.productList?.filter(
          (item) => item?.ondcEnabled?.toLowerCase() === "yes"
        );

        setOndcProductCount(ondcProducts?.length || 0);
      }
    }

    if (productStatus === "active") {
      clearFilter();
      clearSearch();
      if (
        businessProducts?.productList &&
        pendingProducts &&
        updatedPendingProducts &&
        rejectedProducts
      ) {
        const activeList = filterProducts("active");
        const pendingList = pendingProducts?.details || [];
        const updatedPendingList = updatedPendingProducts?.details || [];
        const rejectList = rejectedProducts?.details || [];

        const updatedActiveList = checkActiveProductInPendingRejected(
          activeList,
          [...pendingList, ...updatedPendingList],
          rejectList
        );

        updatedActiveList?.sort((a, b) => {
          if (!a.updatedOn) return 1;
          if (!b.updatedOn) return -1;

          return parseInt(b.updatedOn) - parseInt(a.updatedOn);
        });

        console.log("updatedActiveList:", updatedActiveList);

        activeUpdatedList = updatedActiveList;
        setProductsList(updatedActiveList);
        setListLoader(false);
      }
    }

    if (productStatus === "pending" && pendingProducts) {
      console.log("pendingTest", pendingProducts);
      const pendingList = pendingProducts?.details || [];

      setProductFilteredData({
        ...productFilteredData,
        pendingNew: pendingList,
      });

      const pendingNewSort = [...pendingList];

      pendingNewSort?.sort((a, b) => {
        if (!a.productUpdatedOn) return 1;
        if (!b.productUpdatedOn) return -1;

        return parseInt(b.productUpdatedOn) - parseInt(a.productUpdatedOn);
      });

      console.log("pendingNewList:", pendingNewSort);

      setProductsList(pendingNewSort);
      setListLoader(false);
    }

    if (productStatus === "pendingExisting" && updatedPendingProducts) {
      let pendingList = updatedPendingProducts?.details || [];

      setProductFilteredData({
        ...productFilteredData,
        pendingExisting: pendingList,
      });

      const pendingExistingSort = [...pendingList];

      pendingExistingSort?.sort((a, b) => {
        if (!a.productUpdatedOn) return 1;
        if (!b.productUpdatedOn) return -1;

        return parseInt(b.productUpdatedOn) - parseInt(a.productUpdatedOn);
      });

      setProductsList(pendingExistingSort);
      setListLoader(false);
    }

    // Filtering Rejected Products by Status

    if (businessProducts?.productList && rejectedProducts) {
      const activeList = filterProducts("active");
      const rejectList = rejectedProducts?.details || [];

      const updatedNewRejectedList = getRejectedProductsByStatus(
        activeList,
        rejectList,
        "new"
      );

      const updatedExistingRejectedList = getRejectedProductsByStatus(
        activeList,
        rejectList,
        "existing"
      );

      setProductFilteredData({
        ...productFilteredData,
        active: activeUpdatedList,
        rejectedNew: updatedNewRejectedList,
        rejectedExisting: updatedExistingRejectedList,
      });

      if (productStatus === "rejected") {
        const rejectedNewSort = [...updatedNewRejectedList];

        rejectedNewSort?.sort((a, b) => {
          if (!a.productUpdatedOn) return 1;
          if (!b.productUpdatedOn) return -1;

          return parseInt(b.productUpdatedOn) - parseInt(a.productUpdatedOn);
        });

        setProductsList(rejectedNewSort);
        setListLoader(false);
      }

      if (productStatus === "rejectedExisting") {
        const rejectedExistingSort = [...updatedExistingRejectedList];

        rejectedExistingSort?.sort((a, b) => {
          if (!a.productUpdatedOn) return 1;
          if (!b.productUpdatedOn) return -1;

          return parseInt(b.productUpdatedOn) - parseInt(a.productUpdatedOn);
        });

        setProductsList(rejectedExistingSort);
        setListLoader(false);
      }
    }

    const catalogStatus = status || "active";

    setTabValue(
      catalogStatus === "active"
        ? 0
        : catalogStatus === "pending"
        ? 1
        : catalogStatus === "pendingExisting"
        ? 0
        : catalogStatus === "rejected"
        ? 1
        : catalogStatus === "rejectedExisting"
        ? 0
        : 1
    );
    setActiveTab(
      catalogStatus === "active"
        ? "active"
        : catalogStatus === "pending"
        ? "pendingApprovalNew"
        : catalogStatus === "pendingExisting"
        ? "pendingApproval"
        : catalogStatus === "rejected"
        ? "rejectedNew"
        : catalogStatus === "rejectedExisting"
        ? "rejected"
        : catalogStatus
    );
  }, [
    businessProducts,
    updatedPendingProducts,
    pendingProducts,
    rejectedProducts,
    location,
  ]);

  useEffect(() => {
    if (productCategory?.details) {
      console.log("productCategory", productCategory?.details);
      formatCategory(productCategory?.details, setCategoryList, "value");
    }
  }, [productCategory]);

  useEffect(() => {
    if (ondcCategory) {
      const ondcFilteredCategory = ondcCategory?.filter(
        (item) => item?.ondcCode
      );
      formatCategory(ondcFilteredCategory, setOndcCategoryList, "ondcValue");
      console.log("ondcCategory", ondcCategory, ondcFilteredCategory);
    }
  }, [ondcCategory]);

  // User Edit Eligibility Check
  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (editEligibility) {
      const businessList = editEligibility?.editEligibilityList;
      const isEditable = isUserEditEligible(businessId, businessList);

      setIsProductsEditable(isEditable);
    }
    return () => {};
  }, [editEligibility]);

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (
  //       ondcFilterRef.current &&
  //       !ondcFilterRef.current.contains(event.target)
  //     ) {
  //       setShowONDCFilter(false);
  //     }
  //     if (
  //       webstoreFilterRef.current &&
  //       !webstoreFilterRef.current.contains(event.target)
  //     ) {
  //       setShowWebstoreFilter(false);
  //     }
  //   }

  //   // Add event listener to handle clicks on the document
  //   document.addEventListener("click", handleClickOutside);

  //   // Cleanup function to remove event listener when component unmounts
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  return (
    activeBusinessInfo && (
      <Box sx={styles.container(matches)}>
        <DashboardLayout activeMenu="catalog">
          <Box sx={styles.content}>
            {isProductsEditable ? (
              <Box>
                <Box sx={{ ...stickyHeader, top: { md: "45px", xs: "108px" } }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      // alignItems: { xs: "flex-start", md: "center" },
                      justifyContent: "space-between",
                      gap: { xs: 1, md: 0 },
                      mb: { xs: "12px", md: "20px" },
                    }}
                  >
                    <Box width="100%">
                      <Box
                        display="flex"
                        alignContent="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          component="h4"
                          fontSize={{ xs: 15, md: 17 }}
                          color={blackShade05}
                          fontWeight={600}
                          lineHeight="20px"
                          pt={{ xs: 0.5, md: 0 }}
                        >
                          Product Catalog
                        </Typography>

                        {!matches && (
                          <HeaderActionButtons
                            matches={matches}
                            navigate={navigate}
                            handleAddProduct={handleAddProduct}
                            setOpenVideoModal={setOpenVideoModal}
                          />
                        )}
                      </Box>

                      {activeBusinessInfo &&
                        activeBusinessInfo?.mainBranch &&
                        !activeBusinessInfo?.mainBranchId &&
                        !matches && (
                          <Typography
                            fontSize={{ xs: 12, md: 13 }}
                            fontWeight={500}
                            color={blackShade21}
                            lineHeight={{ xs: "17px", md: "24px" }}
                            mt={1}
                          >
                            Catalog push to branches can be performed from a web
                            browser
                          </Typography>
                        )}
                    </Box>

                    {matches && (
                      <HeaderActionButtons
                        matches={matches}
                        navigate={navigate}
                        handleAddProduct={handleAddProduct}
                        setOpenVideoModal={setOpenVideoModal}
                      />
                    )}
                  </Box>

                  {/* {!matches && <Box m='4px 0 8px 0' >
                    <Typography
                      fontSize={{ xs: 12, md: 13 }}
                      fontWeight={500}
                      color={blackShade21}
                      lineHeight={{ xs: "17px", md: "24px" }}
                      
                    >
                      Catalog push to branches can be performed from a web browser
                    </Typography>
                  </Box>} */}

                  {
                    // !activeBusinessProductsLoader &&
                    //   !productsByStatusLoader &&
                    matches && (
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          mb: { xs: "10px", md: "12px" },
                        }}
                      >
                        <Tabs
                          value={tabValue}
                          onChange={(event, newValue) =>
                            handleChange(event, newValue)
                          }
                          aria-label="basic tabs example"
                          textColor={blackColor}
                          TabIndicatorProps={{
                            style: {
                              height: "3px",
                              backgroundColor: blueColor04,
                            },
                          }}
                          sx={{
                            minHeight: "35px !important",
                            "& .MuiButtonBase-root": {
                              minWidth: "auto !important",
                            },

                            "& .MuiTabs-flexContainer": {
                              // justifyContent: "space-between",
                            },
                          }}
                        >
                          {stepList?.map((item, index) => {
                            return (
                              <Tab
                                key={"filter_" + index}
                                label={
                                  item.label?.toLowerCase() +
                                  ` - ${getProductsCount(item.id)}`
                                }
                                // label={item.label}
                                sx={{
                                  fontSize: { xs: "11px", md: "13px" },
                                  fontWeight: tabValue == index ? 600 : 500,
                                  p: 0,
                                  minHeight: "30px !important",
                                  mr: { xs: "10px", md: "55px" },
                                  color:
                                    item.id === tabValue
                                      ? blueColor04
                                      : greyColor40,
                                  textTransform: "capitalize",
                                }}
                              />
                            );
                          })}
                        </Tabs>
                      </Box>
                    )
                  }

                  {!matches && (
                    <Box sx={{ ...styles.mobileTabs, ...scrollBox }}>
                      {stepList?.map((item, index) => {
                        return (
                          <MobileTab
                            title={
                              item.mobileLabel
                              // ` - ${getProductsCount(item.id)}`
                            }
                            productCount={getProductsCount(item.id)}
                            isActive={tabValue == index}
                            onClick={() => handleChange("", index)}
                          />
                        );
                      })}
                    </Box>
                  )}

                  <PillTabs
                    steps={getSteps()}
                    active={activeTab}
                    ontabClick={ontabClick}
                    productCount={(data) =>
                      getProductsCountByValue(data?.value)
                    }
                  />
                  {!businessProductsLoader &&
                    commerceInfo &&
                    ondcProductCount > 0 &&
                    (!commerceInfo?.info?.ondcEnabled ||
                      commerceInfo?.info?.ondcEnabled?.toLowerCase() ===
                        "no") && (
                      <Box
                        sx={{
                          bgcolor: lightRedColor02,
                          p: "8px 15px",
                          borderRadius: "10px",
                          my: 0.2,
                        }}
                      >
                        <Typography
                          fontSize={{ xs: 12, md: 13 }}
                          fontWeight={500}
                          color={outOfStockColor}
                          lineHeight="21px"
                        >
                          ONDC Controls are enabled for {ondcProductCount}{" "}
                          Products. Enable ONDC control at business level to
                          make sure products are visible in ONDC search from
                          buyer apps.{" "}
                          <Link
                            to={`/${sessionStorage.getItem(
                              "costboWEBURL"
                            )}/ONDCControls`}
                            style={{
                              color: greyColor49,
                              textDecoration: "underline",
                            }}
                          >
                            Click Here
                          </Link>
                        </Typography>
                      </Box>
                    )}
                </Box>

                <Box mt={1.5} mb={{ xs: 2, md: 2 }}>
                  {checkListEmpty() && (
                    <Box>
                      {productStatus !== "rejected" &&
                        productStatus !== "rejectedExisting" && (
                          <Box
                            display="flex"
                            flexDirection={{ xs: "column", md: "row" }}
                            justifyContent="space-between"
                            mb={{ xs: 0, md: 0.5 }}
                          >
                            <Box flex={1}>
                              <Typography
                                fontSize={{ xs: 12, md: 13 }}
                                color={{ xs: blackShade19, md: greyColor40 }}
                                lineHeight="18px"
                              >
                                For stock and pricing updates use Inventory
                                update section
                              </Typography>
                            </Box>
                          </Box>
                        )}

                      {productStatus === "active" && (
                        <>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={{ md: "center" }}
                            flexDirection={{ md: "row", xs: "column" }}
                            gap={2}
                          >
                            <Box>
                              <Box width={{ xs: "auto", md: "300px" }}>
                                <SearchBoxInput
                                  label="Search"
                                  placeholder="Search Product"
                                  value={searchQuery}
                                  onChange={(text) => {
                                    setSearchQuery(text);
                                  }}
                                  onSearchClick={handleSearch}
                                  isDashboardInput
                                />
                              </Box>
                            </Box>

                            <Box
                              display={"flex"}
                              gap={1}
                              position={{ xs: "relative", md: "static" }}
                              mt={{xs:0, md:2}}
                            >
                              <Box
                                position={{ md: "relative", xs: "static" }}
                                ref={ondcFilterRef}
                              >
                                <Button
                                  sx={styles.filterIconBtn}
                                  variant="contained"
                                  disableElevation
                                  onClick={() => {
                                    clearSearch();
                                    setShowWebstoreFilter(false);
                                    setShowONDCFilter((prev) => !prev);
                                  }}
                                  endIcon={
                                    <FilterListRoundedIcon
                                      sx={{ fontSize: "17px" }}
                                    />
                                  }
                                >
                                  {matches
                                    ? "ONDC Category Filters"
                                    : "ONDC Category"}
                                </Button>
                                {showONDCFilter && (
                                  <FilterCard
                                    title="ONDC Category Filters"
                                    categoryList={ondcCategoryList}
                                    subCategoryList={ondcSubCatList}
                                    selectedCategory={selectedOndcCategory}
                                    selectedSubCategory={selectedOndcSubCat}
                                    handleCategoryChange={
                                      handleOndcCategoryChange
                                    }
                                    handleSubCategoryChange={
                                      handleOndcSubCategoryChange
                                    }
                                    onApply={handleOndcFilter}
                                    onCancel={onOndcFilterClose}
                                    onAllClear={clearAllOndcFilterData}
                                    loader={filterLoader}
                                    error={filterError}
                                  />
                                )}
                              </Box>
                              <Box
                                position={{ md: "relative", xs: "static" }}
                                ref={webstoreFilterRef}
                              >
                                <Button
                                  sx={styles.filterIconBtn}
                                  variant="contained"
                                  disableElevation
                                  onClick={() => {
                                    clearSearch();
                                    setShowONDCFilter(false);
                                    setShowWebstoreFilter((prev) => !prev);
                                  }}
                                  endIcon={
                                    <FilterListRoundedIcon
                                      sx={{ fontSize: "17px" }}
                                    />
                                  }
                                >
                                  {matches
                                    ? "Webstore Category Filters"
                                    : "Webstore Category"}
                                </Button>
                                {showWebstoreFilter && (
                                  <FilterCard
                                    title="Webstore Category Filters"
                                    categoryList={categoryList}
                                    subCategoryList={subCatList}
                                    selectedCategory={selectedCategory}
                                    selectedSubCategory={selectedSubCat}
                                    handleCategoryChange={
                                      handleWebstoreCategoryChange
                                    }
                                    handleSubCategoryChange={
                                      handleWebstoreSubCategoryChange
                                    }
                                    onApply={handleWebstoreFilter}
                                    onCancel={onWebstoreFilterClose}
                                    onAllClear={clearAllWebstoreFilterData}
                                    error={filterError}
                                  />
                                )}
                              </Box>
                            </Box>
                          </Box>

                          {isClearSearch ? (
                            <Box
                              display="flex"
                              alignItems={"flex-start"}
                              flexDirection={searchQuery?.length > 50 ? 'column' : 'row'}
                              flexWrap='wrap'
                              gap="6px"
                              mt={1}
                              
                            >
                              <Typography
                                component="h4"
                                fontSize={12}
                                color={blackShade05}
                                fontWeight={500}
                                lineHeight="20px"
                              >
                                Search results for “{searchQuery}” -{" "}
                                {searchProducts?.size} products
                              </Typography>

                              <Chip
                                label={searchQuery}
                                sx={{ fontSize: "12px", height: "28px" }}
                                onDelete={clearSearch}
                              />
                            </Box>
                          ) : null}
                        </>
                      )}

                      {productStatus === "active" && isClearFilter?.show ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          gap="6px"
                          mt={1}
                          flexWrap={"wrap"}
                        >
                          <Typography
                            component="h4"
                            fontSize={12}
                            color={blackShade05}
                            fontWeight={500}
                            lineHeight="20px"
                          >
                            {isClearFilter?.message}
                          </Typography>

                          <Chip
                            label={isClearFilter?.category}
                            sx={{ fontSize: "12px", height: "28px" }}
                            onDelete={clearFilter}
                          />
                        </Box>
                      ) : null}
                    </Box>
                  )}
                </Box>

                <Box mt={{ xs: 0, md: 2 }}>
                  {businessProductsLoader ||
                  searchProductsLoader ||
                  filterLoader ||
                  listLoader ? (
                    <ThreeDotLoader />
                  ) : productsList?.length > 0 ? (
                    <ProductNewlist
                      productsList={productsList}
                      productStatus={productStatus}
                      handlePreview={handlePreview}
                      handleEditProduct={handleEditProduct}
                      onCheckClick={onCheckClick}
                      onCopyProduct={onCopyProduct}
                      handleOndcClick={handleOndcClick}
                      onDeleteProduct={(product) => onDeleteProduct(product)}
                    />
                  ) : !businessProductsLoader &&
                    !searchProductsLoader &&
                    productsList?.length === 0 ? (
                    <EmptyCard
                      icon="https://storage.googleapis.com/bodefaults/businessdashboardweb/product-reject.svg"
                      message={getEmptyMessage()}
                    />
                  ) : null}
                </Box>
              </Box>
            ) : (
              editEligibility &&
              !isProductsEditable && (
                <Typography variant="body1" fontSize={15} fontWeight={500}>
                  You don't have edit permission
                </Typography>
              )
            )}
          </Box>
        </DashboardLayout>

        <ModalCard
          open={isShowDeleteAlter.show}
          // open={true}
          handleClose={closeDeleteAlert}
          width="27%"
        >
          <DeleteProductModalContent
            productName={isShowDeleteAlter?.data?.productName}
            isLoading={isShowDeleteAlter?.loader}
            onDelete={updateProductStatus}
            onCancel={closeDeleteAlert}
            isDeleteComplete={isShowDeleteAlter?.deleteComplete}
            onDone={updateProductList}
          />
        </ModalCard>

        {openVideoModal && (
          <VideoModal
            open={openVideoModal}
            handleClose={handleCloseVideoModal}
            data={CATALOG_VIDEO_DATA}
          />
        )}
      </Box>
    )
  );
}

export default ProductcatalogList;

const MobileTab = ({ title, productCount, isActive, onClick }) => {
  return (
    <Box sx={styles.mobileTabItem(isActive)} onClick={onClick}>
      <Typography
        fontSize={13.5}
        color={{
          xs: isActive ? mobilePrimary : greyColor40,
          md: isActive ? blueColor04 : greyColor40,
        }}
        fontWeight={500}
        sx={{ whiteSpace: "nowrap" }}
      >
        {productCount}
      </Typography>

      <Typography
        fontSize={11.5}
        color={{
          xs: isActive ? mobilePrimary : greyColor40,
          md: isActive ? blueColor04 : greyColor40,
        }}
        fontWeight={isActive ? 600 : 500}
        sx={{ whiteSpace: "nowrap" }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const HeaderActionButtons = ({
  matches,
  navigate,
  handleAddProduct,
  setOpenVideoModal,
}) => (
  <Box display="flex" gap={{ md: 2, xs: 1 }} alignItems="center">
    <HowToLinkCard
      link="Catalog Management"
      handleClick={() => {
        setOpenVideoModal(true);
      }}
    />
    {matches ? (
      <Button
        sx={styles.actionBtn}
        variant="contained"
        disableElevation
        startIcon={<FileUploadOutlinedIcon />}
        onClick={() => {
          navigate(
            `/${sessionStorage.getItem("costboWEBURL")}/catalogBulkUpload`
          );
        }}
      >
        Bulk upload
      </Button>
    ) : (
      <IconButton
        sx={styles.bulkaddIconBtnSmall}
        onClick={() => {
          navigate(
            `/${sessionStorage.getItem("costboWEBURL")}/catalogBulkUpload`
          );
        }}
      >
        <FileUploadOutlinedIcon sx={{ fontSize: "18px" }} />
      </IconButton>
    )}
    {matches ? (
      <Button
        sx={styles.addIconBtn}
        variant="contained"
        disableElevation
        onClick={handleAddProduct}
        startIcon={<AddIcon />}
      >
        Add Product
      </Button>
    ) : (
      <IconButton sx={styles.addIconBtnSmall} onClick={handleAddProduct}>
        <AddIcon sx={{ fontSize: "18px" }} />
      </IconButton>
    )}
  </Box>
);

const styles = {
  container: (isMobile) => ({
    backgroundColor: isMobile ? BGColor02 : greyColor39,
  }),

  content: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "10px" },
    pt: { xs: 2.5, md: 0 },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  addBtn: {
    height: "299px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #f1f1f1",
    borderRadius: "5px",
    boxShadow: "0px 1px 3px rgb(0 0 0 / 6%)",
    backgroundColor: whiteColor,
    cursor: "pointer",
  },

  addIconBtn: {
    padding: "4px 14px",
    fontSize: "12.5px",
    fontWeight: 600,
    color: blueColor04,
    backgroundColor: whiteColor,
    border: "1.5px solid " + blueColor04,
    whiteSpace: "nowrap",
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },
  filterIconBtn: {
    padding: "4px 12px",
    fontSize: "11px",
    fontWeight: 600,
    color: greyColor9,
    backgroundColor: whiteColor,
    border: "1px solid " + greyColor9,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor9,
    },
  },
  actionBtn: {
    backgroundColor: blackShade22,
    padding: "4px 14px",
    fontSize: "12.5px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + blackShade22,
    whiteSpace: "nowrap",
    "&:hover": {
      color: blackShade22,
      backgroundColor: whiteColor,
    },
  },

  addIconBtnSmall: {
    width: { xs: "36px", md: "38px" },
    height: { xs: "30px", md: "34px" },
    backgroundColor: "transparent",
    fontWeight: 600,
    color: { xs: mobilePrimary, md: blueColor04 },
    backgroundColor: whiteColor,
    borderRadius: "5px",
    border: {
      xs: "1px solid " + mobilePrimary,
      md: "1px solid " + blueColor04,
    },
    "&:hover": {
      color: whiteColor,
      backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    },
  },
  bulkaddIconBtnSmall: {
    width: { xs: "36px", md: "38px" },
    height: { xs: "30px", md: "34px" },
    backgroundColor: "transparent",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: blackShade22,
    borderRadius: "5px",
    border: "1px solid " + whiteColor,
    "&:hover": {
      color: blackShade22,
      backgroundColor: whiteColor,
    },
  },

  statsCard: {
    width: { xs: "100%", md: "auto" },
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    gap: { xs: "20px", md: "35px" },
    backgroundColor: whiteColor,
    padding: { xs: "10px 16px", md: "8px 16px" },
    borderRadius: { xs: "8px", md: "12px" },
    border: logoImgborder1,
    m: { xs: "12px 0 12px 0", md: "0" },
  },

  copyBtnBox: {
    width: "28px",
    height: "28px",
    borderRadius: "5px",
    border: `1px solid ${greyColor33}`,
    padding: "3px 4px",
    mx: "2px",
    backgroundColor: whiteColor,
    color: greyColor33,
    display: "inline",
  },

  mobileTabs: {
    width: { xs: "90vw", md: "calc(100% - 100px)" },
    display: "flex",
    alignItems: "center",
    mb: 1,
    borderBottom: logoImgborder,
  },

  mobileTabItem: (isActive) => ({
    textAlign: "center",
    borderBottom: {
      xs: isActive ? `3px solid ${mobilePrimary}` : "none",
      md: isActive ? `3px solid ${blueColor04}` : "none",
    },
    mr: 1,
  }),

  card: {
    position: "relative",
    backgroundColor: whiteColor,
    borderRadius: "13px",
    border: border25,
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    my: 2,
    overflow: "hidden",
  },
};
