import { Box, Typography } from "@mui/material";
import React from "react";
import {
  blueColor02,
  blueColor04,
  mobilePrimary,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { blackShade05 } from "../../configs/styles/muiThemes";

function EmptyCard({ icon, msg, submsg, link, hideBorder, noMargin }) {
  return (
    <Box sx={styles.card(hideBorder, noMargin)} gap={1}>
      {icon && <Box sx={styles.iconContainer}>{icon}</Box>}
      <Typography
        color={blackShade05}
        fontSize={{xs:13.5, md:13.5}}
        fontWeight={500}
        lineHeight={1.5}
      >
        {msg}
      </Typography>
      <Typography
        display="inline"
        color={blackShade05}
        fontSize={14}
        fontWeight={400}
        lineHeight={1.5}
      >
        {submsg}
        <Typography
          component="a"
          display="inline"
          target="_blank"
          color={{md:blueColor04,xs:mobilePrimary}}
          href={link}
          sx={{ textDecoration: "underline" }}
        >
          {link ? "Click Here" : null}
        </Typography>
      </Typography>
    </Box>
  );
}

export default EmptyCard;

const styles = {
  card: (hideBorder, noMargin) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: whiteColor,
    marginBottom: noMargin ? 0 : "24px",
    borderRadius: "10px",
    padding: {xs:'20px 15px', md:"30px 15px"},
    textAlign: "center",
    border: hideBorder ? "none" : "1px solid #e5e9f0",
    boxShadow: hideBorder ? "none" : "0 3px 4px rgb(229 233 240 / 22%)",
    marginTop: noMargin ? 0 : "15px",
  }),
  iconContainer: {
    width: "50px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    background: "rgb(5 60 81 / 7%)",
    padding: "5px",
    mb: "10px",
  },
};
