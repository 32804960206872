import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
    blackShade05,
    greyColor50,
    greyColor6,
    greyColorM01,
  lightBlueColor05,
  logoImgborder1,
  mobilePrimary,
  redColorBG01,
  skyblueColorBG01,
  whiteColor,
  yellowColorBG02,
} from "../../../configs/styles/muiThemes";
import { addThousandsSeparator } from "../../../utils/helper";
import MobileCashBackEmptyCard from "../EmptyCards/MobileCashBackEmptyCard";

function MobileCashBackCard({
  businessData,
  cashbackCount,
  cashbackSummary,
  availableCashback,
  onClick,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const cashbackPercentage = Number(cashbackSummary?.rewardPointPercent) || 0;
  const totalCashbackOrder =
    Number(cashbackCount?.totalCashbackOrderNumbers) || 0;
  const totalOrderValue = Number(cashbackCount?.totalCashbackOrderValue) || 0;

  return (
    <Box width={"100%"}>
      {cashbackPercentage === 0 &&
      availableCashback?.totalAvailableCashBackValueForBusiness === 0 ? (
        <MobileCashBackEmptyCard
          activeBusinessInfo={businessData}
          onClick={onClick}
        />
      ) : (
        <Grid container spacing={{ xs: 1, md: 3 }}>
          <Grid
            item
            md={7}
            xs={6}
            display="flex"
            alignItems="stretch"
            justifyContent={"center"}
            flexGrow={1}
          >
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              flexWrap={"wrap"}
              mt={1}
            >
              <Box
                flex={1}
                display="flex"
                flexDirection={{ xs: "row", md: "row" }}
                alignItems={{ xs: "center", md: "center" }}
                sx={{
                  ...styles.orderDateCardMobile(redColorBG01, "#FFEAEF"),
                  background: redColorBG01,
                }}
                flexWrap={"wrap"}
              >
                <MobileStatsCard
                  label="Cashback %"
                  value={`${cashbackPercentage}%`}
                  icon={
                    <Box sx={styles.iconCircle}>
                      <Box
                        component="img"
                        alt="Product Image"
                        src={
                          " https://storage.googleapis.com/bodefaults/businessdashboardweb/cash_per.svg"
                        }
                        width="auto"
                        height="128px"
                      />
                    </Box>
                  }
                  //   bgColor={matches ? blueColor04 : whiteColor}
                  color={matches ? blackShade05 : "#E74068"}
                  labelColor={matches ? greyColor6 : "#E74068"}
                  valueSize={14}
                  hideMobileBorder
                />
              </Box>
              <Box
                flex={1}
                display="flex"
                flexDirection={{ xs: "row", md: "row" }}
                alignItems={{ xs: "center", md: "center" }}
                // columnGap={3.5}
                // sx={{ ...styles.orderDateCard, background: skyblueColorBG01}}
                sx={{
                  ...styles.orderDateCardMobile(yellowColorBG02, "#FFEAEF"),
                  background: yellowColorBG02,
                }}
                mt={2}
              >
                <MobileStatsCard
                  label="CashBack Balance"
                  value={`₹ ${addThousandsSeparator(
                    availableCashback?.totalAvailableCashBackValueForBusiness
                  )}`}
                  icon={
                    <Box sx={styles.iconCircle}>
                      <Box
                        component="img"
                        alt="Product Image"
                        src={
                          "https://storage.googleapis.com/bodefaults/businessdashboardweb/cash_amount.svg"
                        }
                        width="auto"
                        height="128px"
                      />
                    </Box>
                  }
                  //   bgColor={matches ? blueColor04 : whiteColor}
                  color={matches ? blackShade05 : "#FF9800"}
                  labelColor={matches ? greyColor6 : "#FF9800"}
                  valueSize={14}
                  hideMobileBorder
                />
              </Box>
              <Box
                flex={1}
                display="flex"
                flexDirection={{ xs: "row", md: "row" }}
                alignItems={{ xs: "center", md: "center" }}
                // columnGap={3.5}
                // sx={{ ...styles.orderDateCard, background: skyblueColorBG01}}
                sx={{
                  ...styles.orderDateCardMobile("#EAE8FF", "#E4E2FC"),
                  background: "#EAE8FF",
                }}
                mt={2}
              >
                <MobileStatsCard
                  label="Order Placed"
                  value={`${addThousandsSeparator(totalCashbackOrder, true)}`}
                  icon={
                    <Box sx={styles.iconCircle}>
                      <Box
                        component="img"
                        alt="Product Image"
                        src={
                          "https://storage.googleapis.com/bodefaults/businessdashboardweb/order_placed.svg"
                        }
                        width="auto"
                        height="128px"
                      />
                    </Box>
                  }
                  //   bgColor={matches ? blueColor04 : whiteColor}
                  color={matches ? blackShade05 : "#4F46E5"}
                  labelColor={matches ? greyColor6 : "#4F46E5"}
                  valueSize={14}
                  hideMobileBorder
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            display="flex"
            alignItems="stretch"
            justifyContent={"center"}
            flexGrow={1}
          >
            <Box
              flex={1}
              display="flex"
              flexDirection={{ xs: "row", md: "row" }}
              alignItems={{ xs: "center", md: "center" }}
              // columnGap={3.5}
              // sx={{ ...styles.orderDateCard, background: skyblueColorBG01}}
              sx={{
                ...styles.orderDateCardMobile("#DDF2FF", "#D7F0FF"),
                background: "#DDF2FF",
              }}
            >
              <Box
                component="img"
                alt="Product Image"
                src={
                  "https://storage.googleapis.com/bodefaults/businessdashboardweb/cash_back.svg"
                }
                width="auto"
                height="100%"
                // sx={{objectFit:"fit"}}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default MobileCashBackCard;

const MobileStatsCard = ({
  icon,
  label,
  value,
  color,
  bgColor,
  labelColor,
  valueSize,
  hideMobile,
  flex,
  hideMobileBorder,
}) => {
  return (
    <Box
      sx={{
        ...styles.card(flex),
        borderBottom: hideMobileBorder ? "" : `1px solid #F5F7FC`,
        pb: 0.5,
        pt: 0.5,
      }}
    >
      <Box sx={styles.iconContainer(bgColor, hideMobile)}>{icon}</Box>
      <Stack>
        <Typography
          variant="body2"
          fontSize={{ xs: 10, md: 11.28 }}
          color={labelColor || greyColorM01}
          fontWeight={500}
          lineHeight="20px"
        >
          {label}
        </Typography>

        <Typography
          component="h6"
          fontSize={{ xs: valueSize || 15, md: 15 }}
          color={color || blackShade05}
          fontWeight={600}
        >
          {value}
        </Typography>
      </Stack>
    </Box>
  );
};

const styles = {
  orderCard: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px", md: "12px" },
  },
  orderDateCard: {
    backgroundColor: { xs: greyColor50, md: whiteColor },
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "7px 15px", md: "12px" },
  },
  orderDateCardMobile: (color, borderColor) => ({
    backgroundColor: { xs: color ? color : skyblueColorBG01, md: whiteColor },
    border: `1px solid ${borderColor}`,
    borderRadius: { xs: "10px", md: "12px" },
    // boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "4px 6px", md: "12px" },
  }),
  orderTotalCardMobile: {
    backgroundColor: { xs: "#FFEEF2", md: whiteColor },
    border: `1px solid #FFE4EB`,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 10px", md: "12px" },
  },
  card: (flex) => ({
    display: "flex",
    alignItems: "center",
    gap: 1,
    flex: flex || 1,
  }),
  iconContainer: (bgColor, hideMobile) => ({
    width: { md: "40px", xs: "34px" },
    height: { md: "40px", xs: "34px" },
    borderRadius: "50%",
    backgroundColor: bgColor,
    display: { md: "flex", xs: hideMobile ? "none" : "flex" },
    alignItems: "center",
    justifyContent: "center",
  }),
  icon: (color) => ({ fontSize: { md: 20, xs: 17 }, color: color }),
};
