import { Box, Checkbox, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  greyColor42,
  greyColor8,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { CATALOG_BRANCH_PUSH_HEADER, CATALOG_BRANCH_PUSH_SINGLE_HEADER } from "../../../utils/data";

const HeaderInfoRow = ({
  productList,
  selectAllProductSelect,
  loader,
  tabValue,
}) => {
  const [headerDataList, setHeaderDataList] = useState(
    CATALOG_BRANCH_PUSH_HEADER
  );

  useEffect(() => {
    if(tabValue === 0){
      setHeaderDataList(CATALOG_BRANCH_PUSH_HEADER)
    }else{
      setHeaderDataList(CATALOG_BRANCH_PUSH_SINGLE_HEADER)
    }

    return () => {};
  }, [tabValue]);

  return (
    <Box
      sx={{
        backgroundColor: greyColor42,
        zIndex: 20,
        borderRadius: "8px 8px 0 0",
      }}
    >
      <Grid container>
        {headerDataList?.map((item, index) => {
          return (
            <Grid
              key={item.title}
              item
              md={item.col}
              borderRight={`1px solid ${whiteColor}`}
            >
              <Box
                display={index === 0 ? "flex" : "inline-block"}
                alignItems="center"
                gap={2.5}
                padding="4px 15px"
              >
                {index === 0 && tabValue === 0 && (
                  <Checkbox
                    checked={productList?.every((item) => item?.isSelected)}
                    onChange={({ target }) => {
                      selectAllProductSelect();
                    }}
                    sx={styles.checkbox}
                    disabled={loader}
                  />
                )}
                <Box sx={{ fontSize: 12, fontWeight: 500 }}>{item?.title}</Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default HeaderInfoRow;

const styles = {
  checkbox: {
    p: 0,
    transform: "scale(1.3)",
    "&.Mui-checked": {
      color: greyColor8,
    },
  },
};
