import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoCardHeader from "./InfoCardHeader";
import {
  blackColor,
  blackShade05,
  blueColor02,
  blueColor04,
  blueColorBG01,
  blueColorM01,
  blueColorMo11,
  colorOrangeLight,
  greyColor50,
  greyColor6,
  greyColorM01,
  greyShade83,
  lightBlueColor03,
  lightBlueColor04,
  lightBlueColor05,
  logoImgborder1,
  mobilePrimary,
  orangeStatusColor,
  skyblueColorBG01,
  whiteColor,
  yellowColorBG01,
  yellowColorM01,
} from "../../../configs/styles/muiThemes";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import LabelValueText from "./LabelValueText";
import moment from "moment";
import MobileInfoCardHeader from "../MObileInfoCards/MobileInfoCardHeader";

function OrdersInfo({ orderSummary, recentOrders, onClick }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [orderData, setOrderData] = useState(null);

  const filterOrderDetails = () => {
    console.log("filterOrderDetails:", orderSummary);

    const orderList = orderSummary?.statusCounts;

    const newPendingApproval = orderList?.filter(
      (item) => item?.id === "ordercreated"
    );

    const pendingShipment = orderList?.filter(
      (item) => item?.id === "orderapproved"
    );

    const shipped = orderList?.filter((item) => item?.id === "ordershipped");

    const delivered = orderList?.filter(
      (item) => item?.id === "orderdelivered"
    );

    const filteredData = {
      newPendingApproval,
      pendingShipment,
      shipped,
      delivered,
    };

    console.log("filterOrderDetails:", filteredData);
    setOrderData(filteredData);
  };

  useEffect(() => {
    if (orderSummary) {
      console.log("HITTTTT");
      filterOrderDetails();
    }

    return () => {};
  }, [orderSummary]);

  return (
    <Box>
      {matches && (
        <Grid container spacing={{ xs: 0.5, md: 3 }} alignItems="flex-end">
          <Grid item md={7} xs={12}>
            <InfoCardHeader title="Order Management" onManageClick={onClick} />
            <Box
              display="flex"
              flexDirection={{ xs: "row", md: "row" }}
              alignItems={{ xs: "none", md: "center" }}
              columnGap={3.5}
              sx={styles.orderCard}
              mt={1}
            >
              <StatsCard
                label={matches ? "NEW/PENDING APPROVAL" : "NEW/PENDING"}
                value={orderData?.newPendingApproval?.[0]?.count || 0}
                icon={
                  <Inventory2OutlinedIcon sx={styles.icon(orangeStatusColor)} />
                }
                bgColor={colorOrangeLight}
                color={matches ? orangeStatusColor : mobilePrimary}
                hideMobile
              />

              <StatsCard
                label={"PENDING SHIPMENT"}
                value={orderData?.pendingShipment?.[0]?.count || 0}
                icon={
                  <PendingActionsIcon sx={styles.icon(orangeStatusColor)} />
                }
                bgColor={colorOrangeLight}
                color={matches ? orangeStatusColor : mobilePrimary}
                hideMobile
              />

              <StatsCard
                label="SHIPPED"
                value={orderData?.shipped?.[0]?.count || 0}
                icon={<LocalShippingIcon sx={styles.icon(blueColor04)} />}
                bgColor={greyShade83}
                hideMobile
                flex={0.7}
              />
            </Box>
          </Grid>
          <Grid item md={5} xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "row", md: "row" }}
              alignItems={{ xs: "none", md: "center" }}
              columnGap={3.5}
              sx={styles.orderDateCard}
              mt={1}
            >
              <StatsCard
                label="TOTAL ORDERS"
                value={orderSummary?.totalOrders || 0}
                icon={
                  <Inventory2OutlinedIcon
                    sx={styles.icon(matches ? whiteColor : mobilePrimary)}
                  />
                }
                bgColor={matches ? blueColor04 : whiteColor}
                color={matches ? blackShade05 : whiteColor}
                labelColor={matches ? greyColor6 : whiteColor}
                valueSize={14}
              />

              <StatsCard
                label="LATEST ORDER DATE"
                value={
                  recentOrders?.length > 0
                    ? moment(recentOrders[0]?.orderDate).format("Do MMM YYYY")
                    : "N/A"
                }
                icon={
                  <CalendarMonthIcon
                    sx={styles.icon(matches ? whiteColor : mobilePrimary)}
                  />
                }
                bgColor={matches ? blueColor04 : whiteColor}
                color={matches ? blackShade05 : whiteColor}
                labelColor={matches ? greyColor6 : whiteColor}
                valueSize={14}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      {!matches && (
        <Grid container spacing={{ xs: 1.5, md: 3 }} flexWrap={"wrap"}>
          <Grid item md={7} xs={12}>
            <MobileInfoCardHeader
              title="Order Management"
              onManageClick={onClick}
            />
          </Grid>
          <Grid
            item
            md={7}
            xs={6}
            display="flex"
            alignItems="stretch"
            justifyContent={"center"}
            flexGrow={1}
          >
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              alignItems={{ xs: "none", md: "center" }}
              columnGap={3.5}
              sx={styles.orderCard}
              mt={1}
            >
              <MobileStatsCard
                label={matches ? "NEW/PENDING APPROVAL" : "New/Pending"}
                value={orderData?.newPendingApproval?.[0]?.count || 0}
                icon={
                  <Inventory2OutlinedIcon sx={styles.icon(mobilePrimary)} />
                }
                bgColor={lightBlueColor05}
                color={matches ? orangeStatusColor : mobilePrimary}
              />
              <MobileStatsCard
                label={"Pending Shipment"}
                value={orderData?.pendingShipment?.[0]?.count || 0}
                icon={<PendingActionsIcon sx={styles.icon(yellowColorM01)} />}
                bgColor={yellowColorBG01}
                color={matches ? orangeStatusColor : yellowColorM01}
              />
              <MobileStatsCard
                label="Shipped"
                value={orderData?.shipped?.[0]?.count || 0}
                icon={<LocalShippingIcon sx={styles.icon(blueColorM01)} />}
                bgColor={blueColorBG01}
                color={matches ? orangeStatusColor : blueColorM01}
                flex={0.7}
                hideMobileBorder
              />
            </Box>
          </Grid>
          <Grid
            item
            md={7}
            xs={6}
            display="flex"
            alignItems="stretch"
            justifyContent={"center"}
            flexGrow={1}
          >
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              flexWrap={"wrap"}
              mt={1}
            >
              <Box
                flex={1}
                display="flex"
                flexDirection={{ xs: "row", md: "row" }}
                alignItems={{ xs: "center", md: "center" }}
                sx={{
                  ...styles.orderDateCardMobile,
                  background: skyblueColorBG01,
                }}
                flexWrap={"wrap"}
              >
                <MobileStatsCard
                  label="Total Orders"
                  value={orderSummary?.totalOrders || 0}
                  icon={
                    <AttachMoneyIcon
                      sx={styles.icon(matches ? whiteColor : blackColor)}
                    />
                  }
                  bgColor={matches ? blueColor04 : whiteColor}
                  color={matches ? blackShade05 : blackColor}
                  labelColor={matches ? greyColor6 : blackColor}
                  valueSize={14}
                  hideMobileBorder
                />
              </Box>
              <Box
                flex={1}
                display="flex"
                flexDirection={{ xs: "row", md: "row" }}
                alignItems={{ xs: "center", md: "center" }}
                // columnGap={3.5}
                // sx={{ ...styles.orderDateCard, background: skyblueColorBG01}}
                sx={{
                  ...styles.orderTotalCardMobile,
                  background: skyblueColorBG01,
                }}
                mt={3}
              >
                <MobileStatsCard
                  label="Latest Order Date"
                  value={
                    recentOrders?.length > 0
                      ? moment(recentOrders[0]?.orderDate).format("Do MMM YYYY")
                      : "N/A"
                  }
                  icon={
                    <EventRepeatIcon
                      sx={styles.icon(matches ? whiteColor : blackColor)}
                    />
                  }
                  bgColor={matches ? blueColor04 : whiteColor}
                  color={matches ? blackShade05 : blackColor}
                  labelColor={matches ? greyColor6 : blackColor}
                  valueSize={14}
                  hideMobileBorder
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default OrdersInfo;

const StatsCard = ({
  icon,
  label,
  value,
  color,
  bgColor,
  labelColor,
  valueSize,
  hideMobile,
  flex,
}) => {
  return (
    <Box sx={styles.card(flex)}>
      <Box sx={styles.iconContainer(bgColor, hideMobile)}>{icon}</Box>
      <Stack>
        <Typography
          variant="body2"
          fontSize={{ xs: 10, md: 11.28 }}
          color={labelColor || greyColor6}
          fontWeight={500}
          lineHeight="20px"
        >
          {label}
        </Typography>

        <Typography
          component="h6"
          fontSize={{ xs: valueSize || 15, md: 15 }}
          color={color || blackShade05}
          fontWeight={600}
        >
          {value}
        </Typography>
      </Stack>
    </Box>
  );
};

const MobileStatsCard = ({
  icon,
  label,
  value,
  color,
  bgColor,
  labelColor,
  valueSize,
  hideMobile,
  flex,
  hideMobileBorder,
}) => {
  return (
    <Box
      sx={{
        ...styles.card(flex),
        borderBottom: hideMobileBorder ? "" : `1px solid #F5F7FC`,
        pb: 0.5,
        pt: 0.5,
      }}
    >
      <Box sx={styles.iconContainer(bgColor, hideMobile)}>{icon}</Box>
      <Stack>
        <Typography
          variant="body2"
          fontSize={{ xs: 10, md: 11.28 }}
          color={labelColor || greyColorM01}
          fontWeight={500}
          lineHeight="20px"
        >
          {label}
        </Typography>

        <Typography
          component="h6"
          fontSize={{ xs: valueSize || 15, md: 15 }}
          color={color || blackShade05}
          fontWeight={600}
        >
          {value}
        </Typography>
      </Stack>
    </Box>
  );
};

const styles = {
  orderCard: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px", md: "12px" },
  },
  orderDateCard: {
    backgroundColor: { xs: greyColor50, md: whiteColor },
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "7px 15px", md: "12px" },
  },
  orderDateCardMobile: {
    backgroundColor: { xs: skyblueColorBG01, md: whiteColor },
    border: `1px solid #B5ECFF`,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 18px", md: "12px" },
  },
  orderTotalCardMobile: {
    backgroundColor: { xs: "#FFEEF2", md: whiteColor },
    border: `1px solid #FFE4EB`,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 10px", md: "12px" },
  },
  card: (flex) => ({
    display: "flex",
    alignItems: "center",
    gap: 1,
    flex: flex || 1,
  }),
  iconContainer: (bgColor, hideMobile) => ({
    width: { md: "40px", xs: "34px" },
    height: { md: "40px", xs: "34px" },
    borderRadius: "50%",
    backgroundColor: bgColor,
    display: { md: "flex", xs: hideMobile ? "none" : "flex" },
    alignItems: "center",
    justifyContent: "center",
  }),
  icon: (color) => ({ fontSize: { md: 20, xs: 17 }, color: color }),
};
