import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { BGColor02 } from "../configs/styles/muiThemes";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import {
  getCountryNames,
  getGstRates,
} from "../reducers/catalog/CatalogSlice";
import ThreeDotLoader from "../components/ThreeDotLoader";
import EditProductContent from "./EditProductNew/EditProductContent";

const EditProductNew = () => {
  const dispatch = useDispatch();

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      dispatch(getCountryNames());
      dispatch(getGstRates());
    }
  }, []);

  return (
     <Box sx={styles.container}>
     <DashboardLayout activeMenu="catalog">
       {activeBusinessInfoLoader ? (
         <ThreeDotLoader />
       ) : (
        <EditProductContent />
       )}
     </DashboardLayout>
   </Box>
  )
}

export default EditProductNew

const styles = {
    container: {
      backgroundColor: BGColor02,
    },
  };
  