import React, { useState } from "react";
import ModalCard from "../../../components/ModalCard";
import {
  BGColor02,
  blackShade19,
  blackShade22,
  blueColor04,
  cardBoder,
  greyColor40,
  greyColor42,
  greyColor8,
  greyColor9,
  logoImgborder,
  mobilePrimary,
  orderCardBorder3,
  redColor05,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Radio,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  CATALOG_BRANCH_PUSH_HEADER,
  CATALOG_BRANCH_PUSH_SINGLE_HEADER,
  MULTI_PROD_PUSH_HEADER,
} from "../../../utils/data";
import ProductInfoRowCard from "../Card/ProductInfoRowCard";
import InfiniteScroll from "react-infinite-scroll-component";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import moment from "moment";
import MultiPushProductCard from "../Card/MultiPushProductCard";
import SearchBoxInput from "../../../components/Input/SearchBoxInput";
import SearchInfoCard from "../../SubBranchCalalog/Components/SearchInfoCard";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import ProductFilterCard from "../../SubBranchCalalog/Components/ProductFilterCard";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import FilterListIcon from "@mui/icons-material/FilterList";
import EmptyCard from "../../../components/Alerts/EmptyCard";

const PushMultipleProductPopup = ({
  openModal,
  setOpenModal,

  selectedBranchInfo,
  mainBranchProducts,

  selectedRowCount,
  onProductSelect,
  selectAllProducts,

  fetchMainBranchProducts,
  hasMoreData,
  productLoader,
  getEmptyMessage,
  branchPushLoader,
  onSubmit,
  onCloseMultiProductModel,

  // Filter Props
  searchQuery,
  setSearchQuery,
  handleSearch,
  isClearSearch,
  isClearFilter,
  filterType,
  clearSearch,
  ondcFilterRef,
  setShowWebstoreFilter,
  setShowONDCFilter,
  showONDCFilter,

  ondcCategoryList,
  ondcSubCatList,
  selectedOndcCategory,
  selectedOndcSubCat,
  handleOndcCategoryChange,
  handleOndcSubCategoryChange,
  handleOndcFilter,
  onOndcFilterClose,
  clearAllOndcFilterData,
  filterLoader,
  filterError,
  // Mobile Filter Pops
  setOpenMobileFilterModel,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [showSearchBar, setShowSearchBar] = useState(false);

  return (
    <>
      <ModalCard
        open={openModal}
        handleClose={() => {
          onCloseMultiProductModel(false);
        }}
        width="86%"
      >
        <Box sx={{ backgroundColor: whiteColor, borderRadius: 1.8 }}>
          <Box
            sx={{
              backgroundColor: BGColor02,
              p: 1.5,
              borderRadius: "14.4px 14.4px 0 0",
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              fontSize={16}
              fontWeight={600}
              color={blackShade19}
              mb={0.3}
            >
              Catalog Push to Branch
              <Typography
                variant="body1"
                fontSize={13}
                fontWeight={500}
                color={greyColor9}
              >
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                    color: blueColor04,
                  }}
                >
                  {selectedRowCount} OF {mainBranchProducts?.length}
                </span>{" "}
                products selected
              </Typography>
            </Typography>
          </Box>

          <Box sx={styles.modelBodyBox}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={{ xs: "flex-start", md: "space-between" }}
              mb={1}
              px={1.5}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <Box sx={styles.imgBox}>
                  <Box
                    sx={styles.logoImg}
                    component="img"
                    src={selectedBranchInfo?.logo}
                  />
                </Box>

                <Box>
                  <Typography
                    fontSize={{ xs: 12.5, md: 13.5 }}
                    fontWeight={600}
                    color={blackShade19}
                  >
                    {selectedBranchInfo?.businessName}
                  </Typography>
                </Box>
              </Box>

              <Box
                display={"flex"}
                gap={1}
                position={{ xs: "relative", md: "static" }}
              >
                <Box
                  position={{ md: "relative", xs: "static" }}
                  ref={ondcFilterRef}
                >
                  <Button
                    sx={styles.filterIconBtn}
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      setShowWebstoreFilter(false);
                      setShowONDCFilter((prev) => !prev);
                    }}
                    endIcon={
                      <FilterListRoundedIcon sx={{ fontSize: "17px" }} />
                    }
                  >
                    {matches ? "ONDC Category Filters" : "ONDC Category"}
                  </Button>

                  {showONDCFilter && (
                    <ProductFilterCard
                      title="ONDC Category Filters"
                      categoryList={ondcCategoryList}
                      subCategoryList={ondcSubCatList}
                      selectedCategory={selectedOndcCategory}
                      selectedSubCategory={selectedOndcSubCat}
                      handleCategoryChange={handleOndcCategoryChange}
                      handleSubCategoryChange={handleOndcSubCategoryChange}
                      onApply={handleOndcFilter}
                      onCancel={onOndcFilterClose}
                      onAllClear={clearAllOndcFilterData}
                      loader={filterLoader}
                      error={filterError}
                    />
                  )}
                </Box>
                {/* <Box
              position={{ md: "relative", xs: "static" }}
              ref={webstoreFilterRef}
            >
              <Button
                sx={styles.filterIconBtn}
                variant="contained"
                disableElevation
                onClick={() => {
                  setShowONDCFilter(false);
                  setShowWebstoreFilter((prev) => !prev);
                }}
                endIcon={<FilterListRoundedIcon sx={{ fontSize: "17px" }} />}
              >
                {matches ? "Webstore Category Filters" : "Webstore Category"}
              </Button>

              {showWebstoreFilter && (
                <ProductFilterCard
                  title="Webstore Category Filters"
                  categoryList={categoryList}
                  subCategoryList={subCatList}
                  selectedCategory={selectedCategory}
                  selectedSubCategory={selectedSubCat}
                  handleCategoryChange={handleWebstoreCategoryChange}
                  handleSubCategoryChange={handleWebstoreSubCategoryChange}
                  onApply={handleWebstoreFilter}
                  onCancel={onWebstoreFilterClose}
                  onAllClear={clearAllWebstoreFilterData}
                  error={filterError}
                />
              )}
            </Box> */}
              </Box>
            </Box>

            {mainBranchProducts?.length > 0 || filterType ? (
              matches ? (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={{ md: "center" }}
                  flexDirection={{ md: "row", xs: "column" }}
                  gap={2}
                  px={1.5}
                  mb={2}
                >
                  <Box>
                    {/* <Box width={{ xs: "auto", md: "300px" }}>
                    <SearchBoxInput
                      label=""
                      placeholder="Search Product"
                      value={searchQuery}
                      onChange={(text) => {
                        setSearchQuery(text);
                      }}
                      onSearchClick={handleSearch}
                      isDashboardInput
                    />
                  </Box> */}

                    {(isClearSearch || isClearFilter) && (
                      <Box mt={{ xs: 2.5, md: 1.3 }}>
                        <SearchInfoCard
                          searchQuery={searchQuery}
                          clearSearch={() => {
                            clearSearch(true);
                          }}
                          filterType={filterType}
                          isClearFilter={isClearFilter}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : (
                <Box pb={2}>
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="flex-end"
                    gap={1.5}
                  >
                    <IconButton
                      sx={styles.iconBtn}
                      onClick={() => setOpenMobileFilterModel(true)}
                    >
                      <FilterListIcon sx={{ fontSize: "18px" }} />
                    </IconButton>

                    {/* <IconButton
                    sx={styles.iconBtn}
                    onClick={() => setShowSearchBar(!showSearchBar)}
                  >
                    {showSearchBar ? (
                      <SearchOffIcon sx={{ fontSize: "18px" }} />
                    ) : (
                      <SearchIcon sx={{ fontSize: "18px" }} />
                    )}
                  </IconButton> */}
                  </Box>

                  {showSearchBar && (
                    <Box width={{ xs: "auto", md: "300px" }} mt={1}>
                      <SearchBoxInput
                        label=""
                        placeholder="Search Product"
                        value={searchQuery}
                        onChange={(text) => {
                          setSearchQuery(text);
                        }}
                        onSearchClick={handleSearch}
                        isDashboardInput
                      />
                    </Box>
                  )}

                  {(isClearSearch || isClearFilter) && (
                    <Box mt={{ xs: 2.5, md: 1.3 }}>
                      <SearchInfoCard
                        searchQuery={searchQuery}
                        clearSearch={() => {
                          clearSearch(true);
                        }}
                        filterType={filterType}
                        isClearFilter={isClearFilter}
                      />
                    </Box>
                  )}
                </Box>
              )
            ) : null}

            <Box sx={styles.tableContainer}>
              {matches && mainBranchProducts?.length > 0 && (
                <HeaderRow
                  subBranchList={mainBranchProducts}
                  selectAllProducts={() => {
                    selectAllProducts();
                  }}
                />
              )}

              <Box
                sx={{
                  ...styles.tableBody,
                  height: isClearSearch || isClearFilter ? "50vh" : "55vh",
                }}
              >
                {!productLoader && mainBranchProducts?.length == 0 && (
                  <EmptyCard
                    icon="https://storage.googleapis.com/bodefaults/businessdashboardweb/product-reject.svg"
                    message={getEmptyMessage()}
                    hideBorder
                  />
                )}

                <Box>
                  <InfiniteScroll
                    dataLength={mainBranchProducts?.length || 0}
                    next={() => fetchMainBranchProducts(selectedBranchInfo?.branchId || "", false)}
                    hasMore={hasMoreData}
                    loader={
                      productLoader ? (
                        <Box>
                          <ThreeDotLoader />
                        </Box>
                      ) : null
                    }
                  >
                    {mainBranchProducts?.map((productInfo, index) => {
                      return (
                        <MultiPushProductCard
                          key={`${productInfo?.productId}_${index}`}
                          index={index}
                          isSelected={productInfo?.isSelected || false}
                          onProductSelect={() => {
                            onProductSelect(index);
                          }}
                          loader={branchPushLoader}
                          productImg={productInfo?.productPhoto?.[0]?.docURL}
                          productName={productInfo?.productName}
                          originalPrice={productInfo?.originalPrice}
                          salePrice={productInfo?.salePrice}
                          ondcEnabled={productInfo?.ondcEnabled}
                          lastUpdateDate={productInfo?.lastUpdateDate || null}
                          lastImportDate={
                            productInfo?.productLastImportDate || null
                          }
                          tabValue={0}
                          onPushProduct={() => {
                            // setOpenSingleProductPopup({
                            //   show: true,
                            //   productInfo: productInfo,
                            // });
                          }}
                          isAllSelected={mainBranchProducts?.every(
                            (item) => item?.isSelected
                          )}
                          selectAllProducts={selectAllProducts}
                          isSynced={productInfo?.isSynced}
                        />
                      );
                    })}
                  </InfiniteScroll>

                  {hasMoreData && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      p={1}
                    >
                      <Button
                        sx={styles.loadMoreBtn}
                        variant="contained"
                        disableElevation
                        onClick={() => fetchMainBranchProducts(selectedBranchInfo?.branchId || "", false)}
                        startIcon={
                          productLoader && (
                            <CircularProgress size={16} color="inherit" />
                          )
                        }
                      >
                        {productLoader ? "Loading..." : "Load More"}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: BGColor02,
              p: { xs: "10px 10px 10px 10px", md: "10px 16px 16px 16px" },
              borderRadius: "0 0 14.4px 14.4px",
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={4}
            >
              <Box />
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  sx={styles.backBtn}
                  disableElevation
                  onClick={() => {
                    onCloseMultiProductModel(false);
                  }}
                >
                  CANCEL
                </Button>

                <Button
                  variant="contained"
                  disableElevation
                  sx={styles.applyBtn}
                  onClick={() => {
                    onSubmit();
                  }}
                  disabled={
                    branchPushLoader ||
                    mainBranchProducts?.every((item) => !item.isSelected)
                  }
                  startIcon={
                    branchPushLoader && (
                      <CircularProgress size={16} sx={{ color: "inherit" }} />
                    )
                  }
                >
                  MULTI PRODUCT PUSH
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>
      </ModalCard>
    </>
  );
};

export default PushMultipleProductPopup;

const HeaderRow = ({ subBranchList, selectAllProducts, loader, tabValue }) => {
  return (
    <Box
      sx={{
        backgroundColor: greyColor42,
        zIndex: 20,
        borderRadius: "8px 8px 0 0",
      }}
    >
      <Grid container>
        {MULTI_PROD_PUSH_HEADER?.map((item, index) => {
          return (
            <Grid
              key={item.title}
              item
              md={item.col}
              borderRight={`1px solid ${whiteColor}`}
            >
              <Box
                display={index === 0 ? "flex" : "inline-block"}
                alignItems="center"
                gap={2.5}
                padding="4px 15px"
              >
                {index === 0 && (
                  <Checkbox
                    checked={subBranchList?.every((item) => item?.isSelected)}
                    onChange={({ target }) => {
                      selectAllProducts();
                    }}
                    sx={styles.checkbox}
                    disabled={loader}
                  />
                )}
                <Box sx={{ fontSize: 11, fontWeight: 500 }}>{item?.title}</Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const styles = {
  backBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: whiteColor,
    border: `1px solid ${redColor05}`,
    color: redColor05,
    p: "5px 20px",
    "&:hover": {
      color: redColor05,
      backgroundColor: whiteColor,
    },
  },

  applyBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: blueColor04,
    border: `1px solid ${blueColor04}`,
    color: whiteColor,
    p: "5px 20px",
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },

  modelBodyBox: {
    // height: "60vh",
    // overflowY: "scroll",
    p: "10px 12px 0 12px",
    mb: 1,
  },

  subBranchCard: {
    display: "flex",
    alignItems: "center",
    py: 2,
    borderBottom: logoImgborder,
  },

  logoImg: {
    width: "45px",
    height: "45px",
    border: logoImgborder,
    borderRadius: "5px",
    objectFit: "contain",
  },

  tableContainer: {
    borderRadius: "8px",
  },

  tableBody: {
    overflowY: "scroll",
    border: { xs: cardBoder, md: "none" },
    borderRadius: "8px",
    "&::-webkit-scrollbar": {
      width: { xs: "1.5px", md: "3px" },
      height: "3px",
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      // background: '#d0d3da',
      background: "rgba(0,0,0,0.1)",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.05)",
    },
  },

  statusChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    p: "5px 13px",
    borderRadius: "50px",
    width: "max-content",
  }),

  checkbox: {
    p: 0,
    transform: "scale(1.3)",
    "&.Mui-checked": {
      color: greyColor8,
    },
  },

  filterIconBtn: {
    padding: "4px 12px",
    fontSize: "11px",
    fontWeight: 600,
    color: greyColor9,
    backgroundColor: whiteColor,
    whiteSpace: "nowrap",
    border: "1px solid " + greyColor9,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor9,
    },
  },

  loadMoreBtn: {
    padding: { md: "3px 14px", xs: "3px 14px" },
    fontSize: { md: "12px", xs: "12px" },
    fontWeight: 500,
    color: whiteColor,
    backgroundColor: { xs: mobilePrimary, md: blackShade22 },
    border: {
      xs: `1.5px solid ${mobilePrimary}`,
      md: `1.5px solid ${blackShade22}`,
    },
    "&:hover": {
      border: {
        xs: `1.5px solid ${mobilePrimary}`,
        md: `1.5px solid ${blackShade22}`,
      },
      color: { xs: mobilePrimary, md: blackShade22 },
      backgroundColor: whiteColor,
    },
  },

  iconBtn: {
    width: "40px",
    height: "32px",
    bgcolor: whiteColor,
    borderRadius: "5px",
    border: `1px solid ${greyColor9}`,
  },
};
