import { Box, Typography } from "@mui/material";
import { whiteColor } from "../configs/styles/muiThemes";

function ProfileCharAvatar({ username, border, size, borderRadius, fontSize }) {
  const charList = [
    { char: ["b", "c", "d", "e", "t","1","6"], color: "#ff5c00" },
    { char: ["f", "h", "i", "j","2","7"], color: "#ad00ff" },
    { char: ["m", "n", "o", "p", "q","3","8"], color: "#f20ce6" },
    { char: ["s", "u", "v", "w","4","9"], color: "#de9d0e" },
    { char: ["x", "y", "z", "g", "k","5"], color: "#3d3ac6" },
    { char: ["r", "l", "a","0"], color: "#414141" },
  ];

  const getColor = (key) => {
    let color = "#e8e8e8";

    if (username.length > 0) {
      charList.forEach((item) => {
        if (item.char.includes(username.charAt(0).toLowerCase())) {
          color = item.color;
        }
      });
    }

    return color;
  };

  return (
    <Box
      width={size ? size : "38px"}
      height={size ? size : "38px"}
      borderRadius={borderRadius || "50%"}
      backgroundColor={{
        md: border ? getColor() : `rgb(5 60 81 / 7%)`,
        xs: !border ? getColor() : whiteColor,
      }}
      // backgroundColor='rgb(5 60 81 / 7%)'
      display="flex"
      alignItems="center"
      justifyContent="center"
      border={border ? "1px solid " + getColor() : "none"}
    >
      <Typography
        variant="boby1"
        color={{
          md: !border ? getColor() : whiteColor,
          // xs: border ? getColor() : `rgb(5 60 81 / 7%)`,
          xs: border ? getColor() : whiteColor,
        }}
        fontSize={size ? (fontSize ? fontSize : 20) : 14}
        fontWeight={600}
        textTransform="uppercase"
      >
        {username
          .split(" ")
          .map((word) => word[0])
          .join("")
          .slice(0, 2).length == 2
          ? username
              .split(" ")
              .map((word) => word[0])
              .join("")
              .slice(0, 2)
          : username.substring(0, 2).toUpperCase()}
      </Typography>
    </Box>
  );
}

export default ProfileCharAvatar;
