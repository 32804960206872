import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import {
  bgColor15,
  blackShade05,
  greyColor11,
  greyColor6,
  greyColor8,
  logoImgborder1,
  orangeStatusColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ProfileCharAvatar from "../../../components/ProfileCharAvatar";
function IssueDetailCard() {
  const partnerPic =
    "https://storage.googleapis.com/bo3151920215/account/4d60cacd94144abb91782ce7fbf37ace/profilephoto.jpeg";
  const partnerName = "Vishnupriya";
  const productpic =
    "https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/product/4ad2e576c01540a58e39891be756bfb7/Product1-820670.jpeg";
  const productname = "Anti Blemish Cream - 50g";
  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1} mt={3}>
        <Typography
          component="h4"
          fontSize={15}
          color={bgColor15}
          fontWeight={600}
          lineHeight="20px"
          disabled
        >
          Customer Grievance
        </Typography>{" "}
        <KeyboardDoubleArrowLeftIcon
          sx={{ fontSize: "18px", color: bgColor15 }}
        />
        <Typography
          component="h4"
          fontSize={17}
          color={blackShade05}
          fontWeight={600}
          lineHeight="20px"
        >
          Grievance Details
        </Typography>
      </Box>
      <Box
        display="flex"
        bgcolor={whiteColor}
        padding={"25px"}
        // justifyContent={"center"}
        // alignItems="center"
        flexDirection="column"
        // width="80%"
        m={{ xs:"25px 0", md: "30px 200px" }}
        borderRadius={2}
      >
        <Box
          flex={1}
          display="flex"
          alignItems={{ md: "center", xs: "flex-start" }}
          justifyContent={{ md: "center", xs: "flex-start" }}
        >
          <Typography
            component="h4"
            fontSize={17}
            color={blackShade05}
            fontWeight={600}
            lineHeight="20px"
          >
            Grievance Details
          </Typography>
        </Box>

        <Box
          display="flex"
          alignItems={"flex-start"}
          gap={3}
          flexDirection={"column"}
          borderBottom={`1px solid ${greyColor11}`}
        >
          <Box flex={1} width="100%" mb={2} mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3.8}>
                <Box display="flex" gap="15px" alignItems="center">
                  {partnerPic ? (
                    <Avatar
                      alt="remy sharp"
                      variant="rounded"
                      src={partnerPic}
                      sx={{
                        width: "45px",
                        height: "45px",
                      }}
                    />
                  ) : (
                    <ProfileCharAvatar
                      username={partnerName || "Guest"}
                      border
                      size="50px"
                    />
                  )}
                  <Box gap={1}>
                    <Typography
                      fontSize={{ xs: 13.5, md: 14.5 }}
                      fontWeight={600}
                      color={blackShade05}
                    >
                      {partnerName}
                    </Typography>
                    <IssueDetails
                      label="Status"
                      labelValue={"Pending"}
                      labelValueColor={orangeStatusColor}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2}>
                <IssueDetails label="Order ID" labelValue={"OD9949872021"} />
              </Grid>
              <Grid item xs={6} md={2}>
                <IssueDetails label="Issue ID" labelValue={"ISE9949872021"} />
              </Grid>
              <Grid item xs={6} md={2.2}>
                <IssueDetails
                  label="Issue Date"
                  labelValue={"02 Nov, 02:35 PM"}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <IssueDetails label="Issue Type" labelValue={"Package"} />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" mt={2}>
          <Typography
            component="h4"
            fontSize={16}
            color={blackShade05}
            fontWeight={600}
            lineHeight="20px"
            // alignItems={"flex-start"}
            marginBottom={2}
          >
            Order Details
          </Typography>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4.5}>
                <Box display="flex" gap="10px" alignItems="center">
                  {productpic ? (
                    <Avatar
                      alt="remy sharp"
                      variant="rounded"
                      src={productpic}
                      sx={{
                        width: "50px",
                        height: "50px",
                        border: `1px solid ${greyColor11}`,
                      }}
                    />
                  ) : (
                    <ProfileCharAvatar
                      username={partnerName || "Guest"}
                      border
                      size="50px"
                    />
                  )}
                  <Typography
                    fontSize={{ xs: 13.5, md: 14.5 }}
                    fontWeight={600}
                    color={blackShade05}
                  >
                    {productname}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} md={2.5}>
                <IssueDetails label="UNIT PRICE" labelValue={"₹340.00"} />
              </Grid>
              <Grid item xs={4} md={2.5}>
                <IssueDetails label="QUANTITY" labelValue={"14"} />
              </Grid>
              <Grid item xs={4} md={2.5}>
                <IssueDetails label="PRICE" labelValue={"₹300.00"} />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={3}>
          <Typography
            component="h4"
            fontSize={13}
            color={greyColor6}
            fontWeight={600}
            lineHeight="20px"
            // alignItems={"flex-start"}
            marginBottom={1}
          >
            Description
          </Typography>
          <Typography
            component="h4"
            fontSize={13}
            color={blackShade05}
            fontWeight={600}
            lineHeight="20px"
            // alignItems={"flex-start"}
            marginBottom={1}
          >
            An exquisite Olive Green Kora Benarasi tissue saree gleams with
            chirping 🦜 parrots. An exquisite Olive Green Kora Benarasi tissue
            saree gleams with chirping parrots.
          </Typography>
        </Box>
        <Box mt={3} display="flex" gap={2} flexDirection="column">
          <Typography
            component="h4"
            fontSize={13}
            color={greyColor6}
            fontWeight={600}
            lineHeight="20px"
            // alignItems={"flex-start"}
            marginBottom={1}
          >
            Images
          </Typography>
          <Box>
            <Grid container spacing={{xs:3,md:"none"}} >
              <Grid item xs={6} md={3}>
                <Avatar
                  alt="remy sharp"
                  variant="rounded"
                  src={partnerPic}
                  sx={{
                    width: "100px",
                    height: "100px",
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <Avatar
                  alt="remy sharp"
                  variant="rounded"
                  src={partnerPic}
                  sx={{
                    width: "100px",
                    height: "100px",
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <Avatar
                  alt="remy sharp"
                  variant="rounded"
                  src={partnerPic}
                  sx={{
                    width: "100px",
                    height: "100px",
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <Avatar
                  alt="remy sharp"
                  variant="rounded"
                  src={partnerPic}
                  sx={{
                    width: "100px",
                    height: "100px",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        bgcolor={whiteColor}
        padding={"25px"}
        // justifyContent={"center"}
        // alignItems="center"
        flexDirection="column"
        // width="80%"
        m={{xs:"25px 0",md:"30px 200px"}}
        borderRadius={2}
      >
        <Box
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            component="h4"
            fontSize={17}
            color={blackShade05}
            fontWeight={600}
            lineHeight="20px"
          >
            Grievance Details
          </Typography>
        </Box>
        <Box mt={3} borderBottom={`1px solid ${greyColor6}`}>
          <Typography
            component="h4"
            fontSize={13}
            color={greyColor6}
            fontWeight={600}
            lineHeight="20px"
            // alignItems={"flex-start"}
            marginBottom={1}
          >
            Customer Comments
          </Typography>
          <Typography
            component="h4"
            fontSize={13}
            color={blackShade05}
            fontWeight={600}
            lineHeight="20px"
            // alignItems={"flex-start"}
            marginBottom={1}
          >
            An exquisite Olive Green Kora Benarasi tissue saree gleams with
            chirping parrots.
          </Typography>
        </Box>
        <Box mt={3} borderBottom={`1px solid ${greyColor6}`}>
          <Typography
            component="h4"
            fontSize={13}
            color={greyColor6}
            fontWeight={600}
            lineHeight="20px"
            // alignItems={"flex-start"}
            marginBottom={1}
          >
            Business Resolution
          </Typography>
          <Typography
            component="h4"
            fontSize={13}
            color={blackShade05}
            fontWeight={600}
            lineHeight="20px"
            // alignItems={"flex-start"}
            marginBottom={1}
          >
            An exquisite Olive Green Kora Benarasi tissue saree gleams with
            chirping parrots.
          </Typography>
        </Box>
        <Box mt={3} borderBottom={`1px solid ${greyColor6}`} gap={3}>
          <Typography
            component="h4"
            fontSize={13}
            color={greyColor6}
            fontWeight={600}
            lineHeight="20px"
            // alignItems={"flex-start"}
            marginBottom={6}
          >
            Resolution
          </Typography>
          <Typography
            component="h4"
            fontSize={13}
            color={blackShade05}
            fontWeight={600}
            lineHeight="20px"
            // alignItems={"flex-start"}
            marginBottom={1}
          ></Typography>
        </Box>
        <Box mt={3}>
          <Button
            sx={styles.addIconBtn}
            variant="contained"
            disableElevation
            // onClick={handleViewIssue}
          >
            Add Resolution
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default IssueDetailCard;

const IssueDetails = ({ label, labelValue, labelValueColor }) => {
  return (
    <Box flex={1}>
      <Typography variant="h5" sx={styles.valueTitle}>
        {label}
      </Typography>
      <Typography
        sx={{
          ...styles.value,
          color: labelValueColor ? labelValueColor : blackShade05,
        }}
      >
        {labelValue}
      </Typography>
    </Box>
  );
};

const styles = {
  infoBox: {
    backgroundColor: whiteColor,
    baxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    padding: "15px 15px",
    border: logoImgborder1,
    borderRadius: "10px",
    marginTop: "22px",
  },
  valueTitle: {
    color: greyColor6,
    fontSize: "13px",
    fontWeight: "400",
  },

  value: {
    color: blackShade05,
    fontSize: "13px",
    fontWeight: "500",
  },
  addIconBtn: {
    padding: "5px 24px",
    fontSize: "12px",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: greyColor8,
    border: "1px solid " + greyColor8,
    "&:hover": {
      color: greyColor8,
      backgroundColor: whiteColor,
    },
  },
};
