import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blueColor04,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { DASHBOARD_BANNERS } from "../../../utils/data";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

const DashboardBanners = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3500,
    arrows: false,
  };

  const handleClick = (route) => {
    navigate(`/${sessionStorage.getItem("costboWEBURL")}${route}`);
  };

  return (
    <Box mt={{ xs: 1.5, md: 0.8 }}>
      <Typography
        component="p"
        fontSize={{ xs: 14, md: 14.5 }}
        color={blackShade05}
        fontWeight={600}
        lineHeight="20px"
      >
        Great features readily available to increase sales many folds.
      </Typography>

      <Box mt={{ xs: 1.5, md: 1.2 }}>
        {matches ? (
          <Grid container spacing={4}>
            {DASHBOARD_BANNERS.map((item) => (
              <Grid item xs={4} key={item.id}>
                <Box
                  component="img"
                  src={item.imgUrl}
                  alt={item.alt}
                  sx={styles.bannerImg}
                  onClick={() => {
                    handleClick(item.route);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Slider {...settings}>
            {DASHBOARD_BANNERS.map((item) => (
              <Box
                key={item.id}
                component="img"
                src={item.imgUrl}
                alt={item.alt}
                sx={styles.bannerImg}
                // px="5px"
                onClick={() => {
                  handleClick(item.route);
                }}
              />
            ))}
          </Slider>
        )}
      </Box>
    </Box>
  );
};

export default DashboardBanners;

const styles = {
  bannerImg: {
    width: { xs: "calc(100vw - 30px)", md: "100%" },
    height: "auto",
    objectFit: "contain",
    borderRadius: "9px",
    cursor: "pointer",
  },
  
};
