import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import InputPreview from "../../../components/Input/InputPreview";
import { blackColor, whiteColor } from "../../../configs/styles/muiThemes";
import moment from "moment";

function BusinessTimingPreviewCard({ data }) {
  const DAY_TIME_LIST = [
    { day: "Sunday", timing: "12:00 AM - 11:59 PM" },
    { day: "Monday", timing: "12:00 AM - 11:59 PM" },
    { day: "Tuesday", timing: "12:00 AM - 11:59 PM" },
    { day: "Wednesday", timing: "12:00 AM - 11:59 PM" },
    { day: "Thursday", timing: "12:00 AM - 11:59 PM" },
    { day: "Friday", timing: "12:00 AM - 11:59 PM" },
    { day: "Saturday", timing: "12:00 AM - 11:59 PM" },
  ];

  const [datTimeList, setDatTimeList] = useState(DAY_TIME_LIST);

  useEffect(() => {
    const newList = [];

    if (data?.businessTime === "all-days") {
      data?.businessHours?.forEach((item) => {
        newList.push({
          day: item?.day,
          timing: `${moment(data?.allDays?.from, "HH:mm").format(
            "hh:mm A"
          )} - ${moment(data?.allDays?.to, "HH:mm").format("hh:mm A")}`,
        });
      });
    }

    if (data?.businessTime === "select-specific-days") {
      data?.businessHours?.forEach((item) => {
        if (item?.isClosed) {
          newList.push({
            day: item?.day,
            timing: `${moment(item?.from, "HH:mm").format(
              "hh:mm A"
            )} - ${moment(item?.to, "HH:mm").format("hh:mm A")}`,
          });
        }
      });
    }

    if (newList?.length === 0) {
      setDatTimeList(DAY_TIME_LIST);
      return;
    }

    setDatTimeList(newList);
    return () => {};
  }, []);

  return (
    <Box sx={styles.section}>
      <Typography
        component="h5"
        fontSize={15}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        m="5px 0 15px !important"
      >
        Business Timing
      </Typography>

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        {datTimeList?.map((item, index) => (
          <Grid item xs={6} md={3} key={`timing_${index}`}>
            <InputPreview label={item?.day} value={item?.timing} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default BusinessTimingPreviewCard;

const styles = {
  section: {
    p: "10px 20px 20px",
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: "10px",
    mt: { xs: "10px", md: "auto" },
  },
};
