import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import useDynamicColor from "../hooks/useDynamicColor";
import { textOneLines } from "../utils/styles";

function CopyTextWithIcon({ text, onLinkClick }) {
  const [isCopied, setIsCopied] = useState(false);
  const mainColor = useDynamicColor()

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <Box display="flex" alignItems="center" gap="12px">
      <Typography
        variant="body2"
        // component="a"
        fontSize={{xs:12, md:13}}
        fontWeight={500}
        color={mainColor}
        sx={{ textOneLines, textDecoration: "underline", cursor:'pointer', lineBreak:'anywhere' }}
        onClick={onLinkClick}
      >
        {text}
      </Typography>

      {/* <IconButton sx={{ width: "18px", height: "18px" }} onClick={handleCopy}>
        {isCopied ? (
          <CheckCircleOutlinedIcon sx={{ fontSize: 17, color: greenColor2 }} />
        ) : (
          <ContentCopyOutlinedIcon sx={{ fontSize: 17, color: blueColor04 }} />
        )}
      </IconButton> */}
    </Box>
  );
}

export default CopyTextWithIcon;
