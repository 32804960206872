import React, { useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  blackShade05,
  blackColor,
  blackShade19,
  blueColor04,
  borderTextbox,
  deleteBtnColor,
  descriptionColor,
  greyColor11,
  greyColor24,
  greyColor27,
  greyColor6,
  greyColor8,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import FormInputBox from "../../../components/Input/FormInputBox";

function BuyerAppForm({ buyerLinks, menuList, error, handleOndcInfoChange }) {
  const [emptyError, setEmptyError] = useState("");
  return (
    <Box>
      {console.log("buyerLinks", buyerLinks)}
      <Typography
        component="h6"
        sx={{
          fontSize: 15,
          fontWeight: 600,
          color: blackShade05,
          mt: 2,
          mb: 1,
        }}
      >
        Buyer App
      </Typography>

      {buyerLinks?.map((textInput, index) => (
        <Grid
          container
          display={"flex"}
          alignItems="center"
          justifyContent={{ xs: "flex-end", md: "flex-start" }}
          columnSpacing={3}
          rowSpacing={{ xs: 2, md: 0 }}
        >
          <Grid item xs={5} md={2.5}>
            <SelectWithLabel
              label="Buyer App"
              value={textInput?.displayName}
              onValueChange={(value) => {
                if (emptyError) setEmptyError("");
                if (buyerLinks.some((obj) => obj.code === value?.bapName))
                  setEmptyError("This Buyer app name already selected");
                let data = [...buyerLinks];
                data[index] = {
                  ...data[index],
                  code: value?.bapName,
                  displayName: value?.displayName,
                };
                handleOndcInfoChange("buyerLinks", data);
              }}
              returnFullItem
              menuList={menuList || []}
              valueSelector="displayName"
            />
          </Grid>

          <Grid item xs={5} md={2.5}>
            <Box mt={{ xs: 1, md: 1.5 }}>
              <FormInputBox
                label="Value"
                placeholder=""
                value={textInput?.value}
                onChange={({ target }) => {
                  let data = [...buyerLinks];
                  data[index] = { ...data[index], value: target?.value };
                  handleOndcInfoChange("buyerLinks", data);
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={2} md={0.6}>
            <IconButton
              onClick={() => {
                if (index === buyerLinks?.length - 1 && emptyError)
                  setEmptyError("");
                let data = [...buyerLinks];
                if (data?.length === 1) {
                  handleOndcInfoChange("buyerLinks", [{ code: "", value: "" }]);
                  return;
                }

                data.splice(index, 1);
                handleOndcInfoChange("buyerLinks", data);
              }}
              sx={{ ...styles.iconBtn, ...styles.deleteBtn }}
            >
              <DeleteRoundedIcon sx={{ fontSize: { xs: 18, md: 24 } }} />
            </IconButton>
          </Grid>

          <Grid item xs={12} md={12} mt={0}>
            {buyerLinks?.length - 1 === index && (
              <>
                {emptyError ? (
                  <Typography
                    variant="subtitle1"
                    component="p"
                    fontSize={12}
                    sx={{
                      mt: { xs: "-15px !important", md: "0px !important" },
                      mb: { xs: "0px !important", md: "10px !important" },
                      textAlign: "start !important",
                    }}
                    style={{ color: "red" }}
                  >
                    {emptyError}
                  </Typography>
                ) : null}
                {error ? (
                  <Typography
                    variant="subtitle1"
                    component="p"
                    fontSize={12}
                    sx={{
                      mt: { xs: "-15px !important", md: "0px !important" },
                      mb: { xs: "0px !important", md: "10px !important" },
                      textAlign: "start !important",
                    }}
                    style={{ color: "red" }}
                  >
                    {error}
                  </Typography>
                ) : null}

                <Button
                  sx={styles.addIconBtn}
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    if (textInput?.code && textInput?.value && !emptyError) {
                      const data = [...buyerLinks, { code: "", value: "" }];
                      handleOndcInfoChange("buyerLinks", data);
                    } else
                      setEmptyError(
                        emptyError ? emptyError : "Enter valid input"
                      );
                  }}
                  startIcon={
                    <AddRoundedIcon
                      sx={{ fontSize: { xs: "18px", md: "20px" } }}
                    />
                  }
                >
                  Add Link
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      ))}
    </Box>
  );
}

export default BuyerAppForm;

const styles = {
  btn: (bgColor, textcolor) => ({
    background: bgColor,
    padding: { xs: "3px 24px", md: "4px 45px" },
    fontSize: { xs: "12px", md: "14px" },
    color: textcolor,
    borderRadius: "4px",
    fontWeight: 500,
    border: "1px solid " + bgColor,
  }),

  labelText: {
    fontSize: 13,
    color: greyColor6,
    fontWeight: 400,
    // mb: "8px !important",
    isplay: "inline",
  },

  inputBox: {
    fontSize: 14,
    borderBottom: borderTextbox,
    backgroundColor: whiteColor,
    padding: "7px 0px",
    mb: 1,
  },

  iconBtn: {
    width: "40px",
    height: "40px",
    borderRadius: "5px",
    border: `1px solid rgb(133 147 172)`,
  },

  addBtn: {
    backgroundColor: "rgb(133 147 172)",
    color: whiteColor,
    "&:hover": {
      backgroundColor: whiteColor,
      color: "rgb(133 147 172)",
    },
  },

  deleteBtn: {
    width: { xs: "30px", md: "37px" },
    height: { xs: "30px", md: "37px" },
    border: `1px solid ${deleteBtnColor}`,
    backgroundColor: whiteColor,
    color: deleteBtnColor,
    "&:hover": {
      backgroundColor: deleteBtnColor,
      color: whiteColor,
    },
  },

  demoInput: {
    fontSize: "12px",
    fontWeight: 400,
    color: greyColor6,
    padding: "8px 0 16px",
    borderBottom: `1px solid ${greyColor11}`,
  },

  demoInfoText: {
    fontSize: "13px",
    fontWeight: 400,
    color: greyColor6,
    lineHeight: "20px",
    // padding: "8px 0",
  },

  dotUi: {
    width: "6px",
    height: "6px",
    borderRadius: "8px",
    backgroundColor: greyColor6,
  },

  addIconBtn: {
    padding: "4px 14px",
    fontSize: "12px",
    fontWeight: 600,
    color: { xs: blackShade19, md: blueColor04 },
    backgroundColor: whiteColor,
    border: {
      xs: `1.2px solid ${blackShade19}`,
      md: `1.5px solid ${blueColor04}`,
    },
    "&:hover": {
      color: { xs: blackShade19, md: whiteColor },
      backgroundColor: { xs: whiteColor, md: blueColor04 },
    },
  },
};
