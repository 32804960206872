import React, { useState } from "react";
import { Box } from "@mui/material";
import ApprovePopupContent from "../Popups/ApprovePopupContent";
import ModalCard from "../../../components/ModalCard";
import CancelOrderContent from "./CancelOrderContent";

const OrderCancelPopup = ({
  open,
  handleClose,
  data,
  commerceInfo,
  handleCloseModal,
  orderShipStatus,
  getOndcBapNameBySubscriberId
}) => {
  return (
    <ModalCard
      open={open}
      handleClose={handleCloseModal}
      width="80%"
      // modalType="auth"
    >
      <Box overflow="hidden">
        <CancelOrderContent
          orderData={data}
          handleClose={handleClose}
          commerceInfo={commerceInfo}
          orderShipStatus={orderShipStatus}
          getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
        />
      </Box>
    </ModalCard>
  );
};

export default OrderCancelPopup;
