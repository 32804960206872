import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  styled,
  tooltipClasses,
  IconButton,
} from "@mui/material";
import React from "react";
import {
  blackShade159,
  blackShade22,
  blueColor04,
  greyColor13,
  greyColor7,
  logoImgborder1,
  mobilePrimary,
  redColor,
  redColor01,
  whiteColor,
} from "../../configs/styles/muiThemes";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: whiteColor,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    border: "1px solid " + greyColor7,
    fontSize: 11,
  },
}));

function CatalogActionBtns({
  isSaveLoading,
  isLoading,
  onBackClick,
  onSaveClick,
  onNextClick,
  onCancelClick,
  showBackBtn,
  nextBtnText,
  saveBtnText,
  backBtnText,
  hideNextBtn,
  hideSaveBtn,
  saveTooltip,
  hideCancel,
  showPreviewBtn,
  onPreviewSubmitClick,
}) {
  return (
    <Box sx={styles.container}>
      <Box display="flex" alignItems="center" gap="10px">
        {showBackBtn && (
          <IconButton sx={styles.iconBtn} onClick={onBackClick}>
            <SkipPreviousIcon
              sx={{
                fontSize: { xs: "22px", md: "22px" },
                color: blackShade159,
              }}
            />
          </IconButton>
        )}

        {!hideCancel && (
          <Button
            sx={{
              ...styles.cancelbtn,
              // display: { xs: "none", md: "flex" },
            }}
            variant="contained"
            disableElevation
            disableFocusRipple
            onClick={onCancelClick}
            disabled={isLoading}
            // startIcon={<CircularProgress size={14} sx={{color: blackShade159}} />}
          >
            CANCEL
          </Button>
        )}

        {/* <IconButton
          sx={{
            ...styles.iconBtn,
            display: { xs: "flex", md: "none" },
            mr: { xs: 1, md: 0 },
          }}
          onClick={onNextClick}
          disabled={isLoading}
        >
          <SkipNextIcon
            sx={{ fontSize: { xs: "22px", md: "24px" }, color: blackShade159 }}
          />
        </IconButton> */}
      </Box>

      <Box sx={!hideSaveBtn && !hideNextBtn ? styles.saveNextBtnContainer : {}}>
        {hideSaveBtn ? (
          <Box />
        ) : (
          <LightTooltip
            title={<span style={{ fontSize: "11px" }}>{saveTooltip}</span>}
            placement="right"
          >
            <Button
              sx={styles.saveBtn}
              variant="contained"
              disableElevation
              disableFocusRipple
              onClick={onSaveClick}
              disabled={isSaveLoading}
              // startIcon={<CircularProgress size={14} sx={{color: blackShade159}} />}
            >
              {saveBtnText || "SAVE"}
            </Button>
          </LightTooltip>
        )}

        {hideNextBtn ? null : (
          <Box display="flex" alignItems="center" gap="10px">
            {showPreviewBtn && (
              <Button
                sx={{ ...styles.saveBtn }}
                variant="contained"
                disableElevation
                disableFocusRipple
                onClick={onPreviewSubmitClick}
                disabled={isLoading}
                startIcon={
                  isLoading && (
                    <CircularProgress size={14} sx={{ color: blackShade159 }} />
                  )
                }
              >
                SUBMIT
              </Button>
            )}

            <Button
              sx={{
                ...styles.saveBtn,
                ...styles.nextBtn
                // display: { xs: "none", md: "flex" },
              }}
              variant="contained"
              disableElevation
              onClick={onNextClick}
              disabled={isLoading}
              disableFocusRipple
              startIcon={
                isLoading && (
                  <CircularProgress size={14} sx={{ color: blackShade159 }} />
                )
              }
            >
              {nextBtnText || "NEXT"}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default CatalogActionBtns;

const styles = {
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  btn: (bgColor, textcolor, borderColor) => ({
    background: bgColor,
    borderRadius: { xs: "4px", md: "6px" },
    padding: { xs: "5px 5px", md: "6px 35px" },
    fontSize: { xs: "11px", md: "14px" },
    color: textcolor,
    borderRadius: { md: "6px", xs: "0.4rem" },
    fontWeight: 500,
    border: "1px solid " + borderColor,

    "&.Mui-disabled": {
      border: "none",
    },
  }),

  btnHover: (textcolor, bgColor) => ({
    border: `1px solid ${blackShade159}`,
    "&:hover": {
      border: `1px solid ${blackShade159}`,
      backgroundColor: bgColor || whiteColor,
      color: textcolor,
    },
  }),

  saveBtn: {
    backgroundColor: whiteColor,
    padding: { md: "6.5px 24px", xs: "4px 8px" },
    fontSize: "12px",
    fontWeight: 600,
    color: {xs: mobilePrimary,md:blueColor04},
    border: {xs:`1.5px solid ${mobilePrimary}`, md:`1.5px solid ${blueColor04}`},
    "&:hover": {
      color: whiteColor,
      backgroundColor: {xs: mobilePrimary,md:blueColor04},
    },
  },

  nextBtn: {
    backgroundColor: {xs: mobilePrimary,md:blackShade22},
    color: whiteColor,
    border: {xs:`1.5px solid ${mobilePrimary}`, md:`1.5px solid ${blackShade22}`},
    "&:hover": {
      color: {xs: whiteColor,md:blackShade22},
      backgroundColor: {xs: mobilePrimary,md:whiteColor},
    },
  },

  cancelbtn: {
    padding: { md: "6.5px 20px", xs: "4px 8px" },
    fontSize: "12px",
    fontWeight: 600,
    color: redColor,
    backgroundColor: whiteColor,
    border: "1px solid " + redColor,
    "&:hover": {
      color: whiteColor,
      backgroundColor: redColor,
    },
  },

  backBtn: {
    backgroundColor: "transparent",
    padding: { xs: "3px 0px", md: "8px" },
    fontSize: "14px",
    color: blackShade159,
    textTransform: "unset",
    textDecoration: "underline",
    border: "1px solid " + blackShade159,
    "&:hover": {
      backgroundColor: "transparent",
      color: greyColor13,
    },
  },

  iconBtn: {
    width: "45px",
    height: "auto",
    p: {xs:"4px", md:'6px'},
    borderRadius: "4px",
    border: `1px solid ${blackShade159}`,
  },

  saveNextBtnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,

    "&.Mui-disabled": {
      border: "none",
    },
  },
};
