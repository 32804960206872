import {
  Box,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  bgColor25,
  blackShade05,
  blackShade19,
  blackShade21,
  blueColor04,
  borderTextBoxColor,
  greenColor,
  greyColor40,
  greyColor42,
  greyColor47,
  lightRedColor,
  orangeShade11,
  redColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import {
  getOndcFullSpecificationAPI,
  getOndcSpecificationAPI,
} from "../../service/api_calls/AddProduct/AddProductAPI";
import {
  extractNameCodePairs,
  extractNames,
  getOptionsForDependentCode,
  getValueByCode,
} from "../../utils/helper";
import { textOneLines } from "../../utils/styles";
import OndcSpecInput from "./Components/OndcSpecInput/OndcSpecInput";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import TableDropdown from "./Components/TableDropdown";
import QuickOndcSpecUpdate from "./Components/QuickOndcSpecUpdate";
import EmptyCatalogData from "./Components/EmptyCatalogData";
import { getNameByCode, getValueByPath } from "../../utils/validationHelper";
import { toTitleCase } from "../../utils/validationUtils";

const AddOndcSpecifications = ({
  selectedProdGroup,
  setSelectedProdGroup,
  productDetails,
  openQuickSpecUpdateModal,
  setOpenQuickSpecUpdateModal,
  selectedOndcSpecCount,
  setSelectedOndcSpecCount,
  setIsDynamicSpecEmpty,
  ondcSpecLoader,
  setOndcSpecLoader,
}) => {
  const [tableHeaderList, setTableHeaderList] = useState([]);
  const [dymanicSpecList, setDymanicSpecList] = useState([]);

  const getTableHeaders = (specList) => {
    const headerList = [
      { id: "01", title: "PRODUCT NAME", isRequired: true, headerFixed: true },
    ];

    specList?.forEach((product, index) => {
      if (product?.display?.toLowerCase() !== "n") {
        headerList.push({
          id: index + 4,
          title: product?.displayName?.toUpperCase(),
          isRequired: product?.required?.toLowerCase() === "m",
          hint: product?.hintText || "",
        });
      }
    });

    if (specList?.length > 0) {
      headerList.push({
        id: "100",
        title: "ACTION",
      });
    }

    setTableHeaderList(headerList);
    console.log("SpecListData___tableHeaderList", headerList);
    console.log("SpecListData___dymanicSpecList", specList);
  };

  // adding dymaic specs inside each product objects
  const prepareSelectedProdGroup = (specList, specificationInfo) => {
    const productList = [...selectedProdGroup?.products];
    const newProductList = [];

    const mapByCode = specList?.reduce((acc, obj) => {
      acc[obj.code] = obj;
      return acc;
    }, {});

    const specificationList = [...specList];

    console.log("prepareSelectedProdGroup___mapByCode:", mapByCode);
    console.log(
      "prepareSelectedProdGroup___specificationList:",
      specificationInfo
    );

    productList.forEach((productInfo) => {
      const array = specificationInfo?.details?.[0]?.attributes?.map((obj1) => {
        // Check if there is a matching object in the second array based on code
        if (mapByCode[obj1?.value]) {
          console.log(
            "prepareSelectedProdGroup___specificationInputValue",
            productInfo,
            mapByCode[obj1?.value]?.dbObjectName,
            getValueByPath(productInfo, mapByCode[obj1?.value]?.dbObjectName)
          );
          // Merge properties from the second array object to the first array object
          console.log("map", mapByCode[obj1?.value]?.type);
          if (mapByCode[obj1?.value]?.dbObjectName === "list") {
            if (mapByCode[obj1?.value]?.type === "codeValue") {
              const colorList = extractNameCodePairs(
                mapByCode[obj1?.value]?.values
              );
              const colorCode = getValueByCode(
                productInfo?.ondc?.attributeList,
                mapByCode[obj1?.value]?.code
              );
              const colourName = getNameByCode(colorList, colorCode);
              return {
                ...obj1,
                required: obj1?.code,
                ...mapByCode[obj1?.value],
                inputValue: toTitleCase(colourName) || "",
                options: extractNames(mapByCode[obj1?.value]?.values),
                nameCodes: extractNameCodePairs(mapByCode[obj1?.value]?.values),
              };
            }

            if (mapByCode[obj1?.value]?.display === "N") {
              return {
                ...obj1,
                required: "o",
                ...mapByCode[obj1?.value],
                inputValue: mapByCode[obj1?.value]?.defaultValue,
              };
            }

            if (mapByCode[obj1?.value]?.type === "dependentCode") {
              return {
                ...obj1,
                required: obj1?.code,
                ...mapByCode[obj1?.value],
                inputValue:
                  getValueByCode(
                    productInfo?.ondc?.attributeList,
                    mapByCode[obj1?.value]?.code
                  ) || "",
                options: getOptionsForDependentCode(
                  mapByCode[obj1?.value]?.dependentValues,
                  specificationInfo?.details?.[0]?.pscCode1
                ),
              };
            }

            return {
              ...obj1,
              required: obj1?.code,
              ...mapByCode[obj1?.value],
              inputValue:
                getValueByCode(
                  productInfo?.ondc?.attributeList,
                  mapByCode[obj1?.value]?.code
                ) || "",
              // mapByCode[obj1?.value]?.type === "default"
              //   ? mapByCode[obj1?.value]?.values?.[0]
              //   : "",
            };
          }
          if (mapByCode[obj1?.value]?.display === "N") {
            return {
              ...obj1,
              required: "o",
              ...mapByCode[obj1?.value],
              inputValue: mapByCode[obj1?.value]?.defaultValue,
            };
          }
          if (mapByCode[obj1?.value]?.type === "codeValue") {
            return {
              ...obj1,
              required: obj1?.code,
              ...mapByCode[obj1?.value],
              inputValue: "",
              options: extractNames(mapByCode[obj1?.value]?.values),
              nameCodes: extractNameCodePairs(mapByCode[obj1?.value]?.values),
            };
          }
          if (mapByCode[obj1?.value]?.type === "radioButton") {
            const options = mapByCode[obj1?.value]?.radioOptions;

            const radioOptions = options?.map((option) => {
              return {
                ...option,
                value: getValueByPath(productInfo, option?.key),
              };
            });

            return {
              ...obj1,
              required: obj1?.code,
              ...mapByCode[obj1?.value],
              radioOptions,
            };
          }
          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            inputValue:
              getValueByPath(
                productInfo,
                mapByCode[obj1?.value]?.dbObjectName
              ) || "",
            // mapByCode[obj1?.value]?.type === "default"
            //   ? mapByCode[obj1?.value]?.values?.[0]
            //   : "",
          };
        } else {
          // If no match found, simply return the first array object
          return obj1;
        }
      });

      console.log("prepareSelectedProdGroup___FINAL_ARRAY:", array);

      newProductList.push({
        ...productInfo,
        specList: array,
      });
    });

    console.log("prepareSelectedProdGroup___newProductList:", newProductList);
    setSelectedProdGroup((prevState) => ({
      ...prevState,
      products: newProductList,
    }));
  };

  const getOndcSpecsInfo = async (data) => {
    setOndcSpecLoader(true);

    const specificationPayload = {
      pscCode1: data?.pscCode1,
      status: "active",
    };
    const fullSpecificationPayload = {
      domainCode: data?.pcCode,
      status: "active",
    };

    const specification = await getOndcSpecificationAPI(specificationPayload);
    const fullSpecification = await getOndcFullSpecificationAPI(
      fullSpecificationPayload
    );

    const mapByCode = fullSpecification?.details?.reduce((acc, obj) => {
      acc[obj.code] = obj;
      return acc;
    }, {});

    const array = [];

    specification?.details?.[0]?.attributes?.forEach((obj1) => {
      // Check if there is a matching object in the second array based on code
      if (mapByCode[obj1?.value]) {
        // Merge properties from the second array object to the first array object
        console.log("map", mapByCode[obj1?.value]?.type);
        if (mapByCode[obj1?.value]?.display === "N") {
          array.push({
            ...obj1,
            required: "o",
            ...mapByCode[obj1?.value],
            inputValue: mapByCode[obj1?.value]?.defaultValue,
          });
          return;
        }

        if (mapByCode[obj1?.value]?.type === "codeValue") {
          array.push({
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            inputValue: "",
            options: extractNames(mapByCode[obj1?.value]?.values),
            nameCodes: extractNameCodePairs(mapByCode[obj1?.value]?.values),
          });
          return;
        }
        if (mapByCode[obj1?.value]?.type === "radioButton") {
          // const radioOptions = mapByCode[obj1?.value]?.values?.map(
          //   (jsonString) => JSON.parse(jsonString)
          // );
          array.push({
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            radioOptions: mapByCode[obj1?.value]?.radioOptions,
            // radioOptions: radioOptions,
          });
          return;
        }

        if (mapByCode[obj1?.value]?.type === "dependentCode") {
          array.push({
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            inputValue: "",
            options: getOptionsForDependentCode(
              mapByCode[obj1?.value]?.dependentValues,
              data?.pscCode1
            ),
          });
          return;
        }

        array.push({
          ...obj1,
          required: obj1?.code,
          ...mapByCode[obj1?.value],
          inputValue:
            mapByCode[obj1?.value]?.type === "default"
              ? mapByCode[obj1?.value]?.values?.[0]
              : "",
        });
        return;
      }
    });

    console.log("START____array:", array);

    // sort dropdown data in ascending order

    const sortedArray = array.map((arrItem) => {
      if (arrItem?.type === "data") {
        return {
          ...arrItem,
          values: arrItem?.values?.sort() || [],
        };
      }

      return arrItem;
    });

    console.log("START____array_after:", sortedArray);

    setDymanicSpecList(sortedArray);
    prepareSelectedProdGroup(sortedArray, specification);
    getTableHeaders(sortedArray);
    setOndcSpecLoader(false);
    setIsDynamicSpecEmpty(sortedArray?.length === 0);

    // console.log(
    //   "SpecListData___",
    //   data,
    //   specification,
    //   fullSpecification,
    //   array
    // );
  };

  // Handle Specs Value Change
  const handleChange = (
    rootIndex,
    specIndex,
    value,
    selector = "inputValue"
  ) => {
    console.log("SpecListData___handleChange_rootIndex", rootIndex);
    console.log("SpecListData___handleChange_specIndex", specIndex);
    console.log("SpecListData___handleChange_value", value);
    console.log("SpecListData___handleChange_selector", selector);

    // Create a shallow copy of the products array
    const newProductList = [...selectedProdGroup?.products];

    // Create a shallow copy of the specific product object
    const updatedProduct = { ...newProductList[rootIndex] };

    // Create a shallow copy of the specList array within the product
    const updatedSpecList = [...updatedProduct.specList];

    // Update the inputValue of the specified specIndex in the updatedSpecList
    updatedSpecList[specIndex] = {
      ...updatedSpecList[specIndex],
      [selector]: value,
      error: value ? "" : updatedSpecList[specIndex]?.error,
    };

    // Update the specList array within the updatedProduct
    updatedProduct.specList = updatedSpecList;

    // // Updating specErrorCount
    // let errorCount = Number(updatedProduct.specErrorCount)
    // if(value){
    //   errorCount = errorCount - 1
    //   updatedProduct.specErrorCount = errorCount
    //   console.log("SpecListData___ERR_COUNT", errorCount);
    // }

    // Update the product within the newProductList
    newProductList[rootIndex] = updatedProduct;

    console.log("SpecListData___handleChange_newProductList", newProductList);

    setSelectedProdGroup((prevState) => ({
      ...prevState,
      products: newProductList,
    }));
  };

  // Update Select Row Count
  const updateSelectedRowCount = (prodList) => {
    let selectedProdCount = 0;

    prodList?.forEach((item) => {
      if (item?.isSelected) {
        selectedProdCount = selectedProdCount + 1;
      }
    });

    setSelectedOndcSpecCount(selectedProdCount);
  };

  // update isSelected
  const handleIsSelectChange = (rootIndex, value) => {
    // Create a shallow copy of the products array
    const newProductList = [...selectedProdGroup?.products];

    // Create a shallow copy of the specific product object
    const updatedProduct = { ...newProductList[rootIndex] };

    // Update the specList array within the updatedProduct
    updatedProduct.isSelected = value;

    // Update the product within the newProductList
    newProductList[rootIndex] = updatedProduct;

    console.log(
      "SpecListData___handleIsSelectChange_newProductList",
      newProductList
    );

    setSelectedProdGroup((prevState) => ({
      ...prevState,
      products: newProductList,
    }));

    updateSelectedRowCount(newProductList);
  };

  const getErrorCount = (specListData) => {
    let count = 0;

    specListData.forEach((item) => {
      if (
        item?.error !== "" &&
        item?.required?.toLowerCase() == "m" &&
        item?.type != "default"
      ) {
        count = count + 1;
      }
    });

    return count;
  };

  const selectAllProductRows = () => {
    const isAllSelected = selectedProdGroup?.products?.every(
      (item) => item?.isSelected
    );

    // Create a shallow copy of the products array
    const newProductList = [...selectedProdGroup?.products];
    const updatedProductList = [];

    newProductList?.forEach((product) => {
      updatedProductList.push({
        ...product,
        isSelected: !isAllSelected,
      });
    });

    setSelectedProdGroup((prevState) => ({
      ...prevState,
      products: updatedProductList,
    }));
  };

  useEffect(() => {
    const firstProduct = selectedProdGroup?.products?.[0] || null;
    if (firstProduct) {
      const prodMainCatInfo = firstProduct?.productMainCategory?.[0];
      const prodSubCatInfo = firstProduct?.productSubCategory?.[0];
      // console.log("firstProduct__prodMainCatInfo", prodMainCatInfo);
      // console.log("firstProduct__prodSubCatInfo", prodSubCatInfo);

      getOndcSpecsInfo({
        pscCode1: `${prodMainCatInfo?.ondcCode}${prodSubCatInfo?.ondcSubCode}`,
        pcCode: prodMainCatInfo?.ondcCode,
      });

      updateSelectedRowCount(selectedProdGroup?.products || []);
    }
    return () => {};
  }, []);

  return (
    <Box>
      {dymanicSpecList?.length > 0 && openQuickSpecUpdateModal && (
        <Box>
          <QuickOndcSpecUpdate
            dymanicSpecList={dymanicSpecList}
            openModal={openQuickSpecUpdateModal}
            setOpenModal={setOpenQuickSpecUpdateModal}
            selectedProdGroup={selectedProdGroup}
            setSelectedProdGroup={setSelectedProdGroup}
            selectedOndcSpecCount={selectedOndcSpecCount}
          />
        </Box>
      )}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1.5}
      >
        <Box>
          <Typography sx={styles.heading}>ONDC Specifications</Typography>
          <Typography sx={styles.subHeading}>
            {selectedProdGroup?.products?.length || 0} of{" "}
            {productDetails?.length} rows selected for updates and import. Go to
            Step 3 to choose additional rows for import.
          </Typography>
        </Box>

        <Box mr={2}>
          <Typography sx={styles.textStyle}>
            Category:{" "}
            <span style={styles.valueText}>{selectedProdGroup?.topLevel}</span>
          </Typography>
          <Typography sx={styles.textStyle}>
            SubCategory:
            <span style={styles.valueText}>
              {selectedProdGroup?.subCategory}
            </span>
          </Typography>
        </Box>
      </Box>

      {!ondcSpecLoader && dymanicSpecList?.length == 0 && (
        <Typography sx={{ ...styles.subHeading, color: blackShade19, mt: 1 }}>
          No product specifications needed. Please proceed with import
        </Typography>
      )}

      {/* {dymanicSpecList?.length > 0 && tableHeaderList?.length > 0 ? ( */}
      {!ondcSpecLoader ? (
        // dymanicSpecList?.length > 0 && tableHeaderList?.length > 0
        // ? (
        <Box sx={styles.tableWrapper}>
          <TableContainer sx={styles.tableContainer} component={Box}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    position: "sticky !important",
                    top: 0,
                    backgroundColor: greyColor42,
                    zIndex: 20,
                    borderRadius: "8px 8px 0 0",
                  }}
                >
                  {tableHeaderList?.map((item, index) => {
                    return (
                      <TableCell
                        key={item.id}
                        sx={{
                          ...styles.tableCell,
                          ...styles.colSticky,
                          padding: "0px",
                        }}
                      >
                        <Box
                          display={index === 0 ? "flex" : "inline-block"}
                          alignItems="center"
                          gap={1.2}
                          p="8px 15px"
                        >
                          {index === 0 && (
                            <Checkbox
                              // size="large"
                              checked={selectedProdGroup?.products?.every(
                                (item) => item?.isSelected
                              )}
                              // checked={true}
                              onChange={({ target }) => {
                                selectAllProductRows();
                              }}
                              sx={styles.checkbox}
                            />
                          )}
                          <Box sx={{ fontSize: 12 }}>
                            {item?.title}{" "}
                            {item?.isRequired && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </Box>
                        </Box>
                        {dymanicSpecList?.length > 0 && (
                          <Box
                            sx={{
                              width: "100%",
                              bgcolor: bgColor25,
                              fontSize: 11.5,
                              fontWeight: 400,
                              color: orangeShade11,
                              p: "3px 15px",
                            }}
                          >
                            {item?.hint || "-"}
                          </Box>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody sx={{ backgroundColor: whiteColor }}>
                {selectedProdGroup?.products?.map((productInfo, index) => {
                  return (
                    <TableRow
                      key={productInfo?.id}
                      sx={{
                        borderBottom: `1px solid ${borderTextBoxColor}`,
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell
                        sx={{
                          ...styles.tableStickyLeftCell,
                        }}
                      >
                        <Box display="flex" alignItems="flex-start" gap={1.2}>
                          <Checkbox
                            // size="large"
                            sx={styles.checkbox}
                            checked={productInfo?.isSelected || false}
                            onChange={() => {
                              handleIsSelectChange(
                                index,
                                !productInfo?.isSelected
                              );
                            }}
                          />
                          <Typography
                            variant="body1"
                            title={productInfo?.productName}
                            sx={{
                              ...styles.prodNameBox,
                              ...textOneLines,
                            }}
                          >
                            {productInfo?.productName}
                          </Typography>
                        </Box>
                      </TableCell>

                      {productInfo?.specList?.map((specItemInfo, specIndex) => {
                        if (specItemInfo?.display?.toLowerCase() == "n")
                          return null;

                        return (
                          <OndcSpecInput
                            key={`spec_data${specIndex}`}
                            value={specItemInfo?.inputValue}
                            specItemInfo={specItemInfo}
                            // disabled
                            errorMsg={specItemInfo?.error}
                            onChange={(value, selector) => {
                              handleChange(index, specIndex, value, selector);
                            }}
                          />
                        );
                      })}

                      {dymanicSpecList?.length > 0 && (
                        <TableCell
                          sx={{
                            ...styles.tableStickyLeftCell,
                            ...styles.tableStickyRightCell,
                          }}
                        >
                          <Box
                            minWidth="50px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            gap={0.5}
                          >
                            <Stack alignItems="center" justifyContent="center">
                              {productInfo?.specificationError &&
                              getErrorCount(productInfo?.specList || []) > 0 ? (
                                <Box sx={styles.errorCountTag}>
                                  {getErrorCount(productInfo?.specList || [])}
                                </Box>
                              ) : (
                                <CheckCircleIcon
                                  sx={{ fontSize: 16, color: greenColor }}
                                />
                              )}
                            </Stack>

                            {/* <IconButton
                            size="small"
                            onClick={() => {
                              handleDeleteSingleProducts(index);
                            }}
                          >
                            <DeleteIcon
                              sx={{ fontSize: "16px", color: redColor }}
                            />
                          </IconButton> */}
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* <ModalCard
            open={showDeleteAlert?.show}
            handleClose={() => {
              setShowDeleteAlert({ show: false, data: null });
            }}
            width="33%"
          >
            <DeleteAlert
              alertMsg={showDeleteAlert?.data?.message}
              onCancel={() => {
                setShowDeleteAlert({ show: false, data: null });
              }}
              onDelete={() => {
                handleDeleteProducts();
              }}
            />
          </ModalCard> */}
        </Box>
      ) : (
        // ) : (
        //   <EmptyCatalogData
        //     message={`The ${selectedProdGroup?.subCategory} sub-category does not have any ONDC Specs to be updated`}
        //     goBack={() => {
        //       // setCurrentPage("upload");
        //     }}
        //     hideBtn
        //   />
        // )
        <ThreeDotLoader />
      )}
    </Box>
  );
};

export default AddOndcSpecifications;

const styles = {
  textStyle: {
    fontSize: 13,
    fontWeight: 400,
    color: greyColor40,
  },

  valueText: { color: blackShade19, fontWeight: 600, marginLeft: "8px" },

  heading: {
    fontSize: "14px",
    fontWeight: "600",
    color: blackShade19,
    mb: 0.2,
  },
  subHeading: {
    fontSize: "13px",
    fontWeight: "400",
    color: blackShade21,
    mb: 1,
  },

  tableWrapper: {
    width: "calc(100vw - 310px)",
    borderRadius: "8px",
    border: `1px solid ${borderTextBoxColor}`,
  },

  tableContainer: {
    borderRadius: "8px",
    overflow: "scroll",
    maxHeight: "calc(100vh - 370px)",
    maxWidth: "100%",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      // background: '#d0d3da',
      background: "rgba(0,0,0,0.1)",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.05)",
    },
  },

  tableCell: {
    color: greyColor47,
    fontSize: "12.5px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    padding: "8px 15px",
    backgroundColor: greyColor42,
    borderRight: `1px solid ${whiteColor}`,
  },

  colSticky: {
    // position: "-webkit-sticky",
    // position: isSticky ? "sticky" : "unset",
    // width: isSticky ? "100px" : "auto",
    // minWidth: isSticky ? "100px" : "auto",
    // maxWidth: isSticky ? "100px" : "auto",
    // left: "0px",
    // right: isRight ? "0px" : "unset",

    ":first-child": { position: "sticky", left: "0px" },
    // ":nth-last-child(2)": {
    //   position: "sticky",
    //   right: "78px",
    // },
    ":last-child": { position: "sticky", right: "-3px" },
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  statuSticky: (isStatus) => ({
    right: isStatus ? "100px" : "unset",
  }),

  colStickyRight: (isSticky) => ({
    position: "-webkit-sticky",
    position: isSticky ? "sticky" : "unset",
    width: isSticky ? "100px" : "auto",
    minWidth: isSticky ? "100px" : "auto",
    maxWidth: isSticky ? "100px" : "auto",
    right: "0px",
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  }),

  tableBodyCell: (isError) => ({
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: isError ? lightRedColor : whiteColor,
    padding: "8px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,

    boxShadow: isError ? `0px -1px 0 0 ${redColor} inset` : "none",
  }),

  tableStickyLeftCell: {
    minWidth: "70px !important",
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: whiteColor,
    padding: "10px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,
    position: "sticky",
    left: 0,
    zIndex: 10,
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  tableStickyRightCell: {
    position: "sticky",
    right: 0,
    boxShadow: `1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  prodNameBox: {
    minWidth: "250px",
    maxWidth: "250px",
    whiteSpace: "normal",
    fontSize: 12,
    fontWeight: 600,
    color: blackShade19,
    lineHeight: "16px",
  },

  prodDescriptionBox: {
    minWidth: "550px",
    maxWidth: "650px",
    whiteSpace: "normal",
  },

  checkbox: {
    p: 0,
    transform: "scale(1.3)",
    "&.Mui-checked": {
      color: blueColor04,
    },
  },

  errorCountTag: {
    backgroundColor: lightRedColor,
    color: redColor,
    fontSize: 12,
    fontWeight: 600,
    p: "3px 18px",
    borderRadius: "10px",
  },

  deleteBtn: {
    fontSize: 13,
    fontWeight: 500,
    color: whiteColor,
    p: "4px 30px",
    border: `1.5px solid ${redColor}`,
    backgroundColor: redColor,
    "&:hover": {
      color: redColor,
      backgroundColor: whiteColor,
    },
  },
};
