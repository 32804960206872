import { Box, Switch, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  blackShade05,
  blackShade07,
  borderTextbox,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import DashboardFormHeader from "./DashboardFormHeader";
import DashboardStepper from "../DashboardStepper";
import TimeRange from "../../../components/Input/TimeRange";
import RadiosBtnWithLabel from "../../../components/Input/RadiosBtnWithLabel";
import { useState } from "react";
import { STORE_STEP_LIST } from "../../../utils/appData";

function BusinessTimingForm({ gotoNext, goBack }) {
  const [businessTime, setBusinessTime] = useState("");

  const BUSINESS_TIME_LIST = [
    { label: "Open 24 Hrs", value: "open-24-Hrs" },
    { label: "All Days", value: "all-days" },
    { label: "Select specific days", value: "select-specific-days" },
  ];

  return (
    <Box sx={styles.card}>
      <DashboardFormHeader />
      <DashboardStepper
        stepList={STORE_STEP_LIST}
        currentPage="business-timings"
      />

      <Box m="20px 0 15px 0">
        <Typography
          component="h5"
          fontSize={{ xs: 15, md: 18 }}
          color={blackShade05}
          fontWeight={600}
          lineHeight="20px"
        >
          BusinessTiming
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "flex-start", md: "center" },
            justifyContent: "space-between",
            mt: { xs: "6px", md: "15px" },
            pb: "8px",
            borderBottom: borderTextbox,
          }}
        >
          {BUSINESS_TIME_LIST.map((item, index) => {
            return (
              <RadiosBtnWithLabel
                key={"business_time" + index}
                label={item.label}
                labelStyles={styles.labelText}
                checked={businessTime === item.value}
                onValuesChanged={() => {
                  setBusinessTime(item.value);
                }}
              />
            );
          })}
        </Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <Typography
          component="h5"
          fontSize={{ xs: 14, md: 15 }}
          color={blackColor}
          fontWeight={600}
          lineHeight="30px"
          mb="12px !important"
        >
          Select Specific Days
        </Typography>
      </Box>

      <BusinessTiming day="Sunday" isOpen={false} />
      <BusinessTiming day="Monday" isOpen={true} />
      <BusinessTiming day="Tuesday" isOpen={true} />
      <BusinessTiming day="Wednesday" isOpen={true} />
      <BusinessTiming day="Thursday" isOpen={true} />
      <BusinessTiming day="Friday" isOpen={true} />
      <BusinessTiming day="Saturday" isOpen={false} />

      <Box mt="15px">
        <ActionButtons
          onBackClick={goBack}
          onSaveClick={() => {}}
          onNextClick={gotoNext}
          backBtnText="Previous"
          showBackBtn
        />
      </Box>
    </Box>
  );
}

export default BusinessTimingForm;

const BusinessTiming = ({ day, isOpen }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: { xs: "flex-start", md: "center" },
        gap: { xs: "6px", md: "80px" },
        mb: { xs: "12px", md: "24px" },
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", md: "auto" },
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "space-between" },
        }}
      >
        <Typography
          width="90px"
          component="h5"
          fontSize={{ xs: 14, md: 14 }}
          color={blackColor}
          fontWeight={500}
          lineHeight="30px"
        >
          {day}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Switch checked={isOpen} color="warning" />

          <Typography
            width="50px"
            fontSize={{ xs: 13, md: 13.5 }}
            color={blackShade07}
            fontWeight={500}
            lineHeight="20px"
          >
            {isOpen ? "Open" : "Closed"}
          </Typography>
        </Box>
      </Box>

      <TimeRange />
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    p: "20px",
    my: "25px",
  },

  labelText: {
    fontSize: "15px",
    fontWeight: 600,
  },
};
