import { Box, Typography } from "@mui/material";
import React from "react";
import { BULK_UPLOAD_STEPPER } from "../../utils/data";
import {
  blueColor04,
  greyColor37,
  greyColor40,
  whiteColor,
} from "../../configs/styles/muiThemes";

const UploadStepper = ({ currentPage, handleStepperClick }) => {
  const getCurrentStep = (page) => {
    switch (page) {
      case "download":
        return 0;
      case "upload":
        return 1;
      case "assign-category":
        return 2;
      case "review":
        return 3;
      case "ondc":
        return 4;
      case "ondcSpecGroup":
        return 5;
      case "addOndcSpecs":
        return 5;
      case "published":
        return 6;

      default:
        return 0;
    }
  };

  return (
    <Box display="flex" alignItems="center" gap={3}>
      {BULK_UPLOAD_STEPPER.map((item, index) => (
        <Box sx={{ width:'127px', display: "flex", alignItems: "center", gap: 2, position:'relative' }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={1}
            sx={{ cursor: "pointer" }}
            onClick={() => handleStepperClick(item, index)}
          >
            <Box sx={styles.countCircle(getCurrentStep(currentPage) >= index)}>
              {item.id}
            </Box>

            <Typography
              variant="body1"
              fontSize={12.5}
              fontWeight={400}
              color={greyColor37}
            >
              {item.label}
            </Typography>
          </Box>

          {BULK_UPLOAD_STEPPER.length !== index + 1 && (
            <Box
              sx={{
                width: "60px",
                height: "1.5px",
                backgroundColor: greyColor37,
                mt:-3,
                position:'absolute', left:'100px'
              }}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default UploadStepper;

const styles = {
  countCircle: (isActive) => ({
    width: "25px",
    height: "25px",
    borderRadius: "40px",
    backgroundColor: isActive ? blueColor04 : whiteColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
    fontWeight: 500,
    color: isActive ? whiteColor : greyColor40,
    border: isActive ? `1px solid ${blueColor04}` : `1px solid ${greyColor40}`,
  }),
};
