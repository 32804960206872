import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { BGColor02, blackShade05 } from "../configs/styles/muiThemes";
import { useDispatch, useSelector } from "react-redux";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import EmptyCard from "../components/Cards/EmptyCard";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import { stickyHeader } from "../utils/styles";

function Reports() {
  const dispatch = useDispatch();
  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
    }
  }, []);

  return (
    <Box sx={styles.container}>
      {activeBusinessInfo && (
        <DashboardLayout activeMenu="report">
          <Box pt={{xs:1.5, md:0}}>
            <Typography
              variant="h5"
              color={blackShade05}
              fontSize={{ xs: "14.5px", md: "17px" }}
              fontWeight="600"
              sx={stickyHeader}
              
            >
              Reports
            </Typography>

            <EmptyCard
              icon={
                <Box
                  component="img"
                  src="https://storage.googleapis.com/bodefaults/businessdashboardweb/coimg-soon.svg"
                  alt="coming soon"
                  width="50px"
                  height="auto"
                  display="block"
                />
              }
              msg="Data Analysis and Performance reports - Coming Soon"
            />
          </Box>
        </DashboardLayout>
      )}
    </Box>
  );
}

export default Reports;

const styles = {
  container: {
    minHeight: { xs: "calc(100vh - 91px)", md: "100vh" },
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "20px", md: "20px" },
    p: { xs: "0px", md: "15px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },
};
