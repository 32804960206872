import { useMediaQuery } from "@mui/material";
import React from "react";
import { VictoryPie } from "victory";

const DonutChart = ({ data, isChartEmpty }) => {

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <VictoryPie
      width={matches ? 400 : 800}
      height={matches ? 400 : 800}
      colorScale={isChartEmpty ? ['#e8e8e8'] : ["#00b293", "#fca549", "#f43c3c", "#2d6fff"]}
      innerRadius={matches? 119 : 200}
      data={data}
      style={{
        data: {},
        labels: {
          display: "none",
          fontSize: 13,
          fontFamily: "Poppins",
        },
      }}
      // animate={{
      //   duration: 1000,
      // }}

      animate={{
        duration: 1000,
        easing: "bounce",
        onLoad: { duration: 2000 },
      }}
    />
  );
};

export default DonutChart;
