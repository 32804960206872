import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import { Box, Typography } from "@mui/material";
import {
  CheckRounded,
  LocalMallRounded,
  LocalShippingRounded,
} from "@mui/icons-material";
import {
  stepperBGColor,
  blackShade05,
  whiteColor,
  lightBlueColor01,
  greyColor7,
} from "../configs/styles/muiThemes";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    marginTop: "2px",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {},
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {},
  },
  [`& .${stepConnectorClasses.line}`]: {
    border: "1px dashed #a2aebe",
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: whiteColor,
  zIndex: 1,
  color: stepperBGColor,
  width: 40,
  height: 40,
  border: `3px solid ${stepperBGColor}`,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {}),
  ...(ownerState.completed && {
    backgroundColor: lightBlueColor01,
    color: whiteColor,
    border: "",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, index } = props;

  const icons = {
    1: (
      <Box>
        <LocalMallRounded
          sx={{
            color: completed || active ? whiteColor : blackShade05,
            fontSize: "20px",
          }}
        />
      </Box>
    ),
    2: (
      <Box>
        <LocalShippingRounded
          sx={{
            color: completed || active ? whiteColor : blackShade05,
            fontSize: "20px",
          }}
        />
      </Box>
    ),
    3: (
      <Box>
        <CheckRounded
          sx={{
            color: completed || active ? whiteColor : blackShade05,
            fontSize: "24px",
          }}
        />
      </Box>
    ),
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={{ width: "25px", height: "25px", gap: "25px" }}
    >
      {completed ? (
        <CheckRoundedIcon sx={{ fontSize: "18px", color: whiteColor }} />
      ) : (
        <FiberManualRecordIcon
          sx={{ fontSize: "18px", color: stepperBGColor }}
        />
      )}
    </ColorlibStepIconRoot>
  );
}

function HorizontalDotLineStepper({ steps, activeStep, width }) {
  return (
    <Stack sx={{ width: "100%", ml: -10 }}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((item, index) => (
          <Step key={item.label} onClick={() => {}}>
            <StepLabel
              StepIconComponent={(props) =>
                ColorlibStepIcon({ ...props, route: item.route, index })
              }
            >
              <Typography
                variant="h5"
                component="p"
                fontSize={12.5}
                fontWeight={500}
                lineHeight="18px !important"
                color={blackShade05}
                ml={8}
                mt="-5px"
              >
                {item.label}
              </Typography>
              <Typography
                variant="h4"
                fontSize={11}
                fontWeight={400}
                color={greyColor7}
                ml={10.9}
                mt={{ md: 1 }}
              >
                {item.sublabel}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}

export default HorizontalDotLineStepper;
