import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  blackShade05,
  greyColor6,
} from "../../../configs/styles/muiThemes";
import FormInputBox from "../../../components/Input/FormInputBox";

const PriceControlsForm = ({
  data,
  setData,
  ondcCategories,
  handleOndcInfoChange,
}) => {
  return (
    <Box>
      <Typography
        component="h6"
        sx={{ fontSize: 15, fontWeight: 600, color: blackShade05, mt: 2 }}
      >
        Pricing Controls
      </Typography>

      <Box mt={1}>
        <Grid container rowSpacing={{xs:0.7, md:0}} columnSpacing={3}>
          <Grid item xs={12} md={3.5}>
            <FormInputBox
              label="Minimum Order Amount"
              placeholder=""
              startIcon="₹"
              required
              value={data?.ondc?.ondcMinOrder}
              onChange={({ target }) => {
                const value = target?.value?.replace(/[^0-9]/g, "");
                handleOndcInfoChange("ondcMinOrder", value);
              }}
              error={data?.errors?.ondcMinOrder}
              helperText="Recommended value is average product price"
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <FormInputBox
              label="Packing Cost"
              placeholder=""
              startIcon="₹"
              required
              value={data?.ondc?.packagingCost}
              onChange={({ target }) => {
                const value = target?.value?.replace(/[^0-9]/g, "");
                handleOndcInfoChange("packagingCost", value);
              }}
              error={data?.errors?.packagingCost}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <FormInputBox
              label="Convenience Fee"
              placeholder=""
              startIcon="₹"
              required
              value={data?.ondc?.convenienceFee}
              onChange={({ target }) => {
                const value = target?.value?.replace(/[^0-9]/g, "");
                handleOndcInfoChange("convenienceFee", value);
              }}
              error={data?.errors?.convenienceFee}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <FormInputBox
              label="Buyer Finder Fee Limit %"
              placeholder="Min 3 to Max 10"
              required
              value={data?.ondc?.ondcBuyerFinderFeeUpperLimitPercentage}
              onChange={({ target }) => {
                const value = target?.value?.replace(/[^0-9]/g, "");
                handleOndcInfoChange(
                  "ondcBuyerFinderFeeUpperLimitPercentage",
                  value
                );
              }}
              error={data?.errors?.ondcBuyerFinderFeeUpperLimitPercentage}
              helperText="If the Buyer app finder fee is more than specified here, your products will not he displayed in customer search"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PriceControlsForm;

const styles = {
  sectionHeader: {
    fontSize: { xs: 15, md: 15 },
    color: blackColor,
    fontWeight: 600,
    lineHeight: "20px",
    mb: 2,
  },

  radioLabel: {
    color: greyColor6,
    fontSize: 13,
    fontWeight: 400,
  },
};
