import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  blackColor,
  blackShade19,
  blackShade21,
  blackShade22,
  greenColor02,
  greenColor03,
  greyColor48,
  lightGreen02,
  lightGreen03,
  whiteColor,
  yellowColor,
} from "../../configs/styles/muiThemes";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAssignCatError,
  clearPublishedProducts,
} from "../../reducers/catalogBulkUpload/catalogBulkUploadSlice";
import { useNavigate } from "react-router-dom";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import { clearCatalogData } from "../../reducers/businessPreview/businessPreviewSlice";

const UploadSuccessful = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { productPublished } = useSelector((state) => state.catalogBulkUpload);

  useEffect(() => {
    return () => {
      dispatch(clearPublishedProducts());
      dispatch(clearAssignCatError());
      dispatch(clearCatalogData());

    };
  }, []);

  return (
    <Box>
      <Typography sx={styles.heading}>Bulk Catalog Upload</Typography>

      <Grid container>
        <Grid item xs={12} md={7}>
          <Box sx={styles.card}>
            {/* <CheckCircleIcon sx={{ fontSize: "50px", color: greenColor02 }} />
            <Typography
              fontSize={16}
              fontWeight={600}
              my="10px"
              color={blackShade22}
            >
              {productPublished
                ? productPublished?.ids?.length > 1
                  ? `${productPublished?.ids?.length} Products `
                  : `${productPublished?.ids?.length} Product `
                : ""}{" "}
              submitted for import successfully
            </Typography> */}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1.5,
                p: "10px 18px",
                borderRadius: "13px",
                backgroundColor: lightGreen02,
                border: `1px solid ${lightGreen03}`,
                mb: 3,
              }}
            >
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "20px",
                  backgroundColor: whiteColor,
                }}
              >
                <PublishedWithChangesOutlinedIcon
                  sx={{ fontSize: "20px", color: greenColor03 }}
                />
              </Box>

              <Box>
                <Typography
                  fontSize={11.2}
                  fontWeight={500}
                  color={greyColor48}
                  lineHeight="15px"
                >
                  PRODUCTS SUBMITTED FOR IMPORT
                </Typography>
                <Typography fontSize={15} fontWeight={600} color={blackShade19}>
                  {productPublished?.ids?.length || "-"}
                </Typography>
              </Box>
            </Box>

            <Typography
              fontSize={13}
              fontWeight={400}
              color={blackShade21}
              textAlign="center"
              mb={1.5}
            >
              Product details have been submitted for import and will be
              complete in approx 10 mins.
            </Typography>

            <Typography
              fontSize={13}
              fontWeight={400}
              color={blackShade21}
              textAlign="center"
              mb={1.5}
            >
              Products pending for approval can be viewed from ‘Pending Approval
              (New)’ tab in Catalog page. Please assign the specifications
              relevant to the product category chosen, using the EDIT option.
            </Typography>

            <Typography
              fontSize={13}
              fontWeight={400}
              color={blackShade21}
              textAlign="center"
              mb={2}
            >
              Once updated, our team will review and approve within 24 hours.
              Approved products will reflect in your active catalog.
            </Typography>

            <Button
              variant="contained"
              sx={styles.nextBtn}
              disableElevation
              onClick={() => {
                navigate(
                  `/${sessionStorage.getItem("costboWEBURL")}/catalogPending`
                );
              }}
            >
              GO TO CATALOG
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UploadSuccessful;

const styles = {
  heading: {
    fontSize: "14px",
    fontWeight: "600",
    color: blackShade19,
    mb: 1,
  },

  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${greenColor02}`,
    borderRadius: "10px",
    padding: "20px",
  },

  nextBtn: {
    fontSize: 13,
    fontWeight: 500,
    color: blackColor,
    backgroundColor: yellowColor,
    border: `1px solid ${yellowColor}`,
    padding: "7px 30px",
    borderRadius: "7px",
    "&:hover": {
      color: yellowColor,
      backgroundColor: whiteColor,
    },
  },
};
