import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllOrderInfo,
  getAllOrdersData,
} from "../../../reducers/orders/ordersSlice";
import ListEmptyCard from "../../../components/ListEmptyCard";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import moment from "moment";
import { getOrderDeliveryTypeText, getOrderType } from "../../../utils/helper";
import InfiniteScroll from "react-infinite-scroll-component";
import AllOrderCard from "../Cards/AllOrderCard";
import SearchOrdersById from "../SearchOrdersById";
import {
  blackShade23,
  greyColor42,
  orderCardBorder,
  orderCardHeadingBorder2,
} from "../../../configs/styles/muiThemes";
import { stickyHeader } from "../../../utils/styles";
function AllOrdersContent({
  handleTrackOrder,
  handleViewOrder,
  tabValue,
  getOndcBapNameBySubscriberId,
  downloadShipingLabel,
}) {
  const PAGE_SIZE = 30;

  const dispatch = useDispatch();
  const [hasMoreData, setHasMoreData] = useState(true);

  const [isSearchActive, setIsSearchActive] = useState(false);

  const { allOrders, allPageNo, allOrdersLoader } = useSelector(
    (state) => state.businessOrders
  );

  const getOrdersData = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      getAllOrdersData({
        businessId,
        pageSize: PAGE_SIZE,
        pageNo: allPageNo,
      })
    );
  };

  const fetchNextData = () => {
    // console.log("Next Hit");
    getOrdersData();
  };

  const getOrderDate = (item) => {
    switch (item?.orderStatus) {
      case "ordercreated":
        return moment(item?.orderCreatedOn).fromNow();
      case "orderapproved":
        return moment(item?.orderApprovedOn).fromNow();
      case "ordershipped":
        return moment(item?.orderShippedOn).fromNow();
      case "orderdelivered":
        return moment(item?.orderDeliveredOn).fromNow();
      case "bordercanceled":
        const businessCanceledDate = item?.orderCancelDateBusiness;

        let cancelDate = "";

        if (businessCanceledDate) {
          cancelDate = moment(businessCanceledDate).fromNow();
        }

        return cancelDate || "N/A";
      case "cordercanceled":
        const customerCanceledDate = item?.orderCancelDateConsumer;

        let formatedCancelDate = "";

        if (customerCanceledDate) {
          formatedCancelDate = moment(customerCanceledDate).fromNow();
        }

        return formatedCancelDate || "N/A";

      default:
        return "N/A";
    }
  };

  // handle Searching
  const onSearchStart = () => {
    setIsSearchActive(true);
    dispatch(clearAllOrderInfo());
  };

  const onSearchClear = () => {
    setIsSearchActive(false);
    getOrdersData();
  };

  useEffect(() => {
    getOrdersData();
    return () => {
      dispatch(clearAllOrderInfo());
    };
  }, []);

  useEffect(() => {
    if ((allPageNo - 1) * PAGE_SIZE > allOrders?.length) {
      setHasMoreData(false);
    }

    return () => {};
  }, [allPageNo]);

  return (
    <Box>
      <SearchOrdersById
        handleTrackOrder={handleTrackOrder}
        handleViewOrder={handleViewOrder}
        onSearchStart={onSearchStart}
        onSearchClear={onSearchClear}
        tabValue={tabValue}
        disableSearch={allOrders?.length == 0}
        getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
        downloadShipingLabel={downloadShipingLabel}
      />

      {!allOrdersLoader &&
      allPageNo === 1 &&
      allOrders?.length === 0 &&
      !isSearchActive ? (
        <ListEmptyCard
          icon={
            <ProductionQuantityLimitsOutlinedIcon sx={{ fontSize: "24px" }} />
          }
          text="No Delivered Orders Found"
          hideBtn
        />
      ) : (
        allOrdersLoader && allPageNo === 1 && <ThreeDotLoader />
      )}
      {allOrders?.length > 0 && (
        <Box
          sx={{
            ...stickyHeader,
            pb: { md: 0, xs: 0 },
            pt: 0,
            top: 248,
            display: { md: "block", xs: "none" },
          }}
        >
          <Box sx={styles.card}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3.2}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  ORDER DETAIL
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  CUSTOMER INFO & PRICE & QTY
                </Typography>
              </Grid>

              <Grid item xs={6} md={2.1}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  STATUS
                </Typography>
              </Grid>
              <Grid item xs={12} md={3.6}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  ACTION
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}

      {allOrders?.length > 0 && (
        <>
          <InfiniteScroll
            dataLength={allOrders?.length || 0}
            next={fetchNextData}
            hasMore={hasMoreData}
            loader={
              <Box>
                <ThreeDotLoader />
              </Box>
            }
          >
            {allOrders?.map((item, index) => (
              <AllOrderCard
                index={index}
                key={`order_${item?.orderId}`}
                id={item?.orderId}
                ondcOrderId={item?.ondcOrderId || ""}
                date={moment(item?.orderCreatedOn)?.format("DD MMM YYYY")}
                username={item?.customerAddressName}
                addressName={item?.customerAddressName || ""}
                city={item?.customerCity}
                addressCity={item?.customerAddressCity || ""}
                orderCost={item?.orderTotalCost}
                cartCount={item?.totalQuantity}
                orderType={getOrderType(item)}
                trackingStatus={item?.orderStatus}
                statusUpdatedDate={getOrderDate(item)}
                handleTrackOrder={() => {
                  handleTrackOrder(item);
                }}
                handleViewOrder={() => {
                  handleViewOrder(item);
                }}
                getOndcBapNameBySubscriberId={() =>
                  getOndcBapNameBySubscriberId(item?.ondcBapId)
                }
                downloadShipingLabel={downloadShipingLabel}
                orderData={item}
                deliveryType={getOrderDeliveryTypeText(
                  item?.deliveryType?.type
                )}
              />
            ))}
          </InfiniteScroll>
        </>
      )}
    </Box>
  );
}

export default AllOrdersContent;
const styles = {
  card: {
    display: { md: "flex", xs: "none" },
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "16px", md: "20px" },
    backgroundColor: greyColor42,
    borderTop: orderCardBorder,
    borderRadius: "7px 7px 0px 0px",
    boxShadow: "0 2px 4px rgb(179 188 198 / 5%)",
    padding: "7px 15px",
    position: "sticky",
    top: 248,
    zIndex: 10000,
    // marginBottom: "15px",
  },
};
