import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import {
  bgColor15,
  blackShade05,
  blackShade19,
  border25,
  copyBtnColor,
  deleteBtnColor,
  greyColor8,
  lightBlueColor01,
  orangeStatusColor,
  orderCardBorder,
  orderCardBorder2,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { addThousandsSeparator } from "../../../utils/helper";
import VisibilityIcon from "@mui/icons-material/Visibility";

function MobileReturnsCard({
  id,
  date,
  ondcID,
  orderCost,
  cartCount,

  refundTotalAmt,
  refundProcessed,
  returnQty,

  requestedDate,
  completedDate,

  handleViewOrder,
  handleActionClick,

  isCompleted,
  hideBtns,
  getOndcBapNameBySubscriberId,
  orderType,
  item,
}) {
  return (
    <Box sx={styles.card}>
      <Box borderBottom={border25} pb={1}>
        <Grid container spacing={0} alignItems="flex-start">
          <Grid item xs={6} md={6}>
            <Box>
              <Typography sx={styles.labelText}>ORDER DETAIL</Typography>
              <Typography variant="h5" sx={styles.valueText}>
                ID# {id}
              </Typography>
              <Typography variant="p" sx={styles.labelText}>
                {date}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} md={6}>
            <Box textAlign="right">
              <Typography sx={styles.labelText}>ONDC ORDER ID</Typography>
              <Typography
                variant="h5"
                sx={{ ...styles.valueText, color: orderType?.color }}
              >
                ID# {ondcID?.slice(0, 17)}
              </Typography>
            </Box>
            <Box flex={1} display={"flex"} gap={2}>
              <Typography sx={{ ...styles.title, display: "inline-block" }}>
                {" "}
                <Typography
                  sx={{
                    ...styles.title,
                    fontSize: 12,
                    color: orderType?.color,
                    display: "inline-block",
                    ml: 3,
                  }}
                >
                  {orderType?.label}
                  {orderType?.orderType === "ondcOrder" ? (
                    <span style={{ color: blackShade19 }}>
                      {" "}
                      - {getOndcBapNameBySubscriberId(item?.ondcInfo?.bapId)}
                    </span>
                  ) : null}
                </Typography>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box borderBottom={border25} py={1}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <Box>
              <Typography sx={styles.labelText}>PRICE & QTY</Typography>
              <InfoBox
                title={`₹ ${addThousandsSeparator(orderCost)}`}
                subtitle={cartCount}
              />
            </Box>
          </Grid>

          <Grid item xs={6} md={6}>
            <Box textAlign="right">
              <Typography sx={styles.labelText}>
                {!isCompleted ? "RETURN REQUESTED" : "RETURN(PRICE & QTY)"}
              </Typography>
              <InfoBox
                title={`₹ ${refundTotalAmt}`}
                subtitle={returnQty}
                isAlignRight
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box borderBottom={border25} py={1}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <Box textAlign="left">
              <Typography sx={styles.labelText}>RETURN PROCESSED</Typography>
              <InfoBox
                title={`₹ ${refundProcessed}`}
                hideSubtitle
                // subtitle={returnQty}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box py={1}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6} md={6}>
            <Box>
              <Typography sx={styles.labelText}>STATUS</Typography>
              <Typography
                variant="h5"
                fontSize={12}
                fontWeight={500}
                color={orangeStatusColor}
                lineHeight="18px"
              >
                Requested
              </Typography>
              {isCompleted && (
                <Typography variant="h5" sx={styles.valueText}>
                  {requestedDate}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={6} md={6}>
            <Box textAlign="right">
              {isCompleted ? (
                <>
                  <Typography
                    variant="h5"
                    fontSize={12}
                    fontWeight={500}
                    color={copyBtnColor}
                    lineHeight="18px"
                  >
                    Completed
                  </Typography>

                  <Typography variant="h5" sx={styles.valueText}>
                    {completedDate}
                  </Typography>
                </>
              ) : (
                <Typography variant="h5" sx={styles.valueText}>
                  {requestedDate}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {!hideBtns ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={1.8}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={{ xs: "flex-end", md: "flex-start" }}
              gap={{ xs: "15px", md: "10px" }}
            >
              {isCompleted ? (
                <Button
                  sx={{ ...styles.actionBtn, ...styles.viewBtn }}
                  variant="contained"
                  disableElevation
                  onClick={handleViewOrder}
                >
                  VIEW
                </Button>
              ) : (
                <IconButton
                  sx={styles.addIconBtn}
                  disableElevation
                  onClick={handleViewOrder}
                >
                  <VisibilityIcon sx={{ fontSize: 20 }} />
                </IconButton>
              )}

              {!isCompleted && (
                <Button
                  sx={styles.actionBtn}
                  variant="contained"
                  disableElevation
                  onClick={handleActionClick}
                >
                  ACTION
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
}

export default MobileReturnsCard;

const InfoBox = ({ title, subtitle, isAlignRight, hideSubtitle }) => {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent={isAlignRight ? "flex-end" : "flex-start"}
      gap={0}
    >
      <Typography
        variant="h5"
        fontSize={12.5}
        fontWeight={500}
        color={blackShade05}
        lineHeight="20px"
      >
        {title}
      </Typography>

      {!hideSubtitle && (
        <>
          <Box sx={styles.dotUi} />

          <Typography
            variant="h5"
            fontSize={12.5}
            fontWeight={500}
            color={blackShade05}
            lineHeight="20px"
          >
            {subtitle}
          </Typography>
        </>
      )}
    </Stack>
  );
};

const styles = {
  card: {
    backgroundColor: whiteColor,
    border: orderCardBorder,
    borderRadius: { xs: "7px", md: "0px" },
    boxShadow: "0 2px 4px rgb(179 188 198 / 5%)",
    padding: "15px",
    mb: { md: 0, xs: 1 },
  },

  dotUi: {
    width: "6px",
    height: "6px",
    borderRadius: "3px",
    backgroundColor: orangeStatusColor,
  },

  addIconBtn: {
    padding: { md: "4px 10px", xs: "2px 12px" },
    fontSize: "12px",
    fontWeight: 600,
    color: bgColor15,
    backgroundColor: whiteColor,
    border: "1px solid " + bgColor15,
    borderRadius: "4px",
    "&:hover": {
      color: whiteColor,
      backgroundColor: bgColor15,
    },
  },

  viewBtn: {
    color: bgColor15,
    backgroundColor: whiteColor,
    border: "1px solid " + bgColor15,
    "&:hover": {
      color: whiteColor,
      backgroundColor: bgColor15,
    },
  },

  actionBtn: {
    padding: { md: "4px 15px", xs: "2px 12px" },
    fontSize: "12px",
    fontWeight: 500,
    color: whiteColor,
    backgroundColor: lightBlueColor01,
    border: "1px solid " + lightBlueColor01,
    "&:hover": {
      color: lightBlueColor01,
      backgroundColor: whiteColor,
    },
  },

  cancelBtn: {
    padding: { md: "5px 24px", xs: "3px 15px" },
    fontSize: "12px",
    fontWeight: 600,
    color: deleteBtnColor,
    backgroundColor: whiteColor,
    border: "1px solid " + deleteBtnColor,
    "&:hover": {
      color: whiteColor,
      backgroundColor: deleteBtnColor,
    },
  },

  trackBtn: {
    color: whiteColor,
    backgroundColor: greyColor8,
    "&:hover": {
      color: greyColor8,
      backgroundColor: whiteColor,
    },
  },

  infoTab: {
    display: "flex",
    alignItems: { xs: "flex-start", md: "flex-start" },
    flexDirection: { xs: "column", md: "column" },
    justifyContent: { xs: "space-between", md: "flex-start" },
    pr: { xs: 0, md: 2 },
    borderRight: { xs: "none", md: orderCardBorder2 },
  },

  valueText: {
    fontSize: 12,
    fontWeight: { xs: 500, md: 600 },
    color: blackShade19,
    lineHeight: "22px",
  },

  labelText: {
    display: { xs: "block", md: "none" },
    fontSize: 11,
    fontWeight: 400,
    color: bgColor15,
    lineHeight: "20px",
  },

  dotUi: {
    width: "4px",
    height: "4px",
    background: bgColor15,
    borderRadius: "30px",
    position: "relative",
    margin: " 0px 10px",
    bottom: "2px",
  },
};
