import React, { useEffect, useState } from "react";
import {
  blackShade05,
  blackShade19,
  blackShade21,
  blueColor04,
  borderTextBoxColor,
  greenColor,
  greyColor21,
  greyColor42,
  greyColor47,
  lightRedColor,
  redColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  BULK_UPLOAD_TABLE_HEADER,
  PRODUCT_CODE_UNIT_LIST,
} from "../../utils/data";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSelectedProducts,
  deleteSingleProducts,
  selectAllProductRows,
  toggleProductRowSelect,
  updateProductList,
} from "../../reducers/catalogBulkUpload/catalogBulkUploadSlice";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DescriptionInput from "./Components/Popups/DescriptionInput";
import TabelCellInput from "./Components/Popups/TabelCellInput";
import {
  getCountryNames,
  getGstRates,
} from "../../reducers/catalog/CatalogSlice";
import TableDropdown from "./Components/TableDropdown";
import ModalCard from "../../components/ModalCard";
import DeleteAlert from "./Components/Popups/DeleteAlert";
import { useNavigate } from "react-router-dom";
import { getUnitOfMeasurementApi } from "../../service/api_calls/catalog/CatalogAPI";
import ProductImageLink from "./Components/ProductImageLink";
import { textOneLines } from "../../utils/styles";
import { toTitleCase } from "../../utils/validationUtils";
import EmptyCatalogData from "./Components/EmptyCatalogData";
import ErrorToolTipIcon from "./Components/ErrorToolTipIcon";

const ReviewAndPublish = React.memo(
  ({ productDetails, isLoading, setCurrentPage }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { countryList, gstRates, countryListLoading, gstRatesLoading } =
      useSelector((state) => state.catalog);

    const { selectedRowCount, deleteProductLoader, productPublished } =
      useSelector((state) => state.catalogBulkUpload);

    const [showDeleteAlert, setShowDeleteAlert] = useState({
      show: false,
      data: null,
    });
    const [unitOfMeasurementData, setUnitOfMeasurementData] = useState([]);

    //handle table cell value change
    const handleValueChange = (key, value, index) => {
      let allProducts = [...productDetails];

      const errors = { ...allProducts[index]?.errors };
      delete errors[key];

      if (key === "maxOrderQuantity") {
        console.log("HITTITIIT");
        allProducts[index] = {
          ...allProducts[index],
          [key]: value,
          alertQuantity: Number(value) * 3,
          errors: errors,
        };
        console.log("handleValueChange", allProducts[index]);
        dispatch(updateProductList({ productDetails: allProducts }));
        return;
      }

      allProducts[index] = {
        ...allProducts[index],
        [key]: value,
        errors: errors,
      };

      console.log("handleValueChange", allProducts[index]);
      dispatch(updateProductList({ productDetails: allProducts }));
    };

    //handle table cell gst value change
    const handlePhotoLinkChange = (photoIndex, value, index) => {
      let allProducts = [...productDetails];

      const errors = { ...allProducts[index]?.errors };
      delete errors.productPhotoUrl;

      let imageArr = [...allProducts[index]?.productPhotoUrl];

      imageArr[photoIndex] = value;

      allProducts[index] = {
        ...allProducts[index],
        productPhotoUrl: imageArr,
        errors,
      };

      console.log("handlePhotoLinkChange", allProducts[index]);
      dispatch(updateProductList({ productDetails: allProducts }));
    };

    //handle table cell gst value change
    const handleGstValueChange = (key, value, index) => {
      let allProducts = [...productDetails];

      const errors = { ...allProducts[index]?.errors };
      delete errors[key === "code" ? "gstInfoCode" : key];

      allProducts[index] = {
        ...allProducts[index],
        gstInfo: { ...allProducts[index]?.gstInfo, [key]: value },
        // errors: {...allProducts[index]?.errors, [key === 'code' ? 'gstInfoCode' : key]:''}
        errors,
      };

      console.log("handleGstValueChange", allProducts[index]);
      dispatch(updateProductList({ productDetails: allProducts }));
    };

    //handle table cell measurement value change
    const handleMeasureValueChange = (key, value, index) => {
      let allProducts = [...productDetails];

      const errors = { ...allProducts[index]?.errors };
      delete errors[key];

      allProducts[index] = {
        ...allProducts[index],
        measurementInfo: {
          ...allProducts[index]?.measurementInfo,
          [key]: Number(value) || 0,
        },
        // errors: {...allProducts[index]?.errors, [key]: ''}
        errors,
      };

      console.log("handleGstValueChange", allProducts[index]);
      dispatch(updateProductList({ productDetails: allProducts }));
    };

    //handle table cell specifications value change
    const handleSpecsValueChange = (
      type,
      index,
      value,
      labelIndex,
      labelValueIndex
    ) => {
      let allProducts = [...productDetails];

      let labels = [...allProducts[index]?.label];

      // add new label object of user tried to edit a unknown label
      if (labelIndex >= labels.length) {
        const count = labelIndex - labels.length;

        for (let i = 0; i <= count; i++) {
          const labelData = { code: "", value: [""] };
          labels.push(labelData);
        }
      }

      if (type === "code") {
        labels[labelIndex] = { ...labels[labelIndex], code: value };
      } else {
        let labelValues = [...labels[labelIndex]?.value];
        labelValues[labelValueIndex] = value;
        labels[labelIndex] = { ...labels[labelIndex], value: [...labelValues] };
      }

      allProducts[index] = {
        ...allProducts[index],
        label: labels,
      };

      console.log("handleSpecsValueChange", allProducts[index]);
      dispatch(updateProductList({ productDetails: allProducts }));
    };

    //handle table cell ref links value change
    const handleRefLinksValueChange = (type, index, value, refLinkIndex) => {
      let allProducts = [...productDetails];

      let refLinks = [...allProducts[index]?.refLink];

      // add new label object of user tried to edit a unknown label
      if (refLinkIndex >= refLinks.length) {
        const count = refLinkIndex - refLinks.length;

        for (let i = 0; i <= count; i++) {
          const linkData = { code: "", link: "" };
          refLinks.push(linkData);
        }
      }

      if (type === "code") {
        refLinks[refLinkIndex] = { ...refLinks[refLinkIndex], code: value };
      } else {
        refLinks[refLinkIndex] = { ...refLinks[refLinkIndex], link: value };
      }

      allProducts[index] = {
        ...allProducts[index],
        refLink: refLinks,
      };

      console.log("handleSpecsValueChange", allProducts[index]);
      dispatch(updateProductList({ productDetails: allProducts }));
    };

    // handling delete bulk products
    const handleDeleteProducts = async () => {
      if (deleteProductLoader) return;

      setShowDeleteAlert({ show: false, data: null });

      const businessId = sessionStorage.getItem("selectedBusinessId");

      if (businessId) {
        dispatch(deleteSelectedProducts({ productDetails, businessId })).then(
          (res) => {
            if (selectedRowCount === productDetails?.length) navigate(0);
          }
        );
      }
    };

    // handling delete single products
    const handleDeleteSingleProducts = async (productIndex) => {
      if (deleteProductLoader) return;

      setShowDeleteAlert({ show: false, data: null });

      const businessId = sessionStorage.getItem("selectedBusinessId");

      if (businessId) {
        console.log("handleDeleteSingleProducts", {
          productDetails,
          businessId,
          productIndex,
        });
        dispatch(
          deleteSingleProducts({ productDetails, businessId, productIndex })
        ).then((res) => {
          if (productDetails?.length === 1) navigate(0);
        });
      }
    };

    // Get Unit of measurement
    const getUnitsData = async () => {
      const response = await getUnitOfMeasurementApi();
      setUnitOfMeasurementData(() => response?.details || []);
      console.log("getUnitsData:", response);
    };

    useEffect(() => {
      dispatch(getCountryNames());
      dispatch(getGstRates());
      getUnitsData();
      return () => {};
    }, []);

    return (
      <Box>
        {/* {JSON.stringify(productDetails)} */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography sx={styles.heading}>Product Attributes</Typography>
            {productDetails?.length > 0 && (
              <Typography sx={styles.subHeading}>
                {selectedRowCount} of {productDetails?.length} rows selected for
                updates and import. Go to Step 3 to choose additional rows for
                import.
              </Typography>
            )}
          </Box>

          {/* {selectedRowCount > 0 ? (
          <Button
            variant="contained"
            disableElevation
            sx={styles.deleteBtn}
            onClick={() => {
              setShowDeleteAlert({
                show: true,
                data: {
                  message: `Are you sure you want to delete the selected ${
                    selectedRowCount === 1
                      ? `${selectedRowCount} Row?`
                      : `${selectedRowCount} Rows?`
                  }`,
                },
              });
            }}
            startIcon={
              deleteProductLoader ? (
                <CircularProgress color="inherit" size={14} />
              ) : null
            }
          >
            DELETE{" "}
            {selectedRowCount === 1
              ? `${selectedRowCount} Row`
              : `${selectedRowCount} Rows`}
          </Button>
        ) : (
          <Box />
        )} */}
        </Box>

        {isLoading || countryListLoading || gstRatesLoading ? (
          <ThreeDotLoader />
        ) : productDetails?.length > 0 ? (
          <Box sx={styles.tableWrapper}>
            <TableContainer sx={styles.tableContainer} component={Box}>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow
                    sx={{
                      position: "sticky !important",
                      top: 0,
                      backgroundColor: greyColor42,
                      zIndex: 20,
                      borderRadius: "8px 8px 0 0",
                    }}
                  >
                    {BULK_UPLOAD_TABLE_HEADER?.map((item, index) => {
                      return (
                        <TableCell
                          key={item.id}
                          sx={{
                            ...styles.tableCell,
                            ...styles.colSticky,
                          }}
                        >
                          <Box
                            display={index === 0 ? "flex" : "inline-block"}
                            alignItems="center"
                            gap={1.2}
                          >
                            {index === 0 && (
                              <Checkbox
                                // checked={productDetails?.every(
                                //   (item) => item.isSelected
                                // )}
                                checked={true}
                                onChange={({ target }) => {
                                  // dispatch(
                                  //   selectAllProductRows({
                                  //     productDetails,
                                  //     isSelected: target.checked,
                                  //   })
                                  // );
                                }}
                                sx={styles.checkbox}
                              />
                            )}
                            <Box sx={{ fontSize: 12 }}>
                              {item?.title}{" "}
                              {item?.isRequired && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </Box>
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody sx={{ backgroundColor: whiteColor }}>
                  {productDetails?.map((productInfo, index) => {
                    if (!productInfo.isSelected) return null;

                    return (
                      <TableRow
                        key={productInfo?.id}
                        sx={{
                          borderBottom: `1px solid ${borderTextBoxColor}`,
                          verticalAlign: "top",
                        }}
                      >
                        <TableCell
                          sx={{
                            ...styles.tableStickyLeftCell,
                          }}
                        >
                          <Box display="flex" alignItems="flex-start" gap={1.2}>
                            <Checkbox
                              sx={styles.checkbox}
                              checked={productInfo?.isSelected || false}
                              onChange={() => {
                                dispatch(
                                  toggleProductRowSelect({
                                    productDetails,
                                    index,
                                    isSelected: productInfo?.isSelected,
                                  })
                                );
                              }}
                            />
                            <Typography
                              variant="body1"
                              title={productInfo?.productName}
                              sx={{ ...styles.prodNameBox, ...textOneLines }}
                            >
                              {productInfo?.productName}
                            </Typography>
                          </Box>
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(
                            productInfo?.errors?.productDescription
                          )}
                        >
                          <Box sx={styles.prodDescriptionBox}>
                            <DescriptionInput
                              description={productInfo?.productDescription}
                              onChange={(value) => {
                                handleValueChange(
                                  "productDescription",
                                  value,
                                  index
                                );
                              }}
                              errorMsg={productInfo?.errors?.productDescription}
                            />
                          </Box>
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.skuId}
                            onValueChange={(value) => {
                              handleValueChange("skuId", value, index);
                            }}
                            regexType="skuid"
                          />
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(productInfo?.errors?.brand)}
                        >
                          <TabelCellInput
                            value={productInfo?.brand}
                            onValueChange={(value) => {
                              handleValueChange("brand", value, index);
                            }}
                            errorMsg={productInfo?.errors?.brand}
                          />
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(productInfo?.errors?.origin)}
                        >
                          <TableDropdown
                            value={productInfo?.origin || "Select Country"}
                            onValueChange={(value) => {
                              handleValueChange("origin", value?.name, index);
                            }}
                            returnFullItem
                            menuList={[
                              { name: "Select Country" },
                              ...countryList,
                            ]}
                            valueSelector="name"
                            borderFull
                            rounded
                            errorMsg={productInfo?.errors?.origin}
                          />
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(
                            productInfo?.errors?.productPhotoUrl
                          )}
                        >
                          <ProductImageLink
                            value={productInfo?.productPhotoUrl?.[0]}
                            onValueChange={(value) => {
                              handlePhotoLinkChange(0, value, index);
                            }}
                            photoIndex={1}
                            errorMsg={productInfo?.errors?.productPhotoUrl}
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <ProductImageLink
                            value={productInfo?.productPhotoUrl?.[1]}
                            onValueChange={(value) => {
                              handlePhotoLinkChange(1, value, index);
                            }}
                            photoIndex={2}
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <ProductImageLink
                            value={productInfo?.productPhotoUrl?.[2]}
                            onValueChange={(value) => {
                              handlePhotoLinkChange(2, value, index);
                            }}
                            photoIndex={3}
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <ProductImageLink
                            value={productInfo?.productPhotoUrl?.[3]}
                            onValueChange={(value) => {
                              handlePhotoLinkChange(3, value, index);
                            }}
                            photoIndex={4}
                          />
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(
                            productInfo?.errors?.productCodeKey
                          )}
                        >
                          <TableDropdown
                            value={
                              productInfo?.productCodeKey ||
                              "Select Product Code"
                            }
                            onValueChange={(value) => {
                              handleValueChange("productCodeKey", value, index);
                            }}
                            menuList={[
                              { label: "Select Product Code" },
                              ...PRODUCT_CODE_UNIT_LIST,
                            ]}
                            borderFull
                            rounded
                            errorMsg={productInfo?.errors?.productCodeKey}
                          />
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(
                            productInfo?.errors?.productCodeValue
                          )}
                        >
                          <TabelCellInput
                            value={productInfo?.productCodeValue}
                            onValueChange={(value) => {
                              handleValueChange(
                                "productCodeValue",
                                value,
                                index
                              );
                            }}
                            regexType="number"
                            maxLength={13}
                            disabled={
                              !productInfo?.productCodeKey ||
                              productInfo?.productCodeKey ===
                                "Select Product Code"
                            }
                            errorMsg={productInfo?.errors?.productCodeValue}
                          />
                        </TableCell>

                        {/* <TableCell
                        sx={styles.tableBodyCell(
                          productInfo?.errors?.gstInfoCode
                        )}
                      >
                        <TabelCellInput
                          value={productInfo?.gstInfo?.code}
                          onValueChange={(value) => {
                            handleGstValueChange("code", value, index);
                          }}
                          regexType="gsthsnCode"
                          errorMsg={productInfo?.errors?.gstInfoCode}
                        />
                      </TableCell> */}

                        <TableCell
                          sx={styles.tableBodyCell(
                            productInfo?.errors?.gstPercentage
                          )}
                        >
                          <TableDropdown
                            value={
                              productInfo?.gstInfo?.gstPercentage ||
                              "Select GST %"
                            }
                            onValueChange={(value) => {
                              handleGstValueChange(
                                "gstPercentage",
                                value?.value,
                                index
                              );
                            }}
                            returnFullItem
                            menuList={[{ value: "Select GST %" }, ...gstRates]}
                            valueSelector="value"
                            borderFull
                            rounded
                            errorMsg={productInfo?.errors?.gstPercentage}
                          />
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(
                            productInfo?.errors?.unitOfmeasurement
                          )}
                        >
                          <TableDropdown
                            value={
                              productInfo?.unitOfmeasurement
                                ? toTitleCase(productInfo?.unitOfmeasurement)
                                : "Select Unit Of Measurement"
                            }
                            onValueChange={(value) => {
                              handleValueChange(
                                "unitOfmeasurement",
                                value,
                                index
                              );
                            }}
                            menuList={[
                              { value: "Select Unit Of Measurement" },
                              ...unitOfMeasurementData?.map((item) => ({
                                ...item,
                                value: toTitleCase(item?.value),
                              })),
                            ]}
                            valueSelector="value"
                            borderFull
                            rounded
                            errorMsg={productInfo?.errors?.unitOfmeasurement}
                          />
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(
                            productInfo?.errors?.unitOfmeasurementValue
                          )}
                        >
                          <TabelCellInput
                            value={productInfo?.unitOfmeasurementValue}
                            onValueChange={(value) => {
                              handleValueChange(
                                "unitOfmeasurementValue",
                                value,
                                index
                              );
                            }}
                            regexType="price"
                            maxLength={13}
                            disabled={
                              !productInfo?.unitOfmeasurement ||
                              productInfo?.unitOfmeasurement ===
                                "Select Unit Of Measurement"
                            }
                            errorMsg={
                              productInfo?.errors?.unitOfmeasurementValue
                            }
                          />
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(
                            productInfo?.errors?.productOriginalPrice
                          )}
                        >
                          <TabelCellInput
                            value={productInfo?.productOriginalPrice}
                            onValueChange={(value) => {
                              handleValueChange(
                                "productOriginalPrice",
                                value,
                                index
                              );
                            }}
                            regexType="price"
                            errorMsg={productInfo?.errors?.productOriginalPrice}
                          />
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(
                            productInfo?.errors?.productSalePrice
                          )}
                        >
                          <TabelCellInput
                            value={productInfo?.productSalePrice}
                            onValueChange={(value) => {
                              handleValueChange(
                                "productSalePrice",
                                value,
                                index
                              );
                            }}
                            regexType="price"
                            errorMsg={productInfo?.errors?.productSalePrice}
                          />
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(
                            productInfo?.errors?.availableQuantity
                          )}
                        >
                          <TabelCellInput
                            value={productInfo?.availableQuantity}
                            onValueChange={(value) => {
                              handleValueChange(
                                "availableQuantity",
                                value,
                                index
                              );
                            }}
                            regexType="qty"
                            errorMsg={productInfo?.errors?.availableQuantity}
                          />
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(
                            productInfo?.errors?.alertQuantity
                          )}
                        >
                          {/* <TabelCellInput
                          value={productInfo?.alertQuantity}
                          onValueChange={(value) => {
                            // handleValueChange("alertQuantity", value, index);
                          }}
                          disabled
                          regexType="qty"
                          errorMsg={productInfo?.errors?.alertQuantity}
                        /> */}
                          {productInfo?.alertQuantity}
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(
                            productInfo?.errors?.minOrderQuantity
                          )}
                        >
                          <TabelCellInput
                            value={productInfo?.minOrderQuantity}
                            onValueChange={(value) => {
                              handleValueChange(
                                "minOrderQuantity",
                                value,
                                index
                              );
                            }}
                            regexType="qty"
                            errorMsg={productInfo?.errors?.minOrderQuantity}
                          />
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(
                            productInfo?.errors?.maxOrderQuantity
                          )}
                        >
                          <TabelCellInput
                            value={productInfo?.maxOrderQuantity}
                            onValueChange={(value) => {
                              handleValueChange(
                                "maxOrderQuantity",
                                value,
                                index
                              );
                            }}
                            regexType="qty"
                            errorMsg={productInfo?.errors?.maxOrderQuantity}
                          />
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(productInfo?.errors?.weight)}
                        >
                          <TabelCellInput
                            value={productInfo?.measurementInfo?.weight}
                            onValueChange={(value) => {
                              handleMeasureValueChange("weight", value, index);
                            }}
                            regexType="weight"
                            errorMsg={productInfo?.errors?.weight}
                          />
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(
                            productInfo?.errors?.netProductWeight
                          )}
                        >
                          <TabelCellInput
                            value={productInfo?.netProductWeight}
                            onValueChange={(value) => {
                              handleValueChange(
                                "netProductWeight",
                                value,
                                index
                              );
                            }}
                            regexType="price"
                            errorMsg={productInfo?.errors?.netProductWeight}
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.measurementInfo?.length}
                            onValueChange={(value) => {
                              handleMeasureValueChange("length", value, index);
                            }}
                            regexType="dimension"
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.measurementInfo?.height}
                            onValueChange={(value) => {
                              handleMeasureValueChange("height", value, index);
                            }}
                            regexType="dimension"
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.measurementInfo?.width}
                            onValueChange={(value) => {
                              handleMeasureValueChange("width", value, index);
                            }}
                            regexType="dimension"
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.label?.[0]?.code}
                            onValueChange={(value) => {
                              handleSpecsValueChange("code", index, value, 0);
                            }}
                            regexType=""
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.label?.[0]?.value?.[0]}
                            onValueChange={(value) => {
                              handleSpecsValueChange(
                                "value",
                                index,
                                value,
                                0,
                                0
                              );
                            }}
                            regexType=""
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.label?.[0]?.value?.[1]}
                            onValueChange={(value) => {
                              handleSpecsValueChange(
                                "value",
                                index,
                                value,
                                0,
                                1
                              );
                            }}
                            regexType=""
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.label?.[0]?.value?.[2]}
                            onValueChange={(value) => {
                              handleSpecsValueChange(
                                "value",
                                index,
                                value,
                                0,
                                2
                              );
                            }}
                            regexType=""
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.label?.[1]?.code}
                            onValueChange={(value) => {
                              handleSpecsValueChange("code", index, value, 1);
                            }}
                            regexType=""
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.label?.[1]?.value?.[0]}
                            onValueChange={(value) => {
                              handleSpecsValueChange(
                                "value",
                                index,
                                value,
                                1,
                                0
                              );
                            }}
                            regexType=""
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.label?.[1]?.value?.[1]}
                            onValueChange={(value) => {
                              handleSpecsValueChange(
                                "value",
                                index,
                                value,
                                1,
                                1
                              );
                            }}
                            regexType=""
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.label?.[1]?.value?.[2]}
                            onValueChange={(value) => {
                              handleSpecsValueChange(
                                "value",
                                index,
                                value,
                                1,
                                2
                              );
                            }}
                            regexType=""
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.refLink?.[0]?.code}
                            onValueChange={(value) => {
                              handleRefLinksValueChange(
                                "code",
                                index,
                                value,
                                0
                              );
                            }}
                            regexType=""
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.refLink?.[0]?.link}
                            onValueChange={(value) => {
                              handleRefLinksValueChange(
                                "link",
                                index,
                                value,
                                0
                              );
                            }}
                            regexType=""
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.refLink?.[1]?.code}
                            onValueChange={(value) => {
                              handleRefLinksValueChange(
                                "code",
                                index,
                                value,
                                1
                              );
                            }}
                            regexType=""
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={productInfo?.refLink?.[1]?.link}
                            onValueChange={(value) => {
                              handleRefLinksValueChange(
                                "link",
                                index,
                                value,
                                1
                              );
                            }}
                            regexType=""
                          />
                        </TableCell>

                        {/* <TableCell
                        sx={{
                          ...styles.tableBodyCell(false),
                          ...styles.tableStickyRightCell,
                          right: "78px",
                        }}
                      >
                        <Stack alignItems="center" justifyContent="center">
                          {productInfo?.errors &&
                          Object.keys(productInfo?.errors).length > 0 ? (
                            <Box sx={styles.errorCountTag}>
                              {Object.keys(productInfo?.errors).length}
                            </Box>
                          ) : (
                            <CheckCircleIcon
                              sx={{ fontSize: 20, color: greenColor }}
                            />
                          )}
                        </Stack>
                      </TableCell> */}

                        <TableCell
                          sx={{
                            ...styles.tableStickyLeftCell,
                            ...styles.tableStickyRightCell,
                          }}
                        >
                          <Box
                            minWidth="50px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            gap={1.5}
                          >
                            <Stack alignItems="center" justifyContent="center">
                              {productInfo?.errors &&
                              Object.keys(productInfo?.errors).length > 0 ? (
                                <Box sx={styles.errorCountTag}>
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      right: -14,
                                      top: -12,
                                    }}
                                  >
                                    <ErrorToolTipIcon errorMsg="Please review all columns for errors" />
                                  </Box>
                                  {Object.keys(productInfo?.errors).length}
                                </Box>
                              ) : (
                                <CheckCircleIcon
                                  sx={{ fontSize: 16, color: greenColor }}
                                />
                              )}
                            </Stack>

                            <IconButton
                              size="small"
                              onClick={() => {
                                handleDeleteSingleProducts(index);
                              }}
                              sx={{ p: 0 }}
                            >
                              <DeleteIcon
                                sx={{ fontSize: "16px", color: redColor }}
                              />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <ModalCard
              open={showDeleteAlert?.show}
              handleClose={() => {
                setShowDeleteAlert({ show: false, data: null });
              }}
              width="33%"
            >
              <DeleteAlert
                alertMsg={showDeleteAlert?.data?.message}
                onCancel={() => {
                  setShowDeleteAlert({ show: false, data: null });
                }}
                onDelete={() => {
                  handleDeleteProducts();
                }}
              />
            </ModalCard>
          </Box>
        ) : (
          <EmptyCatalogData
            message="Catalog details not uploaded. Please make sure the data is in the required format as in the template"
            goBack={() => {
              setCurrentPage("upload");
            }}
          />
        )}
      </Box>
    );
  }
);

export default ReviewAndPublish;

const styles = {
  heading: {
    fontSize: "14px",
    fontWeight: "600",
    color: blackShade19,
    mb: 0.2,
  },
  subHeading: {
    fontSize: "13px",
    fontWeight: "400",
    color: blackShade21,
    mb: 2,
  },

  tableWrapper: {
    width: "calc(100vw - 310px)",
    borderRadius: "8px",
    border: `1px solid ${borderTextBoxColor}`,
  },

  tableContainer: {
    borderRadius: "8px",
    overflow: "scroll",
    maxHeight: "calc(100vh - 350px)",
  },

  tableCell: {
    color: greyColor47,
    fontSize: "12.5px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    padding: "8px 15px",
    backgroundColor: greyColor42,
    borderRight: `1px solid ${whiteColor}`,
  },

  colSticky: {
    // position: "-webkit-sticky",
    // position: isSticky ? "sticky" : "unset",
    // width: isSticky ? "100px" : "auto",
    // minWidth: isSticky ? "100px" : "auto",
    // maxWidth: isSticky ? "100px" : "auto",
    // left: "0px",
    // right: isRight ? "0px" : "unset",

    ":first-child": { position: "sticky", left: "0px" },
    // ":nth-last-child(2)": {
    //   position: "sticky",
    //   right: "78px",
    // },
    ":last-child": { position: "sticky", right: "-3px" },
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  statuSticky: (isStatus) => ({
    right: isStatus ? "100px" : "unset",
  }),

  colStickyRight: (isSticky) => ({
    position: "-webkit-sticky",
    position: isSticky ? "sticky" : "unset",
    width: isSticky ? "100px" : "auto",
    minWidth: isSticky ? "100px" : "auto",
    maxWidth: isSticky ? "100px" : "auto",
    right: "0px",
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  }),

  tableBodyCell: (isError) => ({
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: isError ? lightRedColor : whiteColor,
    padding: "8px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,

    boxShadow: isError ? `0px -1px 0 0 ${redColor} inset` : "none",
  }),

  tableStickyLeftCell: {
    minWidth: "70px !important",
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: whiteColor,
    padding: "10px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,
    position: "sticky",
    left: 0,
    zIndex: 10,
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  tableStickyRightCell: {
    position: "sticky",
    right: 0,
    boxShadow: `1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  prodNameBox: {
    minWidth: "250px",
    maxWidth: "250px",
    whiteSpace: "normal",
    fontSize: 12,
    fontWeight: 600,
    color: blackShade19,
    lineHeight: "16px",
  },

  prodDescriptionBox: {
    minWidth: "550px",
    maxWidth: "650px",
    whiteSpace: "normal",
  },

  checkbox: {
    color: greyColor21,
    p: 0,
    transform: "scale(1.3)",
    "&.Mui-checked": {
      color: blueColor04,
    },
  },

  errorCountTag: {
    backgroundColor: lightRedColor,
    color: redColor,
    fontSize: 12,
    fontWeight: 600,
    p: "3px 18px",
    borderRadius: "10px",
    position: "relative",
  },

  deleteBtn: {
    fontSize: 13,
    fontWeight: 500,
    color: whiteColor,
    p: "4px 30px",
    border: `1.5px solid ${redColor}`,
    backgroundColor: redColor,
    "&:hover": {
      color: redColor,
      backgroundColor: whiteColor,
    },
  },
};
