import { Box, Checkbox, Radio, Typography } from "@mui/material";
import React from "react";
import {
  BGColor05,
  blackShade19,
  blackShade43,
  border25,
  greyColor40,
  lightGreen02,
  lightGreen03,
} from "../../../configs/styles/muiThemes";

const OndcGroupCard = ({
  isSelected,
  onChangeValue,
  category,
  subCategory,
  count,
}) => {
  return (
    <Box display="flex" alignItems="center" gap={2} sx={styles.card}>
      <Box>
        <Radio
          size="large"
          checked={isSelected}
          onChange={() => {
            onChangeValue();
          }}
        />
      </Box>

      <Box>
        <Typography sx={styles.textStyle}>
          Category: <span style={styles.valueText}>{category}</span>
        </Typography>
        <Typography sx={styles.textStyle}>
          SubCategory:
          <span style={styles.valueText}>{subCategory}</span>
        </Typography>

        <Box
          sx={{
            fontSize: 11.5,
            fontWeight: 500,
            color: blackShade43,
            backgroundColor: lightGreen02,
            border: `1px solid ${lightGreen03}`,
            borderRadius: "5px",
            padding: "2px 8px",
            mt: 0.8,
          }}
        >
          {count} rows selected for import in this Category
        </Box>
      </Box>
    </Box>
  );
};

export default OndcGroupCard;

const styles = {
  card: {
    backgroundColor: BGColor05,
    p: "12px",
    border: border25,
    borderRadius: "8px",
  },

  textStyle: {
    fontSize: 12,
    fontWeight: 400,
    color: greyColor40,
  },

  valueText: { color: blackShade19, fontWeight: 600, marginLeft: "8px" },
};
