import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  blackShade05,
  blackShade19,
  blueColor04,
  borderTextbox,
  deleteBtnColor,
  greyColor11,
  greyColor6,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import FormInputBox from "../../../components/Input/FormInputBox";

function BuyerAppForm({
  buyerLinks,
  menuList,
  error,
  handleOndcInfoChange,
  isLoading,
  onSubmit,
}) {
  const [emptyError, setEmptyError] = useState("");
  return (
    <Box>
      <Typography
        fontSize={{ xs: 12, md: 13 }}
        fontWeight={500}
        color={blackShade19}
        mb={1.5}
      >
        Add your ONDC Checkout Links from multiple ONDC buyer apps.
        <br />
        <strong style={{ fontWeight: "600", lineHeight:'24px' }}>
          Ex: Paytm, MyStore, Maginpin, OLA
        </strong>
      </Typography>

      {buyerLinks?.map((textInput, index) => (
        <Grid
          key={`link_${index}`}
          container
          display={"flex"}
          alignItems="center"
          justifyContent={{ xs: "flex-end", md: "flex-start" }}
          columnSpacing={3}
          rowSpacing={{ xs: 2, md: 0 }}
        >
          <Grid item xs={12} md={5}>
            <SelectWithLabel
              label="Buyer App"
              value={textInput?.displayName}
              onValueChange={(value) => {
                if (emptyError) setEmptyError("");

                if (buyerLinks.some((obj) => obj.code === value?.bapName))
                  setEmptyError("This Buyer app name already selected");

                let data = [...buyerLinks];
                data[index] = {
                  ...data[index],
                  code: value?.bapName,
                  displayName: value?.displayName,
                };

                handleOndcInfoChange(data);
              }}
              returnFullItem
              menuList={menuList || []}
              valueSelector="displayName"
            />
          </Grid>

          <Grid item xs={10.1} md={10.5}>
            <Box mt={{ xs: 0, md: 1.5 }}>
              <FormInputBox
                label="Url"
                placeholder=""
                value={textInput?.value}
                onChange={({ target }) => {
                  let data = [...buyerLinks];
                  data[index] = { ...data[index], value: target?.value };
                  handleOndcInfoChange(data);
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={1.9} md={1}>
            <IconButton
              onClick={() => {
                if (index === buyerLinks?.length - 1 && emptyError)
                  setEmptyError("");
                let data = [...buyerLinks];
                if (data?.length === 1) {
                  handleOndcInfoChange([{ code: "", value: "" }]);
                  return;
                }

                data.splice(index, 1);
                handleOndcInfoChange(data);
              }}
              sx={{ ...styles.iconBtn, ...styles.deleteBtn }}
            >
              <DeleteRoundedIcon sx={{ fontSize: { xs: 18, md: 20 } }} />
            </IconButton>
          </Grid>

          <Grid item xs={12} md={12} mt={0}>
            {buyerLinks?.length - 1 === index && (
              <>
                {emptyError ? (
                  <Typography
                    variant="subtitle1"
                    component="p"
                    fontSize={12}
                    sx={{
                      mt: { xs: "-15px !important", md: "0px !important" },
                      mb: { xs: "0px !important", md: "10px !important" },
                      textAlign: "start !important",
                    }}
                    style={{ color: "red" }}
                  >
                    {emptyError}
                  </Typography>
                ) : null}

                {error ? (
                  <Typography
                    variant="subtitle1"
                    component="p"
                    fontSize={12}
                    sx={{
                      mt: { xs: "-15px !important", md: "0px !important" },
                      mb: { xs: "0px !important", md: "10px !important" },
                      textAlign: "start !important",
                    }}
                    style={{ color: "red" }}
                  >
                    {error}
                  </Typography>
                ) : null}

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={2}
                >
                  <Button
                    sx={styles.addIconBtn}
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      if (textInput?.code && textInput?.value && !emptyError) {
                        const data = [...buyerLinks, { code: "", value: "" }];
                        handleOndcInfoChange(data);
                      } else
                        setEmptyError(
                          emptyError ? emptyError : "Enter valid input"
                        );
                    }}
                    startIcon={
                      <AddRoundedIcon
                        sx={{ fontSize: { xs: "18px", md: "20px" } }}
                      />
                    }
                  >
                    Add Url
                  </Button>

                  <Button
                    sx={styles.submitBtn}
                    variant="contained"
                    disableElevation
                    startIcon={
                      isLoading ? (
                        <CircularProgress size={14} color="inherit" />
                      ) : null
                    }
                    disabled={emptyError || !buyerLinks?.[0]?.code}
                    onClick={() => {
                      setEmptyError("");
                      onSubmit();
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      ))}
    </Box>
  );
}

export default BuyerAppForm;

const styles = {
  btn: (bgColor, textcolor) => ({
    background: bgColor,
    padding: { xs: "3px 24px", md: "4px 45px" },
    fontSize: { xs: "12px", md: "14px" },
    color: textcolor,
    borderRadius: "4px",
    fontWeight: 500,
    border: "1px solid " + bgColor,
  }),

  labelText: {
    fontSize: 13,
    color: greyColor6,
    fontWeight: 400,
    // mb: "8px !important",
    isplay: "inline",
  },

  inputBox: {
    fontSize: 14,
    borderBottom: borderTextbox,
    backgroundColor: whiteColor,
    padding: "7px 0px",
    mb: 1,
  },

  iconBtn: {
    width: "38px",
    height: "38px",
    borderRadius: "5px",
    border: `1px solid rgb(133 147 172)`,
  },

  addBtn: {
    backgroundColor: "rgb(133 147 172)",
    color: whiteColor,
    "&:hover": {
      backgroundColor: whiteColor,
      color: "rgb(133 147 172)",
    },
  },

  deleteBtn: {
    width: { xs: "30px", md: "37px" },
    height: { xs: "30px", md: "37px" },
    border: `1px solid ${deleteBtnColor}`,
    backgroundColor: whiteColor,
    color: deleteBtnColor,
    "&:hover": {
      backgroundColor: deleteBtnColor,
      color: whiteColor,
    },
  },

  demoInput: {
    fontSize: "12px",
    fontWeight: 400,
    color: greyColor6,
    padding: "8px 0 16px",
    borderBottom: `1px solid ${greyColor11}`,
  },

  demoInfoText: {
    fontSize: "13px",
    fontWeight: 400,
    color: greyColor6,
    lineHeight: "20px",
    // padding: "8px 0",
  },

  dotUi: {
    width: "6px",
    height: "6px",
    borderRadius: "8px",
    backgroundColor: greyColor6,
  },

  addIconBtn: {
    padding: "4px 14px",
    fontSize: "12px",
    fontWeight: 600,
    color: { xs: blackShade19, md: blueColor04 },
    backgroundColor: whiteColor,
    border: {
      xs: `1px solid ${blackShade19}`,
      md: `1px solid ${blueColor04}`,
    },
    "&:hover": {
      color: { xs: blackShade19, md: whiteColor },
      backgroundColor: { xs: whiteColor, md: blueColor04 },
    },
  },

  submitBtn: {
    padding: "4px 14px",
    fontSize: "12px",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    border: {
      xs: `1.2px solid ${mobilePrimary}`,
      md: `1.2px solid ${blueColor04}`,
    },
    "&:hover": {
      color: { xs: mobilePrimary, md: blueColor04 },
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },
};
