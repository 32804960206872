import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import FormInputBox from "../../../components/Input/FormInputBox";
import {
  blackColor,
  blackShade21,
  blueColor02,
  blueColor04,
  greyColor24,
  greyShade04,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import DashboardStepper from "../../Dashboard/DashboardStepper";
import { ECOMMERCE_STEP_LIST } from "../../../utils/appData";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import RadiosBtnWithLabel from "../../../components/Input/RadiosBtnWithLabel";
import DiscountInputModal from "../PopupModals/DiscountInputModal";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getActiveBusinessInfo } from "../../../reducers/businessPreview/businessPreviewSlice";
import {
  ECOMINPUTSUPPORTLABEL,
  emailAddress,
  whatsAppNumber,
} from "../../../configs/Constants";
import { stickyHeader } from "../../../utils/styles";

function AddDiscountDetails({
  data,
  onValueChange,
  currentPage,
  gotoNext,
  goBack,
  deliveryMethods,
  handleCancel,
}) {
  const dispatch = useDispatch();
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
    }
  }, []);

  const CASHBACK_VALIDITY = [
    { label: "Select Cashback Validity" },
    { label: "90 days", value: 90 },
    { label: "180 days", value: 180 },
    { label: "365 days", value: 365 },
  ];

  const CASHBACK_PERCENTAGES = [
    { label: "Select Cashback Percentage" },
    { label: "0%", value: 0 },
    { label: "3%", value: 3 },
    { label: "5%", value: 5 },
    { label: "10%", value: 10 },
    { label: "15%", value: 15 },
    { label: "20%", value: 20 },
  ];

  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Support+Request.+`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };

  return (
    <Box>
      {/* <Box sx={stickyHeader} pb={1.5}>
        <Typography
          component="h6"
          fontSize={{ xs: 15, md: 17 }}
          color={blackColor}
          fontWeight={600}
          lineHeight="20px"
          mb={"4px !important"}
        >
          E-Commerce Details
        </Typography>

        <Typography
          variant="h6"
          fontSize={13}
          fontWeight={500}
          color={blackShade21}
          display="inline"
          mb="15px"
        >
          {ECOMINPUTSUPPORTLABEL}{" "}
          <Typography
            // variant="body1"
            fontSize={13}
            fontWeight={500}
            color={blueColor04}
            display="inline"
            // onclick={handleChatClick}
            component="a"
            href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Support+Request.+&fs=1&to=${emailAddress}&tf=cm`}
            target="_blank"
          >
            Email
          </Typography>
          {"  "}
          or{" "}
          <Typography
            // variant="body1"
            fontSize={13}
            fontWeight={500}
            color={blueColor04}
            display="inline"
            sx={{ cursor: "pointer" }}
            onClick={handleChatClick}
          >
            Whatsapp
          </Typography>
        </Typography>
      </Box> */}
      <Box sx={styles.card}>
        {/* <DashboardStepper
          stepList={ECOMMERCE_STEP_LIST}
          currentPage={currentPage}
          isAddProduct
        /> */}

        <Box m="15px 0 15px 0">
          <Typography
            component="h6"
            fontSize={{ xs: 15, md: 15 }}
            color={blackColor}
            fontWeight={600}
            lineHeight="20px"
          >
            Discount Details
          </Typography>

          <Box sx={{ mt: "5px" }}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} md={2.5}>
                <FormInputBox
                  label="Free shipment orders above"
                  placeholder=""
                  startIcon="₹"
                  value={data?.immediateDiscount}
                  onChange={({ target }) => {
                    const value = target?.value?.replace(/[^0-9]/g, "");
                    onValueChange("immediateDiscount", value);
                  }}
                  error={data?.errors?.immediateDiscount}
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mt: "10px" }}>
            <Typography component="h6" sx={styles.heading} mb={0.5}>
              Cashback on Orders
            </Typography>

            <Grid container rowSpacing={{ xs: 0.5, md: 2 }} columnSpacing={3}>
              <Grid item xs={12} md={3.3}>
                <SelectWithLabel
                  label="Cashback Percentage (10% preferred)"
                  menuList={CASHBACK_PERCENTAGES}
                  value={
                    data?.rewardPointPercent?.label || data?.rewardPointPercent
                  }
                  onValueChange={(value) => {
                    onValueChange("rewardPointPercent", value);
                  }}
                  returnFullItem
                  errorMsg={data?.errors?.rewardPointPercent}
                />
              </Grid>

              {data?.rewardPointPercent?.label !==
                "Select Cashback Percentage" &&
              data?.rewardPointPercent?.label !== "0%" ? (
                <>
                  <Grid item xs={12} md={2.7}>
                    <FormInputBox
                      label="Max Cashback usage per Order"
                      placeholder=""
                      startIcon="₹"
                      value={data?.referralPointsMaxPerTxn}
                      onChange={({ target }) => {
                        const value = target?.value?.replace(/[^0-9]/g, "");
                        onValueChange("referralPointsMaxPerTxn", value);
                      }}
                      error={data?.errors?.referralPointsMaxPerTxn}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SelectWithLabel
                      label="Cashback Validity"
                      menuList={CASHBACK_VALIDITY}
                      value={
                        data?.rewardExpirationDays?.label ||
                        data?.rewardExpirationDays
                      }
                      onValueChange={(value) => {
                        onValueChange("rewardExpirationDays", value);
                      }}
                      returnFullItem
                      errorMsg={data?.errors?.rewardExpirationDays}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} md={8} />
              )}

              {/* {deliveryMethods?.includes("Fulfillment by Business") && ( */}
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: "10px" }}>
                  <Typography component="h6" sx={styles.heading}>
                    Delivery discount
                  </Typography>

                  <Typography
                    variant="body1"
                    fontSize={12}
                    fontWeight={400}
                    color={greyShade04}
                    mb="15px !important"
                  >
                    Additional Discount based on distance (Distance between
                    warehouse and customer address)
                  </Typography>
                  {matches ? <Box width={"50%"}>
                    <DiscountInputModal
                      label=""
                      placeholder="Ex: 5-50KM 3%, 51-100KM 10%"
                      required
                      disabled
                      onValueChange={() => {}}
                      value={data?.additionalDiscount}
                      setMoreProducts={(data) => {
                        onValueChange("additionalDiscount", data);
                      }}
                    />
                  </Box>: <DiscountInputModal
                      label=""
                      placeholder="Ex: 5-50KM 3%, 51-100KM 10%"
                      required
                      disabled
                      onValueChange={() => {}}
                      value={data?.additionalDiscount}
                      setMoreProducts={(data) => {
                        onValueChange("additionalDiscount", data);
                      }}
                    />}

                  <Typography
                    variant="body2"
                    sx={styles.errorText}
                    m="0px 0 12px"
                  >
                    {data?.errors?.additionalDiscount}
                  </Typography>
                </Box>
              </Grid>
              {/* )} */}
            </Grid>
          </Box>
        </Box>

        <Box mt="20px">
          <ActionButtons
            onBackClick={goBack}
            onSaveClick={() => {}}
            onNextClick={gotoNext}
            onCancelClick={handleCancel}
            showBackBtn
            hideSaveBtn
            backBtnText="Previous"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default AddDiscountDetails;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    p: "5px 20px 20px 20px",
    m: { xs: "0px 0 10px", md: "0 0 25px" },
  },

  heading: {
    fontSize: { xs: 15, md: 15 },
    color: blackColor,
    fontWeight: 600,
    lineHeight: "20px",
  },

  required: {
    fontSize: 14,
    fontWeight: 400,
    color: "red",
  },
  errorText: {
    fontSize: 12,
    fontWeight: 500,
    color: "red",
    mt: -1,
  },
};
