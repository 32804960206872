import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import {
  authInputBorder,
  blackShade05,
  blackShade09,
  errorTextColor,
  whiteColor,
  yellowColor,
} from "../../configs/styles/muiThemes";
import { useState } from "react";
import { getUserName } from "../../utils/validationUtils";
import { getPhoneNumber } from "../../utils/authHelpers";
import { whatsAppNumber } from "../../configs/Constants";
import { allowOnlyEnglish } from "../../utils/helper";

function SendRequestForm() {
  const [businessName, setBusinessName] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    setError(false);

    if (businessName === "") {
      setError(true);
      return;
    }

    const msg = `${getUserName()}- ${getPhoneNumber()?.slice(
      3
    )} is requesting business dashboard access for ${businessName}`;

    window.open(
      `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${msg}`,
      "_blank"
    );
  };

  return (
    <Box sx={styles.card}>
      <Typography
        fontWeight={600}
        color={blackShade05}
        fontSize={{ xs: 15, md: 18 }}
        textAlign={"center"}
        mt={"8px"}
        lineHeight={1.5}
      >
        If you business is already registered with COSTBO, please enter the
        Business name and send a request to access
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          mt: { xs: 2, md: 4 },
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "60%" } }}>
          <TextField
            placeholder="Enter Business Name"
            value={businessName}
            onChange={({ target }) => {
              if (error) setError(false);

              const finalValue = allowOnlyEnglish(target.value);
              setBusinessName(finalValue);
            }}
            sx={{
              width: { xs: "100%", md: "100%" },
              border: authInputBorder,
              p: "5px 10px",
              borderRadius: "6px",
              fontSize: 14,
              fontWeight: 500,
            }}
            InputProps={{
              disableUnderline: true,
            }}
            variant="standard"
          />
          {error ? (
            <Typography
              variant="subtitle1"
              component="p"
              fontSize={13}
              color={errorTextColor}
              mt={1}
            >
              Enter the business name
            </Typography>
          ) : null}
        </Box>

        <Button
          sx={styles.actionBtn}
          variant="contained"
          disableElevation
          onClick={handleSubmit}
          // disabled={loader}
        >
          Send Request
        </Button>
      </Box>
    </Box>
  );
}

export default SendRequestForm;

const styles = {
  card: {
    backgroundColor: whiteColor,
    p: "20px",
    borderRadius: "10px",
  },
  actionBtn: {
    fontSize: { md: "14px", xs: "13px" },
    fontWeight: 600,
    color: blackShade09,
    background: yellowColor,
    p: "7px 20px",
    borderRadius: "6px",
    "&:hover": {
      background: "none",
      backgroundColor: whiteColor,
      color: blackShade09,
      border: `1px solid ${blackShade09}`,
    },
    letterSpacing: "0.5px",
  },
};
