import { Box, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  greyColor28,
  lightBlueColor01,
  orangeStatusColor,
  outOfStockColor,
  whiteColor,
} from "../../../../configs/styles/muiThemes";

function ApproveHeaderCard({
  orderId,
  ondcOrderId,
  orderDate,
  status,
  orderType,
  getOndcBapNameBySubscriberId,
}) {
  const getBGColorByStatus = (status) => {
    switch (status) {
      case "ordercreated":
        return orangeStatusColor;
      case "orderapproved":
        return blackShade05;
      case "ordershipped":
        return lightBlueColor01;
      case "orderdelivered":
        return lightBlueColor01;
      case "bordercanceled":
        return outOfStockColor;

      default:
        return "";
    }
  };

  const getOrderStatusText = (status) => {
    switch (status) {
      case "ordercreated":
        return "Pending";
      case "orderapproved":
        return "Approved";
      case "ordershipped":
        return "Shipped";
      case "orderdelivered":
        return "Completed";
      case "bordercanceled":
        return "Canceled";

      default:
        return "";
    }
  };
  return (
    <Box sx={styles.orderInfoBox}>
      <Box>
        <Box mb={{ md: 0.8, xs: 0.5 }}>
          <Typography variant="h6" sx={styles.orderIdText}>
            Order ID:{" "}
            <Typography
              variant="h6"
              sx={{ ...styles.orderIdText, fontWeight: 600 }}
            >
              {orderId}
            </Typography>
          </Typography>
        </Box>

        {orderType?.orderType == "ondcOrder" && (
          <>
            <Box mb={{ md: 0.8, xs: 0.5 }}>
              <Typography variant="h6" sx={styles.orderIdText}>
                ONDC Order ID:{" "}
                <Typography
                  variant="h6"
                  sx={{ ...styles.orderIdText, fontWeight: 600 }}
                >
                  {ondcOrderId || ""}
                </Typography>
              </Typography>
            </Box>

            <Box mb={{ md: 0.8, xs: 0.5 }}>
              <Typography variant="h6" sx={styles.orderIdText}>
                ONDC Buyer App:{" "}
                <Typography
                  variant="h6"
                  sx={{ ...styles.orderIdText, fontWeight: 600 }}
                >
                  {getOndcBapNameBySubscriberId()}
                </Typography>
              </Typography>
            </Box>
          </>
        )}

        <Typography variant="h6" sx={styles.orderIdText}>
          Order Date:{" "}
          <Typography
            variant="h6"
            sx={{ ...styles.orderIdText, fontWeight: 600 }}
          >
            {orderDate}
          </Typography>
        </Typography>
      </Box>

      <Box sx={styles.statusTag(getBGColorByStatus(status))}>
        {getOrderStatusText(status)}
      </Box>
    </Box>
  );
}

export default ApproveHeaderCard;

const styles = {
  orderIdText: {
    fontSize: { md: 14, xs: 13 },
    fontWeight: 500,
    lineHeight: "27px",
    color: blackShade05,
    display: "inline",
  },

  orderInfoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "12px",
    border: `1px solid ${greyColor28}`,
    p: "12px 0 12px 12px",
    mt: 2,
  },

  statusTag: (bgColor) => ({
    backgroundColor: bgColor || lightBlueColor01,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "20px",
    color: whiteColor,
    p: "0 15px 0 20px",
    position: "relative",

    "&::before": {
      content: '" "',
      width: "15px",
      height: "28px",
      backgroundColor: whiteColor,
      rotate: "40deg",
      position: "absolute",
      left: -3,
      top: -8,
    },
  }),
};
