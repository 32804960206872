import { getApiInstance } from "../../AppService";
import { DELETE_BULK_PRODUCT, GET_BULK_PRODUCTS_DETAILS, IMPORT_BULK_PRODUCT, UPDATE_BULK_PRODUCT, UPLOAD_BULK_PRODUCTS } from "../../constant";

// upload catalog excel data
export const uploadBulkProductApi = async (data) => {
  try {
    const formData = new FormData();
    formData.append('file', data?.selectedFile);

    const instance = getApiInstance({
      header: {
        businessID: data?.businessId,
      },
    });
    const response = await instance.post(UPLOAD_BULK_PRODUCTS, formData);
    console.log("uploadBulkProductApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// get bulk upload product details
export const getBulkUploadProductsDetailsAPI = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessID: data?.businessId,
        status: 'NEW'
      },
    });
    const response = await instance.get(GET_BULK_PRODUCTS_DETAILS);
    console.log("getBulkUploadProductsDetailsAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Delete products
export const deleteBulkProductApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessID: data?.businessId,
      },
    });
    const response = await instance.post(DELETE_BULK_PRODUCT, data?.postData);
    console.log("deleteBulkProductApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Update bolk products
export const updateBulkProductApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessID: data?.businessId,
      },
    });
    const response = await instance.post(UPDATE_BULK_PRODUCT, data?.postData);
    console.log("updateBulkProductApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Update bolk products
export const importBulkProductApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.post(IMPORT_BULK_PRODUCT, data?.postData);
    console.log("importBulkProductApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};