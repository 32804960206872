import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getGrievanceData, getIssuesData, searchCustomerGrievanceIssueIdAPI, searchCustomerGrievanceOrderIdAPI } from "../../service/api_calls/grievance/grievanceApi";
import { getCartInfoApi } from "../../service/api_calls/orders/OrdersApi";

const initialState = {
  grievanceData: null,
  issueDataIgm: null,
  cartData: null,
  fulfillmentData: null,

  grievanceLoader: false,
  issueDataIgmLoader: false,
  fulfillmentLoader: false,
  cartLoader: false,

  searchByOrderId: null,
  searchByOrderIdLoader: false,

  searchByIssueId: null,
  searchByIssueIdLoader:false,
};

//get all grievance details
export const getGrievanceDetails = createAsyncThunk(
  "getGrievanceDetails",
  async (param, thunkAPI) => {
    const res = await getGrievanceData(param);
    return res;
  }
);

//get all grievance details
export const getIssuesDataIgm = createAsyncThunk(
  "getIssuesDataIgm",
  async (param, thunkAPI) => {
    const res = await getIssuesData(param);
    return res;
  }
);

//get all fulfillment grievance details
export const getFulfillmentGrievanceDetails = createAsyncThunk(
  "getFulfillmentGrievanceDetails",
  async (param, thunkAPI) => {
    const res = await getGrievanceData(param);
    return res;
  }
);

//get cart details
export const getCartDetails = createAsyncThunk(
  "getCartDetails",
  async (param, thunkAPI) => {
    const res = await getCartInfoApi(param);
    return res;
  }
);

export const searchCustomerGrievanceOrderId = createAsyncThunk(
  "searchCustomerGrievanceOrderId",
  async (param, thunkAPI) => {
    const res = await searchCustomerGrievanceOrderIdAPI(param);
    return res;
  }
);
export const searchCustomerGrievanceIssueId = createAsyncThunk(
  "searchCustomerGrievanceIssueId",
  async (param, thunkAPI) => {
    const res = await searchCustomerGrievanceIssueIdAPI(param);
    return res;
  }
);

export const grievanceSlice = createSlice({
  name: "grievance",
  initialState,
  reducers: {
    clearCartInfo: (state, action) => {
      state.cartData = null;
    },
    clearOrderIdInfo: (state, action) => {
      state.searchByOrderId = null;
    },
    clearIssueIdInfo: (state, action) => {
      state.searchByIssueId = null;
    },
  },
  extraReducers: {
    //get all getIssuesDataIgm
    [getIssuesDataIgm.pending]: (state) => {
      state.issueDataIgm = null;
      state.issueDataIgmLoader = true;
    },
    [getIssuesDataIgm.fulfilled]: (state, { payload }) => {
      state.issueDataIgmLoader = false;
      state.issueDataIgm = payload || null;
    },
    [getIssuesDataIgm.rejected]: (state, { payload }) => {
      state.issueDataIgm = null;
      state.issueDataIgmLoader = false;
    },

    //get all grievance details
    [getGrievanceDetails.pending]: (state) => {
      state.grievanceData = null;
      state.grievanceLoader = true;
    },
    [getGrievanceDetails.fulfilled]: (state, { payload }) => {
      state.grievanceLoader = false;
      state.grievanceData = payload || null;
    },
    [getGrievanceDetails.rejected]: (state, { payload }) => {
      state.grievanceData = null;
      state.grievanceLoader = false;
    },

    //get all grievance details
    [getFulfillmentGrievanceDetails.pending]: (state) => {
      state.fulfillmentData = null;
      state.fulfillmentLoader = true;
    },
    [getFulfillmentGrievanceDetails.fulfilled]: (state, { payload }) => {
      state.fulfillmentLoader = false;
      state.fulfillmentData = payload || null;
    },
    [getFulfillmentGrievanceDetails.rejected]: (state, { payload }) => {
      state.fulfillmentData = null;
      state.fulfillmentLoader = false;
    },

    //get cart details
    [getCartDetails.pending]: (state) => {
      state.cartData = null;
      state.cartLoader = true;
    },
    [getCartDetails.fulfilled]: (state, { payload }) => {
      state.cartLoader = false;
      state.cartData = payload?.cart || null;
    },
    [getCartDetails.rejected]: (state, { payload }) => {
      state.cartData = null;
      state.cartLoader = false;
    },

    // search by orderId
    [searchCustomerGrievanceOrderId.pending]: (state) => {
      state.searchByOrderIdLoader = true;
      state.searchByOrderId = null;
    },
    [searchCustomerGrievanceOrderId.fulfilled]: (state, { payload }) => {
      // console.log(
      //   "payload123",
      //   payload?.found ? myArray.splice(1,1) : []
      // );
      const myArray = Object.values(payload);
      state.searchByOrderId = payload?.found ? myArray?.splice(1, 1) : [];
      state.searchByOrderIdLoader = false;
    },
    [searchCustomerGrievanceOrderId.rejected]: (state, { payload }) => {
      state.searchByOrderIdLoader = false;
      state.searchByOrderId = null;
    },

    // search by issueId
    [searchCustomerGrievanceIssueId.pending]: (state) => {
      state.searchByIssueIdLoader = true;
      state.searchByIssueId = null;
    },
    [searchCustomerGrievanceIssueId.fulfilled]: (state, { payload }) => {
      const myArray = Object.values(payload);
      console.log("payload", payload);
      state.searchByIssueId = payload?.found ? myArray?.splice(1, 1) : [];

      state.searchByIssueIdLoader = false;
    },
    [searchCustomerGrievanceIssueId.rejected]: (state, { payload }) => {
      state.searchByIssueIdLoader = false;
      state.searchByIssueId = null;
    },
  },
});

export const { clearCartInfo, clearOrderIdInfo, clearIssueIdInfo } =
  grievanceSlice.actions;
export const grievanceReducer = grievanceSlice.reducer;
