import React, { useEffect, useState } from "react";
import {
  BGColor02,
  blackShade05,
  blackShade19,
  blueColor04,
  greyColor15,
  greyColor16,
  greyColor42,
  greyColor6,
  greyColor9,
  greyColorM01,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

function BusinessContactDetailsPop({ activeBusinessInfo, commerceInfo, onManageClick }) {
  const [emailString, setEmailString] = useState("");

  useEffect(() => {
    if (activeBusinessInfo?.businessMailingAddress) {
      let mailText = "";
      const emailList = activeBusinessInfo?.businessMailingAddress?.split(",");

      if (emailList?.length > 0) {
        emailList?.forEach((item, index) => {
          mailText =
            index === emailList?.length - 1
              ? mailText + item
              : mailText + `${item},  `;
        });

        setEmailString(mailText);
      }
    }

    return () => {};
  }, [activeBusinessInfo]);
  return (
    <Box sx={styles.card}>
      <Box p={{ xs: "12px", md: "15px" }}>
        <Typography
          variant="h3"
          fontSize={16}
          fontWeight={600}
          lineHeight="25px"
          color={blackShade05}
          textAlign="center"
          p={{ xs: "0 0 4px 0", md: "5px 50px" }}
        >
          Contact Detail
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: whiteColor,
          p: "10px 15px 0px 15px",
        }}
      >
        <Box display='flex' alignItems='center' justifyContent='space-between' mb={0.5}>
          <Typography
            variant="h3"
            fontSize={14}
            fontWeight={600}
            lineHeight="25px"
            color={blackShade05}
            p={{ xs: "0 0 4px 0", md: "5px 50px" }}
          >
            Owner
          </Typography>

          <Button sx={styles.btn} onClick={onManageClick}>
            Manage
          </Button>
        </Box>

        <TableContainer component={Box} border={`1px solid ${greyColor42}`}>
          {/* <Table sx={{ minWidth: 350 }} aria-label="simple table"> */}
          <Table sx={{ minWidth: 250 }} aria-label="simple table">
            <TableHead sx={{ position: "sticky" }}>
              <TableRow
                sx={{ position: "sticky", backgroundColor: greyColor42 }}
              >
                <TableCell
                  sx={{ ...styles.tableCell, p: "0 5px 0 15px !important" }}
                >
                  Name
                </TableCell>
                <TableCell sx={styles.tableCell}>Mobile Number</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  sx={{
                    ...styles.tableBodyCell(),
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    ml: 1,
                  }}
                  component="th"
                  scope="row"
                >
                  {activeBusinessInfo?.owner?.name || ""}
                </TableCell>
                <TableCell sx={styles.tableBodyCell()}>
                  {activeBusinessInfo?.owner?.phone
                    ? activeBusinessInfo?.owner?.phone?.replace("+91", "+91 ")
                    : ""}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            backgroundColor: whiteColor,
            // p: "10px 15px 0px 15px",
          }}
          display={"flex"}
          flexDirection={"column"}
          pt={1}
        >
          <Typography
            variant="h3"
            fontSize={14}
            fontWeight={600}
            lineHeight="25px"
            color={blackShade05}
            p={{ xs: "0 0 4px 0", md: "5px 50px" }}
          >
            Warehouse Contact
          </Typography>
          <Box
            border={`1px solid ${greyColor42}`}
            padding={"10px 15px 0px 15px"}
            borderRadius={"10px"}
            display={"flex"}
            flexDirection={"column"}
            gap={1}
          >
            <Box width={"100%"}>
              <Typography
                variant="body2"
                fontSize={{ xs: 12, md: 11.28 }}
                color={greyColorM01}
                fontWeight={500}
                lineHeight="20px"
              >
                Mobile Number
              </Typography>

              <Typography
                component="h6"
                fontSize={{ xs: 12, md: 15 }}
                color={blackShade05}
                fontWeight={600}
              >
                {activeBusinessInfo?.contactNos?.length > 0
                  ? activeBusinessInfo?.contactNos?.[0]?.number?.replace(
                      "+91",
                      "+91 "
                    )
                  : ""}
              </Typography>
            </Box>
            <Box width={"100%"}>
              <Typography
                variant="body2"
                fontSize={{ xs: 12, md: 11.28 }}
                color={greyColorM01}
                fontWeight={500}
                lineHeight="20px"
              >
                address
              </Typography>

              <Typography
                component="h6"
                fontSize={{ xs: 12, md: 15 }}
                color={blackShade05}
                fontWeight={600}
              >
                {commerceInfo?.address || "N/A"}
              </Typography>
            </Box>
            <Box width={"100%"}>
              <Typography
                variant="body2"
                fontSize={{ xs: 12, md: 11.28 }}
                color={greyColorM01}
                fontWeight={500}
                lineHeight="20px"
              >
                Email Id
              </Typography>

              <Typography
                component="h6"
                fontSize={{ xs: 12, md: 15 }}
                color={blackShade05}
                fontWeight={600}
              >
                {emailString || "N/A"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: whiteColor,
            // p: "10px 15px 0px 15px",
          }}
          display={"flex"}
          flexDirection={"column"}
          pt={1}
          pb={2}
        >
          <Typography
            variant="h3"
            fontSize={14}
            fontWeight={600}
            lineHeight="25px"
            color={blackShade05}
            p={{ xs: "0 0 4px 0", md: "5px 50px" }}
          >
            Deligates - {activeBusinessInfo?.delegates?.length || 0}
          </Typography>
          <TableContainer component={Box} border={`1px solid ${greyColor42}`}>
            {/* <Table sx={{ minWidth: 350 }} aria-label="simple table"> */}
            <Table sx={{ minWidth: 250 }} aria-label="simple table">
              <TableHead sx={{ position: "sticky" }}>
                <TableRow
                  sx={{ position: "sticky", backgroundColor: greyColor42 }}
                >
                  <TableCell
                    sx={{
                      ...styles.tableCell,
                      p: "0 5px 0 15px !important",
                      borderRight: `1px solid ${whiteColor}`,
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell sx={styles.tableCell}>Mobile Number</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {activeBusinessInfo?.delegates?.map((item, index) => {
                  return (
                    <TableRow
                      sx={
                        {
                          //   "&:last-child td, &:last-child th": { border: 0 },
                        }
                      }
                    >
                      <TableCell
                        sx={{
                          ...styles.tableBodyCell(),
                          display: "flex",
                          alignItems: "center",
                          // gap: "10px",
                          // ml: 1,
                          borderBottom: `1px solid ${greyColor42}`,
                          borderRight: `1px solid ${greyColor42}`,
                        }}
                        component="th"
                        scope="row"
                      >
                        {item?.name || ""}
                      </TableCell>
                      <TableCell
                        sx={{
                          ...styles.tableBodyCell(),
                          borderBottom: `1px solid ${greyColor42}`,
                        }}
                      >
                        {item?.phone?.replace("+91", "+91 ") || ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}

export default BusinessContactDetailsPop;

const styles = {
  card: {
    minHeight: "100px",
    backgroundColor: BGColor02,
    borderRadius: "15px",
  },

  actionBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    color: blueColor04,
    border: `1px solid ${blueColor04}`,
    p: "4px 20px",
  },
  caption: {
    color: { xs: greyColor9, md: blueColor04 },
    textAlign: "right",
    fontSize: { xs: 12, md: 13 },
    fontWeight: "500",
    textAlign: "center",
  },
  radiolabel: {
    color: greyColor6,
    textAlign: "right",
    fontSize: "12px",
    fontWeight: "500",
    textAlign: "center",
  },

  cancelBtn: {
    color: { xs: blackShade19, md: blueColor04 },
    border: { xs: `1px solid ${blackShade19}`, md: `1px solid ${blueColor04}` },
    "&:hover": {
      color: whiteColor,
      backgroundColor: { xs: blackShade19, md: blueColor04 },
    },
  },

  proceedBtn: {
    color: whiteColor,
    background: { xs: mobilePrimary, md: blueColor04 },
    p: "5px 20px",
    border: "none",

    "&:hover": {
      color: { xs: mobilePrimary, md: blueColor04 },
      background: whiteColor,
      border: {
        xs: `1px solid ${mobilePrimary}`,
        md: `1px solid ${blueColor04}`,
      },
    },
  },
  tableHeadings: {
    align: "right",
    color: greyColor16,
    fontSize: "12px",
    fontWeight: "500",
    backgroundColor: BGColor02,
  },

  tableCell: {
    height: "34px !important",
    padding: "5px 5px !important",
    border: `1px solid ${greyColor42}`,
  },

  tableBodyCell: (color) => ({
    fontSize: 12,
    fontWeight: 500,
    color: color || greyColor15,
    padding: "10px 5px !important",
    border: `1px solid ${greyColor42}`,
  }),

  btn: {
    fontSize: 11.5,
    fontWeight: 400,
    color: mobilePrimary,
    textDecoration: "underline",
    textTransform: "unset",
    p: "0px",
    mb:1.2
  },
};
