import { Box, Typography } from "@mui/material";
import React from "react";
import CategoryPreview from "../Preview/CategoryPreview";
import AddONDCControls from "../../AddProductNew/Forms/AddONDCControls";
import CatalogActionBtns from "../../../components/Buttons/CatalogActionBtns";

function OndcForm({
  categoryData,
  ondcData,
  setOndcData,
  onValueChange,
  ondcSpecificationData,
  setOndcSpecificationData,
  productCodeList,
  unitOfMeasurementData,
  onNextClick,
  onBackClick,
  handleCancel,
  pageError,
}) {
  const handleKeyValueChange = (property, value, rootProperty, errCode) => {
    setOndcData((prevState) => ({
      ...prevState,
      [rootProperty]: { ...prevState[rootProperty], [property]: value },
      errors: prevState?.errors
        ? { ...prevState?.errors, [errCode]: "" }
        : null,
    }));
  };
  return (
    <Box>
      <AddONDCControls
        ondcSpecificationData={ondcSpecificationData}
        setOndcSpecificationData={setOndcSpecificationData}
        ondcData={ondcData}
        productCodeList={productCodeList}
        onValueChange={(name, value) =>
          onValueChange(name, value, ondcData, setOndcData)
        }
        handleKeyValueChange={handleKeyValueChange}
        unitOfMeasurementData={unitOfMeasurementData}
      />
      <Box>
        <CatalogActionBtns
          nextBtnText={"PREVIEW"}
          showBackBtn
          hideSaveBtn
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          onCancelClick={handleCancel}
        />
        {pageError && (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={12}
            fontWeight={500}
            textAlign={"right"}
            color="red"
            mt="6px"
          >
            Please fix the errors above to proceed
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default OndcForm;
