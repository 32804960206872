import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  blackShade04,
  blackShade05,
  greyColor6,
  logoImgborder,
  primaryColor2,
  progressBGColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { textOneLines } from "../../utils/styles";
import { useNavigate } from "react-router-dom";

function BusinessInfoCard({
  logo,
  businessName,
  description,
  showOnBoardedInfo,
  onClick,
}) {
  const navigate = useNavigate();

  const onLogoClick = () => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/dashboard`);
  };

  return (
    <Box sx={styles.card(true)} onClick={onClick || onLogoClick}>
      <Box
        sx={{
          display: "flex",
          // flexDirection: { xs: "column", md: "row" },
          // alignItems: { xs: "flex-start", md: "center" },
          alignItems: "center",
          gap: { xs: "6px", md: "10px" },
        }}
      >
        <Box
          component="img"
          src={logo}
          width={{ xs: "60px", md: "60px" }}
          height={{ xs: "60px", md: "60px" }}
          sx={{
            objectFit: "cover",
            borderRadius: { xs: "6px", md: "10px" },
            border: logoImgborder,
          }}
        />

        <Box>
          <Typography
            component="h6"
            fontSize={{ xs: 17, md: 18 }}
            color={blackColor}
            fontWeight={600}
            lineHeight="22px"
          >
            {businessName}
          </Typography>

          {description && (
            <Typography
              variant="body1"
              fontSize={{ xs: 11, md: 12 }}
              color={blackShade05}
              fontWeight={500}
              lineHeight={{ xs: "16px", md: "20px" }}
              sx={textOneLines}
            >
              {description}
            </Typography>
          )}

          {showOnBoardedInfo && (
            <Typography
              variant="body1"
              fontSize={{ xs: 11, md: 12 }}
              color={greyColor6}
              fontWeight={400}
              lineHeight={{ xs: "16px", md: "20px" }}
              sx={{ ...textOneLines, mt: 0.5 }}
            >
              {showOnBoardedInfo}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default BusinessInfoCard;

const styles = {
  card: (showBG) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "13px",
    cursor: "pointer",
  }),
};
