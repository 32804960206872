import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import {
  borderTextbox,
  greyColor40,
  orangeShade11,
} from "../../configs/styles/muiThemes";

function HorizontalSelectWithLabel({
  label,
  value,
  disabled,
  required,
  hintText,
  onValueChange,
  menuList,
  error,
}) {
  return (
    <Grid
      container
      columnSpacing={2}
      rowSpacing={1}
      display={"flex"}
      alignItems={"start"}
    >
      <Grid item md={6} xs={12}>
        <Typography
          variant="h3"
          fontSize={{ xs: 12, md: 13 }}
          color={greyColor40}
          fontWeight={400}
          mb={"0px"}
          sx={{
            display: "inline",
            opacity: disabled ? 0.7 : 1,
          }}
        >
          {label}{" "}
        </Typography>
        {required ? (
          <Typography variant="body1" sx={{ display: "inline", color: "#f00" }}>
            *
          </Typography>
        ) : null}
        {hintText && (
          <Typography
            sx={{ display: "block" }}
            fontSize={11.5}
            color={orangeShade11}
            fontWeight={400}
            mt={-0.7}
          >
            {hintText}
          </Typography>
        )}
      </Grid>
      <Grid item md={6} xs={12}>
        <Box
          sx={{
            border: borderTextbox,
            height: { xs: "38px", md: "auto" },
            borderRadius: "6px",
            pl: "6px",
          }}
        >
          <FormControl sx={styles.formControlStyle}>
            <Select
              value={value}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              fullWidth
              sx={{
                height: { xs: "34px", md: "30px" },
                pb: "2px",
                textTransform: "capitalize",
                ml: -1.5,
                fontWeight: 500,
              }}
              disabled={disabled}
            >
              {menuList.map((item, index) => {
                return (
                  <MenuItem
                    value={item}
                    key={"Menu_Item_" + index}
                    onClick={() => {
                      onValueChange(item);
                    }}
                    sx={{ fontWeight: 500, textTransform: "capitalize" }}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        {error ? (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={12}
            sx={{ mt: "4px !important", textAlign: "start !important" }}
            style={{ color: "red" }}
          >
            {/* Please enter, its a mandatory value */}
            {error}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default HorizontalSelectWithLabel;

const styles = {
  formControlStyle: {
    width: "100%",
    height: "auto",
    "& .css-hdw1oc": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline, .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
      {
        border: "none",
      },
  },
};
