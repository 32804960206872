import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  BGColor02,
  blackShade19,
  mobilePrimary,
  redColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ErrorIcon from "@mui/icons-material/Error";
import ModalCard from "../../../components/ModalCard";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import { dynamicSort } from "../../../utils/helper";

const MobileCategoryFilter = ({
  openModal,
  setOpenModal,

  applyONDCFilter,
  applyWebFilter,
  setIsClearFilter,
  setFilterType,

  ondcDropdownData,
  selectedOndcCategory,
  setSelectedOndcCategory,
  selectedOndcSubCat,
  setSelectedOndcSubCat,

  refreshProductList,
}) => {
  const [showONDCFilter, setShowONDCFilter] = useState(false);
  const [showWebstoreFilter, setShowWebstoreFilter] = useState(false);

  const ondcFilterRef = useRef(null);
  const webstoreFilterRef = useRef(null);

  const [filterLoader, setFilterLoader] = useState(false);
  const [filterError, setFilterError] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);

  const [ondcCategoryList, setOndcCategoryList] = useState([]);
  const [ondcSubCatList, setOndcSubCatList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedSubCat, setSelectedSubCat] = useState("All");

  // Prepare list data for filter dropdown
  const formatCategory = (category, setValue, valueSelector) => {
    const sortedArray = category?.slice()?.sort(dynamicSort(valueSelector));
    const catList = sortedArray?.map((item) => {
      return { ...item, label: item?.[valueSelector] };
    });

    setValue([{ label: "All" }, ...catList]);
  };

  const handleOndcCategoryChange = (data) => {
    setSelectedOndcCategory(data);
    setSelectedOndcSubCat("All");

    if (data === "All") {
      setOndcSubCatList([]);
      return;
    }

    setFilterError("");

    const categoryData = ondcCategoryList?.filter((item) => {
      return item?.ondcValue === data;
    });

    formatCategory(
      categoryData?.[0]?.productSubCategory,
      setOndcSubCatList,
      "ondcSubValue"
    );
  };

  const handleOndcSubCategoryChange = (data) => {
    setSelectedOndcSubCat(data);
  };

  // Apply ONDC Filter
  const handleOndcFilter = async () => {
    setFilterError("");

    if (ondcCategoryList?.length === 1) {
      setFilterError("ONDC category not assigned for any of the products");
      return;
    }
    if (selectedOndcCategory === "All") {
      setFilterError("Select the Main Category");
      return;
    }

    setFilterLoader(true);

    const categoryData = ondcCategoryList?.find((item) => {
      return item?.ondcValue === selectedOndcCategory;
    });

    const subCategoryData = ondcSubCatList?.find((item) => {
      return item?.ondcSubValue === selectedOndcSubCat;
    });

    console.log("SELECTED___categoryData:", categoryData);
    console.log("SELECTED___subCategoryData:", subCategoryData || null);

    applyONDCFilter(categoryData, subCategoryData);

    setFilterLoader(false);
    setShowONDCFilter(false);

    setIsClearFilter({
      show: true,
      category: `${selectedOndcCategory} - ${selectedOndcSubCat}`,
      message: `ONDC Category Filter results for “${selectedOndcCategory} - ${selectedOndcSubCat}”`,
    });

    setOpenModal(false)
  };

  const onOndcFilterClose = () => {
    setFilterError("");
    setShowONDCFilter(false);
    setSelectedOndcCategory("All");
    setSelectedOndcSubCat("All");
    setOndcSubCatList([]);

    setIsClearFilter(null);
    setFilterType("");

    setOpenModal(false);
    refreshProductList(true);
  };

  const clearAllOndcFilterData = () => {
    setSelectedOndcCategory("All");
    setSelectedOndcSubCat("All");
    setOndcSubCatList([]);
  };

  const prepareCategoryListData = async (ondcCategoryInfo) => {
    const ondcFilteredCategory = ondcCategoryInfo?.filter(
      (item) => item?.ondcCode
    );

    formatCategory(ondcFilteredCategory, setOndcCategoryList, "ondcValue");
    console.log("ondcCategory", ondcCategoryInfo, ondcFilteredCategory);
  };

  useEffect(() => {
    if (ondcDropdownData?.length > 0) {
      prepareCategoryListData(ondcDropdownData);
    }
    return () => {};
  }, [ondcDropdownData]);

  return (
    <ModalCard
      open={openModal}
      handleClose={() => {
        onOndcFilterClose();
      }}
      width="40%"
    >
      <Box sx={{ backgroundColor: whiteColor, borderRadius: 1.8 }}>
        <Box
          sx={{
            backgroundColor: BGColor02,
            p: 1.5,
            borderRadius: "14.4px 14.4px 0 0",
            textAlign: "center",
          }}
        >
          <Typography
            variant="body1"
            fontSize={16}
            fontWeight={600}
            color={blackShade19}
            mb={0.3}
          >
            Filter Products by Categories
          </Typography>
        </Box>

        <Box sx={{ p: 2, mb: 1 }}>
          <Typography
            variant="h6"
            fontSize={14}
            fontWeight={600}
            color={blackShade19}
            mb={0.4}
          >
            ONDC Categories
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SelectWithLabel
                label="ONDC Category"
                value={selectedOndcCategory}
                onValueChange={handleOndcCategoryChange}
                menuList={ondcCategoryList || []}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <SelectWithLabel
                label="ONDC Sub Category"
                value={selectedOndcSubCat}
                onValueChange={handleOndcSubCategoryChange}
                menuList={selectedOndcCategory === 'All' ? [] : ondcSubCatList || []}
                disabled={ondcSubCatList?.length === 0}
                required
              />
            </Grid>
          </Grid>

          {filterError ? (
            <Typography
              fontSize={12}
              fontWeight={500}
              color="red"
              mt={2}
              lineHeight='18px'
            >
              {filterError}
            </Typography>
          ) : null}
        </Box>

        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          sx={{
            backgroundColor: BGColor02,
            p: 2,
            borderRadius: "0 0 14.4px 14.4px",
          }}
        >
          <Button
            variant="contained"
            sx={styles.backBtn}
            disableElevation
            onClick={() => {
              onOndcFilterClose();
            }}
          >
            CANCEL
          </Button>

          <Button
            variant="contained"
            disableElevation
            sx={styles.applyBtn}
            onClick={() => {
              handleOndcFilter();
            }}
          >
            SUBMIT
          </Button>
        </Stack>
      </Box>
    </ModalCard>
  );
};

export default MobileCategoryFilter;

const ErrorText = ({ errorMsg }) => {
  if (!errorMsg) return null;

  return (
    <Typography
      variant="body1"
      fontSize={11.5}
      fontWeight={500}
      color={redColor}
      mt={0.4}
    >
      <ErrorIcon sx={{ fontSize: 13, mr: 0.3, mt: -0.3 }} /> {errorMsg}
    </Typography>
  );
};

const styles = {
  backBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: whiteColor,
    border: `1px solid ${blackShade19}`,
    color: blackShade19,
    p: "5px 20px",
    "&:hover": {
      color: blackShade19,
      backgroundColor: whiteColor,
    },
  },

  applyBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: mobilePrimary,
    border: `1px solid ${mobilePrimary}`,
    color: whiteColor,
    p: "5px 20px",
    "&:hover": {
      color: mobilePrimary,
      backgroundColor: whiteColor,
    },
  },
};
