import { Box } from "@mui/material";
import React, { useState } from "react";
import ErrorToolTipIcon from "../ErrorToolTipIcon";
import { blackShade05, borderTextbox } from "../../../../configs/styles/muiThemes";
import { textOneLines } from "../../../../utils/styles";
import ModalCard from "../../../../components/ModalCard";
import MultiSelectWithCustomInputCard from "../../../../components/Cards/MultiSelectWithCustomInputCard";

const OndcCustomMultiSelect = ({
  label,
  value,
  disabled,
  required,
  hintText,
  onValueChange,
  menuList,
  minWidth,
  errorMsg,
}) => {

  const [customDataModal, setCustomDataModal] = useState(false);

  return (
    <Box position="relative" sx={{ minWidth: minWidth || "120px" }}>
      {errorMsg && (
        <Box sx={{ position: "absolute", right: -14, top: -12, zIndex: 1 }}>
          <ErrorToolTipIcon errorMsg={errorMsg} />
        </Box>
      )}

      <Box
        sx={styles.inputTextBox}
        title={value}
        onClick={() => setCustomDataModal(true)}
      >
        {value?.replace(/_/g, " ")?.replace(/,/g, ", ")}
      </Box>

      <ModalCard
        open={customDataModal}
        handleClose={() => {
          setCustomDataModal(false);
        //   setCustomData("");
        }}
        width="33%"
        disableOutsideClick
      >
        <MultiSelectWithCustomInputCard
          title={label}
          menuList={menuList}
          onValueChange={onValueChange}
          value={value}
          handleClose={() => {
            setCustomDataModal(false);
          }}
        />
      </ModalCard>
    </Box>
  );
};

export default OndcCustomMultiSelect;

const styles = {
  inputTextBox: {
    ...textOneLines,
    borderBottom: borderTextbox,
    height: { xs: "38px", md: "30px" },
    pl: "2px",
    pt: "5px",
    textTransform: "capitalize",
    fontSize: 12,
    fontWeight: 500,
    color: blackShade05,
    width: "100%",
  },
};
