import { Box } from "@mui/material";
import React from "react";
import ProductDetailsPreview from "../../ProductPreview/ProductDetailsPreview";
import CategoryDetailPreview from "../../ProductPreview/CategoryDetailPreview";
import PriceDetailsPreview from "../../ProductPreview/PriceDetailsPreview";
import ProductMeasurementPreview from "../../ProductPreview/ProductMeasurementPreview";
import ProductAvailabilityPreview from "../../ProductPreview/ProductAvailabilityPreview";
import SubscriptionInfoPreview from "../../ProductPreview/SubscriptionInfoPreview";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import moment from "moment";
import ONDCDetailsPreview from "../../ProductPreview/ONDCDetailsPreview";

function DataPreview({
  productData,
  productPhotos,
  onGoBack,
  onSubmit,
  isCopyProduct,
}) {
  return (
    <Box mt={{ xs: 3, md: 0 }}>
      <ProductDetailsPreview
        productName={productData?.productName}
        brand={productData?.brand}
        country={productData?.origin}
        skuId={productData?.skuId || "NA"}
        description={productData?.productDescription}
        businessCategory={productData?.productMainCategory?.[0]?.value}
        productCategory={productData?.productSubCategory?.[0]?.value}
        productSubCategory={productData?.productSubCategory2?.[0]?.value}
        // description="A beautiful necklace with Vilandi Kundan pendant and 3 lines of ruby red agate beads. On the other side of the pendant, it has meenakari work. Available with Kundan earrings. A beautiful necklace with Vilandi Kundan pendant and 3 lines of ruby red agate beads. On the other side of the pendant, it has meenakari work. Available with Kundan earrings."
        images={productPhotos}
        imageSelector="dataURL"
      />

      <PriceDetailsPreview
        currency={productData?.currencySymbol}
        originalPrice={`₹ ${productData?.productOriginalPrice}`}
        offerPrice={`₹ ${productData?.productSalePrice}`}
        taxesIncluded={
          productData?.taxDetails?.[0]?.value == "true" ? "YES" : "NO"
        }
        gstHsnCode={productData?.gstInfo?.code}
        gstPercentage={productData?.gstInfo?.gstPercentage}
        ondcData={productData?.ondcInfo}
      />

      <ProductMeasurementPreview
        weight={`${productData?.ondcInfo?.netProductWeight}`}
        length={`${productData?.measurementInfo?.length}`}
        width={`${productData?.measurementInfo?.width}`}
        height={`${productData?.measurementInfo?.height}`}
        ondcData={productData?.ondcInfo}
        netProductWeight={productData?.measurementInfo?.weight}
        // deliveryMethod="Delivery Fulfillment"
        // deliveryMethod={productData?.deliveryType?.join(", ") || ""}
      />

      {isCopyProduct && (
        <ProductAvailabilityPreview
          isProductAvailable={productData?.availability?.[0] || "No"}
          qty={productData?.availableQuantity}
          alertQty={productData?.alertQuantity}
          minQtyPerOrder={productData?.minOrderQuantity}
          maxQtyPerOrder={productData?.maxOrderQuantity}
          startDate={
            moment(productData?.commerceStartDate)?.format("DD-MM-YYYY") ||
            "DD-MM-YYYY"
          }
          endDate={
            moment(productData?.commerceEndDate)?.format("DD-MM-YYYY") ||
            "DD-MM-YYYY"
          }
        />
      )}

      <CategoryDetailPreview
        businessCategory={productData?.productMainCategory?.[0]?.value}
        productCategory={productData?.productSubCategory?.[0]?.value}
        productSubCategory={productData?.productSubCategory2?.[0]?.value}
        productSpecLabel={productData?.label || []}
        // productSpecValue="Rs 700"
        // refLinkLabel="Affordable Designer Saree"
        // refLinkValue="https://youtu.be/5GVvqnoZliw"

        refLinks={productData?.refLink || []}
        refLinkLabel=""
        refLinkValue=""
      />

      <ONDCDetailsPreview data={productData?.ondcInfo} />

      {/* <SubscriptionInfoPreview
        isSubscription={productData?.subscription?.subscription}
        subscriptionType={productData?.subscription?.type?.join(", ") || ""}
        originalValue={productData?.subscription?.originalValue}
        offerValue={productData?.subscription?.offerValue}
        cancelTime={productData?.subscription?.cancelTime}
        cancelDate={productData?.subscription?.cancelDays}
        serviceFee={productData?.subscription?.serviceFee}
        packingFee={productData?.subscription?.packingFee}
        specialFee={productData?.subscription?.specialFee}
        startDate={
          moment(productData?.subscription?.startDate)?.format("DD-MM-YYYY") ||
          "DD-MM-YYYY"
        }
        endDate={
          moment(productData?.subscription?.endDate)?.format("DD-MM-YYYY") ||
          "DD-MM-YYYY"
        }
      /> */}

      <Box py="20px">
        <ActionButtons
          backBtnText="Previous"
          saveBtnText="SUBMIT"
          onBackClick={onGoBack}
          onSaveClick={onSubmit}
          showBackBtn
          hideNextBtn
        />
      </Box>
    </Box>
  );
}

export default DataPreview;
