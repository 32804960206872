import {
  Box,
  Button,
  Chip,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BGColor02,
  blackColor,
  blackShade05,
  blackShade159,
  blackShade21,
  blueColor04,
  greenStatusColor,
  greyColor24,
  greyColor8,
  inactiveTextColor,
  mobilePrimary,
  orangeStatusColor,
  searchInputIconColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import AddIcon from "@mui/icons-material/Add";
import ModalCard from "../../components/ModalCard";
import PreferredDiscountLegend from "./PreferredDiscountLegend";
import AddDiscountModalContent from "./AddDiscountModalContent";
import NumberSearchInput from "../../components/Input/NumberSearchInput";
import SearchIcon from "@mui/icons-material/Search";
import PrefferedDiscountInfoCard from "./PrefferedDiscountInfoCard";
import {
  preferredDiscountAddRewardDataApi,
  preferredDiscountDeleteDataApi,
  preferredDiscountGetEmailOrPhoneNumberApi,
  prepareAddRewardPostData,
  searchPreferredDiscountByNumberApi,
} from "../../service/api_calls/preferredDiscounts/PreferredDiscountsApi";
import {
  getDashboardPreferredDiscountCountApi,
  getDashboardPreferredDiscountSummaryApi,
} from "../../service/api_calls/BusinessDashboard/BusinessDashboardApi";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import AllPreferredDiscountContent from "./TabContent/AllPreferredDiscountContent";
import ClaimedPreferredDiscountContent from "./TabContent/ClaimedPreferredDiscountContent";
import ExpiredPreferredDiscountContent from "./TabContent/ExpiredPreferredDiscountContent";
import ActivePreferredDiscountContent from "./TabContent/ActivePreferredDiscountContent";
import {
  clearPreferredDiscountsInfo,
  getAllBusinessPreferredDiscountsData,
} from "../../reducers/preferredDiscount/PreferredDiscountSlice";
import { addThousandsSeparator } from "../../utils/helper";
import { stickyHeader } from "../../utils/styles";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import PreferredEmptyCard from "./Card/PreferredEmptyCard";

function PreferredDiscountContent({ getPreferredDiscount, businessName }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const dispatch = useDispatch();

  const [openAddModal, setOpenAddModal] = useState(false);
  const [number, setNumber] = useState("");
  const [isNumberError, setIsNumberError] = useState(false);

  const [discountList, setDiscountList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("all");

  const [isClearSearch, setIsClearSearch] = useState(false);

  const [statusInfo, setStatusInfo] = useState(null);

  const [isShowDeleteAlter, setIsShowDeleteAlter] = useState({
    message: "Do you want to delete this discount permanently?",
    show: false,
  });
  const [serchStatus, setSerchStatus] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { allPreferredDiscounts, allPreferredDiscountsLoader } = useSelector(
    (state) => state.businessPreferredDiscounts
  );

  const PREFERRED_DISCOUNT_STATUS = [
    {
      status: "All",
      color: blackShade159,
      value: "all",
    },
    {
      status: "Active",
      color: greenStatusColor,
      value: "active",
    },
    {
      status: "Expired",
      color: orangeStatusColor,
      value: "expired",
    },
    {
      status: "Claimed",
      color: inactiveTextColor,
      value: "claimed",
    },
  ];

  const [discount, setDiscount] = useState([]);

  // Add Coupon Data
  const [newCoupon, setNewCoupon] = useState({
    type: "Amount",
    originalPhoneNo: "", //original number input
    minimumAmount: "",
    pointPercentage: "",
    pointValue: "",
    startDate: "",
    endDate: "",
    errors: null,
  });

  const [addRewardLoader, setAddRewardLoader] = useState(false);

  const setCouponValue = (name, value) => {
    if (name === "type") {
      setNewCoupon({
        ...newCoupon,
        [name]: value,
        pointPercentage: "",
        pointValue: "",
      });
      return;
    }

    setNewCoupon({
      ...newCoupon,
      [name]: value,
    });
  };

  const handleAddCoupon = () => {
    setCouponValue("startDate", moment().format("YYYY-MM-DD"));
    setOpenAddModal(true);
  };

  const handleCloseModal = () => {
    setSelectedStatus((prev) => "all");
    getPreferredDiscountInfo();
    clearAddRewardData();
    setOpenAddModal(false);
  };

  const onStatusChange = (value) => {
    // if (value === "all") {
    //   setDiscountList(discount);
    //   setSelectedStatus(value);
    //   return;
    // }

    // const filteredDiscounts = discount?.filter(
    //   (item) => item?.rewardStatus === value
    // );

    // // console.log("filteredDiscounts:", filteredDiscounts);
    // setDiscountList(filteredDiscounts);
    setSelectedStatus(value);
    clearSearch();
  };

  const onSearch = async () => {
    if (number?.length < 5) {
      setIsNumberError(true);
      return;
    }

    setIsNumberError(false);

    const businessId = sessionStorage.getItem("selectedBusinessId");
    const result = await searchPreferredDiscountByNumberApi({
      businessId,
      phone: number,
      status: selectedStatus === "all" ? `active,claimed` : `claimed`,
    });

    console.log("RESULTS-1", result);

    setIsClearSearch(true);
    setDiscountList(result || []);
    setSerchStatus(true);
  };

  const clearSearch = () => {
    setIsClearSearch(false);
    // setDiscountList();

    setNumber("");
    setSerchStatus(false);
    // setSelectedStatus("all")
  };

  const getPreferredDiscountInfo = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const summary = await getDashboardPreferredDiscountSummaryApi(businessId);
    const orderCount = await getDashboardPreferredDiscountCountApi(businessId);

    const data = {
      summary: summary?.details,
      orderCount: orderCount?.details,
    };

    setStatusInfo(data);
    setIsLoading(false);
    console.log("getPreferredDiscountInfo:", data);
  };

  // Delete Coupon
  const onDeleteCoupon = async () => {
    const couponData = isShowDeleteAlter?.data;

    setIsLoading(true);
    const response = await preferredDiscountDeleteDataApi({
      rewardId: couponData?.id,
      businessId: sessionStorage.getItem("selectedBusinessId"),
      rewardType: couponData?.rewardType,
    });
    setTimeout(() => {
      setIsLoading(false);
      getPreferredDiscountInfo();
      setIsShowDeleteAlter({ ...isShowDeleteAlter, show: false, data: null });
      setSelectedStatus("all");
    }, 2000);
  };

  // Open Delete Alter
  const openDeleteAlter = (data) => {
    setIsShowDeleteAlter({ ...isShowDeleteAlter, show: true, data });
  };

  // Clear Add Reward Data
  const clearAddRewardData = () => {
    setNewCoupon({
      type: "Amount",
      originalPhoneNo: "", //original number input
      minimumAmount: "",
      pointPercentage: "",
      pointValue: "",
      startDate: "",
      endDate: "",
      errors: null,
    });
  };

  // Handling Add new coupon Submit
  const handleAddNewCoupon = () => {
    let errors = null;
    let isError = false;

    if (!newCoupon?.type) {
      errors = { ...errors, type: "Selet discount type" };
      isError = true;
    }

    if (newCoupon?.originalPhoneNo?.length < 10) {
      errors = {
        ...errors,
        originalPhoneNo: "Phone number should be 10 digits",
      };
      isError = true;
    }

    if (!newCoupon?.originalPhoneNo) {
      errors = { ...errors, originalPhoneNo: "Enter Phone Number" };
      isError = true;
    }

    if (newCoupon?.type === "Amount") {
      if (!newCoupon?.pointValue) {
        errors = { ...errors, pointValue: "Enter Discount Amount" };
        isError = true;
      }
    }

    if (newCoupon?.type === "Percentage") {
      if (!newCoupon?.pointPercentage) {
        errors = { ...errors, pointPercentage: "Enter Discount Percentage" };
        isError = true;
      }
    }

    if (!newCoupon?.minimumAmount) {
      errors = { ...errors, minimumAmount: "Enter Minimum Order Amount" };
      isError = true;
    }

    if (!newCoupon?.startDate) {
      errors = { ...errors, startDate: "Select Start date" };
      isError = true;
    }
    if (!newCoupon?.endDate) {
      errors = { ...errors, endDate: "Select End date" };
      isError = true;
    }

    if (newCoupon?.type === "Amount") {
      if (newCoupon?.pointValue && newCoupon?.minimumAmount) {
        if (Number(newCoupon?.pointValue) >= Number(newCoupon?.minimumAmount)) {
          errors = {
            ...errors,
            pointValue:
              "Discount value should be less than Mimimum Purchase Amount",
          };
          isError = true;
        }
      }
    }

    if (newCoupon?.startDate && newCoupon?.endDate) {
      const fromDate = moment(newCoupon?.startDate);
      const toDate = moment(newCoupon?.endDate);

      const currentDate = moment().subtract(1, "day");

      const isFromBeforeCurrentDate = fromDate?.isBefore(currentDate);
      const isFromAfterToDate = fromDate?.isAfter(toDate);

      if (isFromAfterToDate) {
        errors = {
          ...errors,
          startDate: "Start Date should be before End Date",
        };
        isError = true;
      }

      if (isFromBeforeCurrentDate) {
        errors = { ...errors, startDate: "Start Date cannot be in past" };
        isError = true;
      }

      if (!isFromBeforeCurrentDate && !isFromAfterToDate) {
        const diffCount = moment.duration(toDate.diff(fromDate)).asDays();

        if (diffCount > 180) {
          errors = {
            ...errors,
            startEndDiff: "Period between start and end date is 180 days",
          };
          isError = true;
        }
      }
    }

    if (isError) {
      setCouponValue("errors", errors);
      // console.log("testing", newCoupon);
      return;
    } else {
      setCouponValue("errors", null);
      // Add Coupon API CALL
      let windowReference = window.open();
      addNewPreferredDiscount(windowReference);
    }
  };

  const addNewPreferredDiscount = async (windowReference) => {
    let originalProfileId = "";

    setAddRewardLoader(true);

    setSelectedStatus((prev) => "none");

    // Fetching User Details to get Original Profile ID
    const userInfo = await preferredDiscountGetEmailOrPhoneNumberApi({
      phoneNumber: newCoupon?.originalPhoneNo,
    });

    console.log("USER_INFO:", userInfo);

    if (userInfo?.accountSummary) {
      originalProfileId = userInfo?.accountSummary?.profileId;
      console.log("originalProfileId:", originalProfileId);
    }

    // ADD Preferred Discount

    const businessId = sessionStorage.getItem("selectedBusinessId");
    const postData = prepareAddRewardPostData({
      newCoupon,
      originalProfileId,
      businessId,
    });

    const res = await preferredDiscountAddRewardDataApi({
      data: postData,
      businessId,
    });

    setTimeout(() => {
      clearAddRewardData();
      setAddRewardLoader(false);
      handleCloseModal();

      const webURL = sessionStorage.getItem("costboWEBURL");
      const message = `Special discount just for you from ${businessName}  \n\n GET ${
        newCoupon?.type === "Percentage"
          ? `${newCoupon?.pointPercentage}% OFF on all products`
          : ""
      }${
        newCoupon?.type === "Amount" ? `₹ ${newCoupon?.pointValue} OFF ` : ""
      } on minimum purchase of ₹ ${addThousandsSeparator(
        newCoupon?.minimumAmount
      )} \n Use below link for instant discount! \n https://shop.costbo.com/${webURL}`;
      const msg = encodeURIComponent(message);
      windowReference.location.assign(
        `https://api.whatsapp.com/send/?phone=+91${newCoupon?.originalPhoneNo}&text=${msg}`,
        "_blank"
      );
      console.log("hitt_message", msg);

      getPreferredDiscountInfo();
    }, 200);
  };

  useEffect(() => {
    setDiscountList(discount);

    if (!statusInfo) {
      setIsLoading(true);
      getPreferredDiscountInfo();
    }

    return () => {};
  }, [discount]);

  return (
    <Box>
      <Box sx={{ ...stickyHeader, pb: 1 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h4"
            color={blackShade05}
            fontSize={{ md: "17px", xs: "15px" }}
            fontWeight="600"
          >
            Personalized Discounts
          </Typography>

          {statusInfo?.summary?.totalPreferredDiscounts === 0 && (
            <Button
              sx={styles.addIconBtn}
              variant="contained"
              disableElevation
              onClick={handleAddCoupon}
              startIcon={<AddIcon />}
            >
              {matches ? "New Discount" : "New"}
            </Button>
          )}
        </Box>
      </Box>
      {!isLoading ? (
        <>
          {statusInfo?.summary?.totalPreferredDiscounts > 0 ? (
            <>
              <Box
                sx={{
                  position: "sticky",
                  top: { md: 90, xs: 137 },
                  backgroundColor: BGColor02,
                  zIndex: 100,
                  pb: 2,
                }}
              >
                <Box
                  display="flex"
                  alignItems={{ xs: "start", md: "center" }}
                  gap={2}
                  // flexDirection={{ xs: "column", md: "row" }}
                  justifyContent={{ xs: "space-between", md: "space-between" }}
                >
                  <Box flex={1}>
                    <Typography
                      sx={{
                        color: blackShade21,
                        fontSize: "13px",
                        fontWeight: 500,
                        display: { md: "block", xs: "block" },
                      }}
                    >
                      Boost sales through, Personalized discounts for your loyal
                      customers.
                    </Typography>
                    <Typography
                      sx={{
                        display: { md: "block", xs: "none" },
                        color: blackShade21,
                        fontSize: "13px",
                        fontWeight: 400,
                      }}
                    >
                      Assign discounts by customer phone number. You can assign
                      mulitiple discounts<br></br> to same customer. Customer
                      can use only one discount per order.
                    </Typography>
                  </Box>

                  <Box
                    alignItems="center"
                    flexDirection={{ xs: "column", md: "row" }}
                    justifyContent={{ xs: "center", md: "space-between" }}
                    gap={{ xs: "0px", md: "20px" }}
                  >
                    {matches ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection={{ xs: "column", md: "row" }}
                        justifyContent={{ xs: "center", md: "space-between" }}
                        gap="20px"
                      >
                        <Button
                          sx={styles.addIconBtn}
                          variant="contained"
                          disableElevation
                          onClick={handleAddCoupon}
                          startIcon={<AddIcon />}
                        >
                          {matches ? "New Discount" : "New"}
                        </Button>
                      </Box>
                    ) : (
                      <IconButton
                        sx={styles.addIconBtnSmall}
                        onClick={handleAddCoupon}
                      >
                        <AddIcon sx={{ fontSize: "18px" }} />
                      </IconButton>
                    )}
                  </Box>
                </Box>

                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={{ md: "row", xs: "column" }}
                  mt={{ md: 1, xs: 0 }}
                  gap={{ md: 0, xs: 1 }}
                >
                  <Box flex={1}>
                    {!!statusInfo?.summary?.totalPreferredDiscounts ? (
                      <Box display="flex" alignItems="center" mt={1}>
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          gap={{ xs: "30px", md: "20px" }}
                        >
                          {PREFERRED_DISCOUNT_STATUS.map((item, index) => {
                            return (
                              <PreferredDiscountLegend
                                index={index}
                                key={`status+${index}`}
                                dotColor={item.color}
                                label={item?.status}
                                isActive={item?.value === selectedStatus}
                                onSelect={() => onStatusChange(item?.value)}
                              />
                            );
                          })}
                        </Box>
                        {/* {matches ? null : (
                          <Button
                            sx={styles.addIconBtn}
                            variant="contained"
                            disableElevation
                            onClick={handleAddCoupon}
                            startIcon={<AddIcon />}
                          >
                            {matches ? "New Discount" : "New"}
                          </Button>
                        )} */}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Box
                    flex={1}
                    // display={"flex"}
                    display={
                      selectedStatus === "all" || selectedStatus === "claimed"
                        ? "flex"
                        : "none"
                    }
                    justifyContent={"flex-end"}
                    alignItems={"flex-end"}
                  >
                    {!!statusInfo?.summary?.totalPreferredDiscounts ? (
                      <Box width={{ md: "60%", xs: "100%" }}>
                        <NumberSearchInput
                          placeholder="Customer Phone Number (Min 5 digits)"
                          value={number}
                          onChange={({ target }) => {
                            const value = target?.value?.replace(/[^0-9]/g, "");
                            setNumber(value);
                            if (target?.value?.length === 0) {
                              clearSearch();
                            }
                          }}
                          icon={
                            <IconButton onClick={onSearch}>
                              <SearchIcon
                                sx={{
                                  fontSize: "20px",
                                  color: searchInputIconColor,
                                }}
                              />
                            </IconButton>
                          }
                          maxLength={10}
                          error={
                            isNumberError
                              ? "Phone number should be minimum 5 digits"
                              : ""
                          }
                        />
                        {isClearSearch && number ? (
                          <Box
                            display="flex"
                            alignItems="center"
                            gap="10px"
                            mt="5px"
                          >
                            <Typography
                              component="h4"
                              fontSize={14}
                              color={blackShade05}
                              fontWeight={400}
                              lineHeight="20px"
                              m="0px !important"
                            >
                              Search results for
                              {/* “
                              <Box
                                component="span"
                                sx={{
                                  fontSize: 14,
                                  color: blackShade05,
                                  fontWeight: 600,
                                }}
                              >
                                {number}
                              </Box>
                              ” */}
                            </Typography>

                            <Chip
                              label={number}
                              sx={{ fontSize: "12px", height: "28px" }}
                              onDelete={clearSearch}
                            />
                          </Box>
                        ) : null}
                      </Box>
                    ) : null}
                  </Box>
                </Box>

                {matches && (
                  <PrefferedDiscountInfoCard
                    summary={statusInfo?.summary}
                    orderCount={statusInfo?.orderCount}
                  />
                )}
              </Box>
              {!matches && (
                <PrefferedDiscountInfoCard
                  summary={statusInfo?.summary}
                  orderCount={statusInfo?.orderCount}
                />
              )}

              <Box mt={{xs:1, md:0}}>
                {selectedStatus === "all" && (
                  <AllPreferredDiscountContent
                    searchData={discountList}
                    searchStatus={serchStatus}
                    openDeleteAlter={openDeleteAlter}
                    isLoading={isLoading}
                  />
                )}
                {selectedStatus === "active" && (
                  <ActivePreferredDiscountContent
                    searchData={discountList}
                    searchStatus={serchStatus}
                    openDeleteAlter={openDeleteAlter}
                  />
                )}

                {selectedStatus === "expired" && (
                  <ExpiredPreferredDiscountContent
                    searchData={discountList}
                    searchStatus={serchStatus}
                  />
                )}

                {selectedStatus === "claimed" && (
                  <ClaimedPreferredDiscountContent
                    searchData={discountList}
                    searchStatus={serchStatus}
                  />
                )}
              </Box>
            </>
          ) : (
            <PreferredEmptyCard />
          )}
        </>
      ) : (
        <ThreeDotLoader />
      )}
      {statusInfo?.summary?.totalPreferredDiscounts > 0}
      <ModalCard open={openAddModal} handleClose={handleCloseModal} width="28%">
        <AddDiscountModalContent
          data={newCoupon}
          setCouponValue={setCouponValue}
          onCancel={handleCloseModal}
          onSubmit={handleAddNewCoupon}
          loading={addRewardLoader}
        />
      </ModalCard>
      <ModalCard
        open={isShowDeleteAlter.show}
        handleClose={() => {
          setIsShowDeleteAlter({
            ...isShowDeleteAlter,
            show: false,
            data: null,
          });
        }}
        width="27%"
      >
        <Box
          sx={{ backgroundColor: whiteColor, px: 3, py: 2.5, borderRadius: 2 }}
        >
          <Typography fontSize={16} fontWeight={600} color={blackColor}>
            Alert
          </Typography>
          <Typography fontSize={14} mt={2.5}>
            {isShowDeleteAlter?.message}
          </Typography>
          <Box display="flex" justifyContent={"flex-end"} mt={1.5} gap={2}>
            <Button sx={styles.addIconBtn} onClick={onDeleteCoupon}>
              Yes
            </Button>
            <Button
              sx={styles.fillBtn}
              onClick={() => {
                setIsShowDeleteAlter({
                  ...isShowDeleteAlter,
                  show: false,
                  data: null,
                });
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </ModalCard>
    </Box>
  );
}

export default PreferredDiscountContent;

const styles = {
  addIconBtn: {
    backgroundColor: "transparent",
    padding: { xs: "2px 10px", md: "3px 22px" },
    alignItems: { md: "center", xs: "center" },
    fontSize: 13,
    fontWeight: 600,
    color: { xs: mobilePrimary, md: blueColor04 },
    backgroundColor: whiteColor,
    border: {
      xs: "1px solid " + mobilePrimary,
      md: "1px solid " + blueColor04,
    },
    "&:hover": {
      color: whiteColor,
      backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    },
  },

  addIconBtnSmall: {
    width: {xs:'36px', md:"38px"},
    height:{xs:'30px', md:"34px"},
    backgroundColor: "transparent",
    fontWeight: 600,
    color: { xs: mobilePrimary, md: blueColor04 },
    backgroundColor: whiteColor,
    borderRadius: "5px",
    border: {
      xs: "1px solid " + mobilePrimary,
      md: "1px solid " + blueColor04,
    },
    mt:0.7,
    "&:hover": {
      color: whiteColor,
      backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    },
  },

  fillBtn: {
    backgroundColor: greyColor8,
    padding: "6px 22px",
    fontSize: "13px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + greyColor8,
    "&:hover": {
      color: greyColor8,
      backgroundColor: whiteColor,
    },
  },
};
