import { Box, Typography } from "@mui/material";
import React from "react";
import {
  whiteColor,
  logoImgborder1,
  greyColor6,
  blackShade05,
} from "../../configs/styles/muiThemes";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CancelPresentationOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
function PrefferedDiscountInfoCard({ summary, orderCount }) {
  const totalDiscount = Number(summary?.totalPreferredDiscounts) || 0;
  const totalActiveDiscounts =
    Number(summary?.totalActivePreferredDiscounts) || 0;
  const totalUtilizedDiscoutns =
    Number(summary?.totalUtilizedPreferredDiscoutns) || 0;
  const totalOrderNumbers = Number(orderCount?.totalPreferredOrderNumbers) || 0;
  const totalOrderValue =
    Number(orderCount?.totalPreferredOrderValue)
      ?.toFixed(2)
      .replaceAll(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",") || 0;
  return (
    <Box sx={styles.infoBox}>
      {/* {JSON.stringify(orderCount)} */}
      <Box flex="1" display="flex" marginBottom={{ xs: "10px", md: "0px" }}>
        <Box flex="1">
          <StatsInfoCard
            icon={
              <DiscountOutlinedIcon
                sx={{ fontSize: { xs: "0px", md: "24px" } }}
              />
            }
            label="TOTAL DISCOUNT"
            value={totalDiscount}
          />
        </Box>
        <Box flex="1">
          <StatsInfoCard
            icon={
              <LocalOfferOutlinedIcon
                sx={{ fontSize: { xs: "0px", md: "24px" } }}
              />
            }
            label="ACTIVE DISCOUNT"
            value={totalActiveDiscounts}
          />
        </Box>
      </Box>

      <Box flex="1" display="flex" marginBottom={{ xs: "10px", md: "0px" }}>
        <Box flex="1">
          <StatsInfoCard
            icon={
              <AddShoppingCartIcon
                sx={{ fontSize: { xs: "0px", md: "24px" } }}
              />
            }
            label="TOTAL ORDERS PLACED"
            value={totalOrderNumbers}
          />
        </Box>

        <Box flex="1">
          <StatsInfoCard
            icon={
              <ShoppingCartCheckoutIcon
                sx={{ fontSize: { xs: "0px", md: "24px" } }}
              />
            }
            label="TOTAL ORDER VALUE"
            value={`₹ ${isNaN(totalOrderValue) ? totalOrderValue : 0}`}
          />
        </Box>
      </Box>

      <Box display="flex" >
        <Box flex="1" sx={{ display: { xs: "none", md: "block" } }}>
          <StatsInfoCard
            icon={
              <CancelPresentationOutlinedIcon
                sx={{ fontSize: { xs: "0px", md: "24px" } }}
              />
            }
            label="CLAIMED DISCOUNT"
            value={totalUtilizedDiscoutns}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default PrefferedDiscountInfoCard;

const StatsInfoCard = ({ icon, label, value }) => {
  return (
    <Box display="flex" gap={{ xs: "0px", md: "20px" }}>
      <Box sx={styles.iconCircle}>{icon}</Box>

      <Box>
        <Typography fontSize={12} fontWeight={500} color={greyColor6}>
          {label}
        </Typography>

        <Typography fontSize={{xs:15, md:16}} fontWeight={600} color={blackShade05}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  infoBox: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: { xs: "space-between", md: "space-between" },
    backgroundColor: whiteColor,
    baxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    padding: "10px",
    border: logoImgborder1,
    borderRadius: "10px",
    marginTop: {xs: "2px", md:"15px"},
  },

  statCard: {},

  iconCircle: {
    width: { xs: "0px", md: "45px" },
    height: { xs: "0px", md: "45px" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(5 60 81 / 7%)",
    borderRadius: "50%",
  },
};
