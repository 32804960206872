import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardMenu from "./Dashboard/DashboardMenu";
import DashboardHeader from "./Dashboard/DashboardHeader";
import { BGColor02, eCommerceblackcolor1 } from "../configs/styles/muiThemes";
import BusinessInfoCard from "../components/Cards/BusinessInfoCard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import CRMDashboardContent from "./CRMOrMarketing/CRMDashboardContent";
import CRMMarketingContent from "./CRMOrMarketing/CRMMarketingContent";
import { getAllBusinessCustomersData } from "../reducers/crmcustomerdata/customerSlice";
import { getDashboardOrderSummaryApi } from "../service/api_calls/BusinessDashboard/BusinessDashboardApi";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import { stickyHeader } from "../utils/styles";

function CRMMarketing() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [crmSummaryInfo, setCRMSummaryInfo] = useState()

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  // const { businessCustomers, businessCustomersLoader } = useSelector(
  //   (state) => state.businessCustomers
  // );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getCRMSummaryData = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const res = await getDashboardOrderSummaryApi(businessId);

    if (res?.details) {
      setCRMSummaryInfo(res?.details);
      console.log("DASHBoard",res?.details);
    }
    
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      // if (!businessCustomers) {      
        // dispatch(getAllBusinessCustomersData({ businessId }));
      // }
     getCRMSummaryData();

    }
  }, []);

  return (
    <Box sx={styles.container}>
      <DashboardLayout activeMenu="crm">
        <Box sx={{...stickyHeader,pb:2}}>
          <Typography
            variant="h4"
            color={eCommerceblackcolor1}
            fontSize={{xs:15, md:18}}
            fontWeight="600"
            mb={{xs:2, md:3}}
            mt={{xs:0.5, md:1}}
          >
            CRM Analysis
          </Typography>
            <CRMDashboardContent data={crmSummaryInfo} />
        </Box>
        <CRMMarketingContent />
      </DashboardLayout>
    </Box>
  );
}

export default CRMMarketing;

const styles = {
  container: {
    minHeight: "100vh",
    backgroundColor: BGColor02,
  },
};
