import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  greyShade07,
  greyShade65,
  iconContainerBgColor,
  progressBGColor,
  whiteColor,
} from "../../configs/styles/muiThemes";

function BusinessProductTypeCard({
  businessCategory,
  mainProducts,
  fssaiNumber,
}) {
  const BUSINESS_CATEGORIES = [
    "Agriculture & Farming",
    "Pets & Supplies",
    "Clothing & Accessories",
    "Crafts & Collectibles",
    "Automobiles & Accessories",
    "Beauty & Personal Care, ",
    "Arts, Crafts & Collectibles",
    "Computers & Networking",
  ];
  return (
    <Box sx={styles.card}>
      <Typography
        fontSize={13}
        fontWeight={600}
        color={blackColor}
        p="12px 0px 6px"
      >
        Business Category
      </Typography>

      <Grid container columnSpacing={4} rowSpacing={{ md: 1, xs: 0.5 }} pb="10px">
        {businessCategory?.map((item, index) => {
          return (
            <Grid item xs={12} md={4} key={"business_cat_" + index}>
              <ListData value={item?.name} />
            </Grid>
          );
        })}
      </Grid>

      <Divider
        sx={{
          border: "1px solid " + progressBGColor,
          my: { xs: "00px", md: "0px" },
        }}
      />

      <Typography
        fontSize={13}
        fontWeight={600}
        color={blackColor}
        p="12px 0px 6px"
      >
        Main Products
      </Typography>

      <Grid container columnSpacing={4} rowSpacing={{ md: 1, xs: 0.5 }}>
        {mainProducts?.map((item, index) => {
          return (
            <Grid item xs={12} md={4} key={"business_cat_" + index}>
              <ListData value={item} />
            </Grid>
          );
        })}
      </Grid>
      {fssaiNumber ? (
        <>
          <Divider
            sx={{
              border: "1px solid " + progressBGColor,
              my: { xs: "0px", md: "0px" },
            }}
          />

          <Typography
            fontSize={13}
            fontWeight={600}
            color={blackColor}
            p="12px 0px 6px"
          >
            FSSAI Number
          </Typography>

          <Grid container columnSpacing={4} rowSpacing={1}>
            <Grid item xs={12} md={4}>
              <ListData value={fssaiNumber} />
            </Grid>
          </Grid>
        </>
      ) : null}
    </Box>
  );
}

export default BusinessProductTypeCard;

const ListData = ({ value }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <Box
        sx={{
          width: { md: "6px", xs: "5px" },
          height: { md: "6px", xs: "5px" },
          borderRadius: "50%",
          backgroundColor: greyShade65,
        }}
      />
      <Typography fontSize={12} fontWeight={500} color={greyShade65}>
        {value}
      </Typography>
    </Box>
  );
};

const styles = {
  card: {
    padding: "2px 18px 10px",
    background: whiteColor,
    border: "1px solid #eeeeee",
    borderRadius: "13px",
  },
};
