import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import InputPreview from "../../../components/Input/InputPreview";
import { blackColor, whiteColor } from "../../../configs/styles/muiThemes";

function OtherDetailsPreviewCard({ data }) {
  return (
    <Box sx={styles.section}>
      <Typography
        component="h5"
        fontSize={15}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        m="5px 0 15px !important"
      >
        Returns & Other Info
      </Typography>

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        <Grid item xs={12} md={3}>
          <InputPreview
            label="Minimum Order Amount"
            value={
              data?.minOrderAmount
                ? `₹ ${data?.minOrderAmount}`
                : data?.minOrderAmount
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputPreview label="Return Policy" value={data?.returnPolicy} />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputPreview label="Special Notes" value={data?.notes} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default OtherDetailsPreviewCard;

const styles = {
  section: {
    p: "10px 20px 20px",
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: "10px",
    mt: { xs: "10px", md: "20px" },
  },
};
