import { Box, IconButton, Modal, useMediaQuery } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { whiteColor } from "../configs/styles/muiThemes";

export default function ModalCard(props) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    // <Modal open={props.open} onClose={props.handleClose}>
    //   <Box
    //     sx={{ ...style(true), width: "90%" }}
    //   >
    //     <Box sx={modalContent}>
    //       <IconButton sx={btnClose} onClick={props.handleClose}>
    //         <CloseIcon />
    //       </IconButton>
    //       {props.children}
    //     </Box>
    //   </Box>
    // </Modal>

    <Modal
      open={props.open}
      onClose={props?.disableOutsideClick ? () => {} : props.handleClose}
    >
      <Box
        sx={{ ...style(true), width: matches ? props?.width || 600 : "93%" }}
      >
        <Box sx={modalContent}>
          {!props?.hideClose && (
            <IconButton
              sx={props?.modalType === "auth" ? authCloseBtn : btnClose}
              onClick={props.handleClose}
            >
              <CloseIcon
                sx={{ fontSize: props?.modalType === "auth" ? 22 : 18 }}
              />
            </IconButton>
          )}
          {props.children}
        </Box>
      </Box>
    </Modal>
  );
}

const style = (transparent) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  bgcolor: transparent ? null : "#fff",
  // overflow:"hidden",
  boxShadow: 24,
});

const modalContent = {
  width: "100%",
  height: "100%",
  position: "relative",
};

const btnClose = {
  background: "#fff",
  position: "absolute",
  top: "-10px",
  right: "-6px",
  width: "40px",
  height: "40px",
  zIndex: 5,
  opacity: 1,
  borderRadius: "50px",
  "&:hover": {
    background: "#fff",
  },
};

const authCloseBtn = {
  background: "transparent",
  color: whiteColor,
  position: "absolute",
  top: "8px",
  right: "5px",
  width: "40px",
  height: "40px",
  opacity: 1,
  zIndex: "50 !important",
  borderRadius: "50px",
  "&:hover": {
    background: "rgba(256,256,256,0.08)",
  },
};
