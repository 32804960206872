import moment from "moment";
import { getProfileId } from "../../../utils/validationUtils";

export const prepareUpdateCommerceData = (data) => {
  const profileId = getProfileId();

  const {
    addressInfo,
    deliveryMethods,
    discountInfo,
    otherDetails,
    gstNumber,
    panNumber,
  } = data;

  const {
    costBoDelivery,
    costBoHyperlocal,
    cod,
    fullfillment,
    fullfillmentPricing,
    b2bDelivery,
    storePickup,
    isCOD,
  } = deliveryMethods;

  let postData = {
    commerceEnable: true,
    status: "testing",
    cancelReason: "",
    returnPolicy: otherDetails?.returnPolicy || "",
    pickupExpireTime: "",
    shipmentDistance: "3000",
    returnShippingBy: "",
    referralPointsTransfer: "",
    catalogType: "",
    currencyType: "",
    notes: otherDetails?.notes || "",
    shipmentArea: "India",
    rewardPointPercent: 0.0,
    referralPointsMaxPerTxn: 0.0,
    firstTimeDiscount: 0.0,
    restockingFeePercentage: 0.0,
    restockingTimeFrameDays: 0.0,
    shipmentETA: [],
    payType: ["UPI", "CARD"],
    referralPointsClaimPolicy: [],
    referralAmount: [
      {
        byFor: 0.0,
        discountOn: 0.0,
      },
    ],
    costBoFee: [],
    shipmentCost: [],
    licenceInfo: [],
    returnPolicies: [],
    referralPointsPolicy: [],
    commissionPercentages: [],
    additionalDiscount: discountInfo?.additionalDiscount || [],
    freeShipment: ["0"],
    startDate: null,
    endDate: null,
    referralExpireDate: null,
    rewardExpirationDays: 0,
    updatedOn: moment().valueOf(),
    updatedBy: profileId,
    createdOn: null,
    docValidationData: [],
    deliveryInfoEnable: "false",
    cashBackDetails: [{ walletEnable: false }],
    minOrderEnable: true,
    webOrder: { enable: true },
    orderInfoDetails: [{ minOrderAmount: otherDetails?.minOrderAmount || 0 }],
    deliveryTypeInfo: [],
    fssaiInfo: [{ code: "", value: "" }],
    gstNumber: gstNumber,
    panNumber: panNumber,
    immediateDiscount: discountInfo?.immediateDiscount
      ? Number(discountInfo?.immediateDiscount)
      : 0,
  };

  //  adding delivery types
  const deliveryTypeInfo = [];
  const selectedMethods = deliveryMethods?.deliveryMethods;

  if (selectedMethods?.includes("CostBo Delivery")) {
    deliveryTypeInfo.push({
      ...costBoDelivery,
    });

    if (isCOD) {
      const codInfo = [{ ...cod, chargeTo: cod?.chargeTo?.toLowerCase() }];
      postData = { ...postData, codInfo };
    }
  }

  if (selectedMethods?.includes("CostBo Hyperlocal")) {
    deliveryTypeInfo.push({
      ...costBoHyperlocal,
    });
  }

  if (selectedMethods?.includes("Fulfillment by Business")) {
    deliveryTypeInfo.push({
      ...fullfillment,
    });
    const shipmentCost = fullfillmentPricing;
    const shipmentETA = ["3-5 days"];

    postData = { ...postData, shipmentCost, shipmentETA };
  }

  if (selectedMethods?.includes("B2B Delivery")) {
    deliveryTypeInfo.push({
      ...b2bDelivery,
    });
  }

  //Updating deliveryTypeInfo in postData
  postData = { ...postData, deliveryTypeInfo };

  //Updating Store Pickup details of its seleted
  if (selectedMethods?.includes("Store Pickup")) {
    const pickUpInfoDetails = [
      {
        discountBy: storePickup?.discountBy,
        discountAmount: storePickup?.discountAmount
          ? Number(storePickup?.discountAmount)
          : 0,
        discountPercentage: storePickup?.discountPercentage
          ? Number(storePickup?.discountPercentage)
          : 0,
      },
    ];

    postData = { ...postData, pickUpInfoDetails };
  }

  //Updating Discount Details
  if (
    discountInfo?.rewardPointPercent?.label !== "Select Cashback Percentage" &&
    discountInfo?.referralPointsMaxPerTxn !== "" &&
    discountInfo?.rewardExpirationDays?.label !== "Select Cashback Validity"
  ) {
    const rewardPointPercent = discountInfo?.rewardPointPercent?.value
      ? Number(discountInfo?.rewardPointPercent?.value)
      : 0.0;
    const referralPointsMaxPerTxn = discountInfo?.referralPointsMaxPerTxn
      ? Number(discountInfo?.referralPointsMaxPerTxn)
      : 0.0;
    const rewardExpirationDays = discountInfo?.rewardExpirationDays?.value
      ? Number(discountInfo?.rewardExpirationDays?.value)
      : 0.0;

    postData = {
      ...postData,
      rewardPointPercent,
      referralPointsMaxPerTxn,
      rewardExpirationDays,
    };
  }

  console.log("UPDATE_COMMERCE_DATA:", postData);

  return postData;
};

export const prepareEditCommerceData = (data) => {
  const profileId = getProfileId();

  const {
    addressInfo,
    deliveryMethods,
    discountInfo,
    otherDetails,
    gstNumber,
    panNumber,
    commerceInfo,
  } = data;

  const {
    costBoDelivery,
    costBoHyperlocal,
    cod,
    fullfillment,
    fullfillmentPricing,
    b2bDelivery,
    storePickup,
    isCOD,
  } = deliveryMethods;

  let postData = {
    commerceEnable: true,
    status: "testing",
    cancelReason: "",
    returnPolicy: otherDetails?.returnPolicy || "",
    pickupExpireTime: "",
    shipmentDistance: "3000",
    returnShippingBy: "",
    referralPointsTransfer: "",
    catalogType: "",
    currencyType: "",
    notes: otherDetails?.notes || "",
    shipmentArea: "India",
    rewardPointPercent: 0.0,
    referralPointsMaxPerTxn: 0.0,
    firstTimeDiscount: 0.0,
    restockingFeePercentage: 0.0,
    restockingTimeFrameDays: 0.0,
    shipmentETA: [],
    payType: ["UPI", "CARD"],
    referralPointsClaimPolicy: [],
    referralAmount: [
      {
        byFor: 0.0,
        discountOn: 0.0,
      },
    ],
    costBoFee: [],
    shipmentCost: [],
    licenceInfo: [],
    returnPolicies: [],
    referralPointsPolicy: [],
    commissionPercentages: [],
    additionalDiscount: discountInfo?.additionalDiscount || [],
    freeShipment: ["0"],
    startDate: null,
    endDate: null,
    referralExpireDate: null,
    rewardExpirationDays: 0,
    updatedOn: moment().valueOf(),
    updatedBy: profileId,
    createdOn: null,
    docValidationData: [],
    deliveryInfoEnable: "false",
    cashBackDetails: [{ walletEnable: false }],
    minOrderEnable: true,
    webOrder: { enable: true },
    orderInfoDetails: [{ minOrderAmount: otherDetails?.minOrderAmount || 0 }],
    deliveryTypeInfo: [],
    fssaiInfo: [{ code: "", value: "" }],
    gstNumber: gstNumber,
    panNumber: panNumber,
    immediateDiscount: discountInfo?.immediateDiscount
      ? Number(discountInfo?.immediateDiscount)
      : 0,
  };

  //  adding delivery types
  const deliveryTypeInfo = [];
  const selectedMethods = deliveryMethods?.deliveryMethods;

  if (selectedMethods?.includes("CostBo Delivery")) {
    deliveryTypeInfo.push({
      ...costBoDelivery,
    });

    if (isCOD) {
      const codInfo = [{ ...cod, chargeTo: cod?.chargeTo?.toLowerCase() }];
      postData = { ...postData, codInfo };
    }
  }

  if (selectedMethods?.includes("CostBo Hyperlocal")) {
    deliveryTypeInfo.push({
      ...costBoHyperlocal,
      deliveryTime: ["rush", "express", "standard"],
    });
  }

  if (selectedMethods?.includes("Fulfillment by Business")) {
    deliveryTypeInfo.push({
      ...fullfillment,
    });
    const shipmentCost = fullfillmentPricing;
    const shipmentETA = ["3-5 days"];

    postData = { ...postData, shipmentCost, shipmentETA };
  }

  if (selectedMethods?.includes("B2B Delivery")) {
    deliveryTypeInfo.push({
      ...b2bDelivery,
    });
  }

  //Updating deliveryTypeInfo in postData
  postData = { ...postData, deliveryTypeInfo };

  //Updating Store Pickup details of its seleted
  if (selectedMethods?.includes("Store Pickup")) {
    const pickUpInfoDetails = [
      {
        discountBy: storePickup?.discountBy,
        discountAmount: storePickup?.discountAmount
          ? Number(storePickup?.discountAmount)
          : 0,
        discountPercentage: storePickup?.discountPercentage
          ? Number(storePickup?.discountPercentage)
          : 0,
      },
    ];

    postData = { ...postData, pickUpInfoDetails };
  }

  //Updating Discount Details
  if (
    discountInfo?.rewardPointPercent?.label !== "Select Cashback Percentage" &&
    discountInfo?.referralPointsMaxPerTxn !== "" &&
    discountInfo?.rewardExpirationDays?.label !== "Select Cashback Validity"
  ) {
    const rewardPointPercent = discountInfo?.rewardPointPercent?.value
      ? Number(discountInfo?.rewardPointPercent?.value)
      : 0.0;
    const referralPointsMaxPerTxn = discountInfo?.referralPointsMaxPerTxn
      ? Number(discountInfo?.referralPointsMaxPerTxn)
      : 0.0;
    const rewardExpirationDays = discountInfo?.rewardExpirationDays?.value
      ? Number(discountInfo?.rewardExpirationDays?.value)
      : 0.0;

    postData = {
      ...postData,
      rewardPointPercent,
      referralPointsMaxPerTxn,
      rewardExpirationDays,
    };
  }

  // Adding ONDC details

  const ondcPostData = {
    cmsOndcEnabled: commerceInfo?.cmsOndcEnabled,
    ondcEnabled: commerceInfo?.ondcEnabled,
    ondc: commerceInfo?.ondc,
  };

  postData = {
    ...postData,
    ...ondcPostData,

    deliveryInfo: commerceInfo?.deliveryInfo || null,
    executionDay: commerceInfo?.executionDay || null,

    fintech: commerceInfo?.fintech || null,
    costboPowered: commerceInfo?.costboPowered || null,
    weFast: commerceInfo?.weFast || null,

    hyperLocal: commerceInfo?.hyperLocal || null,
    hyperLocalInfo: commerceInfo?.hyperLocalInfo || null,
    hyperlocalStore: commerceInfo?.hyperlocalStore || null,

    deliveryLeadEndTime: commerceInfo?.deliveryLeadEndTime || null,
    deliveryLeadStartTime: commerceInfo?.deliveryLeadStartTime || null,

    otherLocation: commerceInfo?.otherLocation || null,
    preferredLocation: commerceInfo?.preferredLocation || null,

    preferredPayments: commerceInfo?.preferredPayments || null,

    returnPolicyDays: commerceInfo?.returnPolicyDays || null,

    productSubscription: commerceInfo?.productSubscription || null,
    subscriptionAmounts: commerceInfo?.subscriptionAmounts || null,
    subscriptionRunTime: commerceInfo?.subscriptionRunTime || null,
    subscriptionWallet: commerceInfo?.subscriptionWallet || null,
  };

  console.log("EDIT_COMMERCE_POST_DATA:", postData);

  return postData;
};

function getDatesArray(startDate, endDate) {
  const dateArray = [];
  let currentDate = moment(startDate);
  const stopDate = moment(endDate);

  while (currentDate <= stopDate) {
    dateArray.push(currentDate.format("YYYY-MM-DD"));
    currentDate = currentDate.add(1, "days");
  }

  return dateArray;
}

function sortString(inputString) {
  var numbersArray = inputString.split(",").map(Number);
  numbersArray.sort(function (a, b) {
    return a - b;
  });
  var sortedString = numbersArray.join(",");
  return sortedString;
}

export const prepareONDCControlsUpdateData = (data, isEdit) => {
  const profileId = getProfileId();

  const { commerceInfo, ondcInfo, ondcCategories, stdData } = data;

  const ondcDomainData = [];

  const stdCode = stdData?.details?.[0]?.stdCode || "";

  let buyerAppLinks = [];
  ondcInfo?.ondc?.buyerLinks?.forEach((item) => {
    if (item?.code !== "" && item?.value !== "") {
      buyerAppLinks?.push({ code: item?.code, value: item?.value });
    }
  });

  ondcInfo?.ondc?.ondcDomain?.forEach((item) => {
    const category = ondcCategories?.filter(
      (categoryItem) => categoryItem?.domainName === item
    );

    if (category?.length > 0) {
      ondcDomainData.push(category?.[0]?.domainCode);
    }
  });

  if (isEdit) {
    ondcInfo?.additionalONDCCat?.forEach((item) => {
      const category = ondcCategories?.filter(
        (categoryItem) => categoryItem?.domainName === item
      );

      if (category?.length > 0) {
        ondcDomainData.push(category?.[0]?.domainCode);
      }
    });
  }

  let ondcOpenDays = [];
  let storeStartEndTime = [];

  let timingList = [];

  if (ondcInfo?.ondc?.isOpen24Hr === "yes") {
    ondcOpenDays = ["1", "2", "3", "4", "5", "6", "7"];
    storeStartEndTime = ["0000", "2359"];
  } else {
    console.log("ondcInfo.ondc.storeDays__:", ondcInfo?.ondc?.storeDays);
    console.log(
      "ondcInfo?.ondc?.storeTimeList__:",
      ondcInfo?.ondc?.storeTimeList
    );

    ondcInfo?.ondc?.storeDays?.forEach((item) => {
      if (item?.isOpen) {
        ondcOpenDays.push(item?.id);
      }
    });

    // If No split times use `operatingHours` else schedule.times
    if (ondcInfo?.ondc?.storeTimeList?.length === 1) {
      const timeInfo = ondcInfo?.ondc?.storeTimeList?.[0];

      storeStartEndTime = [
        `${timeInfo?.storeStartTime?.toString().replace(":", "")}`,
        `${timeInfo?.storeEndTime?.toString().replace(":", "")}`,
      ];
    } else {
      ondcInfo?.ondc?.storeTimeList?.forEach((timing) => {
        timingList.push(
          `${timing?.storeStartTime?.toString().replace(":", "")}`
        );
        // timingList.push(`${timing?.storeEndTime?.toString().replace(":", "")}`);
      });
    }

    console.log("timingList___:", timingList);
  }

  let frequencyData = null;

  if (
    ondcInfo?.ondc?.isOpen24Hr !== "yes" &&
    ondcInfo?.ondc?.storeTimeList?.length > 1
  ) {
    const timeData = ondcInfo?.ondc?.storeTimeList?.[0];

    const format = "HH:mm";
    const storeStartMoment = moment(timeData?.storeStartTime, format);
    const storeEndMoment = moment(timeData?.storeEndTime, format);

    const duration = moment.duration(storeEndMoment.diff(storeStartMoment));
    const durationHours = duration.hours();
    const durationMinutes = duration.minutes();

    frequencyData = `PT${durationHours ? durationHours + "H" : ""}${
      durationMinutes ? durationMinutes + "M" : ""
    }`;

    console.log(
      "Duration:",
      durationHours,
      "hours",
      durationMinutes,
      "minutes"
    );
  }

  const holidayDateList = getDatesArray(
    ondcInfo?.ondc?.holidayStartDate,
    ondcInfo?.ondc?.holidayEndDate
  );

  const ondcPostData = {
    shipmentDistance: ondcInfo?.ondc?.ondcSupportDistanceKm || '3000',
    cmsOndcEnabled: ondcInfo?.cmsOndcEnabled,
    ondcEnabled: ondcInfo?.ondcEnabled,
    ondc: {
      packagingCost: ondcInfo?.ondc?.packagingCost,
      convenienceFee: ondcInfo?.ondc?.convenienceFee,
      ondcDomain: ondcDomainData,

      ondcCustomerDiscountEnabled: ondcInfo?.ondc?.ondcCustomerDiscountEnabled,
      ondcBuyerFinderFeeUpperLimitPercentage:
        ondcInfo?.ondc?.ondcBuyerFinderFeeUpperLimitPercentage,
      ondcBuyerFinderFeeUpperLimitIncludingMktg:
        ondcInfo?.ondc?.ondcBuyerFinderFeeUpperLimitPercentage,

      ondcBPPFulfillments: [ondcInfo?.ondc?.ondcBPPFulfillments],
      ondcShipmentMethod:
        commerceInfo?.deliveryTypeInfo?.map((item) => item?.type) || [],

      ondcSupportRegion: [ondcInfo?.ondc?.ondcSupportRegion?.value],
      ondcSupportDistanceKm: ondcInfo?.ondc?.ondcSupportDistanceKm,
      ondcMultiWarehouseAllowed: ondcInfo?.ondc?.ondcMultiWarehouseAllowed,
      ondcMinOrder: ondcInfo?.ondc?.ondcMinOrder,
      brandMessaging: ondcInfo?.ondc?.brandMessaging,
      notes: ondcInfo?.ondc?.notes,

      cancelTerms: {
        ondcPartialCancellationStage: "ordercreated",
        ondcFullCancellationStage:
          ondcInfo?.ondc?.cancelTerms?.ondcFullCancellationStage?.value,
      },

      autoApproval: ondcInfo?.ondc?.autoApproval,

      schedule: {
        holidays: holidayDateList,
        // frequency: "PT4H",
        frequency: frequencyData,
        times: timingList,
      },
      openDays: sortString(ondcOpenDays.join(",")),
      operatingHours: storeStartEndTime,
      stdCode,
      buyerAppLinks,
    },
  };

  // const postData = {
  //   commerceEnable: commerceInfo?.commerceEnable,
  //   status: commerceInfo?.status,
  //   cancelReason: commerceInfo?.cancelReason || "",
  //   returnPolicy: commerceInfo?.returnPolicy || "",
  //   pickupExpireTime: commerceInfo?.pickupExpireTime || "",

  //   shipmentDistance: commerceInfo?.shipmentDistance || "",
  //   returnShippingBy: commerceInfo?.returnShippingBy || "",
  //   referralPointsTransfer: commerceInfo?.referralPointsTransfer || "",
  //   catalogType: commerceInfo?.catalogType || "",
  //   currencyType: commerceInfo?.currencyType || "",
  //   notes: commerceInfo?.notes || "",
  //   shipmentArea: commerceInfo?.shipmentArea || "India",

  //   rewardPointPercent: commerceInfo?.rewardPointPercent || 0,

  //   referralPointsMaxPerTxn: commerceInfo?.referralPointsMaxPerTxn || 0,
  //   firstTimeDiscount: commerceInfo?.firstTimeDiscount || 0,
  //   restockingFeePercentage: commerceInfo?.restockingFeePercentage || 0,
  //   restockingTimeFrameDays: commerceInfo?.restockingTimeFrameDays || 0,

  //   shipmentETA: commerceInfo?.shipmentETA,
  //   payType: commerceInfo?.payType,
  //   referralPointsClaimPolicy: commerceInfo?.referralPointsClaimPolicy,
  //   referralAmount: commerceInfo?.referralAmount,

  //   costBoFee: commerceInfo?.costBoFee,
  //   shipmentCost: commerceInfo?.shipmentCost,
  //   licenceInfo: commerceInfo?.licenceInfo,
  //   returnPolicies: commerceInfo?.returnPolicies,
  //   referralPointsPolicy: commerceInfo?.referralPointsPolicy,
  //   commissionPercentages: commerceInfo?.commissionPercentages,
  //   additionalDiscount: commerceInfo?.additionalDiscount,

  //   freeShipment: commerceInfo?.freeShipment,
  //   startDate: commerceInfo?.startDate || null,
  //   endDate: commerceInfo?.endDate || null,

  //   referralExpireDate: commerceInfo?.referralExpireDate || null,
  //   rewardExpirationDays: commerceInfo?.rewardExpirationDays,

  //   updatedOn: moment().valueOf(),
  //   updatedBy: profileId,

  //   createdOn: commerceInfo?.createdOn || moment().valueOf(),
  //   docValidationData: commerceInfo?.docValidationData || [],
  //   deliveryInfoEnable: commerceInfo?.deliveryInfoEnable,
  //   cashBackDetails: commerceInfo?.cashBackDetails,
  //   minOrderEnable: commerceInfo?.minOrderEnable,
  //   webOrder: commerceInfo?.webOrder,

  //   orderInfoDetails: commerceInfo?.orderInfoDetails,

  //   deliveryTypeInfo: commerceInfo?.deliveryTypeInfo || [],

  //   fssaiInfo: commerceInfo?.fssaiInfo,
  //   gstNumber: commerceInfo?.gstNumber || null,

  //   panNumber: commerceInfo?.panNumber || "",
  //   immediateDiscount: commerceInfo?.immediateDiscount || 0,
  //   codInfo: commerceInfo?.codInfo,
  //   pickUpInfoDetails: commerceInfo?.pickUpInfoDetails,

  //   // ONDC Controls

  //   ...ondcPostData,
  // };

  return ondcPostData;
};

export const prepareONDCBusinessOptionsData = (data) => {
  const { ondcInfo, ondcCategories, isEdit } = data;

  const ondcDomainData = [];

  ondcInfo?.ondc?.ondcDomain?.forEach((item) => {
    const category = ondcCategories?.filter(
      (categoryItem) => categoryItem?.domainName === item
    );

    if (category?.length > 0) {
      ondcDomainData.push({
        code: category?.[0]?.domainCode,
        name: category?.[0]?.domainName,
        status: "active",
      });
    }
  });

  if (isEdit) {
    ondcInfo?.additionalONDCCat?.forEach((item) => {
      const category = ondcCategories?.filter(
        (categoryItem) => categoryItem?.domainName === item
      );

      if (category?.length > 0) {
        ondcDomainData.push({
          code: category?.[0]?.domainCode,
          name: category?.[0]?.domainName,
          status: "active",
        });
      }
    });
  }

  const postData = {
    businessONDCCategories: ondcDomainData,
  };

  return postData;
};

export const prepareAdditionalBusinessCategoryData = (data) => {
  const { ondcInfo, ondcCategories, activeBusinessInfo, isEdit } = data;

  const selectedCatList = [];
  const businessCategoriesList = activeBusinessInfo?.businessCategories || [];

  ondcCategories.forEach((item) => {
    const isSelected = ondcInfo?.ondc?.ondcDomain?.some(
      (catName) => catName === item?.domainName
    );

    if (isSelected) {
      selectedCatList.push(item);
    }
  });

  if (isEdit) {
    ondcCategories.forEach((item) => {
      const isSelected = ondcInfo?.additionalONDCCat?.some(
        (catName) => catName === item?.domainName
      );

      if (isSelected) {
        selectedCatList.push(item);
      }
    });
  }

  console.log("USER_SELECTED_CATT:", selectedCatList);

  const businessCategories = [];

  selectedCatList?.forEach((item) => {
    const isPresentInBusinessCat = businessCategoriesList?.some(
      (businessCat) => businessCat?.name === item?.businessCategoryName
    );

    if (!isPresentInBusinessCat) {
      businessCategories.push({
        code: item?.businessCategoryCodes,
        name: item?.businessCategoryName,
        status: "active",
      });
    }
  });

  const postData = {
    businessCategories,
    businessHours: activeBusinessInfo?.businessHours,
    deliveryMethods: activeBusinessInfo?.deliveryMethods,
  };

  return postData;
};

export const getONDCOpenDays = (dates) => {
  const DAYS = [
    {
      id: "7",
      label: "Sunday",
      isOpen: false,
    },
    {
      id: "1",
      label: "Monday",
      isOpen: false,
    },
    {
      id: "2",
      label: "Tuesday",
      isOpen: false,
    },
    {
      id: "3",
      label: "Wednesday",
      isOpen: false,
    },
    {
      id: "4",
      label: "Thursday",
      isOpen: false,
    },
    {
      id: "5",
      label: "Friday",
      isOpen: false,
    },
    {
      id: "6",
      label: "Saturday",
      isOpen: false,
    },
  ];

  const newDays = DAYS.map((item) => {
    if (dates?.includes(item?.id)) {
      return {
        ...item,
        isOpen: true,
      };
    } else {
      return item;
    }
  });

  return newDays;
};
