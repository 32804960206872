import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import {
  blackShade05,
  blackShade19,
  border25,
  borderTextBoxColor,
  borderTextbox,
  greyColor24,
  greyColor27,
  greyColor42,
  greyColor47,
  greyColor6,
  lightRedColor,
  orangeShade11,
  redColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import FormInputBox from "../../../components/Input/FormInputBox";
import ErrorHighlight from "../../../components/ErrorHighlight/ErrorHighlight";
import {
  PRODUCT_AVAILABLE_TABLE_HEADER,
  PRODUCT_MEASUREMENT_DIMENTION_TABLE_HEADER,
  PRODUCT_MEASUREMENT_WEIGHT_TABLE_HEADER,
} from "../../../utils/data";
import TabelCellInput from "../../CatalogBulkUpload/Components/Popups/TabelCellInput";
import ModalCard from "../../../components/ModalCard";
import ProductPackingInfo from "../../Catalog/PopupModals/InfoPopups/ProductPackingInfo";
import LabelInputModal from "../../Catalog/PopupModals/LabelInputModal";
import RefLinksInputModal from "../../Catalog/PopupModals/RefLinksInputModal";
import TableDateInput from "../../CatalogBulkUpload/Components/TableDateInput";
import moment from "moment";
import {
  getVolumetricWeightInKg,
  mmToCm,
  mmToInches,
} from "../../../utils/helper";
import DateInput from "../../../components/Input/DateInput";

function AddPriceDetails({
  priceInfo,
  onPriceValueChange,
  availabilityInfo,
  onAvailabilityValueChange,
  measurementInfo,
  onMeasurementValueChange,
  productSpec,
  onProductSpecValueChange,
  isEdit,
}) {
  const [showPackingInfo, setShowPackingInfo] = useState(false);

  const isAlertQtyValid = () => {
    if (
      availabilityInfo?.alertQty === "" ||
      availabilityInfo?.availabelQty === ""
    ) {
      return "none";
    }

    const alertQty = Number(availabilityInfo?.alertQty);
    const availableQty = Number(availabilityInfo?.availabelQty);

    return alertQty < availableQty ? "valid" : "invalid";
  };

  const isMinOrderValid = () => {
    if (availabilityInfo?.minQty === "" || availabilityInfo?.maxQty === "") {
      return "none";
    }

    const minQty = Number(availabilityInfo?.minQty);
    const maxQty = Number(availabilityInfo?.maxQty);

    return minQty <= maxQty ? "valid" : "invalid";
  };

  const isAlertMinOrderValid = () => {
    if (availabilityInfo?.minQty === "" || availabilityInfo?.alertQty === "") {
      return "none";
    }

    const minQty = Number(availabilityInfo?.minQty);
    const alertQty = Number(availabilityInfo?.alertQty);

    return alertQty >= minQty ? "valid" : "invalid";
  };

  const isAvailableQtyValid = () => {
    if (
      availabilityInfo?.availabelQty === "" ||
      availabilityInfo?.alertQty === "" ||
      availabilityInfo?.maxQty === ""
    ) {
      return "none";
    }

    const maxQty = Number(availabilityInfo?.maxQty);
    const alertQty = Number(availabilityInfo?.alertQty);
    const availabelQty = Number(availabilityInfo?.availabelQty);

    return availabelQty >= maxQty && availabelQty >= alertQty
      ? "valid"
      : "invalid";
  };

  const isStartEndDateValid = () => {
    if (
      availabilityInfo?.startDate === "" ||
      availabilityInfo?.endDate === ""
    ) {
      return "none";
    }

    const startDate = availabilityInfo?.startDate;
    const endDate = availabilityInfo?.endDate;

    return moment(startDate)?.isSameOrBefore(moment(endDate))
      ? "valid"
      : "invalid";
  };

  return (
    <Box sx={styles.card}>
      {!isEdit && (
        <>
          <Box>
            <Typography sx={styles.title}>Price Details</Typography>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={3} md={2.5}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: 13, md: 14 },
                    fontWeight: 400,
                    color: greyColor6,
                  }}
                  mb={1}
                >
                  Currency
                </Typography>

                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: 13, md: 14 },
                      fontWeight: { xs: 500, md: 500 },
                      color: blackShade05,
                    }}
                  >
                    ₹ INR
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={4.5} md={3}>
                <FormInputBox
                  required
                  label="Original Price"
                  placeholder=""
                  value={priceInfo?.originalPrice}
                  onChange={({ target }) => {
                    const regex = /^\d{0,7}(\.\d{0,2})?$/;

                    if (regex.test(target.value)) {
                      onPriceValueChange("originalPrice", target.value);
                    }
                  }}
                  error={priceInfo?.errors?.originalPrice}
                />
              </Grid>
              <Grid item xs={4.5} md={3}>
                <FormInputBox
                  required
                  label="Offer Price"
                  placeholder=""
                  value={priceInfo?.OfferPrice}
                  onChange={({ target }) => {
                    const regex = /^\d{0,7}(\.\d{0,2})?$/;

                    if (regex.test(target.value)) {
                      onPriceValueChange("OfferPrice", target.value);
                    }
                  }}
                  error={
                    priceInfo?.errors?.OfferPrice
                      ? "Please fill out this field"
                      : priceInfo?.errors?.priceError
                      ? "Offer Price should not be greater than original price"
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Typography sx={styles.title}>Product Availablity</Typography>
            <Box
              sx={{ width: { md: "85%", xs: "100%" } }}
              pt={1}
              display={{ xs: "none", md: "flex" }}
            >
              <Box sx={styles.tableWrapper}>
                <TableContainer sx={styles.tableContainer} component={Box}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow
                        sx={{
                          position: "sticky !important",
                          top: 0,
                          backgroundColor: greyColor42,
                          zIndex: 20,
                          borderRadius: "8px 8px 0 0",
                        }}
                      >
                        {PRODUCT_AVAILABLE_TABLE_HEADER?.map((item, index) => {
                          return (
                            <TableCell
                              key={item.id}
                              sx={{
                                ...styles.tableCell,
                                ...styles.colSticky,
                              }}
                            >
                              <Box
                                display={index === 0 ? "flex" : "inline-block"}
                                alignItems="center"
                                gap={1.2}
                              >
                                <Box sx={{ fontSize: 12 }}>
                                  {item?.title}{" "}
                                  {item?.isRequired && (
                                    <span style={{ color: "red" }}>*</span>
                                  )}
                                </Box>
                              </Box>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>

                    <TableBody sx={{ backgroundColor: whiteColor }}>
                      <TableRow
                        sx={{
                          borderBottom: `1px solid ${borderTextBoxColor}`,
                          verticalAlign: "top",
                        }}
                      >
                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={availabilityInfo?.availabelQty}
                            onValueChange={(value) => {
                              onAvailabilityValueChange("availabelQty", value);
                            }}
                            errorMsg={availabilityInfo?.errors?.availabelQty}
                            regexType="qty"
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={availabilityInfo?.alertQty}
                            onValueChange={(value) => {
                              onAvailabilityValueChange("alertQty", value);
                            }}
                            errorMsg={availabilityInfo?.errors?.alertQty}
                            regexType="qty"
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={availabilityInfo?.minQty}
                            onValueChange={(value) => {
                              onAvailabilityValueChange("minQty", value);
                            }}
                            errorMsg={availabilityInfo?.errors?.minQty}
                            regexType="qty"
                          />
                        </TableCell>
                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TabelCellInput
                            value={availabilityInfo?.maxQty}
                            onValueChange={(value) => {
                              onAvailabilityValueChange("maxQty", value);
                            }}
                            errorMsg={availabilityInfo?.errors?.maxQty}
                            regexType="qty"
                          />
                        </TableCell>
                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TableDateInput
                            value={moment(availabilityInfo?.startDate).format(
                              "yyyy-MM-DD"
                            )}
                            onValueChange={(value) => {
                              onAvailabilityValueChange(
                                "startDate",
                                value == "Invalid date"
                                  ? null
                                  : new Date(value).toISOString()
                              );
                            }}
                            errorMsg={availabilityInfo?.errors?.startDate}
                          />
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell(false)}>
                          <TableDateInput
                            value={moment(availabilityInfo?.endDate).format(
                              "yyyy-MM-DD"
                            )}
                            onValueChange={(value) => {
                              onAvailabilityValueChange(
                                "endDate",
                                value == "Invalid date"
                                  ? null
                                  : new Date(value).toISOString()
                              );
                            }}
                            errorMsg={availabilityInfo?.errors?.endDate}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>

            <Grid
              container
              display={{ md: "none", xs: "flex" }}
              rowSpacing={2}
              columnSpacing={{ xs: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <FormInputBox
                  label="Available Quantity"
                  placeholder=""
                  required
                  value={availabilityInfo?.availabelQty}
                  onChange={({ target }) => {
                    const value = target?.value?.replace(/[^0-9]/g, "");
                    if (value.length <= 7) {
                      onAvailabilityValueChange("availabelQty", value);
                    }
                  }}
                  error={availabilityInfo?.errors?.availabelQty}
                />
              </Grid>

              <Grid item xs={6}>
                <FormInputBox
                  label="Alert Quantity"
                  placeholder=""
                  required
                  value={availabilityInfo?.alertQty}
                  onChange={({ target }) => {
                    const value = target?.value?.replace(/[^0-9]/g, "");
                    if (value.length <= 7) {
                      onAvailabilityValueChange("alertQty", value);
                    }
                  }}
                  error={availabilityInfo?.errors?.alertQty}
                />
              </Grid>

              <Grid item xs={6}>
                <FormInputBox
                  label="Min Quantity Per Order"
                  placeholder=""
                  required
                  value={availabilityInfo?.minQty}
                  onChange={({ target }) => {
                    const value = target?.value?.replace(/[^0-9]/g, "");
                    if (value.length <= 7) {
                      onAvailabilityValueChange("minQty", value);
                    }
                  }}
                  error={availabilityInfo?.errors?.minQty}
                />
              </Grid>

              <Grid item xs={6}>
                <FormInputBox
                  label="Max Quantity Per Order"
                  placeholder=""
                  required
                  value={availabilityInfo?.maxQty}
                  onChange={({ target }) => {
                    const value = target?.value?.replace(/[^0-9]/g, "");
                    if (value.length <= 7) {
                      onAvailabilityValueChange("maxQty", value);
                    }
                  }}
                  error={availabilityInfo?.errors?.maxQty}
                />
              </Grid>

              <Grid item xs={6}>
                <DateInput
                  required
                  label="Start Date"
                  date={moment(availabilityInfo?.startDate).format(
                    "yyyy-MM-DD"
                  )}
                  onDateChange={({ target }) => {
                    const value = target.value;

                    onAvailabilityValueChange(
                      "startDate",
                      value == "Invalid date"
                        ? null
                        : new Date(value).toISOString()
                    );
                  }}
                  error={availabilityInfo?.errors?.startDate}
                />
              </Grid>

              <Grid item xs={6}>
                <DateInput
                  required
                  label="End Date"
                  date={moment(availabilityInfo?.endDate).format("yyyy-MM-DD")}
                  onDateChange={({ target }) => {
                    const value = target.value;
                    onAvailabilityValueChange(
                      "endDate",
                      value == "Invalid date"
                        ? null
                        : new Date(value).toISOString()
                    );
                  }}
                  error={availabilityInfo?.errors?.endDate}
                />
              </Grid>
            </Grid>
            <Stack mt={2} gap={0.5}>
              <Grid container spacing={{ md: 2, xs: 0.5 }}>
                <Grid item xs={12} md={3.8}>
                  <ErrorHighlight
                    status={isAlertQtyValid()}
                    text="Alert quantity should be less than Available quantity"
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <ErrorHighlight
                    status={isMinOrderValid()}
                    text="Min order quantity must be less than Max order quantity"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={{ md: 2, xs: 0.5 }}>
                <Grid item xs={12} md={3.8}>
                  <ErrorHighlight
                    status={isAlertMinOrderValid()}
                    text="Alert quantity must be more than Min order quantity"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ErrorHighlight
                    status={isStartEndDateValid()}
                    text="Start date should be less than End date"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                  <ErrorHighlight
                    status={isAvailableQtyValid()}
                    text="Available Quantity should be greater than Alert Quantity & Maximum Quantity Per Order"
                  />
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </>
      )}
      <Box mt={1.5}>
        <Typography sx={styles.title}>Product Measurement</Typography>
        <Typography
          variant="body2"
          fontSize={{ xs: 12, md: 12.5 }}
          fontWeight={400}
          color={greyColor27}
          display="inline"
          lineHeight="18px"
        >
          Please refer to Shipping costs and Packaging recommendations{" "}
          <Typography
            variant="body2"
            fontSize={12.5}
            fontWeight={500}
            color={greyColor24}
            lineHeight="18px"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            display="inline"
            onClick={() => {
              setShowPackingInfo(true);
            }}
          >
            Here
          </Typography>
        </Typography>

        <Typography
          variant="body2"
          fontSize={12.5}
          fontWeight={400}
          color={greyColor27}
        >
          These values will be used for calculating shipping rates, not visible
          in catalog
        </Typography>

        <Box
          sx={{ width: { md: "60%", xs: "100%" } }}
          mt={1.5}
          display={{ md: "flex", xs: "none" }}
        >
          <Box sx={styles.tableWrapper}>
            <TableContainer sx={styles.tableContainer} component={Box}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow
                    sx={{
                      position: "sticky !important",
                      top: 0,
                      backgroundColor: greyColor42,
                      zIndex: 20,
                      borderRadius: "8px 8px 0 0",
                    }}
                  >
                    {PRODUCT_MEASUREMENT_WEIGHT_TABLE_HEADER?.map(
                      (item, index) => {
                        return (
                          <TableCell
                            key={item.id}
                            sx={{
                              ...styles.tableCell,
                              ...styles.colSticky,
                            }}
                          >
                            <Box
                              display={index === 0 ? "flex" : "inline-block"}
                              alignItems="center"
                              gap={1.2}
                            >
                              <Box sx={{ fontSize: 12 }}>
                                {item?.title}{" "}
                                {item?.isRequired && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </Box>
                            </Box>
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>

                <TableBody sx={{ backgroundColor: whiteColor }}>
                  <TableRow
                    sx={{
                      borderBottom: `1px solid ${borderTextBoxColor}`,
                      verticalAlign: "top",
                    }}
                  >
                    <TableCell sx={styles.tableBodyCell(false)}>
                      {/* {Number(measurementInfo?.weight) / 1000} */}
                      <TabelCellInput
                        value={measurementInfo?.weight}
                        onValueChange={(value) => {
                          onMeasurementValueChange("weight", value);
                        }}
                        errorMsg={measurementInfo?.errors?.weight}
                        regexType="weight"
                      />
                    </TableCell>
                    <TableCell sx={styles.tableBodyCell(false)}>
                      <Box sx={styles.readOnlyText}>
                        {Number(measurementInfo?.weight) / 1000}
                      </Box>
                    </TableCell>
                    <TableCell sx={styles.tableBodyCell(false)}>
                      <TabelCellInput
                        value={measurementInfo?.netProductWeight}
                        onValueChange={(value) => {
                          onMeasurementValueChange("netProductWeight", value);
                        }}
                        errorMsg={measurementInfo?.errors?.netProductWeight}
                        regexType="weight"
                      />
                    </TableCell>
                    <TableCell sx={styles.tableBodyCell(false)}>
                      <Box sx={styles.readOnlyText}>
                        {Number(measurementInfo?.netProductWeight) / 1000}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Box
          sx={{ width: { md: "80vw", xs: "100%" } }}
          my={2}
          display={{ md: "flex", xs: "none" }}
        >
          <Box sx={styles.tableWrapper}>
            <TableContainer sx={styles.tableContainer} component={Box}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow
                    sx={{
                      position: "sticky !important",
                      top: 0,
                      backgroundColor: greyColor42,
                      zIndex: 20,
                      borderRadius: "8px 8px 0 0",
                    }}
                  >
                    {PRODUCT_MEASUREMENT_DIMENTION_TABLE_HEADER?.map(
                      (item, index) => {
                        return (
                          <TableCell
                            key={item.id}
                            sx={{
                              ...styles.tableCell,
                              ...styles.colSticky,
                            }}
                          >
                            <Box
                              display={index === 0 ? "flex" : "inline-block"}
                              alignItems="center"
                              gap={1.2}
                            >
                              <Box sx={{ fontSize: 12 }}>
                                {item?.title}{" "}
                                {item?.isRequired && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </Box>
                            </Box>
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>

                <TableBody sx={{ backgroundColor: whiteColor }}>
                  <TableRow
                    sx={{
                      borderBottom: `1px solid ${borderTextBoxColor}`,
                      verticalAlign: "top",
                    }}
                  >
                    <TableCell sx={styles.tableBodyCell(false)}>
                      <TabelCellInput
                        value={measurementInfo?.length}
                        onValueChange={(value) => {
                          onMeasurementValueChange("length", value);
                        }}
                        errorMsg={measurementInfo?.errors?.length}
                        regexType="dimension"
                      />
                    </TableCell>
                    <TableCell sx={styles.tableBodyCell(false)}>
                      <Box sx={styles.readOnlyText}>
                        {mmToCm(measurementInfo?.length)}
                      </Box>
                    </TableCell>
                    <TableCell sx={styles.tableBodyCell(false)}>
                      <Box sx={styles.readOnlyText}>
                        {mmToInches(measurementInfo?.length)}
                      </Box>
                    </TableCell>

                    <TableCell sx={styles.tableBodyCell(false)}>
                      <TabelCellInput
                        value={measurementInfo?.width}
                        onValueChange={(value) => {
                          onMeasurementValueChange("width", value);
                        }}
                        errorMsg={measurementInfo?.errors?.width}
                        regexType="dimension"
                      />
                    </TableCell>
                    <TableCell sx={styles.tableBodyCell(false)}>
                      <Box sx={styles.readOnlyText}>
                        {mmToCm(measurementInfo?.width)}
                      </Box>
                    </TableCell>
                    <TableCell sx={styles.tableBodyCell(false)}>
                      <Box sx={styles.readOnlyText}>
                        {mmToInches(measurementInfo?.width)}
                      </Box>
                    </TableCell>

                    <TableCell sx={styles.tableBodyCell(false)}>
                      <TabelCellInput
                        value={measurementInfo?.height}
                        onValueChange={(value) => {
                          onMeasurementValueChange("height", value);
                        }}
                        errorMsg={measurementInfo?.errors?.height}
                        regexType="dimension"
                      />
                    </TableCell>
                    <TableCell sx={styles.tableBodyCell(false)}>
                      <Box sx={styles.readOnlyText}>
                        {mmToCm(measurementInfo?.height)}
                      </Box>
                    </TableCell>
                    <TableCell sx={styles.tableBodyCell(false)}>
                      <Box sx={styles.readOnlyText}>
                        {mmToInches(measurementInfo?.height)}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Grid
          container
          rowSpacing={{md:2,xs:1}}
          columnSpacing={{ xs: 2, md: 3 }}
          display={{ xs: "flex", md: "none" }}
        >
          <Grid item xs={9}>
            <FormInputBox
              label="Product Weight (in gms)"
              required
              placeholder=""
              value={measurementInfo?.weight}
              onChange={({ target }) => {
                const value = target?.value
                  ?.replace(/[^\d.]/g, "")
                  ?.replace(/^0+(?=\d)/, "")
                  ?.replace(/^(\d*\.\d{0,2}).*/, "$1");

                if (value === "" || parseFloat(value) <= 10000000) {
                  onMeasurementValueChange("weight", value);
                }
              }}
              error={measurementInfo?.errors?.weight}
            />
          </Grid>
          <Grid item xs={3}>
            <FormInputBox
              label="In kgs"
              placeholder=""
              value={Number(measurementInfo?.weight) / 1000}
              disabled
            />
          </Grid>

          <Grid item xs={9}>
            <FormInputBox
              label="Shipping Weight (in gms)"
              placeholder=""
              required
              value={measurementInfo?.netProductWeight}
              onChange={({ target }) => {
                const value = target?.value
                  ?.replace(/[^\d.]/g, "")
                  ?.replace(/^0+(?=\d)/, "")
                  ?.replace(/^(\d*\.\d{0,2}).*/, "$1");

                if (value === "" || parseFloat(value) <= 10000000) {
                  onMeasurementValueChange("netProductWeight", value);
                }
              }}
              error={measurementInfo?.errors?.netProductWeight}
            />
          </Grid>
          <Grid item xs={3}>
            <FormInputBox
              label="In kgs"
              placeholder=""
              value={Number(measurementInfo?.netProductWeight) / 1000}
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <FormInputBox
              label="Length (in mm)"
              placeholder=""
              value={measurementInfo?.length}
              onChange={({ target }) => {
                const value = target?.value
                  ?.replace(/[^\d.]/g, "")
                  ?.replace(/^0+(?=\d)/, "")
                  ?.replace(/^(\d*\.\d{0,2}).*/, "$1");

                if (value === "" || parseFloat(value) <= 10000) {
                  onMeasurementValueChange("length", value);
                }
              }}
              error={measurementInfo?.errors?.length}
            />
          </Grid>
          <Grid item xs={3}>
            <FormInputBox
              label="In cm"
              placeholder=""
              value={mmToCm(measurementInfo?.length)}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <FormInputBox
              label="In inches"
              placeholder=""
              value={mmToInches(measurementInfo?.length)}
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <FormInputBox
              label="Width (in mm)"
              placeholder=""
              value={measurementInfo?.width}
              onChange={({ target }) => {
                const value = target?.value
                  ?.replace(/[^\d.]/g, "")
                  ?.replace(/^0+(?=\d)/, "")
                  ?.replace(/^(\d*\.\d{0,2}).*/, "$1");

                if (value === "" || parseFloat(value) <= 10000) {
                  onMeasurementValueChange("width", value);
                }
              }}
              error={measurementInfo?.errors?.width}
            />
          </Grid>
          <Grid item xs={3}>
            <FormInputBox
              label="In cm"
              placeholder=""
              value={mmToCm(measurementInfo?.width)}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <FormInputBox
              label="In inches"
              placeholder=""
              value={mmToInches(measurementInfo?.width)}
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <FormInputBox
              label="Height (in mm)"
              placeholder=""
              value={measurementInfo?.height}
              onChange={({ target }) => {
                const value = target?.value
                  ?.replace(/[^\d.]/g, "")
                  ?.replace(/^0+(?=\d)/, "")
                  ?.replace(/^(\d*\.\d{0,2}).*/, "$1");

                if (value === "" || parseFloat(value) <= 10000) {
                  onMeasurementValueChange("height", value);
                }
              }}
              error={measurementInfo?.errors?.height}
            />
          </Grid>
          <Grid item xs={3}>
            <FormInputBox
              label="In cm"
              placeholder=""
              value={mmToCm(measurementInfo?.height)}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <FormInputBox
              label="In inches"
              placeholder=""
              value={mmToInches(measurementInfo?.height)}
              disabled
            />
          </Grid>
        </Grid>

        <Grid container mt={{ xs: 2, md: 0 }}>
          <Grid item md={5} xs={12}>
            <Typography
              variant="h3"
              fontSize={{ xs: 12, md: 13 }}
              color={greyColor6}
              fontWeight={400}
              mb={"5px"}
              sx={{
                display: "inline",
              }}
            >
              Volumetirc Weight (in kg){" "}
            </Typography>
            <Typography
              variant="h3"
              fontSize={{ xs: 11, md: 11.5 }}
              color={orangeShade11}
              fontWeight={400}
              mb={"5px"}
              sx={{
                display: "inline",
              }}
            >
              (Length * Width * Height / 5000 in cm)
            </Typography>
            <FormInputBox
              value={getVolumetricWeightInKg(
                mmToCm(measurementInfo?.length),
                mmToCm(measurementInfo?.width),
                mmToCm(measurementInfo?.height)
              )}
              disabled
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={2}>
        <Typography sx={styles.title}>Product Specification</Typography>

        <LabelInputModal
          label="Product Specification"
          placeholder="Enter product specifications"
          required
          disabled
          onValueChange={() => {}}
          productSpcs={productSpec?.productSpecs}
          setMoreProducts={(data) => {
            onProductSpecValueChange("productSpecs", data);
          }}
          error={productSpec?.errors?.productSpecs}
        />

        <Typography sx={styles.title} mt={3}>
          Reference Link
        </Typography>

        <RefLinksInputModal
          label="Reference Link"
          placeholder="Enter reference links"
          required
          disabled
          onValueChange={() => {}}
          refLinks={productSpec?.refLinks}
          setMoreProducts={(data) => {
            onProductSpecValueChange("refLinks", data);
          }}
          isDashboardInput
          error={productSpec?.errors?.refLinks}
        />
      </Box>

      <ModalCard
        open={showPackingInfo}
        handleClose={() => {
          setShowPackingInfo(false);
        }}
        width="70%"
      >
        <ProductPackingInfo />
      </ModalCard>
    </Box>
  );
}

export default AddPriceDetails;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    border: border25,
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: "15px 15px",
    mb: 2,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    mb: "6px",
    color: blackShade19,
  },
  tableWrapper: {
    width: "100%",
    borderRadius: "8px",
    border: `1px solid ${borderTextBoxColor}`,
  },

  tableContainer: {
    borderRadius: "8px",
    // overflow: "scroll",
    maxWidth: "100%",
  },

  tableCell: {
    color: greyColor47,
    fontSize: "12.5px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    padding: "8px 15px",
    backgroundColor: greyColor42,
    borderRight: `1px solid ${whiteColor}`,
  },

  tableBodyCell: (isError) => ({
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: isError ? lightRedColor : whiteColor,
    padding: "8px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,

    boxShadow: isError ? `0px -1px 0 0 ${redColor} inset` : "none",
  }),

  readOnlyText: {
    fontSize: 11.5,
    fontWeight: 500,
    height: { xs: "27px", md: "27px" },
    pt: 0.5,
    borderBottom: `1.5px solid ${borderTextBoxColor}`,
  },
};
