import { useState, useEffect } from "react";
import { blueColor04, mobilePrimary } from "../configs/styles/muiThemes";

const useDynamicColor = () => {
  const [color, setColor] = useState(blueColor04);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setColor(mobilePrimary);
      } else {
        setColor(blueColor04);
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobilePrimary, blueColor04]);

  return color;
};

export default useDynamicColor;
