import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import InputPreview from "../../components/Input/InputPreview";
import { blackColor, whiteColor } from "../../configs/styles/muiThemes";

function ProductDetailsPreview({
  productName,
  brand,
  country,
  skuId,
  description,
  images,
  imageSelector,
  businessCategory,
  productCategory,
  productSubCategory,
  viewProduct,
}) {
  return (
    <Box sx={styles.section}>
      {!viewProduct && <Typography
        component="h5"
        fontSize={{ xs: 15, md: 18 }}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        m={{xs:"0px 0 15px !important" , md:"0px 0 20px !important"}}
      >
        {viewProduct ? productName : "Product Preview Info"}
      </Typography>}

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        <Grid item xs={12} md={12}>
          <InputPreview label="Product Name" value={productName} />
        </Grid>

        <Grid item xs={12} md={12}>
          <InputPreview
            label="Product Description"
            value={description}
            isReadMoreLess
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputPreview label="Brand" value={brand} />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputPreview label="Country of Origin" value={country} />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputPreview label="SKU ID" value={skuId} />
        </Grid>
      </Grid>

      <Box mt={2.5}>
        <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
          <Grid item xs={12} md={4}>
            <InputPreview label="Business Category" value={businessCategory} />
          </Grid>

          <Grid item xs={12} md={4}>
            <InputPreview label="Product Category" value={productCategory} />
          </Grid>

          <Grid item xs={12} md={4}>
            <InputPreview
              label="Product Sub-Category"
              value={productSubCategory}
            />
          </Grid>
        </Grid>
      </Box>

      <Typography
        component="h6"
        fontSize={{ xs: 13, md: 15 }}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        m="20px 0 15px !important"
      >
        Uploaded Product Images
      </Typography>

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        {images?.map((item, index) => {
          if (item?.status === "inactive") return null;

          const imageUrl = imageSelector ? item[imageSelector] : item?.docURL;
          if (!imageUrl) return;

          return (
            <Grid key={"preview_img_" + index} item xs={12} md={3}>
              <Box
                component="img"
                src={imageUrl}
                width="100%"
                height="200px"
                sx={{ objectFit: "cover", borderRadius: "12px" }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default ProductDetailsPreview;

const styles = {
  section: {
    p: "20px 20px 20px",
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: "13px",
    mt: { xs: "5px", md: "5px" },
  },
};

const ABC = () => {
  return (
    <>
      {/* <Grid item xs={12} md={3.5}>
        <InputPreview
          label="Product Category"
          value={productCategory}
          // value={data?.ondcProductCategory?.name}
        />
      </Grid>
      <Grid item xs={12} md={3.5}>
        <InputPreview
          label="Product Sub Category"
          value={subProductCategory}
          // value={data?.ondcProductSubCategory?.productSubCategory1}
        />
      </Grid> */}
      {/* <Grid item xs={12} md={3.5}>
              <InputPreview
                label="Time to Ship"
                value={`${parseInt(
                  data?.ondc?.timeToShip.match(/\d+/)?.[0]
                )} ${getTimeLabel(
                  data?.ondc?.timeToShip.charAt(
                    data?.ondc?.timeToShip.length - 1
                  )
                )}`}
              />
            </Grid> */}
      {/* <Grid item xs={12} md={3.5}>
        <InputPreview
          label="Return Period"
          value={`${moment
            .duration(data?.ondc?.returnTerms?.returnPeriod)
            .days()} Days`}
        />
      </Grid> */}
    </>
  );
};
