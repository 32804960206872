import { Box, Button, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  blackShade22,
  blueColor04,
  greyColor8,
  whiteColor,
} from "../../configs/styles/muiThemes";
import ThreeDotLoader from "../../components/ThreeDotLoader";

function DeleteProductModalContent({
  isLoading,
  isDeleteComplete,
  productName,
  onDelete,
  onCancel,
  onDone,
}) {
  return (
    <Box sx={{ backgroundColor: whiteColor, px: 3, py: 2.5, borderRadius: 2 }}>
      {isLoading ? (
        <ThreeDotLoader />
      ) : isDeleteComplete ? (
        <>
          <>
            <Typography fontSize={16} fontWeight={600} color={blackColor}>
              Alert
            </Typography>

            <Typography fontSize={14} my={2.5}>
              "{productName}" has been removed from the catalog
            </Typography>

            <Box display="flex" justifyContent={"flex-end"} mt={1.5} gap={2}>
              <Button sx={styles.fillBtn} onClick={onDone}>
                OK
              </Button>
            </Box>
          </>
        </>
      ) : (
        <>
          <Typography fontSize={16} fontWeight={600} color={blackColor}>
            Alert
          </Typography>

          <Typography fontSize={14} my={2.5}>
            Do you want to delete this product - "{productName}"
          </Typography>

          <Box display="flex" justifyContent={"flex-end"} mt={1.5} gap={2}>
            <Button sx={styles.cancelBtn} onClick={onCancel}>
              No
            </Button>

            <Button sx={styles.fillBtn} onClick={onDelete}>
              Yes
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default DeleteProductModalContent;

const styles = {
  cancelBtn: {
    padding: "4px 14px",
    fontSize: "12px",
    fontWeight: 600,
    color: blueColor04,
    backgroundColor: whiteColor,
    border: "1.5px solid " + blueColor04,
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },

  fillBtn: {
    backgroundColor: blackShade22,
    padding: "4px 14px",
    fontSize: "12px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + blackShade22,
    "&:hover": {
      color: blackShade22,
      backgroundColor: whiteColor,
    },
  },
};
