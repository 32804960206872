import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BGColor02, greyColor39 } from "../configs/styles/muiThemes";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";

import DashboardLayout from "../components/Layouts/DashboardLayout";

import {
  clearMultiBranchInfo,
  getActiveBusinessProducts,
} from "../reducers/multiBranchCatalog/multiBranchCatalogSlice";
import SubBranchCalalogContent from "./SubBranchCalalog/SubBranchCalalogContent";
import { getInventorySummaryCounts } from "../reducers/inventory/inventorySlice";

function SubBranchCalalog() {
  const PAGE_SIZE = 500;
  const [hasMoreData, setHasMoreData] = useState(true);

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeBusinessInfo, commerceInfo } = useSelector((state) => state.businessPreview);
  const { inventorySummary } = useSelector((state) => state.inventory);

  const {
    activeBusinessProducts,
    activeBusinessProductsPageNo,
    activeBusinessProductsLoader,
  } = useSelector((state) => state.multiBranchCatalog);

  // Fetch active Business Products
  const fetchActiveBusinessProducts = (isRefresh) => {
    setHasMoreData(true);
    const businessId = sessionStorage.getItem("selectedBusinessId");

    if (isRefresh) {
      dispatch(
        getActiveBusinessProducts({
          businessId,
          pageSize: PAGE_SIZE,
          pageNo: 1,
          isRefresh: true,
        })
      ).then((res) => {
        if (
          res?.payload?.productList?.length == 0 ||
          res?.payload?.productList?.length < PAGE_SIZE
        ) {
          setHasMoreData(false);
        }
      });

      return;
    }

    dispatch(
      getActiveBusinessProducts({
        businessId,
        pageSize: PAGE_SIZE,
        pageNo: activeBusinessProductsPageNo,
      })
    ).then((res) => {
      if (
        res?.payload?.productList?.length == 0 ||
        res?.payload?.productList?.length < PAGE_SIZE
      ) {
        setHasMoreData(false);
      }
    });
  };

  // Fetching all the Catalog Data
  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }

      if (!inventorySummary) {
        dispatch(getInventorySummaryCounts(businessId));
      }

      console.log("SUB_BRANCH_CATALOG__HITTT");
      fetchActiveBusinessProducts();
    }

    return () => {
      dispatch(clearMultiBranchInfo());
    };
  }, []);

  return (
    activeBusinessInfo && (
      <Box sx={styles.container(matches)}>
        <DashboardLayout activeMenu="catalog">
          <Box sx={styles.content}>
            <SubBranchCalalogContent
              productList={activeBusinessProducts}
              productLoader={activeBusinessProductsLoader}
              commerceInfo={commerceInfo}
              fetchActiveBusinessProducts={fetchActiveBusinessProducts}
              hasMoreData={hasMoreData}
              setHasMoreData={setHasMoreData}
              inventorySummary={inventorySummary}
            />
          </Box>
        </DashboardLayout>
      </Box>
    )
  );
}

export default SubBranchCalalog;

const styles = {
  container: (isMobile) => ({
    backgroundColor: isMobile ? BGColor02 : greyColor39,
  }),

  content: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "10px" },
    pt: { xs: 2.5, md: 0 },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },
};
