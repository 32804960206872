import { Box, Typography } from "@mui/material";
import React from "react";
import {
  whiteColor,
  logoImgborder1,
  greyColor6,
  blackShade05,
  BGColor02,
} from "../../configs/styles/muiThemes";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import CancelPresentationOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import { addThousandsSeparator } from "../../utils/helper";
import { stickyHeader } from "../../utils/styles";
function CouponInfoCard({
  totalCoupons,
  activeCoupons,
  ordersPalcedWithCoupon,
  totalUsedDiscount,
  couponExpired,
}) {
  return (
    <Box
      sx={{
        position: "sticky",
        top: {md:185,xs:230},
        backgroundColor: BGColor02,
        zIndex: 100,
        pb: 2,pt:{md:1.5,xs:0}
      }}
    >
      <Box
        sx={{
          ...styles.infoBox,
        }}
      >
        <Box flex="1" display="flex">
          <Box flex="1">
            <StatsInfoCard
              icon={
                <DiscountOutlinedIcon
                  sx={{ fontSize: { xs: "0px", md: "24px" } }}
                />
              }
              label="TOTAL COUPONS"
              value={totalCoupons}
            />
          </Box>
          <Box flex="1">
            <StatsInfoCard
              icon={
                <LocalOfferOutlinedIcon
                  sx={{ fontSize: { xs: "0px", md: "24px" } }}
                />
              }
              label="ACTIVE COUPONS"
              value={activeCoupons}
            />
          </Box>
        </Box>
        <Box flex="1" display="flex">
          <Box flex="1">
            <StatsInfoCard
              icon={
                <AddShoppingCartIcon
                  sx={{ fontSize: { xs: "0px", md: "24px" } }}
                />
              }
              label="ORDERS PLACED "
              value={ordersPalcedWithCoupon}
            />
          </Box>
          <Box flex="1">
            <StatsInfoCard
              icon={
                <RedeemOutlinedIcon
                  sx={{ fontSize: { xs: "0px", md: "24px" } }}
                />
              }
              label="TOTAL DISCOUNT USED"
              value={`₹ ${
                isNaN(totalUsedDiscount)
                  ? 0
                  : addThousandsSeparator(totalUsedDiscount)
              }`}
            />
          </Box>
        </Box>

        <Box
          sx={{ ...styles.infoInnerBox, display: { xs: "none", md: "block" } }}
        >
          <StatsInfoCard
            icon={
              <CancelPresentationOutlinedIcon
                sx={{ fontSize: { xs: "0px", md: "24px" } }}
              />
            }
            label="EXPIRED COUPONS"
            value={couponExpired}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default CouponInfoCard;

const StatsInfoCard = ({icon, label, value}) => {
  return (
    <Box display="flex" gap={{md:"20px",xs:"none"}}>
      <Box sx={{ ...styles.iconCircle }}>{icon}</Box>

      <Box>
        <Typography
          fontSize={{ xs: 11, md: 12 }}
          fontWeight={500}
          color={greyColor6}
        >
          {label}
        </Typography>

        <Typography
          fontSize={{ xs: "15px", md: "16px" }}
          fontWeight={600}
          color={blackShade05}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  infoBox: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "space-between",
    backgroundColor: whiteColor,
    baxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    padding: "10px",
    border: logoImgborder1,
    borderRadius: "10px",
  },
  infoInnerBox: {
    display: "flex",
    marginTop: {xs:"10px",md:"0px"},
    justifyContent: "space-between",
  },

  statCard: {},

  iconCircle: {
    width: { xs: "0px", md: "45px" },
    height: { xs: "0px", md: "45px" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(5 60 81 / 7%)",
    borderRadius: "50%",
  },
};
