import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import MobileInfoCardHeader from "./MobileInfoCardHeader";
import { blackShade05, blueColorM02, greenColorM01, greyColor42, greyColorM01, logoImgborder1, whiteColor } from "../../../configs/styles/muiThemes";

function MobileSupplyChainTech({ onClick }) {
  return (
    <Box sx={styles.card}>
      <Box borderBottom={`1px solid ${greyColor42}`} pb={1} mb={1}>
        <MobileInfoCardHeader
          title={"Supply Chain Fintech"}
          onManageClick={onClick}
        />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Box width={"100%"}>
            <Typography
              variant="body2"
              fontSize={{ xs: 11, md: 11.28 }}
              color={blueColorM02}
              fontWeight={500}
              lineHeight="20px"
            >
              No Of Orders
            </Typography>

            <Typography
              component="h6"
              fontSize={{ xs: 15, md: 15 }}
              color={blackShade05}
              fontWeight={600}
            >
              0
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box width={"100%"}>
            <Typography
              variant="body2"
              fontSize={{ xs: 11, md: 11.28 }}
              color={greenColorM01}
              fontWeight={500}
              lineHeight="20px"
            >
              Avg Order Value
            </Typography>

            <Typography
              component="h6"
              fontSize={{ xs: 15, md: 15 }}
              color={blackShade05}
              fontWeight={600}
            >
              0
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Box width={"100%"}>
            <Typography
              variant="body2"
              fontSize={{ xs: 11, md: 11.28 }}
              color={greyColorM01}
              fontWeight={500}
              lineHeight="20px"
            >
              Total Sale
            </Typography>

            <Typography
              component="h6"
              fontSize={{ xs: 15, md: 15 }}
              color={blackShade05}
              fontWeight={600}
            >
              ₹ {0}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} mt={-9}>
          <Box
            component="img"
            alt="Product Image"
            src={
              "https://storage.googleapis.com/bodefaults/businessdashboardweb/supply.svg"
            }
            width="auto"
            height="128px"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default MobileSupplyChainTech;

const styles = {
  card: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "13px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 15px", md: "15px 25px" },
    mb: { xs: 2, md: 0 },
  },
};
