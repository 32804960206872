import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import AuthTextBox from "../../../components/AuthTextBox";
import {
  enterPasswordMsg,
  inputError_NotRegistered,
  inputError_NumberPasswordNotMatch,
} from "../../../configs/Constants";
import {
  errorTextColor,
  headingColor,
  labelColor,
  loginPrimary,
  secondaryColor,
  greyColor1,
  whiteColor,
  blueColor03,
  greyColor6,
  blackShade58,
  greyColor24,
  authInputIconColor,
  blueColor04,
  blackShade09,
  yellowColor,
  greyColor21,
} from "../../../configs/styles/muiThemes";
import React, { useState } from "react";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { Link } from "react-router-dom";
import { decryptData, encryptData } from "../../../utils/authHelpers";
import { validatePassword } from "../../../utils/validationUtils";
// import { getCode, getLoginDetials } from 'redux/authRedux/authSlice'
import { getCode, getLoginDetials } from "../../../reducers/auth/authSlice";
import { useDispatch } from "react-redux";

function PasswordScreen({
  mobileNumber,
  onChangeNumber,
  onForgotClick,
  onLoginSuccess,
}) {
  const [showPassword, setShowPassword] = useState(true);
  const [password, setPassword] = useState(
    decryptData(localStorage.getItem("password") || "") || ""
  );
  const [isRememberMe, setIsRememberMe] = useState(
    localStorage.getItem("rememberme")
      ? JSON.parse(localStorage.getItem("rememberme"))
      : false
  );
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [errorMsgValue, setErrorMsgValue] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = () => {
    setLoading(true);
    if (isRememberMe) {
      if (password.length > 0) {
        localStorage.setItem("phone", mobileNumber);
        localStorage.setItem("password", encryptData(password));
        localStorage.setItem("rememberme", true);
      } else {
        localStorage.removeItem("phone");
        localStorage.removeItem("password");
        localStorage.removeItem("rememberme");
      }
    }
    const error = validatePassword(password);
    if (error?.length > 0) {
      setIsError(true);
      setLoading(false);
      return;
    }
    dispatch(getLoginDetials({ phone: mobileNumber, password })).then(
      (response) => {
        console.log("LOGEGDIN", response);
        if (response.payload) {
          if (response.payload.status === 200) {
            console.log("LOGIN RESPONSE 200", response);
            onLoginSuccess();
          }
          if (response.payload.status === 400) {
            console.log("LOGIN RESPONSE 400", response);
            setErrorMsg(true);
            setErrorMsgValue(inputError_NumberPasswordNotMatch);
          }
          if (response.payload.status === 401) {
            console.log("LOGIN RESPONSE 401", response);
            setErrorMsgValue(inputError_NotRegistered);
            setErrorMsg(true);
          }
          setLoading(false);
        } else {
          setLoading(false);
          console.log("LOGIN RESPONSE", response);
          setErrorMsg(true);
        }
      }
    );
  };

  return (
    <Box>
      <Box mb={{ md: "20px", xs: "12px" }}>
        <Typography
          variant="h4"
          color={blackShade58}
          fontSize={{ xs: 17, md: 22 }}
          fontWeight={600}
          lineHeight="30px"
          m={{ md: "0px 0 15px !important", xs: "0px 0 10px !important" }}
        >
          Welcome back!
        </Typography>

        <Typography
          variant="h4"
          color={greyColor6}
          fontSize={{ xs: 13, md: 14 }}
          fontWeight={400}
          lineHeight="18px"
          m={{ md: "0px 0 8px !important", xs: "0 0 3px !important" }}
        >
          Enter your password to continue
        </Typography>

        <Typography
          variant="h5"
          color={greyColor6}
          fontSize={{ xs: 13, md: 14 }}
          fontWeight={400}
          lineHeight="18px"
          m="0px 0 5px !important"
          display="inline"
        >
          Phone Number: +91 {mobileNumber}{" "}
          <Typography
            component="h5"
            color={blueColor04}
            fontSize={13}
            fontWeight={500}
            sx={{
              textDecoration: "underline",
              ml: 0.5,
              "&:hover": { textDecoration: "none" },
              cursor: "pointer",
            }}
            onClick={onChangeNumber}
            display="inline"
          >
            Change
          </Typography>
        </Typography>
      </Box>

      <Typography
        variant="body1"
        color={greyColor6}
        fontSize={13.5}
        fontWeight={400}
        lineHeight="18px"
        mb="0px !important"
      >
        Password
        {/* <Typography
          component="span"
          color="red"
          fontSize={13.5}
          fontWeight={400}
        >
          *
        </Typography> */}
      </Typography>

      <AuthTextBox
        value={password}
        onChange={(data) => {
          if (isError) setIsError(!isError);
          if (errorMsg) setErrorMsg(!errorMsg);
          setPassword(data.target.value);
        }}
        name="password"
        placeholder={enterPasswordMsg}
        isError={isError}
        errorMsg={"Please enter Password"}
        icon={
          <VisibilityRoundedIcon
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            sx={{
              fontSize: 18,
              color: !showPassword ? loginPrimary : authInputIconColor,
            }}
          />
        }
        inputType={!showPassword ? "text" : "password"}
        showAutoComplete
      />
      {errorMsg && (
        <Box>
          <Typography variant="subtitle1" fontSize={13} color={errorTextColor}>
            {errorMsgValue}
          </Typography>
        </Box>
      )}

      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={{ xs: 0.5, md: 0 }}
      >
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={isRememberMe}
                // color="secondary"
                onChange={() => {
                  setIsRememberMe(!isRememberMe);
                }}
                sx={{
                  color: greyColor21,
                  "&.Mui-checked": {
                    color: "secondary",
                  },
                  "& .MuiSvgIcon-root": { fontSize: 18 },
                }}
                border={`1px solid rgba(0, 0, 0, .25)`}
              />
            }
            label={
              <Typography
                variant="body1"
                color={labelColor}
                fontSize="13px"
                fontWeight={500}
              >
                Remember Me
              </Typography>
            }
          />
        </Grid>

        <Grid item>
          <Button
            sx={{
              textTransform: "none",
              color: blueColor04,
              fontWeight: 500,
              display: "block",
              p: 0,
              fontSize: 13,
            }}
            onClick={() => {
              dispatch(getCode({ phone: mobileNumber })).then((response) => {
                if (response.payload) {
                  if (response.payload.status === 200) {
                    if (response.payload.data.email.length > 0)
                      onForgotClick(response.payload.data.email);
                    //   onResetClick(response.payload.data.email, values.phone)
                    else setErrorMsg(true);
                  }
                } else {
                  //   setLoading(false)
                  setErrorMsg(true);
                }
              });
            }}
          >
            Forgot Password ?
          </Button>
        </Grid>
      </Grid>

      <Box mt="8px">
        <Typography
          variant="body1"
          component="p"
          color={greyColor6}
          fontSize={13}
          display="inline"
        >
          By continuing, you agree to our{" "}
          <Typography
            color={loginPrimary}
            display="inline"
            fontSize={13}
            fontWeight={500}
            component={Link}
            // target='_blank'
            to={`/terms-conditions`}
          >
            Terms of Use
          </Typography>{" "}
          &{" "}
          <Typography
            color={loginPrimary}
            display="inline"
            fontSize={13}
            fontWeight={500}
            component={Link}
            // target='_blank'
            to={`/privacy-policy`}
          >
            Privacy Policy
          </Typography>
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          sx={{
            ...styles.actionBtn(),
            border: loading ? "none" : `1px solid ${yellowColor}`,
          }}
          variant="contained"
          disableElevation
          onClick={handleSubmit}
          disabled={loading}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
}

export default PasswordScreen;

const styles = {
  actionBtn: () => ({
    minWidth: { md: "115px", xs: "auto" },
    fontSize: { md: "14px", xs: "13px" },
    fontWeight: 600,
    color: blackShade09,
    background: yellowColor,
    p: { md: "5px 22px", xs: "4px 12px" },
    mt: "20px !important",
    borderRadius: "6px",

    "&:hover": {
      background: "none",
      backgroundColor: whiteColor,
      color: blackShade09,
      border: `1px solid ${blackShade09}`,
    },
    letterSpacing: "0.5px",
  }),
};
