import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import { BGColor02, blackColor } from "../configs/styles/muiThemes";
import { useDispatch, useSelector } from "react-redux";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import ThreeDotLoader from "../components/ThreeDotLoader";
import {
  getReviewProductDetailsAPI,
  getViewProductDetailsAPI,
} from "../service/api_calls/catalog/CatalogAPI";
import {
  getOndcFullSpecificationAPI,
  getOndcSpecificationAPI,
  getProductSubCategoryOneAPI,
} from "../service/api_calls/AddProduct/AddProductAPI";
import { useLocation, useNavigate } from "react-router-dom";
import ProductInfoPreview from "./AddProductNew/DataPreview/ProductInfoPreview";
import { DEFAULT_COUNTRY } from "../utils/data";
import { prepareViewProductData } from "../utils/postDataHelpers/CatalogHelper";
import { getNameByCode, getValueByPath } from "../utils/validationHelper";
import {
  extractNameCodePairs,
  extractNames,
  getValueByCode,
} from "../utils/helper";
import { getCountryNames } from "../reducers/catalog/CatalogSlice";
import { stickyHeader } from "../utils/styles";

const ProductPreviewNew = () => {
  const businessName = sessionStorage.getItem("costboWEBURL");

  const [productDetails, setProductDetails] = useState(null);
  const [productDetailsLoader, setProductDetailsLoader] = useState(false);
  const [productInfo, setProductInfo] = useState({
    productName: "",
  });

  const [categoryInfo, setCategoryInfo] = useState({
    ondcEnabled: "yes",
    selectedBusinessCat: "",
    selectedProductCat: "",
    selectedProductSubCat: "",
    selectedOndcCat: "",
    selectedOndcSubCat: "",

    errors: null,
  });

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  const { countryList, countryListLoading } = useSelector(
    (state) => state.catalog
  );

  const autoFillOndcSpecification = async (productDetails) => {
    console.log("productDetailsProductDetails", productDetails);
    const specificationPayload = {
      pscCode1: `${productDetails?.productMainCategory?.[0]?.ondcCode}${productDetails?.productSubCategory?.[0]?.ondcSubCode}`,
      status: "active",
    };
    const fullSpecificationPayload = {
      domainCode: productDetails?.productMainCategory?.[0]?.ondcCode,
      status: "active",
    };
    const specification = await getOndcSpecificationAPI(specificationPayload);
    const fullSpecification = await getOndcFullSpecificationAPI(
      fullSpecificationPayload
    );

    const mapByCode = fullSpecification?.details?.reduce((acc, obj) => {
      acc[obj.code] = obj;
      return acc;
    }, {});

    const array = specification?.details?.[0]?.attributes?.map((obj1) => {
      // Check if there is a matching object in the second array based on code
      if (mapByCode[obj1?.value]) {
        console.log(
          "specificationInputValue",
          productDetails,
          mapByCode[obj1?.value]?.dbObjectName,
          getValueByPath(productDetails, mapByCode[obj1?.value]?.dbObjectName)
        );
        // Merge properties from the second array object to the first array object
        console.log("map", mapByCode[obj1?.value]?.type);
        if (mapByCode[obj1?.value]?.dbObjectName === "list") {
          console.log("hit585");
          if (mapByCode[obj1?.value]?.type === "codeValue") {
            const colorList = extractNameCodePairs(
              mapByCode[obj1?.value]?.values
            );
            const colorCode = getValueByCode(
              productDetails?.ondc?.attributeList,
              mapByCode[obj1?.value]?.code
            );
            const colourName = getNameByCode(colorList, colorCode);
            return {
              ...obj1,
              required: obj1?.code,
              ...mapByCode[obj1?.value],
              inputValue: colourName || "",
              options: extractNames(mapByCode[obj1?.value]?.values),
              nameCodes: extractNameCodePairs(mapByCode[obj1?.value]?.values),
            };
          }
          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            inputValue:
              getValueByCode(
                productDetails?.ondc?.attributeList,
                mapByCode[obj1?.value]?.code
              ) || "",
            // mapByCode[obj1?.value]?.type === "default"
            //   ? mapByCode[obj1?.value]?.values?.[0]
            //   : "",
          };
        }
        if (mapByCode[obj1?.value]?.type === "codeValue") {
          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            inputValue: "",
            options: extractNames(mapByCode[obj1?.value]?.values),
            nameCodes: extractNameCodePairs(mapByCode[obj1?.value]?.values),
          };
        }
        if (mapByCode[obj1?.value]?.type === "radioButton") {
          // const options = mapByCode[obj1?.value]?.values?.map((jsonString) =>
          //   JSON.parse(jsonString)
          // );
          const options = mapByCode[obj1?.value]?.radioOptions;
          const radioOptions = options?.map((option) => {
            return {
              ...option,
              value: getValueByPath(productDetails, option?.key),
            };
          });

          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            radioOptions,
          };
        }
        return {
          ...obj1,
          required: obj1?.code,
          ...mapByCode[obj1?.value],
          inputValue:
            getValueByPath(
              productDetails,
              mapByCode[obj1?.value]?.dbObjectName
            ) || "",
          // mapByCode[obj1?.value]?.type === "default"
          //   ? mapByCode[obj1?.value]?.values?.[0]
          //   : "",
        };
      } else {
        // If no match found, simply return the first array object
        return obj1;
      }
    });

    console.log(
      "codeMapDataSpecification",
      mapByCode,
      specification?.details?.[0]?.attributes,
      fullSpecification,
      array
    );

    return array;
  };

  const autoFillCategotyInfo = (productDetails) => {
    if (activeBusinessInfo && productDetails) {
      const businessCat = productDetails?.productMainCategory?.[0] || null;
      const productCat = productDetails?.productSubCategory?.[0] || null;
      const prodSubCat = productDetails?.productSubCategory2?.[0] || null;

      const ondcMainCat = productDetails?.productMainCategory?.[0] || null;
      const ondcSubCat = productDetails?.productSubCategory?.[0] || null;

      const catData = {
        ...categoryInfo,
        ondcEnabled: productDetails?.ondcEnabled || "no",

        selectedBusinessCat: {
          code: businessCat?.code || "",
          name: businessCat?.value || "",
          status: "active",
        },
        selectedProductCat: {
          code: productCat?.code || "",
          value: productCat?.value || "",
          pcCode: productCat?.pcCode || "",
          status: "active",
          pscCode1: productCat?.pscCode1 || "",
        },
        selectedProductSubCat: {
          code: prodSubCat?.code || "",
          value: prodSubCat?.value || "",
          pcCode: prodSubCat?.pcCode || "",
          psCode: prodSubCat?.psCode || "",
          status: "active",
          pscCode2: prodSubCat?.pscCode2 || "",
        },
        selectedOndcCat: {
          code: ondcMainCat?.ondcCode || "",
          name: ondcMainCat?.ondcValue || "",
          status: "active",
        },
        selectedOndcSubCat: {
          pcCode: "",
          scCode1: ondcSubCat?.ondcSubCode || "",
          pscCode1: "",
          productSubCategory1: ondcSubCat?.ondcSubValue || "",
        },
      };

      console.log("CAT_DATA___ProdInfo:", productDetails);
      console.log("CAT_DATA___:", catData);
      setCategoryInfo(catData);
    }
  };

  const getProductData = async (productDetails) => {
    const ondcSpecificationData = await autoFillOndcSpecification(
      productDetails
    );
    autoFillCategotyInfo(productDetails);
    const previewData = prepareViewProductData({
      productInfo: productDetails,
      ondcSpecificationData,
      countryList,
    });
    setProductInfo({ productName: productDetails?.productName });
    setProductDetails(previewData);
    setProductDetailsLoader(false);
  };

  // Fetching product details
  const getProductDetails = async () => {
    setProductDetailsLoader(true);
    const productId = location?.state?.productId;
    const isRejectedProduct = location?.state?.isRejectedProduct || false;

    const businessId = sessionStorage.getItem("selectedBusinessId");

    if (location?.state?.isReviewProduct) {
      const data = await getReviewProductDetailsAPI({ businessId, productId });
      if (data?.details) {
        getProductData(data?.details);
      }
      return;
    }

    const data = await getViewProductDetailsAPI(productId);
    if (data?.product) {
      getProductData(data?.product);
    }
  };

  useEffect(() => {
    if (countryList) {
      getProductDetails();
    }
  }, [countryList]);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      dispatch(getCountryNames());
    }
  }, []);

  return (
    <Box sx={styles.container}>
      <DashboardLayout activeMenu="catalog">
        {activeBusinessInfoLoader ||
        productDetailsLoader ||
        countryListLoading ? (
          <ThreeDotLoader />
        ) : (
          <>
            {productDetails && (
              <ProductInfoPreview
                // noMt
                data={productDetails}
                productInfo={productInfo}
                categoryData={categoryInfo}
                hideActionBtn
                links={[
                  {
                    label: `Product Catalog`,
                    to: `/${businessName}/${
                      location?.state?.urlLink === "active"
                        ? "catalog"
                        : location?.state?.urlLink === "pendingApprovalNew"
                        ? "catalogPending"
                        : location?.state?.urlLink === "pendingApproval"
                        ? "catalogPendingExisting"
                        : location?.state?.urlLink === "rejectedNew"
                        ? "catalogRejected"
                        : location?.state?.urlLink === "rejected"
                        ? "catalogRejectedExisting"
                        : location?.state?.urlLink
                    }`,
                  },
                  { label: "Product Details", to: `#` },
                  { label: productInfo?.productName, to: `#` },
                ]}
                titleFrom={`Product Details`}
                title={productInfo?.productName}
                imageSelector="docURL"
                urlLink={location?.state?.urlLink}
              />
            )}
          </>
        )}
      </DashboardLayout>
    </Box>
  );
};

export default ProductPreviewNew;

const styles = {
  container: {
    backgroundColor: BGColor02,
  },
};
