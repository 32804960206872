import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import InfoCardHeader from "./InfoCardHeader";
import {
  blackShade05,
  greyColor6,
  greyColorM01,
  logoImgborder1,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import LabelValueText from "./LabelValueText";
import DonutChart from "../../../components/Charts/DonutChart";
import MobileInfoCardHeader from "../MObileInfoCards/MobileInfoCardHeader";

function InventoryInfoCard({
  allProducts,
  productData,
  onClick,
  onPriceUpdateClick,
}) {
  const [isChartEmpty, setIsChartEmpty] = useState(false);
  const [donutChartData, setDonutChartData] = useState([]);

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const prepareChartData = (
    avaibalePercentage,
    lowStockPercentage,
    outOfStockPercentage,
    notAvailable
  ) => {
    if (
      !avaibalePercentage &&
      !lowStockPercentage &&
      !outOfStockPercentage &&
      !notAvailable
    ) {
      const chartData = [{ x: "No Data", y: 100 }];
      setDonutChartData(chartData);
      setIsChartEmpty(true);
      return;
    }

    const chartData = [
      { x: "Available", y: avaibalePercentage },
      { x: "Low Stock", y: lowStockPercentage },
      { x: "Out of Stock", y: outOfStockPercentage },
      { x: "Not Selling", y: notAvailable },
    ];

    setDonutChartData(chartData);
    setIsChartEmpty(false);
  };

  useEffect(() => {
    if (productData) {
      prepareChartData(
        productData?.inStock || 0,
        productData?.lowStock || 0,
        productData?.outOfStock || 0,
        productData?.notAvailable || 0
      );
    } else {
      const chartData = [{ x: "No Data", y: 100 }];
      setDonutChartData(chartData);
      setIsChartEmpty(true);
      return;
    }

    return () => {};
  }, [productData]);

  return (
    <Box sx={styles.card}>
      {matches && (
        <>
          <Box>
            <Box px={{ md: "10px", xs: 0 }}>
              <InfoCardHeader
                title="Inventory"
                onManageClick={onClick}
                showSecondaryBtn
                btnText="Price Update"
                onSecondaryBtnClick={onPriceUpdateClick}
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap="12px"
            >
              <Box sx={styles.chartContainer}>
                <Box
                  width={{ xs: "70px", md: "60px" }}
                  height={{ xs: "70px", md: "60px" }}
                  // sx={{ m: { md: "-15px 0 0 -16px", xs: 0 } }}
                >
                  {donutChartData?.length > 0 && (
                    <DonutChart
                      data={donutChartData}
                      isChartEmpty={isChartEmpty}
                    />
                  )}
                </Box>
              </Box>

              <Box
                display={{ md: "none", xs: "none" }}
                alignItems="center"
                gap="18px"
                flexWrap="wrap"
                justifyContent="space-between"
              >
                <LegendRow color="#00b293" label="Available" />
                <LegendRow color="#fca549" label="Low Stock" />
                <LegendRow color="#f43c3c" label="Out of Stock" />
                <LegendRow color="#2d6fff" label="Not Selling" />
              </Box>
              <Grid container display={{ xs: "flex", md: "flex" }} width="100%">
                <Grid item xs={6} md={4}>
                  <LegendRow color="#00b293" label="Available" />
                </Grid>
                <Grid item xs={6} md={4}>
                  <LegendRow color="#fca549" label="Low Stock" />
                </Grid>
                <Grid item xs={6} md={4}>
                  <LegendRow color="#f43c3c" label="Out of Stock" />
                </Grid>
                <Grid item xs={6} md={4}>
                  <LegendRow color="#2d6fff" label="Not Selling" />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems={{ xs: "none", md: "center" }}
            flexDirection={{ md: "row", xs: "column" }}
            mt={-0.9}
          >
            <Box display="flex" alignItems="center" flex={1}>
              <LabelValueText
                label="AVAILABLE"
                // value={inventoryData?.avaiableProducts?.length || 0}
                value={productData?.inStock || 0}
                flex={1}
                hideBorder={!matches}
                valueSize={14}
              />

              <LabelValueText
                label="LOW STOCK"
                // value={inventoryData?.lowStockProducts?.length || 0}
                value={productData?.lowStock || 0}
                flex={1}
                hideBorder={!matches}
                valueSize={14}
              />
            </Box>
            <Box display="flex" alignItems="center" flex={1}>
              <LabelValueText
                label="OUT OF STOCK"
                // value={inventoryData?.outOfStockProducts?.length || 0}
                value={productData?.outOfStock || 0}
                flex={{ md: 1.1, xs: 1 }}
                hideBorder={!matches}
                valueSize={14}
              />

              <LabelValueText
                label="NOT SELLING"
                // value={inventoryData?.outOfStockProducts?.length || 0}
                value={productData?.notAvailable || 0}
                hideBorder
                valueSize={14}
              />
            </Box>
          </Box>
        </>
      )}
      {!matches && (
        <>
          <Box>
            <Box borderBottom={`1px solid #F5F7FC`} pb={1} mb={1}>
              <MobileInfoCardHeader
                title="Inventory"
                onManageClick={onClick}
                showSecondaryBtn
                btnText="Price Update"
                onSecondaryBtnClick={onPriceUpdateClick}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              // justifyContent="space-between"
              gap="25px"
            >
              <Box sx={styles.chartContainer}>
                <Box
                  width={{ xs: "70px", md: "60px" }}
                  height={{ xs: "70px", md: "60px" }}
                  // sx={{ m: { md: "-15px 0 0 -16px", xs: 0 } }}
                >
                  {donutChartData?.length > 0 && (
                    <DonutChart
                      data={donutChartData}
                      isChartEmpty={isChartEmpty}
                    />
                  )}
                </Box>
              </Box>

              <Box
                display="flex"
                //alignItems={{ xs: "none", md: "center" }}
                flexDirection={{ md: "row", xs: "column" }}
                width={"100%"}
              >
                <Box display="flex" alignItems="center" flex={1} gap="10px">
                  <MobileStatsCard
                    label="Available"
                    value={productData?.inStock || 0}
                    valueSize={14}
                    hideMobileBorder
                    legendDotcolor={"#00b293"}
                  />
                  <MobileStatsCard
                    label="Out of stock"
                    value={productData?.outOfStock || 0}
                    legendDotcolor={"#f43c3c"}
                    valueSize={14}
                    hideMobileBorder
                  />
                </Box>
                <Box display="flex" alignItems="center" flex={1} gap="10px">
                  <MobileStatsCard
                    label="Low stock"
                    value={productData?.lowStock || 0}
                    valueSize={14}
                    hideMobileBorder
                    legendDotcolor={"#fca549"}
                  />
                  <MobileStatsCard
                    label="Not Selling"
                    value={productData?.notAvailable || 0}
                    legendDotcolor={"#2d6fff"}
                    valueSize={14}
                    hideMobileBorder
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default InventoryInfoCard;

const LegendRow = ({ color, label }) => {
  return (
    <Box display="flex" alignItems="center" mb={0.7}>
      <Box sx={styles.legendDot(color)} />
      <Typography variant="body2" fontSize={12} fontWeight={400} color={color}>
        {label}
      </Typography>
    </Box>
  );
};
const MobileStatsCard = ({
  icon,
  label,
  value,
  color,
  bgColor,
  labelColor,
  valueSize,
  hideMobile,
  flex,
  hideMobileBorder,
  legendDotcolor,
}) => {
  return (
    <Box
      sx={{
        ...styles.cardMobile(flex),
        borderBottom: hideMobileBorder ? "" : `1px solid #F5F7FC`,
        // pb: 0.5,
        pt: 0.5,
      }}
    >
      <Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          alignContent={"center"}
          gap={1}
        >
          <Box sx={styles.legendDot(legendDotcolor)} />
          <Typography
            variant="body2"
            fontSize={{ xs: 10, md: 11.28 }}
            color={labelColor || greyColorM01}
            fontWeight={500}
            lineHeight="20px"
          >
            {label}
          </Typography>
        </Stack>

        <Typography
          component="h6"
          fontSize={{ xs: valueSize || 15, md: 15 }}
          color={color || blackShade05}
          fontWeight={600}
          ml={3}
        >
          {value}
        </Typography>
      </Stack>
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "13px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: "10px 15px",
    width: "100%",
  },
  cardMobile: (flex) => ({
    display: "flex",
    alignItems: "center",
    gap: 1,
    flex: flex || 1,
  }),
  iconContainer: (bgColor, hideMobile) => ({
    width: { md: "40px", xs: "30px" },
    height: { md: "40px", xs: "30px" },
    borderRadius: "8px",
    backgroundColor: bgColor,
    display: { md: "flex", xs: hideMobile ? "none" : "flex" },
    alignItems: "center",
    justifyContent: "center",
  }),
  icon: (color) => ({ fontSize: { md: 20, xs: 17 }, color: color }),
  chartContainer: {
    width: { xs: "70px", md: "60px" },
    height: { xs: "70px", md: "60px" },
    // overflow: "hidden",
  },

  legendDot: (color) => ({
    width: "8px",
    height: "8px",
    borderRadius: "5px",
    marginRight: "6px",
    backgroundColor: color || greyColor6,
  }),
};
