import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import FormInputBox from "../../../components/Input/FormInputBox";
import {
  addressBtnColor,
  blackColor,
  blackShade06,
  blackShade58,
  blueColor04,
  greyColor6,
  greyColor7,
  greyShade65,
  logoImgborder1,
  mobilePrimary,
  orangeBgColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import FilePickerInput from "../../../components/Input/FilePickerInput";
import AddItemsInputWithLabel from "../../../components/Input/AddItemsInputWithLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusinessCategory,
  getBusinessLookupTypes,
  getMyBusinessSummary,
} from "../../../reducers/businessOnboard/businessOnboardSlice";
import MultiSelectWithLabel from "../../../components/Input/MultiSelectWithLabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, useNavigate } from "react-router-dom";
import {
  clearViewMyBusinessData,
  viewMyBusinessData,
} from "../../../reducers/businessPreview/businessPreviewSlice";
import {
  addBusinessData,
  deleteItem,
  editBusinessData,
  getGeoPointApi,
  sendWhatsAppNotification,
  updateBanner,
  updateLogo,
} from "../../../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";
import {
  addBusinessDetails,
  editBusinessDetails,
} from "../../../utils/businessDataUtils";
import TextAreaComponent from "../../../components/Input/TextAreaComponent";
import { NEW_BANNERS_DND_DATA, saveMsg } from "../../../utils/data";
import { convertImagesToBase64 } from "../../../utils/imageCropUtils/imgUrlToBase64";
import NewBusinessBannerListDND from "../NewBusinessBannerListDND";
import { getUserDetails } from "../../../utils/validationUtils";
import { validateUserEmail } from "../../../utils/validationHelper";
import { getAddressInfoFromLatLong } from "../../../utils/geoLocationHelper";
import PickLocationBtn from "../../../components/Buttons/PickLocationBtn";
import PickAddressFromMapModal from "../../../components/PickAddress/PickAddressFromMapModal";
import ModalCard from "../../../components/ModalCard";
import AlertPopup from "../../../components/Alerts/AlertPopup";
import { isStringNotPresentInArray } from "../../../utils/helper";
import { stickyHeader } from "../../../utils/styles";

function DashboardDetailsForm({ goBack }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const userDetails = getUserDetails();

  const [openMap, setOpenMap] = useState({
    show: false,
    userLat: "",
    userLong: "",
  });
  const [isFormError, setIsFormError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const [businessDetails, setBusinessDetails] = useState({
    image: "",
    banners: NEW_BANNERS_DND_DATA,
    description: "",
    category: [],
    fssaiNumber: "",
    mainProducts: [],
    errors: {},
  });

  const [gstDetails, setGstDetails] = useState({
    gstNumber: "",
    registeredBusinessName: "",
    panNumber: "",
    errors: {},
  });

  const [addressDetails, setAddressDetails] = useState({
    pinCode: "",
    address: "",
    landmark: "",
    city: "",
    state: "",
    locality: "",
    sublocality: "",
    lat: "",
    lng: "",
    errors: {},
  });

  const [storeInfoDetails, setStoreInfoDetails] = useState({
    name: "",
    type: "",
    errors: {},
  });

  const [ownerDetails, setOwnerDetails] = useState({
    name: userDetails?.account?.name || "",
    phone: userDetails?.account?.phonenumber?.replace("+91", "") || "",
    email: userDetails?.account?.email || "",
    alternateEmail: "",
    alternatePhone1: "",
    alternatePhone2: "",
    errors: {},
  });

  const [moreProducts, setMoreProducts] = useState([""]);
  const [businessType, setBusinessType] = useState([]);
  const [businessType1, setBusinessType1] = useState([]);

  const [logoChange, setLogoChange] = useState(false);

  const [base64Banners, setBase64Banners] = useState();
  const [deletedBannerIndexList, setDeletedBannerIndexList] = useState([]);

  const [showAddressSection, setShowAddressSection] = useState(false);
  const [showBusinessSection, setShowBusinessSection] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { businessCategory, businessLookupTypes } = useSelector(
    (state) => state.businessOnboard
  );

  const { myBusinessData } = useSelector((state) => state.businessPreview);
  const { myBusinessSummary } = useSelector((state) => state.businessOnboard);

  // Convert Banner Images into Base64 String and Prepare data for DND
  const convertBannertoBase64Str = async (banners) => {
    const base64StringList = await convertImagesToBase64(banners);
    console.log("banners_base64StringList:", base64StringList);

    const bannerPhotoKeys = [
      { id: 0, key: "bannerImgOne" },
      { id: 1, key: "bannerImgTwo" },
      { id: 2, key: "bannerImgThree" },
      { id: 3, key: "bannerImgFour" },
    ];

    const newBase64Banners = [];

    for (let index = 0; index < 4; index++) {
      if (index < banners?.length) {
        newBase64Banners.push({
          ...banners[index],
          base64String: base64StringList?.[index] || "",
          id: index,
          key: bannerPhotoKeys[index]?.key,
          initialIndex: index,
          update: false,
          // currentIndex: index,
        });
      } else {
        newBase64Banners.push({
          base64String: "",
          id: index,
          key: bannerPhotoKeys[index]?.key,
          initialIndex: index,
          // currentIndex: index,
        });
      }
    }

    console.log("newBase64Banners:", newBase64Banners);

    // setBase64Banners(base64StringList);
    return newBase64Banners;
  };

  const prepareBannerUpdateData = (banners, deletedBanners) => {
    const updateBannerList = [];
    const deleteBannerList = [...deletedBanners];

    // Preparing update banner list
    banners.forEach((element, index) => {
      if (element?.update || element?.initialIndex !== index) {
        const isBannerPresent = updateBannerList?.filter(
          (item) => item?.key === element?.key
        );
        if (isBannerPresent?.length === 0 && element?.base64String) {
          updateBannerList.push({ ...element, dndIndex: index });
        }
      }
    });

    // Preparing banner list to detele
    banners.forEach((element, index) => {
      if (element?.initialIndex !== index) {
        const isBannerPresent = deleteBannerList?.filter(
          (item) => item?.key === element?.key
        );
        if (isBannerPresent?.length === 0 && element?.docType) {
          deleteBannerList.push(element);
        }
      }
    });

    console.log("prepareBannerUpdateData:", {
      updateBannerList,
      deleteBannerList,
    });

    return {
      updateBannerList,
      deleteBannerList,
    };
  };

  const updateBannerImages = async (updateList, deleteList, businessId) => {
    let deleteData = "";

    deleteList?.forEach((element, index) => {
      deleteData =
        index == 0
          ? deleteData + "photoTypes=" + element?.docType
          : deleteData + "&photoTypes=" + element?.docType;
    });

    console.log("updateBannerImages_deleteData:", deleteData);

    if (deleteData) {
      const deletedRes = await deleteItem(deleteData, businessId);
      console.log("updateBannerImages_deletedRess:", deletedRes);
    }

    const updateData = [];

    updateList?.forEach((element) => {
      let randomNumber = Math.floor(100000 + Math.random() * 900000);
      let id = Number(element?.dndIndex) + 1;
      updateData.push({
        replicate: false,
        extension: "jpeg",
        name: `business_pic_${randomNumber}`,
        mimeType: "image/jpeg",
        type: `bpic${id}-${randomNumber}`,
        bytesString: element?.base64String,
      });
    });

    console.log("updateBannerImages_updateData:", updateData);

    if (updateData?.length > 0) {
      const updateRes = await updateBanner(
        { businessDocumentsURL: updateData },
        businessId
      );
      console.log("updateBannerImages_updateRes:", updateRes);
      if (updateRes.error) {
        setShowAlert(true);
        throw "ERROR FOUND";
      }
    }
  };

  useEffect(() => {
    console.log(location.state);
    dispatch(getBusinessCategory());
    dispatch(getBusinessLookupTypes());
    if (location?.state?.businessId) {
      dispatch(viewMyBusinessData(location?.state?.businessId));
    }
    if (location?.state?.data) {
      setGstDetails(location?.state.data?.gstDetails);
      setAddressDetails(location?.state.data?.addressDetails);
      let arr = [];

      location?.state?.data?.storeInfoDetails?.type?.forEach((element) => {
        arr?.push(element?.name);
      });

      setBusinessType1(arr);
      setStoreInfoDetails(location?.state?.data?.storeInfoDetails);
    }

    if (!myBusinessSummary) {
      dispatch(getMyBusinessSummary());
    }
    return () => {
      sessionStorage.removeItem("selectedBusinessId");
      dispatch(clearViewMyBusinessData());
    };
  }, []);

  useEffect(async () => {
    console.log("myBusinessDatamyBusinessData", myBusinessData);
    if (myBusinessData) {
      const pincode = myBusinessData?.viewBusiness?.businessArea?.filter(
        (item) => item.code == "pincode"
      );
      const address = myBusinessData?.viewBusiness?.businessArea?.filter(
        (item) => item.code == "addressline one"
      );
      const landmark = myBusinessData?.viewBusiness?.businessArea?.filter(
        (item) => item.code == "locality"
      );
      const city = myBusinessData?.viewBusiness?.businessArea?.filter(
        (item) => item.code == "City"
      );
      const state = myBusinessData?.viewBusiness?.businessArea?.filter(
        (item) => item.code == "State"
      );
      const locality = myBusinessData?.viewBusiness?.businessArea?.filter(
        (item) => item.code == "locality"
      );
      const sublocality = myBusinessData?.viewBusiness?.businessArea?.filter(
        (item) => item.code == "sublocality"
      );

      let arr = [];

      myBusinessData?.viewBusiness?.businessTypes?.forEach((element) => {
        arr?.push(element?.name);
      });

      setBusinessType1(arr);

      let arr1 = [];

      myBusinessData?.viewBusiness?.businessCategories?.forEach((element) => {
        arr1?.push(element?.name);
      });

      setBusinessType(arr1);

      let arrBanner = ["", "", "", ""];

      myBusinessData?.viewBusiness?.documentsURL?.forEach((element, index) => {
        arrBanner[index] = element?.docURL;
        // arrBanner?.push(element?.docURL);
      });

      let mainProductsArr = [];

      myBusinessData?.viewBusiness?.mainProducts?.forEach((element) => {
        mainProductsArr?.push(element?.productName);
      });

      setGstDetails({
        ...gstDetails,
        gstNumber: myBusinessData?.viewBusiness?.gstnumber,
        registeredBusinessName: myBusinessData?.viewBusiness?.aliasName,
        panNumber: myBusinessData?.viewBusiness?.pannumber,
      });

      setAddressDetails({
        ...addressDetails,
        pinCode: pincode?.[0]?.value,
        address: address?.[0]?.value,
        landmark: landmark?.[0]?.value,
        city: city?.[0]?.value,
        state: state?.[0]?.value,
        locality: locality?.[0]?.value,
        sublocality: sublocality?.[0]?.value,
        lat: myBusinessData?.viewBusiness?.loc?.y,
        lng: myBusinessData?.viewBusiness?.loc?.x,
      });

      setStoreInfoDetails({
        ...storeInfoDetails,
        name: myBusinessData?.viewBusiness?.name,
        type: myBusinessData?.viewBusiness?.businessTypes,
      });

      const base64Banners = await convertBannertoBase64Str(
        myBusinessData?.viewBusiness?.documentsURL
      );

      setBusinessDetails({
        ...businessDetails,
        description: myBusinessData?.viewBusiness?.description || "",
        category: myBusinessData?.viewBusiness?.businessCategories || [],
        fssaiNumber: myBusinessData?.viewBusiness?.rewardCurrency || "",
        image: myBusinessData?.viewBusiness?.logoURL || "",
        // banners: arrBanner || [],
        banners: base64Banners || [],
        mainProducts: mainProductsArr || [],
      });

      setMoreProducts(mainProductsArr);

      // getting Emails and contact numbers
      let alternateMail = "";
      let primaryMail = "";

      if (myBusinessData?.viewBusiness?.businessMailingAddress) {
        const mailList =
          myBusinessData?.viewBusiness?.businessMailingAddress?.split(",");

        console.log("mailList:", mailList);
        primaryMail = mailList?.[0] || "";
        alternateMail = mailList?.[1] || "";
      }

      const contactNumbers = myBusinessData?.viewBusiness?.contactNos;

      setOwnerDetails({
        ...ownerDetails,
        name: myBusinessData?.viewBusiness?.owner?.name,
        // email: myBusinessData?.viewBusiness?.owner?.email,
        email: primaryMail || myBusinessData?.viewBusiness?.owner?.email,
        phone:
          contactNumbers?.[0]?.number?.replace("+91", "") ||
          myBusinessData?.viewBusiness?.owner?.phone?.replace("+91", ""),
        alternateEmail: alternateMail || "",
        alternatePhone1: contactNumbers?.[1]?.number?.replace("+91", "") || "",
        alternatePhone2: contactNumbers?.[2]?.number?.replace("+91", "") || "",
      });
    }
  }, [myBusinessData]);

  const handleChange = (value) => {
    setBusinessType(value);
    const filteredArray = businessCategory?.filter((type) =>
      value.includes(type.name)
    );
    handleValueChange(
      businessDetails,
      setBusinessDetails,
      "category",
      filteredArray
    );
  };

  const handleBusinessTypeChange = (value) => {
    setBusinessType1(value);
    const filteredArray = businessLookupTypes?.filter((type) =>
      value.includes(type.name)
    );
    handleValueChange(
      storeInfoDetails,
      setStoreInfoDetails,
      "type",
      filteredArray
    );
  };

  const handleValueChange = (state, setState, name, value) => {
    let errors = { ...state?.errors };
    if (value) {
      errors = { ...errors, [name]: "" };
    }

    setState({
      ...state,
      [name]: value,
      errors,
    });
  };

  const handleSave = async () => {
    setIsSaveLoading(true);
    let isStoreError = false;
    const storeErrors = {};

    // Store Info Validation
    if (!storeInfoDetails?.name) {
      storeErrors.name = "Please enter business name";
      isStoreError = true;
    }

    if (!storeInfoDetails?.type) {
      storeErrors.type = "Please select business type";
      isStoreError = true;
    }

    if (storeInfoDetails?.type?.length == 0) {
      storeErrors.type = "Please select business type";
      isStoreError = true;
    }

    if (isStoreError) {
      setStoreInfoDetails({ ...storeInfoDetails, errors: storeErrors });
      setIsSaveLoading(false);
      return;
    }

    if (location.state.businessId) {
      const businessData = editBusinessDetails({
        gstDetails,
        addressDetails,
        storeInfoDetails,
        businessDetails,
        businessData: myBusinessData?.viewBusiness,
        ownerDetails: ownerDetails,
        maintainDraft: true,
      });

      console.log(businessData, location?.state?.businessId);

      const updateBannerData = prepareBannerUpdateData(
        businessDetails?.banners,
        deletedBannerIndexList
      );

      await updateBannerImages(
        updateBannerData?.updateBannerList,
        updateBannerData?.deleteBannerList,
        location?.state?.businessId
      );

      editBusinessData(businessData, location?.state?.businessId).then(
        async (res) => {
          if (res.error) {
            console.log("ERROR FOUND", res);
            setShowAlert(true);
            throw "ERROR FOUND";
          }
          if (logoChange) {
            const logodata = {
              replicate: false,
              extension: "jpeg",
              name: storeInfoDetails?.name + "1.jpeg",
              mimeType: "image/jpeg",
              type: "logo",
              bytesString: businessDetails?.image,
            };

            updateLogo(logodata, location?.state?.businessId).then((res) => {
              console.log(res);
              setIsSaveLoading(false);
              sendWhatsAppNotificationData();
            });
          } else {
            console.log(res);
            setIsSaveLoading(false);
            sendWhatsAppNotificationData();
          }
        }
      );
    }

    if (location.state.data) {
      const businessData = addBusinessDetails({
        gstDetails,
        addressDetails,
        storeInfoDetails,
        businessDetails,
        ownerDetails,
      });
      console.log(businessData);
      addBusinessData(businessData).then((res) => {
        if (res.error) {
          console.log("ERROR FOUND", res);
          setShowAlert(true);
          throw "ERROR FOUND";
        }
        console.log(res);
        setIsSaveLoading(false);
        // navigate("/businessListing");
        sendWhatsAppNotificationData();
      });
    }
  };

  const sendWhatsAppNotificationData = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const data = {
      businessId: businessId || "",
      code: "B001",
      businessOwnerName: userDetails?.account?.name || "",
      businessName: gstDetails?.registeredBusinessName || "",
      businessOwnerPhoneNumber: ownerDetails?.phone
        ? "+91" + ownerDetails?.phone
        : "",
    };
    console.log("Whatsapp", data);
    const res = await sendWhatsAppNotification(data, businessId);
    if (res) {
      navigate("/businessListing");
    }
  };

  const handlePinChange = async (value) => {
    if (value?.length === 6) {
      const responce = await getGeoPointApi(value);

      const pinCodeErr = isStringNotPresentInArray(
        responce?.results?.[0]?.address_components,
        "postal_code"
      );
      console.log("pinCodeErrpinCodeErr", pinCodeErr);
      if (pinCodeErr) {
        console.log("Hit");
        setAddressDetails({
          ...addressDetails,
          pinCode: value,
          city: "",
          state: "",
          landmark: "",
          address: "",
          errors: {
            ...addressDetails?.errors,
            pinCode: "Invalid pincode",
          },
        });
        return;
      }
      const city = responce?.results?.[0]?.address_components?.[1]?.long_name;

      const state =
        responce?.results?.[0]?.address_components?.[
          responce?.results?.[0]?.address_components?.length - 2
        ]?.long_name;

      setAddressDetails({
        ...addressDetails,
        city: city || "",
        state: state || "",
        pinCode: value,
        landmark: "",
        locality: city || "",
        sublocality: "",
        lat: responce?.results?.[0]?.geometry?.location?.lat || "",
        lng: responce?.results?.[0]?.geometry?.location?.lng || "",
        errors: { ...addressDetails?.errors, city: "", state: "", pinCode: "" },
      });

      setOpenMap({
        show: true,
        userLat: responce?.results?.[0]?.geometry?.location?.lat || "",
        userLong: responce?.results?.[0]?.geometry?.location?.lng || "",
      });
    }
  };

  const handleSubmit = async () => {
    let isAddressError = false;
    const addressErrors = {};

    let isGSTError = false;
    const gstErrors = {};

    let isStoreError = false;
    const storeErrors = {};

    let isOwnerError = false;
    const ownerErrors = {};

    let isError = false;
    const errors = {};

    // Validating Address
    if (!addressDetails?.pinCode) {
      addressErrors.pinCode = "Please enter pin code";
      isAddressError = true;
    }

    if (!addressDetails?.address) {
      addressErrors.address = "Please enter address";
      isAddressError = true;
    }

    if (!addressDetails?.city) {
      addressErrors.city = "Please enter city";
      isAddressError = true;
    }

    if (!addressDetails?.state) {
      addressErrors.state = "Please enter state";
      isAddressError = true;
    }

    // Validating GST
    if (!gstDetails?.gstNumber) {
      gstErrors.gstNumber = "Please enter GST number";
      isGSTError = true;
    }
    if (
      !/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
        gstDetails?.gstNumber
      )
    ) {
      gstErrors.gstNumber = "Please enter valid GST number";
      isGSTError = true;
    }

    if (!gstDetails?.registeredBusinessName) {
      gstErrors.registeredBusinessName =
        "Please enter registered business name";
      isGSTError = true;
    }

    if (!gstDetails?.panNumber) {
      gstErrors.panNumber = "Please enter pan number";
      isGSTError = true;
    }

    if (!/[A-Z]{5}\d{4}[A-Z]{1}/.test(gstDetails?.panNumber)) {
      gstErrors.panNumber = "Please enter valid PAN number";
      isGSTError = true;
    }

    // Store Info Validation
    if (!storeInfoDetails?.name) {
      storeErrors.name = "Please enter business name";
      isStoreError = true;
    }

    if (!storeInfoDetails?.type) {
      storeErrors.type = "Please select business type";
      isStoreError = true;
    }

    if (storeInfoDetails?.type?.length == 0) {
      storeErrors.type = "Please select business type";
      isStoreError = true;
    }

    // Validating Business Data
    if (
      !businessDetails?.image ||
      businessDetails?.image?.charAt(businessDetails?.image?.length - 1) == "."
    ) {
      errors.image = "Add a logo";
      isError = true;
    }

    const isBanners = businessDetails?.banners?.filter(
      (item) => item?.base64String !== ""
    );

    console.log("isBanners", isBanners);
    console.log("isBanners+banres", businessDetails?.banners);

    if (isBanners?.length === 0) {
      errors.banners = "Add atleast one banner";
      isError = true;
    }

    if (!businessDetails?.category || businessDetails?.category?.length == 0) {
      errors.category = "Select business category";
      isError = true;
    }

    if (
      !businessDetails?.mainProducts ||
      businessDetails?.mainProducts?.length == 0 ||
      (businessDetails?.mainProducts?.length === 1 &&
        businessDetails?.mainProducts?.[0] === "")
    ) {
      errors.mainProducts = "Add main Products";
      isError = true;
    }

    if (
      !businessDetails?.description ||
      businessDetails?.description?.length == 0
    ) {
      errors.description = "Enter description";
      isError = true;
    }

    if (businessDetails?.fssaiNumber?.length > 0) {
      const fssaiRegex = /^\d{14}$/;
      if (!fssaiRegex.test(businessDetails?.fssaiNumber)) {
        errors.fssaiNumber = "Enter a valid 14 digit FSSAI number";
        isError = true;
      }
    }

    if (!ownerDetails?.name) {
      ownerErrors.name = "Enter business owner name";
      isOwnerError = true;
    }

    const isEmailValid = validateUserEmail(ownerDetails?.email);

    console.log("isEmailValid", isEmailValid);

    if (!ownerDetails?.email || !isEmailValid) {
      ownerErrors.email = "Enter primary email";
      isOwnerError = true;
    }

    if (ownerDetails?.phone?.length !== 10) {
      ownerErrors.phone = "Enter primary phone number";
      isOwnerError = true;
    }

    if (ownerDetails?.alternatePhone1?.length !== 10) {
      ownerErrors.alternatePhone1 = "Enter alternate phone number";
      isOwnerError = true;
    }

    console.log("hit", businessDetails, isError, errors);

    if (
      isAddressError ||
      isGSTError ||
      isStoreError ||
      isOwnerError ||
      isError
    ) {
      setAddressDetails({ ...addressDetails, errors: addressErrors });
      setGstDetails({ ...gstDetails, errors: gstErrors });
      setStoreInfoDetails({ ...storeInfoDetails, errors: storeErrors });
      setBusinessDetails({ ...businessDetails, errors });
      setOwnerDetails({ ...ownerDetails, errors: ownerErrors });
      setIsFormError(true);
      return;
    } else {
      setIsFormError(false);

      if (location.state.businessId) {
        const businessData = editBusinessDetails({
          gstDetails,
          addressDetails,
          storeInfoDetails,
          businessDetails,
          businessData: myBusinessData?.viewBusiness,
          ownerDetails: ownerDetails,
          maintainDraft: true,
        });
        console.log(businessData, location?.state?.businessId);

        const updateBannerData = prepareBannerUpdateData(
          businessDetails?.banners,
          deletedBannerIndexList
        );

        await updateBannerImages(
          updateBannerData?.updateBannerList,
          updateBannerData?.deleteBannerList,
          location?.state?.businessId
        );

        editBusinessData(businessData, location?.state?.businessId).then(
          (res) => {
            if (res.error) {
              console.log("ERROR FOUND", res);
              setShowAlert(true);
              throw "ERROR FOUND";
            }
            if (logoChange) {
              const logodata = {
                replicate: false,
                extension: "jpeg",
                name: storeInfoDetails?.name + "1.jpeg",
                mimeType: "image/jpeg",
                type: "logo",
                bytesString: businessDetails?.image,
              };
              updateLogo(logodata, location?.state?.businessId).then((res) => {
                console.log(res);
                const newBanners = businessDetails?.banners?.filter(
                  (item) => item?.deleted === false
                );

                navigate("/addBusinessPreview", {
                  state: {
                    businessDetails: {
                      ...businessDetails,
                      banners: newBanners,
                    },
                    gstDetails,
                    addressDetails,
                    storeInfoDetails,
                    ownerDetails: ownerDetails,
                    businessData: myBusinessData?.viewBusiness,
                    id: location.state.businessId,
                  },
                });
                // navigate("/businessListing");
              });
            } else {
              console.log(res);

              const newBanners = businessDetails?.banners?.filter(
                (item) => item?.deleted === false
              );

              navigate("/addBusinessPreview", {
                state: {
                  businessDetails: { ...businessDetails, banners: newBanners },
                  gstDetails,
                  addressDetails,
                  storeInfoDetails,
                  ownerDetails: ownerDetails,
                  businessData: myBusinessData?.viewBusiness,
                  id: location.state.businessId,
                },
              });
            }
          }
        );
      }

      if (location.state.data) {
        const businessData = addBusinessDetails({
          gstDetails,
          addressDetails,
          storeInfoDetails,
          businessDetails,
          ownerDetails,
        });
        console.log(businessData);
        console.log("businessDetails:", businessDetails);

        setIsLoading(true);

        addBusinessData(businessData).then((res) => {
          if (res.error) {
            console.log("ERROR FOUND", res);
            setShowAlert(true);
            throw "ERROR FOUND";
          }

          console.log(res);

          const banners = businessDetails?.banners?.filter(
            (item) => item?.base64String !== ""
          );

          setIsLoading(false);
          navigate("/addBusinessPreview", {
            state: {
              businessDetails: { ...businessDetails, banners },
              gstDetails,
              addressDetails,
              storeInfoDetails,
              ownerDetails: ownerDetails,
              businessData: myBusinessData?.viewBusiness,
              id: res?.token,
            },
          });
        });
      }
    }
  };

  // Get user current location address
  const handleChooseCurrentLocation = () => {
    setOpenMap({
      show: true,
      userLat: localStorage.getItem("lat"),
      userLong: localStorage.getItem("long"),
    });
    // const addressInfo = await getAddressInfoFromLatLong();

    // // postalCode, state, city, address
    // const {
    //   postalCode,
    //   state,
    //   city,
    //   address,
    //   lat,
    //   lng,
    //   locality,
    //   sublocality,
    // } = addressInfo;

    // if (postalCode && state && city && address) {
    //   setAddressDetails({
    //     ...addressDetails,
    //     city: city || "",
    //     state: state || "",
    //     pinCode: postalCode,
    //     address: address || "",
    //     lat: lat || "",
    //     lng: lng || "",
    //     landmark: locality || "",
    //     locality: locality || "",
    //     sublocality: sublocality || "",
    //     errors: {
    //       ...addressDetails?.errors,
    //       city: "",
    //       state: "",
    //       pinCode: "",
    //       address: "",
    //     },
    //   });
    // }
  };

  // autoFill user selected address from map
  const handlePickLocationConfirm = (userAddress) => {
    console.log("userAddressuserAddress", userAddress);
    if (userAddress) {
      setAddressDetails({
        ...addressDetails,
        city: userAddress?.city || "",
        state: userAddress?.state || "",
        pinCode: userAddress?.postalCode,
        address: userAddress?.address?.trim() || "",
        lat: userAddress?.lat || "",
        lng: userAddress?.lng || "",
        landmark: userAddress?.locality || "",
        locality: userAddress?.locality || "",
        sublocality: userAddress?.sublocality || "",
        errors: {
          ...addressDetails?.errors,
          city: "",
          state: "",
          pinCode: "",
          address: "",
        },
      });
    }

    setOpenMap({
      show: false,
      userLat: "",
      userLong: "",
    });
  };

  const handleCancel = () => {
    if (myBusinessSummary?.details?.length === 0) {
      navigate("/");
    } else {
      navigate("/businessListing");
    }
  };

  return (
    <>
      <ModalCard
        open={showAlert}
        handleClose={() => {
          // setShowAlert(false);
        }}
        width="48%"
      >
        <AlertPopup
          text={`Sorry we have encountered a technical issue. Please retry again.`}
          onClick={() => {
            navigate(`/businessListing`);
          }}
        />
      </ModalCard>
      {matches && (
        <Box sx={{ ...stickyHeader, top: "45px", pb: 1.5 }}>
          <Typography
            component="h3"
            fontSize={{ xs: 15, md: 18 }}
            color={blackShade58}
            fontWeight={600}
            lineHeight="30px"
          >
            New Business Registration
          </Typography>
        </Box>
      )}

      <Box mt={{ xs: 2, md: 0 }}>
        <Grid
          container
          display="flex"
          justifyContent={"space-between"}
          px={"20"}
        >
          <Grid item md={5.9} sx={styles.card} xs={12}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                component="h3"
                fontSize={{ xs: 14, md: 15.5 }}
                color={blackColor}
                fontWeight={600}
                // mb={{
                //   md: "14px !important",
                //   xs: "0px",
                // }}
              >
                Business Detail
              </Typography>
              <ExpandMoreIcon
                sx={{
                  fontSize: "24px",
                  cursor: "pointer",
                  display: { md: "none", xs: "block" },
                }}
                onClick={() => setShowBusinessSection(!showBusinessSection)}
              />
            </Box>

            <Box
              display={{
                md: "block",
                xs: showBusinessSection ? "block" : "none",
              }}
              mt={{ md: "14px", xs: "5px" }}
            >
              <Grid
                container
                rowSpacing={{ xs: 0.5, md: 0 }}
                columnSpacing={{ md: 3, xs: 2 }}
              >
                <Grid item xs={12} md={12}>
                  <FormInputBox
                    label={"Brand Name"}
                    value={storeInfoDetails?.name}
                    onChange={({ target }) => {
                      const value = target.value?.replace(
                        /[^a-zA-Z0-9 -]/g,
                        ""
                      );
                      handleValueChange(
                        storeInfoDetails,
                        setStoreInfoDetails,
                        "name",
                        value
                      );
                    }}
                    maxLength={50}
                    required
                    error={storeInfoDetails?.errors?.name}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormInputBox
                    label={
                      "Registered Business Name (as per GST, for invoicing)"
                    }
                    mobilelabel={"Registered Business Name"}
                    mobilelabel1={"(as per GST, for invoicing)"}
                    value={gstDetails?.registeredBusinessName}
                    onChange={({ target }) => {
                      handleValueChange(
                        gstDetails,
                        setGstDetails,
                        "registeredBusinessName",
                        target.value
                      );
                    }}
                    maxLength={100}
                    required
                    error={gstDetails?.errors?.registeredBusinessName}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormInputBox
                    label={"GST Number"}
                    value={gstDetails?.gstNumber}
                    onChange={({ target }) => {
                      const value = target.value
                        .replace(/[^a-zA-Z0-9]/g, "")
                        .toUpperCase();

                      if (value.length == 15) {
                        setGstDetails({
                          ...gstDetails,
                          panNumber: value.slice(2, 12),
                          gstNumber: value,
                          errors: { ...gstDetails.errors, panNumber: "" },
                        });
                      } else {
                        handleValueChange(
                          gstDetails,
                          setGstDetails,
                          "gstNumber",
                          value
                        );
                      }
                    }}
                    error={gstDetails?.errors?.gstNumber}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormInputBox
                    label={"PAN Number"}
                    value={gstDetails?.panNumber}
                    onChange={({ target }) => {
                      const value = target.value
                        .replace(/[^a-zA-Z0-9]/g, "")
                        .toUpperCase();
                      handleValueChange(
                        gstDetails,
                        setGstDetails,
                        "panNumber",
                        value
                      );
                    }}
                    error={gstDetails?.errors?.panNumber}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <MultiSelectWithLabel
                    label="Business Type"
                    value={businessType1}
                    onValueChange={handleBusinessTypeChange}
                    menuList={businessLookupTypes}
                    required
                    // error={storeInfoDetails?.errors?.type}
                    error={storeInfoDetails?.errors?.type}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item md={5.9} xs={12} sx={styles.card}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                component="h3"
                fontSize={{ xs: 14, md: 15.5 }}
                color={blackColor}
                fontWeight={600}
                // mb={{
                //   md: "14px !important",
                //   xs: showAddressSection ? "10px" : "0px",
                // }}
              >
                Address Detail
              </Typography>
              <ExpandMoreIcon
                sx={{
                  fontSize: "24px",
                  cursor: "pointer",
                  display: { md: "none", xs: "block" },
                }}
                onClick={() => setShowAddressSection(!showAddressSection)}
              />
            </Box>
            <Box
              display={{
                md: "block",
                xs: showAddressSection ? "block" : "none",
              }}
              mt={{ md: "14px", xs: "5px" }}
            >
              <PickAddressFromMapModal
                isOpen={openMap.show}
                setIsOpen={setOpenMap}
                onConfirm={handlePickLocationConfirm}
                userLat={openMap.userLat}
                userLong={openMap.userLong}
              />

              <Grid container rowSpacing={{ xs: 0, md: 0 }} columnSpacing={2}>
                <Grid item md={4}>
                  <FormInputBox
                    label={"Pincode"}
                    value={addressDetails?.pinCode}
                    onChange={({ target }) => {
                      const value = target.value.replace(
                        /[a-zA-Z]|[^\w\s]/gi,
                        ""
                      );
                      handleValueChange(
                        addressDetails,
                        setAddressDetails,
                        "pinCode",
                        value
                      );
                      if (value.length >= 6) handlePinChange(value);
                    }}
                    required
                    error={addressDetails?.errors?.pinCode}
                  />
                </Grid>

                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: { xs: 1, md: "none" },
                  }}
                >
                  <PickLocationBtn
                    onChooseLocation={handleChooseCurrentLocation}
                    onPickNearLocation={() => {
                      setOpenMap(true);
                    }}
                    bgColor={whiteColor}
                    textColor={matches ? blueColor04 : mobilePrimary}
                  />
                </Grid>

                <Grid item md={4}>
                  <FormInputBox
                    label={"City"}
                    value={addressDetails?.city}
                    required
                    error={addressDetails?.errors?.city}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <FormInputBox
                    label={"State"}
                    value={addressDetails?.state}
                    required
                    error={addressDetails?.errors?.state}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormInputBox
                    label={"Address"}
                    value={addressDetails?.address}
                    onChange={({ target }) => {
                      handleValueChange(
                        addressDetails,
                        setAddressDetails,
                        "address",
                        target.value
                      );
                    }}
                    required
                    error={addressDetails?.errors?.address}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormInputBox
                    label={"Landmark"}
                    value={addressDetails?.landmark}
                    onChange={({ target }) => {
                      handleValueChange(
                        addressDetails,
                        setAddressDetails,
                        "landmark",
                        target.value
                      );
                    }}
                  />
                </Grid>
              </Grid>

              <Box sx={styles.infoBox}>
                <Typography
                  textAlign={"center"}
                  fontWeight={500}
                  fontSize={13}
                  color={blackShade58}
                  lineHeight={{ md: "22px", xs: "20px" }}
                >
                  Please make sure the above address is accurate as the
                  deliveries will be picked up from this location
                </Typography>
              </Box>
            </Box>
            {/* <InfoCard title="Address Detail" data={ADDRESS_INFO} /> */}
          </Grid>
        </Grid>
      </Box>

      <Typography
        fontSize={{ md: 14, xs: 13 }}
        fontWeight={400}
        color={greyColor7}
        lineHeight={{ md: "20px", xs: "20px" }}
        mb={{ md: "20px", xs: "15px" }}
      >
        If you do not have the below details handy, please SAVE & EXIT. You may
        login back and complete the registration. Details entered here will be
        stored as ‘Draft’.
      </Typography>

      <Box sx={styles.card}>
        {/* <DashboardFormHeader title={storeInfoDetails?.name} /> */}

        <Grid
          container
          columnSpacing={{ xs: 0, md: 5 }}
          rowSpacing={{ xs: 2, md: 0 }}
        >
          {/* {businessDetails.image} */}
          <Grid item xs={12} md={2}>
            <>
              <Typography
                variant="h3"
                fontSize={13}
                color={greyColor6}
                fontWeight={400}
                mb={{ md: "18px !important", xs: "5px !important" }}
                sx={{ display: "inline" }}
              >
                Business Logo{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{ display: "inline", color: "#f00" }}
              >
                *
              </Typography>
              <Box display={{ xs: "block", md: "none" }}>
                <Typography
                  variant="span"
                  fontSize={11}
                  color={greyColor6}
                  fontWeight={400}
                  mt={"0px !important"}
                >
                  75 x 75 px (1:1, Size: 10-20 Kb)
                </Typography>
              </Box>
              {/* {required ? (
                
              ) : null} */}
            </>
            <FilePickerInput
              required
              // subText1="75 x 75 pixels "
              // subText2="(Square 1:1 , size : 10 - 20 Kb)"
              ratio={1 / 1}
              setLogoChange={(data) => setLogoChange(data)}
              setCropedImage={(data) =>
                handleValueChange(
                  businessDetails,
                  setBusinessDetails,
                  "image",
                  data
                )
              }
              hideLabel
              onDelete={(data) =>
                handleValueChange(
                  businessDetails,
                  setBusinessDetails,
                  "image",
                  data
                )
              }
              cropedimage={businessDetails.image}
            />

            <Box mt={2.1} display={{ md: "block", xs: "none" }}>
              <Typography
                variant="body1"
                fontSize={12}
                color={greyColor6}
                fontWeight={400}
                mt={"0px !important"}
                lineHeight="18px !important"
              >
                75 x 75 px (1:1, Size: 10-20 Kb)
              </Typography>

              <Typography
                variant="span"
                fontSize={12}
                color={"red"}
                fontWeight={500}
                mt={"0px !important"}
                lineHeight="18px !important"
              >
                {businessDetails?.errors?.image}
              </Typography>
            </Box>
          </Grid>

          <Grid item md={10} xs={12}>
            <>
              <Typography
                variant="h3"
                fontSize={13}
                color={greyColor6}
                fontWeight={400}
                mb={{ md: "18px !important", xs: "5px !important" }}
                sx={{ display: "inline" }}
              >
                Banner Images{" "}
              </Typography>

              <Typography
                variant="h3"
                fontSize={11}
                color={greyShade65}
                fontWeight={400}
                mb={{ md: "18px !important", xs: "5px !important" }}
                sx={{ display: "inline" }}
              >
                ( Min 1 & Max 4 Images){" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{ display: "inline", color: "#f00" }}
              >
                *
              </Typography>
              <Box display={{ xs: "block", md: "none" }}>
                <Typography
                  variant="span"
                  fontSize={11}
                  color={greyColor6}
                  fontWeight={400}
                  mt={"0px !important"}
                >
                  560 x 420 px (4:3, Size: 100-200 Kb)
                </Typography>
              </Box>
              {/* {required ? (
                
              ) : null} */}
            </>

            <Grid container columnSpacing={{ md: 2, xs: 0 }}>
              <Grid item md={12} xs={12}>
                {businessDetails?.banners?.length > 0 && (
                  <NewBusinessBannerListDND
                    businessDetails={businessDetails}
                    bannerImgDataList={businessDetails?.banners || []}
                    setBannerImgDataList={(data) => {
                      setBusinessDetails({
                        ...businessDetails,
                        banners: data,
                        errors: { ...businessDetails?.errors, banners: "" },
                      });
                    }}
                    // List of banners to delete
                    deletedBannerIndexList={deletedBannerIndexList}
                    setDeletedBannerIndexList={setDeletedBannerIndexList}
                  />
                )}

                <Box mt={-1.1} display={{ md: "block", xs: "none" }}>
                  <Typography
                    variant="span"
                    fontSize={12}
                    color={greyColor6}
                    fontWeight={400}
                    mt={"0px !important"}
                  >
                    560 x 420 px (4:3, Size: 100-200 Kb)
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {businessDetails?.errors?.banners ? (
              <Typography
                variant="subtitle1"
                component="p"
                fontSize={12}
                sx={{
                  textAlign: "start !important",
                  color: "red",
                }}
              >
                {businessDetails?.errors?.banners}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Box>

      <Box sx={styles.card}>
        <Grid container spacing={{md:3,xs:0}}>
          <Grid item xs={12} md={12}>
            <TextAreaComponent
              required
              label="Business Description"
              placeholder="Limit to 5000 characters"
              value={businessDetails.description}
              onChange={({ target }) => {
                handleValueChange(
                  businessDetails,
                  setBusinessDetails,
                  "description",
                  target.value
                );
              }}
              error={businessDetails?.errors?.description}
            />
          </Grid>
          {/* {JSON.stringify(businessDetails)} */}
        </Grid>
      </Box>

      <Grid
        container
        columnSpacing={{ md: 3, xs: 0 }}
        rowSpacing={{ md: 0, xs: 0.5 }}
      >
        <Grid item xs={12} md={6}>
          <Box sx={{ ...styles.card, height: "100%", pb: 0,mb:0 }}>
            <Grid container spacing={{md:2,xs:1}}>
              <Grid item xs={12} md={12}>
                <MultiSelectWithLabel
                  label="Business Category"
                  value={businessType}
                  onValueChange={handleChange}
                  menuList={businessCategory}
                  required
                  error={businessDetails?.errors?.category}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <FormInputBox
                  label="FSSAI Number"
                  placeholder=""
                  value={businessDetails.fssaiNumber}
                  onChange={({ target }) => {
                    const value = target?.value?.replace(/[^0-9]/g, "");
                    handleValueChange(
                      businessDetails,
                      setBusinessDetails,
                      "fssaiNumber",
                      value
                    );
                  }}
                  maxLength={14}
                  error={businessDetails?.errors?.fssaiNumber}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                {/* {JSON.stringify(moreProducts)} */}
                <AddItemsInputWithLabel
                  label="Main Products"
                  required
                  disabled
                  onValueChange={() => {}}
                  value={moreProducts}
                  setMoreProducts={(data) => {
                    setMoreProducts(data);
                    handleValueChange(
                      businessDetails,
                      setBusinessDetails,
                      "mainProducts",
                      data
                    );
                  }}
                  error={businessDetails?.errors?.mainProducts}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{ ...styles.card, height: "100%", pb: 0, mt: { xs: 2, md: 0 },mb:-2 }}
          >
            <Grid container spacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={12}>
                <FormInputBox
                  label="Business Owner Name"
                  placeholder=""
                  required
                  value={ownerDetails.name}
                  onChange={({ target }) => {
                    handleValueChange(
                      ownerDetails,
                      setOwnerDetails,
                      "name",
                      target.value
                    );
                  }}
                  error={ownerDetails?.errors?.name}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormInputBox
                  label="Primary Email"
                  placeholder=""
                  required
                  value={ownerDetails.email}
                  onChange={({ target }) => {
                    handleValueChange(
                      ownerDetails,
                      setOwnerDetails,
                      "email",
                      target.value
                    );
                  }}
                  error={ownerDetails?.errors?.email}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormInputBox
                  label="Alternate Email"
                  placeholder=""
                  value={ownerDetails.alternateEmail}
                  onChange={({ target }) => {
                    handleValueChange(
                      ownerDetails,
                      setOwnerDetails,
                      "alternateEmail",
                      target.value
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormInputBox
                  label="Primary Number"
                  placeholder=""
                  required
                  value={ownerDetails.phone}
                  onChange={({ target }) => {
                    const value = target?.value?.replace(/[^0-9]/g, "");
                    handleValueChange(
                      ownerDetails,
                      setOwnerDetails,
                      "phone",
                      value
                    );
                  }}
                  maxLength={10}
                  error={ownerDetails?.errors?.phone}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormInputBox
                  label="Alternate Number 1"
                  placeholder=""
                  required
                  value={ownerDetails.alternatePhone1}
                  onChange={({ target }) => {
                    const value = target?.value?.replace(/[^0-9]/g, "");
                    handleValueChange(
                      ownerDetails,
                      setOwnerDetails,
                      "alternatePhone1",
                      value
                    );
                  }}
                  maxLength={10}
                  error={ownerDetails?.errors?.alternatePhone1}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormInputBox
                  label="Alternate Number 2"
                  placeholder=""
                  value={ownerDetails.alternatePhone2}
                  onChange={({ target }) => {
                    const value = target?.value?.replace(/[^0-9]/g, "");
                    handleValueChange(
                      ownerDetails,
                      setOwnerDetails,
                      "alternatePhone2",
                      value
                    );
                  }}
                  maxLength={10}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {isFormError && (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={12}
          sx={{
            color: "red",
            mt: "4px !important",
            textAlign: "end !important",
          }}
        >
          Please fill out all mandatory fields
        </Typography>
      )}

      <Box my={{ xs: "35px", md: "15px" }}>
        <ActionButtons
          onBackClick={goBack}
          onSaveClick={handleSave}
          onNextClick={handleSubmit}
          onCancelClick={handleCancel}
          isSaveLoading={isSaveLoading}
          nextBtnText="PREVIEW & SUBMIT"
          saveBtnText="Save & Exit"
          saveTooltip={saveMsg}
          isLoading={isLoading}
        />
      </Box>
    </>
  );
}

export default DashboardDetailsForm;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: { md: "13px", xs: "8px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    mb: { md: "15px", xs: "15px" },
    p: { md: "16px", xs: "12px" },
    border: logoImgborder1,
  },
  infoBox: {
    px: 1,
    py: 1,
    borderRadius: "7px",
    backgroundColor: orangeBgColor,
    mt:{xs:2,md:0}
  },
};
