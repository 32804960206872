import { Box, Fade, Popper, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  blackColor,
  blackShade05,
  blueColor04,
  border25,
  cardBoderlightBlue,
  cardBoderlightPink,
  greenColor6,
  greenColor7,
  greyColor39,
  greyColor40,
  greyShade83,
  lightBlueColor05,
  lightBlueColor06,
  lightGreen04,
  lightPinkColor01,
  lightRedColor02,
  logoImgborder,
  mobilePrimary,
  orangeShade06,
  orangeShade07,
  orangeStatusColor,
  pendingBGColor,
  pendingTextColor,
  redColor01,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { getStatusBGColor, getStatusTextColor } from "../../../utils/data";
import LazyLoad from "react-lazyload";
import moment from "moment";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { textOneLines } from "../../../utils/styles";
import { addThousandsSeparator } from "../../../utils/helper";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

function MultiBranchCard({
  status,
  image,
  name,
  city,
  color,
  orderInfo,
  ondcEnabled,
  isLoading,
  onClick,
  orderSummary,
}) {
  const [imageExists, setImageExists] = useState(true);

  const [newOrders, setNewOrders] = useState(null);
  const [pendingOrders, setPendingOrders] = useState(null);
  const [shippedOrders, setShippedOrders] = useState(null);

  const loadingRef = useRef(null);
  const logoImgRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);

    setTimeout(() => {
      setOpen(() => false);
    }, 3000);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  useEffect(() => {
    if (orderInfo) {
      const orderList = orderInfo?.statusCounts;

      const newPendingApproval = orderList?.find(
        (item) => item?.id === "ordercreated"
      );

      const pendingShipment = orderList?.find(
        (item) => item?.id === "orderapproved"
      );

      const shipped = orderList?.find((item) => item?.id === "ordershipped");

      setNewOrders(newPendingApproval || null);
      setPendingOrders(pendingShipment || null);
      setShippedOrders(shipped || null);
    }

    return () => {};
  }, [orderInfo]);

  return (
    <>
      <Box
        sx={styles.card}
        onClick={(event) => {
          if (status !== "active") {
            handleClick(event);
          }

          onClick();
        }}
      >
        <Box
          sx={{
            bgcolor: greyColor39,
            p: "8px 10px",
            position: "relative",
            borderBottom: border25,
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <>
              <Box ref={loadingRef}>
                <Skeleton
                  variant="rectangular"
                  width="45px"
                  height="45px"
                  sx={{ borderRadius: "10px", mr: "-12px" }}
                />
              </Box>

              <LazyLoad throttle={200}>
                <Box
                  ref={logoImgRef}
                  component="img"
                  alt="Business Logo"
                  src={`${image}?tag${moment()?.valueOf()}`}
                  width="1px"
                  height="1px"
                  sx={{ borderRadius: "10px", border: logoImgborder }}
                  onLoad={() => {
                    loadingRef.current.style.display = "none";
                    logoImgRef.current.style.width = "45px";
                    logoImgRef.current.style.height = "45px";
                  }}
                  // onError={({ currentTarget }) => {
                  //   currentTarget.onerror = null; // prevents looping
                  //   currentTarget.src = noProductImgUrl;
                  //   setDisableCard(true);
                  // }}
                  onError={() => {
                    setImageExists(false);
                  }}
                />
              </LazyLoad>
            </>

            <Stack>
              <Typography
                variant="body1"
                color={blackShade05}
                fontSize={13}
                lineHeight="20px"
                fontWeight={600}
                sx={textOneLines}
              >
                {name}
              </Typography>
              <Typography
                variant="body1"
                color={greyColor40}
                fontSize={12}
                lineHeight="20px"
                fontWeight={400}
              >
                {city}
              </Typography>
            </Stack>
          </Box>

          <Box
            sx={{
              ...styles.statusTag,
              backgroundColor: getStatusBGColor(status),
            }}
          >
            <Typography
              variant="body1"
              fontSize={10.5}
              fontWeight={500}
              lineHeight="20px"
              color={getStatusTextColor(status)}
              sx={{ textTransform: "uppercase" }}
            >
              {status}
            </Typography>
          </Box>
        </Box>

        {status === "active" && (
          <>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent="space-between"
              p="10px"
              borderBottom={border25}
            >
              <Box
                display="flex"
                flexDirection={{ xs: "row", md: "column" }}
                alignItems="flex-start"
                gap={{ xs: 1, md: 0 }}
                mb={{ xs: 1.5, md: 0 }}
              >
                <Typography
                  fontSize={10}
                  fontWeight={500}
                  color={blackColor}
                  whiteSpace="nowrap"
                >
                  ONDC Enabled
                </Typography>
                <Box sx={styles.ondcTag(ondcEnabled)}>
                  {ondcEnabled ? "Yes" : "No"}
                </Box>
              </Box>

              <Box
                width={{ xs: "auto", md: "100%" }}
                display="flex"
                alignItems="center"
                justifyContent={{ xs: "space-between", md: "flex-end" }}
                gap={1}
              >
                <OrderInfoCard
                  icon={<EventRepeatIcon sx={{ fontSize: "20px" }} />}
                  label="Total Orders"
                  value={isLoading ? "XX" : orderInfo?.totalOrders}
                  bgColor={lightBlueColor06}
                  border={cardBoderlightBlue}
                />

                <OrderInfoCard
                  icon={<AttachMoneyIcon sx={{ fontSize: "20px" }} />}
                  label="Total Sales"
                  value={
                    isLoading
                      ? "XX"
                      : `₹ ${addThousandsSeparator(orderInfo?.totalAmount)}`
                  }
                  bgColor={lightPinkColor01}
                  border={cardBoderlightPink}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // borderTop: border25,
                // borderBottom: "2px solid " + color?.color,
                gap: "10px",
                p: "8px 10px",
              }}
            >
              <StatCard
                icon={
                  <Inventory2Icon
                    sx={{ fontSize: "18px", color: mobilePrimary }}
                  />
                }
                label="New/Pending"
                value={isLoading ? "XX" : newOrders?.count || 0}
                valueColor={mobilePrimary}
                iconBgColor={lightBlueColor05}
              />

              <StatCard
                icon={
                  <PendingActionsIcon
                    sx={{ fontSize: "18px", color: orangeStatusColor }}
                  />
                }
                label="Pending Shipment"
                value={isLoading ? "XX" : pendingOrders?.count || 0}
                valueColor={orangeStatusColor}
                iconBgColor={lightBlueColor05}
              />

              <StatCard
                icon={
                  <PendingActionsIcon
                    sx={{ fontSize: "18px", color: blueColor04 }}
                  />
                }
                label="Shipped"
                value={isLoading ? "XX" : shippedOrders?.count || 0}
                valueColor={blueColor04}
                iconBgColor={greyShade83}
              />
            </Box>
          </>
        )}

        {status !== "active" && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            p={1.5}
          >
            <Box
              sx={{
                ...styles.statIconStyle,
                backgroundColor: getStatusBGColor(status),
              }}
            >
              <PendingActionsIcon
                sx={{ fontSize: "18px", color: getStatusTextColor(status) }}
              />
            </Box>

            <Typography
              fontSize={12.5}
              fontWeight={500}
              color={blackColor}
              lineHeight="18px"
              mt="10px"
            >
              {status?.toLowerCase() == "pending"
                ? `This business is Pending approval. Our CMS agents will review and
              approve the business within 24 hrs of submission.`
                : `This business is in Draft status. Please complete the details and submit for approval. 
                 Once submitted our CMS agents will review and approve the business within 24 hours.`}
            </Typography>
          </Box>
        )}

        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            p: "8px 10px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              ...styles.statusTag,
              backgroundColor: getStatusBGColor(status),
            }}
          >
            <Typography
              variant="body1"
              fontSize={10.5}
              fontWeight={500}
              lineHeight="20px"
              color={getStatusTextColor(status)}
              sx={{ textTransform: "uppercase" }}
            >
              {status}
            </Typography>
          </Box>
          <>
            <Box ref={loadingRef}>
              <Skeleton
                variant="rectangular"
                width="45px"
                height="45px"
                sx={{ borderRadius: "10px", mr: "-12px" }}
              />
            </Box>
            <LazyLoad throttle={200}>
              <Box
                ref={logoImgRef}
                component="img"
                alt="Business Logo"
                src={`${image}?tag${moment()?.valueOf()}`}
                width="1px"
                height="1px"
                sx={{ borderRadius: "10px", border: logoImgborder }}
                onLoad={() => {
                  loadingRef.current.style.display = "none";
                  logoImgRef.current.style.width = "45px";
                  logoImgRef.current.style.height = "45px";
                }}
                // onError={({ currentTarget }) => {
                //   currentTarget.onerror = null; // prevents looping
                //   currentTarget.src = noProductImgUrl;
                //   setDisableCard(true);
                // }}
                onError={() => {
                  setImageExists(false);
                }}
              />
            </LazyLoad>
          </>
          <Stack>
            <Typography
              variant="body1"
              color={blackShade05}
              fontSize={13}
              lineHeight="20px"
              fontWeight={600}
              sx={textOneLines}
            >
              {name}
            </Typography>
            <Typography
              variant="body1"
              color={greyColor40}
              fontSize={12}
              lineHeight="20px"
              fontWeight={400}
            >
              {city}
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderTop: border25,
            borderBottom: "2px solid " + color?.color,
            gap: "10px",
            p: "8px 10px",
          }}
        >
          <StatCard
            icon={<Inventory2OutlinedIcon sx={{ color: color?.color }} />}
            label="Total Orders"
            value={isLoading ? "XX" : orderInfo?.totalOrders}
            color={color}
          />
          <StatCard
            icon={<CalendarMonthIcon sx={{ color: color?.color }} />}
            label="Total Sales"
            value={
              isLoading
                ? "XX"
                : `₹ ${addThousandsSeparator(orderInfo?.totalAmount)}`
            }
            color={color}
          />
        </Box> */}
      </Box>

      {/* <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box sx={styles.popperBox}>
              This business is Pending approval. Our CMS agents will review and
              approve the business within 24 hrs of submission.
            </Box>
          </Fade>
        )}
      </Popper> */}
    </>
  );
}

export default MultiBranchCard;

const OrderInfoCard = ({ icon, label, value, bgColor, border }) => {
  return (
    <Box sx={styles.orderInfoCard(bgColor, border)}>
      <Box sx={styles.iconStyles}>{icon}</Box>

      <Box>
        <Typography
          fontSize={10}
          fontWeight={500}
          color={blackColor}
          lineHeight="15px"
        >
          {label}
        </Typography>
        <Typography
          fontSize={13}
          fontWeight={600}
          color={blackColor}
          lineHeight="17px"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

const StatCard = ({ icon, label, value, iconBgColor, valueColor }) => {
  return (
    <Box display="flex" alignItems="center" gap="8px">
      <Box sx={{ ...styles.statIconStyle, backgroundColor: iconBgColor }}>
        {icon}
      </Box>

      <Stack>
        <Typography
          variant="body1"
          color={greyColor40}
          fontSize={10}
          textTransform={"uppercase"}
          lineHeight="18px"
          fontWeight={500}
        >
          {label}
        </Typography>
        <Typography
          variant="body1"
          color={valueColor}
          fontSize={13}
          lineHeight="18px"
          fontWeight={600}
        >
          {value}
        </Typography>
      </Stack>
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: whiteColor,
    border: border25,
    borderRadius: "10px",
    overflow: "hidden",
    width: { md: "32.6%", xs: "95%" },
    cursor: "pointer",
  },

  statusTag: {
    position: "absolute",
    right: 0,
    bottom: 12,
    borderRadius: "10px 0 0 10px",
    padding: { md: "1.5px 14px", xs: "0px 15px" },
  },

  popperBox: {
    width: { xs: "100vw", md: "28vw" },
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    fontSize: 12,
    fontWeight: 400,
    textAlign: "center",
    lineHeight: "20px",
    bgcolor: blackShade05,
    color: whiteColor,
    border: logoImgborder,
    borderRadius: "8px",
    p: 1,
    mt: 1,
    position: "relative",
    "&::after": {
      content: '" "',
      width: "12px",
      height: "12px",
      bgcolor: blackShade05,
      position: "absolute",
      rotate: "45deg",
      top: -5,
    },
  },

  ondcTag: (isOndcEnabled) => ({
    width: "50px",
    fontSize: 12,
    fontWeight: 500,
    color: isOndcEnabled ? greenColor7 : redColor01,
    bgcolor: isOndcEnabled ? lightGreen04 : lightRedColor02,
    textAlign: "center",
    p: "0px 12px",
    borderRadius: "20px",
  }),

  iconStyles: {
    width: "35px",
    height: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px",
    bgcolor: whiteColor,
  },

  orderInfoCard: (bgColor, border) => ({
    display: "flex",
    alignItems: "center",
    gap: 1,
    bgcolor: bgColor,
    p: 1,
    borderRadius: "8px",
    border,
  }),

  statIconStyle: {
    width: "34px",
    height: "34px",
    display: { xs: "none", md: "flex" },
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
};
