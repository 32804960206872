import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import InfoCardHeader from "./InfoCardHeader";
import {
  blackShade05,
  blackShade21,
  blueColor02,
  blueColor04,
  logoImgborder1,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import LabelValueText from "./LabelValueText";
import { addThousandsSeparator } from "../../../utils/helper";
import PersonalizeEmptyCard from "../EmptyCards/PersonalizeEmptyCard";
import useDynamicColor from "../../../hooks/useDynamicColor";

function PreferredDiscountsCard({
  preferredDiscountSummary,
  preferredDiscountCount,
  onClick,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mainColor = useDynamicColor();

  const totalDiscount =
    Number(preferredDiscountSummary?.totalPreferredDiscounts) || 0;
  const activePreferredDiscount =
    Number(preferredDiscountSummary?.totalActivePreferredDiscounts) || 0;
  const totalOrdersPlaced =
    Number(preferredDiscountCount?.totalPreferredOrderNumbers) || 0;
  const totalUtilizedPreferredDiscoutns =
    Number(preferredDiscountSummary?.totalUtilizedPreferredDiscoutns) || 0;
  const totalPreferredOrderValue =
    Number(preferredDiscountCount?.totalPreferredOrderValue) || 0;
  return (
    <Box sx={styles.card}>
      <InfoCardHeader title="Personalized Discounts" onManageClick={onClick} />

      {totalDiscount === 0 ? (
        <PersonalizeEmptyCard onClick={onClick} />
      ) : (
        <>
          <Typography
            color={blackShade21}
            fontSize={{xs:12, md:13}}
            fontWeight={500}
            lineHeight={{xs:1.2, md:1.5}}
            sx={{ whiteSpace: "pre-line", mt: 1 }}
          >
            {`Boost sales through `}
            <Typography
              fontSize={{xs:12, md:13}}
              fontWeight={500}
              sx={{
                color: mainColor,
                display: "inline",
                whiteSpace: "pre-line",
                display: "inline",
              }}
            >
              {`Personalized Discounts`}
            </Typography>
            {` for customers(by phone number)`}
          </Typography>

          <Box
            display="flex"
            alignItems="center"
            borderBottom="1px dashed #e2e4ea"
            pb={0.8}
          >
            <LabelValueText
              label="TOTAL DISCOUNTS"
              value={addThousandsSeparator(totalDiscount, true)}
              valueColor={matches ? blueColor04 : blackShade05}
              borderStyle="dashed"
              valueSize={14}
            />
            <LabelValueText
              label="ACTIVE DISCOUNTS"
              value={activePreferredDiscount}
              hideBorder
              valueSize={14}
            />
          </Box>

          <Box display="flex" alignItems="center" pb={0}>
            <LabelValueText
              label="TOTAL ORDERS PLACED"
              value={addThousandsSeparator(totalOrdersPlaced, true)}
              valueColor={matches ? blueColor04 : blackShade05}
              borderStyle="dashed"
              styles={{
                margin: { xs: "18px 10px 0 0", md: "10px 20px 0 0 !important" },
              }}
              valueSize={14}
            />
            <LabelValueText
              label="TOTAL ORDER VALUE"
              value={`₹ ${addThousandsSeparator(totalPreferredOrderValue)}`}
              hideBorder
              styles={{
                margin: { xs: "18px 10px 0 0", md: "10px 20px 0 0 !important" },
              }}
              valueSize={14}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default PreferredDiscountsCard;

const styles = {
  card: {
    width: "100%",
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: {xs:'10px', md:"13px"},
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 15px", md: "10px 15px 8px 15px" },
  },
};
