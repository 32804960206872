import { Box, Typography } from "@mui/material";
import React from "react";
import AddOndcCatagory from "../Forms/AddOndcCatagory";
import AddProductDetails from "../Forms/AddProductDetails";
import CatalogActionBtns from "../../../components/Buttons/CatalogActionBtns";
import { useDispatch } from "react-redux";
import {
  getProductSubCategoryOne,
  getProductSubCategoryTwo,
} from "../../../reducers/AddProduct/AddProductSlice";
import {
  getOndcFullSpecificationAPI,
  getOndcSpecificationAPI,
  getProductCodeAPI,
  getProductSubCategoryOneAPI,
} from "../../../service/api_calls/AddProduct/AddProductAPI";
import {
  extractNameCodePairs,
  extractNames,
  getOptionsForDependentCode,
} from "../../../utils/helper";
import { PRODUCT_CODE_DEFAULT_LIST } from "../../../utils/data";
import { stickyHeader } from "../../../utils/styles";
import {
  blackColor,
  blueColor04,
  errorTextColor,
} from "../../../configs/styles/muiThemes";
import BreadcrumbSection from "../../../components/Breadcrumb/BreadcrumbSection";

function ProductDetailsTab({
  categoryData,
  setCategoryData,
  productInfo,
  setProductInfo,
  taxDetails,
  setTaxDetails,
  onNextClick,
  onValueChange,
  setOndcSpecificationData,
  setProductCodeList,
  title,
  titleFrom,
  links,
  handleCancel,
  pageError,
}) {
  const dispatch = useDispatch();

  const handleMainCatChange = (value) => {
    if (!categoryData?.selectedBusinessCat?.code) {
      const selectedMainCat = {
        code: value?.code,
        name: value?.name,
        status: "active",
      };

      dispatch(getProductSubCategoryOne(value?.code));
      setCategoryData((prevState) => ({
        ...categoryData,
        selectedBusinessCat: selectedMainCat,
        selectedProductCat: null,
        selectedProductSubCat: null,
        selectedProductSubCat1: "",
        selectedProductSubCat2: "",
        errors: prevState?.errors
          ? { ...prevState?.errors, selectedBusinessCat: "" }
          : null,
      }));
      return;
    }

    if (categoryData?.selectedBusinessCat?.code !== value?.code) {
      const selectedMainCat = {
        code: value?.code,
        name: value?.name,
        status: "active",
      };

      dispatch(getProductSubCategoryOne(value?.code));
      setCategoryData((prevState) => ({
        ...categoryData,
        selectedBusinessCat: selectedMainCat,
        selectedProductCat: null,
        selectedProductSubCat: null,
        selectedProductSubCat1: "",
        selectedProductSubCat2: "",
        errors: prevState?.errors
          ? { ...prevState?.errors, selectedBusinessCat: "" }
          : null,
      }));
    }

    // onCategoryValueChange("selectedBusinessCat", value);
  };

  const handleProductCatChange = async (value) => {
    dispatch(
      getProductSubCategoryTwo({
        pcCode: value?.pcCode,
        pscCode1: value?.scCode1,
      })
    );

    setCategoryData((prevState) => ({
      ...categoryData,
      selectedProductCat: {
        code: value?.pscCode1,
        value: value?.productSubCategory1,
        pcCode: value?.pcCode,
        status: "active",
        pscCode1: value?.scCode1,
      },
      selectedProductSubCat: "",
      errors: prevState?.errors
        ? { ...prevState?.errors, selectedProductCat: "" }
        : null,
    }));
  };

  const handleSubProductCatChange = (value) => {
    setCategoryData({
      ...categoryData,
      selectedProductSubCat: {
        code: value?.pscCode2,
        value: value?.productSubCategory2,
        pcCode: value?.pcCode,
        psCode: value?.pcCode + value?.scCode1,
        status: "active",
        pscCode2: "",
      },
    });
  };

  const handleOndcCategoryChange = async (catData) => {
    console.log("catData", catData);
    // setCategoryData((prevState) => ({
    //   ...prevState,
    //   selectedOndcCat: catData,
    // }));

    const response = await getProductSubCategoryOneAPI(catData?.code);

    const productCodeResponse = await getProductCodeAPI({
      domainCode: catData?.code,
      status: "active",
    });

    if (productCodeResponse?.details?.length > 0) {
      setProductCodeList(productCodeResponse?.details);
    } else {
      setProductCodeList(PRODUCT_CODE_DEFAULT_LIST);
    }

    console.log(response, productCodeResponse);

    setCategoryData((prevState) => ({
      ...prevState,
      selectedOndcCat: catData,
      ondcSubCategory: response?.details || [],
      selectedOndcSubCat: "",
      errors: prevState?.errors
        ? { ...prevState?.errors, selectedOndcCat: "" }
        : null,
    }));
  };

  const handleOndcSubCategoryChange = async (subCatData) => {
    console.log("DATA", categoryData, subCatData);
    setCategoryData((prevState) => ({
      ...prevState,
      selectedOndcSubCat: subCatData,
      errors: prevState?.errors
        ? { ...prevState?.errors, selectedOndcSubCat: "" }
        : null,
    }));

    const specificationPayload = {
      pscCode1: subCatData?.pscCode1,
      status: "active",
    };
    const fullSpecificationPayload = {
      domainCode: categoryData?.selectedOndcCat?.code,
      status: "active",
    };
    const specification = await getOndcSpecificationAPI(specificationPayload);
    const fullSpecification = await getOndcFullSpecificationAPI(
      fullSpecificationPayload
    );

    const mapByCode = fullSpecification?.details?.reduce((acc, obj) => {
      acc[obj.code] = obj;
      return acc;
    }, {});

    const array = specification?.details?.[0]?.attributes?.map((obj1) => {
      // Check if there is a matching object in the second array based on code
      if (mapByCode[obj1?.value]) {
        // Merge properties from the second array object to the first array object
        console.log("map", mapByCode[obj1?.value]?.type);
        if (mapByCode[obj1?.value]?.display === "N") {
          return {
            ...obj1,
            required: "o",
            ...mapByCode[obj1?.value],
            inputValue: mapByCode[obj1?.value]?.defaultValue,
          };
        }
        if (mapByCode[obj1?.value]?.type === "codeValue") {
          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            inputValue: "",
            options: extractNames(mapByCode[obj1?.value]?.values),
            nameCodes: extractNameCodePairs(mapByCode[obj1?.value]?.values),
          };
        }
        if (mapByCode[obj1?.value]?.type === "radioButton") {
          // const radioOptions = mapByCode[obj1?.value]?.values?.map(
          //   (jsonString) => JSON.parse(jsonString)
          // );
          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            radioOptions: mapByCode[obj1?.value]?.radioOptions,
            // radioOptions: radioOptions,
          };
        }

        if (mapByCode[obj1?.value]?.type === "dependentCode") {
          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            inputValue: "",
            options: getOptionsForDependentCode(
              mapByCode[obj1?.value]?.dependentValues,
              subCatData?.pscCode1
            ),
          };
        }
        return {
          ...obj1,
          required: obj1?.code,
          ...mapByCode[obj1?.value],
          inputValue:
            mapByCode[obj1?.value]?.type === "default"
              ? mapByCode[obj1?.value]?.values?.[0]
              : "",
        };
      } else {
        // If no match found, simply return the first array object
        return obj1;
      }
    });

    setOndcSpecificationData(array);
    console.log(
      "codeMapData",
      mapByCode,
      specification?.details?.[0]?.attributes,
      array
    );
  };

  const handleProductCodeChange = (property, value, rootProperty) => {
    setTaxDetails((prevState) => ({
      ...prevState,
      [rootProperty]: { ...prevState[rootProperty], [property]: value },
      errors: prevState?.errors
        ? { ...prevState?.errors, productCode: "" }
        : null,
    }));
  };

  return (
    <Box mt={{ md: 0, xs: 1 }}>
      <Box
        sx={{
          ...stickyHeader,
          display: { md: "block", xs: "none" },
          pb: 1,
        }}
      >
        <BreadcrumbSection links={links} />
        <Typography
          component="h6"
          fontSize={{ xs: 15, md: 17 }}
          color={blackColor}
          fontWeight={{ xs: 600, md: 600 }}
          lineHeight="20px"
          display="inline"
        >
          {titleFrom && <span style={{ color: blueColor04 }}>{titleFrom}</span>}
          {title}
        </Typography>
        <AddOndcCatagory
          data={categoryData}
          setData={setCategoryData}
          handleMainCatChange={handleMainCatChange}
          handleProductCatChange={handleProductCatChange}
          handleSubProductCatChange={handleSubProductCatChange}
          handleOndcCategoryChange={handleOndcCategoryChange}
          handleOndcSubCategoryChange={handleOndcSubCategoryChange}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        <AddOndcCatagory
          data={categoryData}
          setData={setCategoryData}
          handleMainCatChange={handleMainCatChange}
          handleProductCatChange={handleProductCatChange}
          handleSubProductCatChange={handleSubProductCatChange}
          handleOndcCategoryChange={handleOndcCategoryChange}
          handleOndcSubCategoryChange={handleOndcSubCategoryChange}
        />
      </Box>

      <AddProductDetails
        data={productInfo}
        taxData={taxDetails}
        onProductValueChange={(name, value) =>
          onValueChange(name, value, productInfo, setProductInfo)
        }
        onTaxValueChange={(name, value) =>
          onValueChange(name, value, taxDetails, setTaxDetails)
        }
        handleProductCodeChange={handleProductCodeChange}
      />
      <Box>
        <CatalogActionBtns
          hideSaveBtn
          onNextClick={onNextClick}
          onCancelClick={handleCancel}
          // Preview Btn
        />
        {pageError ? (
          <Typography
            fontSize={12}
            fontWeight={500}
            mt={1}
            textAlign="right"
            color={errorTextColor}
          >
            Please fix the errors above to proceed
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
}

export default ProductDetailsTab;
