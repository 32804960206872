import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearPreferredDiscountsInfo, getClaimedPreferredDiscountsData } from "../../../reducers/preferredDiscount/PreferredDiscountSlice";
import moment from "moment";
import PreferredDiscountCard from "../Card/PreferredDiscountCard";
import InfiniteScroll from "react-infinite-scroll-component";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import EmptyCard from "../../../components/Cards/EmptyCard";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";


function ClaimedPreferredDiscountContent({
  openDeleteAlter,
  searchData,
  searchStatus,
}) {
  const dispatch = useDispatch();

  const { claimedPreferredDiscounts, claimedPreferredDiscountsLoader } =
    useSelector((state) => state.businessPreferredDiscounts);
  const [discountList, setDiscountList] = useState([]);

  const getDiscountData = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      getClaimedPreferredDiscountsData({
        businessId,
        status: "claimed",
        pageNo: claimedPreferredDiscounts?.pageNo + 1,
      })
    );
  };

  useEffect(() => {
    if (claimedPreferredDiscounts) {
      setDiscountList(claimedPreferredDiscounts);
    }
    return () => {};
  }, [claimedPreferredDiscounts]);

  useEffect(() => {
    if (searchStatus) {
      setDiscountList(searchData || []);
      console.log("searchData", searchData);
    } else {
      setDiscountList(claimedPreferredDiscounts);
    }

    return () => {};
  }, [searchData, searchStatus]);


  useEffect(() => {
    getDiscountData();

    return () => {
      dispatch(clearPreferredDiscountsInfo());
    };
  }, []);

  useEffect(() => {
    console.log("CLAIMED_CODE_SUCCESS:", claimedPreferredDiscounts);
    console.log(
      "CLAIMED_CODE_SUCCESS_CODE_HAS_MORE",
      Math.round(
        Number(claimedPreferredDiscounts?.totalCount) /
          Number(claimedPreferredDiscounts?.pageSize)
      ) > Number(claimedPreferredDiscounts?.pageNo + 1)
    );
    return () => {};
  }, [claimedPreferredDiscounts]);

  return (
    <Box>
      {!claimedPreferredDiscountsLoader &&
        claimedPreferredDiscounts?.details?.length === 0 && (
          <EmptyCard
            msg="No claimed personalized Discount "
            icon={<DiscountOutlinedIcon sx={{ fontSize: "24px" }} />}
          />
        )}
      {claimedPreferredDiscountsLoader && <ThreeDotLoader />}

      {!claimedPreferredDiscountsLoader &&
      discountList?.details?.length > 0 &&
      searchStatus === true ? (
        <Grid container spacing={2}>
          {discountList?.details?.map((item, index) => {
            const endDate = item?.pointEndDate;
            const currentDate = moment(endDate).valueOf();
            const statusText = item?.rewardStatus;

            const userInfo = discountList?.profileInfo?.filter(
              (profile) => item.rewardsProfileId === profile.profileId
            );

            let username = "";
            let profilePic = "";

            if (userInfo?.length > 0) {
              username = userInfo?.[0]?.name;
              profilePic = userInfo?.[0]?.photoUrl;
            }

            return (
              <Grid item xs={12} md={3} mt={4}>
                <PreferredDiscountCard
                  customerName={username}
                  customerNumber={item?.rewardsPhoneNo.replace(
                    /(\d{2})(\d{0})/,
                    "$1 $2"
                  )}
                  totalDiscount={item?.pointValue}
                  startDate={moment(item?.pointStartDate).format("MMM Do YYYY")}
                  endDate={moment(endDate).format("MMM Do YYYY")}
                  percentage={item?.pointPercentage}
                  minpurchase={item?.minimumAmount}
                  statusText={statusText}
                  profileImage={profilePic}
                  item={item}
                  onDeleteClick={() => {
                    openDeleteAlter(item);
                    getDiscountData();
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <InfiniteScroll
          dataLength={claimedPreferredDiscounts?.details?.length || 0}
          next={getDiscountData}
          hasMore={
            Math.round(
              Number(claimedPreferredDiscounts?.totalCount) /
                Number(claimedPreferredDiscounts?.pageSize)
            ) > Number(claimedPreferredDiscounts?.pageNo + 1)
          }
          loader={
            <Box>
              <ThreeDotLoader />
            </Box>
          }
        >
          <Grid container spacing={2} mt={1}>
            {claimedPreferredDiscounts?.details?.map((item, index) => {
              const endDate = item?.pointEndDate;
              const currentDate = moment(endDate).valueOf();
              const statusText = item?.rewardStatus;

              const userInfo = claimedPreferredDiscounts?.profileInfo?.filter(
                (profile) => item.rewardsProfileId === profile.profileId
              );

              let username = "";
              let profilePic = "";

              if (userInfo?.length > 0) {
                username = userInfo?.[0]?.name;
                profilePic = userInfo?.[0]?.photoUrl;
              }

              return (
                <Grid item xs={12} md={3} mt={4}>
                  <PreferredDiscountCard
                    customerName={username}
                    customerNumber={item?.rewardsPhoneNo.replace(
                      /(\d{2})(\d{0})/,
                      "$1 $2"
                    )}
                    totalDiscount={item?.pointValue}
                    startDate={moment(item?.pointStartDate).format(
                      "MMM Do YYYY"
                    )}
                    endDate={moment(endDate).format("MMM Do YYYY")}
                    percentage={item?.pointPercentage}
                    minpurchase={item?.minimumAmount}
                    statusText={statusText}
                    profileImage={profilePic}
                    item={item}
                    onDeleteClick={() => {
                      openDeleteAlter(item);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </InfiniteScroll>
      )}
    </Box>
  );
}

export default ClaimedPreferredDiscountContent;
