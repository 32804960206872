import {
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import React from "react";
import {
  authInputBorder,
  blackColor,
  blackShade02,
  blackShade05,
  borderTextbox,
  descriptionColor,
  errorborderTextbox,
  greyColor6,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { allowOnlyEnglish } from "../../utils/helper";

function FormInputBoxWithBorder({
  label,
  mobilelabel1,
  mobilelabel2,
  placeholder,
  name,
  value,
  onChange,
  icon,
  onIconClick,
  showPassword,
  maxLength,
  inputType,
  startIcon,
  // isError,
  error,
  errorMsg,
  helperText,
  autoFocus,
  inputRef,
  onKeyUp,
  onBlur,
  disabled,
  isDashboardInput,
  hideLabel,
  required,
  inputOpaticy,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = allowOnlyEnglish(inputValue);

    if (onChange) {
      onChange({ target: { value: sanitizedValue } });
    }
  };

  return (
    <Box mb={{ md: "15px !important", xs: "10px !important" }}>
      {!hideLabel ? (
        <Box mb="3px" display={"flex"} flexDirection={"column"}>
          <Box>
            <Typography
              variant="body2"
              fontSize={{ md: 13, xs: 12 }}
              color={greyColor6}
              fontWeight={400}
              lineHeight={{ md: 1.5, xs: 0.8 }}
              sx={{
                display: "inline",
                opacity: inputOpaticy ? inputOpaticy : disabled ? 0.5 : 1,
              }}
            >
              {mobilelabel1 ? mobilelabel1 : label}{" "}
            </Typography>
            {required ? (
              <Typography
                variant="body1"
                sx={{ display: "inline", color: "#f00" }}
              >
                *
              </Typography>
            ) : null}
          </Box>
          {mobilelabel2 && (
            <Typography
              variant="body2"
              fontSize={{ md: 13, xs: 12 }}
              color={greyColor6}
              fontWeight={400}
              lineHeight={{ md: 1.5, xs: 0.8 }}
              sx={{
                display: "inline",
                opacity: inputOpaticy ? inputOpaticy : disabled ? 0.5 : 1,
              }}
              mt={0.5}
              mb={1}
            >
              {mobilelabel2}{" "}
            </Typography>
          )}
        </Box>
      ) : null}

      <TextField
        variant="standard"
        required={true}
        fullWidth
        name={name}
        disabled={disabled}
        value={value}
        onBlur={onBlur}
        autoFocus={autoFocus}
        onChange={handleInputChange}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        inputRef={inputRef}
        sx={{
          fontSize: 13,
          fontWeight: 500,
          color: blackShade05,
          //   borderTop: "none",
          //   borderBottom: borderTextbox,
          //   borderLeft: "none",
          //   borderRight: "none",
          //   borderRadius: "0px",
          border: authInputBorder,
          borderRadius: "6px",
          backgroundColor: whiteColor,
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
        }}
        InputProps={{
          startAdornment: startIcon ? (
            <InputAdornment
              position="start"
              sx={{
                color: `${blackShade05} !important`,
                fontSize: 13.5,
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: 13, fontWeight: 500, color: blackShade05 }}
              >
                {startIcon}
              </Typography>
            </InputAdornment>
          ) : (
            <></>
          ),
          endAdornment: (
            <InputAdornment position="end" sx={{ cursor: "pointer" }}>
              {icon}
            </InputAdornment>
          ),
          style: {
            padding: "4px 10px",
            color: grey,
            fontSize: 13.5,
            fontWeight: 500,
          },
          disableUnderline: true,
        }}
        inputProps={{ maxLength: maxLength }}
        type={inputType}
      />

      {error ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={12}
          sx={{ mt: "4px !important", textAlign: "start !important" }}
          style={{ color: "red" }}
        >
          {error}
        </Typography>
      ) : null}

      {helperText ? (
        <Typography
          variant="body1"
          component="p"
          fontSize={11}
          color={descriptionColor}
        >
          {helperText}
        </Typography>
      ) : null}
    </Box>
  );
}

export default FormInputBoxWithBorder;
