import { Box, Typography, Avatar } from "@mui/material";
import ProfileCharAvatar from "../ProfileCharAvatar";
import {
  blackShade42,
  blueColor04,
  greyColor7,
  greyColor80,
  mobilePrimary,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { useState } from "react";
import ModalCard from "../ModalCard";
import AlertCard from "../Alerts/AlertCard";
import { textOneLines } from "../../utils/styles";
import { Link } from "react-router-dom";

const DelegateInfoCard = ({
  profileImg,
  name,
  phoneNumber,
  statusColor,
  handleRemove,
  edit,
  deleteLoader,
  isNotValid,
  currentManager,
  masterBranchInfo,
}) => {
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [infoAlert, setInfoAlert] = useState(false);

  const handleRemoveManager = () => {
    const masterBranchOwnerInfo = masterBranchInfo?.details?.owner || null;

    const selectedManagerId = currentManager?.profileId || null;
    const ownerId = masterBranchOwnerInfo?.profileId || null;

    if (selectedManagerId === ownerId) {
      setInfoAlert(true);
    } else {
      setDeleteAlert(true);
    }
  };

  return (
    <Box width={"100%"}>
      <Box
        sx={{
          ...styles.card,
          display: { md: "flex", xs: "flex" },
          // "box-shadow": `0 2px 4px rgb(179 188 198 / 10%)`,
          border: `1px solid #E5E9F0`,
          borderRadius: "10px",
        }}
        textAlign="center"
        mt={{ md: "32px", xs: "15px" }}
        position="relative"
      >
        <Box
          position="absolute"
          top={{ md: "-32px", xs: "-26px" }}
          left={{ md: "calc(50%-26px)", md: "calc(50%-21px)" }}
        >
          {profileImg ? (
            <Avatar
              alt={name}
              src={profileImg}
              sx={{
                width: { md: 52, xs: 42 },
                height: { md: 52, xs: 42 },
                border: "2px solid #e2e5ec",
              }}
            />
          ) : (
            <ProfileCharAvatar
              username={name || "Guest"}
              border
              size={{ md: "50px", xs: "42px" }}
              fontSize={{ md: 20, xs: 14 }}
            />
          )}

          {/* <Box
            sx={{
              width: "13px",
              height: "13px",
              borderRadius: "10px",
              // backgroundColor: statusColor || orangeStatusColor,
              position: "absolute",
              bottom: 0,
              right: 3,
              // border: `2px solid ${whiteColor}`,
            }}
          /> */}
        </Box>

        {!isNotValid ? (
          <>
            {" "}
            <Typography
              fontSize={{ md: 14, xs: 12 }}
              fontWeight={600}
              color={{ md: blueColor04, xs: blackShade42 }}
              lineHeight="20px"
              textAlign="center"
              sx={textOneLines}
              title={name}
              mt="6px"
            >
              {name}
            </Typography>
            <Typography
              fontSize={{ md: 12, xs: 11 }}
              fontWeight={500}
              color={greyColor7}
              lineHeight={{ md: "22px", xs: "18px" }}
              textAlign="center"
            >
              {phoneNumber}
            </Typography>
          </>
        ) : (
          <>
            <Typography
              fontSize={{ md: 12, xs: 11 }}
              fontWeight={500}
              color={greyColor7}
              lineHeight={{ md: "22px", xs: "18px" }}
              textAlign="center"
              mt={0.4}
            >
              User not found
            </Typography>
            <Typography
              fontSize={{ md: 11, xs: 11 }}
              fontWeight={500}
              color={{ xs: mobilePrimary, md: blueColor04 }}
              lineHeight={{ md: "18px", xs: "18px" }}
              textAlign="center"
              mt={0.2}
            >
              Contact CostBo support team to add a valid owner{" "}
              <Link
                to={`/${sessionStorage.getItem("costboWEBURL")}/support`}
                style={{ textDecoration: "underline" }}
              >
                click here
              </Link>
            </Typography>
          </>
        )}

        {edit && (
          <Typography
            textAlign="center"
            sx={{
              textDecoration: "underline",
              display: { xs: "flex", md: "none" },
              cursor: "pointer",
            }}
            fontSize={11.5}
            color={blueColor04}
            onClick={() => handleRemoveManager()}
          >
            Remove Access
          </Typography>
        )}
      </Box>
      <Box sx={{ ...styles.card, display: { md: "none", xs: "none" } }}>
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={2}
        >
          <Box>
            {profileImg ? (
              <Avatar
                alt={name}
                src={profileImg}
                sx={{
                  width: 42,
                  height: 42,
                  border: "2px solid #e2e5ec",
                }}
              />
            ) : (
              <ProfileCharAvatar
                username={name || "Guest"}
                border
                size="42px"
                fontSize="14px"
              />
            )}
          </Box>
          <Box>
            <Typography
              fontSize={13}
              fontWeight={600}
              color={blackShade42}
              lineHeight="20px"
              mt="6px"
            >
              {name}
            </Typography>

            <Typography
              fontSize={12}
              fontWeight={500}
              color={greyColor7}
              lineHeight="18px"
            >
              {phoneNumber}
            </Typography>
          </Box>
        </Box>
        {edit && (
          <Typography
            textAlign="center"
            sx={{
              textDecoration: "underline",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              cursor: "pointer",
            }}
            mt={0.5}
            fontSize={12.5}
            color={greyColor80}
            onClick={() => handleRemoveManager()}
          >
            Remove Access
          </Typography>
        )}
      </Box>
      {edit && (
        <Typography
          textAlign="center"
          sx={{
            textDecoration: "underline",
            display: { md: "flex", xs: "none" },
            justifyContent: "center",
            cursor: "pointer",
          }}
          mt={0.5}
          fontSize={12.5}
          color={greyColor80}
          onClick={() => handleRemoveManager()}
        >
          Remove Access
        </Typography>
      )}

      <ModalCard
        open={deleteAlert}
        // open={true}
        handleClose={() => setDeleteAlert(false)}
        width="30%"
      >
        <AlertCard
          message={`Do you want to remove access for this Manager - "${name}"`}
          onYes={handleRemove}
          yesloader={deleteLoader}
          onCancel={() => setDeleteAlert(false)}
        />
      </ModalCard>

      <ModalCard
        open={infoAlert}
        // open={true}
        handleClose={() => setInfoAlert(false)}
        width="30%"
      >
        <AlertCard
          message={`You can't remove owner of the main branch from delegate list.`}
          onYes={() => {
            setInfoAlert(false);
          }}
          yesloader={deleteLoader}
          onCancel={() => setInfoAlert(false)}
          hideNegativeBtn
          btnText="OK"
        />
      </ModalCard>
    </Box>
  );
};

export default DelegateInfoCard;

const styles = {
  card: {
    width: "100%",
    display: "flex",
    flexDirection: { md: "column", xs: "column" },
    alignItems: { md: "center", xs: "center" },
    justifyContent: { md: "center", xs: "center" },
    p: { xs: " 15px 10px 6px", md: "20px 10px 10px" },
    backgroundColor: whiteColor,
    //boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: { xs: "8px", md: "8px" },
    zIndex: 1,
  },
};
