import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getBusinessProductsAPI,
  getInventoryOndcFilterAPI,
  getInventoryProductsByStatusAPI,
} from "../../service/api_calls/inventory/InventoryApi";
import {
  filterNotSellingProducts,
  filterPriceUpdateProducts,
  prepareLowAndOOSProducts,
  updatePriceInputDetails,
  updateQuickPriceInputDetails,
} from "./inventoryHelper";
import { getDashboardInventorySummaryApi } from "../../service/api_calls/BusinessDashboard/BusinessDashboardApi";
import { getONDCCategoriesAPI } from "../../service/api_calls/BusinessPreview/BusinessPreviewAPI";

const initialState = {
  lowStockProducts: [],
  lowStockPageNo: 1,
  lowStockProductsLoader: false,

  outOfStockProducts: [],
  outOfStockPageNo: 1,
  outOfStockProductsLoader: false,

  priceUpdateProducts: [],
  priceUpdatePageNo: 1,
  priceUpdateProductsLoader: false,

  allStockUpdateProducts: [],
  allStockUpdatePageNo: 1,
  allStockUpdateProductsLoader: false,

  notSellingUpdateProducts: [],
  notSellingUpdatePageNo: 1,
  notSellingUpdateProductsLoader: false,

  inventorySummary: null,
  summaryLoader: false,

  inventorySearchResult: null,
  searchLoader: false,

  ondcDropdownData: [],
  categoryLoader: false,
};

// Get Low Stock Products
export const getAllLowStockProducts = createAsyncThunk(
  "getAllLowStockProducts",
  async (param, thunkAPI) => {
    const res = await getInventoryProductsByStatusAPI(param);
    return res;
  }
);

// Refresh Low Stock Products
export const refreshLowStockProducts = createAsyncThunk(
  "refreshLowStockProducts",
  async (param, thunkAPI) => {
    const res = await getInventoryProductsByStatusAPI(param);
    return res;
  }
);

// Get Out Of Stock Products
export const getAllOutOfStockProducts = createAsyncThunk(
  "getAllOutOfStockProducts",
  async (param, thunkAPI) => {
    const res = await getInventoryProductsByStatusAPI(param);
    return res;
  }
);

// Refresh Out Of Stock Products
export const refreshOutOfStockProducts = createAsyncThunk(
  "refreshOutOfStockProducts",
  async (param, thunkAPI) => {
    const res = await getInventoryProductsByStatusAPI(param);
    return res;
  }
);

// Get Price Update Products
export const getPriceUpdateProducts = createAsyncThunk(
  "getPriceUpdateProducts",
  async (param, thunkAPI) => {
    const res = await getInventoryProductsByStatusAPI(param);
    return res;
  }
);

// Refresh Price Update Products
export const refreshPriceUpdateProducts = createAsyncThunk(
  "refreshPriceUpdateProducts",
  async (param, thunkAPI) => {
    const res = await getInventoryProductsByStatusAPI(param);
    return res;
  }
);

// Get All stock Update Products
export const getAllStockUpdateProducts = createAsyncThunk(
  "getAllStockUpdateProducts",
  async (param, thunkAPI) => {
    const res = await getInventoryProductsByStatusAPI(param);
    return res;
  }
);

// Refresh All stock Update Products
export const refreshAllStockUpdateProducts = createAsyncThunk(
  "refreshAllStockUpdateProducts",
  async (param, thunkAPI) => {
    const res = await getInventoryProductsByStatusAPI(param);
    return res;
  }
);

// Get Not Selling Update Products
export const getNotSellingUpdateProducts = createAsyncThunk(
  "getNotSellingUpdateProducts",
  async (param, thunkAPI) => {
    const res = await getInventoryProductsByStatusAPI(param);
    return res;
  }
);

// Refresh Not Selling Update Products
export const refreshNotSellingUpdateProducts = createAsyncThunk(
  "refreshNotSellingUpdateProducts",
  async (param, thunkAPI) => {
    const res = await getInventoryProductsByStatusAPI(param);
    return res;
  }
);

// Get Inventory Summary Counts
export const getInventorySummaryCounts = createAsyncThunk(
  "getInventorySummaryCounts",
  async (param, thunkAPI) => {
    const res = await getDashboardInventorySummaryApi(param);
    return res;
  }
);

// Search Inventory Products
export const searchInventoryProducts = createAsyncThunk(
  "searchInventoryProducts",
  async (param, thunkAPI) => {
    const res = await getInventoryProductsByStatusAPI(param);
    return {
      result: res?.details || [],
      status: param?.tabStatus || "",
    };
  }
);

// Get Category List Data
export const getCategoryDropdownListData = createAsyncThunk(
  "getCategoryDropdownListData",
  async (businessId, thunkAPI) => {
    const ondcCategoryData = await getONDCCategoriesAPI(businessId);

    return {
      ondcCategoryData,
    };
  }
);

// ONDC Filter
export const filterInventoryOndcProducts = createAsyncThunk(
  "filterInventoryOndcProducts",
  async (param, thunkAPI) => {
    const res = await getInventoryOndcFilterAPI(param);
    return {
      result: [...res?.details],
      status: param?.tabStatus || "",
      pageNo: param?.pageNo || 1,
    };
  }
);

export const businessInventory = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    clearInventoryInfo: (state, action) => {
      state.lowStockProducts = [];
      state.lowStockPageNo = 1;
      state.lowStockProductsLoader = false;

      state.outOfStockProducts = [];
      state.outOfStockPageNo = 1;
      state.outOfStockProductsLoader = false;

      state.priceUpdateProducts = [];
      state.priceUpdatePageNo = 1;
      state.priceUpdateProductsLoader = false;

      state.allStockUpdateProducts = [];
      state.allStockUpdatePageNo = 1;
      state.allStockUpdateProductsLoader = false;

      state.notSellingUpdateProducts = [];
      state.notSellingUpdatePageNo = 1;
      state.notSellingUpdateProductsLoader = false;
    },

    clearAllInventoryInfo: (state, action) => {
      state.lowStockProducts = [];
      state.lowStockPageNo = 1;
      state.lowStockProductsLoader = false;

      state.outOfStockProducts = [];
      state.outOfStockPageNo = 1;
      state.outOfStockProductsLoader = false;

      state.priceUpdateProducts = [];
      state.priceUpdatePageNo = 1;
      state.priceUpdateProductsLoader = false;

      state.allStockUpdateProducts = [];
      state.allStockUpdatePageNo = 1;
      state.allStockUpdateProductsLoader = false;

      state.notSellingUpdateProducts = [];
      state.notSellingUpdatePageNo = 1;
      state.notSellingUpdateProductsLoader = false;

      state.inventorySummary = null;
      state.inventorySearchResult = null;

      state.ondcDropdownData = [];
    },

    // Handle Price Update On change
    handlePriceUpdateChange: (state, action) => {
      const { priceUpdateProducts, value, index } = action.payload;

      const updatedProductsList = updatePriceInputDetails(
        priceUpdateProducts,
        value,
        index
      );

      state.priceUpdateProducts = [...updatedProductsList];
    },

    // Apply Quick Price Update Data
    handleQuickPriceUpdate: (state, action) => {
      const { priceUpdateProducts, quickUpdateData } = action.payload;

      const updatedProductsList = updateQuickPriceInputDetails(
        priceUpdateProducts,
        quickUpdateData
      );

      state.priceUpdateProducts = [...updatedProductsList];
    },

    // Update Price Info List
    updatePriceInfoList: (state, action) => {
      const { priceUpdateProducts } = action.payload;
      state.priceUpdateProducts = [...priceUpdateProducts];
    },

    // Handle All Stock Update On change
    handleAllStockUpdateChange: (state, action) => {
      const { priceUpdateProducts, value, index } = action.payload;

      const updatedProductsList = updatePriceInputDetails(
        priceUpdateProducts,
        value,
        index
      );

      console.log("handleAllStockUpdateChange___:", updatedProductsList);

      state.allStockUpdateProducts = [...updatedProductsList];
    },

    // Apply Quick All Stock Update Data
    handleQuickAllStockUpdate: (state, action) => {
      const { allStockUpdateProducts, quickUpdateData } = action.payload;

      const updatedProductsList = updateQuickPriceInputDetails(
        allStockUpdateProducts,
        quickUpdateData
      );

      state.allStockUpdateProducts = [...updatedProductsList];
    },

    // Update All Stock Info List
    updateAllStockInfoList: (state, action) => {
      const { allStockUpdateProducts } = action.payload;
      state.allStockUpdateProducts = [...allStockUpdateProducts];
    },

    // Handle Not Selling Update On change
    handleNotSellingUpdateChange: (state, action) => {
      const { priceUpdateProducts, value, index } = action.payload;

      const updatedProductsList = updatePriceInputDetails(
        priceUpdateProducts,
        value,
        index
      );

      console.log("handleNotSellingUpdateChange___:", updatedProductsList);

      state.notSellingUpdateProducts = [...updatedProductsList];
    },

    // Apply Quick Not Selling Update Data
    handleQuickNotSellingUpdate: (state, action) => {
      const { notSellingUpdateProducts, quickUpdateData } = action.payload;

      const updatedProductsList = updateQuickPriceInputDetails(
        notSellingUpdateProducts,
        quickUpdateData
      );

      state.notSellingUpdateProducts = [...updatedProductsList];
    },

    // Update Not Selling Info List
    updateNotSellingInfoList: (state, action) => {
      const { notSellingUpdateProducts } = action.payload;
      state.notSellingUpdateProducts = [...notSellingUpdateProducts];
    },

    // Handle Low stock Update On change
    handleLowStockUpdateChange: (state, action) => {
      const { lowStockProducts, value, index } = action.payload;

      const updatedProductsList = updatePriceInputDetails(
        lowStockProducts,
        value,
        index
      );

      console.log("handleLowStockUpdateChange___:", updatedProductsList);

      state.lowStockProducts = [...updatedProductsList];
    },

    // Update Low Stock Info List
    updateLowStockInfoList: (state, action) => {
      const { lowStockProducts } = action.payload;
      state.lowStockProducts = [...lowStockProducts];
    },

    // Handle Out Of Stock Update On change
    handleOutOfStockUpdateChange: (state, action) => {
      const { outOfStockProducts, value, index } = action.payload;

      const updatedProductsList = updatePriceInputDetails(
        outOfStockProducts,
        value,
        index
      );

      console.log("handleOutOfStockUpdateChange___:", updatedProductsList);

      state.outOfStockProducts = [...updatedProductsList];
    },

    // Update Out Of Stock Info List
    updateOutOfStockInfoList: (state, action) => {
      const { outOfStockProducts } = action.payload;
      state.outOfStockProducts = [...outOfStockProducts];
    },

    // Select All Rows
    selectAllInventoryProducts: (state, action) => {
      const { tabStatus, productList, selectType } = action.payload;

      const productArr = [...productList];
      const newArray = [];

      productArr.forEach((item) => {
        newArray.push({
          ...item,
          itemChange: selectType,
        });
      });

      console.log("productArr___tabStatus", tabStatus);
      console.log("productArr___", newArray);

      if (tabStatus === "out_of_stock") {
        state.outOfStockProducts = [...newArray];
      } else if (tabStatus === "low_stock") {
        state.lowStockProducts = [...newArray];
      } else if (tabStatus === "all_stock") {
        state.allStockUpdateProducts = [...newArray];
      } else if (tabStatus === "price_update") {
        state.priceUpdateProducts = [...newArray];
      } else if (tabStatus === "not_selling") {
        state.notSellingUpdateProducts = [...newArray];
      }
    },
  },

  extraReducers: {
    //fetch Low Stock Update Products
    [getAllLowStockProducts.pending]: (state) => {
      state.lowStockProductsLoader = true;
    },
    [getAllLowStockProducts.fulfilled]: (state, { payload }) => {
      const productsData = payload?.details || [];

      const filteredList = prepareLowAndOOSProducts(productsData);

      if (productsData.length > 0) {
        state.lowStockProducts = [...state.lowStockProducts, ...filteredList];
      }

      state.lowStockPageNo = state.lowStockPageNo + 1;
      state.lowStockProductsLoader = false;
    },
    [getAllLowStockProducts.rejected]: (state) => {
      state.lowStockProductsLoader = false;
    },

    //Refresh Low Stock Update Products
    [refreshLowStockProducts.pending]: (state) => {
      state.lowStockProductsLoader = true;
    },
    [refreshLowStockProducts.fulfilled]: (state, { payload }) => {
      const productsData = payload?.details || [];

      const filteredList = prepareLowAndOOSProducts(productsData);

      if (productsData.length > 0) {
        state.lowStockProducts = [...filteredList];
      }

      state.lowStockPageNo = 2;
      state.lowStockProductsLoader = false;
    },
    [refreshLowStockProducts.rejected]: (state) => {
      state.lowStockProductsLoader = false;
    },

    //fetch Out Of Stock Update Products
    [getAllOutOfStockProducts.pending]: (state) => {
      state.outOfStockProductsLoader = true;
    },
    [getAllOutOfStockProducts.fulfilled]: (state, { payload }) => {
      const productsData = payload?.details || [];

      const filteredList = prepareLowAndOOSProducts(productsData);

      if (productsData.length > 0) {
        state.outOfStockProducts = [
          ...state.outOfStockProducts,
          ...filteredList,
        ];
      }

      state.outOfStockPageNo = state.outOfStockPageNo + 1;
      state.outOfStockProductsLoader = false;
    },
    [getAllOutOfStockProducts.rejected]: (state) => {
      state.outOfStockProductsLoader = false;
    },

    //Refresh Out Of Stock Update Products
    [refreshOutOfStockProducts.pending]: (state) => {
      state.outOfStockProductsLoader = true;
    },
    [refreshOutOfStockProducts.fulfilled]: (state, { payload }) => {
      const productsData = payload?.details || [];

      const filteredList = prepareLowAndOOSProducts(productsData);

      if (productsData.length > 0) {
        state.outOfStockProducts = [...filteredList];
      }

      state.outOfStockPageNo = 2;
      state.outOfStockProductsLoader = false;
    },
    [refreshOutOfStockProducts.rejected]: (state) => {
      state.outOfStockProductsLoader = false;
    },

    //fetch Price Update Products
    [getPriceUpdateProducts.pending]: (state) => {
      state.priceUpdateProductsLoader = true;
    },
    [getPriceUpdateProducts.fulfilled]: (state, { payload }) => {
      const productsData = payload?.details || [];

      const filteredList = filterPriceUpdateProducts(productsData);

      if (productsData.length > 0) {
        state.priceUpdateProducts = [
          ...state.priceUpdateProducts,
          ...filteredList,
        ];
      }

      state.priceUpdatePageNo = state.priceUpdatePageNo + 1;
      state.priceUpdateProductsLoader = false;
    },
    [getPriceUpdateProducts.rejected]: (state) => {
      state.priceUpdateProductsLoader = false;
    },

    //Refresh Price Update Products
    [refreshPriceUpdateProducts.pending]: (state) => {
      state.priceUpdateProductsLoader = true;
    },
    [refreshPriceUpdateProducts.fulfilled]: (state, { payload }) => {
      const productsData = payload?.details || [];

      const filteredList = filterPriceUpdateProducts(productsData);

      if (productsData.length > 0) {
        state.priceUpdateProducts = [...filteredList];
      }

      state.priceUpdatePageNo = 2;
      state.priceUpdateProductsLoader = false;
    },
    [refreshPriceUpdateProducts.rejected]: (state) => {
      state.priceUpdateProductsLoader = false;
    },

    //fetch All Stock Update Products
    [getAllStockUpdateProducts.pending]: (state) => {
      state.allStockUpdateProductsLoader = true;
    },
    [getAllStockUpdateProducts.fulfilled]: (state, { payload }) => {
      const productsData = payload?.details || [];

      const filteredList = filterPriceUpdateProducts(productsData);

      if (productsData.length > 0) {
        state.allStockUpdateProducts = [
          ...state.allStockUpdateProducts,
          ...filteredList,
        ];
      }
      state.allStockUpdatePageNo = state.allStockUpdatePageNo + 1;
      state.allStockUpdateProductsLoader = false;
    },
    [getAllStockUpdateProducts.rejected]: (state) => {
      state.allStockUpdateProductsLoader = false;
    },

    //Refresh All Stock Update Products
    [refreshAllStockUpdateProducts.pending]: (state) => {
      state.allStockUpdateProductsLoader = true;
    },
    [refreshAllStockUpdateProducts.fulfilled]: (state, { payload }) => {
      const productsData = payload?.details || [];

      const filteredList = filterPriceUpdateProducts(productsData);

      if (productsData.length > 0) {
        state.allStockUpdateProducts = [...filteredList];
      }
      state.allStockUpdatePageNo = 2;
      state.allStockUpdateProductsLoader = false;
    },
    [refreshAllStockUpdateProducts.rejected]: (state) => {
      state.allStockUpdateProductsLoader = false;
    },

    //fetch Not Selling Update Products
    [getNotSellingUpdateProducts.pending]: (state) => {
      state.notSellingUpdateProductsLoader = true;
    },
    [getNotSellingUpdateProducts.fulfilled]: (state, { payload }) => {
      const productsData = payload?.details || [];

      const filteredList = filterNotSellingProducts(productsData);

      if (productsData.length > 0) {
        state.notSellingUpdateProducts = [
          ...state.notSellingUpdateProducts,
          ...filteredList,
        ];
      }

      state.notSellingUpdatePageNo = state.notSellingUpdatePageNo + 1;
      state.notSellingUpdateProductsLoader = false;
    },
    [getNotSellingUpdateProducts.rejected]: (state) => {
      state.notSellingUpdateProductsLoader = false;
    },

    //Refresh Not Selling Update Products
    [refreshNotSellingUpdateProducts.pending]: (state) => {
      state.notSellingUpdateProductsLoader = true;
    },
    [refreshNotSellingUpdateProducts.fulfilled]: (state, { payload }) => {
      const productsData = payload?.details || [];

      const filteredList = filterNotSellingProducts(productsData);

      if (productsData.length > 0) {
        state.notSellingUpdateProducts = [...filteredList];
      }

      state.notSellingUpdatePageNo = 2;
      state.notSellingUpdateProductsLoader = false;
    },
    [refreshNotSellingUpdateProducts.rejected]: (state) => {
      state.notSellingUpdateProductsLoader = false;
    },

    //Get Inventory summary Count
    [getInventorySummaryCounts.pending]: (state) => {
      state.summaryLoader = true;
    },
    [getInventorySummaryCounts.fulfilled]: (state, { payload }) => {
      state.inventorySummary = payload;
      state.summaryLoader = false;
    },
    [getInventorySummaryCounts.rejected]: (state) => {
      state.summaryLoader = false;
    },

    //Search Inventory Products
    [searchInventoryProducts.pending]: (state) => {
      state.searchLoader = true;
    },
    [searchInventoryProducts.fulfilled]: (state, { payload }) => {
      const { result, status } = payload;

      if (status === "all_stock") {
        state.allStockUpdateProducts = result || [];
        state.allStockUpdatePageNo = 2;
      } else if (status === "price_update") {
        state.priceUpdateProducts = result || [];
        state.priceUpdatePageNo = 2;
      } else if (status === "low_stock") {
        const filteredList = prepareLowAndOOSProducts(result);
        state.lowStockProducts = filteredList || [];
        state.lowStockPageNo = 2;
      } else if (status === "out_of_stock") {
        const filteredList = prepareLowAndOOSProducts(result);
        state.outOfStockProducts = filteredList || [];
        state.outOfStockPageNo = 2;
      } else if (status === "not_selling") {
        state.notSellingUpdateProducts = result || [];
        state.notSellingUpdatePageNo = 2;
      } else {
      }

      state.searchLoader = false;
    },
    [searchInventoryProducts.rejected]: (state) => {
      state.searchLoader = false;
    },

    //Get Category Dropdown Data
    [getCategoryDropdownListData.pending]: (state) => {
      state.categoryLoader = true;
    },
    [getCategoryDropdownListData.fulfilled]: (state, { payload }) => {
      const { ondcCategoryData } = payload;
      state.ondcDropdownData = ondcCategoryData || [];
    },
    [getCategoryDropdownListData.rejected]: (state) => {
      state.categoryLoader = false;
    },

    //ONDC Filter Inventory Products
    [filterInventoryOndcProducts.pending]: (state) => {
      state.allStockUpdateProductsLoader = true;
      state.priceUpdateProductsLoader = true;
      state.lowStockProductsLoader = true;
      state.outOfStockProductsLoader = true;
      state.notSellingUpdateProductsLoader = true;
    },
    [filterInventoryOndcProducts.fulfilled]: (state, { payload }) => {
      const { result, status, pageNo } = payload;

      if (status === "all_stock") {
        state.allStockUpdateProducts =
          pageNo == 1
            ? [...result]
            : [...state.allStockUpdateProducts, ...result];

        state.allStockUpdatePageNo =
          pageNo == 1 ? 2 : state.allStockUpdatePageNo + 1;
      } else if (status === "price_update") {
        state.priceUpdateProducts =
          pageNo == 1 ? [...result] : [...state.priceUpdateProducts, ...result];

        state.priceUpdatePageNo = pageNo == 1 ? 2 : state.priceUpdatePageNo + 1;
      } else if (status === "low_stock") {
        const filteredList = prepareLowAndOOSProducts(result);

        state.lowStockProducts =
          pageNo == 1
            ? [...filteredList]
            : [...state.lowStockProducts, ...filteredList];

        state.lowStockPageNo = pageNo == 1 ? 2 : state.lowStockPageNo + 1;
      } else if (status === "out_of_stock") {
        const filteredList = prepareLowAndOOSProducts(result);

        state.outOfStockProducts =
          pageNo == 1
            ? [...filteredList]
            : [...state.outOfStockProducts, ...filteredList];

        state.outOfStockPageNo = pageNo == 1 ? 2 : state.outOfStockPageNo + 1;
      } else if (status === "not_selling") {
        state.notSellingUpdateProducts =
          pageNo == 1
            ? [...result]
            : [...state.notSellingUpdateProducts, ...result];

        state.notSellingUpdatePageNo =
          pageNo == 1 ? 2 : state.notSellingUpdatePageNo + 1;
      } else {
      }

      state.allStockUpdateProductsLoader = false;
      state.priceUpdateProductsLoader = false;
      state.lowStockProductsLoader = false;
      state.outOfStockProductsLoader = false;
      state.notSellingUpdateProductsLoader = false;
    },
    [filterInventoryOndcProducts.rejected]: (state) => {
      state.allStockUpdateProductsLoader = false;
      state.priceUpdateProductsLoader = false;
      state.lowStockProductsLoader = false;
      state.outOfStockProductsLoader = false;
      state.notSellingUpdateProductsLoader = false;
    },
  },
});

export const {
  clearInventoryInfo,
  clearAllInventoryInfo,

  handlePriceUpdateChange,
  handleQuickPriceUpdate,
  updatePriceInfoList,

  handleAllStockUpdateChange,
  handleQuickAllStockUpdate,
  updateAllStockInfoList,

  handleNotSellingUpdateChange,
  handleQuickNotSellingUpdate,
  updateNotSellingInfoList,

  handleLowStockUpdateChange,
  updateLowStockInfoList,

  handleOutOfStockUpdateChange,
  updateOutOfStockInfoList,

  selectAllInventoryProducts
} = businessInventory.actions;

export const businessInventoryReducer = businessInventory.reducer;
