import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import React from "react";
import {
  blackShade159,
  blackShade22,
  blueColor04,
  greyColor13,
  greyColor40,
  greyColor7,
  logoImgborder1,
  mobilePrimary,
  whiteColor,
} from "../../configs/styles/muiThemes";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: whiteColor,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    border: "1px solid " + greyColor7,
    fontSize: 11,
  },
}));

function ActionButtons({
  isSaveLoading,
  isLoading,
  onBackClick,
  onSaveClick,
  onNextClick,
  onCancelClick,
  showBackBtn,
  nextBtnText,
  saveBtnText,
  backBtnText,
  hideNextBtn,
  hideNextTabBtn,
  hideSaveBtn,
  saveTooltip,
  hideCancel,
  showPreviewBtn,
  onPreviewSubmitClick,
}) {
  return (
    <Box sx={styles.container}>
      {showBackBtn ? (
        <IconButton
          sx={styles.backBtn}
          variant="contained"
          disableElevation
          onClick={onBackClick}
          // startIcon={}
        >
          {/* {backBtnText} */}
          <SkipPreviousIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      ) : (
        // <Box></Box>
        <Box />
      )}

      <Box sx={!hideSaveBtn && !hideNextBtn ? styles.saveNextBtnContainer : {}}>
        {hideSaveBtn ? (
          <Box />
        ) : saveTooltip ? (
          <LightTooltip
            title={<span style={{ fontSize: "11px" }}>{saveTooltip}</span>}
            placement="right"
          >
            <Button
              sx={styles.saveBtn}
              variant="contained"
              disableElevation
              onClick={onSaveClick}
              disabled={isSaveLoading}
              // startIcon={<CircularProgress size={14} sx={{color: blackShade159}} />}
            >
              {saveBtnText || "SAVE"}
            </Button>
          </LightTooltip>
        ) : (
          <Button
            sx={styles.saveBtn}
            variant="contained"
            disableElevation
            onClick={onSaveClick}
            disabled={isSaveLoading}
            // startIcon={<CircularProgress size={14} sx={{color: blackShade159}} />}
          >
            {saveBtnText || "SAVE"}
          </Button>
        )}

        {hideNextBtn ? null : (
          <Box display="flex" alignItems="center" gap="15px">
            {!hideCancel && (
              <Button
                sx={styles.cancelBtn}
                variant="contained"
                disableElevation
                onClick={onCancelClick}
                disabled={isLoading}
                // startIcon={<CircularProgress size={14} sx={{color: blackShade159}} />}
              >
                CANCEL
              </Button>
            )}

            {!hideNextTabBtn && (
              <Button
                sx={styles.submitBtn}
                variant="contained"
                disableElevation
                onClick={onNextClick}
                disabled={isLoading}
                startIcon={
                  isLoading && (
                    <CircularProgress size={14} sx={{ color: blackShade159 }} />
                  )
                }
              >
                {nextBtnText || "NEXT"}
              </Button>
            )}

            {showPreviewBtn && (
              <Button
                sx={styles.submitBtn}
                variant="contained"
                disableElevation
                onClick={onPreviewSubmitClick}
                disabled={isLoading}
                startIcon={
                  isLoading && (
                    <CircularProgress size={14} sx={{ color: blackShade159 }} />
                  )
                }
              >
                PREVIEW & SUBMIT
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ActionButtons;

const styles = {
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  btn: (bgColor, textcolor, borderColor) => ({
    background: bgColor,
    borderRadius: { xs: "4px", md: "6px" },
    padding: { xs: "3px 4px", md: "6px 35px" },
    fontSize: { xs: "11.5px", md: "14px" },
    color: textcolor,
    borderRadius: { md: "6px", xs: "0.4rem" },
    fontWeight: 500,
    border: "1px solid " + borderColor,

    "&.Mui-disabled": {
      border: "none",
    },
  }),

  btnHover: (textcolor, bgColor) => ({
    border: `1px solid ${blackShade159}`,
    "&:hover": {
      border: `1px solid ${blackShade159}`,
      backgroundColor: bgColor || whiteColor,
      color: textcolor,
    },
  }),

  saveBtn: {
    backgroundColor: { xs: whiteColor, md: blackShade22 },
    padding: { md: "4px 14px", xs: "3px 6px" },
    fontSize: "12px",
    fontWeight: 500,
    color: { xs: mobilePrimary, md: whiteColor },
    border: {
      xs: "1.5px solid " + mobilePrimary,
      md: "1.5px solid " + blackShade22,
    },
    "&:hover": {
      color: { xs: mobilePrimary, md: blackShade22 },
      backgroundColor: { xs: mobilePrimary, md: whiteColor },
    },
  },

  submitBtn: {
    backgroundColor: { xs: mobilePrimary, md: blackShade22 },
    padding: { md: "4px 14px", xs: "3px 6px" },
    fontSize: "12px",
    fontWeight: 500,
    color: whiteColor,
    border: {
      xs: "1.5px solid " + mobilePrimary,
      md: "1.5px solid " + blackShade22,
    },
    "&:hover": {
      color: { xs: mobilePrimary, md: blackShade22 },
      backgroundColor: whiteColor,
    },
  },

  cancelBtn: {
    padding: { md: "4px 14px", xs: "3px 6px" },
    fontSize: "12px",
    fontWeight: 600,
    color: { xs: greyColor40, md: blueColor04 },
    backgroundColor: whiteColor,
    border: {
      xs: "1.5px solid " + greyColor40,
      md: "1.5px solid " + blueColor04,
    },
    "&:hover": {
      color: whiteColor,
      backgroundColor: { xs: greyColor40, md: blueColor04 },
    },
  },

  backBtn: {
    backgroundColor: "transparent",
    padding: { md: "4px 6px !important", xs: "3px 6px !important" },
    fontSize: "14px",
    color: { xs: mobilePrimary, md: blackShade159 },
    textTransform: "unset",
    textDecoration: "underline",
    border: {
      xs: "1px solid " + mobilePrimary,
      md: "1px solid " + blackShade159,
    },
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "transparent",
      color: { xs: mobilePrimary, md: greyColor13 },
    },
  },

  saveNextBtnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,

    "&.Mui-disabled": {
      border: "none",
    },
  },
};
