import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  blackShade05,
  greyColor6,
} from "../../../configs/styles/muiThemes";
import ONDCCatMultiSelect from "../ONDCCatMultiSelect";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import { SHIPMENT_REGION_LIST } from "../../../utils/data";
import FormInputBox from "../../../components/Input/FormInputBox";
import RadiosBtnWithLabel from "../../../components/Input/RadiosBtnWithLabel";
import RadioOptionWithLabel from "../../../components/Radio/RadioOptionWithLabel";
import CheckBoxWithLabel from "../../../components/Input/CheckBoxWithLabel";

const CategorySpecsForm = ({
  data,
  setData,
  ondcCategories,
  additionalONDCList,
  handleOndcInfoChange,
  editOndc,
}) => {
  const handleDeliveryMethodChange = (id, deliveryMethod) => {
    handleOndcInfoChange("ondcBPPFulfillments", { id, type: deliveryMethod });
  };

  const handleONDCCategoryChange = (value) => {
    handleOndcInfoChange("ondcDomain", value);
  };

  const handleONDCAdditionalCategoryChange = (value) => {
    setData((prevState=>({
      ...prevState,
      additionalONDCCat: value
    })))
  };

  return (
    <Box>
      <Typography
        component="h6"
        sx={{ fontSize: 15, fontWeight: 600, color: blackShade05, mt:{md:2,xs: 1.5 }}}
      >
        Category & Shipments
      </Typography>

      <Box mt={{md:1,xs:0.5}}>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12} md={6}>
            <ONDCCatMultiSelect
              label={`ONDC Category ${editOndc ? '(Existing)' : ''}`}
              value={data?.ondc?.ondcDomain || []}
              onValueChange={handleONDCCategoryChange}
              menuList={ondcCategories}
              required
              error={data?.errors?.ondcDomain}
              disabled={editOndc}
            />
          </Grid>

          {editOndc && <>
           <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <ONDCCatMultiSelect
              label={`ONDC Category ${editOndc ? '(New)' : ''}`}
              value={data?.additionalONDCCat || []}
              onValueChange={handleONDCAdditionalCategoryChange}
              menuList={additionalONDCList}
              error={data?.errors?.ondcDomain}
              // disabled={editOndc}
            />
          </Grid></>}
        </Grid>
      </Box>

      <Box mt={2}>
        <Typography component="h6" sx={styles.sectionHeader}>
          Shipments & Discounts
        </Typography>

        <Grid container rowSpacing={{ xs: 0.8, md: 0 }} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <SelectWithLabel
              label="Shipment Region"
              value={data?.ondc?.ondcSupportRegion?.label}
              onValueChange={(region) => {
                handleOndcInfoChange("ondcSupportRegion", region);
              }}
              returnFullItem
              menuList={SHIPMENT_REGION_LIST}
              errorMsg={data?.errors?.ondcSupportRegion}
            />
          </Grid>

          <Grid item xs={12} md={3.6}>
            <Box mt={{ xs: 1, md: 0 }}>
              <FormInputBox
                label="Shipment Distance (in KM)"
                placeholder=""
                required
                value={data?.ondc?.ondcSupportDistanceKm}
                onChange={({ target }) => {
                  const value = target?.value?.replace(/[^0-9]/g, "");
                  handleOndcInfoChange("ondcSupportDistanceKm", value);
                }}
                error={data?.errors?.ondcSupportDistanceKm}
                helperText="For Hyperlocal delivery choose distance as 5KM"
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={3}></Grid>

          <Grid item xs={12} md={5}>
            <Typography
              component="p"
              fontSize={13}
              fontWeight={400}
              color={greyColor6}
              display="inline"
            >
              Delivery Methods
              <Typography
                variant="body1"
                sx={{ display: "inline", color: "#f00" }}
              >
                *
              </Typography>
            </Typography>

            <Box>
              <CheckBoxWithLabel
                label="Delivery"
                checked={data?.ondc?.ondcBPPFulfillments?.type === "Delivery"}
                onValuesChanged={() => {
                  handleDeliveryMethodChange("F1", "Delivery");
                }}
                labelStyle={styles.radioLabel}
              />

              <CheckBoxWithLabel
                label="Self-Pickup"
                checked={data?.ondc?.ondcBPPFulfillments?.type === "Self-Pickup"}
                onValuesChanged={() => {
                  handleDeliveryMethodChange("F2", "Self-Pickup");
                }}
                labelStyle={styles.radioLabel}
                disabled
              />

              {/* <RadiosBtnWithLabel
                label="Delivery and Self-Pickup"
                checked={
                  data?.ondc?.ondcBPPFulfillments?.type ===
                  "Delivery and Self-Pickup"
                }
                onValuesChanged={() => {
                  handleDeliveryMethodChange("F3", "Delivery and Self-Pickup");
                }}
                labelStyles={styles.radioLabel}
              /> */}
            </Box>

            <Typography
              variant="subtitle1"
              component="p"
              fontSize={12}
              sx={{ color: "red" }}
            >
              {data?.errors?.ondcBPPFulfillments}
            </Typography>
          </Grid>

          {/* <Grid item xs={12} md={3}>
            <RadioOptionWithLabel
              label="Customer Discount"
              required
              value={
                data?.ondc?.ondcCustomerDiscountEnabled === ""
                  ? ""
                  : data?.ondc?.ondcCustomerDiscountEnabled === "yes"
                  ? "Yes"
                  : "No"
              }
              onYesClick={() => {
                handleOndcInfoChange("ondcCustomerDiscountEnabled", "yes");
              }}
              onNoClick={() => {
                handleOndcInfoChange("ondcCustomerDiscountEnabled", "no");
              }}
              error={data?.errors?.ondcCustomerDiscountEnabled}
            />
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default CategorySpecsForm;

const styles = {
  sectionHeader: {
    fontSize: { xs: 15, md: 15 },
    color: blackColor,
    fontWeight: 600,
    lineHeight: "20px",
    mb: {md:2,xs:1},
  },

  radioLabel: {
    color: greyColor6,
    fontSize: 13,
    fontWeight: 400,
  },
};
