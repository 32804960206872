import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BGColor02,
  BGcolor01,
  bgColor15,
  blackShade05,
  blackShade159,
  blackShade19,
  blueColor04,
  border25,
  copyBtnColor,
  greyColor47,
  orangeStatusColor,
  orderCardBorder2,
  redColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { textOneLines } from "../../../utils/styles";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import moment from "moment";
import { addThousandsSeparator, getOrderType } from "../../../utils/helper";
import ReturnProductInfoCard from "../Cards/ReturnProductInfoCard";
import {
  getReturnActionListApi,
  processReturnStatusApi,
} from "../../../service/api_calls/manageReturns/ManageReturnsApi";
import ModalCard from "../../../components/ModalCard";
import MobileReturnsCard from "../Cards/MobileReturnsCard";
import { toTitleCase } from "../../../utils/validationUtils";

const ViewReturnOrderPopupContent = ({
  data,
  getAllReturnQty,
  type,
  handleCancel,
  handleCloseAfterSubmit,
  getOndcBapNameBySubscriberId,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [returnOrderData, setReturnOrderData] = useState(data || null);
  const [errorMsg, setErrorMsg] = useState("");

  const [returnedProducts, setReturnedProducts] = useState([]);
  const [actionReasons, setActionReasons] = useState([]);

  const [totalRefundAmount, setTotalRefundAmount] = useState(0);

  const [loader, setLoader] = useState(false);

  const [openImageView, setOpenImageView] = useState({
    show: false,
    imageUrl: "",
  });

  const calculateTotalRefundAmount = () => {
    const partialProducts = data?.returnOrderInfo?.partialProducts || [];

    let refundAmt = 0;

    partialProducts.forEach((partialReturnItem) => {
      const productData =
        data?.productList?.find(
          (item) => item?.productId === partialReturnItem?.productId
        ) || null;
      const productSalePrice = Number(productData?.productSalePrice) || 0;

      refundAmt =
        refundAmt +
        Number(partialReturnItem?.returnQuantity) * productSalePrice;
    });

    console.log("calculateTotalRefundAmount__:", refundAmt);

    setTotalRefundAmount(refundAmt);
  };

  // auto fill previously updated Action and Reson
  const autoFillPartialReturnData = () => {
    console.log(
      "autoFillPartialReturnData_returnOrderData__:",
      returnOrderData
    );

    const partialProducts =
      returnOrderData?.returnOrderInfo?.partialProducts || [];

    const updatedList = partialProducts.map((item) => {
      let data = { ...item };
      if (item?.returnStatus !== "INITIATED") {
        data = {
          ...data,
          updatedReturnStatus: toTitleCase(item?.returnStatus),
          reasonDesc: { reason: item?.rejectReason },
          disabled: true,
        };
      }

      return data;
    });

    console.log("autoFillPartialReturnData__updatedList", updatedList);

    setReturnOrderData((prevState) => ({
      ...prevState,
      returnOrderInfo: {
        ...prevState?.returnOrderInfo,
        partialProducts: updatedList,
      },
    }));
  };

  const prepareData = () => {
    const partialReturned =
      returnOrderData?.returnOrderInfo?.partialProducts || [];

    const productList = returnOrderData?.productList?.filter((item) => {
      const isProductReturned = partialReturned?.some(
        (product) => product?.productId === item?.productId
      );

      return isProductReturned;
    });

    setReturnedProducts(productList);
    console.log("returnedProducts___:", productList);
  };

  //fetch dropdown data for reason
  const getActionResons = async () => {
    const response = await getReturnActionListApi();

    if (response?.details?.length > 0) {
      setActionReasons(response?.details);
    }
  };

  const handleUpdateAction = (returnId, key, value, isSelected) => {
    console.log("handleUpdateAction__returnOrderData__:", returnOrderData);
    console.log("handleUpdateAction__returnId__:", returnId);
    console.log("handleUpdateAction__key__:", key);
    console.log("handleUpdateAction__value__:", value);

    if (returnOrderData?.returnOrderInfo?.partialProducts?.length > 0) {
      let partialReturned = [
        ...returnOrderData?.returnOrderInfo?.partialProducts,
      ];
      const updatedItemIndex = partialReturned?.findIndex(
        (item) => item?.returnId === returnId
      );

      console.log("handleUpdateAction__updatedItemIndex__:", updatedItemIndex);

      if (updatedItemIndex !== -1) {
        if (key === isSelected) {
          partialReturned[updatedItemIndex] = {
            ...partialReturned[updatedItemIndex],
            [key]: value,
            errors: null,
          };
        } else if (key === "updatedReturnStatus") {
          partialReturned[updatedItemIndex] = {
            ...partialReturned[updatedItemIndex],
            isSelected: true,
            [key]: value,
            reasonDesc: null,
            errors: { ...partialReturned[updatedItemIndex]?.errors, [key]: "" },
          };
        } else {
          partialReturned[updatedItemIndex] = {
            ...partialReturned[updatedItemIndex],
            isSelected: true,
            [key]: value,
            errors: { ...partialReturned[updatedItemIndex]?.errors, [key]: "" },
          };
        }

        console.log("handleUpdateAction__FINAL_UPDATE__", partialReturned);
        setReturnOrderData((prevState) => ({
          ...prevState,
          returnOrderInfo: {
            ...prevState?.returnOrderInfo,
            partialProducts: partialReturned,
          },
        }));
      }
    }
  };

  const processReturns = async (partialReturnItems, cartId) => {
    setLoader(true);

    const businessId = sessionStorage.getItem("selectedBusinessId");
    const orderItems = [];

    partialReturnItems?.forEach((item) => {
      if (item.isSelected) {
        let orderData = null;

        if (
          item?.updatedReturnStatus?.toUpperCase() === "LIQUIDATED" ||
          item?.updatedReturnStatus?.toUpperCase() === "APPROVED"
        ) {
          orderData = {
            productId: item?.productId,
            returnId: item?.returnId,
            returnStatus: item?.updatedReturnStatus?.toUpperCase(),
          };
        } else {
          orderData = {
            productId: item?.productId,
            returnId: item?.returnId,
            returnStatus: item?.updatedReturnStatus?.toUpperCase(),
            rejectCode: item?.reasonDesc?.code || "",
            rejectReason: item?.reasonDesc?.reason || "",
          };
        }

        orderItems.push(orderData);
      }
    });

    const postData = {
      businessId,
      postData: {
        // businessId,
        returnProductStatusInfo: orderItems,
      },
      cartId,
    };

    console.log("postData__:", postData);

    const res = await processReturnStatusApi(postData);

    if (res) {
      setTimeout(() => {
        setLoader(false);
        handleCloseAfterSubmit();
      }, 1500);
    }
  };

  const handleSubmit = () => {
    console.log("returnOrderData__", returnOrderData);

    const returnItems = returnOrderData?.returnOrderInfo?.partialProducts;

    const partialProductsList = [];
    let isError = false;

    returnItems?.forEach((item) => {
      let errors = null;
      if (item?.isSelected) {
        if (
          !item?.updatedReturnStatus ||
          item?.updatedReturnStatus === "Select Return Status"
        ) {
          errors = { ...errors, updatedReturnStatus: "Select Return Status" };
          isError = true;
        } else {
          delete errors?.updatedReturnStatus;
        }

        if (item?.updatedReturnStatus === "Rejected") {
          if (
            !item?.reasonDesc?.reason ||
            item?.reasonDesc?.reason === "Select Reason"
          ) {
            errors = { ...errors, reasonDesc: "Select Reason" };
            isError = true;
          } else {
            delete errors?.reasonDesc;
          }
        }

        partialProductsList.push({
          ...item,
          errors,
        });
      } else {
        partialProductsList.push({
          ...item,
          errors: null,
        });
      }
    });

    const selectedItems = returnItems?.filter((item) => item?.isSelected);

    if (selectedItems?.length === 0) {
      isError = true;
      setErrorMsg("Select atleast one product to continue");
    } else {
      setErrorMsg("");
    }

    console.log("isError___:", isError);

    if (isError) {
      setReturnOrderData((prevState) => ({
        ...prevState,
        returnOrderInfo: {
          ...prevState?.returnOrderInfo,
          partialProducts: partialProductsList,
        },
      }));
    } else {
      processReturns(partialProductsList, returnOrderData?.id);
    }
  };
  const orderType = getOrderType(returnOrderData);

  useEffect(() => {
    prepareData();
    getActionResons();
    autoFillPartialReturnData();
    calculateTotalRefundAmount();
    return () => {};
  }, []);

  return (
    <Box overflow="hidden">
      <Box sx={styles.card}>
        <Box p={{ xs: 2, md: 2 }} bgcolor={BGcolor01}>
          <Typography
            variant="h3"
            fontSize={{ xs: 15, md: 18 }}
            fontWeight={600}
            color={blackShade19}
            textAlign="center"
          >
            Return Request Details
          </Typography>
        </Box>

        <Box p={{ xs: 1.5, md: 2.5 }}>
          {matches ? (
            <Box sx={styles.orderInfoCard}>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={6} md={1.7}>
                  <Box display="flex" gap="8px" flex={1}>
                    <ReceiptLongOutlinedIcon
                      sx={{
                        fontSize: "18px",
                        color: blackShade19,
                        display: { xs: "none", md: "block" },
                        mt: 0.2,
                      }}
                    />

                    <Box
                      flex={1}
                      display="flex"
                      flexDirection={{ xs: "column", md: "column" }}
                      justifyContent={{ xs: "space-between", md: "flex-start" }}
                    >
                      <Typography
                        variant="h5"
                        fontSize={12.5}
                        fontWeight={600}
                        color={blackShade19}
                        lineHeight="22px"
                      >
                        ID# {returnOrderData?.orderId}
                      </Typography>

                      <Typography
                        variant="p"
                        fontSize={11}
                        fontWeight={500}
                        color={bgColor15}
                        lineHeight="22px"
                      >
                        {moment(returnOrderData?.orderCreatedOn)?.format(
                          "DD MMM YYYY"
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={6} md={2.1}>
                  <Box mt={-0.5}>
                    <Typography
                      variant="p"
                      fontSize={11}
                      fontWeight={500}
                      color={bgColor15}
                      // lineHeight="22px"
                    >
                      ONDC ORDER ID:
                    </Typography>

                    <Box display="flex" gap="8px" flex={1}>
                      <ReceiptLongOutlinedIcon
                        sx={{
                          fontSize: "18px",
                          color: blackShade19,
                          display: { xs: "none", md: "block" },
                          mt: 0.2,
                        }}
                      />

                      <Box
                        flex={1}
                        display="flex"
                        flexDirection={{ xs: "column", md: "column" }}
                        justifyContent={{
                          xs: "space-between",
                          md: "flex-start",
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontSize={12.5}
                          fontWeight={600}
                          color={orderType?.color}
                          lineHeight="22px"
                          sx={{ lineBreak: "anywhere" }}
                        >
                          ID# {returnOrderData?.ondcInfo?.bapOrderId}
                        </Typography>
                      </Box>
                    </Box>
                    <Box flex={1} display={"flex"} gap={2}>
                      <Typography
                        sx={{ ...styles.title, display: "inline-block" }}
                      >
                        {" "}
                        <Typography
                          sx={{
                            ...styles.title,
                            fontSize: 12,
                            color: orderType?.color,
                            display: "inline-block",
                            ml: 3,
                          }}
                        >
                          {orderType?.label}
                          {orderType?.orderType === "ondcOrder" ? (
                            <span style={{ color: blackShade19 }}>
                              {" "}
                              -{" "}
                              {getOndcBapNameBySubscriberId(
                                returnOrderData?.ondcInfo?.bapId
                              )}
                            </span>
                          ) : null}
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={6} md={2.1}>
                  <InfoBox
                    infoTitle="PRICE & QTY"
                    titleIcon={<CreditCardIcon sx={{ fontSize: "20px" }} />}
                    title={`₹ ${addThousandsSeparator(
                      returnOrderData?.orderTotalCost
                    )}`}
                    subtitleIcon={
                      <ShoppingCartIcon
                        sx={{ fontSize: "16px", color: bgColor15 }}
                      />
                    }
                    subtitle={returnOrderData?.totalQuantity}
                  />
                </Grid>

                <Grid item xs={6} md={2}>
                  <InfoBox
                    infoTitle={
                      type === "view"
                        ? "RETURN REQUESTED"
                        : "RETURN(PRICE & QTY)"
                    }
                    titleIcon={<CreditCardIcon sx={{ fontSize: "20px" }} />}
                    title={`₹ ${
                      type === "view"
                        ? addThousandsSeparator(totalRefundAmount)
                        : addThousandsSeparator(
                            Number(
                              returnOrderData?.returnOrderInfo
                                ?.refundTotalAmount
                            )?.toFixed(2) || "-"
                          )
                    }`}
                    subtitleIcon={
                      <ShoppingCartIcon
                        sx={{ fontSize: "16px", color: bgColor15 }}
                      />
                    }
                    subtitle={getAllReturnQty(
                      returnOrderData?.returnOrderInfo?.partialProducts || []
                    )}
                  />
                </Grid>

                <Grid item xs={6} md={2}>
                  <InfoBox
                    infoTitle="RETURN PROCESSED"
                    titleIcon={<CreditCardIcon sx={{ fontSize: "20px" }} />}
                    title={`₹ ${addThousandsSeparator(
                      Number(
                        returnOrderData?.returnOrderInfo?.refundTotalAmount
                      )?.toFixed(2) || "-"
                    )}`}
                    hideQty
                    // subtitleIcon={
                    //   <ShoppingCartIcon
                    //     sx={{ fontSize: "16px", color: bgColor15 }}
                    //   />
                    // }
                    // subtitle={getAllReturnQty(
                    //   returnOrderData?.returnOrderInfo?.partialProducts || []
                    // )}
                  />
                </Grid>

                <Grid item xs={6} md={2}>
                  <Stack flexDirection="row" alignItems="center" gap={4}>
                    <Box sx={styles.infoTab}>
                      <Typography
                        variant="h5"
                        fontSize={12}
                        fontWeight={600}
                        color={orangeStatusColor}
                        lineHeight="20px"
                      >
                        Requested
                      </Typography>

                      <Typography
                        variant="p"
                        fontSize={12}
                        fontWeight={500}
                        color={bgColor15}
                        lineHeight="18px !important"
                      >
                        {returnOrderData?.returnOrderInfo?.createdOn
                          ? moment(
                              returnOrderData?.returnOrderInfo?.createdOn
                            ).format("DD MMM, hh:mm A")
                          : "N/A"}
                      </Typography>
                    </Box>

                    {data?.orderType === "completed" && (
                      <Box sx={styles.infoTab}>
                        <Typography
                          variant="h5"
                          fontSize={12}
                          fontWeight={600}
                          color={copyBtnColor}
                          lineHeight="20px"
                        >
                          Completed
                        </Typography>

                        <Typography
                          variant="p"
                          fontSize={12}
                          fontWeight={500}
                          color={bgColor15}
                          lineHeight="18px !important"
                        >
                          {returnOrderData?.returnOrderInfo?.updatedOn
                            ? moment(
                                returnOrderData?.returnOrderInfo?.updatedOn
                              ).format("DD MMM, hh:mm A")
                            : "N/A"}
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <MobileReturnsCard
              getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
              orderType={orderType}
              item={returnOrderData}
              id={returnOrderData?.orderId}
              date={moment(returnOrderData?.orderCreatedOn)?.format(
                "DD MMM YYYY"
              )}
              ondcID={returnOrderData?.ondcInfo?.bapOrderId}
              orderCost={returnOrderData?.orderTotalCost}
              cartCount={returnOrderData?.totalQuantity}
              refundTotalAmt={
                type === "view"
                  ? addThousandsSeparator(totalRefundAmount)
                  : addThousandsSeparator(
                      Number(
                        returnOrderData?.returnOrderInfo?.refundTotalAmount
                      )?.toFixed(2)
                    )
              }
              refundProcessed={
                addThousandsSeparator(
                  Number(
                    returnOrderData?.returnOrderInfo?.refundTotalAmount
                  )?.toFixed(2)
                ) || "-"
              }
              returnQty={getAllReturnQty(
                returnOrderData?.returnOrderInfo?.partialProducts || []
              )}
              requestedDate={
                returnOrderData?.returnOrderInfo?.createdOn
                  ? moment(returnOrderData?.returnOrderInfo?.createdOn).format(
                      "DD MMM, hh:mm A"
                    )
                  : ""
              }
              completedDate={
                returnOrderData?.returnOrderInfo?.updatedOn
                  ? moment(returnOrderData?.returnOrderInfo?.updatedOn).format(
                      "DD MMM, hh:mm A"
                    )
                  : "N/A"
              }
              isCompleted={data?.orderType === "completed"}
              hideBtns
            />
          )}

          <Typography
            variant="h5"
            fontSize={{ xs: 14, md: 15 }}
            fontWeight={600}
            color={blackShade19}
            mb={1.5}
          >
            Returns
          </Typography>

          {returnedProducts?.map((item, index) => {
            return (
              <ReturnProductInfoCard
                key={`product_${index}`}
                productInfo={item}
                productId={item?.productId}
                partialReturned={
                  returnOrderData?.returnOrderInfo?.partialProducts || []
                }
                actionReasons={actionReasons}
                type={type || "view"}
                handleUpdateAction={handleUpdateAction}
                onViewImage={(imageUrl) => {
                  setOpenImageView({
                    show: true,
                    imageUrl: imageUrl,
                  });
                }}
              />
            );
          })}

          <Typography fontSize={11.5} fontWeight={500} color={redColor}>
            {errorMsg}
          </Typography>
        </Box>

        {type === "action" && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p="15px 20px"
            bgcolor={BGcolor01}
          >
            <Button
              variant="outlined"
              disableElevation
              sx={styles.btn}
              onClick={() => handleCancel()}
              disabled={loader}
            >
              CANCEL
            </Button>

            <Button
              variant="contained"
              disableElevation
              sx={{ ...styles.btn, ...styles.processBtn }}
              onClick={handleSubmit}
              disabled={loader}
              startIcon={
                loader ? <CircularProgress color="inherit" size={18} /> : null
              }
            >
              PROCESS RETURNS
            </Button>
          </Box>
        )}
      </Box>

      <ModalCard
        handleClose={() => setOpenImageView({ show: false, imageUrl: "" })}
        open={openImageView.show}
        width="30%"
      >
        <Box
          sx={{ bgcolor: whiteColor, borderRadius: "10px", overflow: "hidden" }}
        >
          <Box sx={{ p: 2, bgcolor: BGColor02 }}>
            <Typography fontSize={16} fontWeight={500} color={blackShade19}>
              Image
            </Typography>
          </Box>

          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={openImageView?.imageUrl}
              alt=""
              sx={styles.img}
            />
          </Box>

          <Box
            sx={{
              p: 2,
              bgcolor: BGColor02,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              disableElevation
              sx={{ ...styles.btn, ...styles.processBtn }}
              onClick={() => setOpenImageView({ show: false, imageUrl: "" })}
            >
              CLOSE
            </Button>
          </Box>
        </Box>
      </ModalCard>
    </Box>
  );
};

export default ViewReturnOrderPopupContent;

const InfoBox = ({
  infoTitle,
  titleIcon,
  title,
  subtitleIcon,
  subtitle,
  hideQty,
  alignRight,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box sx={styles.infoCard(alignRight)}>
      <Typography
        fontSize={11}
        fontWeight={500}
        color={bgColor15}
        m="-3px 0 2px"
      >
        {infoTitle}
      </Typography>

      <Stack flexDirection="row" gap={2}>
        <Box display="flex" alignItems="center" gap="10px">
          {matches ? titleIcon : null}

          <Typography
            variant="h5"
            fontSize={12.5}
            fontWeight={600}
            color={blackShade05}
            lineHeight="20px"
            sx={{ ...textOneLines }}
          >
            {title}
          </Typography>
        </Box>

        {!hideQty && (
          <Box display="flex" alignItems="center" gap="0px">
            {matches ? subtitleIcon : null}

            <Typography
              variant="p"
              fontSize={12.5}
              fontWeight={500}
              color={bgColor15}
              lineHeight="18px !important"
              ml={`${subtitleIcon ? 6 : 30}px !important`}
            >
              {subtitle}
            </Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: { xs: "10px", md: "12px" },
    maxHeight: { md: "95vh", xs: "90vh" },
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
      // height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blackShade159,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },

  orderInfoCard: {
    border: border25,
    p: "10px 20px",
    borderRadius: "10px",
    mb: 2,
  },

  infoCard: (alignRight) => ({
    display: "flex",
    flexDirection: { xs: "column", md: "column" },
    justifyContent: { xs: "space-between", md: "flex-start" },
    alignItems: {
      md: "flex-start",
      xs: alignRight ? "flex-end" : "flex-start",
    },
    flex: 1,
  }),

  infoTab: {
    display: "flex",
    alignItems: { xs: "flex-start", md: "flex-start" },
    flexDirection: { xs: "column", md: "column" },
    justifyContent: { xs: "space-between", md: "flex-start" },
    pr: { xs: 0, md: 2 },
    // borderRight: { xs: "none", md: orderCardBorder2 },
  },

  btn: {
    fontSize: 12,
    fontWeight: 600,
    p: "4px 20px",
    color: blackShade19,
    bgcolor: whiteColor,
    border: `1px solid ${blackShade19}`,

    "&:hover": {
      color: whiteColor,
      bgcolor: blackShade19,
      border: `1px solid ${blackShade19}`,
    },
  },

  processBtn: {
    color: whiteColor,
    bgcolor: blueColor04,
    border: `1px solid ${blueColor04}`,

    "&:hover": {
      color: blueColor04,
      bgcolor: whiteColor,
      border: `1px solid ${blueColor04}`,
    },
  },

  img: {
    width: "350px",
    height: "350px",
    border: border25,
    borderRadius: "7px",
    objectFit: "contain",
  },
};
