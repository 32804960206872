import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {  getAllBusinessCustomersApi } from "../../service/api_calls/BusinessDashboard/BusinessDashboardApi";
import { getAllBusinessProfileIDApi } from "../../service/api_calls/preferredDiscounts/PreferredDiscountsApi";

const initialState = {
  businessCustomers: {
    details: [],
    profileInfo: [],
  },
  businessCustomersPageNo: 0,
  businessCustomerLoader: false,
};

// Get All Business customers
export const getAllBusinessCustomersData = createAsyncThunk(
  "getAllBusinessCustomersData",
  async (param, thunkAPI) => {
    const res = await getAllBusinessCustomersApi(param);
    const profileId = res.details?.map(
      (item, index) => item?.contactInfo?.profileId
    );
    console.log(profileId);

    const uniqueprofileIds = [];

    for (const item of profileId) {
      if (!uniqueprofileIds.includes(item)) {
        uniqueprofileIds.push(item);
      }
    }
    console.log("uniqueprofileIds_CRM:", uniqueprofileIds);

    let profileParams = "?";
    uniqueprofileIds.forEach((element, index) => {
      profileParams =
        profileParams +
        (index === uniqueprofileIds.length - 1
          ? `params=${element}`
          : `params=${element}&`);
    });
    console.log("profileParams:", profileParams);

    const profileInfo = await getAllBusinessProfileIDApi({
      ...param,
      profileParams,
    });
    console.log("ProfileInfo:", profileInfo);

    return {
       BusinessDetails: res,
      profileInfo,
    };
    
  }
);

export const businessCustomers = createSlice({
  name: "businessCustomers",
  initialState,
  reducers: {
    
    clearCRMInfo:(state, action) => {
      state.businessCustomers = {
        details: [],
        profileInfo: [],
      };
      state.businessCustomersPageNo=0;
    }
  },
  extraReducers: {
    //fetch active business info
    [getAllBusinessCustomersData.pending]: (state) => {
      state.businessCustomersLoader = true;
      // state.businessCustomers = null;
    },
    [getAllBusinessCustomersData.fulfilled]: (state, { payload }) => {
       const paylodData= payload?.BusinessDetails;
       const crmCustomers = paylodData?.details || [];
       const profileInfo = payload?.profileInfo?.accountList || [];

      console.log("CUSTOMER", profileInfo);
      if (crmCustomers?.length > 0) {
        state.businessCustomers = {
          details: [...state.businessCustomers.details, ...crmCustomers],
          profileInfo: [...state.businessCustomers.profileInfo, ...profileInfo],
        };
        state.businessCustomersPageNo = state.businessCustomersPageNo + 1;
      }
      state.businessCustomersLoader = false;
    },
    [getAllBusinessCustomersData.rejected]: (state, { payload }) => {
      state.businessCustomersLoader = false;
      // state.businessCustomers = null;
    },

    
  },
});

export const {  clearCRMInfo } = businessCustomers.actions;

export const businessCustomersReducer = businessCustomers.reducer;


