import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getActiveBusinessAPI,
  getActiveProductsByBusinessAPI,
  getBusinessProductsByStatusAPI,
  getBuyerAppLookupAPI,
  getCommerceInfoAPI,
  getMyBusinessAPI,
  getONDCCategoriesAPI,
  getProductCategoriesAPI,
  getProductsByBusinessAPI,
  searchBusinessProductsAPI,
} from "../../service/api_calls/BusinessPreview/BusinessPreviewAPI";

const initialState = {
  activeBusinessInfo: null,
  businessProducts: null,
  searchProducts: null,
  productCategory: null,
  myBusinessData: null,
  commerceInfo: null,
  pendingProducts: null,
  updatedPendingProducts: null,
  rejectedProducts: null,

  activeBusinessProducts: [],
  totalProductCount: null,
  activePageNo: 1,
  activeBusinessProductsLoader: false,

  ondcCategory: null,

  buyerAppData: [],
  buyerAppDataLoader: false,

  activeBusinessInfoLoader: false,
  businessProductsLoader: false,
  searchProductsLoader: false,
  productCategoryLoader: false,
  myBusinessDataLoader: false,
  commerceInfoLoader: false,
  productsByStatusLoader: false,
  ondcCategoryLoader: false,
};

//get active Business details
export const getActiveBusinessInfo = createAsyncThunk(
  "getActiveBusinessInfo",
  async (param, thunkAPI) => {
    const res = await getActiveBusinessAPI(param);
    return res;
  }
);

//get products by business
export const getProductsByBusiness = createAsyncThunk(
  "getProductsByBusiness",
  async (param, thunkAPI) => {
    const res = await getProductsByBusinessAPI(param);
    return res;
  }
);

//get products by business
export const getActiveProductsByBusiness = createAsyncThunk(
  "getActiveProductsByBusiness",
  async (param, thunkAPI) => {
    const res = await getActiveProductsByBusinessAPI(param);
    return res;
  }
);

//get business pending products
export const getBusinessPendingProducts = createAsyncThunk(
  "getBusinessPendingProducts",
  async (param, thunkAPI) => {
    const pendingProductList = await getBusinessProductsByStatusAPI(param);
    return pendingProductList;
  }
);

//get business updated pending products
export const getBusinessUpdatedPendingProducts = createAsyncThunk(
  "getBusinessUpdatedPendingProducts",
  async (param, thunkAPI) => {
    const pendingProductList = await getBusinessProductsByStatusAPI(param);
    return pendingProductList;
  }
);

//get business rejects products
export const getBusinessRejectsProducts = createAsyncThunk(
  "getBusinessRejectsProducts",
  async (param, thunkAPI) => {
    const res = await getBusinessProductsByStatusAPI(param);
    return res;
  }
);

//search Products
export const searchBusinessProducts = createAsyncThunk(
  "searchBusinessProducts",
  async (param, thunkAPI) => {
    const res = await searchBusinessProductsAPI(param);
    return res;
  }
);

//get Product categories
export const getProductCategories = createAsyncThunk(
  "getProductCategories",
  async (param, thunkAPI) => {
    const res = await getProductCategoriesAPI(param);
    return res;
  }
);
//get ONDC categories
export const getONDCCategories = createAsyncThunk(
  "getONDCCategories",
  async (param, thunkAPI) => {
    const res = await getONDCCategoriesAPI(param);
    return res;
  }
);

// view My Business Data
export const viewMyBusinessData = createAsyncThunk(
  "viewMyBusinessData",
  async (param, thunkAPI) => {
    const res = await getMyBusinessAPI(param);
    return res;
  }
);

// get business commerce info
export const getCommerceInfo = createAsyncThunk(
  "getCommerceInfo",
  async (param, thunkAPI) => {
    const res = await getCommerceInfoAPI(param);
    return res;
  }
);

// get business commerce info
export const getBuyerAppLookup = createAsyncThunk(
  "getBuyerAppLookup",
  async (param, thunkAPI) => {
    const res = await getBuyerAppLookupAPI(param);
    return res;
  }
);

export const businessPreview = createSlice({
  name: "businessPreview",
  initialState,
  reducers: {
    clearCommerceInfo: (state, action) => {
      state.commerceInfo = null;
      state.activeBusinessProducts = [];
      state.totalProductCount = null;
      state.activePageNo = 1;
    },
    clearSearchProducts: (state, action) => {
      state.searchProducts = null;
    },
    clearViewMyBusinessData: (state, action) => {
      state.myBusinessData = null;
    },

    clearBusinessPreviewData: (state, action) => {
      state.myBusinessData = null;
      state.activeBusinessInfo = null;
      state.businessProducts = null;
      state.activeBusinessProducts = [];
      state.totalProductCount = null;
      state.activePageNo = 1;
      state.searchProducts = null;
      state.productCategory = null;
      state.myBusinessData = null;
      state.commerceInfo = null;
      console.log("Hit--1");
      state.pendingProducts = null;
      state.updatedPendingProducts = null;
      state.rejectedProducts = null;
      state.ondcCategory = null;
    },

    clearActiveBusinessProducts: (state, action) => {
      state.activeBusinessProducts = [];
      state.activePageNo = 1;
    },

    clearCatalogData: (state, action) => {
      state.businessProducts = null;
      state.activeBusinessProducts = [];
      state.totalProductCount = null;
      state.activePageNo = 1;
      state.searchProducts = null;
      state.pendingProducts = null;
      state.updatedPendingProducts = null;
      state.rejectedProducts = null;
    },
  },
  extraReducers: {
    //fetch active business info
    [getActiveBusinessInfo.pending]: (state) => {
      state.activeBusinessInfoLoader = true;
      state.activeBusinessInfo = null;
    },
    [getActiveBusinessInfo.fulfilled]: (state, { payload }) => {
      state.activeBusinessInfo = payload?.viewBusiness;
      state.activeBusinessInfoLoader = false;
    },
    [getActiveBusinessInfo.rejected]: (state, { payload }) => {
      state.activeBusinessInfoLoader = false;
      state.activeBusinessInfo = null;
    },

    //get products by business
    [getProductsByBusiness.pending]: (state) => {
      state.businessProductsLoader = true;
      state.businessProducts = null;
    },
    [getProductsByBusiness.fulfilled]: (state, { payload }) => {
      state.businessProducts = payload;
      state.businessProductsLoader = false;
    },
    [getProductsByBusiness.rejected]: (state, { payload }) => {
      state.businessProductsLoader = false;
      state.businessProducts = null;
    },

    //get products by business
    [getActiveProductsByBusiness.pending]: (state) => {
      state.activeBusinessProductsLoader = state.activePageNo == 1;
    },
    [getActiveProductsByBusiness.fulfilled]: (state, { payload }) => {
      state.totalProductCount = payload?.totalProduct;
      const productList = payload?.productList || [];
      if (productList.length > 0) {
        state.activeBusinessProducts = [
          ...state.activeBusinessProducts,
          ...productList,
        ];
        state.activePageNo = state.activePageNo + 1;
      }
      state.activeBusinessProductsLoader = false;
    },
    [getActiveProductsByBusiness.rejected]: (state, { payload }) => {
      state.activeBusinessProductsLoader = false;
    },

    //get business pending products
    [getBusinessPendingProducts.pending]: (state) => {
      state.productsByStatusLoader = true;
      state.pendingProducts = null;
    },
    [getBusinessPendingProducts.fulfilled]: (state, { payload }) => {
      state.pendingProducts = payload;
      state.productsByStatusLoader = false;
    },
    [getBusinessPendingProducts.rejected]: (state, { payload }) => {
      state.productsByStatusLoader = false;
      state.pendingProducts = null;
    },

    //get business updated pending products
    [getBusinessUpdatedPendingProducts.pending]: (state) => {
      state.productsByStatusLoader = true;
      state.updatedPendingProducts = null;
    },
    [getBusinessUpdatedPendingProducts.fulfilled]: (state, { payload }) => {
      state.updatedPendingProducts = payload;
      state.productsByStatusLoader = false;
    },
    [getBusinessUpdatedPendingProducts.rejected]: (state, { payload }) => {
      state.productsByStatusLoader = false;
      state.updatedPendingProducts = null;
    },

    //get business rejected products
    [getBusinessRejectsProducts.pending]: (state) => {
      state.productsByStatusLoader = true;
      state.rejectedProducts = null;
    },
    [getBusinessRejectsProducts.fulfilled]: (state, { payload }) => {
      state.rejectedProducts = payload;
      state.productsByStatusLoader = false;
    },
    [getBusinessRejectsProducts.rejected]: (state, { payload }) => {
      state.productsByStatusLoader = false;
      state.rejectedProducts = null;
    },

    //search business Products
    [searchBusinessProducts.pending]: (state) => {
      state.searchProductsLoader = true;
      state.searchProducts = null;
    },
    [searchBusinessProducts.fulfilled]: (state, { payload }) => {
      state.searchProducts = payload;
      state.searchProductsLoader = false;
    },
    [searchBusinessProducts.rejected]: (state, { payload }) => {
      state.searchProductsLoader = false;
      state.searchProducts = null;
    },

    //get Product categories
    [getProductCategories.pending]: (state) => {
      state.productCategoryLoader = true;
      state.productCategory = null;
    },
    [getProductCategories.fulfilled]: (state, { payload }) => {
      state.productCategory = payload;
      state.productCategoryLoader = false;
    },
    [getProductCategories.rejected]: (state, { payload }) => {
      state.productCategoryLoader = false;
      state.productCategory = null;
    },

    //get Ondc categories
    [getONDCCategories.pending]: (state) => {
      state.ondcCategoryLoader = true;
      state.ondcCategory = null;
    },
    [getONDCCategories.fulfilled]: (state, { payload }) => {
      state.ondcCategory = payload;
      state.ondcCategoryLoader = false;
    },
    [getONDCCategories.rejected]: (state, { payload }) => {
      state.ondcCategoryLoader = false;
      state.ondcCategory = null;
    },

    //view My Business Data
    [viewMyBusinessData.pending]: (state) => {
      state.myBusinessDataLoader = true;
      state.myBusinessData = null;
    },
    [viewMyBusinessData.fulfilled]: (state, { payload }) => {
      state.myBusinessData = payload;
      state.myBusinessDataLoader = false;
    },
    [viewMyBusinessData.rejected]: (state, { payload }) => {
      state.myBusinessDataLoader = false;
      state.myBusinessData = null;
    },

    //get business commerce info
    [getCommerceInfo.pending]: (state) => {
      state.commerceInfoLoader = true;
      // state.commerceInfo = null;
    },
    [getCommerceInfo.fulfilled]: (state, { payload }) => {
      state.commerceInfo = payload;
      state.commerceInfoLoader = false;
    },
    [getCommerceInfo.rejected]: (state, { payload }) => {
      state.commerceInfoLoader = false;
      state.commerceInfo = null;
    },

    //get business commerce info
    [getBuyerAppLookup.pending]: (state) => {
      state.buyerAppDataLoader = true;
      state.buyerAppData = null;
    },
    [getBuyerAppLookup.fulfilled]: (state, { payload }) => {
      let buyerApps = payload?.details || [];
      buyerApps?.sort((a, b) => {
        // Convert names to lowercase for case-insensitive sorting
        const nameA = a?.displayName?.toLowerCase();
        const nameB = b?.displayName?.toLowerCase();

        // Compare the names
        if (nameA < nameB) {
          return -1; // nameA comes before nameB
        }
        if (nameA > nameB) {
          return 1; // nameA comes after nameB
        }
        return 0; // names are equal
      });
      state.buyerAppData = buyerApps;
      state.buyerAppDataLoader = false;
    },
    [getBuyerAppLookup.rejected]: (state, { payload }) => {
      state.buyerAppDataLoader = false;
      state.buyerAppData = null;
    },
  },
});

export const {
  clearCommerceInfo,
  clearSearchProducts,
  clearViewMyBusinessData,
  clearBusinessPreviewData,
  clearCatalogData,
  clearActiveBusinessProducts,
} = businessPreview.actions;

export const businessPreviewReducer = businessPreview.reducer;
