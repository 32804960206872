import React, { useState } from "react";
import { Box, Typography, Avatar, Button } from "@mui/material";
import {
  blackColor,
  blackShade19,
  blackShade42,
  blueColor04,
  greenColor4,
  greyColor7,
  orangeStatusColor,
  redColor05,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ProfileCharAvatar from "../../../components/ProfileCharAvatar";
import moment from "moment";
import ModalCard from "../../../components/ModalCard";
import AlertCard from "../../../components/Alerts/AlertCard";

function PendingAdminCard({
  profileImg,
  name,
  phoneNumber,
  handleNotify,
  handleDelete,
  deleteLoader,
  createdOn,
  owner,
}) {
  const [deleteAlert, setDeleteAlert] = useState(false);
  return (
    <Box>
      {/* {owner && (
        <Typography
          component="h6"
          fontSize={12.5}
          color={greyColor7}
          fontWeight={400}
          lineHeight="20px"
          textAlign={"center"}
          pb={1.5}
        >
          Access granted on:{" "}
          <span style={{ color: orangeStatusColor }}>
            {moment(createdOn).format("DD MMM YYYY")} (
            {moment(createdOn).fromNow()})
          </span>
        </Typography>
      )} */}
      <Box
        sx={{ ...styles.card, display: { xs: "none", md: "flex" } }}
        textAlign="center"
        mt="32px"
      >
        <Box position="absolute" top={"-32px"} left={"calc(50%-26px)"}>
          {profileImg ? (
            <Avatar
              alt={name}
              src={profileImg}
              sx={{
                width: 52,
                height: 52,
                border: "2px solid #e2e5ec",
              }}
            />
          ) : (
            <ProfileCharAvatar username={name || "Guest"} border size="50px" />
          )}

          {/* <Box
        sx={{
          width: "13px",
          height: "13px",
          borderRadius: "10px",
          // backgroundColor: statusColor || orangeStatusColor,
          position: "absolute",
          bottom: 0,
          right: 3,
          // border: `2px solid ${whiteColor}`,
        }}
      /> */}
        </Box>

        <Typography
          fontSize={14}
          fontWeight={600}
          color={blueColor04}
          lineHeight="20px"
          textAlign="center"
          mt="6px"
        >
          {name}
        </Typography>

        <Typography
          fontSize={12}
          fontWeight={500}
          color={greyColor7}
          lineHeight="22px"
          textAlign="center"
        >
          {phoneNumber}
        </Typography>
        <Typography
          fontSize={11.5}
          fontWeight={500}
          color={blackShade19}
          lineHeight="22px"
          textAlign="center"
          mb="7px"
        >
          User yet to register in CostBo
        </Typography>
        {/* {!owner && ( */}
          <Box display="flex" gap={0.5}>
            <Typography
              component="h6"
              fontSize={11.5}
              color={greyColor7}
              fontWeight={400}
              lineHeight="20px"
              pb={1.5}
            >
              Access granted on:{" "}
              <span style={{ color: orangeStatusColor, fontWeight: 600 }}>
                {moment(createdOn).format("DD MMM YYYY")} (
              </span>
              <span style={{ fontSize:'11px', color: orangeStatusColor}}>{moment(createdOn).fromNow()})</span>
            </Typography>
            {/* <Box>
              <Typography
                component="h6"
                fontSize={11.5}
                color={orangeStatusColor}
                fontWeight={500}
                lineHeight="20px"
              >
                {moment(createdOn).format("DD MMM YYYY")}
              </Typography>
              <Typography
                fontSize={11.5}
                color={orangeStatusColor}
                fontWeight={500}
                lineHeight="20px"
                pb={1.5}
              >
                ({moment(createdOn).fromNow()})
              </Typography>
            </Box> */}
          </Box>
        {/* )} */}
        <Box display="flex" gap={2}>
          <Button sx={styles.notifyBtn} onClick={handleNotify}>
            Notify
          </Button>
          <Button
            sx={styles.deleteBtn}
            onClick={() => setDeleteAlert(true)}
            disabled={deleteLoader}
          >
            Delete
          </Button>
        </Box>
      </Box>
      <Box sx={{ ...styles.card, display: { md: "none", xs: "flex" } }}>
        <Box display="flex" gap={2} width="100%" alignItems={"center"}>
          <Box>
            {profileImg ? (
              <Avatar
                alt={name}
                src={profileImg}
                sx={{
                  width: 42,
                  height: 42,
                  border: "2px solid #e2e5ec",
                }}
              />
            ) : (
              <ProfileCharAvatar
                username={name || "Guest"}
                border
                size="42px"
                fontSize="14px"
              />
            )}

            {/* <Box
        sx={{
          width: "13px",
          height: "13px",
          borderRadius: "10px",
          // backgroundColor: statusColor || orangeStatusColor,
          position: "absolute",
          bottom: 0,
          right: 3,
          // border: `2px solid ${whiteColor}`,
        }}
      /> */}
          </Box>
          <Box flex={1}>
            <Typography
              fontSize={14}
              fontWeight={600}
              color={blackShade42}
              lineHeight="20px"
              mt="6px"
            >
              {name}
            </Typography>

            <Typography
              fontSize={12}
              fontWeight={500}
              color={greyColor7}
              lineHeight="22px"
            >
              {phoneNumber}
            </Typography>
            <Typography
              fontSize={11.5}
              fontWeight={500}
              color={blackShade19}
              lineHeight="22px"
              mb={owner ? "7px" : "7px"}
            >
              User yet to register in CostBo
            </Typography>
          </Box>
        </Box>
        {!owner && (
          <Box display="flex" width="100%">
            <Typography
              component="h6"
              fontSize={12.5}
              color={greyColor7}
              fontWeight={400}
              lineHeight="20px"
              pb={1.5}
            >
              Access granted on:{" "}
              <span style={{ color: orangeStatusColor }}>
                {moment(createdOn).format("DD MMM YYYY")} (
                {moment(createdOn).fromNow()})
              </span>
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent={{ md: "center", xs: "flex-end" }}
          width="100%"
          gap={2}
        >
          <Button sx={styles.notifyBtn} onClick={handleNotify}>
            Notify
          </Button>
          <Button
            sx={styles.deleteBtn}
            onClick={() => setDeleteAlert(true)}
            disabled={deleteLoader}
          >
            Delete
          </Button>
        </Box>
      </Box>

      <ModalCard
        open={deleteAlert}
        // open={true}
        handleClose={() => setDeleteAlert(false)}
        width="30%"
      >
        <AlertCard
          message={`Do you want to delete this ${
            owner ? "Admin" : "Manager"
          } - "${name}"`}
          onYes={handleDelete}
          yesloader={deleteLoader}
          onCancel={() => setDeleteAlert(false)}
        />
      </ModalCard>
    </Box>
  );
}

export default PendingAdminCard;

const styles = {
  card: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: { md: "column", xs: "column" },
    alignItems: { md: "center", xs: "center" },
    justifyContent: { md: "center", xs: "center" },
    p: { xs: "6px 10px", md: "20px 10px 10px" },
    backgroundColor: whiteColor,
    // boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: { xs: "8px", md: "8px" },
    zIndex: 1,
  },
  notifyBtn: {
    backgroundColor: greenColor4,
    padding: { md: "3px 14px", xs: "2px 10px" },
    fontSize: { md: "12.5px", xs: "11.5px" },
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + greenColor4,
    "&:hover": {
      color: greenColor4,
      backgroundColor: whiteColor,
    },
  },
  deleteBtn: {
    backgroundColor: whiteColor,
    padding: { md: "3px 14px", xs: "2px 10px" },
    fontSize: { md: "12.5px", xs: "11.5px" },
    fontWeight: 600,
    color: redColor05,
    border: "1.5px solid " + redColor05,
    "&:hover": {
      color: whiteColor,
      backgroundColor: redColor05,
    },
  },
};
