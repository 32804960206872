import {
  Box,
  Button,
  IconButton,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  blackColor,
  blackShade05,
  blueColor04,
  borderTextbox,
  whiteColor,
} from "../../../../configs/styles/muiThemes";
import { textOneLines, textTwoLines } from "../../../../utils/styles";
import ModalCard from "../../../../components/ModalCard";
import ErrorToolTipIcon from "../ErrorToolTipIcon";
import EditIcon from "@mui/icons-material/Edit";
import { allowOnlyEnglish } from "../../../../utils/helper";

const DescriptionInput = ({ description, onChange, errorMsg }) => {
  const [openModal, setOpenModal] = useState(false);
  const [text, setText] = useState(description);

  return (
    <Box position="relative">
      {errorMsg && (
        <Box sx={{ position: "absolute", right: -14, top: -12, zIndex: 1 }}>
          <ErrorToolTipIcon errorMsg={errorMsg} />
        </Box>
      )}

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{
            flex: 1,
            fontSize: 12,
            color: blackColor,
            ...textOneLines,
            lineHeight: "18px",
          }}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          {description}
        </Typography>

        <IconButton
          onClick={() => {
            setOpenModal(true);
          }}
          sx={{ p: 0 }}
        >
          <EditIcon sx={{ fontSize: "18px", color: blueColor04 }} />
        </IconButton>
      </Box>

      <ModalCard
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        width="60%"
      >
        <Box sx={{ p: 3, backgroundColor: whiteColor, borderRadius: "10px" }}>
          <Typography
            variant="body1"
            fontSize={15}
            fontWeight={500}
            color={blackColor}
            mb={2}
          >
            Description
          </Typography>

          <Box
            sx={{
              border: borderTextbox,
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <TextareaAutosize
              minRows={3}
              aria-label="maximum height"
              style={{
                fontSize: 12,
                fontWeight: 500,
                color: blackShade05,
                display: "block",
                width: "100%",
                border: "none",
                borderRadius: "0px",
                backgroundColor: whiteColor,
                padding: "10px 15px",
              }}
              placeholder="Enter Description"
              value={text}
              onChange={({ target }) => {
                const finalValue = allowOnlyEnglish(target.value);
                setText(finalValue);
              }}
              maxLength={5000}
            />
          </Box>

          <Stack alignItems="flex-end">
            <Button
              variant="contained"
              disableElevation
              sx={styles.applyBtn}
              onClick={() => {
                onChange(text);
                setOpenModal(false);
              }}
            >
              UPDATE
            </Button>
          </Stack>
        </Box>
      </ModalCard>
    </Box>
  );
};

export default DescriptionInput;

const styles = {
  applyBtn: {
    fontSize: 13,
    fontWeight: 500,
    backgroundColor: blueColor04,
    border: `1.5px solid ${blueColor04}`,
    color: whiteColor,
    p: "4px 30px",
    mt: 2,
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
    },
  },
};
