import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import InfoCardHeader from "./InfoCardHeader";
import {
  blackShade05,
  blackShade21,
  blueColor02,
  blueColor04,
  greyColor6,
  lightBlueColor05,
  logoImgborder1,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import { addThousandsSeparator } from "../../../utils/helper";
import LoyaltyPointEmptyCard from "../EmptyCards/LoyaltyPointEmptyCard";

function CashbackInfoCard({
  businessData,
  cashbackCount,
  cashbackSummary,
  availableCashback,
  onClick,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const cashbackPercentage = Number(cashbackSummary?.rewardPointPercent) || 0;
  const totalCashbackOrder =
    Number(cashbackCount?.totalCashbackOrderNumbers) || 0;
  const totalOrderValue = Number(cashbackCount?.totalCashbackOrderValue) || 0;

  return (
    <Box sx={styles.card}>
      <InfoCardHeader title="Loyalty Points" onManageClick={onClick} />

      {cashbackPercentage === 0 &&
      availableCashback?.totalAvailableCashBackValueForBusiness === 0 ? (
        <LoyaltyPointEmptyCard
          activeBusinessInfo={businessData}
          onClick={onClick}
        />
      ) : (
        <>
          <Typography
            color={blackShade21}
            fontSize={{ xs: 12, md: 13 }}
            fontWeight={500}
            lineHeight={{ xs: 1, md: 1.5 }}
            sx={{ whiteSpace: "pre-line", mt: 1 }}
          >
            {`To encourage repeat customer purchase enable `}
            <Typography
              fontSize={{ xs: 12, md: 13 }}
              fontWeight={500}
              sx={{
                color: { xs: mobilePrimary, md: blueColor04 },
                display: "inline",
              }}
            >
              Loyalty Points Program
            </Typography>
          </Typography>

          <Box
            display="flex"
            width="100%"
            backgroundColor="#fff"
            gap={2}
            mt={1.5}
          >
            <CashbackStatInfo
              icon={<SavingsOutlinedIcon sx={styles.icon} />}
              value={`${cashbackPercentage}%`}
              label="CASHBACK %"
            />

            <CashbackStatInfo
              icon={<PaymentsOutlinedIcon sx={styles.icon} />}
              value={`${addThousandsSeparator(totalCashbackOrder, true)}`}
              label="ORDERS PLACED"
            />

            {/* <CashbackStatInfo
          icon={<CreditScoreOutlinedIcon sx={{ fontSize: {xs:'19px', md:"26px"} }} />}
          value={totalOrderValue === 0 ? '₹ 0' : `₹ ${addThousandsSeparator(totalOrderValue)}`}
          label="ORDER VALUE"
          valueColor={blueColor04}
        /> */}
            <CashbackStatInfo
              icon={<CreditScoreOutlinedIcon sx={styles.icon} />}
              value={`₹ ${addThousandsSeparator(
                availableCashback?.totalAvailableCashBackValueForBusiness
              )}`}
              label="CASHBACK BALANCE"
              valueColor={matches ? blueColor04 : blackShade05}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default CashbackInfoCard;

const CashbackStatInfo = ({ icon, value, label, valueColor }) => {
  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems={{ xs: "center", md: "flex-start" }}
      textAlign={{ xs: "center", md: "left" }}
    >
      <Box sx={styles.iconCircle}>{icon}</Box>

      <Typography
        variant="h5"
        fontSize={{xs:14, md:15}}
        fontWeight={600}
        color={valueColor || blackShade05}
        lineHeight="25px"
      >
        {value}
      </Typography>

      <Typography
        variant="h5"
        fontSize={{ md: 11.2, xs: 11 }}
        fontWeight={500}
        color={greyColor6}
      >
        {label}
      </Typography>
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: {xs:'10px', md:"13px"},
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 15px", md: "10px 15px" },
  },

  iconCircle: {
    width: { xs: "38px", md: "55px" },
    height: { xs: "38px", md: "55px" },
    borderRadius: "35px",
    backgroundColor: { xs: lightBlueColor05, md: "rgb(235 236 239)" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  },

  icon: {
    fontSize: { xs: "19px", md: "26px" },
    color: { xs: mobilePrimary, md: blackShade05 },
  },
};
