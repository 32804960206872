import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllCouponsCodeApi, getAllCouponsCountApi, getAllCouponsSummaryApi } from "../../service/api_calls/coupons/couponsApi";
import moment from "moment";


const initialState = {
  couponSummary: null,
  couponCount: null,
  couponsCode: null,

  couponSummaryLoader: false,
  couponCountLoader: false,
  couponsCodeLoader:false,
};

export const getAllCouponsSummaryData = createAsyncThunk(
  "getAllCouponsSummaryData",
  async (param, thunkAPI) => {
    const res = await getAllCouponsSummaryApi(param);
    return res;
  }
  );

export const getAllCouponsCountData = createAsyncThunk(
  "getAllCouponsCountData",
  async (param, thunkAPI) => {
    const res = await getAllCouponsCountApi(param);
    return res;
  }
);

export const getAllCouponsCodeData = createAsyncThunk(
  "getAllCouponsCodeData",
  async (param, thunkAPI) => {
    const res = await getAllCouponsCodeApi(param);
    return res;
  }
);



export const businessCoupons = createSlice({
  name: "businessCoupons",
  initialState,
  reducers: {
    clearCouponInfo: (state, action) => {
      state.couponSummary = null;
      state.couponCount = null;
      state.couponsCode = null;
    },
  },

  extraReducers: {
    //fetch all coupons summary (duplicate)
    [getAllCouponsSummaryData.pending]: (state) => {
      state.couponSummaryLoader = true;
      state.couponSummary = null;
    },
    [getAllCouponsSummaryData.fulfilled]: (state, { payload }) => {
      state.couponSummary = payload;
      state.couponSummaryLoader = false;
    },
    [getAllCouponsSummaryData.rejected]: (state, { payload }) => {
      state.couponSummaryLoader = false;
      state.couponSummary = null;
    },
    
    //fetch all coupons summary
    [getAllCouponsCodeData.pending]: (state) => {
      state.couponsCodeLoader = true;
      state.couponsCode = null;
    },
    [getAllCouponsCodeData.fulfilled]: (state, { payload }) => {
      state.couponsCode = payload;
      const dataList = payload?.details || [];

      const sorteddate= dataList?.sort((a, b) => {
        let na = moment(a?.codeValidityEnd).valueOf(),
          nb = moment(b?.codeValidityEnd).valueOf();

        if (na > nb) {
          return -1;
        }
        if (na < nb) {
          return 1;
        }
        return 0;
      });

      console.log("coupon Data", sorteddate);
      state.couponsCodeLoader = false;
    },
    [getAllCouponsCodeData.rejected]: (state, { payload }) => {
      state.couponsCodeLoader = false;
      state.couponsCode = null;
    },

    //fetch all coupons counts
    [getAllCouponsCountData.pending]: (state) => {
      state.couponCountLoader = true;
      state.couponCount = null;
    },
    [getAllCouponsCountData.fulfilled]: (state, { payload }) => {
      state.couponCount = payload;
      state.couponCountLoader = false;
    },
    [getAllCouponsCountData.rejected]: (state, { payload }) => {
      state.couponCountLoader = false;
      state.couponCount = null;
    },
  },
});

export const { clearCouponInfo } = businessCoupons.actions;

export const businessCouponsReducer = businessCoupons.reducer;
