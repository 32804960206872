import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { Box, Button, Typography } from "@mui/material";
import { GOOGLE_MAPS_API_KEY } from "../../configs/Constants";
import ModalCard from "../ModalCard";
import {
  blackColor,
  blueColor02,
  greenColor,
  greyColor8,
  menuGradient,
  mobilePrimary,
  whiteColor,
  yellowColor,
} from "../../configs/styles/muiThemes";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useEffect } from "react";
import { fetchAddressFromLatLng } from "../../utils/geoLocationHelper";

const PickAddressFromMapModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  userLat,
  userLong,
}) => {
  // const userLat = localStorage.getItem("lat");
  // const userLong = localStorage.getItem("long");

  const [mapCenter, setMapCenter] = useState({
    lat: 13,
    lng: 77.5946,
  });
  const [userAddress, setUserAddress] = useState("");

  const handleMapChange = async ({ center }) => {
    console.log(center);
    setMapCenter(center);
    const addressInfo = await fetchAddressFromLatLng(center?.lat, center?.lng);
    setUserAddress(addressInfo);
  };

  const handleConfirmAddress = () => {
    onConfirm(userAddress);
  };

  useEffect(() => {
    setMapCenter({
      lat: Number(userLat),
      lng: Number(userLong),
    });
    return () => {};
  }, [isOpen]);

  return (
    <ModalCard
      open={isOpen}
      handleClose={() => {
        setIsOpen({
          show: false,
          userLat: "",
          userLong: "",
        });
      }}
      width="42%"
    >
      <Box sx={styles.card}>
        <Typography
          variant="h6"
          fontSize={16}
          fontWeight={500}
          p={1.5}
          textAlign="center"
        >
          {/* {JSON.stringify(mapCenter)} --- {userLat} ,{" "}
          {userLong} */}
          Please pick the exact warehouse location for delivery pickups
        </Typography>

        <Box sx={styles.addressBox}>
          <Typography
            variant="body2"
            fontSize={12}
            fontWeight={500}
            color={whiteColor}
          >
            {userAddress?.fullAddress}
          </Typography>
        </Box>

        <Box sx={{ width: "100%", height: "400px" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
            center={mapCenter}
            defaultZoom={17}
            onChange={handleMapChange}
          >
            <Marker lat={mapCenter.lat} lng={mapCenter.lng} />
          </GoogleMapReact>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="20px"
          p="15px"
        >
          <Button
            sx={[styles.actionBtn, styles.cancelBtn]}
            onClick={() => {
              setIsOpen({
                show: false,
                userLat: "",
                userLong: "",
              });
            }}
          >
            CANCEL
          </Button>

          <Button
            sx={[styles.actionBtn, styles.proceedBtn]}
            onClick={handleConfirmAddress}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </ModalCard>
  );
};

const Marker = () => (
  <Box>
    <LocationOnIcon sx={{ fontSize: "36px", color: "red" }} />
  </Box>
);

export default PickAddressFromMapModal;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "10px",
    overflow: "hidden",
  },

  addressBox: {
    backgroundColor: greenColor,
    p: "4px 15px",
    textAlign: "center",
  },

  actionBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    color: greyColor8,
    border: `1px solid ${greyColor8}`,
    p: "5px 55px",
  },

  cancelBtn: {
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },

  proceedBtn: {
    color: {xs: whiteColor, md:blackColor},
    background: {xs: mobilePrimary, md:yellowColor},
    p: "6px 55px",
    border: {xs:`1px solid ${mobilePrimary}`, md:`1px solid ${yellowColor}`},
    "&:hover": {
      color: greyColor8,
      background: whiteColor,
      border: `1px solid ${greyColor8}`,
    },
  },
};
