import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardMenu from "./Dashboard/DashboardMenu";
import DashboardHeader from "./Dashboard/DashboardHeader";
import {
  BGColor02,
  blackShade05,
  greyColor10,
  greyColor24,
  iconBG1,
  iconBG2,
  whiteColor,
} from "../configs/styles/muiThemes";
import BusinessInfoCard from "../components/Cards/BusinessInfoCard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import EmptyCard from "../components/Cards/EmptyCard";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import SupportContent from "./Support/SupportContent";
import StatsInfoCard from "../components/Cards/StatsInfoCard";
import DashboardLayout from "../components/Layouts/DashboardLayout";

function Support() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
    }
  }, []);

  return (
    <Box sx={styles.container}>
      <DashboardLayout
        activeMenu="support"
        // disableAll={sessionStorage.getItem("selectedBusinessId") ? false : true}
        hideBusinessData={
          sessionStorage.getItem("selectedBusinessId") ? false : true
        }
        // disableAllMenu
      >
        <Box paddingTop={{xs:1,md:"none"}}>
          <SupportContent
            activeBusinessInfo={
              activeBusinessInfo ? activeBusinessInfo : "CostBo"
            }
          />
        </Box>
      </DashboardLayout>
    </Box>
  );
}

export default Support;

const styles = {
  container: {
    minHeight: { xs: "calc(100vh - 91px)", md: "100vh" },
    backgroundColor: BGColor02,
  },
};
