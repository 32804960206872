/* eslint-disable prefer-const */
import React, { useState } from 'react'
import { makeStyles } from '@mui/material'
import axios from 'axios'
import Paper from '@mui/material'
import Grid from '@mui/material'
import Typography from '@mui/material'

export function UseForm(
  initialFValues,
  errorValues,
  validateOnChange = true,
  validate
) {
  const [values, setValues] = useState(initialFValues)
  const [errors, setErrors] = useState(errorValues)

  const handleAnyOtherChange = (e) => {
    const { name, value } = e.target
    // console.log(e)
    setValues({
      ...values,
      [name]: value,
    })
    if (validateOnChange) validate({ [name]: value })
  }

  const handleInputChange = (phone,name) => {
  // console.log(phone)
    setValues({
      ...values,
      [name]: phone,
    })
    if (validateOnChange) validate({ [name]: phone })
  }

  const onBlur = () => {

  }

  const resetForm = () => {
    setValues(initialFValues)
    setErrors(initialFValues)
  }

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
    onBlur,
    handleAnyOtherChange
  }
}

export function Form(props) {
  const { children, ...other } = props
  return (
    <form autoComplete='off' {...other} noValidate>
      {props.children}
    </form>
  )
}
