import React from "react";
import {
  bgColor15,
  blackShade01,
  blackShade19,
  blueColor03,
  checkProductBtnColor,
  deleteBtnColor,
  greyColor11,
  greyColor33,
  greyColor9,
  heroSectionText,
  lightBlueColor01,
  orderCardBorder2,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { Box, Button, Grid, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  addDurationToDate,
  getColorChip,
  getStatusColor,
} from "../../../utils/helper";
import moment from "moment";

function ItemIssueMobileCard({
  customerName,
  phoneNo,
  orderId,
  issueId,
  issueType,
  msgstatus,
  setIsActionIssueModal,
  setIsViewIssueModal,
  Processing_Time,
  responseTimeStatus,
  responseTimeDuration,
  resolutionTimeStatus,
  resolutionTimeDuration,
  createdOn,
  slaResponseText,
  slaResolutionText,
  resUpdatedAt,
  resolutionUpdatedAt,
  orderType,
  getOndcBapNameBySubscriberId,
  item,
}) {
  return (
    <Box sx={styles.card}>
      <Grid container spacing={{ xs: 1, md: 3 }}>
        <Grid
          item
          xs={12}
          md={4}
          borderBottom={{ xs: orderCardBorder2, md: "none" }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            gap={"10px"}
            mb={1}
          >
            <Box
              display="flex"
              alignItems={{ xs: "flex-start", md: "center" }}
              flexDirection={"column"}
            >
              <Typography sx={styles.blackText}>CUSTOMER</Typography>
              <Typography
                sx={{
                  color: blackShade01,
                  fontSize: { xs: 12.5, md: 13 },
                  fontWeight: 600,
                  lineHeight: 1.5,
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  overflow: "hidden",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                }}
                // mb={0.5}
              >
                {customerName}
              </Typography>
              <Typography sx={styles.blackText}>+91 {phoneNo}</Typography>
            </Box>
            <Box
              display="flex"
              alignItems={{ xs: "flex-end", md: "center" }}
              flexDirection={"column"}
            >
              <Typography sx={styles.blackText}>ORDER ID</Typography>
              <Typography
                sx={{
                  color: blackShade01,
                  fontSize: { xs: 12.5, md: 13 },
                  fontWeight: 600,
                  lineHeight: 1.5,
                  //   textOverflow: "ellipsis",
                  //   display: "-webkit-box",
                  //   overflow: "hidden",
                  //   "-webkit-line-clamp": "1",
                  //   "-webkit-box-orient": "vertical",
                }}
              >
                {orderId}
              </Typography>
              <Typography sx={{ ...styles.title, display: "inline-block" }}>
                {" "}
                <Typography
                  sx={{
                    ...styles.title,
                    fontSize: 12,
                    color: orderType?.color,
                    display: "inline-block",
                  }}
                >
                  {console.log("item", item)}
                  {orderType?.label}
                  {item?.ondcInfo?.bapId}
                  {orderType?.orderType === "ondcOrder" ? (
                    <span style={{ color: blackShade19 }}>
                      {" "}
                      - {getOndcBapNameBySubscriberId(item?.context?.bap_id)}
                    </span>
                  ) : null}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  ...styles.headingText,
                  ...styles.title,
                  fontSize: 12,
                  color: orderType?.color,
                  display: "inline-block",
                }}
                mb={0.5}
              >
                # {item?.message?.issue?.order_details?.id}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          borderBottom={{ xs: orderCardBorder2, md: "none" }}
        >
          <Box display={"flex"} justifyContent={"space-between"} mb={1}>
            <Box
              flex={1}
              display="flex"
              alignItems={{ xs: "flex-start", md: "center" }}
              flexDirection={"column"}
            >
              <Typography sx={styles.blackText}>ISSUE ID</Typography>
              <Typography
                sx={{
                  color: blackShade01,
                  fontSize: { xs: 12.5, md: 13 },
                  fontWeight: 600,
                  lineHeight: 1.5,
                  //   textOverflow: "ellipsis",
                  //   display: "-webkit-box",
                  //   overflow: "hidden",
                  //   "-webkit-line-clamp": "1",
                  //   "-webkit-box-orient": "vertical",
                }}
              >
                # {issueId}
              </Typography>
              <Typography sx={styles.blackText}>
                {moment(createdOn).format("DD MMM, hh:mm A")}
              </Typography>
            </Box>
            <Box
              flex={1}
              display="flex"
              alignItems={{ xs: "flex-end", md: "center" }}
              flexDirection={"column"}
            >
              <Typography sx={styles.blackText}>ISSUE TYPE</Typography>
              <Typography
                sx={{
                  color: blackShade01,
                  fontSize: { xs: 12.5, md: 13 },
                  fontWeight: 600,
                  lineHeight: 1.5,
                }}
              >
                {issueType}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          md={2}
          borderBottom={{ xs: orderCardBorder2, md: "none" }}
        >
          <Box display={"flex"} justifyContent={"space-between"} mb={1}>
            <Box
              flex={1}
              display={"flex"}
              alignItems={"flex-start"}
              flexDirection={"column"}
            >
              <Typography sx={styles.blackText}>RESPONSE TIME</Typography>
              {responseTimeDuration ? (
                <>
                  <Typography sx={styles.headingText}>
                    {Processing_Time}
                    {" ("}
                    {responseTimeDuration.slice(2) || "NA"}
                    {")"}
                  </Typography>
                  {(msgstatus === "RESOLVED" || msgstatus === "CLOSED") && (
                    <Typography sx={styles.headingText}>
                      {resUpdatedAt}
                    </Typography>
                  )}

                  {msgstatus === "OPEN" ? (
                    <Box
                      sx={styles.statusChip(
                        getColorChip(responseTimeStatus?.toLowerCase())
                      )}
                    >
                      {responseTimeStatus}
                    </Box>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: { xs: 12.5, md: 12 },
                        fontWeight: 500,
                        lineHeight: 1.5,
                        color: getStatusColor(slaResponseText?.toLowerCase()),
                      }}
                    >
                      {slaResponseText}
                    </Typography>
                  )}
                </>
              ) : (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography>--/--/--</Typography>
                </Box>
              )}
            </Box>
            {/* <Box
              flex={1}
              display={"flex"}
              alignItems={"flex-end"}
              flexDirection={"column"}
            >
              <Typography sx={styles.blackText}>
                EXPECTED RESOLUTION TIME
              </Typography>
              {resolutionTimeDuration ? (
                <>
                  <Typography sx={styles.headingText}>
                    {addDurationToDate(createdOn, resolutionTimeDuration)}
                    {" ("}
                    {resolutionTimeDuration.slice(1) || "NA"}
                    {")"}
                  </Typography>{" "}
                  {msgstatus === "PROCESSING" ? (
                    <Box
                      sx={styles.statusChip(
                        getColorChip(resolutionTimeStatus?.toLowerCase())
                      )}
                    >
                      {resolutionTimeStatus}
                    </Box>
                  ) : (
                    <Typography
                      sx={{
                        ...styles.blackText,
                        color: getStatusColor(slaResolutionText?.toLowerCase()),
                      }}
                    >
                      {slaResolutionText}
                    </Typography>
                  )}
                </>
              ) : (
                <Typography>--/--/--</Typography>
              )}
            </Box> */}
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          md={2}
          borderBottom={{ xs: orderCardBorder2, md: "none" }}
        >
          <Box display={"flex"} justifyContent={"space-between"} mb={1}>
            {/* <Box
              flex={1}
              display={"flex"}
              alignItems={"flex-start"}
              flexDirection={"column"}
            >
              <Typography sx={styles.blackText}>
                EXPECTED RESPONSE TIME
              </Typography>
              {responseTimeDuration ? (
                <>
                  <Typography sx={styles.headingText}>
                    {Processing_Time}
                    {" ("}
                    {responseTimeDuration.slice(2) || "NA"}
                    {")"}
                  </Typography>
                  {msgstatus === "OPEN" ? (
                    <Box
                      sx={styles.statusChip(
                        getColorChip(responseTimeStatus?.toLowerCase())
                      )}
                    >
                      {responseTimeStatus}
                    </Box>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: { xs: 12.5, md: 12 },
                        fontWeight: 500,
                        lineHeight: 1.5,
                        color: getStatusColor(slaResponseText?.toLowerCase()),
                      }}
                    >
                      {slaResponseText}
                    </Typography>
                  )}
                </>
              ) : (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography>--/--/--</Typography>
                </Box>
              )}
            </Box> */}
            <Box
              flex={1}
              display={"flex"}
              alignItems={"flex-end"}
              flexDirection={"column"}
            >
              <Typography sx={styles.blackText}>RESOLUTION TIME</Typography>
              {resolutionTimeDuration ? (
                <>
                  <Typography sx={styles.headingText}>
                    {addDurationToDate(createdOn, resolutionTimeDuration)}
                    {" ("}
                    {resolutionTimeDuration.slice(2) || "NA"}
                    {")"}
                  </Typography>{" "}
                  {(msgstatus === "RESOLVED" || msgstatus === "CLOSED") && (
                    <Typography sx={styles.headingText}>
                      {resolutionUpdatedAt}
                    </Typography>
                  )}
                  {msgstatus === "PROCESSING" ? (
                    <Box
                      sx={styles.statusChip(
                        getColorChip(resolutionTimeStatus?.toLowerCase())
                      )}
                    >
                      {resolutionTimeStatus}
                    </Box>
                  ) : (
                    <Typography
                      sx={{
                        ...styles.blackText,
                        color: getStatusColor(slaResolutionText?.toLowerCase()),
                      }}
                    >
                      {slaResolutionText}
                    </Typography>
                  )}
                </>
              ) : (
                <Typography>--/--/--</Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display={"flex"} justifyContent={"space-between"} mb={1}>
            <Box
              flex={1}
              display="flex"
              alignItems={{ xs: "flex-start", md: "center" }}
              flexDirection={"column"}
            >
              <Typography sx={styles.blackText}>STATUS</Typography>
              <Typography
                sx={{
                  ...styles.headingText,
                  color: getStatusColor(msgstatus.toLowerCase()),
                }}
              >
                {msgstatus}
              </Typography>
            </Box>
            <Box
              flex={1}
              display="flex"
              alignItems={{ xs: "flex-end", md: "center" }}
              gap={3}
              justifyContent={"end"}
            >
              <Button
                sx={styles.viewBtn(bgColor15)}
                variant="outlined"
                onClick={setIsViewIssueModal}
              >
                <VisibilityIcon sx={{ fontSize: 20 }} />
              </Button>
              {msgstatus !== "RESOLVED" && msgstatus !== "CLOSED" && (
                <Button
                  variant="outlined"
                  sx={styles.actionBtn(lightBlueColor01)}
                  onClick={setIsActionIssueModal}
                >
                  Action
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ItemIssueMobileCard;

const styles = {
  card: {
    padding: "12px 15px",
    background: "#fff",
    border: `1px solid ${greyColor11}`,
    borderRadius: "10px",
    cursor: "pointer",
    mb: 2,
  },
  blackText: {
    color: bgColor15,
    fontSize: { xs: 11, md: 11 },
    fontWeight: 500,
    lineHeight: 1.5,
  },
  headingText: {
    color: blackShade01,
    fontSize: { xs: 12.5, md: 12 },
    fontWeight: 600,
    lineHeight: 1.5,
  },
  statusChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    p: "5px 13px",
    borderRadius: "50px",
    width: "max-content",
  }),

  offTag: {
    height: "20px",
    display: "flex",
    alignItems: "center",
    backgroundColor: whiteColor,
    border: `1px solid ${heroSectionText}`,
    borderRadius: "4px",
    lineHeight: "10px",
    p: "1.5px 6px",
  },

  actionBtn: {
    // minWidth: "76px",
    fontSize: 12,
    fontWeight: 600,
    padding: "3px 15px",
    textTransform: "unset",
    border: `1px solid ${blueColor03}`,
    "&:hover": {
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      borderColor: "rgba(0,0,0,0.2)",
    },
  },

  copyBtn: {
    backgroundColor: blueColor03,
    color: whiteColor,
    "&:hover": {
      backgroundColor: whiteColor,
      color: blueColor03,
    },
  },

  checkBtn: {
    border: `1px solid ${checkProductBtnColor}`,
    backgroundColor: whiteColor,
    color: checkProductBtnColor,
    "&:hover": {
      backgroundColor: whiteColor,
      color: checkProductBtnColor,
    },
  },

  deleteBtn: {
    width: "28px",
    height: "28px",
    borderRadius: "5px",
    border: `1px solid ${deleteBtnColor}`,
    backgroundColor: whiteColor,
    color: deleteBtnColor,
    "&:hover": {
      backgroundColor: deleteBtnColor,
      color: whiteColor,
    },
  },

  copyIconBtn: {
    width: "28px",
    height: "28px",
    borderRadius: "5px",
    border: `1px solid ${greyColor33}`,
    backgroundColor: whiteColor,
    color: greyColor33,
    "&:hover": {
      backgroundColor: greyColor33,
      color: whiteColor,
    },
  },

  actionBtn: (color) => ({
    fontSize: 12.5,
    fontWeight: 600,
    color: color,
    borderColor: color,
    p: "3px 8px",
    "&:hover": {
      color: whiteColor,
      bgcolor: color,
      borderColor: color,
    },
  }),
  viewBtn: (color) => ({
    fontSize: 12.5,
    fontWeight: 600,
    color: color,
    borderColor: color,
    p: "3px 8px",
    "&:hover": {
      color: whiteColor,
      bgcolor: color,
      borderColor: color,
    },
  }),

  ondcIconBtn: {
    width: "28px",
    height: "28px",
    borderRadius: "5px",
    border: `1px solid ${heroSectionText}`,
    backgroundColor: whiteColor,
    color: heroSectionText,
    "&:hover": {
      backgroundColor: heroSectionText,
      color: whiteColor,
    },
  },

  infoText: {
    color: greyColor9,
    fontSize: 12.5,
    lineHeight: "15px",
    fontWeight: 500,
  },

  ondcStatusTag: {
    width: "55px",
    display: { xs: "inline", md: "flex" },
    alignItems: "center",
    justifyContent: "center",
    fontSize: { xs: 13, md: 12 },
    fontWeight: 400,
    lineHeight: "22px",
    borderRadius: "50px",
    p: { xs: "0 8px", md: "1px 13px" },
  },
};
