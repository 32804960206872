import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  BGcolor01,
  blackColor01,
  blueColor03,
  blueColor04,
  border25,
  catalogCardHeadingBorder,
  checkProductBtnColor,
  deleteBtnColor,
  greenShade04,
  greyColor02,
  greyColor03,
  greyColor11,
  greyColor33,
  greyColor40,
  greyColor9,
  heroSectionText,
  oldPriceColor,
  orangeShade01,
  redShade03,
  whiteColor,
} from "../../configs/styles/muiThemes";
import DeleteIcon from "@mui/icons-material/Delete";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";

import moment from "moment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getColorChip, getOrderStatusText } from "../../utils/helper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ProductCardMinimal from "../../components/Cards/ProductCardMinimal";
import { useDispatch, useSelector } from "react-redux";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import { getActiveProductsByBusiness } from "../../reducers/businessPreview/businessPreviewSlice";

function ActiveProductNewlist({
  productsList,
  productStatus,
  handlePreview,
  handleEditProduct,
  onCopyProduct,
  handleOndcClick,
  onDeleteProduct,
  onCheckClick,
  filterApplied,
  pendingList,
  updatedPendingList,
  rejectList,
}) {
  const PAGE_SIZE = 50;

  const [hasMoreData, setHasMoreData] = useState(true);

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const dispatch = useDispatch();

  const {
    activeBusinessProducts,
    totalProductCount,
    activePageNo,
    activeBusinessProductsLoader,
  } = useSelector((state) => state.businessPreview);

  const getTableSize = (productStatus) => {
    switch (productStatus) {
      case "active":
        return "calc(100vh - 300px)";
      case "pending":
        return "calc(100vh - 280px)";
      case "pendingExisting":
        return "calc(100vh - 260px)";
      default:
        return "500px";
    }
  };

  const getNextData = () => {
    console.log("Next hit");
    const businessId = sessionStorage.getItem("selectedBusinessId");
    dispatch(getActiveProductsByBusiness({ businessId, pageNo: activePageNo }));
  };

  useEffect(() => {
    if ((activePageNo - 1) * PAGE_SIZE > activeBusinessProducts?.length) {
      console.log("hit", activePageNo, PAGE_SIZE, activeBusinessProducts);
      setHasMoreData(false);
    }

    return () => {};
  }, [activePageNo]);

  return (
    <Box>
      {filterApplied && productsList?.length > 0 ? (
        matches ? (
          <TableContainer
            component={Box}
            sx={{
              borderRadius: "10px",
              overflow: "scroll",
              maxHeight: { md: getTableSize(productStatus), xs: "300px" },
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: greyColor02,
                    zIndex: 100,
                    borderRadius: "8px 8px 0 0 ",
                  }}
                >
                  <TableCell
                    sx={{
                      ...styles.tableCell,
                      paddingLeft: 2,
                      borderRight: { xs: "none", md: catalogCardHeadingBorder },
                    }}
                  >
                    IMAGE
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles.tableCell,
                      borderRight: { xs: "none", md: catalogCardHeadingBorder },
                    }}
                  >
                    PRODUCT NAME
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles.tableCell,
                      borderRight: { xs: "none", md: catalogCardHeadingBorder },
                    }}
                  >
                    PRICE
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles.tableCell,
                      borderRight: { xs: "none", md: catalogCardHeadingBorder },
                    }}
                  >
                    UPDATED STATUS
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles.tableCell,
                      borderRight: { xs: "none", md: catalogCardHeadingBorder },
                    }}
                  >
                    ONDC ENABLED
                  </TableCell>
                  <TableCell sx={{ ...styles.tableCell }}>ACTION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: whiteColor }}>
                {productsList?.map((item) => {
                  const pendingProducts = [
                    ...pendingList,
                    ...updatedPendingList,
                  ];
                  const isPendingProduct1 = pendingProducts?.filter(
                    (product) => item.id === product.id
                  );
                  const isRejectedProduct1 = rejectList?.filter(
                    (product) => item.id === product.id
                  );

                  const isPendingProduct = isPendingProduct1?.length > 0;
                  const isRejectedProduct = isRejectedProduct1?.length > 0;

                  const disableEdit = item?.disableEdit;
                  let updatedOn = "";

                  if (isPendingProduct1?.length > 0) {
                    updatedOn = isPendingProduct1?.[0]?.productUpdatedOn;
                  }

                  if (isRejectedProduct1?.length > 0) {
                    updatedOn = isRejectedProduct1?.[0]?.productUpdatedOn;
                  }

                  const getActiveInfoText = (isInPending, isInRejected) => {
                    if (!isInPending && !isInRejected)
                      return "No recent changes";
                    if (isInPending) return `Update requested`;
                    if (isInRejected) return `Update request rejected`;

                    return "";
                  };

                  return (
                    <TableRow
                      key={item?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        sx={{
                          ...styles.tableBodyCell("blackShade05"),
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          fontSize: "15px",
                          fontWeight: 600,
                          ml: 1,
                        }}
                        component="th"
                        scope="row"
                      >
                        <Box
                          component="img"
                          src={item?.productPhoto?.[0]?.docURL}
                          width={{ xs: "52px", md: "45px" }}
                          height={{ xs: "52px", md: "45px" }}
                          sx={{
                            objectFit: "cover",
                            borderRadius: { xs: "6px", md: "10px" },
                            border: border25,
                            cursor: "pointer",
                          }}
                          onClick={() => handlePreview(item.id)}
                        />
                      </TableCell>

                      <TableCell
                        sx={{
                          ...styles.tableBodyCell(),
                          maxWidth: 380,
                          textOverflow: "ellipsis",
                          // display: "-webkit-tablecell",
                          overflow: "hidden",
                          // "-webkit-line-clamp": 2,
                          // "line-clamp": 2,
                          // "-webkit-box-orient": "vertical",
                          "white-space": "nowrap",
                          cursor: "pointer",
                        }}
                        onClick={() => handlePreview(item.id)}
                        title={item?.productName}
                      >
                        {item?.productName}
                      </TableCell>

                      <TableCell sx={styles.tableBodyCell(blueColor04)}>
                        <Box display={"flex"} flexDirection={"row"}>
                          <Typography
                            variant="body1"
                            color={oldPriceColor}
                            fontSize={12}
                            lineHeight="15px"
                            fontWeight={400}
                            mt={{ xs: "0px !important", md: "7px !important" }}
                            sx={{
                              textDecoration: "line-through",
                              display: "inline",
                            }}
                          >
                            {item?.productOriginalPrice ===
                            item?.productSalePrice
                              ? ""
                              : `₹${item?.productOriginalPrice}`}
                          </Typography>

                          <Typography
                            variant="body1"
                            color={blueColor04}
                            fontSize={13}
                            lineHeight="15px"
                            fontWeight={600}
                            m="6px 0 0 0px"
                            sx={{
                              display: "inline",
                              ml:
                                item?.productOriginalPrice ===
                                item?.productSalePrice
                                  ? 0
                                  : 1,
                              mr: 1,
                            }}
                          >
                            ₹{item?.productSalePrice}
                          </Typography>
                          {item?.percentageOff !== 0 ? (
                            <Box
                              sx={styles.statusBoxChip(
                                getColorChip("processing")
                              )}
                            >
                              <Typography
                                variant="body1"
                                color={heroSectionText}
                                fontSize="12px"
                                fontWeight={600}
                                m="6px 0 0 0px !important"
                                sx={{ display: "inline" }}
                              >
                                {item?.percentageOff}%
                              </Typography>
                            </Box>
                          ) : null}
                        </Box>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        {productStatus === "active" && (
                          <Box
                            sx={{
                              color:
                                getActiveInfoText(
                                  isPendingProduct,
                                  isRejectedProduct
                                ).split("\n")?.[0] === "No recent changes"
                                  ? redShade03 
                                  : getActiveInfoText(
                                      isPendingProduct,
                                      isRejectedProduct
                                    ).split("\n")?.[0] === "Update requested"
                                  ? orangeShade01
                                  : getActiveInfoText(
                                      isPendingProduct,
                                      isRejectedProduct
                                    ).split("\n")?.[0] ===
                                    "Update request rejected"
                                  ? greenShade04
                                  : greyColor40,
                              fontWeight: 500,
                              fontSize: 12,
                              lineHeight: 1.5,
                            }}
                          >
                            {
                              getActiveInfoText(
                                isPendingProduct,
                                isRejectedProduct
                              ).split("\n")?.[0]
                            }
                          </Box>
                        )}

                        <Typography color={greyColor40}>
                          {productStatus === "active" &&
                          getActiveInfoText(
                            isPendingProduct,
                            isRejectedProduct
                          ).split("\n")?.[0] === "No recent changes"
                            ? ""
                            : moment(updatedOn).fromNow()}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        <Box
                          sx={styles.statusChip(
                            getColorChip(
                              item?.ondcEnabled === "yes" ? "yes" : "no"
                            )
                          )}
                        >
                          {getOrderStatusText(
                            item?.ondcEnabled === "yes" ? "yes" : "no"
                          )}
                        </Box>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        <Box
                          sx={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "15px",
                            mt: { xs: 1, md: 0 },
                          }}
                        >
                          {productStatus === "active" && (
                            <>
                              {!isPendingProduct && !isRejectedProduct && (
                                <Button
                                  onClick={() => handleEditProduct(item?.id)}
                                  sx={{
                                    ...styles.actionBtn,
                                    width: "75px",
                                    color: blueColor04,
                                    borderColor: blueColor04,
                                  }}
                                  disabled={disableEdit}
                                >
                                  EDIT
                                </Button>
                              )}

                              {isPendingProduct && (
                                <LightTooltip
                                  title="Edit the product details from ‘Pending Approval(Existing)’ tab"
                                  placement="top"
                                >
                                  <Button
                                    onClick={() => handlePreview(item.id)}
                                    sx={{
                                      ...styles.viewIconBtn,
                                    }}
                                  >
                                    <VisibilityIcon sx={{ fontSize: 18 }} />
                                  </Button>
                                </LightTooltip>
                              )}

                              {isRejectedProduct && (
                                <Button
                                  onClick={onCheckClick}
                                  sx={{
                                    ...styles.actionBtn,
                                    ...styles.checkBtn,
                                  }}
                                >
                                  CHECK
                                </Button>
                              )}
                            </>
                          )}

                          {(productStatus === "pending" ||
                            productStatus === "pendingExisting") && (
                            <Button
                              onClick={() => handleEditProduct(item?.id)}
                              sx={{
                                ...styles.actionBtn,
                                width: "75px",
                                color: blueColor04,
                                borderColor: blueColor04,
                              }}
                              disabled={disableEdit}
                            >
                              EDIT
                            </Button>
                          )}

                          {
                            productStatus !== "rejected" &&
                              productStatus !== "rejectedExisting" && (
                                // (!matches ? (
                                <LightTooltip
                                  title="Use this option to add a new product quickly"
                                  placement="top"
                                >
                                  <IconButton
                                    onClick={(e) => onCopyProduct(e, item?.id)}
                                    sx={styles.copyIconBtn}
                                  >
                                    <ContentCopyIcon
                                      sx={{ fontSize: "16px" }}
                                    />
                                  </IconButton>
                                </LightTooltip>
                              )
                            // ) : (
                            // <Button
                            //   onClick={handleCopyProduct}
                            //   sx={{ ...styles.actionBtn, ...styles.copyBtn }}
                            // >
                            //   COPY
                            // </Button>
                            // ))
                          }

                          {productStatus === "active" && (
                            <LightTooltip
                              title="Use this option to edit ONDC parameters"
                              placement="top"
                            >
                              <IconButton
                                onClick={() => handleOndcClick(item?.id)}
                                sx={styles.ondcIconBtn(isPendingProduct)}
                                disabled={isPendingProduct}
                              >
                                <HubOutlinedIcon sx={{ fontSize: "16px" }} />
                              </IconButton>
                            </LightTooltip>
                          )}

                          {productStatus === "rejected" ||
                          productStatus === "rejectedExisting" ? (
                            <Button
                              onClick={() => handleEditProduct(item?.id)}
                              sx={{
                                ...styles.actionBtn,
                                color: blueColor04,
                                borderColor: blueColor04,
                              }}
                            >
                              EDIT & RESUBMIT
                            </Button>
                          ) : null}

                          {productStatus === "active" && (
                            <LightTooltip
                              title="Delete product"
                              placement="top"
                            >
                              <IconButton
                                onClick={() => onDeleteProduct(item)}
                                sx={styles.deleteBtn}
                              >
                                <DeleteIcon sx={{ fontSize: "16px" }} />
                              </IconButton>
                            </LightTooltip>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box>
            {productsList?.map((item) => {
              const pendingProducts = [...pendingList, ...updatedPendingList];
              const isPendingProduct1 = pendingProducts?.filter(
                (product) => item.id === product.id
              );
              const isRejectedProduct1 = rejectList?.filter(
                (product) => item.id === product.id
              );

              const isPendingProduct = isPendingProduct1?.length > 0;
              const isRejectedProduct = isRejectedProduct1?.length > 0;

              const disableEdit = item?.disableEdit;
              let updatedOn = "";

              if (isPendingProduct1?.length > 0) {
                updatedOn = isPendingProduct1?.[0]?.productUpdatedOn;
              }

              if (isRejectedProduct1?.length > 0) {
                updatedOn = isRejectedProduct1?.[0]?.productUpdatedOn;
              }
              return (
                <ProductCardMinimal
                  key={item?.id}
                  title={item?.productName}
                  image={item?.productPhoto?.[0]?.docURL}
                  newPrice={item?.productSalePrice}
                  oldPrice={item?.productOriginalPrice}
                  isDiscount={item?.percentageOff !== 0}
                  percentageOff={item?.percentageOff}
                  onClick={() => {
                    handlePreview(item?.id);
                  }}
                  onEditClick={() => {
                    handleEditProduct(item?.id);
                  }}
                  onCopyClick={() => {}}
                  onDeleteClick={() => onDeleteProduct(item)}
                  onOndcClick={() => handleOndcClick(item?.id)}
                  handleCopyProduct={(e) => onCopyProduct(e, item?.id)}
                  onCheckClick={onCheckClick}
                  disableEdit={item?.disableEdit}
                  productStatus={productStatus}
                  rejectedNote={item?.notes || ""}
                  // Status Check in pending and rejected
                  isPendingProduct={isPendingProduct}
                  isRejectedProduct={isRejectedProduct}
                  updatedOn={updatedOn}
                  // Pending
                  catalogStatus={item?.status}
                  // Rejected
                  rejectedProductInActive={
                    item?.rejectedProductInActive || false
                  }
                  // ONDC
                  ondcEnabled={item?.ondcEnabled}
                />
              );
            })}
          </Box>
        )
      ) : null}

      {!filterApplied ? (
        matches ? (
          <Box>
            <InfiniteScroll
              dataLength={activeBusinessProducts?.length || 0}
              next={getNextData}
              hasMore={hasMoreData}
              loader={
                <Box>
                  <ThreeDotLoader />
                </Box>
              }
              height={getTableSize(productStatus)}
            >
              <TableContainer
                component={Box}
                sx={{
                  borderRadius: "10px",
                  // overflow: "scroll",
                }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow
                      sx={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: greyColor02,
                        zIndex: 100,
                        borderRadius: "8px 8px 0 0 ",
                      }}
                    >
                      <TableCell
                        sx={{
                          ...styles.tableCell,
                          paddingLeft: 2,
                          borderRight: {
                            xs: "none",
                            md: catalogCardHeadingBorder,
                          },
                        }}
                      >
                        IMAGE
                      </TableCell>
                      <TableCell
                        sx={{
                          ...styles.tableCell,
                          borderRight: {
                            xs: "none",
                            md: catalogCardHeadingBorder,
                          },
                        }}
                      >
                        PRODUCT NAME
                      </TableCell>
                      <TableCell
                        sx={{
                          ...styles.tableCell,
                          borderRight: {
                            xs: "none",
                            md: catalogCardHeadingBorder,
                          },
                        }}
                      >
                        PRICE
                      </TableCell>
                      <TableCell
                        sx={{
                          ...styles.tableCell,
                          borderRight: {
                            xs: "none",
                            md: catalogCardHeadingBorder,
                          },
                        }}
                      >
                        UPDATED STATUS
                      </TableCell>
                      <TableCell
                        sx={{
                          ...styles.tableCell,
                          borderRight: {
                            xs: "none",
                            md: catalogCardHeadingBorder,
                          },
                        }}
                      >
                        ONDC ENABLED
                      </TableCell>
                      <TableCell sx={{ ...styles.tableCell }}>ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: whiteColor }}>
                    {activeBusinessProducts?.map((item) => {
                      const pendingProducts = [
                        ...pendingList,
                        ...updatedPendingList,
                      ];
                      const isPendingProduct1 = pendingProducts?.filter(
                        (product) => item.id === product.id
                      );
                      const isRejectedProduct1 = rejectList?.filter(
                        (product) => item.id === product.id
                      );

                      const isPendingProduct = isPendingProduct1?.length > 0;
                      const isRejectedProduct = isRejectedProduct1?.length > 0;

                      const disableEdit = item?.disableEdit;
                      let updatedOn = "";

                      if (isPendingProduct1?.length > 0) {
                        updatedOn = isPendingProduct1?.[0]?.productUpdatedOn;
                      }

                      if (isRejectedProduct1?.length > 0) {
                        updatedOn = isRejectedProduct1?.[0]?.productUpdatedOn;
                      }

                      const getActiveInfoText = (isInPending, isInRejected) => {
                        if (!isInPending && !isInRejected)
                          return "No recent changes";
                        if (isInPending) return `Update requested`;
                        if (isInRejected) return `Update request rejected`;

                        return "";
                      };

                      return (
                        <TableRow
                          key={item?.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{
                              ...styles.tableBodyCell("blackShade05"),
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              fontSize: "15px",
                              fontWeight: 600,
                              ml: 1,
                            }}
                            component="th"
                            scope="row"
                          >
                            <Box
                              component="img"
                              src={item?.productPhoto?.[0]?.docURL}
                              width={{ xs: "52px", md: "45px" }}
                              height={{ xs: "52px", md: "45px" }}
                              sx={{
                                objectFit: "cover",
                                borderRadius: { xs: "6px", md: "10px" },
                                border: border25,
                                cursor: "pointer",
                              }}
                              onClick={() => handlePreview(item.id)}
                            />
                          </TableCell>

                          <TableCell
                            sx={{
                              ...styles.tableBodyCell(),
                              maxWidth: 380,
                              textOverflow: "ellipsis",
                              // display: "-webkit-tablecell",
                              overflow: "hidden",
                              // "-webkit-line-clamp": 2,
                              // "line-clamp": 2,
                              // "-webkit-box-orient": "vertical",
                              "white-space": "nowrap",
                              cursor: "pointer",
                            }}
                            onClick={() => handlePreview(item.id)}
                            title={item?.productName}
                          >
                            {item?.productName}
                          </TableCell>

                          <TableCell sx={styles.tableBodyCell(blueColor04)}>
                            <Box display={"flex"} flexDirection={"row"}>
                              <Typography
                                variant="body1"
                                color={oldPriceColor}
                                fontSize={12}
                                lineHeight="15px"
                                fontWeight={400}
                                mt={{
                                  xs: "0px !important",
                                  md: "7px !important",
                                }}
                                sx={{
                                  textDecoration: "line-through",
                                  display: "inline",
                                }}
                              >
                                {item?.productOriginalPrice ===
                                item?.productSalePrice
                                  ? ""
                                  : `₹${item?.productOriginalPrice}`}
                              </Typography>

                              <Typography
                                variant="body1"
                                color={blueColor04}
                                fontSize={13}
                                lineHeight="15px"
                                fontWeight={600}
                                m="6px 0 0 0px"
                                sx={{
                                  display: "inline",
                                  ml:
                                    item?.productOriginalPrice ===
                                    item?.productSalePrice
                                      ? 0
                                      : 1,
                                  mr: 1,
                                }}
                              >
                                ₹{item?.productSalePrice}
                              </Typography>
                              {item?.percentageOff !== 0 ? (
                                <Box
                                  sx={styles.statusBoxChip(
                                    getColorChip("processing")
                                  )}
                                >
                                  <Typography
                                    variant="body1"
                                    color={heroSectionText}
                                    fontSize="12px"
                                    fontWeight={600}
                                    m="6px 0 0 0px !important"
                                    sx={{ display: "inline" }}
                                  >
                                    {item?.percentageOff}%
                                  </Typography>
                                </Box>
                              ) : null}
                            </Box>
                          </TableCell>
                          <TableCell sx={styles.tableBodyCell()}>
                            {productStatus === "active" && (
                              <Box
                                sx={{
                                  color:
                                    getActiveInfoText(
                                      isPendingProduct,
                                      isRejectedProduct
                                    ).split("\n")?.[0] === "No recent changes"
                                      ? greenShade04
                                      : getActiveInfoText(
                                          isPendingProduct,
                                          isRejectedProduct
                                        ).split("\n")?.[0] ===
                                        "Update requested"
                                      ? orangeShade01
                                      : getActiveInfoText(
                                          isPendingProduct,
                                          isRejectedProduct
                                        ).split("\n")?.[0] ===
                                        "Update request rejected"
                                      ? redShade03
                                      : greyColor40,
                                  fontWeight: 500,
                                  fontSize: 12,
                                  lineHeight: 1.5,
                                }}
                              >
                                {
                                  getActiveInfoText(
                                    isPendingProduct,
                                    isRejectedProduct
                                  ).split("\n")?.[0]
                                }
                              </Box>
                            )}

                            <Typography color={greyColor40}>
                              {productStatus === "active" &&
                              getActiveInfoText(
                                isPendingProduct,
                                isRejectedProduct
                              ).split("\n")?.[0] === "No recent changes"
                                ? ""
                                : moment(updatedOn).fromNow()}
                            </Typography>
                          </TableCell>
                          <TableCell sx={styles.tableBodyCell()}>
                            <Box
                              sx={styles.statusChip(
                                getColorChip(
                                  item?.ondcEnabled === "yes" ? "yes" : "no"
                                )
                              )}
                            >
                              {getOrderStatusText(
                                item?.ondcEnabled === "yes" ? "yes" : "no"
                              )}
                            </Box>
                          </TableCell>
                          <TableCell sx={styles.tableBodyCell()}>
                            <Box
                              sx={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: "15px",
                                mt: { xs: 1, md: 0 },
                              }}
                            >
                              {productStatus === "active" && (
                                <>
                                  {!isPendingProduct && !isRejectedProduct && (
                                    <Button
                                      onClick={() =>
                                        handleEditProduct(item?.id)
                                      }
                                      sx={{
                                        ...styles.actionBtn,
                                        width: "75px",
                                        color: blueColor04,
                                        borderColor: blueColor04,
                                      }}
                                      disabled={disableEdit}
                                    >
                                      EDIT
                                    </Button>
                                  )}

                                  {isPendingProduct && (
                                    <LightTooltip
                                      title="Edit the product details from ‘Pending Approval(Existing)’ tab"
                                      placement="top"
                                    >
                                      <Button
                                        onClick={() => handlePreview(item.id)}
                                        sx={{
                                          ...styles.viewIconBtn,
                                        }}
                                      >
                                        <VisibilityIcon sx={{ fontSize: 18 }} />
                                      </Button>
                                    </LightTooltip>
                                  )}

                                  {isRejectedProduct && (
                                    <Button
                                      onClick={onCheckClick}
                                      sx={{
                                        ...styles.actionBtn,
                                        ...styles.checkBtn,
                                      }}
                                    >
                                      CHECK
                                    </Button>
                                  )}
                                </>
                              )}

                              {(productStatus === "pending" ||
                                productStatus === "pendingExisting") && (
                                <Button
                                  onClick={() => handleEditProduct(item?.id)}
                                  sx={{
                                    ...styles.actionBtn,
                                    width: "75px",
                                    color: blueColor04,
                                    borderColor: blueColor04,
                                  }}
                                  disabled={disableEdit}
                                >
                                  EDIT
                                </Button>
                              )}

                              {
                                productStatus !== "rejected" &&
                                  productStatus !== "rejectedExisting" && (
                                    // (!matches ? (
                                    <LightTooltip
                                      title="Use this option to add a new product quickly"
                                      placement="top"
                                    >
                                      <IconButton
                                        onClick={(e) =>
                                          onCopyProduct(e, item?.id)
                                        }
                                        sx={styles.copyIconBtn}
                                      >
                                        <ContentCopyIcon
                                          sx={{ fontSize: "16px" }}
                                        />
                                      </IconButton>
                                    </LightTooltip>
                                  )
                                // ) : (
                                // <Button
                                //   onClick={handleCopyProduct}
                                //   sx={{ ...styles.actionBtn, ...styles.copyBtn }}
                                // >
                                //   COPY
                                // </Button>
                                // ))
                              }

                              {productStatus === "active" && (
                                <LightTooltip
                                  title="Use this option to edit ONDC parameters"
                                  placement="top"
                                >
                                  <IconButton
                                    onClick={() => handleOndcClick(item?.id)}
                                    sx={styles.ondcIconBtn(isPendingProduct)}
                                    disabled={isPendingProduct}
                                  >
                                    <HubOutlinedIcon
                                      sx={{ fontSize: "16px" }}
                                    />
                                  </IconButton>
                                </LightTooltip>
                              )}

                              {productStatus === "rejected" ||
                              productStatus === "rejectedExisting" ? (
                                <Button
                                  onClick={() => handleEditProduct(item?.id)}
                                  sx={{
                                    ...styles.actionBtn,
                                    color: blueColor04,
                                    borderColor: blueColor04,
                                  }}
                                >
                                  EDIT & RESUBMIT
                                </Button>
                              ) : null}

                              {productStatus === "active" && (
                                <LightTooltip
                                  title="Delete product"
                                  placement="top"
                                >
                                  <IconButton
                                    onClick={() => onDeleteProduct(item)}
                                    sx={styles.deleteBtn}
                                  >
                                    <DeleteIcon sx={{ fontSize: "16px" }} />
                                  </IconButton>
                                </LightTooltip>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          </Box>
        ) : (
          <Box>
            {activeBusinessProducts?.map((item) => {
              const pendingProducts = [...pendingList, ...updatedPendingList];
              const isPendingProduct1 = pendingProducts?.filter(
                (product) => item.id === product.id
              );
              const isRejectedProduct1 = rejectList?.filter(
                (product) => item.id === product.id
              );

              const isPendingProduct = isPendingProduct1?.length > 0;
              const isRejectedProduct = isRejectedProduct1?.length > 0;

              const disableEdit = item?.disableEdit;
              let updatedOn = "";

              if (isPendingProduct1?.length > 0) {
                updatedOn = isPendingProduct1?.[0]?.productUpdatedOn;
              }

              if (isRejectedProduct1?.length > 0) {
                updatedOn = isRejectedProduct1?.[0]?.productUpdatedOn;
              }
              return (
                <ProductCardMinimal
                  key={item?.id}
                  title={item?.productName}
                  image={item?.productPhoto?.[0]?.docURL}
                  newPrice={item?.productSalePrice}
                  oldPrice={item?.productOriginalPrice}
                  isDiscount={item?.percentageOff !== 0}
                  percentageOff={item?.percentageOff}
                  onClick={() => {
                    handlePreview(item?.id);
                  }}
                  onEditClick={() => {
                    handleEditProduct(item?.id);
                  }}
                  onCopyClick={() => {}}
                  onDeleteClick={() => onDeleteProduct(item)}
                  onOndcClick={() => handleOndcClick(item?.id)}
                  handleCopyProduct={(e) => onCopyProduct(e, item?.id)}
                  onCheckClick={onCheckClick}
                  disableEdit={item?.disableEdit}
                  productStatus={productStatus}
                  rejectedNote={item?.notes || ""}
                  // Status Check in pending and rejected
                  isPendingProduct={isPendingProduct}
                  isRejectedProduct={isRejectedProduct}
                  updatedOn={updatedOn}
                  // Pending
                  catalogStatus={item?.status}
                  // Rejected
                  rejectedProductInActive={
                    item?.rejectedProductInActive || false
                  }
                  // ONDC
                  ondcEnabled={item?.ondcEnabled}
                />
              );
            })}
          </Box>
        )
      ) : null}
    </Box>
  );
}

export default ActiveProductNewlist;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[2],
    fontSize: 11,
    border: `1px solid rgba(0,0,0,0.2)`,
  },
}));

const styles = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", md: "column" },
    backgroundColor: BGcolor01,
  },
  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGcolor01,
  },
  statsCard: {
    width: { xs: "100%", md: "auto" },
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    gap: { xs: "20px", md: "35px" },
    backgroundColor: whiteColor,
    padding: { xs: "10px 16px", md: "8px 16px" },
    borderRadius: { xs: "8px", md: "12px" },
    border: `1px solid ${greyColor03}`,
    m: { xs: "12px 0 12px 0", md: "0" },
  },
  tableHeadings: {
    align: "right",
    color: greyColor03,
    fontSize: "12px",
    fontWeight: "500",
    backgroundColor: BGcolor01,
  },

  tableCell: {
    fontSize: "13px",
    fontWeight: "500",
    height: "34px !important",
    padding: "5px 15px",
  },

  tableBodyCell: (color) => ({
    fontSize: 13,
    fontWeight: 600,
    color: color || blackColor01,
    padding: "10px 15px !important",
  }),
  card: {
    padding: "12px 15px",
    background: "#fff",
    border: `1px solid ${greyColor11}`,
    borderRadius: "10px",
    cursor: "pointer",
    mb: 2,
  },

  actionBtn: {
    // minWidth: "76px",
    fontSize: 12,
    fontWeight: 600,
    padding: "3px 15px",
    textTransform: "unset",
    border: `1px solid ${blueColor03}`,
    "&:hover": {
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      borderColor: "rgba(0,0,0,0.2)",
    },
  },

  copyBtn: {
    backgroundColor: blueColor03,
    color: whiteColor,
    "&:hover": {
      backgroundColor: whiteColor,
      color: blueColor03,
    },
  },

  checkBtn: {
    width: "75px",
    border: `1px solid ${checkProductBtnColor}`,
    backgroundColor: whiteColor,
    color: checkProductBtnColor,
    "&:hover": {
      backgroundColor: whiteColor,
      color: checkProductBtnColor,
    },
  },

  deleteBtn: {
    width: "28px",
    height: "28px",
    borderRadius: "5px",
    border: `1px solid ${deleteBtnColor}`,
    backgroundColor: whiteColor,
    color: deleteBtnColor,
    "&:hover": {
      backgroundColor: deleteBtnColor,
      color: whiteColor,
    },
  },

  copyIconBtn: {
    width: "28px",
    height: "28px",
    borderRadius: "5px",
    border: `1px solid ${greyColor33}`,
    backgroundColor: whiteColor,
    color: greyColor33,
    "&:hover": {
      backgroundColor: greyColor33,
      color: whiteColor,
    },
  },
  viewIconBtn: {
    width: "75px",
    height: "28px",
    borderRadius: "5px",
    border: `1px solid ${blueColor04}`,
    backgroundColor: whiteColor,
    color: blueColor04,
    "&:hover": {
      backgroundColor: blueColor04,
      color: whiteColor,
    },
  },

  ondcIconBtn: (disabled) => ({
    width: "28px",
    height: "28px",
    borderRadius: "5px",
    border: disabled
      ? `1px solid rgba(0, 0, 0, 0.26)`
      : `1px solid ${heroSectionText}`,
    backgroundColor: whiteColor,
    color: heroSectionText,
    "&:hover": {
      backgroundColor: heroSectionText,
      color: whiteColor,
    },
  }),

  infoText: {
    color: greyColor9,
    fontSize: 12.5,
    lineHeight: "15px",
    fontWeight: 500,
  },

  ondcStatusTag: {
    width: "55px",
    display: { xs: "inline", md: "flex" },
    alignItems: "center",
    justifyContent: "center",
    fontSize: { xs: 13, md: 12 },
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "50px",
    p: { xs: "0 8px", md: "1px 13px" },
  },

  statusChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    p: "5px 13px",
    borderRadius: "50px",
    width: "max-content",
  }),
  statusBoxChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    p: "2px 4px",
    borderRadius: "4px",
    width: "max-content",
  }),
};
