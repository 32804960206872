import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  borderTextbox,
  saveBtnColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import DashboardFormHeader from "./DashboardFormHeader";
import DashboardStepper from "../DashboardStepper";
import FormInputBox from "../../../components/Input/FormInputBox";
import { STORE_STEP_LIST } from "../../../utils/appData";

function BankDetailsForm({ gotoNext, goBack }) {
  return (
    <Box sx={styles.card}>
      <DashboardFormHeader />
      <DashboardStepper stepList={STORE_STEP_LIST} currentPage="bank-detail" />

      <Box m="20px 0 15px 0">
        <Typography component="h5" sx={styles.heading}>
          Bank Detail
        </Typography>

        <Box sx={{ pb: 1, mt: "20px" }}>
          <Grid container rowSpacing={{ xs: 0.5, md: 2 }} columnSpacing={3}>
            <Grid item xs={12} md={4}>
              <FormInputBox
                label="Account Holder Name"
                placeholder="Enter Account Holder Name"
                isDashboardInput
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInputBox
                label="Account Number"
                placeholder="Enter Account Number"
                isDashboardInput
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInputBox
                label="IFSC code"
                placeholder="Enter IFSC code"
                isDashboardInput
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInputBox
                label="Email Id"
                placeholder="Enter Account Number"
                isDashboardInput
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInputBox
                label="Contact Number"
                placeholder="Enter Contact Number"
                isDashboardInput
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Button
                sx={styles.backBtn}
                variant="contained"
                disableElevation
                onClick={() => {}}
              >
                VERIFY
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box mt="15px">
        <ActionButtons
          onBackClick={goBack}
          onSaveClick={() => {}}
          onNextClick={gotoNext}
          backBtnText="Previous"
          showBackBtn
        />
      </Box>
    </Box>
  );
}

export default BankDetailsForm;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    p: "20px",
    my: "25px",
  },

  heading: {
    fontSize: { xs: 15, md: 18 },
    color: blackShade05,
    fontWeight: 600,
    lineHeight: "20px",
  },

  backBtn: {
    backgroundColor: whiteColor,
    padding: "6px",
    fontSize: "14px",
    color: saveBtnColor,
    textTransform: "unset",
    mt: { xs: "0px", md: "24px" },
    textDecoration: "underline",
    "&:hover": {
      backgroundColor: whiteColor,
    },
  },
};
