import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import {
  bgColor25,
  blackShade05,
  blackShade19,
  border25,
  borderTextBoxColor,
  greyColor34,
  greyColor42,
  greyColor47,
  greyColor6,
  lightRedColor,
  orangeShade11,
  redColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import FormInputBox from "../../../components/Input/FormInputBox";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import TextAreaComponent from "../../../components/Input/TextAreaComponent";
import {
  ONDC_TABLE_HEADER_1,
  ONDC_TABLE_HEADER_2,
  ONDC_TABLE_HINT,
  PRODUCT_CODE_UNIT_LIST,
  PRODUCT_MEASUREMENT_WEIGHT_TABLE_HEADER,
  TIME_TO_SHIP_UNIT,
  YES_NO_DROPDOWN_DATA,
} from "../../../utils/data";
import HorizontalInputWithLabel from "../../../components/Input/HorizontalInputWithLabel";
import HorizontalSelectWithLabel from "../../../components/Input/HorizontalSelectWithLabel";
import { foodInput, foodInput1 } from "../../../utils/dummyData";
import TabelCellInput from "../../CatalogBulkUpload/Components/Popups/TabelCellInput";
import HorizontalAutoCompleteWithlabel from "../../../components/Input/HorizontalAutoCompleteWithlabel";
import HorizontalMultiSelectCustomTextWithLabel from "../../../components/Input/HorizontalMultiSelectCustomTextWithLabel";
import TableDropdown from "../../CatalogBulkUpload/Components/TableDropdown";
import HorizontalRadioGroupWithLabel from "../../../components/Input/HorizontalRadioGroupWithLabel";
import {
  dynamicSort,
  getProductCodeLength,
  getProductCodeMaxLength,
} from "../../../utils/helper";

function AddONDCControls({
  ondcSpecificationData,
  setOndcSpecificationData,
  ondcData,
  onValueChange,
  unitOfMeasurementData,
  productCodeList,
  handleKeyValueChange,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const handleChange = (index, value, selector = "inputValue") => {
    const updatedOndcSpecificationData = [...ondcSpecificationData];
    updatedOndcSpecificationData[index][selector] = value;
    setOndcSpecificationData(updatedOndcSpecificationData);
  };
  return (
    <Box sx={styles.card}>
      <Typography sx={styles.title}>ONDC Controls</Typography>
      <Grid container rowSpacing={2} columnSpacing={3}>
        <Grid item xs={5} md={1.7}>
          <SelectWithLabel
            label="Product Code"
            value={ondcData?.productCode?.key}
            onValueChange={(value) => {
              console.log(value);
              handleKeyValueChange(
                "key",
                value?.prodictCode,
                "productCode",
                "productCode1"
              );
            }}
            returnFullItem
            menuList={
              productCodeList?.slice()?.sort(dynamicSort("prodictCode")) || []
            }
            required
            valueSelector="prodictCode"
            errorMsg={ondcData?.errors?.productCode1}
          />
        </Grid>

        <Grid item xs={7} md={3}>
          <FormInputBox
            label="Product Code Value"
            placeholder=""
            required
            value={ondcData?.productCode?.value}
            helperText={
              productCodeList?.find(
                (obj) => obj?.prodictCode === ondcData?.productCode?.key
              ) &&
              `Provide valid ${getProductCodeLength(
                productCodeList,
                ondcData?.productCode?.key
              )} digit Product code`
            }
            helperColor={orangeShade11}
            onChange={({ target }) => {
              // const value = target?.value?.replace(/[^0-9]/g, "");
              handleKeyValueChange(
                "value",
                target?.value,
                "productCode",
                "productCode"
              );
            }}
            // maxLength={getProductCodeMaxLength(ondcData?.productCode?.key)}
            error={ondcData?.errors?.productCode}
          />
        </Grid>

        <Grid item xs={5} md={2.2}>
          <SelectWithLabel
            label={matches ? "Unit of Measure (UOM)" : "Unit of Measure"}
            value={ondcData?.unitOfMeasure?.unit}
            onValueChange={(value) => {
              handleKeyValueChange(
                "unit",
                value?.value,
                "unitOfMeasure",
                "unitOfMeasure1"
              );
            }}
            returnFullItem
            menuList={
              unitOfMeasurementData?.slice()?.sort(dynamicSort("value")) || []
            }
            valueSelector="value"
            required
            errorMsg={ondcData?.errors?.unitOfMeasure1}
          />
        </Grid>

        <Grid item xs={7} md={3}>
          <FormInputBox
            label="UOM value"
            placeholder=""
            required
            value={ondcData?.unitOfMeasure?.value}
            onChange={({ target }) => {
              // const value = target?.value
              //   ?.replace(/[^\d.]/g, "")
              //   ?.replace(/^0+(?=\d)/, "")
              //   ?.replace(/^(\d*\.\d{0,2}).*/, "$1");
              const value = target?.value?.replace(/[^0-9]/g, "");

              if (value === "" || parseFloat(value) <= 10000000) {
                handleKeyValueChange(
                  "value",
                  value,
                  "unitOfMeasure",
                  "unitOfMeasure"
                );
              }
            }}
            error={ondcData?.errors?.unitOfMeasure}
          />
        </Grid>
      </Grid>
      <Box sx={{ width: { md: "75%", xs: "100%" } }} mt={1.5}>
        <Box sx={styles.tableWrapper}>
          {console.log("ondcData", ondcData)}
          <TableContainer sx={styles.tableContainer} component={Box}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    position: "sticky !important",
                    top: 0,
                    backgroundColor: greyColor42,
                    zIndex: 20,
                    borderRadius: "8px 8px 0 0",
                  }}
                >
                  {ONDC_TABLE_HEADER_1?.map((item, index) => {
                    return (
                      <TableCell
                        key={item.id}
                        sx={{
                          ...styles.tableCell,
                          ...styles.colSticky,
                        }}
                      >
                        <Box
                          display={index === 0 ? "flex" : "inline-block"}
                          alignItems="center"
                          gap={1.2}
                        >
                          <Box sx={{ fontSize: 12 }}>
                            {item?.title}{" "}
                            {item?.isRequired && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </Box>
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody sx={{ backgroundColor: whiteColor }}>
                <TableRow
                  sx={{
                    borderBottom: `1px solid ${borderTextBoxColor}`,
                    verticalAlign: "top",
                  }}
                >
                  <TableCell sx={styles.tableBodyCell(false)}>
                    <TabelCellInput
                      value={ondcData?.timeToShip}
                      onValueChange={(value) => {
                        onValueChange("timeToShip", value);
                      }}
                      regexType="number"
                      errorMsg={ondcData?.errors?.timeToShip}
                    />
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell(false)}>
                    <TableDropdown
                      value={ondcData?.timeToShipUnit?.label}
                      onValueChange={(value) => {
                        onValueChange("timeToShipUnit", value);
                      }}
                      menuList={TIME_TO_SHIP_UNIT}
                      returnFullItem
                      borderFull
                      rounded
                    />
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell(false)}>
                    <TableDropdown
                      value={ondcData?.available_on_cod === true ? "YES" : "NO"}
                      onValueChange={(value) => {
                        onValueChange(
                          "available_on_cod",
                          value === "YES" ? true : false
                        );
                      }}
                      menuList={YES_NO_DROPDOWN_DATA}
                      borderFull
                      rounded
                    />
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell(false)}>
                    <TableDropdown
                      value={ondcData?.fragile === true ? "YES" : "NO"}
                      onValueChange={(value) => {
                        onValueChange(
                          "fragile",
                          value === "YES" ? true : false
                        );
                      }}
                      menuList={YES_NO_DROPDOWN_DATA}
                      borderFull
                      rounded
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Box
        sx={{ width: { md: "80vw", xs: "100%" } }}
        mt={1.5}
        display={{ md: "flex", xs: "none" }}
      >
        <Box sx={styles.tableWrapper}>
          <TableContainer
            sx={{ ...styles.tableContainer, height: "110px" }}
            component={Box}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    position: "sticky !important",
                    top: 0,
                    backgroundColor: greyColor42,
                    zIndex: 20,
                    borderRadius: "8px 8px 0 0",
                  }}
                >
                  {ONDC_TABLE_HEADER_2?.map((item, index) => {
                    return (
                      <TableCell
                        key={item.id}
                        sx={{
                          ...styles.tableCell,
                          ...styles.colSticky,
                        }}
                      >
                        <Box
                          display={index === 0 ? "flex" : "inline-block"}
                          alignItems="center"
                          gap={1.2}
                        >
                          <Box sx={{ fontSize: 12 }}>
                            {item?.title}{" "}
                            {item?.isRequired && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </Box>
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody sx={{ backgroundColor: whiteColor }}>
                <TableRow
                  sx={{
                    backgroundColor: bgColor25,
                  }}
                >
                  {ONDC_TABLE_HINT?.map((item, index) => {
                    return (
                      <TableCell
                        key={item.id}
                        sx={{
                          ...styles.tableCellHint,
                        }}
                      >
                        <Box sx={{ fontSize: 11.5 }}>{item?.title} </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow
                  sx={{
                    borderBottom: `1px solid ${borderTextBoxColor}`,
                    verticalAlign: "top",
                  }}
                >
                  <TableCell sx={styles.tableBodyCell(false)}>
                    <TableDropdown
                      value={ondcData?.returnTerms?.returnable?.toUpperCase()}
                      onValueChange={(value) => {
                        onValueChange("returnTerms", {
                          ...ondcData?.returnTerms,
                          returnable: value,
                        });
                      }}
                      menuList={YES_NO_DROPDOWN_DATA}
                      borderFull
                      rounded
                    />
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell(false)}>
                    <TabelCellInput
                      value={ondcData?.returnTerms?.returnPeriod}
                      onValueChange={(value) => {
                        onValueChange("returnTerms", {
                          ...ondcData?.returnTerms,
                          returnPeriod: value,
                        });
                      }}
                      regexType="number"
                      errorMsg={ondcData?.errors?.returnPeriod}
                    />
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell(false)}>
                    <TableDropdown
                      value={
                        ondcData?.returnTerms?.seller_pickup_return?.toUpperCase() ||
                        "NO"
                      }
                      onValueChange={(value) => {
                        onValueChange("returnTerms", {
                          ...ondcData?.returnTerms,
                          seller_pickup_return: value,
                        });
                      }}
                      menuList={YES_NO_DROPDOWN_DATA}
                      borderFull
                      rounded
                    />
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell(false)}>
                    <TableDropdown
                      value={ondcData?.cancelTerms?.cancellable?.toUpperCase()}
                      onValueChange={(value) => {
                        onValueChange("cancelTerms", {
                          ...ondcData?.cancelTerms,
                          cancellable: value,
                        });
                      }}
                      menuList={YES_NO_DROPDOWN_DATA}
                      borderFull
                      rounded
                    />
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell(false)}>
                    <TableDropdown
                      value={ondcData?.cancelTerms?.refundEligible?.toUpperCase()}
                      onValueChange={(value) => {
                        onValueChange("cancelTerms", {
                          ...ondcData?.cancelTerms,
                          refundEligible: value,
                        });
                      }}
                      menuList={YES_NO_DROPDOWN_DATA}
                      borderFull
                      rounded
                      disabled={
                        ondcData?.cancelTerms?.cancellable?.toUpperCase() ===
                        "NO"
                      }
                    />
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell(false)}>
                    <TabelCellInput
                      value={ondcData?.cancelTerms?.cancellationFeePercentage}
                      onValueChange={(value) => {
                        onValueChange("cancelTerms", {
                          ...ondcData?.cancelTerms,
                          cancellationFeePercentage: value,
                        });
                      }}
                      regexType="number"
                      disabled={
                        ondcData?.cancelTerms?.cancellable?.toUpperCase() ===
                        "NO"
                      }
                      errorMsg={ondcData?.errors?.cancellationFeePercentage}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Grid container spacing={1} display={{ xs: "flex", md: "none" }} mt={1}>
        <Grid item xs={12}>
          <SelectWithLabel
            label="RETURNABLE"
            required
            value={ondcData?.returnTerms?.returnable?.toUpperCase()}
            onValueChange={(value) => {
              onValueChange("returnTerms", {
                ...ondcData?.returnTerms,
                returnable: value,
              });
            }}
            menuList={YES_NO_DROPDOWN_DATA}
          />
        </Grid>
        <Grid item xs={7}>
          <FormInputBox
            label="RETURN PERIOD (IN DAYS)"
            required
            value={ondcData?.returnTerms?.returnPeriod}
            onChange={({ target }) => {
              onValueChange("returnTerms", {
                ...ondcData?.returnTerms,
                returnPeriod: target.value,
              });
            }}
            error={ondcData?.errors?.returnPeriod}
            inputType={"number"}
          />
        </Grid>
        <Grid item xs={5}>
          <SelectWithLabel
            label="SELLER PICKUP"
            required
            value={
              ondcData?.returnTerms?.seller_pickup_return?.toUpperCase() || "NO"
            }
            onValueChange={(value) => {
              onValueChange("returnTerms", {
                ...ondcData?.returnTerms,
                seller_pickup_return: value,
              });
            }}
            menuList={YES_NO_DROPDOWN_DATA}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectWithLabel
            label="CANCELLABLE "
            required
            value={ondcData?.cancelTerms?.cancellable?.toUpperCase()}
            onValueChange={(value) => {
              onValueChange("cancelTerms", {
                ...ondcData?.cancelTerms,
                cancellable: value,
              });
            }}
            menuList={YES_NO_DROPDOWN_DATA}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectWithLabel
            label="REFUND ELIGIBLE "
            value={ondcData?.cancelTerms?.refundEligible?.toUpperCase()}
            onValueChange={(value) => {
              onValueChange("cancelTerms", {
                ...ondcData?.cancelTerms,
                refundEligible: value,
              });
            }}
            menuList={YES_NO_DROPDOWN_DATA}
            disabled={
              ondcData?.cancelTerms?.cancellable?.toUpperCase() === "NO"
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormInputBox
            label="CANCELLATION FEE %"
            value={ondcData?.cancelTerms?.cancellationFeePercentage}
            onChange={({ target }) => {
              onValueChange("cancelTerms", {
                ...ondcData?.cancelTerms,
                cancellationFeePercentage: target.value,
              });
            }}
            disabled={
              ondcData?.cancelTerms?.cancellable?.toUpperCase() === "NO"
            }
            inputType={"number"}
            error={ondcData?.errors?.cancellationFeePercentage}
          />
        </Grid>
      </Grid>
      <Typography sx={styles.title} mt={2}>
        Product Specifications
      </Typography>
      <Grid container spacing={{md:2,xs:1}}>
        {ondcSpecificationData?.map((item, index) => {
          if (item?.display === "N") return null;
          if (item?.type === "variants" || item?.type === "data") {
            return (
              <Grid item md={5} xs={12} key={item?.code} >
                <HorizontalSelectWithLabel
                  required={item?.required?.toLowerCase() === "m"}
                  label={item?.displayName}
                  hintText={item?.hintText}
                  menuList={item?.values || []}
                  value={item?.inputValue}
                  onValueChange={(value) => handleChange(index, value)}
                  error={item?.error}
                />
              </Grid>
            );
          }
          if (item?.type === "codeValue") {
            return (
              <Grid item md={5} xs={12} key={item?.code}>
                <HorizontalSelectWithLabel
                  required={item?.required?.toLowerCase() === "m"}
                  label={item?.displayName}
                  hintText={item?.hintText}
                  menuList={item?.options || []}
                  value={item?.inputValue}
                  onValueChange={(value) => handleChange(index, value)}
                  error={item?.error}
                />
              </Grid>
            );
          }

          if (item?.type === "default") {
            return (
              <Grid item md={5} xs={12} key={item?.code}>
                <HorizontalInputWithLabel
                  required={item?.required?.toLowerCase() === "m"}
                  label={item?.displayName}
                  hintText={item?.hintText}
                  value={
                    item?.values?.[0]?.charAt(0)?.toUpperCase() +
                    item?.values?.[0]?.slice(1)
                  }
                  disabled
                  error={item?.error}
                />
              </Grid>
            );
          }
          if (item?.type === "freeform") {
            return (
              <Grid item md={5} xs={12} key={item?.code}>
                <HorizontalInputWithLabel
                  required={item?.required?.toLowerCase() === "m"}
                  label={item?.displayName}
                  hintText={item?.hintText}
                  value={item?.inputValue}
                  onValueChange={(value) => {
                    handleChange(index, value);
                  }}
                  error={item?.error}
                />
              </Grid>
            );
          }
          if (item?.type === "sample data") {
            return (
              <Grid item md={5} xs={12} key={item?.code}>
                <HorizontalMultiSelectCustomTextWithLabel
                  required={item?.required?.toLowerCase() === "m"}
                  label={item?.displayName}
                  hintText={item?.hintText}
                  menuList={item?.values || []}
                  value={item?.inputValue}
                  onValueChange={(value) => handleChange(index, value)}
                  error={item?.error}
                />
              </Grid>
            );
          }
          if (item?.type === "radioButton") {
            return (
              <Grid item md={5} xs={12} key={item?.code}>
                <HorizontalRadioGroupWithLabel
                  required={item?.required?.toLowerCase() === "m"}
                  label={item?.displayName}
                  hintText={item?.hintText}
                  error={item?.error}
                  radioOptions={item?.radioOptions}
                  onValuesChanged={(obj) => {
                    const updatedRadioOptions = item?.radioOptions?.map(
                      (option) => {
                        if (option?.key === obj?.key) {
                          return {
                            ...option,
                            value: option?.options?.selected,
                          };
                        } else {
                          return {
                            ...option,
                            value: option?.options?.notSelected,
                          };
                        }
                      }
                    );
                    console.log(updatedRadioOptions);
                    handleChange(index, updatedRadioOptions, "radioOptions");
                  }}
                />
              </Grid>
            );
          }
          if (item?.type === "dependentCode") {
            return (
              <Grid item md={5} xs={12} key={item?.code}>
                <HorizontalSelectWithLabel
                  required={item?.required?.toLowerCase() === "m"}
                  label={item?.displayName}
                  hintText={item?.hintText}
                  menuList={item?.options || []}
                  value={item?.inputValue}
                  onValueChange={(value) => handleChange(index, value)}
                  error={item?.error}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </Box>
  );
}

export default AddONDCControls;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    border: border25,
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: "15px 15px 15px",
    mb: 2,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    mb: "10px",
    color: blackShade19,
  },
  tableWrapper: {
    width: "100%",
    borderRadius: "8px",
    border: `1px solid ${borderTextBoxColor}`,
    borderBottom: "none",
  },

  tableContainer: {
    borderRadius: "8px",
    height: "80px",
    // overflow: "scroll",
    maxWidth: "100%",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      // background: '#d0d3da',
      background: "rgba(0,0,0,0.1)",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.05)",
    },
  },

  tableCell: {
    color: greyColor47,
    fontSize: "12.5px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    padding: "8px 15px",
    backgroundColor: greyColor42,
    borderRight: `1px solid ${whiteColor}`,
  },
  tableCellHint: {
    color: orangeShade11,
    fontSize: "11.5px",
    fontWeight: "400",
    whiteSpace: "nowrap",
    padding: "4px 15px",
    backgroundColor: bgColor25,
    borderRight: `1px solid ${borderTextBoxColor}`,
  },

  tableBodyCell: (isError) => ({
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: isError ? lightRedColor : whiteColor,
    padding: "8px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,

    boxShadow: isError ? `0px -1px 0 0 ${redColor} inset` : "none",
  }),
};
