import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import {
  BGColor02,
  blackShade19,
  blackShade43,
  greyColor40,
  logoImgborder1,
  orangeColor02,
  orangeShade12,
  orangeShade13,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import CallIcon from "@mui/icons-material/Call";
import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import ModalCard from "../../../components/ModalCard";
import { textOneLines } from "../../../utils/styles";

function DelegateContactInfoCard({ activeBusinessInfo }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [openModel, setOpenModel] = useState(false);

  return (
    <Box sx={styles.card}>
      <Box display="flex" alignItems="center" gap={{ xs: 1.2, md: 1.5 }}>
        <Box sx={styles.iconCircle}>
          <SupervisorAccountOutlinedIcon sx={styles.icon} />
        </Box>

        <Box>
          <Typography
            fontSize={{ xs: 10, md: 11.2 }}
            fontWeight={500}
            color={greyColor40}
          >
            DELEGATES - {activeBusinessInfo?.delegates?.length || 0}
          </Typography>

          <ContactInfo
            icon={<PersonIcon sx={{ fontSize: "15px" }} />}
            text={activeBusinessInfo?.delegates?.[0]?.name || "N/A"}
          />
          
          <Box display="flex" alignItems="center" gap={{ xs: 1.2, md: 1.5 }}>
            <ContactInfo
              width={matches ? null : "120px"}
              icon={<CallIcon sx={{ fontSize: "15px" }} />}
              text={
                activeBusinessInfo?.delegates?.length > 0
                  ? activeBusinessInfo?.delegates?.[0]?.phone?.replace(
                      "+91",
                      "+91 "
                    )
                  : "N/A"
              }
            />

            <Typography
              fontSize={{ xs: 10, md: 12 }}
              fontWeight={500}
              color={orangeColor02}
              sx={{
                textDecoration: "underline",
                mt: "-3px",
                cursor: "pointer",
              }}
              onClick={() => {
                setOpenModel(true);
              }}
            >
              More
            </Typography>
          </Box>
        </Box>
      </Box>

      <DelegateListModel
        openModal={openModel}
        setOpenModal={setOpenModel}
        delegateList={activeBusinessInfo?.delegates || []}
      />
    </Box>
  );
}

export default DelegateContactInfoCard;

const DelegateListModel = ({ openModal, setOpenModal, delegateList }) => {
  return (
    <ModalCard
      open={openModal}
      handleClose={() => {
        setOpenModal(false);
      }}
      width="30%"
    >
      <Box sx={styles.container}>
        <Box
          sx={{
            backgroundColor: BGColor02,
            p: 1.5,
            borderRadius: "14.4px 14.4px 0 0",
            textAlign: "center",
          }}
        >
          <Typography
            variant="body1"
            fontSize={16}
            fontWeight={600}
            color={blackShade19}
            mb={0.3}
          >
            Delegates
          </Typography>
        </Box>

        <Box sx={{ p: "10px 15px 15px 15px" }}>
          {delegateList?.map((item, index) => (
            <Box
              key={`contact_${index}`}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderBottom={logoImgborder1}
              py={1.2}
            >
              <Box flex={1}>
                <ContactInfo
                  icon={<PersonIcon sx={{ fontSize: "15px" }} />}
                  text={item?.name || "N/A"}
                />
              </Box>

              <Box flex={1}>
                <ContactInfo
                  icon={<CallIcon sx={{ fontSize: "15px" }} />}
                  text={
                    item?.phone ? item?.phone?.replace("+91", "+91 ") : "N/A"
                  }
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </ModalCard>
  );
};

const ContactInfo = ({ icon, text, width }) => {
  return (
    <Box display="flex" alignItems="center" gap={0.7} width={width || "auto"}>
      {icon}
      <Typography
        fontSize={{ xs: 11.5, md: 12 }}
        fontWeight={500}
        color={blackShade43}
        sx={{ ...textOneLines }}
      >
        {text}
      </Typography>
    </Box>
  );
};

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: orangeShade12,
    border: `1px solid ${orangeShade13}`,
    borderRadius: { xs: "10px", md: "13px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "5px 10px", md: "10px 15px" },
    width: "100%",
  },

  iconCircle: {
    width: { md: "40px", xs: "34px" },
    height: { md: "40px", xs: "34px" },
    borderRadius: "50%",
    border: `1px solid ${orangeShade13}`,
    backgroundColor: whiteColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  icon: {
    fontSize: { md: "20px", xs: "17px" },
    color: { xs: orangeColor02, md: orangeColor02 },
  },

  container: {
    backgroundColor: whiteColor,
    borderRadius: 1.8,
  },
};
