import { useTheme } from "@emotion/react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  blackColor,
  blackShade05,
  borderTextbox,
  greyColor6,
  whiteColor,
} from "../../configs/styles/muiThemes";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function MultiSelectWithLabel({
  label,
  required,
  value,
  onValueChange,
  error,
  menuList,
  valueSelector,
  isDashboardInput,
  disabled,
  dashboard,
}) {
  const [personName, setPersonName] = React.useState([]);
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log("hghgh", value);
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    onValueChange(value);
  };

  useEffect(() => {
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  }, [value]);

  
  return (
    <Box>
      <Box>
        <Typography
          variant="h3"
          fontSize={isDashboardInput ? 14 : 13}
          color={isDashboardInput ? blackColor : greyColor6}
          fontWeight={isDashboardInput ? 500 : 400}
          mb={isDashboardInput ? "8px !important" : "0px"}
          sx={{ display: "inline" }}
        >
          {label}{" "}
        </Typography>
        {required ? (
          <Typography
            variant="body1"
            sx={[styles.label, { color: "#f00", display: "inline" }]}
          >
            *
          </Typography>
        ) : null}
      </Box>

      <Box
        sx={{
          borderTop: "none",
          borderBottom: borderTextbox,
          borderLeft: "none",
          borderRight: "none",
          borderRadius: "0px",
          height: { xs: "42px", md: "auto" },
          backgroundColor: whiteColor,
        }}
      >
        <FormControl sx={styles.formControlStyle}>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={personName}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  // flexWrap: "wrap",
                  // gap: 0.8,
                  fontSize: 13.5,
                  fontWeight: 500,
                  color: blackShade05,
                  ml:-1.8
                }}
              >
                {selected?.map((value, index) => (
                  //   <Chip key={value} label={value} variant="outlined" />
                  <Box key={index} >
                    {value}
                    {index === selected.length - 1 ? "" : ","}
                  </Box>
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
            sx={{
              minHeight: "45px",
              pb: "2px",
            }}
          >
            {menuList?.map((menu, index) => (
              <MenuItem
                key={index}
                value={menu.name}
                style={getStyles(menu.name, personName, theme)}
              >
                {menu.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {error ? (
        <Typography variant="subtitle1" component="p" fontSize={12} color="red" mt={0.8}>
          {error}
        </Typography>
      ) : null}
    </Box>
  );
}

export default MultiSelectWithLabel;

const styles = {
  label: {
    fontSize: { md: "14px", xs: "12px" },
    color: blackColor,
    display: "inline",
  },

  formControlStyle: {
    width: "100%",
    minHeight: "45px",
    "& .css-hdw1oc": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline, .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
      {
        border: "none",
      },
  },
};
