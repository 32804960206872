import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import {
  BGColor02,
  blackShade05,
  greyColor7,
  greyColor8,
  greyColor9,
  menuGradient,
  whiteColor,
} from "../../configs/styles/muiThemes";
import FormInputBox from "../../components/Input/FormInputBox";

function AddCustomerModalContent({ onCancel, onSubmit }) {
  return (
    <Box sx={styles.card}>
      <Typography
        variant="h3"
        fontSize={16}
        fontWeight={600}
        lineHeight="25px"
        color={blackShade05}
        textAlign="center"
        p="15px 50px"
        mb="15px"
      >
        Add a New Customer
      </Typography>

      <Box
        sx={{
          backgroundColor: whiteColor,
          p: "15px",
        }}
      >
        <FormInputBox
          label="Customer Name"
          placeholder="Enter Customer Name"
          required
        />

        <FormInputBox
          label="Phone Number"
          placeholder="Ex:(919876543210)%"
          required
        />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p="15px"
      >
        <Button sx={[styles.actionBtn, styles.cancelBtn]} onClick={onCancel}>
          CANCEL
        </Button>

        <Button sx={[styles.actionBtn, styles.proceedBtn]} onClick={onSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default AddCustomerModalContent;

const styles = {
  card: {
    backgroundColor: BGColor02,
    borderRadius: "15px",
  },

  actionBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    color: greyColor8,
    border: `1px solid ${greyColor8}`,
    p: "4px 35px",
  },

  cancelBtn: {
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },

  proceedBtn: {
    color: whiteColor,
    background: menuGradient,
    p: "5px 35px",
    border: "none",

    "&:hover": {
      color: greyColor8,
      background: whiteColor,
      border: `1px solid ${greyColor8}`,
    },
  },
};
