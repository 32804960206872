import { Box, IconButton } from "@mui/material";
import React from "react";
import { blackColor, whiteColor } from "../../configs/styles/muiThemes";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import DashboardMenu from "./DashboardMenu";
import { DISABLE_ALL_MENU } from "../../utils/data";

function MobileSideMenu({
  onCloseClick,
  disableMenu,
  activeMenu,
  activeSubMenu,
  hideBusinessData
}) {
  return (
    <Box sx={styles.container}>
      <DashboardMenu
        disableMenu={disableMenu}
        activeMenu={activeMenu}
        activeSubMenu={activeSubMenu}
        hideBusinessData={hideBusinessData}
      />
      <StyledCloseBtn onClick={onCloseClick}>
        <CloseIcon />
      </StyledCloseBtn>
    </Box>
  );
}

const StyledCloseBtn = styled(IconButton)({
  color: blackColor,
  backgroundColor: whiteColor,
  position: "absolute",
  top: 10,
  left: "260px",

  "&:hover": {},
});

export default MobileSideMenu;
const styles = {
  container: {
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.4)",

    position: "fixed",
    top: { xs: "0px", md: "55px" },
    left: 0,
    zIndex: 999,
  },
};
