export const ONDC_DEFAULT_DATA = {
  productDisplayUnitOfMeasure: {
    // unit: "pieces",
    unit: "",
    value: "",
  },
  netProductWeight: "",
  ondcEnabled: "no",

  // ONDC Product dropdown
  ondcProductCategory: {
    name: "Select ONDC Product Category",
    code: "",
    status: "",
  },
  ondcSubCatList: [],
  ondcProductSubCategory: { productSubCategory1: "" },

  ondc: {
    discount: "0",
    veg_nonveg: {
      veg: "no",
      nonVeg: "no",
      egg: "no",
    },
    productCode: {
      key: "EAN",
      value: "",
    },
    shelfLife: "",
    fragile: "none",
    timeToShip: "",
    available_on_cod: false,
    cancelTerms: {
      cancellable: "none",
      cancellationFeeAmount: null,
      cancellationFeePercentage: "",
      refundEligible: "none",
    },
    returnTerms: {
      returnable: "none",
      returnPeriod: "",
      seller_pickup_return: "none",
    },
    fssai: [
      {
        number: "",
        validTill: null,
      },
    ],
    statutoryReqsPackagedCommodities: {
      manufacturer_or_packer_name: "",
      manufacturer_or_packer_address: "",
      common_or_generic_name_of_commodity: "",
      mfg_license_no: "",
      expiry_date: "",
      month_year_of_manufacture_packing_import: "",
      net_quantity_or_measure_of_commodity_in_pkg: "",
      multiple_products_name_number_or_qty: "",
    },
    statutoryReqsPrepackagedFood: {
      ingredients_info: "",
      nutritional_info: "",
      additives_info: "",
      brand_owner_FSSAI_license_no: "",
      other_FSSAI_license_no: "",
      importer_FSSAI_license_no: "",
    },
  },
};

export const ONDC_DEFAULT_NEW_DATA = {
  fragile: false,
  timeToShip: "",
  timeToShipUnit: {
    label: "Minutes",
    unit: "M",
  },

  available_on_cod: false,
  cancelTerms: {
    cancellable: "no",
    cancellationFeePercentage: "",
    refundEligible: "no",
  },
  returnTerms: {
    returnable: "no",
    returnPeriod: "",
    seller_pickup_return: "no",
  },
  productCode: {
    key: "",
    value: "",
  },
  unitOfMeasure: {
    unit: "",
    value: "",
  },
};

export const ONDC_ECOM_DEFAULT_DATA = {
  cmsOndcEnabled: "no", // yes / no
  ondcEnabled: "no", // yes / no

  ondc: {
    packagingCost: "",
    convenienceFee: "",
    ondcDomain: [],

    ondcCustomerDiscountEnabled: "no", // yes / no

    ondcBuyerFinderFeeUpperLimitPercentage: "",
    ondcBuyerFinderFeeUpperLimitIncludingMktg: "",

    // delivery method
    ondcBPPFulfillments: { id: "F1", type: "Delivery" },

    ondcShipmentMethod: [],

    // shipment
    ondcSupportRegion: { label: "Select Shipment Region", value: "" },
    ondcSupportDistanceKm: "",

    ondcMultiWarehouseAllowed: "no",

    // min order amt
    ondcMinOrder: "",

    brandMessaging: null,
    notes: "",

    cancelTerms: {
      ondcPartialCancellationStage: "ordercreated",
      ondcFullCancellationStage: {
        label: "Order Created",
        value: "ordercreated",
      },
    },

    autoApproval: {
      autoApprovalEnabled: false, // true / false
      autoApprovalAlert: true,
    },

    // Timings

    isOpen24Hr: "",

    storeDays: [
      {
        id: "7",
        label: "Sunday",
        isOpen: false,
      },
      {
        id: "1",
        label: "Monday",
        isOpen: false,
      },
      {
        id: "2",
        label: "Tuesday",
        isOpen: false,
      },
      {
        id: "3",
        label: "Wednesday",
        isOpen: false,
      },
      {
        id: "4",
        label: "Thursday",
        isOpen: false,
      },
      {
        id: "5",
        label: "Friday",
        isOpen: false,
      },
      {
        id: "6",
        label: "Saturday",
        isOpen: false,
      },
    ],

    storeStartTime: "",
    storeEndTime: "",

    storeTimeList: [{ storeStartTime: "", storeEndTime: "" }],

    breakStartTime: "",
    breakEndTime: "",

    holidayStartDate: "",
    holidayEndDate: "",

    buyerLinks: [{ code: "", value: "", displayName: "" }],
  },
};
