import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import FormInputBox from "../../../components/Input/FormInputBox";
import {
  blackColor,
  blackShade05,
  greyColor11,
  greyColor6,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import DashboardStepper from "../../Dashboard/DashboardStepper";
import DateInput from "../../../components/Input/DateInput";
import moment from "moment";
import RadiosBtnWithLabel from "../../../components/Input/RadiosBtnWithLabel";
import StyledSwitch from "../../../components/Switch/StyledSwitch";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import RadioOptionWithLabel from "../../../components/Radio/RadioOptionWithLabel";
import {
  PRODUCT_CODE_UNIT_LIST,
  TIME_TO_SHIP_UNIT,
  UNITS_OF_MEASURE,
} from "../../../utils/data";
import { getProductCodeMaxLength } from "../../../utils/helper";
import ONDCMultiValueInput from "../../Catalog/PopupModals/ONDCMultiValueInput";
import { getProductSubCategoryOneAPI } from "../../../service/api_calls/AddProduct/AddProductAPI";

function ONDCInfoForm({
  data,
  setData,
  ondcUIControl,
  setOndcUIControl,
  ondcProductCategory,
  onValueChange,
  currentPage,
  gotoNext,
  goBack,
  productName,
  isAddProduct,
  onCancelClick,
  isCopyProduct,
  handleTabChange,
  tabTitle,
  unitOfMeasurementData,
}) {
  const handleTimeToShipChange = (target) => {
    const value = target?.value?.replace(/[^0-9]/g, "");
    setData((prevState) => ({
      ...prevState,
      ondc: {
        ...prevState?.ondc,
        timeToShip: value,
      },
      errors: { ...prevState?.errors, timeToShip: "" },
    }));
  };

  const handleOndcInfoChange = (property, value, rootProperty) => {
    setData((prevState) => {
      const ondcUpdate = rootProperty
        ? {
            ...prevState.ondc,
            [rootProperty]: {
              ...prevState.ondc?.[rootProperty],
              [property]: value,
            },
          }
        : {
            ...prevState.ondc,
            [property]: value,
          };

      return {
        ...prevState,
        ondc: ondcUpdate,
        errors: { ...prevState?.errors, [property]: "" },
      };
    });
  };

  const handleVegNonVegInput = (property, value) => {
    const vegNonVegData = {
      veg: "no",
      nonVeg: "no",
      egg: "no",
    };

    setData((prevState) => ({
      ...prevState,
      ondc: {
        ...prevState?.ondc,
        veg_nonveg: {
          ...vegNonVegData,
          [property]: value,
        },
      },
      errors: { ...prevState?.errors, [property]: "" },
    }));
  };

  const clearFoodItemsInfo = () => {
    setData((prevState) => ({
      ...prevState,
      ondc: {
        ...prevState?.ondc,
        veg_nonveg: {
          veg: "no",
          nonVeg: "no",
          egg: "no",
        },
        shelfLife: "",
        fssai: [
          {
            number: "",
            validTill: null,
          },
        ],
      },
    }));
  };

  const clearPackageInfo = () => {
    setData((prevState) => ({
      ...prevState,
      ondc: {
        ...prevState?.ondc,
        statutoryReqsPackagedCommodities: {
          manufacturer_or_packer_name: "",
          manufacturer_or_packer_address: "",
          common_or_generic_name_of_commodity: "",
          mfg_license_no: "",
          expiry_date: "",
          month_year_of_manufacture_packing_import: "",
          net_quantity_or_measure_of_commodity_in_pkg: "",
          multiple_products_name_number_or_qty: "",
        },
      },
    }));
  };

  const clearPrePackageInfo = () => {
    setData((prevState) => ({
      ...prevState,
      ondc: {
        ...prevState?.ondc,
        statutoryReqsPrepackagedFood: {
          ingredients_info: "",
          nutritional_info: "",
          additives_info: "",
          brand_owner_FSSAI_license_no: "",
          other_FSSAI_license_no: "",
          importer_FSSAI_license_no: "",
        },
      },
    }));
  };

  // Handle Product categoty change
  const handleCategoryChange = async (catData) => {
    console.log(catData);

    setData((prevState) => ({
      ...prevState,
      ondcProductCategory: catData,
    }));

    const response = await getProductSubCategoryOneAPI(catData?.code);

    console.log(response);

    setData((prevState) => ({
      ...prevState,
      ondcProductCategory: catData,
      ondcSubCatList: response?.details || [],
      ondcProductSubCategory: { productSubCategory1: "" },
    }));
  };

  const handleSubCategoryChange = (subCatData) => {
    setData((prevState) => ({
      ...prevState,
      ondcProductSubCategory: subCatData,
    }));
  };

  return (
    <Box sx={styles.card}>
      <Box mb={"10px !important"}>
        <Typography component="h6" sx={styles.sectionHeader}>
          ONDC Parameters
        </Typography>

        <Typography
          component="p"
          fontSize={13}
          fontWeight={400}
          color={greyColor6}
        >
          ONDC Enabled{" "}
          <Typography variant="body1" sx={{ display: "inline", color: "#f00" }}>
            *
          </Typography>
        </Typography>
      </Box>

      <Box>
        <StyledSwitch
          label="Yes / No"
          isChecked={data?.ondcEnabled === "yes"}
          onChange={() => {
            setData((prevState) => ({
              ...prevState,
              ondcEnabled: prevState?.ondcEnabled === "yes" ? "no" : "yes",
            }));
          }}
        />

        {data?.ondcEnabled === "yes" && (
          <Typography
            component="label"
            fontSize={{ xs: 12, md: 13 }}
            color={greyColor6}
            fontWeight={400}
            lineHeight="18px"
            m="8px 0 8px 0 !important"
          >
            Below details are mandatory for a product to be listed in ONDC
          </Typography>
        )}
      </Box>

      {data?.ondcEnabled === "yes" && (
        <>
          <Box mt={2}>
            <Typography component="h6" sx={styles.sectionHeader}>
              ONDC Product Category
            </Typography>

            <Grid container rowSpacing={2} columnSpacing={6}>
              <Grid item xs={12} md={4}>
                <SelectWithLabel
                  label="Product Category"
                  required
                  value={data?.ondcProductCategory?.name}
                  onValueChange={handleCategoryChange}
                  returnFullItem
                  menuList={
                    [
                      {
                        name: "Select ONDC Product Category",
                        code: "",
                        status: "",
                      },
                      ...ondcProductCategory,
                    ] || []
                  }
                  valueSelector="name"
                  errorMsg={data?.errors?.ondcProductCategory}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <SelectWithLabel
                  label="Product Sub Category"
                  required
                  value={data?.ondcProductSubCategory?.productSubCategory1}
                  onValueChange={handleSubCategoryChange}
                  returnFullItem
                  menuList={[...data?.ondcSubCatList] || []}
                  valueSelector="productSubCategory1"
                  errorMsg={data?.errors?.ondcProductSubCategory}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <Typography component="h6" sx={styles.sectionHeader}>
              Shipments & Discounts
            </Typography>

            <Grid container rowSpacing={2} columnSpacing={6}>
              <Grid item xs={12} md={3.5}>
                <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={5} md={5.5}>
                    <SelectWithLabel
                      label="Time to Ship"
                      required
                      value={ondcUIControl?.timeToShipUnit?.label}
                      onValueChange={(value) => {
                        setOndcUIControl((prevState) => ({
                          ...prevState,
                          timeToShipUnit: value,
                        }));

                        setData((prevState) => ({
                          ...prevState,
                          ondc: {
                            ...prevState?.ondc,
                            timeToShip: "",
                          },
                        }));
                      }}
                      returnFullItem
                      menuList={TIME_TO_SHIP_UNIT}
                      errorMsg=""
                    />
                  </Grid>
                  <Grid item xs={7} md={6.5} mt={3}>
                    <FormInputBox
                      label=""
                      placeholder="Enter Time to Ship"
                      value={data?.ondc?.timeToShip}
                      onChange={({ target }) => {
                        handleTimeToShipChange(target);
                      }}
                      error={data?.errors?.timeToShip}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                <RadioOptionWithLabel
                  label="Available on COD (Cash on Delivery)"
                  required
                  value={
                    data?.ondc?.available_on_cod === "none"
                      ? ""
                      : data?.ondc?.available_on_cod === true
                      ? "Yes"
                      : "No"
                  }
                  onYesClick={() => {
                    handleOndcInfoChange("available_on_cod", true);
                  }}
                  onNoClick={() => {
                    handleOndcInfoChange("available_on_cod", false);
                  }}
                  disableYes
                  error={data?.errors?.available_on_cod}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                {/* <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={12} md={3.5}>
                    <FormInputBox
                      label="Customer Discount"
                      placeholder=""
                      required
                      startIcon="₹"
                      value={data?.ondc?.discount}
                      onChange={({ target }) => {
                        const value = target?.value?.replace(/[^0-9]/g, "");
                        handleOndcInfoChange("discount", value);
                      }}
                      error={data?.errors?.discount}
                    />
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <Typography component="h6" sx={styles.sectionHeader}>
              Additional Product Info
            </Typography>

            <Grid container rowSpacing={2} columnSpacing={6}>
              <Grid item xs={6} md={2.5}>
                <SelectWithLabel
                  label="Unit of measure"
                  value={data?.productDisplayUnitOfMeasure?.unit}
                  onValueChange={(value) => {
                    setData((prevState) => ({
                      ...prevState,
                      productDisplayUnitOfMeasure: {
                        ...prevState?.productDisplayUnitOfMeasure,
                        unit: value?.value,
                      },
                    }));
                  }}
                  returnFullItem
                  // menuList={UNITS_OF_MEASURE}
                  menuList={unitOfMeasurementData}
                  valueSelector="value"
                  required
                  errorMsg={data?.errors?.productDisplayUnitOfMeasure1}
                />
              </Grid>

              <Grid item xs={6} md={2.5}>
                <FormInputBox
                  label="Enter value"
                  placeholder=""
                  required
                  value={data?.productDisplayUnitOfMeasure?.value}
                  onChange={({ target }) => {
                    const value = target?.value?.replace(/[^0-9]/g, "");
                    setData((prevState) => ({
                      ...prevState,
                      productDisplayUnitOfMeasure: {
                        ...prevState?.productDisplayUnitOfMeasure,
                        value: value,
                      },
                      errors: {
                        ...prevState?.errors,
                        productDisplayUnitOfMeasure: "",
                      },
                    }));
                  }}
                  error={data?.errors?.productDisplayUnitOfMeasure}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} md={2.5}>
                    <FormInputBox
                      label="Net Product Weight (in gms)"
                      required
                      placeholder=""
                      value={data?.weight}
                      onChange={({ target }) => {
                        const value = target?.value
                          ?.replace(/[^\d.]/g, "")
                          ?.replace(/^0+(?=\d)/, "")
                          ?.replace(/^(\d*\.\d{0,2}).*/, "$1");

                        if (value === "" || parseFloat(value) <= 10000000) {
                          setData((prevState) => ({
                            ...prevState,
                            weight: value,
                            errors: {
                              ...prevState?.errors,
                              weight: "",
                            },
                          }));
                        }
                      }}
                      error={data?.errors?.weight && "Enter weight"}
                    />
                  </Grid>

                  <Grid item xs={8} md={2}>
                    <FormInputBox
                      label="In kgs (max: 9,999)"
                      placeholder=""
                      value={Number(data?.weight) / 1000}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={8} md={2.3}>
                    <FormInputBox
                      label="Shipping Weight (in gms)"
                      placeholder=""
                      required
                      value={data?.netProductWeight}
                      onChange={({ target }) => {
                        const value = target?.value?.replace(/[^0-9]/g, "");
                        setData((prevState) => ({
                          ...prevState,
                          netProductWeight: value,
                          errors: {
                            ...prevState?.errors,
                            netProductWeight: "",
                          },
                        }));
                      }}
                      helperText="Product + Package Weight"
                      error={data?.errors?.netProductWeight}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={5} md={2.3}>
                    <SelectWithLabel
                      label="Product Code"
                      value={data?.ondc?.productCode?.key}
                      onValueChange={(value) => {
                        handleOndcInfoChange(
                          "key",
                          value?.label,
                          "productCode"
                        );
                      }}
                      returnFullItem
                      menuList={PRODUCT_CODE_UNIT_LIST}
                      required
                      errorMsg={data?.errors?.productCode1}
                    />
                  </Grid>

                  <Grid item xs={7} md={2.5}>
                    <Box mt={3.2}>
                      <FormInputBox
                        label=" "
                        placeholder=""
                        value={data?.ondc?.productCode?.value}
                        onChange={({ target }) => {
                          const value = target?.value?.replace(/[^0-9]/g, "");
                          handleOndcInfoChange("value", value, "productCode");
                        }}
                        maxLength={getProductCodeMaxLength(
                          data?.ondc?.productCode?.key
                        )}
                        error={data?.errors?.productCode}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} md={3}>
                <RadioOptionWithLabel
                  label="Fragile"
                  required
                  value={
                    data?.ondc?.fragile === "yes"
                      ? "Yes"
                      : data?.ondc?.fragile === "no"
                      ? "No"
                      : ""
                  }
                  onYesClick={() => {
                    setData((prevState) => ({
                      ...prevState,
                      ondc: {
                        ...prevState?.ondc,
                        fragile: "yes",
                      },
                      errors: { ...prevState?.errors, fragile: "" },
                    }));
                  }}
                  onNoClick={() => {
                    setData((prevState) => ({
                      ...prevState,
                      ondc: {
                        ...prevState?.ondc,
                        fragile: "no",
                      },
                      errors: { ...prevState?.errors, fragile: "" },
                    }));
                  }}
                  error={data?.errors?.fragile}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <Typography component="h6" sx={styles.sectionHeader}>
              Return Terms
            </Typography>

            <Grid container rowSpacing={2} columnSpacing={0}>
              <Grid item xs={12} md={12}>
                <RadioOptionWithLabel
                  label="Returnable"
                  required
                  value={
                    data?.ondc?.returnTerms?.returnable === "yes"
                      ? "Yes"
                      : data?.ondc?.returnTerms?.returnable === "no"
                      ? "No"
                      : ""
                  }
                  onYesClick={() => {
                    handleOndcInfoChange("returnable", "yes", "returnTerms");
                  }}
                  onNoClick={() => {
                    handleOndcInfoChange("returnable", "no", "returnTerms");
                  }}
                  error={data?.errors?.returnable}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={12} md={10}>
                    <FormInputBox
                      label="Return/Dispute Eligible Period (In Days)"
                      placeholder=""
                      required
                      value={data?.ondc?.returnTerms?.returnPeriod}
                      onChange={({ target }) => {
                        const value = target?.value?.replace(/[^0-9]/g, "");
                        handleOndcInfoChange(
                          "returnPeriod",
                          value,
                          "returnTerms"
                        );
                      }}
                      error={data?.errors?.returnPeriod}
                    />
                  </Grid>

                  {/* <Grid item xs={4} md={4}>
                    <Box sx={styles.infoTextBox}>
                      <Typography
                        component="p"
                        fontSize={13}
                        fontWeight={500}
                        color={blackShade05}
                      >
                        Days
                      </Typography>
                    </Box>
                  </Grid> */}
                </Grid>
              </Grid>

              <Grid item xs={12} md={3}>
                <RadioOptionWithLabel
                  label="Seller Pickup"
                  required
                  value={
                    data?.ondc?.returnTerms?.seller_pickup_return === "yes"
                      ? "Yes"
                      : data?.ondc?.returnTerms?.seller_pickup_return === "no"
                      ? "No"
                      : ""
                  }
                  onYesClick={() => {
                    handleOndcInfoChange(
                      "seller_pickup_return",
                      "yes",
                      "returnTerms"
                    );
                  }}
                  onNoClick={() => {
                    handleOndcInfoChange(
                      "seller_pickup_return",
                      "no",
                      "returnTerms"
                    );
                  }}
                  error={data?.errors?.seller_pickup_return}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <Typography component="h6" sx={styles.sectionHeader}>
              Cancel Terms
            </Typography>

            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} md={3}>
                <RadioOptionWithLabel
                  label="Cancellable"
                  required
                  value={
                    data?.ondc?.cancelTerms?.cancellable === "yes"
                      ? "Yes"
                      : data?.ondc?.cancelTerms?.cancellable === "no"
                      ? "No"
                      : ""
                  }
                  onYesClick={() => {
                    handleOndcInfoChange("cancellable", "yes", "cancelTerms");
                  }}
                  onNoClick={() => {
                    handleOndcInfoChange("cancellable", "no", "cancelTerms");
                  }}
                  error={data?.errors?.cancellable}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Grid container rowSpacing={2} columnSpacing={3}>
                  {data?.ondc?.cancelTerms?.cancellable === "yes" && (
                    <>
                      <Grid item xs={12} md={2}>
                        <RadioOptionWithLabel
                          label="Refund Eligible"
                          required
                          value={
                            data?.ondc?.cancelTerms?.refundEligible === "yes"
                              ? "Yes"
                              : data?.ondc?.cancelTerms?.refundEligible === "no"
                              ? "No"
                              : ""
                          }
                          onYesClick={() => {
                            handleOndcInfoChange(
                              "refundEligible",
                              "yes",
                              "cancelTerms"
                            );
                          }}
                          onNoClick={() => {
                            handleOndcInfoChange(
                              "refundEligible",
                              "no",
                              "cancelTerms"
                            );
                          }}
                          error={data?.errors?.refundEligible}
                        />
                      </Grid>

                      {/* <Grid item xs={12} md={3}>
                        <FormInputBox
                          label="Cancellation Fee Amount"
                          placeholder=""
                          required
                          startIcon="₹"
                          value={data?.ondc?.cancelTerms?.cancellationFeeAmount}
                          onChange={({ target }) => {
                            const value = target?.value?.replace(/[^0-9]/g, "");
                            handleOndcInfoChange(
                              "cancellationFeeAmount",
                              value,
                              "cancelTerms"
                            );
                          }}
                          error={data?.errors?.cancellationFeeAmount}
                        />
                      </Grid> */}

                      <Grid item xs={12} md={3}>
                        <FormInputBox
                          label="Cancellation Fee %"
                          placeholder=""
                          required
                          value={
                            data?.ondc?.cancelTerms?.cancellationFeePercentage
                          }
                          onChange={({ target }) => {
                            const value = target?.value?.replace(/[^0-9]/g, "");
                            if (value > 100) return;
                            handleOndcInfoChange(
                              "cancellationFeePercentage",
                              value,
                              "cancelTerms"
                            );
                          }}
                          error={data?.errors?.cancellationFeePercentage}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <Typography component="h6" sx={styles.sectionHeader}>
              Food & Related Items
            </Typography>

            <Grid container rowSpacing={2} columnSpacing={6}>
              <Grid item xs={12} md={12}>
                <RadioOptionWithLabel
                  label="Select 'Yes' if your products all under Food, Baverages & any other related categories"
                  value={
                    ondcUIControl?.isFoodRelatedItems === "none"
                      ? ""
                      : ondcUIControl?.isFoodRelatedItems === "yes"
                      ? "Yes"
                      : "No"
                  }
                  onYesClick={() => {
                    setOndcUIControl((prevState) => ({
                      ...prevState,
                      isFoodRelatedItems: "yes",
                    }));
                  }}
                  onNoClick={() => {
                    setOndcUIControl((prevState) => ({
                      ...prevState,
                      isFoodRelatedItems: "no",
                    }));
                    clearFoodItemsInfo();
                  }}
                  error={data?.errors?.isFoodRelatedItems}
                />
              </Grid>

              {ondcUIControl?.isFoodRelatedItems === "yes" && (
                <>
                  <Grid item xs={12} md={12}>
                    <Grid container rowSpacing={2} columnSpacing={3}>
                      <Grid item xs={8} md={3}>
                        <FormInputBox
                          label="Shelf Life (In Days) "
                          placeholder=""
                          required
                          value={data?.ondc?.shelfLife}
                          onChange={({ target }) => {
                            const value = target?.value?.replace(/[^0-9]/g, "");
                            handleOndcInfoChange("shelfLife", value);
                          }}
                          error={data?.errors?.shelfLife}
                        />
                      </Grid>

                      {/* <Grid item xs={4} md={4}>
                        <Box sx={styles.infoTextBox}>
                          <Typography
                            component="p"
                            fontSize={13}
                            fontWeight={500}
                            color={blackShade05}
                          >
                            Days
                          </Typography>
                        </Box>
                      </Grid> */}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Grid container rowSpacing={2} columnSpacing={3}>
                      <Grid item xs={6} md={3}>
                        <FormInputBox
                          label="FSSAI License Number"
                          placeholder=""
                          required
                          value={data?.ondc?.fssai?.[0]?.number}
                          onChange={({ target }) => {
                            const value = target?.value?.replace(/[^0-9]/g, "");
                            setData((prevState) => ({
                              ...prevState,
                              ondc: {
                                ...prevState?.ondc,
                                fssai: [
                                  {
                                    ...prevState?.ondc?.fssai?.[0],
                                    number: value,
                                  },
                                ],
                              },
                              errors: {
                                ...prevState?.errors,
                                fssai: "",
                              },
                            }));
                          }}
                          maxLength={14}
                          error={data?.errors?.fssai}
                        />
                      </Grid>

                      {/* <Grid item xs={6} md={2.4}>
                        <DateInput
                          label="Valid Till"
                          date={
                            data?.ondc?.fssai?.[0]?.validTill
                              ? moment(
                                  data?.ondc?.fssai?.[0]?.validTill
                                ).format("yyyy-MM-DD")
                              : ""
                          }
                          onDateChange={({ target }) => {
                            // onValueChange("endDate", moment(target.value).valueOf());
                            setData((prevState) => ({
                              ...prevState,
                              ondc: {
                                ...prevState?.ondc,
                                fssai: [
                                  {
                                    ...prevState?.ondc?.fssai?.[0],
                                    validTill: moment(target.value).valueOf(),
                                  },
                                ],
                              },
                              errors: {
                                ...prevState?.errors,
                                validTill: "",
                              },
                            }));
                          }}
                          error={data?.errors?.validTill}
                        />
                      </Grid> */}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Grid container rowSpacing={2} columnSpacing={3}>
                      <Grid item xs={12} md={12}>
                        <Typography
                          component="p"
                          fontSize={13}
                          fontWeight={400}
                          color={greyColor6}
                          mb={{ xs: 1.5, md: 1 }}
                        >
                          Veg/Nonveg/Egg
                        </Typography>

                        <Box>
                          <RadiosBtnWithLabel
                            label="Veg"
                            checked={data?.ondc?.veg_nonveg?.veg === "yes"}
                            onValuesChanged={() => {
                              handleVegNonVegInput("veg", "yes");
                            }}
                            labelStyles={styles.radioLabel}
                          />

                          <RadiosBtnWithLabel
                            label="Non Veg"
                            checked={data?.ondc?.veg_nonveg?.nonVeg === "yes"}
                            onValuesChanged={() => {
                              handleVegNonVegInput("nonVeg", "yes");
                            }}
                            labelStyles={styles.radioLabel}
                          />

                          <RadiosBtnWithLabel
                            label="Egg"
                            checked={data?.ondc?.veg_nonveg?.egg === "yes"}
                            onValuesChanged={() => {
                              handleVegNonVegInput("egg", "yes");
                            }}
                            labelStyles={styles.radioLabel}
                          />

                          {/* <RadiosBtnWithLabel
                            label="Veg/Egg"
                            checked={data?.ondc?.veg_nonveg?.vegEgg === "yes"}
                            onValuesChanged={() => {
                              handleVegNonVegInput("vegEgg", "yes");
                            }}
                            labelStyles={styles.radioLabel}
                          /> */}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>

          <Box mt={2.5}>
            <Typography
              component="h6"
              sx={{ ...styles.sectionHeader, mb: "8px" }}
            >
              Packaged Commodities
            </Typography>

            <RadioOptionWithLabel
              value={
                ondcUIControl?.isPackaged === "none"
                  ? ""
                  : ondcUIControl?.isPackaged === "yes"
                  ? "Yes"
                  : "No"
              }
              onYesClick={() => {
                setOndcUIControl((prevState) => ({
                  ...prevState,
                  isPackaged: "yes",
                }));
              }}
              onNoClick={() => {
                setOndcUIControl((prevState) => ({
                  ...prevState,
                  isPackaged: "no",
                }));
                clearPackageInfo();
              }}
              error={data?.errors?.isPackaged}
            />

            {ondcUIControl?.isPackaged === "yes" && (
              <>
                <Typography
                  fontSize={{ xs: 12, md: 13 }}
                  fontWeight={500}
                  color={blackShade05}
                  lineHeight="18px"
                  m={{ xs: "5px 0 15px", md: "12px 0 16px" }}
                >
                  Below detail are the statutory requirements for packaged
                  commodities
                </Typography>

                <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={12} md={3}>
                    <FormInputBox
                      label="Manufacturer/Packer Name"
                      placeholder=""
                      required
                      value={
                        data?.ondc?.statutoryReqsPackagedCommodities
                          ?.manufacturer_or_packer_name
                      }
                      onChange={({ target }) => {
                        handleOndcInfoChange(
                          "manufacturer_or_packer_name",
                          target.value,
                          "statutoryReqsPackagedCommodities"
                        );
                      }}
                      error={data?.errors?.manufacturer_or_packer_name}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormInputBox
                      label="License No"
                      placeholder=""
                      required
                      value={
                        data?.ondc?.statutoryReqsPackagedCommodities
                          ?.mfg_license_no
                      }
                      onChange={({ target }) => {
                        handleOndcInfoChange(
                          "mfg_license_no",
                          target.value,
                          "statutoryReqsPackagedCommodities"
                        );
                      }}
                      error={data?.errors?.mfg_license_no}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormInputBox
                      label="Manufacturer/Packer Address"
                      placeholder=""
                      required
                      value={
                        data?.ondc?.statutoryReqsPackagedCommodities
                          ?.manufacturer_or_packer_address
                      }
                      onChange={({ target }) => {
                        handleOndcInfoChange(
                          "manufacturer_or_packer_address",
                          target.value,
                          "statutoryReqsPackagedCommodities"
                        );
                      }}
                      error={data?.errors?.manufacturer_or_packer_address}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Grid container rowSpacing={2} columnSpacing={3}>
                      <Grid item xs={12} md={3}>
                        <FormInputBox
                          label="Product Common/Generic Name"
                          placeholder=""
                          required
                          value={
                            data?.ondc?.statutoryReqsPackagedCommodities
                              ?.common_or_generic_name_of_commodity
                          }
                          onChange={({ target }) => {
                            handleOndcInfoChange(
                              "common_or_generic_name_of_commodity",
                              target.value,
                              "statutoryReqsPackagedCommodities"
                            );
                          }}
                          error={
                            data?.errors?.common_or_generic_name_of_commodity
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={3.5}>
                        <FormInputBox
                          label="Manufacture/Import Date (MM/YYYY or N/A)"
                          value={
                            data?.ondc?.statutoryReqsPackagedCommodities
                              ?.month_year_of_manufacture_packing_import || ""
                          }
                          onChange={({ target }) => {
                            // onValueChange("endDate", moment(target.value).valueOf());
                            handleOndcInfoChange(
                              "month_year_of_manufacture_packing_import",
                              target.value,
                              "statutoryReqsPackagedCommodities"
                            );
                          }}
                          error={
                            data?.errors
                              ?.month_year_of_manufacture_packing_import
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={3.5}>
                        <FormInputBox
                          label="Expiry Date (MM/YYYY or N/A)"
                          value={
                            data?.ondc?.statutoryReqsPackagedCommodities
                              ?.expiry_date || ""
                          }
                          onChange={({ target }) => {
                            console.log(target?.value);
                            // onValueChange("endDate", moment(target.value).valueOf());
                            handleOndcInfoChange(
                              "expiry_date",
                              target.value,
                              "statutoryReqsPackagedCommodities"
                            );
                          }}
                          error={data?.errors?.expiry_date}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Grid container rowSpacing={2} columnSpacing={3}>
                      <Grid item xs={12} md={3}>
                        <FormInputBox
                          label="Net Quantity"
                          placeholder=""
                          required
                          value={
                            data?.ondc?.statutoryReqsPackagedCommodities
                              ?.net_quantity_or_measure_of_commodity_in_pkg
                          }
                          onChange={({ target }) => {
                            handleOndcInfoChange(
                              "net_quantity_or_measure_of_commodity_in_pkg",
                              target.value,
                              "statutoryReqsPackagedCommodities"
                            );
                          }}
                          error={
                            data?.errors
                              ?.net_quantity_or_measure_of_commodity_in_pkg
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <ONDCMultiValueInput
                          label="Multiple Products - Name, Number/QTY"
                          required
                          disabled
                          value={
                            data?.ondc?.statutoryReqsPackagedCommodities?.multiple_products_name_number_or_qty?.split(
                              ","
                            ) || []
                          }
                          setLabelValue={(labelValues) => {
                            // console.log("ONDC_INFO__labelValues___", labelValues);
                            handleOndcInfoChange(
                              "multiple_products_name_number_or_qty",
                              labelValues?.join(","),
                              "statutoryReqsPackagedCommodities"
                            );
                          }}
                          error={
                            data?.errors?.multiple_products_name_number_or_qty
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>

          <Box mt={2.5}>
            <Typography
              component="h6"
              sx={{ ...styles.sectionHeader, mb: "8px" }}
            >
              Pre Packaged Food
            </Typography>

            <RadioOptionWithLabel
              value={
                ondcUIControl?.isPrePackaged === "none"
                  ? ""
                  : ondcUIControl?.isPrePackaged === "yes"
                  ? "Yes"
                  : "No"
              }
              onYesClick={() => {
                setOndcUIControl((prevState) => ({
                  ...prevState,
                  isPrePackaged: "yes",
                }));
              }}
              onNoClick={() => {
                setOndcUIControl((prevState) => ({
                  ...prevState,
                  isPrePackaged: "no",
                }));
                clearPrePackageInfo();
              }}
              error={data?.errors?.isPrePackaged}
            />

            {ondcUIControl?.isPrePackaged === "yes" && (
              <>
                <Typography
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade05}
                  m="12px 0 16px"
                >
                  Below detail are the statutory requirements for packaged Food
                  products
                </Typography>

                <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={12} md={3}>
                    <FormInputBox
                      label="Brand Owner FSSAI License No"
                      placeholder=""
                      required
                      value={
                        data?.ondc?.statutoryReqsPrepackagedFood
                          ?.brand_owner_FSSAI_license_no
                      }
                      onChange={({ target }) => {
                        const value = target?.value?.replace(/[^0-9]/g, "");

                        handleOndcInfoChange(
                          "brand_owner_FSSAI_license_no",
                          value,
                          "statutoryReqsPrepackagedFood"
                        );
                      }}
                      maxLength={14}
                      error={data?.errors?.brand_owner_FSSAI_license_no}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormInputBox
                      label="Other FSSAI License No"
                      placeholder=""
                      required
                      value={
                        data?.ondc?.statutoryReqsPrepackagedFood
                          ?.other_FSSAI_license_no
                      }
                      onChange={({ target }) => {
                        const value = target?.value?.replace(/[^0-9]/g, "");

                        handleOndcInfoChange(
                          "other_FSSAI_license_no",
                          value,
                          "statutoryReqsPrepackagedFood"
                        );
                      }}
                      maxLength={14}
                      error={data?.errors?.other_FSSAI_license_no}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormInputBox
                      label="Importer FSSAI License No"
                      placeholder=""
                      required
                      value={
                        data?.ondc?.statutoryReqsPrepackagedFood
                          ?.importer_FSSAI_license_no
                      }
                      onChange={({ target }) => {
                        const value = target?.value?.replace(/[^0-9]/g, "");

                        handleOndcInfoChange(
                          "importer_FSSAI_license_no",
                          value,
                          "statutoryReqsPrepackagedFood"
                        );
                      }}
                      maxLength={14}
                      error={data?.errors?.importer_FSSAI_license_no}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Grid container rowSpacing={2} columnSpacing={3}>
                      <Grid item xs={12} md={6.1}>
                        <ONDCMultiValueInput
                          label="Ingredients Info"
                          required
                          disabled
                          value={
                            data?.ondc?.statutoryReqsPrepackagedFood?.ingredients_info?.split(
                              ","
                            ) || []
                          }
                          setLabelValue={(labelValues) => {
                            // console.log("ONDC_INFO__labelValues___", labelValues);
                            handleOndcInfoChange(
                              "ingredients_info",
                              labelValues?.join(","),
                              "statutoryReqsPrepackagedFood"
                            );
                          }}
                          error={data?.errors?.ingredients_info}
                        />
                      </Grid>

                      <Grid item xs={12} md={6.1}>
                        <ONDCMultiValueInput
                          label="Nutritional Info"
                          required
                          disabled
                          value={
                            data?.ondc?.statutoryReqsPrepackagedFood?.nutritional_info?.split(
                              ","
                            ) || []
                          }
                          setLabelValue={(labelValues) => {
                            // console.log("ONDC_INFO__labelValues___", labelValues);
                            handleOndcInfoChange(
                              "nutritional_info",
                              labelValues?.join(","),
                              "statutoryReqsPrepackagedFood"
                            );
                          }}
                          error={data?.errors?.nutritional_info}
                        />
                      </Grid>

                      <Grid item xs={12} md={6.1}>
                        <ONDCMultiValueInput
                          label="Additives Info"
                          required
                          disabled
                          value={
                            data?.ondc?.statutoryReqsPrepackagedFood?.additives_info?.split(
                              ","
                            ) || []
                          }
                          setLabelValue={(labelValues) => {
                            // console.log("ONDC_INFO__labelValues___", labelValues);
                            handleOndcInfoChange(
                              "additives_info",
                              labelValues?.join(","),
                              "statutoryReqsPrepackagedFood"
                            );
                          }}
                          error={data?.errors?.additives_info}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </>
      )}

      <Box mt="20px">
        <ActionButtons
          onBackClick={goBack}
          onSaveClick={() => {}}
          onNextClick={gotoNext}
          onCancelClick={onCancelClick}
          hideSaveBtn
          nextBtnText="PREVIEW & SUBMIT"
          backBtnText="Previous"
        />
      </Box>
    </Box>
  );
}

export default ONDCInfoForm;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    p: "20px",
    m: { xs: "15px 0 25px", md: "0px 0 25px" },
  },

  sectionHeader: {
    fontSize: { xs: 15, md: 15 },
    color: blackColor,
    fontWeight: 600,
    lineHeight: "20px",
    mb: 2,
  },

  infoTextBox: {
    maxWidth: "70px",
    padding: "32px 0 8px 0",
    borderBottom: `1px solid ${greyColor11}`,
  },

  radioLabel: {
    color: greyColor6,
    fontSize: 13,
  },
};
