import React from "react";
import { Box } from "@mui/material";
import CategoryPreview from "./CategoryPreview";
import OndcInfoPreview from "../../AddProductNew/DataPreview/OndcInfoPreview";
import CatalogActionBtns from "../../../components/Buttons/CatalogActionBtns";

function OndcPreview({
  categoryData,
  ondcData,
  ondcSpecificationData,
  onGoBack,
  handleCancel,
  onSubmit,
}) {
  return (
    <Box>
      <OndcInfoPreview
        ondcEnabled={categoryData?.ondcEnabled?.toLowerCase() == "yes"}
        timeToShip={ondcData?.timeToShip}
        timeUnit={ondcData?.timeToShipUnit?.label}
        cod={ondcData?.available_on_cod ? "Yes" : "No"}
        fragile={ondcData?.fragile ? "Yes" : "No"}
        returnable={ondcData?.returnTerms?.returnable}
        returnPeriod={ondcData?.returnTerms?.returnPeriod}
        sellerPickup={ondcData?.returnTerms?.seller_pickup_return}
        cancellable={ondcData?.cancelTerms?.cancellable}
        refundEligible={ondcData?.cancelTerms?.refundEligible}
        cancellationFee={ondcData?.cancelTerms?.cancellationFeePercentage}
        productCode={ondcData?.productCode?.key}
        productCodeValue={ondcData?.productCode?.value}
        unitOfMeasure={ondcData?.unitOfMeasure?.unit}
        unitOfMeasureValue={ondcData?.unitOfMeasure?.value}
        dynamicSpecs={ondcSpecificationData}
      />

      <Box my="20px">
        <CatalogActionBtns
          backBtnText="Previous"
          onBackClick={onGoBack}
          showBackBtn
          onCancelClick={handleCancel}
          nextBtnText={"SUBMIT"}
          onNextClick={onSubmit}
          hideSaveBtn
        />
      </Box>
    </Box>
  );
}

export default OndcPreview;
