import moment from "moment";
import { capitalizeFirstCharacter } from "../../utils/helper";

const BUSINESS_TIMINGS = [
  { dayOfWeek: 1, day: "Monday", isClosed: false, from: "", to: "" },
  { dayOfWeek: 2, day: "Tuesday", isClosed: false, from: "", to: "" },
  { dayOfWeek: 3, day: "Wednesday", isClosed: false, from: "", to: "" },
  { dayOfWeek: 4, day: "Thursday", isClosed: false, from: "", to: "" },
  { dayOfWeek: 5, day: "Friday", isClosed: false, from: "", to: "" },
  { dayOfWeek: 6, day: "Saturday", isClosed: false, from: "", to: "" },
  { dayOfWeek: 7, day: "Sunday", isClosed: false, from: "", to: "" },
];

//Get Day of Week by number
const getDayOfWeekByNum = (day) => {
  switch (day) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    case 7:
      return "Sunday";

    default:
      return "";
  }
};

// Check of all time range are equal
function areAllTimeRangesEqual(timeArray) {
  if (timeArray.length === 0) {
    return true;
  }

  const firstTimeRange = timeArray[0];

  for (let i = 1; i < timeArray.length; i++) {
    const currentTimeRange = timeArray[i];

    if (
      currentTimeRange.from !== firstTimeRange.from ||
      currentTimeRange.to !== firstTimeRange.to
    ) {
      return false;
    }
  }

  return true;
}

// Check if all from and to equal to 12:00 AM and 11:59PM
function areAllTimeRangesMidnight(timeArray) {
  const midnightFrom = "12:00 AM";
  const midnightTo = "11:59 PM";

  for (let i = 0; i < timeArray.length; i++) {
    const currentTimeRange = timeArray[i];

    if (
      currentTimeRange.from !== midnightFrom ||
      currentTimeRange.to !== midnightTo
    ) {
      return false;
    }
  }

  return true;
}

export const prepareBusinessHoursData = (businessHours) => {
  const openHoursArr = businessHours || [];

  let businessTime = "";
  const newOpenHourArr = [];

  if (openHoursArr?.length > 0) {
    // Check for open 24 Hours
    const isOpen24 = areAllTimeRangesMidnight(openHoursArr);

    if (isOpen24) {
      businessTime = "open-24-Hrs";

      const timingData = {
        businessTime,
        businessHours: BUSINESS_TIMINGS,
        allDays: { from: "", to: "" },
      };

      console.log("timingData:", timingData);
      return timingData;
    }

    // Check for all days
    const isAllDay = areAllTimeRangesEqual(openHoursArr);

    if (isAllDay) {
      businessTime = "all-days";

      const timingData = {
        businessTime,
        businessHours: BUSINESS_TIMINGS,
        allDays: {
          from: moment(openHoursArr?.[0]?.from, "hh:mm A").format("HH:mm"),
          to: moment(openHoursArr?.[0]?.to, "hh:mm A").format("HH:mm"),
        },
      };

      console.log("timingData:", timingData);
      return timingData;
    }

    // else select specific days
    businessTime = "select-specific-days";

    BUSINESS_TIMINGS.forEach((item) => {
      const dayOfWeek = businessHours.filter(
        (dayTiming) => dayTiming.dayOfWeek === item.dayOfWeek
      );

      if (dayOfWeek?.length > 0) {
        newOpenHourArr.push({
          ...item,
          isClosed: true,
          from: moment(dayOfWeek?.[0]?.from, "hh:mm A").format("HH:mm"),
          to: moment(dayOfWeek?.[0]?.to, "hh:mm A").format("HH:mm"),
        });
      } else {
        newOpenHourArr.push(item);
      }
    });

    const timingData = {
      businessTime,
      businessHours: newOpenHourArr,
      allDays: { from: "", to: "" },
    };

    console.log("timingData:", timingData);
    return timingData;
  }
};

export const prepareDeliveryMethodData = (commerceInfo) => {
  const data = commerceInfo?.info || null;
  const deliveryTypeInfo = data?.deliveryTypeInfo || [];

  let deliveryMethods = [];

  let costBoDelivery = {
    from: "",
    to: "",
    type: "delhivery",
    unit: "km",
    deliveryTime: ["COSTBO SURFACE"],
  };

  let isCOD = false;

  let cod = {
    type: "delhivery",
    limit: "",
    notes: `Maximum allowed Rs  otherwise pay by card/wallet`,
    codFlatCharge: 45.0,
    codPercent: 3.0,
    settlementDays: 7,
    chargeTo: "Select Cash on delivery fee charged to",
  };

  // Checking for CostBo Delivery
  const delhiveryInfo = deliveryTypeInfo?.filter(
    (item) => item?.type == "delhivery"
  );

  if (delhiveryInfo?.length > 0) {
    const costBoDeliveryInfo = delhiveryInfo?.[0];

    deliveryMethods.push("CostBo Delivery");

    costBoDelivery = {
      ...costBoDelivery,
      from: costBoDeliveryInfo?.from?.toString(),
      to: costBoDeliveryInfo?.to,
    };

    const isCod = data?.codInfo?.filter((item) => item?.type == "delhivery");

    if (isCod?.length > 0) {
      const codInfo = isCod?.[0] || null;

      isCOD = true;

      cod = {
        ...cod,
        limit: codInfo?.limit,
        chargeTo:
          capitalizeFirstCharacter(codInfo?.chargeTo) ||
          "Select Cash on delivery fee charged to",
      };
    }
  }

  // Checking for CostBo HyperLocal
  let costBoHyperlocal = {
    from: "",
    to: "",
    type: "telyport",
    unit: "km",
    deliveryTime: [],
  };

  const hyperLocalInfo = deliveryTypeInfo?.filter(
    (item) => item?.type == "telyport"
  );

  if (hyperLocalInfo?.length > 0) {
    const costBoHyperlocalInfo = hyperLocalInfo?.[0];

    deliveryMethods.push("CostBo Hyperlocal");

    costBoHyperlocal = {
      ...costBoHyperlocal,
      from: costBoHyperlocalInfo?.from?.toString(),
      to: costBoHyperlocalInfo?.to,
    };
  }

  // Checking for Fulfillment by Business
  let fullfillment = {
    from: "",
    to: "",
    type: "business",
    unit: "km",
    deliveryTime: [],
  };

  let fullfillmentPricing = [];

  const fullfillmentInfo = deliveryTypeInfo?.filter(
    (item) => item?.type == "business"
  );

  if (fullfillmentInfo?.length > 0) {
    const fullfillmentData = fullfillmentInfo?.[0];

    deliveryMethods.push("Fulfillment by Business");

    fullfillment = {
      ...fullfillment,
      from: fullfillmentData?.from?.toString(),
      to: fullfillmentData?.to,
    };

    fullfillmentPricing =
      data?.shipmentCost?.length > 0
        ? data?.shipmentCost?.map((item) => ({
            ...item,
            byFor: item?.byFor?.toString(),
            txnAmount: item?.txnAmount?.toString(),
            txnAmount2: item?.txnAmount2?.toString(),
          }))
        : [];
  }

  // Checking for B2B Delivery
  let b2bDelivery = {
    from: "",
    to: "",
    type: "delhiveryb2b",
    unit: "km",
    deliveryTime: ["COSTBO B2B"],
  };

  const b2bDeliveryInfo = deliveryTypeInfo?.filter(
    (item) => item?.type == "delhiveryb2b"
  );

  if (b2bDeliveryInfo?.length > 0) {
    const b2bData = b2bDeliveryInfo?.[0];

    deliveryMethods.push("B2B Delivery");

    b2bDelivery = {
      ...b2bDelivery,
      from: b2bData?.from?.toString(),
      to: b2bData?.to,
    };
  }

  // Cheching for Store pickup
  const storePickupInfo = data?.pickUpInfoDetails?.[0] || null;

  if (storePickupInfo) {
    deliveryMethods.push("Store Pickup");
  }

  let discountBy = "Select Discount Type";

  if (
    storePickupInfo?.discountAmount == 0 &&
    storePickupInfo?.discountPercentage == 0
  ) {
    discountBy = "No Discount";
  } else {
    discountBy = storePickupInfo?.discountBy || "Select Discount Type";
  }

  const storePickup = {
    discountBy,
    discountAmount:
      storePickupInfo?.discountAmount !== ""
        ? String(storePickupInfo?.discountAmount)
        : "",
    discountPercentage:
      storePickupInfo?.discountPercentage !== ""
        ? String(storePickupInfo?.discountPercentage)
        : "",
  };

  const deliveryData = {
    deliveryMethods,
    costBoDelivery,
    isCOD,
    cod,
    // teleport
    costBoHyperlocal,
    // business full fillment
    fullfillment,
    fullfillmentPricing,
    // B2B
    b2bDelivery,
    // Store pickup
    storePickup,
  };

  console.log("auto____prepareDeliveryMethodData:", deliveryData);
  return deliveryData;
};

export const prepareDiscountData = (commerceInfo) => {
  const commerceData = commerceInfo?.info || null;

  let discountInfo = {
    immediateDiscount: commerceData?.immediateDiscount || "",
    rewardPointPercent: commerceData?.rewardPointPercent
      ? {
          label: `${commerceData?.rewardPointPercent}%`,
          value: commerceData?.rewardPointPercent,
        }
      : { label: "Select Cashback Percentage" },
    referralPointsMaxPerTxn: commerceData?.referralPointsMaxPerTxn || "",
    rewardExpirationDays: commerceData?.rewardExpirationDays
      ? {
          label: `${commerceData?.rewardExpirationDays} days`,
          value: commerceData?.rewardExpirationDays,
        }
      : { label: "Select Cashback Validity" },
    additionalDiscount:
      commerceData?.additionalDiscount?.map((item) => ({
        ...item,
        discountPercentage: item?.discountPercentage?.toString(),
        distanceEnd: item?.distanceEnd?.toString(),
        distanceStart: item?.distanceStart?.toString(),
      })) || [],
  };

  console.log("auto____prepareDiscountDate:", discountInfo);
  return discountInfo;
};
