import { Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import {
  borderTextbox,
  greyShade7,
  errorTextColor,
  blackColor,
  authInputBorder,
} from "../configs/styles/muiThemes";

function NumberInput({
  value,
  onChange,
  icon,
  isError,
  errorMsg,
  helperText,
  showAutoComplete,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: authInputBorder,
          borderRadius: "6px",
          p: { md: "7px 5px 7px 15px", xs: "5px 5px 5px 5px" },
        }}
      >
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={{ md: 14, xs: 12 }}
          fontWeight={500}
          color={blackColor}
        >
          +91 -
        </Typography>

        <input
          type="text"
          name="number"
          value={value}
          pattern="[0-9]+"
          onChange={onChange}
          maxLength={10}
          placeholder=""
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          style={{
            border: "none",
            padding: "0 10px",
            flex: "1",
            fontSize: matches ? "14px" : "12.5px",
            fontWeight: 500,
            fontFamily: "Poppins",
            margin: "0 5px 0 0",
            letterSpacing: "1px",
            width: "100px",
          }}
          autocomplete={showAutoComplete ? "on" : "off"}
        />

        <Box m={{ md: "0 5px -10px 0", xs: "0 0 -6px 0" }}>{icon}</Box>
      </Box>

      {isError ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={13}
          color={errorTextColor}
        >
          {errorMsg}
        </Typography>
      ) : null}

      {helperText ? (
        <Typography
          variant="body1"
          component="p"
          fontSize={11}
          color={greyShade7}
        >
          {helperText}
        </Typography>
      ) : null}
    </Box>
  );
}

export default NumberInput;
