import { Box, Button, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blueColor02,
  blueColor04,
  greyColor24,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";

function PersonalizeEmptyCard({ onClick }) {
  return (
    <Box sx={styles.card}>
      <Box sx={styles.iconContainer}>
        <CreditCardOutlinedIcon sx={{ fontSize: "24px" }} />
      </Box>

      <Typography
        color={blackShade05}
        fontSize={13}
        fontWeight={500}
        lineHeight={1.6}
        sx={{ whiteSpace: "pre-line" }}
      >
        {`Boost sales through `}
        <Typography
          fontSize={13}
          fontWeight={500}
          sx={{
            color: { xs: mobilePrimary, md: blueColor04 },
            display: "inline",
            whiteSpace: "pre-line",
          }}
        >
          {`Personalized \nDiscounts`}
        </Typography>
        {` for customers(by phone \nnumber)`}
      </Typography>

      <Button
        variant="contained"
        disableElevation
        onClick={onClick}
        sx={styles.actionBtn}
      >
        Manage Discounts
      </Button>
    </Box>
  );
}

export default PersonalizeEmptyCard;

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: whiteColor,
    marginBottom: "12px",
    borderRadius: "10px",
    padding: "20px 0 0 0",
    textAlign: "center",
  },
  iconContainer: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    background: "rgb(5 60 81 / 7%)",
    padding: "12px",
    mb: "10px",
  },

  actionBtn: {
    fontSize: "13px",
    fontWeight: "600",
    color: { xs: mobilePrimary, md: blueColor04 },
    backgroundColor: whiteColor,
    padding: "3px 22px",
    mt: "12px",
    border: {
      xs: `1px solid ${mobilePrimary}`,
      md: `1px solid ${blueColor04}`,
    },
    "&:hover": {
      color: whiteColor,
      backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    },
  },
};
