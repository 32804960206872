import moment from "moment";
import { LATITUDE, LONGITUDE } from "../../configs/Constants";
import { getProfileId } from "../validationUtils";
import { getListToLabel, getSelectedCountryByCode } from "../helper";

export const prepareAddProductPhotoData = (data) => {
  const randomTypeImage = Math.floor(100000 + Math.random() * 900000);
  const type = `Product${data?.imageIndex}-${randomTypeImage}`;

  const postData = {
    id: data?.productId,
    businessId: data?.businessId,
    productPhoto: [
      {
        name: data?.filename,
        type,
        mimeType: data?.mimeType,
        extension: "jpeg",
        replicate: false,
        bytesString: data?.dataURL,
      },
    ],
  };

  return postData;
};

export const prepareAddNewProductPhotoData = (photoList) => {
  const addPhotoList = [];

  photoList.forEach((element) => {
    if (element?.dataURL) {
      const randomTypeImage = Math.floor(100000 + Math.random() * 900000);
      const type = `Product${element?.index}-${randomTypeImage}`;

      const photoData = {
        name: element?.fileDetails?.name,
        type,
        mimeType: "image/jpeg",
        extension: "jpeg",
        replicate: false,
        bytesString: element?.dataURL,
      };

      addPhotoList.push(photoData);
    }
  });

  return addPhotoList;
};

export const getUpdateProductPostData = (data) => {
  const profileId = getProfileId();
  const businessId = data?.businessId;
  const businessInfo = data?.businessInfo;

  const productDetails = data?.productDetails;

  const productInfo = data?.productInfo;
  const categoryInfo = data?.categoryInfo;
  const priceInfo = data?.priceInfo;
  const measurementInfo = data?.measurementInfo;
  const availabilityInfo = data?.availabilityInfo;
  const subscriptionInfo = data?.subscriptionInfo;

  const ondcInfo = data?.ondcInfo;
  const ondcUIControl = data?.ondcUIControl;

  // const lat = localStorage.getItem("lat") || LATITUDE;
  // const long = localStorage.getItem("long") || LONGITUDE;

  const lat = businessInfo?.loc?.y || "";
  const long = businessInfo?.loc?.x || "";

  const subscriptionData = {
    status: "active",
    subStatus: "ready",
    details: "",
    subscription: subscriptionInfo?.isProductSubscribed,
    offerValue: subscriptionInfo?.offerValue,
    originalValue: subscriptionInfo?.originalValue,
    cancelTime: subscriptionInfo?.cancelTime,
    serviceFee: subscriptionInfo?.serviceFee,
    specialFee: subscriptionInfo?.specialFee,
    packingFee: subscriptionInfo?.packingFee,
    type: subscriptionInfo?.subType,
    notes: null,
    cancelDays: subscriptionInfo?.cancelDays,
    endDate: subscriptionInfo?.endDate,
    startDate: subscriptionInfo?.startDate,
  };

  const specsList = categoryInfo?.productSpecs?.filter(
    (item) => item?.code !== "" && item?.value?.[0] !== ""
  );

  const refLinkList = categoryInfo?.refLinks?.filter(
    (item) => item?.code !== "" && item?.link !== ""
  );

  const postData = {
    profileId: profileId,
    businessId: businessId,
    productName: productInfo?.productName,
    productDescription: productInfo?.description,
    catalogType: "Product",
    // label: categoryInfo?.productSpecs || [],
    label: specsList || [],
    searchText: productDetails?.searchText,
    brand: productInfo?.brand || "",
    skuId: productInfo?.skuId || null,
    // refLink: categoryInfo?.refLinks || [],
    refLink: refLinkList || [],
    productPhoto: [],
    latitude: lat,
    longitude: long,
    status: productDetails?.status,
    commerceEnabled: "YES",
    hyperLocal: "Y",
    origin: productInfo?.country?.name || "",
    productMainCategory: [{ ...categoryInfo?.selectedBusinessCat }],
    productSubCategory: categoryInfo?.selectedProductSubCat1
      ? [{ ...categoryInfo?.selectedProductSubCat1 }]
      : [],
    productSubCategory2: categoryInfo?.selectedProductSubCat2
      ? [{ ...categoryInfo?.selectedProductSubCat2 }]
      : [],
    // commerceStartDate: availabilityInfo?.startDate,
    // commerceEndDate: availabilityInfo?.endDate,

    commerceStartDate: moment(availabilityInfo?.startDate)
      .startOf("day")
      .toISOString(),
    commerceEndDate: moment(availabilityInfo?.endDate)
      .endOf("day")
      .toISOString(),

    minOrderQuantity: availabilityInfo?.minQty,
    maxOrderQuantity: availabilityInfo?.maxQty,
    productSalePrice: priceInfo?.OfferPrice,
    productOriginalPrice: priceInfo?.originalPrice,
    subscription: subscriptionInfo?.isProductSubscribed
      ? subscriptionData
      : productDetails?.subscription,
    gstInfo: {
      code:
        `${ondcInfo?.ondc?.productCode?.key}:${ondcInfo?.ondc?.productCode?.value}` ||
        "",
      gstPercentage: priceInfo?.gstPercentage || "",
    },
    measurementInfo: {
      weight: measurementInfo?.weight || "0",
      length: measurementInfo?.length || "0",
      width: measurementInfo?.width || "0",
      height: measurementInfo?.height || "0",
    },
    taxDetails: [
      {
        code: "Inclusive of taxes",
        name: "Taxes Included",
        value: priceInfo?.taxDetails,
      },
    ],
    currencySymbol: "₹",
    unitOfMeasure: "Gram",
    availability: [`${availabilityInfo?.availability}`],
    // deliveryType: measurementInfo?.deliveryMethod,
    deliveryType: ["Delivery/Fulfillment by Business"],
    shipmentDistance: productDetails?.shipmentDistance,
    preference: productDetails?.preference,
    availableQuantity: availabilityInfo?.availabelQty,
    soldQuantity: productDetails?.soldQuantity,
    alertQuantity: availabilityInfo?.alertQty,
    clientType: "business-dashboard-web",
    productId: productDetails?.id,

    ondcInfo: {
      ...ondcInfo,
      ondcUIControl,
    },
  };

  return postData;
};

export const getAddNewProductPostData = (data) => {
  const profileId = getProfileId();
  const businessId = data?.businessId;
  const businessInfo = data?.businessInfo;

  const productInfo = data?.productInfo;
  const categoryInfo = data?.categoryInfo;
  const priceInfo = data?.priceInfo;
  const measurementInfo = data?.measurementInfo;
  const availabilityInfo = data?.availabilityInfo;
  const subscriptionInfo = data?.subscriptionInfo;

  const ondcInfo = data?.ondcInfo;
  const ondcUIControl = data?.ondcUIControl;

  // const lat = localStorage.getItem("lat") || LATITUDE;
  // const long = localStorage.getItem("long") || LONGITUDE;

  const lat = businessInfo?.loc?.y || "";
  const long = businessInfo?.loc?.x || "";

  const specsList = categoryInfo?.productSpecs?.filter(
    (item) => item?.code !== "" && item?.value?.[0] !== ""
  );

  const refLinkList = categoryInfo?.refLinks?.filter(
    (item) => item?.code !== "" && item?.link !== ""
  );

  const subscriptionData = {
    status: "active",
    subStatus: "ready",
    details: "",
    subscription: subscriptionInfo?.isProductSubscribed,
    offerValue: subscriptionInfo?.offerValue,
    originalValue: subscriptionInfo?.originalValue,
    cancelTime: subscriptionInfo?.cancelTime,
    serviceFee: subscriptionInfo?.serviceFee,
    specialFee: subscriptionInfo?.specialFee,
    packingFee: subscriptionInfo?.packingFee,
    type: subscriptionInfo?.subType,
    notes: null,
    cancelDays: subscriptionInfo?.cancelDays,
    endDate: subscriptionInfo?.endDate,
    startDate: subscriptionInfo?.startDate,
  };

  const ondcPost = {
    // ...ondcInfo,

    productDisplayUnitOfMeasure: ondcInfo?.productDisplayUnitOfMeasure,
    netProductWeight: measurementInfo?.weight || 0,
    ondcEnabled: ondcInfo?.ondcEnabled,

    ondc: {
      ...ondcInfo?.ondc,
      fragile: ondcInfo?.ondc?.fragile === "yes" ? true : false,
      available_on_cod:
        ondcInfo?.ondc?.available_on_cod === "none"
          ? false
          : ondcInfo?.ondc?.available_on_cod,

      // timeToShip: ondcInfo?.ondc?.timeToShip
      //   ? `PT${ondcInfo?.ondc?.timeToShip}${ondcUIControl?.timeToShipUnit?.unit}`
      //   : "",
      timeToShip: ondcInfo?.ondc?.timeToShip
        ? formatTimeToShip(
            ondcUIControl?.timeToShipUnit?.unit,
            ondcInfo?.ondc?.timeToShip
          )
        : "",

      veg_nonveg: {
        veg:
          ondcInfo?.ondc?.veg_nonveg?.vegEgg === "yes"
            ? "yes"
            : ondcInfo?.ondc?.veg_nonveg?.veg,
        nonVeg: ondcInfo?.ondc?.veg_nonveg?.nonVeg,
        egg:
          ondcInfo?.ondc?.veg_nonveg?.vegEgg === "yes"
            ? "yes"
            : ondcInfo?.ondc?.veg_nonveg?.egg,
      },

      cancelTerms: {
        ...ondcInfo?.ondc?.cancelTerms,
        cancellable:
          ondcInfo?.ondc?.cancelTerms?.cancellable === "none"
            ? "no"
            : ondcInfo?.ondc?.cancelTerms?.cancellable,

        refundEligible:
          ondcInfo?.ondc?.cancelTerms?.refundEligible === "none"
            ? "no"
            : ondcInfo?.ondc?.cancelTerms?.refundEligible,
      },

      returnTerms: {
        ...ondcInfo?.ondc?.returnTerms,
        returnable:
          ondcInfo?.ondc?.returnTerms?.returnable === "none"
            ? "no"
            : ondcInfo?.ondc?.returnTerms?.returnable,

        returnPeriod: ondcInfo?.ondc?.returnTerms?.returnPeriod
          ? `P${ondcInfo?.ondc?.returnTerms?.returnPeriod}D`
          : "",

        seller_pickup_return:
          ondcInfo?.ondc?.returnTerms?.seller_pickup_return === "none"
            ? "no"
            : ondcInfo?.ondc?.returnTerms?.seller_pickup_return,
      },

      statutoryReqsPackagedCommodities: {
        ...ondcInfo?.ondc?.statutoryReqsPackagedCommodities,
        month_year_of_manufacture_packing_import:
          ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.month_year_of_manufacture_packing_import || "N/A",
      },
    },
  };

  const newPostData = {
    profileId: profileId,
    businessId: businessId,
    productName: productInfo?.productName,
    productDescription: productInfo?.description,
    catalogType: "Product",
    // label: categoryInfo?.productSpecs || [],
    label: specsList || [],
    searchText: "",
    brand: productInfo?.brand || "",
    origin: productInfo?.country?.ondcCountryCode || "",

    skuId: productInfo?.skuId || null,
    // refLink: categoryInfo?.refLinks || [],
    refLink: refLinkList || [],

    productPhoto: data?.productPhoto,

    latitude: lat,
    longitude: long,

    status: "active",
    commerceEnabled: "YES",
    hyperLocal: "Y",

    productMainCategory: [
      {
        ...categoryInfo?.selectedBusinessCat,
        ondcCode: ondcInfo?.ondcProductCategory?.code || null,
        ondcValue:
          ondcInfo?.ondcProductCategory?.name === "Select ONDC Product Category"
            ? null
            : ondcInfo?.ondcProductCategory?.name,
      },
    ],
    productSubCategory: categoryInfo?.selectedProductSubCat1
      ? [
          {
            ...categoryInfo?.selectedProductSubCat1,
            ondcSubCode: ondcInfo?.ondcProductSubCategory?.scCode1 || null,
            ondcSubValue:
              ondcInfo?.ondcProductSubCategory?.productSubCategory1 || null,
          },
        ]
      : [],
    productSubCategory2: categoryInfo?.selectedProductSubCat2
      ? [
          {
            ...categoryInfo?.selectedProductSubCat2,
            ondcSub2Code: null,
            ondcSub2Value: null,
          },
        ]
      : [],

    // commerceStartDate: moment(availabilityInfo?.startDate).format("YYYY-MM-DD"),
    // commerceEndDate: moment(availabilityInfo?.endDate).format("YYYY-MM-DD"),
    commerceStartDate: moment(availabilityInfo?.startDate)
      .startOf("day")
      .toISOString(),
    commerceEndDate: moment(availabilityInfo?.endDate)
      .endOf("day")
      .toISOString(),

    minOrderQuantity: availabilityInfo?.minQty,
    maxOrderQuantity: availabilityInfo?.maxQty,

    productOriginalPrice: priceInfo?.originalPrice,
    productSalePrice: priceInfo?.OfferPrice,

    gstInfo: {
      code:
        `${ondcInfo?.ondc?.productCode?.key}:${ondcInfo?.ondc?.productCode?.value}` ||
        "",
      gstPercentage: priceInfo?.gstPercentage || "",
    },

    measurementInfo: {
      weight: ondcInfo?.netProductWeight || "0",
      length: measurementInfo?.length || "0",
      width: measurementInfo?.width || "0",
      height: measurementInfo?.height || "0",
    },

    taxDetails: [
      {
        code: "Inclusive of taxes",
        name: "Taxes Included",
        value: priceInfo?.taxDetails,
      },
    ],

    currencySymbol: "₹",
    unitOfMeasure: "Gram",

    availability: [`${availabilityInfo?.availability}`],
    deliveryType: measurementInfo?.deliveryMethod,

    shipmentDistance: "0",
    preference: 0,

    availableQuantity: availabilityInfo?.availabelQty,
    soldQuantity: 0,
    alertQuantity: availabilityInfo?.alertQty,

    subscription: subscriptionInfo?.isProductSubscribed
      ? subscriptionData
      : null,

    ...ondcPost,
  };

  return newPostData;
};

// New Add Product Post Data
export const prepareAddNewProductPostData = (data) => {
  const profileId = getProfileId();
  const businessId = data?.businessId;
  const businessInfo = data?.businessInfo;

  const taxDetails = data?.taxDetails;
  const productSpec = data?.productSpec;

  const productInfo = data?.productInfo;
  const categoryInfo = data?.categoryInfo;
  const priceInfo = data?.priceInfo;
  const measurementInfo = data?.measurementInfo;
  const availabilityInfo = data?.availabilityInfo;
  const subscriptionInfo = data?.subscriptionInfo;

  const ondcInfo = data?.ondcInfo;
  const ondcUIControl = data?.ondcUIControl;
  const ondcDbObj = data?.ondcDbObj;

  // const lat = localStorage.getItem("lat") || LATITUDE;
  // const long = localStorage.getItem("long") || LONGITUDE;

  const lat = businessInfo?.loc?.y || "";
  const long = businessInfo?.loc?.x || "";

  const specsList = productSpec?.productSpecs?.filter(
    (item) => item?.code !== "" && item?.value?.[0] !== ""
  );

  const refLinkList = productSpec?.refLinks?.filter(
    (item) => item?.code !== "" && item?.link !== ""
  );

  const subscriptionData = {
    status: "active",
    subStatus: "ready",
    details: "",
    subscription: subscriptionInfo?.isProductSubscribed,
    offerValue: subscriptionInfo?.offerValue,
    originalValue: subscriptionInfo?.originalValue,
    cancelTime: subscriptionInfo?.cancelTime,
    serviceFee: subscriptionInfo?.serviceFee,
    specialFee: subscriptionInfo?.specialFee,
    packingFee: subscriptionInfo?.packingFee,
    type: subscriptionInfo?.subType,
    notes: null,
    cancelDays: subscriptionInfo?.cancelDays,
    endDate: subscriptionInfo?.endDate,
    startDate: subscriptionInfo?.startDate,
  };
  console.log("ondcDbObjondcDbObj", ondcDbObj);

  const ondcPost = {
    ...ondcDbObj,
    productDisplayUnitOfMeasure: ondcInfo?.unitOfMeasure,
    netProductWeight: measurementInfo?.weight || 0,
    ondcEnabled: categoryInfo?.ondcEnabled,

    ondc: {
      ...ondcDbObj?.ondc,
      productCode: ondcInfo?.productCode,
      fragile: ondcInfo?.fragile,
      available_on_cod: ondcInfo?.available_on_cod,

      // timeToShip: ondcInfo?.timeToShip
      //   ? `PT${ondcInfo?.timeToShip}${ondcInfo?.timeToShipUnit?.unit}`
      //   : "",

      timeToShip: ondcInfo?.timeToShip
        ? formatTimeToShip(ondcInfo?.timeToShipUnit?.unit, ondcInfo?.timeToShip)
        : "",

      cancelTerms: {
        ...ondcInfo?.cancelTerms,
        cancellable: ondcInfo?.cancelTerms?.cancellable?.toLowerCase(),

        refundEligible: ondcInfo?.cancelTerms?.refundEligible?.toLowerCase(),
        cancellationFeeAmount: null,
      },

      returnTerms: {
        ...ondcInfo?.returnTerms,
        returnable: ondcInfo?.returnTerms?.returnable?.toLowerCase(),

        returnPeriod: ondcInfo?.returnTerms?.returnPeriod
          ? `P${ondcInfo?.returnTerms?.returnPeriod}D`
          : "",

        seller_pickup_return:
          ondcInfo?.returnTerms?.seller_pickup_return?.toLowerCase(),
      },
    },
    // : { productCode: taxDetails?.productCode },
  };

  const newPostData = {
    profileId: profileId,
    businessId: businessId,
    productName: productInfo?.productName,
    productDescription: productInfo?.description,
    catalogType: "Product",
    label: specsList || [],
    // label: getListToLabel(data?.ondcSpecificationData) || [],
    searchText: "",
    brand: productInfo?.brand || "",
    origin: productInfo?.country?.ondcCountryCode || "",

    skuId: productInfo?.skuId || null,
    // refLink: getListToLabel(data?.ondcSpecificationData) || [],
    refLink: refLinkList || [],

    productPhoto: data?.productPhoto,

    latitude: lat,
    longitude: long,

    status: "active",
    commerceEnabled: "YES",
    hyperLocal: "Y",

    productMainCategory: [
      {
        ...categoryInfo?.selectedBusinessCat,
        ondcCode: categoryInfo?.selectedOndcCat?.code || null,
        ondcValue: categoryInfo?.selectedOndcCat?.name || null,
        value: categoryInfo?.selectedBusinessCat?.name || null,
      },
    ],
    productSubCategory: categoryInfo?.selectedProductCat
      ? [
          {
            ...categoryInfo?.selectedProductCat,
            ondcSubCode: categoryInfo?.selectedOndcSubCat?.scCode1 || null,
            ondcSubValue:
              categoryInfo?.selectedOndcSubCat?.productSubCategory1 || null,
          },
        ]
      : [],
    productSubCategory2: categoryInfo?.selectedProductSubCat
      ? [
          {
            ...categoryInfo?.selectedProductSubCat,
            ondcSub2Code: null,
            ondcSub2Value: null,
          },
        ]
      : [],

    // commerceStartDate: moment(availabilityInfo?.startDate).format("YYYY-MM-DD"),
    // commerceEndDate: moment(availabilityInfo?.endDate).format("YYYY-MM-DD"),
    commerceStartDate: moment(availabilityInfo?.startDate)
      .startOf("day")
      .toISOString(),
    commerceEndDate: moment(availabilityInfo?.endDate)
      .endOf("day")
      .toISOString(),

    minOrderQuantity: availabilityInfo?.minQty,
    maxOrderQuantity: availabilityInfo?.maxQty,

    productOriginalPrice: priceInfo?.originalPrice,
    productSalePrice: priceInfo?.OfferPrice,

    gstInfo: {
      code:
        `${ondcInfo?.productCode?.key}:${ondcInfo?.productCode?.value}` || "",
      gstPercentage: taxDetails?.gstPercentage || "",
    },

    measurementInfo: {
      weight: measurementInfo?.netProductWeight || "0",
      length: measurementInfo?.length || "0",
      width: measurementInfo?.width || "0",
      height: measurementInfo?.height || "0",
    },

    taxDetails: [
      {
        code: "Inclusive of taxes",
        name: "Taxes Included",
        value: priceInfo?.taxDetails,
      },
    ],

    currencySymbol: "₹",
    unitOfMeasure: "Gram",

    availability: [`${availabilityInfo?.availability}`],
    deliveryType: measurementInfo?.deliveryMethod,

    shipmentDistance: "0",
    preference: 0,

    availableQuantity: availabilityInfo?.availabelQty,
    soldQuantity: 0,
    alertQuantity: availabilityInfo?.alertQty,

    subscription: subscriptionInfo?.isProductSubscribed
      ? subscriptionData
      : null,

    ...ondcPost,
  };

  return newPostData;
};

export const prepareProductPreviewData = (data) => {
  console.log("prepareProductPreviewData___:", data);

  const {
    productInfo,
    taxDetails,
    productImgUpdate,
    priceInfo,
    availabilityInfo,
    measurementInfo,
    productSpec,
    ondcData,
    ondcSpecificationData,
    categoryInfo,
  } = data;

  const productDetails = {
    description: productInfo?.description,
    brand: productInfo?.brand,
    country: productInfo?.country,
    skuId: productInfo?.skuId,
    taxesIncluded: "Yes",
    gstPercentage: taxDetails?.gstPercentage,
    productCode: taxDetails?.productCode?.key,
    productCodeValue: taxDetails?.productCode?.value,
  };

  const priceDetails = {
    currency: priceInfo?.currency,
    originalPrice: priceInfo?.originalPrice,
    offerPrice: priceInfo?.OfferPrice,
    qty: availabilityInfo?.availabelQty,
    alertQty: availabilityInfo?.alertQty,
    minQtyPerOrder: availabilityInfo?.minQty,
    maxQtyPerOrder: availabilityInfo?.maxQty,
    startDate: availabilityInfo?.startDate,
    endDate: availabilityInfo?.endDate,
  };

  const measurementDetails = {
    weight: measurementInfo?.weight,
    netProductWeight: measurementInfo?.netProductWeight,
    length: measurementInfo?.length,
    width: measurementInfo?.width,
    height: measurementInfo?.height,
    productSpecLabel: productSpec?.productSpecs,
    refLinks: productSpec?.refLinks,
  };

  const ondcDetails = {
    ondcEnabled: categoryInfo?.ondcEnabled,
    timeToShip: ondcData?.timeToShip,
    timeUnit: ondcData?.timeToShipUnit?.label,
    cod: ondcData?.available_on_cod ? "Yes" : "No",
    fragile: ondcData?.fragile ? "Yes" : "No",
    returnable: ondcData?.returnTerms?.returnable,
    returnPeriod: ondcData?.returnTerms?.returnPeriod,
    sellerPickup: ondcData?.returnTerms?.seller_pickup_return,
    cancellable: ondcData?.cancelTerms?.cancellable,
    refundEligible: ondcData?.cancelTerms?.refundEligible,
    cancellationFee: ondcData?.cancelTerms?.cancellationFeePercentage,
    dynamicSpecs: ondcSpecificationData,
    productCode: ondcData?.productCode?.key,
    productCodeValue: ondcData?.productCode?.value,
    unitOfMeasure: ondcData?.unitOfMeasure?.unit,
    unitOfMeasureValue: ondcData?.unitOfMeasure?.value,
  };

  return {
    productDetails,
    productImgUpdate,
    priceDetails,
    measurementDetails,
    ondcDetails,
  };
};

export const prepareViewProductData = (data) => {
  console.log("prepareViewProductData", data);
  const { productInfo, ondcSpecificationData, countryList } = data;

  const refLink =
    productInfo?.refLink?.length > 0
      ? productInfo?.refLink
      : [{ code: "", link: "" }];

  const productSpec =
    productInfo?.label?.length > 0
      ? productInfo?.label
      : [{ code: "", link: "" }];

  const unitSymbol =
    productInfo?.ondc?.timeToShip?.charAt(
      productInfo?.ondc?.timeToShip?.length - 1
    ) || "";

  const ondcData = {
    ondcEnabled: productInfo?.ondc?.ondcEnabled,
    timeToShipUnit: getTimeToShipUnitValue(unitSymbol),
    available_on_cod: productInfo?.ondc?.available_on_cod,
    fragile: productInfo?.ondc?.fragile == true,

    timeToShip: getTimeToShipValue(unitSymbol, productInfo?.ondc?.timeToShip),

    cancelTerms: {
      cancellable: productInfo?.ondc?.cancelTerms?.cancellable || "none",
      cancellationFeeAmount: null,
      cancellationFeePercentage:
        productInfo?.ondc?.cancelTerms?.cancellationFeePercentage || "",
      refundEligible: productInfo?.ondc?.cancelTerms?.refundEligible || "none",
    },

    returnTerms: {
      returnable: productInfo?.ondc?.returnTerms?.returnable || "none",
      returnPeriod: productInfo?.ondc?.returnTerms?.returnPeriod
        ? productInfo?.ondc?.returnTerms?.returnPeriod?.slice(1, -1)
        : "",
      seller_pickup_return:
        productInfo?.ondc?.returnTerms?.seller_pickup_return || "none",
    },

    productCode: {
      key: productInfo?.ondc?.productCode?.key,
      value: productInfo?.ondc?.productCode?.value || "",
    },

    unitOfMeasure: productInfo?.productDisplayUnitOfMeasure,
  };

  const productImgUpdate = productInfo?.productPhoto || [];
  const productDetails = {
    description: productInfo?.productDescription,
    brand: productInfo?.brand,
    country: getSelectedCountryByCode(productInfo?.origin, countryList),
    skuId: productInfo?.skuId,
    taxesIncluded: "Yes",
    gstPercentage: productInfo?.gstInfo?.gstPercentage,
    productCode: productInfo?.ondc?.productCode?.key,
    productCodeValue: productInfo?.ondc?.productCode?.value,
  };

  const priceDetails = {
    currency: productInfo?.currencySymbol,
    originalPrice: productInfo?.productOriginalPrice,
    offerPrice: productInfo?.productSalePrice,
    qty: productInfo?.availableQuantity,
    alertQty: productInfo?.alertQuantity,
    minQtyPerOrder: productInfo?.minOrderQuantity,
    maxQtyPerOrder: productInfo?.maxOrderQuantity,
    startDate: productInfo?.commerceStartDate,
    endDate: productInfo?.commerceEndDate,
  };

  const measurementDetails = {
    netProductWeight: productInfo?.measurementInfo?.weight,
    weight: productInfo?.netProductWeight,
    length: productInfo?.measurementInfo?.length,
    width: productInfo?.measurementInfo?.width,
    height: productInfo?.measurementInfo?.height,
    productSpecLabel: productSpec,
    refLinks: refLink,
  };
  const ondcDetails = {
    ondcEnabled: productInfo?.ondcEnabled,
    timeToShip: ondcData?.timeToShip,
    timeUnit: ondcData?.timeToShipUnit?.label,
    cod: ondcData?.available_on_cod ? "Yes" : "No",
    fragile: ondcData?.fragile ? "Yes" : "No",
    returnable: ondcData?.returnTerms?.returnable,
    returnPeriod: ondcData?.returnTerms?.returnPeriod,
    sellerPickup: ondcData?.returnTerms?.seller_pickup_return,
    cancellable: ondcData?.cancelTerms?.cancellable,
    refundEligible: ondcData?.cancelTerms?.refundEligible,
    cancellationFee: ondcData?.cancelTerms?.cancellationFeePercentage,
    dynamicSpecs: ondcSpecificationData,
    productCode: ondcData?.productCode?.key,
    productCodeValue: ondcData?.productCode?.value,
    unitOfMeasure: ondcData?.unitOfMeasure?.unit,
    unitOfMeasureValue: ondcData?.unitOfMeasure?.value,
  };

  console.log("preparedViewProductData", {
    productDetails,
    productImgUpdate,
    priceDetails,
    measurementDetails,
    ondcDetails,
  });

  return {
    productDetails,
    productImgUpdate,
    priceDetails,
    measurementDetails,
    ondcDetails,
  };
};

export const getUpdateProductPostAPIData = (data) => {
  console.log("hit555", data?.ondcInfo);
  const profileId = getProfileId();
  const businessId = data?.businessId;
  const businessInfo = data?.businessInfo;

  const productDetails = data?.productDetails;

  const productInfo = data?.productInfo;
  const categoryInfo = data?.categoryInfo;
  const priceInfo = data?.priceInfo;
  const measurementInfo = data?.measurementInfo;
  const availabilityInfo = data?.availabilityInfo;
  const subscriptionInfo = data?.subscriptionInfo;

  const ondcInfo = data?.ondcInfo;
  const ondcUIControl = data?.ondcUIControl;

  // const lat = localStorage.getItem("lat") || LATITUDE;
  // const long = localStorage.getItem("long") || LONGITUDE;

  const lat = businessInfo?.loc?.y || "";
  const long = businessInfo?.loc?.x || "";

  const subscriptionData = {
    status: "active",
    subStatus: "ready",
    details: "",
    subscription: subscriptionInfo?.isProductSubscribed,
    offerValue: subscriptionInfo?.offerValue,
    originalValue: subscriptionInfo?.originalValue,
    cancelTime: subscriptionInfo?.cancelTime,
    serviceFee: subscriptionInfo?.serviceFee,
    specialFee: subscriptionInfo?.specialFee,
    packingFee: subscriptionInfo?.packingFee,
    type: subscriptionInfo?.subType,
    notes: null,
    cancelDays: subscriptionInfo?.cancelDays,
    endDate: subscriptionInfo?.endDate,
    startDate: subscriptionInfo?.startDate,
  };

  const specsList = categoryInfo?.productSpecs?.filter(
    (item) => item?.code !== "" && item?.value?.[0] !== ""
  );

  const refLinkList = categoryInfo?.refLinks?.filter(
    (item) => item?.code !== "" && item?.link !== ""
  );

  const ondcPost = {
    // ...ondcInfo,

    productDisplayUnitOfMeasure: ondcInfo?.productDisplayUnitOfMeasure,
    netProductWeight: measurementInfo?.weight || "0",
    ondcEnabled: ondcInfo?.ondcEnabled,

    ondc: {
      ...ondcInfo?.ondc,
      fragile: ondcInfo?.ondc?.fragile === "yes" ? true : false,
      available_on_cod:
        ondcInfo?.ondc?.available_on_cod === "none"
          ? false
          : ondcInfo?.ondc?.available_on_cod,

      // timeToShip: ondcInfo?.ondc?.timeToShip
      //   ? `PT${ondcInfo?.ondc?.timeToShip}${ondcUIControl?.timeToShipUnit?.unit}`
      //   : "",
      timeToShip: ondcInfo?.ondc?.timeToShip
        ? formatTimeToShip(
            ondcUIControl?.timeToShipUnit?.unit,
            ondcInfo?.ondc?.timeToShip
          )
        : "",

      veg_nonveg: {
        veg:
          ondcInfo?.ondc?.veg_nonveg?.vegEgg === "yes"
            ? "yes"
            : ondcInfo?.ondc?.veg_nonveg?.veg,
        nonVeg: ondcInfo?.ondc?.veg_nonveg?.nonVeg,
        egg:
          ondcInfo?.ondc?.veg_nonveg?.vegEgg === "yes"
            ? "yes"
            : ondcInfo?.ondc?.veg_nonveg?.egg,
      },

      cancelTerms: {
        ...ondcInfo?.ondc?.cancelTerms,
        cancellable:
          ondcInfo?.ondc?.cancelTerms?.cancellable === "none"
            ? "no"
            : ondcInfo?.ondc?.cancelTerms?.cancellable,

        refundEligible:
          ondcInfo?.ondc?.cancelTerms?.refundEligible === "none"
            ? "no"
            : ondcInfo?.ondc?.cancelTerms?.refundEligible,
      },

      returnTerms: {
        ...ondcInfo?.ondc?.returnTerms,
        returnable:
          ondcInfo?.ondc?.returnTerms?.returnable === "none"
            ? "no"
            : ondcInfo?.ondc?.returnTerms?.returnable,

        returnPeriod: ondcInfo?.ondc?.returnTerms?.returnPeriod
          ? `P${ondcInfo?.ondc?.returnTerms?.returnPeriod}D`
          : "",

        seller_pickup_return:
          ondcInfo?.ondc?.returnTerms?.seller_pickup_return === "none"
            ? "no"
            : ondcInfo?.ondc?.returnTerms?.seller_pickup_return,
      },

      statutoryReqsPackagedCommodities: {
        ...ondcInfo?.ondc?.statutoryReqsPackagedCommodities,
        month_year_of_manufacture_packing_import:
          ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.month_year_of_manufacture_packing_import || "N/A",
      },
    },
  };

  const postData = {
    profileId: profileId,
    businessId: businessId,
    productName: productInfo?.productName,
    productDescription: productInfo?.description,
    catalogType: "Product",
    // label: categoryInfo?.productSpecs || [],
    label: specsList || [],
    searchText: productDetails?.searchText,
    brand: productInfo?.brand || "",
    skuId: productInfo?.skuId || null,
    // refLink: categoryInfo?.refLinks || [],
    refLink: refLinkList || [],
    productPhoto: [],
    latitude: lat,
    longitude: long,
    status: productDetails?.status,
    commerceEnabled: "YES",
    hyperLocal: "Y",
    origin: productInfo?.country?.ondcCountryCode || "",
    productMainCategory: [
      {
        ...categoryInfo?.selectedBusinessCat,
        ondcCode: ondcInfo?.ondcProductCategory?.code || null,
        ondcValue:
          ondcInfo?.ondcProductCategory?.name === "Select ONDC Product Category"
            ? null
            : ondcInfo?.ondcProductCategory?.name,
      },
    ],
    productSubCategory: categoryInfo?.selectedProductSubCat1
      ? [
          {
            ...categoryInfo?.selectedProductSubCat1,
            ondcSubCode: ondcInfo?.ondcProductSubCategory?.scCode1 || null,
            ondcSubValue:
              ondcInfo?.ondcProductSubCategory?.productSubCategory1 || null,
          },
        ]
      : [],
    productSubCategory2: categoryInfo?.selectedProductSubCat2
      ? [
          {
            ...categoryInfo?.selectedProductSubCat2,
            ondcSub2Code: null,
            ondcSub2Value: null,
          },
        ]
      : [],
    // commerceStartDate: availabilityInfo?.startDate,
    // commerceEndDate: availabilityInfo?.endDate,

    commerceStartDate: moment(availabilityInfo?.startDate)
      .startOf("day")
      .toISOString(),
    commerceEndDate: moment(availabilityInfo?.endDate)
      .endOf("day")
      .toISOString(),

    minOrderQuantity: availabilityInfo?.minQty,
    maxOrderQuantity: availabilityInfo?.maxQty,
    productSalePrice: priceInfo?.OfferPrice,
    productOriginalPrice: priceInfo?.originalPrice,
    subscription: subscriptionInfo?.isProductSubscribed
      ? subscriptionData
      : productDetails?.subscription,
    gstInfo: {
      code:
        `${ondcInfo?.ondc?.productCode?.key}:${ondcInfo?.ondc?.productCode?.value}` ||
        "",
      gstPercentage: priceInfo?.gstPercentage || "",
    },
    measurementInfo: {
      weight: ondcInfo?.netProductWeight || "0",
      length: measurementInfo?.length || "0",
      width: measurementInfo?.width || "0",
      height: measurementInfo?.height || "0",
    },
    taxDetails: [
      {
        code: "Inclusive of taxes",
        name: "Taxes Included",
        value: priceInfo?.taxDetails,
      },
    ],
    currencySymbol: "₹",
    unitOfMeasure: "Gram",
    availability: [`${availabilityInfo?.availability}`],
    // deliveryType: measurementInfo?.deliveryMethod,
    deliveryType: ["Delivery/Fulfillment by Business"],
    shipmentDistance: productDetails?.shipmentDistance,
    preference: productDetails?.preference,
    availableQuantity: availabilityInfo?.availabelQty,
    soldQuantity: productDetails?.soldQuantity,
    alertQuantity: availabilityInfo?.alertQty,
    clientType: "business-dashboard-web",
    productId: productDetails?.id,

    ...ondcPost,
  };

  return postData;
};

// Only ONDC Post data
export const getUpdateProductONDCPostAPIData = (data) => {
  const profileId = getProfileId();
  const businessId = data?.businessId;
  const businessInfo = data?.businessInfo;

  const productDetails = data?.productDetails;

  const ondcInfo = data?.ondcInfo;
  const ondcUIControl = data?.ondcUIControl;

  // const lat = localStorage.getItem("lat") || LATITUDE;
  // const long = localStorage.getItem("long") || LONGITUDE;

  const lat = businessInfo?.loc?.y || "";
  const long = businessInfo?.loc?.x || "";

  const ondcPost = {
    // ...ondcInfo,

    productDisplayUnitOfMeasure: ondcInfo?.productDisplayUnitOfMeasure,
    netProductWeight: ondcInfo?.weight || 0,
    ondcEnabled: ondcInfo?.ondcEnabled,

    ondc: {
      ...ondcInfo?.ondc,
      fragile: ondcInfo?.ondc?.fragile === "yes" ? true : false,
      available_on_cod:
        ondcInfo?.ondc?.available_on_cod === "none"
          ? false
          : ondcInfo?.ondc?.available_on_cod,

      // timeToShip: ondcInfo?.ondc?.timeToShip
      //   ? `PT${ondcInfo?.ondc?.timeToShip}${ondcUIControl?.timeToShipUnit?.unit}`
      //   : "",
      timeToShip: ondcInfo?.ondc?.timeToShip
        ? formatTimeToShip(
            ondcUIControl?.timeToShipUnit?.unit,
            ondcInfo?.ondc?.timeToShip
          )
        : "",

      veg_nonveg: {
        veg:
          ondcInfo?.ondc?.veg_nonveg?.vegEgg === "yes"
            ? "yes"
            : ondcInfo?.ondc?.veg_nonveg?.veg,
        nonVeg: ondcInfo?.ondc?.veg_nonveg?.nonVeg,
        egg:
          ondcInfo?.ondc?.veg_nonveg?.vegEgg === "yes"
            ? "yes"
            : ondcInfo?.ondc?.veg_nonveg?.egg,
      },

      cancelTerms: {
        ...ondcInfo?.ondc?.cancelTerms,
        cancellable:
          ondcInfo?.ondc?.cancelTerms?.cancellable === "none"
            ? "no"
            : ondcInfo?.ondc?.cancelTerms?.cancellable,

        refundEligible:
          ondcInfo?.ondc?.cancelTerms?.refundEligible === "none"
            ? "no"
            : ondcInfo?.ondc?.cancelTerms?.refundEligible,
      },

      returnTerms: {
        ...ondcInfo?.ondc?.returnTerms,
        returnable:
          ondcInfo?.ondc?.returnTerms?.returnable === "none"
            ? "no"
            : ondcInfo?.ondc?.returnTerms?.returnable,

        returnPeriod: ondcInfo?.ondc?.returnTerms?.returnPeriod
          ? `P${ondcInfo?.ondc?.returnTerms?.returnPeriod}D`
          : "",

        seller_pickup_return:
          ondcInfo?.ondc?.returnTerms?.seller_pickup_return === "none"
            ? "no"
            : ondcInfo?.ondc?.returnTerms?.seller_pickup_return,
      },

      statutoryReqsPackagedCommodities: {
        ...ondcInfo?.ondc?.statutoryReqsPackagedCommodities,
        month_year_of_manufacture_packing_import:
          ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.month_year_of_manufacture_packing_import || "N/A",
      },
    },
  };

  const postData = {
    profileId: profileId,
    businessId: businessId,
    productName: productDetails?.productName,
    productDescription: productDetails?.productDescription,
    catalogType: "Product",

    label: productDetails?.label,

    searchText: productDetails?.searchText,
    brand: productDetails?.brand,
    skuId: productDetails?.skuId,

    refLink: productDetails?.refLink,

    productPhoto: [],

    latitude: lat,
    longitude: long,

    status: productDetails?.status,
    commerceEnabled: "YES",
    hyperLocal: "Y",
    origin: productDetails?.origin,

    productMainCategory: [
      {
        ...productDetails?.productMainCategory?.[0],
        ondcCode: ondcInfo?.ondcProductCategory?.code || null,
        ondcValue:
          ondcInfo?.ondcProductCategory?.name === "Select ONDC Product Category"
            ? null
            : ondcInfo?.ondcProductCategory?.name,
      },
    ],
    productSubCategory: [
      {
        ...productDetails?.productSubCategory?.[0],
        ondcSubCode: ondcInfo?.ondcProductSubCategory?.scCode1 || null,
        ondcSubValue:
          ondcInfo?.ondcProductSubCategory?.productSubCategory1 || null,
      },
    ],
    productSubCategory2: productDetails?.productSubCategory2,

    // commerceStartDate: productDetails?.commerceStartDate,
    // commerceEndDate: productDetails?.commerceEndDate,
    commerceStartDate: moment(productDetails?.commerceStartDate).toISOString(),
    commerceEndDate: moment(productDetails?.commerceEndDate).toISOString(),

    minOrderQuantity: productDetails?.minOrderQuantity,
    maxOrderQuantity: productDetails?.maxOrderQuantity,
    productSalePrice: productDetails?.productSalePrice,
    productOriginalPrice: productDetails?.productOriginalPrice,

    subscription: productDetails?.subscription,
    gstInfo: productDetails?.gstInfo,
    measurementInfo: {
      ...productDetails?.measurementInfo,
      weight: Number(ondcInfo?.netProductWeight) || 0,
    },
    taxDetails: productDetails?.taxDetails,
    currencySymbol: "₹",
    unitOfMeasure: "Gram",
    availability: productDetails?.availability,
    // deliveryType: measurementInfo?.deliveryMethod,
    deliveryType: ["Delivery/Fulfillment by Business"],
    shipmentDistance: productDetails?.shipmentDistance,
    preference: productDetails?.preference,
    availableQuantity: productDetails?.availableQuantity,
    soldQuantity: productDetails?.soldQuantity,
    alertQuantity: productDetails?.alertQuantity,
    clientType: "business-dashboard-web",
    productId: productDetails?.id,

    ...ondcPost,
  };

  return postData;
};

// Only ONDC Post data
export const prepareONDCDataPostAPIData = (data) => {
  const profileId = getProfileId();
  const businessId = data?.businessId;
  const businessInfo = data?.businessInfo;

  const productDetails = data?.productDetails;
  const categoryInfo = data?.categoryInfo;

  const ondcInfo = data?.ondcData;
  const ondcDbObj = data?.dbSpecificationObj;

  const lat = businessInfo?.loc?.y || "";
  const long = businessInfo?.loc?.x || "";

  const ondcPost = {
    ...ondcDbObj,
    productDisplayUnitOfMeasure: ondcInfo?.unitOfMeasure,
    netProductWeight: productDetails?.netProductWeight,
    ondcEnabled: categoryInfo?.ondcEnabled,

    ondc: {
      ...ondcDbObj?.ondc,
      productCode: ondcInfo?.productCode,
      fragile: ondcInfo?.fragile,
      available_on_cod: ondcInfo?.available_on_cod,

      // timeToShip: ondcInfo?.timeToShip
      //   ? `PT${ondcInfo?.timeToShip}${ondcInfo?.timeToShipUnit?.unit}`
      //   : "",

      timeToShip: ondcInfo?.timeToShip
        ? formatTimeToShip(ondcInfo?.timeToShipUnit?.unit, ondcInfo?.timeToShip)
        : "",

      cancelTerms: {
        ...ondcInfo?.cancelTerms,
        cancellable: ondcInfo?.cancelTerms?.cancellable?.toLowerCase(),

        refundEligible: ondcInfo?.cancelTerms?.refundEligible?.toLowerCase(),
        cancellationFeeAmount: null,
      },

      returnTerms: {
        ...ondcInfo?.returnTerms,
        returnable: ondcInfo?.returnTerms?.returnable?.toLowerCase(),

        returnPeriod: ondcInfo?.returnTerms?.returnPeriod
          ? `P${ondcInfo?.returnTerms?.returnPeriod}D`
          : "",

        seller_pickup_return:
          ondcInfo?.returnTerms?.seller_pickup_return?.toLowerCase(),
      },
    },
    // : { productCode: taxDetails?.productCode },
  };

  const postData = {
    profileId: profileId,
    businessId: businessId,
    productName: productDetails?.productName,
    productDescription: productDetails?.productDescription,
    catalogType: "Product",

    label: productDetails?.label,

    searchText: productDetails?.searchText,
    brand: productDetails?.brand,
    skuId: productDetails?.skuId,

    refLink: productDetails?.refLink,

    productPhoto: [],

    latitude: lat,
    longitude: long,

    status: productDetails?.status,
    commerceEnabled: "YES",
    hyperLocal: "Y",
    origin: productDetails?.origin,

    productMainCategory: [
      {
        ...productDetails?.productMainCategory?.[0],
        ondcCode: categoryInfo?.selectedOndcCat?.code || null,
        ondcValue: categoryInfo?.selectedOndcCat?.name || null,
      },
    ],
    productSubCategory: [
      {
        ...productDetails?.productSubCategory?.[0],
        ondcSubCode: categoryInfo?.selectedOndcSubCat?.scCode1 || null,
        ondcSubValue:
          categoryInfo?.selectedOndcSubCat?.productSubCategory1 || null,
      },
    ],
    productSubCategory2: productDetails?.productSubCategory2,

    // commerceStartDate: productDetails?.commerceStartDate,
    // commerceEndDate: productDetails?.commerceEndDate,
    commerceStartDate: moment(productDetails?.commerceStartDate).toISOString(),
    commerceEndDate: moment(productDetails?.commerceEndDate).toISOString(),

    minOrderQuantity: productDetails?.minOrderQuantity,
    maxOrderQuantity: productDetails?.maxOrderQuantity,
    productSalePrice: productDetails?.productSalePrice,
    productOriginalPrice: productDetails?.productOriginalPrice,

    subscription: productDetails?.subscription,
    gstInfo: productDetails?.gstInfo,
    measurementInfo: productDetails?.measurementInfo,
    taxDetails: productDetails?.taxDetails,
    currencySymbol: "₹",
    unitOfMeasure: "Gram",
    availability: productDetails?.availability,
    // deliveryType: measurementInfo?.deliveryMethod,
    deliveryType: ["Delivery/Fulfillment by Business"],
    shipmentDistance: productDetails?.shipmentDistance,
    preference: productDetails?.preference,
    availableQuantity: productDetails?.availableQuantity,
    soldQuantity: productDetails?.soldQuantity,
    alertQuantity: productDetails?.alertQuantity,
    clientType: "business-dashboard-web",
    productId: productDetails?.id,

    ...ondcPost,
  };

  return postData;
};

// Only ONDC Enabled Attribute Update
export const prepareONDCEnabledPostAPIData = (data) => {
  const profileId = getProfileId();
  const businessId = data?.businessId;
  const businessInfo = data?.businessInfo;

  const productDetails = data?.productDetails;
  const categoryInfo = data?.categoryInfo;

  const lat = businessInfo?.loc?.y || "";
  const long = businessInfo?.loc?.x || "";

  const ondcPost = {
    productDisplayUnitOfMeasure: productDetails?.productDisplayUnitOfMeasure,
    netProductWeight: productDetails?.netProductWeight,
    ondcEnabled: categoryInfo?.ondcEnabled,

    ondc: {
      ...productDetails?.ondc,
    },
  };

  const postData = {
    profileId: profileId,
    businessId: businessId,
    productName: productDetails?.productName,
    productDescription: productDetails?.productDescription,
    catalogType: "Product",

    label: productDetails?.label,

    searchText: productDetails?.searchText,
    brand: productDetails?.brand,
    skuId: productDetails?.skuId,

    refLink: productDetails?.refLink,

    productPhoto: [],

    latitude: lat,
    longitude: long,

    status: productDetails?.status,
    commerceEnabled: "YES",
    hyperLocal: "Y",
    origin: productDetails?.origin,

    productMainCategory: productDetails?.productMainCategory,
    productSubCategory: productDetails?.productSubCategory,
    productSubCategory2: productDetails?.productSubCategory2,

    // commerceStartDate: productDetails?.commerceStartDate,
    // commerceEndDate: productDetails?.commerceEndDate,
    commerceStartDate: moment(productDetails?.commerceStartDate).toISOString(),
    commerceEndDate: moment(productDetails?.commerceEndDate).toISOString(),

    minOrderQuantity: productDetails?.minOrderQuantity,
    maxOrderQuantity: productDetails?.maxOrderQuantity,
    productSalePrice: productDetails?.productSalePrice,
    productOriginalPrice: productDetails?.productOriginalPrice,

    subscription: productDetails?.subscription,
    gstInfo: productDetails?.gstInfo,
    measurementInfo: productDetails?.measurementInfo,
    taxDetails: productDetails?.taxDetails,
    currencySymbol: "₹",
    unitOfMeasure: "Gram",
    availability: productDetails?.availability,
    // deliveryType: measurementInfo?.deliveryMethod,
    deliveryType: ["Delivery/Fulfillment by Business"],
    shipmentDistance: productDetails?.shipmentDistance,
    preference: productDetails?.preference,
    availableQuantity: productDetails?.availableQuantity,
    soldQuantity: productDetails?.soldQuantity,
    alertQuantity: productDetails?.alertQuantity,
    clientType: "business-dashboard-web",
    productId: productDetails?.id,

    ...ondcPost,
  };

  return postData;
};

export const getTimeToShipUnitValue = (symbol) => {
  switch (symbol) {
    case "M":
      return {
        label: "Minutes",
        unit: "M",
      };

    case "H":
      return {
        label: "Hours",
        unit: "H",
      };

    case "D":
      return {
        label: "Days",
        unit: "D",
      };

    default:
      return {
        label: "Minutes",
        unit: "M",
      };
  }
};

export const getCancellableFeeType = (cancelTerms) => {
  if (
    !cancelTerms?.cancellationFeeAmount &&
    !cancelTerms?.cancellationFeePercentage
  )
    return "Select Fee Type";

  const defaultType = cancelTerms?.cancellationFeeAmount
    ? "Amount"
    : "Percentage";

  return defaultType;
};

export const prepareUpdateNewProductPostData = (data) => {
  console.log("hit555", data?.ondcInfo);
  const profileId = getProfileId();
  const businessId = data?.businessId;
  const businessInfo = data?.businessInfo;

  const productDetails = data?.productDetails;

  const taxDetails = data?.taxDetails;
  const productSpec = data?.productSpec;

  const productInfo = data?.productInfo;
  const categoryInfo = data?.categoryInfo;
  const priceInfo = data?.priceInfo;
  const measurementInfo = data?.measurementInfo;
  const availabilityInfo = data?.availabilityInfo;
  const subscriptionInfo = data?.subscriptionInfo;

  const ondcInfo = data?.ondcInfo;
  const ondcUIControl = data?.ondcUIControl;
  const ondcDbObj = data?.ondcDbObj;

  // const lat = localStorage.getItem("lat") || LATITUDE;
  // const long = localStorage.getItem("long") || LONGITUDE;

  const lat = businessInfo?.loc?.y || "";
  const long = businessInfo?.loc?.x || "";

  const specsList = productSpec?.productSpecs?.filter(
    (item) => item?.code !== "" && item?.value?.[0] !== ""
  );

  const refLinkList = productSpec?.refLinks?.filter(
    (item) => item?.code !== "" && item?.link !== ""
  );

  const subscriptionData = {
    status: "active",
    subStatus: "ready",
    details: "",
    subscription: subscriptionInfo?.isProductSubscribed,
    offerValue: subscriptionInfo?.offerValue,
    originalValue: subscriptionInfo?.originalValue,
    cancelTime: subscriptionInfo?.cancelTime,
    serviceFee: subscriptionInfo?.serviceFee,
    specialFee: subscriptionInfo?.specialFee,
    packingFee: subscriptionInfo?.packingFee,
    type: subscriptionInfo?.subType,
    notes: null,
    cancelDays: subscriptionInfo?.cancelDays,
    endDate: subscriptionInfo?.endDate,
    startDate: subscriptionInfo?.startDate,
  };

  const ondcPost = {
    ...ondcDbObj,
    productDisplayUnitOfMeasure: ondcInfo?.unitOfMeasure,
    netProductWeight: measurementInfo?.weight,
    ondcEnabled: categoryInfo?.ondcEnabled,

    ondc: {
      ...ondcDbObj?.ondc,
      productCode: ondcInfo?.productCode,
      fragile: ondcInfo?.fragile,
      available_on_cod: ondcInfo?.available_on_cod,

      // timeToShip: ondcInfo?.timeToShip
      //   ? `PT${ondcInfo?.timeToShip}${ondcInfo?.timeToShipUnit?.unit}`
      //   : "",

      timeToShip: ondcInfo?.timeToShip
        ? formatTimeToShip(ondcInfo?.timeToShipUnit?.unit, ondcInfo?.timeToShip)
        : "",

      cancelTerms: {
        ...ondcInfo?.cancelTerms,
        cancellable: ondcInfo?.cancelTerms?.cancellable?.toLowerCase(),

        refundEligible: ondcInfo?.cancelTerms?.refundEligible?.toLowerCase(),
        cancellationFeeAmount: null,
      },

      returnTerms: {
        ...ondcInfo?.returnTerms,
        returnable: ondcInfo?.returnTerms?.returnable?.toLowerCase(),

        returnPeriod: ondcInfo?.returnTerms?.returnPeriod
          ? `P${ondcInfo?.returnTerms?.returnPeriod}D`
          : "",

        seller_pickup_return:
          ondcInfo?.returnTerms?.seller_pickup_return?.toLowerCase(),
      },
    },
    // : { productCode: taxDetails?.productCode },
  };

  const postData = {
    profileId: profileId,
    businessId: businessId,
    productName: productInfo?.productName,
    productDescription: productInfo?.description,
    catalogType: "Product",
    // label: categoryInfo?.productSpecs || [],
    label: specsList || [],
    searchText: productDetails?.searchText,
    brand: productInfo?.brand || "",
    skuId: productInfo?.skuId || null,
    // refLink: categoryInfo?.refLinks || [],
    refLink: refLinkList || [],
    // refLink: getListToLabel(data?.ondcSpecificationData) || [],
    productPhoto: [],
    latitude: lat,
    longitude: long,
    status: productDetails?.status,
    commerceEnabled: "YES",
    hyperLocal: "Y",
    origin: productInfo?.country?.ondcCountryCode || "",
    productMainCategory: [
      {
        ...categoryInfo?.selectedBusinessCat,
        ondcCode: categoryInfo?.selectedOndcCat?.code || null,
        ondcValue: categoryInfo?.selectedOndcCat?.name || null,
        value: categoryInfo?.selectedBusinessCat?.name || null,
      },
    ],
    productSubCategory: categoryInfo?.selectedProductCat
      ? [
          {
            ...categoryInfo?.selectedProductCat,
            ondcSubCode: categoryInfo?.selectedOndcSubCat?.scCode1 || null,
            ondcSubValue:
              categoryInfo?.selectedOndcSubCat?.productSubCategory1 || null,
          },
        ]
      : [],
    productSubCategory2: categoryInfo?.selectedProductSubCat
      ? [
          {
            ...categoryInfo?.selectedProductSubCat,
            ondcSub2Code: null,
            ondcSub2Value: null,
          },
        ]
      : [],
    // commerceStartDate: availabilityInfo?.startDate,
    // commerceEndDate: availabilityInfo?.endDate,

    commerceStartDate: moment(availabilityInfo?.startDate)
      .startOf("day")
      .toISOString(),
    commerceEndDate: moment(availabilityInfo?.endDate)
      .endOf("day")
      .toISOString(),

    minOrderQuantity: availabilityInfo?.minQty,
    maxOrderQuantity: availabilityInfo?.maxQty,
    productSalePrice: priceInfo?.OfferPrice,
    productOriginalPrice: priceInfo?.originalPrice,
    subscription: subscriptionInfo?.isProductSubscribed
      ? subscriptionData
      : productDetails?.subscription,
    gstInfo: {
      code:
        `${ondcInfo?.productCode?.key}:${ondcInfo?.productCode?.value}` || "",
      gstPercentage: taxDetails?.gstPercentage || "",
    },
    measurementInfo: {
      weight: measurementInfo?.netProductWeight || "0",
      length: measurementInfo?.length || "0",
      width: measurementInfo?.width || "0",
      height: measurementInfo?.height || "0",
    },
    taxDetails: [
      {
        code: "Inclusive of taxes",
        name: "Taxes Included",
        value: priceInfo?.taxDetails,
      },
    ],
    currencySymbol: "₹",
    unitOfMeasure: "Gram",
    availability: [`${availabilityInfo?.availability}`],
    // deliveryType: measurementInfo?.deliveryMethod,
    deliveryType: ["Delivery/Fulfillment by Business"],
    shipmentDistance: productDetails?.shipmentDistance,
    preference: productDetails?.preference,
    availableQuantity: availabilityInfo?.availabelQty,
    soldQuantity: productDetails?.soldQuantity,
    alertQuantity: availabilityInfo?.alertQty,

    clientType: "business-dashboard-web",
    productId: productDetails?.id,

    ...ondcPost,
  };

  return postData;
};

export const getTimeToShipValue = (unitSymbol, timeToShip) => {
  if (unitSymbol && timeToShip) {
    if (unitSymbol === "D") {
      if (timeToShip?.includes("PT")) {
        return timeToShip?.slice(2, -1);
      }
      return timeToShip?.slice(1, -1);
    } else {
      return timeToShip?.slice(2, -1);
    }
  }
};

export const formatTimeToShip = (measurementUnit, timeToShip) => {
  if (measurementUnit === "D") return `P${timeToShip}D`;

  return `PT${timeToShip}${measurementUnit}`;
};
