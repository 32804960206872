import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import React, { useState } from "react";
import {
  blackColor,
  blackShade02,
  blackShade05,
  borderTextbox,
  descriptionColor,
  greyColor6,
  nextBtnBG,
  saveBtnBG,
  saveBtnColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import ModalCard from "../ModalCard";
import { useEffect } from "react";
import { allowOnlyEnglish } from "../../utils/helper";

function AddItemsInputWithLabel({
  label,
  placeholder,
  name,
  value,
  onChange,
  icon,
  onIconClick,
  showPassword,
  setMoreProducts,
  maxLength,
  inputType,
  // isError,
  error,
  errorMsg,
  helperText,
  autoFocus,
  inputRef,
  onKeyUp,
  onBlur,
  disabled,
  isDashboardInput,
  hideLabel,
  required,
}) {
  const [modal, setModal] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [arr, setArr] = useState([""]);
  const [prevArr, setPrevArr] = useState([""]);
  useEffect(() => {
    setArr(value?.length > 0 ? value : [""]);
    return () => {};
  }, [value]);

  return (
    <Box mb="8px !important">
      {!hideLabel ? (
        <>
          <Typography
            variant="h3"
            fontSize={13}
            color={greyColor6}
            fontWeight={500}
            mb={"8px !important"}
            sx={{ display: "inline" }}
          >
            {label}{" "}
          </Typography>
          {required ? (
            <Typography
              variant="body1"
              sx={{ display: "inline", color: "#f00" }}
            >
              *
            </Typography>
          ) : null}
        </>
      ) : null}

      <Box sx={{ position: "relative" }}>
        <TextField
          variant="standard"
          required={true}
          fullWidth
          name={name}
          disabled={disabled}
          value={value?.join(", ")}
          onBlur={onBlur}
          autoFocus={autoFocus}
          onChange={onChange}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          onClick={() => setModal(true)}
          inputRef={inputRef}
          sx={{
            fontSize: 13.5,
            fontWeight: 500,
            color: blackShade05,
            borderTop: "none",
            borderBottom: borderTextbox,
            borderLeft: "none",
            borderRight: "none",
            borderRadius: "0px",
            backgroundColor: whiteColor,
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                {icon}
              </InputAdornment>
            ),
            style: {
              padding: isDashboardInput ? "7px 12px" : "4px 8px",
              color: grey,
              fontSize: 13.5,
              fontWeight: 500,
            },
            disableUnderline: true,
          }}
          inputProps={{ maxLength: maxLength }}
          type={inputType}
        />

        <Box
          sx={{
            width: "100%",
            height: "35px",
            backgroundColor: "transparent",
            position: "absolute",
            top: 0,
          }}
          onClick={() => {
            setPrevArr(arr);
            setModal(true);
          }}
        />
      </Box>

      {error ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={12}
          sx={{ mt: "4px !important", textAlign: "start !important" }}
          style={{ color: "red" }}
        >
          {error}
        </Typography>
      ) : null}

      {helperText ? (
        <Typography
          variant="body1"
          component="p"
          fontSize={11}
          color={descriptionColor}
        >
          {helperText}
        </Typography>
      ) : null}
      <ModalCard
        open={modal}
        handleClose={() => {
          setArr(prevArr);
          setModal(false);
        }}
        width="30%"
      >
        <Box
          backgroundColor={whiteColor}
          p={{ md: 3, xs: 1 }}
          // height="40vh"
          position="relative"
          overflow="hidden"
          borderRadius={2}
        >
          <Typography
            variant="h3"
            fontSize={isDashboardInput ? 14 : 13}
            color={isDashboardInput ? blackColor : blackShade02}
            fontWeight={isDashboardInput ? 500 : 400}
            mb={isDashboardInput ? "8px !important" : "0px"}
            sx={{ display: "inline" }}
          >
            {label}{" "}
          </Typography>
          {arr.map((textInput, index) => (
            <Grid
              container
              display={"flex"}
              alignItems="center"
              justifyContent={"flex-start"}
              columnSpacing={{ md: 2, xs: 3 }}
            >
              <Grid item md={10} xs={8.5}>
                <TextField
                  variant="standard"
                  fullWidth
                  value={textInput}
                  onChange={({ target }) => {
                    const finalValue = allowOnlyEnglish(target.value)
                    if (modalError) setModalError(false);
                    let data = [...arr];
                    data[index] = finalValue;
                    setArr(data);
                  }}
                  sx={{
                    mt: 1,
                    fontSize: 14.5,
                    borderTop: isDashboardInput ? borderTextbox : "none",
                    borderBottom: borderTextbox,
                    borderLeft: isDashboardInput ? borderTextbox : "none",
                    borderRight: isDashboardInput ? borderTextbox : "none",
                    borderRadius: isDashboardInput ? "6px" : "0px",
                    backgroundColor: whiteColor,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                        {icon}
                      </InputAdornment>
                    ),
                    style: {
                      padding: isDashboardInput ? "7px 12px" : "4px 8px",
                      color: grey,
                      fontSize: 14.5,
                      fontWeight: 500,
                    },
                    disableUnderline: true,
                  }}
                  inputProps={{ maxLength: maxLength }}
                  type={inputType}
                />
              </Grid>

              {index == arr?.length - 1 ? (
                <Grid item md={1} xs={1}>
                  <IconButton
                    onClick={() => {
                      if (textInput) {
                        setArr([...arr, ""]);
                        setModalError(false);
                      } else setModalError(true);
                    }}
                  >
                    <AddRoundedIcon fontSize="large" />
                  </IconButton>
                </Grid>
              ) : null}

              <Grid item md={1} xs={1}>
                <IconButton
                  onClick={() => {
                    if (index === 0) return;
                    let data = [...arr];
                    data.splice(index, 1);
                    setArr(data);
                    setModalError(false);
                  }}
                  sx={{ opacity: index === 0 ? 0.3 : 1 }}
                >
                  <DeleteRoundedIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          {modalError ? (
            <Typography
              variant="subtitle1"
              component="p"
              fontSize={12}
              sx={{ mt: "4px !important", textAlign: "start !important" }}
              style={{ color: "red" }}
            >
              Please enter product name
            </Typography>
          ) : null}

          <Box display={"flex"} justifyContent="flex-end" mt={2} gap={1}>
            <Button
              sx={styles.btn(nextBtnBG, whiteColor)}
              onClick={() => {
                setArr(prevArr);
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              sx={styles.btn(saveBtnBG, saveBtnColor)}
              onClick={() => {
                setMoreProducts(arr);
                setModalError(false);
                setModal(false);
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </ModalCard>
    </Box>
  );
}

export default AddItemsInputWithLabel;

const styles = {
  btn: (bgColor, textcolor) => ({
    background: bgColor,
    padding: { xs: "3px 24px", md: "4px 45px" },
    fontSize: { xs: "12px", md: "14px" },
    color: textcolor,
    borderRadius: "4px",
    fontWeight: 500,
    border: "1px solid " + bgColor,
  }),
};
