import { Box, Button, Typography } from "@mui/material";
import {
  errorTextColor,
  loginPrimary,
  whiteColor,
  greyColor6,
  blackShade58,
  authInputIconColor,
  blueColor04,
  yellowColor,
  blackShade09,
} from "../../../configs/styles/muiThemes";
import { Link } from "react-router-dom";
import {
  inputError_EnterValidKey,
  resetPassword_EnterPassword,
} from "../../../configs/Constants";
import AuthTextBox from "../../../components/AuthTextBox";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { useState } from "react";
import AuthPasswordBox from "../../../components/AuthPasswordBox";
import {
  validateKey,
  validatePasswordSave,
} from "../../../utils/validationUtils";
import { finishSetup, getLoginDetials } from "../../../reducers/auth/authSlice";
import { useDispatch } from "react-redux";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

function ResetPasswordNew({ email, onForgotpasswordSuccess, phone }) {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(true);
  const [key, setKey] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErrMsg, setPasswordErrMsg] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [keyErrMsg, setKeyErrMsg] = useState("");
  const [keyErr, setKeyErr] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const symbol = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const numbers = /[0-9]/;
  const capital = /^(?=.*[A-Z])/;
  const length = /^(?=.{8,})/;

  const maskMail = (mail) => {
    let str = mail;
    str = str.split("");
    let finalArr = [];
    let len = str.indexOf("@");
    str.forEach((item, pos) => {
      pos >= 2 && pos <= len - 3 ? finalArr.push("*") : finalArr.push(str[pos]);
    });
    return finalArr.join("");
  };

  const handleSubmit = () => {
    console.log(key, password);
    setPasswordErrMsg("");
    setPasswordErr(false);
    setKeyErrMsg("");
    setErrorMsg(false);
    setLoading(true);

    const keyMsg = validateKey(key);
    const passMsg = validatePasswordSave(password);

    console.log(passMsg);
    if (keyMsg != "") {
      setKeyErr(true);
      setKeyErrMsg(keyMsg);
      setLoading(false);
      return;
    }

    if (passMsg != "") {
      setPasswordErrMsg(passMsg);
      setPasswordErr(true);
      setLoading(false);
      return;
    }
    resetPassword();
  };
  const resetPassword = async () => {
    return dispatch(finishSetup({ key, password })).then((response) => {
      console.log(response);
      if (response.payload) {
        if (response.payload.status === 200) {
          const data = {
            phone: phone,
            password: password,
          };
          loginDetails(data);
        }
      } else {
        console.log();
        setErrorMsg(true);
        setLoading(false);
      }
    });
  };

  const loginDetails = async (data) => {
    return dispatch(getLoginDetials(data)).then((response) => {
      console.log(response);
      if (response.payload) {
        if (response.payload.status === 200) {
          onForgotpasswordSuccess();
          setLoading(false);
        }
      } else {
        setErrorMsg(true);
        setLoading(false);
      }
    });
  };

  // function validate() {
  //   setPasswordErrMsg('')
  //   setKeyErrMsg('')

  //   const keyMsg = validateKey(key)
  //   const passMsg = validatePasswordSave(password)

  //   setPasswordErr(passMsg)
  //   setKeyErrMsg(keyMsg)
  //   // setErrorMsg(false)
  //   // setErrorMsgForPassword(false)
  //   // const temp = { ...errors }
  //   // console.log(fieldValues)

  //   // if ('key' in fieldValues) {
  //   //   temp.key = validateKey(fieldValues.key)
  //   //   console.log(temp.key)
  //   // }

  //   // if ('password' in fieldValues) {
  //   //   temp.password = validatePasswordSave(fieldValues.password)
  //   //   console.log(temp.password)
  //   // }
  //   // console.log(fieldValues)
  //   // console.log(temp)

  //   // setErrors({
  //   //   ...temp,
  //   // })
  //   // if (fieldValues === values)
  //   //   return Object.values(temp).every((x) => x === '')
  // }
  return (
    <Box>
      <Typography
        variant="h4"
        color={blackShade58}
        fontSize={{ xs: 17, md: 22 }}
        fontWeight={600}
        lineHeight="30px"
        m="0px 0 15px !important"
      >
        Reset your password
      </Typography>

      <Typography
        variant="body1"
        color={greyColor6}
        fontSize={{ xs: 13, md: 14 }}
        fontWeight={400}
        lineHeight="18px"
        m="0px 0 8px !important"
      >
        Password reset key has been sent to
      </Typography>

      <Typography
        variant="body1"
        fontSize={{ xs: 13, md: 13 }}
        fontWeight={500}
        color={blueColor04}
        m="0px 0 18px !important"
      >
        {maskMail(email)}
      </Typography>

      <Typography
        variant="body1"
        color={greyColor6}
        fontSize={13}
        fontWeight={400}
        lineHeight="18px"
        mb="0px !important"
      >
        Reset Key From Your Email{" "}
        <Typography component="span" color="red" fontSize={13} fontWeight={400}>
          *
        </Typography>
      </Typography>

      <AuthTextBox
        value={key}
        // placeholder={resetPassword_EnterKey}
        icon={
          <VpnKeyIcon sx={{ fontSize: "20px", color: authInputIconColor }} />
        }
        isError={keyErr}
        errorMsg={keyErrMsg}
        onChange={(e) => {
          if (keyErr) setKeyErr(false);
          if (errorMsg) setErrorMsg(false);
          setKey(e.target.value);
        }}
        name="key"
        autoFocus={true}
      />
      {errorMsg && (
        <Box>
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={13}
            color={errorTextColor}
          >
            {inputError_EnterValidKey}
          </Typography>
        </Box>
      )}

      <Typography
        variant="body1"
        color={greyColor6}
        fontSize={13}
        fontWeight={400}
        lineHeight="18px"
        m="10px 0 0px !important"
      >
        Enter New Password{" "}
        <Typography
          component="span"
          color="red"
          fontSize={13.5}
          fontWeight={400}
        >
          *
        </Typography>
      </Typography>

      <AuthPasswordBox
        value={password}
        onChange={(e) => {
          if (passwordErr) setPasswordErr(false);
          const value = e.target.value.replace(/[ ]/g, "");
          setPassword(value);
        }}
        name="password"
        placeholder={resetPassword_EnterPassword}
        isError={passwordErr}
        errorMsg={passwordErrMsg}
        lower={length.test(password)}
        symbol={symbol.test(password.trim())}
        upper={capital.test(password)}
        numberFormat={numbers.test(password)}
        icon={
          <VisibilityRoundedIcon
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            sx={{
              fontSize: "20px",
              color: !showPassword ? loginPrimary : authInputIconColor,
            }}
          />
        }
        inputType={!showPassword ? "text" : "password"}
        isPasswordError={passwordErr}
        maxLength={25}
      />

      <Box sx={{ mt: 1.5 }}>
        <Typography
          variant="body1"
          component="p"
          color={greyColor6}
          fontSize={13}
          display="inline"
        >
          By continuing, you agree to our{" "}
          <Typography
            color={loginPrimary}
            display="inline"
            fontSize={13}
            fontWeight={500}
            component={Link}
            // target='_blank'
            to={`/terms-conditions`}
          >
            Terms of Use
          </Typography>{" "}
          &{" "}
          <Typography
            color={loginPrimary}
            display="inline"
            fontSize={13}
            fontWeight={500}
            component={Link}
            // target='_blank'
            to={`/privacy-policy`}
          >
            Privacy Policy
          </Typography>
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          sx={{
            ...styles.actionBtn(),
            border: loading ? "none" : `1px solid ${yellowColor}`,
          }}
          variant="contained"
          disableElevation
          onClick={handleSubmit}
          disabled={loading}
        >
          Reset & Login
        </Button>
      </Box>
    </Box>
  );
}

export default ResetPasswordNew;

const styles = {
  actionBtn: () => ({
    minWidth: { md: "115px", xs: "auto" },
    fontSize: { md: "14px", xs: "13px" },
    fontWeight: 600,
    color: blackShade09,
    background: yellowColor,
    p: { md: "5px 22px", xs: "4px 12px" },
    mt: "20px !important",
    borderRadius: "6px",

    "&:hover": {
      background: "none",
      backgroundColor: whiteColor,
      color: blackShade09,
      border: `1px solid ${blackShade09}`,
    },
    letterSpacing: "0.5px",
  }),
};
