import { Box } from "@mui/material";
import React from "react";
import EmptyBusinessListCard from "./EmptyBusinessListCard";
import { greyColor84 } from "../../configs/styles/muiThemes";
import SendRequestForm from "./SendRequestForm";

function EmptyBusinessListContent() {
  return (
    <Box
      sx={{
        width: { md: "56%", xs: "100%" },
        py: 4,
      }}
    >
      <EmptyBusinessListCard />
      <Box
        sx={{
          width: "100%",
          height: "2px",
          background: greyColor84,
          position: "relative",
          my: { xs: 4, md: 7 },
        }}
      >
        <Box
          sx={{
            width: "40px",
            height: "40px",
            background: greyColor84,
            borderRadius: "50%",
            fontSize: "14px",
            fontWeight: "600",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "-20px",
            right: "48%",
          }}
        >
          OR
        </Box>
      </Box>
      <SendRequestForm />
    </Box>
  );
}

export default EmptyBusinessListContent;
