import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllBusinessCouponsApi,
  getBusinessFollowersApi,
  getBusinessReviewSummaryApi,
  getDashboardAffiliateSaleInfoApi,
  getDashboardAffiliateShareCountApi,
  getDashboardCashbackCountApi,
  getDashboardCashbackSummaryApi,
  getDashboardInventorySummaryApi,
  getDashboardOrderSummaryApi,
  getDashboardPaycartSummaryApi,
  getDashboardPreferredDiscountCountApi,
  getDashboardPreferredDiscountSummaryApi,
  getDashboardUniqueAffiliateCountApi,
  getViewBusinessActivityApi,
  viewAllOrderByBusinessApi,
} from "../../service/api_calls/BusinessDashboard/BusinessDashboardApi";
import { getProductsByBusinessAPI } from "../../service/api_calls/BusinessPreview/BusinessPreviewAPI";
import { getCashBackValueForBusinessApi } from "../../service/api_calls/cashback/CashbackApi";
import { getAllCouponsCountApi, getAllCouponsSummaryApi } from "../../service/api_calls/coupons/couponsApi";

const initialState = {
  dashboardData: null,
  statsCountInfo: null,

  dashboardDataLoader: false,
  statsCountInfoLoader: false
};

//Fetch all Business Dashboard data
export const getBusinessDashboardData = createAsyncThunk(
  "getBusinessDashboardData",
  async (param, thunkAPI) => {
    // const orderSummary = await getDashboardOrderSummaryApi(param?.businessId);
    // const inventorySummary = await getDashboardInventorySummaryApi(param?.businessId);
    // const allCoupons = await getAllBusinessCouponsApi(param);
    // const affiliateSaleInfo = await getDashboardAffiliateSaleInfoApi(param?.businessId);
    // const uniqueAffiliateCount = await getDashboardUniqueAffiliateCountApi(param?.businessId);
    // const affiliateShareCount = await getDashboardAffiliateShareCountApi(param?.businessId);

    // const businessViews = await getViewBusinessActivityApi(param);
    // const followers = await getBusinessFollowersApi(param);
    // const reviewSummary = await getBusinessReviewSummaryApi(param);

    // const paycartSummary = await getDashboardPaycartSummaryApi(param?.businessId);

    // const preferredDiscountSummary = await getDashboardPreferredDiscountSummaryApi(param?.businessId);
    // const preferredDiscountCount = await getDashboardPreferredDiscountCountApi(param?.businessId);

    // const cashbackSummary = await getDashboardCashbackSummaryApi(param?.businessId);
    // const cashbackCount = await getDashboardCashbackCountApi(param?.businessId);

    // return {
    //   orderSummary,
    //   inventorySummary,
    //   allCoupons,
    //   affiliateSaleInfo,
    //   uniqueAffiliateCount,
    //   affiliateShareCount,

    //   businessViews,
    //   followers,
    //   reviewSummary: reviewSummary?.reviewSummary || null,
    //   paycartSummary,

    //   preferredDiscountSummary,
    //   preferredDiscountCount,

    //   cashbackSummary,
    //   cashbackCount
    // };

    try {
      const apiCalls = [
        getDashboardOrderSummaryApi(param?.businessId),
        getDashboardInventorySummaryApi(param?.businessId),
        getAllBusinessCouponsApi(param),
        getAllCouponsSummaryApi({businessId: param?.businessId}),
        getAllCouponsCountApi({businessId: param?.businessId}),
        getDashboardAffiliateSaleInfoApi(param?.businessId),
        getDashboardUniqueAffiliateCountApi(param?.businessId),
        getDashboardAffiliateShareCountApi(param?.businessId),
        getViewBusinessActivityApi(param),
        getBusinessFollowersApi(param),
        getBusinessReviewSummaryApi(param),
        getDashboardPaycartSummaryApi(param?.businessId),
        getDashboardPreferredDiscountSummaryApi(param?.businessId),
        getDashboardPreferredDiscountCountApi(param?.businessId),
        getDashboardCashbackSummaryApi(param?.businessId),
        getDashboardCashbackCountApi(param?.businessId),
        getCashBackValueForBusinessApi({businessId: param?.businessId})
      ];

      const [
        orderSummary,
        inventorySummary,
        allCoupons,
        couponSummary,
        couponCounts,
        affiliateSaleInfo,
        uniqueAffiliateCount,
        affiliateShareCount,
        businessViews,
        followers,
        reviewSummary,
        paycartSummary,
        preferredDiscountSummary,
        preferredDiscountCount,
        cashbackSummary,
        cashbackCount,
        availabelCashbackValue,
      ] = await Promise.all(apiCalls);

      return {
        orderSummary: orderSummary || null,
        inventorySummary: inventorySummary || null,
        allCoupons: allCoupons || null,
        couponSummary: couponSummary || null,
        couponCounts: couponCounts || null,

        affiliateSaleInfo: affiliateSaleInfo || null,
        uniqueAffiliateCount: uniqueAffiliateCount || null,
        affiliateShareCount: affiliateShareCount || null,
        businessViews: businessViews || null,
        followers: followers || null,
        reviewSummary: reviewSummary?.reviewSummary || null,
        paycartSummary: paycartSummary || null,
        preferredDiscountSummary: preferredDiscountSummary || null,
        preferredDiscountCount: preferredDiscountCount || null,
        cashbackSummary: cashbackSummary || null,
        cashbackCount: cashbackCount || null,
        availabelCashbackValue: availabelCashbackValue || null
      };
    } catch (error) {
      return null;
    }
  }
);

//Fetch Count Status
export const getStatsCountInfoData = createAsyncThunk(
  "getStatsCountInfoData",
  async (businessId, thunkAPI) => {

    try {
      const apiCalls = [
        getDashboardOrderSummaryApi(businessId),
        getDashboardInventorySummaryApi(businessId),
      ];

      const [orderCountInfo, inventoryCountInfo] = await Promise.all(apiCalls);

      return {
        orderCountInfo: orderCountInfo || null,
        inventoryCountInfo: inventoryCountInfo || null,
      };
    } catch (error) {
      return null;
    }
  }
);

export const businessDashboardSlice = createSlice({
  name: "businessDashboard",
  initialState,
  reducers: {
    clearBusinessDashboardData: (state, action) => {
      state.dashboardData = null;
      state.statsCountInfo = null;
    },
  },
  extraReducers: {
    [getBusinessDashboardData.pending]: (state) => {
      state.dashboardData = null;
      state.dashboardDataLoader = true;
    },
    [getBusinessDashboardData.fulfilled]: (state, { payload }) => {
      state.dashboardDataLoader = false;
      state.dashboardData = payload;
    },
    [getBusinessDashboardData.rejected]: (state, { payload }) => {
      state.dashboardData = null;
      state.dashboardDataLoader = false;
    },
    
    // Get Stats Count Info
    [getStatsCountInfoData.pending]: (state) => {
      state.statsCountInfo = null;
      state.statsCountInfoLoader = true;
    },
    [getStatsCountInfoData.fulfilled]: (state, { payload }) => {
      state.statsCountInfoLoader = false;
      state.statsCountInfo = payload;
    },
    [getStatsCountInfoData.rejected]: (state, { payload }) => {
      state.statsCountInfo = null;
      state.statsCountInfoLoader = false;
    },
  },
});

export const { clearBusinessDashboardData } = businessDashboardSlice.actions;
export const businessDashboardReducer = businessDashboardSlice.reducer;
