import { Box, Typography } from "@mui/material";
import React from "react";
import ErrorToolTipIcon from "./ErrorToolTipIcon";
import { blackColor } from "../../../configs/styles/muiThemes";

const CellDataPreview = ({ value, errorMsg }) => {
  return (
    <Box position="relative" minWidth='150px'>
      {errorMsg && (
        <Box sx={{ position: "absolute", right: -26, top: -22 }}>
          <ErrorToolTipIcon errorMsg={errorMsg} />
        </Box>
      )}

      <Typography fontSize={12} fontWeight={400} color={blackColor}>{value}</Typography>
    </Box>
  );
};

export default CellDataPreview;
