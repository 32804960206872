import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import {
  bgColor15,
  blackShade19,
  blueColor04,
  greyColor40,
  greyColor41,
  greyColor8,
  heroSectionText,
  oldPriceColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { textOneLines } from "../../../utils/styles";
import { getColorChip, getOrderStatusText } from "../../../utils/helper";
import { calculatePercentageOff } from "../../../utils/validationHelper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";

const SubBranchProductInfoRowCard = ({
  productImg,
  productName,
  originalPrice,
  salePrice,
  importDate,
  ondcEnabled,
  onViewClick,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        bgcolor: whiteColor,
        padding: "12px 0",
        borderBottom: `1px solid ${greyColor41}`,
        borderRadius: { xs: "6px", md: "0px" },
        mb: { xs: 1.5, md: 0 },
      }}
    >
      <Grid container rowSpacing={{ xs: 1, md: 0 }}>
        <Grid item xs={1.85} md={1}>
          <Box
            display="flex"
            alignItems="flex-start"
            gap={{ xs: 1.5, md: 2 }}
            pl={2}
          >
            <Box sx={styles.imgBox} onClick={onViewClick}>
              <Box component="img" src={productImg} sx={styles.imgContainer} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={10.15} md={4.5}>
          <Box pl={2}>
            <Typography
              sx={{
                ...textOneLines,
                fontSize: 12.5,
                fontWeight: 600,
                color: blackShade19,
                cursor:'pointer'
              }}
              onClick={onViewClick}
            >
              {productName}
            </Typography>

            {!matches && (
              <PriceInfo originalPrice={originalPrice} salePrice={salePrice} />
            )}
          </Box>
        </Grid>

        {matches && (
          <Grid item xs={12} md={2}>
            <PriceInfo originalPrice={originalPrice} salePrice={salePrice} />
          </Grid>
        )}

        {matches && (
          <Grid item xs={12} md={1.4}>
            <Box
              display="flex"
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={{ xs: "flex-start", md: "center" }}
              // pl={2}
            >
              <Box
                sx={styles.statusChip(
                  getColorChip(ondcEnabled === "yes" ? "yes" : "no")
                )}
              >
                {getOrderStatusText(ondcEnabled === "yes" ? "yes" : "no")}
              </Box>
            </Box>
          </Grid>
        )}

        {!matches && (
          <Grid item xs={6}>
            <Typography
              variant="body1"
              color={bgColor15}
              fontSize={12}
              lineHeight="20px"
              fontWeight={400}
              display="inline"
              pl={2}
            >
              {`ONDC Enabled: `}{" "}
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: ondcEnabled === "yes" ? "#27bd72" : "#e53935",
                  marginLeft: "6px",
                }}
              >
                {ondcEnabled === "yes" ? "Yes" : "No"}
              </span>
            </Typography>
          </Grid>
        )}

        <Grid item xs={6} md={1.6}>
          <Box ml={2}>
            <Typography
              sx={{
                fontSize: { xs: 11, md: 12 },
                fontWeight: 500,
                color: greyColor40,
              }}
            >
              {importDate ? moment(importDate)?.format('Do MMM YYYY, hh:mm A') : "N/A"}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={1.5}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={{ xs: "flex-end", md: "flex-start" }}
            p={{ xs: "0 16px 0 0", md: "0 0 0 36px" }}
          >
            <IconButton onClick={onViewClick} sx={styles.actionBtn}>
              <RemoveRedEyeIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SubBranchProductInfoRowCard;

const PriceInfo = ({ originalPrice, salePrice }) => {
  return (
    <Box display={"flex"} flexDirection={"row"} gap={1} pl={{ xs: 0, md: 2 }}>
      <Typography variant="body1" sx={styles.originalPriceText}>
        {originalPrice === salePrice ? "" : `₹${originalPrice}`}
      </Typography>

      <Typography variant="body1" sx={styles.salePriceText}>
        ₹{salePrice}
      </Typography>

      <Box sx={styles.statusBoxChip(getColorChip("processing"))}>
        <Typography
          variant="body1"
          color={heroSectionText}
          fontSize="12px"
          fontWeight={600}
          m="6px 0 0 0px !important"
          sx={{ display: "inline" }}
        >
          {Math.round(calculatePercentageOff(originalPrice, salePrice))}%
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  checkbox: {
    p: 0,
    transform: "scale(1.3)",
    "&.Mui-checked": {
      color: greyColor8,
    },
  },

  imgBox: {
    borderRadius: "4px",
    border: "1px solid #e5e9f0",
    p: "5px",
    cursor:'pointer'
  },

  imgContainer: {
    backgroundColor: whiteColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: { xs: "25px", md: "35px !important" },
    height: { xs: "25px", md: "35px !important" },
    objectFit: "contain",
  },

  ondcStatusTag: {
    width: "55px",
    display: { xs: "inline", md: "flex" },
    alignItems: "center",
    justifyContent: "center",
    fontSize: { xs: 13, md: 12 },
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "50px",
    p: { xs: "0 8px", md: "1px 13px" },
  },

  statusChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    p: "5px 13px",
    borderRadius: "50px",
    width: "max-content",
  }),

  statusBoxChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    p: "2px 4px",
    borderRadius: "4px",
    width: "max-content",
  }),

  originalPriceText: {
    color: oldPriceColor,
    fontSize: 12,
    lineHeight: "15px",
    fontWeight: 400,
    mt: { xs: "6px !important", md: "7px !important" },
    textDecoration: "line-through",
    display: "inline",
  },

  salePriceText: {
    display: "inline",
    color: blueColor04,
    fontSize: 13,
    lineHeight: "15px",
    fontWeight: 600,
    m: { xs: "6px", md: "6px 0 0 0px" },
    mr: 1,
  },

  actionBtn: {
    width: "33px",
    height: "27px",
    fontSize: 12,
    fontWeight: 600,
    padding: "3px 15px",
    textTransform: "unset",
    border: `1px solid ${bgColor15}`,
    borderRadius: "4px",
    color: bgColor15,
    mt: { xs: 0, md: 1.2 },
    "&:hover": {
      color: whiteColor,
      backgroundColor: bgColor15,
    },
    "&.Mui-disabled": {
      borderColor: "rgba(0,0,0,0.2)",
    },
  },
};
