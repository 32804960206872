import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import {
  bgColor15,
  blackShade05,
  blackShade19,
  blueColor04,
  blueShade10,
  border25,
  copyBtnColor,
  greenShade11,
  greyColor8,
  mobilePrimary,
  orangeShade10,
  orangeStatusColor,
  orderCardBorder,
  orderCardBorder2,
  orderCardBorder3,
  outOfStockColor,
  perpleShade10,
  voiletShade01,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HorizontalStepper from "../../../components/HorizontalStepper";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { addThousandsSeparator } from "../../../utils/helper";
import { textOneLines } from "../../../utils/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PinDropIcon from "@mui/icons-material/PinDrop";
import DownloadIcon from "@mui/icons-material/Download";

function AllOrderCard({
  id,
  ondcOrderId,
  date,
  username,
  orderCost,
  city,
  addressName,
  addressCity,
  cartCount,
  trackingStatus,
  statusUpdatedDate,
  handleTrackOrder,
  downloadShipingLabel,
  handleViewOrder,
  orderType,
  getOndcBapNameBySubscriberId,
  orderData,
  index,
  deliveryType,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const getTrackingStatus = (status) => {
    switch (status) {
      case "ordercreated":
        return "Created";
      case "orderapproved":
        return "Approved";
      case "ordershipped":
        return "Shipped";
      case "orderdelivered":
        return "Delivered";
      case "bordercanceled":
        return "Canceled";
      case "cordercanceled":
        return "Canceled";

      default:
        return "";
    }
  };

  const STEPS = [
    {
      label: "Created",
    },
    {
      label: "Approved",
    },
    {
      label: "Shipped",
    },
    {
      label: "Delivered",
    },
  ];

  const getOrderStatusStep = (status) => {
    switch (status) {
      case "ordercreated":
        return 0;
      case "orderapproved":
        return 1;
      case "ordershipped":
        return 2;
      case "orderdelivered":
        return 3;

      default:
        return -1;
    }
  };

  const getOrderStatusText = (status) => {
    switch (status) {
      case "ordercreated":
        return "Pending";
      case "orderapproved":
        return "Ready to Ship";
      case "ordershipped":
        return "Shipped";
      case "orderdelivered":
        return "Completed";
      case "bordercanceled":
        return "Canceled";
      case "cordercanceled":
        return "Canceled";

      default:
        return "";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "ordercreated":
        return orangeStatusColor;
      case "orderapproved":
        return blackShade05;
      case "ordershipped":
        return copyBtnColor;
      case "orderdelivered":
        return copyBtnColor;
      case "bordercanceled":
        return outOfStockColor;
      case "cordercanceled":
        return outOfStockColor;

      default:
        return blackShade05;
    }
  };

  return (
    <Box sx={styles.card} mt={{ xs: index === 0 ? 2 : 0, md: 0 }}>
      {matches ? (
        <Grid container spacing={2}>
          <Grid item xs={6} md={3.2}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "column" }}
              justifyContent={{ xs: "space-between", md: "flex-start" }}
            >
              <Typography
                fontSize={13}
                fontWeight={600}
                color={blackShade05}
                lineHeight="18px"
                sx={{ textDecoration: "underline" }}
              >
                # {id}
              </Typography>

              <Typography
                variant="p"
                fontSize={11.5}
                fontWeight={500}
                color={bgColor15}
                lineHeight="20px"
              >
                {date}
              </Typography>

              <Typography
                variant="p"
                fontSize={12}
                fontWeight={600}
                color={orderType?.color}
                lineHeight="18px"
                mt="2px"
              >
                {orderType?.label}{" "}
                {orderType?.orderType === "ondcOrder" ? (
                  <span style={{ color: blackShade05 }}>
                    {" "}
                    - {getOndcBapNameBySubscriberId()}
                  </span>
                ) : null}
              </Typography>

              {orderType?.orderType === "ondcOrder" && (
                <Typography
                  variant="p"
                  fontSize={11.5}
                  fontWeight={600}
                  color={orderType?.color}
                  lineHeight="18px"
                  sx={{ ...textOneLines, lineBreak: "anywhere" }}
                >
                  # {ondcOrderId}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={6} md={3}>
            <InfoBox
              titleIcon={<PersonIcon sx={{ fontSize: "17px" }} />}
              title={
                username?.toLowerCase()?.trim() === "guest"
                  ? addressName
                  : username
              }
              subtitleIcon={
                <PlaceIcon sx={{ fontSize: "15px", color: bgColor15 }} />
              }
              subtitle={addressCity || "N/A"}
              alignRight
            />
            <Stack flexDirection="row" alignItems="center" gap={3} mt={0.8}>
              <Box display="flex" gap="10px">
                <CreditCardIcon sx={{ fontSize: "17px" }} />

                <Typography
                  variant="h5"
                  fontSize={12.5}
                  fontWeight={600}
                  color={blackShade05}
                  lineHeight="20px"
                  sx={{ ...textOneLines }}
                >
                  {`₹ ${addThousandsSeparator(orderCost)}`}
                </Typography>
              </Box>

              <Box display="flex" gap="8px">
                <ShoppingCartIcon sx={{ fontSize: "15px", mt: 0.3 }} />

                <Typography
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade05}
                  lineHeight="20px"
                  sx={{ ...textOneLines }}
                >
                  {cartCount}
                </Typography>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={6} md={2.1}>
            <Box sx={styles.infoTab}>
              <Box display="flex" alignItems="center" gap="10px">
                <Typography
                  variant="h5"
                  fontSize={12}
                  fontWeight={500}
                  color={getStatusColor(trackingStatus)}
                  lineHeight="20px"
                >
                  {getOrderStatusText(trackingStatus)} - (
                  {getTrackingStatus(trackingStatus)})
                </Typography>
              </Box>

              <Typography
                variant="p"
                fontSize={12}
                fontWeight={500}
                color={bgColor15}
                lineHeight="18px !important"
                mt={1}
                // ml={2}
              >
                {statusUpdatedDate}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} md={3.6}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={{ xs: "flex-end", md: "flex-start" }}
              columnGap={"5px"}
              rowGap={"10px"}
              flexWrap={"wrap"}
            >
              <Button
                sx={styles.actionBtn(blueShade10)}
                disableElevation
                onClick={handleViewOrder}
              >
                View
              </Button>

              {trackingStatus !== "bordercanceled" &&
                trackingStatus !== "cordercanceled" &&
                trackingStatus !== "ordercreated" && (
                  <Button
                    sx={styles.actionBtn(perpleShade10)}
                    disableElevation
                    onClick={handleTrackOrder}
                  >
                    Tracking
                  </Button>
                )}

              {deliveryType === "Automated Delivery" &&
              trackingStatus !== "ordercreated" ? (
                <>
                  {orderData?.invoiceUrl && (
                    <a
                      href={orderData?.invoiceUrl}
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        sx={styles.actionBtn(orangeShade10)}
                        onClick={() => {}}
                        startIcon={
                          <DownloadIcon sx={{ fontSize: "15px !important" }} />
                        }
                      >
                        Invoice
                      </Button>
                    </a>
                  )}
                  {trackingStatus !== "orderdelivered" && (
                    <Button
                      sx={styles.actionBtn(greenShade11)}
                      onClick={() => downloadShipingLabel(orderData)}
                      startIcon={
                        <DownloadIcon sx={{ fontSize: "15px !important" }} />
                      }
                    >
                      Shipping Label
                    </Button>
                  )}
                </>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack borderBottom={border25} pb={1.2}>
              <Box display="flex" justifyContent="space-between" gap="10px">
                <Stack>
                  <Typography
                    fontSize={13}
                    fontWeight={600}
                    color={blackShade05}
                    lineHeight="18px"
                    sx={{ textDecoration: "underline" }}
                  >
                    # {id}
                  </Typography>

                  <Typography
                    variant="p"
                    fontSize={12}
                    fontWeight={500}
                    color={bgColor15}
                    lineHeight="20px"
                  >
                    {date}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    variant="h5"
                    fontSize={12}
                    fontWeight={600}
                    color={getStatusColor(trackingStatus)}
                    lineHeight="20px"
                  >
                    {getOrderStatusText(trackingStatus)} - (
                    {getTrackingStatus(trackingStatus)})
                  </Typography>

                  <Typography
                    variant="p"
                    fontSize={12}
                    fontWeight={500}
                    color={bgColor15}
                    textAlign={"end"}
                    lineHeight="20px"
                  >
                    {statusUpdatedDate}
                  </Typography>
                </Stack>
              </Box>
              <Typography
                variant="p"
                fontSize={12}
                fontWeight={600}
                color={orderType?.color}
                lineHeight="18px"
                mt="2px"
              >
                {orderType?.label}{" "}
                {orderType?.orderType === "ondcOrder" ? (
                  <span style={{ color: blackShade05 }}>
                    {" "}
                    - {getOndcBapNameBySubscriberId()}
                  </span>
                ) : null}
              </Typography>

              {orderType?.orderType === "ondcOrder" && (
                <Typography
                  variant="p"
                  fontSize={11.5}
                  fontWeight={600}
                  color={orderType?.color}
                  lineHeight="18px"
                  sx={{ ...textOneLines, lineBreak: "anywhere" }}
                >
                  # {ondcOrderId}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" gap={2} borderBottom={border25} pb={1.2}>
              <Stack flex={1}>
              <Typography
                  fontSize={13}
                  fontWeight={600}
                  color={blackShade05}
                  title={
                    username?.toLowerCase()?.trim() === "guest"
                      ? addressName
                      : username
                  }
                  lineHeight="18px"
                  sx={textOneLines}
                >
                  {username?.toLowerCase()?.trim() === "guest"
                    ? addressName
                    : username}
                </Typography>

                <Typography
                  variant="p"
                  fontSize={12}
                  fontWeight={500}
                  color={bgColor15}
                  lineHeight="20px"
                >
                  {addressCity || "N/A"}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  fontSize={13}
                  fontWeight={600}
                  color={blackShade05}
                  lineHeight="18px"
                >
                  {`₹ ${addThousandsSeparator(orderCost)}`}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap={1}
                >
                  <Typography
                    variant="p"
                    fontSize={12}
                    fontWeight={500}
                    color={bgColor15}
                    lineHeight="20px"
                  >
                    Qty
                  </Typography>

                  <Box
                    sx={{ ...styles.legendDot, backgroundColor: bgColor15 }}
                  />
                  <Typography
                    variant="p"
                    fontSize={12}
                    fontWeight={500}
                    color={bgColor15}
                    lineHeight="20px"
                  >
                    {cartCount}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              columnGap={"5px"}
              rowGap={"10px"}
              flexWrap={"wrap"}
            >
              <Button
                sx={styles.actionBtn(blueShade10)}
                disableElevation
                onClick={handleViewOrder}
              >
                View
              </Button>

              {trackingStatus !== "bordercanceled" &&
                trackingStatus !== "cordercanceled" &&
                trackingStatus !== "ordercreated" && (
                  <Button
                    sx={styles.actionBtn(perpleShade10)}
                    disableElevation
                    onClick={handleTrackOrder}
                  >
                    Tracking
                  </Button>
                )}

              {deliveryType === "Automated Delivery" &&
              trackingStatus !== "ordercreated" ? (
                <>
                  {orderData?.invoiceUrl && (
                    <a
                      href={orderData?.invoiceUrl}
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        sx={styles.actionBtn(orangeShade10)}
                        onClick={() => {}}
                        startIcon={
                          <DownloadIcon sx={{ fontSize: "15px !important" }} />
                        }
                      >
                        Invoice
                      </Button>
                    </a>
                  )}
                  {trackingStatus !== "orderdelivered" && (
                    <Button
                      sx={styles.actionBtn(greenShade11)}
                      onClick={() => downloadShipingLabel(orderData)}
                      startIcon={
                        <DownloadIcon sx={{ fontSize: "15px !important" }} />
                      }
                    >
                      Shipping Label
                    </Button>
                  )}
                </>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default AllOrderCard;

const InfoBox = ({
  titleIcon,
  title,
  subtitleIcon,
  subtitle,
  showCartCount,
  cartCount,
  isCityInfo,
  alignRight,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "column" }}
      justifyContent={{ xs: "space-between", md: "flex-start" }}
      alignItems={{
        md: "flex-start",
        xs: alignRight ? "flex-end" : "flex-start",
      }}
      flex={1}
    >
      <Box display="flex" alignItems="center" gap="10px">
        {matches ? titleIcon : null}

        <Typography
          variant="h5"
          fontSize={13}
          fontWeight={600}
          color={blackShade05}
          lineHeight="20px"
          sx={{ ...textOneLines }}
        >
          {title}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" gap="10px">
        <Box display="flex" alignItems="center" gap="10px" mt="6px">
          {matches ? subtitleIcon : null}

          <Typography
            variant="p"
            fontSize={12.5}
            fontWeight={400}
            color={bgColor15}
            lineHeight="18px !important"
            ml={`${subtitleIcon ? 6 : 30}px !important`}
            sx={{
              maxWidth: isCityInfo ? "95px" : "auto",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {subtitle}
          </Typography>
        </Box>

        {showCartCount && (
          // matches &&
          <Box display="flex" alignItems="center" gap="10px" mt="6px">
            <ShoppingCartIcon sx={{ fontSize: "16px", color: bgColor15 }} />

            <Typography
              variant="p"
              fontSize={13}
              fontWeight={400}
              color={bgColor15}
              lineHeight="18px !important"
            >
              {cartCount}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const MobileInfoBox = ({
  titleIcon,
  title,
  subtitleIcon,
  subtitleOne,
  subtitleTwo,
  alignRight,
  marginleft,
  dotColor,
  subtitleIconTwo,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "column" }}
      justifyContent={{ xs: "space-between", md: "flex-start" }}
      alignItems={{
        md: "flex-start",
        xs: alignRight ? "flex-end" : "flex-start",
      }}
      flex={1}
    >
      <Box display="flex" alignItems="center" gap="10px">
        {matches ? titleIcon : null}

        <Typography
          variant="h5"
          fontSize={13}
          fontWeight={500}
          color={blackShade05}
          lineHeight="20px"
          sx={{ ...textOneLines }}
        >
          {title}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" gap="10px">
        <Box display="flex" alignItems="center" gap="10px" mt="6px">
          {matches ? subtitleIcon : null}

          <Typography
            variant="p"
            fontSize={12.5}
            fontWeight={400}
            color={bgColor15}
            lineHeight="18px !important"
            ml={marginleft ? "" : `${subtitleIcon ? 6 : 30}px !important`}
          >
            {subtitleOne}
          </Typography>
          {subtitleIconTwo ? (
            subtitleIconTwo
          ) : (
            <Box sx={{ ...styles.legendDot, backgroundColor: dotColor }} />
          )}
          <Typography
            variant="p"
            fontSize={12.5}
            fontWeight={400}
            color={bgColor15}
            lineHeight="18px !important"
            ml={marginleft ? "" : `${subtitleIcon ? 6 : 30}px !important`}
          >
            {subtitleTwo}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  card: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "16px", md: "20px" },
    backgroundColor: whiteColor,
    border: { md: orderCardBorder, xs: border25 },
    borderRadius: { md: "0px", xs: "10px" },
    boxShadow: "0 2px 4px rgb(179 188 198 / 5%)",
    padding: { md: "15px", xs: "12px" },
    mb: { md: 0, xs: 2 },
  },

  dotUi: {
    width: "6px",
    height: "6px",
    borderRadius: "3px",
    backgroundColor: copyBtnColor,
  },

  addIconBtn: (color) => ({
    fontSize: "12px",
    fontWeight: 500,
    color: color || bgColor15,
    backgroundColor: whiteColor,
    border: "1px solid " + color || bgColor15,
    padding: { md: "4px 6px !important", xs: "3px 6px" },
    borderRadius: "4px",
    whiteSpace: "nowrap",
    "&:hover": {
      color: whiteColor,
      backgroundColor: color || bgColor15,
    },
  }),

  trackBtn: {
    color: whiteColor,
    backgroundColor: { md: blueColor04, xs: mobilePrimary },
    "&:hover": {
      color: { md: blueColor04, xs: mobilePrimary },
      backgroundColor: whiteColor,
    },
    border: {
      xs: `1px solid ${mobilePrimary}`,
      md: `1px solid ${blueColor04}`,
    },

    "&.Mui-disabled": {
      border: "1px solid rgba(0, 0, 0, 0.26)",
    },
  },

  infoTab: {
    display: "flex",
    alignItems: { xs: "flex-start", md: "flex-start" },
    flexDirection: { xs: "column", md: "column" },
    justifyContent: { xs: "space-between", md: "flex-start" },
    pr: { xs: 0, md: 2 },
  },

  viewBtn: (color) => ({
    padding: { md: "3px 6px !important", xs: "3px 6px" },
    fontSize: "12px",
    fontWeight: 600,
    color: color || greyColor8,
    backgroundColor: whiteColor,
    borderRadius: "4px",
    border: color ? "1px solid " + color : "1px solid " + bgColor15,
    "&:hover": {
      color: whiteColor,
      backgroundColor: color || greyColor8,
    },
  }),
  actionBtn: (color) => ({
    padding: "2px 15px",
    fontSize: "12px",
    borderRadius: "50px",
    fontWeight: 600,
    color: blackShade19,
    backgroundColor: color,
    border: "1px solid " + color,
    whiteSpace: "nowrap",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: whiteColor,
    },
  }),
  legendDot: {
    width: "4px",
    height: "4px",
    backgroundColor: bgColor15,
    borderRadius: "50%",
  },
};
