import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BGColor02,
  blackShade159,
  blackShade19,
  blueColor04,
  greyColor9,
  redColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { useDispatch, useSelector } from "react-redux";
import ErrorIcon from "@mui/icons-material/Error";
import ModalCard from "../../../components/ModalCard";
import { useNavigate } from "react-router-dom";
import HorizontalSelectWithLabel from "../../../components/Input/HorizontalSelectWithLabel";
import HorizontalInputWithLabel from "../../../components/Input/HorizontalInputWithLabel";
import HorizontalMultiSelectCustomTextWithLabel from "../../../components/Input/HorizontalMultiSelectCustomTextWithLabel";
import HorizontalRadioGroupWithLabel from "../../../components/Input/HorizontalRadioGroupWithLabel";
import { toTitleCase } from "../../../utils/validationUtils";

const QuickOndcSpecUpdate = ({
  dymanicSpecList,
  openModal,
  setOpenModal,
  selectedProdGroup,
  setSelectedProdGroup,
  selectedOndcSpecCount,
}) => {
  const [specListInfo, setSpecListInfo] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState(null);

  const handleChange = (index, value, selector = "inputValue") => {
    const formattedValue = typeof value == "string" ? value?.trim() : value;
    const updatedOndcSpecificationData = [...specListInfo];
    updatedOndcSpecificationData[index][selector] = formattedValue;
    setSpecListInfo(updatedOndcSpecificationData);

    console.log(
      "QuickOndcSpecUpdate__handleChange:",
      updatedOndcSpecificationData
    );
  };

  const onApply = () => {
    // Create a shallow copy of the products array
    const newProductList = [...selectedProdGroup?.products];

    const updatedList = [];

    newProductList.forEach((product, index) => {
      // Create a shallow copy of the specList array within the product
      const updatedSpecList = [...product.specList];

      const newUpdatedSpecList = updatedSpecList?.map((item, itemIndex) => {
        const quickSpecItem = specListInfo[itemIndex];

        if (item?.type === "radioButton") {
          return {
            ...item,
            radioOptions: quickSpecItem?.radioOptions
              ? quickSpecItem?.radioOptions
              : item?.radioOptions,
          };
        }

        return {
          ...item,
          inputValue: quickSpecItem?.inputValue
            ? quickSpecItem?.inputValue
            : item?.inputValue,
        };
      });

      updatedList.push({
        ...product,
        specList: newUpdatedSpecList,
      });
    });

    console.log("QuickOndcSpecUpdate__Apply:", updatedList);

    setSelectedProdGroup((prevState) => ({
      ...prevState,
      products: updatedList,
    }));

    setSpecListInfo([]);
    setOpenModal(false);
  };

  useEffect(() => {
    if (dymanicSpecList?.length > 0 && specListInfo?.length === 0) {
      setSpecListInfo(dymanicSpecList);
    }

    return () => {};
  }, [dymanicSpecList]);

  return (
    <ModalCard
      open={openModal}
      handleClose={() => {
        setOpenModal(false);
      }}
      width="70%"
    >
      <Box sx={{ backgroundColor: whiteColor, borderRadius: 1.8 }}>
        <Box
          sx={{
            backgroundColor: BGColor02,
            p: 1.5,
            borderRadius: "14.4px 14.4px 0 0",
            textAlign: "center",
          }}
        >
          <Typography
            variant="body1"
            fontSize={16}
            fontWeight={600}
            color={blackShade19}
            mb={0.3}
          >
            Update ONDC Specifications{" "}
            <Typography
              variant="body1"
              fontSize={13}
              fontWeight={500}
              color={greyColor9}
            >
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: blueColor04,
                }}
              >
                {selectedOndcSpecCount} of {selectedProdGroup?.products?.length}
              </span>{" "}
              rows selected
            </Typography>
          </Typography>
        </Box>

        <Box sx={styles.modalBodyBox}>
          <Box sx={{ p: "16px 40px", mb: 1 }}>
            <Typography
              variant="h6"
              fontSize={14}
              fontWeight={600}
              color={blackShade19}
              mb={1}
            >
              ONDC Parameters
            </Typography>

            {/* <Grid container spacing={5}>
            <Grid item md={6}>
              <Typography variant="body1" sx={styles.inputLabel}>
                ONDC Category <span style={styles.requiredStar}>*</span>
              </Typography>

              <ErrorText errorMsg={errorMsg?.selectedOndcCat} />
            </Grid>
          </Grid> */}

            <Grid container spacing={2}>
              {specListInfo?.map((item, index) => {
                if (item?.display === "N") return null;
                if (item?.type === "variants" || item?.type === "data") {
                  return (
                    <Grid item md={6} xs={12} key={item?.code}>
                      <HorizontalSelectWithLabel
                        //   required={item?.required?.toLowerCase() === "m"}
                        label={item?.displayName}
                        hintText={item?.hintText}
                        menuList={item?.values || []}
                        value={item?.inputValue}
                        onValueChange={(value) => handleChange(index, value)}
                        error={item?.error}
                      />
                    </Grid>
                  );
                }
                if (item?.type === "codeValue") {
                  return (
                    <Grid item md={6} xs={12} key={item?.code}>
                      <HorizontalSelectWithLabel
                        //   required={item?.required?.toLowerCase() === "m"}
                        label={item?.displayName}
                        hintText={item?.hintText}
                        menuList={
                          item?.options?.length > 0
                            ? item?.options?.map((item) => toTitleCase(item))
                            : []
                        }
                        value={item?.inputValue}
                        onValueChange={(value) => handleChange(index, value)}
                        error={item?.error}
                      />
                    </Grid>
                  );
                }
                if (item?.type === "default") {
                  return (
                    <Grid item md={6} xs={12} key={item?.code}>
                      <HorizontalInputWithLabel
                        //   required={item?.required?.toLowerCase() === "m"}
                        label={item?.displayName}
                        hintText={item?.hintText}
                        value={
                          item?.values?.[0]?.charAt(0)?.toUpperCase() +
                          item?.values?.[0]?.slice(1)
                        }
                        disabled
                        error={item?.error}
                      />
                    </Grid>
                  );
                }
                if (item?.type === "freeform") {
                  return (
                    <Grid item md={6} xs={12} key={item?.code}>
                      <HorizontalInputWithLabel
                        //   required={item?.required?.toLowerCase() === "m"}
                        label={item?.displayName}
                        hintText={item?.hintText}
                        value={item?.inputValue}
                        onValueChange={(value) => {
                          handleChange(index, value);
                        }}
                        error={item?.error}
                      />
                    </Grid>
                  );
                }
                if (item?.type === "sample data") {
                  return (
                    <Grid item md={6} xs={12} key={item?.code}>
                      <HorizontalMultiSelectCustomTextWithLabel
                        //   required={item?.required?.toLowerCase() === "m"}
                        label={item?.displayName}
                        hintText={item?.hintText}
                        menuList={item?.values || []}
                        value={item?.inputValue}
                        onValueChange={(value) => handleChange(index, value)}
                        error={item?.error}
                      />
                    </Grid>
                  );
                }
                if (item?.type === "radioButton") {
                  return (
                    <Grid item md={6} xs={12} key={item?.code}>
                      <HorizontalRadioGroupWithLabel
                        //   required={item?.required?.toLowerCase() === "m"}
                        label={item?.displayName}
                        hintText={item?.hintText}
                        error={item?.error}
                        radioOptions={item?.radioOptions}
                        onValuesChanged={(obj) => {
                          const updatedRadioOptions = item?.radioOptions?.map(
                            (option) => {
                              if (option?.key === obj?.key) {
                                return {
                                  ...option,
                                  value: option?.options?.selected,
                                };
                              } else {
                                return {
                                  ...option,
                                  value: option?.options?.notSelected,
                                };
                              }
                            }
                          );
                          console.log(updatedRadioOptions);
                          handleChange(
                            index,
                            updatedRadioOptions,
                            "radioOptions"
                          );
                        }}
                      />
                    </Grid>
                  );
                }
                if (item?.type === "dependentCode") {
                  return (
                    <Grid item md={6} xs={12} key={item?.code}>
                      <HorizontalSelectWithLabel
                        //   required={item?.required?.toLowerCase() === "m"}
                        label={item?.displayName}
                        hintText={item?.hintText}
                        menuList={
                          item?.options?.length > 0
                            ? item?.options?.map((item) => toTitleCase(item))
                            : []
                        }
                        value={item?.inputValue}
                        onValueChange={(value) => handleChange(index, value)}
                        error={item?.error}
                      />
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Box>
        </Box>

        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          sx={{
            backgroundColor: BGColor02,
            p: 2,
            borderRadius: "0 0 14.4px 14.4px",
          }}
        >
          <Button
            variant="contained"
            sx={styles.backBtn}
            disableElevation
            onClick={() => {
              setOpenModal(false);
            }}
          >
            CANCEL
          </Button>

          <Button
            variant="contained"
            disableElevation
            sx={styles.applyBtn}
            onClick={() => {
              onApply();
            }}
            //   startIcon={
            //     updateLoader ? (
            //       <CircularProgress color="inherit" size={14} />
            //     ) : null
            //   }
          >
            Apply To{" "}
            {selectedOndcSpecCount === 1
              ? `${selectedOndcSpecCount} Row`
              : `${selectedOndcSpecCount} Rows`}
          </Button>
        </Stack>
      </Box>
    </ModalCard>
  );
};

export default QuickOndcSpecUpdate;

const ErrorText = ({ errorMsg }) => {
  if (!errorMsg) return null;

  return (
    <Typography
      variant="body1"
      fontSize={11.5}
      fontWeight={500}
      color={redColor}
      mt={0.4}
    >
      <ErrorIcon sx={{ fontSize: 13, mr: 0.3, mt: -0.3 }} /> {errorMsg}
    </Typography>
  );
};

const styles = {
  backBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: whiteColor,
    border: `1px solid ${blackShade19}`,
    color: blackShade19,
    p: "5px 20px",
    "&:hover": {
      color: blackShade19,
      backgroundColor: whiteColor,
    },
  },

  applyBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: blueColor04,
    border: `1px solid ${blueColor04}`,
    color: whiteColor,
    p: "5px 20px",
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
    },
  },

  uncheckBtn: {
    fontSize: 13,
    fontWeight: 500,
    border: `1.5px solid ${blueColor04}`,
    color: blueColor04,
    p: "4px 30px",
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
      border: `1.5px solid ${blueColor04}`,
    },
  },

  requiredStar: { fontSize: 12, color: redColor },

  deleteBtn: {
    border: `1.5px solid ${redColor}`,
    backgroundColor: redColor,
    "&:hover": {
      color: redColor,
      backgroundColor: whiteColor,
    },
  },

  inputLabel: {
    fontSize: 12,
    fontWeight: 400,
    mb: 0.3,
  },

  modalBodyBox: {
    height: "70vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "4px",
      // height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(0,0,0,0.3)",
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },
};
