import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { blackShade05 } from "../../../../configs/styles/muiThemes";
import InputPreview from "../../../../components/Input/InputPreview";
import moment from "moment";

function DisplayThroughDeliveryAgent({
  deliveryPersonPhone,
  deliveryPersonName,
  etaDeliveryDate,
  etaDeliveryTime,
  notes,
  mode,
}) {
  return (
    <Box>
      <Typography
        fontSize={14}
        fontWeight={600}
        color={blackShade05}
        mt="4px"
        mb="15px"
        lineHeight={"20px"}
      >
        Through Delivery Agent
      </Typography>
      <Grid container mt={1} spacing={{ xs: 1, md: 0 }}>
        <Grid item md={3} xs={12}>
          <InputPreview
            label="Delivery Person Number"
            value={deliveryPersonPhone}
            valueSize={14}
            hideBorder
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <InputPreview
            label="Delivery Person Name"
            value={deliveryPersonName}
            valueSize={14}
            hideBorder
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <InputPreview
            label="Delivery Mode"
            value={mode}
            valueSize={14}
            hideBorder
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <InputPreview
            label="Delivery ETA Date & Time "
            value={`${moment(etaDeliveryDate).format("DD-MM-YYYY")}, ${moment(
              etaDeliveryTime
            ).format("hh:mm A")}`}
            valueSize={14}
            hideBorder
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputPreview label="Notes" value={notes} valueSize={14} hideBorder />
        </Grid>
      </Grid>
    </Box>
  );
}

export default DisplayThroughDeliveryAgent;
