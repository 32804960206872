import { Box } from '@mui/system'
import React from 'react'
import { keyframes } from '@emotion/react'

const load7 = keyframes`
0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
`

function ThreeDotLoader({ white }) {
  return <Box sx={styles.loader(white)}>Loader</Box>
}

export default ThreeDotLoader

const styles = {
  loader: (color) => ({
    color: color ? "#fff" : "rgb(18, 37, 75)",
    fontSize: "7px",
    margin: "10px auto 60px",
    position: "relative",
    textIndent: "-9999em",
    "-webkit-transform": "translateZ(0)",
    "-ms-transform": "translateZ(0)",
    transform: "translateZ(0)",
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    "-webkit-animation-fill-mode": "both",
    "animation-fill-mode": "both",
    "-webkit-animation": `${load7} 1.8s infinite ease-in-out`,
    animation: `${load7} 1.8s infinite ease-in-out`,
    "-webkit-animation-delay": "-0.16s",
    "animation-delay": "-0.16s",
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: "-3.5em",
      borderRadius: "50%",
      width: "14px",
      height: "14px",
      "-webkit-animation-fill-mode": "both",
      "animation-fill-mode": "both",
      "-webkit-animation": `${load7} 1.8s infinite ease-in-out`,
      animation: `${load7} 1.8s infinite ease-in-out`,
      "-webkit-animation-delay": "-0.32s",
      "animation-delay": "-0.32s",
    },
    "&::after": {
      content: "''",
      position: "absolute",
      top: 0,
      left: "3.5em",
      borderRadius: "50%",
      width: "14px",
      height: "14px",
      "-webkit-animation-fill-mode": "both",
      "animation-fill-mode": "both",
      "-webkit-animation": `${load7} 1.8s infinite ease-in-out`,
      animation: `${load7} 1.8s infinite ease-in-out`,
    },
  }),
};
