import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import CategoryPreview from "../Forms/CategoryPreview";
import ProductDetailsPreview from "./ProductDetailsPreview";
import ProductImagesPreview from "./ProductImagesPreview";
import PriceInfoPreview from "./PriceInfoPreview";
import MeasurementInfoPreview from "./MeasurementInfoPreview";
import OndcInfoPreview from "./OndcInfoPreview";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import moment from "moment";
import BreadcrumbSection from "../../../components/Breadcrumb/BreadcrumbSection";
import { stickyHeader } from "../../../utils/styles";
import {
  blackColor,
  blueColor04,
  mobilePrimary,
} from "../../../configs/styles/muiThemes";
import CatalogActionBtns from "../../../components/Buttons/CatalogActionBtns";
import { useNavigate } from "react-router-dom";
import ImageGalleryPopup from "../Popup/ImageGalleryPopup";

const ProductInfoPreview = ({
  data,
  categoryData,
  productInfo,
  onGoBack,
  onSubmit,
  hideActionBtn,
  noMt,
  imageSelector,
  links,
  titleFrom,
  title,
  handleCancel,
  loader,
  urlLink,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [showImagePopup, setShowImagePopup] = useState(false);
  const [activeImg, setActiveImg] = useState("");

  const {
    productDetails,
    productImgUpdate,
    priceDetails,
    measurementDetails,
    ondcDetails,
  } = data;

  const navigate = useNavigate();

  const handleOk = () => {
    const webURL = sessionStorage.getItem("costboWEBURL");
    navigate(
      `/${webURL}/${
        urlLink === "active"
          ? "catalog"
          : urlLink === "pendingApprovalNew"
          ? "catalogPending"
          : urlLink === "pendingApproval"
          ? "catalogPendingExisting"
          : urlLink === "rejectedNew"
          ? "catalogRejected"
          : urlLink === "rejected"
          ? "catalogRejectedExisting"
          : urlLink
      }`
    );
  };

  return (
    <Box>
      <Box
        sx={{
          ...stickyHeader,
          display: "block",
          pb: 1,
        }}
      >
        {matches && <BreadcrumbSection links={links} urlLink={urlLink} />}

        <Typography
          component="h6"
          fontSize={{ xs: 15, md: 17 }}
          color={blackColor}
          fontWeight={{ xs: 600, md: 600 }}
          lineHeight="20px"
          display="inline"
        >
          {titleFrom && (
            <span style={{ color: matches ? blueColor04 : mobilePrimary }}>
              {titleFrom} -{" "}
            </span>
          )}
          {title}
        </Typography>
      </Box>

      <CategoryPreview categoryData={categoryData} productInfo={productInfo} />

      <ProductDetailsPreview
        description={productDetails?.description}
        brand={productDetails?.brand}
        country={productDetails?.country?.name}
        skuId={productDetails?.skuId}
        taxesIncluded={productDetails?.taxesIncluded}
        gstPercentage={productDetails?.gstPercentage}
      />

      <ProductImagesPreview
        images={productImgUpdate}
        imageSelector={imageSelector}
        onImageClick={(imgUrl) => {
          setActiveImg(imgUrl);
          setShowImagePopup(true);
        }}
      />

      <PriceInfoPreview
        currency={priceDetails?.currency}
        originalPrice={priceDetails?.originalPrice}
        offerPrice={priceDetails?.offerPrice}
        qty={priceDetails?.qty}
        alertQty={priceDetails?.alertQty}
        minQtyPerOrder={priceDetails?.minQtyPerOrder}
        maxQtyPerOrder={priceDetails?.maxQtyPerOrder}
        startDate={
          moment(priceDetails?.startDate)?.format("DD-MM-YYYY") || "DD-MM-YYYY"
        }
        endDate={
          moment(priceDetails?.endDate)?.format("DD-MM-YYYY") || "DD-MM-YYYY"
        }
      />

      <MeasurementInfoPreview
        weight={measurementDetails?.weight}
        netProductWeight={measurementDetails?.netProductWeight}
        length={measurementDetails?.length}
        width={measurementDetails?.width}
        height={measurementDetails?.height}
        productSpecLabel={measurementDetails?.productSpecLabel}
        refLinks={measurementDetails?.refLinks}
      />

      <OndcInfoPreview
        ondcEnabled={ondcDetails?.ondcEnabled?.toLowerCase() == "yes"}
        timeToShip={ondcDetails?.timeToShip}
        timeUnit={ondcDetails?.timeUnit}
        cod={ondcDetails?.cod}
        fragile={ondcDetails?.fragile}
        returnable={ondcDetails?.returnable}
        returnPeriod={ondcDetails?.returnPeriod}
        sellerPickup={ondcDetails?.sellerPickup}
        cancellable={ondcDetails?.cancellable}
        refundEligible={ondcDetails?.refundEligible}
        cancellationFee={ondcDetails?.cancellationFee}
        dynamicSpecs={ondcDetails?.dynamicSpecs}
        productCode={ondcDetails?.productCode}
        productCodeValue={ondcDetails?.productCodeValue}
        unitOfMeasure={ondcDetails?.unitOfMeasure}
        unitOfMeasureValue={ondcDetails?.unitOfMeasureValue}
      />

      {showImagePopup && (
        <ImageGalleryPopup
          openModal={showImagePopup}
          setOpenModal={setShowImagePopup}
          productImages={productImgUpdate}
          imageSelector={imageSelector}
          title={title}
          activeImg={activeImg}
          onActiveClick={(url) => setActiveImg(url)}
        />
      )}

      {!hideActionBtn ? (
        <Box my="20px">
          <CatalogActionBtns
            backBtnText="Previous"
            onBackClick={onGoBack}
            showBackBtn
            onCancelClick={handleCancel}
            nextBtnText={"SUBMIT"}
            onNextClick={onSubmit}
            hideSaveBtn
            isLoading={loader}
          />
        </Box>
      ) : (
        <Box my="20px">
          <CatalogActionBtns
            hideCancel
            nextBtnText={"OK"}
            onNextClick={handleOk}
            hideSaveBtn
          />
        </Box>
      )}
    </Box>
  );
};

export default ProductInfoPreview;
