import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import {
  BGColor02,
  greyColor02,
  blackShade01,
  blueColor100,
  blueColor03,
  border27,
  greyShade01,
  whiteColor,
  blueColor04,
} from "../../../configs/styles/muiThemes";
import IssueDetailsCard from "./InfoCards/IssueDetailsCard";
import { PRODUCT_LIST } from "../../../utils/dummyData";
import ProductDetailsCard from "./InfoCards/ProductDetailsCard";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ModalCard from "../../../components/ModalCard";
import {
  addDurationToDate,
  getIssueStatusText,
  getOpenStatusText,
  getResolvedSLAText,
  getStatusText,
  getStatusTextSLA,
  getStatusTextSLAEX,
} from "../../../utils/helper";
import RadiosBtnWithLabel from "../../../components/Input/RadiosBtnWithLabel";
import InputPreview from "../../../components/Input/InputPreview";
import ActionTimeLine from "./InfoCards/ActionTimeLine";
import {
  getCartDetails,
  getFulfillmentGrievanceDetails,
  getGrievanceDetails,
} from "../../../reducers/grievanceIssue/grievanceSlice";
import { TextBox } from "../../../components/Input/TextBox";
import { useNavigate } from "react-router-dom";
import {
  updateIssueResolutionApi,
  updateIssueStatusByBusinessApi,
  viewMyBusinessAPI,
} from "../../../service/api_calls/grievance/grievanceApi";
import moment from "moment";

function ActionIssueModal({
  modal,
  setModal,
  grievanceData,
  status,
  setIsImageIssueModal,
  statusrefresh,
  category,
  refreshNewProcessing,
  refreshProcessingResolved,
  getOndcBapNameBySubscriberId,
}) {
  const [resolutionData, setResolutionData] = useState({
    type: "NO-ACTION",
    resolution: "",
    description: "",
    refundAmount: "",
    errors: null,
  });
  const [steps, setSteps] = useState([]);
  const [responseStatus, setResponseStatus] = useState("");
  const [resolutionStatus, setResolutionStatus] = useState("");
  const [responseStatusSla, setResponseStatusSla] = useState("");
  const [resolutionStatusSla, setResolutionStatusSla] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cartData, cartLoader } = useSelector((state) => state.grievance);

  const handleSetValue = (name, value, state, setState) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const buildStepData = () => {
    const open =
      grievanceData?.message?.issue?.issue_actions?.complainant_actions?.find(
        (data) => data?.complainant_action === "OPEN"
      );
    const processing =
      grievanceData?.message?.issue?.issue_actions?.respondent_actions?.find(
        (data) => data?.respondent_action === "PROCESSING"
      );
    const resolved =
      grievanceData?.message?.issue?.issue_actions?.respondent_actions?.find(
        (data) => data?.respondent_action === "RESOLVED"
      );
    const completed =
      grievanceData?.message?.issue?.issue_actions?.complainant_actions?.find(
        (data) => data?.complainant_action === "CLOSE"
      );
    console.log(open, processing, resolved, completed);

    const stepArray = [open, processing, resolved, completed];

    const filteredArray = stepArray?.filter((item) => item != undefined);

    setSteps(filteredArray);
  };

  const handleSubmit = () => {
    let errors = null;
    handleSetValue("errors", null, resolutionData, setResolutionData);

    if (resolutionData?.resolution == "") {
      errors = {
        ...errors,
        resolution: "Please enter a Resolution details",
      };
    }
    if (resolutionData?.description == "") {
      errors = {
        ...errors,
        description: "Please enter a description",
      };
    }
    if (resolutionData?.type === "REFUND") {
      if (resolutionData?.refundAmount === "") {
        errors = {
          ...errors,
          refundAmount: "Please enter refund amount",
        };
      }
    }

    console.log(errors);

    if (errors) {
      handleSetValue("errors", errors, resolutionData, setResolutionData);
      return;
    } else {
      updateStatusToResolved();
    }
  };
  const updateStatusToResolved = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    console.log("updateStatusToProgress_grievanceData:", grievanceData);

    if (grievanceData?.businessId) {
      setIsLoading(true);

      const businessDetails = await viewMyBusinessAPI(
        grievanceData?.businessId || businessId
      );

      if (businessDetails) {
        const response = await updateIssueResolutionApi({
          issueId: grievanceData?.issueId,
          businessId: grievanceData?.businessId || businessId,
          bppId: grievanceData?.context?.bpp_id,
          domain: grievanceData?.context?.domain,
          ownerInfo: businessDetails?.viewBusiness?.owner,

          actionType: resolutionData?.type,
          resolution: resolutionData?.resolution,
          description: resolutionData?.description,
          refundAmount: resolutionData?.refundAmount,
        });

        if (response) {
          setIsLoading(false);
          setModal(false);
          setTimeout(() => {
            refreshProcessingResolved();
            // dispatch(
            //   getGrievanceDetails({
            //     businessId: businessId,
            //     status: statusrefresh,
            //     pageNo: 0,
            //     category: category ? category : "ITEM",
            //     endDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            //   })
            // );
          }, 1000);

          // navigate();
        }
      } else {
        setIsLoading(false);
      }
    }
  };
  const updateStatusToProgress = async () => {
    console.log("updateStatusToProgress_grievanceData:", grievanceData);
    const businessId = sessionStorage.getItem("selectedBusinessId");

    if (grievanceData?.businessId) {
      setIsLoading(true);

      const businessDetails = await viewMyBusinessAPI(businessId);

      if (businessDetails) {
        const response = await updateIssueStatusByBusinessApi({
          issueId: grievanceData?.issueId,
          businessId: grievanceData?.businessId || businessId,
          bppId: grievanceData?.context?.bpp_id,
          domain: grievanceData?.context?.domain,
          ownerInfo: businessDetails?.viewBusiness?.owner,
        });

        if (response) {
          setModal(false);
          setIsLoading(false);
          setTimeout(() => {
            refreshNewProcessing();
            // dispatch(
            //   getGrievanceDetails({
            //     businessId: businessId,
            //     status: statusrefresh,
            //     pageNo: 0,
            //     category: category ? category : "ITEM",
            //     endDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            //   })
            // );
          }, 1000);
          // navigate(0);
        }
      } else {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const Processing_Time = addDurationToDate(
      grievanceData?.createdOn,
      grievanceData?.message?.issue?.expected_response_time?.duration
    );
    const responseTimeStatus =
      grievanceData?.message?.issue?.status === "OPEN"
        ? getStatusText(
            grievanceData?.createdOn,
            grievanceData?.message?.issue?.expected_response_time?.duration
          )
        : getStatusTextSLAEX(
            grievanceData?.createdOn,
            grievanceData?.message?.issue?.expected_response_time?.duration,
            grievanceData?.message?.issue?.issue_actions?.respondent_actions?.find(
              (item, index) => {
                return (
                  item.respondent_action === "PROCESSING" && item.updated_at
                );
              }
            )
          );

    const resolutionTimeStatus =
      grievanceData?.message?.issue?.status === "OPEN"
        ? getStatusText(
            grievanceData?.createdOn,
            grievanceData?.message?.issue?.expected_resolution_time?.duration
          )
        : grievanceData?.message?.issue?.status === "PROCESSING"
        ? getStatusText(
            grievanceData?.createdOn,
            grievanceData?.message?.issue?.expected_resolution_time?.duration
          )
        : getStatusTextSLAEX(
            grievanceData?.createdOn,
            grievanceData?.message?.issue?.expected_resolution_time?.duration,
            grievanceData?.message?.issue?.issue_actions
              ?.respondent_actions?.[0]?.updated_at
          );
    const slaResponseText = getResolvedSLAText(grievanceData, "responceSla");
    const slaResolutionText = getResolvedSLAText(grievanceData, "resolveSla");
    dispatch(
      getCartDetails({
        cartId: grievanceData?.cartId,
        businessId: grievanceData?.businessId,
      })
    );
    console.log("getCartDetails");

    setResponseStatus(responseTimeStatus);
    setResolutionStatus(resolutionTimeStatus);
    setResponseStatusSla(slaResponseText);
    setResolutionStatusSla(slaResolutionText);
    buildStepData();
    return () => {};
  }, [grievanceData]);
  return (
    <Box>
      <ModalCard
        open={modal}
        handleClose={() => {
          setModal(false);
        }}
        width="75%"
        modalType="inside"
      >
        <Box sx={styles.card}>
          <Typography
            variant="h3"
            fontSize={18}
            fontWeight={600}
            lineHeight="34px"
            color={blackShade01}
            textAlign="center"
            p="10px 50px"
          >
            {status === "PROCESSING" ? "Resolution" : "Grievance Details"}
          </Typography>
          <Box sx={styles.cardBody}>
            {status === "PROCESSING" && (
              <Box sx={{ ...styles.contentCard, p: "18px" }} mb={2}>
                <Typography
                  variant="h5"
                  fontSize={{ xs: 14, md: 15 }}
                  fontWeight={600}
                  color={blackShade01}
                  mb={1.5}
                >
                  Action
                </Typography>
                <Box display="flex" flexWrap={"wrap"} gap={2}>
                  <RadiosBtnWithLabel
                    label="No Action"
                    labelStyles={styles.labelStyles}
                    checked={resolutionData?.type === "NO-ACTION"}
                    onValuesChanged={() => {
                      handleSetValue(
                        "type",
                        "NO-ACTION",
                        resolutionData,
                        setResolutionData
                      );
                    }}
                  />
                  <RadiosBtnWithLabel
                    label="Cancel"
                    labelStyles={styles.labelStyles}
                    checked={resolutionData?.type === "CANCEL"}
                    onValuesChanged={() => {
                      handleSetValue(
                        "type",
                        "CANCEL",
                        resolutionData,
                        setResolutionData
                      );
                    }}
                  />
                  <RadiosBtnWithLabel
                    label="Replace"
                    labelStyles={styles.labelStyles}
                    checked={resolutionData?.type === "REPLACEMENT"}
                    onValuesChanged={() => {
                      handleSetValue(
                        "type",
                        "REPLACEMENT",
                        resolutionData,
                        setResolutionData
                      );
                    }}
                  />
                  <RadiosBtnWithLabel
                    label="Refund"
                    labelStyles={styles.labelStyles}
                    checked={resolutionData?.type === "REFUND"}
                    onValuesChanged={() => {
                      handleSetValue(
                        "type",
                        "REFUND",
                        resolutionData,
                        setResolutionData
                      );
                    }}
                  />
                </Box>
                <Stack gap={2} mt={0.6}>
                  <TextBox
                    label="Resolution"
                    required
                    value={resolutionData?.resolution}
                    onChange={({ target }) => {
                      handleSetValue(
                        "resolution",
                        target.value,
                        resolutionData,
                        setResolutionData
                      );
                    }}
                    error={resolutionData?.errors?.resolution}
                  />
                  <TextBox
                    label="Description"
                    required
                    value={resolutionData?.description}
                    onChange={({ target }) => {
                      handleSetValue(
                        "description",
                        target.value,
                        resolutionData,
                        setResolutionData
                      );
                    }}
                    error={resolutionData?.errors?.description}
                  />
                  {resolutionData?.type === "REFUND" && (
                    <TextBox
                      label="Refund Amount"
                      placeholder="Enter Refund Amount"
                      value={resolutionData?.refundAmount}
                      onChange={({ target }) => {
                        const value = target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*)\./g, "$1");
                        handleSetValue(
                          "refundAmount",
                          value,
                          resolutionData,
                          setResolutionData
                        );
                      }}
                      startIcon={"₹"}
                      error={resolutionData?.errors?.refundAmount}
                    />
                  )}
                  <Box display="flex" mt={2} justifyContent="center">
                    <Button
                      variant="contained"
                      sx={styles.actionBtn}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Box>
                </Stack>
              </Box>
            )}
            {!cartLoader ? (
              <>
                <Box sx={styles.contentCard}>
                  <IssueDetailsCard
                    grievanceData={grievanceData}
                    getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
                    issueId={grievanceData?.issueId}
                    issuedDate={grievanceData?.createdOn}
                    issueType={grievanceData?.message?.issue?.sub_category}
                    status={grievanceData?.message?.issue?.status}
                    msgResolution={
                      grievanceData?.message?.issue?.resolution?.short_desc
                    }
                    refund_amount={
                      grievanceData?.message?.issue?.resolution?.refund_amount
                    }
                    customerName={
                      grievanceData?.message?.issue?.complainant_info?.person
                        ?.name
                    }
                    phoneNumber={
                      grievanceData?.message?.issue?.complainant_info?.contact
                        ?.phone
                    }
                    orderId={grievanceData?.orderId}
                    orderStatus={grievanceData?.orderStatus}
                    responseTime={
                      grievanceData?.message?.issue?.expected_response_time
                        ?.duration
                    }
                    responseTimeStatus={responseStatus}
                    resolutionTime={
                      grievanceData?.message?.issue?.expected_resolution_time
                        ?.duration
                    }
                    resolutionTimeStatus={resolutionStatus}
                    responseStatusSla={responseStatusSla}
                    resolutionStatusSla={resolutionStatusSla}
                  />
                </Box>

                <Typography
                  variant="h5"
                  fontSize={{ xs: 14, md: 15 }}
                  fontWeight={600}
                  color={blackShade01}
                  mb={2}
                  mt={2}
                >
                  Issue Details
                </Typography>
                <Box sx={styles.contentCard}>
                  <Stack gap={2} mb={2}>
                    {grievanceData?.message?.issue?.order_details?.items?.map(
                      (item, index) => {
                        const product = cartData?.productList?.find(
                          (data) => data?.productId === item?.id
                        );
                        return (
                          <ProductDetailsCard
                            key={"product" + item?.id}
                            imageUrl={product?.productPicURL}
                            productName={product?.productName}
                            orderQty={product?.currentQuantity}
                            issueQty={item?.quantity}
                            price={product?.productTotalPrice}
                          />
                        );
                      }
                    )}
                  </Stack>
                  <Stack gap={2} mb={1}>
                    <InputPreview
                      label="Issue"
                      value={
                        grievanceData?.message?.issue?.description?.short_desc
                      }
                    />
                    <InputPreview
                      label="Description"
                      value={
                        grievanceData?.message?.issue?.description?.long_desc
                      }
                    />
                    <Box sx={{ pb: 2 }}>
                      <Typography
                        variant="h3"
                        fontSize={{ xs: 12, md: 13 }}
                        color={greyShade01}
                        fontWeight={400}
                      >
                        Images
                      </Typography>
                      <Box mt={1.5} display="flex" gap={2}>
                        {grievanceData?.message?.issue?.description?.images?.map(
                          (imageUrl, index) => (
                            <Box
                              component="img"
                              src={imageUrl}
                              alt=""
                              sx={styles.logoImg}
                              key={index}
                              onClick={() => {
                                setIsImageIssueModal(imageUrl);
                              }}
                            />
                          )
                        )}
                      </Box>
                    </Box>
                  </Stack>
                  {/* <Typography
                    variant="h5"
                    fontSize={{ xs: 14, md: 15 }}
                    fontWeight={600}
                    color={blackShade01}
                    mb={2}
                  >
                    Actions Taken
                  </Typography> */}
                  {/* <ActionTimeLine steps={steps} /> */}
                </Box>
                {status === "OPEN" && (
                  <Box display="flex" mt={2} justifyContent="center">
                    <Button
                      variant="contained"
                      sx={styles.actionBtn}
                      onClick={() => {
                        updateStatusToProgress();
                      }}
                      disabled={isLoading}
                      startIcon={
                        isLoading ? (
                          <CircularProgress
                            size={16}
                            sx={{ color: greyShade01 }}
                          />
                        ) : null
                      }
                    >
                      Set to processing
                    </Button>
                  </Box>
                )}
              </>
            ) : null}
          </Box>
        </Box>
      </ModalCard>
    </Box>
  );
}

export default ActionIssueModal;

const styles = {
  card: {
    backgroundColor: BGColor02,
    borderRadius: "15px",
    overflow: "hidden",
  },
  cardBody: {
    maxHeight: "80vh",
    backgroundColor: whiteColor,
    p: " 28px 12px 15px 12px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blueColor100,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: greyColor02,
      borderRadius: "50px !important",
    },
  },
  contentCard: {
    border: border27,
    p: { xs: "12px 16px", md: "12px 18px" },
    borderRadius: "10px",
  },
  logoImg: {
    width: { xs: "52px", md: "84px" },
    height: { xs: "52px", md: "84px" },
    border: border27,
    objectFit: "contain",
    borderRadius: "8px",
  },
  actionBtn: {
    fontSize: 12.5,
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: blueColor04,
    border: "1px solid" + blueColor04,
    p: "3px 16px",
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
    },
  },
  labelStyles: {
    fontSize: 14,
    fontWeight: 500,
    color: blackShade01,
  },
};
