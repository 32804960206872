import {
  Box,
  Button,
  Fade,
  Paper,
  Popper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  bgColor15,
  blackColor,
  blackShade05,
  blueColor04,
  blueColor1,
  greyColor10,
  greyColor24,
  greyColor6,
  greyColor9,
  iconBG1,
  iconBG2,
  logoImgborder,
  logoImgborder1,
  whiteColor,
} from "../../configs/styles/muiThemes";
import HorizontalStepper from "../../components/HorizontalStepper";
import ReadyToShipOrdersContent from "./TabContent/ReadyToShipOrdersContent";
import DeliveredOrdersContent from "./TabContent/DeliveredOrdersContent";
import PendingOrdersContent from "./TabContent/PendingOrdersContent";
import IntransitOrdersContent from "./TabContent/IntransitOrdersContent";
import CancelledOrdersContent from "./TabContent/CancelledOrdersContent";
import AllOrdersContent from "./TabContent/AllOrdersContent";
import OrderTrackingPopupContent from "./Popups/OrderTrackingPopupContent";
import ModalCard from "../../components/ModalCard";
import ViewOrderPopupContent from "./Popups/ViewOrderPopupContent";
import ApprovePopupContent from "./Popups/ApprovePopupContent";
import PickupDetailsPopupContent from "./Popups/ApprovePopups/PickupDetailsPopupContent";
import OrderDetailsPopupContent from "./Popups/ApprovePopups/OrderDetailsPopupContent";
import OrderApprove from "./OrderApprove";
import OrderMobileTab from "../../components/MobileTabs/OrderMobileTab";
import { useDispatch, useSelector } from "react-redux";
import { getCommerceInfo } from "../../reducers/businessPreview/businessPreviewSlice";
import { handleRefreshTabData } from "../../reducers/orders/ordersSlice";
import HowToLinkCard from "../../components/Cards/HowToLinkCard";
import { useNavigate } from "react-router-dom";
import VideoModal from "../../components/VideoModal";
import { ORDER_VIDEO_DATA } from "../../utils/data";
import {
  getCartInfoApi,
  getShipingLabelLink,
  resendInvoiceApi,
  resendInvoiceApiB2B,
} from "../../service/api_calls/orders/OrdersApi";
import { stickyHeader, scrollBox } from "../../utils/styles";
import MobileTab from "../../components/MobileTabs/MobileTab";
import { getBuyerAppLookupAPI } from "../../service/api_calls/BusinessPreview/BusinessPreviewAPI";
import AlertPopup from "../../components/Alerts/AlertPopup";

function OrdersContent({ orderData, loader, orderSummaryInfo }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const [tabTitle, setTabTitle] = useState("Pending Approval");

  const [orderList, setOrderList] = useState([]);
  const [ordersByStatus, setOrdersByStatus] = useState(null);

  const [orderCountData, setOrderCountData] = useState(null);
  const [openTrackOrder, setOpenTrackOrder] = useState({
    show: false,
    data: null,
  });
  const [openViewOrder, setOpenViewOrder] = useState({
    show: false,
    data: null,
  });

  const [openVideoModal, setOpenVideoModal] = useState(false);

  const [success, setSuccess] = useState(null);
  const [successB2B, setSuccessB2B] = useState(false);
  const [errorB2B, setErrorB2B] = useState(null);
  const [loadingB2B, setLoadingB2B] = useState(false);

  const [bapNameList, setBapNameList] = useState(null);
  const [errorLabel, setErrorLabel] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { commerceInfo } = useSelector((state) => state.businessPreview);

  const ORDER_STATUS = [
    { id: 0, label: "New/Pending Approval", mobileLabel: "PENDING APPROVAL" },
    { id: 1, label: "Ready To Ship", mobileLabel: "READY TO SHIP" },
    { id: 2, label: "In Transit", mobileLabel: "IN TRANSIT" },
    { id: 3, label: "Delivered", mobileLabel: "DELIVERED" },
    { id: 4, label: "Canceled", mobileLabel: "CANCELED" },
    { id: 5, label: "All Orders", mobileLabel: "ALL ORDERS" },
  ];

  const STEPS = [
    {
      label: "Created",
    },
    {
      label: "Approved",
    },
    {
      label: "Shipped",
    },
    {
      label: "Delivered",
    },
  ];

  const getTabTitle = (tabsNum) => {
    switch (tabsNum) {
      case 0:
        return "Pending Approval";
      case 1:
        return "Ready to Ship";
      case 2:
        return "In Transit";
      case 3:
        return "Delivered";
      case 4:
        return "Cancelled";
      case 5:
        return "All Orders";
      default:
        return "Pending Approval";
    }
  };

  const handleChange = (event, newValue) => {
    dispatch(handleRefreshTabData());
    setTabTitle(getTabTitle(newValue));
    setOpen(false);
    // updateOrderList(newValue);
    setTabValue(newValue);
  };

  const getOrderCoutByStatus = (tabNum) => {
    switch (tabNum) {
      case 0:
        return orderCountData?.ordercreated || 0;
      case 1:
        return orderCountData?.orderapproved || 0;
      case 2:
        return orderCountData?.ordershipped || 0;
      case 3:
        return orderCountData?.orderdelivered || 0;
      case 4:
        return orderCountData?.bordercanceled || 0;
      case 5:
        return orderSummaryInfo?.totalOrders || 0;
      default:
        return "";
    }
  };

  const prepareOrderCountDetails = (data) => {
    const orderCounts = data?.statusCounts?.reduce((preValue, currentValue) => {
      return {
        ...preValue,
        [currentValue?.id]: currentValue?.count,
      };
    }, {});
    console.log("orderCountDataorderCountData", orderCounts, data);
    if (orderCounts) {
      setOrderCountData(orderCounts);
    }
  };

  // Open Track Order Popup
  const handleTrackOrder = (data) => {
    setOpenTrackOrder({ show: true, data });
  };

  // Open View Order Popup
  const handleViewOrder = (data) => {
    setOpenViewOrder({ show: true, data });
  };

  const handleActionButton = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);

    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  const handleResend = async (orderData) => {
    console.log("hit6", orderData);
    setSuccess(null);
    if (orderData?.cartId) {
      console.log("hit7");
      const response = await resendInvoiceApi({
        cartId: orderData?.cartId,
      });

      if (response) {
        console.log("hit8");
        setSuccess({ show: true, id: orderData?.cartId });
        // setTimeout(() => {
        //   setSuccess(null);
        // }, 2000);
      }
    }
  };

  const handleResendB2B = async (orderData) => {
    console.log("hit3");
    setErrorB2B(null);
    setSuccess(null);
    setLoadingB2B(false);
    const businessId = sessionStorage.getItem("selectedBusinessId");

    if (orderData?.cartId) {
      console.log("hit4");
      const cartInfoResponse = await getCartInfoApi({
        cartId: orderData?.cartId,
        businessId,
      });
      if (cartInfoResponse?.cart) {
        const cartInfo = cartInfoResponse?.cart;
        if (
          cartInfo?.shipmentInfo?.carrierTrackId?.length === 36 ||
          !cartInfo?.shipmentInfo?.carrierTrackId
        ) {
          setErrorB2B({ show: true, id: orderData?.cartId });
          return;
        }
        setLoadingB2B(true);

        const response = await resendInvoiceApiB2B({
          cartId: orderData?.cartId,
          carrierId: cartInfo?.shipmentInfo?.carrierTrackId,
          businessId,
        });

        if (response) {
          console.log("hit5");
          setSuccess({ show: true, id: orderData?.cartId });
          setLoadingB2B(false);
          // setTimeout(() => {
          //   setSuccess(null);
          // }, 2000);
        }
      }
    }
  };

  const downloadShipingLabel = async (orderData) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const data = { businessId, wbns: orderData?.shipmentInfo?.carrierTrackId };
    setErrorLabel(false);
    const shipingLabelLink = await getShipingLabelLink(data);

    console.log("downloadPdf", shipingLabelLink);
    if (!shipingLabelLink?.packages?.[0]?.pdf_download_link) {
      setErrorLabel(true);
      return;
    }
    downloadPdf(
      shipingLabelLink?.packages?.[0]?.pdf_download_link,
      "shippingLabel.pdf"
    );
  };

  const downloadPdf = (url, name) => {
    console.log(url, name);
    window.open(url, "_self");
    // window.open(url,)
    // const link = document.createElement("a");
    // link.href = { url }; // Replace with the path to your PDF file
    // link.download = name; // The name of the downloaded file
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    // fetch(url, { method: "HEAD" })
    //   .then((response) => {
    //     if (response.ok) {
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.download = name; // The name of the downloaded file
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //     } else {
    //       alert("File wasn't available on site");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching the PDF:", error);
    //     alert("An error occurred while trying to fetch the PDF");
    //   });
  };

  const getBapDisplayNameInfo = async () => {
    const response = await getBuyerAppLookupAPI();

    if (response?.details?.length > 0) {
      setBapNameList(response?.details || []);
    }
  };

  // Get ONDC Bap Display Name by Subscriber ID
  const getOndcBapNameBySubscriberId = (id) => {
    const data = bapNameList?.find((item) => item?.subscriberId === id);
    return data ? data?.displayName : "";
  };

  useEffect(() => {
    prepareOrderCountDetails(orderSummaryInfo);

    return () => {};
  }, [orderSummaryInfo]);

  useEffect(() => {
    getBapDisplayNameInfo();

    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!commerceInfo) {
        dispatch(getCommerceInfo(businessId));
      }
    }
  }, []);

  return (
    <Box>
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          handleClose={handleCloseVideoModal}
          data={ORDER_VIDEO_DATA}
        />
      )}
      <Box>
        <Box sx={stickyHeader}>
          <Box
            sx={{
              display: "flex",
              alignItems: { md: "center", xs: "flex-start" },
              justifyContent: "space-between",
              flexDirection: { md: "row", xs: "row" },
              mb: 2,
            }}
          >
            <Typography
              component="h4"
              fontSize={17}
              color={blackShade05}
              fontWeight={600}
              lineHeight="20px"
            >
              Manage Orders
            </Typography>
            <HowToLinkCard
              link="Order Management"
              handleClick={() => {
                setOpenVideoModal(true);
              }}
            />
          </Box>

          {matches ? (
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                mb: { xs: "10px", md: 1 },
                mt: 2,
              }}
            >
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => handleChange(event, newValue)}
                textColor={blueColor04}
                TabIndicatorProps={{
                  style: {
                    height: "3px",
                    backgroundColor: blueColor04,
                  },
                }}
                sx={{
                  minHeight: "35px !important",
                  "& .MuiButtonBase-root": {
                    minWidth: "auto !important",
                  },
                }}
              >
                {ORDER_STATUS?.map((item, index) => {
                  return (
                    <Tab
                      key={"filter_" + index}
                      label={item.label + ` - ${getOrderCoutByStatus(index)}`}
                      // label={item.label}
                      sx={{
                        textTransform: "capitalize",
                        fontSize: { xs: "11px", md: "13px" },
                        fontWeight: tabValue == index ? 600 : 500,
                        p: { xs: "10px 35px 10px 10px", md: 0 },
                        minHeight: "30px !important",
                        mr: { xs: "0px", md: "55px" },
                        color: {
                          md: item.id === tabValue ? blueColor04 : greyColor9,
                          xs: item.id === tabValue ? whiteColor : bgColor15,
                        },
                        backgroundColor: {
                          xs: item.id === tabValue ? bgColor15 : whiteColor,
                          md: "transparent",
                        },
                        "&::after": {
                          content: '""',
                          position: "absolute",
                          display: "inline-block",
                          width: "35px",
                          height: "35px",
                          top: "0px",
                          right: "-20px",
                          background: {
                            xs: item.id === tabValue ? bgColor15 : whiteColor,
                            md: "transparent",
                          },
                          borderRight: {
                            xs: `2px solid ${
                              item.id === tabValue ? whiteColor : bgColor15
                            }`,
                            md: "none",
                          },
                          borderBottom: {
                            xs: `2px solid ${
                              item.id === tabValue ? whiteColor : bgColor15
                            }`,
                            md: "none",
                          },
                          borderTopRightRadius: "5px",
                          transform: "scale(0.707) rotate(-45deg)",
                          // boxShadow: "2px -2px rgb(192 196 204)",
                          zIndex: 16,
                        },
                      }}
                    />
                  );
                })}
              </Tabs>
            </Box>
          ) : (
            <Box sx={{ ...styles.mobileTabs, ...scrollBox }}>
              {ORDER_STATUS?.map((item, index) => {
                return (
                  <MobileTab
                    title={
                      item.mobileLabel
                      // ` - ${getProductsCount(item.id)}`
                    }
                    count={getOrderCoutByStatus(index)}
                    isActive={tabValue == index}
                    onClick={() => handleChange("", item.id)}
                  />
                );
              })}
            </Box>
          )}

          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent={{ xs: "flex-start", md: "space-between" }}
            m={"-5px 0 0px"}
          >
            <Box
              flex={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                component="h5"
                fontSize={{ md: 15, xs: 13 }}
                color={blackShade05}
                fontWeight={600}
                lineHeight="20px"
                pb={{ md: 0, xs: 1.5 }}
                my={{
                  xs: "0px",
                  md: tabValue === 4 || tabValue === 5 ? "16px" : "0px",
                }}
                pt={{ xs: 1.5, md: 0 }}
              >
                {tabTitle}{" "}
                <Typography
                  component="span"
                  fontSize={13}
                  color={greyColor6}
                  fontWeight={400}
                  lineHeight="20px"
                >
                  - {getOrderCoutByStatus(tabValue)}{" "}
                  {tabTitle === 1 ? "order" : "orders"}
                </Typography>
              </Typography>

              <Box>
                {(tabValue === 0 ||
                  tabValue === 1 ||
                  tabValue === 2 ||
                  tabValue === 3 ||
                  tabValue === 5) && (
                  <Box
                    display={{ md: "block", xs: "none" }}
                    mt={{ xs: 1, md: 2 }}
                    mb={{ md: 0, xs: 1 }}
                    ml={-1}
                  >
                    <HorizontalStepper
                      steps={STEPS}
                      activeStep={tabValue === 5 ? -1 : tabValue}
                    />
                  </Box>
                )}
              </Box>

              {tabValue < 2 ? (
                <>
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement="bottom"
                    transition
                    sx={{ zIndex: 999 }}
                  >
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Box
                          sx={{
                            backgroundColor: whiteColor,
                            p: "3px 20px",
                            m: "10px 20px",
                            borderRadius: "8px",
                            boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
                            border: logoImgborder1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: 500,
                              color: blackShade05,
                              m: "10px 0",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {tabValue === 0
                              ? "To 'APPROVE' new orders use COSTBO app. This feature will be available in web dashboard soon."
                              : `Once shipment is picked up from your warehouse 'NOTIFY CUSTOMER' using COSTBO app. \nThis feature will be available in web dashboard soon.`}
                          </Typography>
                        </Box>
                      </Fade>
                    )}
                  </Popper>
                </>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Box>

        {bapNameList && (
          <Box>
            {tabValue === 0 && (
              <PendingOrdersContent
                handleViewOrder={handleViewOrder}
                handleTrackOrder={handleTrackOrder}
                // handleApproveOrder={handleApproveOrder}
                steps={STEPS}
                tabValue={tabValue}
                commerceInfo={commerceInfo}
                getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
              />
            )}
            {tabValue === 1 && (
              <ReadyToShipOrdersContent
                handleTrackOrder={handleTrackOrder}
                handleViewOrder={handleViewOrder}
                steps={STEPS}
                tabValue={tabValue}
                handleResend={handleResend}
                success={success}
                error={errorB2B}
                handleResendB2B={handleResendB2B}
                downloadShipingLabel={downloadShipingLabel}
                commerceInfo={commerceInfo}
                getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
              />
            )}

            {tabValue === 2 && (
              <IntransitOrdersContent
                handleTrackOrder={handleTrackOrder}
                handleViewOrder={handleViewOrder}
                steps={STEPS}
                tabValue={tabValue}
                handleResend={handleResend}
                success={success}
                error={errorB2B}
                handleResendB2B={handleResendB2B}
                commerceInfo={commerceInfo}
                downloadShipingLabel={downloadShipingLabel}
                getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
              />
            )}

            {tabValue === 3 && (
              <DeliveredOrdersContent
                handleTrackOrder={handleTrackOrder}
                handleViewOrder={handleViewOrder}
                steps={STEPS}
                tabValue={tabValue}
                downloadShipingLabel={downloadShipingLabel}
                getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
              />
            )}

            {tabValue === 4 && (
              <CancelledOrdersContent
                handleViewOrder={handleViewOrder}
                tabValue={tabValue}
                tabTitle={tabTitle}
                subTitle={`- ${getOrderCoutByStatus(tabValue)} ${
                  getOrderCoutByStatus(tabValue) == 1 ? "order" : "orders"
                }`}
                getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
              />
            )}

            {tabValue === 5 && (
              <AllOrdersContent
                handleTrackOrder={handleTrackOrder}
                handleViewOrder={handleViewOrder}
                tabValue={tabValue}
                tabTitle={tabTitle}
                subTitle={`- ${getOrderCoutByStatus(tabValue)} ${
                  getOrderCoutByStatus(tabValue) == 1 ? "order" : "orders"
                }`}
                getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
                downloadShipingLabel={downloadShipingLabel}
              />
            )}
          </Box>
        )}

        <ModalCard
          open={openTrackOrder.show}
          handleClose={() => {
            setOpenTrackOrder({ show: false, data: null });
          }}
          // width="30%"
        >
          <OrderTrackingPopupContent
            orderData={openTrackOrder?.data || null}
            getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
          />
        </ModalCard>
        <ModalCard
          open={errorLabel}
          handleClose={() => {
            setErrorLabel(false);
          }}
          // width="30%"
        >
          <AlertPopup
            text={`Shipping Label expired/ not available.`}
            btnTxt="Ok"
            color={blueColor04}
            onClick={() => {
              setErrorLabel(false);
            }}
          />
        </ModalCard>

        {/* View Order Card */}
        <ModalCard
          open={openViewOrder?.show}
          handleClose={() => {
            setOpenViewOrder({ show: false, data: null });
          }}
          width="80%"
        >
          <ViewOrderPopupContent
            orderData={openViewOrder?.data || null}
            handleTrackOrder={() => handleTrackOrder(openViewOrder?.data)}
            showTracking={tabValue === 1 || tabValue == 2 || tabValue == 3}
            getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
          />
        </ModalCard>

        {/* <ModalCard
          open={openApproveOrder?.show}
          handleClose={() => {
            setOpenApproveOrder({ show: false, data: null });
          }}
          width="80%"
        >
          <ApprovePopupContent orderData={openApproveOrder?.data || null} /> */}
        {/* <PickupDetailsPopupContent/> */}
        {/* <OrderDetailsPopupContent /> */}
        {/* </ModalCard> */}
        {/* {openApproveOrder && (
          <OrderApprove
            open={openApproveOrder?.show}
            handleClose={() => {
              setOpenApproveOrder({ show: false, data: null });
            }}
            handleApproveSuccess={() => {
              setOpenApproveOrder({ show: false, data: null });
            }}
            data={openApproveOrder?.data || null}
            commerceInfo={commerceInfo}
          />
        )} */}
      </Box>
    </Box>
  );
}

export default OrdersContent;

const styles = {
  actionBtn: {
    fontSize: "13px",
    fontWeight: "600",
    color: greyColor24,
    backgroundColor: whiteColor,
    padding: "4px 22px",
    my: "10px",
    border: `1px solid ${greyColor24}`,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor24,
    },
  },
  statsCard: {
    width: { xs: "100%", md: "40%" },
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    gap: { xs: "20px", md: "35px" },
    backgroundColor: whiteColor,
    padding: { xs: "10px 16px", md: "8px 16px" },
    borderRadius: { xs: "8px", md: "12px" },
    border: `1px solid ${greyColor10}`,
    m: { xs: "12px 0 12px 0", md: "0" },
  },
  mobileTabs: {
    width: { xs: "90vw", md: "calc(100% - 100px)" },
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
    mb: 1,
    borderBottom: logoImgborder,
  },
};
