import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  BGColor02,
  blackShade05,
  blackShade19,
  blueColor04,
  greyColor6,
  greyColor8,
  greyColor9,
  menuGradient,
  mobilePrimary,
  whiteColor,
} from "../../configs/styles/muiThemes";
import FormInputBox from "../../components/Input/FormInputBox";
import { PreferredDiscountAddRewardDataApi } from "../../service/api_calls/preferredDiscounts/PreferredDiscountsApi";
import moment from "moment";
import ThreeDotLoader from "../../components/ThreeDotLoader";

function AddDiscountModalContent({
  data,
  setCouponValue,
  onCancel,
  onSubmit,
  loading,
}) {
  const handleRadioChange = (event) => {
    setCouponValue("type", event.target.value);
  };

  return (
    <Box sx={styles.card}>
      {loading ? (
        <Box pt={2}>
          <ThreeDotLoader />
        </Box>
      ) : (
        <>
          <Box p={{ xs: "12px", md: "15px" }}>
            <Typography
              variant="h3"
              fontSize={16}
              fontWeight={600}
              lineHeight="25px"
              color={blackShade05}
              textAlign="center"
              p={{ xs: "0 0 4px 0", md: "5px 50px" }}
            >
              Personalized Discount
            </Typography>
            <Typography variant="h5" sx={styles.caption}>
              Assign Discount to Specific customer
            </Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: whiteColor,
              p: "10px 15px 0px 15px",
            }}
          >
            <Box>
              <RadioGroup value={data?.type} row>
                <FormControlLabel
                  value="Amount"
                  onChange={handleRadioChange}
                  control={<Radio />}
                  label="Amount"
                />
                <FormControlLabel
                  value="Percentage"
                  onChange={handleRadioChange}
                  control={<Radio />}
                  label="Percentage"
                />
              </RadioGroup>
            </Box>

            <Typography
              variant="subtitle1"
              component="p"
              fontSize={12}
              sx={{ m: "-8px 0 8px !important", textAlign: "start !important" }}
              style={{ color: "red" }}
            >
              {data?.errors?.type}
            </Typography>
            <Box display={"flex"} flexDirection={"column"}>
              <FormInputBox
                label="Customer Phone Number"
                placeholder="Enter Phone Number"
                startIcon="+91"
                value={data?.originalPhoneNo || ""}
                onChange={({ target }) => {
                  const value = target?.value?.replace(/[^0-9]/g, "");
                  setCouponValue("originalPhoneNo", value);
                }}
                error={data?.errors?.originalPhoneNo}
                maxLength={10}
                required
              />

              <Box mt={-1}>
                {data?.type === "Amount" ? (
                  <FormInputBox
                    label="Discount Value"
                    // startIcon="₹"
                    placeholder="Max ₹ 5,00,000 "
                    value={data?.pointValue || ""}
                    onChange={({ target }) => {
                      if (
                        !isNaN(target.value) &&
                        target.value.charAt(0) !== "0" &&
                        target.value < 500000
                      ) {
                        setCouponValue("pointValue", target.value);
                      }
                    }}
                    error={data?.errors?.pointValue}
                    required
                  />
                ) : (
                  <FormInputBox
                    label="Discount Percentage"
                    placeholder="Max 99%"
                    value={data?.pointPercentage}
                    onChange={({ target }) => {
                      if (
                        !isNaN(target.value) &&
                        target.value.charAt(0) !== "0" &&
                        target.value < 100
                      ) {
                        setCouponValue("pointPercentage", target.value);
                      }
                    }}
                    error={data?.errors?.pointPercentage}
                    required
                  />
                )}
              </Box>
              <Box mt={-1} mb={-1}>
                <FormInputBox
                  label="Minimum Purchase Amount"
                  // startIcon="₹"
                  placeholder="Max ₹ 5,00,000"
                  value={data?.minimumAmount}
                  onChange={({ target }) => {
                    const value = target?.value?.replace(/[^0-9]/g, "");
                    if (Number(value) <= 500000) {
                      setCouponValue("minimumAmount", value);
                    }
                  }}
                  error={data?.errors?.minimumAmount}
                  required
                />
              </Box>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <FormInputBox
                  label="Valid From"
                  placeholder=""
                  inputType="date"
                  value={data?.startDate}
                  onChange={({ target }) => {
                    setCouponValue("startDate", target.value);
                  }}
                  error={data?.errors?.startDate}
                  required
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <FormInputBox
                  label="Valid To"
                  placeholder=""
                  inputType="date"
                  value={data?.endDate}
                  onChange={({ target }) => {
                    setCouponValue("endDate", target.value);
                  }}
                  error={data?.errors?.endDate}
                  required
                />
              </Grid>
            </Grid>
            <Typography
              variant="subtitle1"
              component="p"
              fontSize={12}
              sx={{ m: "-8px 0 8px !important", textAlign: "start !important" }}
              style={{ color: "red" }}
            >
              {data?.errors?.startEndDiff}
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p="15px"
          >
            <Button
              sx={[styles.actionBtn, styles.cancelBtn]}
              onClick={onCancel}
            >
              CANCEL
            </Button>

            <Button
              sx={[styles.actionBtn, styles.proceedBtn]}
              onClick={onSubmit}
            >
              SUBMIT & SHARE
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default AddDiscountModalContent;
const styles = {
  card: {
    minHeight: "100px",
    backgroundColor: BGColor02,
    borderRadius: "15px",
  },

  actionBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    color: blueColor04,
    border: `1px solid ${blueColor04}`,
    p: "4px 20px",
  },
  caption: {
    color: { xs: greyColor9, md: blueColor04 },
    textAlign: "right",
    fontSize: {xs:12, md:13},
    fontWeight: "500",
    textAlign: "center",
  },
  radiolabel: {
    color: greyColor6,
    textAlign: "right",
    fontSize: "12px",
    fontWeight: "500",
    textAlign: "center",
  },

  cancelBtn: {
    color:{xs: blackShade19, md:blueColor04},
    border: {xs:`1px solid ${blackShade19}`, md:`1px solid ${blueColor04}`},
    "&:hover": {
      color: whiteColor,
      backgroundColor: {xs: blackShade19, md:blueColor04},
    },
  },

  proceedBtn: {
    color: whiteColor,
    background: {xs: mobilePrimary, md:blueColor04},
    p: "5px 20px",
    border: "none",

    "&:hover": {
      color: {xs:mobilePrimary, md:blueColor04},
      background: whiteColor,
      border: {xs:`1px solid ${mobilePrimary}`, md:`1px solid ${blueColor04}`},
    },
  },
};
