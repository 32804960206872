import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import {
  BGColor02,
  blackColor,
  blackShade05,
  blueColorBG01,
  border25,
  cardBoderlightBlue2,
  cardBoderlightOrange,
  cardBoderlightPink,
  greyColorM01,
  lightOrange01,
  lightOrange03,
  logoImgborder1,
  orangeTextColor,
  redColorBG01,
  whiteColor,
} from "../configs/styles/muiThemes";
import { useNavigate } from "react-router-dom";
import ShareIcon from "@mui/icons-material/Share";

function BusinessMobileSideMenu({
  onCloseClick,
  disableMenu,
  activeMenu,
  activeSubMenu,
  hideBusinessData,
  activeBusinessInfo,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const navigate = useNavigate();
  const handleNavigattion = (route) => {
    navigate(route);
  };

  const whatsAppNumber = "+918088422615";
  const emailAddress = "support@costbo.com";
  const businessName = sessionStorage.getItem("costboWEBURL");
  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        // `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        `https://api.whatsapp.com/send/?text=${`https://shop.costbo.com/${
          activeBusinessInfo?.costboWebURL || businessName
        }/Catalog`}`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };
  return (
    <Box sx={styles.container}>
      {matches ? (
        navigate(`/${sessionStorage.getItem("costboWEBURL")}${"/dashboard"}`)
      ) : (
        <DashboardLayout activeMenu="">
          <Box py={3}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                component="h6"
                fontSize={{ xs: 15, md: 15 }}
                color={blackColor}
                fontWeight={600}
              >
                Dashboard & Controls
              </Typography>

              <IconButton
                sx={{
                  bgcolor: whiteColor,
                  borderRadius: "8px",
                  border: border25,
                }}
                onClick={() => {
                  handleChatClick();
                }}
              >
                <ShareIcon sx={{ fontSize: "16px", color: orangeTextColor }} />
              </IconButton>
            </Box>

            <Box sx={styles.orderCard}>
              <Grid container rowSpacing={1}>
                <Grid
                  item
                  xs={4}
                  sx={{ width: "100%" }}
                  onClick={() => {
                    navigate(
                      `/${sessionStorage.getItem(
                        "costboWEBURL"
                      )}${"/dashboard"}`
                    );
                  }}
                >
                  <MobileStatsCard
                    label={"Dashboard"}
                    icon={
                      <Box sx={styles.iconCircle}>
                        <Box
                          component="img"
                          src={
                            "https://storage.googleapis.com/bodefaults/businessdashboardweb/icon-dashboard.svg"
                          }
                          width="40px"
                        />
                      </Box>
                    }
                  />
                </Grid>

                {/* <Grid
                  item
                  xs={3}
                  sx={{ width: "100%" }}
                  onClick={() => {
                    handleChatClick();
                  }}
                >
                  <MobileStatsCard
                    label={"Share Catalog"}
                    icon={
                      <Box sx={styles.iconCircle}>
                        <Box
                          component="img"
                          src={
                            "https://storage.googleapis.com/bodefaults/businessdashboardweb/icon-share-catalog.svg"
                          }
                          width="40px"
                          //   height="128px"
                        />
                      </Box>
                    }
                  />
                </Grid> */}

                <Grid
                  item
                  xs={4}
                  sx={{ width: "100%" }}
                  onClick={() => {
                    navigate(
                      `/${sessionStorage.getItem(
                        "costboWEBURL"
                      )}${"/warehouseDetail"}`
                    );
                  }}
                >
                  <MobileStatsCard
                    label={"E-com Controls"}
                    icon={
                      <Box sx={styles.iconCircle}>
                        <Box
                          component="img"
                          src={
                            "https://storage.googleapis.com/bodefaults/businessdashboardweb/icon-ecommerce.svg"
                          }
                          width="40px"
                        />
                      </Box>
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{ width: "100%" }}
                  onClick={() => {
                    console.log("count0ne", "hitt");
                    navigate(
                      `/${sessionStorage.getItem(
                        "costboWEBURL"
                      )}${"/ONDCControls"}`
                    );
                  }}
                >
                  <MobileStatsCard
                    label={"ONDC Controls"}
                    icon={
                      <Box sx={styles.iconCircle}>
                        <Box
                          component="img"
                          src={
                            "https://storage.googleapis.com/bodefaults/businessdashboardweb/icon-ondc.svg"
                          }
                          width="40px"
                        />
                      </Box>
                    }
                  />
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                bgcolor: redColorBG01,
                border: cardBoderlightPink,
                mt: 2.3,
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <Box>
                <Typography
                  component="h6"
                  fontSize={{ xs: 15, md: 14.5 }}
                  color={blackColor}
                  fontWeight={600}
                  m="8px 15px"
                >
                  Order Management
                </Typography>
              </Box>

              <Box sx={{ bgcolor: whiteColor, p: "10px" }}>
                <Grid container>
                  <Grid
                    item
                    xs={4}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      console.log("count0ne", "hitt");
                      navigate(
                        `/${sessionStorage.getItem("costboWEBURL")}${"/orders"}`
                      );
                    }}
                  >
                    <MobileStatsCard
                      label={"Orders"}
                      icon={
                        <Box sx={styles.iconCircle}>
                          <Box
                            component="img"
                            src={
                              "https://storage.googleapis.com/bodefaults/businessdashboardweb/customer_order.svg"
                            }
                            width="40px"
                            //   height="128px"
                          />
                        </Box>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      navigate(
                        `/${sessionStorage.getItem(
                          "costboWEBURL"
                        )}${"/manageReturns"}`
                      );
                    }}
                  >
                    <MobileStatsCard
                      label={"Returns"}
                      icon={
                        <Box sx={styles.iconCircle}>
                          <Box
                            component="img"
                            src={
                              "https://storage.googleapis.com/bodefaults/businessdashboardweb/manage_return.svg"
                            }
                            width="40px"
                            //   height="128px"
                          />
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      navigate(
                        `/${sessionStorage.getItem(
                          "costboWEBURL"
                        )}${"/igmIssuesItem"}`
                      );
                    }}
                  >
                    <MobileStatsCard
                      label={"Grievance"}
                      icon={
                        <Box sx={styles.iconCircle}>
                          <Box
                            component="img"
                            src={
                              "https://storage.googleapis.com/bodefaults/businessdashboardweb/customer_grievance.svg"
                            }
                            width="40px"
                          />
                        </Box>
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box mt={2}>
              <Typography
                component="h6"
                fontSize={{ xs: 15, md: 14.5 }}
                color={blackColor}
                fontWeight={600}
              >
                Catalog, Inventory & Pricing
              </Typography>

              <Box sx={styles.orderCard}>
                <Grid container rowSpacing={1}>
                  <Grid
                    item
                    xs={3}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      console.log("count0ne", "hitt");
                      navigate(
                        `/${sessionStorage.getItem(
                          "costboWEBURL"
                        )}${"/catalog"}`
                      );
                    }}
                  >
                    <MobileStatsCard
                      label={"Catalog"}
                      icon={
                        <Box sx={styles.iconCircle}>
                          <Box
                            component="img"
                            src={
                              "https://storage.googleapis.com/bodefaults/businessdashboardweb/menu_catalog.svg"
                            }
                            width="40px"
                          />
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      console.log("count0ne", "hitt");
                      navigate(
                        `/${sessionStorage.getItem(
                          "costboWEBURL"
                        )}${"/allStock"}`
                      );
                    }}
                  >
                    <MobileStatsCard
                      label={"Inventory"}
                      icon={
                        <Box sx={styles.iconCircle}>
                          <Box
                            component="img"
                            src={
                              "https://storage.googleapis.com/bodefaults/businessdashboardweb/menu_inventory.svg"
                            }
                            width="40px"
                          />
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      console.log("count0ne", "hitt");
                      navigate(
                        `/${sessionStorage.getItem(
                          "costboWEBURL"
                        )}${"/pausedInventory"}`
                      );
                    }}
                  >
                    <MobileStatsCard
                      label={"Not Selling"}
                      icon={
                        <Box sx={styles.iconCircle}>
                          <Box
                            component="img"
                            src={
                              "https://storage.googleapis.com/bodefaults/businessdashboardweb/menu_paused_inventory.svg"
                            }
                            width="40px"
                          />
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      console.log("count0ne", "hitt");
                      navigate(
                        `/${sessionStorage.getItem(
                          "costboWEBURL"
                        )}${"/priceUpdate"}`
                      );
                    }}
                  >
                    <MobileStatsCard
                      label={"Price Update"}
                      icon={
                        <Box sx={styles.iconCircle}>
                          <Box
                            component="img"
                            src={
                              "https://storage.googleapis.com/bodefaults/businessdashboardweb/price_update.svg"
                            }
                            width="40px"
                          />
                        </Box>
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box mt={2}>
              <Typography
                component="h6"
                fontSize={{ xs: 15, md: 14.5 }}
                color={blackColor}
                fontWeight={600}
              >
                Marketing
              </Typography>

              <Box sx={styles.orderCard}>
                <Grid container rowSpacing={2} columnSpacing={0.5}>
                  <Grid
                    item
                    xs={4}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      navigate(
                        `/${sessionStorage.getItem(
                          "costboWEBURL"
                        )}${"/crmAnalysis"}`
                      );
                    }}
                  >
                    <MobileStatsCard
                      label={"CRM Analysis"}
                      icon={
                        <Box sx={styles.iconCircle}>
                          <Box
                            component="img"
                            src={
                              "https://storage.googleapis.com/bodefaults/businessdashboardweb/menu_crm.svg"
                            }
                            width="40px"
                          />
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      navigate(
                        `/${sessionStorage.getItem(
                          "costboWEBURL"
                        )}${"/ondcShareLink"}`
                      );
                    }}
                  >
                    <MobileStatsCard
                      label={"Share Links"}
                      icon={
                        <Box sx={styles.iconCircle}>
                          <Box
                            component="img"
                            src={
                              "https://storage.googleapis.com/bodefaults/businessdashboardweb/menu_share_link.svg"
                            }
                            width="40px"
                          />
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      navigate(
                        `/${sessionStorage.getItem(
                          "costboWEBURL"
                        )}${"/easyCartOrders"}`
                      );
                    }}
                  >
                    <MobileStatsCard
                      label={"Book Order"}
                      icon={
                        <Box sx={styles.iconCircle}>
                          <Box
                            component="img"
                            src={
                              "https://storage.googleapis.com/bodefaults/businessdashboardweb/menu_book_order.svg"
                            }
                            width="40px"
                          />
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      navigate(
                        `/${sessionStorage.getItem(
                          "costboWEBURL"
                        )}${"/affiliateMarketing"}`
                      );
                    }}
                  >
                    <MobileStatsCard
                      label={"Affiliate Marketing"}
                      icon={
                        <Box sx={styles.iconCircle}>
                          <Box
                            component="img"
                            src={
                              "https://storage.googleapis.com/bodefaults/businessdashboardweb/menu_affiliate_marketing.svg"
                            }
                            width="40px"
                          />
                        </Box>
                      }
                    />
                  </Grid>


                  <Grid
                    item
                    xs={4}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      navigate(
                        `/${sessionStorage.getItem(
                          "costboWEBURL"
                        )}${"/fintechB2BCS"}`
                      );
                    }}
                  >
                    <MobileStatsCard
                      label={`Fintech Program`}
                      icon={
                        <Box sx={styles.iconCircle}>
                          <Box
                            component="img"
                            src={
                              "https://storage.googleapis.com/bodefaults/businessdashboardweb/menu_fintech_program.svg?token=t1"
                            }
                            width="40px"
                          />
                        </Box>
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box
              sx={{
                bgcolor: whiteColor,
                border: cardBoderlightOrange,
                mt: 2.3,
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <Box>
                <Typography
                  component="h6"
                  fontSize={{ xs: 15, md: 14.5 }}
                  color={blackColor}
                  fontWeight={600}
                  m="8px 15px"
                >
                  Discounts
                </Typography>
              </Box>

              <Box sx={{ bgcolor: lightOrange03, p: "10px 0" }}>
                <Grid container rowSpacing={2} pt={0}>
                  <Grid
                    item
                    xs={4}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      navigate(
                        `/${sessionStorage.getItem(
                          "costboWEBURL"
                        )}${"/couponsDiscount"}`
                      );
                    }}
                  >
                    <MobileStatsCard
                      label={"Coupons"}
                      icon={
                        <Box sx={styles.iconCircle}>
                          <Box
                            component="img"
                            src={
                              "https://storage.googleapis.com/bodefaults/businessdashboardweb/menu_generate_coupon.svg"
                            }
                            width="40px"
                          />
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      navigate(
                        `/${sessionStorage.getItem(
                          "costboWEBURL"
                        )}${"/cashBack"}`
                      );
                    }}
                  >
                    <MobileStatsCard
                      label={"Loyalty Points"}
                      icon={
                        <Box sx={styles.iconCircle}>
                          <Box
                            component="img"
                            src={
                              "https://storage.googleapis.com/bodefaults/businessdashboardweb/loyalty_points.svg"
                            }
                            width="40px"
                          />
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      navigate(
                        `/${sessionStorage.getItem(
                          "costboWEBURL"
                        )}${"/preferredDiscounts"}`
                      );
                    }}
                  >
                    <MobileStatsCard
                      label={"Personalized Discounts"}
                      icon={
                        <Box sx={styles.iconCircle}>
                          <Box
                            component="img"
                            src={
                              "https://storage.googleapis.com/bodefaults/businessdashboardweb/personalized_discounts.svg"
                            }
                            width="40px"
                          />
                        </Box>
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box
              sx={{
                bgcolor: blueColorBG01,
                border: cardBoderlightBlue2,
                mt: 2.3,
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <Box>
                <Typography
                  component="h6"
                  fontSize={{ xs: 15, md: 14.5 }}
                  color={blackColor}
                  fontWeight={600}
                  m="8px 15px"
                >
                  Others
                </Typography>
              </Box>

              <Box sx={{ bgcolor: whiteColor, p: "10px 0" }}>
                <Grid container rowSpacing={1}>
                  <Grid
                    item
                    xs={12}
                    // sx={styles.orderDateCardMobile(lightOrange01)}
                    width={"100%"}
                  >
                    <Grid container rowSpacing={2} pt={0}>
                      <Grid
                        item
                        xs={4}
                        sx={{ width: "100%" }}
                        onClick={() => {
                          //   handleNavigattion(
                          //     `/${sessionStorage.getItem("costboWEBURL")}${"/dashboard"}`
                          //   );
                          console.log("count0ne", "hitt");
                          navigate(
                            `/${sessionStorage.getItem(
                              "costboWEBURL"
                            )}${"/reportsCS"}`
                          );
                        }}
                      >
                        <MobileStatsCard
                          label={"Report"}
                          icon={
                            <Box sx={styles.iconCircle}>
                              <Box
                                component="img"
                                src={
                                  "https://storage.googleapis.com/bodefaults/businessdashboardweb/report.svg"
                                }
                                width="40px"
                                //   height="128px"
                              />
                            </Box>
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{ width: "100%" }}
                        onClick={() => {
                          //   handleNavigattion(
                          //     `/${sessionStorage.getItem("costboWEBURL")}${"/dashboard"}`
                          //   );
                          console.log("count0ne", "hitt");
                          navigate(
                            `/${sessionStorage.getItem(
                              "costboWEBURL"
                            )}${"/subscriptionsCS"}`
                          );
                        }}
                      >
                        <MobileStatsCard
                          label={"Subscriptions"}
                          icon={
                            <Box sx={styles.iconCircle}>
                              <Box
                                component="img"
                                src={
                                  "https://storage.googleapis.com/bodefaults/businessdashboardweb/subscription.svg"
                                }
                                width="40px"
                                //   height="128px"
                              />
                            </Box>
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{ width: "100%" }}
                        onClick={() => {
                          //   handleNavigattion(
                          //     `/${sessionStorage.getItem("costboWEBURL")}${"/dashboard"}`
                          //   );
                          console.log("count0ne", "hitt");
                          navigate("/howToVideoGuides");
                        }}
                      >
                        <MobileStatsCard
                          label={"How-To-Videos"}
                          icon={
                            <Box sx={styles.iconCircle}>
                              <Box
                                component="img"
                                src={
                                  "https://storage.googleapis.com/bodefaults/businessdashboardweb/how-videos.svg"
                                }
                                width="40px"
                                //   height="128px"
                              />
                            </Box>
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sx={{ width: "100%" }}
                        onClick={() => {
                          //   handleNavigattion(
                          //     `/${sessionStorage.getItem("costboWEBURL")}${"/dashboard"}`
                          //   );
                          console.log("count0ne", "hitt");
                          navigate(
                            `/${sessionStorage.getItem(
                              "costboWEBURL"
                            )}${"/support"}`
                          );
                        }}
                      >
                        <MobileStatsCard
                          label={"Support"}
                          icon={
                            <Box sx={styles.iconCircle}>
                              <Box
                                component="img"
                                src={
                                  "https://storage.googleapis.com/bodefaults/businessdashboardweb/support.svg"
                                }
                                width="40px"
                                //   height="128px"
                              />
                            </Box>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </DashboardLayout>
      )}
    </Box>
  );
}

export default BusinessMobileSideMenu;

const MobileStatsCard = ({
  icon,
  label,
  bgColor,
  labelColor,
  hideMobile,
  onClick,
}) => {
  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Box sx={styles.iconContainer(bgColor, hideMobile)}>{icon}</Box>
      <Typography
        variant="body2"
        fontSize={{ xs: 11.2, md: 11.28 }}
        color={labelColor || blackShade05}
        fontWeight={600}
        lineHeight="16px"
        textAlign={"center"}
        mt={1}
      >
        {label}
      </Typography>
    </Box>
  );
};

const styles = {
  container: {
    backgroundColor: BGColor02,
  },

  orderCard: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "12px", md: "12px" },
    mt: 1.8,
  },

  iconContainer: (bgColor, hideMobile) => ({
    width: { md: "40px", xs: "40px" },
    height: { md: "40px", xs: "40px" },
    borderRadius: "10px",
    backgroundColor: bgColor,
    display: { md: "flex", xs: hideMobile ? "none" : "flex" },
    alignItems: "center",
    justifyContent: "center",
  }),
  icon: (color) => ({ fontSize: { md: 20, xs: 17 }, color: color }),
  orderDateCardMobile: (color, borderColor) => ({
    backgroundColor: { xs: color ? color : lightOrange01, md: whiteColor },
    border: `1px solid ${borderColor}`,
    borderRadius: { xs: "10px", md: "12px" },
    // boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "4px 6px", md: "12px" },
  }),
};