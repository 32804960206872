import moment from "moment";

const OPEN_DAYS = [
  {
    id: "7",
    label: "Sunday",
    isOpen: false,
  },
  {
    id: "1",
    label: "Monday",
    isOpen: false,
  },
  {
    id: "2",
    label: "Tuesday",
    isOpen: false,
  },
  {
    id: "3",
    label: "Wednesday",
    isOpen: false,
  },
  {
    id: "4",
    label: "Thursday",
    isOpen: false,
  },
  {
    id: "5",
    label: "Friday",
    isOpen: false,
  },
  {
    id: "6",
    label: "Saturday",
    isOpen: false,
  },
];

export const getONDCDomainData = (selectedDomainArr, ondcDomainArr = []) => {
  const catList = [];

  selectedDomainArr?.forEach((item) => {
    const category = ondcDomainArr?.filter(
      (categoryItem) => categoryItem?.domainCode === item
    );

    if (category?.length > 0) {
      catList.push(category?.[0]?.domainName);
    }
  });

  console.log("catList___:", catList);

  return catList;
};

export const getSupportRegionData = (status) => {
  switch (status) {
    case "locality":
      return { label: "Locality", value: "locality" };

    case "intercity":
      return { label: "Intercity", value: "intercity" };

    case "nationwide":
      return { label: "Nationwide", value: "nationwide" };

    default:
      return { label: "Select Shipment Region", value: "none" };
  }
};

export const getFullCancelStageData = (status) => {
  switch (status) {
    case "ordercreated":
      return { label: "Order Created", value: "ordercreated" };

    case "orderapproved":
      return { label: "Order Approved", value: "orderapproved" };

    case "ordershipped":
      return { label: "Order Shipped", value: "ordershipped" };

    default:
      return { label: "Select Full Cancel", value: "" };
  }
};

export const getOpenDaysData = (openDays) => {
  const openDaysArr = OPEN_DAYS.map((item) => {
    if (openDays?.includes(item?.id)) {
      return {
        ...item,
        isOpen: true,
      };
    }
    return item;
  });

  return openDaysArr;
};

// 1230 => 12:30
export const convertToTimeFormat = (timeString) => {
  if (/^\d{4}$/.test(timeString)) {
    const hours = timeString.slice(0, 2);
    const minutes = timeString.slice(2);

    return `${hours}:${minutes}`;
  } else {
    return "";
  }
};

// Function to check if two time intervals overlap
export const doIntervalsOverlap = (interval1, interval2) => {
  const start1 = moment(interval1.storeStartTime, "HH:mm");
  const end1 = moment(interval1.storeEndTime, "HH:mm");
  const start2 = moment(interval2.storeStartTime, "HH:mm");
  const end2 = moment(interval2.storeEndTime, "HH:mm");

  return start1.isBefore(end2) && start2.isBefore(end1);
};

export const getIsOpen24HrData = (operatingHours, openDays) => {
  const startTime = operatingHours?.[0]
  const endTime = operatingHours?.[1]

  const openDaysList = openDays?.split(",")

  return (startTime == '0000' && endTime == '2359' && openDaysList?.length === 7) ? 'yes' : 'no'
};

// calculate End Time using frequency
const calculateEndTime = (startTime, frequency) =>{
  // Parse the start time and duration
  const startTimeMoment = moment(startTime, 'HH:mm');
  const durationMoment = moment.duration(frequency);

  // Calculate the end time
  const endTimeMoment = startTimeMoment.clone().add(durationMoment);

  // Format the end time as HH:mm
  const endTime = endTimeMoment.format('HH:mm');
  
  return endTime;
}

export const getStoreTimingList = (timeArr, frequency, operatingHours, openDays) => {
  const startTime = operatingHours?.[0]
  const endTime = operatingHours?.[1]

  const openDaysList = openDays?.split(",")

  const timeObjects = [];

  console.log("timeArr___:", timeArr);
  console.log("timeArr___startTime:", startTime);
  console.log("timeArr___endTime:", endTime);
  console.log("timeArr___openDaysList:", openDaysList);
  console.log("timeArr___Con:", timeArr?.length);

  if(timeArr?.length === 0 && operatingHours?.length > 0){
    console.log("timeArr___HITTR:");
    const storeStartTime = startTime?.substring(0, 2) + ":" + startTime?.substring(2);
    const storeEndTime = endTime?.substring(0, 2) + ":" + endTime?.substring(2); 
      
    timeObjects.push({ storeStartTime, storeEndTime });
  }else{
    if(timeArr?.length <= 0) return [{ storeStartTime: "", storeEndTime: "" }]

    for (let i = 0; i < timeArr?.length; i ++) {
      const storeStartTime = timeArr[i].substring(0, 2) + ":" + timeArr[i].substring(2);
      const storeEndTime = calculateEndTime(timeArr[i].substring(0, 2) + ":" + timeArr[i].substring(2), frequency);
      
      timeObjects.push({ storeStartTime, storeEndTime });
    }
  }

  console.log("timeObjects___:", timeObjects);
  
  return timeObjects;
};
