import React, { useEffect } from "react";
import {
  BGColor02,
  blackShade05,
  greyColor15,
  greyColor16,
  greyColor6,
  logoImgborder1,
  tableAvatarBGColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import {
  Avatar,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EmptyCard from "../../components/Cards/EmptyCard";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import {
  clearAffiliatePanelInfo,
  getAllTopAffiliatesData,
} from "../../reducers/AffiliatePanels/AffiliateSlice";
import { useDispatch, useSelector } from "react-redux";
import ProfileCharAvatar from "../../components/ProfileCharAvatar";
import { addThousandsSeparator } from "../../utils/helper";
import { scrollBox } from "../../utils/styles";
import ThreeDotLoader from "../../components/ThreeDotLoader";


function TopAffiliates({ topAffiiliates, topAffiliatesLoader }) {
  // function createData(name, commission, commissionDiscount, orders, sales) {
  //   return { name, commission, commissionDiscount, orders, sales };
  // }
  // const rows = [
  //   createData("Ranganathan", 12, 10, 1875, 23500),
  //   createData("Satish Gangaiah", 15, 15, 3790, 32752),
  //   createData("Praveen N", 10, 10, 2830, 33856),
  // ];
  const dispatch = useDispatch();

  // const { topAffiiliates } = useSelector(
  //   (state) => state.affiliatePanelControl
  // );

  // useEffect(() => {
  //   const businessId = sessionStorage.getItem("selectedBusinessId");
  //   // if (topAffiiliates) {
  //   dispatch(getAllTopAffiliatesData(businessId));
  //   // }
  //   return () => {
  //     // dispatch(clearAffiliatePanelInfo());
  //   };
  // }, []);
  return (
    <Box sx={styles.infoBox}>
      <Box display="flex" justifyContent="space-between" padding="15px">
        <Typography
          variant="h3"
          color={blackShade05}
          fontSize={{xs:14,md:"15px"}}
          fontWeight="600"
        >
          Top Affiliates
        </Typography>
      </Box>
      {topAffiliatesLoader && <ThreeDotLoader />}
      {!topAffiliatesLoader && topAffiiliates?.details.length > 0 ? (
        <>
          <TableContainer component={Box}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ position: "sticky" }}>
                <TableRow
                  sx={{ position: "sticky", backgroundColor: BGColor02 }}
                >
                  <TableCell
                    sx={{ ...styles.tableCell, p: "0 5px 0 15px !important" }}
                  >
                    Name
                  </TableCell>
                  <TableCell sx={styles.tableCell}>Commission</TableCell>
                  <TableCell sx={styles.tableCell}>Customer Discount</TableCell>
                  <TableCell sx={styles.tableCell}>Orders</TableCell>
                  <TableCell
                    sx={{
                      ...styles.tableCell,
                      textAlign: "right",
                      padding: "10px 35px 10px 5px",
                    }}
                  >
                    Sales
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {topAffiiliates?.details.map((item, index) => {
                  const userInfo = topAffiiliates?.profileInfo.filter(
                    (profile) => item?.affiliateId === profile.profileId
                  );
                  console.log("userInfo", userInfo);

                  let username = "";
                  let profilePic = "";

                  if (userInfo?.length > 0) {
                    username = userInfo?.[0]?.name;
                    profilePic = userInfo?.[0]?.photoUrl;
                  }
                  return (
                    <TableRow
                      key={username}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{
                          ...styles.tableBodyCell(),
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          ml: 1,
                        }}
                        component="th"
                        scope="row"
                      >
                        {profilePic ? (
                          <Avatar
                            src={profilePic}
                            sx={{
                              ...styles.avatar(tableAvatarBGColor),
                              width: "30px",
                              height: "30px",
                              display: { xs: "flex", md: "flex" },
                            }}
                          ></Avatar>
                        ) : (
                          <ProfileCharAvatar
                            username={username || "Guest"}
                            border
                            // size={30}
                            sx={{
                              ...styles.avatar(tableAvatarBGColor),
                            }}
                          />
                        )}{" "}
                        {username}
                      </TableCell>

                      <TableCell sx={styles.tableBodyCell()}>
                        {item?.comissionPercentage}%
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        {item?.discountPercentage}%
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell()}>
                        {item?.numberOfOrders}
                      </TableCell>
                      <TableCell
                        sx={{
                          ...styles.tableBodyCell(),
                          textAlign: "right",
                          padding: "10px 30px 10px 5px",
                        }}
                      >
                        ₹ {addThousandsSeparator(item?.sumOfOrderTotalCost)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        !topAffiliatesLoader && topAffiiliates?.details.length === 0 && (
          <Box mb={{ md: 4, xs: 0 }} mt={{md:0,xs:-2}}>
            <EmptyCard hideBorder noMargin msg="No Top Affiliates" />
          </Box>
        )
      )}
    </Box>
  );
}

export default TopAffiliates;

const styles = {
  infoBox: {
    flex:1,
    display: "flex",
    flexDirection: "column",
    height:{md:"210px",xs:"auto"},
    width:{md:"auto",xs:"100%"},
    // alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: whiteColor,
    baxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    border: logoImgborder1,
    borderRadius: "10px",
    // marginTop: "22px",
  },

  tableHeadings: {
    align: "right",
    color: greyColor16,
    fontSize: "12px",
    fontWeight: "500",
    backgroundColor: BGColor02,
  },

  tableCell: {
    height: "34px !important",
    padding: "5px 5px !important",
  },

  tableBodyCell: (color) => ({
    fontSize: 12,
    fontWeight: 500,
    color: color || greyColor15,
    padding: "10px 5px !important",
  }),

  avatar: (color) => ({
    // width:25,
    // height: 25,
    fontSize: "12px",
    fontWeight: 600,
    color: greyColor16,
    // color: whiteColor,
    // backgroundColor: color || BGColor02,
    backgroundColor: color,
    // border: `1px solid ${color}`,
    ml: 1,
  }),
};
