import { InputAdornment, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import React from "react";
import {
  BGColor02,
  blackColor,
  blackShade02,
  blackShade05,
  borderTextbox,
  descriptionColor,
  errorborderTextbox,
  greyColor6,
  whiteColor,
} from "../../configs/styles/muiThemes";

function NumberSearchInput({
  label,
  placeholder,
  name,
  value,
  onChange,
  startIcon,
  icon,
  onIconClick,
  showPassword,
  maxLength,
  inputType,
  // isError,
  error,
  errorMsg,
  helperText,
  autoFocus,
  inputRef,
  onKeyUp,
  onBlur,
  disabled,
  isDashboardInput,
  hideLabel,
  required,
  inputOpaticy,
}) {
  return (
    <Box
      sx={{
        opacity: inputOpaticy ? inputOpaticy : disabled ? 0.5 : 1,
        width: { xs: "100%", md: "auto" },
      }}
    >
      {!hideLabel ? (
        <>
          <Typography
            variant="h3"
            fontSize={isDashboardInput ? 14 : 13}
            color={isDashboardInput ? blackColor : greyColor6}
            fontWeight={isDashboardInput ? 500 : 500}
            mb={isDashboardInput ? "8px !important" : "0px"}
            sx={{ display: "inline" }}
          >
            {label}{" "}
          </Typography>
          {required ? (
            <Typography
              variant="body1"
              sx={{ display: "inline", color: "#f00" }}
            >
              *
            </Typography>
          ) : null}
        </>
      ) : null}

      <TextField
        variant="standard"
        required={true}
        fullWidth
        name={name}
        disabled={disabled}
        value={value}
        onBlur={onBlur}
        autoFocus={autoFocus}
        onChange={onChange}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        inputRef={inputRef}
        sx={{
          fontSize: 13,
          fontWeight: 500,
          color: blackShade05,
          borderTop: "none",
          borderBottom: borderTextbox,
          borderLeft: "none",
          borderRight: "none",
          borderRadius: "0px",
          backgroundColor: BGColor02,

          "& .Mui-focused": {
            borderBottom: `1px solid ${blackColor}`,
          },
        }}
        InputProps={{
          startAdornment: startIcon ? (
            <InputAdornment
              position="start"
              sx={{
                color: `${blackShade05} !important`,
                fontSize: 13.5,
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: 13, fontWeight: 500, color: blackShade05 }}
              >
                {startIcon}
              </Typography>
            </InputAdornment>
          ) : (
            <Box ml={-1}/>
          ),
          endAdornment: (
            <InputAdornment position="end" sx={{ cursor: "pointer" }}>
              {icon}
            </InputAdornment>
          ),
          style: {
            width: "100%",
            padding: isDashboardInput ? "7px 12px" : "4px 8px",
            color: grey,
            fontSize: 13.5,
            fontWeight: 500,
          },
          disableUnderline: true,
        }}
        inputProps={{ maxLength: maxLength }}
        type={inputType}
      />

      {error ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={12}
          sx={{ mt: "4px !important", textAlign: "start !important" }}
          style={{ color: "red" }}
        >
          {error}
        </Typography>
      ) : null}

      {helperText ? (
        <Typography
          variant="body1"
          component="p"
          fontSize={11}
          color={descriptionColor}
        >
          {helperText}
        </Typography>
      ) : null}
    </Box>
  );
}

export default NumberSearchInput;
