import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  blackColor,
  primaryColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { stickyHeader } from "../../utils/styles";
import HowToLinkCard from "../../components/Cards/HowToLinkCard";
import ProductDetailsTab from "./Tabs/ProductDetailsTab";
import ProductImageSpecificationTab from "./Tabs/ProductImageSpecificationTab";
import ProductONDCTab from "./Tabs/ProductONDCTab";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_COUNTRY } from "../../utils/data";
import { ONDC_DEFAULT_NEW_DATA } from "../../utils/data/defaultFormData";
import { parseDbObjectInsideArray } from "../../utils/validationHelper";
import moment from "moment";
import {
  getAddNewProductPostData,
  prepareAddNewProductPhotoData,
  prepareAddNewProductPostData,
  prepareProductPreviewData,
} from "../../utils/postDataHelpers/CatalogHelper";
import ModalCard from "../../components/ModalCard";
import { useNavigate } from "react-router-dom";
import {
  addNewProductApi,
  getUnitOfMeasurementApi,
} from "../../service/api_calls/catalog/CatalogAPI";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import ProductInfoPreview from "./DataPreview/ProductInfoPreview";
import { countMatch } from "../../utils/helper";
import { clearCatalogData } from "../../reducers/businessPreview/businessPreviewSlice";

function AddProductNewContent() {
  const [tab, setTab] = useState("product-details");
  // const [tab, setTab] = useState("preview");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showAlert, setShowAlert] = useState(false);
  const [addProductLoader, setAddProductLoader] = useState(false);

  const [categoryInfo, setCategoryInfo] = useState({
    ondcEnabled: "yes",
    businessCategory: [],
    productCategory: [],
    productSubCategory: [],
    ondcCategory: [],
    ondcSubCategory: [],
    selectedBusinessCat: "",
    selectedProductCat: "",
    selectedProductSubCat: "",
    selectedOndcCat: "",
    selectedOndcSubCat: "",

    errors: null,
  });

  const [productInfo, setProductInfo] = useState({
    productName: "",
    brand: "",
    description: "",
    country: DEFAULT_COUNTRY,
    skuId: "",

    errors: null,
  });

  const [taxDetails, setTaxDetails] = useState({
    gstPercentage: "",
    productCode: { key: "EAN", value: "" },
  });

  const [productImages, setProductImages] = useState({
    productImgOne: "",
    productImgTwo: "",
    productImgThree: "",
    productImgFour: "",
  });

  const [ondcData, setOndcData] = useState(ONDC_DEFAULT_NEW_DATA);
  const [productImgUpdate, setProductImgUpdate] = useState([]);

  const [priceInfo, setPriceInfo] = useState({
    currency: "₹",
    originalPrice: "",
    OfferPrice: "",
    taxDetails: "false",
    gstHsnCode: "",

    errors: null,
  });

  const [availabilityInfo, setAvailabilityInfo] = useState({
    availability: "Yes",
    availabelQty: "",
    alertQty: "",
    minQty: "",
    maxQty: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: "",

    errors: null,
  });

  const [measurementInfo, setMeasurementInfo] = useState({
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
    netProductWeight: "",
    deliveryMethod: [],

    errors: null,
  });

  const [productSpec, setProductSpec] = useState({
    productSpecs: [{ code: "", value: [""] }],
    refLinks: [{ code: "", link: "" }],
  });

  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  const [ondcSpecificationData, setOndcSpecificationData] = useState([]);

  const [productPreviewData, setProductPreviewData] = useState(null);

  const [productCodeList, setProductCodeList] = useState(null);
  const [unitOfMeasurementData, setUnitOfMeasurementData] = useState([]);

  const [errorPageOne, setErrorPageOne] = useState(false);
  const [errorPageTwo, setErrorPageTwo] = useState(false);
  const [errorPageThree, setErrorPageThree] = useState(false);

  const handleSetValue = (name, value, state, setState) => {
    if (name !== "errors") {
      setState({
        ...state,
        [name]: value,
        errors: { ...state?.errors, [name]: "" },
      });
      return;
    }
    setState({
      ...state,
      [name]: value,
    });
  };

  const validateProductDetailsFormOne = () => {
    let categoryErr = null;
    let productInfoErr = null;
    let taxInfoErr = null;

    console.log("validateProductDetailsFormOne__categoryInfo:", categoryInfo);
    console.log("validateProductDetailsFormOne__productInfo:", productInfo);
    console.log("validateProductDetailsFormOne__taxDetails:", taxDetails);

    // ONDC categories
    if (categoryInfo?.ondcEnabled === "yes") {
      if (!categoryInfo?.selectedOndcCat) {
        categoryErr = {
          ...categoryErr,
          selectedOndcCat: "Select ONDC category",
        };
      }
      if (!categoryInfo?.selectedOndcSubCat) {
        categoryErr = {
          ...categoryErr,
          selectedOndcSubCat: "Select ONDC sub category",
        };
      }
    }

    // Webstore Category
    if (!categoryInfo?.selectedBusinessCat) {
      categoryErr = {
        ...categoryErr,
        selectedBusinessCat: "Select business category",
      };
    }
    if (!categoryInfo?.selectedProductCat) {
      categoryErr = {
        ...categoryErr,
        selectedProductCat: "Select product category",
      };
    }

    // Product Info
    if (!productInfo?.productName) {
      productInfoErr = { ...productInfoErr, productName: "Enter product name" };
    }
    if (!productInfo?.description) {
      productInfoErr = { ...productInfoErr, description: "Enter description" };
    }
    if (!productInfo?.country) {
      productInfoErr = { ...productInfoErr, country: "Select origin" };
    }

    // Tax Info
    // if (!taxDetails?.productCode?.value) {
    //   taxInfoErr = { ...taxInfoErr, productCode: "Enter product code" };
    // }

    // if (
    //   taxDetails?.productCode?.key === "EAN" &&
    //   taxDetails?.productCode?.value?.length != 13
    // ) {
    //   taxInfoErr = {
    //     ...taxInfoErr,
    //     productCode: "Enter a valid 13 digit EAN code",
    //   };
    // }
    // if (
    //   taxDetails?.productCode?.key === "ISBN" &&
    //   taxDetails?.productCode?.value?.length != 13
    // ) {
    //   taxInfoErr = {
    //     ...taxInfoErr,
    //     productCode: "Enter a valid 13 digit ISBN code",
    //   };
    // }
    // if (
    //   taxDetails?.productCode?.key === "GTIN" &&
    //   taxDetails?.productCode?.value?.length != 12
    // ) {
    //   taxInfoErr = {
    //     ...taxInfoErr,
    //     productCode: "Enter a valid 12 digit GTIN code",
    //   };
    // }
    // if (
    //   taxDetails?.productCode?.key === "HSN" &&
    //   taxDetails?.productCode?.value?.length != 8
    // ) {
    //   taxInfoErr = {
    //     ...taxInfoErr,
    //     productCode: "Enter a valid 8 digit HSN code",
    //   };
    // }
    // if (
    //   taxDetails?.productCode?.key === "OTHERS" &&
    //   taxDetails?.productCode?.value?.length != 13
    // ) {
    //   taxInfoErr = {
    //     ...taxInfoErr,
    //     productCode: "Enter a valid 13 digit code",
    //   };
    // }

    if (!taxDetails?.gstPercentage) {
      taxInfoErr = { ...taxInfoErr, gstPercentage: "Select GST percentage" };
    }

    console.log(
      "validateProductDetailsFormOne__ERRORS:",
      productInfoErr,
      categoryErr
    );

    if (categoryErr || productInfoErr || taxInfoErr) {
      setErrorPageOne(true);
      if (categoryErr) {
        setCategoryInfo((prevState) => ({
          ...prevState,
          errors: categoryErr,
        }));
      }

      if (productInfoErr) {
        setProductInfo((prevState) => ({
          ...prevState,
          errors: productInfoErr,
        }));
      }

      if (taxInfoErr) {
        setTaxDetails((prevState) => ({
          ...prevState,
          errors: taxInfoErr,
        }));
      }
    } else {
      setErrorPageOne(false);
      setCategoryInfo((prevState) => ({
        ...prevState,
        errors: null,
      }));

      setProductInfo((prevState) => ({
        ...prevState,
        errors: null,
      }));
      // setTab("product-ondc");
      setTab("product-images");
      window.scrollTo(0, 0);
    }
  };

  const validateProductDetailsFormTwo = () => {
    document.querySelector("body").focus();

    let productImgErr = null;
    let priceInfoErr = null;
    let availabilityErr = null;
    let measurementErr = null;
    let productSpecErr = null;

    console.log("validateProductDetailsFormTwo__Info", {
      productImages,
      productImgUpdate,
      priceInfo,
      availabilityInfo,
      measurementInfo,
      productSpec,
    });

    if (productImgUpdate?.length === 0) {
      productImgErr = {
        ...productImgErr,
        productImg: "Upload atleast one product photo",
      };
    }

    // Price Info
    if (!priceInfo?.originalPrice) {
      priceInfoErr = {
        ...priceInfoErr,
        originalPrice: "Please fill out this field",
      };
    }

    if (priceInfo?.originalPrice && Number(priceInfo?.originalPrice) <= 0) {
      priceInfoErr = {
        ...priceInfoErr,
        originalPrice: "Original price should be greater than 0",
      };
    }

    if (!priceInfo?.OfferPrice) {
      priceInfoErr = { ...priceInfoErr, OfferPrice: true };
    }

    if (Number(priceInfo?.OfferPrice) > Number(priceInfo?.originalPrice)) {
      priceInfoErr = { ...priceInfoErr, priceError: true };
    }

    // Availability Info
    if (!availabilityInfo?.availabelQty) {
      availabilityErr = {
        ...availabilityErr,
        availabelQty: "Enter available quantity",
      };
    }
    if (!availabilityInfo?.alertQty) {
      availabilityErr = {
        ...availabilityErr,
        alertQty: "Enter alert quantity",
      };
    }
    if (!availabilityInfo?.minQty) {
      availabilityErr = {
        ...availabilityErr,
        minQty: "Enter minimum quantity",
      };
    }
    if (!availabilityInfo?.maxQty) {
      availabilityErr = {
        ...availabilityErr,
        maxQty: "Enter maximum quantity",
      };
    }
    if (!availabilityInfo?.startDate) {
      availabilityErr = {
        ...availabilityErr,
        startDate: "Enter startDate quantity",
      };
    }
    if (!availabilityInfo?.endDate) {
      availabilityErr = {
        ...availabilityErr,
        endDate: "Enter endDate quantity",
      };
    }

    if (
      Number(availabilityInfo?.availabelQty) <=
        Number(availabilityInfo?.alertQty) ||
      Number(availabilityInfo?.availabelQty) < Number(availabilityInfo?.maxQty)
    ) {
      availabilityErr = {
        ...availabilityErr,
        availabilityError:
          "Available Quantity should be greater than Alert Quantity & Maximum Quantity Per Order",
      };
    }

    if (Number(availabilityInfo?.maxQty) < Number(availabilityInfo?.minQty)) {
      availabilityErr = {
        ...availabilityErr,
        maxQtyError:
          "Maximum Quantity Per Order should be greater than Minimum Quantity Per Order",
      };
    }

    if (Number(availabilityInfo?.alertQty) < Number(availabilityInfo?.minQty)) {
      availabilityErr = {
        ...availabilityErr,
        alertQtyError: "Alert quantity must be more than Min order quantity",
      };
    }

    if (
      moment(availabilityInfo?.startDate)?.isAfter(
        moment(availabilityInfo?.endDate)
      )
    ) {
      availabilityErr = {
        ...availabilityErr,
        startDate: "Start date should be less than End date",
      };
    }

    // Measurement Info
    if (!measurementInfo?.weight || measurementInfo?.weight == 0) {
      measurementErr = { ...measurementErr, weight: "Enter product weight" };
    }

    if (
      !measurementInfo?.netProductWeight ||
      measurementInfo?.netProductWeight == "0"
    ) {
      measurementErr = {
        ...measurementErr,
        netProductWeight: "Enter net product weight",
      };
    }

    if (
      measurementInfo?.netProductWeight > "0" &&
      Number(measurementInfo?.netProductWeight) <
        Number(measurementInfo?.weight)
    ) {
      measurementErr = {
        ...measurementErr,
        netProductWeight:
          "Net product weight should be greater than product weight",
      };
    }

    // Product Specs
    const isSpecError = productSpec?.productSpecs?.some(
      (item) =>
        (item?.code === "" && item?.value?.[0] !== "") ||
        (item?.code !== "" && item?.value?.[0] === "")
    );

    // console.log("validateProductDetailsFormTwo__isSpecError__:", isSpecError);
    if (isSpecError) {
      productSpecErr = {
        ...productSpecErr,
        productSpecs: "Enter the label and value",
      };
    }

    const isRefLinkError = productSpec?.refLinks?.some(
      (item) =>
        (item?.code === "" && item?.link !== "") ||
        (item?.code !== "" && item?.link === "")
    );

    // console.log("validateProductDetailsFormTwo__isRefLinkError__:", isRefLinkError);
    if (isRefLinkError) {
      productSpecErr = {
        ...productSpecErr,
        refLinks: "Enter the label and value",
      };
    }

    if (
      productImgErr ||
      priceInfoErr ||
      availabilityErr ||
      measurementErr ||
      productSpecErr
    ) {
      setErrorPageTwo(true);
      if (productImgErr) {
        setProductImages((prevState) => ({
          ...prevState,
          errors: productImgErr,
        }));
      }

      if (priceInfoErr) {
        setPriceInfo((prevState) => ({
          ...prevState,
          errors: priceInfoErr,
        }));
      }

      if (availabilityErr) {
        setAvailabilityInfo((prevState) => ({
          ...prevState,
          errors: availabilityErr,
        }));
      }

      if (measurementErr) {
        setMeasurementInfo((prevState) => ({
          ...prevState,
          errors: measurementErr,
        }));
      }

      if (productSpecErr) {
        setProductSpec((prevState) => ({
          ...prevState,
          errors: productSpecErr,
        }));
      }
    } else {
      setErrorPageTwo(false);
      setPriceInfo((prevState) => ({
        ...prevState,
        errors: null,
      }));

      setAvailabilityInfo((prevState) => ({
        ...prevState,
        errors: null,
      }));

      setMeasurementInfo((prevState) => ({
        ...prevState,
        errors: null,
      }));

      setProductSpec((prevState) => ({
        ...prevState,
        errors: null,
      }));

      setProductImages((prevState) => ({
        ...prevState,
        errors: null,
      }));

      if (categoryInfo?.ondcEnabled === "yes") {
        setTab("product-ondc");
        window.scrollTo(0, 0);
      } else {
        // updateProductDetails();
        const previewData = prepareProductPreviewData({
          categoryInfo,
          productInfo,
          taxDetails,
          productImgUpdate,
          priceInfo,
          availabilityInfo,
          measurementInfo,
          productSpec,
          ondcData,
          ondcSpecificationData,
        });

        console.log("previewData__;", previewData);
        setProductPreviewData(previewData);
        setTab("preview");
        window.scrollTo(0, 0);
      }
    }
  };

  const validateOndc = (isSubmit) => {
    console.log("ondcSpecificationData", ondcSpecificationData);
    console.log("ondcData", ondcData);

    // handleSetValue("errors", null, ondcData, setOndcData);

    let errors = null;
    let specificationError = false;

    if (!ondcData?.timeToShip || ondcData?.timeToShip == "0") {
      errors = { ...errors, timeToShip: "Enter time to ship" };
    }

    if (
      !ondcData?.returnTerms?.returnPeriod ||
      ondcData?.returnTerms?.returnPeriod == "0"
    ) {
      errors = { ...errors, returnPeriod: "Enter return period" };
    }

    if (ondcData?.cancelTerms?.cancellable?.toLowerCase() === "yes") {
      if (!ondcData?.cancelTerms?.cancellationFeePercentage) {
        errors = {
          ...errors,
          cancellationFeePercentage: "Enter cancellation fee percentage",
        };
      }
      if (
        ondcData?.cancelTerms?.cancellationFeePercentage > 0 &&
        ondcData?.cancelTerms?.cancellationFeePercentage > 100
      ) {
        errors = {
          ...errors,
          cancellationFeePercentage:
            "Cancellation fee percentage should be less than or equal to 100",
        };
      }
    }

    if (!ondcData?.productCode?.key) {
      errors = { ...errors, productCode1: "Select product code" };
    }

    if (!ondcData?.productCode?.value) {
      errors = { ...errors, productCode: "Enter product code" };
    }

    if (ondcData?.productCode?.key && ondcData?.productCode?.value) {
      const productCodeLookup = productCodeList?.find(
        (obj) => obj?.prodictCode === ondcData?.productCode?.key
      );

      const regex = new RegExp(
        productCodeLookup?.validation?.[0]
          ?.replace(/d/g, "\\d")
          ?.replace(/\./g, "\\.")
      );

      if (!regex.test(ondcData?.productCode?.value)) {
        errors = {
          ...errors,
          productCode: `Enter valid ${countMatch(
            productCodeLookup?.validation?.[0]
              ?.replace(/d/g, "\\d")
              ?.replace(/\./g, "\\.")
          )} digit product code`,
        };
      }
      console.log(
        "productCodeLookup",
        productCodeLookup,
        regex,
        ondcData?.productCode?.value
      );
    }

    if (!ondcData?.unitOfMeasure?.unit) {
      errors = { ...errors, unitOfMeasure1: "Select Unit of measure" };
    }

    if (!ondcData?.unitOfMeasure?.value) {
      errors = { ...errors, unitOfMeasure: "Enter Unit of measure" };
    }

    // Validations
    const updatedArray = ondcSpecificationData?.map((item) => {
      if (item?.type === "default") {
        return item;
      }
      if (
        !item?.inputValue &&
        item?.required?.toLowerCase() === "m" &&
        item?.type !== "radioButton"
      ) {
        specificationError = true;
        return { ...item, error: "Please enter, it's a mandatory value" };
      }
      if (
        item?.required?.toLowerCase() === "m" &&
        item?.type == "radioButton" &&
        item?.radioOptions?.every((obj) => obj.value === "")
      ) {
        specificationError = true;
        return { ...item, error: "Please select, it's a mandatory value" };
      }

      if (!item?.inputValue || item?.validation?.length === 0) {
        return { ...item, error: "" };
      }

      // const validationArray = JSON.parse(item?.validation);

      // console.log("validationArray", validationArray);

      // if (validationArray?.length === 0) {
      //   return { ...item, error: "" };
      // }

      for (let validation of item?.validation) {
        if (validation.substring(0, 5) !== "regex") {
          if (validation === item?.inputValue) {
            return { ...item, error: "" };
          }
        } else {
          const regexString = validation.match(/regex\((.+)\)/)[1];
          const regexPattern = regexString
            .replace(/d/g, "\\d")
            .replace(/\./g, "\\.");
          const regex = new RegExp(regexPattern);
          console.log(
            "regex inputValue",
            validation,
            regexPattern,
            item?.inputValue
          );
          if (regex.test(item?.inputValue)) {
            console.log("validation success");
            return { ...item, error: "" };
          }
        }
      }

      specificationError = true;
      return { ...item, error: "Please enter valid format" };
    });

    const dbSpecificationObj = parseDbObjectInsideArray(updatedArray);
    console.log("Validated Array", updatedArray);
    console.log("Db SpecificationObj", dbSpecificationObj);

    setOndcSpecificationData(updatedArray);

    if (errors) {
      handleSetValue("errors", errors, ondcData, setOndcData);
    } else {
      handleSetValue("errors", null, ondcData, setOndcData);
    }

    if (errors || specificationError) {
      setErrorPageThree(true);
      console.log("ONDC NOT VALIDATED");
    } else {
      setErrorPageThree(false);
      if (isSubmit) {
        updateProductDetails(dbSpecificationObj);
      } else {
        const previewData = prepareProductPreviewData({
          categoryInfo,
          productInfo,
          taxDetails,
          productImgUpdate,
          priceInfo,
          availabilityInfo,
          measurementInfo,
          productSpec,
          ondcData,
          ondcSpecificationData,
        });

        console.log("previewData__;", previewData);
        setProductPreviewData(previewData);
        setTab("preview");
        window.scrollTo(0, 0);
        console.log("ONDC VALIDATED");
      }
    }
  };

  // Update Product Details
  const updateProductDetails = async (dbSpecificationObj = null) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    console.log("FORM_DATA:", {
      categoryInfo,
      productInfo,
      productImages,
      productImgUpdate,
      priceInfo,
      availabilityInfo,
      measurementInfo,
      productSpec,
    });

    const productPhoto = prepareAddNewProductPhotoData(productImgUpdate);
    console.log("UpdateIMGList:", productPhoto);

    const postData = prepareAddNewProductPostData({
      businessId,
      businessInfo: activeBusinessInfo,
      productPhoto,
      productInfo,
      categoryInfo,
      priceInfo,
      taxDetails,
      productSpec,
      measurementInfo,
      availabilityInfo,
      subscriptionInfo: null,
      ondcInfo: ondcData,
      ondcUIControl: null,
      ondcDbObj: dbSpecificationObj,
      ondcSpecificationData,
    });

    console.log("AddPostData:", postData);

    // Add new product details
    setAddProductLoader(true);
    const response = await addNewProductApi(postData);

    if (response?.success) {
      dispatch(clearCatalogData());
      setShowAlert(true);
      setAddProductLoader(false);
      return;
    }

    setAddProductLoader(false);
  };

  // Get BreadCrumbs List
  const getBreadCrumblinks = () => {
    const businessName = sessionStorage.getItem("costboWEBURL");
    return [
      { label: "Product Catalog", to: `/${businessName}/catalog` },
      { label: "Add Product", to: `#` },
    ];
  };

  // Get Unit of measurement
  const getUnitsData = async () => {
    const response = await getUnitOfMeasurementApi();
    setUnitOfMeasurementData(() => response?.details || []);
    console.log("getUnitsData:", response);
  };

  const goback = (data) => {
    setTab(data);
    window.scrollTo(0, 0);
  };

  const handleCancel = () => {
    const webURL = sessionStorage.getItem("costboWEBURL");
    navigate(`/${webURL}/catalog`);
  };

  useEffect(() => {
    if (activeBusinessInfo) {
      setCategoryInfo({
        ...categoryInfo,
        businessCategory: activeBusinessInfo?.businessCategories || [],
        ondcCategory: activeBusinessInfo?.businessONDCCategories || [],
      });
    }
    return () => {};
  }, [activeBusinessInfo]);

  useEffect(() => {
    if (unitOfMeasurementData?.length === 0) {
      getUnitsData();
    }
  }, []);

  return (
    <Box pb={2}>
      <Box
        sx={{
          ...stickyHeader,
          display: { md: "none", xs: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          pb: {md:2,xs:1},
        }}
      >
        <Typography
          component="h6"
          fontSize={{ xs: 15, md: 17 }}
          color={blackColor}
          fontWeight={{ xs: 600, md: 600 }}
          lineHeight="20px"
          display="inline"
        >
          Add Product
        </Typography>
      </Box>

      {tab === "product-details" && (
        <ProductDetailsTab
          onNextClick={() => validateProductDetailsFormOne()}
          categoryData={categoryInfo}
          setCategoryData={setCategoryInfo}
          productInfo={productInfo}
          setProductInfo={setProductInfo}
          taxDetails={taxDetails}
          setTaxDetails={setTaxDetails}
          onValueChange={handleSetValue}
          setOndcSpecificationData={setOndcSpecificationData}
          setProductCodeList={setProductCodeList}
          title={"Add Product"}
          links={getBreadCrumblinks()}
          handleCancel={handleCancel}
          pageError={errorPageOne}
        />
      )}
      {tab === "product-images" && (
        <ProductImageSpecificationTab
          productInfo={productInfo}
          categoryData={categoryInfo}
          productImages={productImages}
          setProductImages={setProductImages}
          productImgUpdate={productImgUpdate}
          setProductImgUpdate={setProductImgUpdate}
          priceInfo={priceInfo}
          setPriceInfo={setPriceInfo}
          availabilityInfo={availabilityInfo}
          setAvailabilityInfo={setAvailabilityInfo}
          measurementInfo={measurementInfo}
          setMeasurementInfo={setMeasurementInfo}
          productSpec={productSpec}
          setProductSpec={setProductSpec}
          onValueChange={handleSetValue}
          onNextClick={() => validateProductDetailsFormTwo()}
          onBackClick={() => goback("product-details")}
          isOndcEnabled={categoryInfo?.ondcEnabled === "yes"}
          title={"Add Product"}
          links={getBreadCrumblinks()}
          handleCancel={handleCancel}
          pageError={errorPageTwo}
        />
      )}
      {tab === "product-ondc" && (
        <ProductONDCTab
          productInfo={productInfo}
          categoryData={categoryInfo}
          ondcData={ondcData}
          setOndcData={setOndcData}
          onValueChange={handleSetValue}
          ondcSpecificationData={ondcSpecificationData}
          setOndcSpecificationData={setOndcSpecificationData}
          productCodeList={productCodeList}
          onNextClick={() => validateOndc()}
          onBackClick={() => goback("product-images")}
          unitOfMeasurementData={unitOfMeasurementData}
          title={"Add Product"}
          links={getBreadCrumblinks()}
          handleCancel={handleCancel}
          pageError={errorPageThree}
        />
      )}

      {tab === "preview" && (
        <ProductInfoPreview
          data={productPreviewData}
          productInfo={productInfo}
          categoryData={categoryInfo}
          onGoBack={() =>
            goback(
              categoryInfo?.ondcEnabled === "yes"
                ? "product-ondc"
                : "product-images"
            )
          }
          imageSelector={"dataURL"}
          onSubmit={() => {
            if (categoryInfo?.ondcEnabled === "yes") {
              validateOndc(categoryInfo?.ondcEnabled === "yes");
            } else {
              updateProductDetails();
            }
          }}
          title={"Add Product"}
          links={getBreadCrumblinks()}
          handleCancel={handleCancel}
          loader={addProductLoader}
        />
      )}

      <ModalCard
        open={showAlert}
        handleClose={() => {
          // setShowAlert(false);
        }}
        width="48%"
      >
        <AlertMessagePopup
          isLoading={addProductLoader}
          text={`Product added successfully. Our team will review and approve within 24 hours.  New products will be available for customer ordering after approval.\nFor immediate approval, please contact via whatsapp - ‘8088422615’`}
          onClick={() => {
            const webURL = sessionStorage.getItem("costboWEBURL");
            navigate(`/${webURL}/catalogPending`);
          }}
        />
      </ModalCard>
    </Box>
  );
}

export default AddProductNewContent;

const AlertMessagePopup = ({ text, onClick, isLoading }) => {
  return (
    <Box sx={styles.alertCard}>
      {isLoading ? (
        <ThreeDotLoader />
      ) : (
        <>
          <Typography
            variant="body2"
            fontSize={14}
            fontWeight={500}
            textAlign="center"
            sx={{ whiteSpace: "pre-line", lineHeight: "26px", my: 1.2 }}
          >
            {text}
          </Typography>
          <Button sx={styles.alertBtn} variant="outlined" onClick={onClick}>
            GO TO CATALOG
          </Button>
        </>
      )}
    </Box>
  );
};

const styles = {
  alertCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    padding: "20px 20px 15px 20px",
    borderRadius: "8px",
  },

  alertBtn: {
    fontSize: "13px",
    fontWeight: "500",
    color: whiteColor,
    backgroundColor: primaryColor,
    padding: "6px 24px",
    my: "10px",
    "&:hover": {
      color: primaryColor,
      backgroundColor: whiteColor,
    },
  },
};
