import moment from "moment";
import {
  getApiInstance,
  getApiInstanceWithoutProfileId,
} from "../../AppService";
import {
  ALL_ISSUES,
  BUSINESS_INFO,
  GET_BUSINESS_CART_INFO_IGM,
  SEARCH_CUSTOMER_GRIVANCE_ISSUEID,
  SEARCH_CUSTOMER_GRIVANCE_ORDERID,
  UPDATE_ISSUE_RESOLUTION,
  UPDATE_ISSUE_STATUS_BY_BUSINESS,
  VIEW_MY_BUSINESS_URL,
} from "../../constant";

export const getBusinessData = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(`${BUSINESS_INFO}?params=${data}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const searchCustomerGrievanceOrderIdAPI = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(
      `${SEARCH_CUSTOMER_GRIVANCE_ORDERID}${data?.orderId}`
      //   {
      //   params: {
      //     key: data?.query,
      //     businessId: data?.businessId,
      //     page: 0,
      //   },
      // }
    );
    console.log("getActiveBusinessAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const searchCustomerGrievanceIssueIdAPI = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(
      `${SEARCH_CUSTOMER_GRIVANCE_ISSUEID}${data?.issueId}`
      //   {
      //   params: {
      //     key: data?.query,
      //     businessId: data?.businessId,
      //     page: 0,
      //   },
      // }
    );
    console.log("getActiveBusinessAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getIssuesData = async (data) => {
  try {
    const params = {
      status: data?.status,
      category: data?.category,
      pageNo: data?.pageNo,
      pageSize: 100,
      from: data?.startDate || "2023-10-01T12:00:00.000Z",
      to: data?.endDate || moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    };
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(ALL_ISSUES, { params });
    console.log("ISSUE_DATA", response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getGrievanceData = async (data) => {
  const issueResponse = await getIssuesData(data);
  console.log("GRIEVANCE_DATA_1", {
    issueData: issueResponse?.details,
  });

  return {
    issueData: issueResponse?.details,
  };
};

export const getCartInfoApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        cartid: data?.cartId,
        businessId: data?.businessId,
      },
    });

    const response = await instance.get(GET_BUSINESS_CART_INFO_IGM);

    console.log("getCartInfoApi:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Update Issue Status to PROCESSING
export const updateIssueStatusByBusinessApi = async (data) => {
  try {
    const postData = prepareIssueStatusPostData(data);
    console.log("updateIssueStatusByBusinessApi_postData:", postData);

    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });

    const response = await instance.post(
      UPDATE_ISSUE_STATUS_BY_BUSINESS,
      postData
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const prepareIssueStatusPostData = (data) => {
  const { issueId, businessId, bppId, domain, ownerInfo } = data;

  const postData = {
    issueId: issueId,
    status: "PROCESSING",
    issue_actions: {
      respondent_actions: [
        {
          respondent_action: "PROCESSING",
          short_desc:
            "Dear Customer, Apologies for the inconvenience. We're looking into the issue and will get back to you soon. Thanks for your patience.",
          updated_at: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), // UTC time
          updated_by: {
            org: {
              name: `${bppId}:${domain}`,
            },
            contact: {
              phone: ownerInfo?.phone?.replace("+91", ""),
              email: ownerInfo?.email,
            },
            person: {
              name: ownerInfo?.name,
            },
          },
          cascaded_level: 1,
        },
      ],
    },
  };

  return postData;
};

// Update Issue Status to RESOLVED
export const updateIssueResolutionApi = async (data) => {
  try {
    const postData = prepareIssueResolutionPostData(data);
    console.log("updateIssueResolutionApi_postData:", postData);

    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });

    const response = await instance.post(UPDATE_ISSUE_RESOLUTION, postData);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const prepareIssueResolutionPostData = (data) => {
  const {
    issueId,
    businessId,
    bppId,
    domain,
    ownerInfo,
    actionType,
    resolution,
    description,
    refundAmount,
  } = data;

  const postData = {
    issueId: issueId,
    issue: {
      status: "RESOLVED",
      issue_actions: {
        respondent_actions: [
          {
            respondent_action: "RESOLVED",
            short_desc: resolution,
            updated_at: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), // UTC time
            updated_by: {
              org: {
                name: `${bppId}:${domain}`,
              },
              contact: {
                phone: ownerInfo?.phone?.replace("+91", ""),
                email: ownerInfo?.email,
              },
              person: {
                name: ownerInfo?.name,
              },
            },
            cascaded_level: 1,
          },
        ],
      },
      resolution_provider: {
        respondent_info: {
          type: "TRANSACTION-COUNTERPARTY-NP",
          organization: {
            org: {
              name: `${bppId}:${domain}`,
            },
            contact: {
              phone: ownerInfo?.phone?.replace("+91", ""),
              email: ownerInfo?.email,
            },
            person: {
              name: ownerInfo?.name,
            },
          },
          resolution_support: {
            contact: {
              phone: ownerInfo?.phone?.replace("+91", ""),
              email: ownerInfo?.email,
            },
            gros: [
              {
                person: {
                  name: ownerInfo?.name,
                },
                contact: {
                  phone: ownerInfo?.phone?.replace("+91", ""),
                  email: ownerInfo?.email,
                },
                gro_type: "TRANSACTION-COUNTERPARTY-NP-GRO",
              },
            ],
          },
        },
      },
      resolution: {
        short_desc: resolution,
        long_desc: description,
        action_triggered: actionType,
        refund_amount: actionType === "REFUND" ? refundAmount : null,
        // resolution_remarks: resolution,
        // resolution_action: "RESOLVE",
      },
    },
  };

  return postData;
};

export const viewMyBusinessAPI = async (businessId) => {
  try {
    const instance = getApiInstanceWithoutProfileId();
    const response = await instance.get(VIEW_MY_BUSINESS_URL, {
      headers: { businessId },
    });
    // console.log("My_Business_Data", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
