import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import DashboardMenu from "./Dashboard/DashboardMenu";
import DashboardHeader from "./Dashboard/DashboardHeader";
import { BGColor02 } from "../configs/styles/muiThemes";
import BusinessInfoCard from "../components/Cards/BusinessInfoCard";

import { useDispatch, useSelector } from "react-redux";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import PreferredDiscountContent from "./PreferredDiscounts/PreferredDiscountContent";
import { getAllBusinessPreferredDiscountsData } from "../reducers/preferredDiscount/PreferredDiscountSlice";
import DashboardLayout from "../components/Layouts/DashboardLayout";

function PreferredDiscounts() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  const dispatch = useDispatch();

  const getPreferredDiscount = ()=>{
    
  }

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
    }
  }, []);

  return (
    <Box sx={styles.container}>
      <DashboardLayout
        activeMenu="coupons"
        activeSubMenu="personalizedDiscounts"
      >
        <PreferredDiscountContent
          businessName={activeBusinessInfo?.name}
          getPreferredDiscount={() => {
            dispatch(
              getAllBusinessPreferredDiscountsData({
                businessId: sessionStorage.getItem("selectedBusinessId"),
              })
            );
          }}
        />
      </DashboardLayout>
    </Box>
  );
}

export default PreferredDiscounts;

const styles = {
  container: {
    minHeight: {xs:"calc(100vh - 110px)", md:'100vh'},
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "25px" },
    p: { xs: "0px", md: "15px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },
};
