import { Box, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  greyColor6,
  greyColor7,
  titleColor,
} from "../../../configs/styles/muiThemes";

function LabelValueText({
  label,
  value,
  valueColor,
  valueSize,
  hideBorder,
  flex,
  borderStyle,
  labelBottom,
  styles,
}) {
  return (
    <Box
      borderRight={
        hideBorder ? "none" : `1px ${borderStyle || "solid"} #e2e4ea`
      }
      flex={flex || 1}
      m={{ xs: "10px 10px 0 0", md: "18px 20px 0 0" }}
      sx={{ ...styles }}
    >
      {!labelBottom && (
        <Typography
          variant="body2"
          fontSize={{ xs: 11, md: 11.2 }}
          color={greyColor6}
          fontWeight={500}
          lineHeight={1.6}
        >
          {label}
        </Typography>
      )}

      <Typography
        component="h6"
        fontSize={{ xs: valueSize || 15, md: 15 }}
        color={valueColor || blackShade05}
        fontWeight={600}
        lineHeight="25px"
      >
        {value}
      </Typography>

      {labelBottom && (
        <Typography
          variant="body2"
          fontSize={{ xs: 12, md: 12 }}
          color={greyColor6}
          fontWeight={500}
          lineHeight="20px"
        >
          {label}
        </Typography>
      )}
    </Box>
  );
}

export default LabelValueText;
