import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BGColor02,
  blackShade05,
  greyColor39,
} from "../configs/styles/muiThemes";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import { getUserEditEligibility } from "../reducers/businessOnboard/businessOnboardSlice";

import DashboardLayout from "../components/Layouts/DashboardLayout";

import BranchCatalogPushContent from "./BranchCatalogPush/BranchCatalogPushContent";
import { getMainBranchProducts } from "../reducers/multiBranchCatalog/multiBranchCatalogSlice";
import { getInventorySummaryCounts } from "../reducers/inventory/inventorySlice";
import EmptyCard from "../components/Alerts/EmptyCard";

function BranchCatalogPush() {
  const PAGE_SIZE = 50;
  const [hasMoreData, setHasMoreData] = useState(true);

  const profileId = sessionStorage.getItem("profileId");

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  const { inventorySummary } = useSelector((state) => state.inventory);

  const {
    mainBranchProducts,
    mainBranchProductsPageNo,
    mainBranchProductsLoader,
    branchPushLoader,
  } = useSelector((state) => state.multiBranchCatalog);

  const { editEligibility } = useSelector((state) => state.businessOnboard);
  const { statsCountInfo } = useSelector((state) => state.businessDashboard);

  // Fetch Main Branch Products
  const fetchMainBranchProducts = (isRefresh, updateProductSyncStatus, selectedBranchId) => {
    const prevList = [...mainBranchProducts]

    setHasMoreData(true);
    const businessId = sessionStorage.getItem("selectedBusinessId");
  
    const handleResponse = (res) => {

      const result = res?.payload?.res?.details || [];
      if (result.length === 0 || result.length < PAGE_SIZE) {
        setHasMoreData(false);
      }
      if (updateProductSyncStatus) {
        console.log("djcdjcbdjbcdkjbnckjbn_____Res_Lenth", result.length);
        console.log("djcdjcbdjbcdkjbnckjbn_____", [...prevList,...result]);
        
        updateProductSyncStatus([...prevList,...result]);
      }
    };
  
    if (isRefresh) {
      dispatch(
        getMainBranchProducts({
          businessId,
          pageSize: PAGE_SIZE,
          pageNo: 1,
          isRefresh: true,
          selectedBranchId: selectedBranchId || null
        })
      ).then(handleResponse);
    } else {
      dispatch(
        getMainBranchProducts({
          businessId,
          pageSize: PAGE_SIZE,
          pageNo: mainBranchProductsPageNo,
          isRefresh: false,
          selectedBranchId: selectedBranchId || null
        })
      ).then(handleResponse);
    }
  };

  // Fetching all the Catalog Data
  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!editEligibility) {
        dispatch(getUserEditEligibility());
      }
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }

      fetchMainBranchProducts();

      if (!inventorySummary) {
        dispatch(getInventorySummaryCounts(businessId));
      }
    }

    return () => {};
  }, []);

  return (
    activeBusinessInfo && (
      <Box sx={styles.container(matches)}>
        <DashboardLayout activeMenu="catalog" activeSubMenu="branchCatalogPush">
          <Box sx={styles.content}>
            {activeBusinessInfo?.owner?.profileId === profileId ? (
              <BranchCatalogPushContent
                mainBranchProducts={mainBranchProducts}
                activeBusinessInfo={activeBusinessInfo}
                productLoader={mainBranchProductsLoader}
                branchPushLoader={branchPushLoader}
                inventorySummary={inventorySummary}
                fetchMainBranchProducts={fetchMainBranchProducts}
                hasMoreData={hasMoreData}
                setHasMoreData={setHasMoreData}
              />
            ) : (
              <Box>
                <Typography
                  component="h4"
                  fontSize={{ xs: 15, md: 17 }}
                  color={blackShade05}
                  fontWeight={600}
                  lineHeight="20px"
                  pb={1.5}
                  pt={1}
                >
                  Product Catalog
                </Typography>

                <EmptyCard
                  icon="https://storage.googleapis.com/bodefaults/businessdashboardweb/product-reject.svg"
                  message="You don't have permission to push the catalog to sub-branches"
                />
              </Box>
            )}
          </Box>
        </DashboardLayout>
      </Box>
    )
  );
}

export default BranchCatalogPush;

const styles = {
  container: (isMobile) => ({
    backgroundColor: isMobile ? BGColor02 : greyColor39,
  }),

  content: {
    minHeight:'calc(100vh - 90px)',
    bgcolor: greyColor39,
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "10px" },
    pt: { xs: 2.5, md: 0 },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },
};
