import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import {
  blackShade05,
  blackShade159,
  deleteBtnColor,
  greyColor11,
  greyColor28,
  greyColor29,
  logoImgborder1,
  tableHeaderColor,
  whiteColor,
} from "../../../../configs/styles/muiThemes";
import ApproveHeaderCard from "./ApproveHeaderCard";
import RadiosBtnWithLabel from "../../../../components/Input/RadiosBtnWithLabel";
import TrackingInfoCard from "./TrackingInfoCard";
import ThroughDeliveryAgent from "./ThroughDeliveryAgent";
import { useState } from "react";
import { prepareOrderShipmentData } from "../../../../utils/postDataHelpers/ApproveOrderHelper";
import {
  approveShipment,
  getCartInfoApi,
} from "../../../../service/api_calls/orders/OrdersApi";
import ThreeDotLoader from "../../../../components/ThreeDotLoader";
import moment from "moment";
import { addThousandsSeparator, getOrderType } from "../../../../utils/helper";

function OrderBusinessApprove({
  orderData,
  handleApproveSuccess,
  hideTable,
  handleClose,
  getOndcBapNameBySubscriberId,
}) {
  const [shipmentType, setShipmentType] = useState("trackingInfo");
  const [trackingInfoData, setTrackingInfoData] = useState({
    img: "",
    carrierName: "",
    carrierURL: "",
    carrierTrackId: "",
    notes: "",
    errors: {},
  });
  const [deliveryAgentData, setDeliveryAgentData] = useState({
    deliveryPersonName: "",
    deliveryPersonPhone: "",
    notes: "",
    etaDeliveryDate: "",
    etaDeliveryTime: "",
    errors: {},
  });
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cartInfo, setCartInfo] = useState(null);

  const orderType = getOrderType(orderData);

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const handleSetValue = (name, value, state, setState) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const getCartData = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    setLoading(true);

    if (orderData?.cartId) {
      const response = await getCartInfoApi({
        cartId: orderData?.cartId,
        businessId,
      });

      if (response?.cart) {
        setCartInfo(response?.cart || null);
        setLoading(false);
        return;
      }
    }

    setLoading(false);
  };

  const handleTrackingShipmentInfo = () => {
    handleSetValue("errors", null, trackingInfoData, setTrackingInfoData);
    const businessId = sessionStorage.getItem("selectedBusinessId");
    let errors = null;

    if (trackingInfoData?.carrierTrackId === "") {
      errors = {
        ...errors,
        carrierTrackId: "Enter the shipment tracking number",
      };
    }
    if (trackingInfoData?.carrierName === "") {
      errors = {
        ...errors,
        carrierName: "Enter carrier name",
      };
    }
    if (errors) {
      handleSetValue("errors", errors, trackingInfoData, setTrackingInfoData);
      return;
    }
    const data = prepareOrderShipmentData(trackingInfoData);
    const postData = { cartId: orderData?.cartId, businessId, data: data };
    handleApproveShipment(postData);
    console.log("trackingInfoPayloadData", data);
  };

  const handleDeliveryAgentShipmentInfo = () => {
    handleSetValue("errors", null, trackingInfoData, setTrackingInfoData);
    const businessId = sessionStorage.getItem("selectedBusinessId");
    let errors = null;

    if (deliveryAgentData?.deliveryPersonName === "") {
      errors = {
        ...errors,
        deliveryPersonName: "Enter the delivery person name",
      };
    }
    if (deliveryAgentData?.deliveryPersonPhone === "") {
      errors = {
        ...errors,
        deliveryPersonPhone: "Enter the delivery person number",
      };
    }
    if (deliveryAgentData?.etaDeliveryDate === "") {
      errors = {
        ...errors,
        etaDeliveryDate: "Enter delivery date",
      };
    }
    if (deliveryAgentData?.etaDeliveryTime === "") {
      errors = {
        ...errors,
        etaDeliveryTime: "Enter delivery time",
      };
    }

    if (errors) {
      handleSetValue("errors", errors, deliveryAgentData, setDeliveryAgentData);
      return;
    }
    const data = prepareOrderShipmentData(deliveryAgentData);
    const postData = { cartId: orderData?.cartId, businessId, data: data };
    handleApproveShipment(postData);
    console.log("deliveryAgentPayloadData", data);
  };

  const handleApproveShipment = async (data) => {
    setLoader(true);
    const response = await approveShipment(data);

    if (response) {
      handleApproveSuccess();
      setLoader(false);
    }
  };

  useEffect(() => {
    getCartData();
    return () => {};
  }, []);

  return (
    <Box overflow="hidden">
      {loading ? (
        <Box
          sx={{
            minHeight: "100px",
            backgroundColor: whiteColor,
            borderRadius: "12px",
            pt: "20px",
          }}
        >
          <ThreeDotLoader />
        </Box>
      ) : (
        <Box sx={styles.card}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography fontSize={15} fontWeight={600} color={blackShade05}>
              Order Approved
            </Typography>

            <Button
              sx={styles.cancelIconBtn}
              variant="contained"
              disableElevation
              onClick={handleClose}
            >
              CLOSE
            </Button>
          </Box>
          <ApproveHeaderCard
            orderId={cartInfo?.orderId}
            ondcOrderId={orderData?.ondcOrderId || ""}
            orderDate={moment(cartInfo?.orderCreatedOn).format("MMM Do, YYYY")}
            status="orderapproved"
            orderType={orderType}
            getOndcBapNameBySubscriberId={() =>
              getOndcBapNameBySubscriberId(orderData?.ondcBapId)
            }
          />
          <Box sx={styles.borderedCard}>
            <Typography
              fontSize={15}
              fontWeight={600}
              color={blackShade05}
              mb="12px"
              lineHeight={"20px"}
            >
              Shipment Info
            </Typography>
            <Box
              display="flex"
              gap={{ md: 5, xs: 0 }}
              flexDirection={{ md: "row", xs: "column" }}
              mb={1}
            >
              <RadiosBtnWithLabel
                label="Tracking Info"
                checked={shipmentType === "trackingInfo"}
                labelStyles={{ color: blackShade05, fontWeight: 600 }}
                onValuesChanged={() => {
                  setShipmentType("trackingInfo");
                }}
              />
              <RadiosBtnWithLabel
                label="Through Delivery Agent"
                checked={shipmentType === "deliveryAgent"}
                labelStyles={{
                  color: blackShade05,
                  fontWeight: 600,
                }}
                onValuesChanged={() => {
                  setShipmentType("deliveryAgent");
                }}
              />
            </Box>
            {shipmentType === "trackingInfo" && (
              <TrackingInfoCard
                trackingInfoData={trackingInfoData}
                onValueChange={(name, value) => {
                  handleSetValue(
                    name,
                    value,
                    trackingInfoData,
                    setTrackingInfoData
                  );
                }}
                handleShipmentInfo={handleTrackingShipmentInfo}
                loader={loader}
              />
            )}
            {shipmentType === "deliveryAgent" && (
              <ThroughDeliveryAgent
                deliveryAgentData={deliveryAgentData}
                onValueChange={(name, value) => {
                  handleSetValue(
                    name,
                    value,
                    deliveryAgentData,
                    setDeliveryAgentData
                  );
                }}
                handleShipmentInfo={handleDeliveryAgentShipmentInfo}
                loader={loader}
              />
            )}
          </Box>
          {matches && !hideTable ? (
            <>
              <Typography
                fontSize={15}
                fontWeight={600}
                color={blackShade05}
                mb="12px"
                lineHeight={"20px"}
                mt={2}
              >
                Product Overview
              </Typography>
              <Box sx={styles.table}>
                <Box sx={styles.tableHeader}>
                  <Grid container>
                    <Grid item xs={4.5}>
                      <Typography variant="h4" sx={styles.tableHeaderText}>
                        PRODUCT
                      </Typography>
                    </Grid>

                    <Grid item xs={1.5}>
                      <Typography variant="h4" sx={styles.tableHeaderText}>
                        UNIT PRICE
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography variant="h4" sx={styles.tableHeaderText}>
                        QUANTITY
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography variant="h4" sx={styles.tableHeaderText}>
                        PRICE
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography variant="h4" sx={styles.tableHeaderText}>
                        SAVINGS
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                {cartInfo?.productList?.map((item, index) => {
                  const savings =
                    Number(item?.productOriginalPrice) *
                      Number(item?.currentQuantity) -
                    Number(item?.productSalePrice) *
                      Number(item?.currentQuantity);

                  return (
                    <Box sx={styles.productRow} key={item?.productId}>
                      <Grid container spacing={2}>
                        <Grid item xs={4.5}>
                          <Box
                            display="flex"
                            alignItems="center"
                            gap="10px"
                            ml={2}
                          >
                            <Box
                              component="img"
                              src={item?.productPicURL}
                              alt={item?.productName}
                              sx={styles.productImg}
                            />

                            <Typography
                              variant="h4"
                              sx={styles.productInfoText}
                            >
                              {item?.productName}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={1.5}>
                          <Box sx={styles.centerText}>
                            <Typography
                              variant="h4"
                              sx={styles.productInfoText}
                            >
                              ₹{addThousandsSeparator(item?.productSalePrice)}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={2}>
                          <Box sx={styles.centerText}>
                            <Typography
                              variant="h4"
                              sx={styles.productInfoText}
                            >
                              {item?.currentQuantity}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={2}>
                          <Box sx={styles.centerText}>
                            <Typography
                              variant="h4"
                              sx={styles.productInfoText}
                            >
                              ₹{addThousandsSeparator(item?.productTotalPrice)}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={2}>
                          <Box sx={styles.centerText}>
                            <Typography
                              variant="h4"
                              sx={styles.productInfoText}
                            >
                              ₹{addThousandsSeparator(savings)}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
              </Box>
            </>
          ) : null}
        </Box>
      )}
    </Box>
  );
}

export default OrderBusinessApprove;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "12px",
    padding: "16px",

    maxHeight: { md: "95vh", xs: "70vh" },
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
      // height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blackShade159,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },
  cancelIconBtn: {
    padding: { md: "5px 24px", xs: "3px 12px" },
    fontSize: "12px",
    fontWeight: 600,
    color: deleteBtnColor,
    backgroundColor: whiteColor,
    border: "1px solid " + deleteBtnColor,
    "&:hover": {
      color: whiteColor,
      backgroundColor: deleteBtnColor,
    },
    mr: { md: 10, xs: 0 },
  },
  borderedCard: {
    borderRadius: "12px",
    border: `1px solid ${greyColor28}`,
    p: "12px",
    mt: 2,
  },
  table: {
    border: `1px solid ${greyColor11}`,
    borderRadius: "7px",
    mt: 2,
  },

  tableHeader: {
    backgroundColor: tableHeaderColor,
    p: "15px 10px",
    borderRadius: "7px 7px 0 0",
  },

  tableHeaderText: {
    fontSize: 13,
    fontWeight: 500,
    color: greyColor29,
  },

  productRow: {
    p: { xs: "0 0", md: "18px 0" },
    borderBottom: "1px solid #f2f2f2",
  },

  productImg: {
    width: { xs: "50px", md: "60px" },
    height: { xs: "50px", md: "60px" },
    borderRadius: "8px",
    border: logoImgborder1,
  },

  productInfoText: {
    fontSize: 14,
    fontWeight: 500,
    color: greyColor29,
  },

  centerText: {
    height: "100%",
    display: "flex",
    alignItems: { xs: "flex-start", md: "center" },
    justifyContent: "flex-start",
  },
};
