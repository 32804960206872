import { Box, Button, Typography } from "@mui/material";
import React from "react";
import UpdateIcon from "@mui/icons-material/Update";
import {
  blackShade22,
  redColor01,
  whiteColor,
} from "../../../configs/styles/muiThemes";

const QuickUpdateBtn = ({ onClick, updateLoader, quickUpdateErr, styles, fullWidth }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        mt:{xs:1.7, md:0},
        ...styles,
        
      }}
    >
      <Button
        sx={style.quickUpdateIconBtn}
        variant="contained"
        disableElevation
        onClick={onClick}
        fullWidth={fullWidth}
        disabled={updateLoader}
        startIcon={<UpdateIcon sx={{ fontSize: "14px" }} />}
      >
        BULK UPDATE
      </Button>

      <Typography
        fontSize={11}
        fontWeight={500}
        lineHeight='18px'
        color={redColor01}
        textAlign="right"
        mt={0.3}
      >
        {quickUpdateErr}
      </Typography>
    </Box>
  );
};

export default QuickUpdateBtn;

const style = {
  quickUpdateIconBtn: {
    padding: { md: "3px 14px", xs: "3px 14px" },
    fontSize: { md: "11px", xs: "11px" },
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: { xs: blackShade22, md: blackShade22 },
    whiteSpace:'nowrap',
    border: {
      xs: `1.5px solid ${blackShade22}`,
      md: `1.5px solid ${blackShade22}`,
    },
    "&:hover": {
      border: {
        xs: `1.5px solid ${blackShade22}`,
        md: `1.5px solid ${blackShade22}`,
      },
      color: { xs: blackShade22, md: blackShade22 },
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },
};
