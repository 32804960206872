import { Box, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  greyColor40,
  logoImgborder,
  mobilePrimary,
} from "../../../configs/styles/muiThemes";
import { scrollBox } from "../../../utils/styles";
import { useNavigate } from "react-router-dom";

const PageMobileHeader = ({ title, tabValue }) => {
  const navigate = useNavigate();

  const TAB_DATA_LIST = [
    { title: "ONDC CHECKOUT LINKS", value:'ondc_links', route: "/ondcShareLink" },
    { title: "DIRECT STORE LINK", value:'direct_link', route: "/directStoreLink" },
  ];

  const handleChange = (route) => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}${route}`);
  };

  return (
    <Box>
      <Typography
        component="h4"
        fontSize={{ xs: 16, md: 17 }}
        color={blackShade05}
        fontWeight={600}
        lineHeight="28px"
        m="10px 0 6px"
      >
        Share Links
      </Typography>

      <Box sx={{ ...styles.mobileTabs, ...scrollBox }}>
        {TAB_DATA_LIST?.map((item, index) => {
          return (
            <MobileTab
              key={`tab_${index}`}
              title={item.title}
              isActive={tabValue == item.value}
              onClick={() => handleChange(item.route)}
            />
          );
        })}
      </Box>

      <Typography
        component="h4"
        fontSize={{ xs: 14, md: 15 }}
        color={blackShade05}
        fontWeight={600}
        lineHeight="28px"
        mb={0.5}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default PageMobileHeader;

const MobileTab = ({ title, count, isActive, onClick }) => {
  return (
    <Box sx={styles.mobileTabItem(isActive)} onClick={onClick}>
      <Typography
        fontSize={12.5}
        color={isActive ? mobilePrimary : greyColor40}
        fontWeight={isActive ? 600 : 500}
        sx={{ whiteSpace: "nowrap" }}
        textTransform="capitalize"
        mb={1}
      >
        {title}
      </Typography>
    </Box>
  );
};

const styles = {
  mobileTabs: {
    width: { xs: "90vw", md: "calc(100% - 100px)" },
    display: "flex",
    alignItems: "center",
    mb: 1,
    borderBottom: logoImgborder,
  },

  mobileTabItem: (isActive) => ({
    textAlign: "center",
    borderBottom: isActive ? `3px solid ${mobilePrimary}` : "none",
    mr: 1,
  }),
};
