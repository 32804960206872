import {
  Box,
  Checkbox,
  Grid,
  Switch,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import {
  blackColor,
  borderCart,
  copyBtnColor,
  greyColor42,
  greyColor47,
  greyColor5,
  greyColor8,
  greyShade25,
  outOfStockColor,
  tableHeaderColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { textThreeLines, textTwoLines } from "../../../utils/styles";
function AllStockCard({
  index,
  productList,
  onSelectAll,
  selectedRows,
  totalCount,
  img,
  title,
  originalAmt,
  amt,
  endDate,
  startDate,
  onValueChange,
  isChecked,
  gst,
  gstRates,
  gstCode,
  errors,
  priceOff,
  validityDays,
  availability,
  alertQty,
  avlQty,
  minQty,
  maxQty,
  disabled,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [toggleData, settoggleData] = useState(false);
  const handleShowData = (data) => {
    settoggleData(data);
  };
  return (
    <Box
      sx={{
        // borderRadius: "6px",
        border: `1px solid ${tableHeaderColor}`,
        background: whiteColor,
        mb: { xs: 0, md: 1 },
      }}
    >
      <Box
        sx={{ p: "16px 16px 16px 16px" }}
        border={{
          xs: `1px solid ${errors?.length > 0 ? "red" : "none"}`,
          md: "none",
        }}
      >
        {matches ? (
          <Grid
            container
            display={"flex"}
            alignItems="center"
            rowSpacing={{ xs: 1.9, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
          >
            <Grid item md={3.8} xs={12}>
              <Box display="flex" alignItems="flex-start">
                <Checkbox
                  sx={{ ml: -1, ...styles.checkbox }}
                  checked={isChecked}
                  size={"large"}
                  onChange={() => {
                    onValueChange({ type: "itemChange", value: !isChecked });
                  }}
                />

                <Box ml={1} flex={1}>
                  <Typography
                    fontSize={{ xs: 13, md: 14 }}
                    color={blackColor}
                    fontWeight={{ xs: 600, md: 500 }}
                    lineHeight={1.5}
                    mb="0px !important"
                  >
                    {title}
                  </Typography>
                </Box>

                <StyledSwitch
                  sx={{ ml: 1, color: "red" }}
                  checked={availability}
                  onChange={() => {
                    if (availability) {
                      onValueChange({ type: "availability", value: ["No"] });
                    } else {
                      onValueChange({ type: "availability", value: ["Yes"] });
                    }
                  }}
                />
              </Box>
            </Grid>

            <Grid item md={1.7} xs={6}>
              <Typography
                variant="h3"
                fontSize={{ md: 13, xs: 11 }}
                color={greyShade25}
                fontWeight={{ xs: 500, md: 400 }}
                textTransform="uppercase"
                sx={styles.labelStyles}
              >
                AVAILABLE QTY
              </Typography>

              <InputBox
                value={avlQty}
                onChange={({ target }) => {
                  const value = target.value;
                  const regex = /^(\d{0,7})$/;
                  if (regex.test(value) && target.value.charAt(0) !== "0") {
                    onValueChange({
                      type: "availableQuantity",
                      value: target.value,
                    });
                  }
                }}
                disabled={disabled}
              />
            </Grid>

            <Grid item md={1.7} xs={6}>
              <Typography
                variant="h3"
                fontSize={{ md: 13, xs: 11 }}
                color={greyShade25}
                fontWeight={{ xs: 500, md: 400 }}
                textTransform="uppercase"
                sx={styles.labelStyles}
              >
                ALERT QTY
              </Typography>

              <InputBox
                value={alertQty}
                onChange={({ target }) => {
                  const value = target.value;
                  const regex = /^(\d{0,7})$/;
                  if (regex.test(value) && target.value.charAt(0) !== "0") {
                    onValueChange({
                      type: "alertQuantity",
                      value: target.value,
                    });
                  }
                }}
                disabled={disabled}
              />
            </Grid>

            <Grid item md={1.7} xs={4}>
              <Typography
                variant="h3"
                fontSize={{ md: 13, xs: 11 }}
                color={greyShade25}
                fontWeight={{ xs: 500, md: 400 }}
                textTransform="uppercase"
                sx={styles.labelStyles}
              >
                MIN QTY
              </Typography>

              <InputBox
                value={minQty}
                onChange={({ target }) => {
                  const value = target.value;
                  const regex = /^(\d{0,7})$/;
                  if (regex.test(value) && target.value.charAt(0) !== "0") {
                    onValueChange({ type: "minOrderQuantity", value });
                  }
                }}
                disabled={disabled}
              />
            </Grid>

            <Grid item md={1.7} xs={4}>
              <Typography
                variant="h3"
                fontSize={{ md: 13, xs: 11 }}
                color={greyShade25}
                fontWeight={{ xs: 500, md: 400 }}
                textTransform="uppercase"
                sx={styles.labelStyles}
              >
                MAX QTY
              </Typography>

              <InputBox
                value={maxQty}
                onChange={({ target }) => {
                  const value = target.value;
                  const regex = /^(\d{0,7})$/;
                  if (regex.test(value) && target.value.charAt(0) !== "0") {
                    onValueChange({ type: "maxOrderQuantity", value });
                  }
                }}
                disabled={disabled}
              />
            </Grid>

            <Grid item md={1.2} xs={4}>
              <Typography
                variant="h3"
                fontSize={{ xs: 11, md: 13 }}
                color={greyShade25}
                fontWeight={{ xs: 500, md: 400 }}
                textTransform="uppercase"
                sx={styles.labelStyles}
              >
                GST%
              </Typography>

              <SelectWithLabel
                value={gst?.replace("%", "") || ""}
                onValueChange={(value) => {
                  onValueChange({
                    type: "gstInfo",
                    value: { code: gstCode, gstPercentage: value },
                  });
                }}
                menuList={gstRates}
                valueSelector="value"
                isDashboardInput
                disabled={disabled}
              />
            </Grid>

            <Grid item md={1.7} xs={4}>
              <Typography
                variant="h3"
                fontSize={{ md: 13, xs: 11 }}
                color={greyShade25}
                fontWeight={{ xs: 500, md: 400 }}
                textTransform="uppercase"
                sx={styles.labelStyles}
              >
                Original Price
              </Typography>

              <InputBox
                value={originalAmt}
                onChange={({ target }) => {
                  const value = target.value;
                  // const regex = /^(\d{0,7}|\d{0,7}\.)(?:\.\d{1,2})?$/;
                  // if (regex.test(value) && target.value.charAt(0) !== "0") {
                  //   onValueChange({
                  //     type: "productOriginalPrice",
                  //     value: target.value,
                  //   });
                  // }

                  const regex = /^\d{0,7}(\.\d{0,2})?$/;

                  if (regex.test(value)) {
                    onValueChange({
                      type: "productOriginalPrice",
                      value: value,
                    });
                  }
                }}
                disabled={disabled}
              />
            </Grid>

            <Grid item md={1.5} xs={4}>
              <Typography
                variant="h3"
                fontSize={{ md: 13, xs: 11 }}
                color={greyShade25}
                fontWeight={{ xs: 500, md: 400 }}
                textTransform="uppercase"
                sx={styles.labelStyles}
              >
                % OFF
              </Typography>

              <InputBox
                value={priceOff}
                onChange={({ target }) => {
                  const newValue = target?.value?.replace(/[^0-9]/g, "");

                  onValueChange({
                    type: "priceOff",
                    value: newValue,
                  });
                }}
                disabled={disabled}
              />
            </Grid>

            <Grid item md={1.5} xs={4}>
              <Typography
                variant="h3"
                fontSize={{ md: 13, xs: 11 }}
                color={greyShade25}
                fontWeight={{ xs: 500, md: 400 }}
                textTransform="uppercase"
                sx={styles.labelStyles}
              >
                Sale Price
              </Typography>

              <InputBox
                value={amt}
                onChange={({ target }) => {
                  const value = target.value;
                  const regex = /^\d{0,7}(\.\d{0,2})?$/;

                  if (regex.test(value)) {
                    onValueChange({
                      type: "productSalePrice",
                      value: value,
                    });
                  }

                  // const regex = /^(\d{0,7}|\d{0,7}\.)(?:\.\d{1,2})?$/;
                  // const regex = /^\d{0,7}(\.\d{0,2})?$/;
                  // if (
                  //   regex.test(value) &&
                  //   target.value.charAt(0) !== "0" &&
                  //   !isNaN(target.value)
                  // ) {
                  //   onValueChange({
                  //     type: "productSalePrice",
                  //     value: target.value,
                  //   });
                  // }
                }}
                disabled={disabled}
              />
            </Grid>

            <Grid item md={1.9} xs={4.2}>
              <Typography
                variant="h3"
                fontSize={{ md: 13, xs: 11 }}
                color={greyShade25}
                fontWeight={{ xs: 500, md: 400 }}
                textTransform="uppercase"
                sx={styles.labelStyles}
              >
                Start Date
              </Typography>

              <TextField
                variant="standard"
                value={startDate}
                type="date"
                sx={styles.input}
                onChange={({ target }) => {
                  onValueChange({
                    type: "commerceStartDate",
                    value: target.value,
                  });
                }}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: blackColor,
                    fontSize: { md: 14, xs: 11 },
                    fontWeight: 500,
                  },
                }}
                disabled={disabled}
              />
            </Grid>

            <Grid item md={1.5} xs={3.6}>
              <Typography
                variant="h3"
                fontSize={{ md: 13, xs: 11 }}
                color={greyShade25}
                fontWeight={{ xs: 500, md: 400 }}
                textTransform="uppercase"
                sx={styles.labelStyles}
              >
                VALIDITY(DAYS)
              </Typography>

              <InputBox
                value={validityDays}
                onChange={({ target }) => {
                  const newValue = target?.value?.replace(/[^0-9]/g, "");

                  onValueChange({
                    type: "validityDays",
                    value: newValue,
                  });
                }}
                maxLength={4}
                disabled={disabled}
              />
            </Grid>

            <Grid item md={1.9} xs={4.2}>
              <Typography
                variant="h3"
                fontSize={{ md: 13, xs: 11 }}
                color={greyShade25}
                fontWeight={{ xs: 500, md: 400 }}
                textTransform="uppercase"
                sx={styles.labelStyles}
              >
                End Date
              </Typography>
              <TextField
                variant="standard"
                type="date"
                value={endDate}
                sx={styles.input}
                onChange={({ target }) => {
                  onValueChange({
                    type: "commerceEndDate",
                    value: target.value,
                  });
                }}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: blackColor,
                    fontSize: { md: 14, xs: 11 },
                    fontWeight: 500,
                  },
                }}
                disabled={disabled}
              />
            </Grid>

            {errors?.length > 0 ? (
              <Grid item md={12} mt={0}>
                {errors?.map((item, i) => (
                  <Typography
                    key={`error_${i}`}
                    color={"red"}
                    fontSize={12}
                    fontWeight={500}
                    display="block"
                    mr={1}
                    mb={0.5}
                    lineHeight="18px"
                  >
                    {item}
                  </Typography>
                ))}
              </Grid>
            ) : null}
          </Grid>
        ) : (
          <Grid
            container
            display={"flex"}
            alignItems="center"
            rowSpacing={{ xs: 1.9, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
            // backgroundColor={!toggleData ? whiteColor : greyColor42}
          >
            {!toggleData && (
              <Grid item md={3.8} xs={12}>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent={"space-between"}
                >
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Checkbox
                      sx={{ ml: -1, ...styles.checkbox }}
                      checked={isChecked}
                      size={"large"}
                      onChange={() => {
                        onValueChange({
                          type: "itemChange",
                          value: !isChecked,
                        });
                      }}
                    />
                    <Box sx={styles.imgContainer}>
                      <Box
                        component="img"
                        src={img}
                        height={"30px"}
                        maxWidth={"30px !important"}
                      />
                    </Box>
                    <Typography
                      fontSize={{ xs: 13, md: 14 }}
                      color={blackColor}
                      fontWeight={{ xs: 600, md: 500 }}
                      lineHeight={1.5}
                      mb="0px !important"
                      sx={{ ...textTwoLines }}
                    >
                      {title}
                    </Typography>
                  </Box>
                  <Box display={"flex"} gap={1}>
                    <StyledSwitch
                      sx={{ color: "red" }}
                      checked={availability}
                      onChange={() => {
                        if (availability) {
                          onValueChange({
                            type: "availability",
                            value: ["No"],
                          });
                        } else {
                          onValueChange({
                            type: "availability",
                            value: ["Yes"],
                          });
                        }
                      }}
                    />

                    <Box
                      sx={styles.iconContainer()}
                      onClick={() => {
                        handleShowData(true);
                      }}
                      mr={-1}
                    >
                      <ExpandMoreIcon
                        sx={styles.icon(matches ? whiteColor : "#e83658")}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
            {toggleData && (
              <Grid item md={3.8} xs={12}>
                <Box display="flex" alignItems="flex-start" justifyContent={"space-between"}>
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Checkbox
                      sx={{ ml: -1, ...styles.checkbox }}
                      checked={isChecked}
                      size={"large"}
                      onChange={() => {
                        onValueChange({
                          type: "itemChange",
                          value: !isChecked,
                        });
                      }}
                    />
                    <Box sx={styles.imgContainer}>
                      <Box
                        component="img"
                        src={img}
                        height={"30px"}
                        maxWidth={"30px !important"}
                      />
                    </Box>
                    <Typography
                      fontSize={{ xs: 13, md: 14 }}
                      color={blackColor}
                      fontWeight={{ xs: 600, md: 500 }}
                      lineHeight={1.5}
                      mb="0px !important"
                    >
                      {title}
                    </Typography>
                  </Box>
                  <Box display={"flex"} gap={1}>
                    <StyledSwitch
                      sx={{ color: "red" }}
                      checked={availability}
                      onChange={() => {
                        if (availability) {
                          onValueChange({
                            type: "availability",
                            value: ["No"],
                          });
                        } else {
                          onValueChange({
                            type: "availability",
                            value: ["Yes"],
                          });
                        }
                      }}
                    />
                    <Box
                      sx={styles.iconContainer()}
                      onClick={() => {
                        handleShowData(false);
                      }}
                      mr={-1}
                    >
                      <ExpandLessIcon
                        sx={styles.icon(matches ? whiteColor : "#e83658")}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}

            {toggleData && (
              <>
                <Grid item md={1.7} xs={6}>
                  <Typography
                    variant="h3"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyShade25}
                    fontWeight={{ xs: 500, md: 400 }}
                    textTransform="uppercase"
                    sx={styles.labelStyles}
                  >
                    AVAILABLE QTY
                  </Typography>

                  <InputBox
                    value={avlQty}
                    onChange={({ target }) => {
                      const value = target.value;
                      const regex = /^(\d{0,7})$/;
                      if (regex.test(value) && target.value.charAt(0) !== "0") {
                        onValueChange({
                          type: "availableQuantity",
                          value: target.value,
                        });
                      }
                    }}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item md={1.7} xs={6}>
                  <Typography
                    variant="h3"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyShade25}
                    fontWeight={{ xs: 500, md: 400 }}
                    textTransform="uppercase"
                    sx={styles.labelStyles}
                  >
                    ALERT QTY
                  </Typography>

                  <InputBox
                    value={alertQty}
                    onChange={({ target }) => {
                      const value = target.value;
                      const regex = /^(\d{0,7})$/;
                      if (regex.test(value) && target.value.charAt(0) !== "0") {
                        onValueChange({
                          type: "alertQuantity",
                          value: target.value,
                        });
                      }
                    }}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item md={1.7} xs={4}>
                  <Typography
                    variant="h3"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyShade25}
                    fontWeight={{ xs: 500, md: 400 }}
                    textTransform="uppercase"
                    sx={styles.labelStyles}
                  >
                    MIN QTY
                  </Typography>

                  <InputBox
                    value={minQty}
                    onChange={({ target }) => {
                      const value = target.value;
                      const regex = /^(\d{0,7})$/;
                      if (regex.test(value) && target.value.charAt(0) !== "0") {
                        onValueChange({ type: "minOrderQuantity", value });
                      }
                    }}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item md={1.7} xs={4}>
                  <Typography
                    variant="h3"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyShade25}
                    fontWeight={{ xs: 500, md: 400 }}
                    textTransform="uppercase"
                    sx={styles.labelStyles}
                  >
                    MAX QTY
                  </Typography>

                  <InputBox
                    value={maxQty}
                    onChange={({ target }) => {
                      const value = target.value;
                      const regex = /^(\d{0,7})$/;
                      if (regex.test(value) && target.value.charAt(0) !== "0") {
                        onValueChange({ type: "maxOrderQuantity", value });
                      }
                    }}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item md={1.2} xs={4}>
                  <Typography
                    variant="h3"
                    fontSize={{ xs: 11, md: 13 }}
                    color={greyShade25}
                    fontWeight={{ xs: 500, md: 400 }}
                    textTransform="uppercase"
                    sx={styles.labelStyles}
                  >
                    GST%
                  </Typography>

                  <SelectWithLabel
                    value={gst?.replace("%", "") || ""}
                    onValueChange={(value) => {
                      onValueChange({
                        type: "gstInfo",
                        value: { code: gstCode, gstPercentage: value },
                      });
                    }}
                    menuList={gstRates}
                    valueSelector="value"
                    isDashboardInput
                    disabled={disabled}
                  />
                </Grid>

                <Grid item md={1.7} xs={4}>
                  <Typography
                    variant="h3"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyShade25}
                    fontWeight={{ xs: 500, md: 400 }}
                    textTransform="uppercase"
                    sx={styles.labelStyles}
                  >
                    Original Price
                  </Typography>

                  <InputBox
                    value={originalAmt}
                    onChange={({ target }) => {
                      const value = target.value;
                      // const regex = /^(\d{0,7}|\d{0,7}\.)(?:\.\d{1,2})?$/;
                      // if (regex.test(value) && target.value.charAt(0) !== "0") {
                      //   onValueChange({
                      //     type: "productOriginalPrice",
                      //     value: target.value,
                      //   });
                      // }

                      const regex = /^\d{0,7}(\.\d{0,2})?$/;

                      if (regex.test(value)) {
                        onValueChange({
                          type: "productOriginalPrice",
                          value: value,
                        });
                      }
                    }}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item md={1.5} xs={4}>
                  <Typography
                    variant="h3"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyShade25}
                    fontWeight={{ xs: 500, md: 400 }}
                    textTransform="uppercase"
                    sx={styles.labelStyles}
                  >
                    % OFF
                  </Typography>

                  <InputBox
                    value={priceOff}
                    onChange={({ target }) => {
                      const newValue = target?.value?.replace(/[^0-9]/g, "");

                      onValueChange({
                        type: "priceOff",
                        value: newValue,
                      });
                    }}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item md={1.5} xs={4}>
                  <Typography
                    variant="h3"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyShade25}
                    fontWeight={{ xs: 500, md: 400 }}
                    textTransform="uppercase"
                    sx={styles.labelStyles}
                  >
                    Sale Price
                  </Typography>

                  <InputBox
                    value={amt}
                    onChange={({ target }) => {
                      const value = target.value;
                      const regex = /^\d{0,7}(\.\d{0,2})?$/;

                      if (regex.test(value)) {
                        onValueChange({
                          type: "productSalePrice",
                          value: value,
                        });
                      }

                      // const regex = /^(\d{0,7}|\d{0,7}\.)(?:\.\d{1,2})?$/;
                      // const regex = /^\d{0,7}(\.\d{0,2})?$/;
                      // if (
                      //   regex.test(value) &&
                      //   target.value.charAt(0) !== "0" &&
                      //   !isNaN(target.value)
                      // ) {
                      //   onValueChange({
                      //     type: "productSalePrice",
                      //     value: target.value,
                      //   });
                      // }
                    }}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item md={1.9} xs={4.2}>
                  <Typography
                    variant="h3"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyShade25}
                    fontWeight={{ xs: 500, md: 400 }}
                    textTransform="uppercase"
                    sx={styles.labelStyles}
                  >
                    Start Date
                  </Typography>

                  <TextField
                    variant="standard"
                    value={startDate}
                    type="date"
                    sx={styles.input}
                    onChange={({ target }) => {
                      onValueChange({
                        type: "commerceStartDate",
                        value: target.value,
                      });
                    }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: blackColor,
                        fontSize: { md: 14, xs: 11 },
                        fontWeight: 500,
                      },
                    }}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item md={1.5} xs={3.6}>
                  <Typography
                    variant="h3"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyShade25}
                    fontWeight={{ xs: 500, md: 400 }}
                    textTransform="uppercase"
                    sx={styles.labelStyles}
                  >
                    VALIDITY(DAYS)
                  </Typography>

                  <InputBox
                    value={validityDays}
                    onChange={({ target }) => {
                      const newValue = target?.value?.replace(/[^0-9]/g, "");

                      onValueChange({
                        type: "validityDays",
                        value: newValue,
                      });
                    }}
                    maxLength={4}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item md={1.9} xs={4.2}>
                  <Typography
                    variant="h3"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyShade25}
                    fontWeight={{ xs: 500, md: 400 }}
                    textTransform="uppercase"
                    sx={styles.labelStyles}
                  >
                    End Date
                  </Typography>
                  <TextField
                    variant="standard"
                    type="date"
                    value={endDate}
                    sx={styles.input}
                    onChange={({ target }) => {
                      onValueChange({
                        type: "commerceEndDate",
                        value: target.value,
                      });
                    }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: blackColor,
                        fontSize: { md: 14, xs: 11 },
                        fontWeight: 500,
                      },
                    }}
                    disabled={disabled}
                  />
                </Grid>

                {errors?.length > 0 ? (
                  <Grid item md={12} mt={0}>
                    {errors?.map((item, i) => (
                      <Typography
                        key={`error_${i}`}
                        color={"red"}
                        fontSize={12}
                        fontWeight={500}
                        display="block"
                        mr={1}
                        mb={0.5}
                        lineHeight="18px"
                      >
                        {item}
                      </Typography>
                    ))}
                  </Grid>
                ) : null}
              </>
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
}

export default AllStockCard;

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 35,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(8px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: copyBtnColor,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : outOfStockColor,
    boxSizing: "border-box",
  },
}));

const InputBox = ({ value, onChange, maxLength, disabled }) => {
  return (
    <TextField
      variant="standard"
      value={value}
      sx={styles.input}
      onChange={onChange}
      disabled={disabled}
      InputProps={{
        disableUnderline: true,
        style: {
          color: blackColor,
          fontSize: { md: 14, xs: 11 },
          fontWeight: 500,
        },
      }}
      inputProps={{ maxLength: maxLength }}
      fullWidth
      autoComplete="off"
    />
  );
};

const styles = {
  checkbox: {
    p: 0,
    transform: "scale(0.95)",
    "&.Mui-checked": {
      color: greyColor8,
    },
  },

  input: {
    width: { md: "65%", xs: "100%" },
    fontSize: { md: 14.5, xs: 11 },
    fontWeight: 500,
    borderTop: "none",
    borderBottom: borderCart,
    borderLeft: "none",
    borderRight: "none",
    backgroundColor: whiteColor,
  },

  labelStyles: {
    display: { md: "none", xs: "block" },
    mt: { xs: 0, md: 3 },
    mb: { xs: 0.5, md: 0 },
  },
  iconContainer: (bgColor, hideMobile) => ({
    width: { md: "40px", xs: "20px" },
    height: { md: "40px", xs: "20px" },
    borderRadius: "50%",
    backgroundColor: whiteColor,
    display: { md: "flex", xs: hideMobile ? "none" : "flex" },
    alignItems: "center",
    justifyContent: "center",
  }),
  icon: (color) => ({ fontSize: { md: 20, xs: 26 }, color: color }),
  imgContainer: {
    width: "40px !important",
    height: "40px",
    borderRadius: "6px",
    border: "1px solid #e5e9f0",
    backgroundColor: whiteColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
