 
import { getApiInstance, getApiInstanceForExistingUser, getApiInstanceForLogin, getApiInstanceForPassword, getApiInstanceForRegister } from "../../service/AppService";
import { getProfileId, storeUserInfo } from "../../utils/validationUtils";
  
  export const authLogin = async (data) => {
    console.log("fdjlkjd",data);
    const phone = "+91" + data.phone.replace(" ", "").trim();
    const loginFormData = new FormData();
    loginFormData.append("grant_type", "password");
    loginFormData.append("username", phone.replace("-", "").trim());
    loginFormData.append("password", data.password);
  
    const instance = getApiInstanceForLogin();
    try {
      const response = await instance.post(
        `https://web.costbo.com/costbouaa/oauth/token`,
        loginFormData
      );
      console.log("ksfms",response)
      return response;
    } catch (error) {
      console.log("hblhlkgbkb", error.response);
      return error.response;
    }
  };
  
  
export const checkUser = async (data) => {
  // console.log(data);
  const instance = getApiInstanceForExistingUser();
  const phone = "+91" + data.phone.replace(" ", "");
  try {
    const response = await instance.post(
      `https://web.costbo.com/costbouaa/api/account/checkUser`,
      {},
      {
        headers: {
          phone: phone.replace("-", ""),
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getProfileData = async () => {
  const profile = { _pId: getProfileId() };
  const instance = getApiInstance();
  try {
    const response = await instance.post(
      `https://web.costbo.com/account-query-side/api/v1/account/getAccount`,
      profile
    );
    if (response.status === 200) {
      storeUserInfo(response.data);
    }
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const changePassword = async (data) => {
  // console.log(data);
  let key = data.key;
  let password = data.password;
  const formJson = {
    key: key,
    newPassword: password,
  };
  const instance = getApiInstanceForPassword();
  try {
    const response = await instance.post(
      `https://web.costbo.com/costbouaa/api/account/reset-password/finish`,
      formJson
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const eRegister = async (data) => {
  // console.log("E_REGISTER", data);
  const instance = getApiInstance();
  try {
    const response = await instance.post(
      `https://web.costbo.com/costbouaa/api/eRegister`,
      data,
      {
        headers: {
          profileId: sessionStorage.getItem("profileId"),
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getCodeToMail = async (data) => {
  // console.log(data);
  let phone = "+91" + data.phone.replace("-", "");
  phone = phone.replace(" ", "");
  const instance = getApiInstanceForPassword();
  try {
    const response = await instance.post(
      `https://web.costbo.com/costbouaa/api/account/reset-password/init`,
      phone
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const storeUserData = async (data) => {
  // console.log(data);
  let xmpprandomNumber = Math.floor(100000 + Math.random() * 900000);
  const formData = {
    login: data.phone,
    password: data.password,
    firstName: data.name,
    lastName: "",
    email: data.email,
    phone: data.phone,
    type: "0",
    imeiNumber: "",
    deviceModel: "WEB",
    osVersion: "Windows",
    deviceID: "windows-10-Chrome",
    clientVersion: "",
    latitude: 0,
    longitude: 0,
    gender: "M/F",
    birthYear: "0",
    fcmKey: "",
    apnsKey: "",
    fcmUserToken: data.token,
    id: "",
    imageUrl: "",
    status: "",
    sr: false,
    country: "INDIA",
    xmppID: xmpprandomNumber,
  };

  // console.log("USER_DATA", formData);

  const instance = getApiInstanceForRegister();
  try {
    const response = await instance.post(
      `https://web.costbo.com/costbouaa/api/registerUser`,
      formData
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};


