import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import DashboardMenu from "./Dashboard/DashboardMenu";
import DashboardHeader from "./Dashboard/DashboardHeader";
import { BGColor02 } from "../configs/styles/muiThemes";
import EmptyBusinessListContent from "./EmptyBusinessList/EmptyBusinessListContent";
import { DISABLE_ALL_MENU } from "../utils/data";
import DashboardLayout from "../components/Layouts/DashboardLayout";

function EmptyBusinessList() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Box sx={styles.container}>
      {/* {matches ? (
        <Box sx={styles.menuStickyLeft}>
          <DashboardMenu
          disableMenu={DISABLE_ALL_MENU}
          />
        </Box>
      ) : null} */}

      {/* <Box flex={1}>
        <Box sx={styles.stickyTop}>
          <DashboardHeader />
        </Box>

        <Box sx={styles.content}>
          <EmptyBusinessListContent />
        </Box>
      </Box> */}
      <DashboardLayout activeMenu="store" isLoading>
        <Box sx={styles.content}>
          <EmptyBusinessListContent />
        </Box>
      </DashboardLayout>
    </Box>
  );
}

export default EmptyBusinessList;

const styles = {
  container: {
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: { xs: "0px", md: "25px" },
    p: { xs: "0px", md: "25px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },
};
