import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getBusinessCategoryApi,
  getBusinessLookupApi,
  getMyBusinessSummaryAPI,
  getUserEditEligibilityApi,
} from "../../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";

const initialState = {
  myBusinessSummary: null,
  businessLookupTypes: null,
  businessCategory: null,
  editEligibility: null,

  myBusinessSummaryLoader: false,
  businessLookupTypesLoader: false,
  businessCategoryLoader: false,
  editEligibilityLoader: false,
};

//Fetch My Business Summary
export const getMyBusinessSummary = createAsyncThunk(
  "getMyBusinessSummary",
  async (param, thunkAPI) => {
    const res = await getMyBusinessSummaryAPI();
    return res;
  }
);

//Fetch My Business Summary
export const getBusinessLookupTypes = createAsyncThunk(
  "getBusinessLookupTypes",
  async (param, thunkAPI) => {
    const res = await getBusinessLookupApi();
    return res;
  }
);

export const getBusinessCategory = createAsyncThunk(
  "getBusinessCategory",
  async (param, thunkAPI) => {
    const res = await getBusinessCategoryApi();
    return res;
  }
);

export const getUserEditEligibility = createAsyncThunk(
  "getUserEditEligibility",
  async (param, thunkAPI) => {
    const res = await getUserEditEligibilityApi();
    return res;
  }
);

export const businessOnboarding = createSlice({
  name: "businessOnboard",
  initialState,
  reducers: {
    clearUserEditEligibility: (state, action) => {
      state.editEligibility = null;
    },
  },
  extraReducers: {
    [getMyBusinessSummary.pending]: (state) => {
      state.myBusinessSummaryLoader = true;
    },
    [getMyBusinessSummary.fulfilled]: (state, { payload }) => {
      state.myBusinessSummaryLoader = false;
      state.myBusinessSummary = payload;
    },
    [getMyBusinessSummary.rejected]: (state, { payload }) => {
      state.myBusinessSummaryLoader = false;
    },

    //Business Types
    [getBusinessLookupTypes.pending]: (state) => {
      state.businessLookupTypesLoader = true;
    },
    [getBusinessLookupTypes.fulfilled]: (state, { payload }) => {
      state.businessLookupTypesLoader = false;
      const dataList = payload?.details || [];

      dataList?.sort((a, b) => {
        let na = a?.name?.toLowerCase(),
          nb = b?.name?.toLowerCase();

        if (na < nb) {
          return -1;
        }
        if (na > nb) {
          return 1;
        }
        return 0;
      });

      state.businessLookupTypes = dataList;
    },
    [getBusinessLookupTypes.rejected]: (state, { payload }) => {
      state.businessLookupTypesLoader = false;
    },

    //Business Category
    [getBusinessCategory.pending]: (state) => {
      state.businessCategoryLoader = true;
    },
    [getBusinessCategory.fulfilled]: (state, { payload }) => {
      state.businessCategoryLoader = false;
      const dataList = payload?.details || [];

      dataList?.sort((a, b) => {
        let na = a?.name?.toLowerCase(),
          nb = b?.name?.toLowerCase();

        if (na < nb) {
          return -1;
        }
        if (na > nb) {
          return 1;
        }
        return 0;
      });

      state.businessCategory = dataList;
    },
    [getBusinessCategory.rejected]: (state, { payload }) => {
      state.businessCategoryLoader = false;
    },

    //get User Edit Eligibility
    [getUserEditEligibility.pending]: (state) => {
      state.editEligibilityLoader = true;
    },
    [getUserEditEligibility.fulfilled]: (state, { payload }) => {
      state.editEligibilityLoader = false;
      state.editEligibility = payload || null;
    },
    [getUserEditEligibility.rejected]: (state, { payload }) => {
      state.editEligibilityLoader = false;
    },
  },
});

export const { clearUserEditEligibility } = businessOnboarding.actions;
export const businessOnboardingReducer = businessOnboarding.reducer;
