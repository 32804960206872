import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  bgColor15,
  greyColor24,
  whiteColor,
} from "../../../../configs/styles/muiThemes";
import { PRODUCT_DEMO_PICS } from "../../../../utils/data";

function ProductPicRecommendations() {
  return (
    <Box sx={styles.modelContainer}>
      <Box m="16px 0 34px">
        <Typography
          variant="h5"
          fontSize={{xs:16, md:18}}
          fontWeight={600}
          color={greyColor24}
          textAlign="center"
        >
          Picture Recommendations and Samples
        </Typography>
      </Box>

      <Typography
        variant="h5"
        fontSize={{xs:14, md:16}}
        fontWeight={600}
        color={greyColor24}
        mb="15px !important"
      >
        Please ensure the product images follow these guidelines for better
        customer experience
      </Typography>

      <Typography variant="body1" sx={styles.infoText}>
        1. Image Background should be white (preferably)
      </Typography>

      <Typography variant="body1" sx={styles.infoText}>
        2. For Food products, include an image displaying the nutrition facts
      </Typography>

      <Typography variant="body1" sx={styles.infoText}>
        3. For all FMCG products, include an image displaying the ingredients
      </Typography>

      <Typography variant="body1" sx={styles.infoText}>
        4. Do not include any contact information on the images
      </Typography>

      <Typography variant="body1" sx={styles.infoText}>
        5. Provide all necessary angles of the product, including a front and
        side view
      </Typography>

      <Typography
        variant="h5"
        fontSize={{xs:15, md:16}}
        fontWeight={600}
        color={greyColor24}
        m="30px 0 15px !important"
      >
        Sample Images
      </Typography>

      <Box>
        <Grid container spacing={2}>
          {PRODUCT_DEMO_PICS?.map((item, index)=>(
            <Grid item xs={12} md={3} key={`demo_pic${index}`}>
            <Box sx={{textAlign:'center'}}>
            <Box component='img' src={item} sx={{minWidth:{xs:'calc(100vw - 80px)', md:'240px'},maxHeight:'240px', borderRadius:'15px'}} />
            </Box>
          </Grid>
          ))}
          
        </Grid>
      </Box>
    </Box>
  );
}

export default ProductPicRecommendations;

const styles = {
  modelContainer: {
    maxHeight: {xs:'90vh', md:'auto'},
    overflowY: 'scroll',
    backgroundColor: whiteColor,
    borderRadius: "16px",
    padding: "15px",
  },

  infoText: {
    fontSize: {xs:12.5, md:13.5},
    fontWeight: 400,
    color: bgColor15,
    mb: "14px !important",
  },
};
