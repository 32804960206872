import React, { useState } from "react";
import { Box, Button, Typography, Grid, useMediaQuery } from "@mui/material";
import {
  BGColor02,
  blackShade05,
  blackShade19,
  blueColor04,
  greyColor9,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import FormInputBoxWithBorder from "../../../components/Input/FormInputBoxWithBorder";
import PickLocationBtn from "../../../components/Buttons/PickLocationBtn";
import PickAddressFromMapModal from "../../../components/PickAddress/PickAddressFromMapModal";
import { getGeoPointApi } from "../../../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";
import { isStringNotPresentInArray } from "../../../utils/helper";

function AddBranchPopup({
  businessName,
  data,
  setData,
  onValueChange,
  onCancel,
  onSubmit,
  loader,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [openMap, setOpenMap] = useState({
    show: false,
    userLat: "",
    userLong: "",
  });

  const [cityDisabled, setCityDisabled] = useState(true);

  // Get user current location address
  const handleChooseCurrentLocation = () => {
    setOpenMap({
      show: true,
      userLat: localStorage.getItem("lat"),
      userLong: localStorage.getItem("long"),
    });
  };

  const handlePinChange = async (value) => {
    if (value?.length === 6) {
      const responce = await getGeoPointApi(value);

      const pinCodeErr = isStringNotPresentInArray(
        responce?.results?.[0]?.address_components,
        "postal_code"
      );
      console.log("pinCodeErrpinCodeErr", pinCodeErr);
      if (pinCodeErr) {
        console.log("Hit");
        setData({
          ...data,
          pinCode: value,
          city: "",
          state: "",
          landmark: "",
          address: "",
          errors: {
            ...data?.errors,
            pinCode: "Invalid pincode",
          },
        });
        return;
      }

      const city = responce?.results?.[0]?.address_components?.[1]?.long_name;

      const state =
        responce?.results?.[0]?.address_components?.[
          responce?.results?.[0]?.address_components?.length - 2
        ]?.long_name;

      if (!city || !state) setCityDisabled(false);

      setData({
        ...data,
        city: city || "",
        state: state || "",
        landmark: "",
        locality: city || "",
        sublocality: "",
        lat: responce?.results?.[0]?.geometry?.location?.lat || "",
        lng: responce?.results?.[0]?.geometry?.location?.lng || "",
        pinCode: value,
        errors: { ...data?.errors, city: "", state: "", pinCode: "" },
      });

      setOpenMap({
        show: true,
        userLat: responce?.results?.[0]?.geometry?.location?.lat || "",
        userLong: responce?.results?.[0]?.geometry?.location?.lng || "",
      });
    }
  };

  // autoFill user selected address from map
  const handlePickLocationConfirm = (userAddress) => {
    if (userAddress) {
      setData({
        ...data,
        city: userAddress?.city || "",
        state: userAddress?.state || "",
        pinCode: userAddress?.postalCode,
        address: userAddress?.address || "",
        lat: userAddress?.lat || "",
        lng: userAddress?.lng || "",
        landmark: userAddress?.locality || "",
        locality: userAddress?.locality || "",
        sublocality: userAddress?.sublocality || "",
        errors: {
          ...data?.errors,
          city: "",
          state: "",
          pinCode: "",
          address: "",
        },
      });
    }

    setOpenMap({
      show: false,
      userLat: "",
      userLong: "",
    });
  };
  return (
    <Box sx={{ background: whiteColor, borderRadius: "10px" }}>
      <PickAddressFromMapModal
        isOpen={openMap.show}
        setIsOpen={setOpenMap}
        onConfirm={handlePickLocationConfirm}
        userLat={openMap.userLat}
        userLong={openMap.userLong}
      />
      <Box
        sx={{
          backgroundColor: BGColor02,
          p: "10px 15px",
          borderRadius: "10px 10px 0 0",
        }}
      >
        <Typography
          fontSize={17}
          fontWeight={600}
          color={blackShade19}
          textAlign={"center"}
          mb={0.5}
        >
          {businessName}
        </Typography>
        <Typography
          fontSize={13}
          fontWeight={500}
          color={greyColor9}
          textAlign={"center"}
        >
          Master Branch
        </Typography>
      </Box>
      <Box p={2} sx={styles.content}>
        <Grid container rowSpacing={0} columnSpacing={{ md: 3, xs: 2 }}>
          <Grid item xs={12} md={12}>
            <FormInputBoxWithBorder
              label="Branch Name"
              value={data?.branchName}
              onChange={({ target }) => {
                onValueChange("branchName", target.value);
              }}
              required
              maxLength={50}
              error={data?.errors?.branchName}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormInputBoxWithBorder
              label="Registered Business Name (as per GST, for invoicing)"
              value={data.registeredBusinessName}
              onChange={({ target }) => {
                onValueChange("registeredBusinessName", target.value);
              }}
              maxLength={100}
              error={data?.errors?.registeredBusinessName}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInputBoxWithBorder
              label="GSTIN Number"
              value={data.gstNumber}
              onChange={({ target }) => {
                const value = target.value
                  .replace(/[^a-zA-Z0-9]/g, "")
                  .toUpperCase();

                if (value.length == 15) {
                  const reg =
                    /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
                      value
                    );
                  console.log("reg", reg);
                  if (reg) {
                    setData({
                      ...data,
                      gstNumber: value,
                      panNumber: value.slice(2, 12),
                      errors: {},
                    });
                  } else {
                    setData({
                      ...data,
                      gstNumber: value,
                      errors: {
                        ...data?.errors,
                        gstNumber: "Please enter the valid GST Number.",
                      },
                    });
                  }
                } else {
                  onValueChange("gstNumber", value);
                }
              }}
              maxLength={15}
              error={data?.errors?.gstNumber}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInputBoxWithBorder
              label="PAN Number"
              value={data?.panNumber}
              onChange={({ target }) => {
                const value = target.value
                  .replace(/[^a-zA-Z0-9]/g, "")
                  .toUpperCase();
                if (value.length == 10) {
                  const reg = /[A-Z]{5}\d{4}[A-Z]{1}/.test(value);
                  if (reg) {
                    setData({
                      ...data,
                      panNumber: value,
                      errors: {},
                    });
                  } else {
                    setData({
                      ...data,
                      panNumber: value,
                      errors: {
                        ...data?.errors,
                        panNumber: "Please enter the valid PAN Number.",
                      },
                    });
                  }
                } else {
                  onValueChange("panNumber", value);
                }
              }}
              maxLength={10}
              error={data?.errors?.panNumber}
              required
            />
          </Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent="space-between"
          mb={0.5}
          mt={1}
          alignItems="center"
        >
          <Typography
            fontSize={14}
            fontWeight={600}
            color={blackShade19}
            textAlign={"start"}
          >
            Business Address Detail
          </Typography>
          <PickLocationBtn
            onChooseLocation={handleChooseCurrentLocation}
            onPickNearLocation={() => {
              setOpenMap(true);
            }}
            bgColor={whiteColor}
            textColor={matches ? blueColor04 : mobilePrimary}
          />
        </Box>

        <Grid container rowSpacing={{ md: 0.5, xs: 0 }} columnSpacing={3}>
          <Grid item xs={12} md={4}>
            <FormInputBoxWithBorder
              label="PinCode"
              required
              maxLength={6}
              value={data?.pinCode}
              onChange={({ target }) => {
                const value = target.value.replace(/[a-zA-Z]|[^\w\s]/gi, "");
                onValueChange("pinCode", value);
                if (!cityDisabled) setCityDisabled(true);
                if (value.length >= 6) handlePinChange(value);
              }}
              error={data?.errors?.pinCode}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormInputBoxWithBorder
              label="City"
              value={data?.city}
              onChange={({ target }) => {
                onValueChange("city", target.value);
              }}
              required
              disabled={cityDisabled}
              inputOpaticy={1}
              error={data?.errors?.city}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormInputBoxWithBorder
              label="State"
              value={data?.state}
              onChange={({ target }) => {
                onValueChange("state", target.value);
              }}
              required
              disabled={cityDisabled}
              inputOpaticy={1}
              error={data?.errors?.state}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInputBoxWithBorder
              label="Bldg No, Street, Area"
              value={data?.address}
              onChange={({ target }) => {
                onValueChange("address", target.value);
              }}
              error={data?.errors?.address}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInputBoxWithBorder
              label="Landmark"
              value={data?.landmark}
              onChange={({ target }) => {
                onValueChange("landmark", target.value);
              }}
            />
          </Grid>
        </Grid>
        <Typography
          fontSize={14}
          fontWeight={600}
          color={blackShade19}
          textAlign={"start"}
          mt={1}
          mb={0.5}
        >
          Contact Detail
        </Typography>
        <Grid container rowSpacing={{ md: 0.5, xs: 0 }} columnSpacing={3}>
          <Grid item xs={12} md={6}>
            <FormInputBoxWithBorder
              label="Phone Number"
              required
              startIcon="+91"
              maxLength={10}
              value={data?.phoneNumber}
              onChange={({ target }) => {
                const number = target.value?.replace(/[^0-9]/g, "");
                onValueChange("phoneNumber", number);
              }}
              error={data?.errors?.phoneNumber}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInputBoxWithBorder
              label="Email"
              required
              value={data?.email}
              onChange={({ target }) => {
                onValueChange("email", target.value);
              }}
              error={data?.errors?.email}
            />
          </Grid>
        </Grid>
        <Typography
          fontSize={13}
          fontWeight={500}
          color={blackShade19}
          textAlign={"center"}
          mt={1}
        >
          E-Commerce Settings and Active Catalog details will be copied over
          from the Master Branch
        </Typography>
        <Typography
          fontSize={13}
          fontWeight={500}
          color={blackShade19}
          textAlign={"center"}
          mt={1.5}
        >
          You will be able to switch the Super Admin and assign managers, once
          the branch is activated
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: BGColor02,
          p: "10px 15px",
          borderRadius: "0 0 10px 10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          sx={styles.cancelBtn}
          variant="contained"
          disableElevation
          onClick={onCancel}
          disabled={loader}
        >
          Cancel
        </Button>
        <Button
          sx={styles.submitBtn}
          variant="contained"
          disableElevation
          onClick={onSubmit}
          disabled={loader}
        >
          Add Branch
        </Button>
      </Box>
    </Box>
  );
}

export default AddBranchPopup;

const styles = {
  content: {
    maxHeight: "70vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      // background: '#d0d3da',
      background: "rgba(0,0,0,0.1)",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.05)",
    },
  },
  submitBtn: {
    padding: "5px 16px",
    width: { md: "auto", xs: "90%" },
    fontSize: "12.5px",
    fontWeight: 600,
    backgroundColor: blueColor04,
    color: whiteColor,
    border: "1px solid " + blueColor04,
    "&:hover": {
      backgroundColor: whiteColor,
      color: blueColor04,
    },

    "&.Mui-disabled": {
      border: "none",
    },
  },
  cancelBtn: {
    padding: "5px 16px",
    width: { md: "auto", xs: "90%" },
    fontSize: "12.5px",
    fontWeight: 600,
    color: blackShade19,
    backgroundColor: whiteColor,
    border: "1px solid " + blackShade19,
    "&:hover": {
      color: whiteColor,
      backgroundColor: blackShade19,
    },

    "&.Mui-disabled": {
      border: "none",
    },
  },
};
