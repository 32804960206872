import moment from "moment";
import { getApiInstance } from "../../AppService";
import {
  ADD_NEW_COUPON,
  CURRENT_DATE,
  FROM_DATE,
  GET_ALL_COUPONS_COUNT,
  GET_ALL_COUPONS_SUMMARY,
  GET_ALL_COUPON_CODE,
} from "../../constant";
import { getProfileId } from "../../../utils/validationUtils";

export const getAllCouponsSummaryApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(GET_ALL_COUPONS_SUMMARY, {
      params: {
        from: FROM_DATE,
        to: moment().valueOf(),
      },
    });
    console.log("getAllCouponsSummaryApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCouponsCountApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(GET_ALL_COUPONS_COUNT, {
      params: {
        from: FROM_DATE,
        to: moment().valueOf(),
      },
    });
    console.log("getAllCouponsCountApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCouponsCodeApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(GET_ALL_COUPON_CODE, {
      params: {
        from: FROM_DATE,
        to: moment().valueOf(),
        pageNo: 1,
        pageSize: 200,
      },
    });
    console.log("getAllCouponsCodeApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Add New Coupon details
export const addNewCouponApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.post(ADD_NEW_COUPON, data);
    console.log("getAllCouponsCodeApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const prepareAddNewCouponPostData = (data) => {
  const profileId = getProfileId()

  const postData = {
    businessId: data?.businessId,
    businessName: data?.businessName,
    profileId: profileId,
    phoneNo: "",
    resellerName: "",
    resellerBusinessName: "",
    resellerBusinessId: "",
    resellerCode: data?.couponCode,
    resellerType: "business",
    status: "active",
    cancelReason: "NA",
    resellerPan: "",
    commissionPercentage: 0.0,
    commissionAmount: 0.0,
    discountPercentage: data?.discountPercentage,
    activatedDate: moment(data?.startDate).startOf("day").valueOf(),
    deactivatedDate: moment(data?.endDate).endOf("day").valueOf(),
    codeValidityStart: moment(data?.startDate).startOf("day").valueOf(),
    codeValidityEnd: moment(data?.endDate).endOf("day").valueOf(),
  };

  return postData
};
