import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import {
  authInputBorder,
  borderTextbox,
  descriptionColor,
  errorTextColor,
} from "../configs/styles/muiThemes";
import { allowOnlyEnglish } from "../utils/helper";

function AuthTextBox({
  placeholder,
  name,
  value,
  onChange,
  icon,
  onIconClick,
  showPassword,
  maxLength,
  inputType,
  isError,
  error,
  errorMsg,
  helperText,
  autoFocus,
  inputRef,
  onKeyUp,
  showAutoComplete,
}) {
  
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = allowOnlyEnglish(inputValue)

    if(onChange){
      onChange({ target: { value: sanitizedValue } });
    }
  };

  return (
    <Box>
      <TextField
        variant="standard"
        required={true}
        fullWidth
        name={name}
        value={value}
        autoFocus={autoFocus}
        onChange={handleInputChange}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        inputRef={inputRef}
        sx={{
          fontSize: { md: 13, xs: 12.5 },
          my: { md: "6px !important", xs: "3px !important" },
          // borderBottom: borderTextbox,
          border: authInputBorder,
          p: "6px 10px",
          borderRadius: "6px",
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ cursor: "pointer" }}>
              {icon}
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        inputProps={{
          maxLength: maxLength,
          autocomplete: showAutoComplete
            ? "on"
            : inputType === "password"
            ? "new-password"
            : "off",
        }}
        type={inputType}

        // {...(error && { error: true, helperText: error })}
      />

      {isError ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={13}
          color={errorTextColor}
        >
          {errorMsg}
        </Typography>
      ) : null}

      {helperText ? (
        <Typography
          variant="body1"
          component="p"
          fontSize={11}
          color={descriptionColor}
        >
          {helperText}
        </Typography>
      ) : null}
    </Box>
  );
}

export default AuthTextBox;
