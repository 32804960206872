import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    clearPreferredDiscountsInfo,
    getActivePreferredDiscountsData,
    getExpiredPreferredDiscountsData,
} from "../../../reducers/preferredDiscount/PreferredDiscountSlice";
import moment from "moment";
import PreferredDiscountCard from "../Card/PreferredDiscountCard";
import InfiniteScroll from "react-infinite-scroll-component";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import EmptyCard from "../../../components/Cards/EmptyCard";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";

function ExpiredPreferredDiscountContent({
  openDeleteAlter,
  searchData,
  searchStatus,
}) {
  const dispatch = useDispatch();

  const { expiredPreferredDiscounts, expiredPreferredDiscountsLoader } =
    useSelector((state) => state.businessPreferredDiscounts);

  const getDiscountData = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      getExpiredPreferredDiscountsData({
        businessId,
        status: "active",
        pageNo: expiredPreferredDiscounts?.pageNo + 1,
      })
    );
  };

  useEffect(() => {
    getDiscountData();

    return () => {
      dispatch(clearPreferredDiscountsInfo());
    };
  }, []);

  useEffect(() => {
    console.log("EXPIRED_CODE_SUCCESS:", expiredPreferredDiscounts);
    console.log(
      "EXPIRED_CODE_SUCCESS_CODE_HAS_MORE",
      Math.round(
        Number(expiredPreferredDiscounts?.totalCount) /
          Number(expiredPreferredDiscounts?.pageSize)
      ) !== Number(expiredPreferredDiscounts?.pageNo)
    );
    return () => {};
  }, [expiredPreferredDiscounts]);

  return (
    <Box>
      {!expiredPreferredDiscountsLoader &&
        expiredPreferredDiscounts?.details?.length === 0 && (
          <EmptyCard
            msg="No expired personalized Discount "
            icon={<DiscountOutlinedIcon sx={{ fontSize: "24px" }} />}
          />
        )}
      {expiredPreferredDiscountsLoader && <ThreeDotLoader />}

      {expiredPreferredDiscounts?.details?.length > 0 && (
        <InfiniteScroll
          dataLength={expiredPreferredDiscounts?.details?.length || 0}
          next={getDiscountData}
          hasMore={
            Math.round(
              Number(expiredPreferredDiscounts?.totalCount) /
                Number(expiredPreferredDiscounts?.pageSize)
            ) !== Number(expiredPreferredDiscounts?.pageNo)
          }
          loader={
            <Box>
              <ThreeDotLoader />
            </Box>
          }
        >
          <Grid container spacing={2} >
            {expiredPreferredDiscounts?.details?.map((item, index) => {
              const endDate = item?.pointEndDate;
              const currentDate = moment(endDate).valueOf();
              const statusText = item?.rewardStatus;

              const userInfo = expiredPreferredDiscounts?.profileInfo?.filter(
                (profile) => item.rewardsProfileId === profile.profileId
              );

              let username = "";
              let profilePic = "";

              if (userInfo?.length > 0) {
                username = userInfo?.[0]?.name;
                profilePic = userInfo?.[0]?.photoUrl;
              }

              return (
                <Grid item xs={12} md={3} mt={4}>
                  <PreferredDiscountCard
                    customerName={username}
                    customerNumber={item?.rewardsPhoneNo.replace(
                      /(\d{2})(\d{0})/,
                      "$1 $2"
                    )}
                    totalDiscount={item?.pointValue}
                    startDate={moment(item?.pointStartDate).format(
                      "MMM Do YYYY"
                    )}
                    endDate={moment(endDate).format("MMM Do YYYY")}
                    percentage={item?.pointPercentage}
                    minpurchase={item?.minimumAmount}
                    statusText={statusText}
                    profileImage={profilePic}
                    item={item}
                    onDeleteClick={() => {
                      openDeleteAlter(item);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </InfiniteScroll>
      )}
    </Box>
  );
}

export default ExpiredPreferredDiscountContent;
