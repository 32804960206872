import {
  Avatar,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoCardHeader from "./InfoCardHeader";
import {
  BGColor02,
  bgColor15,
  greenStatusColor,
  greyColor15,
  greyColor16,
  greyColor42,
  greyColor50,
  greyColor51,
  logoImgborder1,
  orangeStatusColor,
  redStatusColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import moment from "moment";
import { CHAR_AVATAR_COLORS } from "../../../utils/data";
import DashboardEmptyCard from "./DashboardEmptyCard";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import { addThousandsSeparator } from "../../../utils/helper";
import Slider from "react-slick";
import MobileInfoCardHeader from "../MObileInfoCards/MobileInfoCardHeader";

function RecentOrdersInfoCard({ recentOrders, onClick }) {
  const [rows, setRows] = useState([]);
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3500,
    arrows: false,
  };

  const createData = (
    name,
    city,
    orderDate,
    discount,
    totalAmt,
    status,
    orderId
  ) => {
    return { name, city, orderDate, discount, totalAmt, status, orderId };
  };

  const prepareTableData = () => {
    const orderList = [];
    console.log("recentOrders",recentOrders);
    recentOrders?.slice(0, 3)?.forEach((element) => {
      orderList?.push(
        createData(
          element?.customerName,
          element?.customerCity || "Store Pickup",
          element?.orderDate,
          `₹ ${addThousandsSeparator(element?.discount?.toFixed(2))}`,
          `₹ ${addThousandsSeparator(element?.orderCost?.toFixed(2))}`,
          element?.status,
          element?.orderId
        )
      );
    });

    setRows(orderList);
  };

  const getStatusColor = (status) => {
    if (status === "ordercreated") {
      return orangeStatusColor;
    } else if (status === "orderapproved") {
      return orangeStatusColor;
    } else if (status === "ordershipped" || status === "orderdelivered") {
      return greenStatusColor;
    } else {
      return redStatusColor;
    }
  };

  const getColor = (name) => {
    let color = "#e8e8e8";

    if (name?.length > 0) {
      CHAR_AVATAR_COLORS?.forEach((item) => {
        if (item?.char?.includes(name?.charAt(0).toLowerCase())) {
          color = item.color;
        }
      });
    }

    return color;
  };

  const getStatusText = (status) => {
    switch (status) {
      case "ordercreated":
        return "Created";

      case "orderapproved":
        return "Approved";

      case "ordershipped":
        return "Shipped";

      case "orderdelivered":
        return "Delivered";

      case "bordercanceled":
        return "Canceled";

      case "cordercanceled":
        return "Canceled";

      default:
        return "";
    }
  };

  useEffect(() => {
    if (recentOrders?.length > 0) {
      prepareTableData();
    }
    return () => {};
  }, [recentOrders]);

  return (
    <Box sx={styles.card}>
      {matches && (
        <>
          <InfoCardHeader
            title="Recent Orders"
            onManageClick={onClick}
            buttonText="View All"
            hideBtn={rows?.length === 0}
          />
          <Box mt={1}>
            {rows?.length === 0 ? (
              <DashboardEmptyCard
                icon={
                  <ProductionQuantityLimitsOutlinedIcon
                    sx={{ fontSize: "24px" }}
                  />
                }
                text="No Orders Available"
                hideBtn
              />
            ) : matches ? (
              <TableContainer
                component={Box}
                sx={{ border: "1px solid #E9ECEF", borderRadius: "12px" }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: greyColor42 }}>
                      <TableCell sx={{ ...styles.tableCell, pl: 1 }}>
                        Customer Name
                      </TableCell>
                      <TableCell sx={styles.tableCell}>City</TableCell>
                      <TableCell sx={styles.tableCell}>Order Date</TableCell>
                      <TableCell sx={styles.tableCell}>Discount</TableCell>
                      <TableCell sx={styles.tableCell}>Total Amount</TableCell>
                      <TableCell sx={styles.tableCell}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          border: "0px solid #E9ECEF",

                          "&:last-child td, &:last-child th": {
                            borderr: 0,
                            borderColor: "#E9ECEF",
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            ...styles.tableBodyCell(),
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                          component="th"
                          scope="row"
                          ml={2}
                        >
                          <Avatar sx={styles.avatar(getColor(row.name))}>
                            {row.name?.slice(0, 2)?.toUpperCase()}
                          </Avatar>{" "}
                          {row.name?.length > 30
                            ? `${"kcjndjncdjncjddncdkcnkdjnckdjncdknjncdkn".slice(
                                0,
                                29
                              )}...`
                            : row.name}
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell()} align="left">
                          {row.city}
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell()} align="left">
                          {moment(row.orderDate).format("DD-MM-YYYY")}
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell()} align="left">
                          {row.discount}
                        </TableCell>

                        <TableCell sx={styles.tableBodyCell()} align="left">
                          {row.totalAmt}
                        </TableCell>

                        <TableCell
                          sx={styles.tableBodyCell(getStatusColor(row.status))}
                          align="left"
                        >
                          {getStatusText(row.status)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Slider {...settings}>
                {rows?.map((row) => {
                  console.log("row", row);
                  return (
                    <Box sx={{ ...styles.card, mb: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box display={"flex"} flexDirection={"row"} gap={1}>
                            <Avatar sx={styles.avatar(getColor(row.name))}>
                              {row.name?.slice(0, 2)?.toUpperCase()}
                            </Avatar>
                            <Box display={"flex"} flexDirection={"column"}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  color: greyColor51,
                                  fontWeight: 600,
                                  lineHeight: 1.5,
                                }}
                              >
                                {row.name?.length > 30
                                  ? `${"kcjndjncdjncjddncdkcnkdjnckdjncdknjncdkn".slice(
                                      0,
                                      29
                                    )}...`
                                  : row.name}
                              </Typography>
                              <Box display={"flex"} gap={3}>
                                <Typography
                                  sx={{
                                    fontSize: "12.5px",
                                    color: bgColor15,
                                    fontWeight: 600,
                                    lineHeight: 1.5,
                                  }}
                                >
                                  {row.totalAmt}
                                </Typography>
                                {/* <Typography
                              sx={{
                                fontSize: "12.5px",
                                color: bgColor15,
                                fontWeight: 600,
                                lineHeight: 1.5,
                              }}
                            >
                              {row.discount}
                            </Typography> */}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display={"flex"} flexDirection={"column"}>
                            <Typography
                              sx={{
                                fontSize: "12.5px",
                                color: greyColor51,
                                fontWeight: 600,
                                lineHeight: 1.5,
                              }}
                            >
                              ID# {row.orderId}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: bgColor15,
                                fontWeight: 600,
                                lineHeight: 1.5,
                              }}
                            >
                              {moment(row.orderDate).format("DD MMM, hh:mm A")}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          alignContent={"center"}
                          textAlign={"end"}
                        >
                          <Typography
                            sx={{
                              fontSize: "11px",
                              color: getStatusColor(row.status),
                              fontWeight: 600,
                              lineHeight: 1.5,
                              textAlign: "flex-end",
                            }}
                          >
                            {getStatusText(row.status)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
              </Slider>
            )}
          </Box>
        </>
      )}
      {!matches && (
        <>
          <Box>
            <Box borderBottom={`1px solid #F5F7FC`} pb={1}>
              <MobileInfoCardHeader
                title="Recent Orders"
                onManageClick={onClick}
                buttonText="View All"
                hideBtn={rows?.length === 0}
              />
            </Box>
            {rows?.length === 0 ? (
              <DashboardEmptyCard
                icon={
                  <Box
                    component="img"
                    src={
                      "https://storage.googleapis.com/bodefaults/businessdashboardweb/order-empty.svg"
                    }
                    width="40px"
                  />
                }
                text="No Orders Available"
                hideBtn
              />
            ) : (
              <Slider {...settings}>
                {rows?.map((row) => {
                  console.log("row", row);
                  return (
                    <Box sx={{ ...styles.card }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Box display={"flex"} flexDirection={"row"} gap={1}>
                            <Avatar sx={styles.avatar(getColor(row.name))}>
                              {row.name?.slice(0, 2)?.toUpperCase()}
                            </Avatar>
                            <Box display={"flex"} flexDirection={"column"}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  color: greyColor51,
                                  fontWeight: 600,
                                  lineHeight: 1.5,
                                }}
                              >
                                {row.name?.length > 30
                                  ? `${"kcjndjncdjncjddncdkcnkdjnckdjncdknjncdkn".slice(
                                      0,
                                      29
                                    )}...`
                                  : row.name}
                              </Typography>
                              <Box display={"flex"} gap={3}>
                                <Typography
                                  sx={{
                                    fontSize: "12.5px",
                                    color: bgColor15,
                                    fontWeight: 600,
                                    lineHeight: 1.5,
                                  }}
                                >
                                  {row.totalAmt}
                                </Typography>
                                {/* <Typography
                              sx={{
                                fontSize: "12.5px",
                                color: bgColor15,
                                fontWeight: 600,
                                lineHeight: 1.5,
                              }}
                            >
                              {row.discount}
                            </Typography> */}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"flex-end"}
                            alignContent={"flex-end"}
                          >
                            <Typography
                              sx={{
                                fontSize: "12.5px",
                                color: greyColor51,
                                fontWeight: 600,
                                lineHeight: 1.5,
                              }}
                            >
                              ID# {row.orderId}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: bgColor15,
                                fontWeight: 600,
                                lineHeight: 1.5,
                              }}
                            >
                              {moment(row.orderDate).format("DD MMM, hh:mm A")}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: getStatusColor(row.status),
                                fontWeight: 600,
                                lineHeight: 1.5,
                                textAlign: "flex-end",
                              }}
                            >
                              {getStatusText(row.status)}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
              </Slider>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default RecentOrdersInfoCard;

const styles = {
  card: {
    // display:{xs:"none",md:"block"},
    height: { xs: "auto", md: "100%" },
    backgroundColor: whiteColor,
    border: {md:logoImgborder1,xs:"none"},
    borderRadius: { xs: "10px", md: "13px" },
    // boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 10px 5px 10px", md: "10px 15px " },
  },

  tableCell: {
    height: "28px !important",
    padding: "0px 5px 0px 15px  !important",
    whiteSpace: "nowrap",
    textTransform: "uppercase !important",
  },

  tableBodyCell: (color) => ({
    fontSize: 12,
    fontWeight: 500,
    color: color || greyColor15,
    padding: "5px 5px 5px 15px  !important",
    whiteSpace: "nowrap",
  }),

  avatar: (color) => ({
    width: 35,
    height: 35,
    fontSize: "12.5px",
    fontWeight: 600,
    color: color || greyColor16,
    // color: whiteColor,
    // backgroundColor: color || BGColor02,
    backgroundColor: whiteColor,
    border: `1px solid ${color}`,
  }),
};
