import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  blackShade05,
  borderTextbox,
  greyColor40,
  orangeShade11,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { allowOnlyEnglish } from "../../utils/helper";

function HorizontalInputWithLabel({
  label,
  placeholder,
  name,
  value,
  startIcon,
  icon,
  maxLength,
  inputType,
  autoFocus,
  inputRef,
  onKeyUp,
  disabled,
  required,
  hintText,
  onValueChange,
  onChange,
  error,
}) {
  const [inputValue, setInputValue] = useState(value);

  const handleBlur = () => {
    onValueChange(inputValue);
  };

  return (
    <Grid
      container
      columnSpacing={2}
      rowSpacing={1}
      display={"flex"}
      alignItems={"start"}
    >
      <Grid item md={6} xs={12}>
        <Typography
          variant="h3"
          fontSize={{ xs: 12, md: 13 }}
          color={greyColor40}
          fontWeight={400}
          mb={"0px"}
          sx={{
            display: "inline",
          }}
        >
          {label}{" "}
        </Typography>
        {required ? (
          <Typography variant="body1" sx={{ display: "inline", color: "#f00" }}>
            *
          </Typography>
        ) : null}
        {hintText && (
          <Typography
            sx={{ display: "block" }}
            fontSize={11.5}
            color={orangeShade11}
            fontWeight={400}
            mt={-0.2}
            lineHeight={1.5}
          >
            {hintText}
          </Typography>
        )}
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          variant="standard"
          required={true}
          fullWidth
          name={name}
          disabled={disabled}
          value={inputValue}
          onBlur={handleBlur}
          autoFocus={autoFocus}
          onChange={({ target }) => setInputValue(target.value)}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          inputRef={inputRef}
          sx={{
            fontSize: 13,
            fontWeight: 500,
            height: { xs: "37px", md: "33px" },
            color: blackShade05,
            pt:0.3,
            opacity: disabled ? 0.5 : 1,
            border: borderTextbox,
            borderRadius: "5px",
            backgroundColor: whiteColor,
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  color: `${blackShade05} !important`,
                  fontSize: 13.5,
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: 13, fontWeight: 500, color: blackShade05 }}
                >
                  {startIcon}
                </Typography>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                {icon}
              </InputAdornment>
            ),
            style: {
              fontSize: 13,
              fontWeight: 500,
            },
            disableUnderline: true,
          }}
          inputProps={{ maxLength: maxLength }}
          type={inputType}
        />

        {error ? (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={12}
            sx={{ mt: "4px !important", textAlign: "start !important" }}
            style={{ color: "red" }}
          >
            {/* Please enter, its a mandatory value */}
            {error}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default HorizontalInputWithLabel;
