import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import StyledSwitch from "../../../components/Switch/StyledSwitch";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import {
  blackShade19,
  border25,
  greyColor6,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import CatalogActionBtns from "../../../components/Buttons/CatalogActionBtns";

function CategoryForm({
  data,
  setData,
  handleOndcCategoryChange,
  handleOndcSubCategoryChange,
  onNextClick,
  handleCancel,
  pageError,
  productDetails
}) {
  return (
    <Box>
      <Box sx={styles.card}>
        <Typography sx={styles.title}>ONDC</Typography>

        <Grid container spacing={2}>
          <Grid item md={3}>
            <Box>
              <Typography
                fontSize={{ xs: 12, md: 13 }}
                color={greyColor6}
                fontWeight={400}
                mb="0px !important"
                display="inline"
              >
                Enabled{" "}
                <Typography
                  variant="body1"
                  sx={{ display: "inline", color: "#f00" }}
                >
                  *
                </Typography>
              </Typography>
              <StyledSwitch
                label={data?.ondcEnabled === "yes" ? "Yes" : "No"}
                isChecked={data?.ondcEnabled === "yes"}
                onChange={() => {
                  setData((prevState) => ({
                    ...prevState,
                    ondcEnabled:
                      prevState?.ondcEnabled === "yes" ? "no" : "yes",
                  })); 
                }}
              />
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            <SelectWithLabel
              label="Category"
              required
              value={data?.selectedOndcCat?.name}
              disabled={data?.ondcEnabled !== "yes"}
              menuList={data?.ondcCategory || []}
              valueSelector="name"
              onValueChange={handleOndcCategoryChange}
              returnFullItem
              errorMsg={data?.errors?.selectedOndcCat}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <SelectWithLabel
              label="Sub Category"
              required
              valueSelector="productSubCategory1"
              value={data?.selectedOndcSubCat?.productSubCategory1}
              menuList={data?.ondcSubCategory || []}
              disabled={data?.ondcEnabled !== "yes"}
              onValueChange={handleOndcSubCategoryChange}
              returnFullItem
              errorMsg={data?.errors?.selectedOndcSubCat}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <CatalogActionBtns
          hideSaveBtn
          // hideNextBtn={data?.ondcEnabled !== "yes"}
          hideNextBtn={productDetails?.ondcEnabled === "no" && data?.ondcEnabled !== "yes"}
          nextBtnText={
            productDetails?.ondcEnabled === "yes" && data?.ondcEnabled === "no"
              ? "SUBMIT"
              : "NEXT"
          }
          onNextClick={() => onNextClick("ondcDetails")}
          onCancelClick={handleCancel}
          // Preview Btn
        />
        {pageError && (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={12}
            fontWeight={500}
            textAlign={"right"}
            color="red"
            mt="6px"
          >
            Please fix the errors above to proceed
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default CategoryForm;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    border: border25,
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: "15px 15px",
    mb: 2,
    mt: 1,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    mb: "10px",
    color: blackShade19,
  },
};
