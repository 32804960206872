import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardMenu from "./Dashboard/DashboardMenu";
import DashboardHeader from "./Dashboard/DashboardHeader";
import { BGColor02, blackShade05 } from "../configs/styles/muiThemes";
import BusinessInfoCard from "../components/Cards/BusinessInfoCard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import EmptyCard from "../components/Cards/EmptyCard";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import { stickyHeader } from "../utils/styles";

function Subscriptions() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
    }
  }, []);

  return (
    <Box sx={styles.container}>
      <DashboardLayout activeMenu="subscriptions">
        <Box pt={{ xs: 1.5, md: 0 }}>
          <Typography
            variant="h5"
            color={blackShade05}
            fontSize={{ xs: "14.5px", md: "17px" }}
            fontWeight="600"
            sx={stickyHeader}
          >
            Subscription
          </Typography>

          <EmptyCard
            icon={
              <Box
                component="img"
                src="https://storage.googleapis.com/bodefaults/businessdashboardweb/coimg-soon.svg"
                alt="coming soon"
                width="50px"
                height="auto"
                display="block"
              />
            }
            msg="Subscription details - Coming Soon"
            submsg="To view subscription details and update delivery - "
            link="https://business-dashboard2.costbo.com/"
          />
        </Box>

        {/* <Box paddingTop={{xs:1,md:"none"}}>
          <Typography
            variant="h4"
            color={blackShade05}
            fontSize={{md:"17px",xs:"15px"}}
            fontWeight="600"
            mt={{ md: 1, xs: 1.5 }}
          >
            Subscription
          </Typography>
          <EmptyCard
            icon={
              <Box
                component="img"
                src="https://storage.googleapis.com/bodefaults/businessdashboardweb/coimg-soon.svg"
                alt="coming soon"
                width="50px"
                height="auto"
                display="block"
              />
            }
            msg="Subscription details - Coming Soon"
            submsg="To view subscription details and update delivery - "
            link="https://business-dashboard2.costbo.com/"
          />
        </Box> */}
      </DashboardLayout>
    </Box>
  );
}

export default Subscriptions;

const styles = {
  container: {
    minHeight: { xs: "calc(100vh - 91px)", md: "100vh" },
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "25px", md: "25px" },
    p: { xs: "0px", md: "15px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },
};
