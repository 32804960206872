import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blackShade19,
  blueColor02,
  blueColor04,
  greyColor6,
  logoImgborder1,
  whiteColor,
} from "../../configs/styles/muiThemes";

function AffiliateMarketingInfocard({
  affiliatePartners,
  noOfShares,
  totalOrders,
  totalSales,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Box sx={styles.infoBox}>
      <Grid container spacing={1}>
        <Grid item xs={6} md={3} ml={-1}>
          <StatsInfoCard
            label="AFFILIATE PARTNERS"
            value={affiliatePartners || 0}
            textcolor={matches ? blackShade05 : blackShade19}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <StatsInfoCard
            label="NO OF SHARES"
            value={noOfShares || 0}
            borderbefore="1px solid #e2e4ea"
            textcolor={matches ? blackShade05 : blackShade19}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <StatsInfoCard
            label="TOTAL ORDERS"
            value={totalOrders || 0}
            borderbefore="1px solid #e2e4ea"
            textcolor={matches ? blackShade05 : blackShade19}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <StatsInfoCard
            label="TOTAL SALES"
            value={
              totalSales
                ? `₹ ${
                    Number(totalSales)
                      ?.toFixed(2)
                      .replaceAll(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",") ||
                    0
                  }`
                : 0
            }
            borderbefore="1px solid #e2e4ea"
            textcolor={matches ? blackShade05 : blackShade19}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AffiliateMarketingInfocard;

const StatsInfoCard = ({ label, value, borderbefore, textcolor }) => {
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        // alignItems="center"
        borderLeft={{ md: borderbefore, xs: "none" }}
        padding={{ md: "5px 30px", xs: "0px 5px" }}
      >
        <Typography
          fontSize={12}
          fontWeight={500}
          color={greyColor6}
          textTransform="uppercase"
        >
          {label}
        </Typography>

        <Typography fontSize={{xs:14, md:16}} fontWeight={600} color={textcolor}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  infoBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: whiteColor,
    baxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    padding: "10px",
    border: logoImgborder1,
    borderRadius: "10px",
    marginTop: { xs: "10px", md: "22px" },
  },

  statCard: {},
};
