import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import InfoCardHeader from "./InfoCardHeader";
import {
  blackColor,
  blackShade05,
  blackShade21,
  blueColor02,
  blueColor04,
  greenColor2,
  greyColor6,
  logoImgborder1,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import moment from "moment";
import DashboardEmptyCard from "./DashboardEmptyCard";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import LabelValueText from "./LabelValueText";
import { addThousandsSeparator } from "../../../utils/helper";
import CouponsEmptyCard from "../EmptyCards/CouponsEmptyCard";
import useDynamicColor from "../../../hooks/useDynamicColor";

function AvaiableDiscountsInfoCard({
  discountData,
  onClick,
  couponSummary,
  couponCount,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mainColor = useDynamicColor();

  return (
    <Box sx={styles.card}>
      <InfoCardHeader
        title="Discount Coupons"
        onManageClick={onClick}
        buttonText="Manage"
        // hideBtn={discountData?.details?.length === 0}
      />

      {!couponSummary?.details?.totalCoupons ||
      couponSummary?.details?.totalCoupons === 0 ? (
        <CouponsEmptyCard onClick={onClick} />
      ) : (
        <>
          <Typography
            color={blackShade21}
            fontSize={{ xs: 12, md: 13 }}
            fontWeight={500}
            lineHeight={1.5}
            sx={{ whiteSpace: "pre-line", mt: { xs: 0.4, md: 1 } }}
          >
            {`Increase your sales by 5X, with `}
            <Typography
              fontSize={{ xs: 12, md: 13 }}
              fontWeight={500}
              sx={{ color: mainColor, display: "inline" }}
            >
              Limited Time Offers
            </Typography>
          </Typography>

          <Box
            display="flex"
            alignItems="center"
            borderBottom="1px dashed #e2e4ea"
            pb={1}
          >
            <LabelValueText
              label="TOTAL COUPONS"
              value={couponSummary?.details?.totalCoupons || 0}
              valueColor={matches ? blueColor04 : blackShade05}
              borderStyle="dashed"
              valueSize={14}
            />

            <LabelValueText
              label="ACTIVE COUPONS"
              value={couponSummary?.details?.totalActiveCoupons}
              hideBorder
              valueSize={14}
            />
          </Box>

          <Box display="flex" alignItems="center" pb={1}>
            <LabelValueText
              label="TOTAL ORDERS PLACED"
              value={couponCount?.details?.totalCouponOrders}
              valueColor={matches ? blueColor04 : blackShade05}
              borderStyle="dashed"
              styles={{
                margin: { xs: "18px 10px 0 0", md: "10px 20px 0 0 !important" },
              }}
              valueSize={14}
            />
            <LabelValueText
              label="TOTAL ORDER VALUE"
              value={`₹ ${addThousandsSeparator(
                couponCount?.details?.totalCouponOrderValue
              )}`}
              hideBorder
              styles={{
                margin: { xs: "18px 10px 0 0", md: "10px 20px 0 0 !important" },
              }}
              valueSize={14}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default AvaiableDiscountsInfoCard;

const DiscountInfoTile = ({ discount, coupon, expiryDate }) => {
  return (
    <Box sx={styles.discountInfoCard}>
      <Box>
        <Typography
          variant="h5"
          fontSize={{ xs: 13, md: 15 }}
          color={blackColor}
          fontWeight={700}
          lineHeight="24px"
          mb="2px"
        >
          {discount}% OFF
        </Typography>

        <Typography
          variant="body2"
          fontSize={{ xs: 11, md: 11.5 }}
          color={greyColor6}
          fontWeight={500}
          lineHeight="17px"
        >
          Exp : {moment(expiryDate).format("MMM DD YYYY")}
        </Typography>
      </Box>

      <Box>
        <Typography
          variant="body2"
          fontSize={{ xs: 11, md: 11.5 }}
          color={greyColor6}
          fontWeight={500}
          lineHeight="17px"
          mb="2px"
          textAlign="right"
        >
          Coupon code:
        </Typography>

        <Typography
          variant="h5"
          fontSize={{ xs: 13, md: 13 }}
          color={greenColor2}
          fontWeight={600}
          lineHeight="24px"
          textAlign="right"
        >
          {coupon}
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  card: {
    width: "100%",
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "13px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 15px", md: "10px 15px" },
  },

  discountInfoCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #e8eaed",
    borderLeft: `3px solid ${greenColor2}`,
    p: "5px 12px",
    mb: "10px",
    borderRadius: "5px",
    boxShadow: "0 1px 2px rgb(0 0 0 / 5%)",
  },
};
