import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackColor,
  blackShade22,
  blueColor04,
  greyColor24,
  greyColor7,
  whiteColor,
} from "../../configs/styles/muiThemes";
import DelegateInfoCard from "../../components/Cards/DelegateInfoCard";
import PendingAdminCard from "./Cards/PendingAdminCard";
import { MANAGER_LIST } from "../../utils/dummyData";
import OwnerSwitchCard from "./Managers/OwnerSwitchCard";
import ModalCard from "../../components/ModalCard";
import AddNewOwner from "./PopupModals/AddNewOwner";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { insertSpaceAtPosition } from "../../utils/helper";
import {
  getManagerDetails,
  verifyBusinessRole,
  verifyManagerRole,
} from "../../reducers/ecommerceControl/EcommerceControlSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteBusinessDelegates,
  sendBusinessWhatsappNotificationApi,
  updateBusinessRoleApi,
} from "../../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";
import AddNewManager from "./PopupModals/AddNewManager";
import ActionButtons from "../../components/Buttons/ActionButtons";
import { prepareBusinessWhatsappMessage } from "../../utils/postDataHelpers/businessWhatsappMsgHelper";
import AddManagerCard from "./Cards/AddManagerCard";
import { getMasterBranchInfo } from "../../reducers/multiBranchCatalog/multiBranchCatalogSlice";

function AddManagerDelegates({ goBack, gotoNext, handleCancel }) {
  const { managersInfo, managersInfoLoader, businessRole, managerRole } =
    useSelector((state) => state.eCommerceControl);

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  const { masterBranchInfo, masterBranchInfoLoader } = useSelector(
    (state) => state.multiBranchCatalog
  );

  const [openAddOwnerPopup, setOpenAddOwnerPopup] = useState(false);
  const [openAddManagerPopup, setOpenAddManagerPopup] = useState(false);

  const [deletingPhoneNo, setDeletingPhoneNo] = useState("");
  const [deletingActivePhoneNo, setDeletingActivePhoneNo] = useState("");

  const dispatch = useDispatch();

  const userProfileId = sessionStorage.getItem("profileId");
  const businessId = sessionStorage.getItem("selectedBusinessId");

  const onSuccess = () => {
    dispatch(verifyBusinessRole({ businessId, params: { role: "OWNER" } }));
    dispatch(getManagerDetails(businessId));
    setOpenAddOwnerPopup(false);
  };

  const onSuccessManager = () => {
    dispatch(verifyManagerRole({ businessId, params: { role: "ADMIN" } }));
    dispatch(getManagerDetails(businessId));
    setOpenAddManagerPopup(false);
  };

  const onDeleteAdminSuccess = () => {
    dispatch(verifyBusinessRole({ businessId, params: { role: "OWNER" } }));
  };

  // const onDeleteAdmin = async (data) => {
  //   const payload = {
  //     businessId,
  //     params: { role: "ADMIN", phoneNo: data?.phoneNo, newStatus: "INACTIVE" },
  //   };

  //   const inactiveUser = await updateBusinessRoleApi(payload);
  // };

  const onNotifyClick = (pendingUser) => {
    const whatsAppNumber = pendingUser?.phoneNo; // Replace this with the WhatsApp number you want to send the message to
    const text = encodeURIComponent(
      `Hey ${pendingUser?.name}, Congratulations! You've been granted Super Admin privileges for ${activeBusinessInfo?.name}. This will enable you to access the Seller dashboard for managing ONDC/E-Commerce orders (powered by costBo). Kindly proceed to create your user account using the link provided below`
    ); // Encode the text

    // Open the WhatsApp message window
    window.open(
      `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${text}`,
      "_blank"
    );
  };

  const onDeleteManager = async (pendingUser) => {
    // setDeleteLoader(true);

    console.log("pendingUser___:", pendingUser);

    setDeletingPhoneNo(pendingUser?.phoneNo);
    const payload = {
      businessId,
      params: {
        role: "ADMIN",
        phoneNo: pendingUser?.phoneNo,
        newStatus: "INACTIVE",
        type: 2,
      },
    };

    const inactiveUser = await updateBusinessRoleApi(payload);

    if (inactiveUser) {
      // Sending Removed Whatsapp msg: removed as delegate
      const removedNotificationData = prepareBusinessWhatsappMessage({
        businessId,
        code: "B016",
        ownerName: pendingUser?.name || "",
        brandName: activeBusinessInfo?.name || "",
        phoneNumber: pendingUser?.phoneNo,
      });

      await sendBusinessWhatsappNotificationApi(removedNotificationData);

      dispatch(verifyManagerRole({ businessId, params: { role: "ADMIN" } }));
      setDeletingPhoneNo("");
    }

    // setDeleteLoader(false);
    // onDeleteSuccess();
  };

  const deleteActiveManager = async (manager) => {
    setDeletingActivePhoneNo(manager?.phone);
    const deleteDelegate = {
      businessId: businessId,
      type: "REMOVE",
      delegates: [
        {
          profileId: manager?.profileId || "",
          name: manager?.name || "",
          phone: manager?.phone,
          email: manager?.email || "",
          country: "India",
          status: "active",
          role: "Admin",
          picURL: "",
          type: 2,
          privileges: [],
        },
      ],
    };
    console.log("managermanager", manager, deleteDelegate);

    // setIsLoading(false);

    const deletedDelegate = await deleteBusinessDelegates(deleteDelegate);

    if (deletedDelegate) {
      // Sending Removed Whatsapp msg: removed as delegate
      const removedNotificationData = prepareBusinessWhatsappMessage({
        businessId,
        code: "B016",
        ownerName: manager?.name || "",
        brandName: activeBusinessInfo?.name || "",
        phoneNumber: manager?.phone,
      });

      await sendBusinessWhatsappNotificationApi(removedNotificationData);

      dispatch(getManagerDetails(businessId));
      setDeletingActivePhoneNo(manager?.phone);
    }
  };

  useEffect(() => {
    if (!masterBranchInfo) {
      const businessId = sessionStorage.getItem("selectedBusinessId");
      dispatch(getMasterBranchInfo(businessId));
    }

    return () => {};
  }, []);

  return (
    <Box>
      <Typography
        component="h6"
        fontSize={{ xs: 14, md: 15 }}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        mb={{ md: 2, xs: 0 }}
        mt={{ md: 0, xs: 1 }}
      >
        Super Admin
      </Typography>
      {managersInfo?.ownerInfo?.profileId === userProfileId ? (
        <OwnerSwitchCard
          onAddAdmin={() => {
            setOpenAddOwnerPopup(true);
          }}
          owner={managersInfo?.ownerInfo}
          pending={businessRole?.length > 0}
          pendingUser={businessRole?.[0]}
          onDeleteSuccess={onDeleteAdminSuccess}
        />
      ) : (
        <Grid container spacing={2}>
          <Grid item md={3} xs={12} mt={3}>
            <DelegateInfoCard
              profileImg={managersInfo?.ownerInfo?.photoUrl}
              name={managersInfo?.ownerInfo?.name}
              phoneNumber={insertSpaceAtPosition(
                managersInfo?.ownerInfo?.phone,
                3
              )}
            />
          </Grid>
        </Grid>
      )}
      {/* <OwnerSwitchCard pending /> */}
      <Box
        display={"flex"}
        flexDirection={{ md: "row", xs: "column" }}
        alignItems={{ md: "center", xs: "flex-start" }}
        justifyContent="space-between"
        gap={{ md: 2, xs: 1 }}
        mb={2}
      >
        <Box>
          <Typography
            variant="h4"
            fontSize={15}
            fontWeight={600}
            color={greyColor24}
            lineHeight="28px"
            mt={2}
            mb="4px"
          >
            Managers
          </Typography>

          <Typography
            fontSize={12.5}
            fontWeight={500}
            color={greyColor7}
            lineHeight={1.5}
            mt="8px"
            mb="6px"
          >
            The following users will be able Manage inventory, Approve orders,
            Monitor transactions, Update E-Commerce setting & other business
            functionalities
          </Typography>
          <Typography
            fontSize={12.5}
            fontWeight={500}
            color={greyColor7}
            lineHeight={1.5}
            mt="8px"
            mb="6px"
          >
            They will be able to mange customer data as well
          </Typography>
        </Box>
        {/* {managersInfo?.ownerInfo?.profileId === userProfileId && (
          <Stack alignItems="flex-end">
            <Button
              sx={styles.btn}
              flex={1}
              onClick={() => setOpenAddManagerPopup(true)}
              disabled={
                managerRole?.length + managersInfo?.managers?.length >= 5
              }
            >
              Add Managers
            </Button>

            {managerRole?.length + managersInfo?.managers?.length >= 5 && (
              <Typography fontSize={11} fontWeight={500} mt={0.5}>
                You can add maximum 5 delegates
              </Typography>
            )}
          </Stack>
        )} */}
      </Box>
      <Grid container spacing={2} mb={3}>
        <Grid item xs={6} md={3} key="add-manager-btn" mt={-2} mb={0.5}>
          <AddManagerCard
            disabled={managerRole?.length + managersInfo?.managers?.length >= 5}
            onClick={() => setOpenAddManagerPopup(true)}
          />
        </Grid>

        {managersInfo?.managers?.length > 0 ? (
          managersInfo?.managers?.map((manager, index) => (
            <Grid item xs={6} md={3} key={manager?.profileId}>
              <DelegateInfoCard
                masterBranchInfo={masterBranchInfo}
                currentManager={manager}
                profileImg={manager.photoUrl}
                name={manager?.name}
                phoneNumber={insertSpaceAtPosition(manager?.phone, 3)}
                edit={managersInfo?.ownerInfo?.profileId === userProfileId}
                handleRemove={() => deleteActiveManager(manager)}
                deleteLoader={manager.phone === deletingActivePhoneNo}
                // statusColor={greenColor2}
              />
            </Grid>
          ))
        ) : (
          <Box sx={styles.emptyCard}>
            <Box
              display="flex"
              flexDirection="column"
              gap="10px"
              alignItems="center"
              my={4}
            >
              <Box sx={styles.iconContainer}>
                <AccountBalanceOutlinedIcon sx={{ fontSize: "24px" }} />
              </Box>

              <Typography variant="body2" sx={styles.noDataText}>
                No Managers are assigned
              </Typography>
            </Box>
          </Box>
        )}
      </Grid>
      {managerRole?.length > 0 && (
        <>
          <Typography
            variant="h4"
            fontSize={15}
            fontWeight={600}
            color={greyColor24}
            lineHeight="28px"
            display={"inline"}
            mb="4px"
          >
            Managers/Delegates
            <Typography
              variant="h4"
              fontSize={13}
              fontWeight={600}
              color={greyColor24}
              lineHeight="28px"
              display={"inline"}
            >
              (Registration Pending)
            </Typography>
          </Typography>

          <Typography
            fontSize={12.5}
            fontWeight={500}
            color={greyColor7}
            lineHeight={1.5}
            mt="8px"
            mb="12px"
          >
            The following users will be added as Managers/Delegates to the
            business once they register in CostBo
          </Typography>
          <Grid container spacing={2}>
            {managerRole?.map((manager, index) => (
              <Grid item xs={12} md={4} key={manager?.phoneNo}>
                <PendingAdminCard
                  name={manager?.name}
                  phoneNumber={insertSpaceAtPosition(manager?.phoneNo, 3)}
                  handleDelete={() => onDeleteManager(manager)}
                  handleNotify={() => onNotifyClick(manager)}
                  deleteLoader={manager.phoneNo === deletingPhoneNo}
                  createdOn={manager?.createdOn}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      <Box mt="20px">
        <ActionButtons
          onBackClick={goBack}
          onSaveClick={() => {}}
          onNextClick={gotoNext}
          onCancelClick={handleCancel}
          hideSaveBtn
          hideNextTabBtn
          backBtnText="Previous"
        />
      </Box>
      <ModalCard
        open={openAddOwnerPopup}
        handleClose={() => {
          setOpenAddOwnerPopup(false);
        }}
        width="28%"
      >
        <AddNewOwner
          ownerInfo={managersInfo?.ownerInfo}
          delegates={managersInfo?.managers}
          managersNotRegistered={managerRole}
          onSuccess={onSuccess}
          onClose={() => {
            setOpenAddOwnerPopup(false);
          }}
        />
      </ModalCard>
      <ModalCard
        open={openAddManagerPopup}
        handleClose={() => {
          setOpenAddManagerPopup(false);
        }}
        width="28%"
      >
        <AddNewManager
          ownerInfo={managersInfo?.ownerInfo}
          delegates={managersInfo?.managers}
          managersNotRegistered={managerRole}
          adminNotRegistered={businessRole}
          onSuccess={onSuccessManager}
          onClose={() => {
            setOpenAddManagerPopup(false);
          }}
        />
      </ModalCard>
    </Box>
  );
}

export default AddManagerDelegates;

const styles = {
  btn: {
    whiteSpace: "nowrap",
    backgroundColor: blackShade22,
    padding: "3px 14px",
    fontSize: "12.5px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + blackShade22,
    "&:hover": {
      color: blackShade22,
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
      color: "rgba(0,0,0,0.4)",
      fontWeight: 500,
      backgroundColor: "rgba(0,0,0,0.14)",
    },
  },
  emptyCard: {
    p: { xs: "10px 15px", md: "10px 20px 20px" },
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: { xs: "8px", md: "13px" },
    mt: { xs: "5px", md: "15px" },
    mx: "20px",
    width: "100%",
  },
  iconContainer: {
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "25px",
    backgroundColor: "#e5e8ee",
  },

  noDataText: {
    fontSize: 14,
    fontWeight: 500,
    color: greyColor24,
    lineHeight: "24px",
  },
};
