import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";

import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import RoomPreferencesOutlinedIcon from "@mui/icons-material/RoomPreferencesOutlined";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { blueColor04 } from "../configs/styles/muiThemes";

import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";

export const STORE_STEP_LIST = [
  {
    label: "Store Detail",
    value: "store-detail",
    icon: <StorefrontIcon sx={{ fontSize: "20px" }} />,
    tab: 0,
  },
  {
    label: "Pickup Address",
    value: "pickup-address",
    icon: <PlaceOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 1,
  },
  {
    label: "Business Timings",
    value: "business-timings",
    icon: <PendingActionsOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 2,
  },
  {
    label: "Delivery Methods",
    value: "delivery-methods",
    icon: <LocalShippingOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 3,
  },
  {
    label: "Discount Details",
    value: "discount-details",
    icon: <DiscountOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 4,
  },
  {
    label: "Bank Detail",
    value: "bank-detail",
    icon: <AccountBalanceOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 5,
  },
  {
    label: "Other Detail",
    value: "other-detail",
    icon: <StorefrontIcon sx={{ fontSize: "20px" }} />,
    tab: 6,
  },
];

export const CATALOG_STEP_LIST = [
  {
    label: "Product Detail",
    value: "product-detail",
    icon: <Inventory2OutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 0,
  },
  {
    label: "Tax & GST Info",
    value: "price-tax-info",
    icon: <RequestQuoteOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 1,
  },
  {
    label: "Weight & Package Info",
    value: "measurement-delivery-method",
    icon: <ViewInArOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 2,
  },
  {
    label: "Specification",
    value: "category-specs",
    icon: <InventoryOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 3,
  },
  {
    label: "ONDC Parameters",
    value: "ondc",
    icon: <HubOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 4,
  },
  // {
  //   label: "Subscription Info",
  //   value: "subscription-info",
  //   icon: <PeopleOutlineOutlinedIcon sx={{ fontSize: "20px" }} />,
  //   tab: 5,
  // },
];

export const CATALOG_ADD_PRODUCT_STEP_LIST = [
  {
    label: "Product Detail",
    value: "product-detail",
    icon: <Inventory2OutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 0,
  },
  {
    label: "Pricing & Tax Info",
    value: "price-tax-info",
    icon: <RequestQuoteOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 1,
  },
  {
    label: "Weight & Package Info",
    value: "measurement-delivery-method",
    icon: <ViewInArOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 2,
  },
  {
    label: "Availablity",
    value: "product-availablity",
    icon: <AssignmentOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 3,
    onlyAddProduct: true,
  },
  {
    label: "Specification",
    value: "category-specs",
    icon: <InventoryOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 4,
  },
  {
    label: "ONDC Parameters",
    value: "ondc",
    icon: <HubOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 5,
  },

  // {
  //   label: "Subscription Info",
  //   value: "subscription-info",
  //   icon: <PeopleOutlineOutlinedIcon sx={{ fontSize: "20px" }} />,
  //   tab: 5,
  // },
];

export const ECOMMERCE_STEP_LIST = [
  // {
  //   label: "Pickup Address",
  //   value: "pickup-address",
  //   icon: <PlaceOutlinedIcon sx={{ fontSize: "20px" }} />,
  //   tab: 0,
  // },
  {
    label: "Business Timings",
    value: "business-timings",
    icon: (
      <InventoryOutlinedIcon sx={{ fontSize: { md: "20px", xs: "18px" } }} />
    ),
    tab: 1,
  },
  {
    label: "Delivery Methods",
    value: "delivery-methods",
    icon: (
      <LocalShippingOutlinedIcon
        sx={{ fontSize: { md: "20px", xs: "18px" } }}
      />
    ),
    tab: 2,
  },
  {
    label: "Discount Details",
    value: "discount-details",
    icon: (
      <DiscountOutlinedIcon sx={{ fontSize: { md: "20px", xs: "18px" } }} />
    ),
    tab: 3,
  },
  {
    label: "Returns & Other Info",
    value: "other-detail",
    icon: <StorefrontIcon sx={{ fontSize: { md: "20px", xs: "18px" } }} />,
    tab: 4,
  },
  {
    label: "Admin/Managers",
    value: "manager-delegates",
    icon: <ManageAccountsIcon sx={{ fontSize: { md: "20px", xs: "18px" } }} />,
    tab: 5,
  },

  {
    label: "Bank Info",
    value: "bank-detail",
    icon: <AccountBalanceOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 6,
  },
  // {
  //   label: "ONDC Controls",
  //   value: "ondc",
  //   icon: <HubOutlinedIcon sx={{ fontSize: "20px" }} />,
  //   tab: 6,
  // },
];

export const INVENTORY_STEP_LIST = [
  {
    label: "OUT OF STOCK",
    value: "out-of-stock",
    tab: 0,
  },
  {
    label: "LOW STOCK",
    value: "low-stock",
    tab: 1,
  },
  {
    label: "PRICE UPDATE",
    value: "price-update",
    tab: 2,
  },
  {
    label: "ALL STOCK",
    value: "all-stock",
    tab: 3,
  },
];

export const ONDC_STEP_LIST = [
  {
    label: "Category & Shipments",
    value: "category",
    icon: <CategoryOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 0,
  },
  {
    label: "Pricing Controls",
    value: "price-controls",
    icon: <CurrencyRupeeOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 1,
  },
  {
    label: "Approval Settings",
    value: "approval-settings",
    icon: <RoomPreferencesOutlinedIcon sx={{ fontSize: "20px" }} />,
    tab: 2,
  },
  {
    label: "Timings & Holiday",
    value: "timing-holiday",
    icon: <PendingActionsIcon sx={{ fontSize: "20px" }} />,
    tab: 3,
  },
  {
    label: "Buyer App",
    value: "buyer-app",
    icon: <PhoneIphoneIcon sx={{ fontSize: "20px" }} />,
    tab: 4,
  },
];
