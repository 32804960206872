import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  BGColor02,
  blackShade04,
  blackShade159,
  blackShade19,
  blackShade22,
  blueColor03,
  blueColor04,
  colorGreen,
  colorRed,
  colorYellow,
  greyColor39,
  greyColor5,
  greyColor6,
  heroSectionText,
  logoImgborder,
  mobilePrimary,
  whiteColor,
} from "../../configs/styles/muiThemes";
import {
  geProfilePhoto,
  getProfileId,
  getUserName,
  removeSessionInfo,
} from "../../utils/validationUtils";
import ProfileCharAvatar from "../../components/ProfileCharAvatar";
import { useNavigate } from "react-router-dom";
import MobileSideMenu from "./MobileSideMenu";
import { useDispatch, useSelector } from "react-redux";
import { getActiveBusinessInfo } from "../../reducers/businessPreview/businessPreviewSlice";
import LazyLoad from "react-lazyload";
import HowToLinkCard from "../../components/Cards/HowToLinkCard";
import AddIcon from "@mui/icons-material/Add";
import BusinessMobileSideMenu from "../BusinessMobileSideMenu";

function DashboardHeader({
  showLogo,
  pending,
  disableMenu,
  activeMenu,
  activeSubMenu,
  disableMobileMenu,
  handleOpenVideoGuide,
  handleAddBusiness,
  isAddBusinessDisabled,
  headerTitle,
  hideAction,
  isUserFromApp,
  hideBusinessData,
  hideMobileMenu,
}) {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isProfileImgAvailabel = geProfilePhoto();
  const username = getUserName();

  const onLogout = () => {
    removeSessionInfo();
    // navigate("/");
    window.open("/", "_self");
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
    }
  }, []);

  return (
    <Box
      backgroundColor={{ xs: greyColor39, md: BGColor02 }}
      // pb={{ xs: 3.5, md: 0 }}
      pb={{ xs: 2.1, md: 0 }}
    >
      {!matches ? (
        <HeaderMobile
          prifileImg={isProfileImgAvailabel ? geProfilePhoto() : ""}
          onMenuClick={() => {}}
          onLogout={onLogout}
          handleClose={handleClose}
          anchorEl={anchorEl}
          open={open}
          handleClick={handleClick}
          openMobileMenu={openMobileMenu}
          setOpenMobileMenu={setOpenMobileMenu}
          onCloseClick={(data) => setOpenMobileMenu(data)}
          disableMenu={disableMenu}
          activeMenu={activeMenu}
          activeSubMenu={activeSubMenu}
          activeBusinessInfo={activeBusinessInfo}
          disableMobileMenu={disableMobileMenu}
          handleOpenVideoGuide={handleOpenVideoGuide}
          handleAddBusiness={handleAddBusiness}
          isAddBusinessDisabled={isAddBusinessDisabled}
          headerTitle={headerTitle}
          hideAction={hideAction}
          isUserFromApp={isUserFromApp}
          hideBusinessData={hideBusinessData}
          hideMobileMenu={hideMobileMenu}
        />
      ) : null}

      {matches && (
        <Box
          sx={{
            height: "45px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: blackShade22,
            // boxShadow: "0 5px 8px rgb(179 188 198 / 14%)",
            // p: { xs: "16px 16px 0 16px", md: "9px 29px 9px 10px" },
            px: 1,
            zIndex: 999,
          }}
        >
          <Box
            component="img"
            src="https://storage.googleapis.com/bodefaults/businessdashboardweb/costbo_logo_with_title.svg"
            sx={{
              width: "auto",
              height: { xs: "38px", md: "30px" },
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/");
            }}
          />

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              gap: "20px",
            }}
            mr={2}
          >
            {/* <NavIconButton
              count="34"
              color="cart"
              hideBadge
            /> */}

            <Box display="flex" alignItems="center" gap="6px">
              {/* <Typography variant="body2" sx={styles.menuText}>
                Download App
              </Typography> */}

              <Box display="flex" alignItems="center" gap="10px">
                <NavIconButton
                  icon="https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/play-store.png"
                  count="13"
                  color="chat"
                  hideBadge
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.costbo&pli=1",
                      "_blank"
                    );
                  }}
                />

                <NavIconButton
                  icon="https://storage.googleapis.com/bodefaults/businessdashboardweb/app_icons/app-store.png"
                  count="13"
                  color="chat"
                  hideBadge
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/app/id1468091785",
                      "_blank"
                    );
                  }}
                />
              </Box>
            </Box>

            {/* <NavIconButton
              count="13"
              color="chat"
            />

            <NavIconButton
              count="25"
              color="notification"
            /> */}

            {getProfileId() && (
              <ProfileCard
                prifileImg={isProfileImgAvailabel ? geProfilePhoto() : ""}
                name={username}
                onLogout={onLogout}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default DashboardHeader;

const HeaderMobile = ({
  prifileImg,
  onMenuClick,
  onLogout,
  handleClose,
  anchorEl,
  open,
  handleClick,
  setOpenMobileMenu,
  openMobileMenu,
  disableMenu,
  activeMenu,
  activeSubMenu,
  activeBusinessInfo,
  disableMobileMenu,
  handleOpenVideoGuide,
  handleAddBusiness,
  isAddBusinessDisabled,
  headerTitle,
  hideAction,
  isUserFromApp,
  hideBusinessData,
  hideMobileMenu
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const loadingRef = useRef(null);
  const logoImgRef = useRef(null);
  return (
    <Box>
      {/* {openMobileMenu ? (
        <>
          {/* <MobileSideMenu
            disableMenu={disableMenu}
            activeMenu={activeMenu}
            activeSubMenu={activeSubMenu}
            onCloseClick={() => {
              setOpenMobileMenu(false);
            }}
            hideBusinessData={hideBusinessData}
          /> */}
      {/* {}
        </>
      ) : null}  */}
      <Box sx={styles.headerContainer(activeMenu !== "howToVideoGuides")}>
        <Box display="flex" alignItems="center" gap="15px">
          {!disableMobileMenu && !hideMobileMenu ? (
            <Box
              onClick={() =>
                {matches ? navigate(
                    `/${sessionStorage.getItem(
                      "costboWEBURL"
                    )}${"/Dashboard"}`
                  ):
                  navigate(
                    `/${sessionStorage.getItem(
                      "costboWEBURL"
                    )}${"/mobileSideMenu"}`
                  );
              }
               
              }
            >
              <Box sx={styles.menuLine(20)} />
              <Box sx={styles.menuLine(14)} my={"5px"} />
              <Box sx={styles.menuLine(20)} />
            </Box>
          ) : null}

          <Box
            component="img"
            src="https://storage.googleapis.com/bodefaults/businessdashboardweb/logo.png"
            width="90px"
            height="auto"
            sx={{ objectFit: "contain", display: { xs: "none", md: "block" } }}
          />
        </Box>

        {!isUserFromApp ? (
          <Box
            display="flex"
            alignItems="center"
            gap="8px"
            mr={isUserFromApp ? 2 : 0}
          >
            <NavIconButton
              icon="https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/play-store.png"
              count="13"
              color="chat"
              hideBadge
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.costbo&pli=1",
                  "_blank"
                );
              }}
            />

            <NavIconButton
              icon="https://storage.googleapis.com/bodefaults/businessdashboardweb/app_icons/app-store.png"
              count="13"
              color="chat"
              hideBadge
              onClick={() => {
                window.open(
                  "https://apps.apple.com/app/id1468091785",
                  "_blank"
                );
              }}
            />

            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ ml: -1.5 }}
            >
              {prifileImg ? (
                <Box
                  component="img"
                  src={prifileImg}
                  width={{ xs: "38px", md: "44px" }}
                  height={{ xs: "38px", md: "44px" }}
                  sx={{ borderRadius: "50%" }}
                />
              ) : (
                <ProfileCharAvatar
                  username={getUserName()}
                  border
                  size="32px"
                  fontSize="13.5px"
                />
              )}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{ p: 0 }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem sx={{ py: 0, minHeight: "20px" }} onClick={onLogout}>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        ) : (
          <Box sx={{ width: "40px", height: "50px" }} />
        )}

        {activeMenu !== "store" && activeMenu !== "howToVideoGuides" && (
          <Box sx={styles.businessInfoCard}>
            <Box display="flex" alignItems="center" gap={1}>
              <Box ref={loadingRef}>
                <Skeleton
                  variant="rectangular"
                  width="40px"
                  height="40px"
                  sx={{ borderRadius: "6px" }}
                />
              </Box>
              <LazyLoad throttle={200}>
                <Box
                  ref={logoImgRef}
                  component="img"
                  src={activeBusinessInfo?.logoURL}
                  width="1px"
                  height="1px"
                  sx={{
                    objectFit: "cover",
                    borderRadius: { xs: "6px", md: "10px" },
                    border: logoImgborder,
                  }}
                  onLoad={() => {
                    loadingRef.current.style.display = "none";
                    logoImgRef.current.style.width = "40px";
                    logoImgRef.current.style.height = "40px";
                  }}
                  onClick={() => {
                    navigate(
                      `/${sessionStorage.getItem(
                        "costboWEBURL"
                      )}${"/dashboard"}`
                    );
                  }}
                />
              </LazyLoad>

              <Typography
                variant="h6"
                fontSize={13.5}
                fontWeight={600}
                lineHeight="22px"
                color={blackShade19}
              >
                {activeBusinessInfo?.name}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Box
                component="img"
                alt="Business Change"
                src={
                  "https://storage.googleapis.com/bodefaults/businessdashboardweb/change_business.svg"
                }
                width="20px"
                // height="128px"
                onClick={() => {
                  navigate("/businessListing");
                }}
              />
              <Typography
                fontSize={12.5}
                fontWeight={500}
                lineHeight="15px"
                color={mobilePrimary}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                mt={0.5}
                onClick={() => {
                  navigate("/businessListing");
                }}
              >
                Change
              </Typography>
            </Box>
          </Box>
        )}

        {activeMenu === "store" && (
          <Box sx={{ height: "52px", ...styles.businessInfoCard, px: 2 }}>
            <Typography
              variant="h6"
              fontSize={15}
              fontWeight={600}
              lineHeight="22px"
              color={blackShade19}
            >
              {headerTitle || "Business Listing"}
            </Typography>

            {!hideAction && (
              <Stack flexDirection="row" gap={1.8}>
                <HowToLinkCard
                  link="Add New Business"
                  handleClick={() => {
                    handleOpenVideoGuide(true);
                  }}
                  txt="to"
                />

                <IconButton
                  sx={styles.addIconBtnSmall}
                  onClick={handleAddBusiness}
                  disabled={isAddBusinessDisabled}
                >
                  <AddIcon sx={{ fontSize: "18px" }} />
                </IconButton>
              </Stack>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const NavIconButton = ({ icon, count, color, hideBadge, onClick }) => {
  const BADGE_COLORS = {
    cart: colorYellow,
    chat: colorGreen,
    notification: colorRed,
  };

  return (
    <Box sx={styles.iconBtn} onClick={onClick}>
      <Box
        component="img"
        alt="CostBo"
        src={icon}
        sx={{
          width: { xs: "20px", md: "22px" },
          height: { xs: "20px", md: "22px" },
        }}
      />

      {!hideBadge && (
        <Box sx={styles.badge(BADGE_COLORS[color])}>
          <Typography
            variant="body1"
            fontSize={{ xs: 9, md: 9 }}
            color={whiteColor}
            fontWeight={500}
            lineHeight="14px"
          >
            {count}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const ProfileCard = ({ prifileImg, name, onLogout }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        cursor: "pointer",
      }}
      onClick={() => {}}
    >
      {prifileImg == "" ? (
        <ProfileCharAvatar
          username={getUserName()}
          border
          size="32px"
          fontSize="13.5px"
        />
      ) : (
        <Box
          component="img"
          src={prifileImg}
          width={{ xs: "32px", md: "35px" }}
          height={{ xs: "38px", md: "35px" }}
          sx={{ borderRadius: "50%" }}
        />
      )}

      <Box>
        <Typography
          variant="body1"
          fontSize={{ xs: 13, md: 13.5 }}
          color={whiteColor}
          fontWeight={500}
          lineHeight="15px"
        >
          {name}
        </Typography>

        <Typography
          variant="body1"
          fontSize={{ xs: 11, md: 12 }}
          color={greyColor6}
          fontWeight={400}
          lineHeight="20px"
          sx={{
            "&:hover": {
              color: heroSectionText,
            },
          }}
          onClick={onLogout}
        >
          Log out
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  iconBtn: {
    width: "28px",
    height: "41px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    // border: "1px solid #d5d5d5",
    borderRadius: "10px",
    position: "relative",
    cursor: "pointer",
  },

  badge: (bgColor) => ({
    width: "22px",
    height: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: bgColor,
    borderRadius: "20px",
    position: "absolute",
    top: "-3px",
    right: "-3px",
  }),

  headerContainer: (width) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // backgroundColor: navbarBGColor,
    background: { xs: mobilePrimary, md: blueColor03 },
    boxShadow: "0 5px 8px rgb(179 188 198 / 14%)",
    p: { xs: width ? "0 0 28px 16px" : "0 0 4px 16px", md: "0px 16px" },
    borderRadius: { xs: "0 0 25px 25px", md: "none" },
    position: "relative",
  }),

  menuLine: (width) => ({
    width: width || "22px",
    height: "1.5px",
    backgroundColor: whiteColor,

    borderRadius: "2px",
  }),

  menuText: {
    color: greyColor5,
    fontSize: { xs: 13, md: 14 },
    fontWeight: 500,
    "&:hover": {
      color: greyColor5,
    },
  },

  businessInfoCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 1,
    width: { xs: "calc(100vw - 20px)", md: "auto" },
    p: "6px 8px",
    backgroundColor: whiteColor,
    borderBottom: "1px solid #e5e9f0",
    borderRadius: { xs: "8px", md: "0px" },
    position: "absolute",
    bottom: "-31px",
    left: "10px",
  },

  addIconBtnSmall: {
    width: "32px",
    height: "28px",
    backgroundColor: "transparent",
    fontWeight: 600,
    color: { xs: mobilePrimary, md: blueColor04 },
    backgroundColor: whiteColor,
    borderRadius: "5px",
    border: {
      xs: "1px solid " + mobilePrimary,
      md: "1px solid " + blueColor04,
    },
    "&:hover": {
      color: whiteColor,
      backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    },
  },
};
