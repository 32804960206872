import React from "react";
import { Box, Typography } from "@mui/material";
import {
  blackShade05,
  blueColor04,
  border26,
  mobilePrimary,
  whiteColor,
} from "../configs/styles/muiThemes";
import { scrollBox } from "../utils/styles";

function PillTabs({ steps, active, ontabClick, productCount }) {
  return (
    <Box
      display={"flex"}
      gap={2}
      sx={{ ...scrollBox, display: "flex" }}
      mb={{ md: 0, xs: 2 }}
    >
      {steps?.map((item, index) => (
        <Box
          key={item?.id + index}
          sx={styles?.pillTab(active === item?.value)}
          onClick={() => ontabClick(item)}
        >
          {item?.label} - {productCount(item)}
        </Box>
      ))}
    </Box>
  );
}

export default PillTabs;

const styles = {
  pillTab: (active) => ({
    p: "3px 17px",
    borderRadius: "60px",
    backgroundColor: {xs: active ? mobilePrimary : whiteColor, md:active ? blueColor04 : whiteColor},
    fontWeight: 500,
    fontSize: "12px",
    border: border26,
    cursor: "pointer",
    color: active ? whiteColor : blackShade05,
    whiteSpace: "nowrap",
  }),
};
