import React, { useState } from "react";
import PageHeader from "../components/PageHeader";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  bgColor15,
  blackColor,
  blackShade05,
  greenColor6,
  greyColor42,
  lightGreen15,
  orangeStatusColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import PageMobileHeader from "../components/PageMobileHeader";

const DirectShareLinkContent = ({ businessData }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [isCopied, setIsCopied] = useState(false);

  const DATA_LIST = [
    "Direct Customer orders",
    "Pick up, Drop and Monitoring",
    "Low cost logistics",
    "Repeat orders",
    "ONDC discounts",
  ];

  //   Copy Link
  const handleCopy = (link) => {
    navigator.clipboard.writeText(link);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const openLink = (link, target = "_blank") => {
    window.open(link, target);
  };

  const onWhatsappClick = (link) => {
    const message = `Dear Customer,%0A%0AExciting news! We are thrilled to announce that our entire range of products are now available to buy online in ${businessData?.name} webstore. Get ready to unlock fantastic discounts and elevate your savings to new heights! %0A%0A
To make the most of this opportunity, simply place your order through the below link. %0A%0A${link}%0A%0ADon't miss out on this chance to enjoy premium quality products at unbeatable prices. Thank you for choosing us.%0A%0AWarm regards,%0A${businessData?.name}
`;

    window.open(`https://web.whatsapp.com/send?text=${message}`, "_blank");
  };

  return (
    <Box pt={{ xs: 1.5, md: 0 }} pb={3}>
      {matches ? (
        <PageHeader
          title="Direct Store Link"
          hideUrl
          businessData={businessData}
        />
      ) : (
        <PageMobileHeader title="Direct Store Link" tabValue="direct_link" />
      )}

      <Box mt={{ xs: 1, md: 2.5 }}>
        <Grid container spacing={{ xs: 2, md: 4 }}>
          <Grid item xs={12} md={6}>
            <Box sx={styles.card}>
              <Box sx={styles.cardHeader}>Share to Customers</Box>

              <Box
                sx={{
                  p: "12px 15px",
                }}
              >
                <Box sx={{ textAlign: "center", mt: 1 }}>
                  <Box
                    component="img"
                    src="https://storage.googleapis.com/bodefaults/businessdashboardweb/store-link.svg"
                    sx={{ width: "171px", objectFit: "contain" }}
                  />
                </Box>

                <Typography
                  component="h4"
                  fontSize={{ xs: 13, md: 13 }}
                  color={blackShade05}
                  fontWeight={600}
                  lineHeight="25px"
                  mb={1}
                >
                  Share the Link
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Box
                      sx={styles.linkBox}
                      onClick={() => {
                        openLink(
                          `https://shop.costbo.com/${businessData?.costboWebURL}/catalog`
                        );
                      }}
                    >
                      https://shop.costbo.com/{businessData?.costboWebURL}/catalog
                    </Box>
                  </Grid>

                  <Grid item xs={2} md={1.1}>
                    <IconButton
                      sx={{
                        width: "40px",
                        height: "35px",
                        border: `1px solid ${
                          isCopied ? greenColor6 : bgColor15
                        }`,
                        bgcolor: whiteColor,
                        borderRadius: "6px",
                      }}
                      onClick={() => {
                        handleCopy(
                          `https://shop.costbo.com/${businessData?.costboWebURL}/catalog`
                        );
                      }}
                    >
                      {isCopied ? (
                        <CheckCircleOutlineIcon
                          sx={{ fontSize: "20px", color: greenColor6 }}
                        />
                      ) : (
                        <ContentCopyIcon
                          sx={{ fontSize: "16px", color: bgColor15 }}
                        />
                      )}
                    </IconButton>
                  </Grid>

                  <Grid item xs={2} md={2}>
                    <IconButton
                      sx={{
                        width: "40px",
                        height: "35px",
                        bgcolor: greenColor6,
                        borderRadius: "6px",
                        "&:hover": {
                          bgcolor: greenColor6,
                        },
                      }}
                      onClick={() => {
                        onWhatsappClick(
                          `https://shop.costbo.com/${businessData?.costboWebURL}/catalog`
                        );
                      }}
                    >
                      <WhatsAppIcon
                        sx={{ fontSize: "20px", color: whiteColor }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={styles.card}>
              <Box sx={styles.cardHeader}>Benefits</Box>

              <Box
                sx={{
                  p: "12px 15px",
                }}
              >
                {DATA_LIST?.map((item) => (
                  <Box display="flex" alignItems="center" gap={2} key={item}>
                    <DoubleArrowIcon
                      sx={{ fontSize: "20px", color: orangeStatusColor }}
                    />

                    <Typography
                      fontSize={{ xs: 13, md: 14 }}
                      fontWeight={500}
                      color={blackColor}
                      lineHeight={{ xs: "32px", md: "44px" }}
                    >
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DirectShareLinkContent;

const styles = {
  card: {
    bgcolor: whiteColor,
    borderRadius: "10px",
    border: `1px solid ${greyColor42}`,
    overflow: "hidden",
  },

  cardHeader: {
    fontSize: { xs: 14, md: 15 },
    fontWeight: 600,
    lineHeight: "18px",
    color: blackColor,
    bgcolor: greyColor42,
    p: { xs: "8px 15px", md: "10px 15px" },
    textAlign: { xs: "left", md: "center" },
  },

  linkBox: {
    fontSize: 12,
    fontWeight: 600,
    bgcolor: lightGreen15,
    color: greenColor6,
    border: `1px dashed ${greenColor6}`,
    p: "5px 10px",
    borderRadius: "6px",
    cursor: "pointer",
  },
};
