import {
  Box,
  Button,
  Checkbox,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import {
  bgColor15,
  blackShade19,
  blackShade23,
  blueColor04,
  greyColor40,
  greyColor41,
  greyColor42,
  greyColor50,
  greyColor8,
  heroSectionText,
  mobilePrimary,
  oldPriceColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { textOneLines } from "../../../utils/styles";
import { getColorChip, getOrderStatusText } from "../../../utils/helper";
import { calculatePercentageOff } from "../../../utils/validationHelper";

const ProductInfoRowCard = ({
  isSelected,
  onProductSelect,
  loader,
  productImg,
  productName,
  originalPrice,
  salePrice,
  ondcEnabled,
  lastUpdateDate,
  tabValue,
  onPushProduct,
  index,
  isAllSelected,
  selectAllProducts,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        bgcolor: whiteColor,
        padding: index === 0 && !matches && tabValue === 0 ? "0 0 12px" : "12px 0",
        borderBottom: `1px solid ${greyColor41}`,
        borderRadius: { xs: "6px", md: "0px" },
        mb: { xs: 1.5, md: 0 },
      }}
    >
      {index === 0 && !matches && tabValue === 0 && (
        <Box sx={styles.mobileSelectAllBox}>
          <Checkbox
            checked={isAllSelected}
            onChange={({ target }) => {
              selectAllProducts();
            }}
            sx={styles.checkbox}
            disabled={loader}
          />
          <Box sx={{ fontSize: 13, fontWeight: 500, color: blackShade23 }}>
            Select All Products
          </Box>
        </Box>
      )}

      <Grid container rowSpacing={{ xs: 1, md: 0 }}>
        <Grid item xs={3} md={1}>
          <Box
            display="flex"
            alignItems="flex-start"
            gap={{ xs: 1.5, md: 2 }}
            pl={2}
          >
            {tabValue === 0 && (
              <Checkbox
                sx={{ ...styles.checkbox, mt: 1 }}
                checked={isSelected}
                onChange={onProductSelect}
                disabled={loader}
              />
            )}

            <Box sx={styles.imgBox}>
              <Box component="img" src={productImg} sx={styles.imgContainer} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={9} md={tabValue === 0 ? 5 : 4}>
          <Box pl={2}>
            <Typography
              sx={{
                ...textOneLines,
                fontSize: 12.5,
                fontWeight: 600,
                color: blackShade19,
              }}
            >
              {productName}
            </Typography>

            {!matches && (
              <PriceInfo originalPrice={originalPrice} salePrice={salePrice} />
            )}
          </Box>
        </Grid>

        {matches && (
          <Grid item xs={12} md={1.8}>
            <PriceInfo originalPrice={originalPrice} salePrice={salePrice} />
          </Grid>
        )}

        {matches && (
          <Grid item xs={12} md={1.4}>
            <Box
              display="flex"
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={{ xs: "flex-start", md: "center" }}
              // pl={2}
            >
              <Box
                sx={styles.statusChip(
                  getColorChip(ondcEnabled === "yes" ? "yes" : "no")
                )}
              >
                {getOrderStatusText(ondcEnabled === "yes" ? "yes" : "no")}
              </Box>
            </Box>
          </Grid>
        )}

        {!matches && (
          <Grid item xs={6}>
            <Typography
              variant="body1"
              color={bgColor15}
              fontSize={12}
              lineHeight="20px"
              fontWeight={400}
              display="inline"
              pl={2}
            >
              {`ONDC Enabled: `}{" "}
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: ondcEnabled === "yes" ? "#27bd72" : "#e53935",
                  marginLeft: "6px",
                }}
              >
                {ondcEnabled === "yes" ? "Yes" : "No"}
              </span>
            </Typography>
          </Grid>
        )}

        <Grid item xs={6} md={tabValue === 0 ? 2 : 1.9}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            m={{ xs: 0, md: "4px 0 0 15px" }}
          >
            <Typography
              sx={{
                fontSize: { xs: 12, md: 12 },
                fontWeight: { xs: 400, md: 500 },
                color: { xs: greyColor50, md: greyColor40 },
                m: { xs: "0 15px 0 auto", md: 0 },
              }}
            >
              {lastUpdateDate}
            </Typography>
          </Box>
        </Grid>

        {tabValue !== 0 && (
          <Grid item xs={12} md={tabValue === 0 ? 2 : 1.9}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              m={{ xs: 0, md: "4px 0 0 0px" }}
            >
              <Button
                sx={styles.pushProductBtn}
                variant="contained"
                disableElevation
                onClick={() => onPushProduct()}
                // disabled={}
              >
                PUSH TO BRANCHES
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ProductInfoRowCard;

const PriceInfo = ({ originalPrice, salePrice }) => {
  return (
    <Box display={"flex"} flexDirection={"row"} gap={1} pl={{ xs: 0, md: 2 }}>
      <Typography variant="body1" sx={styles.originalPriceText}>
        {originalPrice === salePrice ? "" : `₹${originalPrice}`}
      </Typography>

      <Typography variant="body1" sx={styles.salePriceText}>
        ₹{salePrice}
      </Typography>

      <Box sx={styles.statusBoxChip(getColorChip("processing"))}>
        <Typography
          variant="body1"
          color={heroSectionText}
          fontSize="12px"
          fontWeight={600}
          m="6px 0 0 0px !important"
          sx={{ display: "inline" }}
        >
          {Math.round(calculatePercentageOff(originalPrice, salePrice))}%
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  checkbox: {
    p: 0,
    transform: "scale(1.3)",
    "&.Mui-checked": {
      color: greyColor8,
    },
  },

  imgBox: {
    borderRadius: "4px",
    border: "1px solid #e5e9f0",
    p: "5px",
  },

  imgContainer: {
    backgroundColor: whiteColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: { xs: "25px", md: "35px !important" },
    height: { xs: "25px", md: "35px !important" },
    objectFit: "contain",
  },

  ondcStatusTag: {
    width: "55px",
    display: { xs: "inline", md: "flex" },
    alignItems: "center",
    justifyContent: "center",
    fontSize: { xs: 13, md: 12 },
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "50px",
    p: { xs: "0 8px", md: "1px 13px" },
  },

  statusChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    p: "5px 13px",
    borderRadius: "50px",
    width: "max-content",
  }),

  statusBoxChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    p: "2px 4px",
    borderRadius: "4px",
    width: "max-content",
  }),

  originalPriceText: {
    color: oldPriceColor,
    fontSize: 12,
    lineHeight: "15px",
    fontWeight: 400,
    mt: { xs: "6px !important", md: "7px !important" },
    textDecoration: "line-through",
    display: "inline",
  },

  salePriceText: {
    display: "inline",
    color: blueColor04,
    fontSize: 13,
    lineHeight: "15px",
    fontWeight: 600,
    m: { xs: "6px", md: "6px 0 0 0px" },
    mr: 1,
  },

  pushProductBtn: {
    padding: { md: "3px 6px", xs: "3px 14px" },
    fontSize: { md: "11px", xs: "11px" },
    fontWeight: 600,
    color: { xs: mobilePrimary, md: blueColor04 },
    backgroundColor: whiteColor,
    border: {
      xs: `1.5px solid ${mobilePrimary}`,
      md: `1.5px solid ${blueColor04}`,
    },
    ml: { xs: "auto", md: 0 },
    mr: { xs: 2, md: 0 },
    whiteSpace: "nowrap",
    "&:hover": {
      border: {
        xs: `1.5px solid ${mobilePrimary}`,
        md: `1.5px solid ${blueColor04}`,
      },
      color: { xs: mobilePrimary, md: blueColor04 },
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },

  mobileSelectAllBox: {
    display: "flex",
    alignItems: "center",
    gap: 1.4,
    padding: "8px 15px",
    backgroundColor: greyColor42,
    borderRadius: "6px 6px 0 0",
    mb: 1,
  },
};
