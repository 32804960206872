import { configureStore } from "@reduxjs/toolkit";
import { addProductReducer } from "./AddProduct/AddProductSlice";
import { authReducer } from "./auth/authSlice";
import { businessOnboardingReducer } from "./businessOnboard/businessOnboardSlice";
import { businessPreviewReducer } from "./businessPreview/businessPreviewSlice";
import { leadsReducer } from "./leadsRedux/leadsReducer";
import { catalogReducer } from "./catalog/CatalogSlice";
import { businessDashboardReducer } from "./businessDashboard/BusinessDashboardSlice";
import { businessCouponsReducer } from "./coupons/couponSlice";
import { businessOrdersReducer } from "./orders/ordersSlice";
import { businessPaycartOrdersReducer } from "./paycartOrders/paycartOrderSlice";
import { businessCustomersReducer } from "./crmcustomerdata/customerSlice";
import { businessPreferredDiscountsReducer } from "./preferredDiscount/PreferredDiscountSlice";
import { businessCashbackReducer } from "./cashback/CashbackSlice";
import { affiliatePanelReducer } from "./AffiliatePanels/AffiliateSlice";
import { eCommerceControlReducer } from "./ecommerceControl/EcommerceControlSlice";
import { catalogBulkUploadReducer } from "./catalogBulkUpload/catalogBulkUploadSlice";
import { manageReturnOrdersReducer } from "./manageReturns/manageReturnsSlice";
import { grievanceReducer } from "./grievanceIssue/grievanceSlice";
import { catalogBulkUploadReducer1 } from "./catalogBulkUploadCopy/catalogBulkUploadSlice";
import { businessInventoryReducer } from "./inventory/inventorySlice";
import { multiBranchCatalogReducer } from "./multiBranchCatalog/multiBranchCatalogSlice";

const store = configureStore({
  reducer: {
    leads: leadsReducer,
    auth: authReducer,
    businessOnboard: businessOnboardingReducer,
    businessPreview: businessPreviewReducer,
    addProduct: addProductReducer,
    catalog: catalogReducer,
    businessDashboard: businessDashboardReducer,
    businessCoupons: businessCouponsReducer,
    businessOrders: businessOrdersReducer,
    businessPaycartOrders: businessPaycartOrdersReducer,
    businessCustomers: businessCustomersReducer,
    businessPreferredDiscounts: businessPreferredDiscountsReducer,
    businessCashback: businessCashbackReducer,
    affiliatePanelControl: affiliatePanelReducer,
    eCommerceControl: eCommerceControlReducer,
    catalogBulkUpload: catalogBulkUploadReducer,
    catalogBulkUploadCopy: catalogBulkUploadReducer1,
    manageReturns: manageReturnOrdersReducer,
    grievance: grievanceReducer,
    inventory: businessInventoryReducer,
    multiBranchCatalog: multiBranchCatalogReducer
  },
});
export default store;
