import { Box, Button, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  greyColor8,
  logoImgborder1,
  whiteColor,
} from "../configs/styles/muiThemes";

function ListEmptyCard({ icon, text, hideBtn, btnText, onClick, noPadding }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={noPadding ? 0.3 : 3}
      backgroundColor={whiteColor}
      borderRadius='10px'
      boxShadow='0 3px 4px rgb(229 233 240 / 22%)'
      border={logoImgborder1}
    >
      <Box sx={styles.iconCircle}>{icon}</Box>

      <Typography fontSize={14} fontWeight={500} color={blackShade05}>
        {text}
      </Typography>

      {!hideBtn && (
        <Button sx={styles.btnStyles} onClick={onClick}>
          {btnText}
        </Button>
      )}
    </Box>
  );
}

export default ListEmptyCard;

const styles = {
  iconCircle: {
    width: "50px",
    height: "50px",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(236 237 239)",
    mb: "10px",
  },

  btnStyles: {
    padding: "4px 22px",
    fontSize: "13px",
    fontWeight: 600,
    color: greyColor8,
    backgroundColor: whiteColor,
    border: "1.5px solid " + greyColor8,
    marginTop: "15px",
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },
};
