import { Box } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AffiliateOrderCard from "../AffiiateCards/AffiliateOrderCard";
import { addThousandsSeparator } from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAffiliatePanelInfo,
  getAllAffiliatePanelData,
} from "../../../reducers/AffiliatePanels/AffiliateSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import EmptyCard from "../../../components/Cards/EmptyCard";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";

function AffiliateTotalOrderCard() {
  const page_size = 50;

  const dispatch = useDispatch();
  const [hasMoreData, setHasMoreData] = useState(true);

  const { affiliatePanel, affiliatePanelPageNo, affiliatePanelLoader } =
    useSelector((state) => state.affiliatePanelControl);

  const getAffiliateTotalsOrders = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    dispatch(
      getAllAffiliatePanelData({
        businessId,
        pageNo: affiliatePanelPageNo + 1,
        pageSize: page_size,
      })
    );
  };
  const fetchNextData = () => {
    getAffiliateTotalsOrders();
  };

  useEffect(() => {
    getAffiliateTotalsOrders();
    return () => {
      dispatch(clearAffiliatePanelInfo());
    };
  }, []);
  useEffect(() => {
    if (affiliatePanelPageNo * page_size > affiliatePanel?.details?.length) {
      setHasMoreData(false);
    }

    return () => {};
  }, [affiliatePanelPageNo]);

  return (
    <Box>
      {/* json:{JSON.stringify(affiliatePanel)} */}
      {/* <EmptyCard
        icon={
          <Box
            component="img"
            alt="coming soon"
            width="40px"
            height="auto"
            display="block"
          />
        }
        msg="Details of orders placed with affiliate code - Coming Soon "
      /> */}
      {!affiliatePanelLoader &&
      affiliatePanelPageNo === 0 &&
      affiliatePanel?.details.length === 0 ? (
        <Box><EmptyCard
          icon={<HandshakeOutlinedIcon sx={{ fontSize: "24px" }} />}
          msg="Total Orders not Found"
        />
        </Box>
      ) : (
        affiliatePanelLoader && affiliatePanelPageNo === 1 && <ThreeDotLoader />
      )}
      {affiliatePanel?.details.length > 0 && (
        <InfiniteScroll
          dataLength={affiliatePanel?.details?.length || 0}
          next={fetchNextData}
          hasMore={hasMoreData}
          loader={
            <Box>
              <ThreeDotLoader />
            </Box>
          }
        >
          {affiliatePanel?.details?.map((item, index) => {
            const orderedDate = moment(item?.orderCreatedOn).format(
              "MMM Do YYYY"
            );

            const userInfo = affiliatePanel.profileInfo?.filter(
              (profile) => item.profileId === profile.profileId
            );
            let username = "";
            let usernumber = "";
            let profilePic = "";

            if (userInfo?.length > 0) {
              username = userInfo?.[0]?.name;
              usernumber = userInfo?.[0]?.phone;
              profilePic = userInfo?.[0]?.photoUrl;
            }

            return (
              <AffiliateOrderCard
                partnerName={username}
                partnerNumber={usernumber.replace(/(\d{2})(\d{0})/, "$1 $2")}
                partnerPic={profilePic}
                orderValue={`₹ ${
                  item?.orderTotalCost
                    ? addThousandsSeparator(item?.orderTotalCost)
                    : 0
                }`}
                comminsionEarned={`₹ ${
                  item?.amountEarned
                    ? addThousandsSeparator(item?.amountEarned)
                    : 0
                }`}
                commissionPercentage={item?.comissionPercentage}
                discountCommission={item?.discountPercentage}
                orderDate={orderedDate}
                orderId={item?.orderId}
                orderStatus={item?.orderStatus}
              />
            );
          })}
        </InfiniteScroll>
      )}
    </Box>
  );
}

export default AffiliateTotalOrderCard;
