import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade19,
  border25,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import InputPreview from "../../../components/Input/InputPreview";

const PriceInfoPreview = ({
  currency,
  originalPrice,
  offerPrice,
  qty,
  alertQty,
  minQtyPerOrder,
  maxQtyPerOrder,
  startDate,
  endDate,
}) => {
  return (
    <Box sx={styles.card}>
      <Box pb={1.2} mb={0}>
        <Typography sx={styles.title}>Price Details</Typography>
      </Box>

      <Grid container rowSpacing={{ xs: 2, md: 0 }} columnSpacing={3}>
        <Grid item xs={12} md={2.2}>
          <InputPreview label="Currency" value={currency} hideBorder />
        </Grid>

        <Grid item xs={6} md={2.2}>
          <InputPreview
            label="Original Price"
            value={originalPrice}
            hideBorder
          />
        </Grid>

        <Grid item xs={6} md={2.2}>
          <InputPreview label="Offer Price" value={offerPrice} hideBorder />
        </Grid>
      </Grid>

      <Box py={1.2} mb={0}>
        <Typography sx={styles.title}>Product Availablity</Typography>
      </Box>

      <Grid container rowSpacing={{ xs: 2, md: 0 }} columnSpacing={3}>
        <Grid item xs={6} md={2}>
          <InputPreview label="Available Quantity" value={qty} hideBorder />
        </Grid>

        <Grid item xs={6} md={2}>
          <InputPreview label="Alert Quantity" value={alertQty} hideBorder />
        </Grid>

        <Grid item xs={6} md={2}>
          <InputPreview
            label="Min Quantity Per Order"
            value={minQtyPerOrder}
            hideBorder
          />
        </Grid>

        <Grid item xs={6} md={2}>
          <InputPreview
            label="Max Quantity Per Order"
            value={maxQtyPerOrder}
            hideBorder
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <InputPreview label="Start Date" value={startDate} hideBorder />
        </Grid>

        <Grid item xs={6} md={2}>
          <InputPreview label="End Date" value={endDate} hideBorder />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PriceInfoPreview;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    border: border25,
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: "15px 15px",
    mb: 2,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    color: blackShade19,
  },
};
