import { Box, Button, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blueColor02,
  blueColor04,
  greyColor24,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";

function LoyaltyPointEmptyCard({ onClick, activeBusinessInfo }) {
  const whatsAppNumber = "+918088422615";
  const emailAddress = "support@costbo.com";
  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        // `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${activeBusinessInfo?.name}+- Loyalty Points+Update+Request.+`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };
  return (
    <Box sx={styles.card} gap={1}>
      <Box sx={styles.iconContainer}>
        <SellOutlinedIcon sx={{ fontSize: "24px" }} />
      </Box>

      <Typography
        color={blackShade05}
        fontSize={13}
        fontWeight={500}
        lineHeight={1.6}
        sx={{ whiteSpace: "pre-line" }}
      >
        {`To encourage repeat customer purchase \nenable `}
        <Typography
          fontSize={13}
          fontWeight={500}
          sx={{ color: {xs: mobilePrimary, md:blueColor04}, display: "inline" }}
        >
          Loyalty Points Program
        </Typography>
      </Typography>

      <Typography
        color={{xs: mobilePrimary, md:blueColor04}}
        fontSize={{xs:12, md:13}}
        fontWeight={400}
        lineHeight={1.6}
        // sx={{ whiteSpace: "pre-line", mt: 2 }}
        display="inline"
      >
        Contact COSTBO support via{" "}
        <Typography
          // variant="body1"
          fontSize={{xs:12, md:13}}
          fontWeight={500}
          color={{xs: mobilePrimary, md:blueColor04}}
          display="inline"
          // onclick={handleChatClick}
          component="a"
          href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=${activeBusinessInfo?.name}+- Loyalty Points+Update+Request+&fs=1&to=${emailAddress}&tf=cm`}
          target="_blank"
        >
          Email
        </Typography>
        {"  "}
        or{" "}
        <Typography
          // variant="body1"
          fontSize={{xs:12, md:13}}
          fontWeight={500}
          color={{xs: mobilePrimary, md:blueColor04}}
          display="inline"
          // component="a"
          onClick={handleChatClick}
          // href={`https://mail.google.com/mail/u/0/?fs=1&to=${emailAddress}&tf=cm`}
          // target="_blank"
        >
          Whatsapp{"  "}
        </Typography>
        {`to enroll in \nthis program`}
      </Typography>
    </Box>
  );
}

export default LoyaltyPointEmptyCard;

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: whiteColor,
    marginBottom: "12px",
    borderRadius: "10px",
    padding: "10px 0 0 0",
    textAlign: "center",
  },
  iconContainer: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    background: "rgb(5 60 81 / 7%)",
    padding: "12px",
    mb: "10px",
  },

  actionBtn: {
    fontSize: "13px",
    fontWeight: "600",
    color: greyColor24,
    backgroundColor: whiteColor,
    padding: "6px 22px",
    mt: "15px",
    border: `1px solid ${greyColor24}`,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor24,
    },
  },
};
