import {
  Box,
  Checkbox,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BGColor04,
  bgColor15,
  bgColor3,
  blackShade19,
  border25,
  greyColor21,
  greyColor41,
  greyColor42,
  greyColor47,
  redColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import moment from "moment";
import {
  HEADER_COLUMNS,
  HEADER_COLUMNS_ACTION,
  RETURN_ORDER_ACTION_DATA,
} from "../../../utils/data";
import TableDropdown from "../../CatalogBulkUpload/Components/TableDropdown";
import { addThousandsSeparator } from "../../../utils/helper";

const ReturnProductInfoCard = ({
  productInfo,
  productId,
  partialReturned,
  actionReasons,
  type,
  handleUpdateAction,
  onViewImage,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  let count = 0;

  const current_Columns =
    type === "view" ? HEADER_COLUMNS : HEADER_COLUMNS_ACTION;

  const convertArrayToObject = (array) => {
    return array.reduce((result, item) => {
      result[item.code] = item.value;
      return result;
    }, {});
  };

  const getReturnOrderCount = () => {
    count = count + 1;
    return count;
  };

  return (
    <Box sx={styles.card}>
      <Box mb={2.5}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3.5}>
            <Typography variant="body2" sx={styles.labelText}>
              PRODUCT DETAIL
            </Typography>

            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Box
                component="img"
                src={productInfo?.productPicURL}
                alt="product img"
                sx={styles.img}
              />

              <Typography
                variant="h5"
                fontSize={{ xs: 12, md: 13 }}
                fontWeight={600}
                color={blackShade19}
              >
                {productInfo?.productName}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={5} md={2.83}>
            <Typography variant="body2" sx={styles.labelText}>
              ORDER QTY
            </Typography>
            <Typography sx={{ ...styles.infoText, ml: 0, fontWeight: 600 }}>
              {productInfo?.originalQuantity}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2.83}>
            <Typography variant="body2" sx={styles.labelText}>
              PRICE
            </Typography>
            <Typography sx={{ ...styles.infoText, ml: 0, fontWeight: 600 }}>
              ₹ {productInfo?.productTotalPrice}
            </Typography>
          </Grid>
        </Grid>

        {matches && (
          <Typography
            fontSize={14}
            fontWeight={600}
            color={blackShade19}
            mt={1.5}
          >
            Return Info
          </Typography>
        )}
      </Box>

      <Box
        border={{ xs: "none", md: `1px solid ${greyColor41}` }}
        borderRadius="6px"
      >
        {matches && (
          <Box
            sx={{ bgcolor: greyColor42, pl: 2, borderRadius: "6px 6px 0 0" }}
          >
            <Grid container spacing={2}>
              {current_Columns.map((item, index) => {
                return (
                  <Grid item xs={12} md={item.col} p="3px 0 0 0 !important">
                    {item.isCheckBox ? (
                      <Typography
                        fontSize={11.5}
                        fontWeight={500}
                        color={greyColor47}
                        ml={2}
                      >
                        #
                      </Typography>
                    ) : (
                      <Box
                        borderRight={
                          HEADER_COLUMNS.length === index + 1
                            ? "none"
                            : `1px solid ${whiteColor}`
                        }
                        ml={index === 0 ? 2 : 1}
                      >
                        <Typography
                          fontSize={11.5}
                          fontWeight={500}
                          color={greyColor47}
                        >
                          {item.title}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}

        {partialReturned?.map((returnedProd, index) => {
          const returnRequestedData = returnedProd?.ondcReturnTags?.filter(
            (item) => item?.code === "return_request"
          );

          const ondcReturnRequestData = convertArrayToObject(
            returnRequestedData?.[0]?.list || []
          );

          return (
            returnedProd.productId === productId && (
              <>
                {!matches && (
                  <Typography
                    fontSize={13}
                    fontWeight={600}
                    color={blackShade19}
                    my={0.5}
                  >
                    Return Info ({getReturnOrderCount()})
                  </Typography>
                )}

                <Box
                  sx={{
                    bgcolor: whiteColor,
                    borderRadius: { xs: "8px", md: "0px" },
                  }}
                  key={`partial_prod_${index}`}
                  borderBottom={border25}
                >
                  <Box p={{ xs: "10px", md: "10px 0" }}>
                    <Grid
                      container
                      spacing={{ xs: 0, md: 2 }}
                      // alignItems="flex-start"
                      alignItems={"center"}
                    >
                      {type === "action" && matches && (
                        <Grid item xs={2} md={0.35}>
                          <Checkbox
                            sx={{
                              color: greyColor21,
                              mt: -1,
                              ml: { xs: 0, md: 0.6 },
                              transform: "scale(1.3)",
                            }}
                            checked={returnedProd?.isSelected || false}
                            onChange={({ target }) => {
                              handleUpdateAction(
                                returnedProd?.returnId,
                                "isSelected",
                                target.checked
                              );
                            }}
                            disabled={returnedProd?.disabled}
                          />
                        </Grid>
                      )}

                      <Grid item xs={5} md={type === "view" ? 0.8 : 1}>
                        <Typography sx={styles.labelTextMobile}>
                          QUANTITY
                        </Typography>
                        <Typography
                          sx={{
                            ...styles.infoText,
                            ml: { xs: 0, md: type === "view" ? 2 : 1 },
                          }}
                        >
                          {returnedProd?.returnQuantity}
                        </Typography>
                      </Grid>

                      <Grid item xs={5} md={type === "view" ? 1.3 : 1.3}>
                        <Typography sx={styles.labelTextMobile}>
                          REFUND REQUESTED
                        </Typography>
                        <Typography sx={styles.infoText}>
                          ₹{" "}
                          {type === "view"
                            ? Number(returnedProd?.returnQuantity) *
                              Number(productInfo?.productSalePrice)
                            : returnedProd?.refundAmount}
                        </Typography>
                      </Grid>

                      {!matches && (
                        <Grid item xs={12}>
                          <Box sx={{ my: 1, borderBottom: border25 }} />
                        </Grid>
                      )}

                      <Grid item xs={9.8} md={1.8}>
                        <Typography sx={styles.labelTextMobile}>
                          REASON
                        </Typography>
                        <Typography sx={styles.infoText}>
                          {ondcReturnRequestData?.reason_desc || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={2.2} md={type === "view" ? 0.65 : 0.7}>
                        {ondcReturnRequestData?.images ? (
                          <>
                            <Typography
                              sx={{
                                ...styles.labelTextMobile,
                                textAlign: { xs: "right", md: "left" },
                              }}
                            >
                              IMAGE
                            </Typography>
                            <Box
                              component="img"
                              src={ondcReturnRequestData?.images}
                              alt=""
                              sx={{
                                ...styles.img,
                                width: "32px",
                                height: "32px",
                                ml: { xs: "15px", md: "0px" },
                              }}
                              onClick={() => {
                                onViewImage(ondcReturnRequestData?.images);
                              }}
                            />
                          </>
                        ) : (
                          <Typography sx={styles.infoText}>N/A</Typography>
                        )}
                      </Grid>

                      {!matches && (
                        <Grid item xs={12}>
                          <Box sx={{ my: 1, borderBottom: border25 }} />
                        </Grid>
                      )}

                      <Grid
                        item
                        xs={type === "view" ? 8.5 : 5}
                        md={type === "view" ? 1.2 : 1.5}
                      >
                        <Typography sx={styles.labelTextMobile}>
                          REQUEST DATE
                        </Typography>
                        <Typography sx={styles.infoText}>
                          {returnedProd?.createdOn
                            ? moment(returnedProd?.createdOn)?.format(
                                "DD MMM, hh:mm A"
                              )
                            : "N/A"}
                        </Typography>
                      </Grid>

                      {!matches && type === "view" && (
                        <Grid item xs={12}>
                          <Box sx={{ my: 1, borderBottom: border25 }} />
                        </Grid>
                      )}

                      <Grid
                        item
                        xs={type === "view" ? 8.5 : 5}
                        md={type === "view" ? 1.3 : 1.25}
                      >
                        <Typography sx={styles.labelTextMobile}>
                          REFUND PROCESSED
                        </Typography>
                        <Typography sx={styles.infoText}>
                          {returnedProd?.refundAmount
                            ? `₹ ${addThousandsSeparator(
                                returnedProd?.refundAmount
                              )}`
                            : "-"}
                        </Typography>
                      </Grid>

                      {(type === "view" || type === "action") && (
                        <Grid item xs={type === "view" ? 3.5 : 7} md={1.3}>
                          {type === "view" ? (
                            <>
                              <Typography
                                sx={{
                                  ...styles.labelTextMobile,
                                  textAlign: { xs: "right", md: "left" },
                                }}
                              >
                                ACTION
                              </Typography>
                              <Typography
                                sx={{
                                  ...styles.infoText,
                                  textAlign: { xs: "right", md: "left" },
                                }}
                              >
                                {returnedProd?.returnStatus}
                              </Typography>
                            </>
                          ) : matches ? (
                            <>
                              <TableDropdown
                                value={
                                  returnedProd?.updatedReturnStatus ||
                                  "Select Return Status"
                                }
                                onValueChange={(value) => {
                                  handleUpdateAction(
                                    returnedProd?.returnId,
                                    "updatedReturnStatus",
                                    value
                                  );
                                }}
                                menuList={RETURN_ORDER_ACTION_DATA}
                                borderFull
                                rounded
                                width="100%"
                                disabled={returnedProd?.disabled}
                              />

                              <Typography
                                fontSize={11}
                                fontWeight={500}
                                color={redColor}
                              >
                                {returnedProd?.errors?.updatedReturnStatus}
                              </Typography>
                            </>
                          ) : null}
                        </Grid>
                      )}

                      {!matches && (
                        <Grid item xs={12}>
                          <Box sx={{ my: 1, borderBottom: border25 }} />
                        </Grid>
                      )}

                      {(type === "view" || type === "action") && (
                        <Grid item xs={12} md={type === "view" ? 2 : 2.7}>
                          {type === "view" ? (
                            <>
                              <Typography sx={styles.labelTextMobile}>
                                REASON
                              </Typography>
                              <Typography
                                sx={styles.infoText}
                                lineHeight={"18px"}
                              >
                                {returnedProd?.rejectReason || "N/A"}
                              </Typography>
                            </>
                          ) : matches ? (
                            <>
                              <TableDropdown
                                value={
                                  returnedProd?.reasonDesc?.reason ||
                                  "Select Reason"
                                }
                                onValueChange={(value) => {
                                  handleUpdateAction(
                                    returnedProd?.returnId,
                                    "reasonDesc",
                                    value
                                  );
                                }}
                                menuList={
                                  actionReasons?.length > 0
                                    ? [
                                        { reason: "Select Reason" },
                                        ...actionReasons,
                                      ]
                                    : [{ reason: "Select Reason" }]
                                }
                                returnFullItem
                                valueSelector="reason"
                                borderFull
                                rounded
                                width="100%"
                                disabled={
                                  returnedProd?.updatedReturnStatus !==
                                    "Rejected" || returnedProd?.disabled
                                }
                              />

                              <Typography
                                fontSize={11}
                                fontWeight={500}
                                color={redColor}
                              >
                                {returnedProd?.errors?.reasonDesc}
                              </Typography>
                            </>
                          ) : null}
                        </Grid>
                      )}

                      {type === "view" && (
                        <>
                          {!matches && (
                            <Grid item xs={12}>
                              <Box sx={{ my: 1, borderBottom: border25 }} />
                            </Grid>
                          )}
                          <Grid item xs={6} md={1.5}>
                            <Typography sx={styles.labelTextMobile}>
                              PROCESS DATE
                            </Typography>
                            <Typography sx={styles.infoText}>
                              {returnedProd?.updatedOn
                                ? moment(returnedProd?.updatedOn)?.format(
                                    "DD MMM, hh:mm A"
                                  )
                                : "N/A"}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>

                  {type !== "view" && !matches && (
                    <MobileAction
                      returnedProd={returnedProd}
                      actionReasons={actionReasons}
                      handleUpdateAction={handleUpdateAction}
                    />
                  )}
                </Box>
              </>
            )
          );
        })}
      </Box>
    </Box>
  );
};

export default ReturnProductInfoCard;

const MobileAction = ({ returnedProd, actionReasons, handleUpdateAction }) => {
  return (
    <Box sx={{ bgcolor: BGColor04, p: "8px 12px", borderRadius: "8px" }}>
      <Grid container spacing={1.5}>
        <Grid item xs={2}>
          <Checkbox
            sx={{ mt: 0.5, ml: { xs: 0, md: 0.6 }, transform: "scale(1.3)",color:greyColor21 }}
            checked={returnedProd?.isSelected || false}
            onChange={({ target }) => {
              handleUpdateAction(
                returnedProd?.returnId,
                "isSelected",
                target.checked
              );
            }}
            size="medium"
            disabled={returnedProd?.disabled}
          />
        </Grid>

        <Grid item xs={10}>
          <Typography
            sx={{
              ...styles.labelTextMobile,
              textAlign: "right",
              mb: 0.5,
              mr: 0.5,
            }}
          >
            ACTION
          </Typography>

          <TableDropdown
            value={returnedProd?.updatedReturnStatus || "Select Return Status"}
            onValueChange={(value) => {
              handleUpdateAction(
                returnedProd?.returnId,
                "updatedReturnStatus",
                value
              );
            }}
            menuList={RETURN_ORDER_ACTION_DATA}
            borderFull
            rounded
            width="100%"
            disabled={returnedProd?.disabled}
          />

          <Typography fontSize={11} fontWeight={500} color={redColor}>
            {returnedProd?.errors?.updatedReturnStatus}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography sx={styles.labelTextMobile}>REASON</Typography>

          <TableDropdown
            value={returnedProd?.reasonDesc?.reason || "Select Reason"}
            onValueChange={(value) => {
              handleUpdateAction(returnedProd?.returnId, "reasonDesc", value);
            }}
            menuList={
              actionReasons?.length > 0
                ? [{ reason: "Select Reason" }, ...actionReasons]
                : [{ reason: "Select Reason" }]
            }
            returnFullItem
            valueSelector="reason"
            borderFull
            rounded
            width="100%"
            disabled={
              returnedProd?.updatedReturnStatus !== "Rejected" ||
              returnedProd?.disabled
            }
          />

          <Typography fontSize={11} fontWeight={500} color={redColor}>
            {returnedProd?.errors?.reasonDesc}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = {
  card: {
    border: border25,
    p: "12px",
    borderRadius: { xs: "8px", md: "10px" },
    // mb: 2,
    backgroundColor: { xs: bgColor3, md: whiteColor },
  },

  img: {
    width: "35px",
    height: "35px",
    border: border25,
    borderRadius: "7px",
    objectFit: "contain",
  },

  labelText: {
    fontSize: 11.5,
    fontWeight: 400,
    color: bgColor15,
  },

  infoText: {
    fontSize: 11.7,
    fontWeight: { xs: 500, md: 600 },
    color: blackShade19,
    lineHeight: "18px",
    ml: { xs: 0, md: 1 },
  },

  labelTextMobile: {
    display: { xs: "block", md: "none" },
    fontSize: 11,
    fontWeight: 400,
    color: bgColor15,
    lineHeight: "20px",
  },
};
