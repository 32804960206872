import React from "react";
import BusinessTimingPreviewCard from "./BusinessTimingPreviewCard";
import { Box, Typography } from "@mui/material";
import DeliveryMethodsPreviewCard from "./DeliveryMethodsPreviewCard";
import DiscountPreviewCard from "./DiscountPreviewCard";
import BankDetailsPreviewCard from "./BankDetailsPreviewCard";
import OtherDetailsPreviewCard from "./OtherDetailsPreviewCard";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import { blackColor } from "../../../configs/styles/muiThemes";
import { stickyHeader } from "../../../utils/styles";

function CommerceInfoPreview({ data, onSubmit, onBack, handleCancel }) {
  const {
    businessTiming,
    addressInfo,
    deliveryMethods,
    discountInfo,
    otherDetails,
  } = data;
  const {
    costBoDelivery,
    costBoHyperlocal,
    isCOD,
    cod,
    fullfillment,
    fullfillmentPricing,
    b2bDelivery,
    storePickup,
  } = deliveryMethods;
  return (
    <Box pb={1.2}>
      <Typography
        component="h6"
        fontSize={{ xs: 15, md: 17 }}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        pb={"12px !important"}
        sx={stickyHeader}
      >
        E-Commerce Details
      </Typography>
      <BusinessTimingPreviewCard data={businessTiming} />
      <DeliveryMethodsPreviewCard
        costBoDelivery={costBoDelivery}
        costBoHyperlocal={costBoHyperlocal}
        isCOD={isCOD}
        cod={cod}
        fullfillment={fullfillment}
        fullfillmentPricing={fullfillmentPricing}
        b2bDelivery={b2bDelivery}
        storePickup={storePickup}
      />
      <DiscountPreviewCard data={discountInfo} />
      {/* <BankDetailsPreviewCard /> */}
      <OtherDetailsPreviewCard data={otherDetails} />

      <Box mt="20px">
        <ActionButtons
          backBtnText="Previous"
          saveBtnText="SUBMIT"
          onBackClick={onBack}
          onSaveClick={onSubmit}
          onCancelClick={handleCancel}
          showBackBtn
          hideNextBtn
        />
      </Box>
    </Box>
  );
}

export default CommerceInfoPreview;
