import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { blackColor, blackShade05, displaySwitchGreenColor, displaySwitchRedColor, logoImgborder1, whiteColor } from "../../../configs/styles/muiThemes";
import { addThousandsSeparator } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";

function MobileTotalSalesOndcCard({ orderSummary,commerceInfo }) {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      flexDirection={{ xs: "row", md: "row" }}
      alignItems={{ xs: "none", md: "center" }}
      justifyContent={"space-between"}
      sx={styles.orderCard}
    >
      <Box display={"flex"} gap={1}>
        <Box sx={styles.iconContainer(whiteColor)}>
          <Box
            component="img"
            alt="Total Sales"
            src={
              "https://storage.googleapis.com/bodefaults/businessdashboardweb/total_sales.svg"
            }
            width="auto"
            height="128px"
          />
        </Box>
        <Stack>
          <Typography
            variant="body2"
            fontSize={{ xs: 11, md: 11.28 }}
            color={blackShade05}
            fontWeight={500}
            lineHeight="20px"
          >
            Total Sales
          </Typography>
          {console.log("orderSummary", orderSummary)}
          <Typography
            component="h6"
            fontSize={{ xs: 15, md: 15 }}
            color={blackColor}
            fontWeight={600}
            lineHeight="16px"
          >
            ₹ {addThousandsSeparator(orderSummary?.totalAmount) || 0}
          </Typography>
        </Stack>
      </Box>
      <Stack alignItems={"end"}>
        <Typography
          variant="body2"
          fontSize={{ xs: 11, md: 11.28 }}
          color={blackShade05}
          fontWeight={500}
          lineHeight="20px"
        >
          ONDC Enabled
        </Typography>
        {console.log("orderSummary", orderSummary)}
        <Typography
          component="body2"
          fontSize={13}
          color={
            commerceInfo?.info?.ondcEnabled?.toLowerCase() == "yes"
              ? displaySwitchGreenColor
              : displaySwitchRedColor
          }
          fontWeight={500}
          lineHeight="20px"
          // display={{ xs: "block", md: "none" }}
          sx={{ textDecoration: "underline" }}
          onClick={() => {
            //   handleNavigattion(
            //     `/${sessionStorage.getItem("costboWEBURL")}${"/dashboard"}`
            //   );
            console.log("count0ne", "hitt");
            navigate(
              `/${sessionStorage.getItem("costboWEBURL")}${"/ONDCControls"}`
            );
          }}
        >
          {commerceInfo?.info?.ondcEnabled?.toLowerCase() == "yes"
            ? "Yes"
            : "No"}
        </Typography>
      </Stack>
    </Box>
  );
}

export default MobileTotalSalesOndcCard;
const styles = {
  orderCard: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px", md: "12px" },
  },
  iconContainer: (bgColor, hideMobile) => ({
    width: { md: "40px", xs: "42px" },
    height: { md: "40px", xs: "42px" },
    borderRadius: "50%",
    backgroundColor: bgColor,
    display: { md: "flex", xs: hideMobile ? "none" : "flex" },
    alignItems: "center",
    justifyContent: "center",
  }),
};