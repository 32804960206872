import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
  deleteBulkProductApi,
  getBulkUploadProductsDetailsAPI,
  importBulkProductApi,
  updateBulkProductApi,
} from "../../service/api_calls/CatalogBulkUpload/CatalogBulkUpload";
import {
  getProductSubCategoryOneAPI,
  getProductSubCategoryTwoAPI,
} from "../../service/api_calls/AddProduct/AddProductAPI";
import {
  checkObjectHasValue,
  convertDateFormat,
} from "../../utils/validationUtils";
import {
  getTimeToShipment,
  prepareProductONDCInfo,
  prepareProductPhotoData,
  validateOndcProductData,
  validateReviewProductData,
} from "./catalogBulkValidationHelper";
import {
  getSelectedCountryCodeByName,
  removeNewlines,
} from "../../utils/helper";

const initialState = {
  bulkProductDetails: null,
  bulkProductDetailsLoader: false,
  selectedRowCount: 0,

  isAssignCatError: true,
  isReviewError: true,

  productDeleted: null,
  productPublished: null,

  productCatLoader: false,
  productSubCatLoader: false,
  ONDCCatLoader: false,
  deleteProductLoader: false,
  updateLoader: false,
};

// get All Uploaded Products
export const getAllBulkProductData = createAsyncThunk(
  "getAllBulkProductData",
  async (param, thunkAPI) => {
    const res = await getBulkUploadProductsDetailsAPI(param);

    const productList = [...res];

    console.log("GET_BULK_Before:", productList);

    const allProducts = productList?.map((productInfo) => {
      const ondcInfo = productInfo?.ondc || null;
      // Product Attributes
      const productCodeKey = ondcInfo?.productCode?.key || "";
      const productCodeValue =
        typeof ondcInfo?.productCode?.value === "string"
          ? removeNewlines(ondcInfo?.productCode?.value)
          : ondcInfo?.productCode?.value || "";

      const unitOfmeasurement =
        productInfo?.productDisplayUnitOfMeasure?.unit?.trim() || "";
      const unitOfmeasurementValue =
        productInfo?.productDisplayUnitOfMeasure?.value || "";

      // ONDC Values
      const foodTypeData = ondcInfo?.veg_nonveg || null;

      let selectedValue = null;

      console.log("GET_BULK_foodTypeData:", foodTypeData);

      if (foodTypeData) {
        Object.keys(foodTypeData)?.forEach((item) => {
          if (
            foodTypeData[item] &&
            foodTypeData[item]?.toLowerCase() === "yes"
          ) {
            if (!selectedValue) {
              selectedValue =
                item === "veg" ? "Veg" : item === "nonVeg" ? "Non Veg" : "Egg";
            }
          }
        });
      }

      const shelfLife = ondcInfo?.shelfLife;
      const fssaiNo = ondcInfo?.fssai?.[0]?.number || "";
      const fssaiValidTill = ondcInfo?.fssai?.[0]?.validTill
        ? convertDateFormat(ondcInfo?.fssai?.[0]?.validTill)
        : "";

      const isFoodRelatedItems =
        shelfLife || fssaiNo || fssaiValidTill ? "YES" : "NO";

      const packagedInfo = ondcInfo?.statutoryReqsPackagedCommodities || null;
      const packaged = checkObjectHasValue(packagedInfo);

      const prePackagedInfo = ondcInfo?.statutoryReqsPrepackagedFood || null;
      const prePackaged = checkObjectHasValue(prePackagedInfo);

      const shipmentTime = getTimeToShipment(ondcInfo?.timeToShip);

      console.log("GET_BULK_Inside:");

      return {
        ...productInfo,
        productCodeKey,
        productCodeValue,
        unitOfmeasurement,
        unitOfmeasurementValue,
        // measurementInfo: {
        //   ...productInfo?.measurementInfo,
        //   weight: productInfo?.netProductWeight || 0,
        // },
        // netProductWeight: productInfo?.measurementInfo?.weight || 0,

        ondc: {
          ...productInfo?.ondc,
          foodType: selectedValue || "Select Value",
          fssaiNo,
          fssaiValidTill,
          foodRelatedItems: isFoodRelatedItems,
          isPackaged: packaged ? "YES" : "NO",
          isPrePackaged: prePackaged ? "YES" : "NO",
          shipmentTime: shipmentTime?.timeData,
          timeToShipUnit: { label: shipmentTime?.timeUnit },
          returnPeriodDays:
            ondcInfo?.returnTerms?.returnPeriod?.replace(/[a-zA-Z]/g, "") ||
            "0",
        },
      };
    });

    console.log("GET_BULK:", allProducts);

    return allProducts;
  }
);

// update business category and get product category list
export const updateBusinessCatGetProductCat = createAsyncThunk(
  "updateBusinessCatGetProductCat",
  async (param, thunkAPI) => {
    const { categoryData, productDetails, index } = param;

    try {
      const productCatList = await getProductSubCategoryOneAPI(
        categoryData?.code
      );

      if (!productCatList) {
        return productDetails;
      }

      const allProducts = [...productDetails];
      const productInfo = allProducts[index] || null;

      if (!productInfo) {
        return productDetails;
      }

      const updatedProductInfo = {
        ...productInfo,
        productMainCategory: [
          {
            code: categoryData?.code || null,
            value: categoryData?.name || null,
            status: "active",
            ondcCode: null,
            ondcValue: null,
          },
        ],
        productSubCategory: [],
        productSubCategory2: [],
        productCategoryList: productCatList.details,
        errors: productInfo?.errors
          ? { ...productInfo?.errors, productMainCategory: "" }
          : {},
      };

      allProducts[index] = updatedProductInfo;

      return allProducts;
    } catch (error) {
      console.error("updateBusinessCatGetProductCat error:", error);
      throw error;
    }
  }
);

// update product category and get product sub-category list
export const updateProductCatGetProductSubCat = createAsyncThunk(
  "updateProductCatGetProductSubCat",
  async (param, thunkAPI) => {
    const { categoryData, productDetails, index } = param;

    try {
      const productSubCatList = await getProductSubCategoryTwoAPI({
        pcCode: categoryData?.pcCode,
        pscCode1: categoryData?.scCode1,
      });

      if (!productSubCatList) {
        return productDetails;
      }

      const allProducts = [...productDetails];
      const productInfo = allProducts[index] || null;

      if (!productInfo) {
        return productDetails;
      }

      const updatedProductInfo = {
        ...productInfo,
        productSubCategory: [
          {
            code: categoryData?.pscCode1,
            value: categoryData?.productSubCategory1,
            pcCode: categoryData?.pcCode,
            status: "active",
            pscCode1: categoryData?.scCode1,
            ondcSubCode: null,
            ondcSubValue: null,
          },
        ],
        productSubCategory2: [],
        productSubCategoryList: productSubCatList.details,
        errors: productInfo?.errors
          ? { ...productInfo?.errors, productSubCategory: "" }
          : {},
      };

      allProducts[index] = updatedProductInfo;

      return allProducts;
    } catch (error) {
      console.error("updateBusinessCatGetProductCat error:", error);
      throw error;
    }
  }
);

// update business category and get product category list
export const updateONDCCatGetONDCSubCat = createAsyncThunk(
  "updateONDCCatGetONDCSubCat",
  async (param, thunkAPI) => {
    const { categoryData, productDetails, index } = param;

    try {
      const ondcSubCatList = await getProductSubCategoryOneAPI(
        categoryData?.code
      );

      if (!ondcSubCatList) {
        return productDetails;
      }

      const allProducts = [...productDetails];
      const productInfo = allProducts[index] || null;

      if (!productInfo) {
        return productDetails;
      }

      const updatedProductInfo = {
        ...productInfo,
        selectedOndcCategory: categoryData,
        selectedOndcSubCategory: null,
        ONDCSubCategoryList: ondcSubCatList.details,
        errors: productInfo?.errors
          ? { ...productInfo?.errors, selectedOndcCategory: "" }
          : {},
      };

      allProducts[index] = updatedProductInfo;

      return allProducts;
    } catch (error) {
      console.error("updateONDCCatGetONDCSubCat error:", error);
      throw error;
    }
  }
);

// update category data
export const updateCategoryData = createAsyncThunk(
  "updateCategoryData",
  async (param, thunkAPI) => {
    const {
      productDetails,
      selectedBusinessCat,
      selectedProductCat,
      selectedProductSubCat,
      selectedOndcCat,
      selectedOndcSubCat,
      businessId,
      ondcSpecification,
    } = param;

    const updateData = getUpdatedProductDetails({
      productDetails,
      selectedBusinessCat,
      selectedProductCat,
      selectedProductSubCat,
      selectedOndcCat,
      selectedOndcSubCat,
      ondcSpecification,
    });

    console.log("updateData:", updateData);

    const updatePostData = [];

    updateData?.forEach((product) => {
      if (product?.isSelected) {
        const data = {
          id: product?.id,
          productName: removeNewlines(product?.productName),
          productMainCategory: product?.productMainCategory,
          productSubCategory: product?.productSubCategory,
          productSubCategory2: product?.productSubCategory2,
        };
        updatePostData.push(data);
      }
    });

    console.log("updatePostData:", updatePostData);

    const updateRes = await updateBulkProductApi({
      businessId,
      postData: updatePostData,
    });
    console.log("updatePostData_updateRes:", updateRes);

    return updateData;
  }
);

const getUpdatedProductDetails = (data) => {
  const {
    productDetails,
    selectedBusinessCat,
    selectedProductCat,
    selectedProductSubCat,
    selectedOndcCat,
    selectedOndcSubCat,
    ondcSpecification,
  } = data;

  let allProducts = [...productDetails];

  productDetails.forEach((product, index) => {
    if (product?.isSelected) {
      let businessCatData = {
        code: null,
        value: null,
        status: null,
        ondcCode: null,
        ondcValue: null,
      };

      if (selectedBusinessCat) {
        businessCatData = {
          ...businessCatData,
          code: selectedBusinessCat?.code || null,
          value: selectedBusinessCat?.name || null,
          status: "active",
        };
      }

      if (selectedOndcCat) {
        businessCatData = {
          ...businessCatData,
          ondcCode: selectedOndcCat?.code || null,
          ondcValue: selectedOndcCat?.name || null,
        };
      }

      let productCatData = {
        code: null,
        value: null,
        pcCode: null,
        status: null,
        pscCode1: null,
        ondcSubCode: null,
        ondcSubValue: null,
      };

      if (selectedProductCat) {
        productCatData = {
          ...productCatData,
          code: selectedProductCat?.pscCode1 || null,
          value: selectedProductCat?.productSubCategory1 || null,
          pcCode: selectedProductCat?.pcCode || null,
          status: "active",
          pscCode1: selectedProductCat?.scCode1 || null,
        };
      }

      if (selectedOndcSubCat) {
        productCatData = {
          ...productCatData,
          ondcSubCode: selectedOndcSubCat?.scCode1 || null,
          ondcSubValue: selectedOndcSubCat?.productSubCategory1 || null,
        };
      }

      let productSubCatData = {
        code: null,
        value: null,
        pcCode: null,
        psCode: null,
        status: null,
        pscCode2: null,
      };

      if (selectedProductSubCat) {
        productSubCatData = {
          ...productSubCatData,
          code: selectedProductSubCat?.pscCode2 || null,
          value: selectedProductSubCat?.productSubCategory2 || null,
          pcCode: selectedProductSubCat?.pcCode || null,
          psCode:
            selectedProductSubCat?.pcCode + selectedProductSubCat?.scCode1,
          status: "active",
          pscCode2: "",
        };
      }

      allProducts[index] = {
        ...allProducts[index],
        productMainCategory: [businessCatData],
        productSubCategory: [productCatData],
        productSubCategory2: productSubCatData?.value
          ? [productSubCatData]
          : [],
        ondcSpecification: ondcSpecification,
        errors: null,
      };
    }
  });

  const finalData = allProducts?.map((item) => {
    const data = { ...item };
    delete data.errors;
    return data;
  });

  return finalData;
};

// update selected product details data
export const updateBulkProductDetails = createAsyncThunk(
  "updateBulkProductDetails",
  async (param, thunkAPI) => {
    const { productDetails, businessId, unitOfMeasurementData } = param;

    const allProducts = [...productDetails];
    console.log("updateBulkProductDetails_allProducts:", allProducts);

    const validationResult = validateReviewProductData(allProducts, unitOfMeasurementData);
    console.log("updateBulkProductDetails_validationResult:", validationResult);

    if (!validationResult?.isError) {
      // Update
      const selectedProducts = allProducts.filter((item) => item?.isSelected);
      console.log(
        "updateBulkProductDetails_selectedProducts:",
        selectedProducts
      );

      const updatePostData = [];

      selectedProducts?.forEach((product) => {
        if (product?.isSelected) {
          const productPhoto = prepareProductPhotoData(
            product?.productPhotoUrl || []
          );

          const data = {
            id: product?.id,
            productDescription: product?.productDescription || "",
            skuId: product?.skuId || "",
            brand: product?.brand || "",
            origin: product?.origin || "",
            productPhotoUrl: productPhoto,
            gstInfo: product?.gstInfo,
            productOriginalPrice: Number(product?.productOriginalPrice),
            productSalePrice: Number(product?.productSalePrice),
            percentageOff: Number(
              (
                100 -
                (Number(product?.productSalePrice) /
                  Number(product?.productOriginalPrice)) *
                  100
              ).toFixed(2)
            ),
            minOrderQuantity: Number(product?.minOrderQuantity),
            maxOrderQuantity: Number(product?.maxOrderQuantity),
            availableQuantity: Number(product?.availableQuantity),
            alertQuantity:
              Number(product?.alertQuantity) ||
              Number(product?.maxOrderQuantity) * 3,
            // measurementInfo: {
            //   ...product?.measurementInfo,
            //   weight: Number(product?.netProductWeight) || 0,
            // },
            // netProductWeight: product?.measurementInfo?.weight,
            measurementInfo: product?.measurementInfo,
            netProductWeight: Number(product?.netProductWeight) || 0,
            label: product?.label || [{ code: "", value: [] }],
            refLink: product?.refLink || [{ code: "", link: "" }],

            productDisplayUnitOfMeasure: {
              unit: product?.unitOfmeasurement?.toLowerCase() || "",
              value: product?.unitOfmeasurementValue || "",
            },
            ondc: prepareProductONDCInfo(product),
          };
          updatePostData.push(data);
        }
      });

      console.log("updatePostData:", updatePostData);

      const updateRes = await updateBulkProductApi({
        businessId,
        postData: updatePostData,
      });
      console.log("updatePostData_updateRes:", updateRes);

      const selectedRows = productDetails?.filter((item) => item?.isSelected);
      return {
        products: validationResult?.allProducts,
        isError: false,
        selectedRowCount: selectedRows?.length || 0,
      };
    } else {
      const selectedRows = productDetails?.filter((item) => item?.isSelected);

      return {
        products: validationResult?.allProducts,
        isError: true,
        selectedRowCount: selectedRows?.length || 0,
      };
    }
  }
);

// update selected product ondc details
export const updateBulkOndcProductDetails = createAsyncThunk(
  "updateBulkOndcProductDetails",
  async (param, thunkAPI) => {
    const { productDetails, businessId, countryList, isRemoveONDCSpecs } =
      param;

    const allProducts = [...productDetails];

    const validationResult = validateOndcProductData(
      allProducts,
      isRemoveONDCSpecs
    );
    console.log(
      "updateBulkOndcProductDetails_validationResult:",
      validationResult
    );

    if (!validationResult?.isError) {
      // Update
      const selectedProducts = allProducts.filter((item) => item?.isSelected);
      console.log(
        "updateBulkOndcProductDetails_selectedProducts:",
        selectedProducts
      );

      const updatePostData = [];

      selectedProducts?.forEach((product) => {
        if (product?.isSelected) {
          const data = {
            id: product?.id,
            // origin: getSelectedCountryCodeByName(product?.origin, countryList),
            ondcEnabled: product?.ondcEnabled?.toLowerCase() || "no",
            ondc: prepareProductONDCInfo(product),
          };
          updatePostData.push(data);
        }
      });

      console.log("updatePostData:", updatePostData);

      const updateRes = await updateBulkProductApi({
        businessId,
        postData: updatePostData,
      });
      console.log("updatePostData_updateRes:", updateRes);

      const selectedRows = productDetails?.filter((item) => item?.isSelected);
      return {
        products: validationResult?.allProducts,
        isError: false,
        selectedRowCount: selectedRows?.length || 0,
      };

      // Publish the products

      // const publistProductIds = updatePostData.map((item) => {
      //   return item.id;
      // });

      // const postData = {
      //   uploadId: "",
      //   ids: publistProductIds,
      // };

      // console.log("updatePostData_postData:", postData);

      // const publishRes = await importBulkProductApi({ postData, businessId });
      // console.log("updatePostData_postData:", publishRes);

      // if (publishRes) {
      //   const productsAfterUpdate = await getBulkUploadProductsDetailsAPI({
      //     businessId,
      //   });
      //   console.log("updatePostData_productsAfterUpdate:", productsAfterUpdate);

      //   return {
      //     products: productsAfterUpdate,
      //     isError: false,
      //     selectedRowCount: 0,
      //     publishedProducts: {
      //       ids: publistProductIds,
      //     },
      //   };
      // } else {
      //   const selectedRows = productDetails?.filter((item) => item?.isSelected);
      //   return {
      //     products: validationResult?.allProducts,
      //     isError: false,
      //     selectedRowCount: selectedRows?.length || 0,
      //   };
      // }
    } else {
      const selectedRows = productDetails?.filter((item) => item?.isSelected);

      return {
        products: validationResult?.allProducts,
        isError: true,
        selectedRowCount: selectedRows?.length || 0,
      };
    }
  }
);

// delete selected products
export const deleteSelectedProducts = createAsyncThunk(
  "deleteSelectedProducts",
  async (param, thunkAPI) => {
    const { productDetails, businessId } = param;

    const selectedProducts = productDetails?.filter((item) => item?.isSelected);
    const unSelectedProducts = productDetails?.filter(
      (item) => !item?.isSelected
    );

    console.log("selectedProducts", selectedProducts);
    console.log("unSelectedProducts", unSelectedProducts);

    const productIdsToDelete = selectedProducts?.map((item) => item?.id);

    const postData = {
      uploadId: "",
      ids: productIdsToDelete,
    };

    console.log("postData", postData);

    const res = await deleteBulkProductApi({ postData, businessId });

    console.log("deleteRes", res);

    return { products: unSelectedProducts, productIdsToDelete };
  }
);

// delete single products
export const deleteSingleProducts = createAsyncThunk(
  "deleteSingleProducts",
  async (param, thunkAPI) => {
    const { productDetails, businessId, productIndex } = param;

    const allProducts = [...productDetails];
    const productToDelete = allProducts[productIndex];

    const unSelectedProducts = [
      ...allProducts.slice(0, productIndex),
      ...allProducts.slice(productIndex + 1),
    ];

    console.log("selectedProducts", productToDelete);
    console.log("unSelectedProducts", unSelectedProducts);

    const productIdsToDelete = [productToDelete?.id];

    const postData = {
      uploadId: "",
      ids: productIdsToDelete,
    };

    console.log("postData", postData);

    const res = await deleteBulkProductApi({ postData, businessId });
    console.log("deleteRes", res);

    return { products: unSelectedProducts, productIdsToDelete };
  }
);

export const catalogBulkUpload = createSlice({
  name: "catalogBulkUpload",
  initialState,
  reducers: {
    clearBulkProductInfo: (state, action) => {
      state.bulkProductDetails = null;
      state.selectedRowCount = 0;

      state.isAssignCatError = true;
      state.isReviewError = true;

      state.productDeleted = null;
      state.productPublished = null;
    },
    clearAssignCatError: (state, action) => {
      state.isAssignCatError = true;
    },
    clearPublishedProducts: (state, action) => {
      state.productPublished = null;
    },

    // update the selected product sub category
    updateProductSubCat: (state, action) => {
      const { categoryData, productDetails, index } = action.payload;

      const allProducts = [...productDetails];
      const productInfo = allProducts[index] || null;

      if (!productInfo) {
        state.bulkProductDetails = productDetails;
        return;
      }

      const updatedProductInfo = {
        ...productInfo,
        productSubCategory2: [
          {
            code: categoryData?.pscCode2,
            value: categoryData?.productSubCategory2,
            pcCode: categoryData?.pcCode,
            psCode: categoryData?.pcCode + categoryData?.scCode1,
            status: "active",
            pscCode2: "",
          },
        ],
      };

      allProducts[index] = updatedProductInfo;
      console.log("updateProductSubCat_allProducts:", allProducts);

      state.bulkProductDetails = allProducts;
    },

    // update the selected ONDC sub category
    updateOndcSubCat: (state, action) => {
      const { categoryData, productDetails, index } = action.payload;

      const allProducts = [...productDetails];
      const productInfo = allProducts[index] || null;

      if (!productInfo) {
        state.bulkProductDetails = productDetails;
        return;
      }

      const updatedProductInfo = {
        ...productInfo,
        selectedOndcSubCategory: categoryData,
      };

      allProducts[index] = updatedProductInfo;
      console.log("updateOndcSubCat_allProducts:", allProducts);

      state.bulkProductDetails = allProducts;
    },

    // validate assigned categories
    validateCategories: (state, action) => {
      console.log("validateAssignedCategories_productDetails:");
      const { productDetails } = action.payload;

      console.log("validateAssignedCategories_productDetails:", productDetails);

      let allProducts = [...productDetails];

      let isError = false;

      productDetails.forEach((product, index) => {
        let errors = null;

        if (!product.isSelected) return;

        if (!product?.productMainCategory?.[0]?.value) {
          errors = {
            ...errors,
            productMainCategory: "Select business category",
          };
        } else {
          delete errors?.productMainCategory;
          // errors = { ...errors, productMainCategory: "" };
        }

        if (product?.productSubCategory?.length === 0) {
          errors = { ...errors, productSubCategory: "Select product category" };
        } else {
          delete errors?.productSubCategory;
          // errors = { ...errors, productSubCategory: "" };
        }

        if (!product?.productMainCategory?.[0]?.ondcValue) {
          errors = {
            ...errors,
            selectedOndcCategory: "Select ONDC category",
          };
        } else {
          delete errors?.selectedOndcCategory;
          // errors = { ...errors, selectedOndcCategory: "" };
        }

        if (!product?.productSubCategory?.[0]?.ondcSubValue) {
          errors = {
            ...errors,
            selectedOndcSubCategory: "Select ONDC sub category",
          };
        } else {
          delete errors?.selectedOndcSubCategory;
        }

        if (errors) {
          allProducts[index] = {
            ...allProducts[index],
            errors: errors,
          };
          isError = true;
          console.log("validateAssignedCategories_isError_INSIDE:", isError);
        }
      });

      console.log("validateAssignedCategories_Verified:", allProducts);
      console.log("validateAssignedCategories_isError:", isError);

      state.bulkProductDetails = allProducts;
      state.isAssignCatError = isError;
    },

    // validate assigned categories
    validateReviewProducts: (state, action) => {
      const { productDetails } = action.payload;

      console.log("validateReviewProducts_productDetails:", productDetails);

      let allProducts = [...productDetails];

      let isError = false;

      productDetails.forEach((product, index) => {
        if (!product.isSelected) return;

        let errors = null;

        if (!product?.productDescription) {
          errors = {
            ...errors,
            productDescription: "Enter product description",
          };
        } else {
          delete errors?.productDescription;
        }

        if (!product?.origin) {
          errors = {
            ...errors,
            origin: "Select origin",
          };
        } else {
          delete errors?.origin;
        }

        if (
          !product?.productCodeKey ||
          product?.productCodeKey === "Select Product Code"
        ) {
          errors = {
            ...errors,
            productCodeKey: "Select Product Code",
          };
        } else {
          delete errors?.productCodeKey;
        }

        if (!product?.productCodeValue) {
          errors = {
            ...errors,
            productCodeValue: "Enter value",
          };
        } else {
          delete errors?.productCodeValue;
        }

        if (product?.productCodeKey && product?.productCodeValue) {
          if (
            product?.productCodeKey === "GTIN" &&
            product?.productCodeValue?.length !== 12
          ) {
            errors = {
              ...errors,
              productCodeValue: "GTIN should be 12 digits",
            };
          } else if (
            product?.productCodeKey === "HSN" &&
            product?.productCodeValue?.length !== 8
          ) {
            errors = {
              ...errors,
              productCodeValue: "HSN should be 8 digits",
            };
          } else if (
            product?.productCodeKey !== "GTIN" &&
            product?.productCodeKey !== "HSN" &&
            product?.productCodeValue?.length !== 13
          ) {
            errors = {
              ...errors,
              productCodeValue: "Value should be 13 digits",
            };
          } else {
            delete errors?.productCodeValue;
          }
        }

        if (!product?.gstInfo?.code) {
          errors = {
            ...errors,
            gstInfoCode: "Enter GST/HSN code",
          };
        } else {
          delete errors?.gstInfoCode;
        }

        if (!product?.gstInfo?.gstPercentage) {
          errors = {
            ...errors,
            gstPercentage: "Select GST percentage",
          };
        } else {
          delete errors?.gstPercentage;
        }

        if (
          !product?.unitOfmeasurement ||
          product?.unitOfmeasurement === "Select Unit Of Measurement"
        ) {
          errors = {
            ...errors,
            unitOfmeasurement: "Select Unit Of Measurement",
          };
        } else {
          delete errors?.unitOfmeasurement;
        }

        if (!product?.unitOfmeasurementValue) {
          errors = {
            ...errors,
            unitOfmeasurementValue: "Enter unit value",
          };
        } else {
          delete errors?.unitOfmeasurementValue;
        }

        if (!product?.productOriginalPrice) {
          errors = {
            ...errors,
            productOriginalPrice: "Enter original price",
          };
        } else {
          delete errors?.productOriginalPrice;
        }

        if (!product?.productSalePrice) {
          errors = {
            ...errors,
            productSalePrice: "Enter offer price",
          };
        } else {
          delete errors?.productSalePrice;
        }

        if (product?.productSalePrice && product?.productOriginalPrice) {
          if (
            Number(product?.productSalePrice) >
            Number(product?.productOriginalPrice)
          ) {
            errors = {
              ...errors,
              productSalePrice:
                "Offer Price must be less than or equal to Original Price",
            };
          }
        }

        if (!product?.availableQuantity) {
          errors = {
            ...errors,
            availableQuantity: "Enter qty on hand",
          };
        } else {
          delete errors?.availableQuantity;
        }

        if (!product?.alertQuantity) {
          errors = {
            ...errors,
            alertQuantity: "Enter alert qty",
          };
        } else {
          delete errors?.alertQuantity;
        }

        if (!product?.minOrderQuantity) {
          errors = {
            ...errors,
            minOrderQuantity: "Enter minimum qty",
          };
        } else {
          delete errors?.minOrderQuantity;
        }

        if (!product?.maxOrderQuantity) {
          errors = {
            ...errors,
            maxOrderQuantity: "Enter maximum qty",
          };
        } else {
          delete errors?.maxOrderQuantity;
        }

        if (
          product?.availableQuantity &&
          product?.alertQuantity &&
          product?.maxOrderQuantity
        ) {
          if (
            Number(product?.availableQuantity) <=
              Number(product?.alertQuantity) ||
            Number(product?.availableQuantity) <
              Number(product?.maxOrderQuantity)
          ) {
            errors = {
              ...errors,
              availableQuantity:
                "Qty On Hand should be greater than Alert Qty & Max Qty",
            };
          } else {
            delete errors?.availableQuantity;
          }
        }

        if (product?.maxOrderQuantity && product?.minOrderQuantity) {
          if (
            Number(product?.maxOrderQuantity) <
            Number(product?.minOrderQuantity)
          ) {
            errors = {
              ...errors,
              maxOrderQuantity:
                "Max Qty per order should be greater than Min Qty per order",
            };
          } else {
            delete errors?.maxOrderQuantity;
          }
        }

        // if(product?.alertQuantity && product?.minOrderQuantity){
        //   if (Number(product?.alertQuantity) < Number(product?.minOrderQuantity)) {
        //     errors = {
        //       ...errors,
        //       alertQuantity: "Alert Qty must be more than Min Qty per order",
        //     };
        //   }else{
        //     delete errors?.alertQuantity
        //   }
        // }

        if (!product?.measurementInfo?.weight) {
          errors = {
            ...errors,
            weight: "Enter product weight",
          };
        } else {
          delete errors?.weight;
        }

        if (!product?.netProductWeight || product?.netProductWeight === "") {
          errors = {
            ...errors,
            netProductWeight: "Enter net product weight",
          };
        } else {
          delete errors?.netProductWeight;
        }

        if (
          product?.netProductWeight !== "" &&
          product?.netProductWeight <= product?.measurementInfo?.weight
        ) {
          errors = {
            ...errors,
            netProductWeight:
              "Net Product Weight should be more than Product Weight",
          };
        } else {
          delete errors?.netProductWeight;
        }

        if (errors) {
          allProducts[index] = {
            ...allProducts[index],
            errors: errors,
          };
          isError = true;
          console.log("validateReviewProducts_isError_INSIDE:", isError);
          state.bulkProductDetails = allProducts;
        } else {
          allProducts[index] = {
            ...allProducts[index],
            errors: null,
          };
        }
      });

      console.log("validateReviewProducts_Verified:", allProducts);
      console.log("validateReviewProducts_isError:", isError);

      state.bulkProductDetails = allProducts;
      state.isReviewError = isError;
    },

    // validate ondc parameters
    validateOndcParameters: (state, action) => {
      const { productDetails } = action.payload;

      console.log("validateOndcParameters_productDetails:", productDetails);

      let allProducts = [...productDetails];

      let isError = false;

      productDetails.forEach((product, index) => {
        if (!product.isSelected) return;

        let errors = null;

        if (product?.ondcEnabled?.toUpperCase() === "YES") {
          if (
            !product?.ondc?.shipmentTime ||
            product?.ondc?.shipmentTime == "0"
          ) {
            errors = { ...errors, timeToShip: "Enter time to ship" };
          } else {
            delete errors?.timeToShip;
          }

          // CancelTerms
          if (
            product?.ondc?.cancelTerms?.cancellable?.toUpperCase() === "YES"
          ) {
            if (!product?.ondc?.cancelTerms?.cancellationFeeAmount) {
              errors = {
                ...errors,
                cancellationFeeAmount: "Enter cancellation fee amount",
              };
            } else {
              delete errors?.cancellationFeeAmount;
            }

            if (!product?.ondc?.cancelTerms?.cancellationFeePercentage) {
              errors = {
                ...errors,
                cancellationFeePercentage: "Enter cancellation fee percentage",
              };
            } else {
              delete errors?.cancellationFeePercentage;
            }
          }

          // return terms
          if (product?.ondc?.returnTerms?.returnable?.toUpperCase() === "YES") {
            if (
              !product?.ondc?.returnPeriodDays ||
              product?.ondc?.returnPeriodDays == "0"
            ) {
              errors = { ...errors, returnPeriod: "Enter return period" };
            } else {
              delete errors?.returnPeriod;
            }
          }

          // food related items
          if (product?.ondc?.foodRelatedItems?.toUpperCase() === "YES") {
            if (!product?.ondc?.shelfLife || product?.ondc?.shelfLife == "0") {
              errors = { ...errors, shelfLife: "Enter shelfLife" };
            } else {
              delete errors?.shelfLife;
            }

            if (
              !product?.ondc?.foodType ||
              product?.ondc?.foodType == "Select Value"
            ) {
              errors = { ...errors, foodType: "Select value" };
            } else {
              delete errors?.foodType;
            }

            if (product?.ondc?.fssaiNo?.length !== 14) {
              errors = { ...errors, fssaiNo: "Enter 14 digit fssai number" };
            } else {
              delete errors?.fssaiNo;
            }

            if (!product?.ondc?.fssaiValidTill) {
              errors = {
                ...errors,
                fssaiValidTill: "Enter fssai valid till date",
              };
            } else {
              delete errors?.fssaiValidTill;
            }
          }

          // packaged food
          if (product?.ondc?.isPackaged?.toUpperCase() === "YES") {
            if (
              !product?.ondc?.statutoryReqsPackagedCommodities
                ?.manufacturer_or_packer_name
            ) {
              errors = {
                ...errors,
                manufacturer_or_packer_name:
                  "Enter manufacturer or packer name",
              };
            } else {
              delete errors?.manufacturer_or_packer_name;
            }

            if (
              !product?.ondc?.statutoryReqsPackagedCommodities?.mfg_license_no
            ) {
              errors = {
                ...errors,
                mfg_license_no: "Enter manufacturer license number",
              };
            } else {
              delete errors?.mfg_license_no;
            }

            if (
              !product?.ondc?.statutoryReqsPackagedCommodities
                ?.manufacturer_or_packer_address
            ) {
              errors = {
                ...errors,
                manufacturer_or_packer_address: "Enter address",
              };
            } else {
              delete errors?.manufacturer_or_packer_address;
            }

            if (
              !product?.ondc?.statutoryReqsPackagedCommodities
                ?.common_or_generic_name_of_commodity
            ) {
              errors = {
                ...errors,
                common_or_generic_name_of_commodity:
                  "Enter product common/generic name",
              };
            } else {
              delete errors?.common_or_generic_name_of_commodity;
            }

            if (
              !product?.ondc?.statutoryReqsPackagedCommodities
                ?.month_year_of_manufacture_packing_import
            ) {
              errors = {
                ...errors,
                month_year_of_manufacture_packing_import: "Enter import date",
              };
            } else {
              delete errors?.month_year_of_manufacture_packing_import;
            }

            if (!product?.ondc?.statutoryReqsPackagedCommodities?.expiry_date) {
              errors = { ...errors, expiry_date: "Enter expiry date" };
            } else {
              delete errors?.expiry_date;
            }

            if (
              !product?.ondc?.statutoryReqsPackagedCommodities
                ?.net_quantity_or_measure_of_commodity_in_pkg
            ) {
              errors = {
                ...errors,
                net_quantity_or_measure_of_commodity_in_pkg:
                  "Enter net quantity",
              };
            } else {
              delete errors?.net_quantity_or_measure_of_commodity_in_pkg;
            }

            if (
              !product?.ondc?.statutoryReqsPackagedCommodities
                ?.multiple_products_name_number_or_qty
            ) {
              errors = {
                ...errors,
                multiple_products_name_number_or_qty: "Enter multiple products",
              };
            } else {
              delete errors?.multiple_products_name_number_or_qty;
            }
          }

          // pre packaged food
          if (product?.ondc?.isPrePackaged?.toUpperCase() === "YES") {
            if (
              product?.ondc?.statutoryReqsPrepackagedFood
                ?.brand_owner_FSSAI_license_no?.length !== 14
            ) {
              errors = {
                ...errors,
                brand_owner_FSSAI_license_no: "Enter 14 digit fssai number",
              };
            } else {
              delete errors?.brand_owner_FSSAI_license_no;
            }

            if (
              product?.ondc?.statutoryReqsPrepackagedFood
                ?.other_FSSAI_license_no?.length !== 14
            ) {
              errors = {
                ...errors,
                other_FSSAI_license_no: "Enter 14 digit fssai number",
              };
            } else {
              delete errors?.other_FSSAI_license_no;
            }

            if (
              product?.ondc?.statutoryReqsPrepackagedFood
                ?.importer_FSSAI_license_no?.length !== 14
            ) {
              errors = {
                ...errors,
                importer_FSSAI_license_no: "Enter 14 digit fssai number",
              };
            } else {
              delete errors?.importer_FSSAI_license_no;
            }

            if (
              !product?.ondc?.statutoryReqsPrepackagedFood?.ingredients_info
            ) {
              errors = {
                ...errors,
                ingredients_info: "Enter ingredients info",
              };
            } else {
              delete errors?.ingredients_info;
            }

            if (
              !product?.ondc?.statutoryReqsPrepackagedFood?.nutritional_info
            ) {
              errors = {
                ...errors,
                nutritional_info: "Enter nutritional info",
              };
            } else {
              delete errors?.nutritional_info;
            }

            if (!product?.ondc?.statutoryReqsPrepackagedFood?.additives_info) {
              errors = { ...errors, additives_info: "Enter additives info" };
            } else {
              delete errors?.additives_info;
            }
          }
        }

        if (errors) {
          allProducts[index] = {
            ...allProducts[index],
            ondcErrors: errors,
          };
          isError = true;
          console.log("validateOndcParameters_isError_INSIDE:", isError);
          state.bulkProductDetails = allProducts;
        } else {
          allProducts[index] = {
            ...allProducts[index],
            ondcErrors: null,
          };
        }
      });

      console.log("validateOndcParameters_Verified:", allProducts);
      console.log("validateOndcParameters_isError:", isError);

      state.bulkProductDetails = allProducts;
      state.isReviewError = isError;
    },

    // select all products
    selectAllProductRows: (state, action) => {
      const { productDetails, isSelected } = action.payload;
      const allProducts = productDetails?.map((item) => ({
        ...item,
        isSelected: isSelected ? true : false,
      }));

      const selectedCount = allProducts?.filter(
        (item) => item?.isSelected
      ).length;

      state.bulkProductDetails = allProducts;
      state.selectedRowCount = selectedCount;
    },

    // unselect all products
    unselectAllProductRows: (state, action) => {
      const { productDetails } = action.payload;
      const allProducts = productDetails?.map((item) => ({
        ...item,
        isSelected: false,
      }));

      const selectedCount = allProducts?.filter(
        (item) => item?.isSelected
      ).length;

      state.bulkProductDetails = allProducts;
      state.selectedRowCount = selectedCount;
    },

    // select product row
    toggleProductRowSelect: (state, action) => {
      const { productDetails, index, isSelected } = action.payload;

      console.log("toggleProductRowSelect:", productDetails, index, isSelected);
      const allProducts = [...productDetails];
      const productInfo = allProducts[index] || null;

      console.log("SelectedProduct:", productInfo);

      if (!productInfo) {
        state.bulkProductDetails = productDetails;
        return;
      }

      const updatedProductInfo = {
        ...productInfo,
        isSelected: !isSelected,
      };

      allProducts[index] = updatedProductInfo;
      console.log("toggleProductRowSelect_allProducts:", allProducts);

      const selectedCount = allProducts?.filter(
        (item) => item?.isSelected
      ).length;

      state.bulkProductDetails = allProducts;
      state.selectedRowCount = selectedCount;
    },

    // bulk Category Update
    bulkCategoryUpdate: (state, action) => {
      console.log("bulkCategoryUpdate_productDetails:");
      const {
        productDetails,
        selectedBusinessCat,
        selectedProductCat,
        selectedProductSubCat,
        selectedOndcCat,
        productCatList,
        productSubCatList,
        selectedOndcSubCat,
        ondcSubCatList,
      } = action.payload;

      console.log("bulkCategoryUpdate_productDetails:", productDetails);
      console.log(
        "bulkCategoryUpdate_selectedBusinessCat:",
        selectedBusinessCat
      );
      console.log("bulkCategoryUpdate_selectedProductCat:", selectedProductCat);
      console.log("bulkCategoryUpdate_selectedOndcCat:", selectedOndcCat);

      let allProducts = [...productDetails];

      productDetails.forEach((product, index) => {
        if (product?.isSelected) {
          let businessCatData = {
            code: null,
            value: null,
            status: null,
            ondcCode: null,
            ondcValue: null,
          };

          if (selectedBusinessCat) {
            businessCatData = {
              ...businessCatData,
              code: selectedBusinessCat?.code || null,
              value: selectedBusinessCat?.name || null,
              status: "active",
            };
          }

          if (selectedOndcCat) {
            businessCatData = {
              ...businessCatData,
              ondcCode: selectedOndcCat?.code || null,
              ondcValue: selectedOndcCat?.name || null,
            };
          }

          let productCatData = {
            code: null,
            value: null,
            pcCode: null,
            status: null,
            pscCode1: null,
            ondcSubCode: null,
            ondcSubValue: null,
          };

          if (selectedProductCat) {
            productCatData = {
              ...productCatData,
              code: selectedProductCat?.pscCode1 || null,
              value: selectedProductCat?.productSubCategory1 || null,
              pcCode: selectedProductCat?.pcCode || null,
              status: "active",
              pscCode1: selectedProductCat?.scCode1 || null,
            };
          }

          if (selectedOndcSubCat) {
            productCatData = {
              ...productCatData,
              ondcSubCode: selectedOndcSubCat?.scCode1 || null,
              ondcSubValue: selectedOndcSubCat?.productSubCategory1 || null,
            };
          }

          let productSubCatData = {
            code: null,
            value: null,
            pcCode: null,
            psCode: null,
            status: null,
            pscCode2: null,
          };

          if (selectedProductSubCat) {
            productSubCatData = {
              ...productSubCatData,
              code: selectedProductSubCat?.pscCode2 || null,
              value: selectedProductSubCat?.productSubCategory2 || null,
              pcCode: selectedProductSubCat?.pcCode || null,
              psCode:
                selectedProductSubCat?.pcCode + selectedProductSubCat?.scCode1,
              status: "active",
              pscCode2: "",
            };
          }

          allProducts[index] = {
            ...allProducts[index],
            // productMainCategory: selectedBusinessCat
            //   ? [
            //       {
            //         code: selectedBusinessCat?.code || null,
            //         value: selectedBusinessCat?.name || null,
            //         status: "active",
            //         ondcCode: null,
            //         ondcValue: null,
            //       },
            //     ]
            //   : [],
            productMainCategory: [businessCatData],
            // productCategoryList: productCatList,
            // productSubCategoryList: productSubCatList,
            // // product Cat
            // productSubCategory: selectedProductCat
            //   ? [
            //       {
            //         code: selectedProductCat?.pscCode1 || null,
            //         value: selectedProductCat?.productSubCategory1 || null,
            //         pcCode: selectedProductCat?.pcCode || null,
            //         status: "active",
            //         pscCode1: selectedProductCat?.scCode1 || null,
            //         ondcSubCode: null,
            //         ondcSubValue: null,
            //       },
            //     ]
            //   : [],
            productSubCategory: [productCatData],
            // // product sub cat
            // productSubCategory2: selectedProductSubCat
            //   ? [
            //       {
            //         code: selectedProductSubCat?.pscCode2 || null,
            //         value: selectedProductSubCat?.productSubCategory2 || null,
            //         pcCode: selectedProductSubCat?.pcCode || null,
            //         psCode:
            //           selectedProductSubCat?.pcCode +
            //           selectedProductSubCat?.scCode1,
            //         status: "active",
            //         pscCode2: "",
            //       },
            //     ]
            //   : [],
            productSubCategory2: [productSubCatData],
            // ONDC cat
            // selectedOndcCategory: selectedOndcCat,
            // selectedOndcSubCategory: selectedOndcSubCat,
            // ONDCSubCategoryList: ondcSubCatList,
            errors: null,
          };
        }
      });

      console.log("bulkCategoryUpdate_Verified:", allProducts);

      state.bulkProductDetails = allProducts;
    },

    // update the recieved list
    updateProductList: (state, action) => {
      const { productDetails } = action.payload;
      state.bulkProductDetails = productDetails;
    },
  },

  extraReducers: {
    //fetch all bulk upload product details
    [getAllBulkProductData.pending]: (state) => {
      state.bulkProductDetailsLoader = true;
      state.bulkProductDetails = null;
    },
    [getAllBulkProductData.fulfilled]: (state, { payload }) => {
      state.bulkProductDetails = payload;
      state.bulkProductDetailsLoader = false;
    },
    [getAllBulkProductData.rejected]: (state, { payload }) => {
      state.bulkProductDetailsLoader = false;
      state.bulkProductDetails = null;
    },

    //update business category and set product category list
    [updateBusinessCatGetProductCat.pending]: (state) => {
      state.productCatLoader = true;
    },
    [updateBusinessCatGetProductCat.fulfilled]: (state, { payload }) => {
      state.bulkProductDetails = payload;
      state.productCatLoader = false;
    },
    [updateBusinessCatGetProductCat.rejected]: (state, { payload }) => {
      state.productCatLoader = false;
    },

    //update business category and set product category list
    [updateProductCatGetProductSubCat.pending]: (state) => {
      state.productSubCatLoader = true;
    },
    [updateProductCatGetProductSubCat.fulfilled]: (state, { payload }) => {
      state.bulkProductDetails = payload;
      state.productSubCatLoader = false;
    },
    [updateProductCatGetProductSubCat.rejected]: (state, { payload }) => {
      state.productSubCatLoader = false;
    },

    //update ONDC category and set ONDC sub category list
    [updateONDCCatGetONDCSubCat.pending]: (state) => {
      state.ONDCCatLoader = true;
    },
    [updateONDCCatGetONDCSubCat.fulfilled]: (state, { payload }) => {
      state.bulkProductDetails = payload;
      state.ONDCCatLoader = false;
    },
    [updateONDCCatGetONDCSubCat.rejected]: (state, { payload }) => {
      state.ONDCCatLoader = false;
    },

    //delete select products
    [deleteSelectedProducts.pending]: (state) => {
      state.deleteProductLoader = true;
    },
    [deleteSelectedProducts.fulfilled]: (state, { payload }) => {
      state.productDeleted = {
        count: payload?.productIdsToDelete?.length,
        productIdsToDelete: payload?.productIdsToDelete,
      };
      state.bulkProductDetails = payload?.products;
      const selectedCount = payload?.products?.filter(
        (item) => item?.isSelected
      ).length;

      state.selectedRowCount = selectedCount;
      state.deleteProductLoader = false;
    },
    [deleteSelectedProducts.rejected]: (state, { payload }) => {
      state.deleteProductLoader = false;
    },

    //delete single products
    [deleteSingleProducts.pending]: (state) => {
      state.deleteProductLoader = true;
    },
    [deleteSingleProducts.fulfilled]: (state, { payload }) => {
      state.productDeleted = {
        count: payload?.productIdsToDelete?.length,
        productIdsToDelete: payload?.productIdsToDelete,
      };
      state.bulkProductDetails = payload?.products;
      const selectedCount = payload?.products?.filter(
        (item) => item?.isSelected
      ).length;

      state.selectedRowCount = selectedCount;
      state.deleteProductLoader = false;
    },
    [deleteSingleProducts.rejected]: (state, { payload }) => {
      state.deleteProductLoader = false;
    },

    //update category data
    [updateCategoryData.pending]: (state) => {
      state.updateLoader = true;
    },
    [updateCategoryData.fulfilled]: (state, { payload }) => {
      console.log("updateCategoryData_Payload:", payload);

      state.bulkProductDetails = payload;
      const selectedCount = payload?.filter((item) => item?.isSelected).length;

      state.selectedRowCount = selectedCount;
      state.updateLoader = false;
    },
    [updateCategoryData.rejected]: (state, { payload }) => {
      state.updateLoader = false;
    },

    //update bulk product data
    [updateBulkProductDetails.pending]: (state) => {
      state.updateLoader = true;
    },
    [updateBulkProductDetails.fulfilled]: (state, { payload }) => {
      console.log("updateBulkProductDetails_Payload:", payload);

      state.bulkProductDetails = payload?.products;

      state.selectedRowCount = payload?.selectedRowCount || 0;
      state.productPublished = payload?.publishedProducts || null;
      state.productDeleted = null;
      state.updateLoader = false;
    },
    [updateBulkProductDetails.rejected]: (state, { payload }) => {
      state.updateLoader = false;
    },

    //update bulk product data
    [updateBulkOndcProductDetails.pending]: (state) => {
      state.updateLoader = true;
    },
    [updateBulkOndcProductDetails.fulfilled]: (state, { payload }) => {
      console.log("updateBulkOndcProductDetails_Payload:", payload);

      state.bulkProductDetails = payload?.products;

      state.selectedRowCount = payload?.selectedRowCount || 0;
      state.productPublished = payload?.publishedProducts || null;
      state.productDeleted = null;
      state.updateLoader = false;
    },
    [updateBulkOndcProductDetails.rejected]: (state, { payload }) => {
      state.updateLoader = false;
    },
  },
});

export const {
  clearBulkProductInfo,
  clearAssignCatError,
  clearPublishedProducts,

  updateProductSubCat,
  updateOndcSubCat,
  validateCategories,
  validateReviewProducts,
  validateOndcParameters,

  selectAllProductRows,
  unselectAllProductRows,
  toggleProductRowSelect,
  bulkCategoryUpdate,
  updateProductList,
} = catalogBulkUpload.actions;

export const catalogBulkUploadReducer1 = catalogBulkUpload.reducer;
