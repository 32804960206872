import { getApiInstance } from "../../AppService";
import {
  COPY_FRANCHISE_PRODUCTS,
  GET_BRANCH_INFO,
  GET_BUSINESS_PRODUCTS,
  GET_MASTER_PRODUCT_INFO,
  GET_PRODUCT_SYNC_STATUS,
  UPDATE_FRANCHISE_PRODUCTS,
} from "../../constant";

export const getMainBranchBusinessProductsAPI = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(GET_BUSINESS_PRODUCTS, {
      params: {
        pageSize: data?.pageSize || 50,
        page: data?.pageNo || 1,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const pushProductsToSubBranch = async (postData) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(COPY_FRANCHISE_PRODUCTS, postData);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getMasterProductInfoAPI = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });

    const response = await instance.get(GET_MASTER_PRODUCT_INFO, {
      params: {
        masterProductId: data?.masterProductId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBranchInfoAPI = async (masterBranchId) => {
  try {
    const instance = getApiInstance();

    const response = await instance.get(GET_BRANCH_INFO, {
      params: {
        masterBranchId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateProductInfoInSubBranch = async (postData) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(UPDATE_FRANCHISE_PRODUCTS, postData);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const getProdcutsSyncStatusAPI = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.masterBranchId,
      },
    });

    const response = await instance.get(GET_PRODUCT_SYNC_STATUS, {
      params: {
        masterBranchBusinessId: data?.masterBranchId,
        subBranchBusinessId: data?.subBranchId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
