import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import FormInputBox from "../../../components/Input/FormInputBox";
import {
  blackColor,
  blackShade05,
  blueColor02,
  errorTextColor,
  greyColor24,
  greyColor27,
  greyColor5,
  greyColor6,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import DashboardStepper from "../../Dashboard/DashboardStepper";
import {
  CATALOG_ADD_PRODUCT_STEP_LIST,
  CATALOG_STEP_LIST,
} from "../../../utils/appData";
import CheckBoxWithLabel from "../../../components/Input/CheckBoxWithLabel";
import {
  getVolumetricWeightInKg,
  mmToCm,
  mmToInches,
} from "../../../utils/helper";
import ModalCard from "../../../components/ModalCard";
import ProductPackingInfo from "../PopupModals/InfoPopups/ProductPackingInfo";
import CatalogActionBtns from "../../../components/Buttons/CatalogActionBtns";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";

function MeasurementDeliveryForm({
  data,
  ondcData,
  onValueChange,
  allDeliveryMethod,
  currentPage,
  gotoNext,
  goBack,
  productName,
  isAddProduct,
  isCopyProduct,
  onCancelClick,
  handleTabChange,
  tabTitle,
  showPreviewBtn,
  previewErrorMsg,
  handlePreviewSubmit,
  setOndcData,
  unitOfMeasurementData,
}) {
  const [showPackingInfo, setShowPackingInfo] = useState(false);

  const onDeliveryMethodChange = (value) => {
    const deliveryMethods = data?.deliveryMethod;

    const index = deliveryMethods.indexOf(value);
    if (index === -1) {
      deliveryMethods.push(value);
    } else {
      deliveryMethods.splice(index, 1);
    }

    console.log("deliveryMethods:", deliveryMethods);
    onValueChange("deliveryMethod", deliveryMethods);
  };

  return (
    <Box sx={styles.card}>
      {/* <DashboardStepper
        stepList={
          isCopyProduct ? CATALOG_ADD_PRODUCT_STEP_LIST : CATALOG_STEP_LIST
        }
        currentPage={currentPage}
        isAddProduct
        hideAvailability={!isCopyProduct}
        handleTabChange={(destinationTab) =>
          handleTabChange(
            currentPage,
            isCopyProduct ? CATALOG_ADD_PRODUCT_STEP_LIST : CATALOG_STEP_LIST,
            destinationTab
          )
        }
      /> */}

      <Typography
        component="h6"
        fontSize={{ xs: 15, md: 15 }}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        mb={"8px !important"}
        mt={{ xs: "0px !important", md: "15px !important" }}
      >
        Product Measurement
      </Typography>

      <Box mb="30px">
        <Typography
          variant="body2"
          fontSize={{ xs: 13, md: 13.5 }}
          fontWeight={400}
          color={greyColor27}
          display="inline"
          lineHeight="20px"
        >
          Please refer to Shipping costs and Packaging recommendations{" "}
          <Typography
            variant="body2"
            fontSize={13.5}
            fontWeight={500}
            color={{xs: mobilePrimary, md:greyColor24}}
            lineHeight="20px"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            display="inline"
            onClick={() => {
              setShowPackingInfo(true);
            }}
          >
            Here
          </Typography>
        </Typography>

        <Typography
          variant="body2"
          fontSize={13.5}
          fontWeight={400}
          color={greyColor27}
          mt="8px !important"
        >
          These values will be used for calculating shipping rates, not visible
          in catalog
        </Typography>
      </Box>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 2, md: 3 }}>
        <Grid item xs={6} md={2.5}>
          <SelectWithLabel
            label="Unit of measure"
            value={ondcData?.productDisplayUnitOfMeasure?.unit}
            onValueChange={(value) => {
              setOndcData((prevState) => ({
                ...prevState,
                productDisplayUnitOfMeasure: {
                  ...prevState?.productDisplayUnitOfMeasure,
                  unit: value?.value,
                },
                errors: {
                  ...prevState?.errors,
                  productDisplayUnitOfMeasure1: "",
                },
              }));
              onValueChange("errors", {
                ...data?.errors,
                productDisplayUnitOfMeasure1: "",
              });
            }}
            returnFullItem
            // menuList={UNITS_OF_MEASURE}
            menuList={unitOfMeasurementData}
            valueSelector="value"
            required
            errorMsg={data?.errors?.productDisplayUnitOfMeasure1}
          />
        </Grid>

        <Grid item xs={6} md={2}>
          <FormInputBox
            label="Enter value"
            placeholder=""
            required
            value={ondcData?.productDisplayUnitOfMeasure?.value}
            onChange={({ target }) => {
              const value = target?.value
                ?.replace(/[^\d.]/g, "")
                ?.replace(/^0+(?=\d)/, "")
                ?.replace(/^(\d*\.\d{0,2}).*/, "$1");

              if (value === "" || parseFloat(value) <= 10000000) {
                setOndcData((prevState) => ({
                  ...prevState,
                  productDisplayUnitOfMeasure: {
                    ...prevState?.productDisplayUnitOfMeasure,
                    value: value,
                  },
                  errors: {
                    ...prevState?.errors,
                    productDisplayUnitOfMeasure: "",
                  },
                }));
                onValueChange("errors", {
                  ...data?.errors,
                  productDisplayUnitOfMeasure: "",
                });
              }
            }}
            error={data?.errors?.productDisplayUnitOfMeasure}
          />
        </Grid>
      </Grid>

      <Box mt={{ xs: 2, md: 0 }}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} md={2.5}>
            <FormInputBox
              label="Net Product Weight (in gms)"
              required
              placeholder=""
              value={data?.weight}
              onChange={({ target }) => {
                const value = target?.value
                  ?.replace(/[^\d.]/g, "")
                  ?.replace(/^0+(?=\d)/, "")
                  ?.replace(/^(\d*\.\d{0,2}).*/, "$1");

                if (value === "" || parseFloat(value) <= 10000000) {
                  onValueChange("weight", value);
                }
              }}
              error={data?.errors?.weight && "Enter weight"}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <FormInputBox
              label="In kgs (max: 9,999)"
              placeholder=""
              value={Number(data?.weight) / 1000}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={2.3}>
            <FormInputBox
              label="Shipping Weight (in gms)"
              placeholder=""
              required
              value={ondcData?.netProductWeight}
              onChange={({ target }) => {
                const value = target?.value
                  ?.replace(/[^\d.]/g, "")
                  ?.replace(/^0+(?=\d)/, "")
                  ?.replace(/^(\d*\.\d{0,2}).*/, "$1");

                if (value === "" || parseFloat(value) <= 10000000) {
                  setOndcData((prevState) => ({
                    ...prevState,
                    netProductWeight: value,
                    errors: {
                      ...prevState?.errors,
                      netProductWeight: "",
                    },
                  }));
                  onValueChange("errors", {
                    ...data?.errors,
                    netProductWeight: "",
                  });
                }
              }}
              helperText="Product + Package Weight"
              error={data?.errors?.netProductWeight}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={{ xs: 2, md: 0 }}>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12} md={2.5}>
            <FormInputBox
              label="Product Length (in mm)"
              placeholder=""
              value={data?.length}
              onChange={({ target }) => {
                const value = target?.value
                  ?.replace(/[^\d.]/g, "")
                  ?.replace(/^0+(?=\d)/, "")
                  ?.replace(/^(\d*\.\d{0,2}).*/, "$1");

                if (value === "" || parseFloat(value) <= 10000) {
                  onValueChange("length", value);
                }
              }}
              error={data?.errors?.length && "Enter length"}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <FormInputBox
              label="In cm"
              placeholder=""
              value={mmToCm(data?.length)}
              disabled
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <FormInputBox
              label="In inches"
              placeholder=""
              value={mmToInches(data?.length)}
              disabled
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={{ xs: 2, md: 0 }}>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12} md={2.5}>
            <FormInputBox
              label="Product Width (in mm)"
              placeholder=""
              value={data?.width}
              onChange={({ target }) => {
                const value = target?.value
                  ?.replace(/[^\d.]/g, "")
                  ?.replace(/^0+(?=\d)/, "")
                  ?.replace(/^(\d*\.\d{0,2}).*/, "$1");

                if (value === "" || parseFloat(value) <= 10000) {
                  onValueChange("width", value);
                }
              }}
              error={data?.errors?.width && "Enter width"}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <FormInputBox
              label="In cm"
              placeholder=""
              value={mmToCm(data?.width)}
              disabled
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <FormInputBox
              label="In inches"
              placeholder=""
              value={mmToInches(data?.width)}
              disabled
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={{ xs: 2, md: 0 }}>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12} md={2.5}>
            <FormInputBox
              label="Product Height (in mm)"
              placeholder=""
              value={data?.height}
              onChange={({ target }) => {
                const value = target?.value
                  ?.replace(/[^\d.]/g, "")
                  ?.replace(/^0+(?=\d)/, "")
                  ?.replace(/^(\d*\.\d{0,2}).*/, "$1");

                if (value === "" || parseFloat(value) <= 10000) {
                  onValueChange("height", value);
                }
              }}
              error={data?.errors?.height && "Enter height"}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <FormInputBox
              label="In cm"
              placeholder=""
              value={mmToCm(data?.height)}
              disabled
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <FormInputBox
              label="In inches"
              placeholder=""
              value={mmToInches(data?.height)}
              disabled
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={{ xs: 1, md: 0 }}>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12} md={3.5}>
            <Box mt={{ xs: 1, md: 0 }}>
              <FormInputBox
                label="Volumetirc weight (in kg)"
                placeholder=""
                value={getVolumetricWeightInKg(
                  mmToCm(data?.length),
                  mmToCm(data?.width),
                  mmToCm(data?.height)
                )}
                disabled
              />

              <Typography
                variant="h3"
                fontSize={{ xs: 12, md: 12.5 }}
                color={greyColor6}
                fontWeight={400}
                mt={{ xs: 1, md: 0 }}
              >
                (Length * Width * Height / 5000 in centimeter)
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* <Divider sx={{ m: "10px 0 15px" }} /> */}

      {/* <Typography
        component="h6"
        fontSize={{ xs: 15, md: 14.5 }}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        mb={"15px !important"}
        mt={{ xs: "0px !important", md: "20px !important" }}
      >
        Delivery Method
      </Typography> */}

      {/* <Grid container rowSpacing={2} columnSpacing={3} alignItems="center">
        <Grid item xs={12} md={4}>
          <Typography
            variant="body1"
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: blackShade05,
            }}
          >
            Select Delivery Method{" "}
            <span style={{ display: "inline", color: "#f00" }}>*</span>
          </Typography>

          <Box sx={{ display: "flex", gap: "10px" }}>

            {allDeliveryMethod?.map((item, index) => {
              return (
                <Box key={`delivery_method_${index}`}>
                  <CheckBoxWithLabel
                    label={item?.name}
                    onValuesChanged={() => {
                      onDeliveryMethodChange(item?.name);
                    }}
                    checked={data?.deliveryMethod?.includes(item?.name)}
                  />
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid> */}

      {/* <Typography
        variant="subtitle1"
        component="p"
        fontSize={12}
        sx={{ mt: "-4px !important", textAlign: "start !important" }}
        style={{ color: "red" }}
      >
        {data?.errors?.deliveryMethod && "Select delivery method"}
      </Typography> */}

      <Box mt="20px">
        <CatalogActionBtns
          onBackClick={goBack}
          onSaveClick={() => {}}
          onNextClick={gotoNext}
          showBackBtn
          hideSaveBtn
          backBtnText="Previous"
          onCancelClick={onCancelClick}
          // Preview Btn
          showPreviewBtn={showPreviewBtn}
          onPreviewSubmitClick={handlePreviewSubmit}
        />
      </Box>

      <Typography
        fontSize={12}
        fontWeight={500}
        mt={1}
        textAlign="right"
        color={errorTextColor}
      >
        {previewErrorMsg}
      </Typography>

      {/* Product Packing Info Popup */}

      <ModalCard
        open={showPackingInfo}
        handleClose={() => {
          setShowPackingInfo(false);
        }}
        width="70%"
      >
        <ProductPackingInfo />
      </ModalCard>
    </Box>
  );
}

export default MeasurementDeliveryForm;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: { xs: "8px", md: "13px" },
    p: { xs: "12px", md: "5px 20px 20px" },
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    m: { xs: "15px 0 25px", md: "0px 0 25px" },
  },
};
