import moment from "moment";

export const prepareOrderShipmentData = (data) => {
  const postData = {
    details: {
      carrierTrackId: data?.carrierTrackId || "",
      carrierName: data?.carrierName || "",
      carrierURL: data?.carrierURL || "",
      deliveryMode: data?.carrierTrackId ? "Truck" : "Person",
      deliveryPersonName: data?.deliveryPersonName || "",
      deliveryPersonPhone: data?.deliveryPersonPhone || "",
      notes: data?.notes || "",
      countryCode: "+91",
      shipmentNotified: false,
      etaDeliveryDate: data?.etaDeliveryDate
        ? moment(data?.etaDeliveryDate).valueOf()
        : "",
      etaDeliveryTime: data?.etaDeliveryTime
        ? moment(`${data?.etaDeliveryDate}T${data?.etaDeliveryTime}`).valueOf()
        : "",
      shipmentNotifiedDate: moment().valueOf(),
    },
    doc: data?.img
      ? {
          name: `Shipment slip ${data?.carrierTrackId}`,
          type: "ppic-1",
          mimeType: "image/jpeg",
          extension: "jpeg",
          replicate: false,
          bytesString: data?.img || "",
        }
      : {},
  };

  return postData;
};
