import React, { useEffect, useState } from "react";
import {
  blackColor,
  blackShade21,
  blueColor04,
  greyColor14,
  logoImgborder1,
  redColor,
  whiteColor,
  yellowColor,
} from "../../configs/styles/muiThemes";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import UploadStepper from "./UploadStepper";
import DownloadTemplate from "./DownloadTemplate";
import UploadTemplate from "./UploadTemplate";
import {
  importBulkProductApi,
  updateBulkProductApi,
  uploadBulkProductApi,
} from "../../service/api_calls/CatalogBulkUpload/CatalogBulkUpload";
import {
  BULK_UPLOAD_EXCEL_TEMPLATES,
  BULK_UPLOAD_STEPPER,
} from "../../utils/data";
import AssignCategory from "./AssignCategory";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSelectedProducts,
  getAllBulkProductData,
  updateBulkOndcProductDetails,
  updateBulkProductDetails,
  validateCategories,
} from "../../reducers/catalogBulkUploadCopy/catalogBulkUploadSlice";
import ReviewAndPublish from "./ReviewAndPublish";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UploadSuccessful from "./UploadSuccessful";
import OndcParameters from "./OndcParameters";
import DeleteAlert from "./Components/Popups/DeleteAlert";
import ModalCard from "../../components/ModalCard";
import { useNavigate } from "react-router-dom";
import ONDCSpecGroups from "./ONDCSpecGroups";
import AddOndcSpecifications from "./AddOndcSpecifications";
import {
  prepareOndcSpecsPostData,
  validateOndcProductSpecs,
} from "../../reducers/catalogBulkUploadCopy/catalogBulkValidationHelper";
import { getUnitOfMeasurementApi } from "../../service/api_calls/catalog/CatalogAPI";
import { sortByAttribute } from "../../utils/validationUtils";
import BulkUploadTemplateGuide from "./Components/Popups/BulkUploadTemplateGuide";

const CatalogBulkUploadContent = ({ activeBusinessInfo, isDisabled }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState("loading");
  const [openCategoryModal, setOpenCategoryModal] = useState(false);

  // used for ondc specs update
  const [selectedProdGroup, setSelectedProdGroup] = useState(null);
  const [openQuickSpecUpdateModal, setOpenQuickSpecUpdateModal] =
    useState(false);
  const [selectedOndcSpecCount, setSelectedOndcSpecCount] = useState(0);
  const [ondcSpecError, setOndcSpecError] = useState("");
  const [isDynamicSpecEmpty, setIsDynamicSpecEmpty] = useState(true);

  const [publishedInfo, setPublishedInfo] = useState(null);

  const [isError, setIsError] = useState("");

  const [templateInfo, setTemplateInfo] = useState({
    selectedTemplate: BULK_UPLOAD_EXCEL_TEMPLATES[0],
  });

  const [fileDetails, setFileDetails] = useState({
    selectedFile: null,
    errors: null,
  });

  const [showDeleteAlert, setShowDeleteAlert] = useState({
    show: false,
    data: null,
  });
  const [categoryError, setCategoryError] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ondcSpecLoader, setOndcSpecLoader] = useState(false);

  const [unitOfMeasurementData, setUnitOfMeasurementData] = useState([]);

  const [openTemplateGuideModel, setOpenTemplateGuideModel] = useState(false);

  const {
    bulkProductDetails,
    bulkProductDetailsLoader,
    isAssignCatError,
    isReviewError,
    productDeleted,
    deleteProductLoader,
    selectedRowCount,
    updateLoader,
  } = useSelector((state) => state.catalogBulkUploadCopy);

  const { countryList } = useSelector((state) => state.catalog);

  const handleNext = () => {
    if (isLoading || updateLoader) return;
    setIsError("");

    if (currentPage === "download") {
      setCurrentPage("upload");
      return;
    }

    if (currentPage === "upload") {
      uploadSelectedFile();
      return;
    }

    if (currentPage === "assign-category") {
      validateAssignedCategories();
      return;
    }

    if (currentPage === "ondc") {
      // setCurrentPage("review");
      // dispatch(validateOndcParameters({ productDetails: bulkProductDetails }));
      const businessId = sessionStorage.getItem("selectedBusinessId");
      dispatch(
        updateBulkOndcProductDetails({
          productDetails: bulkProductDetails,
          businessId,
          countryList,
          isRemoveONDCSpecs: true,
        })
      ).then((res) => {
        console.log("updateBulkOndcProductDetails_res:", res);
        if (res?.payload?.isError) {
          setIsError("Please fix the errors in the list to proceed");
          return;
        }
        // setCurrentPage("ondc");
        // setCurrentPage("ondcSpecifications");
        setCurrentPage("ondcSpecGroup");
      });

      return;
    }

    // if (currentPage === "review" && !isReviewError) {
    //   console.log("REVIEW Publish", bulkProductDetails);
    //   const businessId = sessionStorage.getItem("selectedBusinessId");
    //   dispatch(
    //     updateBulkProductDetails({
    //       productDetails: bulkProductDetails,
    //       businessId,
    //     })
    //   ).then((res) => {
    //     setCurrentPage("ondc");
    //   });
    //   return;
    // }

    if (currentPage === "review") {
      console.log("REVIEW VERIFY", bulkProductDetails);
      // dispatch(validateReviewProducts({ productDetails: bulkProductDetails }));
      const businessId = sessionStorage.getItem("selectedBusinessId");
      dispatch(
        updateBulkProductDetails({
          productDetails: bulkProductDetails,
          businessId,
          unitOfMeasurementData: unitOfMeasurementData || [],
        })
      ).then((res) => {
        console.log("updateBulkProductDetails_res:", res);
        if (res?.payload?.isError) {
          setIsError("Please fix the errors in the list to proceed");
          return;
        }
        setCurrentPage("ondc");
      });
      return;
    }

    if (currentPage === "ondcSpecGroup") {
      if (selectedProdGroup) {
        setCurrentPage("addOndcSpecs");
      } else {
        setIsError("Please select a product group to proceed");
      }
    }

    if (currentPage === "addOndcSpecs") {
      if (selectedProdGroup) {
        handleOndcSpecSubmit(selectedProdGroup);
      }
    }
  };

  const goBack = () => {
    setIsError("");
    switch (currentPage) {
      case "addOndcSpecs":
        setCurrentPage("ondcSpecGroup");
        break;

      case "ondcSpecGroup":
        setCurrentPage("ondc");
        break;

      case "review":
        setCurrentPage("assign-category");
        break;

      case "ondc":
        setCurrentPage("review");
        break;

      case "ondcSpecifications":
        setCurrentPage("ondc");
        break;

      case "assign-category":
        setCurrentPage("assign-category");
        break;

      case "upload":
        setCurrentPage("download");
        break;

      case "download":
        setCurrentPage("download");
        break;

      default:
        break;
    }
  };

  const getNextButtonText = () => {
    switch (currentPage) {
      case "download":
        return "NEXT";

      case "upload":
        return "UPLOAD";

      case "assign-category":
        // return isAssignCatError ? `VERIFY & CONTINUE(${selectedRowCount} ROWS)` : "NEXT";
        return `VERIFY & CONTINUE(${selectedRowCount} ROWS)`;

      case "review":
        return `VERIFY & CONTINUE(${selectedRowCount} ROWS)`;

      case "ondc":
        return `VERIFY & CONTINUE(${selectedRowCount} ROWS)`;

      case "ondcSpecGroup":
        return `CHOOSE & CONTINUE`;

      case "addOndcSpecs":
        return !ondcSpecLoader && isDynamicSpecEmpty
          ? `IMPORT ROWS`
          : `VERIFY & IMPORT(${selectedOndcSpecCount} ROWS)`;

      default:
        return "NEXT";
    }
  };

  // Upload Excel File
  const uploadSelectedFile = async () => {
    if (!fileDetails?.selectedFile) {
      setFileDetails((prevState) => ({
        ...prevState,
        errors: { selectedFile: "Please select a valid Excel sheet file." },
      }));
      return;
    }

    setIsLoading(true);

    const businessId = sessionStorage.getItem("selectedBusinessId");

    const response = await uploadBulkProductApi({
      selectedFile: fileDetails?.selectedFile,
      businessId,
    });

    setIsLoading(false);
    setCurrentPage("assign-category");

    // if (response) {
    //   setIsLoading(false);
    //   setCurrentPage("assign-category");
    // } else {
    //   setIsLoading(false);
    // }
  };

  const validateAssignedCategories = () => {
    dispatch(validateCategories({ productDetails: bulkProductDetails }));
  };

  const handleCloseSnackbar = () => {
    setShowSnackBar(false);
  };

  // Hanlde Multi Delete
  const handleDeleteProducts = async () => {
    if (deleteProductLoader) return;

    setShowDeleteAlert({ show: false, data: null });

    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      dispatch(
        deleteSelectedProducts({
          productDetails: bulkProductDetails,
          businessId,
        })
      ).then((res) => {
        if (selectedRowCount === bulkProductDetails?.length) navigate(0);
      });
    }
  };

  const handleOndcSpecSubmit = async (productSpecData) => {
    // setIsLoading(true);

    const updatedSpecData = validateOndcProductSpecs(
      productSpecData?.products || []
    );
    setSelectedProdGroup((prevState) => ({
      ...prevState,
      products: updatedSpecData?.updatedProductList,
    }));

    if (!updatedSpecData?.isError) {
      const businessId = sessionStorage.getItem("selectedBusinessId");
      const specsPostData = prepareOndcSpecsPostData(
        updatedSpecData?.updatedProductList || [],
        countryList
      );

      console.log("updateBulkProductApi_specsPostData:", specsPostData);

      const updateRes = await updateBulkProductApi({
        businessId,
        postData: specsPostData,
      });
      console.log("updateBulkProductApi_updateRes:", updateRes);

      if (updateRes) {
        // Publish the products

        const publistProductIds = specsPostData.map((item) => {
          return item.id;
        });

        const postData = {
          // uploadId: "",
          ids: publistProductIds,
        };

        console.log("updatePostData_postData:", postData);

        const publishRes = await importBulkProductApi({ postData, businessId });
        console.log("updatePostData_postData:", publishRes);

        if (publishRes) {
          setPublishedInfo({
            publishedProducts: specsPostData?.length || 0,
          });
          setCurrentPage("published");
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } else {
      setIsError("Please fix the errors in the list to proceed");
      setIsLoading(false);
    }
  };

  const ondcSpecSaveAndExit = async (productSpecData) => {
    setIsLoading(true);

    const businessId = sessionStorage.getItem("selectedBusinessId");
    const businessUrl = sessionStorage.getItem("costboWEBURL");

    const specsPostData = prepareOndcSpecsPostData(
      productSpecData?.products || [],
      countryList,
      true
    );

    console.log("ondcSpecSaveAndExit_specsPostData:", specsPostData);

    const updateRes = await updateBulkProductApi({
      businessId,
      postData: specsPostData,
    });

    if (updateRes) {
      setIsLoading(false);
      navigate(`/${businessUrl}/catalog`);
    }
  };

  // Allow only backward navigation
  const handleStepperClick = (stepInfo, stepIndex) => {
    const currentPageInfo = BULK_UPLOAD_STEPPER.find(
      (item) =>
        item.pageName === currentPage || item?.pageTwoName === "addOndcSpecs"
    );

    if (stepInfo?.id > 2 && currentPageInfo?.id > stepInfo?.id) {
      setCurrentPage(stepInfo.pageName);
    }
  };

  // Get Unit of measurement
  const getUnitsData = async () => {
    const response = await getUnitOfMeasurementApi();
    const sortedList = sortByAttribute([...response?.details], "value");

    setUnitOfMeasurementData(() => sortedList || []);
  };

  useEffect(() => {
    if (!isAssignCatError) {
      console.log("NO ERROR in CAT ASSIGN");
      setCurrentPage("review");
    }

    return () => {};
  }, [isAssignCatError]);

  useEffect(() => {
    if (productDeleted) {
      setShowSnackBar(true);
    }

    return () => {};
  }, [productDeleted]);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      dispatch(getAllBulkProductData({ businessId })).then((res) => {
        console.log("getAllBulkProductData_CONTENT:", res);
        if (res?.payload?.length > 0) {
          setCurrentPage("assign-category");
          // setCurrentPage("review");
        } else {
          setCurrentPage("download");
        }
      });
    }

    getUnitsData();

    return () => {};
  }, []);

  return (
    <Box sx={styles.card}>
      <UploadStepper
        currentPage={currentPage}
        handleStepperClick={handleStepperClick}
      />

      {currentPage === "loading" ? (
        <ThreeDotLoader />
      ) : (
        <Box mt={2}>
          {currentPage === "download" && (
            <DownloadTemplate
              templateInfo={templateInfo}
              setTemplateInfo={setTemplateInfo}
              setOpenModal={setOpenTemplateGuideModel}
            />
          )}
          {currentPage === "upload" && (
            <UploadTemplate
              fileDetails={fileDetails}
              setFileDetails={setFileDetails}
              setOpenModal={setOpenTemplateGuideModel}
            />
          )}
          {currentPage === "assign-category" && (
            <AssignCategory
              productDetails={bulkProductDetails}
              isLoading={bulkProductDetailsLoader}
              businessInfo={activeBusinessInfo}
              openCategoryModal={openCategoryModal}
              setOpenCategoryModal={setOpenCategoryModal}
              setCurrentPage={setCurrentPage}
            />
          )}

          {currentPage === "ondc" && (
            <OndcParameters
              productDetails={bulkProductDetails}
              isLoading={bulkProductDetailsLoader}
              setCurrentPage={setCurrentPage}
            />
          )}

          {currentPage === "review" && (
            <ReviewAndPublish
              productDetails={bulkProductDetails}
              isLoading={bulkProductDetailsLoader}
              setCurrentPage={setCurrentPage}
              unitOfMeasurementData={unitOfMeasurementData}
            />
          )}

          {currentPage === "ondcSpecGroup" && (
            <ONDCSpecGroups
              productDetails={bulkProductDetails}
              isLoading={bulkProductDetailsLoader}
              setCurrentPage={setCurrentPage}
              setSelectedProdGroup={setSelectedProdGroup}
            />
          )}

          {currentPage === "addOndcSpecs" && (
            <AddOndcSpecifications
              selectedProdGroup={selectedProdGroup}
              setSelectedProdGroup={setSelectedProdGroup}
              productDetails={bulkProductDetails}
              isLoading={bulkProductDetailsLoader}
              openQuickSpecUpdateModal={openQuickSpecUpdateModal}
              setOpenQuickSpecUpdateModal={setOpenQuickSpecUpdateModal}
              selectedOndcSpecCount={selectedOndcSpecCount}
              setSelectedOndcSpecCount={setSelectedOndcSpecCount}
              setIsDynamicSpecEmpty={setIsDynamicSpecEmpty}
              ondcSpecLoader={ondcSpecLoader}
              setOndcSpecLoader={setOndcSpecLoader}
            />
          )}

          {currentPage === "published" && (
            <UploadSuccessful
              publishedInfo={publishedInfo?.publishedProducts}
              productDetails={bulkProductDetails}
              isLoading={bulkProductDetailsLoader}
              setCurrentPage={setCurrentPage}
            />
          )}

          {!bulkProductDetailsLoader &&
            currentPage !== "published" &&
            !ondcSpecLoader && (
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="center"
                gap={2}
                mt={2}
              >
                {currentPage !== "download" &&
                  currentPage !== "assign-category" && (
                    <Button
                      variant="contained"
                      sx={styles.backBtn}
                      disableElevation
                      onClick={goBack}
                    >
                      BACK
                    </Button>
                  )}
                {currentPage === "assign-category" &&
                  bulkProductDetails?.length > 0 && (
                    <Box>
                      <Button
                        variant="contained"
                        sx={{ ...styles.backBtn, ...styles.assignBtn }}
                        disableElevation
                        onClick={() => {
                          if (selectedRowCount > 0) {
                            setCategoryError("");
                            setOpenCategoryModal(true);
                          } else {
                            setCategoryError(
                              "Select atleast 1 to assign category"
                            );
                          }
                        }}
                        disabled={
                          bulkProductDetails?.length === 0 || isDisabled
                        }
                      >
                        ASSIGN CATEGORY
                      </Button>

                      {categoryError && (
                        <Typography
                          fontSize={10.5}
                          fontWeight={500}
                          color={redColor}
                          mt={1}
                        >
                          {categoryError}
                        </Typography>
                      )}
                    </Box>
                  )}
                {currentPage === "addOndcSpecs" &&
                  selectedProdGroup &&
                  !isDynamicSpecEmpty && (
                    <Box>
                      <Button
                        variant="contained"
                        sx={{ ...styles.backBtn, ...styles.assignBtn }}
                        disableElevation
                        onClick={() => {
                          if (selectedOndcSpecCount > 0) {
                            setOndcSpecError("");
                            setOpenQuickSpecUpdateModal(true);
                          } else {
                            setOndcSpecError(
                              "Select atleast 1 to update specs"
                            );
                          }
                        }}
                        disabled={
                          selectedProdGroup?.products?.length === 0 ||
                          !selectedProdGroup ||
                          isDisabled
                        }
                      >
                        QUICK UPDATE FOR SPECS
                      </Button>

                      {ondcSpecError && (
                        <Typography
                          fontSize={10.5}
                          fontWeight={500}
                          color={redColor}
                          mt={1}
                        >
                          {ondcSpecError}
                        </Typography>
                      )}
                    </Box>
                  )}

                {currentPage !== "download" &&
                  currentPage !== "upload" &&
                  currentPage !== "ondcSpecGroup" &&
                  currentPage !== "addOndcSpecs" &&
                  bulkProductDetails?.length > 0 && (
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{ ...styles.backBtn, ...styles.deleteBtn }}
                      onClick={() => {
                        setShowDeleteAlert({
                          show: true,
                          data: {
                            message: `Are you sure you want to delete the selected ${
                              selectedRowCount === 1
                                ? `${selectedRowCount} Row?`
                                : `${selectedRowCount} Rows?`
                            }`,
                          },
                        });
                      }}
                      startIcon={
                        deleteProductLoader ? (
                          <CircularProgress color="inherit" size={14} />
                        ) : null
                      }
                      disabled={selectedRowCount === 0 || isDisabled}
                    >
                      DELETE{" "}
                      {selectedRowCount === 1
                        ? `${selectedRowCount} Row`
                        : `${selectedRowCount} Rows`}
                    </Button>
                  )}
                {currentPage !== "download" &&
                currentPage !== "upload" &&
                bulkProductDetails?.length === 0 ? (
                  <></>
                ) : (
                  <Stack>
                    <Box>
                      <Button
                        variant="contained"
                        sx={styles.nextBtn(isLoading)}
                        disableElevation
                        onClick={handleNext}
                        disabled={
                          ondcSpecLoader ||
                          updateLoader ||
                          isDisabled ||
                          isLoading ||
                          (selectedRowCount === 0 &&
                            (currentPage === "review" ||
                              currentPage === "ondc" ||
                              currentPage === "assign-category"))
                          // (selectedRowCount === 0 && currentPage === "review")
                        }
                        startIcon={
                          ondcSpecLoader ||
                          isLoading ||
                          (updateLoader && (
                            <CircularProgress
                              sx={{ color: greyColor14, opacity: 0.5 }}
                              size={16}
                            />
                          ))
                        }
                      >
                        {getNextButtonText()}
                      </Button>
                    </Box>

                    {/* {!isReviewError && currentPage === "review" && (
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color={greenColor02}
                      mt={1}
                    >
                      No Errors Found
                    </Typography>
                  )} */}

                    {isError && (
                      <Typography
                        fontSize={10.5}
                        fontWeight={500}
                        color={redColor}
                        mt={1}
                      >
                        {isError}
                      </Typography>
                    )}
                  </Stack>
                )}

                {currentPage === "addOndcSpecs" && (
                  <Button
                    variant="contained"
                    sx={{ ...styles.backBtn, ...styles.assignBtn }}
                    disableElevation
                    onClick={() => {
                      ondcSpecSaveAndExit(selectedProdGroup);
                    }}
                    disabled={
                      isLoading ||
                      selectedProdGroup?.products?.length === 0 ||
                      !selectedProdGroup ||
                      isDisabled
                    }

                    startIcon={
                      ondcSpecLoader ||
                      isLoading ||
                      (updateLoader && (
                        <CircularProgress
                          sx={{ color: greyColor14, opacity: 0.5 }}
                          size={16}
                        />
                      ))
                    }
                  >
                    SAVE & EXIT
                  </Button>
                )}
              </Box>
            )}

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={showSnackBar}
            autoHideDuration={3000}
            onClose={() => {
              handleCloseSnackbar();
            }}
            sx={{ mt: 4 }}
          >
            <Alert
              onClose={() => {
                handleCloseSnackbar();
              }}
              severity="success"
              icon={<DeleteOutlineIcon fontSize="inherit" />}
              variant="filled"
              sx={{ width: "100%" }}
            >
              Deleted {productDeleted?.count} Rows
            </Alert>
          </Snackbar>

          <ModalCard
            open={showDeleteAlert?.show}
            handleClose={() => {
              setShowDeleteAlert({ show: false, data: null });
            }}
            width="33%"
          >
            <DeleteAlert
              alertMsg={showDeleteAlert?.data?.message}
              onCancel={() => {
                setShowDeleteAlert({ show: false, data: null });
              }}
              onDelete={() => {
                handleDeleteProducts();
              }}
            />
          </ModalCard>

          <BulkUploadTemplateGuide
            openModal={openTemplateGuideModel}
            setOpenModal={setOpenTemplateGuideModel}
          />
        </Box>
      )}
    </Box>
  );
};

export default CatalogBulkUploadContent;

const styles = {
  card: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "8px", md: "10px" },
    p: { xs: "12px", md: "15px" },
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    m: { xs: "15px 0 25px", md: "0px 0 25px" },
  },

  backBtn: {
    fontSize: 12,
    fontWeight: 600,
    color: blackShade21,
    backgroundColor: whiteColor,
    border: `1px solid ${blackShade21}`,
    padding: "7px 30px",
    borderRadius: "5px",
    "&:hover": {
      color: whiteColor,
      backgroundColor: blackShade21,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },

  assignBtn: {
    color: blueColor04,
    border: `1px solid ${blueColor04}`,
    backgroundColor: whiteColor,
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },

  deleteBtn: {
    color: redColor,
    border: `1px solid ${redColor}`,
    backgroundColor: whiteColor,
    "&:hover": {
      color: whiteColor,
      backgroundColor: redColor,
    },
  },

  nextBtn: (isDisabled) => ({
    fontSize: 13,
    fontWeight: 500,
    color: blackColor,
    backgroundColor: yellowColor,
    border: isDisabled ? "none" : `1px solid ${yellowColor}`,
    padding: "7px 30px",
    borderRadius: "7px",
    "&:hover": {
      color: yellowColor,
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  }),
};
