import { Box, Button, Typography } from "@mui/material";
import React from "react";
import {
  bgColor15,
  blueColor04,
  greenColor,
  greyColor1,
  greyColor17,
  greyColor18,
  greyColor8,
  heroSectionText,
  logoImgborder1,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { blackShade05 } from "../../configs/styles/muiThemes";
import { greyColor6 } from "../../configs/styles/muiThemes";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
import HowToLinkCard from "../../components/Cards/HowToLinkCard";
import { ECOMMERCE_VIDEO_DATA } from "../../utils/data";
import VideoModal from "../../components/VideoModal";
import { useState } from "react";

function BusinessStepCard({
  stepCount,
  icon,
  status,
  title,
  description,
  onClick,
  hideButton,
  buttonText,
  btnType,
  isLastStep,
  disabled,
  cardType,
  isMailVerified,
  userEmail,
  showVideoGuide,
}) {
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const navigate = useNavigate();

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  return (
    <Box sx={styles.card(disabled)}>
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          handleClose={handleCloseVideoModal}
          data={ECOMMERCE_VIDEO_DATA}
        />
      )}
      <Box display="flex" alignItems="center" gap="15px" position="relative">
        {!isLastStep && <Box sx={styles.stepLine} />}

        <Typography
          variant="body2"
          fontSize={13}
          fontWeight={500}
          color={bgColor15}
          display={{ md: "block", xs: "none" }}
        >
          STEP {stepCount}
        </Typography>

        <Box>
          <Box
            sx={{
              ...styles.icon,
              backgroundColor: status === "active" ? blueColor04 : bgColor15,
            }}
          >
            {icon}
          </Box>
          {status === "active" && (
            <Box sx={styles.doneIcon}>
              <CheckIcon sx={{ fontSize: { md: 20, xs: 12 } }} />
            </Box>
          )}
        </Box>
      </Box>

      <Box flex={1} position="relative">
        <Box sx={styles.uiArrow} />

        <Box sx={styles.infoCard}>
          <Typography
            variant="h2"
            fontSize={{ md: 14, xs: 14 }}
            fontWeight={600}
            lineHeight="22px"
            color={blackShade05}
            mb="5px"
          >
            {title}
          </Typography>

          {cardType === "email-verify" ? (
            <Typography
              variant="body1"
              fontSize={13}
              fontWeight={400}
              lineHeight="20px"
              color={greyColor6}
              mb="2px"
              sx={{ whiteSpace: "pre-line", display: "inline" }}
            >
              {isMailVerified
                ? `Registered Email: `
                : `A verification code has been sent to `}
              <Typography
                fontSize={13}
                fontWeight={500}
                color={heroSectionText}
                display="inline"
              >
                {userEmail}
              </Typography>
              {isMailVerified
                ? ""
                : `. Please complete verification, to continue with E-commerce settings.`}
            </Typography>
          ) : (
            <Typography
              variant="body1"
              fontSize={{ md: 12.5, xs: 12 }}
              fontWeight={400}
              lineHeight="20px"
              color={greyColor6}
              mb="2px"
              sx={{ whiteSpace: "pre-line" }}
            >
              {description}
            </Typography>
          )}

          {!hideButton && (
            <Button
              startIcon={btnType ? <AddIcon /> : null}
              onClick={onClick}
              sx={styles.actionBtn(disabled)}
              disabled={disabled}
            >
              {buttonText}
            </Button>
          )}

          {showVideoGuide && (
            <HowToLinkCard
              link="E-Commerce Controls"
              handleClick={() => {
                setOpenVideoModal(true);
                // navigate("/howToVideoGuides", {
                //   state: { status: "eCommerceControls" },
                // });
              }}
              justifyContent="flex-start"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default BusinessStepCard;

const styles = {
  card: (isDisabled) => ({
    height: { md: "170px", xs: "160px" },
    display: "flex",
    alignItems: "start",
    gap: "20px",
    opacity: isDisabled ? 0.6 : 1,
  }),

  stepLine: {
    width: "2px",
    height: { md: "145px", xs: "150px" },
    backgroundColor: greyColor17,
    position: "absolute",
    top: "25px",
    right: { md: "25px", xs: 20 },
  },

  icon: {
    width: { md: "55px", xs: "40px" },
    height: { md: "55px", xs: "40px" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: whiteColor,

    border: `4px solid ${whiteColor}`,
    borderRadius: "30px",
    zIndex: 1,
    position: "relative",
  },

  doneIcon: {
    width: { md: "20px", xs: "15px" },
    height: { md: "20px", xs: "15px" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: whiteColor,
    borderRadius: "10px",
    backgroundColor: greenColor,
    position: "absolute",
    bottom: 0,
    right: 2,
    zIndex: 1,
  },

  infoCard: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { md: "15px", xs: "10px" },
    boxShadow: "0 2px 4px rgb(179 188 198 / 10%)",
    p: { md: "15px", xs: "8px 12px" },
    position: "relative",
  },

  uiArrow: {
    width: "16px",
    height: "16px",
    backgroundColor: greyColor18,
    transform: "rotate(45deg)",
    position: "absolute",
    top: 15,
    left: -5,
  },

  actionBtn: (isDisabled) => ({
    fontSize: 12,
    fontWeight: 600,
    color: blueColor04,
    border: isDisabled ? `1px solid ${greyColor1}` : `1px solid ${blueColor04}`,
    mt: "8px",
    mb: 1,
    p: { md: "3px 22px", xs: "3px 22px" },

    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04
      ,
    },
  }),
};
