import { NavigateNextRounded } from "@mui/icons-material";
import { Box, Typography, Breadcrumbs, useMediaQuery } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { blackShade19, greyColor40 } from "../../configs/styles/muiThemes";

function BreadcrumbSection({ links, urlLink }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <>
      <Box
        sx={{
          // display: { xs: 'none', md: 'flex' },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "30px",
        }}
        borderRadius="6px"
        mb={{ xs: 1, md: 1.2 }}
      >
        <Breadcrumbs separator={<NavigateNextRounded />}>
          {matches
            ? links?.map((link, index) => {
                return (
                  <Typography
                    key="2"
                    color={
                      index == links?.length - 1 ? blackShade19 : greyColor40
                    }
                    fontSize="12px"
                    fontWeight={index == links?.length - 1 ? 500 : 400}
                    component={
                      index == links?.length - 1 || link?.to == "#" ? "p" : Link
                    }
                    to={link?.disabled ? "" : link.to}
                    sx={{
                      maxWidth: "100ch", // Limit to 80 characters
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {link.label}
                  </Typography>
                );
              })
            : null}
        </Breadcrumbs>
      </Box>
    </>
  );
}

export default BreadcrumbSection;
