import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import {
  blackShade04,
  blackShade09,
  blueColor04,
  greyColor6,
  heroSectionText,
  whiteColor,
  yellowColor,
} from "../configs/styles/muiThemes";
import ProfileCharAvatar from "./ProfileCharAvatar";
import { geProfilePhoto, getUserName } from "../utils/authHelpers";
import { removeSessionInfo } from "../utils/validationUtils";

function NewNavBar({ handleDashboardClick, isLoggedIn }) {
  const isProfileImgAvailabel = geProfilePhoto();
  const username = getUserName();

  const onLogout = () => {
    removeSessionInfo();
    // navigate("/");
    window.open("/", "_self");
  };

  return (
    <Box sx={styles.navbar}>
      <Container sx={styles.navbarContent}>
        <Box
          component="img"
          maxHeight={{ xs: "29px", md: "40px" }}
          src="https://storage.googleapis.com/bodefaults/businessdashboardweb/logo.svg"
          alt="CostBo"
        />

        {isLoggedIn ? (
          <Box>
            <ProfileCard
              prifileImg={isProfileImgAvailabel ? geProfilePhoto() : ""}
              name={username}
              onLogout={onLogout}
            />
          </Box>
        ) : (
          <Box
            display={{ md: "flex", xs: "none" }}
            alignItems="center"
            gap="40px"
          >
            <Button
              sx={styles.actionOutlineBtn}
              variant="contained"
              disableElevation
              size="large"
              onClick={handleDashboardClick}
            >
              BUSINESS LOGIN
            </Button>
            <Button
              sx={styles.actionBtn}
              variant="contained"
              disableElevation
              size="large"
              onClick={handleDashboardClick}
            >
              SIGNUP/GET STARTED
            </Button>
            {/* <Typography
              component="span"
              sx={styles.navLink}
              onClick={handleDashboardClick}
            >
              BUSINESS LOGIN
            </Typography>

            <Typography
              component="span"
              sx={styles.navLink}
              onClick={handleDashboardClick}
            >
              SIGNUP/GET STARTED
            </Typography> */}
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default NewNavBar;

const ProfileCard = ({ prifileImg, name, onLogout }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "15px",
        cursor: "pointer",
      }}
      onClick={() => {}}
    >
      {prifileImg == "" ? (
        <ProfileCharAvatar username={getUserName()} />
      ) : (
        <Box
          component="img"
          src={prifileImg}
          width={{ xs: "38px", md: "44px" }}
          height={{ xs: "38px", md: "44px" }}
          sx={{ borderRadius: "50%" }}
        />
      )}

      <Box>
        <Typography
          variant="body1"
          fontSize={{ xs: 13, md: 15 }}
          color={blackShade04}
          fontWeight={600}
          lineHeight="20px"
        >
          {name}
        </Typography>

        <Typography
          variant="body1"
          fontSize={{ xs: 11, md: 12 }}
          color={greyColor6}
          fontWeight={400}
          lineHeight="20px"
          sx={{
            "&:hover": {
              color: heroSectionText,
            },
          }}
          onClick={onLogout}
        >
          LOGOUT
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  navbar: {
    backgroundcolor: whiteColor,
    boxShadow: "0 0px 0px rgb(0 0 0 / 7%)",
    borderBottom: "1px solid #ffffff0d",
    padding: "15px 0 20px",
    position: "relative",
    zIndex: 999,
  },

  navbarContent: {
    maxWidth: "1350px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  navLink: {
    fontSize: 15,
    fontWeight: 500,
    cursor: "pointer",
    color: `${blackShade09} !important`,
    letterSpacing: 0.5,
    textDecoration: "underline",
  },

  actionBtn: {
    padding: "6px 20px",
    fontSize: "13px",
    fontWeight: { xs: 500, md: 500 },
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: yellowColor,
    color: blackShade09,
    border: `1.5px solid ${yellowColor}`,
    "&:hover": {
      background: "transparent",
      color: yellowColor,
      border: {
        xs: `1.5px solid transparent`,
        md: `1.5px solid ${yellowColor}`,
      },
      outline: "none",
    },
  },
  actionOutlineBtn: {
    padding: "6px 20px",
    fontSize: "13px",
    fontWeight: { xs: 500, md: 500 },
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: whiteColor,
    color: blueColor04,
    border: `1.5px solid ${blueColor04}`,
    "&:hover": {
      background: blueColor04,
      color: whiteColor,
      border: {
        xs: `1.5px solid transparent`,
        md: `1.5px solid ${blueColor04}`,
      },
      outline: "none",
    },
  },
};
