import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import {
  authInputBorder,
  blackShade159,
  blackShade58,
  greyColor21,
  greyColor6,
} from "../../../configs/styles/muiThemes";

function BusinessTypeCheckBox({ isChecked, onClick, businessType }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "6px",
        // border: authInputBorder,
        // p: "8px 10px",
        // borderRadius: "6px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Checkbox
        size="large"
        sx={{
          maxWidth: "30px",
          maxHeight: "30px",
          color: greyColor21,
          "&.Mui-checked": {
            color: blackShade159,
          },
        }}
        checked={isChecked}
        // onChange={()=>{
        //     console.log("HITT");
        // }}
        disableRipple
      />

      <Box>
        <Typography
          variant="h5"
          fontSize={13}
          fontWeight={500}
          color={blackShade58}
          lineHeight="20px"
        >
          {businessType}
        </Typography>

        {/* <Typography
          variant="h6"
          fontSize={13}
          fontWeight={400}
          color={greyColor6}
          lineHeight="20px"
        >
          Shipped by me
        </Typography>*/}
      </Box>
    </Box>
  );
}

export default BusinessTypeCheckBox;
