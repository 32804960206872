import { Box, Typography } from "@mui/material";
import React from "react";
import { blackColor, greyShade13 } from "../../configs/styles/muiThemes";

export default function ParagraphWithHeader({ title, paragraph, subTitle }) {
  return (
    <Box pb={1}>
      <Typography
        color={blackColor}
        variant="h5"
        fontSize={{ md: 18, xs: 15 }}
        fontWeight={600}
        component="h4"
        pb={1.6}
      >
        {title}
      </Typography>
      {subTitle ? (
        <Typography
          color={blackColor}
          variant="h5"
          fontSize={{ md: 14, xs: 13 }}
          fontWeight={500}
          component="h4"
          pb={{ md: 1.6, xs: 1 }}
        >
          {subTitle}
        </Typography>
      ) : null}
      {paragraph?.map((item, i) => {
        return (
          <Typography
            color={greyShade13}
            fontSize={{ md: 14, xs: 13 }}
            fontWeight={400}
            pb={2}
            lineHeight="25px"
            textAlign="justify"
          >
            {item}
          </Typography>
        );
      })}
    </Box>
  );
}
