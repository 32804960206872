import React, { useEffect, useState } from "react";
import ModalCard from "../../../components/ModalCard";
import {
  BGColor02,
  blackShade19,
  blueColor04,
  copyBtnColor,
  greyColor40,
  greyColor6,
  greyColor8,
  greyColor9,
  outOfStockColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import {
  Box,
  Button,
  Grid,
  Stack,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import FormInputBox from "../../../components/Input/FormInputBox";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import UpdateInfoAlert from "./UpdateInfoAlert";

const QuickAllStockUpdate = ({
  openModal,
  setOpenModal,
  onApply,
  gstRates,
  allProducts,
  totalCount,
  type,
}) => {
  const [inputData, setInputData] = useState({
    availability: type == "all_stock" ? ["Yes"] : ["No"],
    percentageOff: "",
    gstPercentage: "",
    validityDays: "",
    minQty: "",
    maxQty: "",
  });

  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [openAlertModel, setOpenAlertModel] = useState(false);

  const handleValueChange = (name, value) => {
    setInputData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const selectedRows = [...allProducts]?.reduce(
      (accumulator, currentValue) => {
        if (currentValue?.itemChange) {
          accumulator = accumulator + 1;
        }

        return accumulator;
      },
      0
    );

    setSelectedRowCount(selectedRows);

    return () => {};
  }, []);

  return (
    <>
      <ModalCard
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        width="30%"
      >
        <Box sx={{ backgroundColor: whiteColor, borderRadius: 1.8 }}>
          <Box
            sx={{
              backgroundColor: BGColor02,
              p: 1.5,
              borderRadius: "14.4px 14.4px 0 0",
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              fontSize={16}
              fontWeight={600}
              color={blackShade19}
              mb={0.3}
            >
              Bulk Update
              <Typography
                variant="body1"
                fontSize={13}
                fontWeight={500}
                color={greyColor9}
              >
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                    color: blueColor04,
                  }}
                >
                  {selectedRowCount} of {totalCount}
                </span>{" "}
                rows selected
              </Typography>
            </Typography>
          </Box>

          <Box sx={{ p: "15px 15px 0 15px", mb: 1 }}>
            <Grid container columnSpacing={3} rowSpacing={0}>
              <Grid item xs={6}>
                <Box>
                  <Typography
                    variant="h3"
                    fontSize={13}
                    color={greyColor6}
                    fontWeight={400}
                    mb={"15px"}
                  >
                    Availability
                  </Typography>

                  <StyledSwitch
                    sx={{ ml: 1, color: "red" }}
                    checked={inputData?.availability?.[0] == "Yes"}
                    onChange={() => {
                      if (inputData?.availability?.[0] == "Yes") {
                        handleValueChange("availability", ["No"]);
                      } else {
                        handleValueChange("availability", ["Yes"]);
                      }
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={6}>
                <FormInputBox
                  label="% OFF"
                  value={inputData?.percentageOff}
                  onChange={({ target }) => {
                    const newValue = target?.value?.replace(/[^0-9]/g, "");
                    if (newValue <= 100 || newValue === "")
                      handleValueChange("percentageOff", newValue);
                  }}
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs={6}>
                <SelectWithLabel
                  label="GST %"
                  value={inputData?.gstPercentage?.replace("%", "") || ""}
                  onValueChange={(value) => {
                    handleValueChange("gstPercentage", value);
                  }}
                  menuList={gstRates}
                  valueSelector="value"
                  isDashboardInput
                />
              </Grid>

              <Grid item xs={6}>
                <FormInputBox
                  label="Min Qty"
                  value={inputData?.minQty}
                  onChange={({ target }) => {
                    const newValue = target?.value?.replace(/[^0-9]/g, "");
                    handleValueChange("minQty", newValue);
                  }}
                  autoComplete="off"
                  maxLength={7}
                />
              </Grid>

              <Grid item xs={6}>
                <FormInputBox
                  label="Max Qty"
                  value={inputData?.maxQty}
                  onChange={({ target }) => {
                    const newValue = target?.value?.replace(/[^0-9]/g, "");
                    handleValueChange("maxQty", newValue);
                  }}
                  autoComplete="off"
                  maxLength={7}
                />
              </Grid>

              <Grid item xs={6}>
                <FormInputBox
                  label="Validity Days"
                  value={inputData?.validityDays}
                  onChange={({ target }) => {
                    const newValue = target?.value?.replace(/[^0-9]/g, "");
                    if (newValue <= 1000 || newValue === "")
                      handleValueChange("validityDays", newValue);
                  }}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              backgroundColor: BGColor02,
              p: {xs:'10px 10px 10px 10px', md:"10px 16px 16px 16px"},
              borderRadius: "0 0 14.4px 14.4px",
            }}
          >
            <Typography
              fontSize={{xs:11.5, md:12}}
              fontWeight={400}
              color={greyColor40}
              lineHeight="20px"
              mb={1}
            >
              The changes above will be applied to {selectedRowCount} selected
              rows.
            </Typography>

            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={4}
            >
              <Box />

              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  sx={styles.backBtn}
                  disableElevation
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  sx={styles.applyBtn}
                  onClick={() => {
                    // onApply(inputData);
                    setOpenAlertModel(true);
                    // setOpenModal(false);
                  }}
                >
                  APPLY
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>
      </ModalCard>

      <UpdateInfoAlert
        openModal={openAlertModel}
        setOpenModal={setOpenAlertModel}
        selectedRowCount={selectedRowCount}
        onClick={() => {
          onApply(inputData);
          setOpenAlertModel(false);
        }}
      />
    </>
  );
};

export default QuickAllStockUpdate;

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 35,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(8px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: copyBtnColor,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : outOfStockColor,
    boxSizing: "border-box",
  },
}));

const styles = {
  backBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: whiteColor,
    border: `1px solid ${blackShade19}`,
    color: blackShade19,
    p: "5px 20px",
    "&:hover": {
      color: blackShade19,
      backgroundColor: whiteColor,
    },
  },

  applyBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: blueColor04,
    border: `1px solid ${blueColor04}`,
    color: whiteColor,
    p: "5px 20px",
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },
};
