import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade19,
  border25,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import InputPreview from "../../../components/Input/InputPreview";

const ProductDetailsPreview = ({
  description,
  brand,
  country,
  skuId,
  taxesIncluded,
  gstPercentage,
  productCode,
  productCodeValue,
}) => {
  return (
    <Box sx={styles.card}>
      <Box pb={1.2} mb={0}>
        <Typography sx={styles.title}>Product Detail</Typography>
      </Box>

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        <Grid item xs={12} md={4}>
          <InputPreview label="Brand" value={brand} hideBorder />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputPreview label="Country of Origin" value={country} hideBorder />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputPreview label="SKU ID" value={skuId} hideBorder />
        </Grid>

        <Grid item xs={12} md={12}>
          <InputPreview
            label="Product Description"
            value={description}
            hideBorder
            isReadMoreLess
          />
        </Grid>
      </Grid>

      <Box py={1.2} mb={0}>
        <Typography sx={styles.title}>Tax and GST Information</Typography>
      </Box>

      <Grid container rowSpacing={{ xs: 2, md: 0 }} columnSpacing={3}>
        <Grid item xs={12} md={3}>
          <InputPreview
            label="Taxes Included"
            value={taxesIncluded}
            hideBorder
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputPreview
            label="GST Percentage(%)"
            value={gstPercentage}
            hideBorder
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductDetailsPreview;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    border: border25,
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: "15px 15px",
    mb: 2,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    color: blackShade19,
  },
};
