import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  eCommerceblackcolor1,
  greyColor8,
  whiteColor,
} from "../../configs/styles/muiThemes";
import ModalCard from "../../components/ModalCard";
import AddCustomerModalContent from "./AddCustomerModalContent";
import CustomerPurchaceInfo from "./CustomerPurchaceInfo";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCRMInfo,
  getAllBusinessCustomersData,
} from "../../reducers/crmcustomerdata/customerSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import EmptyCard from "../../components/Cards/EmptyCard";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";

function CRMMarketingContent() {
  const PAGE_SIZE = 100;
  const [hasMoreData, setHasMoreData] = useState(true);

  const {
    businessCustomers,
    businessCustomersPageNo,
    businessCustomersLoader,
  } = useSelector((state) => state.businessCustomers);

  const dispatch = useDispatch();
  const crmcustomerdata = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      getAllBusinessCustomersData({
        businessId,
        pageNo: businessCustomersPageNo + 1,
      })
    );
  };
  const fetchNextData = () => {
    crmcustomerdata();
  };
  useEffect(() => {
    crmcustomerdata();
    return () => {
      dispatch(clearCRMInfo());
    };
  }, []);
  useEffect(() => {
    if ((businessCustomersPageNo ) * PAGE_SIZE > businessCustomers?.details?.length) {
      setHasMoreData(false);
    }

    // console.log("PAGE_NO", businessCustomersPageNo);
    // console.log("PAGE_SIZE", (businessCustomersPageNo - 1 ) * PAGE_SIZE);
    // console.log(
    //   (businessCustomersPageNo - 1) * PAGE_SIZE > businessCustomers?.length
    // );

    return () => {};
  }, [businessCustomersPageNo]);

  return (
    <Box mt={1}>
      {!businessCustomersLoader && businessCustomers?.details?.length === 0 && (
        <EmptyCard
          
          icon={<PeopleOutlineOutlinedIcon sx={{ fontSize: "20px" }} />}
          msg="Customer details not available"
        />
      )}
      {businessCustomersLoader &&<ThreeDotLoader/>}
      {businessCustomers?.details?.length > 0 && (
        <InfiniteScroll
          dataLength={businessCustomers?.details?.length || 0}
          next={fetchNextData}
          hasMore={hasMoreData}
          loader={
            <Box>
              <ThreeDotLoader />
            </Box>
          }
        >
          {businessCustomers?.details?.map((item, index) => {
            const onBoardedDate = item?.createdOn;
            const lastPurchasedDate = item.orderInfo?.lastTxnDate;

            const userInfo = businessCustomers?.profileInfo?.filter(
              (profile) => item.contactInfo?.profileId === profile.profileId
            );

            let username = "";
            let profilePic = "";

            if (userInfo?.length > 0) {
              username = userInfo?.[0]?.name;
              profilePic = userInfo?.[0]?.photoUrl;
            }

            return (
              <CustomerPurchaceInfo
                customeName={item.contactInfo?.name}
                customeOnBoardedDate={moment(onBoardedDate).format(
                  "MMM DD YYYY"
                )}
                lastPurchasedDate={
                  lastPurchasedDate
                    ? moment(lastPurchasedDate).format("MMM DD YYYY")
                    : "-/-"
                }
                lastPurcahedAmount={item.orderInfo?.lastTxnAmount}
                numberOfPurchase={item.orderInfo?.totalOrder}
                totalPurchase={item.orderInfo?.totalAmount}
                profileImage={profilePic}
              />
            );
          })}
        </InfiniteScroll>
      )}
    </Box>
  );
}

export default CRMMarketingContent;

const styles = {
  addIconBtn: {
    backgroundColor: "transparent",
    padding: "6px 22px",
    fontSize: "13px",
    fontWeight: 600,
    color: greyColor8,
    backgroundColor: whiteColor,
    border: "1.5px solid " + greyColor8,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },
};
