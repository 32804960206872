import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import InfoCardHeader from "./InfoCardHeader";
import {
  blackShade05,
  blueColor04,
  greyColor14,
  logoImgborder1,
  whiteColor,
  yellowColorStar,
} from "../../../configs/styles/muiThemes";
import LabelValueText from "./LabelValueText";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { addThousandsSeparator } from "../../../utils/helper";

function CustomerTractionInfoCard({
  view,
  followers,
  reviewSummary,
  orderSummary,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box sx={styles.card}>
      <Box display="flex" justifyContent="space-between">
        <InfoCardHeader title="Customer Traction" hideBtn />
        <Box>
          <Typography
            fontSize={12}
            fontWeight={500}
            color={greyColor14}
            lineHeight="20px"
            display="inline"
          >
            RATING:{" "}
          </Typography>

          <Typography
            fontSize={13}
            fontWeight={500}
            color={yellowColorStar}
            lineHeight="22px"
            display="inline"
          >
            <StarRoundedIcon
              sx={{ fontSize: "17px", mt: "-4px", mr: "-3px" }}
            />{" "}
            {reviewSummary?.[0]?.average || 0}
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        borderBottom="1px dashed #e2e4ea"
        pb={1}
        mt={-1}
      >
        <LabelValueText
          label="VIEWS"
          value={addThousandsSeparator(view?.totalView, true) || 0}
          valueColor={matches ? blueColor04 : blackShade05}
          borderStyle="dashed"
        />
        <LabelValueText
          label="FOLLOWERS"
          value={addThousandsSeparator(followers?.totalFollower, true) || 0}
          hideBorder
        />
      </Box>

      <Box display="flex" alignItems="center" pb={0}>
        <LabelValueText
          label="REVIEWS"
          value={reviewSummary?.[0]?.totalRated || 0}
          valueColor={matches ? blueColor04 : blackShade05}
          borderStyle="dashed"
          styles={{
            margin: { xs: "18px 10px 0 0", md: "10px 20px 0 0 !important" },
          }}
        />

        <LabelValueText
          label="UNIQUE BUYERS"
          // value={uniqueBuyers?.length}
          value={addThousandsSeparator(orderSummary?.totalBuyers, true) || 0}
          hideBorder
          styles={{
            margin: { xs: "18px 10px 0 0", md: "10px 20px 0 0 !important" },
          }}
        />
      </Box>
    </Box>
  );
}

export default CustomerTractionInfoCard;

const styles = {
  card: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "13px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 15px", md: "10px 15px " },
    width: "100%",
  },
};
