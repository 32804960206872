import { Box, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import {
  BGColor02,
  greyColor02,
  blackShade01,
  blueColor100,
  border27,
  greyShade01,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import IssueDetailsCard from "./InfoCards/IssueDetailsCard";
import { PRODUCT_LIST } from "../../../utils/dummyData";
import ProductDetailsCard from "./InfoCards/ProductDetailsCard";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ModalCard from "../../../components/ModalCard";
import ActionTimeLine from "./InfoCards/ActionTimeLine";
import InputPreview from "../../../components/Input/InputPreview";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import {
  addDurationToDate,
  getIssueStatusText,
  getOpenStatusText,
  getResolvedSLAText,
  getStatusText,
  getStatusTextSLA,
  getStatusTextSLAEX,
} from "../../../utils/helper";
import {
  clearCartInfo,
  getCartDetails,
} from "../../../reducers/grievanceIssue/grievanceSlice";
import ImageViewModal from "./ImageViewModal";

function ViewIssueModal({
  modal,
  setModal,
  grievanceData,
  setIsImageIssueModal,
  getOndcBapNameBySubscriberId,
}) {
  const [steps, setSteps] = useState([]);
  const [responseStatus, setResponseStatus] = useState("");
  const [resolutionStatus, setResolutionStatus] = useState("");
  const [responseStatusSla, setResponseStatusSla] = useState("");
  const [resolutionStatusSla, setResolutionStatusSla] = useState("");
  const dispatch = useDispatch();

  const { cartData, cartLoader } = useSelector((state) => state.grievance);

  const buildStepData = () => {
    const open =
      grievanceData?.message?.issue?.issue_actions?.complainant_actions?.find(
        (data) => data?.complainant_action === "OPEN"
      );
    const processing =
      grievanceData?.message?.issue?.issue_actions?.respondent_actions?.find(
        (data) => data?.respondent_action === "PROCESSING"
      );
    const resolved =
      grievanceData?.message?.issue?.issue_actions?.respondent_actions?.find(
        (data) => data?.respondent_action === "RESOLVED"
      );
    const completed =
      grievanceData?.message?.issue?.issue_actions?.complainant_actions?.find(
        (data) => data?.complainant_action === "CLOSE"
      );
    console.log(open, processing, resolved, completed);

    const stepArray = [open, processing, resolved, completed];

    const filteredArray = stepArray?.filter((item) => item != undefined);

    setSteps(filteredArray);
  };

  useEffect(() => {
    const Processing_Time = addDurationToDate(
      grievanceData?.createdOn,
      grievanceData?.message?.issue?.expected_response_time?.duration
    );
    const responseTimeStatus =
      grievanceData?.message?.issue?.status === "OPEN"
        ? getStatusText(
            grievanceData?.createdOn,
            grievanceData?.message?.issue?.expected_response_time?.duration
          )
        : getStatusTextSLAEX(
            grievanceData?.createdOn,
            grievanceData?.message?.issue?.expected_response_time?.duration,
            grievanceData?.message?.issue?.issue_actions?.respondent_actions?.find(
              (item, index) => {
                return (
                  item.respondent_action === "PROCESSING" && item.updated_at
                );
              }
            )
          );

    const resolutionTimeStatus =
      grievanceData?.message?.issue?.status === "OPEN"
        ? getStatusText(
            grievanceData?.createdOn,
            grievanceData?.message?.issue?.expected_resolution_time?.duration
          )
        : grievanceData?.message?.issue?.status === "PROCESSING"
        ? getStatusText(
            grievanceData?.createdOn,
            grievanceData?.message?.issue?.expected_resolution_time?.duration
          )
        : getStatusTextSLAEX(
            grievanceData?.createdOn,
            grievanceData?.message?.issue?.expected_resolution_time?.duration,
            grievanceData?.message?.issue?.issue_actions
              ?.respondent_actions?.[0]?.updated_at
          );
    const slaResponseText = getResolvedSLAText(grievanceData, "responceSla");
    const slaResolutionText = getResolvedSLAText(grievanceData, "resolveSla");

    dispatch(
      getCartDetails({
        cartId: grievanceData?.cartId,
        businessId: grievanceData?.businessId,
      })
    );

    setResponseStatus(responseTimeStatus);
    setResolutionStatus(resolutionTimeStatus);
    setResponseStatusSla(slaResponseText);
    setResolutionStatusSla(slaResolutionText);
    buildStepData();
    return () => {};
  }, [grievanceData]);

  return (
    <Box>
      <ModalCard
        open={modal}
        handleClose={() => {
          setModal({
            show: false,
            grievanceData: null,
          });
          dispatch(clearCartInfo());
        }}
        width="80%"
        modalType="inside"
      >
        <Box sx={styles.card}>
          <Typography
            variant="h3"
            fontSize={{ xs: 16, md: 17 }}
            fontWeight={600}
            lineHeight="34px"
            color={blackShade01}
            textAlign="center"
            p="10px 50px"
          >
            {grievanceData?.message?.issue?.status === "RESOLVED" ||
            grievanceData?.message?.issue?.status === "CLOSED"
              ? "Resolution Details"
              : "Issue Details"}
          </Typography>
          <Box sx={styles.cardBody}>
            {!cartLoader ? (
              <>
                <Box sx={styles.contentCard}>
                  <IssueDetailsCard
                    grievanceData={grievanceData}
                    getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
                    issueId={grievanceData?.issueId}
                    issuedDate={grievanceData?.createdOn}
                    issueType={grievanceData?.message?.issue?.sub_category}
                    status={grievanceData?.message?.issue?.status}
                    customerName={
                      grievanceData?.message?.issue?.complainant_info?.person
                        ?.name
                    }
                    phoneNumber={
                      grievanceData?.message?.issue?.complainant_info?.contact
                        ?.phone
                    }
                    orderId={grievanceData?.orderId}
                    orderStatus={grievanceData?.orderStatus}
                    responseTime={
                      grievanceData?.message?.issue?.expected_response_time
                        ?.duration
                    }
                    responseTimeStatus={responseStatus}
                    resolutionTime={
                      grievanceData?.message?.issue?.expected_resolution_time
                        ?.duration
                    }
                    resolutionTimeStatus={resolutionStatus}
                    msgResolution={
                      grievanceData?.message?.issue?.resolution?.short_desc
                    }
                    refundAmount={
                      grievanceData?.message?.issue?.resolution?.refund_amount
                    }
                    processing={grievanceData?.message?.issue?.status}
                    responseStatusSla={responseStatusSla}
                    resolutionStatusSla={resolutionStatusSla}
                  />
                </Box>
                <Typography
                  variant="h5"
                  fontSize={{ xs: 14, md: 15 }}
                  fontWeight={600}
                  color={blackShade01}
                  mb={1}
                  mt={2}
                >
                  Issue Details
                </Typography>
                <Box sx={styles.contentCard}>
                  <Stack gap={2} mb={2}>
                    {grievanceData?.message?.issue?.order_details?.items?.map(
                      (item, index) => {
                        const product = cartData?.productList?.find(
                          (data) => data?.productId === item?.id
                        );
                        return (
                          <ProductDetailsCard
                            key={"product" + item?.id}
                            imageUrl={product?.productPicURL}
                            productName={product?.productName}
                            orderQty={product?.currentQuantity}
                            issueQty={item?.quantity}
                            price={product?.productTotalPrice}
                          />
                        );
                      }
                    )}
                  </Stack>
                  <Stack gap={2} mb={2}>
                    <InputPreview
                      label="Issue"
                      value={
                        grievanceData?.message?.issue?.description?.short_desc
                      }
                    />
                    <InputPreview
                      label="Description"
                      value={
                        grievanceData?.message?.issue?.description?.long_desc
                      }
                    />
                    <Box sx={{ borderBottom: border27, pb: 2 }}>
                      <Typography
                        variant="h3"
                        fontSize={{ xs: 12, md: 13 }}
                        color={greyShade01}
                        fontWeight={400}
                      >
                        Images
                      </Typography>
                      <Box mt={1.5} display="flex" gap={2}>
                        {grievanceData?.message?.issue?.description?.images?.map(
                          (imageUrl, index) => (
                            <Box
                              component="img"
                              src={imageUrl}
                              alt=""
                              sx={styles.logoImg}
                              key={index}
                              onClick={() => {
                                setIsImageIssueModal(imageUrl);
                              }}
                            />
                          )
                        )}
                      </Box>
                    </Box>
                  </Stack>
                </Box>
                <Typography
                  variant="h5"
                  fontSize={{ xs: 14, md: 15 }}
                  fontWeight={600}
                  color={blackShade01}
                  mb={1}
                  mt={2}
                >
                  Actions Taken
                </Typography>
                <Box sx={styles.contentCard}>
                  <ActionTimeLine steps={steps} />
                </Box>
              </>
            ) : (
              <ThreeDotLoader />
            )}
          </Box>
        </Box>
      </ModalCard>
    </Box>
  );
}

export default ViewIssueModal;

const styles = {
  card: {
    backgroundColor: BGColor02,
    borderRadius: "15px",
    overflow: "hidden",
  },
  cardBody: {
    maxHeight: "80vh",
    backgroundColor: whiteColor,
    p: "20px 12px",
    overflow: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blueColor100,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: greyColor02,
      borderRadius: "50px !important",
    },
  },
  contentCard: {
    border: border27,
    p: { xs: "10px 16px", md: "10px 18px" },
    borderRadius: "10px",
  },
  logoImg: {
    width: { xs: "52px", md: "84px" },
    height: { xs: "52px", md: "84px" },
    border: border27,
    objectFit: "contain",
    borderRadius: "8px",
  },
};
