import { Box, Button, Typography } from "@mui/material";
import {
  errorTextColor,
  headingColor,
  loginPrimary,
  secondaryColor,
  greyColor1,
  whiteColor,
  greyColor6,
  blueColor03,
  authInputIconColor,
  blackShade58,
  blackShade09,
  yellowColor,
} from "../../../configs/styles/muiThemes";
import { Link } from "react-router-dom";
import {
  resetPassword_EnterPassword,
  inputError_EmailNotMatch,
  inputError_EmailUsedAlready,
  register_EnterEmail,
  register_EnterName,
  register_ReEnterEmail,
} from "../../../configs/Constants";
import AuthTextBox from "../../../components/AuthTextBox";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { useState } from "react";
import AuthPasswordBox from "../../../components/AuthPasswordBox";
import {
  getEregister,
  getLoginDetials,
  saveUserDetails,
  updateUserDetails,
} from "../../../reducers/auth/authSlice";
import { useDispatch } from "react-redux";
import {
  validateEmail,
  validateName,
  validatePasswordSave,
  validateRenterEmail,
} from "../../../utils/validationUtils";
import {
  getOwnRewards,
  upadateRewardProfile,
  userAuthGuestUser,
} from "../../../service/api_calls/auth/login_api";
import {
  checkBusinessRoleApi,
  updateBusinessDelegates,
  updateBusinessOwner,
} from "../../../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";
import { getActiveBusinessAPI } from "../../../service/api_calls/BusinessPreview/BusinessPreviewAPI";

function CreateAccountNew({ phone, token, onSuccess, userType }) {
  const [showPassword, setShowPassword] = useState(true);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [reEnterEMail, setReEnterEMail] = useState("");

  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [reEmailErr, setReEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [emailMsg, setEmailMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const symbol = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const numbers = /[0-9]/;
  const capital = /^(?=.*[A-Z])/;
  const length = /^(?=.{8,})/;

  async function handleSubmit() {
    setLoading(true);
    setNameErr("");
    setPasswordErr("");
    setEmailErr("");
    setReEmailErr("");
    setErrorMsg(false);

    const nameError = validateName(name);
    const passError = validatePasswordSave(password);
    const mailErr = validateEmail(email);
    const reMailErr = validateRenterEmail(reEnterEMail, email);

    let error = false;

    console.log("passError", passError);

    if (nameError.length > 0) {
      setNameErr(nameError);
      setLoading(false);
      error = true;
    }
    if (mailErr.length > 0) {
      setEmailErr(mailErr);
      setLoading(false);
      error = true;
    }
    // if (reMailErr.length > 0) {
    //   setReEmailErr(reMailErr);
    //   setLoading(false);
    //   error = true;
    // }

    if (passError.length > 0) {
      setPasswordErr(passError);
      setLoading(false);
      error = true;
    }

    if (error) {
      return;
    }

    if (email) {
      setEmailMsg(false);
      // setIsPasswordError(false)
      setLoading(true);
      const newUser = userType === "new-user" ? true : false;
      console.log("fkfk", newUser);

      if (newUser) {
        regisTer({
          name,
          password,
          email,
          reEnterEMail,
          phone: "+91" + phone,
          token,
        });
      } else {
        guestRegister({
          name,
          password,
          email,
          reEnterEMail,
          phone: "+91" + phone,
          token,
        });
      }
    } else {
      setEmailMsg(true);
      setLoading(false);
    }

    // if (email.toLowerCase() === reEnterEMail.toLowerCase()) {
    //   setEmailMsg(false);
    //   // setIsPasswordError(false)
    //   setLoading(true);
    //   const newUser = userType === "new-user" ? true : false;
    //   console.log("fkfk", newUser);
    //   if (newUser) {
    //     regisTer({
    //       name,
    //       password,
    //       email,
    //       reEnterEMail,
    //       phone: "+91" + phone,
    //       token,
    //     });
    //   } else {
    //     guestRegister({
    //       name,
    //       password,
    //       email,
    //       reEnterEMail,
    //       phone: "+91" + phone,
    //       token,
    //     });
    //   }
    // } else {
    //   setEmailMsg(true);
    //   setLoading(false);
    // }
  }
  const checkUser = async (phoneNumber) => {
    if (userType === "new-user") {
      return true;
    } else {
      return false;
    }
    // return dispatch(checkExistingUserGuest(phoneNumber)).then((response) => {
    //   console.log(response)
    //   if (response.payload) {
    //     if (response.payload.status === 200) {
    //       if (response.payload.data.na === false) {
    //         return true
    //       } else {
    //         return false
    //       }
    //     }
    //   }
    // })
  };

  const updateOwnerDelegate = async (data) => {
    const ownerPayload = { phoneNo: data?.mobileNumber, role: "OWNER" };

    const roleList = await checkBusinessRoleApi(ownerPayload);

    for (const business of roleList) {
      const businessData = await getActiveBusinessAPI(business?.businessId);

      const payload = {
        businessId: business?.businessId,
        data: {
          newOwnerProfileID: data?.profileId,
          newOwnerPhoneNum: data?.mobileNumber,
        },
      };

      const switchOwner = await updateBusinessOwner(payload);

      if (switchOwner) {
        const updateDelegate = {
          newAdminDelegatePhoneNum:
            businessData?.viewBusiness?.owner?.phone || "",
          businessId: business?.businessId,
          type: "ADD",
          delegates: [
            {
              profileId: businessData?.viewBusiness?.owner?.profileId || "",
              name: businessData?.viewBusiness?.owner?.name || "",
              phone: businessData?.viewBusiness?.owner?.phone || "",
              email: businessData?.viewBusiness?.owner?.email || "",
              country: "India",
              status: "active",
              role: "Admin",
              picURL: businessData?.viewBusiness?.owner?.picURL || "",
              type: 2,
              privileges: [],
            },
          ],
        };
        const updateOwner = await updateBusinessDelegates(updateDelegate);
      }
    }
  };

  const regisTer = async (values) => {
    console.log(values);
    let xmpprandomNumber = Math.floor(100000 + Math.random() * 900000);
    return dispatch(saveUserDetails({ ...values, xmpprandomNumber })).then(
      (response) => {
        console.log(response);
        if (response.payload) {
          if (response.payload.status === 200) {
            sessionStorage.setItem(
              "profileId",
              response.payload.data.profileID
            );
            // let xmpprandomNumber = Math.floor(100000 + Math.random() * 900000)
            let profileIdRandomNo =
              response.payload.data.profileID + xmpprandomNumber;
            var encodedprofileId = btoa(profileIdRandomNo);

            // const eRegister = {
            //   ePhone: values.phone,
            //   eHost: "s.costbo.com",
            //   eUser: values.phone,
            //   ePassword: encodedprofileId,
            // };
            // dispatch(getEregister({ eRegister, profileId: response.payload.data.profileID,}))

            updateOwnerDelegate({
              mobileNumber: values?.phone,
              profileId: response?.payload?.data?.profileID,
            });

            const mobileNumber = values.phone.replace("+91", "");
            const loginData = {
              phone: mobileNumber,
              password: values.password,
            };
            const type = "new";

            loginDetails(loginData, type);
          }
        } else {
          setErrorMsg(true);
          setLoading(false);
        }
      }
    );
  };
  const guestRegister = async (values) => {
    let xmpprandomNumber = Math.floor(100000 + Math.random() * 900000);
    console.log("guestRegister", values);
    let formdata = new FormData();
    formdata.append("grant_type", "password");
    formdata.append("username", values.phone);
    formdata.append("password", values.token);
    userAuthGuestUser(formdata).then((res) => {
      console.log("USER_AUTH_RESPONSE", res.data.randomNo);
      // const updateValues = { ...values, id: res.data.randomNo }
      dispatch(
        updateUserDetails({
          values,
          id: res.data.randomNo,
          token: res.data.access_token,
          xmpprandomNumber: xmpprandomNumber,
        })
      ).then((response) => {
        console.log("xmpprandomNumber", xmpprandomNumber, response);
        if (response.payload) {
          if (response.payload.status === 200) {
            sessionStorage.setItem("profileId", res.data.randomNo);
            // let xmpprandomNumber = Math.floor(100000 + Math.random() * 900000)
            let profileIdRandomNo = res.data.randomNo + xmpprandomNumber;
            var encodedprofileId = btoa(profileIdRandomNo);
            console.log(
              "xmpprandomNumber1",
              xmpprandomNumber,
              encodedprofileId
            );

            const eRegister = {
              ePhone: values.phone,
              eHost: "s.costbo.com",
              eUser: values.phone,
              ePassword: encodedprofileId,
            };

            // dispatch(getEregister({ eRegister, profileId: res.data.randomNo }));

            const mobileNumber = values.phone.replace("+91", "");
            const loginData = {
              phone: mobileNumber,
              password: values.password,
            };
            const type = "old";
            loginDetails(loginData, type);
          }
        } else {
          setErrorMsg(true);
          setLoading(false);
        }
      });
    });
  };

  const loginDetails = async (loginData, type) => {
    return dispatch(getLoginDetials(loginData)).then((response) => {
      console.log(response);
      if (response.payload) {
        if (response.payload.status === 200) {
          if (type == "new") {
            getOwnRewards("91" + phone).then((resp1) => {
              if (resp1?.data?.details) {
                upadateRewardProfile({
                  data: resp1?.data?.details,
                  phone: "91" + phone,
                }).then(() => {
                  setLoading(false);
                  // onLoginSuccess()
                });
              }
            });
          }
          setLoading(false);
          onSuccess();
        }
      } else {
        setErrorMsg(true);
        setLoading(false);
      }
    });
  };

  return (
    <Box>
      <Typography
        variant="h4"
        color={blackShade58}
        fontSize={{ xs: 17, md: 22 }}
        fontWeight={600}
        lineHeight="30px"
      >
        Create an account
      </Typography>

      <Typography
        variant="body1"
        color={greyColor6}
        fontSize={{ xs: 13, md: 14 }}
        fontWeight={400}
        lineHeight="18px"
        m="4px 0 8px !important"
      >
        One last step before registering your business!
      </Typography>

      <Typography
        variant="body1"
        color={greyColor6}
        fontSize={13.5}
        fontWeight={400}
        lineHeight="18px"
        m={{ md: "18px 0 0px !important", xs: "10px 0 0px !important" }}
      >
        Full Name{" "}
        <Typography
          component="span"
          color="red"
          fontSize={13.5}
          fontWeight={400}
        >
          *
        </Typography>
      </Typography>

      <AuthTextBox
        // placeholder={register_EnterName}
        icon={
          <PersonRoundedIcon
            sx={{ fontSize: "18px", color: authInputIconColor }}
          />
        }
        isError={nameErr}
        errorMsg={nameErr}
        value={name}
        onChange={({ target }) => {
          if (nameErr) setNameErr("");
          setName(target.value);
        }}
        // onBlur= {handleAnyOtherChange}
        name="name"
        autoFocus={true}
      />

      <Typography
        variant="body1"
        color={greyColor6}
        fontSize={13.5}
        fontWeight={400}
        lineHeight="18px"
        m="12px 0 0px !important"
      >
        Email Address{" "}
        <Typography
          component="span"
          color="red"
          fontSize={13.5}
          fontWeight={400}
        >
          *
        </Typography>
      </Typography>

      <AuthTextBox
        // placeholder={register_EnterEmail}
        icon={
          <EmailRoundedIcon
            sx={{ fontSize: "18px", color: authInputIconColor }}
          />
        }
        isError={emailErr}
        errorMsg={emailErr}
        value={email}
        onChange={({ target }) => {
          if (emailErr) setEmailErr("");
          setEmail(target.value);
        }}
        name="email"
      />

      <Typography
        variant="body1"
        color={loginPrimary}
        fontSize={12}
        fontWeight={500}
        lineHeight="18px"
      >
        A verification E-mail will be sent to this id
      </Typography>

      {/* <Typography
        variant="body1"
        color={greyColor6}
        fontSize={13.5}
        fontWeight={400}
        lineHeight="18px"
        m="12px 0 0px !important"
      >
        Re-Enter Email {" "}
        <Typography
          component="span"
          color="red"
          fontSize={13.5}
          fontWeight={400}
        >
          *
        </Typography>
      </Typography>

      <AuthTextBox
        // placeholder={register_ReEnterEmail}
        value={reEnterEMail}
        onChange={({ target }) => {
          if (reEmailErr) setReEmailErr("");
          setReEnterEMail(target.value);
        }}
        name="reEnterEMail"
        icon={
          <EmailRoundedIcon
            sx={{ fontSize: "18px", color: authInputIconColor }}
          />
        }
        isError={reEmailErr}
        errorMsg={reEmailErr}
      /> */}

      <Typography
        variant="body1"
        color={greyColor6}
        fontSize={13.5}
        fontWeight={400}
        lineHeight="18px"
        m="12px 0 0px !important"
      >
        Password{" "}
        <Typography
          component="span"
          color="red"
          fontSize={13.5}
          fontWeight={400}
        >
          *
        </Typography>
      </Typography>

      <AuthPasswordBox
        value={password}
        onChange={({ target }) => {
          if (passwordErr) setPasswordErr("");
          const value = target.value.replace(/[ ]/g, "");
          setPassword(value);
        }}
        name="password"
        placeholder={resetPassword_EnterPassword}
        isError={passwordErr}
        errorMsg={passwordErr}
        lower={length.test(password)}
        symbol={symbol.test(password.trim())}
        upper={capital.test(password)}
        numberFormat={numbers.test(password)}
        icon={
          <VisibilityRoundedIcon
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            sx={{
              fontSize: "18px",
              color: !showPassword ? loginPrimary : authInputIconColor,
            }}
          />
        }
        inputType={!showPassword ? "text" : "password"}
        isPasswordError={passwordErr}
        maxLength={25}
      />

      <Box sx={{ mt: 1.5 }}>
        <Typography
          variant="body1"
          component="p"
          color={greyColor6}
          fontSize={13}
          display="inline"
        >
          By continuing, you agree to our{" "}
          <Typography
            color={loginPrimary}
            display="inline"
            fontSize={13}
            fontWeight={500}
            component={Link}
            // target='_blank'
            to={`/terms-conditions`}
          >
            Terms of Use
          </Typography>{" "}
          &{" "}
          <Typography
            color={loginPrimary}
            display="inline"
            fontSize={13}
            fontWeight={500}
            component={Link}
            // target='_blank'
            to={`/privacy-policy`}
          >
            Privacy Policy
          </Typography>
        </Typography>
      </Box>

      {emailMsg && (
        <Box>
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={13}
            color={errorTextColor}
          >
            {inputError_EmailNotMatch}
          </Typography>
        </Box>
      )}
      {errorMsg && (
        <Box>
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={13}
            color={errorTextColor}
          >
            {inputError_EmailUsedAlready}
          </Typography>
        </Box>
      )}

      <Box display="flex" justifyContent="flex-end">
        <Button
          sx={{
            ...styles.actionBtn(),
            border: loading ? "none" : `1px solid ${yellowColor}`,
          }}
          variant="contained"
          disableElevation
          onClick={handleSubmit}
          disabled={loading}
        >
          SIGN UP & ADD BUSINESS
        </Button>
      </Box>
    </Box>
  );
}

export default CreateAccountNew;

const styles = {
  actionBtn: () => ({
    minWidth: { md: "115px", xs: "auto" },
    fontSize: { md: "14px", xs: "13px" },
    fontWeight: 600,
    color: blackShade09,
    background: yellowColor,
    p: { md: "5px 22px", xs: "4px 12px" },
    mt: { md: "20px !important", xs: "10px !important" },
    borderRadius: "6px",

    "&:hover": {
      background: "none",
      backgroundColor: whiteColor,
      color: blackShade09,
      border: `1px solid ${blackShade09}`,
    },
    letterSpacing: "0.5px",
  }),
};
