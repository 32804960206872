import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackColor,
  blueColor04,
  greyColor40,
} from "../../../configs/styles/muiThemes";
import { INVENTORY_STEP_LIST } from "../../../utils/data";

import { scrollBox } from "../../../utils/styles";
import MobileTab from "../../../components/MobileTabs/MobileTab";
import { useDispatch, useSelector } from "react-redux";
import { getInventorySummaryCounts } from "../../../reducers/inventory/inventorySlice";

const InventoryTabs = ({ tabValue, handleChange }) => {
  const dispatch = useDispatch();
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { inventorySummary, summaryLoader } = useSelector(
    (state) => state.inventory
  );

  const getProductsCountByTab = (tabCount) => {
    switch (tabCount) {
      case 0:
        return inventorySummary?.inStock || 0;

      case 1:
        return inventorySummary?.outOfStock || 0;

      case 2:
        return inventorySummary?.lowStock || 0;

      case 3:
        return inventorySummary?.inStock || 0;

      default:
        return "XX";
    }
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!inventorySummary) {
        dispatch(getInventorySummaryCounts(businessId));
      }
    }
    return () => {};
  }, []);

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        mb: { xs: "10px", md: "10px" },
        mt: 1,
      }}
    >
      {matches ? (
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => handleChange(event, newValue)}
          textColor={blackColor}
          TabIndicatorProps={{
            style: {
              height: "3px",
              backgroundColor: blueColor04,
            },
          }}
          sx={{
            minHeight: "35px !important",
            "& .MuiButtonBase-root": {
              minWidth: "auto !important",
            },
          }}
        >
          {INVENTORY_STEP_LIST?.map((item, index) => {
            return (
              <Tab
                key={"filter_" + index}
                label={
                  item.label?.toLowerCase() +
                  ` - ${summaryLoader ? "XX" : getProductsCountByTab(item.id)}`
                }
                sx={{
                  fontSize: { xs: "11px", md: "13.5px" },
                  fontWeight: tabValue == index ? 500 : 500,
                  p: 0,
                  minHeight: "30px !important",
                  mr: { xs: "10px", md: "55px" },
                  color: item.id === tabValue ? blueColor04 : greyColor40,
                  textTransform: "capitalize",
                }}
              />
            );
          })}
        </Tabs>
      ) : (
        <Box sx={{ ...styles.mobileTabs, ...scrollBox }}>
          {INVENTORY_STEP_LIST?.map((item, index) => {
            return (
              <MobileTab
                key={"inventory_tab_" + index}
                title={item.label?.toUpperCase()}
                count={summaryLoader ? "XX" : getProductsCountByTab(item.id)}
                isActive={tabValue == index}
                onClick={() => handleChange("", item.id)}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default InventoryTabs;

const styles = {
  mobileTabs: {
    width: { xs: "90vw", md: "calc(100% - 100px)" },
    display: "flex",
    alignItems: "center",
    // borderBottom: logoImgborder,
  },
};
