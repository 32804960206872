import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  activeBGColor,
  activeTextColor,
  blackColor,
  blackShade05,
  draftBGColor,
  draftTextColor,
  greyColor40,
  greyColor6,
  inactiveBGColor,
  inactiveTextColor,
  logoImgborder,
  logoImgborder1,
  pendingBGColor,
  pendingTextColor,
  rejectedBGColor,
  rejectedTextColor,
  whiteColor,
} from "../../configs/styles/muiThemes";

import { textOneLines, textTwoLines } from "../../utils/styles";
import ProfileCharAvatar from "../ProfileCharAvatar";
import moment from "moment";
import LazyLoad from "react-lazyload";

function ChooseBusinessCard({
  businessName,
  image,
  status,
  onClick,
  disabled,
  type,
  city
}) {
  const [imageExists, setImageExists] = useState(true);

  const loadingRef = useRef(null);
  const productImgRef = useRef(null);

  const getBGColor = () => {
    if (status === "active") return activeBGColor;
    if (status === "draft") return inactiveBGColor;
    if (status === "pending") return pendingBGColor;
    if (status === "rejected") return rejectedBGColor;
    if (status === "inactive") return draftBGColor;

    return draftBGColor;
  };

  const getStatusTextColor = () => {
    if (status === "active") return activeTextColor;
    if (status === "draft") return inactiveTextColor;
    if (status === "pending") return pendingTextColor;
    if (status === "rejected") return rejectedTextColor;
    if (status === "inactive") return draftTextColor;

    return whiteColor;
  };

  return (
    <Box
      sx={styles.card(disabled)}
      onClick={() => {
        if (disabled) return;
        onClick();
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          p: "8px 8px 0 8px",
        }}
      >
        {!imageExists ? (
          <Box>
            <ProfileCharAvatar
              username={businessName}
              border
              size={"45px"}
              borderRadius="10px"
            />
          </Box>
        ) : (
          // <Box
          //   component="img"
          //   alt="Business Logo"
          //   src={`${image}?tag${moment()?.valueOf()}`}
          //   width="55px"
          //   height="55px"
          //   sx={{ borderRadius: "10px", border: logoImgborder }}
          //   onError={() => {
          //     setImageExists(false);
          //   }}
          // />
          <>
            <Box ref={loadingRef}>
              <Skeleton
                variant="rectangular"
                width="45px"
                height="45px"
                sx={{ borderRadius: "10px", mr: "-12px" }}
              />
            </Box>
            <LazyLoad throttle={200}>
              <Box
                ref={productImgRef}
                component="img"
                alt="Business Logo"
                src={`${image}?tag${moment()?.valueOf()}`}
                width="1px"
                height="1px"
                sx={{ borderRadius: "10px", border: logoImgborder }}
                onLoad={() => {
                  loadingRef.current.style.display = "none";
                  productImgRef.current.style.width = "45px";
                  productImgRef.current.style.height = "45px";
                }}
                // onError={({ currentTarget }) => {
                //   currentTarget.onerror = null; // prevents looping
                //   currentTarget.src = noProductImgUrl;
                //   setDisableCard(true);
                // }}
                onError={() => {
                  setImageExists(false);
                }}
              />
            </LazyLoad>
          </>
        )}
        <Stack>
          <Typography
            variant="body1"
            // height="45px"
            color={blackShade05}
            fontSize={13}
            lineHeight="22px"
            fontWeight={600}
            sx={textOneLines}
          >
            {businessName?.length > 25
              ? businessName?.slice(0, 25) + "..."
              : businessName}
          </Typography>
          {type && (
            <Typography
              color={greyColor40}
              fontSize={12}
              fontWeight={400}
            >
              {type}
            </Typography>
          )}
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: { xs: status === "draft" ? 1 : -2, md: 0 },
        }}
      >
        {status === "draft" ? (
          <Button sx={styles.editBtn} onClick={() => {}}>
            Edit Business
          </Button>
        ) : (
          <Box />
        )}

        <Box sx={{ ...styles.statusTag, backgroundColor: getBGColor() }}>
          <Typography
            variant="body1"
            fontSize={10.5}
            fontWeight={500}
            lineHeight="22px"
            color={getStatusTextColor()}
            sx={{ textTransform: "uppercase" }}
          >
            {status}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ChooseBusinessCard;

const styles = {
  card: (isDisabled) => ({
    width: "100%",
    background: whiteColor,
    border: logoImgborder1,
    borderRadius: "15px",
    boxShadow: "0 2px 4px rgb(179 188 198 / 10%)",
    opacity: isDisabled ? 0.6 : 1,
  }),

  editBtn: {
    fontSize: 12,
    fontWeight: 500,
    textDecoration: "underline",
    padding: "0 15px 0px",
    textTransform: "unset",
    color: greyColor6,

    "&:hover": {
      backgroundColor: whiteColor,
    },
  },

  statusTag: {
    borderRadius: "10px 0",
    padding: { md: "3px 17px", xs: "0px 15px" },
  },
};