import { getApiInstance } from "../../AppService";
import {
  DELETE_BANNER_DATA,
  INVENTORY_ONDC_FILTER,
  INVENTORY_PRODUCTS_BY_STATUS,
  PRODUCTS_BY_BUSINESS,
  UPDATE_INVENTORY,
} from "../../constant";

export const updateInventoryData = async (data, businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });
    const response = await instance.post(UPDATE_INVENTORY, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBusinessProductsAPI = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(
      PRODUCTS_BY_BUSINESS + data?.businessId,
      {
        params: {
          business: true,
          percentageOff: false,
          highToLow: false,
          lowToHigh: false,
          pageSize: data?.pageSize || 500,
          pageNo: data?.pageNo || 1,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getInventoryProductsByStatusAPI = async (data) => {
  try {
    const instance = getApiInstance({
      header: { businessId: data?.businessId },
    });
    const response = await instance.get(INVENTORY_PRODUCTS_BY_STATUS, {
      params: {
        status: data?.status || "all",
        ONDC_enabled: "all",
        pageSize: data?.pageSize || 50,
        page: data?.pageNo || 1,
        search: data?.searchQuery || "",
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getInventoryOndcFilterAPI = async (data) => {
  try {
    const instance = getApiInstance({
      header: { businessId: data?.businessId },
    });
    const response = await instance.get(INVENTORY_ONDC_FILTER, {
      params: {
        status: data?.status || "all",
        ONDC_enabled: "all",
        pageSize: data?.pageSize || 50,
        page: data?.pageNo || 1,
        productMainCategory_ondcCode: data?.ondcMainCat || "",
        productSubCategory_ondcSubCode: data?.ondcSubCat || "all",
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
