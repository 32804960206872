import { Box, Button, Grid, Typography } from "@mui/material";
import {
  BGColor02,
  blackShade05,
  blackShade19,
  blueColor04,
  greyColor40,
  heroSectionText,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import FormInputBox from "../../../components/Input/FormInputBox";
import { useSelector } from "react-redux";
import { useState } from "react";
import { checkUser } from "../../../api/auth/loginApi";
import {
  preferredDiscountGetEmailOrPhoneNumberApi,
} from "../../../service/api_calls/preferredDiscounts/PreferredDiscountsApi";
import {
  addNewBusinessOwner,
  sendBusinessWhatsappNotificationApi,
  updateBusinessDelegates,
} from "../../../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";
import { prepareBusinessWhatsappMessage } from "../../../utils/postDataHelpers/businessWhatsappMsgHelper";

function AddNewManager({
  ownerInfo,
  delegates,
  managersNotRegistered,
  adminNotRegistered,
  onClose,
  onSuccess,
}) {
  const [currentPage, setCurrentPage] = useState("add-phone");

  const [isLoading, setIsLoading] = useState(false);

  const [newPhoneNo, setNewPhoneNo] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [userDetails, setUserDetails] = useState(null);
  const [userName, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState("");

  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  function doesPhoneNoExist(phoneNo, arrayOfObjects, key) {
    return (
      arrayOfObjects?.find(
        (obj) => obj?.[key]?.replace("+91", "") === phoneNo
      ) !== undefined
    );
  }

  const verifyPhoneNumber = async () => {
    if (newPhoneNo?.length < 10) {
      setPhoneError("Enter valid phone number");
      return;
    }

    if (ownerInfo?.phone?.replace("+91", "") == newPhoneNo) {
      setPhoneError("This user is the Super Admin");
      return;
    }

    if (doesPhoneNoExist(newPhoneNo, delegates, "phone")) {
      setPhoneError("This user is already a Manager");
      return;
    }

    if (doesPhoneNoExist(newPhoneNo, adminNotRegistered, "phoneNo")) {
      setPhoneError(
        "This user has been granted Admin access already. Yet to register in CostBo"
      );
      return;
    }
    if (doesPhoneNoExist(newPhoneNo, managersNotRegistered, "phoneNo")) {
      setPhoneError(
        "This user has been granted Manager access already. Yet to register in CostBo"
      );
      return;
    }

    setIsLoading(true);

    const checkNumberRes = await checkUser({ phone: newPhoneNo });

    if (checkNumberRes) {
      console.log("checkNumberRes", checkNumberRes);
      if (checkNumberRes?.data?.sr) {
        setCurrentPage("new-user");
        setIsLoading(false);
        return;
      }

      if (!checkNumberRes?.data?.sr && checkNumberRes?.data?.pn2?.length > 0) {
        const profileInfo = await preferredDiscountGetEmailOrPhoneNumberApi({
          phoneNumber: newPhoneNo,
        });

        console.log("profileInfo", profileInfo);
        setUserDetails(profileInfo?.accountSummary);
        setCurrentPage("existing-user");
        setIsLoading(false);
        return;
      }
    }
  };

  const newUserSwitchOwner = async () => {
    if (userName === "") {
      setUserNameError("Enter user name");
      return;
    }

    setIsLoading(true);
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const payload = {
      businessId,
      profileId: "",
      phoneNo: "+91" + newPhoneNo,
      name: userName,
      role: "ADMIN",
      type: "2",
    };

    const newOwner = await addNewBusinessOwner(payload);

    if (newOwner) {
      // Sending Removed Whatsapp msg: removed as delegate
      const addedNotificationData = prepareBusinessWhatsappMessage({
        businessId,
        code: "B014",
        ownerName: userName || "",
        brandName: activeBusinessInfo?.name || "",
        phoneNumber: "+91" + newPhoneNo,
      });

      await sendBusinessWhatsappNotificationApi(addedNotificationData);

      setIsLoading(false);
      onSuccess();
    }
  };

  const existingIserSwitchOwner = async (profileId, phoneNumber) => {
    setIsLoading(true);
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const updateDelegate = {
      newAdminDelegatePhoneNum: "+91" + newPhoneNo,
      businessId: businessId,
      type: "ADD",
      delegates: [
        {
          profileId: userDetails?.profileId || "",
          name: userDetails?.name || "",
          phone: "+91" + newPhoneNo,
          email: userDetails?.email || "",
          country: "India",
          status: "active",
          role: "Admin",
          picURL: userDetails?.picURL || "",
          type: 2,
          privileges: [],
        },
      ],
    };
    const updateOwner = await updateBusinessDelegates(updateDelegate);

    // Sending Removed Whatsapp msg: removed as delegate
    const addedNotificationData = prepareBusinessWhatsappMessage({
      businessId,
      code: "B011",
      ownerName: userDetails?.name || "",
      brandName: activeBusinessInfo?.name || "",
      phoneNumber: "+91" + newPhoneNo,
    });

    await sendBusinessWhatsappNotificationApi(addedNotificationData);

    setIsLoading(false);
    onSuccess();
  };

  const handleSubmit = () => {
    if (currentPage === "add-phone") {
      verifyPhoneNumber();
    } else if (currentPage === "existing-user") {
      existingIserSwitchOwner(userDetails?.profileId, newPhoneNo);
    } else if (currentPage === "new-user") {
      newUserSwitchOwner(null, newPhoneNo);
    } else {
      return false;
    }
  };
  return (
    <Box sx={styles.card}>
      <Box p="10px 15px 0">
        <Typography
          variant="h3"
          fontSize={18}
          fontWeight={600}
          lineHeight="34px"
          color={blackShade05}
          textAlign="center"
        >
          Add Managers/Delegates
        </Typography>
        {/* <Typography
          fontSize={13}
          fontWeight={500}
          color={greyColor9}
          textAlign="center"
        >
          Super Admin access will be switched to the new user and you will be
          added as a delegate to this business
        </Typography> */}
      </Box>

      {currentPage === "add-phone" && (
        <Box sx={styles.infoContainer}>
          <FormInputBox
            label="Phone Number"
            placeholder=""
            startIcon="+91"
            maxLength={10}
            value={newPhoneNo}
            onChange={({ target }) => {
              const number = target.value?.replace(/[^0-9]/g, "");
              setNewPhoneNo(number);
              setPhoneError("");
            }}
            error={phoneError}
          />
        </Box>
      )}

      {currentPage === "new-user" && (
        <Box sx={styles.infoContainer}>
          <Grid container alignItems="flex-end" spacing={3}>
            <Grid item xs={7}>
              <FormInputBox
                label="Phone Number"
                placeholder=""
                startIcon="+91"
                maxLength={10}
                value={newPhoneNo}
                disabled
              />
            </Grid>

            <Grid item xs={5}>
              <Typography
                fontSize={12.5}
                fontWeight={500}
                color={heroSectionText}
                sx={{ textDecoration: "underline", cursor: "pointer", mb: 2 }}
                onClick={() => {
                  setCurrentPage("add-phone");
                  setUserName("");
                  setUserNameError("");
                }}
              >
                Change Phone No
              </Typography>
            </Grid>
          </Grid>
          <FormInputBox
            label="Name"
            placeholder="Enter Name"
            required
            value={userName}
            onChange={({ target }) => {
              setUserName(target?.value);
              setUserNameError("");
            }}
            error={userNameError}
          />
          <Typography component="p" sx={styles.infoText}>
            This user is currently not registered in CostBo.
          </Typography>
          <Typography component="p" sx={styles.infoText}>
            Manager access will be granted once they register in CostBo
          </Typography>
        </Box>
      )}

      {currentPage === "existing-user" && (
        <Box sx={styles.infoContainer}>
          <Grid container alignItems="flex-end" spacing={3}>
            <Grid item xs={7}>
              <FormInputBox
                label="Phone Number"
                placeholder=""
                startIcon="+91"
                maxLength={10}
                value={newPhoneNo}
                disabled
              />
            </Grid>

            <Grid item xs={5}>
              <Typography
                fontSize={12.5}
                fontWeight={500}
                color={heroSectionText}
                sx={{ textDecoration: "underline", cursor: "pointer", mb: 2 }}
                onClick={() => {
                  setCurrentPage("add-phone");
                }}
              >
                Change Phone No
              </Typography>
            </Grid>
          </Grid>

          <Box mt={1}>
            <Typography fontSize={13} fontWeight={400} color={greyColor40}>
              Name
            </Typography>

            <Typography
              fontSize={13}
              fontWeight={500}
              color={blackShade19}
              mt={0.5}
            >
              {userDetails?.name}
            </Typography>
          </Box>

          <Box mt={1.5}>
            <Typography fontSize={13} fontWeight={400} color={greyColor40}>
              Email Id
            </Typography>

            <Typography
              fontSize={13}
              fontWeight={500}
              color={blackShade19}
              mt={0.5}
            >
              {userDetails?.email || "-"}
            </Typography>
          </Box>
        </Box>
      )}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p="15px"
      >
        <Button sx={[styles.actionBtn, styles.cancelBtn]} onClick={onClose}>
          Cancel
        </Button>

        <Button
          sx={[styles.actionBtn, styles.proceedBtn]}
          onClick={() => {
            handleSubmit();
          }}
          //   startIcon={
          //     isLoading && (
          //       <CircularProgress size={18} sx={{ color: whiteColor }} />
          //     )
          //   }
          disabled={isLoading}
        >
          {currentPage === "add-phone" ? "Continue" : "ADD AS Managers"}
        </Button>
      </Box>
    </Box>
  );
}

export default AddNewManager;

const styles = {
  card: {
    backgroundColor: BGColor02,
    borderRadius: "15px",
  },

  infoContainer: {
    backgroundColor: whiteColor,
    p: "15px",
    mt: "10px",
  },

  actionBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    color: blueColor04,
    border: `1px solid ${blueColor04}`,
    p: "4px 20px",
    backgroundColor: whiteColor,

    "&.Mui-disabled": {
      color: blackShade19,
    },
  },

  cancelBtn: {
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },

  proceedBtn: {
    color: whiteColor,
    background: blueColor04,
    border: `1px solid ${blueColor04}`,

    "&:hover": {
      color: blueColor04,
      background: whiteColor,
    },
  },

  infoText: {
    fontSize: 13,
    fontWeight: 500,
    color: blackShade19,
    textAlign: "center",
    mb: "10px",
  },
};
