import {
  Avatar,
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import {
  bgColor15,
  blackShade05,
  blackShade22,
  blueColor02,
  blueColor04,
  greenColor2,
  greyColor8,
  mobilePrimary,
  orderCardBorder,
  orderCardBorder2,
  primaryColor,
  redStatusColor,
  shareBgColor,
  shareBgColor1,
  whiteColor,
} from "../../configs/styles/muiThemes";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ProfileCharAvatar from "../../components/ProfileCharAvatar";

function PaycartOrderCard({
  profileImg,
  username,
  phoneNumber,
  email,
  orderId,
  address,
  orderCost,
  cartCount,
  cartDate,
  orderDate,
  isPaid,
  paymentLink,
  orderDetailsLink,
  handleViewOrder,
  handleTrackOrder,
  status,
  onWhatsappClick,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const openInNewTab = (link) => {
    window.open(link, "_blank");
  };

  return (
    <Box sx={styles.card}>
      <Box
        sx={{
          ...styles.cardHeader,
          borderLeft: {
            xs: "none",
            md: `3px solid ${isPaid ? greenColor2 : redStatusColor}`,
          },
        }}
      >
        <ProfileInfoBox
          profileImg={profileImg}
          name={username}
          phoneNumber={phoneNumber}
          email={email}
          matches={matches}
          isPaid={isPaid}
        />

        <Box
          flex={1.9}
          p={{ xs: "0 15px", md: "0 12px 0 0" }}
          borderRight={{ xs: "none", md: orderCardBorder2 }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h5" sx={styles.subTextStyle}>
                Order Id:
              </Typography>
              <Typography
                variant="h5"
                fontSize={12}
                fontWeight={600}
                color={blackShade05}
                lineHeight={1.5}
                mt={0.5}
              >
                {orderId}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h5" sx={styles.subTextStyle}>
                {isPaid ? "Order Date:" : "Requested Date:"}
              </Typography>
              <Typography
                variant="h5"
                fontSize={12}
                fontWeight={600}
                color={blackShade05}
                lineHeight={1.5}
                mt={0.5}
              >
                {isPaid ? orderDate : cartDate}
              </Typography>
            </Box>
          </Box>

          <Typography variant="body1" sx={styles.subTextStyle} mt={1.5}>
            {address}
          </Typography>
        </Box>

        <Box
          flex={1.2}
          display="flex"
          gap={2}
          width={{ md: "auto", xs: "100%" }}
          alignItems={"flex-start"}
        >
          <Box
            sx={{
              ...styles.cartInfoBox,
              flex: 0.8,
            }}
          >
            <Box display="flex" alignItems="center" gap="10px">
              <CreditCardOutlinedIcon sx={{ fontSize: "20px" }} />

              <Typography
                variant="h5"
                fontSize={13}
                fontWeight={500}
                color={blackShade05}
                lineHeight="20px"
              >
                ₹ {orderCost}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="10px" mt="2px">
              <ShoppingCartRoundedIcon
                sx={{ fontSize: "16px", color: bgColor15 }}
              />

              <Typography variant="body1" sx={styles.subTextStyle} ml={1}>
                {cartCount}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              ...styles.cartInfoBox,
              flex: 1.1,
              alignItems: { xs: "flex-end", md: "flex-start" },
              p: { md: "0 12px 12px 0", xs: 0 },
            }}
          >
            <Typography
              variant="h5"
              fontSize={13}
              fontWeight={600}
              color={blackShade05}
              display={{ md: "block", xs: "none" }}
              lineHeight={1.5}
            >
              Status
            </Typography>
            <Box sx={styles.paymentStatus} my={0.5}>
              <Box
                width="8px"
                height="8px"
                borderRadius="50%"
                backgroundColor={isPaid ? greenColor2 : redStatusColor}
              />

              <Typography
                variant="body2"
                fontSize={12}
                fontWeight={600}
                color={isPaid ? greenColor2 : redStatusColor}
              >
                {isPaid ? "PAID" : "NOT PAID"}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              sx={{ ...styles.subTextStyle, fontSize: 12.5 }}
            >
              {status}
            </Typography>
          </Box>
        </Box>

        <Box
          width={{ xs: "100%", md: "auto" }}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: "flex-end", md: "center" }}
          gap={{ xs: "15px", md: "6px" }}
          flex={0.8}
          px={1}
          mb={1.5}
        >
          <Button
            sx={styles.viewBtn}
            variant="contained"
            disableElevation
            onClick={handleViewOrder}
          >
            View
          </Button>

          <Button
            sx={{ ...styles.viewBtn, ...styles.trackBtn(isPaid) }}
            variant="contained"
            disableElevation
            disabled={!isPaid}
            onClick={handleTrackOrder}
          >
            Track
          </Button>
        </Box>
      </Box>

      <Box sx={styles.cardFooter}>
        <InfoLinkWithCopy
          icon={
            <CurrencyRupeeIcon sx={{ fontSize: "16px", color: whiteColor }} />
          }
          label="Payment Link:"
          link={paymentLink}
          onClick={openInNewTab}
          isPaid={isPaid}
        />

        <InfoLinkWithCopy
          icon={
            <ShoppingCartOutlinedIcon
              sx={{ fontSize: "16px", color: whiteColor }}
            />
          }
          label="Order Details:"
          link={orderDetailsLink}
          onClick={openInNewTab}
        />
        {!isPaid ? (
          <Box
            display="flex"
            alignItems="center"
            gap={{ md: "10px", xs: "5px" }}
            sx={{ cursor: "pointer", width: "100px" }}
            onClick={onWhatsappClick}
            mt={{ md: 0, xs: "8px" }}
            mb={{ md: 0, xs: "8px" }}
          >
            <Box
              sx={{
                ...styles.iconCircle,
                display: "flex",
                background: shareBgColor1,
              }}
            >
              <WhatsAppIcon
                sx={{ fontSize: { md: "16px", xs: "14px" }, color: whiteColor }}
              />
            </Box>

            <Typography
              variant="body2"
              fontSize={12}
              fontWeight={500}
              color={{ md: blueColor04, xs: mobilePrimary }}
              sx={{ overflowWrap: "anywhere" }}
            >
              Share
            </Typography>
          </Box>
        ) : (
          <Box sx={{ width: "100px" }} />
        )}
      </Box>
    </Box>
  );
}

export default PaycartOrderCard;

const ProfileInfoBox = ({
  profileImg,
  name,
  phoneNumber,
  email,
  isPaid,
  matches,
}) => {
  return (
    <Box
      flex={1}
      display="flex"
      alignItems="center"
      gap="10px"
      borderRight={{ xs: "none", md: orderCardBorder2 }}
      borderLeft={{
        xs: `3px solid ${isPaid ? greenColor2 : redStatusColor}`,
        md: "none",
      }}
      p={{ xs: "15px 15px 0 15px", md: "0 12px 0 0" }}
      borderRadius={{ xs: "7px 0 0 0", md: "0" }}
    >
      {profileImg ? (
        <Avatar
          alt="Remy Sharp"
          src={profileImg}
          sx={{
            width: 46,
            height: 46,
            border: "2px solid #e2e5ec",
            marginRight: 0,
            marginBottom: "3px",
          }}
        ></Avatar>
      ) : (
        <ProfileCharAvatar
          username={name || "Guest"}
          border
          size="46px"
          fontSize={17.5}
        />
      )}
      <Box>
        <Typography
          variant="h5"
          fontSize={14}
          fontWeight={600}
          color={blackShade05}
          lineHeight="24px"
        >
          {name || "Guest"}
        </Typography>

        <Typography variant="p" sx={styles.subTextStyle}>
          +91 {phoneNumber?.replace("+91", "")}
        </Typography>
      </Box>
    </Box>
  );
};

const InfoLinkWithCopy = ({ icon, label, link, isPaid, onClick }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <Box display="flex" alignItems="center" gap="10px">
      <Box sx={styles.iconCircle}>{icon}</Box>

      <Box flex={1}>
        <Typography
          variant="h5"
          fontSize={{ xs: 12, md: 12 }}
          fontWeight={600}
          color={blackShade05}
          lineHeight="24px"
        >
          {label}
        </Typography>

        <Box display="flex" alignItems="flex-end" gap="7px">
          <Typography
            variant="body2"
            fontSize={12}
            fontWeight={500}
            color={{md:blueColor04,xs:mobilePrimary}}
            sx={{
              overflowWrap: "anywhere",
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => onClick(link)}
          >
            {link}
          </Typography>

          {isPaid ? (
            <Typography
              variant="body2"
              fontSize={13}
              fontWeight={600}
              color={primaryColor}
            >
              PAID
            </Typography>
          ) : (
            <IconButton
              sx={{ width: "18px", height: "18px" }}
              onClick={handleCopy}
            >
              {isCopied ? (
                <CheckCircleOutlinedIcon
                  sx={{ fontSize: 16, color: greenColor2 }}
                />
              ) : (
                <ContentCopyOutlinedIcon
                  sx={{ fontSize: 16, color: {md:blueColor04,xs:mobilePrimary} }}
                />
              )}
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: whiteColor,
    border: orderCardBorder,
    borderRadius: "7px",
    boxShadow: "0 2px 4px rgb(179 188 198 / 5%)",
    marginBottom: "15px",
    overflow: "hidden",
  },

  cardHeader: {
    display: "flex",
    alignItems: { xs: "flex-start", md: "center" },
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "16px", md: "20px" },
    borderLeft: { xs: "none", md: `3px solid ${greenColor2}` },
    padding: { xs: "0px", md: "10px" },
    borderRadius: "7px 0 0 0",
  },

  avatarImg: {
    width: 46,
    height: 46,
    border: "2px solid #e2e5ec",
    marginRight: 0,
    marginBottom: "3px",
  },

  titleStyle: {
    fontSize: 13,
    fontWeight: 500,
    color: blackShade05,
    lineHeight: "16px",
  },

  subTextStyle: {
    fontSize: 12,
    fontWeight: 400,
    color: bgColor15,
    lineHeight: "16px",
    overflowWrap: "anywhere",
  },

  cartInfoBox: {
    p: "0 12px 12px 0",
    display: "flex",
    flexDirection: { xs: "column", md: "column" },
    alignItems: { xs: "flex-start", md: "flex-start" },
    justifyContent: { xs: "flex-start", md: "flex-start" },
    borderRight: { xs: "none", md: orderCardBorder2 },
    m: { xs: "0 15px", md: 0 },
  },

  dateInfo: {
    width: { xs: "calc(100% - 30px)", md: "auto" },
    gap: "15px",
    display: "flex",
    alignItems: { xs: "center", md: "flex-start" },
    justifyContent: { xs: "space-between", md: "flex-start" },
    borderBottom: { xs: orderCardBorder2, md: "none" },
    m: { xs: "0 15px", md: 0 },
  },

  viewBtn: {
    padding: "5px 24px",
    fontSize: "12px",
    fontWeight: 600,
    color: { md: blackShade22, xs: blackShade22 },
    backgroundColor: whiteColor,
    border: {
      md: "1.5px solid " + blackShade22,
      xs: "1.5px solid " + blackShade22,
    },
    "&:hover": {
      color: whiteColor,
      backgroundColor: { md: blackShade22, xs: blackShade22 },
    },
  },

  trackBtn: (isActive) => ({
    color: whiteColor,
    backgroundColor: { md: blueColor04, xs: mobilePrimary },
    border: isActive
      ? { md: `1.5px solid ${blueColor04}`, xs: `1.5px solid ${mobilePrimary}` }
      : "1.5px solid rgba(0, 0, 0, 0.12)",
    "&:hover": {
      color: { md: blueColor04, xs: mobilePrimary },
      backgroundColor: whiteColor,
    },
  }),

  cardFooter: {
    display: "flex",
    gap: { xs: 1, md: 0 },
    flexDirection: { xs: "column", md: "row" },
    alignItems: { xs: "flex-start", md: "center" },
    justifyContent: "space-between",
    backgroundColor: whiteColor,
    borderTop: orderCardBorder2,
    padding: "6px 12px",
  },

  iconCircle: {
    width: { md: "30px", xs: "24px" },
    height: { md: "30px", xs: "24px" },
    display: { md: "flex", xs: "none" },
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: blueColor04,
    borderRadius: "15px",
  },

  paymentStatus: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
};
