import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import {
  BGColor02,
  blackShade05,
  blackShade19,
  blueColor04,
  greyColor7,
  greyColor8,
  greyColor9,
  menuGradient,
  mobilePrimary,
  whiteColor,
} from "../../configs/styles/muiThemes";
import FormInputBox from "../../components/Input/FormInputBox";

function AddCouponModalContent({
  data,
  handleValueChange,
  onCancel,
  onSubmit,
  isLoading,
}) {
  return (
    <Box sx={styles.card}>
      <Typography
        variant="h3"
        fontSize={16}
        fontWeight={600}
        lineHeight="25px"
        color={blackShade05}
        textAlign="center"
        p="15px 50px"
      >
        New Coupon/Promo Code
      </Typography>

      <Box
        sx={{
          backgroundColor: whiteColor,
          p: "15px 15px 0px 15px",
        }}
      >
        <FormInputBox
          label="New Promo Code"
          placeholder="Ex: DIWALIOFFER25 (Max 15 Characters)"
          value={data?.couponCode}
          onChange={({ target }) => {
            const value = target?.value
              ?.replace(/[^A-Za-z0-9]/g, "")
              ?.toUpperCase();

            handleValueChange("couponCode", value);
          }}
          maxLength={15}
          required
          error={data?.errors?.couponCode}
        />

        <FormInputBox
          label="Discount Percentage(%)"
          placeholder="Max 99 %"
          value={data?.discountPercentage}
          onChange={({ target }) => {
            // const value = target?.value?.replace(/[^0-9]/g, "");
            // if (value !=0 && value < 100) {
            //   handleValueChange("discountPercentage", value);
            // }
            if (
              !isNaN(target.value) &&
              target.value.charAt(0) !== "0" &&
              target.value < 100
            ) {
              handleValueChange("discountPercentage", target.value);
            }
          }}
          required
          error={data?.errors?.discountPercentage}
        />

        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <FormInputBox
              label="Valid Start Date"
              placeholder=""
              value={data?.startDate}
              onChange={({ target }) => {
                handleValueChange("startDate", target?.value);
              }}
              inputType="date"
              required
              error={data?.errors?.startDate}
            />
          </Grid>

          <Grid item xs={6} md={6}>
            <FormInputBox
              label="Valid End Date"
              placeholder=""
              value={data?.endDate}
              onChange={({ target }) => {
                handleValueChange("endDate", target?.value);
              }}
              inputType="date"
              required
              error={data?.errors?.endDate}
            />
          </Grid>
        </Grid>

        <Typography
          variant="subtitle1"
          component="p"
          fontSize={12}
          style={{ color: "red" }}
        >
          {data?.errors?.startEndDiff}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p="15px 15px 15px 15px"
      >
        <Button sx={[styles.actionBtn, styles.cancelBtn]} onClick={onCancel}>
          CANCEL
        </Button>

        <Button
          sx={[styles.actionBtn, styles.proceedBtn]}
          onClick={onSubmit}
          disabled={isLoading}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default AddCouponModalContent;

const styles = {
  card: {
    backgroundColor: BGColor02,
    borderRadius: "15px",
  },

  actionBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    color: blueColor04,
    border: `1px solid ${blueColor04}`,
    p: { xs: "3px 20px", md: "4px 25px" },
  },  

  cancelBtn: {
    color: { xs: blackShade19, md: blueColor04 },
    border: { xs: `1px solid ${blackShade19}`, md: `1px solid ${blueColor04}` },
    "&:hover": {
      color: whiteColor,
      backgroundColor: { xs: blackShade19, md: blueColor04 },
    },
  },

  proceedBtn: {
    color: whiteColor,
    background: { xs: mobilePrimary, md: blueColor04 },
    p: { xs: "3px 20px", md: "4px 25px" },
    border: "none",

    "&:hover": {
      color: { xs: mobilePrimary, md: blueColor04 },
      background: whiteColor,
      border: {
        xs: `1px solid ${mobilePrimary}`,
        md: `1px solid ${blueColor04}`,
      },
    },

    "&.Mui-disabled": {
      color: whiteColor,
      background: {
        xs: `${mobilePrimary} !important`,
        md: `${blueColor04} !important`,
      },
      borderColor: { xs: mobilePrimary, md: blueColor04 },
    },
  },
};
