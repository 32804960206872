import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BusinessInfoCard from "../components/Cards/BusinessInfoCard";
import {
  BGColor02,
  blackColor,
  blueColor04,
  primaryColor,
  whiteColor,
} from "../configs/styles/muiThemes";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import AddProductForm from "./Catalog/Forms/AddProductForm";
import CategorySpecsForm from "./Catalog/Forms/CategorySpecsForm";
import MeasurementDeliveryForm from "./Catalog/Forms/MeasurementDeliveryForm";
import PriceTaxInfoForm from "./Catalog/Forms/PriceTaxInfoForm";
import DashboardHeader from "./Dashboard/DashboardHeader";
import DashboardMenu from "./Dashboard/DashboardMenu";
import {
  addNewProductApi,
  getUnitOfMeasurementApi,
} from "../service/api_calls/catalog/CatalogAPI";
import {
  getAddNewProductPostData,
  getUpdateProductPostData,
  prepareAddNewProductPhotoData,
} from "../utils/postDataHelpers/CatalogHelper";
import {
  getAllDeliveryMethods,
  getCountryNames,
  getGstRates,
} from "../reducers/catalog/CatalogSlice";
import ModalCard from "../components/ModalCard";
import DataPreview from "./Catalog/Forms/DataPreview";
import ProductAvailabilityForm from "./Catalog/Forms/ProductAvailabilityForm";
import ThreeDotLoader from "../components/ThreeDotLoader";
import moment from "moment";
import ONDCFrom from "./Catalog/Forms/ONDCFrom";
import { ONDC_DEFAULT_DATA } from "../utils/data/defaultFormData";
import { isStringValidDateFormat, sortByValue } from "../utils/helper";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import { stickyHeader } from "../utils/styles";
import DashboardStepper from "./Dashboard/DashboardStepper";
import { CATALOG_ADD_PRODUCT_STEP_LIST } from "../utils/appData";
import { DEFAULT_COUNTRY } from "../utils/data";
import BreadcrumbSection from "../components/Breadcrumb/BreadcrumbSection";

function AddProduct() {
  const businessName = sessionStorage.getItem("costboWEBURL");

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [currentPage, setCurrentPage] = useState("product-detail");
  // const [currentPage, setCurrentPage] = useState("ondc");

  const [productImgUpdate, setProductImgUpdate] = useState([]);

  const [showAlert, setShowAlert] = useState(false);

  const [addProductLoader, setAddProductLoader] = useState(false);

  const location = useLocation();
  const contentRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  const { countryList, countryListLoading, gstRates, allDeliveryMethod } =
    useSelector((state) => state.catalog);

  const [productDetails, setProductDetails] = useState(null);
  const [productDetailsLoader, setProductDetailsLoader] = useState(false);

  const [unitOfMeasurementData, setUnitOfMeasurementData] = useState([]);

  const [productInfo, setProductInfo] = useState({
    productName: "",
    brand: "",
    description: "",
    country: DEFAULT_COUNTRY,
    skuId: "",
    productImgOne: "",
    productImgTwo: "",
    productImgThree: "",
    productImgFour: "",

    errors: null,
  });

  const [categoryInfo, setCategoryInfo] = useState({
    businessCategory: [],
    productSubCatOne: [],
    productSubCatTwo: [],
    selectedBusinessCat: "",
    selectedProductSubCat1: "",
    selectedProductSubCat2: "",

    productSpecs: [{ code: "", value: [""] }],
    refLinks: [{ code: "", link: "" }],

    errors: null,
  });

  const [priceInfo, setPriceInfo] = useState({
    currency: "₹",
    originalPrice: "",
    OfferPrice: "",
    taxDetails: "false",
    gstHsnCode: "",

    errors: null,
  });

  const [measurementInfo, setMeasurementInfo] = useState({
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
    deliveryMethod: [],

    errors: null,
  });

  const [availabilityInfo, setAvailabilityInfo] = useState({
    availability: "Yes",
    availabelQty: "",
    alertQty: "",
    minQty: "",
    maxQty: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: "",

    errors: null,
  });

  const [ondcInfo, setOndcInfo] = useState(ONDC_DEFAULT_DATA);
  const [ondcUIControl, setOndcUIControl] = useState({
    timeToShipUnit: {
      label: "Minutes",
      unit: "M",
    },
    cancellationFeeType: "Select Fee Type",
    isFoodRelatedItems: "none",
    isPackaged: "none",
    isPrePackaged: "none",
  });

  const [subscriptionInfo, setSubscriptionInfo] = useState({
    subType: [],
    originalValue: 0,
    offerValue: 0,
    cancelTime: "",
    cancelDays: 1,
    serviceFee: 0,
    specialFee: 0,
    packingFee: 0,
    startDate: "",
    endDate: "",
    isProductSubscribed: false,

    errors: null,
  });

  const [productPreviewData, setProductPreviewData] = useState(null);

  const handleSetValue = (name, value, state, setState) => {
    if (name !== "errors") {
      setState({
        ...state,
        [name]: value,
        errors: { ...state?.errors, [name]: "" },
      });
      return;
    }
    setState({
      ...state,
      [name]: value,
    });
  };

  // Update product image update flag
  const onProductImageUpdate = (data) => {
    const imgFlagList = [...productImgUpdate];

    let index = -1;

    imgFlagList.forEach((item, index) => {
      index = data?.index === item?.index ? item?.index : -1;
    });

    if (index === -1) {
      imgFlagList.push(data);
    } else {
      imgFlagList.splice(index, 1);
    }

    setProductImgUpdate(imgFlagList);
  };

  // To handle Product Info Next Click
  const handleProductInfoSubmit = () => {
    let errors = null;
    let categoryErrors = null;

    if (!productInfo?.productName) {
      errors = { ...errors, productName: true };
    }
    // if (!productInfo?.brand) {
    //   errors = { ...errors, brand: true };
    // }
    if (!productInfo?.description) {
      errors = { ...errors, description: true };
    }
    if (!productInfo?.country) {
      errors = { ...errors, country: true };
    }
    // if (!productInfo?.skuId) {
    //   errors = { ...errors, skuId: true };
    // }
    if (productImgUpdate?.length === 0) {
      errors = { ...errors, productImg: true };
    }

    console.log(productInfo);

    if (!categoryInfo?.selectedBusinessCat) {
      categoryErrors = { ...categoryErrors, selectedBusinessCat: true };
    }
    if (!categoryInfo?.selectedProductSubCat1) {
      categoryErrors = { ...categoryErrors, selectedProductSubCat1: true };
    }
    // if (!categoryInfo?.selectedProductSubCat2) {
    //   categoryErrors = { ...categoryErrors, selectedProductSubCat1: true };
    // }

    console.log("categoryInfo:", categoryInfo);

    if (errors || categoryErrors) {
      if (errors) {
        handleSetValue("errors", errors, productInfo, setProductInfo);
      }

      if (categoryErrors) {
        handleSetValue("errors", categoryErrors, categoryInfo, setCategoryInfo);
      }
    } else {
      handleSetValue("errors", null, productInfo, setProductInfo);
      handleSetValue("errors", null, categoryInfo, setCategoryInfo);
      setCurrentPage("price-tax-info");
      contentRef.current.scrollIntoView();
    }
  };

  // To handle Category Info Next Click
  const handleCategoryInfoSubmit = () => {
    let errors = null;

    // if (!categoryInfo?.selectedBusinessCat) {
    //   errors = { ...errors, selectedBusinessCat: true };
    // }
    // if (!categoryInfo?.selectedProductSubCat1) {
    //   errors = { ...errors, selectedProductSubCat1: true };
    // }
    // if (!categoryInfo?.selectedProductSubCat1) {
    //   errors = { ...errors, selectedProductSubCat1: true };
    // }

    const isSpecError = categoryInfo?.productSpecs?.some(
      (item) =>
        (item?.code === "" && item?.value?.[0] !== "") ||
        (item?.code !== "" && item?.value?.[0] === "")
    );

    // console.log("isSpecError__:", isSpecError);
    if (isSpecError) {
      errors = { ...errors, productSpecs: "Enter the label and value" };
    }

    const isRefLinkError = categoryInfo?.refLinks?.some(
      (item) =>
        (item?.code === "" && item?.link !== "") ||
        (item?.code !== "" && item?.link === "")
    );

    // console.log("isRefLinkError__:", isRefLinkError);
    if (isRefLinkError) {
      errors = { ...errors, refLinks: "Enter the label and value" };
    }

    console.log(categoryInfo);

    if (errors) {
      handleSetValue("errors", errors, categoryInfo, setCategoryInfo);
    } else {
      handleSetValue("errors", null, categoryInfo, setCategoryInfo);
      // previewData();
      setCurrentPage("ondc");
      // setCurrentPage("subscription-info");
      // contentRef.current.scrollIntoView();
    }
  };

  // To handle Price & Tax Info Next Click
  const handlePriceInfoSubmit = () => {
    let errors = null;
    let ondcErrors = null;

    if (!priceInfo?.currency) {
      errors = { ...errors, currency: true };
    }

    if (!priceInfo?.originalPrice) {
      errors = { ...errors, originalPrice: "Please fill out this field" };
    }

    if (priceInfo?.originalPrice && Number(priceInfo?.originalPrice) <= 0) {
      errors = {
        ...errors,
        originalPrice: "Original price should be greater than 0",
      };
    }

    if (!priceInfo?.OfferPrice) {
      errors = { ...errors, OfferPrice: true };
    }

    if (Number(priceInfo?.OfferPrice) > Number(priceInfo?.originalPrice)) {
      errors = { ...errors, priceError: true };
    }

    if (!ondcInfo?.ondc?.productCode?.value) {
      errors = { ...errors, productCode: "Enter product code" };
    }

    if (
      ondcInfo?.ondc?.productCode?.key === "EAN" &&
      ondcInfo?.ondc?.productCode?.value?.length != 13
    ) {
      errors = {
        ...errors,
        productCode: "Enter a valid 13 digit EAN code",
      };
    }
    if (
      ondcInfo?.ondc?.productCode?.key === "ISBN" &&
      ondcInfo?.ondc?.productCode?.value?.length != 13
    ) {
      errors = {
        ...errors,
        productCode: "Enter a valid 13 digit ISBN code",
      };
    }
    if (
      ondcInfo?.ondc?.productCode?.key === "GTIN" &&
      ondcInfo?.ondc?.productCode?.value?.length != 12
    ) {
      errors = {
        ...errors,
        productCode: "Enter a valid 12 digit GTIN code",
      };
    }
    if (
      ondcInfo?.ondc?.productCode?.key === "HSN" &&
      ondcInfo?.ondc?.productCode?.value?.length != 8
    ) {
      errors = {
        ...errors,
        productCode: "Enter a valid 8 digit HSN code",
      };
    }
    if (
      ondcInfo?.ondc?.productCode?.key === "OTHERS" &&
      ondcInfo?.ondc?.productCode?.value?.length != 13
    ) {
      errors = {
        ...errors,
        productCode: "Enter a valid 13 digit code",
      };
    }
    // if (!priceInfo?.taxDetails) {
    //   errors = { ...errors, taxDetails: true };
    // }

    // if (!priceInfo?.gstHsnCode) {
    //   errors = { ...errors, gstHsnCode: true };
    // }

    if (!priceInfo?.gstPercentage) {
      errors = { ...errors, gstPercentage: true };
    }

    console.log(priceInfo);

    if (errors) {
      handleSetValue("errors", errors, priceInfo, setPriceInfo);
    } else {
      handleSetValue("errors", null, priceInfo, setPriceInfo);
    }

    if (ondcErrors) {
      handleSetValue("errors", ondcErrors, ondcInfo, setOndcInfo);
    } else {
      handleSetValue("errors", null, ondcInfo, setOndcInfo);
    }

    if (!errors && !ondcErrors) {
      setCurrentPage("measurement-delivery-method");
      contentRef.current.scrollIntoView();
    }
  };

  // To handle Measure Info Next Click
  const handleMeasurementInfoSubmit = () => {
    let errors = null;
    let ondcErrors = null;

    if (!measurementInfo?.weight || measurementInfo?.weight == 0) {
      errors = { ...errors, weight: true };
    }

    const isUnitValid = unitOfMeasurementData?.filter(
      (item) => item?.value === ondcInfo?.productDisplayUnitOfMeasure?.unit
    );

    if (isUnitValid?.length === 0) {
      errors = {
        ...errors,
        productDisplayUnitOfMeasure1: "Select unit",
      };
    }
    if (!ondcInfo?.productDisplayUnitOfMeasure?.unit) {
      errors = {
        ...errors,
        productDisplayUnitOfMeasure1: "Select unit",
      };
    }

    if (
      !ondcInfo?.productDisplayUnitOfMeasure?.value ||
      ondcInfo?.productDisplayUnitOfMeasure?.value == "0"
    ) {
      errors = {
        ...errors,
        productDisplayUnitOfMeasure: "Enter value",
      };
    }

    if (!ondcInfo?.netProductWeight || ondcInfo?.netProductWeight == "0") {
      errors = {
        ...errors,
        netProductWeight: "Enter net product weight",
      };
    }

    if (
      ondcInfo?.netProductWeight > "0" &&
      Number(ondcInfo?.netProductWeight) < Number(measurementInfo?.weight)
    ) {
      errors = {
        ...errors,
        netProductWeight:
          "Net product weight should be greater than product weight",
      };
    }

    console.log(measurementInfo);

    if (errors) {
      handleSetValue("errors", errors, measurementInfo, setMeasurementInfo);
    } else {
      handleSetValue("errors", null, measurementInfo, setMeasurementInfo);
    }
    if (ondcErrors) {
      handleSetValue("errors", ondcErrors, ondcInfo, setOndcInfo);
    } else {
      handleSetValue("errors", null, ondcInfo, setOndcInfo);
    }
    if (!errors && !ondcErrors) {
      setCurrentPage("product-availablity");
      contentRef.current.scrollIntoView();
    }
  };

  // To handle Availability Info Next Click
  const handleAvailabilityInfoSubmit = () => {
    let errors = null;

    if (!availabilityInfo?.availability) {
      errors = { ...errors, availability: true };
    }
    if (!availabilityInfo?.availabelQty) {
      errors = { ...errors, availabelQty: true };
    }
    if (!availabilityInfo?.alertQty) {
      errors = { ...errors, alertQty: true };
    }
    if (!availabilityInfo?.minQty) {
      errors = { ...errors, minQty: true };
    }
    if (!availabilityInfo?.maxQty) {
      errors = { ...errors, maxQty: true };
    }
    if (!availabilityInfo?.startDate) {
      errors = { ...errors, startDate: true };
    }
    if (!availabilityInfo?.endDate) {
      errors = { ...errors, endDate: true };
    }

    if (
      Number(availabilityInfo?.availabelQty) <=
        Number(availabilityInfo?.alertQty) ||
      Number(availabilityInfo?.availabelQty) < Number(availabilityInfo?.maxQty)
    ) {
      errors = {
        ...errors,
        availabilityError:
          "Available Quantity should be greater than Alert Quantity & Maximum Quantity Per Order",
      };
    }

    if (Number(availabilityInfo?.maxQty) < Number(availabilityInfo?.minQty)) {
      errors = {
        ...errors,
        maxQtyError:
          "Maximum Quantity Per Order should be greater than Minimum Quantity Per Order",
      };
    }

    if (Number(availabilityInfo?.alertQty) < Number(availabilityInfo?.minQty)) {
      errors = {
        ...errors,
        alertQtyError: "Alert quantity must be more than Min order quantity",
      };
    }

    if (
      moment(availabilityInfo?.startDate)?.isAfter(
        moment(availabilityInfo?.endDate)
      )
    ) {
      errors = {
        ...errors,
        startDate: "Start date should be less than End date",
      };
    }

    console.log(availabilityInfo);

    if (errors) {
      handleSetValue("errors", errors, availabilityInfo, setAvailabilityInfo);
    } else {
      handleSetValue("errors", null, availabilityInfo, setAvailabilityInfo);
      setCurrentPage("category-specs");
      contentRef.current.scrollIntoView();
    }
  };

  // To handle Subscription Info Next Click
  const handleSubscriptionInfoSubmit = async () => {
    let errors = null;

    if (subscriptionInfo?.isProductSubscribed) {
      if (subscriptionInfo?.subType?.length === 0) {
        errors = { ...errors, subType: true };
      }
      if (!subscriptionInfo?.originalValue) {
        errors = { ...errors, originalValue: true };
      }
      if (!subscriptionInfo?.offerValue) {
        errors = { ...errors, offerValue: true };
      }
      if (!subscriptionInfo?.cancelTime) {
        errors = { ...errors, cancelTime: true };
      }
      if (!subscriptionInfo?.cancelDays) {
        errors = { ...errors, cancelDays: true };
      }
      // if (!subscriptionInfo?.serviceFee) {
      //   errors = { ...errors, serviceFee: true };
      // }
      // if (!subscriptionInfo?.specialFee) {
      //   errors = { ...errors, specialFee: true };
      // }
      // if (!subscriptionInfo?.packingFee) {
      //   errors = { ...errors, packingFee: true };
      // }
      if (!subscriptionInfo?.startDate) {
        errors = { ...errors, startDate: true };
      }
      if (!subscriptionInfo?.endDate) {
        errors = { ...errors, endDate: true };
      }

      console.log(subscriptionInfo);

      if (errors) {
        handleSetValue("errors", errors, subscriptionInfo, setSubscriptionInfo);
      } else {
        // Update or Add product
        handleSetValue("errors", null, subscriptionInfo, setSubscriptionInfo);
        previewData();
      }
    } else {
      // Update or Add product
      handleSetValue("errors", null, subscriptionInfo, setSubscriptionInfo);
      previewData();
    }
  };

  // To handle ONDC Info Next Click
  const handleOndcInfoSubmit = async () => {
    let errors = null;

    if (ondcInfo?.ondcEnabled === "yes") {
      if (
        ondcInfo?.ondcProductCategory?.name == "Select ONDC Product Category"
      ) {
        errors = {
          ...errors,
          ondcProductCategory: "Select ONDC product category",
        };
      }

      if (ondcInfo?.ondcProductSubCategory?.productSubCategory1 == "") {
        errors = {
          ...errors,
          ondcProductSubCategory: "Select ONDC product sub category",
        };
      }

      if (!ondcInfo?.ondc?.timeToShip || ondcInfo?.ondc?.timeToShip == "0") {
        errors = { ...errors, timeToShip: "Enter time to ship" };
      }

      if (ondcInfo?.ondc?.available_on_cod === "none") {
        errors = { ...errors, available_on_cod: "choose available on COD" };
      }

      // if (!ondcInfo?.ondc?.discount) {
      //   errors = { ...errors, discount: "Enter customer discount" };
      // }

      // if (!ondcInfo?.productDisplayUnitOfMeasure?.unit) {
      //   errors = { ...errors, productDisplayUnitOfMeasure1: "Select unit" };
      // }

      // if (
      //   !ondcInfo?.productDisplayUnitOfMeasure?.value ||
      //   ondcInfo?.productDisplayUnitOfMeasure?.value == "0"
      // ) {
      //   errors = { ...errors, productDisplayUnitOfMeasure: "Enter value" };
      // }

      // if (!ondcInfo?.netProductWeight || ondcInfo?.netProductWeight == "0") {
      //   errors = { ...errors, netProductWeight: "Enter net product weight" };
      // }

      // if (!ondcInfo?.ondc?.productCode?.value) {
      //   errors = { ...errors, productCode: "Enter product code" };
      // }

      if (ondcInfo?.ondc?.cancelTerms?.cancellable === "none") {
        errors = { ...errors, cancellable: "Choose cancellable" };
      }

      if (ondcInfo?.ondc?.cancelTerms?.cancellable === "yes") {
        if (ondcInfo?.ondc?.cancelTerms?.refundEligible === "none") {
          errors = { ...errors, refundEligible: "Choose refund eligible" };
        }

        // if (ondcUIControl?.cancellationFeeType === "Select Fee Type") {
        //   errors = {
        //     ...errors,
        //     cancellationFeeType: "Select fee type",
        //   };
        // }

        // if (ondcUIControl?.cancellationFeeType === "Amount") {
        //   if (!ondcInfo?.ondc?.cancelTerms?.cancellationFeeAmount) {
        //     errors = {
        //       ...errors,
        //       cancellationFeeAmount: "Enter cancellation fee amount",
        //     };
        //   }
        // }

        if (!ondcInfo?.ondc?.cancelTerms?.cancellationFeePercentage) {
          errors = {
            ...errors,
            cancellationFeePercentage: "Enter cancellation fee percentage",
          };
        }
      }

      if (ondcInfo?.ondc?.fragile === "none") {
        errors = { ...errors, fragile: "Choose fragile" };
      }

      if (ondcInfo?.ondc?.returnTerms?.returnable === "none") {
        errors = { ...errors, returnable: "Choose returnable" };
      }

      if (
        !ondcInfo?.ondc?.returnTerms?.returnPeriod ||
        ondcInfo?.ondc?.returnTerms?.returnPeriod == "0"
      ) {
        errors = { ...errors, returnPeriod: "Enter return period" };
      }

      if (ondcInfo?.ondc?.returnTerms?.seller_pickup_return === "none") {
        errors = { ...errors, seller_pickup_return: "Choose seller pickup" };
      }

      if (ondcUIControl?.isFoodRelatedItems === "none") {
        errors = {
          ...errors,
          isFoodRelatedItems: "Choose Food & Related Items",
        };
      }

      if (ondcUIControl?.isFoodRelatedItems === "yes") {
        if (!ondcInfo?.ondc?.shelfLife || ondcInfo?.ondc?.shelfLife == "0") {
          errors = { ...errors, shelfLife: "Enter shelfLife" };
        }

        if (
          !ondcInfo?.ondc?.fssai?.[0]?.number ||
          ondcInfo?.ondc?.fssai?.[0]?.number?.length != 14
        ) {
          errors = { ...errors, fssai: "Enter a valid 14 digit FSSAI number" };
        }

        // if (ondcInfo?.ondc?.fssai?.[0]?.validTill === "") {
        //   errors = { ...errors, validTill: "Enter validTill" };
        // }
      }

      if (ondcUIControl?.isPackaged === "none") {
        errors = { ...errors, isPackaged: "Choose Packaged Commodities" };
      }

      if (ondcUIControl?.isPackaged === "yes") {
        if (
          !ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.manufacturer_or_packer_name
        ) {
          errors = {
            ...errors,
            manufacturer_or_packer_name: "Enter Manufacturer/Packer Name",
          };
        }
        if (!ondcInfo?.ondc?.statutoryReqsPackagedCommodities?.mfg_license_no) {
          errors = { ...errors, mfg_license_no: "Enter License No" };
        }
        if (
          !ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.manufacturer_or_packer_address
        ) {
          errors = {
            ...errors,
            manufacturer_or_packer_address: "Enter Manufacturer/Packer Address",
          };
        }
        if (
          !ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.common_or_generic_name_of_commodity
        ) {
          errors = {
            ...errors,
            common_or_generic_name_of_commodity:
              "Enter Product Common/Generic Name",
          };
        }

        if (
          ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.month_year_of_manufacture_packing_import &&
          !isStringValidDateFormat(
            ondcInfo?.ondc?.statutoryReqsPackagedCommodities
              ?.month_year_of_manufacture_packing_import
          )
        ) {
          errors = {
            ...errors,
            month_year_of_manufacture_packing_import:
              "Please enter a valid Month, Year or N/A",
          };
        }

        if (
          ondcInfo?.ondc?.statutoryReqsPackagedCommodities?.expiry_date &&
          !isStringValidDateFormat(
            ondcInfo?.ondc?.statutoryReqsPackagedCommodities?.expiry_date
          )
        ) {
          errors = {
            ...errors,
            expiry_date: "Please enter a valid Month, Year or N/A",
          };
        }

        if (
          !ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.net_quantity_or_measure_of_commodity_in_pkg
        ) {
          errors = {
            ...errors,
            net_quantity_or_measure_of_commodity_in_pkg: "Enter Net Quantity",
          };
        }
        if (
          !ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.multiple_products_name_number_or_qty
        ) {
          errors = {
            ...errors,
            multiple_products_name_number_or_qty: "Enter Multiple Products",
          };
        }
      }

      if (ondcUIControl?.isPrePackaged === "none") {
        errors = { ...errors, isPrePackaged: "Choose Packaged Commodities" };
      }

      if (ondcUIControl?.isPrePackaged === "yes") {
        if (
          !ondcInfo?.ondc?.statutoryReqsPrepackagedFood
            ?.brand_owner_FSSAI_license_no ||
          ondcInfo?.ondc?.statutoryReqsPrepackagedFood
            ?.brand_owner_FSSAI_license_no?.length != 14
        ) {
          errors = {
            ...errors,
            brand_owner_FSSAI_license_no: "Enter a valid 14 digit FSSAI number",
          };
        }
        if (
          !ondcInfo?.ondc?.statutoryReqsPrepackagedFood
            ?.other_FSSAI_license_no ||
          ondcInfo?.ondc?.statutoryReqsPrepackagedFood?.other_FSSAI_license_no
            ?.length != 14
        ) {
          errors = {
            ...errors,
            other_FSSAI_license_no: "Enter a valid 14 digit FSSAI number",
          };
        }
        if (
          !ondcInfo?.ondc?.statutoryReqsPrepackagedFood
            ?.importer_FSSAI_license_no ||
          ondcInfo?.ondc?.statutoryReqsPrepackagedFood
            ?.importer_FSSAI_license_no?.length != 14
        ) {
          errors = {
            ...errors,
            importer_FSSAI_license_no: "Enter a valid 14 digit FSSAI number",
          };
        }

        if (!ondcInfo?.ondc?.statutoryReqsPrepackagedFood?.ingredients_info) {
          errors = {
            ...errors,
            ingredients_info: "Enter Ingredients Info",
          };
        }
        if (!ondcInfo?.ondc?.statutoryReqsPrepackagedFood?.nutritional_info) {
          errors = {
            ...errors,
            nutritional_info: "Enter Nutritional Info ",
          };
        }
        if (!ondcInfo?.ondc?.statutoryReqsPrepackagedFood?.additives_info) {
          errors = {
            ...errors,
            additives_info: "Enter Additives Info",
          };
        }
      }

      console.log("ONDC_INFO__:", ondcInfo);

      if (errors) {
        handleSetValue("errors", errors, ondcInfo, setOndcInfo);
      } else {
        handleSetValue("errors", null, ondcInfo, setOndcInfo);
        window.scrollTo(0, 0);
        previewData();
      }
    } else {
      // Update or Add product
      handleSetValue("errors", null, ondcInfo, setOndcInfo);
      window.scrollTo(0, 0);
      previewData();
    }
  };

  const previewData = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const previewDetails = getUpdateProductPostData({
      businessId,
      productDetails,
      productInfo,
      categoryInfo,
      priceInfo,
      measurementInfo,
      availabilityInfo,
      subscriptionInfo,
      ondcInfo,
      ondcUIControl,
    });

    console.log("previewDetails:", previewDetails);

    setProductPreviewData(previewDetails);
    setCurrentPage("preview");
  };

  // Update Product Details
  const updateProductDetails = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    const productPhoto = await prepareAddNewProductPhotoData(productImgUpdate);
    console.log("UpdateIMGList:", productPhoto);

    const postData = getAddNewProductPostData({
      businessId,
      businessInfo: activeBusinessInfo,
      productPhoto,
      productDetails,
      productInfo,
      categoryInfo,
      priceInfo,
      measurementInfo,
      availabilityInfo,
      subscriptionInfo,
      ondcInfo,
      ondcUIControl,
    });

    console.log("AddPostData:", postData);

    // Add new product details
    setAddProductLoader(true);
    const response = await addNewProductApi(postData);

    if (response?.success) {
      setShowAlert(true);
      setAddProductLoader(false);
      return;
    }

    setAddProductLoader(false);
  };

  const deleteProductPhoto = async (imageId) => {
    // console.log("imageId__:", imageId);
    // console.log("productImgUpdate__:", productImgUpdate);

    const updatedList = productImgUpdate?.map((item, index) => {
      if (item?.dataURL === imageId) {
        return { ...item, dataURL: "" };
      } else {
        return item;
      }
    });

    // console.log("updatedList___", updatedList);

    setProductImgUpdate(updatedList);
  };

  const onCancelClick = () => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/catalog`);
  };

  const goBack = () => {
    if (currentPage === "category-specs") {
      setCurrentPage("product-availablity");
    }
    if (currentPage === "price-tax-info") setCurrentPage("product-detail");
    if (currentPage === "measurement-delivery-method")
      setCurrentPage("price-tax-info");
    if (currentPage === "product-availablity")
      setCurrentPage("measurement-delivery-method");
    if (currentPage === "subscription-info")
      setCurrentPage("product-availablity");
    // if (currentPage === "preview") setCurrentPage("category-specs");
    if (currentPage === "ondc") setCurrentPage("category-specs");
    if (currentPage === "preview") setCurrentPage("ondc");

    contentRef.current.scrollIntoView();
  };

  const handleTabChange = (currentName, tabList, destinationTab) => {
    // console.log("currentTab__:", currentName);
    // console.log("tabList:", tabList);
    // console.log("destinationTab__:", destinationTab);

    const currentTabInfo = tabList?.filter(
      (item) => item?.value === currentName
    );
    const descriptionTabInfo = tabList?.filter(
      (item) => item?.tab === destinationTab
    );

    if (currentTabInfo.length > 0 && descriptionTabInfo.length > 0) {
      const currentTabIndex = currentTabInfo[0]?.tab;

      if (destinationTab < currentTabIndex) {
        const destinationTabName = descriptionTabInfo[0]?.value;
        setCurrentPage(destinationTabName);
        return;
      }
    }
  };

  // Get Unit of measurement
  const getUnitsData = async () => {
    const response = await getUnitOfMeasurementApi();
    setUnitOfMeasurementData(() => response?.details?.sort(sortByValue) || []);
    console.log("getUnitsData:", response);
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      dispatch(getCountryNames());
      dispatch(getGstRates());
      dispatch(getAllDeliveryMethods());

      if (unitOfMeasurementData?.length === 0) {
        getUnitsData();
      }
    }
  }, []);

  useEffect(() => {
    if (activeBusinessInfo) {
      setCategoryInfo({
        ...categoryInfo,
        businessCategory: activeBusinessInfo?.businessCategories || [],
      });
    }
    return () => {};
  }, [activeBusinessInfo]);

  return (
    <Box sx={styles.container}>
      <DashboardLayout activeMenu="catalog">
        {!productDetailsLoader && !activeBusinessInfoLoader ? (
          <Box ref={contentRef}>
            <Box sx={stickyHeader}>
              <BreadcrumbSection
                links={[
                  { label: 'Product Catalog', to: `/${businessName}/catalog` },
                  { label: "Add Product", to: `#` },
                ]}
              />

              <Box pb={{ xs: 0, md: currentPage === "product-detail" ? 2 : 0 }}>
                {/* <Typography sx={styles.backTextBtn} onClick={onCancelClick}>
                  Back to Catalog
                </Typography> */}

                <Typography
                  component="h6"
                  fontSize={{ xs: 15, md: 16 }}
                  color={blackColor}
                  fontWeight={{ xs: 600, md: 600 }}
                  lineHeight="20px"
                  display="inline"
                >
                  Add Product
                </Typography>
              </Box>

              {currentPage !== "product-detail" &&
                currentPage !== "preview" && (
                  <Box mb={{ xs: 0, md: 2 }}>
                    <DashboardStepper
                      stepList={CATALOG_ADD_PRODUCT_STEP_LIST}
                      currentPage={currentPage}
                      isAddProduct
                      // hideAvailability={!location.state?.isCopyProduct}
                      handleTabChange={(destinationTab) =>
                        handleTabChange(
                          currentPage,
                          CATALOG_ADD_PRODUCT_STEP_LIST,
                          destinationTab
                        )
                      }
                    />
                  </Box>
                )}
            </Box>

            <Box mt={{xs:1, md:0}}>
              <Box
                sx={{
                  display: currentPage === "product-detail" ? "block" : "none",
                }}
              >
                <AddProductForm
                  isAddProduct
                  data={productInfo}
                  onValueChange={(name, value) => {
                    handleSetValue(name, value, productInfo, setProductInfo);
                  }}
                  countryList={countryList}
                  productId={location?.state?.productId}
                  gotoNext={handleProductInfoSubmit}
                  goBack={goBack}
                  productName={productInfo?.productName}
                  onProductImageUpdate={onProductImageUpdate}
                  onDeleteProductPhoto={(imgURL) => {
                    deleteProductPhoto(imgURL);
                  }}
                  productImgDataList={productImgUpdate}
                  setProductImgDataList={setProductImgUpdate}
                  // CategoryInfo
                  categoryData={categoryInfo}
                  setCategoryData={setCategoryInfo}
                  onCategoryValueChange={(name, value) => {
                    handleSetValue(name, value, categoryInfo, setCategoryInfo);
                  }}
                  onCancelClick={onCancelClick}
                  tabTitle={`New Product`}
                />
              </Box>

              <Box
                sx={{
                  display: currentPage === "category-specs" ? "block" : "none",
                }}
              >
                <CategorySpecsForm
                  isAddProduct
                  data={categoryInfo}
                  setData={setCategoryInfo}
                  onValueChange={(name, value) => {
                    handleSetValue(name, value, categoryInfo, setCategoryInfo);
                  }}
                  gotoNext={handleCategoryInfoSubmit}
                  goBack={goBack}
                  currentPage={currentPage}
                  isCopyProduct
                  onCancelClick={onCancelClick}
                  handleTabChange={handleTabChange}
                  tabTitle={`New Product`}
                  productName={productInfo?.productName}
                />
              </Box>

              {currentPage === "price-tax-info" ? (
                <PriceTaxInfoForm
                  isAddProduct
                  data={priceInfo}
                  ondcData={ondcInfo}
                  setOndcData={setOndcInfo}
                  onValueChange={(name, value) => {
                    handleSetValue(name, value, priceInfo, setPriceInfo);
                  }}
                  gstRates={gstRates}
                  gotoNext={handlePriceInfoSubmit}
                  goBack={goBack}
                  currentPage={currentPage}
                  isCopyProduct
                  onCancelClick={onCancelClick}
                  handleTabChange={handleTabChange}
                  tabTitle={`New Product`}
                  productName={productInfo?.productName}
                />
              ) : null}

              {currentPage === "measurement-delivery-method" ? (
                <MeasurementDeliveryForm
                  isAddProduct
                  data={measurementInfo}
                  ondcData={ondcInfo}
                  setOndcData={setOndcInfo}
                  onValueChange={(name, value) => {
                    handleSetValue(
                      name,
                      value,
                      measurementInfo,
                      setMeasurementInfo
                    );
                  }}
                  allDeliveryMethod={allDeliveryMethod || []}
                  gotoNext={handleMeasurementInfoSubmit}
                  goBack={goBack}
                  currentPage={currentPage}
                  isCopyProduct
                  onCancelClick={onCancelClick}
                  handleTabChange={handleTabChange}
                  tabTitle={`New Product`}
                  productName={productInfo?.productName}
                  unitOfMeasurementData={unitOfMeasurementData}
                />
              ) : null}

              {currentPage === "product-availablity" ? (
                <ProductAvailabilityForm
                  isAddProduct
                  data={availabilityInfo}
                  onValueChange={(name, value) => {
                    handleSetValue(
                      name,
                      value,
                      availabilityInfo,
                      setAvailabilityInfo
                    );
                  }}
                  gotoNext={handleAvailabilityInfoSubmit}
                  goBack={goBack}
                  currentPage={currentPage}
                  isCopyProduct
                  onCancelClick={onCancelClick}
                  handleTabChange={handleTabChange}
                  tabTitle={`New Product`}
                  productName={productInfo?.productName}
                />
              ) : null}

              {/* {currentPage === "subscription-info" ? (
                <SubscriptionInfoForm
                  isAddProduct
                  data={subscriptionInfo}
                  onValueChange={(name, value) => {
                    handleSetValue(
                      name,
                      value,
                      subscriptionInfo,
                      setSubscriptionInfo
                    );
                  }}
                  gotoNext={handleSubscriptionInfoSubmit}
                  goBack={goBack}
                  currentPage={currentPage}
                  productName={productInfo?.productName}
                  onCancelClick={onCancelClick}
                />
              ) : null} */}

              {currentPage === "ondc" ? (
                <ONDCFrom
                  data={ondcInfo}
                  setData={setOndcInfo}
                  ondcUIControl={ondcUIControl}
                  setOndcUIControl={setOndcUIControl}
                  ondcProductCategory={
                    activeBusinessInfo?.businessONDCCategories || []
                  }
                  gotoNext={handleOndcInfoSubmit}
                  goBack={goBack}
                  currentPage={currentPage}
                  onCancelClick={onCancelClick}
                  handleTabChange={handleTabChange}
                  isCopyProduct
                  tabTitle={`New Product`}
                  productName={productInfo?.productName}
                  unitOfMeasurementData={unitOfMeasurementData}
                  handlePreviewSubmit={handleOndcInfoSubmit}
                />
              ) : null}

              {currentPage === "preview" ? (
                <DataPreview
                  productData={productPreviewData}
                  productPhotos={productImgUpdate}
                  onGoBack={goBack}
                  onSubmit={updateProductDetails}
                  isCopyProduct={true}
                />
              ) : null}
            </Box>
          </Box>
        ) : (
          <ThreeDotLoader />
        )}
      </DashboardLayout>

      <ModalCard open={addProductLoader} handleClose={() => {}} width="48%">
        <Box
          sx={{
            minHeight: "90px",
            backgroundColor: whiteColor,
            border: "15px",
            overflow: "hidden",
          }}
        >
          <ThreeDotLoader />
        </Box>
      </ModalCard>

      <ModalCard
        open={showAlert}
        handleClose={() => {
          // setShowAlert(false);
        }}
        width="48%"
      >
        <AlertMessagePopup
          text={`Product added successfully. Our team will review and approve within 24 hours.  New products will be available for customer ordering after approval.\nFor immediate approval, please contact via whatsapp - ‘8088422615’`}
          onClick={() => {
            const webURL = sessionStorage.getItem("costboWEBURL");
            navigate(`/${webURL}/catalogPending`);
          }}
        />
      </ModalCard>
    </Box>
  );
}

export default AddProduct;

const AlertMessagePopup = ({ text, onClick }) => {
  return (
    <Box sx={styles.alertCard}>
      <Typography
        variant="body2"
        fontSize={14}
        fontWeight={500}
        textAlign="center"
        sx={{ whiteSpace: "pre-line", lineHeight: "26px", my: 1.2 }}
      >
        {text}
      </Typography>
      <Button sx={styles.alertBtn} variant="outlined" onClick={onClick}>
        GO TO CATALOG
      </Button>
    </Box>
  );
};

const styles = {
  container: {
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "25px" },
    p: { xs: "0px", md: "15px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  alertCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    padding: "20px 20px 15px 20px",
    borderRadius: "8px",
  },

  alertBtn: {
    fontSize: "13px",
    fontWeight: "500",
    color: whiteColor,
    backgroundColor: primaryColor,
    padding: "6px 24px",
    my: "10px",
    "&:hover": {
      color: primaryColor,
      backgroundColor: whiteColor,
    },
  },

  backTextBtn: {
    fontSize: 12.5,
    fontWeight: 500,
    color: blueColor04,
    display: { xs: "block", md: "none" },
    textDecoration: "underline",
    mb: 0.5,
    cursor: "pointer",
  },
};
