import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ActionButtons from "../components/Buttons/ActionButtons";
import BusinessInfoCard from "../components/Cards/BusinessInfoCard";
import StatsInfoCard from "../components/Cards/StatsInfoCard";
import {
  BGColor02,
  blackColor,
  greyColor10,
  iconBG1,
  iconBG2,
  whiteColor,
} from "../configs/styles/muiThemes";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import {
  getReviewProductDetailsAPI,
  getViewProductDetailsAPI,
} from "../service/api_calls/catalog/CatalogAPI";
import DashboardHeader from "./Dashboard/DashboardHeader";
import DashboardMenu from "./Dashboard/DashboardMenu";
import CategoryDetailPreview from "./ProductPreview/CategoryDetailPreview";
import PriceDetailsPreview from "./ProductPreview/PriceDetailsPreview";
import ProductAvailabilityPreview from "./ProductPreview/ProductAvailabilityPreview";
import ProductDetailsPreview from "./ProductPreview/ProductDetailsPreview";
import ProductMeasurementPreview from "./ProductPreview/ProductMeasurementPreview";
import SubscriptionInfoPreview from "./ProductPreview/SubscriptionInfoPreview";
import ONDCDetailsPreview from "./ProductPreview/ONDCDetailsPreview";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import { stickyHeader } from "../utils/styles";
import BreadcrumbSection from "../components/Breadcrumb/BreadcrumbSection";

function ProductPreview() {
  const businessName = sessionStorage.getItem("costboWEBURL");

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [productData, setProductData] = useState(null);
  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  const getProductDetails = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const productId = state?.productId;

    if (state?.isReviewProduct) {
      const data = await getReviewProductDetailsAPI({ businessId, productId });
      if (data?.details) {
        setProductData(data?.details);
      }
      return;
    }

    const data = await getViewProductDetailsAPI(productId);
    if (data?.product) {
      setProductData(data?.product);
    }
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }

      getProductDetails();
    }
  }, []);

  return (
    <Box sx={styles.container}>
      <DashboardLayout activeMenu="catalog">
        <Box flex={1}>
          <Box sx={stickyHeader}>
            <BreadcrumbSection
              links={[
                {
                  label: "Product Catalog",
                  to: `/${businessName}/catalog`,
                },
                { label: "Product Details", to: `#` },
                { label: productData?.productName, to: `#` },
              ]}
            />

            <Typography
              component="h5"
              fontSize={{ xs: 14.5, md: 17 }}
              color={blackColor}
              fontWeight={600}
              lineHeight="20px"
              pb={1.5}
            >
              Product Details
            </Typography>
          </Box>
          <ProductDetailsPreview
            productName={productData?.productName}
            brand={productData?.brand}
            country={productData?.origin}
            skuId={productData?.skuId || "NA"}
            description={productData?.productDescription}
            businessCategory={productData?.productMainCategory?.[0]?.value}
            productCategory={productData?.productSubCategory?.[0]?.value}
            productSubCategory={productData?.productSubCategory2?.[0]?.value}
            // description="A beautiful necklace with Vilandi Kundan pendant and 3 lines of ruby red agate beads. On the other side of the pendant, it has meenakari work. Available with Kundan earrings. A beautiful necklace with Vilandi Kundan pendant and 3 lines of ruby red agate beads. On the other side of the pendant, it has meenakari work. Available with Kundan earrings."
            images={productData?.productPhoto}
            viewProduct
          />

          <PriceDetailsPreview
            currency={productData?.currencySymbol}
            originalPrice={`₹ ${productData?.productOriginalPrice}`}
            offerPrice={`₹ ${productData?.productSalePrice}`}
            taxesIncluded={
              productData?.taxDetails?.[0]?.value == "true" ? "YES" : "NO"
            }
            gstHsnCode={productData?.gstInfo?.code}
            gstPercentage={productData?.gstInfo?.gstPercentage}
            ondcData={productData?.ondc}
            viewProduct
          />

          <ProductMeasurementPreview
            weight={productData?.measurementInfo?.weight}
            length={productData?.measurementInfo?.length}
            width={productData?.measurementInfo?.width}
            height={productData?.measurementInfo?.height}
            // deliveryMethod="Delivery Fulfillment"
            deliveryMethod={productData?.deliveryType?.join(", ") || ""}
            ondcData={productData?.ondc}
            unitOfmeasure={productData?.productDisplayUnitOfMeasure}
            netProductWeight={productData?.netProductWeight}
            viewProduct
          />

          <ProductAvailabilityPreview
            isProductAvailable={productData?.availability?.[0] || "No"}
            qty={productData?.availableQuantity}
            alertQty={productData?.alertQuantity}
            minQtyPerOrder={productData?.minOrderQuantity}
            maxQtyPerOrder={productData?.maxOrderQuantity}
            startDate={
              moment(productData?.commerceStartDate)?.format("DD-MM-YYYY") ||
              "DD-MM-YYYY"
            }
            endDate={
              moment(productData?.commerceEndDate)?.format("DD-MM-YYYY") ||
              "DD-MM-YYYY"
            }
          />

          <CategoryDetailPreview
            // businessCategory={productData?.productMainCategory?.[0]?.value}
            // productCategory={productData?.productSubCategory?.[0]?.value}
            // productSubCategory={productData?.productSubCategory2?.[0]?.value}
            productSpecLabel={productData?.label || []}
            // productSpecValue="Rs 700"
            // refLinkLabel="Affordable Designer Saree"
            // refLinkValue="https://youtu.be/5GVvqnoZliw"

            refLinks={productData?.refLink || []}
            refLinkLabel=""
            refLinkValue=""
          />

          <ONDCDetailsPreview
            data={{
              ondc: productData?.ondc,
              productDisplayUnitOfMeasure:
                productData?.productDisplayUnitOfMeasure,
              netProductWeight: productData?.netProductWeight || "",
              ondcEnabled: productData?.ondcEnabled,
            }}
            productCategory={productData?.productMainCategory?.[0]?.ondcValue}
            subProductCategory={
              productData?.productSubCategory?.[0]?.ondcSubValue
            }
            isViewProduct
            viewProduct
          />

          {/* <SubscriptionInfoPreview
            subscriptionType={productData?.subscription?.type?.join(", ") || ""}
            originalValue={productData?.subscription?.originalValue}
            offerValue={productData?.subscription?.offerValue}
            cancelTime={productData?.subscription?.cancelTime}
            cancelDate="Sunday"
            serviceFee={productData?.subscription?.serviceFee}
            packingFee={productData?.subscription?.packingFee}
            startDate={
              moment(productData?.subscription?.startDate)?.format(
                "DD-MM-YYYY"
              ) || "DD-MM-YYYY"
            }
          /> */}

          <Box mt="20px" pb={{xs:2, md:0}}>
            <ActionButtons
              backBtnText="Previous"
              saveBtnText="SUBMIT"
              onBackClick={() => {
                navigate(`/${sessionStorage.getItem("costboWEBURL")}/catalog`);
              }}
              onSaveClick={() => {}}
              showBackBtn
              hideNextBtn
              hideSaveBtn
            />
          </Box>
        </Box>
      </DashboardLayout>
    </Box>
  );
}

export default ProductPreview;

const styles = {
  container: {
    backgroundColor: BGColor02,
  },

  content: {},

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  statsCard: {
    width: { xs: "100%", md: "auto" },
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    gap: { xs: "20px", md: "35px" },
    backgroundColor: whiteColor,
    padding: { xs: "10px 16px", md: "8px 16px" },
    borderRadius: { xs: "8px", md: "12px" },
    border: `1px solid ${greyColor10}`,
    m: { xs: "12px 0 12px 0", md: "0" },
  },
};
