import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  authLogin,
  changePassword,
  checkUser,
  eRegister,
  getCodeToMail,
  getProfileData,
  storeUserData,
} from "../../api/auth/loginApi";
import { updateUserData } from "../../service/api_calls/auth/login_api";
import { getAccessToken, getUserName } from "../../utils/validationUtils";

const initialState = {
  loginDetails: null,
  err: null,
  profile: {} || undefined,
  loading: false,

  isLoggedIn: false,
  openLoginModal: { show: false },
};

//Fetching all coupons
export const getLoginDetials = createAsyncThunk(
  "getLoginDetials",
  async (param, thunkAPI) => {
    const res = await authLogin(param);
    if (res.status === 200) {
      sessionStorage.setItem("token", res.data.access_token);
      sessionStorage.setItem("profileId", res.data.randomNo);
    }
    return res;
  }
);

export const checkExistingUser = createAsyncThunk(
  "checkExistingUser",
  async (param, thunkAPI) => {
    const res = await checkUser(param);
    return res;
  }
);

export const saveUserDetails = createAsyncThunk(
  "saveUserDetails",
  async (param, thunkAPI) => {
    const res = await storeUserData(param);
    return res;
  }
);


export const updateUserDetails = createAsyncThunk(
  'updateUserDetails',
  async (param, thunkAPI) => {
    const res = await updateUserData(param)
    return res
  }
)

export const getEregister = createAsyncThunk(
  "getEregister",
  async (param, thunkAPI) => {
    const res = await eRegister(param);
    return res;
  }
);

export const getCode = createAsyncThunk("getCode", async (param, thunkAPI) => {
  const res = await getCodeToMail(param);
  return res;
});

export const finishSetup = createAsyncThunk(
  "finishSetup",
  async (param, thunkAPI) => {
    const res = await changePassword(param);
    return res;
  }
);

export const checkUserExist = createAsyncThunk(
  "checkUserExist",
  async (param, thunkAPI) => {
    // const res = await checkUser(param)
  }
);

export const getProfileDetails = createAsyncThunk(
  "getProfileDetails",
  async (thunkAPI) => {
    const res = await getProfileData();
    // console.log("Profile",res)
    return res;
  }
);

export const checkUserLoggedIn = createAsyncThunk(
  "getProfileDetails",
  async (thunkAPI) => {
    const token = getAccessToken()
    const res = token ? true : false;
    return res;
  }
);

export const toggleLoginModal = createAsyncThunk(
  "toggleLoginModal",
  async (data) => {
    return data;
  }
);

 

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [getLoginDetials.pending]: (state) => {
      state.loading = true;
    },
    [getLoginDetials.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.loginDetails = payload?.status == 200 ? payload : null;
    },
    [getLoginDetials.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [getProfileDetails.pending]: (state) => {
      state.loading = true;
    },
    [getProfileDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.profile = payload;
    },
    [getProfileDetails.rejected]: (state) => {
      state.loading = false;
    },

    [getCode.pending]: (state) => {
      state.loading = true;
    },
    [getCode.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.profile = payload;
    },
    [getCode.rejected]: (state) => {
      state.loading = false;
    },

    [checkUserLoggedIn.pending]: (state) => {
      state.loading = true;
    },
    [checkUserLoggedIn.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isLoggedIn = payload;
    },
    [checkUserLoggedIn.rejected]: (state) => {
      state.loading = false;
    },

    [toggleLoginModal.pending]: (state) => {
      state.loading = true;
    },
    [toggleLoginModal.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.openLoginModal = {
        show: !state.openLoginModal.show,
        currentPage: payload,
      };
    },
    [toggleLoginModal.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const authReducer = authSlice.reducer;
