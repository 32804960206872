import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import FormInputBox from "../../../components/Input/FormInputBox";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
  blueColor04,
  deleteBtnColor,
  greyColor36,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import moment from "moment";
import InputPreview from "../../../components/Input/InputPreview";

const StoreTimeMultiInput = ({
  inputList,
  setInputList,
  isError,
  setIsError,
}) => {
  // const [isError, setIsError] = useState(false);

  const getDurationValue = (storeStartTime, storeEndTime) => {
    if (storeStartTime && storeEndTime) {
      const format = "HH:mm";
      const storeStartMoment = moment(storeStartTime, format);
      const storeEndMoment = moment(storeEndTime, format);

      const duration = moment.duration(storeEndMoment.diff(storeStartMoment));
      const durationHours = duration.hours();
      const durationMinutes = duration.minutes();

      return `${durationHours ? `${durationHours} Hour` : ""}  ${
        durationMinutes ? `${durationMinutes} Minutes` : ""
      }`;
    }

    return "";
  };

  const handleAddNewRow = (input, index) => {
    if (inputList?.length >= 4) return;

    const format = "HH:mm";
    setIsError("");

    if (input?.storeStartTime && input?.storeEndTime) {
      const fromMoment = moment(input?.storeStartTime, format);
      const toMoment = moment(input?.storeEndTime, format);

      //   console.log("fromMoment", fromMoment);
      //   console.log("toMoment", toMoment);

      const isAfter = fromMoment.isAfter(toMoment);
      const isSame = fromMoment.isSame(toMoment);

      if (isAfter) {
        setIsError("Start Time should not be greater End Time");
        return;
      } else if (isSame) {
        setIsError("Start and End Time should not be same");
        return;
      }

      //   if (inputList?.length > 0) {
      //     const prevTiming = inputList?.[index - 1]
      //     const prevStartMoment = moment(prevTiming?.storeStartTime, format);
      //     const prevEndMoment = moment(prevTiming?.storeEndTime, format);

      //     const duration = moment.duration(prevEndMoment.diff(prevStartMoment));
      //     const newEndTime = prevStartMoment.clone().add(duration);

      //     console.log("NEW_END_DATE:", newEndTime.format(format));
      //     console.log("prevTiming:", prevTiming);
      //   }

      setInputList([...inputList, { storeStartTime: "", storeEndTime: "" }]);
    } else setIsError("Enter the start time and end time");
  };

  return (
    <Box>
      {inputList?.map((input, index) => {
        return (
          <Grid
            container
            rowSpacing={2}
            columnSpacing={3}
            key={`time_input_${index}`}
          >
            <Grid item xs={5} md={2.5}>
              <FormInputBox
                label="Start Time"
                placeholder=""
                required
                value={input?.storeStartTime}
                onChange={({ target }) => {
                  let data = [...inputList];
                  data[index] = {
                    ...data[index],
                    storeStartTime: target.value,
                  };
                  setInputList(data);
                }}
                inputType="time"
                // error={data?.errors?.storeStartTime}
              />
            </Grid>

            <Grid item xs={5} md={2.5}>
              <FormInputBox
                label="End Time"
                placeholder=""
                required
                value={input?.storeEndTime}
                onChange={({ target }) => {
                  let data = [...inputList];
                  data[index] = { ...data[index], storeEndTime: target.value };
                  setInputList(data);
                }}
                inputType="time"
                // error={data?.errors?.storeEndTime}
              />
            </Grid>

            <Grid item xs={6} md={2}>
              <InputPreview
                label="Duration"
                value={getDurationValue(
                  input?.storeStartTime,
                  input?.storeEndTime
                )}
                valueStyles={{
                  py:'4px'
                }}
              />
            </Grid>

            <Grid item xs={2} md={1}>
              <IconButton
                onClick={() => {
                  console.log("TIME_INPUT:", inputList);
                  let data = [...inputList];

                  if (data?.length === 1) {
                    setInputList([{ storeStartTime: "", storeEndTime: "" }]);
                    return;
                  }
                  data.splice(index, 1);
                  setInputList(data);
                  setIsError("");
                }}
                sx={{ ...styles.iconBtn, ...styles.deleteBtn(index === 0) }}
                disabled={index === 0}
              >
                <DeleteRoundedIcon sx={{ fontSize: { xs: 18, md: 22 } }} />
              </IconButton>
            </Grid>

            {index === inputList.length - 1 && (
              <Grid item xs={2} md={1}>
                <IconButton
                  onClick={() => {
                    handleAddNewRow(input, index);
                  }}
                  sx={{ ...styles.iconBtn, ...styles.addBtn }}
                >
                  <AddRoundedIcon sx={{ fontSize: { xs: 18, md: 22 } }} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        );
      })}

      <Typography
        component="p"
        fontSize={12}
        fontWeight={500}
        sx={{ mt: "-6px !important", color: "red" }}
      >
        {isError}
      </Typography>
    </Box>
  );
};

export default StoreTimeMultiInput;

const styles = {
  iconBtn: {
    borderRadius: "5px",
    border: `1px solid rgb(133 147 172)`,
    mt: { xs: 2, md: 2 },
  },

  addBtn: {
    width: { xs: "30px", md: "35px" },
    height: { xs: "30px", md: "35px" },
    border: {md:`1px solid ${blueColor04}`,xs:`1px solid ${mobilePrimary}`},
    backgroundColor: { md: blueColor04, xs: mobilePrimary },
    color: whiteColor,
    "&:hover": {
      backgroundColor: whiteColor,
      color: { md: blueColor04, xs: mobilePrimary },
    },
  },

  deleteBtn: (disabled) => ({
    width: { xs: "30px", md: "35px" },
    height: { xs: "30px", md: "35px" },
    border: `1px solid ${disabled ? "#e8e8e8" : deleteBtnColor}`,
    backgroundColor: whiteColor,
    color: deleteBtnColor,
    "&:hover": {
      backgroundColor: deleteBtnColor,
      color: whiteColor,
    },
  }),
};
