import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade19,
  border25,
  whiteColor,
} from "../../../configs/styles/muiThemes";

const ProductImagesPreview = ({ images, imageSelector, onImageClick }) => {
  return (
    <Box sx={styles.card}>
      <Box pb={1.2} mb={0}>
        <Typography sx={styles.title}>Product Images</Typography>
      </Box>

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        {images
          ?.sort((a, b) => a.index - b.index)
          ?.map((item, index) => {
            if (item?.status === "inactive") return null;

            const imageUrl = imageSelector
              ? item[imageSelector]
              : item?.dataURL;
            if (!imageUrl) return;

            return (
              <Grid key={"preview_img_" + index} item xs={12} md={3}>
                <Box
                  component="img"
                  src={imageUrl}
                  width="100%"
                  height="200px"
                  sx={{ objectFit: "contain", borderRadius: "12px" }}
                  onClick={() => onImageClick(imageUrl)}
                />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export default ProductImagesPreview;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    border: border25,
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: "15px 15px",
    mb: 2,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    color: blackShade19,
  },
};
