import { Box, Button, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blueColor02,
  blueColor04,
  greyColor24,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";

function CouponsEmptyCard({ onClick }) {
  return (
    <Box sx={styles.card}>
      <Box sx={styles.iconContainer}>
        <SellOutlinedIcon sx={{ fontSize: "24px" }} />
      </Box>

      <Typography
        color={blackShade05}
        fontSize={13}
        fontWeight={500}
        lineHeight={1.6}
        sx={{ whiteSpace: "pre-line" }}
      >
        {`Increase your sales by 5X, with\n`}
        <Typography fontSize={13} fontWeight={500} sx={{ color: {xs: mobilePrimary, md:blueColor04} }}>
          Limited Time Offers 
        </Typography>
      </Typography>

      <Button
        variant="contained"
        disableElevation
        onClick={onClick}
        sx={styles.actionBtn}
      >
        Manage Coupons
      </Button>
    </Box>
  );
}

export default CouponsEmptyCard;

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: whiteColor,
    marginBottom: "24px",
    borderRadius: "10px",
    //padding: "10px 0 0 0",
    textAlign: "center",
  },
  iconContainer: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    background: "rgb(5 60 81 / 7%)",
    padding: "12px",
    mb: "10px",
    mt:"15px"
  },

  actionBtn: {
    fontSize: "13px",
    fontWeight: "600",
    color: {xs: mobilePrimary, md:blueColor04},
    backgroundColor: whiteColor,
    padding: "3px 22px",
    mt: "12px",
    border: {xs: `1px solid ${mobilePrimary}`, md:`1px solid ${blueColor04}`},
    "&:hover": {
      color: whiteColor,
      backgroundColor: {xs: mobilePrimary, md:blueColor04},
    },
  },
};
