import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
  bannerDescriptionColor,
  blackColor,
  logoImgborder,
  whiteColor,
} from "../../configs/styles/muiThemes";

function Banner({ businessDetails, storeInfoDetails, carouselWidth }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [bannerData, setBannerData] = useState([]);

  const settings = {
    dots: matches ? true : false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    arrows: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
  };

  console.log("Banner Length:", businessDetails);

  return (
    <Box sx={{ pb: 4, pt: 2 }}>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12} display="flex" justifyContent={"center"}>
          <Box
            display="flex"
            flexDirection="column"
            // justifyContent="center"
            alignItems="center"
            px={5}
          >
            <Box
              component="img"
              src={businessDetails?.image}
              width="75px"
              height="75px"
              borderRadius="14px"
              sx={{
                border: logoImgborder,
                mt: { md: 4, xs: 1 },
              }}
            />
            <Typography
              variant="h1"
              // marginY="12px !important"
              textAlign="center"
              fontSize={{ md: "20px", xs: 16 }}
              color={blackColor}
              mt={{ md: 3, xs: 1.8 }}
              mb={{ md: "12px !important", xs: "5px !important" }}
            >
              {storeInfoDetails?.name}
            </Typography>

            <Typography
              variant="body1"
              fontSize={{ md: 14, xs: 12 }}
              color={bannerDescriptionColor}
              textAlign="center"
            >
              {businessDetails?.mainProducts?.join(", ")}
            </Typography>
          </Box>
        </Grid>

        <Grid item md={7.5} xs={12} display={{ md: "block", xs: "none" }}>
          {/* <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              {businessDetails?.banners?.length > 3 ? (
                <Box
                  width={{
                    xs: "calc(100vw - 30px)",
                    md: carouselWidth || "500px",
                  }}
                >
                  <Slider {...settings}>
                    {businessDetails?.banners?.map((item, index) => {
                      return (
                        <Box
                          component="img"
                          alt="banner"
                          key={item?.docType}
                          src={item?.base64String || item?.docURL}
                          sx={{
                            cursor: "pointer",
                            objectFit: "cover",
                            backgroundColor: whiteColor,
                          }}
                          width={{ md: "100%", xs: "100%" }}
                          height={{ md: "auto", xs: "calc(100vw - 25vw)" }}
                          borderRadius={{ md: "16px 0 0 16px", xs: "16px" }}
                        />
                      );
                    })}
                  </Slider>
                </Box>
              ) : (
                <Box
                  component="img"
                  alt="banner"
                  src={
                    businessDetails?.banners?.[0]?.base64String ||
                    businessDetails?.banners?.[0]?.docURL
                  }
                  sx={{
                    cursor: "pointer",
                    objectFit: "cover",
                    backgroundColor: whiteColor,
                  }}
                  width={{ md: "100%", xs: "100%" }}
                  height={{ md: "410px", xs: "calc(100vw - 25vw)" }}
                  borderRadius={{ md: "16px 0 0 16px", xs: "16px" }}
                />
              )}
            </Grid>

            <Grid item md={4} xs={12}>
              {(businessDetails?.banners?.[1]?.base64String ||
                businessDetails?.banners?.[1]?.docURL) && (
                <>
                  {businessDetails?.banners?.[1] ? (
                    <Box
                      component="img"
                      src={
                        businessDetails?.banners?.[1]?.base64String ||
                        businessDetails?.banners?.[1]?.docURL
                      }
                      width="100%"
                      height="185px"
                      sx={{
                        cursor: "pointer",
                        backgroundColor: whiteColor,
                        objectFit: "cover",
                      }}
                      borderRadius="0 16px 16px 0"
                      marginTop="0px !important"
                    />
                  ) : null}
                </>
              )}

              {(businessDetails?.banners?.[2]?.base64String ||
                businessDetails?.banners?.[2]?.docURL) && (
                <>
                  {businessDetails?.banners?.[2] ? (
                    <Box
                      component="img"
                      src={
                        businessDetails?.banners?.[2]?.base64String ||
                        businessDetails?.banners?.[2]?.docURL
                      }
                      width="100%"
                      height="185px"
                      sx={{
                        cursor: "pointer",
                        backgroundColor: whiteColor,
                        objectFit: "cover",
                      }}
                      borderRadius="0 16px 16px 0"
                    />
                  ) : null}
                </>
              )}
            </Grid>
          </Grid> */}
          <Box display="flex" gap={2}>
            <Box
              sx={{ width: "70%" }}
              borderRadius={{ md: "16px 0 0 16px", xs: "16px" }}
            >
              {businessDetails?.banners?.length > 3 ? (
                <Box
                  width={{
                    xs: "calc(100vw - 30px)",
                    md: "34vw",
                  }}
                  height={"400px"}
                >
                  <Slider {...settings}>
                    {businessDetails?.banners?.map((item, index) => {
                      return (
                        <Box
                          component="img"
                          alt="banner"
                          key={item?.docType}
                          src={item?.base64String || item?.docURL}
                          sx={{
                            cursor: "pointer",
                            objectFit: "cover",
                            backgroundColor: whiteColor,
                          }}
                          width={{ md: "100%", xs: "100%" }}
                          height={{ md: "400px", xs: "calc(100vw - 25vw)" }}
                          borderRadius={{ md: "16px 0 0 16px", xs: "16px" }}
                        />
                      );
                    })}
                  </Slider>
                </Box>
              ) : (
                <Box
                  component="img"
                  alt="banner"
                  src={
                    businessDetails?.banners?.[0]?.base64String ||
                    businessDetails?.banners?.[0]?.docURL
                  }
                  sx={{
                    cursor: "pointer",
                    objectFit: "cover",
                    backgroundColor: whiteColor,
                  }}
                  width={{ md: "100%", xs: "100%" }}
                  height={{ md: "410px", xs: "calc(100vw - 25vw)" }}
                  borderRadius={{ md: "16px 0 0 16px", xs: "16px" }}
                />
              )}
            </Box>
            <Box
              display="flex"
              flexDirection={"column"}
              gap={2}
              sx={{ width: "30%" }}
            >
              {(businessDetails?.banners?.[1]?.base64String ||
                businessDetails?.banners?.[1]?.docURL) && (
                <>
                  {businessDetails?.banners?.[1] ? (
                    <Box
                      component="img"
                      src={
                        businessDetails?.banners?.[1]?.base64String ||
                        businessDetails?.banners?.[1]?.docURL
                      }
                      width="100%"
                      height="192px"
                      sx={{
                        cursor: "pointer",
                        backgroundColor: whiteColor,
                        objectFit: "cover",
                      }}
                      borderRadius="0 16px 16px 0"
                      marginTop="0px !important"
                    />
                  ) : null}
                </>
              )}
              {(businessDetails?.banners?.[2]?.base64String ||
                businessDetails?.banners?.[2]?.docURL) && (
                <>
                  {businessDetails?.banners?.[2] ? (
                    <Box
                      component="img"
                      src={
                        businessDetails?.banners?.[2]?.base64String ||
                        businessDetails?.banners?.[2]?.docURL
                      }
                      width="100%"
                      height="192px"
                      sx={{
                        cursor: "pointer",
                        backgroundColor: whiteColor,
                        objectFit: "cover",
                      }}
                      borderRadius="0 16px 16px 0"
                    />
                  ) : null}
                </>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item md={7.5} xs={12} display={{ md: "none", xs: "block" }}>
          <Grid container spacing={2}>
            <Grid item md={7.8} xs={12}>
              {businessDetails?.banners?.length > 1 ? (
                <Box
                  width={{
                    xs: "calc(100vw - 30px)",
                    md: carouselWidth || "570px",
                  }}
                >
                  <Slider {...settings}>
                    {businessDetails?.banners?.map((item, index) => {
                      return (
                        <Box sx={{ px: 1 }} key={index}>
                          <Box
                            component="img"
                            alt="banner"
                            key={item?.docType}
                            src={item?.base64String || item?.docURL}
                            sx={{
                              cursor: "pointer",
                              objectFit: "cover",
                              backgroundColor: whiteColor,
                            }}
                            width={{ md: "100%", xs: "100%" }}
                            height={{ md: "auto", xs: "calc(100vw - 25vw)" }}
                            borderRadius={{ md: "16px 0 0 16px", xs: "12px" }}
                          />
                        </Box>
                      );
                    })}
                  </Slider>
                </Box>
              ) : (
                <Box
                  component="img"
                  alt="banner"
                  src={
                    businessDetails?.banners?.[0]?.base64String ||
                    businessDetails?.banners?.[0]?.docURL
                  }
                  sx={{
                    cursor: "pointer",
                    objectFit: "cover",
                    backgroundColor: whiteColor,
                  }}
                  width={{ md: "100%", xs: "100%" }}
                  height={{ md: "410px", xs: "calc(100vw - 25vw)" }}
                  borderRadius={{ md: "16px 0 0 16px", xs: "16px" }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Banner;
