import React, { useEffect, useState } from "react";
import {
  BGColor02,
  blackShade05,
  blackShade19,
  blackShade21,
  blueColor04,
  borderTextBoxColor,
  greyColor16,
  greyColor21,
  greyColor42,
  greyColor47,
  lightRedColor,
  redColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ASSIGN_CATEGORY_TABLE_HEADER } from "../../utils/data";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAssignCatError,
  getAllBulkProductData,
  selectAllProductRows,
  toggleProductRowSelect,
  updateBusinessCatGetProductCat,
  updateONDCCatGetONDCSubCat,
  updateOndcSubCat,
  updateProductCatGetProductSubCat,
  updateProductSubCat,
} from "../../reducers/catalogBulkUpload/catalogBulkUploadSlice";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import QuickActions from "./Components/QuickActions";
import CellDataPreview from "./Components/CellDataPreview";
import { textOneLines } from "../../utils/styles";
import EmptyCatalogData from "./Components/EmptyCatalogData";

const AssignCategory = ({
  openCategoryModal,
  setOpenCategoryModal,
  productDetails,
  isLoading,
  businessInfo,
  setCurrentPage
}) => {
  const dispatch = useDispatch();

  const [businessCategories, setBusinessCategories] = useState([]);
  const [businessONDCCategories, setBusinessONDCCategories] = useState([]);

  const prepareBusinessCategoryData = () => {
    const businessCatList = businessInfo?.businessCategories || [];
    const businessONDCCatList = businessInfo?.businessONDCCategories || [];
    setBusinessCategories(businessCatList);
    setBusinessONDCCategories(businessONDCCatList);
  };

  // update business category
  const updateBusinessCategory = async (categoryData, index) => {
    if (categoryData?.name !== "Select Business Category") {
      dispatch(
        updateBusinessCatGetProductCat({
          categoryData,
          productDetails,
          index,
        })
      );
    }
  };

  // update product category
  const updateProductCategory = async (categoryData, index) => {
    if (categoryData?.productSubCategory1 !== "Select Product Category") {
      dispatch(
        updateProductCatGetProductSubCat({
          categoryData,
          productDetails,
          index,
        })
      );
    }
  };

  // update product sub category
  const updateProductSubCategory = async (categoryData, index) => {
    console.log("updateProductSubCategory_categoryData:", categoryData);

    if (categoryData?.productSubCategory2 !== "Select Product Sub Category") {
      dispatch(
        updateProductSubCat({
          categoryData,
          productDetails,
          index,
        })
      );
    }
  };

  // update product ONDC category
  const updateONDCCategory = async (categoryData, index) => {
    console.log("updateONDCCategory_categoryData:", categoryData);

    if (categoryData?.name !== "Select ONDC Category") {
      dispatch(
        updateONDCCatGetONDCSubCat({
          categoryData,
          productDetails,
          index,
        })
      );
    }
  };

  // update ondc sub category
  const updateOndcSubCategory = async (categoryData, index) => {
    console.log("updateOndcSubCategory_categoryData:", categoryData);

    if (categoryData?.productSubCategory1 !== "Select ONDC Sub Category") {
      dispatch(
        updateOndcSubCat({
          categoryData,
          productDetails,
          index,
        })
      );
    }
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (productDetails?.length === 0 || !productDetails) {
        dispatch(getAllBulkProductData({ businessId }));
      }
      prepareBusinessCategoryData();
    }

    return () => {
      dispatch(clearAssignCatError());
    };
  }, []);

  return (
    <Box>
      <Typography sx={styles.heading}>Add Category</Typography>
      {productDetails?.length > 0 && (
        <Typography sx={styles.subHeading}>
          ASSIGN CATEGORY to the Products before proceeding with VERIFY &
          CONTINUE
        </Typography>
      )}

      {!isLoading && productDetails?.length > 0 && openCategoryModal && (
        <Box>
          <QuickActions
            openModal={openCategoryModal}
            setOpenModal={setOpenCategoryModal}
            businessCategories={businessCategories}
            businessONDCCategories={businessONDCCategories}
            productDetails={productDetails}
          />
        </Box>
      )}

      {isLoading ? (
        <ThreeDotLoader />
      ) : productDetails?.length > 0 ? (
        <Box sx={styles.tableWrapper}>
          <TableContainer sx={styles.tableContainer} component={Box}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    position: "sticky !important",
                    top: 0,
                    backgroundColor: greyColor42,
                    zIndex: 20,
                    borderRadius: "8px 8px 0 0",
                  }}
                >
                  {ASSIGN_CATEGORY_TABLE_HEADER?.map((item, index) => {
                    return (
                      <TableCell
                        key={item.id}
                        sx={{
                          ...styles.tableCell,
                          ...styles.colSticky(index === 0),
                        }}
                      >
                        <Box
                          display={index === 0 ? "flex" : "inline-block"}
                          alignItems="center"
                          gap={1.2}
                        >
                          {index === 0 && (
                            <Checkbox
                              // size=""
                              checked={productDetails?.every(
                                (item) => item.isSelected
                              )}
                              onChange={({ target }) => {
                                dispatch(
                                  selectAllProductRows({
                                    productDetails,
                                    isSelected: target.checked,
                                  })
                                );
                              }}
                              sx={styles.checkbox}
                            />
                          )}
                          <Box sx={{ fontSize: 12 }}>
                            {item?.title}{" "}
                            {item?.isRequired && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </Box>
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody sx={{ backgroundColor: whiteColor }}>
                {productDetails?.map((productInfo, index) => {
                  return (
                    <TableRow
                      key={productInfo?.id}
                      sx={{
                        borderBottom: `1px solid ${borderTextBoxColor}`,
                      }}
                    >
                      <TableCell
                        sx={{
                          ...styles.tableStickyLeftCell,
                        }}
                      >
                        <Box display="flex" alignItems="flex-start" gap={1.2}>
                          <Checkbox
                            // size="large"
                            sx={styles.checkbox}
                            checked={productInfo?.isSelected || false}
                            onChange={() => {
                              dispatch(
                                toggleProductRowSelect({
                                  productDetails,
                                  index,
                                  isSelected: productInfo?.isSelected,
                                })
                              );
                            }}
                          />
                          <Typography
                            variant="body1"
                            title={productInfo?.productName}
                            sx={{ ...styles.prodNameBox, ...textOneLines }}
                          >
                            {productInfo?.productName}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell
                        sx={styles.tableBodyCell(
                          productInfo?.errors?.selectedOndcCategory
                        )}
                      >
                        <CellDataPreview
                          value={
                            productInfo?.productMainCategory?.[0]?.ondcValue ||
                            "-"
                          }
                          errorMsg={productInfo?.errors?.selectedOndcCategory}
                        />
                        {/* <TableDropdown
                          value={
                            productInfo?.selectedOndcCategory?.name ||
                            "Select ONDC Category"
                          }
                          onValueChange={(value) => {
                            updateONDCCategory(value, index);
                          }}
                          returnFullItem
                          menuList={
                            businessONDCCategories?.length > 0
                              ? [
                                  { name: "Select ONDC Category" },
                                  ...businessONDCCategories,
                                ]
                              : [{ name: "Select ONDC Category" }]
                          }
                          valueSelector="name"
                          borderFull
                          rounded
                          required
                          errorMsg={productInfo?.errors?.selectedOndcCategory}
                        /> */}
                      </TableCell>

                      <TableCell
                        sx={styles.tableBodyCell(
                          productInfo?.errors?.selectedOndcSubCategory
                        )}
                      >
                        <CellDataPreview
                          value={
                            productInfo?.productSubCategory?.[0]
                              ?.ondcSubValue || "-"
                          }
                          errorMsg={
                            productInfo?.errors?.selectedOndcSubCategory
                          }
                        />

                        {/* <TableDropdown
                          value={
                            productInfo?.selectedOndcSubCategory
                              ?.productSubCategory1 ||
                            "Select ONDC Sub Category"
                          }
                          onValueChange={(value) => {
                            updateOndcSubCategory(value, index);
                          }}
                          returnFullItem
                          menuList={
                            productInfo?.ONDCSubCategoryList?.length > 0
                              ? [
                                  {
                                    productSubCategory1:
                                      "Select ONDC Sub Category",
                                  },
                                  ...productInfo?.ONDCSubCategoryList,
                                ]
                              : [
                                  {
                                    productSubCategory1:
                                      "Select ONDC Sub Category",
                                  },
                                ]
                          }
                          valueSelector="productSubCategory1"
                          borderFull
                          rounded
                          required
                        /> */}
                      </TableCell>

                      <TableCell
                        sx={styles.tableBodyCell(
                          productInfo?.errors?.productMainCategory
                        )}
                      >
                        <CellDataPreview
                          value={
                            productInfo?.productMainCategory?.[0]?.value || "-"
                          }
                          errorMsg={productInfo?.errors?.productMainCategory}
                        />

                        {/* <TableDropdown
                          value={
                            productInfo?.productMainCategory?.[0]?.value ||
                            "Select Business Category"
                          }
                          onValueChange={(value) => {
                            updateBusinessCategory(value, index);
                          }}
                          returnFullItem
                          menuList={[
                            { name: "Select Business Category" },
                            ...businessCategories,
                          ]}
                          valueSelector="name"
                          borderFull
                          rounded
                          required
                          errorMsg={productInfo?.errors?.productMainCategory}
                        /> */}
                      </TableCell>

                      <TableCell
                        sx={styles.tableBodyCell(
                          productInfo?.errors?.productSubCategory
                        )}
                      >
                        <CellDataPreview
                          value={
                            productInfo?.productSubCategory?.[0]?.value || "-"
                          }
                          errorMsg={productInfo?.errors?.productSubCategory}
                        />
                        {/* <TableDropdown
                          value={
                            productInfo?.productSubCategory?.[0]?.value ||
                            "Select Product Category"
                          }
                          onValueChange={(value) => {
                            updateProductCategory(value, index);
                          }}
                          returnFullItem
                          menuList={
                            productInfo?.productCategoryList?.length > 0
                              ? [
                                  {
                                    productSubCategory1:
                                      "Select Product Category",
                                  },
                                  ...productInfo?.productCategoryList,
                                ]
                              : [
                                  {
                                    productSubCategory1:
                                      "Select Product Category",
                                  },
                                ]
                          }
                          valueSelector="productSubCategory1"
                          borderFull
                          rounded
                          required
                          errorMsg={productInfo?.errors?.productSubCategory}
                        /> */}
                      </TableCell>

                      <TableCell sx={styles.tableBodyCell(false)}>
                        <CellDataPreview
                          value={
                            productInfo?.productSubCategory2?.[0]?.value || "-"
                          }
                        />
                        {/* <TableDropdown
                          value={
                            productInfo?.productSubCategory2?.[0]?.value ||
                            "Select Product Sub Category"
                          }
                          onValueChange={(value) => {
                            updateProductSubCategory(value, index);
                          }}
                          returnFullItem
                          menuList={
                            productInfo?.productSubCategoryList?.length > 0
                              ? [
                                  {
                                    productSubCategory2:
                                      "Select Product Sub Category",
                                  },
                                  ...productInfo?.productSubCategoryList,
                                ]
                              : [
                                  {
                                    productSubCategory2:
                                      "Select Product Sub Category",
                                  },
                                ]
                          }
                          valueSelector="productSubCategory2"
                          borderFull
                          rounded
                          required
                        /> */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <EmptyCatalogData
          message="Catalog details not uploaded. Please make sure the data is in the required format as in the template"
          goBack={() => {setCurrentPage("upload")}}
        />
      )}
    </Box>
  );
};

export default AssignCategory;

const styles = {
  heading: {
    fontSize: "14px",
    fontWeight: "600",
    color: blackShade19,
  },

  subHeading: {
    fontSize: "13px",
    fontWeight: "400",
    color: blackShade21,
    mb: 2,
  },

  tableWrapper: {
    width: "calc(100vw - 310px)",
    borderRadius: "8px",
    border: `1px solid ${borderTextBoxColor}`,
  },

  tableContainer: {
    borderRadius: "8px",
    overflow: "scroll",
    maxHeight: "calc(100vh - 350px)",
  },

  tableHeadings: {
    align: "right",
    color: greyColor16,
    fontSize: "12px",
    fontWeight: "500",
    backgroundColor: BGColor02,
  },

  tableCell: {
    color: greyColor47,
    fontSize: "12.5px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    padding: "8px 15px",
    backgroundColor: greyColor42,
    borderRight: `1px solid ${whiteColor}`,
  },

  colSticky: (isSticky) => ({
    position: "-webkit-sticky",
    position: isSticky ? "sticky" : "unset",
    width: isSticky ? "100px" : "auto",
    minWidth: isSticky ? "100px" : "auto",
    maxWidth: isSticky ? "100px" : "auto",
    left: "0px",
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  }),

  tableBodyCell: (isError) => ({
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: isError ? lightRedColor : whiteColor,
    padding: "8px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,

    boxShadow: isError ? `0px -1px 0 0 ${redColor} inset` : "none",
    // borderBottom: isError ? `1px solid ${lightRedColor}` : `1px solid ${borderTextBoxColor}`,
  }),

  tableStickyLeftCell: {
    minWidth: "100px !important",
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: whiteColor,
    padding: "12px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,
    position: "sticky",
    left: 0,
    zIndex: 10,
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  prodNameBox: {
    minWidth: "250px",
    maxWidth: "250px",
    whiteSpace: "normal",
    fontSize: 12,
    fontWeight: 600,
    color: blackShade19,
    lineHeight: "16px",
  },

  checkbox: {
    color: greyColor21,
    p: 0,
    transform: "scale(1.3)",
    "&.Mui-checked": {
      color: blueColor04,
    },
  },
};
