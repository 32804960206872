import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBusinessProductsAPI } from "../../service/api_calls/inventory/InventoryApi";
import {
  getMainBranchBusinessProductsAPI,
  getProdcutsSyncStatusAPI,
  pushProductsToSubBranch,
  updateProductInfoInSubBranch,
} from "../../service/api_calls/multiBranchCatalog/multiBranchCatalogAPIs";
import {
  prepareCopyFranchiseProductPostData,
  prepareUpdateFranchiseProductPostData,
} from "./multiBranchCatalogHelper";
import { getMainBranchInfoApi } from "../../service/api_calls/dashboard/dashboardApi";

const initialState = {
  masterBranchInfo: null,
  masterBranchInfoLoader: false,

  mainBranchProducts: [],
  mainBranchProductsPageNo: 1,
  mainBranchProductsLoader: false,

  activeBusinessProducts: [],
  activeBusinessProductsPageNo: 1,
  activeBusinessProductsLoader: false,

  branchPushLoader: false,
};

// Get Master Branch Info
export const getMasterBranchInfo = createAsyncThunk(
  "getMasterBranchInfo",
  async (param, thunkAPI) => {
    const res = await getMainBranchInfoApi(param);
    return res;
  }
);

// Get All Main Branch Products
export const getMainBranchProducts = createAsyncThunk(
  "getMainBranchProducts",
  async (param, thunkAPI) => {
    const res = await getMainBranchBusinessProductsAPI(param);

    const masterBranchId = param?.businessId || "";
    const subBranchId = param?.selectedBranchId || "";

    const productArr = res?.details || []

    // Get Product Sync status with given sub-branch Id
    if (subBranchId) {
      const syncRes = await getProdcutsSyncStatusAPI({
        masterBranchId,
        subBranchId,
      });

      const productSyncStatus = syncRes?.details || [];

      // Adding sync status & productLastImportDate attribute into product array
      const updatedList = productArr?.map((item) => {
        const isSynced = productSyncStatus?.find(
          (data) =>
            data?.masterProductId === item?.productId &&
            data?.childProductDetils?.childProductId
        );
  
        return {
          ...item,
          isSynced: !!isSynced,
          childProductId: isSynced?.childProductDetils?.childProductId || "",
          productLastImportDate:
            isSynced?.childProductDetils?.productImportDate || null,
        };
      });

      return { res: {details: updatedList}, isRefresh: param?.isRefresh };
    }

    return { res, isRefresh: param?.isRefresh };
  }
);

// Push Main Branch Products to Sub Branchs
export const pushMainBranchProductsToSubBranch = createAsyncThunk(
  "pushMainBranchProductsToSubBranch",
  async (param, thunkAPI) => {
    // const {mainBranchBusinessId, subBranchBusienssId, mainBrachProducts, latitude, longitude } = param
    const { selectedSubBranch, mainBranchProducts } = param;

    const result = [];

    // for (let i = 0; i < branchInfoList.length; i++) {
    //   const branchInfo = branchInfoList[i];

    //   const postData = prepareCopyFranchiseProductPostData({
    //     ...param,
    //     subBranchBusienssId: branchInfo?.branchId,
    //   });

    //   console.log(`PostData___${i}`, postData);

    //   // const res = await pushProductsToSubBranch(postData);
    //   // result.push(res);

    //   // result.push(`Product Push to Branch ${i}`);
    // }

    // const productList = [...mainBranchProducts];
    // const resetProductList = productList?.map((item) => ({
    //   ...item,
    //   isSelected: false,
    //   isSynced: false
    // }));

    // return { result, resetProductList };

    const syncedProducts = [];
    const notSyncedProducts = [];

    mainBranchProducts.forEach((productData) => {
      if (productData?.isSelected) {
        if (productData?.isSynced) {
          syncedProducts?.push(productData);
        } else {
          notSyncedProducts?.push(productData);
        }
      }
    });

    console.log("selectedBranchInfo______syncedProducts:", syncedProducts);
    console.log(
      "selectedBranchInfo______notSyncedProducts:",
      notSyncedProducts
    );

    if (notSyncedProducts?.length > 0) {
      const postData = prepareCopyFranchiseProductPostData({
        ...param,
        notSyncedProducts,
        subBranchBusienssId: selectedSubBranch?.branchId,
      });

      console.log("selectedBranchInfo______Synced_postData", postData);

      const res = await pushProductsToSubBranch(postData);
      result.push(res);
    }

    if (syncedProducts?.length > 0) {
      const postDataList = prepareUpdateFranchiseProductPostData({
        ...param,
        syncedProducts,
        subBranchBusienssId: selectedSubBranch?.branchId,
      });

      console.log("selectedBranchInfo______Synced_postData", postDataList);

      // Use Promise.all to handle the asynchronous calls concurrently
      const result = await Promise.all(
        postDataList.map((postData) => updateProductInfoInSubBranch(postData))
      );

      result.push({ result });
    }

    const productList = [...mainBranchProducts];
    const resetProductList = productList?.map((item) => ({
      ...item,
      isSelected: false,
      isSynced: false,
      childProductId: "",
    }));

    return { result, resetProductList };
  }
);

// Get Active Business Products For Sub_Branch
export const getActiveBusinessProducts = createAsyncThunk(
  "getActiveBusinessProducts",
  async (param, thunkAPI) => {
    const res = await getBusinessProductsAPI(param);
    return { res, isRefresh: param?.isRefresh };
  }
);

export const multiBranchCatalog = createSlice({
  name: "multiBranchCatalog",
  initialState,
  reducers: {
    clearMultiBranchInfo: (state, action) => {
      state.mainBranchProducts = [];
      state.mainBranchProductsPageNo = 1;
      state.mainBranchProductsLoader = false;

      state.activeBusinessProducts = [];
      state.activeBusinessProductsPageNo = 1;
      state.activeBusinessProductsLoader = false;

      state.branchPushLoader = false;
    },

    clearMaterBranchInfo: (state, action) => {
      state.masterBranchInfo = null;
      state.masterBranchInfoLoader = false;
    },

    // Update Change to List
    updateMainProductList: (state, action) => {
      const { updatedList } = action.payload;
      state.mainBranchProducts = [...updatedList];
    },

    // Update Sub Branch Products Change
    updateSubProductList: (state, action) => {
      const { updatedList } = action.payload;
      state.activeBusinessProducts = [...updatedList];
    },
  },

  extraReducers: {
    //fetch master branch info
    [getMasterBranchInfo.pending]: (state) => {
      state.masterBranchInfoLoader = true;
    },
    [getMasterBranchInfo.fulfilled]: (state, { payload }) => {
      state.masterBranchInfo = payload;
      state.masterBranchInfoLoader = false;
    },
    [getMasterBranchInfo.rejected]: (state) => {
      state.masterBranchInfoLoader = false;
    },

    //fetch All Main Branch Products
    [getMainBranchProducts.pending]: (state) => {
      state.mainBranchProductsLoader = true;
    },
    [getMainBranchProducts.fulfilled]: (state, { payload }) => {
      const productsData = payload?.res?.details || [];
      const isRefresh = payload?.isRefresh;

      if (productsData.length > 0) {
        const productArr = isRefresh
          ? [...productsData]
          : [...state.mainBranchProducts, ...productsData];

        state.mainBranchProducts = productArr;
      }

      const pageNo = isRefresh ? 2 : state.mainBranchProductsPageNo + 1;
      state.mainBranchProductsPageNo = pageNo;
      state.mainBranchProductsLoader = false;
    },
    [getMainBranchProducts.rejected]: (state) => {
      state.mainBranchProductsLoader = false;
    },

    //Push Main Branch Products to Sub Branch
    [pushMainBranchProductsToSubBranch.pending]: (state) => {
      state.branchPushLoader = true;
    },
    [pushMainBranchProductsToSubBranch.fulfilled]: (state, { payload }) => {
      state.mainBranchProducts = [...payload?.resetProductList];
      state.branchPushLoader = false;
    },
    [pushMainBranchProductsToSubBranch.rejected]: (state) => {
      state.branchPushLoader = false;
    },

    // fetch active business products for sub branch
    [getActiveBusinessProducts.pending]: (state) => {
      state.activeBusinessProductsLoader = true;
    },
    [getActiveBusinessProducts.fulfilled]: (state, { payload }) => {
      const productsData = payload?.res?.productList || [];
      const isRefresh = payload?.isRefresh;

      if (productsData.length > 0) {
        // const productArr = isRefresh
        //   ? [...productsData]
        //   : [...state.activeBusinessProducts, ...productsData];
        // state.activeBusinessProducts = productArr;

        state.activeBusinessProducts = [...productsData];
      }

      const pageNo = isRefresh ? 2 : state.activeBusinessProductsPageNo + 1;
      state.activeBusinessProductsPageNo = pageNo;
      state.activeBusinessProductsLoader = false;
    },
    [getActiveBusinessProducts.rejected]: (state) => {
      state.activeBusinessProductsLoader = false;
    },
  },
});

export const {
  clearMultiBranchInfo,
  clearMaterBranchInfo,
  updateMainProductList,
  updateSubProductList,
} = multiBranchCatalog.actions;

export const multiBranchCatalogReducer = multiBranchCatalog.reducer;
