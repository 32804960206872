import moment from "moment";
import { getProfileId } from "../../../utils/validationUtils";
import { getApiInstance } from "../../AppService";
import {
  ADD_BANK_DETAILS,
  ADD_BUSINESS_DATA,
  ADD_NEW_BUSINESS_OWNER,
  BANK_DETAILS,
  CHECK_BUSINESS_ROLE,
  DELETE_BANNER_DATA,
  EDIT_BUSINESS_DATA,
  GET_BUSINESS_CATEGORY,
  GET_BUSINESS_TYPE,
  GET_GEO_POINT,
  GET_ONDC_BUSINESS_CATEGORY,
  GET_ONDC_CITY_TO_PINCODE,
  GET_USER_EDIT_ELIGIBILITY,
  MY_BUSINESS_SUMMARY,
  PUBLISH_BUSINESS_DATA,
  REMOVE_BUSINESS_DELEGATES,
  SEND_WHATSPP_NOTIFICATION,
  UPDATE_BANNER_DATA,
  UPDATE_BUSINESS_DELEGATES,
  UPDATE_BUSINESS_OWNER_PRIVILEGE,
  UPDATE_BUSINESS_ROLE,
  UPDATE_BUSINESS_TIMINGS_DELIVERY_METHOD,
  UPDATE_COMMERCE_SUBMIT,
  UPDATE_INACTIVE_DATE,
  UPDATE_LOGO_DATA,
  UPDATE_ONDC_BUSINESS_OPTIONAL_FIELDS,
  UPDATE_ONDC_CONTROLS,
  VERIFY_BUSINESS_ROLE,
  WHATSAPP_NONIFICATION,
} from "../../constant";

export const getMyBusinessSummaryAPI = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(MY_BUSINESS_SUMMARY);
    // console.log("getMyBusinessSummaryAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// get Geo Points, city, state..
export const getGeoPointApi = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(`${GET_GEO_POINT}${data}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// get Geo Points, city, state..
export const getBusinessLookupApi = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_BUSINESS_TYPE);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBusinessCategoryApi = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_BUSINESS_CATEGORY);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBusinessONDCCategoryApi = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_ONDC_BUSINESS_CATEGORY);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserEditEligibilityApi = async (data) => {
  const profileId = getProfileId();
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_USER_EDIT_ELIGIBILITY + profileId);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addBusinessData = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(ADD_BUSINESS_DATA, data);
    return response.data;
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const editBusinessData = async (data, businessID) => {
  try {
    const instance = getApiInstance({
      header: {
        businessID,
      },
    });
    const response = await instance.post(EDIT_BUSINESS_DATA, data);
    return response.data;
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const updateLogo = async (data, businessID) => {
  try {
    const instance = getApiInstance({
      header: {
        businessID,
      },
    });
    const response = await instance.post(UPDATE_LOGO_DATA, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteItem = async (data, businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });
    const response = await instance.post(DELETE_BANNER_DATA + data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateBanner = async (data, businessID) => {
  try {
    const instance = getApiInstance({
      header: {
        businessID,
      },
    });
    const response = await instance.post(UPDATE_BANNER_DATA, data);
    return response.data;
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const publishBusinessData = async (data, businessID) => {
  try {
    const instance = getApiInstance({
      header: {
        businessID,
      },
    });
    const response = await instance.post(PUBLISH_BUSINESS_DATA, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

//Update commerce info details
export const updateCommerceSubmitAPI = async (data, businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });
    const response = await instance.post(UPDATE_COMMERCE_SUBMIT, data);
    return response.data;
  } catch (error) {
    console.log(error);
    return { error };
  }
};

// update Business Time and Delivery Methods
export const updateBusinessTimingAndDeliveryMethod = async (
  data,
  businessId
) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });
    const response = await instance.post(
      UPDATE_BUSINESS_TIMINGS_DELIVERY_METHOD,
      data
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return { error };
  }
};

//Update ONDC controls details
export const getOndcCityToPincodeApi = async (data) => {
  try {
    const instance = getApiInstance();

    const response = await instance.get(GET_ONDC_CITY_TO_PINCODE, {
      params: {
        pinCode: data?.pincode,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

//Update ONDC controls details
export const updateONDCControlsAPI = async (data, businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });
    const response = await instance.post(UPDATE_ONDC_CONTROLS, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

//Update ONDC Business Optional Fields
export const updateONDCBusinessOptionalFields = async (data, businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessID: businessId,
      },
    });
    const response = await instance.post(UPDATE_ONDC_BUSINESS_OPTIONAL_FIELDS, {
      ...data,
      businessId,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const sendWhatsAppNotification = async (data, businessId) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    });
    const response = await instance.post(WHATSAPP_NONIFICATION, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addNewBusinessOwner = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(ADD_NEW_BUSINESS_OWNER, data, {
      headers: {
        businessId: data?.businessId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateBusinessOwner = async ({ businessId, data }) => {
  try {
    const instance = getApiInstance();
    const profileId = sessionStorage.getItem("profileId");
    const response = await instance.post(
      UPDATE_BUSINESS_OWNER_PRIVILEGE,
      data,
      {
        headers: {
          businessId: businessId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateBusinessDelegates = async (data) => {
  try {
    const instance = getApiInstance();
    const profileId = sessionStorage.getItem("profileId");
    const response = await instance.post(UPDATE_BUSINESS_DELEGATES, data, {
      headers: {
        businessId: data.businessId,
        profileID: profileId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const deleteBusinessDelegates = async (data) => {
  try {
    const instance = getApiInstance();
    const profileId = sessionStorage.getItem("profileId");
    const response = await instance.post(REMOVE_BUSINESS_DELEGATES, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const verifyBusinessRoleApi = async ({ businessId, params }) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(VERIFY_BUSINESS_ROLE, {
      headers: {
        businessId,
      },
      params,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateBusinessRoleApi = async ({ businessId, params }) => {
  try {
    const role = params?.role || "";
    const phoneNo = params?.phoneNo || "";
    const newStatus = params?.newStatus || "";
    const type = params?.type || "";

    const instance = getApiInstance({
      header: {
        businessId,
      },
    });
    const response = await instance.post(
      UPDATE_BUSINESS_ROLE +
        `?role=${role}&phoneNo=${phoneNo}&newStatus=${newStatus}&type=${type}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const checkBusinessRoleApi = async (params) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(CHECK_BUSINESS_ROLE, {
      params,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const sendBusinessWhatsappNotificationApi = async (postData) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(SEND_WHATSPP_NOTIFICATION, postData);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};



export const getBankDetailsApi = async (data) => {
  try {
    const profileId = sessionStorage.getItem("profileId");
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
        profileId,
      },
    });
    const response = await instance.get(BANK_DETAILS, {});
    console.log("getBankDetailsApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const postBankDetailsApi = async (postData) => {
  try {
    const profileId = sessionStorage.getItem("profileId");

    const instance = getApiInstance({
      header: {
        businessId: postData?.businessId,
        profileId,
      },
    });
    const response = await instance.post(ADD_BANK_DETAILS, postData);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const UpdateInactiveDateApi = async (data) => {
  try {
    const profileId = sessionStorage.getItem("profileId");

    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
        profileId,
      },
    });
    const response = await instance.put(UPDATE_INACTIVE_DATE, data);
    console.log("UPDATE_INACTIVE_DATE", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};




export const prepareBankDetailsPostData = (data) => {
console.log("checking",data);
  const postData = {
    businessId: data?.businessId,
    accountNo: data?.accountNo,
    ifscCode: data?.ifscCode,
    upiId: data?.upiId || "",
    bankName: data?.bankName,
    bankAddress: data?.bankAddress,
    accountName: data?.accountName,
    accountType: data?.accountType,
    addedDate: data?.addedDate
      ? moment(data?.addedDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
      : "",
    udpatedDate: moment().format("YYYY-MM-DDTHH:mm:ss[Z]"),
    expiredDate: "",
    status: "active",
    mainBranchBusinessId: "",
  };

  return postData;
};