import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubBranchCalalog from "./SubBranchCalalog";
import ProductcatalogList from "./ProductcatalogList";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";

const ProductCatalog = () => {
  const dispatch = useDispatch();

  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
    }

    return () => {};
  }, []);

  return activeBusinessInfo ? (
    !activeBusinessInfo?.mainBranch && activeBusinessInfo?.mainBranchId ? (
      <>
        <SubBranchCalalog />
      </>
    ) : (
      <>
        <ProductcatalogList status="active" />
      </>
    )
  ) : (
    <></>
  );
};

export default ProductCatalog;
