import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  borderTextbox,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import DashboardFormHeader from "./DashboardFormHeader";
import DashboardStepper from "../DashboardStepper";
import FormInputBox from "../../../components/Input/FormInputBox";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import { useState } from "react";
import { STORE_STEP_LIST } from "../../../utils/appData";

function OtherDetails({ gotoNext, goBack }) {
  const [minOrderAtm, setMinOrderAtm] = useState("Enter Minimum Order Amount");
  const [returnPolicy, setReturnPolicy] = useState("Enter Return Policy");

  const MIN_ORDER_AMT = [
    { label: "Enter Minimum Order Amount" },
    { label: "Brand" },
    { label: "Retailers" },
    { label: "Wholesalers/Distributors" },
  ];

  const RETURN_POLICY = [
    { label: "Enter Return Policy" },
    { label: "Brand" },
    { label: "Retailers" },
    { label: "Wholesalers/Distributors" },
  ];

  return (
    <Box sx={styles.card}>
      <DashboardFormHeader />
      <DashboardStepper stepList={STORE_STEP_LIST} currentPage="other-detail" />

      <Box m="20px 0 15px 0">
        <Typography component="h5" sx={styles.heading}>
          Other Detail
        </Typography>

        <Box sx={{ pb: 1, mt: "20px" }}>
          <Grid container rowSpacing={2} columnSpacing={3}>
            <Grid item xs={12} md={4}>
              <FormInputBox
                label="Preferred Payment Method"
                placeholder="Enter Preferred Payment Method"
                isDashboardInput
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <SelectWithLabel
                label="Minimum Order Amount"
                value={minOrderAtm}
                onValueChange={() => {}}
                menuList={MIN_ORDER_AMT}
                isDashboardInput
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <SelectWithLabel
                label="Return Policy"
                value={returnPolicy}
                onValueChange={() => {}}
                menuList={RETURN_POLICY}
                isDashboardInput
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInputBox
                label="Account Number"
                placeholder="Enter Account Number"
                isDashboardInput
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box mt="15px">
        <ActionButtons
          onBackClick={goBack}
          onSaveClick={() => {}}
          onNextClick={gotoNext}
          backBtnText="Previous"
          showBackBtn
        />
      </Box>
    </Box>
  );
}

export default OtherDetails;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    p: "20px",
    my: "25px",
  },

  heading: {
    fontSize: { xs: 15, md: 18 },
    color: blackShade05,
    fontWeight: 600,
    lineHeight: "20px",
  },
};
