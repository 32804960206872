import { Box, Chip, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import ThreeDotLoader from "../components/ThreeDotLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  BGColor02,
  bgColor15,
  blackColor,
  blackShade01,
  blackShade05,
  blackShade21,
  blueColor04,
  greyColor40,
  greyColor9,
  logoImgborder,
  mobilePrimary,
  whiteColor,
} from "../configs/styles/muiThemes";
import {
  clearIssueIdInfo,
  clearOrderIdInfo,
  getFulfillmentGrievanceDetails,
  getGrievanceDetails,
  searchCustomerGrievanceIssueId,
  searchCustomerGrievanceOrderId,
} from "../reducers/grievanceIssue/grievanceSlice";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import ItemIssueTab from "./CustomerGrivvance/TabCardList/ItemIssueTab";
import { stickyHeader } from "../utils/styles";
import VideoModal from "../components/VideoModal";
import { ORDER_VIDEO_DATA } from "../utils/data";
import HowToLinkCard from "../components/Cards/HowToLinkCard";
import { useNavigate } from "react-router-dom";
import SearchBoxInput from "../components/Input/SearchBoxInput";
import { getGrievanceData } from "../service/api_calls/grievance/grievanceApi";
import { getBuyerAppLookupAPI } from "../service/api_calls/BusinessPreview/BusinessPreviewAPI";
import PillTabs from "../components/PillTabs";

function CustomerGrievance({ category, status }) {
  const [tabValue, setTabValue] = useState(0);
  const [tabTitle, setTabTitle] = useState("New");
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [issuetabValue, setIssueTabValue] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");
  const [issuesearchQuery, setIssuesearchQuery] = useState("");
  const [isClearSearch, setIsClearSearch] = useState(false);
  const [isClearSearchIssue, setIsClearSearchIssue] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [tabCountData, setTabCountData] = useState({
    open: "XX",
    processing: "XX",
    resolved: "XX",
    closed: "XX",
  });
  const [activeTab, setActiveTab] = useState("active");

  const businessUrl = sessionStorage.getItem("costboWEBURL");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };
  const Issue_Status = [
    { id: 0, label: "ITEM ISSUE", mobileLabel: "ITEM ISSUE" },
    { id: 1, label: "FULLFILLMENT ISSUE", mobileLabel: "FULLFILLMENT ISSUE" },
  ];
  const Item_Issue_Status = [
    { id: 0, label: "New", mobileLabel: "NEW" },
    { id: 1, label: "Processing", mobileLabel: "PROCESSING" },
    { id: 2, label: "Resolved", mobileLabel: "RESOLVED" },
    { id: 3, label: "Closed", mobileLabel: "CLOSED" },
  ];
  const getTabTitle = (tabsNum) => {
    switch (tabsNum) {
      case 0:
        return "OPEN";
      case 1:
        return "PROCESSING";
      case 2:
        return "RESOLVED";
      case 3:
        return "CLOSED";
      default:
        return "OPEN";
    }
  };
  const getTabValue = (status) => {
    switch (status) {
      case "open":
        return 0;
      case "processing":
        return 1;
      case "resolved":
        return 2;
      case "closed":
        return 3;
      default:
        return 0;
    }
  };

  const getAllIsuueCount = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const newIssueData = await getGrievanceData({
      status: "OPEN",
      pageNo: 0,
      category: "ITEM",
      businessId: businessId,
    });
    const processingIssueData = await getGrievanceData({
      status: "PROCESSING",
      pageNo: 0,
      category: "ITEM",
      businessId: businessId,
    });
    const resolvedIssueData = await getGrievanceData({
      status: "RESOLVED",
      pageNo: 0,
      category: "ITEM",
      businessId: businessId,
    });
    const closedIssueData = await getGrievanceData({
      status: "CLOSED",
      pageNo: 0,
      category: "ITEM",
      businessId: businessId,
    });

    const countData = {
      open: newIssueData?.issueData?.length,
      processing: processingIssueData?.issueData?.length,
      resolved: resolvedIssueData?.issueData?.length,
      closed: closedIssueData?.issueData?.length,
    };
    console.log("countData", countData);
    setTabCountData(countData);
  };

  const getItemIssueCoutByStatus = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return tabCountData?.open;
      case 1:
        return tabCountData?.processing;
      case 2:
        return tabCountData?.resolved;
      case 3:
        return tabCountData?.closed;
      default:
        return "XX";
    }
  };

  const refreshNewProcessing = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    let newIssueData = null;
    dispatch(
      getGrievanceDetails({
        status: "OPEN",
        pageNo: 0,
        category: "ITEM",
        businessId: businessId,
      })
    ).then(async (res) => {
      newIssueData = res?.payload?.issueData;
      console.log("newIssueData_res", res);
      const processingIssueData = await getGrievanceData({
        status: "PROCESSING",
        pageNo: 0,
        category: "ITEM",
        businessId: businessId,
      });
      console.log("OpenIssueData", newIssueData);
      console.log("processingIssueData", processingIssueData);
      setTabCountData((prevState) => ({
        ...prevState,
        open: newIssueData ? newIssueData?.length : 0,
        processing: processingIssueData?.issueData?.length,
      }));
    });
  };

  const refreshProcessingResolved = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    let processingIssueData = null;
    dispatch(
      getGrievanceDetails({
        status: "PROCESSING",
        pageNo: 0,
        category: "ITEM",
        businessId: businessId,
      })
    ).then(async (res) => {
      processingIssueData = res?.payload?.issueData;
      console.log("processingIssueData_res", res);
      const resolvedIssueData = await getGrievanceData({
        status: "RESOLVED",
        pageNo: 0,
        category: "ITEM",
        businessId: businessId,
      });
      console.log("processingIssueData", processingIssueData);
      console.log("resolvedIssueData", resolvedIssueData);
      setTabCountData((prevState) => ({
        ...prevState,
        processing: processingIssueData ? processingIssueData?.length : 0,
        resolved: resolvedIssueData?.issueData?.length,
      }));
    });
  };

  const handleTabChange = (event, newValue) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    setTabTitle("New");
    setTabValue(newValue);
  };
  const handleChangeIsuueTAB = (event, newValue) => {
     handleOnPageChange();
    setIssueTabValue(newValue);
  }
  
  const handleChange = (event, newValue) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    console.log("event, newValue", event, newValue);
    handleTabChange();
    setTabTitle(getTabTitle(newValue));
    dispatch(
      getGrievanceDetails({
        status: getTabTitle(newValue),
        pageNo: 0,
        category: category ? category : "ITEM",
        businessId: businessId,
      })
    );
    setProductsList(grievanceData?.issueData);
    setTabTitle("New");
    setTabValue(newValue);
  };

  const handleOnPageChange = () => {
    navigate(`/${businessUrl}/igmIssuesFulfillment`, {
      state: { category: "FULFILLMENT", status: "open" },
    });
  };

  const {
    grievanceData,
    grievanceLoader,
    searchByOrderId,
    searchByIssueId,
    searchByOrderIdLoader,
    searchByIssueIdLoader,
  } = useSelector((state) => state.grievance);

  const handleSearch = () => {
    clearSearchIssueId();
    if (!searchQuery) return;
    const businessId = sessionStorage.getItem("selectedBusinessId");
    // setSelectedCategory("All");
    // setSelectedSubCat("All");

    dispatch(
      searchCustomerGrievanceOrderId({
        businessId: businessId,
        orderId: searchQuery,
      })
    );
    setProductsList(searchByOrderId);
  };
  const handleIssueSearch = () => {
    clearSearchOrderId();
    if (!issuesearchQuery) return;
    const businessId = sessionStorage.getItem("selectedBusinessId");
    // setSelectedCategory("All");
    // setSelectedSubCat("All");

    dispatch(
      searchCustomerGrievanceIssueId({
        businessId: businessId,
        issueId: issuesearchQuery,
      })
    );
  };

  const clearSearchOrderId = () => {
    dispatch(clearOrderIdInfo());
    setProductsList(grievanceData?.issueData);
    setSearchQuery("");
    setIsClearSearch(false);
  };
  const clearSearchIssueId = () => {
    dispatch(clearIssueIdInfo());
    setProductsList(grievanceData?.issueData);
    setIssuesearchQuery("");
    setIsClearSearchIssue(false);
  };
   const [bapNameList, setBapNameList] = useState(null);

   const getBapDisplayNameInfo = async () => {
     const response = await getBuyerAppLookupAPI();

     if (response?.details?.length > 0) {
       setBapNameList(response?.details || []);
     }
   };
   // Get ONDC Bap Display Name by Subscriber ID
   const getOndcBapNameBySubscriberId = (id) => {
     const data = bapNameList?.find((item) => item?.subscriberId === id);
     return data ? data?.displayName : "";
   };
   useEffect(() => {
     getBapDisplayNameInfo();
   }, []);
  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    handleChange();
    getAllIsuueCount();
    // dispatch(
    //   getFulfillmentGrievanceDetails({
    //     status: tabValue === 0 && "OPEN",
    //     pageNo: 0,
    //     category: "FULFILLMENT",
    //     businessId: businessId,
    //   })
    // );
  }, []);

  useEffect(() => {
    if (status) {
      setTabValue(getTabValue(status));
    }
  }, [status]);

  useEffect(() => {
    if (!grievanceLoader) {
      setProductsList(grievanceData?.issueData);
    }
  }, [grievanceData]);

  useEffect(() => {
    if (searchByOrderId) {
      setIsClearSearch(true);
      setProductsList(searchByOrderId);
    } else {
      setProductsList(grievanceData?.issueData);
      // setIsClearSearch(false);
    }
  }, [searchByOrderId]);
  console.log("searchByOrderId", searchByOrderId);
  useEffect(() => {
    if (searchByIssueId) {
      setIsClearSearchIssue(true);
      setProductsList(searchByIssueId);
    } else {
      setProductsList(grievanceData?.issueData);
      // setIsClearSearch(false);
    }
  }, [searchByIssueId]);
  return (
    <Box sx={styles.container}>
      <DashboardLayout
        activeMenu="issueRaising"
        activeSubMenu={"igmIssuesItem"}
      >
        {openVideoModal && (
          <VideoModal
            open={openVideoModal}
            handleClose={handleCloseVideoModal}
            data={ORDER_VIDEO_DATA}
          />
        )}
        <Box sx={{ ...stickyHeader, pt: 1.8, pb: { xs: 1, md: "none" } }}>
          <Box mb={1}>
            <Typography
              variant="h2"
              fontSize={{ xs: 15, md: 17 }}
              fontWeight={600}
              color={blackShade01}
            >
              Customer Grievance {matches ? "- Item Issue" : ""}
            </Typography>

            <Typography
              variant="p"
              fontSize={"13px"}
              fontWeight={500}
              color={blackShade21}
              lineHeight={"20px"}
            >
              Grievance requests will be processed only for{" "}
              <Typography
                variant="span"
                fontSize={13}
                fontWeight={600}
                color={{ md: blueColor04, xs: mobilePrimary }}
                lineHeight={"20px"}
                display={"inline"}
              >
                ONDC
              </Typography>{" "}
              orders
            </Typography>
          </Box>

          {matches ? (
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                mb: { xs: "10px", md: 1 },
                mt: 1,
              }}
            >
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => handleChange(event, newValue)}
                textColor={blueColor04}
                TabIndicatorProps={{
                  style: {
                    height: "3px",
                    backgroundColor: blueColor04,
                  },
                }}
                sx={{
                  minHeight: "35px !important",
                  "& .MuiButtonBase-root": {
                    minWidth: "auto !important",
                  },
                }}
              >
                {Item_Issue_Status?.map((item, index) => {
                  return (
                    <Tab
                      key={"filter_" + index}
                      label={
                        item.label + ` -  ${getItemIssueCoutByStatus(index)}`
                      }
                      // label={item.label}
                      sx={{
                        textTransform: "capitalize",
                        fontSize: { xs: "11px", md: "13px" },
                        fontWeight: tabValue == index ? 600 : 500,
                        p: { xs: "10px 35px 10px 10px", md: 0 },
                        minHeight: "30px !important",
                        mr: { xs: "0px", md: "55px" },
                        color: {
                          md: item.id === tabValue ? blueColor04 : greyColor9,
                          xs: item.id === tabValue ? whiteColor : bgColor15,
                        },
                        backgroundColor: {
                          xs: item.id === tabValue ? bgColor15 : whiteColor,
                          md: "transparent",
                        },
                        "&::after": {
                          content: '""',
                          position: "absolute",
                          display: "inline-block",
                          width: "35px",
                          height: "35px",
                          top: "0px",
                          right: "-20px",
                          background: {
                            xs: item.id === tabValue ? bgColor15 : whiteColor,
                            md: "transparent",
                          },
                          borderRight: {
                            xs: `2px solid ${
                              item.id === tabValue ? whiteColor : bgColor15
                            }`,
                            md: "none",
                          },
                          borderBottom: {
                            xs: `2px solid ${
                              item.id === tabValue ? whiteColor : bgColor15
                            }`,
                            md: "none",
                          },
                          borderTopRightRadius: "5px",
                          transform: "scale(0.707) rotate(-45deg)",
                          // boxShadow: "2px -2px rgb(192 196 204)",
                          zIndex: 16,
                        },
                      }}
                    />
                  );
                })}
              </Tabs>
            </Box>
          ) : (
            <Box
              sx={{
                ...styles.mobileTabs,
                mb: 1,
                //  ...scrollBox
              }}
            >
              {Issue_Status?.map((item, index) => {
                return (
                  <MobileTab
                    title={item.label}
                    // productCount={getItemIssueCoutByStatus(index)}
                    isActive={issuetabValue == index}
                    // onClick={() => handleChange("", index)}
                    onClick={() => handleChangeIsuueTAB("", index)}
                  />
                );
              })}
            </Box>
          )}
          {!matches && (
            <PillTabs
              steps={Item_Issue_Status}
              active={tabValue}
              ontabClick={(data) => handleChange("", data?.id)}
              productCount={(data) => getItemIssueCoutByStatus(data?.id)}
            />
          )}

          {matches && (
            <>
              <Box
                display="flex"
                alignItems={{ md: "center", xs: "flex-start" }}
                flexDirection={{ xs: "column", md: "row" }}
              >
                {grievanceData && (
                  <Box
                    flex={1}
                    display="flex"
                    flexWrap="wrap"
                    gap={{ xs: "10px", md: "20px" }}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                  >
                    <Typography
                      variant="h5"
                      fontSize={{ xs: 14, md: 15 }}
                      fontWeight={600}
                      color={blackShade01}
                      textTransform="capitalize"
                      mt={grievanceData?.issueData?.length === 0 ? 1 : 0}
                    >
                      {getTabTitle(tabValue) === "OPEN"
                        ? "New"
                        : getTabTitle(tabValue)?.toLowerCase()}{" "}
                      - {grievanceData?.issueData?.length}
                    </Typography>
                  </Box>
                )}
                {grievanceData?.issueData?.length > 0 && (
                  <Box
                    flex={1}
                    display={"flex"}
                    justifyContent={{ md: "flex-end", xs: "center" }}
                    flexDirection={"column"}
                  >
                    <Box
                      flex={1}
                      display={"flex"}
                      gap={{ md: "15px", xs: "5px" }}
                      justifyContent={{ md: "flex-end", xs: "center" }}
                      flexDirection={{ xs: "column", md: "row" }}
                      alignItems={{ md: "flex-end", xs: "center" }}
                    >
                      <Box width={{ xs: "calc(100vw - 36px)", md: "40%" }}>
                        <SearchBoxInput
                          // label="Search"
                          placeholder="Search by Order ID"
                          value={searchQuery}
                          onChange={(text) => {
                            if (text.length <= 20) {
                              setSearchQuery(text.replace(/[^\.0-9]/g, ""));
                            }
                          }}
                          onSearchClick={handleSearch}
                          isDashboardInput
                        />
                      </Box>
                      <Box
                        display={{ md: "none", xs: "flex" }}
                        justifyContent={{ md: "flex-end", xs: "center" }}
                        flexWrap={"wrap"}
                        alignItems={{ md: "flex-end", xs: "center" }}
                      >
                        {isClearSearch && searchQuery && (
                          <Typography
                            component="h4"
                            fontSize={14}
                            color={blackShade05}
                            fontWeight={500}
                            lineHeight="20px"
                            m="15px 5px 0 0 !important"
                          >
                            Search results for Order Id
                          </Typography>
                        )}
                        {isClearSearch && searchQuery ? (
                          <Box mt={1.5}>
                            <Chip
                              label={searchQuery}
                              sx={{ fontSize: "12px", height: "24px" }}
                              onDelete={clearSearchOrderId}
                            />
                          </Box>
                        ) : null}
                      </Box>
                      <Box width={{ xs: "calc(100vw - 36px)", md: "40%" }}>
                        <SearchBoxInput
                          //   label="Search"
                          placeholder="Search by Issue ID"
                          value={issuesearchQuery}
                          onChange={(text) => {
                            if (text.length <= 20) {
                              setIssuesearchQuery(text);
                            }
                          }}
                          onSearchClick={handleIssueSearch}
                          isDashboardInput
                        />
                      </Box>
                    </Box>
                    <Box
                      display={{ md: "flex", xs: "none" }}
                      justifyContent={{ md: "flex-end", xs: "center" }}
                      flexWrap={"wrap"}
                    >
                      {isClearSearch && searchQuery && (
                        <Typography
                          component="h4"
                          fontSize={14}
                          color={blackShade05}
                          fontWeight={500}
                          lineHeight="20px"
                          m="15px 5px 0 0 !important"
                        >
                          Search results for Order Id
                        </Typography>
                      )}
                      {isClearSearch && searchQuery ? (
                        <Box mt={1.5}>
                          <Chip
                            label={searchQuery}
                            sx={{ fontSize: "12px", height: "24px" }}
                            onDelete={clearSearchOrderId}
                          />
                        </Box>
                      ) : null}
                    </Box>
                    <Box
                      display={{ md: "flex", xs: "none" }}
                      justifyContent={{ md: "flex-end", xs: "center" }}
                    >
                      {isClearSearchIssue && issuesearchQuery && (
                        <Typography
                          component="h4"
                          fontSize={14}
                          color={blackShade05}
                          fontWeight={500}
                          lineHeight="20px"
                          m="15px 5px 0 0 !important"
                        >
                          Search results for Issue Id
                        </Typography>
                      )}
                      {isClearSearchIssue && issuesearchQuery ? (
                        <Box mt={1.5}>
                          <Chip
                            label={issuesearchQuery}
                            sx={{ fontSize: "12px", height: "24px" }}
                            onDelete={clearSearchIssueId}
                          />
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                )}
              </Box>
              {grievanceData ? (
                <>
                  {tabValue === 0 && (
                    <ItemIssueTab
                      grievanceData={productsList}
                      status={"OPEN"}
                      category={"FULFILLMENT"}
                      refreshNewProcessing={refreshNewProcessing}
                      getOndcBapNameBySubscriberId={
                        getOndcBapNameBySubscriberId
                      }
                    />
                  )}
                  {tabValue === 1 && (
                    <ItemIssueTab
                      grievanceData={productsList}
                      status={"PROCESSING"}
                      category={"FULFILLMENT"}
                      refreshProcessingResolved={refreshProcessingResolved}
                      getOndcBapNameBySubscriberId={
                        getOndcBapNameBySubscriberId
                      }
                    />
                  )}
                  {tabValue === 2 && (
                    <ItemIssueTab
                      grievanceData={productsList}
                      status={"RESOLVED"}
                      category={"FULFILLMENT"}
                      getOndcBapNameBySubscriberId={
                        getOndcBapNameBySubscriberId
                      }
                    />
                  )}
                  {tabValue === 3 && (
                    <ItemIssueTab
                      grievanceData={productsList}
                      status={"CLOSED"}
                      category={"FULFILLMENT"}
                      getOndcBapNameBySubscriberId={
                        getOndcBapNameBySubscriberId
                      }
                    />
                  )}
                  {/* {tabValue === 1 && <FulfillmentIssueTab />} */}
                </>
              ) : (
                <ThreeDotLoader />
              )}
            </>
          )}
        </Box>
        {!matches && (
          <>
            <Box
              display="flex"
              alignItems={{ md: "center", xs: "flex-start" }}
              mb={2}
              flexDirection={{ xs: "column", md: "row" }}
              // mt={1}
            >
              {grievanceData && (
                <Box
                  flex={1}
                  display="flex"
                  flexWrap="wrap"
                  gap={{ xs: "10px", md: "20px" }}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                >
                  <Typography
                    variant="h5"
                    fontSize={{ xs: 14, md: 15 }}
                    fontWeight={600}
                    color={blackShade01}
                    textTransform="capitalize"
                    mt={grievanceData?.issueData?.length === 0 ? 1 : 1}
                  >
                    {getTabTitle(tabValue) === "OPEN"
                      ? "New"
                      : getTabTitle(tabValue)?.toLowerCase()}{" "}
                    - {grievanceData?.issueData?.length}
                  </Typography>
                </Box>
              )}
              {grievanceData?.issueData?.length > 0 && (
                <Box
                  flex={1}
                  display={"flex"}
                  justifyContent={{ md: "flex-end", xs: "center" }}
                  flexDirection={"column"}
                >
                  <Box
                    flex={1}
                    display={"flex"}
                    gap={{ md: "15px", xs: "5px" }}
                    justifyContent={{ md: "flex-end", xs: "center" }}
                    flexDirection={{ xs: "column", md: "row" }}
                    alignItems={{ md: "flex-end", xs: "center" }}
                  >
                    <Box width={{ xs: "calc(100vw - 36px)", md: "40%" }}>
                      <SearchBoxInput
                        // label="Search"
                        placeholder="Search by Order ID"
                        value={searchQuery}
                        onChange={(text) => {
                          if (text.length <= 20) {
                            setSearchQuery(text.replace(/[^\.0-9]/g, ""));
                          }
                        }}
                        onSearchClick={handleSearch}
                        isDashboardInput
                      />
                    </Box>
                    <Box
                      display={{ md: "none", xs: "flex" }}
                      justifyContent={{ md: "flex-end", xs: "center" }}
                      flexWrap={"wrap"}
                      alignItems={{ md: "flex-end", xs: "center" }}
                    >
                      {isClearSearch && searchQuery && (
                        <Typography
                          component="h4"
                          fontSize={14}
                          color={blackShade05}
                          fontWeight={500}
                          lineHeight="20px"
                          m="15px 5px 0 0 !important"
                        >
                          Search results for Order Id
                        </Typography>
                      )}
                      {isClearSearch && searchQuery ? (
                        <Box mt={1.5}>
                          <Chip
                            label={searchQuery}
                            sx={{ fontSize: "12px", height: "24px" }}
                            onDelete={clearSearchOrderId}
                          />
                        </Box>
                      ) : null}
                    </Box>
                    <Box width={{ xs: "calc(100vw - 36px)", md: "40%" }}>
                      <SearchBoxInput
                        //   label="Search"
                        placeholder="Search by Issue ID"
                        value={issuesearchQuery}
                        onChange={(text) => {
                          if (text.length <= 20) {
                            setIssuesearchQuery(text);
                          }
                        }}
                        onSearchClick={handleIssueSearch}
                        isDashboardInput
                      />
                    </Box>
                  </Box>
                  <Box
                    display={{ md: "flex", xs: "none" }}
                    justifyContent={{ md: "flex-end", xs: "center" }}
                    flexWrap={"wrap"}
                  >
                    {isClearSearch && searchQuery && (
                      <Typography
                        component="h4"
                        fontSize={14}
                        color={blackShade05}
                        fontWeight={500}
                        lineHeight="20px"
                        m="15px 5px 0 0 !important"
                      >
                        Search results for Order Id
                      </Typography>
                    )}
                    {isClearSearch && searchQuery ? (
                      <Box mt={1.5}>
                        <Chip
                          label={searchQuery}
                          sx={{ fontSize: "12px", height: "24px" }}
                          onDelete={clearSearchOrderId}
                        />
                      </Box>
                    ) : null}
                  </Box>
                  <Box
                    display={{ md: "flex", xs: "none" }}
                    justifyContent={{ md: "flex-end", xs: "center" }}
                  >
                    {isClearSearchIssue && issuesearchQuery && (
                      <Typography
                        component="h4"
                        fontSize={14}
                        color={blackShade05}
                        fontWeight={500}
                        lineHeight="20px"
                        m="15px 5px 0 0 !important"
                      >
                        Search results for Issue Id
                      </Typography>
                    )}
                    {isClearSearchIssue && issuesearchQuery ? (
                      <Box mt={1.5}>
                        <Chip
                          label={issuesearchQuery}
                          sx={{ fontSize: "12px", height: "24px" }}
                          onDelete={clearSearchIssueId}
                        />
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              )}
            </Box>
            {grievanceData ? (
              <>
                {tabValue === 0 && (
                  <ItemIssueTab
                    grievanceData={productsList}
                    status={"OPEN"}
                    category={"FULFILLMENT"}
                    refreshNewProcessing={refreshNewProcessing}
                    getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
                  />
                )}
                {tabValue === 1 && (
                  <ItemIssueTab
                    grievanceData={productsList}
                    status={"PROCESSING"}
                    category={"FULFILLMENT"}
                    refreshProcessingResolved={refreshProcessingResolved}
                    getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
                  />
                )}
                {tabValue === 2 && (
                  <ItemIssueTab
                    grievanceData={productsList}
                    status={"RESOLVED"}
                    category={"FULFILLMENT"}
                    getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
                  />
                )}
                {tabValue === 3 && (
                  <ItemIssueTab
                    grievanceData={productsList}
                    status={"CLOSED"}
                    category={"FULFILLMENT"}
                    getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
                  />
                )}
              </>
            ) : (
              <ThreeDotLoader />
            )}
          </>
        )}
      </DashboardLayout>
    </Box>
  );
}

export default CustomerGrievance;
const MobileTab = ({ title, productCount, isActive, onClick }) => {
  return (
    <Box sx={styles.mobileTabItem(isActive)} onClick={onClick}>
      <Typography
        fontSize={13.5}
        color={isActive ? mobilePrimary : greyColor40}
        fontWeight={500}
        sx={{ whiteSpace: "nowrap" }}
      >
        {productCount}
      </Typography>

      <Typography
        fontSize={13}
        color={isActive ? mobilePrimary : greyColor40}
        fontWeight={isActive ? 600 : 500}
        sx={{ whiteSpace: "nowrap" }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const styles = {
  container: {
    minHeight: { xs: "calc(100vh - 91px)", md: "100vh" },
    backgroundColor: BGColor02,
  },
  mobileTabs: {
    width: { xs: "90vw", md: "calc(100% - 100px)" },
    display: "flex",
    alignItems: "center",
    // mb: 1,
    gap: 4,
    borderBottom: logoImgborder,
  },
  mobileTabItem: (isActive) => ({
    textAlign: "center",
    borderBottom: isActive ? `3px solid ${mobilePrimary}` : "none",
    mr: 1,
  }),
};
