import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blackShade19,
  blueColor02,
  blueColor04,
  greyColor6,
  logoImgborder1,
  whiteColor,
} from "../../configs/styles/muiThemes";

function AffiliatesSales({
  totalSales,
  commission,
  commissionPaid,
  commissionPending,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box sx={styles.infoBox}>
      <Box p="10px 15px 0 15px">
        <Typography
          variant="h3"
          color={blackShade05}
          fontSize={{xs:14, md:"15px"}}
          fontWeight="600"
          mb={{ xs: "0px", md: "4px" }}
        >
          Affiliate Sales
        </Typography>

        <Typography fontSize={12} fontWeight={500} color={greyColor6}>
          (Commission 10%, Buyer Discount 15%)
        </Typography>
      </Box>

      <Box m="6px 0 10px 0">
        <Box
          display="flex"
          justifyContent="space-between"
          borderBottom="1px dashed #dadae1"
        >
          <StatsInfoCard
            label="TOTAL SALES"
            value={
              Number(totalSales)
                ?.toFixed(2)
                .replaceAll(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",") || 0
            }
            textColor={matches ? blackShade05 : blackShade19}
          />
          <StatsInfoCard
            label="COMMISSION"
            value={
              Number(commission)
                ?.toFixed(2)
                .replaceAll(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",") || 0
            }
            textColor={matches ? blackShade05 : blackShade19}
            hideRightBorder
          />
        </Box>

        <Box display="flex" justifyContent="space-between" mt="7px">
          <StatsInfoCard
            label="COMMISSION PAID"
            value={
              Number(commissionPaid)
                ?.toFixed(2)
                .replaceAll(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",") || 0
            }
            textColor={matches ? blackShade05 : blackShade19}
          />
          <StatsInfoCard
            label="COMMISSION PENDING"
            value={
              Number(commissionPending)
                ?.toFixed(2)
                .replaceAll(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",") || 0
            }
            textColor={matches ? blackShade05 : blackShade19}
            hideRightBorder
          />
        </Box>
      </Box>
    </Box>
  );
}

export default AffiliatesSales;

const StatsInfoCard = ({ label, value, textColor, hideRightBorder }) => {
  return (
    <Box
      pl="15px"
      borderRight={hideRightBorder ? "none" : "1px dashed #dadae1"}
      mb="7px"
      flex={1}
    >
      <Typography fontSize={{xs:14, md:16}} fontWeight={600} color={textColor}>
        ₹ {value}
      </Typography>

      <Typography
        fontSize={12}
        fontWeight={500}
        color={greyColor6}
        textTransform="uppercase"
      >
        {label}
      </Typography>
    </Box>
  );
};

const styles = {
  infoBox: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: whiteColor,
    baxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    // padding: "15px",
    border: logoImgborder1,
    borderRadius: "10px",
    // marginTop: "22px",
  },

  statCard: {},
};
