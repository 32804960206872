import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import {
  blackShade05,
  blackShade19,
  border25,
  greyColor34,
  greyColor6,
  orangeShade11,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import FormInputBox from "../../../components/Input/FormInputBox";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import TextAreaComponent from "../../../components/Input/TextAreaComponent";
import { PRODUCT_CODE_UNIT_LIST } from "../../../utils/data";
import { dynamicSort, getProductCodeMaxLength } from "../../../utils/helper";
import { useSelector } from "react-redux";

function AddProductDetails({
  data,
  taxData,
  onProductValueChange,
  onTaxValueChange,
  handleProductCodeChange,
}) {
  const { countryList, countryListLoading, gstRates, allDeliveryMethod } =
    useSelector((state) => state.catalog);
  return (
    <Box sx={styles.card}>
      <Typography sx={styles.title}>Product Detail</Typography>
      <Grid container rowSpacing={{md:2,xs:1}} columnSpacing={3}>
        <Grid item xs={12} md={6}>
          <FormInputBox
            label="Product Name"
            placeholder="Max 150 characters"
            required
            value={data?.productName}
            onChange={({ target }) => {
              onProductValueChange("productName", target.value);
            }}
            error={data?.errors?.productName}
            maxLength={150}
          />

          <Typography
            variant="body2"
            fontSize={{ xs: 12, md: 11.5 }}
            fontWeight={400}
            color={orangeShade11}
            mt={{ xs: 0, md: -0.8 }}
            lineHeight="20px"
          >
            Please include product weight (if applicable) as part of the name.
            Ex: Cold pressed coconut Oil - 500g.
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <FormInputBox
            label="Brand"
            placeholder="Max 50 characters"
            value={data?.brand}
            onChange={({ target }) => {
              if (target.value?.length <= 50) {
                onProductValueChange("brand", target.value);
              }
            }}
          />
        </Grid>

        <Grid item xs={6} md={2}>
          <SelectWithLabel
            label="Country of Origin"
            required
            value={data?.country?.name || ""}
            onValueChange={(value) => {
              onProductValueChange("country", value);
              // onValueChange("country", "India");
            }}
            returnFullItem
            menuList={countryList?.slice()?.sort(dynamicSort("name")) || []}
            // menuList={[{ name: "India", code: "IN" }]}
            valueSelector="name"
            errorMsg={data?.errors?.country}
          />
        </Grid>

        <Grid item xs={6} md={2}>
          <FormInputBox
            label="SKU ID"
            // required
            placeholder="Max 30 characters"
            value={data?.skuId}
            onChange={({ target }) => {
              if (target.value?.length <= 30) {
                const skuValue = target.value?.replace(/[^a-zA-Z0-9-_]/g, "");
                onProductValueChange("skuId", skuValue);
              }
            }}
            // error={data?.errors?.skuId && "Enter skuId"}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextAreaComponent
            label="Product Description"
            minRows={2}
            required
            placeholder="Max 5000 characters"
            value={data?.description}
            onChange={({ target }) => {
              if (target.value?.length <= 5000) {
                onProductValueChange("description", target.value);
              }
            }}
            error={data?.errors?.description}
          />
        </Grid>
      </Grid>
      <Typography sx={styles.title} mt={1}>
        Tax and GST Information
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={3}>
        <Grid item xs={5} md={2.5}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 13, md: 14 },
              fontWeight: 400,
              color: greyColor6,
            }}
          >
            Taxes Included
          </Typography>

          <Box>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: 13, md: 14.5 },
                fontWeight: { xs: 500, md: 400 },
                color: blackShade05,
              }}
            >
              Yes
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={7} md={3}>
          <SelectWithLabel
            label="GST Percentage (in %)"
            required
            value={taxData?.gstPercentage || ""}
            onValueChange={(value) => {
              onTaxValueChange("gstPercentage", value);
            }}
            menuList={gstRates}
            valueSelector="value"
            errorMsg={taxData?.errors?.gstPercentage}
          />
        </Grid>

        {/* <Grid item xs={5} md={3}>
          <SelectWithLabel
            label="Product Code"
            value={taxData?.productCode?.key}
            onValueChange={(value) => {
              handleProductCodeChange("key", value?.label, "productCode");
            }}
            returnFullItem
            menuList={PRODUCT_CODE_UNIT_LIST}
            required
            errorMsg={taxData?.errors?.productCode1}
          />
        </Grid>

        <Grid item xs={7} md={3}>
          <FormInputBox
            label="Product Code Value"
            placeholder=""
            value={taxData?.productCode?.value}
            onChange={({ target }) => {
              const value = target?.value?.replace(/[^0-9]/g, "");
              handleProductCodeChange("value", value, "productCode");
            }}
            maxLength={getProductCodeMaxLength(taxData?.productCode?.key)}
            error={taxData?.errors?.productCode}
          />
        </Grid> */}
      </Grid>
    </Box>
  );
}

export default AddProductDetails;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    border: border25,
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: "15px 15px 15px",
    mb: 2,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    mb: "10px",
    color: blackShade19,
  },
};
