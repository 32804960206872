import { Box, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import moment from "moment";
import Compressor from "compressorjs";
import {
  base64ToBlob,
  blobToBase64,
  imageUrlToBase64,
} from "../../utils/imageCropUtils/imgUrlToBase64";
import {
  blackColor,
  greyColor6,
  greyShade05,
} from "../../configs/styles/muiThemes";

function FilePickerWithoutCrop({
  label,
  required,
  height,
  placeholder,
  onDelete,
  image,
  setImage,
}) {
  //   const [image, setImage] = useState("");
  const [fileDetails, setFileDetails] = useState(null);

  const inputRef = useRef(null);
  const triggerFileSelectPopup = () => inputRef.current.click();

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (e) {
        const dataURL = reader.result;
        console.log("reader", reader, event.target.files[0]);
        setFileDetails(event.target.files[0]);

        convertToJPEG(dataURL);
      };
    }
  };

  const convertToJPEG = (pngDataURL) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      // Convert the canvas to a data URL with JPEG format
      const jpegDataURL = canvas.toDataURL("image/jpeg");

      console.log("jpegDataURL", jpegDataURL);
      compressImage(jpegDataURL);

      // Update the state with the JPEG data URL
      // setJPEGDataURL(jpegDataURL);
    };

    img.src = pngDataURL;
  };

  const compressImage = async (dataURL) => {
    console.log("Compression_Before:", dataURL, fileDetails);

    let compressedBase64Str = null;

    if (dataURL) {
      const blob = base64ToBlob(dataURL);

      const compressedBlob = await new Promise((resolve) => {
        new Compressor(blob, {
          quality: 0.6,
          success: (result) => resolve(result),
          error: (err) => {
            console.error(err.message);
            resolve(blob);
          },
        });
      });

      console.log("hit55", dataURL, compressedBlob);

      const compressedBase64 = await blobToBase64(compressedBlob);

      console.log("Compression_After:", compressedBase64);
      compressedBase64Str = compressedBase64 || dataURL;
    } else {
      console.error("Invalid base64 input");
      compressedBase64Str = dataURL;
    }
    setImage(compressedBase64Str, fileDetails);
  };

  return (
    <Box sx={{ position: "relative" }}>
      {label ? (
        <>
          <Typography
            variant="h3"
            fontSize={13}
            color={greyColor6}
            fontWeight={400}
            mb={"18px !important"}
            sx={{ display: "inline" }}
          >
            {label}{" "}
          </Typography>
          {required ? (
            <Typography
              variant="body1"
              sx={{ display: "inline", color: "#f00" }}
            >
              *
            </Typography>
          ) : null}
        </>
      ) : null}

      {image?.charAt(image.length - 1) !== "." && image ? (
        <Box sx={styles.card(height)}>
          <Box
            sx={{
              color: "red",
              position: "absolute",
              right: "-10px",
              top: "-10px",
            }}
          >
            <RemoveCircleRoundedIcon
              sx={{ fontSize: "23px", cursor: "pointer" }}
              onClick={() => {
                console.log(image);
                setImage(null);
                onDelete("");
              }}
            />
          </Box>

          <Box
            component="img"
            src={
              image?.slice(0, 4) == "data"
                ? image
                : `${image}?tag${moment()?.valueOf()}`
            }
            width="auto"
            height="100%"
            sx={{ objectFit: "contain", borderRadius: "15px" }}
            onError={() => {
              onDelete("");
            }}
          />
        </Box>
      ) : (
        <>
          <input
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={onSelectFile}
            style={{ display: "none" }}
          />
          <Box onClick={triggerFileSelectPopup}>
            <Box sx={styles.card(height)}>
              <Box
                component="img"
                src="https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/upload.svg"
                width="40px"
                height="40px"
                sx={{ objectFit: "contain", mb: "8px", mt: 1 }}
              />

              <Typography
                variant="h4"
                fontSize={13}
                color={blackColor}
                fontWeight={500}
              >
                {placeholder || "Upload Picture"}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default FilePickerWithoutCrop;

const styles = {
  card: (height) => ({
    width: { xs: height ? "auto" : "calc(100vw - 60px)", md: "auto" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1.5px dashed #afbbc1",
    // pt: 3,
    borderRadius: "15px",
    // width: "160px",
    height: { md: height ? height : "160px", xs: height ? height : "200px" },
    background: "rgb(150 160 163 / 7%)",
    // overflow: "hidden",
    position: "relative",
    mt: 1,
  }),
};
