import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  blackShade05,
  blackShade159,
  deleteBtnColor,
  greyColor28,
  greyColor8,
  whiteColor,
} from "../../../../configs/styles/muiThemes";
import ApproveHeaderCard from "./ApproveHeaderCard";
import ManifestCard from "./ManifestCard";
import {
  addRewardPointApi,
  approveCart,
  getCartInfoApi,
  getRewardPointsApi,
  updateRewardPointApi,
} from "../../../../service/api_calls/orders/OrdersApi";
import { useEffect } from "react";
import ThreeDotLoader from "../../../../components/ThreeDotLoader";
import moment from "moment";
import ModalCard from "../../../../components/ModalCard";
import ConfirmationAlert from "../Components/ConfirmationAlert";
import { addThousandsSeparator, getOrderType } from "../../../../utils/helper";

function ApproveBtoBPopup({
  orderData,
  handleApproveSuccess,
  handleApproveOrder,
  commerceInfo,
  handleClose,
}) {
  const [cartInfo, setCartInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const [confirmationPopup, setConfirmationPopup] = useState({
    show: false,
    count: "",
  });

  const [smallBoxData, setSmallBoxData] = useState({
    isChecked: false,
    length: 30,
    width: 33,
    height: 26,
    noOfBoxes: 0,
    errors: {},
  });
  const [mediumBoxData, setMediumBoxData] = useState({
    isChecked: false,
    length: 46,
    width: 46,
    height: 40,
    noOfBoxes: 0,
    errors: {},
  });
  const [customBoxData, setCustomBoxData] = useState({
    isChecked: false,
    length: 34,
    width: 41,
    height: 17,
    noOfBoxes: 0,
    errors: {},
  });
  const [moreCustomBoxData, setMoreCustomBoxData] = useState([]);

  const [error, setError] = useState(false);
  const [addError, setAddError] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);

  const orderType = getOrderType(orderData);

  const handleSetValue = (name, value, state, setState) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleMoreBoxChange = (index, name, value) => {
    const newMoreCustomBoxData = [...moreCustomBoxData];
    newMoreCustomBoxData[index] = {
      ...newMoreCustomBoxData[index],
      [name]: value,
    };
    setMoreCustomBoxData(newMoreCustomBoxData);
  };

  const getCartData = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    setLoading(true);

    if (orderData?.cartId) {
      const response = await getCartInfoApi({
        cartId: orderData?.cartId,
        businessId,
      });

      if (response?.cart) {
        setCartInfo(response?.cart || null);
        setLoading(false);
        return;
      }
    }

    setLoading(false);
  };

  const handleAddMore = () => {
    if (!customBoxData?.isChecked) {
      setAddError(true);
      return;
    }
    if (
      !moreCustomBoxData.every(
        (item) =>
          item.isChecked &&
          item.length &&
          item.width &&
          item.height &&
          item.noOfBoxes
      )
    ) {
      setAddError(true);
      return;
    }

    setMoreCustomBoxData([
      ...moreCustomBoxData,
      {
        isChecked: false,
        length: "",
        width: "",
        height: "",
        noOfBoxes: "",
        errors: {},
      },
    ]);
    setAddError(false);
  };

  const handleShipmentInfo = async () => {
    setError(false);

    handleSetValue("errors", null, smallBoxData, setSmallBoxData);
    handleSetValue("errors", null, mediumBoxData, setMediumBoxData);
    handleSetValue("errors", null, customBoxData, setCustomBoxData);

    const updatedArray = moreCustomBoxData.map((obj) => ({
      ...obj,
      errors: null,
    }));

    setMoreCustomBoxData(updatedArray);

    let smallBoxErrors = null;
    let mediumBoxErrors = null;
    let customBoxErrors = null;
    let moreError = false;

    if (
      !smallBoxData?.isChecked &&
      !mediumBoxData?.isChecked &&
      !customBoxData?.isChecked &&
      moreCustomBoxData.every((item) => !item.isChecked)
    ) {
      setError(true);
      return;
    }
    let boxCount = 0;
    if (smallBoxData?.isChecked) {
      boxCount += Number(smallBoxData?.noOfBoxes);
      if (smallBoxData?.length === "") {
        smallBoxErrors = {
          ...smallBoxErrors,
          length: "Please enter the length",
        };
      }
      if (smallBoxData?.width === "") {
        smallBoxErrors = { ...smallBoxErrors, width: "Please enter the width" };
      }
      if (smallBoxData?.height === "") {
        smallBoxErrors = {
          ...smallBoxErrors,
          height: "Please enter the height",
        };
      }
      if (
        !(
          Number(smallBoxData?.noOfBoxes) >= 1 &&
          Number(smallBoxData?.noOfBoxes) <= 100
        )
      ) {
        smallBoxErrors = {
          ...smallBoxErrors,
          noOfBoxes: "Number of boxes should be between 1 to 100",
        };
      }
      if (smallBoxData?.noOfBoxes === "") {
        smallBoxErrors = {
          ...smallBoxErrors,
          noOfBoxes: "Please enter the number of boxes",
        };
      }

      if (smallBoxErrors) {
        handleSetValue("errors", smallBoxErrors, smallBoxData, setSmallBoxData);
      }
    }

    if (mediumBoxData?.isChecked) {
      boxCount += Number(mediumBoxData?.noOfBoxes);
      if (mediumBoxData?.length === "") {
        mediumBoxErrors = {
          ...mediumBoxErrors,
          length: "Please enter the length",
        };
      }
      if (mediumBoxData?.width === "") {
        mediumBoxErrors = {
          ...mediumBoxErrors,
          width: "Please enter the width",
        };
      }
      if (mediumBoxData?.height === "") {
        mediumBoxErrors = {
          ...mediumBoxErrors,
          height: "Please enter the height",
        };
      }
      if (
        !(
          Number(mediumBoxData?.noOfBoxes) >= 1 &&
          Number(mediumBoxData?.noOfBoxes) <= 100
        )
      ) {
        mediumBoxErrors = {
          ...mediumBoxErrors,
          noOfBoxes: "Number of boxes should be between 1 to 100",
        };
      }
      if (mediumBoxData?.noOfBoxes === "") {
        mediumBoxErrors = {
          ...mediumBoxErrors,
          noOfBoxes: "Please enter the number of boxes",
        };
      }

      if (mediumBoxErrors) {
        handleSetValue(
          "errors",
          mediumBoxErrors,
          mediumBoxData,
          setMediumBoxData
        );
      }
    }

    if (customBoxData?.isChecked) {
      boxCount += Number(customBoxData?.noOfBoxes);
      if (customBoxData?.length === "") {
        customBoxErrors = {
          ...customBoxErrors,
          length: "Please enter the length",
        };
      }
      if (customBoxData?.width === "") {
        customBoxErrors = {
          ...customBoxErrors,
          width: "Please enter the width",
        };
      }
      if (customBoxData?.height === "") {
        customBoxErrors = {
          ...customBoxErrors,
          height: "Please enter the height",
        };
      }
      if (
        !(
          Number(customBoxData?.noOfBoxes) >= 1 &&
          Number(customBoxData?.noOfBoxes) <= 100
        )
      ) {
        customBoxErrors = {
          ...customBoxErrors,
          noOfBoxes: "Number of boxes should be between 1 to 100",
        };
      }
      if (customBoxData?.noOfBoxes === "") {
        customBoxErrors = {
          ...customBoxErrors,
          noOfBoxes: "Please enter the number of boxes",
        };
      }

      if (customBoxErrors) {
        handleSetValue(
          "errors",
          customBoxErrors,
          customBoxData,
          setCustomBoxData
        );
      }
    }

    moreCustomBoxData.forEach((boxData, index) => {
      let boxErrors = null;
      if (boxData.isChecked) {
        boxCount += Number(boxData?.noOfBoxes);
        if (boxData?.length === "") {
          boxErrors = {
            ...boxErrors,
            length: "Please enter the length",
          };
        }
        if (boxData?.width === "") {
          boxErrors = {
            ...boxErrors,
            width: "Please enter the width",
          };
        }
        if (boxData?.height === "") {
          boxErrors = {
            ...boxErrors,
            height: "Please enter the height",
          };
        }
        if (
          !(
            Number(boxData?.noOfBoxes) >= 1 && Number(boxData?.noOfBoxes) <= 100
          )
        ) {
          boxErrors = {
            ...boxErrors,
            noOfBoxes: "Number of boxes should be between 1 to 100",
          };
        }
        if (boxData?.noOfBoxes === "") {
          boxErrors = {
            ...boxErrors,
            noOfBoxes: "Please enter the number of boxes",
          };
        }
        if (boxErrors) {
          moreError = true;
          console.log("errors", boxErrors, index);
          handleMoreBoxChange(index, "errors", boxErrors);
        } else {
          handleMoreBoxChange(index, "errors", null);
        }
      } else {
        handleMoreBoxChange(index, "errors", null);
      }
    });

    if (smallBoxErrors || mediumBoxErrors || customBoxErrors || moreError) {
      return;
    }
    setConfirmationPopup({ show: true, count: boxCount });
  };

  const onOrderApprove = async () => {
    setApproveLoading(true);
    const businessId = sessionStorage.getItem("selectedBusinessId");

    const checkedCustomBoxes = moreCustomBoxData?.filter(
      (item) => item.isChecked
    );

    let dimensions = [];

    if (smallBoxData?.isChecked) {
      dimensions.push({
        length: smallBoxData?.length,
        width: smallBoxData?.width,
        height: smallBoxData?.height,
        count: smallBoxData?.noOfBoxes,
        boxType: "small-cardboard-1",
        unit: "cm",
      });
    }
    if (mediumBoxData?.isChecked) {
      dimensions.push({
        length: mediumBoxData?.length,
        width: mediumBoxData?.width,
        height: mediumBoxData?.height,
        count: mediumBoxData?.noOfBoxes,
        boxType: "medium-cardboard-1",
        unit: "cm",
      });
    }
    if (customBoxData?.isChecked) {
      dimensions.push({
        length: customBoxData?.length,
        width: customBoxData?.width,
        height: customBoxData?.height,
        count: customBoxData?.noOfBoxes,
        boxType: "standard-cardboard-2",
        unit: "cm",
      });
    }

    if (checkedCustomBoxes?.length > 0) {
      checkedCustomBoxes?.forEach((box, index) => {
        dimensions.push({
          length: box?.length,
          width: box?.width,
          height: box?.height,
          count: box?.noOfBoxes,
          boxType: `standard-cardboard-${3 + index}`,
          unit: "cm",
        });
      });
    }

    const approveData = {
      notesBusiness: "Thanks for your order",
      deliveryDateRange: cartInfo?.deliveryDateRange,
      shippingCost: cartInfo?.shippingCost,
      totalQuantity: cartInfo?.totalQuantity,
      orderTotalProductCost: cartInfo?.orderTotalProductCost,
      orderTotalCost: cartInfo?.orderTotalCost,
      productList: cartInfo?.productList,
      claimedPoint: cartInfo?.claimedPoint,
      btobInfo: { dimensions },
    };

    const data = { cartId: orderData?.cartId, businessId, data: approveData };

    const approveResponse = await approveCart(data);

    if (approveResponse) {
      console.log("HIT 1");
      if (cartInfo?.claimType === "cashback" || cartInfo?.claimType === "") {
        console.log("HIT 2");
        const rewardResponse = await getRewardPointsApi({
          businessId,
          profileId: cartInfo?.profileId,
        });

        if (rewardResponse?.details?.[0]?.id) {
          const awardedPoints =
            ((Number(cartInfo?.orderTotalProductCost) -
              Number(cartInfo?.claimedPoint)) *
              Number(commerceInfo?.info?.rewardPointPercent)) /
            100;
          const updateData = {
            businessId: businessId,
            rewardPointId: rewardResponse?.details?.[0]?.id,
            data: {
              cartId: orderData?.cartId,
              businessId: businessId,
              awardedReason: "business wallet",
              referralSenderPhone: "",
              referralSenderProfileId: "",
              referralSenderName: "",
              status: "active",
              profileId: cartInfo?.profileId,
              phoneNo: cartInfo?.customerAddress?.phoneNo,
              expiryDays: commerceInfo?.info?.rewardExpirationDays,
              awardedPoints: awardedPoints,
              awardedDate: moment().valueOf(),
            },
          };

          const updateResponse = updateRewardPointApi(updateData);

          if (updateResponse) {
            setApproveLoading(false);
            setConfirmationPopup({
              show: false,
              count: "",
            });

            handleApproveOrder("OrderApproved");
          }
        } else {
          const awardedPoints =
            ((Number(cartInfo?.orderTotalProductCost) -
              Number(cartInfo?.claimedPoint)) *
              Number(commerceInfo?.info?.rewardPointPercent)) /
            100;

          const addData = {
            businessId: businessId,
            data: {
              cartId: orderData?.cartId,
              businessId: businessId,
              awardedReason: "purchase",
              referralSenderPhone: " ",
              referralSenderProfileId: " ",
              referralSenderName: " ",
              status: "active",
              profileId: cartInfo?.profileId,
              phoneNo: cartInfo?.customerAddress?.phoneNo,
              expiryDays: commerceInfo?.info?.rewardExpirationDays,
              awardedPoints: awardedPoints,
              awardedDate: moment().valueOf(),
            },
          };

          const addResponse = addRewardPointApi(addData);

          if (addResponse) {
            setApproveLoading(false);
            setConfirmationPopup({
              show: false,
              count: "",
            });

            handleApproveOrder("OrderApproved");
          }

          console.log("ADD_PAYLOAD_DATA", addData);
        }
      } else {
        console.log("HIT 3");
        setApproveLoading(false);
        setConfirmationPopup({
          show: false,
          count: "",
        });

        handleApproveOrder("OrderApproved");
      }
    }

    console.log(
      "DATA",
      smallBoxData,
      mediumBoxData,
      customBoxData,
      checkedCustomBoxes,
      approveData
    );
  };

  useEffect(() => {
    getCartData();
    return () => {};
  }, []);

  return (
    <Box overflow="hidden">
      {loading ? (
        <Box
          sx={{
            minHeight: "100px",
            backgroundColor: whiteColor,
            borderRadius: "12px",
            pt: "20px",
          }}
        >
          <ThreeDotLoader />
        </Box>
      ) : (
        <Box sx={styles.card}>
          <ModalCard
            open={confirmationPopup?.show}
            handleClose={() => {
              setConfirmationPopup({
                show: false,
                count: "",
              });
            }}
            width="33%"
          >
            <ConfirmationAlert
              title="Order Approval"
              msg="Are you sure you want to approve this order ?"
              total={`₹
                ${addThousandsSeparator(
                  Number(cartInfo?.orderTotalProductCost) +
                    Number(cartInfo?.convenienceFee) +
                    Number(cartInfo?.packingInfo?.cost) +
                    Number(cartInfo?.shippingCost || 0) -
                    Number(cartInfo?.claimedPoint || 0)
                )}`}
              handleCancel={() => {
                setConfirmationPopup({
                  show: false,
                  count: "",
                });
              }}
              handleOk={onOrderApprove}
              okLoading={approveLoading}
              boxCount={confirmationPopup?.count}
            />
          </ModalCard>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography fontSize={15} fontWeight={600} color={blackShade05}>
              Order Approved
            </Typography>

            <Button
              sx={styles.cancelIconBtn}
              variant="contained"
              disableElevation
              onClick={handleClose}
            >
              CLOSE
            </Button>
          </Box>
          <ApproveHeaderCard
            orderId={cartInfo?.orderId}
            ondcOrderId={orderData?.ondcOrderId || ''}
            orderDate={moment(cartInfo?.orderCreatedOn).format("MMM Do, YYYY")}
            status="ordercreated"
            orderType={orderType}
          />
          <Box sx={styles.borderedCard}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                fontSize={15}
                fontWeight={600}
                color={blackShade05}
                mb="12px"
                lineHeight={"20px"}
              >
                Mainfest Details
              </Typography>
              <Box display="flex" alignItems="flex-end" flexDirection="column">
                <Button
                  sx={styles.addActionBtn}
                  onClick={handleAddMore}
                  // disabled={loader}
                >
                  Add More
                </Button>
                {addError && (
                  <Typography
                    component="p"
                    fontSize={12}
                    fontWeight={500}
                    sx={{ color: "red", mt: 1 }}
                  >
                    Please enter custom box details
                  </Typography>
                )}
              </Box>
            </Box>
            <ManifestCard
              title="Small Box"
              boxData={smallBoxData}
              onValueChange={(name, value) => {
                handleSetValue(name, value, smallBoxData, setSmallBoxData);
              }}
            />
            <ManifestCard
              title="Medium Box"
              boxData={mediumBoxData}
              onValueChange={(name, value) => {
                handleSetValue(name, value, mediumBoxData, setMediumBoxData);
              }}
            />
            <ManifestCard
              title="Custom Box"
              boxData={customBoxData}
              onValueChange={(name, value) => {
                handleSetValue(name, value, customBoxData, setCustomBoxData);
              }}
            />
            {moreCustomBoxData?.map((manifestData, index) => (
              <ManifestCard
                key={index}
                title="Custom Box"
                boxData={manifestData}
                onValueChange={(name, value) => {
                  handleMoreBoxChange(index, name, value);
                }}
              />
            ))}

            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={{ md: "flex-end", xs: "center" }}
              alignItems={{ md: "flex-end", xs: "center" }}
              mt={3}
            >
              <Button
                sx={styles.actionBtn}
                onClick={handleShipmentInfo}
                disabled={approveLoading}
              >
                APPROVE
              </Button>
              {error && (
                <Typography
                  component="p"
                  fontSize={12}
                  fontWeight={500}
                  sx={{ color: "red", mt: 1 }}
                >
                  Select atleast one type
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ApproveBtoBPopup;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "12px",
    padding: "16px",

    maxHeight: { md: "95vh", xs: "70vh" },
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
      // height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blackShade159,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },
  cancelIconBtn: {
    padding: { md: "5px 24px", xs: "3px 12px" },
    fontSize: "12px",
    fontWeight: 600,
    color: deleteBtnColor,
    backgroundColor: whiteColor,
    border: "1px solid " + deleteBtnColor,
    "&:hover": {
      color: whiteColor,
      backgroundColor: deleteBtnColor,
    },
    mr: { md: 10, xs: 0 },
  },
  borderedCard: {
    borderRadius: "12px",
    border: `1px solid ${greyColor28}`,
    p: "12px",
    mt: 2,
  },
  actionBtn: {
    padding: "5px 24px",
    fontSize: "14px",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: greyColor8,
    border: "1px solid " + greyColor8,
    "&:hover": { color: greyColor8, backgroundColor: whiteColor },
  },
  addActionBtn: {
    padding: "3px 18px",
    fontSize: "13px",
    fontWeight: 500,
    color: whiteColor,
    backgroundColor: greyColor8,
    border: "1px solid " + greyColor8,
    "&:hover": { color: greyColor8, backgroundColor: whiteColor },
  },
};
