import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  blackShade05,
  blackShade59,
  greyShade04,
  orangeTextColor,
  primaryColor2,
  blueShade1,
  progressBGColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";

function DashboardFormHeader({ title }) {
  return (
    <Box
      mb="20px"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        

        <Typography
          component="h4"
          fontSize={{ xs: 14, md: 16 }}
          color={blackColor}
          fontWeight={600}
          lineHeight="28px"
        >
          {title}
        </Typography>
      </Box>

      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          alignItems: "center",
          gap: "12px",
        }}
      >
        <Box>
          <Typography
            component="h6"
            fontSize={{ xs: 12, md: 12 }}
            color={blackShade05}
            fontWeight={500}
            mb="4px !important"
          >
            Store Detail Completed :{" "}
            <Box component="span" sx={{ color: blueShade1, fontWeight: 600 }}>
              65%
            </Box>
          </Typography>

          <Box sx={styles.progressBG}>
            <Box sx={styles.progress} />
          </Box>
        </Box>

        <Button variant="contained" disableElevation sx={styles.viewMoreBtn}>
          View More
        </Button>
      </Box>
    </Box>
  );
}

export default DashboardFormHeader;

const styles = {
  progressBG: {
    height: "8px",
    borderRadius: "4px",
    backgroundColor: progressBGColor,
  },

  progress: {
    width: "70%",
    height: "8px",
    borderRadius: "4px",
    backgroundColor: blueShade1,
  },

  viewMoreBtn: {
    color: primaryColor2,
    fontSize: "13px",
    fontWeight: 500,
    textTransform: "unset",
    backgroundColor: "transparent",
    textDecoration: "underline",
  },
};
