import React, { useEffect, useState } from "react";
import SearchBoxInput from "../../components/Input/SearchBoxInput";
import {
  Box,
  Chip,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  searchOrdersByOndcOrderIdApi,
  searchOrdersByOrderIdApi,
} from "../../service/api_calls/orders/OrdersApi";
import moment from "moment";
import {
  getCancelledBy,
  getOrderDeliveryTypeText,
  getOrderType,
} from "../../utils/helper";
import OrderReadyToShipCard from "./Cards/OrderReadyToShipCard";
import OrderPendingCard from "./Cards/OrderPendingCard";
import OrderIntransitCard from "./Cards/OrderIntransitCard";
import OrderDeliveredCard from "./Cards/OrderDeliveredCard";
import OrderCanceledCard from "./Cards/OrderCanceledCard";
import {
  BGColor02,
  bgColor15,
  blackShade05,
  blackShade159,
  greenStatusColor,
  greyColor6,
  orangeStatusColor,
  perpleColor1,
  redStatusColor,
} from "../../configs/styles/muiThemes";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import ListEmptyCard from "../../components/ListEmptyCard";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import HorizontalStepper from "../../components/HorizontalStepper";
import AllOrderCard from "./Cards/AllOrderCard";
import GridTableHeader from "../../components/Cards/GridTableHeader";
import {
  ALL_ORDERS_COLUMN_HEADING,
  CANCELED_ORDERS_COLUMN_HEADING,
  DELIVERED_ORDERS_COLUMN_HEADING,
  IN_TRANSIT_COLUMN_HEADING,
  PENDING_ORDERS_COLUMN_HEADING,
  READY_TO_SHIP_ORDERS_COLUMN_HEADING,
} from "../../utils/data";
import { useSelector } from "react-redux";

function SearchOrdersById({
  setOpen,
  setOpenCancelOrder,
  handleTrackOrder,
  handleViewOrder,
  handleApproveOrder,
  handleCancelOrder,
  onSearchStart,
  onSearchClear,
  steps,
  tabValue,
  handleResendB2B,
  handleResend,
  error,
  success,
  disableSearch,
  getOndcBapNameBySubscriberId,
  downloadShipingLabel,
}) {
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchQueryOndc, setSearchQueryOndc] = useState(null);

  const [searchEnabled, setSearchEnabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgOndc, setErrorMsgOndc] = useState("");
  const [isClearSearch, setIsClearSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [orderList, setOrderList] = useState([]);

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { refreshTabData } = useSelector((state) => state.businessOrders);

  const getTabValueByOrderStatus = (status) => {
    if (tabValue === 5) return 5;

    switch (status) {
      case "ordercreated":
        return 0;
      case "orderapproved":
        return 1;
      case "ordershipped":
        return 2;
      case "orderdelivered":
        return 3;
      case "bordercanceled":
        return 4;
      case "cordercanceled":
        return 4;

      default:
        return 5;
    }
  };

  const handleSearch = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    if (!searchQuery) {
      setErrorMsg("Enter the order id");
      return;
    }

    onSearchStart();

    setIsLoading(true);
    setErrorMsg("");
    setSearchQueryOndc("");
    setOrderList([]);
    setIsClearSearch(false);

    const result = await searchOrdersByOrderIdApi({
      businessId,
      orderId: searchQuery,
    });

    if (result) {
      const currentTabValue = getTabValueByOrderStatus(
        result?.cart?.orderStatus || ""
      );
      console.log("SEARCH_RESULT:", result, currentTabValue, tabValue);

      const cartData =
        currentTabValue == tabValue
          ? {
              ...result?.cart,
              cartId: result?.cart?.id,
              ondcOrderId: result?.cart?.ondcInfo?.bapOrderId || "",
            }
          : null;

      const newList = [];
      if (cartData) {
        newList.push(cartData);
      }

      setOrderList(newList || []);
      setIsClearSearch(true);
      setIsLoading(false);
    } else {
      setIsClearSearch(true);
      setIsLoading(false);
    }
  };

  const handleOndcSearch = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    if (!searchQueryOndc) {
      setErrorMsgOndc("Enter the ONDC order id");
      return;
    }

    onSearchStart();

    setIsLoading(true);
    setErrorMsgOndc("");
    setSearchQuery("");
    setOrderList([]);
    setIsClearSearch(false);

    const result = await searchOrdersByOndcOrderIdApi({
      businessId,
      orderId: searchQueryOndc,
    });

    if (result) {
      const currentTabValue = getTabValueByOrderStatus(
        result?.cart?.orderStatus || ""
      );

      const cartData =
        currentTabValue == tabValue
          ? {
              ...result?.cart,
              cartId: result?.cart?.id,
              ondcOrderId: result?.cart?.ondcInfo?.bapOrderId || "",
            }
          : null;

      console.log("SEARCH_RESULT:", result, currentTabValue, tabValue);
      console.log("SEARCH_RESULT__cartData:", cartData);

      const newList = [];
      if (cartData) {
        newList.push(cartData);
      }

      setOrderList(newList || []);
      setIsClearSearch(true);
      setIsLoading(false);
    } else {
      setIsClearSearch(true);
      setIsLoading(false);
    }
  };

  const clearSearch = () => {
    setSearchQuery("");
    setSearchQueryOndc("");
    setIsClearSearch(false);
    setOrderList([]);
    setSearchEnabled(false);
    onSearchClear();
  };
  const getOrderDate = (item) => {
    switch (item?.orderStatus) {
      case "ordercreated":
        return moment(item?.orderCreatedOn).fromNow();
      case "orderapproved":
        return moment(item?.orderApprovedOn).fromNow();
      case "ordershipped":
        return moment(item?.orderShippedOn).fromNow();
      case "orderdelivered":
        return moment(item?.orderDeliveredOn).fromNow();
      case "bordercanceled":
        const businessCanceledDate = item?.orderCancelDateBusiness;

        let cancelDate = "";

        if (businessCanceledDate) {
          cancelDate = moment(businessCanceledDate).fromNow();
        }

        return cancelDate || "N/A";
      case "cordercanceled":
        const customerCanceledDate = item?.orderCancelDateConsumer;

        let formatedCancelDate = "";

        if (customerCanceledDate) {
          formatedCancelDate = moment(customerCanceledDate).fromNow();
        }

        return formatedCancelDate || "N/A";

      default:
        return "N/A";
    }
  };

  const getTableHeading = (tabValue) => {
    switch (tabValue) {
      case 0:
        return PENDING_ORDERS_COLUMN_HEADING;
      case 1:
        return READY_TO_SHIP_ORDERS_COLUMN_HEADING;
      case 2:
        return IN_TRANSIT_COLUMN_HEADING;
      case 3:
        return DELIVERED_ORDERS_COLUMN_HEADING;
      case 4:
        return CANCELED_ORDERS_COLUMN_HEADING;
      case 5:
        return ALL_ORDERS_COLUMN_HEADING;
      default:
        return PENDING_ORDERS_COLUMN_HEADING;
    }
  };

  const FILTER_OPTIONS = [
    { id: "01", label: "All Order", color: blackShade159 },
    { id: "02", label: "ONDC Order", color: greenStatusColor },
    { id: "03", label: "Website Order", color: orangeStatusColor },
    { id: "04", label: "Easy Cart Order", color: redStatusColor },
    { id: "05", label: "Influencer Order", color: perpleColor1 },
  ];

  useEffect(() => {
    if (searchQuery) {
      clearSearch();
    }
  }, [refreshTabData]);

  return (
    <Box
      mb={{ md: 0, xs: 0 }}
      sx={
        matches
          ? {
              position: "sticky",
              backgroundColor: BGColor02,
              top: 190,
              zIndex: 100,
              pb: tabValue === 4 ? 2.2 : 2,
            }
          : {
              backgroundColor: BGColor02,
              pb: tabValue === 4 ? 2 : 0,
            }
      }
    >
      <Box pt={{ xs: 0, md: tabValue === 4 ? 0 : 1 }}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={7.7}>
            <Box
              display={{ md: "flex", xs: "none" }}
              flexWrap="wrap"
              columnGap={2}
              rowGap={0.5}
              mt={0.5}
              mb={{ xs: 1, md: 0 }}
            >
              {FILTER_OPTIONS.map((item) => (
                <FilterOption
                  key={item.id}
                  dotColor={item.color}
                  label={item.label}
                />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} md={2.3}>
            {!disableSearch || searchEnabled ? (
              <Box mb={{ xs: 1.5, md: 0 }}>
                <SearchBoxInput
                  label=""
                  // minWidth="210px"
                  placeholder="Search by ONDC Order ID"
                  value={searchQueryOndc}
                  onChange={(text) => {
                    if (text === "") {
                      clearSearch();
                      return;
                    }
                    setSearchQueryOndc(text);
                    setSearchEnabled(true);
                  }}
                  onSearchClick={handleOndcSearch}
                  errorMsg={errorMsgOndc}
                />
              </Box>
            ) : null}
          </Grid>

          <Grid item xs={12} md={2}>
            {!disableSearch || searchEnabled ? (
              <SearchBoxInput
                label=""
                placeholder="Search by Order ID"
                value={searchQuery}
                onChange={(text) => {
                  if (text === "") {
                    clearSearch();
                    return;
                  }
                  setSearchQuery(text);
                  setSearchEnabled(true);
                }}
                onSearchClick={handleSearch}
                errorMsg={errorMsg}
              />
            ) : null}
          </Grid>

          {/* <Grid
            item
            xs={12}
            md={12}
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            // alignItems="center"
            justifyContent={
              tabValue < 4
                ? "space-between"
                : { md: "flex-end", xs: "space-between" }
            }
            gap={1}
            mt={{ md: 0, xs: 0 }}
          >
            {(tabValue === 0 ||
              tabValue === 1 ||
              tabValue === 2 ||
              tabValue === 3) && (
              <Box
                display={{ md: "block", xs: "none" }}
                mt={2}
                mb={{ md: 0, xs: 1 }}
                ml={-1}
              >
                <HorizontalStepper steps={steps} activeStep={tabValue} />
              </Box>
            )}

            <Stack flexDirection="row" alignItems="center" gap={3}>
              {!disableSearch || searchEnabled ? (
                <SearchBoxInput
                  label=""
                  minWidth="210px"
                  placeholder="Search by ONDC Order ID"
                  value={searchQueryOndc}
                  onChange={(text) => {
                    if (text === "") {
                      clearSearch();
                      return;
                    }
                    setSearchQueryOndc(text);
                    setSearchEnabled(true);
                  }}
                  onSearchClick={handleOndcSearch}
                  errorMsg={errorMsgOndc}
                />
              ) : null}

              {!disableSearch || searchEnabled ? (
                <SearchBoxInput
                  label=""
                  placeholder="Search by Order ID"
                  value={searchQuery}
                  onChange={(text) => {
                    if (text === "") {
                      clearSearch();
                      return;
                    }
                    setSearchQuery(text);
                    setSearchEnabled(true);
                  }}
                  onSearchClick={handleSearch}
                  errorMsg={errorMsg}
                />
              ) : null}
            </Stack>
          </Grid> */}

          {isClearSearch && (
            <Grid
              item
              xs={12}
              md={12}
              display="flex"
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
              mt={tabValue > 3 ? { md: 2, xs: 1.5 } : { md: -1, xs: 1.5 }}
            >
              <Box
                display="flex"
                alignItems={{ md: "center", xs: "flex-start" }}
                flexDirection={{ xs: "row", md: "row" }}
                flexWrap={"wrap"}
                gap="6px"
                m="18px 0 10px"
              >
                <Typography
                  component="h4"
                  fontSize={{ md: 13, xs: 13 }}
                  color={blackShade05}
                  fontWeight={400}
                  lineHeight="20px"
                >
                  Search result for Order Id{" "}
                </Typography>

                <Chip
                  label={searchQuery || searchQueryOndc}
                  sx={{ fontSize: "12px", height: "28px" }}
                  onDelete={clearSearch}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      {isLoading && <ThreeDotLoader />}

      {!isLoading && orderList?.length === 0 && isClearSearch && (
        <ListEmptyCard
          icon={
            <ProductionQuantityLimitsOutlinedIcon sx={{ fontSize: "24px" }} />
          }
          text="No Orders Found"
          hideBtn
        />
      )}

      {orderList?.length > 0 ? (
        <GridTableHeader list={getTableHeading(tabValue)} mb={2} />
      ) : null}

      {orderList?.map((item, index) => {
        if (tabValue === 0) {
          return (
            <OrderPendingCard
              key={`order_${item?.orderId}`}
              id={item?.orderId}
              ondcOrderId={item?.ondcOrderId || ""}
              date={moment(item?.orderCreatedOn)?.format("DD MMM YYYY")}
              username={item?.customerAddress?.name}
              addressName={item?.customerAddressName || ""}
              city={item?.businessCity}
              addressCity={item?.customerAddress?.city || ""}
              orderCost={item?.orderTotalCost}
              cartCount={item?.totalQuantity}
              orderType={getOrderType(item)}
              orderStatus="Pending"
              deliveryType={getOrderDeliveryTypeText(item?.deliveryType?.type)}
              statusUpdatedDate={
                item?.orderCreatedOn
                  ? moment(item?.orderCreatedOn).fromNow()
                  : ""
              }
              type={!item?.deliveryType ? null : item?.deliveryType?.type}
              handleViewOrder={() => handleViewOrder(item)}
              handleTrackOrder={() => handleTrackOrder(item)}
              handleApproveOrder={() => handleApproveOrder(item)}
              handleCancelOrder={() => handleCancelOrder(item)}
              getOndcBapNameBySubscriberId={() =>
                getOndcBapNameBySubscriberId(item?.ondcInfo?.bapId)
              }
            />
          );
        }
        if (tabValue === 1) {
          return (
            <OrderReadyToShipCard
              key={`order_${item?.orderId}_search`}
              id={item?.orderId}
              ondcOrderId={item?.ondcOrderId || ""}
              date={moment(item?.orderCreatedOn)?.format("DD MMM YYYY")}
              username={item?.customerAddress?.name}
              addressName={item?.customerAddressName || ""}
              city={item?.businessCity}
              addressCity={item?.customerAddress?.city || ""}
              orderCost={item?.orderTotalCost}
              cartCount={item?.totalQuantity}
              orderType={getOrderType(item)}
              orderStatus="Ready to Ship"
              deliveryType={getOrderDeliveryTypeText(item?.deliveryType?.type)}
              statusUpdatedDate={
                item?.orderApprovedOn
                  ? moment(item?.orderApprovedOn).fromNow()
                  : ""
              }
              handleViewOrder={() => handleViewOrder(item)}
              trackingStatus={item?.orderStatus}
              handleTrackOrder={() => {
                handleTrackOrder(item);
              }}
              handleShipmentInfo={() => setOpen({ show: true, data: item })}
              type={item?.deliveryType?.type}
              shipmentInfo={item?.shipmentInfo}
              handleCancelOrder={() => {
                setOpenCancelOrder({ show: true, data: item });
              }}
              handleResendB2B={handleResendB2B}
              handleResend={handleResend}
              error={error}
              success={success}
              orderData={item}
              search
              getOndcBapNameBySubscriberId={() =>
                getOndcBapNameBySubscriberId(item?.ondcInfo?.bapId)
              }
              downloadShipingLabel={downloadShipingLabel}
            />
          );
        }
        if (tabValue === 2) {
          return (
            <OrderIntransitCard
              key={`order_${item?.orderId}_search`}
              id={item?.orderId}
              ondcOrderId={item?.ondcOrderId || ""}
              date={moment(item?.orderCreatedOn)?.format("DD MMM YYYY")}
              username={item?.customerAddress?.name}
              addressName={item?.customerAddressName || ""}
              city={item?.businessCity}
              addressCity={item?.customerAddress?.city || ""}
              orderCost={item?.orderTotalCost}
              cartCount={item?.totalQuantity}
              orderType={getOrderType(item)}
              orderStatus="Shipped"
              deliveryType={getOrderDeliveryTypeText(item?.deliveryType?.type)}
              carrierId={item?.shipmentInfo?.carrierTrackId || ""}
              statusUpdatedDate={
                item?.orderShippedOn
                  ? moment(item?.orderShippedOn).fromNow()
                  : ""
              }
              handleTrackOrder={() => {
                handleTrackOrder(item);
              }}
              handleViewOrder={() => handleViewOrder(item)}
              handleResendB2B={handleResendB2B}
              handleResend={handleResend}
              error={error}
              success={success}
              orderData={item}
              search
              getOndcBapNameBySubscriberId={() =>
                getOndcBapNameBySubscriberId(item?.ondcInfo?.bapId)
              }
              downloadShipingLabel={downloadShipingLabel}
            />
          );
        }
        if (tabValue === 3) {
          return (
            <OrderDeliveredCard
              key={`order_${item?.orderId}`}
              id={item?.orderId}
              ondcOrderId={item?.ondcOrderId || ""}
              date={moment(item?.orderCreatedOn)?.format("DD MMM YYYY")}
              username={item?.customerAddress?.name}
              addressName={item?.customerAddressName || ""}
              city={item?.businessCity}
              addressCity={item?.customerAddress?.city || ""}
              orderCost={item?.orderTotalCost}
              cartCount={item?.totalQuantity}
              orderStatus="Completed"
              deliveryType={getOrderDeliveryTypeText(item?.deliveryType?.type)}
              orderType={getOrderType(item)}
              carrierId={item?.shipmentInfo?.carrierTrackId || ""}
              statusUpdatedDate={
                item?.orderDeliveredOn
                  ? moment(item?.orderDeliveredOn).fromNow()
                  : ""
              }
              handleTrackOrder={() => handleTrackOrder(item)}
              handleViewOrder={() => handleViewOrder(item)}
              getOndcBapNameBySubscriberId={() =>
                getOndcBapNameBySubscriberId(item?.ondcInfo?.bapId)
              }
              downloadShipingLabel={downloadShipingLabel}
              orderData={item}
            />
          );
        }
        if (tabValue === 4) {
          console.log(item);
          return (
            <OrderCanceledCard
              position={index}
              key={`order_${item?.orderId}`}
              id={item?.orderId}
              ondcOrderId={item?.ondcOrderId || ""}
              date={moment(item?.orderCreatedOn)?.format("DD MMM YYYY")}
              username={item?.customerAddress?.name}
              addressName={item?.customerAddressName || ""}
              city={item?.businessCity}
              addressCity={item?.customerAddress?.city || ""}
              orderCost={item?.orderTotalCost}
              cartCount={item?.totalQuantity}
              trackingStatus={item?.orderStatus}
              orderType={getOrderType(item)}
              // Dates
              orderCreatedOn={item?.orderCreatedOn}
              cancelledBy={getCancelledBy(item?.orderStatus)}
              cancellReason={
                item?.cancelInfo
                  ? item?.cancelInfo?.cancelReason
                  : item?.orderStatus === "bordercanceled"
                  ? item?.notesBusiness
                  : item?.notesConsumer
              }
              orderApprovedOn={item?.orderApprovedOn}
              orderShippedOn={item?.orderShippedOn}
              orderDeliveredOn={item?.orderDeliveredOn}
              handleViewOrder={() => handleViewOrder(item)}
              getOndcBapNameBySubscriberId={() =>
                getOndcBapNameBySubscriberId(item?.ondcInfo?.bapId)
              }
            />
          );
        }
        if (tabValue === 5) {
          return (
            <AllOrderCard
              key={`order_${item?.orderId}`}
              id={item?.orderId}
              ondcOrderId={item?.ondcOrderId || ""}
              date={moment(item?.orderCreatedOn)?.format("DD MMM YYYY")}
              username={item?.customerAddress?.name}
              addressName={item?.customerAddressName || ""}
              city={item?.businessCity}
              addressCity={item?.customerAddress?.city || ""}
              orderCost={item?.orderTotalCost}
              cartCount={item?.totalQuantity}
              orderType={getOrderType(item)}
              trackingStatus={item?.orderStatus}
              statusUpdatedDate={getOrderDate(item)}
              handleTrackOrder={() => {
                handleTrackOrder(item);
              }}
              handleViewOrder={() => {
                handleViewOrder(item);
              }}
              getOndcBapNameBySubscriberId={() =>
                getOndcBapNameBySubscriberId(item?.ondcInfo?.bapId)
              }
              downloadShipingLabel={downloadShipingLabel}
              orderData={item}
              deliveryType={getOrderDeliveryTypeText(item?.deliveryType?.type)}
            />
          );
        }
      })}
    </Box>
  );
}

export default SearchOrdersById;

const FilterOption = ({ dotColor, label, isActive, onSelect }) => {
  return (
    <Box sx={{ ...styles.container(isActive) }} onClick={onSelect}>
      <Box sx={{ ...styles.legendDot, backgroundColor: dotColor }} />
      <Typography
        color={greyColor6}
        fontSize="12px"
        fontWeight="400"
        whiteSpace="nowrap"
      >
        {label}
      </Typography>
    </Box>
  );
};

const styles = {
  legendDot: {
    width: "6px !important",
    height: "6px !important",
    backgroundColor: bgColor15,
    borderRadius: "4px",
    gap: "2px",
  },

  container: (isActive) => ({
    display: "flex",
    // width: { xs: "20vw", md: "auto" },
    // flexDirection:{xs:'column',md:'row'},
    alignItems: "center",
    gap: "10px",
    position: "relative",
    cursor: "pointer",
    "&::before": {
      content: "' '",
      width: "30px",
      height: "2px",
      backgroundColor: isActive ? blackShade159 : "transparent",
      borderRadius: "3px",
      position: "absolute",
      bottom: { md: -3, xs: 8 },
      // right: {xs:65,md:0},
      left: 10,
    },
  }),
};
