import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  bgColor15,
  blackColor,
  blackShade05,
  closedColor,
  greyShade65,
  iconContentColor,
  infoTextColor2,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import MapRoundedIcon from "@mui/icons-material/MapRounded";
import NearMeRoundedIcon from "@mui/icons-material/NearMeRounded";
import { getUserDetails } from "../../../utils/authHelpers";
import BusinessProductTypeCard from "../../BusinessPreview/BusinessProductTypeCard";

function BusinessInfoSection({
  businessDetails,
  storeInfoDetails,
  addressDetails,
  gstDetails,
  ownerDetails,
  isPendingPreview,
  onSubmit,
  onEdit,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const user = getUserDetails();

  const contactNumbers = [
    ownerDetails?.phone,
    ownerDetails?.alternatePhone1,
    ownerDetails?.alternatePhone2,
  ];

  console.log("ownerDetails:", ownerDetails);

  const numberString = contactNumbers
    ?.filter((item) => item !== "")
    ?.join(", ");
  const userEmails = `${ownerDetails?.email} ${
    ownerDetails?.alternateEmail ? ", " + ownerDetails?.alternateEmail : ""
  }`;

  return (
    <Box>
      {/* {JSON.stringify(user)} */}
      <Grid container spacing={4}>
        <Grid item md={8}>
          <Box>
            <Typography
              fontSize={15}
              fontWeight={600}
              color={blackShade05}
              lineHeight={1.5}
              mb={1}
            >
              Business Description
            </Typography>

            <Typography
              fontSize={13}
              fontWeight={400}
              color={infoTextColor2}
              mb={2}
              sx={{ textAlign: "justify" }}
            >
              {businessDetails?.description}
            </Typography>
          </Box>

          <Typography
            fontSize={15}
            fontWeight={600}
            color={blackShade05}
            lineHeight={1.5}
            mb={1}
          >
            Business Detail
          </Typography>

          <GSTInfoCard
            gstNumber={gstDetails?.gstNumber || gstDetails?.gstnumber}
            panNumber={gstDetails?.panNumber || gstDetails?.pannumber}
            businessName={
              gstDetails?.registeredBusinessName || gstDetails?.aliasName
            }
            businessType={storeInfoDetails?.type || gstDetails?.businessTypes}
          />
          <Typography
            fontSize={15}
            fontWeight={600}
            color={blackShade05}
            lineHeight={1.5}
            mt={2}
            mb={1}
          >
            Category & Product Info
          </Typography>

          <BusinessProductTypeCard
            businessCategory={
              businessDetails?.category || businessDetails?.businessCategories
            }
            mainProducts={businessDetails?.mainProducts}
            fssaiNumber={
              businessDetails?.fssaiNumber || businessDetails?.rewardCurrency
            }
          />
        </Grid>

        <Grid item md={4}>
          <ContractCard
            name={user?.account?.name}
            // phoneNumber={user?.account?.phonenumber}
            // email={user?.account?.email || "NA"}
            email={userEmails || "NA"}
            phoneNumber={numberString || "NA"}
          />

          <InformationCard
            title="Address"
            content={`${addressDetails?.address} 
            ${addressDetails?.city ? addressDetails?.city + "," : ""} 
            ${addressDetails?.state ? addressDetails?.state + "," : ""} 
            ${addressDetails?.pinCode || addressDetails?.pincode}`}
            icon={<MapRoundedIcon sx={styles.icon} />}
          />
          <InformationCard
            title="Landmark"
            content={
              addressDetails?.landmark || addressDetails?.locality || "NA"
            }
            icon={<NearMeRoundedIcon sx={styles.icon} />}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default BusinessInfoSection;

const ContractCard = ({ name, phoneNumber, email }) => {
  return (
    <Box sx={styles.card}>
      <Grid container spacing={2}>
        <Grid item>
          <Box sx={styles.iconContainer}>
            <PersonRoundedIcon sx={styles.icon} />
          </Box>
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          <Box>
            <Typography sx={styles.title}>Contact Detail</Typography>
            <Typography sx={styles.content1}>{name}</Typography>
            <Typography sx={styles.content1}>{phoneNumber}</Typography>
            <Typography sx={styles.content1}>{email}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const InformationCard = ({ title, content, content2, icon }) => {
  return (
    <Box sx={{ ...styles.card, mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item md={1.6}>
          <Box sx={styles.iconContainer}>{icon}</Box>
        </Grid>
        <Grid item md={10.4}>
          <Box>
            <Typography sx={styles.title}>{title}</Typography>
            <Typography sx={styles.content1}>{content || "NA"}</Typography>
            {content2 && (
              <Typography sx={styles.content1}>{content2}</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const GSTInfoCard = ({ gstNumber, businessName, panNumber, businessType }) => {
  return (
    <Box sx={{ ...styles.card, mt: 2 }}>
      <Grid container spacing={1.8}>
        <Grid item md={6}>
          <Box>
            <Typography sx={styles.gstTitle}>GST Number</Typography>
            <Typography sx={styles.gstContent}>{gstNumber}</Typography>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box>
            <Typography sx={styles.gstTitle}>PAN Number</Typography>
            <Typography sx={styles.gstContent}>{panNumber}</Typography>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box>
            <Typography sx={styles.gstTitle}>
              Registered Business Name
            </Typography>
            <Typography sx={styles.gstContent}>{businessName}</Typography>
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box>
            <Typography sx={styles.gstTitle}>Business Type</Typography>
            <Grid container columnSpacing={4} rowSpacing={1} pb="10px">
              {businessType?.map((item, index) => {
                return (
                  <Grid item xs={12} md={4} key={"business_type_" + index}>
                    <ListData value={item?.name} />
                  </Grid>
                );
              })}
            </Grid>
            {/* <Typography sx={styles.gstContent}>{businessName}</Typography> */}
          </Box>
        </Grid>
      </Grid>
      {/* <Box display={"flex"} justifyContent="space-between">
        <Box>
          <Typography sx={styles.gstTitle}>GST Number</Typography>
          <Typography sx={styles.gstContent}>{gstNumber}</Typography>
        </Box>
        <Box>
          <Typography sx={styles.gstTitle}>PAN Number</Typography>
          <Typography sx={styles.gstContent}>{panNumber}</Typography>
        </Box>
        <Box>
          <Typography sx={styles.gstTitle}>Registered Business Name</Typography>
          <Typography sx={styles.gstContent}>{businessName}</Typography>
        </Box>
      </Box> */}
    </Box>
  );
};

const ListData = ({ value }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <Box
        sx={{
          width: "6px",
          height: "6px",
          borderRadius: "50%",
          backgroundColor: blackShade05,
        }}
      />
      <Typography fontSize={12} fontWeight={500} color={blackShade05}>
        {value}
      </Typography>
    </Box>
  );
};

const styles = {
  card: {
    padding: "10px 18px 10px",
    background: whiteColor,
    border: "1px solid #eeeeee",
    borderRadius: "13px",
  },
  iconContainer: {
    backgroundColor: bgColor15,
    borderRadius: "50%",
    width: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 15px 35px 0 rgba(0,0,0,0.1)",
  },
  icon: {
    fontSize: "18px",
    color: whiteColor,
  },
  title: {
    fontSize: 15,
    fontWeight: 500,
    color: blackColor,
    lineHeight: 1.5,
    mb: 0.75,
  },
  content: {
    fontSize: 13,
    fontWeight: 400,
    color: iconContentColor,
    lineHeight: 1.7,
  },
  content1: {
    fontSize: 13,
    fontWeight: 400,
    color: greyShade65,
    lineHeight: 1.7,
  },
  closedText: {
    fontSize: 13,
    fontWeight: 500,
    color: closedColor,
    lineHeight: 1.7,
  },

  otherInfoLabel: {
    fontSize: 13,
    fontWeight: 600,
    color: blackColor,
    lineHeight: 1.5,
    mb: 0.75,
  },

  otherInfovalue: {
    fontSize: 13,
    fontWeight: 500,
    color: blackColor,
  },

  contentBox: {
    height: "38px",
    borderRadius: "4px",
    padding: "6px 12px",
    border: "1px solid #e8ebf1",
    backgroundColor: whiteColor,
  },
  gstTitle: {
    fontSize: 13,
    color: greyShade65,
    fontWeight: 400,
    mt: "2px",
    lineHeight: 1.5,
  },
  gstContent: {
    fontSize: 13,
    color: blackShade05,
    fontWeight: 500,
    mb: "2px !important",
    mt: "6px",
    lineHeight: 1.5,
  },
};
