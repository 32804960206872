import { InputAdornment, TextField, Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import {
  authInfoColor,
  authInputBorder,
  borderTextbox,
  descriptionColor,
  errorTextColor,
  greenColor2,
} from "../configs/styles/muiThemes";
import {
  inputError_PasswordRequirementNotMet,
  inputLabel_MinChar,
  inputLabel_OneNum,
  inputLabel_OneSpecialChar,
  inputLabel_OneUpperChar,
} from "../configs/Constants";
import { allowOnlyEnglish } from "../utils/helper";

const lowerCaseLetters = /[a-z]/g;

function AuthPasswordBox({
  placeholder,
  name,
  value,
  onChange,
  icon,
  onIconClick,
  showPassword,
  maxLength,
  inputType,
  isError,
  error,
  errorMsg,
  helperText,
  autoFocus,
  inputRef,
  onKeyUp,
  lower,
  numberFormat,
  upper,
  symbol,
  isPasswordError,
}) {
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = allowOnlyEnglish(inputValue)

    if(onChange){
      onChange({ target: { value: sanitizedValue } });
    }
  };
  return (
    <Box>
      <TextField
        variant="standard"
        required={true}
        fullWidth
        name={name}
        value={value}
        autoFocus={autoFocus}
        onChange={handleInputChange}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        inputRef={inputRef}
        sx={{
          fontSize: 14,
          my: { md: "6px !important", xs: "4px  !important" },
          border: authInputBorder,
          borderRadius: "6px",
          p: "7px 10px",
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ cursor: "pointer" }}>
              {icon}
            </InputAdornment>
          ),
          // style: { padding: '8px' },
          disableUnderline: true,
        }}
        inputProps={{ maxLength: maxLength, autocomplete: "new-password" }}
        type={inputType}

        // {...(error && { error: true, helperText: error })}
      />

      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box>
              <CircleIcon
                style={{ height: "7px", padding: 0 }}
                sx={{ color: upper ? greenColor2 : authInfoColor }}
              />{" "}
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                component="p"
                fontSize={{ xs: 11, md: 12 }}
                fontWeight={400}
                color={upper ? greenColor2 : authInfoColor}
              >
                {inputLabel_OneUpperChar}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box>
              <CircleIcon
                style={{ height: "7px", padding: 0 }}
                sx={{ color: symbol ? greenColor2 : authInfoColor }}
              />{" "}
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                component="p"
                fontSize={{ xs: 11, md: 12 }}
                fontWeight={400}
                color={symbol ? greenColor2 : authInfoColor}
              >
                {inputLabel_OneSpecialChar}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box>
              <CircleIcon
                style={{ height: "7px", padding: 0 }}
                sx={{ color: lower ? greenColor2 : authInfoColor }}
              />{" "}
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                component="p"
                fontSize={{ xs: 11, md: 12 }}
                fontWeight={400}
                color={lower ? greenColor2 : authInfoColor}
              >
                {inputLabel_MinChar}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box>
              <CircleIcon
                style={{ height: "7px", padding: 0 }}
                sx={{ color: numberFormat ? greenColor2 : authInfoColor }}
              />{" "}
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                component="p"
                fontSize={{ xs: 11, md: 12 }}
                fontWeight={400}
                color={numberFormat ? greenColor2 : authInfoColor}
              >
                {inputLabel_OneNum}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {isPasswordError ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={{ xs: 13, md: 13 }}
          color={errorTextColor}
        >
          {inputError_PasswordRequirementNotMet}
        </Typography>
      ) : null}

      {helperText ? (
        <Typography
          variant="body1"
          component="p"
          fontSize={11}
          color={descriptionColor}
        >
          {helperText}
        </Typography>
      ) : null}
    </Box>
  );
}

export default AuthPasswordBox;
