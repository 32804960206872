import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  greyColor19,
  tealColor,
  whiteColor,
} from "../../configs/styles/muiThemes";

function BenefitsInfoCard() {
  const BENEFITS_LIST = [
    "PAN-India Low cost Shipping (Up to 2500 Kgs)",
    "Loyalty wallets for Customers",
    "Buy now/ Pay later option",
    "Dedicated Mobile dashboard",
    "Automated CRM Tools",
    "Coupon codes, Preferred Discounts",
    "Google SEO & ONDC Reach",
    "First time buyer discounts",
    "Free Sample programs",
  ];

  return (
    <Box sx={styles.card}>
      <Typography
        variant="h5"
        fontSize={{ md: 17, xs: 14 }}
        fontWeight={600}
        lineHeight="26px"
        color={whiteColor}
        mt="2px"
      >
        You Store will be online and ready for accepting orders once the catalog
        and E-Commerce detail are updated
      </Typography>

      <Typography
        variant="h6"
        fontSize={{ md: 14, xs: 13 }}
        fontWeight={600}
        lineHeight="25px"
        color={tealColor}
        m={{md:"10px 0",xs:"8px 0"}}
      >
        Here are the benefits you can enjoy!
      </Typography>

      <Grid container>
        {BENEFITS_LIST?.map((item, index) => (
          <Grid item md={6}>
            <Typography
              key={`benefit_point_${index}`}
              component="p"
              fontSize={{ md: 13, xs: 12 }}
              fontWeight={500}
              color={greyColor19}
              lineHeight="20px"
              mb={{md:"7px",xs:"4px"}}
              //   sx={{whiteSpace:'pre-line'}}
            >
              &#x2022; {item}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default BenefitsInfoCard;

const styles = {
  card: {
    backgroundColor: "#051e3b",
    backgroundImage: {
      md: "url(https://storage.googleapis.com/bodefaults/businessdashboardweb/10.png)",
      xs: "none",
    },
    backgroundSize: "140%",
    // backgroundPosition: '15% 30%',
    borderRadius: "14px",
    p:{md: "30px 20px",xs: "15px 20px"},
  },
};
