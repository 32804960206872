import { Box, Typography } from '@mui/material';
import React from 'react'
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import { blueColor02, blueColor04, greyColor24, mobilePrimary, whiteColor } from '../../../configs/styles/muiThemes';


function PreferredEmptyCard() {
  return (
    <Box
      style={styles.card}
      gap={1}
      justifyContent="center"
      alignItems="center"
      mt={2}
    >
      <Box sx={styles.iconContainer}>
        <CreditCardOutlinedIcon sx={{ fontSize: "24px" }} />
      </Box>
      <Typography
        sx={{
          color: greyColor24,
          fontSize: "14px",
          fontWeight: 500,
          textAlign: "center",
          mb: "10px",
          display: "inline",
        }}
      >
        Boost sales through,{" "}
        <Typography
          variant="h6"
          color={{xs: mobilePrimary, md:blueColor04}}
          fontSize="14px"
          fontWeight="500"
          display="inline"
        >
          Personalized Discounts
        </Typography>
        {""} for your loyal customers
      </Typography>

      <Typography
        sx={{
          color: greyColor24,
          fontSize: "14px",
          fontWeight: 500,
          textAlign: "center",
          mb: "10px",
        }}
      >
        Assign discounts by customer phone number. You can assign mulitiple
        discounts to <br />
        same customer. Customer can use only one discount per order
      </Typography>
      <Typography
        sx={{
          color: greyColor24,
          fontSize: "14px",
          fontWeight: 400,
          textAlign: "center",
        }}
      >
        Get started by adding new discounts!
      </Typography>
    </Box>
  );
}

export default PreferredEmptyCard

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: whiteColor,
    marginBottom: "24px",
    borderRadius: "10px",
    padding: "30px 15px",
    textAlign: "center",
    border: "1px solid #e5e9f0",
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
  },
  iconContainer: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    background: "rgb(5 60 81 / 7%)",
    padding: "12px",
    mb: "10px",
  },
};
