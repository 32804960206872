import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import {
  greyColor24,
  greyColor26,
  greyColor7,
  whiteColor,
  blackColor,
  blueColor04,
  greyColor40,
  blackShade22,
  mobilePrimary,
} from "../../../configs/styles/muiThemes";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { insertSpaceAtPosition } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import {
  ECOMMERCE_VIDEO_DATA,
  E_COMMERCE_STEP_LIST,
} from "../../../utils/data";
import { scrollBox, stickyHeader } from "../../../utils/styles";
import HowToLinkCard from "../../../components/Cards/HowToLinkCard";
import VideoModal from "../../../components/VideoModal";
import DelegateInfoCard from "../../../components/Cards/DelegateInfoCard";
import ThreeDotLoader from "../../../components/ThreeDotLoader";

function ManageDelegatesContent({
  managersInfo,
  managersInfoLoader,
  activeBusinessInfo,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const profileId = sessionStorage.getItem("profileId");

  const whatsAppNumber = "+918088422615";
  const emailAddress = "support@costbo.com";
  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        // `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Managers+Update+Request.+`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };
  const [tabValue, setTabValue] = useState(4);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const navigate = useNavigate();
  const navigateToTab = (newValue) => {
    if (newValue === 0) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/warehouseDetail`);
    }
    if (newValue === 1) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/deliveryMethods`);
    }
    if (newValue == 2) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/discountDetails`);
    }
    if (newValue == 3) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/otherDetails`);
    }
    if (newValue == 4) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/managers`);
    }
    if (newValue == 5) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/bankInfo`);
    }
  };

  const handleChange = (event, newValue) => {
    navigateToTab(newValue);
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  const handleEditDetails = () => {
    navigate(`/editCommerceInfo`, {
      state: { status: "manager-delegates" },
    });
  };

  return (
    <Box flex={1}>
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          handleClose={handleCloseVideoModal}
          data={ECOMMERCE_VIDEO_DATA}
        />
      )}
      <Box sx={stickyHeader} pb={1.5}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={{ xs: "row", md: "row" }}
          mb="10px"
        >
          <Typography
            variant="h2"
            fontSize={{ md: 17, xs: 16 }}
            fontWeight={600}
            color={greyColor24}
            lineHeight="28px"
          >
            E-Commerce Controls
          </Typography>
          <HowToLinkCard
            link="E-Commerce Controls"
            handleClick={() => {
              setOpenVideoModal(true);
              // navigate("/howToVideoGuides", {
              //   state: { status: "eCommerceControls" },
              // });
            }}
            justifyContent="flex-start"
          />
        </Box>
        <Box
          sx={{
            ...scrollBox,
            display: "block",
            borderBottom: 1,
            borderColor: "divider",
            mb: { xs: "10px", md: 1 },
          }}
        >
          <Tabs
            variant="scrollable"
            value={tabValue}
            onChange={(event, newValue) => handleChange(event, newValue)}
            aria-label="basic tabs example"
            textColor={blackColor}
            TabIndicatorProps={{
              style: {
                height: "3px",
                backgroundColor: matches ? blueColor04 : mobilePrimary,
              },
            }}
            sx={{
              minHeight: "35px !important",
              "& .MuiButtonBase-root": {
                minWidth: "auto !important",
              },
            }}
          >
            {E_COMMERCE_STEP_LIST?.map((item, index) => {
              return (
                <Tab
                  key={"filter_" + index}
                  label={item.label}
                  // label={item.label}
                  sx={{
                    fontSize: { xs: "13px", md: "13.5px" },
                    fontWeight: tabValue == index ? 500 : 500,
                    p: 0,
                    minHeight: "30px !important",
                    mr: { xs: "20px", md: "55px" },
                    color: item.id === tabValue ? matches ? blueColor04 : mobilePrimary : greyColor40,
                    textTransform: "capitalize",
                  }}
                />
              );
            })}
          </Tabs>
        </Box>

        {!managersInfoLoader ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="h4"
              fontSize={15}
              fontWeight={600}
              color={greyColor24}
              lineHeight="28px"
            >
              Super Admin
            </Typography>
            {managersInfo?.ownerInfo?.profileId === profileId && (
              <Button sx={styles.editBtn} onClick={handleEditDetails}>
                EDIT
              </Button>
            )}
          </Box>
        ) : (
          <ThreeDotLoader />
        )}
      </Box>
      {!managersInfoLoader && (
        <>
          <Grid container mt={1}>
            <Grid item xs={12} md={3} spacing={2}>
              <DelegateInfoCard
                profileImg={managersInfo?.ownerInfo?.photoUrl}
                name={managersInfo?.ownerInfo?.name}
                phoneNumber={insertSpaceAtPosition(
                  managersInfo?.ownerInfo?.phone,
                  3
                )}
                isNotValid={!managersInfo?.ownerInfo}
              />
            </Grid>
          </Grid>
          <Typography
            variant="h4"
            fontSize={15}
            fontWeight={600}
            color={greyColor24}
            lineHeight="28px"
            mt={2}
            mb="4px"
          >
            Managers
          </Typography>
          <Typography
            fontSize={{ md: 13.5, xs: 12.5 }}
            fontWeight={400}
            color={greyColor7}
            lineHeight="20px"
            mt="8px"
            mb={"2px"}
          >
            The following users will be able Manage inventory, Approve orders,
            Monitor transactions, Update E-Commerce setting and other business
            functionalities
          </Typography>
          <Typography
            variant="h6"
            fontSize={{ md: 13.5, xs: 12.5 }}
            fontWeight={400}
            color={greyColor7}
            display="inline"
            lineHeight="20px"
            mb="15px"
          >
            They will be able to manage customer data as well
          </Typography>
          <Box mt="16px">
            <Grid container spacing={2}>
              {/* <Grid item xs={12} md={1.5}>
            <AddActionButton btnText="Add Managers" onClick={() => {}} />
          </Grid> */}
              {managersInfo?.managers?.length > 0 ? (
                managersInfo?.managers?.map((manager, index) => (
                  <Grid item xs={12} md={3} key={manager?.profileId}>
                    <DelegateInfoCard
                      profileImg={manager.photoUrl}
                      name={manager?.name}
                      phoneNumber={insertSpaceAtPosition(manager?.phone, 3)}
                      // statusColor={greenColor2}
                    />
                  </Grid>
                ))
              ) : (
                <Box sx={styles.emptyCard}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="10px"
                    alignItems="center"
                    my={4}
                  >
                    <Box sx={styles.iconContainer}>
                      <AccountBalanceOutlinedIcon sx={{ fontSize: "24px" }} />
                    </Box>

                    <Typography variant="body2" sx={styles.noDataText}>
                      No Managers are assigned
                    </Typography>
                  </Box>
                </Box>
              )}

              {/* <Grid item xs={12} md={1.5}>
            <AddActionButton btnText="Phone Number Empty" onClick={() => {}} />
          </Grid> */}
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
}

export default ManageDelegatesContent;

const AddActionButton = ({ btnText, onClick }) => {
  return (
    <Box
      height="120px"
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      onClick={onClick}
    >
      <AddCircleIcon sx={{ fontSize: "40px", color: greyColor24 }} />

      <Typography
        fontSize={14}
        fontWeight={600}
        color={greyColor24}
        lineHeight="22px"
        textAlign="center"
        mt="12px"
      >
        {btnText}
      </Typography>
    </Box>
  );
};

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    p: "10px",
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: { xs: "8px", md: "8px" },
  },

  emptyCard: {
    p: { xs: "10px 15px", md: "10px 20px 20px" },
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: { xs: "8px", md: "13px" },
    mt: { xs: "5px", md: "15px" },
    mx: "20px",
    width: "100%",
  },

  iconContainer: {
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "25px",
    backgroundColor: "#e5e8ee",
  },

  noDataText: {
    fontSize: 14,
    fontWeight: 500,
    color: greyColor24,
    lineHeight: "24px",
  },

  removeBtn: {
    backgroundColor: "transparent",
    p: "0px",
    fontSize: 13,
    fontWeight: 500,
    color: greyColor26,
    textDecoration: "underline",
    textTransform: "unset",
    mt: 1,
  },

  editBtn: {
    backgroundColor: blackShade22,
    padding: "3px 14px",
    fontSize: "12.5px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + blackShade22,
    "&:hover": {
      color: blackShade22,
      backgroundColor: whiteColor,
    },
  },
};
