import { Box, Button, Typography } from "@mui/material";
import React from "react";
import {
  BGColor02,
  blackShade05,
  eCommerceblackcolor1,
  greyColor24,
  greyColor25,
  greyColor6,
  greyColor8,
  menuGradient,
  whiteColor,
} from "../../../../configs/styles/muiThemes";

function ProductTitleInfo({ onCancel, onProceed }) {
  return (
    <Box sx={styles.card}>
      <Typography
        variant="h3"
        fontSize={15}
        fontWeight={600}
        lineHeight="22px"
        color={eCommerceblackcolor1}
        textAlign="center"
        p="15px 50px"
      >
        Here are few other products in your webstore with similar Name
      </Typography>

      <Box
        sx={{
          backgroundColor: whiteColor,
          p: "20px 15px 15px",
        }}
      >

        <InfoTitle count="1" text="Toor dal - 500g" />
        <InfoTitle count="2" text="Toor dal - 100g" />
        <InfoTitle count="3" text="Toor dal - 1kg" />


        <Typography
          variant="body1"
          fontSize={14}
          fontWeight={500}
          lineHeight="20px"
          color={greyColor6}
          m="10px 0 0 0"
          textAlign="center"
        >
          Do you want to proceed with this name?
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        p="4px 15px 18px"
        backgroundColor={whiteColor}
      >
        <Button sx={[styles.actionBtn, styles.cancelBtn]} onClick={onCancel}>
          NO
        </Button>

        <Button sx={[styles.actionBtn, styles.proceedBtn]} onClick={onProceed}>
          YES
        </Button>
      </Box>
    </Box>
  );
}

export default ProductTitleInfo;

const InfoTitle = ({ count, text }) => {
  return (
    <Box display='flex' alignContent='center' gap='10px' mb="12px">
      <Box sx={styles.countBox}>
        <Typography
          variant="body2"
          fontSize={12}
          fontWeight={500}
          lineHeight="20px"
          color={greyColor24}
        >
          {count}
        </Typography>
      </Box>

      <Typography
          variant="body1"
          fontSize={13}
          fontWeight={500}
          lineHeight="20px"
          color={greyColor24}
        >
          {text}
        </Typography>
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: BGColor02,
    borderRadius: "15px",
    overflow: "hidden",
  },

  actionBtn: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "22px",
    color: greyColor8,
    border: `1px solid ${greyColor8}`,
    p: "5px 35px",
  },

  cancelBtn: {
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },

  proceedBtn: {
    color: whiteColor,
    background: menuGradient,
    p: "5px 35px",
    border: `1px solid ${greyColor8}`,

    "&:hover": {
      color: greyColor8,
      background: whiteColor,
      border: `1px solid ${greyColor8}`,
    },
  },

  countBox: {
    width: "24px",
    height: "24px",
    borderRadius: "15px",
    backgroundColor: greyColor25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
