import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { Box, Typography } from "@mui/material";
import { greenColor2, greyColor6, redStatusColor } from "../../configs/styles/muiThemes";

function ErrorHighlight({status = 'none', isActive, isError, text}) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
        <CircleIcon
          style={{ height: "7px", padding: 0 }}
          sx={{ color: status === 'valid' ? greenColor2 : status === 'invalid' ? redStatusColor : greyColor6  }}
        />{" "}
      </Box>

      <Box>
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={{ xs: 11, md: 12 }}
          fontWeight={500}
          color={status === 'valid' ? greenColor2 : status === 'invalid' ? redStatusColor : greyColor6}
        >
          {text}
        </Typography>  
      </Box>
    </Box>
  );
}

export default ErrorHighlight;
