// export const convertImgURLtoBase64 = async (imageUrl) => {
//   const url =
//     "https://images.pexels.com/photos/13036804/pexels-photo-13036804.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
//   const base64Data = await fetch(url)
//     .then((response) => response.blob())
//     .then((blob) => {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const base64String = reader.result;
//         return base64String;
//       };
//       reader.readAsDataURL(blob);
//     })
//     .catch((error) => {
//       console.error("Error converting image to base64:", error);
//       return null;
//     });

//   return base64Data;
// };

// Function to convert base64 String to Blob
export const base64ToBlob = (base64) => {
  const byteString = atob(base64.split(",")[1]);
  const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

// Function to convert Blob to base64 String
export const blobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

const imageUrlToBase64 = async (url) => {
  const imgUrl =
    "https://images.pexels.com/photos/13036804/pexels-photo-13036804.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          resolve(base64String);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const convertImagesToBase64 = async (imageUrls) => {
  console.log("convertImagesToBase64", imageUrls);

  const filteredList = imageUrls?.filter(
    (photo) => photo?.status !== "inactive"
  );

  console.log("convertImagesToBase64_filteredList", filteredList);

  const base64Strings = await Promise.all(
    filteredList.map((url) => imageUrlToBase64(url?.docURL))
  );

  console.log("convertImagesToBase64_base64Strings", base64Strings);
  return base64Strings;
};
