import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import FormInputBox from "../../../components/Input/FormInputBox";
import {
  addressBtnColor,
  blackShade05,
  blackShade06,
  greyShade04,
  orangeTextColor,
  primaryColor2,
  progressBGColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import DashboardFormHeader from "./DashboardFormHeader";
import DashboardStepper from "../DashboardStepper";
import PickLocationBtn from "../../../components/Buttons/PickLocationBtn";
import RadiosBtnWithLabel from "../../../components/Input/RadiosBtnWithLabel";
import { STORE_STEP_LIST } from "../../../utils/appData";

function PickAddressForm({ gotoNext, goBack }) {
  return (
    <Box sx={styles.card}>
      <DashboardFormHeader />
      <DashboardStepper
        stepList={STORE_STEP_LIST}
        currentPage="pickup-address"
      />

      <Box m="20px 0 15px 0">
        <Typography
          component="h5"
          fontSize={{ xs: 15, md: 18 }}
          color={blackShade05}
          fontWeight={600}
          lineHeight="20px"
        >
          Pickup Address{" "}
          <Box
            component="span"
            sx={{
              fontSize: 13,
              color: greyShade04,
              fontWeight: 400,
              ml: "4px",
            }}
          >
            <br />
            (Shipments will be picked up from this location for delivery)
          </Box>
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
          gap: { xs: "0px", md: "12px" },
          mb: "15px",
        }}
      >
        <Typography
          component="h5"
          fontSize={{ xs: 13, md: 14 }}
          color={blackShade05}
          fontWeight={500}
          mb="3px !important"
        >
          Pickup Address same as Business Address
        </Typography>

        <Box>
          <RadiosBtnWithLabel
            label="Yes"
            checked={false}
            onValuesChanged={() => {}}
          />

          <RadiosBtnWithLabel
            label="No"
            checked={false}
            onValuesChanged={() => {}}
          />
        </Box>
      </Box>

      <PickLocationBtn
        onChooseLocation={() => {}}
        onPickNearLocation={() => {}}
        bgColor={addressBtnColor}
        textColor={blackShade06}
        large
      />

      <Grid container rowSpacing={{ xs: 0.5, md: 2 }} columnSpacing={3}>
        <Grid item xs={12} md={4}>
          <FormInputBox
            label="Pin Code"
            placeholder="Enter Pin Code"
            isDashboardInput
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormInputBox
            label="City"
            placeholder="Enter City"
            isDashboardInput
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormInputBox
            label="State"
            placeholder="Enter State"
            isDashboardInput
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormInputBox
            label="Address"
            placeholder="Enter Address"
            isDashboardInput
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormInputBox
            label="Landmark"
            placeholder="Enter Landmark"
            isDashboardInput
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormInputBox
            label="Pickup Contact Number"
            placeholder="Enter Pickup Contact Number"
            isDashboardInput
          />
        </Grid>
      </Grid>

      <Box mt="15px">
        <ActionButtons
          onBackClick={goBack}
          onSaveClick={() => {}}
          onNextClick={gotoNext}
          backBtnText="Previous"
          showBackBtn
        />
      </Box>
    </Box>
  );
}

export default PickAddressForm;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    p: "20px",
    my: "25px",
  },

  progressBG: {
    height: "8px",
    borderRadius: "4px",
    backgroundColor: progressBGColor,
  },

  progress: {
    width: "70%",
    height: "8px",
    borderRadius: "4px",
    backgroundColor: orangeTextColor,
  },

  viewMoreBtn: {
    color: primaryColor2,
    fontSize: "13px",
    fontWeight: 500,
    textTransform: "unset",
    backgroundColor: "transparent",
    textDecoration: "underline",
  },
};
