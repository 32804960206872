import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  blackColor,
  blackShade05,
  greyColor6,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
function MobileInfoCardHeader({
  title,
  onManageClick,
  buttonText,
  hideBtn,
  showSecondaryBtn,
  onSecondaryBtnClick,
  btnText,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box sx={styles.card}>
      <Typography
        component="body2"
        fontSize={{ xs: 14.5, md: 14 }}
        color={blackColor}
        fontWeight={600}
      >
        {title}
      </Typography>

      <Box display='flex'>
        {showSecondaryBtn && (
          <Button
            sx={{
              ...styles.btn,
              color: mobilePrimary,
              mr: 1,
            }}
            onClick={onSecondaryBtnClick}
          >
            {btnText}
          </Button>
        )}

        {!hideBtn && (
          <Box display={"flex"}>
            <Button sx={styles.btn} onClick={onManageClick}>
              {buttonText || "Manage"}
            </Button>
            <Box sx={styles.iconContainer()} onClick={onManageClick}>
              <ArrowForwardIcon
                sx={styles.icon(matches ? whiteColor : whiteColor)}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default MobileInfoCardHeader;

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  iconContainer: (bgColor, hideMobile) => ({
    width: { md: "40px", xs: "20px" },
    height: { md: "40px", xs: "20px" },
    borderRadius: "50%",
    backgroundColor: blackColor,
    display: { md: "flex", xs: hideMobile ? "none" : "flex" },
    alignItems: "center",
    justifyContent: "center",
  }),

  icon: (color) => ({ fontSize: { md: 20, xs: 12 }, color: color }),

  btn: {
    fontSize: 11.5,
    fontWeight: 400,
    color: blackColor,
    textDecoration: "underline",
    textTransform: "unset",
    p: "0px",
  },
};
