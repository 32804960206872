import { Box, Typography } from "@mui/material";
import React from "react";
import {
  blueColor02,
  blueColor04,
  greyColor24,
  mobilePrimary,
  whiteColor,
} from "../../configs/styles/muiThemes";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";

function EmptyCashBack({ activeBusinessInfo }) {
  const whatsAppNumber = "+918088422615";
  const emailAddress = "support@costbo.com";
  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        // `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${activeBusinessInfo?.name}+- Loyalty Points+Update+Request.+`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };
  return (
    <Box
      style={styles.card}
      gap={1}
      justifyContent="center"
      alignItems="center"
      mt={2}
    >
      <Box sx={styles.iconContainer}>
        <CardGiftcardOutlinedIcon sx={{ fontSize: "24px" }} />
      </Box>
      <Typography
        sx={{
          color: greyColor24,
          fontSize: "14px",
          fontWeight: 500,
          textAlign: "center",
          mb: "10px",
          display: "inline",
        }}
      >
        To encourage repeat customer purchase enable{" "}
        <Typography
          variant="h6"
          color={{xs: mobilePrimary, md:blueColor04}}
          fontSize="14px"
          fontWeight="500"
          display="inline"
        >
          Loyalty Points Program
        </Typography>
      </Typography>

      <Typography
        sx={{
          color: greyColor24,
          fontSize: "14px",
          fontWeight: 500,
          textAlign: "center",
          mb: "10px",
        }}
      >
        Your customer will be able to get 5% to 10% cashback on the order value
        and use <br />
        the points in subsequent orders
      </Typography>
      <Typography
        color={greyColor24}
        fontSize={14}
        fontWeight={400}
        lineHeight={1.6}
        // sx={{ whiteSpace: "pre-line", mt: 2 }}
        display="inline"
        mb="15px"
      >
        Contact COSTBO support via{" "}
        <Typography
          // variant="body1"
          fontSize={14}
          fontWeight={500}
          color={{xs: mobilePrimary, md:blueColor04}}
          display="inline"
          // onclick={handleChatClick}
          component="a"
          href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=${activeBusinessInfo?.name}+- Loyalty Points+Update+Request+&fs=1&to=${emailAddress}&tf=cm`}
          target="_blank"
        >
          Email
        </Typography>
        {"  "}
        or{" "}
        <Typography
          // variant="body1"
          fontSize={14}
          fontWeight={500}
          color={{xs: mobilePrimary, md:blueColor04}}
          display="inline"
          // component="a"
          onClick={handleChatClick}
          // href={`https://mail.google.com/mail/u/0/?fs=1&to=${emailAddress}&tf=cm`}
          // target="_blank"
        >
          Whatsapp{"  "}
        </Typography>
        {`to enroll in \nthis program`}
      </Typography>
    </Box>
  );
}

export default EmptyCashBack;

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: whiteColor,
    marginBottom: "24px",
    borderRadius: "10px",
    padding: "30px 15px",
    textAlign: "center",
    border: "1px solid #e5e9f0",
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
  },
  iconContainer: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    background: "rgb(5 60 81 / 7%)",
    padding: "12px",
    mb: "10px",
  },
};
