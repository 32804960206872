import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  CheckRounded,
  LocalMallRounded,
  LocalShippingRounded,
} from "@mui/icons-material";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { blackShade20, blueColor04, categoryLabelColor, greenColor4, mobilePrimary, stepperBGColor, whiteColor } from "../../configs/styles/muiThemes";
import useDynamicColor from "../../hooks/useDynamicColor";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    // top: 0,
    // right: -50,
    marginTop: "-1px",
    marginLeft: "-20px",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
        useDynamicColor() === mobilePrimary ? greenColor4 : blueColor04,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
        useDynamicColor() === mobilePrimary ? greenColor4 : blueColor04,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: "120%",
    height: 2,
    border: 0,
    backgroundColor: stepperBGColor,
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: stepperBGColor,
  zIndex: 1,
  color: whiteColor,
  width: 32,
  height: 32,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor:
      useDynamicColor() === mobilePrimary ? greenColor4 : blueColor04,
    color: whiteColor,
  }),
  ...(ownerState.completed && {
    backgroundColor:
      useDynamicColor() === mobilePrimary ? greenColor4 : blueColor04,
    color: whiteColor,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, index } = props;

  const icons = {
    1: (
      <Box>
        <LocalMallRounded
          sx={{
            color: completed || active ? whiteColor : blueColor04,
            fontSize: "20px",
          }}
        />
      </Box>
    ),
    2: (
      <Box>
        <LocalShippingRounded
          sx={{
            color: completed || active ? whiteColor : blueColor04,
            fontSize: "20px",
          }}
        />
      </Box>
    ),
    3: (
      <Box>
        <CheckRounded
          sx={{
            color: completed || active ? whiteColor : blueColor04,
            fontSize: "20px",
          }}
        />
      </Box>
    ),
  };
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={{ width: "20px", height: "20px" }}
    >
      {/* {icons[String(props.icon)]}
      <Typography variant='body1' fontSize={14} fontWeight={500}>
        {index + 1}
      </Typography> */}

      <CheckRoundedIcon sx={{ fontSize: "14px", color: whiteColor }} />
    </ColorlibStepIconRoot>
  );
}

function PaycartStatusStepper({
  steps,
  activeStep,
  width,
  getDateByOrderStatus,
}) {
  return (
    <Stack sx={{ width: width || { xs: 360, md: "320px" } }}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((item, index) => (
          <Step key={item.label} onClick={() => {}}>
            <StepLabel
              StepIconComponent={(props) =>
                ColorlibStepIcon({ ...props, route: item.route, index })
              }
            >
              <Typography
                component="p"
                fontSize={{ xs: 10, md: 12.5 }}
                fontWeight={500}
                lineHeight="15px !important"
                color={
                  activeStep >= index
                    ? { md: blueColor04, xs: greenColor4 }
                    : stepperBGColor
                }
                mt="-5px"
              >
                {item.label}
              </Typography>

              <Typography
                component="p"
                fontSize={{ xs: 10, md: 12 }}
                fontWeight={400}
                lineHeight="15px !important"
                color={categoryLabelColor}
                mt="7px"
              >
                {getDateByOrderStatus(index)}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}

export default PaycartStatusStepper;
