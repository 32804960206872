import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearReadyToShipOrderInfo,
  getReadyToShipOrderData,
  handleRefreshTabData,
  refreshReadyToShipOrderData,
} from "../../../reducers/orders/ordersSlice";
import ListEmptyCard from "../../../components/ListEmptyCard";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import moment from "moment";
import OrderReadyToShipCard from "../Cards/OrderReadyToShipCard";
import { getOrderDeliveryTypeText, getOrderType } from "../../../utils/helper";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchOrdersById from "../SearchOrdersById";
import HorizontalStepper from "../../../components/HorizontalStepper";
import ModalCard from "../../../components/ModalCard";
import OrderBusinessApprove from "../Popups/ApprovePopups/OrderBusinessApprove";
import OrderCancelPopup from "../CancelOrderPopup/OrderCancelPopup";
import {
  blackShade23,
  greyColor42,
  orderCardBorder,
  orderCardHeadingBorder2,
} from "../../../configs/styles/muiThemes";
import { stickyHeader } from "../../../utils/styles";
import ActionInfoHeader from "../Cards/ActionInfoHeader";
function ReadyToShipOrdersContent({
  handleTrackOrder,
  handleViewOrder,
  steps,
  tabValue,
  handleResendB2B,
  downloadShipingLabel,
  handleResend,
  error,
  success,
  commerceInfo,
  getOndcBapNameBySubscriberId,
}) {
  const PAGE_SIZE = 30;

  const dispatch = useDispatch();
  const [hasMoreData, setHasMoreData] = useState(true);

  const [isSearchActive, setIsSearchActive] = useState(false);

  const [open, setOpen] = useState({ show: false, data: "" });

  const { readyToShipOrders, readyToShipPageNo, readyToShipOrdersLoader } =
    useSelector((state) => state.businessOrders);

  const [openCancelOrder, setOpenCancelOrder] = useState({
    show: false,
    data: null,
  });

  const getOrdersData = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      getReadyToShipOrderData({
        businessId,
        pageSize: PAGE_SIZE,
        pageNo: readyToShipPageNo,
      })
    );
  };

  const fetchNextData = () => {
    getOrdersData();
  };

  // handle Searching
  const onSearchStart = () => {
    setIsSearchActive(true);
    dispatch(clearReadyToShipOrderInfo());
  };

  const onSearchClear = () => {
    setIsSearchActive(false);
    getOrdersData();
  };

  const handleApproveSuccess = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    setOpen({ show: false, data: "" });
    dispatch(handleRefreshTabData());
    dispatch(
      refreshReadyToShipOrderData({
        businessId,
        pageSize: PAGE_SIZE,
        pageNo: 1,
      })
    );
  };

  useEffect(() => {
    getOrdersData();
    return () => {
      dispatch(clearReadyToShipOrderInfo());
    };
  }, []);

  useEffect(() => {
    if ((readyToShipPageNo - 1) * PAGE_SIZE > readyToShipOrders?.length) {
      setHasMoreData(false);
    }

    return () => {};
  }, [readyToShipPageNo]);

  return (
    <Box>
      <SearchOrdersById
        handleTrackOrder={handleTrackOrder}
        setOpen={setOpen}
        setOpenCancelOrder={setOpenCancelOrder}
        handleViewOrder={handleViewOrder}
        onSearchStart={onSearchStart}
        onSearchClear={onSearchClear}
        steps={steps}
        tabValue={tabValue}
        handleResendB2B={handleResendB2B}
        downloadShipingLabel={downloadShipingLabel}
        handleResend={handleResend}
        error={error}
        success={success}
        disableSearch={readyToShipOrders?.length == 0}
        getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
      />

      {!readyToShipOrdersLoader &&
      readyToShipPageNo === 1 &&
      readyToShipOrders?.length === 0 &&
      !isSearchActive ? (
        <ListEmptyCard
          icon={
            <ProductionQuantityLimitsOutlinedIcon sx={{ fontSize: "24px" }} />
          }
          text="No Ready To Ship Orders Found"
          hideBtn
        />
      ) : (
        readyToShipOrdersLoader && readyToShipPageNo === 1 && <ThreeDotLoader />
      )}
      {readyToShipOrders?.length > 0 && (
        <Box
          sx={{
            ...stickyHeader,
            pb: { md: 0, xs: 0 },
            pt: 0,
            top: 250,
            // zIndex: 10000,
            display: { md: "block", xs: "none" },
          }}
        >
          <Box sx={styles.card}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3.2}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  ORDER DETAIL
                </Typography>
              </Grid>
              <Grid item xs={6} md={2.4}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  CUSTOMER INFO & PRICE & QTY
                </Typography>
              </Grid>
              <Grid item xs={6} md={2.2}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  DELIVERY TYPE & STATUS
                </Typography>
              </Grid>
              <Grid item xs={12} md={4.2}>
                <Stack flexDirection="row" alignItems="center" gap={0.5}>
                  <Typography
                    variant="h5"
                    fontSize={12.5}
                    fontWeight={500}
                    color={blackShade23}
                  >
                    ACTION
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      {readyToShipOrders?.length > 0 && (
        <>
          <InfiniteScroll
            dataLength={readyToShipOrders?.length || 0}
            next={fetchNextData}
            hasMore={hasMoreData}
            loader={
              <Box>
                <ThreeDotLoader />
              </Box>
            }
          >
            {readyToShipOrders?.map((item, index) => (
              <OrderReadyToShipCard
                index={index}
                key={`order_${item?.orderId}`}
                id={item?.orderId}
                ondcOrderId={item?.ondcOrderId || ""}
                date={moment(item?.orderCreatedOn)?.format("DD MMM YYYY")}
                username={item?.customerAddressName}
                addressName={item?.customerAddressName || ""}
                city={item?.customerCity}
                addressCity={item?.customerAddressCity || ""}
                orderCost={item?.orderTotalCost}
                cartCount={item?.totalQuantity}
                orderType={getOrderType(item)}
                orderStatus="Ready to Ship"
                deliveryType={getOrderDeliveryTypeText(
                  item?.deliveryType?.type
                )}
                statusUpdatedDate={
                  item?.orderApprovedOn
                    ? moment(item?.orderApprovedOn).fromNow()
                    : ""
                }
                handleViewOrder={() => handleViewOrder(item)}
                trackingStatus={item?.orderStatus}
                handleTrackOrder={() => {
                  handleTrackOrder(item);
                }}
                handleShipmentInfo={() => setOpen({ show: true, data: item })}
                type={item?.deliveryType?.type}
                shipmentInfo={item?.shipmentInfo}
                handleCancelOrder={() => {
                  setOpenCancelOrder({ show: true, data: item });
                }}
                handleResendB2B={handleResendB2B}
                downloadShipingLabel={downloadShipingLabel}
                handleResend={handleResend}
                error={error}
                success={success}
                orderData={item}
                getOndcBapNameBySubscriberId={() =>
                  getOndcBapNameBySubscriberId(item?.ondcBapId)
                }
              />
            ))}
          </InfiniteScroll>
        </>
      )}
      <ModalCard
        open={open.show}
        handleClose={() => {
          setOpen({ show: false, data: "" });
        }}
        width="80%"
      >
        <OrderBusinessApprove
          handleApproveSuccess={() => {
            handleApproveSuccess();
          }}
          handleClose={() => {
            setOpen({ show: false, data: "" });
          }}
          orderData={open.data}
        />
      </ModalCard>

      {openCancelOrder?.show && (
        <OrderCancelPopup
          open={openCancelOrder?.show}
          handleClose={() => {
            setOpenCancelOrder({ show: false, data: null });
            handleApproveSuccess();
          }}
          handleCloseModal={() => {
            setOpenCancelOrder({ show: false, data: null });
          }}
          data={openCancelOrder?.data || null}
          commerceInfo={commerceInfo}
          orderShipStatus="readyToShip"
          getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
        />
      )}
    </Box>
  );
}

export default ReadyToShipOrdersContent;
const styles = {
  card: {
    // display: { md: "flex", xs: "none" },
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "16px", md: "20px" },
    backgroundColor: greyColor42,
    borderTop: orderCardBorder,
    borderRadius: "7px 7px 0px 0px",
    boxShadow: "0 2px 4px rgb(179 188 198 / 5%)",
    padding: "7px 15px",
  },
};
