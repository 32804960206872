import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { whiteColor } from "../../configs/styles/muiThemes";

function ImageMagnifier({ imgUrl, width, height }) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const imgRef = useRef(null);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const handleMouseHover = (e) => {
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();

    console.log({ left, top, width, height });
    console.log(e.clientX, e.clientY);

    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setPosition({ x, y });

    setCursorPosition({ x: e.clientX - left, y: e.clientY - top });
  };

  useEffect(() => {
    if (imgRef.current) {
      const { naturalWidth, naturalHeight } = imgRef.current;
      const aspectRatio = naturalWidth / naturalHeight;

      const height = window.innerHeight * 0.6;
      const width = height * aspectRatio;

      setImageDimensions({ width, height });
    }
  }, [imgUrl]);

  return (
    <Box
      onMouseEnter={() => setShowMagnifier(true)}
      onMouseLeave={() => setShowMagnifier(false)}
      onMouseMove={handleMouseHover}
      position="relative"
      width={`${Math.min(imageDimensions.width, width)}px`}
      height={`${imageDimensions.height}px`}
    >
      <Box
        component="img"
        src={imgUrl}
        ref={imgRef}
        backgroundColor={whiteColor}
        sx={{
          width: "100%",
          height: "100%",
          //   height: { xs: "250px", md: "70vh" },
          borderRadius: "10px",
          objectFit: "contain",
        }}
      />

      {showMagnifier && (
        <Box
          sx={{
            position: "absolute",
            left: `${cursorPosition.x - 100}px`,
            top: `${cursorPosition.y - 50}px`,
            pointerEvents: "none",
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${imgUrl})`,
              //   backgroundPosition: `20% 30%`,
              backgroundPosition: `${position.x}% ${position.y}%`,
              ...styles.magnifierImage,
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default ImageMagnifier;

const styles = {
  magnifierImage: {
    width: "200px",
    height: "200px",
    border: "2px solid " + whiteColor,
  },
};
