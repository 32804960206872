import {
  Box,
  Button,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BGColor02,
  BGcolor01,
  bgColor15,
  blackShade01,
  blackShade05,
  blackShade159,
  blackShade19,
  blackShade23,
  blueColor04,
  blueColor100,
  borderTextbox,
  greenStatusColor,
  greyColor02,
  greyColor03,
  greyColor12,
  greyColor42,
  iconBGcolor01,
  inactiveTextColor,
  lightBlueColor01,
  orangeStatusColor,
  orderCardHeadingBorder2,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  addDurationToDate,
  getColorChip,
  getIssueStatusText,
  getIssueTypeText,
  getONDCOrderType,
  getOpenStatusText,
  getOrderStatusText,
  getOrderType,
  getResolvedSLAText,
  getStatusColor,
  getStatusText,
  getStatusTextSLA,
  getStatusTextSLAEX,
} from "../../../utils/helper";
import PreferredDiscountLegend from "../../PreferredDiscounts/PreferredDiscountLegend";
import SearchBoxInput from "../../../components/Input/SearchBoxInput";
import ViewIssueModal from "../PopupModal/ViewIssueModal";
import ActionIssueModal from "../PopupModal/ActionIssueModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import EmptyCard from "../../../components/Cards/EmptyCard";
import ImageViewModal from "../PopupModal/ImageViewModal";
import {
  clearOrderIdInfo,
  searchCustomerGrievanceIssueId,
  searchCustomerGrievanceOrderId,
} from "../../../reducers/grievanceIssue/grievanceSlice";
import ItemIssueMobileCard from "../Card/ItemIssueMobileCard";

function ItemIssueTab({
  grievanceData,
  status,
  category,
  refreshNewProcessing,
  refreshProcessingResolved,
  getOndcBapNameBySubscriberId,
}) {
  const dispatch = useDispatch();
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const Order_Table_Headers = [
    {
      title: "ISSUE ID ",
      image: "",
    },
    { title: "ORDER ID & CUSTOMER ", image: "" },
    {
      title: "EXPECTED RESPONSE",
      subtitle: `${(<VisibilityIcon sx={{ fontSize: 20 }} />)} EXPECTED - ${(
        <VisibilityIcon sx={{ fontSize: 20 }} />
      )} ACTUAL`,
      image: "",
    },
    { title: "RESOLUTION TIME", subtitle: "123", image: "" },
    { title: "STATUS & ISSUE TYPE ", image: "" },
    { title: "ACTION", image: "" },
  ];

  const [isViewIssueModal, setIsViewIssueModal] = useState({
    show: false,
    grievanceData: null,
  });
  const [isActionIssueModal, setIsActionIssueModal] = useState({
    show: false,
    grievanceData: null,
  });
  const [isImageIssueModal, setIsImageIssueModal] = useState({
    show: false,
    image: null,
  });

  return (
    <Box mt={1} width={"100%"}>
      {grievanceData?.length === 0 && <EmptyCard msg="No issues found" />}
      {/* {searchByOrderId?.length === 0 && !isClearSearch && (
        <EmptyCard msg="No issues found for this Order Id" />
      )}
      {searchByIssueId?.length === 0 && !isClearSearch && (
        <EmptyCard msg="No issues found for this Issue Id" />
      )} */}

      {matches ? (
        <TableContainer
          component={Box}
          sx={{
            borderRadius: "10px",
            overflow: "scroll",
            maxHeight: "420px",
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: blueColor100,
              borderRadius: "50px !important",
            },
            // "&::-webkit-scrollbar-track": {
            //   background: greyColor02,
            //   borderRadius: "50px !important",
            // },
          }}
        >
          <Table aria-label="simple table">
            {matches && (
              <TableHead>
                <TableRow
                  sx={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: greyColor42,
                    zIndex: 1000000,
                    borderRadius: "8px 8px 0 0 ",
                  }}
                >
                  {grievanceData?.length > 0 &&
                    Order_Table_Headers?.map((item, index) => {
                      return (
                        <TableCell
                          sx={{
                            ...styles.tableCell,
                            p: "5px 20px 10px 20px",
                            borderRight: {
                              xs: "none",
                              md: orderCardHeadingBorder2,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              // alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              fontSize={12.5}
                              mb={-0.8}
                              color={"#212735"}
                              fontWeight={500}
                            >
                              {item?.title}
                            </Typography>

                            {(status === "RESOLVED" || status === "CLOSED") &&
                            item?.subtitle ? (
                              <Typography fontSize={11}>
                                (<HourglassTopIcon sx={{ fontSize: 12 }} />{" "}
                                EXPECTED -{" "}
                                <AccessAlarmOutlinedIcon
                                  sx={{ fontSize: 12 }}
                                />{" "}
                                ACTUAL)
                              </Typography>
                            ) : (
                              ""
                            )}
                          </Box>
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
            )}
            <TableBody
              sx={{ backgroundColor: whiteColor }}
              scrollbarWidth={"thin"}
            >
              {grievanceData?.map((item, index) => {
                const orderType = getONDCOrderType(item);
                const Processing_Time = addDurationToDate(
                  item?.createdOn,
                  item?.message?.issue?.expected_response_time?.duration
                );
                const responseTimeStatus =
                  item?.message?.issue?.status === "OPEN"
                    ? getStatusText(
                        item?.createdOn,
                        item?.message?.issue?.expected_response_time?.duration
                      )
                    : getStatusTextSLAEX(
                        item?.createdOn,
                        item?.message?.issue?.expected_response_time?.duration,
                        item?.message?.issue?.issue_actions?.respondent_actions?.find(
                          (item, index) => {
                            return (
                              item.respondent_action === "PROCESSING" &&
                              item.updated_at
                            );
                          }
                        )
                      );

                const resolutionTimeStatus =
                  item?.message?.issue?.status === "OPEN"
                    ? getStatusText(
                        item?.createdOn,
                        item?.message?.issue?.expected_resolution_time?.duration
                      )
                    : item?.message?.issue?.status === "PROCESSING"
                    ? getStatusText(
                        item?.createdOn,
                        item?.message?.issue?.expected_resolution_time?.duration
                      )
                    : getStatusTextSLAEX(
                        item?.createdOn,
                        item?.message?.issue?.expected_resolution_time
                          ?.duration,
                        item?.message?.issue?.issue_actions
                          ?.respondent_actions?.[0]?.updated_at
                      );
                const slaResponseText = getResolvedSLAText(item, "responceSla");
                const slaResolutionText = getResolvedSLAText(
                  item,
                  "resolveSla"
                );
                const respondentProcessData =
                  item?.message?.issue?.issue_actions?.respondent_actions?.find(
                    (item, index) => {
                      return (
                        item.respondent_action === "PROCESSING" &&
                        item.updated_at
                      );
                    }
                  );
                const resUpdatedAt = respondentProcessData
                  ? moment(respondentProcessData?.updated_at)
                  : null;

                const respondentResolvedData =
                  item?.message?.issue?.issue_actions?.respondent_actions?.find(
                    (item, index) => {
                      return (
                        item.respondent_action === "RESOLVED" && item.updated_at
                      );
                    }
                  );

                const resolutionUpdatedAt = respondentResolvedData
                  ? moment(respondentResolvedData?.updated_at)
                  : null;
                return (
                  <TableRow
                    key={"issue" + item?.idex}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      border: borderTextbox,
                    }}
                    scrollbarWidth={"thin"}
                  >
                    <TableCell sx={styles.tableBodyCell()}>
                      <Typography sx={styles.headingText} mb={0.5}>
                        # {item?.issueId}
                      </Typography>
                      <Typography sx={styles.blackText}>
                        {moment(item?.createdOn)?.format("DD MMM, hh:mm A")}
                      </Typography>
                      <Box flex={1} display={"flex"} gap={2}>
                        <Typography
                          sx={{ ...styles.title, display: "inline-block" }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...styles.title,
                              fontSize: 12,
                              color: orderType?.color,
                              display: "inline-block",
                            }}
                          >
                            {console.log("item", item)}
                            {orderType?.label}
                            {item?.ondcInfo?.bapId}
                            {orderType?.orderType === "ondcOrder" ? (
                              <span style={{ color: blackShade19 }}>
                                {" "}
                                -{" "}
                                {getOndcBapNameBySubscriberId(
                                  item?.context?.bap_id
                                )}
                              </span>
                            ) : null}
                          </Typography>
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          ...styles.headingText,
                          ...styles.title,
                          fontSize: 12,
                          color: orderType?.color,
                          display: "inline-block",
                        }}
                        mb={0.5}
                      >
                        # {item?.message?.issue?.order_details?.id}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ ...styles.tableBodyCell() }}>
                      <Box>
                        <Typography
                          sx={{
                            ...styles.headingText,
                          }}
                        >
                          # {item?.orderId}
                        </Typography>
                        <Box>
                          <Typography
                            sx={{
                              color: blackShade01,
                              fontSize: { xs: 12.5, md: 13 },
                              fontWeight: 600,
                              lineHeight: 1.5,
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              overflow: "hidden",
                              "-webkit-line-clamp": "1",
                              "-webkit-box-orient": "vertical",
                            }}
                            // mb={0.5}
                          >
                            {
                              item?.message?.issue?.complainant_info?.person
                                ?.name
                            }
                          </Typography>
                          <Typography sx={styles.blackText}>
                            +91{" "}
                            {
                              item?.message?.issue?.complainant_info?.contact
                                ?.phone
                            }
                          </Typography>
                        </Box>
                        <Typography sx={styles.blackText}>
                          {item?.orderStatus}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={styles.tableBodyCell()}>
                      <Box>
                        {item?.message?.issue?.expected_response_time
                          ?.duration ? (
                          <>
                            <Typography sx={styles.blackText}>
                              <HourglassTopIcon sx={{ fontSize: 11 }} />{" "}
                              {Processing_Time}
                              {" ("}
                              {item?.message?.issue?.expected_response_time?.duration.slice(
                                2
                              ) || "NA"}
                              {")"}
                            </Typography>
                            {(item?.message?.issue?.status === "RESOLVED" ||
                              item?.message?.issue?.status === "CLOSED") && (
                              <Typography sx={{ ...styles.blackText }}>
                                <AccessAlarmOutlinedIcon
                                  sx={{ fontSize: 11 }}
                                />{" "}
                                {moment(resUpdatedAt).format("DD MMM, hh:mm A")}
                              </Typography>
                            )}
                            {item?.message?.issue?.status === "OPEN" ? (
                              <Box
                                sx={styles.statusChip(
                                  getColorChip(
                                    responseTimeStatus?.toLowerCase()
                                  )
                                )}
                              >
                                {responseTimeStatus}
                              </Box>
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: { xs: 12.5, md: 12 },
                                  fontWeight: 500,
                                  lineHeight: 1.5,
                                  color: getStatusColor(
                                    slaResponseText?.toLowerCase()
                                  ),
                                }}
                              >
                                {slaResponseText}
                              </Typography>
                            )}
                          </>
                        ) : (
                          <Box
                            display={"flex"}
                            // justifyContent={"center"}
                            // alignItems={"center"}
                          >
                            <Typography>--/--/--</Typography>
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell sx={styles.tableBodyCell()}>
                      <Box>
                        {item?.message?.issue?.expected_resolution_time
                          ?.duration ? (
                          <>
                            <Typography sx={styles.blackText}>
                              <HourglassTopIcon sx={{ fontSize: 11 }} />{" "}
                              {addDurationToDate(
                                item?.createdOn,
                                item?.message?.issue?.expected_resolution_time
                                  ?.duration
                              )}
                              {" ("}
                              {item?.message?.issue?.expected_resolution_time?.duration.slice(
                                1
                              ) || "NA"}
                              {")"}
                            </Typography>
                            {(item?.message?.issue?.status === "RESOLVED" ||
                              item?.message?.issue?.status === "CLOSED") && (
                              <Typography sx={{ ...styles.blackText }}>
                                <AccessAlarmOutlinedIcon
                                  sx={{ fontSize: 11 }}
                                />{" "}
                                {moment(resolutionUpdatedAt).format(
                                  "DD MMM, hh:mm A"
                                )}
                              </Typography>
                            )}{" "}
                            {item?.message?.issue?.status === "PROCESSING" ? (
                              <Box
                                sx={styles.statusChip(
                                  getColorChip(
                                    resolutionTimeStatus?.toLowerCase()
                                  )
                                )}
                              >
                                {resolutionTimeStatus}
                              </Box>
                            ) : item?.message?.issue?.status === "OPEN" ? (
                              <Box
                                sx={styles.statusChip(
                                  getColorChip(
                                    resolutionTimeStatus?.toLowerCase()
                                  )
                                )}
                              >
                                {resolutionTimeStatus}
                              </Box>
                            ) : (
                              <>
                                <Typography
                                  sx={{
                                    ...styles.blackText,
                                    color: getStatusColor(
                                      slaResolutionText?.toLowerCase()
                                    ),
                                  }}
                                >
                                  {slaResolutionText}
                                </Typography>
                              </>
                            )}
                          </>
                        ) : (
                          <Typography>--/--/--</Typography>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell sx={styles.tableBodyCell()}>
                      <Box
                      // sx={styles.statusChip(
                      //   getColorChip(item?.message?.issue?.status)
                      // )}
                      >
                        <Typography
                          sx={{
                            ...styles.headingText,
                            color: getStatusColor(
                              item?.message?.issue?.status?.toLowerCase()
                            ),
                          }}
                        >
                          {item?.message?.issue?.status}
                        </Typography>
                        {/* {getOrderStatusText(item?.message?.issue?.status)} */}
                      </Box>
                      <Typography sx={styles.headingText}>
                        {getIssueTypeText(
                          item?.message?.issue?.sub_category?.toUpperCase()
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ ...styles.tableBodyCell(), alignItems: "center" }}
                    >
                      <Box display={"flex"} gap={2}>
                        <IconButton
                          sx={styles.addIconBtn}
                          size="large"
                          variant="contained"
                          disableElevation
                          onClick={() =>
                            setIsViewIssueModal({
                              show: true,
                              grievanceData: item,
                            })
                          }
                        >
                          <VisibilityIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                        {item?.message?.issue?.status !== "RESOLVED" &&
                          item?.message?.issue?.status !== "CLOSED" && (
                            <Button
                              variant="outlined"
                              sx={styles.actionBtn(lightBlueColor01)}
                              onClick={() =>
                                setIsActionIssueModal({
                                  show: true,
                                  grievanceData: item,
                                  status: item?.message?.issue?.status,
                                })
                              }
                            >
                              Action
                            </Button>
                          )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        grievanceData?.map((item, index) => {
          const orderType = getONDCOrderType(item);
          const Processing_Time = addDurationToDate(
            item?.createdOn,
            item?.message?.issue?.expected_response_time?.duration
          );
          const responseTimeStatus =
            item?.message?.issue?.status === "OPEN"
              ? getStatusText(
                  item?.createdOn,
                  item?.message?.issue?.expected_response_time?.duration
                )
              : getStatusTextSLAEX(
                  item?.createdOn,
                  item?.message?.issue?.expected_response_time?.duration,
                  item?.message?.issue?.issue_actions?.respondent_actions?.find(
                    (item, index) => {
                      return (
                        item.respondent_action === "PROCESSING" &&
                        item.updated_at
                      );
                    }
                  )
                );

          const resolutionTimeStatus =
            item?.message?.issue?.status === "OPEN"
              ? getStatusText(
                  item?.createdOn,
                  item?.message?.issue?.expected_resolution_time?.duration
                )
              : item?.message?.issue?.status === "PROCESSING"
              ? getStatusText(
                  item?.createdOn,
                  item?.message?.issue?.expected_resolution_time?.duration
                )
              : getStatusTextSLAEX(
                  item?.createdOn,
                  item?.message?.issue?.expected_resolution_time?.duration,
                  item?.message?.issue?.issue_actions?.respondent_actions?.[0]
                    ?.updated_at
                );
          const slaResponseText = getResolvedSLAText(item, "responceSla");
          const slaResolutionText = getResolvedSLAText(item, "resolveSla");
          const respondentProcessData =
            item?.message?.issue?.issue_actions?.respondent_actions?.find(
              (item, index) => {
                return (
                  item.respondent_action === "PROCESSING" && item.updated_at
                );
              }
            );
          const resUpdatedAt = respondentProcessData
            ? moment(respondentProcessData?.updated_at)
            : null;

          const respondentResolvedData =
            item?.message?.issue?.issue_actions?.respondent_actions?.find(
              (item, index) => {
                return item.respondent_action === "RESOLVED" && item.updated_at;
              }
            );

          const resolutionUpdatedAt = respondentResolvedData
            ? moment(respondentResolvedData?.updated_at)
            : null;
          return (
            <ItemIssueMobileCard
              orderType={orderType}
              getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
              item={item}
              customerName={
                item?.message?.issue?.complainant_info?.person?.name
              }
              phoneNo={item?.message?.issue?.complainant_info?.contact?.phone}
              orderId={item?.orderId}
              issueId={item?.issueId}
              createdOn={item?.createdOn}
              issueType={getIssueTypeText(
                item?.message?.issue?.sub_category?.toUpperCase()
              )}
              Processing_Time={Processing_Time}
              responseTimeStatus={responseTimeStatus}
              responseTimeDuration={
                item?.message?.issue?.expected_response_time?.duration
              }
              resolutionTimeStatus={resolutionTimeStatus}
              slaResponseText={slaResponseText}
              slaResolutionText={slaResolutionText}
              resolutionTimeDuration={
                item?.message?.issue?.expected_resolution_time?.duration
              }
              msgstatus={item?.message?.issue?.status}
              setIsViewIssueModal={() =>
                setIsViewIssueModal({
                  show: true,
                  grievanceData: item,
                })
              }
              setIsActionIssueModal={() =>
                setIsActionIssueModal({
                  show: true,
                  grievanceData: item,
                  status: item?.message?.issue?.status,
                })
              }
              resUpdatedAt={moment(resUpdatedAt).format("DD MMM, hh:mm A")}
              resolutionUpdatedAt={moment(resolutionUpdatedAt).format(
                "DD MMM, hh:mm A"
              )}
            />
          );
        })
      )}

      {isViewIssueModal?.show && (
        <ViewIssueModal
          modal={isViewIssueModal?.show}
          getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
          grievanceData={isViewIssueModal?.grievanceData}
          setModal={setIsViewIssueModal}
          setIsImageIssueModal={(imageUrl) =>
            setIsImageIssueModal({
              show: true,
              image: imageUrl,
            })
          }
        />
      )}
      {isActionIssueModal?.show && (
        <ActionIssueModal
          modal={isActionIssueModal?.show}
          getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
          grievanceData={isActionIssueModal?.grievanceData}
          status={isActionIssueModal?.status}
          setModal={setIsActionIssueModal}
          statusrefresh={status}
          category={category}
          refreshNewProcessing={refreshNewProcessing}
          refreshProcessingResolved={refreshProcessingResolved}
          setIsImageIssueModal={(imageUrl) =>
            setIsImageIssueModal({
              show: true,
              image: imageUrl,
            })
          }
        />
      )}
      {isImageIssueModal && (
        <ImageViewModal
          modal={isImageIssueModal?.show}
          setModal={setIsImageIssueModal}
          srcImage={isImageIssueModal.image}
        />
      )}
    </Box>
  );
}

export default ItemIssueTab;

const styles = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", md: "column" },
    backgroundColor: BGcolor01,
  },

  tableHeadings: {
    align: "right",
    color: greyColor03,
    fontSize: "12px",
    fontWeight: "500",
    backgroundColor: BGcolor01,
  },
  arrowIcon: {
    //  position: "absolute",
    color: "#8e97a6",
    width: "9px",
    float: "right",
    opacity: 0.4,
    // top: "16px",
    // right: "5px",
  },
  actionBtn: (color) => ({
    fontSize: 12.5,
    fontWeight: 600,
    color: color,
    borderColor: color,
    p: "3px 8px",
    "&:hover": {
      color: whiteColor,
      bgcolor: color,
      borderColor: color,
    },
  }),
  viewBtn: (color) => ({
    fontSize: 12.5,
    fontWeight: 600,
    color: color,
    borderColor: color,
    p: "3px 8px",
    "&:hover": {
      color: whiteColor,
      bgcolor: color,
      borderColor: color,
    },
  }),

  tableBodyCell: (color) => ({
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: color || blackShade01,
    padding: "10px 20px !important",
  }),
  avatar: (color) => ({
    // width:25,
    // height: 25,
    fontSize: "12px",
    fontWeight: 600,
    color: greyColor03,
    // color: whiteColor,
    // backgroundColor: color || BGColor02,
    backgroundColor: color,
    border: `1px solid ${color || greyColor03}`,
    ml: 1,
  }),
  iconCircle: {
    width: "100px",
    height: "25px",
    borderRadius: "12px",
    backgroundColor: iconBGcolor01,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  tableCell: {
    color: blackShade23,
    fontSize: "13px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    padding: "10px 20px",
  },
  blackText: {
    color: bgColor15,
    fontSize: { xs: 12.5, md: 11 },
    fontWeight: 400,
    lineHeight: 1.5,
  },
  headingText: {
    color: blackShade01,
    fontSize: { xs: 12.5, md: 12 },
    fontWeight: 600,
    lineHeight: 1.5,
  },
  avatar: (color) => ({
    // width:25,
    // height: 25,
    fontSize: "12px",
    fontWeight: 600,
    color: greyColor03,
    // color: whiteColor,
    // backgroundColor: color || BGColor02,
    backgroundColor: color,
    border: `1px solid ${color || greyColor03}`,
    ml: 1,
  }),
  iconCircle: {
    width: "100px",
    height: "25px",
    borderRadius: "12px",
    backgroundColor: iconBGcolor01,
    alignItems: "center",
    justifyContent: "center",
  },
  statusChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 11,
    lineHeight: 1.5,
    p: "1px 13px",
    borderRadius: "50px",
    width: "max-content",
  }),

  excelBtn: {
    display: "flex",
    alignItems: "center",
    gap: 1,
    padding: "4px 12px",
    backgroundColor: "#e7f7f2",
    border: `1px solid #b7e6d9`,
    borderRadius: "20px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#cfeee6",
    },
  },
  textOneLines: {
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflow: "hidden",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
  },
  addIconBtn: {
    fontSize: "12px",
    fontWeight: 600,
    color: bgColor15,
    backgroundColor: whiteColor,
    border: "1px solid " + bgColor15,
    padding: { md: "3px 6px !important", xs: "3px 15px" },
    borderRadius: "4px",
    "&:hover": {
      color: whiteColor,
      backgroundColor: bgColor15,
    },
  },
};
