import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackColor,
  blackShade09,
  blueColor04,
  greyColor8,
  heroOverlayBG,
  whiteColor,
  yellowColor,
} from "../../configs/styles/muiThemes";
import NewNavBar from "../../components/NewNavBar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { validateMobileNumber } from "../../utils/validationUtils";
import { clearCommerceInfo } from "../../reducers/businessPreview/businessPreviewSlice";
import { getMyBusinessSummaryAPI } from "../../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";
import { getUserLocation } from "../../utils/geoLocationHelper";
import {
  checkUserLoggedIn,
  getProfileDetails,
} from "../../reducers/auth/authSlice";
import ModalCard from "../../components/ModalCard";
import AuthLayoutNew from "../Auth/newUi/AuthLayoutNew";
import MobileNumberScreen from "../Auth/newUi/MobileNumberScreen";
import OTPScreen from "../Auth/newUi/OTPScreen";
import PasswordScreen from "../Auth/newUi/PasswordScreen";
import ResetPasswordNew from "../Auth/newUi/ResetPasswordNew";
import CreateAccountNew from "../Auth/newUi/CreateAccountNew";
import { UseForm } from "../../components/UseForm";
import BusinessOnboarding from "../BusinessOnboarding";
import {
  getCommerceDashboardInfoAPI,
  getProductsByBusinessAPI,
} from "../../service/api_calls/BusinessPreview/BusinessPreviewAPI";

const errorValues = {
  phone: "",
};

function getInitializeValues() {
  let initialFValues = {
    phone: "",
  };
  return initialFValues;
}

function HeroSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const initialFValues = getInitializeValues();

  const [openAddBusinessModal, setOpenAddBusinessModal] = useState(false);

  const [userLogin, setUserLogin] = useState(false);
  const [emailId, SetEmailId] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [idToken, setIdToken] = useState("");

  const [currentPage, setCurrentPage] = useState("mobile-number");
  const [userType, setUserType] = useState("");

  const [alert, setAlert] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);

  function validate(fieldValues = values) {
    const temp = { ...errors };

    if ("phone" in fieldValues) {
      temp.phone = validateMobileNumber(fieldValues.phone, true);
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  }

  const { values, errors, setErrors } = UseForm(
    initialFValues,
    errorValues,
    true,
    validate
  );

  const handleNavigation = (route, businessId, routeData) => {
    dispatch(clearCommerceInfo());
    sessionStorage.setItem("selectedBusinessId", businessId);
    navigate(route, routeData || {});
  };

  const onDashboardClicked = async () => {
    getUserLocation();
    const data = await getMyBusinessSummaryAPI();

    if (data?.totalBusiness === 0) {
      dispatch(clearCommerceInfo());
      navigate("/businessListingEmpty");
      // setOpenAddBusinessModal(true);
      return;
    }

    if (
      data?.totalBusiness === 1 &&
      data?.details?.[0]?.status === "inactive"
    ) {
      dispatch(clearCommerceInfo());
      navigate("/businessListing");
      return;
    }

    if (data?.totalBusiness === 1 && data?.details?.[0]?.status === "active") {
      sessionStorage.setItem("costboWEBURL", data?.details?.[0]?.costboWEBURL);

      const businessProduct = await getProductsByBusinessAPI(
        data?.details?.[0]?.businessid
      );

      const businessCommerceInfo = await getCommerceDashboardInfoAPI(
        data?.details?.[0]?.businessid
      );

      if (
        businessProduct?.totalProduct === 0 ||
        businessCommerceInfo?.status === "new" ||
        businessCommerceInfo?.status === "submitted"
      ) {
        sessionStorage.setItem(
          "costboWEBURL",
          data?.details?.[0]?.costboWEBURL
        );
        handleNavigation(
          `/dashboardStartBusiness`,
          data?.details?.[0]?.businessid,
          {
            state: { businessProduct, businessCommerceInfo },
            // state: { businessProduct, businessCommerceInfo: { status: "new" } },
            // state: { businessProduct, businessCommerceInfo: { status: "submitted" } },
            // state: { businessProduct, businessCommerceInfo: { status: "active" } },
          }
        );
        return;
      }

      // Navigate to Dashboard
      handleNavigation(
        `/${data?.details?.[0]?.costboWEBURL}/dashboard`,
        data?.details?.[0]?.businessid
      );
      return;
    }

    if (data?.totalBusiness === 1 && data?.details?.[0]?.status === "pending") {
      handleNavigation(`/addBusinessPending`, data?.details?.[0]?.businessid);
      return;
    }

    if (
      data?.totalBusiness === 1 &&
      (data?.details?.[0]?.status === "draft" ||
        data?.details?.[0]?.status === "rejected")
    ) {
      handleNavigation("/addBusinessDetail", data?.details?.[0]?.businessid, {
        state: { businessId: data?.details?.[0]?.businessid },
      });
      return;
    }

    if (data?.totalBusiness > 1) {
      dispatch(clearCommerceInfo());
      navigate("/businessListing");
    }
  };

  useEffect(() => {
    dispatch(checkUserLoggedIn());
    return () => {};
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [text, SetText] = useState("Most Recent");

  const gotoDashboard = () => {
    // if (matches) {
    if (!isLoggedIn) {
      setUserLogin(true);
      return;
    }
    onDashboardClicked();
    // } else {
    //   setAlert(true);
    // }
    // window.open("https://business-dashboard.costbo.com/", "_blank");
  };

  const handleCloseModal = () => {
    setOpenAddBusinessModal(false);
  };

  return (
    <Box sx={styles.container}>
      <NewNavBar handleDashboardClick={gotoDashboard} isLoggedIn={isLoggedIn} />

      <Box m={{ md: "55px 0 125px", xs: "25px 0 20px" }}>
        <Container
          sx={{
            maxWidth: "1350px !important",
            // paddingRight: { xs: "0px", md: "auto" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              flexDirection: { md: "row", xs: "column" },
              
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
                mt:{xs:'0', md:'58px'}
              }}
            >
              <Typography
                variant="h2"
                fontSize={{ xs: 15, md: 18 }}
                fontWeight={{ xs: 400, md: 500 }}
                lineHeight="25px"
                letterSpacing="0.5px"
                textAlign={{ md: "left", xs: "center" }}
                color={blackShade09}
                sx={{ mb: { md: "20px !important", xs: "0" } }}
              >
                Sign up with CostBo
              </Typography>

              <Typography
                variant="h1"
                fontSize={{ xs: 24, md: 47 }}
                fontWeight={{ xs: 600, md: 600 }}
                lineHeight={{ xs: "34px", md: "60px" }}
                letterSpacing="0.5px"
                color={blackShade09}
                textAlign={{ md: "left", xs: "center" }}
                m={{
                  xs: "5px 0 20px !important",
                  md: "0px 0 40px !important",
                }}
                sx={{ whiteSpace: "pre-line" }}
              >
                Most Advanced Full-Stack{" "}
                <strong
                  style={{
                    color: blueColor04,
                    fontWeight: "600",
                  }}
                >
                  ONDC Seller App
                </strong>{" "}<br/>
                Trusted by Leading Brands
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                gap={{ xs: "15px", md: "30px" }}
                flexWrap="wrap"
              >
                {isLoggedIn ? (
                  <Button
                    sx={{ ...styles.actionBtn, ...styles.reachUsBtn }}
                    variant="contained"
                    disableElevation
                    size="large"
                    onClick={gotoDashboard}
                  >
                    GET STARTED
                  </Button>
                ) : (
                  <Button
                    sx={{ ...styles.actionBtn, ...styles.reachUsBtn }}
                    variant="contained"
                    disableElevation
                    size="large"
                    onClick={gotoDashboard}
                    // onClick={() => {}}
                  >
                    SIGNUP / GET STARTED
                  </Button>
                )}

                <Button
                  sx={{ ...styles.actionBtn, ...styles.gotoDashboardBtn }}
                  variant="contained"
                  disableElevation
                  size="large"
                  onClick={gotoDashboard}
                >
                  {isLoggedIn ? "BUSINESS DASHBOARD" : "BUSINESS LOGIN"}
                </Button>
              </Box>
            </Box>

            <Box
              component="img"
              // src="https://storage.googleapis.com/bodefaults/businessdashboardweb/landing-banner.png"
              src="https://storage.googleapis.com/bodefaults/businessdashboardweb/landing_banner_V2.png"
              alt="costBo"
              sx={styles.heroImg}
            />
          </Box>
        </Container>
      </Box>

      {/* Auth Modal Start */}
      <ModalCard
        open={userLogin}
        handleClose={() => {
          setUserLogin(false);
          setCurrentPage("mobile-number");
        }}
        width="34%"
        modalType="auth"
      >
        <AuthLayoutNew>
          {currentPage === "mobile-number" ? (
            <MobileNumberScreen
              onContinue={(data) => {
                console.log("LOGIN_MODAL_TEST_LOGIN_DATA:", data);
                setCurrentPage(data.page);
                setPhoneNo(data.phone);
              }}
              setUserType={(data) => setUserType(data)}
            />
          ) : null}

          {currentPage === "otp" ? (
            <OTPScreen
              mobileNumber={phoneNo}
              userType={userType}
              onLoginSuccess={() => {
                // setIsLoggedIn(true);
                // dispatch(setLoginData());
                // dispatch(updateIsLogin());
                dispatch(getProfileDetails()).then(() => {});
                setUserLogin(false);
                setCurrentPage("mobile-number");
                // props.handleNext()
              }}
              onChangeNumber={() => setCurrentPage("mobile-number")}
              onCreateAccount={(token) => {
                console.log("LOGIN_MODAL_TEST_CREATE_ACC_TOKEN", token);
                setCurrentPage("create-account");
                setIdToken(token);
              }}
            />
          ) : null}

          {currentPage === "password" ? (
            <PasswordScreen
              mobileNumber={phoneNo}
              onChangeNumber={() => setCurrentPage("mobile-number")}
              onForgotClick={(email) => {
                setCurrentPage("reset");
                SetEmailId(email);
              }}
              onLoginSuccess={() => {
                // setIsLoggedIn(true)
                // dispatch(setLoginData())
                // dispatch(updateIsLogin())
                dispatch(getProfileDetails()).then(() => {});
                setUserLogin(false);
                setCurrentPage("mobile-number");
                onDashboardClicked();
                // props.handleNext()
              }}
            />
          ) : null}

          {currentPage === "reset" ? (
            <ResetPasswordNew
              email={emailId}
              phone={phoneNo}
              onForgotpasswordSuccess={() => {
                dispatch(getProfileDetails());
                setUserLogin(false);
                setCurrentPage("mobile-number");
                onDashboardClicked();
                // setIsLoggedIn(true);
                // dispatch(setLoginData());
              }}
            />
          ) : null}

          {currentPage === "create-account" ? (
            <CreateAccountNew
              phone={phoneNo}
              token={idToken}
              userType={userType}
              onSuccess={() => {
                dispatch(getProfileDetails()).then(() => {});
                // dispatch(setLoginData());
                // setIsLoggedIn(true);
                setUserLogin(false);
                setCurrentPage("mobile-number");
                onDashboardClicked();
              }}
            />
          ) : null}
        </AuthLayoutNew>
      </ModalCard>

      {/* Auth Modal End */}

      {openAddBusinessModal && (
        <BusinessOnboarding
          open={openAddBusinessModal}
          handleClose={handleCloseModal}
        />
      )}

      <ModalCard
        open={alert}
        handleClose={() => {
          setAlert(false);
        }}
        width="27%"
      >
        <AlertMessagePopup
          onClick={() => {
            setAlert(false);
          }}
        />
      </ModalCard>
    </Box>
  );
}

export default HeroSection;

const AlertMessagePopup = ({ onClick }) => {
  return (
    <Box sx={styles.alertCard}>
      <Typography
        variant="body2"
        fontSize={14}
        fontWeight={500}
        textAlign="center"
        sx={{ whiteSpace: "pre-line", lineHeight: { md: "28px", xs: "23px" } }}
      >
        Business Dashboard detail functionality is currently not available in
        mobile devices. Please access the same link from a desktop/laptop.
      </Typography>
      <Typography
        variant="body2"
        fontSize={14}
        fontWeight={500}
        textAlign="center"
        sx={{ whiteSpace: "pre-line", lineHeight: { md: "28px", xs: "23px" } }}
      >
        These features will be made available in mobile devices soon.
      </Typography>
      <Box
        display="flex"
        justifyContent={"flex-end"}
        mt={1.5}
        gap={2}
        sx={{ width: "100%" }}
      >
        <Button sx={styles.alertOkBtn} onClick={onClick}>
          OK
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    position: "relative",
    overflow: "hidden",
  },

  overlay: {
    backgroundColor: heroOverlayBG,
    opacity: 0.75,
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },

  heroImg: {
    width: { md: "619px", xs: "100%" },
    mt: { md: 0, xs: 5 },
    // position: "absolute",
    display: { md: "block", xs: "block" },
    // bottom: 0,
    // right: 80,
  },

  actionBtn: {
    padding: { md: "10px 25px", xs: "6px 15px" },
    fontSize: { xs: "13px", md: "15px" },
    fontWeight: { xs: 500, md: 500 },
    color: blackColor,
    borderRadius: { xs: "5px", md: "7px" },
    cursor: "pointer",
    border: `1.5px solid ${whiteColor}`,
  },

  getStartedBtn: {
    backgroundColor: whiteColor,
    color: blackColor,
    border: `1.5px solid ${whiteColor}`,
    "&:hover": {
      background: "transparent",
      color: whiteColor,
      border: {
        xs: `1.5px solid transparent`,
        md: `1.5px solid ${whiteColor}`,
      },
      outline: "none",
    },
  },

  reachUsBtn: {
    backgroundColor: blueColor04,
    color: whiteColor,
    border: `1.5px solid ${blueColor04}`,
    "&:hover": {
      background: "transparent",
      color: blueColor04,
      border: {
        xs: `1.5px solid transparent`,
        md: `1.5px solid ${blueColor04}`,
      },
      outline: "none",
    },
  },

  gotoDashboardBtn: {
    backgroundColor: yellowColor,
    color: blackShade09,
    border: `1.5px solid ${yellowColor}`,
    "&:hover": {
      background: "transparent",
      color: yellowColor,
      border: {
        xs: `1.5px solid transparent`,
        md: `1.5px solid ${yellowColor}`,
      },
      outline: "none",
    },
  },

  alertCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    padding: "25px 12px 12px 12px",
    borderRadius: "8px",
  },

  alertOkBtn: {
    padding: { md: "6px 22px", xs: "4px 10px" },
    fontSize: "13px",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: greyColor8,
    border: "1.5px solid " + greyColor8,
    "&:hover": {
      color: greyColor8,
      backgroundColor: whiteColor,
      border: "1.5px solid " + greyColor8,
    },
  },
};
