import React from 'react'
import HeroSection from './LandingPage/HeroSection'
import { Box } from '@mui/material'
import FooterBig from '../components/FooterBig'

function LandingPage() {
  return (
    <Box>
        <HeroSection />
        <FooterBig />
    </Box>
  )
}

export default LandingPage