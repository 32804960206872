import React from "react";
import {
  blackColor,
  blueColor04,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { Box, Button, Typography } from "@mui/material";

function AlertCard({
  message,
  onYes,
  onCancel,
  yesloader,
  hideNegativeBtn,
  btnText,
}) {
  return (
    <Box sx={{ backgroundColor: whiteColor, px: 3, py: 2.5, borderRadius: 2 }}>
      <Typography fontSize={16} fontWeight={600} color={blackColor}>
        Alert
      </Typography>

      <Typography fontSize={14} my={2.5}>
        {message}
      </Typography>

      <Box display="flex" justifyContent={"flex-end"} mt={1.5} gap={2}>
        {!hideNegativeBtn && (
          <Button sx={styles.cancelBtn} onClick={onCancel}>
            No
          </Button>
        )}

        <Button sx={styles.fillBtn} onClick={onYes} disabled={yesloader}>
          {btnText || "Yes"}
        </Button>
      </Box>
    </Box>
  );
}

export default AlertCard;

const styles = {
  cancelBtn: {
    padding: "4px 14px",
    fontSize: "12px",
    fontWeight: 600,
    color: blueColor04,
    backgroundColor: whiteColor,
    border: "1.5px solid " + blueColor04,
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },

  fillBtn: {
    backgroundColor: blueColor04,
    padding: "4px 14px",
    fontSize: "12px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + blueColor04,
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
    },
  },
};
