import { Box, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FilePickerInput from "../../components/Input/FilePickerInput";

function ProductImageListDND({
  productImgDataList,
  setProductImgDataList,
  data,
  productPhotoKeys,
  onProductImageUpdate,
  onValueChange,
  onDeleteProductPhoto,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const SELECTOR_LIST = [
    "productImgOne",
    "productImgTwo",
    "productImgThree",
    "productImgFour",
  ];

  const [dragDropList, setDragDropList] = useState(productPhotoKeys);

  const onDragComplete = (result) => {
    if (!result.destination) return;
    if (result?.destination?.index === result?.source?.index) return;

    const arr = [...dragDropList];

    const isSourceSafe = productImgDataList?.filter(
      (item) => item?.index === result?.source?.index + 1
    );

    const isDestinationSafe = productImgDataList?.filter(
      (item) => item?.index === result?.destination?.index + 1
    );

    console.log(
      "DND_result:",
      result,
      " , Destination Safe:",
      isDestinationSafe,
      " Source Safe",
      isSourceSafe
    );

    if (isDestinationSafe?.[0]?.dataURL && isSourceSafe?.[0]?.dataURL) {
      //Changing the position of Array element
      let removedItem = arr.splice(result.source.index, 1)[0];
      arr.splice(result.destination.index, 0, removedItem);

      // updating productImgDataList array elements index
      const newArray = productImgDataList;
      let removedProductImgItem = newArray.splice(result.source.index, 1)[0];
      newArray.splice(result.destination.index, 0, removedProductImgItem);

      const updatedList = [];

      newArray.forEach((element, index) => {
        const data = {
          ...element,
          selector: SELECTOR_LIST[index],
          index: index + 1,
        };

        updatedList.push(data);
      });

      //Updating the list
      setDragDropList(arr);
      setProductImgDataList(updatedList);

      console.log("newArray: ", newArray);
      console.log("updatedList: ", updatedList);
    }
  };

  return (
    <Box>
      {console.log("ProductImageListDND_data", data)}
      {console.log(
        "ProductImageListDND_productImgDataList",
        productImgDataList
      )}

      <DragDropContext onDragEnd={onDragComplete}>
        <Droppable
          droppableId="drag-drop-list"
          direction={matches ? "horizontal" : "vertical"}
          // direction="horizontal"
        >
          {(provided, snapshot) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: matches ? "row" : "row",
                flexWrap: "wrap",
                alignItems: "center",
                gap: { md: "25px", xs: "10px" },
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {dragDropList.map((item, index) => (
                <Draggable key={item.id} draggableId={item.key} index={index}>
                  {(provided) => (
                    <div
                      className="item-card"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <FilePickerInput
                        label=""
                        subText1="100 x 75 pixels "
                        subText2="(Ratio 4:3 , size : 20 - 100 Kb)"
                        ratio={4 / 3}
                        cropedimage={data?.[item?.key]}
                        setCropedImage={(dataURL, fileDetails) => {
                          onProductImageUpdate({
                            selector: item?.key,
                            dataURL,
                            fileDetails,
                            index: item?.id,
                          });
                          onValueChange(item?.key, dataURL);
                        }}
                        setLogoChange={() => {}}
                        onDelete={(value) => {
                          onDeleteProductPhoto(data?.[item?.key]);
                          onValueChange(item?.key, value);
                        }}
                        hideLabel
                        // error={
                        //   data?.errors?.productImg && "Upload product image"
                        // }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}

export default ProductImageListDND;
