import { Box, Button, Grid, Typography } from "@mui/material";
import {
  BGColor02,
  blackShade05,
  blackShade19,
  blueColor04,
  greyColor40,
  greyColor9,
  heroSectionText,
  lightBlueColor05,
  orangeStatusColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import FormInputBox from "../../../components/Input/FormInputBox";
import { useSelector } from "react-redux";
import { useState } from "react";
import { checkUser } from "../../../api/auth/loginApi";
import { preferredDiscountGetEmailOrPhoneNumberApi } from "../../../service/api_calls/preferredDiscounts/PreferredDiscountsApi";
import {
  addNewBusinessOwner,
  deleteBusinessDelegates,
  sendBusinessWhatsappNotificationApi,
  updateBusinessDelegates,
  updateBusinessOwner,
} from "../../../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";
import { prepareBusinessWhatsappMessage } from "../../../utils/postDataHelpers/businessWhatsappMsgHelper";
import { getBranchInfoAPI } from "../../../service/api_calls/multiBranchCatalog/multiBranchCatalogAPIs";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

function AddNewOwner({
  ownerInfo,
  delegates,
  managersNotRegistered,
  onClose,
  onSuccess,
}) {
  const [currentPage, setCurrentPage] = useState("add-phone");

  const [isLoading, setIsLoading] = useState(false);

  const [newPhoneNo, setNewPhoneNo] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [userDetails, setUserDetails] = useState(null);
  const [userName, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState("");

  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  function doesProfileIdExist(profileId, arrayOfObjects) {
    return (
      arrayOfObjects?.find((obj) => obj.profileId === profileId) !== undefined
    );
  }

  function doesPhoneNoExist(phoneNo, arrayOfObjects, key) {
    return (
      arrayOfObjects?.find(
        (obj) => obj?.[key]?.replace("+91", "") === phoneNo
      ) !== undefined
    );
  }

  const verifyPhoneNumber = async () => {
    if (newPhoneNo?.length < 10) {
      setPhoneError("Enter valid phone number");
      return;
    }

    if (ownerInfo?.phone?.replace("+91", "") == newPhoneNo) {
      setPhoneError("This user already has Super Admin privileges");
      return;
    }

    if (doesPhoneNoExist(newPhoneNo, managersNotRegistered, "phoneNo")) {
      setPhoneError(
        "This user has been granted Manager access already. Yet to register in CostBo"
      );
      return;
    }

    setIsLoading(true);

    const checkNumberRes = await checkUser({ phone: newPhoneNo });

    if (checkNumberRes) {
      console.log("checkNumberRes", checkNumberRes);

      if (checkNumberRes?.data?.sr) {
        // checking if the selected business is a head quarters
        if (
          activeBusinessInfo?.mainBranch &&
          !activeBusinessInfo?.mainBranchId
        ) {
          setCurrentPage("user-not-registered");
          setIsLoading(false);
          return;
        }

        setCurrentPage("new-user");
        setIsLoading(false);
        return;
      }

      if (!checkNumberRes?.data?.sr && checkNumberRes?.data?.pn2?.length > 0) {
        const profileInfo = await preferredDiscountGetEmailOrPhoneNumberApi({
          phoneNumber: newPhoneNo,
        });

        console.log("profileInfo", profileInfo);
        setUserDetails(profileInfo?.accountSummary);
        setCurrentPage("existing-user");
        setIsLoading(false);
        return;
      }
    }
  };

  const newUserSwitchOwner = async () => {
    if (userName === "") {
      setUserNameError("Enter user name");
      return;
    }

    setIsLoading(true);
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const payload = {
      businessId,
      profileId: "",
      phoneNo: "+91" + newPhoneNo,
      name: userName,
      role: "OWNER",
      type: "1",
    };

    const switchOwner = await addNewBusinessOwner(payload);

    if (switchOwner) {
      // Sending Whatsapp notification to New Owner
      const notificationPostData = prepareBusinessWhatsappMessage({
        businessId,
        code: "B013",
        ownerName: userName,
        brandName: activeBusinessInfo?.name || "",
        phoneNumber: "+91" + newPhoneNo,
      });

      await sendBusinessWhatsappNotificationApi(notificationPostData);

      setIsLoading(false);
      onSuccess();
    }
  };

  const existingUserSwitchOwner = async (profileId, phoneNumber) => {
    setIsLoading(true);
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const payload = {
      businessId,
      data: {
        newOwnerProfileID: profileId,
        newOwnerPhoneNum: "+91" + phoneNumber,
      },
    };

    const switchOwner = await updateBusinessOwner(payload);

    if (switchOwner) {
      if (!doesProfileIdExist(ownerInfo?.profileId, delegates)) {
        setIsLoading(true);
        const updateDelegate = {
          newAdminDelegatePhoneNum: ownerInfo?.phone || "",
          businessId: businessId,
          type: "ADD",
          delegates: [
            {
              profileId: ownerInfo?.profileId || "",
              name: ownerInfo?.name || "",
              phone: ownerInfo?.phone || "",
              email: ownerInfo?.email || "",
              country: "India",
              status: "active",
              role: "Admin",
              picURL: ownerInfo?.picURL || "",
              type: 2,
              privileges: [],
            },
          ],
        };
        const updateOwner = await updateBusinessDelegates(updateDelegate);

        // Sending Whatsapp notification to New Owner
        const notificationPostData = prepareBusinessWhatsappMessage({
          businessId,
          code: "B012",
          ownerName: userDetails?.name,
          brandName: activeBusinessInfo?.name || "",
          phoneNumber: "+91" + phoneNumber,
        });

        await sendBusinessWhatsappNotificationApi(notificationPostData);

        // Sending Removed Whatsapp notification to Old Owner
        const removedNotificationData = prepareBusinessWhatsappMessage({
          businessId,
          code: "B015",
          ownerName: ownerInfo?.name,
          brandName: activeBusinessInfo?.name || "",
          phoneNumber: ownerInfo?.phone || "",
        });

        await sendBusinessWhatsappNotificationApi(removedNotificationData);

        setIsLoading(false);
        console.log("SWITCH_OWNER_PAYLOAD", payload, updateDelegate, ownerInfo);
      }

      if (doesProfileIdExist(profileId, delegates)) {
        setIsLoading(true);
        const deleteDelegate = {
          businessId: businessId,
          type: "REMOVE",
          delegates: [
            {
              profileId: profileId || "",
              name: userDetails?.name || "",
              phone: "+91" + newPhoneNo,
              email: userDetails?.email || "",
              country: "India",
              status: "active",
              role: "Admin",
              picURL: "",
              type: 2,
              privileges: [],
            },
          ],
        };
        setIsLoading(false);

        const deleteOwner = await deleteBusinessDelegates(deleteDelegate);

        // // Sending Removed Whatsapp msg: removed as delegate
        // const removedNotificationData = prepareBusinessWhatsappMessage({
        //   businessId,
        //   code: "B016",
        //   ownerName: userDetails?.name || "",
        //   brandName: activeBusinessInfo?.name || "",
        //   phoneNumber: "+91" + newPhoneNo,
        // });

        // await sendBusinessWhatsappNotificationApi(removedNotificationData);

        console.log("SWITCH_OWNER_PAYLOAD", payload, deleteDelegate, ownerInfo);
      }

      // Check if the select Business is Main Branch
      if (activeBusinessInfo?.mainBranch && !activeBusinessInfo?.mainBranchId) {
        const branchListData = await getBranchInfoAPI(businessId);

        //get all sub-branch Info
        const branchInfo = branchListData?.details?.branchInfo || [];
        const postDataList = [];

        const tempAddList = [];
        const tempExistingList = [];

        branchInfo.forEach((info) => {
          //Check if Branch is Active & the new main branch owner is not owner of sub-branch
          if (
            // info?.status?.toLowerCase() === "active" &&
            profileId !== info?.owner?.profileId
          ) {
            // check if new owner is already a delegate of sub-branch
            if (!doesProfileIdExist(profileId, info?.delegates || [])) {
              postDataList.push({
                newAdminDelegatePhoneNum: `+91${newPhoneNo}` || "",
                businessId: info.branchId,
                type: "ADD",
                delegates: [
                  {
                    profileId: profileId || "",
                    name: userDetails?.name || "",
                    phone: "+91" + newPhoneNo,
                    email: userDetails?.email || "",
                    country: "India",
                    status: "active",
                    role: "Admin",
                    picURL: "",
                    type: 2,
                    privileges: [],
                  },
                ],
              });

              tempAddList.push(info?.businessName);
            } else {
              tempExistingList.push(info?.businessName);
            }
          } else {
            tempExistingList.push(info?.businessName);
          }
        });

        console.log("MAIN_BRANCH____branchInfo:", branchInfo);
        console.log("MAIN_BRANCH____postDataList:", postDataList);
        console.log("MAIN_BRANCH____delegate_updated_for:", tempAddList);
        console.log(
          "MAIN_BRANCH____user_already_delegate_or_owner_or_!active_for:",
          tempExistingList
        );

        // Assuming updateBusinessOwner does not need to be awaited in each iteration
        await Promise.all(
          postDataList.map((postData) => updateBusinessDelegates(postData))
        );

        setIsLoading(false);
        onSuccess();
        return;
      }
    }

    setIsLoading(false);
    onSuccess();
  };

  const handleSubmit = () => {
    if (currentPage === "add-phone") {
      verifyPhoneNumber();
    } else if (currentPage === "existing-user") {
      existingUserSwitchOwner(userDetails?.profileId, newPhoneNo);
    } else if (currentPage === "new-user") {
      newUserSwitchOwner(null, newPhoneNo);
    } else {
      return false;
    }
  };

  return (
    <Box sx={styles.card}>
      <Box p="10px 15px 0">
        <Typography
          variant="h3"
          fontSize={18}
          fontWeight={600}
          lineHeight="34px"
          color={blackShade05}
          textAlign="center"
        >
          Super Admin
        </Typography>
        <Typography
          fontSize={13}
          fontWeight={500}
          color={greyColor9}
          textAlign="center"
        >
          Super Admin access will be switched to the new user and you will be
          added as a delegate to this business
        </Typography>
      </Box>

      {currentPage === "add-phone" && (
        <Box sx={styles.infoContainer}>
          <FormInputBox
            label="Phone Number"
            placeholder=""
            startIcon="+91"
            maxLength={10}
            value={newPhoneNo}
            onChange={({ target }) => {
              const number = target.value?.replace(/[^0-9]/g, "");
              setNewPhoneNo(number);
              setPhoneError("");
            }}
            error={phoneError}
          />
        </Box>
      )}

      {currentPage === "user-not-registered" && (
        <Box sx={styles.infoContainer}>
          <Grid container alignItems="flex-end" spacing={3}>
            <Grid item xs={7}>
              <FormInputBox
                label="Phone Number"
                placeholder=""
                startIcon="+91"
                maxLength={10}
                value={newPhoneNo}
                disabled
              />
            </Grid>

            <Grid item xs={5}>
              <Typography
                fontSize={12.5}
                fontWeight={500}
                color={heroSectionText}
                sx={{ textDecoration: "underline", cursor: "pointer", mb: 2 }}
                onClick={() => {
                  setCurrentPage("add-phone");
                  setUserName("");
                  setUserNameError("");
                }}
              >
                Change Phone No
              </Typography>
            </Grid>
          </Grid>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            gap={2}
            p={1.5}
          >
            <Box sx={styles.infoIconStyle}>
              <WarningAmberRoundedIcon
                sx={{ fontSize: "22px", color: orangeStatusColor }}
              />
            </Box>

            <Typography component="p" sx={styles.infoText}>
              This user is currently not registered in CostBo. Main branch Super
              Admin privileges can only be transferred to a registered CostBo
              user.
            </Typography>
          </Box>
        </Box>
      )}

      {currentPage === "new-user" && (
        <Box sx={styles.infoContainer}>
          <Grid container alignItems="flex-end" spacing={3}>
            <Grid item xs={7}>
              <FormInputBox
                label="Phone Number"
                placeholder=""
                startIcon="+91"
                maxLength={10}
                value={newPhoneNo}
                disabled
              />
            </Grid>

            <Grid item xs={5}>
              <Typography
                fontSize={12.5}
                fontWeight={500}
                color={heroSectionText}
                sx={{ textDecoration: "underline", cursor: "pointer", mb: 2 }}
                onClick={() => {
                  setCurrentPage("add-phone");
                  setUserName("");
                  setUserNameError("");
                }}
              >
                Change Phone No
              </Typography>
            </Grid>
          </Grid>
          <FormInputBox
            label="Name"
            placeholder="Enter Name"
            required
            value={userName}
            onChange={({ target }) => {
              setUserName(target?.value);
              setUserNameError("");
            }}
            error={userNameError}
          />
          <Typography component="p" sx={styles.infoText}>
            This user is currently not registered in CostBo.
          </Typography>
          <Typography component="p" sx={styles.infoText}>
            Super Admin privilege will be transferred to the user once they
            register in CostBo
          </Typography>
        </Box>
      )}

      {currentPage === "existing-user" && (
        <Box sx={styles.infoContainer}>
          <Grid container alignItems="flex-end" spacing={3}>
            <Grid item xs={7}>
              <FormInputBox
                label="Phone Number"
                placeholder=""
                startIcon="+91"
                maxLength={10}
                value={newPhoneNo}
                disabled
              />
            </Grid>

            <Grid item xs={5}>
              <Typography
                fontSize={12.5}
                fontWeight={500}
                color={heroSectionText}
                sx={{ textDecoration: "underline", cursor: "pointer", mb: 2 }}
                onClick={() => {
                  setCurrentPage("add-phone");
                }}
              >
                Change Phone No
              </Typography>
            </Grid>
          </Grid>

          <Box mt={1}>
            <Typography fontSize={13} fontWeight={400} color={greyColor40}>
              Name
            </Typography>

            <Typography
              fontSize={13}
              fontWeight={500}
              color={blackShade19}
              mt={0.5}
            >
              {userDetails?.name}
            </Typography>
          </Box>

          <Box mt={1.5}>
            <Typography fontSize={13} fontWeight={400} color={greyColor40}>
              Email Id
            </Typography>

            <Typography
              fontSize={13}
              fontWeight={500}
              color={blackShade19}
              mt={0.5}
            >
              {userDetails?.email || "-"}
            </Typography>
          </Box>
        </Box>
      )}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p="15px"
      >
        <Button sx={[styles.actionBtn, styles.cancelBtn]} onClick={onClose}>
          Cancel
        </Button>

        <Button
          sx={[styles.actionBtn, styles.proceedBtn]}
          onClick={() => {
            handleSubmit();
          }}
          //   startIcon={
          //     isLoading && (
          //       <CircularProgress size={18} sx={{ color: whiteColor }} />
          //     )
          //   }
          disabled={isLoading || currentPage == "user-not-registered"}
        >
          {currentPage === "add-phone" ? "Continue" : "Change Admin"}
        </Button>
      </Box>
    </Box>
  );
}

export default AddNewOwner;

const styles = {
  card: {
    backgroundColor: BGColor02,
    borderRadius: "15px",
  },

  infoContainer: {
    backgroundColor: whiteColor,
    p: "15px",
    mt: "10px",
  },

  actionBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    color: blueColor04,
    border: `1px solid ${blueColor04}`,
    p: "4px 20px",
    backgroundColor: whiteColor,

    "&.Mui-disabled": {
      color: "rgba(0,0,0,0.35)",
      border: "none",
      backgroundColor: "rgba(0,0,0,0.2)",
    },
  },

  cancelBtn: {
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },

  proceedBtn: {
    color: whiteColor,
    background: blueColor04,
    border: `1px solid ${blueColor04}`,

    "&:hover": {
      color: blueColor04,
      background: whiteColor,
    },
  },

  infoText: {
    fontSize: 13,
    fontWeight: 500,
    color: blackShade19,
    textAlign: "center",
    mb: "10px",
  },

  infoIconStyle: {
    width: "38px",
    height: "38px",
    display: { xs: "none", md: "flex" },
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: lightBlueColor05,
  },
};
