import React, { useEffect, useState } from "react";
import ModalCard from "../../../components/ModalCard";
import {
  BGColor02,
  blackShade19,
  blueColor04,
  mobilePrimary,
  orangeStatusColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";

const UpdateInfoAlert = ({
  openModal,
  setOpenModal,
  selectedRowCount,
  onClick,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <ModalCard
      open={openModal}
      handleClose={() => {
        setOpenModal(false);
      }}
      width="28%"
    >
      <Box sx={styles.container}>
        <Box
          sx={{
            backgroundColor: BGColor02,
            p: 1.5,
            borderRadius: "14.4px 14.4px 0 0",
            textAlign: "center",
          }}
        >
          <Typography
            variant="body1"
            fontSize={16}
            fontWeight={600}
            color={blackShade19}
            mb={0.3}
          >
            Bulk Update Alert
          </Typography>
        </Box>

        <Box sx={{ p: "10px 15px 15px 15px" }} >
          <Box m="15px 0 25px" textAlign='center'>
            {/* <Button
              sx={styles.addIconBtn}
              variant="contained"
              disableElevation
              onClick={() => {}}
              fullWidth={!matches}
              startIcon={matches ? <InventoryRoundedIcon /> : null}
              disableRipple
            >
              Update Inventory
            </Button> */}

            <Box
              component="img"
              src="https://storage.googleapis.com/bodefaults/businessdashboardweb/inventory-btn.svg"
              alt="inventory button"
              sx={{
                width: "100%",
                height: "55px",
                objectFit: "contain",
              }}
            />
          </Box>

          <Typography
            fontSize={13.5}
            fontWeight={500}
            color={blackShade19}
            lineHeight="20px"
            textAlign='center'
            mt={-1}
            px="15px"
          >
            Please use the{" "}
            <span
              style={{ fontSize: 13.5, fontWeight: 600, color: orangeStatusColor }}
            >
              "Update Inventory"
            </span>{" "}
            button to submit the changes from the main screen
          </Typography>
        </Box>

        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          gap={4}
          sx={{
            backgroundColor: BGColor02,
            p: 2,
            borderRadius: "0 0 14.4px 14.4px",
          }}
        >
          {/* <Button
            variant="contained"
            sx={styles.backBtn}
            disableElevation
            onClick={() => {
              setOpenModal(false);
            }}
          >
            CANCEL
          </Button> */}

          <Button
            variant="contained"
            disableElevation
            sx={styles.applyBtn}
            onClick={onClick}
          >
            OKAY
          </Button>
        </Stack>
      </Box>
    </ModalCard>
  );
};

export default UpdateInfoAlert;

const styles = {
  container: {
    backgroundColor: whiteColor,
    borderRadius: 1.8,
  },

  applyBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: whiteColor,
    border: `1px solid ${blueColor04}`,
    color: blueColor04,
    p: "5px 20px",
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },

  addIconBtn: {
    padding: { md: "3px 16px", xs: "3px 14px" },
    fontSize: { md: "13px", xs: "12px" },
    fontWeight: 600,
    color: { xs: mobilePrimary, md: blueColor04 },
    backgroundColor: whiteColor,
    border: {
      xs: `1.5px solid ${mobilePrimary}`,
      md: `1.5px solid ${blueColor04}`,
    },
    "&:hover": {
      color: { xs: mobilePrimary, md: blueColor04 },
      backgroundColor: whiteColor,
    },
    cursor: "default",
  },
};
