import React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, styled, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { blackShade05, greyColor40, outOfStockColor } from "../../../configs/styles/muiThemes";

const DateWithTooltip = ({ date, message, loader }) => {
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: blackShade05,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: 11,
      textAlign: "center",
      lineHeight: "18px",
      backgroundColor: blackShade05,
    },
  }));

  return (
    <>
      <BootstrapTooltip title={message}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
            color: outOfStockColor,
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: 12, md: 12 },
              fontWeight: { xs: 400, md: 500 },
              color: loader ? greyColor40 : outOfStockColor,
              textDecoration: "underline",
            }}
          >
            {date}
          </Typography>

          <ErrorIcon />
        </Box>
      </BootstrapTooltip>
    </>
  );
};

export default DateWithTooltip;
