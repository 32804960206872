import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TableDropdown from "./TableDropdown";
import {
  getProductSubCategoryOneAPI,
  getProductSubCategoryTwoAPI,
} from "../../../service/api_calls/AddProduct/AddProductAPI";
import {
  BGColor02,
  blackShade19,
  blackShade21,
  blueColor04,
  greyColor9,
  lightBlueColor03,
  redColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { useDispatch, useSelector } from "react-redux";
import {
  bulkCategoryUpdate,
  deleteSelectedProducts,
  unselectAllProductRows,
  updateCategoryData,
} from "../../../reducers/catalogBulkUpload/catalogBulkUploadSlice";
import ErrorIcon from "@mui/icons-material/Error";
import ModalCard from "../../../components/ModalCard";
import DeleteAlert from "./Popups/DeleteAlert";
import { useNavigate } from "react-router-dom";

const QuickActions = ({
  openModal,
  setOpenModal,
  businessCategories,
  businessONDCCategories,
  productDetails,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedRowCount, deleteProductLoader, updateLoader } = useSelector(
    (state) => state.catalogBulkUpload
  );

  const [showDeleteAlert, setShowDeleteAlert] = useState({
    show: false,
    data: null,
  });

  const categoryInfo = {
    selectedBusinessCat: { name: "Select Business Category" },
    selectedProductCat: { productSubCategory1: "Select Product Category" },
    selectedProdSubCat: { productSubCategory2: "Select Product Sub Category" },
    selectedOndcCat: { name: "Select ONDC Category" },
    selectedOndcSubCat: { productSubCategory1: "Select ONDC Sub Category" },

    // dropdown list
    productCatList: [],
    productSubCatList: [],
    ondcSubCatList: [],
  };

  const [categoryData, setCategoryData] = useState(categoryInfo);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleValueChange = (key, value) => {
    setCategoryData((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    setErrorMsg((prevState) => ({
      ...prevState,
      [key]: "",
    }));
  };

  const handleBusinessCatChange = async (data) => {
    const productCatData = await getProductSubCategoryOneAPI(data?.code);

    if (productCatData?.details?.length > 0) {
      handleValueChange("productCatList", productCatData?.details);
    }

    setCategoryData((prevState) => ({
      ...prevState,
      selectedBusinessCat: data,
      selectedProductCat: categoryInfo?.selectedProductCat,
      selectedProdSubCat: categoryInfo?.selectedProdSubCat,
    }));

    setErrorMsg((prevState) => ({
      ...prevState,
      selectedBusinessCat: "",
    }));
  };

  const handleProductCatChange = async (data) => {
    const productSubCatData = await getProductSubCategoryTwoAPI({
      pcCode: data?.pcCode,
      pscCode1: data?.scCode1,
    });

    if (productSubCatData?.details?.length > 0) {
      handleValueChange("productSubCatList", productSubCatData?.details);
    }

    setCategoryData((prevState) => ({
      ...prevState,
      selectedProductCat: data,
      selectedProdSubCat: categoryInfo?.selectedProdSubCat,
    }));

    setErrorMsg((prevState) => ({
      ...prevState,
      selectedProductCat: "",
    }));
  };

  const handleOndcCatChange = async (data) => {
    const ondcCatData = await getProductSubCategoryOneAPI(data?.code);

    if (ondcCatData?.details?.length > 0) {
      handleValueChange("ondcSubCatList", ondcCatData?.details);
    }

    setCategoryData((prevState) => ({
      ...prevState,
      selectedOndcCat: data,
      selectedOndcSubCat: categoryInfo?.selectedOndcSubCat,
    }));
    setErrorMsg((prevState) => ({
      ...prevState,
      selectedOndcCat: "",
    }));
  };

  const onApply = () => {
    let errors = null;

    if (
      categoryData?.selectedBusinessCat?.name === "Select Business Category"
    ) {
      errors = { ...errors, selectedBusinessCat: "Select business category" };
    }
    if (
      categoryData?.selectedProductCat?.productSubCategory1 ===
      "Select Product Category"
    ) {
      errors = { ...errors, selectedProductCat: "Select product category" };
    }
    if (categoryData?.selectedOndcCat?.name === "Select ONDC Category") {
      errors = { ...errors, selectedOndcCat: "Select ONDC category" };
    }

    if (
      categoryData?.selectedOndcSubCat?.productSubCategory1 ===
      "Select ONDC Sub Category"
    ) {
      errors = { ...errors, selectedOndcSubCat: "Select ONDC Sub Category" };
    }

    if (errors) {
      setErrorMsg(errors);
      return;
    }

    setErrorMsg(null);

    const selectedCats = {
      businessCat:
        categoryData?.selectedBusinessCat?.name === "Select Business Category"
          ? null
          : categoryData?.selectedBusinessCat,

      productCat:
        categoryData?.selectedProductCat?.productSubCategory1 ===
        "Select Product Category"
          ? null
          : categoryData?.selectedProductCat,

      productSubCat:
        categoryData?.selectedProdSubCat?.productSubCategory2 ===
        "Select Product Sub Category"
          ? null
          : categoryData?.selectedProdSubCat,

      ondcCat:
        categoryData?.selectedOndcCat?.name === "Select ONDC Category"
          ? null
          : categoryData?.selectedOndcCat,

      ondcSubCat:
        categoryData?.selectedOndcSubCat?.productSubCategory1 ===
        "Select ONDC Sub Category"
          ? null
          : categoryData?.selectedOndcSubCat,
    };

    // dispatch(
    //   bulkCategoryUpdate({
    //     productDetails: productDetails,
    //     selectedBusinessCat: selectedCats?.businessCat,
    //     selectedProductCat: selectedCats?.productCat,
    //     selectedProductSubCat: selectedCats?.productSubCat,
    //     selectedOndcCat: selectedCats?.ondcCat,
    //     selectedOndcSubCat: selectedCats?.ondcSubCat,

    //     productCatList: categoryData?.productCatList,
    //     productSubCatList: categoryData?.productSubCatList,
    //     ondcSubCatList: categoryData?.ondcSubCatList,
    //   })
    // );
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      updateCategoryData({
        productDetails: productDetails,
        selectedBusinessCat: selectedCats?.businessCat,
        selectedProductCat: selectedCats?.productCat,
        selectedProductSubCat: selectedCats?.productSubCat,
        selectedOndcCat: selectedCats?.ondcCat,
        selectedOndcSubCat: selectedCats?.ondcSubCat,
        businessId,
      })
    );

    setOpenModal(false);
  };

  const handleDeleteProducts = async () => {
    if (deleteProductLoader) return;

    setShowDeleteAlert({ show: false, data: null });

    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      dispatch(deleteSelectedProducts({ productDetails, businessId })).then(
        (res) => {
          if (selectedRowCount === productDetails?.length) navigate(0);
        }
      );
    }
  };

  return (
    <ModalCard
      open={openModal}
      handleClose={() => {
        setOpenModal(false);
      }}
      width="40%"
    >
      <Box sx={{ backgroundColor: whiteColor, borderRadius: 1.8 }}>
        <Box
          sx={{
            backgroundColor: BGColor02,
            p: 1.5,
            borderRadius: "14.4px 14.4px 0 0",
            textAlign: "center",
          }}
        >
          <Typography
            variant="body1"
            fontSize={16}
            fontWeight={600}
            color={blackShade19}
            mb={0.3}
          >
            Assign Category{" "}
            <Typography
              variant="body1"
              fontSize={13}
              fontWeight={500}
              color={greyColor9}
            >
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: blueColor04,
                }}
              >
                {selectedRowCount} of {productDetails?.length}
              </span>{" "}
              rows selected
            </Typography>
          </Typography>
        </Box>

        <Box sx={{ p: 2, mb:1}}>
          <Typography
            variant="h6"
            fontSize={14}
            fontWeight={600}
            color={blackShade19}
            mb={1}
          >
            ONDC Categories
          </Typography>

          <Grid container spacing={3}>
            <Grid item md={6}>
              <Typography variant="body1" sx={styles.inputLabel}>
                ONDC Category <span style={styles.requiredStar}>*</span>
              </Typography>

              <TableDropdown
                value={categoryData?.selectedOndcCat?.name}
                onValueChange={(value) => {
                  handleOndcCatChange(value);
                }}
                returnFullItem
                menuList={[
                  { name: "" },
                  ...businessONDCCategories,
                ]}
                valueSelector="name"
                borderFull
                rounded
                required
                width="100%"

                // errorMsg={productInfo?.errors?.productMainCategory}
              />

              <ErrorText errorMsg={errorMsg?.selectedOndcCat} />
            </Grid>

            <Grid item md={6}>
              <Typography variant="body1" sx={styles.inputLabel}>
                ONDC Sub Category <span style={styles.requiredStar}>*</span>
              </Typography>

              <TableDropdown
                value={categoryData?.selectedOndcSubCat?.productSubCategory1}
                onValueChange={(value) => {
                  handleValueChange("selectedOndcSubCat", value);
                }}
                returnFullItem
                menuList={[
                  { productSubCategory1: "" },
                  ...categoryData?.ondcSubCatList,
                ]}
                valueSelector="productSubCategory1"
                borderFull
                rounded
                required
                width="100%"
                // errorMsg={productInfo?.errors?.productMainCategory}
              />
              <ErrorText errorMsg={errorMsg?.selectedOndcSubCat} />
            </Grid>
          </Grid>

          <Typography
            variant="h6"
            fontSize={14}
            fontWeight={600}
            color={blackShade19}
            m="20px 0 6px"
          >
            Webstore Categories
          </Typography>

          <Grid container spacing={3}>
            <Grid item md={6.01}>
              <Typography variant="body1" sx={styles.inputLabel}>
                Business Category <span style={styles.requiredStar}>*</span>
              </Typography>

              <TableDropdown
                value={categoryData?.selectedBusinessCat?.name}
                onValueChange={(value) => {
                  handleBusinessCatChange(value);
                }}
                returnFullItem
                menuList={[
                  { name: "" },
                  ...businessCategories,
                ]}
                valueSelector="name"
                borderFull
                rounded
                required
                width="100%"
                // errorMsg={productInfo?.errors?.productMainCategory}
              />
              <ErrorText errorMsg={errorMsg?.selectedBusinessCat} />
            </Grid>

            <Grid item md={6}>
              <Typography variant="body1" sx={styles.inputLabel}>
                Product Category <span style={styles.requiredStar}>*</span>
              </Typography>

              <TableDropdown
                value={categoryData?.selectedProductCat?.productSubCategory1}
                onValueChange={(value) => {
                  handleProductCatChange(value);
                }}
                returnFullItem
                menuList={[
                  { productSubCategory1: "" },
                  ...categoryData?.productCatList,
                ]}
                valueSelector="productSubCategory1"
                borderFull
                rounded
                required
                width="100%"
                // errorMsg={productInfo?.errors?.productMainCategory}
              />

              <ErrorText errorMsg={errorMsg?.selectedProductCat} />
            </Grid>

            <Grid item md={6}>
              <Typography variant="body1" sx={styles.inputLabel}>
                Product Sub Category
              </Typography>

              <TableDropdown
                value={categoryData?.selectedProdSubCat?.productSubCategory2}
                onValueChange={(value) => {
                  handleValueChange("selectedProdSubCat", value);
                }}
                returnFullItem
                menuList={[
                  { productSubCategory2: "" },
                  ...categoryData?.productSubCatList,
                ]}
                valueSelector="productSubCategory2"
                borderFull
                rounded
                required
                width="100%"
                // errorMsg={productInfo?.errors?.productMainCategory}
              />
            </Grid>
          </Grid>
        </Box>

        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          sx={{
            backgroundColor: BGColor02,
            p: 2,
            borderRadius: "0 0 14.4px 14.4px",
          }}
        >
          <Button
            variant="contained"
            sx={styles.backBtn}
            disableElevation
            onClick={() => {
              setOpenModal(false);
            }}
          >
            CANCEL
          </Button>

          <Button
            variant="contained"
            disableElevation
            sx={styles.applyBtn}
            onClick={() => {
              onApply();
            }}
            startIcon={
              updateLoader ? (
                <CircularProgress color="inherit" size={14} />
              ) : null
            }
          >
            Apply To{" "}
            {selectedRowCount === 1
              ? `${selectedRowCount} Row`
              : `${selectedRowCount} Rows`}
          </Button>

          {/* <Button
              variant="outlined"
              disableElevation
              sx={styles.uncheckBtn}
              onClick={() => {
                dispatch(unselectAllProductRows({ productDetails }));
              }}
            >
              UNCHECK ALL
            </Button> */}

          {/* <Button
              variant="contained"
              disableElevation
              sx={{ ...styles.applyBtn, ...styles.deleteBtn }}
              onClick={() => {
                setShowDeleteAlert({
                  show: true,
                  data: {
                    message: `Are you sure you want to delete the selected ${
                      selectedRowCount === 1
                        ? `${selectedRowCount} Row?`
                        : `${selectedRowCount} Rows?`
                    }`,
                  },
                });
              }}
              startIcon={
                deleteProductLoader ? (
                  <CircularProgress color="inherit" size={14} />
                ) : null
              }
            >
              DELETE{" "}
              {selectedRowCount === 1
                ? `${selectedRowCount} Row`
                : `${selectedRowCount} Rows`}
            </Button> */}
        </Stack>
      </Box>
    </ModalCard>
  );
};

export default QuickActions;

const ErrorText = ({ errorMsg }) => {
  if (!errorMsg) return null;

  return (
    <Typography
      variant="body1"
      fontSize={11.5}
      fontWeight={500}
      color={redColor}
      mt={0.4}
    >
      <ErrorIcon sx={{ fontSize: 13, mr: 0.3, mt: -0.3 }} /> {errorMsg}
    </Typography>
  );
};

const styles = {
  backBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: whiteColor,
    border: `1px solid ${blackShade19}`,
    color: blackShade19,
    p: "5px 20px",
    "&:hover": {
      color: blackShade19,
      backgroundColor: whiteColor,
    },
  },

  applyBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: blueColor04,
    border: `1px solid ${blueColor04}`,
    color: whiteColor,
    p: "5px 20px",
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
    },
  },

  uncheckBtn: {
    fontSize: 13,
    fontWeight: 500,
    border: `1.5px solid ${blueColor04}`,
    color: blueColor04,
    p: "4px 30px",
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
      border: `1.5px solid ${blueColor04}`,
    },
  },

  requiredStar: { fontSize: 12, color: redColor },

  deleteBtn: {
    border: `1.5px solid ${redColor}`,
    backgroundColor: redColor,
    "&:hover": {
      color: redColor,
      backgroundColor: whiteColor,
    },
  },

  inputLabel: {
    fontSize: 12,
    fontWeight: 400,
    mb: 0.3,
  },
};
