import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blackShade22,
  blueColor04,
  greyColor20,
  greyColor8,
  menuGradient,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { BGColor02 } from "../../../configs/styles/muiThemes";

function EcomAlertModalContent({ onCancel, onProceed }) {
  const CHECK_LIST = [
    "Warehouse address",
    "Delivery Methods preferred",
    "COD Terms (if applicable)",
    "Discount offered to customers",
    "Cashback information",
    "Return Policy & Other Info",
    "Bank details for settlement",
    "Business hours",
    "Delivery Partner info",
  ];
  return (
    <Box sx={styles.card}>
      <Typography
        variant="h3"
        fontSize={16}
        fontWeight={600}
        lineHeight="25px"
        color={blackShade05}
        textAlign="center"
        p="15px 50px"
      >
        You will need the following details to update E-Commerce Info
      </Typography>

      <Box
        sx={{
          backgroundColor: whiteColor,
          p: "15px",
        }}
      >
        <Grid container>
          {CHECK_LIST?.map((item, index) => (
            <Grid item md={6}>
              <Typography
                key={`checklist_point_${index}`}
                component="p"
                fontSize={12.5}
                fontWeight={500}
                color={greyColor20}
                lineHeight="26px"
                mb="2px"
              >
                &#x2022; {item}
              </Typography>
            </Grid>
          ))}
        </Grid>

        <Typography
          variant="h6"
          fontSize={13}
          fontWeight={600}
          lineHeight="22px"
          color={blackShade05}
          m="10px 0 2px 0"
        >
          If you have all the above information handy, please proceed else you
          can login back and fill the details
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p="15px"
      >
        <Button sx={[styles.actionBtn, styles.cancelBtn]} onClick={onCancel}>
          CANCEL
        </Button>

        <Button sx={styles.proceedBtn} onClick={onProceed}>
          PROCEED
        </Button>
      </Box>
    </Box>
  );
}

export default EcomAlertModalContent;

const styles = {
  card: {
    backgroundColor: BGColor02,
    borderRadius: "15px",
  },

  actionBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    color: blueColor04,
    border: `1px solid ${blueColor04}`,
    p: "4px 35px",
  },

  cancelBtn: {
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },

  proceedBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    color: whiteColor,
    background: blackShade22,
    p: "5px 35px",
    border: "none",

    "&:hover": {
      color: blackShade22,
      background: whiteColor,
      border: `1px solid ${blackShade22}`,
    },
  },
};
