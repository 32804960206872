import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blueColor04,
  greyColor6,
  greyColor8,
  whiteColor,
} from "../../../../configs/styles/muiThemes";
import FormInputBox from "../../../../components/Input/FormInputBox";

function ThroughDeliveryAgent({
  deliveryAgentData,
  onValueChange,
  handleShipmentInfo,
  loader,
}) {
  return (
    <Box>
      <Typography
        fontSize={14}
        fontWeight={600}
        color={blackShade05}
        mt="4px"
        mb="10px"
        lineHeight={"20px"}
      >
        Through Delivery Agent
      </Typography>

      <Grid container spacing={4}>
        <Grid item md={3} xs={12}>
          <FormInputBox
            label={"Delivery Person Number"}
            value={deliveryAgentData?.deliveryPersonPhone}
            onChange={({ target }) => {
              const value = target.value
                .replace(/[^0-9.]/g, "")
                .replace(/(\..*)\./g, "$1");
              onValueChange("deliveryPersonPhone", value);
            }}
            error={deliveryAgentData?.errors?.deliveryPersonPhone}
            maxLength={10}
            required
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormInputBox
            label={"Delivery Person Name "}
            value={deliveryAgentData?.deliveryPersonName}
            onChange={({ target }) => {
              onValueChange("deliveryPersonName", target.value);
            }}
            error={deliveryAgentData?.errors?.deliveryPersonName}
            required
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography
            variant="h3"
            fontSize={13}
            color={greyColor6}
            fontWeight={400}
            mb={"0px"}
            sx={{
              display: "inline",
            }}
          >
            Delivery ETA Date & Time{" "}
          </Typography>

          <Typography variant="body1" sx={{ display: "inline", color: "#f00" }}>
            *
          </Typography>
          <Box display="flex" gap={3}>
            <Box flex={1}>
              <FormInputBox
                inputType={"date"}
                value={deliveryAgentData?.etaDeliveryDate}
                onChange={({ target }) => {
                  onValueChange("etaDeliveryDate", target.value);
                }}
                error={deliveryAgentData?.errors?.etaDeliveryDate}
              />
            </Box>
            <Box flex={1}>
              <FormInputBox
                inputType={"time"}
                value={deliveryAgentData?.etaDeliveryTime}
                onChange={({ target }) => {
                  onValueChange("etaDeliveryTime", target.value);
                }}
                error={deliveryAgentData?.errors?.etaDeliveryTime}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormInputBox
            label={"Notes"}
            value={deliveryAgentData?.notes}
            onChange={({ target }) => {
              onValueChange("notes", target.value);
            }}
            error={deliveryAgentData?.errors?.notes}
          />
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        justifyContent={{ md: "flex-end", xs: "center" }}
        mt={3}
      >
        <Button
          sx={styles.actionBtn}
          onClick={handleShipmentInfo}
          disabled={loader}
        >
          Send Shipping Info
        </Button>
      </Box>
    </Box>
  );
}

export default ThroughDeliveryAgent;

const styles = {
  actionBtn: {
    padding: "5px 24px",
    fontSize: "14px",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: blueColor04,
    border: "1px solid " + blueColor04,
    "&:hover": { color: blueColor04, backgroundColor: whiteColor },
  },
};
