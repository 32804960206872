import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackShade05,
  blackShade159,
  blueColor04,
  blueColor05,
  deleteBtnColor,
  greenColor3,
  greyColor11,
  greyColor21,
  greyColor28,
  greyColor29,
  greyColor30,
  greyColor50,
  greyColor6,
  heroSectionText,
  lightBlueColor01,
  logoImgborder1,
  mobilePrimary,
  orangeStatusColor,
  outOfStockColor,
  tableHeaderColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import {
  cancelCompleteOrderApi,
  getCancelnationListDetails,
  getCartInfoApi,
  partialCompleteOrderApi,
} from "../../../service/api_calls/orders/OrdersApi";
import moment from "moment";
import {
  addThousandsSeparator,
  generateRandomString,
  getBGColorByOrderStatus,
  getOrderStatusText,
  getOrderType,
} from "../../../utils/helper";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import RadioOptionWithLabel from "../../../components/Radio/RadioOptionWithLabel";
import RadiosBtnWithLabel from "../../../components/Input/RadiosBtnWithLabel";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import QuantityPickerInput from "../../../components/Input/QuantityPickerInput";
import { ORDER_CANCEL_REASONS } from "../../../utils/data";
import ModalCard from "../../../components/ModalCard";
import ConfirmationAlert from "../Popups/Components/ConfirmationAlert";
import { getProfileId } from "../../../utils/authHelpers";

function CancelOrderContent({
  orderData,
  handleClose,
  orderShipStatus,
  getOndcBapNameBySubscriberId,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [cartInfo, setCartInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const [isCompleteCancel, setIsCompleteCancel] = useState("");
  const [cancelReason, setCancelReason] = useState(null);

  const [productList, setProductList] = useState([]);
  const [cancelReasonList, setCancelReasonList] = useState([]);

  const [errors, setErrors] = useState(null);
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  const [confirmationPopup, setConfirmationPopup] = useState({
    show: false,
    cancelledQty: "",
  });

  const orderType = getOrderType(orderData);

  const getCartData = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    setLoading(true);

    if (orderData?.cartId) {
      const response = await getCartInfoApi({
        cartId: orderData?.cartId,
        businessId,
      });

      if (response?.cart) {
        setCartInfo(response?.cart || null);
        setProductList(response?.cart?.productList || []);
        setLoading(false);
        return;
      }
    }

    setLoading(false);
  };

  const getCancelationListData = async () => {
    setLoading(true);

    const isNotPending =
      orderShipStatus === "readyToShip" || orderShipStatus === "transit";
    const isNotPendingReadyToShip = orderShipStatus === "transit";
    const isONDCOrder = !orderData?.cartType && orderData?.ondcCart;

    if (orderData?.cartId) {
      let paramData = {
        codeUseBy: isNotPendingReadyToShip ? "LSP" : "SNP",
        purpose: "cancellation",
      };

      const response = await getCancelnationListDetails(paramData);

      if (response) {
        setCancelReasonList(response?.details || null);
        setLoading(false);
        return;
      }
    }

    setLoading(false);
  };

  const handleCancelTypeChange = (type) => {
    if (type === "no") {
      const newProductArr = productList?.map((item) => ({
        ...item,
        cancelledQty: Number(item?.currentQuantity),
        isSelected: false,
        priceAfterCancel: Number(item?.productTotalPrice),
      }));
      setProductList(newProductArr);
    } else {
      const newProductArr = productList?.map((item) => ({
        ...item,
        isSelected: true,
        priceAfterCancel: Number(item?.productTotalPrice),
      }));
      setProductList((prev) => [...newProductArr]);
    }

    setIsCompleteCancel(type);
  };

  //   Complete Order Cancel
  const completeOrderCancel = async (data) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    setIsCancelLoading(true);

    const postData = {
      businessId,
      cartId: orderData?.cartId,
      data,
    };

    console.log("CANCEL_POST:", postData);

    const response = await cancelCompleteOrderApi({
      businessId,
      cartId: orderData?.cartId,
      data,
    });

    console.log("COMPLETE_CANCEL_RES__:", response);

    if (response) {
      setIsCancelLoading(false);
      setConfirmationPopup({
        show: false,
        cancelledQty: "",
      });
      handleClose();
    }
  };

  //   Partial Order Cancel
  const partialOrderCancel = async (canceledBy) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    const cancelProducts = [];
    let totalCancelledQty = 0;

    const cancelId = "C" + generateRandomString();

    productList.forEach((product) => {
      if (product?.isSelected) {
        const data = {
          cancelId: cancelId,
          cancelQuantity: product?.cancelledQty,
          productId: product.productId,
          cancelInfo: {
            cancelCode: cancelReason?.code,
            cancelReason: cancelReason?.reason,
            canceledBy: canceledBy,
          },
        };

        totalCancelledQty += Number(product?.cancelledQty);
        cancelProducts.push(data);
      }
    });

    if (Number(totalCancelledQty) == Number(cartInfo?.totalQuantity)) {
      completeOrderCancel({
        // notesBusiness: cancelReason,
        cancelInfo: {
          cancelCode: cancelReason?.code,
          cancelReason: cancelReason?.reason,
          canceledBy: canceledBy,
        },
        deliveryType: cartInfo?.deliveryType?.type,
        deliveryId: "none",
      });
      return;
    }

    const postData = {
      businessId,
      reason: cancelReason?.reason,
      cartId: orderData?.cartId,
      maxClaimPoint: cartInfo?.maxClaimPoint,
      cancelProducts,
      notes: "",
    };

    setIsCancelLoading(true);

    console.log("PARTIAL_CANCEL_POST_totalCancelledQty:", totalCancelledQty);
    console.log(
      "PARTIAL_CANCEL_POST_cartInfo?.totalQuantity:",
      cartInfo?.totalQuantity
    );
    console.log("PARTIAL_CANCEL_POST:", postData);

    const response = await partialCompleteOrderApi(postData);

    console.log("COMPLETE_CANCEL_RES__:", response);

    if (response) {
      setIsCancelLoading(false);
      handleClose();
      setConfirmationPopup({
        show: false,
        cancelledQty: "",
      });
    }
  };

  const handleConfirmCancel = () => {
    let errorObj = null;

    if (isCompleteCancel === "") {
      errorObj = { ...errorObj, isCompleteCancel: "Select cancel Type" };
    }

    if (!cancelReason) {
      errorObj = {
        ...errorObj,
        cancelReason: "Select reason for cancellation",
      };
    }

    if (isCompleteCancel === "no") {
      const isItemSelected = productList?.some(
        (item) => item?.isSelected === true
      );

      if (!isItemSelected) {
        errorObj = {
          ...errorObj,
          errorMsg: "Select atleast one product",
        };
      }
    }

    if (errorObj) {
      setErrors(errorObj);
    } else {
      setErrors(null);

      // API Call

      console.log("PRODUCT LIST", productList);

      if (isCompleteCancel === "yes") {
        setConfirmationPopup({
          show: true,
          cancelledQty: cartInfo?.totalQuantity,
        });
        return;
      } else {
        let cancelItems = null;

        productList.forEach((product) => {
          if (product?.isSelected) {
            cancelItems = {
              ...cancelItems,
              [product.productId]: product?.cancelledQty,
            };
          }
        });

        let totalCancelledQty = 0;

        for (const prop in cancelItems) {
          if (cancelItems.hasOwnProperty(prop)) {
            totalCancelledQty += cancelItems[prop];
          }
        }
        setConfirmationPopup({ show: true, cancelledQty: totalCancelledQty });
        return;
      }
    }
  };

  const handleCancel = () => {
    const orderType = getOrderType(orderData);
    const canceledBy =
      orderType?.orderType === "ondcOrder"
        ? cartInfo?.ondcInfo?.bppId
        : getProfileId();
    if (isCompleteCancel === "yes") {
      completeOrderCancel({
        // notesBusiness: cancelReason,
        deliveryType: cartInfo?.deliveryType?.type,
        deliveryId: "none",
        cancelInfo: {
          cancelCode: cancelReason?.code,
          cancelReason: cancelReason?.reason,
          canceledBy: canceledBy,
        },
      });
    } else {
      partialOrderCancel(canceledBy);
    }
  };

  const handleProductSelect = (product, index) => {
    if (isCompleteCancel === "no") {
      const newArr = [...productList];
      newArr[index] = {
        ...product,
        isSelected: !product?.isSelected,
      };

      setProductList(newArr);
    }
  };

  const handleAddQty = (product, index) => {
    if (isCompleteCancel === "no" && product?.isSelected === true) {
      let qty = Number(product?.cancelledQty);

      console.log("product?.currentQuantity:", product?.currentQuantity);
      console.log("product?.cancelledQty:", product?.cancelledQty);

      if (qty < product?.currentQuantity && qty > 0) {
        const newArr = [...productList];
        newArr[index] = {
          ...product,
          cancelledQty: qty + 1,
          priceAfterCancel: Number(product?.productSalePrice) * (qty + 1),
        };

        setProductList(newArr);
      }
    }
  };

  const handleRemoveQty = (product, index) => {
    if (isCompleteCancel === "no" && product?.isSelected === true) {
      let qty = Number(product?.cancelledQty);

      console.log("product?.currentQuantity:", product?.currentQuantity);
      console.log("product?.cancelledQty:", product?.cancelledQty);

      if (qty > 1) {
        const newArr = [...productList];
        newArr[index] = {
          ...product,
          cancelledQty: qty - 1,
          priceAfterCancel: Number(product?.productSalePrice) * (qty - 1),
        };

        setProductList(newArr);
      }
    }
  };

  useEffect(() => {
    getCartData();
    getCancelationListData();
    return () => {};
  }, []);

  return (
    <Box overflow="hidden">
      {loading ? (
        <Box
          sx={{
            minHeight: "100px",
            backgroundColor: whiteColor,
            borderRadius: "12px",
            pt: "20px",
          }}
        >
          <ThreeDotLoader />
        </Box>
      ) : (
        <Box sx={styles.card}>
          <ModalCard
            open={confirmationPopup.show}
            handleClose={() => {
              setConfirmationPopup({
                show: false,
                cancelledQty: "",
              });
            }}
            width="33%"
          >
            <ConfirmationAlert
              title="Order Cancel"
              msg="Are you sure you want to cancel this order ?"
              total={`₹
                ${addThousandsSeparator(
                  // Number(cartInfo?.orderTotalCost) +
                  Number(cartInfo?.orderTotalProductCost) +
                    Number(cartInfo?.convenienceFee || 0) +
                    Number(cartInfo?.packingInfo?.cost || 0) +
                    Number(cartInfo?.shippingCost || 0) -
                    Number(cartInfo?.claimedPoint || 0)
                )}`}
              originalQuantity={cartInfo?.totalQuantity || ""}
              cancelledQuantity={confirmationPopup?.cancelledQty || ""}
              handleCancel={() => {
                setConfirmationPopup({
                  show: false,
                  cancelledQty: "",
                });
              }}
              handleOk={handleCancel}
              okLoading={isCancelLoading}
            />
          </ModalCard>
          <Box
            display="flex"
            // flexDirection={{ xs: "column", md: "row" }}

            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Box>
              <Typography
                variant="h6"
                fontSize={{ xs: 14, md: 15 }}
                fontWeight={600}
                color={blackShade05}
              >
                Order Detail
              </Typography>

              <Typography
                variant="h6"
                fontSize={{ xs: 11.5, md: 14 }}
                fontWeight={500}
                color={{ md: blueColor04, xs: mobilePrimary }}
              >
                This Order is Pending Approval
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection={"column"}
              alignItems={{ xs: "flex-end", md: "flex-start" }}
              width={{ xs: "auto", md: "auto" }}
            >
              <Button
                variant="contained"
                disableElevation
                size="small"
                sx={styles.cancelIconBtn}
                onClick={() => {
                  handleConfirmCancel();
                }}
                disabled={isCancelLoading}
                startIcon={
                  isCancelLoading ? (
                    <CircularProgress size={18} sx={{ color: greyColor6 }} />
                  ) : null
                }
              >
                CONFIRM CANCEL
              </Button>

              <Typography
                component="p"
                fontSize={12}
                fontWeight={500}
                sx={{ color: "red", mt: 1 }}
              >
                {errors?.errorMsg}
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems={{ xs: "flex-start", md: "center" }}
            flexDirection={{ xs: "column", md: "row" }}
            gap={{ xs: 0, md: 3 }}
            mt={1}
          >
            <RadiosBtnWithLabel
              label="Cancel Complete Orders"
              checked={isCompleteCancel === "yes"}
              onValuesChanged={() => handleCancelTypeChange("yes")}
              labelStyles={styles.radioInputStyle}
            />
            {orderShipStatus !== "readyToShip" &&
              orderShipStatus !== "transit" && (
                <RadiosBtnWithLabel
                  label="Cancel Selected Items"
                  checked={isCompleteCancel === "no"}
                  onValuesChanged={() => handleCancelTypeChange("no")}
                  labelStyles={styles.radioInputStyle}
                />
              )}
          </Box>

          <Typography
            component="p"
            fontSize={12}
            fontWeight={500}
            sx={{ color: "red" }}
          >
            {errors?.isCompleteCancel}
          </Typography>

          <Box>
            <Grid container rowSpacing={0} columnSpacing={3}>
              <Grid item xs={12} md={5}>
                <SelectWithLabel
                  label="Reason for Cancellation"
                  value={cancelReason?.reason}
                  onValueChange={(reason) => {
                    setCancelReason(reason);
                  }}
                  returnFullItem
                  menuList={cancelReasonList || []}
                  errorMsg={errors?.cancelReason}
                  valueSelector="reason"
                />
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={{md:2,xs:1}} mt={0}>
            <Grid item xs={12} md={12}>
              <Box sx={styles.orderInfoBox}>
                <Box>
                  <Box mb={{ md: 0.8, xs: 0.5 }}>
                    <Typography variant="h6" sx={styles.orderIdText}>
                      Order ID:{" "}
                      <Typography
                        variant="h6"
                        sx={{ ...styles.orderIdText, fontWeight: 600 }}
                      >
                        {cartInfo?.orderId}
                      </Typography>
                    </Typography>
                  </Box>

                  {orderType?.orderType == "ondcOrder" && (
                    <>
                      <Box mb={{ md: 0.8, xs: 0.5 }}>
                        <Typography variant="h6" sx={styles.orderIdText}>
                          ONDC Order ID:{" "}
                          <Typography
                            variant="h6"
                            sx={{ ...styles.orderIdText, fontWeight: 600 }}
                          >
                            {orderData?.ondcOrderId || ""}
                          </Typography>
                        </Typography>
                      </Box>

                      <Box mb={{ md: 0.8, xs: 0.5 }}>
                        <Typography variant="h6" sx={styles.orderIdText}>
                          ONDC Buyer App:{" "}
                          <Typography
                            variant="h6"
                            sx={{ ...styles.orderIdText, fontWeight: 600 }}
                          >
                            {getOndcBapNameBySubscriberId(orderData?.ondcBapId)}
                          </Typography>
                        </Typography>
                      </Box>
                    </>
                  )}

                  <Typography variant="h6" sx={styles.orderIdText}>
                    Order Date:{" "}
                    <Typography
                      variant="h6"
                      sx={{ ...styles.orderIdText, fontWeight: 600 }}
                    >
                      {moment(cartInfo?.orderCreatedOn).format("MMM Do, YYYY")}
                    </Typography>
                  </Typography>
                </Box>

                <Box
                  sx={styles.statusTag(
                    getBGColorByOrderStatus(cartInfo?.orderStatus)
                  )}
                >
                  {getOrderStatusText(cartInfo?.orderStatus)}
                </Box>
              </Box>
            </Grid>

            {matches ? null : (
              <>
                <Grid item xs={12} md="none">
                  <Typography
                    variant="h3"
                    fontSize={15}
                    fontWeight={600}
                    color={blackShade05}
                    sx={{
                      textAlign: { md: "none", xs: "left" },
                    }}
                  >
                    Product Overview
                  </Typography>
                </Grid>
                <Grid item xs={12} md="none">
                  {/* <Box sx={styles.summaryCard}> */}
                  {productList?.map((item, index) => {
                    const savings =
                      Number(item?.productOriginalPrice) *
                        Number(item?.currentQuantity) -
                      Number(item?.productSalePrice) *
                        Number(item?.currentQuantity);

                    return (
                      <Box sx={styles.summaryCard} key={index}>
                        {/* <Box sx={styles.productRow} key={item?.productId}> */}
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Box
                              display="flex"
                              alignItems="center"
                              gap="12px"
                              // ml={2}
                            >
                              <Box ml={-2}>
                                <Checkbox
                                  checked={Boolean(item?.isSelected)}
                                  size="large"
                                  sx={{
                                    color: greyColor21,
                                    "&.Mui-checked": {
                                      color: blackShade159,
                                    },
                                    ml: 1,
                                  }}
                                  onChange={() => {
                                    handleProductSelect(item, index);
                                  }}
                                />
                              </Box>
                              <Box
                                component="img"
                                src={item?.productPicURL}
                                alt={item?.productName}
                                sx={styles.productImg}
                              />

                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  fontSize: "13px",
                                  fontWeight: 600,
                                }}
                              >
                                {item?.productName}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: { md: "12px", xs: "11px" },
                                  mb: 1,
                                }}
                              >
                                UNIT PRICE
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹{addThousandsSeparator(item?.productSalePrice)}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: { md: "12px", xs: "11px" },
                                  mb: 1,
                                }}
                              >
                                QUANTITY
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                {item?.currentQuantity}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: { md: "12px", xs: "11px" },
                                  mb: 1,
                                }}
                              >
                                CANCELED QUANTITY
                              </Typography>
                              <Box sx={styles.centerText}>
                                {isCompleteCancel === "no" ? (
                                  <QuantityPickerInput
                                    value={item?.cancelledQty}
                                    onAdd={() => {
                                      handleAddQty(item, index);
                                    }}
                                    onRemove={() => {
                                      handleRemoveQty(item, index);
                                    }}
                                    disabled={!item?.isSelected}
                                  />
                                ) : (
                                  item?.currentQuantity
                                )}
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={styles.centerText}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  ...styles.productInfoText,
                                  color: greyColor6,
                                  fontSize: { md: "12px", xs: "11px" },
                                  mb: 1,
                                }}
                              >
                                PRICE
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹
                                {addThousandsSeparator(
                                  isCompleteCancel !== "no"
                                    ? item?.productTotalPrice
                                    : item?.priceAfterCancel
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </Grid>
              </>
            )}
            <Grid item xs={12} md={12}>
              {matches ? (
                <Box sx={styles.table}>
                  <Box sx={styles.tableHeader}>
                    <Grid container>
                      <Grid item xs={4.6}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          PRODUCT
                        </Typography>
                      </Grid>

                      <Grid item xs={2.3}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          ORIGINAL QUANTITY
                        </Typography>
                      </Grid>

                      <Grid item xs={2.8}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          CANCELLED QUANTITY
                        </Typography>
                      </Grid>

                      <Grid item xs={2.3}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          PRICE
                        </Typography>
                      </Grid>

                      {/* <Grid item xs={2}>
                        <Typography variant="h4" sx={styles.tableHeaderText}>
                          SAVINGS
                        </Typography>
                      </Grid> */}
                    </Grid>
                  </Box>

                  {productList?.map((item, index) => {
                    const savings =
                      Number(item?.productOriginalPrice) *
                        Number(item?.currentQuantity) -
                      Number(item?.productSalePrice) *
                        Number(item?.currentQuantity);

                    return (
                      <Box sx={styles.productRow} key={item?.productId}>
                        <Grid container spacing={2}>
                          <Grid item xs={0.5}>
                            <Checkbox
                              checked={Boolean(item?.isSelected)}
                              size="large"
                              sx={{
                                color: greyColor21,
                                "&.Mui-checked": {
                                  color: blackShade159,
                                },
                                ml: 1,
                              }}
                              onChange={() => {
                                handleProductSelect(item, index);
                              }}
                            />
                          </Grid>

                          <Grid item xs={4.5}>
                            <Box
                              display="flex"
                              alignItems="center"
                              gap="10px"
                              ml={2}
                            >
                              <Box
                                component="img"
                                src={item?.productPicURL}
                                alt={item?.productName}
                                sx={styles.productImg}
                              />

                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                {item?.productName}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={2}>
                            <Box sx={styles.centerText}>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                {item?.currentQuantity}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={2.5}>
                            <Box sx={styles.centerText}>
                              {isCompleteCancel === "no" ? (
                                <QuantityPickerInput
                                  value={item?.cancelledQty}
                                  onAdd={() => {
                                    handleAddQty(item, index);
                                  }}
                                  onRemove={() => {
                                    handleRemoveQty(item, index);
                                  }}
                                  disabled={!item?.isSelected}
                                />
                              ) : (
                                item?.currentQuantity
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={2.5}>
                            <Box sx={styles.centerText}>
                              <Typography
                                variant="h4"
                                sx={styles.productInfoText}
                              >
                                ₹
                                {/* {addThousandsSeparator(item?.productTotalPrice)} */}
                                {addThousandsSeparator(
                                  isCompleteCancel !== "no"
                                    ? item?.productTotalPrice
                                    : item?.priceAfterCancel
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default CancelOrderContent;

const SummaryDataRow = ({ label, value, valueColor }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb="10px"
    >
      <Typography
        variant="body1"
        fontSize={13}
        fontWeight={500}
        color={blackShade05}
      >
        {label}
      </Typography>

      <Typography
        variant="body2"
        fontSize={13.5}
        fontWeight={600}
        color={valueColor || greyColor30}
      >
        {value}
      </Typography>
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "12px",
    padding: "16px",

    maxHeight: { md: "95vh", xs: "70vh" },
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
      // height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blackShade159,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },

  cancelIconBtn: {
    height: { xs: "30px", md: "35px" },
    padding: { md: "3px 24px", xs: "1px 6px" },
    fontSize: { xs: "11px", md: "12px" },
    fontWeight: 600,
    color: deleteBtnColor,
    backgroundColor: whiteColor,
    border: "1px solid " + deleteBtnColor,
    mr: { xs: 0, md: 5 },
    "&:hover": {
      color: whiteColor,
      backgroundColor: deleteBtnColor,
    },

    "&.Mui-disabled": {
      border: "none",
    },
  },

  orderIdText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "27px",
    color: blackShade05,
    display: "inline",
  },

  orderInfoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "12px",
    border: `1px solid ${greyColor28}`,
    p: "10px 0 10px 10px",
  },

  statusTag: (bgColor) => ({
    backgroundColor: bgColor || lightBlueColor01,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "20px",
    color: whiteColor,
    p: "0 15px 0 20px",
    position: "relative",

    "&::before": {
      content: '" "',
      width: "15px",
      height: "28px",
      backgroundColor: whiteColor,
      rotate: "40deg",
      position: "absolute",
      left: -3,
      top: -8,
    },
  }),

  table: {
    border: `1px solid ${greyColor11}`,
    borderRadius: "7px",
  },

  tableHeader: {
    backgroundColor: tableHeaderColor,
    p: "15px 10px",
    borderRadius: "7px 7px 0 0",
  },

  tableHeaderText: {
    fontSize: 13,
    fontWeight: 500,
    color: greyColor29,
  },

  productRow: {
    p: { xs: "0 0", md: "18px 0" },
    borderBottom: "1px solid #f2f2f2",
  },

  productImg: {
    width: { xs: "50px", md: "60px" },
    height: { xs: "50px", md: "60px" },
    borderRadius: "8px",
    border: logoImgborder1,
  },

  productInfoText: {
    fontSize: 14,
    fontWeight: 500,
    color: greyColor50,
  },

  centerText: {
    height: "100%",
    display: "flex",
    alignItems: { xs: "flex-start", md: "center" },
    justifyContent: "flex-start",
  },

  summaryCard: {
    padding: "12px",
    borderRadius: "10px",
    border: { xs: `1px solid ${greyColor28}`, md: "none" },
    mt: { md: "none", xs: "none" },
    mb: { md: "none", xs: 2.2 },
  },

  summaryContainer: {
    border: `1px solid ${greyColor28}`,
    p: "12px",
    borderRadius: "12px",
  },

  radioInputStyle: {
    color: blackShade05,
    fontSize: 13,
    fontWeight: 600,
  },
};
