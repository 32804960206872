import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import InfoCardHeader from "./InfoCardHeader";
import {
  blackColor,
  blackShade05,
  blueColor02,
  blueColor04,
  blueColorBG01,
  blueColorM01,
  colorOrangeLight,
  greyColorM01,
  greyShade83,
  lightBlueColor04,
  lightBlueColor05,
  lightOrange01,
  logoImgborder1,
  mobilePrimary,
  orangeStatusColor,
  redColorBG01,
  redColorM01,
  whiteColor,
  yellowColorM01,
} from "../../../configs/styles/muiThemes";
import LabelValueText from "./LabelValueText";
import { useEffect } from "react";
import { useState } from "react";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import { addThousandsSeparator } from "../../../utils/helper";
import MobileInfoCardHeader from "../MObileInfoCards/MobileInfoCardHeader";
import PaymentsIcon from "@mui/icons-material/Payments";
function CatalogInfoCard({ productData, onClick }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box sx={styles.card}>
      {matches && (
        <>
          <Box mr="10px">
            <InfoCardHeader title="Catalog" onManageClick={onClick} />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            flexWrap={"wrap"}
            mt={{ xs: 0, md: 1 }}
            columnGap={{ xs: 2, md: 2 }}
          >
            <Box
              flex={1}
              display={{ md: "block", xs: "flex" }}
              alignItems="center"
              gap={1}
            >
              <Box sx={styles.iconCircle}>
                <Inventory2OutlinedIcon sx={styles.icon} />
              </Box>

              <LabelValueText
                label="PRODUCTS"
                value={
                  addThousandsSeparator(productData?.totalProduct, true) || 0
                }
                valueColor={matches ? blueColor04 : blackShade05}
                hideBorder={!matches}
                valueSize={14}
              />
            </Box>

            <Box
              flex={1}
              display={{ md: "block", xs: "flex" }}
              alignItems="center"
              gap={1}
            >
              <Box sx={styles.iconCircle}>
                <InventoryOutlinedIcon sx={styles.icon} />
              </Box>

              <LabelValueText
                label="ON SALE"
                value={
                  addThousandsSeparator(productData?.saleProduct, true) || 0
                }
                hideBorder={!matches}
                valueSize={14}
              />
            </Box>

            <Box
              flex={1}
              display={{ md: "block", xs: "flex" }}
              alignItems="center"
              gap={1}
            >
              <Box
                sx={{
                  ...styles.iconCircle,
                  background: { xs: lightBlueColor05, md: colorOrangeLight },
                }}
              >
                <HubOutlinedIcon
                  sx={{
                    ...styles.icon,
                    color: { xs: mobilePrimary, md: orangeStatusColor },
                  }}
                />
              </Box>

              <LabelValueText
                label="ONDC ENABLED"
                value={addThousandsSeparator(productData?.ondcCount, true) || 0}
                hideBorder
                valueColor={matches ? orangeStatusColor : blackShade05}
                valueSize={14}
              />
            </Box>
          </Box>
        </>
      )}
      {!matches && (
        <>
          <Grid container spacing={{ xs: 0, md: 3 }}>
            <Grid item md={7} xs={12}>
              <Box borderBottom={`1px solid #F5F7FC`} pb={1} mb={1}>
                <MobileInfoCardHeader title="Catalog" onManageClick={onClick} />
              </Box>
            </Grid>
            <Grid item md={7} xs={3.7}>
              <MobileStatsCard
                label={"Products"}
                value={
                  addThousandsSeparator(productData?.totalProduct, true) || 0
                }
                bgColor={lightOrange01}
                color={matches ? orangeStatusColor : yellowColorM01}
                icon={
                  <Inventory2OutlinedIcon
                    sx={styles.icon(matches ? yellowColorM01 : yellowColorM01)}
                  />
                }
                // labelColor={matches ? greyColor6 : blackColor}
                valueSize={14}
                hideMobileBorder
              />
            </Grid>
            <Grid item md={7} xs={3.7}>
              <MobileStatsCard
                label="On Sale"
                value={
                  addThousandsSeparator(productData?.saleProduct, true) || 0
                }
                bgColor={blueColorBG01}
                color={matches ? orangeStatusColor : yellowColorM01}
                icon={
                  <PaymentsIcon
                    sx={styles.icon(matches ? blueColorM01 : blueColorM01)}
                  />
                }
                // labelColor={matches ? greyColor6 : blackColor}
                valueSize={14}
                hideMobileBorder
              />
            </Grid>
            <Grid item md={7} xs={4.6}>
              <MobileStatsCard
                label="ONDC Enabled"
                value={addThousandsSeparator(productData?.ondcCount, true) || 0}
                bgColor={redColorBG01}
                color={matches ? orangeStatusColor : yellowColorM01}
                icon={
                  <HubOutlinedIcon
                    sx={styles.icon(matches ? redColorM01 : redColorM01)}
                  />
                }
                // labelColor={matches ? greyColor6 : blackColor}
                valueSize={14}
                hideMobileBorder
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}

export default CatalogInfoCard;

const MobileStatsCard = ({
  icon,
  label,
  value,
  color,
  bgColor,
  labelColor,
  valueSize,
  hideMobile,
  flex,
  hideMobileBorder,
}) => {
  return (
    <Box
      sx={{
        ...styles.cardMobile(flex),
        borderBottom: hideMobileBorder ? "" : `1px solid #F5F7FC`,
        // pb: 0.5,
        // pt: 0.5,
        width:"100%"
      }}
    >
      <Box sx={styles.iconContainer(bgColor, hideMobile)}>{icon}</Box>
      <Stack>
        <Typography
          component="h6"
          fontSize={{ xs: valueSize || 15, md: 15 }}
          color={blackColor}
          fontWeight={600}
        >
          {value}
        </Typography>
        <Typography
          variant="body2"
          fontSize={{ xs: 10, md: 11.28 }}
          color={labelColor || greyColorM01}
          fontWeight={500}
          lineHeight="20px"
          mt={-0.5}
        >
          {label}
        </Typography>
      </Stack>
    </Box>
  );
};
const styles = {
  card: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "13px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 15px", md: "10px 15px" },
    width: "100%",
  },

  iconCircle: {
    width: { md: "48px", xs: "34px" },
    height: { md: "48px", xs: "34px" },
    borderRadius: "50%",
    backgroundColor: { xs: lightBlueColor05, md: greyShade83 },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  icon: {
    fontSize: { md: "20px", xs: "17px" },
    color: { xs: mobilePrimary, md: lightBlueColor04 },
  },
  cardMobile: (flex) => ({
    display: "flex",
    alignItems: "center",
    gap: 1,
    flex: flex || 1,
  }),
  iconContainer: (bgColor, hideMobile) => ({
    width: { md: "40px", xs: "30px" },
    height: { md: "40px", xs: "30px" },
    borderRadius: "8px",
    backgroundColor: bgColor,
    display: { md: "flex", xs: hideMobile ? "none" : "flex" },
    alignItems: "center",
    justifyContent: "center",
  }),
  icon: (color) => ({ fontSize: { md: 20, xs: 17 }, color: color }),
};
