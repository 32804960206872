import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import React, { useState } from "react";
import {
  blackColor,
  blackShade19,
  blueColor04,
  borderTextbox,
  deleteBtnColor,
  descriptionColor,
  greyColor11,
  greyColor24,
  greyColor27,
  greyColor6,
  greyColor8,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ModalCard from "../../../components/ModalCard";
import { PRODUCT_SPECS_DEMO_INFO } from "../../../utils/data";
import LabelValueInputPopup from "./LabelValueInputPopup";

import AddIcon from "@mui/icons-material/Add";
import { allowOnlyEnglish } from "../../../utils/helper";

function LabelInputModal({
  productSpcs,
  icon,
  setMoreProducts,
  maxLength,
  inputType,
  // isError,
  error,
  helperText,
}) {
  const [modal, setModal] = useState(false);
  const [modalError, setModalError] = useState(false);

  return (
    <Box mb="15px !important">
      {helperText ? (
        <Typography
          variant="body1"
          component="p"
          fontSize={11}
          color={descriptionColor}
        >
          {helperText}
        </Typography>
      ) : null}

      <Box mt={1}>
        <Typography
          variant="body2"
          fontSize={{ xs: 12, md: 12.5 }}
          fontWeight={400}
          color={greyColor27}
        >
          Detailed specification will help users get a better understanding of
          the product offering
        </Typography>

        <Typography
          variant="body2"
          fontSize={{ xs: 12, md: 12.5 }}
          fontWeight={400}
          color={greyColor27}
          display="inline"
        >
          Example:{" "}
          <Typography
            variant="body2"
            fontSize={{ xs: 12, md: 12.5 }}
            fontWeight={500}
            color={{xs: mobilePrimary,md: blueColor04}}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            display="inline"
            onClick={() => {
              setModal(true);
            }}
          >
            Here
          </Typography>
        </Typography>
      </Box>

      <Box mt={1}>
        <Grid
          container
          display={"flex"}
          alignItems="center"
          justifyContent={"flex-start"}
          columnSpacing={3}
        >
          <Grid item md={2}>
            <Typography
              variant="body1"
              sx={{
                ...styles.labelText,
                display: { xs: "none", md: "block" },
              }}
            >
              Label
            </Typography>
          </Grid>

          <Grid item md={3}>
            <Typography
              variant="body1"
              sx={{
                ...styles.labelText,
                display: { xs: "none", md: "block" },
              }}
            >
              Value
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {productSpcs.map((textInput, index) => (
        <Grid
          container
          display={"flex"}
          alignItems="center"
          justifyContent={{ xs: "flex-end", md: "flex-start" }}
          columnSpacing={3}
          rowSpacing={{ xs: 2, md: 0 }}
        >
          <Grid item xs={5} md={2}>
            <Typography
              variant="body1"
              sx={{
                ...styles.labelText,
                display: { xs: "block", md: "none" },
              }}
            >
              Label
            </Typography>

            <TextField
              variant="standard"
              fullWidth
              value={textInput?.code}
              onChange={({ target }) => {
                if (modalError) setModalError(false);
                const finalValue = allowOnlyEnglish(target.value);
                let data = [...productSpcs];
                data[index] = { ...data[index], code: finalValue };
                setMoreProducts(data);
              }}
              sx={styles.inputBox}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                    {icon}
                  </InputAdornment>
                ),
                style: {
                  fontWeight: 500,
                },
                disableUnderline: true,
              }}
              inputProps={{ maxLength: maxLength }}
              type={inputType}
            />
          </Grid>

          <Grid item xs={5} md={3}>
            <Typography
              variant="body1"
              sx={{
                ...styles.labelText,
                display: { xs: "block", md: "none" },
              }}
            >
              Value
            </Typography>

            <LabelValueInputPopup
              label={textInput?.code}
              required
              disabled
              onValueChange={() => {}}
              value={textInput?.value}
              setLabelValue={(labelValues) => {
                if (modalError) setModalError(false);
                let data = [...productSpcs];
                data[index] = {
                  ...data[index],
                  value: labelValues,
                };
                setMoreProducts(data);
              }}
            />
          </Grid>

          <Grid item xs={2} md={0.6}>
            <IconButton
              onClick={() => {
                let data = [...productSpcs];

                if (data?.length === 1) {
                  setMoreProducts([{ code: "", value: [""] }]);
                  return;
                }
                data.splice(index, 1);
                setMoreProducts(data);
              }}
              sx={{ ...styles.iconBtn, ...styles.deleteBtn }}
            >
              <DeleteRoundedIcon sx={{ fontSize: { xs: 18, md: 24 } }} />
            </IconButton>
          </Grid>

          <Grid item xs={12} md={12}>
            {productSpcs?.length - 1 === index && (
              <>
                {modalError ? (
                  <Typography
                    variant="subtitle1"
                    component="p"
                    fontSize={12}
                    sx={{
                      mt: { xs: "-15px !important", md: "4px !important" },
                      mb: { xs: "4px !important", md: "0px !important" },
                      textAlign: "start !important",
                    }}
                    style={{ color: "red" }}
                  >
                    Enter valid input
                  </Typography>
                ) : null}

                {error ? (
                  <Typography
                    variant="subtitle1"
                    component="p"
                    fontSize={12}
                    sx={{
                      mt: { xs: "-15px !important", md: "4px !important" },
                      mb: { xs: "4px !important", md: "0px !important" },
                      textAlign: "start !important",
                    }}
                    style={{ color: "red" }}
                  >
                    {error}
                  </Typography>
                ) : null}

                <Button
                  sx={styles.addIconBtn}
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    if (textInput?.code && textInput?.value?.[0]) {
                      setMoreProducts([
                        ...productSpcs,
                        { code: "", value: [""] },
                      ]);
                    } else setModalError(true);
                  }}
                  startIcon={
                    <AddIcon sx={{ fontSize: { xs: "18px", md: "24px" } }} />
                  }
                >
                  Add Label
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      ))}

      <ModalCard
        open={modal}
        handleClose={() => {
          setModal(false);
        }}
        width="70%"
      >
        <Box
          backgroundColor={whiteColor}
          p={3}
          // height="40vh"
          position="relative"
          overflow="hidden"
          borderRadius="20px"
          sx={{
            maxHeight: "90vh",
            overflowY: "scroll",
          }}
        >
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={18}
            fontWeight={600}
            sx={{
              m: "12px 0 20px !important",
              textAlign: "center !important",
            }}
            style={{ color: blackColor }}
          >
            Example
          </Typography>

          <Typography
            variant="subtitle1"
            component="p"
            fontSize={16}
            fontWeight={500}
            sx={{ m: "12px 0 20px !important" }}
            style={{ color: blackColor }}
          >
            Specification
          </Typography>

          <Box display="flex" alignItems="center" mb={2}>
            <Typography
              variant="body2"
              sx={{ ...styles.demoInput, flex: { xs: 0.5, md: 0.51 } }}
            >
              Label
            </Typography>
            <Typography
              variant="body2"
              sx={{ ...styles.demoInput, flex: { xs: 1, md: 2 } }}
            >
              Value
            </Typography>
          </Box>

          {PRODUCT_SPECS_DEMO_INFO?.map((item, index) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                gap={{ xs: 1, md: 0 }}
                key={`specs_${index}`}
                mb={1.5}
              >
                <Box flex={0.5}>
                  <Typography variant="body2" sx={{ ...styles.demoInfoText }}>
                    {item?.label}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  alignItems="flex-start"
                  gap={0.7}
                  flex={{ xs: 1, md: 2 }}
                >
                  <Box>
                    <Typography
                      component="h6"
                      sx={{
                        height: "15px",
                        fontSize: "20px",
                        color: greyColor6,
                      }}
                    >
                      &#8226;
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        ...styles.demoInfoText,
                        flex: 2,
                      }}
                    >
                      {" "}
                      {item?.value}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </ModalCard>
    </Box>
  );
}

export default LabelInputModal;

const styles = {
  labelText: {
    fontSize: 13,
    color: greyColor6,
    fontWeight: 400,
    // mb: "8px !important",
    isplay: "inline",
  },

  inputBox: {
    fontSize: 14,
    borderBottom: borderTextbox,
    backgroundColor: whiteColor,
    padding: "7px 0px",
    mb: 1,
  },

  iconBtn: {
    width: "40px",
    height: "40px",
    borderRadius: "5px",
    border: `1px solid rgb(133 147 172)`,
  },

  addBtn: {
    backgroundColor: "rgb(133 147 172)",
    color: whiteColor,
    "&:hover": {
      backgroundColor: whiteColor,
      color: "rgb(133 147 172)",
    },
  },

  deleteBtn: {
    width: { xs: "30px", md: "37px" },
    height: { xs: "30px", md: "37px" },
    border: `1px solid ${deleteBtnColor}`,
    backgroundColor: whiteColor,
    color: deleteBtnColor,
    "&:hover": {
      backgroundColor: deleteBtnColor,
      color: whiteColor,
    },
  },

  demoInput: {
    fontSize: "12px",
    fontWeight: 400,
    color: greyColor6,
    padding: "8px 0 16px",
    borderBottom: `1px solid ${greyColor11}`,
  },

  demoInfoText: {
    fontSize: "13px",
    fontWeight: 400,
    color: greyColor6,
    lineHeight: "20px",
    // padding: "8px 0",
  },

  dotUi: {
    width: "6px",
    height: "6px",
    borderRadius: "8px",
    backgroundColor: greyColor6,
  },

  addIconBtn: {
    padding: "4px 14px",
    fontSize: "12px",
    fontWeight: 600,
    color: {xs: blackShade19, md:blueColor04},
    backgroundColor: whiteColor,
    border: {xs: `1.2px solid ${blackShade19}`, md: `1.5px solid ${blueColor04}`},
    "&:hover": {
      color: {xs:blackShade19, md:whiteColor},
      backgroundColor: {xs: whiteColor, md:blueColor04},
    },
  },
};
