import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import InputPreview from "../../components/Input/InputPreview";
import {
  blackColor,
  greyColor6,
  greyColor7,
  whiteColor,
} from "../../configs/styles/muiThemes";
import moment from "moment";
import StyledReadOnlySwitch from "../../components/Switch/StyledReadOnlySwitch";
import { getTimeLabel } from "../../utils/helper";

const ONDCDetailsPreview = ({
  data,
  isViewProduct,
  ondcPreview,
  viewProduct,
  productCategory,
  subProductCategory,
}) => {
  const getMnfImportDate = (date) => {
    if (date?.toString()?.includes("/")) {
      return date;
    }

    return data?.ondc?.statutoryReqsPackagedCommodities
      ?.month_year_of_manufacture_packing_import
      ? moment(
          data?.ondc?.statutoryReqsPackagedCommodities
            ?.month_year_of_manufacture_packing_import
        ).format("MM/YYYY")
      : "-";
  };

  return (
    <Box sx={styles.section}>
      <Typography
        component="h5"
        fontSize={{ xs: 14, md: 15 }}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        m={{ xs: "10px 0 !important", md: "10px 0 20px !important" }}
      >
        ONDC Parameters
      </Typography>

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        <Grid item xs={12} md={3.5}>
          {/* <InputPreview
            label="ONDC Enabled"
            value={`${data?.ondcEnabled === "yes" ? "Yes" : "No"}`}
          /> */}

          <Typography
            fontSize={13}
            fontWeight={400}
            color={greyColor6}
            mb={{ xs: 0, md: 1 }}
          >
            ONDC Enabled{" "}
          </Typography>
          <StyledReadOnlySwitch isEnabled={data?.ondcEnabled === "yes"} />
        </Grid>
      </Grid>

      {data?.ondcEnabled === "yes" && (
        <>
          <Typography
            component="h6"
            fontSize={{ xs: 13, md: 14 }}
            color={blackColor}
            fontWeight={600}
            lineHeight="20px"
            m={{ xs: "10px 0 15px !important", md: "20px 0 15px !important" }}
          >
            ONDC Product Category
          </Typography>
          <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
            <Grid item xs={12} md={3.5}>
              <InputPreview
                label="Product Category"
                value={
                  !viewProduct
                    ? data?.ondcProductCategory?.name
                    : productCategory
                }
              />
            </Grid>
            <Grid item xs={12} md={3.5}>
              <InputPreview
                label="Product Sub Category"
                value={
                  !viewProduct
                    ? data?.ondcProductSubCategory?.productSubCategory1
                    : subProductCategory
                }
              />
            </Grid>
            <Grid item xs={12} md={3.5}>
              {/* <InputPreview
                label="Customer Discount"
                value={`₹ ${data?.ondc?.discount}`}
              /> */}
            </Grid>
          </Grid>

          <Typography
            component="h6"
            fontSize={{ xs: 13, md: 14 }}
            color={blackColor}
            fontWeight={600}
            lineHeight="20px"
            m={{ xs: "10px 0 15px !important", md: "20px 0 15px !important" }}
          >
            Shipments & Discounts
          </Typography>
          <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
            <Grid item xs={12} md={3.5}>
              <InputPreview
                label="Time to Ship"
                value={
                  !viewProduct
                    ? `${data?.ondc?.timeToShip} ${
                        data?.ondcUIControl?.timeToShipUnit?.label || ""
                      }`
                    : `${parseInt(
                        data?.ondc?.timeToShip.match(/\d+/)?.[0]
                      )} ${getTimeLabel(
                        data?.ondc?.timeToShip.charAt(
                          data?.ondc?.timeToShip.length - 1
                        )
                      )}`
                }
              />
            </Grid>
            <Grid item xs={12} md={3.5}>
              <InputPreview
                label="Available on COD (Cash on Delivery)"
                value={data?.ondc?.available_on_cod ? "Yes" : "No"}
              />
            </Grid>
            <Grid item xs={12} md={3.5}>
              {/* <InputPreview
                label="Customer Discount"
                value={`₹ ${data?.ondc?.discount}`}
              /> */}
            </Grid>
          </Grid>

          {ondcPreview && (
            <>
              <Typography
                component="h6"
                fontSize={{ xs: 13, md: 14 }}
                color={blackColor}
                fontWeight={600}
                lineHeight="20px"
                m="20px 0 15px !important"
              >
                Additional Product Info
              </Typography>
              <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
                <Grid item xs={6} md={3.5}>
                  <InputPreview
                    label="Unit of measure"
                    value={
                      data?.productDisplayUnitOfMeasure?.unit === "gms"
                        ? "grams"
                        : data?.productDisplayUnitOfMeasure?.unit
                    }
                  />
                </Grid>
                <Grid item xs={6} md={3.5}>
                  <InputPreview
                    label="Value"
                    value={data?.productDisplayUnitOfMeasure?.value}
                  />
                </Grid>
              </Grid>
              <Box my={2}>
                <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
                  <Grid item xs={8} md={3}>
                    <InputPreview
                      label="Net Product Weight (in gms)"
                      value={data?.weight}
                    />
                  </Grid>

                  <Grid item xs={4} md={3}>
                    <InputPreview
                      label="In kgs"
                      value={(Number(data?.weight) / 1000).toFixed(2)}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <InputPreview
                      label="Shipping Weight"
                      value={`${data?.netProductWeight}`}
                    />
                  </Grid>
                </Grid>
              </Box>
              {/* <Grid item xs={12} md={3.5}>
                  <InputPreview
                    label="Net Product Weight"
                    value={`${data?.netProductWeight} gms`}
                  />
                </Grid> */}
              <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
                <Grid item xs={6} md={3.5}>
                  <InputPreview
                    label="Product Code"
                    value={data?.ondc?.productCode?.key}
                  />
                </Grid>
                <Grid item xs={6} md={3.5}>
                  <InputPreview
                    label=""
                    value={data?.ondc?.productCode?.value}
                    hideLabel
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Box mt="20px">
            <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
              <Grid item xs={12} md={3.5}>
                <InputPreview
                  label="Fragile"
                  value={data?.ondc?.fragile === "yes" ? "Yes" : "No"}
                />
              </Grid>
            </Grid>
          </Box>
          <Typography
            component="h6"
            fontSize={{ xs: 13, md: 14 }}
            color={blackColor}
            fontWeight={600}
            lineHeight="20px"
            m="20px 0 15px !important"
          >
            Cancel Terms
          </Typography>
          <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
            <Grid item xs={12} md={3.5}>
              <InputPreview
                label="Cancellable"
                value={
                  data?.ondc?.cancelTerms?.cancellable === "yes" ? "Yes" : "No"
                }
              />
            </Grid>

            {data?.ondc?.cancelTerms?.cancellable === "yes" && (
              <>
                <Grid item xs={12} md={8}></Grid>

                <Grid item xs={12} md={3.5}>
                  <InputPreview
                    label="Refund Eligible"
                    value={
                      data?.ondc?.cancelTerms?.refundEligible === "yes"
                        ? "Yes"
                        : "No"
                    }
                  />
                </Grid>

                {/* <Grid item xs={12} md={3.5}>
                  <InputPreview
                    label="Cancellation Fee Amount"
                    value={data?.ondc?.cancelTerms?.cancellationFeeAmount}
                  />
                </Grid> */}

                <Grid item xs={12} md={3.5}>
                  <InputPreview
                    label="Cancellation Fee %"
                    value={data?.ondc?.cancelTerms?.cancellationFeePercentage}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Typography
            component="h6"
            fontSize={{ xs: 13, md: 14 }}
            color={blackColor}
            fontWeight={600}
            lineHeight="20px"
            m="20px 0 15px !important"
          >
            Return Terms
          </Typography>
          <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
            <Grid item xs={12} md={3.5}>
              <InputPreview
                label="Return Period"
                value={
                  !viewProduct
                    ? `${data?.ondc?.returnTerms?.returnPeriod} Days`
                    : `${moment
                        .duration(data?.ondc?.returnTerms?.returnPeriod)
                        .days()} Days`
                }
              />
            </Grid>

            <Grid item xs={12} md={3.5}>
              <InputPreview
                label="Seller Pickup"
                value={
                  data?.ondc?.returnTerms?.seller_pickup_return === "yes"
                    ? "Yes"
                    : "No"
                }
              />
            </Grid>
          </Grid>

          <Typography
            component="h6"
            fontSize={{ xs: 13, md: 14 }}
            color={blackColor}
            fontWeight={600}
            lineHeight="20px"
            m="20px 0 15px !important"
          >
            Food & Related Items
          </Typography>
          <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
            <Grid item xs={12} md={3.5}>
              <InputPreview
                label="Food & Related Items"
                value={
                  data?.ondcUIControl?.isFoodRelatedItems
                    ? data?.ondcUIControl?.isFoodRelatedItems === "yes"
                      ? "Yes"
                      : "No"
                    : data?.ondc?.shelfLife
                    ? "Yes"
                    : "No"
                }
              />
            </Grid>

            {(data?.ondcUIControl?.isFoodRelatedItems === "yes" ||
              (data?.ondc?.shelfLife && isViewProduct)) && (
              <>
                <Grid item xs={12} md={3.5}>
                  <InputPreview
                    label="Shelf Life"
                    value={`${data?.ondc?.shelfLife} Days`}
                  />
                </Grid>

                <Grid item xs={12} md={4}></Grid>

                <Grid item xs={6} md={3.5}>
                  <InputPreview
                    label="FSSAI License Number"
                    value={data?.ondc?.fssai?.[0]?.number}
                  />
                </Grid>

                {/* <Grid item xs={6} md={3.5}>
                  <InputPreview
                    label="Valid Till"
                    value={
                      data?.ondc?.fssai?.[0]?.validTill
                        ? moment(data?.ondc?.fssai?.[0]?.validTill).format(
                            "DD/MM/YYYY"
                          )
                        : "-"
                    }
                  />
                </Grid> */}

                <Grid item xs={12} md={3.5}>
                  <InputPreview
                    label="Veg/Nonveg/Egg"
                    value={
                      data?.ondc?.veg_nonveg?.egg === "yes"
                        ? "Egg"
                        : data?.ondc?.veg_nonveg?.nonVeg === "yes"
                        ? "Non Veg"
                        : data?.ondc?.veg_nonveg?.veg === "yes"
                        ? "Veg"
                        : data?.ondc?.veg_nonveg?.vegEgg === "yes"
                        ? "Veg/Egg"
                        : ""
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Typography
            component="h6"
            fontSize={{ xs: 13, md: 14 }}
            color={blackColor}
            fontWeight={600}
            lineHeight="20px"
            m="20px 0 0px !important"
          >
            Packaged Commodities
          </Typography>
          <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
            <Grid item xs={12} md={3.5}>
              <InputPreview
                label=""
                value={
                  data?.ondcUIControl?.isPackaged
                    ? data?.ondcUIControl?.isPackaged === "yes"
                      ? "Yes"
                      : "No"
                    : data?.ondc?.statutoryReqsPackagedCommodities
                        ?.manufacturer_or_packer_name
                    ? "Yes"
                    : "No"
                }
              />
            </Grid>

            <Grid item xs={12} md={7}></Grid>

            {(data?.ondcUIControl?.isPackaged === "yes" ||
              (data?.ondc?.statutoryReqsPackagedCommodities
                ?.manufacturer_or_packer_name &&
                isViewProduct)) && (
              <>
                <Grid item xs={12} md={3}>
                  <InputPreview
                    label="Manufacturer/Packer Name"
                    value={
                      data?.ondc?.statutoryReqsPackagedCommodities
                        ?.manufacturer_or_packer_name
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputPreview
                    label="License No"
                    value={
                      data?.ondc?.statutoryReqsPackagedCommodities
                        ?.mfg_license_no
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputPreview
                    label="Manufacturer/Packer Address"
                    value={
                      data?.ondc?.statutoryReqsPackagedCommodities
                        ?.manufacturer_or_packer_address
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}></Grid>

                <Grid item xs={12} md={3}>
                  <InputPreview
                    label="Product Common/Generic Name"
                    value={
                      data?.ondc?.statutoryReqsPackagedCommodities
                        ?.common_or_generic_name_of_commodity
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputPreview
                    label="Manufacture/Import Date"
                    value={
                      data?.ondc?.statutoryReqsPackagedCommodities
                        ?.month_year_of_manufacture_packing_import
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputPreview
                    label="Expiry Date"
                    value={
                      data?.ondc?.statutoryReqsPackagedCommodities?.expiry_date
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}></Grid>

                <Grid item xs={12} md={3}>
                  <InputPreview
                    label="Net Quantity"
                    value={
                      data?.ondc?.statutoryReqsPackagedCommodities
                        ?.net_quantity_or_measure_of_commodity_in_pkg
                    }
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <InputPreview
                    label="Multiple Products"
                    value={
                      data?.ondc?.statutoryReqsPackagedCommodities
                        ?.multiple_products_name_number_or_qty
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Typography
            component="h6"
            fontSize={{ xs: 13, md: 14 }}
            color={blackColor}
            fontWeight={600}
            lineHeight="20px"
            m="20px 0 0px !important"
          >
            Pre Packaged Food
          </Typography>
          <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
            <Grid item xs={12} md={3.5}>
              <InputPreview
                label=""
                value={
                  data?.ondcUIControl?.isPrePackaged
                    ? data?.ondcUIControl?.isPrePackaged === "yes"
                      ? "Yes"
                      : "No"
                    : data?.ondc?.statutoryReqsPrepackagedFood
                        ?.brand_owner_FSSAI_license_no ||
                      data?.ondc?.statutoryReqsPrepackagedFood
                        ?.other_FSSAI_license_no ||
                      data?.ondc?.statutoryReqsPrepackagedFood
                        ?.importer_FSSAI_license_no ||
                      data?.ondc?.statutoryReqsPrepackagedFood?.ingredients_info
                    ? "Yes"
                    : "No"
                }
              />
            </Grid>

            <Grid item xs={12} md={7}></Grid>

            {(data?.ondcUIControl?.isPrePackaged === "yes" ||
              ((data?.ondc?.statutoryReqsPrepackagedFood
                ?.brand_owner_FSSAI_license_no ||
                data?.ondc?.statutoryReqsPrepackagedFood
                  ?.other_FSSAI_license_no ||
                data?.ondc?.statutoryReqsPrepackagedFood
                  ?.importer_FSSAI_license_no ||
                data?.ondc?.statutoryReqsPrepackagedFood?.ingredients_info) &&
                isViewProduct)) && (
              <>
                <Grid item xs={12} md={3}>
                  <InputPreview
                    label="Brand Owner FSSAI License No"
                    value={
                      data?.ondc?.statutoryReqsPrepackagedFood
                        ?.brand_owner_FSSAI_license_no
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputPreview
                    label="Other FSSAI License No"
                    value={
                      data?.ondc?.statutoryReqsPrepackagedFood
                        ?.other_FSSAI_license_no
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputPreview
                    label="Importer FSSAI License No"
                    value={
                      data?.ondc?.statutoryReqsPrepackagedFood
                        ?.importer_FSSAI_license_no
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}></Grid>

                <Grid item xs={12} md={6}>
                  <InputPreview
                    label="Ingredients Info"
                    value={
                      data?.ondc?.statutoryReqsPrepackagedFood?.ingredients_info
                    }
                  />
                </Grid>

                <Grid item xs={12} md={7}></Grid>

                <Grid item xs={12} md={6}>
                  <InputPreview
                    label="Nutritional Info"
                    value={
                      data?.ondc?.statutoryReqsPrepackagedFood?.nutritional_info
                    }
                  />
                </Grid>

                <Grid item xs={12} md={7}></Grid>

                <Grid item xs={12} md={6}>
                  <InputPreview
                    label="Additives Info"
                    value={
                      data?.ondc?.statutoryReqsPrepackagedFood?.additives_info
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ONDCDetailsPreview;

const styles = {
  section: {
    p: "10px 20px 15px",
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: "13px",
    mt: { xs: "25px", md:'5px' },
  },
};
