import { Box, Tooltip, Typography, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  blackShade05,
  blackShade19,
  blackShade21,
  blueColor04,
  colorRed,
  displaySwitchGreenColor,
  displaySwitchRedColor,
  greyColor053,
  greyColor054,
  greyColor059,
  greyColor40,
  greyColor41,
  lightBlueColor03,
  lightGreen04,
  lightOrange02,
  lightRedColor02,
  logoImgborder,
  mobilePrimary,
  orangeShade15,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { DASHBOARD_MENU, DASHBOARD_MENU_BRANCH, DASHBOARD_MENU_MAIN_BRANCH } from "../../utils/data";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useDispatch, useSelector } from "react-redux";
import { getStatsCountInfoData } from "../../reducers/businessDashboard/BusinessDashboardSlice";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { addThousandsSeparator } from "../../utils/helper";
import {
  getActiveBusinessInfo,
  getCommerceInfo,
} from "../../reducers/businessPreview/businessPreviewSlice";
import { getDashboardOrderSummaryApi } from "../../service/api_calls/BusinessDashboard/BusinessDashboardApi";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { getMainBranchInfoApi } from "../../service/api_calls/dashboard/dashboardApi";
import { getMasterBranchInfo } from "../../reducers/multiBranchCatalog/multiBranchCatalogSlice";

function DashboardMenu({
  disableMenu,
  activeMenu,
  activeSubMenu,
  hideBusinessData,
}) {
  const [inventoryCountInfo, setInventoryCountInfo] = useState(null);
  const [orderCountInfo, setOrderCountInfo] = useState(null);
  const [mainBranch, setMainBranch] = useState(null);

  const [dashboardSideMenuData, setDashboardSideMenuData] = useState([]);

  const sidebarContainerRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dashboardData, statsCountInfo, dashboardDataLoader } = useSelector(
    (state) => state.businessDashboard
  );
  const { masterBranchInfo, masterBranchInfoLoader } = useSelector(
    (state) => state.multiBranchCatalog
  );

  const {
    activeBusinessInfo,
    activeBusinessInfoLoader,
    commerceInfo,
    commerceInfoLoader,
  } = useSelector((state) => state.businessPreview);

  const { refreshTabData } = useSelector((state) => state.businessOrders);

  const handleNavigattion = (route) => {
    navigate(route);
  };

  const isMenuDisabled = (menu) => {
    if (!disableMenu) return false;
    return disableMenu.includes(menu);
  };

  const getMainBranch = async () => {
    if (masterBranchInfoLoader) return;

    const businessId = sessionStorage.getItem("selectedBusinessId");
    dispatch(getMasterBranchInfo(businessId));

    // const mainBranchRes = await getMainBranchInfoApi(businessId);
    // if (mainBranchRes?.details) {
    //   if (!mainBranch) {
    //     setMainBranch(mainBranchRes?.details);
    //   }
    // }
  };

  const getOrderSummaryData = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    if (businessId) {
      const res = await getDashboardOrderSummaryApi(businessId);

      if (res?.details) {
        console.log("setOrderSummaryInfo hit");

        const orderCounts = res?.details?.statusCounts?.reduce(
          (preValue, currentValue) => {
            return {
              ...preValue,
              [currentValue?.id]: currentValue?.count,
            };
          },
          {}
        );
        setOrderCountInfo(orderCounts);
      }
    }
  };

  useEffect(() => {
    if (!activeBusinessInfo?.mainBranch && activeBusinessInfo?.mainBranchId) {
      setDashboardSideMenuData(DASHBOARD_MENU_BRANCH);
    } else if (activeBusinessInfo?.mainBranch && !activeBusinessInfo?.mainBranchId) {
      setDashboardSideMenuData(DASHBOARD_MENU_MAIN_BRANCH);
    } else {
      setDashboardSideMenuData(DASHBOARD_MENU);
    }

    return () => {};
  }, [activeBusinessInfo]);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId && !dashboardDataLoader) {
      if (!commerceInfo) {
        dispatch(getCommerceInfo(businessId));
      }

      if (dashboardData) {
        // const orderSummary = dashboardData?.orderSummary?.details;
        // const orderCountData = orderSummary?.statusCounts?.reduce(
        //   (preValue, currentValue) => {
        //     return {
        //       ...preValue,
        //       [currentValue?.id]: currentValue?.count,
        //     };
        //   },
        //   {}
        // );

        // setOrderCountInfo(orderCountData);
        setInventoryCountInfo(dashboardData?.inventorySummary);
      } else {
        console.log("FETCH_COUNT_MENU");

        // Getting Product and Sales Count
        if (!statsCountInfo) {
          dispatch(getStatsCountInfoData(businessId));
        }
      }
    }

    return () => {};
  }, []);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
    }

    if (statsCountInfo) {
      const orderSummary = statsCountInfo?.orderCountInfo?.details;
      const orderCountData = orderSummary?.statusCounts?.reduce(
        (preValue, currentValue) => {
          return {
            ...preValue,
            [currentValue?.id]: currentValue?.count,
          };
        },
        {}
      );

      setOrderCountInfo(orderCountData);
      setInventoryCountInfo(statsCountInfo?.inventoryCountInfo);
    }

    return () => {};
  }, [statsCountInfo]);

  useEffect(() => {
    // getOrderSummaryData();
    if (refreshTabData > 1) {
      const businessId = sessionStorage.getItem("selectedBusinessId");
      dispatch(getStatsCountInfoData(businessId));
    }
  }, [refreshTabData]);

  useEffect(() => {
    if (!activeMenu) return;

    console.log("ANIM_SIIDE_MENU");

    const selectedElement = sidebarContainerRef.current.querySelector(
      `[data-id="${activeMenu}"]`
    );

    if (selectedElement) {
      setTimeout(() => {
        sidebarContainerRef.current.scrollTop = selectedElement.offsetTop - 390;
      }, 100);
    }
  }, [dashboardSideMenuData, activeMenu]);

  useEffect(() => {
    if (
      activeBusinessInfo?.mainBranchId?.length > 0 &&
      !masterBranchInfo &&
      !masterBranchInfoLoader
    ) {
      getMainBranch();
    }

    return () => {};
  }, [activeBusinessInfo]);

  // useEffect(() => {
  //   if (!activeMenu) return;

  //   const selectedElement = sidebarContainerRef.current.querySelector(
  //     `[data-id="${activeMenu}"]`
  //   );

  //   if (selectedElement) {
  //     setTimeout(() => {
  //       const offset = 390; // Adjust this value as needed
  //       const scrollY =
  //         selectedElement.offsetTop - sidebarContainerRef.current.offsetTop - offset;
  //       sidebarContainerRef.current.scrollTo({
  //         top: scrollY,
  //         behavior: 'smooth'
  //       });
  //     }, 100);
  //   }
  // }, [activeMenu]);

  return (
    <Box ref={sidebarContainerRef} sx={styles.menu}>
      {/* <Box
        sx={{
          height:'45px',
          borderBottom: `1px solid #ffffff14`,
          mb: { md: "22px", xs: "15px" },
          backgroundColor: whiteColor
        }}
      >
        <Box
          component="img"
          src="https://storage.googleapis.com/bodefaults/businessdashboardweb/logo.png"
          sx={{
            width: "auto",
            maxHeight: { md: "27px", xs: "38px" },
            // p: { md: "10px 12px", xs: "5px 12px" },
            objectFit: "contain",
            cursor: "pointer",
            mt: "5px",
          }}
          onClick={() => {
            navigate("/");
          }}
        />
      </Box> */}

      <Box px={{ md: 0, xs: "10px" }} pb={10}>
        {activeMenu !== "store" &&
          !activeBusinessInfoLoader &&
          // !commerceInfoLoader &&
          !hideBusinessData && (
            <Box
              sx={{
                ...styles.businessInfoSection,
                borderBottom: `3px solid ${greyColor41}`,
                position: "sticky",
                top: "0px",
                backgroundColor: whiteColor,
                pt: 3,
                zIndex: 20,
              }}
            >
              <Box
                component="img"
                src={activeBusinessInfo?.logoURL}
                width={{ xs: "50px", md: "50px" }}
                height={{ xs: "50px", md: "50px" }}
                loading="lazy"
                sx={{
                  objectFit: "cover",
                  borderRadius: { xs: "6px", md: "10px" },
                  border: logoImgborder,
                  cursor: "pointer",
                }}
                onClick={() => {
                  const businessUrl = sessionStorage.getItem("costboWEBURL");
                  navigate(`/${businessUrl}/dashboard`);
                }}
              />

              <Typography
                variant="h6"
                fontSize={15}
                fontWeight={600}
                lineHeight="22px"
                color={blackShade19}
                mt={1}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  const businessUrl = sessionStorage.getItem("costboWEBURL");
                  navigate(`/${businessUrl}/dashboard`);
                }}
              >
                {activeBusinessInfo?.name}
              </Typography>

              {activeBusinessInfo?.mainBranchId === "" &&
                activeBusinessInfo?.mainBranch && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    m="5px 0 8px"
                  >
                    <Box sx={styles.branchTag}>
                      <Typography
                        fontSize={11}
                        fontWeight={500}
                        lineHeight="15px"
                        color={orangeShade15}
                      >
                        {activeBusinessInfo?.branchCount} Branches
                      </Typography>
                    </Box>
                  </Box>
                )}
              {activeBusinessInfo?.mainBranchId?.length > 0 && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  m="5px 0 8px"
                >
                  <Box sx={{ ...styles.branchTag, p: "4px 10px" }}>
                    <Typography
                      fontSize={11}
                      fontWeight={500}
                      lineHeight="15px"
                      color={orangeShade15}
                    >
                      Head Quarters -{" "}
                      {masterBranchInfo?.details?.masterBranchName}
                      {/* {JSON.stringify(mainBranch)} */}
                    </Typography>
                  </Box>
                </Box>
              )}

              <Typography
                fontSize={12.5}
                fontWeight={500}
                lineHeight="15px"
                color={{ md: blueColor04, xs: mobilePrimary }}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                mt={0.5}
                onClick={() => {
                  navigate("/businessListing");
                }}
              >
                Change Business {JSON.stringify(hideBusinessData)}
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={{ md: "row", xs: "column" }}
                mx={{ md: 1.5, xs: 0.5 }}
                mt={1}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  flexDirection={{ xs: "row", md: "column" }}
                >
                  <Typography sx={styles.countLabel} textAlign="left">
                    TOTAL ORDERS
                  </Typography>
                  <Typography sx={styles.countValue} textAlign="left">
                    {statsCountInfo?.orderCountInfo?.details?.totalOrders || 0}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={{ xs: "row", md: "column" }}
                  width="100%"
                >
                  <Typography sx={styles.countLabel} textAlign="right">
                    TOTAL SALES
                  </Typography>
                  <Typography sx={styles.countValue} textAlign="right">
                    ₹{" "}
                    {addThousandsSeparator(
                      statsCountInfo?.orderCountInfo?.details?.totalAmount,
                      true
                    )}
                  </Typography>
                </Box>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mx={{ md: 1.5, xs: 0.5 }}
                sx={{
                  bgcolor:
                    commerceInfo?.info?.ondcEnabled?.toLowerCase() == "yes"
                      ? lightGreen04
                      : lightRedColor02,
                  p: "5px 7px",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
                mt={{ md: "8px", xs: "4px" }}
                onClick={() => {
                  const businessUrl = sessionStorage.getItem("costboWEBURL");
                  navigate(`/${businessUrl}/ONDCControls`);
                }}
              >
                <Typography
                  fontSize={12}
                  fontWeight={400}
                  color={greyColor053}
                  lineHeight={1}
                >
                  ONDC Enabled
                </Typography>
                {/* <Box display={{ md: "block", xs: "none" }}>
                  <StyledSwitch
                    size="small"
                    isChecked={
                      commerceInfo?.info?.ondcEnabled?.toLowerCase() == "yes"
                    }
                    onChange={() => {}}
                    label={
                      commerceInfo?.info?.ondcEnabled?.toLowerCase() == "yes"
                        ? "YES"
                        : "NO"
                    }
                    labelColor={
                      commerceInfo?.info?.ondcEnabled?.toLowerCase() == "yes"
                        ? displaySwitchGreenColor
                        : displaySwitchRedColor
                    }
                    gap={"0px"}
                  />
                </Box> */}
                <Typography
                  component="body2"
                  fontSize={13}
                  color={
                    commerceInfo?.info?.ondcEnabled?.toLowerCase() == "yes"
                      ? displaySwitchGreenColor
                      : displaySwitchRedColor
                  }
                  fontWeight={500}
                  lineHeight="20px"
                  // display={{ xs: "block", md: "none" }}
                  sx={{ textDecoration: "underline" }}
                >
                  {commerceInfo?.info?.ondcEnabled?.toLowerCase() == "yes"
                    ? "Yes"
                    : "No"}
                </Typography>
              </Box>
            </Box>
          )}

        {dashboardSideMenuData?.map((item, index) => {
          if (
            activeMenu !== "store" &&
            item?.value === "store" &&
            !hideBusinessData
          )
            return null;
          if (isMenuDisabled(item?.value)) {
            return (
              <Tooltip
                title={
                  <Typography fontSize={11} color={whiteColor}>
                    Please select your Business from Business Listing to enable
                    this menu
                  </Typography>
                }
                enterTouchDelay={0}
              >
                <Box>
                  <NavItem
                    key={`side_menu_${index}`}
                    sectionHeader={item?.sectionHeader}
                    icon={item?.icon}
                    label={item?.label}
                    onClick={() => {
                      if (item?.subMenu?.length > 0) return;

                      if (item?.root) {
                        handleNavigattion(item?.route);
                        return;
                      }

                      handleNavigattion(
                        index != 0
                          ? `/${sessionStorage.getItem("costboWEBURL")}${
                              item?.route
                            }`
                          : item?.route
                      );
                    }}
                    onSubMenuClick={(route) => {
                      handleNavigattion(
                        `/${sessionStorage.getItem("costboWEBURL")}${route}`
                      );
                    }}
                    disabled={isMenuDisabled(item?.value)}
                    isActive={activeMenu === item?.value}
                    activeSubMenu={activeSubMenu}
                    sectionBreak={item?.sectionBreak}
                    subMenu={item?.subMenu}
                    inventoryCountInfo={inventoryCountInfo}
                    orderCountData={orderCountInfo}
                    isMenuCollapsed={
                      item?.collapseDefault && activeMenu !== item?.value
                      // (item?.value === "eCommerceControls" &&
                      //   activeMenu !== "eCommerceControls") ||
                      // (item?.value === "customerGrievance" &&
                      //   activeMenu !== "customerGrievance")
                    }
                    dataId={item?.value}
                  />
                </Box>
              </Tooltip>
            );
          } else {
            return (
              <NavItem
                key={`side_menu_${index}`}
                sectionHeader={item?.sectionHeader}
                icon={item?.icon}
                label={item?.label}
                onClick={() => {
                  if (item?.subMenu?.length > 0) return;

                  if (item?.root) {
                    handleNavigattion(item?.route);
                    return;
                  }

                  handleNavigattion(
                    index != 0
                      ? `/${sessionStorage.getItem("costboWEBURL")}${
                          item?.route
                        }`
                      : item?.route
                  );
                }}
                onSubMenuClick={(route) => {
                  handleNavigattion(
                    `/${sessionStorage.getItem("costboWEBURL")}${route}`
                  );
                }}
                disabled={isMenuDisabled(item?.value)}
                isActive={activeMenu === item?.value}
                activeSubMenu={activeSubMenu}
                sectionBreak={item?.sectionBreak}
                subMenu={item?.subMenu}
                inventoryCountInfo={inventoryCountInfo}
                orderCountData={orderCountInfo}
                isMenuCollapsed={
                  item?.collapseDefault && activeMenu !== item?.value
                  // item?.value === "eCommerceControls" &&
                  // activeMenu !== "eCommerceControls"
                }
                dataId={item?.value}
              />
            );
          }
        })}

        <Stack alignItems="center" justifyContent="center" mt={2}>
          <Box
            sx={styles.gotoTopBtn}
            onClick={() => {
              navigate(
                !activeBusinessInfoLoader &&
                  !hideBusinessData &&
                  !commerceInfoLoader
                  ? `/${sessionStorage.getItem("costboWEBURL")}/dashboard`
                  : "/businessListing"
              );

              const scrollY = 0;
              sidebarContainerRef.current.scrollTo({
                top: scrollY,
                behavior: "smooth",
              });
            }}
          >
            <KeyboardDoubleArrowUpIcon
              sx={{ fontSize: "18px", color: "inherit" }}
            />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}

export default DashboardMenu;

const NavItem = ({
  icon,
  label,
  onClick,
  onSubMenuClick,
  disabled,
  isActive,
  sectionBreak,
  subMenu,
  inventoryCountInfo,
  orderCountData,
  isMenuCollapsed,
  activeSubMenu,
  sectionHeader,
  dataId,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(isMenuCollapsed || false);

  return (
    <Box px={{ md: 1, xs: 0 }} data-id={dataId} mt={1}>
      {sectionHeader && (
        <Typography
          fontSize={11.5}
          fontWeight={500}
          color={blackShade21}
          mb={1}
        >
          {sectionHeader}
        </Typography>
      )}

      <Box
        sx={{
          borderBottom: sectionBreak ? `1px solid ${greyColor41}` : "none",
          mb: sectionBreak || "2px",
          pb: sectionBreak ? "8px" : "0px",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
          p="6px 0 6px 8px"
          sx={{
            opacity: disabled ? 0.7 : 1,
            borderRadius: "0px",
            cursor: disabled ? "" : "pointer",

            background:
              isActive && !activeSubMenu ? lightBlueColor03 : "transparent",
            borderRight:
              isActive && !activeSubMenu ? `4px solid ${blueColor04}` : "none",
            borderRadius: "6px",
            "&:hover": {
              background: lightBlueColor03,
              borderRight: `4px solid ${blueColor04}`,
            },
          }}
          onClick={() => {
            if (disabled) return;
            setIsCollapsed(!isCollapsed);
            onClick();
          }}
        >
          <Box display="flex" alignItems="center">
            <Box component="img" src={icon} width="20px" height="20px" />

            <Typography
              component="h3"
              fontSize={{ xs: 12.5, md: 12.5 }}
              color={isActive && !activeSubMenu ? blueColor04 : greyColor40}
              fontWeight={500}
              // textAlign="center"
              ml="12px"
              position="relative"
            >
              {label}

              {!disabled &&
                label === "Orders" &&
                orderCountData?.ordercreated > 0 && (
                  <Box sx={styles.badgeDot}>{orderCountData?.ordercreated}</Box>
                )}
            </Typography>
          </Box>

          {subMenu?.length > 0 ? (
            !isCollapsed ? (
              <KeyboardArrowUpIcon
                sx={{ color: greyColor40, fontSize: "18px" }}
              />
            ) : (
              <ExpandMoreRoundedIcon
                sx={{ color: greyColor40, fontSize: "18px" }}
              />
            )
          ) : null}
        </Box>

        {!isCollapsed
          ? subMenu?.map((item, index) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  p: "2px 0 2px 40px",
                  // mb: "4px",
                  cursor: disabled ? "" : "pointer",
                  opacity: disabled ? 0.3 : 1,
                  borderRadius: "6px",
                  background:
                    activeSubMenu === item?.value
                      ? lightBlueColor03
                      : "transparent",
                  borderRight:
                    activeSubMenu === item?.value
                      ? `4px solid ${blueColor04}`
                      : "none",
                  "&:hover": {
                    background: lightBlueColor03,
                    borderRight: `4px solid ${blueColor04}`,
                  },
                }}
                key={label + index}
                onClick={() => {
                  if (disabled) return;
                  onSubMenuClick(item?.route);
                }}
              >
                <Box
                  sx={{
                    width: "5px",
                    height: "5px",
                    borderRadius: "50%",
                    backgroundColor: greyColor40,
                  }}
                />
                <Typography
                  component="h3"
                  fontSize={{ xs: 12, md: 12 }}
                  color={greyColor40}
                  fontWeight={500}
                  textAlign="center"
                  position="relative"
                >
                  {item?.label}

                  {!disabled &&
                    item?.label === "Active Inventory" &&
                    inventoryCountInfo?.outOfStock > 0 && (
                      <Box sx={styles.badgeDot}>
                        {inventoryCountInfo?.outOfStock}
                      </Box>
                    )}
                </Typography>
              </Box>
            ))
          : null}
      </Box>
    </Box>
  );
};

const styles = {
  menu: {
    width: { md: "220px", xs: "250px" },
    height: "calc(100vh - 45px)",
    background: whiteColor,
    mt: "45px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "20px",
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      // background: '#d0d3da',
      background: "rgba(0,0,0,0.1)",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.05)",
    },
  },

  badgeDot: {
    width: "18px",
    height: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    fontSize: "9px",
    fontWeight: 500,
    color: whiteColor,
    backgroundColor: colorRed,
    position: "absolute",
    top: -6,
    right: -17,
  },

  businessInfoSection: {
    textAlign: "center",
    pb: 1,
    borderBottom: `1px solid ${greyColor41}`,
    mb: 1,
  },
  countLabel: { fontSize: 11.3, fontWeight: 500, color: greyColor40 },
  countValue: { fontSize: 15, fontWeight: 600, color: blackShade05 },

  gotoTopBtn: {
    width: "35px",
    height: "35px",
    borderRadius: "25px",
    bgcolor: greyColor054,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      color: whiteColor,
      bgcolor: blackShade19,
    },
  },

  branchTag: { bgcolor: lightOrange02, p: "2px 10px", borderRadius: "5px" },
};
