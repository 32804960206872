import moment from "moment";
import {
  getApiInstance,
  getApiInstanceWithoutProfileId,
} from "../../AppService";
import {
  ADD_REWARD_POINT,
  APPROVE_CART,
  APPROVE_SHIPMENT,
  CANCEL_COMPLETE_ORDER,
  CURRENT_DATE,
  DELHIVERY_STATUS_SHIPPMENTINFO,
  FROM_DATE,
  GET_BUSINESS_CART_INFO,
  GET_BUSINESS_ORDERS_BY_STATUS,
  GET_CANCELNATION_LIST,
  GET_DELHIVERY_B2B_STATUS,
  GET_DELHIVERY_STATUS,
  GET_REWARD_POINTS,
  GET_SHIPING_LABEL_LINK,
  GET_TELYPORT_INFO,
  GET_TELYPORT_STATUS,
  ORDER_DELHIVERED_SHIPPMENTINFO,
  PARTIAL_COMPLETE_ORDER,
  RESEND_INVOICE,
  RESEND_INVOICE_B2B,
  SEARCH_ONDC_ORDERS_BY_ID,
  SEARCH_ORDERS_BY_ID,
  UPDATE_REWARD_POINT,
} from "../../constant";

export const getBusinessOrdersByStatusApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(GET_BUSINESS_ORDERS_BY_STATUS, {
      params: {
        pageNo: data?.pageNo,
        pageSize: data?.pageSize,
        status: data?.status,
        from: FROM_DATE,
        // to: CURRENT_DATE,
        to: moment().valueOf(),
      },
    });
    console.log("getBusinessOrdersByStatusApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getDelhiveryStatusDetails = async (trackId) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_DELHIVERY_STATUS, {
      params: {
        trackId: trackId,
      },
    });

    console.log("getDelhiveryStatusDetails:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getDelhiveryB2BStatusDetails = async (trackId) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_DELHIVERY_B2B_STATUS + trackId);

    console.log("getDelhiveryB2BStatusDetails:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getTelyportStatusDetails = async (trackId) => {
  try {
    const instance = getApiInstance({
      header: {
        telyPortOrderId: trackId,
      },
    });

    const response = await instance.get(GET_TELYPORT_STATUS, {
      params: {
        trackId: trackId,
      },
    });

    console.log("getTelyportStatusDetails:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getTelyportStatusInfo = async (trackId) => {
  try {
    const instance = getApiInstance({
      header: {
        telyPortOrderId: trackId,
      },
    });

    const response = await instance.get(GET_TELYPORT_INFO);

    console.log("getTelyportStatusDetails:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const searchOrdersByOrderIdApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });

    const response = await instance.get(SEARCH_ORDERS_BY_ID, {
      params: {
        orderId: data?.orderId,
      },
    });

    console.log("searchOrdersByOrderIdApi:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const searchOrdersByOndcOrderIdApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });

    const response = await instance.get(SEARCH_ONDC_ORDERS_BY_ID, {
      params: {
        id: data?.orderId,
      },
    });

    console.log("searchOrdersByOrderIdApi:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCartInfoApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        cartid: data?.cartId,
        businessId: data?.businessId,
      },
    });

    const response = await instance.get(GET_BUSINESS_CART_INFO);

    console.log("getCartInfoApi:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const approveCart = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        cartid: data?.cartId,
        businessId: data?.businessId,
      },
    });

    const response = await instance.post(APPROVE_CART, data?.data);

    console.log("appor:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateRewardPointApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
        rewardPointId: data?.rewardPointId,
      },
    });

    const response = await instance.post(UPDATE_REWARD_POINT, data?.data);

    console.log("appor:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addRewardPointApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });

    const response = await instance.post(ADD_REWARD_POINT, data?.data);

    console.log("appor:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getRewardPointsApi = async (data) => {
  try {
    const instance = getApiInstanceWithoutProfileId({
      header: {
        businessId: data?.businessId,
        profileId: data?.profileId,
      },
    });

    const response = await instance.get(GET_REWARD_POINTS);

    console.log("getRewardPointsApi:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const resendInvoiceApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        cartId: data?.cartId,
      },
    });

    const response = await instance.get(RESEND_INVOICE);

    console.log("getRewardPointsApi:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const resendInvoiceApiB2B = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        cartId: data?.cartId,
        businessId: data?.businessId,
      },
    });

    const response = await instance.get(RESEND_INVOICE_B2B + data?.carrierId);

    console.log("getRewardPointsApi:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const approveShipment = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        cartId: data?.cartId,
        businessId: data?.businessId,
      },
    });

    const response = await instance.post(APPROVE_SHIPMENT, data?.data);

    console.log("APPROVE_SHIPMENT:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Cancel Complete Order
export const cancelCompleteOrderApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        cartId: data?.cartId,
        businessId: data?.businessId,
      },
    });

    const response = await instance.post(CANCEL_COMPLETE_ORDER, data?.data);

    console.log("appor:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Cancel Partial Order
export const partialCompleteOrderApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        cartId: data?.cartId,
        businessId: data?.businessId,
      },
    });

    const response = await instance.post(PARTIAL_COMPLETE_ORDER, data);

    console.log("appor:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCancelnationListDetails = async (paramData) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_CANCELNATION_LIST, {
      params: paramData,
    });

    console.log("getCancelnationListDetails:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deliveryStatusShippmentApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        cartId: data?.cartId,
        businessId: data?.businessId,
        // profileId: data?.profileId,
      },
    });

    const response = await instance.post(
      DELHIVERY_STATUS_SHIPPMENTINFO,
      data?.data
    );

    console.log("getCartInfoApi:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const orderDeliveredShippmentApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        cartId: data?.cartId,
        businessId: data?.businessId,
        // profileId: data?.profileId,
      },
    });

    const response = await instance.post(
      ORDER_DELHIVERED_SHIPPMENTINFO,
      data?.data
    );

    console.log("getCartInfoApi:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getShipingLabelLink = async (data) => {
  try {
    const instance = getApiInstance();

    const response = await instance.get(GET_SHIPING_LABEL_LINK, {
      headers: data,
    });

    console.log("appor:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
