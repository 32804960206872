import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardMenu from "./Dashboard/DashboardMenu";
import DashboardHeader from "./Dashboard/DashboardHeader";
import { BGColor02 } from "../configs/styles/muiThemes";
import BusinessInfoCard from "../components/Cards/BusinessInfoCard";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import CashBackContent from "./CashBack/CashBackContent";
import { getCashBackValueForBusinessData } from "../reducers/cashback/CashbackSlice";
import {
  getDashboardCashbackCountApi,
  getDashboardCashbackSummaryApi,
} from "../service/api_calls/BusinessDashboard/BusinessDashboardApi";
import DashboardLayout from "../components/Layouts/DashboardLayout";

function CashBack() {
  const [cashbackStats, setCashbackStats] = useState(null);

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  const { cashBackValue, businessCashbackLoader, cashBackValueLoader } =
    useSelector((state) => state.businessCashback);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getCashbackStatus = async (businessId) => {
    const cashbackSummary = await getDashboardCashbackSummaryApi(businessId);
    const cashbackCount = await getDashboardCashbackCountApi(businessId);

    setCashbackStats({
      cashbackSummary: cashbackSummary?.details || null,
      cashbackCount: cashbackCount?.details || null,
    });
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      dispatch(getCashBackValueForBusinessData({ businessId }));
      getCashbackStatus(businessId);
    }
  }, []);

  return (
    <Box sx={styles.container}>
      <DashboardLayout activeMenu="coupons" activeSubMenu="loyaltyPoints">
        <CashBackContent
          cashbackStats={cashbackStats}
          customerCashback={cashBackValue}
          loading={businessCashbackLoader}
          activeBusinessInfo={activeBusinessInfo}
        />
      </DashboardLayout>
    </Box>
  );
}

export default CashBack;

const styles = {
  container: {
    minHeight: "calc(100vh - 110px)",
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "25px" },
    p: { xs: "0px", md: "0px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },
};
