import React from 'react'
import { blackColor, blackShade05, blueColor04, greyColor50, greyColor6, greyColorM01, logoImgborder1, redColorBG01, skyblueColorBG01, whiteColor } from '../../../configs/styles/muiThemes';
import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { addThousandsSeparator } from '../../../utils/helper';
import Inventory2Icon from "@mui/icons-material/Inventory2";
import PaymentsIcon from "@mui/icons-material/Payments";
function MobileEasyCartOrders({ paycartData, onClick }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const totalPaycartcreated = Number(paycartData?.totalPaycartcreated) || 0;
  const totalPaycartOrders = Number(paycartData?.totalPaycartOrders) || 0;
  const totalPaycartValue = Number(paycartData?.totalPaycartValue) || 0;

  let ordersNotPayed = totalPaycartcreated - totalPaycartOrders;
  return (
    <Box sx={styles.card}>
      <Box
        display="flex"
        flexDirection={{ xs: "row", md: "row" }}
        alignItems={{ xs: "center", md: "center" }}
        sx={{
          ...styles.orderCardMobile(redColorBG01, "#FFEAEF"),
          background: redColorBG01,
        }}
      >
        <Grid container spacing={{ xs: 1.5, md: 3 }} flexWrap={"wrap"}>
          <Grid item md={7} xs={6} flexGrow={"wrap"}>
            <Box
              sx={{
                ...styles.orderDateCardMobile(whiteColor, "#FFEAEF"),
                background: whiteColor,
                borderRadius: "10px",
              }}
              flexWrap={"wrap"}
            >
              <MobileStatsCard
                label="Paycart Orders"
                value={totalPaycartcreated}
                icon={
                  <Box sx={styles.iconCircle}>
                    <Box
                      component="img"
                      alt="Product Image"
                      src={
                        "https://storage.googleapis.com/bodefaults/businessdashboardweb/paycart_orders.svg"
                      }
                      width="auto"
                      height="128px"
                    />
                  </Box>
                }
                bgColor={matches ? blueColor04 : redColorBG01}
                valueSize={14}
                hideMobileBorder
              />
            </Box>
          </Grid>

          <Grid item md={7} xs={6} flexGrow={"wrap"}>
            <Box
              sx={{
                ...styles.orderDateCardMobile(whiteColor, "#FFEAEF"),
                background: whiteColor,
                borderRadius: "10px",
              }}
              flexWrap={"wrap"}
            >
              <MobileStatsCard
                label="Paycart Value"
                value={`₹ ${addThousandsSeparator(totalPaycartValue)}`}
                icon={
                  <Box sx={styles.iconCircle}>
                    <Box
                      component="img"
                      alt="Product Image"
                      src={
                        "https://storage.googleapis.com/bodefaults/businessdashboardweb/paycart_value.svg"
                      }
                      width="auto"
                      height="128px"
                    />
                  </Box>
                }
                bgColor={matches ? blueColor04 : redColorBG01}
                valueSize={14}
                hideMobileBorder
              />
            </Box>
          </Grid>
          <Grid item md={7} xs={6} flexGrow={"wrap"}>
            <Box
              sx={{
                ...styles.orderDateCardMobile(whiteColor, "#FFEAEF"),
                background: whiteColor,
                borderRadius: "10px",
              }}
              flexWrap={"wrap"}
            >
              <MobileStatsCard
                label="Orders Paid"
                value={totalPaycartOrders}
                icon={
                  <Box sx={styles.iconCircle}>
                    <Box
                      component="img"
                      alt="Product Image"
                      src={
                        "https://storage.googleapis.com/bodefaults/businessdashboardweb/orders_paid.svg"
                      }
                      width="auto"
                      height="128px"
                    />
                  </Box>
                }
                bgColor={matches ? blueColor04 : redColorBG01}
                valueSize={14}
                hideMobileBorder
              />
            </Box>
          </Grid>
          <Grid item md={7} xs={6} flexGrow={"wrap"}>
            <Box
              sx={{
                ...styles.orderDateCardMobile(whiteColor, "#FFEAEF"),
                background: whiteColor,
                borderRadius: "10px",
              }}
              flexWrap={"wrap"}
            >
              <MobileStatsCard
                label="Orders Not Paid"
                value={ordersNotPayed || 0}
                icon={
                  <Box sx={styles.iconCircle}>
                    <Box
                      component="img"
                      alt="Product Image"
                      src={
                        "https://storage.googleapis.com/bodefaults/businessdashboardweb/orders_no_paid.svg"
                      }
                      width="auto"
                      height="128px"
                    />
                  </Box>
                }
                bgColor={matches ? blueColor04 : redColorBG01}
                valueSize={14}
                hideMobileBorder
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default MobileEasyCartOrders;

const MobileStatsCard = ({
  icon,
  label,
  value,
  color,
  bgColor,
  labelColor,
  valueSize,
  hideMobile,
  flex,
  hideMobileBorder,
}) => {
  return (
    <Box
      sx={{
        ...styles.card(flex),
        borderBottom: hideMobileBorder ? "" : `1px solid #F5F7FC`,
        p: "4px",
      }}
    >
      <Box sx={styles.iconContainer(bgColor, hideMobile)}>{icon}</Box>
      <Stack>
        <Typography
          variant="body2"
          fontSize={{ xs: 10, md: 11.28 }}
          color={labelColor || greyColorM01}
          fontWeight={500}
          lineHeight="20px"
          mb={-0.5}
        >
          {label}
        </Typography>

        <Typography
          component="h6"
          fontSize={{ xs: valueSize || 15, md: 15 }}
          color={color || blackShade05}
          fontWeight={600}
        >
          {value}
        </Typography>
      </Stack>
    </Box>
  );
};

const styles = {
  orderCard: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px", md: "12px" },
  },
  orderDateCard: {
    backgroundColor: { xs: greyColor50, md: whiteColor },
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "7px 15px", md: "12px" },
  },
  orderDateCardMobile: (color, borderColor) => ({
    backgroundColor: { xs: color ? color : skyblueColorBG01, md: whiteColor },
    border: `1px solid ${borderColor}`,
    borderRadius: { xs: "10px", md: "12px" },
    // boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "4px 6px", md: "12px" },
  }),
  orderCardMobile: (color, borderColor) => ({
    backgroundColor: { xs: color ? color : skyblueColorBG01, md: whiteColor },
    border: `1px solid ${borderColor}`,
    borderRadius: { xs: "10px", md: "12px" },
    // boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "15px 10px", md: "12px" },
  }),
  orderTotalCardMobile: {
    backgroundColor: { xs: "#FFEEF2", md: whiteColor },
    border: `1px solid #FFE4EB`,
    borderRadius: { xs: "10px", md: "12px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 10px", md: "12px" },
  },
  card: (flex) => ({
    display: "flex",
    alignItems: "center",
    gap: 1,
    flex: flex || 1,
  }),
  iconContainer: (bgColor, hideMobile) => ({
    width: { md: "40px", xs: "36px" },
    height: { md: "40px", xs: "36px" },
    borderRadius: "10px",
    backgroundColor: bgColor,
    display: { md: "flex", xs: hideMobile ? "none" : "flex" },
    alignItems: "center",
    justifyContent: "center",
  }),
  icon: (color) => ({ fontSize: { md: 20, xs: 17 }, color: color }),
};