import React from "react";
import ModalCard from "./ModalCard";
import VideoCard from "../pages/Guides/VideoCard";

function VideoModal({ open, handleClose, data }) {
  return (
    <ModalCard open={open} handleClose={handleClose} width="36%">
      <VideoCard
        url={data?.url}
        title={data?.title}
        category={data?.category?.label}
        date={data?.date}
      />
    </ModalCard>
  );
}

export default VideoModal;
