import moment from "moment";

var MOBILE_REGEX$1 =
  /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[6789]\d{9}|(\d[ -]?){10}\d$/;
var PASSWORD_REGX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$/;
var validEmailRegEx =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateMobileNumber(mobileNumber, isMandatory) {
  console.log(mobileNumber);
  var res = isMandatory ? "Please enter a valid Mobile number" : "";
  if (mobileNumber && mobileNumber.length > 0) {
    var isValid = MOBILE_REGEX$1.test(mobileNumber);
    if (!isValid) {
      res = "Please enter a valid Mobile number";
    } else {
      res = "";
    }
  }
  return res;
}

export function validatePassword(password) {
  if (password.length > 0) password = "";
  else password = "Please enter Password";
  return password;
}

export function validateName(name) {
  if (name.length > 0) name = "";
  else name = "Please enter name";
  return name;
}

export function validateBusinessName(name) {
  if (name.length > 0) name = "";
  else name = "Please enter name";
  return name;
}

export function validateEmail(email) {
  if (email.length > 0) {
    var isValid = validEmailRegEx.test(email.trim());
    if (!isValid) {
      email = "Please enter a valid Email";
    } else {
      email = "";
    }
  } else email = "Please enter email";
  return email;
}

export const getProfileId = () => {
  return sessionStorage.getItem("profileId");
};

export const storeUserInfo = (userInfo) => {
  const stringifyObj = JSON.stringify(userInfo);

  // convert string to base64

  const b64Str = btoa(stringifyObj);
  console.log(b64Str);
  sessionStorage.setItem("userInfo", b64Str);
};

export const getAccessToken = () => {
  let token = sessionStorage.getItem("token");
  return token;
};

export const getUserName = () => {
  const userDetails = getUserDetails();
  if (userDetails) return userDetails.account.name;
  else return "";
};

export const getPhoneNumber = () => {
  const userDetails = getUserDetails();
  console.log(userDetails);
  if (userDetails) return userDetails.account.phonenumber;
  else return "";
};

export const getUserDetails = () => {
  const tempString = sessionStorage.getItem("userInfo");
  if (tempString) {
    const objStr = atob(tempString);
    // console.log(objStr)
    const loggedInUserDetails = JSON.parse(objStr);
    return loggedInUserDetails;
  }
  return undefined;
};

export const geProfilePhoto = () => {
  const userDetails = getUserDetails();
  if (userDetails) return userDetails.account.photoURL;
  else return "";
};

export const removeSessionInfo = () => {
  // sessionStorage.removeItem("userInfo");
  // sessionStorage.removeItem("token");
  // sessionStorage.removeItem("profileId");
  // sessionStorage.removeItem("cartData");
  // sessionStorage.removeItem("cashback");
  sessionStorage.clear();
};

export function validateKey(key) {
  if (key.length > 0) key = "";
  else key = "Please enter password reset key";
  return key;
}

export function validatePasswordSave(password) {
  if (password.length > 0) {
    var isValid = PASSWORD_REGX.test(password);
    if (!isValid) {
      password = "Min 8 char, 1 Alphabet Caps, 1 Special char, & 1 number";
    } else {
      password = "";
    }
  } else password = "Please enter Password";

  return password;
}

export function validateRenterEmail(email) {
  if (email.length > 0) {
    var isValid = validEmailRegEx.test(email.trim());
    if (!isValid) {
      email = "Please enter a valid Email";
    } else {
      email = "";
    }
  } else email = "Please re-enter email";
  return email;
}

// Returns true if any one of the attritue value is truthy
export const checkObjectHasValue = (obj) => {
  for (const key in obj) {
    if (obj[key]) {
      return true;
    }
  }
  return false;
};

// check if the give date string is valid
export const validateDate = (dateString) => {
  const dateRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;

  if (!dateRegex.test(dateString)) {
    return false;
  }

  const [day, month, year] = dateString.split("/");
  const enteredDate = new Date(`${year}-${month}-${day}`);

  return (
    enteredDate.getDate() === parseInt(day, 10) &&
    enteredDate.getMonth() + 1 === parseInt(month, 10) &&
    enteredDate.getFullYear() === parseInt(year, 10)
  );
};

// check if given date string in `MM/YYYY` format or equal to N/A
export const checkValidMonthYear = (dateString) => {
  if (dateString === "N/A") return true;

  const monthYearRegex = /^(0[1-9]|1[0-2])\/\d{4}$/;

  if (monthYearRegex.test(dateString)) {
    const [month, year] = dateString.split("/");
    const numericMonth = parseInt(month, 10);
    const numericYear = parseInt(year, 10);

    // Check if the month is valid (1-12) and the year is a valid number
    return numericMonth >= 1 && numericMonth <= 12 && !isNaN(numericYear);
  }

  return false;
};

export const toTitleCase = (str) => {
  if(!str) return "";
  
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const convertDateFormat = (inputDate) => {
  if (validateDate(inputDate)) return inputDate;

  const dateParts = inputDate.split("/");

  const month = dateParts[1].length === 1 ? "0" + dateParts[1] : dateParts[1];

  const formattedDate = dateParts[0] + "/" + month + "/" + dateParts[2];

  return formattedDate;
};

// Sorts array of object by a specific attribute.
export const sortByAttribute = (array, attribute) => array.sort((a, b) => {
  if (a[attribute] < b[attribute]) {
      return -1;
  }
  if (a[attribute] > b[attribute]) {
      return 1;
  }
  return 0;
});

// To calculate number of days between two dates
export const calculateDaysDifference = (startDateMillis, endDateMillis) => {
  const startDate = moment(startDateMillis);
  const endDate = moment(endDateMillis);

  // Calculate the difference in days
  const daysDifference = endDate.diff(startDate, 'days');

  return daysDifference;
};