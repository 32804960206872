import { Box, Button, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  blueColor04,
  greyColor8,
  mobilePrimary,
  whiteColor,
} from "../../../../configs/styles/muiThemes";

function ConfirmationAlert({
  title,
  msg,
  total,
  quantity,
  originalQuantity,
  cancelledQuantity,
  handleCancel,
  handleOk,
  okLoading,
  boxCount,
}) {
  return (
    <Box sx={styles.card}>
      <Typography fontSize={15} fontWeight={600} color={blackColor} mb={1.5}>
        {title}
      </Typography>
      <Typography fontSize={14} color={blackColor}>
        {msg}
      </Typography>
      <Box display={"flex"} columnGap={3} rowGap={1} mt={0.8} flexWrap={"wrap"}>
        <Typography
          fontSize={13}
          fontWeight={500}
          color={blackColor}
          sx={{ display: "inline" }}
        >
          Total:{" "}
          <Typography
            fontSize={13}
            fontWeight={600}
            color={blackColor}
            sx={{ display: "inline" }}
          >
            {total}
          </Typography>
        </Typography>
        {quantity && (
          <Typography
            fontSize={13}
            fontWeight={500}
            color={blackColor}
            sx={{ display: "inline" }}
          >
            Quantity:{" "}
            <Typography
              fontSize={13}
              fontWeight={600}
              color={blackColor}
              sx={{ display: "inline" }}
            >
              {quantity}
            </Typography>
          </Typography>
        )}
        {originalQuantity && (
          <Typography
            fontSize={13}
            fontWeight={500}
            color={blackColor}
            sx={{ display: "inline" }}
          >
            Original Quantity:{" "}
            <Typography
              fontSize={13}
              fontWeight={600}
              color={blackColor}
              sx={{ display: "inline" }}
            >
              {originalQuantity}
            </Typography>
          </Typography>
        )}
        {cancelledQuantity && (
          <Typography
            fontSize={13}
            fontWeight={500}
            color={blackColor}
            sx={{ display: "inline" }}
          >
            Canceled Quantity:{" "}
            <Typography
              fontSize={13}
              fontWeight={600}
              color={blackColor}
              sx={{ display: "inline" }}
            >
              {cancelledQuantity}
            </Typography>
          </Typography>
        )}
        {boxCount && (
          <Typography
            fontSize={13}
            fontWeight={500}
            color={blackColor}
            sx={{ display: "inline" }}
          >
            No. of boxes:{" "}
            <Typography
              fontSize={13}
              fontWeight={600}
              color={blackColor}
              sx={{ display: "inline" }}
            >
              {boxCount}
            </Typography>
          </Typography>
        )}
      </Box>
      <Box display={"flex"} gap={1} mt={2} justifyContent="flex-end">
        <Button sx={styles.alertCancelBtn} onClick={handleCancel}>
          NO
        </Button>
        <Button sx={styles.alertOkBtn} onClick={handleOk} disabled={okLoading}>
          YES
        </Button>
      </Box>
    </Box>
  );
}

export default ConfirmationAlert;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "12px",
    padding: "16px",
  },
  alertOkBtn: {
    padding: { md: "4px 22px", xs: "4px 10px" },
    fontSize: "13px",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    border: {
      xs: `1px solid ${mobilePrimary}`,
      md: `1px solid ${blueColor04}`,
    },
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
      border: {
        xs: `1px solid ${mobilePrimary}`,
        md: `1px solid ${blueColor04}`,
      },
    },
  },
  alertCancelBtn: {
    padding: { md: "4px 22px", xs: "4px 10px" },
    fontSize: "13px",
    fontWeight: 600,
    backgroundColor: whiteColor,
    color: { xs: mobilePrimary, md: blueColor04 },
    border: {
      xs: `1px solid ${mobilePrimary}`,
      md: `1px solid ${blueColor04}`,
    },
    "&:hover": {
      backgroundColor: { xs: mobilePrimary, md: blueColor04 },
      color: whiteColor,
      border: {
        xs: `1px solid ${mobilePrimary}`,
        md: `1px solid ${blueColor04}`,
      },
    },
  },
};
