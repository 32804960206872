import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  greyColor6,
  greyColor8,
  logoImgborder1,
  orangeStatusColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ProfileCharAvatar from "../../../components/ProfileCharAvatar";

function NewIssueRaised({ handleViewIssue, }) {
  const partnerPic =
    "https://storage.googleapis.com/bo3151920215/account/4d60cacd94144abb91782ce7fbf37ace/profilephoto.jpeg";
  const partnerName = "Vishnupriya";
  return (
    <Box>
      <Typography
        component="h4"
        fontSize={15}
        color={blackShade05}
        fontWeight={600}
        lineHeight="20px"
        mt={2}
      >
        New - 15
      </Typography>
      <Box sx={styles.infoBox}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <Box display="flex" gap="10px" alignItems="center">
              {partnerPic ? (
                <Avatar
                  alt="remy sharp"
                  variant="rounded"
                  src={partnerPic}
                  sx={{
                    width: "45px",
                    height: "45px",
                  }}
                />
              ) : (
                <ProfileCharAvatar
                  username={partnerName || "Guest"}
                  border
                  size="50px"
                />
              )}
              <Typography
                fontSize={{ xs: 13.5, md: 14.5 }}
                fontWeight={600}
                color={blackShade05}
              >
                {partnerName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={1.8}>
            <IssueDetails label="Order ID" labelValue={"OD9949872021"} />
          </Grid>
          <Grid item xs={6} md={1.8}>
            <IssueDetails label="Issue ID" labelValue={"ISE9949872021"} />
          </Grid>
          <Grid item xs={6} md={1.8}>
            <IssueDetails label="Issue Date" labelValue={"02 Nov, 02:35 PM"} />
          </Grid>
          <Grid item xs={6} md={1.6}>
            <IssueDetails label="Issue Type" labelValue={"Package"} />
          </Grid>
          <Grid item xs={6} md={1.5}>
            <IssueDetails
              label="Status"
              labelValue={"Pending"}
              labelValueColor={orangeStatusColor}
            />
          </Grid>
          <Grid item xs={6} md={1.5}>
            <Box display="flex" alignItems="center">
              <Button
                sx={styles.addIconBtn}
                variant="contained"
                disableElevation
                onClick={handleViewIssue}
              >
                VIEW
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default NewIssueRaised;

const IssueDetails = ({ label, labelValue,labelValueColor }) => {
  return (
    <Box flex={1}>
      <Typography variant="h5" sx={styles.valueTitle}>
        {label}
      </Typography>
      <Typography
        sx={{
          ...styles.value,
          color: labelValueColor ? labelValueColor : blackShade05,
        }}
      >
        {labelValue}
      </Typography>
    </Box>
  );
};



const styles = {
  infoBox: {
    backgroundColor: whiteColor,
    baxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    padding: "15px 15px",
    border: logoImgborder1,
    borderRadius: "10px",
    marginTop: "22px",
  },
  valueTitle: {
    color: greyColor6,
    fontSize: "13px",
    fontWeight: "400",
  },

  value: {
    color: blackShade05,
    fontSize: "13px",
    fontWeight: "500",
  },
  updateBtn: {
    backgroundColor: "transparent",
    padding: "6px 22px",
    fontSize: "13px",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: greyColor8,
    border: "1.5px solid " + greyColor8,
    "&:hover": {
      color: greyColor8,
      backgroundColor: whiteColor,
    },
  },
  addIconBtn: {
    padding: "5px 24px",
    fontSize: "12px",
    fontWeight: 600,
    color: greyColor8,
    backgroundColor: whiteColor,
    border: "1px solid " + greyColor8,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },
};
