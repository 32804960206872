import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BusinessInfoCard from "../components/Cards/BusinessInfoCard";
import {
  BGColor02,
  blackColor,
  blueColor04,
  greyColor40,
  mobilePrimary,
  primaryColor,
  whiteColor,
} from "../configs/styles/muiThemes";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import AddProductForm from "./Catalog/Forms/AddProductForm";
import CategorySpecsForm from "./Catalog/Forms/CategorySpecsForm";
import MeasurementDeliveryForm from "./Catalog/Forms/MeasurementDeliveryForm";
import PriceTaxInfoForm from "./Catalog/Forms/PriceTaxInfoForm";
import DashboardHeader from "./Dashboard/DashboardHeader";
import DashboardMenu from "./Dashboard/DashboardMenu";
import {
  addNewProductApi,
  deleteProductPhotoApi,
  getReviewProductDetailsAPI,
  getUnitOfMeasurementApi,
  getViewProductDetailsAPI,
  uploadProductApi,
  uploadProductPhotoApi,
} from "../service/api_calls/catalog/CatalogAPI";
import {
  getAddNewProductPostData,
  getCancellableFeeType,
  getTimeToShipUnitValue,
  getTimeToShipValue,
  getUpdateProductPostAPIData,
  getUpdateProductPostData,
  prepareAddNewProductPhotoData,
  prepareAddProductPhotoData,
} from "../utils/postDataHelpers/CatalogHelper";
import {
  getAllDeliveryMethods,
  getCountryNames,
  getGstRates,
} from "../reducers/catalog/CatalogSlice";
import ModalCard from "../components/ModalCard";
import DataPreview from "./Catalog/Forms/DataPreview";
import { convertImagesToBase64 } from "../utils/imageCropUtils/imgUrlToBase64";
import ThreeDotLoader from "../components/ThreeDotLoader";
import ProductAvailabilityForm from "./Catalog/Forms/ProductAvailabilityForm";
import moment from "moment";
import ONDCFrom from "./Catalog/Forms/ONDCFrom";
import { ONDC_DEFAULT_DATA } from "../utils/data/defaultFormData";
import {
  DEFAULT_COUNTRY,
  PRODUCT_CODE_UNIT_LIST,
  UNITS_OF_MEASURE,
} from "../utils/data";
import { getProductSubCategoryOneAPI } from "../service/api_calls/AddProduct/AddProductAPI";
import {
  getSelectedCountryByCode,
  isStringValidDateFormat,
} from "../utils/helper";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import { stickyHeader } from "../utils/styles";
import DashboardStepper from "./Dashboard/DashboardStepper";
import {
  CATALOG_ADD_PRODUCT_STEP_LIST,
  CATALOG_STEP_LIST,
} from "../utils/appData";
import BreadcrumbSection from "../components/Breadcrumb/BreadcrumbSection";

function Catalog() {
  const businessName = sessionStorage.getItem("costboWEBURL");

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [currentPage, setCurrentPage] = useState("product-detail");

  const [productImgUpdate, setProductImgUpdate] = useState([]);
  const [deleteImgList, setDeleteImgList] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [addProductLoader, setAddProductLoader] = useState(false);

  const location = useLocation();
  const contentRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  const { countryList, countryListLoading, gstRates, allDeliveryMethod } =
    useSelector((state) => state.catalog);

  const [productDetails, setProductDetails] = useState(null);
  const [productDetailsLoader, setProductDetailsLoader] = useState(false);

  // ONDC subcat data
  const [ondcProductSubCatList, setOndcProductSubCatList] = useState([]);

  const [unitOfMeasurementData, setUnitOfMeasurementData] = useState([]);

  // Fetching product details
  const getProductDetails = async () => {
    setProductDetailsLoader(true);
    const productId = location?.state?.productId;
    const isRejectedProduct = location?.state?.isRejectedProduct || false;

    const businessId = sessionStorage.getItem("selectedBusinessId");

    if (isRejectedProduct) {
      const data = await getReviewProductDetailsAPI({ businessId, productId });
      if (data?.details) {
        const productMainCat = data?.details?.productMainCategory?.filter(
          (item) => item?.status == "active"
        );

        if (productMainCat?.length > 0 && productMainCat?.[0]?.ondcCode) {
          const ondcSubCatData = await getProductSubCategoryOneAPI(
            productMainCat?.[0]?.ondcCode
          );
          setOndcProductSubCatList(ondcSubCatData?.details || []);
        }

        setProductDetails(data?.details);
        setProductDetailsLoader(false);
      }
    } else {
      const data = await getViewProductDetailsAPI(productId);
      if (data?.product) {
        const productMainCat = data?.product?.productMainCategory?.filter(
          (item) => item?.status == "active"
        );

        if (productMainCat?.length > 0 && productMainCat?.[0]?.ondcCode) {
          const ondcSubCatData = await getProductSubCategoryOneAPI(
            productMainCat?.[0]?.ondcCode
          );

          console.log("ondcSubCatData___:", ondcSubCatData);
          setOndcProductSubCatList(ondcSubCatData?.details || []);
        }

        setProductDetails(data?.product);
        setProductDetailsLoader(false);
      }
    }
  };

  const [productInfo, setProductInfo] = useState({
    productName: "",
    brand: "",
    description: "",
    country: DEFAULT_COUNTRY,
    skuId: "",
    productImgOne: "",
    productImgTwo: "",
    productImgThree: "",
    productImgFour: "",

    errors: null,
  });

  const [categoryInfo, setCategoryInfo] = useState({
    businessCategory: [],
    productMainCat: [],
    productSubCatOne: [],
    productSubCatTwo: [],
    selectedBusinessCat: "",
    selectedProductSubCat1: "",
    selectedProductSubCat2: "",

    productSpecs: [{ code: "", value: [""] }],
    refLinks: [{ code: "", link: "" }],

    errors: null,
  });

  const [priceInfo, setPriceInfo] = useState({
    currency: "₹",
    originalPrice: "",
    OfferPrice: "",
    taxDetails: "",
    gstHsnCode: "",

    errors: null,
  });

  const [measurementInfo, setMeasurementInfo] = useState({
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
    deliveryMethod: [],

    errors: null,
  });

  const [availabilityInfo, setAvailabilityInfo] = useState({
    availability: "",
    availabelQty: 0,
    alertQty: 0,
    minQty: 1,
    maxQty: 0,
    startDate: "",
    endDate: "",

    errors: null,
  });

  const [ondcInfo, setOndcInfo] = useState(ONDC_DEFAULT_DATA);

  const [ondcUIControl, setOndcUIControl] = useState({
    timeToShipUnit: {
      label: "Minutes",
      unit: "M",
    },
    cancellationFeeType: "Select Fee Type",
    isFoodRelatedItems: "none",
    isPackaged: "none",
    isPrePackaged: "none",
  });

  const [subscriptionInfo, setSubscriptionInfo] = useState({
    subType: [],
    originalValue: "",
    offerValue: "",
    cancelTime: "",
    cancelDays: "",
    serviceFee: 0,
    specialFee: 0,
    packingFee: 0,
    startDate: "",
    endDate: "",
    isProductSubscribed: false,

    errors: null,
  });

  const [previewErrorMsg, setPreviewErrorMsg] = useState(null);
  const [productPreviewData, setProductPreviewData] = useState(null);

  const handleSetValue = (name, value, state, setState) => {
    if (name !== "errors") {
      setState({
        ...state,
        [name]: value,
        errors: { ...state?.errors, [name]: "" },
      });
      return;
    }

    setState({
      ...state,
      [name]: value,
    });
  };

  // Update product image update flag
  const onProductImageUpdate = (data) => {
    console.log("DATA___:", data);
    const imgFlagList = [...productImgUpdate];

    let isImgExist = false;

    // let imgIndex = -1;

    // imgFlagList.forEach((item, index) => {
    //   if (data?.index === item?.index) {
    //     console.log("HTTR___");
    //     imgIndex = index;
    //   }
    // });

    // if (imgIndex === -1) {
    //   imgFlagList.push(data);
    // } else {
    //   imgFlagList.splice(imgIndex, 1);
    // }

    const updatedImgList = imgFlagList.map((item, index) => {
      if (data?.index === item?.index) {
        isImgExist = true;
        return {
          ...data,
        };
      } else {
        return item;
      }
    });

    if (!isImgExist) {
      updatedImgList.push(data);
    }

    console.log("imgFlagList_data:", data);
    // console.log("imgFlagList_index:", imgIndex);
    console.log("imgFlagList___:", imgFlagList);
    console.log("updatedImgList___:", updatedImgList);
    setProductImgUpdate(updatedImgList);
  };

  // Converting all photo urls to base64 string
  const convertURLtoBase64String = async (photos) => {
    const selector = [
      "productImgOne",
      "productImgTwo",
      "productImgThree",
      "productImgFour",
    ];

    const base64StringList = await convertImagesToBase64(photos);
    console.log("base64StringList:", base64StringList);
    console.log("PRODUCT_IMAGES___:", photos);

    const photoListBase64 = [];

    // Removing inactive photos
    const filteredList = photos?.filter(
      (photo) => photo?.status !== "inactive"
    );

    filteredList.forEach((item, index) => {
      const data = {
        dataURL: base64StringList[index],
        fileDetails: {
          name: item?.name,
          type: "image/jpeg",
        },
        index: index + 1,
        selector: selector[index],
        docType: item?.docType,
        docURL: item?.docURL,
        // id and key for drag & drop
        id: index + 1,
        key: selector[index],
      };

      photoListBase64.push(data);
    });

    setProductImgUpdate(photoListBase64);
    console.log("photoListWithBase64:", photoListBase64);
  };

  useEffect(() => {
    if (productDetails && countryList?.length > 0) {
      if (productDetails?.productPhoto?.length > 0) {
        if (!location.state?.isCopyProduct) {
          convertURLtoBase64String(productDetails?.productPhoto);
        }
      }

      if (location.state?.isCopyProduct) {
        setProductInfo({
          copyProductName: productDetails?.productName || "",

          productName: "",
          brand: productDetails?.brand || "",
          description: productDetails?.productDescription || "",
          country: getSelectedCountryByCode(
            productDetails?.origin,
            countryList
          ),
          skuId: productDetails?.skuId || "",

          productImgOne: "",
          productImgTwo: "",
          productImgThree: "",
          productImgFour: "",
        });
      } else {
        // Removing inactive photos
        const filteredPhotoList = productDetails?.productPhoto?.filter(
          (photo) => photo?.status !== "inactive"
        );

        setProductInfo({
          productName: productDetails?.productName || "",
          brand: productDetails?.brand || "",
          description: productDetails?.productDescription || "",
          country: getSelectedCountryByCode(
            productDetails?.origin,
            countryList
          ),
          skuId: productDetails?.skuId || "",

          // productImgOne: productDetails?.productPhoto?.[0]?.docURL || "",
          // productImgTwo: productDetails?.productPhoto?.[1]?.docURL || "",
          // productImgThree: productDetails?.productPhoto?.[2]?.docURL || "",
          // productImgFour: productDetails?.productPhoto?.[3]?.docURL || "",

          productImgOne: filteredPhotoList?.[0]?.docURL || "",
          productImgTwo: filteredPhotoList?.[1]?.docURL || "",
          productImgThree: filteredPhotoList?.[2]?.docURL || "",
          productImgFour: filteredPhotoList?.[3]?.docURL || "",
        });
      }

      let specs = [];

      if (
        productDetails?.label?.length === 1 &&
        productDetails?.label?.[0]?.value?.length === 0
      ) {
        specs = [{ code: "", value: [""] }];
      } else {
        specs =
          productDetails?.label?.length > 0
            ? productDetails?.label
            : [{ code: "", value: [""] }];
      }

      setCategoryInfo((prevState) => ({
        ...prevState,
        productMainCat: productDetails?.productMainCategory || [],
        productSubCatOne: productDetails?.productSubCategory || [],
        productSubCatTwo: productDetails?.productSubCategory2 || [],
        // productSpecs:
        //   productDetails?.label?.length > 0
        //     ? productDetails?.label
        //     : [{ code: "", value: [""] }],
        productSpecs: specs,

        refLinks:
          productDetails?.refLink?.length > 0
            ? productDetails?.refLink
            : [{ code: "", link: "" }],
      }));

      setPriceInfo({
        currency: productDetails?.currencySymbol || "₹",
        originalPrice: productDetails?.productOriginalPrice || "",
        OfferPrice: productDetails?.productSalePrice || "",
        taxDetails: productDetails?.taxDetails?.[0]?.value || false,
        gstHsnCode: productDetails?.gstInfo?.code || "",
        gstPercentage: productDetails?.gstInfo?.gstPercentage || "",
      });

      setMeasurementInfo({
        weight: productDetails?.netProductWeight || "",
        length: productDetails?.measurementInfo?.length || 0,
        width: productDetails?.measurementInfo?.width || 0,
        height: productDetails?.measurementInfo?.height || 0,
        deliveryMethod: productDetails?.deliveryType || [],
      });

      setAvailabilityInfo({
        availability: productDetails?.availability?.[0] || "",
        availabelQty: productDetails?.availableQuantity || 0,
        alertQty: productDetails?.alertQuantity || 0,
        minQty: productDetails?.minOrderQuantity || 1,
        maxQty: productDetails?.maxOrderQuantity || 0,
        startDate: productDetails?.commerceStartDate || "",
        endDate: productDetails?.commerceEndDate || "",
      });

      const ondcData = productDetails?.ondc;

      if (productDetails?.ondc) {
        const unitSymbol =
          ondcData?.timeToShip?.charAt(ondcData?.timeToShip?.length - 1) || "";

        const isPackagedData = ondcData?.statutoryReqsPackagedCommodities;
        const isPrePackagedData = ondcData?.statutoryReqsPrepackagedFood;

        setOndcUIControl({
          timeToShipUnit: getTimeToShipUnitValue(unitSymbol),
          cancellationFeeType: getCancellableFeeType(ondcData?.cancelTerms),
          isFoodRelatedItems:
            ondcData?.shelfLife || ondcData?.fssai?.[0]?.number ? "yes" : "no",
          isPackaged:
            isPackagedData?.manufacturer_or_packer_name ||
            isPackagedData?.manufacturer_or_packer_address ||
            isPackagedData?.common_or_generic_name_of_commodity ||
            isPackagedData?.mfg_license_no ||
            isPackagedData?.net_quantity_or_measure_of_commodity_in_pkg
              ? "yes"
              : "no",
          isPrePackaged:
            isPrePackagedData?.ingredients_info ||
            isPrePackagedData?.nutritional_info ||
            isPrePackagedData?.additives_info ||
            isPrePackagedData?.brand_owner_FSSAI_license_no ||
            isPrePackagedData?.other_FSSAI_license_no ||
            isPrePackagedData?.importer_FSSAI_license_no
              ? "yes"
              : "no",
        });

        // const importMfnDate =
        // ondcData?.statutoryReqsPackagedCommodities
        //   ?.month_year_of_manufacture_packing_import;

        // const parsedDate = moment(importMfnDate, "MM/YYYY");

        // const mfnImportDateMilliseconds = parsedDate.valueOf();

        const activeMainCat =
          productDetails?.productMainCategory?.filter(
            (item) => item?.status == "active"
          ) || [];

        const activeSubCat =
          productDetails?.productSubCategory?.filter(
            (item) => item?.status == "active"
          ) || [];

        setOndcInfo({
          ...ONDC_DEFAULT_DATA,

          productDisplayUnitOfMeasure: {
            unit:
              productDetails?.productDisplayUnitOfMeasure?.unit === "gms"
                ? "gram"
                : productDetails?.productDisplayUnitOfMeasure?.unit || "",
            value: productDetails?.productDisplayUnitOfMeasure?.value || "",
          },

          netProductWeight: productDetails?.measurementInfo?.weight || 0,
          ondcEnabled: productDetails?.ondcEnabled || "no",

          ondcProductCategory: {
            name:
              activeMainCat?.[0]?.ondcValue || "Select ONDC Product Category",
            code: activeMainCat?.[0]?.ondcCode || "",
            status: "",
          },
          ondcSubCatList: ondcProductSubCatList || [],
          ondcProductSubCategory: {
            productSubCategory1: activeSubCat?.[0]?.ondcSubValue,
            scCode1: activeSubCat?.[0]?.ondcSubCode,
          },

          ondc: {
            available_on_cod: ondcData?.available_on_cod,
            discount: ondcData?.discount || "",
            fragile: ondcData?.fragile == true ? "yes" : "no",
            shelfLife: ondcData?.shelfLife || "",
            // timeToShip: ondcData?.timeToShip
            //   ? ondcData?.timeToShip?.slice(2, -1)
            //   : "",
            timeToShip: getTimeToShipValue(unitSymbol, ondcData?.timeToShip),

            cancelTerms: {
              cancellable: ondcData?.cancelTerms?.cancellable || "none",
              // cancellationFeeAmount:
              //   ondcData?.cancelTerms?.cancellationFeeAmount || "",
              cancellationFeeAmount: null,
              cancellationFeePercentage:
                ondcData?.cancelTerms?.cancellationFeePercentage || "",
              refundEligible: ondcData?.cancelTerms?.refundEligible || "none",
            },

            veg_nonveg: {
              veg: ondcData?.veg_nonveg?.veg || "no",
              nonVeg: ondcData?.veg_nonveg?.nonVeg || "no",
              // egg: ondcData?.veg_nonveg?.egg || "no",
              egg: "no",
            },

            fssai: [
              {
                number: ondcData?.fssai?.[0]?.number || "",
                // validTill: ondcData?.fssai?.[0]?.validTill || "",
                validTill: null,
              },
            ],

            productCode: {
              key: ondcData?.productCode?.key || "EAN",
              value: ondcData?.productCode?.value || "",
            },

            returnTerms: {
              returnable: ondcData?.returnTerms?.returnable || "none",
              returnPeriod: ondcData?.returnTerms?.returnPeriod
                ? ondcData?.returnTerms?.returnPeriod?.slice(1, -1)
                : "",
              seller_pickup_return:
                ondcData?.returnTerms?.seller_pickup_return || "none",
            },

            statutoryReqsPackagedCommodities: {
              manufacturer_or_packer_name:
                ondcData?.statutoryReqsPackagedCommodities
                  ?.manufacturer_or_packer_name || "",
              manufacturer_or_packer_address:
                ondcData?.statutoryReqsPackagedCommodities
                  ?.manufacturer_or_packer_address || "",
              common_or_generic_name_of_commodity:
                ondcData?.statutoryReqsPackagedCommodities
                  ?.common_or_generic_name_of_commodity || "",
              mfg_license_no:
                ondcData?.statutoryReqsPackagedCommodities?.mfg_license_no ||
                "",

              expiry_date:
                ondcData?.statutoryReqsPackagedCommodities?.expiry_date || "",
              month_year_of_manufacture_packing_import:
                ondcData?.statutoryReqsPackagedCommodities
                  ?.month_year_of_manufacture_packing_import || "",

              net_quantity_or_measure_of_commodity_in_pkg:
                ondcData?.statutoryReqsPackagedCommodities
                  ?.net_quantity_or_measure_of_commodity_in_pkg || "",
              multiple_products_name_number_or_qty:
                ondcData?.statutoryReqsPackagedCommodities
                  ?.multiple_products_name_number_or_qty || "",
            },

            statutoryReqsPrepackagedFood: {
              ingredients_info:
                ondcData?.statutoryReqsPrepackagedFood?.ingredients_info || "",
              nutritional_info:
                ondcData?.statutoryReqsPrepackagedFood?.nutritional_info || "",
              additives_info:
                ondcData?.statutoryReqsPrepackagedFood?.additives_info || "",
              brand_owner_FSSAI_license_no:
                ondcData?.statutoryReqsPrepackagedFood
                  ?.brand_owner_FSSAI_license_no || "",
              other_FSSAI_license_no:
                ondcData?.statutoryReqsPrepackagedFood
                  ?.other_FSSAI_license_no || "",
              importer_FSSAI_license_no:
                ondcData?.statutoryReqsPrepackagedFood
                  ?.importer_FSSAI_license_no || "",
            },
          },
        });
      }

      setSubscriptionInfo({
        subType: productDetails?.subscription?.type || [],
        originalValue: productDetails?.subscription?.originalValue || 0,
        offerValue: productDetails?.subscription?.offerValue || 0,
        cancelTime: productDetails?.subscription?.cancelTime || "",
        cancelDays: productDetails?.subscription?.cancelDays || 1,
        serviceFee: productDetails?.subscription?.serviceFee || 0,
        specialFee: productDetails?.subscription?.specialFee || 0,
        packingFee: productDetails?.subscription?.packingFee || 0,
        startDate: productDetails?.subscription?.startDate || "",
        endDate: productDetails?.subscription?.endDate || "",
        isProductSubscribed:
          productDetails?.subscription?.subscription || false,
      });
    }
    return () => {};
  }, [productDetails, countryList]);

  // To handle Product Info Next Click
  const handleProductInfoSubmit = (isNavDisabled = false) => {
    let errors = null;
    let categoryErrors = null;

    if (!productInfo?.productName) {
      errors = { ...errors, productName: true };
    }
    // if (!productInfo?.brand) {
    //   errors = { ...errors, brand: true };
    // }
    if (!productInfo?.description) {
      errors = { ...errors, description: true };
    }
    if (!productInfo?.country) {
      errors = { ...errors, country: true };
    }
    // if (!productInfo?.skuId) {
    //   errors = { ...errors, skuId: true };
    // }
    if (
      !productInfo?.productImgOne &&
      !productInfo?.productImgTwo &&
      !productInfo?.productImgThree &&
      !productInfo?.productImgFour
    ) {
      errors = { ...errors, productImg: true };
    }

    console.log(productInfo);
    console.log("Errors:", errors);

    if (!categoryInfo?.selectedBusinessCat) {
      categoryErrors = { ...categoryErrors, selectedBusinessCat: true };
    }
    if (!categoryInfo?.selectedProductSubCat1) {
      categoryErrors = { ...categoryErrors, selectedProductSubCat1: true };
    }
    // if (!categoryInfo?.selectedProductSubCat2) {
    //   categoryErrors = { ...categoryErrors, selectedProductSubCat1: true };
    // }

    console.log("categoryInfo:", categoryInfo);

    if (errors || categoryErrors) {
      if (errors) {
        handleSetValue("errors", errors, productInfo, setProductInfo);
      }

      if (categoryErrors) {
        handleSetValue("errors", categoryErrors, categoryInfo, setCategoryInfo);
      }

      return false;
    } else {
      handleSetValue("errors", null, productInfo, setProductInfo);
      handleSetValue("errors", null, categoryInfo, setCategoryInfo);

      if (isNavDisabled) {
        return true;
      }

      setPreviewErrorMsg(null);
      setCurrentPage("price-tax-info");
      contentRef.current.scrollIntoView();
    }
  };

  // To handle Category Info Next Click
  const handleCategoryInfoSubmit = (isNavDisabled = false) => {
    let errors = null;

    // if (!categoryInfo?.selectedBusinessCat) {
    //   errors = { ...errors, selectedBusinessCat: true };
    // }
    // if (!categoryInfo?.selectedProductSubCat1) {
    //   errors = { ...errors, selectedProductSubCat1: true };
    // }
    // if (!categoryInfo?.selectedProductSubCat1) {
    //   errors = { ...errors, selectedProductSubCat1: true };
    // }

    const isSpecError = categoryInfo?.productSpecs?.some(
      (item) =>
        (item?.code === "" && item?.value?.[0] !== "") ||
        (item?.code !== "" && item?.value?.[0] === "")
    );

    if (isSpecError) {
      errors = { ...errors, productSpecs: "Enter the label and value" };
    }

    const isRefLinkError = categoryInfo?.refLinks?.some(
      (item) =>
        (item?.code === "" && item?.link !== "") ||
        (item?.code !== "" && item?.link === "")
    );

    // console.log("isRefLinkError__:", isRefLinkError);
    if (isRefLinkError) {
      errors = { ...errors, refLinks: "Enter the label and value" };
    }

    console.log(categoryInfo);

    if (errors) {
      handleSetValue("errors", errors, categoryInfo, setCategoryInfo);
      return false;
    } else {
      handleSetValue("errors", null, categoryInfo, setCategoryInfo);

      if (isNavDisabled) {
        return true;
      }

      // previewData();
      setPreviewErrorMsg(null);
      setCurrentPage("ondc");
      // setCurrentPage("subscription-info");
      // contentRef.current.scrollIntoView();
    }
  };

  // To handle Price & Tax Info Next Click
  const handlePriceInfoSubmit = (isNavDisabled = false) => {
    let errors = null;
    let ondcErrors = null;

    if (location.state?.isCopyProduct) {
      if (!priceInfo?.currency) {
        errors = { ...errors, currency: true };
      }

      if (!priceInfo?.originalPrice) {
        errors = { ...errors, originalPrice: "Please fill out this field" };
      }

      if (priceInfo?.originalPrice && Number(priceInfo?.originalPrice) <= 0) {
        errors = {
          ...errors,
          originalPrice: "Original price should be greater than 0",
        };
      }

      if (!priceInfo?.OfferPrice) {
        errors = { ...errors, OfferPrice: true };
      }

      if (Number(priceInfo?.OfferPrice) > Number(priceInfo?.originalPrice)) {
        errors = { ...errors, priceError: true };
      }
    }
    if (!priceInfo?.gstPercentage) {
      errors = { ...errors, gstPercentage: true };
    }

    const isKeyValid = PRODUCT_CODE_UNIT_LIST?.filter(
      (item) => item?.label === ondcInfo?.ondc?.productCode?.key
    );

    if (isKeyValid?.length === 0) {
      errors = { ...errors, productCode1: "Select code" };
    }

    if (!ondcInfo?.ondc?.productCode?.value) {
      errors = { ...errors, productCode: "Enter product code" };
    }
    if (
      ondcInfo?.ondc?.productCode?.key === "EAN" &&
      ondcInfo?.ondc?.productCode?.value?.length != 13
    ) {
      errors = {
        ...errors,
        productCode: "Enter a valid 13 digit EAN code",
      };
    }
    if (
      ondcInfo?.ondc?.productCode?.key === "ISBN" &&
      ondcInfo?.ondc?.productCode?.value?.length != 13
    ) {
      errors = {
        ...errors,
        productCode: "Enter a valid 13 digit ISBN code",
      };
    }
    if (
      ondcInfo?.ondc?.productCode?.key === "GTIN" &&
      ondcInfo?.ondc?.productCode?.value?.length != 12
    ) {
      errors = {
        ...errors,
        productCode: "Enter a valid 12 digit GTIN code",
      };
    }
    if (
      ondcInfo?.ondc?.productCode?.key === "HSN" &&
      ondcInfo?.ondc?.productCode?.value?.length != 8
    ) {
      errors = {
        ...errors,
        productCode: "Enter a valid 8 digit HSN code",
      };
    }
    if (
      ondcInfo?.ondc?.productCode?.key === "OTHERS" &&
      ondcInfo?.ondc?.productCode?.value?.length != 13
    ) {
      errors = {
        ...errors,
        productCode: "Enter a valid 13 digit code",
      };
    }

    console.log(priceInfo);

    if (errors) {
      console.log("hit-1");
      handleSetValue("errors", errors, priceInfo, setPriceInfo);
    } else {
      console.log("hit0");
      handleSetValue("errors", null, priceInfo, setPriceInfo);
    }

    if (ondcErrors) {
      console.log("hit1");
      handleSetValue("errors", ondcErrors, ondcInfo, setOndcInfo);
    } else {
      console.log("hit2");
      handleSetValue("errors", null, ondcInfo, setOndcInfo);
    }

    if (errors || ondcErrors) {
      console.log("hit3");
      return false;
    } else {
      if (isNavDisabled) {
        console.log("hit4");
        return true;
      }

      setPreviewErrorMsg(null);
      setCurrentPage("measurement-delivery-method");
      contentRef.current.scrollIntoView();
    }
  };

  // To handle Measure Info Next Click
  const handleMeasurementInfoSubmit = (isNavDisabled = false) => {
    let errors = null;
    let ondcErrors = null;

    if (!measurementInfo?.weight || measurementInfo?.weight == 0) {
      errors = { ...errors, weight: true };
    }
    // if (!measurementInfo?.length) {
    //   errors = { ...errors, length: true };
    // }
    // if (!measurementInfo?.width) {
    //   errors = { ...errors, width: true };
    // }
    // if (!measurementInfo?.height) {
    //   errors = { ...errors, height: true };
    // }
    // if (measurementInfo?.deliveryMethod?.length === 0) {
    //   errors = { ...errors, deliveryMethod: true };

    const isUnitValid = unitOfMeasurementData?.filter(
      (item) => item?.value === ondcInfo?.productDisplayUnitOfMeasure?.unit
    );

    if (isUnitValid?.length === 0) {
      errors = {
        ...errors,
        productDisplayUnitOfMeasure1: "Select unit",
      };
    }
    if (!ondcInfo?.productDisplayUnitOfMeasure?.unit) {
      errors = {
        ...errors,
        productDisplayUnitOfMeasure1: "Select unit",
      };
    }

    if (
      !ondcInfo?.productDisplayUnitOfMeasure?.value ||
      ondcInfo?.productDisplayUnitOfMeasure?.value == "0"
    ) {
      errors = {
        ...errors,
        productDisplayUnitOfMeasure: "Enter value",
      };
    }

    if (!ondcInfo?.netProductWeight || ondcInfo?.netProductWeight == "0") {
      errors = {
        ...errors,
        netProductWeight: "Enter net product weight",
      };
    }
    if (
      ondcInfo?.netProductWeight > "0" &&
      Number(ondcInfo?.netProductWeight) < Number(measurementInfo?.weight)
    ) {
      errors = {
        ...errors,
        netProductWeight:
          "Net product weight should be greater than product weight",
      };
    }
    // }

    console.log(measurementInfo);

    if (errors) {
      handleSetValue("errors", errors, measurementInfo, setMeasurementInfo);
    } else {
      handleSetValue("errors", null, measurementInfo, setMeasurementInfo);
    }

    if (ondcErrors) {
      handleSetValue("errors", ondcErrors, ondcInfo, setOndcInfo);
    } else {
      handleSetValue("errors", null, ondcInfo, setOndcInfo);
    }

    if (errors || ondcErrors) {
      return false;
    } else {
      if (isNavDisabled) {
        return true;
      }

      // setCurrentPage("product-availablity");
      if (location.state?.isCopyProduct) {
        setCurrentPage("product-availablity");
      } else {
        setCurrentPage("category-specs");
      }

      setPreviewErrorMsg(null);
      contentRef.current.scrollIntoView();
    }
  };

  // To handle Availability Info Next Click
  const handleAvailabilityInfoSubmit = (isNavDisabled = false) => {
    let errors = null;

    if (!availabilityInfo?.availability) {
      errors = { ...errors, availability: true };
    }
    if (!availabilityInfo?.availabelQty) {
      errors = { ...errors, availabelQty: true };
    }
    if (!availabilityInfo?.alertQty) {
      errors = { ...errors, alertQty: true };
    }
    if (!availabilityInfo?.minQty) {
      errors = { ...errors, minQty: true };
    }
    if (!availabilityInfo?.maxQty) {
      errors = { ...errors, maxQty: true };
    }
    if (!availabilityInfo?.startDate) {
      errors = { ...errors, startDate: true };
    }
    if (!availabilityInfo?.endDate) {
      errors = { ...errors, endDate: true };
    }

    if (
      Number(availabilityInfo?.availabelQty) <=
        Number(availabilityInfo?.alertQty) ||
      Number(availabilityInfo?.availabelQty) < Number(availabilityInfo?.maxQty)
    ) {
      errors = {
        ...errors,
        availabilityError:
          "Available Quantity should be greater than Alert Quantity & Maximum Quantity Per Order",
      };
    }

    if (Number(availabilityInfo?.maxQty) < Number(availabilityInfo?.minQty)) {
      errors = {
        ...errors,
        maxQtyError:
          "Maximum Quantity Per Order should be greater than Minimum Quantity Per Order",
      };
    }

    if (Number(availabilityInfo?.alertQty) < Number(availabilityInfo?.minQty)) {
      errors = {
        ...errors,
        alertQtyError: "Alert quantity must be more than Min order quantity",
      };
    }

    if (
      moment(availabilityInfo?.startDate)?.isAfter(
        moment(availabilityInfo?.endDate)
      )
    ) {
      errors = {
        ...errors,
        startDate: "Start date should be less than End date",
      };
    }

    console.log(availabilityInfo);

    if (errors) {
      handleSetValue("errors", errors, availabilityInfo, setAvailabilityInfo);
      return false;
    } else {
      handleSetValue("errors", null, availabilityInfo, setAvailabilityInfo);

      if (isNavDisabled) {
        return true;
      }

      setPreviewErrorMsg(null);
      setCurrentPage("category-specs");
      contentRef.current.scrollIntoView();
    }
  };

  // To handle ONDC Info Next Click
  const handleOndcInfoSubmit = (isNavDisabled = false) => {
    let errors = null;

    if (ondcInfo?.ondcEnabled === "yes") {
      if (
        ondcInfo?.ondcProductCategory?.name == "Select ONDC Product Category"
      ) {
        errors = {
          ...errors,
          ondcProductCategory: "Select ONDC product category",
        };
      }

      if (!ondcInfo?.ondcProductSubCategory?.productSubCategory1) {
        errors = {
          ...errors,
          ondcProductSubCategory: "Select ONDC product sub category",
        };
      }

      if (!ondcInfo?.ondc?.timeToShip || ondcInfo?.ondc?.timeToShip == "0") {
        errors = { ...errors, timeToShip: "Enter time to ship" };
      }

      if (ondcInfo?.ondc?.available_on_cod === "none") {
        errors = { ...errors, available_on_cod: "choose available on COD" };
      }

      // if (!ondcInfo?.ondc?.discount) {
      //   errors = { ...errors, discount: "Enter customer discount" };
      // }

      // const isUnitValid = UNITS_OF_MEASURE?.filter(
      //   (item) => item?.label === ondcInfo?.productDisplayUnitOfMeasure?.unit
      // );

      // const isUnitValid = unitOfMeasurementData?.filter(
      //   (item) => item?.value === ondcInfo?.productDisplayUnitOfMeasure?.unit
      // );

      // if (isUnitValid?.length === 0) {
      //   errors = { ...errors, productDisplayUnitOfMeasure1: "Select unit" };
      // }

      // if (!ondcInfo?.productDisplayUnitOfMeasure?.unit) {
      //   errors = { ...errors, productDisplayUnitOfMeasure1: "Select unit" };
      // }

      // if (
      //   !ondcInfo?.productDisplayUnitOfMeasure?.value ||
      //   ondcInfo?.productDisplayUnitOfMeasure?.value == "0"
      // ) {
      //   errors = { ...errors, productDisplayUnitOfMeasure: "Enter value" };
      // }

      // if (!ondcInfo?.netProductWeight || ondcInfo?.netProductWeight == "0") {
      //   errors = { ...errors, netProductWeight: "Enter net product weight" };
      // }

      // const isKeyValid = PRODUCT_CODE_UNIT_LIST?.filter(
      //   (item) => item?.label === ondcInfo?.ondc?.productCode?.key
      // );

      // if (isKeyValid?.length === 0) {
      //   errors = { ...errors, productCode1: "Select code" };
      // }

      // if (!ondcInfo?.ondc?.productCode?.value) {
      //   errors = { ...errors, productCode: "Enter product code" };
      // }

      if (ondcInfo?.ondc?.fragile === "none") {
        errors = { ...errors, fragile: "Choose fragile" };
      }

      if (ondcInfo?.ondc?.cancelTerms?.cancellable === "none") {
        errors = { ...errors, cancellable: "Choose cancellable" };
      }

      if (ondcInfo?.ondc?.cancelTerms?.cancellable === "yes") {
        if (ondcInfo?.ondc?.cancelTerms?.refundEligible === "none") {
          errors = { ...errors, refundEligible: "Choose refund eligible" };
        }

        // if (ondcUIControl?.cancellationFeeType === "Select Fee Type") {
        //   errors = {
        //     ...errors,
        //     cancellationFeeType: "Select fee type",
        //   };
        // }

        // if (ondcUIControl?.cancellationFeeType === "Amount") {
        //   if (!ondcInfo?.ondc?.cancelTerms?.cancellationFeeAmount) {
        //     errors = {
        //       ...errors,
        //       cancellationFeeAmount: "Enter cancellation fee amount",
        //     };
        //   }
        // }

        if (!ondcInfo?.ondc?.cancelTerms?.cancellationFeePercentage) {
          errors = {
            ...errors,
            cancellationFeePercentage: "Enter cancellation fee percentage",
          };
        }

        if (ondcInfo?.ondc?.cancelTerms?.cancellationFeePercentage > 100) {
          errors = {
            ...errors,
            cancellationFeePercentage: "Percentage value must be less than 100",
          };
        }
      }

      if (ondcInfo?.ondc?.returnTerms?.returnable === "none") {
        errors = { ...errors, returnable: "Choose returnable" };
      }

      if (
        !ondcInfo?.ondc?.returnTerms?.returnPeriod ||
        ondcInfo?.ondc?.returnTerms?.returnPeriod == "0"
      ) {
        errors = { ...errors, returnPeriod: "Enter return period" };
      }

      if (ondcInfo?.ondc?.returnTerms?.seller_pickup_return === "none") {
        errors = { ...errors, seller_pickup_return: "Choose seller pickup" };
      }

      if (ondcUIControl?.isFoodRelatedItems === "none") {
        errors = {
          ...errors,
          isFoodRelatedItems: "Choose Food & Related Items",
        };
      }

      if (ondcUIControl?.isFoodRelatedItems === "yes") {
        if (!ondcInfo?.ondc?.shelfLife || ondcInfo?.ondc?.shelfLife == "0") {
          errors = { ...errors, shelfLife: "Enter shelfLife" };
        }

        if (
          !ondcInfo?.ondc?.fssai?.[0]?.number ||
          ondcInfo?.ondc?.fssai?.[0]?.number?.length != 14 ||
          isNaN(ondcInfo?.ondc?.fssai?.[0]?.number)
        ) {
          errors = { ...errors, fssai: "Enter a valid 14 digit FSSAI number" };
        }

        // if (ondcInfo?.ondc?.fssai?.[0]?.validTill === "") {
        //   errors = { ...errors, validTill: "Enter validTill" };
        // }
      }

      if (ondcUIControl?.isPackaged === "none") {
        errors = { ...errors, isPackaged: "Choose Packaged Commodities" };
      }

      if (ondcUIControl?.isPackaged === "yes") {
        if (
          !ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.manufacturer_or_packer_name
        ) {
          errors = {
            ...errors,
            manufacturer_or_packer_name: "Enter Manufacturer/Packer Name",
          };
        }
        if (!ondcInfo?.ondc?.statutoryReqsPackagedCommodities?.mfg_license_no) {
          errors = { ...errors, mfg_license_no: "Enter License No" };
        }
        if (
          !ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.manufacturer_or_packer_address
        ) {
          errors = {
            ...errors,
            manufacturer_or_packer_address: "Enter Manufacturer/Packer Address",
          };
        }
        if (
          !ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.common_or_generic_name_of_commodity
        ) {
          errors = {
            ...errors,
            common_or_generic_name_of_commodity:
              "Enter Product Common/Generic Name",
          };
        }

        if (
          ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.month_year_of_manufacture_packing_import &&
          !isStringValidDateFormat(
            ondcInfo?.ondc?.statutoryReqsPackagedCommodities
              ?.month_year_of_manufacture_packing_import
          )
        ) {
          errors = {
            ...errors,
            month_year_of_manufacture_packing_import:
              "Please enter a valid Month, Year or N/A",
          };
        }

        if (
          ondcInfo?.ondc?.statutoryReqsPackagedCommodities?.expiry_date &&
          !isStringValidDateFormat(
            ondcInfo?.ondc?.statutoryReqsPackagedCommodities?.expiry_date
          )
        ) {
          errors = {
            ...errors,
            expiry_date: "Please enter a valid Month, Year or N/A",
          };
        }

        if (
          !ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.net_quantity_or_measure_of_commodity_in_pkg
        ) {
          errors = {
            ...errors,
            net_quantity_or_measure_of_commodity_in_pkg: "Enter Net Quantity",
          };
        }
        if (
          !ondcInfo?.ondc?.statutoryReqsPackagedCommodities
            ?.multiple_products_name_number_or_qty
        ) {
          errors = {
            ...errors,
            multiple_products_name_number_or_qty: "Enter Multiple Products",
          };
        }
      }

      if (ondcUIControl?.isPrePackaged === "none") {
        errors = { ...errors, isPrePackaged: "Choose Packaged Commodities" };
      }

      if (ondcUIControl?.isPrePackaged === "yes") {
        if (
          !ondcInfo?.ondc?.statutoryReqsPrepackagedFood
            ?.brand_owner_FSSAI_license_no ||
          ondcInfo?.ondc?.statutoryReqsPrepackagedFood
            ?.brand_owner_FSSAI_license_no?.length != 14 ||
          isNaN(
            ondcInfo?.ondc?.statutoryReqsPrepackagedFood
              ?.brand_owner_FSSAI_license_no
          )
        ) {
          errors = {
            ...errors,
            brand_owner_FSSAI_license_no: "Enter a valid 14 digit FSSAI number",
          };
        }
        if (
          !ondcInfo?.ondc?.statutoryReqsPrepackagedFood
            ?.other_FSSAI_license_no ||
          ondcInfo?.ondc?.statutoryReqsPrepackagedFood?.other_FSSAI_license_no
            ?.length != 14 ||
          isNaN(
            ondcInfo?.ondc?.statutoryReqsPrepackagedFood?.other_FSSAI_license_no
          )
        ) {
          errors = {
            ...errors,
            other_FSSAI_license_no: "Enter a valid 14 digit FSSAI number",
          };
        }
        if (
          !ondcInfo?.ondc?.statutoryReqsPrepackagedFood
            ?.importer_FSSAI_license_no ||
          ondcInfo?.ondc?.statutoryReqsPrepackagedFood
            ?.importer_FSSAI_license_no?.length != 14 ||
          isNaN(
            ondcInfo?.ondc?.statutoryReqsPrepackagedFood
              ?.importer_FSSAI_license_no
          )
        ) {
          errors = {
            ...errors,
            importer_FSSAI_license_no: "Enter a valid 14 digit FSSAI number",
          };
        }
        if (!ondcInfo?.ondc?.statutoryReqsPrepackagedFood?.ingredients_info) {
          errors = {
            ...errors,
            ingredients_info: "Enter Ingredients Info",
          };
        }
        if (!ondcInfo?.ondc?.statutoryReqsPrepackagedFood?.nutritional_info) {
          errors = {
            ...errors,
            nutritional_info: "Enter Nutritional Info ",
          };
        }
        if (!ondcInfo?.ondc?.statutoryReqsPrepackagedFood?.additives_info) {
          errors = {
            ...errors,
            additives_info: "Enter Additives Info",
          };
        }
      }

      console.log("ONDC_INFO__:", ondcInfo);

      if (errors) {
        handleSetValue("errors", errors, ondcInfo, setOndcInfo);
        return false;
      } else {
        handleSetValue("errors", null, ondcInfo, setOndcInfo);

        if (isNavDisabled) {
          return true;
        }

        window.scrollTo(0, 0);
        previewData();
      }
    } else {
      // Update or Add product
      handleSetValue("errors", null, ondcInfo, setOndcInfo);

      if (isNavDisabled) {
        return true;
      }

      setPreviewErrorMsg(null);

      window.scrollTo(0, 0);
      previewData();
    }
  };

  // To handle Subscription Info Next Click
  const handleSubscriptionInfoSubmit = async () => {
    let errors = null;

    if (subscriptionInfo?.isProductSubscribed) {
      if (subscriptionInfo?.subType?.length === 0) {
        errors = { ...errors, subType: true };
      }
      if (!subscriptionInfo?.originalValue) {
        errors = { ...errors, originalValue: true };
      }
      if (!subscriptionInfo?.offerValue) {
        errors = { ...errors, offerValue: true };
      }
      if (!subscriptionInfo?.cancelTime) {
        errors = { ...errors, cancelTime: true };
      }
      if (!subscriptionInfo?.cancelDays) {
        errors = { ...errors, cancelDays: true };
      }
      // if (!subscriptionInfo?.serviceFee) {
      //   errors = { ...errors, serviceFee: true };
      // }
      // if (!subscriptionInfo?.specialFee) {
      //   errors = { ...errors, specialFee: true };
      // }
      // if (!subscriptionInfo?.packingFee) {
      //   errors = { ...errors, packingFee: true };
      // }
      if (!subscriptionInfo?.startDate) {
        errors = { ...errors, startDate: true };
      }
      if (!subscriptionInfo?.endDate) {
        errors = { ...errors, endDate: true };
      }

      console.log(subscriptionInfo);

      if (errors) {
        handleSetValue("errors", errors, subscriptionInfo, setSubscriptionInfo);
      } else {
        // Update or Add product
        handleSetValue("errors", null, subscriptionInfo, setSubscriptionInfo);
        previewData();
        // updateProductDetails();
      }
    } else {
      // Update or Add product
      handleSetValue("errors", null, subscriptionInfo, setSubscriptionInfo);
      previewData();
      // updateProductDetails();
    }
  };

  const previewData = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const previewDetails = getUpdateProductPostData({
      businessId,
      productDetails,
      productInfo,
      categoryInfo,
      priceInfo,
      measurementInfo,
      availabilityInfo,
      subscriptionInfo,
      ondcInfo,
      ondcUIControl,
    });

    console.log("previewDetails:", previewDetails);

    setProductPreviewData(previewDetails);
    setCurrentPage("preview");
  };

  // Clear Form Data
  const clearFormData = () => {};

  // Update Product Details
  const updateProductDetails = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const productPhotosAPI = productDetails?.productPhoto;

    const deletePhotoList = [];
    const uploadPhotoList = [];

    let index = 0;

    if (productImgUpdate?.length > 0) {
      for (const item of productImgUpdate) {
        if (productPhotosAPI?.length > index) {
          if (item?.docType !== productPhotosAPI[index]?.docType) {
            console.log("Change_Photo:", item, productPhotosAPI[index]);
            deletePhotoList.push(productPhotosAPI[index]?.docType);
            uploadPhotoList.push(item);
          } else {
            console.log("No_Change_Photo:", item, productPhotosAPI[index]);
          }
        } else {
          uploadPhotoList.push(item);
        }
        index = index + 1;
      }
    }

    console.log("UpdateIMGList:", productImgUpdate);

    console.log("deletePhotoList:", deletePhotoList);
    console.log("deleteImgList:", deleteImgList);
    console.log("uploadPhotoList:", uploadPhotoList);

    setAddProductLoader(true);

    const combinedDeleteImg = [...deletePhotoList, ...deleteImgList];

    // Remove duplicates using a Set
    const uniqueImgs = [...new Set(combinedDeleteImg)];

    console.log("uniqueImgs___:", uniqueImgs);

    // Deleting Photos
    let deletedImages = 0;
    for (const imageId of uniqueImgs) {
      const deleteRes = await deleteProductPhoto(imageId);
      if (deleteRes?.success) deletedImages = deletedImages + 1;
    }

    if (deletedImages > 0) {
      console.log(`${deletedImages} Photos deleted`);
    }

    // Uploading Photos
    let uploadedImages = 0;
    for (const item of uploadPhotoList) {
      const uploadRes = await addProductPhoto(
        item?.dataURL,
        item?.fileDetails,
        item?.index
      );
      if (uploadRes?.success) uploadedImages = uploadedImages + 1;
    }

    if (uploadedImages > 0) {
      console.log(`${uploadedImages} Photos Uploaded`);
    }

    // const postData = getUpdateProductPostData({
    //   businessId,
    //   businessInfo: activeBusinessInfo,
    //   productDetails,
    //   productInfo,
    //   categoryInfo,
    //   priceInfo,
    //   measurementInfo,
    //   availabilityInfo,
    //   subscriptionInfo,
    //   ondcInfo,
    //   ondcUIControl
    // });

    const postData = getUpdateProductPostAPIData({
      businessId,
      businessInfo: activeBusinessInfo,
      productDetails,
      productInfo,
      categoryInfo,
      priceInfo,
      measurementInfo,
      availabilityInfo,
      subscriptionInfo,
      ondcInfo,
      ondcUIControl,
    });

    console.log("UpdatePostData:", postData);

    // Updating product details
    const response = await uploadProductApi(postData);

    if (response?.success) {
      setAddProductLoader(false);
      setShowAlert(true);
    } else {
      setAddProductLoader(false);
      setShowAlert(false);
    }
  };

  // Adding New Copy Product
  const addCopyProductDetails = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    const productPhoto = prepareAddNewProductPhotoData(productImgUpdate);
    console.log("UpdateIMGList:", productPhoto);

    const postData = getAddNewProductPostData({
      businessId,
      businessInfo: activeBusinessInfo,
      productPhoto,
      productDetails,
      productInfo,
      categoryInfo,
      priceInfo,
      measurementInfo,
      availabilityInfo,
      subscriptionInfo,
      ondcInfo,
      ondcUIControl,
    });

    console.log("AddPostData:", postData);

    // Add new product details
    setAddProductLoader(true);
    const response = await addNewProductApi(postData);

    if (response?.success) {
      setShowAlert(true);
      setAddProductLoader(false);
      return;
    }

    setAddProductLoader(false);
  };

  // Preparing and uploading new product photo
  const addProductPhoto = async (dataURL, fileDetails, imageIndex) => {
    if (!dataURL || !imageIndex) return;

    const businessId = sessionStorage.getItem("selectedBusinessId");
    const filename = fileDetails?.name;
    const mimeType = fileDetails?.type;

    const params = {
      imageIndex,
      productId: location?.state?.productId,
      businessId,
      filename,
      mimeType,
      dataURL,
    };

    const postData = prepareAddProductPhotoData(params);
    console.log("photo_upload_postData:", postData);

    const response = await uploadProductPhotoApi(postData);
    console.log("response:", response);
  };

  const deleteProductPhoto = async (imageId) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const productImgData = productDetails?.productPhoto?.filter(
      (item, index) => imageId === item?.docType
    );

    if (productImgData?.length > 0) {
      // Delete Product photo
      const photoItem = productImgData?.[0];
      const postData = {
        imageId: photoItem?.docType,
        productID: productDetails?.id,
        businessID: businessId,
      };

      await deleteProductPhotoApi(postData);
    }

    console.log("delete_IMG_productData:", productDetails?.productPhoto);
    console.log("delete_imageId:", imageId);
    console.log("delete_IMG_DATA:", productImgData);
  };

  const removeProductPhoto = (imageData) => {
    console.log("imageData___:", imageData);
    console.log("productImgUpdate__:", productImgUpdate);

    const updatedList = productImgUpdate?.map((item, index) => {
      if (imageData === item?.dataURL || imageData === item?.docURL) {
        if (imageData === item?.docURL) {
          setDeleteImgList([...deleteImgList, `${item?.docType || ""}`]);
        }
        console.log("HTTTT____");
        return { ...item, dataURL: "" };
      } else {
        return item;
      }
    });

    console.log("updatedList___", updatedList);

    setProductImgUpdate(updatedList);
  };

  const goBack = () => {
    if (currentPage === "category-specs") {
      if (location.state?.isCopyProduct) {
        setCurrentPage("product-availablity");
        return;
      }
      setCurrentPage("measurement-delivery-method");
    }
    if (currentPage === "price-tax-info") setCurrentPage("product-detail");
    if (currentPage === "measurement-delivery-method")
      setCurrentPage("price-tax-info");
    if (currentPage === "product-availablity")
      setCurrentPage("measurement-delivery-method");
    // if (currentPage === "subscription-info")
    //   setCurrentPage("product-availablity");
    if (currentPage === "subscription-info") setCurrentPage("category-specs");
    // setCurrentPage("measurement-delivery-method");
    if (currentPage === "ondc") setCurrentPage("category-specs");
    if (currentPage === "preview") setCurrentPage("ondc");
    // if (currentPage === "preview") setCurrentPage("category-specs");

    contentRef.current.scrollIntoView();
  };

  const onCancelClick = () => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/catalog`);
  };

  // handle swicth between tabs
  const handleTabChange = (currentName, tabList, destinationTab) => {
    // console.log("currentTab__:", currentName);
    // console.log("tabList:", tabList);
    // console.log("destinationTab__:", destinationTab);

    const currentTabInfo = tabList?.filter(
      (item) => item?.value === currentName
    );
    const descriptionTabInfo = tabList?.filter(
      (item) => item?.tab === destinationTab
    );

    let isTabDataValid = false;

    switch (currentName) {
      case "product-detail":
        isTabDataValid = handleProductInfoSubmit(true);
        break;
      case "price-tax-info":
        isTabDataValid = handlePriceInfoSubmit(true);
        break;
      case "measurement-delivery-method":
        isTabDataValid = handleMeasurementInfoSubmit(true);
        break;
      case "product-availablity":
        isTabDataValid = handleAvailabilityInfoSubmit(true);
        break;
      case "category-specs":
        isTabDataValid = handleCategoryInfoSubmit(true);
        break;
      case "ondc":
        isTabDataValid = handleOndcInfoSubmit(true);
        break;

      default:
        break;
    }

    console.log("isTabDataValid__:", isTabDataValid);
    console.log("currentTabInfo__:", currentTabInfo);
    console.log("descriptionTabInfo__:", descriptionTabInfo);

    if (
      currentTabInfo.length > 0 &&
      descriptionTabInfo.length > 0 &&
      isTabDataValid
    ) {
      const currentTabIndex = currentTabInfo[0]?.tab;

      // if (destinationTab < currentTabIndex) {
      const destinationTabName = descriptionTabInfo[0]?.value;
      setCurrentPage(destinationTabName);
      setPreviewErrorMsg(null);
      return;
      // }
    }
  };

  // handle preview from all tabs
  const handlePreviewFromAnyTab = () => {
    const errorTabs = [];

    const isCopyProd = location.state?.isCopyProduct;

    const isProductTabError = handleProductInfoSubmit(true);
    const isPriceTabError = handlePriceInfoSubmit(true);
    const isMeasurementTabError = handleMeasurementInfoSubmit(true);
    const isAvailabilityTabError = handleAvailabilityInfoSubmit(true);
    const isSpecsTabError = handleCategoryInfoSubmit(true);
    const isONDCTabError = handleOndcInfoSubmit(true);

    if (!isProductTabError) {
      errorTabs.push("Product Info");
    }

    if (!isPriceTabError) {
      const errorTab = isCopyProd ? "Pricing & Tax Info" : "Tax & GST Info";
      errorTabs.push(errorTab);
    }

    if (!isMeasurementTabError) {
      errorTabs.push("Weight & Package Info");
    }

    if (!isAvailabilityTabError && isCopyProd) {
      errorTabs.push("Availability");
    }

    if (!isSpecsTabError) {
      errorTabs.push("Specification");
    }

    if (!isONDCTabError) {
      errorTabs.push("ONDC Parameters");
    }

    console.log("errorTabs__:", errorTabs);

    if (errorTabs.length > 0) {
      setPreviewErrorMsg(
        `Fill the required details in ${
          errorTabs.length === 1 ? errorTabs.join("") : errorTabs.join(", ")
        } ${errorTabs.length === 1 ? "Tab" : "Tabs"}`
      );
      return;
    }

    setPreviewErrorMsg(null);

    window.scrollTo(0, 0);
    previewData();
  };

  // Get Unit of measurement
  const getUnitsData = async () => {
    const response = await getUnitOfMeasurementApi();
    setUnitOfMeasurementData(() => response?.details || []);
    console.log("getUnitsData:", response);
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      getProductDetails();
      dispatch(getCountryNames());
      dispatch(getGstRates());
      dispatch(getAllDeliveryMethods());
    }
  }, []);

  useEffect(() => {
    if (activeBusinessInfo) {
      setCategoryInfo((prevState) => ({
        ...prevState,
        businessCategory: activeBusinessInfo?.businessCategories || [],
      }));

      if (unitOfMeasurementData?.length === 0) {
        getUnitsData();
      }
    }
    return () => {};
  }, [activeBusinessInfo]);

  return (
    <Box sx={styles.container}>
      {activeBusinessInfo && (
        <DashboardLayout activeMenu="catalog">
          {!productDetailsLoader && !activeBusinessInfoLoader ? (
            <Box pt={{ xs: 1.5, md: 0 }} ref={contentRef}>
              <Box mb={{ xs: 1, md: 2 }} sx={stickyHeader}>
                {/* <Typography sx={styles.backTextBtn} onClick={onCancelClick}>
                Back to Catalog
              </Typography> */}

                <BreadcrumbSection
                  links={[
                    {
                      label: "Product Catalog",
                      to: `/${businessName}/catalog`,
                    },
                    {
                      label: location.state?.isCopyProduct
                        ? "Copy Product"
                        : "Edit Product",
                      to: `#`,
                    },
                    {
                      label: location.state?.isCopyProduct
                        ? productInfo?.copyProductName
                        : productInfo?.productName,
                      to: `#`,
                    },
                  ]}
                />

                <Typography
                  component="h6"
                  fontSize={{ xs: 15, md: 16 }}
                  color={blackColor}
                  fontWeight={{ xs: 600, md: 600 }}
                  lineHeight="20px"
                  display="inline"
                >
                  <span
                    style={{ color: matches ? blueColor04 : mobilePrimary }}
                  >
                    {location.state?.isCopyProduct
                      ? `Product details copied from`
                      : `Edit Product`}
                  </span>{" "}
                  -{" "}
                  <Typography
                    fontSize={{ xs: 15, md: 16 }}
                    fontWeight={600}
                    color={blackColor}
                    display="inline"
                  >
                    {location.state?.isCopyProduct
                      ? productInfo?.copyProductName
                      : productInfo?.productName}
                  </Typography>
                </Typography>

                {currentPage !== "preview" && (
                  <DashboardStepper
                    stepList={
                      location.state?.isCopyProduct
                        ? CATALOG_ADD_PRODUCT_STEP_LIST
                        : CATALOG_STEP_LIST
                    }
                    currentPage={currentPage}
                    isAddProduct={location.state?.isCopyProduct}
                    hideAvailability={!location.state?.isCopyProduct}
                    handleTabChange={(destinationTab) =>
                      handleTabChange(
                        currentPage,
                        location.state?.isCopyProduct
                          ? CATALOG_ADD_PRODUCT_STEP_LIST
                          : CATALOG_STEP_LIST,
                        destinationTab
                      )
                    }
                  />
                )}
                {!location.state?.isCopyProduct && (
                  <Typography
                    mb={0.5}
                    color={greyColor40}
                    pt={1}
                    pb={{ xs: 0.5, md: 1 }}
                  >
                    For stock and pricing updates use Inventory update section
                  </Typography>
                )}
              </Box>

              <Box>
                <Box
                  sx={{
                    display:
                      currentPage === "product-detail" ? "block" : "none",
                  }}
                >
                  <AddProductForm
                    data={productInfo}
                    onValueChange={(name, value) => {
                      handleSetValue(name, value, productInfo, setProductInfo);
                    }}
                    countryList={countryList}
                    productId={location?.state?.productId}
                    gotoNext={() => handleProductInfoSubmit(false)}
                    goBack={goBack}
                    handleTabChange={handleTabChange}
                    // productName={productInfo?.productName}
                    productName={
                      location.state?.isCopyProduct
                        ? productInfo?.copyProductName
                        : productInfo?.productName
                    }
                    onProductImageUpdate={onProductImageUpdate}
                    onDeleteProductPhoto={(imgURL) => {
                      removeProductPhoto(imgURL);
                    }}
                    productImgDataList={productImgUpdate}
                    setProductImgDataList={setProductImgUpdate}
                    // CategoryInfo
                    categoryData={categoryInfo}
                    setCategoryData={setCategoryInfo}
                    onCategoryValueChange={(name, value) => {
                      handleSetValue(
                        name,
                        value,
                        categoryInfo,
                        setCategoryInfo
                      );
                    }}
                    onCancelClick={onCancelClick}
                    isCopyProduct={location.state?.isCopyProduct}
                    tabTitle={
                      location.state?.isCopyProduct
                        ? `Product details copied from`
                        : `Edit Product`
                    }
                    // Handle preview
                    showPreviewBtn
                    previewErrorMsg={previewErrorMsg}
                    handlePreviewSubmit={handlePreviewFromAnyTab}
                    currentPage={currentPage}
                  />
                </Box>

                {currentPage === "category-specs" ? (
                  <CategorySpecsForm
                    data={categoryInfo}
                    setData={setCategoryInfo}
                    onValueChange={(name, value) => {
                      handleSetValue(
                        name,
                        value,
                        categoryInfo,
                        setCategoryInfo
                      );
                    }}
                    gotoNext={() => handleCategoryInfoSubmit(false)}
                    goBack={goBack}
                    currentPage={currentPage}
                    productName={
                      location.state?.isCopyProduct
                        ? productInfo?.copyProductName
                        : productInfo?.productName
                    }
                    onCancelClick={onCancelClick}
                    isCopyProduct={location.state?.isCopyProduct}
                    handleTabChange={handleTabChange}
                    tabTitle={
                      location.state?.isCopyProduct
                        ? `Product details copied from`
                        : `Edit Product`
                    }
                    // Handle preview
                    showPreviewBtn
                    previewErrorMsg={previewErrorMsg}
                    handlePreviewSubmit={handlePreviewFromAnyTab}
                  />
                ) : null}

                {currentPage === "price-tax-info" ? (
                  <PriceTaxInfoForm
                    data={priceInfo}
                    onValueChange={(name, value) => {
                      handleSetValue(name, value, priceInfo, setPriceInfo);
                    }}
                    ondcData={ondcInfo}
                    setOndcData={setOndcInfo}
                    gstRates={gstRates}
                    gotoNext={() => handlePriceInfoSubmit(false)}
                    goBack={goBack}
                    currentPage={currentPage}
                    productName={
                      location.state?.isCopyProduct
                        ? productInfo?.copyProductName
                        : productInfo?.productName
                    }
                    isAddProduct={location.state?.isCopyProduct}
                    isCopyProduct={location.state?.isCopyProduct}
                    onCancelClick={onCancelClick}
                    handleTabChange={handleTabChange}
                    tabTitle={
                      location.state?.isCopyProduct
                        ? `Product details copied from`
                        : `Edit Product`
                    }
                    // Handle preview
                    showPreviewBtn
                    previewErrorMsg={previewErrorMsg}
                    handlePreviewSubmit={handlePreviewFromAnyTab}
                  />
                ) : null}

                {currentPage === "measurement-delivery-method" ? (
                  <MeasurementDeliveryForm
                    data={measurementInfo}
                    onValueChange={(name, value) => {
                      handleSetValue(
                        name,
                        value,
                        measurementInfo,
                        setMeasurementInfo
                      );
                    }}
                    ondcData={ondcInfo}
                    setOndcData={setOndcInfo}
                    allDeliveryMethod={allDeliveryMethod || []}
                    gotoNext={() => handleMeasurementInfoSubmit(false)}
                    goBack={goBack}
                    currentPage={currentPage}
                    productName={
                      location.state?.isCopyProduct
                        ? productInfo?.copyProductName
                        : productInfo?.productName
                    }
                    onCancelClick={onCancelClick}
                    isCopyProduct={location.state?.isCopyProduct}
                    handleTabChange={handleTabChange}
                    tabTitle={
                      location.state?.isCopyProduct
                        ? `Product details copied from`
                        : `Edit Product`
                    }
                    // Handle preview
                    showPreviewBtn
                    previewErrorMsg={previewErrorMsg}
                    handlePreviewSubmit={handlePreviewFromAnyTab}
                    unitOfMeasurementData={unitOfMeasurementData}
                  />
                ) : null}

                {location.state?.isCopyProduct && (
                  <>
                    {currentPage === "product-availablity" ? (
                      <ProductAvailabilityForm
                        data={availabilityInfo}
                        onValueChange={(name, value) => {
                          handleSetValue(
                            name,
                            value,
                            availabilityInfo,
                            setAvailabilityInfo
                          );
                        }}
                        gotoNext={() => handleAvailabilityInfoSubmit(false)}
                        goBack={goBack}
                        currentPage={currentPage}
                        productName={
                          location.state?.isCopyProduct
                            ? productInfo?.copyProductName
                            : productInfo?.productName
                        }
                        onCancelClick={onCancelClick}
                        isCopyProduct={location.state?.isCopyProduct}
                        handleTabChange={handleTabChange}
                        tabTitle={
                          location.state?.isCopyProduct
                            ? `Product details copied from`
                            : `Edit Product`
                        }
                        // Handle preview
                        showPreviewBtn
                        previewErrorMsg={previewErrorMsg}
                        handlePreviewSubmit={handlePreviewFromAnyTab}
                      />
                    ) : null}
                  </>
                )}

                {currentPage === "ondc" ? (
                  <ONDCFrom
                    data={ondcInfo}
                    setData={setOndcInfo}
                    ondcUIControl={ondcUIControl}
                    setOndcUIControl={setOndcUIControl}
                    ondcProductCategory={
                      activeBusinessInfo?.businessONDCCategories || []
                    }
                    gotoNext={() => handleOndcInfoSubmit(false)}
                    goBack={goBack}
                    currentPage={currentPage}
                    onCancelClick={onCancelClick}
                    handleTabChange={handleTabChange}
                    isCopyProduct={location.state?.isCopyProduct}
                    tabTitle={
                      location.state?.isCopyProduct
                        ? `Product details copied from`
                        : `Edit Product`
                    }
                    productName={productInfo?.productName}
                    // Handle preview
                    showPreviewBtn={!matches}
                    previewErrorMsg={previewErrorMsg}
                    handlePreviewSubmit={handlePreviewFromAnyTab}
                    unitOfMeasurementData={unitOfMeasurementData}
                  />
                ) : null}

                {currentPage === "preview" ? (
                  <DataPreview
                    productData={productPreviewData}
                    productPhotos={productImgUpdate}
                    onGoBack={goBack}
                    onSubmit={
                      location.state?.isCopyProduct
                        ? addCopyProductDetails
                        : updateProductDetails
                    }
                    isCopyProduct={location.state?.isCopyProduct}
                  />
                ) : null}
              </Box>
            </Box>
          ) : (
            <ThreeDotLoader />
          )}
        </DashboardLayout>
      )}
      <ModalCard
        open={showAlert || addProductLoader}
        handleClose={() => {
          // setShowAlert(false);
        }}
        width="48%"
      >
        <Box backgroundColor={whiteColor} borderRadius="8px">
          {addProductLoader ? (
            <Box sx={{ height: "100px" }}>
              <ThreeDotLoader />
            </Box>
          ) : showAlert ? (
            <AlertMessagePopup
              text={
                location.state?.isCopyProduct
                  ? "Product added successfully. Our team will review and approve within 24 hours.  New products will be available for customer ordering after approval.\nFor immediate approval, please contact via whatsapp - ‘8088422615’"
                  : "Product updated successfully. Our team will review and approve within 24 hours.  New changes will be reflected in the product after approval.\nFor immediate approval, please contact via whatsapp - ‘8088422615’"
              }
              onClick={() => {
                const webURL = sessionStorage.getItem("costboWEBURL");
                navigate(`/${webURL}/catalogPending`);
              }}
            />
          ) : null}
        </Box>
      </ModalCard>
    </Box>
  );
}

export default Catalog;

const AlertMessagePopup = ({ text, onClick }) => {
  return (
    <Box sx={styles.alertCard}>
      <Typography
        variant="body2"
        fontSize={14}
        fontWeight={500}
        textAlign="center"
      >
        {text}
      </Typography>
      <Button sx={styles.alertBtn} variant="outlined" onClick={onClick}>
        GO TO CATALOG
      </Button>
    </Box>
  );
};

const styles = {
  container: {
    backgroundColor: BGColor02,
    pb: 2,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "25px" },
    p: { xs: "0px", md: "15px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  alertCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    padding: "20px 20px 15px 20px",
    borderRadius: "8px",
  },

  alertBtn: {
    fontSize: "13px",
    fontWeight: "500",
    color: whiteColor,
    backgroundColor: primaryColor,
    padding: "6px 24px",
    marginTop: "12px",
    "&:hover": {
      color: primaryColor,
      backgroundColor: whiteColor,
    },
  },

  backTextBtn: {
    fontSize: 12.5,
    fontWeight: 500,
    color: blueColor04,
    display: { xs: "block", md: "none" },
    textDecoration: "underline",
    mb: 0.7,
    cursor: "pointer",
  },
};
