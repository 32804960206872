import React from "react";
import { blueColor04, greyColor40, mobilePrimary } from "../../configs/styles/muiThemes";
import { Box, Typography } from "@mui/material";
import useDynamicColor from "../../hooks/useDynamicColor";

const MobileTab = ({ title, count, isActive, onClick }) => {
   const mainColor = useDynamicColor();
  return (
    <Box sx={styles.mobileTabItem(isActive)} onClick={onClick}>
      <Typography
        fontSize={13.5}
        color={isActive ? mainColor : greyColor40}
        fontWeight={500}
        sx={{ whiteSpace: "nowrap" }}
      >
        {count}
      </Typography>

      <Typography
        fontSize={11.5}
        color={isActive ? mainColor : greyColor40}
        fontWeight={isActive ? 600 : 500}
        sx={{ whiteSpace: "nowrap"}}
        textTransform= "capitalize"
      >
        {title}
      </Typography>
    </Box>
  );
};

export default MobileTab;

const styles = {
  mobileTabItem: (isActive) => ({
    textAlign: "center",
    borderBottom: isActive ? `3px solid ${mobilePrimary}` : "none",
    mr: 1,
  }),
};
