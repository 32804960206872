import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import FormInputBox from "../../../components/Input/FormInputBox";
import {
  blackColor,
  blackShade01,
  infoCardBG,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import { saveMsg } from "../../../utils/data";
import FormInputBoxWithBorder from "../../../components/Input/FormInputBoxWithBorder";
import NavigationButtons from "../Components/NavigationButtons";

function GSTDetailsForm({
  handleNext,
  handleBack,
  handleSave,
  gstDetails,
  onValueChange,
  setGstDetails,
}) {
  // useEffect(() => {
  //   if (gstDetails?.gstNumber) {
  //     onValueChange("panNumber", gstDetails?.gstNumber?.slice(2, 12));
  //   }
  // }, [gstDetails]);
  return (
    <Box sx={styles.card}>
      {/* <Typography
        variant="h5"
        fontSize={{ xs: 15, md: 16 }}
        color={blackColor}
        fontWeight={500}
        lineHeight="30px"
        mb="10px !important"
      >
        GST Detail
      </Typography> */}

      {/* <Box sx={styles.infoAlert}>
        <Typography
          variant="h3"
          fontSize={{ xs: 12, md: 13 }}
          color={blackShade01}
          fontWeight={400}
          lineHeight={{ xs: "19px", md: "22px" }}
        >
          Your business details are automatically fetched through a third party
          API
        </Typography>
      </Box> */}

      <Grid container rowSpacing={0} columnSpacing={{ md: 3, xs: 2 }}>
        <Grid item xs={12} md={12}>
          <FormInputBoxWithBorder
            label="Registered Business Name (as per GST, for invoicing)"
            mobilelabel1="Registered Business Name"
            mobilelabel2="(as per GST, for invoicing)"
            value={gstDetails.registeredBusinessName}
            onChange={({ target }) => {
              onValueChange("registeredBusinessName", target.value);
            }}
            maxLength={100}
            error={gstDetails?.errors?.registeredBusinessName}
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormInputBoxWithBorder
            label="GSTIN Number"
            value={gstDetails.gstNumber}
            onChange={({ target }) => {
              const value = target.value
                .replace(/[^a-zA-Z0-9]/g, "")
                .toUpperCase();

              if (value.length == 15) {
                const reg =
                  /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
                    value
                  );
                console.log("reg", reg);
                if (reg) {
                  setGstDetails({
                    ...gstDetails,
                    gstNumber: value,
                    panNumber: value.slice(2, 12),
                    errors: {},
                  });
                } else {
                  setGstDetails({
                    ...gstDetails,
                    gstNumber: value,
                    errors: { gstNumber: "Please enter the valid GST Number." },
                  });
                }

                // onValueChange("panNumber", value.slice(2, 12));
              } else {
                onValueChange("gstNumber", value);
              }
            }}
            maxLength={15}
            error={gstDetails?.errors?.gstNumber}
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormInputBoxWithBorder
            label="PAN Number"
            value={gstDetails?.panNumber}
            onChange={({ target }) => {
              const value = target.value
                .replace(/[^a-zA-Z0-9]/g, "")
                .toUpperCase();
              if (value.length == 10) {
                const reg = /[A-Z]{5}\d{4}[A-Z]{1}/.test(value);
                if (reg) {
                  setGstDetails({
                    ...gstDetails,
                    panNumber: value,
                    errors: {},
                  });
                } else {
                  setGstDetails({
                    ...gstDetails,
                    panNumber: value,
                    errors: { panNumber: "Please enter the valid PAN Number." },
                  });
                }
              } else {
                onValueChange("panNumber", value);
              }
            }}
            maxLength={10}
            error={gstDetails?.errors?.panNumber}
            required
          />
        </Grid>
      </Grid>

      {/* <ActionButtons
        onBackClick={handleBack}
        // onSaveClick={handleSave}
        onNextClick={handleNext}
        showBackBtn
        // saveBtnText={"save & exit"}
        hideSaveBtn
        saveTooltip={saveMsg}
      /> */}

      <Box mt={1}>
        <NavigationButtons onBackClick={handleBack} onNextClick={handleNext} />
      </Box>
    </Box>
  );
}

export default GSTDetailsForm;

const styles = {
  card: {
    // width: { xs: "100%", md: "100%" },
    // mt: { md: "35px", xs: 1.5 },
  },

  infoAlert: {
    backgroundColor: infoCardBG,
    borderRadius: "5px",
    mb: "25px",
    p: "10px",
  },
};
