import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { blueColor04 } from "../../configs/styles/muiThemes";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import useDynamicColor from "../../hooks/useDynamicColor";

function HowToLinkCard({ handleClick, justifyContent, mb }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mainColor = useDynamicColor();

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={justifyContent || "flex-end"}
      gap={1}
      mb={mb || 0}
      onClick={handleClick}
    >
      <VideoLibraryIcon
        sx={{ fontSize: { md: "18px", xs: "20px" }, color: mainColor }}
      />

      {matches && (
        <Typography
          fontSize={14}
          fontWeight={500}
          color={mainColor}
          sx={{ textDecoration: "underline", whiteSpace: "nowrap" }}
        >
          Video Guides
        </Typography>
      )}
    </Box>
  );
}

export default HowToLinkCard;
