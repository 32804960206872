import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllBusinessCashbackApi,
  getCashBackValueForBusinessApi,
} from "../../service/api_calls/cashback/CashbackApi";
import { getAllBusinessProfileIDApi } from "../../service/api_calls/preferredDiscounts/PreferredDiscountsApi";

const initialState = {
  businessCashback: {
    details: [],
    profileInfo: [],
  },

  businessCashbackPageNo: 0,
  businessCashbackLoader: false,

  cashBackValue: null,
  cashBackValueLoader: false,
};

// Get All Business Cashback
export const getAllBusinessCashbackData = createAsyncThunk(
  "getAllBusinessCashbackData",
  async (param, thunkAPI) => {
    const res = await getAllBusinessCashbackApi(param);

    const uniqueprofileIds = [];

    for (const item of res?.details) {
      if (!uniqueprofileIds.includes(item?.profileId)) {
        uniqueprofileIds.push(item?.profileId);
      }
    }
    console.log("uniqueprofileIds:", uniqueprofileIds);

    let profileParams = "?";
    uniqueprofileIds.forEach((element, index) => {
      profileParams =
        profileParams +
        (index === uniqueprofileIds.length - 1
          ? `params=${element}`
          : `params=${element}&`);
    });
    console.log("profileParams:", profileParams);

    const profileInfo = await getAllBusinessProfileIDApi({
      ...param,
      profileParams,
    });
    return { cashbackInfo: res, profileInfo };
  }
);

export const getCashBackValueForBusinessData = createAsyncThunk(
  "getCashBackValueForBusinessData",
  async (param, thunkAPI) => {
    const res = await getCashBackValueForBusinessApi(param);
    return res;
  }
);

export const businessCashback = createSlice({
  name: "businessCashback",
  initialState,
  reducers: {
    clearCashbackInfo: (state, action) => {
      state.businessCashback = {
        details: [],
        profileInfo: [],
      };
      state.cashBackValue = null;
      state.businessCashbackPageNo = 1;
    },
  },

  extraReducers: {
    //fetch All Business Cashback
    [getAllBusinessCashbackData.pending]: (state) => {
      state.businessCashbackLoader = true;
    },
    [getAllBusinessCashbackData.fulfilled]: (state, { payload }) => {
      const cashbackDetailsInfo = payload?.cashbackInfo;
      const profileInfo = payload?.profileInfo?.accountList || [];
      const cashback = cashbackDetailsInfo?.details || [];

      console.log("slice", cashback.length);

      if (cashback.length > 0) {
        state.businessCashback = {
          // ...cashbackDetailsInfo,
          details: [...state.businessCashback?.details, ...cashback],
          profileInfo: [...state.businessCashback?.profileInfo, ...profileInfo],
        };
        state.businessCashbackPageNo = state.businessCashbackPageNo + 1;
      }
      state.businessCashbackLoader = false;
    },

    [getAllBusinessCashbackData.rejected]: (state, { payload }) => {
      state.businessCashbackLoader = false;
    },

    //fetch Cashback value for Business
    [getCashBackValueForBusinessData.pending]: (state) => {
      state.cashBackValueLoader = true;
      state.cashBackValue = null;
    },
    [getCashBackValueForBusinessData.fulfilled]: (state, { payload }) => {
      state.cashBackValue = payload;
      state.cashBackValueLoader = false;
    },
    [getCashBackValueForBusinessData.rejected]: (state, { payload }) => {
      state.cashBackValueLoader = false;
      state.cashBackValue = null;
    },
  },
});

export const { clearCashbackInfo } = businessCashback.actions;

export const businessCashbackReducer = businessCashback.reducer;
