import React from 'react'
import ModalCard from '../../../components/ModalCard';
import { Box, Typography } from '@mui/material';
import { clearCartInfo } from '../../../reducers/grievanceIssue/grievanceSlice';
import { useDispatch } from 'react-redux';
import { BGColor02, blackShade01, blueColor100, border27, greyColor02, whiteColor } from '../../../configs/styles/muiThemes';

function ImageViewModal({ modal, setModal, srcImage }) {
  const dispatch = useDispatch();

  return (
    <Box>
      <ModalCard
        open={modal}
        handleClose={() => {
          setModal({
            show: false,
            image:""
          });
        }}
        width="30%"
        modalType="inside"
      >
        <Box sx={styles.card}>
          <Typography
            variant="h3"
            fontSize={18}
            fontWeight={600}
            lineHeight="34px"
            color={blackShade01}
            textAlign="center"
            p="15px 50px"
          >
            Image
          </Typography>
          <Box sx={styles.contentCard}>
            <Box component="img" src={srcImage} alt="" sx={styles.logoImg} />
          </Box>
          
        </Box>
      </ModalCard>
    </Box>
  );
}

export default ImageViewModal

const styles = {
  card: {
    backgroundColor: BGColor02,
    borderRadius: "15px",
    overflow: "hidden",
  },
  cardBody: {
    maxHeight: "80vh",
    backgroundColor: whiteColor,
    p: "28px 12px",
    overflow: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blueColor100,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: greyColor02,
      borderRadius: "50px !important",
    },
  },
  contentCard: {
    backgroundColor: whiteColor,
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "-webkit-box-align": "center",
    "-webkit-box-pack": "center",
  },
  logoImg: {
    width: { xs: "350px", md: "350px" },
    height: { xs: "350px", md: "350px" },
    border: border27,
    objectFit: "contain",
    borderRadius: "8px",
  },
};
