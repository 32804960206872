import { Box, Typography } from "@mui/material";
import React from "react";
import CopyTextWithIcon from "../../../components/CopyTextWithIcon";
import {
  blackShade05,
  mobilePrimary,
  orangeStatusColor,
} from "../../../configs/styles/muiThemes";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import HowToLinkCard from "../../../components/Cards/HowToLinkCard";
import { useNavigate } from "react-router-dom";

const PageHeader = ({ title, businessData, hideUrl }) => {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(`/${businessData?.costboWebURL}/${route}`);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt={2}
    >
      <Box>
        <Typography
          component="h4"
          fontSize={{ xs: 16, md: 17 }}
          color={blackShade05}
          fontWeight={600}
          lineHeight="28px"
          mb={0.4}
        >
          {title}
        </Typography>

        {!hideUrl && (
          <CopyTextWithIcon
            text={`https://shop.costbo.com/${businessData?.costboWebURL}`}
            onLinkClick={() => {
              window.open(
                `https://shop.costbo.com/${businessData?.costboWebURL}`,
                "_blank"
              );
            }}
          />
        )}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        gap={{ md: 5, xs: 1 }}
        sx={{ cursor: "pointer" }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <SettingsRoundedIcon
            sx={{
              color: { md: orangeStatusColor, xs: mobilePrimary },
              fontSize: { md: "18px", xs: "20px" },
            }}
          />
          <Typography
            fontSize={14}
            fontWeight={500}
            color={orangeStatusColor}
            sx={{
              textDecoration: "underline",
              display: { md: "block", xs: "none" },
            }}
            onClick={() => {
              handleNavigation("warehouseDetail");
            }}
          >
            E-Commerce Settings
          </Typography>
        </Box>

        <HowToLinkCard
          link="Video Guides"
          root
          handleClick={() => {
            navigate("/howToVideoGuides", {
              state: { status: "all" },
            });
          }}
        />
      </Box>
    </Box>
  );
};

export default PageHeader;
