import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardMenu from "./Dashboard/DashboardMenu";
import DashboardHeader from "./Dashboard/DashboardHeader";
import { BGColor02 } from "../configs/styles/muiThemes";
import BusinessInfoCard from "../components/Cards/BusinessInfoCard";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getActiveBusinessInfo } from "../reducers/businessPreview/businessPreviewSlice";
import PaycartOrdersContent from "./PaycartOrders/PaycartOrdersContent";
import { getDashboardPaycartSummaryApi } from "../service/api_calls/BusinessDashboard/BusinessDashboardApi";
import DashboardLayout from "../components/Layouts/DashboardLayout";

function PaycartOrders() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [statusInfo, setStatusInfo] = useState(null);

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  const getPayCartCarddetails = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const summary = await getDashboardPaycartSummaryApi(businessId);

    setStatusInfo(summary?.details);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      if (!statusInfo) {
        getPayCartCarddetails();
      }
    }
  }, []);

  return (
    <Box sx={styles.container}>
      
      <DashboardLayout activeMenu="paycartOrders">
        <PaycartOrdersContent
          businessName={activeBusinessInfo?.name}
          // orderList={businessPaycartOrders || []}
          activeBusinessInfo={activeBusinessInfo}
          summary={statusInfo}
        />
      </DashboardLayout>
    </Box>
  );
}

export default PaycartOrders;

const styles = {
  container: {
    minHeight: "calc(100vh - 110px)",
    backgroundColor: BGColor02,
  },

  content: {
    // display: "flex",
    // flexDirection: "column",
    // gap: { xs: "0px", md: "25px" },
    p: { xs: "0px", md: "15px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },
};
