import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BGColor02,
  blackShade05,
  blackShade159,
  deleteBtnColor,
  greyColor11,
  greyColor28,
  greyColor29,
  greyColor6,
  greyColor7,
  greyColor8,
  greyShade81,
  lightBlueColor01,
  logoImgborder1,
  orangeStatusColor,
  outOfStockColor,
  regiterSuccessNameColor,
  stepperBGColor,
  tableHeaderColor,
  whiteColor,
} from "../../../../configs/styles/muiThemes";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HorizontalStepper from "../../../../components/HorizontalStepper";
import HorizontalDotLineStepper from "../../../../components/HorizontalDotLineStepper";
import VerticalDotLineStepper from "../../../../components/VerticalDotLineStepper";
import ResponsiveStepper from "../../../../components/Stepper/ResponsiveStepper";
import {
  getCartInfoApi,
  resendInvoiceApi,
  resendInvoiceApiB2B,
} from "../../../../service/api_calls/orders/OrdersApi";
import moment from "moment";
import ThreeDotLoader from "../../../../components/ThreeDotLoader";
import {
  getOrderDeliveryTypeText,
  updateApproveList,
} from "../../../../utils/helper";
import { TIME_SCHEDULE } from "../../../../utils/data";
import TimeScheduleStep from "../../../../components/Stepper/TimeScheduleStep";
import DisplayB2BViewContent from "./DisplayB2BViewContent";

function OrderApprovedPopupContent({
  handleClose,
  orderData,
  commerceInfo,
  handleTrackOrder,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [cartInfo, setCartInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState(null);
  const [success, setSuccess] = useState(false);
  const [successB2B, setSuccessB2B] = useState(false);
  const [errorB2B, setErrorB2B] = useState(false);
  const [loadingB2B, setLoadingB2B] = useState(false);

  const getCartData = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    setLoading(true);

    if (orderData?.cartId) {
      const response = await getCartInfoApi({
        cartId: orderData?.cartId,
        businessId,
      });

      if (response?.cart) {
        setCartInfo(response?.cart || null);
        setLoading(false);
        setSteps(updateApproveList(response?.cart));
        setSuccess(false);
        return;
      }
    }

    setLoading(false);
  };

  const handleResend = async () => {
    if (orderData?.cartId) {
      const response = await resendInvoiceApi({
        cartId: orderData?.cartId,
      });

      if (response) {
        setSuccess(true);
        return;
      }
    }
  };

  const handleResendB2B = async () => {
    setErrorB2B(false);
    setSuccessB2B(false);
    setLoadingB2B(false);
    if (orderData?.cartId) {
      if (
        cartInfo?.shipmentInfo?.carrierTrackId?.length === 36 ||
        !cartInfo?.shipmentInfo?.carrierTrackId
      ) {
        setErrorB2B(true);
        return;
      }
      setLoadingB2B(true);
      const businessId = sessionStorage.getItem("selectedBusinessId");
      const response = await resendInvoiceApiB2B({
        cartId: orderData?.cartId,
        carrierId: cartInfo?.shipmentInfo?.carrierTrackId,
        businessId,
      });

      if (response) {
        setSuccessB2B(true);
        setLoadingB2B(false);
        return;
      }
    }
  };

  const getBGColorByStatus = (status) => {
    switch (status) {
      case "ordercreated":
        return orangeStatusColor;
      case "orderapproved":
        return blackShade05;
      case "ordershipped":
        return lightBlueColor01;
      case "orderdelivered":
        return lightBlueColor01;
      case "bordercanceled":
        return outOfStockColor;

      default:
        return "";
    }
  };

  const getOrderStatusText = (status) => {
    switch (status) {
      case "ordercreated":
        return "Pending";
      case "orderapproved":
        return "Ready to Ship";
      case "ordershipped":
        return "Shipped";
      case "orderdelivered":
        return "Completed";
      case "bordercanceled":
        return "Canceled";

      default:
        return "";
    }
  };

  useEffect(() => {
    getCartData();
    return () => {};
  }, []);

  return (
    <Box overflow="hidden">
      {loading ? (
        <Box
          sx={{
            minHeight: "100px",
            backgroundColor: whiteColor,
            borderRadius: "12px",
            pt: "20px",
          }}
        >
          <ThreeDotLoader />
        </Box>
      ) : (
        <Box sx={styles.card}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              fontWeight={600}
              color={blackShade05}
            >
              Order Approved
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              gap={1}
              mr={{ md: 10, xs: 0 }}
            >
              <Button
                sx={styles.cancelIconBtn}
                variant="contained"
                disableElevation
                onClick={handleClose}
              >
                CLOSE
              </Button>
            </Box>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box sx={styles.orderInfoBox}>
                <Box>
                  <Box mb={0.8}>
                    <Typography variant="h6" sx={styles.orderIdText}>
                      Order ID:{" "}
                      <Typography
                        variant="h6"
                        sx={{ ...styles.orderIdText, fontWeight: 600 }}
                      >
                        {cartInfo?.orderId}
                      </Typography>
                    </Typography>
                  </Box>

                  <Typography variant="h6" sx={styles.orderIdText}>
                    Order Date:{" "}
                    <Typography
                      variant="h6"
                      sx={{ ...styles.orderIdText, fontWeight: 600 }}
                    >
                      {moment(cartInfo?.orderCreatedOn).format("MMM Do, YYYY")}
                    </Typography>
                  </Typography>
                </Box>

                <Box
                  sx={styles.statusTag(getBGColorByStatus("Order Processing"))}
                >
                  {getOrderStatusText("Order Processing")}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  borderRadius: "12px",
                  border: `1px solid ${greyColor28}`,
                  p: "13px",
                }}
              >
                <Box
                  display="flex"
                  flexDirection={{ md: "row", xs: "column" }}
                  justifyContent="space-between"
                  sx={{
                    width: "100%",
                  }}
                  gap={1}
                >
                  <Box display="flex" gap={1} alignItems={"center"}>
                    <Avatar
                      alt="Remy Sharp"
                      src={cartInfo?.businessLogo}
                      variant="square"
                      sx={{
                        width: 58,
                        height: 58,
                        border: "2px solid #e2e5ec",
                        borderRadius: "5px",
                      }}
                    />
                    <Box>
                      <Typography
                        variant="h5"
                        fontSize={14}
                        fontWeight={600}
                        lineHeight={"30px"}
                        color={blackShade05}
                      >
                        {cartInfo?.businessName}
                      </Typography>
                      <Typography
                        variant="h4"
                        fontSize={{ xs: 12, md: 13 }}
                        fontWeight={400}
                        lineHeight={1.5}
                        color={greyColor7}
                        width={{ md: "85%", xs: "100%" }}
                      >
                        {cartInfo?.businessAddress?.addressLine1}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" gap={3}>
                    <InfoCard
                      icon={
                        <LocalShippingOutlinedIcon
                          sx={{ fontSize: { xs: "20px", Fmd: "24px" } }}
                        />
                      }
                      label={getOrderDeliveryTypeText(
                        orderData?.deliveryType?.type
                      )}
                    />
                    <InfoCard
                      icon={
                        <EventAvailableOutlinedIcon
                          sx={{ fontSize: { xs: "20px", Fmd: "24px" } }}
                        />
                      }
                      label={cartInfo?.deliveryDateRange}
                    />
                  </Box>
                </Box>
                <Divider sx={{ mt: 1.5, mb: 3 }} />
                {cartInfo?.deliveryType?.type?.toLowerCase() === "delhivery" ||
                cartInfo?.deliveryType?.type?.toLowerCase() === "telyport" ? (
                  <Box>
                    <ResponsiveStepper
                      steps={steps}
                      handleTrackOrder={handleTrackOrder}
                    />
                    {cartInfo?.deliveryType?.type?.toLowerCase() ===
                      "delhivery" && (
                      <Box
                        display="flex"
                        flexDirection={{ md: "row", xs: "column" }}
                        mt={3}
                        ml={{ md: "205px", xs: 0 }}
                      >
                        {TIME_SCHEDULE.map((item, index) => (
                          <TimeScheduleStep
                            label={item.label}
                            subLabel={item.sublabel}
                            msg={item.msg}
                            btn={item.btn}
                            hideLine={TIME_SCHEDULE.length - 1 === index}
                            handleResend={handleResend}
                            success={success}
                            successMsg={item.successMsg}
                            key={`${item.label}${index}`}
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                ) : null}
                {cartInfo?.deliveryType?.type?.toLowerCase() ===
                "delhiveryb2b" ? (
                  <DisplayB2BViewContent
                    boxData={cartInfo?.btobInfo?.dimensions}
                    handleResend={handleResendB2B}
                    success={successB2B}
                    error={errorB2B}
                    loading={loadingB2B}
                  />
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default OrderApprovedPopupContent;

const InfoCard = ({ icon, label, value }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={{ xs: "5px", md: "2px" }}
      width={{ md: "150px", xs: "auto" }}
    >
      <Box sx={styles.iconCircle}>{icon}</Box>
      <Typography
        fontSize={13}
        fontWeight={600}
        color={blackShade05}
        textTransform="capitalize"
        textAlign={"center"}
      >
        {label}
      </Typography>
    </Box>
  );
};

// const TimeScheduleStep = ({
//   hideLine,
//   label,
//   subLabel,
//   btn,
//   msg,
//   handleResend,
//   successMsg,
//   success,
// }) => {
//   return (
//     <Box width={hideLine ? "500px" : "230px"}>
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//         }}
//       >
//         <Box sx={styles.stepNode} />

//         {!hideLine && (
//           <Box
//             sx={{
//               border: "1px dashed #a2aebe",
//               width: "230px",
//               height: "1px",
//             }}
//           />
//         )}
//       </Box>
//       <Box width={"80%"}>
//         <Typography sx={styles.label} mt={1}>
//           {label}
//         </Typography>
//         <Typography sx={styles.subLabel}>{subLabel}</Typography>
//         <Typography sx={styles.msg}>{msg}</Typography>
//         {btn && (
//           <Button sx={styles.resendBtn} onClick={handleResend}>
//             Resend
//           </Button>
//         )}
//         {success && (
//           <Typography sx={{ ...styles.msg, color: "green" }}>
//             {successMsg}
//           </Typography>
//         )}
//       </Box>
//     </Box>
//   );
// };
const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "12px",
    padding: "20px",

    maxHeight: { md: "95vh", xs: "70vh" },
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
      height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blackShade159,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },
  addIconBtn: {
    padding: "5px 24px",
    fontSize: "12px",
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    color: whiteColor,
    backgroundColor: greyColor8,
    borderRadius: "5px",
    border: "1px solid " + greyColor8,
    "&:hover": {
      color: greyColor8,
      backgroundColor: whiteColor,
    },
  },
  approveIconBtn: {
    padding: "5px 24px",
    fontSize: "12px",
    fontWeight: 600,
    color: lightBlueColor01,
    backgroundColor: whiteColor,
    border: "1px solid " + lightBlueColor01,
    "&:hover": {
      color: whiteColor,
      backgroundColor: lightBlueColor01,
    },
  },
  cancelIconBtn: {
    padding: "5px 24px",
    fontSize: "12px",
    fontWeight: 600,
    color: deleteBtnColor,
    backgroundColor: whiteColor,
    border: "1px solid " + deleteBtnColor,
    "&:hover": {
      color: whiteColor,
      backgroundColor: deleteBtnColor,
    },
  },
  orderIdText: {
    fontSize: { xs: 13, md: 14 },
    fontWeight: 500,
    lineHeight: "27px",
    color: blackShade05,
    display: "inline",
  },

  orderInfoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "12px",
    border: `1px solid ${greyColor28}`,
    p: "13px 0 13px 15px",
    mt: 2.2,
  },

  statusTag: (bgColor) => ({
    backgroundColor: bgColor || lightBlueColor01,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "20px",
    color: whiteColor,
    p: "0 15px 0 20px",
    position: "relative",

    "&::before": {
      content: '" "',
      width: "15px",
      height: "28px",
      backgroundColor: whiteColor,
      rotate: "40deg",
      position: "absolute",
      left: -3,
      top: -8,
    },
  }),

  table: {
    border: `1px solid ${greyColor11}`,
    borderRadius: "7px",
    mt: 3,
  },

  tableHeader: {
    backgroundColor: tableHeaderColor,
    p: "15px 10px",
    borderRadius: "7px 7px 0 0",
  },

  tableHeaderText: {
    fontSize: 13,
    fontWeight: 500,
    color: greyColor29,
  },

  productRow: {
    p: "18px 0",
    borderBottom: "1px solid #f2f2f2",
  },

  productImg: {
    width: "60px",
    height: "60px",
    borderRadius: "8px",
    border: logoImgborder1,
  },

  productInfoText: {
    fontSize: 14,
    fontWeight: 500,
    color: greyColor29,
  },

  centerText: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  summaryCard: {
    padding: "12px",
    borderRadius: "10px",
    border: `1px solid ${greyColor28}`,
    mt: 2.2,
  },
  iconCircle: {
    width: { xs: "45px", md: "45px" },
    height: { xs: "45px", md: "45px" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: BGColor02,
    borderRadius: "50%",
  },
  stepNode: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    backgroundColor: greyShade81,
  },
  label: {
    color: blackShade05,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "23px",
  },
  subLabel: {
    color: greyColor6,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "18px",
  },
  msg: {
    color: deleteBtnColor,
    mt: "4px",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "18px",
  },
  resendBtn: {
    padding: "5px 24px",
    fontSize: "12px",
    fontWeight: 600,
    color: greyColor8,
    mt: 1,
    border: "1px solid" + greyColor8,
    backgroundColor: whiteColor,
    "&:hover": {
      backgroundColor: greyColor8,
      color: whiteColor,
    },

    "&.Mui-disabled": {
      border: "1px solid rgba(0, 0, 0, 0.26)",
    },
  },
};
