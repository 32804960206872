import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import {
  BGColor02,
  blackShade19,
  blueColor04,
  borderTextBoxColor,
  greyColor42,
  greyColor47,
  orangeColor02,
  orangeShade14,
  whiteColor,
} from "../../../../configs/styles/muiThemes";
import ModalCard from "../../../../components/ModalCard";

const BulkUploadTemplateGuide = ({ openModal, setOpenModal }) => {
  const STEPS_LIST = [
    { id: "1", text: "Add the S. No. if not given." },

    {
      id: "2",
      text: "Do not remove any of the header rows from the file. Product data should start from row no. 8.",
    },

    {
      id: "3",
      text: "Make sure there are no formulas/calculations in any of the columns.",
    },

    {
      id: "4",
      text: "Check for capitalization and spelling errors in all columns especially in ‘Product name’.",
    },

    {
      id: "5",
      text: "If the ‘Offer Price’ is not provided, assign the ‘Original Price’ value to the column.",
    },

    {
      id: "6",
      text: "‘Quantity on Hand’ should be at least 3 times more than the ‘Max Quantity Per Order’",
    },

    {
      id: "7",
      text: "Make sure photo links (Photo 1, Photo 2, Photo 3, Photo 4) are the direct product image URLs and not the drive folder links that contain multiple images.",
    },

    {
      id: "8",
      text: "Add the ‘Return Period’ if not provided (default is 3 days). This is needed even if the product is not returnable for handling disputes.",
    },

    {
      id: "9",
      text: "‘Product Code’ should align with the category of the products. See the guidelines below.",
    },
  ];

  function createData(category, productCode, digits) {
    return { category, productCode, digits };
  }

  const rows = [
    createData("Grocery", "EAN", "13"),
    createData("BPC", "HSN", "8"),
    createData("Electronics", "GTIN", "12"),
    createData("Home & Kitchen", "HSN", "8"),
    createData("Appliances", "GTIN", "12"),
    createData("Health & Wellness", "EAN", "13"),
    createData("Pharma", "HSN", "8"),
  ];

  return (
    <ModalCard
      open={openModal}
      handleClose={() => {
        setOpenModal(false);
      }}
      width="60%"
    >
      <Box sx={styles.container}>
        <Box
          sx={{
            backgroundColor: BGColor02,
            p: 1.5,
            borderRadius: "14.4px 14.4px 0 0",
            textAlign: "center",
          }}
        >
          <Typography
            variant="body1"
            fontSize={16}
            fontWeight={600}
            color={blackShade19}
            mb={0.3}
          >
            Bulk Upload Product Template Guidelines
          </Typography>
        </Box>

        <Box sx={{ p: "16px 15px" }}>
          <Typography
            variant="h6"
            fontSize={14}
            fontWeight={500}
            color={blackShade19}
            mb={2}
          >
            Please make sure the following guidelines are adhered to, to avoid
            errors while uploading the product template.
          </Typography>

          {STEPS_LIST.map((item, index) => (
            <Box
              display="flex"
              alignItems="center"
              gap={1.5}
              key={item.id}
              mb={1.5}
            >
              <Box sx={styles.count}>{item.id}</Box>

              <Typography
                flex={1}
                variant="body1"
                fontSize={13}
                fontWeight={500}
                color={blackShade19}
              >
                {item.text}
              </Typography>
            </Box>
          ))}

          <Typography
            variant="body1"
            fontSize={14.5}
            fontWeight={600}
            color={blackShade19}
            mt={4}
            mb={2.5}
          >
            Product Code Mapping
          </Typography>

          <Grid container>
            <Grid item xs={6}>
              <Box sx={styles.tableWrapper}>
                <TableContainer sx={styles.tableContainer} component={Box}>
                  <Table>
                    <TableHead>
                      <TableRow
                        sx={{
                          position: "sticky !important",
                          top: 0,
                          backgroundColor: greyColor42,
                          zIndex: 20,
                          borderRadius: "8px 8px 0 0",
                        }}
                      >
                        <TableCell
                          sx={{
                            ...styles.tableCell,
                            borderRight: `1px solid ${whiteColor}`,
                          }}
                        >
                          CATEGORY
                        </TableCell>
                        <TableCell
                          sx={{
                            ...styles.tableCell,
                            borderRight: `1px solid ${whiteColor}`,
                          }}
                        >
                          PRODUCT CODE
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          NO OF DIGITS
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.category}
                          sx={
                            {
                              // "&:last-child td, &:last-child th": { border: 0 },
                            }
                          }
                        >
                          <TableCell sx={styles.tableCell}>
                            {row.category}
                          </TableCell>

                          <TableCell sx={styles.tableCell}>
                            {row.productCode}
                          </TableCell>

                          <TableCell sx={styles.tableCell}>
                            {row.digits}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ModalCard>
  );
};

export default BulkUploadTemplateGuide;

const styles = {
  container: {
    backgroundColor: whiteColor,
    borderRadius: 1.8,
    height: "90vh",
    overflowY: "scroll",

    "&::-webkit-scrollbar": {
        width: "4px",
        height: "4px",
        borderRadius: "50px !important",
      },
      "&::-webkit-scrollbar-thumb": {
        // background: '#d0d3da',
        background: "rgba(0,0,0,0.1)",
      },
      "&::-webkit-scrollbar-track": {
        background: "rgba(0,0,0,0.05)",
      },
  },

  backBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: whiteColor,
    border: `1px solid ${blackShade19}`,
    color: blackShade19,
    p: "5px 20px",
    "&:hover": {
      color: blackShade19,
      backgroundColor: whiteColor,
    },
  },

  applyBtn: {
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: blueColor04,
    border: `1px solid ${blueColor04}`,
    color: whiteColor,
    p: "5px 20px",
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
    },
  },

  count: {
    width: "22px",
    height: "22px",
    borderRadius: "15px",
    fontSize: "12px",
    fontWeight: 600,
    color: orangeColor02,
    bgcolor: whiteColor,
    border:`1px solid ${orangeShade14}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  tableWrapper: {
    borderRadius: "8px",
    border: `1px solid ${borderTextBoxColor}`,
  },

  tableContainer: {
    borderRadius: "8px",
    // overflow: "scroll",
    // maxWidth: "100%",
  },

  tableCell: {
    color: greyColor47,
    fontSize: "12.5px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    padding: "8px 15px",
    borderRight: `1px solid ${borderTextBoxColor}`,
  },
};
