import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllBusinessPreferredDiscountApi,
  getAllBusinessProfileIDApi,
  searchPreferredDiscountByNumberApi,
} from "../../service/api_calls/preferredDiscounts/PreferredDiscountsApi";

const initialState = {
  businessPreferredDiscounts: [],
  businessPreferredDiscountsPageNo: 1,

  allPreferredDiscounts: {
    pageNo: 0,
    pageSize: 0,
    totalCount: 0,
    details: [],
    profileInfo: [],
  },
  allPreferredDiscountsLoader: false,

  activePreferredDiscounts: {
    pageNo: 0,
    pageSize: 0,
    totalCount: 0,
    details: [],
    profileInfo: [],
  },
  activePreferredDiscountsLoader: false,

  expiredPreferredDiscounts: {
    pageNo: 0,
    pageSize: 0,
    totalCount: 0,
    details: [],
    profileInfo: [],
  },
  expiredPreferredDiscountsLoader: false,

  businessPreferredDiscountsLoader: false,

  claimedPreferredDiscounts: {
    pageNo: 0,
    pageSize: 0,
    totalCount: 0,
    details: [],
    profileInfo: [],
  },
  claimedPreferredDiscountsLoader: false,

  searchPreferredDiscounts: {
    details: [],
    profileInfo: [],
  },
  searchPreferredDiscountsLoader:false,
};

const getPreferredDiscountsByStatus = async (param) => {
  const res = await getAllBusinessPreferredDiscountApi(param);
  // console.log(res);
  const profileId = res.details?.map((item, index) => item?.rewardsProfileId);
  // console.log(profileId);
  console.log("profileId", profileId);

  const uniqueprofileId = [];

  for (const item of profileId) {
    if (!uniqueprofileId.includes(item)) {
      uniqueprofileId.push(item);
    }
  }
  const uniqueprofileIds = uniqueprofileId.filter((item) => item !== "");
  console.log("uniqueprofileIds:", uniqueprofileIds );

  let profileParams = "?";
  uniqueprofileIds.forEach((element, index) => {
    profileParams =
      profileParams +
      (index === uniqueprofileIds.length - 1
        ? `params=${element}`
        : `params=${element}&`);
  });
  console.log("profileParams:", profileParams);

  const profileInfo = await getAllBusinessProfileIDApi({
    ...param,
    profileParams,
  });
  console.log("ProfileInfo:", profileInfo);

  return {
    preferredDiscounts: res,
    profileInfo,
  };
};

// Get All Business customers
export const getAllBusinessPreferredDiscountsData = createAsyncThunk(
  "getAllBusinessPreferredDiscountsData",
  async (param, thunkAPI) => {
    const response = await getPreferredDiscountsByStatus(param);
    return response;
  }
);

// Active
export const getActivePreferredDiscountsData = createAsyncThunk(
  "getActivePreferredDiscountsData",
  async (param, thunkAPI) => {
    const response = await getPreferredDiscountsByStatus({
      ...param,
    });
    console.log("ACTIVE_RESPONSE", response);

    return response;
  }
);

// Expired
export const getExpiredPreferredDiscountsData = createAsyncThunk(
  "getExpiredPreferredDiscountsData",
  async (param, thunkAPI) => {
    const response = await getPreferredDiscountsByStatus({
      ...param,
    });
    console.log("EXPIRED_RESPONSE", response);

    return response;
  }
);

// Claimed
export const getClaimedPreferredDiscountsData = createAsyncThunk(
  "getClaimedPreferredDiscountsData",
  async (param, thunkAPI) => {
    const response = await getPreferredDiscountsByStatus({
      ...param,
      status: "claimed",
      pageNo: 1,
    });
    console.log("CLAIMED_RESPONSE", response);

    return response;
  }
);
export const searchPreferredDiscountsData = createAsyncThunk(
  "searchPreferredDiscountsData",
  async (param, thunkAPI) => {
    const response = await searchPreferredDiscountByNumberApi({
      ...param,
    });
    const profileId = response.details?.map(
      (item, index) => item?.rewardsProfileId
    );
    // console.log(profileId);

    const uniqueprofileIds = [];

    for (const item of profileId) {
      if (!uniqueprofileIds.includes(item)) {
        uniqueprofileIds.push(item);
      }
    }
    console.log("uniqueprofileIds:", uniqueprofileIds);

    let profileParams = "?";
    uniqueprofileIds.forEach((element, index) => {
      profileParams =
        profileParams +
        (index === uniqueprofileIds.length - 1
          ? `params=${element}`
          : `params=${element}&`);
    });
    console.log("profileParams:", profileParams);

    const profileInfo = await getAllBusinessProfileIDApi({
      ...param,
      profileParams,
    });
    console.log("ProfileInfo:", profileInfo);

    return {
      preferredDiscounts: response,
      profileInfo,
    };
  }
);

export const businessPreferredDiscounts = createSlice({
  name: "PreferredDiscounts",
  initialState,
  reducers: {
    clearPreferredDiscountsInfo: (state, action) => {
      state.businessPreferredDiscounts = null;

      //search Preferred discount
      state.searchPreferredDiscounts = {
        details: [],
        profileInfo: [],
      };
      state.searchPreferredDiscountsLoader = false;

      // All Preferred Discounts
      state.allPreferredDiscounts = {
        pageNo: 0,
        pageSize: 0,
        totalCount: 0,
        details: [],
        profileInfo: [],
      };
      state.allPreferredDiscountsLoader = false;

      // Active Preferred Discounts
      state.activePreferredDiscounts = {
        pageNo: 0,
        pageSize: 0,
        totalCount: 0,
        details: [],
        profileInfo: [],
      };
      state.activePreferredDiscountsLoader = false;

      // Expired Preferred Discounts
      state.expiredPreferredDiscounts = {
        pageNo: 0,
        pageSize: 0,
        totalCount: 0,
        details: [],
        profileInfo: [],
      };
      state.expiredPreferredDiscountsLoader = false;

      // Claimed Preferred Discounts
      state.claimedPreferredDiscounts = {
        pageNo: 0,
        pageSize: 0,
        totalCount: 0,
        details: [],
        profileInfo: [],
      };
      state.claimedPreferredDiscountsLoader = false;
    },
  },
  extraReducers: {
    //fetch all Preferred Discounts
    [getAllBusinessPreferredDiscountsData.pending]: (state) => {
      state.allPreferredDiscountsLoader = true;
    },
    [getAllBusinessPreferredDiscountsData.fulfilled]: (state, { payload }) => {
      const discountsInfo = payload?.preferredDiscounts;
      const profileInfo = payload?.profileInfo?.accountList || [];
      const preferredDiscounts = discountsInfo?.details || [];

      if (preferredDiscounts.length > 0) {
        state.allPreferredDiscounts = {
          ...discountsInfo,
          details: [
            ...state.allPreferredDiscounts?.details,
            ...preferredDiscounts,
          ],
          profileInfo: [
            ...state.allPreferredDiscounts.profileInfo,
            ...profileInfo,
          ],
        };
      }

      state.allPreferredDiscountsLoader = false;
    },
    [getAllBusinessPreferredDiscountsData.rejected]: (state, { payload }) => {
      state.allPreferredDiscountsLoader = false;
    },

    //fetch active preferred Discounts
    [getActivePreferredDiscountsData.pending]: (state) => {
      state.activePreferredDiscountsLoader = true;
    },
    [getActivePreferredDiscountsData.fulfilled]: (state, { payload }) => {
      const discountsInfo = payload?.preferredDiscounts;
      const profileInfo = payload?.profileInfo?.accountList || [];
      const preferredDiscounts = discountsInfo?.details || [];
      const filteredDiscounts = preferredDiscounts?.filter(
        (item) => item?.rewardStatus === "active"
      );
      if (preferredDiscounts.length > 0) {
        state.activePreferredDiscounts = {
          ...discountsInfo,
          details: [
            ...state.activePreferredDiscounts?.details,
            ...filteredDiscounts,
          ],
          profileInfo: [
            ...state.activePreferredDiscounts.profileInfo,
            ...profileInfo,
          ],
        };
      }
      state.activePreferredDiscountsLoader = false;
    },
    [getActivePreferredDiscountsData.rejected]: (state, { payload }) => {
      state.activePreferredDiscountsLoader = false;
    },

    //fetch expired preferred Discounts
    [getExpiredPreferredDiscountsData.pending]: (state) => {
      state.expiredPreferredDiscountsLoader = true;
    },
    [getExpiredPreferredDiscountsData.fulfilled]: (state, { payload }) => {
      const discountsInfo = payload?.preferredDiscounts;
      const profileInfo = payload?.profileInfo?.accountList || [];
      const preferredDiscounts = discountsInfo?.details || [];
      const filteredDiscounts = preferredDiscounts?.filter(
        (item) => item?.rewardStatus === "expired"
      );

      if (preferredDiscounts.length > 0) {
        state.expiredPreferredDiscounts = {
          ...discountsInfo,
          details: [
            ...state.expiredPreferredDiscounts?.details,
            ...filteredDiscounts,
          ],
          profileInfo: [
            ...state.expiredPreferredDiscounts.profileInfo,
            ...profileInfo,
          ],
        };
      }
      state.expiredPreferredDiscountsLoader = false;
    },
    [getExpiredPreferredDiscountsData.rejected]: (state, { payload }) => {
      state.expiredPreferredDiscountsLoader = false;
    },

    //fetch claimed preferred Discounts
    [getClaimedPreferredDiscountsData.pending]: (state) => {
      state.claimedPreferredDiscountsLoader = true;
    },
    [getClaimedPreferredDiscountsData.fulfilled]: (state, { payload }) => {
      const discountsInfo = payload?.preferredDiscounts;
      const profileInfo = payload?.profileInfo?.accountList || [];
      const preferredDiscounts = discountsInfo?.details || [];

      if (preferredDiscounts.length > 0) {
        state.claimedPreferredDiscounts = {
          ...discountsInfo,
          details: [
            ...state.claimedPreferredDiscounts?.details,
            ...preferredDiscounts,
          ],
          profileInfo: [
            ...state.claimedPreferredDiscounts.profileInfo,
            ...profileInfo,
          ],
        };
      }
      state.claimedPreferredDiscountsLoader = false;
    },
    [getClaimedPreferredDiscountsData.rejected]: (state, { payload }) => {
      state.claimedPreferredDiscountsLoader = false;
    },

    //SEARCh preferred Discounts
    [searchPreferredDiscountsData.pending]: (state) => {
      state.searchPreferredDiscountsLoader = true;
    },
    [searchPreferredDiscountsData.fulfilled]: (state, { payload }) => {
      const discountsInfo = payload?.preferredDiscounts;
      const profileInfo = payload?.profileInfo?.accountList || [];
      const preferredDiscounts = discountsInfo?.details || [];
      console.log("Payload",payload);

      if (preferredDiscounts.length > 0) {
        state.searchPreferredDiscounts = {
          ...discountsInfo,
          details: [
            ...state.searchPreferredDiscounts?.details,
            ...preferredDiscounts,
          ],
          profileInfo: [
            ...state.searchPreferredDiscounts.profileInfo,
            ...profileInfo,
          ],
        };
      }
      state.searchPreferredDiscountsLoader = false;
    },
    [searchPreferredDiscountsData.rejected]: (state, { payload }) => {
      state.searchPreferredDiscountsLoader = false;
    },
  },
});

export const { clearPreferredDiscountsInfo } =
  businessPreferredDiscounts.actions;

export const businessPreferredDiscountsReducer =
  businessPreferredDiscounts.reducer;
