import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { BGColor02 } from "../configs/styles/muiThemes";

import { useDispatch, useSelector } from "react-redux";
import {
  getActiveBusinessInfo,
} from "../reducers/businessPreview/businessPreviewSlice";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import ManageReturnsContent from "./ManageReturns/ManageReturnsContent";
import { clearReturnOrderInfo } from "../reducers/manageReturns/manageReturnsSlice";

function ManageReturns() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);

  const dispatch = useDispatch();

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
    }
    return ()=>{
      dispatch(clearReturnOrderInfo())
    }
  }, []);

  return (
    activeBusinessInfo && (
      <Box sx={styles.container}>
        <DashboardLayout activeMenu="returns">
          <ManageReturnsContent />
        </DashboardLayout>
      </Box>
    )
  );
}

export default ManageReturns;

const styles = {
  container: {
    backgroundColor: BGColor02,
  },
};
