import { Box, Button, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blackShade22,
  blueColor04,
  greyColor6,
  greyColor8,
  greyShade81,
  lightBlueColor01,
  mobilePrimary,
  whiteColor,
} from "../../configs/styles/muiThemes";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function ResponsiveStepper({ steps, handleTrackOrder }) {
  return (
    <Box display={"flex"} flexDirection={{ md: "row", xs: "column" }}>
      {steps?.map((item, index) => (
        <Step
          active={item.status == "active"}
          hideLine={steps.length - 1 === index}
          label={item.label}
          subLabel={item.subLabel}
          btn={item.btn}
          handleTrackOrder={handleTrackOrder}
          key={`${item.label}${index}`}
        />
      ))}
    </Box>
  );
}
function Step({ active, hideLine, label, subLabel, btn, handleTrackOrder }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { md: "column", xs: "row" },
        gap: { xs: 1, md: 0 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          // background: "red",
          alignItems: { md: "center", xs: "center" },
        }}
      >
        <Box sx={active ? styles.activeNode : styles.inactiveNode}>
          {active ? (
            <CheckRoundedIcon sx={{ fontSize: "18px", color: whiteColor }} />
          ) : (
            <FiberManualRecordIcon
              sx={{ fontSize: "15px", color: greyShade81 }}
            />
          )}
        </Box>

        {!hideLine && (
          <Box
            sx={{
              border: "1px dashed #a2aebe",
              width: { md: "180px", xs: "1px" },
              height: { md: "1px", xs: "50px" },
            }}
          />
        )}
      </Box>
      <Box>
        <Typography sx={styles.label} mt={{ xs: -0.5, md: 1 }}>
          {label}
        </Typography>
        <Typography sx={styles.subLabel}>{subLabel}</Typography>
        {btn && (
          <Button sx={styles.trackBtn} onClick={handleTrackOrder}>
            Track
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default ResponsiveStepper;

const styles = {
  activeNode: {
    width: "25px",
    height: "25px",
    background: { md: lightBlueColor01, xs: mobilePrimary },
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inactiveNode: {
    width: "25px",
    height: "25px",
    background: whiteColor,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid" + greyShade81,
  },

  trackBtn: {
    padding: { md: "5px 24px", xs: "3px 15px" },
    fontSize: "12px",
    fontWeight: 600,
    color: whiteColor,
    mt: 1,
    border: "1px solid" + { md: blueColor04, xs: blackShade22 },
    backgroundColor: { md: blueColor04, xs: blackShade22 },
    "&:hover": {
      color: { md: blueColor04, xs: blackShade22 },
      backgroundColor: whiteColor,
    },

    "&.Mui-disabled": {
      border: "1px solid rgba(0, 0, 0, 0.26)",
    },
  },
  label: {
    color: blackShade05,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "23px",
  },
  subLabel: {
    color: greyColor6,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "23px",
  },
};
