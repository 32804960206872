import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardMenu from "../Dashboard/DashboardMenu";
import DashboardHeader from "../Dashboard/DashboardHeader";
import {
  getActiveBusinessInfo,
  getCommerceInfo,
} from "../../reducers/businessPreview/businessPreviewSlice";
import { BGColor02 } from "../../configs/styles/muiThemes";
import BusinessInfoCard from "../../components/Cards/BusinessInfoCard";
import moment from "moment";
import OtherDetailsContent from "./PreviewContent/OtherDetailsContent";
import DashboardLayout from "../../components/Layouts/DashboardLayout";

function OtherDetails() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const dispatch = useDispatch();

  const {
    activeBusinessInfo,
    commerceInfo,
    activeBusinessInfoLoader,
    commerceInfoLoader,
  } = useSelector((state) => state.businessPreview);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      if (!commerceInfo) {
        dispatch(getCommerceInfo(businessId));
      }
    }
  }, []);

  return (
    <Box sx={styles.container}>
      <DashboardLayout
        activeMenu="eCommerceControls"
        activeSubMenu="otherDetail"
      >
        <Box pt={{ xs: 1, md: 0 }}>
          <OtherDetailsContent commerceDetails={commerceInfo?.info} />
        </Box>
      </DashboardLayout>
    </Box>
  );
}

export default OtherDetails;

const styles = {
  container: {
    backgroundColor: BGColor02,
    pb: "15px",
  },

  content: {
    p: { xs: "0 0 20px", md: "15px 25px 25px 25px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },
};
