import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  IconButton,
  Snackbar,
  Popper,
  Fade,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Stack,
  CircularProgress,
  Chip,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import MuiAlert from "@mui/material/Alert";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import moment from "moment";
import {
  getActiveBusinessInfo,
  getCommerceInfo,
} from "../../../reducers/businessPreview/businessPreviewSlice";
import { updateInventoryData } from "../../../service/api_calls/inventory/InventoryApi";
import {
  BGColor02,
  blackColor,
  blackShade05,
  blackShade19,
  blackShade21,
  blackShade22,
  blueColor04,
  borderTextBoxColor,
  copyBtnColor,
  greenColor,
  greyColor10,
  greyColor40,
  greyColor42,
  greyColor47,
  greyColor5,
  greyColor6,
  greyColor8,
  greyColor9,
  greyShade168,
  lightRedColor,
  logoImgborder1,
  mobilePrimary,
  outOfStockColor,
  redColor,
  redColor01,
  whiteColor,
  yellowShade01,
  yellowShade02,
} from "../../../configs/styles/muiThemes";
import {
  ALL_STOCK_COLUMN_HEADER_NEW,
  INVENTORY_VIDEO_DATA,
} from "../../../utils/data";
import ModalCard from "../../../components/ModalCard";
import EmptyCard from "../../../components/Cards/EmptyCard";
import { getGstRates } from "../../../reducers/catalog/CatalogSlice";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import HowToLinkCard from "../../../components/Cards/HowToLinkCard";
import VideoModal from "../../../components/VideoModal";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import { stickyHeader, textOneLines } from "../../../utils/styles";
import UpdateIcon from "@mui/icons-material/Update";
import QuickAllStockUpdate from "../Popups/QuickAllStockUpdate";
import TabelCellInputBox from "../../../components/TableInputs/TabelCellInputBox";
import TableCellDropdownBox from "../../../components/TableInputs/TableCellDropdownBox";
import TableCellDateInputBox from "../../../components/TableInputs/TableCellDateInputBox";
import ErrorToolTipIcon from "../../CatalogBulkUploadNewCopy/Components/ErrorToolTipIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { toTitleCase } from "../../../utils/validationUtils";
import {
  clearInventoryInfo,
  filterInventoryOndcProducts,
  getAllStockUpdateProducts,
  getCategoryDropdownListData,
  getInventorySummaryCounts,
  handleAllStockUpdateChange,
  handleQuickAllStockUpdate,
  refreshAllStockUpdateProducts,
  searchInventoryProducts,
  selectAllInventoryProducts,
  updateAllStockInfoList,
} from "../../../reducers/inventory/inventorySlice";
import TableCellSwitchBtn from "../../../components/TableInputs/TableCellSwitchBtn";
import {
  getSelectedInventoryRowCount,
  validateAllStockInfoUpdate,
} from "../../../reducers/inventory/inventoryHelper";
import InventoryTabs from "../Components/InventoryTabs";
import SearchBoxInput from "../../../components/Input/SearchBoxInput";
import SearchInfoCard from "../Components/SearchInfoCard";
import QuickUpdateBtn from "../Components/QuickUpdateBtn";
import InfiniteScroll from "react-infinite-scroll-component";
import AllStockCard from "../Cards/AllStockCard";
import InventoryFilterCard from "../Cards/InventoryFilterCard";
import UpdateInventoryBtn from "../Components/UpdateInventoryBtn";
import MobileActionBtns from "../Components/MobileActionBtns";
import MobileCategoryFilter from "../Popups/MobileCategoryFilter";

function AllStockTab() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();

  const PAGE_SIZE = 50;
  const [hasMoreData, setHasMoreData] = useState(true);

  const [openQuickUpdateModal, setOpenQuickUpdateModal] = useState(false);
  const [quickUpdateErr, setQuickUpdateErr] = useState("");

  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");
  const [isClearSearch, setIsClearSearch] = useState(false);

  const [filterType, setFilterType] = useState("");
  const [isClearFilter, setIsClearFilter] = useState(null);

  const [categoryInfo, setCategoryInfo] = useState(null);

  const [selectedOndcCategory, setSelectedOndcCategory] = useState("All");
  const [selectedOndcSubCat, setSelectedOndcSubCat] = useState("All");

  const [openSnack, setOpenSnack] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [updateLoader, setUpdateLoader] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, value: "" });
  const [infoModal, setInfoModal] = useState(false);

  const [inventoryErr, setInventoryErr] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openMobileFilterModel, setOpenMobileFilterModel] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const { activeBusinessInfo, commerceInfo, businessProductsLoader } =
    useSelector((state) => state.businessPreview);

  const { gstRates } = useSelector((state) => state.catalog);

  const {
    allStockUpdateProducts,
    allStockUpdatePageNo,
    allStockUpdateProductsLoader,
    inventorySummary,
    searchLoader,
    ondcDropdownData,
  } = useSelector((state) => state.inventory);

  // Fetch All Stock Update Products
  const fetchAllStockUpdateProducts = (isRefresh) => {
    setHasMoreData(true);
    const businessId = sessionStorage.getItem("selectedBusinessId");

    if (isRefresh) {
      dispatch(
        refreshAllStockUpdateProducts({
          businessId,
          pageSize: PAGE_SIZE,
          pageNo: 1,
        })
      );

      dispatch(getInventorySummaryCounts(businessId));
      setFilterType("");
      setIsClearFilter(null);

      setSearchQuery("");
      setIsClearSearch(false);

      setSelectedOndcCategory("All");
      setSelectedOndcSubCat("All");

      return;
    }

    if (filterType === "ondc" && categoryInfo) {
      dispatch(
        filterInventoryOndcProducts({
          businessId,
          pageSize: PAGE_SIZE,
          pageNo: allStockUpdatePageNo,
          ondcMainCat: categoryInfo?.ondcMainCat || "",
          ondcSubCat: categoryInfo?.ondcSubCat || "",
          tabStatus: "all_stock",
        })
      ).then((res) => {
        if (
          res?.payload?.result?.length == 0 ||
          res?.payload?.result?.length < PAGE_SIZE
        ) {
          setHasMoreData(false);
        }
      });

      return;
    }

    dispatch(
      getAllStockUpdateProducts({
        businessId,
        pageSize: PAGE_SIZE,
        pageNo: allStockUpdatePageNo,
      })
    ).then((res) => {
      if (
        res?.payload?.details?.length == 0 ||
        res?.payload?.details?.length < PAGE_SIZE
      ) {
        setHasMoreData(false);
      }
    });
  };

  // navigate To Tab
  const navigateToTab = (newValue) => {
    if (newValue === 2) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/lowStock`);
    }
    if (newValue === 1) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/outOfStock`);
    }
    if (newValue == 3) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/priceUpdate`);
    }
  };

  // Handle Tab Value Change
  const handleChange = (event, newValue) => {
    console.log("NEW_VALUE", newValue);
    const filteredProducts = allStockUpdateProducts?.filter(
      (item) => item?.itemChange
    );

    if (filteredProducts?.length > 0) {
      setErrorMsg(
        `${tabValue == 2 ? "Pricing" : "Inventory"} has been modified for ${
          filteredProducts?.length
        } products. Do you want to save the changes before proceeding ?`
      );
      setErrorModal({ show: true, value: newValue });
      return;
    }

    navigateToTab(newValue);
  };

  // Update inventory
  const updateInventory = (event) => {
    setInventoryErr(false);
    setUpdateLoader(true);

    const filteredList = allStockUpdateProducts?.filter(
      (item) => item?.itemChange
    );

    if (filteredList?.length == 0) {
      setInventoryErr((prev) => !prev);
      setAnchorEl(event.currentTarget);
      setUpdateLoader(false);

      setTimeout(() => {
        setInventoryErr(false);
      }, 2000);
      return;
    }

    const valididatedData = validateAllStockInfoUpdate(allStockUpdateProducts);
    console.log("valididatedData__:", valididatedData);

    if (valididatedData.isError) {
      dispatch(
        updateAllStockInfoList({
          allStockUpdateProducts: valididatedData?.productList,
        })
      );
      setUpdateLoader(false);
      return;
    } else {
      const businessId = sessionStorage.getItem("selectedBusinessId");
      let postDataList = [];

      filteredList?.forEach((item) => {
        if (item?.availability?.[0] == "Yes") {
          postDataList.push({
            productId: item?.productId,
            // skuId: "",
            businessId: businessId,
            minOrderQuantity: Number(item?.minOrderQuantity),
            maxOrderQuantity: Number(item?.maxOrderQuantity),
            availableQuantity: Number(item?.availableQuantity),
            alertQuantity: Number(item?.alertQuantity),
            availability: ["Yes"],
            hyperLocal: "Y",
            shipmentDistance: "5000",
            productSalePrice: Number(item?.productSalePrice),
            productOriginalPrice: Number(item?.productOriginalPrice),
            commerceStartDate: moment(item?.commerceStartDate)
              .startOf("day")
              .valueOf(),
            commerceEndDate: moment(item?.commerceEndDate)
              .endOf("day")
              .valueOf(),
            gstInfo: item?.gstInfo,
          });
        } else {
          postDataList.push({
            productId: item?.productId,
            skuId: "",
            businessId: businessId,
            minOrderQuantity: Number(item?.minOrderQuantity),
            maxOrderQuantity: Number(item?.maxOrderQuantity),
            availableQuantity: Number(item?.availableQuantity),
            alertQuantity: Number(item?.alertQuantity),
            availability: ["No"],
            hyperLocal: "Y",
            shipmentDistance: "5000",
            productSalePrice: Number(item?.productSalePrice),
            productOriginalPrice: Number(item?.productOriginalPrice),
            commerceStartDate: moment(item?.commerceStartDate).valueOf(),
            commerceEndDate: moment(item?.commerceEndDate).valueOf(),
            gstInfo: item?.gstInfo,
          });
        }
      });
      // setUpdateLoader(false);
      updateInventoryData(postDataList, businessId)
        .then(() => {
          console.log("Success");
          setSuccessMsg(
            `${tabValue == 2 ? "Pricing" : "Inventory"} has been updated for ${
              filteredList?.length
            } products`
          );
          setOpenSnack(true);
          setUpdateLoader(false);
        })
        .finally(() => {
          setUpdateLoader(false);
          fetchAllStockUpdateProducts(true);
        });
    }
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setSuccessMsg("");
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  // handle Input Value Change
  const handleInputChange = (value, index) => {
    dispatch(
      handleAllStockUpdateChange({
        priceUpdateProducts: allStockUpdateProducts,
        value,
        index,
      })
    );
  };

  // Handle Quick Update Click
  const handleQuickUpdateClick = () => {
    setQuickUpdateErr("");
    const isSelectedAny = allStockUpdateProducts?.some(
      (item) => item.itemChange
    );

    if (isSelectedAny) {
      setOpenQuickUpdateModal(true);
    } else {
      setQuickUpdateErr("Select atleast one row to continue");
    }
  };

  // Applting Quick All Stock Update details
  const applyQuickUpdates = (quickUpdateData) => {
    dispatch(
      handleQuickAllStockUpdate({
        allStockUpdateProducts,
        quickUpdateData,
      })
    );

    setTimeout(() => {
      setOpenQuickUpdateModal(false);
    }, 500);
  };

  // Handle Inventory Product Search
  const handleSearch = () => {
    if (!searchQuery) return;

    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      searchInventoryProducts({
        businessId,
        pageSize: PAGE_SIZE,
        pageNo: 1,
        tabStatus: "all_stock",
        searchQuery,
      })
    ).then((res) => {
      setFilterType("");
      setIsClearFilter(null);

      setSelectedOndcCategory("All");
      setSelectedOndcSubCat("All");

      setIsClearSearch(true);
    });
  };

  const applyONDCFilter = (categoryData, subCategoryData) => {
    setHasMoreData(true);

    const mainCatCode = categoryData?.ondcCode || "";
    const subCatCode = subCategoryData?.ondcSubCode || "";

    setCategoryInfo((prevState) => ({
      ...prevState,
      ondcMainCat: mainCatCode,
      ondcSubCat: subCatCode,
    }));

    setFilterType("ondc");

    setSearchQuery("");
    setIsClearSearch(false);

    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      filterInventoryOndcProducts({
        businessId,
        pageSize: PAGE_SIZE,
        pageNo: 1,
        ondcMainCat: mainCatCode,
        ondcSubCat: subCatCode,
        tabStatus: "all_stock",
      })
    ).then((res) => {
      if (
        res?.payload?.result?.length == 0 ||
        res?.payload?.result?.length < PAGE_SIZE
      ) {
        setHasMoreData(false);
      }
    });
  };

  // Select All Rows
  const selectAllRows = () => {
    const selectType = allStockUpdateProducts?.every(
      (item) => item.itemChange
    )

    dispatch(
      selectAllInventoryProducts({
        tabStatus: "all_stock",
        productList: allStockUpdateProducts,
        selectType: !selectType
      })
    );
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }

      fetchAllStockUpdateProducts();

      // dispatch(getProductCategories(businessId));

      // Fetching ONDC Dropdown Data
      if (ondcDropdownData?.length === 0) {
        dispatch(getCategoryDropdownListData(businessId));
      }

      if (!commerceInfo) {
        dispatch(getCommerceInfo(businessId));
      }
      dispatch(getGstRates());
    }
    return () => {
      dispatch(clearInventoryInfo());
    };
  }, []);

  return (
    <Box sx={styles.container}>
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          handleClose={handleCloseVideoModal}
          data={INVENTORY_VIDEO_DATA}
        />
      )}

      <Snackbar
        open={openSnack}
        autoHideDuration={4000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>

      <DashboardLayout activeMenu="inventory" activeSubMenu="activeInventory">
        <Box sx={styles.content}>
          <Box sx={{ ...stickyHeader, pt: 1.5 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: { md: "center", xs: "flex-start" },
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Typography
                component="h4"
                fontSize={{ xs: 15, md: 17 }}
                color={blackShade05}
                fontWeight={600}
                lineHeight="20px"
              >
                Active Inventory
              </Typography>
              <HowToLinkCard
                link="Inventory Management"
                handleClick={() => {
                  setOpenVideoModal(true);
                }}
              />
            </Box>

            <InventoryTabs tabValue={tabValue} handleChange={handleChange} />

            {!businessProductsLoader &&
            allStockUpdatePageNo != 1 &&
            inventorySummary ? (
              <>
                {Number(inventorySummary?.inStock) > 0 && (
                  <Box
                    display={{ md: "flex", xs: "none" }}
                    justifyContent="space-between"
                    width={"100%"}
                  >
                    <Box>
                      <Typography
                        fontSize={13}
                        color={greyColor5}
                        display={{ md: "block", xs: "none" }}
                      >
                        You may update the stock, pricing and other inventory
                        related details for the products listed here
                      </Typography>

                      <Typography
                        fontSize={13}
                        color={greyColor5}
                        display={{ md: "block", xs: "none" }}
                      >
                        To temporarily pause selling a product set the
                        'AVAILABILITY' toggle button to NO. This will mark the
                        product as 'Out of Stock' in your catalog.
                      </Typography>

                      <Box display={"flex"} alignItems="center" mt={0.2}>
                        <Typography
                          fontSize={13}
                          fontWeight={500}
                          color={greyShade168}
                          sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => setInfoModal(true)}
                        >
                          How to update Stock?
                        </Typography>

                        <IconButton
                          sx={{ p: "0 0 0 6px" }}
                          onClick={() => setInfoModal(true)}
                        >
                          <InfoRoundedIcon
                            sx={{ fontSize: "18px", color: greyColor9 }}
                          />
                        </IconButton>
                      </Box>
                    </Box>

                    <UpdateInventoryBtn
                      inventoryErr={inventoryErr}
                      anchorEl={anchorEl}
                      updateInventory={updateInventory}
                      updateLoader={updateLoader}
                    />
                  </Box>
                )}

                <Box m={{ xs: "10px 0 10px", md: "6px 0 10px" }}>
                  <Grid
                    container
                    // rowSpacing={{ xs: 2, md: 0 }}
                    columnSpacing={{ xs: 2, md: 0 }}
                  >
                    <Grid item xs={12} md={7}>
                      <Box
                        display="flex"
                        flexDirection={{ xs: "row", md: "column" }}
                        alignItems={{ xs: "center", md: "flex-start" }}
                        justifyContent="space-between"
                      >
                        <Box display="flex" alignItems="center" flex={1}>
                          <Typography
                            component="h4"
                            fontSize={15}
                            color={blackShade05}
                            fontWeight={600}
                            lineHeight={{ xs: "18px", md: "20px" }}
                            mt={{ xs: 0, md: 0.7 }}
                          >
                            All Stock
                          </Typography>

                          {!matches && (
                            <IconButton>
                              <InfoRoundedIcon
                                sx={{ fontSize: "18px", color: greyColor9 }}
                                onClick={() => setInfoModal(true)}
                              />
                            </IconButton>
                          )}
                        </Box>

                        {!matches && Number(inventorySummary?.inStock) > 0 && (
                          <MobileActionBtns
                            onFilterClick={() => {
                              setOpenMobileFilterModel(true);
                            }}
                            isSearchShown={showSearchBar}
                            onSearchClick={() => {
                              setShowSearchBar(!showSearchBar);
                            }}
                          />
                        )}

                        {(isClearSearch || isClearFilter) && matches && (
                          <SearchInfoCard
                            searchQuery={searchQuery}
                            clearSearch={() => {
                              fetchAllStockUpdateProducts(true);
                              setIsClearSearch(false);
                              setSearchQuery("");
                            }}
                            filterType={filterType}
                            isClearFilter={isClearFilter}
                            data={allStockUpdateProducts}
                          />
                        )}
                      </Box>
                    </Grid>

                    {Number(inventorySummary?.inStock) > 0 && (
                      <>
                        <Grid item xs={12} md={5}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-end"
                          >
                            {(!matches && showSearchBar) || matches ? (
                              <SearchBoxInput
                                placeholder="Search Product"
                                value={searchQuery}
                                onChange={(text) => {
                                  setSearchQuery(text);
                                }}
                                onSearchClick={handleSearch}
                                minWidth={
                                  matches ? "335px" : "calc(100vw - 30px)"
                                }
                                isDashboardInput
                              />
                            ) : null}

                            {matches && (
                              <Box
                                display="flex"
                                alignItems="flex-start"
                                mt={2}
                              >
                                <InventoryFilterCard
                                  applyONDCFilter={applyONDCFilter}
                                  setIsClearFilter={setIsClearFilter}
                                  setFilterType={setFilterType}
                                  // ONDC Category
                                  ondcDropdownData={ondcDropdownData}
                                  selectedOndcCategory={selectedOndcCategory}
                                  setSelectedOndcCategory={
                                    setSelectedOndcCategory
                                  }
                                  selectedOndcSubCat={selectedOndcSubCat}
                                  setSelectedOndcSubCat={setSelectedOndcSubCat}
                                  refreshProductList={
                                    fetchAllStockUpdateProducts
                                  }
                                />

                                <QuickUpdateBtn
                                  onClick={handleQuickUpdateClick}
                                  updateLoader={updateLoader}
                                  quickUpdateErr={quickUpdateErr}
                                />
                              </Box>
                            )}
                          </Box>
                        </Grid>

                        {!matches && Number(inventorySummary?.inStock) > 0 && (
                          <>
                            <Grid item xs={1} md={12}></Grid>

                            <Grid item xs={5} md={12}>
                              <QuickUpdateBtn
                                onClick={handleQuickUpdateClick}
                                updateLoader={updateLoader}
                                quickUpdateErr={quickUpdateErr}
                                styles={{ alignItems: "center" }}
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={6} md={12}>
                              <UpdateInventoryBtn
                                inventoryErr={inventoryErr}
                                anchorEl={anchorEl}
                                updateInventory={updateInventory}
                                updateLoader={updateLoader}
                              />
                            </Grid>

                            {allStockUpdateProducts?.map(
                              (data, i) =>
                                // data?.errorList?.map((item, i) => (
                                data?.errorList?.length > 0 && (
                                  <Grid item xs={12} md={12} alignItems={"end"}>
                                    <Typography
                                      key={`error_${i}`}
                                      color={"red"}
                                      fontSize={12}
                                      fontWeight={500}
                                      display="block"
                                      mr={1}
                                      mb={0.5}
                                      pt={2}
                                      lineHeight="18px"
                                      textAlign={"end"}
                                      alignItems={"end"}
                                    >
                                      {data?.errorList?.length > 0
                                        ? "Please fix the errors in the selected rows to proceed"
                                        : ""}
                                    </Typography>
                                  </Grid>
                                )
                              // ))
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Grid>

                  {(isClearSearch || isClearFilter) && !matches && (
                    <Box mt={{ xs: 2.5, md: 1.3 }}>
                      <SearchInfoCard
                        searchQuery={searchQuery}
                        clearSearch={() => {
                          fetchAllStockUpdateProducts(true);
                          setIsClearSearch(false);
                          setSearchQuery("");
                        }}
                        filterType={filterType}
                        isClearFilter={isClearFilter}
                        data={allStockUpdateProducts}
                      />
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <ThreeDotLoader />
            )}
          </Box>

          {!businessProductsLoader &&
            allStockUpdatePageNo != 1 &&
            inventorySummary && (
              <>
                {allStockUpdateProducts?.length == 0 ? (
                  <EmptyCard
                    msg={
                      isClearSearch
                        ? "No matching products found. Please refine your search"
                        : "No active products are available for Stock Update"
                    }
                    icon={<ContentPasteIcon sx={{ fontSize: "24px" }} />}
                    noMargin={!matches}
                  />
                ) : matches ? (
                  <Box sx={styles.tableWrapper}>
                    <TableContainer sx={styles.tableContainer} component={Box}>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow
                            sx={{
                              position: "sticky !important",
                              top: 0,
                              backgroundColor: greyColor42,
                              zIndex: 20,
                              borderRadius: "8px 8px 0 0",
                            }}
                          >
                            {ALL_STOCK_COLUMN_HEADER_NEW?.map((item, index) => {
                              const selectedRows = getSelectedInventoryRowCount(
                                allStockUpdateProducts
                              );

                              return (
                                <TableCell
                                  key={item.title}
                                  sx={{
                                    ...styles.tableCell,
                                    ...styles.colSticky,
                                  }}
                                >
                                  <Box
                                    display={
                                      index === 0 ? "flex" : "inline-block"
                                    }
                                    alignItems="center"
                                    gap={1.2}
                                  >
                                    {index === 0 && (
                                      <Checkbox
                                        checked={allStockUpdateProducts?.every(
                                          (item) => item.itemChange
                                        )}
                                        onChange={({ target }) => {
                                          selectAllRows();
                                        }}
                                        sx={styles.checkbox}
                                      />
                                    )}
                                    <Box sx={{ fontSize: 12 }}>
                                      {item?.title}{" "}
                                      {selectedRows > 0 &&
                                      item?.title === "PRODUCT NAME" ? (
                                        <span
                                          style={{
                                            fontSize: 11.5,
                                            fontWeight: 400,
                                            color: greyColor5,
                                            marginLeft: "5px",
                                          }}
                                        >
                                          {selectedRows} of{" "}
                                          {inventorySummary?.inStock} rows
                                          selected
                                        </span>
                                      ) : null}
                                    </Box>
                                  </Box>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>

                        <TableBody sx={{ backgroundColor: whiteColor }}>
                          {allStockUpdateProducts?.map((productInfo, index) => {
                            return (
                              <TableRow
                                key={`Price_Update_${index}`}
                                sx={{
                                  borderBottom: `1px solid ${borderTextBoxColor}`,
                                  verticalAlign: "top",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    ...styles.tableStickyLeftCell,
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    alignItems="flex-start"
                                    gap={1.2}
                                  >
                                    <Checkbox
                                      sx={{ ...styles.checkbox, mt: 1 }}
                                      checked={productInfo?.itemChange || false}
                                      onChange={() => {
                                        handleInputChange(
                                          {
                                            type: "itemChange",
                                            value: !productInfo?.itemChange,
                                          },
                                          index
                                        );
                                      }}
                                    />
                                    <Box display="flex" gap={1.5}>
                                      <Box width="33px">
                                        <Box
                                          component="img"
                                          src={
                                            productInfo?.productPhoto?.[0]
                                              ?.docURL
                                          }
                                          sx={styles.imgContainer}
                                        />
                                      </Box>

                                      <Box flex={1}>
                                        <Typography
                                          variant="body1"
                                          title={productInfo?.productName}
                                          sx={{
                                            ...styles.prodNameBox,
                                            ...textOneLines,
                                          }}
                                        >
                                          {productInfo?.productName}
                                        </Typography>

                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          gap={1}
                                        >
                                          <Typography
                                            fontSize={11}
                                            color={blackShade21}
                                            sx={{
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            ₹{" "}
                                            {productInfo?.productOriginalPrice}
                                          </Typography>

                                          <Typography
                                            fontSize={12}
                                            fontWeight={600}
                                            color={blueColor04}
                                          >
                                            ₹ {productInfo?.productSalePrice}
                                          </Typography>

                                          <Box sx={styles.percentageOffTag}>
                                            {productInfo?.percentageOff} %
                                          </Box>

                                          <Typography
                                            fontSize={11.5}
                                            fontWeight={400}
                                            color={greyColor40}
                                          >
                                            ONDC Enabled :{" "}
                                            <span
                                              style={{
                                                fontSize: 11,
                                                fontWeight: 600,
                                                color:
                                                  productInfo?.ondcEnabled?.toLowerCase() ===
                                                  "yes"
                                                    ? copyBtnColor
                                                    : outOfStockColor,
                                              }}
                                            >
                                              {toTitleCase(
                                                productInfo?.ondcEnabled || "No"
                                              )}
                                            </span>
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </TableCell>

                                <TableCell sx={styles.tableBodyCell(false)}>
                                  <TableCellSwitchBtn
                                    value={
                                      productInfo?.availability?.[0] == "Yes"
                                    }
                                    onValueChange={(value) => {
                                      if (value) {
                                        handleInputChange(
                                          {
                                            type: "availability",
                                            value: ["Yes"],
                                          },
                                          index
                                        );
                                      } else {
                                        handleInputChange(
                                          {
                                            type: "availability",
                                            value: ["No"],
                                          },
                                          index
                                        );
                                      }
                                    }}
                                    errorMsg={productInfo?.errors?.availability}
                                  />
                                </TableCell>

                                <TableCell sx={styles.tableBodyCell(false)}>
                                  <TabelCellInputBox
                                    value={productInfo?.availableQuantity}
                                    onValueChange={(value) => {
                                      handleInputChange(
                                        {
                                          type: "availableQuantity",
                                          value,
                                        },
                                        index
                                      );
                                    }}
                                    regexType="qty"
                                    disabled={
                                      productInfo?.availability?.[0] == "No"
                                    }
                                    errorMsg={
                                      productInfo?.errors?.availableQuantity
                                    }
                                    errorPositionRight
                                  />
                                </TableCell>

                                <TableCell sx={styles.tableBodyCell(false)}>
                                  <TabelCellInputBox
                                    value={productInfo?.alertQuantity}
                                    onValueChange={(value) => {
                                      handleInputChange(
                                        {
                                          type: "alertQuantity",
                                          value,
                                        },
                                        index
                                      );
                                    }}
                                    regexType="qty"
                                    disabled={
                                      productInfo?.availability?.[0] == "No"
                                    }
                                    errorMsg={
                                      productInfo?.errors?.alertQuantity
                                    }
                                  />
                                </TableCell>

                                <TableCell sx={styles.tableBodyCell(false)}>
                                  <TabelCellInputBox
                                    value={productInfo?.minOrderQuantity}
                                    onValueChange={(value) => {
                                      handleInputChange(
                                        {
                                          type: "minOrderQuantity",
                                          value,
                                        },
                                        index
                                      );
                                    }}
                                    regexType="qty"
                                    disabled={
                                      productInfo?.availability?.[0] == "No"
                                    }
                                    errorMsg={
                                      productInfo?.errors?.minOrderQuantity
                                    }
                                  />
                                </TableCell>

                                <TableCell sx={styles.tableBodyCell(false)}>
                                  <TabelCellInputBox
                                    value={productInfo?.maxOrderQuantity}
                                    onValueChange={(value) => {
                                      handleInputChange(
                                        {
                                          type: "maxOrderQuantity",
                                          value,
                                        },
                                        index
                                      );
                                    }}
                                    regexType="qty"
                                    disabled={
                                      productInfo?.availability?.[0] == "No"
                                    }
                                    errorMsg={
                                      productInfo?.errors?.maxOrderQuantity
                                    }
                                  />
                                </TableCell>

                                <TableCell sx={styles.tableBodyCell(false)}>
                                  <TabelCellInputBox
                                    value={productInfo?.productOriginalPrice}
                                    onValueChange={(value) => {
                                      handleInputChange(
                                        {
                                          type: "productOriginalPrice",
                                          value,
                                        },
                                        index
                                      );
                                    }}
                                    regexType="price"
                                    startIcon="₹"
                                    disabled={
                                      productInfo?.availability?.[0] == "No"
                                    }
                                    errorMsg={
                                      productInfo?.errors?.productOriginalPrice
                                    }
                                  />
                                </TableCell>

                                <TableCell sx={styles.tableBodyCell(false)}>
                                  <TabelCellInputBox
                                    value={productInfo?.priceOff}
                                    onValueChange={(value) => {
                                      handleInputChange(
                                        {
                                          type: "priceOff",
                                          value,
                                        },
                                        index
                                      );
                                    }}
                                    regexType="number"
                                    minWidth="50px"
                                    maxLength={2}
                                    disabled={
                                      productInfo?.availability?.[0] == "No"
                                    }
                                  />
                                </TableCell>

                                <TableCell sx={styles.tableBodyCell(false)}>
                                  <TabelCellInputBox
                                    value={productInfo?.productSalePrice}
                                    onValueChange={(value) => {
                                      handleInputChange(
                                        {
                                          type: "productSalePrice",
                                          value,
                                        },
                                        index
                                      );
                                    }}
                                    regexType="price"
                                    startIcon="₹"
                                    disabled={
                                      productInfo?.availability?.[0] == "No"
                                    }
                                    errorMsg={
                                      productInfo?.errors?.productSalePrice
                                    }
                                  />
                                </TableCell>

                                <TableCell
                                  sx={styles.tableBodyCell(
                                    productInfo?.errors?.gstPercentage
                                  )}
                                >
                                  <TableCellDropdownBox
                                    width="100px"
                                    value={
                                      productInfo?.gstInfo?.gstPercentage ||
                                      "Select GST %"
                                    }
                                    onValueChange={(value) => {
                                      handleInputChange(
                                        {
                                          type: "gstInfo",
                                          value: {
                                            code: productInfo?.gstInfo?.code,
                                            gstPercentage: value,
                                          },
                                        },
                                        index
                                      );
                                    }}
                                    menuList={[
                                      { value: "Select GST %" },
                                      ...gstRates,
                                    ]}
                                    valueSelector="value"
                                    borderFull
                                    disabled={
                                      productInfo?.availability?.[0] == "No"
                                    }
                                    errorMsg={
                                      productInfo?.errors?.gstPercentage
                                    }
                                  />
                                </TableCell>

                                <TableCell sx={styles.tableBodyCell(false)}>
                                  <TableCellDateInputBox
                                    value={moment(
                                      productInfo?.commerceStartDate
                                    )?.format("YYYY-MM-DD")}
                                    onValueChange={(value) => {
                                      console.log("HITTITITIT___:", value);
                                      handleInputChange(
                                        {
                                          type: "commerceStartDate",
                                          value: value,
                                        },
                                        index
                                      );
                                    }}
                                    disabled={
                                      productInfo?.availability?.[0] == "No"
                                    }
                                    errorMsg={
                                      productInfo?.errors?.commerceStartDate
                                    }
                                  />
                                </TableCell>

                                <TableCell sx={styles.tableBodyCell(false)}>
                                  <TabelCellInputBox
                                    value={productInfo?.validityDays}
                                    onValueChange={(value) => {
                                      handleInputChange(
                                        {
                                          type: "validityDays",
                                          value,
                                        },
                                        index
                                      );
                                    }}
                                    regexType="number"
                                    maxLength={4}
                                    disabled={
                                      productInfo?.availability?.[0] == "No"
                                    }
                                    errorMsg={productInfo?.errors?.validityDays}
                                  />
                                </TableCell>

                                <TableCell sx={styles.tableBodyCell(false)}>
                                  <TableCellDateInputBox
                                    value={moment(
                                      productInfo?.commerceEndDate
                                    )?.format("YYYY-MM-DD")}
                                    onValueChange={(value) => {
                                      handleInputChange(
                                        {
                                          type: "commerceEndDate",
                                          value: value,
                                        },
                                        index
                                      );
                                    }}
                                    disabled={
                                      productInfo?.availability?.[0] == "No"
                                    }
                                    errorMsg={
                                      productInfo?.errors?.commerceEndDate
                                    }
                                  />
                                </TableCell>

                                <TableCell
                                  sx={{
                                    ...styles.tableStickyLeftCell,
                                    ...styles.tableStickyRightCell,
                                  }}
                                >
                                  <Box
                                    minWidth="50px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap={1.5}
                                  >
                                    <Stack
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      {productInfo?.errors &&
                                      Object.keys(productInfo?.errors).length >
                                        0 ? (
                                        <Box sx={styles.errorCountTag}>
                                          <Box
                                            sx={{
                                              position: "absolute",
                                              right: -6,
                                              top: -6,
                                            }}
                                          >
                                            <ErrorToolTipIcon errorMsg="Please review all columns for errors" />
                                          </Box>
                                          {
                                            Object.keys(productInfo?.errors)
                                              .length
                                          }
                                        </Box>
                                      ) : (
                                        <CheckCircleIcon
                                          sx={{
                                            fontSize: 16,
                                            color: greenColor,
                                          }}
                                        />
                                      )}
                                    </Stack>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            );
                          })}

                          {allStockUpdateProducts?.length > 0 &&
                            hasMoreData &&
                            !isClearSearch && (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    ...styles.tableStickyLeftCell,
                                    bgcolor: BGColor02,
                                    borderRight: `none`,
                                    boxShadow: "none",
                                  }}
                                  colSpan={7}
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    p={1}
                                  >
                                    <Button
                                      sx={styles.loadMoreBtn}
                                      variant="contained"
                                      disableElevation
                                      onClick={() =>
                                        fetchAllStockUpdateProducts()
                                      }
                                      startIcon={
                                        allStockUpdateProductsLoader && (
                                          <CircularProgress
                                            size={16}
                                            color="inherit"
                                          />
                                        )
                                      }
                                    >
                                      {allStockUpdateProductsLoader
                                        ? "Loading..."
                                        : "Load More"}
                                    </Button>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ) : (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      p="6px 16px"
                      bgcolor={greyColor42}
                      sx={{
                        position: "sticky",
                        top: { md: "45px", xs: "290px" },
                        zIndex: 100,
                      }}
                    >
                      <Checkbox
                        sx={{
                          ml: -1,
                          ...styles.checkbox,
                          transform: "scale(0.9)",
                        }}
                        checked={allStockUpdateProducts?.every(
                          (item) => item.itemChange
                        )}
                        onChange={({ target }) => {
                          selectAllRows();
                        }}
                        size={"large"}
                      />

                      <Typography
                        fontSize={12.5}
                        fontWeight={500}
                        color={greyColor47}
                      >
                        Select All Inventory{" "}
                        {getSelectedInventoryRowCount(allStockUpdateProducts) >
                        0 ? (
                          <span
                            style={{
                              fontSize: 11.5,
                              fontWeight: 400,
                              color: greyColor5,
                              marginLeft: "5px",
                            }}
                          >
                            {getSelectedInventoryRowCount(
                              allStockUpdateProducts
                            )}{" "}
                            of {inventorySummary?.inStock} rows selected
                          </span>
                        ) : null}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        overflow: "hidden",
                      }}
                    >
                      <Box>
                        <InfiniteScroll
                          dataLength={allStockUpdateProducts?.length || 0}
                          next={() => fetchAllStockUpdateProducts()}
                          hasMore={
                            hasMoreData && !isClearSearch
                            // Math.ceil(inventorySummary?.inStock / PAGE_SIZE) >=
                            //   allStockUpdatePageNo && !isClearSearch
                          }
                          loader={
                            allStockUpdateProductsLoader ? (
                              <Box>
                                <ThreeDotLoader />
                              </Box>
                            ) : null
                          }
                        >
                          {allStockUpdateProducts?.map((data, index) => {
                            const selectedRows = getSelectedInventoryRowCount(
                              allStockUpdateProducts
                            );

                            return (
                              <AllStockCard
                                index={index}
                                productList={allStockUpdateProducts}
                                onSelectAll={() => {
                                  selectAllRows();
                                }}
                                selectedRows={selectedRows}
                                totalCount={inventorySummary?.inStock}
                                key={`AllStock_Update_${index}`}
                                img={data?.productPhoto?.[0]?.docURL || ""}
                                title={data?.productName || ""}
                                originalAmt={data?.productOriginalPrice}
                                availability={data?.availability?.[0] == "Yes"}
                                amt={data?.productSalePrice}
                                alertQty={data?.alertQuantity}
                                avlQty={data?.availableQuantity}
                                minQty={data?.minOrderQuantity}
                                maxQty={data?.maxOrderQuantity}
                                currentStock={data?.currentStock}
                                startDate={moment(
                                  data?.commerceStartDate
                                )?.format("YYYY-MM-DD")}
                                endDate={moment(data?.commerceEndDate)?.format(
                                  "YYYY-MM-DD"
                                )}
                                isChecked={data?.itemChange}
                                gstRates={gstRates}
                                gst={data?.gstInfo?.gstPercentage}
                                gstCode={data?.gstInfo?.code}
                                priceOff={data?.priceOff}
                                validityDays={data?.validityDays}
                                errors={data?.errorList}
                                onValueChange={(value) => {
                                  handleInputChange(value, index);
                                }}
                                disabled={data?.availability?.[0] == "No"}
                              />
                            );
                          })}
                        </InfiniteScroll>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            )}
        </Box>
      </DashboardLayout>

      <ModalCard
        open={errorModal.show}
        handleClose={() => {
          setErrorModal({ show: false, value: "" });
        }}
        width="29%"
      >
        <Box
          sx={{ backgroundColor: whiteColor, px: 3, py: 2.5, borderRadius: 2 }}
        >
          <Typography fontSize={16} fontWeight={600} color={blackColor}>
            Alert
          </Typography>
          <Typography fontSize={14} mt={2.5}>
            {errorMsg}
          </Typography>
          <Box display="flex" justifyContent={"flex-end"} mt={1.5} gap={2}>
            <Button
              sx={styles.addIconBtn}
              onClick={() => {
                updateInventory();
                setErrorModal({ show: false, value: "" });
              }}
            >
              Yes
            </Button>
            <Button
              sx={styles.fillBtn}
              onClick={() => {
                navigateToTab(errorModal?.value);
                setErrorModal({ show: false, value: "" });
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </ModalCard>

      <ModalCard
        open={infoModal}
        handleClose={() => {
          setInfoModal(false);
        }}
        width="60%"
      >
        <Box
          sx={{ backgroundColor: whiteColor, px: 3, py: 2.5, borderRadius: 2 }}
        >
          <Box>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={greyShade168}
              mb={2}
            >
              How to update Stock?
            </Typography>

            <Box mt={1}>
              <Typography
                fontSize={14}
                fontWeight={400}
                color={greyColor9}
                display="inline"
                lineHeight={1.5}
              >
                Use the product category filter to update inventory for specific
                category of products.
              </Typography>
            </Box>

            <Box mt={1}>
              <Typography
                fontSize={14}
                fontWeight={400}
                color={greyColor9}
                display="inline"
                lineHeight={1.5}
              >
                You can update inventory details for multiple products and click
                on{" "}
                <Typography
                  fontSize={15}
                  fontWeight={600}
                  color={greyColor9}
                  display="inline"
                  lineHeight={1.5}
                >
                  ‘UPDATE INVENTORY’
                </Typography>{" "}
                button to save the details{" "}
              </Typography>
            </Box>
          </Box>
        </Box>
      </ModalCard>

      {openQuickUpdateModal && (
        <Box>
          <QuickAllStockUpdate
            openModal={openQuickUpdateModal}
            setOpenModal={setOpenQuickUpdateModal}
            onApply={applyQuickUpdates}
            gstRates={gstRates}
            allProducts={allStockUpdateProducts}
            totalCount={inventorySummary?.inStock}
            type="all_stock"
          />
        </Box>
      )}

      <MobileCategoryFilter
        openModal={openMobileFilterModel}
        setOpenModal={setOpenMobileFilterModel}
        applyONDCFilter={applyONDCFilter}
        setIsClearFilter={setIsClearFilter}
        setFilterType={setFilterType}
        // ONDC Category
        ondcDropdownData={ondcDropdownData}
        selectedOndcCategory={selectedOndcCategory}
        setSelectedOndcCategory={setSelectedOndcCategory}
        selectedOndcSubCat={selectedOndcSubCat}
        setSelectedOndcSubCat={setSelectedOndcSubCat}
        refreshProductList={fetchAllStockUpdateProducts}
      />
    </Box>
  );
}

export default AllStockTab;

const styles = {
  container: {
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    pt: { xs: 2, md: 0 },
  },

  bodySticky: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "20px" },
    p: { xs: "0px", md: "0 " },
    mx: { xs: "15px", md: "0px" },
    mt: { xs: "15px", md: "15px" },
    position: "-webkit-sticky",
    position: "sticky",
    top: "70px",
    backgroundColor: BGColor02,
    zIndex: 5,
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  statsCard: {
    width: { xs: "100%", md: "auto" },
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    gap: { xs: "20px", md: "35px" },
    backgroundColor: whiteColor,
    padding: { xs: "10px 16px", md: "8px 16px" },
    borderRadius: { xs: "8px", md: "12px" },
    border: `1px solid ${greyColor10}`,
    m: { xs: "12px 0 12px 0", md: "0" },
  },

  addIconBtn: {
    padding: { md: "3px 16px", xs: "3px 14px" },
    fontSize: { md: "13px", xs: "12px" },
    fontWeight: 600,
    color: { xs: mobilePrimary, md: blueColor04 },
    backgroundColor: whiteColor,
    border: {
      xs: `1.5px solid ${mobilePrimary}`,
      md: `1.5px solid ${blueColor04}`,
    },
    "&:hover": {
      color: whiteColor,
      backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    },
  },
  fillBtn: {
    backgroundColor: greyColor8,
    padding: "6px 22px",
    fontSize: "13px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + greyColor8,
    "&:hover": {
      color: greyColor8,
      backgroundColor: whiteColor,
    },
  },

  addIconBtnSmall: {
    backgroundColor: "transparent",
    fontSize: "13px",
    fontWeight: 600,
    color: greyColor8,
    backgroundColor: whiteColor,
    borderRadius: "3px",
    border: "1.5px solid " + greyColor8,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },

  mobileTabs: {
    width: { xs: "90vw", md: "calc(100% - 100px)" },
    display: "flex",
    alignItems: "center",
    // borderBottom: logoImgborder,
  },

  quickUpdateIconBtn: {
    padding: { md: "3px 14px", xs: "3px 14px" },
    fontSize: { md: "11px", xs: "11px" },
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: { xs: mobilePrimary, md: blackShade22 },
    border: {
      xs: `1.5px solid ${mobilePrimary}`,
      md: `1.5px solid ${blackShade22}`,
    },
    "&:hover": {
      border: {
        xs: `1.5px solid ${mobilePrimary}`,
        md: `1.5px solid ${blackShade22}`,
      },
      color: { xs: mobilePrimary, md: blackShade22 },
      backgroundColor: whiteColor,
    },
  },

  loadMoreBtn: {
    padding: { md: "3px 14px", xs: "3px 14px" },
    fontSize: { md: "12px", xs: "12px" },
    fontWeight: 500,
    color: whiteColor,
    backgroundColor: { xs: mobilePrimary, md: blackShade22 },
    border: {
      xs: `1.5px solid ${mobilePrimary}`,
      md: `1.5px solid ${blackShade22}`,
    },
    "&:hover": {
      border: {
        xs: `1.5px solid ${mobilePrimary}`,
        md: `1.5px solid ${blackShade22}`,
      },
      color: { xs: mobilePrimary, md: blackShade22 },
      backgroundColor: whiteColor,
    },
  },

  tableWrapper: {
    width: "calc(100vw - 270px)",
    borderRadius: "6px",
    border: `1px solid ${borderTextBoxColor}`,
    // overflowX: "scroll",
    bgColor: whiteColor,
  },

  tableContainer: {
    borderRadius: "8px",
    overflow: "scroll",
    maxHeight: "calc(100vh - 330px)",

    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      // background: '#d0d3da',
      background: "rgba(0,0,0,0.1)",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.05)",
    },
  },

  tableCell: {
    color: greyColor47,
    fontSize: "12.5px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    padding: "8px 15px",
    backgroundColor: greyColor42,
    borderRight: `1px solid ${whiteColor}`,
  },

  colSticky: {
    // position: "-webkit-sticky",
    // position: isSticky ? "sticky" : "unset",
    // width: isSticky ? "100px" : "auto",
    // minWidth: isSticky ? "100px" : "auto",
    // maxWidth: isSticky ? "100px" : "auto",
    // left: "0px",
    // right: isRight ? "0px" : "unset",

    ":first-child": { position: "sticky", left: "0px" },
    // ":nth-last-child(2)": {
    //   position: "sticky",
    //   right: "78px",
    // },
    ":last-child": { position: "sticky", right: "-3px" },
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  statuSticky: (isStatus) => ({
    right: isStatus ? "100px" : "unset",
  }),

  colStickyRight: (isSticky) => ({
    position: "-webkit-sticky",
    position: isSticky ? "sticky" : "unset",
    width: isSticky ? "100px" : "auto",
    minWidth: isSticky ? "100px" : "auto",
    maxWidth: isSticky ? "100px" : "auto",
    right: "0px",
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  }),

  tableBodyCell: (isError) => ({
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: isError ? lightRedColor : whiteColor,
    padding: "8px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,

    boxShadow: isError ? `0px -1px 0 0 ${redColor} inset` : "none",
  }),

  tableStickyLeftCell: {
    minWidth: "70px !important",
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: whiteColor,
    padding: "10px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,
    position: "sticky",
    left: 0,
    zIndex: 10,
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  tableStickyRightCell: {
    position: "sticky",
    right: 0,
    boxShadow: `1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  prodNameBox: {
    minWidth: "250px",
    maxWidth: "250px",
    whiteSpace: "normal",
    fontSize: 12,
    fontWeight: 600,
    color: blackShade19,
    lineHeight: "16px",
  },

  checkbox: {
    p: 0,
    transform: "scale(1.3)",
    "&.Mui-checked": {
      color: greyColor8,
    },
  },

  percentageOffTag: {
    fontSize: 10.5,
    fontWeight: 600,
    color: yellowShade01,
    bgcolor: yellowShade02,
    p: "0px 3px",
    borderRadius: "2px",
  },

  imgContainer: {
    borderRadius: "6px",
    border: "1px solid #e5e9f0",
    backgroundColor: whiteColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "33px !important",
    height: "33px",
  },

  errorCountTag: {
    backgroundColor: lightRedColor,
    color: redColor,
    fontSize: 12,
    fontWeight: 600,
    p: "3px 18px",
    borderRadius: "10px",
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
