import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BGcolor01,
  bgColor15,
  blackColor,
  blackShade05,
  blackShade19,
  blueColor04,
  border25,
  greenColor6,
  greyColor40,
  greyColor42,
  lightGreen15,
  lightOrange02,
  mobilePrimary,
  orangeColor02,
  orangeShade18,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import PageHeader from "../components/PageHeader";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getBuyerAppLookupAPI } from "../../../service/api_calls/BusinessPreview/BusinessPreviewAPI";
import { ONDC_ECOM_DEFAULT_DATA } from "../../../utils/data/defaultFormData";
import BuyerAppForm from "../components/BuyerAppForm";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { updateONDCControlsAPI } from "../../../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";
import { getCommerceInfo } from "../../../reducers/businessPreview/businessPreviewSlice";
import { useDispatch } from "react-redux";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PageMobileHeader from "../components/PageMobileHeader";
import { useNavigate } from "react-router-dom";
import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ModalCard from "../../../components/ModalCard";

const OndcStoreLinkContent = ({ businessData, commerceInfo }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const navigate = useNavigate();

  const [buyerLinks, setBuyerLinks] = useState(null);
  const [inputLinkData, setInputLinkData] = useState([{ code: "", value: "" }]);

  const [isLoading, setIsLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [copiedIndex, setCopiedIndex] = useState(-1);

  const [buyerAppMenuList, setBuyerAppMenuList] = useState([]);

  const [openSampleLinksModal, setOpenSampleLinksModal] = useState(false);

  const dispatch = useDispatch();

  //Get Buyer App Links
  const getBuyerLinks = async (commerceDetails) => {
    let buyerLinkList = [];
    let menuList = [];

    const buyerResponse = await getBuyerAppLookupAPI();

    if (buyerResponse.details) {
      if (commerceDetails?.info?.ondc?.buyerAppLinks?.length > 0) {
        commerceDetails?.info?.ondc?.buyerAppLinks?.forEach((obj1) => {
          // Find the matching object from arr2 based on the 'code' property
          const matchedObj = buyerResponse.details.find(
            (obj2) => obj2.bapName === obj1.code
          );

          // If a matching object is found, merge its properties with obj1
          if (matchedObj) {
            buyerLinkList?.push({
              ...obj1,
              type: matchedObj?.type,
              displayName: matchedObj?.displayName,
            });
          }
        });
      }

      let buyerArr = buyerResponse.details || [];

      buyerArr?.sort((a, b) => {
        const nameA = a?.displayName?.toLowerCase();
        const nameB = b?.displayName?.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      menuList = buyerArr;
    }

    console.log("buyerLinks__", buyerLinkList, commerceDetails);

    setBuyerLinks(buyerLinkList);
    setInputLinkData(
      buyerLinkList?.length === 0 ? [{ code: "", value: "" }] : buyerLinkList
    );
    setBuyerAppMenuList(menuList);
    setShowAddForm(buyerLinkList?.length > 0);
  };

  const handleOndcInfoChange = (updatedList) => {
    setInputLinkData(
      updatedList?.length === 0 ? [{ code: "", value: "" }] : updatedList
    );
  };

  //   Copy Link
  const handleCopy = (link, index) => {
    navigator.clipboard.writeText(link);
    setCopiedIndex(index);

    setTimeout(() => {
      setCopiedIndex(-1);
    }, 1200);
  };

  const onWhatsappClick = () => {
    let webLinks = "%0A%0AWeb links";
    let appLinks = "%0A%0AApp links";

    buyerLinks?.forEach((buyerLink) => {
      if (buyerLink?.type === "link") {
        webLinks =
          webLinks +
          `%0A${buyerLink?.displayName}%0A${buyerLink?.value?.replace(
            /&/g,
            "%26"
          )}`;
      }

      if (buyerLink?.type === "app") {
        appLinks =
          appLinks +
          `%0A${buyerLink?.displayName}%0A${buyerLink?.value?.replace(
            /&/g,
            "%26"
          )}`;
      }
    });

    const message = `Dear Customer,%0A%0AExciting news! We are thrilled to announce that "${
      businessData?.name
    }" is now live on the ONDC Network. Get ready to unlock fantastic discounts and elevate your savings to new heights!
    %0A%0ATo make the most of this opportunity, simply place your order through any of the following buyer apps of your preference
    ${appLinks != "%0A%0AApp links" ? appLinks : ""}
    ${webLinks != "%0A%0AWeb links" ? webLinks : ""}
     
     %0A%0ADon't miss out on this chance to enjoy premium quality products at unbeatable prices. Thank you for choosing us.
     %0A%0AWarm regards,%0A${businessData?.name}
    `;

    window.open(`https://web.whatsapp.com/send?text=${message}`, "_blank");
  };

  // Update Buyer App Links
  const updateBuyerAppLinks = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    setIsLoading(true);

    const ecomInfo = commerceInfo?.info || null;
    const ondcInfo = ecomInfo?.ondc || null;

    console.log("buyerLinks__commerceInfo__", commerceInfo);
    console.log("buyerLinks__inputLinkData__", inputLinkData);

    const updatedLinkData = inputLinkData?.map((item) => ({
      code: item?.code,
      value: item.value,
    }));

    console.log("buyerLinks__updatedLinkData__", updatedLinkData);

    const postData = {
      shipmentDistance: ecomInfo?.shipmentDistance || "3000",
      cmsOndcEnabled: ecomInfo?.cmsOndcEnabled || "no",
      ondcEnabled: ecomInfo?.ondcEnabled || null,
      ondc: {
        packagingCost: ondcInfo?.packagingCost || 0,
        convenienceFee: ondcInfo?.convenienceFee || 0,
        ondcDomain: ondcInfo?.ondcDomain || [],
        ondcCustomerDiscountEnabled:
          ondcInfo?.ondcCustomerDiscountEnabled || "no",
        ondcBuyerFinderFeeUpperLimitPercentage:
          ondcInfo?.ondcBuyerFinderFeeUpperLimitPercentage,
        ondcBuyerFinderFeeUpperLimitIncludingMktg:
          ondcInfo?.ondcBuyerFinderFeeUpperLimitIncludingMktg,
        ondcBPPFulfillments: ondcInfo?.ondcBPPFulfillments,
        ondcShipmentMethod: ondcInfo?.ondcShipmentMethod,
        ondcSupportRegion: ondcInfo?.ondcSupportRegion,
        ondcSupportDistanceKm: ondcInfo?.ondcSupportDistanceKm,
        ondcMultiWarehouseAllowed: ondcInfo?.ondcMultiWarehouseAllowed,
        ondcMinOrder: ondcInfo?.ondcMinOrder,
        brandMessaging: ondcInfo?.brandMessaging,
        notes: ondcInfo?.notes || "",
        cancelTerms: ondcInfo?.cancelTerms,
        autoApproval: ondcInfo?.autoApproval,
        schedule: ondcInfo?.schedule,
        openDays: ondcInfo?.openDays,
        operatingHours: ondcInfo?.operatingHours,
        stdCode: ondcInfo?.stdCode,
        buyerAppLinks: updatedLinkData || [],
      },
    };

    console.log("buyerLinks__postData__", postData);

    const responce = await updateONDCControlsAPI(postData, businessId);

    if (responce) {
      dispatch(getCommerceInfo(businessId)).then((res) => {
        const commerceData = res?.payload || null;
        console.log("buyerLinks__getCommerceInfo__", commerceData);
        getBuyerLinks(commerceData);
        setIsLoading(false);
      });
      setErrorMsg("");
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    const isBuyerLinkError = inputLinkData?.some(
      (item) =>
        (item?.code === "" && item?.value !== "") ||
        (item?.code !== "" && item?.value === "")
    );

    if (isBuyerLinkError) {
      setErrorMsg("Enter the label and value");
      return;
    } else {
      updateBuyerAppLinks();
    }
  };

  const openLink = (link, target = "_blank") => {
    window.open(link, target);
  };

  useEffect(() => {
    if (commerceInfo && !buyerLinks) {
      getBuyerLinks(commerceInfo);
    }

    return () => {};
  }, [commerceInfo]);

  return (
    <>
      <Box pt={{ xs: 1.5, md: 0 }} pb={3}>
        {matches ? (
          <PageHeader
            title="ONDC Checkout Links"
            businessData={businessData}
            hideUrl
          />
        ) : (
          <PageMobileHeader title="ONDC Checkout Links" tabValue="ondc_links" />
        )}

        <Box mt={{ xs: 1, md: 2.5 }}>
          {commerceInfo?.info?.ondcEnabled?.toLowerCase() == "yes" ? (
            <Grid container spacing={{ xs: 2, md: 4 }}>
              <Grid item xs={12} md={6}>
                <Box sx={styles.card}>
                  <Box sx={{ ...styles.cardHeader2 }}>
                    <Typography
                      fontSize={{ xs: 14, md: 15 }}
                      fontWeight={600}
                      lineHeight="18px"
                      color={blackColor}
                    >
                      Share to Customers
                    </Typography>

                    {commerceInfo?.info?.ondcEnabled?.toLowerCase() == "yes" &&
                      buyerLinks?.length > 0 && (
                        <Box
                          component="img"
                          src="https://storage.googleapis.com/bodefaults/businessweb/whatsapp.svg"
                          sx={styles.whatsappIconBtn}
                          onClick={() => {
                            onWhatsappClick(
                              `https://shop.costbo.com/${businessData?.costboWebURL}`
                            );
                          }}
                        />
                      )}
                  </Box>

                  <Box p="12px 15px">
                    {buyerLinks ? (
                      <>
                        {commerceInfo?.info?.ondcEnabled?.toLowerCase() ==
                          "yes" && buyerLinks?.length > 0 ? (
                          <>
                            {buyerLinks?.length > 0 && (
                              <Box>
                                {buyerLinks?.map((item, index) => (
                                  <LinkInfoCard
                                    key={`${index}_${item?.id}`}
                                    index={index}
                                    label={item?.displayName}
                                    link={item?.value}
                                    copiedIndex={copiedIndex}
                                    onCopy={handleCopy}
                                    openLink={openLink}
                                  />
                                ))}
                              </Box>
                            )}
                          </>
                        ) : (
                          <NoBuyerLinkCard />
                        )}
                      </>
                    ) : (
                      <ThreeDotLoader />
                    )}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box sx={styles.card}>
                  <Box sx={styles.cardHeader2}>
                    <Typography
                      fontSize={{ xs: 14, md: 15 }}
                      fontWeight={600}
                      lineHeight="18px"
                      color={blackColor}
                    >
                      Add Checkout Links
                    </Typography>

                    <Button
                      variant="text"
                      sx={styles.sampleBtn}
                      onClick={() => {
                        setOpenSampleLinksModal(true);
                      }}
                    >
                      Samples Here
                    </Button>
                  </Box>

                  {buyerLinks ? (
                    <Box
                      sx={{
                        p: "12px 15px",
                      }}
                    >
                      {showAddForm ? (
                        <>
                          {inputLinkData?.length > 0 &&
                            buyerAppMenuList?.length > 0 && (
                              <BuyerAppForm
                                buyerLinks={inputLinkData}
                                menuList={buyerAppMenuList}
                                handleOndcInfoChange={handleOndcInfoChange}
                                error={errorMsg}
                                onSubmit={handleSubmit}
                                isLoading={isLoading}
                              />
                            )}
                        </>
                      ) : (
                        <Stack
                          height="220px"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box
                            component="img"
                            src="https://storage.googleapis.com/bodefaults/businessdashboardweb/buyer-app.svg"
                            sx={{ width: "70px", height: "70px", mb: 1 }}
                          />

                          <Typography
                            fontSize={{ xs: 12.5, md: 13.5 }}
                            fontWeight={500}
                            color={blackShade19}
                            textAlign="center"
                            mb={1.5}
                          >
                            Add your ONDC Checkout Links from multiple ONDC
                            buyer {matches ? <br /> : null}
                            apps.
                            <br />
                            <span style={{ fontWeight: "700" }}>
                              Ex: Paytm, MyStore, Maginpin, OLA
                            </span>
                          </Typography>

                          <Button
                            sx={styles.addIconBtn}
                            variant="contained"
                            disableElevation
                            onClick={() => {
                              setShowAddForm(true);
                            }}
                            startIcon={
                              <AddRoundedIcon
                                sx={{ fontSize: { xs: "18px", md: "20px" } }}
                              />
                            }
                          >
                            Add Url
                          </Button>
                        </Stack>
                      )}
                    </Box>
                  ) : (
                    <ThreeDotLoader />
                  )}
                </Box>
              </Grid>
            </Grid>
          ) : (
            !isLoading && (
              <InfoCard
                icon="https://storage.googleapis.com/bodefaults/businessdashboardweb/icon-ondc.svg"
                message="ONDC controls are not enabled for the business. Enable ONDC controls at the business level to add and share your ONDC buyer apps store links"
                onClick={() => {
                  const businessUrl = sessionStorage.getItem("costboWEBURL");
                  navigate(`/${businessUrl}/ONDCControls`);
                }}
              />
            )
          )}
        </Box>
      </Box>

      <SampleCheckoutLinks
        open={openSampleLinksModal}
        setOpen={setOpenSampleLinksModal}
      />
    </>
  );
};

export default OndcStoreLinkContent;

const LinkInfoCard = ({
  label,
  link,
  index,
  copiedIndex,
  onCopy,
  openLink,
}) => {
  return (
    <Box mt={index > 0 ? 1.5 : 0}>
      <Box>
        <Typography fontSize={13} fontWeight={600} color={blackShade19}>
          {label}
        </Typography>

        <Box mt={0.2}>
          <Box
            component="button"
            display="flex"
            alignItems="center"
            gap={1}
            cursor="pointer"
          >
            <Typography
              fontSize={12}
              fontWeight={500}
              color={greenColor6}
              sx={{ textDecoration: "underline", textAlign:'left', ml: -0.7 }}
              onClick={() => {
                openLink(link);
              }}
            >
              {link}
            </Typography>

            {copiedIndex === index ? (
              <Box display="flex" alignItems="center" gap={0.5}>
                <CheckCircleOutlineIcon
                  sx={{ fontSize: "18px", color: greenColor6 }}
                />

                <Typography fontSize={11} fontWeight={500} color={greenColor6}>
                  Copied
                </Typography>
              </Box>
            ) : (
              <ContentCopyIcon
                sx={{ fontSize: "16px", color: bgColor15 }}
                onClick={() => {
                  onCopy(link, index);
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const InfoCard = ({ icon, message, onClick }) => {
  return (
    <Box sx={styles.infoCard}>
      <Box
        component="img"
        src={icon}
        alt="list empty"
        sx={{ width: "60px", height: "auto", objectFit: "contain" }}
      />

      <Typography
        width={{ xs: "auto", md: "800px" }}
        component="h6"
        fontSize={{ xs: 14, md: 14 }}
        color={blackColor}
        fontWeight={500}
        lineHeight="27px"
        mt="12px !important"
        textAlign="center"
      >
        {message}
      </Typography>

      <Button
        variant="contained"
        disableElevation
        sx={styles.infoCardActionBtn}
        onClick={onClick}
      >
        CLICK HERE
      </Button>
    </Box>
  );
};

const NoBuyerLinkCard = () => {
  return (
    <Box
      sx={{
        height: { xs: "195px", md: "220px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Box
        component="img"
        src="https://storage.googleapis.com/bodefaults/businessdashboardweb/buyer-app.svg"
        sx={{ width: "70px", height: "70px", mb: 1.5 }}
      />

      <Typography
        fontSize={{ xs: 12.5, md: 13.5 }}
        fontWeight={600}
        color={blackShade19}
        textAlign="center"
        px={{ xs: 0, md: 6 }}
      >
        Add your ONDC Checkout Links from ONDC buyer apps and you will be able
        to Share it with customers
      </Typography>

      <Box
        sx={styles.arrowCircle}
        display="flex"
        alignItems="center"
        flexDirection={{ xs: "column", md: "row" }}
        gap={3}
      >
        <DoubleArrowRoundedIcon
          sx={{
            fontSize: { xs: "20px", md: "24px" },
            rotate: { xs: "90deg", md: "0deg" },
            color: orangeShade18,
          }}
        />
      </Box>
    </Box>
  );
};

const SampleCheckoutLinks = ({ open, setOpen }) => {
  const SampleImg = ({ title, imgUrl }) => (
    <Box>
      <Typography
        fontSize={{ xs: 16, md: 18 }}
        fontWeight={700}
        color={blackShade19}
      >
        {title}
      </Typography>

      <Box
        component="img"
        src={imgUrl}
        sx={{ width: "100%", objectFit: "contain" }}
      />
    </Box>
  );
  return (
    <ModalCard
      open={open}
      handleClose={() => {
        setOpen(false);
      }}
      width="70%"
    >
      <Box sx={styles.modalBox}>
        <Box
          sx={{
            ...styles.cardHeader,
            fontSize: { xs: 16, md: 17 },
            color: blackShade19,
            bgcolor: BGcolor01,
            textAlign: "center",
            p: "15px",
          }}
        >
          Sample Checkout Links
        </Box>

        <Box sx={styles.modalBody} p="15px">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <SampleImg
                title="Mystore"
                imgUrl="https://storage.googleapis.com/bodefaults/businessdashboardweb/mystore.png"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SampleImg
                title="Paytm"
                imgUrl="https://storage.googleapis.com/bodefaults/businessdashboardweb/paytm.png"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ModalCard>
  );
};

const styles = {
  card: {
    bgcolor: whiteColor,
    borderRadius: "10px",
    border: `1px solid ${greyColor42}`,
    // overflow: "hidden",
  },

  cardHeader: {
    fontSize: { xs: 14, md: 15 },
    fontWeight: 600,
    lineHeight: "18px",
    color: blackColor,
    bgcolor: greyColor42,
    borderRadius: "9px 9px 0 0",
    p: { xs: "8px 15px", md: "10px 15px" },
    textAlign: { xs: "left", md: "center" },
  },

  cardHeader2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    bgcolor: greyColor42,
    borderRadius: "9px 9px 0 0",
    p: { xs: "8px 15px", md: "10px 15px" },
    position: "relative",
  },

  infoCard: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    bgcolor: whiteColor,
    border: border25,
    borderRadius: "10px",
    p: "15px",
    mt: { xs: 2, md: 5 },
  },

  infoCardActionBtn: {
    padding: "4px 14px",
    fontSize: "12px",
    fontWeight: 400,
    color: whiteColor,
    backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    border: {
      xs: `1.2px solid ${mobilePrimary}`,
      md: `1.2px solid ${blueColor04}`,
    },
    mt: 2,
    "&:hover": {
      color: { xs: mobilePrimary, md: blueColor04 },
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },

  whatsappIconBtn: {
    width: "28px",
    objectFit: "contain",
    cursor: "pointer",
    position: "absolute",
    top: 4,
    right: 15,
  },

  addIconBtn: {
    padding: "4px 14px",
    fontSize: "12px",
    fontWeight: 600,
    color: { xs: blueColor04, md: blueColor04 },
    backgroundColor: whiteColor,
    border: {
      xs: `1px solid ${blueColor04}`,
      md: `1px solid ${blueColor04}`,
    },
    "&:hover": {
      color: { xs: blueColor04, md: whiteColor },
      backgroundColor: { xs: whiteColor, md: blueColor04 },
    },
  },

  arrowCircle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: { xs: "40px", md: "44px" },
    height: { xs: "40px", md: "44px" },
    borderRadius: "25px",
    bgcolor: lightOrange02,
    position: "absolute",
    right: { xs: "43%", md: -32 },
    top: { xs: "90%", md: "40%" },
  },

  sampleBtn: {
    fontSize: 13,
    fontWeight: 600,
    color: { xs: mobilePrimary, md: blueColor04 },
    p: 0,
    textTransform: "none",
    textDecoration: "underline",
  },

  modalBox: {
    padding: 0,
    borderRadius: "10px",
    border: "1px solid #e5e9f0",
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    background: whiteColor,
  },

  modalBody: {
    maxHeight: "83vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      // background: '#d0d3da',
      background: "rgba(0,0,0,0.1)",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.05)",
    },
  },
};
