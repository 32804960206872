// import { Box, Typography } from '@mui/material'
// import React from 'react'
// import { bgColor15, greyColor6 } from '../../configs/styles/muiThemes';

// function CouponLegend({dotColor, label}) {
    
//   return (
//     <Box display="flex" alignItems="center" gap="5px">
//       <Box
//         sx={{ ...styles.legendDot, backgroundColor: dotColor }}
//       />
//       <Typography color={greyColor6} fontSize="13px" fontWeight="400">
//         {label}
//       </Typography>
//     </Box>
//   );
// }

// export default CouponLegend
// const styles = {
//   legendDot: {
//     width: "8px",
//     height: "8px",
//     backgroundColor: bgColor15,
//     borderRadius: "4px",
//     gap: "2px",
//   },
// };

import { Box, Typography } from "@mui/material";
import React from "react";
import {
  bgColor15,
  blackShade159,
  greyColor6,
} from "../../configs/styles/muiThemes";

function CouponLegend({ dotColor, label, isActive, onSelect }) {
  return (
    <Box sx={styles.container(isActive)} onClick={onSelect}>
      <Box sx={{ ...styles.legendDot, backgroundColor: dotColor }} />
      <Typography color={greyColor6} fontSize="13px" fontWeight="400">
        {label}
      </Typography>
    </Box>
  );
}

export default CouponLegend;

const styles = {
  legendDot: {
    width: "8px",
    height: "8px",
    backgroundColor: bgColor15,
    borderRadius: "4px",
    gap: "2px",
  },

  container: (isActive) => ({
    display: "flex",
    width:{xs:"20vw",md:"auto"},
    // flexDirection:{xs:'column',md:'row'},
    alignItems: "center",
    gap: "10px",
    position: "relative",
    cursor:'pointer',
    "&::before": {
      content: "' '",
      width: "30px",
      height: "2px",
      backgroundColor: isActive ? blackShade159 : "transparent",
      borderRadius: "3px",
      position: "absolute",
      bottom:{md: -3,xs:8 },
      // right: {xs:65,md:0},
      left:10,
    },
  }),
};