import {
  Box,
  Button,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackShade05,
  blueColor04,
  border25,
  brownShade01,
  brownShade02,
  greyColor059,
  greyColor7,
  lightBlueColor03,
  mobilePrimary,
  orangeShade06,
  orangeShade07,
  purpleShade01,
  purpleShade02,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import AddIcon from "@mui/icons-material/Add";
import MultiBranchCard from "./MultiBranchCard";
import { scrollBox } from "../../../utils/styles";
import { clearReduxState } from "../../../service/reduxStateResetHelper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getCommerceDashboardInfoAPI,
  getProductsByBusinessAPI,
} from "../../../service/api_calls/BusinessPreview/BusinessPreviewAPI";
import { getDashboardOrderSummaryApi } from "../../../service/api_calls/BusinessDashboard/BusinessDashboardApi";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: whiteColor,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    border: "1px solid " + greyColor7,
    fontSize: 11,
  },
}));

function MultiBranchSection({ branchDetails, businessData, onAddNewBranch }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [orderSummaryList, setOrderSummaryList] = useState([]);

  const getColor = (value) => {
    if (value % 3 === 0) {
      return { color: orangeShade06, bgColor: orangeShade07 };
    }
    if (value % 3 === 1) {
      return { color: purpleShade01, bgColor: purpleShade02 };
    }
    if (value % 3 === 2) {
      return { color: brownShade01, bgColor: brownShade02 };
    }
  };

  const handleNavigation = (route, businessId) => {
    clearReduxState(dispatch);

    sessionStorage.setItem("selectedBusinessId", businessId);
    navigate(route, { replace: true });
    window.location.reload();
  };

  const onBusinessSelected = async (data) => {

    // Checking the business status
    if (data?.status === "active" && data?.costBoWebUrl) {
      clearReduxState(dispatch);
      sessionStorage.setItem("costboWEBURL", data?.costBoWebUrl);
      
      handleNavigation(`/${data?.costBoWebUrl}/dashboard`, data?.branchId);
      return;
    }
  };

  const getOrderSummaryData = async (branchList) => {
    const resultData = [];

    for (let i = 0; i < branchList.length; i++) {
      const branchInfo = branchList[i];

      const res = await getDashboardOrderSummaryApi(branchInfo?.branchId);

      resultData.push({
        branchId: branchInfo?.branchId,
        orderInfo: res?.details || null,
      });
    }

    setOrderSummaryList(resultData);

    console.log("getOrderSummaryData__resultData:", resultData);
  };

  useEffect(() => {
    if (orderSummaryList?.length === 0) {
      getOrderSummaryData(businessData?.branchInfo);
    }

    return () => {};
  }, []);

  return (
    <Box mt={{ xs: 1.2, md: 1.4 }} mb={2} sx={styles.card}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={1}
      >
        <Stack gap={0.5}>
          <Typography
            component="p"
            fontSize={{ xs: 14, md: 14.5 }}
            color={blackShade05}
            fontWeight={600}
            lineHeight="20px"
          >
            Multi Branch
          </Typography>
          <Typography
            component="p"
            fontSize={{ xs: 11, md: 11 }}
            color={greyColor059}
            fontWeight={400}
            lineHeight="14px"
          >
            {businessData?.branchCount || "X"} Branches Available
          </Typography>
        </Stack>

        {businessData?.branchCount >= 5 ? (
          <LightTooltip
            title={
              <span style={{ fontSize: "11px" }}>
                Maximum 5 branches are allowed
              </span>
            }
            placement="top"
          >
            <Box>
              <Button
                sx={styles.addIconBtn}
                variant="contained"
                disableElevation
                onClick={() => {}}
                startIcon={<AddIcon />}
                disabled
              >
                {matches ? "Add Branch" : "Branch"}
              </Button>
            </Box>
          </LightTooltip>
        ) : (
          <Button
            sx={styles.addIconBtn}
            variant="contained"
            disableElevation
            onClick={onAddNewBranch}
            startIcon={<AddIcon />}
            disabled={businessData?.branchCount >= 5}
          >
            {matches ? "Add Branch" : "Branch"}
          </Button>
        )}
      </Box>

      {branchDetails ? (
        <Box
          sx={{
            display: "-webkit-box",
            gap: 1.4,
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
              borderRadius: "50px !important",
            },
            pb: 1,
            "&::-webkit-scrollbar-thumb": {
              // background: '#d0d3da',
              background: "rgba(0,0,0,0.1)",
            },
            "&::-webkit-scrollbar-track": {
              background: "rgba(0,0,0,0.05)",
            },
          }}
        >
          {branchDetails?.map((branch, index) => {
            const orderSummary = orderSummaryList?.find(
              (item) => item?.branchId === branch.branchId
            );

            return (
              <MultiBranchCard
                status={branch?.status}
                image={branch?.logo}
                ondcEnabled={branch?.ondcEnabled}
                name={branch?.businessName}
                city={branch?.city}
                key={branch?.businessName + index}
                color={getColor(index)}
                onClick={() => onBusinessSelected(branch)}
                orderInfo={orderSummary?.orderInfo}
                isLoading={orderSummaryList?.length === 0}
                orderSummary={orderSummary}
              />
            );
          })}
        </Box>
      ) : (
        <Box display="flex" alignItems="center" gap={2}>
          <CardSkeletonLoader />
          <CardSkeletonLoader />
          <CardSkeletonLoader />
        </Box>
      )}
    </Box>
  );
}

export default MultiBranchSection;

const CardSkeletonLoader = () => (
  <Box
    variant="rectangular"
    sx={{
      width: { md: "30%", xs: "90%" },
      height: "116px",
      borderRadius: "10px",
      mb: 2,
      border: border25,
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: 1.8,
        borderBottom: border25,
        p: "10px",
      }}
    >
      <Skeleton
        variant="rectangular"
        width="45px"
        height="45px"
        sx={{ borderRadius: "10px" }}
      />

      <Box>
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "130px" }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "80px" }} />
      </Box>
    </Box>

    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: 1,
        }}
      >
        <Skeleton
          variant="rectangular"
          width="26px"
          height="26px"
          sx={{ borderRadius: "10px" }}
        />

        <Box>
          <Skeleton variant="text" sx={{ fontSize: "0.7rem", width: "70px" }} />
          <Skeleton variant="text" sx={{ fontSize: "0.8rem", width: "30px" }} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: 1,
        }}
      >
        <Skeleton
          variant="rectangular"
          width="26px"
          height="26px"
          sx={{ borderRadius: "10px" }}
        />

        <Box>
          <Skeleton variant="text" sx={{ fontSize: "0.7rem", width: "50px" }} />
          <Skeleton variant="text" sx={{ fontSize: "0.8rem", width: "30px" }} />
        </Box>
      </Box>
    </Box>
  </Box>
);

const styles = {
  card: {
    backgroundColor: whiteColor,
    border: border25,
    p: "12px 12px 0",
    borderRadius: "12px",
    width: { md: "calc(100vw - 280px)", xs: "100%" },
  },
  
  addIconBtn: {
    padding: {xs:'3px 8px', md:"5px 16px"},
    width: { md: "auto", xs: "auto" },
    fontSize: "12.5px",
    fontWeight: 600,
    backgroundColor: { md: blueColor04, xs: mobilePrimary },
    color: whiteColor,
    border: {
      md: "1px solid " + blueColor04,
      xs: "1px solid " + mobilePrimary,
    },
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: whiteColor,
      color: { md: blueColor04, xs: mobilePrimary },
    },

    "&.Mui-disabled": {
      border: "none",
    },
  },
};
