import React, { useEffect, useState } from "react";
import Banner from "../../BusinessPreview/Banner";
import BusinessDetailsPreviewContent from "../../BusinessPreview/BusinessDetailsPreviewContent";
import { Box } from "@mui/material";
import { blackShade159, whiteColor } from "../../../configs/styles/muiThemes";
import BusinessInfoSection from "./BusinessInfoSection";

function BusinessDetailPopupContent({ businessData }) {
  const [businessInfo, setBusinessInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const getBusinessDetails = async () => {
    setLoading(true);

    if (businessData) {
      const businessAddress = businessData?.businessArea?.reduce(
        (preValue, currentValue) => {
          return {
            ...preValue,
            [currentValue?.code?.toLowerCase()?.split(" ")?.join("_") ===
            "addressline_one"
              ? "address"
              : currentValue?.code?.toLowerCase()?.split(" ")?.join("_")]:
              currentValue?.value,
          };
        },
        {}
      );

      console.log("POPUP_BUSINESS_address:", businessAddress);

      const ownerDetails = {
        phone:
          businessData?.contactNos?.[0]?.number?.replace("+91", "+91 ") || "",
        alternatePhone1:
          businessData?.contactNos?.[1]?.number?.replace("+91", "+91 ") || "",
        alternatePhone2:
          businessData?.contactNos?.[2]?.number?.replace("+91", "+91 ") || "",
        email: businessData?.businessMailingAddress?.split(",")?.[0] || "",
        alternateEmail:
          businessData?.businessMailingAddress?.split(",")?.[1] || "",
      };

      console.log("POPUP_BUSINESS_owner:", ownerDetails);

      businessData = {
        ...businessData,
        image: businessData?.logoURL,
        banners: businessData?.documentsURL,
        mainProducts: businessData?.mainProducts?.map(
          (item) => item?.productName
        ),
        businessAddress,
        ownerDetails,
      };

      setBusinessInfo(businessData);
      setLoading(false);

      console.log("POPUP_BUSINESS_getbusinessDatas:", businessData);
    }

    setLoading(false);
  };

  useEffect(() => {
    getBusinessDetails();
    return () => {};
  }, [businessData]);

  return (
    <Box sx={styles.card}>
      <Banner
        businessDetails={businessInfo}
        storeInfoDetails={businessInfo}
        carouselWidth="100%"
      />

      <BusinessInfoSection
        businessDetails={businessInfo}
        storeInfoDetails={null}
        addressDetails={businessInfo?.businessAddress}
        gstDetails={businessInfo}
        ownerDetails={businessInfo?.ownerDetails}
      />
    </Box>
  );
}

export default BusinessDetailPopupContent;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "12px",
    padding: "20px",

    maxHeight: "95vh",
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
      // height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blackShade159,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },
};
