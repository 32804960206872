import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BGColor02 } from "../configs/styles/muiThemes";
import { removeSessionInfo } from "../utils/authHelpers";
import DashboardHeader from "./Dashboard/DashboardHeader";
import DashboardMenu from "./Dashboard/DashboardMenu";
import BankDetailsForm from "./Dashboard/Form/BankDetailsForm";
import BusinessTimingForm from "./Dashboard/Form/BusinessTimingForm";
import DashboardDetailsForm from "./Dashboard/Form/DashboardDetailsForm";
import DeliveryMethodForm from "./Dashboard/Form/DeliveryMethodForm";
import DiscountDetailsForm from "./Dashboard/Form/DiscountDetailsForm";
import OtherDetails from "./Dashboard/Form/OtherDetails";
import PickAddressForm from "./Dashboard/Form/PickAddressForm";
import { DISABLE_ALL_MENU } from "../utils/data";
import { useSelector } from "react-redux";

function Dashboard() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [currentPage, setCurrentPage] = useState("store-detail");
  const contentRef = useRef(null);
  const navigate = useNavigate();

  const { myBusinessSummary } = useSelector((state) => state.businessOnboard);

  const gotoNext = () => {
    if (currentPage === "store-detail") setCurrentPage("pick-address");
    if (currentPage === "pick-address") setCurrentPage("business-timings");
    if (currentPage === "business-timings") setCurrentPage("delivery-methods");
    if (currentPage === "delivery-methods") setCurrentPage("discount-details");
    if (currentPage === "discount-details") setCurrentPage("bank-detail");
    if (currentPage === "bank-detail") setCurrentPage("other-detail");
    if (currentPage === "other-detail") setCurrentPage("other-detail");

    contentRef.current.scrollIntoView();
  };

  const goBack = () => {
    if (currentPage === "pick-address") setCurrentPage("store-detail");
    if (currentPage === "business-timings") setCurrentPage("pick-address");
    if (currentPage === "delivery-methods") setCurrentPage("business-timings");
    if (currentPage === "discount-details") setCurrentPage("delivery-methods");
    if (currentPage === "bank-detail") setCurrentPage("discount-details");
    if (currentPage === "other-detail") setCurrentPage("bank-detail");

    contentRef.current.scrollIntoView();
  };

  return (
    <Box sx={styles.container}>
      {/* {matches ? (
        <Box sx={styles.menuStickyLeft}>
          <DashboardMenu
            disableMenu={DISABLE_ALL_MENU?.filter((item) => item != "store")}
          />
        </Box>
      ) : null} */}

      <Box flex={1}>
        <Box sx={styles.stickyTop}>
          <DashboardHeader showLogo activeMenu={"store"} headerTitle='New Business Registration' hideAction />
        </Box>

        <Box ref={contentRef} sx={styles.content}>
          <Box flex={1}>
            {/* <DashboardProgressCard /> */}

            {currentPage === "store-detail" ? (
              <DashboardDetailsForm gotoNext={gotoNext} goBack={goBack} />
            ) : null}

            {currentPage === "pick-address" ? (
              <PickAddressForm gotoNext={gotoNext} goBack={goBack} />
            ) : null}

            {currentPage === "business-timings" ? (
              <BusinessTimingForm gotoNext={gotoNext} goBack={goBack} />
            ) : null}

            {currentPage === "delivery-methods" ? (
              <DeliveryMethodForm gotoNext={gotoNext} goBack={goBack} />
            ) : null}

            {currentPage === "discount-details" ? (
              <DiscountDetailsForm gotoNext={gotoNext} goBack={goBack} />
            ) : null}

            {currentPage === "bank-detail" ? (
              <BankDetailsForm gotoNext={gotoNext} goBack={goBack} />
            ) : null}

            {currentPage === "other-detail" ? (
              <OtherDetails gotoNext={gotoNext} goBack={goBack} />
            ) : null}
          </Box>

          {/* <Box>
            <InfoCard title="GST Detail" data={GST_INFO} />
            <InfoCard title="Address Detail" data={ADDRESS_INFO} />
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}

export default Dashboard;

const styles = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "0px", md: "25px" },
    p: { xs: "0px", md: "0px 25px 10px" },
    mx: { xs: "15px", md: "0px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },
};
