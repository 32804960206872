import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  IconButton,
  Chip,
  Snackbar,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import MuiAlert from "@mui/material/Alert";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import moment from "moment";
import {
  clearSearchProducts,
  getActiveBusinessInfo,
  getCommerceInfo,
  getProductCategories,
  getProductsByBusiness,
  searchBusinessProducts,
} from "../../reducers/businessPreview/businessPreviewSlice";
import { updateInventoryData } from "../../service/api_calls/inventory/InventoryApi";
import {
  BGColor02,
  blackColor,
  blackShade05,
  blueColor04,
  greyColor10,
  greyColor5,
  greyColor6,
  greyColor8,
  greyColor9,
  greyShade168,
  greyShade65,
  logoImgborder,
  mobilePrimary,
  tableHeaderColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import {
  ALL_STOCK_COLUMN_HEADING,
  INVENTORY_VIDEO_DATA,
} from "../../utils/data";
import SelectWithLabel from "../../components/Input/SelectWithLabel";
import ModalCard from "../../components/ModalCard";
import AllStockRowCard from "./AllStockRowCard";
import EmptyCard from "../../components/Cards/EmptyCard";
import { getGstRates } from "../../reducers/catalog/CatalogSlice";
import { getStatsCountInfoData } from "../../reducers/businessDashboard/BusinessDashboardSlice";
import HowToLinkCard from "../../components/Cards/HowToLinkCard";
import VideoModal from "../../components/VideoModal";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import { stickyHeader } from "../../utils/styles";
import ThreeDotLoader from "../../components/ThreeDotLoader";

function PausedInventory() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState(3);
  const [productsList, setProductsList] = useState(null);
  const [pausedProductsList, setPausedProductsList] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [isClearSearch, setIsClearSearch] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedSubCat, setSelectedSubCat] = useState("All");

  const [openSnack, setOpenSnack] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [updateLoader, setUpdateLoader] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, value: "" });
  const [infoModal, setInfoModal] = useState(false);

  const [openVideoModal, setOpenVideoModal] = useState(false);

  const {
    activeBusinessInfo,
    businessProducts,
    searchProducts,
    productCategory,
    commerceInfo,
    businessProductsLoader,
    searchProductsLoader,
    productCategoryLoader,
  } = useSelector((state) => state.businessPreview);

  const { statsCountInfo, statsCountInfoLoader } = useSelector(
    (state) => state.businessDashboard
  );

  const { gstRates } = useSelector((state) => state.catalog);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      dispatch(getProductsByBusiness(businessId));
      dispatch(getProductCategories(businessId));
      if (!commerceInfo) {
        dispatch(getCommerceInfo(businessId));
      }
      dispatch(getGstRates());

      // Getting Product and Sales Count
      if (!statsCountInfo) {
        dispatch(getStatsCountInfoData(businessId));
      }
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (productCategory?.details) {
      formatCategory(productCategory?.details, setCategoryList);
    }
  }, [productCategory]);

  useEffect(() => {
    if (businessProducts?.productList) {
      const pausedProducts = getPausedProducts();
      setProductsList(pausedProducts);
      setPausedProductsList(pausedProducts);
    }
  }, [businessProducts]);

  useEffect(() => {
    if (searchProducts?.details) {
      setIsClearSearch(true);
      // Get only availability -> No products from search
      const filteredSearchData = searchProducts?.details
        // ?.filter((item) => item?.availability?.[0] == "No")
        ?.map((item) => {
          return {
            ...item,
            errors: [],
            itemChange: false,
            currentStock: item.availableQuantity,
          };
        });

      setProductsList(filteredSearchData);
    }
  }, [searchProducts]);

  // Prepare list data for filter dropdown
  const formatCategory = (category, setValue) => {
    const catList = category.map((item) => {
      return { ...item, label: item?.value };
    });

    setValue([{ label: "All" }, ...catList]);
  };

  // Prepare list data for OutOfStock products
  const getPausedProducts = () => {
    const products = businessProducts?.productList
      ?.filter((item) => item?.availability?.[0] == "No")
      .map((item) => {
        return {
          ...item,
          errors: [],
          itemChange: false,
          currentStock: item.availableQuantity,
        };
      });
    return products;
  };

  const clearSearch = () => {
    dispatch(clearSearchProducts());
    setProductsList(getPausedProducts());
    setSearchQuery("");
    setIsClearSearch(false);
  };

  const handleChange = (event, newValue) => {
    const filteredProducts = productsList?.filter((item) => item?.itemChange);
    if (filteredProducts?.length > 0) {
      setErrorMsg(
        `${tabValue == 2 ? "Pricing" : "Inventory"} has been modified for ${
          filteredProducts?.length
        } products. Do you want to save the changes before proceeding ?`
      );
      setErrorModal({ show: true, value: newValue });
      return;
    }
    if (newValue === 1) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/lowStock`);
    }
    if (newValue === 0) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/outOfStock`);
    }
    if (newValue == 2) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/priceUpdate`);
    }
    setSelectedCategory("All");
    setSelectedSubCat("All");
  };

  //search for products
  const handleSearch = () => {
    if (!searchQuery) return;
    const businessId = sessionStorage.getItem("selectedBusinessId");
    setSelectedCategory("All");
    setSelectedSubCat("All");

    dispatch(
      searchBusinessProducts({ query: searchQuery, businessId: businessId })
    );
  };

  // Update inventory
  const updateInventory = () => {
    setUpdateLoader(true);
    let error = false;

    const filteredList = productsList?.filter((item) => item?.itemChange);

    filteredList.forEach((item, index) => {
      let errors = [];
      console.log("ProductUpdate itteration", index, item);
      if (!item?.availableQuantity || Number(item?.availableQuantity) == 0) {
        errors.push("Quantity columns cannot be null or ‘0’.");
        error = true;
      }
      if (Number(item?.availableQuantity) < Number(item?.maxOrderQuantity)) {
        console.log("Hit1", index);
        errors.push(
          "Available quantity should be at greater than Max quantity per order."
        );
        error = true;
      }
      // if (
      //   Number(item?.availableQuantity) <
      //   Number(item?.maxOrderQuantity) * 3
      // ) {
      //   console.log("Hit1", index);
      //   errors.push(
      //     "Available quantity should be at least 3 times more than Max quantity per order."
      //   );
      //   error = true;
      // }
      if (Number(item?.minOrderQuantity) > Number(item?.maxOrderQuantity)) {
        console.log("Hit2", index);
        errors.push(
          "Min quantity per order should be less than or equal to Max quantity per order."
        );
        error = true;
      }

      if (Number(item?.availableQuantity) < Number(item?.alertQuantity)) {
        console.log("Hit3", index);
        errors.push("Available quantity should be more than Alert Quantity.");
        error = true;
      }
      if (
        !item?.productSalePrice ||
        !item?.productOriginalPrice ||
        !item?.commerceStartDate ||
        !item?.commerceEndDate
      ) {
        errors.push("All fields are mandatory.");
        error = true;
      }
      if (Number(item?.productSalePrice) > Number(item?.productOriginalPrice)) {
        console.log("Hit1", index);
        errors.push("Sales price should not be more than Original price.");
        error = true;
      }
      if (moment(item?.commerceStartDate).isAfter(item?.commerceEndDate)) {
        console.log(
          "Hit2",
          index,
          item?.commerceEndDate,
          item?.commerceStartDate
        );
        errors.push(
          "Availability End date should not be prior than Start date."
        );
        error = true;
      }

      if (errors?.length > 0) {
        item.errors = errors;
      } else {
        item.errors = [];
      }
    });

    let newArr = [];
    productsList?.forEach((item) => {
      let value = item;
      filteredList?.forEach((ele) => {
        if (item?.id == ele?.id) {
          value = ele;
        }
      });
      newArr.push(value);
    });
    setProductsList(newArr);

    if (error) {
      setUpdateLoader(false);
      return;
    }

    const businessId = sessionStorage.getItem("selectedBusinessId");
    let newArr1 = [];
    filteredList?.forEach((item) => {
      if (item?.availability?.[0] == "Yes") {
        newArr1.push({
          productId: item?.id,
          skuId: "",
          businessId: businessId,
          minOrderQuantity: item?.minOrderQuantity,
          maxOrderQuantity: item?.maxOrderQuantity,
          availableQuantity: item?.availableQuantity,
          alertQuantity: item?.alertQuantity,
          availability: ["Yes"],
          hyperLocal: "Y",
          shipmentDistance: "5000",
          productSalePrice: item?.productSalePrice,
          productOriginalPrice: item?.productOriginalPrice,
          commerceStartDate: moment(item?.commerceStartDate).valueOf(),
          commerceEndDate: moment(item?.commerceEndDate).valueOf(),
          gstInfo: item?.gstInfo,
        });
      } else {
        newArr1.push({
          // productId: item?.id,
          // skuId: "",
          // businessId: businessId,
          // availability: ["No"],
          // hyperLocal: "Y",
          // shipmentDistance: "5000",

          productId: item?.id,
          skuId: "",
          businessId: businessId,
          minOrderQuantity: item?.minOrderQuantity,
          maxOrderQuantity: item?.maxOrderQuantity,
          availableQuantity: item?.availableQuantity,
          alertQuantity: item?.alertQuantity,
          availability: ["No"],
          hyperLocal: "Y",
          shipmentDistance: "5000",
          productSalePrice: item?.productSalePrice,
          productOriginalPrice: item?.productOriginalPrice,
          commerceStartDate: moment(item?.commerceStartDate).valueOf(),
          commerceEndDate: moment(item?.commerceEndDate).valueOf(),
          gstInfo: item?.gstInfo,
        });
      }
    });
    // setUpdateLoader(false);
    updateInventoryData(newArr1, businessId)
      .then((res) => {
        console.log("Success");
        setSuccessMsg(
          `${tabValue == 2 ? "Pricing" : "Inventory"} has been updated for ${
            filteredList?.length
          } products`
        );
        setOpenSnack(true);
        dispatch(getProductsByBusiness(businessId));
        setUpdateLoader(false);
      })
      .finally(() => setUpdateLoader(false));
  };

  // Filter Products by Category
  const handleCategoryFilter = (data) => {
    clearSearch();

    setSelectedCategory(data);
    setSelectedSubCat("All");

    if (data === "All") {
      setSubCatList([]);
      let filteredList = getPausedProducts();
      setProductsList(filteredList);
      return;
    }

    const CategoryData = categoryList?.filter((item) => {
      return item?.value === data;
    });

    formatCategory(CategoryData?.[0]?.subCat, setSubCatList);

    const filteredProducts = businessProducts?.productList?.filter((item) => {
      return (
        item?.productSubCategory?.[0]?.pcCode === CategoryData?.[0]?.code &&
        // item?.availableQuantity < item?.minOrderQuantity &&
        item?.availability?.[0] == "No"
      );
    });

    let filteredList1 = [];
    filteredProducts?.forEach((element) => {
      filteredList1?.push({
        ...element,
        itemChange: false,
        currentStock: element.availableQuantity,
        errors: [],
      });
    });

    console.log("CAT_FILTER:", filteredProducts);

    setProductsList(filteredList1);
  };

  // Filter Products by Sub Category
  const handleSubCategoryFilter = (data) => {
    clearSearch();

    setSelectedSubCat(data);
    if (data === "All") {
      const CategoryData = categoryList?.filter((item) => {
        return item?.value === selectedCategory;
      });

      const filteredProducts = businessProducts?.productList?.filter((item) => {
        return (
          item?.productSubCategory?.[0]?.pcCode === CategoryData?.[0]?.code &&
          // item?.availableQuantity < item?.minOrderQuantity &&
          item?.availability?.[0] == "No"
        );
      });

      console.log("SUB_CAT_FILTER:", filteredProducts);

      let filteredList1 = [];
      filteredProducts?.forEach((element) => {
        filteredList1?.push({
          ...element,
          itemChange: false,
          currentStock: element.availableQuantity,
          errors: [],
        });
      });

      setProductsList(filteredList1);
      return;
    }

    const subCategoryData = subCatList?.filter((item) => {
      return item?.value === data;
    });

    const filteredProducts = businessProducts?.productList?.filter((item) => {
      return (
        item?.productSubCategory?.[0]?.code === subCategoryData?.[0]?.code &&
        // item?.availableQuantity < item?.minOrderQuantity &&
        item?.availability?.[0] == "No"
      );
    });

    let filteredList1 = [];
    filteredProducts?.forEach((element) => {
      filteredList1?.push({
        ...element,
        itemChange: false,
        currentStock: element.availableQuantity,
        errors: [],
      });
    });
    setProductsList(filteredList1);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setSuccessMsg("");
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  return (
    <Box sx={styles.container}>
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          handleClose={handleCloseVideoModal}
          data={INVENTORY_VIDEO_DATA}
        />
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={4000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>

      <DashboardLayout activeMenu="inventory" activeSubMenu="notSelling">
        <Box sx={styles.content}>
          <Box sx={{ ...stickyHeader, pt: 0.5 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: { md: "center", xs: "flex-start" },
                justifyContent: "space-between",
                flexDirection: { md: "row", xs: "row" },
                mb: "10px",
                mt: "8px",
              }}
            >
              <Typography
                component="h4"
                fontSize={{ xs: 15, md: 17 }}
                color={blackShade05}
                fontWeight={600}
                lineHeight="20px"
              >
                Not Selling
              </Typography>

              <HowToLinkCard
                link="Inventory Management"
                handleClick={() => {
                  setOpenVideoModal(true);
                }}
                // mb={2}
              />
            </Box>

            <Box
              display="flex"
              alignItems="flex-start"
              flexDirection={{ xs: "column", md: "row" }}
              borderBottom={logoImgborder}
              mb="15px"
            >
              {!matches && (
                <Typography
                  component="p"
                  fontSize={13}
                  color={{ xs: mobilePrimary, md: blueColor04 }}
                  width="150px"
                  textAlign="center"
                >
                  {businessProducts?.productList ? productsList?.length : "XX"}
                </Typography>
              )}

              <Typography
                component="span"
                fontSize={{ xs: 11.5, md: 13 }}
                color={{ xs: mobilePrimary, md: blueColor04 }}
                fontWeight={500}
                lineHeight="20px"
                pb={1}
                borderBottom={{
                  xs: `3px solid ${mobilePrimary}`,
                  md: `3px solid ${blueColor04}`,
                }}
              >
                {matches ? "Products not Orderable" : "PRODUCTS NOT ORDERABLE"}
                {matches
                  ? ` - ${
                      businessProducts?.productList
                        ? productsList?.length
                        : "XX"
                    }`
                  : ""}
              </Typography>
            </Box>

            {!businessProductsLoader ? (
              !businessProductsLoader &&
              pausedProductsList?.length !== 0 && (
                <>
                  {businessProducts?.productList?.length > 0 ? (
                    <>
                      <Box
                        display={"flex"}
                        justifyContent="space-between"
                        width={"100%"}
                        pt={{xs:1,md:0}}
                      >
                        <Box>
                          {matches && (
                            <Typography
                              fontSize={13}
                              color={greyColor5}
                              // display={{ md: "block", xs: "none" }}
                            >
                              All the products listed below are set as NOT
                              available and are OUT OF STOCK for ordering.{" "}
                            </Typography>
                          )}

                          <Box display={"flex"} alignItems="center">
                            <Typography
                              fontSize={13}
                              fontWeight={500}
                              color={greyShade168}
                              sx={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => setInfoModal(true)}
                            >
                              How to update Stock?
                            </Typography>
                            {/* <Tooltip
                        arrow
                        title={
                          <Typography color={whiteColor}>
                            The below products are ‘Out of Stock’ and are
                            not orderable by the customer. Please update the
                            ‘AVAILABLE QTY’.
                          </Typography>
                        }
                      > */}
                            <IconButton>
                              <InfoRoundedIcon
                                sx={{ fontSize: "18px", color: greyColor9 }}
                              />
                            </IconButton>
                            {/* </Tooltip> */}
                          </Box>
                        </Box>

                        <Box>
                          {" "}
                          {matches ? (
                            <Button
                              sx={styles.addIconBtn}
                              variant="contained"
                              disableElevation
                              onClick={() => {
                                updateInventory();
                                // navigate("/addProduct");
                              }}
                              startIcon={<InventoryRoundedIcon />}
                              disabled={updateLoader}
                            >
                              Update Inventory
                            </Button>
                          ) : (
                            // <IconButton
                            //   sx={styles.addIconBtnSmall}
                            //   onClick={() => {
                            //     navigate("/addProduct");
                            //   }}
                            // >
                            //   <AddIcon />
                            // </IconButton>
                            <Button
                              sx={styles.addIconBtn}
                              variant="contained"
                              disableElevation
                              onClick={() => {
                                updateInventory();
                                // navigate("/addProduct");
                              }}
                              // startIcon={<InventoryRoundedIcon />}
                              disabled={updateLoader}
                            >
                              Update Inventory
                            </Button>
                          )}{" "}
                        </Box>
                      </Box>

                      {matches && (
                        <Box mt={{ md: 2.5, xs: 0 }}>
                          <Grid container>
                            <Grid item xs={12} md={7}>
                              <Typography
                                component="h4"
                                fontSize={15}
                                color={blackShade05}
                                fontWeight={600}
                                lineHeight={{ xs: "18px", md: "20px" }}
                                m="10px 0 12px !important"
                                flex={0.8}
                              >
                                {/* Paused Inventory{" "} */}
                                <Box
                                  component="span"
                                  sx={{
                                    fontSize: 13,
                                    color: greyColor6,
                                    fontWeight: 400,
                                  }}
                                >
                                  {/* - {productsList?.length || 0} */}
                                </Box>
                              </Typography>

                              {isClearSearch && searchQuery ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gap="10px"
                                  mt="4px"
                                >
                                  <Typography
                                    component="h4"
                                    fontSize={15}
                                    color={blackShade05}
                                    fontWeight={500}
                                    lineHeight="20px"
                                    m="0px !important"
                                  >
                                    Search results for “
                                    <Box
                                      component="span"
                                      sx={{
                                        fontSize: 15,
                                        color: blackShade05,
                                        fontWeight: 600,
                                      }}
                                    >
                                      {searchQuery}
                                    </Box>
                                    ”
                                  </Typography>

                                  <Chip
                                    label={searchQuery}
                                    sx={{ fontSize: "12px", height: "28px" }}
                                    onDelete={clearSearch}
                                  />
                                </Box>
                              ) : null}
                            </Grid>

                            <Grid item xs={12} md={5}>
                              <Box m={{ xs: "12px 16px 0 16px", md: "0px" }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={6} p="0px !important">
                                    <Box m={{ xs: "16px 0", md: "0 16px" }}>
                                      <SelectWithLabel
                                        label="Product Category"
                                        value={selectedCategory}
                                        onValueChange={(value) => {
                                          handleCategoryFilter(value);
                                        }}
                                        menuList={categoryList}
                                        isDashboardInput
                                      />
                                    </Box>
                                  </Grid>

                                  <Grid item xs={12} md={6} p="0px !important">
                                    <SelectWithLabel
                                      label="Product Sub Category"
                                      value={selectedSubCat}
                                      onValueChange={(value) => {
                                        handleSubCategoryFilter(value);
                                      }}
                                      menuList={subCatList}
                                      isDashboardInput
                                      disabled={subCatList.length === 0}
                                    />
                                  </Grid>
                                  {/* <Grid item xs={12} md={4} p="0px !important">
                          <Box m={{ xs: "16px 0", md: "0 16px" }}>
                            <SearchBoxInput
                              label="Search"
                              placeholder="Search Product"
                              value={searchQuery}
                              onChange={(text) => {
                                setSearchQuery(text);
                              }}
                              onSearchClick={handleSearch}
                              isDashboardInput
                            />
                          </Box>
                        </Grid> */}
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      )}

                      {matches && (
                        <Box sx={styles.bodySticky}>
                          <Grid
                            container
                            sx={{
                              // backgroundColor: tableHeaderColor,
                              backgroundColor: "#e8eaf2",
                              px: 2,
                              py: 1,
                              borderBottom: "1px solid #eaeaea",
                              display: { md: "flex", xs: "flex" },
                              borderRadius: "12px 12px 0 0 ",
                            }}
                            alignItems="center"
                          >
                            {ALL_STOCK_COLUMN_HEADING.map((colHead, i) => (
                              <Grid
                                item
                                md={colHead.size}
                                key={i}
                                // sx={{ display: "flex", alignItems: "center" }}
                              >
                                {/* {colHead.label == "Product" ? <CheckBoxWithLabel /> : null} */}
                                <Typography
                                  variant="h4"
                                  fontSize={13}
                                  color={greyColor5}
                                  fontWeight={500}
                                  lineHeight={1.5}
                                  textTransform="uppercase"
                                  ml={i === 8 ? 2 : 0}
                                >
                                  {colHead.label}
                                </Typography>

                                <Typography
                                  fontSize={12}
                                  color={greyShade65}
                                  fontWeight={400}
                                  lineHeight={1.5}
                                  textTransform="uppercase"
                                >
                                  {colHead.description}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      )}
                    </>
                  ) : (
                    <Typography
                      component="h4"
                      fontSize={15}
                      color={blackShade05}
                      fontWeight={600}
                      lineHeight={{ xs: "18px", md: "20px" }}
                      m="10px 0 12px !important"
                      flex={0.8}
                    >
                      All Stock{" "}
                      <Box
                        component="span"
                        sx={{
                          fontSize: 13,
                          color: greyColor6,
                          fontWeight: 400,
                        }}
                      >
                        - 0
                      </Box>
                    </Typography>
                  )}
                </>
              )
            ) : (
              <ThreeDotLoader />
            )}
          </Box>

          {!matches && pausedProductsList?.length > 0 && (
            <Box mb={2}>
              <Grid container>
                <Grid item xs={12} md={7}>
                  <Typography
                    component="h4"
                    fontSize={15}
                    color={blackShade05}
                    fontWeight={600}
                    lineHeight={{ xs: "18px", md: "20px" }}
                    m="10px 0 12px !important"
                    flex={0.8}
                  >
                    {/* Paused Inventory{" "} */}
                    <Box
                      component="span"
                      sx={{
                        fontSize: 13,
                        color: greyColor6,
                        fontWeight: 400,
                      }}
                    >
                      {/* - {productsList?.length || 0} */}
                    </Box>
                  </Typography>

                  {isClearSearch && searchQuery ? (
                    <Box display="flex" alignItems="center" gap="10px" mt="4px">
                      <Typography
                        component="h4"
                        fontSize={15}
                        color={blackShade05}
                        fontWeight={500}
                        lineHeight="20px"
                        m="0px !important"
                      >
                        Search results for “
                        <Box
                          component="span"
                          sx={{
                            fontSize: 15,
                            color: blackShade05,
                            fontWeight: 600,
                          }}
                        >
                          {searchQuery}
                        </Box>
                        ”
                      </Typography>

                      <Chip
                        label={searchQuery}
                        sx={{ fontSize: "12px", height: "28px" }}
                        onDelete={clearSearch}
                      />
                    </Box>
                  ) : null}
                </Grid>

                <Grid item xs={12} md={5}>
                  <Box m={{ xs: "0px 16px 0 16px", md: "0px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} p="0px !important">
                        <Box m={{ xs: "16px 0", md: "0 16px" }}>
                          <SelectWithLabel
                            label="Product Category"
                            value={selectedCategory}
                            onValueChange={(value) => {
                              handleCategoryFilter(value);
                            }}
                            menuList={categoryList}
                            isDashboardInput
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6} p="0px !important">
                        <SelectWithLabel
                          label="Product Sub Category"
                          value={selectedSubCat}
                          onValueChange={(value) => {
                            handleSubCategoryFilter(value);
                          }}
                          menuList={subCatList}
                          isDashboardInput
                          disabled={subCatList.length === 0}
                        />
                      </Grid>
                      {/* <Grid item xs={12} md={4} p="0px !important">
                          <Box m={{ xs: "16px 0", md: "0 16px" }}>
                            <SearchBoxInput
                              label="Search"
                              placeholder="Search Product"
                              value={searchQuery}
                              onChange={(text) => {
                                setSearchQuery(text);
                              }}
                              onSearchClick={handleSearch}
                              isDashboardInput
                            />
                          </Box>
                        </Grid> */}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}

          {/* </Box> */}
          {!businessProductsLoader && (
            <>
              {productsList?.length == 0 ? (
                <EmptyCard
                  msg={
                    selectedCategory !== "All" && selectedSubCat !== "All"
                      ? "No products matching this criteria are set to Not Selling"
                      : "No products are set to Not Selling"
                  }
                  icon={<ContentPasteIcon sx={{ fontSize: "24px" }} />}
                />
              ) : (
                <Grid
                  container
                  spacing={{ md: 0, xs: 2 }}
                  sx={{
                    borderRadius: {
                      xs: "none",
                      md: "0 0 0 0",
                    },
                  }}
                >
                  {productsList?.map((data, i) => (
                    <Grid item xs={12}>
                      <Box
                        // my={1}
                        sx={{
                          overflow: "hidden",
                          borderRadius: { xs: "12px 12px", md: "0 0 0 0 " },
                          border: "1px solid #eaeaea",
                        }}
                      >
                        <AllStockRowCard
                          img={data?.productPhoto?.[0]?.docURL}
                          title={data?.productName}
                          originalAmt={data?.productOriginalPrice}
                          amt={data?.productSalePrice}
                          alertQty={data?.alertQuantity}
                          avlQty={data?.availableQuantity}
                          minQty={data?.minOrderQuantity}
                          maxQty={data?.maxOrderQuantity}
                          last={productsList?.length - 1 === i}
                          currentStock={data?.currentStock}
                          availability={data?.availability?.[0] == "Yes"}
                          startDate={moment(data?.commerceStartDate)?.format(
                            "YYYY-MM-DD"
                          )}
                          endDate={moment(data?.commerceEndDate)?.format(
                            "YYYY-MM-DD"
                          )}
                          errors={data?.errors}
                          isChecked={data?.itemChange}
                          onValueChange={(value) => {
                            let arr = [...productsList];
                            arr[i][value.type] = value.value;
                            if (value.type !== "itemChange")
                              arr[i].itemChange = true;
                            setProductsList(arr);
                          }}
                          gstRates={gstRates}
                          gst={data?.gstInfo?.gstPercentage}
                          ke={i}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          )}
        </Box>
      </DashboardLayout>

      <ModalCard
        open={errorModal.show}
        handleClose={() => {
          setErrorModal({ show: false, value: "" });
        }}
        width="27%"
      >
        <Box
          sx={{ backgroundColor: whiteColor, px: 3, py: 2.5, borderRadius: 2 }}
        >
          <Typography fontSize={16} fontWeight={600} color={blackColor}>
            Alert
          </Typography>
          <Typography fontSize={14} mt={2.5}>
            {errorMsg}
          </Typography>
          <Box display="flex" justifyContent={"flex-end"} mt={1.5} gap={2}>
            <Button
              sx={styles.addIconBtn}
              onClick={() => {
                setErrorModal({ show: false, value: "" });
                updateInventory();
              }}
            >
              Yes
            </Button>
            <Button
              sx={styles.fillBtn}
              onClick={() => {
                // handleChange("", 1);
                // setTabValue(errorModal.value);
                // setProductsList([]);
                setErrorModal({ show: false, value: "" });
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </ModalCard>

      <ModalCard
        open={infoModal}
        handleClose={() => {
          setInfoModal(false);
        }}
        width="50%"
      >
        <Box
          sx={{ backgroundColor: whiteColor, px: 3, py: 2.5, borderRadius: 2 }}
        >
          <Box>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={greyShade168}
              mb={2}
            >
              How to update Stock?
            </Typography>
            <Box>
              <Typography
                fontSize={14}
                fontWeight={400}
                color={greyColor9}
                display="inline"
                lineHeight={1.5}
              >
                You may restart selling the products by setting{" "}
                <Typography
                  fontSize={15}
                  fontWeight={600}
                  color={greyColor9}
                  display="inline"
                  lineHeight={1.5}
                >
                  ‘AVAILABILITY’
                </Typography>{" "}
                toggle button to{" "}
                <Typography
                  fontSize={15}
                  fontWeight={600}
                  color={greyColor9}
                  display="inline"
                  lineHeight={1.5}
                >
                  ‘YES’.
                </Typography>
              </Typography>
            </Box>

            <Box mt={0.6}>
              <Typography
                fontSize={14}
                fontWeight={400}
                color={greyColor9}
                display="inline"
                lineHeight={1.5}
              >
                Also update all inventory details to enable smooth availability
                of product for customers.
              </Typography>
            </Box>

            {/* <Box mt={0.6}>
              <Typography
                fontSize={14}
                fontWeight={400}
                color={greyColor9}
                display="inline"
                lineHeight={1.5}
              >
                For stock update -{" "}
                <Typography
                  fontSize={15}
                  fontWeight={600}
                  color={greyColor9}
                  display="inline"
                  lineHeight={1.5}
                >
                  ‘Available Quantity’
                </Typography>{" "}
                should be at least 3 times more than{" "}
                <Typography
                  fontSize={15}
                  fontWeight={600}
                  color={greyColor9}
                  display="inline"
                  lineHeight={1.5}
                >
                  ‘Maximum Order Quantity (per order)’.
                </Typography>
              </Typography>
            </Box> */}

            <Box mt={0.6}>
              <Typography
                fontSize={14}
                fontWeight={400}
                color={greyColor9}
                display="inline"
                lineHeight={1.5}
              >
                You can update inventory details for multiple products and click
                on{" "}
                <Typography
                  fontSize={15}
                  fontWeight={600}
                  color={greyColor9}
                  display="inline"
                  lineHeight={1.5}
                >
                  ‘UPDATE INVENTORY’
                </Typography>{" "}
                button to save the details{" "}
              </Typography>
            </Box>
          </Box>
        </Box>
      </ModalCard>
    </Box>
  );
}

export default PausedInventory;

const styles = {
  container: {
    backgroundColor: BGColor02,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    pt: { xs: 2, md: 0 },
  },

  bodySticky: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: "0px", md: "20px" },
    p: { xs: "0px", md: "0 " },
    mx: { xs: "15px", md: "0px" },
    mt: { xs: "15px", md: "15px" },
    position: "-webkit-sticky",
    position: "sticky",
    top: "70px",
    backgroundColor: BGColor02,
    zIndex: 5,
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  statsCard: {
    width: { xs: "100%", md: "auto" },
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    gap: { xs: "20px", md: "35px" },
    backgroundColor: whiteColor,
    padding: { xs: "10px 16px", md: "8px 16px" },
    borderRadius: { xs: "8px", md: "12px" },
    border: `1px solid ${greyColor10}`,
    m: { xs: "12px 0 12px 0", md: "0" },
  },

  addIconBtn: {
    backgroundColor: "transparent",
    padding: { md: "6px 22px", xs: "3px 14px" },
    fontSize: { md: "13px", xs: "12px" },
    fontWeight: 600,
    color: { xs: mobilePrimary, md: blueColor04 },
    backgroundColor: whiteColor,
    border: {
      xs: `1.5px solid ${mobilePrimary}`,
      md: `1.5px solid ${blueColor04}`,
    },
    "&:hover": {
      color: whiteColor,
      backgroundColor: { xs: mobilePrimary, md: blueColor04 },
    },
  },
  fillBtn: {
    backgroundColor: blueColor04,
    padding: "6px 22px",
    fontSize: "13px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + blueColor04,
    "&:hover": {
      color: blueColor04,
      backgroundColor: whiteColor,
    },
  },

  addIconBtnSmall: {
    backgroundColor: "transparent",
    fontSize: "13px",
    fontWeight: 600,
    color: greyColor8,
    backgroundColor: whiteColor,
    borderRadius: "3px",
    border: "1.5px solid " + greyColor8,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
