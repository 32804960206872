import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import DelegateInfoCard from "../../../components/Cards/DelegateInfoCard";
import {
  blackColor,
  blackShade22,
  blueColor04,
  orangeStatusColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import PendingAdminCard from "../Cards/PendingAdminCard";
import { insertSpaceAtPosition } from "../../../utils/helper";
import { useSelector } from "react-redux";
import {
  sendBusinessWhatsappNotificationApi,
  updateBusinessRoleApi,
} from "../../../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";
import { prepareBusinessWhatsappMessage } from "../../../utils/postDataHelpers/businessWhatsappMsgHelper";

function OwnerSwitchCard({
  pending,
  onAddAdmin,
  owner,
  pendingUser,
  onDeleteSuccess,
}) {
  const [deleteLoader, setDeleteLoader] = useState(false);

  const businessId = sessionStorage.getItem("selectedBusinessId");

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  const onNotifyClick = () => {
    const whatsAppNumber = pendingUser?.phoneNo; // Replace this with the WhatsApp number you want to send the message to
    const text = encodeURIComponent(
      `Hey ${pendingUser?.name}, Congratulations! You've been granted Super Admin privileges for ${activeBusinessInfo?.name}. This will enable you to access the Seller dashboard for managing ONDC/E-Commerce orders (powered by costBo). Kindly proceed to create your user account using the link provided below`
    ); // Encode the text

    // Open the WhatsApp message window
    window.open(
      `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${text}`,
      "_blank"
    );
  };

  const onDeleteAdmin = async () => {
    setDeleteLoader(true);
    console.log("pendingUser___:", pendingUser);
    const payload = {
      businessId,
      params: {
        role: "OWNER",
        phoneNo: pendingUser?.phoneNo,
        newStatus: "INACTIVE",
        type: 1,
      },
    };

    const inactiveUser = await updateBusinessRoleApi(payload);

    // Sending Removed Whatsapp notification to Old Owner
    const removedNotificationData = prepareBusinessWhatsappMessage({
      businessId,
      code: "B015",
      ownerName: pendingUser?.name,
      brandName: activeBusinessInfo?.name || "",
      phoneNumber: pendingUser?.phoneNo,
    });

    await sendBusinessWhatsappNotificationApi(removedNotificationData);

    setDeleteLoader(false);
    onDeleteSuccess();
  };

  return (
    <Box
      mb={{ md: "75px", xs: "8px" }}
      pt={{ md: pending ? "60px" : "10px", xs: "10px" }}
    >
      <Grid container>
        <Grid
          item
          md={3}
          xs={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <DelegateInfoCard
            profileImg={owner?.photoUrl}
            name={owner?.name}
            phoneNumber={insertSpaceAtPosition(owner?.phone, 3)}
          />
        </Grid>
        <Grid
          item
          md={3.5}
          xs={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={{ md: "center", xs: "flex-start" }}
        >
          <Box
            position={"relative"}
            display={{ md: "flex", xs: "none" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box
              component={"img"}
              src="https://storage.googleapis.com/bodefaults/businessdashboardweb/super-admin-permission.svg"
              width="52%"
            />
            <Box
              position={"absolute"}
              bottom={"-70px"}
              left="-65%"
              width="240%"
            >
              <Box
                component={"img"}
                src="https://storage.googleapis.com/bodefaults/businessdashboardweb/switch-user-line.svg"
                width="100%"
              />
            </Box>
            <Box
              position={"absolute"}
              bottom={"-78px"}
              left="0"
              right="0"
              sx={{
                width: "100px",
                border: "1px solid " + blueColor04,
                zIndex: 1,
                backgroundColor: whiteColor,
                p: "6px 14px",
                borderRadius: "6px",
                m: "0px auto",
              }}
            >
              <Typography
                fontSize={14}
                fontWeight={600}
                lineHeight={"20px"}
                color={blueColor04}
              >
                Switch To
              </Typography>
            </Box>
          </Box>
          <Box
            display={{ md: "none", xs: "flex" }}
            width="82%"
            position={"relative"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={1}
          >
            <Typography
              fontSize={13}
              fontWeight={600}
              color={orangeStatusColor}
            >
              Switch to
            </Typography>
            <Box
              component={"img"}
              src="https://storage.googleapis.com/bodefaults/businessdashboardweb/switch-user-line-mb.svg"
              height={"96px"}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={3.5}
          xs={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {pending ? (
            <Box mt={{ md: "-55px", xs: "0" }} width="100%">
              <Typography
                component="h6"
                fontSize={{ xs: 14, md: 15 }}
                color={blackColor}
                fontWeight={600}
                lineHeight="20px"
                textAlign={"center"}
                pb={0.5}
              >
                Assignment Pending
              </Typography>
              <PendingAdminCard
                profileImg={""}
                name={pendingUser?.name}
                phoneNumber={insertSpaceAtPosition(pendingUser?.phoneNo, 3)}
                handleNotify={onNotifyClick}
                handleDelete={onDeleteAdmin}
                deleteLoader={deleteLoader}
                createdOn={pendingUser?.createdOn}
                owner
              />
            </Box>
          ) : (
            <Button sx={styles.btn} onClick={onAddAdmin}>
              New Super Admin
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default OwnerSwitchCard;

const styles = {
  btn: {
    whiteSpace: "nowrap",
    backgroundColor: blackShade22,
    padding: "7px 15px",
    fontSize: "13px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + blackShade22,
    borderRadius: "6px",
    "&:hover": {
      color: blackShade22,
      backgroundColor: whiteColor,
    },
  },
};
