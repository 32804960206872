import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackShade05,
  blueColor04,
  borderTextbox,
  greyColor40,
  nextBtnBG,
  orangeShade11,
  saveBtnBG,
  saveBtnColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import ModalCard from "../ModalCard";
import FormInputBox from "./FormInputBox";
import MultiSelectWithCustomInputCard from "../Cards/MultiSelectWithCustomInputCard";
import { textOneLines } from "../../utils/styles";

function HorizontalMultiSelectCustomTextWithLabel({
  label,
  value,
  disabled,
  required,
  hintText,
  onValueChange,
  menuList,
  error,
}) {
  const [inputValue, setInputValue] = useState([]);

  const [customDataModal, setCustomDataModal] = useState(false);
  const [customData, setCustomData] = useState("");

  useEffect(() => {
    setInputValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  }, [value]);
  return (
    <Grid
      container
      columnSpacing={2}
      rowSpacing={0.5}
      display={"flex"}
      alignItems={"center"}
    >
      <Grid item md={6} xs={12}>
        <Typography
          variant="h3"
          fontSize={{ xs: 12, md: 13 }}
          color={greyColor40}
          fontWeight={400}
          mb={"0px"}
          sx={{
            display: "inline",
            opacity: disabled ? 0.7 : 1,
          }}
        >
          {label}{" "}
        </Typography>
        {required ? (
          <Typography variant="body1" sx={{ display: "inline", color: "#f00" }}>
            *
          </Typography>
        ) : null}
        {hintText && (
          <Typography
            sx={{ display: "block" }}
            fontSize={11.5}
            color={orangeShade11}
            fontWeight={400}
            mt={-0.7}
          >
            {hintText}
          </Typography>
        )}
      </Grid>

      <Grid item md={6} xs={12}>
        <Box
          sx={{
            ...textOneLines,
            border: borderTextbox,
            height: { xs: "38px", md: "34px" },
            borderRadius: "6px",
            pl: "6px",
            pt: "5px",
            textTransform: "capitalize",
            fontSize: 13,
            fontWeight: 500,
            color: blackShade05,
            width: "100%",
          }}
          title={value}
          onClick={() => setCustomDataModal(true)}
        >
          {value?.replace(/_/g, " ")?.replace(/,/g, ", ")}
        </Box>
        {error ? (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={12}
            sx={{ mt: "4px !important", textAlign: "start !important" }}
            style={{ color: "red" }}
          >
            {error}
          </Typography>
        ) : null}
      </Grid>
      <ModalCard
        open={customDataModal}
        handleClose={() => {
          setCustomDataModal(false);
          setCustomData("");
        }}
        width="33%"
        disableOutsideClick
      >
        <MultiSelectWithCustomInputCard
          title={label}
          menuList={menuList}
          onValueChange={onValueChange}
          value={value}
          handleClose={() => {
            setCustomDataModal(false);
          }}
        />
      </ModalCard>
    </Grid>
  );
}

export default HorizontalMultiSelectCustomTextWithLabel;

const styles = {
  formControlStyle: {
    width: "100%",
    height: "auto",
    "& .css-hdw1oc": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline, .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
      {
        border: "none",
      },
  },
  cancelBtn: {
    padding: "4px 14px",
    fontSize: "12.5px",
    fontWeight: 600,
    color: blueColor04,
    backgroundColor: whiteColor,
    border: "1.5px solid " + blueColor04,
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },
  applyBtn: {
    padding: "4px 14px",
    fontSize: "12.5px",
    fontWeight: 600,
    backgroundColor: blueColor04,
    color: whiteColor,
    border: "1.5px solid " + blueColor04,
    "&:hover": {
      backgroundColor: whiteColor,
      color: blueColor04,
    },
  },
};
