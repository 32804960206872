import {
  Box,
  Button,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  blackColor,
  blackShade159,
  blackShade21,
  blackShade22,
  blueColor02,
  blueColor04,
  blueColor1,
  greyColor24,
  greyColor28,
  greyColor40,
  greyColor6,
  greyColor9,
  greyShade07,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import InputPreview from "../../../components/Input/InputPreview";
import StyledReadOnlySwitch from "../../../components/Switch/StyledReadOnlySwitch";
import { useNavigate } from "react-router-dom";
import {
  ECOMMERCE_VIDEO_DATA,
  E_COMMERCE_STEP_LIST,
} from "../../../utils/data";
import { scrollBox, stickyHeader } from "../../../utils/styles";
import HowToLinkCard from "../../../components/Cards/HowToLinkCard";
import VideoModal from "../../../components/VideoModal";

function BusinessDiscountsContent({ commerceDetails, activeBusinessInfo }) {
  const [fullfillmentInfo, setFullfillmentInfo] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const [tabValue, setTabValue] = useState(2);

  const navigate = useNavigate();
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const whatsAppNumber = "+918088422615";
  const emailAddress = "support@costbo.com";
  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        // `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${activeBusinessInfo?.name}+ -E-Commerce Controls+-+Discount+Detail+Update+Request.+`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };
  const navigateToTab = (newValue) => {
    if (newValue === 0) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/warehouseDetail`);
    }
    if (newValue === 1) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/deliveryMethods`);
    }
    if (newValue == 2) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/discountDetails`);
    }
    if (newValue == 3) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/otherDetails`);
    }
    if (newValue == 4) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/managers`);
    }
    if (newValue == 5) {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/bankInfo`);
    }
  };

  const handleChange = (event, newValue) => {
    navigateToTab(newValue);
  };

  const handleEditDetails = () => {
    navigate(`/editCommerceInfo`);
  };

  useEffect(() => {
    if (commerceDetails) {
      const fullfillmentData = commerceDetails?.deliveryTypeInfo?.filter(
        (item) => item?.type === "business"
      );
      if (fullfillmentData?.length > 0) {
        setFullfillmentInfo(true);
      }
    }

    return () => {};
  }, [commerceDetails]);

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  return (
    <Box flex={1}>
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          handleClose={handleCloseVideoModal}
          data={ECOMMERCE_VIDEO_DATA}
        />
      )}
      <Box sx={stickyHeader} pb={1.5}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={{ xs: "row", md: "row" }}
          mb="10px"
        >
          <Typography
            variant="h2"
            fontSize={{ md: 17, xs: 17 }}
            fontWeight={600}
            color={greyColor24}
            lineHeight="28px"
          >
            E-Commerce Controls
          </Typography>
          <HowToLinkCard
            link="E-Commerce Controls"
            handleClick={() => {
              setOpenVideoModal(true);
              // navigate("/howToVideoGuides", {
              //   state: { status: "eCommerceControls" },
              // });
            }}
            justifyContent="flex-start"
          />
        </Box>
        <Box
          sx={{
            ...scrollBox,
            display: { xs: "block", md: "block" },
            borderBottom: 1,
            borderColor: "divider",
            mb: { xs: "10px", md: 1 },
          }}
        >
          <Tabs
            variant="scrollable"
            value={tabValue}
            onChange={(event, newValue) => handleChange(event, newValue)}
            aria-label="basic tabs example"
            textColor={blackColor}
            TabIndicatorProps={{
              style: {
                height: "3px",
                backgroundColor: matches ? blueColor04 : mobilePrimary,
              },
            }}
            sx={{
              minHeight: "35px !important",
              "& .MuiButtonBase-root": {
                minWidth: "auto !important",
              },
            }}
          >
            {E_COMMERCE_STEP_LIST?.map((item, index) => {
              return (
                <Tab
                  key={"filter_" + index}
                  label={item.label}
                  // label={item.label}
                  sx={{
                    fontSize: { xs: "13px", md: "13.5px" },
                    fontWeight: tabValue == index ? 500 : 500,
                    p: 0,
                    minHeight: "30px !important",
                    mr: { xs: "20px", md: "55px" },
                    color:
                      item.id === tabValue
                        ? matches
                          ? blueColor04
                          : mobilePrimary
                        : greyColor40,
                    textTransform: "capitalize",
                  }}
                />
              );
            })}
          </Tabs>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width={"100%"}
            >
              <Typography
                variant="h4"
                fontSize={15}
                fontWeight={600}
                color={greyColor24}
                lineHeight="28px"
              >
                Discount Detail
              </Typography>
              {!matches ? (
                <Button onClick={handleEditDetails} sx={styles.editBtn}>
                  EDIT
                </Button>
              ) : null}
            </Box>

            {/* <Typography
              variant="h6"
              fontSize={13}
              fontWeight={500}
              color={blackShade21}
              lineHeight="20px"x
              // display="inline"
              // mb="15px"
            >
              For any changes to below details, please contact COSTBO support
              via{" "}
              <Typography
                // variant="body1"
                fontSize={13}
                fontWeight={500}
                color={{ xs: mobilePrimary, md: blueColor04 }}
                display="inline"
                // onclick={handleChatClick}
                component="a"
                href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Discount+Detail+Update+Request.+&fs=1&to=${emailAddress}&tf=cm`}
                target="_blank"
              >
                Email
              </Typography>
              {"  "}
              or{" "}
              <Typography
                // variant="body1"
                fontSize={13}
                fontWeight={500}
                color={{ xs: mobilePrimary, md: blueColor04 }}
                display="inline"
                onClick={handleChatClick}
              >
                Whatsapp
              </Typography>
            </Typography> */}

          {matches ? (
            <Button onClick={handleEditDetails} sx={styles.editBtn}>
              EDIT
            </Button>
          ) : null}
        </Box>
      </Box>
      <Box sx={styles.card}>
        <TitleText title="Free Shipment" />

        <FeatureStatusInfo
          isEnabled={
            commerceDetails?.immediateDiscount !== "" &&
            commerceDetails?.immediateDiscount !== 0
          }
          infoText="To boost sales, enable Free Shipments for orders above certain value"
        />

        <Box m={{ md: "10px 0 10px 16px", xs: "10px 0 10px " }}>
          {commerceDetails?.immediateDiscount !== "" &&
          commerceDetails?.immediateDiscount !== 0 ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <InputPreview
                  label="On Orders above"
                  value={`₹ ${commerceDetails?.immediateDiscount}`}
                  valueSize={14}
                  hideBorder
                />
              </Grid>
            </Grid>
          ) : null}
        </Box>

        <Divider />

        <Box mt={2}>
          <TitleText title="Cashback on Orders" />

          <FeatureStatusInfo
            isEnabled={commerceDetails?.rewardPointPercent > 0}
            infoText={`To encourage repeat customer purchase enable Loyalty Points Program.\nCustomers will be able to get 5% to 10% cashback on the order value and use the points in subsequent orders.`}
          />

          <Box m={{ md: "10px 0 10px 16px", xs: "10px 0 20px " }}>
            {commerceDetails?.rewardPointPercent > 0 ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <InputPreview
                    label="Cashback Percentage (10% preferred)"
                    value={`${commerceDetails?.rewardPointPercent}%`}
                    valueSize={14}
                    hideBorder
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputPreview
                    label="Max Cashback usage per Order"
                    value={`₹ ${commerceDetails?.referralPointsMaxPerTxn}`}
                    valueSize={14}
                    hideBorder
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputPreview
                    label="Cashback Validity"
                    value={`${commerceDetails?.rewardExpirationDays} Days`}
                    valueSize={14}
                    hideBorder
                  />
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Box>

        <Divider />

        <Box mt={2}>
          <TitleText title="Delivery discount" />
          <Typography
            variant="h3"
            fontSize={13}
            color={greyColor6}
            fontWeight={400}
            lineHeight="18px"
            mt={0.8}
            ml={1.8}
          >
            Additional Discount based on Distance (Distance between warehouse
            and customer address)
          </Typography>
          {/* <TitleText
            title="Additional Discount based on Distance"
            subtitle="(Distance between warehouse and customer address)"
          /> */}

          <FeatureStatusInfo
            isEnabled={
              commerceDetails?.additionalDiscount?.length > 0
              // && fullfillmentInfo
            }
            infoText={`Enable more discounts based on shipment distance`}
          />

          <Box m={{ md: "10px 0 6px 16px", xs: "10px 0 20px " }}>
            {commerceDetails?.additionalDiscount?.length > 0 ? (
              <>
                <Box display="flex" alignItems="center" gap="12px" mt={1.5}>
                  <Typography
                    variant="h3"
                    fontSize={13}
                    color={greyColor6}
                    fontWeight={400}
                    width={"200px"}
                  >
                    Shipping distance
                  </Typography>
                  <Typography
                    variant="h3"
                    fontSize={13}
                    color={greyColor6}
                    fontWeight={400}
                    width={"170px"}
                  >
                    % OFF
                  </Typography>
                </Box>
                {commerceDetails?.additionalDiscount?.map((item, index) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="12px"
                    mt={1.5}
                    key={`pricing_${index}`}
                  >
                    <Box sx={styles.pricingValueBox}>
                      <Typography variant="body1" sx={styles.pricingValueText}>
                        {item?.distanceStart}KM - {item?.distanceEnd}KM
                      </Typography>
                    </Box>

                    <Box sx={styles.pricingValueBox}>
                      <Typography variant="body1" sx={styles.pricingValueText}>
                        {item?.discountPercentage}%
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </>
            ) : // (
            //   <Grid container spacing={2}>
            //     <Grid item xs={12} md={6}>
            //       <InputPreview
            //         label=""
            //         value={`${commerceDetails?.additionalDiscount
            //           ?.map((item) =>
            //             item?.distanceStart !== "" &&
            //             item?.distanceEnd !== "" &&
            //             item?.discountPercentage !== ""
            //               ? `${item?.distanceStart} KM - ${item?.distanceEnd} KM ${item?.discountPercentage}%`
            //               : ""
            //           )
            //           ?.join(", ")}`}
            //       />
            //     </Grid>
            //   </Grid>
            // )
            null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BusinessDiscountsContent;

const TitleText = ({ title, subtitle }) => {
  return (
    <Box display="flex" alignItems="center" gap="8px">
      <Box sx={styles.dotUi} />

      <Typography
        component="h6"
        fontSize={14}
        color={greyColor24}
        fontWeight={600}
        lineHeight="18px"
      >
        {title}{" "}
        <Typography
          component="span"
          fontSize={12.5}
          color={greyShade07}
          fontWeight={400}
          ml="5px !important"
        >
          {subtitle}
        </Typography>
      </Typography>
    </Box>
  );
};

const FeatureStatusInfo = ({ isEnabled, infoText }) => {
  return (
    <Box ml={{ md: 1, xs: 0 }}>
      <StyledReadOnlySwitch isEnabled={isEnabled} />

      {!isEnabled && (
        <Typography
          variant="body1"
          fontSize={13}
          fontWeight={400}
          color={greyColor6}
          mt={1}
          sx={{ whiteSpace: "pre-line" }}
        >
          {infoText}
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  card: {
    p: { xs: "10px 15px", md: "15px 15px" },
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: { xs: "8px", md: "13px" },
    mt: { xs: "5px", md: "5px" },
  },

  dotUi: {
    width: "6px",
    height: "6px",
    borderRadius: "3px",
    backgroundColor: greyColor24,
    mt: -0.2,
  },

  pricingValueBox: {
    width: "200px",
    border: `1px solid ${greyColor28}`,
    p: "9px",
    borderRadius:"6px"
  },

  pricingValueText: {
    fontSize: {md:14,xs:13},
    fontWeight: 500,
    color: greyColor24,
    lineHeight: "18px",
  },

  editBtn: {
    backgroundColor: blackShade22,
    padding: "3px 14px",
    fontSize: "12.5px",
    fontWeight: 600,
    color: whiteColor,
    border: "1.5px solid " + blackShade22,
    "&:hover": {
      color: blackShade22,
      backgroundColor: whiteColor,
    },
  },
};
