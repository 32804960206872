import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import InputPreview from "../../components/Input/InputPreview";
import { blackColor, whiteColor } from "../../configs/styles/muiThemes";

function ProductAvailabilityPreview({
  isProductAvailable,
  qty,
  alertQty,
  minQtyPerOrder,
  maxQtyPerOrder,
  startDate,
  endDate,
}) {
  return (
    <Box sx={styles.section}>
      <Typography
        component="h5"
        fontSize={{xs:15, md:16}}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        m="5px 0 20px !important"
      >
        Product Availablity
      </Typography>

      {/* <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        <Grid item xs={12} md={3}>
          <InputPreview
            label="Product Available for selling online"
            value={isProductAvailable}
          />
        </Grid>
      </Grid> */}

      <Box mt={2.5}>
        <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
          <Grid item xs={6} md={3}>
            <InputPreview label="Available Quantity" value={qty} />
          </Grid>

          <Grid item xs={6} md={3}>
            <InputPreview label="Alert Quantity" value={alertQty} />
          </Grid>
        </Grid>
      </Box>

      <Box mt={2.5}>
        <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
          <Grid item xs={6} md={3}>
            <InputPreview
              label="Min Quantity Per Order"
              value={minQtyPerOrder}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <InputPreview
              label="Max Quantity Per Order"
              value={maxQtyPerOrder}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={2.5}>
        <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
          <Grid item xs={6} md={3}>
            <InputPreview label="Start Date" value={startDate} />
          </Grid>

          <Grid item xs={6} md={3}>
            <InputPreview label="End Date" value={endDate} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ProductAvailabilityPreview;

const styles = {
  section: {
    p: "10px 20px 35px",
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: "13px",
    mt: { xs: "25px" },
  },
};
