import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import {
  blackShade22,
  blueColor04,
  greyColor38,
  greyColor39,
  greyColor40,
  lightBlueColor02,
  redStatusColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const DragAndDropFileInput = ({file, setFile, error, onError}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  // const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const droppedFile = e.dataTransfer.files[0];
    handleFileSelection(droppedFile);
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    handleFileSelection(selectedFile);
  };

  const handleSelectFilesClick = () => {
    // Trigger click on the file input element
    fileInputRef.current.click();
  };

  const handleFileSelection = (selectedFile) => {
    if (selectedFile) {
      // Check if the selected file is an Excel sheet
      if (
        selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        setFile(selectedFile);
        console.log("Selected File:", selectedFile);

        // Display file details
        console.log("File Name:", selectedFile.name);
        console.log(
          "File Size:",
          `${(selectedFile.size / 1024).toFixed(2)} KB`
        );
      } else {
        onError("Please select a valid Excel sheet file.")
      }
    }
  };

  const handleClear = ()=>{
    setFile(null)
  }

  return (
    <>
      {!file && (
        <Box
          sx={{
            ...styles.card,
            backgroundColor: isDragOver ? lightBlueColor02 : greyColor39,
            border: `1px dashed ${isDragOver ? blueColor04 : greyColor38}`,
          }}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            ref={fileInputRef}
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />

          <Box sx={styles.container}>
            <FileUploadOutlinedIcon
              sx={{ fontSize: "24px", color: blueColor04 }}
            />
          </Box>

          <Typography
            fontSize={15}
            fontWeight={600}
            color={blackShade22}
            mt={1}
          >
            Browse or Drag and Drop file to import
          </Typography>

          <Typography fontSize={14} fontWeight={400} color={greyColor40} mt={1}>
            or
          </Typography>

          <Button
            variant="contained"
            disableElevation
            sx={styles.btn}
            onClick={handleSelectFilesClick}
          >
            CHOOSE FILE
          </Button>

          <Typography fontSize={12} fontWeight={400} color={greyColor40} mt={2}>
            Maximum Size & Files Supported: 4MB & .xlsx, .xlsm
          </Typography>

          <Typography fontSize={12} fontWeight={500} color={redStatusColor} mt={2}>
            {error}
          </Typography>
        </Box>
      )}

      {file && (
        <Box sx={styles.fileInfoCard}>
          <Box sx={styles.container}>
            <DescriptionOutlinedIcon
              sx={{ fontSize: "22px", color: blueColor04 }}
            />
          </Box>

          <Box flex={1}>
            <Typography fontSize={13} fontWeight={500} sx={{lineBreak:'anywhere'}}>
              {file?.name}
            </Typography>

            <Typography fontSize={12.5} fontWeight={400} color={greyColor40}>
              {(file?.size / 1024).toFixed(2)} KB
            </Typography>
          </Box>

          <IconButton onClick={handleClear}>
            <CloseOutlinedIcon sx={{fontSize:'24px', color: greyColor40}} />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default DragAndDropFileInput;

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    border: `1px dashed ${greyColor38}`,
    backgroundColor: greyColor39,
    borderRadius: "20px",
    padding: "30px",
  },

  container: {
    width: "45px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    border: `1px solid ${blueColor04}`,
    borderRadius: "25px",
  },

  btn: {
    fontSize: 12,
    fontWeight: 600,
    color: blueColor04,
    backgroundColor: whiteColor,
    border: `1px solid ${blueColor04}`,
    p: "6px 25px",
    mt: 2,
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },

  fileInfoCard: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    border: `1px dashed ${blueColor04}`,
    backgroundColor: greyColor39,
    borderRadius: "10px",
    padding: "15px",
  },
};
