import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { blackShade05 } from "../../../../configs/styles/muiThemes";
import InputPreview from "../../../../components/Input/InputPreview";

function DisplayBoxData({ title, length, width, height, count }) {
  return (
    <Box mb={2}>
      <Typography
        fontSize={14}
        fontWeight={600}
        color={blackShade05}
        lineHeight={"20px"}
        mb={1}
      >
        {title}
      </Typography>
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid item xs={12} md={3}>
          <InputPreview label="Length (cm)" value={length} valueSize={14} />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputPreview label="Width (cm)" value={width} valueSize={14} />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputPreview label="Height (cm)" value={height} valueSize={14} />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputPreview
            label="No of Boxes (1 to 100)"
            value={count}
            valueSize={14}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default DisplayBoxData;
