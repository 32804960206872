import React, { useEffect, useState } from "react";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ONDC_DEFAULT_NEW_DATA } from "../../utils/data/defaultFormData";
import { stickyHeader } from "../../utils/styles";
import {
  blackColor,
  blueColor04,
  mobilePrimary,
  primaryColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import BreadcrumbSection from "../../components/Breadcrumb/BreadcrumbSection";
import CategoryForm from "./Forms/CategoryForm";
import {
  getOndcFullSpecificationAPI,
  getOndcSpecificationAPI,
  getProductCodeAPI,
  getProductSubCategoryOneAPI,
} from "../../service/api_calls/AddProduct/AddProductAPI";
import { PRODUCT_CODE_DEFAULT_LIST } from "../../utils/data";
import {
  countMatch,
  extractNameCodePairs,
  extractNames,
  getValueByCode,
} from "../../utils/helper";
import CatalogActionBtns from "../../components/Buttons/CatalogActionBtns";
import OndcForm from "./Forms/OndcForm";
import {
  getReviewProductDetailsAPI,
  getUnitOfMeasurementApi,
  getViewProductDetailsAPI,
  uploadProductApi,
} from "../../service/api_calls/catalog/CatalogAPI";
import {
  getTimeToShipUnitValue,
  getTimeToShipValue,
  prepareONDCDataPostAPIData,
  prepareONDCEnabledPostAPIData,
} from "../../utils/postDataHelpers/CatalogHelper";
import {
  getProductSubCategoryOne,
  getProductSubCategoryTwo,
} from "../../reducers/AddProduct/AddProductSlice";
import {
  getNameByCode,
  getValueByPath,
  parseDbObjectInsideArray,
} from "../../utils/validationHelper";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import OndcPreview from "./Preview/OndcPreview";
import ModalCard from "../../components/ModalCard";
import CategoryPreview from "./Preview/CategoryPreview";
import { clearCatalogData } from "../../reducers/businessPreview/businessPreviewSlice";

function AddONDCDetailsNewContent() {
  const businessName = sessionStorage.getItem("costboWEBURL");

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [tab, setTab] = useState("category");

  const [productDetails, setProductDetails] = useState(null);

  const [categoryInfo, setCategoryInfo] = useState({
    ondcEnabled: "yes",
    ondcCategory: [],
    ondcSubCategory: [],
    selectedOndcCat: "",
    selectedOndcSubCat: "",
    productName: "",

    errors: null,
  });

  const [ondcData, setOndcData] = useState(ONDC_DEFAULT_NEW_DATA);

  const [ondcSpecificationData, setOndcSpecificationData] = useState([]);

  const [productCodeList, setProductCodeList] = useState(null);
  const [unitOfMeasurementData, setUnitOfMeasurementData] = useState([]);

  const [errorPage1, setErrorPage1] = useState(false);
  const [errorPage2, setErrorPage2] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [addProductLoader, setAddProductLoader] = useState(false);

  const { activeBusinessInfo } = useSelector((state) => state.businessPreview);
  const { countryList } = useSelector((state) => state.catalog);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSetValue = (name, value, state, setState) => {
    if (name !== "errors") {
      setState({
        ...state,
        [name]: value,
        errors: { ...state?.errors, [name]: "" },
      });
      return;
    }
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleOndcCategoryChange = async (catData) => {
    console.log("catData", catData);

    const response = await getProductSubCategoryOneAPI(catData?.code);

    const productCodeResponse = await getProductCodeAPI({
      domainCode: catData?.code,
      status: "active",
    });

    if (productCodeResponse?.details?.length > 0) {
      setProductCodeList(productCodeResponse?.details);
    } else {
      setProductCodeList(PRODUCT_CODE_DEFAULT_LIST);
    }

    console.log(response, productCodeResponse);

    setCategoryInfo((prevState) => ({
      ...prevState,
      selectedOndcCat: catData,
      ondcSubCategory: response?.details || [],
      selectedOndcSubCat: "",
      errors: prevState?.errors
        ? { ...prevState?.errors, selectedOndcCat: "" }
        : null,
    }));
  };

  const handleOndcSubCategoryChange = async (subCatData) => {
    console.log("DATA", categoryInfo, subCatData);
    setCategoryInfo((prevState) => ({
      ...prevState,
      selectedOndcSubCat: subCatData,
      errors: prevState?.errors
        ? { ...prevState?.errors, selectedOndcSubCat: "" }
        : null,
    }));

    const specificationPayload = {
      pscCode1: subCatData?.pscCode1,
      status: "active",
    };
    const fullSpecificationPayload = {
      domainCode: categoryInfo?.selectedOndcCat?.code,
      status: "active",
    };
    const specification = await getOndcSpecificationAPI(specificationPayload);
    const fullSpecification = await getOndcFullSpecificationAPI(
      fullSpecificationPayload
    );

    const mapByCode = fullSpecification?.details?.reduce((acc, obj) => {
      acc[obj.code] = obj;
      return acc;
    }, {});

    const array = specification?.details?.[0]?.attributes?.map((obj1) => {
      // Check if there is a matching object in the second array based on code
      if (mapByCode[obj1?.value]) {
        // Merge properties from the second array object to the first array object
        console.log("map", mapByCode[obj1?.value]?.type);
        if (mapByCode[obj1?.value]?.display === "N") {
          return {
            ...obj1,
            required: "o",
            ...mapByCode[obj1?.value],
            inputValue: mapByCode[obj1?.value]?.defaultValue,
          };
        }
        if (mapByCode[obj1?.value]?.type === "codeValue") {
          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            inputValue: "",
            options: extractNames(mapByCode[obj1?.value]?.values),
            nameCodes: extractNameCodePairs(mapByCode[obj1?.value]?.values),
          };
        }
        if (mapByCode[obj1?.value]?.type === "radioButton") {
          // const radioOptions = mapByCode[obj1?.value]?.values?.map(
          //   (jsonString) => JSON.parse(jsonString)
          // );
          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            radioOptions: mapByCode[obj1?.value]?.radioOptions,
            // radioOptions: radioOptions,
          };
        }
        return {
          ...obj1,
          required: obj1?.code,
          ...mapByCode[obj1?.value],
          inputValue:
            mapByCode[obj1?.value]?.type === "default"
              ? mapByCode[obj1?.value]?.values?.[0]
              : "",
        };
      } else {
        // If no match found, simply return the first array object
        return obj1;
      }
    });

    setOndcSpecificationData(array);
    console.log(
      "codeMapData",
      mapByCode,
      specification?.details?.[0]?.attributes,
      array
    );
  };

  const validateProductDetailsFormOne = () => {
    let categoryErr = null;

    if (
      productDetails?.ondcEnabled === "yes" &&
      categoryInfo?.ondcEnabled === "no"
    ) {
      updateOnlyOndcEnabledValue();
      return;
    }

    console.log("validateProductDetailsFormOne__categoryInfo:", categoryInfo);

    // ONDC categories

    if (!categoryInfo?.selectedOndcCat?.code) {
      categoryErr = {
        ...categoryErr,
        selectedOndcCat: "Select ONDC category",
      };
    }
    if (!categoryInfo?.selectedOndcSubCat?.productSubCategory1) {
      categoryErr = {
        ...categoryErr,
        selectedOndcSubCat: "Select ONDC sub category",
      };
    }

    if (categoryErr) {
      setErrorPage1(true);
      setCategoryInfo((prevState) => ({
        ...prevState,
        errors: categoryErr,
      }));
    } else {
      setErrorPage1(false);
      setCategoryInfo((prevState) => ({
        ...prevState,
        errors: null,
      }));

      setTab("ondcDetails");
      window.scrollTo(0, 0);
    }
  };

  const validateProductDetailsFormTwo = (isSubmit) => {
    console.log("ondcSpecificationData", ondcSpecificationData);
    console.log("ondcData", ondcData);

    // handleSetValue("errors", null, ondcData, setOndcData);

    let errors = null;
    let specificationError = false;

    if (!ondcData?.timeToShip || ondcData?.timeToShip == "0") {
      errors = { ...errors, timeToShip: "Enter time to ship" };
    }

    if (
      !ondcData?.returnTerms?.returnPeriod ||
      ondcData?.returnTerms?.returnPeriod == "0"
    ) {
      errors = { ...errors, returnPeriod: "Enter return period" };
    }

    if (ondcData?.cancelTerms?.cancellable?.toLowerCase() === "yes") {
      if (!ondcData?.cancelTerms?.cancellationFeePercentage) {
        errors = {
          ...errors,
          cancellationFeePercentage: "Enter cancellation fee percentage",
        };
      }
      if (
        ondcData?.cancelTerms?.cancellationFeePercentage > 0 &&
        ondcData?.cancelTerms?.cancellationFeePercentage > 100
      ) {
        errors = {
          ...errors,
          cancellationFeePercentage:
            "Cancellation fee percentage should be less than or equal to 100",
        };
      }
    }

    if (!ondcData?.productCode?.key) {
      errors = { ...errors, productCode1: "Select product code" };
    }

    if (!ondcData?.productCode?.value) {
      errors = { ...errors, productCode: "Enter product code" };
    }

    if (ondcData?.productCode?.key && ondcData?.productCode?.value) {
      const productCodeLookup = productCodeList?.find(
        (obj) => obj?.prodictCode === ondcData?.productCode?.key
      );

      const regex = new RegExp(
        productCodeLookup?.validation?.[0]
          ?.replace(/d/g, "\\d")
          ?.replace(/\./g, "\\.")
      );

      if (!regex.test(ondcData?.productCode?.value)) {
        errors = {
          ...errors,
          productCode: `Enter valid ${countMatch(
            productCodeLookup?.validation?.[0]
              ?.replace(/d/g, "\\d")
              ?.replace(/\./g, "\\.")
          )} digit product code`,
        };
      }
      console.log(
        "productCodeLookup",
        productCodeLookup,
        regex,
        ondcData?.productCode?.value
      );
    }

    if (!ondcData?.unitOfMeasure?.unit) {
      errors = { ...errors, unitOfMeasure1: "Select Unit of measure" };
    }

    if (!ondcData?.unitOfMeasure?.value) {
      errors = { ...errors, unitOfMeasure: "Enter Unit of measure" };
    }

    // Validations
    const updatedArray = ondcSpecificationData?.map((item) => {
      if (item?.type === "default") {
        return item;
      }
      if (
        !item?.inputValue &&
        item?.required?.toLowerCase() === "m" &&
        item?.type !== "radioButton"
      ) {
        specificationError = true;
        return { ...item, error: "Please enter, it's a mandatory value" };
      }
      if (
        item?.required?.toLowerCase() === "m" &&
        item?.type == "radioButton" &&
        item?.radioOptions?.every((obj) => obj.value === "")
      ) {
        specificationError = true;
        return { ...item, error: "Please select, it's a mandatory value" };
      }

      if (!item?.inputValue || item?.validation?.length === 0) {
        return { ...item, error: "" };
      }

      // const validationArray = JSON.parse(item?.validation);

      // console.log("validationArray", validationArray);

      // if (validationArray?.length === 0) {
      //   return { ...item, error: "" };
      // }

      for (let validation of item?.validation) {
        if (validation.substring(0, 5) !== "regex") {
          if (validation === item?.inputValue) {
            return { ...item, error: "" };
          }
        } else {
          const regexString = validation.match(/regex\((.+)\)/)[1];
          const regexPattern = regexString
            .replace(/d/g, "\\d")
            .replace(/\./g, "\\.");
          const regex = new RegExp(regexPattern);
          console.log(
            "regex inputValue",
            validation,
            regexPattern,
            item?.inputValue
          );
          if (regex.test(item?.inputValue)) {
            console.log("validation success");
            return { ...item, error: "" };
          }
        }
      }

      specificationError = true;
      return { ...item, error: "Please enter valid format" };
    });

    const dbSpecificationObj = parseDbObjectInsideArray(updatedArray);
    console.log("Validated Array", updatedArray);
    console.log("Db SpecificationObj", dbSpecificationObj);

    setOndcSpecificationData(updatedArray);

    if (errors) {
      handleSetValue("errors", errors, ondcData, setOndcData);
    } else {
      handleSetValue("errors", null, ondcData, setOndcData);
    }

    if (errors || specificationError) {
      setErrorPage2(true);
      console.log("ONDC NOT VALIDATED");
    } else {
      if (isSubmit) {
        updateOndcDetails(dbSpecificationObj);
      } else {
        console.log("isSubmit", isSubmit);
        setErrorPage2(false);
        setTab("preview");
        window.scrollTo(0, 0);
      }
    }
  };

  const updateOndcDetails = async (dbSpecificationObj) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    console.log("FORM_DATA:", {
      businessId,
      categoryInfo,
      productDetails,
      ondcData,
      dbSpecificationObj,
    });

    const updateOndcPayload = prepareONDCDataPostAPIData({
      businessId,
      businessInfo: activeBusinessInfo,
      categoryInfo,
      productDetails,
      ondcData,
      dbSpecificationObj,
    });

    console.log("PREPARED_ONDC_PAYLOAD_DATA:", updateOndcPayload);

    setAddProductLoader(true);

    // Updating product details
    const response = await uploadProductApi(updateOndcPayload);

    setTimeout(() => {
      if (response?.success) {
        setAddProductLoader(false);
        setShowAlert(true);
        dispatch(clearCatalogData());
      } else {
        setAddProductLoader(false);
        setShowAlert(false);
      }
    }, 1500);
  };

  const updateOnlyOndcEnabledValue = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    const updateOndcPayload = prepareONDCEnabledPostAPIData({
      businessId,
      businessInfo: activeBusinessInfo,
      categoryInfo,
      productDetails,
    });

    console.log("PREPARED_ONDC_PAYLOAD_DATA:", updateOndcPayload);

    setAddProductLoader(true);

    // Updating product details
    const response = await uploadProductApi(updateOndcPayload);

    setTimeout(() => {
      if (response?.success) {
        setAddProductLoader(false);
        setShowAlert(true);
        dispatch(clearCatalogData());
      } else {
        setAddProductLoader(false);
        setShowAlert(false);
      }
    }, 1500);
  };

  const handleCancel = () => {
    navigate(`/${businessName}/catalog`);
  };

  // Fetching product details
  const getProductDetails = async () => {
    const productId = location?.state?.productId;
    const isRejectedProduct = location?.state?.isRejectedProduct || false;

    const businessId = sessionStorage.getItem("selectedBusinessId");

    if (isRejectedProduct) {
      const data = await getReviewProductDetailsAPI({ businessId, productId });
      if (data?.details) {
        setProductDetails(data?.details);
      }
    } else {
      const data = await getViewProductDetailsAPI(productId);
      if (data?.product) {
        setProductDetails(data?.product);
      }
    }
  };

  // Get Unit of measurement
  const getUnitsData = async () => {
    const response = await getUnitOfMeasurementApi();
    setUnitOfMeasurementData(() => response?.details || []);
    console.log("getUnitsData:", response);
  };

  const autoFillCategotyInfo = async () => {
    if (activeBusinessInfo && productDetails) {
      const ondcMainCat = productDetails?.productMainCategory?.[0] || null;
      const ondcSubCat = productDetails?.productSubCategory?.[0] || null;

      let ondcSubCatArray = [];
      if (productDetails?.ondcEnabled?.toLowerCase() == "yes") {
        const response = await getProductSubCategoryOneAPI(
          ondcMainCat?.ondcCode
        );
        ondcSubCatArray = response?.details;
        const productCodeResponse = await getProductCodeAPI({
          domainCode: ondcMainCat?.ondcCode,
          status: "active",
        });

        if (productCodeResponse?.details?.length > 0) {
          setProductCodeList(productCodeResponse?.details);
        } else {
          setProductCodeList(PRODUCT_CODE_DEFAULT_LIST);
        }
      }

      const catData = {
        ...categoryInfo,
        ondcEnabled: productDetails?.ondcEnabled || "no",

        ondcCategory: activeBusinessInfo?.businessONDCCategories || [],
        ondcSubCategory: ondcSubCatArray || [],

        selectedOndcCat: {
          code: ondcMainCat?.ondcCode || "",
          name: ondcMainCat?.ondcValue || "",
          status: "active",
        },
        selectedOndcSubCat: {
          pcCode: "",
          scCode1: ondcSubCat?.ondcSubCode || "",
          pscCode1: "",
          productSubCategory1: ondcSubCat?.ondcSubValue || "",
        },
        productName: productDetails?.productName,
      };

      console.log("CAT_DATA___ProdInfo:", productDetails);
      console.log("CAT_DATA___:", catData);
      setCategoryInfo(catData);
    }
  };

  const autoFillOndcSpecification = async (productDetails) => {
    console.log("productDetailsProductDetails", productDetails);
    const specificationPayload = {
      pscCode1: `${productDetails?.productMainCategory?.[0]?.ondcCode}${productDetails?.productSubCategory?.[0]?.ondcSubCode}`,
      status: "active",
    };
    const fullSpecificationPayload = {
      domainCode: productDetails?.productMainCategory?.[0]?.ondcCode,
      status: "active",
    };
    const specification = await getOndcSpecificationAPI(specificationPayload);
    const fullSpecification = await getOndcFullSpecificationAPI(
      fullSpecificationPayload
    );

    const mapByCode = fullSpecification?.details?.reduce((acc, obj) => {
      acc[obj.code] = obj;
      return acc;
    }, {});

    const array = specification?.details?.[0]?.attributes?.map((obj1) => {
      // Check if there is a matching object in the second array based on code
      if (mapByCode[obj1?.value]) {
        console.log(
          "specificationInputValue",
          productDetails,
          mapByCode[obj1?.value]?.dbObjectName,
          getValueByPath(productDetails, mapByCode[obj1?.value]?.dbObjectName)
        );
        // Merge properties from the second array object to the first array object
        console.log("map", mapByCode[obj1?.value]?.type);
        if (mapByCode[obj1?.value]?.dbObjectName === "list") {
          console.log("hit585");
          if (mapByCode[obj1?.value]?.type === "codeValue") {
            const colorList = extractNameCodePairs(
              mapByCode[obj1?.value]?.values
            );
            const colorCode = getValueByCode(
              productDetails?.ondc?.attributeList,
              mapByCode[obj1?.value]?.code
            );
            const colourName = getNameByCode(colorList, colorCode);
            return {
              ...obj1,
              required: obj1?.code,
              ...mapByCode[obj1?.value],
              inputValue: colourName || "",
              options: extractNames(mapByCode[obj1?.value]?.values),
              nameCodes: extractNameCodePairs(mapByCode[obj1?.value]?.values),
            };
          }
          if (mapByCode[obj1?.value]?.display === "N") {
            return {
              ...obj1,
              required: "o",
              ...mapByCode[obj1?.value],
              inputValue: mapByCode[obj1?.value]?.defaultValue,
            };
          }
          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            inputValue:
              getValueByCode(
                productDetails?.ondc?.attributeList,
                mapByCode[obj1?.value]?.code
              ) || "",
            // mapByCode[obj1?.value]?.type === "default"
            //   ? mapByCode[obj1?.value]?.values?.[0]
            //   : "",
          };
        }
        if (mapByCode[obj1?.value]?.display === "N") {
          return {
            ...obj1,
            required: "o",
            ...mapByCode[obj1?.value],
            inputValue: mapByCode[obj1?.value]?.defaultValue,
          };
        }
        if (mapByCode[obj1?.value]?.type === "codeValue") {
          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            inputValue: "",
            options: extractNames(mapByCode[obj1?.value]?.values),
            nameCodes: extractNameCodePairs(mapByCode[obj1?.value]?.values),
          };
        }
        if (mapByCode[obj1?.value]?.type === "radioButton") {
          const options = mapByCode[obj1?.value]?.radioOptions;

          const radioOptions = options?.map((option) => {
            return {
              ...option,
              value: getValueByPath(productDetails, option?.key),
            };
          });

          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            radioOptions,
          };
        }
        return {
          ...obj1,
          required: obj1?.code,
          ...mapByCode[obj1?.value],
          inputValue:
            getValueByPath(
              productDetails,
              mapByCode[obj1?.value]?.dbObjectName
            ) || "",
          // mapByCode[obj1?.value]?.type === "default"
          //   ? mapByCode[obj1?.value]?.values?.[0]
          //   : "",
        };
      } else {
        // If no match found, simply return the first array object
        return obj1;
      }
    });

    console.log(
      "codeMapDataSpecification",
      mapByCode,
      specification?.details?.[0]?.attributes,
      fullSpecification,
      array
    );

    setOndcSpecificationData(array);
  };

  useEffect(() => {
    if (productDetails && countryList?.length > 0) {
      autoFillCategotyInfo();

      const ondc = productDetails?.ondc;
      if (productDetails?.ondc) {
        console.log("Ondc HiT", ondc);
        const unitSymbol =
          ondc?.timeToShip?.charAt(ondc?.timeToShip?.length - 1) || "";

        setOndcData({
          ...ONDC_DEFAULT_NEW_DATA,
          timeToShipUnit: getTimeToShipUnitValue(unitSymbol),
          available_on_cod: ondc?.available_on_cod,
          fragile: ondc?.fragile == true,
          timeToShip: getTimeToShipValue(unitSymbol, ondc?.timeToShip),

          cancelTerms: {
            cancellable: ondc?.cancelTerms?.cancellable || "none",
            cancellationFeeAmount: null,
            cancellationFeePercentage:
              ondc?.cancelTerms?.cancellationFeePercentage || "",
            refundEligible: ondc?.cancelTerms?.refundEligible || "none",
          },

          returnTerms: {
            returnable: ondc?.returnTerms?.returnable || "none",
            returnPeriod: ondc?.returnTerms?.returnPeriod
              ? ondc?.returnTerms?.returnPeriod?.slice(1, -1)
              : "",
            seller_pickup_return:
              ondc?.returnTerms?.seller_pickup_return || "none",
          },

          productCode: {
            key: ondc?.productCode?.key,
            value: ondc?.productCode?.value || "",
          },

          unitOfMeasure: productDetails?.productDisplayUnitOfMeasure,
        });

        autoFillOndcSpecification(productDetails);
      }
    }
    return () => {};
  }, [productDetails, countryList]);

  useEffect(() => {
    getProductDetails();
    if (unitOfMeasurementData?.length === 0) {
      getUnitsData();
    }
    return () => {};
  }, []);

  return (
    <Box pt={{ md: 0, xs: "10px" }}>
      <Box
        sx={{
          ...stickyHeader,
          pb: 1,
        }}
      >
        <Box sx={{ display: { md: "flex", xs: "none" } }}>
          <BreadcrumbSection
            links={[
              { label: "Product Catalog", to: `/${businessName}/catalog` },
              { label: "Edit ONDC Product Specifications", to: `#` },
            ]}
          />
        </Box>
        <Typography
          component="h6"
          fontSize={{ xs: 15, md: 17 }}
          color={blackColor}
          fontWeight={{ xs: 600, md: 600 }}
          lineHeight="20px"
          display="inline"
        >
          Edit ONDC Product Specifications -{" "}
          <span style={{ color: matches ? blueColor04 : mobilePrimary }}>
            {categoryInfo?.productName}
          </span>
        </Typography>
        <Box display={{ md: "block", xs: "none" }}>
          {tab !== "category" && (
            <CategoryPreview categoryData={categoryInfo} />
          )}
        </Box>
      </Box>
      <Box display={{ xs: "block", md: "none" }}>
        {tab !== "category" && <CategoryPreview categoryData={categoryInfo} />}
      </Box>
      {tab === "category" && categoryInfo?.selectedOndcCat ? (
        <CategoryForm
          data={categoryInfo}
          setData={setCategoryInfo}
          handleOndcCategoryChange={handleOndcCategoryChange}
          handleOndcSubCategoryChange={handleOndcSubCategoryChange}
          handleCancel={handleCancel}
          onNextClick={validateProductDetailsFormOne}
          pageError={errorPage1}
          productDetails={productDetails}
        />
      ) : (
        tab === "category" && <ThreeDotLoader />
      )}
      {tab === "ondcDetails" && (
        <OndcForm
          categoryData={categoryInfo}
          ondcData={ondcData}
          setOndcData={setOndcData}
          onValueChange={handleSetValue}
          ondcSpecificationData={ondcSpecificationData}
          setOndcSpecificationData={setOndcSpecificationData}
          productCodeList={productCodeList}
          unitOfMeasurementData={unitOfMeasurementData}
          handleCancel={handleCancel}
          onNextClick={() => validateProductDetailsFormTwo()}
          onBackClick={() => setTab("category")}
          pageError={errorPage2}
        />
      )}
      {tab === "preview" && (
        <OndcPreview
          categoryData={categoryInfo}
          ondcData={ondcData}
          ondcSpecificationData={ondcSpecificationData}
          onSubmit={() => validateProductDetailsFormTwo(true)}
          onGoBack={() => setTab("ondcDetails")}
          handleCancel={handleCancel}
        />
      )}

      <ModalCard
        open={showAlert || addProductLoader}
        handleClose={() => {
          // setShowAlert(false);
        }}
        width="48%"
      >
        <Box backgroundColor={whiteColor} borderRadius="8px">
          {addProductLoader ? (
            <Box sx={{ height: "100px", pt: 2 }}>
              <ThreeDotLoader />
            </Box>
          ) : showAlert ? (
            <AlertMessagePopup
              text={
                "Product ONDC parameters updated successfully. Our team will review and approve within 24 hours.  New changes will be reflected in the product after approval.\nFor immediate approval, please contact via whatsapp - ‘8088422615’"
              }
              onClick={() => {
                navigate(`/${businessName}/catalogPendingExisting`);
              }}
            />
          ) : null}
        </Box>
      </ModalCard>
    </Box>
  );
}

export default AddONDCDetailsNewContent;

const AlertMessagePopup = ({ text, onClick }) => {
  return (
    <Box sx={styles.alertCard}>
      <Typography
        variant="body2"
        fontSize={14}
        fontWeight={500}
        textAlign="center"
        lineHeight="26px"
        m="16px 0 12px "
      >
        {text}
      </Typography>
      <Button sx={styles.alertBtn} variant="outlined" onClick={onClick}>
        GO TO CATALOG
      </Button>
    </Box>
  );
};

const styles = {
  alertCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    padding: "20px 20px 15px 20px",
    borderRadius: "8px",
  },

  alertBtn: {
    fontSize: "13px",
    fontWeight: "500",
    color: whiteColor,
    backgroundColor: primaryColor,
    padding: "6px 24px",
    marginTop: "12px",
    "&:hover": {
      color: primaryColor,
      backgroundColor: whiteColor,
    },
  },
};
