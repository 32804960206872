import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blueColor02,
  blueColor04,
  greyColor24,
  logoImgborder1,
  mobilePrimary,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import InfoCardHeader from "../InfoCards/InfoCardHeader";

function EasyCartEmptyCard({ onClick }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Box sx={styles.card}>
     {matches && <InfoCardHeader title="Easy Cart Orders" onManageClick={onClick} />}
      <Box
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        my={2}
      >
        <Box sx={styles.iconContainer}>
          <Box
            component="img"
            src="https://storage.googleapis.com/bodefaults/businessdashboardweb/east-cart.svg"
            width="40px"
          />
        </Box>
        <Typography
          color={blackShade05}
          fontSize={14}
          fontWeight={500}
          textAlign={"center"}
          lineHeight={1.5}
          mt={1}
        >
          {`Maximize Your Order Volume: Enable One-Click Payment Carts for Your Customers!`}
        </Typography>
        <Box display={"flex"} gap={1}>
          <Typography
            fontSize={14}
            fontWeight={500}
            lineHeight={1.8}
            sx={{
              color: {xs: mobilePrimary, md:blueColor04},
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={onClick}
          >
            Click here
          </Typography>
          <Typography
            color={blackShade05}
            fontSize={15}
            fontWeight={500}
            lineHeight={1.5}
            sx={{ textDecoration: "none" }}
          >
            to get started.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default EasyCartEmptyCard;

const styles = {
  card: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "10px", md: "13px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "10px 15px", md: "10px 15px" },
  },
  iconContainer: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    background: "rgb(5 60 81 / 7%)",
    mb: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  actionBtn: {
    fontSize: "13px",
    fontWeight: "600",
    color: greyColor24,
    backgroundColor: whiteColor,
    padding: "6px 22px",
    mt: "15px",
    border: `1px solid ${greyColor24}`,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor24,
    },
  },
};
