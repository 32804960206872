import { Box, Typography } from "@mui/material";
import React from "react";
import {
  whiteColor,
  logoImgborder1,
  greyColor6,
  blackShade05,
} from "../../configs/styles/muiThemes";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import PriceCheckOutlinedIcon from "@mui/icons-material/PriceCheckOutlined";
import MoneyOffCsredOutlinedIcon from "@mui/icons-material/MoneyOffCsredOutlined";
import { addThousandsSeparator } from "../../utils/helper";

function PaycartStatsCard({summary}) {
  return (
    <Box sx={styles.infoBox}>
      <Box flex={1} display="flex" width={{ xs: "100%", md: "auto" }}>
        <StatsInfoCard
          icon={<ReceiptLongRoundedIcon sx={{ fontSize: "24px" }} />}
          label="TOTAL EASY CART ORDERS"
          value={summary?.totalPaycartcreated}
        />

        <StatsInfoCard
          icon={<PaymentsOutlinedIcon sx={{ fontSize: "24px" }} />}
          label="TOTAL EASY CART VALUE"
          value={`₹ ${
            isNaN(summary?.totalPaycartValue)
              ? 0
              : addThousandsSeparator(summary?.totalPaycartValue, true)
          }`}
        />
      </Box>
      <Box flex={1} display="flex" width={{ xs: "100%", md: "auto" }}>
        <StatsInfoCard
          icon={<PriceCheckOutlinedIcon sx={{ fontSize: "24px" }} />}
          label="ORDERS PAID"
          value={summary?.totalPaycartOrders}
        />

        <StatsInfoCard
          icon={<MoneyOffCsredOutlinedIcon sx={{ fontSize: "24px" }} />}
          label="ORDERS NOT PAID"
          value={
            Number(summary?.totalPaycartcreated) -
              Number(summary?.totalPaycartOrders)
          }
        />
      </Box>
    </Box>
  );
}

export default PaycartStatsCard;

const StatsInfoCard = ({ icon, label, value }) => {
  return (
    <Box flex={1} display="flex" gap="20px">
      <Box sx={styles.iconCircle}>{icon}</Box>

      <Box>
        <Typography
          variant="h6"
          fontSize={{ xs: 11, md: 12 }}
          fontWeight={500}
          color={greyColor6}
        >
          {label}
        </Typography>

        <Typography
          fontSize={{ xs: 15, md: 16 }}
          fontWeight={600}
          color={blackShade05}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  infoBox: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: { xs: "flex-start", md: "center" },
    // justifyContent: "space-between",
    backgroundColor: whiteColor,
    baxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    padding: { xs: "7px 12px", md: "10px" },
    border: logoImgborder1,
    borderRadius: { xs: "8px", md: "10px" },
    marginTop: "10px",
    //mb:2
  },

  statCard: {},

  iconCircle: {
    width: "45px",
    height: "45px",
    display: { xs: "none", md: "flex" },
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(5 60 81 / 7%)",
    borderRadius: "50%",
  },
};
