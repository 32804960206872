import React from "react";
import ErrorToolTipIcon from "../ErrorToolTipIcon";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import {
  borderTextbox,
  whiteColor,
} from "../../../../configs/styles/muiThemes";

const OndcRadioGroup = ({
  value,
  onValueChange,
  radioOptions,
  valueSelector,
  returnFullItem,
  disabled,
  rounded,
  errorMsg,
  width,
}) => {
  return (
    <Box position="relative">
      {errorMsg && (
        <Box sx={{ position: "absolute", right: -18, top: -15 }}>
          <ErrorToolTipIcon errorMsg={errorMsg} />
        </Box>
      )}

      <Box
        sx={{
          borderBottom: borderTextbox,
          height: { xs: "38px", md: "auto" },
          backgroundColor: whiteColor,
        }}
      >
        <FormControl
          sx={{ ...styles.formControlStyle, width: width || "200px" }}
        >
          <Select
            value={value}
            displayEmpty
            fullWidth
            sx={{
              height: "28px",
              pb: "3px",
            }}
            disabled={disabled}
          >
            {radioOptions.map((item, index) => {
              if (item?.display !== "Y") return null;

              return (
                <MenuItem
                  value={valueSelector ? item[valueSelector] : item.label}
                  key={"Menu_Item_" + index}
                  onClick={() => {
                    if (!returnFullItem) {
                      onValueChange(
                        valueSelector ? item[valueSelector] : item.label
                      );
                    } else {
                      onValueChange(item);
                    }
                  }}
                  sx={{ fontWeight: 500 }}
                >
                  {valueSelector ? item[valueSelector] : item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default OndcRadioGroup;

const styles = {
  formControlStyle: {
    height: "26px",
    "& .css-hdw1oc": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline, .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
      {
        border: "none",
      },
  },
};
