import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import Cropper from "react-easy-crop";
import {
  backBtnColor,
  blackColor,
  greyColor40,
  greyColor6,
  greyShade05,
  primaryColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { generateDownload } from "../../utils/imageCropUtils/cropImage";
import ModalCard from "../ModalCard";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import moment from "moment";
import Compressor from "compressorjs";
import {
  base64ToBlob,
  blobToBase64,
} from "../../utils/imageCropUtils/imgUrlToBase64";

function FilePickerInput({
  cropedimage,
  setCropedImage,
  setLogoChange,
  label,
  subText1,
  subText2,
  hideLabel,
  required,
  onDelete,
  error,
  ratio,
  height,
  placeholder,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  
  const inputRef = useRef(null);
  const triggerFileSelectPopup = () => inputRef.current.click();

  const [image, setImage] = useState("");
  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [openCropModal, setOpenCropModal] = useState(false);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const [fileDetails, setFileDetails] = useState(null);

  function calculateBoxDimensionsForImage(imageWidth, imageHeight) {
    // Calculate the aspect ratio of the image
    const imageAspectRatio = imageWidth / imageHeight;

    // Define the target box aspect ratio
    const targetAspectRatio = 4 / 3;

    if (imageAspectRatio > targetAspectRatio) {
      // The image is wider than the target box
      const boxWidth = imageWidth;
      const boxHeight = imageWidth / targetAspectRatio;
      return { width: boxWidth, height: boxHeight };
    } else {
      // The image is taller than the target box
      const boxHeight = imageHeight;
      const boxWidth = imageHeight * targetAspectRatio;
      return { width: boxWidth, height: boxHeight };
    }
  }

  function convertImageTo4By3AspectRatio(file, callback) {
    const img = new Image();

    img.onload = () => {
      const newBoxDimention = calculateBoxDimensionsForImage(
        img.width,
        img.height
      );

      const targetWidth = newBoxDimention.width;
      let targetHeight = newBoxDimention.height;

      const widthDiff = targetWidth - img.width;
      const heightDiff = targetHeight - img.height;

      const leftPadding = widthDiff / 2;
      const rightPadding = widthDiff / 2;

      const topPadding = heightDiff / 2;
      const bottomPadding = heightDiff / 2;

      // console.log("newBoxDimention:", newBoxDimention);

      // console.log("targetWidth:", targetWidth);
      // console.log("targetHeight:", targetHeight);

      // console.log("img.width:", img.width);
      // console.log("img.height:", img.height);

      // console.log("leftPadding:", leftPadding);
      // console.log("rightPadding:", rightPadding);
      // console.log("topPadding:", topPadding);
      // console.log("bottomPadding:", bottomPadding);

      const canvas = document.createElement("canvas");
      canvas.width = targetWidth;
      canvas.height = targetHeight;

      const ctx = canvas.getContext("2d");
      const x = leftPadding;
      const y = topPadding;

      // console.log("CANVAS_IMAG_X:", x);
      // console.log("CANVAS_IMAG_Y:", y);

      ctx.fillStyle = 'rgba(255, 255, 255, 1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(img, x, y);

      canvas.toBlob((blob) => {
        const croppedImageFile = new File([blob], file.name, {
          type: file.type,
        });
        callback(croppedImageFile);
      }, file.type);
    };

    const reader = new FileReader();
    reader.onload = (e) => {
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  }

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      // const reader = new FileReader();
      // reader.readAsDataURL(event.target.files[0]);
      // reader.onload = function (e) {
      //   setImage(reader.result);
      //   setOpenCropModal(true);
      //   setFileDetails(event.target.files[0]);
      // };

      // ---------- Start Add padding to image
      const file = event.target.files[0];
      convertImageTo4By3AspectRatio(file, (croppedImageFile) => {
        console.log("croppedImageFile:", croppedImageFile);
        console.log("event.target.files[0]:", event.target.files[0]);

        const reader = new FileReader();
        reader.readAsDataURL(croppedImageFile);
        reader.onload = function (e) {
          setImage(reader.result);
          setOpenCropModal(true);
          setFileDetails(croppedImageFile);
        };
      });
      // ---------- END Add padding to image

      // console.log("Reader:", reader);
      // console.log("event.target.files:", event.target.files);

      // reader.addEventListener("load", () => {
      //   setImage(reader.result);
      //   setOpenCropModal(true);
      // });
      // console.log("Selected Img", event.target.files);
    }
  };

  const onCropClicked = async () => {
    const dataURL = await generateDownload(image, croppedArea);

    console.log("Compression_Before:", dataURL);

    let compressedBase64Str = null;

    if (/^data:image\/[a-zA-Z]+;base64,/.test(dataURL)) {
      const blob = base64ToBlob(dataURL);

      const compressedBlob = await new Promise((resolve) => {
        new Compressor(blob, {
          quality: 0.6,
          success: (result) => resolve(result),
          error: (err) => {
            console.error(err.message);
            resolve(blob);
          },
        });
      });

      const compressedBase64 = await blobToBase64(compressedBlob);

      console.log("Compression_After:", compressedBase64);
      compressedBase64Str = compressedBase64 || dataURL;
    } else {
      console.error("Invalid base64 input");
      compressedBase64Str = dataURL;
    }

    setCropedImage(compressedBase64Str, fileDetails);
    setLogoChange(true);
    setOpenCropModal(false);
  };

  const logoError = () => {
    console.log("IMAGE_ERROR");
    onDelete("");
  };

  return (
    <Box sx={{ position: "relative" }}>
      {!hideLabel ? (
        <>
          <Typography
            variant="h3"
            fontSize={13}
            color={greyColor6}
            fontWeight={400}
            mb={"18px !important"}
            sx={{ display: "inline" }}
          >
            {label}{" "}
          </Typography>
          {required ? (
            <Typography
              variant="body1"
              sx={{ display: "inline", color: "#f00" }}
            >
              *
            </Typography>
          ) : null}
        </>
      ) : null}
      {cropedimage?.charAt(cropedimage.length - 1) !== "." && cropedimage ? (
        <Box sx={styles.card(height)}>
          <Box
            sx={{
              color: "red",
              position: "absolute",
              // borderRadius: "50%",
              right: "-10px",
              top: "-10px",
            }}
          >
            <RemoveCircleRoundedIcon
              sx={{ fontSize: "23px", cursor: "pointer" }}
              onClick={() => {
                setImage(null);
                // setCropedImage(null);
                onDelete("");
              }}
            />
          </Box>

          {/* <Box sx={styles.imgActionBtnContainer}>
            <IconButton
              size="medium"
              onClick={() => {
                setImage(null);
                setCropedImage(null);
              }}
            >
              <DeleteOutlineIcon fontSize="large" sx={{ color: whiteColor }} />
            </IconButton>
          </Box> */}

          {/* {cropedimage} */}

          <Box
            component="img"
            src={
              cropedimage?.slice(0, 4) == "data"
                ? cropedimage
                : `${cropedimage}?tag${moment()?.valueOf()}`
            }
            width="auto"
            height="100%"
            sx={{ objectFit: "contain", borderRadius: "15px" }}
            onError={() => {
              onDelete("");
            }}
          />
        </Box>
      ) : (
        <>
          <input
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={onSelectFile}
            style={{ display: "none" }}
          />
          <Box>
            <Box
              component="button"
              onClick={triggerFileSelectPopup}
              sx={styles.card(height)}
            >
              <Box
                component="img"
                src="https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/upload.svg"
                width={{ md: "40px", xs: "32px" }}
                height={{ md: "40px", xs: "32px" }}
                sx={{ objectFit: "contain", mb: "8px", mt: 1 }}
              />

              <Typography
                variant="h4"
                fontSize={{ md: 13, xs: 12 }}
                color={blackColor}
                fontWeight={500}
              >
                {placeholder || "Upload Picture"}
              </Typography>

              <Typography
                variant="span"
                fontSize={{ md: 12, xs: 11 }}
                color={greyShade05}
                fontWeight={400}
                mt={{md:"15px !important",xs:"5px !important"}}
              >
                {subText1}
              </Typography>
              <Typography
                variant="h3"
                fontSize={{ md: 12, xs: 11 }}
                color={greyShade05}
                fontWeight={400}
              >
                {subText2}
              </Typography>
            </Box>
          </Box>

          <ModalCard
            open={openCropModal}
            handleClose={() => {
              setOpenCropModal(false);
            }}
            width="50%"
          >
            <Box sx={styles.cropModalStyle}>
              <Box
                backgroundColor={whiteColor}
                p={3}
                height="70vh"
                position="relative"
                overflow="hidden"
              >
                <Box>
                  <Cropper
                    // image="https://images.pexels.com/photos/4245826/pexels-photo-4245826.jpeg"
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={ratio}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                    style={{
                      containerStyle: {
                        width: "100%",
                        height: "80%",
                        backgroundColor: "#fff",
                      },
                    }}
                  />
                </Box>

                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={3}
                  mt={2}
                  mb={3}
                  position="absolute"
                  bottom="5px"
                >
                  <Button
                    sx={styles.actionBtn(greyColor40, whiteColor)}
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      setOpenCropModal(false);
                    }}
                  >
                    CANCEL
                  </Button>

                  <Button
                    sx={styles.actionBtn(primaryColor, whiteColor)}
                    variant="contained"
                    disableElevation
                    onClick={onCropClicked}
                  >
                    CROP & APPLY
                  </Button>
                </Box>
              </Box>
            </Box>
          </ModalCard>
        </>
      )}

      <Box>
        <Typography
          variant="span"
          fontSize={12}
          color={whiteColor}
          fontWeight={400}
          sx={{ visibility: "hidden", display: { xs: "none", md: "block" } }}
        >
          {subText1}
          {subText2}
        </Typography>
      </Box>

      {error ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={12}
          sx={{ mt: "4px !important", textAlign: "start !important" }}
          style={{ color: "red" }}
        >
          {error}
        </Typography>
      ) : null}
    </Box>
  );
}

export default FilePickerInput;

const styles = {
  actionBtn: (bgColor, textcolor) => ({
    background: bgColor,
    padding: { md: "7px 32px", xs: "5px" },
    fontSize: "13px",
    color: textcolor,
    borderRadius: "5px",
    fontWeight: 600,
    border: "1px solid " + bgColor,

    "&:hover": {
      backgroundColor: whiteColor,
      color: bgColor,
      border: "1px solid " + bgColor,
    },
  }),
  card: (height) => ({
    width: { xs: "calc(100vw - 55px)", md: "100%" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1.5px dashed #afbbc1",
    // pt: 3,
    borderRadius: "15px",
    // width: "160px",
    height: {
      md: height ? height : "160px",
      xs: height ? height : "calc(50vw - 36px)",
    },
    background: "rgb(150 160 163 / 7%)",
    // overflow: "hidden",
    position: "relative",
    mt: 1,
  }),
  cropModalStyle: {
    width: "100%",
    borderRadius: "10px",
    overflow: "hidden",
  },
  imgActionBtnContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#00000040",
    padding: "3px 10px",
    borderRadius: "6px 0 0 0",
    position: "absolute",
    bottom: 0,
    right: 0,
  },
};
