import {
  BGColor02,
  blackShade159,
  blueColor04,
} from "../configs/styles/muiThemes";

export const scrollBox = {
  display: { xs: "flex", md: "none" },
  alignItems: "center",
  gap: 1.5,

  overflowX: "scroll",
  overscrollBehaviorInline: "contain",

  "&::-webkit-scrollbar": {
    display: "none",
  },

  "-webkit-overflow-scrolling": "touch",
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
};

export const textFourLines = {
  textOverflow: "ellipsis",
  display: "-webkit-box",
  overflow: "hidden",
  "-webkit-line-clamp": "4",
  "-webkit-box-orient": "vertical",
};
export const textThreeLines = {
  textOverflow: "ellipsis",
  display: "-webkit-box",
  overflow: "hidden",
  "-webkit-line-clamp": "3",
  "-webkit-box-orient": "vertical",
};
export const textTwoLines = {
  textOverflow: "ellipsis",
  display: "-webkit-box",
  overflow: "hidden",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
};

export const textOneLines = {
  textOverflow: "ellipsis",
  display: "-webkit-box",
  overflow: "hidden",
  "-webkit-line-clamp": "1",
  "-webkit-box-orient": "vertical",
};

export const stickyHeader = {
  position: "sticky",
  top: { md: "45px", xs: "101px" },
  background: BGColor02,
  pt: { md: 2, xs: 1.8 },
  zIndex: 200,
};

export const scrollBarStyle = {
  "&::-webkit-scrollbar": {
    width: "4px",
    // height: "1px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: blueColor04,
    borderRadius: "50px !important",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
    borderRadius: "50px !important",
  },
};
