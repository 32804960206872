import { Box, Button } from "@mui/material";
import React from "react";
import {
  locationBtnBG,
  newPrimaryColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NearMeIcon from "@mui/icons-material/NearMe";

function PickLocationBtn({
  onChooseLocation,
  onPickNearLocation,
  bgColor,
  textColor,
  large,
}) {
  return (
    <Box mb={1}>
      <Button
        sx={{
          ...styles.btn(bgColor || locationBtnBG, textColor || newPrimaryColor),
          ...styles.btnHover(
            textColor || newPrimaryColor,
            bgColor || newPrimaryColor
          ),
          p: 0,
        }}
        variant="contained"
        disableElevation
        onClick={onChooseLocation}
        startIcon={<NearMeIcon />}
      >
        Choose Current Location
      </Button>

      {/* <Button
        sx={{
          ...styles.btn(bgColor || locationBtnBG, textColor || newPrimaryColor),
          ...styles.btnHover(
            textColor || newPrimaryColor,
            bgColor || newPrimaryColor
          ),
          p: large ? '8px 35px' : '5px 25px'
        }}
        variant="contained"
        disableElevation
        onClick={onPickNearLocation}
        startIcon={<LocationOnIcon />}
      >
        Pick Near By Location
      </Button> */}
    </Box>
  );
}

export default PickLocationBtn;

const styles = {
  btn: (bgColor, textcolor) => ({
    // flex: 1,
    width: 'auto',
    background: bgColor,
    fontSize: {md:"13.5px",xs:"13px"},
    color: textcolor,
    // borderRadius: "4px",
    fontWeight: 600,
    // border: "1px solid " + bgColor,
    textTransform: "unset",
    textDecoration: "underline",
  }),

  btnHover: (textcolor, borderColor) => ({
    "&:hover": {
      backgroundColor: whiteColor,
      color: textcolor,
      // border: "1px solid " + borderColor,
      textDecoration: "none",
    },
  }),
};
