import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { blackShade05, blueColor02, blueColor04, mobilePrimary, yellowColor } from "../../../configs/styles/muiThemes";
import { greyColor14 } from "../../../configs/styles/muiThemes";
import { orangeTextColor } from "../../../configs/styles/muiThemes";
import { whiteColor } from "../../../configs/styles/muiThemes";
import { logoImgborder1 } from "../../../configs/styles/muiThemes";

function MarketingInfoCard({ features, slide }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Box
      sx={{
        ...styles.card,
        flexDirection: {
          md: slide === 1 ? "row" : "row-reverse",
          xs: slide === 1 ? "column" : "column-reverse",
        },
      }}
    >
      <Box
        component="img"
        src={
          slide === 1
            ? "https://storage.googleapis.com/bodefaults/businessdashboardweb/affiliates-banner.jpg"
            : "https://storage.googleapis.com/bodefaults/businessdashboardweb/fintech-banner.jpg"
        }
        alt=""
        width={{ xs: "100%", md: "251px" }}
        height="auto"
        // display={"none"}
      />

      <Box
        p={{ xs: slide === 2 ? "15px 15px 0" : "0px 15px 15px", md: "0px" }}
        ml={{ md: slide === 2 ? "20px" : "0px", xs: 0 }}
      >
        {slide === 1 && (
          <Typography
            component="h6"
            fontSize={{xs:14, md:18}}
            fontWeight={600}
            color={blackShade05}
            lineHeight={{xs:'22px', md:'28px'}}
          >
            New market reach through{" "}
            <strong style={{ color: yellowColor }}>
              Influencers & Affiliates
            </strong>
          </Typography>
        )}

        {slide === 2 && (
          <Typography
            component="h6"
            fontSize={{xs:14, md:18}}
            fontWeight={600}
            color={blackShade05}
            lineHeight={{xs:'22px', md:'28px'}}
          >
            Increase{" "}
            <span style={{ color: matches ? yellowColor : mobilePrimary }}>B2B Supply</span> Chain
            Efficiency to reach All India
          </Typography>
        )}

        <Box my={1}>
          {features?.map((item, index) => (
            <Typography
              key={`feature_point_${index}`}
              component="p"
              fontSize={{xs:12.5, md:13}}
              fontWeight={500}
              color={greyColor14}
              lineHeight="20px"
              mb="7px"
              //   sx={{whiteSpace:'pre-line'}}
            >
              &#x2022; {item}
            </Typography>
          ))}
        </Box>

        <Typography
          variant="body1"
          fontSize={12}
          fontWeight={500}
          color={blackShade05}
          display="inline"
        >
          To enrol, please contact{" "}
          <Typography
            variant="body1"
            fontSize={12}
            fontWeight={500}
            color={{xs: mobilePrimary, md:yellowColor}}
            display="inline"
          >
            support@costbo.com
          </Typography>
        </Typography>

        {/* <Button
          sx={{
            fontSize: 12,
            color: orangeTextColor,
            textTransform: "unset",
            textDecoration: "underline",
            p: "0px",
          }}
        >
          Click here
        </Button> */}
      </Box>
    </Box>
  );
}

export default MarketingInfoCard;

const styles = {
  card: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: { xs: "flex-start", md: "center" },
    gap: "15px",
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: "13px",
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    overflow: "hidden",
  },
};
