import { Box, Grid } from "@mui/material";
import React from "react";
import {
  greyColor42,
  greyColor8,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { CATALOG_SUB_BRANCH_HEADER } from "../../../utils/data";

const HeaderInfoRow = () => {
  return (
    <Box
      sx={{
        backgroundColor: greyColor42,
        zIndex: 20,
        borderRadius: "8px 8px 0 0",
      }}
    >
      <Grid container>
        {CATALOG_SUB_BRANCH_HEADER?.map((item, index) => {
          return (
            <Grid
              key={item.title}
              item
              md={item.col}
              borderRight={`1px solid ${whiteColor}`}
            >
              <Box padding="4px 15px">
                <Box sx={{ fontSize: 12, fontWeight: 500 }}>{item?.title}</Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default HeaderInfoRow;

const styles = {
  checkbox: {
    p: 0,
    transform: "scale(1.3)",
    "&.Mui-checked": {
      color: greyColor8,
    },
  },
};
