import { Box, Typography, Avatar } from "@mui/material";
import {
  blackShade42,
  blueColor04,
  greyColor056,
  greyColor057,
  greyColor7,
  mobilePrimary,
  redColor06,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { useState } from "react";
import { textOneLines } from "../../../utils/styles";
import AddIcon from "@mui/icons-material/Add";

const AddManagerCard = ({ onClick, disabled }) => {
  return (
    <Box
      width={"100%"}
      onClick={() => {
        if (disabled) return;

        onClick();
      }}
    >
      <Box
        sx={{ ...styles.card(disabled) }}
        textAlign="center"
        mt="32px"
        position="relative"
      >
        <Box
          position="absolute"
          top={{ md: "-32px", xs: "-26px" }}
          left={{ md: "calc(50%-26px)", md: "calc(50%-21px)" }}
        >
          <Avatar
            sx={{
              width: { md: 52, xs: 42 },
              height: { md: 52, xs: 42 },
              border: "2px solid #fff",
              bgcolor: {
                md: disabled ? greyColor057 : blueColor04,
                xs: disabled ? greyColor057 : mobilePrimary,
              },
            }}
          >
            <AddIcon sx={{ fontSize: "26px" }} />
          </Avatar>
        </Box>

        <Typography
          fontSize={{ md: 14, xs: 12 }}
          fontWeight={600}
          color={{
            md: disabled ? greyColor056 : blueColor04,
            xs: disabled ? greyColor056 : mobilePrimary,
          }}
          lineHeight="20px"
          textAlign="center"
          sx={textOneLines}
          m={disabled ? "6px 0 0 0" : "12px 0 12px 0"}
        >
          Add Manager
        </Typography>
        {disabled && (
          <>
            <Typography
              fontSize={{ md: 12, xs: 11 }}
              fontWeight={500}
              color={redColor06}
              lineHeight={{ md: "22px", xs: "18px" }}
              textAlign="center"
              my={0.4}
            >
              You can add maximum 5 delegates
            </Typography>
            <Typography
              fontSize={{ md: 11.4, xs: 11 }}
              fontWeight={400}
              color={greyColor056}
              lineHeight={{ md: "18px", xs: "18px" }}
              textAlign="center"
            >
              Remove access of existing managers to add new managers
            </Typography>{" "}
          </>
        )}
      </Box>
    </Box>
  );
};

export default AddManagerCard;

const styles = {
  card: (disabled) => ({
    width: "100%",
    display: "flex",
    flexDirection: { md: "column", xs: "column" },
    alignItems: { md: "center", xs: "center" },
    justifyContent: { md: "center", xs: "center" },
    p: { xs: " 15px 10px 6px", md: "20px 10px 10px" },
    backgroundColor: whiteColor,
    // boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    border: {
      md: `1px solid ${disabled ? greyColor057 : blueColor04}`,
      xs: `1px solid ${disabled ? greyColor057 : mobilePrimary}`,
    },
    borderRadius: { xs: "8px", md: "8px" },
    zIndex: 1,
    cursor: "pointer",
  }),
};
