import { Box, Typography } from "@mui/material";
import { loginBgImgUrl } from "../../../configs/Constants";
import React from "react";
import {
  greyColor2,
  greyColor3,
  greyShade06,
  headingColor,
  primaryColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";

function AuthLayoutNew({ children }) {
  let businessFullName = "";
  const businessInfo = "";

  return (
    <Box sx={styles.container}>
      {/* <Box
        sx={{
          
          borderRadius: "16px 16px 0px 0px",
          padding: '20px 15px',
          textAlign: "center",
        }}
      >
        <Box
          component="img"
          alt={businessFullName + " CostBo"}
          width="140px"
          sx={{ objectFit: "contain", borderRadius: 1, mb: "8px" }}
        />
        <Typography
          variant="subtitle1"
          component="h3"
          color={whiteColor}
          textAlign="center"
          fontSize={18}
          lineHeight="30px"
          fontWeight={600}
        >
          Welcome to CostBo
        </Typography>

        <Typography
          variant="subtitle1"
          component="body1"
          color={greyColor2}
          textAlign="center"
          fontSize={13}
          lineHeight="20px"
          fontWeight={400}
          mt="6px !important"
        >
          Future ready, All in One D2C E-Commerce Infra
        </Typography>
      </Box> */}

      <Box sx={{ minHeight: "10vh" }}>{children}</Box>
      {/* <Box
        sx={{
          //   width: { xs: '100%' },
          display: "flex",
          flexDirection: "column",
          padding: "10px 15px 20px",
        }}
      >
        <Typography
          variant="body1"
          component="h4"
          color={greyColor3}
          textAlign="center"
          fontSize={12}
          fontWeight={500}
          //   sx={{ mt: { xs: 0, md: 3 } }}
        >
          Trusted by 1000s of Brands
        </Typography>
      </Box> */}
    </Box>
  );
}

export default AuthLayoutNew;

const styles = {
  container: {
    maxHeight: "95vh",
    backgroundColor: whiteColor,
    borderRadius: "10px",
    padding: { md: "25px", xs: "12px"},

    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "6px",
      // height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: greyShade06,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },
};
