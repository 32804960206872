import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPreferredDiscountsInfo,
  getAllBusinessPreferredDiscountsData,
} from "../../../reducers/preferredDiscount/PreferredDiscountSlice";
import moment from "moment";
import PreferredDiscountCard from "../Card/PreferredDiscountCard";
import InfiniteScroll from "react-infinite-scroll-component";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import PreferredEmptyCard from "../Card/PreferredEmptyCard";
import EmptyCard from "../../../components/Cards/EmptyCard";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";

function AllPreferredDiscountContent({
  openDeleteAlter,
  searchData,
  searchStatus,
  isLoading,
}) {
  const dispatch = useDispatch();

  const { allPreferredDiscounts, allPreferredDiscountsLoader } = useSelector(
    (state) => state.businessPreferredDiscounts
  );
  const [discountList, setDiscountList] = useState([]);

  const getDiscountData = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      getAllBusinessPreferredDiscountsData({
        businessId,
        status: "all",
        pageNo: allPreferredDiscounts?.pageNo + 1,
      })
    );
  };
  useEffect(() => {
    if (allPreferredDiscounts) {
      setDiscountList(allPreferredDiscounts);
    }
    return () => {};
  }, [allPreferredDiscounts]);

  useEffect(() => {
    if (searchStatus) {
      setDiscountList(searchData || []);
      console.log("searchData", searchData);
    } else {
      setDiscountList(allPreferredDiscounts);
    }

    return () => {};
  }, [searchData, searchStatus]);

  useEffect(() => {
    getDiscountData();
    return () => {
      dispatch(clearPreferredDiscountsInfo());
    };
  }, []);

  // useEffect(() => {
  //   // console.log("RAVI_CODE_SUCCESS:", allPreferredDiscounts);
  //   // console.log(
  //   //   "RAVI_CODE_HAS_MORE",
  //   //   Math.round(
  //   //     Number(allPreferredDiscounts?.totalCount) /
  //   //       Number(allPreferredDiscounts?.pageSize)
  //   //   ) !== Number(allPreferredDiscounts?.pageNo)
  //   // );
  //   // if (
  //   //   discountList?.pageNo * discountList?.pageSize >
  //   //   discountList?.details?.length
  //   // ){
  //   //   set
  //   // }
  //   console.log(
  //     discountList?.pageNo * discountList?.pageSize >
  //       discountList?.details?.length
  //   );
  //   console.log(discountList?.details?.length);
  //   return () => {};
  // }, [discountList]);

  return (
    <Box>
      {/* json:{JSON.stringify(discountList?.details)} */}
      {/* {!allPreferredDiscountsLoader &&
        discountList?.details?.length == 0 &&
        searchStatus === false && <PreferredEmptyCard />} */}
      {!allPreferredDiscountsLoader &&
        (discountList?.details?.length == 0 || discountList?.length == 0) &&
        searchStatus === true && (
          <EmptyCard
            icon={<DiscountOutlinedIcon sx={{ fontSize: "24px" }} />}
            msg="No Personalized discounts assigned to this number"
          />
        )}
      {allPreferredDiscountsLoader && <ThreeDotLoader />}

      {!isLoading &&
      !allPreferredDiscountsLoader &&
      discountList?.details?.length > 0 &&
      searchStatus === true ? (
        <Grid container spacing={2} mt={{ xs: 0, md: 1 }}>
          {discountList?.details?.map((item, index) => {
            const endDate = item?.pointEndDate;
            const currentDate = moment(endDate).valueOf();
            const statusText = item?.rewardStatus;

            const userInfo = discountList?.profileInfo?.filter(
              (profile) => item.rewardsProfileId === profile.profileId
            );

            let username = "";
            let profilePic = "";

            if (userInfo?.length > 0) {
              username = userInfo?.[0]?.name;
              profilePic = userInfo?.[0]?.photoUrl;
            }

            return (
              <Grid item xs={12} md={3} mt={4}>
                <PreferredDiscountCard
                  customerName={username}
                  customerNumber={item?.rewardsPhoneNo.replace(
                    /(\d{2})(\d{0})/,
                    "$1 $2"
                  )}
                  totalDiscount={item?.pointValue}
                  startDate={moment(item?.pointStartDate).format("MMM Do YYYY")}
                  endDate={moment(endDate).format("MMM Do YYYY")}
                  percentage={item?.pointPercentage}
                  minpurchase={item?.minimumAmount}
                  statusText={statusText}
                  profileImage={profilePic}
                  item={item}
                  onDeleteClick={() => {
                    openDeleteAlter(item);
                    getDiscountData();
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        // searchStatus === false &&
        <InfiniteScroll
          dataLength={discountList?.details?.length || 0}
          next={getDiscountData}
          hasMore={
            Math.round(
              Number(discountList?.totalCount) / Number(discountList?.pageSize)
            ) >= Number(discountList?.pageNo)
          }
          loader={
            <Box>
              <ThreeDotLoader />
            </Box>
          }
        >
          <Grid container spacing={2} >
            {discountList?.details?.map((item, index) => {
              const endDate = item?.pointEndDate;
              const currentDate = moment(endDate).valueOf();
              const statusText = item?.rewardStatus;

              const userInfo = discountList?.profileInfo?.filter(
                (profile) => item.rewardsProfileId === profile.profileId
              );

              let username = "";
              let profilePic = "";

              if (userInfo?.length > 0) {
                username = userInfo?.[0]?.name;
                profilePic = userInfo?.[0]?.photoUrl;
              }

              return (
                <Grid item xs={12} md={3} mt={4}>
                  <PreferredDiscountCard
                    customerName={username}
                    customerNumber={item?.rewardsPhoneNo.replace(
                      /(\d{2})(\d{0})/,
                      "$1 $2"
                    )}
                    totalDiscount={item?.pointValue}
                    startDate={moment(item?.pointStartDate).format(
                      "MMM Do YYYY"
                    )}
                    endDate={moment(endDate).format("MMM Do YYYY")}
                    percentage={item?.pointPercentage}
                    minpurchase={item?.minimumAmount}
                    statusText={statusText}
                    profileImage={profilePic}
                    item={item}
                    onDeleteClick={() => {
                      openDeleteAlter(item);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </InfiniteScroll>
      )}
    </Box>
  );
}

export default AllPreferredDiscountContent;
