import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { BGColor02 } from "../../../configs/styles/muiThemes";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveBusinessInfo,
  getCommerceInfo,
} from "../../../reducers/businessPreview/businessPreviewSlice";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import OndcStoreLinkContent from "./OndcStoreLinkContent";
import ThreeDotLoader from "../../../components/ThreeDotLoader";

function OndcStoreLinks() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { activeBusinessInfo, activeBusinessInfoLoader, commerceInfo } =
    useSelector((state) => state.businessPreview);

  const dispatch = useDispatch();

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }

      if (!commerceInfo) {
        dispatch(getCommerceInfo(businessId));
      }
    }
    return () => {};
  }, []);

  return (
    activeBusinessInfo && (
      <Box sx={styles.container}>
        <DashboardLayout activeMenu="shareLinks" activeSubMenu="ondcStoreLinks">
          <Box sx={{ minHeight:'calc(100vh - 86px)'}}>
            {activeBusinessInfo ? (
              <OndcStoreLinkContent
                businessData={activeBusinessInfo}
                commerceInfo={commerceInfo}
              />
            ) : (
              <ThreeDotLoader />
            )}
          </Box>
        </DashboardLayout>
      </Box>
    )
  );
}

export default OndcStoreLinks;

const styles = {
  container: {
    backgroundColor: BGColor02,
  },
};
