import { Box, Typography } from "@mui/material";
import React from "react";
import CatalogActionBtns from "../../../components/Buttons/CatalogActionBtns";
import CategoryPreview from "../Forms/CategoryPreview";
import AddProductImage from "../Forms/AddProductImage";
import AddPriceDetails from "../Forms/AddPriceDetails";
import {
  blackColor,
  blueColor04,
  errorTextColor,
} from "../../../configs/styles/muiThemes";
import { stickyHeader } from "../../../utils/styles";
import BreadcrumbSection from "../../../components/Breadcrumb/BreadcrumbSection";
import { push } from "firebase/database";

function ProductImageSpecificationTab({
  categoryData,
  productInfo,
  productImages,
  setProductImages,
  productImgUpdate,
  setProductImgUpdate,

  priceInfo,
  setPriceInfo,
  availabilityInfo,
  setAvailabilityInfo,
  measurementInfo,
  setMeasurementInfo,
  productSpec,
  setProductSpec,
  onValueChange,
  onNextClick,
  onBackClick,
  isOndcEnabled,
  setDeleteImgList,
  deleteImgList,
  title,
  titleFrom,
  links,
  handleCancel,
  pageError,

  isEdit,
}) {
  // Update product image update flag
  const onProductImageUpdate = (data) => {
    const imgFlagList = [...productImgUpdate];

    let index = -1;

    imgFlagList.forEach((item, index) => {
      index = data?.index === item?.index ? item?.index : -1;
    });

    if (index === -1) {
      imgFlagList.push(data);
    } else {
      imgFlagList.splice(index, 1);
    }

    setProductImgUpdate(imgFlagList);
  };

  const deleteProductPhoto = async (imageId) => {
    console.log("imageId__:", imageId);
    console.log("productImgUpdate__:", productImgUpdate);

    const updatedList = [];
    productImgUpdate?.forEach((item, index) => {
      if (item?.dataURL === imageId || item?.docURL === imageId) {
        if (imageId === item?.docURL) {
          console.log("Edit photo");
          setDeleteImgList([...deleteImgList, `${item?.docType || ""}`]);
        }
      } else {
        updatedList?.push(item);
      }
    });

    console.log("updatedList___", updatedList);

    setProductImgUpdate(updatedList);
  };
  return (
    <Box mt={{ md: 0, xs: 1 }}>
      <Box
        sx={{
          ...stickyHeader,
          display: { md: "block", xs: "none" },
          pb: 1,
        }}
      >
        {console.log("DeleteList__", deleteImgList)}
        <BreadcrumbSection links={links} />
        <Typography
          component="h6"
          fontSize={{ xs: 15, md: 17 }}
          color={blackColor}
          fontWeight={{ xs: 600, md: 600 }}
          lineHeight="20px"
          display="inline"
        >
          {titleFrom && <span style={{ color: blueColor04 }}>{titleFrom}</span>}
          {title}
        </Typography>
        <CategoryPreview
          categoryData={categoryData}
          productInfo={productInfo}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        <CategoryPreview
          categoryData={categoryData}
          productInfo={productInfo}
        />
      </Box>

      <AddProductImage
        productImages={productImages}
        setProductImages={setProductImages}
        productImgUpdate={productImgUpdate}
        setProductImgUpdate={setProductImgUpdate}
        onProductImageUpdate={onProductImageUpdate}
        onValueChange={
          (name, value) =>
            setProductImages((prevState) => ({
              ...prevState,
              [name]: value,
              errors: null,
            }))
          // onValueChange(name, value, productImages, setProductImages)
        }
        onDeleteProductPhoto={(imgURL) => {
          deleteProductPhoto(imgURL);
        }}
      />

      <AddPriceDetails
        priceInfo={priceInfo}
        onPriceValueChange={(name, value) =>
          onValueChange(name, value, priceInfo, setPriceInfo)
        }
        availabilityInfo={availabilityInfo}
        onAvailabilityValueChange={(name, value) =>
          onValueChange(name, value, availabilityInfo, setAvailabilityInfo)
        }
        measurementInfo={measurementInfo}
        onMeasurementValueChange={(name, value) =>
          onValueChange(name, value, measurementInfo, setMeasurementInfo)
        }
        productSpec={productSpec}
        onProductSpecValueChange={(name, value) =>
          onValueChange(name, value, productSpec, setProductSpec)
        }
        isEdit={isEdit}
      />
      <Box>
        <CatalogActionBtns
          nextBtnText={isOndcEnabled ? "NEXT" : "PREVIEW & SUBMIT"}
          showBackBtn
          hideSaveBtn
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          onCancelClick={handleCancel}
        />
        {pageError ? (
          <Typography
            fontSize={12}
            fontWeight={500}
            mt={1}
            textAlign="right"
            color={errorTextColor}
          >
            Please fix the errors above to proceed
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
}

export default ProductImageSpecificationTab;
