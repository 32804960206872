import moment from "moment";
import {
  blackShade05,
  copyBtnColor,
  deleteBtnColor,
  greenBgShade01,
  greenShade01,
  greenShade04,
  greyColor051,
  greyColor8,
  inactiveTextColor,
  lightBlueColor01,
  orangeBgShade01,
  orangeColor02,
  orangeShade01,
  orangeStatusColor,
  outOfStockColor,
  perpleColor1,
  redBgShade01,
  redShade03,
  voiletColor01,
  voiletShade01,
  voiletShade02,
} from "../configs/styles/muiThemes";
import { DEFAULT_COUNTRY } from "./data";
import { getCodeByName } from "./validationHelper";

export const allowOnlyEnglish = (inputValue) => {
  // check if the input contains only English characters
  const sanitizedValue = inputValue.replace(
    /[^a-zA-Z0-9\s!@#$%^&*()-_+=<>?.,:;'"{}[\]|/\\]/g,
    ""
  );

  return sanitizedValue;
};

export const addThousandsSeparator = (number, removeDecimal = false) => {
  if (!number) return "0";
  const num_parts = Number(number).toFixed(2).toString().split(".");
  num_parts[0] = num_parts[0].replace(
    /\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g,
    ","
  );

  const result = num_parts.join(".");
  return removeDecimal ? result.slice(0, result.length - 3) : result;
};

export const mmToCm = (mm) => {
  const inCm = (parseFloat(mm) / 10).toFixed(3);
  return isNaN(inCm) ? 0 : inCm;
};

export const mmToInches = (mm) => {
  const inInches = (parseFloat(mm) / 25.4).toFixed(3);
  return isNaN(inInches) ? 0 : inInches;
};

export const getVolumetricWeightInKg = (lengthInMm, widthInMm, heightInMm) => {
  const volumetricFactor = 5000;
  const volumetricWeightKg =
    (parseFloat(lengthInMm) * parseFloat(widthInMm) * parseFloat(heightInMm)) /
    volumetricFactor;
  return isNaN(volumetricWeightKg) ? 0 : volumetricWeightKg.toFixed(5);
};

// Order delivery Type

export const getOrderDeliveryTypeText = (type) => {
  switch (type?.toLowerCase()) {
    case "business":
      return "Business Delivery";
    case "delhivery":
      return "Automated Delivery";
    case "Delhivery":
      return "Automated Delivery";
    case "telyport":
      return "Automated Hyperlocal";
    case "delhiveryb2b":
      return "Automated B2B";

    default:
      return "";
  }
};

export function insertSpaceAtPosition(inputString, position) {
  const firstPart = inputString?.slice(0, position);
  const secondPart = inputString?.slice(position);

  return firstPart + " " + secondPart;
}

// returns allow only numbers and 2 digits after decimal point
export const formatNumberOnlyNumber = (value) => {
  return value
    ?.replace(/[^\d.]/g, "")
    ?.replace(/^0+(?=\d)/, "")
    ?.replace(/^(\d*\.\d{0,2}).*/, "$1");
};

// Get milliseconds of specified date and time
export const formatDateTomilliseconds = (date, time) => {
  const inputDate = `${date} ${time}`;
  const format = "YYYY-MM-DD HH:mm:ss";

  return moment(inputDate, format)?.valueOf();
};

export const updateApproveList = (data) => {
  return [
    {
      label: "Order Received",
      subLabel:
        moment(data?.orderCreatedOn).format("DD-MMM-YYYY [at] hh:mm A") || "",
      status: "active",
    },
    {
      label: "Order Approved",
      subLabel: data?.orderApprovedOn
        ? moment(data?.orderApprovedOn).format("DD-MMM-YYYY [at] hh:mm A")
        : "",
      status: "active",
    },
    {
      label: "Order Shipped",
      btn: true,
      status:
        data?.orderStatus == "orderdelivered" ||
        data?.orderStatus == "ordershipped"
          ? "active"
          : "",
    },
    {
      label: "Order Delivered",
      status: data?.orderStatus == "orderdelivered" ? "active" : "",
    },
  ];
};
// Get Bg Color by Order Type
export const getBGColorByOrderStatus = (status) => {
  switch (status) {
    case "ordercreated":
      return orangeStatusColor;
    case "orderapproved":
      return blackShade05;
    case "ordershipped":
      return lightBlueColor01;
    case "orderdelivered":
      return lightBlueColor01;
    case "bordercanceled":
      return outOfStockColor;

    default:
      return "";
  }
};

// Get Order Status Test by Order Status
export const getOrderStatusText = (status) => {
  switch (status) {
    case "yes":
      return "Yes";
    case "no":
      return "No";
    case "no":
      return "No";

    default:
      return "";
  }
};

// Function to check if a string is NOT present in any array
export function isStringNotPresentInArray(arrayOfObjects, searchString) {
  for (const obj of arrayOfObjects) {
    // Check if the searchString is present in the 'types' array of the current object
    if (obj?.types?.includes(searchString)) {
      return false; // String found
    }
  }
  return true; // String not found
}

export const capitalizeFirstCharacter = (str) => {
  if (typeof str !== "string" || str.length === 0) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getOrderType = (orderData) => {
  if (!orderData?.cartType && !orderData?.ondcCart) {
    return {
      label: "Website Order",
      orderType: "websiteOrder",
      color: orangeColor02,
    };
  }
  if (!orderData?.cartType && orderData?.ondcCart) {
    return {
      label: "ONDC Order",
      orderType: "ondcOrder",
      color: copyBtnColor,
    };
  }
  if (orderData?.cartType?.toLowerCase() === "businesspaycart") {
    return {
      label: "Easy Cart Order",
      orderType: "easyCartOrder",
      color: inactiveTextColor,
    };
  }
  if (orderData?.cartType?.toLowerCase() === "influencerpaycart") {
    return {
      label: "Influencer Cart Order",
      orderType: "influencerCartOrder",
      color: perpleColor1,
    };
  }

  return {
    label: "N/A",
    orderType: "",
    color: greyColor8,
  };
};

export const getONDCOrderType = (orderData) => {
  if (!orderData?.ondcIssue) {
    return {
      label: "Website Order",
      orderType: "websiteOrder",
      color: orangeShade01,
    };
  }
  if (orderData?.ondcIssue) {
    return {
      label: "ONDC Order",
      orderType: "ondcOrder",
      color: greenShade01,
    };
  }
  if (orderData?.cartType?.toLowerCase() === "businesspaycart") {
    return {
      label: "Easy Cart Order",
      orderType: "easyCartOrder",
      color: voiletShade01,
    };
  }
  if (orderData?.cartType?.toLowerCase() === "influencerpaycart") {
    return {
      label: "Influencer Cart Order",
      orderType: "influencerCartOrder",
      color: perpleColor1,
    };
  }

  return {
    label: "N/A",
    orderType: "",
    color: greyColor8,
  };
};

export const sortByValue = (a, b) => {
  const valueA = a.value.toUpperCase();
  const valueB = b.value.toUpperCase();

  if (valueA < valueB) {
    return -1;
  }
  if (valueA > valueB) {
    return 1;
  }

  return 0; // Names are equal
};

export const capitalizeString = (str) => {
  // Capitalize the first letter and concatenate the rest of the string
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const getProductCodeMaxLength = (value) => {
  switch (value) {
    case "EAN":
      return 13;
    case "GTIN":
      return 12;
    case "HSN":
      return 8;
    case "ISBN":
      return 13;
    case "OTHERS":
      return 13;

    default:
      return 13;
  }
};

export const getTimeLabel = (value) => {
  switch (value) {
    case "M":
      return "Minutes";
    case "H":
      return "Hours";
    case "D":
      return "Days";

    default:
      return "";
  }
};

export const getCancelledBy = (value) => {
  switch (value) {
    case "cordercanceled":
      return "Canceled by Customer";
    case "bordercanceled":
      return "Canceled by Business";
  }
};

export function isStringValidDateFormat(inputString) {
  // Check if the input is "N/A"
  if (inputString === "N/A") {
    return true;
  }

  // Check if the input follows the format MM/YYYY using regular expression
  const regex = /^(0[1-9]|1[0-2])\/\d{4}$/;
  return regex.test(inputString);
}

export const getColorChip = (status) => {
  switch (status) {
    case "processing":
      return {
        color: orangeShade01,
        bgColor: orangeBgShade01,
      };
    case "ordercreated":
      return {
        color: orangeShade01,
        bgColor: orangeBgShade01,
      };
    case "PROCESSING":
      return {
        color: orangeShade01,
        bgColor: orangeBgShade01,
      };
    case "orderapproved":
      return {
        color: orangeShade01,
        bgColor: orangeBgShade01,
      };
    case "pending":
      return {
        color: orangeShade01,
        bgColor: orangeBgShade01,
      };
    case "overdue":
      return {
        color: redShade03,
        bgColor: redBgShade01,
      };
    case "resolved":
      return {
        color: voiletColor01,
        bgColor: voiletShade02,
      };
    case "bordercanceled":
      return {
        color: redShade03,
        bgColor: redBgShade01,
      };
    case "cordercanceled":
      return {
        color: redShade03,
        bgColor: redBgShade01,
      };
    case "No recent changes":
      return {
        color: redShade03,
        bgColor: "",
      };
    case "open":
      return {
        color: greenShade04,
        bgColor: greenBgShade01,
      };
    case "OPEN":
      return {
        color: greenShade04,
        bgColor: greenBgShade01,
      };
    case "CLOSED":
      return {
        color: redShade03,
        bgColor: redBgShade01,
      };
    case "ordershipped":
      return {
        color: greenShade04,
        bgColor: greenBgShade01,
      };
    case "no":
      return {
        color: redShade03,
        bgColor: redBgShade01,
      };
    case "orderdelivered":
      return {
        color: greenShade04,
        bgColor: greenBgShade01,
      };
    case "completed":
      return {
        color: greenShade04,
        bgColor: greenBgShade01,
      };
    case "yes":
      return {
        color: greenShade04,
        bgColor: greenBgShade01,
      };
    case "close":
      return {
        color: greyColor8,
        bgColor: "rgba(0,0,0,0.1)",
      };

    default:
      return "";
  }
};

export function getFirstStringBeforeComma(str) {
  // Split the string by commas
  const parts = str.split(",");

  // If there is at least one comma, return the first part
  if (parts.length > 1) {
    return parts[0];
  } else {
    // If no comma found, return the original string
    return str;
  }
}

export const getSelectedCountryByCode = (code, allCountries) => {
  if (!code) return DEFAULT_COUNTRY;

  const selectedCountry = allCountries?.filter(
    (item) => item.ondcCountryCode === code
  );

  if (selectedCountry.length > 0) {
    return selectedCountry[0];
  } else {
    return DEFAULT_COUNTRY;
  }
};

export const getSelectedCountryCodeByName = (name, allCountries) => {
  if (!name) return DEFAULT_COUNTRY?.code;

  const selectedCountry = allCountries?.filter((item) => item.name === name);

  if (selectedCountry.length > 0) {
    return selectedCountry[0]?.ondcCountryCode;
  } else {
    return DEFAULT_COUNTRY?.ondcCountryCode;
  }
};

export function getMatchingObjects(firstArray, secondArray) {
  return secondArray.filter((obj2) => {
    return firstArray.some((obj1) => obj1.profileId === obj2.profileId);
  });
}
export const getIssueTypeText = (type) => {
  switch (type) {
    case "ITM01":
      return "Missing items";
    case "ITM02":
      return "Quantity issue";
    case "ITM03":
      return "Item mismatch";
    case "ITM04":
      return "Quality issue";
    case "ITM05":
      return "Expired item";

    case "FLM01":
      return "Wrong delivery address";
    case "FLM02":
      return "Delay in delivery";
    case "FLM03":
      return "Delayed delivery";
    case "FLM04":
      return "Packing";
    case "FLM05":
      return "Buyer not found";
    case "FLM06":
      return "seller not found";
    case "FLM07":
      return "Package info mismatch";
    case "FLM08":
      return "Incorrectly marked as delivered";

    default:
      return "";
  }
};

export const getStatusColor = (status) => {
  switch (status) {
    case "new":
      return voiletShade01;
    case "open":
      return voiletShade01;
    case "processing":
      return orangeShade01;
    case "completed":
      return copyBtnColor;
    case "resolved":
      return copyBtnColor;
    case "closed":
      return copyBtnColor;
    case "sla not met":
      return deleteBtnColor;
    case "sla met":
      return copyBtnColor;
    case "Item Mismatch":
      return deleteBtnColor;

    default:
      return "";
  }
};

export const getStatusText = (inputDate, durationString) => {
  const originalDate = moment(inputDate);
  const specifiedDate = originalDate.add(moment.duration(durationString));
  const currentDate = moment();
  console.log("DATES", specifiedDate, currentDate);
  return specifiedDate.isBefore(currentDate) ? "OverDue" : "Pending";
};

export const getStatusTextSLA = (inputDate, durationString) => {
  const originalDate = moment(inputDate);
  const specifiedDate = originalDate.add(moment.duration(durationString));
  const currentDate = moment();
  console.log("DATES", specifiedDate, currentDate);
  return specifiedDate.isBefore(currentDate) ? "SLA not met" : "SLA met";
};

export const getStatusTextSLAEX = (inputDate, durationString, exDate) => {
  const originalDate = moment(inputDate);
  const specifiedDate = originalDate.add(moment.duration(durationString));
  const currentDate = moment(exDate?.updated_at);
  console.log("DATES_SLA", inputDate, originalDate, currentDate);
  return specifiedDate.isBefore(currentDate) ? "SLA not met" : "SLA met";
};

// Resolved SLA Test
export const getResolvedSLAText = (data, type) => {
  if (
    data?.message?.issue?.status === "RESOLVED" ||
    data?.message?.issue?.status === "CLOSED"
  ) {
    if (type === "responceSla") {
      console.log("getResolvedSLAText___RESPONSE:", data);

      const createdOn = data?.createdOn ? moment(data?.createdOn) : null;

      const expectedResTime =
        data?.message?.issue?.expected_response_time?.duration;

      const respondentProcessData =
        data?.message?.issue?.issue_actions?.respondent_actions?.find(
          (item, index) => {
            return item.respondent_action === "PROCESSING" && item.updated_at;
          }
        );

      const expResTime = createdOn.add(moment.duration(expectedResTime));
      const resUpdatedAt = respondentProcessData
        ? moment(respondentProcessData?.updated_at)
        : null;

      console.log("getResolvedSLAText___createdOn:", createdOn);
      console.log("getResolvedSLAText___expectedResTime:", expectedResTime);
      console.log(
        "getResolvedSLAText___respondentProcessData:",
        respondentProcessData
      );
      console.log(
        "getResolvedSLAText___expResTime:",
        expResTime?.format("DD/MM/YYYY hh:mm A")
      );
      console.log(
        "getResolvedSLAText___resUpdatedAt:",
        resUpdatedAt?.format("DD/MM/YYYY hh:mm A")
      );

      const isResSLAMet = resUpdatedAt?.isBefore(expResTime);
      console.log(
        "getResolvedSLAText___isResSLAMet:",
        isResSLAMet ? "SLA Met" : "SLA Not Met"
      );

      return isResSLAMet ? "SLA Met" : "SLA Not Met";
    }

    if (type === "resolveSla") {
      console.log("getResolvedSLAText___RESOLUTION:", data);

      const createdOn = data?.createdOn ? moment(data?.createdOn) : null;

      const expectedResolutionTime =
        data?.message?.issue?.expected_resolution_time?.duration;

      const respondentResolvedData =
        data?.message?.issue?.issue_actions?.respondent_actions?.find(
          (item, index) => {
            return item.respondent_action === "RESOLVED" && item.updated_at;
          }
        );

      const expResolutionTime = createdOn.add(
        moment.duration(expectedResolutionTime)
      );

      const resolutionUpdatedAt = respondentResolvedData
        ? moment(respondentResolvedData?.updated_at)
        : null;

      console.log("getResolvedSLAText___createdOn:", createdOn);
      console.log("getResolvedSLAText___expResolutionTime:", expResolutionTime);
      console.log(
        "getResolvedSLAText___respondentResolvedData:",
        respondentResolvedData
      );

      console.log(
        "getResolvedSLAText___expResolutionTime:",
        expResolutionTime?.format("DD/MM/YYYY hh:mm A")
      );
      console.log(
        "getResolvedSLAText___resUpdatedAt:",
        resolutionUpdatedAt?.format("DD/MM/YYYY hh:mm A")
      );

      const isSLAMet = resolutionUpdatedAt?.isBefore(expResolutionTime);
      console.log(
        "getResolvedSLAText___isResSLAMet:",
        isSLAMet ? "SLA Met" : "SLA Not Met"
      );

      return isSLAMet ? "SLA Met" : "SLA Not Met";
    }

    // console.log("getResolvedSLAText___respondentResolvedData:", respondentResolvedData);
  }
};

export const getOpenStatusText = (inputDate, durationString) => {
  const originalDate = moment(inputDate);
  const currentDate = moment(durationString);
  console.log("DATES", currentDate);
  return originalDate.isBefore(currentDate) ? "OverDue" : "Pending";
};
export const getIssueStatusText = (inputDate, campareDate) => {
  const originalDate = moment(inputDate);
  const currentDate = moment(campareDate);
  console.log("DATES_STATUS", originalDate, currentDate);
  return originalDate.isBefore(currentDate) ? "SLA not met" : "SLA met";
};

export const addDurationToDate = (inputDate, durationString) => {
  // Parse the input date using Moment.js
  const originalDate = moment(inputDate);

  // Add the specified duration
  const newDate = originalDate.add(moment.duration(durationString));

  // Return the formatted new date
  return newDate.format("DD MMM, hh:mm A");
};

export const formatDate = (date) => {
  return moment(date)?.format("DD MMM, hh:mm A");
};

export function formatDuration(duration) {
  const parsedDuration = moment.duration(duration);
  let formattedString = "";

  if (parsedDuration.years() > 0) {
    formattedString += `${parsedDuration.years()} Years `;
  }

  if (parsedDuration.months() > 0) {
    formattedString += `${parsedDuration.months()} Months `;
  }

  if (parsedDuration.days() > 0) {
    formattedString += `${parsedDuration.days()} ${
      parsedDuration.days() == 1 ? "Day" : "Days"
    } `;
  }

  if (parsedDuration.hours() > 0) {
    formattedString += `${parsedDuration.hours()} Hr `;
  }

  if (parsedDuration.minutes() > 0) {
    formattedString += `${parsedDuration.minutes()} Min `;
  }

  if (parsedDuration.seconds() > 0) {
    formattedString += `${parsedDuration.seconds()} Sec `;
  }

  return formattedString.trim();
}

export const DurationTimeDate = (inputDate, durationString) => {
  const createdOn1 = moment.utc(inputDate).local().add(durationString);
  const createdOn = createdOn1.format("DD MMM YYYY, hh:mm A");
  const current = moment().format("DD MMM YYYY, hh:mm A");
  const isCreatedOnBeforeCurrent = moment(
    createdOn,
    "DD MMM YYYY, hh:mm A"
  ).isBefore(moment(current, "DD MMM YYYY, hh:mm A"));
  return moment(createdOn, "DD MMM YYYY, hh:mm A").isBefore(
    moment(current, "DD MMM YYYY, hh:mm A")
  );
};

// Generates 5 digits alphanumeric string
export const generateRandomString = () => {
  const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  for (let i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};
export const extractNames = (data) => {
  return data.map((item) => JSON.parse(item)[0]);
};

export const extractNameCodePairs = (data) => {
  return data.map((item) => {
    const [name, color] = JSON.parse(item);
    return [name, color];
  });
};

export const getListToLabel = (inputArray) => {
  let list = [];

  inputArray.forEach((item) => {
    if (item.dbObjectName == "list") {
      list.push({
        code: item?.code,
        link:
          item?.type === "codeValue"
            ? getCodeByName(item?.nameCodes, item?.inputValue)
            : item?.inputValue,
      });
      return;
    }
  });

  return list;
};

export const getValueByCode = (arrayOfObjects, code) => {
  console.log("getValueByCode", arrayOfObjects, code);
  for (let i = 0; i < arrayOfObjects?.length; i++) {
    if (arrayOfObjects[i].code === code) {
      return arrayOfObjects[i].value;
    }
  }
  // Return null if no match is found
  return null;
};

export const countMatch = (pattern) => {
  // Extract the part of the pattern that represents the number of repetitions
  const repetitionMatch = pattern.match(/\{\d+\}/);

  if (repetitionMatch) {
    // Extract the number of repetitions from the match
    const repetitionCount = parseInt(repetitionMatch[0].slice(1, -1));

    // Return the number of repetitions
    return repetitionCount;
  } else {
    // If no repetition count found, return 0
    return 0;
  }
};

export const findLabelBySelectedValue = (inputArray) => {
  // Loop through the array
  for (let i = 0; i < inputArray?.length; i++) {
    // Check if the value of options.selected is 'yes'
    if (inputArray[i]?.options?.selected === inputArray[i]?.value) {
      // Return the label of the object
      return inputArray[i]?.label;
    }
  }
  // If no match is found, return null or handle the case accordingly
  return null;
};

export const getProductCodeLength = (productCodeList, type) => {
  // Extract the part of the pattern that represents the number of repetitions
  const productCodeLookup = productCodeList?.find(
    (obj) => obj?.prodictCode === type
  );

  const pattern = productCodeLookup?.validation?.[0]
    ?.replace(/d/g, "\\d")
    ?.replace(/\./g, "\\.");

  const repetitionMatch = pattern?.match(/\{\d+\}/);

  if (repetitionMatch) {
    // Extract the number of repetitions from the match
    const repetitionCount = parseInt(repetitionMatch[0]?.slice(1, -1));

    // Return the number of repetitions
    return repetitionCount;
  } else {
    // If no repetition count found, return 0
    return 0;
  }
};

export const findIndexById = (arrayOfObjects, id) => {
  // Iterate through the array of objects
  for (let i = 0; i < arrayOfObjects.length; i++) {
    // Check if the current object's id matches the specified id
    if (arrayOfObjects[i].id === id) {
      // Return the index of the matching object
      return i;
    }
  }
  // If no matching object is found, return -1
  return -1;
};

// Remove \n from the string
export const removeNewlines = (text) => {
  return text.replace(/\n/g, "");
};

export const dynamicSort = (property) => {
  return (a, b) => {
    if (a[property] < b[property]) {
      return -1;
    } else if (a[property] > b[property]) {
      return 1;
    } else {
      return 0;
    }
  };
};

export const getOptionsForDependentCode = (data, targetKey) => {
  // Find the object with the matching key
  const matchingItem = data?.find((item) => item.key === targetKey);

  // Return the options array for the matching item, or an empty array if no match is found
  return matchingItem ? matchingItem.options : [];
};
