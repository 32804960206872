import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { BGColor02 } from "../../../configs/styles/muiThemes";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveBusinessInfo,
  getCommerceInfo,
} from "../../../reducers/businessPreview/businessPreviewSlice";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import DirectShareLinkContent from "./DirectShareLinkContent";

function DirectShareLink() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { activeBusinessInfo, commerceInfo } = useSelector(
    (state) => state.businessPreview
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }

      if (!commerceInfo) {
        dispatch(getCommerceInfo(businessId));
      }
    }
    return () => {};
  }, []);

  return (
    activeBusinessInfo && (
      <Box sx={styles.container}>
        <DashboardLayout
          activeMenu="shareLinks"
          activeSubMenu="directStoreLink"
        >
          {activeBusinessInfo ? (
            <DirectShareLinkContent businessData={activeBusinessInfo} />
          ) : (
            <ThreeDotLoader />
          )}
        </DashboardLayout>
      </Box>
    )
  );
}

export default DirectShareLink;

const styles = {
  container: {
    backgroundColor: BGColor02,
  },
};
