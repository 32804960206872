import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  blackShade05,
  blueColor04,
  deleteBtnColor,
  greyColor6,
  greyColor8,
  greyShade81,
  mobilePrimary,
  whiteColor,
} from "../../configs/styles/muiThemes";

function TimeScheduleStep({
  hideLine,
  isShipmentLabel,
  hideNode,
  label,
  subLabel,
  btn,
  msg,
  handleResend,
  successMsg,
  success,
  error,
  loading,
  disableButton,
  subLabel1,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Box
      width={{
        md: isShipmentLabel ? "100%" : hideLine ? "500px" : "230px",
        xs: "100%",
      }}
      sx={{
        display: "flex",
        flexDirection: { md: "column", xs: "row" },
        gap: { xs: 1, md: 0 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
        }}
      >
        {!hideNode && <Box sx={styles.stepNode} />}

        {!hideLine && (
          <Box
            sx={{
              border: "1px dashed #a2aebe",
              width: { md: "230px", xs: "1px" },
              height: { md: "1px", xs: "100px" },
            }}
          />
        )}
      </Box>
      <Box width={"100%"}>
        <Typography sx={styles.label} mt={{ md: 1, xs: -0.5 }}>
          {label}
        </Typography>
        <Typography sx={styles.subLabel}>{subLabel}</Typography>
        <Typography sx={styles.subLabel}>{subLabel1}</Typography>
        <Typography sx={styles.msg}>{msg}</Typography>
        {btn && (
          <Button
            sx={styles.resendBtn}
            onClick={handleResend}
            disabled={loading || disableButton}
          >
            Resend
          </Button>
        )}
        {success && (
          <Typography sx={{ ...styles.msg, color: "green" }}>
            {successMsg}
          </Typography>
        )}
        {error && (
          <Typography sx={{ ...styles.msg, color: deleteBtnColor }}>
            Shipping Label is not available
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default TimeScheduleStep;

const styles = {
  stepNode: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    backgroundColor: greyShade81,
  },
  label: {
    color: blackShade05,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "23px",
  },
  subLabel: {
    color: greyColor6,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "18px",
    mt: 0.3,
  },
  msg: {
    color: deleteBtnColor,
    mt: "4px",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "18px",
  },
  resendBtn: {
    padding: "5px 24px",
    fontSize: "12px",
    fontWeight: 600,
    color: { md: blueColor04, xs: mobilePrimary },
    mt: 1,
    border: { md: "1px solid" + blueColor04, xs: "1px solid" + mobilePrimary },
    backgroundColor: whiteColor,
    "&:hover": {
      backgroundColor: { md: blueColor04, xs: mobilePrimary },
      color: whiteColor,
    },

    "&.Mui-disabled": {
      border: "1px solid rgba(0, 0, 0, 0.26)",
    },
  },
};
