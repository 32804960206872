import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import BusinessInfoCard from "../../components/Cards/BusinessInfoCard";
import moment from "moment";
import BusinessStepCard from "./BusinessStepCard";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  getBusinessProductsByStatusAPI,
  getCommerceDashboardInfoAPI,
  getProductsByBusinessAPI,
} from "../../service/api_calls/BusinessPreview/BusinessPreviewAPI";
import BenefitsInfoCard from "./BenefitsInfoCard";

function BusinessDashboardStartContent({ businessData, handleAddEcomClick }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeProducts, setActiveProducts] = useState([]);
  const [cmsPendingProducts, setCmsPendingProducts] = useState([]);
  // const [dashboardCommerce, setDashboardCommerce] = useState(null);
  const [dashboardCommerce, setDashboardCommerce] = useState(null);

  const handleAddProducts = () => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/addProductNew`);
  };

  const handleGoToCatalog = () => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/catalog`);
  };

  const getDashboardCommerceInfo = async () => {
    const dashboardComInfo =
      location?.state?.businessCommerceInfo ||
      (await getCommerceDashboardInfoAPI(businessData?.id));

    console.log("dashboardComInfo:", dashboardComInfo);
    if (dashboardComInfo) setDashboardCommerce(dashboardComInfo);
  };

  const getActivePendingProducts = async () => {
    console.log("POST_DATTT:", businessData);

    const activeProductsData =
      location?.state?.businessProduct ||
      (await getProductsByBusinessAPI(businessData?.id));

    // const penidngProducts = await getCMSPendingProductsApi({
    //   businessId: businessData?.id,
    //   status: "new",
    //   pageNo: 1,
    // });

    const penidngProducts = await getBusinessProductsByStatusAPI({
      businessId: businessData?.id,
      status: "new",
    });

    if (activeProductsData?.productList) {
      setActiveProducts(activeProductsData?.productList);
    }

    if (penidngProducts?.details) {
      setCmsPendingProducts(penidngProducts?.details);
    }
  };

  const getStepTwoTitle = () => {
    if (activeProducts?.length > 0 || cmsPendingProducts?.length > 0) {
      return "Add more products";
    } else {
      return "Start adding your catalog";
    }
  };

  const getStepTwoDescription = () => {
    if (cmsPendingProducts?.length >= 1) {
      return `Pending Approval - ${cmsPendingProducts?.length} \n Ordering will be enabled once the products are approved`;
    } else if (dashboardCommerce?.status !== "active") {
      return "You will be able to access this section after updating E-Commerce Controls";
    } else if (
      cmsPendingProducts?.length === 0 &&
      dashboardCommerce?.status === "active"
    ) {
      return "";
    } else {
      return "You will be able to access this section after updating E-Commerce Controls";
    }
  };

  const getEcomDescription = () => {
    if (dashboardCommerce?.status === "submitted") {
      return `ECommerce details have been submitted for approval. Please contact support@costbo.com for any queries`;
    } else if (dashboardCommerce?.status === "active") {
      return "ECommerce details have been updated..";
    } else {
      return "";
    }
  };

  useEffect(() => {
    getActivePendingProducts();
    getDashboardCommerceInfo();
    return () => {};
  }, []);

  return (
    <Box width="100%">
      <Box pt={{ md: 0, xs: 2 }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Box>
              <BusinessStepCard
                stepCount="1"
                icon={
                  <StorefrontIcon
                    sx={{ fontSize: { md: "25px", xs: "17px" } }}
                  />
                }
                status="active"
                title="Congrats! Your Business is successfully onboarded"
                description=""
                hideButton
              />

              <BusinessStepCard
                stepCount="2"
                icon={
                  <Box
                    component="img"
                    src="https://storage.googleapis.com/bodefaults/businessdashboardweb/store-management.svg"
                    sx={{
                      width: { md: "25px", xs: "17px" },
                      height: { md: "25px", xs: "17px" },
                    }}
                  />
                }
                status={dashboardCommerce?.status === "active" ? "active" : ""}
                title="E-commerce Control Panel"
                description={getEcomDescription()}
                buttonText={
                  dashboardCommerce?.status === "submitted"
                    ? "GO TO BUSINESS LISTING"
                    : "CLICK HERE"
                }
                onClick={() => {
                  if (dashboardCommerce?.status === "submitted") {
                    navigate("/businessListing");
                    return;
                  }
                  handleAddEcomClick();
                }}
                hideButton={
                  dashboardCommerce?.status !== "new"
                  // &&
                  // dashboardCommerce?.status !== "submitted"
                }
                showVideoGuide
              />

              <BusinessStepCard
                stepCount="3"
                icon={
                  <AddShoppingCartIcon
                    sx={{ fontSize: { md: "25px", xs: "17px" } }}
                  />
                }
                // status={dashboardCommerce?.status === "active" ? "active" : ""}
                title={getStepTwoTitle()}
                description={getStepTwoDescription()}
                buttonText={
                  cmsPendingProducts?.length >= 1
                    ? "GO TO CATALOG"
                    : "ADD PRODUCT"
                }
                btnType="addProduct"
                onClick={
                  cmsPendingProducts?.length >= 1
                    ? handleGoToCatalog
                    : handleAddProducts
                }
                isLastStep
                disabled={
                  dashboardCommerce?.status !== "active" &&
                  dashboardCommerce?.status !== "submitted"
                }
              />
            </Box>
          </Grid>

          <Grid item md={6} xs={12}>
            <BenefitsInfoCard />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default BusinessDashboardStartContent;
