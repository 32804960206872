import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import InputPreview from "../../components/Input/InputPreview";
import {
  blackColor,
  greyColor7,
  whiteColor,
} from "../../configs/styles/muiThemes";

function CategoryDetailPreview({
  businessCategory,
  productCategory,
  productSubCategory,
  productSpecLabel,
  productSpecValue,
  refLinkLabel,
  refLinkValue,
  refLinks,
}) {
  return (
    <Box sx={styles.section}>
      {/* <Typography
        component="h5"
        fontSize={18}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        m="5px 0 20px !important"
      >
        Category Detail
      </Typography>

      <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
        <Grid item xs={12} md={4}>
          <InputPreview label="Business Category" value={businessCategory} />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputPreview label="Product Category" value={productCategory} />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputPreview
            label="Product Sub-Category"
            value={productSubCategory}
          />
        </Grid>
      </Grid> */}

      <Typography
        component="h5"
        fontSize={{xs:14.5, md:16}}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        m="10px 0 20px !important"
      >
        Product Specification
      </Typography>

      {productSpecLabel?.length === 0 ||
      (productSpecLabel?.length === 1 && !productSpecLabel?.[0]?.code) ? (
        <Typography
          component="h6"
          fontSize={13.5}
          color={greyColor7}
          fontWeight={500}
          lineHeight="20px"
          m="10px 0 15px !important"
        >
          N/A
        </Typography>
      ) : null}

      {productSpecLabel?.map((item, index) => {
        if (!item?.code) return;

        return (
          <Box key={`product_spec_${index}`} mb={{ xs: 2, md: 2 }}>
            <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
              <Grid item xs={12} md={3.5}>
                <InputPreview label="Label" value={item?.code} />
              </Grid>

              <Grid item xs={12} md={8.5}>
                <InputPreview label="Value" value={item?.value?.join(", ")} />
              </Grid>
            </Grid>
          </Box>
        );
      })}

      <Typography
        component="h6"
        fontSize={{xs:14, md:15}}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        m="20px 0 15px !important"
      >
        Reference Link
      </Typography>

      {refLinks?.length === 1 &&
      !refLinks?.[0]?.code &&
      !refLinks?.[0]?.link ? (
        <Typography
          component="h6"
          fontSize={13.5}
          color={greyColor7}
          fontWeight={500}
          lineHeight="20px"
          m="10px 0 15px !important"
        >
          N/A
        </Typography>
      ) : (
        <>
          {refLinks?.map((item, index) => {
            return (
              <Box key={`ref_link_${index}`} mb={{ xs: 2, md: 2 }}>
                <Grid container rowSpacing={{ xs: 2, md: 2 }} columnSpacing={3}>
                  <Grid item xs={12} md={3.5}>
                    <InputPreview label="Link Name" value={item?.code} />
                  </Grid>

                  <Grid item xs={12} md={8.5}>
                    <InputPreview label="Link" value={item?.link} />
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
}

export default CategoryDetailPreview;

const styles = {
  section: {
    p: "10px 20px 15px",
    backgroundColor: whiteColor,
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    borderRadius: "13px",
    mt: { xs: "25px" },
  },
};
