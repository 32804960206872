import {
  activeBGColor,
  activeTextColor,
  bgColor15,
  draftBGColor,
  draftTextColor,
  greenColor2,
  inactiveBGColor,
  inactiveTextColor,
  orangeStatusColor,
  pendingBGColor,
  pendingTextColor,
  rejectedBGColor,
  rejectedTextColor,
  whiteColor,
} from "../configs/styles/muiThemes";

export const saveMsg =
  "If you do not have all the above details handy, please SAVE& EXIT. You may login back and complete the registration. Details entered here will be stored as 'Draft'.";

export const DEFAULT_COUNTRY = {
  name: "India",
  code: "IN",
  ondcCountryCode: "IND",
};

export const HERO_SECTION_DATA = [
  {
    id: "01",

    bgImg:
      "https://storage.googleapis.com/bodefaults/businessweb/business-home-page-v2/Slider-1.png",
    title: "A Fully loaded B2B, Online \nportal for ",
    hightlight: "Direct Commerce",
    hightlightPosition: "end",
    subtitle: "Sign up with CostBo",
    paginationLabel: "Managed Online Infrastructure",
  },
  {
    id: "02",
    bgImg:
      "https://storage.googleapis.com/bodefaults/businessweb/business-home-page-v2/Slider-2.png",
    title: " in Under \n2 hours with Integrated Fintech",
    hightlight: "Go Pan-India",
    hightlightPosition: "start",
    subtitle: "Take your B2B Business to the Next Level!",
    paginationLabel: "Direct Reach to Customers & Dealers",
  },
  {
    id: "03",
    bgImg:
      "https://storage.googleapis.com/bodefaults/businessweb/business-home-page-v2/Slider-3.png",
    title: "Own Your Brand \nand Own ",
    hightlight: "Your Customer!",
    hightlightPosition: "end",
    subtitle: "Eliminate the Intermediary",
    paginationLabel: "Working Capital for your Dealers",
  },
  {
    id: "04",
    bgImg:
      "https://storage.googleapis.com/bodefaults/businessweb/business-home-page-v2/Slider-4.png",
    title: "Plug & Play with Future \nReady ",
    hightlight: "E-comm Ecosystem",
    hightlightPosition: "end",
    subtitle: "No IT Dept ? No Problem!",
    paginationLabel: "Integrated Affiliates Network",
  },
  {
    id: "05",
    bgImg:
      "https://storage.googleapis.com/bodefaults/businessweb/business-home-page-v2/Slider-5.png",
    title: " \nfor your buyers",
    hightlight: "Automated Fintech",
    hightlightPosition: "start",
    subtitle: "Don’t sweat about Cash Flow",
    paginationLabel: "Integrated Pan-India Logistics & Tracking",
  },
  // {
  //   id: "06",
  //   title: "Own Your Brand \nand Own Your ",
  //   hightlight:'Customer!',
  //   hightlightPosition:'end',
  //   subtitle: "Eliminate the intermediary",
  //   paginationLabel: "ONDC Ready Technology",
  // },
];

export const DASHBOARD_MENU = [
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/store.png",
    label: "Business Listing",
    value: "store",
    route: "/businessListing",
    sectionBreak: { md: "12px", xs: "12px" },
    sectionHeader: "BUSINESS",
  },
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/menu-dashboard.png",
    label: "Dashboard",
    value: "dashboard",
    route: "/dashboard",
    sectionHeader: "DASHBOARD",
  },
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/ecommerce.png",
    label: "ECommerce Controls",
    value: "eCommerceControls",
    route: `/warehouseDetail`,
    // sectionHeader: "CONTROLS",
    collapseDefault: true,
    subMenu: [
      {
        label: "Warehouse Detail",
        route: "/warehouseDetail",
        value: "warehouseDetail",
      },
      {
        label: "Delivery Methods",
        route: "/deliveryMethods",
        value: "deliveryMethods",
      },
      {
        label: "Discount Detail",
        route: "/discountDetails",
        value: "discountDetail",
      },
      {
        label: "Returns & Other Info",
        route: "/otherDetails",
        value: "otherDetail",
      },
      { label: "Admin/Managers", route: "/managers", value: "managers" },
      {
        label: "Bank Information",
        route: "/bankInfo",
        value: "bankInformation",
      },
    ],
  },
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/ecommerce.png",
    label: "ONDC Controls",
    value: "ONDCControls",
    route: "/ONDCControls",
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/order.png",
    label: "Orders",
    value: "orders",
    route: "/orders",
    sectionHeader: "ORDER MANAGEMENT",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/return.png",
    label: "Returns",
    value: "returns",
    route: "/manageReturns",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/issue.png",
    label: "Customer Grievance",
    value: "issueRaising",
    route: `/igmIssuesItem`,
    collapseDefault: true,
    subMenu: [
      {
        label: "Item Issue",
        route: "/igmIssuesItem",
        value: "igmIssuesItem",
      },
      {
        label: "Fulfillment Issue",
        route: "/igmIssuesFulfillment",
        value: "igmIssuesFulfillment",
      },
    ],
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/catalog.png",
    label: "Catalog",
    value: "catalog",
    route: `/catalog`,
    collapseDefault: false,
    subMenu: [
      {
        label: "Catalog",
        route: "/catalog",
        value: "catalog",
      },
      // {
      //   label: "Catalog >> Branches",
      //   route: "/branchCatalogPush",
      //   value: "branchCatalogPush",
      // },
      {
        label: "Bulk Upload",
        value: "bulkUploadNew",
        route: "/catalogBulkUpload",
      },
    ],
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/inventory.png",
    label: "Inventory",
    value: "inventory",
    route: `/allStock`,
    subMenu: [
      {
        label: "Active Inventory",
        route: "/allStock",
        value: "activeInventory",
      },
      { label: "Not Selling", route: "/pausedInventory", value: "notSelling" },
      { label: "Price Update", route: "/priceUpdate", value: "priceUpdate" },
    ],
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/crm.png",
    label: "CRM Analysis",
    value: "crm",
    route: "/crmAnalysis",
    sectionHeader: "MARKETING",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/share_link.svg",
    label: "Share Links",
    value: "shareLinks",
    route: "/ondcShareLink",
    subMenu: [
      {
        label: "ONDC Checkout Links",
        route: "/ondcShareLink",
        value: "ondcStoreLinks",
      },
      {
        label: "Direct Store Links",
        route: "/directStoreLink",
        value: "directStoreLink",
      },
    ],
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/paycart.png",
    label: "Book Orders",
    value: "paycartOrders",
    route: "/easyCartOrders",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/affiliate.png",
    label: "Affiliate Marketing",
    value: "affiliateMarketing",
    route: "/affiliateMarketing",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/affiliate.png",
    label: "Fintech Program",
    value: "fintechProgram",
    route: "/fintechB2BCS",
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/discount.png",
    label: "Coupons & Discount",
    value: "coupons",
    route: "/couponsDiscount",
    sectionHeader: "DISCOUNT",
    subMenu: [
      {
        label: "Coupons/Promo Code",
        route: "/couponsDiscount",
        value: "couponsPromo",
      },
      { label: "Loyalty Points", route: "/cashBack", value: "loyaltyPoints" },
      {
        label: "Personalized Discounts",
        route: "/preferredDiscounts",
        value: "personalizedDiscounts",
      },
    ],
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/subscription.png",
    label: "Subscriptions",
    value: "subscriptions",
    route: "/subscriptions",
    sectionHeader: "OTHERS",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/report.png",
    label: "Report",
    value: "report",
    route: "/reports",
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/video.png",
    label: "How-to Video Guides",
    value: "howToVideoGuides",
    route: "/howToVideoGuides",
    root: true,
    sectionHeader: "HELP",
  },
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/support.png",
    label: "Support",
    value: "support",
    route: "/support",
  },
];

export const DASHBOARD_MENU_MAIN_BRANCH = [
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/store.png",
    label: "Business Listing",
    value: "store",
    route: "/businessListing",
    sectionBreak: { md: "12px", xs: "12px" },
    sectionHeader: "BUSINESS",
  },
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/menu-dashboard.png",
    label: "Dashboard",
    value: "dashboard",
    route: "/dashboard",
    sectionHeader: "DASHBOARD",
  },
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/ecommerce.png",
    label: "ECommerce Controls",
    value: "eCommerceControls",
    route: `/warehouseDetail`,
    // sectionHeader: "CONTROLS",
    collapseDefault: true,
    subMenu: [
      {
        label: "Warehouse Detail",
        route: "/warehouseDetail",
        value: "warehouseDetail",
      },
      {
        label: "Delivery Methods",
        route: "/deliveryMethods",
        value: "deliveryMethods",
      },
      {
        label: "Discount Detail",
        route: "/discountDetails",
        value: "discountDetail",
      },
      {
        label: "Returns & Other Info",
        route: "/otherDetails",
        value: "otherDetail",
      },
      { label: "Admin/Managers", route: "/managers", value: "managers" },
      {
        label: "Bank Information",
        route: "/bankInfo",
        value: "bankInformation",
      },
    ],
  },
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/ecommerce.png",
    label: "ONDC Controls",
    value: "ONDCControls",
    route: "/ONDCControls",
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/order.png",
    label: "Orders",
    value: "orders",
    route: "/orders",
    sectionHeader: "ORDER MANAGEMENT",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/return.png",
    label: "Returns",
    value: "returns",
    route: "/manageReturns",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/issue.png",
    label: "Customer Grievance",
    value: "issueRaising",
    route: `/igmIssuesItem`,
    collapseDefault: true,
    subMenu: [
      {
        label: "Item Issue",
        route: "/igmIssuesItem",
        value: "igmIssuesItem",
      },
      {
        label: "Fulfillment Issue",
        route: "/igmIssuesFulfillment",
        value: "igmIssuesFulfillment",
      },
    ],
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/catalog.png",
    label: "Catalog",
    value: "catalog",
    route: `/catalog`,
    collapseDefault: false,
    subMenu: [
      {
        label: "Catalog",
        route: "/catalog",
        value: "catalog",
      },
      {
        label: "Catalog >> Branches",
        route: "/branchCatalogPush",
        value: "branchCatalogPush",
      },
      {
        label: "Bulk Upload",
        value: "bulkUploadNew",
        route: "/catalogBulkUpload",
      },
    ],
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/inventory.png",
    label: "Inventory",
    value: "inventory",
    route: `/allStock`,
    subMenu: [
      {
        label: "Active Inventory",
        route: "/allStock",
        value: "activeInventory",
      },
      { label: "Not Selling", route: "/pausedInventory", value: "notSelling" },
      { label: "Price Update", route: "/priceUpdate", value: "priceUpdate" },
    ],
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/crm.png",
    label: "CRM Analysis",
    value: "crm",
    route: "/crmAnalysis",
    sectionHeader: "MARKETING",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/share_link.svg",
    label: "Share Links",
    value: "shareLinks",
    route: "/ondcShareLink",
    subMenu: [
      {
        label: "ONDC Checkout Links",
        route: "/ondcShareLink",
        value: "ondcStoreLinks",
      },
      {
        label: "Direct Store Links",
        route: "/directStoreLink",
        value: "directStoreLink",
      },
    ],
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/paycart.png",
    label: "Book Orders",
    value: "paycartOrders",
    route: "/easyCartOrders",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/affiliate.png",
    label: "Affiliate Marketing",
    value: "affiliateMarketing",
    route: "/affiliateMarketing",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/affiliate.png",
    label: "Fintech Program",
    value: "fintechProgram",
    route: "/fintechB2BCS",
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/discount.png",
    label: "Coupons & Discount",
    value: "coupons",
    route: "/couponsDiscount",
    sectionHeader: "DISCOUNT",
    subMenu: [
      {
        label: "Coupons/Promo Code",
        route: "/couponsDiscount",
        value: "couponsPromo",
      },
      { label: "Loyalty Points", route: "/cashBack", value: "loyaltyPoints" },
      {
        label: "Personalized Discounts",
        route: "/preferredDiscounts",
        value: "personalizedDiscounts",
      },
    ],
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/subscription.png",
    label: "Subscriptions",
    value: "subscriptions",
    route: "/subscriptions",
    sectionHeader: "OTHERS",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/report.png",
    label: "Report",
    value: "report",
    route: "/reports",
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/video.png",
    label: "How-to Video Guides",
    value: "howToVideoGuides",
    route: "/howToVideoGuides",
    root: true,
    sectionHeader: "HELP",
  },
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/support.png",
    label: "Support",
    value: "support",
    route: "/support",
  },
];

export const DASHBOARD_MENU_BRANCH = [
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/store.png",
    label: "Business Listing",
    value: "store",
    route: "/businessListing",
    sectionBreak: { md: "12px", xs: "12px" },
    sectionHeader: "BUSINESS",
  },
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/menu-dashboard.png",
    label: "Dashboard",
    value: "dashboard",
    route: "/dashboard",
    sectionHeader: "DASHBOARD",
  },
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/ecommerce.png",
    label: "ECommerce Controls",
    value: "eCommerceControls",
    route: `/warehouseDetail`,
    collapseDefault: true,
    subMenu: [
      {
        label: "Warehouse Detail",
        route: "/warehouseDetail",
        value: "warehouseDetail",
      },
      {
        label: "Delivery Methods",
        route: "/deliveryMethods",
        value: "deliveryMethods",
      },
      {
        label: "Discount Detail",
        route: "/discountDetails",
        value: "discountDetail",
      },
      {
        label: "Returns & Other Info",
        route: "/otherDetails",
        value: "otherDetail",
      },
      { label: "Admin/Managers", route: "/managers", value: "managers" },
      {
        label: "Bank Information",
        route: "/bankInfo",
        value: "bankInformation",
      },
    ],
  },
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/ecommerce.png",
    label: "ONDC Controls",
    value: "ONDCControls",
    route: "/ONDCControls",
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/order.png",
    label: "Orders",
    value: "orders",
    route: "/orders",
    sectionHeader: "ORDER MANAGEMENT",
  },
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/return.png",
    label: "Returns",
    value: "returns",
    route: "/manageReturns",
  },
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/paycart.png",
    label: "Easy Cart Orders",
    value: "paycartOrders",
    route: "/easyCartOrders",
  },
  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/issue.png",
    label: "Customer Grievance",
    value: "issueRaising",
    route: `/igmIssuesItem`,
    collapseDefault: true,
    subMenu: [
      {
        label: "Item Issue",
        route: "/igmIssuesItem",
        value: "igmIssuesItem",
      },
      {
        label: "Fulfillment Issue",
        route: "/igmIssuesFulfillment",
        value: "igmIssuesFulfillment",
      },
    ],
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/crm.png",
    label: "Catalog",
    value: "catalog",
    route: "/catalog",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/inventory.png",
    label: "Inventory",
    value: "inventory",
    route: `/allStock`,
    subMenu: [
      {
        label: "Active Inventory",
        route: "/allStock",
        value: "activeInventory",
      },
      { label: "Not Selling", route: "/pausedInventory", value: "notSelling" },
    ],
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/crm.png",
    label: "CRM Analysis",
    value: "crm",
    route: "/crmAnalysis",
    sectionHeader: "MARKETING",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/share_link.svg",
    label: "Share Links",
    value: "shareLinks",
    route: "/ondcShareLink",
    subMenu: [
      {
        label: "ONDC Checkout Links",
        route: "/ondcShareLink",
        value: "ondcStoreLinks",
      },
      {
        label: "Direct Store Links",
        route: "/directStoreLink",
        value: "directStoreLink",
      },
    ],
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/paycart.png",
    label: "Book Orders",
    value: "paycartOrders",
    route: "/easyCartOrders",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/affiliate.png",
    label: "Affiliate Marketing",
    value: "affiliateMarketing",
    route: "/affiliateMarketing",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/affiliate.png",
    label: "Fintech Program",
    value: "fintechProgram",
    route: "/fintechB2BCS",
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/discount.png",
    label: "Coupons & Discount",
    value: "coupons",
    route: "/couponsDiscount",
    sectionHeader: "DISCOUNT",
    subMenu: [
      {
        label: "Coupons/Promo Code",
        route: "/couponsDiscount",
        value: "couponsPromo",
      },
      { label: "Loyalty Points", route: "/cashBack", value: "loyaltyPoints" },
      {
        label: "Personalized Discounts",
        route: "/preferredDiscounts",
        value: "personalizedDiscounts",
      },
    ],
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/subscription.png",
    label: "Subscriptions",
    value: "subscriptions",
    route: "/subscriptions",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/report.png",
    label: "Report",
    value: "report",
    route: "/reports",
    sectionBreak: "12px",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/video.png",
    label: "How-to Video Guides",
    value: "howToVideoGuides",
    route: "/howToVideoGuides",
    root: true,
    sectionHeader: "HELP",
  },

  {
    icon: "https://storage.googleapis.com/bodefaults/businessdashboardweb/sidebar_icons/support.png",
    label: "Support",
    value: "support",
    route: "/support",
  },
];

export const DISABLE_ALL_MENU = [
  "dashboard",
  "websiteIntegration",
  "eCommerceControls",
  "ONDCControls",
  "store",
  "catalog",
  "bulkUpload",
  "shareLinks",
  // "newCatalog",
  "inventory",
  "orders",
  "returns",
  "issueRaising",
  "customerGrievance",
  "crm",
  "subscriptions",
  "report",
  "coupons",
  "paycartOrders",
  "affiliateMarketing",
  "fintechProgram",
  // "howToGuides",
  "support",
];

export const INVENTORY_STEP_LIST = [
  { id: 0, label: "ALL STOCK" },
  { id: 1, label: "OUT OF STOCK" },
  { id: 2, label: "LOW STOCK" },
  { id: 3, label: "PRICE UPDATE" },
];

export const E_COMMERCE_STEP_LIST = [
  { id: 0, label: "Warehouse Detail" },
  { id: 1, label: "Delivery Methods" },
  { id: 2, label: "Discount Detail" },
  { id: 3, label: "Returns & Other Info" },
  { id: 4, label: "Admin/Managers" },
  { id: 5, label: "Bank Info" },
];

export const OOS_COLUMN_HEADING = [
  { label: "Product", size: 5 },
  { label: "Available Qty", size: 1.75 },
  { label: "Alert Qty", size: 1.75 },
  { label: "Min Qty", size: 1.75, description: "(PER ORDER)" },
  { label: "Max Qty", size: 1.75, description: "(PER ORDER)" },
];

export const PU_COLUMN_HEADING = [
  { label: "Product", size: 3.8 },
  { label: "Original price", size: 1.75 },
  { label: "Sale price", size: 1.75 },
  { label: "GST %", size: 1.1 },
  { label: "Start Date", size: 1.85 },
  { label: "End Date", size: 1.75 },
];

export const ALL_STOCK_COLUMN_HEADING = [
  { label: "Product", size: 2.4 },
  { label: "Availability", size: 1.19 },
  { label: "Available Qty", size: 1.23 },
  { label: "Alert Qty", size: 1.23 },
  { label: "Min Qty", description: "Per Order", size: 1.23 },
  { label: "Max Qty", description: "Per Order", size: 1.23 },
  { label: "Original Price", size: 1.23 },
  { label: "Sale Price", size: 1.23 },
  { label: "GST%", size: 1 },
  // { label: "Start Selling on", size: 1.345 },
  // { label: "End Selling on", size: 1.345 },
];

export const PU_COLUMN_HEADING_NEW = [
  { title: "PRODUCT NAME", isRequired: false, width: "350px" },
  { title: "ORIGINAL PRICE", isRequired: false, width: "135px" },
  { title: "% OFF", isRequired: false, width: "75px" },
  { title: "SALE PRICE", isRequired: false, width: "115px" },
  { title: "GST %", isRequired: false, width: "130px" },
  { title: "START DATE", isRequired: false, width: "175px" },
  { title: "VALIDITY", infoText: "IN DAYS", isRequired: false, width: "145px" },
  { title: "END DATE", isRequired: false, width: "200px" },
  { title: "STATUS", isRequired: false, width: "300px" },
];
export const OOS_COLUMN_HEADING_NEW = [
  { title: "PRODUCT NAME", isRequired: false, width: "350px" },
  { title: "AVAILABLE QTY", isRequired: false, width: "135px" },
  { title: "ALERT QTY", isRequired: false, width: "75px" },
  {
    title: "MIN QTY",
    infoText: "PER ORDER",
    isRequired: false,
    width: "115px",
  },
  {
    title: "MAX QTY",
    infoText: "PER ORDER",
    isRequired: false,
    width: "130px",
  },
  { title: "STATUS", isRequired: false, width: "300px" },
];

export const ALL_STOCK_COLUMN_HEADER_NEW = [
  { title: "PRODUCT NAME" },
  { title: "AVAILABILITY" },
  { title: "AVAILABLE QTY" },
  { title: "ALERT QTY" },
  { title: "MIN QTY", infoText: "Per Order" },
  { title: "MAX QTY", infoText: "Per Order" },
  { title: "ORIGINAL PRICE" },
  { title: "% OFF", isRequired: false },
  { title: "SALE PRICE" },
  { title: "GST%" },
  { title: "START SELLING ON" },
  { title: "VALIDITY", infoText: "IN DAYS", isRequired: false },
  { title: "END SELLING ON" },
  { title: "STATUS", isRequired: false },
];

export const PENDING_ORDERS_COLUMN_HEADING = [
  { label: "ORDER DETAIL", size: 3.3 },
  { label: "CUSTOMER INFO & PRICE & QTY", size: 3 },
  { label: "DELIVERY TYPE & STATUS", size: 2.1 },
  { label: "ACTION", size: 3.6 },
];
export const READY_TO_SHIP_ORDERS_COLUMN_HEADING = [
  { label: "ORDER DETAIL", size: 3.2 },
  { label: "CUSTOMER INFO & PRICE & QTY", size: 2.4 },
  { label: "DELIVERY TYPE & STATUS", size: 2.2 },
  { label: "ACTION", size: 4.2 },
];
export const IN_TRANSIT_COLUMN_HEADING = [
  { label: "ORDER DETAIL", size: 3.2 },
  { label: "CUSTOMER INFO & PRICE & QTY", size: 2.5 },
  { label: "DELIVERY TYPE & STATUS", size: 2.1 },
  { label: "ACTION", size: 4.2 },
];
export const DELIVERED_ORDERS_COLUMN_HEADING = [
  { label: "ORDER DETAIL", size: 3.3 },
  { label: "CUSTOMER INFO & PRICE & QTY", size: 2.8 },
  { label: "DELIVERY TYPE & STATUS", size: 2.3 },
  { label: "ACTION", size: 3.6 },
];
export const CANCELED_ORDERS_COLUMN_HEADING = [
  { label: "ORDER DETAIL", size: 3.3 },
  { label: "CUSTOMER INFO & PRICE & QTY", size: 2.3 },
  { label: "CANCELED STAGE", size: 3.1 },
  { label: "STATUS", size: 2.1 },
  { label: "ACTION", size: 1.2 },
];
export const ALL_ORDERS_COLUMN_HEADING = [
  { label: "ORDER DETAIL", size: 3.2 },
  { label: "CUSTOMER INFO & PRICE & QTY", size: 3 },
  { label: "STATUS", size: 2.1 },
  { label: "ACTION", size: 3.6 },
];

export const CHAR_AVATAR_COLORS = [
  { char: ["b", "c", "d", "e", "t"], color: "#ff5c00" },
  { char: ["f", "h", "i", "j"], color: "#ad00ff" },
  { char: ["m", "n", "o", "p", "q"], color: "#f20ce6" },
  { char: ["s", "u", "v", "w"], color: "#de9d0e" },
  { char: ["x", "y", "z", "g", "k"], color: "#3d3ac6" },
  { char: ["r", "l", "a"], color: "#414141" },
];

export const NEW_BANNERS_DND_DATA = [
  {
    base64String: "",
    id: 0,
    key: "bannerImgOne",
    initialIndex: 0,
  },
  {
    base64String: "",
    id: 1,
    key: "bannerImgTwo",
    initialIndex: 1,
  },
  {
    base64String: "",
    id: 2,
    key: "bannerImgThree",
    initialIndex: 2,
  },
  {
    base64String: "",
    id: 3,
    key: "bannerImgFour",
    initialIndex: 3,
  },
];

// Product Photos Demo

export const PRODUCT_DEMO_PICS = [
  "https://storage.googleapis.com/bo3151920215/business/c7430ace4f56477f9ea2e362864d0586/product/d3d723eaf5d44371b762e44f56f472d2/Product0-755783.jpeg",
  "https://storage.googleapis.com/bo3151920215/business/c7430ace4f56477f9ea2e362864d0586/product/d3d723eaf5d44371b762e44f56f472d2/Product1-195662.jpeg",
  "https://storage.googleapis.com/bo3151920215/business/c7430ace4f56477f9ea2e362864d0586/product/d3d723eaf5d44371b762e44f56f472d2/Product2-604564.jpeg",
  "https://storage.googleapis.com/bo3151920215/business/c7430ace4f56477f9ea2e362864d0586/product/d3d723eaf5d44371b762e44f56f472d2/Product3-352544.jpeg",
];

export const PRODUCT_SPECS_DEMO_INFO = [
  {
    label: "Good to Know",
    value: "Fresh Oil, Unrefined No odulteration, Premium Brand",
  },
  {
    label: "Health Benefits",
    value:
      "Reduces Heart Disease Risk, Rich in Vitamin C, can raise good HDL cholesterol, Natural Monisturizer, Solves teeth problems",
  },
  {
    label: "Ingredients",
    value: "Made from 100% finest coconuts",
  },
  {
    label: "Happily Produced!",
    value:
      "Our coconuts are grow in rich soils in most pristine region of India. Within hours of row material arrival, they are cold pressed with our Proprietary extraction method to lock in freshness, flavor and fragrance.",
  },
];

export const PRODUCT_DEMO_INFO_LINKS = [
  {
    label: "Testimony",
    value: "https://www.facebook.com/costboconnect",
  },
  {
    label: "Product Video",
    value: "https://www.instagram.com/costboconnect",
  },
  {
    label: "Peview - Google",
    value: "https://g.co/kgs/MaJXGd",
  },
];

// ONDC ADD PRODUCT

export const TIME_TO_SHIP_UNIT = [
  { label: "Days", unit: "D" },
  { label: "Hours", unit: "H" },
  { label: "Minutes", unit: "M" },
];

export const YES_NO_DROPDOWN_DATA = [{ label: "YES" }, { label: "NO" }];

export const FOOD_TYPE_DATA = [
  { label: "Select Value" },
  { label: "Veg" },
  { label: "Non Veg" },
  // { label: "Egg" },
];

export const UNITS_OF_MEASURE = [{ label: "pieces" }, { label: "gms" }];

export const PRODUCT_CODE_UNIT_LIST = [
  { label: "EAN" },
  { label: "GTIN" },
  { label: "HSN" },
  { label: "ISBN" },
  { label: "OTHERS" },
];
export const PRODUCT_CODE_DEFAULT_LIST = [
  {
    id: "productcode-1",
    prodictCode: "EAN",
    validation: ["^d{13}$"],
  },
  {
    id: "productcode-2",
    prodictCode: "GTIN",
    validation: ["^d{12}$"],
  },
  {
    id: "productcode-3",
    prodictCode: "HSN",
    validation: ["^d{8}$"],
  },
  {
    id: "productcode-4",
    prodictCode: "ISBN",
    validation: ["^d{13}$"],
  },
  {
    id: "productcode-5",
    prodictCode: "OTHERS",
    validation: ["^d{13}$"],
  },
];

export const CANCELLABLE_FEE_TYPES = [
  { label: "Select Fee Type" },
  { label: "Amount" },
  { label: "Percentage" },
];

// ONDC Ecommerce Info

export const SHIPMENT_REGION_LIST = [
  { label: "Select Shipment Region", value: "none" },
  { label: "Locality", value: "locality" },
  { label: "Intercity", value: "intercity" },
  { label: "Nationwide", value: "nationwide" },
];

export const PARTIAL_CANCEL_STAGE = [
  // { label: "Select Partial Cancel" },
  { label: "Order Created", value: "ordercreated" },
  // { label: "Order Approved" },
  // { label: "Order Shipped" },
];

export const FULL_CANCEL_STAGE = [
  // { label: "Select Full Cancel", value: "" },
  { label: "Order Created", value: "ordercreated" },
  // { label: "Order Approved", value: "orderapproved" },
  // { label: "Order Shipped", value: "ordershipped" },
];

export const TIME_SCHEDULE = [
  {
    label: "Pickup Schedule",
    sublabel:
      "Scheduled Pickup 12 noon next day, If missed will be auto scheduled next day",
  },
  {
    label: "Shipping label",
    sublabel: "Email From : orders@costbo.com",
    msg: "ATTACH TO DELIVERY PACKAGE",
    successMsg: "Shipping label sent successfully",
    btn: true,
  },
];

// Order Cancel reason list
export const ORDER_CANCEL_REASONS = [
  {
    label: "Select reason for cancellation",
  },
  {
    label: "Products Out of Stock",
  },
  {
    label: "Logistics Delay",
  },
  {
    label: "Shipping not available",
  },
  {
    label: "Store closed temporarily",
  },
  {
    label: "Not all products available",
  },
];

export const ONBOARDING_VIDEO_DATA = {
  title: "Onboarding at Costbo Seller Platform #ONDC #ondconboarding",
  url: "https://www.youtube.com/embed/yhqzR9DPw48?si=rtxLyXARex7TSFpP",
  category: {
    value: "gettingStarted",
    label: "Getting started/Add Business",
  },
  date: "Nov 10, 2023",
};

export const CATALOG_VIDEO_DATA = {
  title:
    "Costbo - How to add product catalog in your ecommerce account #ONDC #digitalcommerce",
  url: "https://www.youtube.com/embed/SeFU99QlG1A?si=Kpze1hTtDVBW51Gj",
  category: { label: "Catalog Management", value: "catalog" },
  date: "Nov 10, 2023",
};

export const ORDER_VIDEO_DATA = {
  title: "Effective order management on #ecommerce #ondc #digitalcommerce",
  url: "https://www.youtube.com/embed/G4-732LfZss?si=hgPDAeQ6lOThPOig",
  category: { label: "Order Management", value: "order" },
  date: "Nov 10, 2023",
};

export const INVENTORY_VIDEO_DATA = {
  title: "Manage your Inventory in real time #digitalcommerce #ecommerce #ondc",
  url: "https://www.youtube.com/embed/XnJDqi_GdEQ?si=X4adGdl5ugd-KDwK",
  category: { label: "Inventory Management", value: "inventory" },
  date: "Nov 10, 2023",
};

export const ECOMMERCE_VIDEO_DATA = {
  title: "Setting Up E Commerce Controls #digitalcommerce #ondc",
  url: "https://www.youtube.com/embed/CwOvZ-mSuA4?si=kMYhBkIuJInarIGP",
  category: { label: "E-Commerce Controls", value: "eCommerceControls" },
  date: "Nov 10, 2023",
};

export const GUIDE_DATA = [
  {
    title: "Onboarding at Costbo Seller Platform #ONDC #ondconboarding",
    url: "https://www.youtube.com/embed/yhqzR9DPw48?si=rtxLyXARex7TSFpP",
    category: {
      value: "gettingStarted",
      label: "Getting started/Add Business",
    },
    date: "Nov 10, 2023",
  },
  {
    title: "Setting Up E Commerce Controls #digitalcommerce #ondc",
    url: "https://www.youtube.com/embed/CwOvZ-mSuA4?si=kMYhBkIuJInarIGP",
    category: { label: "E-Commerce Controls", value: "eCommerceControls" },
    date: "Nov 10, 2023",
  },
  {
    title:
      "Costbo - How to add product catalog in your ecommerce account #ONDC #digitalcommerce",
    url: "https://www.youtube.com/embed/SeFU99QlG1A?si=Kpze1hTtDVBW51Gj",
    category: { label: "Catalog Management", value: "catalog" },
    date: "Nov 10, 2023",
  },
  {
    title:
      "Manage your Inventory in real time #digitalcommerce #ecommerce #ondc",
    url: "https://www.youtube.com/embed/XnJDqi_GdEQ?si=X4adGdl5ugd-KDwK",
    category: { label: "Inventory Management", value: "inventory" },
    date: "Nov 10, 2023",
  },
  {
    title: "Effective order management on #ecommerce #ondc #digitalcommerce",
    url: "https://www.youtube.com/embed/G4-732LfZss?si=hgPDAeQ6lOThPOig",
    category: { label: "Order Management", value: "order" },
    date: "Nov 10, 2023",
  },
];

export const PAYCART_TABS = [
  {
    label: "All",
    value: "all",
    color: bgColor15,
  },
  {
    label: "Paid",
    value: "paid",
    color: greenColor2,
  },
  {
    label: "Not Paid",
    value: "notPaid",
    color: orangeStatusColor,
  },
];

export const BULK_UPLOAD_STEPPER = [
  { id: 1, label: "Download Template", pageName: "download" },
  { id: 2, label: "Upload Template", pageName: "upload" },
  { id: 3, label: "Assign Category", pageName: "assign-category" },
  { id: 4, label: "Product Attributes", pageName: "review" },
  { id: 5, label: "ONDC Parameters", pageName: "ondc" },
  {
    id: 6,
    label: "ONDC Specs",
    pageName: "ondcSpecGroup",
    pageTwoName: "addOndcSpecs",
  },
  { id: 7, label: "Catalog Upload", pageName: "published" },
];

export const BULK_UPLOAD_EXCEL_TEMPLATES = [
  {
    label: "ONDC Catalog for Bulk Product Upload",
    fileUrl:
      "https://storage.googleapis.com/bo3151920215/productBulkUpload/templates/productBulkUpload_Template_V10.xlsx",
  },
  // {
  //   label: "ONDC Catalog for F&B (Food & Beverages)",
  //   fileUrl:
  //     "https://storage.googleapis.com/bo3151920215/productBulkUpload/templates/productBulkUpload_template_ONDC_Catalog_Food_Beverages.xlsx",
  // },
  // {
  //   label: "ONDC Catalog for BPC (Beauty & Personal Care)",
  //   fileUrl:
  //     "https://storage.googleapis.com/bo3151920215/productBulkUpload/templates/productBulkUpload_template_ONDC_Catalog_BPC.xlsx",
  // },
];

export const ASSIGN_CATEGORY_TABLE_HEADER = [
  { id: "01", title: "PRODUCT NAME", isRequired: true, headerFixed: true },
  { id: "02", title: "ONDC CATEGORY", isRequired: true },
  { id: "03", title: "ONDC SUB CATEGORY", isRequired: true },
  { id: "04", title: "BUSINESS CATEGORY", isRequired: true },
  { id: "05", title: "PRODUCT CATEGORY", isRequired: true },
  { id: "06", title: "PRODUCT SUB CATEGORY" },
];

export const BULK_UPLOAD_TABLE_HEADER = [
  { id: "01", title: "PRODUCT NAME", isRequired: true, headerFixed: true },
  // { id: "02", title: "ONDC CATEGORY", isRequired: true },
  { id: "07", title: "PRODUCT DESCRIPTION (Click To Edit)", isRequired: true },
  { id: "08", title: "SKU ID" },
  { id: "09", title: "BRAND", isRequired: true },
  { id: "10", title: "COUNTRY OF ORIGIN", isRequired: true },
  { id: "03", title: "PRODUCT PHOTO 1", isRequired: true },
  { id: "04", title: "PRODUCT PHOTO 2" },
  { id: "05", title: "PRODUCT PHOTO 3" },
  { id: "06", title: "PRODUCT PHOTO 4" },
  { id: "11", title: "PRODUCT CODE", isRequired: true },
  { id: "12", title: "PRODUCT CODE VALUE", isRequired: true },
  // { id: "13", title: "GST/HSN CODE", isRequired: true },
  { id: "14", title: "GST PERCENTAGE (%)", isRequired: true },
  { id: "15", title: "UNIT OF MEASUREMENT", isRequired: true },
  { id: "16", title: "UNIT OF MEASUREMENT VALUE", isRequired: true },
  { id: "17", title: "ORIGINAL PRICE", isRequired: true },
  { id: "18", title: "OFFER PRICE", isRequired: true },
  { id: "19", title: "QTY ON HAND", isRequired: true },
  { id: "20", title: "ALERT QTY", isRequired: true },
  { id: "21", title: "MIN QTY PER ORDER", isRequired: true },
  { id: "22", title: "MAX QTY PER ORDER", isRequired: true },
  { id: "23", title: "PRODUCT WEIGHT (IN GMS)", isRequired: true },
  { id: "24", title: "SHIPPING WEIGHT(IN GMS)", isRequired: true },
  { id: "25", title: "PRODUCT LENGTH (IN MM)" },
  { id: "26", title: "PRODUCT HEIGHT (IN MM)" },
  { id: "27", title: "PRODUCT WIDTH (IN MM)" },
  { id: "28", title: "SPECIFICATION (LABEL1)" },
  { id: "29", title: "SPECS (LABEL1-VALUE1)" },
  { id: "30", title: "SPECS (LABEL1-VALUE2)" },
  { id: "31", title: "SPECS (LABEL1-VALUE3)" },
  { id: "32", title: "SPECIFICATION (LABEL2)" },
  { id: "33", title: "SPECS (LABEL2-VALUE1)" },
  { id: "34", title: "SPECS (LABEL2-VALUE2)" },
  { id: "35", title: "SPECS (LABEL2-VALUE3)" },
  { id: "36", title: "REFERENCE LINK (LINKTYPE1)" },
  { id: "37", title: "REF LINK (LINKTYPE1 - VALUE1)" },
  { id: "38", title: "REFERENCE LINK (LINKTYPE2)" },
  { id: "39", title: "Ref Link (LinkType2 - Value1)" },
  // { id: "40", title: "STATUS" },
  { id: "41", title: "ACTION" },
];

export const ONDC_PARAMETERS_TABLE_HEADER = [
  { id: "01", title: "PRODUCT NAME", isRequired: true, headerFixed: true },
  { id: "02", title: "ONDC ENABLED", isRequired: true },
  { id: "03", title: "TIME TO SHIP", isRequired: true },
  { id: "04", title: "TIME TO SHIP (Days/Hours)", isRequired: true },
  { id: "05", title: "CASH ON DELIVERY", isRequired: true },
  { id: "06", title: "FRAGILE", isRequired: true },
  { id: "07", title: "CANCELLABLE", isRequired: true },
  // { id: "08", title: "CANCELLATION FEE AMOUNT" },
  { id: "09", title: "CANCELLATION FEE %" },
  { id: "10", title: "REFUND ELIGIBLE" },
  { id: "11", title: "RETURNABLE", isRequired: true },
  { id: "12", title: "RETURN/DISPUTE ELIGIBLE PERIOD (In Days)" },
  { id: "13", title: "SELLER PICKUP" },
  { id: "14", title: "FOOD & RELATED ITEMS", isRequired: true },
  { id: "15", title: "SHELF LIFE (In Days)" },
  { id: "16", title: "VEG/NONVEG" },
  { id: "17", title: "FSSAI LICENSE Number" },
  // { id: "18", title: "FSSAI LICENSE VALID TILL (DD/MM/YYYY)" },
  { id: "19", title: "PACKAGED COMMODITIES", isRequired: true },
  { id: "20", title: "MANUFACTURER/PACKER NAME" },
  { id: "21", title: "LICENSE NO" },
  { id: "22", title: "MANUFACTURER/PACKER ADDRESS" },
  { id: "23", title: "PRODUCT COMMON/GENERIC NAME" },
  { id: "24", title: "MANUFACTURER/IMPORT DATE (MM/YYYY)" },
  { id: "25", title: "EXPIRY DATE (MM/YYYY)" },
  { id: "26", title: "NET QUANTITY (in gms)" },
  { id: "27", title: "MULTIPLE PRODUCTS - NAME, NUMBER/QTY" },
  { id: "28", title: "PRE PACKAGED FOOD", isRequired: true },
  { id: "29", title: "BRAND ONWER FSSAI LICENSE NO" },
  { id: "30", title: "OTHER FSSAI LICENSE NO" },
  { id: "31", title: "IMPORTER FSSAI LICENSE NO" },
  { id: "32", title: "INGREDIENT INFO" },
  { id: "33", title: "NUTRITIONAL INFO" },
  { id: "34", title: "ADDITIVES INFO" },
  // { id: "35", title: "STATUS" },
  { id: "36", title: "ACTION" },
];

export const ONDC_PARAMETERS_TABLE_HEADER_NEW = [
  { id: "01", title: "PRODUCT NAME", isRequired: true, headerFixed: true },
  { id: "02", title: "ONDC ENABLED", isRequired: true },
  { id: "03", title: "TIME TO SHIP", isRequired: true },
  { id: "04", title: "TIME TO SHIP (Days/Hours)", isRequired: true },
  { id: "05", title: "CASH ON DELIVERY", isRequired: true },
  { id: "06", title: "FRAGILE", isRequired: true },
  { id: "07", title: "CANCELLABLE", isRequired: true },
  // { id: "08", title: "CANCELLATION FEE AMOUNT" },
  { id: "09", title: "CANCELLATION FEE %" },
  { id: "10", title: "REFUND ELIGIBLE" },
  { id: "11", title: "RETURNABLE", isRequired: true },
  { id: "12", title: "RETURN/DISPUTE ELIGIBLE PERIOD (in Days)" },
  { id: "13", title: "SELLER PICKUP" },
  { id: "36", title: "ACTION" },
];
export const ONDC_PARAMETERS_TABLE_HEADER_NEW1 = [
  { id: "01", title: "PRODUCT NAME", isRequired: true, headerFixed: true },
  { id: "02", title: "ONDC CATEGORY", isRequired: true },
  { id: "03", title: "ONDC SUB CATEGORY", isRequired: true },
  // { id: "02", title: "ONDC ENABLED", isRequired: true },
  // { id: "03", title: "TIME TO SHIP", isRequired: true },
  // { id: "04", title: "TIME TO SHIP (Days/Hours)", isRequired: true },
  // { id: "05", title: "CASH ON DELIVERY", isRequired: true },
  // { id: "06", title: "FRAGILE", isRequired: true },
  // { id: "07", title: "CANCELLABLE", isRequired: true },
  // // { id: "08", title: "CANCELLATION FEE AMOUNT" },
  // { id: "09", title: "CANCELLATION FEE %" },
  // { id: "10", title: "REFUND ELIGIBLE" },
  // { id: "11", title: "RETURNABLE", isRequired: true },
  // { id: "12", title: "RETURN/DISPUTE ELIGIBLE PERIOD (in Days)" },
  // { id: "13", title: "SELLER PICKUP" },
  // { id: "36", title: "ACTION" },
];

// Dashboard Banners
export const DASHBOARD_BANNERS = [
  {
    id: "01",
    imgUrl:
      "https://storage.googleapis.com/bodefaults/businessdashboardweb/ONDC-Banner.png",
    alt: "ONDC Banner",
    route: "/ONDCControls",
  },
  {
    id: "02",
    imgUrl:
      "https://storage.googleapis.com/bodefaults/businessdashboardweb/easy-cart-banner.png",
    alt: "Easy Cart Banner",
    route: "/easyCartOrders",
  },
  {
    id: "03",
    imgUrl:
      "https://storage.googleapis.com/bodefaults/businessdashboardweb/bulk-upload.png",
    alt: "Bluk Upload Banner",
    route: "/catalogBulkUpload",
  },
];

export const RETURNS_TAB_DATA = [
  { id: 0, label: "New/In Process", mobileLabel: "NEW/IN PROCESS" },
  { id: 1, label: "Completed", mobileLabel: "COMPLETED" },
];

export const RETURN_ORDER_TABLE_COL = [
  { id: "01", title: "ORDER DETAIL", col: 2.2 },
  { id: "02", title: "ONDC ORDER ID", col: 2.2 },
  { id: "02", title: "PRICE & QTY", col: 2 },
  { id: "03", title: "RETURN(PRICE & QTY)", col: 2 },
  { id: "04", title: "STATUS", col: 1.8 },
  { id: "05", title: "ACTION", col: 1.8 },
];

export const COMPLETED_RETURN_ORDER_TABLE_COL = [
  { id: "01", title: "ORDER DETAIL", col: 2.2 },
  { id: "02", title: "ONDC ORDER ID", col: 2.2 },
  { id: "02", title: "PRICE & QTY", col: 2 },
  { id: "03", title: "RETURN(PRICE & QTY)", col: 1.8 },
  { id: "04", title: "STATUS", col: 2.45 },
  { id: "05", title: "ACTION", col: 1.15 },
];

export const HEADER_COLUMNS_ACTION = [
  { id: "01", isCheckBox: true, col: 0.35 },
  { id: "02", title: "QUANTITY", col: 1 },
  { id: "03", title: "REFUND REQUESTED", col: 1.3 },
  { id: "04", title: "REASON", col: 1.8 },
  { id: "05", title: "IMAGE", col: 0.7 },
  { id: "06", title: "REQUEST DATE", col: 1.5 },
  { id: "06", title: "REFUND PROCESSED", col: 1.3 },
  { id: "07", title: "ACTION", col: 1.3 },
  { id: "08", title: "REASON", col: 2 },
  // { id: "01", title: "PROCESSED DATE", col: 1.5 },
];

export const HEADER_COLUMNS = [
  { id: "01", title: "QUANTITY", col: 0.8 },
  { id: "02", title: "REFUND REQUESTED", col: 1.3 },
  { id: "03", title: "REASON", col: 1.8 },
  { id: "04", title: "IMAGE", col: 0.7 },
  { id: "05", title: "REQUEST DATE", col: 1.2 },
  { id: "06", title: "REFUND PROCESSED", col: 1.3 },
  { id: "07", title: "ACTION", col: 1.3 },
  { id: "08", title: "REASON", col: 2 },
  { id: "09", title: "PROCESSED DATE", col: 1.5 },
];

export const RETURN_ORDER_ACTION_DATA = [
  { label: "Select Return Status" },
  { label: "Approved" },
  { label: "Liquidated" },
  { label: "Rejected" },
];

export const PRODUCT_AVAILABLE_TABLE_HEADER = [
  {
    id: "01",
    title: "AVAILABLE QUANTITY",
    isRequired: true,
    headerFixed: true,
  },
  { id: "02", title: "ALERT QUANTITY", isRequired: true },
  { id: "03", title: "MIN QTY PER ORDER", isRequired: true },
  { id: "04", title: "MAX QTY PER ORDER", isRequired: true },
  { id: "05", title: "START DATE", isRequired: true },
  { id: "06", title: "END DATE", isRequired: true },
];

export const PRODUCT_MEASUREMENT_WEIGHT_TABLE_HEADER = [
  {
    id: "01",
    title: "PRODUCT WEIGHT (IN GMS)",
    isRequired: true,
  },
  { id: "02", title: "IN KGS", isRequired: false },
  {
    id: "03",
    title: "SHIPPING WEIGHT (IN GMS)",
    isRequired: true,
  },
  { id: "04", title: "IN KGS", isRequired: false },
];
export const PRODUCT_MEASUREMENT_DIMENTION_TABLE_HEADER = [
  {
    id: "01",
    title: "PRODUCT LENGTH (IN MM)",
    isRequired: false,
  },
  { id: "02", title: "IN CM", isRequired: false },
  { id: "03", title: "IN INCHES", isRequired: false },
  {
    id: "04",
    title: "PRODUCT WIDTH (IN MM)",
    isRequired: false,
  },
  { id: "05", title: "IN CM", isRequired: false },
  { id: "06", title: "IN INCHES", isRequired: false },
  {
    id: "07",
    title: "PRODUCT HEIGHT (IN MM)",
    isRequired: false,
  },
  { id: "08", title: "IN CM", isRequired: false },
  { id: "09", title: "IN INCHES", isRequired: false },
];
export const ONDC_TABLE_HEADER_1 = [
  {
    id: "01",
    title: "TIME TO SHIP",
    isRequired: true,
  },
  { id: "02", title: "TIME", isRequired: true },
  { id: "03", title: "CASH ON DELIVERY", isRequired: true },
  {
    id: "04",
    title: "FRAGILE",
    isRequired: true,
  },
];
export const ONDC_TABLE_HEADER_2 = [
  {
    id: "01",
    title: "RETURNABLE",
    isRequired: true,
  },
  {
    id: "02",
    title: "RETURN/DISPUTE ELIGIBLE PERIOD ",
    isRequired: true,
  },
  { id: "03", title: "SELLER PICKUP", isRequired: true },
  {
    id: "04",
    title: "CANCELLABLE",
    isRequired: true,
  },
  {
    id: "05",
    title: "REFUND ELIGIBLE",
    isRequired: true,
  },
  {
    id: "06",
    title: "CANCELLATION FEE %",
    isRequired: true,
  },
];
export const ONDC_TABLE_HINT = [
  {
    id: "01",
    title: "YES/NO",
  },
  { id: "02", title: "IN DAYS" },
  { id: "03", title: "YES/NO" },
  {
    id: "04",
    title: "YES/NO",
  },
  {
    id: "05",
    title: "YES/NO",
  },
  {
    id: "06",
    title: "Enter Fee %",
  },
];

export const ACTIVE_PRODUCT_STEPLIST = [
  { id: 0, label: "Active", value: "active" },
  { id: 1, label: "Pending Approval", value: "pendingApproval" },
  { id: 1, label: "Rejected", value: "rejected" },
];

export const NEW_PRODUCT_STEPLIST = [
  { id: 1, label: "Pending Approval", value: "pendingApprovalNew" },
  { id: 1, label: "Rejected", value: "rejectedNew" },
];

export const DEFAULT_BRANCH_DATA = {
  branchName: "",
  gstNumber: "",
  registeredBusinessName: "",
  panNumber: "",
  pinCode: "",
  address: "",
  landmark: "",
  city: "",
  state: "",
  locality: "",
  sublocality: "",
  lat: "",
  lng: "",
  phoneNumber: "",
  email: "",
  errors: {},
};

export const getStatusBGColor = (status) => {
  if (status === "active") return activeBGColor;
  if (status === "draft") return inactiveBGColor;
  if (status === "pending") return pendingBGColor;
  if (status === "rejected") return rejectedBGColor;
  if (status === "inactive") return draftBGColor;

  return draftBGColor;
};

export const getStatusTextColor = (status) => {
  if (status === "active") return activeTextColor;
  if (status === "draft") return inactiveTextColor;
  if (status === "pending") return pendingTextColor;
  if (status === "rejected") return rejectedTextColor;
  if (status === "inactive") return draftTextColor;

  return whiteColor;
};
export const CATALOG_BRANCH_PUSH_HEADER = [
  { title: "IMAGE", col: 1.3 },
  { title: "PRODUCT NAME", col: 5 },
  { title: "PRICE", col: 2 },
  { title: "ONDC ENABLED", col: 1.5 },
  { title: "LAST UPDATE DATE", col: 2.2 },
  // { title: "ACTION", col: 2 },
];

export const CATALOG_BRANCH_PUSH_SINGLE_HEADER = [
  { title: "IMAGE", col: 1 },
  { title: "PRODUCT NAME", col: 4 },
  { title: "PRICE", col: 1.8 },
  { title: "ONDC ENABLED", col: 1.4 },
  { title: "LAST UPDATE DATE", col: 1.9 },
  { title: "ACTION", col: 1.9 },
];

export const CATALOG_SUB_BRANCH_HEADER = [
  { title: "IMAGE", col: 1 },
  { title: "PRODUCT NAME", col: 4.5 },
  { title: "PRICE", col: 2 },
  { title: "ONDC ENABLED", col: 1.4 },
  { title: "LAST SYNC DATE", col: 1.6 },
  { title: "ACTION", col: 1.5 },
];

export const CATALOG_PUSH_TABS = [
  { id: 0, label: "Multi Product Push >> One Branch" },
  { id: 1, label: "Single Product Push " },
];

export const SINGLE_PRODUCT_PUSH_HEADER = [
  { title: "BRANCH NAME", col: 6.5 },
  { title: "SYNC STATUS", col: 2.1 },
  { title: "BRANCH PPRODUCT - LAST SYNC DATE", col: 3.4 },
  // { title: "ACTION", col: 2 }
]

export const MULTI_PROD_PUSH_HEADER = [
  { title: "IMAGE", col: 1.1 },
  { title: "PRODUCT NAME", col: 3.4 },
  { title: "ONDC ENABLED", col: 1.3 },
  { title: "MASTER PRODUCT - LAST UPDATE DATE", col: 2.55 },
  { title: "SYNC STATUS", col: 1.2 },
  { title: "BRANCH PRODUCT - LAST SYNC DATE", col: 2.45 },
];