import {
  Box,
  Checkbox,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import {
  blackColor,
  blackShade159,
  blackShade19,
  blackShade21,
  blueColor04,
  borderCart,
  greyColor42,
  greyColor47,
  greyColor5,
  greyColor8,
  greyShade10,
  greyShade25,
  mobilePrimary,
  tableHeaderColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { textOneLines, textThreeLines } from "../../../utils/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

function OutOfStockCard({
  index,
  productList,
  onSelectAll,
  selectedRows,
  totalCount,
  img,
  title,
  originalAmt,
  amt,
  alertQty,
  avlQty,
  minQty,
  maxQty,
  isChecked,
  onValueChange,
  currentStock,
  errors,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [toggleData, settoggleData] = useState(false);
  const handleShowData = (data) => {
    settoggleData(data);
  };
  return (
    <Box
      sx={{
        // borderRadius: "6px",
        border: `1px solid ${tableHeaderColor}`,
        background: whiteColor,
        mb: { md: 1, xs: 0 },
      }}
    >
      <Box
        sx={{ p: "16px 16px 16px 16px" }}
        border={{
          xs: `1px solid ${errors?.length > 0 ? "red" : "none"}`,
          md: "none",
        }}
      >
        {matches ? (
          <Grid
            container
            // display={"flex"}
            // alignItems="center"
            // sx={{
            //   p: { xs: "10px", md: 0 },
            //   background: whiteColor,
            //   mb: { md: 0, xs: 1 },
            //   borderRadius: { md: 0, xs: 3 },
            // }}
          >
            <Grid item md={5} xs={12}>
              <Box
                display="flex"
                alignItems="flex-start"
                gap={1.2}
                p={{ md: "8px 10px 5px", xs: "0" }}
              >
                <Checkbox
                  // sx={{ ml: -0.1 }}
                  sx={{ ml: -0.8, ...styles.checkbox }}
                  checked={isChecked}
                  size={"large"}
                  onChange={() => {
                    onValueChange({ type: "itemChange", value: !isChecked });
                  }}
                />

                <Box display="flex" gap={1.5} alignItems={"flex-start"}>
                  <Box
                    width="35px"
                    sx={{ display: { md: "block", xs: "none" } }}
                  >
                    <Box component="img" src={img} sx={styles.imgContainer} />
                  </Box>
                  <Box flex={1}>
                    <Typography
                      variant="body1"
                      title={title}
                      sx={{
                        ...styles.prodNameBox,
                        ...textOneLines,
                      }}
                    >
                      {title}
                    </Typography>

                    <Box display="flex" gap={1} mt={0.4}>
                      {originalAmt !== amt ? (
                        <Typography
                          fontSize={11}
                          color={blackShade21}
                          sx={{
                            textDecoration: "line-through",
                          }}
                        >
                          ₹ {originalAmt}
                        </Typography>
                      ) : null}
                      <Typography
                        fontSize={12}
                        fontWeight={600}
                        color={blueColor04}
                      >
                        ₹ {amt}
                      </Typography>
                      <Typography
                        fontSize={{ md: 12, xs: 12 }}
                        color={greyShade10}
                        fontWeight={400}
                        ml={{ md: 3, xs: 1.5 }}
                      >
                        Current Stock:
                      </Typography>
                      <Typography
                        fontSize={{ md: 12, xs: 12 }}
                        color={blackColor}
                        fontWeight={600}
                      >
                        {currentStock}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={1.75} xs={6} p={{ md: "5px 20px", xs: "0" }}>
              <Typography
                variant="h3"
                fontSize={{ md: 13, xs: 11 }}
                color={greyShade25}
                fontWeight={400}
                textTransform="uppercase"
                sx={{ display: { md: "none", xs: "block" }, mt: 2 }}
              >
                Ava Qty
              </Typography>
              <TextField
                variant="standard"
                value={avlQty}
                sx={styles.input}
                onChange={({ target }) => {
                  const value = target.value;
                  const regex = /^(\d{0,7}|\d{0,7})$/;
                  if (regex.test(value) && target.value.charAt(0) !== "0") {
                    onValueChange({ type: "availableQuantity", value });
                  }
                }}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: blackColor,
                    fontSize: { md: 14, xs: 12 },
                    fontWeight: 500,
                  },
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid item md={1.75} xs={6} p={{ md: "5px 20px", xs: "0" }}>
              <Typography
                variant="h3"
                fontSize={{ md: 13, xs: 11 }}
                color={greyShade25}
                fontWeight={400}
                textTransform="uppercase"
                sx={{ display: { md: "none", xs: "block" }, mt: 2 }}
              >
                Alert Qty
              </Typography>
              <TextField
                variant="standard"
                value={alertQty}
                onChange={({ target }) => {
                  const value = target.value;
                  const regex = /^(\d{0,7}|\d{0,7})$/;
                  if (regex.test(value) && target.value.charAt(0) !== "0") {
                    onValueChange({ type: "alertQuantity", value });
                  }
                }}
                sx={styles.input}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: blackColor,
                    fontSize: { md: 14, xs: 12 },
                    fontWeight: 500,
                  },
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid item md={1.75} xs={6} p={{ md: "5px 20px", xs: "0" }}>
              <Typography
                variant="h3"
                fontSize={{ md: 13, xs: 11 }}
                color={greyShade25}
                fontWeight={400}
                textTransform="uppercase"
                sx={{ display: { md: "none", xs: "block" }, mt: 2 }}
              >
                Min Qty
              </Typography>
              <TextField
                variant="standard"
                value={minQty}
                sx={styles.input}
                onChange={({ target }) => {
                  const value = target.value;
                  const regex = /^(\d{0,7}|\d{0,7})$/;
                  if (regex.test(value) && target.value.charAt(0) !== "0") {
                    onValueChange({ type: "minOrderQuantity", value });
                  }
                }}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: blackColor,
                    fontSize: { md: 14, xs: 12 },
                    fontWeight: 500,
                  },
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid item md={1.75} xs={6} p={{ md: "5px 20px", xs: "0" }}>
              <Typography
                variant="h3"
                fontSize={{ md: 13, xs: 11 }}
                color={greyShade25}
                fontWeight={400}
                textTransform="uppercase"
                sx={{ display: { md: "none", xs: "block" }, mt: 2 }}
              >
                Max Qty
              </Typography>
              <TextField
                variant="standard"
                value={maxQty}
                onChange={({ target }) => {
                  const value = target.value;
                  const regex = /^(\d{0,7}|\d{0,7})$/;
                  if (regex.test(value) && target.value.charAt(0) !== "0") {
                    onValueChange({ type: "maxOrderQuantity", value });
                  }
                }}
                sx={styles.input}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: blackColor,
                    fontSize: { md: 14, xs: 12 },
                    fontWeight: 500,
                  },
                }}
                autoComplete="off"
              />
            </Grid>

            {errors?.length > 0 ? (
              <Grid item md={12} mt={2}>
                {errors?.map((item, i) => (
                  <Typography
                    key={`error_${i}`}
                    color={"red"}
                    textAlign="center"
                    fontSize={12}
                    fontWeight={500}
                    display="inline"
                    mr={1}
                  >
                    {item}
                  </Typography>
                ))}
              </Grid>
            ) : null}
          </Grid>
        ) : (
          <Grid
            container
            // display={"flex"}
            // alignItems="center"
            // sx={{
            //   p: { xs: "10px", md: 0 },
            //   background: whiteColor,
            //   mb: { md: 0, xs: 1 },
            //   borderRadius: { md: 0, xs: 3 },
            // }}
          >
            {!toggleData ? (
              <Grid item md={5} xs={12}>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  gap={1}
                  p={{ md: "8px 10px 5px", xs: "0" }}
                  justifyContent={"space-between"}
                >
                  <Checkbox
                    // sx={{ ml: -0.1 }}
                    sx={{ ml: -0.8, ...styles.checkbox }}
                    checked={isChecked}
                    size={"large"}
                    onChange={() => {
                      onValueChange({ type: "itemChange", value: !isChecked });
                    }}
                  />

                  <Box display={"flex"} gap={1}>
                    <Box sx={styles.imgContainer}>
                      <Box
                        component="img"
                        src={img}
                        height={"30px"}
                        maxWidth={"30px !important"}
                      />
                    </Box>
                    <Box flex={1}>
                      <Typography
                        variant="body1"
                        title={title}
                        sx={{
                          ...styles.prodNameBox,
                          ...textOneLines,
                        }}
                      >
                        {title}
                      </Typography>

                      <Box display="flex" gap={1} mt={0.4}>
                        {originalAmt !== amt ? (
                          <Typography
                            fontSize={11}
                            color={blackShade21}
                            sx={{
                              textDecoration: "line-through",
                            }}
                          >
                            ₹ {originalAmt}
                          </Typography>
                        ) : null}
                        <Typography
                          fontSize={12}
                          fontWeight={600}
                          color={blueColor04}
                        >
                          ₹ {amt}
                        </Typography>
                        <Typography
                          fontSize={{ md: 12, xs: 12 }}
                          color={greyShade10}
                          fontWeight={400}
                          ml={{ md: 3, xs: 1.5 }}
                        >
                          Current Stock:
                        </Typography>
                        <Typography
                          fontSize={{ md: 12, xs: 12 }}
                          color={blackColor}
                          fontWeight={600}
                        >
                          {currentStock}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={styles.iconContainer()}
                    onClick={() => {
                      handleShowData(true);
                    }}
                  >
                    <ExpandMoreIcon
                      sx={styles.icon(matches ? whiteColor : "#e83658")}
                    />
                  </Box>
                </Box>
              </Grid>
            ) : (
              <Grid item md={5} xs={12}>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  gap={1}
                  p={{ md: "8px 10px 5px", xs: "0" }}
                >
                  <Checkbox
                    // sx={{ ml: -0.1 }}
                    sx={{ ml: -0.8, ...styles.checkbox }}
                    checked={isChecked}
                    size={"large"}
                    onChange={() => {
                      onValueChange({ type: "itemChange", value: !isChecked });
                    }}
                  />

                  <Box display={"flex"} gap={1}>
                    <Box sx={styles.imgContainer}>
                      <Box
                        component="img"
                        src={img}
                        height={"30px"}
                        maxWidth={"30px !important"}
                      />
                    </Box>
                    <Box flex={1}>
                      <Typography
                        variant="body1"
                        title={title}
                        sx={{
                          ...styles.prodNameBox,
                          // ...textOneLines,
                        }}
                      >
                        {title}
                      </Typography>

                      <Box display="flex" gap={1} mt={0.4}>
                        {originalAmt !== amt ? (
                          <Typography
                            fontSize={11}
                            color={blackShade21}
                            sx={{
                              textDecoration: "line-through",
                            }}
                          >
                            ₹ {originalAmt}
                          </Typography>
                        ) : null}
                        <Typography
                          fontSize={12}
                          fontWeight={600}
                          color={blueColor04}
                        >
                          ₹ {amt}
                        </Typography>
                        <Typography
                          fontSize={{ md: 12, xs: 12 }}
                          color={greyShade10}
                          fontWeight={400}
                          ml={{ md: 3, xs: 1.5 }}
                        >
                          Current Stock:
                        </Typography>
                        <Typography
                          fontSize={{ md: 12, xs: 12 }}
                          color={blackColor}
                          fontWeight={600}
                        >
                          {currentStock}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={styles.iconContainer()}
                    onClick={() => {
                      handleShowData(false);
                    }}
                  >
                    <ExpandLessIcon
                      sx={styles.icon(matches ? whiteColor : "#e83658")}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
            {toggleData && (
              <>
                <Grid item md={1.75} xs={6} p={{ md: "5px 20px", xs: "0" }}>
                  <Typography
                    variant="h3"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyShade25}
                    fontWeight={400}
                    textTransform="uppercase"
                    sx={{ display: { md: "none", xs: "block" }, mt: 2 }}
                  >
                    Ava Qty
                  </Typography>
                  <TextField
                    variant="standard"
                    value={avlQty}
                    sx={styles.input}
                    onChange={({ target }) => {
                      const value = target.value;
                      const regex = /^(\d{0,7}|\d{0,7})$/;
                      if (regex.test(value) && target.value.charAt(0) !== "0") {
                        onValueChange({ type: "availableQuantity", value });
                      }
                    }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: blackColor,
                        fontSize: { md: 14, xs: 12 },
                        fontWeight: 500,
                      },
                    }}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item md={1.75} xs={6} p={{ md: "5px 20px", xs: "0" }}>
                  <Typography
                    variant="h3"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyShade25}
                    fontWeight={400}
                    textTransform="uppercase"
                    sx={{ display: { md: "none", xs: "block" }, mt: 2 }}
                  >
                    Alert Qty
                  </Typography>
                  <TextField
                    variant="standard"
                    value={alertQty}
                    onChange={({ target }) => {
                      const value = target.value;
                      const regex = /^(\d{0,7}|\d{0,7})$/;
                      if (regex.test(value) && target.value.charAt(0) !== "0") {
                        onValueChange({ type: "alertQuantity", value });
                      }
                    }}
                    sx={styles.input}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: blackColor,
                        fontSize: { md: 14, xs: 12 },
                        fontWeight: 500,
                      },
                    }}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item md={1.75} xs={6} p={{ md: "5px 20px", xs: "0" }}>
                  <Typography
                    variant="h3"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyShade25}
                    fontWeight={400}
                    textTransform="uppercase"
                    sx={{ display: { md: "none", xs: "block" }, mt: 2 }}
                  >
                    Min Qty
                  </Typography>
                  <TextField
                    variant="standard"
                    value={minQty}
                    sx={styles.input}
                    onChange={({ target }) => {
                      const value = target.value;
                      const regex = /^(\d{0,7}|\d{0,7})$/;
                      if (regex.test(value) && target.value.charAt(0) !== "0") {
                        onValueChange({ type: "minOrderQuantity", value });
                      }
                    }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: blackColor,
                        fontSize: { md: 14, xs: 12 },
                        fontWeight: 500,
                      },
                    }}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item md={1.75} xs={6} p={{ md: "5px 20px", xs: "0" }}>
                  <Typography
                    variant="h3"
                    fontSize={{ md: 13, xs: 11 }}
                    color={greyShade25}
                    fontWeight={400}
                    textTransform="uppercase"
                    sx={{ display: { md: "none", xs: "block" }, mt: 2 }}
                  >
                    Max Qty
                  </Typography>
                  <TextField
                    variant="standard"
                    value={maxQty}
                    onChange={({ target }) => {
                      const value = target.value;
                      const regex = /^(\d{0,7}|\d{0,7})$/;
                      if (regex.test(value) && target.value.charAt(0) !== "0") {
                        onValueChange({ type: "maxOrderQuantity", value });
                      }
                    }}
                    sx={styles.input}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: blackColor,
                        fontSize: { md: 14, xs: 12 },
                        fontWeight: 500,
                      },
                    }}
                    autoComplete="off"
                  />
                </Grid>
                {errors?.length > 0 ? (
                  <Grid item md={12} mt={2}>
                    {errors?.map((item, i) => (
                      <Typography
                        key={`error_${i}`}
                        color={"red"}
                        textAlign="center"
                        fontSize={12}
                        fontWeight={500}
                        display="inline"
                        mr={1}
                      >
                        {item}
                      </Typography>
                    ))}
                  </Grid>
                ) : null}
              </>
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
}

export default OutOfStockCard;

const styles = {
  imgContainer: {
    borderRadius: "6px",
    border: "1px solid #e5e9f0",
    backgroundColor: whiteColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40px !important",
    height: "40px",
  },
  input: {
    width: { md: "65%", xs: "80%" },
    fontSize: 14.5,
    fontWeight: 500,
    borderTop: "none",
    borderBottom: borderCart,
    borderLeft: "none",
    borderRight: "none",
    backgroundColor: whiteColor,
  },
  prodNameBox: {
    minWidth: "250px",
    maxWidth: "100%",
    whiteSpace: "normal",
    fontSize: 12,
    fontWeight: 600,
    color: blackShade19,
    lineHeight: "16px",
  },

  checkbox: {
    p: 0,
    transform: "scale(0.95)",
    "&.Mui-checked": {
      color: greyColor8,
    },
  },
  iconContainer: (bgColor, hideMobile) => ({
    width: { md: "40px", xs: "20px" },
    height: { md: "40px", xs: "20px" },
    borderRadius: "50%",
    backgroundColor: whiteColor,
    display: { md: "flex", xs: hideMobile ? "none" : "flex" },
    alignItems: "center",
    justifyContent: "center",
  }),
  icon: (color) => ({ fontSize: { md: 20, xs: 26 }, color: color }),
};
