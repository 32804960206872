import { getProfileId } from "../../../utils/validationUtils";
import { getApiInstance } from "../../AppService";
import { GET_BUSINESS_PAYCART_ORDERS } from "../../constant";

export const getAllBusinessPaycartOrdersApi = async (data) => {
  try {
    const profileId = getProfileId();

    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.post(
      GET_BUSINESS_PAYCART_ORDERS,
      {
        all: true,
        profileId: [profileId],
        cartType: ["businesspaycart"],
      },
      {
        params: {
          pageNo: data?.pageNo || 1,
          filter: 900,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPayCartApi = async (data) => {
  try {
    const instance = getApiInstance();

    const response = await instance.post(
      `cart-query-side/api/v2/cart/consumer/getPayCart`,
      {
        all: false,
        businessId: [`${data?.businessId}`],
        cartType: ["businesspaycart"],
      },
      {
        headers: {
          Cartid: data.cartId,
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
