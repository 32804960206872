import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  borderTextbox,
  greyShade04,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import DashboardFormHeader from "./DashboardFormHeader";
import DashboardStepper from "../DashboardStepper";
import RadiosBtnWithLabel from "../../../components/Input/RadiosBtnWithLabel";
import { useState } from "react";
import CheckBoxWithLabel from "../../../components/Input/CheckBoxWithLabel";
import FormInputBox from "../../../components/Input/FormInputBox";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import { STORE_STEP_LIST } from "../../../utils/appData";

function DeliveryMethodForm({ gotoNext, goBack }) {
  const [codFee, setCodFee] = useState("Enter Cash on delivery fee charged to");

  const COD_FEE_CHARGES = [
    { label: "Enter Cash on delivery fee charged to" },
    { label: "Brand" },
    { label: "Retailers" },
    { label: "Wholesalers/Distributors" },
  ];

  return (
    <Box sx={styles.card}>
      <DashboardFormHeader />
      <DashboardStepper
        stepList={STORE_STEP_LIST}
        currentPage="delivery-methods"
      />

      <Box m="20px 0 15px 0">
        <Typography component="h5" sx={styles.heading}>
          Delivery Methods
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "flex-start", md: "center" },
            mt: { xs: "8px", md: "15px" },
            pb: "8px",
            borderBottom: borderTextbox,
          }}
        >
          <CheckBoxWithLabel label="Delivery/Fulfillment by Business" />
          <CheckBoxWithLabel label="Store Pickup" />
        </Box>

        <Typography
          component="h6"
          sx={{ ...styles.heading, fontSize: "16.5px", my: "15px" }}
        >
          All India Shipment
        </Typography>

        <Box sx={{ borderBottom: borderTextbox, pb: { xs: 2.5, md: 1 } }}>
          <Grid container rowSpacing={{ xs: 0.5, md: 2 }} columnSpacing={3}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: blackShade05,
                }}
              >
                Need Express delivery?
              </Typography>

              <Box>
                <RadiosBtnWithLabel
                  label="Yes"
                  checked={false}
                  onValuesChanged={() => {}}
                />

                <RadiosBtnWithLabel
                  label="No"
                  checked={false}
                  onValuesChanged={() => {}}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputBox
                label="Cash On Delivery Order Amount Limit"
                placeholder="Enter Order Amount Limit"
                isDashboardInput
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputBox
                label="Express Delivery distance"
                placeholder="Enter Express Delivery distance"
                isDashboardInput
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SelectWithLabel
                label="Cash on delivery fee charged to"
                value={codFee}
                onValueChange={() => {}}
                menuList={COD_FEE_CHARGES}
                isDashboardInput
              />
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Typography
            component="h6"
            sx={{ ...styles.heading, fontSize: "16.5px", m: "15px 0 6px" }}
          >
            Hyperlocal
          </Typography>

          <Typography
            variant="body1"
            fontSize={13}
            fontWeight={400}
            color={greyShade04}
            mb="15px !important"
          >
            (Available in Bangalore/Chennai/Hyderabad for 50km)
          </Typography>

          <Grid container rowSpacing={2} columnSpacing={3}>
            <Grid item xs={12} md={6}>
              <FormInputBox
                label="Express Delivery Distance"
                placeholder="Enter Express Delivery Distance"
                isDashboardInput
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box mt="15px">
        <ActionButtons
          onBackClick={goBack}
          onSaveClick={() => {}}
          onNextClick={gotoNext}
          backBtnText="Previous"
          showBackBtn
        />
      </Box>
    </Box>
  );
}

export default DeliveryMethodForm;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "13px",
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    p: "20px",
    my: "25px",
  },

  heading: {
    fontSize: { xs: 15, md: 18 },
    color: blackShade05,
    fontWeight: 600,
    lineHeight: "20px",
  },
};
