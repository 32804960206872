import { Box, Switch, Typography, styled } from "@mui/material";
import React from "react";
import {
  displaySwitchGreenColor,
  displaySwitchRedColor,
  greyColor24,
  greyShade09,
} from "../../configs/styles/muiThemes";

function StyledReadOnlySwitch({ isEnabled }) {
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 40,
    height: 22,
    padding: 0,
    // backgroundColor:greyShade09,
    cursor: "none !important",
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: displaySwitchGreenColor,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },

      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: displaySwitchGreenColor,
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 18,
      height: 18,
    },
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      backgroundColor: displaySwitchRedColor,
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <Box display="flex" alignItems="center" gap="10px" mt="4px">
      <IOSSwitch sx={{ m: 1 }} checked={isEnabled} onChange={() => {}} />

      <Typography
        component="body2"
        fontSize={14}
        color={greyColor24}
        fontWeight={500}
        lineHeight="20px"
      >
        {isEnabled ? "Enabled" : "Not Enabled"}
      </Typography>
    </Box>
  );
}

export default StyledReadOnlySwitch;
