import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import { Box, Typography } from "@mui/material";
import {
  CheckRounded,
  LocalMallRounded,
  LocalShippingRounded,
} from "@mui/icons-material";
import {
  stepperBGColor,
  blackShade05,
  whiteColor,
  blueColor04,
  greyColor40,
} from "../configs/styles/muiThemes";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    // top: 0,
    // right: -50,
    marginTop: "-5.5px",
    marginLeft: "-20px",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: blueColor04,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: blueColor04,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: "120%",
    height: 2,
    border: 0,
    backgroundColor: greyColor40,
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: greyColor40,
  zIndex: 1,
  color: whiteColor,
  width: 20,
  height: 20,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: blueColor04,
    // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    color: whiteColor,
  }),
  ...(ownerState.completed && {
    backgroundColor: blueColor04,
    color: whiteColor,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, index } = props;

  const icons = {
    1: (
      <Box>
        <LocalMallRounded
          sx={{
            color: completed || active ? whiteColor : blueColor04,
            fontSize: "10px !important",
          }}
        />
      </Box>
    ),
    2: (
      <Box>
        <LocalShippingRounded
          sx={{
            color: completed || active ? whiteColor : blueColor04,
            fontSize: "10px !important",
          }}
        />
      </Box>
    ),
    3: (
      <Box>
        <CheckRounded
          sx={{
            color: completed || active ? whiteColor : blueColor04,
            fontSize: "10px !important",
          }}
        />
      </Box>
    ),
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={{ width: "14px", height: "14px" }}
    >
      {/* {icons[String(props.icon)]}
      <Typography variant='body1' fontSize={14} fontWeight={500}>
        {index + 1}
      </Typography> */}

      <CheckRoundedIcon
        sx={{ fontSize: "10px !important", color: whiteColor }}
      />
    </ColorlibStepIconRoot>
  );
}

function HorizontalStepper({ steps, activeStep, width, fontSize }) {
  return (
    <Stack sx={{ width: width || { xs: 360, md: "320px" } }}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((item, index) => (
          <Step key={item.label} onClick={() => {}}>
            <StepLabel
              StepIconComponent={(props) =>
                ColorlibStepIcon({ ...props, route: item.route, index })
              }
            >
              <Typography
                variant="h5"
                component="p"
                fontSize={fontSize || 11.5}
                fontWeight={500}
                lineHeight="18px !important"
                color={activeStep >= index ? blueColor04 : greyColor40}
                mt="-12px"
              >
                {item.label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}

export default HorizontalStepper;
