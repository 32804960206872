import React, { useEffect, useState } from "react";
import {
  blackShade05,
  blackShade19,
  blackShade21,
  blueColor04,
  borderTextBoxColor,
  greenColor,
  greyColor21,
  greyColor42,
  greyColor47,
  lightRedColor,
  redColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  FOOD_TYPE_DATA,
  ONDC_PARAMETERS_TABLE_HEADER,
  ONDC_PARAMETERS_TABLE_HEADER_NEW,
  TIME_TO_SHIP_UNIT,
  YES_NO_DROPDOWN_DATA,
} from "../../utils/data";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSelectedProducts,
  deleteSingleProducts,
  toggleProductRowSelect,
  updateProductList,
} from "../../reducers/catalogBulkUploadCopy/catalogBulkUploadSlice";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TabelCellInput from "./Components/Popups/TabelCellInput";
import {
  getCountryNames,
  getGstRates,
} from "../../reducers/catalog/CatalogSlice";
import TableDropdown from "./Components/TableDropdown";
import ModalCard from "../../components/ModalCard";
import DeleteAlert from "./Components/Popups/DeleteAlert";
import { useNavigate } from "react-router-dom";
import { textOneLines } from "../../utils/styles";

const OndcParameters = ({ productDetails, isLoading, setCurrentPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { countryList, gstRates, countryListLoading, gstRatesLoading } =
    useSelector((state) => state.catalog);

  const { selectedRowCount, deleteProductLoader, productPublished } =
    useSelector((state) => state.catalogBulkUploadCopy);

  const [showDeleteAlert, setShowDeleteAlert] = useState({
    show: false,
    data: null,
  });

  //handle table cell value change
  const handleValueChange = (key, value, index, rootKey) => {
    let allProducts = [...productDetails];

    const errors = { ...allProducts[index]?.ondcErrors };
    delete errors[key];

    if (key === "ondcEnabled") {
      allProducts[index] = {
        ...allProducts[index],
        [key]: value,
        ondcErrors: errors,
      };
      console.log("handleValueChange", allProducts[index]);
      dispatch(updateProductList({ productDetails: allProducts }));
      return;
    }

    if (rootKey) {
      allProducts[index] = {
        ...allProducts[index],
        ondc: {
          ...allProducts[index]?.ondc,
          [rootKey]: { ...allProducts[index]?.ondc?.[rootKey], [key]: value },
        },
        ondcErrors: errors,
      };
      console.log("handleValueChange", allProducts[index]);
      dispatch(updateProductList({ productDetails: allProducts }));
      return;
    }

    allProducts[index] = {
      ...allProducts[index],
      ondc: {
        ...allProducts[index]?.ondc,
        [key]: value,
      },
      ondcErrors: errors,
    };

    console.log("handleValueChange", allProducts[index]);
    dispatch(updateProductList({ productDetails: allProducts }));
  };

  const getFoodTypeValue = (index) => {
    const allProducts = [...productDetails];
    const foodTypeData = allProducts[index]?.ondc?.veg_nonveg || null;

    // console.log("getFoodTypeValue:", Object.keys(foodTypeData));

    let selectedValue = null;
    Object.keys(foodTypeData)?.forEach((item) => {
      if (foodTypeData[item] && foodTypeData[item]?.toLowerCase() === "yes") {
        // console.log("getFoodTypeValue:", index, "->", "Veg");

        if (!selectedValue) {
          selectedValue =
            item === "veg" ? "Veg" : item === "nonVeg" ? "Non Veg" : "Egg";
        }
      }
    });

    return selectedValue || "Select Value";
  };

  // handling delete bulk products
  const handleDeleteProducts = async () => {
    if (deleteProductLoader) return;

    setShowDeleteAlert({ show: false, data: null });

    const businessId = sessionStorage.getItem("selectedBusinessId");

    if (businessId) {
      dispatch(deleteSelectedProducts({ productDetails, businessId })).then(
        (res) => {
          if (selectedRowCount === productDetails?.length) navigate(0);
        }
      );
    }
  };

  // handling delete single products
  const handleDeleteSingleProducts = async (productIndex) => {
    if (deleteProductLoader) return;

    setShowDeleteAlert({ show: false, data: null });

    const businessId = sessionStorage.getItem("selectedBusinessId");

    if (businessId) {
      console.log("handleDeleteSingleProducts", {
        productDetails,
        businessId,
        productIndex,
      });
      dispatch(
        deleteSingleProducts({ productDetails, businessId, productIndex })
      ).then((res) => {
        if (productDetails?.length === 1) navigate(0);
      });
    }
  };

  useEffect(() => {
    dispatch(getCountryNames());
    dispatch(getGstRates());
    return () => {};
  }, []);

  useEffect(() => {
    if (productPublished) {
      setCurrentPage("published");
    }

    return () => {};
  }, [productPublished]);

  return (
    <Box>
      {/* {JSON.stringify(gstRates)} */}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography sx={styles.heading}>ONDC Parameters</Typography>
          <Typography sx={styles.subHeading}>
            {selectedRowCount} of {productDetails?.length} rows selected for
            updates and import. Go to Step 3 to choose additional rows for
            import.
          </Typography>
        </Box>

        {/* {selectedRowCount > 0 ? (
          <Button
            variant="contained"
            disableElevation
            sx={styles.deleteBtn}
            onClick={() => {
              setShowDeleteAlert({
                show: true,
                data: {
                  message: `Are you sure you want to delete the selected ${
                    selectedRowCount === 1
                      ? `${selectedRowCount} Row?`
                      : `${selectedRowCount} Rows?`
                  }`,
                },
              });
            }}
            startIcon={
              deleteProductLoader ? (
                <CircularProgress color="inherit" size={14} />
              ) : null
            }
          >
            DELETE{" "}
            {selectedRowCount === 1
              ? `${selectedRowCount} Row`
              : `${selectedRowCount} Rows`}
          </Button>
        ) : (
          <Box />
        )} */}
      </Box>

      {isLoading || countryListLoading || gstRatesLoading ? (
        <ThreeDotLoader />
      ) : (
        <Box sx={styles.tableWrapper}>
          <TableContainer sx={styles.tableContainer} component={Box}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    position: "sticky !important",
                    top: 0,
                    backgroundColor: greyColor42,
                    zIndex: 20,
                    borderRadius: "8px 8px 0 0",
                  }}
                >
                  {ONDC_PARAMETERS_TABLE_HEADER_NEW?.map((item, index) => {
                    return (
                      <TableCell
                        key={item.id}
                        sx={{
                          ...styles.tableCell,
                          ...styles.colSticky,
                        }}
                      >
                        <Box
                          display={index === 0 ? "flex" : "inline-block"}
                          alignItems="center"
                          gap={1.2}
                        >
                          {index === 0 && (
                            <Checkbox
                              // size="large"
                              // checked={productDetails?.every(
                              //   (item) => item.isSelected
                              // )}
                              checked={true}
                              onChange={({ target }) => {
                                // dispatch(
                                //   selectAllProductRows({
                                //     productDetails,
                                //     isSelected: target.checked,
                                //   })
                                // );
                              }}
                              sx={styles.checkbox}
                            />
                          )}
                          <Box sx={{ fontSize: 12 }}>
                            {item?.title}{" "}
                            {item?.isRequired && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </Box>
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody sx={{ backgroundColor: whiteColor }}>
                {productDetails?.map((productInfo, index) => {
                  if (!productInfo.isSelected) return null;

                  const isOndcEnabled =
                    productInfo?.ondcEnabled?.toUpperCase() === "YES";
                  const isCancellable =
                    productInfo?.ondc?.cancelTerms?.cancellable?.toUpperCase() ===
                    "YES";

                  // const isReturnable =
                  //   productInfo?.ondc?.returnTerms?.returnable?.toUpperCase() ===
                  //   "YES";

                  const isReturnable = true;

                  const isFoodItems =
                    productInfo?.ondc?.foodRelatedItems?.toUpperCase() ===
                    "YES";
                  const isPackaged =
                    productInfo?.ondc?.isPackaged?.toUpperCase() === "YES";
                  const isPrePackaged =
                    productInfo?.ondc?.isPrePackaged?.toUpperCase() === "YES";

                  return (
                    <TableRow
                      key={productInfo?.id}
                      sx={{
                        borderBottom: `1px solid ${borderTextBoxColor}`,
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell
                        sx={{
                          ...styles.tableStickyLeftCell,
                        }}
                      >
                        <Box display="flex" alignItems="flex-start" gap={1.2}>
                          <Checkbox
                            // size="large"
                            sx={styles.checkbox}
                            checked={productInfo?.isSelected || false}
                            onChange={() => {
                              dispatch(
                                toggleProductRowSelect({
                                  productDetails,
                                  index,
                                  isSelected: productInfo?.isSelected,
                                })
                              );
                            }}
                          />
                          <Typography
                            variant="body1"
                            title={productInfo?.productName}
                            sx={{ ...styles.prodNameBox, ...textOneLines }}
                          >
                            {productInfo?.productName}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell sx={styles.tableBodyCell(false)}>
                        <TableDropdown
                          value={
                            productInfo?.ondcEnabled?.toUpperCase() || "NO"
                          }
                          onValueChange={(value) => {
                            handleValueChange("ondcEnabled", value, index);
                          }}
                          menuList={YES_NO_DROPDOWN_DATA}
                          borderFull
                          rounded
                          width="110px"
                        />
                      </TableCell>

                      <TableCell
                        sx={styles.tableBodyCell(
                          productInfo?.ondcErrors?.shipmentTime
                        )}
                      >
                        <TabelCellInput
                          value={productInfo?.ondc?.shipmentTime}
                          onValueChange={(value) => {
                            handleValueChange("shipmentTime", value, index);
                          }}
                          regexType="number"
                          errorMsg={productInfo?.ondcErrors?.shipmentTime}
                          disabled={!isOndcEnabled}
                          minWidth="100px"
                        />
                      </TableCell>

                      <TableCell sx={styles.tableBodyCell(false)}>
                        <TableDropdown
                          value={
                            productInfo?.ondc?.timeToShipUnit?.label ||
                            "Minutes"
                          }
                          onValueChange={(value) => {
                            handleValueChange("timeToShipUnit", value, index);
                          }}
                          menuList={TIME_TO_SHIP_UNIT}
                          returnFullItem
                          borderFull
                          rounded
                          disabled={!isOndcEnabled}
                          width="190px"
                        />
                      </TableCell>

                      <TableCell sx={styles.tableBodyCell(false)}>
                        <TableDropdown
                          value={
                            productInfo?.ondc?.available_on_cod === true
                              ? "YES"
                              : "NO"
                          }
                          onValueChange={(value) => {
                            handleValueChange(
                              "available_on_cod",
                              value === "YES" ? true : false,
                              index
                            );
                          }}
                          menuList={YES_NO_DROPDOWN_DATA}
                          borderFull
                          rounded
                          disabled={!isOndcEnabled}
                          width="130px"
                        />
                      </TableCell>

                      <TableCell sx={styles.tableBodyCell(false)}>
                        <TableDropdown
                          value={
                            productInfo?.ondc?.fragile === true ? "YES" : "NO"
                          }
                          onValueChange={(value) => {
                            handleValueChange(
                              "fragile",
                              value === "YES" ? true : false,
                              index
                            );
                          }}
                          menuList={YES_NO_DROPDOWN_DATA}
                          borderFull
                          rounded
                          disabled={!isOndcEnabled}
                          width="80px"
                        />
                      </TableCell>

                      <TableCell sx={styles.tableBodyCell(false)}>
                        <TableDropdown
                          value={
                            productInfo?.ondc?.cancelTerms?.cancellable?.toUpperCase() ||
                            "NO"
                          }
                          onValueChange={(value) => {
                            handleValueChange(
                              "cancellable",
                              value,
                              index,
                              "cancelTerms"
                            );
                          }}
                          menuList={YES_NO_DROPDOWN_DATA}
                          borderFull
                          rounded
                          disabled={!isOndcEnabled}
                          width="100px"
                        />
                      </TableCell>

                      {/* <TableCell
                        sx={styles.tableBodyCell(
                          productInfo?.ondcErrors?.cancellationFeeAmount
                        )}
                      >
                        <TabelCellInput
                          value={
                            productInfo?.ondc?.cancelTerms
                              ?.cancellationFeeAmount
                          }
                          onValueChange={(value) => {
                            handleValueChange(
                              "cancellationFeeAmount",
                              value,
                              index,
                              "cancelTerms"
                            );
                          }}
                          regexType="number"
                          disabled={!isOndcEnabled || !isCancellable}
                          errorMsg={
                            productInfo?.ondcErrors?.cancellationFeeAmount
                          }
                        />
                      </TableCell> */}

                      <TableCell
                        sx={styles.tableBodyCell(
                          productInfo?.ondcErrors?.cancellationFeePercentage
                        )}
                      >
                        <TabelCellInput
                          value={
                            productInfo?.ondc?.cancelTerms
                              ?.cancellationFeePercentage
                          }
                          onValueChange={(value) => {
                            handleValueChange(
                              "cancellationFeePercentage",
                              value,
                              index,
                              "cancelTerms"
                            );
                          }}
                          regexType="number"
                          disabled={!isOndcEnabled || !isCancellable}
                          errorMsg={
                            productInfo?.ondcErrors?.cancellationFeePercentage
                          }
                        />
                      </TableCell>

                      <TableCell sx={styles.tableBodyCell(false)}>
                        <TableDropdown
                          value={
                            productInfo?.ondc?.cancelTerms?.refundEligible?.toUpperCase() ||
                            "NO"
                          }
                          onValueChange={(value) => {
                            handleValueChange(
                              "refundEligible",
                              value,
                              index,
                              "cancelTerms"
                            );
                          }}
                          menuList={YES_NO_DROPDOWN_DATA}
                          borderFull
                          rounded
                          disabled={!isOndcEnabled}
                          width="90px"
                        />
                      </TableCell>

                      <TableCell sx={styles.tableBodyCell(false)}>
                        <TableDropdown
                          value={
                            productInfo?.ondc?.returnTerms?.returnable?.toUpperCase() ||
                            "NO"
                          }
                          onValueChange={(value) => {
                            handleValueChange(
                              "returnable",
                              value,
                              index,
                              "returnTerms"
                            );
                          }}
                          menuList={YES_NO_DROPDOWN_DATA}
                          borderFull
                          rounded
                          disabled={!isOndcEnabled}
                          width="80px"
                        />
                      </TableCell>

                      <TableCell
                        sx={styles.tableBodyCell(
                          productInfo?.ondcErrors?.returnPeriod
                        )}
                      >
                        <TabelCellInput
                          value={productInfo?.ondc?.returnPeriodDays}
                          onValueChange={(value) => {
                            handleValueChange("returnPeriodDays", value, index);
                          }}
                          regexType="number"
                          disabled={!isOndcEnabled || !isReturnable}
                          errorMsg={productInfo?.ondcErrors?.returnPeriod}
                        />
                      </TableCell>

                      <TableCell
                        sx={styles.tableBodyCell(
                          productInfo?.ondcErrors?.seller_pickup_return
                        )}
                      >
                        <TableDropdown
                          value={
                            productInfo?.ondc?.returnTerms?.seller_pickup_return?.toUpperCase() ||
                            "NO"
                          }
                          onValueChange={(value) => {
                            handleValueChange(
                              "seller_pickup_return",
                              value,
                              index,
                              "returnTerms"
                            );
                          }}
                          menuList={YES_NO_DROPDOWN_DATA}
                          borderFull
                          rounded
                          disabled={!isOndcEnabled || !isReturnable}
                          width="90px"
                        />
                      </TableCell>

                      

                      <TableCell
                        sx={{
                          ...styles.tableStickyLeftCell,
                          ...styles.tableStickyRightCell,
                        }}
                      >
                        <Box
                          minWidth="50px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          gap={0.5}
                        >
                          <Stack alignItems="center" justifyContent="center">
                            {productInfo?.ondcErrors &&
                            Object.keys(productInfo?.ondcErrors).length > 0 ? (
                              <Box sx={styles.errorCountTag}>
                                {Object.keys(productInfo?.ondcErrors).length}
                              </Box>
                            ) : (
                              <CheckCircleIcon
                                sx={{ fontSize: 16, color: greenColor }}
                              />
                            )}
                          </Stack>

                          <IconButton
                            size="small"
                            onClick={() => {
                              handleDeleteSingleProducts(index);
                            }}
                          >
                            <DeleteIcon
                              sx={{ fontSize: "16px", color: redColor }}
                            />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <ModalCard
            open={showDeleteAlert?.show}
            handleClose={() => {
              setShowDeleteAlert({ show: false, data: null });
            }}
            width="33%"
          >
            <DeleteAlert
              alertMsg={showDeleteAlert?.data?.message}
              onCancel={() => {
                setShowDeleteAlert({ show: false, data: null });
              }}
              onDelete={() => {
                handleDeleteProducts();
              }}
            />
          </ModalCard>
        </Box>
      )}
    </Box>
  );
};

export default OndcParameters;

const styles = {
  heading: {
    fontSize: "14px",
    fontWeight: "600",
    color: blackShade19,
    mb: 0.2,
  },
  subHeading: {
    fontSize: "13px",
    fontWeight: "400",
    color: blackShade21,
    mb: 2,
  },

  tableWrapper: {
    width: "calc(100vw - 310px)",
    borderRadius: "8px",
    border: `1px solid ${borderTextBoxColor}`,
  },

  tableContainer: {
    borderRadius: "8px",
    overflow: "scroll",
    maxHeight: "calc(100vh - 370px)",
    maxWidth: "100%",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      // background: '#d0d3da',
      background: "rgba(0,0,0,0.1)",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.05)",
    },
  },

  tableCell: {
    color: greyColor47,
    fontSize: "12.5px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    padding: "8px 15px",
    backgroundColor: greyColor42,
    borderRight: `1px solid ${whiteColor}`,
  },

  colSticky: {
    // position: "-webkit-sticky",
    // position: isSticky ? "sticky" : "unset",
    // width: isSticky ? "100px" : "auto",
    // minWidth: isSticky ? "100px" : "auto",
    // maxWidth: isSticky ? "100px" : "auto",
    // left: "0px",
    // right: isRight ? "0px" : "unset",

    ":first-child": { position: "sticky", left: "0px" },
    // ":nth-last-child(2)": {
    //   position: "sticky",
    //   right: "78px",
    // },
    ":last-child": { position: "sticky", right: "-3px" },
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  statuSticky: (isStatus) => ({
    right: isStatus ? "100px" : "unset",
  }),

  colStickyRight: (isSticky) => ({
    position: "-webkit-sticky",
    position: isSticky ? "sticky" : "unset",
    width: isSticky ? "100px" : "auto",
    minWidth: isSticky ? "100px" : "auto",
    maxWidth: isSticky ? "100px" : "auto",
    right: "0px",
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  }),

  tableBodyCell: (isError) => ({
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: isError ? lightRedColor : whiteColor,
    padding: "8px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,

    boxShadow: isError ? `0px -1px 0 0 ${redColor} inset` : "none",
  }),

  tableStickyLeftCell: {
    minWidth: "70px !important",
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: whiteColor,
    padding: "10px 15px !important",
    borderRight: `1px solid ${borderTextBoxColor}`,
    position: "sticky",
    left: 0,
    zIndex: 10,
    boxShadow: `-1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  tableStickyRightCell: {
    position: "sticky",
    right: 0,
    boxShadow: `1px 0 0 0 ${borderTextBoxColor} inset`,
  },

  prodNameBox: {
    minWidth: "250px",
    maxWidth: "250px",
    whiteSpace: "normal",
    fontSize: 12,
    fontWeight: 600,
    color: blackShade19,
    lineHeight: "16px",
  },

  prodDescriptionBox: {
    minWidth: "550px",
    maxWidth: "650px",
    whiteSpace: "normal",
  },

  checkbox: {
    color: greyColor21,
    p: 0,
    transform: "scale(1.3)",
    "&.Mui-checked": {
      color: blueColor04,
    },
  },

  errorCountTag: {
    backgroundColor: lightRedColor,
    color: redColor,
    fontSize: 12,
    fontWeight: 600,
    p: "3px 18px",
    borderRadius: "10px",
  },

  deleteBtn: {
    fontSize: 13,
    fontWeight: 500,
    color: whiteColor,
    p: "4px 30px",
    border: `1.5px solid ${redColor}`,
    backgroundColor: redColor,
    "&:hover": {
      color: redColor,
      backgroundColor: whiteColor,
    },
  },
};
