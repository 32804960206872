import React, { useEffect, useState } from "react";
import {
  bgColor15,
  blackShade05,
  blackShade159,
  blackShade21,
  blueColor04,
  greenColor2,
  greyColor6,
  greyColor8,
  logoImgborder1,
  mobilePrimary,
  orangeStatusColor,
  searchInputIconColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import NumberSearchInput from "../../components/Input/NumberSearchInput";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import PaycartStatsCard from "./PaycartStatsCard";
import PaycartOrderCard from "./PaycartOrderCard";
import moment from "moment";
import ModalCard from "../../components/ModalCard";
import ViewOrderPopupContent from "../Orders/Popups/ViewOrderPopupContent";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import OrderTrackingPopupContent from "../Orders/Popups/OrderTrackingPopupContent";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPaycartOrderInfo,
  getAllBusinessPaycartOrderData,
} from "../../reducers/paycartOrders/paycartOrderSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import { PAYCART_TABS } from "../../utils/data";
import ListEmptyCard from "../../components/ListEmptyCard";
import ViewPayCartOrderPopup from "./Popups/ViewPayCartOrderPopup";
import CreatePaycartAlert from "./Popup/CreatePaycartAlert";
import { getProfileId } from "../../utils/validationUtils";
import { encryptAuth } from "../../utils/authHelpers";
import { useNavigate } from "react-router-dom";
import { stickyHeader } from "../../utils/styles";
function PaycartOrdersContent({ businessName, activeBusinessInfo, summary }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const PAGE_SIZE = 25;

  const dispatch = useDispatch();
  const [hasMoreData, setHasMoreData] = useState(true);

  // const [number, setNumber] = useState("");
  const [openViewOrder, setOpenViewOrder] = useState({
    show: false,
    data: null,
  });

  const [openTrackOrder, setOpenTrackOrder] = useState({
    show: false,
    data: null,
  });

  const [tabValue, setTabValue] = useState("all");
  const [payCartData, setPayCartData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchErr, setSearchErr] = useState("");
  const [isClearSearch, setIsClearSearch] = useState(false);
  const [openCreateAlert, setOpenCreateAlert] = useState(false);

  const { businessPaycartOrders, businessPaycartOrdersLoader, pageNo } =
    useSelector((state) => state.businessPaycartOrders);

  const navigate = useNavigate();

  const handleViewOrder = (data) => {
    setOpenViewOrder({ show: true, data });
  };

  const handleTrackOrder = (data) => {
    setOpenTrackOrder({ show: true, data });
  };

  const onWhatsappClick = (data) => {
    const orderUrl = `https://shop.costbo.com/${sessionStorage.getItem(
      "costboWEBURL"
    )}/easyCart/${data?.cartId}`;

    const message = `Experience shopping made simple with ${businessName}' Easy Cart.%0A%0AOrder Details: ${orderUrl}    
    %0A%0AFor a smooth order placement, kindly proceed with the payment through the following link: ${data?.paymentLink}    
    %0A%0AMake your purchase effortlessly with just a click!
    `;

    const phoneNumber = data?.customerAddress?.alternatePhoneNo
      ? data?.customerAddress?.alternatePhoneNo
      : data?.customerAddress?.phoneNo;

    window.open(
      `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${message}`,
      "_blank"
    );
  };

  const handleSearch = () => {
    if (!searchQuery) {
      setSearchErr("Enter Phone Number to search");
      return;
    }
    if (searchQuery?.length > 0 && searchQuery?.length < 5) {
      setSearchErr("Enter atleast 5 digits to search");
      return;
    }

    const filteredList = businessPaycartOrders?.filter(
      (item) =>
        item?.customerAddress?.alternatePhoneNo
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase()) ||
        item?.customerAddress?.phoneNo
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase())
    );

    setPayCartData(() => filteredList);
    setTabValue("all");
    setIsClearSearch(true);
  };

  const clearSearch = () => {
    setPayCartData(businessPaycartOrders);
    setIsClearSearch(false);
    setSearchQuery("");
  };

  const getOrdersData = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      getAllBusinessPaycartOrderData({
        businessId,
        pageNo: pageNo,
      })
    );
  };

  const fetchNextData = () => {
    // console.log("Next Hit");
    getOrdersData();
  };

  const onTabChange = (status) => {
    clearSearch();

    if (status === "all") {
      setPayCartData(businessPaycartOrders);
      setTabValue(status);
      return;
    }

    if (status === "paid") {
      const paidData = businessPaycartOrders?.filter(
        (item) =>
          item?.cartType === "businesspaycart" &&
          item?.orderStatus !== "paycartcreated"
      );
      setPayCartData(paidData);
      setTabValue(status);
      return;
    }
    if (status === "notPaid") {
      const notPaidData = businessPaycartOrders?.filter(
        (item) =>
          item?.cartType === "businesspaycart" &&
          item?.orderStatus === "paycartcreated"
      );
      setPayCartData(notPaidData);
      setTabValue(status);
      return;
    }
  };

  const getOrderStatusText = (status) => {
    switch (status) {
      case "paycartcreated":
        return "Easy Cart Created";
      case "ordercreated":
        return "Order Created";
      case "orderapproved":
        return "Order Approved";
      case "ordershipped":
        return "Order Shipped";
      case "orderdelivered":
        return "Order Delivered";
      case "bordercanceled":
        return "Order Canceled";
      case "cordercanceled":
        return "Order Canceled";

      default:
        return "";
    }
  };

  const handleCreateNewPaycart = () => {
    const token = sessionStorage.getItem("token");
    const profileId = getProfileId();

    const costBoUrl = sessionStorage.getItem("costboWEBURL");

    const authData = {
      id: profileId,
      path: "/catalog",
      accessToken: token,
    };

    const encryptedAuth = encryptAuth(authData);

    const openUrl = `https://shop.costbo.com/${costBoUrl}/userauth?id=${encryptedAuth}`;
    // const openUrl = `https://prelive-shop.costbo.com/${costBoUrl}/userauth?id=${encryptedAuth}`;
    // const openUrl = `localhost:3001/${costBoUrl}/userauth?id=${encryptedAuth}`

    console.log("OPEN_URL", encryptedAuth);

    window.open(openUrl, "_blank");
    setOpenCreateAlert(false);
  };

  useEffect(() => {
    getOrdersData();
    return () => {
      dispatch(clearPaycartOrderInfo());
    };
  }, []);

  useEffect(() => {
    if (businessPaycartOrders) {
      onTabChange(tabValue);
    }
  }, [businessPaycartOrders]);

  useEffect(() => {
    if ((pageNo - 1) * PAGE_SIZE > businessPaycartOrders?.length) {
      setHasMoreData(false);
    }
    return () => {};
  }, [pageNo]);

  return (
    <Box>
      <Box sx={{ ...stickyHeader, pb: 2 }}>
        <Box
          display="flex"
          alignItems={{ md: "flex-start", xs: "center" }}
          justifyContent="space-between"
          flexWrap="wrap"
          gap={2}
          mb={{ xs: 2, md: 2 }}
        >
          <Box>
            <Typography
              component="h4"
              fontSize={{ xs: 16, md: 17 }}
              color={blackShade05}
              fontWeight={600}
              lineHeight="20px"
            >
              Book Order
            </Typography>

            <Typography
              fontSize={{ xs: 12, md: 13 }}
              fontWeight={500}
              color={blackShade21}
              lineHeight={{ xs: "17px", md: "24px" }}
              mt={0.4}
            >
              Hassle free{" "}
              <span style={{ color: matches ? blueColor04 : mobilePrimary }}>
                Order Booking!
              </span>{" "}
              You can book orders on behalf of your customers and send the{" "}
              <span style={{ color: matches ? blueColor04 : mobilePrimary }}>
                payment link via WhatsApp
              </span>
            </Typography>
          </Box>

          <Box
            display={{ xs: "none", md: "flex" }}
            alignItems="flex-start"
            justifyContent="space-between"
            flexDirection="column"
            gap="10px"
          >
            <Box
              display={{ xs: "none", md: "flex" }}
              alignItems="center"
              justifyContent="space-between"
              gap="20px"
            >
              <Button
                sx={styles.addIconBtn}
                variant="contained"
                disableElevation
                onClick={() => {
                  setOpenCreateAlert(true);
                }}
                startIcon={<AddIcon />}
              >
                Create Order
              </Button>
              <IconButton onClick={() => navigate(0)}>
                <RefreshIcon fontSize="large" sx={{ color: greyColor8 }} />
              </IconButton>
            </Box>
          </Box>

          <Box display={{ xs: "flex", md: "none" }}>
            <Button
              sx={styles.addIconBtn}
              variant="contained"
              disableElevation
              onClick={() => {
                setOpenCreateAlert(true);
              }}
            >
              Create Order
            </Button>
          </Box>
        </Box>
        <Box
          display={{ md: "flex", xs: "none" }}
          alignItems={{ md: "flex-start", xs: "center" }}
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Box
            flex={1}
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            gap={{ xs: "10px", md: "20px" }}
            mb={{ xs: 4, md: 1 }}
          >
            <Box display="flex" alignItems="center" gap="25px">
              {PAYCART_TABS?.map((item, index) => (
                <OrderPaymentLegend
                  dotColor={item.color}
                  isActive={item.value === tabValue}
                  label={item.label}
                  key={"tab" + index}
                  onClick={() => onTabChange(item.value)}
                  disabled={businessPaycartOrders?.length === 0}
                />
              ))}
            </Box>
          </Box>

          {businessPaycartOrders?.length > 0 && (
            <Box
              flex={1}
              display="flex"
              flexDirection={{ xs: "column", md: "colum" }}
              gap={{ xs: "10px", md: "20px" }}
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
              width={"100%"}
              // mt={1}
            >
              <NumberSearchInput
                placeholder="Customer Phone Number"
                value={searchQuery}
                onChange={({ target }) => {
                  const value = target?.value?.replace(/[^0-9]/g, "");
                  if (value === "") {
                    clearSearch();
                    return;
                  }
                  setSearchQuery(value);
                  if (searchErr) setSearchErr("");
                }}
                icon={
                  <SearchIcon
                    sx={{ fontSize: "20px", color: searchInputIconColor }}
                    onClick={handleSearch}
                  />
                }
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    // Call your function here
                    handleSearch();
                  }
                }}
                maxLength={10}
                error={searchErr}
              />
              {isClearSearch && (
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection={{ xs: "column", md: "row" }}
                  gap="6px"
                >
                  <Typography
                    component="h4"
                    fontSize={{ md: 13, xs: 13 }}
                    color={blackShade05}
                    fontWeight={400}
                    lineHeight="20px"
                  >
                    Search result for Phone number “{searchQuery}”
                  </Typography>

                  <Chip
                    label={searchQuery}
                    sx={{ fontSize: "12px", height: "28px" }}
                    onDelete={clearSearch}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Box
          width={{ xs: "100%", md: "auto" }}
          display={{ xs: "flex", md: "none" }}
          alignItems="center"
          justifyContent="space-between"
          mt="10px"
        >
          <Box display="flex" alignItems="center" gap="25px">
            {PAYCART_TABS?.map((item, index) => (
              <OrderPaymentLegend
                dotColor={item.color}
                isActive={item.value === tabValue}
                label={item.label}
                key={"tab" + index}
                onClick={() => onTabChange(item.value)}
                disabled={businessPaycartOrders?.length === 0}
              />
            ))}
          </Box>
        </Box>

        {businessPaycartOrders?.length > 0 && (
          <Box
            display={{ md: "none", xs: "flex" }}
            alignItems="flex-start"
            justifyContent="space-between"
            flexDirection="column"
            gap="10px"
            mt={"15px"}
          >
            <NumberSearchInput
              placeholder="Customer Phone Number"
              value={searchQuery}
              onChange={({ target }) => {
                const value = target?.value?.replace(/[^0-9]/g, "");
                setSearchQuery(value);
                if (searchErr) setSearchErr("");
              }}
              icon={
                <SearchIcon
                  sx={{ fontSize: "20px", color: searchInputIconColor }}
                  onClick={handleSearch}
                />
              }
              maxLength={10}
              error={searchErr}
            />

            {isClearSearch && (
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDirection={{ xs: "column", md: "row" }}
                gap="6px"
              >
                <Typography
                  component="h4"
                  fontSize={{ md: 13, xs: 13 }}
                  color={blackShade05}
                  fontWeight={400}
                  lineHeight="20px"
                >
                  Search result for Phone number “{searchQuery}”
                </Typography>

                <Chip
                  label={searchQuery}
                  sx={{ fontSize: "12px", height: "28px" }}
                  onDelete={clearSearch}
                />
              </Box>
            )}
            {/* {isClearSearch && (
            <Box
              display="flex"
              alignItems="center"
              flexDirection={{ xs: "column", md: "row" }}
              gap="6px"
            >
              <Typography
                component="h4"
                fontSize={{ md: 13, xs: 13 }}
                color={blackShade05}
                fontWeight={400}
                lineHeight="20px"
              >
                Search result for Phone number “{searchQuery}”
              </Typography>

              <Chip
                label={searchQuery}
                sx={{ fontSize: "12px", height: "28px" }}
                onDelete={clearSearch}
              />
            </Box>
          )} */}
          </Box>
        )}

        <PaycartStatsCard summary={summary} />
      </Box>
      <Box>
        {/* <PaycartStatsCard summary={summary} /> */}

        <Box
          display="flex"
          // alignItems="center"
          flexDirection="column"
          pb={"20px"}
        >
          {!isClearSearch ? (
            payCartData?.length > 0 ? (
              <>
                <InfiniteScroll
                  dataLength={payCartData?.length || 0}
                  next={fetchNextData}
                  hasMore={hasMoreData}
                  loader={
                    <Box>
                      <ThreeDotLoader />
                    </Box>
                  }
                >
                  {payCartData?.map((item, index) => {
                    return (
                      <PaycartOrderCard
                        key={"easyCart" + index}
                        status={getOrderStatusText(item?.orderStatus)}
                        profileImg={
                          `https://storage.googleapis.com/bo3151920215/account/${item?.profileId}/profilephoto.jpeg`
                            ? ""
                            : ""
                        }
                        username={item?.customerAddress?.name}
                        phoneNumber={
                          item?.customerAddress?.alternatePhoneNo
                            ? item?.customerAddress?.alternatePhoneNo
                            : item?.customerAddress?.phoneNo
                        }
                        email={item?.customerAddress?.emails}
                        orderId={item?.orderId}
                        address={
                          item?.customerAddress
                            ? `${item?.customerAddress?.addressLine1}, ${item?.customerAddress?.city}, ${item?.customerAddress?.state} - ${item?.customerAddress?.zip}`
                            : "--"
                        }
                        orderCost={item?.orderTotalCost}
                        cartCount={item?.totalQuantity}
                        cartDate={moment(item?.orderRequestedOn).format(
                          "Do MMM YYYY"
                        )}
                        orderDate={
                          item?.orderDate
                            ? moment(item?.orderDate).format("Do MMM YYYY")
                            : "-/-"
                        }
                        isPaid={
                          item?.cartType === "businesspaycart" &&
                          item?.orderStatus !== "paycartcreated"
                        }
                        paymentLink={
                          item?.paymentLink || "No Payment Link Generated"
                        }
                        orderDetailsLink={`https://shop.costbo.com/${sessionStorage.getItem(
                          "costboWEBURL"
                        )}/easycart/${item?.cartId}`}
                        handleViewOrder={() => handleViewOrder(item)}
                        handleTrackOrder={() => handleTrackOrder(item)}
                        onWhatsappClick={() => onWhatsappClick(item)}
                      />
                    );
                  })}
                </InfiniteScroll>
              </>
            ) : summary?.totalPaycartcreated != 0 && tabValue !== "all" ? (
              <Box width="100%">
                <ListEmptyCard
                  icon={
                    <ProductionQuantityLimitsOutlinedIcon
                      sx={{ fontSize: "24px" }}
                    />
                  }
                  text={
                    tabValue === "paid"
                      ? "No paid Easy Cart orders"
                      : "No unpaid Easy Cart orders"
                  }
                  hideBtn
                />
              </Box>
            ) : null
          ) : null}
          {isClearSearch ? (
            payCartData?.length > 0 ? (
              <>
                {payCartData?.map((item, index) => {
                  return (
                    <PaycartOrderCard
                      key={"easyCart" + index}
                      status={getOrderStatusText(item?.orderStatus)}
                      profileImg={
                        `https://storage.googleapis.com/bo3151920215/account/${item?.profileId}/profilephoto.jpeg`
                          ? ""
                          : ""
                      }
                      username={item?.customerAddress?.name}
                      phoneNumber={
                        item?.customerAddress?.alternatePhoneNo
                          ? item?.customerAddress?.alternatePhoneNo
                          : item?.customerAddress?.phoneNo
                      }
                      email={item?.customerAddress?.emails}
                      orderId={item?.orderId}
                      address={
                        item?.customerAddress
                          ? `${item?.customerAddress?.addressLine1}, ${item?.customerAddress?.city}, ${item?.customerAddress?.state} - ${item?.customerAddress?.zip}`
                          : "--"
                      }
                      orderCost={item?.orderTotalCost}
                      cartCount={item?.totalQuantity}
                      cartDate={moment(item?.cartDate).format("Do MMM YYYY")}
                      orderDate={
                        item?.orderDate
                          ? moment(item?.orderDate).format("Do MMM YYYY")
                          : "-/-"
                      }
                      isPaid={
                        item?.cartType === "businesspaycart" &&
                        item?.orderStatus !== "paycartcreated"
                      }
                      paymentLink={
                        item?.paymentLink || "No Payment Link Generated"
                      }
                      orderDetailsLink={`https://shop.costbo.com/${sessionStorage.getItem(
                        "costboWEBURL"
                      )}/easycart/${item?.cartId}`}
                      handleViewOrder={() => handleViewOrder(item)}
                      handleTrackOrder={() => handleTrackOrder(item)}
                      onWhatsappClick={() => onWhatsappClick(item)}
                    />
                  );
                })}
              </>
            ) : (
              <Box width="100%">
                {businessPaycartOrders?.length > 0 && (
                  <ListEmptyCard
                    icon={
                      <ProductionQuantityLimitsOutlinedIcon
                        sx={{ fontSize: "24px" }}
                      />
                    }
                    text={`No Easy Cart Found for Phone number “${searchQuery}”`}
                    hideBtn
                  />
                )}
              </Box>
            )
          ) : null}
          {summary?.totalPaycartcreated == 0 ? (
            <Box
              component="img"
              src="https://storage.googleapis.com/bodefaults/businessdashboardweb/easy-cart-banner.jpg"
              width={{ xs: "100%", md: "100%" }}
              sx={{ borderRadius: "15px", mt: "10px" }}
            />
          ) : null}
        </Box>
      </Box>
      <ModalCard
        open={openViewOrder?.show}
        handleClose={() => {
          setOpenViewOrder({ show: false, data: null });
        }}
        width="85%"
      >
        <ViewPayCartOrderPopup orderData={openViewOrder?.data || null} />
      </ModalCard>

      <ModalCard
        open={openTrackOrder.show}
        handleClose={() => {
          setOpenTrackOrder({ show: false, data: null });
        }}
        // width="30%"
      >
        <OrderTrackingPopupContent orderData={openTrackOrder?.data || null} />
      </ModalCard>

      <ModalCard
        open={openCreateAlert}
        handleClose={() => {
          setOpenCreateAlert(false);
        }}
        width="28%"
      >
        <CreatePaycartAlert
          businessName={activeBusinessInfo?.name}
          onCancel={() => {
            setOpenCreateAlert(false);
          }}
          onProceed={handleCreateNewPaycart}
        />
      </ModalCard>
    </Box>
  );
}

export default PaycartOrdersContent;

const OrderPaymentLegend = ({
  dotColor,
  label,
  onClick,
  isActive,
  disabled,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="5px"
      sx={{ ...styles.legendContainer(isActive), opacity: disabled ? 0.8 : 1 }}
    >
      <Box sx={{ ...styles.legendDot, backgroundColor: dotColor }} />
      <Typography
        color={greyColor6}
        fontSize="13px"
        fontWeight="400"
        sx={{ cursor: "pointer" }}
        onClick={() => {
          if (disabled) return false;
          onClick();
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

const styles = {
  legendDot: {
    width: "8px",
    height: "8px",
    backgroundColor: bgColor15,
    borderRadius: "4px",
    gap: "2px",
  },

  addIconBtn: {
    padding: { md: "4px 22px", xs: "4px 10px" },
    fontSize: { md: "13px", xs: "12px" },
    fontWeight: 600,
    color: { md: blueColor04, xs: mobilePrimary },
    backgroundColor: whiteColor,
    border: {
      md: "1.5px solid " + blueColor04,
      xs: "1.5px solid " + mobilePrimary,
    },
    "&:hover": {
      color: whiteColor,
      backgroundColor: { md: blueColor04, xs: mobilePrimary },
    },
  },

  createIconBtn: {
    width: "34px",
    height: "34px",
    color: greyColor8,
    backgroundColor: whiteColor,
    border: "1.5px solid " + greyColor8,
    borderRadius: "5px",
    display: { xs: "flex", md: "none" },
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor8,
    },
  },

  cardContainer: {
    backgroundColor: { xs: "none", md: whiteColor },
    baxShadow: { xs: "none", md: "0 3px 4px rgb(229 233 240 / 22%)" },
    padding: { xs: "0px", md: "15px" },
    border: { xs: "none", md: logoImgborder1 },
    borderRadius: "13px",
  },
  legendContainer: (isActive) => ({
    position: "relative",
    cursor: "pointer",
    "&::before": {
      content: "' '",
      width: "25px",
      height: "2px",
      backgroundColor: isActive ? blackShade159 : "transparent",
      borderRadius: "3px",
      position: "absolute",
      bottom: -3,
      // right: {xs:65,md:0},
      left: 10,
    },
  }),
};
