import { Box, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BGColor02,
  bgColor15,
  blackShade05,
  blackShade21,
  blueColor04,
  greyColor6,
  greyColor9,
  logoImgborder,
  mobilePrimary,
  whiteColor,
} from "../../configs/styles/muiThemes";
import HowToLinkCard from "../../components/Cards/HowToLinkCard";
import { stickyHeader, scrollBox } from "../../utils/styles";
import MobileTab from "../../components/MobileTabs/MobileTab";
import { RETURNS_TAB_DATA } from "../../utils/data";
import SearchBoxInput from "../../components/Input/SearchBoxInput";
import NewReturnOrdersContent from "./TabContent/NewReturnOrdersContent";
import { useDispatch, useSelector } from "react-redux";
import ModalCard from "../../components/ModalCard";
import ViewReturnOrderPopupContent from "./Popups/ViewReturnOrderPopupContent";
import CompletedReturnOrdersContent from "./TabContent/CompletedReturnOrdersContent";
import {
  getCompletedReturnOrderData,
  getNewReturnOrderData,
  refreshCompltedReturnOrderData,
  refreshNewReturnOrderData,
} from "../../reducers/manageReturns/manageReturnsSlice";
import { useNavigate } from "react-router-dom";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import ListEmptyCard from "../../components/ListEmptyCard";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import { getBuyerAppLookupAPI } from "../../service/api_calls/BusinessPreview/BusinessPreviewAPI";

const ManageReturnsContent = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    newReturnOrders,
    newReturnPageNo,
    newReturnOrdersLoader,
    completedReturnOrders,
    completedReturnPageNo,
    completedReturnOrdersLoader,
  } = useSelector((state) => state.manageReturns);

  const [tabValue, setTabValue] = useState(0);
  const [tabTitle, setTabTitle] = useState("New/In Process");

  const [searchQuery, setSearchQuery] = useState("");

  const [openViewOrder, setOpenViewOrder] = useState({
    show: false,
    data: null,
    type: "view",
  });

  const getTabTitle = (tab) => {
    switch (tab) {
      case 0:
        return "New/In Process";
      case 1:
        return "Completed";
      default:
        return "New/In Process";
    }
  };

  const handleChange = (event, newValue) => {
    // dispatch(handleRefreshTabData());
    setTabTitle(getTabTitle(newValue));
    // setOpen(false);
    // // updateOrderList(newValue);
    setTabValue(newValue);
  };

  // New Orders
  const getNewOrdersData = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      getNewReturnOrderData({
        businessId,
        pageNo: newReturnPageNo,
      })
    );
  };

  // Completed Orders
  const getCompletedOrdersData = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      getCompletedReturnOrderData({
        businessId,
        pageNo: completedReturnPageNo,
      })
    );
  };

  const refreshTabs = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      refreshNewReturnOrderData({
        businessId,
        pageNo: 1,
      })
    );

    dispatch(
      refreshCompltedReturnOrderData({
        businessId,
        pageNo: 1,
      })
    );
  };

  const getOrderCout = (tab) => {
    if (tab === 0)
      return newReturnOrdersLoader ? "XX" : newReturnOrders?.length || 0;
    if (tab === 1)
      return completedReturnOrdersLoader
        ? "XX"
        : completedReturnOrders?.length || 0;

    return 0;
  };

  const handleViewClick = (data, orderType) => {
    setOpenViewOrder({
      show: true,
      data: { ...data, orderType },
      type: "view",
    });
  };

  const handleActionClick = (data) => {
    setOpenViewOrder({ show: true, data, type: "action" });
  };

  // get all return quantity
  const getAllReturnQty = (productlist) => {
    const prodArr = [...productlist];
    const returnQty = prodArr?.reduce(
      (sum, item) => sum + Number(item.returnQuantity),
      0
    );

    return returnQty;
  };

  // Close Action Popup after Submit Process
  const handleCloseAfterSubmit = () => {
    setOpenViewOrder({ show: false, data: null, type: "view" });
    refreshTabs();
    // navigate(0);
  };
   const [bapNameList, setBapNameList] = useState(null);

   const getBapDisplayNameInfo = async () => {
     const response = await getBuyerAppLookupAPI();

     if (response?.details?.length > 0) {
       setBapNameList(response?.details || []);
     }
   };
   // Get ONDC Bap Display Name by Subscriber ID
   const getOndcBapNameBySubscriberId = (id) => {
     const data = bapNameList?.find((item) => item?.subscriberId === id);
     return data ? data?.displayName : "";
   };
   useEffect(() => {
     getBapDisplayNameInfo();
   }, []);

  useEffect(() => {
    getNewOrdersData("refresh");
    getCompletedOrdersData("refresh");

    return () => {};
  }, []);

  return (
    <Box>
      <Box sx={stickyHeader}>
        <Box sx={styles.header}>
          <Box>
            <Typography
              component="h4"
              fontSize={{ xs: 16, md: 17 }}
              color={blackShade05}
              fontWeight={600}
              lineHeight="20px"
            >
              Manage Returns
            </Typography>

            <Typography
              fontSize={{ xs: 12, md: 13 }}
              fontWeight={500}
              color={blackShade21}
              lineHeight={{ xs: "17px", md: "24px" }}
              mt={0.4}
            >
              Return requests will be processed only for{" "}
              <span style={{ color: matches ? blueColor04 : mobilePrimary }}>
                ONDC
              </span>{" "}
              orders
            </Typography>
          </Box>
        </Box>

        {matches ? (
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              mb: { xs: "10px", md: 1 },
              mt: 1,
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => handleChange(event, newValue)}
              textColor={blueColor04}
              TabIndicatorProps={{
                style: {
                  height: "3px",
                  backgroundColor: blueColor04,
                },
              }}
              sx={{
                minHeight: "35px !important",
                "& .MuiButtonBase-root": {
                  minWidth: "auto !important",
                },
              }}
            >
              {RETURNS_TAB_DATA?.map((item, index) => {
                return (
                  <Tab
                    key={"order_" + index}
                    label={item.label + ` - ${getOrderCout(index)}`}
                    sx={styles.tabStyles(tabValue, index, item.id)}
                  />
                );
              })}
            </Tabs>
          </Box>
        ) : (
          <Box sx={{ ...styles.mobileTabs, ...scrollBox }}>
            {RETURNS_TAB_DATA?.map((item, index) => {
              return (
                <MobileTab
                  title={item.mobileLabel}
                  count={getOrderCout(index)}
                  isActive={tabValue == index}
                  onClick={() => handleChange("", item.id)}
                />
              );
            })}
          </Box>
        )}

        {matches && (
          <Box sx={styles.headerContainer}>
            <Box sx={styles.headerTitleBox}>
              <Typography component="h5" sx={styles.headerTitle}>
                {tabTitle}{" "}
                <Typography component="span" sx={styles.orderCountText}>
                  - {getOrderCout(tabValue)}
                </Typography>
              </Typography>
            </Box>

            {/* <Box width={{ xs: "100%", md: "auto" }}>
              <SearchBoxInput
                label=""
                placeholder="Search by Order ID"
                value={searchQuery}
                onChange={(text) => {
                  //   if (text === "") {
                  //     clearSearch();
                  //     return;
                  //   }
                  setSearchQuery(text);
                  //   setSearchEnabled(true);
                }}
                // onSearchClick={handleSearch}
                // errorMsg={errorMsg}
              />
            </Box> */}
          </Box>
        )}
      </Box>

      {!matches && (
        <Box sx={styles.headerContainer}>
          <Box sx={styles.headerTitleBox}>
            <Typography component="h5" sx={styles.headerTitle}>
              {tabTitle}{" "}
              <Typography component="span" sx={styles.orderCountText}>
                - {getOrderCout(tabValue)}
              </Typography>
            </Typography>
          </Box>

          {/* <Box width={{ xs: "100%", md: "auto" }}>
            <SearchBoxInput
              label=""
              placeholder="Search by Order ID"
              value={searchQuery}
              onChange={(text) => {
                //   if (text === "") {
                //     clearSearch();
                //     return;
                //   }
                setSearchQuery(text);
                //   setSearchEnabled(true);
              }}
              // onSearchClick={handleSearch}
              // errorMsg={errorMsg}
            />
          </Box> */}
        </Box>
      )}

      <Box>
        {newReturnOrdersLoader || completedReturnOrdersLoader ? (
          <ThreeDotLoader />
        ) : (
          <>
            {tabValue === 0 && newReturnOrders?.length > 0 ? (
              <NewReturnOrdersContent
                onViewOrderClick={(data) => handleViewClick(data, "new")}
                onActionClick={(data) => {
                  handleActionClick(data);
                }}
                getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
                getAllReturnQty={getAllReturnQty}
              />
            ) : tabValue === 0 ? (
              <Box width="100%" mt={1}>
                <ListEmptyCard
                  icon={
                    <ProductionQuantityLimitsOutlinedIcon
                      sx={{ fontSize: "24px" }}
                    />
                  }
                  text="No ative return requests found"
                  hideBtn
                />
              </Box>
            ) : null}

            {tabValue === 1 && completedReturnOrders?.length > 0 ? (
              <CompletedReturnOrdersContent
                onViewOrderClick={(data) => handleViewClick(data, "completed")}
                getAllReturnQty={getAllReturnQty}
                getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
              />
            ) : tabValue === 1 ? (
              <Box width="100%" mt={1}>
                <ListEmptyCard
                  icon={
                    <ProductionQuantityLimitsOutlinedIcon
                      sx={{ fontSize: "24px" }}
                    />
                  }
                  text="No completed return found"
                  hideBtn
                />
              </Box>
            ) : null}
          </>
        )}
      </Box>

      {/* View Order Card */}
      <ModalCard
        open={openViewOrder?.show}
        handleClose={() => {
          setOpenViewOrder({ show: false, data: null, type: "view" });
        }}
        width="92%"
      >
        <ViewReturnOrderPopupContent
          data={openViewOrder?.data}
          getAllReturnQty={getAllReturnQty}
          type={openViewOrder?.type}
          getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
          handleCancel={() => {
            setOpenViewOrder({ show: false, data: null, type: "view" });
          }}
          handleCloseAfterSubmit={handleCloseAfterSubmit}
        />
      </ModalCard>
    </Box>
  );
};

export default ManageReturnsContent;

const styles = {
  header: {
    display: "flex",
    alignItems: { md: "center", xs: "flex-start" },
    justifyContent: "space-between",
    flexDirection: { md: "row", xs: "row" },
    mb: { xs: 0.8, md: 2 },
    mt: { xs: 0.5, md: 0 },
  },

  tabStyles: (tabValue, index, itemId) => ({
    textTransform: "capitalize",
    fontSize: { xs: "11px", md: "13px" },
    fontWeight: tabValue == index ? 600 : 500,
    p: { xs: "10px 35px 10px 10px", md: 0 },
    minHeight: "30px !important",
    mr: { xs: "0px", md: "55px" },
    color: {
      md: itemId === tabValue ? blueColor04 : greyColor9,
      xs: itemId === tabValue ? whiteColor : bgColor15,
    },
    backgroundColor: {
      xs: itemId === tabValue ? bgColor15 : whiteColor,
      md: "transparent",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      display: "inline-block",
      width: "35px",
      height: "35px",
      top: "0px",
      right: "-20px",
      background: {
        xs: itemId === tabValue ? bgColor15 : whiteColor,
        md: "transparent",
      },
      borderRight: {
        xs: `2px solid ${itemId === tabValue ? whiteColor : bgColor15}`,
        md: "none",
      },
      borderBottom: {
        xs: `2px solid ${itemId === tabValue ? whiteColor : bgColor15}`,
        md: "none",
      },
      borderTopRightRadius: "5px",
      transform: "scale(0.707) rotate(-45deg)",
      // boxShadow: "2px -2px rgb(192 196 204)",
      zIndex: 16,
    },
  }),

  mobileTabs: {
    width: { xs: "90vw", md: "calc(100% - 100px)" },
    display: "flex",
    alignItems: "center",
    mb: 1,
    borderBottom: logoImgborder,
  },

  headerContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: { xs: "flex-start", md: "center" },
    justifyContent: { xs: "flex-start", md: "space-between" },
    m: { xs: "20px 0 10px", md: "20px 0 0px" },
    pb:1
  },

  headerTitleBox: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  headerTitle: {
    fontSize: { xs: 13, md: 15 },
    color: blackShade05,
    fontWeight: 600,
    lineHeight: "20px",
    pb: { md: 0, xs: 0 },
  },

  orderCountText: {
    fontSize: 13,
    color: greyColor6,
    fontWeight: 400,
    lineHeight: "20px",
  },
};
