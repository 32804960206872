import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import FormInputBox from "../../../components/Input/FormInputBox";
import {
  blackColor,
  blackShade05,
  blueColor02,
  errorTextColor,
  greyColor5,
  greyColor6,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ActionButtons from "../../../components/Buttons/ActionButtons";
import DashboardStepper from "../../Dashboard/DashboardStepper";
import {
  CATALOG_ADD_PRODUCT_STEP_LIST,
  CATALOG_STEP_LIST,
} from "../../../utils/appData";
import SelectWithLabel from "../../../components/Input/SelectWithLabel";
import CatalogActionBtns from "../../../components/Buttons/CatalogActionBtns";
import { PRODUCT_CODE_UNIT_LIST } from "../../../utils/data";
import { getProductCodeMaxLength } from "../../../utils/helper";

function PriceTaxInfoForm({
  data,
  ondcData,
  setOndcData,
  onValueChange,
  gstRates,
  currentPage,
  gotoNext,
  goBack,
  productName,
  isAddProduct,
  isCopyProduct,
  onCancelClick,
  handleTabChange,
  tabTitle,
  showPreviewBtn,
  previewErrorMsg,
  handlePreviewSubmit,
}) {
  const handleOndcInfoChange = (property, value, rootProperty) => {
    setOndcData((prevState) => {
      const ondcUpdate = rootProperty
        ? {
            ...prevState.ondc,
            [rootProperty]: {
              ...prevState.ondc?.[rootProperty],
              [property]: value,
            },
          }
        : {
            ...prevState.ondc,
            [property]: value,
          };

      return {
        ...prevState,
        ondc: ondcUpdate,
        errors: null,
      };
    });
  };
  return (
    <Box sx={styles.card}>
      {/* <DashboardStepper
        stepList={
          isCopyProduct ? CATALOG_ADD_PRODUCT_STEP_LIST : CATALOG_STEP_LIST
        }
        currentPage={currentPage}
        isAddProduct
        hideAvailability={!isCopyProduct}
        handleTabChange={(destinationTab) =>
          handleTabChange(
            currentPage,
            isCopyProduct ? CATALOG_ADD_PRODUCT_STEP_LIST : CATALOG_STEP_LIST,
            destinationTab
          )
        }
      /> */}

      {isAddProduct && (
        <>
          <Typography
            component="h6"
            fontSize={{ xs: 15, md: 15 }}
            color={blackColor}
            fontWeight={600}
            lineHeight="20px"
            mb={"20px !important"}
            mt={{ xs: "0px !important", md: "15px !important" }}
          >
            Price Details
          </Typography>

          <Grid container rowSpacing={2} columnSpacing={3}>
            <Grid item xs={12} md={2.5}>
              <FormInputBox
                label="Currency"
                placeholder=""
                value={data?.currency + " INR"}
                onChange={({ target }) => {
                  onValueChange("currency", target.value);
                }}
                error={data?.errors?.currency && "Enter currency"}
                disabled
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <FormInputBox
                label="Original Price"
                placeholder=""
                required
                value={data?.originalPrice}
                onChange={({ target }) => {
                  const regex = /^\d{0,7}(\.\d{0,2})?$/;

                  if (regex.test(target.value)) {
                    onValueChange("originalPrice", target.value);
                  }
                }}
                error={data?.errors?.originalPrice}
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <FormInputBox
                label="Offer Price"
                placeholder=""
                required
                value={data?.OfferPrice}
                onChange={({ target }) => {
                  const regex = /^\d{0,7}(\.\d{0,2})?$/;

                  if (regex.test(target.value)) {
                    onValueChange("OfferPrice", target.value);
                  }
                }}
                error={
                  data?.errors?.OfferPrice
                    ? "Please fill out this field"
                    : data?.errors?.priceError
                    ? "Offer Price should not be greater than original price"
                    : ""
                }
              />
            </Grid>
          </Grid>
        </>
      )}

      {/* <Divider sx={{ m: "20px 0 15px" }} /> */}

      <Typography
        component="h6"
        fontSize={{ xs: 13.5, md: 14.5 }}
        color={blackColor}
        fontWeight={600}
        lineHeight="20px"
        mb={"15px !important"}
        mt={{
          xs: "15px !important",
          md: "20px !important",
        }}
      >
        Tax and GST Information
      </Typography>

      <Grid container rowSpacing={2} columnSpacing={3}>
        <Grid item xs={5} md={2.5}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 13, md: 14 },
              fontWeight: 400,
              color: greyColor6,
            }}
          >
            Taxes Included
          </Typography>

          <Box>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: 13, md: 14.5 },
                fontWeight: { xs: 500, md: 400 },
                color: blackShade05,
              }}
            >
              Yes
            </Typography>

            {/* <RadiosBtnWithLabel
              label="Yes"
              checked={data?.taxDetails}
              onValuesChanged={() => {
                onValueChange("taxDetails", true);
              }}
            /> */}

            {/* <RadiosBtnWithLabel
              label="No"
              checked={!data?.taxDetails}
              onValuesChanged={() => {
                onValueChange("taxDetails", false);
              }}
            /> */}
          </Box>
        </Grid>

        <Grid item xs={7} md={2}>
          <Box mt={{ xs: "0px", md: "-10px" }}>
            <SelectWithLabel
              label="GST Percentage (in %)"
              required
              value={data?.gstPercentage || ""}
              onValueChange={(value) => {
                onValueChange("gstPercentage", value);
              }}
              menuList={gstRates}
              valueSelector="value"
              errorMsg={data?.errors?.gstPercentage && "Select GST percentage"}
            />
          </Box>
        </Grid>

        <Grid item xs={5} md={1.5}>
          <Box mt={{ xs: "0px", md: "-10px" }}>
            <SelectWithLabel
              label="Product Code"
              value={ondcData?.ondc?.productCode?.key}
              onValueChange={(value) => {
                handleOndcInfoChange("key", value?.label, "productCode");
              }}
              returnFullItem
              menuList={PRODUCT_CODE_UNIT_LIST}
              required
              errorMsg={data?.errors?.productCode1}
            />
          </Box>
        </Grid>

        <Grid item xs={7} md={2.5}>
          <Box mt={{xs:3.4, md:2}}>
            <FormInputBox
              label=" "
              placeholder=""
              value={ondcData?.ondc?.productCode?.value}
              onChange={({ target }) => {
                const value = target?.value?.replace(/[^0-9]/g, "");
                handleOndcInfoChange("value", value, "productCode");
              }}
              maxLength={getProductCodeMaxLength(
                ondcData?.ondc?.productCode?.key
              )}
              error={data?.errors?.productCode}
            />
          </Box>
        </Grid>
      </Grid>

      <Box mt="20px">
        <CatalogActionBtns
          onBackClick={goBack}
          onSaveClick={() => {}}
          onNextClick={gotoNext}
          showBackBtn
          hideSaveBtn
          backBtnText="Previous"
          onCancelClick={onCancelClick}
          // Preview Btn
          showPreviewBtn={showPreviewBtn}
          onPreviewSubmitClick={handlePreviewSubmit}
        />
      </Box>

      <Typography
        fontSize={12}
        fontWeight={500}
        mt={1}
        textAlign="right"
        color={errorTextColor}
      >
        {previewErrorMsg}
      </Typography>
    </Box>
  );
}

export default PriceTaxInfoForm;

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: { xs: "8px", md: "13px" },
    p: { xs: "12px", md: "5px 20px 20px" },
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    m: { xs: "15px 0 25px", md: "0px 0 25px" },
  },
};
