import React, { useState } from 'react'
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import {
  BGColor02,
  blackColor,
  blackShade19,
  blackShade43,
  greyColor40,
  logoImgborder1,
  orangeColor02,
  orangeShade12,
  orangeShade13,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ModalCard from '../../../components/ModalCard';
import BusinessContactDetailsPop from '../Popup/BusinessContactDetailsPop';


function MobileContactDetailsCard({
  activeBusinessInfo,
  commerceInfo,onManageClick,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [openModal, setOpenModal] = useState(false);
  const handleOpenContact = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <Box sx={styles.card}>
      <Box display="flex" alignItems="center" gap={{ xs: 1.2, md: 1.5 }}>
        <Box sx={styles.iconCircle}>
          <Box
            component="img"
            alt="Product Image"
            src={
              " https://storage.googleapis.com/bodefaults/businessdashboardweb/dash_contact.svg"
            }
            width="auto"
            height="128px"
          />
        </Box>
        <Stack>
          <Typography
            component="body2"
            fontSize={{ xs: 14.5, md: 14 }}
            color={blackColor}
            fontWeight={600}
          >
            Contact Details
          </Typography>
          <Typography
            component="body2"
            fontSize={{ xs: 11, md: 14 }}
            color={blackColor}
            fontWeight={500}
            mt={-0.5}
          >
            Owner, Warehouse Contact, Deligates
          </Typography>
        </Stack>
        <Box sx={styles.iconContainer()} onClick={handleOpenContact} ml={3}>
          <ArrowForwardIcon
            sx={styles.iconMobile(matches ? whiteColor : whiteColor)}
          />
        </Box>
      </Box>
      <ModalCard open={openModal} handleClose={handleCloseModal} width="28%">
        <BusinessContactDetailsPop
          activeBusinessInfo={activeBusinessInfo}
          commerceInfo={commerceInfo}
          onManageClick={onManageClick}
        />
      </ModalCard>
    </Box>
  );
}

export default MobileContactDetailsCard

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: orangeShade12,
    border: `1px solid ${orangeShade13}`,
    borderRadius: { xs: "10px", md: "13px" },
    boxShadow: "0 3px 4px rgb(229 233 240 / 22%)",
    p: { xs: "5px 10px", md: "10px 15px" },
    width: "100%",
  },

  iconCircle: {
    width: { md: "40px", xs: "40px" },
    height: { md: "40px", xs: "40px" },
    borderRadius: "50%",
    border: `1px solid ${orangeShade13}`,
    backgroundColor: whiteColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  icon: {
    fontSize: { md: "20px", xs: "17px" },
    color: { xs: blackColor, md: blackColor },
  },

  container: {
    backgroundColor: whiteColor,
    borderRadius: 1.8,
  },
  iconContainer: (bgColor, hideMobile) => ({
    width: { md: "40px", xs: "25px" },
    height: { md: "40px", xs: "25px" },
    borderRadius: "50%",
    backgroundColor: blackColor,
    display: { md: "flex", xs: hideMobile ? "none" : "flex" },
    alignItems: "center",
    justifyContent: "center",
  }),
  iconMobile: (color) => ({ fontSize: { md: 20, xs: 15 }, color: color }),
};
