import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { viewAllOrderByBusinessApi } from "../../service/api_calls/BusinessDashboard/BusinessDashboardApi";
import {
  getBusinessOrdersByStatusApi,
  getDelhiveryB2BStatusDetails,
  getDelhiveryStatusDetails,
  getTelyportStatusDetails,
  getTelyportStatusInfo,
} from "../../service/api_calls/orders/OrdersApi";

const initialState = {
  businessOrders: null,
  businessOrdersLoader: false,

  pendingOrders: [],
  pendingPageNo: 1,
  pendingOrdersLoader: false,

  readyToShipOrders: [],
  readyToShipPageNo: 1,
  readyToShipOrdersLoader: false,

  intransitOrders: [],
  intransitPageNo: 1,
  intransitOrdersLoader: false,

  deliveredOrders: [],
  deliveredPageNo: 1,
  deliveredOrdersLoader: false,

  cancelledOrders: [],
  cancelledPageNo: 1,
  cancelledOrdersLoader: false,

  allOrders: [],
  allPageNo: 1,
  allOrdersLoader: false,

  dehiveryStatusData: null,
  dehiveryB2BStatusData: null,
  telyportStatusData: null,
  statusLoader: false,

  refreshTabData: 1,
};

// Get All Business Orders
export const getAllBusinessOrderData = createAsyncThunk(
  "getAllBusinessOrderData",
  async (param, thunkAPI) => {
    const res = await viewAllOrderByBusinessApi(param);
    return res;
  }
);

// Get Pending Orders
export const getPendingOrderData = createAsyncThunk(
  "getPendingOrderData",
  async (param, thunkAPI) => {
    const res = await getBusinessOrdersByStatusApi({
      ...param,
      status: "ordercreated",
    });
    return res;
  }
);

// Refresh Pending Orders
export const refreshPendingOrderData = createAsyncThunk(
  "refreshPendingOrderData",
  async (param, thunkAPI) => {
    const res = await getBusinessOrdersByStatusApi({
      ...param,
      status: "ordercreated",
    });
    return res;
  }
);

// Get Ready To Ship Orders
export const getReadyToShipOrderData = createAsyncThunk(
  "getReadyToShipOrderData",
  async (param, thunkAPI) => {
    const res = await getBusinessOrdersByStatusApi({
      ...param,
      status: "orderapproved",
    });
    return res;
  }
);

// Get Ready To Ship Orders
export const refreshReadyToShipOrderData = createAsyncThunk(
  "refreshReadyToShipOrderData",
  async (param, thunkAPI) => {
    const res = await getBusinessOrdersByStatusApi({
      ...param,
      status: "orderapproved",
    });
    return res;
  }
);

// Get Intransit Orders
export const getIntransitOrderData = createAsyncThunk(
  "getIntransitOrderData",
  async (param, thunkAPI) => {
    const res = await getBusinessOrdersByStatusApi({
      ...param,
      status: "ordershipped",
    });
    return res;
  }
);

export const refreshIntransitOrderData = createAsyncThunk(
  "refreshIntransitOrderData",
  async (param, thunkAPI) => {
    const res = await getBusinessOrdersByStatusApi({
      ...param,
      status: "ordershipped",
    });
    return res;
  }
);

// Get Delivered Orders
export const getDeliveredOrderData = createAsyncThunk(
  "getDeliveredOrderData",
  async (param, thunkAPI) => {
    const res = await getBusinessOrdersByStatusApi({
      ...param,
      status: "orderdelivered",
    });
    return res;
  }
);

// Get Canceled Orders
export const getCancelledOrderData = createAsyncThunk(
  "getCancelledOrderData",
  async (param, thunkAPI) => {
    const res = await getBusinessOrdersByStatusApi({
      ...param,
      status: "ordercanceled",
    });
    return res;
  }
);

// Get All Orders
export const getAllOrdersData = createAsyncThunk(
  "getAllOrdersData",
  async (param, thunkAPI) => {
    const res = await getBusinessOrdersByStatusApi({
      ...param,
      status: "all",
    });
    return res;
  }
);

//Fetching Delhivery Order Status
export const getDelhiveryStatus = createAsyncThunk(
  "getDelhiveryStatus",
  async (carrierTrackId) => {
    const res = await getDelhiveryStatusDetails(carrierTrackId);
    return res;
  }
);

//Fetching Delhivery Order Status
export const getDelhiveryB2BStatus = createAsyncThunk(
  "getDelhiveryB2BStatus",
  async (carrierTrackId) => {
    const res = await getDelhiveryB2BStatusDetails(carrierTrackId);
    return res;
  }
);

//Fetching Telyport Order Status
export const getTelyportStatus = createAsyncThunk(
  "getTelyportStatus",
  async (carrierTrackId) => {
    const res = await getTelyportStatusInfo(carrierTrackId);
    // const res = await getTelyportStatusDetails(carrierTrackId);
    return res;
  }
);

export const businessOrders = createSlice({
  name: "businessOrders",
  initialState,
  reducers: {
    clearOrderInfo: (state, action) => {
      state.businessOrders = null;

      state.pendingOrders = [];
      state.pendingPageNo = 1;

      state.readyToShipOrders = [];
      state.readyToShipPageNo = 1;

      state.intransitOrders = [];
      state.intransitPageNo = 1;

      state.deliveredOrders = [];
      state.deliveredPageNo = 1;

      state.cancelledOrders = [];
      state.cancelledPageNo = 1;

      state.allOrders = [];
      state.allPageNo = 1;

      state.dehiveryStatusData = null;
      state.dehiveryB2BStatusData = null;
      state.telyportStatusData = null;
    },

    clearPendingOrderInfo: (state) => {
      state.pendingOrders = [];
      state.pendingPageNo = 1;
    },

    clearReadyToShipOrderInfo: (state) => {
      state.readyToShipOrders = [];
      state.readyToShipPageNo = 1;
    },

    clearIntransitOrderInfo: (state) => {
      state.intransitOrders = [];
      state.intransitPageNo = 1;
    },

    clearDeliveredOrderInfo: (state) => {
      state.deliveredOrders = [];
      state.deliveredPageNo = 1;
    },

    clearCancelledOrderInfo: (state) => {
      state.cancelledOrders = [];
      state.cancelledPageNo = 1;
    },

    clearAllOrderInfo: (state) => {
      state.allOrders = [];
      state.allPageNo = 1;
    },

    clearDeliveryStatusInfo: (state) => {
      state.dehiveryStatusData = null;
      state.dehiveryB2BStatusData = null;
      state.telyportStatusData = null;
    },

    handleRefreshTabData: (state) => {
      state.refreshTabData = state.refreshTabData + 1;
    },
  },
  extraReducers: {
    //fetch All Business Orders
    [getAllBusinessOrderData.pending]: (state) => {
      state.businessOrdersLoader = true;
      state.businessOrders = null;
    },
    [getAllBusinessOrderData.fulfilled]: (state, { payload }) => {
      state.businessOrders = payload;
      state.businessOrdersLoader = false;
    },
    [getAllBusinessOrderData.rejected]: (state, { payload }) => {
      state.businessOrdersLoader = false;
      state.businessOrders = null;
    },

    //fetch Pending Business Orders
    [getPendingOrderData.pending]: (state) => {
      state.pendingOrdersLoader = true;
    },
    [getPendingOrderData.fulfilled]: (state, { payload }) => {
      const orderData = payload?.details || [];
      if (orderData.length > 0) {
        state.pendingOrders = [...state.pendingOrders, ...orderData];
        state.pendingPageNo = state.pendingPageNo + 1;
      }
      state.pendingOrdersLoader = false;
    },
    [getPendingOrderData.rejected]: (state) => {
      state.pendingOrdersLoader = false;
    },

    //fetch Pending Business Orders
    [refreshPendingOrderData.pending]: (state) => {
      state.pendingOrdersLoader = true;
    },
    [refreshPendingOrderData.fulfilled]: (state, { payload }) => {
      const orderData = payload?.details || [];
      state.pendingOrders = orderData;
      state.pendingPageNo = 2;
      state.pendingOrdersLoader = false;
    },
    [refreshPendingOrderData.rejected]: (state) => {
      state.pendingOrdersLoader = false;
    },

    //fetch Ready To Ship Business Orders
    [getReadyToShipOrderData.pending]: (state) => {
      state.readyToShipOrdersLoader = true;
    },
    [getReadyToShipOrderData.fulfilled]: (state, { payload }) => {
      const orderData = payload?.details || [];
      if (orderData.length > 0) {
        state.readyToShipOrders = [...state.readyToShipOrders, ...orderData];
        state.readyToShipPageNo = state.readyToShipPageNo + 1;
      }
      state.readyToShipOrdersLoader = false;
    },
    [getReadyToShipOrderData.rejected]: (state) => {
      state.readyToShipOrdersLoader = false;
    },
    //refresh Ready To Ship Business Orders
    [refreshReadyToShipOrderData.pending]: (state) => {
      state.readyToShipOrdersLoader = true;
    },
    [refreshReadyToShipOrderData.fulfilled]: (state, { payload }) => {
      const orderData = payload?.details || [];
      state.readyToShipOrders = orderData;
      state.readyToShipPageNo = 2;
      state.readyToShipOrdersLoader = false;
    },
    [refreshReadyToShipOrderData.rejected]: (state) => {
      state.readyToShipOrdersLoader = false;
    },

    //fetch Intransit Business Orders
    [getIntransitOrderData.pending]: (state) => {
      state.intransitOrdersLoader = true;
    },
    [getIntransitOrderData.fulfilled]: (state, { payload }) => {
      const orderData = payload?.details || [];
      if (orderData.length > 0) {
        state.intransitOrders = [...state.intransitOrders, ...orderData];
        state.intransitPageNo = state.intransitPageNo + 1;
      }
      state.intransitOrdersLoader = false;
    },
    [getIntransitOrderData.rejected]: (state) => {
      state.intransitOrdersLoader = false;
    },

    //refresh Ready To Ship Business Orders
    [refreshIntransitOrderData.pending]: (state) => {
      state.intransitOrdersLoader = true;
    },
    [refreshIntransitOrderData.fulfilled]: (state, { payload }) => {
      const orderData = payload?.details || [];
      state.intransitOrders = orderData;
      state.intransitPageNo = 2;
      state.intransitOrdersLoader = false;
    },
    [refreshIntransitOrderData.rejected]: (state) => {
      state.intransitOrdersLoader = false;
    },

    //fetch Delivered Business Orders
    [getDeliveredOrderData.pending]: (state) => {
      state.deliveredOrdersLoader = true;
    },
    [getDeliveredOrderData.fulfilled]: (state, { payload }) => {
      const orderData = payload?.details || [];
      if (orderData.length > 0) {
        state.deliveredOrders = [...state.deliveredOrders, ...orderData];
        state.deliveredPageNo = state.deliveredPageNo + 1;
      }
      state.deliveredOrdersLoader = false;
    },
    [getDeliveredOrderData.rejected]: (state) => {
      state.deliveredOrdersLoader = false;
    },

    //fetch Cancelled Business Orders
    [getCancelledOrderData.pending]: (state) => {
      state.cancelledOrdersLoader = true;
    },
    [getCancelledOrderData.fulfilled]: (state, { payload }) => {
      const orderData = payload?.details || [];
      if (orderData.length > 0) {
        state.cancelledOrders = [...state.cancelledOrders, ...orderData];
        state.cancelledPageNo = state.cancelledPageNo + 1;
      }
      state.cancelledOrdersLoader = false;
    },
    [getCancelledOrderData.rejected]: (state) => {
      state.cancelledOrdersLoader = false;
    },

    //fetch all Orders
    [getAllOrdersData.pending]: (state) => {
      state.allOrdersLoader = true;
    },
    [getAllOrdersData.fulfilled]: (state, { payload }) => {
      const orderData = payload?.details || [];
      if (orderData.length > 0) {
        state.allOrders = [...state.allOrders, ...orderData];
        state.allPageNo = state.allPageNo + 1;
      }
      state.allOrdersLoader = false;
    },
    [getAllOrdersData.rejected]: (state) => {
      state.allOrdersLoader = false;
    },

    //Fetch Delhivery Status
    [getDelhiveryStatus.pending]: (state) => {
      state.statusLoader = true;
    },
    [getDelhiveryStatus.fulfilled]: (state, { payload }) => {
      state.statusLoader = false;
      state.dehiveryStatusData = payload;
    },
    [getDelhiveryStatus.rejected]: (state) => {
      state.statusLoader = false;
    },

    //Fetch Delhivery B2B Status
    [getDelhiveryB2BStatus.pending]: (state) => {
      state.statusLoader = true;
    },
    [getDelhiveryB2BStatus.fulfilled]: (state, { payload }) => {
      state.statusLoader = false;
      state.dehiveryB2BStatusData = payload;
    },
    [getDelhiveryB2BStatus.rejected]: (state) => {
      state.statusLoader = false;
    },

    //Fetch Telyport Status Details
    [getTelyportStatus.pending]: (state) => {
      state.statusLoader = true;
    },
    [getTelyportStatus.fulfilled]: (state, { payload }) => {
      state.statusLoader = false;
      state.telyportStatusData = payload;
    },
    [getTelyportStatus.rejected]: (state) => {
      state.statusLoader = false;
    },
  },
});

export const {
  clearOrderInfo,
  clearPendingOrderInfo,
  clearReadyToShipOrderInfo,
  clearIntransitOrderInfo,
  clearDeliveredOrderInfo,
  clearCancelledOrderInfo,
  clearAllOrderInfo,
  clearDeliveryStatusInfo,
  handleRefreshTabData,
} = businessOrders.actions;

export const businessOrdersReducer = businessOrders.reducer;
