import React from "react";
import ModalCard from "../../../components/ModalCard";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import {
  blackShade19,
  blueColor04,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import ImageMagnifier from "../../../components/ImageMagnifier/ImageMagnifier";
import ImageMagnifierMobile from "../../../components/ImageMagnifier/ImageMagnifierMobile";

function ImageGalleryPopup({
  openModal,
  setOpenModal,
  title,
  productImages,
  imageSelector,
  activeImg,
  onActiveClick,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <ModalCard
      open={openModal}
      handleClose={() => {
        setOpenModal(false);
      }}
      width="70%"
    >
      <Box
        p={{ md: 3, xs: 2 }}
        sx={{
          background: whiteColor,
          minHeight: { xs: "60vh", md: "75vh" },
          maxHeight: { xs: "75vh", md: "75vh" },
          borderRadius: "10px",
        }}
      >
        <Grid
          container
          direction={{ md: "row", xs: "column-reverse" }}
          spacing={2}
        >
          <Grid item xs={12} md={5}>
            <Box>
              <Typography
                color={blackShade19}
                mt={1.5}
                mb="20px !important"
                fontSize={15}
                fontWeight={600}
                lineHeight={1.6}
                display={{ md: "block", xs: "none" }}
              >
                {title}
              </Typography>
              <Grid
                container
                spacing={{ md: 4, xs: 2 }}
                justifyContent="center"
              >
                {productImages?.map((item, i) => {
                  if (item?.status === "inactive") return null;

                  const imageUrl = imageSelector
                    ? item[imageSelector]
                    : item?.dataURL;
                  if (!imageUrl) return;
                  return (
                    <Grid item xs={6} key={i}>
                      <Box
                        component="img"
                        src={imageUrl}
                        width="100%"
                        height="200px"
                        sx={{
                          width: { xs: "120px", md: "150px" },
                          height: { xs: "80px", md: "120px" },
                          border:
                            activeImg === imageUrl
                              ? "1px solid" + blueColor04
                              : "",
                          boxShadow: "0px 1px 3px rgb(0 0 0 / 6%)",
                          borderRadius: "10px",
                          p: 0.5,
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={() => onActiveClick(imageUrl)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {matches ? (
              <ImageMagnifier
                imgUrl={activeImg}
                width="100%"
                // height={{ xs: "250px", md: "70vh" }}
              />
            ) : (
              <ImageMagnifierMobile
                imgUrl={activeImg}
                width="100%"
                // height={{ xs: "250px", md: "70vh" }}
              />

              // <Box component="img" src={activeImg} width="100%" height="100%" />
            )}
          </Grid>
        </Grid>
      </Box>
    </ModalCard>
  );
}

export default ImageGalleryPopup;
