import { InputAdornment, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import React from "react";
import {
  blackColor,
  blackShade02,
  blackShade05,
  borderTextbox,
  descriptionColor,
  errorborderTextbox,
  greyColor6,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { allowOnlyEnglish } from "../../utils/helper";

function FormInputBox({
  label,
  mobilelabel,
  mobilelabel1,
  placeholder,
  name,
  value,
  onChange,
  startIcon,
  icon,
  maxLength,
  inputType,
  // isError,
  error,
  helperText,
  autoFocus,
  inputRef,
  onKeyUp,
  onBlur,
  disabled,
  isDashboardInput,
  hideLabel,
  required,
  inputOpaticy,
  onKeyDown,
  id,
  onFocus,
  autoComplete,
  helperColor,
  dateformat,
}) {
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = allowOnlyEnglish(inputValue);

    if (onChange) {
      onChange({ target: { value: sanitizedValue } });
    }
  };

  return (
    <Box
      mb={{ xs: "0px", md: "12px !important" }}
      display={"flex"}
      flexDirection={"column"}
    >
      {!hideLabel ? (
        <>
          <Box>
            <Typography
              variant="h3"
              fontSize={{ xs: 12, md: 13 }}
              color={isDashboardInput ? blackColor : greyColor6}
              fontWeight={isDashboardInput ? 500 : 400}
              mb={isDashboardInput ? "8px !important" : "0px"}
              sx={{
                display: "inline",
                opacity: inputOpaticy ? inputOpaticy : disabled ? 0.7 : 1,
              }}
            >
              {mobilelabel ? mobilelabel : label}{" "}
            </Typography>
            {required ? (
              <Typography
                variant="body1"
                sx={{ display: "inline", color: "#f00" }}
              >
                *
              </Typography>
            ) : null}
          </Box>
          {mobilelabel1 && <Typography
            variant="h3"
            fontSize={{ xs: 12, md: 13 }}
            color={isDashboardInput ? blackColor : greyColor6}
            fontWeight={isDashboardInput ? 500 : 400}
            mb={isDashboardInput ? "8px !important" : "0px"}
            sx={{
              display: "inline",
              opacity: inputOpaticy ? inputOpaticy : disabled ? 0.7 : 1,
            }}
          >
            {mobilelabel ? mobilelabel1 : label}{" "}
          </Typography>}
        </>
      ) : null}

      <TextField
        id={id}
        variant="standard"
        required={true}
        fullWidth
        name={name}
        disabled={disabled}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        autoFocus={autoFocus}
        onChange={handleInputChange}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        inputRef={inputRef}
        autoComplete={autoComplete}
        sx={{
          fontSize: 13,
          fontWeight: 500,
          color: blackShade05,
          opacity: disabled ? 0.5 : 1,
          borderTop: "none",
          borderBottom: borderTextbox,
          borderLeft: "none",
          borderRight: "none",
          borderRadius: "0px",
          mb: 1,
          backgroundColor: whiteColor,
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
        }}
        InputProps={{
          startAdornment: startIcon ? (
            <InputAdornment
              position="start"
              sx={{
                color: `${blackShade05} !important`,
                fontSize: 13.5,
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: 13, fontWeight: 500, color: blackShade05 }}
              >
                {startIcon}
              </Typography>
            </InputAdornment>
          ) : (
            <></>
          ),
          endAdornment: (
            <InputAdornment position="end" sx={{ cursor: "pointer" }}>
              {icon}
            </InputAdornment>
          ),
          style: {
            padding: "0px 8px 4px 0px",
            fontSize: 13,
            fontWeight: 500,
          },
          disableUnderline: true,
        }}
        inputProps={{ maxLength: maxLength }}
        type={inputType}
        format={dateformat}
      />

      {error ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={12}
          sx={{ mt: "4px !important", textAlign: "start !important" }}
          style={{ color: "red" }}
        >
          {error}
        </Typography>
      ) : null}

      {helperText ? (
        <Typography
          variant="body1"
          component="p"
          fontSize={12}
          color={helperColor || greyColor6}
          lineHeight="20px"
        >
          {helperText}
        </Typography>
      ) : null}
    </Box>
  );
}

export default FormInputBox;
