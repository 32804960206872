import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BusinessInfoCard from "../components/Cards/BusinessInfoCard";
import {
  BGColor02,
  blackColor,
  blackShade21,
  blueColor04,
  mobilePrimary,
  primaryColor,
  whiteColor,
} from "../configs/styles/muiThemes";
import {
  getActiveBusinessInfo,
  getCommerceInfo,
} from "../reducers/businessPreview/businessPreviewSlice";
import DashboardHeader from "./Dashboard/DashboardHeader";
import DashboardMenu from "./Dashboard/DashboardMenu";
import ModalCard from "../components/ModalCard";
import moment from "moment";
import AddBusinessTimings from "./AddCommerceInfo/Forms/AddBusinessTimings";
import AddDeliveryMethods from "./AddCommerceInfo/Forms/AddDeliveryMethods";
import AddDiscountDetails from "./AddCommerceInfo/Forms/AddDiscountDetails";
import AddBankDetails from "./AddCommerceInfo/Forms/AddBankDetails";
import AddOtherDetails from "./AddCommerceInfo/Forms/AddOtherDetails";
import { DISABLE_ALL_MENU } from "../utils/data";
import {
  prepareEditCommerceData,
  prepareUpdateCommerceData,
} from "../service/api_calls/BusinessDashboard/BusinessDashboardHelper";
import {
  UpdateInactiveDateApi,
  getBankDetailsApi,
  postBankDetailsApi,
  prepareBankDetailsPostData,
  sendWhatsAppNotification,
  updateBusinessTimingAndDeliveryMethod,
  updateCommerceSubmitAPI,
} from "../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";
import CommerceInfoPreview from "./AddCommerceInfo/Preview/CommerceInfoPreview";
import { prepareUpdateBusinessTimingData } from "../utils/businessDataUtils";
import { getCommerceDashboardInfoAPI } from "../service/api_calls/BusinessPreview/BusinessPreviewAPI";
import {
  prepareBusinessHoursData,
  prepareDeliveryMethodData,
  prepareDiscountData,
} from "./AddONDCControlInfo/ONDCControlHelper";
import ThreeDotLoader from "../components/ThreeDotLoader";
import AlertPopup from "../components/Alerts/AlertPopup";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import { stickyHeader } from "../utils/styles";
import {
  ECOMINPUTSUPPORTLABEL,
  emailAddress,
  whatsAppNumber,
} from "../configs/Constants";
import DashboardStepper from "./Dashboard/DashboardStepper";
import { ECOMMERCE_STEP_LIST } from "../utils/appData";
import AddManagerDelegates from "./AddCommerceInfo/AddManagerDelegates";
import {
  BankDetailsData,
  getManagerDetails,
  verifyBusinessRole,
  verifyManagerRole,
} from "../reducers/ecommerceControl/EcommerceControlSlice";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";

function EditCommerceInfo({type}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const location = useLocation();
  const activeTab = location.state?.status || "business-timings";

  const [currentPage, setCurrentPage] = useState(activeTab);

  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const contentRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeBusinessInfo, activeBusinessInfoLoader } = useSelector(
    (state) => state.businessPreview
  );

  const {
    managersInfo,
    managersInfoLoader,
    businessRole,
    businessRoleLoader,
    managerRoleLoader,
    bankInfo,
    bankInfoLoader,
  } = useSelector((state) => state.eCommerceControl);

  const [commerceInfo, setCommerceInfo] = useState(null);

  const [businessTiming, setBusinessTiming] = useState({
    businessTime: "open-24-Hrs",
    open24Hours: { from: "12:00 AM", to: "11:59 PM" },
    allDays: { from: "", to: "" },
    businessHours: [
      { dayOfWeek: 1, day: "Monday", isClosed: false, from: "", to: "" },
      { dayOfWeek: 2, day: "Tuesday", isClosed: false, from: "", to: "" },
      { dayOfWeek: 3, day: "Wednesday", isClosed: false, from: "", to: "" },
      { dayOfWeek: 4, day: "Thursday", isClosed: false, from: "", to: "" },
      { dayOfWeek: 5, day: "Friday", isClosed: false, from: "", to: "" },
      { dayOfWeek: 6, day: "Saturday", isClosed: false, from: "", to: "" },
      { dayOfWeek: 7, day: "Sunday", isClosed: false, from: "", to: "" },
    ],
  });

  const [addressInfo, setAddressInfo] = useState({
    isAddressSame: true,
    pincode: "",
    city: "",
    state: "",
    address: "",
    landmark: "",
    contanctNumber: "",
    errors: {},
  });

  const [deliveryMethods, setDeliveryMethods] = useState({
    deliveryMethods: [],

    costBoDelivery: {
      from: "",
      to: "",
      type: "delhivery",
      unit: "km",
      deliveryTime: ["COSTBO SURFACE"],
    },

    costBoHyperlocal: {
      from: "",
      to: "",
      type: "telyport",
      unit: "km",
      deliveryTime: ["rush", "express", "standard"],
    },

    fullfillment: {
      from: "",
      to: "",
      type: "business",
      unit: "km",
      deliveryTime: [],
    },

    fullfillmentPricing: [],

    b2bDelivery: {
      from: "",
      to: "",
      type: "delhiveryb2b",
      unit: "km",
      deliveryTime: ["COSTBO B2B"],
    },

    storePickup: {
      discountBy: "Select Discount Type",
      discountAmount: "",
      discountPercentage: "",
    },

    cod: {
      type: "delhivery",
      limit: "",
      notes: `Maximum allowed Rs  otherwise pay by card/wallet`,
      codFlatCharge: 45.0,
      codPercent: 3.0,
      settlementDays: 7,
      chargeTo: "Select Cash on delivery fee charged to",
    },

    isCOD: false,
    errors: {},
  });

  const [discountInfo, setDiscountInfo] = useState({
    immediateDiscount: "",
    rewardPointPercent: { label: "Select Cashback Percentage" },
    referralPointsMaxPerTxn: "",
    rewardExpirationDays: { label: "Select Cashback Validity" },
    additionalDiscount: [],
  });

  const [otherDetails, setOtherDetails] = useState({
    minOrderAmount: "",
    returnPolicy: "",
    notes: "",
  });
  const [bankDetails, setBankDetails] = useState({
    accountName: "",
    accountNo: "",
    accountType: "",
    bankName: "",
    bankAddress: "",
    ifscCode: "",
    addedDate: "",
  });
  const [inactiveDate, setInactiveDate] = useState({
    expiredDate: "",
  });
  const [bankInfoError, setBankInfoError] = useState("");

  const [bankLoader, setBankLoader] = useState(false);

  const handleSetValue = (name, value, state, setState) => {
    if (
      name === "rewardPointPercent" &&
      (value?.label === "Select Cashback Percentage" || value?.label === "0%")
    ) {
      setState({
        ...state,
        [name]: value,
        referralPointsMaxPerTxn: "",
        rewardExpirationDays: { label: "Select Cashback Validity" },
      });
      return;
    }

    setState({
      ...state,
      [name]: value,
    });
  };

  const validateBusinessTiming = () => {
    let errors = null;

    if (businessTiming?.businessTime === "all-days") {
      if (!businessTiming?.allDays?.from) {
        errors = {
          ...errors,
          allDays: { ...errors?.allDays, from: "Enter from time" },
        };
      }

      if (!businessTiming?.allDays?.to) {
        errors = {
          ...errors,
          allDays: { ...errors?.allDays, to: "Enter to time" },
        };
      }

      if (!businessTiming?.allDays?.from && !businessTiming?.allDays?.to) {
        errors = {
          ...errors,
          allDays: {
            ...errors?.allDays,
            from: "Enter from and to time",
            to: "",
          },
        };
      }

      if (businessTiming?.allDays?.from && businessTiming?.allDays?.to) {
        const format = "HH:mm";
        const fromMoment = moment(businessTiming?.allDays?.from, format);
        const toMoment = moment(businessTiming?.allDays?.to, format);

        const isAfter = fromMoment.isAfter(toMoment);
        const isSame = fromMoment.isSame(toMoment);

        if (isAfter) {
          errors = {
            ...errors,
            allDays: {
              ...errors?.allDays,
              from: "From time should not be greater To time",
              to: "",
            },
          };
        } else if (isSame) {
          errors = {
            ...errors,
            allDays: {
              ...errors?.allDays,
              from: "From time and To time should not be equal",
              to: "",
            },
          };
        }
      }
    }

    if (businessTiming?.businessTime === "select-specific-days") {
      const newTimeList = [];
      let isError = false;

      businessTiming?.businessHours?.forEach((item, index) => {
        if (item?.isClosed) {
          if (!item?.from || !item?.to) {
            newTimeList.push({
              ...item,
              error: `Please select From and To time`,
            });
            isError = true;
          } else if (item?.from && item?.to) {
            const format = "HH:mm";
            const fromMoment = moment(item?.from, format);
            const toMoment = moment(item?.to, format);

            const isAfter = fromMoment.isAfter(toMoment);
            const isSame = fromMoment.isSame(toMoment);

            if (isAfter) {
              console.log("HITT");
              newTimeList.push({
                ...item,
                error: `From time should not be greater than To time`,
              });
              isError = true;
            } else if (isSame) {
              newTimeList.push({
                ...item,
                error: `From time and To time should not be same`,
              });
              isError = true;
            }
          } else {
            newTimeList.push({
              ...item,
              error: "",
            });
          }
        } else {
          newTimeList.push({
            ...item,
            error: "",
          });
        }

        // if (item?.isClosed) {
        //   if(!item?.from || !item?.to){
        //     errors = {
        //       ...errors,
        //       businessHours: {
        //         error: `Please select From and To time for ${getDayofWeekName(item?.dayOfWeek)}`,
        //         dayOfWeek: item?.dayOfWeek
        //       },
        //     };
        //   }
        // }
      });

      if (isError) {
        errors = {
          ...errors,
          businessHours: {
            error: `Please fill the from and to time for the selected days`,
            errorList: newTimeList,
          },
        };
      }

      const selectedList = businessTiming?.businessHours?.filter(
        (item) => item?.isClosed
      );

      if (selectedList?.length === 0) {
        errors = {
          ...errors,
          businessHours: {
            error: `Select the day and timings`,
          },
        };
      }
    }

    console.log("businessTiming:", businessTiming);

    if (errors) {
      handleSetValue("errors", errors, businessTiming, setBusinessTiming);
    } else {
      handleSetValue("errors", null, businessTiming, setBusinessTiming);
      setCurrentPage("delivery-methods");
      contentRef.current.scrollIntoView();
    }
  };

  const validatePickupAddress = () => {
    let errors = null;

    if (!addressInfo?.pincode) {
      errors = { ...errors, pincode: true };
    }
    if (!addressInfo?.city) {
      errors = { ...errors, city: true };
    }
    if (!addressInfo?.state) {
      errors = { ...errors, state: true };
    }
    if (!addressInfo?.address) {
      errors = { ...errors, address: true };
    }
    // if (!addressInfo?.landmark) {
    //   errors = { ...errors, landmark: true };
    // }
    if (addressInfo?.contanctNumber?.length < 10) {
      errors = { ...errors, contanctNumber: true };
    }

    if (errors) {
      handleSetValue("errors", errors, addressInfo, setAddressInfo);
    } else {
      handleSetValue("errors", null, addressInfo, setAddressInfo);
      setCurrentPage("business-timings");
      contentRef.current.scrollIntoView();
    }
  };

  const validateDeliveryMethods = () => {
    let errors = null;

    const COSTBO_DELIVERY = "CostBo Delivery";
    const FULLFILLMENT_BUSINESS = "Fulfillment by Business";
    const B2B_DELIVERY = "B2B Delivery";
    const STORE_PICKUP = "Store Pickup";
    const COSTBO_HYPERLOCAL = "CostBo Hyperlocal";

    // if (deliveryMethods?.deliveryMethods?.length === 0) {
    //   errors = { ...errors, deliveryMethods: true };
    // }

    if (
      !deliveryMethods?.deliveryMethods?.includes("Fulfillment by Business") &&
      !deliveryMethods?.deliveryMethods?.includes("CostBo Delivery")
    ) {
      errors = { ...errors, deliveryMethods: true };
    }

    // CostBo Delivery Validation
    if (deliveryMethods?.deliveryMethods?.includes(COSTBO_DELIVERY)) {
      const costBoDeliveryDetails = deliveryMethods?.costBoDelivery;
      const codDetails = deliveryMethods?.cod;

      let costBoDeliveryErrors = null;

      if (!costBoDeliveryDetails?.from) {
        costBoDeliveryErrors = {
          ...costBoDeliveryErrors,
          from: "Enter from distance",
        };
      }

      if (
        Number(costBoDeliveryDetails?.form) &&
        Number(costBoDeliveryDetails?.form) > 4000
      ) {
        costBoDeliveryErrors = {
          ...costBoDeliveryErrors,
          from: "From distance should not be greater than 4000 KM",
        };
      }

      if (!costBoDeliveryDetails?.to) {
        costBoDeliveryErrors = {
          ...costBoDeliveryErrors,
          to: "Enter to distance",
        };
      }

      if (
        costBoDeliveryDetails?.to &&
        Number(costBoDeliveryDetails?.to) > 4000
      ) {
        costBoDeliveryErrors = {
          ...costBoDeliveryErrors,
          to: "To distance should not be greater than 4000 KM",
        };
      }

      if (
        costBoDeliveryDetails?.from &&
        costBoDeliveryDetails?.to &&
        Number(costBoDeliveryDetails?.from) >= Number(costBoDeliveryDetails?.to)
      ) {
        costBoDeliveryErrors = {
          ...costBoDeliveryErrors,
          from: "From Distance should not be greater than To Distance",
        };
      }

      if (deliveryMethods?.isCOD) {
        if (codDetails?.chargeTo === "Select Cash on delivery fee charged to") {
          costBoDeliveryErrors = {
            ...costBoDeliveryErrors,
            chargeTo: "Select Cash on delivery fee charged to",
          };
        }

        if (!codDetails?.limit) {
          costBoDeliveryErrors = {
            ...costBoDeliveryErrors,
            chargeTo: "Select Cash on delivery fee charged to",
            limit: "Enter COD max order amount limit",
          };
        }
      }

      if (costBoDeliveryErrors) {
        errors = {
          ...errors,
          costBoDelivery: costBoDeliveryErrors,
        };
      }
    }

    // Fulfillment by Business
    if (deliveryMethods?.deliveryMethods?.includes(FULLFILLMENT_BUSINESS)) {
      const fullfillmentDetails = deliveryMethods?.fullfillment;

      let fullfillmentErrors = null;

      if (!fullfillmentDetails?.from) {
        fullfillmentErrors = {
          ...fullfillmentErrors,
          from: "Enter from distance",
        };
      }

      if (!fullfillmentDetails?.to) {
        fullfillmentErrors = {
          ...fullfillmentErrors,
          to: "Enter to distance",
        };
      }

      if (
        Number(fullfillmentDetails?.form) &&
        Number(fullfillmentDetails?.form) > 4000
      ) {
        fullfillmentErrors = {
          ...fullfillmentErrors,
          from: "From distance should not be greater than 4000 KM",
        };
      }

      if (fullfillmentDetails?.to && Number(fullfillmentDetails?.to) > 4000) {
        fullfillmentErrors = {
          ...fullfillmentErrors,
          to: "To distance should not be greater than 4000 KM",
        };
      }

      if (
        fullfillmentDetails?.from &&
        fullfillmentDetails?.to &&
        Number(fullfillmentDetails?.from) >= Number(fullfillmentDetails?.to)
      ) {
        fullfillmentErrors = {
          ...fullfillmentErrors,
          from: "From Distance should not be greater than To Distance",
        };
      }

      if (deliveryMethods?.fullfillmentPricing?.length === 0) {
        fullfillmentErrors = {
          ...fullfillmentErrors,
          fullfillmentPricing: "Please add pricing details",
        };
      }

      if (fullfillmentErrors) {
        errors = {
          ...errors,
          fullfillment: fullfillmentErrors,
        };
      }
    }

    // B2B Delivery
    if (deliveryMethods?.deliveryMethods?.includes(B2B_DELIVERY)) {
      const b2bDeliveryDetails = deliveryMethods?.b2bDelivery;

      let b2bDeliveryErrors = null;

      if (!b2bDeliveryDetails?.from) {
        b2bDeliveryErrors = {
          ...b2bDeliveryErrors,
          from: "Enter from distance",
        };
      }

      if (!b2bDeliveryDetails?.to) {
        b2bDeliveryErrors = {
          ...b2bDeliveryErrors,
          to: "Enter to distance",
        };
      }

      if (
        Number(b2bDeliveryDetails?.form) &&
        Number(b2bDeliveryDetails?.form) > 4000
      ) {
        b2bDeliveryErrors = {
          ...b2bDeliveryErrors,
          from: "From distance should not be greater than 4000 KM",
        };
      }

      if (b2bDeliveryDetails?.to && Number(b2bDeliveryDetails?.to) > 4000) {
        b2bDeliveryErrors = {
          ...b2bDeliveryErrors,
          to: "To distance should not be greater than 4000 KM",
        };
      }

      if (
        b2bDeliveryDetails?.from &&
        b2bDeliveryDetails?.to &&
        Number(b2bDeliveryDetails?.from) >= Number(b2bDeliveryDetails?.to)
      ) {
        b2bDeliveryErrors = {
          ...b2bDeliveryErrors,
          from: "From Distance should not be greater than To Distance",
        };
      }

      if (b2bDeliveryErrors) {
        errors = {
          ...errors,
          b2bDelivery: b2bDeliveryErrors,
        };
      }
    }

    // Costbo HyperLocal Delivery
    if (deliveryMethods?.deliveryMethods?.includes(COSTBO_HYPERLOCAL)) {
      const costboHyperLocalDetails = deliveryMethods?.costBoHyperlocal;

      let costboHyperLocalErrors = null;

      if (!costboHyperLocalDetails?.from) {
        costboHyperLocalErrors = {
          ...costboHyperLocalErrors,
          from: "Enter from distance",
        };
      }

      if (!costboHyperLocalDetails?.to) {
        costboHyperLocalErrors = {
          ...costboHyperLocalErrors,
          to: "Enter to distance",
        };
      }

      if (
        Number(costboHyperLocalDetails?.form) &&
        Number(costboHyperLocalDetails?.form) > 40
      ) {
        costboHyperLocalErrors = {
          ...costboHyperLocalErrors,
          from: "From distance should not be greater than 40 KM",
        };
      }

      if (
        costboHyperLocalDetails?.to &&
        Number(costboHyperLocalDetails?.to) > 40
      ) {
        costboHyperLocalErrors = {
          ...costboHyperLocalErrors,
          to: "To distance should not be greater than 40 KM",
        };
      }

      if (
        costboHyperLocalDetails?.from &&
        costboHyperLocalDetails?.to &&
        Number(costboHyperLocalDetails?.from) >=
          Number(costboHyperLocalDetails?.to)
      ) {
        costboHyperLocalErrors = {
          ...costboHyperLocalErrors,
          from: "From Distance should not be greater than To Distance",
        };
      }

      if (costboHyperLocalErrors) {
        errors = {
          ...errors,
          costBoHyperlocal: costboHyperLocalErrors,
        };
      }
    }

    // Store Pickup
    if (deliveryMethods?.deliveryMethods?.includes(STORE_PICKUP)) {
      const storePickupDetails = deliveryMethods?.storePickup;

      let storePickupErrors = null;

      if (storePickupDetails?.discountBy === "Select Discount Type") {
        storePickupErrors = {
          ...storePickupErrors,
          discountBy: "Select Discount Type",
        };
      }

      if (storePickupDetails?.discountBy === "Amount") {
        if (!storePickupDetails?.discountAmount) {
          storePickupErrors = {
            ...storePickupErrors,
            discountAmount: "Enter discount amount",
          };
        }
      }

      if (storePickupDetails?.discountBy === "Percentage") {
        if (!storePickupDetails?.discountPercentage) {
          storePickupErrors = {
            ...storePickupErrors,
            discountPercentage: "Enter discount percentage",
          };
        } else if (storePickupDetails?.discountPercentage > 100) {
          storePickupErrors = {
            ...storePickupErrors,
            discountPercentage:
              "Discount percentage should be less than or equal to 100",
          };
        }
      }

      if (storePickupErrors) {
        errors = {
          ...errors,
          storePickup: storePickupErrors,
        };
      }
    }

    console.log("deliveryMethods_erros:", deliveryMethods);

    if (errors) {
      handleSetValue("errors", errors, deliveryMethods, setDeliveryMethods);
    } else {
      handleSetValue("errors", null, deliveryMethods, setDeliveryMethods);
      setCurrentPage("discount-details");
      contentRef.current.scrollIntoView();
    }
  };

  const validateDiscountDetails = () => {
    let errors = null;

    if (
      discountInfo?.rewardPointPercent?.label !==
        "Select Cashback Percentage" &&
      discountInfo?.rewardPointPercent?.label !== "0%"

      // || discountInfo?.referralPointsMaxPerTxn !== "" ||
      // discountInfo?.rewardExpirationDays?.label !== "Select Cashback Validity"
    ) {
      console.log(
        "HITTITITI__Login1",
        discountInfo?.rewardPointPercent?.label !==
          "Select Cashback Percentage" &&
          discountInfo?.rewardPointPercent?.label !== "0%"
      );
      console.log(
        "HITTITITI__Login2",
        discountInfo?.referralPointsMaxPerTxn !== ""
      );
      console.log(
        "HITTITITI__Login3",
        discountInfo?.rewardExpirationDays?.label !== "Select Cashback Validity"
      );

      console.log(
        "HITTITITI__rewardPointPercent",
        discountInfo?.rewardPointPercent?.label
      );
      console.log(
        "HITTITITI__referralPointsMaxPerTxn",
        discountInfo?.referralPointsMaxPerTxn
      );
      console.log(
        "HITTITITI__rewardExpirationDays",
        discountInfo?.rewardExpirationDays?.label
      );

      if (
        discountInfo?.rewardPointPercent?.label === "Select Cashback Percentage"
      ) {
        errors = {
          ...errors,
          rewardPointPercent: "Select cashback percentage",
        };
      }
      if (discountInfo?.referralPointsMaxPerTxn === "") {
        errors = {
          ...errors,
          referralPointsMaxPerTxn: "Enter max cashback usage per order",
        };
      }
      if (
        discountInfo?.rewardExpirationDays?.label === "Select Cashback Validity"
      ) {
        errors = {
          ...errors,
          rewardExpirationDays: "Select cashback validity",
        };
      }
    }
    // if (discountInfo?.immediateDiscount === '') {
    //   errors = { ...errors, immediateDiscount: 'Enter free shipment orders above' };
    // }
    // if (discountInfo?.additionalDiscount?.length === 0) {
    //   errors = { ...errors, additionalDiscount: 'Please add additional discount details' };
    // }

    console.log("discountInfo:", discountInfo);

    if (errors) {
      handleSetValue("errors", errors, discountInfo, setDiscountInfo);
    } else {
      handleSetValue("errors", null, discountInfo, setDiscountInfo);
      setCurrentPage("other-detail");
      // setCurrentPage("other-detail");
      contentRef.current.scrollIntoView();
    }
  };

  const validateOtherDetails = () => {
    let errors = null;
    // if (otherDetails?.minOrderAmount === "") {
    //   errors = { ...errors, minOrderAmount: "Enter minimun order amount" };
    // }

    if (otherDetails?.returnPolicy === "") {
      errors = { ...errors, returnPolicy: "Enter return policy" };
    }

    if (
      otherDetails?.returnPolicy !== "" &&
      otherDetails?.returnPolicy?.length > 150
    ) {
      errors = { ...errors, returnPolicy: "Max limit is 150 characters" };
    }

    if (otherDetails?.notes !== "" && otherDetails?.notes?.length > 150) {
      errors = { ...errors, notes: "Max limit is 150 characters" };
    }

    console.log("otherDetails:", otherDetails);

    if (errors) {
      handleSetValue("errors", errors, otherDetails, setOtherDetails);
    } else {
      handleSetValue("errors", null, otherDetails, setOtherDetails);
      setCurrentPage("preview");
      contentRef.current.scrollIntoView();

      console.log("PickUp Address:", addressInfo);
      console.log("Delivery Methods:", deliveryMethods);
      console.log("Discount Details:", discountInfo);
      console.log("OtherDetails:", otherDetails);
      console.log("-----------------");
    }
  };
  // Bank details
  const validateBankDetails = (nowData, errorCheck) => {
    let isError = false;
    let errors = {};

    if (!bankDetails.accountName) {
      errors = { ...errors, accountName: "Enter Account Holder Name" };
      isError = true;
    }

    if (
      !bankDetails.accountNo
      // && bankDetails.accountNo?.length < 20
    ) {
      errors = {
        ...errors,
        accountNo: "Account number should be Max 20 characters",
      };
      isError = true;
    }

    if (!bankDetails.accountType) {
      errors = { ...errors, accountType: "Enter Account Type" };
      isError = true;
    }
    if (!bankDetails.bankName) {
      errors = { ...errors, bankName: "Enter bank Name" };
      isError = true;
    }
    if (!bankDetails.bankAddress) {
      errors = { ...errors, bankAddress: "Enter bank branch name" };
      isError = true;
    }
    if (!bankDetails.ifscCode) {
      errors = { ...errors, ifscCode: "Enter IFSC code" };
      isError = true;
    }
    if (bankDetails?.ifscCode?.length != 11) {
      errors = { ...errors, ifscCode: "Enter 11 characters IFSC code" };
      isError = true;
    }

    if (!bankDetails.addedDate) {
      errors = { ...errors, addedDate: "Enter Active Date" };
      isError = true;
    }

    if (!bankDetails?.addedDate) {
      const fromDate = moment(bankDetails?.addedDate);
      const currentDate = moment().subtract(1, "day");

      const isFromBeforeCurrentDate = fromDate?.isBefore(currentDate);

      if (isFromBeforeCurrentDate) {
        errors = { ...errors, addedDate: "Active Date cannot be in past" };
        isError = true;
      }
    }
    console.log("nowData", nowData);

    if (isError) {
      console.log("bankDetails", bankDetails);
      handleSetValue("errors", errors, bankDetails, setBankDetails);
      setBankInfoError("Please fix the errors above to submit");
    } else {
      handleSetValue("errors", null, bankDetails, setBankDetails);
      setBankInfoError("");
      if (!errorCheck) addBankData(nowData);
    }
  };

  const addBankData = async (nowData) => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    console.log(
      "getBankDetailsDATA",
      nowData?.[0]?.id || nowData?.id,
      businessId,
      inactiveDate?.expiredDate,
      "expiredDate",
      moment(inactiveDate?.expiredDate)
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss[Z]"),
      "daddedDate",
      moment(bankDetails?.addedDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss[Z]")
      //       .utc()
      //       .format("YYYY-MM-DDTHH:mm:ss[Z]"),
    );
    setBankLoader(true);
    if (inactiveDate?.expiredDate) {
      const response = await UpdateInactiveDateApi({
        id: nowData?.id || nowData?.[0]?.id,
        businessId: businessId,
        expiredDate: moment(inactiveDate?.expiredDate)
          .endOf("day")
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss[Z]"),
      });
      console.log("UpdateInactiveDateApiresp", response);
    }

    const postData = prepareBankDetailsPostData({
      ...bankDetails,
      businessId,
    });
    const res = await postBankDetailsApi(postData);
    console.log("HITT_BANK_REFRESH", postData);
    console.log("HITT__R", res);
    setBankLoader(false);
    setCurrentPage("bank-detail");
    navigate(`/${sessionStorage.getItem("costboWEBURL")}/bankInfo`);
  };

  const updateInactivedate = (data) => {
    let isError = false;
    let errors = {};
    if (!inactiveDate.expiredDate) {
      errors = { ...errors, expiredDate: "Enter Inactive Date" };
      isError = true;
    }
    const fromDate = moment(bankDetails?.addedDate);
    const currentDate = moment().subtract(1, "month");
    const isFromBeforeCurrentDate = fromDate?.isBefore(currentDate);
    const datecheck = moment(data)
      .startOf("day")
      .isAfter(moment(inactiveDate?.expiredDate).endOf("day"));
    if (datecheck) {
      errors = {
        ...errors,
        expiredDate: "Enter Inactive date after the active date",
      };
      isError = true;
    }
    if (inactiveDate?.expiredDate) {
      const date = moment(inactiveDate?.expiredDate).isSame(
        moment(inactiveDate?.expiredDate).endOf("month"),
        "day"
      );

      if (!date) {
        errors = { ...errors, expiredDate: "Enter Inactive date as Month End" };
        isError = true;
      }
      // if (!isFromBeforeCurrentDate) {
      //   errors = { ...errors, expiredDate: "Start Date cannot be in past" };
      //   isError = true;
      // }
    }
    if (isError) {
      console.log("inactiveDate", inactiveDate);
      handleSetValue("errors", errors, inactiveDate, setInactiveDate);
      setBankInfoError("Please fix the errors above to submit");
      return true;
    } else {
      handleSetValue("errors", null, inactiveDate, setInactiveDate);
      setBankInfoError("");
      return false;
    }
  };

  const updateCommerceDetails = async () => {
    const businessId = activeBusinessInfo?.id;
    const postData = prepareEditCommerceData({
      addressInfo,
      deliveryMethods,
      discountInfo,
      otherDetails,
      gstNumber: activeBusinessInfo?.gstnumber || "",
      panNumber: activeBusinessInfo?.pannumber || "",
      commerceInfo: commerceInfo?.info || null,
    });

    const responce = await updateCommerceSubmitAPI(postData, businessId);

    if (responce.error) {
      setShowErrorAlert(true);
      throw "ERROR FOUND";
    }

    const editBusinessTextData = prepareUpdateBusinessTimingData({
      businessTiming,
      businessData: activeBusinessInfo,
      deliveryMethods,
    });

    console.log("UPDATE_E_COMM:", postData, editBusinessTextData);

    const businessTextUpdateRes = await updateBusinessTimingAndDeliveryMethod(
      editBusinessTextData,
      activeBusinessInfo?.id
    );

    if (businessTextUpdateRes.error) {
      setShowErrorAlert(true);
      throw "ERROR FOUND";
    }

    sendWhatsAppNotificationData();
    dispatch(getCommerceInfo(businessId));
    setShowAlert(true);
  };

  const sendWhatsAppNotificationData = async () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const data = {
      businessId: businessId || "",
      code: "B004",
      businessOwnerName: activeBusinessInfo?.owner?.name || "",
      businessName: activeBusinessInfo?.name || "",
      businessOwnerPhoneNumber: activeBusinessInfo?.owner?.phone || "",
      // businessOwnerPhoneNumber: activeBusinessInfo?.contactNos?.numbers || "" ,
    };
    console.log("Whatsapp", data);
    const res = await sendWhatsAppNotification(data, businessId);
  };

  const handleNext = () => {
    if (currentPage === "pickup-address") setCurrentPage("business-timings");
    if (currentPage === "business-timings") setCurrentPage("delivery-methods");
    if (currentPage === "delivery-methods") setCurrentPage("discount-details");
    if (currentPage === "discount-details") setCurrentPage("bank-detail");
    if (currentPage === "bank-detail") setCurrentPage("other-detail");

    contentRef.current.scrollIntoView();
  };

  const goBack = () => {
    if (currentPage === "delivery-methods") setCurrentPage("business-timings");
    if (currentPage === "discount-details") setCurrentPage("delivery-methods");
    // if (currentPage === "bank-detail") setCurrentPage("discount-details");
    // if (currentPage === "manager-delegates") setCurrentPage("discount-details");
    if (currentPage === "other-detail") setCurrentPage("discount-details");
    if (currentPage === "preview") setCurrentPage("other-detail");

    contentRef.current.scrollIntoView();
  };

  const handleCancel = () => {
    // if (
    //   commerceInfo?.info?.status === "active" ||
    //   commerceInfo?.info?.status === "submitted"
    // ) {
    //   navigate(`/${sessionStorage.getItem("costboWEBURL")}/warehouseDetail`);
    //   return;
    // }
    // navigate("/dashboardStartBusiness");

    if (currentPage === "manager-delegates") {
      navigate(`/${sessionStorage.getItem("costboWEBURL")}/managers`);
      return;
    }

    navigate(`/${sessionStorage.getItem("costboWEBURL")}/warehouseDetail`);
  };

  // Get CommerceInfo
  const getCommerceInfoDetails = async (businessId) => {
    const businessCommerceInfo = await getCommerceDashboardInfoAPI(businessId);
    setCommerceInfo(businessCommerceInfo || null);
  };

  // Auto fill input values
  const autoFillEditData = (commerceInfo, businessInfo) => {
    // console.log("autoFillEditData__commerceInfo:", commerceInfo);
    // console.log("autoFillEditData__businessInfo:", businessInfo);

    const openTimings = prepareBusinessHoursData(businessInfo?.businessHours);

    setBusinessTiming((prevState) => ({
      ...prevState,
      businessTime: openTimings?.businessTime,
      allDays: openTimings?.allDays || { from: "", to: "" },
      businessHours: openTimings?.businessHours,
    }));

    const deliveryOptionData = prepareDeliveryMethodData(commerceInfo);

    setDeliveryMethods((prevState) => ({
      ...prevState,
      deliveryMethods: deliveryOptionData?.deliveryMethods,
      costBoDelivery: deliveryOptionData?.costBoDelivery,
      cod: deliveryOptionData?.cod,
      isCOD: deliveryOptionData?.isCOD,
      costBoHyperlocal: deliveryOptionData?.costBoHyperlocal,
      fullfillment: deliveryOptionData?.fullfillment,
      fullfillmentPricing: deliveryOptionData?.fullfillmentPricing,
      b2bDelivery: deliveryOptionData?.b2bDelivery,
      storePickup: deliveryOptionData?.storePickup,
    }));

    const discountData = prepareDiscountData(commerceInfo);
    setDiscountInfo(discountData);

    const orderInfoDetails = commerceInfo?.info?.orderInfoDetails?.[0] || null;

    setOtherDetails((prevState) => ({
      minOrderAmount: orderInfoDetails?.minOrderAmount || "",
      returnPolicy: commerceInfo?.info?.returnPolicy || "",
      notes: commerceInfo?.info?.notes || "",
    }));
    // setBankDetails((prevState) => ({
    //   accountName: bankInfo?.accountName,
    //   accountNo: bankInfo?.accountNo,
    //   accountType: bankInfo?.accountType,
    //   bankName: bankInfo?.bankName,
    //   bankAddress: bankInfo?.bankAddress,
    //   ifscCode: bankInfo?.ifscCode,
    //   addedDate: bankInfo?.addedDate,
    // }));
  };

  // Whatsapp chat click
  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Support+Request.+`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!activeBusinessInfo) {
        dispatch(getActiveBusinessInfo(businessId));
      }
      getCommerceInfoDetails(businessId);
      dispatch(getManagerDetails(businessId));
      dispatch(verifyBusinessRole({ businessId, params: { role: "OWNER" } }));
      dispatch(verifyManagerRole({ businessId, params: { role: "ADMIN" } }));
      dispatch(BankDetailsData({ businessId }));
    }
  }, []);

  useEffect(() => {
    if (commerceInfo && activeBusinessInfo) {
      autoFillEditData(commerceInfo, activeBusinessInfo);
    }
    return () => {};
  }, [commerceInfo, activeBusinessInfo]);

  return (
    <Box sx={styles.container}>
      <ModalCard
        open={showErrorAlert}
        handleClose={() => {
          // setShowAlert(false);
        }}
        width="48%"
      >
        <AlertPopup
          text={`Sorry we have encountered a technical issue. Please retry again.`}
          btnTxt="GO TO DASHBOARD"
          onClick={() => {
            setShowErrorAlert(false);
            const businessUrl = sessionStorage.getItem("costboWEBURL");
            navigate(
              `/${sessionStorage.getItem("costboWEBURL")}/warehouseDetail`
            );
          }}
        />
      </ModalCard>

      <DashboardLayout>
        <Box ref={contentRef} sx={styles.content}>
          {currentPage !== "preview" && (
            <Box sx={stickyHeader} pb={1.5}>
              <Typography
                component="h6"
                fontSize={{ xs: 15, md: 17 }}
                color={blackColor}
                fontWeight={600}
                lineHeight="20px"
                mb={"4px !important"}
              >
                <span
                  style={{
                    color: matches ? blueColor04 : mobilePrimary,
                    marginRight: "5px",
                  }}
                >
                  {type=== 'Add' ? "Add" : "Edit"}
                </span>{" "}
                E-Commerce Details
              </Typography>

              <Typography
                variant="h6"
                fontSize={13}
                fontWeight={500}
                color={blackShade21}
                lineHeight={"20px"}
                // display="inline"
                // mb="15px"
              >
                {ECOMINPUTSUPPORTLABEL}{" "}
                <Typography
                  // variant="body1"
                  fontSize={13}
                  fontWeight={500}
                  color={{ xs: mobilePrimary, md: blueColor04 }}
                  display="inline"
                  // onclick={handleChatClick}
                  component="a"
                  href={`https://mail.google.com/mail/u/0/?cc=&subject=yourSubject&su=${activeBusinessInfo?.name}+- E-Commerce Controls+-+Support+Request.+&fs=1&to=${emailAddress}&tf=cm`}
                  target="_blank"
                >
                  Email
                </Typography>
                {"  "}
                or{" "}
                <Typography
                  // variant="body1"
                  fontSize={13}
                  fontWeight={500}
                  color={{ xs: mobilePrimary, md: blueColor04 }}
                  sx={{ cursor: "pointer" }}
                  display="inline"
                  onClick={handleChatClick}
                >
                  Whatsapp
                </Typography>
              </Typography>

              <DashboardStepper
                stepList={ECOMMERCE_STEP_LIST}
                currentPage={currentPage}
                isAddProduct
              />
            </Box>
          )}
          {activeBusinessInfo &&
          commerceInfo &&
          managersInfo &&
          !managersInfoLoader &&
          !businessRoleLoader &&
          !managerRoleLoader ? (
            <Box>
              {currentPage === "business-timings" && (
                <AddBusinessTimings
                  isEdit
                  data={businessTiming}
                  setData={setBusinessTiming}
                  onValueChange={(name, value) => {
                    handleSetValue(
                      name,
                      value,
                      businessTiming,
                      setBusinessTiming
                    );
                  }}
                  currentPage={currentPage}
                  goBack={goBack}
                  gotoNext={validateBusinessTiming}
                  handleCancel={handleCancel}
                />
              )}
              {currentPage === "delivery-methods" && (
                <AddDeliveryMethods
                  isEdit
                  data={deliveryMethods}
                  setData={setDeliveryMethods}
                  setDiscountInfo={setDiscountInfo}
                  onValueChange={(name, value) => {
                    handleSetValue(
                      name,
                      value,
                      deliveryMethods,
                      setDeliveryMethods
                    );
                  }}
                  currentPage={currentPage}
                  goBack={goBack}
                  gotoNext={validateDeliveryMethods}
                  handleCancel={handleCancel}
                />
              )}
              {currentPage === "discount-details" && (
                <AddDiscountDetails
                  data={discountInfo}
                  setData={setDiscountInfo}
                  onValueChange={(name, value) => {
                    handleSetValue(name, value, discountInfo, setDiscountInfo);
                  }}
                  currentPage={currentPage}
                  goBack={goBack}
                  gotoNext={validateDiscountDetails}
                  deliveryMethods={deliveryMethods?.deliveryMethods || []}
                  handleCancel={handleCancel}
                />
              )}
              {currentPage === "bank-detail" && (
                <AddBankDetails
                  data={bankInfo}
                  addBankData={bankDetails}
                  currentPage={currentPage}
                  inactiveDate={inactiveDate}
                  onInactiveDate={(name, value) => {
                    handleSetValue(name, value, inactiveDate, setInactiveDate);
                  }}
                  setData={setBankDetails}
                  onValueChange={(name, value) => {
                    handleSetValue(name, value, bankDetails, setBankDetails);
                  }}
                  onSubmit={validateBankDetails}
                  goBack={goBack}
                  UpdateInactiveDate={updateInactivedate}
                  setBankInfoError={setBankInfoError}
                  bankInfoError={bankInfoError}
                  loader={bankLoader}
                  // gotoNext={validateBankDetails}
                />
              )}
              {currentPage === "other-detail" && (
                <AddOtherDetails
                  data={otherDetails}
                  setData={setOtherDetails}
                  onValueChange={(name, value) => {
                    handleSetValue(name, value, otherDetails, setOtherDetails);
                  }}
                  currentPage={currentPage}
                  goBack={goBack}
                  gotoNext={validateOtherDetails}
                  handleCancel={handleCancel}
                />
              )}
              {currentPage === "preview" && (
                <CommerceInfoPreview
                  data={{
                    businessTiming,
                    addressInfo,
                    deliveryMethods,
                    discountInfo,
                    otherDetails,
                  }}
                  onSubmit={updateCommerceDetails}
                  onBack={goBack}
                  handleCancel={handleCancel}
                />
              )}
              {currentPage === "manager-delegates" && (
                <AddManagerDelegates
                  activeBusinessInfo={activeBusinessInfo}
                  goBack={goBack}
                  handleCancel={handleCancel}
                  gotoNext={() => setCurrentPage("other-detail")}
                  // managersInfo={managersInfo}
                />
              )}
            </Box>
          ) : (
            <ThreeDotLoader />
          )}
        </Box>
      </DashboardLayout>

      {/* <ModalCard open={addProductLoader} handleClose={() => {}} width="48%">
        <Box backgroundColor="#ffffff">
          <Loader />
        </Box>
      </ModalCard> */}

      <ModalCard
        open={showAlert}
        handleClose={() => {
          // setShowAlert(false);
        }}
        width="48%"
      >
        <AlertMessagePopup
          text={`ECommerce details have been submitted for approval. Please contact support@costbo.com for any queries`}
          onClick={() => {
            const webURL = sessionStorage.getItem("costboWEBURL");
            navigate(
              `/${sessionStorage.getItem("costboWEBURL")}/warehouseDetail`
            );
          }}
        />
      </ModalCard>
    </Box>
  );
}

export default EditCommerceInfo;

const AlertMessagePopup = ({ text, onClick }) => {
  return (
    <Box sx={styles.alertCard}>
      <Typography
        variant="body2"
        fontSize={14}
        fontWeight={500}
        textAlign="center"
        sx={{ whiteSpace: "pre-line", lineHeight: "26px", my: 1.2 }}
      >
        {text}
      </Typography>
      <Button sx={styles.alertBtn} variant="outlined" onClick={onClick}>
        DONE
      </Button>
    </Box>
  );
};

const styles = {
  container: {
    backgroundColor: BGColor02,
    pb: 2,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    // gap: { xs: "0px", md: "25px" },
  },

  stickyTop: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 100,
    pb: 1.5,
    backgroundColor: BGColor02,
  },

  menuStickyLeft: {
    position: { md: "sticky", xs: "static" },
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 100,
    backgroundColor: BGColor02,
  },

  alertCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    padding: "20px 20px 15px 20px",
    borderRadius: "8px",
  },

  alertBtn: {
    fontSize: "13px",
    fontWeight: "500",
    color: whiteColor,
    backgroundColor: primaryColor,
    padding: "6px 24px",
    my: "10px",
    "&:hover": {
      color: primaryColor,
      backgroundColor: whiteColor,
    },
  },
};
