import { Box, Typography, styled, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import {
  blackShade159,
  blackShade58,
  blackShade59,
  blueColor04,
  greyColor25,
  greyColor6,
  greyShade80,
  gstActiveBorder,
  gstInactiveBorder,
  mobilePrimary,
  whiteColor,
} from "../configs/styles/muiThemes";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import GSTDetailsForm from "./BusinessOnBoard/Forms/GSTDetailsForm";
import AddressInfoForm from "./BusinessOnBoard/Forms/AddressInfoForm";
import { useState } from "react";
import StoreInfoForm from "./BusinessOnBoard/Forms/StoreInfoForm";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessLookupTypes } from "../reducers/businessOnboard/businessOnboardSlice";
import { useNavigate } from "react-router-dom";
import { addBusinessDetails } from "../utils/businessDataUtils";
import { geProfilePhoto, getUserName } from "../utils/validationUtils";
import { addBusinessData } from "../service/api_calls/BusinessOnboard/BusinessOnboardingAPI";
import { clearViewMyBusinessData } from "../reducers/businessPreview/businessPreviewSlice";
import ModalCard from "../components/ModalCard";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

function BusinessOnboarding({ open, handleClose }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const isProfileImgAvailabel = geProfilePhoto();
  const username = getUserName();

  const [currentPage, setCurrentPage] = useState("store-info");
  const [titleDescrip, setTitleDescrip] = useState({
    title: "Add your business in few simple steps",
    description: "",
    step: "1",
    progress: 10,
  });

  const [gstDetails, setGstDetails] = useState({
    gstNumber: "",
    registeredBusinessName: "",
    panNumber: "",
    errors: {},
  });

  const [addressDetails, setAddressDetails] = useState({
    pinCode: "",
    address: "",
    landmark: "",
    city: "",
    state: "",
    locality: "",
    sublocality: "",
    lat: "",
    lng: "",
    errors: {},
  });

  const [storeInfoDetails, setStoreInfoDetails] = useState({
    name: "",
    type: [],
    errors: {},
  });

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { businessLookupTypes, businessLookupTypesLoader } = useSelector(
    (state) => state.businessOnboard
  );

  useEffect(() => {
    sessionStorage.removeItem("selectedBusinessId");
    dispatch(clearViewMyBusinessData());
    dispatch(getBusinessLookupTypes());
  }, []);

  const handleValueChange = (state, setState, name, value) => {
    let errors = { ...state?.errors };
    if (value) {
      errors = { ...errors, [name]: "" };
    }

    setState({
      ...state,
      [name]: value,
      errors,
    });
  };

  const handleNext = () => {
    if (currentPage === "gst-info") {
      setCurrentPage("address-info");
      setTitleDescrip({
        title: "Business Address Detail",
        description: "",
        step: "3",
        progress: 80,
      });
    }
    if (currentPage === "address-info") {
      setCurrentPage("store-info");
      setTitleDescrip({
        title: "Add your business in few simple steps",
        description: "",
        step: "1",
        progress: 10,
      });
    }
    if (currentPage === "store-info") {
      setCurrentPage("gst-info");
      setTitleDescrip({
        title: "Business Registration Detail",
        description: "",
        step: "2",
        progress: 45,
      });
    }
  };

  const handleBack = () => {
    if (currentPage === "address-info") setCurrentPage("gst-info");
    if (currentPage === "store-info") setCurrentPage("address-info");
    if (currentPage === "gst-info") setCurrentPage("store-info");
  };

  // Validating Gst Form and Show Next Form
  const handleGstForm = () => {
    let isError = false;
    const errors = {};

    if (!gstDetails?.gstNumber) {
      errors.gstNumber = "Please enter GST number";
      isError = true;
    }
    if (
      !/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
        gstDetails?.gstNumber
      )
    ) {
      errors.gstNumber = "Please enter valid GST number";
      isError = true;
    }

    if (!gstDetails?.registeredBusinessName) {
      errors.registeredBusinessName = "Please enter registered business name";
      isError = true;
    }

    if (!gstDetails?.panNumber) {
      errors.panNumber = "Please enter pan number";
      isError = true;
    }

    if (!/[A-Z]{5}\d{4}[A-Z]{1}/.test(gstDetails?.panNumber)) {
      errors.panNumber = "Please enter valid PAN number";
      isError = true;
    }

    if (isError) {
      setGstDetails({ ...gstDetails, errors });
      return;
    }
    setGstDetails({ ...gstDetails, errors: {} });
    handleNext();
  };

  // Validating Address Form and Show Next Form
  const handleAddressForm = async () => {
    let isError = false;
    const errors = {};

    if (!addressDetails?.pinCode) {
      errors.pinCode = "Please enter pin code";
      isError = true;
    }

    if (!addressDetails?.address) {
      errors.address = "Please enter address";
      isError = true;
    }

    if (!addressDetails?.city) {
      errors.city = "Please enter city";
      isError = true;
    }

    if (!addressDetails?.state) {
      errors.state = "Please enter state";
      isError = true;
    }

    if (isError) {
      setAddressDetails({ ...addressDetails, errors });
      return;
    }

    isError = false;
    setAddressDetails({ ...addressDetails, errors: {} });

    console.log("HITTTT:", { gstDetails, addressDetails, storeInfoDetails });
    // handleNext();
    navigate("/addBusinessDetail", {
      state: { data: { gstDetails, addressDetails, storeInfoDetails } },
    });
  };

  // Validating Store Info Form and Show Next Form
  const handleStoreInfoForm = async () => {
    let isError = false;
    const errors = {};

    if (!storeInfoDetails?.name) {
      errors.name = "Please enter business name";
      isError = true;
    }

    console.log("storeInfoDetails?.type", storeInfoDetails?.type);

    if (!storeInfoDetails?.type) {
      errors.type = "Please select business type";
      isError = true;
    }

    if (storeInfoDetails?.type?.length == 0) {
      errors.type = "Please select business type";
      isError = true;
    }

    if (isError) {
      setStoreInfoDetails({ ...storeInfoDetails, errors });
      return;
    }

    isError = false;
    setStoreInfoDetails({ ...storeInfoDetails, errors: {} });

    handleNext();

    // console.log(gstDetails, addressDetails, storeInfoDetails);

    // navigate("/addBusinessDetail", {
    //   state: { data: { gstDetails, addressDetails, storeInfoDetails } },
    // });
  };

  const handleSave = () => {
    const businessData = addBusinessDetails({
      gstDetails,
      addressDetails,
      storeInfoDetails,
    });
    console.log(businessData);
    addBusinessData(businessData).then((res) => {
      console.log(res);
      navigate("/businessListing");
    });
  };

  return (
    <ModalCard
      open={open}
      handleClose={handleClose}
      width="36%"
      modalType="auth"
      hideClose
    >
      <Box overflow="hidden">
        <Box sx={styles.content}>
          {/* <Box sx={{ width: { md: "70%", xs: "100%" } }}> */}
          <Box>
            {/* <UserNavProfileCard
            prifileImg={isProfileImgAvailabel ? geProfilePhoto() : ""}
            name={username}
            // onLogout={onLogout}
            styles={{ position: "absolute", top: "15px", right: "20px" }}
          /> */}

            {/* <Typography
            component="h3"
            fontSize={{ xs: 17, md: 28 }}
            color={blackShade58}
            fontWeight={600}
            lineHeight="30px"
            mb={{ xs: "2px !important", md: "12px !important" }}
            textAlign="center"
          >
            Register your Business
          </Typography>

          <Typography
            variant="body1"
            fontSize={{ xs: 13, md: 17 }}
            color={blackShade58}
            fontWeight={400}
            lineHeight="20px"
            textAlign="center"
            mb="25px !important"
          >
            You are just a few steps away.
          </Typography>

          <StepperCard currentPage={currentPage} /> */}

            <Box>
              <Typography
                variant="body1"
                color={greyColor6}
                fontSize={{ xs: 11, md: 13 }}
                fontWeight={500}
                textAlign="right"
                m={{ xs: "4px 0 8px !important", md: "0px 0 8px !important" }}
              >
                Steps {titleDescrip?.step} / 4
              </Typography>

              <BorderLinearProgress
                sx={{
                  height: "8px",
                  borderRadius: "10px",
                  backgroundColor: greyColor25,
                  mb: { xs: "20px", md: "20px" },
                  "& .MuiLinearProgress-bar": {
                    borderRadius: "8px !important",
                  },
                }}
                variant="determinate"
                value={titleDescrip?.progress}
                matches={matches}
              />

              <Typography
                variant="h4"
                color={blackShade58}
                fontSize={{ xs: 16, md: 17 }}
                fontWeight={600}
                lineHeight={{ xs: "21px", md: "30px" }}
                m={{
                  md: "10px 0 18px !important",
                  xs: "10px 0 8px !important",
                }}
              >
                {titleDescrip?.title}
              </Typography>

              {/* <Typography
                variant="body1"
                color={greyColor6}
                fontSize={{ xs: 14, md: 16 }}
                fontWeight={400}
                lineHeight="25px"
                mb="25px !important"
              >
                {titleDescrip?.description}
              </Typography> */}
            </Box>

            {currentPage === "gst-info" ? (
              <GSTDetailsForm
                handleNext={handleGstForm}
                handleBack={handleBack}
                handleSave={handleSave}
                gstDetails={gstDetails}
                setGstDetails={setGstDetails}
                onValueChange={(name, value) => {
                  handleValueChange(gstDetails, setGstDetails, name, value);
                }}
              />
            ) : null}

            {currentPage === "address-info" ? (
              <AddressInfoForm
                handleNext={handleAddressForm}
                handleBack={handleBack}
                handleSave={handleSave}
                addressDetails={addressDetails}
                onValueChange={(name, value) => {
                  handleValueChange(
                    addressDetails,
                    setAddressDetails,
                    name,
                    value
                  );
                }}
                setAddressDetails={setAddressDetails}
              />
            ) : null}

            {currentPage === "store-info" ? (
              <StoreInfoForm
                handleNext={handleStoreInfoForm}
                handleBack={handleBack}
                businessLookupTypes={businessLookupTypes}
                storeInfoDetails={storeInfoDetails}
                onValueChange={(name, value) => {
                  handleValueChange(
                    storeInfoDetails,
                    setStoreInfoDetails,
                    name,
                    value
                  );
                }}
                handleSave={handleSave}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </ModalCard>
  );
}

export default BusinessOnboarding;

const BorderLinearProgress = styled(LinearProgress)(({ theme, matches }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: matches ? blueColor04 : mobilePrimary,
  },
}));

const StepperCard = ({ currentPage }) => {
  return (
    <Box
      width={{ xs: "100%", md: "100%" }}
      display="flex"
      alignItems="center"
      flexWrap="wrap"
      gap="10px"
      borderBottom={gstInactiveBorder}
    >
      <StepperBtn
        icon={
          <StorefrontOutlinedIcon
            sx={{
              fontSize: { xs: "18px", md: "22px" },
              color:
                currentPage === "gst-info" ||
                currentPage === "address-info" ||
                currentPage === "store-info"
                  ? blackShade59
                  : greyShade80,
            }}
          />
        }
        title="STORE INFO"
        onClick={() => {}}
        isEnd
        isActive={
          currentPage === "gst-info" ||
          currentPage === "address-info" ||
          currentPage === "store-info"
        }
      />

      <StepperBtn
        icon={
          <ReceiptLongIcon
            sx={{
              fontSize: { xs: "18px", md: "22px" },
              color:
                currentPage === "gst-info" || currentPage === "address-info"
                  ? blackShade59
                  : greyShade80,
            }}
          />
        }
        title="GST INFO"
        onClick={() => {}}
        isStart
        isActive={currentPage === "gst-info" || currentPage === "address-info"}
      />

      <StepperBtn
        icon={
          <MapOutlinedIcon
            sx={{
              fontSize: { xs: "18px", md: "22px" },
              color:
                currentPage === "address-info" ? blackShade59 : greyShade80,
            }}
          />
        }
        title="ADDRESS INFO"
        onClick={() => {}}
        isActive={currentPage === "address-info"}
      />
    </Box>
  );
};

const StepperBtn = ({ icon, title, isStart, isEnd, isActive }) => {
  return (
    <Box
      sx={{
        flex: 1,
        minWidth: "118px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: { xs: "6px 0", md: "10px 0px" },
        borderBottom: isActive ? gstActiveBorder : "none",
        // borderRadius: isStart
        //   ? "6px 0px 0px 6px"
        //   : isEnd
        //   ? "0px 6px 6px 0px"
        //   : "0px",
      }}
    >
      {icon}

      <Typography
        variant="body1"
        fontSize={{ xs: 11, md: 14 }}
        color={isActive ? blackShade59 : greyShade80}
        fontWeight={600}
        ml="10px"
      >
        {title}
      </Typography>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    // height: "100vh",
    // overflow: "hidden",
    // backgroundColor: BGColor,
  },

  content: {
    // width: { xs: "100%", md: "58%" },
    // p: { xs: "10px", md: "30px" },
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",

    maxHeight: "95vh",
    backgroundColor: whiteColor,
    borderRadius: "10px",
    padding: { xs: "20px", md: "25px" },

    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "4px",
      // height: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: blackShade159,
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px !important",
    },
  },
};
