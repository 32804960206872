import React, { useEffect, useRef, useState } from "react";

import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { Box, Button, useMediaQuery } from "@mui/material";
import { greyColor9, whiteColor } from "../../../configs/styles/muiThemes";
import CategoryFilterCard from "../Popups/CategoryFilterCard";
import { dynamicSort } from "../../../utils/helper";
import { useDispatch } from "react-redux";
import { getProductCategories } from "../../../reducers/businessPreview/businessPreviewSlice";
import { getONDCCategoriesAPI } from "../../../service/api_calls/BusinessPreview/BusinessPreviewAPI";

const InventoryFilterCard = ({
  applyONDCFilter,
  applyWebFilter,
  setIsClearFilter,
  setFilterType,

  ondcDropdownData,
  selectedOndcCategory,
  setSelectedOndcCategory,
  selectedOndcSubCat,
  setSelectedOndcSubCat,
data,
  refreshProductList,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [showONDCFilter, setShowONDCFilter] = useState(false);
  const [showWebstoreFilter, setShowWebstoreFilter] = useState(false);

  const ondcFilterRef = useRef(null);
  const webstoreFilterRef = useRef(null);

  const [filterLoader, setFilterLoader] = useState(false);
  const [filterError, setFilterError] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);

  const [ondcCategoryList, setOndcCategoryList] = useState([]);
  const [ondcSubCatList, setOndcSubCatList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedSubCat, setSelectedSubCat] = useState("All");

  // Prepare list data for filter dropdown
  const formatCategory = (category, setValue, valueSelector) => {
    const sortedArray = category?.slice()?.sort(dynamicSort(valueSelector));
    const catList = sortedArray?.map((item) => {
      return { ...item, label: item?.[valueSelector] };
    });

    setValue([{ label: "All" }, ...catList]);
  };

  const handleOndcCategoryChange = (data) => {
    setSelectedOndcCategory(data);
    setSelectedOndcSubCat("All");

    if (data === "All") {
      setOndcSubCatList([]);
      return;
    }

    setFilterError("");

    const categoryData = ondcCategoryList?.filter((item) => {
      return item?.ondcValue === data;
    });

    formatCategory(
      categoryData?.[0]?.productSubCategory,
      setOndcSubCatList,
      "ondcSubValue"
    );
  };

  const handleOndcSubCategoryChange = (data) => {
    setSelectedOndcSubCat(data);
  };

  // Apply ONDC Filter
  const handleOndcFilter = async () => {
    setFilterError("");

    if (ondcCategoryList?.length === 1) {
      setFilterError("ONDC category not assigned for any of the products");
      return;
    }
    if (selectedOndcCategory === "All") {
      setFilterError("Select the Main Category");
      return;
    }

    setFilterLoader(true);

    const categoryData = ondcCategoryList?.find((item) => {
      return item?.ondcValue === selectedOndcCategory;
    });

    const subCategoryData = ondcSubCatList?.find((item) => {
      return item?.ondcSubValue === selectedOndcSubCat;
    });

    console.log("SELECTED___categoryData:", categoryData);
    console.log("SELECTED___subCategoryData:", subCategoryData || null);

    applyONDCFilter(categoryData, subCategoryData);

    setFilterLoader(false);
    setShowONDCFilter(false);

    setIsClearFilter({
      show: true,
      category: `${selectedOndcCategory} - ${selectedOndcSubCat}`,
      message: `ONDC Category Filter results for “${selectedOndcCategory} - ${selectedOndcSubCat}”`,
    });
  };

  const onOndcFilterClose = () => {
    setFilterError("");
    setShowONDCFilter(false);
    setSelectedOndcCategory("All");
    setSelectedOndcSubCat("All");
    setOndcSubCatList([]);

    setIsClearFilter(null);
    setFilterType("");

    refreshProductList(true);
  };

  const clearAllOndcFilterData = () => {
    setSelectedOndcCategory("All");
    setSelectedOndcSubCat("All");
    setOndcSubCatList([]);
  };

  const prepareCategoryListData = async (ondcCategoryInfo) => {
    const ondcFilteredCategory = ondcCategoryInfo?.filter(
      (item) => item?.ondcCode
    );

    formatCategory(ondcFilteredCategory, setOndcCategoryList, "ondcValue");
    console.log("ondcCategory", ondcCategoryInfo, ondcFilteredCategory);
  };

  useEffect(() => {
    if (ondcDropdownData?.length > 0) {
      prepareCategoryListData(ondcDropdownData);
    }
    return () => {};
  }, [ondcDropdownData]);

  return (
    <Box
      display={"flex"}
      gap={1}
      position={{ xs: "relative", md: "static" }}
      mr={1.5}
    >
      <Box position={{ md: "relative", xs: "static" }} ref={ondcFilterRef}>
        <Button
          sx={styles.filterIconBtn}
          variant="contained"
          disableElevation
          onClick={() => {
            // clearSearch();
            setShowWebstoreFilter(false);
            setShowONDCFilter((prev) => !prev);
          }}
          endIcon={<FilterListRoundedIcon sx={{ fontSize: "17px" }} />}
        >
          {matches ? "ONDC Category Filters" : "ONDC Category"}
        </Button>

        {showONDCFilter && (
          <CategoryFilterCard
            title="ONDC Category Filters"
            categoryList={ondcCategoryList}
            subCategoryList={ondcSubCatList}
            selectedCategory={selectedOndcCategory}
            selectedSubCategory={selectedOndcSubCat}
            handleCategoryChange={handleOndcCategoryChange}
            handleSubCategoryChange={handleOndcSubCategoryChange}
            onApply={handleOndcFilter}
            onCancel={onOndcFilterClose}
            onAllClear={clearAllOndcFilterData}
            loader={filterLoader}
            error={filterError}
          />
        )}
      </Box>

      {/* <Box position={{ md: "relative", xs: "static" }} ref={webstoreFilterRef}>
        <Button
          sx={styles.filterIconBtn}
          variant="contained"
          disableElevation
          onClick={() => {
            clearSearch();
            setShowONDCFilter(false);
            setShowWebstoreFilter((prev) => !prev);
          }}
          endIcon={<FilterListRoundedIcon sx={{ fontSize: "17px" }} />}
        >
          {matches ? "Webstore Category Filters" : "Webstore Category"}
        </Button>
        {showWebstoreFilter && (
          <FilterCard
            title="Webstore Category Filters"
            categoryList={categoryList}
            subCategoryList={subCatList}
            selectedCategory={selectedCategory}
            selectedSubCategory={selectedSubCat}
            handleCategoryChange={handleWebstoreCategoryChange}
            handleSubCategoryChange={handleWebstoreSubCategoryChange}
            onApply={handleWebstoreFilter}
            onCancel={onWebstoreFilterClose}
            onAllClear={clearAllWebstoreFilterData}
            error={filterError}
          />
        )}
      </Box> */}
    </Box>
  );
};

export default InventoryFilterCard;

const styles = {
  filterIconBtn: {
    padding: "4px 12px",
    fontSize: "11px",
    fontWeight: 600,
    color: greyColor9,
    backgroundColor: whiteColor,
    border: "1px solid " + greyColor9,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor9,
    },
  },
};
