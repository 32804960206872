import { TextareaAutosize, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import {
  blackShade05,
  borderTextbox,
  descriptionColor,
  greyColor6,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { allowOnlyEnglish } from "../../utils/helper";

function TextAreaComponent({
  label,
  placeholder,
  value,
  onChange,
  // isError,
  error,
  helperText,
  hideLabel,
  required,
  minRows
}) {
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = allowOnlyEnglish(inputValue)

    if(onChange){
      onChange({ target: { value: sanitizedValue } });
    }
  };

  return (
    <Box mb="4px !important">
      {!hideLabel ? (
        <>
          <Typography
            variant="h3"
            fontSize={{xs:12, md: 13}}
            color={greyColor6}
            fontWeight={400}
            mb={"8px !important"}
            sx={{ display: "inline" }}
          >
            {label}{" "}
          </Typography>
          {required ? (
            <Typography
              variant="body1"
              sx={{ display: "inline", color: "#f00" }}
            >
              *
            </Typography>
          ) : null}
        </>
      ) : null}
      <TextareaAutosize
        minRows={minRows || 1}
        aria-label="maximum height"
        style={{
          fontSize: 13.5,
          fontWeight: 500,
          color: blackShade05,
          display: "block",
          width: "100%",
          borderTop: "none",
          borderBottom: borderTextbox,
          borderLeft: "none",
          borderRight: "none",
          borderRadius: "0px",
          backgroundColor: whiteColor,
          padding: "10px 0",
        }}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        maxLength={5000}
      />
      {/* <TextField
        variant="standard"
        required={true}
        fullWidth
        name={name}
        disabled={disabled}
        value={value}
        onBlur={onBlur}
        autoFocus={autoFocus}
        onChange={onChange}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        inputRef={inputRef}
        sx={{
          fontSize: 14.5,
          borderTop: isDashboardInput ? borderTextbox : "none",
          borderBottom: borderTextbox,
          borderLeft: isDashboardInput ? borderTextbox : "none",
          borderRight: isDashboardInput ? borderTextbox : "none",
          borderRadius: isDashboardInput ? "6px" : "0px",
          backgroundColor: whiteColor,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ cursor: "pointer" }}>
              {icon}
            </InputAdornment>
          ),
          style: {
            padding: isDashboardInput ? "7px 12px" : "4px 8px",
            color: grey,
            fontSize: 14.5,
            fontWeight: 500,
          },
          disableUnderline: true,
        }}
        inputProps={{ maxLength: maxLength }}
        type={inputType}
      /> */}

      {error ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={12}
          sx={{ mt: "8px !important", textAlign: "start !important" }}
          style={{ color: "red" }}
        >
          {error}
        </Typography>
      ) : null}

      {helperText ? (
        <Typography
          variant="body1"
          component="p"
          fontSize={11}
          color={descriptionColor}
        >
          {helperText}
        </Typography>
      ) : null}
    </Box>
  );
}

export default TextAreaComponent;
