import React, { useEffect, useState } from "react";
import {
  blackColor,
  blackShade21,
  blueColor04,
  greenColor02,
  greyColor14,
  logoImgborder1,
  redColor,
  whiteColor,
  yellowColor,
} from "../../configs/styles/muiThemes";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import UploadStepper from "./UploadStepper";
import DownloadTemplate from "./DownloadTemplate";
import UploadTemplate from "./UploadTemplate";
import { uploadBulkProductApi } from "../../service/api_calls/CatalogBulkUpload/CatalogBulkUpload";
import { BULK_UPLOAD_EXCEL_TEMPLATES } from "../../utils/data";
import AssignCategory from "./AssignCategory";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSelectedProducts,
  getAllBulkProductData,
  updateBulkOndcProductDetails,
  updateBulkProductDetails,
  validateCategories,
} from "../../reducers/catalogBulkUpload/catalogBulkUploadSlice";
import ReviewAndPublish from "./ReviewAndPublish";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UploadSuccessful from "./UploadSuccessful";
import OndcParameters from "./OndcParameters";
import DeleteAlert from "./Components/Popups/DeleteAlert";
import ModalCard from "../../components/ModalCard";
import { useNavigate } from "react-router-dom";

const CatalogBulkUploadContent = ({ activeBusinessInfo, isDisabled }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState("loading");
  const [openCategoryModal, setOpenCategoryModal] = useState(false);

  const [isError, setIsError] = useState(false);

  const [templateInfo, setTemplateInfo] = useState({
    selectedTemplate: BULK_UPLOAD_EXCEL_TEMPLATES[0],
  });

  const [fileDetails, setFileDetails] = useState({
    selectedFile: null,
    errors: null,
  });

  const [showDeleteAlert, setShowDeleteAlert] = useState({
    show: false,
    data: null,
  });
  const [categoryError, setCategoryError] = useState("");

  const [showSnackBar, setShowSnackBar] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const {
    bulkProductDetails,
    bulkProductDetailsLoader,
    isAssignCatError,
    isReviewError,
    productDeleted,
    deleteProductLoader,
    selectedRowCount,
    updateLoader,
  } = useSelector((state) => state.catalogBulkUpload);

  const { countryList } = useSelector((state) => state.catalog);

  const handleNext = () => {
    if (isLoading || updateLoader) return;
    setIsError(false);

    if (currentPage === "download") {
      setCurrentPage("upload");
      return;
    }

    if (currentPage === "upload") {
      uploadSelectedFile();
      return;
    }

    if (currentPage === "assign-category") {
      validateAssignedCategories();
      return;
    }

    if (currentPage === "ondc") {
      // setCurrentPage("review");
      // dispatch(validateOndcParameters({ productDetails: bulkProductDetails }));
      const businessId = sessionStorage.getItem("selectedBusinessId");
      dispatch(
        updateBulkOndcProductDetails({
          productDetails: bulkProductDetails,
          businessId,
          countryList,
          isRemoveONDCSpecs: true,
        })
      ).then((res) => {
        console.log("updateBulkOndcProductDetails_res:", res);
        if (res?.payload?.isError) {
          setIsError(true);
          return;
        }
        setCurrentPage("ondc");
      });

      return;
    }

    // if (currentPage === "review" && !isReviewError) {
    //   console.log("REVIEW Publish", bulkProductDetails);
    //   const businessId = sessionStorage.getItem("selectedBusinessId");
    //   dispatch(
    //     updateBulkProductDetails({
    //       productDetails: bulkProductDetails,
    //       businessId,
    //     })
    //   ).then((res) => {
    //     setCurrentPage("ondc");
    //   });
    //   return;
    // }

    if (currentPage === "review") {
      console.log("REVIEW VERIFY", bulkProductDetails);
      // dispatch(validateReviewProducts({ productDetails: bulkProductDetails }));
      const businessId = sessionStorage.getItem("selectedBusinessId");
      dispatch(
        updateBulkProductDetails({
          productDetails: bulkProductDetails,
          businessId,
        })
      ).then((res) => {
        console.log("updateBulkProductDetails_res:", res);
        if (res?.payload?.isError) {
          setIsError(true);
          return;
        }
        setCurrentPage("ondc");
      });
      return;
    }
  };

  const goBack = () => {
    switch (currentPage) {
      case "review":
        setCurrentPage("assign-category");
        break;

      case "ondc":
        setCurrentPage("review");
        break;

      case "assign-category":
        setCurrentPage("assign-category");
        break;

      case "upload":
        setCurrentPage("download");
        break;

      case "download":
        setCurrentPage("download");
        break;

      default:
        break;
    }
  };

  const getNextButtonText = () => {
    switch (currentPage) {
      case "download":
        return "NEXT";

      case "upload":
        return "UPLOAD";

      case "assign-category":
        // return isAssignCatError ? `VERIFY & CONTINUE(${selectedRowCount} ROWS)` : "NEXT";
        return `VERIFY & CONTINUE(${selectedRowCount} ROWS)`;

      case "review":
        return `VERIFY & CONTINUE(${selectedRowCount} ROWS)`;

      case "ondc":
        return `VERIFY & IMPORT(${selectedRowCount} ROWS)`;

      default:
        return "NEXT";
    }
  };

  // Upload Excel File
  const uploadSelectedFile = async () => {
    if (!fileDetails?.selectedFile) {
      setFileDetails((prevState) => ({
        ...prevState,
        errors: { selectedFile: "Please select a valid Excel sheet file." },
      }));
      return;
    }

    setIsLoading(true);

    const businessId = sessionStorage.getItem("selectedBusinessId");

    const response = await uploadBulkProductApi({
      selectedFile: fileDetails?.selectedFile,
      businessId,
    });

    setIsLoading(false);
    setCurrentPage("assign-category");

    // if (response) {
    //   setIsLoading(false);
    //   setCurrentPage("assign-category");
    // } else {
    //   setIsLoading(false);
    // }
  };

  const validateAssignedCategories = () => {
    dispatch(validateCategories({ productDetails: bulkProductDetails }));
  };

  const handleCloseSnackbar = () => {
    setShowSnackBar(false);
  };

  // Hanlde Multi Delete
  const handleDeleteProducts = async () => {
    if (deleteProductLoader) return;

    setShowDeleteAlert({ show: false, data: null });

    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      dispatch(
        deleteSelectedProducts({
          productDetails: bulkProductDetails,
          businessId,
        })
      ).then((res) => {
        if (selectedRowCount === bulkProductDetails?.length) navigate(0);
      });
    }
  };
  useEffect(() => {
    if (!isAssignCatError) {
      console.log("NO ERROR in CAT ASSIGN");
      setCurrentPage("review");
      // setCurrentPage("ondc");
    }

    return () => {};
  }, [isAssignCatError]);

  useEffect(() => {
    if (productDeleted) {
      setShowSnackBar(true);
    }

    return () => {};
  }, [productDeleted]);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      dispatch(getAllBulkProductData({ businessId })).then((res) => {
        console.log("getAllBulkProductData_CONTENT:", res?.payload?.length);
        if (res?.payload?.length > 0) {
          setCurrentPage("assign-category");
          // setCurrentPage("published");
        } else {
          setCurrentPage("download");
        }
      });
    }

    return () => {};
  }, []);

  return (
    <Box sx={styles.card}>
      <UploadStepper currentPage={currentPage} />

      {currentPage === "loading" ? (
        <ThreeDotLoader />
      ) : (
        <Box mt={3}>
          {currentPage === "download" && (
            <DownloadTemplate
              templateInfo={templateInfo}
              setTemplateInfo={setTemplateInfo}
            />
          )}
          {currentPage === "upload" && (
            <UploadTemplate
              fileDetails={fileDetails}
              setFileDetails={setFileDetails}
            />
          )}
          {currentPage === "assign-category" && (
            <AssignCategory
              productDetails={bulkProductDetails}
              isLoading={bulkProductDetailsLoader}
              businessInfo={activeBusinessInfo}
              openCategoryModal={openCategoryModal}
              setOpenCategoryModal={setOpenCategoryModal}
              setCurrentPage={setCurrentPage}
            />
          )}

          {currentPage === "ondc" && (
            <OndcParameters
              productDetails={bulkProductDetails}
              isLoading={bulkProductDetailsLoader}
              setCurrentPage={setCurrentPage}
            />
          )}

          {currentPage === "review" && (
            <ReviewAndPublish
              productDetails={bulkProductDetails}
              isLoading={bulkProductDetailsLoader}
              setCurrentPage={setCurrentPage}
            />
          )}

          {currentPage === "published" && (
            <UploadSuccessful
              productDetails={bulkProductDetails}
              isLoading={bulkProductDetailsLoader}
              setCurrentPage={setCurrentPage}
            />
          )}

          {!bulkProductDetailsLoader && currentPage !== "published" && (
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="center"
              gap={2}
              mt={5}
            >
              {currentPage !== "download" &&
                currentPage !== "assign-category" && (
                  <Button
                    variant="contained"
                    sx={styles.backBtn}
                    disableElevation
                    onClick={goBack}
                  >
                    BACK
                  </Button>
                )}

              {currentPage === "assign-category" &&
                bulkProductDetails?.length > 0 && (
                  <Box>
                    <Button
                      variant="contained"
                      sx={{ ...styles.backBtn, ...styles.assignBtn }}
                      disableElevation
                      onClick={() => {
                        if (selectedRowCount > 0) {
                          setCategoryError("");
                          setOpenCategoryModal(true);
                        } else {
                          setCategoryError(
                            "Select atleast 1 to assign category"
                          );
                        }
                      }}
                      disabled={bulkProductDetails?.length === 0 || isDisabled}
                    >
                      ASSIGN CATEGORY
                    </Button>

                    {categoryError && (
                      <Typography
                        fontSize={10.5}
                        fontWeight={500}
                        color={redColor}
                        mt={1}
                      >
                        {categoryError}
                      </Typography>
                    )}
                  </Box>
                )}

              {currentPage !== "download" &&
                currentPage !== "upload" &&
                bulkProductDetails?.length > 0 && (
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{ ...styles.backBtn, ...styles.deleteBtn }}
                    onClick={() => {
                      setShowDeleteAlert({
                        show: true,
                        data: {
                          message: `Are you sure you want to delete the selected ${
                            selectedRowCount === 1
                              ? `${selectedRowCount} Row?`
                              : `${selectedRowCount} Rows?`
                          }`,
                        },
                      });
                    }}
                    startIcon={
                      deleteProductLoader ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : null
                    }
                    disabled={selectedRowCount === 0 || isDisabled}
                  >
                    DELETE{" "}
                    {selectedRowCount === 1
                      ? `${selectedRowCount} Row`
                      : `${selectedRowCount} Rows`}
                  </Button>
                )}

              {currentPage !== "download" &&
              currentPage !== "upload" &&
              bulkProductDetails?.length === 0 ? (
                <></>
              ) : (
                <Stack>
                  <Button
                    variant="contained"
                    sx={styles.nextBtn(isLoading)}
                    disableElevation
                    onClick={handleNext}
                    disabled={
                      isDisabled ||
                      isLoading ||
                      (selectedRowCount === 0 &&
                        (currentPage === "review" ||
                          currentPage === "ondc" ||
                          currentPage === "assign-category"))
                      // (selectedRowCount === 0 && currentPage === "review")
                    }
                    startIcon={
                      isLoading ||
                      (updateLoader && (
                        <CircularProgress
                          sx={{ color: greyColor14, opacity: 0.5 }}
                          size={16}
                        />
                      ))
                    }
                  >
                    {getNextButtonText()}
                  </Button>

                  {/* {isReviewError && currentPage === "review" && (
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color={greenColor02}
                      mt={1}
                    >
                      Errors Found {bulkProductDetails?.}
                    </Typography>
                  )} */}

                  {isError && (
                    <Typography
                      fontSize={10.5}
                      fontWeight={500}
                      color={redColor}
                      mt={1}
                    >
                      Please fix the errors in the list to proceed
                    </Typography>
                  )}
                </Stack>
              )}
            </Box>
          )}

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={showSnackBar}
            autoHideDuration={3000}
            onClose={() => {
              handleCloseSnackbar();
            }}
            sx={{ mt: 4 }}
          >
            <Alert
              onClose={() => {
                handleCloseSnackbar();
              }}
              severity="success"
              icon={<DeleteOutlineIcon fontSize="inherit" />}
              variant="filled"
              sx={{ width: "100%" }}
            >
              Deleted {productDeleted?.count} Rows
            </Alert>
          </Snackbar>

          <ModalCard
            open={showDeleteAlert?.show}
            handleClose={() => {
              setShowDeleteAlert({ show: false, data: null });
            }}
            width="33%"
          >
            <DeleteAlert
              alertMsg={showDeleteAlert?.data?.message}
              onCancel={() => {
                setShowDeleteAlert({ show: false, data: null });
              }}
              onDelete={() => {
                handleDeleteProducts();
              }}
            />
          </ModalCard>
        </Box>
      )}
    </Box>
  );
};

export default CatalogBulkUploadContent;

const styles = {
  card: {
    backgroundColor: whiteColor,
    border: logoImgborder1,
    borderRadius: { xs: "8px", md: "10px" },
    p: { xs: "12px", md: "15px" },
    boxShadow: "0 0px 4px 0 rgb(0 0 0 / 5%)",
    m: { xs: "15px 0 25px", md: "0px 0 25px" },
  },

  backBtn: {
    fontSize: 12,
    fontWeight: 600,
    color: blackShade21,
    backgroundColor: whiteColor,
    border: `1px solid ${blackShade21}`,
    padding: "7px 30px",
    borderRadius: "5px",
    "&:hover": {
      color: whiteColor,
      backgroundColor: blackShade21,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },

  assignBtn: {
    color: blueColor04,
    border: `1px solid ${blueColor04}`,
    backgroundColor: whiteColor,
    "&:hover": {
      color: whiteColor,
      backgroundColor: blueColor04,
    },
  },

  deleteBtn: {
    color: redColor,
    border: `1px solid ${redColor}`,
    backgroundColor: whiteColor,
    "&:hover": {
      color: whiteColor,
      backgroundColor: redColor,
    },
  },

  nextBtn: (isDisabled) => ({
    fontSize: 13,
    fontWeight: 500,
    color: blackColor,
    backgroundColor: yellowColor,
    border: isDisabled ? "none" : `1px solid ${yellowColor}`,
    padding: "7px 30px",
    borderRadius: "7px",
    "&:hover": {
      color: yellowColor,
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  }),
};
