import moment from "moment";
import { getProfileId } from "../../../utils/validationUtils";
import { getApiInstance } from "../../AppService";
import {
  ADD_PREFERRED_DISCOUNT_REWARD,
  CURRENT_DATE,
  DELETE_PREFERRED_DISCOUNT,
  FROM_DATE,
  GET_ALL_PREFERRED_DISCOUNTS,
  GET_ALL_PROFILE_IDS,
  GET_EMAIL_OR_PHONE_NUMBER,
  SEARCH_PREFERRED_DISCOUNT,
} from "../../constant";

// Get Business preferredDiscount
export const getAllBusinessPreferredDiscountApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(GET_ALL_PREFERRED_DISCOUNTS, {
      params: {
        status: data.status,
        pageNo: data.pageNo,
        from: FROM_DATE,
        to:CURRENT_DATE,
      },
    });
    console.log("getAllBusinessPreferredDiscountApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Geting profile info using original profile id
export const getAllBusinessProfileIDApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        profileId: getProfileId(),
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(
      GET_ALL_PROFILE_IDS + data?.profileParams
    );
    console.log("getAllBusinessProfileIDApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Geting profile info using original profile id
export const searchPreferredDiscountByNumberApi = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        businessId: data?.businessId,
      },
    });
    const response = await instance.get(SEARCH_PREFERRED_DISCOUNT, {
      params: {
        pageNo: 1,
        status: data?.status || `active,claimed`,
        phone: data?.phone,
      },
    });
    const profileId = response.data.details?.map(
      (item, index) => item?.rewardsProfileId
    );
    // console.log(profileId);

    const uniqueprofileIds = [];

    for (const item of profileId) {
      if (!uniqueprofileIds.includes(item)) {
        uniqueprofileIds.push(item);
      }
    }
    console.log("uniqueprofileIds:", uniqueprofileIds);

    let profileParams = "?";
    uniqueprofileIds.forEach((element, index) => {
      profileParams =
        profileParams +
        (index === uniqueprofileIds.length - 1
          ? `params=${element}`
          : `params=${element}&`);
    });
    console.log("profileParams:", profileParams);

    const profileInfom = await getAllBusinessProfileIDApi({
      ...data,
      profileParams,
    });
    console.log("ProfileInfo:", profileInfom);

    const details=response.data.details
    const profileInfo = profileInfom.accountList;
    console.log("searchPreferredDiscountByNumberApi", details, profileInfo);
    return { details, profileInfo };
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const preferredDiscountDeleteDataApi = async (data) => {
  try {
    const instance = getApiInstance({
      // header: {
      //   businessId: data?.businessId,
      // },
    });
    const response = await instance.put(DELETE_PREFERRED_DISCOUNT, data);
    console.log("PreferredDiscountDeleteDataApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const preferredDiscountGetEmailOrPhoneNumberApi = async (data) => {
  try {
    const instance = getApiInstance();
    const phoneNumber = `+91${data?.phoneNumber}`;

    const response = await instance.get(
      GET_EMAIL_OR_PHONE_NUMBER + `?phone_email=${phoneNumber}`
    );

    console.log("PreferredDiscountEmailOrPhoneNodApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const preferredDiscountAddRewardDataApi = async (postData) => {
  const { data, businessId } = postData;
  try {
    const instance = getApiInstance({
      header: {
        businessID: businessId,
      },
    });
    const response = await instance.post(ADD_PREFERRED_DISCOUNT_REWARD, data);
    console.log("PreferredDiscountAddRewardApi", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const prepareAddRewardPostData = (rewardData) => {
  const { newCoupon, originalProfileId, businessId } = rewardData;
  const profileId = getProfileId();

  const postData = {
    businessCountryCode: "+91",
    businessId: businessId,
    costBoDue: "0",
    currencySymbol: "₹",
    customerInstalled: originalProfileId ? true : false,
    latitude: localStorage.getItem("lat"),
    longitude: localStorage.getItem("long"),

    minimumAmount: newCoupon?.minimumAmount,
    pointStartDate: moment(newCoupon?.startDate).startOf("day").valueOf(),
    pointEndDate: moment(newCoupon?.endDate).endOf("day").valueOf(),
    pointPercentage:
      newCoupon?.type === "Percentage" ? newCoupon?.pointPercentage : 0,
    pointValue: newCoupon?.type === "Amount" ? newCoupon?.pointValue : 0,

    originalProfileId: originalProfileId || "",
    originalPhoneNo: "+91" + newCoupon?.originalPhoneNo,
    overrideCount: "0",
    pointOption: "0",
    pointOwner: "original",
    primary: "true",
    profileId: profileId,
    rejectCount: "0",
    rewardReferralAmount: "0",
    rewardSearchId: "server assigns",
    rewardStatus: "active",
    rewardTitle: "Instant discount for your next order",
    rewardTransferable: "false",
    rewardType: "instantbusinessdiscount",
    rewardsPhoneNo: "+91" + newCoupon?.originalPhoneNo,
    rewardsProfileId: originalProfileId || "",
    seenByCustomer: "false",
    submitCount: "0",
    text: "Share the discount and earn more points to your wallets",
    totalPointOption: "0",
    transactionAmount: "0",
    updateCount: "0",
  };

  return postData;
};
