import React from "react";
import {
  blackColor,
  blueColor04,
  blueColor1,
  borderTextbox,
  greyColor36,
  greyColor40,
  greyShade06,
  mobilePrimary,
  primaryColor2,
} from "../../configs/styles/muiThemes";
import { Box, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { scrollBox } from "../../utils/styles";

function DashboardStepper({
  stepList,
  currentPage,
  isAddProduct,
  hideAvailability,
  handleTabChange,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    handleTabChange(newValue);
  };

  const getTabValue = (currentPage) => {
    const tabValue = {};

    stepList.forEach((element, index) => {
      tabValue[element.value] = index;
    });

    return tabValue[currentPage];
  };

  return (
    <Box
      sx={{
        ...scrollBox,
        display: { xs: "block", md: "block" },
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Tabs
        value={getTabValue(currentPage)}
        onChange={(event, newValue) => handleChange(event, newValue)}
        aria-label="basic tabs example"
        textColor={blackColor}
        TabIndicatorProps={{
          style: {
            height: "3px",
            backgroundColor: matches ? blueColor04 : mobilePrimary,
          },
        }}
      >
        {stepList?.map((item, index) => {
          return (
            <Tab
              key={"step_btn_" + index}
              label={item?.label}
              sx={{
                fontSize: { md: "13px", xs: "13px" },
                textTransform: "unset",
                p: 0,
                minHeight: "50px !important",
                fontWeight: 500,
                mr: "24px",
                color:
                  item?.value === currentPage
                    ? matches
                      ? blueColor04
                      : mobilePrimary
                    : greyColor40,
              }}
              icon={item?.icon}
              iconPosition="start"
            />
          );
        })}
      </Tabs>
    </Box>
  );
}

export default DashboardStepper;

const styles = {
  container: {
    // ...scrollBox,
    display: "flex",
    borderBottom: borderTextbox,
    borderWidth: "2px",
    whiteSpace: "nowrap",
    m: "30px 0 15px 0",
  },

  btn: (isActive) => ({
    p: "0 6px 6px 0px",
    mr: "15px",
    color: isActive ? primaryColor2 : greyShade06,
    borderBottom: isActive ? "3px solid " + primaryColor2 : "none",
  }),

  icon: {
    fontSize: "20px",
  },
};
