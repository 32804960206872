import React, { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Box, IconButton } from "@mui/material";
import { redColor, whiteColor } from "../../configs/styles/muiThemes";

const ErrorToolTipIcon = ({ errorMsg, errorPositionRight }) => {
  const [showMessage, setShowMessage] = useState(false);
  return (
    <Box
      onMouseEnter={() => {
        setShowMessage(true);
      }}
      onMouseLeave={() => {
        setShowMessage(false);
      }}
      position='relative'
    >
      <Box display="flex" alignItems="center">
        {showMessage && !errorPositionRight && <Box sx={styles.errorMsgText}>{errorMsg}</Box>}
        <IconButton>
          <InfoIcon sx={{ fontSize: "18px", color: redColor }} />
        </IconButton>
        {showMessage && errorPositionRight && <Box sx={{...styles.errorMsgText, position:'absolute', left:30}}>{errorMsg}</Box>}
      </Box>
    </Box>
  );
};

export default ErrorToolTipIcon;

const styles = {
  errorMsgText: {
    fontSize: 11,
    fontWeight: 500,
    color: whiteColor,
    backgroundColor: redColor,
    p: "2px 8px",
    zIndex: "999",
  },
};
