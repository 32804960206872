import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPendingOrderInfo,
  getPendingOrderData,
  handleRefreshTabData,
  refreshPendingOrderData,
} from "../../../reducers/orders/ordersSlice";
import ListEmptyCard from "../../../components/ListEmptyCard";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import ThreeDotLoader from "../../../components/ThreeDotLoader";
import moment from "moment";
import { getOrderDeliveryTypeText, getOrderType } from "../../../utils/helper";
import InfiniteScroll from "react-infinite-scroll-component";
import OrderPendingCard from "../Cards/OrderPendingCard";
import SearchOrdersById from "../SearchOrdersById";
import HorizontalStepper from "../../../components/HorizontalStepper";
import OrderApprove from "../OrderApprove";
import OrderCancelPopup from "../CancelOrderPopup/OrderCancelPopup";
import {
  BGColor02,
  blackShade23,
  copyBtnColor,
  greyColor42,
  greyColor8,
  inactiveTextColor,
  orangeColor02,
  orderCardBorder,
  orderCardHeadingBorder2,
  perpleColor1,
} from "../../../configs/styles/muiThemes";
import { stickyHeader } from "../../../utils/styles";
import ActionInfoHeader from "../Cards/ActionInfoHeader";

function PendingOrdersContent({
  handleViewOrder,
  handleTrackOrder,
  steps,
  tabValue,
  commerceInfo,
  getOndcBapNameBySubscriberId,
}) {
  const PAGE_SIZE = 30;

  const dispatch = useDispatch();
  const [hasMoreData, setHasMoreData] = useState(true);

  const [isSearchActive, setIsSearchActive] = useState(false);

  const [openApproveOrder, setOpenApproveOrder] = useState({
    show: false,
    data: null,
  });

  const [openCancelOrder, setOpenCancelOrder] = useState({
    show: false,
    data: null,
  });

  const { pendingOrders, pendingPageNo, pendingOrdersLoader } = useSelector(
    (state) => state.businessOrders
  );

  const getOrdersData = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");

    dispatch(
      getPendingOrderData({
        businessId,
        pageSize: PAGE_SIZE,
        pageNo: pendingPageNo,
      })
    );
  };

  // Open Approve Order Popup
  const handleApproveOrder = (data) => {
    setOpenApproveOrder({ show: true, data });
  };

  // Open Cancel Order Popup
  const handleCancelOrder = (data) => {
    console.log("CANCEL_ORDER__:", data);
    setOpenCancelOrder({ show: true, data });
    // dispatch(handleRefreshTabData());
  };

  const fetchNextData = () => {
    // console.log("Next Hit");
    getOrdersData();
  };

  // handle Searching
  const onSearchStart = () => {
    setIsSearchActive(true);
    dispatch(clearPendingOrderInfo());
  };

  const onSearchClear = () => {
    setIsSearchActive(false);
    getOrdersData();
  };

  const handleApproveSuccess = () => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    setOpenApproveOrder({ show: false, data: null });
    dispatch(handleRefreshTabData());
    dispatch(
      refreshPendingOrderData({
        businessId,
        pageSize: PAGE_SIZE,
        pageNo: 1,
      })
    );
  };

  useEffect(() => {
    getOrdersData();
    return () => {
      dispatch(clearPendingOrderInfo());
    };
  }, []);

  useEffect(() => {
    if ((pendingPageNo - 1) * PAGE_SIZE > pendingOrders?.length) {
      setHasMoreData(false);
    }

    return () => {};
  }, [pendingPageNo]);

  return (
    <Box>
      <SearchOrdersById
        onSearchStart={onSearchStart}
        onSearchClear={onSearchClear}
        steps={steps}
        tabValue={tabValue}
        handleViewOrder={(data) => handleViewOrder(data)}
        handleTrackOrder={(data) => handleTrackOrder(data)}
        handleApproveOrder={(data) => handleApproveOrder(data)}
        handleCancelOrder={(data) => handleCancelOrder(data)}
        disableSearch={pendingOrders?.length == 0}
        getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
      />
      {!pendingOrdersLoader &&
      pendingPageNo === 1 &&
      pendingOrders?.length === 0 &&
      !isSearchActive ? (
        <ListEmptyCard
          icon={
            <ProductionQuantityLimitsOutlinedIcon sx={{ fontSize: "24px" }} />
          }
          text="No Pending Orders Found"
          hideBtn
        />
      ) : (
        pendingOrdersLoader && pendingPageNo === 1 && <ThreeDotLoader />
      )}
      {pendingOrders?.length > 0 && (
        <Box
          sx={{
            ...stickyHeader,
            pb: { md: 0, xs: 0 },
            pt: 0,
            top: 250,
            display: { md: "block", xs: "none" },
          }}
        >
          <Box sx={styles.card}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3.3}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  ORDER DETAIL
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  CUSTOMER INFO & PRICE & QTY
                </Typography>
              </Grid>

              <Grid item xs={6} md={2.1}>
                <Typography
                  variant="h5"
                  fontSize={13}
                  fontWeight={500}
                  color={blackShade23}
                >
                  DELIVERY TYPE & STATUS
                </Typography>
              </Grid>

              <Grid item xs={12} md={3.6}>
                <Stack flexDirection="row" alignItems="center" gap={0.5}>
                  <Typography
                    variant="h5"
                    fontSize={13}
                    fontWeight={500}
                    color={blackShade23}
                  >
                    ACTION
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      {pendingOrders?.length > 0 && (
        <>
          <InfiniteScroll
            dataLength={pendingOrders?.length || 0}
            next={fetchNextData}
            hasMore={hasMoreData}
            loader={
              <Box>
                <ThreeDotLoader />
              </Box>
            }
          >
            {pendingOrders?.map((item, index) => (
              <OrderPendingCard
                index={index}
                key={`order_${item?.orderId}`}
                id={item?.orderId}
                ondcOrderId={item?.ondcOrderId || ""}
                date={moment(item?.orderCreatedOn)?.format("DD MMM YYYY")}
                username={item?.customerAddressName}
                addressName={item?.customerAddressName || ""}
                city={item?.customerCity}
                addressCity={item?.customerAddressCity || ""}
                orderCost={item?.orderTotalCost}
                cartCount={item?.totalQuantity}
                orderType={getOrderType(item)}
                orderStatus="Pending"
                deliveryType={getOrderDeliveryTypeText(
                  item?.deliveryType?.type
                )}
                statusUpdatedDate={
                  item?.orderCreatedOn
                    ? moment(item?.orderCreatedOn).fromNow()
                    : ""
                }
                type={!item?.deliveryType ? null : item?.deliveryType?.type}
                handleViewOrder={() => handleViewOrder(item)}
                handleTrackOrder={() => handleTrackOrder(item)}
                handleApproveOrder={() => handleApproveOrder(item)}
                handleCancelOrder={() => handleCancelOrder(item)}
                getOndcBapNameBySubscriberId={() =>
                  getOndcBapNameBySubscriberId(item?.ondcBapId)
                }
              />
            ))}
          </InfiniteScroll>
        </>
      )}
      {openApproveOrder && (
        <OrderApprove
          open={openApproveOrder?.show}
          handleClose={() => {
            setOpenApproveOrder({ show: false, data: null });
          }}
          handleApproveSuccess={handleApproveSuccess}
          handleTrackOrder={(data) => handleTrackOrder(data)}
          data={openApproveOrder?.data || null}
          commerceInfo={commerceInfo}
          getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
        />
      )}
      {openCancelOrder?.show && (
        <OrderCancelPopup
          open={openCancelOrder?.show}
          handleClose={() => {
            setOpenCancelOrder({ show: false, data: null });
            handleApproveSuccess();
          }}
          handleCloseModal={() => {
            setOpenCancelOrder({ show: false, data: null });
          }}
          data={openCancelOrder?.data || null}
          commerceInfo={commerceInfo}
          getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
        />
      )}
    </Box>
  );
}

export default PendingOrdersContent;

const styles = {
  card: {
    // display: {md:"flex",xs:"none"},
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "16px", md: "20px" },
    backgroundColor: greyColor42,
    borderTop: orderCardBorder,
    borderRadius: "7px 7px 0px 0px",
    boxShadow: "0 2px 4px rgb(179 188 198 / 5%)",
    padding: "7px 15px",
  },
};
