import React, { useState } from "react";
import ModalCard from "../../components/ModalCard";
import ApprovePopupContent from "./Popups/ApprovePopupContent";
import { Box } from "@mui/material";
import OrderApprovedPopupContent from "./Popups/ApprovePopups/OrderApprovedPopupContent";
import OrderBusinessApprove from "./Popups/ApprovePopups/OrderBusinessApprove";
import ApproveBtoBPopup from "./Popups/ApprovePopups/ApproveBtoBPopup";

function OrderApprove({
  open,
  handleClose,
  data,
  commerceInfo,
  handleApproveSuccess,
  handleTrackOrder,
  getOndcBapNameBySubscriberId
}) {
  // const [currentPage, setCurrentPage] = useState("businessOrderApprovedBtoB");
  // const [currentPage, setCurrentPage] = useState("businessOrderApproved");
  // const [currentPage, setCurrentPage] = useState("OrderApproved");
  const [currentPage, setCurrentPage] = useState("Approve");

  const handleApproveOrder = (data) => {
    setCurrentPage(data);
  };

  return (
    <ModalCard
      open={open}
      handleClose={() => {
        setCurrentPage("Approve");
        if (currentPage === "Approve") {
          handleClose();
          return;
        }
        handleApproveSuccess();
      }}
      width="80%"
      // modalType="auth"
    >
      <Box overflow="hidden">
        {currentPage === "Approve" ? (
          <ApprovePopupContent
            orderData={data}
            handleClose={handleClose}
            handleApproveOrder={(data) => handleApproveOrder(data)}
            handleApproveSuccess={handleApproveSuccess}
            commerceInfo={commerceInfo}
            getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
          />
        ) : null}
        {currentPage === "OrderApproved" ? (
          <OrderApprovedPopupContent
            handleClose={() => {
              setCurrentPage("Approve");
              handleApproveSuccess();
            }}
            orderData={data}
            handleTrackOrder={() => handleTrackOrder(data)}
            commerceInfo={commerceInfo}
            getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
          />
        ) : null}

        {currentPage === "businessOrderApproved" ? (
          <OrderBusinessApprove
            handleApproveSuccess={() => {
              setCurrentPage("Approve");
              handleApproveSuccess();
            }}
            handleClose={() => {
              setCurrentPage("Approve");
              handleClose();
            }}
            orderData={data}
            hideTable
            getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
          />
        ) : null}

        {currentPage === "businessOrderApprovedBtoB" ? (
          <ApproveBtoBPopup
            orderData={data}
            handleClose={() => {
              setCurrentPage("Approve");
              handleClose();
            }}
            handleApproveSuccess={() => {
              setCurrentPage("Approve");
              handleApproveSuccess();
            }}
            handleApproveOrder={(data) => handleApproveOrder(data)}
            commerceInfo={commerceInfo}
            getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
          />
        ) : null}
      </Box>
    </ModalCard>
  );
}

export default OrderApprove;
