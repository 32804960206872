import {
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  blackColor,
  blackShade159,
  blackShade21,
  blueColor04,
  borderCart,
  greyColor21,
  greyColor8,
  greyShade09,
  greyShade25,
  iconContainerBgColor,
  mobilePrimary,
  oldPriceColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import SelectWithLabel from "../../components/Input/SelectWithLabel";
import styled from "@emotion/styled";
import { addThousandsSeparator } from "../../utils/helper";

function AllStockRowCard({
  title,
  originalAmt,
  amt,
  alertQty,
  avlQty,
  minQty,
  maxQty,
  last,
  startDate,
  endDate,
  isChecked,
  onValueChange,
  errors,
  gstRates,
  gst,
  availability,
  gstCode,
}) {
  return (
    <Grid
      container
      display={"flex"}
      alignItems="center"
      sx={{
        p: { xs: "16px 0px 16px 22px", md: 2 },
        borderBottom: last ? "none" : "1px solid #eaeaea",
        background: whiteColor,
      }}
      spacing={{ md: 1, xs: 0 }}
    >
      <Grid item md={2.4} xs={8.5}>
        <Box display={"flex"} alignItems="center">
          <Checkbox
            sx={{ ml: -1 }}
            style={{ color: greyColor21 }}
            checked={isChecked}
            size={"large"}
            onChange={() => {
              onValueChange({ type: "itemChange", value: !isChecked });
            }}
          />
          <Box ml={{ md: 2, xs: 1 }}>
            <Typography
              fontSize={13}
              color={blackColor}
              fontWeight={500}
              lineHeight={1.5}
              mb="6px !important"
            >
              {title}
            </Typography>
            {originalAmt != amt ? (
              <Typography
                component={"del"}
                fontSize={13}
                color={blackShade21}
                fontWeight={{ xs: 500, md: 400 }}
                display={"inline"}
                lineHeight={1.5}
              >
                ₹ {addThousandsSeparator(originalAmt)}
              </Typography>
            ) : null}
            <Typography
              compenent="span"
              fontSize={13}
              color={{ xs: mobilePrimary, md: blueColor04 }}
              fontWeight={{ xs: 600, md: 500 }}
              lineHeight={1.5}
              display={"inline"}
              ml={1}
            >
              ₹ {addThousandsSeparator(amt)}
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item md={1.19} xs={3.5}>
        <Typography
          variant="h3"
          fontSize={11}
          color={greyShade25}
          fontWeight={400}
          textTransform="uppercase"
          sx={{
            display: { md: "none", xs: "block" },
            mt: { md: 3, xs: 1 },
          }}
        >
          Availablility
        </Typography>
        <StyledSwitch
          sx={{ ml: 1, color: "red" }}
          checked={availability}
          onChange={() => {
            if (availability) {
              onValueChange({ type: "availability", value: ["No"] });
            } else {
              onValueChange({ type: "availability", value: ["Yes"] });
            }
          }}
        />
      </Grid>

      <Grid item md={1.23} xs={6}>
        <Typography
          variant="h3"
          fontSize={{ md: 13, xs: 11 }}
          color={greyShade25}
          fontWeight={400}
          textTransform="uppercase"
          sx={{ display: { md: "none", xs: "block" }, mt: 3 }}
        >
          Available Qty
        </Typography>
        <TextField
          variant="standard"
          // value={`${addThousandsSeparator(avlQty, true)}`}
          value={avlQty}
          sx={styles.input}
          disabled={!availability}
          onChange={({ target }) => {
            const value = target.value;
            const regex = /^(\d{0,7})$/;
            if (regex.test(value) && target.value.charAt(0) !== "0") {
              onValueChange({
                type: "availableQuantity",
                value: target.value,
              });
            }
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              color: blackColor,
              fontSize: { md: 14, xs: 12 },
              fontWeight: 500,
            },
          }}
        />
      </Grid>

      <Grid item md={1.23} xs={6}>
        <Typography
          variant="h3"
          fontSize={{ md: 13, xs: 11 }}
          color={greyShade25}
          fontWeight={400}
          textTransform="uppercase"
          sx={{ display: { md: "none", xs: "block" }, mt: 3 }}
        >
          Alert Qty
        </Typography>
        <TextField
          variant="standard"
          // value={addThousandsSeparator(alertQty, true)}
          value={alertQty}
          sx={styles.input}
          disabled={!availability}
          onChange={({ target }) => {
            const value = target.value;
            const regex = /^(\d{0,7})$/;
            if (regex.test(value) && target.value.charAt(0) !== "0") {
              onValueChange({
                type: "alertQuantity",
                value: target.value,
              });
            }
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              color: blackColor,
              fontSize: { md: 14, xs: 12 },
              fontWeight: 500,
            },
          }}
        />
      </Grid>

      <Grid item md={1.23} xs={6}>
        <Typography
          variant="h3"
          fontSize={{ md: 13, xs: 11 }}
          color={greyShade25}
          fontWeight={400}
          textTransform="uppercase"
          sx={{ display: { md: "none", xs: "block" }, mt: 3 }}
        >
          Min Qty Per Order
        </Typography>
        <TextField
          variant="standard"
          // value={addThousandsSeparator(minQty, true)}
          value={minQty}
          sx={styles.input}
          disabled={!availability}
          onChange={({ target }) => {
            const value = target.value;
            const regex = /^(\d{0,7})$/;
            if (regex.test(value) && target.value.charAt(0) !== "0") {
              onValueChange({ type: "minOrderQuantity", value });
            }
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              color: blackColor,
              fontSize: { md: 14, xs: 12 },
              fontWeight: 500,
            },
          }}
        />
      </Grid>

      <Grid item md={1.23} xs={6}>
        <Typography
          variant="h3"
          fontSize={{ md: 13, xs: 11 }}
          color={greyShade25}
          fontWeight={400}
          textTransform="uppercase"
          sx={{ display: { md: "none", xs: "block" }, mt: 3 }}
        >
          Max Qty Per Order
        </Typography>
        <TextField
          variant="standard"
          value={maxQty}
          sx={styles.input}
          disabled={!availability}
          onChange={({ target }) => {
            const value = target.value;
            const regex = /^(\d{0,7})$/;
            if (
              regex.test(value) &&
              target.value.charAt(0) !== "0" &&
              !isNaN(target.value)
            ) {
              onValueChange({
                type: "maxOrderQuantity",
                value: target.value,
              });
            }
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              color: blackColor,
              fontSize: { md: 14, xs: 12 },
              fontWeight: 500,
            },
          }}
        />
      </Grid>

      <Grid item md={1.23} xs={6}>
        <Typography
          variant="h3"
          fontSize={{ md: 13, xs: 11 }}
          color={greyShade25}
          fontWeight={400}
          textTransform="uppercase"
          sx={{ display: { md: "none", xs: "block" }, mt: 3 }}
        >
          Original Price
        </Typography>
        <TextField
          variant="standard"
          // value={`₹ ${originalAmt}`}

          value={originalAmt}
          sx={styles.input}
          disabled={!availability}
          onChange={({ target }) => {
            const value = target.value;
            const regex = /^(\d{0,7}|\d{0,7}\.)(?:\.\d{1,2})?$/;
            if (regex.test(value) && target.value.charAt(0) !== "0") {
              onValueChange({
                type: "productOriginalPrice",
                value: target.value,
              });
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  cursor: "pointer",
                  color: blackColor,
                  fontSize: { md: 14, xs: 12 },
                  fontWeight: 500,
                  mt: -0.2,
                }}
              >
                ₹
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          inputProps={{
            style: {
              color: blackColor,
              fontSize: { md: 14, xs: 12 },
              fontWeight: 500,
            },
          }}
        />
      </Grid>

      <Grid item md={1.23} xs={6}>
        <Typography
          variant="h3"
          fontSize={{ md: 13, xs: 11 }}
          color={greyShade25}
          fontWeight={400}
          textTransform="uppercase"
          sx={{ display: { md: "none", xs: "block" }, mt: 3 }}
        >
          Sale Price
        </Typography>
        <TextField
          variant="standard"
          value={amt}
          sx={styles.input}
          disabled={!availability}
          onChange={({ target }) => {
            const value = target.value;
            const regex = /^(\d{0,7}|\d{0,7}\.)(?:\.\d{1,2})?$/;
            if (regex.test(value) && target.value.charAt(0) !== "0") {
              onValueChange({ type: "productSalePrice", value });
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  cursor: "pointer",
                  color: blackColor,
                  mt: -0.2,
                }}
              >
                ₹
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          inputProps={{
            style: {
              color: blackColor,
              fontSize: { md: 14, xs: 12 },
              fontWeight: 500,
            },
          }}
        />
      </Grid>

      <Grid item md={1} xs={5} mt={{ md: -1, xs: 0 }}>
        <Typography
          variant="h3"
          fontSize={{ md: 13, xs: 11 }}
          color={greyShade25}
          fontWeight={400}
          textTransform="uppercase"
          sx={{ display: { md: "none", xs: "block" }, mt: 3 }}
        >
          GST%
        </Typography>
        <SelectWithLabel
          value={gst?.replace("%", "") || ""}
          disabled={!availability}
          onValueChange={(value) => {
            // console.log(value);
            // const gstValue = gstRates?.filter((item) => item?.value == value);
            onValueChange({
              type: "gstInfo",
              value: { code: gstCode, gstPercentage: value },
            });
          }}
          menuList={gstRates}
          valueSelector="value"
          isDashboardInput
        />
      </Grid>

      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent={{ xs: "space-between", md: "flex-end" }}
        gap={{ md: "20px", xs: "10px" }}
        mt={{ xs: 2, md: 0 }}
      >
        <Box minWidth="150px">
          <Typography
            variant="h3"
            fontSize={{ md: 13, xs: 11 }}
            color={greyShade25}
            fontWeight={400}
            textTransform="uppercase"
            sx={{ display: { md: "block", xs: "block" }, mb: 0.5 }}
          >
            Start Selling On
          </Typography>
          <TextField
            variant="standard"
            type="date"
            disabled={!availability}
            value={startDate}
            onChange={({ target }) => {
              onValueChange({ type: "commerceStartDate", value: target.value });
            }}
            sx={styles.input}
            InputProps={{
              disableUnderline: true,
              style: {
                color: blackColor,
                fontSize: { md: 14, xs: 12 },
                fontWeight: 500,
              },
            }}
          />
        </Box>

        <Box minWidth={{ xs: "160px", md: "150px" }}>
          <Typography
            variant="h3"
            fontSize={{ md: 13, xs: 11 }}
            color={greyShade25}
            fontWeight={400}
            textTransform="uppercase"
            sx={{ display: { md: "block", xs: "block" }, mb: 0.5 }}
          >
            End Selling On
          </Typography>

          <TextField
            variant="standard"
            type="date"
            disabled={!availability}
            value={endDate}
            onChange={({ target }) => {
              onValueChange({ type: "commerceEndDate", value: target.value });
            }}
            sx={styles.input}
            InputProps={{
              disableUnderline: true,
              style: {
                color: blackColor,
                fontSize: { md: 14, xs: 12 },
                fontWeight: 500,
              },
            }}
          />
        </Box>
      </Box>

      {errors?.length > 0 ? (
        <Grid item md={12} mt={2}>
          {errors?.map((item, i) => (
            <Typography
              color={"red"}
              textAlign="center"
              fontWeight={500}
              display="inline"
              mr={1}
            >
              {item}
            </Typography>
          ))}
        </Grid>
      ) : null}
    </Grid>
  );
}

export default AllStockRowCard;

const styles = {
  imgContainer: {
    borderRadius: "12px",
    border: "1.4px solid #eaeaea",
    backgroundColor: whiteColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 65,
    height: 65,
  },
  input: {
    width: { md: "95%", xs: "80%" },
    fontSize: 12.5,
    fontWeight: 500,
    borderTop: "none",
    borderBottom: borderCart,
    borderLeft: "none",
    borderRight: "none",
    backgroundColor: whiteColor,
  },
};

const StyledSwitch = styled(Switch)({
  "& .MuiSwitch-switchBase": {
    color: whiteColor,
    "&.Mui-checked": {
      color: greyColor8,

      "& + .MuiSwitch-track": {
        backgroundColor: greyColor8,
      },
    },
  },
});
