import {
  Box,
  Button,
  Chip,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  BGColor02,
  bgColor15,
  blackShade05,
  blackShade21,
  blackShade22,
  blueColor04,
  borderTextBoxColor,
  greyColor40,
  greyColor42,
  greyColor47,
  greyColor49,
  greyColor5,
  greyColor8,
  greyColor9,
  lightRedColor,
  lightRedColor02,
  mobilePrimary,
  oldPriceColor,
  outOfStockColor,
  redColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import ThreeDotLoader from "../../components/ThreeDotLoader";
import MuiAlert from "@mui/material/Alert";
import { Link, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import SubBranchProductInfoRowCard from "./Card/SubBranchProductInfoRowCard";
import HeaderInfoRow from "./Card/HeaderInfoRow";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSearchProducts,
  getONDCCategories,
  getProductCategories,
  searchBusinessProducts,
} from "../../reducers/businessPreview/businessPreviewSlice";
import SearchBoxInput from "../../components/Input/SearchBoxInput";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import ProductFilterCard from "./Components/ProductFilterCard";
import { getONDCProductsAPI } from "../../service/api_calls/BusinessPreview/BusinessPreviewAPI";
import { dynamicSort } from "../../utils/helper";
import { updateSubProductList } from "../../reducers/multiBranchCatalog/multiBranchCatalogSlice";
import SearchInfoCard from "./Components/SearchInfoCard";
import EmptyCard from "../../components/Alerts/EmptyCard";

import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import MobileCategoryFilter from "./Components/MobileCategoryFilter";

const SubBranchCalalogContent = ({
  productList,
  productLoader,
  fetchActiveBusinessProducts,
  hasMoreData,
  inventorySummary,
  setHasMoreData,
  commerceInfo,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { masterBranchInfo, masterBranchInfoLoader } = useSelector(
    (state) => state.multiBranchCatalog
  );

  const [ondcProductCount, setOndcProductCount] = useState(0);

  const [openMobileFilterModel, setOpenMobileFilterModel] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [isClearSearch, setIsClearSearch] = useState(false);
  const [filterType, setFilterType] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);

  const [ondcCategoryList, setOndcCategoryList] = useState([]);
  const [ondcSubCatList, setOndcSubCatList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedSubCat, setSelectedSubCat] = useState("All");

  const [selectedOndcCategory, setSelectedOndcCategory] = useState("All");
  const [selectedOndcSubCat, setSelectedOndcSubCat] = useState("All");

  const [showONDCFilter, setShowONDCFilter] = useState(false);
  const [showWebstoreFilter, setShowWebstoreFilter] = useState(false);

  const [isClearFilter, setIsClearFilter] = useState(null);
  const [filterError, setFilterError] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);

  const ondcFilterRef = useRef(null);
  const webstoreFilterRef = useRef(null);

  const { productCategory, ondcCategory } = useSelector(
    (state) => state.businessPreview
  );

  const handlePreview = (productId) => {
    const businessUrl = sessionStorage.getItem("costboWEBURL");
    navigate(`/${businessUrl}/viewProductNew`, {
      state: { productId, isReviewProduct: false },
    });
  };

  const clearFilter = () => {
    setSelectedCategory("All");
    setSelectedSubCat("All");
    setSubCatList([]);
    setSelectedOndcCategory("All");
    setSelectedOndcSubCat("All");
    setOndcSubCatList([]);

    setIsClearFilter(null);
  };

  //search for products
  const handleSearch = () => {
    if (!searchQuery) return;
    clearFilter();
    const businessId = sessionStorage.getItem("selectedBusinessId");
    setSelectedCategory("All");
    setSelectedSubCat("All");

    dispatch(
      searchBusinessProducts({ query: searchQuery, businessId: businessId })
    ).then((res) => {
      if (res?.payload?.details) {
        console.log("SEARCH_RES___:", res?.payload?.details);

        const searchResultList = res?.payload?.details || [];

        const updatedList = [];

        searchResultList.forEach((item) => {
          const result = productList?.find(
            (product) => product?.id === item?.id
          );

          if (result !== undefined) {
            updatedList.push(result);
          }
        });

        setFilterType("search");
        setIsClearSearch(true);
        setHasMoreData(false);
        dispatch(updateSubProductList({ updatedList: [...updatedList] }));

        setIsClearFilter({
          show: true,
          category: `searchQuery`,
          message: `Search results for “${searchQuery}”`,
        });
      }
    });
  };

  // Clear Search
  const clearSearch = () => {
    dispatch(clearSearchProducts());
    fetchActiveBusinessProducts(true);

    setSearchQuery("");
    setIsClearSearch(false);
    setIsClearFilter(false);
    setFilterType("");

    setSelectedCategory("All");
    setSelectedSubCat("All");
    setOndcSubCatList([]);
    setSelectedOndcCategory("All");
    setSelectedOndcSubCat("All");
    setSubCatList([]);
  };

  // Prepare list data for filter dropdown
  const formatCategory = (category, setValue, valueSelector) => {
    const sortedArray = category?.slice()?.sort(dynamicSort(valueSelector));
    const catList = sortedArray?.map((item) => {
      return { ...item, label: item?.[valueSelector] };
    });

    setValue([{ label: "All" }, ...catList]);
  };

  // Handle Web Store Category Change
  const handleWebstoreCategoryChange = (data) => {
    setSelectedCategory(data);
    setSelectedSubCat("All");

    if (data === "All") {
      setSubCatList([]);
      return;
    }

    setFilterError("");

    const CategoryData = categoryList?.filter((item) => {
      return item?.value === data;
    });

    formatCategory(CategoryData?.[0]?.subCat, setSubCatList, "value");
  };

  // Handle Web Store Sub-Category Change
  const handleWebstoreSubCategoryChange = (data) => {
    setSelectedSubCat(data);
  };

  // Handle ONDC Category Change
  const handleOndcCategoryChange = (data) => {
    setSelectedOndcCategory(data);
    setSelectedOndcSubCat("All");

    if (data === "All") {
      setOndcSubCatList([]);
      return;
    }

    setFilterError("");

    const categoryData = ondcCategoryList?.filter((item) => {
      return item?.ondcValue === data;
    });

    formatCategory(
      categoryData?.[0]?.productSubCategory,
      setOndcSubCatList,
      "ondcSubValue"
    );
  };

  // Handle ONDC Sub-Category Change
  const handleOndcSubCategoryChange = (data) => {
    setSelectedOndcSubCat(data);
  };

  // Apply ONDC Filter
  const handleOndcFilter = async () => {
    setFilterError("");
    if (ondcCategoryList?.length === 1) {
      setFilterError("ONDC category not assigned for any of the products");

      return;
    }
    if (selectedOndcCategory === "All") {
      setFilterError("Select the Main Category");

      return;
    }

    setFilterLoader(true);
    const businessId = sessionStorage.getItem("selectedBusinessId");
    const categoryData = ondcCategoryList?.filter((item) => {
      return item?.ondcValue === selectedOndcCategory;
    });

    const subCategoryData = ondcSubCatList?.filter((item) => {
      return item?.ondcSubValue === selectedOndcSubCat;
    });

    const ondcProductList = await getONDCProductsAPI({
      businessId,
      params: {
        ondcCode: categoryData?.[0]?.ondcCode,
        ondcSubCode: subCategoryData?.[0]?.ondcSubCode,
        pageSize: 500,
        pageNo: 1,
      },
    });

    setFilterLoader(false);
    console.log("handleOndcFilter____", ondcProductList);
    // setProductsList(updatedSearchList);

    const formattedProducts = ondcProductList?.map((item) => ({
      addedDate: null,
      lastUpdateDate: null,
      ondcEnabled: item?.ondcEnabled,

      origin: "",
      productOriginalPrice: item?.productOriginalPrice,
      id: item?.id,
      productName: item?.productName,
      productPhoto: item?.productPhoto,
      productSalePrice: item?.productSalePrice,
      percentageOff: item?.percentageOff,
      productLastImportDate: item?.productLastImportDate,
    }));

    dispatch(updateSubProductList({ updatedList: [...formattedProducts] }));
    setFilterType("ondc");
    setShowONDCFilter(false);
    setHasMoreData(false);
    setIsClearFilter({
      show: true,
      category: `${selectedOndcCategory} - ${selectedOndcSubCat}`,
      message: `ONDC Category Filter results for “${selectedOndcCategory} - ${selectedOndcSubCat}” -
    ${ondcProductList?.length} products`,
    });
    setOpenMobileFilterModel(false);
  };

  // Apply Web Store Filter
  const handleWebstoreFilter = () => {
    setFilterError("");
    if (selectedCategory === "All") {
      setFilterError("Select the Main Category");
      return;
    }

    const categoryData = categoryList?.filter((item) => {
      return item?.value === selectedCategory;
    });

    if (selectedSubCat === "All") {
      const currentProductList = [...productList];

      const filteredProducts = currentProductList?.filter((item) => {
        return (
          item?.productSubCategory?.[0]?.pcCode === categoryData?.[0]?.code
        );
      });

      console.log("handleWebstoreFilter___", filteredProducts);

      dispatch(updateSubProductList({ updatedList: [...filteredProducts] }));
      // setProductsList(filteredProducts);

      setFilterType("ondc");
      setShowWebstoreFilter(false);
      setIsClearFilter({
        show: true,
        category: `${selectedCategory} - ${selectedSubCat}`,
        message: `Webstore Category Filter results for “${selectedCategory} - ${selectedSubCat}” -
      ${filteredProducts?.length} products`,
      });
      return;
    }

    const subCategoryData = subCatList?.filter((item) => {
      return item?.value === selectedSubCat;
    });

    const currentProductList = [...productList];

    const filteredProducts = currentProductList?.filter((item) => {
      return item?.productSubCategory?.[0]?.code === subCategoryData?.[0]?.code;
    });

    dispatch(updateSubProductList({ updatedList: [...filteredProducts] }));
    setFilterType("web");
    setShowWebstoreFilter(false);
    setIsClearFilter({
      show: true,
      category: `${selectedCategory} - ${selectedSubCat}`,
      message: `Webstore Category Filter results for “${selectedCategory} - ${selectedSubCat}” -
    ${filteredProducts?.length} products`,
    });
    setOpenMobileFilterModel(false);
  };

  const onWebstoreFilterClose = () => {
    setFilterError("");
    setShowWebstoreFilter(false);
    setSelectedCategory("All");
    setSelectedSubCat("All");
    setSubCatList([]);
  };

  const onOndcFilterClose = () => {
    setFilterError("");
    setShowONDCFilter(false);
    setSelectedOndcCategory("All");
    setSelectedOndcSubCat("All");
    setOndcSubCatList([]);
  };

  const clearAllOndcFilterData = () => {
    setSelectedOndcCategory("All");
    setSelectedOndcSubCat("All");
    setOndcSubCatList([]);
  };

  const clearAllWebstoreFilterData = () => {
    setSelectedCategory("All");
    setSelectedSubCat("All");
    setSubCatList([]);
  };

  const closeMobileFilterPopup = () => {
    clearAllOndcFilterData();
    setOpenMobileFilterModel(false);
  };

  const getEmptyMessage = () => {
    if (filterType === "search") {
      return "No matching active products found. Please refine your search";
    } else if (filterType === "ondc" || filterType === "web") {
      return "No matching active products found";
    }

    return "Products have not been synced from main branch yet. Please contact the Super admin of the branch";
  };

  useEffect(() => {
    if (productCategory?.details) {
      console.log("productCategory", productCategory?.details);
      formatCategory(productCategory?.details, setCategoryList, "value");
    }
  }, [productCategory]);

  useEffect(() => {
    if (ondcCategory) {
      const ondcFilteredCategory = ondcCategory?.filter(
        (item) => item?.ondcCode
      );
      formatCategory(ondcFilteredCategory, setOndcCategoryList, "ondcValue");
      console.log("ondcCategory", ondcCategory, ondcFilteredCategory);
    }
  }, [ondcCategory]);

  useEffect(() => {
    const businessId = sessionStorage.getItem("selectedBusinessId");
    if (businessId) {
      if (!productCategory) {
        dispatch(getProductCategories(businessId));
      }
      if (!ondcCategory) {
        dispatch(getONDCCategories(businessId));
      }
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (ondcProductCount === 0) {
      if (productList?.length > 0) {
        const ondcProducts = productList?.filter(
          (item) => item?.ondcEnabled?.toLowerCase() === "yes"
        );

        setOndcProductCount(ondcProducts?.length || 0);
      }
    }

    return () => {};
  }, [productList]);

  return (
    <Box>
      <Box
        sx={{
          position: "sticky",
          top: { xs: 100, md: 45 },
          width: "100%",
          zIndex: 100,
          backgroundColor: BGColor02,
        }}
      >
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography
              component="h4"
              fontSize={{ xs: 15, md: 17 }}
              color={blackShade05}
              fontWeight={600}
              lineHeight="20px"
              pt={2}
            >
              Product Catalog
            </Typography>

            <Typography
              fontSize={{ xs: 12, md: 13 }}
              fontWeight={500}
              color={blackShade21}
              lineHeight={{ xs: "17px", md: "24px" }}
              mt={0.4}
              pb={{xs:0.5 ,md:1.5}}
            >
              Catalog details pulled from Master branch -{" "}
              <span style={{ color: matches ? blueColor04 : mobilePrimary }}>
                "{masterBranchInfo?.details?.masterBranchName}"
              </span>
              . Please contact the admin to add/modify catalog
            </Typography>

            <Box display='flex' alignItems='center' justifyContent='space-between' pb={1}>
              <Typography
                component="h5"
                fontSize={{ xs: 15, md: 15 }}
                color={blackShade05}
                fontWeight={600}
                lineHeight="20px"
              >
                Available to Order{" "}
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: "400",
                    color: greyColor40,
                  }}
                >
                  -{" "}
                  {!inventorySummary
                    ? "XX"
                    : `${inventorySummary?.totalProduct} ${
                        inventorySummary?.totalProduct?.length === 1
                          ? "product"
                          : "products"
                      }`}
                </span>
              </Typography>

              {!matches && (
                <HeaderActionButtons
                  setOpenMobileFilterModel={setOpenMobileFilterModel}
                  showSearchBar={showSearchBar}
                  setShowSearchBar={setShowSearchBar}
                />
              )}
            </Box>
          </Box>
        </Box>

        {!productLoader &&
          commerceInfo &&
          ondcProductCount > 0 &&
          (!commerceInfo?.info?.ondcEnabled ||
            commerceInfo?.info?.ondcEnabled?.toLowerCase() === "no") && (
            <Box p="10px 0 16px">
              <Box
                sx={{
                  bgcolor: lightRedColor02,
                  p: "8px 15px",
                  borderRadius: "10px",
                  my: 0.2,
                }}
              >
                <Typography
                  fontSize={{ xs: 12, md: 13 }}
                  fontWeight={500}
                  color={outOfStockColor}
                  lineHeight="21px"
                >
                  ONDC Controls are enabled for {ondcProductCount} Products.
                  Enable ONDC control at business level to make sure products
                  are visible in ONDC search from buyer apps.{" "}
                  <Link
                    to={`/${sessionStorage.getItem(
                      "costboWEBURL"
                    )}/ONDCControls`}
                    style={{
                      color: greyColor49,
                      textDecoration: "underline",
                    }}
                  >
                    Click Here
                  </Link>
                </Typography>
              </Box>
            </Box>
          )}

        {matches && (productList?.length > 0 || filterType) ? (
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={{ md: "center" }}
              flexDirection={{ md: "row", xs: "column" }}
              gap={2}
              px={1.5}
              mb={2}
            >
              <Box>
                <Box width={{ xs: "auto", md: "300px" }}>
                  <SearchBoxInput
                    label=""
                    placeholder="Search Product"
                    value={searchQuery}
                    onChange={(text) => {
                      setSearchQuery(text);
                    }}
                    onSearchClick={handleSearch}
                    isDashboardInput
                  />
                </Box>

                {(isClearSearch || isClearFilter) && (
                  <Box mt={{ xs: 2.5, md: 1.3 }}>
                    <SearchInfoCard
                      searchQuery={searchQuery}
                      clearSearch={() => {
                        clearSearch();
                      }}
                      filterType={filterType}
                      isClearFilter={isClearFilter}
                    />
                  </Box>
                )}
              </Box>

              <Box
                display={"flex"}
                gap={1}
                position={{ xs: "relative", md: "static" }}
              >
                <Box
                  position={{ md: "relative", xs: "static" }}
                  ref={ondcFilterRef}
                >
                  <Button
                    sx={styles.filterIconBtn}
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      if (filterType === "search") {
                        clearSearch();
                      }
                      setShowWebstoreFilter(false);
                      setShowONDCFilter((prev) => !prev);
                    }}
                    endIcon={
                      <FilterListRoundedIcon sx={{ fontSize: "17px" }} />
                    }
                  >
                    {matches ? "ONDC Category Filters" : "ONDC Category"}
                  </Button>

                  {showONDCFilter && (
                    <ProductFilterCard
                      title="ONDC Category Filters"
                      categoryList={ondcCategoryList}
                      subCategoryList={ondcSubCatList}
                      selectedCategory={selectedOndcCategory}
                      selectedSubCategory={selectedOndcSubCat}
                      handleCategoryChange={handleOndcCategoryChange}
                      handleSubCategoryChange={handleOndcSubCategoryChange}
                      onApply={handleOndcFilter}
                      onCancel={onOndcFilterClose}
                      onAllClear={clearAllOndcFilterData}
                      loader={filterLoader}
                      error={filterError}
                    />
                  )}
                </Box>

                <Box
                  position={{ md: "relative", xs: "static" }}
                  ref={webstoreFilterRef}
                >
                  <Button
                    sx={styles.filterIconBtn}
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      if (filterType === "search" || filterType === "ondc") {
                        clearSearch();
                      }
                      setShowONDCFilter(false);
                      setShowWebstoreFilter((prev) => !prev);
                    }}
                    endIcon={
                      <FilterListRoundedIcon sx={{ fontSize: "17px" }} />
                    }
                  >
                    {matches
                      ? "Webstore Category Filters"
                      : "Webstore Category"}
                  </Button>

                  {showWebstoreFilter && (
                    <ProductFilterCard
                      title="Webstore Category Filters"
                      categoryList={categoryList}
                      subCategoryList={subCatList}
                      selectedCategory={selectedCategory}
                      selectedSubCategory={selectedSubCat}
                      handleCategoryChange={handleWebstoreCategoryChange}
                      handleSubCategoryChange={handleWebstoreSubCategoryChange}
                      onApply={handleWebstoreFilter}
                      onCancel={onWebstoreFilterClose}
                      onAllClear={clearAllWebstoreFilterData}
                      error={filterError}
                    />
                  )}
                </Box>
              </Box>
            </Box>

            <HeaderInfoRow />
          </>
        ) : !matches ? (
          <Box pt={1}>
            {/* <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="flex-end"
              gap={1.5}
            >
              <IconButton
                sx={styles.iconBtn}
                onClick={() => setOpenMobileFilterModel(true)}
              >
                <FilterListIcon sx={{ fontSize: "18px" }} />
              </IconButton>

              <IconButton
                sx={styles.iconBtn}
                onClick={() => setShowSearchBar(!showSearchBar)}
              >
                {showSearchBar ? (
                  <SearchOffIcon sx={{ fontSize: "18px" }} />
                ) : (
                  <SearchIcon sx={{ fontSize: "18px" }} />
                )}
              </IconButton>
            </Box> */}

            {showSearchBar && (
              <Box width={{ xs: "auto", md: "300px" }} mb={2}>
                <SearchBoxInput
                  label=""
                  placeholder="Search Product"
                  value={searchQuery}
                  onChange={(text) => {
                    setSearchQuery(text);
                  }}
                  onSearchClick={handleSearch}
                  isDashboardInput
                />
              </Box>
            )}

            {(isClearSearch || isClearFilter) && (
              <Box>
                <SearchInfoCard
                  searchQuery={searchQuery}
                  clearSearch={() => {
                    clearSearch();
                  }}
                  filterType={filterType}
                  isClearFilter={isClearFilter}
                />
              </Box>
            )}
          </Box>
        ) : null}
      </Box>

      {!productLoader && productList?.length == 0 && (
        <EmptyCard
          icon="https://storage.googleapis.com/bodefaults/businessdashboardweb/product-reject.svg"
          message={getEmptyMessage()}
        />
      )}

      <Box>
        {/* <InfiniteScroll
          dataLength={productList?.length || 0}
          next={() => fetchActiveBusinessProducts()}
          hasMore={hasMoreData}
          loader={
            productLoader ? (
              <Box>
                <ThreeDotLoader />
              </Box>
            ) : null
          }
        > */}
        {productList?.map((productInfo, index) => {
          return (
            <SubBranchProductInfoRowCard
              key={`${productInfo?.id}_${index}`}
              index={index}
              productImg={productInfo?.productPhoto?.[0]?.docURL}
              productName={productInfo?.productName}
              originalPrice={productInfo?.productOriginalPrice}
              salePrice={productInfo?.productSalePrice}
              ondcEnabled={productInfo?.ondcEnabled}
              importDate={productInfo?.productLastImportDate}
              onViewClick={() => handlePreview(productInfo?.id)}
            />
          );
        })}
        {/* </InfiniteScroll> */}
      </Box>

      <MobileCategoryFilter
        openModal={openMobileFilterModel}
        setOpenModal={setOpenMobileFilterModel}
        // ONDC Category
        selectedOndcCategory={selectedOndcCategory}
        handleOndcCategoryChange={handleOndcCategoryChange}
        selectedOndcSubCat={selectedOndcSubCat}
        handleOndcSubCategoryChange={handleOndcSubCategoryChange}
        ondcCategoryList={ondcCategoryList}
        ondcSubCatList={ondcSubCatList}
        onOndcApplyFilter={handleOndcFilter}
        onCancel={closeMobileFilterPopup}
        onAllClear={clearAllOndcFilterData}
        loader={filterLoader}
        filterError={filterError}
        // WebStore Category
        categoryList={categoryList}
        subCategoryList={subCatList}
        selectedCategory={selectedCategory}
        selectedSubCategory={selectedSubCat}
        handleCategoryChange={handleWebstoreCategoryChange}
        handleSubCategoryChange={handleWebstoreSubCategoryChange}
        onWebStoreApply={handleWebstoreFilter}
      />
    </Box>
  );
};

export default SubBranchCalalogContent;

const HeaderActionButtons = ({
  setOpenMobileFilterModel,
  showSearchBar,
  setShowSearchBar,
}) => (
  <Box
    display="flex"
    alignItems="flex-start"
    justifyContent="flex-end"
    gap={1.5}
    mt={1}
  >
    <IconButton
      sx={styles.iconBtn}
      onClick={() => setOpenMobileFilterModel(true)}
    >
      <FilterListIcon sx={{ fontSize: "18px" }} />
    </IconButton>

    <IconButton
      sx={styles.iconBtn}
      onClick={() => setShowSearchBar(!showSearchBar)}
    >
      {showSearchBar ? (
        <SearchOffIcon sx={{ fontSize: "18px" }} />
      ) : (
        <SearchIcon sx={{ fontSize: "18px" }} />
      )}
    </IconButton>
  </Box>
);

const styles = {
  headerSubText: {
    fontSize: 11.5,
    fontWeight: 400,
    color: greyColor5,
    marginLeft: "5px",
  },

  tableWrapper: {
    // width: "calc(100vw - 270px)",
    borderRadius: "6px",
    border: `1px solid ${borderTextBoxColor}`,
    bgColor: whiteColor,
    mt: 3,
  },

  tableContainer: {
    borderRadius: "8px",
    // overflow: "scroll",
    maxHeight: "calc(100vh - 160px)",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      // background: '#d0d3da',
      background: "rgba(0,0,0,0.1)",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.05)",
    },
  },

  tableRowStyles: {
    position: "sticky !important",
    top: 0,
    backgroundColor: greyColor42,
    zIndex: 20,
    borderRadius: "8px 8px 0 0",
  },

  tableCell: {
    color: greyColor47,
    fontSize: "12.5px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    padding: "8px 15px",
    backgroundColor: greyColor42,
    borderRight: `1px solid ${whiteColor}`,
  },

  tableBodyCell: (isError) => ({
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: blackShade05,
    backgroundColor: isError ? lightRedColor : whiteColor,
    padding: "8px 15px !important",
    // borderRight: `1px solid ${borderTextBoxColor}`,
    boxShadow: isError ? `0px -1px 0 0 ${redColor} inset` : "none",
  }),

  checkbox: {
    p: 0,
    transform: "scale(1.3)",
    "&.Mui-checked": {
      color: greyColor8,
    },
  },

  imgBox: {
    width: "45px",
    borderRadius: "4px",
    border: "1px solid #e5e9f0",
    p: "5px",
  },

  imgContainer: {
    backgroundColor: whiteColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "35px !important",
    height: "35px",
    objectFit: "contain",
  },

  ondcStatusTag: {
    width: "55px",
    display: { xs: "inline", md: "flex" },
    alignItems: "center",
    justifyContent: "center",
    fontSize: { xs: 13, md: 12 },
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "50px",
    p: { xs: "0 8px", md: "1px 13px" },
  },

  statusChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    p: "5px 13px",
    borderRadius: "50px",
    width: "max-content",
  }),

  statusBoxChip: (data) => ({
    backgroundColor: data.bgColor,
    color: data.color,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    p: "2px 4px",
    borderRadius: "4px",
    width: "max-content",
  }),

  originalPriceText: {
    color: oldPriceColor,
    fontSize: 12,
    lineHeight: "15px",
    fontWeight: 400,
    mt: { xs: "0px !important", md: "7px !important" },
    textDecoration: "line-through",
    display: "inline",
  },

  salePriceText: {
    display: "inline",
    color: blueColor04,
    fontSize: 13,
    lineHeight: "15px",
    fontWeight: 600,
    m: "6px 0 0 0px",
    mr: 1,
  },

  actionBtn: {
    width: "33px",
    height: "27px",
    fontSize: 12,
    fontWeight: 600,
    padding: "3px 15px",
    textTransform: "unset",
    border: `1px solid ${bgColor15}`,
    borderRadius: "4px",
    color: bgColor15,
    mt: 1.2,
    "&:hover": {
      color: whiteColor,
      backgroundColor: bgColor15,
    },
    "&.Mui-disabled": {
      borderColor: "rgba(0,0,0,0.2)",
    },
  },

  updateIconBtn: {
    padding: { md: "3px 14px", xs: "3px 14px" },
    fontSize: { md: "11px", xs: "11px" },
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: { xs: blackShade22, md: blackShade22 },
    border: {
      xs: `1.5px solid ${blackShade22}`,
      md: `1.5px solid ${blackShade22}`,
    },
    "&:hover": {
      border: {
        xs: `1.5px solid ${blackShade22}`,
        md: `1.5px solid ${blackShade22}`,
      },
      color: { xs: blackShade22, md: blackShade22 },
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  },

  filterIconBtn: {
    padding: "4px 12px",
    fontSize: "11px",
    fontWeight: 600,
    color: greyColor9,
    backgroundColor: whiteColor,
    whiteSpace: "nowrap",
    border: "1px solid " + greyColor9,
    "&:hover": {
      color: whiteColor,
      backgroundColor: greyColor9,
    },
  },

  iconBtn: {
    width: "40px",
    height: "32px",
    bgcolor: whiteColor,
    borderRadius: "5px",
    border: `1px solid ${greyColor9}`,
  },
};
