import styled from "@emotion/styled";
import { Box, Switch, Typography } from "@mui/material";
import React from "react";
import {
  blackShade05,
  colorGreen,
  whiteColor,
} from "../../configs/styles/muiThemes";

function EcommerceEnableCard({ icon, label, isEnabled }) {
  return (
    <Box sx={styles.card}>
      <Box sx={{ ...styles.iconBG, backgroundColor: "rgb(5 60 81 / 7%)" }}>
        <Box
          component="img"
          src={icon}
          width={{ xs: "21px", md: "21px" }}
          height={{ xs: "21px", md: "21px" }}
          sx={{
            objectFit: "contain",
          }}
        />
      </Box>

      <Box
        sx={{
          width: { xs: "100%", md: "auto" },
          display: "flex",
          flexDirection: { xs: "row", md: "column" },
          alignItems: { xs: "center", md: "flex-start" },
          justifyContent: { xs: "space-between", md: "flex-start" },
        }}
      >
        <Typography
          component="h5"
          fontSize={15}
          color={blackShade05}
          fontWeight={600}
          lineHeight="18px"
        >
          {label}
        </Typography>

        <StyledSwitch size="small" checked={isEnabled} onChange={()=>{}} />
      </Box>
    </Box>
  );
}

export default EcommerceEnableCard;

const StyledSwitch = styled(Switch)({
  "& .MuiSwitch-switchBase": {
    color: whiteColor,
    "&.Mui-checked": {
      color: colorGreen,

      "& + .MuiSwitch-track": {
        backgroundColor: colorGreen,
      },
    },
  },
});

const styles = {
  card: {
    width: { xs: "100%", md: "auto" },
    display: "flex",
    flexDirection: "row",
    alignItems: { xs: "flex-start", md: "center" },
    gap: { xs: "18px", md: "18px" },
  },

  iconBG: {
    width: "45px",
    height: "45px",
    display: { xs: "none", md: "flex" },
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
};
